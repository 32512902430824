export enum LLMTemplateViewCodes {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  ADD = 'ADD',
  LIST = 'LIST',
  CANCEL = 'CANCEL'
}

export const llmTitles = {
  accessRole: 'Access Role',
  createdAt: 'Created At',
  id: 'ID',
  isActive: 'Is Active',
  isDeleted: 'Is Deleted',
  templateName: 'Template Name',
  templateStr: 'Template String',
  templateType: 'Template Type',
  tenantId: 'Tenant ID'
};

export const LLM_VIEW_ROLE = {
  PRIVATE: 'PRIVATE',
  ALL_USERS: 'ALL_USERS',
  SELECTED_USERS: 'SELECTED_USERS',
  SELECTED_ROLES: 'SELECTED_ROLES'
}

export const ROLE_STRING = {
  PRIVATE: 'Only Me',
  ALL_USERS: 'Everyone',
  SELECTED_USERS: 'Selected Users',
  SELECTED_ROLES: 'Selected Roles'
}