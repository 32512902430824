export const stagePercentMetaData = [
  {
    id: 1,
    percentLabel: '10%',
    value: '10',
  },
  {
    id: 2,
    percentLabel: '20%',
    value: '20',
  },
  {
    id: 3,
    percentLabel: '30%',
    value: '30',
  },
  {
    id: 4,
    percentLabel: '40%',
    value: '40',
  },
  {
    id: 5,
    percentLabel: '50%',
    value: '50',
  },
  {
    id: 6,
    percentLabel: '60%',
    value: '60',
  },
  {
    id: 7,
    percentLabel: '70%',
    value: '70',
  },
  {
    id: 8,
    percentLabel: '80%',
    value: '80',
  },
  {
    id: 9,
    percentLabel: '90%',
    value: '90',
  },
  {
    id: 10,
    percentLabel: 'Won',
    value: 'won',
  },
  {
    id: 1,
    percentLabel: 'Lost',
    value: 'lost',
  },
];

export const stageStateMetaData = [
  {
    titleLabel: 'Active',
    value: 'active',
  },
  {
    titleLabel: 'Archived',
    value: 'archived',
  },
];

export const attributions = [
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'Month',
    value: 'month',
  },
  {
    label: 'Day',
    value: 'day',
  },
  {
    label: 'Dash',
    value: '-',
  },
  {
    label: 'underscore',
    value: '_',
  },
  {
    label: 'slash',
    value: '/',
  },
];

export const usersData = ['Bhavesh Patel', 'Abhay'];
export const members = ['Bhavesh Patel', 'Abhay'];

