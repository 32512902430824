import {DatePicker, Drawer} from 'antd';
import {VStack, Text, HStack, FormControl, TextArea} from 'native-base';
import {BUTTON_TYPE, DATE_FORMATS, DISPLAY_DATE_FORMAT} from '../../../../../constants/StringConst';
import {Colors} from '../../../../../styles/Colors';
import {getDateObject, getMomentObj, isPastDay} from '../../../../../utils/DateUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {capitalizeText} from '../../../ContentManagement/CampaignManagement/Helper';
import {IPatientJourneyProps} from './PatientJourneyInterface';
import {StyleSheet} from 'react-native';
import {useContext, useState} from 'react';
import { ModalActionDatePicker } from '../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import { RTE_MODULES } from '../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import ReactQuill from 'react-quill';
import { CommonDataContext } from '../../../../../context/CommonDataContext';

const {RangePicker} = DatePicker;

const PauseCareJourneyDrawer = (props: IPatientJourneyProps) => {
  const [stateData, setStateData] = useState<{
    restartDateTime?: string;
    comment?: string;
  }>({
    comment: undefined,
  });

  function resetStateData() {
    setStateData({});
  }

  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;

  return (
    <Drawer
      destroyOnClose
      placement="right"
      onClose={() => {
        resetStateData();
        props.onCancel();
      }}
      visible={props.enabledDrawer}
      closable={false}
      width={isSidecarContext ? '100%' : '40%'}
      title={<ModalActionTitle
        title={capitalizeText(props?.selectedJourney?.title || props?.selectedJourney?.careJourney?.title)}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: 'cancel',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              resetStateData();
              props.onCancel();
            },
          },
          {
            show: true,
            id: 2,
            btnText: 'pause',
            textColor: Colors.Custom.mainPrimaryPurple,
            isLoading: props.isLoading,
            variant: BUTTON_TYPE.PRIMARY,
            isTransBtn: false,
            onClick: () => {
              props.onActionPerformed({ restartDateTime: stateData.restartDateTime, note: stateData.comment?.trim() }, props.selectedJourney);
              resetStateData();
            },
          },
        ]}
      />}
      >
      <HStack flex={1}>
        <FormControl style={[styles.formElement]}>
          <FormControl.Label style={styles.formLabel}>
            <DisplayText
              textLocalId={'selectJourneyRestartDate'}
              size={'mdMedium'}
              extraStyles={{color: Colors.Custom.Gray900}}
            />
          </FormControl.Label>
          <ModalActionDatePicker
            isRequired={false}
            label={''}
            format={DISPLAY_DATE_FORMAT}
            onChange={(date: any) => {
              if (date) {
                setStateData(prev => {
                  return {
                    ...prev,
                    restartDateTime: date
                  };
                });
              }
            }}
            disabledDate={(current: any) => {
              return current && isPastDay(current);
            }}
            value={stateData.restartDateTime ? getMomentObj(stateData.restartDateTime) : ''}
          />
        </FormControl>
      </HStack>
      <HStack>
        <FormControl style={[styles.formElement]}>
          <FormControl.Label style={styles.formLabel}>
            <DisplayText
              textLocalId={'comment'}
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray900}}
            />
          </FormControl.Label>
          <ReactQuill
            className="appointment-notes"
            theme="snow"
            value={stateData.comment || ''}
            onChange={(value: string) => {
              setStateData(prev => {
                return {
                  ...prev,
                  comment: value,
                };
              });
            }}
            modules={RTE_MODULES}
          />
        </FormControl>
      </HStack>
    </Drawer>
  );
};

export default PauseCareJourneyDrawer;

export const styles = StyleSheet.create({
  formElement: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  formLabel: {
    marginLeft: 4,
  },
});
