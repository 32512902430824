import {gql} from '@apollo/client';

export const GetDashboardByAccountId = gql`
  query GetDashboardByAccountId {
    dashboards(
      where: {isAccountDashboard: {_eq: true}}
      order_by: {createdAt: desc}
      offset: 0
      limit: 1
    ) {
      page {
        id
        pageType
        name
        pageSections(
          where: {parentSectionId: {_is_null: true}}
          order_by: {sequence: asc}
        ) {
          id
          heading
          sequence
          additionalAttributes
          parentSectionId
          subHeading
          sectionCategory {
            code
          }
          pageId
          isVisible
          sectionCategoryId
          parentSectionId
          resourceId
          type
          childSections(order_by: {sequence: asc}) {
            id
            heading
            additionalAttributes
            sequence
            parentSectionId
            resource {
              code
              allowedGroupBy
              allowedFilters
            }
            sectionCategory {
              code
            }
            appliedFilter
            subHeading
            pageId
            isVisible
            resourceId
            appliedFilter
            sectionCategoryId
            type
          }
        }
      }
    }
  }
`;
export const GetDefaultPageForDashboard = gql`
  query GetDefaultPageForDashboard {
    pages(
      where: {
        pageTypeData: {code: {_eq: "ACCOUNT_DEFAULT_DASHBOARD"}}
        pageSections: {parentSectionId: {_is_null: false}}
      }
    ) {
      id
      pageType
      name
      pageSections(
        where: {parentSectionId: {_is_null: true}}
        order_by: {sequence: asc}
      ) {
        id
        heading
        sequence
        additionalAttributes
        parentSectionId
        subHeading
        sectionCategory {
          code
        }
        pageId
        isVisible
        sectionCategoryId
        parentSectionId
        resourceId
        type
        childSections(order_by: {sequence: asc}) {
          id
          heading
          sequence
          additionalAttributes
          parentSectionId
          resource {
            code
            allowedGroupBy
            allowedFilters
          }
          sectionCategory {
            code
          }
          appliedFilter
          subHeading
          pageId
          isVisible
          resourceId
          sectionCategoryId
          type
        }
      }
    }
  }
`;

export const CreatePageSections = gql`
  mutation CreatePageSections($pageSections: [page_sections_insert_input!]!) {
    createPageSections(objects: $pageSections) {
      returning {
        id
      }
    }
  }
`;
export const CreatePage = gql`
  mutation CreatePage($pageType: uuid!, $name: String!) {
    createPage(object: {pageType: $pageType, name: $name}) {
      id
    }
  }
`;

export const CreateDashboard = gql`
  mutation CreateDashboard(
    $accountUuid: uuid!
    $pageId: uuid!
    $name: String!
  ) {
    createDashboard(
      object: {pageId: $pageId, accountUuid: $accountUuid, name: $name, isAccountDashboard: true}
    ) {
      id
      name
    }
  }
`;

export const Employee_Aggregate = gql`
  query Employee_Aggregate($employerId: uuid!) {
    employeeAggregate(where: {employerId: {_eq: $employerId}}) {
      aggregate {
        count
      }
    }
  }
`;

export const UPDATE_SECTION_HIDE_SHOW = gql`
  mutation UPDATE_SECTION_HIDE_SHOW($id: uuid!, $isVisible: Boolean!) {
    updatePageSections(where: {id: {_eq: $id}}, _set: {isVisible: $isVisible}) {
      returning {
        id
        isVisible
        sequence
      }
    }
  }
`;
export const UPDATE_SECTION_POSITION = gql`
  mutation UPDATE_SECTION_POSITION($id: uuid!, $sequence: Int!) {
    updatePageSections(where: {id: {_eq: $id}}, _set: {sequence: $sequence}) {
      returning {
        id
        heading
        sequence
      }
    }
  }
`;

export const UPDATE_SECTION_ADDITIONAL = gql`
  mutation UPDATE_SECTION_ADDITIONAL(
    $id: uuid!
    $additionalAttributes: jsonb!
  ) {
    updatePageSections(
      where: {id: {_eq: $id}}
      _set: {additionalAttributes: $additionalAttributes}
    ) {
      returning {
        id
        additionalAttributes
      }
    }
  }
`;

export const GetContactByCount = gql`
  query GetContactByCount($limit: Int = 100) {
    contacts(
      limit: $limit
      where: {isDeleted: {_eq: false}}
      order_by: {updatedAt: desc}
    ) {
      uuid
    }
  }
`;

export default {
  GetDashboardByAccountId,
  GetDefaultPageForDashboard,
  Employee_Aggregate,
  CreatePageSections,
  CreatePage,
  CreateDashboard,
  UPDATE_SECTION_HIDE_SHOW,
  UPDATE_SECTION_POSITION,
  UPDATE_SECTION_ADDITIONAL,
  GetContactByCount,
};
