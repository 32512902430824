import React, {useEffect} from 'react';
import {FILTER_MENU_KEYS, ITabViewProps, ITimelineMap} from '../../interfaces';
import {getMedicationRequestByPatientId} from '../../../../../../services/CommonService/OrderService';
import {getAllOrdersTimelineData } from '../../utils';
import {MedicationRequest} from 'fhir/r4';
import {Spinner, VStack} from 'native-base';
import OrderTimeLine from '../OrderTimeLine/OrderTimeLine';
import { useLazyQuery } from '@apollo/client';
import { GET_USERS_BY_USER_IDS_OR_EXT_USER_IDS } from '../../../../../../services/User/UserQueries';
import { getRequesterId } from '../../../Orders/OrdersAndReports/OrderUtils';

const ErxOrders = (props: ITabViewProps) => {
  const {notesData, personData, searchQuery,activeFilter, handleNoteSelect} = props;
  const patientId = personData?.patientId || personData?.patientUuid;
  const accountLocationUuid = personData?.accountLocationUuid || '';
  const [componentState, setComponentState] = React.useState({
    loading: false,
    timeLineData: [] as ITimelineMap[],
    prevTimeLineData: [] as ITimelineMap[],
    reports: [],
    reportMap: {},
    orders: [],
    medList: [] as MedicationRequest[],
  });

  const [getUserList] = useLazyQuery(
    GET_USERS_BY_USER_IDS_OR_EXT_USER_IDS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.accountUsers.length) {
          const userIdNameMap: any = {};
          data.accountUsers.forEach((item: any) => {
            if (item.user?.uuid) {
              userIdNameMap[item.user.uuid] = item.user.name;
            }
          });
          setComponentState((prev) => {
            const list = prev.medList;
            list.forEach((med) => {
              const userId = getRequesterId(med);
              if (userId && med.requester && userIdNameMap[userId]) {
                med.requester.display = userIdNameMap[userId];
              }
            })
            return {
              ...prev,
              orderList: [...list],
              loading: false,
            }
          });
        }
      },
      onError: () => {
        setComponentState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    }
  );

  const fetchData = () => {
    setComponentState((prev: any) => {
      return {
        ...prev,
        loading: true,
      };
    });
    getMedicationRequestByPatientId(patientId || '', accountLocationUuid)
      .then((response) => {
        const medicationRequests: MedicationRequest[] = [];
        const userUUIDs: string[] = [];
        response?.data?.entry?.forEach((item: {resource: MedicationRequest}) => {
          const medOrder = item.resource;
          if (medOrder) {
            medicationRequests.push(medOrder);
            const userUUID = getRequesterId(medOrder);
            if (userUUID && !userUUIDs.includes(userUUID)) {
              userUUIDs.push(userUUID);
            }
          }
        });
        if (userUUIDs.length) {
          getUserList({
            variables: {
              userIds: userUUIDs.filter((uuid) => uuid !== '-1'),
              externalUserIds: [],
            }
          });
        }
        const completeTimeLineData = getAllOrdersTimelineData(
          [],
          medicationRequests,
          notesData,
          { searchQuery: '', activeFilter: {} },
        );
        const timeLineData = getAllOrdersTimelineData(
          [],
          medicationRequests,
          notesData,
          { searchQuery, activeFilter },
        );
        setComponentState((prev: any) => {
          return {
            ...prev,
            loading: !!userUUIDs.length,
            timeLineData: timeLineData,
            medList: medicationRequests,
            prevTimeLineData: completeTimeLineData,
          };
        });
      })
      .catch((error) => {
        setComponentState((prev: any) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
  };

  useEffect(() => {
    if (searchQuery === '' && !Object.keys(activeFilter || {}).length) {
      setComponentState((prev) => ({
        ...prev,
        timeLineData: componentState.prevTimeLineData,
      }));
    } else {
      const timeLineData = getAllOrdersTimelineData(
        componentState.orders,
        componentState.medList,
        notesData,
        { searchQuery, activeFilter },
      );
      setComponentState((prev) => ({
        ...prev,
        timeLineData: timeLineData,
      }));
    }
  }, [searchQuery,activeFilter]);

  useEffect(() => {
    fetchData();
  }, [props.notesData]);

  return componentState.loading ? (
    <VStack h={500} justifyContent="center">
      <Spinner size="lg" />
    </VStack>
  ) : (
    <OrderTimeLine
      unformmatedContactDetails={props.unformmatedContactDetails}
      personData={personData}
      reports={componentState.reports}
      timeLineMap={componentState.timeLineData}
      orders={componentState.orders}
      handleNoteSelect={handleNoteSelect}
      refreshData={fetchData}
    />
  );
};

export default ErxOrders;
