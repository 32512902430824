import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../styles';

export const styles = StyleSheet.create({
  mainBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: Platform.OS === 'web' ? 48 : 15,
    paddingTop: Platform.OS === 'web' ? 30 : 0,
    paddingBottom: Platform.OS === 'web' ? 36 : 0,
    overflow: Platform.OS === 'web' ? 'hidden' : 'scroll',
    width: Platform.OS === 'web' ? 416 : '100%',
  },

  mainBoxContainerShadow: {
    boxShadow:
      '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
    shadow: 2,
    boxSize: 'border-box',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: Colors.Custom.BorderColor,
  },

  submitElement: {
    backgroundColor: '#825AC7',
    borderColor: '#825AC7',
    borderRadius: 6,
    borderWidth: 1,
    marginTop: 24,
  },
  logoView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
  },
  logoImg: {
    borderRadius: 50,
    width: 32,
    height: 32,
  },
  logoFullWidthImg: {
    width: 170,
    height: 40,
  },
  headerText: {
    fontSize: 20,
    paddingTop: 2,
    paddingBottom: 2,
    textAlign: 'center',
  },
  headerStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 8,
    marginTop: 20,
  },
  labelStyle: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 21,
    color: Colors.Custom.Gray500,
  },
});
