import {IProductDetailSidebarMenu} from './interfaces';

export enum ProductStatus {
  AVAILABLE = 'AVAILABLE',
  PENDING_SALE = 'PENDING SALE',
}

export const PRODUCT_SIDEBAR_CODE = {
  DETAILS: 'DETAILS',
  VARIANTS: 'VARIANTS',
  PROPERTIES: 'PROPERTIES',
  STOCK: 'STOCK',
  PRICES: 'PRICES',
};

export const PRODUCT_DETAIL_SIDEBAR: IProductDetailSidebarMenu[] = [
  {
    menuCode: PRODUCT_SIDEBAR_CODE.DETAILS,
    menuTitle: 'details',
    iconName: 'edit',
  },
  {
    menuCode: PRODUCT_SIDEBAR_CODE.VARIANTS,
    menuTitle: 'variants',
    iconName: 'switcher',
  },
  {
    menuCode: PRODUCT_SIDEBAR_CODE.PROPERTIES,
    menuTitle: 'properties',
    iconName: 'bars',
  },
  {
    menuCode: PRODUCT_SIDEBAR_CODE.STOCK,
    menuTitle: 'stock',
    iconName: 'appstore-o',
  },
  {
    menuCode: PRODUCT_SIDEBAR_CODE.PRICES,
    menuTitle: 'prices',
    iconName: 'dollar-sign',
  },
];

export const initialJourneyDescriptionLength = 300;