import {JourneyTag} from '../Enums';

export const getTagColorScheme = (
  tagName: string
): {background: string; tag: string} | undefined => {
  switch (tagName) {
    case JourneyTag.Prescribable:
      return {tag: 'green', background: 'green.100'};
    case JourneyTag.SelfPurchase:
      return {tag: 'blue', background: 'blue.100'};
    case JourneyTag.ReqTriage:
      return {tag: 'rose', background: 'rose.100'};
    case JourneyTag.ManagerAssessment:
      return {tag: 'purple', background: 'purple.100'};
    default:
      break;
  }
};

export default {getTagColorScheme};
