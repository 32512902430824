import {useLazyQuery} from '@apollo/client';
import {Checkbox, Input, Popover,Tooltip} from 'antd';
import {Spinner, Text, View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {MLOV_CATEGORY} from '../../../../constants';
import {LABEL_TYPE_CODES} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {GetLabelsByLabelTitleAndTypeId, LABELS_BY_ID_IN} from '../../../../services/Labels/LabelsQueries';
import {getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {styles} from './style';
import {ILabelsDropDown, ITaskLabels, ITaskParams} from './Interfaces';
import {debounce} from 'lodash';
import {Colors} from '../../../../styles';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {NoDataFound} from '../../../common/NoDataFound';
import { Dimensions } from 'react-native';


const LabelsDropDown = (props: ILabelsDropDown) => {
  const {taskLabelsIds, onTaskLabelsChange,isDrillDown} = props;
  const [taskLabelsData, setTaskLabelsData] = useState({
    loading: false,
    taskLabelsList: [] as ITaskLabels[],
    selectedTaskLabelList: [] as ITaskLabels[],
    selectedTaskLabels: ''
  });

  const mlovData = useContext(CommonDataContext);
  const {width} = Dimensions.get('window');
  const labels = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  ).filter((item) => {
    return item.code === LABEL_TYPE_CODES.TASK;
  });
  const [getTaskLabelsByTitle] = useLazyQuery(GetLabelsByLabelTitleAndTypeId, {
    fetchPolicy: 'no-cache',
  });
  const [GET_LABELS_BY_ID_IN] = useLazyQuery(LABELS_BY_ID_IN, {
    fetchPolicy: 'no-cache',
  })
  const onSearchTaskLabel = async (searchString?: string) => {
    try {
      setTaskLabelsData((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      let taskLabels = [] as Array<object>;
      if (searchString) {
        const taskLabelParams: ITaskParams = {
          limit: 10,
          offset: 0,
          labelTypeId: labels?.[0].id,
          search: `%${searchString?.trim()}%`,
        };

        const taskLabelsApiResponse = await getTaskLabelsByTitle({
          variables: taskLabelParams,
        });

        taskLabels = taskLabelsApiResponse?.data?.labels || [];
      }
      if (taskLabels?.length === 0) {
        setTaskLabelsData((prev) => {
          return {
            ...prev,
            loading: false,
            taskLabelsList: [...prev?.selectedTaskLabelList],
          };
        });
      } else {
        setTaskLabelsData((prev: any) => {
          if (taskLabelsData?.selectedTaskLabelList?.length) {
            taskLabels = taskLabels.filter((label: any) => {
              return !prev.selectedTaskLabelList.some(
                (selectedLabel: any) => selectedLabel?.title === label?.title
              );
            });
          }
          return {
            ...prev,
            loading: false,
            taskLabelsList: prev?.selectedTaskLabelList
              ? [...prev?.selectedTaskLabelList, ...taskLabels]
              : [...taskLabels],
          };
        });
      }
    } catch (error) {
      setTaskLabelsData((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
    }
  };
  const getSelectedLabels = async () => {

    if (taskLabelsIds?.length) {
      const taskLabelsParams = {
        uuid: {
          _in: [...taskLabelsIds]
        }
      }
      const taskLabelsApiResponse = await GET_LABELS_BY_ID_IN({
        variables: {where: taskLabelsParams},
      });
      const taskLabels = taskLabelsApiResponse?.data?.labels || [];
      let selectedTaskLabels = '';
        if (taskLabels?.length === 1) {
          selectedTaskLabels = `: ${taskLabels?.[0]?.title?.toString()}`;
        } else if (taskLabels?.length > 1) {
          selectedTaskLabels = `: ${taskLabels?.[0]?.title?.toString()} • + ${
            taskLabels?.length - 1
            }`;
        }
      setTaskLabelsData(prev=> {
        return {
          ...prev,
          selectedTaskLabelList: [...taskLabels],
          selectedTaskLabels: selectedTaskLabels,
          taskLabelsList: [...taskLabels]
        }
      })
    }
  }
  useEffect(()=> {
    if (isDrillDown) {
      getSelectedLabels()
    }
  },[])
  useEffect(() => {
    let selectedTaskLabels = '';
    if (taskLabelsIds?.length){
    if (taskLabelsData?.selectedTaskLabelList?.length === 1) {
      selectedTaskLabels = `: ${taskLabelsData?.selectedTaskLabelList?.[0]?.title?.toString()}`;
    } else if (taskLabelsData?.selectedTaskLabelList?.length > 1) {
      selectedTaskLabels = `: ${taskLabelsData?.selectedTaskLabelList?.[0]?.title?.toString()} • + ${
        taskLabelsData?.selectedTaskLabelList?.length - 1
        }`;
    }}
    setTaskLabelsData(prev => {
      return {
        ...prev,
        selectedTaskLabels: selectedTaskLabels
      }
    })
  }, [taskLabelsIds?.length])

  return (
    <View style={styles.labelsDropDown}>
      <Popover
        content={
          <View>
            <Text bold>Search Label</Text>
            <Input
              style={{borderRadius: 4, marginTop: 6}}
              placeholder="Search Labels"
              onChange={(text) => {
                debounce((value) => {
                  onSearchTaskLabel(value?.target?.value.toLowerCase());
                }, 500)(text);
              }}
            ></Input>
            <Checkbox.Group
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              value={taskLabelsIds}
              defaultValue={taskLabelsIds}
              onChange={(values: any) => {
                const selectedTaskLabelList =
                  taskLabelsData?.taskLabelsList?.filter((label) => {
                    return values.includes(label.uuid);
                  });
                setTaskLabelsData((prev) => {
                  return {
                    ...prev,
                    selectedTaskLabelList: selectedTaskLabelList,
                  };
                });

                onTaskLabelsChange(values);
              }}
            >
              {taskLabelsData?.loading ? (
                <Spinner size="sm" marginTop={2} />
              ) : (
                taskLabelsData?.taskLabelsList?.map((option: ITaskLabels) => (
                  <Checkbox
                    style={{
                      margin: 5,
                      fontWeight: 500,
                      color: '#3A485F',
                      fontSize: 15,
                    }}
                    key={option?.uuid}
                    value={option.uuid}
                  >
                    {option?.title}
                  </Checkbox>
                ))
              )}
            </Checkbox.Group>
          </View>
        }
        placement="bottomLeft"
        trigger="click"
      >
        <Tooltip title={taskLabelsData?.selectedTaskLabels} placement="bottom">
          <Text cursor={'pointer'}>
            Labels
            <View style={{maxWidth: props.maxWidth || width / 16}}>
              <Text
                fontWeight="bold"
                textOverflow={'ellipsis'}
                isTruncated
                numberOfLines={1}
                maxW="90%"
              >
                {taskLabelsData?.selectedTaskLabels}
              </Text>
            </View>
          </Text>
        </Tooltip>
        <AntIcon
          name="down"
          color={Colors.Custom.Gray600}
          size={12}
          style={{marginLeft: 4}}
        />
      </Popover>
    </View>
  );
};

export default LabelsDropDown;
