import {InputNumber} from 'antd';
import {AxiosResponse} from 'axios';
import {CheckIcon, HStack, Select, View, VStack,Text} from 'native-base';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {BUTTON_TYPE} from '../../../../../../constants/StringConst';
import {
  IProductVariantData,
  IProductVariantRelationshipCommonData,
} from '../../../../../../services/ProductAndServices/interfaces/ProductVariant';
import {IStockItemDataApiResp} from '../../../../../../services/ProductAndServices/interfaces/StockItem';
import {addOrUpdateStock} from '../../../../../../services/ProductAndServices/ProductAndServicesAPI';
import {Colors} from '../../../../../../styles';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import HeaderBar from '../../../../../common/HeaderBar/HeaderBar';
import ModalActionBtn from '../../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import {IAddStockState, IAddStockToLocation} from './interfaces';
import {validateStockView} from './StockUtils';

export const AddStockToLocation = (props: IAddStockToLocation) => {
  const {productVariantList, stockLocationList, onStockAddToLocation} = props;
  const intl = useIntl();
  const finalVariantDataList = productVariantList.filter((singleItem) => {
    return !singleItem.attributes.is_master;
  });
  const masterVariant =
    productVariantList.find((singleItem) => {
      return singleItem.attributes.is_master;
    }) || ({} as any);
  const [addStockState, setAddStockState] = useState<IAddStockState>({
    quantity: '',
    stockLocationId: stockLocationList?.[0]?.id || '',
    variantId: masterVariant?.id || '',
    backOrderable: false,
    countOnHand: masterVariant?.attributes?.total_on_hand || 1,
  });
  const [stateData, setStateData] = useState({
    errorMessage: ''
  })
  const getVariantIfPresent = (): IProductVariantData => {
    let selectProductVariant = {} as IProductVariantData;
    if (
      finalVariantDataList.length === 0 &&
      productVariantList.length === 1 &&
      productVariantList[0]?.attributes?.is_master
    ) {
      selectProductVariant = productVariantList[0];
    } else if (productVariantList?.length) {
      selectProductVariant =
        productVariantList.find((singleVariant) => {
          return singleVariant.id === addStockState.variantId;
        }) || ({} as IProductVariantData);
    } else {
    }
    return selectProductVariant;
  };

  const getStockLocationIfPresent = (
    selectProductVariant: IProductVariantData
  ): IProductVariantRelationshipCommonData => {
    let stockLocationData = {} as IProductVariantRelationshipCommonData;
    const stockLocationList =
      selectProductVariant?.relationships?.stock_locations?.data || [];
    if (stockLocationList?.length) {
      stockLocationData =
        stockLocationList.find((locationData) => {
          return locationData.id === addStockState.stockLocationId;
        }) || ({} as IProductVariantRelationshipCommonData);
    }
    return stockLocationData;
  };

  const getStockItemDataFromLocationId = (
    selectProductVariant: IProductVariantData,
    locationId: any
  ): string => {
    const stockItemsList =
      selectProductVariant?.relationships?.stock_items?.data || [];
    const stockItemData =
      stockItemsList.find((singleStockItem) => {
        return (
          singleStockItem.relationships?.stock_location?.data.id === locationId
        );
      }) || ({} as IProductVariantRelationshipCommonData);
    return stockItemData?.id || '';
  };

  const addStockToLocation = async () => {
    if(!!validateStockView(addStockState)){
      setStateData(prev=> {
        return{
          ...prev,
          errorMessage: validateStockView(addStockState)
        }
      })
      setAddStockState(prev=> {
        return{
          ...prev,
          countOnHand: 0
        }
      })
      return
    }
    setStateData(prev=> {
      return{
        ...prev,
        errorMessage: ''
      }
    })
    const selectProductVariant = getVariantIfPresent();
    const locationData = getStockLocationIfPresent(selectProductVariant);
    const stockItemId = getStockItemDataFromLocationId(
      selectProductVariant,
      locationData?.id
    );
    let countOnHand = addStockState.countOnHand;
    if (selectProductVariant?.attributes?.total_on_hand) {
      const total_on_hand = parseInt(
        selectProductVariant?.attributes?.total_on_hand + ''
      );
      if (total_on_hand) {
        countOnHand = countOnHand + total_on_hand;
      }
    }
    if (stockItemId) {
      const variantResp: AxiosResponse<IStockItemDataApiResp> =
        await addOrUpdateStock(
          {
            variant_id: addStockState.variantId,
            stock_location_id: addStockState.stockLocationId,
            count_on_hand: countOnHand,
            // backorderable: addStockState.backOrderable,
          },
          stockItemId
        );
      if (variantResp?.data?.data?.id) {
        onStockAddToLocation(COMMON_ACTION_CODES.UPDATE, {});
      }
    } else {
      const variantResp: AxiosResponse<IStockItemDataApiResp> =
        await addOrUpdateStock(
          {
            variant_id: addStockState.variantId,
            stock_location_id: addStockState.stockLocationId,
            count_on_hand: addStockState.countOnHand,
            backorderable: addStockState.backOrderable,
          },
          stockItemId
        );
      if (variantResp?.data?.data?.id) {
        onStockAddToLocation(COMMON_ACTION_CODES.ADD, {});
      }
    }
  };

  // const getVariantName = (singleVariant: IProductVariantData): string => {
  //   return (
  //     (singleVariant?.attributes?.sku || '') +
  //     (singleVariant?.attributes?.options_text || '')
  //   );
  // };

  return (
    <View paddingBottom={2}>
      <VStack justifyContent={'center'}>
        <HeaderBar title="addStock" />
        {stateData.errorMessage ?<HStack flex={1} p={2} pl={6}> <Text size={'xsMedium'} style={{color: Colors.error['500']}}>{stateData.errorMessage}</Text></HStack> : <></>}

        <HStack flex={1} p={2} pl={6}>
          <View flex={0.2}>
            <DisplayText size={'mdMedium'} textLocalId="quantity" />
          </View>
          <View flex={0.3}>
            <DisplayText size={'mdMedium'} textLocalId="stockLocation" />
          </View>
        </HStack>

        <HStack flex={1} p={2} pl={6}>
          <VStack flex={0.2} mt={1}>
            <InputNumber
              defaultValue={addStockState.countOnHand || 1}
              onChange={(countOnHand) => {
                setAddStockState((prev:any) => {
                  return {
                    ...prev,
                    countOnHand: countOnHand,
                  };
                });
              }}
            />
          </VStack>
          <VStack flex={0.3}>
            <Select
              minWidth="200"
              accessibilityLabel="Choose Stock Location"
              placeholder="Choose Stock Location"
              _selectedItem={{
                endIcon: <CheckIcon size={5} />,
              }}
              mt="1"
              defaultValue={stockLocationList?.[0]?.id || ''}
              onValueChange={(stockLocationId) => {
                setAddStockState((prev) => {
                  return {
                    ...prev,
                    stockLocationId: stockLocationId,
                  };
                });
              }}
            >
              {stockLocationList.map((singleLocation) => {
                return (
                  <Select.Item
                    label={singleLocation.attributes.name}
                    value={singleLocation.id}
                  />
                );
              })}
            </Select>
          </VStack>
          {/* <VStack flex={0.3} ml={5}>
              <Select
                minWidth="200"
                accessibilityLabel="Choose Variant"
                placeholder="Choose Variant"
                _selectedItem={{
                  endIcon: <CheckIcon size={5} />,
                }}
                mt="1"
                defaultValue={finalVariantDataList?.[0]?.id || ''}
                onValueChange={(variantId) => {
                  setAddStockState((prev) => {
                    return {
                      ...prev,
                      variantId: variantId,
                    };
                  });
                }}
              >
                {finalVariantDataList.map((singleVariant) => {
                  return (
                    <Select.Item
                      key={singleVariant.id}
                      label={getVariantName(singleVariant) || ''}
                      value={singleVariant.id}
                    />
                  );
                })}
              </Select>
            </VStack> */}
          <VStack ml={10}>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    addStockToLocation();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({id: 'addStock'}),
                  withRightBorder: false,
                }}
              ></FoldButton>
          </VStack>
        </HStack>
      </VStack>
    </View>
  );
};
