import React, {useEffect, useState} from 'react';
import {Stack, Text} from 'native-base'; // Assuming NativeBase is being used
import {Colors} from './styles';
import Feather from 'react-native-vector-icons/Feather';
import {Popover} from 'antd';
import './components/RafScore/PopOverRafScore.css';
import {getPatientCondition} from './services/CommonService/AidBoxService';
import {
  getBackgroundColorCodeForHCC,
  getColorCodeForHCC,
} from './sidecar/common/SideCardCommonUtils';
import { RAFScorePopoverContent } from './components/RafScore/RAFScorePopoverContent';

interface IRAFScoreElementProps {
  hccScore: number | null;
  hccScoreDiff: number;
  selectedRecord: any;
  patientId: string;
  practiceLocationId: string;
}
export const RAFScoreElement = (props: IRAFScoreElementProps) => {
  const {hccScore, hccScoreDiff} = props;
  const [ICDConditions, setICDConditions] = useState<any[]>([]);
  const hccScoreDiffValue =
    hccScoreDiff > 0 ? `+${hccScoreDiff}` : hccScoreDiff;

  const fetchICDConditions = async () => {
    try {
      const response = await getPatientCondition(
        props?.patientId,
        props?.practiceLocationId || ''
      );
      const mappedConditions = response?.data?.entry?.map((entry: any) => {
        const recordedDate = entry?.resource?.meta?.lastUpdated || '';
        return entry?.resource?.code?.coding?.map((coding: any) => ({
          recordedDate: recordedDate,
          code: coding?.code,
          display: coding?.display,
        }));
      });
      const flatConditions = mappedConditions?.flat();
      setICDConditions(flatConditions);
    } catch (error) {
      console.error('Error fetching ICD conditions:', error);
    }
  };

  useEffect(() => {
    fetchICDConditions();
  }, [props?.patientId, props?.practiceLocationId]);

  const hccScoreColor = getColorCodeForHCC(hccScore || 0);
  const hccBackgroundColor = getBackgroundColorCodeForHCC(hccScore || 0);

  return (
    <Stack direction={'column'}>
      <Popover
        overlayClassName='raf-score-popover'
        overlayInnerStyle={{padding: 0, borderRadius: 16}}
        style={{borderRadius: 16}}
        placement={'bottom'}
        className='raf-score-popover'
        trigger="click"
        content={
          <RAFScorePopoverContent
            contactScore={props?.selectedRecord?.contactData?.contactScore}
            contactScoreAudits={props?.selectedRecord?.contactData?.contactScoreAudit}
            icdConditionsList={ICDConditions}
          />
        }
      >
        {hccScore !== null ? (
          <Stack
            direction={'row'}
            alignItems={'center'}
            backgroundColor={hccBackgroundColor}
            justifyContent={'center'}
            borderRadius={4}
          >
            <Text
              wordBreak={'break-word'}
              size={'smLight'}
              color={hccScoreColor}
              fontSize={14}
              alignSelf={'center'}
            >
              {hccScore}
            </Text>
            <Stack
              direction={'row'}
              alignItems={'center'}
              marginLeft={1}
            >
            {(hccScore || hccScore === 0) && !!hccScoreDiff && (
              <>
                {hccScoreDiff > 0 && (
                  <Feather
                    name="arrow-up"
                    size={15}
                    color={hccScoreColor}
                  />
                )}
                {hccScoreDiff < 0 && (
                  <Feather
                    name="arrow-down"
                    size={15}
                    color={hccScoreColor}
                  />
                )}
                </>
              )}
              </Stack>
          </Stack>
        ) : (
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Text
              wordBreak={'break-word'}
              size={'smLight'}
              color={Colors.Custom.Gray500}
              fontSize={14}
              alignSelf={'center'}
            >
              {'-'}
            </Text>
          </Stack>
        )}
      </Popover>
    </Stack>
  );
};
