import BaseService from "../../../services/CommonService/BaseService";

export const upgradeCallToConference = (bodyParams: {
    direction: string;
    sid: string;
  }) => {
    const baseService = BaseService.getSharedInstance();
    const axiosService = baseService.axios;
    return axiosService.post(`/cloud-telephony/conference`, bodyParams);
};

export const addParticipantToConference = (bodyParams: {
    conferenceSid: string;
    party: string;
    CallerID: string;
  }) => {
    const baseService = BaseService.getSharedInstance();
    const axiosService = baseService.axios;
    return axiosService.post(`/cloud-telephony/conference/participants`, bodyParams);
};

export const removeParticipant = (bodyParams: {
    conferenceSid: string;
    partySid: string;
  }) => {
    const baseService = BaseService.getSharedInstance();
    const axiosService = baseService.axios;
    return axiosService.post(`/cloud-telephony/conference/call/hangup`, bodyParams);
};

export const putParticipantOnHold = (bodyParams: {
    conferenceSid: string;
    partySid: string;
  }) => {
    const baseService = BaseService.getSharedInstance();
    const axiosService = baseService.axios;
    return axiosService.post(`/cloud-telephony/conference/call/hold`, bodyParams);
};

export const getParticipantsByCallSid = (callSid: string) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.get(`/cloud-telephony/conference/call/${callSid}`);
};

export const getParticipantsByConferenceSid = (conferenceSid: string) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.get(`/cloud-telephony/conference/participants?sid=${conferenceSid}`);
};

export const getCallSidDetail = (callSid: string) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.get(`/cloud-telephony/call/${callSid}`);
};
