export const COMMUNICATION_STATS_DUMMY_DATA = {
  result: [
    {
      displayText: 'Enquiry calls',
      countNum: 1,
      month: 'January',
      type: 'Enquiry calls',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 3,
      month: 'January',
      type: 'Emergency Assistance',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 2,
      month: 'January',
      type: 'Prescription Request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 1,
      month: 'January',
      type: 'Prescription request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 3,
      month: 'January',
      type: 'Emergency Assistance',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Enquiry calls',
      countNum: 3,
      month: 'January',
      type: 'Enquiry calls',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Enquiry calls',
      countNum: 2,
      month: 'January',
      type: 'Enquiry calls',
      code: 'OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Appointment booked',
      countNum: 3,
      month: 'January',
      type: 'Appointment booked',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Appointment booked',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Appointment booked',
      countNum: 2,
      month: 'January',
      type: 'Appointment booked',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Appointment booked',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Enquiry calls',
      countNum: 2,
      month: 'January',
      type: 'Enquiry calls',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 2,
      month: 'January',
      type: 'Prescription Request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 3,
      month: 'January',
      type: 'Emergency care',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 2,
      month: 'January',
      type: 'Emergency care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 3,
      month: 'January',
      type: 'Other Clinical Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 2,
      month: 'January',
      type: 'Urgent Care',
      code: 'OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Appointment booked',
      countNum: 1,
      month: 'January',
      type: 'Appointment booked',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Appointment booked',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 2,
      month: 'January',
      type: 'Prescription Request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 1,
      month: 'January',
      type: 'Emergency care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 1,
      month: 'January',
      type: 'Other Clinical Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 1,
      month: 'January',
      type: 'Virtual/Text Consultation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 3,
      month: 'January',
      type: 'Emergency care',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 2,
      month: 'January',
      type: 'Urgent Care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 3,
      month: 'January',
      type: 'Imaging Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 3,
      month: 'January',
      type: 'Referral or care navigation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 1,
      month: 'January',
      type: 'Referral or care navigation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 1,
      month: 'January',
      type: 'New Patient Registration and Enrollment',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 2,
      month: 'January',
      type: 'Other Clinical Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 3,
      month: 'January',
      type: 'Other Clinical Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-01-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 2,
      month: 'February',
      type: 'Other Clinical Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 2,
      month: 'February',
      type: 'Imaging Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 3,
      month: 'February',
      type: 'Other Administrative',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 2,
      month: 'February',
      type: 'Prescription Request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 2,
      month: 'February',
      type: 'Emergency Assistance',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 2,
      month: 'February',
      type: 'Imaging Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 2,
      month: 'February',
      type: 'Urgent Care',
      code: 'OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 3,
      month: 'February',
      type: 'Prescription request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 1,
      month: 'February',
      type: 'Prescription request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 3,
      month: 'February',
      type: 'Virtual/Text Consultation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 3,
      month: 'February',
      type: 'Emergency care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 2,
      month: 'February',
      type: 'Emergency Assistance',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Enquiry calls',
      countNum: 2,
      month: 'February',
      type: 'Enquiry calls',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Enquiry calls',
      countNum: 1,
      month: 'February',
      type: 'Enquiry calls',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 2,
      month: 'February',
      type: 'Other Administrative',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 1,
      month: 'February',
      type: 'Prescription request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 2,
      month: 'February',
      type: 'Prescription request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 1,
      month: 'February',
      type: 'Virtual/Text Consultation',
      code: 'OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 3,
      month: 'February',
      type: 'Virtual/Text Consultation',
      code: 'OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Appointment booked',
      countNum: 2,
      month: 'February',
      type: 'Appointment booked',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Appointment booked',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 3,
      month: 'February',
      type: 'New Patient Registration and Enrollment',
      code: 'OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 3,
      month: 'February',
      type: 'Prescription request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 1,
      month: 'February',
      type: 'Prescription request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 2,
      month: 'February',
      type: 'Virtual/Text Consultation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 2,
      month: 'February',
      type: 'Other Clinical Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 3,
      month: 'February',
      type: 'Emergency Assistance',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 2,
      month: 'February',
      type: 'Other Administrative',
      code: 'OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 3,
      month: 'February',
      type: 'Other Clinical Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-02-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 1,
      month: 'March',
      type: 'Emergency care',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Enquiry calls',
      countNum: 3,
      month: 'March',
      type: 'Enquiry calls',
      code: 'OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 1,
      month: 'March',
      type: 'Urgent Care',
      code: 'OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 3,
      month: 'March',
      type: 'Imaging Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 1,
      month: 'March',
      type: 'Urgent Care',
      code: 'OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Appointment booked',
      countNum: 1,
      month: 'March',
      type: 'Appointment booked',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Appointment booked',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 1,
      month: 'March',
      type: 'New Patient Registration and Enrollment',
      code: 'OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 1,
      month: 'March',
      type: 'Other Clinical Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 3,
      month: 'March',
      type: 'Referral or care navigation',
      code: 'OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 3,
      month: 'March',
      type: 'Referral or care navigation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 1,
      month: 'March',
      type: 'Other Administrative',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 1,
      month: 'March',
      type: 'Imaging Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 1,
      month: 'March',
      type: 'Virtual/Text Consultation',
      code: 'OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 3,
      month: 'March',
      type: 'Virtual/Text Consultation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 3,
      month: 'March',
      type: 'Emergency Assistance',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 3,
      month: 'March',
      type: 'Imaging Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 1,
      month: 'March',
      type: 'Prescription request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 1,
      month: 'March',
      type: 'Emergency Assistance',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 1,
      month: 'March',
      type: 'Other Administrative',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 2,
      month: 'March',
      type: 'Prescription request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 3,
      month: 'March',
      type: 'Emergency Assistance',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 3,
      month: 'March',
      type: 'Virtual/Text Consultation',
      code: 'OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 2,
      month: 'March',
      type: 'New Patient Registration and Enrollment',
      code: 'OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 1,
      month: 'March',
      type: 'Urgent Care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Appointment booked',
      countNum: 1,
      month: 'March',
      type: 'Appointment booked',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Appointment booked',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 1,
      month: 'March',
      type: 'Virtual/Text Consultation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 2,
      month: 'March',
      type: 'Other Administrative',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 3,
      month: 'March',
      type: 'Other Administrative',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-03-01',
      },
    },
    {
      displayText: 'Appointment booked',
      countNum: 2,
      month: 'April',
      type: 'Appointment booked',
      code: 'OFFICE_HOURS',
      colorType: 'Appointment booked',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 2,
      month: 'April',
      type: 'Other Administrative',
      code: 'OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 1,
      month: 'April',
      type: 'Other Administrative',
      code: 'OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 2,
      month: 'April',
      type: 'Urgent Care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 2,
      month: 'April',
      type: 'Other Clinical Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 1,
      month: 'April',
      type: 'Imaging Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Enquiry calls',
      countNum: 3,
      month: 'April',
      type: 'Enquiry calls',
      code: 'OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 3,
      month: 'April',
      type: 'Emergency care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 3,
      month: 'April',
      type: 'Other Administrative',
      code: 'OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 1,
      month: 'April',
      type: 'Emergency care',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 1,
      month: 'April',
      type: 'Urgent Care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 3,
      month: 'April',
      type: 'Referral or care navigation',
      code: 'OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 2,
      month: 'April',
      type: 'Urgent Care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 1,
      month: 'April',
      type: 'Emergency care',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 1,
      month: 'April',
      type: 'Urgent Care',
      code: 'OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Enquiry calls',
      countNum: 1,
      month: 'April',
      type: 'Enquiry calls',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 2,
      month: 'April',
      type: 'Emergency Assistance',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 3,
      month: 'April',
      type: 'Emergency care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 3,
      month: 'April',
      type: 'Other Administrative',
      code: 'OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 1,
      month: 'April',
      type: 'Emergency Assistance',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 2,
      month: 'April',
      type: 'Prescription request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 1,
      month: 'April',
      type: 'Emergency care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 1,
      month: 'April',
      type: 'Imaging Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Enquiry calls',
      countNum: 3,
      month: 'April',
      type: 'Enquiry calls',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 2,
      month: 'April',
      type: 'Virtual/Text Consultation',
      code: 'OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 2,
      month: 'April',
      type: 'Imaging Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 2,
      month: 'April',
      type: 'Other Clinical Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 1,
      month: 'April',
      type: 'Urgent Care',
      code: 'OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-04-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 3,
      month: 'May',
      type: 'Virtual/Text Consultation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 2,
      month: 'May',
      type: 'Emergency Assistance',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 2,
      month: 'May',
      type: 'Emergency Assistance',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 2,
      month: 'May',
      type: 'Prescription request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Appointment booked',
      countNum: 2,
      month: 'May',
      type: 'Appointment booked',
      code: 'OFFICE_HOURS',
      colorType: 'Appointment booked',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 3,
      month: 'May',
      type: 'Prescription Request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 1,
      month: 'May',
      type: 'Virtual/Text Consultation',
      code: 'OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 1,
      month: 'May',
      type: 'Prescription request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 2,
      month: 'May',
      type: 'New Patient Registration and Enrollment',
      code: 'OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 2,
      month: 'May',
      type: 'Prescription request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 3,
      month: 'May',
      type: 'Prescription Request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 2,
      month: 'May',
      type: 'Emergency Assistance',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 3,
      month: 'May',
      type: 'Referral or care navigation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 2,
      month: 'May',
      type: 'New Patient Registration and Enrollment',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 3,
      month: 'May',
      type: 'Virtual/Text Consultation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 3,
      month: 'May',
      type: 'Prescription Request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 1,
      month: 'May',
      type: 'Urgent Care',
      code: 'OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 3,
      month: 'May',
      type: 'Other Administrative',
      code: 'OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 2,
      month: 'May',
      type: 'New Patient Registration and Enrollment',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 3,
      month: 'May',
      type: 'Other Administrative',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Enquiry calls',
      countNum: 1,
      month: 'May',
      type: 'Enquiry calls',
      code: 'OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Enquiry calls',
      countNum: 1,
      month: 'May',
      type: 'Enquiry calls',
      code: 'OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 3,
      month: 'May',
      type: 'Prescription Request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 1,
      month: 'May',
      type: 'Prescription request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 2,
      month: 'May',
      type: 'New Patient Registration and Enrollment',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 2,
      month: 'May',
      type: 'Emergency Assistance',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 3,
      month: 'May',
      type: 'Referral or care navigation',
      code: 'OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 1,
      month: 'May',
      type: 'Other Administrative',
      code: 'OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-05-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 3,
      month: 'June',
      type: 'Referral or care navigation',
      code: 'OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 2,
      month: 'June',
      type: 'Virtual/Text Consultation',
      code: 'OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 3,
      month: 'June',
      type: 'New Patient Registration and Enrollment',
      code: 'OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 2,
      month: 'June',
      type: 'Emergency Assistance',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 2,
      month: 'June',
      type: 'Prescription request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 1,
      month: 'June',
      type: 'Imaging Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 3,
      month: 'June',
      type: 'Other Clinical Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 2,
      month: 'June',
      type: 'Other Administrative',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 2,
      month: 'June',
      type: 'Referral or care navigation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 3,
      month: 'June',
      type: 'Prescription request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 2,
      month: 'June',
      type: 'Emergency care',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 1,
      month: 'June',
      type: 'Other Clinical Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 3,
      month: 'June',
      type: 'New Patient Registration and Enrollment',
      code: 'OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 1,
      month: 'June',
      type: 'New Patient Registration and Enrollment',
      code: 'OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 2,
      month: 'June',
      type: 'Referral or care navigation',
      code: 'OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 2,
      month: 'June',
      type: 'Referral or care navigation',
      code: 'OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 2,
      month: 'June',
      type: 'Virtual/Text Consultation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 3,
      month: 'June',
      type: 'Prescription Request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Appointment booked',
      countNum: 1,
      month: 'June',
      type: 'Appointment booked',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Appointment booked',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 1,
      month: 'June',
      type: 'New Patient Registration and Enrollment',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 3,
      month: 'June',
      type: 'New Patient Registration and Enrollment',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 1,
      month: 'June',
      type: 'Other Clinical Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 2,
      month: 'June',
      type: 'Prescription Request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 1,
      month: 'June',
      type: 'Imaging Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 2,
      month: 'June',
      type: 'Other Clinical Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 3,
      month: 'June',
      type: 'Referral or care navigation',
      code: 'OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 3,
      month: 'June',
      type: 'Prescription Request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Enquiry calls',
      countNum: 3,
      month: 'June',
      type: 'Enquiry calls',
      code: 'OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-06-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 3,
      month: 'July',
      type: 'Prescription Request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 1,
      month: 'July',
      type: 'Virtual/Text Consultation',
      code: 'OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 3,
      month: 'July',
      type: 'Prescription request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Virtual/Text Consultation',
      countNum: 3,
      month: 'July',
      type: 'Virtual/Text Consultation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Virtual/Text Consultation',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 1,
      month: 'July',
      type: 'Other Clinical Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 3,
      month: 'July',
      type: 'Emergency care',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Enquiry calls',
      countNum: 3,
      month: 'July',
      type: 'Enquiry calls',
      code: 'OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 1,
      month: 'July',
      type: 'Referral or care navigation',
      code: 'OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Appointment booked',
      countNum: 1,
      month: 'July',
      type: 'Appointment booked',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Appointment booked',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 1,
      month: 'July',
      type: 'Other Administrative',
      code: 'OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 1,
      month: 'July',
      type: 'Imaging Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 1,
      month: 'July',
      type: 'Other Clinical Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 2,
      month: 'July',
      type: 'Prescription Request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 2,
      month: 'July',
      type: 'Urgent Care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 2,
      month: 'July',
      type: 'Emergency Assistance',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 2,
      month: 'July',
      type: 'New Patient Registration and Enrollment',
      code: 'OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 2,
      month: 'July',
      type: 'Referral or care navigation',
      code: 'OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 1,
      month: 'July',
      type: 'Imaging Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 2,
      month: 'July',
      type: 'Prescription Request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 3,
      month: 'July',
      type: 'Prescription Request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 1,
      month: 'July',
      type: 'Emergency Assistance',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 2,
      month: 'July',
      type: 'Prescription Request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 1,
      month: 'July',
      type: 'Imaging Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Appointment booked',
      countNum: 3,
      month: 'July',
      type: 'Appointment booked',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Appointment booked',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 3,
      month: 'July',
      type: 'Other Administrative',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 2,
      month: 'July',
      type: 'Emergency care',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Prescription Request',
      countNum: 1,
      month: 'July',
      type: 'Prescription Request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription Request',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 3,
      month: 'July',
      type: 'Emergency Assistance',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-07-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 3,
      month: 'August',
      type: 'Urgent Care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 3,
      month: 'August',
      type: 'Emergency care',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 2,
      month: 'August',
      type: 'Emergency Assistance',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Emergency care',
      countNum: 2,
      month: 'August',
      type: 'Emergency care',
      code: 'OFFICE_HOURS',
      colorType: 'Emergency care',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 3,
      month: 'August',
      type: 'Imaging Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 2,
      month: 'August',
      type: 'Other Administrative',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 3,
      month: 'August',
      type: 'New Patient Registration and Enrollment',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 3,
      month: 'August',
      type: 'Other Clinical Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 2,
      month: 'August',
      type: 'New Patient Registration and Enrollment',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Appointment booked',
      countNum: 2,
      month: 'August',
      type: 'Appointment booked',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Appointment booked',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Enquiry calls',
      countNum: 3,
      month: 'August',
      type: 'Enquiry calls',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Enquiry calls',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 1,
      month: 'August',
      type: 'Imaging Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 1,
      month: 'August',
      type: 'Imaging Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 3,
      month: 'August',
      type: 'Other Administrative',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 1,
      month: 'August',
      type: 'Other Clinical Report Status',
      code: 'OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 1,
      month: 'August',
      type: 'Referral or care navigation',
      code: 'OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 1,
      month: 'August',
      type: 'Prescription request',
      code: 'OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Other Clinical Report Status',
      countNum: 2,
      month: 'August',
      type: 'Other Clinical Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Other Clinical Report Status',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 2,
      month: 'August',
      type: 'Imaging Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 3,
      month: 'August',
      type: 'New Patient Registration and Enrollment',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Prescription request',
      countNum: 1,
      month: 'August',
      type: 'Prescription request',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Prescription request',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 1,
      month: 'August',
      type: 'Urgent Care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Other Administrative',
      countNum: 2,
      month: 'August',
      type: 'Other Administrative',
      code: 'OFFICE_HOURS',
      colorType: 'Other Administrative',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'New Patient Registration and Enrollment',
      countNum: 3,
      month: 'August',
      type: 'New Patient Registration and Enrollment',
      code: 'OFFICE_HOURS',
      colorType: 'New Patient Registration and Enrollment',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Imaging Report Status',
      countNum: 1,
      month: 'August',
      type: 'Imaging Report Status',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Imaging Report Status',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Emergency Assistance',
      countNum: 3,
      month: 'August',
      type: 'Emergency Assistance',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Emergency Assistance',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Urgent Care',
      countNum: 3,
      month: 'August',
      type: 'Urgent Care',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Urgent Care',
      monthDt: {
        value: '2023-08-01',
      },
    },
    {
      displayText: 'Referral or care navigation',
      countNum: 3,
      month: 'August',
      type: 'Referral or care navigation',
      code: 'OUT_OF_OFFICE_HOURS',
      colorType: 'Referral or care navigation',
      monthDt: {
        value: '2023-08-01',
      },
    },
  ],
  additionalData: {
    totalSavings: 17500,
    memberCount: 3236,
    recordCount: 13236,
  },
};
