export const SEND_EMAIL_OR_SMS_IF_CONTACT_IS_OFFLINE = [
  {
    label: 'Enable',
    key: 'Enables',
    options: [
      {
        value: 'VERIFIED',
        code: 'VERIFIED',
        label: 'Send to only verified member'
      },
      {
        value: 'NON_VERIFIED',
        code: 'NON_VERIFIED',
        label: 'Send to all'
      }
    ]
  },
  {
    label: 'Disable',
    key: 'Disables',
    options: [
      {
        value: 'OFF',
        code: 'OFF',
        label: "Don't Send"
      }
    ]
  },
]

export const DEFAULT_ADD_OR_REMOVE_ACTION_TYPE = {
  YES_ALL: 'YESALL',
  NO_ALL: 'NOALL',
}