import {ColumnsType} from 'antd/es/table';
import {Text} from 'native-base';
import CommunicationTypesTableActions from './CommunicationTypesTableActions';
import {formatDate} from './CommunicationTypesUtils';
import {
  ICommunicationType,
  ICommunicationTypeTableColumnData,
} from './interfaces';

export const getCommunicationTypesTableColumns = (
  data: ICommunicationTypeTableColumnData
) => {
  const {onActionPerformed} = data;

  const columns: ColumnsType<ICommunicationType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: '20%',
      render(value, record, index) {
        return <Text>{record.name}</Text>;
      },
    },

    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '37%',
      render(value, record, index) {
        return <Text>{record.description || '-'}</Text>;
      },
    },

    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '20%',
      render(value, record, index) {
        const date = formatDate(record.createdAt || '');
        return <Text>{date}</Text>;
      },
    },

    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '13%',
      render(value, record, index) {
        return <Text>{record.createdBy}</Text>;
      },
    },
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   width: '10%',
    //   render(value, record, index) {
    //     return (
    //       <CommunicationTypesTableActions
    //         onActionPerformed={onActionPerformed}
    //         record={record}
    //       />
    //     );
    //   },
    // },
  ];
  return columns;
};
