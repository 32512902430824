import {Box, HStack} from 'native-base';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {styles} from '../../CampaignStyles';

export const CampaignStepOneScreen = () => {
  return (
    <HStack style={styles.boxesStack} space={8}>
      <Box shadow={8} style={styles.boxes}>
        <DisplayText textLocalId="messenger" />
      </Box>
      <Box shadow={8} style={styles.boxes}>
        <DisplayText textLocalId="sms" />
      </Box>
      <Box shadow={8} style={styles.boxes}>
        <DisplayText textLocalId="email" />
      </Box>
    </HStack>
  );
};
