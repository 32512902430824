import { getFormattedGroupData } from "../../../../ContentManagement/CampaignManagement/utils";

export const formatAndCombinePrevAndNewOptions = (
  response: any[]
): {
  dropdownList: any[];
  selectedPopGrps: {id:string,label:string,value:string}[];
} => {
  const finalData: any[] = [];
  const formmattedSetOfPrevGroups = getFormattedGroupData(
    response?.[0]?.data,
    []
  );
  const prevGroupUUIDs = new Set(
    formmattedSetOfPrevGroups?.map((item: any) => item?.id)
  );
  const formmattedSetOfNewGroups = getFormattedGroupData(
    response?.[1]?.data?.data,
    []
  );
  finalData.push(...formmattedSetOfPrevGroups);

  formmattedSetOfNewGroups.filter((group: any) => {
    if (!prevGroupUUIDs.has(group?.id)) {
      finalData.push(group);
    }
  });

  return {
    dropdownList: finalData,
    selectedPopGrps: formmattedSetOfPrevGroups.map((item:any) => ({
      id: item.id,
      key: item.id,
      label: item.name,
    })),
  };
};