import { Text, View, VStack } from 'native-base';
import TaskDueItemInnerPart from './TaskDueItemInnerPart';
import { styles } from "./TaskDueTodayStyle";

const TaskDueTodaylListItem = (props: any) => {
    const { data, onActionPerformed } = props;
    const getHeaderTitle = () => {
        if(data?.type === 'task'){
            return 'Task'
        }else if(data?.type === 'appointment'){
            return 'Appointment'
        }else if(data?.type === 'conversation'){
            return 'Conversation'
        }else if(data?.type === 'form'){
            return 'Form'
        }
        return ''
    }

    return (
     <>
        {/* {data?.taskList?.status?.code !== 'completed' && */}
        <VStack style={styles.listItem}>
            <View flex={1} backgroundColor={'#F2F4F7'} borderBottomColor={'#D0D0D0'}
                    borderBottomWidth= {0.3} height={'52px'} borderTopLeftRadius={8} borderTopRightRadius={8}>
                <Text style={styles.itemHeader}>{getHeaderTitle()}</Text>
            </View>
           <TaskDueItemInnerPart
                itemData={data}
                onActionPerformed={(data:any)=> {
                    onActionPerformed(data);
                }}
           />
        </VStack>
         {/* } */}
     </>
    );
};

export default TaskDueTodaylListItem;
