import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {ICommonSvgProps} from './interfaces';
import {Image} from 'react-native';

const NoCallLogsSvg = (props: ICommonSvgProps) => {
  return (
    <>
      {isWeb() ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="313"
          height="155"
          fill="none"
          viewBox="0 0 313 155"
        >
          <g filter="url(#filter0_d_166_78)">
            <path
              fill="#F2F4F7"
              d="M106.01 60.283c.45 10.791 3.64 36.532 22.905 55.801 19.269 19.265 45.009 22.455 55.801 22.905a11.207 11.207 0 008.397-3.274l10.903-10.9a6.769 6.769 0 000-9.572l-18.288-18.292a6.769 6.769 0 00-9.572 0l-11.524 11.527c-1.634 1.635-4.06 2.176-6.116 1.121-3.218-1.652-8.273-4.735-13.327-9.789-5.054-5.054-8.137-10.11-9.789-13.327-1.055-2.056-.514-4.482 1.12-6.115l11.528-11.525a6.768 6.768 0 000-9.572l-18.292-18.288a6.769 6.769 0 00-9.572 0l-10.9 10.903a11.205 11.205 0 00-3.274 8.397z"
            ></path>
          </g>
          <path
            fill="#fff"
            d="M204.017 124.813l-10.903 10.903a11.204 11.204 0 01-8.397 3.275c-10.792-.454-36.532-3.64-55.801-22.906-19.265-19.268-22.455-45.009-22.905-55.804a11.21 11.21 0 013.274-8.397l2.932-2.932c1.692 14.69 12.365 78.476 69.798 71.99 8.166-.92 14.554-3.831 19.488-8.215l2.514 2.514a6.769 6.769 0 010 9.572z"
          ></path>
          <path
            fill="#D0D5DD"
            d="M122.214 43.949l-1.717 1.635c-1.413 1.346-2.12 2.019-1.939 2.525.18.507 1.188.585 3.204.74 1.033.08 2.198.207 3.455.405 4.41.696 6.614 1.043 6.883.494.269-.549-1.275-1.999-4.363-4.9l-.974-.915a3.31 3.31 0 00-4.549.016zM178.428 100.163l-1.717 1.635c-1.414 1.346-2.12 2.019-1.94 2.525.18.507 1.188.585 3.205.741 1.032.079 2.198.206 3.456.405 4.409.695 6.613 1.043 6.882.494.269-.549-1.275-1.999-4.363-4.9l-.975-.916a3.309 3.309 0 00-4.548.016z"
          ></path>
          <circle cx="230.176" cy="115.176" r="6.176" fill="#D0D5DD"></circle>
          <circle cx="79.25" cy="65.25" r="5.25" fill="#D0D5DD"></circle>
          <circle
            cx="96.25"
            cy="26.25"
            r="4.55"
            stroke="#D0D5DD"
            strokeWidth="1.4"
          ></circle>
          <circle
            cx="209.25"
            cy="24.25"
            r="4.55"
            stroke="#D0D5DD"
            strokeWidth="1.4"
          ></circle>
          <g clipPath="url(#clip0_166_78)">
            <path
              fill="#D0D5DD"
              d="M219.3 66.098h-4.2v4.2h-1.4v-4.2h-4.2v-1.4h4.2v-4.2h1.4v4.2h4.2v1.4z"
            ></path>
          </g>
          <g clipPath="url(#clip1_166_78)">
            <path
              fill="#D0D5DD"
              d="M166.3 22.098h-4.2v4.2h-1.4v-4.2h-4.2v-1.4h4.2v-4.2h1.4v4.2h4.2v1.4z"
            ></path>
          </g>
          <g clipPath="url(#clip2_166_78)">
            <path
              fill="#D0D5DD"
              d="M84.3 122.102h-4.2v4.2h-1.4v-4.2h-4.2v-1.4h4.2v-4.2h1.4v4.2h4.2v1.4z"
            ></path>
          </g>
          <defs>
            <filter
              id="filter0_d_166_78"
              width="123.998"
              height="123.998"
              x="92"
              y="31"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset dx="-2" dy="4"></feOffset>
              <feGaussianBlur stdDeviation="6"></feGaussianBlur>
              <feComposite in2="hardAlpha" operator="out"></feComposite>
              <feColorMatrix values="0 0 0 0 0.596078 0 0 0 0 0.635294 0 0 0 0 0.701961 0 0 0 0.6 0"></feColorMatrix>
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_166_78"
              ></feBlend>
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_166_78"
                result="shape"
              ></feBlend>
            </filter>
            <clipPath id="clip0_166_78">
              <path
                fill="#fff"
                d="M0 0H16.8V16.8H0z"
                transform="translate(206 57)"
              ></path>
            </clipPath>
            <clipPath id="clip1_166_78">
              <path
                fill="#fff"
                d="M0 0H16.8V16.8H0z"
                transform="translate(153 13)"
              ></path>
            </clipPath>
            <clipPath id="clip2_166_78">
              <path
                fill="#fff"
                d="M0 0H16.8V16.8H0z"
                transform="translate(71 113)"
              ></path>
            </clipPath>
          </defs>
        </svg>
      ) : (
        <Image
          resizeMode="contain"
          source={require('../../../assets/images/no_call_logs.png')}
          style={{
            width: 300,
            height: 140,
          }}
        />
      )}
    </>
  );
};

export default NoCallLogsSvg;
