export const clinicalPerformanceMock = {
  glucose: { // parent object with props mapped with field and value
    "0.0": 72.02244792917108,
    "Bottom 25%": 91.76573773874154,
    0.5: 104.26900431298526,
    0.75: 151.9361969446785,
    "1.0": 196.3526020508382,
    Target: 90.05555409733599,
  },
  formatted: {
    "72.0": {
      label: '',
      value: 72.02244792917108,
    }
  }
};

export const clinicalPerformanceMockData = [clinicalPerformanceMock.glucose];
