import {Text, View} from 'native-base';
import React from 'react';
import {ICalendarProps} from '.';
import {ComingSoon} from '../../../common/ComingSoon';
import {styles} from './CalendarStyles';

const Calendar = (props: ICalendarProps) => {
  return (
    <View>
      <View style={styles.left}>
        <Text style={styles.leftText}>Schedule</Text>
      </View>
      <View style={styles.container}>
        <View>
          <Text style={styles.textContainer}>{props.tempProp || ''}</Text>
        </View>
        <ComingSoon />
      </View>
    </View>
  );
};

export default Calendar;
