import { useMutation } from '@apollo/client';
import {
  Box, Checkbox,
  FormControl,
  HStack,
  Input,
  Select,
  Spinner,
  Text,
  useToast,
  VStack
} from 'native-base';
import { useState } from 'react';
import {useIntl} from 'react-intl';
import { Dimensions } from 'react-native';
import { BUTTON_TYPE } from '../../../../../constants';
import InboxQueries from '../../../../../services/Inbox/InboxQueries';
import { Colors } from '../../../../../styles';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {
  formatEditWidgetPreChatNew,
  formatEditWidgetPreChatOld
} from '../Helper/formatIntegrationsData';
import { spinnerStype, styles } from '../IntegrationsStyles';
import { IIntegrationEditWidgetPreChat } from '../interfaces';
import { showToast, ToastType } from '../../../../../utils/commonViewUtils';

export const IntegrationEditWidgetPreChat = (
  props: IIntegrationEditWidgetPreChat
) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const {height} = Dimensions.get('window');
  const formattedData = formatEditWidgetPreChatOld(
    props.currentDetails.channelWebWidget
  );

  const [enablePreChat, setEnablePreChat] = useState(
    formattedData.preChatFormEnabled
  );
  const [preChatMessage, setPreChatMessage] = useState(
    formattedData.preChatMessage
  );
  const [visitorsProvideDetails, setVisitorsProvideDetails] = useState(
    formattedData.visitorsProvideDetails
  );

  const [updateWidgetPreChat] = useMutation(InboxQueries.UpdateWidgetPreChat);

  const handleUpdateClick = () => {
    setLoading(true);
    const toUpdateData = formatEditWidgetPreChatNew({
      id: formattedData.id,
      enablePreChat,
      preChatMessage,
      visitorsProvideDetails,
    });
    updateWidgetPreChat({variables: {...toUpdateData}}).then((res) => {
      setLoading(false);
      showToast(toast, 'PreChat Form updated', ToastType.success);
    });
  };

  return (
    <VStack space={3} paddingLeft={10} paddingTop={10} height={height - 200}>
      <FormControl>
        <Text size={'mdNormal'}>
          Pre chat forms enable you to capture user information before they
          start conversation with you.
        </Text>
        <Text size={'mdNormal'} marginTop={2} marginBottom={1}>
          Enable pre chat form
        </Text>
        <Select
          width={300}
          defaultValue={enablePreChat}
          selectedValue={enablePreChat}
          accessibilityLabel="Enable pre chat form:"
          placeholder="Enable pre chat form:"
          onValueChange={(itemValue) => setEnablePreChat(itemValue)}
          fontSize={'sm'}
        >
          <Select.Item label="Yes" value="Yes" />
          <Select.Item label="No" value="No" />
        </Select>
      </FormControl>
      <FormControl>
        <DisplayText
          textLocalId="preChatMessage"
          size={'mdNormal'}
          extraStyles={{marginTop: 2, marginBottom: 1}}
        />
        <Input
        _focus={{borderColor: '#D0D5DD'}}
          fontSize={'sm'}
          defaultValue={preChatMessage}
          width={300}
          placeholder="Hi there!"
          onChangeText={(value) => setPreChatMessage(value)}
        />
        <HStack style={styles.checkBox} space={2} alignItems={'center'}>
          <Checkbox
            defaultIsChecked={visitorsProvideDetails}
            onChange={(isSelected) => setVisitorsProvideDetails(isSelected)}
            value={'visitorsProvideDetails'}
          />
          <DisplayText
            size={'mdLight'}
            textLocalId={'inboxVisitorCheckBoxTitle'}
          />
        </HStack>
      </FormControl>
      <VStack>
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              handleUpdateClick();
            },
            style: {maxWidth: 150},
          }}
          customProps={{
            btnText: intl.formatMessage({id: 'update'}),
            withRightBorder: false,
          }}
        ></FoldButton>
      </VStack>
      {loading ? (
        <Spinner size="lg" style={spinnerStype.spinnerStyle} />
      ) : (
        <></>
      )}
    </VStack>
  );
};
