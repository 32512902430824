import {useLazyQuery} from '@apollo/client';
import React, {useContext, useEffect, useState} from 'react';
import {MLOV_CATEGORY} from '../../../constants';
import {CommonDataContext} from '../../../context/CommonDataContext';
import LabelsQueries from '../../../services/Labels/LabelsQueries';
import {getAccountId} from '../../../utils/commonUtils';
import {getMlovIdFromCode} from '../../../utils/mlovUtils';
import CustomFilterView from '../CustomFilterView/CustomFilterView';
import {ILabel} from '../FilterView/interfaces';

export const FilterByTag = (props: any) => {
  const {returnFilterItems, labelType} = props;
  const accountId = getAccountId();
  const [searchString, setSearchString] = useState('');
  const commonData = useContext(CommonDataContext);
  const labelTypeList = commonData.MLOV[MLOV_CATEGORY.LABEL_TYPE];
  const [selectedLabels, setSelectedLabels] = useState([] as any);
  const labelTypeId = getMlovIdFromCode(labelTypeList, labelType);
  const [labelData, setLabelData] = useState<ILabel[]>([]);

  const [getLabelDataList] = useLazyQuery<{labels: ILabel[]}>(
    LabelsQueries.GetAllLabelsByFilter
  );
  const [loadingLabelData, setLoadingLabelData] = useState(true);

  useEffect(() => {
    setLabelData([]);
    setLoadingLabelData(true);
    getLabelDataList({
      variables: {
        labelTypeId: labelTypeId,
        accountId: accountId,
        search: `%${searchString}%`,
      },
    })
      .then((labelsRespData) => {
        setLabelData(labelsRespData?.data?.labels || []);
        setLoadingLabelData(false);
      })
      .catch(() => {
        setLabelData([]);
        setLoadingLabelData(false);
      });
  }, [searchString]);
  useEffect(() => {
    returnFilterItems(selectedLabels);
  }, [selectedLabels.length]);
  return (
    <CustomFilterView
      displayString={'noTags'}
      onActionPerformed={(value: any) => {
        setSearchString(value);
      }}
      title="All Tags"
      tagList={labelData}
      loading={loadingLabelData}
      placeHolderText={'Search tags'}
      selectedLabels={selectedLabels}
      onFilterSelect={(labelItem: any) => {
        setSelectedLabels((prev: any) => [...prev, labelItem]);
      }}
      onRemoveItem={(labelItem: any) => {
        setSelectedLabels(
          selectedLabels.filter((item: any) => item.id !== labelItem.id)
        );
      }}
    />
  );
};

export default FilterByTag;
