import { useState } from 'react';
import { View, IconButton, Tooltip } from 'native-base';
import Icon from 'react-native-vector-icons/AntDesign';
import { COMMON_ACTION_CODES } from '../../../../../../constants/ActionConst';
import { ICodeGroupsTableData } from '../../interfaces';
import DeleteCodeGroupModal from '../../CodeGroupsModal/DeleteCodeGroupsModal';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../../styles';

interface ICodeGroupTableAction {
  record: ICodeGroupsTableData;
  onActionPerformed: (actionCode: string, recordData: ICodeGroupsTableData) => {};
}
const CodeGroupsActions = (props: ICodeGroupTableAction) => {
  const { record } = props;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteCodeGroup = () => {
    props.onActionPerformed(COMMON_ACTION_CODES.DELETE, record);
  };

  const handleEditCodeGroup = () => {
    props.onActionPerformed(COMMON_ACTION_CODES.EDIT, record);
  };

  return (
    <View flex={1} flexDirection={'row'}>
      <Tooltip label={!record?.accountUuid ? 'These code groups have been used for pre-defined population groups created by Fold and cannot be edited.' : 'Edit'}>
      <IconButton
        icon={<Feather name="edit-2" size={17} color={Colors.Custom.Gray600} />}
        isDisabled={!record?.accountUuid ? true : false}
        onPress={() => {
          record?.accountUuid && handleEditCodeGroup()
        }}
      />
      </Tooltip>

      <Tooltip label={!record?.accountUuid ? 'These code groups have been used for pre-defined population groups created by Fold and cannot be deleted.' : 'Delete'}>
      <IconButton
        icon={<Icon name="delete" size={17} color={Colors.Custom.Gray600} />}
        isDisabled={!record?.accountUuid ? true : false}
        onPress={() => {
          record?.accountUuid && setOpenDeleteModal(true);
        }}
      />
      </Tooltip>

      <DeleteCodeGroupModal
        isDeleteModalOpen={openDeleteModal}
        onDeleteModalClose={() => {
          setOpenDeleteModal(false);
          handleDeleteCodeGroup();
        }}
        title={record.title}
        onCancel={() => setOpenDeleteModal(false)}
      />
      <IconButton
        width={8}
        colorScheme="gray"
        variant="ghost"
        onPress={() =>
          handleEditCodeGroup()
        }
        icon={
          <Icon
            name="eye"
            size={17}
            color={
              Colors.Custom.Gray600
            }
          />
        }
      />
    </View>
  );
};

export default CodeGroupsActions;
