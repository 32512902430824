import {FlatList, View} from 'native-base';
import React from 'react';
import {Dimensions, Platform} from 'react-native';
import {isWebChannelInboxType} from '../../../../../../utils/commonUtils';
import {ConversationItemLoading} from '../../../../../common/Loader/ConversationItemLoading';
import {CHANNEL_TYPE_CODE} from '../../ConversationConst';

export const ConversationListLoader = (props: {
  listCount: number;
  selectedInboxTypeCode: string;
}) => {
  const listCount = props.listCount || 10;
  const selectInboxTypeCodeStr =
    props?.selectedInboxTypeCode || CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET;
  const conversationList = [];
  for (let index = 1; index <= listCount; index++) {
    conversationList.push({
      id: index,
    });
  }
  // const {height} = Dimensions.get('window');
  // const removeHeight = parseInt((22 / 100) * height + '');
  // const finalListHeight = height - removeHeight;

  const {height} = Dimensions.get('window');
  const removeHeightNum = 20;
  // if (isWebChannelInboxType(selectInboxTypeCodeStr || '')) {
  //   removeHeightNum = 20;
  // }
  const removeHeight = parseInt((removeHeightNum / 100) * height + '');
  let finalListHeight = height - removeHeight;
  if (Platform.OS !== 'web') {
    const removeHeight = parseInt((35 / 100) * height + '');
    finalListHeight = height - removeHeight;
  }
  return (
    <View style={{paddingHorizontal: 16}}>
      <FlatList
        style={{height: finalListHeight, overflow: 'scroll'}}
        data={conversationList}
        renderItem={() => <ConversationItemLoading />}
        keyExtractor={(item: any) => {
          return '_' + item.id;
        }}
      />
    </View>
  );
};
