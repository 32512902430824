import React, {createContext, useCallback, useMemo, useState} from 'react';
import {testID} from '../../../../../testUtils';
import {View, Text} from 'react-native';
import {useContactCareProgramContext} from '../ContactCareProgram.context';
import {getContentView} from './getContentView';
import ContentHeader from './components/ContentHeader';
import {
  IMainContentContextState,
  MainContentProvider,
} from './MainContentView.context';
import useCareProgramStepStatus from '../hooks/useCareProgramStepStatus';

const MainContent: React.FC = () => {
  const {state} = useContactCareProgramContext();
  const {careProgramStepStatusList} = useCareProgramStepStatus();
  const [mainContentState, setMainContentState] =
    useState<IMainContentContextState>({
      headerSavingIndicator: false,
    });

  const updateMainContentState = useCallback(
    (newState: Partial<IMainContentContextState>) => {
      setMainContentState((prevState) => ({...prevState, ...newState}));
    },
    [setMainContentState]
  );

  const {selectedStepId, contactCareProgramDetails} = state;
  const selectedStep = useMemo(
    () =>
      contactCareProgramDetails?.contactCareProgramSteps?.find(
        (step) => step.id === selectedStepId
      ),
    [contactCareProgramDetails?.contactCareProgramSteps, selectedStepId]
  );

  const selectedStepStatus = useMemo(
    () =>
    {
      const stepLog = contactCareProgramDetails?.stepsLog?.find(
        (step) => step.careProgramStepId === selectedStepId
      );
      const statusId = stepLog?.careProgramStepStatusId;
      return statusId ? careProgramStepStatusList.find((item) => item.id === statusId) : undefined;
    },
    [contactCareProgramDetails?.stepsLog, selectedStepId, careProgramStepStatusList]
  );

  if (!selectedStep) {
    return null;
  }

  const ContentView = getContentView({selectedStep, selectedStepStatus});

  return (
    <MainContentProvider
      value={{mainContentContextState: mainContentState, updateMainContentContextState: updateMainContentState}}
    >
      <div className="flex flex-col flex-1" key={selectedStepId}>
        <ContentHeader />
        <div className="divider" />
        <ContentView />
      </div>
    </MainContentProvider>
  );
};

export default MainContent;
