import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';
const baseMargin = {
  marginTop: 24,
  marginLeft: 24,
  marginRight: 24,
  marginBottom: 24,
};
export const styles = StyleSheet.create({
  formElement: {
    paddingTop: 5,
    paddingBottom: 10,
  },
  formLabel: {
    marginLeft: 4,
  },
  formExistingLabel: {
    marginLeft: 4,
    marginBottom: 0,
  },
  formLastElement: {
    paddingTop: 10,
    paddingBottom: 50,
  },
  submitElement: {
    width: 100,
    paddingTop: 10,
    marginBottom: 30,
  },
  cancelElement: {
    width: 100,
    paddingLeft: 10,
    paddingTop: 10,
    marginBottom: 30,
  },
  headerText: {
    fontWeight: '400',
    fontSize: 20,
    color: '#344054',
    paddingLeft: 16,
  },

  dropdown: {
    paddingVertical: 5,
    borderColor: Colors.Custom.CardBorderColor,
    borderWidth: 1,
    borderRadius: 14,
    paddingHorizontal: 8,
  },
  icon: {
    marginRight: 5,
  },
  label: {
    position: 'absolute',
    backgroundColor: 'white',
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.Custom.Gray900,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  selectedStyle: {
    borderRadius: 12,
  },
  loadingView: {
    ...baseMargin,
  },
  swtichBtnStyle: {
    paddingHorizontal: 12,
    height: 22,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 40,
  },
});
