import {Drawer} from 'antd';
import {View,Text} from 'native-base';
import {useState} from 'react';
import {Colors} from '../../../../../styles';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import AddOrUpdateForm from '../../../Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateForm';

const UpdateFormFromList = (props: {style?: any; onClose?: any,isFromEmpDashboard?:boolean}) => {
  const {style,isFromEmpDashboard} = props;
  const [showModal, setShowModal] = useState(true);

  const onClose = () => {
    props.onClose();
  };
  return (
    <Drawer
      open={showModal}
      onClose={onClose}
      width={1200}
      title={ <>
      <ModalActionTitle title={'updateForm'} />
      {isFromEmpDashboard ? (
            <Text style={{  position: 'absolute',
            width: '100%',
            top: 68,
            left: 0,
            backgroundColor: '#fff',
            paddingLeft: 24,
            zIndex: 1,
            color: Colors.Custom.Gray400,
            fontSize: 14
            }}>
              Please make sure the selected form should have 'Score' set for all
              the fields. As the customer satisfaction calculations are done
              based on the form each field defined scores.
            </Text>
          ) : (
            <></>
          )}
      </>}
    >
      {showModal && (
        <View style={[style ? style : {}]}>
          <AddOrUpdateForm onClose={onClose} categoryCode={'MEMBER_SATISFACTION'} isFromEmpDashboard={isFromEmpDashboard} />
        </View>
      )}
    </Drawer>
  );
};

export default UpdateFormFromList;
