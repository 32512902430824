import {PRODUCT_SERVICES_FLOW_TYPE_CODE} from '../../../../../../../../../../constants';
import {getStartOfDay, isBeforeDate} from '../../../../../../../../../../utils/DateUtils';
import { JOURNEY_START_TYPES } from './FormConst';
import {IJourneyValidationData, IStripePrice} from './interfaces';

export const validateProductFormData = (
  productDetailForm: any,
  journeyData: IJourneyValidationData,
  tabType?: string
) => {
  const errors: any = {};
  let validate = true;

  errors.name = !productDetailForm.name
    ? 'Product name is required'
    : productDetailForm.name.length < 3
    ? 'Product name is too short'
    : '';
  if (productDetailForm.compare_at_price <= 0) {
    errors.mrpPrice = 'Price cannot be less then zero(0)';
  } else if (!productDetailForm.compare_at_price) {
    errors.mrpPrice = 'Price is required';
  }

  errors.priceIssue = !productDetailForm.price
    ? 'Price is required'
    : productDetailForm.price && productDetailForm.compare_at_price
    ? parseFloat(productDetailForm.price) <=
      parseFloat(productDetailForm.compare_at_price)
      ? ''
      : 'Offer Price should be less then  Price'
    : '';

  if (tabType === PRODUCT_SERVICES_FLOW_TYPE_CODE.MEMBERSHIP) {
    const priceWithZero = productDetailForm?.prices?.filter(
      (price: IStripePrice) => {
        return price.price === 0 || price.price === null;
      }
    );

    if (priceWithZero.length === productDetailForm?.prices?.length) {
      errors.memberShipPrice = 'Please Add one of the Price';
    }
  }

  if (errors.name || errors.priceIssue || errors.mrpPrice) {
    validate = false;
  }

  if (journeyData.isCareJourney) {
    errors.journeyTitle = !productDetailForm.name
      ? 'Please enter journey name'
      : productDetailForm.name.length < 3
      ? 'Journey name is too short'
      : '';
    errors.triggerEvents =
      !productDetailForm.triggerEvents ||
      productDetailForm.triggerEvents.length === 0
        ? 'Please select journey condition'
        : '';
    errors.description = !productDetailForm.description
      ? 'Please enter description'
      : '';
    errors.duration =
      !productDetailForm.duration ||
      !productDetailForm.duration.value ||
      !productDetailForm.duration.unit
        ? 'Please enter journey duration'
        : '';

    errors.startDate = journeyData.isAssignWorkflow
      ? !productDetailForm.startDate
      : '';

    errors.startDateTime = productDetailForm.startType === JOURNEY_START_TYPES.FIXED && !productDetailForm.startDateTime
      ? 'Please enter the fixed date'
      : '';  

    if (errors.triggerEvents || errors.duration || errors.startDate) {
      validate = false;
    }
  }

  return {validate: validate, errors: errors};
};

export const validateMembershipFormData = (productDetailForm: any) => {
  const errors: any = {};
  let validate = true;

  errors.name = !productDetailForm.name
    ? 'Product name is required'
    : productDetailForm.name.length < 3
    ? 'Product name is too short'
    : '';

  errors.description = !productDetailForm.description
    ? 'Product description is required'
    : productDetailForm.description.length < 3
    ? 'Product description is too short'
    : '';

  const priceWithZero = productDetailForm?.prices?.filter(
    (price: IStripePrice) => {
      return (
        price.price === 0 ||
        price.price === null ||
        !price.intervalCode ||
        !price.intervalCount
      );
    }
  );

  const tieredPrices = productDetailForm.tiers;
  if (
    !tieredPrices &&
    priceWithZero?.length === productDetailForm?.prices?.length
  ) {
    errors.memberShipPrice = 'Please Add one of the Price';
  }

  if (errors.name || errors.price || errors.memberShipPrice) {
    validate = false;
  }

  return {validate: validate, errors: errors};
};

export const getDocumentObj = (documents:any,careJourneyId:string,subjectTypeId: string)=> {
  const documentsObj:any = [];
  documents?.forEach((document:any)=>{
    const obj = {
      id:document?.id,
      caption: document?.caption,
      notes: document?.notes || '',
      documentTypeId: document?.documentType?.id,
      subjectId: careJourneyId,
      subjectTypeId: subjectTypeId,
      isDeleted: false,
      documentAttachments: [{
        id: document?.documentAttachments?.[0]?.id,
        isDeleted: false,
        attachmentId:document?.documentAttachments?.[0]?.attachment?.id,
        attachment: {
          id: document?.documentAttachments?.[0]?.attachment?.id,
          name: document?.documentAttachments?.[0]?.attachment?.name,
          type: document?.documentAttachments?.[0]?.attachment?.type
        },
      }],
    }
    documentsObj.push(obj);
  })
  return documentsObj || [];
}

export const formatPatientDocumentObj = (documents:any,contactUuid:string,subjectTypeId: string)=> {
  const documentsObj:any = [];
  documents?.forEach((document:any)=>{
    if(document?.documentAttachments.length){
      const obj = {
        caption: document?.caption,
        notes: document?.notes || '',
        documentTypeId: document?.documentType?.id,
        subjectId: contactUuid,
        subjectTypeId: subjectTypeId,
        isDeleted: false,
        documentAttachments: [{
          id: document?.documentAttachments?.[0]?.id,
          isDeleted: false,
          attachmentId:document?.documentAttachments?.[0]?.attachment?.id,
          attachment: {
            id: document?.documentAttachments?.[0]?.attachment?.id,
            name: document?.documentAttachments?.[0]?.attachment?.name,
            type: document?.documentAttachments?.[0]?.attachment?.type
          },
        }],
      }
      documentsObj.push(obj);
    }
    
  })
  return documentsObj || [];
}

export const removeUnwantedKeyDocumentObj = (documents: any)=> {
  const filteredDocumentObj:any = [];
  documents?.forEach((document: any)=>{
    delete(document?.documentAttachments?.[0]?.attachment)
    const updatedDocumentObj = {
      ...document
    }
    filteredDocumentObj.push(updatedDocumentObj)
  })
  return filteredDocumentObj;
}
export const removeUnwantedKeyPatientDocumentObj = (documents: any)=> {
  const filteredDocumentObj:any = [];
  documents?.forEach((document: any)=>{
    delete(document?.documentAttachments?.[0]?.attachment)
    delete(document?.documentAttachments?.[0]?.id)
    delete(document?.id)
    const updatedDocumentObj = {
      ...document
    }
    filteredDocumentObj.push(updatedDocumentObj)
  })
  return filteredDocumentObj;
}
export const removeUnwantedKeyCloneJourney = (documents: any)=> {
  const filteredDocumentObj:any = [];
  documents?.forEach((document: any)=>{
    delete(document?.documentAttachments?.[0]?.attachment)
    delete(document?.documentAttachments?.[0]?.id)
    delete(document?.id)
    delete(document?.subjectId)
    const updatedDocumentObj = {
      ...document
    }
    filteredDocumentObj.push(updatedDocumentObj)
  })
  return filteredDocumentObj;
}
export const filterDeletedCareJourneyLocation = (previousLocation: {id:string,locationId:string}[],currentLocation: string[])=> {
  const previousLocationIds = previousLocation.map((location)=>location?.locationId) || [];
  const addedLocation = currentLocation?.filter((locationId)=>{
    if (previousLocationIds.includes(locationId)) {
      return false;
    }
    return true;
  }).map((locationId)=>{
    return {
      isDeleted: false,
      locationId: locationId
    }
  })
  const deletedLocation = previousLocation?.filter((location)=>{
    if (currentLocation?.includes(location?.locationId)) {
      return false;
    }
    return true;
  }).map((location)=>{
    return {
      isDeleted: true,
      locationId: location?.locationId
    }
  }) || []
  const locations = [...addedLocation,...deletedLocation]
  return locations || []
}

export const filterDeletedRecommendedCareJourney = (previousCareJourney: {id:string,packageId:string}[],currentCareJourney: string[])=> {
  const careJourneys: any[] = []
  const previousCareJourneyIds = previousCareJourney.map((careJourney)=> (careJourney?.packageId)) || [];

  currentCareJourney.forEach((currentCareJourney) => {
    if(!previousCareJourneyIds.includes(currentCareJourney)){
      careJourneys.push({
        isDeleted: false,
        packageId: currentCareJourney
      })
    }
  })

  previousCareJourney.forEach((careJourney)=> {
    if(currentCareJourney.includes(careJourney.packageId)){
      careJourneys.push({
        isDeleted: false,
        id: careJourney.id,
        packageId: careJourney?.packageId
      })
    }else if(!currentCareJourney.includes(careJourney.packageId)){
      careJourneys.push({
        isDeleted: true,
        id: careJourney.id,
        packageId: careJourney?.packageId
      })
    }
  })


  return careJourneys || []
}

export const getStartDateForCloneJourney = (startDate: string)=> {
  const isPastDate = isBeforeDate(startDate, new Date())
  if (isPastDate) {
    return getStartOfDay(new Date()).toISOString();
  } else {
    return startDate;
  }
}