
export const CallDirections = {
    All: 'all',
    Incoming: 'inbound',
    Outgoing: 'Outgoing Dial',
    Missed: 'no-answer',
}

export const TWILIO_CALL_STATUS = {
  COMPLETED: 'completed',
  RINGING: 'ringing',
  BUSY: 'busy',
  NO_ANSWER: 'no-answer',
  FAILED: 'failed',
}

export const CALL_STATUS = {
  ANSWERED_CALL: 'Answered Call',
  MISSED_CALL: 'Missed Call',
  INCOMING_CALL: 'Incoming'
}

export const CALL_DETAILS_FLEX_VALUES = {
  CALL_TYPE: 1.5,
  DATE_TIME: 1.2,
  DURATION: 0.5,
  OUT_OF_OFFICE: 0.5,
  ACTIONS: 1.3,
};

export const CALL_TRANSCRIPTION_STATUS = {
  NO_TRANSCRIPTION: '#NO_TRANSCRIPTION',
}