import BaseService from '../../../../services/CommonService/BaseService';
import LocalStorage from '../../../../utils/LocalStorage';

const getHeaders = async () => {
  const userDataString = await LocalStorage.getItem('user');
  const userData = JSON.parse(userDataString);
  const accountUUID =
    userData?.data.accounts.length > 0
      ? userData?.data.accounts[0].uuid || ''
      : '';
  const responseAccessToken = userData?.accessToken || '';
  const fold_access_token = userData?.fold_access_token;
  const headersObj: any = {
    'access-token': responseAccessToken,
    fold_access_token,
    accountUUID,
  };
  return headersObj;
};

export const getContactTypes = async (searchString?: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  const headers = await getHeaders();
  const response = await baseService.get(`crm-nest/api/contact_type?searchQuery=${searchString}`, {
    headers: { headers },
  });
  return response;
};

export const createOrUpdateContactType = async (body: any) => {
  const baseService = BaseService.getSharedInstance().axios;
  const method = baseService.post;
  const url = `crm-nest/api/contact_type`;
  const headers = await getHeaders();
  const response = await method(url, body, {
    headers,
  });
  return response;
};
