import { Platform } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';

const useCareTimeLineNavigate = () => {
  if (Platform.OS === 'web') {
    return useNavigate();
  } else {
    return useNavigation();
  }
};

export default useCareTimeLineNavigate;
