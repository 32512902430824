import { ITextProps, Text, VStack } from "native-base";
import { Colors } from "../../../styles";
import { IVStackProps } from "native-base/lib/typescript/components/primitives/Stack/VStack";

export function getTimeSince(date: Date) {
  const intervals: any = [
    {label: 'year', seconds: 31536000},
    {label: 'month', seconds: 2592000},
    {label: 'day', seconds: 86400},
    {label: 'hour', seconds: 3600},
    {label: 'minute', seconds: 60},
    {label: 'second', seconds: 1},
  ];

  const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
  const interval = intervals.find((i: any) => i.seconds < seconds);
  const count = Math.floor(seconds / interval?.seconds);
  return `${count} ${interval && interval?.label ? interval.label : ''}${count !== 1 ? 's' : ''} ago`;
}

export function getTimeDifferent(fromDate: Date, toDate: Date) {
  const intervals: any = [
    {label: 'year', seconds: 31536000},
    {label: 'month', seconds: 2592000},
    {label: 'day', seconds: 86400},
    {label: 'hour', seconds: 3600},
    {label: 'min', seconds: 60},
    {label: 'second', seconds: 1},
  ];
  const seconds = Math.floor((toDate.getTime() - fromDate.getTime()) / 1000);
  const interval = intervals.find((i: any) => i.seconds < seconds);
  const count = Math.floor(seconds / interval?.seconds);
  return `${count} ${interval.label}${count !== 1 ? 's' : ''}`;
}

export const renderSectionBulletList = (name: string, list: string[], headerProps?:ITextProps, containerProps?: IVStackProps) => {
  return (
    <VStack my={1} borderWidth={1} borderColor={Colors.Custom.Gray200} rounded={'md'} p={2} {...containerProps}>
      <Text color={Colors.Custom.Gray700} fontWeight={'bold'} {...headerProps} >{name}</Text>
      <ul style={{
        paddingLeft: '20px'
      }}>
        {list.map((record) => (
          <li key={record}>
            <Text>{record}</Text>
          </li>
        ))}
      </ul>
    </VStack>
  );
};  