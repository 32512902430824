import {Text, View} from 'native-base';
import React, {useState} from 'react';
import {DISPLAY_TYPE} from '../../../../../../constants';
import FormListView from '../../CommonView/FormListView';
import {DashboardDataContext} from '../../EmployerContext/DashboardContext';
import {WIDGET_ACTION_CODE} from '../../EmployerUtils/EmployerConst';
import {IWidgetCardView} from '../../interface';
import WidgetCardView from './WidgetCardView';
import WidgetVerticalCardView from './WidgetVerticalCardView';

const WidgetCardsViews = (props: IWidgetCardView) => {
  const {
    sectionData,
    sectionCode,
    resourceData,
    loading,
    reusableData,
    ref,
    onImageDownloadClick,
    employeeTotalCount,
    onActionClick,
    employerId,
    appliedFilter,
    isAccountDashboard,
    memberType
  } = props;
  const [stateData, setStateData] = useState({
    viewCode: '',
  });

  const getCardWidgetTypeView = (dateRangeType: string, dateRange: any) => {
    let ele = <></>;
    switch (sectionData.currentDisplayType) {
      case DISPLAY_TYPE.COUNT:
      case DISPLAY_TYPE.COUNT_WITH_LINE_GRAPH:
        ele = (
          <WidgetCardView
            appliedFilter={appliedFilter}
            dateRange={dateRange}
            dateRangeType={dateRangeType}
            onActionClick={onActionClick}
            employeeTotalCount={props.reusableData?.employeeCount}
            sectionCode={sectionCode}
            ref={ref}
            sectionData={sectionData}
            resourceData={{}}
            reusableData={reusableData}
            onImageDownloadClick={onImageDownloadClick}
            isPrintDownload={props.isPrintDownload}
            isPrintPreview={props.isPrintPreview}
            onViewCodeCallBack={(code) => {
              setStateData((prev) => {
                return {
                  ...prev,
                  viewCode: code,
                };
              });
            }}
            employerId={employerId}
          isAccountDashboard={isAccountDashboard}
          memberType={memberType}
          />
        );
        break;
      case DISPLAY_TYPE.COUNT_VERTICAL_WITH_LINE_GRAPH:
        ele = (
          <WidgetVerticalCardView
            appliedFilter={appliedFilter}
            dateRange={dateRange}
            dateRangeType={dateRangeType}
            onActionClick={onActionClick}
            employeeTotalCount={props.reusableData?.employeeCount}
            sectionCode={sectionCode}
            ref={ref}
            sectionData={sectionData}
            resourceData={{}}
            reusableData={reusableData}
            onImageDownloadClick={onImageDownloadClick}
            isPrintDownload={props.isPrintDownload}
            isPrintPreview={props.isPrintPreview}
            employerId={employerId}
            isAccountDashboard={isAccountDashboard}
            memberType={memberType}
          />
        );
        break;
    }
    return ele;
  };
  return (
    <>
    <DashboardDataContext.Consumer>
      {(context) => {
        return getCardWidgetTypeView(context.dateRangeType, {
          from: context.fromDate,
          to: context.toDate,
        });
      }}
    </DashboardDataContext.Consumer>
    {stateData.viewCode === WIDGET_ACTION_CODE.FORM_LIST_VIEW ? (
        <FormListView
        sectionData={sectionData}
          onClose={() => {
            setStateData((prev) => {
              return {
                ...prev,
                viewCode: '',
              };
            });
          }}
        />
      ) : (
        <></>
      )}

    </>
  );
};
export default React.memo(WidgetCardsViews);
