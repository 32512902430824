import {Text, View} from 'native-base';
import {getEnvVariable} from '../../../../constants/BaseUrlConst';
import {Colors} from '../../../../styles';
import {CustomScrollView} from '../../../common/ScrollView/CustomScrollView';
import InstructionsView from '../InstructionsView';
import {getAppointmentWidgetScript} from '../PatientPortalUtils';
import VideoAndScriptContainer from '../ScriptContainer/VideoAndScriptContainer';

const AppointmentWidget = (props: { accountUuid: string }) => {

  const inlineScript = getAppointmentWidgetScript({
    envVariable: getEnvVariable(),
    accountUuid: props.accountUuid,
    openInModal: false,
  });

  const modalScript = getAppointmentWidgetScript({
    envVariable: getEnvVariable(),
    accountUuid: props.accountUuid,
    openInModal: true,
  });

  const refVideos = [
    {
      title: 'Inline Appointment Widget',
      url: 'https://generalfolddocs.s3.us-east-2.amazonaws.com/widget_demo_sample/inline_appointment_widget.mov',
    },
    {
      title: 'New Page Appointment Widget',
      url: 'https://generalfolddocs.s3.us-east-2.amazonaws.com/widget_demo_sample/new_page_appointment_widget.mov',
    },
  ];

  return (
    <CustomScrollView style={{height: 'full', backgroundColor: '#fff'}}>
      <View>
        <InstructionsView
          title={'Appointment widget works as mentioned below'}
          summary={<Text>{`We support two types of appointment widgets - the first type opens the widget inside your website (inline) and the other opens up the widget in a new page (modal).`}</Text>}
          instructionDataArray={[
            <>
              <Text>
                {`Inline widget must use the div tag as below in your html file\n`}
              </Text>
              <Text color={Colors.Custom.mainPrimaryPurple}>
                {`<div id="fh-appointment-trigger"></div>`}
              </Text>
            </>,
            <>
              <Text>
                {`If you would like the widget to open in a new page (modal) you must use the following element\n`}
              </Text>
              <Text color={Colors.Custom.mainPrimaryPurple}>
                {`<button id="fh-appointment-trigger">Click Here</button>`}
              </Text>
            </>,
          ]}
          description={<Text>{`Above change is in addition to the code script pasted below.\n\nThe "Appointment Sign Up" form in Settings > Content > Forms within your tablespace corresponds to the form displayed in the appointment widget. You can customize the "Appointment Sign Up" form by adding extra fields. Note that only First Name, Last Name, Email, and Phone Number will update the Lead created by users who fill out the widget. However, all fields will be recorded in form responses and under form analytics.\n\nBy default, the "Routine Visit" appointment type is linked to the appointment widget. If you wish to modify this setting, please reach out to us.\n\nThe following video tutorials will also illustrate how this works. Please contact us if you have any questions.`}</Text>}
          refVideos={refVideos}
        />

        <VideoAndScriptContainer
          refVideos={refVideos}
          inlineScript={inlineScript}
          modalScript={modalScript}

        />
      </View>
    </CustomScrollView>
  );
};

export default AppointmentWidget;
