import {Column, ColumnConfig} from '@ant-design/charts';
import {Text, View, useMediaQuery} from 'native-base';
import React, {memo} from 'react';
import {StyleSheet} from 'react-native';
import {IOnTimeVsDueDateTask, RESOURCE_LIST, IPageSection} from '../interface';
import {
  getCompletedAndAfterDueTaskGraph,
  getCountAndPercentageOfTask,
  getYAxisMaxValue,
  secondsToHhMm,
} from './UtilsConst';
import { capitalizeText } from '../../../ContactRelationView/ContactRelationUtils';
import { SMALL_WINDOW_1700 } from '../../../../../constants';
import { taskAnalyticsWidgetColorList } from '../../../../RightSideContainer/Analytics/EmployerAnalytics/graphColors';

const OnTimeVsDueDateTask = (props: {
  widgetData: IOnTimeVsDueDateTask;
  pageSection: IPageSection;
}) => {
  const {widgetData, pageSection} = props;
  const [isSmallWindow] = useMediaQuery([
    { maxWidth: SMALL_WINDOW_1700 },
  ]);
  const countAndPercentageOfTask = getCountAndPercentageOfTask(widgetData);
  const completedAndAfterDueTaskGraph =
    getCompletedAndAfterDueTaskGraph(widgetData);
  const yAxisMaxValue = getYAxisMaxValue(
    completedAndAfterDueTaskGraph,
    pageSection?.resource?.code
  );
  const total = parseInt(
    (
      (widgetData?.rowsByPriority?.completionTimeDelayInSecondsAverage || 0) / 3600
    ).toFixed(0)
  );
  const lineAnnotation: any = {
    type: 'line',
    start: ['min', total],
    end: ['max', total],
    style: {
      stroke: '#F47A3E',
      lineWidth: 1,
      lineDash: [1, 1],
    },
    text: {
      position: 'end',
      content: `Average
 ${secondsToHhMm(
        widgetData?.rowsByPriority?.completionTimeDelayInSecondsAverage || 0
      )}s`,
      offsetX: isSmallWindow ? -80 : -90,
      style: {
        fill: '#F47A3E',
        fontSize: 10,
      },
    },
  };
  const config: ColumnConfig = {
    data: completedAndAfterDueTaskGraph,
    xField: 'name',
    yField: 'value',
    isStack: true,
    seriesField: 'type',
    annotations: [lineAnnotation],
    color: taskAnalyticsWidgetColorList,
    tooltip: {
      formatter: (data) => {
        return {
          name: capitalizeText(data?.type) || '0',
          value: data?.value || '0',
        };
      },
    },
    xAxis: {
      title: {
        text: 'Priority',
        style: {
          fill: '#3A485F',
        },
      },
      label: {
        style: {
          fontSize: 12,
          fontWeight: 400,
          fill: '#6F7A90',
        },
      },
      line: {
        style: {
          stroke: '#D0D6E1',
          lineWidth: 0.5,
        },
      },
      grid: {
        alignTick: true,
        line: {
          type: 'line',
          style: {
            stroke: '#E9ECF1',
            lineDash: [1, 1],
            lineWidth: 0.5,
          },
        },
        closed: true,
      },
    },
    columnWidthRatio: 0.4,
    yAxis: {
      ...(yAxisMaxValue),
      title: {
        text: 'Time (in hrs.)',
        style: {
          fill: '#3A485F',
        },
      },
      label: {
        style: {
          fontSize: 12,
          fontWeight: 400,
          fill: '#6F7A90',
        },
      },
      line: {
        style: {
          stroke: '#D0D6E1',
          lineWidth: 0.5,
        },
      },
      grid: {
        alignTick: true,
        line: {
          type: 'line',
          style: {
            stroke: '#E9ECF1',
            lineWidth: 0.5,
          },
        },
        closed: true,
      },
    },
    legend: false,
    label: {
      position: 'middle',
      formatter: () => {
        return '';
      },
    },
    height: 306,
    columnStyle(datum) {
      return {
        radius: datum.type == 'on-time' ? [8, 8, 0, 0] : [0,0,0,0],
        width: 30
      };
    },
  };
  return (
    <View
      flex={1}
      style={styles.container}
    >
      <View
        flex={0.3}
        style={styles.leftColumn}
      >
        <View style={styles.sectionContainer}>
          <Text size={'xsMedium'} color={'#6F7A90'}>
            TOTAL COMPLETED TASKS
          </Text>
          <Text size={'xslBold'} color={'#3A485F'}>
            {countAndPercentageOfTask.totalTaskCount || '--'}
          </Text>
        </View>
        <View style={styles.sectionContainer}>
          <View style={styles.rowAlignCenter}>
            <View
              style={styles.colorIndicator}
              borderRadius={'50%'}
            ></View>
            <Text size={'xsMedium'} color={'#6F7A90'}>
              ON TIME
            </Text>
          </View>
          <Text size={'mdBold'} color={'#3A485F'}>
            {`${countAndPercentageOfTask?.onTime?.onTimeTaskPercentage}% • ${countAndPercentageOfTask.onTime?.onTimeTaskCount} Tasks`}
          </Text>
        </View>
        <View style={styles.sectionContainer}>
          <View style={styles.rowAlignCenter}>
            <View
              style={[styles.colorIndicator, styles.afterDueDateIndicator]}
              borderRadius={'50%'}
            ></View>
            <Text size={'xsMedium'} color={'#6F7A90'}>
              AFTER DUE DATE
            </Text>
          </View>
          <Text size={'mdBold'} color={'#3A485F'}>
            {`${countAndPercentageOfTask?.afterDueDate?.afterDueDateTaskPercentage}% • ${countAndPercentageOfTask.afterDueDate?.afterDueDateTaskCount} Tasks`}
          </Text>
        </View>
        <View style={styles.lastSection}>
          <Text size={'xsMedium'} color={'#6F7A90'}>
            AVERAGE DELAY
          </Text>
          <Text size={'mdBold'} color={'#3A485F'}>
            {secondsToHhMm(
              widgetData?.rowsByPriority?.completionTimeDelayInSecondsAverage
            ) || '--'}
          </Text>
        </View>
      </View>
      <View flex={0.7} style={styles.rightColumn}>
        <Column {...config} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 12,
  },
  leftColumn: {
    borderWidth: 0.5,
    borderColor: '#D0D6E1',
    borderStyle: 'solid',
    borderRadius: 8,
    padding: 16,
  },
  sectionContainer: {
    width: '100%',
    borderBottomWidth: 0.5,
    borderColor: '#D0D6E1',
    borderStyle: 'solid',
    paddingBottom: 12,
    marginTop: 12,
  },
  rowAlignCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  colorIndicator: {
    width: 8,
    height: 8,
    backgroundColor: '#80A4D5',
    marginRight: 8,
  },
  afterDueDateIndicator: {
    backgroundColor: '#BFD7F6',
  },
  lastSection: {
    width: '100%',
    paddingBottom: 12,
    marginTop: 12,
  },
  rightColumn: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
});

export default memo(OnTimeVsDueDateTask);
