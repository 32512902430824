import {useLazyQuery, useQuery} from '@apollo/client';
import {Box, Skeleton, Spinner, useToast, View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {
  ICalendarWidgetMetaState,
  ILocation,
  IUser,
} from '../../../../Interfaces';
import {ScheduleEventQueries, UserQueries} from '../../../../services';
import {commonBodyContentContainer} from '../../../../styles/CommonBodyContentStyle';
import {getAccountUUID, getBooleanFeatureFlag, getUserUUID, isAppointmentTypesGroupEnable} from '../../../../utils/commonUtils';
import CalendarWidget from '../../../common/CalendarWidget/CalendarWidget';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import {GetScheduleAccessByUserId} from '../../../../services/UserScheduleAccess/UserScheduleAccessQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {IScheduleUserResponse} from '../../UserScheduleSetting/interfaces';
import {processCalendarWidgetUsersData} from '../../../common/CalendarWidget/CalendarWidgetUtils';
import {ICalendarWidgetMetaData} from '../../../common/CalendarWidget/CalendarWidgetInterfaces';
import { MLOV_CATEGORY, MLOV_CODES, USER_PREFERENCES_CODES, USER_ROLE_CODES } from '../../../../constants/MlovConst';
import { GetUserPreferencesByTypeId } from '../../../../services/UserPreferences/UserPreferencesQueries';
import { getMlovId } from '../../../../utils/mlovUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { filterNonDeletedLocationUser } from '../../../common/CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper';
import useGetBatchedAccountUsers from '../../../CustomHooks/useGetBatchedAccountUsers';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import { IUserFilterPreferenceDataForCalendar } from '../../AccountSettings/PracticeAvailabilityNew/PracticeAvailabilityInterfaces';
import { IAppointmentType } from '../../AccountSettings/AppointmentTypes/Interfaces';
import {usePermissions} from '../../../CustomHooks/usePermissions';
import {USER_ACCESS_PERMISSION} from '../../UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../../SideMenuBar/SideBarConst';
import {useLocationGroupIdsByAccountLocationIds} from '../../../CustomHooks/useLocationGroupIdsByAccountLocationIds';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
const CalendarView = (props: {
  isDayOptimizerCalender?: boolean,
  onHideCalendarView?: () => void
}) => {
  const toast = useToast();
  const loggedInUserId = getUserUUID();
  const accountUUID = getAccountUUID();
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);

  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code, MAIN_MENU_CODES.SCHEDULE);
  const currentUserAllowedLocations = permissionConfig?.allowedLocationIds || [];
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const locationGroupIds = useLocationGroupIdsByAccountLocationIds(currentUserAllowedLocations);

  const userPreferencesTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCES_TYPE,
    USER_PREFERENCES_CODES.USER_CALENDAR_DAY_VIEW_USER_ORDER
  );
  const {
    loading: accountUserLoading,
    error: accountUsersError,
    userList: accountUserList,
  } = useGetBatchedAccountUsers({
    onError: () => {
      showToast(
        toast,
        intl.formatMessage({id: 'errorMsg'}),
        ToastType.info,
      );
    },
  });
  const [calendarWidgetMetaData, setCalendarWidgetMetaData] =
    useState<ICalendarWidgetMetaState>({
      loading: true,
      accountUsers: [],
      accountLocations: [],
      locationUserMap: new Map<string, IUser[]>(),
      userLocationMap: new Map<string, ILocation[]>(),
      selectedLocation: {} as ILocation,
      appointmentTypes: []
    });

  const [userPreferenceFilterData, setUserPreferenceFilterData] = useState<IUserFilterPreferenceDataForCalendar>();
  const allowAppointmentTypesGroup = isAppointmentTypesGroupEnable(mlovData.userSettings);

  const userDataPostProcessing = (
    userScheduleAccess: IScheduleUserResponse[]
  ) => {
    const widgetMetaData: ICalendarWidgetMetaData =
      processCalendarWidgetUsersData(
        userScheduleAccess,
        accountUserList,
        // We don't want all provider and all location option added in the list, thus sending false
        false,
        loggedInUserId
      );
    setCalendarWidgetMetaData((prev) => ({
      ...prev,
      accountUsers: widgetMetaData.accountUsers,
      loading: false,
      accountLocations: widgetMetaData.accountLocations,
      locationUserMap: widgetMetaData.locationUserMap,
      userLocationMap: widgetMetaData.userLocationMap,
      selectedLocation: widgetMetaData.selectedLocation,
    }));
  };



  const getScheduleAccessUsers = useQuery(GetScheduleAccessByUserId, {
    fetchPolicy: 'no-cache',
    skip: accountUserLoading,
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    variables: {
      userId: loggedInUserId,
    },
    onCompleted: (data: any) => {
      userDataPostProcessing(data?.userScheduleAccesses);
    },
    onError: (error) => {

      setCalendarWidgetMetaData((prev) => ({
        ...prev,
        loading: false,
      }));
    },
  });

  const getUserPreferenceFilterQuery = useQuery<{
    userPreferences: {
      id: string;
      preferencesJson: string;
      preferencesModelVersion: string;
    }[];
  }>(GetUserPreferencesByTypeId, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
    variables: {
      userId: loggedInUserId,
      userPreferencesTypeId: userPreferencesTypeId,
    },
    onCompleted: (data) => {
      if (data && data.userPreferences && data.userPreferences.length) {
        const preferenceData = data.userPreferences?.[0];
        const userPreferenceFilterData =
          JSON.parse(preferenceData['preferencesJson'])?.filterData || [];
        const dayViewUserOrderPreference: Array<string> =
          JSON.parse(preferenceData['preferencesJson'])?.userList || [];
        setUserPreferenceFilterData({
          ...userPreferenceFilterData,
          ...(props?.isDayOptimizerCalender ? {
            selectedUserIds: [loggedInUserId],
            selectedLocationIds: []
          } : {}),
          dayViewUserOrderPreference: dayViewUserOrderPreference,
          calendarFiltersPreferenceId: preferenceData.id,
        });
      }
    },
    onError: (err: any) => {
      showToast(
        toast,
        intl.formatMessage({id: 'userPreferenceFetchFailed'}),
        ToastType.error
      );
    },
  });

  const getAppointmentTypes = useQuery( 
  isMultiTenancyEnabled
    ? ScheduleEventQueries.GET_APPOINTMENT_TYPES_BY_LOCATION_GROUP
    : ScheduleEventQueries.GET_APPOINTMENT_TYPES,
  {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    variables: {
      searchString: `%%`,
      categoryCodes: allowAppointmentTypesGroup ? [MLOV_CODES.ONE_ON_ONE_APPOINTMENT, MLOV_CODES.GROUP_SESSION] : [MLOV_CODES.ONE_ON_ONE_APPOINTMENT],
      ...(isMultiTenancyEnabled && { locationGroupIds: locationGroupIds }),
    },
    onCompleted: (data) => {
      if (data?.appointmentTypes) {
        setCalendarWidgetMetaData((prev) => ({
          ...prev,
          appointmentTypes: data?.appointmentTypes
        }));
      }
    },
    onError: (err: any) => {
      showToast(
        toast,
        intl.formatMessage({id: 'errorFetchingAppointmentTypes'}),
        ToastType.info,
      );
    }
  })


  return (
    <>
      {
        !props.isDayOptimizerCalender ?
        <View style={{backgroundColor: '#fff'}}>
        <TitleSubtitleView
          titleLabelId="calendar"
          subtitleLabelId="manageCalendar"
          />
        </View>
        : null
      }
      {getScheduleAccessUsers.loading ||
      calendarWidgetMetaData.loading ||
      getUserPreferenceFilterQuery.loading ||
      getAppointmentTypes.loading ? (
        <View
          zIndex={10}
          position="absolute"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          top="50%"
          left="50%"
        >
          <Spinner size="lg" />
        </View>
      ) : (
        <CalendarWidget
          calendarMetaData={calendarWidgetMetaData}
          getScheduleAccessUsersLoading={getScheduleAccessUsers.loading}
          calendarWidgetMetaDataLoading={calendarWidgetMetaData.loading}
          filterPreferenceData={userPreferenceFilterData}
          isDayOptimizerCalender={props?.isDayOptimizerCalender || false}
          onHideCalendarView={props?.onHideCalendarView}
        />
      )}
    </>
  );
};

export default CalendarView;
