import {Circle, HStack, VStack, Text, Icon, View} from 'native-base';
import {getInitialsFromFullName} from '../../../utils/commonUtils';
import {StyleSheet} from 'react-native';
import {useEffect, useRef} from 'react';
import {useContainerDimensions} from '../../CustomHooks/ContainerDimensionHook';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import AntIcon from 'react-native-vector-icons/AntDesign';

export const AvailabilityEventComponent = (props: any) => {
  const componentRef = useRef();
  const {width, resetDimension} = useContainerDimensions(componentRef);


  const renderLocationIcon = (iconSize: number): JSX.Element => {
    if (!props?.locationName) {
      return (<></>);
    }
    return (
      <>
        {props?.locationName === 'Virtual' && (
          <Icon as={AntIcon} name="videocamera" size={iconSize} ml={0} />
        )}
        {props?.locationName !== 'Virtual' && (
          <Icon as={MaterialIcon} name="location-on" size={iconSize} ml={0} />
        )}
      </>
    );
  };

  const renderUserAvatar = (
    avatarSize: number,
    fontSize: number,
    fontWeight: number,
  ) => {
    return (
      <Circle
        size={avatarSize}
        backgroundColor={`${props?.userWiseStyle.textColor}`}
        borderColor={'white'}>
        <Text
          style={{color: 'white'}}
          fontSize={fontSize}
          fontWeight={fontWeight}
          textTransform={'uppercase'}>
          { typeof props.primaryContactName === 'string' ? getInitialsFromFullName(props.primaryContactName || '') : `+${props?.primaryContactName}`}
        </Text>
      </Circle>
    );
  };

  const renderUserName = (
    width: number,
    fontSize: number,
    fontWeight: number,
  ): JSX.Element => {
    if (props?.primaryContactName && typeof props.primaryContactName !== 'string') {
      return (<></>)
    }
    return (
      <Text
        marginLeft={1}
        width={width}
        style={availabilityStyles.textStyle}
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={props?.userWiseStyle?.textColor}
        lineHeight={19}>
        {props.primaryContactName}
      </Text>
    );
  };

  const renderLocationName = (width: number): JSX.Element => {
    if (!props?.locationName) {
      return (<></>);
    }
    if (width) {
      return (
        <Text
          width={width}
          style={availabilityStyles.locationStyle}
          lineHeight={15}>
          {props?.locationName}
        </Text>
      );
    }

    return (
      <Text style={availabilityStyles.locationStyle} lineHeight={15}>
        {props?.locationName}
      </Text>
    );
  };

  useEffect(() => {
    resetDimension();
  }, [props]);

  return (
    <HStack
      marginLeft={'3px'}
      ref={componentRef}
      space={1}
      flexWrap={'wrap'}
      key={props.primaryContactName}
      justifyContent={'center'}
      alignItems={'center'}>

      <View justifyContent={'center'} alignItems={'center'}>

      {width >= 0 && width <=7 && (
        <VStack>{renderUserAvatar(3, 10, 800)}</VStack>
      )}
      {width > 7 && width <= 18 && (
        <>
          <VStack>{renderUserAvatar(5, 10, 800)}</VStack>
          <VStack marginTop={1}>{renderLocationIcon(4)}</VStack>
        </>
      )}

      {width > 18 && width <= 36 && (
        <>
          <VStack>{renderUserAvatar(7, 12, 600)}</VStack>
          <VStack marginTop={1}>{renderLocationIcon(4)}</VStack>
        </>
      )}

      {width > 36 && width <= 50 && (
        <>
          <VStack>{renderUserAvatar(6, 12, 600)}</VStack>
          <VStack marginTop={1}>{renderLocationIcon(4)}</VStack>
        </>
      )}
      {width > 50 && width <= 120 && (
        <>
          <VStack>{renderUserAvatar(7, 12, 600)}</VStack>
          <VStack marginTop={1}>{renderLocationIcon(4)}</VStack>
          <VStack  textAlign={'center'}>{renderLocationName(width > 90 ? 90 : width - 20)}</VStack>
        </>
      )}
      {width > 120 && width < 200 && (
        <>
          <VStack>{renderUserAvatar(10, 12, 600)}</VStack>
          <VStack marginTop={1} textAlign={'center'}>{renderUserName(width - 10, 16, 500)}</VStack>
          <VStack marginTop={1}>{renderLocationIcon(4)}</VStack>
          <VStack marginTop={1} textAlign={'center'}>{renderLocationName(width - 10)}</VStack>
        </>
      )}
      {width >= 200 && (
        <>
          <VStack marginTop={2}>
            <HStack>
              {renderUserAvatar(10, 12, 600)}
              <VStack>
                {renderUserName(width - 40, 16, 500)}
                <HStack space={1} marginTop={1} marginLeft={1}>
                  {renderLocationIcon(4)}
                  {renderLocationName(0)}
                </HStack>
              </VStack>
            </HStack>
          </VStack>
        </>
      )}
      </View>
    </HStack>
  );
};

const availabilityStyles = StyleSheet.create({
  textStyle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  locationStyle: {
    color: '#666666',
    fontWeight: '400',
    fontSize: 12,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});
