import {Button, Modal, Spinner, Text, View} from 'native-base';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {deleteCostSavingTemplate} from '../../../../../../services/CostSavingTemplates/CostSavingService';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import {deleteCptModalStyle} from '../helperFiles/DeleteCptModalStyle';
import {IDeleteTemplateProps} from '../helperFiles/interface';
import {styles} from '../helperFiles/Styles';


const {Content, Footer, Header, CloseButton, Body} = Modal;

const DeleteCostTemplateModal = (props: IDeleteTemplateProps) => {
  const {onActionPerformed, selectedData} = props;
  const intl = useIntl();
  const [stateData, setStateData] = useState({
    loading: false
  })
  const handleConfirmation = async () => {
    setStateData(prev => {
      return {
        ...prev,
        loading: true
      }
    })
    const templateId = selectedData?.id
    if (templateId) {
      const response = await deleteCostSavingTemplate(templateId || '');
      onActionPerformed(COMMON_ACTION_CODES.SAVE, response)
    }
    setStateData(prev => {
      return {
        ...prev,
        loading: false
      }
    })
  };
  const onClose = () => {
    onActionPerformed(COMMON_ACTION_CODES.CANCEL)
  }
  return (
    <Modal isOpen onClose={onClose}>
      {stateData.loading ? (
        <Spinner size="lg" style={styles.spinnerStyle} />
      ) : (
        <></>
      )}
      <Content style={deleteCptModalStyle.container}>
        <CloseButton />
        <Header>
          <DisplayText textLocalId="deleteTemplate" textType="Heading" />
        </Header>

        <Body>
          {selectedData?.isDefault ? (
            <View>
              <DisplayText textLocalId="deleteDefaultTemplateConfirmation" />
            </View>
          ) : (
            <>
              <View
                style={{display: selectedData?.assignedTo ? 'flex' : 'none', marginBottom: 4}}
              >
                <DisplayText textLocalId="deleteAssignedTemplateConfirmation" />
                <Text size="smBold">{selectedData?.assignedTo} Employers.</Text>
              </View>
              <View>
                <DisplayText textLocalId="deleteTemplateConfirmation" />
              </View>
            </>
          )}
        </Body>

        <Footer style={deleteCptModalStyle.footerStyle}>
          <Button.Group>
            <FoldButton
              nativeProps={{
                variant: selectedData?.isDefault
                  ? BUTTON_TYPE.PRIMARY
                  : BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: selectedData?.isDefault ? 'ok' : 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            {selectedData?.isDefault ? (
              <></>
            ) : (
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    handleConfirmation();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'confirm',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            )}
          </Button.Group>
        </Footer>
      </Content>
    </Modal>
  );
};

export default DeleteCostTemplateModal;
