import {Progress} from 'antd';
import {Stack, Text, View} from 'native-base';
import React from 'react';
import {Colors} from '../../../../../../styles';
import {DashboardDataContext} from '../../EmployerContext/DashboardContext';
import {getPercentageValue} from '../../EmployerUtils/WidgetUtils';
import {IWidgetCardView} from '../../interface';

const ListingWidgetWithProgress = (props: IWidgetCardView) => {
  const {sectionData, resourceData, loading, reusableData} = props;
  return (
    <>
      <Stack
        direction={'row'}
        space="2"
        flexWrap={'wrap'}
        alignItems={'flex-start'}
        flex={1}
      >
        {resourceData?.map((item:any,index:number) => {
          return (
            <View key={index} style={{width: '48.5%', flexDirection: 'row', alignItems: 'center', paddingVertical: 12, paddingLeft: 12}}>
              <View style={{}}>
              <Progress
                type="circle"
                percent={getPercentageValue(
                  item.count,
                  reusableData.employeeCount
                )}
                strokeColor={Colors.primary['300']}
                strokeWidth={8}
                width={60}
                style={{marginRight: 8, color: Colors.primary['400']}}
              />
              </View>
              <View style={{marginLeft: 8, maxWidth: 170, flexWrap: 'wrap'}}>
                <Text size={'xlBold'} style={{color: '#000',}} noOfLines={2}>{item.name}</Text>
                <Text size={'smMedium'} style={{color: Colors.Custom.Gray500}}>{item.count} Patients</Text>
              </View>
            </View>
          );
        })}
      </Stack>

      {/* <DashboardDataContext.Consumer>
    {(context) => {
      return (
        <Text>{context.employeeCount}</Text>
      );
    }}
  </DashboardDataContext.Consumer> */}
    </>
  );
};
export default React.memo(ListingWidgetWithProgress);
