import BaseService from '../CommonService/BaseService';
import CommonService from '../CommonService/CommonService';

export const getProfileImage = (name: string, email: string) => {
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.axiosService;
  const formData = new FormData();
  formData.append('profile[name]', name);
  formData.append('profile[email]', email);
  formData.append('profile[display_name]', '');
  formData.append('profile[avatar]', '');

  return axiosService.get(`/profile`);
};

export const getAllAgentsList = (accountId: any) => {
  const baseService = BaseService.getSharedInstance().axios;

  return baseService.get(`/chatwoot/api/v1/accounts/${accountId}/agents`);
};
