import React from 'react';
import {Center, Text, View} from 'native-base';
import {IComingSoonProps} from '.';
import {styles} from './ComingSoonStyle';

const ComingSoon = (props: IComingSoonProps) => {
  return (
    <View>
      <Center>
        <Text style={[styles.container, props.textStyle]}>
          {props.displayString || 'Coming Soon...'}
        </Text>
      </Center>
    </View>
  );
};

export default ComingSoon;
