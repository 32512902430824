import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../styles';
import PlusIcon from '../../../common/Svg/PlusSvg';
import {ITableTopBarProps} from '../../../common/TableTopBar';

export const getMembershipOrderTopbar = (): ITableTopBarProps => {
  return {
    title: '',
    buttonList: [],
  };
};

export const getMembershipProductListTopBarButtons = (
  buttonClickFnList: (() => void)[],
  content: JSX.Element
): ITableTopBarProps => {
  return {
    title: '',
    buttonList: [
      {
        id: 0,

        btnText: `newMembership`,
        size: 'sm',
        colorScheme: 'primary',
        backgroundColor: Colors.secondary['100'],
        borderColor: Colors.secondary['200'],
        textColor: Colors.secondary['800'],
        variant: '',
        leftIcon: (
          <PlusIcon />
        ),
        btnClick: () => {
          buttonClickFnList[0]();
        },
        content: content,
      },
    ],
  };
};
