import {useLazyQuery} from '@apollo/client';
import {Drawer, Select} from 'antd';
import {FormControl, Text} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../../../constants';
import {IAccountPracticeLocationResp} from '../../../../../../services/Location/interfaces';
import UserPracticeLocationQueries from '../../../../../../services/Location/UserPracticeLocationQueries';
import {Colors} from '../../../../../../styles';
import {getAccountUUID} from '../../../../../../utils/commonUtils';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import {IAccountLocation} from '../../../../Contacts/TeamMembers/interfaces';

interface ILocationFilters {
  data: any;
  onClose: () => void;
  onActionPerformed: (code: string, data?: any) => void;
}

const LocationFilters = (props: ILocationFilters) => {
  const {data, onClose, onActionPerformed} = props;
  const accountUuid = getAccountUUID();
  const [locationFiltersState, setLocationFiltersState] = useState({
    isVisible: true,
    accountPracticeLocations: [] as IAccountLocation[],
    selectedLocationId: [] as string[],
    selectedLocationNames: [] as string[]
  });

  const [accountPracticeLocations] = useLazyQuery<IAccountPracticeLocationResp>(
    UserPracticeLocationQueries.GetPracticeLocationsByTenantId,
    {
      variables: {tenantId: accountUuid},
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const getAccountPracticeLocations = async () => {
    const accountLocationRes = await accountPracticeLocations().catch((err) => {
    })

    if (accountLocationRes?.data?.accountLocations?.length) {
      setLocationFiltersState(prev => {
        return {
          ...prev,
          accountPracticeLocations: accountLocationRes?.data?.accountLocations || [] as IAccountLocation[]
        }
      })
    }
  }
  const dummyData = {
    data: 1,
    filterCode: 'LOCATION',
    resourceCode: data?.resourceCode,
    location: {
      name: 'new york',
      id: 12,
      uuid: 'rty8ytrfghjo9876trfghui8765redfgh',
    },
    ageGroupsList: [
      {
        code: 'GRE_20',
        name: 'greater the 20',
      },
      {
        code: 'LESS_20',
        name: 'Less than 20',
      },
      {
        code: 'EQ_20',
        name: 'Equal to 20',
      },
    ],
  };
  useEffect(() => {
    getAccountPracticeLocations()
  },[])
  const {width} = Dimensions.get('window');
  const finalWidth = width / 2.5;
  return (
    <Drawer
      width={finalWidth}
      visible={locationFiltersState.isVisible}
      onClose={() => {
        setLocationFiltersState((prev) => {
          return {
            ...prev,
            isVisible: false,
          };
        });
        onClose();
      }}
      title={<ModalActionTitle
        isHeadNotSticky={true}
        title={'Location filter ' + data.sectionCode}
        titleColor={''}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: 'cancel',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              setLocationFiltersState((prev) => {
                return {
                  ...prev,
                  isVisible: false,
                };
              });
              onClose();
            },
          },
          {
            show: true,
            id: 2,
            btnText: 'done',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.PRIMARY,
            // isDisabled: !checkObjectValues(),
            onClick: () => {
              const selectedLocation = {
                filterCode: 'LOCATION',
                filterValue: locationFiltersState.selectedLocationId,
                filterValueName: locationFiltersState.selectedLocationNames,
                resourceCode: data?.resourceCode,
              }
              onActionPerformed('FILTER',selectedLocation);
            },
          },
        ]}
      />}
    >
      <FormControl>
       <FormControl.Label>
          <Text color={Colors.Custom.Gray500} fontWeight={500} fontSize={14}>Location</Text>
        </FormControl.Label>
        <Select
          mode='multiple'
          placeholder="Select location"
          showSearch={false}
          onChange={(value, option) => {
            const selectedLocationObj = option.map((item: any)=> {
              return  item.children
            })
            setLocationFiltersState(prev => {
              return {
                ...prev,
                selectedLocationId: value,
                selectedLocationNames: selectedLocationObj
              }
            })
          }}
          value={locationFiltersState.selectedLocationId}
          style={{
            width: '100%',
            height: 40,
            minHeight: 40,
          }}
        >
          {locationFiltersState.accountPracticeLocations?.map((item) => (
            item?.practiceLocation?.name != null ? <Select.Option key={item.uuid}>{item?.practiceLocation?.name}</Select.Option>: <></>
          ))}
        </Select>
      </FormControl>
    </Drawer>
  );
};

export default LocationFilters;
