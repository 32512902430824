import { Drawer, Table} from 'antd';
import {
  Box,
  Text,
  View,
  useToast,
  Skeleton,
} from 'native-base';
import {Colors} from '../../../../../styles';
import {ScrollView } from 'native-base';
import { IFormSender, IPendingFormResponses } from '../interfaces';
import { getPendingFormResponsesTableColumns } from '../Helper';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import {FormsQueries} from '../../../../../services';
import {useQuery} from '@apollo/client';
import { useContext, useState } from 'react';
import { MLOV_CATEGORY, FORM_STATUS_CODE} from '../../../../../constants/MlovConst';
import {getMlovListFromCategory, getMlovIdFromCode,  getMlovValueFromId} from '../../../../../utils/mlovUtils';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { BUTTON_TYPE } from '../../../../../constants';
import { getFormSenderDetail } from '../../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ActivityAdditionalInfo/CommonComponent/FormResponse/FormUtils';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';


export interface IFormattedPendingFormResponseDrawerProps {
  isDrawerVisible: boolean;
  onClose: () => void;
  contactId: string;
  formId: string;
  formName: string;
  accountUsers?: IFormSender[];
}

const PendingFormRespDrawer = (props: IFormattedPendingFormResponseDrawerProps) => {

  const toast = useToast();
  const {isDrawerVisible, onClose, contactId, formId, formName, accountUsers} = props;
  const intl = useIntl();

  const mlovData = useContext(CommonDataContext);
  const formStatusMlovList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.FORM_STATUS,
  );
  const pendingStatusId = getMlovIdFromCode(formStatusMlovList, FORM_STATUS_CODE.PENDING);
  const draftStatusId = getMlovIdFromCode(formStatusMlovList, FORM_STATUS_CODE.DRAFT);
  const sourceMlovList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.FORM_SOURCE,
  );
  const [pendingFormList, setPendingFormList] = useState<
    IPendingFormResponses[]>([]);

  const formatResponse = (contactFormResponce: any) : IPendingFormResponses[] => {
    const formattedFormResponse: IPendingFormResponses[] = [];
      contactFormResponce.map((item: any) => {
        formattedFormResponse.push({
          name: formName,
          source: item.sourceId ? getMlovValueFromId(sourceMlovList, item.sourceId) : '',
          createdOn: item?.createdOn,
          sentBy: getFormSenderDetail(item, accountUsers || [], sourceMlovList),
      });
    });
    return formattedFormResponse;
  };

  const fetchPendingForms = useQuery(FormsQueries.GET_CONTACT_PENDING_FORM_LIST, {
    variables: {
      contactId: contactId,
      statusIds: [pendingStatusId, draftStatusId],
      formId: formId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (pendingFormData: any) => {
     setPendingFormList(formatResponse(pendingFormData.formLogs));
    },
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    onError: () => {
      showToast(
        toast,
        intl.formatMessage({id: 'errorMsg'}),
        ToastType.error,
      );
    }
  });

  return (
    <Drawer
      visible={isDrawerVisible}
      onClose={onClose}
      destroyOnClose
      width={'50%'}
      placement="right"
      title={
        <ModalActionTitle
          isHeadNotSticky
          title={`Pending Form Responses`}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'close',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                props?.onClose();
              },
            },
          ]}
        />
      }
    >
      {fetchPendingForms.loading ? (
        <View padding={4}>
          <Skeleton.Text lines={4} />
        </View>
      ) : (
        <View>
          {
            <Box
              borderColor={Colors.Custom.BorderColor}
              borderWidth={1}
              overflow="hidden"
              rounded="lg"
              marginBottom={2}
              height={850}
            >
              <View>
                <ScrollView>
                  <Table
                    dataSource={pendingFormList as []}
                    columns={getPendingFormResponsesTableColumns()}
                    pagination={false}
                  />
                </ScrollView>
              </View>
            </Box>
          }
        </View>
      )}
    </Drawer>
  );
};

export default PendingFormRespDrawer;
