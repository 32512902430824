import React from 'react';
import styles from './BillingCompletion.module.css';

interface StatusItemProps {
  iconSrc: string;
  text: string;
  alt: string;
}

const StatusItem: React.FC<StatusItemProps> = ({ iconSrc, text, alt }) => {
  return (
    <div className={styles.statusItem}>
      <img loading="lazy" src={iconSrc} className={styles.statusIcon} alt={alt} />
      <div className={styles.statusText}>{text}</div>
    </div>
  );
};

export default StatusItem;
