import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles';

export const styles = StyleSheet.create({
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 12,
  },
  titleText: {
    color: Colors.Custom.Gray700,
    fontSize: 16,
    fontWeight: '500',
  },
  careTeamMemberCardContainer: {
    borderColor: Colors.Custom.GRAY150,
    borderRadius: 8,
    borderWidth: 1,
    marginTop: 12,
    padding: 2,
  },
  headerText: {
    fontSize: 26,
    fontWeight: '500',
    color: Colors.Custom.Gray800,
  },
  tableContainer: {
    borderRadius: 8,
    borderColor: Colors.Custom.GRAY150,
    padding: 0,
    borderWidth: 1,
    overflow: 'hidden',
  },
  bulkInProgressViewContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  bulkInProgressSubText: {color: Colors.Custom.Gray700, fontWeight: '500'},
  previewViewContainer: {marginTop: 16},
  summaryCountViewContainer: {
    borderRadius: 8,
    borderColor: Colors.Custom.GRAY150,
    padding: 0,
    borderWidth: 1,
    marginTop: 8,
  },
  summaryViewContainer: {marginTop: 24},
  headerViewContainer: {alignItems: 'center', justifyContent: 'space-between'},
});
