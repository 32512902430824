import {Text} from "native-base";
import {CSVLink} from "react-csv";

const DownloadCptView = (props: any) => {
  const headers = props.downloadableCpt?.cptHeader

  const data = props.downloadableCpt?.cptList?.length ? props.downloadableCpt?.cptList : []
  return (
    <CSVLink filename='Sample-cpt' data={data} headers={headers}>
      <Text style={{color: '#8C5AE2'}} size={'xsSemibold'}>Download me</Text>
    </CSVLink>
  )
}
export default DownloadCptView;
