import BaseService from '../../../../services/CommonService/BaseService';
import {getUserUUID} from '../../../../utils/commonUtils';
import {IGridColumn} from '../interface';

export const useGridConfigurations = () => {
  const userUuid = getUserUUID();

  const getGridConfigurationDataFromAPI = async (
    tableCode: string,
    entityCode: string
  ) => {
    const baseService = BaseService.getSharedInstance().axios;
    const gridConfigurationUrl = `crm-nest/api/v1/grid-configurations/${tableCode}`;
    const gridResponse = await baseService.get(gridConfigurationUrl, {
      params: {
        entityCode: entityCode,
      },
    });
    return gridResponse?.data;
  };

  const formatGridColumnsData = (data: IGridColumn[]) => {
    return data
      .filter((column) => !column.isDeleted && column.isVisible)
      .sort((a, b) => a.sequence - b.sequence);
  };

  const getGridConfigurationData = async (
    tableCode: string,
    entityCode: string
  ) => {
    const gridResponse = await getGridConfigurationDataFromAPI(
      tableCode,
      entityCode
    );
    if (gridResponse?.gridColumns) {
      const gridColumnsData: IGridColumn[] = formatGridColumnsData(
        gridResponse?.gridColumns
      );
      return gridColumnsData;
    }
  };

  return {
    getGridConfigurationData,
  };
};
