import {UserActionCode} from './interfaces';

export const USER_ACTION_CODES: {[index in UserActionCode]: string} = {
  INVITE_STAFF_MEMBER: 'INVITE_STAFF_MEMBER',
  ADD_NEW_PROSPECT: 'ADD_NEW_PROSPECT',
  ADD_NEW_PATIENT: 'ADD_NEW_PATIENT',
  SCHEDULE_PATIENT: 'SCHEDULE_PATIENT',
  CONVERT_PROSPECT_TO_PATIENT: 'CONVERT_PROSPECT_TO_PATIENT',
  ADD_NEW_TASK: 'ADD_NEW_TASK',
  LOGIN_TO_MEMBER_PORTAL: 'LOGIN_TO_MEMBER_PORTAL',
  CHAT_WITH_A_TEAM_MATE: 'CHAT_WITH_A_TEAM_MATE',
  CONNECT_WEARABLE_WITHIN_MEMBER_APP: 'CONNECT_WEARABLE_WITHIN_MEMBER_APP',
  LOGIN_TO_FOLD_PROVIDER_APP: 'LOGIN_TO_FOLD_PROVIDER_APP',
  SEND_AN_EXISTING_FORM_TO_A_MEMBER: 'SEND_AN_EXISTING_FORM_TO_A_MEMBER',
  CONFIGURE_AN_EMAIL_TEMPLATE: 'CONFIGURE_AN_EMAIL_TEMPLATE',
  CREATE_A_MEMBER_GROUP: 'CREATE_A_MEMBER_GROUP',
  RUN_EXISTING_AUTOMATION_ON_A_MEMBER: 'RUN_EXISTING_AUTOMATION_ON_A_MEMBER',
  CONFIGURE_PHONE_NUMBER_FOR_SMS_AND_VOICE:
    'CONFIGURE_PHONE_NUMBER_FOR_SMS_AND_VOICE',
  CREATE_AN_EMPLOYER_AND_COUPLE_OF_EMPLOYEES:
    'CREATE_AN_EMPLOYER_AND_COUPLE_OF_EMPLOYEES',
  CREATE_AN_ONBOARDING_AUTOMATION: 'CREATE_AN_ONBOARDING_AUTOMATION',
  CREATE_A_CAMPAIGN: 'CREATE_A_CAMPAIGN',
  CONFIGURE_A_CARE_JOURNEY: 'CONFIGURE_A_CARE_JOURNEY',
  SETUP_A_PHONE_TREE: 'SETUP_A_PHONE_TREE',
  CREATE_A_TASK_POOL: 'CREATE_A_TASK_POOL',
  CREATE_A_NEW_FORM_USING_HEALTH_COMPONENT:
    'CREATE_A_NEW_FORM_USING_HEALTH_COMPONENT',
  HINT: 'HINT',
  ELATION: 'ELATION',
  SPRUCE: 'SPRUCE',
  SETUP_A_LEAD_GENERATION_WIDGET: 'SETUP_A_LEAD_GENERATION_WIDGET',
  CHECK_SCREENING_GROUPS: 'CHECK_SCREENING_GROUPS',
  EMAIL_WITH_UNITY: 'EMAIL_WITH_UNITY',
};
