import { IBatchedAccountUsersInterface } from "../CustomHooks/useGetBatchedAccountUsers";
import { ParticipantType } from "../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum";
import { BoardType } from "../common/CareDashboard/CareDashboardTopBar/interfaces";
import { IUserPool } from "../common/CareDashboard/CareDashboardWidget/UserAutoComplete";
import { ILabelTask, ITask } from "../common/CareDashboard/CareDashboardInterfaces";
import { ITaskCommentsList } from "../common/AddTask/interfaces";
import { TaskPanelType } from "../TaskCard/TaskEnum";

export enum TaskViewType {
  list = 'LIST',
  kanban = 'KANBAN'
}

export enum TaskNavigateContext {
  note = 'Note',
}

export enum MultiSelectState {
  none = 'NONE',
  all = 'ALL',
  some = 'SOME',
  disabled = 'DISABLED'
}

export enum MultiSelectAction {
  selectAllTasks = 'SELECT_ALL_TASKS',
  selectTask = 'SELECT_TASK',
  unSelectAllTask = 'UNSELECT_ALL_TASKS',
  unSelectTask = 'UNSELECT_TASK',
  enableMultiSelect = 'ENABLE_MULTI_SELECT',
  disableMultiSelect = 'DISABLE_MULTI_SELECT',
}

export enum BulkLabelChangeAction {
  addToExisting = 'ADD_EXISTING',
  replaceAllLabels = 'REPLACE_ALL',
  findAndReplace = 'FIND_REPLACE',
  findAndDelete = 'FIND_DELETE',
  deleteAll = 'DELETE_ALL',
}

export enum BulkViewCode {
  configure = 'Configure',
  preview = 'Preview',
  bulkInProgress = 'BulkInProgress',
}

export type TaskOrder = 'asc' | 'desc';

export interface ITaskOrderBy {
  endDateTime?: TaskOrder;
  createdOn?: TaskOrder;
  priority?: TaskOrder;
  title?: TaskOrder;
  mentionedDateTime?: TaskOrder;
  completedDateTime?: TaskOrder;
}

export interface ITaskFilter {
  assigneeIds?: (string | null)[];
  assigneeIdsNotIn?: string[];
  assigneeTypeCodes?: string[];
  assignedByIds?: string[];
  createdByIds?: string[];
  mentionedUserOrContactIds?: string[];
  contactOrAssigneeIds?: string[];
  contactIds?: string[];
  statusIds?: string[];
  statusIdsNotIn?: string[];
  statusCodesNotIn?: string[];
  priorityIds?: string[];
  startDateTime?: string;
  endDateTime?: string;
  userPoolIds?: string[];
  labels?: string[];
  searchString?: string;
  taskDueDateCategoryIds?: string[];
  orderBy?: ITaskOrderBy;
  limit?: number;
  offset?: number;
  timezone?: string;
  entityTypeIds?: string[];
  parentId?: string;
  includeDeletedTask?: boolean;
  orderId?: string;
  dueDateStartDateTime?: string;
  dueDateEndDateTime?: string;
}

export interface ITaskCategory {
  name: string;
  code: string;
  key: string;
  filters: ITaskFilter;
  skipTodayCountCall?: boolean;
  disableViewCategoriesCountRefresh?: boolean;
}

export interface ITaskDisplayConfig {
  boardType?: BoardType;
  viewType: TaskViewType;
  categories: ITaskCategory[];
  panelType?: TaskPanelType;
}

export interface ITaskMetaData {
  boardType?: BoardType;
  masterUsersList: IBatchedAccountUsersInterface[];
  masterPoolList: IUserPool[];
  personData?: any;
  restrictPatientOrLeadSelectionTo?: IUserPatientSearchItem;
  bulkTaskEnabled?: boolean;
  selectedTasks?: ITask[];
  onSelectedTaskChange?: (action: MultiSelectAction, tasks: ITask[]) => void;
  onNavigate?: (context: TaskNavigateContext, task?: ITask) => void;
  panelType?: TaskPanelType;
  loginUserLocations: any[];
  intialDataLoading?: boolean;
}

export interface IUserPatientSearchItem {
  key?: string; // contact - contactUuid, user - userUuid
  value?: string; // contact - contactUuid, user - userUuid
  label?: string; // contact - first name + last name, user - name
  type: ParticipantType; // staff, patient, prospect
  details: any; // entire object of contact or user
  contactData?: any;
  count?: number;
  isSelected?: boolean;
  contactId?: string;
}

export interface ITaskConflict {
  task: ITask;
  conflicts: IConflict[];
}

export interface IConflict {
  conflictCodes: string[];
  key: string;
}

export interface IBulkOperationData {
  priority?: {
    value: string | undefined;
    label: string;
    key: string;
  };
  status?: {
    value: string | undefined;
    label: string;
    key: string;
  };
  dueDate?: string;
  member?: IUserPatientSearchItem;
  selectedContact?: any;
  assignee?: IUserPatientSearchItem;
  taskPool?: {
    value: string | undefined;
    label: string;
    key: string;
  };
  labelOption?: {
    option: string;
    labels?: ILabelTask[];
    replaceLabelPairs?: {find?: ILabelTask; replace?: ILabelTask}[];
  },
  comment?: ITaskCommentsList;
}

export interface IUserDetails {
  id: number;
  uuid: string;
  name: string;
  email: string;
  userRoles: IUserRole[];
}

export interface IUserRole {
  id: string;
  accountLocationId: string | null;
  userRole: IRole;
}

export interface IRole {
  id: string;
  roleId: string;
  roleName: string;
  customRoleCode: string;
}
