import {useLazyQuery} from '@apollo/client';
import moment from 'moment';
import {View} from 'native-base';
import {useEffect, useState} from 'react';
import {StyleSheet} from 'react-native';
import {USER_ROLE_CODES} from '../../../../constants/MlovConst';
import {EmployerDashboard} from '../../../../services';
import {getCurrentUserRole, getUserData, isLoggedInUserGlobalAdmin, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner} from '../../../../utils/commonUtils';
import {ALL_MEMBERS_OBJ} from '../../../RightSideContainer/Analytics/EmployerAnalytics/EmployerUtils/EmployerConst';
import {Employer} from '../../../RightSideContainer/BrazilCodeMR/pages/analytics/AnalyticsController';
import TaskAnalyticsFilterView from './TaskAnalyticsFilterView';
import TaskAnalyticsPage from './TaskAnalyticsPage';
import {FILTER_TYPE_CODES, PATIENT_CONTEXT_VALUE} from './constants';
import {IPage, IPageData} from './interface';
import {
  ITaskLabels,
  ITaskParams,
} from '../../../RightSideContainer/Analytics/CommonComponents/Interfaces';
import { ILoginUserData } from '../../../../Interfaces';

const TaskAnalyticsView = () => { 
  const currentUserRoles = getCurrentUserRole();
  const userData: ILoginUserData = getUserData();
  const isBusinessOwner = isLoginUserBusinessOwner() || isLoggedInUserGlobalAdmin() || isLoggedInUserWorkFlowOrCustomerSuccess()
  const [taskAnalyticState, setTaskAnalyticState] = useState({
    page: {} as IPage,
    employerData: ALL_MEMBERS_OBJ as Employer,
    dateRange: {
      from: moment()
        .subtract(3, 'month')
        .startOf('month')
        .format('yyyy-MM-DDTHH:mm:ss'),
      to: moment().format('yyyy-MM-DDTHH:mm:ss'),
    },
    selectedTaskPriorityList: [] as string[],
    patientContext: PATIENT_CONTEXT_VALUE[0].code as string,
    taskLabelsIds: [] as string[],
    taskPoolsIds: [] as string[],
  });

  const [GetDefaultPage] = useLazyQuery(
    EmployerDashboard.GetDefaultPageForJourneyAnalytics,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const getPageDataDataByCode = async () => {
    let pageTypeCode = 'TASK_DEFAULT_DASHBOARD';
    if (isBusinessOwner) {
      pageTypeCode = 'TASK_ADMIN_DEFAULT_DASHBOARD';
    }
    const response: {
      data: IPageData;
    } = await GetDefaultPage({
      variables: {
        code: pageTypeCode,
      },
    });
    if (response.data.pages.length) {
      setTaskAnalyticState((prev) => {
        return {
          ...prev,
          page: response.data.pages[0],
        };
      });
    } else {
      setTaskAnalyticState((prev) => {
        return {
          ...prev,
          page: {} as IPage,
        };
      });
    }
  };

  const onFilterChange = (code: string, data: any) => {
    if (code === FILTER_TYPE_CODES.EMPLOYER) {
      setTaskAnalyticState((prev) => {
        return {
          ...prev,
          employerData: data,
        };
      });
    } else if (code === FILTER_TYPE_CODES.DATE_RANGE) {
      setTaskAnalyticState((prev) => {
        return {
          ...prev,
          dateRange: data,
        };
      });
    } else if (code === FILTER_TYPE_CODES.PRIORITY) {
      setTaskAnalyticState((prev) => {
        return {
          ...prev,
          selectedTaskPriorityList: data,
        };
      });
    } else if (code === FILTER_TYPE_CODES.PATIENT_CONTEXT) {
      setTaskAnalyticState((prev) => {
        return {
          ...prev,
          patientContext: data,
        };
      });
    } else if (code === FILTER_TYPE_CODES.LABELS) {
      setTaskAnalyticState((prev) => {
        return {
          ...prev,
          taskLabelsIds: data,
        };
      });
    } else if (code === FILTER_TYPE_CODES.TASK_POOL) {
      setTaskAnalyticState((prev) => {
        return {
          ...prev,
          taskPoolsIds: data,
        };
      });
    } else if (code === FILTER_TYPE_CODES.RESET) {
      setTaskAnalyticState((prev) => {
        return {
          ...prev,
          employerData: ALL_MEMBERS_OBJ as Employer,
          dateRange: {
            from: moment()
              .subtract(3, 'month')
              .startOf('month')
              .format('YYYY-MM-DDTHH:mm:ss'),
            to: moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
          },
          selectedTaskPriorityList: [] as string[],
          patientContext: '' as string,
          taskLabelsIds: [] as string[],
          taskPoolsIds: [] as string[],
        };
      });
    }
  };

  useEffect(() => {
    getPageDataDataByCode();
  }, []);
  return (
    <View style={styles.container}>
      <TaskAnalyticsFilterView
        code={'TaskAnalyticsFilterView'}
        onFilterChange={onFilterChange}
        employerData={taskAnalyticState?.employerData}
        selectedTaskPriorityList={taskAnalyticState?.selectedTaskPriorityList}
        dateRange={taskAnalyticState?.dateRange}
        patientContext={taskAnalyticState?.patientContext}
        taskLabelsIds={taskAnalyticState?.taskLabelsIds}
        taskPoolsIds={taskAnalyticState?.taskPoolsIds}
      />
      <TaskAnalyticsPage
        pageData={taskAnalyticState.page}
        taskAnalyticState={taskAnalyticState as any}
        code={'TaskAnalyticsPage'}
        userData={{
          isBusinessOwner: isBusinessOwner,
          uuid: userData?.uuid,
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#E9ECF1',
  },
});

export default TaskAnalyticsView;
