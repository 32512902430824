import {FHIR_RESOURCE} from '../../../../constants/FhirConstant';
import {IEhrCapability} from '../../../../Interfaces';
import {getCapability} from '../../../../utils/capabilityUtils';

export const isCurrentMenuLast = (
  index: number,
  personActionList: any[]
): boolean => {
  return index < personActionList.length - 1;
};

export const getProblemFormattedData = (
  listData: any,
  filterByStatus = 'active'
) => {
  const tempFormattedList: any = [];
  listData.map((item: any) => {
    const status = item?.clinicalStatus?.coding?.[0]
      ? item.clinicalStatus?.coding?.[0]?.code
      : item.clinicalStatus?.text;
    const tempObj = {
      id: item.id,
      name:
        item?.code?.text || item.code?.coding?.[0]?.display || item?.text?.div,
      date: item?.onsetDateTime || item?.recordedDate,
      status: status,
      recordedDate: item?.recordedDate
    };
    if (filterByStatus === 'all') {
      tempFormattedList.push(tempObj);
    } else if (filterByStatus?.toLowerCase() === status?.toLowerCase()) {
      tempFormattedList.push(tempObj);
    }
  });
  return tempFormattedList;
};

export const getImmunizationFormatedData = (listData: any) => {
  const tempFormatedList: any = [];

  listData.map((item: any) => {
    const name = item?.vaccineCode?.text
      ? item?.vaccineCode?.text
      : item?.vaccineCode?.coding?.length > 0
      ? item?.vaccineCode?.coding?.[0]?.display
      : '';
    const status = item.status ? item.status : 'Not Completed';
    const tempObj = {
      id: item?.id,
      name: name,
      date: item?.occurrence?.dateTime || item.occurrenceDateTime,
      status: status,
      primarySource: item.primarySource || false,
    };
    tempFormatedList.push(tempObj);
  });
  return tempFormatedList;
};

export const getMedicationFormatedData = (listData: any) => {
  const tempFormatedList: any = [];
  listData.map((item: any) => {
    const tempObj = {
      id: item.id,
      name: item?.medicationReference?.display ||
        item?.medicationCodeableConcept?.text ||
        item?.medicationCodeableConcept?.coding?.[0]?.display ||
        '',
      date: item?.effectivePeriod?.start ||
        item?.effectiveDateTime ||
        item?.resource?.dispenseRequest?.validityPeriod?.start ||
        item?.dateAsserted ||
        '',
      status: item.status,
    };
    tempFormatedList.push(tempObj);
  });
  return tempFormatedList;
};

export const getAllergyFormattedData = (
  listData: any[],
  allergyList: IEhrCapability
) => {
  const possibleSeverityValues =
    allergyList?.abilities?.keyAllowedOperations?.severity.possibleValues;
  const tempFormatedList: any = [];

  listData?.forEach((item: any) => {
    const status = getStatusTextForAllergies(item);
    const tempObj = {
      id: item.id,
      name: item.code?.text || item.code?.coding?.[0]?.display,
      date: item.onsetDateTime || item.recordedDate,
      status: status,
      reactions: (item.reaction || []).map((item: any) => ({
        severity: possibleSeverityValues?.find(
          (possibleSeverity: any) => possibleSeverity.code === item.severity
        )?.display,
        reaction: item.manifestation?.[0]?.text || item.manifestation?.[0]?.coding?.[0]?.display,
      })),
      recordedDate: item.recordedDate
    };
    if (isActiveStatus(status)) {
      tempFormatedList.push(tempObj);
    }
  });
  return tempFormatedList;
};

export const getStatusTextForAllergies = (resource: any) => {
  if (resource?.clinicalStatus?.coding?.length > 0) {
    return resource.clinicalStatus.coding[0].display || resource.clinicalStatus.coding[0].code;
  } else if (resource?.clinicalStatus?.text) {
    return resource.clinicalStatus.text;
  }
  return '';
};

export const isActiveStatus = (statusText: string) => {
  if (!statusText) {
    return false;
  }
  return statusText.toLocaleLowerCase() === 'active';
};
