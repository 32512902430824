import {v4} from 'uuid';
import {
  getEmailComponents,
  getFormattedEmailComponents,
} from '../EmailComponents/EmailComponentsUtils';
import {SingleEmailComponent} from '../EmailComponents/interfaces';
import {IPatientEducationContent, IPEDynamicZone} from './interfaces';
import {BASE_PREVIEW_URL, COMPONENT_TYPES} from './PatientEducationConsts';
import {getShortenedString} from '../MediaLibrary/MediaLibraryTable/Helper';
import {isEmptyString} from '../../../../utils/commonUtils';

export const getFormattedDynamicZoneData = (dynamicZoneData: any[]) => {
  const dynamicZone: IPEDynamicZone[] = [];
  let key = 1;

  dynamicZoneData.map((item: any) => {
    let mediaType = '';
    let mediaUrl = '';
    let mediaId = '';
    if (item.__component === 'pe-article.media') {
      if (item.media.data !== null) {
        mediaType = item.media.data.attributes.mime;
        mediaUrl = item.media.data.attributes.url;
        mediaId = item.media.data.id;
      }
    }
    const tempObj: IPEDynamicZone = {
      key: key,
      __component: item.__component,
      text: item.__component === COMPONENT_TYPES.TEXT ? item.text : '',
      description:
        item.__component === COMPONENT_TYPES.MEDIA ? item.description : '',
      componentTitle:
        item.__component === COMPONENT_TYPES.MEDIA
          ? 'media'
          : item.__component === COMPONENT_TYPES.EMBED
          ? 'Vimeo'
          : 'text',
      mediaType: item.__component === COMPONENT_TYPES.MEDIA ? mediaType : '',
      mediaUrl: item.__component === COMPONENT_TYPES.MEDIA ? mediaUrl : '',
      mediaId: item.__component === COMPONENT_TYPES.MEDIA ? mediaId : '',
      draggableId: v4(),
      link: item.__component === COMPONENT_TYPES.EMBED ? item.link : '',
    };

    dynamicZone.push(tempObj);
    key = key + 1;
  });

  return dynamicZone;
};

export const getFormattedPEContent = (patientEducationData: any) => {
  const data = patientEducationData.data;
  const formattedPEContent: IPatientEducationContent[] = [];

  data.map((item: any) => {
    const id = item.id;
    const title = item.attributes.title;
    const description = item.attributes.description;
    let dynamicZone: any[] = [];
    try {
      dynamicZone = getFormattedDynamicZoneData(item?.attributes?.dynamicZone);
    } catch (err) {
    }
    const lastUpdated = item.attributes.updatedAt;
    const slug = item.attributes.slug;
    const temp = {
      id: id,
      title: title || 'Untitled',
      description: description,
      dynamicZone: dynamicZone,
      lastUpdated: lastUpdated,
      slug: slug,
    };
    formattedPEContent.push(temp);
  });

  return formattedPEContent;
};

export const getSingleFormattedPEContent = (
  patientEducationData: any
): IPatientEducationContent => {
  const data = patientEducationData.data;
  const singleFormattedPEContent: IPatientEducationContent = {
    id: data.id,
    title: data.attributes.title,
    description: data.attributes.description,
    dynamicZone: getFormattedDynamicZoneData(data.attributes.dynamicZone),
    readTime: data.attributes.readTime,
  };

  return singleFormattedPEContent;
};

export const getFormattedDynamicZoneObject = (
  dynamicZoneData: IPEDynamicZone[]
) => {
  const dynamicZoneDataArray: any[] = [];
  dynamicZoneData.map((item) => {
    if (item.__component === 'pe-article.text') {
      dynamicZoneDataArray.push({
        __component: item.__component,
        // id: item.key,
        text: item.text,
      });
    } else if (item.__component === 'pe-article.media') {
      dynamicZoneDataArray.push({
        __component: item.__component,
        // id: item.key,
        description: item.description,
        media: {
          id: item.mediaId,
        },
      });
    } else if (item.__component === 'pe-article.embed') {
      dynamicZoneDataArray.push({
        __component: item.__component,
        link: item.link,
      });
    }
  });

  return dynamicZoneDataArray;
};

export const getPatientEducationObject = (
  patientEduContent: IPatientEducationContent
) => {
  const object = {
    data: {
      title: patientEduContent.title,
      description: patientEduContent.description,
      dynamicZone: getFormattedDynamicZoneObject(patientEduContent.dynamicZone),
      readTime: patientEduContent.readTime
        ? parseInt(patientEduContent.readTime as string)
        : 0,
    },
  };
  return object;
};

export const patientEduValidation = (patientEduContent: any) => {
  const errors: any = {};
  let validate = true;

  errors.title = !patientEduContent.title
    ? 'Title is required'
    : patientEduContent.title.length < 3
    ? 'Title is too short (minimum is 3 characters)'
    : '';

  if (errors.title || errors.description) {
    validate = false;
  }

  return {
    validated: validate,
    errors: errors,
  };
};

export const getPreviewLinkFromSlug = (slug: string) => {
  const completeUrl = `${BASE_PREVIEW_URL}/${slug}`;
  // const completeUrl = getCurrentSubdomainForPreview() + '/viewer/articles/' + slug;
  return completeUrl;
};

export const getHeaderAndFooter = async () => {
  try {
    const headerResponse = await getDefaultHeader();
    const footerResponse = await getDefaultFooter();
    const header = getFormattedEmailComponents({response: headerResponse});
    const footer = getFormattedEmailComponents({response: footerResponse});
    return {
      header,
      footer,
    };
  } catch (error) {}
};

export const getDefaultFooter = async () => {
  const response = await getEmailComponents({
    categoryQueryString: 'component_types=FOOTER',
    queryParams: {
      isDefault: true,
    },
  });
  return response;
};

export const getDefaultHeader = async () => {
  const response = await getEmailComponents({
    categoryQueryString: 'component_types=HEADER',
    queryParams: {
      isDefault: true,
    },
  });
  return response;
};

export const getTitleString = (title: string) => {
  return `Article / ${
    title ? getShortenedString({str: title, length: 30}) : 'Untitled'
  }`;
};

export const getTitleStringForListView = (title: string) => {
  if (!title) {
    return 'Untitled';
  }
  if (title.trim().length < 30) {
    return title.trim();
  }
  return title.trim().slice(0, 30) + '...';
};

export const isEmptyHtml = (htmlString: string) => {
  if (!htmlString) {
    return true;
  }
  const plainString = htmlString.replace(/<[^>]+>/g, '');
  return isEmptyString(plainString);
};
