import Stack from '../../../../../common/LayoutComponents/Stack';
import {FlatList} from 'react-native';
import {getLabReportsGraphData} from './LabReportsHelper';
import VitalGraphCard from '../HomeMonitoringView/components/VitalGraphCard';
import { getMeasure } from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils';

interface ILabReportsGraph {
  labReportsGroups: any[];
  ccmDate: string;
  goals?: any;
}

export const LabReportsGraph = (props: ILabReportsGraph) => {
  const {labReportsGroups, ccmDate} = props;
  const data = getLabReportsGraphData(labReportsGroups, ccmDate);
  return (
    <Stack direction="column">
      <FlatList
        data={data}
        numColumns={1}
        renderItem={({item}) => {
          const measure = getMeasure(item?.loinc, props?.goals);
          return (
            <>
            {/* Trend view */}
            {/* <CustomLineGraph
              vitalGraph={item}
              isSmallView={true}
              ccmDate={ccmDate}
            /> */}
            {/* Detail Card View */}
            <VitalGraphCard
              key={item?.loinc}
              vital={item}
              isLabGraphCard={true}
              lastRecorded={item?.lastRecordedData}
              measure={measure}
            />
            </>
          );
        }}
        key="lab-reports-flat-list"
        keyExtractor={(item) => item.code}
      />
    </Stack>
  );
};
