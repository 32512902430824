import {Badge, Text, View} from 'native-base';
import {Colors} from '../../../../../../../styles';
import {styles} from './TagViewStyles';

export const TagView = (props: {stringList: string[]}) => {
  const {stringList} = props;
  return (
    <View style={styles.tag}>
      {stringList.map((tagString) => {
        return (
          <View padding={2} key={'VStack_' + tagString + Math.random()}>
            <Badge
              colorScheme={Colors.Custom.Gray200}
              backgroundColor={Colors.Custom.Gray100}
              alignSelf="center"
              variant="transparent"
              marginRight={2}
              key={tagString + Math.random()}
              style={{borderRadius: 16}}
            >
              <Text size={'smMedium'} color={Colors.Custom.Gray500}>
                {tagString}
              </Text>
            </Badge>
          </View>
        );
      })}
    </View>
  );
};

export default TagView;
