import React from 'react';
import {Progress, Tooltip} from 'antd';
import {Colors} from '../../../../styles';
import {getSubstepIcon} from './ContactCareProgramView.utils';

import {MLOV_CATEGORY} from '../../../../constants';
import {getMlovObjectFromId} from '../../../../utils/mlovUtils';
import {useCareProgramStatus} from './hooks/useCareProgramStatus';
import useCareProgramStepStatus from './hooks/useCareProgramStepStatus';
import {testID} from '../../../../testUtils';

const SideBarStep = (props: {
  isActiveStep: boolean;
  onSelectStep: () => void;
  id: string;
  title: string;
  progress: number;
  statusId?: string;
  showProgress?: boolean;
}) => {
  const {isActiveStep, onSelectStep, id, title, progress, statusId, showProgress} = props;
  const {careProgramStepStatusList} = useCareProgramStepStatus();
  const statusMlov = getMlovObjectFromId(statusId as string, careProgramStepStatusList);
  const SubstepIcon = getSubstepIcon(statusMlov?.code as string);
  const status = statusMlov?.value;
  return (
    <div
      className={`sidebar-item-collapsible sidebar-item-child ${
        isActiveStep ? 'active-step' : 'cursor-pointer'
      }`}
      onClick={onSelectStep}
      {...testID(`${title}`)}
    >
      {isActiveStep && <div className={`active-step-indicator`} />}

      {SubstepIcon && (
        <Tooltip title={status}>
          <SubstepIcon />
        </Tooltip>
      )}
      <div className="flex flex-col sidebar-item-text w-full">
        <div className="">{title}</div>
        {showProgress && (
          <Progress
            percent={progress}
            showInfo={false}
            strokeColor={Colors?.Custom?.SuccessColor}
            size="small"
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(SideBarStep);
