import {useLazyQuery} from '@apollo/client';
import {useToast} from 'native-base';
import React, {useEffect, useState} from 'react';
import useGetBatchedAccountUsers from '../../../../../CustomHooks/useGetBatchedAccountUsers';
import {ToastType, showToast} from '../../../../../../utils/commonViewUtils';
import {AUDIT_ACTION_CODES} from '../../../../../common/Audit/AuditHelper';
import {getActionsForPopulationGroup} from '../PopulationGroupApi';
import {GET_FORM_NAME_BY_IDS} from '../../../../../../services/Forms/FormsQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {
  RESOURCE_TYPE_CODES,
  groupAuditDataByTimeStamp,
  parentResourceCodeFilters,
} from '../GroupDetailsUtils';
import {
  ActionTypeNames,
  IAllDataMappedData,
  IAuditLogData,
  IMappedAccountUsers,
  IRuleMemberData,
} from './interface';
import {GET_JOURNEY_NAME_BY_IDS} from '../../../../../../services/CareJourney/CareJourneyQueries';
import {
  getCurrentMonthEndDateTime,
  getEndOfDay,
  getStartOfMonthFromPastMonths,
} from '../../../../../../utils/DateUtils';
import {useIntl} from 'react-intl';
import {GROUP_AUDIT_LOG_PAGE_LIMIT} from './constant';
import { GET_ALL_USER_COUNT_FOR_GROUP_DETAILS_AUDIT, GET_ALL_USERS_BY_IDS_FOR_POP_GROUP_AUDIT } from '../../../../../../services/User/UserQueries';

const useGroupDetailsAudit = (ruleId: string) => {
  const toast = useToast();
  const intl = useIntl();
  const accountUsersMappedById: {[key: string]: IMappedAccountUsers} = {};
  const [auditLogsData, setAuditLogsData] = useState<IAuditLogData[]>([]);
  const [allDataMappedById, setAllDataMappedById] = useState<{
    [key: string]: IAllDataMappedData;
  }>({});
  const dateRangeForFilter = {
    from: getStartOfMonthFromPastMonths(3)?.toISOString(),
    to: getEndOfDay()?.toISOString(),
  };
  const [componentState, setComponentState] = useState({
    selectedFilters: {
      parentResourceType: parentResourceCodeFilters?.find(
        (resource) => resource.code === RESOURCE_TYPE_CODES.ALL
      ),
      dateRange: {
        from: dateRangeForFilter.from,
        to: dateRangeForFilter.to,
      },
    },
  });
  const [paginationState, setPaginationState] = useState({
    paginationLoading: false,
    pageSize: GROUP_AUDIT_LOG_PAGE_LIMIT,
    offset: 0,
    total: 0,
  });

  const [ruleMemberData, setRuleMemberData] = useState<IRuleMemberData>();

  useEffect(() => {
    fetchActions();
  }, []);

  const mappingNameToId = (
    data: {id: string; name: string; title: string}[]
  ) => {
    data.forEach((item) => {
      const {id, name, title} = item;
      setAllDataMappedById((prev) => ({
        ...prev,
        [id]: {id: id, name: name ? name : title},
      }));
    });
  };

  const [getFormNameQuery, {loading: formQueryLoading}] = useLazyQuery(
    GET_FORM_NAME_BY_IDS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getJourneyNameQuery, {loading: journeyQueryLoading}] = useLazyQuery(
    GET_JOURNEY_NAME_BY_IDS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const {
    loading: accountUserLoading,
    error: accountUsersError,
    userList: accountUserList,
  } = useGetBatchedAccountUsers({
    onError: () => {
      showToast(
        toast,
        'Error in fetching account users',
        ToastType.error,
        4000
      );
    },
    usersCountQueryName: GET_ALL_USER_COUNT_FOR_GROUP_DETAILS_AUDIT,
    usersQueryName: GET_ALL_USERS_BY_IDS_FOR_POP_GROUP_AUDIT,
  });

  const handleAuditLogData = (auditLogs: IAuditLogData[]) => {
    const formIds: string[] = [];
    const journeyIds: string[] = [];
    auditLogs.forEach((item) => {
      const idToAdd = item.parentResourceId;
      const isForm = item.parentResourceTypeCode === ActionTypeNames.FORM;
      const isJourney = item.parentResourceTypeCode === ActionTypeNames.JOURNEY;

      if (!allDataMappedById[item.parentResourceId]) {
        if (isForm && !formIds?.includes(idToAdd)) {
          formIds.push(idToAdd);
        } else if (isJourney && !journeyIds?.includes(idToAdd)) {
          journeyIds.push(idToAdd);
        }
      }
    });

    if (formIds.length > 0 || journeyIds.length > 0) {
      const promises: Promise<any>[] = [];

      if (formIds.length > 0) {
        promises.push(
          getFormNameQuery({
            variables: {
              formIds: formIds,
            },
          })
        );
      }

      if (journeyIds.length > 0) {
        promises.push(
          getJourneyNameQuery({
            variables: {
              journeyIds: journeyIds,
            },
          })
        );
      }

      Promise.all(promises)
        .then((responses) => {
          if (formIds.length > 0) {
            const formData = responses.shift()?.data?.forms;
            mappingNameToId(formData);
          }

          if (journeyIds.length > 0) {
            const journeyData = responses.shift()?.data?.careJourneys;
            mappingNameToId(journeyData);
          }
        })
        .catch((error) => {
          showToast(toast, 'Error in fetching audit details', ToastType.error);
        });
    }
  };

  accountUserList.forEach((user) => {
    const {uuid, id, name, email} = user;
    accountUsersMappedById[uuid] = {id, name, email};
  });

  const getParamsForPopGroupAudit = (args?: {
    dateRange?: { from: string; to: string };
    parentResourceTypeCode?: string;
    offset?: number;
  }): URLSearchParams => {
    const searchParams = new URLSearchParams();
    const parentResourceTypeCode =
      args?.parentResourceTypeCode ||
      componentState?.selectedFilters?.parentResourceType?.code;
    searchParams.append('rule-id', ruleId);
    if (
      parentResourceTypeCode &&
      parentResourceTypeCode !== RESOURCE_TYPE_CODES.ALL
    ) {
      searchParams.append('action-type', parentResourceTypeCode);
    }
    searchParams.append(
      'action-date',
      `ge${
        args?.dateRange?.from ||
        componentState?.selectedFilters?.dateRange?.from
      }`
    );
    searchParams.append(
      'action-date',
      `le${
        args?.dateRange?.to || componentState?.selectedFilters?.dateRange?.to
      }`
    );
    searchParams.append('limit', `${GROUP_AUDIT_LOG_PAGE_LIMIT}`);
    searchParams.append('offset', `${args?.offset ?? paginationState?.offset}`);
    return searchParams;
  };

  const handleActions = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case AUDIT_ACTION_CODES.SHOW_MORE:
        const paramsForAPI = getParamsForPopGroupAudit({
          offset: paginationState?.offset + GROUP_AUDIT_LOG_PAGE_LIMIT,
        });
        setPaginationState((prev) => ({
          ...prev,
          offset: paginationState?.offset + GROUP_AUDIT_LOG_PAGE_LIMIT,
        }));
        fetchActions(paramsForAPI);
        break;
      case AUDIT_ACTION_CODES.CHANGE_RESOURCE_CODE_FILTER:
        setComponentState((prev) => {
          return {
            ...prev,
            selectedFilters: {
              ...prev.selectedFilters,
              parentResourceType: actionData,
            },
          };
        });
        const params = getParamsForPopGroupAudit({
          parentResourceTypeCode: actionData.code,
          offset: 0,
        });
        setPaginationState((prev) => {
          return {
            ...prev,
            offset: 0,
          };
        });
        fetchActions(params);
        break;
      case AUDIT_ACTION_CODES.CHANGE_DATE_FILTER:
        setComponentState((prev) => {
          return {
            ...prev,
            selectedFilters: {
              ...prev.selectedFilters,
              dateRange: {
                from: actionData.from,
                to: actionData.to,
              },
            },
          };
        });
        const paramsToGetData = getParamsForPopGroupAudit({
          dateRange: actionData,
        });
        setPaginationState((prev) => {
          return {
            ...prev,
            offset: 0,
          };
        });
        fetchActions(paramsToGetData);
        break;
    }
  };

  const fetchActions = async (params?: URLSearchParams) => {
    try {
      setPaginationState((prev) => {
        return {
          ...prev,
          paginationLoading: true,
        };
      });
      const paramsForAPI: URLSearchParams =
        params || getParamsForPopGroupAudit();
      const response = await getActionsForPopulationGroup(paramsForAPI);
      if (response?.data) {
        setPaginationState((prev) => ({
          ...prev,
          total: response?.data?.total,
        }));
        setAuditLogsData((prev) => {
          const newState =
            Number(paramsForAPI.get('offset')) === 0
              ? response.data.auditLog
              : [...prev, ...response.data.auditLog];
          return newState;
        });
        handleAuditLogData(response?.data?.auditLog);
        setRuleMemberData(response?.data?.ruleMemberCount);
      }
    } catch (error) {
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    } finally {
      setPaginationState((prev) => {
        return {
          ...prev,
          paginationLoading: false,
        };
      });
    }
  };

  const monthMappedData = groupAuditDataByTimeStamp(auditLogsData);
  const showMoreData = auditLogsData.length < (paginationState?.total || 0);
  const showLessData = auditLogsData?.length > GROUP_AUDIT_LOG_PAGE_LIMIT;
  const loaders = {
    intialDataLoading:
      paginationState?.offset === 0 &&
      (paginationState?.paginationLoading || accountUserLoading),
    resourcesLoading: journeyQueryLoading || formQueryLoading,
    paginationLoading:
      paginationState?.offset !== 0 && paginationState?.paginationLoading,
  };

  return {
    accountUsersMappedById,
    allDataMappedById,
    monthMappedData,
    handleActions,
    showMoreData,
    selectedFilters: componentState?.selectedFilters,
    loaders,
    ruleMemberData,
  };
};

export default useGroupDetailsAudit;
