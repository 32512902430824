import React from 'react';

const EditTextSvg = (props: {
  isActive?: boolean;
  customActiveColor?: string;
  customColor?: string;
}) => {
  const strokeColor = props.isActive
    ? props.customActiveColor
      ? props.customActiveColor
      : ''
    : props.customColor
    ? props.customColor
    : '#4F2D90';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7 12.3332H13M10 1.33316C10.2652 1.06794 10.6249 0.918945 11 0.918945C11.1857 0.918945 11.3696 0.955525 11.5412 1.0266C11.7128 1.09767 11.8687 1.20184 12 1.33316C12.1313 1.46448 12.2355 1.62038 12.3066 1.79196C12.3776 1.96354 12.4142 2.14744 12.4142 2.33316C12.4142 2.51888 12.3776 2.70277 12.3066 2.87436C12.2355 3.04594 12.1313 3.20184 12 3.33316L3.66667 11.6665L1 12.3332L1.66667 9.66649L10 1.33316Z"
        stroke="#4F2D90"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EditTextSvg;
