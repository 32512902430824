import {Divider, Popover} from 'antd';
import React from 'react';
import MenuHorizontalIcon from '../../common/Svg/MenuHorizontalIcon';
import {Pressable, StyleSheet} from 'react-native';
import {useIntl} from 'react-intl';
import AssignActionSvg from '../../common/Svg/AssignActionSvg';
import {Colors} from '../../../styles/Colors';
import {Text} from 'native-base';
import Stack from '../../common/LayoutComponents/Stack';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {antStyles} from './CareManageMentViewStyles';
import {ICareProgramMoreActionView} from './interface';
import CareProgramAssignmentView from '../ContactCareProgram/ContactCareProgramView/components/CareProgramAssignmentView';
import {useCareProgramCloseAction} from './CareManagementViewHooks/useCareProgramCloseAction';
import {CARE_PROGRAM_CLOSE_REASONS} from '../CareManagementBilling/CareManagementConstants';
import WarningSvgV2 from '../../common/Svg/WarningSvgV2';
import {View} from 'react-native';
import CustomConfirmationModal from '../../common/CustomConfirmationModal/CustomConfirmationModal';
import {Radio, Input} from 'antd';
import {ToastType} from '../../../utils/commonViewUtils';
import {showToast} from '../../../utils/commonViewUtils';
import {useCustomToast} from '../../Toast/ToastProvider';
import {CONTACT_CARE_PROGRAM_STATUS_CODES} from '../../../constants/MlovConst';

const CareProgramMoreActionView = (props: ICareProgramMoreActionView) => {
  const intl = useIntl();
  const customToast = useCustomToast();
  const {TextArea} = Input;
  const [state, setState] = React.useState({
    popoverVisible: false,
    showConfirmationModal: false,
    closeReason: '',
    showTextArea: false,
  });

  const {onCloseCareProgram} = useCareProgramCloseAction();

  const handlePopoverVisibleChange = (visible: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        popoverVisible: visible,
      };
    });
  };

  const handleCloseCareProgram = async () => {
    try {
      await onCloseCareProgram(
        [props.contactCareProgramId],
        props.contactId,
        state.closeReason
      );
      setState((prev) => {
        return {
          ...prev,
          showConfirmationModal: false,
          popoverVisible: false,
        };
      });
      props?.onActionCloseCareProgram();
      showToast(
        customToast,
        intl.formatMessage({id: 'careProgramClosedSuccessMsg'}),
        ToastType.success,
        1000,
        true
      );
    } catch (error) {
      showToast(
        customToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
        1000,
        true
      );
    }
  };

  const onRadioChange = (e: any) => {
    const isOtherStatusReason =
      e?.target?.value === CARE_PROGRAM_CLOSE_REASONS.OTHER;
    setState((prev) => {
      return {
        ...prev,
        ...(!isOtherStatusReason && {closeReason: e?.target?.value}),
        showTextArea: isOtherStatusReason,
      };
    });
  };

  const popoverContent = (
    <Stack direction="column">
      <CareProgramAssignmentView
        contactCareProgramId={props?.contactCareProgramId}
        contactId={props?.contactId}
        onAssigneeActionPerformed={() => handlePopoverVisibleChange(false)}
        parentPopoverVisible={state.popoverVisible}
        onCareProgramAssigneeChange={props?.onCareProgramAssigneeChange}
        popoverContent={
          <Stack direction="row" style={styles.popoverContent}>
            <AssignActionSvg
              customStrokeColor={Colors.Custom.Gray500}
              width={16}
              height={16}
            />
            <Text
              color={Colors.Custom.Gray500}
              style={styles.popoverContentText}
            >
              {intl.formatMessage({id: 'assignTo'})}
            </Text>
          </Stack>
        }
      />
      {[
        CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS,
        CONTACT_CARE_PROGRAM_STATUS_CODES.NEW,
      ].includes(props.careProgramStatusCode) && (
        <>
          <Divider
            type="horizontal"
            orientationMargin={0}
            style={antStyles.horizontalDivider}
          />
          <Pressable
            onPress={() =>
              setState((prev) => {
                return {
                  ...prev,
                  showConfirmationModal: true,
                  popoverVisible: false,
                };
              })
            }
          >
            <Stack direction="row" style={styles.popoverContent}>
              <AntIcon
                name="close"
                color={Colors.Custom.crossIconColor}
                size={12}
              />
              <Text
                color={Colors.Custom.crossIconColor}
                style={styles.popoverContentText}
              >
                {intl.formatMessage({id: 'endProgram'})}
              </Text>
            </Stack>
          </Pressable>
        </>
      )}
    </Stack>
  );

  return (
    <View>
      <Popover
        overlayClassName="contact-popover"
        visible={state.popoverVisible}
        onVisibleChange={() => handlePopoverVisibleChange(false)}
        content={popoverContent}
        placement={'bottomRight'}
        overlayInnerStyle={{
          padding: 8,
          borderRadius: 8,
        }}
        align={{
          offset: [5, -10],
        }}
      >
        <Pressable
          onPress={() => handlePopoverVisibleChange(!state.popoverVisible)}
        >
          <MenuHorizontalIcon width={20} height={20} />
        </Pressable>
      </Popover>
      {state.showConfirmationModal && (
        <CustomConfirmationModal
          isOpen={state.showConfirmationModal}
          onCancel={() =>
            setState((prev) => {
              return {
                ...prev,
                showConfirmationModal: false,
              };
            })
          }
          onConfirm={handleCloseCareProgram}
          disabled={!state.closeReason}
          headerText="closeCareProgramHeaderText"
          customIcon={
            <WarningSvgV2 strokeColor={Colors.Custom.crossIconColor} />
          }
          modalContent={
            <View style={styles.confirmationModalContent}>
              <Text style={styles.confirmationModalText}>
                {intl.formatMessage({id: 'closeCareProgramMsg'})}
              </Text>
              <Radio.Group onChange={onRadioChange}>
                <View style={{marginTop: 16}}>
                  <Radio
                    value={CARE_PROGRAM_CLOSE_REASONS.PATIENT_DECEASED}
                    style={{marginBottom: 8, color: Colors.Custom.Gray500}}
                  >
                    {intl.formatMessage({id: 'patientDeceased'})}
                  </Radio>
                  <Radio
                    value={
                      CARE_PROGRAM_CLOSE_REASONS.NO_LONGER_MANAGING_PATIENT
                    }
                    style={{marginBottom: 8, color: Colors.Custom.Gray500}}
                  >
                    {intl.formatMessage({id: 'noLongerManagingPatient'})}
                  </Radio>
                  <Radio
                    value={CARE_PROGRAM_CLOSE_REASONS.OTHER}
                    style={{color: Colors.Custom.Gray500}}
                  >
                    {intl.formatMessage({id: 'otherReasons'})}
                  </Radio>
                </View>
              </Radio.Group>
              {state.showTextArea && (
                <View style={styles.confirmationModalRadioGroup}>
                  <TextArea
                    placeholder="Please add any specific reason"
                    maxLength={150}
                    onChange={(e) =>
                      setState((prev) => {
                        return {
                          ...prev,
                          closeReason: e?.target?.value,
                        };
                      })
                    }
                    style={{
                      marginTop: 8,
                      borderRadius: 4,
                      padding: 8,
                      height: 90,
                      resize: 'none',
                    }}
                    showCount
                  />
                </View>
              )}
            </View>
          }
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  divider: {
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
  },
  popoverContent: {
    paddingVertical: 4,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  popoverContentText: {
    fontSize: 14,
    fontWeight: '500',
    paddingLeft: 4,
  },
  confirmationModalContent: {
    marginTop: 2,
  },
  confirmationModalRadioGroup: {
    marginTop: 16,
  },
  confirmationModalText: {
    color: Colors.Custom.Gray300,
    fontSize: 14,
    lineHeight: 16.8,
    fontWeight: '400',
    textAlign: 'center',
  },
});

export default React.memo(CareProgramMoreActionView);
