import React, {useEffect, useState} from 'react';
import {Spinner, Text, View} from 'native-base';
import {styles} from './PrintPreviewStyles';
import {IPrintPreviewProps} from '.';
import {useLocation} from 'react-router-dom';
import {CustomPrintStyle} from '../Analytics/EmployerAnalytics/CustomPrint/CustomCss';
import {Colors} from '../../../styles/Colors';
import LoadingSpinner from '../../common/Loader/LoadingSpinner';

const PrintPreviewDownload = (props: IPrintPreviewProps) => {
  const {state}: any = useLocation();
  const [loading, setLoading] = useState(true);

  const htmlText = `<div><style>${CustomPrintStyle}</style>${state?.html}</div>`;
  useEffect(() => {
    setTimeout(() => {
      printPage();
    }, 2000);
  }, []);

  const printPage = () => {
    const $supportButton: any = document?.querySelector('#jsd-widget')
    if ($supportButton) {
      $supportButton.style.display = 'none';
    } else {
    }
    setLoading(true);
    window.print();
    setLoading(false);
  };
  return (
    <>
      <div
        style={{
          paddingLeft: 14,
          paddingRight: 14,
          paddingTop: 8,
          paddingBottom: 8,
          borderRadius: 20,
          borderWidth: 1,
          borderStyle: 'solid',
          fontSize: 14,
          cursor: 'pointer',
          borderColor: Colors.secondary['200'],
          color: Colors.secondary['800'],
          backgroundColor: Colors.secondary['100'],
          marginRight: 10,
          fontFamily: 'Manrope-Regular',
          position: 'absolute',
          right: 50,
          top: 50,
          display: 'flex',
          zIndex: 121212
        }}
        className="hidePrintBtn"
        onClick={() => {
          printPage();
        }}
      >
        {loading && (
          <Spinner
            marginRight={'5px'}
            size="sm"
            color={Colors.secondary['800']}
          />
        )}
        {'Download Report'}
      </div>
      <div
        style={{
          backgroundColor: '#ffffff',
        }}
        dangerouslySetInnerHTML={{
          __html: htmlText,
        }}
      ></div>
    </>
  );
};

export default PrintPreviewDownload;
