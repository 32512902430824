import {StyleSheet} from 'react-native';
import { Colors } from '../../../../styles/Colors';
export const styles = StyleSheet.create({
  spinnerStyle: {
    margin: '0 auto',
    color: 'black',
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0,
  },
  iconView: {
    flex: 0.8,
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  pressable: {
    paddingBottom: 8,
    paddingTop: 12,
    paddingLeft: 4,
    borderBottomWidth: 1,
    borderColor: Colors.Custom.Gray200,
  },
  mh12pv11mr4: {
    paddingHorizontal: 12,
    paddingVertical: 11,
    marginRight: 4,
  },
  mh12pv11: {
    paddingHorizontal: 12,
    paddingVertical: 11,
  },
  box36: {
    width: 36,
    height: 36,
  },
  bgTransparent: {
    backgroundColor: 'transparent',
  },
  featherIcon: {
    marginLeft: 2,
    marginRight: 2,
    fontSize: 14.29,
    color: Colors.Custom.Gray600,
  },
  p0: {
    padding: 0,
  },
  ph12pv11mr4: {
    paddingHorizontal: 12,
    paddingVertical: 11,
    marginRight: 4,
  },
  ph12pv11: {
    paddingHorizontal: 12,
    paddingVertical: 11,
  },
  ml12: {
    marginLeft: 12,
  },
  mt4: {
    marginTop: 4,
  },
  p10: {
    padding: 10,
  },
  colorError500: {
    color: Colors.error['500'],
  },
  p24pt0: {
    padding: 24,
    paddingTop: 0,
  },
  mb32: {
    marginBottom: 32,
  },
  flexRowAlignCenterJcSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  view: {
    width: 'max-content',
    paddingHorizontal: 8,
    paddingVertical: 3,
    borderRadius: 20,
    marginRight: 2,
    // backgroundColor: Colors.Custom.mainPrimaryPurple,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray100,
    marginBottom: 32,
    flexDirection: 'row',
    alignItems: 'center',
  },
  pb20: {
    paddingBottom: 20,
  },
  flexRowAlignCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  view1: {
    width: 35,
    height: 19,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ml10: {
    marginLeft: 10,
  }
});
