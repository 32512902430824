import React from 'react';
import {Colors} from '../../../../styles/Colors';
import {ICommonSvgProps} from '../interfaces';

const ProductSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';
  return (
    <svg
      style={{width: 'inherit', height: 'inherit'}}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.125 10.625V18.125C18.125 18.4565 17.9933 18.7745 17.7589 19.0089C17.5245 19.2433 17.2065 19.375 16.875 19.375H3.125C2.79348 19.375 2.47554 19.2433 2.24112 19.0089C2.0067 18.7745 1.875 18.4565 1.875 18.125V10.625"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.6233 0.625H2.37667C2.23441 0.624977 2.0964 0.673416 1.98536 0.762335C1.87433 0.851254 1.7969 0.975348 1.76583 1.11417L0.625 6.25C0.625 6.74728 0.822544 7.22419 1.17417 7.57582C1.52581 7.92746 2.00272 8.125 2.5 8.125C2.99728 8.125 3.47419 7.92746 3.82582 7.57582C4.17746 7.22419 4.375 6.74728 4.375 6.25C4.375 6.74728 4.57254 7.22419 4.92417 7.57582C5.27581 7.92746 5.75272 8.125 6.25 8.125C6.74728 8.125 7.22419 7.92746 7.57582 7.57582C7.92746 7.22419 8.125 6.74728 8.125 6.25C8.125 6.74728 8.32254 7.22419 8.67417 7.57582C9.02581 7.92746 9.50272 8.125 10 8.125C10.4973 8.125 10.9742 7.92746 11.3258 7.57582C11.6775 7.22419 11.875 6.74728 11.875 6.25C11.875 6.74728 12.0725 7.22419 12.4242 7.57582C12.7758 7.92746 13.2527 8.125 13.75 8.125C14.2473 8.125 14.7242 7.92746 15.0758 7.57582C15.4275 7.22419 15.625 6.74728 15.625 6.25C15.625 6.74728 15.8225 7.22419 16.1742 7.57582C16.5258 7.92746 17.0027 8.125 17.5 8.125C17.9973 8.125 18.4742 7.92746 18.8258 7.57582C19.1775 7.22419 19.375 6.74728 19.375 6.25L18.2333 1.11417C18.2026 0.975344 18.1253 0.851183 18.0144 0.762229C17.9035 0.673275 17.7655 0.624862 17.6233 0.625Z"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.875 14.375H12.5"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 19.375V10.625"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default React.memo(ProductSvg);
