import React from 'react';

const CallAccept = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="48"
      fill="none"
      viewBox="0 0 49 48"
    >
      <g filter="url(#filter0_d_20003227_34671)">
        <path
          fill="#12B76A"
          d="M2.5 23c0-12.15 9.85-22 22-22s22 9.85 22 22-9.85 22-22 22-22-9.85-22-22z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M32.833 27.098v2.5a1.667 1.667 0 01-1.816 1.666 16.49 16.49 0 01-7.192-2.558 16.248 16.248 0 01-5-5 16.492 16.492 0 01-2.558-7.225 1.668 1.668 0 011.658-1.817h2.5a1.667 1.667 0 011.666 1.434c.106.8.302 1.585.584 2.341a1.668 1.668 0 01-.375 1.759l-1.059 1.058a13.333 13.333 0 005 5l1.059-1.058a1.665 1.665 0 011.758-.375 10.7 10.7 0 002.342.583 1.666 1.666 0 011.433 1.692z"
        ></path>
        <path
          fill="#12B76A"
          d="M24.5 44c-11.598 0-21-9.402-21-21h-2c0 12.703 10.297 23 23 23v-2zm21-21c0 11.598-9.402 21-21 21v2c12.703 0 23-10.297 23-23h-2zm-21-21c11.598 0 21 9.402 21 21h2c0-12.703-10.297-23-23-23v2zm0-2c-12.703 0-23 10.297-23 23h2c0-11.598 9.402-21 21-21V0z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_20003227_34671"
          width="48"
          height="48"
          x="0.5"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_20003227_34671"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_20003227_34671"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default CallAccept;
