import React, {useCallback, useEffect, useReducer} from 'react';
import {
  ContactCareProgramReducer,
  contactCareProgramReducer,
  ContactCareProgramReducerAction,
} from './reducer';
import {CareProgramStatusAction} from './hooks/useCareProgramStatusAction';
import useCareProgramStepStatus from './hooks/useCareProgramStepStatus';
import useCareProgramStatusAction from './hooks/useCareProgramStatusAction';
import {useLazyQuery} from '@apollo/client';
import {GET_CONTACT_CARE_PROGRAM_DETAILS} from '../../../../services/ContactCareProgram/ContactCareProgram';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {
  ContactCareProgramComponentStatus,
  IContactCareProgram,
  IContactCareProgramState,
} from '../interface';
import {
  getFormResponseProgressByAssessmentLogs,
  getInitSelectedStepAndCollapseIds,
  groupStepsNested,
} from './ContactCareProgramView.utils';
import {GET_MULTIPLE_FORM_RESPONSE_BY_FORM_LOG_IDS} from '../../../../services/Forms/FormsQueries';
import useFormOptions from './hooks/useFormOptions';
import {usePersonOmniViewContext} from '../../../PersonOmniView/PersonOmniView.context';
import {useCustomToast} from '../../../Toast/ToastProvider';
import {ToastType} from '../../../../utils/commonViewUtils';

const useContactCareProgram = (props: {contactCareProgramId: string}) => {
  // Constants
  const {contactCareProgramId} = props;
  const [componentState, dispatch] = useReducer<ContactCareProgramReducer>(
    contactCareProgramReducer,
    {
      contactCareProgramDetails: undefined,
      status: undefined,
      selectedStepId: undefined,
      activeStepId: undefined,
      sideBarExpandedStepKeysSet: new Set<string>(),
      groupedSteps: [],
      stepProgressMap: new Map<string, number>(),
    }
  );
  const {careProgramStepStatus} = useCareProgramStepStatus();
  const {handleAction} = useCareProgramStatusAction();
  const toast = useCustomToast();

  const handleOnError = () => {
    toast({
      message: 'Failed to fetch contact care program details',
      toastType: ToastType.error,
    });
    dispatch({
      type: ContactCareProgramReducerAction.SET_STATUS,
      payload: ContactCareProgramComponentStatus.ERROR,
    });
  };

  const [getContactCareProgramDetails, {
    loading: isLoadingContactCareProgramDetails,
    refetch: refetchContactCareProgramDetails
  }] = useLazyQuery(
    GET_CONTACT_CARE_PROGRAM_DETAILS,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      onCompleted: async (response) => {
        const careProgramDetail = response
          ?.contactCarePrograms?.[0] as IContactCareProgram;

      // Get the form responses
      const formProgress = await getFormResponseProgressByAssessmentLogs(
        careProgramDetail?.assessmentLogs || [],
        getFormResponses,
      );

      // Group the steps
      const groupedSteps = groupStepsNested(
        careProgramDetail?.contactCareProgramSteps || []
      );

      // Get the initial selected step and the expanded step ids
      const {initSelectedStepId, expandStepIds} =
        getInitSelectedStepAndCollapseIds(
          groupedSteps,
          careProgramDetail?.stepsLog || [],
          [careProgramStepStatus.in_progress, careProgramStepStatus.to_do]
        );

      // Get the status code
      const statusCode = response?.contactCarePrograms?.[0]
        ? ContactCareProgramComponentStatus.SUCCESS
        : ContactCareProgramComponentStatus.EMPTY;

      // Set the initial data
      dispatch({
        type: ContactCareProgramReducerAction.SET_INIT_DATA,
        payload: {
          contactCareProgramDetails: careProgramDetail,
          groupedSteps,
          status: statusCode,
          selectedStepId: initSelectedStepId,
          sideBarExpandedStepKeysSet: new Set(expandStepIds),
          stepProgressMap: formProgress,
        } as Partial<IContactCareProgramState>,
      });
      },
      onError: handleOnError,
    }
  );

  const [getFormResponsesQuery] = useLazyQuery(
    GET_MULTIPLE_FORM_RESPONSE_BY_FORM_LOG_IDS,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const getFormResponses = async (formLogIds: string[]) => {
    const formResponses = await getFormResponsesQuery({
      variables: {
        formLogId: formLogIds,
      },
    });
    return formResponses?.data;
  };

  const onUpdateCareProgramStatus = async (
    action: CareProgramStatusAction,
    params: {
      careProgramId: string;
      contactId: string;
    }
  ) => {
    const newStatusId = await handleAction(action, params);
    if (newStatusId) {
      dispatch({
        type: ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_STATUS,
        payload: newStatusId,
      });
    }
  };

  // Side Effects
  useEffect(() => {
    fetchContactCareProgramDetails();
  }, [contactCareProgramId]);

  // Functions
  const fetchContactCareProgramDetails = async () => {
    try {
      dispatch({
        type: ContactCareProgramReducerAction.SET_STATUS,
        payload: ContactCareProgramComponentStatus.LOADING,
      });
      await getContactCareProgramDetails({
        variables: {
          id: contactCareProgramId,
        },
        fetchPolicy: 'no-cache',
      });
    } catch (error) {
      handleOnError();
    }
  };

  const handleStepClick = useCallback(
    (stepId: string) => {
      dispatch({
        type: ContactCareProgramReducerAction.SET_SELECTED_STEP_ID,
        payload: stepId,
      });
    },
    [dispatch]
  );

  const handleHeaderAction = useCallback(
    (action: ContactCareProgramReducerAction, payload: string) => {
      dispatch({
        type: action,
        payload: payload,
      });
    },
    [dispatch]
  );

  return {
    componentState,
    onUpdateCareProgramStatus,
    handleStepClick,
    handleHeaderAction,
    dispatch,
    isLoadingContactCareProgramDetails,
    refetchContactCareProgramDetails,
  };
};

export default useContactCareProgram;
