import {Table} from 'antd';
import {View} from 'native-base';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {ICustomContactTypesTableData, ICustomContactTypesTableProps} from '../interfaces';
import { getContactTypesColumns } from './Helper';

const CustomContactTypesTable = (props: ICustomContactTypesTableProps) => {
  const {data, onEdit } = props;
  const screenHeight = Dimensions.get('window').height;

  const onCustomContactTypesTableActionPerformed = (
    actionCode: string,
    rowRecord: ICustomContactTypesTableData
  ): any => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        onEdit(rowRecord);
        break;

      default:
        break;
    }
  };

  return (
    <View flex={1}>
      <Table
        style={{
          backgroundColor: '#fff',
        }}
        scroll={{x: 700, y: screenHeight - 260}}
        columns={getContactTypesColumns(
          onCustomContactTypesTableActionPerformed
        )}
        dataSource={data}
        pagination={false}
        rowKey={(record: ICustomContactTypesTableData) => record.id}
        onRow={(record: ICustomContactTypesTableData) => {
          return !record.isDefault ? {
            onClick: () => {
              onCustomContactTypesTableActionPerformed(
                COMMON_ACTION_CODES.EDIT,
                record
              );
            },
          }: {
            onClick: () => {
            }
          }
        }}
      />
    </View>
  );
};

export default CustomContactTypesTable;
