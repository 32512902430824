import React from 'react';
import { View, ViewStyle } from 'react-native';
import { AwvSvgStyles } from './AwvSvgStyles';
import { Path, Svg } from 'react-native-svg';

const AwvCompletedIconSvg = (props: { style?: ViewStyle }) => {

  return (
    <View style={[AwvSvgStyles.container, props.style]}>
      <Svg
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 16 16"
      >
        <Path
          fill="#A7AFBE"
          d="M4.167 2.359a.5.5 0 101 0h-1zm1-1.026a.5.5 0 00-1 0h1zm5.666 1.026a.5.5 0 001 0h-1zm1-1.026a.5.5 0 10-1 0h1zM1.667 5.278a.5.5 0 100 1v-1zm12.666 1a.5.5 0 100-1v1zM6.358 9.189a.5.5 0 00-.716.699l.716-.699zm.975 1.717l-.358.35a.5.5 0 00.716 0l-.358-.35zm3.025-2.386a.5.5 0 00-.716-.698l.716.698zM6.667 2.859h2.666v-1H6.667v1zm7.5 4.97v1.368h1V7.829h-1zm-4.834 6.338H6.667v1h2.666v-1zm-7.5-4.97V7.829h-1v1.368h1zm4.834 4.97c-1.272 0-2.174-.001-2.858-.096-.668-.092-1.054-.264-1.337-.554l-.716.698c.499.51 1.132.739 1.916.847.769.106 1.752.105 2.995.105v-1zM.833 9.197c0 1.275 0 2.28.102 3.066.105.798.325 1.442.821 1.952l.716-.698c-.284-.292-.455-.693-.545-1.384-.093-.705-.094-1.633-.094-2.936h-1zm13.334 0c0 1.303-.001 2.231-.094 2.936-.09.691-.261 1.092-.545 1.384l.716.698c.496-.51.716-1.154.82-1.952.104-.785.103-1.79.103-3.066h-1zm-4.834 5.97c1.243 0 2.226 0 2.995-.105.784-.108 1.417-.336 1.916-.847l-.716-.698c-.283.29-.669.462-1.337.554-.684.095-1.586.096-2.858.096v1zm0-12.308c1.272 0 2.174.001 2.858.095.668.093 1.054.265 1.337.555l.716-.698c-.499-.511-1.132-.74-1.916-.847-.769-.106-1.752-.105-2.995-.105v1zm5.834 4.97c0-1.275 0-2.281-.102-3.066-.105-.798-.325-1.443-.821-1.952l-.716.698c.284.292.455.692.545 1.384.093.705.094 1.633.094 2.936h1zm-8.5-5.97c-1.243 0-2.226-.001-2.995.105-.784.108-1.417.336-1.916.847l.716.698c.283-.29.669-.462 1.337-.555.684-.094 1.586-.095 2.858-.095v-1zm-4.834 5.97c0-1.303.001-2.231.094-2.936.09-.692.26-1.092.545-1.384l-.716-.698c-.496.51-.716 1.154-.82 1.952-.104.785-.103 1.79-.103 3.066h1zm3.334-5.47V1.333h-1V2.36h1zm6.666 0V1.333h-1V2.36h1zM1.667 6.278h12.666v-1H1.667v1zm3.975 3.61l1.333 1.367.716-.698-1.333-1.368-.716.699zm2.05 1.367l2.666-2.735-.716-.698-2.667 2.735.716.698z"
        ></Path>
      </Svg>
    </View>
  );
};

export default AwvCompletedIconSvg;
