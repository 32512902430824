import { useEffect, useState } from 'react';
import {Select, Select as MultiSelect} from 'antd';
import UserWithRoleItem from '../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/UserWithRoleItem';
import { ALL_USERS_CODE, getUsersName } from '../../RightSideContainer/UserScheduleSetting/UserScheduleHelper';
const { Option } = Select;

interface IModalActionUserAntMultiSelect {
    value?: any;
    defaultValue?: any;
    onChange?: any;
    data: any[];
}

export const ModalActionUserMultiSelect = (props: IModalActionUserAntMultiSelect) => {
    const {
        value,
        defaultValue,
        onChange,
        data,
    } = props;
    const [dataIs, setData] = useState([] as any)

    useEffect(() => {
        setData(data)
    }, [data])

    const handleUsersChange = (values: string[], selectedData: any[]) => {
        let userIds: string[] = [];
        const userSelectedIds = selectedData.map((item: any) => {
            return item.userId;
        })
        const allUser = selectedData.find((item: any) => {
            return item.key === ALL_USERS_CODE;
        })

        if (allUser) {
            (dataIs || []).forEach((user: any) => {
                if (user?.userId !== ALL_USERS_CODE) {
                    userIds.push(user.userId);
                }
            });
        } else {
            userIds = userSelectedIds;
        }
        onChange && onChange(userIds);
    }

    return (
        <MultiSelect
            mode="multiple"
            size="large"
            allowClear
            //defaultValue={getUsersName([...defaultValue], dataIs)}
            value={getUsersName([...value], dataIs)}
            placeholder="Select Users"
            onChange={handleUsersChange}
            maxTagCount={'responsive'}
        >
            {dataIs.map((agentRecord: any) => {
                return (
                    <Option
                        key={agentRecord?.userId}
                        userId={agentRecord?.userId || agentRecord?.id}
                        value={agentRecord?.user?.name || agentRecord?.name || agentRecord?.userName}
                    >
                        <UserWithRoleItem
                            agentRecord={agentRecord}
                        />
                    </Option>
                );
            })}
        </MultiSelect>
    );
};
