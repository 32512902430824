import {HStack, Text, VStack} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
// import {useIntl} from 'react-intl';
import {Platform, Pressable, View} from 'react-native';
import {MLOV_CATEGORY} from '../../../../constants';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {Colors} from '../../../../styles';
import {getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {DisplayText} from '../../DisplayText/DisplayText';
import {getColors} from './HelperUtils';
import {IStatusFilterList} from './interfaces';

const StatusFilterList = (props: IStatusFilterList) => {
  // const intl = useIntl();
  const {onStatusChange} = props;
  const commonData = useContext(CommonDataContext);
  const [dataList, setDataList]: any = useState();

  const [selected, setSelected] = useState('All');
  useEffect(() => {
    const statusList: any = getMlovListFromCategory(
      commonData.MLOV,
      MLOV_CATEGORY.CONVERSATION_STATUS
    );
    const addedNewobjArr = {
      showAll: true,
      code: 'All',
    };
    const filterSnoozed = statusList.filter((item: any) => {
      return item.code != 'snoozed';
    });
    let filterMuted = filterSnoozed.filter((item: any) => {
      return item.code != 'muted';
    });
    filterMuted = filterMuted.map((el: any) => {
      if (el.code === 'open') return {...el, code: 'openChat'};
      if (el.code === 'resolved') return {...el, code: 'closeChat'};
      else return el;
    });
    setDataList([addedNewobjArr, ...filterMuted]);
  }, []);
  return (
    <View>
      <DisplayText
        size={'xsMedium'}
        extraStyles={{fontSize: 10, color: Colors.Custom.Gray500}}
        textLocalId="status"
      />
      <VStack style={{padding: 0, borderRadius: 16, width: 100}}>
        {(dataList || []).map((statusObj: any, index: any) => {
          return (
            <Pressable
              key={index}
              onPress={() => {
                setSelected(statusObj.code);
                onStatusChange(statusObj);
              }}
            >
              <HStack
                alignItems={'center'}
                style={{marginVertical: 8}}
                flex={1}
              >
                <View
                  style={{
                    width: 6,
                    height: 6,
                    borderRadius: 50,
                    backgroundColor: getColors(statusObj),
                    marginRight: 10,
                  }}
                ></View>
                {Platform.OS === 'web' ? (
                  <DisplayText
                    size={'xsMedium'}
                    extraStyles={{}}
                    textLocalId={statusObj.code}
                  />
                ) : (
                  <Text>
                    {/* {intl.formatMessage({id: statusObj.code})} */}
                    Rohit
                  </Text>
                )}
              </HStack>
            </Pressable>
          );
        })}
      </VStack>
    </View>
  );
};

export default StatusFilterList;
