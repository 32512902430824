import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';
import React from 'react';

export const styles = StyleSheet.create({
  radioButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 14,
  },
  radioCircle: {
    height: 20,
    width: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray300,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioCircleSelected: {
    height: 20,
    width: 20,
    borderRadius: 10,
    borderWidth: 10,
    borderColor: '#7F56D9',
    backgroundColor: '#7F56D9',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedRadioCircle: {
    width: 10,
    height: 10,
    borderColor: '#7F56D9',
    backgroundColor: 'white',
    borderRadius: 5,
  },
  width100BorderWidth1PaddingBottom10PaddingTop10: {
    width: '100%',
    borderWidth: 1,
    paddingBottom: 10,
    paddingTop: 10,
  },
  text: {
    marginLeft: 4,
    color: Colors.FoldPixel.GRAY400,
    fontSize: 14,
    alignSelf: 'center',
  },
  text3: {
    marginLeft: 10,
    fontSize: 14,
    color: Colors.FoldPixel.GRAY300,
  },
  flexDirectionRowAlignItemsCenter: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  text2: {
    fontSize: 24,
  },
  text4: {
    color: Colors.FoldPixel.GRAY200,
    fontSize: 12,
    lineHeight: 14.4
  },
  text5: {
    marginLeft: 10,
    fontSize: 14,
    color: Colors.FoldPixel.GRAY300,
  },
  alignItemsFlexEndFlex1: {
    alignItems: 'flex-end',
    flex: 1,
  },
  flexAlignItemsCenterFlexDirectionRow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  viewStyle: {
    width: 1,
    height: 16,
    marginLeft: 12,
    backgroundColor: Colors.Custom.Gray300,
    marginRight: 10
  },
  alignItemsFlexEndJustifyContentCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  flexDirectionRow: {
    flexDirection: 'row',
  },
  textInput: {
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 6,
    height: 32,
    width: '100%',
    paddingLeft: 10,
  },
  marginLeft3MarginTop2: {
    marginLeft: 3,
    marginTop: 2
  },
  marginTop8: {
    marginTop: 8
  },
  text6: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY300,
  },
  text7: {
    marginLeft: 8,
    fontSize: 14,
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY300,
  },
  viewStyle1: {
    width: 34,
    height: 19,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  alignItemsSelfCenter: {
    alignItems: 'center',
    alignSelf: 'center'
  },
  box20: {
    width: 20,
    height: 20,
  },
  viewStyle2: {
    width: 0.5,
    height: 16,
    marginLeft: 14,
    backgroundColor: Colors.FoldPixel.GRAY150,
  },
  pressable: {
    alignItems: 'center',
    alignSelf: 'center',
    marginLeft: 14,
  },
  
});

export const reactStyles: Record<string, React.CSSProperties> = {
  colorGray250: {
    color: Colors.FoldPixel.GRAY250
  },
  errorText: { 
    color: Colors?.Custom?.ErrorColor, 
    marginTop: 2 
  },
  
  width100BorderWidth1PaddingBottom10PaddingTop10: {
    width: '100%',
    borderWidth: 1,
    paddingBottom: 10,
    paddingTop: 10,
  },
  select: {
    borderRadius: 6,
    width: '100%',
    height: 36,
  }
}