import {Drawer} from 'antd';
import {Button, Modal, VStack } from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../constants';
import {Colors} from '../../../../styles';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {IDeleteEmployeeModalProps} from '../interfaces';
import {styles} from './DeleteEmployeeModalStyle';

const {Content, CloseButton, Header, Footer, Body} = Modal;
const DeleteEmployeeModal = (props: IDeleteEmployeeModalProps) => {
  const {isOpen, onClose, onConfirm} = props;
  const {width} = Dimensions.get('window');
  const finalWidth = width / 3;

  return (
    <Drawer
        visible={isOpen}
        width={finalWidth}
        onClose={() => {
          onClose()
        }}
        title={
          <ModalActionTitle
            title={props.title
              ? props.title
              : props?.isRequestConsent
              ? 'requestConsent'
              : 'deleteEmployee'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onClose();
                },
              },
              {
                show: true,
                id: 2,
                btnText: 'confirm',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  onConfirm();
                },
              },
            ]}
          />
        }
      >
        <VStack space={3}>
            <DisplayText
                textLocalId={
                  props.subTitle
                    ? props.subTitle
                    : props?.isRequestConsent
                    ? 'requestConsentMsg'
                    : 'deleteEmployeeConfirmation'
                }
                textType="Text"
              />
        </VStack>
      </Drawer>
  )
};

export default DeleteEmployeeModal;
