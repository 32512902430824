import {HStack, Pressable, View} from 'native-base';
import React from 'react';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../styles';
import {DisplayText} from '../../DisplayText/DisplayText';
import {getColors} from './HelperUtils';
import {ISelectedItem} from './interfaces';

const ActiveListitem = (props: ISelectedItem) => {
  const {
    itemType,
    selectedStaus,
    selectedDate,
    selectedMessageType,
    selectedContactType,
    selectedInboxType,
  } = props;
  return (
    <HStack
      background={Colors.primary['100'] + '7a'}
      style={{
        height: 34,
        paddingVertical: 8,
        paddingHorizontal: 16,
        marginBottom: 1,
        borderRadius: 8,
      }}
      alignItems={'center'}
      flex={1}
      justifyContent={'space-between'}
    >
      {(selectedStaus || selectedMessageType) && (
        <>
          <HStack alignItems={'center'}>
            <View
              style={{
                width: 6,
                height: 6,
                borderRadius: 50,
                backgroundColor: getColors(selectedStaus),
                marginRight: 10,
              }}
            ></View>
            <DisplayText
              textLocalId={selectedStaus.code}
              size={'xsMedium'}
              extraStyles={{color: Colors.Custom.Gray700, marginLeft: 8}}
            />
          </HStack>
          <Pressable onPress={() => props.oncallback(itemType, selectedStaus)}>
            <AntDesign color={Colors.primary['500']} name="close" />
          </Pressable>
        </>
      )}
      {selectedDate && (
        <>
          <HStack alignItems={'center'}>
            <Feather
              name={'calendar'}
              color={Colors.primary['600']}
              size={20}
            />
            <DisplayText
              textLocalId={selectedDate.name}
              size={'xsMedium'}
              extraStyles={{color: Colors.Custom.Gray700, marginLeft: 8}}
            />
          </HStack>
          <Pressable onPress={() => props.oncallback(itemType, selectedDate)}>
            <AntDesign color={Colors.primary['500']} name="close" />
          </Pressable>
        </>
      )}
      {selectedContactType && (
        <>
          <HStack alignItems={'center'}>
            <Feather name={'user'} color={Colors.primary['600']} size={20} />
            <DisplayText
              textLocalId={selectedContactType?.displayNameId}
              size={'xsMedium'}
              extraStyles={{color: Colors.Custom.Gray700, marginLeft: 8}}
            />
          </HStack>
          <Pressable
            onPress={() => props.oncallback(itemType, selectedContactType)}
          >
            <AntDesign color={Colors.primary['500']} name="close" />
          </Pressable>
        </>
      )}
      {selectedInboxType && (
        <>
          <HStack alignItems={'center'}>
            <Feather name={'user'} color={Colors.primary['600']} size={20} />
            <DisplayText
              textLocalId={selectedInboxType?.displayNameId}
              size={'xsMedium'}
              extraStyles={{color: Colors.Custom.Gray700, marginLeft: 8}}
            />
          </HStack>
          <Pressable
            onPress={() => props.oncallback(itemType, selectedInboxType)}
          >
            <AntDesign color={Colors.primary['500']} name="close" />
          </Pressable>
        </>
      )}
    </HStack>
  );
};

export default ActiveListitem;
