import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const getConversationSidebarTabStyles = ({
  isTabSelected,
}: {
  isTabSelected: boolean;
}) => {
  return StyleSheet.create({
    tabPressable: {
      marginHorizontal: 4,
      marginVertical: 2,
      borderRadius: 4,
      borderWidth: 0.5,
      paddingLeft: 12,
      paddingVertical: 10,
      borderColor: isTabSelected
        ? Colors.Custom.PrimaryColor200
        : 'transparent',
      backgroundColor: isTabSelected ? '#FCFAFF' : 'transparent',
    },
    tabPressableHover: {
      backgroundColor: Colors.FoldPixel.GRAY50,
      color: Colors.FoldPixel.GRAY400,
    },
    tabText: {
      paddingStart: 6,
      color: isTabSelected
        ? Colors.Custom.Primary300
        : Colors.FoldPixel.GRAY300,
    },
  });
};
