import { Line, LineConfig } from '@ant-design/plots';
import { graphColorList, graphMetaDataColor } from '../../Analytics/EmployerAnalytics/graphColors';
import NodataViewTypeOne from '../../Analytics/EmployerAnalytics/SectionAndWidget/NoDataView/NodataViewTypeOne';
import { View } from 'native-base';

const JourneyLineChart = (props: {
    data: { type: string, value: number, month: string }[],
    xLabel: string,
    yLabel: string,
    height?: number,
    width?: number,
    tooltipText?: string
    hideXaxis?:boolean;
    yAxisColor?:string;
}) => {
    const multipleLineconfigGroup: LineConfig = {
        appendPadding: 10,
        data: props.data,
        xField: 'month',
        yField: 'value',
        seriesField: 'type',
        height: props?.height || 300,
        width:props?.width ,
        tooltip: {
            formatter: (data) => {

                return {
                    name: props.tooltipText || 'Amount',
                    value: (data.value)
                }
            },
        },
        yAxis:  {
            label: {
                formatter: (text) => {
                    const value: any = text
                    return value
                },
                style: {
                    fontSize: 12,
                    fontWeight: 400,
                    fill: graphMetaDataColor.axisTextColor
                }
            },
            title: props.yLabel ? {
                text: props.yLabel,
                description: '',
                style: {
                    fill: graphMetaDataColor.axisTextColor
                }
            } : undefined,
            line: {
                style: {
                    stroke: props?.yAxisColor || '#F2F4F7',
                }
            },
            grid: {
                alignTick: true,
                line: {
                    type: 'line',
                    style: {
                        stroke: '#F2F4F7'
                    }
                },
                closed: true
            }
        },
        xAxis: props?.hideXaxis ? {
            line: { style: { lineWidth: 0 } },
            tickLine: { style: { lineWidth: 0 } },
            label:{
                style:{
                    fontSize:0
                }
            }
        } : props?.xLabel ? {
            title: {
                text: props.xLabel,
                description: '',
                style: {
                    fill: graphMetaDataColor.axisTextColor
                },
                offset: 60
            },
            line: {
                style: {
                    stroke: '#F2F4F7',
                }
            },
            label: {
                offset: 20,
                style: {
                    fontSize: 12,
                    fontWeight: 400,
                    fill: graphMetaDataColor.axisTextColor
                }
            },
            grid: {
                alignTick: true,
                line: {
                    type: 'line',
                    style: {
                        stroke: '#F2F4F7'
                    }
                },
                closed: true
            }
        } : undefined,
        color: graphColorList,
        legend: false && {
            itemName: {
                formatter(text) {
                    return `${text}`
                },
            },
            position: 'top-right',
            layout: 'horizontal',
            marker: {
                symbol: 'circle',
                style(style) {
                    return {
                        ...style,
                        fill: style.stroke,
                        stroke: null,
                        r: 4
                    }
                },
            },
        },
        lineStyle: {
            lineWidth: 1,
        },
        point: {
            shape: ({ type }) => {
                return 'circle';
            },
        },
    };

    if (props.data.length) {
        return (<Line {...multipleLineconfigGroup} />)
    }


    return <View style={{
        paddingTop: 30,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    }}> <NodataViewTypeOne viewCode="TEXT_ICON" /></View>;


}

export default JourneyLineChart;