import { ColumnsType } from 'antd/lib/table';
import { Button, Text, View, Tooltip} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../styles/Colors';
import { getOnFocusButtonStyles } from '../../../utils/commonUtils';
import { DisplayText } from '../../common/DisplayText/DisplayText';
import { TeamMemberProfile } from '../Contacts/TeamMembers/TeamMembersTable/TeamMemberProfile';
import { IScheduleAccessUserView } from './interfaces';
import { styles } from './UserScheduleSettingStyle';

export const ALL_USERS_CODE = 'ALL_USERS'

const getScheduleUserName = (user: any) => {
  const userNames = user?.scheduleUsers
    ?.filter((item: any) => {
      return item.userId && user.userId !== item.userId;
    })
    .map((item: any) => {
      return item.userName;
    });
    const userList = userNames || [];
    const last = userList.pop();
    const userNameString = last
        ? ` themself${userList.length ? ',' : ''} `.concat(userList.join(', ') + ' and ' + last)
        : ' themself';
    return userNameString;
};

export const getUsersName = (userIds: any[], userList: any[]) => {
  const userNames = [] as any;
  userIds.map((qitem) => {
    userList.map((agentRecord: any) => {
      if (qitem === (agentRecord?.userId || agentRecord?.id)) {
        userNames.push(agentRecord.user?.name || agentRecord?.name || agentRecord?.userName);
      }
    })
  })
  return userNames;
}

export const getColumns = (
  actionFn: (actionCode: string, record: IScheduleAccessUserView) => {}
): ColumnsType<any> => {
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'userName',
      key: 'userName',
      width: '20%',
      fixed: 'left',
      render: (userName: string) => {
        return <TeamMemberProfile fullName={userName || ''} />;
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '20%',
      render: () => (
        <View style={styles.userNameView}>
          <Text style={[{color: Colors.primary['500']}]}>can schedule for</Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="users"
        />
      ),
      key: 'scheduleUsers',
      width: '25%',
      render: (record: IScheduleAccessUserView) => {
        const entries = getScheduleUserName(record).split(', ');
        const entriesLength = 3;
        const savedEntriesString = entries.slice(0, entriesLength).join(', ');
        return (
          <View style={styles.userNameView}>
            {entries.length > entriesLength ? (
              <Text>
                {savedEntriesString}
                <Tooltip
                  label={entries.slice(entriesLength).join(', ')}
                  placement="top"
                  w={400}
                  backgroundColor="white"
                  _text={{
                    color: 'gray.500',
                  }}
                >
                  <Text color={Colors.Custom.PrimaryColor}>
                    {` + ${entries.length - entriesLength} more`}
                  </Text>
                </Tooltip>
              </Text>
            ) : (
              <Text>{savedEntriesString}</Text>
            )}
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="action"
        />
      ),
      key: 'action',
      width: '10%',
      fixed: 'right',
      render: (record: IScheduleAccessUserView) => {
        return (
          <Button.Group justifyContent={'flex-start'}>
            <Button
              onPress={() => {
                actionFn('EDIT', record || ({} as IScheduleAccessUserView));
              }}
              _focus={getOnFocusButtonStyles()}
              leftIcon={
                <Feather
                  name="edit-2"
                  size={17}
                  style={{
                    marginLeft: 2,
                    marginRight: 2,
                    fontSize: 14.29,
                    color: Colors.Custom.Gray600,
                  }}
                />
              }
              style={{backgroundColor: 'transparent', borderWidth: 0}}
            />
          </Button.Group>
        );
      },
    },
  ] as any;
};
