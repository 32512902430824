import React from 'react';
import {Colors} from '../../../../styles/Colors';
import {ICommonSvgProps} from '../interfaces';

const LeadSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0378 3C17.6762 3 19.0238 4.38 19.0238 6.08C19.0238 7.78 17.6859 9.15 16.0378 9.15M20.6331 17.74C22 17.12 22 16.08 22 14.91C22 13.19 19.8187 11.75 16.9685 11.49M13.6335 6C13.6335 8.21 11.8982 10 9.7557 10C7.61319 10 5.87785 8.21 5.87785 6C5.87785 3.79 7.61319 2 9.7557 2C11.8982 2 13.6335 3.79 13.6335 6ZM17.5114 17.5C17.5114 19.99 17.5114 22 9.7557 22C2 22 2 19.99 2 17.5C2 15.01 5.47067 13 9.7557 13C14.0407 13 17.5114 15.01 17.5114 17.5Z"
        stroke={strokeColor} 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default React.memo(LeadSvg);
