import {Center, Image, Text, View} from 'native-base';
import React from 'react';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import {svgStyles} from './SvgStyles';

const EmptyStateSvg = (props: {titleId?: string; fillColor?: string}) => {
  const fillColor = props.fillColor || Colors.primary['300'];

  return (
    <View style={svgStyles.container}>
      <Center>
        <Image
          source={require('../../../assets/images/PNGImages/empty-state.png')}
          alt="image"
        ></Image>
      </Center>

      {props.titleId && (
        <Text style={svgStyles.svgText}>
          <DisplayText textLocalId={props.titleId} textType="Text" />
        </Text>
      )}
    </View>
  );
};

export default EmptyStateSvg;
