import React from 'react';
import {ViewStyle} from 'react-native';
import {Path, Svg} from 'react-native-svg';

const DraggableIconSvg2024 = () => {
  return (
    <Svg width="10" height="12" viewBox="0 0 10 12" fill="none">
    <Path d="M7.51852 8.96231C8.17308 8.96231 8.7037 9.49294 8.7037 10.1475C8.7037 10.8021 8.17308 11.3327 7.51852 11.3327C6.86396 11.3327 6.33333 10.8021 6.33333 10.1475C6.33333 9.49294 6.86396 8.96231 7.51852 8.96231Z" fill="#6F7A90"/>
    <Path d="M7.51852 4.81416C8.17308 4.81416 8.7037 5.34479 8.7037 5.99935C8.7037 6.65391 8.17308 7.18453 7.51852 7.18453C6.86396 7.18453 6.33333 6.65391 6.33333 5.99935C6.33333 5.34479 6.86396 4.81416 7.51852 4.81416Z" fill="#6F7A90"/>
    <Path d="M7.51852 0.666016C8.17308 0.666016 8.7037 1.19664 8.7037 1.8512C8.7037 2.50576 8.17308 3.03639 7.51852 3.03639C6.86396 3.03639 6.33333 2.50576 6.33333 1.8512C6.33333 1.19664 6.86396 0.666016 7.51852 0.666016Z" fill="#6F7A90"/>
    <Path d="M2.18519 8.96231C2.83974 8.96231 3.37037 9.49294 3.37037 10.1475C3.37037 10.8021 2.83974 11.3327 2.18519 11.3327C1.53063 11.3327 1 10.8021 1 10.1475C1 9.49294 1.53063 8.96231 2.18519 8.96231Z" fill="#6F7A90"/>
    <Path d="M2.18519 4.81416C2.83974 4.81416 3.37037 5.34479 3.37037 5.99935C3.37037 6.65391 2.83974 7.18453 2.18519 7.18453C1.53063 7.18453 1 6.65391 1 5.99935C1 5.34479 1.53063 4.81416 2.18519 4.81416Z" fill="#6F7A90"/>
    <Path d="M2.18519 0.666016C2.83974 0.666016 3.37037 1.19664 3.37037 1.8512C3.37037 2.50576 2.83974 3.03639 2.18519 3.03639C1.53063 3.03639 1 2.50576 1 1.8512C1 1.19664 1.53063 0.666016 2.18519 0.666016Z" fill="#6F7A90"/>
    <Path d="M7.51852 8.96231C8.17308 8.96231 8.7037 9.49294 8.7037 10.1475C8.7037 10.8021 8.17308 11.3327 7.51852 11.3327C6.86396 11.3327 6.33333 10.8021 6.33333 10.1475C6.33333 9.49294 6.86396 8.96231 7.51852 8.96231Z" stroke="#667085" stroke-linecap="round"/>
    <Path d="M7.51852 4.81416C8.17308 4.81416 8.7037 5.34479 8.7037 5.99935C8.7037 6.65391 8.17308 7.18453 7.51852 7.18453C6.86396 7.18453 6.33333 6.65391 6.33333 5.99935C6.33333 5.34479 6.86396 4.81416 7.51852 4.81416Z" stroke="#667085" stroke-linecap="round"/>
    <Path d="M7.51852 0.666016C8.17308 0.666016 8.7037 1.19664 8.7037 1.8512C8.7037 2.50576 8.17308 3.03639 7.51852 3.03639C6.86396 3.03639 6.33333 2.50576 6.33333 1.8512C6.33333 1.19664 6.86396 0.666016 7.51852 0.666016Z" stroke="#667085" stroke-linecap="round"/>
    <Path d="M2.18519 8.96231C2.83974 8.96231 3.37037 9.49294 3.37037 10.1475C3.37037 10.8021 2.83974 11.3327 2.18519 11.3327C1.53063 11.3327 1 10.8021 1 10.1475C1 9.49294 1.53063 8.96231 2.18519 8.96231Z" stroke="#667085" stroke-linecap="round"/>
    <Path d="M2.18519 4.81416C2.83974 4.81416 3.37037 5.34479 3.37037 5.99935C3.37037 6.65391 2.83974 7.18453 2.18519 7.18453C1.53063 7.18453 1 6.65391 1 5.99935C1 5.34479 1.53063 4.81416 2.18519 4.81416Z" stroke="#667085" stroke-linecap="round"/>
    <Path d="M2.18519 0.666016C2.83974 0.666016 3.37037 1.19664 3.37037 1.8512C3.37037 2.50576 2.83974 3.03639 2.18519 3.03639C1.53063 3.03639 1 2.50576 1 1.8512C1 1.19664 1.53063 0.666016 2.18519 0.666016Z" stroke="#667085" stroke-linecap="round"/>
    </Svg>
  );
};

export default DraggableIconSvg2024;


