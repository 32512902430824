import {Form, InputNumber, Popconfirm, Select, Table} from 'antd';
import {
  Box,
  Button,
  FormControl,
  HStack,
  Input,
  Pressable,
  Radio,
  Stack,
  Text,
  TextArea,
  useToast,
  View,
  VStack,
} from 'native-base';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useNavigate, useParams} from 'react-router-dom';
import {BUTTON_TYPE} from '../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {evnName} from '../../../../../constants/BaseUrlConst';
import {Colors} from '../../../../../styles';
import {getCurrentSubdomainWeb} from '../../../../../utils/commonUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import LoadingSpinner from '../../../../common/Loader/LoadingSpinner';
import {UnArchivedSvg} from '../../../../common/Svg/UnArchivedSvg';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {validateMembershipFormData} from '../../ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/ProductFormUtils';
import {IMemberShipProduct} from '../../ProductsAndServices/Products/ProductTableView/ProductUtils/stripeRespInterface';
import {styles} from '../CommonStyles';
import {
  IMembershipProductDetail,
  IStripeBaseTier,
  IStripePrice,
  IStripePriceTier,
} from '../interface';
import {addOrUpdateBilling, getMembershipDetails} from '../MembershipAPI';
import {formatPriceList} from '../MembershipUtils';
import {
  MemberShipPrice,
  PRICING_MODEL_CODE,
  SubscriptionPeriod,
} from './FormConst';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';

const BillingProductDetail = (props: any) => {
  const urlQueryParams = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const [errors, setErrors]: any = useState({});
  const [tieredPricesSource, setTieredPricesSource] = useState<
    IStripePriceTier[]
  >([
    {
      key: 1,
      type: 'Existing Lives',
      upto: '-',
      unitAmountDecimal: '0.00',
      flatAmountDecimal: '0.00',
    },
  ]);

  const [basePricesSource, setBasePricesSource] = useState<IStripeBaseTier[]>([
    {
      key: 1,
      type: 'Existing Lives',
      upto: 1,
      unitAmountDecimal: '0.00',
      flatAmountDecimal: '0.00',
      additionalAttributesType: '-',
      additionalAttributes: '-',
    },
  ]);
  const [productDetail, setProductDetails] = useState({} as IMemberShipProduct);
  const [pricingMode, setPricingMode] = useState<string>(
    PRICING_MODEL_CODE.STANDARD
  );
  const [showLoader, setShowLoader] = useState(false);

  const pricingModeHandler = (pricingMode: string) => {
    setPricingMode(pricingMode);
  };
  const [detailViewFormState, setDetailViewFormState] = useState<{
    productDetailForm: IMembershipProductDetail;
    isLoading: boolean;
    productId: number;
  }>({
    productDetailForm:
      props.productDetailForm ||
      ({
        features: [''],
      } as IMembershipProductDetail),
    isLoading: false,
    productId: -1,
  });
  const [count, setCount] = useState(2);
  const [priceCount, setPriceCount] = useState(2);
  const [dataSource, setDataSource] = useState<any>([
    {
      key: 1,
      value: '',
    },
  ]);
  const [priceSource, setPriceSource] = useState<IStripePrice[]>([
    {
      intervalCode: '',
      intervalCount: null,
      price: null,
      active: true,
      key: priceCount - 1,
      billingPlan: {},
    },
  ]);
  const onCancel = () => {
    navigate(-1);
  };
  const productDetailFormToast = useToast();
  const showToastMessage = (msg: string, isError: boolean, isI18N = true) => {
    showToast(productDetailFormToast, isI18N ? intl.formatMessage({id: msg}) : msg, isError ? ToastType.error : ToastType.success);
  };
  const addFeatures = () => {
    if (dataSource.length > 4) {
      return;
    }
    setCount((prv) => prv + 1);
    const newData: any = {
      key: count,
      value: '',
    };

    setDataSource([...dataSource, newData]);
  };
  const deleteFeatures = (item: any) => {
    if (dataSource.length === 1) return;
    const newStageArray = dataSource.filter((obj: any) => {
      return item.key !== obj.key;
    });
    setDataSource(newStageArray);
  };
  const addPriceListItem = () => {
    if (priceSource.length > 4) {
      return;
    }
    setPriceCount((prv) => {
      return prv + 1;
    });
    const newData: IStripePrice = {
      intervalCode: '',
      intervalCount: null,
      price: null,
      active: true,
      key: priceCount,
    };
    setPriceSource([...priceSource, newData]);
  };
  const deletePriceListItem = (item: IStripePrice) => {
    if (priceSource.length === 1) return;

    const newStageArray = priceSource.filter((obj) => {
      return !item.id && obj.key !== item.key;
    });
    setPriceSource(newStageArray);
  };

  const getSelectedInterval = (itemData: IStripePrice) => {
    const selectedInterval = SubscriptionPeriod.find((item) => {
      return (
        item.count == itemData.intervalCount &&
        item.keyLabel == itemData.intervalCode
      );
    });
    return selectedInterval?.labelName;
  };

  const getPricesValue = (prices: IStripePrice[], item: any) => {
    const mutablePriceArray = prices;
    const priceObj = mutablePriceArray?.find((price) => {
      return (
        price.intervalCode === item.keyLabel &&
        price.intervalCount == item.count
      );
    });
    return priceObj?.price;
  };
  const setPricesValue = (
    key: string,
    price: number,
    keyLabel: string,
    count: number
  ) => {
    const mutablePriceArray: any =
      detailViewFormState.productDetailForm.prices || [];
    if (price < 0 || price == null) {
      // return;
    }
    const priceIndex = mutablePriceArray?.findIndex((price: any) => {
      return price.intervalCode === keyLabel && price.intervalCount == count;
    });
    if (priceIndex > -1) {
      mutablePriceArray[priceIndex].price = price;
    } else {
      const tempPriceObj = {
        intervalCode: keyLabel,
        intervalCount: count,
        price: price,
        active: true,
      };
      mutablePriceArray.push(tempPriceObj);
    }

    setErrors({} as any);
    setDetailViewFormState((prev) => {
      return {
        ...prev,
        productDetailForm: {
          ...prev.productDetailForm,
          prices: mutablePriceArray,
          metered: true,
        },
      };
    });
  };
  const updateProductDetails = (isActive?: boolean) => {
    const productFormData = {...detailViewFormState.productDetailForm};
    productFormData.category = 'membership';
    productFormData.foldbilling = 'true';
    const tempPriceObj = priceSource?.filter((price) => {
      if (price.price === 0 && !price.intervalCode && !price.intervalCount) {
        return false;
      } else if (
        price.price == null &&
        !price.intervalCode &&
        !price.intervalCount
      ) {
        return false;
      }
      return true;
    });
    if (isActive) {
      productFormData.active = isActive;
    }
    productFormData.features = [];
    productFormData.prices = tempPriceObj;
    dataSource?.forEach((ele: any) => {
      if (ele?.value?.trim()?.length) {
        productFormData?.features.push(ele.value);
      }
    });
    productFormData.prices = tempPriceObj;
    setDetailViewFormState((prev) => {
      return {
        ...prev,
        isLoading: false,
      };
    });
    addOrUpdateBilling(
      {
        product: productFormData,
      },
      (responseMsg: any) => {
        if (responseMsg?.id) {
          setDetailViewFormState((prev) => {
            return {
              ...prev,
              isLoading: false,
              productId: responseMsg?.id,
            };
          });
        }
        showToastMessage('productDetailFormSuccess', false);
        props?.onActionPerformed?.(COMMON_ACTION_CODES.ADD, responseMsg);
        // navigate(`/admin/membership/products/` + responseMsg?.id, {
        //   replace: true,
        // });

        navigate('/admin/membership/products');
      },
      () => {
        setDetailViewFormState((prev) => {
          return {
            ...prev,
            isLoading: false,
          };
        });
        showToastMessage('productDetailFormError', true);
      },
      productDetail?.id
    );
  };
  const onProductDetailFromSubmit = () => {
    setShowLoader(true);
    setDetailViewFormState((prev) => {
      return {
        ...prev,
        productDetailForm: {
          ...prev.productDetailForm,
          prices: priceSource,
          tiers: tieredPricesSource,
          baseTiers: basePricesSource,
        },
      };
    });

    if (
      validateMembershipFormData(detailViewFormState.productDetailForm).validate
    ) {
      updateProductDetails();
    } else {
      setErrors(
        validateMembershipFormData(detailViewFormState.productDetailForm).errors
      );
      setShowLoader(false);
    }
  };

  const setProductDetailDataView = () => {
    if (productDetail?.id) {
      setDetailViewFormState((prev: any) => {
        return {
          ...prev,
          productId: productDetail.id,
          productDetailForm: {
            ...prev.productDetailForm,
            id: productDetail?.id,
            name: productDetail.name || '',
            description: productDetail.description || '',
            cost_currency: 'USD',
            prices: formatPriceList(productDetail.prices),
            active: productDetail.active,
          },
        };
      });
      setPriceSource((prev) => {
        return formatPriceList(productDetail.prices);
      });
      setPricingMode((prev) => {
        return productDetail.prices[0]?.billing_scheme;
      });
    }
    setDetailViewFormState((prev) => {
      return {
        ...prev,
        isLoading: false,
      };
    });
  };
  useEffect(() => {
    setProductDetailDataView();
  }, [productDetail?.id, detailViewFormState.isLoading]);

  const EditableContext = React.createContext<any>(null);

  const EditableRow = ({index, ...props}: {index: any}) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableBaseCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleBaseSave,
    ...restProps
  }: {
    title: any;
    editable: any;
    children: any;
    dataIndex: any;
    record: any;
    handleBaseSave: any;
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>(null);
    const form = useContext<any>(EditableContext);
    useEffect(() => {
      if (editing && inputRef?.current) {
        inputRef?.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      if (form) {
        form.setFieldsValue({
          [dataIndex]: record[dataIndex],
        });
      }
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleBaseSave({...record, ...values});
      } catch (errInfo) {
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input
            ref={inputRef}
            onEndEditing={save}
            onBlur={save}
            onPressOut={save}
            onSubmitEditing={save}
          />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }: {
    title: any;
    editable: any;
    children: any;
    dataIndex: any;
    record: any;
    handleSave: any;
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>(null);
    const form = useContext<any>(EditableContext);
    useEffect(() => {
      if (editing && inputRef?.current) {
        inputRef?.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      if (form) {
        form.setFieldsValue({
          [dataIndex]: record[dataIndex],
        });
      }
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({...record, ...values});
      } catch (errInfo) {
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <Input
            ref={inputRef}
            onEndEditing={save}
            onBlur={save}
            onPressOut={save}
            onSubmitEditing={save}
          />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const baseComponents = {
    body: {
      row: EditableRow,
      cell: EditableBaseCell,
    },
  };

  const getProductDetails = () => {
    setDetailViewFormState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const productId = urlQueryParams.id;
    if (props.productData !== null && productId) {
      getMembershipDetails(
        productId,
        (productResp: any) => {
          if (productResp) {
            setProductDetails(productResp);
          }
        },
        () => {
          setDetailViewFormState((prev) => {
            return {
              ...prev,
              isLoading: false,
            };
          });
        }
      );
    } else {
      setDetailViewFormState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
      setProductDetails(props.productData);
    }
  };
  const isProductActive = () => {
    if (!productDetail.id) {
      return true;
    }
    return detailViewFormState.productDetailForm.active;
  };

  const handleAdd = () => {
    const newData = {
      key: count,
      type: 'Existing Lives',
      upto: '-',
      unitAmountDecimal: '0.00',
      flatAmountDecimal: '0.00',
    };
    setTieredPricesSource([...tieredPricesSource, newData]);
    setDetailViewFormState((prev: any) => {
      return {
        ...prev,
        productId: productDetail.id,
        productDetailForm: {
          ...prev.productDetailForm,
          tiers: tieredPricesSource,
        },
      };
    });
    setCount(count + 1);
  };

  const handleBaseAdd = () => {
    const newData = {
      key: count,
      type: 'Existing Lives',
      upto: 1,
      unitAmountDecimal: '0.00',
      flatAmountDecimal: '0.00',
      additionalAttributesType: '-',
      additionalAttributes: '-',
    };
    setBasePricesSource([...basePricesSource, newData]);
    setDetailViewFormState((prev: any) => {
      return {
        ...prev,
        productId: productDetail.id,
        productDetailForm: {
          ...prev.productDetailForm,
          baseTiers: basePricesSource,
        },
      };
    });
    setCount(count + 1);
  };

  const isFoldAccount = () => {
    const subdomain = getCurrentSubdomainWeb();
    if (subdomain === `fold.${evnName()}foldhealth.io`) {
      return true;
    }
    return false;
  };

  const handleSave = (row: IStripePriceTier) => {
    const newData = [...tieredPricesSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {...item, ...row});
    setTieredPricesSource(newData);
    setDetailViewFormState((prev: any) => {
      return {
        ...prev,
        productId: productDetail.id,
        productDetailForm: {
          ...prev.productDetailForm,
          tiers: newData,
        },
      };
    });
  };

  const handleBaseSave = (row: IStripeBaseTier) => {
    const newData = [...basePricesSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {...item, ...row});
    setBasePricesSource(newData);
    setDetailViewFormState((prev: any) => {
      return {
        ...prev,
        productId: productDetail.id,
        productDetailForm: {
          ...prev.productDetailForm,
          baseTiers: newData,
        },
      };
    });
  };

  const handleDelete = (key: any) => {
    const newData = tieredPricesSource.filter((item) => item.key !== key);
    setTieredPricesSource(newData);
    setDetailViewFormState((prev: any) => {
      return {
        ...prev,
        productId: productDetail.id,
        productDetailForm: {
          ...prev.productDetailForm,
          tiers: newData,
        },
      };
    });
  };

  const handleBaseDelete = (key: any) => {
    const newData = basePricesSource.filter((item) => item.key !== key);
    setBasePricesSource(newData);
    setDetailViewFormState((prev: any) => {
      return {
        ...prev,
        productId: productDetail.id,
        productDetailForm: {
          ...prev.productDetailForm,
          baseTiers: newData,
        },
      };
    });
  };

  const defaultBaseColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      width: '30%',
      editable: true,
    },
    {
      title: 'Upto',
      dataIndex: 'upto',
      width: '20%',
      editable: true,
    },
    {
      title: 'Per Unit',
      dataIndex: 'unitAmountDecimal',
      editable: true,
    },
    {
      title: 'Flat Fee',
      dataIndex: 'flatAmountDecimal',
      editable: true,
    },
    {
      title: 'Additional Attributes Type',
      dataIndex: 'additionalAttributesType',
      width: '20%',
      editable: true,
    },
    {
      title: 'Additional Attributes',
      dataIndex: 'additionalAttributes',
      width: '20%',
      editable: true,
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_: any, record: {key: any}) =>
        tieredPricesSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleBaseDelete(record.key)}
          >
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ];
  const defaultColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      width: '30%',
      editable: true,
    },
    {
      title: 'Up to',
      dataIndex: 'upto',
      width: '20%',
      editable: true,
    },

    {
      title: 'Per Unit',
      dataIndex: 'unitAmountDecimal',
      editable: true,
    },
    {
      title: 'Flat Fee',
      dataIndex: 'flatAmountDecimal',
      editable: true,
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_: any, record: {key: any}) =>
        tieredPricesSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ];
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const baseColumns = defaultBaseColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleBaseSave,
      }),
    };
  });

  const hasTiers = (tiers: IStripePriceTier[] | undefined) => {
    if (tiers) {
      return tiers.length > 0;
    }
    return false;
  };

  useEffect(() => {
    getProductDetails();
  }, []);
  const onHeaderBackClick = () => {
    navigate(-1);
  };
  return (
    <View flex={1}>
      <View>
        <TitleSubtitleView
          title={'Detail'}
          showBackButton={true}
          onBackClick={onHeaderBackClick}
        />
      </View>
      <View
        style={{
          marginTop: 20,
          paddingHorizontal: 16,
          backgroundColor: Colors.Custom.Gray100,
        }}
      >
        <View
          style={{
            backgroundColor: '#fff',
            borderColor: Colors.Custom.BorderColor,
            borderWidth: 1,
            borderRadius: 8,
            padding: 8,
          }}
        >
          {isProductActive() ? (
            <></>
          ) : (
            <View style={{alignItems: 'flex-end'}}>
              <Pressable
                onPress={() => {
                  updateProductDetails(true);
                }}
                style={{
                  backgroundColor: Colors.secondary['100'],
                  width: 'max-content',
                  paddingHorizontal: 14,
                  marginHorizontal: 12,
                  paddingVertical: 10,
                  borderRadius: 180,
                  marginTop: 3,
                  borderWidth: 1,
                  borderColor: Colors.secondary['200'],
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <View style={{width: 20, height: 20}}>
                  <UnArchivedSvg />
                </View>
                <Text
                  size={'smSemibold'}
                  style={{
                    color: Colors.secondary['800'],
                    marginLeft: 8,
                  }}
                >
                  Unarchive
                </Text>
              </Pressable>
            </View>
          )}

          <View
            justifyContent={'space-between'}
            style={{paddingHorizontal: 12}}
          >
            <HStack flex={1} justifyContent={'space-between'}>
              <FormControl
                style={styles.formElement}
                flex={1}
                isRequired
                isInvalid={'name' in errors}
              >
                <FormControl.Label style={styles.formLabel}>
                  <DisplayText
                    textLocalId={'name'}
                    extraStyles={{color: Colors.Custom.Gray700}}
                    size={'mdBold'}
                  />
                </FormControl.Label>
                <Input
                  isDisabled={!isProductActive()}
                  placeholder="Title"
                  defaultValue={
                    detailViewFormState.productDetailForm.name ||
                    productDetail?.name ||
                    ''
                  }
                  onChangeText={(value) => {
                    setDetailViewFormState((prev) => {
                      return {
                        ...prev,
                        productDetailForm: {
                          ...prev.productDetailForm,
                          name: value,
                        },
                      };
                    });
                  }}
                />
                {errors.name && (
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {errors.name}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
            </HStack>

            <HStack>
              <FormControl
                flex={1}
                style={[styles.formElement]}
                isRequired
                isInvalid={'description' in errors}
              >
                <FormControl.Label style={styles.formLabel}>
                  <DisplayText
                    textLocalId={'description'}
                    extraStyles={{color: Colors.Custom.Gray700}}
                    size={'mdBold'}
                  />
                </FormControl.Label>
                <TextArea
                  isDisabled={!isProductActive()}
                  totalLines={10}
                  // isReadOnly={props.isReadOnly}
                  defaultValue={
                    detailViewFormState.productDetailForm.description ||
                    productDetail?.name ||
                    ''
                  }
                  onChangeText={(description) => {
                    setDetailViewFormState((prev) => {
                      return {
                        ...prev,
                        productDetailForm: {
                          ...prev.productDetailForm,
                          description: description,
                        },
                      };
                    });
                  }}
                />
                {errors.description && (
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {errors.description}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
              {/* Will Remove Once Subscription Work done :: AUTHOR => ABHAY */}
              {false && (
                <View flex={0.49} style={{marginTop: 8}}>
                  <View style={{paddingHorizontal: 12}}>
                    <Text
                      size={'mdBold'}
                      style={{color: Colors.Custom.Gray700}}
                    >
                      Membership Charges
                    </Text>
                  </View>
                  {errors.memberShipPrice && (
                    <Text
                      size={'xsSemibold'}
                      style={{
                        color: Colors.error['500'],
                        paddingHorizontal: 26,
                      }}
                    >
                      {errors.memberShipPrice}
                    </Text>
                  )}
                  <VStack
                    style={{
                      flexWrap: 'wrap',
                      paddingHorizontal: 12,
                    }}
                  >
                    {MemberShipPrice.map((item, index) => {
                      if (index == 0) {
                        return;
                      }
                      return (
                        <FormControl
                          style={[styles.formElement, {paddingRight: 12}]}
                          isRequired
                          isInvalid={'memberShipPrice' in errors}
                        >
                          <FormControl.Label style={styles.formLabel}>
                            <DisplayText
                              textLocalId={item.label}
                              extraStyles={{color: Colors.Custom.Gray700}}
                              size={'smMedium'}
                            />
                          </FormControl.Label>
                          <InputNumber
                            controls={false}
                            placeholder="99"
                            style={{width: '100%'}}
                            type={'number'}
                            value={getPricesValue(
                              detailViewFormState?.productDetailForm?.prices ||
                              ([] as IStripePrice[]),
                              item
                            )}
                            onChange={(price) => {
                              setPricesValue(
                                item.key,
                                price,
                                item.keyLabel,
                                item.count
                              );
                            }}
                          />
                          {errors.memberShipPrice && (
                            <FormControl.ErrorMessage
                              _text={{
                                fontSize: 'xs',
                                color: 'error.500',
                                fontWeight: 500,
                              }}
                            >
                              {errors.memberShipPrice}
                            </FormControl.ErrorMessage>
                          )}
                        </FormControl>
                      );
                    })}
                  </VStack>
                </View>
              )}
            </HStack>
          </View>

          <HStack flex={7}>
            <View marginY={1} flex={1}>
              <Radio.Group
                name="userSelection"
                defaultValue={PRICING_MODEL_CODE.STANDARD}
                value={pricingMode}
                onChange={pricingModeHandler}
              >
                <HStack space={2}>
                  <Radio value={PRICING_MODEL_CODE.STANDARD} marginY={1}>
                    <Text marginLeft={2}>Standard</Text>
                  </Radio>

                  <Radio
                    value={PRICING_MODEL_CODE.TIERED}
                    marginY={1}
                    marginLeft={1}
                  >
                    <Text marginLeft={2}>Tiered</Text>
                  </Radio>
                </HStack>
              </Radio.Group>
            </View>
          </HStack>
          <View style={{paddingHorizontal: 20, marginTop: 12, width: '100%'}}>
            <Text
              size={'mdBold'}
              style={{
                color: Colors.Custom.Gray700,
                marginTop: 12,
                marginBottom: 5,
              }}
            >
              Membership Charges
            </Text>
            <View
              style={{
                maxHeight: 400,
                width: '100%',
                overflow: 'scroll',
              }}
            >
              <div
                aria-hidden={
                  pricingMode === PRICING_MODEL_CODE.TIERED ? true : false
                }
                style={
                  pricingMode === PRICING_MODEL_CODE.TIERED
                    ? {display: 'none', visibility: 'hidden', height: 0}
                    : {}
                }
              >
                {priceSource.map((stageItem: IStripePrice, index: any) => {
                  return (
                    <View
                      key={index}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginVertical: 3,
                        width: '100%',
                        marginBottom: 6,
                      }}
                      flex={1}
                    >
                      <Select
                        disabled={!!stageItem.id}
                        size="large"
                        allowClear={true}
                        placeholder={'Select Interval Period'}
                        value={getSelectedInterval(stageItem)}
                        onChange={(value: any) => {
                          const selectedObj = SubscriptionPeriod.find(
                            (item) => {
                              return item.id === value;
                            }
                          );
                          const mutableObj = priceSource;
                          const indexedItem = mutableObj[index];
                          const tempObj = {
                            ...indexedItem,
                            intervalCode: selectedObj?.keyLabel || '',
                            intervalCount: selectedObj?.count || null,
                            active: true,
                            price: null,
                          };
                          mutableObj[index] = tempObj;
                          setPriceSource(mutableObj);
                        }}
                        style={{height: '40px', minWidth: 200}}
                      >
                        {SubscriptionPeriod.map((item, index) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.labelName}
                          </Select.Option>
                        ))}
                      </Select>
                      <InputNumber
                        disabled={!!stageItem.id}
                        controls={false}
                        placeholder="99"
                        type={'number'}
                        value={priceSource[index].price}
                        onChange={(value) => {
                          const mutableObj = priceSource || [];
                          mutableObj[index].price = value;
                          setPriceSource((prev) => {
                            return mutableObj;
                          });
                          setDetailViewFormState((prev) => {
                            return {
                              ...prev,
                              productDetailForm: {
                                ...prev.productDetailForm,
                                prices: mutableObj,
                              },
                            };
                          });
                        }}
                        style={{
                          minWidth: 200,
                          marginLeft: 12,
                          height: 42,
                          borderRadius: 8,
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      />
                      {stageItem.id ? (
                        <></>
                      ) : (
                        <Pressable
                          onPress={() => {
                            deletePriceListItem(stageItem);
                          }}
                          marginLeft={3}
                          flex={0.1}
                        >
                          <AntIcon name="close" />
                        </Pressable>
                      )}
                    </View>
                  );
                })}
                {isProductActive() ? (
                  <Pressable
                    onPress={() => {
                      addPriceListItem();
                    }}
                    style={{marginTop: 8, marginLeft: 6}}
                  >
                    <Text
                      size={'xsBold'}
                      style={{
                        color:
                          dataSource.length > 4
                            ? Colors.primary['200']
                            : Colors.primary['400'],
                      }}
                    >
                      + Add Price
                    </Text>
                  </Pressable>
                ) : (
                  <></>
                )}
              </div>

              <div
                aria-hidden={
                  pricingMode === PRICING_MODEL_CODE.STANDARD ? true : false
                }
                style={
                  pricingMode === PRICING_MODEL_CODE.STANDARD
                    ? {display: 'none', visibility: 'hidden', height: 0}
                    : {}
                }
              >
                {priceSource.map((stageItem: IStripePrice, index: any) => {
                  return (
                    <View
                      key={index}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginVertical: 3,
                        width: '100%',
                        marginBottom: 6,
                      }}
                      flex={1}
                    >
                      <Text
                        size={'xmBold'}
                        style={{
                          color: Colors.Custom.Gray700,
                          marginTop: 12,
                          marginBottom: 5,
                        }}
                      >
                        Base Price :{' '}
                      </Text>
                      <Select
                        disabled={!!stageItem.id}
                        size="large"
                        allowClear={true}
                        placeholder={'Select Interval Period'}
                        value={getSelectedInterval(stageItem)}
                        onChange={(value: any) => {
                          const selectedObj = SubscriptionPeriod.find(
                            (item) => {
                              return item.id === value;
                            }
                          );
                          const mutableObj = priceSource;
                          const indexedItem = mutableObj[index];
                          const tempObj = {
                            ...indexedItem,
                            intervalCode: selectedObj?.keyLabel || '',
                            intervalCount: selectedObj?.count || null,
                            active: true,
                            price: null,
                          };
                          mutableObj[index] = tempObj;
                          setPriceSource(mutableObj);
                        }}
                        style={{height: '40px', minWidth: 200}}
                      >
                        {SubscriptionPeriod.map((item, index) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.labelName}
                          </Select.Option>
                        ))}
                      </Select>
                      <InputNumber
                        disabled={!!stageItem.id}
                        controls={false}
                        placeholder="99"
                        type={'number'}
                        value={priceSource[index].price}
                        onChange={(value) => {
                          const mutableObj = priceSource || [];
                          mutableObj[index].price = value;
                          setPriceSource((prev) => {
                            return mutableObj;
                          });
                          setDetailViewFormState((prev) => {
                            return {
                              ...prev,
                              productDetailForm: {
                                ...prev.productDetailForm,
                                prices: mutableObj,
                              },
                            };
                          });
                        }}
                        style={{
                          minWidth: 200,
                          marginLeft: 12,
                          height: 42,
                          borderRadius: 8,
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      />
                    </View>
                  );
                })}

                <Stack space={5} alignItems="left">
                  <HStack space={5} alignItems="left">
                    <View>
                      <Text
                        size={'mdBold'}
                        style={{
                          color: Colors.Custom.Gray700,
                          marginTop: 12,
                          marginBottom: 5,
                        }}
                      >
                        Included in Base Price
                      </Text>

                      {priceSource.map(
                        (stageItem: IStripePrice, index: any) => {
                          return (
                            <View>
                              {isFoldAccount() && !stageItem.tiers ? (
                                <>
                                  <View
                                    style={{
                                      flexDirection: 'column',
                                      alignItems: 'flex-start',
                                      marginVertical: 6,
                                      width: '100%',
                                      height: '100%',
                                      marginBottom: 6,
                                    }}
                                    flex={1}
                                  >
                                    <Table
                                      components={baseComponents}
                                      rowClassName={() => 'editable-row'}
                                      bordered
                                      dataSource={basePricesSource}
                                      columns={baseColumns}
                                      style={{
                                        minWidth: 200,
                                        marginLeft: 12,
                                        borderRadius: 8,
                                        display: 'flex',
                                      }}
                                    />
                                    <Pressable
                                      onPress={() => {
                                        handleBaseAdd();
                                      }}
                                      style={{marginTop: 8, marginLeft: 6}}
                                    >
                                      <Text
                                        size={'xsBold'}
                                        style={{
                                          color:
                                            dataSource.length > 4
                                              ? Colors.primary['200']
                                              : Colors.primary['400'],
                                        }}
                                      >
                                        + Add another line
                                      </Text>
                                    </Pressable>
                                  </View>
                                </>
                              ) : (
                                <></>
                              )}

                              {isFoldAccount() && stageItem.tiers ? (
                                <>
                                  <Table
                                    components={components}
                                    rowClassName={() => 'editable-row'}
                                    bordered
                                    dataSource={stageItem.tiers}
                                    columns={baseColumns}
                                  />
                                  <Pressable
                                    onPress={() => {
                                      handleBaseAdd();
                                    }}
                                    style={{marginTop: 8, marginLeft: 6}}
                                  >
                                    <Text
                                      size={'xsBold'}
                                      style={{
                                        color:
                                          dataSource.length > 4
                                            ? Colors.primary['200']
                                            : Colors.primary['400'],
                                      }}
                                    >
                                      + Add another line
                                    </Text>
                                  </Pressable>
                                </>
                              ) : (
                                <></>
                              )}
                            </View>
                          );
                        }
                      )}
                    </View>
                    <View>
                      <Text
                        size={'mdBold'}
                        style={{
                          color: Colors.Custom.Gray700,
                          marginTop: 12,
                          marginBottom: 5,
                        }}
                      >
                        Additional Usage Charges
                      </Text>

                      {priceSource.map(
                        (stageItem: IStripePrice, index: any) => {
                          return (
                            <View>
                              {isFoldAccount() && !stageItem.tiers ? (
                                <>
                                  <View
                                    style={{
                                      flexDirection: 'column',
                                      alignItems: 'flex-start',
                                      marginVertical: 6,
                                      width: '100%',
                                      height: '100%',
                                      marginBottom: 6,
                                    }}
                                    flex={1}
                                  >
                                    <Table
                                      components={components}
                                      rowClassName={() => 'editable-row'}
                                      bordered
                                      dataSource={tieredPricesSource}
                                      columns={columns}
                                      style={{
                                        minWidth: 200,
                                        marginLeft: 12,
                                        borderRadius: 8,
                                        display: 'flex',
                                      }}
                                    />
                                    <Pressable
                                      onPress={() => {
                                        handleAdd();
                                      }}
                                      style={{marginTop: 8, marginLeft: 6}}
                                    >
                                      <Text
                                        size={'xsBold'}
                                        style={{
                                          color:
                                            dataSource.length > 4
                                              ? Colors.primary['200']
                                              : Colors.primary['400'],
                                        }}
                                      >
                                        + Add another line
                                      </Text>
                                    </Pressable>
                                  </View>
                                </>
                              ) : (
                                <></>
                              )}

                              {isFoldAccount() && stageItem.tiers ? (
                                <>
                                  <Table
                                    components={components}
                                    rowClassName={() => 'editable-row'}
                                    bordered
                                    dataSource={stageItem.tiers}
                                    columns={columns}
                                  />
                                  <Pressable
                                    onPress={() => {
                                      handleAdd();
                                    }}
                                    style={{marginTop: 8, marginLeft: 6}}
                                  >
                                    <Text
                                      size={'xsBold'}
                                      style={{
                                        color:
                                          dataSource.length > 4
                                            ? Colors.primary['200']
                                            : Colors.primary['400'],
                                      }}
                                    >
                                      + Add another line
                                    </Text>
                                  </Pressable>
                                </>
                              ) : (
                                <></>
                              )}
                            </View>
                          );
                        }
                      )}
                    </View>
                  </HStack>
                </Stack>
              </div>
            </View>
          </View>

          {/* WIll enable once API dependency done :: AUTHOR:: ABHAY */}
          {false && (
            <View style={{paddingHorizontal: 20, marginTop: 12, width: '100%'}}>
              <Text
                size={'mdBold'}
                style={{
                  color: Colors.Custom.Gray700,
                  marginTop: 12,
                  marginBottom: 5,
                }}
              >
                Feature list
              </Text>
              <View
                style={{
                  maxHeight: 250,
                  width: '40%',
                  overflow: 'scroll',
                }}
              >
                {dataSource.map((stageItem: any, index: any) => {
                  return (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginVertical: 3,
                        width: '100%',
                      }}
                      flex={1}
                    >
                      <Input
                        placeholder="feature name"
                        defaultValue={dataSource[index]?.value}
                        // value={dataSource[index]?.value}
                        onChangeText={(value) => {
                          const mutableObject = [...dataSource];
                          mutableObject[index].value = value;
                          setDataSource(mutableObject);
                        }}
                        flex={0.9}
                      />
                      <Pressable
                        onPress={() => {
                          deleteFeatures(stageItem);
                        }}
                        marginLeft={3}
                        flex={0.1}
                      >
                        <AntIcon name="close" />
                      </Pressable>
                    </View>
                  );
                })}
              </View>
              <Pressable
                onPress={() => {
                  addFeatures();
                }}
                style={{marginTop: 8, marginLeft: 6}}
              >
                <Text
                  size={'xsBold'}
                  style={{
                    color:
                      dataSource.length > 4
                        ? Colors.primary['200']
                        : Colors.primary['400'],
                  }}
                >
                  + Add another line
                </Text>
              </Pressable>
            </View>
          )}
          {isProductActive() ? (
            <Button.Group
              style={{paddingHorizontal: 12, paddingVertical: 12}}
              mx={{
                base: 'auto',
                md: 0,
              }}
              justifyContent={'flex-end'}
            >
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    onCancel();
                  },
                  style: {marginRight: 20},
                  isDisabled: showLoader,
                }}
                customProps={{
                  btnText: intl.formatMessage({id: 'cancel'}),
                  withRightBorder: false,
                }}
              ></FoldButton>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    onProductDetailFromSubmit();
                  },
                  style: {marginRight: 20},
                  isDisabled: showLoader,
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: productDetail?.id ? 'update' : 'add',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </Button.Group>
          ) : (
            <></>
          )}
        </View>
      </View>
      {showLoader && <LoadingSpinner />}
    </View>
  );
};

export default BillingProductDetail;
