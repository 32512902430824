import {VALIDATE_EMAIL_REGEX, isValidPhoneNumber} from "../../../../utils/commonUtils";
import {ALL_CONTACT_TYPE, CONTACT_TYPES_VALUE, CUSTOM_ATTRIBUTE_DATA_TYPES, CUSTOM_ATTRIBUTE_VALUE_COLUMN, CUSTOM_FIELD_DESCRIPTION_MAX_CHAR_LIMIT, CUSTOM_FIELD_NAME_MAX_CHAR_LIMIT} from "./CustomFieldConst";
import {IAddCustomFieldState, IAttributes, IContactTypes, ICustomFieldAddUpdateFormattedData, ICustomFieldData, ICustomFieldDataErrors, ICustomFieldRef, ICustomFieldResponse, ICustomFieldsError, IFormattedCustomFieldsData, IGetCustomFieldByEntityResponse, customAttributeIdValue} from "./interface"

export const getFormattedCustomFieldData = (customFieldData: ICustomFieldResponse[]) => {
  const customFieldsData: ICustomFieldData[] = customFieldData?.map((customField) => {
    const customFieldId: IContactTypes | any = customField?.customContactTypes?.[0];
    const contactTypes = customFieldId === ALL_CONTACT_TYPE.id ? [ALL_CONTACT_TYPE] : customField?.customContactTypes;
    return {
      id: customField?.id,
      title: customField?.label || '',
      description: customField?.description || '',
      visibleIn: [],
      type: customField?.datatype,
      active : customField.isDisabled ? false : true,
      contactTypes: contactTypes,
      isDefault: customField?.isDefault,
    }
  })
  return customFieldsData;
}

export const removeAlreadySelected = (CustomFieldData: ICustomFieldData[], FormattedCustomFieldsData?: IFormattedCustomFieldsData[]) => {
  return CustomFieldData?.filter((field)=> {
    const isExist = FormattedCustomFieldsData?.find((customAttribute)=> customAttribute?.customAttribute?.id === field?.id)
    return !isExist?.customAttributeId && field;
  })
}

export const validateCustomFieldData = (
  customFieldRef: React.MutableRefObject<ICustomFieldRef>,
  customFieldFormData: IAddCustomFieldState
) => {
  const errors = {} as ICustomFieldDataErrors;
  let validate = true;
  errors.name = !customFieldRef?.current['title']?.value.trimLeft()
    ? 'Name is required'
    : customFieldRef?.current['title']?.value.length < 3
    ? 'Enter minimum 3 characters'
    : customFieldRef?.current['title']?.value.length > CUSTOM_FIELD_NAME_MAX_CHAR_LIMIT
    ? `Maximum ${CUSTOM_FIELD_NAME_MAX_CHAR_LIMIT} characters are allowed`
    : '';

  errors.description = customFieldRef?.current['description']?.value.length > CUSTOM_FIELD_DESCRIPTION_MAX_CHAR_LIMIT
    ? `Maximum ${CUSTOM_FIELD_DESCRIPTION_MAX_CHAR_LIMIT} characters are allowed`
    : '';

  errors.type = !customFieldFormData?.selectedType ? 'Type is required' : '';
  errors.applicableTo = !customFieldFormData?.selectedCustomContactTypes?.length ? 'Applicable to is required' : '';

  // in development
  // errors.applicableTo = !customFieldFormData?.selectedCustomContactTypes.length ? 'Application to is required' : '';

  if (errors.name || errors.type || errors.applicableTo || errors.description) {
    validate = false;
  }
  return {
    validate: validate,
    errors: errors
  };
}

export const getCustomFieldBodyData = (
  customFieldRef: React.MutableRefObject<ICustomFieldRef>,
  customFieldFormData: IAddCustomFieldState
) => {
  const customFieldId = customFieldFormData?.selectedCustomField?.id;
  return {
    id: customFieldId ? customFieldId : '',
    name: customFieldRef?.current['title']?.value,
    description: customFieldRef?.current['description']?.value,
    type: customFieldFormData?.selectedType,
  }
}

export const getFormattedCustomAttributesValue = (customFields: IGetCustomFieldByEntityResponse[]) => {
  const formattedCustomFieldsData: IFormattedCustomFieldsData[] = customFields?.map((customFields)=> {
    return {
      customAttribute: {
        datatype: customFields?.customAttribute?.datatype,
        id: customFields?.customAttribute?.id,
        label: customFields?.customAttribute?.label,
      },
      customAttributeId: customFields?.customAttributeId,
      entityId: customFields?.entityId || '',
      entityType: customFields?.entityType,
      formattedValue: customFields?.formattedValue,
      id: customFields?.id,
      tempId: customFields?.tempId,
      isDeleted: customFields?.isDeleted,
      value: customFields?.value,
      dataSource: customFields?.dataSource,
    }
  })
  return formattedCustomFieldsData;
}

export const getCustomFieldAddUpdateFormattedData = (customFieldsValue: IFormattedCustomFieldsData[], dataSource: string, id?: string) => {
  const customFieldAddUpdateFormattedData = {} as ICustomFieldAddUpdateFormattedData;
  const entityId = customFieldsValue[0]?.entityId || id || '';
  const values: customAttributeIdValue[] = customFieldsValue?.map((field: IFormattedCustomFieldsData)=> {
    return {
      customAttributeId: field?.customAttributeId,
      value: field?.value,
    }
  })
  customFieldAddUpdateFormattedData.entityId = entityId;
  customFieldAddUpdateFormattedData.dataSource = dataSource;
  customFieldAddUpdateFormattedData.values = values;
  return customFieldAddUpdateFormattedData || [];
}

const validateValueByCustomFieldType = (customFieldsValue: IFormattedCustomFieldsData) => {
  const customAttributeDataType = customFieldsValue?.customAttribute?.datatype;
  switch (customAttributeDataType) {
    case CUSTOM_ATTRIBUTE_DATA_TYPES.PHONE:
    case CUSTOM_ATTRIBUTE_DATA_TYPES.NPI:
      const validPhoneOrNpi = isValidPhoneNumber(customFieldsValue?.value);
      return {
        valid: validPhoneOrNpi,
        message: validPhoneOrNpi ? '' : `Please enter valid ${customAttributeDataType === CUSTOM_ATTRIBUTE_DATA_TYPES.PHONE ? 'Phone number' : 'NPI'}`
      };
    case CUSTOM_ATTRIBUTE_DATA_TYPES.EMAIL:
      const validEmail = VALIDATE_EMAIL_REGEX.test(customFieldsValue?.value)
      return {
        valid: validEmail,
        message: validEmail ? '' : 'Please enter valid Email'
      };
    default:
      return {
        valid: true,
        message: ''
      };
  }
}

export const validateCustomFieldsValue = (customFieldsValue: IFormattedCustomFieldsData[]) => {
  const customFieldsErrors: ICustomFieldsError = {};
  let validated = true;

  customFieldsValue?.forEach((field: IFormattedCustomFieldsData)=> {
    const { value } = field;
    const entityId = field?.id || field?.tempId || '';
    if (!value) {
      customFieldsErrors[entityId] = "Please enter value";
      validated = false;
    } else {
      const isValidCheck = validateValueByCustomFieldType(field);
      if (!isValidCheck?.valid) {
        customFieldsErrors[entityId] = isValidCheck?.message;
        validated = false;
      }
    }
  })
  return {
    validated,
    customFieldsErrors
  }
}

export const getFormattedCustomAttributesChunkedData = (formattedCustomFieldsData: IFormattedCustomFieldsData[]) => {
  const customAttributesChunkedData: any = [];
  for (let index = 0; index < formattedCustomFieldsData.length; index += 2) {
    customAttributesChunkedData.push(formattedCustomFieldsData.slice(index, index + 2));
  }
  return customAttributesChunkedData;
}

export const isErrorInCustomAttributes = (customAttributes: any) => {
  return customAttributes.some((field: any)=> !field?.customAttributeId || !field?.mappedValue)
}

export const getFormattedCustomAttributesValues = (customAttributes: any) => {
  const transformedCustomAttributes = customAttributes.map((attribute: any) => ({
    [attribute.customAttributeId]: attribute.mappedValue
  }));
  return transformedCustomAttributes || [];
}

export const getSelectedCustomAttributeIds = (customAttributes: any) => {
  const customAttributeIds = customAttributes?.map((attribute: any)=> {
    return attribute?.customAttributeId
  });
  return customAttributeIds || [];
}

export const getFilteredCustomAttribute = (customAttributes: ICustomFieldData[], selectedCustomAttributesId?: string[], selectedValue?: string) => {
  const filteredCustomAttribute = customAttributes?.filter?.((attribute)=> {
    return !selectedCustomAttributesId?.includes(attribute?.id) || selectedValue === attribute?.value
  });
  return filteredCustomAttribute || [];
}

export const getFormattedCustomContactTypesData = (CustomContactTypesData: IContactTypes[]) => {
  const FormattedCustomContactTypesData: IContactTypes[] = [];
  CustomContactTypesData.forEach((element: IContactTypes) => {
    if (element.value === CONTACT_TYPES_VALUE.CUSTOMER) {
      element.value = CONTACT_TYPES_VALUE.PATIENT;
    }
    if (element?.isActive) {
      FormattedCustomContactTypesData.push({
        id: element.id,
        value: element.value || '-',
      })
    }
  });
  return FormattedCustomContactTypesData;
}

export const getCustomAttributeFieldNameByDateType = (dataType: string) => {
  switch (dataType){
    case CUSTOM_ATTRIBUTE_DATA_TYPES.BOOLEAN:
      return CUSTOM_ATTRIBUTE_VALUE_COLUMN.BOOLEAN_VALUE;
    case CUSTOM_ATTRIBUTE_DATA_TYPES.DATE:
      return CUSTOM_ATTRIBUTE_VALUE_COLUMN.DATE_VALUE;
    case CUSTOM_ATTRIBUTE_DATA_TYPES.NUMBER:
      return CUSTOM_ATTRIBUTE_VALUE_COLUMN.NUMBER_VALUE;
    case CUSTOM_ATTRIBUTE_DATA_TYPES.TEXT:
    case CUSTOM_ATTRIBUTE_DATA_TYPES.EMAIL:
    case CUSTOM_ATTRIBUTE_DATA_TYPES.NPI:
    case CUSTOM_ATTRIBUTE_DATA_TYPES.PHONE:
      return CUSTOM_ATTRIBUTE_VALUE_COLUMN.TEXT_VALUE;
    default:
      return '';
  }
}

export const getFormattedCustomAttributesSuggestedValues = (suggestedValues: string[]) => {
  return suggestedValues?.map((value)=> {
    return {
      code: value,
      value: value,
      label: value
    }
  })
}

export const filterNullAttributes = (attributes: IAttributes[]) => {
  return attributes?.filter(attribute => attribute?.value);
}