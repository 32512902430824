import React from 'react';

const EditPenSvg = (props: {width?:number;height?:number }) => {
  const {width, height} = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ width ? width: "24"}
      height={height ? height : "24"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#98A2B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 20h9M16.5 3.5a2.121 2.121 0 113 3L7 19l-4 1 1-4L16.5 3.5z"
      ></path>
    </svg>
  );
};

export default EditPenSvg;
