import React, {useEffect} from 'react';
import {
  HashRouter,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import '../../components/RightSideContainer/ContentManagement/EmailTemplates/CreateOrEditTemplate/CustomBlocks';
import MainScreen from './MainScreen';
import { OnlineStatusProvider } from '../../components/CustomHooks/useOnlineStatus';
import * as Sentry from "@sentry/react";
import { getSentryDNSFromENV } from '../../constants/Configs';
import { evnName } from '../../constants/BaseUrlConst';

//enable sentry
if (getSentryDNSFromENV()) {
  Sentry.init({
    dsn: getSentryDNSFromENV(),
    maxBreadcrumbs: 20,
    release: "06.11.24",
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    autoSessionTracking: false, // Disable session replay
  });
}

const MainScreenBodyView = () => {
  return (
    <HashRouter>
        <MainScreen />
    </HashRouter>
  );
};

export default MainScreenBodyView;
