import {Pressable, Text, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {Colors} from '../../../../../styles';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import {styles} from '../CommonStyles';
import ChargesOrdersList from './ChargesOrdersList';
import MembershipOrdersList from './MembershipOrdersList';
const MembershipOrders = (props: any) => {
  const [searchString, setSearchString] = useState(props.searchText);
  const [stateData, setStateData] = useState({
    isChargesList: false,
    orderType: 'subscription',
  });
  useEffect(() => {
    setSearchString(props.searchText);
  }, [props.searchText]);
  const screenHeight = Dimensions.get('window').height;
  const orderType = [
    {name: 'Recurring', code: 'subscription'},
    {name: 'One Time', code: 'oneTime'},
  ];
  return (
    <View height={screenHeight - 170}>
      <View>
        <View
          style={{
            height: 40,
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: 12,
            paddingHorizontal: 4,
            paddingVertical: 8,
          }}
        >
          {orderType.map((type) => {
            return (
              <Pressable
                key={type.code}
                onPress={() => {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      orderType: type?.code,
                    };
                  });
                }}
              >
                <View
                  style={[
                    styles.swtichBtnStyle,
                    stateData?.orderType === type?.code
                      ? {backgroundColor: Colors.primary['300']}
                      : {},
                  ]}
                >
                  <Text
                    size={'xsBold'}
                    color={
                      stateData?.orderType === type?.code
                        ? '#fff'
                        : Colors.Custom.Gray900
                    }
                  >
                    {type.name}
                  </Text>
                </View>
              </Pressable>
            );
          })}
        </View>
      </View>
      <PageBodyContainer>
        <View flex={1} backgroundColor={'white'}>
          {stateData.orderType === 'oneTime' ? (
            <ChargesOrdersList />
          ) : (
            <MembershipOrdersList />
          )}
        </View>
      </PageBodyContainer>
    </View>
  );
};

export default MembershipOrders;
