import React, {useRef} from 'react';
import {Colors} from '../../../styles/Colors';
import {Collapse, Typography, Select as AntSelect} from 'antd';
import './Styles.css';
import { useIntl } from 'react-intl';
import { Box, HStack, Text, View, Pressable, VStack, useToast, Spacer, Input, TextArea , Tooltip, Stack, Spinner } from 'native-base';
import { useEffect, useState } from 'react';
import HeartPulseSvg from '../../common/Svg/HeartPulseSvg';
import UnitySvg from '../../common/Svg/UnitySvg';
import HardRefreshIconSvg from '../../common/Svg/HardRefreshSvg';
import { LLMSkeletonLoader } from './LLMSkeletonLoader';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CreateOrUpdateUserPreference, GetUserPreferencesByTypeId } from '../../../services/UserPreferences/UserPreferencesQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import { getUserUUID } from '../../../utils/commonUtils';
import { debounce } from 'lodash';
import NoDataFoundWithCustomIcon from '../../common/NoDataFound/NoDataFoundWithCustomIcon';
import InfoCircleSvg from '../../common/Svg/InfoCircleSvg';
import AntIcon from 'react-native-vector-icons/AntDesign';
import DislikeSvg from '../../common/Svg/DislikeSvg';
import LikeSvg from '../../common/Svg/LikeSvg';
import { formatTimestamp } from '../../../utils/llmUtils';
import { TestIdentifiers, testID } from '../../../testUtils';
import { getClaimsRecommendation } from './LLMApi';
const {Option} = AntSelect;
const {Panel} = Collapse;

export const ClaimsRecommendation = (props: { contactUUID: string | undefined }) => {
  const intl = useIntl();
  const [loadingDisabledFlag, setLoadingDisabledFlag] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [isTemplateLoading, setIsTemplateLoading] = useState(true);
  const [lastSync, setLastSync] = useState<string>('');
  const [isError, setIsError] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal]  = useState<{
    loading :  boolean,
     show : boolean
  }>({
    loading : false,
    show : false
  });

  const [feedback, setFeedback] = useState<{
    score: any;
    comment: string;
  }>({
    score: undefined,
    comment: '',
  });

  const [selectedTag, setSelectedTag] = useState<{
    index: string | number;
    selected: boolean;
    shortCode: string;
    textInputReason: string;
  }>({
    index: -1,
    selected: false,
    shortCode: '',
    textInputReason: '',
  });
  const lastSyncMessage = `Last Synced: ${lastSync}`
  const renderSelectElem = (
    item: any,
    isSelectedValueRender: boolean
  ) => {
    return (
      <View
        alignItems={'center'}
        my={1.5}
        maxW={isSelectedValueRender ? '100%' : undefined}
      >
        <Text
          fontSize={13}
          fontWeight={'light'}
          fontFamily={'Manrope-Regular'}
          {...(isSelectedValueRender ? {maxW: '100%', isTruncated: true} : {})}
        >
          {item?.templateName}
        </Text>
     </View>
    );
  };


  const [getUserTemplatePreferencesByTypeId] = useLazyQuery(GetUserPreferencesByTypeId, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [
    addUpdatePreferenceQuery,
    {loading: isAddUpdatePreferenceQueryLoading},
  ] = useMutation(CreateOrUpdateUserPreference, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [stateData, setStateData] = useState<string>('')


  const [resultID, setResultID]= useState('');

  // useEffect(()=>{
  //   getFeedBackStatus(resultID,userUUID)

  // }, [resultID,reasonCode.all])

  const fetchData = (
    isCached?: boolean,
  ) => {
    if (props?.contactUUID) {
      getClaimsRecommendation(props.contactUUID, isCached)
        .then((result: {data: {response: string, syncDate: string, id: string} }) => {
          setIsActive(false);
          setLastSync(formatTimestamp(result?.data?.syncDate));
          setStateData(result?.data?.response);
          setIsTemplateLoading(false);
          setLoadingDisabledFlag(false);
          setIsError(false);
          setResultID(result?.data?.id);
          setShowFeedbackModal( (prev)=>({...prev, show:false}));
          setSelectedTag({
          index: -1,
          selected: false,
          shortCode: '',
          textInputReason: '',
        });
      })
      .catch((error: any) => {
        setIsActive(false);
        setLoadingDisabledFlag(false);
        setIsTemplateLoading(false);
        setIsError(true);
        setStateData(intl.formatMessage({id: 'errorInFetchingData'}));
      });
    }
  };

  useEffect(() => {
    fetchData(true);
  }, []);


  const loaderTemeplateValues = [
    intl.formatMessage({id: 'analyzingMedications'}),
    intl.formatMessage({id: 'analyzingProcedures'}),
    intl.formatMessage({id: 'synthesizingLearnings'}),
    intl.formatMessage({id: 'curatingSynopsis'}),
  ];


  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };


  return (
    <div>
      <Box
        mb={2}
        shadow='0px 4px 8px -2px #10182819, 0px 2px 4px -2px #101828f'
        borderRadius={8}
        borderColor={Colors.Custom.BorderColor}
        borderWidth={1}
      >
        <Collapse
          className='llm-collapse'
          defaultActiveKey={[1]}
          collapsible='header'
          style={{
            borderWidth: 0.5,
            borderColor: Colors.Custom.ContainerBGColor,
          }}
          expandIconPosition='end'
        >
          <Panel
            header={
              <HStack alignItems={'center'} space={3}>
                <Tooltip label={"V1.0.5"} placement='top' hasArrow>
                <View>
                <span  {...testID(TestIdentifiers.llm.patientSynopsis.patientSynopsisIcon)}><HeartPulseSvg /></span>
                </View>
                </Tooltip>
                <VStack>
                  <Text
                    color={Colors.Custom.ProfileViewTextColor}
                    fontSize={20}
                    fontWeight={600}
                    {...testID(TestIdentifiers.llm.patientSynopsis.patientSynopsisTitle)}
                  >
                    {intl.formatMessage({ id: 'carePlanRecommendation' })}
                  </Text>
                </VStack>
              </HStack>
            }
            key={'1'}
          >
            <View padding={3} gap={2}>
              {   isTemplateLoading ?
                <View padding={4} height={200} justifyContent={'center'}>
                  <LLMSkeletonLoader texts={loaderTemeplateValues} iconKey={'llmview' + 'skeleton'}/>
                </View> :
                (stateData ?
                <View>
                  <View gap={3} maxHeight={expanded ? '50%' : 200} overflow={'clip'}>
                    <VStack
                      borderColor={Colors.Custom.Gray200}
                      borderWidth={'1'}
                      borderRadius={12}
                    >
                      <View
                        paddingTop={3}
                        paddingX={3}
                      >
                        <div dangerouslySetInnerHTML={{ __html: stateData }} />
                      </View>
                    </VStack>
                  </View>
                  <View position={'relative'}>
                    <div className={expanded ? '' : 'custom-overflow'} />
                    {stateData && (
                      <Text fontWeight={400} fontSize={14} lineHeight={16.8} color={Colors.Custom.Primary300} onPress={toggleExpand} paddingX={3} paddingTop={expanded ? 2 : 0}>
                        {expanded ? 'Show Less' : 'Show More'}
                      </Text>
                    )}
                  </View>
                </View> :
                <NoDataFoundWithCustomIcon
                icon={<InfoCircleSvg></InfoCircleSvg>}
                displayString={intl.formatMessage({id: 'noDataFound'})}
              ></NoDataFoundWithCustomIcon>

                )
              }
              <HStack
                space={2}
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingVertical: 5,
                  borderBottomLeftRadius: 9,
                  borderBottomRightRadius: 9,
                }}
              >
                <View
                  style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}
                  {...testID(TestIdentifiers.llm.patientSynopsis.patientSynopsisPoweredByUnity)}
                >
                  <UnitySvg iconKey={'llmview' + 'UnitySvg'}/>
                  <Text
                    marginLeft={2}
                    size={'xsNormal'}
                    fontWeight={600}
                    noOfLines={1}
                    style={{
                      // @ts-ignore: Unreachable code error
                      backgroundImage:
                        'linear-gradient(237.73deg, #1E9DAE 0.27%, #D478FF 102.44%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      textAlign: 'center',
                    }}
                  >
                    {intl.formatMessage({ id: 'poweredByUnity' })}
                  </Text>

                  <Text
                    marginLeft={2}
                    size={'xsNormal'}
                    fontWeight={550}
                    noOfLines={1}
                    style={{
                      color: Colors.FoldPixel.GRAY200,
                      fontFamily: 'Courier',
                      textAlign: 'center',
                    }}
                  >
                    {intl.formatMessage({ id: 'beta' })}
                  </Text>
                </View>
                {(!isTemplateLoading) && stateData ? (
                  <>
                  <Tooltip label={intl.formatMessage({id: 'dislike'})} placement='top' hasArrow>
                    <Pressable
                      flexDirection={'row'}
                      alignItems={'center'}
                      onPress={() => {
                        setFeedback({score: 0, comment: ''});
                        setShowFeedbackModal( (prev)=>({...prev, show:true}));
                      }}
                      {...testID(TestIdentifiers.llm.patientSynopsis.patientSynopsisThumpsDown)}
                    >
                        <DislikeSvg
                          isActive={feedback.score === 0 ? true : false}
                          keyy={'llmview'}
                        />
                    </Pressable>
                    </Tooltip>
                    <Tooltip label={intl.formatMessage({id: 'like'})} placement='top' hasArrow>
                    <Pressable
                      flexDirection={'row'}
                      alignItems={'center'}
                      onPress={() => {
                        setFeedback({score: 1, comment: ''});
                        setShowFeedbackModal( (prev)=>({...prev, show:true}));
                      }}
                      {...testID(TestIdentifiers.llm.patientSynopsis.patientSynopsisThumbsUp)}
                    >
                        <LikeSvg
                          isActive={feedback.score === 1 ? true : false}
                          keyy={'llmview'}
                        />
                    </Pressable>
                    </Tooltip>
                  </>
                ) : null}
              <Tooltip
                isDisabled={false}
                label={lastSyncMessage}
                placement='top right'
                hasArrow
              >
                <Pressable
                  flexDirection={'row'}
                  alignItems={'center'}

                  onPress={() => {
                    setIsTemplateLoading(true);
                    fetchData(false);
                  }}
                  {...testID(TestIdentifiers.llm.healthMap.healthMapHardRefresh)}
                >
                <HardRefreshIconSvg iconKey={'llmmap'}/>
              </Pressable>
              </Tooltip>
              </HStack>
            </View>
          </Panel>
        </Collapse>
      </Box>
    </div>
  );
};
