import { IInternalUsers, IUserOptionCheck, IUserPracticeLocation, IUserPracticeLocationResp } from "./interface";

export const filterInternalUsers = (users: IInternalUsers[], disabledUsers: string[], selectedUsers: string[]): IUserOptionCheck[] => {
    const filteredUsers: IUserOptionCheck[] = [];
    for (let userIndex = 0; userIndex < users?.length; userIndex++) {
      const singleUser: any = users[userIndex];
      if (singleUser?.userId && singleUser?.user?.id) {
        singleUser.user.isDisabled = disabledUsers.includes(
          singleUser?.user?.uuid
        );
        singleUser.user.checked = selectedUsers.includes(
          singleUser?.user?.uuid
        )
        filteredUsers.push(singleUser.user);
      }
    }
    return filteredUsers;
}

export const getSelectedUsersUUIDs = (
    internalUsersData: IInternalUsers[]
): string[] => {
    return internalUsersData.map((user) => {
        return user?.user?.uuid;
    });
};

export const filterLoggedInUserFromInternalUser = (
  userList: IInternalUsers[],
  userUuid: string
) => {
  const filteredUsers = userList?.filter(
    (item) => item?.user?.uuid !== userUuid
  );
  return filteredUsers;
};

export const filterSpecificRolesFromInternalUser = (
  userList: IInternalUsers[],
  roles: string[]
) => {
  const filteredUsers = userList?.filter(
    (item) => {
      const userRoleIds = item.user.userRoles?.map((role) => role.userRole.roleId);
      const isRolePresent = userRoleIds?.some(roleId => roles.includes(roleId ?? ''));
      return isRolePresent;
    }
  );
  return filteredUsers;
};

export const getLocationUuidFromPracticeLocations = (
  userPracticeLocationsResp: IUserPracticeLocationResp
) => {
  if (userPracticeLocationsResp?.userPracticeLocations?.length) {
    const locationUuids = userPracticeLocationsResp?.userPracticeLocations
      .map((userPracticeLocation: IUserPracticeLocation) => {
        const locationUuid =
          userPracticeLocation?.accountLocations?.[0]?.practiceLocation?.uuid;
        return locationUuid;
      })
      .filter((locationUuid) => locationUuid !== undefined);
    return locationUuids;
  }
  return [];
};

export const isStringEmpty = (input?: string) => {
  return input !== undefined && !input?.length;
};

export const removeDuplicatesUsers = (users: IInternalUsers[]): IInternalUsers[] => {
  const uniqueUuids = new Set();

  return users.filter((item: IInternalUsers) => {
    const uuid = item.user.uuid;
    if (!uniqueUuids.has(uuid)) {
      uniqueUuids.add(uuid);
      return true;
    }
    return false;
  });
};
