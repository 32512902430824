import React from "react";
import { Button, HStack, Text } from "native-base";
import { Platform } from "react-native";
import Feather from 'react-native-vector-icons/Feather';
import { IDiagnosticReportFhirResponse, IReportAndResourceMap } from "./OrdersInterface";
import { getDiagnosticReportByPatientId } from "../../../../services/CommonService/OrderService";
import { getDiagnosticReportResponseFormatted } from "./RecentReport/ReportUtils";
import { DiagnosticReport } from "fhir/r4";
import { getDiagnosticReportById } from "../../../../services/CommonService/AidBoxService";
import { ReportCategory } from "../../MiddleContainer/Orders/OrdersAndReports/OrderUtils";
import { IOrderDetailsDrawer } from "../../MiddleContainer/Orders/OrdersAndReports/OrderDetailsDrawer/interfaces";
import { StyleSheet } from "react-native";

export const ShowMoreButton = (props: {
    onPress: () => void;
    isShowMore: boolean;
  }) => (
    <HStack justifyContent={Platform.OS === 'web' ? 'center' : 'flex-end'}>
      <Button
        p={1}
        pt={0}
        pb={0}
        mt={2}
        rounded="full"
        style={styles.button}
        onPress={() => {
          props.onPress();
        }}>
        <HStack mx={2} alignItems="center">
          <Feather
            name={props.isShowMore ? 'chevron-up' : 'chevron-down'}
            color="#825AC7"
            size={14}
          />
          <Text
            style={styles.text}
            color={'#825AC7'}>
            {props.isShowMore ? `Show Less` : `Show More`}
          </Text>
        </HStack>
      </Button>
    </HStack>
  );

  export const getLabOrders = async (patientId: string, accountLocationUuid: string, usePagination?: boolean, canShowMarkAsReview?: boolean, type?: string): Promise<IReportAndResourceMap[]> => {
    const LIMIT = 100
    let total = LIMIT
    let offset = 0
    let count = 0;
    let runNext = true
    const labReports: IReportAndResourceMap[] = []
    while(total > count && runNext) {
      try{
        runNext = false
        const response = await getDiagnosticReportByPatientId(patientId || '', accountLocationUuid, usePagination && {_count: LIMIT, _offset: offset}, type)
        if (response?.data) {
          count += response?.data?.entry?.length;
          const isEmpty = response?.data?.entry?.length === 0;
          total = response?.data?.total;
          offset += LIMIT;
          const formattedResponse = getDiagnosticReportResponseFormatted(response, canShowMarkAsReview, type !==  ReportCategory.IMAGING);
          const reports = formattedResponse.reports as IReportAndResourceMap[]
          if(reports){
            labReports.push(...reports)
          }
          if (usePagination && !isEmpty) {
            runNext = true
          }
        }
      }catch(error){
        total = 0
        runNext = false

      }
    }
    return labReports
  }

  export const getReportForImaging = async (
    imagingResource: DiagnosticReport,
    canShowMarkAsReview: boolean,
    supportingParams: {patientId: string; locationId: string}
  ) => {
    const labReports: IReportAndResourceMap[] = [];
    if (imagingResource.id && supportingParams) {
      const response = await getDiagnosticReportById(
        imagingResource.id,
        supportingParams.patientId,
        supportingParams.locationId,
        ReportCategory.IMAGING
      );
      const formattedResponse = getDiagnosticReportResponseFormatted(
        {
          data: {
            entry: [
              {
                resource: {
                  ...response.data,
                  category: [{coding: [{code: 'RAD'}]}],
                },
              },
            ],
          },
        },
        canShowMarkAsReview,
        true
      );
      const reports = formattedResponse.reports as IReportAndResourceMap[];
      if (reports) {
        labReports.push(...reports);
      }
    }
    return labReports;
  };

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#FDFAFF',
    borderColor: '#EFE0FC',
    height: 28,
    borderWidth: 1,
  },
  text: {
    fontWeight: '500',
    fontSize: 10,
  }
})