import { StyleSheet } from "react-native";
import { Colors } from "../../../styles/Colors";

export const styles = StyleSheet.create({
    spinner: {
      margin: '0 auto',
      position: 'absolute',
      zIndex: 100011111,
      backgroundColor: '#0000000f',
      height: '100%',
      width: '100%',
      padding: 0,
    },
    hStack: {
      marginVertical: 4,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottomWidth: 1,
      borderBottomColor: Colors.Custom.Gray200,
      padding: 16,
    },
    radioView: {
      padding: 2,
    },
    radioText: {
      fontSize: 18,
      fontWeight: '500',
    },
    responseText: {
      fontSize: 14,
      fontWeight: '400',
      color: Colors.Custom.Gray500,
    },
    pressable: {
      display: 'flex',
      padding: 18,
      width: 100,
      height: 50,
      alignItems: 'flex-end',
    },
    rightArrowView: {
      width: 16,
      height: 16,
    },
    noDataView: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 12,
    },
    text: {
      color: Colors.secondary['400'],
    }
  });