import {Button, Modal } from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../constants';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {IDeleteEmployerModalProps} from '../interfaces';
import {styles} from './DeleteEmployerModalStyle';

const {Content, CloseButton, Header, Footer, Body} = Modal;
const DeleteEmployerModal = (props: IDeleteEmployerModalProps) => {
  const {isOpen, onClose, onConfirm} = props;
  const intl = useIntl()
  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <Content style={styles.modalContainer}>
        <CloseButton />
        <Header>
          <DisplayText
            textLocalId={props.isMembers ? 'deleteMember' : 'deleteEmployer'}
            textType="Heading"
          />
        </Header>
        <Body>
          <DisplayText
            textLocalId={
              props.isMembers
                ? 'deleteMembersConfirmation'
                : 'deleteEmployerConfirmation'
            }
            textType="Text"
          />
        </Body>
        <Footer style={styles.modalFooter}>
          <Button.Group>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onConfirm();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'confirm',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </Footer>
      </Content>
    </Modal>
  );
};

export default DeleteEmployerModal;
