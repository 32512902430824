export const INTEGRATION_CONSTANTS = {
  LANDING: 'INTEGRATION_LANDING',
  ADD_INTEGRATION: 'INTEGRATION_CREATE',
  SCREEN_ONE: 0,
  SCREEN_TWO: 1,
  SCREEN_THREE: 2,
  SCREEN_FOUR: 3,
  SCREEN_FIVE: 4,
  WEBSITE: 'Website',
  EMAIL: 'Email',
  CHANNEL: 'Channel',
  CHOOSE_CHANNEL: 'Choose a Channel',
  CHANNEL_NAME: 'Channel Name:',
  CREATE_EMAIL_CHANNEL: 'Create Email Channel',
  WEBSITE_NAME: 'Website Name',
  WEBSITE_DOMAIN: 'Website Domain',
  WIDGET_COLOR: 'Widget Color:',
  WELCOME_HEADING: 'Welcome Heading:',
  WELCOME_TAGLINE: 'Welcome Tagline:',
  ENABLE_GREETING: 'Enable Channel Greeting:',
  CREATE_INBOX: 'Create Inbox',
  AGENTS: 'Agents',
  EDIT_EMAIL: 'EDIT_EMAIL',
  EDIT: 'EDIT',
  EDIT_WIDGET: 'EDIT_WIDGET',
  AGENTS_DESCRIPTION: `Here you can add agents to manage your newly created inbox. Only these selected agents will have access to your inbox. Agents which are not part of this inbox will not be able to see or respond to messages in this inbox when they login. PS: As an administrator, if you need access to all inboxes, you should add yourself as agent to all inboxes that you create.`,
  AGENT_BOT_DESCRIPTION: `Here you can add agent bot to conduct a conversation.`,
  ADD_AGENTS: 'Add Agents',
  ADD_AGENT_BOT: 'Add Agent Bot',
  Next: 'Next',
  INTEGRATIONS: 'Integrations',
  REPLY_MINUTES: 'In a few minutes',
  REPLY_HOURS: 'In a few hours',
  REPLY_DAYS: 'In a day',
  WEB_WIDGET: 'web_widget',
};

export const INBOX_CHANNEL_TYPE = {
  SMS: 'SMS',
  WHATSAPP: 'WHATSAPP',
  EMAIL: 'EMAIL',
  WEB_SITE: 'WEB_SITE',
  GMAIL: 'GMAIL',
  OUTLOOK: 'OUTLOOK',
};

export const INBOX_TYPE = {
  SMS: 'SMS',
  WEBSITE: 'Website',
  GMAIL: 'Gmail',
  OUTLOOK: 'Outlook',
  DEFAULT: 'Default',
};

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
