import { IAddOrUpdateAvailability, IAvailabilitiesByDateRangeKey, IAvailabilitiesByDaysOfWeek, ICalendarUserAvailability, IComponentState, IPracticeAvailability } from './PracticeAvailabilityInterfaces';
import { IUser } from '../../../../Interfaces';
import { IAccountLocation } from '../../Contacts/TeamMembers/interfaces';
import { ITimezone } from '../../../../services/Location/interfaces';
import { Drawer } from 'antd';
import { ModalActionTitle } from '../../../common/ModalActionTitle/ModalActionTitle';
import { Colors } from '../../../../styles';
import { BUTTON_TYPE, DATE_FORMATS } from '../../../../constants';
import { Center, HStack, Icon, Pressable, Spacer, Text, View, VStack } from 'native-base';
import { AddOrUpdateAvailabilitiesCard } from './AddOrUpdateAvailabilitiesCard';
import { useEffect, useState } from 'react';
import { extractAccountLocationsFromUserPracticeLocations } from '../../../../utils/commonUtils';
import { v4 as uuidV4 } from 'uuid';
import { areAvailabilitiesInvalid, isAvailabilitiesPresent, isAvailabilityInvalid } from './PracticeAvailabilityHelper';
import { showToast } from '../../../../utils/commonViewUtils';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { useMutation } from '@apollo/client';
import { ScheduleQueries } from '../../../../services';
import { VIRTUAL_LOCATION_CODE } from './PracticeAvailability';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { getFormattedDate } from '../../../../utils/DateUtils';
import { capitalizeText } from '../../ContentManagement/CampaignManagement/Helper';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {useIntl} from 'react-intl';
import PlusIcon from '../../../common/Svg/PlusSvg';
import { updateBusinessHours, updateBusinessHoursInCloudTelephony } from '../../AutoReplyView/AutoReplyViewUtils';

interface IProps {
  availabilitiesByDaysOfWeek: IAvailabilitiesByDaysOfWeek;
  overriddenAvailabilitiesByDateRangeKey?: IAvailabilitiesByDateRangeKey;
  selectedUserData?: IUser;
  accountLocations: IAccountLocation[];
  timezones: ITimezone[];
  defaultTimezone?: ITimezone;
  isUserSchedule: boolean;
  defaultAvailability: IAddOrUpdateAvailability;

  isOpen?: boolean;

  onClose: () => void;
  onSave: (response: { isSuccess: boolean; message?: string; updateRecords?: boolean; }) => void;

  availabilityCalendar?: ICalendarUserAvailability;
}

export function AddOrUpdateAvailabilityDrawer(props: IProps) {
  const [ componentState, setComponentState ] = useState<IComponentState>({
    availabilitiesByDaysOfWeek: props.availabilitiesByDaysOfWeek,
    overriddenAvailabilitiesByDateRangeKey: props.overriddenAvailabilitiesByDateRangeKey,
    updatedAvailabilitiesByLocalId: {},
    showErrors: false,
  });
  const intl = useIntl();
  let accountLocations = props.accountLocations || [];
  if (props.isUserSchedule && !props?.availabilityCalendar) {
    accountLocations = extractAccountLocationsFromUserPracticeLocations(props.selectedUserData?.userPracticeLocations || []);
  }

  const [addUpdateAvailabilitiesAPI, {loading: isAddUpdateScheduleLoading}] = useMutation(ScheduleQueries.ADD_UPDATE_SCHEDULE, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  function onAddNewCard(isOverrideAvailability: boolean) {
    setComponentState((prev) => {
      const cardId = uuidV4();

      const defaultAvailability: IAddOrUpdateAvailability = {
        ...props.defaultAvailability,
        localId: uuidV4(),
        timezoneId: props.defaultTimezone?.uuid,
        isNew: true,
      };

      if (isOverrideAvailability) {
        if (!prev.overriddenAvailabilitiesByDateRangeKey) {
          return prev;
        }
        const beginDate = new Date();
        const endDate = new Date();

        defaultAvailability.daysOfWeek = '[]';
        defaultAvailability.isOverrideAvailability = true;
        defaultAvailability.beginDate = getFormattedDate(beginDate, DATE_FORMATS.AVAILABILITY_DATE_FORMAT);
        defaultAvailability.endDate = getFormattedDate(endDate, DATE_FORMATS.AVAILABILITY_DATE_FORMAT);

        prev.overriddenAvailabilitiesByDateRangeKey?.data.unshift({
          availabilities: [ defaultAvailability ],
          localId: cardId,
          beginDate,
          endDate,
          timezoneId: props.defaultTimezone?.uuid,
        });

        prev.overriddenAvailabilitiesByDateRangeKey.isAvailabilitiesPresent = true;

      } else {
        const selectedDaysOfWeek = [ 1, 2, 3, 4, 5 ];
        defaultAvailability.daysOfWeek = JSON.stringify(selectedDaysOfWeek);

        prev.availabilitiesByDaysOfWeek.data.unshift({
          availabilities: [ defaultAvailability ],
          localId: cardId,
          selectedDaysOfWeek,
          timezoneId: props.defaultTimezone?.uuid,
        });
        prev.availabilitiesByDaysOfWeek.isAvailabilitiesPresent = true;
      }

      prev.updatedAvailabilitiesByLocalId[defaultAvailability.localId] = defaultAvailability;


      return { ...prev };
    });
  }

  function onUpdateAvailabilities(availabilities: IAddOrUpdateAvailability[]) {
    setComponentState((prev) => {
      availabilities.forEach((availability) => {
        prev.updatedAvailabilitiesByLocalId[availability.localId] = availability;
      });

      prev.availabilitiesByDaysOfWeek.isAvailabilitiesPresent = isAvailabilitiesPresent(prev.availabilitiesByDaysOfWeek);
      return { ...prev };
    });
  }

  function onSave() {
    setComponentState((prev) => ({ ...prev, showErrors: true}));

    let availabilities = Object.values(componentState.updatedAvailabilitiesByLocalId);
    if (areAvailabilitiesInvalid(availabilities)) {
      return;
    }

    // availabilities = availabilities.filter((availability) => {
    //   return !availability.isDeleted || availability.id;
    // });

    if (!availabilities.length) {
      return props.onSave({
        isSuccess: true,
        message: 'No records found to update',
      });
    }

    if (props.isUserSchedule && !props.selectedUserData?.uuid) {
      return props.onSave({ isSuccess: false });
    }

    if (props.isUserSchedule && props.availabilitiesByDaysOfWeek.isPracticeScheduleApplied) {
      const isPracticeSchedulesChanged = availabilities.some((availability) => {
        if (!availability.isOverrideAvailability) {
          return true;
        }
        return false;
      });

      if (isPracticeSchedulesChanged) {
        componentState.availabilitiesByDaysOfWeek.data.forEach((data) => {
          data.availabilities.forEach((availability) => {
            if (!componentState.updatedAvailabilitiesByLocalId[availability.localId]) {
              componentState.updatedAvailabilitiesByLocalId[availability.localId] = availability;
            }
          });
        });
        availabilities = Object.values(componentState.updatedAvailabilitiesByLocalId);
      }
    }

    if (props.isUserSchedule) {
      availabilities = availabilities.map((availability) => {
        const userAvailability = {
          ...availability,
          userId: props.selectedUserData?.uuid,
        };
        if (!availability.isOverrideAvailability && (props.availabilitiesByDaysOfWeek.isPracticeScheduleApplied || !availability.userId)) {
          delete userAvailability.id;
        }
        return userAvailability
      })
    }

    const deletedAvailabilities: IPracticeAvailability[] = [];
    const updatedAvailabilities: IPracticeAvailability[] = [];
    const allAvailabilities: IPracticeAvailability[] = []

    availabilities.forEach((availability) => {
      if (availability.isDeleted && !availability.id) {
        return;
      }
      const isVirtualAvailability = availability.locationId === VIRTUAL_LOCATION_CODE || availability.isVirtualLocation;
      const practiceAvailability: IPracticeAvailability = {
        id: availability.id,
        beginDate: availability.beginDate,
        endDate: availability.endDate,
        startTime: availability.startTime,
        endTime: availability.endTime,
        daysOfWeek: availability.daysOfWeek,
        isVirtualLocation: isVirtualAvailability,
        locationId: isVirtualAvailability ? null : availability.locationId,
        timezoneId: availability.timezoneId,
        typeId: availability.typeId,
        userId: availability.userId,
        inboxId: availability.inboxId,
        isDeleted: availability.isDeleted,
      }
      if (availability.isDeleted) {
        deletedAvailabilities.push(practiceAvailability);
      } else {
        updatedAvailabilities.push(practiceAvailability)
        allAvailabilities.push({
          ...practiceAvailability,
          timezone:
            availability.timezone ||
            props.timezones.find(
              (item) => item.uuid == availability.timezoneId
            ),
        });
      }
    });

    if (!deletedAvailabilities.length && !updatedAvailabilities?.length) {
      return props.onSave({
        isSuccess: true,
        message: 'No records found to update',
      });
    }

    setComponentState((prev) => {
      return {
        ...prev,
        isSaveLoading: true,
      }
    });

    addUpdateAvailabilitiesAPI({
      variables: {
        data: [
          ...deletedAvailabilities,
          ...updatedAvailabilities
        ],
      },
      onCompleted: async () => {
        setComponentState((prev) => ({ ...prev, isSaveLoading: false }));
        await updateBusinessHours({data: allAvailabilities})
        await updateBusinessHoursInCloudTelephony({data: allAvailabilities})
        return props.onSave({
          isSuccess: true,
          message: intl.formatMessage({id: 'scheduleUpdated'}),
          updateRecords: true,
        });
      },
      onError: (error) => {
        setComponentState((prev) => ({ ...prev, isSaveLoading: false }));
        return props.onSave({ isSuccess: false });
      }
    });

    // props.onSave();
  }


  useEffect(() => {
   if (props?.availabilityCalendar?.isOverrideAvailability) {
    setComponentState((prev) => {
      const cardId = uuidV4();

      const defaultAvailability: IAddOrUpdateAvailability = {
        ...props.defaultAvailability,
        localId: uuidV4(),
        timezoneId: props.defaultTimezone?.uuid,
      };

        if (!prev.overriddenAvailabilitiesByDateRangeKey) {
          return prev;
        }
        const beginDate = props?.availabilityCalendar?.selectedDate;
        const endDate = props?.availabilityCalendar?.selectedDate;

        defaultAvailability.daysOfWeek = '[]';
        defaultAvailability.isOverrideAvailability = true;
        defaultAvailability.beginDate = getFormattedDate(beginDate, DATE_FORMATS.AVAILABILITY_DATE_FORMAT);
        defaultAvailability.endDate = getFormattedDate(endDate, DATE_FORMATS.AVAILABILITY_DATE_FORMAT);
        defaultAvailability.locationId = props?.availabilityCalendar?.locationId || '';

        prev.overriddenAvailabilitiesByDateRangeKey?.data.unshift({
          availabilities: [ defaultAvailability ],
          localId: cardId,
          beginDate,
          endDate,
          timezoneId: props.defaultTimezone?.uuid,
        });

        prev.overriddenAvailabilitiesByDateRangeKey.isAvailabilitiesPresent = true;
        prev.updatedAvailabilitiesByLocalId[defaultAvailability.localId] = defaultAvailability;
      return { ...prev };
    });
   }
  }, [props?.availabilityCalendar]);

  return (
    <Drawer
      headerStyle={{borderWidth: 0, marginBottom: 0}}
      placement="right"
      visible={props.isOpen}
      afterOpenChange={() => {
      }}
      onClose={props?.onClose}
      width={700}
      closable
      destroyOnClose={true}
      title={
        <VStack space={3}>
          <ModalActionTitle
            isHeadNotSticky={true}
            title={
              props.isUserSchedule
                ? props?.selectedUserData?.name
                  ? `Edit ${capitalizeText(
                      props?.selectedUserData?.name
                    )}'s Schedule`
                  : 'editUserSchedule'
                : 'editPracSchedule'
            }
            titleSize={24}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                size: 'sm',
                isLoading: false,
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  props?.onClose?.();
                },
              },
              {
                show: true,
                isLoading: componentState.isSaveLoading,
                id: 2,
                btnText: 'save',

                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,

                onClick: () => {
                  onSave();
                },
              },
            ]}
          />

          <HStack space={2}>
            <Spacer />
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onAddNewCard(false);
                },
                leftIcon: (
                 <PlusIcon />
                ),
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'practiceAvailabilityAddSlots',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            {props.isUserSchedule && (
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    onAddNewCard(true);
                  },
                  leftIcon: (
                    <PlusIcon />
                  ),
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'practiceAvailabilityScheduleOverride',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            )}
          </HStack>
        </VStack>
      }
    >
      <VStack>
        {componentState.overriddenAvailabilitiesByDateRangeKey
          ?.isAvailabilitiesPresent &&
          componentState.overriddenAvailabilitiesByDateRangeKey.data?.map(
            (data) => {
              const availabilities = data.availabilities;
              const localId = data.localId;
              const beginDate = data.beginDate;
              const endDate = data.endDate;

              return (
                <AddOrUpdateAvailabilitiesCard
                  key={localId}
                  cardId={localId}
                  isOverrideAvailability={true}
                  beginDate={beginDate}
                  endDate={endDate}
                  selectedTimezoneId={data.timezoneId}
                  accountLocations={accountLocations}
                  availabilities={availabilities}
                  defaultTimezone={props.defaultTimezone}
                  timezones={props.timezones}
                  defaultAvailability={props.defaultAvailability}
                  onUpdateAvailabilities={onUpdateAvailabilities}
                  showErrors={componentState.showErrors}
                />
              );
            }
          )}
        {componentState.availabilitiesByDaysOfWeek.isAvailabilitiesPresent &&
          componentState.availabilitiesByDaysOfWeek.data?.map((data) => {
            const availabilities = data.availabilities;
            const localId = data.localId;
            const selectedDaysOfWeek = data.selectedDaysOfWeek;

            return (
              <AddOrUpdateAvailabilitiesCard
                key={localId}
                cardId={localId}
                isOverrideAvailability={false}
                selectedDaysOfWeek={selectedDaysOfWeek}
                selectedTimezoneId={data.timezoneId}
                accountLocations={accountLocations}
                availabilities={availabilities}
                defaultTimezone={props.defaultTimezone}
                timezones={props.timezones}
                defaultAvailability={props.defaultAvailability}
                onUpdateAvailabilities={onUpdateAvailabilities}
                showErrors={componentState.showErrors}
              />
            );
          })}
        {!componentState.availabilitiesByDaysOfWeek.isAvailabilitiesPresent &&
          !componentState.overriddenAvailabilitiesByDateRangeKey
            ?.isAvailabilitiesPresent && (
            <Center
              height={654}
              width="full"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontWeight={200} fontSize="lg" textAlign="center">
                No schedule present.
                <Text>
                  {'\n'}Please click{' '}
                  <Pressable
                    onPress={() => {
                      onAddNewCard(false);
                    }}
                  >
                    <Text color="primary.500">here</Text>
                  </Pressable>{' '}
                  to add new schedule.
                </Text>
              </Text>
            </Center>
          )}
      </VStack>
    </Drawer>
  );
}
