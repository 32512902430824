import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  titleWrapper: {
    backgroundColor: '#fff',
    // marginHorizontal: 22,
    // marginVertical: 12,
  },

  tabsWrapper: {
    // marginHorizontal: 14,
  },
});
