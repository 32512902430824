export const getFormatedResponse = (taxons: any) => {
  let parentTaxon: any = {};
  const addedParentIdArray: any = [];
  const formatedNestedArray: any[] = [];
  const filterTaxonmy = taxons?.filter((item: any) => {
    if (item.relationships.parent.data != null) {
      item.parentId = item.relationships.parent.data.id;
      addedParentIdArray.push(item);
      return item.relationships.parent.data != null;
    } else {
      parentTaxon = item;
      parentTaxon.parentId = null;
      addedParentIdArray.push(parentTaxon);
    }
  });

  const formatedTreeObject = tryanother(addedParentIdArray);
  formatedNestedArray.push(formatedTreeObject);
  filterTaxonmy;
  return formatedNestedArray;
};

export const tryanother = (array: any) => {
  const idMapping = array.reduce((acc: any, el: any, i: any) => {
    acc[el.id] = i;
    return acc;
  }, {});
  let root: any;
  array.forEach((el: any) => {
    // Handle the root element
    if (el.parentId === null) {
      root = el;
      return;
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = array[idMapping[el.parentId]];
    // Add our current el to its parent's `children` array
    if (parentEl) {
      parentEl.children = [...(parentEl.children || []), el];
    }
  });
  return root;
};

export const unflattens = (arr: any, parentTaxon: any) => {
  const tree = [];
  const mappedArr: any = {};
  let arrElem: any = {};
  let mappedElem: any = {};

  for (let i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.id] = arrElem;
    mappedArr[arrElem.id]['children'] = [];
  }
  for (const id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      if (mappedElem.parentId && mappedElem.parentId != parentTaxon.id) {
        if (mappedArr[mappedElem['parentId']]) {
          mappedArr[mappedElem['parentId']]['children'].push(mappedElem);
        }
      } else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
};
