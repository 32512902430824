import { WEB_SOCKET_EVENT_CODE } from './../constants/WebSocketConst';
import {WEB_SOCKET_CONFIG} from '../constants/Configs';
import {SUPPORTED_EVENT_CODE_LIST} from '../constants/WebSocketConst';
import {WebSocketEventData} from '../context/interfaces';
import {broadcastEvent} from './CustomEventHandler';

import { isWeb } from './platformCheckUtils';
import {IPresenceUpdateMsgData} from '../Interfaces/WebSocketEventInterfaces';
declare const window: any;
export class CommonWebSocket {
  private static commonWsInst: CommonWebSocket;
  public webSocketInst: WebSocket;
  private sendPresenceItFn: NodeJS.Timer = {} as any;
  private userData;
  public static onlineUserData: IPresenceUpdateMsgData = {
    account_id: -1,
    users: {},
    contacts: {}
  };
  constructor(userData: any) {
    this.userData = userData;
    this.webSocketInst = new WebSocket(WEB_SOCKET_CONFIG.BASE_URL);
    CommonWebSocket.commonWsInst = this;
    this.init();
  }
  static getWebSocketInstance(userData: any): CommonWebSocket {
    if (!isWeb()) {
      CommonWebSocket.closeWebSocket();
    }
    if (!this.commonWsInst) {
      return (CommonWebSocket.commonWsInst = new CommonWebSocket(userData));
    } else if (
      this.commonWsInst.webSocketInst &&
      (this.commonWsInst.webSocketInst.readyState === WebSocket.CLOSED ||
        this.commonWsInst.webSocketInst.readyState === WebSocket.CLOSING)
    ) {
      return (CommonWebSocket.commonWsInst = new CommonWebSocket(userData));
    }
    return CommonWebSocket.commonWsInst;
  }

  static closeWebSocket() {
    if (
      this.commonWsInst &&
      this.commonWsInst.webSocketInst &&
      this.commonWsInst.webSocketInst.readyState === WebSocket.OPEN
    ) {
      this.commonWsInst.userData = null;
      this.commonWsInst.webSocketInst.close();
    }
  }

  private init() {
    window.webSocketInst = this.webSocketInst;
    this.webSocketInst.onopen = () => {
      const customerPubsubTokenObj = {
        channel: 'RoomChannel',
        pubsub_token: this.userData?.pubsub_token,
        account_id: this.userData?.account_id,
        user_id: this.userData?.id,
      };
      this.webSocketInst.send(
        JSON.stringify({
          command: 'subscribe',
          identifier: JSON.stringify(customerPubsubTokenObj),
        })
      );
      this.sendPresenceToWebSocket();
    };

    this.webSocketInst.onclose = (closeEvent: WebSocketCloseEvent) => {
      clearInterval(this.sendPresenceItFn);
      setTimeout(() => {
        if (this.userData) {
          initializeWebSocketConnection(this.userData);
        }
      }, 5000);
    };

    this.webSocketInst.onerror = (errorEvent: any) => {
      if (errorEvent) {
        if (errorEvent.message) {
        } else {
        }
      } else {
      }
    };
    this.webSocketInst.onmessage = (socketEvent: WebSocketMessageEvent) => {
      const eventData = socketEvent?.data || '{}';
      const msgData: WebSocketEventData = JSON.parse(eventData);
      if (
        SUPPORTED_EVENT_CODE_LIST.includes(msgData?.message?.event) &&
        msgData?.message?.data
      ) {
        let eventMessageData: any = msgData?.message?.data;
        eventMessageData = Object.keys(eventMessageData?.data || {}).length > 0
          ? eventMessageData?.data
          : eventMessageData;
        if (!eventMessageData?.isDuplicate) {
          if (msgData?.message?.data?.eventCode) {
            broadcastEvent(msgData?.message?.event, msgData?.message?.data);
          } else {
            const updatedEventData = {
              ...msgData?.message?.data,
              eventCode: msgData?.message?.event
            }
            broadcastEvent(msgData?.message?.event, updatedEventData);
          }
        }
      }
      if (WEB_SOCKET_EVENT_CODE.PRESENCE_UPDATE === msgData?.message?.event) {
        CommonWebSocket.onlineUserData = msgData?.message?.data as any;
      }
    };
  }

  private sendPresenceToWebSocket() {
    if (this.webSocketInst.readyState === WebSocket.OPEN) {
      const customerPubsubTokenObj = {
        channel: 'RoomChannel',
        pubsub_token: this.userData?.pubsub_token,
        account_id: this.userData?.account_id,
        user_id: this.userData?.id,
      };
      this.sendPresenceItFn = setInterval(() => {
        this.webSocketInst.send(
          JSON.stringify({
            command: 'message',
            data: JSON.stringify({action: 'update_presence'}),
            identifier: JSON.stringify(customerPubsubTokenObj),
          })
        );
      }, WEB_SOCKET_CONFIG.sendPresenceItInMS);
    } else if (
      this.webSocketInst.readyState === WebSocket.CLOSED ||
      this.webSocketInst.readyState === WebSocket.CLOSING
    ) {
      clearInterval(this.sendPresenceItFn);
    }
  }
}
export const initializeWebSocketConnection = (userData: any): void => {
  CommonWebSocket.getWebSocketInstance(userData);
};

export const getUserOnlineDataFromWebsocketUtils = () => {
  return CommonWebSocket.onlineUserData;
}

