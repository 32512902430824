import {AxiosInstance, AxiosRequestConfig} from 'axios';
import {LLM_SERVICE_URL} from '../../../constants/Configs';
import CommonService from '../../../services/CommonService/CommonService';
import {ISuggestionResponse} from './interfaces';
import BaseService from '../../../services/CommonService/BaseService';
import { CONTEXT_PROMPT_TYPE } from '../../RightSideContainer/ContentManagement/LLMTemplates/interfaces';

export const getPromptSuggestions = async (data: {
  prompt: string;
  numOfSuggestions?: number;
  config?: AxiosRequestConfig;
}) => {
  const url = `crm-nest/conversation/prompt-response`;
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.post<ISuggestionResponse>(
    url,
    data,
    data.config || {}
  );
  return response.data;
};

export const getContextPrompt = async (
  context: CONTEXT_PROMPT_TYPE,
  config?: AxiosRequestConfig
) => {
  const url = `${LLM_SERVICE_URL}/summary/prompt?context=${context}`;
  const llmService: AxiosInstance =
    CommonService.getCommonServiceInstance().llmService;
  const response = await llmService.get<{ response: string }>(url, config);
  return response.data.response;
};

export const getSuggestedResponse = async (data: {
  conversationUuid?: string;
  messageUuid?: string;
  config?: AxiosRequestConfig;
}) => {
  const url = `crm-nest/conversation/suggested-response`;
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.post<ISuggestionResponse>(
    url,
    data,
    data.config || {}
  );
  return response?.data;
};
