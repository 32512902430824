import {gql} from '@apollo/client';

export const CreateContactOutreachActivity = gql`
  mutation addOrUpdateContactOutreachActivity(
    $data: AddOrUpdateContactOutreachActivityInput!
  ) {
    addOrUpdateContactOutreachActivity(params: $data) {
      id
    }
  }
`;

export const GetContactOutreachActivities = gql`
  query GetContactOutreachActivities(
    $contactId: uuid
    $careProgramTypeId: uuid
    $limit: Int!
    $offset: Int!
  ) {
    ContactOutreachActivities(
      where: {
        contactId: {_eq: $contactId}
        careProgramTypeId: {_eq: $careProgramTypeId}
      }
      limit: $limit
      offset: $offset
      order_by: {createdOn: desc}
    ) {
      id
      note
      statusId
      typeId
      resourceType
      communicationModeId
      outcomeId
      note
      outreachDateTime
      careProgramTypeId
      contactId
      createdBy
    }
     aggregateContactOutreachActivity(
      where: {
        contactId: {_eq: $contactId}
        careProgramTypeId: {_eq: $careProgramTypeId}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getContactOutreachActivityCount = gql`
  query ($contactId: uuid, $careProgramTypeId: uuid) {
    aggregateContactOutreachActivity(
      where: {
        contactId: {_eq: $contactId}
        careProgramTypeId: {_eq: $careProgramTypeId}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;


