import {Table} from 'antd';
import {View} from 'native-base';
import {Dimensions} from 'react-native';
import { IImportContactLogData, IImportContactLogProps } from './interface';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { getImportContactLogColumns } from './Helper';
import { getFailedContactsInImport } from '../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { useState } from 'react';

const ImportContactLogTable = (props: IImportContactLogProps) => {
  const {data,
  } = props;
  const screenHeight = Dimensions.get('window').height;
  const [isExporting, setIsExporting] = useState(false)


  const getColumnString = (memberObject: any) => {
    const keys = Object.keys(memberObject);
    let columnString = keys.join();
    columnString = columnString.concat(',').concat('Error').concat('\n');
    return columnString
  }
  const exportToCSV = async (rowRecord: IImportContactLogData) => {
    setIsExporting(true);
    const importLogData = await getFailedContactsInImport({
      batchId: rowRecord.batchId,
      tenantId: rowRecord.tenantId
    });

    const firstMessageBodyString = importLogData?.sqsLogs?.[0]?.messageBody;
    const firstMessageBody = JSON.parse(firstMessageBodyString);
    const memberData = firstMessageBody?.memberList?.[0];
    const columnString = getColumnString(memberData);
    const csvColumns = Object.keys(memberData);
    const csvData = buildCsvData(importLogData?.sqsLogs, csvColumns);

    const blob = new Blob(
      [columnString + csvData],
      {type: 'text/csv'}
    );
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'exported_data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    setIsExporting(false);
  };

  const buildCsvData = (sqsLogs: any[], csvColumns: string[]) => {
    let csvData = '';
    sqsLogs?.forEach((sqsLog) => {
      const firstMessageBodyString = sqsLog.messageBody;
      const firstMessageBody = JSON.parse(firstMessageBodyString);
      const memberData = firstMessageBody?.memberList?.[0];
      csvColumns.forEach((csvColumn) => {
        const value = memberData[csvColumn];
        csvData = csvData.concat(value).concat(',')
      });
      if (sqsLog?.status === 'EXISTING_MEMBER' || !sqsLog?.metadata?.message) {
        csvData = csvData.concat('EXISTING_MEMBER');
      } else {
        csvData = csvData.concat(sqsLog?.metadata?.message);
      }
      csvData = csvData.concat('\n');
    });
    return csvData;
  }

  const onImportContactLogTableActionPerformed = (
    actionCode: string,
    rowRecord: IImportContactLogData
  ): any => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.DOWNLOAD:
        exportToCSV(rowRecord);
        break;
      default:
        break;
    }
  };

  return (
    <View flex={1}>
      <Table
        style={{
          backgroundColor: '#fff',
        }}
        scroll={{x: 700, y: screenHeight - 260}}
        columns={getImportContactLogColumns(
          onImportContactLogTableActionPerformed
        )}
        className='import-cursor'
        dataSource={data}
        pagination={false}
        rowKey={(record: IImportContactLogData) => record.batchId}
        loading={isExporting}
      />
    </View>
  );
};

export default ImportContactLogTable;
