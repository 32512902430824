import {Button, Modal, View} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../constants';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {ContentTypes} from '../../ContentManagementConsts';
import {deleteMediaContent} from '../../ContentManagementUtils';
import {IDeleteMediaModalProps} from '../interfaces';
import {styles} from './DeleteModalStyle';

const {Content, Footer, Header, CloseButton, Body} = Modal;

const DeleteMediaModal = (props: IDeleteMediaModalProps) => {
  const {onClose, onConfirm, mediaData} = props;
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const handleConfirmation = () => {
    setLoading(true);
    const id = mediaData?.id;
    deleteMediaContent(ContentTypes.attachments.path, id)
      .then((response) => {
        setLoading(false);
        onConfirm();
      })
      .catch((error) => {

        setLoading(false);
        onClose();
      });
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Content style={styles.container}>
        <CloseButton />
        <Header>
          <DisplayText textLocalId="delete" textType="Heading" />
        </Header>

        <Body>
          <View>
            <DisplayText textLocalId="deleteMediaConfirmation" />
          </View>
        </Body>

        <Footer style={styles.footerStyle}>
          <Button.Group>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  handleConfirmation();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'confirm',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </Footer>
      </Content>
    </Modal>
  );
};

export default DeleteMediaModal;
