import React from 'react';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { ICommonSvgProps } from '../interfaces';
import {Image} from 'native-base'

const CallHoldActive = (props: ICommonSvgProps) => {
  const isWebsite = isWeb();
  return (
    <>
      {isWebsite ?
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width ?? 46}
          height={props.height ?? 45}
          fill="none"
          viewBox="0 0 40 39"
        >
          <rect width="38" height="38" x="1" y="0.5" fill="#825AC7" rx="19"></rect>
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M18.333 22v-5m3.334 5v-5m6.666 2.5a8.333 8.333 0 11-16.666 0 8.333 8.333 0 0116.666 0z"
          ></path>
          <rect
            width="38"
            height="38"
            x="1"
            y="0.5"
            stroke="#825AC7"
            rx="19"
          ></rect>
        </svg>
        :
        <Image
          resizeMode="contain"
          size={'32px'}
          source={require('../../../../assets/images/PNGImages/call_hold_active.png')}
          alt="image"
        />
      }
    </>
  );
};

export default CallHoldActive;
