import {AxiosInstance} from 'axios';
import {
  Button,
  FormControl,
  HStack,
  Image,
  Input,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions, Platform, Pressable} from 'react-native';
import BaseService from '../../services/CommonService/BaseService';
import {Colors} from '../../styles';
import {showToast, ToastType} from '../../utils/commonViewUtils';
import LocalStorage from '../../utils/LocalStorage';
import {isWeb} from '../../utils/platformCheckUtils';
import {DisplayText} from '../common/DisplayText/DisplayText';
import FoldhealthLogoSvg from '../common/Svg/FoldhealthLogoSvg';
import LoginBg from '../Login/LoginBg';
import {useKeyboard} from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/UseKeyBoard.native';
import {styles} from './ForgotPasswordStyles';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {color} from 'native-base/lib/typescript/theme/styled-system';
import { CustomScrollView } from '../common/ScrollView/CustomScrollView';
import {checkIfOffline} from '../../screens/MainScreen/MainScreenHelper';
import Toast from 'react-native-simple-toast';
import {useIntl} from 'react-intl';
import {SMALL_SCREEN} from '../../constants';
import { testID, TestIdentifiers } from '../../testUtils';
import {isValidEmail} from '../../utils/commonUtils';
import { captureError } from '../../utils/SentryEventUtils';
import { TrackEventName } from '../../constants/SentryConst';

interface ForgotPasswordProps {
  onSuccess: () => void;
  onLoginClick: () => void;
  screen?: string;
  email?: string;
}

export const forgotPassword = (args: {
  baseService: AxiosInstance;
  body: {
    email: string;
    accountId: string | number;
  };
}) => {
  const {baseService, body} = args;
  const url = '/crm-nest/auth/forgotUserPassword';
  return baseService.post(url, body);
};

const ForgotPassword = (props: ForgotPasswordProps) => {
  const {onSuccess,screen} = props;
  const {height} = Dimensions.get('window');
  const intl = useIntl();

  const keyboardHeight = useKeyboard();

  const [stateData, setStateData] = useState<{
    email: string;
    accountId: string | number;
  }>({
    email: props?.email || '',
    accountId: '' as string | number,
  });

  const [loading, setLoading] = React.useState(false);
  const isSmallScreen = height <= SMALL_SCREEN;

  const toast = useToast();

  const baseService = BaseService.getSharedInstance().axios;

  const onSubmit = () => {
    const {email, accountId} = stateData;

    if (!email) {
      showToast(toast, 'Please enter your email address', ToastType.error);
      return;
    }

    if (!isValidEmail(email)) {
      showToast(toast, intl.formatMessage({id: 'invalidEmailError'}), ToastType.error);
      return;
    }

    setLoading(true);
    const body = {
      email: email,
      accountId: accountId,
    };

    forgotPassword({
      baseService: baseService,
      body,
    })
      .then((response) => {
        successHandler(response);
      })
      .catch((error) => {
        if (checkIfOffline(error)) {
          setLoading(false);
          showToast(
            toast,
            intl.formatMessage({id: 'networkConnectionFailed'}),
            ToastType.info,
            3500,
          );
        } else {
          errorHandler(error);
        }
      });
  };

  const successHandler = (response: any) => {
    if (response.data) {
      showToast(toast, getSuccessMessage(response), ToastType.success);
      setLoading(false);
      onSuccess();
    }
  };

  const errorHandler = (error: any) => {
    setLoading(false);
    showToast(
      toast,
      'Something went wrong. Please try again later',
      ToastType.error
    );
    captureError(error, TrackEventName.FORGOT_PW_ERROR);
  };

  const getSuccessMessage = (response: any) => {
    return (
      response?.data?.message ||
      'Email has been sent to you with temporary password. Please log in with that and set a new one'
    );
  };

  useEffect(() => {
    LocalStorage.getItem('account')
      .then((response) => {
        const accountId = JSON.parse(response).accountId;
        setStateData((prev) => {
          return {
            ...prev,
            accountId: accountId,
          };
        });
      })
      .catch((error) => {
        captureError(error, TrackEventName.FORGOT_PW_ERROR);
      });
  }, []);

  return (
    <View
      bgColor={'white'}
      height={
        Platform.OS === 'web'
          ? 'auto'
          : keyboardHeight == 0
          ? 'full'
          : height - keyboardHeight
      }
    >
      <CustomScrollView
        style={{ justifyContent: 'flex-end', flexGrow: 1, height: height }}
      >
      {Platform.OS === 'web' && (
        <VStack
          height={Platform.OS === 'web' ? '100vh' : 'full'}
          justifyContent={'space-between'}
          style={
            Platform.OS === 'web' && {
              position: 'relative',
              right: 100,
            }
          }
        >
          <View />
          <View {...testID(TestIdentifiers.loginBg)} height={height - 100}>{<LoginBg />}</View>
        </VStack>
      )}
      {!isWeb() ? (
        <View
          style={{
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginRight: 34,
            marginTop: 60,
          }}
        >
          <View
            style={{
              width: 201,
              height: 200,
              backgroundColor: 'transparent',
            }}
          >
            <Image
              resizeMode="contain"
              source={require('../../assets/images/PNGImages/login-top-image.png')}
              alt={TestIdentifiers.foldhealthLogoIcon}
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
              }}
            />
          </View>
        </View>
      ) : null}
      <View
        flex={1}
        alignItems={'flex-end'}
        mt={Platform.OS === 'web' ? isSmallScreen ? '2%' : '10%' : '0'}
        mr={Platform.OS === 'web' ? '10%' : '0'}
        style={
          Platform.OS === 'web'
            ? {
                flex: 1,
                position: 'absolute',
                right: 0,
                top: 0,
              }
            : {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 16,
              }
        }
      >
        <View
          style={[
            styles.mainBoxContainer,
            {
              paddingTop: Platform.OS === 'web' ? isSmallScreen ? 0 : 30 : 0,
            },
            styles.mainBoxContainerShadow
          ]}
          bg={'white'}
        >
          <VStack w={'100%'}>
            <HStack
              justifyContent="center"
              alignItems={'center'}
              flex={1}
              mb={2}
              {...testID(TestIdentifiers.foldhealthLogoWithName)}
            >
              {Platform.OS === 'web' ? <FoldhealthLogoSvg /> : <></>}
            </HStack>
            <FormControl isRequired {...testID(TestIdentifiers.isRequiredInput)}>
              <VStack
                w={'100%'}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: -12,
                }}
              >
                <Text
                  style={{
                    fontSize: 30,
                    lineHeight: 45,
                    textAlign: 'center',
                    fontWeight: '700',
                    color: 'black',
                  }}
                  {...testID('Forgot Password')}
                >
                  Forgot Password
                </Text>
                <Text
                  size={'smMedium'}
                  color={Colors.Custom.Gray700}
                  mt={2}
                  mb={4}
                  style={{
                    textAlign: 'center',
                  }}
                  {...testID('Enter your registered email. We will send you a link to reset password')}
                >
                  Enter your registered email. We will send you a link to reset
                  password
                </Text>
              </VStack>
              <FormControl.Label>
                <Text {...testID(TestIdentifiers.email)} style={styles.labelStyle}>Email</Text>
              </FormControl.Label>
              <Input
                borderRadius={8}
                value={stateData.email}
                height={44}
                autoCapitalize="none"
                placeholder="Email"
                type="text"
                fontSize={16}
                {...testID(TestIdentifiers.forgotPasswordEmail)}
                onChangeText={(value) => {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      email: value,
                    };
                  });
                }}
              />
            </FormControl>
          </VStack>
          <Button
            w={'100%'}
            h={10}
            variant={'solid'}
            onPress={onSubmit}
            style={styles.submitElement}
            {...testID(TestIdentifiers.submitBtn)}
          >
            <DisplayText
              textLocalId="submit"
              size={'smMedium'}
              extraStyles={
                {color: 'white', fontWeight: '600', fontSize: 14} || ''
              }
            />
          </Button>
          <HStack
            space={2}
            mt={6}
            mb={!isWeb() ? 3 : 0}
            width={'100%'}
            justifyContent={'center'}
          >
            <Pressable
              // style={}
              {...testID(screen ? TestIdentifiers.backToLockScreen : TestIdentifiers.backToLogin)}
              onPress={() => {
                props.onLoginClick();
              }}
            >
              <HStack space={2} alignItems={'center'} justifyItems={'center'}>
                <AntIcon
                  name="arrowleft"
                  size={20}
                  style={{
                    color: '#825AC7',
                    fontSize: 20,
                    fontWeight: '600',
                    alignSelf: 'center',
                    lineHeight: 21,
                  }}
                />
                <Text
                  style={{
                    color: '#825AC7',
                    fontSize: 14,
                    fontWeight: '600',
                    alignSelf: 'center',
                    lineHeight: 21,
                  }}
                >
                  {screen ? 'Back to lock screen' : 'Back to Login'}
                </Text>
              </HStack>
            </Pressable>
          </HStack>
        </View>
      </View>
      </CustomScrollView>
    </View>
  );
};

export default ForgotPassword;
