import {PERSON_TYPES} from '../../../constants';

export const CONTACT_ACTION_CODES = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  MERGE_CONTACT: 'MERGE_CONTACT',
  CONVERT_TO_LEAD: 'CONVERT_TO_LEAD',
  CONVERT_TO_CUSTOMER: 'CONVERT_TO_CUSTOMER',
  ADD_RELATION: 'ADD_RELATION',
  SET_TAG: 'SET_TAG',
  CREATE_PATIENT: 'CREATE_PATIENT',
  DEACTIVATE: 'DEACTIVATE',
  ACTIVATE: 'ACTIVATE',
  SEND_MEMBERSHIP_LINK:'SEND_MEMBERSHIP_LINK',
  SEND_PROVIDER_REQUEST_FORM: 'SEND_PROVIDER_REQUEST_FORM'
};

export const QUICK_ACTION_LIST = [
  {
    displayString: 'edit',
    actionCode: CONTACT_ACTION_CODES.EDIT,
  },
  {
    displayString: 'delete',
    actionCode: CONTACT_ACTION_CODES.DELETE,
  },
  {
    displayString: 'Patients',
    actionCode: CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER,
  },
  {
    displayString: 'convertToLead',
    actionCode: CONTACT_ACTION_CODES.CONVERT_TO_LEAD,
  },

  {
    displayString: 'mergeContact',
    actionCode: CONTACT_ACTION_CODES.MERGE_CONTACT,
  },
  {
    displayString: 'addRelative',
    actionCode: CONTACT_ACTION_CODES.ADD_RELATION,
  },
  {
    displayString: 'setTag',
    actionCode: CONTACT_ACTION_CODES.SET_TAG,
  },
  {
    displayString: 'deactivate',
    actionCode: CONTACT_ACTION_CODES.DEACTIVATE,
  },
  {
    displayString: 'sendmembershiplink',
    actionCode: CONTACT_ACTION_CODES.SEND_MEMBERSHIP_LINK,
  },
];

export const DISPLAY_LIST_BY_PERSON_TYPE = {
  [PERSON_TYPES.LEAD]: [
    // CONTACT_ACTION_CODES.EDIT,
    // CONTACT_ACTION_CODES.DELETE,
    CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER,
    CONTACT_ACTION_CODES.ADD_RELATION,
    CONTACT_ACTION_CODES.SET_TAG,
    CONTACT_ACTION_CODES.EDIT,
    CONTACT_ACTION_CODES.SEND_MEMBERSHIP_LINK,

  ],
  [PERSON_TYPES.CUSTOMER]: [
    // CONTACT_ACTION_CODES.EDIT,
    // CONTACT_ACTION_CODES.DELETE,
    // CONTACT_ACTION_CODES.CONVERT_TO_LEAD,
    CONTACT_ACTION_CODES.ADD_RELATION,
    CONTACT_ACTION_CODES.SET_TAG,
    CONTACT_ACTION_CODES.DEACTIVATE,
    CONTACT_ACTION_CODES.EDIT,
    CONTACT_ACTION_CODES.SEND_MEMBERSHIP_LINK,
  ],
};
