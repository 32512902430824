import React, { useMemo } from 'react';
import { Path, Svg } from 'react-native-svg';
import { Colors } from '../../styles';

const DownArrowFilledSvg = React.memo(() => {
  const svgContent = useMemo(() => (
    <Svg width="6" height="6" viewBox="0 0 6 6" fill="none">
      <Path d="M3 4L5 2H1L3 4Z" fill="#6F7A90" stroke="#6F7A90" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  ), []);

  return svgContent;
});

export default DownArrowFilledSvg;
