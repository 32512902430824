import {
    Box,
    HStack,
    Pressable,
    View,
    VStack,
    Menu,
    Text as NativeText,
    Tooltip,
    Spinner,
    Checkbox,
} from 'native-base';
import { Popover, Typography } from 'antd';
import { DoubleTickIconSvg } from '../../../../assets/Icons/DoubleTickIconSvg';
import NotesSvg from '../../../../assets/Icons/NotesSvg';
import RocketSvg from '../../../../assets/Icons/RocketSvg';
import TagHorizontal from '../../../../assets/Icons/TagHorizontal';
import TrashBinIcon2024 from '../../../../assets/Icons/TrashBinIcon2024';
import UserHeartSvg from '../../../../assets/Icons/UserHeartSvg';
import UserAddSvg from '../../../../assets/svg/UserAddSvg';
import { Colors } from '../../../../styles';
import ClipBoardSvg from '../../Svg/ClipBoardSvg';
import ClockSvg from '../../Svg/ClockSvg';
import EditPenSvg from '../../Svg/EditPenSvg';
import NewScheduleCalenderSvg from '../../Svg/NewScheduleCalenderSvg';
import PatientEducationSvg from '../../Svg/SideCarSvg/PatientEducationSvg';
import TasksSvg from '../../Svg/SideMenu/TasksSvg';
import SirenSvg from '../../Svg/SirenSvg';
import ThreeDotIconSvg from '../../Svg/InterventionSvg/ThreeDotIconSvg';
import { ColumnType, PatientData } from './interfaces';
import { DayOptimizerViewAction } from './DayOptimizerConstants';
import AlphabetAvatar from '../../Avatar/AlphabetAvatar';
import PatientListInternalTask from '../../PatientListInternalTask/PatientListInternalTask';
import { patientDashboardStyles } from './PatientDashboard/PatientDashboardStyles';
import RAFModal from './PatientDashboard/PatientListing/RAFModal/RAFModal';
import ArrowDownSvg from '../../Svg/DayOptimization/ArrowDownSvg';
import ArrowUpSvg from '../../Svg/DayOptimization/ArrowUpSvg';
import { IContactScoreAudit } from '../../MemebersView/interface';
import './styles.css';
import { Moment } from 'moment';
import moment from 'moment';
const { Text } = Typography;

export let columnShuffleDropDownList = [
    { label: 'P', key: 'priority', priority: 1, defaultColumnPriority: 1, hidden: false, width: '60px', sortable: false, isDefault: true },
    { label: 'RAF', key: 'raf', priority: 2, defaultColumnPriority: 2, hidden: false, width: '120px', sortable: false, isDefault: true },
    { label: 'Alerts', key: 'alerts', priority: 3, defaultColumnPriority: 3, hidden: false, width: '84px', sortable: false, isDefault: true },
    { label: 'Reason for Prioritisation', key: 'reason', priority: 4, defaultColumnPriority: 4, hidden: false, width: '534px', sortable: false, isDefault: true },
    { label: 'Location/Office', key: 'location', priority: 5, defaultColumnPriority: 5, hidden: true, width: '200px', sortable: false, isDefault: false },
    { label: 'Client ID', key: 'clientId', priority: 6, defaultColumnPriority: 6, hidden: true, width: '200px', sortable: false, isDefault: false },
    { label: 'Fold ID', key: 'foldId', priority: 7, defaultColumnPriority: 7, hidden: true, width: '120px', sortable: false, isDefault: false },
    // { label: 'PCP', key: 'pcp', priority: 8, defaultColumnPriority: 8, hidden: true, width: '60px', sortable: false, isDefault: false },
];

export const updateColumnShuffleDropDownList = (newList: any) => {
    columnShuffleDropDownList = newList.map((item: any, index: number) => ({
        ...item,
        priority: item.isDefault ? item.priority : index + 1
    }));
};

export const resetDefaultColumns = () => {
    columnShuffleDropDownList = columnShuffleDropDownList.map(item => ({
        ...item,
        hidden: !item.isDefault,
        priority: item.defaultColumnPriority
    })).sort((a, b) => a.priority - b.priority);
};

export const getColumns = (
    rafFilter: string | null,
    fetchList: (rafFilter?: string | null) => void,
    isPatientViewVisible: boolean,
    onViewAction: (action: DayOptimizerViewAction, selectedPatient: PatientData) => void,
    isSmallScreen: boolean,
    updatePatientStatus: (dayOptimizerId: string, isChecked: boolean) => void,
    columnShuffleDropDownList: any[]
): ColumnType[] => {
    const getMenuItems = makeGetMenuItems();
    const columns: ColumnType[] = [];
    const visibleColumns = columnShuffleDropDownList
        .filter((column: any) => !column.hidden)
        .sort((a: any, b: any) => {
            return a.priority - b.priority;
        });

    columns.push({
        title: <View style={{ flex: 1, justifyContent: 'center' }}>
            <Checkbox value='all' colorScheme='purple' />
        </View>,
        dataIndex: 'select',
        width: '3%',
        fixed: 'left',
        render: (_text: any, record: any) =>
            <View style={{ flex: 1, justifyContent: 'center' }}>
                <Checkbox
                    value={record?.dayOptimizerId}
                    isChecked={record?.checked}
                    size='sm'
                    colorScheme='purple'
                    onChange={() => {
                        updatePatientStatus(record?.dayOptimizerId, record?.checked);
                        record.checked = !record.checked;
                    }}
                />
            </View>
    });

    columns.push({
        title: 'Name',
        dataIndex: 'name',
        width: '260px',
        sorter: false,
        fixed: 'left',
        render: (name: string, record: any) => (
            <HStack justifyContent={'left'} alignItems='center' space={2} minHeight={'80px'}>
                <AlphabetAvatar
                    size={8}
                    name={name}
                    style={{
                        alignItems: 'center',
                        borderRadius: 8,
                        BorderColor: '#FBCEB7',
                        borderWidth: 1,
                    }}
                    textStyle={{ fontSize: 12, fontWeight: 500 }}
                    disableTop
                    userType='PATIENT'
                />
                <VStack width={'100%'} alignItems={'left'}>
                    <Pressable onPress={() => {
                        onViewAction(DayOptimizerViewAction.REDIRECT_PATIENT_VIEW, record);
                    }}>
                        <NativeText
                            fontWeight={500}
                            fontSize={14}
                            maxW={isSmallScreen ? '110px' : '260px'}
                            color={Colors.FoldPixel.GRAY400}
                            numberOfLines={1}
                            ellipsizeMode='tail'
                        >
                            {name}
                        </NativeText>
                    </Pressable>
                    <NativeText color={Colors.FoldPixel.GRAY250} fontWeight={400} fontSize={14}>
                        {record.gender && record.age ?
                            `(${record.gender === 'female' ? 'F' : 'M'}, ${record.age})` :
                            record.gender ?
                                `(${record.gender === 'female' ? 'F' : 'M'})` :
                                record.age ?
                                    `(${record.age})` :
                                    ''}
                    </NativeText>
                </VStack>
            </HStack>
        ),
        onCell: (record: any) => ({
            onClick: () => {
                onViewAction(DayOptimizerViewAction.SHOW_PATIENT_DETAIL_VIEW, record);
            }
        })
    });

    visibleColumns.forEach(column => {
        switch (column.key) {
            case 'raf':
                columns.push({
                    title: column.label,
                    dataIndex: column.key,
                    width: column.width,
                    sorter: column.sortable,
                    onHeaderCell: () => ({
                        onClick: () => {
                            const newRafFilter = rafFilter === 'asc' ? 'desc' : rafFilter === 'desc' ? null : 'asc';
                            fetchList(newRafFilter);
                        },
                    }),
                    render: commonColumnRenders.RAF
                });
                break;
            case 'priority':
                columns.push({
                    title: column.label,
                    dataIndex: column.key,
                    width: column.width,
                    sorter: column.sortable,
                    render: (priority: number, record: any) => {
                        return (
                            <NativeText fontWeight={500} fontSize={14} color={Colors.FoldPixel.GRAY400}>
                                {priority || 0}
                            </NativeText>
                        );
                    },
                    onCell: (record: any) => ({
                        onClick: () => {
                            onViewAction(DayOptimizerViewAction.SHOW_PATIENT_DETAIL_VIEW, record);
                        }
                    })
                });
                break;
            case 'foldId':
                columns.push({
                    title: column.label,
                    dataIndex: column.key,
                    width: column.width,
                    sorter: column.sortable,
                    render: (patientId: string, record: any) => {
                        return (
                            <NativeText fontWeight={500} fontSize={14} color={Colors.FoldPixel.GRAY400}>
                                {patientId || record?.patientId}
                            </NativeText>
                        );
                    },
                    onCell: (record: any) => ({
                        onClick: () => {
                            onViewAction(DayOptimizerViewAction.SHOW_PATIENT_DETAIL_VIEW, record);
                        }
                    })
                });
                break;
            case 'location':
                columns.push({
                    title: column.label,
                    dataIndex: column.key,
                    width: column.width,
                    sorter: column.sortable,
                    render: (practiceLocation: string, record: any) => {
                        return (
                            <NativeText fontWeight={500} fontSize={14} color={Colors.FoldPixel.GRAY400}>
                                {practiceLocation || record?.practiceLocation}
                            </NativeText>
                        );
                    },
                    onCell: (record: any) => ({
                        onClick: () => {
                            onViewAction(DayOptimizerViewAction.SHOW_PATIENT_DETAIL_VIEW, record);
                        }
                    })
                });
                break;
            case 'pcp':
                columns.push({
                    title: column.label,
                    dataIndex: column.key,
                    width: column.width,
                    sorter: column.sortable,
                    render: (uuid: string, record: any) => (
                        <NativeText fontWeight={500} fontSize={14} color={Colors.FoldPixel.GRAY400}>
                            {uuid || record?.uuid}
                        </NativeText>
                    ),
                    onCell: (record: any) => ({
                        onClick: () => {
                            onViewAction(DayOptimizerViewAction.SHOW_PATIENT_DETAIL_VIEW, record);
                        }
                    })
                });
                break;
            case 'clientId':
                columns.push({
                    title: column.label,
                    dataIndex: column.key,
                    width: column.width,
                    sorter: column.sortable,
                    render: (clientId: string, record: any) => (
                        <NativeText fontWeight={500} fontSize={14} color={Colors.FoldPixel.GRAY400}>
                            { clientId || record?.clientId }
                        </NativeText>
                    ),
                    onCell: (record: any) => ({
                        onClick: () => {
                            onViewAction(DayOptimizerViewAction.SHOW_PATIENT_DETAIL_VIEW, record);
                        }
                    })
                });
                break;
            case 'alerts':
                columns.push({
                    title: column.label,
                    dataIndex: column.key,
                    width: column.width,
                    sorter: column.sortable ? (a: any, b: any) => a.alerts - b.alerts : undefined,
                    render: (alerts: number, record: any) => (
                        <HStack width={10} backgroundColor={Colors.FoldPixel.GRAY50} borderRadius={4} justifyContent={'center'} padding={1} alignItems='center' space={1}>
                            <PatientListInternalTask
                                contactId={record?.uuid}
                                onActionableClick={() => { }}
                                alertDisplayCategoryId={record?.alertDisplayCategoryId}
                                dayOptimizerView={true}
                            />
                        </HStack>
                    ),
                    onCell: (record: any) => ({
                        onClick: () => {
                            onViewAction(DayOptimizerViewAction.SHOW_PATIENT_DETAIL_VIEW, record);
                        }
                    })
                });
                break;
            case 'reason':
                columns.push({
                    title: column.label,
                    dataIndex: column.key,
                    width: column.width,
                    sorter: column.sortable,
                    render: (reasons: string[], record: PatientData) => (
                        <Box width='534px'>
                            <VStack>
                                {reasons?.slice(0, 3).map((reason, index) => (
                                    <NativeText
                                        key={index}
                                        fontSize={14}
                                        color={Colors.FoldPixel.GRAY250}
                                        width='534px'
                                        numberOfLines={1}
                                        ellipsizeMode='tail'
                                        isTruncated
                                    >
                                        • {reason}
                                    </NativeText>
                                ))}
                            </VStack>
                            {reasons.length > 3 && (
                                <NativeText
                                    fontSize={12}
                                    color={Colors.FoldPixel.PRIMARY300}
                                    width='100%'
                                >
                                    • {reasons.length - 3} more reason
                                </NativeText>
                            )}
                        </Box>
                    ),
                    onCell: (record: any) => ({
                        onClick: () => {
                            onViewAction(DayOptimizerViewAction.SHOW_PATIENT_DETAIL_VIEW, record);
                        }
                    })
                });
                break;
        }
    });
if (!isPatientViewVisible) {
    columns.push({
        title: 'Actions',
        dataIndex: 'actions',
        width: '10%',
        sorter: false,
        fixed: 'right',
        render: (actions: string, record: any) => (
            <HStack justifyContent={'space-between'} paddingX={1} alignItems='center' space={1}>
                <Box
                    backgroundColor={Colors.FoldPixel.PRIMARY100}
                    borderRadius={4}
                    padding={1}
                    flexDirection={'row'}
                    gap={1}
                    justifyContent={'center'}
                    width={'82px'}
                    alignItems={'center'}
                    height={'24px'}
                    borderColor={Colors.Custom.PrimaryColor200}
                >
                    <NativeText fontSize={12} fontWeight={500} color={Colors.FoldPixel.PRIMARY300}>{actions}</NativeText>
                </Box>
                <Popover
                    overlayClassName='threeDotsPopoverStyle'
                    placement={'bottom'}
                    trigger='click'
                    content={
                        getMenuItems(record.patientId).map((item) => (
                            <Menu.Item key={item.key} onPress={item.onPress}>
                                {item.label}
                            </Menu.Item>
                        ))
                    }
                >
                    <View style={patientDashboardStyles.patientActionView}>
                        <ThreeDotIconSvg width={15} style={{ padding: 0, margin: 0 }} />
                    </View>
                </Popover>
            </HStack>
        ),
        });
    }
    return columns;
};

const menuItems = [
    { key: '1', icon: <UserHeartSvg />, label: 'Add Relatives' },
    {
        key: '2',
        icon: <EditPenSvg width={15} height={15} />,
        label: 'Edit Patient',
    },
    { key: '3', icon: <TagHorizontal />, label: 'Set Tag' },
    { key: '4', icon: <ClockSvg width={15} />, label: 'Schedule Appointment' },
    { key: '5', icon: <ClipBoardSvg />, label: 'Send Assessment' },
    {
        key: '6',
        icon: <TasksSvg width={15} defaultColor={Colors.FoldPixel.GRAY300} />,
        label: 'Add Task',
    },
    { key: '7', icon: <RocketSvg />, label: 'Run Automation' },
    { key: '8', icon: <ClipBoardSvg />, label: 'Initiate Protocol' },
    { key: '9', icon: <NotesSvg />, label: 'Publish Note' },
    {
        key: '10',
        icon: <SirenSvg width={15} color={Colors.FoldPixel.GRAY300} />,
        label: 'Change Risk Level',
    },
    { key: '11', icon: <UserAddSvg />, label: 'Assign Care Manager' },
    { key: '12', icon: <PatientEducationSvg />, label: 'Send Education' },
    {
        key: '13',
        icon: (
            <NewScheduleCalenderSvg
                width={15}
                customColor={Colors.FoldPixel.GRAY300}
            />
        ),
        label: 'Add to Today',
    },
    { key: '14', icon: <DoubleTickIconSvg size={20} />, label: 'Mark As Complete' },
    {
        key: '15',
        icon: <TrashBinIcon2024 color={Colors.FoldPixel.STATUS_ERROR} />,
        label: 'Remove from List',
        color: Colors.FoldPixel.STATUS_ERROR,
    },
];

const makeGetMenuItems = () => {
    return (patientId: string) =>
        menuItems?.map((item) => ({
            key: item.key,
            label: (
                <HStack alignItems='center' space={2} width={'100%'}>
                    {item.icon}
                    <Text
                        style={{
                            color: item.color || Colors.FoldPixel.GRAY400,
                            fontSize: 12,
                        }}
                    >
                        {item.label}
                    </Text>
                </HStack>
            ),
            onPress: () => handleMenuClick(item.key, patientId),
        }));
};

const handleMenuClick = (key: string, patientId: string) => {
    console.log(`Action ${key} selected for patient ${patientId}`);
};

const commonColumnRenders = {
    RAF: (rafScore: string, record: any) => {
        let score = parseFloat(rafScore);
        if (isNaN(score)) {
            score = 0;
        }

        return (
            <RAFModal
                score={score}
                contactScore={record?.contactScore}
                contactScoreAudit={record?.contactScoreAudit}
                patientId={record?.patientId}
                practiceLocationId={record?.practiceLocationId}
            />
        );
    },
};

export const getPatientSearchListingColumns = (
    onActionPerformed: (action: string, record: any) => void
) => {
    return [
        {
            title: 'P',
            dataIndex: 'patientId',
            width: '6%',
            sorter: false,
            render: (patientId: string) => {
                return <NativeText fontWeight={500} fontSize={14} color={Colors.FoldPixel.GRAY400}>{patientId}</NativeText>;
            },
        },
        {
            title: 'RAF',
            dataIndex: 'rafScore',
            width: '10%',
            sorter: false,
            render: commonColumnRenders.RAF,
        },
        {
            title: 'Name',
            dataIndex: 'label',
            width: '75%',
            render: (name: string, record: any) => {
                return (
                    <HStack justifyContent={'left'} alignItems='center' space={2}>
                        <AlphabetAvatar
                            size={8}
                            name={name}
                            style={{
                                alignItems: 'center',
                                borderRadius: 8,
                                BorderColor: '#FBCEB7',
                                borderWidth: 1,
                            }}
                            textStyle={{ fontSize: 12, fontWeight: 500 }}
                            disableTop
                            userType='PATIENT'
                        />
                        <VStack width={'100%'} alignItems={'left'}>
                            <NativeText
                                fontWeight={500}
                                fontSize={14}
                                color={Colors.FoldPixel.GRAY400}
                            >
                                {name?.length > 20 ? `${name.substring(0, 20)}...` : name}
                            </NativeText>
                            <NativeText color={Colors.FoldPixel.GRAY250} fontWeight={400} fontSize={14}>
                                ({record.gender === 'female' ? 'F' : 'M'}, {record?.age}Y)
                            </NativeText>
                        </VStack>
                    </HStack>
                )
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            width: '9%',
            render: (actions: string, record: any) => (
                <HStack justifyContent={'space-between'} paddingX={1} alignItems='center' space={1}>
                    {record.isScheduled ? (
                        <Tooltip label="Already in Day Optimizer for today's date">
                            <Pressable
                                disabled={true}
                                style={{
                                    borderRadius: 4,
                                    borderColor: Colors.FoldPixel.GRAY250,
                                    borderWidth: 0.5,
                                    margin: 0,
                                    paddingHorizontal: '19px',
                                    paddingVertical: '5px',
                                    opacity: 0.5,
                                }}
                            >
                                <NativeText style={{
                                    color: Colors.FoldPixel.GRAY300,
                                    fontSize: 12,
                                    fontWeight: '500',
                                }}>Add</NativeText>
                            </Pressable>
                        </Tooltip>
                    ) : (
                        <Pressable
                            onPress={() => {
                                onActionPerformed('add', record)
                            }}
                            style={{
                                borderRadius: 4,
                                borderColor: Colors.FoldPixel.GRAY250,
                                borderWidth: 0.5,
                                margin: 0,
                                paddingHorizontal: '19px',
                                paddingVertical: '5px',
                            }}
                        >
                            <NativeText style={{
                                color: Colors.FoldPixel.GRAY300,
                                fontSize: 12,
                                fontWeight: '500',
                            }}>Add</NativeText>
                        </Pressable>
                    )}
                </HStack>
            ),
        },
    ]
}

export const getDateLabel = (date: Date): string => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    let label = '';
    if (date.toDateString() === today.toDateString()) {
        label = 'Today';
    } else if (date.toDateString() === yesterday.toDateString()) {
        label = 'Yesterday';
    } else if (date.toDateString() === tomorrow.toDateString()) {
        label = 'Tomorrow';
    } else {
        label = date.toLocaleDateString('en-US', { weekday: 'long' });
    }

    const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return label ? `${label}, ${formattedDate}` : formattedDate;
};

export const changeDate = (currentDate: Date, increment: number): Date => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + increment);
    return newDate;
};

export const disableCalendarDates = () => (current: Moment) => {
    const currentDate = current;
    const today = moment();

    const pastLimit = moment(today).subtract(1, 'months').subtract(1, 'days');
    const futureLimit = moment(today).add(3, 'days');

    return currentDate.isBefore(pastLimit) || currentDate.isAfter(futureLimit);
};

export const InfiniteScrollLoader = () => (
    <HStack space={2} justifyContent='center' alignItems='center' py={4}>
        <Spinner size='sm' color={Colors.Custom.Primary300} />
        <NativeText
            fontSize={12}
            fontWeight={400}
            color={Colors.Custom.Primary300}
            textAlign='center'
        >
            Loading...
        </NativeText>
    </HStack>
);

export const formatDateForApi = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const getRafConditionalColors = (
    rafScoreCondition: number
): { foreGround: string; background: string } => {
    if (rafScoreCondition <= 0.5) {
        return {
            foreGround: Colors.Custom.SuccessColor,
            background: Colors.FoldPixel.STATUS_LIGHT_SUCCESS,
        };
    }
    if (rafScoreCondition > 0.5 && rafScoreCondition <= 1) {
        return {
            foreGround: Colors.FoldPixel.STATUS_IN_PROGRESS,
            background: Colors.Custom.PendingTaskBackgroundColor,
        };
    }
    if (rafScoreCondition > 1) {
        return {
            foreGround: Colors.FoldPixel.STATUS_ERROR,
            background: Colors.Custom.Red100,
        };
    }

    return {
        foreGround: Colors.FoldPixel.STATUS_ERROR,
        background: Colors.Custom.Red100,
    };
};

export const getArrowSvg = (props: {
    rafScoreCondition: number;
    color?: string;
}) => {
    if (props.rafScoreCondition === 0) return null;

    if (props.rafScoreCondition > 0) {
        return (
            <ArrowUpSvg customStrokeColor={props?.color} width={12} height={12} />
        );
    } else {
        return (
            <ArrowDownSvg customStrokeColor={props?.color} width={12} height={12} />
        );
    }
};

export const getCalculatedRafScoreCondition = (
    contactScoreAudit: IContactScoreAudit[],
    score: number
) => {
    const contactScoreAuditList = contactScoreAudit?.filter?.(
        (score: IContactScoreAudit) => {
            return score?.scoreType === 'HCC_V_28';
        }
    );

    if (contactScoreAuditList) {
        if (contactScoreAuditList?.length < 2) {
            return 0;
        }
        const diff =
            contactScoreAuditList?.[0]?.scoreValue -
            contactScoreAuditList?.[1]?.scoreValue;
        return diff;
    }

    return 0;
};
