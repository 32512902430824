import { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import LocalStorage from '../../utils/LocalStorage';

const useAxiosQuery = <T>(url: string, queryParams?: object) => {
  const [data, setData] = useState<AxiosResponse<T> | any>(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(
    async (queryParams?: object, signal?: AbortSignal) => {
      setLoading(true);
      try {
        const userDataString = await LocalStorage.getItem('user');
        const userData = JSON.parse(userDataString);
        axios.defaults.headers = {
          'access-token': userData?.access_token || '',
          fold_access_token: userData?.fold_access_token || '',
          accountUUID: userData?.account_id || '',
        } as any;
        const response = await axios.get(url, {
          params: queryParams,
          signal: signal,
        });
        setData(response.data);
        setLoading(false);
        return response.data;
      } catch (e: any) {
        if (e.name === 'AbortError') {
          console.log('Request was aborted');
        } else {
          setError(true);
          setErrorMessage(e.message);
          setLoading(false);
        }
      }
    },
    [url]
  );

  useEffect(() => {
    const abortController = new AbortController();

    fetch(queryParams, abortController.signal);

    return function () {
      abortController.abort();
    };
  }, [url, JSON.stringify(queryParams), fetch]);

  return {
    data,
    loading,
    error,
    errorMessage,
    refetch: fetch as (queryParams?: object) => Promise<AxiosResponse>,
  };
};

export default useAxiosQuery;
