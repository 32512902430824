import React from 'react';
import {ICommonSvgProps} from './interfaces';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';
import { StyleSheet } from 'react-native';

export const ArchivedSvg = (props: ICommonSvgProps) => {
  const {defaultColor} = props;
  const strokeColor = defaultColor ? defaultColor : '#4F2D90';
  return (
    <Svg
      style={styles.svg}
      fill="none"
      viewBox="0 0 20 20"
    >
      <G
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#clip0_7543_106810)"
      >
        <Path
          strokeWidth="2"
          d="M17.5 6.667V17.5h-15V6.667M.833 2.5h18.334v4.167H.833V2.5z"
        />
        <Path
          strokeWidth="1.5"
          d="M10 9.167v5m0 0l2.5-2.5m-2.5 2.5l-2.5-2.5"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_7543_106810">
          <Path fill="#fff" d="M0 0H20V20H0z"></Path>
        </ClipPath>
      </Defs>
    </Svg>
  );
};

const styles = StyleSheet.create({
  svg: {
    width: 'inherit',
    height: 'inherit'
  }
})