import {Icon} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {Colors} from '../../../../styles';
import FilterByDueDate from '../../CommonFilters/FilterByDueDate';
import FilterByTaskPriority from '../../CommonFilters/FilterByTaskPriority';
import FilterByTaskStatus from '../../CommonFilters/FilterByTaskStatus';
import SortByTaskSortType from '../../CommonFilters/SortByTaskSortType';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {Tooltip} from 'antd';
import { BUTTON_TYPE } from '../../../../constants';
import SortTaskIcon from '../../../../assets/Icons/SortTask';
import { TASK_TABS } from '../CareDashboardConstants';

export const getTaskListTopBarButtons = (
  onActionPerformed: (actionCode: any, rawData: any) => void,
  sortType: string,
  selectedSortValueAscending: boolean,
  selectedTab?: string,
  isDisabled?: boolean
): any => {
  return {
    buttonList: [
      // {
      //   id: 1,
      //   btnText: 'filterByStatus',
      //   subTitle: 'Prospect',
      //   size: 'sm',
      //   colorScheme: 'primary',
      //   textColor: Colors.primary['500'],
      //   variant: '',
      //   isTransBtn: true,
      //   leftIcon: (
      //     <Icon
      //       color={Colors.primary['500']}
      //       as={AntIcon}
      //       name={'user'}
      //       size="4"
      //     />
      //   ),
      //   content: (
      //     <FilterByTaskStatus
      //       selectedStatusType={statusType}
      //       returnFilterItems={(actionCode: string, rawData: any) => {
      //         onActionPerformed(actionCode, rawData);
      //       }}
      //     />
      //   ),
      //   btnClick: () => {
      //   },
      // },
      // {
      //   id: 2,
      //   btnText: 'filterByPriority',
      //   subTitle: 'Prospect',
      //   size: 'sm',
      //   colorScheme: 'primary',
      //   textColor: Colors.primary['500'],
      //   variant: '',
      //   isTransBtn: true,
      //   leftIcon: (
      //     <Icon
      //       color={Colors.primary['500']}
      //       as={AntIcon}
      //       name={'user'}
      //       size="4"
      //     />
      //   ),
      //   content: (
      //     <FilterByTaskPriority
      //       selectedPriorityType={priorityType}
      //       returnFilterItems={(actionCode: string, rawData: any) => {
      //         onActionPerformed(actionCode, rawData);
      //       }}
      //     />
      //   ),
      //   btnClick: () => {
      //   },
      // },
      {
        id: 3,
        btnText: '',
        subTitle: 'Prospect',
        size: 'sm',
        backgroundColor: Colors.secondary['100'],
        textColor: Colors.primary['500'],
        variant: BUTTON_TYPE.NO_BORDER+' '+ BUTTON_TYPE.HOVERABLE_BUTTON,
        isTransBtn: true,
        isDisabled: isDisabled,
        leftIcon: (
          <Tooltip title="Sort">
            <Icon
              color={"#825AC7"}
              as={<SortTaskIcon color={isDisabled ? Colors.Custom.Gray300 : sortType !== 'None' ? '#825AC7' : ''}/>}
              name={'sort'}
              size="4"
            />
          </Tooltip>
        ),
        content: (
          <SortByTaskSortType
            selectedTab={selectedTab}
            selectedSortType={sortType}
            returnFilterItems={(actionCode: string, rawData: any) => {
              onActionPerformed(actionCode, rawData);
            }}
            selectedSortValueAscending={selectedSortValueAscending}
          />
        ),
      },
      // {
      //   id: 4,
      //   btnText: 'filterByDueDate',
      //   subTitle: 'Prospect',
      //   size: 'sm',
      //   colorScheme: 'primary',
      //   textColor: Colors.primary['500'],
      //   variant: '',
      //   isTransBtn: true,
      //   leftIcon: (
      //     <Icon
      //       color={Colors.primary['500']}
      //       as={AntIcon}
      //       name={'user'}
      //       size="4"
      //     />
      //   ),
      //   content: (
      //     <FilterByDueDate
      //       selectedDueDateType={dueDateType}
      //       returnFilterItems={(actionCode: string, rawData: any) => {
      //         onActionPerformed(actionCode, rawData);
      //       }}
      //     />
      //   ),
      //   btnClick: () => {
      //   },
      // },
      // statusType || priorityType || sortType || dueDateType
      //   ? {
      //       id: 4,
      //       btnText: 'reset',
      //       subTitle: 'Prospect',
      //       size: 'sm',
      //       colorScheme: 'primary',
      //       textColor: Colors.primary['500'],
      //       variant: '',
      //       isTransBtn: true,
      //       btnClick: () => {
      //         onActionPerformed(COMMON_ACTION_CODES.RESET, '');
      //       },
      //     }
      //   : null,
    ],
  };
};
