import { IconButton, View } from "native-base";
import { Colors } from "../../../../../styles";
import AntIcon from 'react-native-vector-icons/AntDesign';
import Icon, { CloseOutlined } from "@ant-design/icons";



export const addCodeGroupTableColumns = (handleDelete: (record: any) => any, hideCode?: boolean, hideActions?: boolean): any[] => {
  const columns: any = [
    {
      title: "Name",
      dataIndex: "display",
    },
  ];

  if (!hideCode) {
    columns.unshift({
      title: "Code",
      dataIndex: "codeToDisplay",
    });
  }

  if (!hideActions) {
    columns.push(
      {
        title: "Actions",
        dataIndex: "actions",
        render: (display: string, selectedRecord: any): JSX.Element => {
          return (

            <View flex={1} justifyContent={'center'} flexDirection={'row'}>      
            <IconButton
              icon={<CloseOutlined/>}
              onPress={() => handleDelete(selectedRecord)} />
          </View>
          );
        },
      })
  }

  return columns;
};