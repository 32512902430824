import {Svg, Path} from 'react-native-svg';
export const BlockSlotIcon = (props:{
  color?: string;
}) => {
  return (
    <Svg width="13" height="13" viewBox="0 0 13 13" fill="none">
      <Path
        d="M10.3885 2.6115L2.6115 10.3885M6.5 12C3.46243 12 0.999999 9.53757 1 6.5C1 3.46243 3.46243 1 6.5 1C9.53757 1 12 3.46243 12 6.5C12 9.53757 9.53757 12 6.5 12Z"
        stroke={props.color || "#9E77ED"}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};
