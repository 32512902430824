import {HStack, Pressable, Text, useMediaQuery, View} from 'native-base';
import {useEffect, useState} from 'react';
import {IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../constants';
import {Colors} from '../../../../styles';
import BoardIconSvg from '../../Svg/BoardIconSvg';
import ListIconSvg from '../../Svg/ListIconSvg';
import {styles} from './CustomSwitchListBoardStyle';

const CustomSwitchListBoard = (props: {
  isTableView?: boolean;
  onValueChange?: (value: boolean) => void;
  restStyles?:any;
  isDisabled?: boolean;
}) => {
  const [stateData, setStateData] = useState({
    selectedBtn: props.isTableView ? 'list' : 'board',
  });

  useEffect(()=>{
    setStateData((prev)=>{
      return {
        ...prev,
        selectedBtn: props.isTableView ? 'list' : 'board',
      }
    })
  },[props.isTableView])

  const {restStyles} = props;
  const btnTextArray = [
    {
      text: 'List',
      Code: 'list',
    },
    {
      text: 'Board',
      Code: 'board',
    },
  ];

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  return (
    <View
      style={[styles.container, restStyles]}
      backgroundColor={'#fff'}
    >
      {btnTextArray.map((btn) => {
        return (
          <Pressable
            key={btn.Code}
            isDisabled={props.isDisabled}
            onPress={() => {
              setStateData((prev) => {
                return {
                  ...prev,
                  selectedBtn: btn.Code,
                };
              });
              if (props.onValueChange && typeof props.onValueChange === 'function') {
                props.onValueChange(btn.Code === 'list');
              }
            }}
          >
            <View
              style={[
                styles.swtichBtnStyle,
                stateData.selectedBtn === btn.Code
                  ? {backgroundColor: Colors.primary['300']}
                  : {},
              ]}
            >
              <HStack space={2}>
                {btn.Code === 'board' && (
                  <BoardIconSvg
                    isEnabled={stateData.selectedBtn === btn.Code}
                  />
                )}

                {btn.Code === 'list' && (
                  <View marginTop={1}>
                    <ListIconSvg
                      isEnabled={stateData.selectedBtn === btn.Code}
                    />
                  </View>
                )}

                {!isSmallScreen && false && (
                  <Text
                    size={'xsBold'}
                    color={
                      stateData.selectedBtn === btn.Code
                        ? '#fff'
                        : Colors.Custom.Gray900
                    }
                  >
                    {btn.text}
                  </Text>
                )}
              </HStack>
            </View>
          </Pressable>
        );
      })}
    </View>
  );
};
export default CustomSwitchListBoard;
