import {
  Extension,
  MedicationRequest,
  RequestGroup,
} from 'fhir/r4';
import {Box, Divider, HStack, Spacer, Text, VStack, Pressable} from 'native-base';
import React, { useState } from 'react';
import {useIntl} from 'react-intl';
import { addOrUpdateOrder } from '../../../../../../../services/CommonService/OrderService';
import {Colors} from '../../../../../../../styles';
import { getMomentObj } from '../../../../../../../utils/DateUtils';
import {IReportAndResourceMap} from '../../../../../LeftContainer/RecentActivity/OrdersInterface';
import IndividualOrderView from '../../../../Orders/OrdersAndReports/IndividualOrderView/IndividualOrderView';
import { OrderType } from '../../../../Orders/OrdersAndReports/OrderConstants';
import { getFacilityName, getMedicationOrderName, getMedicationSubTitle, getOrderTypeFromOrder, updateOrderStatus } from '../../../../Orders/OrdersAndReports/OrderUtils';
import { renderOrderSubtitle } from '../../../../Orders/OrdersAndReports/OrderViewHelper';
import {EXTENSION_URLS} from '../../../../PatientNotes/components/AddOrUpdateTemplate/constant';
import LabIcon from '../../../assets/LabIcon';
import MedIcon from '../../../assets/MedIcon';
import RadIcon from '../../../assets/RadIcon';
import {ITimelineData} from '../../../interfaces';

interface IRequestGroup extends RequestGroup {
  action?: {
    resource?: {
      display: string;
      report?: any;
    };
  }[];
}

const getRequestGroupIcon = (extension: Extension[]) => {
  let type = '';
  extension.forEach((item: Extension) => {
    if (item.url === EXTENSION_URLS.requestGroupCategory || item.url === EXTENSION_URLS.orderCategory) {
      type = item?.valueString || '';
    }
  });
  switch (type) {
    case 'LAB':
      return <LabIcon />;
    case 'RADIOLOGY':
      return <RadIcon />;
    default:
      return <LabIcon />;
  }
};

const OrderCard = (props: {
  group: ITimelineData;
  handleNoteSelect: (note: any) => void;
  renderCardActions: (params: {
    report?: IReportAndResourceMap;
    key?: string;
    order?: RequestGroup;
    action?: any;
    prescription?: MedicationRequest;
    orderType: OrderType;
    noteId?: string;
  }) => JSX.Element,
  personData?: any;
}) => {
  const {group, personData, renderCardActions} = props;
  const orders = group.orders;
  const intl = useIntl();
  const [statusLoadingOrderId, setStatusLoadingOrderId] = useState('');

  const handleStatusChange = async (status: string, order: RequestGroup) => {
    setStatusLoadingOrderId(order?.id || '');
    await updateOrderStatus(status, order);
    setStatusLoadingOrderId('');
  };

  const renderRequestGroupView = (order: IRequestGroup) => {
    const report = order?.action?.[0]?.resource?.report;
    const key = `${order.id}`
    return (
      <HStack
        px={4}
        justifyContent="space-between"
        my={2}
        // key={index}
        alignItems="baseline"
      >
        <HStack alignItems="flex-start" flex={9}>
          <Box mr={4} display="block" marginTop={2}>
            {getRequestGroupIcon(order?.extension || [])}
          </Box>
          <VStack mb={0.5} flex={1} pr={1.5}>
            <HStack>
              <Text color={Colors.Custom.Gray900} fontWeight={500} fontSize={14}>{getFacilityName(order) || 'No facility selected'}</Text>
              <HStack
                alignItems="center"
                flex={1}
                justifyContent="flex-end"
                space={3}
              >
                {renderCardActions({
                  report: report,
                  key: key,
                  order: order,
                  orderType: getOrderTypeFromOrder(order),
                  noteId: group.noteId,
                })}
              </HStack>
            </HStack>
            <Divider marginY={2} />
            <VStack space={2}>
              {order.action?.map((action, index) => {
                return <IndividualOrderView
                  key={index}
                  order={order}
                  action={action}
                  report={action.resource?.report}
                  personData={personData}
                />
              })}
            </VStack>
            {(order.action || [])?.length > 0 && <Divider marginY={2} />}
            {renderOrderSubtitle(order, statusLoadingOrderId === order.id, (status) => handleStatusChange(status, order))}
          </VStack>
        </HStack>
      </HStack>
    );
  };

  const renderMedicationRequestView = (order: MedicationRequest) => {
    return (
      <HStack justifyContent="space-between" alignItems={'baseline'} my={2} px={4}>
        <HStack alignItems="flex-start" flex={9}>
          <Box mr={5} display="block" marginTop={2}>
            <MedIcon />
          </Box>
          <VStack mb={0.5} flex={1} pr={1.5}>
            <Text my={1} color={Colors.Custom.Gray900} fontWeight={400} fontSize={14}>
              {getMedicationOrderName(order)}
            </Text>
            <Text color={Colors.Custom.Gray500} fontSize="14">
              {getMedicationSubTitle(order, true)}
            </Text>
          </VStack>
        </HStack>
        <HStack
          alignItems="center"
          flex={1}
          justifyContent="flex-end"
          space={3}
        >
          {renderCardActions({
            prescription: order,
            orderType: OrderType.MED,
            noteId: group.noteId,
          })}
        </HStack>
      </HStack>
    );
  };

  return (
    <VStack
      backgroundColor={Colors.Custom.Gray50}
      borderRadius={8}
      borderColor={Colors.Custom.Gray100}
      borderWidth={0.5}

      py={1}
      flex={1}
      key={group.noteId}
      my={1}
    >
      {group.noteId && ( // if noteId is present
        <VStack pt={2}>
        <HStack alignItems={'center'} px={4}>
          {!!group.formName && <Text color={Colors.Custom.Gray500}>{group.formName}</Text>}
          <Spacer />
          <Pressable onPress={() => {
            props.handleNoteSelect(group.noteData)
          }}>
            <Text fontSize={14} fontWeight={400} color="#6941C7">
              {intl.formatMessage({id: 'viewNote'})}
            </Text>
          </Pressable>
        </HStack>
        <Divider my={2} />
        </VStack>
      )}
      {orders.map((order, index) => {
        const isRequestGroup = order?.resourceType === 'RequestGroup';
        const isMedicationRequest = order?.resourceType === 'MedicationRequest';
        return (
          <>
            {isRequestGroup && renderRequestGroupView(order as IRequestGroup)}
            {isMedicationRequest && renderMedicationRequestView(order as MedicationRequest)}
            {index !== orders?.length - 1 && <Divider />}
          </>
        );
      })}
    </VStack>
  );
};

export default OrderCard;
