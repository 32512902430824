import {TASK_STATUS_CODES} from '../../../../../constants/MlovConst';
import {getFormattedDateStringInTimeZone} from '../../../../../utils/DateUtils';
import {capitalizeText} from '../../../ContactRelationView/ContactRelationUtils';
import {
  IOnTimeVsDueDateTask,
  IRowsByPriority,
  ITaskCompletedLineChart,
  ITaskCountResult,
  ITaskDistributionTable,
  ITaskDistributionWidget,
  ITaskOverViewWidgetData,
  RESOURCE_LIST,
} from '../interface';

export const PRIORITY_LIST = [
  {
    code: 'asap',
    text: 'High',
  },
  {
    code: 'urgent',
    text: 'Medium',
  },
  {
    code: 'routine',
    text: 'Low',
  },
];

export const priority_task_status = [
  'missed',
  'completed',
  'accepted'
]

export const DRILL_DOWN_ENABLE_WIDGET = [
  'TASK_ANALYTICS_TASK_DISTRIBUTION_BY_ASSIGNEE',
  'TASK_ANALYTICS_AVERAGE_TIME_TO_RESOLUTION_BY_ASSIGNEE',
  'TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_ASSIGNEE',
  'TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_TASK_POOL'

]

export const getFormattedStatusListByPriority = (
  widgetData: ITaskOverViewWidgetData,
  priorityCode: string
) => {
  const result: {
    name: string;
    count: number;
  }[] = [];
  const statusListByPriority = widgetData.taskCountResult?.filter(
    (resultObj) => {
      return resultObj.priorityCode == priorityCode;
    }
  );

  priority_task_status.forEach((status: string) => {
    const getStatusObj: ITaskCountResult | undefined =
      statusListByPriority?.find((item) => {
        return item?.statusCode === status;
      });
    const statusObj: {
      name: string;
      count: number;
    } = {
      name: status === TASK_STATUS_CODES.ACCEPTED
          ? 'pending'
          : status || '',
      count: getStatusObj?.count || 0,
    };
    result.push(statusObj);
  });
  return result?.sort((firstEle: any, secondEle: any) => {
    return secondEle?.name.localeCompare(firstEle?.name);
  });
};

export const getFormattedDataForColumnChart = (
  rowsByPriority: IRowsByPriority
) => {
  const result = [];
  const data: any = rowsByPriority;

  const taskPriorityOrder: Record<string, number> = {
    high: 3,
    medium: 2,
    low: 1,
  };

  const sortedKeys = data && Object.keys(data).sort((a, b) => {
    if (
      a === 'totalResolutionTimeInSecondsAverage' ||
      b === 'totalResolutionTimeInSecondsAverage'
    ) {
      return -1;
    } else {
      const priorityA = a.replace('PriorityResolutionTimeInSecondsAverage', '');
      const priorityB = b.replace('PriorityResolutionTimeInSecondsAverage', '');
      return taskPriorityOrder[priorityB] - taskPriorityOrder[priorityA];
    }
  });

  if(sortedKeys) {
    for (const key of sortedKeys) {
      if (key !== 'totalResolutionTimeInSecondsAverage') {
        const priority = key.replace(
          'PriorityResolutionTimeInSecondsAverage',
          ''
        );
        result.push({
          name: capitalizeText(priority),
          Time: data[key] ? parseInt((data[key] / 3600).toFixed(0)) : 0,
        });
      }
    }
  }
  return result;
};

export const secondsToHhMm = (seconds: number) => {
  if (isNaN(seconds)) {
    return '--';
  }
  const hours = seconds / 3600;
  const minutes = (hours % 1) * 60;
  let hoursStr = `${Math.floor(hours)}`;
  if (hoursStr?.length === 1) {
    hoursStr = `0${hoursStr}`;
  }
  let minutesStr = `${Math.floor(minutes)}`;
  if (minutesStr?.length === 1) {
    minutesStr = `0${minutesStr}`;
  }
  return `${hoursStr}:${minutesStr} hr`;
};

export const secondsToHM = (seconds: number)=> {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return hours + 'h ' + minutes + 'm';
}

export const getCountAndPercentageOfTask = (
  widgetData: IOnTimeVsDueDateTask
) => {
  const totalTaskCount = widgetData?.rowsByPriority?.totalCompletedTasksCount;
  const afterDueDateTaskCount =
    widgetData?.rowsByPriority?.afterDueDateCompletedTasksCount;
  const onTimeTaskCount = totalTaskCount - afterDueDateTaskCount;
  const afterDueDateTaskPercentage = (
    (afterDueDateTaskCount / totalTaskCount) *
    100
  ).toFixed(1);
  const onTimeTaskPercentage = (
    (onTimeTaskCount / totalTaskCount) *
    100
  ).toFixed(1);
  return {
    totalTaskCount,
    onTime: {
      onTimeTaskCount,
      onTimeTaskPercentage,
    },
    afterDueDate: {
      afterDueDateTaskCount,
      afterDueDateTaskPercentage,
    },
  };
};

export const getCompletedAndAfterDueTaskGraph = (
  widgetData: IOnTimeVsDueDateTask
) => {

  // {...widgetData.rowsByPriority};
  const lowCompletionTimeDelayAverage = parseInt(((widgetData?.rowsByPriority?.lowCompletionTimeDelayInSecondsAverage)/3600).toFixed(0))
  const mediumCompletionTimeDelayAverage = parseInt((widgetData?.rowsByPriority?.mediumCompletionTimeDelayInSecondsAverage/3600).toFixed(0))
  const highCompletionTimeDelayAverage = parseInt((widgetData?.rowsByPriority?.highCompletionTimeDelayInSecondsAverage/3600).toFixed(0))
  const lowAfterDueDatePercentage = ((widgetData?.rowsByPriority?.totalLowAfterDueDateCompletedTasksCount/widgetData?.rowsByPriority?.totalLowCompletedTasksCount) * 100)
  const lowOnTimeDatePercentage = (100 - lowAfterDueDatePercentage)
  const lowAfterDueDate = parseInt(((lowCompletionTimeDelayAverage * lowAfterDueDatePercentage)/100).toFixed(0))
  const lowOnTimeDate = parseInt(((lowCompletionTimeDelayAverage * lowOnTimeDatePercentage)/100).toFixed(0))
  const mediumAfterDueDatePercentage =  ((widgetData?.rowsByPriority?.totalMediumAfterDueDateCompletedTasksCount/widgetData?.rowsByPriority?.totalMediumCompletedTasksCount) * 100)
  const mediumOnTimeDatePercentage = (100 - mediumAfterDueDatePercentage)
  const mediumAfterDueDate = parseInt(((mediumAfterDueDatePercentage *  mediumCompletionTimeDelayAverage)/100).toFixed(0))
  const mediumOnTimeDate = parseInt(((mediumOnTimeDatePercentage *  mediumCompletionTimeDelayAverage)/100).toFixed(0))
  const highAfterDueDatePercentage = ((widgetData?.rowsByPriority?.totalHighAfterDueDateCompletedTasksCount/widgetData?.rowsByPriority?.totalHighCompletedTasksCount) * 100)
  const highOnTimeDatePercentage = (100 - highAfterDueDatePercentage)
  const highAfterDueDate = parseInt(((highAfterDueDatePercentage * highCompletionTimeDelayAverage)/100).toFixed(0))
  const highOnTimeDate = parseInt(((highOnTimeDatePercentage * highCompletionTimeDelayAverage)/100).toFixed(0))
  const result = [
    {
      name:  'High',
      type: 'on-time',
      value: highOnTimeDate
    },
    {
      name:  'High',
      type: 'after-due',
      value: highAfterDueDate
    },
    {
      name:  'Medium',
      type: 'on-time',
      value: mediumOnTimeDate
    },
    {
      name:  'Medium',
      type: 'after-due',
      value: mediumAfterDueDate
    },
    {
      name:  'Low',
      type: 'on-time',
      value: lowOnTimeDate
    },
    {
      name:  'Low',
      type: 'after-due',
      value: lowAfterDueDate
    },
  ]

  return result
};


export const getTaskCompletedLineChart = (widgetData: ITaskCompletedLineChart) => {
  const extendedData =widgetData?.rows?.length  ? widgetData?.rows?.flatMap(item => {
    return [
        { ...item, name: "Task Added", value: item.totalTasksCount },
        { ...item, name: "Completed", value: item.completedTasksCount}
    ];
}) : [];
return extendedData
}

export const getFormattedDataForTable = (widgetData: ITaskDistributionWidget) => {
  const formattedData: ITaskDistributionTable[] = widgetData?.rows?.map((item)=> {
    const name = getAssigneeName(item);
    return {
      id: item?.assigneeId || item?.userPoolId || '',
      name: name,
      taskCompletedPercentage: item.completionRate ? item.completionRate?.toFixed(2) : '0',
      totalTask: item.totalTasksCount,
      pendingTask: item.pendingTasksCount,
      missedTask: item.missedTasksCount,
      completedTask: item.completedTasksCount,
      completedTaskTime: parseInt((item?.completionTimeDelayInSecondsAverage || 0).toFixed()),
      afterDueDateCompletedTask: item.afterDueDateCompletedTasksCount,
      onTimeCompletedTask: item?.completedTasksCount -  item.afterDueDateCompletedTasksCount,
    }
  })
  return formattedData
}

export const getYAxisMaxValue = (data: any[], resourceCode: string) => {
  let yAxisMaxValue = 0;
  let maxValue = 0;
  let groupedData: Record<string, number> = {};
  const returnArray: any = [];
  if (
    resourceCode ===
      RESOURCE_LIST.TASK_ANALYTICS_AVERAGE_TIME_TO_RESOLUTION_BY_ASSIGNEE ||
    resourceCode ===
      RESOURCE_LIST.TASK_ANALYTICS_AVERAGE_TIME_TO_RESOLUTION_BY_TASK_POOL
  ) {
    data?.forEach((singleDataObj: any) => {
      const value = singleDataObj?.Time;
      maxValue = maxValue < value ? value : maxValue;
    });
    yAxisMaxValue = Math.round(maxValue * 1.25);
  } else if (
    resourceCode ===
      RESOURCE_LIST.TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_ASSIGNEE ||
    resourceCode ===
      RESOURCE_LIST.TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_TASK_POOL
  ) {
    groupedData = data.reduce((acc, obj) => {
      const {name, value} = obj;
      if (acc[name]) {
        acc[name] += value;
      } else {
        acc[name] = value
      }
      return acc;
    }, {});
    maxValue = Math.max(...Object.values(groupedData));
    yAxisMaxValue = Math.round(maxValue * 1.25);
  } else if (resourceCode === RESOURCE_LIST.TASK_ANALYTICS_TASK_COMPLETED_BY_MONTH) {
    groupedData = data.reduce((acc, obj) => {
      const { month, value } = obj;
      if (acc[month]) {
        acc[month] += value;
      } else {
        acc[month] = value
      }
      return acc;
    }, {});
    maxValue = Math.max(...Object.values(groupedData));
    yAxisMaxValue = Math.round(maxValue * 1.25);
  }
  return yAxisMaxValue ? {maxLimit: Math.ceil(yAxisMaxValue / 4) * 4} : {};
};

export const getAssigneeName = (item: any) => {
  let name = '-';
  if (item?.assigneeId && item?.assigneeName) {
    name = item?.assigneeName;
  } else if (item?.userPoolId && item?.userPoolName) {
    name = item?.userPoolName;
  } else if (!item?.assigneeId) {
    name = 'Unassigned';
  }
  return name;
};