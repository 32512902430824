import OpportunityDetailDisplayRow from './OpportunityDetailDisplayRow';

const data = [
  {
    name: 'Primary Diagnosis',
    description: 'Type 2 diabetes mellitus',
    children: [
      {
        members: 35,
        name: 'Primary Diagnosis Description',
        description: 'Type 2 diabetes mellitus with ketoacidosis',
      },
      {
        members: 14,
        name: 'Primary Diagnosis Description',
        description: 'Type 2 diabetes mellitus with hyperosmolarity',
      },
      {
        members: 43,
        name: 'Primary Diagnosis Description',
        description: 'Type 2 diabetes mellitus with kidney complications',
      },
      {
        members: 12,
        name: 'Primary Diagnosis Description',
        description: 'Type 2 diabetes mellitus with circulatory complications',
      },
      {
        members: 7,
        name: 'Primary Diagnosis Description',
        description:
          'Type 2 diabetes mellitus with other specified complications',
      },
    ],
  },
  {
    name: 'Primary Diagnosis',
    description: 'Other specified diabetes mellitus',
    children: [
      {
        members: 3,
        name: 'Primary Diagnosis Description',
        description:
          'Other specified diabetes mellitus with unspecified complications',
      },
    ],
  },
];
export default function OpportunityDetailDisplay({
  opportunity,
}: {
  opportunity: any;
}) {
  return (
    <div style={{overflow: 'auto', height: '350px'}}>
      {data.map((episodes) => (
        <OpportunityDetailDisplayRow
          opportunity={opportunity}
          episodes={episodes}
        />
      ))}
    </div>
  );
}
