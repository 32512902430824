import { Tooltip } from 'antd';
import {Text,Pressable, View, HStack, Icon} from 'native-base';
import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';
import {Dimensions, StyleSheet} from 'react-native';
import {MODULE_PAGINATION_COUNT} from '../../../../constants';
import { IUserResp } from '../../../../services/User/interface';
import {Colors} from '../../../../styles';
import {NoDataFound} from '../../../common/NoDataFound';
import {SearchBar} from '../../../common/SearchBar';
import FilterNewIconSvg from '../../../common/Svg/FilterNewIconSvg';
import {renderConversationFooter} from '../Conversations/ConversationContainer/ConversationList/ConversationListComponents';
import {ConversationListLoader} from '../Conversations/ConversationContainer/ConversationList/ConversationListLoader';
import CallLogEntry from './CallLogEntry';
import {ICallLogEntry, ISipNumberList} from './interface';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';

interface CallLogListProps {
  callLogs: ICallLogEntry[];
  selectedCallLog: number;
  onPressItem: (index: any) => void;
  loadMoreCallLogs: () => void;
  isLoading: boolean;
  isLoadMoreLoading: boolean;
  setSearchText: (index: any) => void;
  userList: IUserResp[];
  sipNumberList?: ISipNumberList[];
  reloadCallLogs?:()=> void;
  onFilterModal?: ()=> void;
  conversationFilterProps?: any,
  isDialPadVisible?: boolean;
  showFetchMore: boolean;
}

const CallLogList = (props: CallLogListProps) => {
  const {height} = Dimensions.get('window');
  const intl = useIntl();
  // const finalListHeight = height - 255;
  const removeHeight = parseInt((0.8 / 100) * height + '');
  const finalListHeight = height - removeHeight - 240;

  const [callLogsState, setCallLogsState] =
  useState({
    conversationFilterProps: props?.conversationFilterProps || {} as any,
  });

  useEffect(() => {
    setCallLogsState((prev) => {
      return {
        ...prev,
        conversationFilterProps: props?.conversationFilterProps
      };
    });
  }, [props?.conversationFilterProps]);

  const renderCallLogList = () => {
    return (
      <>
        {props?.callLogs.map((item, index) => {
          return (
            <View
              style={styles.callLogItemContainer}
              key={item.id + index}
            >
              <Pressable
                background={
                  props?.selectedCallLog == index
                    ? Colors.Custom.Gray100
                    : '#ffffff'
                }
                style={
                  styles.callLogItemPressable
                }
                onPress={() => {
                  props?.onPressItem(index);
                }}
              >
                {false && props?.selectedCallLog == index && (
                  <View
                    style={styles.selectedCallLogIndicator}
                    backgroundColor={Colors.primary['300']}
                    height={'full'}
                  ></View>
                )}
                <CallLogEntry
                  callLog={item}
                  userList={props?.userList}
                  sipNumberList={props?.sipNumberList}
                />
              </Pressable>
            </View>
          );
        })}
        {renderConversationFooter(
          props?.showFetchMore,
          props?.isLoadMoreLoading,
          props?.callLogs?.length,
          props?.loadMoreCallLogs,
          false,
          MODULE_PAGINATION_COUNT.CALL_LOG_SIZE,
          'loadMoreCallLogs'
        )}
      </>
    );
  };

  const getRefreshCallLogsElement = (): JSX.Element => {
    return (
      <Tooltip
        title={intl.formatMessage({ id: 'refresh' })}
        placement={'bottom'}
      >
        <View alignItems={'center'}>
          <Pressable
            onPress={() => {
             props?.reloadCallLogs && props?.reloadCallLogs();
            }}
            style={styles.refreshButton}
          >
            <Icon
              as={MaterialCommunityIcon}
              name={'reload'}
              size={'5'}
              color={Colors.Custom.Gray500}
            />
          </Pressable>
        </View>
      </Tooltip>
    );
  }

  const callsRightTobBar = (): JSX.Element => {
    return (
      <View mt={2} flex={0.2} style={styles.callsRightTopBar} alignContent={'center'} justifyContent={'center'}>
        <HStack alignItems={'center'} justifyContent={'center'}>
        <View>{getRefreshCallLogsElement()}</View>
          <View ml={2} mr={2}>
            <Pressable
              onPress={() => {
                props?.onFilterModal && props?.onFilterModal();
              }}
              style={styles.filterButton}
            >
              <Tooltip
                title={callLogsState?.conversationFilterProps?.count?.join(
                  ' \n'
                )}
                arrowPointAtCenter={false}
                placement={'left'}
                overlayStyle={{ whiteSpace: 'pre-line' }}
              >
                {callLogsState?.conversationFilterProps?.count
                  ?.length ? (
                  <View style={styles.filterCountBadge} backgroundColor={Colors.danger[500]}>
                    <Text style={styles.filterCountText}>
                      {
                        callLogsState?.conversationFilterProps
                          ?.count?.length
                      }
                    </Text>
                  </View>
                ) : null}
               <FilterNewIconSvg
                isEnabled={true}
              />
              </Tooltip>
            </Pressable>
          </View>
        </HStack>
      </View>
    );
  };

  return (
    <View>
      <HStack flex={1} style={styles.searchBarContainer} justifyContent={'center'}>
        <View flex={0.8}>
        <SearchBar
          onChange={(value: string) => {
            props?.setSearchText(value);
          }}
          borderWidth={1}
          placeholderText="Search"
          searchBarInputStyles={styles.searchBarInput}
          isDialPadVisible={props.isDialPadVisible}
        />
        </View>
        {callsRightTobBar()}
      </HStack>
      {props?.isLoading ? (
        <ConversationListLoader listCount={10} selectedInboxTypeCode={''} />
      ) : (
        <View style={[styles.callLogListContainer, { height: finalListHeight }]}
        >
          <>
            {' '}
            {props?.callLogs?.length ? (
              renderCallLogList()
            ) : (
              <View
                alignItems={'center'}
                justifyContent={'center'}
                alignContent={'center'}
              >
                <NoDataFound displayString="noCallLogs" />
              </View>
            )}
          </>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  callLogItemContainer: {
    overflow: 'hidden',
  },
  callLogItemPressable: {
    overflow: 'hidden',
  },
  selectedCallLogItem: {
    //borderRadius: 16,
    //borderWidth: 1,
    //borderColor: Colors.Custom.Gray200,
  },
  selectedCallLogIndicator: {
    width: 10,
    // backgroundColor: Colors.primary['300'],
    position: 'absolute',
    zIndex: 22,
  },
  refreshButton: {
    justifyContent: 'center',
  },
  callsRightTopBar: {
    justifyContent: 'center',
  },
  filterButton: {
    width: 32,
    height: 32,
    justifyContent: 'center',
  },
  filterCountBadge: {
    position: 'absolute',
    top: -7.5,
    right: -7.5,
    // backgroundColor: Colors.danger[500],
    width: 20,
    height: 20,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterCountText: {
    color: '#ffffff',
  },
  searchBarContainer: {
    marginTop: 4,
  },
  searchBarInput: {
    marginTop: 2,
    marginLeft: 4,
    //marginRight: 4,
    backgroundColor: '#ffffff',
  },
  callLogListContainer: {
    overflow: 'scroll',
    marginTop: 15,
    scrollBehavior: 'smooth',
    marginLeft: 16,
    marginRight: 16,
  },
});

export default CallLogList;
