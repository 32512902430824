import {Table} from 'antd';
import {ScrollView, View} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {Colors} from '../../../../../styles/Colors';
import {TableWrapper} from '../../../../common/TableWrapper';
import {CMSLoading} from '../../CMSLoading';
import {ContentTypes} from '../../ContentManagementConsts';
import {
  deleteMediaContent,
  getMediaContent,
  getSortString,
  getTableSortOrderString,
} from '../../ContentManagementUtils';
import {AddMediaModal} from '../AddMediaModal';
import {DeleteMediaModal} from '../DeleteMediaModal';
import {IMediaLibraryData} from '../interfaces';
import {getMediaLibraryTableColumns} from '../MediaLibraryTable/Helper';
import {
  getFormattedMediaContent,
  getSingleMediaContent,
  MEDIA_VIEW_CODES,
} from '../MediaLibraryUtils';
import {MediaPreviewModal} from '../MediaPreview';
import {MediaContentApiData, MediaContentMetaData} from './interfaces';

export interface IMediaLibraryListViewProps {
  selectedRow: IMediaLibraryData;
  viewCode: string;
  searchString: string;
  onActionPerformed: (actionCode: string, record: IMediaLibraryData) => void;
}

const MediaLibraryListView = (props: IMediaLibraryListViewProps) => {
  const {selectedRow, viewCode, searchString, onActionPerformed} = props;

  const [deleteLoading, setDeleteLoading] = useState(false);
  const {height} = Dimensions.get('window');

  const [mediaTableState, setMediaTableState] = useState({
    isLoading: true,
    isNoDataFound: false,
    searchString: '',
    mediaList: [] as MediaContentApiData[],
    mediaDisplayList: [] as IMediaLibraryData[],
    mediaMeta: {} as MediaContentMetaData,
    page: 1,
    pageSize: 10,
    sort: 'desc',
  });

  const getMediaData = async () => {
    setMediaTableState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const mediaResponse = await getMediaContent(ContentTypes.attachments.path, {
      name: searchString,
      page: mediaTableState.page,
      pageSize: mediaTableState.pageSize,
      sort: mediaTableState.sort,
    });

    const data = getFormattedMediaContent(mediaResponse.data.data);
    setMediaTableState((prev) => {
      return {
        ...prev,
        isLoading: false,
        mediaList: mediaResponse.data,
        mediaDisplayList: data,
        mediaMeta: mediaResponse.data.meta,
      };
    });
  };

  useEffect(() => {
    // setMediaTableState((prev) => {
    //   return {
    //     ...prev,
    //     isLoading: true,
    //   };
    // });
    try {
      getMediaData();
    } catch (error) {
      setMediaTableState((prev) => {
        return {
          ...prev,
          isNoDataFound: true,
          isLoading: false,
        };
      });

    }
  }, [
    mediaTableState.page,
    mediaTableState.pageSize,
    searchString,
    mediaTableState.sort,
  ]);

  const handleDeleteMedia = async (
    singleMediaContent: IMediaLibraryData | undefined
  ) => {
    setDeleteLoading(true);
    const id = singleMediaContent?.id;
    const response = await deleteMediaContent(
      ContentTypes.attachments.path,
      id
    );
    if (response) {
      const newMediaContent = mediaTableState.mediaDisplayList.filter(
        (data) => data.id !== id
      );
      setMediaTableState((prev) => {
        return {
          ...prev,
          mediaDisplayList: newMediaContent,
        };
      });
      setDeleteLoading(false);
    }
    onActionPerformed(
      COMMON_ACTION_CODES.LIST_VIEW,
      singleMediaContent as IMediaLibraryData
    );
  };

  const handleUploadMedia = async (data: any) => {
    const mediaData = getSingleMediaContent(data.data);
    setMediaTableState((prev) => {
      return {
        ...prev,
        mediaDisplayList: [...mediaTableState.mediaDisplayList, mediaData],
      };
    });
  };

  const getMediaViewFromSelectedCode = () => {
    switch (viewCode) {
      case MEDIA_VIEW_CODES.MEDIA_LIST_VIEW:
      case MEDIA_VIEW_CODES.MEDIA_ADD_VIEW:
      case MEDIA_VIEW_CODES.MEDIA_DELETE_VIEW:
      case MEDIA_VIEW_CODES.MEDIA_EDIT_VIEW:
      case MEDIA_VIEW_CODES.MEDIA_PREVIEW_VIEW:
        return (
          <View>
            <View bgColor={'white'}>
              {mediaTableState.isLoading ? (
                <CMSLoading />
              ) : (
                <TableWrapper
                  hideOnSinglePage={false}
                  pagination={{
                    current: mediaTableState.page,
                    pageSize: mediaTableState.pageSize,
                    total: mediaTableState.mediaMeta.pagination.total,
                    onChange: (currentPage, currentPageSize) => {
                      setMediaTableState((prev) => {
                        return {
                          ...prev,
                          page: currentPage,
                          pageSize: currentPageSize,
                        };
                      });
                    },
                  }}
                >
                  <Table
                    rowClassName={(record, index) =>
                      index % 2 == 0 ? 'table-row-light' : ''
                    }
                    dataSource={mediaTableState.mediaDisplayList}
                    columns={getMediaLibraryTableColumns(
                      onActionPerformed,
                      getTableSortOrderString(mediaTableState.sort)
                    )}
                    scroll={{x: 700, y: height - 280}}
                    rowKey={(row) => row.id as string}
                    onChange={(_, __, sorter: any) => {
                      setMediaTableState((prev) => {
                        return {
                          ...prev,
                          sort: getSortString(sorter['order']),
                        };
                      });
                    }}
                    pagination={false}
                  />
                </TableWrapper>
              )}

              {viewCode === MEDIA_VIEW_CODES.MEDIA_ADD_VIEW && (
                <AddMediaModal
                  onClose={() => {
                    onActionPerformed(
                      COMMON_ACTION_CODES.LIST_VIEW,
                      selectedRow
                    );
                  }}
                  onUpload={(data) => {
                    handleUploadMedia(data);
                    onActionPerformed(
                      COMMON_ACTION_CODES.LIST_VIEW,
                      selectedRow
                    );
                  }}
                />
              )}

              {viewCode === MEDIA_VIEW_CODES.MEDIA_DELETE_VIEW && (
                <DeleteMediaModal
                  onClose={() => {
                    onActionPerformed(
                      COMMON_ACTION_CODES.LIST_VIEW,
                      selectedRow
                    );
                  }}
                  onConfirm={() => {
                    getMediaData();
                    onActionPerformed(
                      COMMON_ACTION_CODES.LIST_VIEW,
                      selectedRow
                    );
                  }}
                  loading={deleteLoading}
                  mediaData={selectedRow}
                />
              )}

              {viewCode === MEDIA_VIEW_CODES.MEDIA_PREVIEW_VIEW && (
                <MediaPreviewModal
                  previewData={{
                    mediaType: selectedRow?.mime,
                    mediaUrl: selectedRow.url,
                    mediaData: selectedRow as IMediaLibraryData,
                  }}
                  mediaData={selectedRow}
                  onClose={() => {
                    onActionPerformed(
                      COMMON_ACTION_CODES.LIST_VIEW,
                      selectedRow
                    );
                  }}
                />
              )}
            </View>
          </View>
        );
    }
  };

  return <>{getMediaViewFromSelectedCode()}</>;
};

export default MediaLibraryListView;
