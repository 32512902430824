import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  HStack,
  Input,
  Switch,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import {Colors} from '../../../../../styles';
import {ModalActionInput} from '../../../../common/ModalActionCommonComponent/ModalActionInput';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {BUTTON_TYPE} from '../../../../../constants';
import {IPaymentBody, IPaymentStateData} from './interfaces';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import CommerceCommonService from '../../../../../services/CommonService/CommerceCommonService';
import {useLazyQuery, useMutation} from '@apollo/client';
import {AccountApiCred} from '../../../../../services';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {styles} from './PaymentStyles';
import {
  CreatePaymentCred,
  UpdatePaymentCred,
} from '../../../../../services/Stripe/StripeServices';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {useIntl} from 'react-intl';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';

const PaymentSetup = (props: {addEditPaymentEvent: boolean}) => {
  const accountUuid = getAccountUUID();
  const intl = useIntl();
  const paymentSetupFormToast = useToast();
  const [GetApiCredentials] = useLazyQuery(AccountApiCred.GetApiCredentials, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });
  const [CreateCredentials] = useMutation(AccountApiCred.CreateCredentials);
  const [UpdateCredentials] = useMutation(AccountApiCred.UpdateCredentials);
  const [stateData, setStateData] = useState<IPaymentStateData>({
    apiMode: 'test',
    isApiModeChecked: false,
    testPublishableKey: '',
    testSecretKey: '',
    livePublishableKey: '',
    liveSecretKey: '',
    name: '',
    id: null,
    active: true,
  });
  const showToastMessage = (msg: string, isError: boolean, isI18N = true) => {
    showToast(paymentSetupFormToast, isI18N ? intl.formatMessage({id: msg}) : msg, isError ? ToastType.error : ToastType.success);
  };
  const addEditPaymentSetupData = () => {
    if (stateData?.id) {
      updatePaymentMethod();
    } else {
      createPaymentMethod();
    }
  };

  const createPaymentMethod = async () => {
    const body = {
      accountUuid: accountUuid,
      apiKey: stateData.livePublishableKey,
      apiSecret: stateData.liveSecretKey,
      credType: 'STRIPE',
    };
    const updateResponse = await CreatePaymentCred(body);
    if (updateResponse?.data?.id) {
      showToastMessage('paymentDetailUpdated', false);
    } else {
      showToastMessage('errorPaymentUpdated', true);
    }
  };

  const updatePaymentMethod = async () => {
    const body = {
      id: stateData.id,
      accountUuid: accountUuid,
      apiKey: stateData.livePublishableKey,
      apiSecret: stateData.liveSecretKey,
      credType: 'STRIPE',
    };

    const updateResponse: any = await UpdatePaymentCred(body);
    if (updateResponse?.data?.count == 1) {
      showToastMessage('paymentDetailUpdated', false);
    } else {
      showToastMessage('errorPaymentUpdated', true);
    }
  };

  const getPaymentSetupData = async () => {
    const stripResponse = await GetApiCredentials({
      variables: {
        accountUuid: accountUuid,
        type: 'STRIPE',
      },
    });
    if (stripResponse?.data?.accountApiCredentials.length > 0) {
      const stripeData = stripResponse.data.accountApiCredentials[0];
      setStateData((prev) => {
        return {
          ...prev,
          name: stripeData.cred_type,
          livePublishableKey: stripeData.apiKey,
          liveSecretKey: stripeData.apiSecret,
          id: stripeData?.id,
        };
      });
    }
  };
  useEffect(() => {
    getPaymentSetupData();
  }, []);

  return (
    <View style={{padding: 32, overflow: 'scroll'}}>
      <View style={{marginTop: 25}}>
        <View flex={1}>
          <Text
            style={{marginBottom: 8, color: Colors.Custom.Gray700}}
            size={'lgBold'}
          >
            Stripe Developer API Keys
          </Text>
          <VStack>
            <FormControl
              style={[styles.formElement, {width: '50%'}]}
              isRequired
            >
              <FormControl.Label style={styles.formLabel}>
                <DisplayText
                  textLocalId={'publishableKey'}
                  size={'smMedium'}
                  extraStyles={{color: Colors.Custom.Gray700}}
                />
              </FormControl.Label>
              <Input
                placeholder="publishableKey"
                value={stateData.livePublishableKey}
                onChangeText={(value) => {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      livePublishableKey: value,
                    };
                  });
                }}
              />
              {/* {errors.name && (
                <FormControl.ErrorMessage
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                >
                  {errors.name}
                </FormControl.ErrorMessage>
              )} */}
            </FormControl>
            <FormControl
              style={[styles.formElement, {width: '50%'}]}
              isRequired
            >
              <FormControl.Label style={styles.formLabel}>
                <DisplayText
                  textLocalId={'secretKey'}
                  size={'smMedium'}
                  extraStyles={{color: Colors.Custom.Gray700}}
                />
              </FormControl.Label>
              <Input
                placeholder="secretKey"
                value={stateData.liveSecretKey}
                onChangeText={(value) => {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      liveSecretKey: value,
                    };
                  });
                }}
              />
              {/* {errors.name && (
                <FormControl.ErrorMessage
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                >
                  {errors.name}
                </FormControl.ErrorMessage>
              )} */}
            </FormControl>
          </VStack>
        </View>
        <Button.Group
          style={{paddingHorizontal: 12, paddingVertical: 12}}
          mx={{
            base: 'auto',
            md: 0,
          }}
          justifyContent={'flex-start'}
        >
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress: () => {
                addEditPaymentSetupData();
              },
              style: {marginRight: 20},
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: stateData.id ? 'updateSettings' : 'saveSettings',
              }),
              withRightBorder: false,
            }}
          ></FoldButton>
        </Button.Group>
      </View>
    </View>
  );
};

export default PaymentSetup;
