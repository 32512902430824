import {useLazyQuery} from '@apollo/client';
import {GET_SINGLE_USER} from '../../../../services/User/UserQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {GET_CARE_PROGRAM_LAST_ACTIVITY_LOGS} from '../../../../services/ContactCareProgram/ContactCareProgram';

export const useContactCareProgramCardAdditionalData = () => {
  const [getSingleUserById] = useLazyQuery(GET_SINGLE_USER, {
    fetchPolicy: 'no-cache',
  });

  const [getCareProgramActivityLogs] = useLazyQuery(
    GET_CARE_PROGRAM_LAST_ACTIVITY_LOGS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const getContactCareProgramAdditionalData = async (
    assigneeId: string,
    contactCareProgramId: string
  ) => {
    const promiseList: Promise<any>[] = [];
    if (assigneeId) {
      promiseList.push(getSingleUserById({variables: {userId: assigneeId}}));
    }
    if (contactCareProgramId) {
      promiseList.push(
        getCareProgramActivityLogs({
          variables: {contactCareProgramId: contactCareProgramId},
        })
      );
    }
    if (promiseList.length === 0) {
      return {
        user: null,
        careProgramActivityLogs: null,
      };
    }
    const [userData, careProgramActivityLogs] = await Promise.all(promiseList);
    return {
      user: userData?.data?.users?.[0],
      careProgramActivityLogs:
        careProgramActivityLogs?.data?.getActivityLogs?.activityLogs?.[0],
    };
  };

  return {
    getContactCareProgramAdditionalData,
  };
};
