import { Icon, Text, View } from 'native-base';
import { IntlShape } from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { DATE_FORMATS, JOURNEYS_TABLE_VIEW } from '../../../../../../constants';
import {
  getDateStrFromFormat,
  isCurrentDateInFutureComparedToOther
} from '../../../../../../utils/DateUtils';
import { TABLE_TOP_BAR_ACTION_CODES } from '../../../../../common/TableTopBar/TableTopBarConst';
import { IWorkflowData } from '../../../../Workflow/Workflow/WorkflowTableView/Interfaces';
import AssignCareJourneyPopup from '../../AssignCareJourneyPopup/AssignCareJourneyPopup';
import { ActionsView } from '../ActionsView';
import AssignJourneyActionsView from '../ActionsView/AssignJourneyActionsView';
import { CohortSizeView } from '../CohortSizeView';
// Helpers
import { Colors } from '../../../../../../styles';
import { TableSortOrders } from '../Enums';
import { IJourney, ITag } from '../Interfaces';
import { StatusView } from '../StatusView';
import { TagView } from '../TagView';
import { TitleView } from '../TitleView';

const getColumnAttributes = (
  columnName: string,
  intl: IntlShape,
  sortOrder: TableSortOrders | undefined,
  onEdit: (record: any) => void,
  onDelete: (record: any) => void
) => {
  let columnAttributes;
  switch (columnName) {
    case 'workflowName':
      columnAttributes = {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      };
      break;
    case 'name':
      columnAttributes = {
        title: intl.formatMessage({id: 'name'}),
        key: 'name',
        dataIndex: 'title',
        render: (title: string, record: IJourney) => {
          return <TitleView title={title} subtitle={record.description} />;
        },
      };
      break;

    case 'patientCareJourneyName':
      columnAttributes = {
        title: intl.formatMessage({id: 'name'}),
        key: 'name',
        render: (record: any) => {
          return (
            <TitleView
              title={record?.title || record?.careJourney?.title}
              subtitle={record?.description || record?.careJourney?.description}
            />
          );
        },
      };
      break;

    case 'status':
      columnAttributes = {
        title: intl.formatMessage({id: 'status'}),
        key: 'status',
        dataIndex: ['status', 'value'],
        defaultSortOrder: sortOrder,
        sorter: true,
        render: (text: string, record: IJourney) => (
          <StatusView status={record.status} />
        ),
      };
      break;

    case 'patientCareJourneyStatus':
      columnAttributes = {
        title: intl.formatMessage({id: 'status'}),
        key: 'status',
        render: (record: any) => {
          let status = 'Active';
          if (
            record.endDateTime &&
            isCurrentDateInFutureComparedToOther(new Date(), record.endDateTime)
          ) {
            status = 'Completed';
          }
          return <Text>{status}</Text>;
        },
      };
      break;

    case 'date':
      columnAttributes = {
        title: intl.formatMessage({id: 'date'}),
        key: 'date',
        render: (record: any) => {
          let date = '-';
          if (record.startDateTime && record.endDateTime) {
            date = getDateStrFromFormat(
              record.startDateTime,
              DATE_FORMATS.DISPLAY_DATE_FORMAT
            );

            if (
              isCurrentDateInFutureComparedToOther(
                new Date(),
                record.endDateTime
              )
            ) {
              date +=
                ' - ' +
                getDateStrFromFormat(
                  record.endDateTime,
                  DATE_FORMATS.DISPLAY_DATE_FORMAT
                );
            } else {
              date += ' - Present';
            }
          }
          return <Text>{date}</Text>;
        },
      };
      break;
    case 'patientCarePrograms_aggregate':
      columnAttributes = {
        title: intl.formatMessage({id: 'cohortSize'}),
        key: 'patientCarePrograms_aggregate',
        dataIndex: ['patientCarePrograms_aggregate', 'aggregate', 'count'],
        render: (text: string, record: IJourney) => {
          const count = record.patientCarePrograms_aggregate.aggregate.count;
          const cohortSize = count + ' Members';
          return <CohortSizeView text={cohortSize} />;
        },
      };
      break;

    case 'workflowTags':
      columnAttributes = {
        title: intl.formatMessage({id: 'tags'}),
        key: 'tags',
        // dataIndex: 'tagList',
        // render: (tags: ITag[]) => {
        //   return <TagView data={tags} />;
        // },
        render: (record: IWorkflowData) => {
          const tagList = record.tagList;
          return (
            <View>
              {tagList.map((tag, index) => {
                return <Text key={index}>{tag}</Text>;
              })}
            </View>
          );
        },
      };
      break;
    case 'tags':
      columnAttributes = {
        title: intl.formatMessage({id: 'tags'}),
        key: 'tags',
        dataIndex: 'tags',
        render: (tags: ITag[]) => {
          return <TagView data={tags} />;
        },
      };
      break;
    case 'action':
      columnAttributes = {
        title: intl.formatMessage({id: 'action'}),
        key: 'action',
        render: (record: any) => {
          return (
            <ActionsView
              onDelete={() => onDelete(record)}
              onEdit={() => onEdit(record)}
            />
          );
        },
      };
      break;
    case 'assignJourneyActions':
      columnAttributes = {
        title: intl.formatMessage({id: 'action'}),
        key: 'assignJourneyActions',
        render: () => {
          return <AssignJourneyActionsView />;
        },
      };
      break;
  }
  return columnAttributes;
};

const getButtonAttributes = (
  buttonName: string,
  handler: (functionCode: string, functionData?: any) => any,
) => {
  let buttonAttributes;
  switch (buttonName) {
    case TABLE_TOP_BAR_ACTION_CODES.NEW_JOURNEY:
      buttonAttributes = {
        btnText: 'newJourney',
        size: 'sm',
        colorScheme: 'primary',
        textColor: 'white',
        variant: '',
        leftIcon: <Icon as={AntIcon} name={'plus'} size="4" color="white" />,
        btnClick: () => {
          handler(TABLE_TOP_BAR_ACTION_CODES.NEW_JOURNEY);
        },
      };
      break;
    case TABLE_TOP_BAR_ACTION_CODES.ASSIGN_JOURNEY:
      buttonAttributes = {
        id: 0,
        btnText: 'assignJourney',
        size: 'sm',
        colorScheme: 'primary',
        backgroundColor: Colors.secondary['100'],
        borderWidth: 1,
        borderColor: Colors.secondary['200'],
        textColor: Colors.secondary['800'],
        variant: '',
        leftIcon: <Icon as={AntIcon} name={'plus'} size="4" color={Colors.secondary['800']} />,
        btnClick: () => {
          // btnClick
        },
        content: (
          <AssignCareJourneyPopup
            onComplete={(record: any) => {
              handler(TABLE_TOP_BAR_ACTION_CODES.ASSIGN_JOURNEY, record);
            }}
          />
        ),
      };
      break;
      case TABLE_TOP_BAR_ACTION_CODES.VIEW_CARE_TEAM:
      buttonAttributes = {
        id: 1,
        btnText: 'showCareTeam',
        size: 'sm',
        colorScheme: 'primary',
        backgroundColor: Colors.secondary['100'],
        borderWidth: 1,
        borderColor: Colors.secondary['200'],
        textColor: Colors.secondary['800'],
        variant: '',
        btnClick: () => {
          handler(TABLE_TOP_BAR_ACTION_CODES.VIEW_CARE_TEAM);
        },
        content: (<></>),
      };
      break;
  }
  return buttonAttributes;
};

const getColumnListForViews = (view: string) => {
  let columnList: any = [];
  switch (view) {
    case JOURNEYS_TABLE_VIEW.ALL_JOURNEYS:
      columnList = [
        // 'name',
        // 'status',
        // 'patientCarePrograms_aggregate',
        // 'tags',
        // 'action',

        'workflowName',
        'workflowTags',
        'action',
      ];
      break;
    case JOURNEYS_TABLE_VIEW.PATIENT_JOURNEYS:
      columnList = [
        // 'name',
        // 'status',
        'patientCareJourneyName',
        'patientCareJourneyStatus',
        'date',
        // 'assignJourneyActions',
      ];
      break;
  }
  return columnList;
};

const getButtonListForViews = (view: string, showCareTeamButton?: boolean) => {
  let buttonList: any = [];
  switch (view) {
    case JOURNEYS_TABLE_VIEW.ALL_JOURNEYS:
      buttonList = [TABLE_TOP_BAR_ACTION_CODES.NEW_JOURNEY];
      break;
    case JOURNEYS_TABLE_VIEW.PATIENT_JOURNEYS:
      buttonList = [TABLE_TOP_BAR_ACTION_CODES.ASSIGN_JOURNEY];
      if (showCareTeamButton) {
        buttonList.push(TABLE_TOP_BAR_ACTION_CODES.VIEW_CARE_TEAM);
      }
      break;
  }
  return buttonList;
};

export const getColumns = (
  sortOrder: TableSortOrders | undefined,
  intl: IntlShape,
  view: any,
  onEdit: (reacord: any) => void,
  onDelete: (reacord: any) => void
): any[] => {
  const columns: Array<any> = [];
  const columnList = getColumnListForViews(view);
  columnList.forEach((columnName: any) => {
    const columnAttributes = getColumnAttributes(
      columnName,
      intl,
      sortOrder,
      onEdit,
      onDelete
    );
    columns.push(columnAttributes);
  });
  return columns;
};

export const getActionButtons = (
  view: any,
  handler: (functionCode: string, functionData?: any) => any,
  personData?: any,
  accountUserList?: any[],
  showCareTeamButton?: boolean,
): any[] => {
  const buttons: Array<any> = [];
  const buttonList = getButtonListForViews(view, showCareTeamButton);
  buttonList.forEach((buttonName: any) => {
    const buttonAttributes = getButtonAttributes(buttonName, handler);
    buttons.push(buttonAttributes);
  });
  return buttons;
};

export function getSortByQuery(
  sortBy: string,
  sortOrder: TableSortOrders | undefined
) {
  const orderText = sortOrder === TableSortOrders.descend ? 'desc' : 'asc';
  if (sortBy === 'status') {
    return {
      status: {
        value: orderText,
      },
    };
  }
  return {
    title: orderText,
  };
}
