import {Badge, Button, DatePicker, Popover} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import moment from 'moment';
import {HStack, Pressable, View, VStack} from 'native-base';
import React, {useState} from 'react';
import Icon from 'react-native-vector-icons/AntDesign';
import {DATE_FORMATS} from '../../../constants';
import {Colors} from '../../../styles';
import {
  getDateStrFromMomentObj,
  getMomentObjFromFormat,
} from '../../../utils/DateUtils';
import {styles} from './FiltersStyles';

const FilterByDateRange = (props: any) => {
  const {returnFilterItems} = props;
  const {RangePicker} = DatePicker;
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(true);
  const [isDatePickerPopup, setIsDatePickerPopup] = useState(false);
  const [dateRangeFilter, serDateRangeFilter] = useState({
    fromDate: '',
    toDate: '',
  });
  const [filterObj, setFilterObj] = useState<any>({
    datePicker: {
      fromDate: '',
      toDate: '',
    },
  } as any);
  const getRangePickerDefaultValue = (): any => {
    const datePicker = filterObj?.datePicker || {};
    let defaultValue: any[] = [];
    if (datePicker && datePicker.fromDate && datePicker.toDate) {
      defaultValue = [
        getMomentObjFromFormat(
          datePicker.fromDate,
          DATE_FORMATS.CONVERSATION_DATE_PICKER
        ),
        getMomentObjFromFormat(
          datePicker.toDate,
          DATE_FORMATS.CONVERSATION_DATE_PICKER
        ),
      ];
    }
    return defaultValue;
  };
  const disabledDate = (current: any) => {
    return current && current > moment().endOf('day');
  };
  const onDateRangeChanged = (value: any) => {
    if (value) {
      value?.[1]?.add(1, 'day');
      const fromDateStr = value
        ? getDateStrFromMomentObj(
            value[0],
            `${DATE_FORMATS.CONVERSATION_DATE_FILTER}`
          )
        : '';
      const toDateStr = value
        ? getDateStrFromMomentObj(
            value[1],
            `${DATE_FORMATS.CONVERSATION_DATE_FILTER}`
          )
        : '';
      returnFilterItems({fromDate: fromDateStr, toDate: toDateStr});
      serDateRangeFilter({
        ...dateRangeFilter,
        fromDate: fromDateStr,
        toDate: toDateStr,
      });
      setFilterObj({
        ...filterObj,
        datePicker: {
          fromDate: getDateStrFromMomentObj(
            value[0],
            DATE_FORMATS.CONVERSATION_DATE_PICKER
          ),
          toDate: getDateStrFromMomentObj(
            value[1],
            DATE_FORMATS.CONVERSATION_DATE_PICKER
          ),
        },
      });
    } else {
      setFilterObj({
        ...filterObj,
        datePicker: {
          fromDate: '',
          toDate: '',
        },
      });
    }
  };
  const getDateRangePicker = (): JSX.Element => {
    return (
      <RangePicker
        size="small"
        defaultValue={getRangePickerDefaultValue()}
        format={DATE_FORMATS.CONVERSATION_DATE_PICKER}
        allowClear={true}
        suffixIcon
        disabledDate={disabledDate}
        style={{
          width: '220px',
          height: '36px',
          marginRight: 10,
          fontSize: 12,
        }}
        placeholder={['Start', 'End']}
        onChange={(val) => {
          onDateRangeChanged(val);
          setIsDatePickerOpen(false);
          setIsDatePickerPopup(false);
        }}
        open={isDatePickerOpen}
      />
    );
  };
  const isDateSelected = () => {
    return filterObj.datePicker.fromDate && filterObj.datePicker.toDate;
  };
  const getDateRangePopup = (): JSX.Element => {
    return (
      <Popover
        visible={isDatePickerPopup}
        content={<Content>{getDateRangePicker()}</Content>}
        title="Select Date Range"
        trigger="click"
        className="date-picker-btn"
        onVisibleChange={(visible: any) => {
          setIsDatePickerOpen(visible);
          setIsDatePickerPopup(visible);
        }}
        overlayClassName="date-picker-container"
      >
        {isDateSelected() ? (
          <Badge
            className="con-date-picker-badge"
            color={Colors.success['200']}
            dot
          >
            <HStack alignItems={'center'} style={styles.dateTextWrapper}>
              <VStack marginRight={2}>
                <Pressable
                  onPress={() => {
                    returnFilterItems({fromDate: '', toDate: ''});
                    serDateRangeFilter({
                      ...dateRangeFilter,
                      fromDate: '',
                      toDate: '',
                    });
                    setFilterObj({
                      ...filterObj,
                      datePicker: {
                        fromDate: '',
                        toDate: '',
                      },
                    });
                  }}
                >
                  <View borderRadius={'50%'}>
                    <Icon
                      name="close"
                      style={{
                        justifyContent: 'center',
                      }}
                    />
                  </View>
                </Pressable>
              </VStack>
              <VStack>
                <HStack>
                  <Button
                    type="link"
                    style={{
                      padding: 0,
                      color: Colors.Custom.TextColor,
                    }}
                  >
                    {filterObj.datePicker.fromDate} --
                    {filterObj.datePicker.toDate}
                  </Button>
                  <Icon
                    name="down"
                    style={{
                      marginTop: 10,
                    }}
                  />
                </HStack>
              </VStack>
            </HStack>
          </Badge>
        ) : (
          <HStack style={styles.dateTextWrapper}>
            <Button
              type="link"
              style={{
                padding: 2,
                color: Colors.Custom.TextColor,
              }}
            >
              Select Date
            </Button>
            <Icon
              name="down"
              style={{
                marginTop: 10,
              }}
            />
          </HStack>
        )}
      </Popover>
    );
  };
  return <>{getDateRangePopup()}</>;
};

export default FilterByDateRange;
