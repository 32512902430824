import {View, Text, StyleSheet} from 'react-native';
import React, {useEffect, useState} from 'react';
import {getEnabledWorkflowList} from '../../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {FlowType} from '../../../../context/WorkflowContext';
import {getAccountUUID} from '../../../../utils/commonUtils';
import Stack from '../../../common/LayoutComponents/Stack';
import {Colors} from '../../../../styles';
import InfoSvg from '../../../common/Svg/InfoSvg';
import {Checkbox} from 'antd';
import ZapSvg from '../../../../assets/svg/ZapSvg';

interface IBulkTaskOperationAutomationListProps {
  allowToTriggerNotification: boolean;
  onAllowToTriggerNotificationChange: (isEnabled: boolean) => void;
}

interface IAutomationList {
  id: string;
  name: string;
  description?: string;
  isEnabled: boolean;
  eventType?: string;
}

const BulkTaskOperationAutomationList = (
  props: IBulkTaskOperationAutomationListProps
) => {
  // Constants
  const {allowToTriggerNotification, onAllowToTriggerNotificationChange} =
    props;
  const accountUuid = getAccountUUID();

  // States
  const [componentState, setComponentState] = useState<{
    loading: boolean;
    automationList: IAutomationList[];
  }>({
    loading: true,
    automationList: [],
  });

  // Lifecycle methods
  useEffect(() => {
    fetchEnabledWorkflows();
  }, []);

  // Other methods
  const fetchEnabledWorkflows = async () => {
    try {
      const response = await getEnabledWorkflowList({
        flowType: FlowType.task,
        accountUUID: accountUuid,
        entity: 'userTask',
        isEnabled: true,
      });
      const automation = response?.data?.data?.workflows || [];
      setComponentState((prev) => ({...prev, loading: false, automationList: automation }));
    } catch {
      setComponentState((prev) => ({...prev, loading: false}));
    }
  };

  return (
    <View>
      {!componentState.loading && componentState.automationList.length > 0 && (
        <Stack
          direction="column"
          space={8}
          style={styles.mainContainer}
        >
          <Stack
            direction="row"
            style={styles.headerContainer}
          >
            <Stack direction="row" space={8} style={styles.headerContent}>
              <Stack direction="row" space={8} style={styles.infoContainer}>
                <View style={styles.infoIconContainer}>
                  <InfoSvg customDimension={'20'} />
                </View>
                <Text style={styles.infoText}>
                    {'Following automation(s) will be impacted'}
                </Text>
              </Stack>
              <View style={styles.checkboxContainer}>
                <Checkbox
                  checked={!allowToTriggerNotification}
                  onChange={(event) => {
                    onAllowToTriggerNotificationChange(!event.target.checked);
                  }}
                >
                  <Text style={styles.checkboxText}>
                    {'Do not trigger the below automation(s)'}
                  </Text>
                </Checkbox>
              </View>
            </Stack>
          </Stack>
          <Stack direction="column">
            {componentState.automationList.map((automation, index) => {
              return (
                <Stack
                  direction="row"
                  space={8}
                  key={automation.id + index}
                  style={styles.automationItem}
                >
                  <View style={styles.zapIconContainer}>
                    <ZapSvg />
                  </View>
                  <Stack direction="column" space={4} style={styles.automationContent}>
                    <Text style={styles.automationName}>
                      {automation.name}
                    </Text>
                    <Text style={styles.automationDescription}>
                      {automation.description}
                    </Text>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: Colors.Custom.Gray100,
    borderColor: Colors.Custom.Gray300,
    borderWidth: 0.5,
    borderRadius: 8,
    marginBottom: 8,
    overflow: 'hidden',
  },
  headerContainer: {
    borderBottomColor: Colors.Custom.Gray300,
    borderBottomWidth: 0.5,
    alignItems: 'center',
    backgroundColor: Colors.Custom.White,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  headerContent: {
    flex: 1,
  },
  infoContainer: {
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  infoIconContainer: {
    marginTop: 2,
  },
  infoText: {
    color: Colors.Custom.Gray600,
    fontSize: 16,
  },
  checkboxContainer: {
    alignItems: 'flex-end',
  },
  checkboxText: {
    color: Colors.Custom.Gray500,
    fontSize: 14,
  },
  automationItem: {
    paddingHorizontal: 8,
    paddingVertical: 8,
    flex: 1,
    borderColor: Colors.Custom.Gray300,
    backgroundColor: Colors.Custom.White,
    overflow: 'hidden',
    borderWidth: 0.5,
    marginHorizontal: 8,
    marginBottom: 8,
    alignItems: 'flex-start',
    borderRadius: 8,
  },
  zapIconContainer: {
    marginTop: 2,
  },
  automationContent: {
    flex: 1,
  },
  automationName: {
    fontWeight: '400',
    fontSize: 16,
    color: Colors.Custom.Gray600,
  },
  automationDescription: {
    fontWeight: '400',
    fontSize: 12,
    color: Colors.Custom.Gray500,
  },
});

export default BulkTaskOperationAutomationList;