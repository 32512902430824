import React, {useEffect, useState} from 'react';
import {MemberGroupsProps} from '../../../interfaces';
import {Divider, HStack, Skeleton, Text, View, VStack} from 'native-base';
import {getCampaignByResourceId} from '../../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {Popover} from 'antd';
import {isCurrentMenuLast} from '../../../../../../../utils/commonUtils';

export interface CampaignList {
  campaign: Campaign;
}

export interface Campaign {
  name: string;
}

const MemberCampaign = (props: MemberGroupsProps) => {
  const {contactData} = props;

  const [state, setState] = useState({
    loading: true,
    error: false,
    campaignList: [] as CampaignList[],
  });

  const getCampaignList = async () => {
    try {
      const response = await getCampaignByResourceId(
        (contactData?.id || '').toString()
      );
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          campaignList: response?.campaignList || [],
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          error: true,
          campaignList: [],
        };
      });
    }
  };

  useEffect(() => {
    getCampaignList();
  }, []);

  const showPopover = state.campaignList.length > 0;

  return (
    <HStack
      style={{
        flexWrap: 'wrap',
      }}
    >
      {state.loading && <Skeleton.Text lines={1} />}

      {state.error && !state.loading && <Text>--</Text>}

      {!state.loading && state.campaignList.length === 0 && <Text>--</Text>}

      {showPopover && (
        <>
          <Popover
            content={
              <VStack>
                {state.campaignList.map((item, index) => {
                  return (
                    <View
                      style={{
                        marginVertical: 2,
                      }}
                    >
                      <Text
                        style={{
                          marginHorizontal: 4,
                          marginVertical: 2,
                        }}
                      >
                        {item.campaign.name || ''}
                      </Text>
                      {isCurrentMenuLast(index, state.campaignList) && (
                        <Divider />
                      )}
                    </View>
                  );
                })}
              </VStack>
            }
          >
            <Text>{`${state.campaignList.length}`}</Text>
          </Popover>
        </>
      )}
    </HStack>
  );
};

export default MemberCampaign;
