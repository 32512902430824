import {TabsProps} from 'antd';
import {Extension, MedicationRequest, RequestGroup} from 'fhir/r4';
import {getDateStrFromFormat, getMomentObj} from '../../../../utils/DateUtils';
import {
  IExtension,
  IRequestGroup,
} from '../../LeftContainer/RecentActivity/OrdersInterface';
import { isAllReportViewedBy } from '../../LeftContainer/RecentActivity/RecentReport/ReportUtils';
import { getAuthorId, getExtensionValue, getMedicationOrderName, getOrderDateTime, getOrderName, getRequesterId, hasReportFor } from '../Orders/OrdersAndReports/OrderUtils';
import {EXTENSION_URLS} from '../PatientNotes/components/AddOrUpdateTemplate/constant';
import {EXTENSION_URL_KEYS} from '../PatientNotes/PatientNotesHelper';
import {
  FILTER_MENU_KEYS,
  IOrderTimeLineTabs,
  IPatientNotesData,
  ITimelineData,
} from './interfaces';
import {
  MedicationRequestStatus,
  OrderTimeLineTabCode,
  RequestGroupStatus,
} from './OrderTimelineConstants';
import { IOrderTimelineSelectedFilters, OrderFilterCode } from './OrderTimelineFilter';
export const getOrderTimeLineTabs = (params: {
  isLabEnabled: boolean; isRadEnabled: boolean; isMedEnabled: boolean;
}) => {
  const tabs: TabsProps['items'] = [];
  Object.keys(OrderTimeLineTabCode).forEach((key) => {
    const isEnabled = key === OrderTimeLineTabCode.ALL ||
      (key === OrderTimeLineTabCode.ERX && params.isMedEnabled) ||
      (key === OrderTimeLineTabCode.LAB && params.isLabEnabled) ||
      (key === OrderTimeLineTabCode.IMAGING && params.isRadEnabled);
    if (isEnabled) {
      tabs.push({
        key: OrderTimeLineTabCode[key as keyof typeof OrderTimeLineTabCode],
        label: IOrderTimeLineTabs[key as keyof typeof OrderTimeLineTabCode],
      });
    }
  });
  return tabs;
};

export const getFormmattedNotes = (res: any): IPatientNotesData[] => {
  const entries = res?.data?.entry?.map((item: any) => item.resource);
  const data: IPatientNotesData[] = [];

  entries?.forEach((item: any) => {
    let formName = '';
    let formId = '';
    let date = '';
    let id = '';
    const extension = item?.extension;
    extension?.forEach((ext: any) => {
      if (ext.url.includes(EXTENSION_URL_KEYS.FORM_NAME)) {
        formName = ext.valueString;
      }
      if (ext.url.includes(EXTENSION_URL_KEYS.FORM_ID)) {
        formId = ext.valueString;
      }
    });
    date = item.date;
    id = item.id;
    data.push({
      id,
      formName: formName,
      date,
      formId,
      noteData: item,
    });
  });
  return data;
};

export const getAllOrdersTimelineData = (
  ordersData: RequestGroup[],
  medOrders: MedicationRequest[],
  notes: IPatientNotesData[],
  filters: {
    searchQuery: string;
    activeFilter: IOrderTimelineSelectedFilters | undefined,
    orderType?: string
  },
) => {
  const data: ITimelineData[] = [];

  // eslint-disable-next-line prefer-const
  let {labRadOrders, medOrders: medicationOrders, notesData} = getFilteredTimelineDataBasedOnStatus(
    filters.activeFilter,
    ordersData,
    medOrders,
    notes
  );
  labRadOrders = getFilteredRequestGroupDataByNameAndType(labRadOrders, filters.searchQuery, filters.orderType);
  medicationOrders = medicationOrders.filter((med) => getMedicationOrderName(med)?.toLowerCase().includes(filters.searchQuery.toLowerCase()))
  const noteIds = notesData.map((note) => note.id);
  notesData.forEach((item) => {
    const orderFound = [];
    const noteOrders = labRadOrders.filter((order) =>
      order?.basedOn?.[0]?.reference?.includes(item.id)
    );
    orderFound.push(...noteOrders);
    const medOrderFound = medicationOrders.filter((order) =>
      order?.basedOn?.[0]?.reference?.includes(item.id)
    );
    orderFound.push(...medOrderFound);
    if (orderFound.length > 0) {
      data.push({
        noteData: item.noteData,
        noteId: item.id,
        formName: item.formName,
        date: item.date,
        orders: orderFound,
      });
    }
  });

  if (!filters.activeFilter?.form?.id) {
    const independentOrders = labRadOrders.filter((item) => {
      const noteId = item?.basedOn?.[0]?.reference?.split('/')[1];
      if (
        noteId === '' ||
        noteId === undefined ||
        noteId === null ||
        noteId === 'undefined' ||
        !noteIds.includes(noteId)
      ) {
        return true;
      } else {
        return false;
      }
    });

    const independentMedOrders = medicationOrders.filter((item) => {
      const noteId = item?.basedOn?.[0]?.reference?.split('/')[1];
      if (
        noteId === '' ||
        noteId === undefined ||
        noteId === null ||
        noteId === 'undefined' ||
        !noteIds.includes(noteId)
      ) {
        return true;
      }
      return false;
    });

    independentMedOrders.forEach((item) => {
      data.push({
        noteId: '',
        formName: '',
        date: '',
        orders: [item],
      });
    });

    independentOrders.forEach((item) => {
      data.push({
        noteId: '',
        formName: '',
        date: '',
        orders: [item],
      });
    });
  }

  const timelineMap = sortData(data);
  return timelineMap;
};

const sortData = (data: ITimelineData[]) => {
  const sortedByDate = data.sort((a, b) => {
    const dateA =
      a.noteId === ''
        ? new Date(getOrderDateTime(a?.orders?.[0]) || '')
        : new Date(a.date);
    const dateB =
      b.noteId === ''
        ? new Date(getOrderDateTime(b?.orders?.[0]) || '')
        : new Date(b.date);
    return dateB.getTime() - dateA.getTime();
  });

  const timelineData = new Map();

  sortedByDate.forEach((item) => {
    const monthName =
      item.noteId === ''
        ? getDateStrFromFormat(getOrderDateTime(item?.orders?.[0]) || '', 'MMMM YYYY')
        : getDateStrFromFormat(item.date, 'MMMM YYYY');
    if (timelineData.has(monthName)) {
      const value = timelineData.get(monthName);
      value.push(item);
      timelineData.set(monthName, value);
    } else {
      timelineData.set(monthName, [item]);
    }
  });
  //create array of objects from map wher key is month and value is array
  const timelineList: {
    month: string;
    data: ITimelineData[];
  }[] = [];
  // convert Map to array
  timelineData.forEach((value, key) => {
    timelineList.push({month: key, data: value});
  });
  const dates: string[] = [];
  timelineList.forEach((item) => {
    const data = item.data;
    data.forEach((item) => {
      if (item.noteId === '') {
        // if order is independent
        const orders = item.orders;
        orders.forEach((order) => {
          // push order as individual cards
          dates.push(getDateStrFromFormat(getOrderDateTime(order) || '', 'MMM DD'));
        });
      } else {
        // push order with corresponding note
        dates.push(getDateStrFromFormat(item.date, 'MMM DD'));
      }
    });
  });
  const uniqueDates = [...new Set(dates)].filter(
    (item) => !item.includes('Invalid')
  );

  const results: any = [];

  timelineList.forEach((item) => {
    const data: any = [];
    uniqueDates.forEach((date) => {
      // filter orders by  uniqueDates
      const filteredData: any = [];
      item.data.forEach((item) => {
        if (item.noteId === '') {
          // if order is independent
          const orders = item.orders;
          const orderFound: any[] = [];
          orders.forEach((order) => {
            if (
              getDateStrFromFormat(getOrderDateTime(order) || '', 'MMM DD') === date
            ) {
              orderFound.push(order); // collect all orders for a date in orderFound
            }
            if (orderFound.length > 0) {
              filteredData.push({
                // push order as individual cards
                ...item,
                orders: orderFound,
              });
            }
          });
        } else {
          if (getDateStrFromFormat(item.date, 'MMM DD') === date) {
            // push order with corresponding note
            filteredData.push(item);
          }
        }
      });
      if (filteredData.length > 0) {
        data.push({groups: filteredData, day: date}); // push all orders for a date in data with respect to month
      }
    });
    results.push({month: item.month, data}); // push all orders for a month in results
  });
  return results;
};

const getFilteredRequestGroupDataByNameAndType = (
  orders: RequestGroup[],
  searchQuery: string,
  orderType?: string,
) => {
  const data: RequestGroup[] = [];
  orders.forEach((order) => {
    let type = '';
    (order?.extension || []).forEach((item: Extension) => {
      if (item.url === EXTENSION_URLS.requestGroupCategory || item.url === EXTENSION_URLS.orderCategory) {
        type = item?.valueString || '';
      }
    });
    if ((orderType && type == orderType) || !orderType) {
      const name = getOrderName(order);
      const hasMatchingSearch = name?.toLowerCase().includes(searchQuery.toLowerCase());
      if (hasMatchingSearch) {
        data.push({
          ...order,
        });
      }
    }
  });
  return data;
};

// export interface IOrderTimelineSelectedFilters {
//   filterCodes?: OrderFilterCode[];

//   createdByUserIds?: string[];
//   createdByUsers?: IUser[];

//   createdOnStartDate?: string;
//   createdOnEndDate?: string;

//   form?: IFormListState;
// }
// order?.action?.[0]?.resource?.report

export const getFilteredTimelineDataBasedOnStatus = (
  activeFilter: IOrderTimelineSelectedFilters | undefined,
  labRadOrders: RequestGroup[],
  medOrders: MedicationRequest[],
  notesData: IPatientNotesData[],
): {
  labRadOrders: RequestGroup[];
  medOrders: MedicationRequest[];
  notesData: IPatientNotesData[],
} => {
  const filter = activeFilter || {};
  let requestGroups = labRadOrders;
  let medicationRequests = medOrders;
  let notes = notesData;
  if (!Object.keys(filter).length) {
    return {
      labRadOrders: requestGroups,
      medOrders: medicationRequests,
      notesData: notes,
    };
  }
  if (filter.createdByUserIds?.length) {
    requestGroups = requestGroups.filter((order) => {
      const author = getAuthorId(order);
      if (author) {
        return filter.createdByUserIds?.includes(author);
      }
      return false;
    });
    medicationRequests = medicationRequests.filter((order) => {
      const author = getRequesterId(order);
      if (author) {
        return filter.createdByUserIds?.includes(author);
      }
      return false;
    });
  }
  if (filter.createdOnStartDate) {
    const createdStartDate = getMomentObj(filter.createdOnStartDate).add(-1, 'day').endOf('day');
    requestGroups = requestGroups.filter((order) => {
      return getMomentObj(getOrderDateTime(order) || new Date()).isAfter(createdStartDate);
    });
    medicationRequests = medicationRequests.filter((order) => {
      return getMomentObj(getOrderDateTime(order) || new Date()).isAfter(createdStartDate);
    });
  }
  if (filter.createdOnEndDate) {
    const createdEndDate = getMomentObj(filter.createdOnEndDate).add(1, 'day').startOf('day');
    requestGroups = requestGroups.filter((order) => {
      return getMomentObj(getOrderDateTime(order) || new Date()).isBefore(createdEndDate);
    });
    medicationRequests = medicationRequests.filter((order) => {
      return getMomentObj(getOrderDateTime(order) || new Date()).isBefore(createdEndDate);
    });
  }
  if (filter.form?.id) {
    notes = notes.filter((note) => {
      return note.formId === filter.form?.id;
    })
  }
  if (filter.filterCodes?.length) {
    requestGroups = requestGroups.filter((order) => {
      let canShow = true;
      const report = (order as IRequestGroup).action?.[0]?.resource?.report;
      filter.filterCodes?.forEach((code) => {
        switch (code) {
          case OrderFilterCode.submitted:
            canShow = canShow && order.status === 'active';
            break;
          case OrderFilterCode.notSubmitted:
            canShow = canShow && order.status === 'draft';
            break;
          case OrderFilterCode.failedToSubmit:
            canShow = canShow && (order.status === 'entered-in-error' || order.status === 'revoked');
            break;
          case OrderFilterCode.pendingReports:
            canShow = canShow && !report && order.status === 'active';
            break;
          case OrderFilterCode.reportsReceived:
            canShow = canShow && !!report;
            break;
          case OrderFilterCode.reviewed:
            canShow = canShow && (!!report && isAllReportViewedBy(report));
            break;
          case OrderFilterCode.pendingReview:
            canShow = canShow && (!!report && !isAllReportViewedBy(report));
            break;
        }
      })
      return canShow
    });
    medicationRequests = medicationRequests.filter((order) => {
      let canShow = true;
      const prescriptionStatus = getExtensionValue(order, EXTENSION_URLS.externalStatus) || order.status;
      filter.filterCodes?.forEach((code) => {
        switch (code) {
          case OrderFilterCode.submitted:
            canShow = canShow && ['eRxSent', 'FaxSent', 'Requested', 'EpcsSigned', 'PharmacyVerified'].includes(prescriptionStatus);
            break;
          case OrderFilterCode.notSubmitted:
            canShow = canShow && ['Entered', 'Printed', 'Sending', 'Deleted', 'Edited', 'ReadyToSign'].includes(prescriptionStatus);
            break;
          case OrderFilterCode.failedToSubmit:
            canShow = canShow && ['Error', 'EpcsError'].includes(prescriptionStatus);
            break;
          case OrderFilterCode.pendingReports:
            canShow = canShow && false;
            break;
          case OrderFilterCode.reportsReceived:
            canShow = canShow && false;
            break;
          case OrderFilterCode.reviewed:
            canShow = canShow && false;
            break;
          case OrderFilterCode.pendingReview:
            canShow = canShow && false;
            break;
        }
      })
      return canShow
    });
  }
  return {
    labRadOrders: requestGroups,
    medOrders: medicationRequests,
    notesData: notes,
  };
  // switch (activeFilter) {
  //   case FILTER_MENU_KEYS.COMPLETED:
  //     const completedLabRadOrders = labRadOrders.filter(
  //       (order) =>
  //         order?.status && RequestGroupStatus.completed.includes(order?.status)
  //     );
  //     const completedMedOrders = medOrders.filter(
  //       (order) =>
  //         order?.status &&
  //         MedicationRequestStatus.completed.includes(order?.status)
  //     );
  //     return {
  //       labRadOrders: completedLabRadOrders,
  //       medOrders: completedMedOrders,
  //     };
  //   case FILTER_MENU_KEYS.IN_PROGRESS:
  //     const inProgressLabRadOrders = labRadOrders.filter(
  //       (order) =>
  //         order?.status && RequestGroupStatus.inProgress.includes(order?.status)
  //     );
  //     const inProgressMedOrders = medOrders.filter(
  //       (order) =>
  //         order?.status &&
  //         MedicationRequestStatus.inProgress.includes(order?.status)
  //     );
  //     return {
  //       labRadOrders: inProgressLabRadOrders,
  //       medOrders: inProgressMedOrders,
  //     };
  //   case FILTER_MENU_KEYS.FAILED:
  //     const failedLabRadOrders = labRadOrders.filter(
  //       (order) =>
  //         order?.status && RequestGroupStatus.failed.includes(order?.status)
  //     );
  //     const failedMedOrders = medOrders.filter(
  //       (order) =>
  //         order?.status &&
  //         MedicationRequestStatus.failed.includes(order?.status)
  //     );
  //     return {
  //       labRadOrders: failedLabRadOrders,
  //       medOrders: failedMedOrders,
  //     };
  // }
  // return {
  //   labRadOrders: [],
  //   medOrders: [],
  // }
};
