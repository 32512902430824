import {Dropdown, Menu} from 'antd';
import {Button, Divider, HStack, Icon, Spacer, View, VStack} from 'native-base';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {BUTTON_TYPE} from '../../../../constants';
import {Colors} from '../../../../styles';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import {SearchBar} from '../../../common/SearchBar';
import PlusIcon from '../../../common/Svg/PlusSvg';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';

export enum AppointmentTypeAction {
  singleAppointment = 'SingleAppointment',
  groupAppointment = 'GroupAppointment',
  groupSessionAppointment = 'GroupSessionAppointment',
  workflowAppointment = 'WorkflowAppointment',
}

export const AppointmentTypeTopBar = (props: {
  onSearch: (text: string) => void;
  onAction: () => void;
}) => {
  const intl = useIntl();

  return (
    <View>
      <View paddingX={4} paddingY={2}>
        <HStack>
          <VStack justifyContent={'center'}>
            <View>
              <DisplayText textLocalId="appointmentTypes" textType="Heading" />
            </View>
          </VStack>
          <Spacer />
          <VStack>
            <HStack justifyContent={'flex-end'} alignItems="center">
              <VStack justifyContent={'flex-end'}>
                <View marginRight={5}>
                  <SearchBar
                    onChange={(value: string) => {
                      props.onSearch(value);
                    }}
                  />
                </View>
              </VStack>
              <HStack>
                <FoldButton
                  nativeProps={{
                    variant: BUTTON_TYPE.PRIMARY,
                    onPress: () => {
                      props.onAction();
                    },
                    leftIcon: (
                     <PlusIcon />
                    ),
                  }}
                  customProps={{
                    btnText: intl.formatMessage({id: 'new'}),
                    withRightBorder: false,
                  }}
                ></FoldButton>
              </HStack>
              {/* <Dropdown overlay={menu} placement="bottomRight">
                <Button
                  rounded="3xl"
                  height="9"
                  variant="solid"
                  endIcon={<Icon as={AntIcon} name={'down'} size="4" />}
                >
                  {intl.formatMessage({id: 'new'})}
                </Button>
              </Dropdown> */}
            </HStack>
          </VStack>
        </HStack>
      </View>

      <Divider />
    </View>
  );
};

export default AppointmentTypeTopBar;
