import React, { useEffect, useState } from 'react'
import { MultiSelectAction } from '../TaskInterfaces';
import { ToastType } from '../../../utils/commonViewUtils';
import { EventBus } from '../../../utils/EventBus';
import { ITask } from '../../common/CareDashboard/CareDashboardInterfaces';
import { TASK_EVENTS, TASK_MODULE_CODE } from '../../common/CareDashboard/CareDashboardConstants';

interface IUseMultiTaskManagerInterface {
  showToast?: (message: string, type: ToastType, additionalData?: any) => void;
}

const useMultiTaskManager = (args: IUseMultiTaskManagerInterface) => {
  // Global constants
  const eventBus = EventBus.getEventBusInstance();

  // States
  const [multiSelectData, setMultiSelectData] = useState<{
    selected: ITask[],
    enabled: boolean;
  }>({
    selected: [],
    enabled: false
  });

  // Life cycle methods
  useEffect(() => {
    eventBus.addEventListener(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, onMultiTaskStateChange, { moduleCode: TASK_MODULE_CODE });

    return () => {
      eventBus.removeEventListenerByEventName(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, onMultiTaskStateChange, { moduleCode: TASK_MODULE_CODE })
    }
  }, []);

  // Event listeners
  const onMultiTaskStateChange = (data: {action: MultiSelectAction; tasks: ITask[];}) => {
    if (data?.action) {
      handleMultiSelectActions(data.action, data.tasks || [])
    }
  }

  // Public methods
  const handleMultiSelectActions = (action: MultiSelectAction, tasks: ITask[]) => {
    switch (action) {
      case MultiSelectAction.disableMultiSelect:
        setMultiSelectData((prev) => ({
          ...prev,
          enabled: false,
          selected: [],
        }));
      break;

      case MultiSelectAction.enableMultiSelect:
        setMultiSelectData((prev) => ({
          ...prev,
          enabled: true,
          selected: [],
        }));
      break;

      case MultiSelectAction.selectTask:
      case MultiSelectAction.selectAllTasks:
        const newTasks = tasks || [];
        if (newTasks.length) {
          setMultiSelectData((prev) => {
            const oldSelected = prev.selected;
            const oldTaskIds = oldSelected.map(item => item.id);
            const updatedNewTasks = newTasks.filter(item => !oldTaskIds.includes(item.id));
            return {
              ...prev,
              selected: prev.selected.concat(updatedNewTasks),
            }
          });
        }
      break;

      case MultiSelectAction.unSelectTask:
      case MultiSelectAction.unSelectAllTask:
        const newUnSelectedTasks = tasks || [];
        if (newUnSelectedTasks.length) {
          const unselectedTaskIds = newUnSelectedTasks.map(item => item.id);
          setMultiSelectData((prev) => {
            const oldSelected = prev.selected;
            return {
              ...prev,
              selected: oldSelected.filter(item => !unselectedTaskIds.includes(item.id)),
            }
          });
        }
      break;
    }
  }


  return {
    multiSelectData,
    handleMultiSelectActions,
  };
}

export default useMultiTaskManager
