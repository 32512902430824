export { default as Configs } from './Configs';
export * from './EntityAndEventsConst';
export {
  MLOV_CATEGORY,
  PERSON_CONTACT_TYPE_CODES
} from './MlovConst';
export {
  OMNI_VIEW_CLIN_SECTION_BTNS,
  OMNI_VIEW_PERSON_SECTION_BTNS, OMNI_VIEW_TOP_BAR_BTN_CONST
} from './OmniViewConsts';
export * from './StringConst';
export {
  NETWORK_EVENTS,
  NEWWORK_EVENT_CODES
} from './VisitHistoryConst';
export { months } from './dateConst';

export const TRACKING_RESOURCE_TYPE = {
  VITALS: 'VITALS',
  LAB_TEST: 'LAB_TEST'
}

export const UNITY_AI = {
  UNITY_AI_CUSTOM_FIELD: 'UNITY_AI_CUSTOM_FIELD',
  CONTEXT: 'context',
  UNITY_OUTPUT: 'unityOutput',
  UNITY_AI_CUSTOM: 'unityAICustom',
  MULTI: 'MULTI',
  SINGLE: 'SINGLE',
  STRING: 'STRING',
  INTEGER: 'INTEGER',
  BOOLEAN: 'BOOLEAN',
  SINGLE_SELECT: 'Single Select',
  MULTI_SELECT: 'Multi Select',
  OUTPUT: 'output'
}
export const CARE_PROGRAM_WIDGET_ALLOWED_CONTACT_TYPE_CODES = ['CUSTOMER']
