import {Modal, useToast, View, VStack} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {BUTTON_TYPE} from '../../../../../constants';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {IMediaPreviewModalProps} from '../interfaces';
import MediaPreview from './MediaPreview';
import {styles} from './MediaPreviewStyle';
import { showToast, ToastType } from '../../../../../utils/commonViewUtils';

const {Content, Header, CloseButton, Body} = Modal;

const MediaPreviewModal = (props: IMediaPreviewModalProps) => {
  const {previewData, onClose} = props;
  const intl = useIntl();
  const toast = useToast();

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(previewData.mediaUrl as string);
    showToast(
      toast,
      intl.formatMessage({id: 'copyClickboardMsg'}),
      ToastType.info,
    );
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Content style={styles.modalContainer}>
        <CloseButton />
        <Header>
          <DisplayText textLocalId="preview" textType="Heading" />
        </Header>

        <Body style={styles.modalBody}>
          <VStack>
            <MediaPreview {...previewData} mediaData={props.mediaData} />
            <View style={styles.copyBtnView}>
                <FoldButton
                  nativeProps={{
                    variant: BUTTON_TYPE.PRIMARY,
                    onPress: () => {
                      handleCopyToClipboard()
                    },
                    leftIcon: <AntIcon name="copy1" size={16} />
                  }}
                  customProps={{
                    btnText: intl.formatMessage({
                      id:'copyUrl',
                    }),
                    withRightBorder: false,
                  }}
                ></FoldButton>
            </View>
          </VStack>
        </Body>
      </Content>
    </Modal>
  );
};

export default MediaPreviewModal;
