import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  Legend,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import AxisLabel from '../AxisLabel';

import React from 'react';

export interface IAreaChartProps {
  width?: string;
  height?: string;
  color?: string;
  data?: any;
}

export default function AreaCartesianGridChart({
  pOverTimeMock,
  renderLegend,
}: {
  pOverTimeMock: any;
  renderLegend: any;
}) {
  return (
    <ResponsiveContainer width="100%" height={450}>
      <AreaChart
        height={450}
        data={(pOverTimeMock as any)
          .map((d: any) => d.months)
          .flat(3)
          .filter((md: any) => md.field === 'Glucose')}
      >
        <defs>
          <linearGradient id={'colorPV'} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={'#8884d8'} stopOpacity={0.8} />
            <stop offset="95%" stopColor={'#8884d8'} stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis
          label={
            <AxisLabel x={12} y={225} width={0} height={0}>
              Glucose (mg/dL)
            </AxisLabel>
          }
          dataKey="value"
        />
        <Legend verticalAlign="top" content={renderLegend} />
        <ReferenceLine
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            paddingTop: '1rem',
          }}
          y={100}
          stroke="#000"
        >
          <Label
            position="insideTopRight"
            value={'Target: Glucose below 101.0 mg/dL'}
            fill="#53389E"
          />
        </ReferenceLine>
        <Tooltip />
        <Area
          type="monotone"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.3}
          dataKey="value"
          id="colorPV"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
