export const FILTER_TYPE_CODES = {
  EMPLOYER: 'EMPLOYER',
  DATE_RANGE: 'DATE_RANGE',
  PRIORITY: 'PRIORITY',
  LABELS: 'LABELS',
  TASK_POOL: 'TASK_POOL',
  PATIENT_CONTEXT: 'PATIENT_CONTEXT',
  RESET: 'RESET',
} as const;

export const PATIENT_CONTEXT_VALUE = [
  {code: 'Both', value: 'Both'},
  {code: 'Yes', value: 'True'},
  {code: 'No', value: 'False'},
];

export const TASK_ANALYTICS_DRILL_DOWN_HEADING: Record<string, string> = {
  TASK_ANALYTICS_TASK_DISTRIBUTION_BY_ASSIGNEE: 'Task Distribution',
  TASK_ANALYTICS_AVERAGE_TIME_TO_RESOLUTION_BY_ASSIGNEE:
    'Average Time to Resolution',
  TASK_ANALYTICS_AVERAGE_TIME_TO_RESOLUTION_BY_TASK_POOL:
    'Average Time to Resolution',
  TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_ASSIGNEE:
    'On Time vs. After Due Date',
  TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_TASK_POOL:
    'On Time vs. After Due Date',
};