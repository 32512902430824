import { Popover } from 'antd';
import { Divider, HStack, Skeleton, Text, View, VStack } from 'native-base';
import React, { useEffect, useState } from 'react';
import {
  getWorkflowByWorkflowMasterId, getWorkflowMasterIdByContactId
} from '../../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { MemberGroupsProps } from '../../../interfaces';

const MemberAutomation = (props: MemberGroupsProps) => {
  const {contactData} = props;
  const [state, setState] = useState({
    loading: true,
    error: false,
    workflowList: [] as any[],
  });

  const isCurrentMenuLast = (index: number, workflowList: any[]): boolean => {
    return index < workflowList.length - 1;
  };

  const getWorkFlowList = async () => {
    try {
      const workflowMasterIdResponse = await getWorkflowMasterIdByContactId(
        (contactData?.id || '').toString()
      );
      const serviceLogs: any[] = workflowMasterIdResponse.serviceLogs || [];
      if (serviceLogs?.length == 0) {
        setState((prev) => {
          return {
            ...prev,
            loading: false,
            workflowList: [],
          };
        });
      } else {
        const workflowMasterIds = serviceLogs
          .map((log) => log?.workflowMasterId || '')
          .filter((item) => !!item);
        const workflowResponse = await getWorkflowByWorkflowMasterId(
          workflowMasterIds
        );
        const workflows = workflowResponse.workflows || [];
        setState((prev) => {
          return {
            ...prev,
            loading: false,
            workflowList: workflows,
          };
        });
      }
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          error: true,
          workflowList: [],
        };
      });
    }
  };

  useEffect(() => {
    getWorkFlowList();
  }, []);

  return (
    <HStack
      style={{
        flexWrap: 'wrap',
      }}
    >
      {state.loading && <Skeleton.Text lines={1} />}

      {state.error && !state.loading && <Text>-</Text>}

      {!state.loading && state.workflowList && (
        <>
          <Popover
            content={
              <VStack>
                {state.workflowList.map((workflow, index) => {
                  return (
                    <View
                      style={{
                        marginVertical: 2,
                      }}
                    >
                      <Text
                        style={{
                          marginHorizontal: 4,
                          marginVertical: 2,
                        }}
                      >
                        {workflow.name || ''}
                      </Text>
                      {isCurrentMenuLast(index, state.workflowList) && (
                        <Divider />
                      )}
                    </View>
                  );
                })}
              </VStack>
            }
          >
            <Text>{`${
              state.workflowList.length > 0 ? state.workflowList.length : '-'
            }`}</Text>
          </Popover>
        </>
      )}
    </HStack>
  );
};

export default MemberAutomation;
