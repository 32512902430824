import {Divider, View} from 'native-base';
import React from 'react';
import {Colors} from '../../../../../../../styles';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';

const ProductLayoutView = () => {
  return (
    <View
      style={{
        borderColor: Colors.Custom.Gray200,
        borderWidth: 1,
        borderRadius: 8,
        backgroundColor: 'white',
        margin: 24,
        flexDirection: 'row',
        overflow: 'hidden',
      }}
    >
      <View flex={0.7} style={{padding: 32}} nativeID="leftContainer">
        <View nativeID="titleView">
          <DisplayText
            textLocalId="arrangeCardDetails"
            size={'lgBold'}
            extraStyles={{color: Colors.Custom.Gray700}}
          />
          <DisplayText
            textLocalId="arrangeCardDescription"
            size={'xsNormal'}
            extraStyles={{color: Colors.Custom.Gray700}}
          />
        </View>
      </View>
      <Divider
        orientation={'vertical'}
        height={'100%'}
        backgroundColor={Colors.Custom.Gray200}
      />
      <View
        flex={0.3}
        style={{padding: 32, backgroundColor: Colors.Custom.Gray50}}
        nativeID="rightView"
      >
        left side
      </View>
    </View>
  );
};

export default ProductLayoutView;
