import _ from 'lodash';
import moment from 'moment';
import {
  ANALYTICS_RESOURCE,
  DISPLAY_SLASH_DATE_FORMAT,
  DISPLAY_TYPE,
  SECTION_LIST,
  keyVisibleForAccountDashboard,
  monthsArray,
  nonEntryPropertyList
} from '../../../../../constants';
import {getFormattedDateStringInTimeZone} from '../../../../../utils/DateUtils';
import {getMinAndMaxFromList} from '../../../../../utils/commonUtils';
import {ISavingDetailView} from '../SavingDrawer/interface';
import {
  IAppliedFilter,
  IBarChartData, IContactMembershipsData, IExpenseParetoPercentileResult, ILineGraphData,
  IPageResponse,
  IPageSection,
  IPageView,
  IPieChartData,
  IRawSection,
  IResource,
  ISectionListItem
} from '../interface';
import {WIDGET_DISPLAY_TYPE} from '../tempDataFile';
import {COST_TYPE_WORDS, OFFICE_HOURS_CONST, WidgetLegendMap} from './EmployerConst';
import {TEMP_WIDGETS} from '../TempAppointmentDummyData';
import { handleResponseDataAddMonth } from './WidgetUtils';
import { AppliedGroupFilters } from '../../../ContentManagement/CampaignManagement/GroupDetails/interface';
import {getCombinedPairForHorizontalStackBar} from '../SectionAndWidget/utils';


export const getFormattedDataForPeiChart = (
  resourceDataArray: IResource[],
  resourceCode: string
): IPieChartData[] => {
  let resourceData = resourceDataArray?.length ? [...resourceDataArray] : []
  if (resourceCode === ANALYTICS_RESOURCE.ED_UTILIZATION_PERCENT) {
    const resourceDataObj = resourceData[0]
    const returnArray: IPieChartData[] = [];
    Object?.entries(resourceDataObj || {} as any)?.forEach(([key, value]) => {
      if (nonEntryPropertyList.includes(key)) {
        return;
      }
      if (value) {
        const temp = {
          type: key,
          value: Math.round(value),
          code: key
        };
        returnArray.push(temp);;
      }
    })
    return returnArray;
  }
  if (resourceCode === ANALYTICS_RESOURCE.ED_UTILIZATION_EMPLOYEE_VS_DEPENDENT) {
    const returnArray: IPieChartData[] = [];
    if (resourceData?.length) {
      resourceData?.forEach((item) => {
        const type = item.displayText || item.employeeType
        if (item.countNum) {
          const temp = {
            type: type,
            value: item.countNum || 0,
            code: type
          };
          returnArray.push(temp);
        }
      });
    }
    return returnArray;
  }
  if (resourceCode === ANALYTICS_RESOURCE.TOP_DIAGNOSIS) {
    const sortedArray = resourceData.sort((a, b) => {
      return b.countNum - a.countNum
    })
    const topDiagnosis = sortedArray.slice(0, 10)
    resourceData = [...topDiagnosis]
  }
  const returnArray: IPieChartData[] = [];
  if (resourceData?.length) {
    resourceData?.map((item) => {
      if (item.countNum) {
        const temp = {
          type: item.displayText,
          value: item.countNum || 0,
          code: item?.code
        };
        returnArray.push(temp);
      }
    });
  }
  return returnArray;
};

export const getFormattedDataForBarChart = (
  resourceData: IResource[],
  appliedFilter: IAppliedFilter | undefined,
  displayType: string,
  resourceCode?: string,
  isAccountDashboard?: boolean
): IBarChartData[] => {
  let returnArray: IBarChartData[] = [];
  if (resourceData?.length) {
    if(
      resourceCode === ANALYTICS_RESOURCE.MEMBERSHIP_REVENUE_GROWTH //not exe
      || resourceCode === ANALYTICS_RESOURCE.MEMBERSHIP_NET_GROWTH //handled
      || resourceCode === ANALYTICS_RESOURCE.MEMBERSHIP_INACTIVATED // handled
      || resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_LOGGED_IN //handled
      || resourceCode === ANALYTICS_RESOURCE.CRM_ACTIVITY // Maha
      || resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION //handled
      || resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS //handled
      || resourceCode === ANALYTICS_RESOURCE.ENCOUNTER_TYPES //handled
      || resourceCode === ANALYTICS_RESOURCE.BILLING_CPT_CODES //handled
      || resourceCode === ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE // handled
      || resourceCode === ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING // handled
      || resourceCode === ANALYTICS_RESOURCE.SMS_MESSAGING //handled
      || resourceCode === ANALYTICS_RESOURCE.VIDEO_CALLING //handled
      || resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED
      ) { // Maha
      resourceData = handleResponseDataAddMonth(resourceData, resourceCode, appliedFilter)
    }
    if (resourceCode === ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT || resourceCode === ANALYTICS_RESOURCE.COMMUNICATION_STATS) {
      const returnData: any = [];
      resourceData?.forEach((item: any) => {
        const type = item.code?.includes(OFFICE_HOURS_CONST.OUT_OF_OFFICE_HOURS)
          ? OFFICE_HOURS_CONST.OUT_OF_OFFICE_HOURS
          : OFFICE_HOURS_CONST.OFFICE_HOURS;
        const findItemByDate = returnData?.find((returnObj: any) => {
          return (
            item?.monthDt?.value === returnObj?.monthDt?.value
          );
        });
        if (findItemByDate?.monthDt?.value) {
          // findItemByDate['countNum'] = findItemByDate?.countNum + item.countNum;
          findItemByDate[type] = (findItemByDate[type] || 0) + (item.countNum || 0)
          findItemByDate.dataList.push(item)
        } else {
          const newObject: any = {
            monthDt: item.monthDt,
            dataList: [item],
            monthDate: item?.monthDt?.value || item.month,
            [type]: (item.countNum || 0)
          };
          returnData.push(newObject);
        }
      });
      // const sortedArray = returnArray.sort((a: IBarChartData, b: IBarChartData) => {
      //   const date1 = new Date(a.date || '')
      //   const date2 = new Date(b.date || '')
      //   return date1.getTime() - date2.getTime()
      // });
      // return sortedArray;
      return returnData;
    } else if (resourceCode === ANALYTICS_RESOURCE.ED_UTILIZATION_EMPLOYEE_VS_DEPENDENT) {
      resourceData?.map((item) => {
        const temp: IBarChartData = {
          xField: item.countNum || 0,
          yField: item.employeeType,
        };
        returnArray.push(temp);
      });
      return returnArray
    }
    if (displayType === DISPLAY_TYPE.BAR_CHART) {
      resourceData?.map((item) => {
        const temp: IBarChartData = {
          action: item.displayText || '',
          pv: item.countNum || 0,
        };
        returnArray.push(temp);
      });
    } else if (
      displayType === DISPLAY_TYPE.BAR_CHART_WITH_STACK_COLUMN ||
      displayType === DISPLAY_TYPE.DETAIL_WITH_BAR_CHART_WITH_STACK_COLUMN
    ) {
      resourceData?.map((item) => {
        const type: string = item?.apptType || item?.employeeType || item?.senderType || item?.displayText || item?.type || item?.code || '';
        if (!isAccountDashboard && keyVisibleForAccountDashboard.includes(type)) {
          return;
        }
        const date = getFormattedDateStringInTimeZone(
          item?.monthDt?.value,
          'MMM YY'
        );
        const getDisplayText = codeToDisplayText(
          resourceCode || '',
          item.type || ''
        );
        const displayText =
          resourceCode === ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE
            ? getDisplayText
            : type;
        const temp: IBarChartData = {
          month: date,
          type: displayText,
          value: item?.countNum || 0,
          date: item?.monthDt?.value,
          filterCode:
            resourceCode === ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE
              ? type
              : item?.filterCode,
        };
        returnArray.push(temp);
      });
      returnArray = returnArray?.sort((item1: any, item2: any) => {
        return item2?.type?.localeCompare(item1?.type)
      })

    } else if (displayType === DISPLAY_TYPE.BAR_CHART_WITH_GROUP_COLUMN) {
      resourceData?.map((item) => {
        const temp: IBarChartData = {
          month: item.month.slice(0, 3),
          type: item.employeeType,
          value: item.countNum || 0,
          date: item?.monthDt?.value
        };
        returnArray.push(temp);
      });
      returnArray = returnArray?.sort((item1: any, item2: any) => {
        return item2?.type?.localeCompare(item1?.type)
      })
    } else if (displayType === DISPLAY_TYPE.BAR_CHART_WITH_STACK_AND_GROUP_COLUMN) {
      resourceData?.map((item) => {
        const type: string = item?.displayText || '';
        const name: string = item?.type || '';
        if (!isAccountDashboard && keyVisibleForAccountDashboard.includes(type)) {
          return;
        }
        const date = getFormattedDateStringInTimeZone(item?.monthDt?.value, 'MMM YY')
        const temp: IBarChartData = {
          month: date,
          type: type,
          name: name,
          value: item?.countNum || 0,
          colorType: item?.colorType,
          code: item?.code,
          date: item?.monthDt?.value
        };
        returnArray.push(temp);
      });
      returnArray = returnArray?.sort((item1: any, item2: any) => {
        return item2?.type?.localeCompare(item1?.type)
      })
    }
  }
  const sortedArray = returnArray.sort((a: IBarChartData, b: IBarChartData) => {
    const date1 = new Date(a.date || '')
    const date2 = new Date(b.date || '')
    return date1.getTime() - date2.getTime()
  });
  return sortedArray;
};

export const getFormattedDataForMixChart = (
  appliedFilter: IAppliedFilter | undefined,
  resourceData: {
    columnStackDataList: IResource[] | any;
    lineDataList: IResource[] | any;
  },
  displayType: string,
  resourceCode: string,
  isAccountDashboard?: boolean
): any => {
  const columnStackDataList: IBarChartData[] = [];
  const lineDataList: IBarChartData[] = [];
  if (
    displayType === DISPLAY_TYPE.MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE ||
    displayType === DISPLAY_TYPE.DETAIL_WITH_MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE
  ) {
    if (resourceData?.columnStackDataList?.length) {
      resourceData.columnStackDataList = handleResponseDataAddMonth(resourceData?.columnStackDataList, resourceCode, appliedFilter) 
      resourceData.columnStackDataList?.map((item: any) => {
        const type: string = item?.displayText || '';
        if (!isAccountDashboard && keyVisibleForAccountDashboard.includes(type)) {
          return;
        }
        if (resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_COUNT && type === 'Inactive Members') {
          return;
        }
        const date = getFormattedDateStringInTimeZone(item?.monthDt?.value, 'MMM YY')
        const temp: IBarChartData = {
          month: date,
          type: type,
          value: item?.countNum || 0,
          date: item?.monthDt?.value,
          dateValue: item?.monthDt?.value,
          filterCode: item?.filterCode
        };
        columnStackDataList.push(temp);
      });
    }

    if (resourceData?.lineDataList?.length) {
      resourceData?.lineDataList?.map((item: any) => {
        const type: string = item?.displayText || '';
        if (!isAccountDashboard && keyVisibleForAccountDashboard.includes(type)) {
          return;
        }
        const date = getFormattedDateStringInTimeZone(item?.monthDt?.value, 'MMM YY')
        const temp: IBarChartData = {
          month: date,
          type: type,
          value: item?.countNum || 0,
          date: item?.monthDt?.value
        };
        lineDataList.push(temp);
      });
    }
  }
  return {
    columnStackDataList,
    lineDataList
  }
};

export const getFormattedDataForLineGraph = (
  sectionData: ISectionListItem,
  resourceData: IResource[]
): ILineGraphData[] => {
  const returnArray: ILineGraphData[] = [];
  if (resourceData?.length) {
    const mutableResourceData = [...resourceData];
    const currentData = moment().month();
    const currentMonth = monthsArray[currentData];
    const lastMonth = monthsArray[currentData - 1];
    const lastToLastMonth = monthsArray[currentData - 2];
    let currentMonthNum = 0;
    let lastMonthNum = 0;
    let lastToLastMonthNum = 0;
    if (sectionData.resourceCode === ANALYTICS_RESOURCE.CHAT_MESSAGING) {
      mutableResourceData?.forEach((item) => {
        if (item.month === currentMonth) {
          currentMonthNum = currentMonthNum + (item.countNum || 0);
        } else if (item.month === lastMonth) {
          lastMonthNum = lastMonthNum + (item.countNum || 0);
        } else if (item.month === lastToLastMonth) {
          lastToLastMonthNum = lastToLastMonthNum + (item.countNum || 0);
        }
      });
      const currentData = mutableResourceData.find(item => {
        return item.month === currentMonth
      })?.monthDt?.value || ''
      const lastData = mutableResourceData.find(item => {
        return item.month === lastMonth
      })?.monthDt?.value || ''
      const lastToMonthData = mutableResourceData.find(item => {
        return item.month === lastToLastMonth
      })?.monthDt?.value || ''
      returnArray.push(
        {
          Date: getFormattedDateStringInTimeZone(
            lastToMonthData || '',
            DISPLAY_SLASH_DATE_FORMAT
          ),
          scales: Number(lastToLastMonthNum),
        },
        {
          Date: getFormattedDateStringInTimeZone(lastData || '', DISPLAY_SLASH_DATE_FORMAT),
          scales: Number(lastMonthNum),
        },
        {
          Date: getFormattedDateStringInTimeZone(
            currentData || '',
            DISPLAY_SLASH_DATE_FORMAT
          ),
          scales: Number(currentMonthNum),
        }
      );
    } else {
      resourceData?.map((item) => {
        // if (item.month === currentMonth || item.month === lastMonth || item.month === lastToLastMonth) {

        // }
        const temp: ILineGraphData = {
          Date: getFormattedDateStringInTimeZone(item?.monthDt?.value || '', DISPLAY_SLASH_DATE_FORMAT),
          scales: Number(item.countNum) || Number(item.count) || 0,
        };
        returnArray.push(temp);
      });
    }
    const sortedArray = returnArray.sort((a: ILineGraphData, b: ILineGraphData) => {
      const date1 = new Date(a.Date)
      const date2 = new Date(b.Date)
      return date1.getTime() - date2.getTime()
    });
    return sortedArray;
  }
  return returnArray;
};

// export const getFormattedDashboardPage = (
//   pageData: IPageResponse
// ): IPageView => {
//   let returnData: IPageView = {} as IPageView;
//   const pageDataObject: IPageResponse = {...pageData}
//   pageDataObject.pageSections.map((item: any) => {
//     if (item.sectionCategory?.code === SECTION_LIST.SAVINGS) {
//       const tempData = [...TEMP_WIDGETS];
//       let isWidgetsAlreadyExists = false;
//       if (item.childSections?.length) {
//         for (let index = 0; index < item.childSections.length; index++) {
//           const singleItem = item.childSections[index];
//           if (singleItem?.resource?.code === ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING
//             || singleItem?.resource?.code === ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING) {
//             isWidgetsAlreadyExists = true;
//             break;
//           }
//         }
//       }
//       if (!isWidgetsAlreadyExists) {
//         item.childSections = [...item.childSections, ...tempData];
//       }
//     }
//     return item
//   })
//   returnData = {
//     id: pageData.id,
//     sectionData: getFormattedSection(pageDataObject.pageSections),
//   };
//   return JSON.parse(JSON.stringify(returnData));
//   // return returnData;
// };




export const getFormattedDashboardPage = (
  pageData: IPageResponse
): IPageView => {
  let returnData: IPageView = {} as IPageView;
  const pageDataObject: IPageResponse = {...pageData}
  returnData = {
    id: pageData.id,
    sectionData: getFormattedSection(pageDataObject.pageSections),
  };
  return returnData;
};

export const getFormattedSection = (
  sectionData: IPageSection[]
): ISectionListItem[] => {
  const returnData: ISectionListItem[] = [];
  sectionData.map((item, index) => {
    const childSections: any = item?.childSections?.length ? item?.childSections : []
    let temp: ISectionListItem = {
      id: item.id || '',
      name: item.heading,
      subHeading: item.subHeading,
      isPrintable: true,
      // printClassName: ((index+1) % 2) == 0 ? 'print' : 'not-print',
      childSectionWidget: childSections?.length
        ? getFormattedSection(childSections)
        : [],
      isVisible: item.isVisible,
      sequence: item.sequence,
      additionalAttributes: item.additionalAttributes,
      isDisabled: false,
      appliedFilter: item.appliedFilter
    };
    if (item?.resource?.code) {
      temp = {
        ...temp,
        resourceCode: item?.resource?.code,
        resourceId: item?.resourceId,
        currentDisplayType:
          WIDGET_DISPLAY_TYPE[item?.resource?.code]?.currentDisplayType,
      };
    }
    if (item.parentSectionId) {
      temp = {
        ...temp,
        parentSectionId: item.parentSectionId
      }
    }
    if (item?.sectionCategory?.code) {
      temp = {
        ...temp,
        sectionCategory: item.sectionCategory.code,
      };
    }
    returnData.push(temp);
  });
  return returnData;
};

export const getFormattedDataForListingWidget = (
  sectionData: ISectionListItem,
  resource: any
) => {
  const mutableResource = [...resource];
  const returnArray: any = [];
  if (mutableResource?.length) {
    const sortResource = mutableResource?.sort(
      (a, b) =>
        parseFloat(b?.countNum.toString()) - parseFloat(a?.countNum.toString())
    );
    const topTenCount = sortResource?.slice(0, 10);
    topTenCount.map((item) => {
      const temp = {
        name: item.name || item.medText || item.displayText || '',
        count: item.countNum || 0,
        patientCount: item.patientCount || 0,
        code: item?.code
      };
      returnArray.push(temp);
    });
    return returnArray;
  }
  return returnArray;
};



export const getFlatListPageSections = (sectionsList: IPageSection[], userUuid: string) => {
  const flatSectionList: IPageSection[] = [...sectionsList];

  const tempList = flatSectionList.map((item) => {
    let tempData: IRawSection | any = {
      heading: item.heading,
      subHeading: item.subHeading,
      sectionCategoryId: item.sectionCategoryId,
      pageId: item.pageId,
      type: item.type,
      sequence: item.sequence,
      resourceId: item.resourceId,
      appliedFilter: item.appliedFilter
    }
    if (item.childSections) {
      const updatedSection = getFlatListPageSections(item.childSections, userUuid)
      tempData = {...tempData, childSections: {data: updatedSection}}
    }
    Object.keys(tempData).forEach((key) => {
      if (tempData[key] === null) {
        delete tempData[key];
      }
    });
    return tempData
  })

  return tempList
}


export const getMembersList = (mem: any): any => {
  const member = {...mem}; // copy
  delete member.childSections;
  delete member?.resource
  delete member?.sectionCategory
  delete member?.id
  Object.keys(member).forEach(key => {
    if (member[key] === null) {
      delete member[key];
    }
  });

  if (!mem.childSections || !mem.childSections.length) {
    return member; // return copied
  }

  // return copied, but pass original to flatMapDeep
  return [
    member,
    _.flatMapDeep(mem.childSections, getMembersList)
  ];
}

export const updatePageIdToList = (pageId: string, pageSections: IRawSection[], userUuid: string) => {
  const mutablePageSection = pageSections;
  mutablePageSection.forEach((item: any) => {
    item['pageId'] = pageId

    if (item?.childSections?.data) {
      updatePageIdToList(pageId, item.childSections.data, userUuid)
    }
  })
  return mutablePageSection
}

export const getHorizontalTrendBarChartData = (resourceCode: string, data: any) => {
  let mutableResource: any[] = [];
  if (data?.topChronicConditions?.length) {
    mutableResource = [...data?.topChronicConditions]
  } else if (data.length) {
    mutableResource = [...data]
  }
  const returnArray: any = [];
  if (
    resourceCode === ANALYTICS_RESOURCE.TOP_DIAGNOSIS ||
    resourceCode === ANALYTICS_RESOURCE.CHRONIC_RISKS_TREND
  ) {
    mutableResource?.map((item) => {
      if (item.name || item.medText || item.displayText) {
        const temp = {
          count: item?.countNum || 0,
          name: item?.displayText || item?.code || '',
          code: item.code || item?.displayText || '',
          type: item.type || item.employeeType,
        };
        returnArray.push(temp);
      }
    });
    return returnArray?.sort?.((a: any, b: any) => {
      return b.count - a.count
    })
  } else {
    mutableResource?.map((item) => {
      if (item.name || item.medText || item.displayText || item?.code) {
        if (item?.countNum) {
          const temp = {
            count: item?.countNum || 0,
            name: item?.displayText || item?.code || '',
            code: item.code || item?.displayText || '',
            flowType: item.flowType || '',
            workflowMasterId: item.workflowMasterId || ''
          };
          returnArray.push(temp);
        }
      }
    });
  }
  const finalResult = returnArray
  finalResult?.sort?.((a: any, b: any) => {
    return b.count - a.count
  })
  return finalResult?.slice(0, 10);
}

export const formateDataForCustomSatisfaction = (data: any[]) => {
  const mutableResource = data?.length ? [...data] : [];
  const returnArray: any = [];
  mutableResource.map((item) => {
    const temp = {
      value: item?.contactAnsCount,
      type: item?.label || '',
      contactUuidList: item?.contactUuidList || [],
      singleData: item,
    }
    returnArray.push(temp)
  })
  return returnArray
}

export const getTopResponseObj = (data: any[]) => {
  const sorted = data?.sort((first, second) => {
    return second?.contactAnsCount - first?.contactAnsCount
  })
  return sorted?.length ? sorted[0] : {} as any
}

export const getParseIntToFixed = (number: any) => {
  // let test = (number)*10
  // test = parseFloat(test.toFixed(2))
  // return `${test}/10`
  return number
}

export const getReplyInPercent = (totalCount: number, count: number) => {
  const percentage = (count / totalCount) * 100
  return parseInt(percentage as any);
}

export const getFormattedDataForParetoCurves = (resourceCode: any,
  data: IExpenseParetoPercentileResult
) => {
  const tempArray = [] as any[];
  let totalCount = 0;
  Object.keys(data || {}).map(function (key) {
    let name = '';
    totalCount = totalCount + data[key].count;
    const totalClaimCost = data[key].totalClaimCost;
    const totalUsage = data[key].totalCount;
    const visits = data[key].visits;
    const labOrders = data[key].labOrders;
    const imagingOrders = data[key].imagingOrders;
    if (resourceCode === ANALYTICS_RESOURCE.EXPENSE_PARETO_CURVES_TREND) {
      if (key.includes('One')) {
        name = `Top 1% ($${(totalClaimCost || 0).toFixed(2)})`;
      } else if (key.includes('Five')) {
        name = `Top 5% ($${(totalClaimCost || 0).toFixed(2)})`;
      } else if (key.includes('Ten')) {
        name = `Top 10% ($${(totalClaimCost || 0).toFixed(2)})`;
      } else {
        name = `Other ($${(totalClaimCost || 0).toFixed(2)})`;
      }
    } else if (resourceCode === ANALYTICS_RESOURCE.DPC_STRATIFICATION) {
      if (key.includes('One')) {
        name = `Top 1%`;
      } else if (key.includes('Five')) {
        name = `Top 5%`;
      } else if (key.includes('Ten')) {
        name = `Top 10%`;
      } else {
        name = `Other`;
      }
    }
    const temp = {
      name: name,
      value: data[key].count,
      label: key,
    };
    tempArray.push(temp);
  });
  return {tempArray, totalCount};
};

export const getTopDataViewForBarChart = (
  dataArray: any[],
  resourceCode: string
) => {
  let typeObject = {
    type1: {
      name: '',
      code: '',
      count: 0,
    },
    type2: {
      name: '',
      code: '',
      count: 0,
    },
  };

  if (
    resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS ||
    resourceCode === ANALYTICS_RESOURCE.ENROLLED_FOR_CARE
  ) {
    typeObject = {
      ...typeObject,
      type1: {
        ...typeObject.type1,
        name: 'Employee',
        code: 'employee',
      },
      type2: {
        ...typeObject.type2,
        name: 'Dependent',
        code: 'dependent',
      },
    };
  } else if (
    resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_LOGGED_IN ||
    resourceCode === ANALYTICS_RESOURCE.CLINICAL_INTERVENTION_MESSAGE_NOTES ||
    resourceCode === ANALYTICS_RESOURCE.MEMBERSHIP_INACTIVATED ||
    resourceCode === ANALYTICS_RESOURCE.MEMBERSHIP_NET_GROWTH

  ) {
    typeObject = {
      ...typeObject,
      type1: {
        ...typeObject.type1,
        name: 'Employee',
        code: 'employee',
      },
      type2: {
        ...typeObject.type2,
        name: 'Dependent',
        code: 'dependent',
      },
    };
  } else if (resourceCode === ANALYTICS_RESOURCE.ENCOUNTER_TYPES) {
    typeObject = {
      ...typeObject,
      type1: {
        ...typeObject.type1,
        name: 'Video',
        code: 'Video',
      },
      type2: {
        ...typeObject.type2,
        name: 'In Person',
        code: 'In Person',
      },
    };
  } else if (resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED) {
    typeObject = {
      ...typeObject,
      type1: {
        ...typeObject.type1,
        name: 'Video',
        code: 'Video',
      },
      type2: {
        ...typeObject.type2,
        name: 'In Person',
        code: 'In Person',
      },
    };
  }
  else if (
    resourceCode === ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING ||
    resourceCode === ANALYTICS_RESOURCE.SMS_MESSAGING
  ) {
    typeObject = {
      ...typeObject,
      type1: {
        ...typeObject.type1,
        name: 'Send By Practice',
        code: 'User',
      },
      type2: {
        ...typeObject.type2,
        name: 'Send By Member',
        code: 'Contact',
      },
    };
  } else if (
    resourceCode === ANALYTICS_RESOURCE.AUTOMATION_TIME_SAVED
  ) {
    typeObject = {
      ...typeObject,
      type1: {
        ...typeObject.type1,
        name: 'Member',
        code: 'Member',
      },
    };
  } else if (resourceCode === ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE) {
    typeObject = {
      ...typeObject,
      type1: {
        ...typeObject.type1,
        name: 'Incoming Calls',
        code: 'inbound',
      },
      type2: {
        ...typeObject.type2,
        name: 'Outgoing Calls',
        code: 'Outgoing Dial',
      },
    };
  } else if (resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BOOKED) {
    typeObject = {
      ...typeObject,
      type1: {
        ...typeObject.type1,
        name: 'Appointment Booked',
        code: 'BOOKED',
      },
      type2: {
        ...typeObject.type2,
        name: 'No Show',
        code: 'NO-SHOW',
      },
    };
  } else if (resourceCode === ANALYTICS_RESOURCE.VIDEO_CALLING) {
    typeObject = {
      ...typeObject,
      type1: {
        ...typeObject.type1,
        name: 'Employee',
        code: 'employee',
      },
      type2: {
        ...typeObject.type2,
        name: 'Dependant',
        code: 'dependant',
      },
    };
  }
  dataArray.forEach((item) => {
    if (item?.type?.trim()?.toLowerCase() == typeObject?.type1?.code.trim()?.toLowerCase()) {
      const count = typeObject?.type1?.count + (item.value || item.count || 0);
      typeObject = {
        ...typeObject,
        type1: {
          ...typeObject.type1,
          count: count,
        },
      };
    } else if (item?.type?.trim()?.toLowerCase() == typeObject?.type2?.code.trim()?.toLowerCase()) {
      const count = typeObject?.type2?.count + (item.value || item.count || 0);
      typeObject = {
        ...typeObject,
        type2: {
          ...typeObject.type2,
          count: count,
        },
      };
    }
  });
  return typeObject
};

export const getFormattedDataForLineChart = (data: any, dateRange: any) => {
  const toDate = dateRange?.toDate;
  const mutableDataObj = {...data};
  const tempArray = [] as any[]
  Object.keys(mutableDataObj || {}).map(function (key) {
    const temp = {
      date: moment(key.replace("_", "-")).format('YYYY-MM-DDTHH:mm:ss'),
      datePoint: moment(key.replace("_", "-")).format('YYYY-MMM'),
      dateString: key.replace("_", "-"),
      ...mutableDataObj[key]
    }
    tempArray.push(temp)
  });
  const sortedArray = [...tempArray].sort((a, b) => {
    const date1 = new Date(a.date || '')
    const date2 = new Date(b.date || '')
    return date2.getTime() - date1.getTime()
  })
  const filterDate = [...sortedArray]?.filter(item => {
    return moment(item.date) < toDate
  })
  const last12Month = filterDate.slice(0, 12);
  const normalDate = last12Month.reverse()
  return normalDate
}

export const getFormattedDataForSaving = (data: ISavingDetailView[]) => {
  const returnArray = [] as any[]
  const mutableData = data?.length ? [...data] : [];
  mutableData?.forEach((item) => {
    const temp = {
      // id: item?.id,
      // name: item?.accountVisitTypes?.visitTypes?.name || '',
      // qty: item?.accountVisitTypes?.totalQuantity || item?.accountVisitTypes?.totalQuantity == 0 ? item?.accountVisitTypes?.totalQuantity : '--',
      // marketCost: item?.currentMarketPrice || item?.currentMarketPrice == 0 ?  item?.currentMarketPrice : '--',
      // dpcCost: item?.price || item?.price == 0 ?  item?.price : '--',
      // rawData: item,
      id: item?.accountVisitTypes?.visitTypes?.id || '',
      name: item?.accountVisitTypes?.visitTypes?.name || '',
      code: item?.accountVisitTypes?.visitTypes?.code || '',
      description: item.accountVisitTypes?.visitTypes?.description || '',
      codeType: item?.accountVisitTypes?.visitTypes?.codeType || '',
      quantity: item?.accountVisitTypes?.totalQuantity || 0,
      accountVisits: {
        id: item?.accountVisitTypes.id,
        visitTypeId: item?.accountVisitTypes?.visitTypeId,
        accountUuid: item?.accountUuid,
        employerUuid: item?.accountVisitTypes?.employerUuid,
        visitCost: {
          id: item?.id,
          price: item?.price,
          priceData: item?.priceData,
          marketPriceData: item?.marketPriceData,
          currentMarketPrice: item?.currentMarketPrice
        }
      }
    }
    returnArray.push(temp)
  })
  return returnArray
}


export const wordWrapFromChart = (displayText: string, maxWidth = 25) => {
  let finalDisplayText = '';
  while (displayText.length > maxWidth) {
    let isTextPresent = false;
    for (let strIndex = maxWidth - 1; strIndex >= 0; strIndex--) {
      if (isEmptyText(displayText.charAt(strIndex))) {
        finalDisplayText = finalDisplayText + [displayText.slice(0, strIndex), '\n'].join('');
        displayText = displayText.slice(strIndex + 1);
        isTextPresent = true;
        break;
      }
    }
    if (!isTextPresent) {
      finalDisplayText += [displayText.slice(0, maxWidth), '\n'].join('');
      displayText = displayText.slice(maxWidth);
    }
  }
  return finalDisplayText + displayText;
}

const isEmptyText = (text: string) => {
  const white = new RegExp(/^\s$/);
  return white.test(text.charAt(0));
}

export const getContactUuidsFromContactData = (contactData: {
  contactUuid: string;
}[]): string[] => {
  let finalContactUuids: string[] = [];
  contactData.forEach((singleContactData) => {
    if (singleContactData?.contactUuid) {
      finalContactUuids.push(singleContactData?.contactUuid);
    }
  });
  finalContactUuids = [
    ...new Set([...finalContactUuids]),
  ];
  return finalContactUuids;
};

export const getContactUuidsFromContactDrillDownDataList = (
  contactData: {
    contactUuid: string;
  }[]
): string[] => {
  let finalContactUuids: string[] = [];
  contactData.forEach((singleContactData) => {
    if (singleContactData?.contactUuid) {
      finalContactUuids.push(singleContactData?.contactUuid);
    }
  });
  finalContactUuids = [...new Set([...finalContactUuids])];
  return finalContactUuids;
};

export const getContactDrillDownColumnDataList = (
  contactData: {
    contactDrillDownDataList: any[];
    columnList: any[];
  }
) => {
  let firstViewFinalColumnList: any[] = contactData?.columnList?.filter((singleColumnData) => {
    return singleColumnData?.isOnFirstView === true;
  });;
  const isOnFirstView = contactData?.columnList?.some((singleColumnData) => {
    return singleColumnData?.isOnFirstView;
  });
  const contactObjColumn = {
    columnName: 'Members',
    columnKey: 'contact',
  };
  firstViewFinalColumnList = [contactObjColumn, ...firstViewFinalColumnList];
  return {
    columnList: firstViewFinalColumnList,
    previousColumnList: contactData?.columnList,
    contactDrillDownDataList: contactData?.contactDrillDownDataList,
    isOnFirstView,
  };
};

export const getFormattedBarChartsAppointmentWidgetData = (
  resourceDataArray: IResource[],
  resourceCode: string,
  displayType?: string
) => {
  const returnArray: IBarChartData[] = []
  const resourceData = resourceDataArray?.length ? [...resourceDataArray] : [];
  if (resourceData?.length) {
    if (
      resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME ||
      resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_TIME
    ) {
      const returnArray: IBarChartData[] = [];
      resourceData?.map((item) => {
        const temp: IBarChartData = {
          type:
            item.employeeType ||
            codeToDisplayText(resourceCode, item.type || ''),
          value: item.countNum || 0,
          groupBy: item?.timeGroup,
          filterCode:
            resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_TIME
              ? item.type
              : '',
        };
        returnArray.push(temp);
      });
      return returnArray;
    }
    if (resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY ||
        resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_DAY) {
      const returnArray: IBarChartData[] = [];
      resourceData?.map((item) => {
        const temp: IBarChartData = {
          type: item.employeeType || codeToDisplayText(
            resourceCode,item.type || ''
          ),
          value: item.countNum || 0,
          groupBy: item?.day,
          filterCode:
            resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_DAY
              ? item.type : ''
        };
        returnArray.push(temp);
      });
      return returnArray;
    }
    if (resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_TYPE) {
      resourceData?.map((item) => {
        const temp: IBarChartData = {
          type: item.employeeType,
          value: item.countNum || 0,
          groupBy: item?.apptType
        };
        returnArray.push(temp);
      });
      const topVisits = getCombinedPairForHorizontalStackBar(returnArray)
      return topVisits
    }
    if (resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE) {
      resourceData.forEach((item: any) => {
        const type = item.apptType
        Object.entries(item).forEach(([key, val]: any) => {
          if (key === 'apptType') {
            return
          }
          const temp: IBarChartData = {
            type: type,
            value: Math.round(val),
            groupBy: key
          };
          returnArray.push(temp)
        });

      })
    }

    if (resourceCode === ANALYTICS_RESOURCE.ENGAGEMENT_ON_APPLIED_RULES) {
      resourceData?.map((item) => {
        const temp: IBarChartData = {
          type: item.type,
          colorType: item?.colorType || '',
          code: item.code,
          value: item.countNum || 0,
          groupBy: item?.displayText
        };
        returnArray.push(temp);
      });
    }

    if (resourceCode === ANALYTICS_RESOURCE.USER_MESSAGING ||
      resourceCode === ANALYTICS_RESOURCE.USER_VIDEO_USAGE ||
      resourceCode === ANALYTICS_RESOURCE.USER_APPOINTMENT_TIME_USAGE) {
      resourceData.forEach((item: any) => {
        if (!item.name) {
          return
        }
        const type = item.messageType || item.type;
        const groupBy = item.name;
        const value = item.countNum || 0;
        const temp: IBarChartData = {
          type: type,
          value: Math.round(value),
          groupBy: groupBy
        };
        returnArray.push(temp)
      })
    }
  }
  if (displayType === DISPLAY_TYPE.APPOINTMENT_WITH_HORIZONTAL_STACK_VIEW) {
    const sortedArray = returnArray.sort((barChartObj1, barChartObj2) => {
      const barChartValue1: any = barChartObj1.value || barChartObj1.value1 || barChartObj1.value2 || 0
      const barChartValue2: any = barChartObj2.value || barChartObj2.value1 || barChartObj2.value2 || 0
      return barChartValue2 - barChartValue1
    })
    return sortedArray
  }
  return returnArray
};

export const getTopDataViewForAppointmentChartView = (
  dataArray: any[],
  resourceCode: string
) => {
  let typeObject = {
    type1: {
      name: '',
      code: '',
      count: 0,
    },
    type2: {
      name: '',
      code: '',
      count: 0,
    },
  };
  if (
    resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME ||
    resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY ||
    resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_TYPE ||
    resourceCode === ANALYTICS_RESOURCE.TOP_DIAGNOSIS ||
    resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE
  ) {
    if (resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE) {
      typeObject = {
        ...typeObject,
        type1: {
          ...typeObject.type1,
          name: 'Video',
          code: 'Video',
        },
        type2: {
          ...typeObject.type2,
          name: 'In Person',
          code: 'In Person',
        },
      };
    } else
      typeObject = {
        ...typeObject,
        type1: {
          ...typeObject.type1,
          name: 'Employee',
          code: 'Employee',
        },
        type2: {
          ...typeObject.type2,
          name: 'Dependent',
          code: 'Dependent',
        },
      };
  }
  dataArray.forEach((item) => {
    if (
      item?.type?.trim()?.toLowerCase() ==
      typeObject?.type1?.code.trim()?.toLowerCase()
    ) {
      const count = typeObject?.type1?.count + (item.value || item.count || 0);
      typeObject = {
        ...typeObject,
        type1: {
          ...typeObject.type1,
          count: count,
        },
      };
    } else if (
      item?.type?.trim()?.toLowerCase() ==
      typeObject?.type2?.code.trim()?.toLowerCase()
    ) {
      const count = typeObject?.type2?.count + (item.value || item.count || 0);
      typeObject = {
        ...typeObject,
        type2: {
          ...typeObject.type2,
          count: count,
        },
      };
    }
  });
  return typeObject;
};

function codeToDisplayText(resourceCode: string, type: string): string {
  const map = WidgetLegendMap[resourceCode];
  if (map && map[type]) {
    return map[type];
  }
  return type;
}

export const getFormattedUtilizationWidgetData = (
  resourceDataArray: any,
  appliedFilter: IAppliedFilter | undefined,
  resourceCode: string,
  employerEnrollmentData: IContactMembershipsData,
  isAccountDashboard?: boolean
) => {
  const returnArray: IBarChartData[] = [];
  const returnArray1: IBarChartData[] = [];
  const returnArray2: IBarChartData[] = [];
  if (resourceDataArray?.length) {
    if(
      resourceCode === ANALYTICS_RESOURCE.FOLD_CONVERSIONS
      || resourceCode === ANALYTICS_RESOURCE.CRM_ACTIVITY
      || resourceCode === ANALYTICS_RESOURCE.FOLD_INACTIVE_ENGAGEMENT) {
        resourceDataArray = handleResponseDataAddMonth(resourceDataArray, resourceCode, appliedFilter)
    }
  }

  if (resourceCode === ANALYTICS_RESOURCE.CLAIMS
    || resourceCode === ANALYTICS_RESOURCE.TOTAL_CLAIM_COST
    || resourceCode === ANALYTICS_RESOURCE.IP_CLAIM_COST
    || resourceCode === ANALYTICS_RESOURCE.URGENT_CARE_CLAIM_COST
  ) {
    const minAndMaxCost = getMinAndMaxFromList(resourceDataArray?.contactData);
    const resourceData = resourceDataArray?.contactData?.length ? resourceDataArray?.contactData : resourceDataArray
    resourceData?.forEach((resourceItem: any) => {
      Object.entries(resourceItem)?.forEach(([key, value]: any) => {
        if (
          nonEntryPropertyList.includes(key)
        ) {
          return;
        }
        let type: string;
        if (key === 'totalClaimCost' || key === 'totalAmount') {
          type = 'totalCost';
        } else if (key === 'contactCount') {
          type = 'totalClaimsRecords'
        } else if (key === 'amortizationCost' || key === 'averageCost') {
          type = 'averageCostPerActiveLives'
        } else if (key === 'totalActiveContacts') {
          type = 'totalActivePatients'
        } else {
          type = key
        }
        const isCost = COST_TYPE_WORDS.find((typeValue) => {
          const typeName = type.toLowerCase();
          return typeName.includes(typeValue)
        })
        const date = resourceItem?.monthDate?.value || resourceItem?.monthDt?.value
        if (isCost) {
          const temp1: IBarChartData = {
            type1: type,
            value1: Math.round(value) || 0,
            month: getFormattedDateStringInTimeZone(date, 'MMM YY'),
            date: date
          };
          returnArray1.push(temp1)
        }
        else {
          const temp2: IBarChartData = {
            type2: type,
            value2: Math.round(value) || 0,
            month: getFormattedDateStringInTimeZone(date, 'MMM YY'),
            date: date
          };
          returnArray2.push(temp2)
        }
      })
    })
    if (minAndMaxCost?.max && employerEnrollmentData?.contactMemberships?.[0]?.startDate) {
      returnArray1.push({
        type1: 'employerEnrollmentDate',
        value1: Math.round(minAndMaxCost?.max),
        month: getFormattedDateStringInTimeZone(employerEnrollmentData?.contactMemberships?.[0]?.startDate, 'MMM YY'),
        date: employerEnrollmentData?.contactMemberships?.[0]?.startDate
      });
      returnArray1.push({
        type1: 'employerEnrollmentDate',
        value1: 0,
        month: getFormattedDateStringInTimeZone(employerEnrollmentData?.contactMemberships?.[0]?.startDate, 'MMM YY'),
        date: employerEnrollmentData?.contactMemberships?.[0]?.startDate
      })
    }
    const sortedArrayReturnArray1 = returnArray1.sort((a: IBarChartData, b: IBarChartData) => {
      const date1 = new Date(a.date || '')
      const date2 = new Date(b.date || '')
      return date1.getTime() - date2.getTime()
    });
    const sortedArrayReturnArray2 = returnArray2.sort((a: IBarChartData, b: IBarChartData) => {
      const date1 = new Date(a.date || '')
      const date2 = new Date(b.date || '')
      return date1.getTime() - date2.getTime()
    });
    return [[...sortedArrayReturnArray1], [...sortedArrayReturnArray2]]
  }
  if (resourceCode === ANALYTICS_RESOURCE.ED_UTILIZATION_AMOUNT) {
    resourceDataArray?.forEach((resourceItem: any) => {
      Object.entries(resourceItem).forEach(([key, value]: any) => {
        if (nonEntryPropertyList.includes(key)) {
          return;
        }
        const temp: IBarChartData = {
          type: key,
          value: Math.round(value),
          month: getFormattedDateStringInTimeZone(resourceItem?.monthDt?.value, 'MMM YY'),
          date: resourceItem?.monthDate?.value
        };
        returnArray.push(temp)
      })
    })
  }
  if (resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION ||
    resourceCode === ANALYTICS_RESOURCE.USER_SATISFACTION ||
    resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_COUNT ||
    resourceCode === ANALYTICS_RESOURCE.EMPLOYER_SATISFACTION ||
    resourceCode === ANALYTICS_RESOURCE.MEMBERSHIP_REVENUE_GROWTH ||
    resourceCode === ANALYTICS_RESOURCE.DPC_STRATIFICATION) {
    resourceDataArray?.forEach((resourceItem: any) => {
      Object.entries(resourceItem).forEach(([key, value]: any) => {
        if (nonEntryPropertyList.includes(key)) {
          return;
        }
        if (false && !isAccountDashboard && keyVisibleForAccountDashboard.includes(key)) {
          return;
        }
        const temp: IBarChartData = {
          type: key,
          value: Math.round(value),
          month: getFormattedDateStringInTimeZone(resourceItem?.monthDt?.value, 'MMM YY'),
          date: resourceItem?.monthDt?.value
        };
        returnArray.push(temp)
      })
    })
  }

  if (resourceCode === ANALYTICS_RESOURCE.ENROLLED_FOR_CARE ||
    resourceCode === ANALYTICS_RESOURCE.CRM_ACTIVITY ||
    resourceCode === ANALYTICS_RESOURCE.FOLD_CONVERSIONS ||
    resourceCode === ANALYTICS_RESOURCE.FOLD_INACTIVE_ENGAGEMENT) {
    resourceDataArray?.forEach((resourceItem: any) => {
      const temp: IBarChartData = {
        count: resourceItem.countNum || 0,
        type: codeToDisplayText(resourceCode, resourceItem?.displayText || ''),
        code: resourceItem?.displayText || '',
        filterCode: resourceItem?.filterCode || '',
        value: Math.round(resourceItem?.countNum) || 0,
        month: getFormattedDateStringInTimeZone(resourceItem?.monthDt?.value, 'MMM YY'),
        date: resourceItem?.monthDt?.value
      };
      returnArray.push(temp)
    })

  }

  if (resourceCode === ANALYTICS_RESOURCE.ED_UTILIZATION_AMOUNT) {
    resourceDataArray?.forEach((resourceItem: any) => {
      Object.entries(resourceItem).forEach(([key, value]: any) => {
        if (nonEntryPropertyList.includes(key)) {
          return;
        }
        const temp: IBarChartData = {
          type: key,
          value: Math.round(value) || 0,
          month: getFormattedDateStringInTimeZone(resourceItem?.monthDt?.value, 'MMM YY'),
          date: resourceItem?.monthDt?.value
        };
        returnArray.push(temp)
      })
    })
  }
  if (resourceCode === ANALYTICS_RESOURCE.ED_UTILIZATION_AMORTIZATION) {
    resourceDataArray?.forEach((resourceItem: any) => {
      Object.entries(resourceItem).forEach(([key, value]: any) => {
        if (nonEntryPropertyList.includes(key)) {
          return;
        }
        let type: string;
        if (key === 'utilizationAmount') {
          type = 'Per employee cost'
        } else if (key === 'totalMembers') {
          type = 'Total employees'
        } else {
          type = key
        }
        const temp: IBarChartData = {
          type: type,
          value: Math.round(value) || 0,
          month: getFormattedDateStringInTimeZone(resourceItem?.monthDt?.value, 'MMM YY'),
          date: resourceItem?.monthDt?.value
        };
        returnArray.push(temp)
      })
    })
  }

  if (resourceCode === ANALYTICS_RESOURCE.ENROLLED_FOR_CHRONIC_CARE) {
    resourceDataArray?.forEach((resourceItem: any) => {
      const temp: IBarChartData = {
        type: resourceItem.displayText,
        code: resourceItem?.code || '',
        value: resourceItem?.countNum || 0,
        month: getFormattedDateStringInTimeZone(resourceItem?.monthDt?.value, 'MMM YY'),
        date: resourceItem?.monthDt?.value
      };
      returnArray.push(temp)
    })
  }
  if (resourceCode === ANALYTICS_RESOURCE.ENGAGEMENT_ON_APPLIED_RULES) {
    resourceDataArray?.forEach((resourceItem: any) => {
      const temp: IBarChartData = {
        type: resourceItem.type,
        value: resourceItem?.countNum || 0,
        month: getFormattedDateStringInTimeZone(resourceItem?.monthDt?.value, 'MMM YY'),
        date: resourceItem?.monthDt?.value
      };
      returnArray.push(temp)
    })
  }

  if (resourceCode === ANALYTICS_RESOURCE.ALL_CLAIM_DATA) {
    resourceDataArray?.forEach((resourceItem: any) => {
      let type = resourceItem.type;
      if (resourceItem.type === 'totalClaimCost') {
        type = 'Total Claim Cost';
      }
      const temp: IBarChartData = {
        type: resourceItem.type || '',
        filterCode: resourceItem?.filterCode || '',
        value: Math.round(resourceItem?.totalClaimCost) || 0,
        month: getFormattedDateStringInTimeZone(resourceItem?.monthDt?.value, 'MMM YY'),
        date: resourceItem?.monthDt?.value
      };
      returnArray.push(temp)
    })
  }
  const sortedArray = returnArray.sort((a: IBarChartData, b: IBarChartData) => {
    const date1 = new Date(a.date || '')
    const date2 = new Date(b.date || '')
    return date1.getTime() - date2.getTime()
  });
  return sortedArray
}

export const getFormattedAppliedFilter = (appliedFilter: any) => {
  const appliedFilterList: any = []
  if (appliedFilter == '{}') {
    return appliedFilterList
  }
  Object.entries(appliedFilter)?.forEach(([key, value]: any) => {
    const tempObj = {
      filterCode: key,
      filterValue: value
    }
    appliedFilterList.push(tempObj)
  })
  return appliedFilterList
}

export const getToolTipList = (toolTipObj: any) => {
  switch (toolTipObj.resourceCode) {
    case ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT:
      const formattedToolTipList: any = []
      if (toolTipObj?.data?.['series-field-key']) {
        toolTipObj?.data['series-field-key']
      }
      const referenceObj: any = toolTipObj?.data?.['series-field-key'] ? toolTipObj : toolTipObj.data[0];
      if (!referenceObj?.name && toolTipObj?.fromAction === 'TOOLTIP') {
        return formattedToolTipList
      }
      const type = referenceObj?.data['series-field-key'] === OFFICE_HOURS_CONST.OUT_OF_OFFICE_HOURS
        ? OFFICE_HOURS_CONST.OUT_OF_OFFICE_HOURS
        : OFFICE_HOURS_CONST.OFFICE_HOURS;
      const date = referenceObj?.data?.monthDate || referenceObj?.title;
      const selectedFormattedData = toolTipObj?.graphData?.find((item: any) => {
        return item?.monthDt?.value === date
      })
      selectedFormattedData?.dataList?.forEach((item: any) => {
        if (type === OFFICE_HOURS_CONST.OUT_OF_OFFICE_HOURS) {
          if (item.code?.includes(OFFICE_HOURS_CONST.OUT_OF_OFFICE_HOURS)) {
            const newObj = {
              ...item,
              name: codeToDisplayText(toolTipObj.resourceCode,item.code),
              displayText: codeToDisplayText(toolTipObj.resourceCode,item.code),
              value: item.countNum || 0,
              type: type,
            }
            formattedToolTipList.push(newObj)
          }
        } else {
          if (!item.code?.includes(OFFICE_HOURS_CONST.OUT_OF_OFFICE_HOURS)) {
            const newObj = {
              ...item,
              name: codeToDisplayText(toolTipObj.resourceCode,item.code),
              displayText: codeToDisplayText(toolTipObj.resourceCode,item.code),
              value: item.countNum || 0,
              type: type
            }
            formattedToolTipList.push(newObj)
          }
        }
      })
      return formattedToolTipList
      break;
    case ANALYTICS_RESOURCE.COMMUNICATION_STATS:
      const data: any[] = toolTipObj.data || []
      data.forEach((item: any) => {
        item.name = item.name.replace(
          /^_*(.)|_+(.)/g,
          (s: string, c: string, d: string) =>
            c ? c.toUpperCase() : ' ' + d.toUpperCase()
        );
      });
      return data
    default:
      return toolTipObj.data
      break;
  }
}
