import React from "react";

const  RestSvgIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="gray"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 4v6m0 0h6m-6 0l4.64-4.36A9 9 0 113.51 15"
      ></path>
    </svg>
  );
}

export default RestSvgIcons;
