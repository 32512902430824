import {VStack, Text, HStack, Pressable} from 'native-base';
import Icon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../../../../styles/Colors';
import {styles} from '../../../CampaignStyles';
import {ICampaignTagsProps} from '../../../interfaces';

export interface ICampaignTagsListProps {
  tagList: ICampaignTagsProps[];
}

export const TagListCampaign = (props: ICampaignTagsListProps) => {
  return (
    <VStack style={styles.btPadding}>
      {props.tagList.map((tagItem: ICampaignTagsProps, index: number) => {
        return (
          <Pressable _hover={{bg: Colors.Custom.HoverBgColor}} key={index}>
            <HStack style={styles.tagList} space="2">
              <Icon name="tago" color={tagItem.tagColor} />
              <Text>{tagItem.tagName}</Text>
              <Text style={styles.tagCount}>{tagItem.tagCount}</Text>
            </HStack>
          </Pressable>
        );
      })}
    </VStack>
  );
};
