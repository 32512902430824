import BaseService from '../../../services/CommonService/BaseService';
import {
  ICreateEmailInboxBody,
  IEmailInboxAuthTokenIdResponse,
  IEmailInboxCreateResp,
  IEmailInboxExpiredResponse,
  IForwardOutlookEmailBody,
  IReplyOutlookEmailBody,
  ISendOutlookEmailBody,
} from '../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/EmailInboxCreate/interfaces';
import {
  IEmailAttachmentResponse,
  IInlineAttachmentDownloadResponse,
  IUploadEmailAttachmentResponse,
} from './interfaces';

export const createOutlookEmailInbox = async (data: ICreateEmailInboxBody) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/api/v1/email-inbox`;
  const response = await baseService.post<IEmailInboxCreateResp>(url, data);
  return response.data;
};

export const getInboxAuthTokenIdAndEmail = async (code: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = 'crm-nest/api/v1/email-inbox/token';
  const response = await baseService.post<IEmailInboxAuthTokenIdResponse>(url, {
    code,
  });
  return response.data;
};

export const sendOutlookEmailAPI = async (body: ISendOutlookEmailBody) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = 'crm-nest/conversation/outlook-email-message';
  const response = await baseService.post(url, {
    ...body,
  });
  return response.data;
};

export const replyEmail = async (body: IReplyOutlookEmailBody) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = 'crm-nest/conversation/outlook-email-message';
  const response = await baseService.post(url, {
    ...body,
  });
  return response.data;
};

export const forwardEmail = async (body: IForwardOutlookEmailBody) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = 'crm-nest/conversation/outlook-email-message';
  const response = await baseService.post(url, {
    ...body,
  });
  return response.data;
};

export const replyEmailAll = async (body: IReplyOutlookEmailBody) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = 'crm-nest/conversation/outlook-email-message';
  const response = await baseService.post(url, {
    ...body,
  });
  return response.data;
};

export const getEmailAttachments = async (data: {messageUuid: string}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/api/v1/email-inbox/message/${data.messageUuid}/attachments`;
  const response = await baseService.get<IEmailAttachmentResponse[]>(url);
  return response.data;
};

export const downloadEmailAttachments = async (data: {
  attachmentId: string;
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/api/v1/email-inbox/attachment/${data.attachmentId}`;
  const response = await baseService.get(url, {responseType: 'blob'});
  return response;
};

export const uploadEmailAttachment = async (data: {formData: FormData}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/api/attachment/upload`;
  const response = await baseService.post<IUploadEmailAttachmentResponse>(
    url,
    data.formData
  );
  return response.data;
};

export const emailReLogin = async (data: {inboxUuid: string; code: string}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/api/v1/email-inbox/relogin`;
  const response = await baseService.post(url, data);
  return response.data;
};

export const getExpiredEmailInbox = async () => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = 'crm-nest/api/v1/email-inbox/expired';
  const response = await baseService.get<IEmailInboxExpiredResponse[]>(url);
  return response.data;
};

export const getInlineAttachment = async (data: {
  messageUuid: string;
  attachmentId: string;
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/api/v1/email-inbox/message/${data.messageUuid}/inline-attachment/${data.attachmentId}`;
  const response = await baseService.get<IInlineAttachmentDownloadResponse>(
    url
  );
  return response.data;
};
