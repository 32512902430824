import {Form, Table} from 'antd';
import {Button, FormControl, Input, Text, useToast, View} from 'native-base';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../constants';
import {evnName, getEnvVariable} from '../../../../../constants/BaseUrlConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import CommonService from '../../../../../services/CommonService/CommonService';
import {Colors} from '../../../../../styles';
import {
  getAccountId,
  getAccountUUID,
  getUserActionObjectFromActionCode,
  getUserUUID
} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import LoadingSpinner from '../../../../common/Loader/LoadingSpinner';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {IStripeBaseTier} from '../../../Sales/MembershipAndSubscriptions/interface';
import {insertUserAction} from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {styles} from '../../AccessFormStyles';
import {IElationConfig} from '../../integrationRespInterface';
const {Label, ErrorMessage} = FormControl;

const AccessForm = () => {
  const EditableContext = React.createContext<any>(null);
  const [showLoader, setShowLoader] = useState(false);
  const commonService = CommonService.getCommonServiceInstance();
  const integrationService = commonService.integrationService;
  const toast = useToast();
  const userActionObject = getUserActionObjectFromActionCode('ELATION');
  const level = userActionObject.level || '';
  const accountUuid = getAccountUUID();
  const accountId = getAccountId();
  const userId = getUserUUID();
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const subdomainMap: any = commonData.accountSubdomain || {};
  const tenantName: string =
    subdomainMap?.subDomain?.split(".")[0];

  const [state, setState] = useState({
    submitting: false,
    clientId: '',
    clientSecret: '',
    appUsername: '',
    appPassword: '',
    practiceId: '',
    apiUrl: '',
    error: false
  });

  const onProductDetailFromSubmit = () => {
    setShowLoader(true);
    const url = 'api/integrate/elation';
    const uuidV4 = accountUuid.split("-")[4];
    const body = {
      elation: {
        clientId: state.clientId,
        clientSecret: state?.clientSecret,
        username: state?.appUsername,
        password: state.appPassword,
        apiUrl: state?.apiUrl
      },
      googleHealthConfig: {
        dataSetId: `${tenantName}_${getEnvVariable()}_${accountId}_${uuidV4}`,
        fhirStoreId: `fhir_r4_${tenantName}_${getEnvVariable()}_${accountId}_${uuidV4}`
      },
      practiceId: state.practiceId
    };

    integrationService
      .post(url, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp: {data: any}) => {
        if (resp?.data) {
          insertUserAction({
            actionCode: 'ELATION',
            level,
            status: 'COMPLETED',
            userId: userId
          }).then((res) => {
            setShowLoader(false);
            showToast(
              toast,
              `Client info saved. Please contact support at hello@fold.health for next steps`,
              ToastType.info
            );
          });
        }
      })
      .catch((e: any) => {

        setShowLoader(false);
        false;
        showToast(
          toast,
          `Client Data could not be synced. Please contact support at hello@fold.health`,
          ToastType.error
        );
      });
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          marginVertical: 20,
          marginBottom: 20
        }}
      >
        <Text>Your Practice is not yet connected to Elation</Text>
        <Text
          size={'smMedium'}
          color={Colors.Custom.Gray700}
          style={{marginTop: 20}}
        >
          Please contact integrations@elationhealth.com for getting the
          integration setup with Fold
        </Text>

        <Text
          size={'smMedium'}
          color={Colors.Custom.Gray700}
          style={{marginTop: 20}}
        >
          Please enter the following information once elation support provides
          you with the details
        </Text>

        <View style={styles.dataContainer}>
          <FormControl style={styles.formContainer} isRequired>
            <Label style={styles.formLabel}>
              <Text size={'smMedium'} color={Colors.Custom.Gray700}>
                Elation URL
              </Text>
            </Label>
            <Input
              height={44}
              fontSize={16}
              onChangeText={(value) => {
                setState((prev) => {
                  return {
                    ...prev,
                    apiUrl: value
                  };
                });
              }}
            />
          </FormControl>
          <FormControl style={styles.formContainer} isRequired>
            <Label style={styles.formLabel}>
              <Text size={'smMedium'} color={Colors.Custom.Gray700}>
                Elation ClientId
              </Text>
            </Label>
            <Input
              height={44}
              fontSize={16}
              onChangeText={(value) => {
                setState((prev) => {
                  return {
                    ...prev,
                    clientId: value
                  };
                });
              }}
            />
          </FormControl>
          <FormControl style={styles.formContainer} isRequired>
            <Label style={styles.formLabel}>
              <Text size={'smMedium'} color={Colors.Custom.Gray700}>
                Elation Client Secret
              </Text>
            </Label>
            <Input
              height={44}
              fontSize={16}
              onChangeText={(value) => {
                setState((prev) => {
                  return {
                    ...prev,
                    clientSecret: value
                  };
                });
              }}
            />
          </FormControl>
          <FormControl style={styles.formContainer} isRequired>
            <Label style={styles.formLabel}>
              <Text size={'smMedium'} color={Colors.Custom.Gray700}>
                Elation Username
              </Text>
            </Label>
            <Input
              height={44}
              fontSize={16}
              onChangeText={(value) => {
                setState((prev) => {
                  return {
                    ...prev,
                    appUsername: value
                  };
                });
              }}
            />
          </FormControl>
          <FormControl style={styles.formContainer} isRequired>
            <Label style={styles.formLabel}>
              <Text size={'smMedium'} color={Colors.Custom.Gray700}>
                Elation Password
              </Text>
            </Label>
            <Input
              height={44}
              fontSize={16}
              onChangeText={(value) => {
                setState((prev) => {
                  return {
                    ...prev,
                    appPassword: value
                  };
                });
              }}
            />
          </FormControl>
          <FormControl style={styles.formContainer} isRequired>
            <Label style={styles.formLabel}>
              <Text size={'smMedium'} color={Colors.Custom.Gray700}>
                Elation Practice Id
              </Text>
            </Label>
            <Input
              height={44}
              fontSize={16}
              onChangeText={(value) => {
                setState((prev) => {
                  return {
                    ...prev,
                    practiceId: value
                  };
                });
              }}
            />
          </FormControl>
        </View>

        <Button.Group
          style={{paddingHorizontal: 12, paddingVertical: 12}}
          mx={{
            base: 'auto',
            md: 0
          }}
          justifyContent={'flex-start'}
        >
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress: () => {
                onProductDetailFromSubmit();
              },
              style: {marginRight: 20}
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'submit'
              }),
              withRightBorder: false
            }}
          ></FoldButton>
        </Button.Group>
      </View>
      {showLoader && <LoadingSpinner />}
    </View>
  );
};

export default AccessForm;
