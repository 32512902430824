import {Tabs} from 'antd';
import {View} from 'native-base';
import React from 'react';
import {JOURNEYS_TABLE_VIEW} from '../../../../../src/constants/StringConst';
// Views
import {PageTitleView} from '../../../common/PageTitleView';
// Styles
import {styles} from './JourneysOfCareStyles';
import {ProgramsTable} from './Table/ProgramsTable';

const JourneyOfCare = () => {
  return (
    <View style={styles.container}>
      <PageTitleView
        title="Journeys of Care"
        description="Design the products and services your business offers"
      />
      <View marginLeft={8}>
        <Tabs type="card">
          <Tabs.TabPane tab="Live" key={'1'}>
            <View marginTop={2} marginRight={8} marginBottom={24}>
              <ProgramsTable view={JOURNEYS_TABLE_VIEW.ALL_JOURNEYS} />
            </View>
          </Tabs.TabPane>
        </Tabs>
      </View>
    </View>
  );
};

export default JourneyOfCare;
