import { ICommonSvgProps } from "./interfaces";

const GoalNoteSvg = ({width, height}: ICommonSvgProps) => {
    return (
        <svg width={width? width : "14"} height={height? height :"14"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 22H4.5C3.84 22 3.2 21.79 2.73 21.41C2.26 21.03 2 20.53 2 20V4C2 3.47 2.26 2.96 2.73 2.59C3.2 2.22 3.83 2 4.5 2H19.5C20.16 2 20.8 2.21 21.27 2.59C21.74 2.97 22 3.47 22 4V16M16 22L22 16M16 22V16H22M16.3102 7.68988H7.7002M16.3102 11.6899H7.7002M10.3102 15.6899H7.7002" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )

}

export default GoalNoteSvg;