import {VStack, View, Text, useToast, Checkbox, HStack} from 'native-base';
import {FC, useEffect, useState} from 'react';
import {styles} from './CodeGroupsFilterStyles';
import {Colors} from '../../../../../styles';
import {useIntl} from 'react-intl';
import {getCategoriesForCodeGroup} from '../CodeGroupApi';
import {ToastType, showToast} from '../../../../../utils/commonViewUtils';
import {Code, ICodeGroupFilter} from '../interfaces';
import {FilterDrawerContainer} from '../../../../common/FilterDrawerContainer/FilterDrawerContainer';
import { ActivityIndicator } from 'react-native-paper';

interface IFilterView {
  onReset: () => void;
  onApply: (filterData: ICodeGroupFilter) => void;
  onClose: () => void;
  filterData?: ICodeGroupFilter;
}

export const CodeGroupsFilter: FC<IFilterView> = ({
  onReset,
  onApply,
  onClose,
  filterData,
}) => {
  const intl = useIntl();
  const toast = useToast();

  const [categories, setCategories] = useState<Code[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<ICodeGroupFilter>({
    categories: [],
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (filterData && filterData?.categories) {
      setSelectedFilter({
        ...filterData,
        categories: [...filterData.categories],
      });
    }
  }, [filterData?.categories?.length]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await getCategoriesForCodeGroup();
      if (response?.data) {
        setCategories(response?.data?.expansion?.contains);
      }
    } catch (error) {
      showToast(toast, 'Error in fetching categories', ToastType.error);
    } finally {
      setLoading(false);
    }
  };

  const checkCategoryIsSelected = (category: Code) => {
    if (selectedFilter?.categories.includes(category.display)) {
      return true;
    }
    return false;
  };

  return (
    <FilterDrawerContainer
      onReset={onReset}
      onApply={() => {
        selectedFilter && onApply({...selectedFilter});
      }}
      onClose={onClose}
    >
      <VStack>
        <VStack>
          <View style={[styles.filterTitle]}>
            <Text size={'lgSemibold'} color={'#000'}>
              {intl.formatMessage({id: 'filterByCategories'})}
            </Text>
           
          </View>
          {loading && (
            <View style={[styles.loaderStyle]}>
              <ActivityIndicator color={Colors.Custom.PrimaryColor} />
            </View>
            )}
        </VStack>

        <VStack space={6} marginLeft={4} marginTop={8}>
          {categories.map((category) => {
            return (
              <VStack>
                <Checkbox
                  key={category.display}
                  value={category?.display}
                  onChange={(isChecked) => {
                    const selctedCategories = selectedFilter?.categories;
                    if (isChecked) {
                      if (!selctedCategories?.includes(category.display)) {
                        selctedCategories?.push(category.display);
                      }
                    } else {
                      if (selctedCategories?.includes(category.display)) {
                        selctedCategories?.splice(
                          selctedCategories?.indexOf(category.display),
                          1
                        );
                      }
                    }
                    setSelectedFilter((prev: any) => {
                      return {
                        ...prev,
                        categories: selctedCategories,
                      };
                    });
                  }}
                  isChecked={checkCategoryIsSelected(category)}
                >
                  <Text
                    size={'smMedium'}
                    color={Colors.Custom.Gray700}
                    style={{marginLeft: 8}}
                  >
                    {category?.display}
                  </Text>
                </Checkbox>
              </VStack>
            );
          })}
        </VStack>
      </VStack>
    </FilterDrawerContainer>
  );
};
