import {
  MEMBERSHIP_INTERVAL_CODE,
  MEMBERSHIP_PRICE_CODE,
  STRIPE_INTERVAL_CODE,
} from '../../../../../../../../../../constants';

export const MemberShipPrice = [
  {
    label: 'oneTimeRegistrationFee',
    code: MEMBERSHIP_PRICE_CODE.ONE_TIME_REGISTRATION_FEE,
    key: MEMBERSHIP_INTERVAL_CODE.ONE_TIME,
    keyLabel: STRIPE_INTERVAL_CODE.ONE_TIME,
    count: 1,
  },
  {
    label: 'monthlyCharges',
    code: MEMBERSHIP_PRICE_CODE.MONTHLY_CHARGES,
    key: MEMBERSHIP_INTERVAL_CODE.MONTHLY,
    keyLabel: STRIPE_INTERVAL_CODE.MONTH,
    count: 1,
  },
  {
    label: 'quarterlyCharges',
    code: MEMBERSHIP_PRICE_CODE.QUARTERLY_CHARGES,
    key: MEMBERSHIP_INTERVAL_CODE.QUARTERLY,
    keyLabel: STRIPE_INTERVAL_CODE.MONTH,
    count: 3,
  },
  {
    label: 'annuallyCharges',
    code: MEMBERSHIP_PRICE_CODE.ANNUALLY_CHARGES,
    key: MEMBERSHIP_INTERVAL_CODE.YEARLY,
    keyLabel: STRIPE_INTERVAL_CODE.YEAR,
    count: 1,
  },
];

export const JOURNEY_START_TYPES = {
  FIXED: 'fixed',
  ROLLING: 'rolling'
}

export const JOURNEY_START_TYPE_VALUES = {
  FIXED: 'Fixed',
  ROLLING: 'Rolling'
}

export const PACKAGE_TYPES = {
  PUBLISHED: 'PUBLISHED',
}

export const FORM_CATEGORY_TYPES = {
  NOTES: 'SOAP',
  PROVIDER_REQUEST_FORM: 'PROVIDER_REQUEST_FORM',
  ASSESSMENT_FORM_CATEGORY: 'CLINICAL_ASSESSMENT',
  CARE_PLAN: 'CARE_PLAN',
  CARE_PLAN_ASSESSMENT: 'CARE_PLAN_ASSESSMENT',
}


export const DISALLOWED_SEND_FORM_CATEGORIES = [
  FORM_CATEGORY_TYPES.NOTES,
  FORM_CATEGORY_TYPES.PROVIDER_REQUEST_FORM,
  FORM_CATEGORY_TYPES.CARE_PLAN,
]; 

export const DISALLOWED_VIEW_ANALYTICS_CATEGORIES = [
  FORM_CATEGORY_TYPES.CARE_PLAN,
]; 

export const FORM_ANALYTICS_KEYS = {
  COMPLETION_RATE: 'completionRate',
  TOTAL_FORM_LOG_COUNT: 'totalFormLogCount',
  PENDING_FORM_LOG_COUNT: 'pendingFormLogCount',
  SUBMITTED_FORM_LOG_COUNT: 'submittedFormLogCount',
  SIGNED_OR_AMMENDED: 'signedOrAmmended'
}
  
export const REQUESTED_BY_TYPES_CODES = {
  CONTACT: 'CONTACT'
}