import { useQuery } from '@apollo/client';
import { Alert } from 'antd';
import {Skeleton, Text,  View} from 'native-base';
import {getEnvVariable} from '../../../../constants/BaseUrlConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { GENERATE_FORM_CODE_TYPE_SUBMIT_FORM_RESPONSE_WIDGET_TOKEN } from '../../../../services/Forms/FormsQueries';
import {Colors} from '../../../../styles';
import {CustomScrollView} from '../../../common/ScrollView/CustomScrollView';
import InstructionsView from '../InstructionsView';
import {getSignUpFormScript} from '../PatientPortalUtils';
import VideoAndScriptContainer from '../ScriptContainer/VideoAndScriptContainer';

const SignUpWidget = (props: { accountUuid: string }) => {
  const getUserTaskPoolAccess = useQuery(GENERATE_FORM_CODE_TYPE_SUBMIT_FORM_RESPONSE_WIDGET_TOKEN, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    variables: {
      params: {
        formCode: 'CONTACT_SIGN_UP',
        accountUuid: props.accountUuid,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const widgetToken = encodeURIComponent(getUserTaskPoolAccess.data?.generateFormCodeTypeSubmitFormResponseWidgetToken?.widgetToken);

  const inlineScript = getSignUpFormScript({
    envVariable: getEnvVariable(),
    accountUuid: props.accountUuid,
    openInModal: false,
    widgetToken: widgetToken,
  });

  const modalScript = getSignUpFormScript({
    envVariable: getEnvVariable(),
    accountUuid: props.accountUuid,
    openInModal: true,
    widgetToken: widgetToken,
  });

  const refVideos = [
    {
      title: 'Inline',
      url: 'https://generalfolddocs.s3.us-east-2.amazonaws.com/widget_demo_sample/inline_sign_up_widget.mov',
    },
    {
      title: 'New Page',
      url: 'https://generalfolddocs.s3.us-east-2.amazonaws.com/widget_demo_sample/new_page_sign_up_widget.mov',
    },
  ];


  const isLoading = getUserTaskPoolAccess.loading;
  const isError = getUserTaskPoolAccess.error || !widgetToken;

  return (
    <CustomScrollView style={{height: 'full', backgroundColor: '#fff'}}>
      {
        !isLoading && !isError &&
        <View>
          <InstructionsView
            title={'Sign up widget works as mentioned below'}
            summary={<Text>{`We support two types of sign up widgets - the first type opens the widget inside your website (inline) and the other opens up the widget in a new page (modal).`}</Text>}
            instructionDataArray={[
              <>
                <Text>
                  {`Inline widget must use the div tag as below in your html file\n`}
                </Text>
                <Text color={Colors.Custom.mainPrimaryPurple}>
                  {`<div id="fh-form-trigger"></div>`}
                </Text>
              </>,
              <>
                <Text>
                  {`If you would like the widget to open in a new page (modal) you must use the following element\n`}
                </Text>
                <Text color={Colors.Custom.mainPrimaryPurple}>
                  {`<button id="fh-form-trigger">Click Here</button>`}
                </Text>
              </>,
            ]}
            description={<Text>{`Above change is in addition to the code script pasted below.\n\nThe "Contact Sign Up" form in Settings > Content > Forms within your tablespace corresponds to the form displayed in the sign up widget. You can customize the "Contact Sign Up" form by adding extra fields. Note that only First Name, Last Name, Email, and Phone Number will update the Lead created by users who fill out the widget. However, all fields will be recorded in form responses and under form analytics.\n\nThe following video tutorials will also illustrate how this works. Please contact us if you have any questions.`}</Text>}
            refVideos={refVideos}
          />

          <VideoAndScriptContainer
            refVideos={refVideos}
            inlineScript={inlineScript}
            modalScript={modalScript}
          />
        </View>
      }
      {
        isLoading &&
        <View>
          <Skeleton></Skeleton>
        </View>
      }
      {
        isError &&
        <Alert
          message="Something went wrong while fetching widget details"
          type='error'
          showIcon={true}
        />
      }
    </CustomScrollView>
  );
};

export default SignUpWidget;
