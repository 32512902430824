import {useLazyQuery, useMutation} from '@apollo/client';
import {Drawer, InputNumber, Table, TableColumnsType, Tooltip} from 'antd';
import moment from 'moment';
import {
  Center,
  HStack,
  Spinner,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {ANALYTICS_RESOURCE, BUTTON_TYPE} from '../../../../../constants';
import {
  GET_PRESCRIBED_MEDICATIONS,
  CREATE_PRESCRIBED_MEDICATION_COST,
  UPDATE_PRESCRIBED_MEDICATION_COST,
  GET_PRESCRIBED_MEDICATIONS_ACC,
} from '../../../../../services/Medication/MedicationQueries';
import {
  CREATE_PRESCRIBED_IMAGING_COST,
  GET_PRESCRIBED_IMAGING,
  GET_PRESCRIBED_IMAGING_ACC,
  UPDATE_PRESCRIBED_IMAGING_COST,
} from '../../../../../services/PrescribedImaging/PrescribedImagingQueries';
import {
  CREATE_PRESCRIBED_LAB_COST,
  GET_PRESCRIBED_LAB,
  GET_PRESCRIBED_LAB_ACC,
  UPDATE_PRESCRIBED_LAB_COST,
} from '../../../../../services/PrescribedLabs/PrescribedLabsQueries';
import {CREATE_PRESCRIBED_PROCEDURES_COST, GET_PRESCRIBED_PROCEDURES, GET_PRESCRIBED_PROCEDURES_ACC, UPDATE_PRESCRIBED_PROCEDURES_COST} from '../../../../../services/Procedures/PracticeProceduresQueries';
import {
  CREATE_VISIT_COST,
  GET_VISITS,
  GET_VISITS_ACC,
  UPDATE_VISIT_COST,
} from '../../../../../services/Visit/VisitsQueries';
import {Colors} from '../../../../../styles';
import {getAccountUUID, isEmployerRole} from '../../../../../utils/commonUtils';
import {getDateStrFromFormat} from '../../../../../utils/DateUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {IMasterData, ISavingDrawer} from '../interface';
import {getTenantForDemo, IMG_SAVING_DRAWER_DATA, LAB_SAVING_DRAWER_DATA, PROC_SAVING_DRAWER_DATA, VISIT_SAVING_DRAWER_DATA} from '../tempDataFile';
import {IExtendedPrescribedLabCptcode, IExtendedSingleRecordData} from './LabInterface';
import {formatLabCostSavingResp, updateQtyInSection} from './SavingDrawerUtils';
import './style.css';

const SavingDrawer = (props: ISavingDrawer) => {
  const {height, width} = Dimensions.get('window');
  const [visible, setVisible] = useState(true);
  const accountUuid = getAccountUUID();
  const intl = useIntl();
  const isEmployer = isEmployerRole()
  const resourceCode = props?.data?.resourceCode;
  // const data:ISavingDetailView[] = props.data?.data?.currentDateResp?.visitCostDatalist?.length  ? props.data?.data?.currentDateResp?.visitCostDatalist : [];

  const [stateData, setStateData] = useState({
    tableData: [] as any[],
    isLoading: false
  })

  const [getData] = useLazyQuery(
    resourceCode == 'ERX_SAVINGS'
      ? GET_PRESCRIBED_MEDICATIONS
      : resourceCode == 'IMAGING_SAVINGS'
        ? GET_PRESCRIBED_IMAGING
        : resourceCode == 'LAB_SAVINGS'
          ? GET_PRESCRIBED_LAB
          : resourceCode == 'VISITS_SAVINGS' || resourceCode == ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING || resourceCode == ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING
            ? GET_VISITS
            : resourceCode == 'PROCEDURES_SAVINGS'
              ? GET_PRESCRIBED_PROCEDURES
              : GET_PRESCRIBED_MEDICATIONS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getAccData] = useLazyQuery(
    resourceCode == 'ERX_SAVINGS'
      ? GET_PRESCRIBED_MEDICATIONS_ACC
      : resourceCode == 'IMAGING_SAVINGS'
        ? GET_PRESCRIBED_IMAGING_ACC
        : resourceCode == 'LAB_SAVINGS'
          ? GET_PRESCRIBED_LAB_ACC
          : resourceCode == 'VISITS_SAVINGS' || resourceCode == ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING || resourceCode == ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING
            ? GET_VISITS_ACC
            : resourceCode == 'PROCEDURES_SAVINGS'
              ? GET_PRESCRIBED_PROCEDURES_ACC
              : GET_PRESCRIBED_MEDICATIONS_ACC,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const onClose = () => {
    setVisible(false);
    props.onCancel?.();
    props.onSendCallBackData?.(resourceCode)
  };
  useEffect(() => {
    setStateData((prev) => {
      return {
        tableData: [],
        isLoading: true,
      }
    });
    getAllData();
  }, [props?.isVisible]);

  const getAllData = () => {
    if (getTenantForDemo()) {
      const demoData: any = resourceCode == 'ERX_SAVINGS'
        ? VISIT_SAVING_DRAWER_DATA
        : resourceCode == 'IMAGING_SAVINGS'
          ? IMG_SAVING_DRAWER_DATA
          : resourceCode == 'LAB_SAVINGS'
            ? LAB_SAVING_DRAWER_DATA
            : resourceCode == 'VISITS_SAVINGS' || resourceCode == ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING || resourceCode == ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING
              ? VISIT_SAVING_DRAWER_DATA
              : resourceCode == 'PROCEDURES_SAVINGS'
                ? PROC_SAVING_DRAWER_DATA
                : VISIT_SAVING_DRAWER_DATA

      setStateData(prev => {
        return {
          ...prev,
          tableData: demoData,
          isLoading: false
        }
      });
      return
    }

    const ids: any[] = []
    if (!props.isAccountDashboard) {
      if (resourceCode == 'LAB_SAVINGS') {
        let dataToSave = [] as any;
        dataToSave = formatLabCostSavingResp(props.masterData || []);
        setStateData(prev => {
          return {
            ...prev,
            tableData: dataToSave?.length ? [...dataToSave] : [],
            isLoading: false
          }
        });
      } else {
        props.masterData?.forEach((item: IMasterData) => {
          ids.push(item?.id)
        })
        getData({
          variables: {
            ids: ids,
            employerUuid: props.employerId,
            accountUuid: accountUuid,
          },
        }).then((resp) => {
          let dataToSave = [] as any;
          if (resourceCode == 'ERX_SAVINGS') {
            dataToSave = resp.data.prescribedMedication;
          } else if (resourceCode == 'IMAGING_SAVINGS') {
            dataToSave = resp.data.prescribedImaging;
          } else if (resourceCode == 'LAB_SAVINGS') {
            dataToSave = resp.data.prescribedLab;
          } else if (resourceCode == 'VISITS_SAVINGS' || resourceCode == ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING || resourceCode == ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING) {
            dataToSave = resp.data.visitTypes;
          } else if (resourceCode == 'PROCEDURES_SAVINGS') {
            dataToSave = resp.data.prescribedProcedure;
          }
          dataToSave = updateQtyInSection(dataToSave || [], props.masterData || []);
          setStateData(prev => {
            return {
              ...prev,
              tableData: dataToSave?.length ? [...dataToSave] : [],
              isLoading: false
            }
          });
        }).catch(() => {
          setStateData(prev => {
            return {
              ...prev,
              tableData: [],
              isLoading: false
            }
          });
        })
      }
    } else {
      if (resourceCode == 'LAB_SAVINGS') {
        let dataToSave = [] as any;
        dataToSave = formatLabCostSavingResp(props.masterData || []);
        setStateData(prev => {
          return {
            ...prev,
            tableData: dataToSave?.length ? [...dataToSave] : [],
            isLoading: false
          }
        });
      } else {
        props.masterData?.forEach((item: IMasterData) => {
          ids.push(item?.id)
        })
        getAccData({
          variables: {
            ids: ids,
            accountUuid: accountUuid,
          },
        }).then((resp) => {
          let dataToSave = [] as any;
          if (resourceCode == 'ERX_SAVINGS') {
            dataToSave = resp.data.prescribedMedication;
          } else if (resourceCode == 'IMAGING_SAVINGS') {
            dataToSave = resp.data.prescribedImaging;
          } else if (resourceCode == 'LAB_SAVINGS') {
            dataToSave = resp.data.prescribedLab;
          } else if (resourceCode == 'VISITS_SAVINGS' || resourceCode == ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING || resourceCode == ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING) {
            dataToSave = resp.data.visitTypes;
          } else if (resourceCode == 'PROCEDURES_SAVINGS') {
            dataToSave = resp.data.prescribedProcedure;
          }
          dataToSave = updateQtyInSection(dataToSave || [], props.masterData || []);
          setStateData(prev => {
            return {
              ...prev,
              tableData: dataToSave?.length ? [...dataToSave] : [],
              isLoading: false
            }
          });
        }).catch(() => {
          setStateData(prev => {
            return {
              ...prev,
              tableData: [],
              isLoading: false
            }
          });
        })
      }
    }
  }

  const [createPrescribedSelectedWidgetCostMutation] = useMutation(
    resourceCode == 'ERX_SAVINGS'
      ? CREATE_PRESCRIBED_MEDICATION_COST
      : resourceCode == 'IMAGING_SAVINGS'
        ? CREATE_PRESCRIBED_IMAGING_COST
        : resourceCode == 'LAB_SAVINGS'
          ? CREATE_PRESCRIBED_LAB_COST
          : resourceCode == 'VISITS_SAVINGS' || resourceCode == ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING || resourceCode == ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING
            ? CREATE_VISIT_COST
            : resourceCode == 'PROCEDURES_SAVINGS'
              ? CREATE_PRESCRIBED_PROCEDURES_COST
              : CREATE_PRESCRIBED_MEDICATION_COST
  );

  const [updatePrescribedSelectedWidgetCostMutation] = useMutation(
    resourceCode == 'ERX_SAVINGS'
      ? UPDATE_PRESCRIBED_MEDICATION_COST
      : resourceCode == 'IMAGING_SAVINGS'
        ? UPDATE_PRESCRIBED_IMAGING_COST
        : resourceCode == 'LAB_SAVINGS'
          ? UPDATE_PRESCRIBED_LAB_COST
          : resourceCode == 'VISITS_SAVINGS' || resourceCode == ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING || resourceCode == ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING
            ? UPDATE_VISIT_COST
            : resourceCode == 'PROCEDURES_SAVINGS'
              ? UPDATE_PRESCRIBED_PROCEDURES_COST
              : UPDATE_PRESCRIBED_MEDICATION_COST
  );

  const updatePrescribedMedicationCost: any = (
    record: any,
    priceRaw: any,
    marketPriceRaw: any,
    index: number
  ) => {
    setStateData((prev) => {
      return {
        ...prev,
        isLoading: true,
      }
    });
    const currentMonth = moment().format('MMM');
    const currentYear = moment().format('YYYY');
    const prescribedMedicationCostLocal =
      record?.[topLevelKey]?.[0]?.[secondLevelKey];

    // in case of lab we get array in second level key

    const id = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.id || record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.id;
    let price = 0.0;
    try {
      if (!isNaN(priceRaw)) {
        price = parseFloat(priceRaw) || 0.0;
      }
    } catch (error) {
      price = 0.0;
    }
    let currentMarketPrice = 0.0;
    try {
      if (!isNaN(marketPriceRaw)) {
        currentMarketPrice = parseFloat(marketPriceRaw) || 0.0;
      }
    } catch (error) {
      currentMarketPrice = 0.0;
    }

    prescribedMedicationCostLocal['marketPriceData'] = {
      [currentYear]: {
        [currentMonth]: currentMarketPrice
      }
    }
    prescribedMedicationCostLocal['priceData'] = {
      [currentYear]: {
        [currentMonth]: price
      }
    }

    const marketPriceData = prescribedMedicationCostLocal['marketPriceData'];
    const priceData = prescribedMedicationCostLocal['priceData'];
    updatePrescribedSelectedWidgetCostMutation({
      variables: {
        id,
        price,
        marketPriceData,
        priceData,
        currentMarketPrice,
      },
    })
      .then((resp) => {
        if (resourceCode !== 'LAB_SAVINGS') {
          getAllData();
        }
        try {
          if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.id) {
            record[topLevelKey][0][secondLevelKey][0].currentMarketPrice = currentMarketPrice;
            record[topLevelKey][0][secondLevelKey][0].price = price;
            if (resourceCode === 'LAB_SAVINGS') {
              const tempStateData = [...stateData?.tableData];
              tempStateData.forEach((singleData) => {
                if (singleData?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.id === record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.id) {
                  singleData[topLevelKey][0][secondLevelKey][0].currentMarketPrice = currentMarketPrice;
                  singleData[topLevelKey][0][secondLevelKey][0].price = price;
                }
              });
              setStateData((prev) => {
                return {
                  tableData: [...tempStateData],
                  isLoading: false,
                }
              });
            }

          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.id) {
            record[topLevelKey][0][secondLevelKey].currentMarketPrice = currentMarketPrice;
            record[topLevelKey][0][secondLevelKey].price = price;
            if (resourceCode === 'LAB_SAVINGS') {
              const tempStateData = [...stateData?.tableData];
              tempStateData.forEach((singleData) => {
                if (singleData?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.id === record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.id) {
                  singleData[topLevelKey][0][secondLevelKey].currentMarketPrice = currentMarketPrice;
                  singleData[topLevelKey][0][secondLevelKey].price = price;
                }
              });
              setStateData((prev) => {
                return {
                  tableData: [...tempStateData],
                  isLoading: false,
                }
              });
            }
          }
        } catch (error) {
        }

        setStateData((prev) => {
          return {
            ...prev,
            isLoading: false,
          }
        });
      })
      .catch((e) => {

      });
  };

  const topLevelKey =
    resourceCode == 'ERX_SAVINGS'
      ? 'accountPrescribedMedication'
      : resourceCode == 'IMAGING_SAVINGS'
        ? 'accountPrescribedImaging'
        : resourceCode == 'LAB_SAVINGS'
          ? 'accountPrescribedLabs'
          : resourceCode == 'VISITS_SAVINGS' || resourceCode == ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING || resourceCode == ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING
            ? 'accountVisits'
            : resourceCode == 'PROCEDURES_SAVINGS'
              ? 'accountPrescribedProcedure'
              : 'accountPrescribedMedication';
  const secondLevelKey =
    resourceCode == 'ERX_SAVINGS'
      ? 'prescribedMedicationCost'
      : resourceCode == 'IMAGING_SAVINGS'
        ? 'prescribedImagingCost'
        : resourceCode == 'LAB_SAVINGS'
          ? 'prescribedLabCosts'
          : resourceCode == 'VISITS_SAVINGS' || resourceCode == ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING || resourceCode == ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING
            ? 'visitCost'
            : resourceCode == 'PROCEDURES_SAVINGS'
              ? 'prescribedProcedureCost'
              : 'prescribedMedicationCost';

  const cptLevelKey =
    resourceCode == 'ERX_SAVINGS'
      ? 'prescribedMedicationCost'
      : resourceCode == 'IMAGING_SAVINGS'
        ? 'prescribedImagingCptcodes'
        : resourceCode == 'LAB_SAVINGS'
          ? 'prescribedLabCptcodes'
          : resourceCode == 'PROCEDURES_SAVINGS'
            ? 'prescribedProcedureCptcodes'
            : 'prescribedMedicationCost';

  const createPrescribedMedicationCost = (
    record: any,
    priceRaw: any,
    marketPriceRaw: any,
    index: number
  ) => {
    setStateData((prev) => {
      return {
        ...prev,
        isLoading: true,
      }
    });
    const id = record?.[topLevelKey]?.[0]?.id;
    const accountUuid = record?.[topLevelKey]?.[0]?.accountUuid;

    const currentMonth = moment().format('MMM');
    const currentYear = moment().format('YYYY');


    let price = 0.0;
    try {
      price = parseFloat(priceRaw) || 0;
    } catch (error) {
      price = 0.0;
    }

    let currentMarketPrice = 0.0;
    try {
      currentMarketPrice = parseFloat(marketPriceRaw) || 0;
    } catch (error) {
      currentMarketPrice = 0.0;
    }


    const marketPriceData = {
      [currentYear]: {
        [currentMonth]: currentMarketPrice
      }
    }
    const priceData = {
      [currentYear]: {
        [currentMonth]: price
      }
    }

    if (record?.[topLevelKey]?.[0]?.id) {
      record[topLevelKey][0][secondLevelKey] = [{
        price,
        priceData,
        marketPriceData,
        currentMarketPrice: parseFloat(marketPriceRaw),
      }];
    }
    createPrescribedSelectedWidgetCostMutation({
      variables: {
        id,
        accountUuid,
        price,
        marketPriceData,
        priceData,
        currentMarketPrice: parseFloat(marketPriceRaw),
      },
    })
      .then((resp) => {

        if (!record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.id) {
          const keys = Object.keys(resp?.data);
          if (record?.[topLevelKey]?.[0]?.id && keys?.length && resp?.data[keys[0]]?.id) {
            record[topLevelKey][0][secondLevelKey] = [resp?.data[keys[0]]];
            if (resourceCode === 'LAB_SAVINGS') {
              const tempStateData = [...stateData?.tableData];
              tempStateData.forEach((singleData) => {
                if (record?.[topLevelKey]?.[0]?.id === singleData[topLevelKey]?.id) {
                  if (singleData[topLevelKey][0][secondLevelKey]?.length === 0) {
                    singleData[topLevelKey][0][secondLevelKey] = [resp?.data[keys[0]]];
                  }
                }
              });
              setStateData((prev) => {
                return {
                  tableData: [...tempStateData],
                  isLoading: false,
                }
              });
            }

          }
        }
        if (resourceCode !== 'LAB_SAVINGS') {
          getAllData();
        }
        setStateData((prev) => {
          return {
            ...prev,
            isLoading: false,
          }
        });
      })
      .catch((e) => {

      });
  };

  const getCptNameFromData = (record: any) => {
    let cptName = '';
    const cptCodeData = record?.cptCodeData || record?.cptCode;
    if (cptCodeData && Object.keys(cptCodeData)?.length) {
      if (cptCodeData?.description && cptCodeData?.code) {
        cptName = `${cptCodeData?.description} (${cptCodeData?.code})`;
      } else if (cptCodeData?.description) {
        cptName = `${cptCodeData?.description}`;
      } else if (cptCodeData?.code) {
        cptName = `${cptCodeData?.code}`;
      } else {
        cptName = '';
      }
    }
    return cptName;
  }

  const getTotalSavingsForTest = (record: any) => {
    let originalMarketValue;
    let originalPriceValue;
    if (topLevelKey === 'accountPrescribedLabs' || topLevelKey === 'accountPrescribedProcedure') {
      originalMarketValue = getMarketPrice(record);
      originalPriceValue = getOriginalPrice(record);
    } else {
      originalMarketValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice || '0';
      originalPriceValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price || '0';
    }
    const quantity = record.quantity || 1;
    const totalSaving = (originalMarketValue - originalPriceValue) * quantity;
    return totalSaving;
  }

  const getTotalSavingsForCpt = (record: any) => {
    let originalMarketValue;
    let originalPriceValue;
    if (topLevelKey === 'accountPrescribedLabs') {
      originalMarketValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice
          ? record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice || record.marketCost :
          '0';
      originalPriceValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price
          ? (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price || record.dpcCost) :
          '0';
    } else if (topLevelKey === 'accountPrescribedProcedure') {
      originalMarketValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice
          ? record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice || record.marketCost :
          '0';
      originalPriceValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price
          ? (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price || record.dpcCost) :
          '0';
    } else if (topLevelKey === 'accountPrescribedImaging') {
      originalMarketValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice
          ? record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice || record.marketCost :
          '0';
      originalPriceValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price
          ? (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price || record.dpcCost) :
          '0';
    } else {
      originalMarketValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice || '0';
      originalPriceValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price || '0';
    }
    const quantity = record.quantity || 1;
    const totalSaving = (originalMarketValue - originalPriceValue) * quantity;
    return totalSaving;
  }

  const getDiscountForCpt = (record: any) => {
    let originalMarketValue;
    let originalPriceValue;
    if (topLevelKey === 'accountPrescribedLabs') {
      originalMarketValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice
          ? record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice || record.marketCost :
          '0';
      originalPriceValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price
          ? (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price || record.dpcCost) :
          '0';
    } else if (topLevelKey === 'accountPrescribedProcedure') {
      originalMarketValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice
          ? record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice || record.marketCost :
          '0';
      originalPriceValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price
          ? (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price || record.dpcCost) :
          '0';
    } else if (topLevelKey === 'accountPrescribedImaging') {
      originalMarketValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice
          ? record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice || record.marketCost :
          '0';
      originalPriceValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price
          ? (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price || record.dpcCost) :
          '0';
    } else {
      originalMarketValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice
          ? record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice || record.marketCost :
          '0';
      originalPriceValue =
        record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price
          ? (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price || record.dpcCost) :
          '0';
    }
    const discountNum = (originalMarketValue && originalMarketValue != '0'
      ? ((originalMarketValue - originalPriceValue) /
        originalMarketValue) *
      100
      : 0
    ).toFixed(2);
    return discountNum;
  }

  const getDiscountForTest = (record: any) => {
    const cptLevelDataList = record?.[cptLevelKey];
    let originalMarketValue: any = 0;
    let originalPriceValue: any = 0;
    cptLevelDataList?.forEach((singlePtMarketData: any) => {
      let tempOriginalMarketValue;
      if (topLevelKey === 'accountPrescribedLabs') {
        tempOriginalMarketValue = singlePtMarketData?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice;
      } else if (topLevelKey === 'accountPrescribedProcedure') {
        tempOriginalMarketValue = singlePtMarketData?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice;
      } else if (topLevelKey === 'accountPrescribedImaging') {
        tempOriginalMarketValue = singlePtMarketData?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice;
      } else {
        tempOriginalMarketValue =
          singlePtMarketData?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice
            ||
            getTenantForDemo()
            ? (singlePtMarketData?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice || record.marketCost) :
            '0';
      }
      if (tempOriginalMarketValue && !isNaN(tempOriginalMarketValue)) {
        originalMarketValue += parseFloat(tempOriginalMarketValue);
      }
    });
    cptLevelDataList?.forEach((singlePtPriceData: any) => {
      let tempOriginalPriceValue;
      if (topLevelKey === 'accountPrescribedLabs') {
        tempOriginalPriceValue = singlePtPriceData?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price;
      } else if (topLevelKey === 'accountPrescribedProcedure') {
        tempOriginalPriceValue = singlePtPriceData?.[topLevelKey]?.[0]?.[secondLevelKey]?.price;
      } else if (topLevelKey === 'accountPrescribedImaging') {
        tempOriginalPriceValue = singlePtPriceData?.[topLevelKey]?.[0]?.[secondLevelKey]?.price;
      } else {
        tempOriginalPriceValue =
          singlePtPriceData?.[topLevelKey]?.[0]?.[secondLevelKey]?.price
            ||
            getTenantForDemo()
            ? singlePtPriceData?.[topLevelKey]?.[0]?.[secondLevelKey]?.price :
            '0';
      }
      if (tempOriginalPriceValue && !isNaN(tempOriginalPriceValue)) {
        originalPriceValue += parseFloat(tempOriginalPriceValue);
      }
    });
    const discountNum = (originalMarketValue && originalMarketValue != '0'
      ? ((originalMarketValue - originalPriceValue) /
        originalMarketValue) *
      100
      : 0
    ).toFixed(2);
    return discountNum;
  }

  const getOriginalPrice = (record: any) => {
    let originalPriceValue = 0;
    const cptLevelDataList = record?.[cptLevelKey];
    cptLevelDataList?.forEach((singlePtPriceData: any) => {
      let tempOriginalPriceValue;
      if (topLevelKey === 'accountPrescribedLabs') {
        tempOriginalPriceValue = singlePtPriceData?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price;
      } else if (topLevelKey === 'accountPrescribedProcedure') {
        tempOriginalPriceValue = singlePtPriceData?.[topLevelKey]?.[0]?.[secondLevelKey]?.price;
      } else if (topLevelKey === 'accountPrescribedImaging') {
        tempOriginalPriceValue = singlePtPriceData?.[topLevelKey]?.[0]?.[secondLevelKey]?.price;
      } else {
        tempOriginalPriceValue =
          singlePtPriceData?.[topLevelKey]?.[0]?.[secondLevelKey]?.price
            ||
            getTenantForDemo()
            ? singlePtPriceData?.[topLevelKey]?.[0]?.[secondLevelKey]?.price :
            '0';
      }
      if (tempOriginalPriceValue && !isNaN(tempOriginalPriceValue)) {
        originalPriceValue += parseFloat(tempOriginalPriceValue);
      }
    });
    return originalPriceValue;
  }

  const getMarketPrice = (record: any) => {
    const cptLevelDataList = record?.[cptLevelKey];
    let originalMarketValue = 0;
    cptLevelDataList?.forEach((singlePtMarketData: any) => {
      let tempOriginalMarketValue;
      if (topLevelKey === 'accountPrescribedLabs') {
        tempOriginalMarketValue = singlePtMarketData?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice;
      } else if (topLevelKey === 'accountPrescribedProcedure') {
        tempOriginalMarketValue = singlePtMarketData?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice;
      } else if (topLevelKey === 'accountPrescribedImaging') {
        tempOriginalMarketValue = singlePtMarketData?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice;
      } else {
        tempOriginalMarketValue =
          singlePtMarketData?.[topLevelKey]?.[0]?.currentMarketPrice
            ||
            getTenantForDemo()
            ? (singlePtMarketData?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice || record.marketCost) :
            '0';
      }
      if (tempOriginalMarketValue && !isNaN(tempOriginalMarketValue)) {
        originalMarketValue += parseFloat(tempOriginalMarketValue);
      }
    });
    return originalMarketValue;
  }

  const getExtendedSavingDrawerTableColumn = (extendedSingleRecordData: any, index: number, indent: number, expanded: boolean) => {
    let extendedDataList = [];
    const columns: TableColumnsType<any> = [
      {
        title: (
          <DisplayText
            size={'mdMedium'}
            extraStyles={{color: '#000000'}}
            textLocalId="cptCode"
          />
        ),
        key: 'cptCode',
        width: 150,
        render: (value: any, record: any, index: any) => {
          return (
            <Text
              key={record?.id}
              size={'smMedium'}
              style={{
                color: '#000000',
                fontSize: 15,
                fontWeight: '400',
              }}
            >
              {getCptNameFromData(record)}
            </Text>
          );

        },
      },
      {
        title: (
          <DisplayText
            size={'mdMedium'}
            extraStyles={{color: '#000000'}}
            textLocalId="marketCost"
          />
        ),
        key: 'marketCost',
        width: 100,
        render: (valueRecord: any, record: any, index: any) => {
          let originalMarketValue = 0;
          let originalPriceValue = 0;
          if (getTenantForDemo()) {
            originalMarketValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0].currentMarketPrice || record.marketCost;
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice) {
            originalMarketValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice;
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice) {
            originalMarketValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice
          }

          if (getTenantForDemo()) {
            originalPriceValue = (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price || record.dpcCost);
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price) {
            originalPriceValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price;
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price) {
            originalPriceValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price;
          }
          return (
            <InputNumber
              key={record?.id}
              disabled={isEmployer}
              onBlur={(value: any) => {
                if (!value?.target?.value) {
                  return;
                }
                if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.id || record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.id) {
                  updatePrescribedMedicationCost(
                    record,
                    originalPriceValue,
                    value?.target?.value,
                    index
                  );
                } else {
                  createPrescribedMedicationCost(
                    record,
                    originalPriceValue,
                    value?.target?.value,
                    index
                  );
                }
              }}
              defaultValue={originalMarketValue}
              controls={false}
              addonBefore={
                <Text
                  size={'smMedium'}
                  style={{
                    color: '#000000',
                    fontSize: 15,
                    fontWeight: '400',
                  }}
                >
                  {`$`}
                </Text>
              }
            />
          );
        },
      },
      {
        title: (
          <DisplayText
            size={'mdMedium'}
            extraStyles={{color: '#000000'}}
            textLocalId="ourCost"
          />
        ),
        key: 'dpcCost',
        width: 100,
        render: (value: any, record: any, index: any) => {
          let originalMarketValue = 0;
          let originalPriceValue = 0;
          if (getTenantForDemo()) {
            originalMarketValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0].currentMarketPrice || record.marketCost;
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice) {
            originalMarketValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice;
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice) {
            originalMarketValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice
          }

          if (getTenantForDemo()) {
            originalPriceValue = (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price || record.dpcCost);
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price) {
            originalPriceValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price;
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price) {
            originalPriceValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price;
          }
          return (
            <InputNumber
              key={record?.id}
              disabled={isEmployer}
              onBlur={(value: any) => {
                if (!value?.target?.value) {
                  return;
                }
                if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.id || record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.id) {
                  updatePrescribedMedicationCost(
                    record,
                    value?.target?.value,
                    originalMarketValue,
                    index
                  );
                } else {
                  createPrescribedMedicationCost(
                    record,
                    value?.target?.value,
                    originalMarketValue,
                    index
                  );
                }
              }}
              defaultValue={originalPriceValue}
              controls={false}
              addonBefore={
                <Text
                  size={'smMedium'}
                  style={{
                    color: '#000000',
                    fontSize: 15,
                    fontWeight: '400',
                  }}
                >
                  {`$`}
                </Text>
              }
            />
          );
        },
      },
      {
        title: (
          <DisplayText
            size={'mdMedium'}
            extraStyles={{color: '#000000'}}
            textLocalId="discount"
          />
        ),
        key: 'discount',
        width: 80,
        render: (value: any, record: any, index: any) => {
          let discountNum = getDiscountForCpt(record);
          if (getTenantForDemo()) {
            discountNum = (((parseInt(record?.marketCost) - parseInt(record?.dpcCost)) / parseInt(record?.marketCost)) * 100).toFixed(2);
          }
          return (
            <Text
              key={record?.id}
              size={'smNormal'}
              style={{
                fontSize: 14,
              }}
            >
              {discountNum}
              %
            </Text>
          );
        },
      },
      {
        title: (
          <DisplayText
            size={'mdMedium'}
            extraStyles={{color: '#000000'}}
            textLocalId="totalSaving"
          />
        ),
        key: 'totalSaving',
        width: 80,
        render: (value: any, record: any, index: any) => {
          const quantity = record.quantity || 1;
          let totalSaving = getTotalSavingsForCpt(record);
          if (getTenantForDemo()) {
            totalSaving = ((parseInt(record?.marketCost) - parseInt(record?.dpcCost)) * parseInt(quantity));
          }
          return (
            <Text
              key={record?.id}
              size={'smNormal'}
              style={{
                fontSize: 14,
              }}
            >
              ${(totalSaving < 0 ? 0 : totalSaving).toFixed(2).toLocaleString()}
            </Text>
          );
        },
      },
    ];

    if (resourceCode === 'LAB_SAVINGS') {
      if (extendedSingleRecordData?.prescribedLabCptcodes?.length) {
        extendedSingleRecordData?.prescribedLabCptcodes.forEach((singleCptData: any) => {
          singleCptData.quantity = extendedSingleRecordData.quantity;
        });
        extendedDataList = extendedSingleRecordData?.prescribedLabCptcodes;
      } else if (extendedSingleRecordData?.accountPrescribedLab?.length) {
        const tempLabData = extendedSingleRecordData?.accountPrescribedLab[0];
        tempLabData.name = extendedSingleRecordData?.name || '';
        tempLabData.quantity = extendedSingleRecordData?.quantity || 1;
        extendedDataList = [tempLabData]
      }
    } else if (resourceCode === 'IMAGING_SAVINGS') {
      if (extendedSingleRecordData?.prescribedImagingCptcodes?.length) {
        extendedSingleRecordData?.prescribedImagingCptcodes.forEach((singleCptData: any) => {
          singleCptData.quantity = extendedSingleRecordData.quantity;
        });
        extendedDataList = extendedSingleRecordData?.prescribedImagingCptcodes;
      } else if (extendedSingleRecordData?.accountPrescribedImaging?.length) {
        const tempImagingData = extendedSingleRecordData?.accountPrescribedImaging[0];
        tempImagingData.name = extendedSingleRecordData?.name || '';
        tempImagingData.quantity = extendedSingleRecordData?.quantity || 1;
        extendedDataList = [tempImagingData];
      }
    } else if (resourceCode === 'PROCEDURES_SAVINGS') {
      if (extendedSingleRecordData?.prescribedProcedureCptcodes?.length) {
        extendedSingleRecordData?.prescribedProcedureCptcodes.forEach((singleCptData: any) => {
          singleCptData.quantity = extendedSingleRecordData.quantity;
        });
        extendedDataList = extendedSingleRecordData?.prescribedProcedureCptcodes;
      } else if (extendedSingleRecordData?.accountPrescribedProcedure?.length) {
        const tempProcData = extendedSingleRecordData?.accountPrescribedProcedure[0];
        tempProcData.name = extendedSingleRecordData?.name || '';
        tempProcData.quantity = extendedSingleRecordData?.quantity || 1;
        extendedDataList = [tempProcData]
      }
    }

    return <Table columns={columns} dataSource={extendedDataList || []} pagination={false} />;
  };


  const categoryColumns: TableColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'mdMedium'}
          extraStyles={{color: '#000000'}}
          textLocalId="name"
        />
      ),
      key: 'name',
      width: 150,
      render: (value: any, record: any, index: any) => {
        if ((resourceCode == 'VISITS_SAVINGS' || resourceCode == ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING || resourceCode == ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING) && !getTenantForDemo()) {
          const visitDisplayText = `${record?.accountVisits?.[0]?.displayName} (${record?.code})`
          return (
            <Tooltip title={record?.accountVisits?.[0]?.displayName} placement="top">
              <Text
                key={record?.id}
                size={'smMedium'}
                textOverflow={'ellipsis'}
                style={{
                  color: '#000000',
                  fontSize: 15,
                  fontWeight: '400',
                }}
              >
                {visitDisplayText}
              </Text>
            </Tooltip>
          );
        } else {
          return (
            <Text
              key={record?.id}
              size={'smMedium'}
              style={{
                color: '#000000',
                fontSize: 15,
                fontWeight: '400',
              }}
            >
              {record.name}
            </Text>
          );
        }

      },
    },
    {
      title: (
        <DisplayText
          size={'mdMedium'}
          extraStyles={{color: '#000000'}}
          textLocalId="qty"
        />
      ),
      key: 'qty',
      width: 100,
      render: (value: any, record: any, index: any) => {
        return (
          <Text
            key={record?.id}
            size={'smNormal'}
            style={{
              fontSize: 14,
            }}
          >
            {record.quantity || 0}
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'mdMedium'}
          extraStyles={{color: '#000000'}}
          textLocalId="marketCost"
        />
      ),
      key: 'marketCost',
      width: 100,
      render: (valueRecord: any, record: any, index: any) => {
        if (record?.[cptLevelKey]?.length) {
          const originalMarketValue = getMarketPrice(record);
          return (
            <Text
              size={'smMedium'}
              style={{
                color: '#000000',
                fontSize: 15,
                fontWeight: '400',
              }}
            >
              {`$`} {originalMarketValue}
            </Text>
          )
        } else {
          const currentMonth = moment().format('MMM');
          const currentYear = moment().format('YYYY');

          let originalMarketValue;
          let originalPriceValue: any;
          if (getTenantForDemo()) {
            originalMarketValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0].currentMarketPrice || record.marketCost;
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice) {
            originalMarketValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice;
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice) {
            originalMarketValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice
          }

          if (getTenantForDemo()) {
            originalPriceValue = (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price || record.dpcCost);
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price) {
            originalPriceValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price;
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price) {
            originalPriceValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price;
          }
          return (
            <InputNumber
              key={record?.id}
              disabled={isEmployer}
              onBlur={(value: any) => {
                if (!value?.target?.value) {
                  return;
                }
                if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.id || record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.id) {
                  updatePrescribedMedicationCost(
                    record,
                    originalPriceValue,
                    value?.target?.value,
                    index
                  );
                } else {
                  createPrescribedMedicationCost(
                    record,
                    originalPriceValue,
                    value?.target?.value,
                    index
                  );
                }
              }}
              defaultValue={originalMarketValue}
              controls={false}
              addonBefore={
                <Text
                  size={'smMedium'}
                  style={{
                    color: '#000000',
                    fontSize: 15,
                    fontWeight: '400',
                  }}
                >
                  {`$`}
                </Text>
              }
            />
          );
        }

      },
    },
    {
      title: (
        <DisplayText
          size={'mdMedium'}
          extraStyles={{color: '#000000'}}
          textLocalId="ourServiceCost"
        />
      ),
      key: 'dpcCost',
      width: 100,
      render: (value: any, record: any, index: any) => {
        if (record?.[cptLevelKey]?.length) {
          const originalPriceValue = getOriginalPrice(record);
          return (
            <Text
              size={'smMedium'}
              style={{
                color: '#000000',
                fontSize: 15,
                fontWeight: '400',
              }}
            >
              {`$`} {originalPriceValue}
            </Text>
          )
        } else {
          const currentMonth = moment().format('MMM');
          const currentYear = moment().format('YYYY');

          let originalMarketValue =
            record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice
              ||
              getTenantForDemo()
              ? record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice || record.marketCost :
              '0';
          let originalPriceValue =
            record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price
              ||
              getTenantForDemo()
              ? (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price || record.dpcCost) :
              '0';
          if (getTenantForDemo()) {
            originalMarketValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0].currentMarketPrice || record.marketCost;
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice) {
            originalMarketValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.currentMarketPrice;
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice) {
            originalMarketValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.currentMarketPrice
          }

          if (getTenantForDemo()) {
            originalPriceValue = (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price || record.dpcCost);
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price) {
            originalPriceValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price;
          } else if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price) {
            originalPriceValue = record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.price;
          }
          return (
            <InputNumber
              key={record?.id}
              disabled={isEmployer}
              onBlur={(value: any) => {
                if (!value?.target?.value) {
                  return;
                }
                if (record?.[topLevelKey]?.[0]?.[secondLevelKey]?.id || record?.[topLevelKey]?.[0]?.[secondLevelKey]?.[0]?.id) {
                  updatePrescribedMedicationCost(
                    record,
                    value?.target?.value,
                    originalMarketValue,
                    index
                  );
                } else {
                  createPrescribedMedicationCost(
                    record,
                    value?.target?.value,
                    originalMarketValue,
                    index
                  );
                }
              }}
              defaultValue={originalPriceValue}
              controls={false}
              addonBefore={
                <Text
                  size={'smMedium'}
                  style={{
                    color: '#000000',
                    fontSize: 15,
                    fontWeight: '400',
                  }}
                >
                  {`$`}
                </Text>
              }
            />
          );
        }

      },
    },
    {
      title: (
        <DisplayText
          size={'mdMedium'}
          extraStyles={{color: '#000000'}}
          textLocalId="discount"
        />
      ),
      key: 'discount',
      width: 80,
      render: (value: any, record: any, index: any) => {
        const currentMonth = moment().format('MMM');
        const currentYear = moment().format('YYYY');
        let discountNum: any = 0;
        if (record?.[cptLevelKey]?.length) {
          discountNum = getDiscountForTest(record);
        } else {
          const originalMarketValue =
            record?.[topLevelKey]?.[0]?.[secondLevelKey]?.marketPriceData?.[
            currentYear
            ]?.[currentMonth] || '0';
          const originalPriceValue =
            record?.[topLevelKey]?.[0]?.[secondLevelKey]?.price || '0';
          discountNum = (originalMarketValue && originalMarketValue != '0'
            ? ((originalMarketValue - originalPriceValue) /
              originalMarketValue) *
            100
            : 0
          ).toFixed(2);
          if (getTenantForDemo()) {
            discountNum = (((parseInt(record?.marketCost) - parseInt(record?.dpcCost)) / parseInt(record?.marketCost)) * 100).toFixed(2);
          }
        }
        return (
          <Text
            key={record?.id}
            size={'smNormal'}
            style={{
              fontSize: 14,
            }}
          >
            {discountNum}
            %
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'mdMedium'}
          extraStyles={{color: '#000000'}}
          textLocalId="totalSaving"
        />
      ),
      key: 'totalSaving',
      width: 80,
      render: (value: any, record: any, index: any) => {
        let totalSaving = getTotalSavingsForTest(record);
        const quantity = record.quantity || 1;
        if (getTenantForDemo()) {
          totalSaving = ((parseInt(record?.marketCost) - parseInt(record?.dpcCost)) * parseInt(quantity));
        }
        return (
          <Text
            key={record?.id}
            size={'smNormal'}
            style={{
              fontSize: 14,
            }}
          >
            ${(totalSaving < 0 ? 0 : totalSaving).toFixed(2).toLocaleString()}
          </Text>
        );
      },
    },
  ];



  return (
    <>
      <Drawer
        title={null}
        destroyOnClose
        placement="right"
        onClose={() => {
          onClose();
        }}
        visible={visible}
        closable={true}
        width={width * 0.55}
        headerStyle={{
          display: 'none',
        }}
      >
        <HStack justifyContent={'space-between'} alignItems="center">
          <VStack>
            <DisplayText
              size={'smBold'}
              extraStyles={{
                fontSize: 30,
                color: '#4f2d90'
              }}
              textLocalId={resourceCode}
            />
            <Text
              size={'smNormal'}
              style={{
                color: '#AFAFAF',
                fontSize: 15,
              }}
            >
                 {`From ${getDateStrFromFormat(props.dateRange?.fromDate, 'MMM YY')} to ${getDateStrFromFormat(props.dateRange?.toDate, 'MMM YY')}`}
            </Text>
          </VStack>
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.SECONDARY,
              onPress: () => {
                props?.onCancel?.();
              },
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'cancel',
              }),
              withRightBorder: false,
            }}
          ></FoldButton>
        </HStack>
        <View
          style={{
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          {/* <Text
            size={'smMedium'}
            style={{
              color: '#000000',
              fontSize: 15,
            }}
          >
            Price unit - Per strip
          </Text> */}
        </View>
        {stateData.isLoading && (
          <View
            height={'full'}
            width={'full'}
            position="absolute"
            zIndex={10}
            bg={'coolGray.50:alpha.70'}
          >
            <Center
              top={'30%'}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Spinner size={'lg'} />
            </Center>
          </View>
        )}
        {
          (resourceCode === 'IMAGING_SAVINGS' || resourceCode === 'LAB_SAVINGS' || resourceCode === 'PROCEDURES_SAVINGS') ?
            (
              <Table
                key={'id'}
                columns={categoryColumns}
                pagination={{
                  position: ['bottomCenter'],
                }}
                dataSource={stateData.tableData}
                expandable={{
                  expandedRowRender: getExtendedSavingDrawerTableColumn as any,
                  rowExpandable: (record) => {
                    const cptLevelDataList: any[] = record?.[cptLevelKey] || [];
                    return cptLevelDataList?.length > 0;
                  },
                }}
                rowClassName={(record, index) =>
                  index % 2 == 0 ? 'table-row-light' : ''
                }
                className="lead-list-table-view patient-list-view"
              />
            )
            :
            (
              <Table
                key={'id'}
                columns={categoryColumns}
                pagination={{
                  position: ['bottomCenter'],
                }}
                dataSource={stateData.tableData}
                rowClassName={(record, index) =>
                  index % 2 == 0 ? 'table-row-light' : ''
                }
                className="lead-list-table-view patient-list-view"
              />
            )
        }

      </Drawer>
    </>
  );
};

export default React.memo(SavingDrawer);

