import { useLazyQuery } from '@apollo/client';
import { View } from 'native-base';
import { useEffect, useState } from 'react';
import EmployerQueries from '../../../../services/Employer/EmployerQueries';
import {
  getAccountId,
  getAccountUUID,
  getUserEmployerId
} from '../../../../utils/commonUtils';
import { GraphMemberList } from '../../../common/AppDashboard/GraphMemberList';
import { GraphConfig } from '../../../common/AppDashboard/GraphUtils';
import { GRAPH_COLOR_CONSTS } from '../AnalyticsConsts';
import { AnalyticsWidget } from '../AnalyticsWidget';
import {
  SegmentValues,
  getContactListFilterQuery,
  getFormattedContactList,
  getLoggedContactsFilterQuery
} from './LoginEmployeeUtils';

const LoginEmployeeGraph = (props: {
  employerId?: string;
  isDashboard?: boolean;
}) => {
  const employerId = getUserEmployerId() || props.employerId;
  const accountUuid = getAccountUUID();
  const accountId = getAccountId();
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 1;
  const [state, setState] = useState({
    loading: false,
    error: false,
    total: 0,
    loggedIn: 0,
    notLoggedIn: 0,
    percent: 0,
    isOpen: false,
    selectedSegmentType: '',
    contactList: [] as any[],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    listLoading: false,
    openContactListDrawer: false,
    contactUuidList: [] as string[],
  });

  const [GetLoggedInContacts] = useLazyQuery(
    EmployerQueries.GetLoggedInContacts,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getEmployeesByLoginFilter] = useLazyQuery(
    EmployerQueries.GetEmployeesByLoginFilter,
    {
      fetchPolicy: 'no-cache',
    }
  );


  const getContactsList = () => {
    setState((prev) => {
      return {
        ...prev,
        listLoading: true,
      };
    });
    const where = getContactListFilterQuery({
      employerId,
      accountUuid,
      selectedSegmentType: state.selectedSegmentType,
      accountId,
    });
    getEmployeesByLoginFilter({
      variables: {
        where: where,
        limit: state.pageSize,
        offset: (state.page - 1) * state.pageSize,
      },
    })
      .then((response) => {
        const contactList = getFormattedContactList(response.data);
        const map: any = {}
        const contactUuidList = contactList.map((item) => item.uuid).filter((uuid) => {
          if (uuid && !map[uuid]) {
            map[uuid] = uuid;
            return true
          } else {
            return false;
          }
        });
        setState((prev) => {
          return {
            ...prev,
            listLoading: false,
            openContactListDrawer: true,
            contactUuidList,
          };
        });
      })
      .catch((error) => {

        setState((prev) => {
          return {
            ...prev,
            contactUuidList: [],
            listLoading: false,
          };
        });
      });
  };
  const [config, setConfig] = useState<any>();
  const getLoggedInContactStats = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const { where1, where2 } = getLoggedContactsFilterQuery({
        employerId,
        accountId,
      });
      const response = await GetLoggedInContacts({
        variables: {
          where1: where1,
          where2: where2,
        },
      });
      const loggedIn = response?.data?.login?.aggregate?.count || 0;
      const notLoggedIn = response?.data?.notLoggedIn?.aggregate?.count || 0;
      setState((prev) => {
        return {
          ...prev,
          loggedIn: loggedIn,
          notLoggedIn: notLoggedIn,
          total: loggedIn + notLoggedIn,
          loading: false,
        };
      });

      const graphData: { count: number; name: string; color: string }[] = [
        {
          count: loggedIn,
          name: SegmentValues.loggedIn,
          color: GRAPH_COLOR_CONSTS.POSITIVE,
        },
        {
          count: notLoggedIn,
          name: SegmentValues.neverLoggedIn,
          color: GRAPH_COLOR_CONSTS.NEGATIVE,
        },
      ];
      const config = {
        data: graphData,
        angleField: 'count',
        colorField: 'name',
        ...GraphConfig,
        onReady: (plot: any) => {
          plot.on('element:click', (args: any) => {
            const name = args.data.data.name;
            setState((prev) => {
              return {
                ...prev,
                isOpen: true,
                selectedSegmentType: name,
                total: args?.data?.data?.count,
              };
            });
          });
        },
      };
      setConfig(config)
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          list: [],
        };
      });
    }
  };



  useEffect(() => {
    getLoggedInContactStats();
  }, [employerId]);

  useEffect(() => {
    if (state.selectedSegmentType.length > 0 && state.isOpen) {
      setState((prev) => {
        return {
          ...prev,
          listLoading: true,
        };
      });
      getContactsList();
    }
  }, [state.selectedSegmentType, state.page, state.pageSize, state.isOpen]);



  return (
    <View flex={1}>
      {config && <AnalyticsWidget
        pieConfig={{
          ...config,
        }}
        height="358px"
        legend={{
          position: 'bottom',
          layout: 'horizontal',
          maxRow: 3,
          title: {
            title: undefined,
            spacing: 10,
            style: { fontSize: 0, lineWidth: 0, lineHeight: 0 },
          },
        }}
        title="Login Count"
        subtitle="Members who have logged into the app vs not logged into the App."
      />
      }

      {state.openContactListDrawer && (
        <GraphMemberList
          page={state.page}
          pageSize={state.pageSize}
          onPaginationChange={(page: number, pageSize: number) => {

            setState((prev) => {
              return {
                ...prev,
                page,
                pageSize
              }
            });
          }}
          total={state.total}
          title={'Member by Login'}
          isOpen={state.openContactListDrawer}
          contactUuidList={state.contactUuidList}
          onCompleteAction={() => {
            setState((prev) => {
              return {
                ...prev,
                contactList: [],
                page: DEFAULT_PAGE,
                pageSize: DEFAULT_PAGE_SIZE,
                selectedSegmentType: '',
                openContactListDrawer: false,
                isOpen: false,
                contactUuidList: [],
              };
            });
          }}
        />
      )}
    </View>
  );
};

export default LoginEmployeeGraph;
