import React from 'react';
import {Colors} from '../../../styles';

const CloseSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3536 4.35359C12.5488 4.15832 12.5488 3.84174 12.3536 3.64648C12.1583 3.45122 11.8417 3.45122 11.6464 3.64648L12.3536 4.35359ZM3.64648 11.6464C3.45122 11.8417 3.45122 12.1583 3.64648 12.3536C3.84174 12.5488 4.15832 12.5488 4.35359 12.3536L3.64648 11.6464ZM4.35355 3.64645C4.15829 3.45118 3.84171 3.45118 3.64645 3.64645C3.45118 3.84171 3.45118 4.15829 3.64645 4.35355L4.35355 3.64645ZM11.6464 12.3535C11.8417 12.5488 12.1583 12.5488 12.3535 12.3535C12.5488 12.1583 12.5488 11.8417 12.3535 11.6464L11.6464 12.3535ZM11.6464 3.64648L7.64646 7.64646L8.35357 8.35357L12.3536 4.35359L11.6464 3.64648ZM7.64646 7.64646L3.64648 11.6464L4.35359 12.3536L8.35357 8.35357L7.64646 7.64646ZM3.64645 4.35355L7.64646 8.35357L8.35357 7.64646L4.35355 3.64645L3.64645 4.35355ZM7.64646 8.35357L11.6464 12.3535L12.3535 11.6464L8.35357 7.64646L7.64646 8.35357Z"
        fill="#6F7A90"
      />
    </svg>
  );
};

export default CloseSvg;
