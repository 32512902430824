import {Icon} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  BUTTON_TYPE,
  FILTER_COMPONENT_CODE,
  PRODUCT_SERVICES_FLOW_TYPE_CODE,
} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import FilterByDateRange from '../../../../common/CommonFilters/FilterByDateRange';
import FilterByDoneOrder from '../../../../common/CommonFilters/FilterByDoneOrder';
import {ITableTopBarProps} from '../../../../common/TableTopBar';

export const getOrdersListTopBarButtons = (
  buttonClickFnList: (() => void)[],
  onActionPerformed: (actioncode: any, rawData: any) => void,
  tabType?: string
): ITableTopBarProps => {
  return {
    title: '',
    buttonList: [
      {
        id: 1,
        btnText: 'filterByDate',
        size: 'sm',
        backgroundColor: Colors.secondary['100'],
        textColor: Colors.secondary['800'],
        variant: BUTTON_TYPE.PRIMARY,
        borderColor: Colors.secondary['200'],
        isTransBtn: true,
        leftIcon: (
          <Icon
            color={Colors.Custom.mainPrimaryPurple}
            as={AntIcon}
            name={'calendar'}
            size="4"
          />
        ),
        content: (
          <>
            <FilterByDateRange
              returnFilterItems={(item: any) => {
                onActionPerformed(FILTER_COMPONENT_CODE.DATE, item);
              }}
            />
          </>
        ),
        btnClick: () => {
          // btnClick
        },
      },
      {
        id: 2,
        btnText: 'completedOrder',
        size: 'sm',
        backgroundColor: Colors.secondary['100'],
        borderColor: Colors.secondary['200'],
        textColor: Colors.secondary['800'],
        variant: BUTTON_TYPE.PRIMARY,
        isTransBtn: true,
        leftIcon: (
          <Icon
            color={Colors.Custom.mainPrimaryPurple}
            as={AntIcon}
            name={'carryout'}
            size="4"
          />
        ),
        content: (
          <>
            <FilterByDoneOrder
              returnFilterItems={(item: any) => {
                onActionPerformed(FILTER_COMPONENT_CODE.COMPLETED, item);
              }}
            />
          </>
        ),
        btnClick: () => {
          // btnClick
        },
      },
    ],
  };
};
