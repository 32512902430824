import { useQuery } from '@apollo/client';
import {Drawer} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {
  Spinner,
  useMediaQuery,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../constants';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import CommonService from '../../../../../services/CommonService/CommonService';
import {Colors} from '../../../../../styles';
import { getAccountId } from '../../../../../utils/commonUtils';
import { showToast, ToastType } from '../../../../../utils/commonViewUtils';
import { IThemeAttachments } from '../../../../BodyContainer/interface';
import {ModalActionInput} from '../../../../common/ModalActionCommonComponent/ModalActionInput';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import { ProfileUpload } from '../../../../common/ProfileUpload/ProfileUpload';
import { capitalizeText } from '../../../ContentManagement/CampaignManagement/Helper';
import {uploadImage } from '../../../ContentManagement/EmailTemplates/EmailTemplatesUtils';
import AccountQuery from './AccountQuery';
import {styles} from './EditAccountStyle';
import { validates } from './EditAccountUtils';
import { IEditAccountProps } from './interface';

const EditAccountDrawer = (props: IEditAccountProps) => {
  const {showDrawer, onClose} = props;
  const accountID = getAccountId();
  const commonData = useContext(CommonDataContext);
  const accountThemeConfig: any = commonData.accountThemeConfig || {};
  const tempThemeAdditionalData: string =
  accountThemeConfig.additionalAttributes || '{}';

const finalThemeAdditionalAttributes: IThemeAttachments = JSON.parse(
  tempThemeAdditionalData
);
  const [stateData, setStateData] = useState({
    loading: false,
    showDrawer: true,
    profileImgSrc: '' as any,
    profileImageBlob: {} as any,
    name: '',
    subtitle: '',
    description: '',
    websiteLink: '',
    twitterLink: '',
    facebookLink: '',
    instagramLink: '',
    errors: {} as any,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const getAccountDataRes = useQuery(AccountQuery.GetAccountsData, {
    fetchPolicy: 'no-cache',
    variables: {
      id: accountID,
    },
  });

  useEffect(() => {
    if(getAccountDataRes.data?.account){
      const accountData = getAccountDataRes.data?.account;
      setStateData((prev: any) => {
        return {
          ...prev,
           name: accountData?.name,
           subtitle: accountData?.subtitle,
           description: accountData?.description,
           profileImgSrc: accountData?.logo || finalThemeAdditionalAttributes?.attachment?.logo?.url || '',
           facebookLink: accountData?.facebookLink,
           twitterLink: accountData?.twitterLink,
           instagramLink: accountData?.instagramLink,
           websiteLink: accountData?.websiteLink,
        };
      });
    }
}, [getAccountDataRes])

  const handleProfileImageChange = (imageData: any, code: string) => {
    switch (code) {
      case 'setImage':
        setStateData({
          ...stateData,
          profileImgSrc: URL.createObjectURL(imageData?.file),
          profileImageBlob: imageData?.file,
        });
        break;
      case 'delete':
          setStateData({
            ...stateData,
            profileImgSrc: finalThemeAdditionalAttributes?.attachment?.logo?.url || '',
            profileImageBlob: {},
          });
          break;
    }
  };

  const onCancel = () => {
    setStateData((prev) => {
      return {
        ...prev,
        showDrawer: false,
      };
    });
    setTimeout(() => {
      onClose();
    }, 200);
  };

  const handleSubmit = () => {
    const {validate, errors} = validates(stateData);
    setStateData({...stateData, errors: errors});
    if (validate ) {
      setIsSubmitting(true);
      uploadAccount();
    }
  };

  const uploadAccount = async () => {
    let logoImage = ''
    if (stateData.profileImageBlob.uid) {
     logoImage = await uploadImage(stateData.profileImageBlob);
    }
      const commonService = CommonService.getCommonServiceInstance();
      const crmService = commonService.crmService;
      const paramsData= {
        accountName: stateData.name,
        subtitle: stateData.subtitle,
        description: stateData.description,
        facebookLink: stateData.facebookLink,
        websiteLink: stateData.websiteLink,
        instagramLink: stateData.instagramLink,
        twitterLink: stateData.twitterLink,
        logo: logoImage ?  logoImage : undefined,
      }

      crmService
        .post(`/edit_account_info`, paramsData, {
          headers: {
            'content-type': 'application/json',
          },
        })
        .then((responseMsg: any) => {
          setIsSubmitting(false);
          setTimeout(() => {
            onClose('ONCOMPLETE');
          }, 200);
          showToast(
            toast,
            'Account update successfully',
            ToastType.success
          );
          // eventBus.broadcastEvent(WEB_SOCKET_EVENT_CODE.PROFILE_URL_UPDATE, {
          //   name: name,
          //   profileImage: stateData.profileImgSrc,
          // });
          //onProfileUpdate();
        })
        .catch((err: any) => {
          setIsSubmitting(false);
          if(err?.response?.status == 400) {
            showToast(
              toast,
              err?.response?.data?.message,
              ToastType.info
            );
          }else {
          showToast(
            toast,
            'Something went wrong',
            ToastType.error
          );}
        });
   //}
    //else {
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '50vw' : '33vw';

  return (
    <Drawer
      visible={showDrawer}
      style={{minWidth: 700}}
      width={drawerWidth}
      onClose={() => {
        setTimeout(() => {
          onClose();
        }, 200);
      }}
      title={
        <>
          <ModalActionTitle
            title={'account'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onCancel();
                },
              },
              {
                show: true,
                id: 2,
                btnText: 'submit',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  handleSubmit();
                },
                isLoading: isSubmitting,
              },
            ]}
          />
        </>
      }
    >
      <View mx={0}>
        <Content>
          {stateData.loading ? (
            <Spinner size="lg" style={styles.spinnerStyle} />
          ) : (
            <></>
          )}
          <View>
            <VStack>
              <ProfileUpload
                handleProfileImageChange={handleProfileImageChange}
                profileImgSrc={stateData.profileImgSrc || ''}
                profileImageBlob={stateData.profileImageBlob}
              />

              <ModalActionInput
                fieldIs={'input'}
                label={'name'}
                placeholder={'Enter Name'}
                isRequired={true}
                errors={stateData.name.length ? false : true}
                errorText={stateData.errors?.name}
                value={stateData.name}
                onChangeText={(value: any) => {
                  setStateData({...stateData, name: value});
                }}
                extraStyle={{flex: 1}}
              />

              <ModalActionInput
                fieldIs={'input'}
                marginTop={2}
                placeholder={'Enter Subtitle'}
                label={'subtitle'}
                isRequired={false}
                value={stateData.subtitle}
                onChangeText={(value: any) => {
                    setStateData({...stateData, subtitle: value});
                }}
                extraStyle={{flex: 1}}
              />

            <ModalActionInput
                fieldIs={'textArea'}
                maxLength={500}
                marginTop={2}
                label={'about'}
                placeholder={'Enter About'}
                isRequired={false}
                value={stateData.description}
                onChangeText={(value: any) => {
                    setStateData({...stateData, description: value});
                }}
                extraStyle={{flex: 1, Height: '80px'}}
              />

            <ModalActionInput
                fieldIs={'input'}
                marginTop={2}
                label={'Twitter link'}
                placeholder={'Enter Twitter Link'}
                isRequired={false}
                value={stateData.twitterLink}
                errors={stateData.errors?.twitterLink ? true : false}
                errorText={stateData.errors?.twitterLink}
                onChangeText={(value: any) => {
                    setStateData({...stateData, twitterLink: value});
                }}
                extraStyle={{flex: 1}}
              />

            <ModalActionInput
                fieldIs={'input'}
                marginTop={2}
                label={'Instagram link'}
                placeholder={'Enter Instagram Link'}
                isRequired={false}
                errors={stateData.errors?.instagramLink ? true : false}
                errorText={stateData.errors?.instagramLink}
                value={stateData.instagramLink}
                onChangeText={(value: any) => {
                    setStateData({...stateData, instagramLink: value});
                }}
                extraStyle={{flex: 1}}
              />

            <ModalActionInput
                fieldIs={'input'}
                marginTop={2}
                label={'Facebook link'}
                placeholder={'Enter Facebook Link'}
                isRequired={false}
                errors={stateData.errors?.facebookLink ? true : false}
                errorText={stateData.errors?.facebookLink}
                value={stateData.facebookLink}
                onChangeText={(value: any) => {
                    setStateData({...stateData, facebookLink: value});
                }}
                extraStyle={{flex: 1}}
              />

            <ModalActionInput
                fieldIs={'input'}
                marginTop={2}
                label={'Website link'}
                placeholder={'Enter Website Link'}
                isRequired={false}
                value={stateData.websiteLink}
                errors={stateData.errors?.websiteLink ? true : false}
                errorText={stateData.errors?.websiteLink}
                onChangeText={(value: any) => {
                    setStateData({...stateData, websiteLink: value});
                }}
                extraStyle={{flex: 1}}
              />

            </VStack>
          </View>
        </Content>
      </View>
    </Drawer>
  );
};

export default EditAccountDrawer;
