import {Drawer} from 'antd';
import {FormControl, Input, Pressable, Spinner, Text, View, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useParams} from 'react-router-dom';
import {BUTTON_TYPE} from '../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {addOrUpdateCPTByTemplate} from '../../../../../../services/CostSavingTemplates/CostSavingService';
import {Colors} from '../../../../../../styles';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import DollorIcon from '../../../../../common/Svg/DollorIocn';
import {SavingTypeConst, SavingTypeNameConst} from '../helperFiles/CostTemplatesConst';
import {InputsStyles} from '../helperFiles/InputsStyles';
import {IAddSavingCPTDrawerProps} from '../helperFiles/interface';
import {styles} from '../helperFiles/Styles';

const AddSavingCPTsDrawer = (props: IAddSavingCPTDrawerProps) => {
  const {actionCode, selectedData, templateId, analyticalResourceId} = props;
  const {tabName}: any = useParams();
  const analyticalResource = SavingTypeNameConst[tabName]
  const {width} = Dimensions.get('window');
  const finalWidth = width / 3;
  const isEditView = actionCode === COMMON_ACTION_CODES.EDIT ? true : false;
  const showModifier = tabName === SavingTypeConst.VISITS_SAVINGS
  const isDuplicateView = actionCode === COMMON_ACTION_CODES.DUPLICATE ? true : false;
  const [showModal, setShowModal] = useState(true);
  const [cptListState, setCptListState] = useState([
    {
      code: '',
      name: '',
      price: 0,
      modifier: ''
    },
  ]);
  const [stateData, setStateData] = useState({
    loading: false
  })
  const [errors, setErrors] = useState([
    {
      name: '',
      code: '',
      price: '',
    },
  ]);
  const onCloseOrCancel = () => {
    props.onFormCompleteAction(COMMON_ACTION_CODES.CANCEL);
    setShowModal(false);
  };
  const onSaveActionDone = (data: any) => {
    props.onFormCompleteAction(COMMON_ACTION_CODES.SAVE, data);
    setShowModal(false);
  }
  const getFormattedModifierKeyValue = (item: any) => {
    let modifier = '[]'
    if (item.modifier) {
      const formattedModifier = item.modifier.split('-')
      const stringFyModifier = JSON.stringify(formattedModifier)?.replaceAll('"', '')
      modifier = stringFyModifier
    }
    return {
      modifier: modifier
    }
  }
  const onSubmit = async () => {
    const errorsLength = validateEntries();
    if (errorsLength.length) {
      return;
    }
    if (templateId) {
      if (isEditView) {
        setStateData(prev=> {
          return {
            ...prev,
            loading: true
          }
        })
        const formattedCptList = cptListState?.map((item: any) => {
          const modifierKeyValue: any = getFormattedModifierKeyValue(item)
          delete item.modifier;
          return {
            ...item,
            ...(showModifier && {...modifierKeyValue}),
            price:
              typeof item.price === 'string'
                ? parseFloat(item.price)
                : item.price,
          };
        })
        const updatedEntry = [
          {
            ...formattedCptList[0],
            id: selectedData.id,
          }
        ]
        const data = {
          id: templateId,
          analyticalResourceId: analyticalResourceId,
          cpts: updatedEntry
        }
        const response = await addOrUpdateCPTByTemplate(data).catch(()=> {
          setStateData(prev=> {
            return {
              ...prev,
              loading: false
            }
          })
        })
        setStateData(prev=> {
          return {
            ...prev,
            loading: false
          }
        })
        onSaveActionDone(response)
      } else {
        setStateData(prev=> {
          return {
            ...prev,
            loading: true
          }
        })
        const formattedCptList = cptListState?.map((item: any)=> {
          const modifierKeyValue: any = getFormattedModifierKeyValue(item)
          delete item.modifier;
          return {
            ...item,
            ...(showModifier && {...modifierKeyValue}),
            price:
              typeof item.price === 'string'
                ? parseFloat(item.price)
                : item.price,
          };
        })
        const data = {
          id: templateId,
          analyticalResourceId: analyticalResourceId,
          cpts: formattedCptList
        }
        const response = await addOrUpdateCPTByTemplate(data)
        setStateData(prev=> {
          return {
            ...prev,
            loading: false
          }
        })
        onSaveActionDone(response)
      }
    }

  };
  const addNewEntry = () => {
    const newEntry = {
      name: '',
      code: '',
      price: 0,
      modifier: ''
    };
    setCptListState((prev) => {
      return [...prev, newEntry];
    });
  };
  const handleRemoveEntry = (index: number) => {
    const updatedEntries = [...cptListState];
    if (updatedEntries.length < 2) {
      return;
    }
    updatedEntries.splice(index, 1);
    setCptListState(updatedEntries);
  };
  const handleInputChange = (index: any, value: any, code: string) => {
    const updatedEntries: any = [...cptListState];
    if (code === 'modifier') {
    const unformattedValue = value.replace(/-/g, '');
    let newValue = unformattedValue.match(/.{1,2}/g);

    newValue = newValue ? newValue.join('-') : '';
    updatedEntries[index][code] = newValue;
    } else {
      updatedEntries[index][code] = value;
    }

    setCptListState(updatedEntries);
  };
  const handleClearError = (index: any, code: string) => {
    const updatedErrors: any = [...errors];
    if (updatedErrors?.[index]?.[code]) {
      updatedErrors[index][code] = '';
      setErrors(updatedErrors);
    }
  };
  const validateEntries = () => {
    const newErrors: any = [];
    cptListState.forEach((entry) => {
      const {name, code, price} = entry;
      const errors = {} as any;

      if (!name.trim()) {
        errors.name = 'Name cannot be empty';
      }

      if (!code.trim()) {
        errors.code = 'Code cannot be empty';
      }

      if ( typeof price == 'string' && price == '' ) {
        errors.price = 'Price cannot be empty or less than zero.';
      }
      if (errors.name || errors.code || errors.price) {
        newErrors.push(errors);
      }
    });

    setErrors(newErrors);
    return newErrors;
  };

  useEffect(() => {
    if (isEditView || isDuplicateView) {
      let parsedModifier = selectedData?.modifier === '[]' ? '' : selectedData?.modifier ;
      let formattedModifier = ''
      if (parsedModifier) {
        parsedModifier = parsedModifier.slice(1, -1);
        const modifierInArray = parsedModifier.split(",");
         formattedModifier = modifierInArray.join('-')
      }
      const entry: any = {
        name: selectedData?.name || '',
        code: selectedData?.cptCode || '',
        price: selectedData?.costPrice || 0,
        modifier: formattedModifier,
        ...(isEditView && {accountCptId: selectedData?.rawData?.accountCptId})
      }
      setCptListState([entry])
    }
  }, [])

  return (
    <Drawer
      visible={showModal}
      width={finalWidth}
      onClose={() => {
        onCloseOrCancel();
      }}
      title={
        <ModalActionTitle
          title={isEditView ? 'updateCpts' : 'addCpts'}
          subtitleBlock={
            <Text size='lgSemibold' style={{color: Colors.Custom.Gray700}}>
              {analyticalResource}
            </Text>
          }
          buttonList={[
            {
              show: true,
              id: 2,
              btnText: isEditView ? 'update' : 'add',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              isDisabled: stateData.loading ? true : false,
              onClick: () => {
                onSubmit();
              },
            },
          ]}
          rightButton={
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <View
                style={{
                  width: 1.5,
                  backgroundColor: Colors.Custom.Gray200,
                  marginHorizontal: 8,
                  height: 20,
                }}
              />
              <Pressable
                onPress={() => {
                  onCloseOrCancel();
                }}
              >
                <AntIcon name="close" size={20} color={Colors.Custom.Gray500} />
              </Pressable>
            </View>
          }
        />
      }
    >

      <VStack space={3}>
      {stateData.loading ? (
        <Spinner size="lg" style={styles.spinnerStyle} />
      ) : (
        <></>
      )}
        {cptListState?.map((cptInputs, index) => {
          return (
            <View
              key={`${index}_CPT`}
              style={{
                borderWidth: 1,
                borderColor: Colors.Custom.Gray200,
                borderRadius: 8,
                paddingHorizontal: 8,
                paddingBottom: 14,
                position: 'relative',
              }}
            >
              {cptListState?.length > 1 && (
                <Pressable
                  style={{
                    alignItems: 'flex-end',
                    position: 'absolute',
                    right: 5,
                    top: 5,
                  }}
                  onPress={() => {
                    handleRemoveEntry(index);
                  }}
                >
                  <AntIcon
                    name="close"
                    size={20}
                    color={Colors.Custom.Gray500}
                  />
                </Pressable>
              )}
              <FormControl
                isRequired
                isInvalid={errors[index] && errors[index]?.name ? true : false}
                marginTop={4}
                style={InputsStyles.formElement}
              >
                <FormControl.Label style={InputsStyles.formLabel}>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{
                      color: Colors.Custom.Gray500,
                      fontWeight: 400,
                    }}
                    textLocalId={'name'}
                  />
                </FormControl.Label>
                <View
                  flex={1}
                  flexDirection={'row'}
                  alignItems={'center'}
                  width={'100%'}
                  borderColor={Colors.Custom.Gray200}
                  borderRadius={8}
                  borderWidth={1}
                >
                  <Input
                    flex={1}
                    borderWidth={0}
                    placeholderTextColor={Colors.Custom.Gray300}
                    color={'black'}
                    bgColor={'white'}
                    borderRadius={8}
                    height={44}
                    placeholder={'Enter CPT name'}
                    fontWeight={400}
                    fontSize={14}
                    value={cptInputs.name}
                    onChangeText={(value) => {
                      handleClearError(index, 'name');
                      handleInputChange(index, value, 'name');
                    }}
                  />
                </View>
                <FormControl.ErrorMessage
                  style={{
                    display:
                      errors[index] && errors[index]?.name ? 'flex' : 'none',
                  }}
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                  borderColor={'error.500'}
                >
                  {errors[index]?.name}
                </FormControl.ErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={errors[index] && errors[index]?.code ? true : false}
                marginTop={4}
                style={InputsStyles.formElement}
              >
                <FormControl.Label style={InputsStyles.formLabel}>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{
                      color: Colors.Custom.Gray500,
                      fontWeight: 400,
                    }}
                    textLocalId={'cptCode'}
                  />
                </FormControl.Label>
                <View
                  flex={1}
                  flexDirection={'row'}
                  alignItems={'center'}
                  width={'100%'}
                  borderColor={Colors.Custom.Gray200}
                  borderRadius={8}
                  borderWidth={1}
                >
                  <Input
                    flex={1}
                    borderWidth={0}
                    isDisabled={isEditView}
                    placeholderTextColor={Colors.Custom.Gray300}
                    color={'black'}
                    bgColor={'white'}
                    borderRadius={8}
                    height={44}
                    placeholder={'Enter CPT code'}
                    fontWeight={400}
                    fontSize={14}
                    value={cptInputs.code}
                    onChangeText={(value) => {
                      handleClearError(index, 'code');
                      handleInputChange(index, value, 'code');
                    }}
                  />
                </View>
                <FormControl.ErrorMessage
                  style={{
                    display:
                      errors[index] && errors[index]?.code ? 'flex' : 'none',
                  }}
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                  borderColor={'error.500'}
                >
                  {errors[index]?.code}
                </FormControl.ErrorMessage>
              </FormControl>
              <FormControl
                marginTop={4}
                style={[InputsStyles.formElement,{display: showModifier ? 'flex' : 'none'}]}
              >
                <FormControl.Label style={InputsStyles.formLabel}>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{
                      color: Colors.Custom.Gray500,
                      fontWeight: 400,
                    }}
                    textLocalId={'modifier'}
                  />
                </FormControl.Label>
                <View
                  flex={1}
                  flexDirection={'row'}
                  alignItems={'center'}
                  width={'100%'}
                  borderColor={Colors.Custom.Gray200}
                  borderRadius={8}
                  borderWidth={1}
                >
                  <Input
                    flex={1}
                    borderWidth={0}
                    placeholderTextColor={Colors.Custom.Gray300}
                    color={'black'}
                    bgColor={'white'}
                    borderRadius={8}
                    height={44}
                    placeholder={'Enter modifier'}
                    fontWeight={400}
                    fontSize={14}
                    value={cptInputs.modifier}
                    maxLength={11}
                    onChangeText={(value) => {
                      handleInputChange(index, value, 'modifier');
                    }}
                  />
                </View>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={errors[index] && errors[index]?.price ? true : false}
                marginTop={4}
                style={InputsStyles.formElement}
              >
                <FormControl.Label style={InputsStyles.formLabel}>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{
                      color: Colors.Custom.Gray500,
                      fontWeight: 400,
                    }}
                    textLocalId={'price'}
                  />
                </FormControl.Label>
                <View
                  flex={1}
                  flexDirection={'row'}
                  alignItems={'center'}
                  width={'100%'}
                  borderColor={Colors.Custom.Gray200}
                  borderRadius={8}
                  borderWidth={1}
                >
                  <Input
                    flex={1}
                    borderWidth={0}
                    placeholderTextColor={Colors.Custom.Gray300}
                    color={'black'}
                    bgColor={'white'}
                    borderRadius={8}
                    height={44}
                    placeholder={'Enter CPT Price'}
                    fontWeight={400}
                    fontSize={14}
                    value={cptInputs.price.toString()}
                    onChangeText={(value) => {
                      const regex = /^\d*\.?\d{0,2}$/;
                      handleClearError(index, 'price');
                      if (regex.test(value) || value === '') {
                        handleInputChange(index, value, 'price');
                      }
                    }}
                    InputLeftElement={
                      <View marginLeft={1}>
                        <DollorIcon />
                      </View>
                    }
                  />
                </View>
                <FormControl.ErrorMessage
                  style={{
                    display:
                      errors[index] && errors[index]?.price ? 'flex' : 'none',
                  }}
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                  borderColor={'error.500'}
                >
                  {errors[index]?.price}
                </FormControl.ErrorMessage>
              </FormControl>
            </View>
          );
        })}
        <Pressable
          onPress={() => {
            addNewEntry();
          }}
          style={{alignItems: 'flex-end', display: isEditView ? 'none' : 'flex'}}
        >
          <Text size={'xsMedium'} color={Colors.Custom.mainPrimaryPurple}>
            Add more
          </Text>
        </Pressable>
      </VStack>
    </Drawer>
  );
};

export default AddSavingCPTsDrawer;
