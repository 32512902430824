import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { useContext } from 'react';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { ILoginUserData } from '../../../../../Interfaces';
import AgentBotQueries from '../../../../../services/AgentBot/AgentBotQueries';
import { getAgentBotRecord } from '../Helper/formatIntegrationsData';
import { IAgentBotDropDown } from '../interfaces';
import { reactStyles } from '../IntegrationsStyles';

const { Option } = Select;

export const AgentBotDropDown = (props: IAgentBotDropDown) => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const accountId = userData.account_id;

  const onSelectAgentBot = (agentBotId: number) => {
    props.onChange(agentBotId);
  };

  const { loading: agentBotsLoading, data: agentBotsData } = useQuery(
    AgentBotQueries.GetAgentBots,
    {
      variables: { accountId: accountId },
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const formattedData = getAgentBotRecord(agentBotsData?.agentBots || []);

  return (
    <Select
      disabled={agentBotsLoading}
      value={agentBotsLoading ? undefined : (!props.agentBotId ? -1 : props.agentBotId)}
      style={reactStyles.selectStyle}
      placeholder={props.placeholder || 'Pick agent bot for the inbox'}
      onChange={(newValue) => {
        if (newValue !== props.agentBotId) {
          onSelectAgentBot(newValue);
        }
      }}
    >
      {formattedData.map((agentBotRecord: any, index: number) => (
        <Option key={index} value={agentBotRecord.agentBotId}>
          {agentBotRecord.agentBotName}
        </Option>
      ))}
    </Select>
  );
};
