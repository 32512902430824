import React, {useMemo, useState} from 'react';
import {useContactCareProgramContext} from '../../ContactCareProgram.context';
import {Divider, Pressable, Spinner, Text} from 'native-base';
import FoldButtonV2, {ButtonType} from '../../../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import {StepAction, useCareProgramStepActions} from '../../hooks/useCareProgramStepActions';
import {useCareProgramStatus} from '../../hooks/useCareProgramStatus';
import {ContactCareProgramReducerAction} from '../../reducer';
import {useMainContentContext} from '../MainContentView.context';
import {Colors} from '../../../../../../styles';
import useCareProgramStepStatus from '../../hooks/useCareProgramStepStatus';
import {Popover} from 'antd';
import ThreeDotsSvg from '../../../../../common/Svg/SideCarSvg/ThreeDotsSvg';
import Stack from '../../../../../common/LayoutComponents/Stack';
import { CARE_PROGRAM_STEP_TYPE } from '../../../../../common/MemebersView/constant';
import {usePersonOmniViewContext} from '../../../../../PersonOmniView/PersonOmniView.context';

const ContentHeader: React.FC = () => {
  const {state, dispatch} = useContactCareProgramContext();
  const {contactCareProgramDetails} = state;
  const {careProgramStatus} = useCareProgramStatus();
  const {formattedData: formattedContactData} = usePersonOmniViewContext();
  const isCareProgramCompleted = careProgramStatus.completed === contactCareProgramDetails?.statusId;
  const activeStep = contactCareProgramDetails?.contactCareProgramSteps?.find(
    (step) => step.id === state.selectedStepId
  );
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const activeStepCode = activeStep?.careProgramStepType?.code;
  const isBillingStep = activeStepCode === CARE_PROGRAM_STEP_TYPE.BILLING;
  const {mainContentContextState} = useMainContentContext();
  const title = activeStep?.title;

  const {careProgramStepStatus} = useCareProgramStepStatus();

  const {handleContentAction, isLoading, dropdownActionList} = useCareProgramStepActions({
    selectedStepCode: activeStepCode as string,
    extraParams: {
      contactId: formattedContactData?.contactUUID as string,
      patientId: formattedContactData?.patientId as string,
    },
  });

  const handleHeaderStatusActions = async (action: StepAction) => {
    const newStepLogStatusId = await handleContentAction(action, {
      careProgramDetails: contactCareProgramDetails,
      stepId: state.selectedStepId as string,
    });
    if (newStepLogStatusId) {
      dispatch?.({
        type: ContactCareProgramReducerAction.UPDATE_STEP_STATUS,
        payload: {
          newStepLogStatusId,
          id: state.selectedStepId,
          skipMoveToNextStep: [StepAction.SEND_CLAIM, StepAction.REVIEW].includes(action) && isBillingStep,
          statusesToCalculateNextStep: [
            careProgramStepStatus.in_progress,
            careProgramStepStatus.to_do,
          ],
        },
      });
    }
  };

  const handleHeaderDropdownActions = async (action: StepAction) => {
    setIsDropdownVisible(false);
    await handleContentAction(action, {
      careProgramDetails: contactCareProgramDetails,
      stepId: state.selectedStepId as string,
    });
  };


  const isDropdownLoading = useMemo(
    () => dropdownActionList.some((action) => isLoading === action.action),
    [dropdownActionList, isLoading]
  );

  const isDropdownDisabled = isDropdownLoading || mainContentContextState.headerSavingIndicator;
  const isActionDisabled = Boolean(isLoading || mainContentContextState.headerSavingIndicator);

  const selectedStepLog = useMemo(
    () =>
      state.contactCareProgramDetails?.stepsLog.find(
        (stepLog) => stepLog.careProgramStepId === state.selectedStepId
      ),
    [state.contactCareProgramDetails?.stepsLog.length, state.selectedStepId]
  );

  const isReadOnlyView = selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.done || selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.skip;
  const showStatusActions = !isCareProgramCompleted && !isBillingStep && !isReadOnlyView;
  const showSendClaim = isBillingStep && !isReadOnlyView && !isCareProgramCompleted;

  return (
    <div className="flex flex-row justify-between p-2 w-100 items-center">
      <div className="flex flex-row">
        <Text style={{fontSize: 16, fontWeight: '600'}}>{title}</Text>
      </div>
      <div className="flex flex-row items-center">
        {mainContentContextState.headerSavingIndicator ? (
          <Text color={Colors.Custom.Gray500} fontStyle={'italic'} mx={2}>
            Saving...
          </Text>
        ) : (
          <></>
        )}
        {showStatusActions && (
          <>
            <FoldButtonV2
              label="Skip"
              isLoading={isLoading === StepAction.SKIP}
              buttonType={ButtonType.secondary}
              onPress={() => handleHeaderStatusActions(StepAction.SKIP)}
              isDisabled={isActionDisabled}
            />
            <FoldButtonV2
              label="Reviewed"
              isLoading={isLoading === StepAction.REVIEW}
              buttonType={ButtonType.tertiary}
              onPress={() => handleHeaderStatusActions(StepAction.REVIEW)}
              isDisabled={isActionDisabled}
            />
          </>
        )}
        {showSendClaim && (
          <>
            <FoldButtonV2
              label="Reviewed"
              isLoading={isLoading === StepAction.REVIEW}
              buttonType={ButtonType.secondary}
              onPress={() => handleHeaderStatusActions(StepAction.REVIEW)}
              isDisabled={isActionDisabled}
            />
            <FoldButtonV2
              label="Send to EHR"
              isLoading={isLoading === StepAction.SEND_CLAIM}
              buttonType={ButtonType.tertiary}
              onPress={() => handleHeaderStatusActions(StepAction.SEND_CLAIM)}
              isDisabled={isActionDisabled}
            />
          </>
        )}
        {!!dropdownActionList?.length && <Popover
          overlayClassName="cm-action-popover"
          key={activeStep?.id}
          placement='bottomLeft'
          overlayInnerStyle={{padding: -8, borderRadius: 8}}
          overlayStyle={{padding: 0}}
          overlay={{padding: 0}}
          style={{padding: 0, borderRadius: 16}}
          trigger="click"
          open={isDropdownVisible}
          onOpenChange={(visible) => setIsDropdownVisible(visible)}
          content={() => (
            <Stack direction="column" style={{padding: 0, borderRadius: 16}}>
              {dropdownActionList.map((dropdownAction, index) => (
                <React.Fragment key={dropdownAction.action}>
                  <Pressable
                    style={{padding: 4, borderRadius: 4}}
                    onPress={() => {
                      handleHeaderDropdownActions(dropdownAction.action);
                    }}
                    _hover={{
                      backgroundColor: Colors.FoldPixel.GRAY50,
                    }}
                  >
                    <Stack
                      direction="row"
                      style={{padding: 4, justifyContent: 'space-between'}}
                      space={4}
                    >
                      <Stack
                        direction="row"
                        style={{alignItems: 'center'}}
                        space={4}
                      >
                        <Text
                          style={{
                            color: Colors.FoldPixel.GRAY400,
                            fontSize: 14,
                            fontWeight: '400',
                          }}
                        >
                          {dropdownAction.label}
                        </Text>
                      </Stack>
                    </Stack>
                  </Pressable>
                  {!!dropdownActionList[index + 1] &&
                    dropdownActionList[index + 1]?.action !==
                      dropdownAction.action && (
                      <Divider style={{marginTop: 8, marginBottom: 8}} />
                    )}
                </React.Fragment>
              ))}
            </Stack>
          )}
        >
          <Pressable
            onPress={() => {
              setIsDropdownVisible(true);
            }}
            _hover={{
              backgroundColor: Colors.FoldPixel.GRAY50,
            }}
          >
            {isDropdownLoading ? (
              <Spinner />
            ) : (
              <ThreeDotsSvg
                customStrokeColor={
                  isDropdownDisabled ? Colors?.FoldPixel?.GRAY150 : undefined
                }
              />
            )}
          </Pressable>
          </Popover>
        }
      </div>
    </div>
  );
};

export default ContentHeader;
