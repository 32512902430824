import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles/Colors';

export const styles = StyleSheet.create({
  container: {
    //padding: 12,
  },
  labelText: {
    marginTop: 4,
    marginBottom: 4,
  },
  titleContainer: {
    marginTop: -30,
  },
  timeHeader: {
    color: Colors.Custom.Gray500,
    fontWeight: '400',
    fontSize: 14,
  },
  spinnerStyle: {
    margin: '0 auto',
    color: 'black',
    position: 'absolute',
    zIndex: 100011111,
    backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0
  }
});
