import { Pie, PieConfig } from '@ant-design/charts';
import { useEffect, useMemo, useState } from 'react';
import { getFormattedDataForPeiChart, wordWrapFromChart } from '../EmployerUtils/FormateDataUtils';
import { IAppliedFilter, IResource, IWidgetCardView } from '../interface';
// import ReactToPrint, {useReactToPrint} from 'react-to-print';
import { Skeleton } from 'antd';
import { Pressable, Tooltip } from 'native-base';
import { ANALYTICS_RESOURCE, DATE_FORMATS, MOMENT_DATE_CONST } from '../../../../../constants';
import { getSectionDataByResourceCode } from '../../../../../services/Employer/EmployerServices/EmployerServices';
import { GraphColors } from '../../../../../styles/Colors';
import EyeHideSvg from '../../../../common/Svg/WidgetsSvg/EyeHideSvg';
import InfoIconView from '../CommonView/InfoIconView';
import { DashboardDataContext } from '../EmployerContext/DashboardContext';
import { WIDGET_ACTION_CODE } from '../EmployerUtils/EmployerConst';
import { isWidgetDataEmpty } from '../EmployerUtils/WidgetUtils';
import {
  ED_UTILIZATION_PERCENT_DATA,
  EMPLOYEE_AGE_GROUP, EMPLOYEE_COUNT,
  EMPLOYEE_GENDER_GROUP,
  LOGGED_IN_COUNT,
  TEMP_ENGAGE_FOR_CARE,
  TOP_DIAGNOSIS,
  getTenantForDemo,
  ED_UTILIZATION_EMPLOYEE_VS_DEPENDENT_DATA} from '../tempDataFile';
import NodataViewTypeOne from './NoDataView/NodataViewTypeOne';
import WidgetActionView from './WidgetActionView';
import { getGraphColorsByName } from './utils';
import RestSvgIcons from '../../../../common/Svg/RestSvgIcons';
import moment from 'moment';
import WidgetHeader from '../WidgetHeader/WidgetHeader';
import {graphColorList, graphColorListForGenderWidget} from '../graphColors';
import {capitalizeText} from '../../../ContentManagement/CampaignManagement/Helper';
import {getFormattedDateStringInTimeZone} from '../../../../../utils/DateUtils';
const WidgetPieChartView = (props: IWidgetCardView) => {
  const {
    sectionData,
    loading,
    ref,
    onImageDownloadClick,
    appliedFilter,
    dateRange,
    employerId,
      isAccountDashboard,
      memberType,
  } = props;
  const isTopDiagnosis = ANALYTICS_RESOURCE.TOP_DIAGNOSIS === sectionData.resourceCode
  const isEnrolledForCare = sectionData.resourceCode === ANALYTICS_RESOURCE.ENROLLED_FOR_CARE

  const isGenderGroup = ANALYTICS_RESOURCE.EMPLOYEE_GENDER_GROUP === sectionData.resourceCode

  const isEmployerCount =
    sectionData.resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_COUNT;
  const isAgeGroup = ANALYTICS_RESOURCE.EMPLOYEE_AGE_GROUP === sectionData.resourceCode
  const isEdUtilizationPercent = ANALYTICS_RESOURCE.ED_UTILIZATION_PERCENT === sectionData.resourceCode
  const isEdUtilizationEmployeeDependent = ANALYTICS_RESOURCE.ED_UTILIZATION_EMPLOYEE_VS_DEPENDENT === sectionData.resourceCode
  const [stateData, setStateData] = useState({
    filterByTitle: 'Sex At Birth',
    filterCode: 'SEX_AT_BIRTH',
    resourceData: [] as IResource[] | any,
    loading: false,
    appliedFilter:  props.appliedFilter,
    filterValueName: [] as string[],
    filterValueString: '',
    selectedAppliedFilter: {} as any
  });
  const formattedResourceData = getFormattedDataForPeiChart(stateData.resourceData, sectionData?.resourceCode || '');
  const onActionClick = (code: string, data?: any) => {
    switch (code) {
      case WIDGET_ACTION_CODE.RESET_LOCATION_FILTER:
        const filteredWidgetFilter = stateData.selectedAppliedFilter.filter((item: any) => {
          return item.filterCode != 'LOCATION'
        })
        setStateData(prev => {
          return {
            ...prev,
            selectedAppliedFilter: filteredWidgetFilter,
            filterValueString: '',
            filterValueName: []
          }
        })
        getSectionByResourceCode();
        break;
      default:
        props.onActionClick?.(code, data)
        break;
    }
  }
  const config: PieConfig = {
    // appendPadding: 10,
    data: formattedResourceData,
    angleField: 'value',
    colorField: 'type',
    appendPadding: 10,
    radius: 1,
    innerRadius: 0.6,
    color:
      sectionData.resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_GENDER_GROUP
        ? graphColorListForGenderWidget
        : graphColorList,
    height: 250,
    width: 300,
    label: false,
    // label: {
    //   type: 'spider',
    //   // offset: '-30%',
    //   content: (data) => {
    //     const percent = data.percent
    //     const value = data.value
    //     return `${value} ( ${(percent * 100).toFixed(0)}% )`
    //   },
    //   style: {
    //     fontSize: 12,
    //     textAlign: 'center',
    //   },
    // },
    tooltip: {
      formatter: (data) => {
        return {
          name: data.type?.replace(/^[a-z]|[A-Z]/g, (c: any, i: any) => (i ? " " : "") + c.toUpperCase()).replace('Amount',''),
          value: data.value
        }
      },
    },
    renderer: 'svg',
    legend: {
      itemSpacing: 20,
      position: 'right',
      layout: 'vertical',
      offsetX: -45,
      itemMarginBottom: 20,
      itemName: {
        formatter(text: any, item: any, index: any) {
          let valueCount = 0;
          formattedResourceData.forEach((data: any)=> {
            if (data?.type?.toLocaleLowerCase()?.trim() === text?.toLocaleLowerCase()?.trim()) {
              valueCount = valueCount + data?.value
            }
          })
          const legendText = text
          ?.replace(
            /^[a-z]|[A-Z]/g,
            (c: any, i: any) => (i ? ' ' : '') + c.toUpperCase()
          )
          .replace('Amount', '');
          return `${wordWrapFromChart(legendText, 25)}`.toLocaleUpperCase();
        },
      },
      itemValue:{
        style: {
          stroke: '#191D23',
          fontWeight: 'lighter',
          fontSize: 10
        },
        formatter(text) {
          let valueCount = 0;
          formattedResourceData.forEach((data: any)=> {
            if (data?.type?.toLocaleLowerCase()?.trim() === text?.toLocaleLowerCase()?.trim()) {
              valueCount = valueCount + data?.value
            }
          })
          return `(${valueCount})`
        },
      },
      marker: {
        symbol: 'circle',
        style(style) {
            return {
              ...style,
              r: 4
            }
        },
      }
    },
    statistic: {
      title: undefined,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: '',
      },
    },
    onReady: (plot: any) => {
      plot.on('element:click', (args: any) => {
        if (args?.data?.data?.type || args?.data?.data?.code) {
          onReady(args);
        }
      });
    },
  };

  const onReady = (data: any) => {
    const dateRangeFilterObj = appliedFilter?.find(item=> {
      return item.filterCode === "DATE_RANGE"
    })
    const fromDate = getFormattedDateStringInTimeZone(
      dateRangeFilterObj?.filterValue?.fromDate || dateRangeFilterObj?.filterValue?.currentDateRange?.fromDate || '',
      `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
      MOMENT_DATE_CONST.START_OF
    );
    const toDate = getFormattedDateStringInTimeZone(
      dateRangeFilterObj?.filterValue?.toDate ||dateRangeFilterObj?.filterValue?.currentDateRange?.toDate || '',
      `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
      MOMENT_DATE_CONST.END_OF
    );
    let filterData = [{
      filterCode: 'DATE_RANGE',
      filterValue: {
        fromDate: fromDate,
        toDate: toDate,
      },
      count: data?.data?.data?.value || data?.data?.data?.xField
    },
    {
      filterCode: 'DASHBOARD_DATE_RANGE',
      filterValue: dateRange
    }] as any[];
    let filterObj = {}  as any;
      const dataEnr = data?.data?.data?.type;
      const dataType = capitalizeText(dataEnr);
      const dateOrMonth = data?.data?.data?.date || data?.data?.data?.month
      switch (sectionData.resourceCode) {
      case ANALYTICS_RESOURCE.EMPLOYEE_AGE_GROUP:
        const fromOrToEmpty = data?.data?.data?.type.includes('under')
          ? 'under'
          : data?.data?.data?.type.includes('over')
          ? 'over'
          : '';
        const number = data?.data?.data?.type.match(/\d+/g);
          filterObj = {
          filterCode: 'AGE_GROUP',
          filterValue: {
            fromAge: fromOrToEmpty && fromOrToEmpty == 'under' ? 0 : number[0],
            toAge:
              fromOrToEmpty && fromOrToEmpty == 'over'
                ? 100
                : number[1]
                ? number[1]
                : number[0],
          },
          count: data?.data?.data?.value
        };
        filterData.push(filterObj);
        filterData = stateData.selectedAppliedFilter.length ?  [...filterData, ...stateData.selectedAppliedFilter] : filterData
        props?.onActionClick?.(sectionData.resourceCode || '', {
          filterData,
          count: data?.data?.data?.value || data?.data?.data?.count,
          type: dataType,
          date: dateOrMonth
        });
        break;
      case ANALYTICS_RESOURCE.EMPLOYEE_GENDER_GROUP:
        const dataObj = data?.data?.data?.type;
        let value = 'NOT_SET';
        if (dataObj.toLowerCase().includes('female')) {
          value = 'F';
        } else if (dataObj.toLowerCase().includes('male')) {
          value = 'M';
        } else if (dataObj.toLowerCase().includes('other')) {
          value = 'OTH';
        } else if (dataObj.toLowerCase().includes('unknown')) {
          value = 'UNK';
        }
        filterObj = {
          filterCode: 'GENDER',
          filterValue: {value: value},
          count: data?.data?.data?.value
        };
        filterData.push(filterObj);
        filterData = stateData.selectedAppliedFilter.length ?  [...filterData, ...stateData.selectedAppliedFilter] : filterData
        props?.onActionClick?.(sectionData.resourceCode || '', {
          filterData,
          count: data?.data?.data?.value || data?.data?.data?.count,
          type: dataType,
          date: dateOrMonth
        });
        break;
        case ANALYTICS_RESOURCE.TOP_DIAGNOSIS:
          const dataDiag = data?.data?.data?.code;
          filterObj = {
            filterCode: 'CONDITION',
            filterValue: dataDiag,
            count: data?.data?.data?.value
          };
          filterData.push(filterObj);
          filterData = stateData.selectedAppliedFilter.length ?  [...filterData, ...stateData.selectedAppliedFilter] : filterData
          props?.onActionClick?.(sectionData.resourceCode || '', {
            filterData,
            count: data?.data?.data?.value || data?.data?.data?.count,
            type: dataType,
            date: dateOrMonth
          });
          break;
        case ANALYTICS_RESOURCE.EMPLOYEE_COUNT:
          filterObj = {
            filterCode: 'EMPLOYEE_TYPE',
            filterValue: [dataEnr],
            count: data?.data?.data?.value
          };

          filterData.push(filterObj);
          filterData = stateData.selectedAppliedFilter.length ?  [...filterData, ...stateData.selectedAppliedFilter] : filterData
          props?.onActionClick?.(sectionData.resourceCode || '', {
            filterData,
            count: data?.data?.data?.value || data?.data?.data?.count,
            type: dataType,
            date: dateOrMonth
          });
          break;
        case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE:
        case ANALYTICS_RESOURCE.ED_UTILIZATION_EMPLOYEE_VS_DEPENDENT:
          filterObj = {
            filterCode: 'EMPLOYEE_TYPE',
            filterValue: [dataEnr],
            count: data?.data?.data?.value
          };
          filterData.push(filterObj);
          filterData = stateData.selectedAppliedFilter.length ?  [...filterData, ...stateData.selectedAppliedFilter] : filterData
          props?.onActionClick?.(sectionData.resourceCode || '', {
            filterData,
            count: data?.data?.data?.value || data?.data?.data?.count,
            type: dataType,
            date: dateOrMonth
          });
          break;
        case ANALYTICS_RESOURCE.ED_UTILIZATION_PERCENT:
          filterObj = {
            filterCode: 'CATEGORY',
            filterValue: dataEnr,
            count: data?.data?.data?.value
          };
          filterData.push(filterObj);
          filterData = stateData.selectedAppliedFilter.length ?  [...filterData, ...stateData.selectedAppliedFilter] : filterData
          props?.onActionClick?.(sectionData.resourceCode || '', {
            filterData,
            count: data?.data?.data?.value || data?.data?.data?.count,
            type: dataType,
            date: dateOrMonth
          });
          break;
      default:
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData});
        break;
    }
  };
  const pieChartView = useMemo(() => {
    return <Pie {...config} />
  },[stateData.loading])

  const subHeading = sectionData.subHeading
    ? sectionData.subHeading
    :  isGenderGroup ? `Bellow Data is based on ${stateData.filterByTitle}` : '';
  const getSectionByResourceCode = async (appliedFilter?: IAppliedFilter[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const filterList = appliedFilter?.length ?  appliedFilter : stateData.appliedFilter
    const response = await getSectionDataByResourceCode(
      employerId,
      sectionData.resourceCode || '',
      filterList,
      '',
      isAccountDashboard,
      memberType,
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          resourceData: [],
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data && isWidgetDataEmpty(response?.data)) {

      const resourceData = response.data?.totalCount ? response.data : response.data?.result || response.data;
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
        };
      });
    } else {
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: [] as IResource[],
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };
  const controller = new AbortController();
  useEffect(() => {
    if (getTenantForDemo()) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: true
        }
      })
      const resourceData = getTenantForDemo()
        ? (isTopDiagnosis
          ? TOP_DIAGNOSIS
          : isGenderGroup
            ? EMPLOYEE_GENDER_GROUP
            : isAgeGroup
              ? EMPLOYEE_AGE_GROUP
              : isEdUtilizationPercent
              ? ED_UTILIZATION_PERCENT_DATA.result
              : isEmployerCount? EMPLOYEE_COUNT.result
              : isEnrolledForCare? TEMP_ENGAGE_FOR_CARE.result
              : isEdUtilizationEmployeeDependent
              ? ED_UTILIZATION_EMPLOYEE_VS_DEPENDENT_DATA.result
              : {}
            )
        : {};
    setTimeout(()=> {
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          loading: false
        }
      })
      },3000)
      return
    }
    getSectionByResourceCode();
    return () => controller?.abort();
  }, [sectionData.id])

  useEffect(() => {
    if (props.filterData?.resourceCode == sectionData.resourceCode) {
      const appliedFilter = stateData.appliedFilter?.length ? [...stateData.appliedFilter] : [];
      const selectedFilter = {
        filterCode: props.filterData?.filterCode,
        filterValue:  props.filterData?.filterValue
      }
      appliedFilter.push(selectedFilter);
      let filterValueString = '';
      props.filterData?.filterValueName?.forEach((item: string, index: number) => {
        if (index < 2) {
          return
        }
        filterValueString = filterValueString + ( filterValueString.length ? " | " : '') + item
      })
      setStateData(prev => {
        return {
          ...prev,
          filterValueName: props.filterData?.filterValueName,
          filterValueString: filterValueString,
          selectedAppliedFilter: [selectedFilter]
        }
      })
      getSectionByResourceCode(appliedFilter);
    }
    return () => controller?.abort();
  },[props.filterData?.filterValue])
  const getRefreshButtonOnLocalEnv = () => {
    if (window.location.host.includes('localhost')) {
      return (
        <Pressable
          style={{marginHorizontal: 3}}
          onPress={() => {
            setStateData(prev => {
              return {
                ...prev,
                filterValueName: [],
                filterValueString: ''
              }
            })
            getSectionByResourceCode();
          }}
        >
          <Tooltip label="Refresh">
            <RestSvgIcons />
          </Tooltip>
        </Pressable>
      )
    }
    return <></>;
  }
  return (
    <div className="widgetBoxShadow">
      <WidgetHeader isPrintPreview ={props.isPrintPreview} isPrintDownload={props.isPrintDownload} refreshButtonOnLocalEnv={getRefreshButtonOnLocalEnv()} sectionData={sectionData} onActionClick={onActionClick} />
      {stateData.loading ? (
        <Skeleton style={{padding: 40}} active={true} />
      ) : (
        <>
          {stateData.resourceData && stateData.resourceData?.length && formattedResourceData?.length ? (
            <div
              style={{
                minHeight: '75%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <main
                // id={sectionData.resourceCode}
                className={
                  stateData.resourceData.length && props.isPrintPreview
                    ? 'canvas-wrapper'
                    : ''
                }
              >
                {pieChartView}
              </main>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '60%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NodataViewTypeOne viewCode={'TEXT_ICON'} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WidgetPieChartView;
