import React, {useEffect, useMemo, useState} from 'react';
import {Checkbox, Drawer, Modal, Table, notification} from 'antd';
import {
  BulkSelectActionType,
  IAppoinmentDataConfig,
  IScheduleRuleMemberListComponentState,
  IScheduleRuleMemberListProps,
  ISendAppointmentLinkToPopulationGroup,
  ScheduleRuleMemberListActionTypes,
} from '../../interface';
import {ModalActionTitle} from '../../../../../ModalActionTitle/ModalActionTitle';
import {BUTTON_TYPE} from '../../../../../../../constants';
import {Colors} from '../../../../../../../styles';
import {Dimensions} from 'react-native';
import {
  formattedContactDataList,
  getColumns,
} from '../../ScheduleSuggestorUtils';
import {
  onMultipleSelectedItem,
  onPagination,
  removeDuplicates,
} from '../../../../../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/LeadListView/LeadListHelper';
import './styles.scss';
import {TableWrapper} from '../../../../../TableWrapper';
import {useLazyQuery, useMutation} from '@apollo/client';
import {LeadQueries} from '../../../../../../../services';
import {getAccountId} from '../../../../../../../utils/commonUtils';
import {HStack, Spacer, Text, VStack, View} from 'native-base';
import {FoldButton} from '../../../../../../CommonComponents/FoldButton/FoldButton';
import AppointmentBooking from '../../../Booking/AppointmentBooking/AppointmentBooking';
import {IParticipantSearch} from '../../../../ParticipantAutoComplete/ParticipantInterfaces';
import EmailAppointmentLinkDrawer from '../EmailAppointmentLinkDrawer/EmailAppointmentLinkDrawer';
import {AppointmentAvailabilityCode} from '../../../../../../RightSideContainer/AccountSettings/AppointmentTypes/constants';
import {SEND_APPOINTMENT_LINK} from '../../../../../../../services/Appointment/AppointmentQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../../constants/Configs';
import { ParticipantType } from '../../../../ParticipantAutoComplete/ParticipantEnum';
import { getContactsWithinGroup } from '../../../../../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';

const ScheduleRuleMemberList = (props: IScheduleRuleMemberListProps) => {
  const accountId = getAccountId();
  const {isOpen, onClose} = props;
  const {height} = Dimensions.get('window');
  const finalHeight = height - 10;
  const [getContactsByIds] = useLazyQuery(LeadQueries.getContactsByIds);
  const [getOrderedContactsByIds] = useLazyQuery(LeadQueries.getOrderedContactsByIds);
  const [sendAppointmentLink] = useMutation(SEND_APPOINTMENT_LINK, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [componentState, setComponentState] =
    useState<IScheduleRuleMemberListComponentState>({
      selectedRowKey: [],
      selectedItems: [],
      contactIdsList: props.contactIdList,
      contactList: [],
      loading: false,
      showConfirmation: false,
      selectAllLoading: false,
      showAppointmentBookingDrawer: false,
      selectedUser: undefined,
      ruleIds: props.rulesIds,
      showSendAppointmentToBulkDrawer: false,
      appointmentData: {} as IAppoinmentDataConfig,
      sendLinkLoading: false,
      sendNotification: false
    });

  const isAllContactsSelected =
    componentState.contactIdsList.length ===
    componentState.selectedRowKey.length;

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 0,
      pageSize: 10,
      total: componentState.contactIdsList.length,
    },
  });

  const toggleBooleanStates = (
    key: keyof Pick<
      IScheduleRuleMemberListComponentState,
      | 'loading'
      | 'showConfirmation'
      | 'showAppointmentBookingDrawer'
      | 'selectAllLoading'
      | 'showSendAppointmentToBulkDrawer'
      | 'sendLinkLoading'
      | 'sendNotification'
    >,
    value: boolean
  ) => {
    setComponentState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleBulkSelect = (type: BulkSelectActionType) => {
    if (type === BulkSelectActionType.None) {
      setComponentState((prev) => ({
        ...prev,
        selectedRowKey: [],
      }));
      return;
    }
    toggleBooleanStates('selectAllLoading', true);
    const contactIds = componentState?.contactIdsList?.map((contact: any) => {
      return parseInt(contact?.contactId || contact);
    });
    setComponentState((prev) => ({
      ...prev,
      selectedRowKey: contactIds,
      selectAllLoading: false,
    }));
    return;
  };

  const buttonList = [
    {
      show: true,
      id: 1,
      btnText: 'cancel',
      textColor: Colors.Custom.mainSecondaryBrown,
      variant: BUTTON_TYPE.SECONDARY,
      isTransBtn: false,
      onClick: () => {
        onClose?.();
        props.refetchAppointments();
      },
    },
    {
      show: true,
      id: 1,
      btnText: `${isAllContactsSelected ? 'Deselect' : 'Select'} All`,
      textColor: Colors.Custom.mainPrimaryPurple,
      btnStype: BUTTON_TYPE.PRIMARY,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      onClick: () => {
        handleBulkSelect(
          isAllContactsSelected
            ? BulkSelectActionType.None
            : BulkSelectActionType.All
        );
      },
    },
    {
      show: true,
      id: 2,
      isDisabled: componentState?.selectedRowKey?.length === 0,
      btnText: 'sendAppointmentLink',
      textColor: Colors.Custom.mainPrimaryPurple,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      onClick: () => {
        toggleBooleanStates('showSendAppointmentToBulkDrawer', true);
      },
    },
  ];

  const confirmationbuttonList = [
    {
      show: true,
      id: 1,
      btnText: 'Cancel',
      textColor: Colors.Custom.mainSecondaryBrown,
      variant: BUTTON_TYPE.SECONDARY,
      isTransBtn: false,
      onClick: () => toggleBooleanStates('showConfirmation', false),
    },
    {
      show: true,
      id: 2,
      btnText: 'Send Link',
      isTransBtn: false,
      onClick: () => handleSendAppointmentLinkClick(),
      isLoading: componentState.sendLinkLoading,
      textColor: Colors.Custom.mainPrimaryPurple,
      variant: BUTTON_TYPE.PRIMARY,
    },
  ];

  const fetchUsers = async () => {
    toggleBooleanStates('loading', true);
    const offset = onPagination(
      tableParams.pagination.current,
      tableParams.pagination.pageSize
    );
    await getOrderedContactsByIds({
      variables: {
        contactIdList: componentState.contactIdsList,
        accountId: accountId,
        offset: Math.max(0, offset),
        limit: tableParams.pagination.pageSize,
        order_by: {
          updatedAt: 'desc',
        },
      },
      onCompleted: (data) => {
        setComponentState((prev) => ({
          ...prev,
          contactList: formattedContactDataList(data.contacts),
          loading: false,
        }));
      },
      onError: (err) => {
        toggleBooleanStates('loading', false);
        setComponentState((prev) => ({
          ...prev,
          contactList: [],
          rules: [],
        }));
      },
    });
  };

  const handleSendAppointmentLinkClick = async () => {
    toggleBooleanStates('sendLinkLoading', true);
    const isCareTeamBased =
      componentState.appointmentData?.availabilityTypeCode ===
      AppointmentAvailabilityCode.CARE_TEAM;
    const body: ISendAppointmentLinkToPopulationGroup = {
      templateId: componentState?.appointmentData?.templateId,
      ruleIds: componentState.ruleIds,
      allowToSendNotification: componentState.sendNotification,
      appointmentType: {
        appointmentTypeId: componentState?.appointmentData.appointmentTypeId,
        ...(componentState?.appointmentData?.appointmentUsers && {
          userIds: componentState?.appointmentData?.appointmentUsers.split(','),
        }),
        ...(isCareTeamBased && {
          isCareTeamBased: isCareTeamBased,
        }),
      },
    };
    if (!isAllContactsSelected) {
      const contactDataForSelectedContacts = await getContactsByIds({
        variables: {
          contactIdList: componentState.selectedRowKey,
          accountId: accountId,
        },
      });
      const contactUUUIDsForSelectedContacts =
        contactDataForSelectedContacts.data?.contacts?.map(
          (contact: any) => contact?.uuid
        );
      body.contactIds = contactUUUIDsForSelectedContacts;
    } else {
      let ruleWiseContact: string[] = [];
      const uniqueContactIds: string[] = [];
      const promiseList = [];
      for (const ruleId of (componentState.ruleIds || [])) {
        promiseList.push(getContactsWithinGroup(ruleId, true));
      }
      if (promiseList?.length) {
        const responses = await Promise.all(promiseList);
        for (const contactResponse of responses) {
          if (contactResponse?.data?.contactUuids?.length) {
            ruleWiseContact = ruleWiseContact.concat(contactResponse.data.contactUuids);
          }
        }
      }
      (ruleWiseContact || []).forEach(contactId => {
        if (!uniqueContactIds.includes(contactId)) {
          uniqueContactIds.push(contactId);
        }
      });
      body.contactIds = uniqueContactIds;
    }
    sendAppointmentLink({
      variables: {
        data: body,
      },
      onCompleted: (data) => {
        if (data?.sendAppointmentLink?.status === "success") {
          notification.success({
            message: 'Appointment link sent successfully',
          });
          onClose();
        } else {
          notification.error({
            message: 'Something went wrong',
            description: 'Please try again after some time.',
          });
          onClose();
        }
      },
      onError: (err) => {
        notification.error({
          message: 'Something went wrong',
          description: 'Please try again after some time.',
        });
        onClose();
      },
    });
  };

  const onActionPerformed = (
    type: ScheduleRuleMemberListActionTypes,
    data: any
  ) => {
    switch (type) {
      case ScheduleRuleMemberListActionTypes.BOOK_APPOINMENT:
        setComponentState((prev) => ({
          ...prev,
          showAppointmentBookingDrawer: true,
          selectedUser: {
            userName: data?.name,
            userUUID: data?.contactData?.uuid,
            contactData: data
          },
        }));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [JSON.stringify(tableParams)]);

  return (
    <Drawer
      className="custom-drawer-styles"
      width={'60%'}
      open={isOpen}
      onClose={onClose}
      title={
        <ModalActionTitle title={'Rule Details'} buttonList={buttonList} />
      }
    >
      <View pb={2} h='100%'>
      <TableWrapper
        wrapperStyle={{flex:1}}
        showDivierAbovePagination
        pagination={{
          ...tableParams.pagination,
          onChange: (page, pageSize) => {
            setTableParams((prev) => ({
              ...prev,
              pagination: {
                ...prev.pagination,
                current: page,
                pageSize: pageSize
              },
            }));
          },
        }}
      >
        <>
        <Table
          pagination={false}
          loading={componentState.loading}
          // onChange={handleTableChange}
          scroll={{y: finalHeight - 180}}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: componentState?.selectedRowKey,
            onSelect: (item: any) => {
              onMultipleSelectedItem(
                item,
                componentState.selectedItems,
                (returnItems: any) => {
                  const selectedKey: any = [];
                  returnItems.forEach((element: any) => {
                    selectedKey.push(element.id);
                  });
                  setComponentState((prev) => {
                    return {
                      ...prev,
                      selectedItems: returnItems,
                      selectedRowKey: selectedKey,
                    };
                  });
                }
              );
            },
            onSelectAll: (isSelected: boolean, data: any) => {
              if (!isSelected) {
                const arrayKey = [...componentState?.selectedRowKey];
                const arrayItems = [...componentState?.selectedItems];
                componentState.contactList?.map((item?: any) => {
                  arrayKey.map((itemId?: any) => {
                    if (item?.id == itemId) {
                      const index = arrayKey.indexOf(itemId);
                      arrayKey.splice(index, 1);
                      setComponentState({
                        ...componentState,
                        selectedRowKey: arrayKey,
                      });
                    }
                  });
                  arrayItems.map((itemId?: any) => {
                    if (item?.id == itemId?.id) {
                      const index = arrayItems.indexOf(itemId);
                      arrayItems.splice(index, 1);
                      setComponentState({
                        ...componentState,
                        selectedItems: arrayItems,
                        selectedRowKey: arrayKey,
                      });
                    }
                  });
                });
              } else {
                const arrayItems = [...componentState?.selectedItems];
                componentState?.contactList?.forEach((item?: any) => {
                  arrayItems.push(item);
                });
                //Remove duplicate from Arraylist
                const uniqueArray = removeDuplicates(arrayItems, 'id');
                const selectedKey: any = [];
                uniqueArray.forEach((element: any) => {
                  selectedKey.push(element.id);
                });

                setComponentState({
                  ...componentState,
                  selectedItems: uniqueArray,
                  selectedRowKey: selectedKey,
                });
              }
            },
          }}
          rowKey={(record) => {
            return record.id;
          }}
          className="lead-list-table-view patient-list-view"
          dataSource={componentState.contactList}
          columns={getColumns({
            showActionColumn: componentState?.selectedItems?.length === 0,
            onActionPerformed,
          })}
        />
        <Spacer />
        </>
      </TableWrapper>
      </View>

      <Modal
        zIndex={999999}
        open={componentState.showConfirmation}
        closable={false}
        onCancel={() => toggleBooleanStates('showConfirmation', false)}
        footer={null}
      >
        <VStack>
          <Text
            mb={1}
            fontSize={'xl'}
            fontWeight={'bold'}
          >{`Send Appointment Link`}</Text>
          <Text
            my={1}
            fontSize={'md'}
          >{`Are you sure you want to send appointment link to ${componentState.selectedRowKey.length} members? The link will be shared on their preferred contact details.`}</Text>
          <View my={2}>
            <Checkbox
              onChange={(e) =>
                toggleBooleanStates('sendNotification', e.target.checked)
              }
            >
              Notify me on successful submission?
            </Checkbox>
          </View>
          <HStack mt={5} justifyContent={'flex-end'}>
            {confirmationbuttonList.map((button: any, index: number) => {
              if (button.show) {
                return (
                  <FoldButton
                    key={index + button.btnText}
                    nativeProps={{
                      variant: button.variant,
                      onPress: () => {
                        button.onClick();
                      },
                      leftIcon: button.leftIcon,
                      rightIcon: button.rightIcon,
                      isDisabled: button.isDisabled,
                      isLoading: button.isLoading,
                      marginBottom: 1,
                      marginTop: 1,
                      marginLeft: 2,
                    }}
                    customProps={{
                      btnText: button.btnText,
                      withRightBorder: false,
                    }}
                  ></FoldButton>
                );
              }
            })}
          </HStack>
        </VStack>
      </Modal>
      {componentState.showAppointmentBookingDrawer &&
        componentState.selectedUser && (
          <AppointmentBooking
            defaultParticipants={
              (componentState.selectedUser
                ? [
                    {
                      key: componentState.selectedUser.userUUID,
                      value: componentState.selectedUser.userUUID,
                      label: componentState.selectedUser.userName,
                      type: ParticipantType.patient,
                      contactData: componentState.selectedUser.contactData
                    },
                  ]
                : []) as IParticipantSearch[]
            }
            isVisible={componentState.showAppointmentBookingDrawer}
            onComplete={(data?: any) => {
              setComponentState((prev) => ({...prev, selectedUser: undefined}));
              toggleBooleanStates('showAppointmentBookingDrawer', false);
            }}
            onCancel={() => {
              setComponentState((prev) => ({...prev, selectedUser: undefined}));
              toggleBooleanStates('showAppointmentBookingDrawer', false);
            }}
          />
        )}
      {componentState.showSendAppointmentToBulkDrawer && (
        <View>
          <EmailAppointmentLinkDrawer
            isVisble={componentState.showSendAppointmentToBulkDrawer}
            onClose={() =>
              setComponentState((prev) => ({
                ...prev,
                showSendAppointmentToBulkDrawer: false,
                appointmentData: {} as IAppoinmentDataConfig,
              }))
            }
            onSend={(data) => {
              setComponentState((prev) => ({
                ...prev,
                showConfirmation: true,
                appointmentData: data,
              }));
            }}
          />
        </View>
      )}
    </Drawer>
  );
};

export default ScheduleRuleMemberList;
