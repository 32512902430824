import {useLazyQuery} from '@apollo/client';
import {Drawer, Empty} from 'antd';
import {
  Box,
  Divider,
  HStack,
  VStack,
  Text,
  View,
  FlatList,
  Center,
  Skeleton,
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import CareJourneyQueries from '../../../../../services/CareJourney/CareJourneyQueries';
import {Colors} from '../../../../../styles';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {
  IPatientJourneyGoal,
  IPatientJourneyGoalState,
  IPatientJourneyGoalViewProps,
} from './PatientJourneyInterface';
import { CommonDataContext } from '../../../../../context/CommonDataContext';

const GoalsCareJourneyDrawer = (props: IPatientJourneyGoalViewProps) => {
  const {enabledDrawer, onClose, selectedJourney} = props;
  const [journeyGoalState, setPatientCareJourneyGoal] =
    useState<IPatientJourneyGoalState>({
      selectedJourney,
      goals: [],
      loading: true,
    });

  const [getPatientCareJourneyGoalById] = useLazyQuery(
    CareJourneyQueries.GET_PATIENT_CARE_JOURNEY_GOAL_BY_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    },
  );

  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;


  const getPatientCareJourneyGoal = async () => {
    setPatientCareJourneyGoal(prev => {
      return {...prev, loading: true};
    });
    try {
      const goalApiResponse = await getPatientCareJourneyGoalById({
        variables: {
          patientCareJourneyId: selectedJourney.id,
        },
      });
      const goals: IPatientJourneyGoal[] = goalApiResponse?.data?.patientCareJourneyGoals || [];
      setPatientCareJourneyGoal(prev => {
        return {
          ...prev,
          goals,
          loading: false,
        };
      });
    } catch (error) {
      setPatientCareJourneyGoal(prev => {
        return {...prev, loading: false};
      });
    }
  };

  const renderGoalCard = ({
    item,
    index,
  }: {
    item: IPatientJourneyGoal;
    index: number;
  }) => {
    return (
      <VStack>
        <VStack marginX={5} marginY={2}>
          <VStack>
            <Text fontWeight={600} fontStyle={'normal'}>
              {item.title}
            </Text>
          </VStack>
          {item?.description && (
            <VStack>
              <Text color={Colors.Custom.Gray500}>{item?.description}</Text>
            </VStack>
          )}
          {(journeyGoalState?.goals?.length || 0) - 1 !== index && (
            <Divider marginTop={2} />
          )}
        </VStack>
      </VStack>
    );
  };

  useEffect(() => {
    getPatientCareJourneyGoal();
  }, [selectedJourney.id]);

  return (
    <Drawer
      open={enabledDrawer}
      width={isSidecarContext ? '100%' : Dimensions.get('window').width * 0.3}
      onClose={() => {
        onClose();
      }}
      title={
        <ModalActionTitle
          title={'Goals'}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'close',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                onClose();
              },
            },
          ]}
        />
      }>
      <View
        bgColor={`white`}
        borderWidth="1"
        shadow="1"
        borderRadius={8}
        borderColor="#F5F1FE"
        overflow={'hidden'}
        style={{
          shadowOffset: {width: 0, height: 1},
          shadowRadius: 2,
        }}>
        <Box
          borderRadius={2}
          bg="white"
          overflow={'hidden'}
          paddingTop={4}
          paddingBottom={1}>
          {
            <Box>
              <VStack>
                <HStack paddingX={5} marginBottom={2}>
                  <Text
                    fontSize={12}
                    fontStyle={'normal'}
                    fontWeight={700}
                    color={Colors.Custom.Gray400}>
                    {(journeyGoalState.selectedJourney?.title || journeyGoalState.selectedJourney.careJourney?.title)?.toLocaleUpperCase()}
                  </Text>
                </HStack>
                <Divider color={Colors.Custom.Gray400} height={'1.5px'} />
                {journeyGoalState.loading && (
                  <Skeleton.Text mx={4} mt={2} lines={6}></Skeleton.Text>
                )}
                {!journeyGoalState.loading && (
                  <FlatList
                    ListEmptyComponent={
                      <Center height={'100%'} flex={1}>
                        <Empty />
                      </Center>
                    }
                    disableVirtualization
                    contentContainerStyle={{flexGrow: 1, overflow: 'scroll'}}
                    data={journeyGoalState.goals}
                    keyExtractor={(item: any) => {
                      return '_' + item?.id;
                    }}
                    renderItem={renderGoalCard}
                  />
                )}
              </VStack>
            </Box>
          }
        </Box>
      </View>
    </Drawer>
  );
};
export default GoalsCareJourneyDrawer;
