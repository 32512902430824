import jwt_decode from 'jwt-decode';

export const getSsoUserEmail = async (accessToken: string) => {
  try {
      const decoded = await jwt_decode(accessToken || '');
      const decodedObj = JSON.parse(JSON.stringify(decoded));
      const userEmail = decodedObj?.['email'];
      return userEmail || '' as string;
  } catch (error) {
    return '';
  }
};

export const checkWorkflowUserByUrl = (loginUrl: string) => {
  //const cleanFragment = loginUrl.substring(1); // Removes the first character ("#")
  const parts = loginUrl.split('/');
  const workflowName = parts?.[1];
  if (workflowName === 'workflow') {
    return true;
  }
  return false;
};

export const getSupportEmailFromSSOConfig = (ssoConfigValue: string) => {
  if (ssoConfigValue?.length) {
    return JSON.parse(ssoConfigValue)?.help_email || '';
  }
  return '';
};
