import React, { useRef } from 'react';
import { Text as NativeText } from 'native-base';
import { Table, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PatientListingTableProps } from '../../interfaces';
import { cssPropertiesAntD } from '../../style';
import { getColumns, InfiniteScrollLoader } from '../../DayOptimizerHelper';
import useDOPatientListing from './useDOPatientListing';
import '../../styles.css';
import DayOptimizerLoadingSvg from '../../../../../../assets/Icons/DayOptimizerLoadingSvg';
import NoPatientFoundSvg from '../../../../../../assets/Icons/NoPatientFoundSvg';
import { Colors } from '../../../../../../styles';
import { useIntl } from 'react-intl';
import { useWindowDimensions } from 'react-native';
import { SMALL_WINDOW_1500 } from '../../../../../../constants';
const PatientListingTable = ({
  isScheduleManagerVisible,
  containerHeight,
  onViewAction,
  isPatientDetailViewVisible,
}: PatientListingTableProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowDimensions();
  const isSmallScreen = width < SMALL_WINDOW_1500;

  const {
    patients,
    isLoading,
    hasMore,
    error,
    fetchPatients,
    rafFilter,
    formattedDate,
    updatePatientStatus,
    columnShuffleDropDownList
  } = useDOPatientListing();
  const intl = useIntl();

  if (error) {
    return <div>Error: {error}</div>;
  }

  const columns = getColumns(
    rafFilter,
    fetchPatients,
    isPatientDetailViewVisible,
    onViewAction,
    isSmallScreen,
    updatePatientStatus,
    columnShuffleDropDownList
  );

  const LoadingTableSkeleton = () => (
    <Table
      dataSource={[...Array(10)].map((_, index) => ({
        key: `${index}`,
        patientId: '',
        rafScore: 0,
        name: '',
        reason: '',
        actions: [],
        alerts: [], 
        status: '',
        lastVisit: ''
      })) as readonly any[]}
      columns={columns.map(column => ({
        ...column,
        render: () => <Skeleton active paragraph={false} />
      }))}
      pagination={false}
      scroll={{ x: true }}
      style={{ padding: 0, backgroundColor: 'white' }}
      size="small"
    />
  );

  const LoadingInitialDOView = () => {
      <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <DayOptimizerLoadingSvg />
          <NativeText fontSize={20} fontWeight={600} color={Colors.FoldPixel.GRAY400} style={{ textAlign: 'center' }}>
            {intl.formatMessage({ id: 'dayOptimizerSettingUp' })}
          </NativeText>
        </div>
  };

  return (
    <div
      ref={scrollRef}
      style={{
        height: containerHeight ? `${containerHeight - 55}px` : 'auto',
        overflowY: 'auto',
        ...cssPropertiesAntD.scrollableDiv,
      }}
      id="patientListTable"
    >
      {isLoading && patients?.length === 0 ? (
        <LoadingTableSkeleton />
      ) : patients.length === 0 && !isLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <NoPatientFoundSvg />
          <NativeText fontSize={20} fontWeight={600} color={Colors.FoldPixel.GRAY400} style={{ textAlign: 'center' }}>
            {intl.formatMessage({ id: 'noPatientFound' })}
          </NativeText>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={patients?.length}
          next={() =>
            fetchPatients(rafFilter, formattedDate, false, patients?.length)
          }
          hasMore={hasMore}
          loader={isLoading && patients.length > 0 && <InfiniteScrollLoader />}
          scrollableTarget="patientListTable"
          scrollThreshold={0.8}
          style={{ padding: 0, width: '100%' }}
          inverse={false}
        >
          <Table
            dataSource={patients as readonly any[]}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
            style={{ padding: 0, backgroundColor: 'white' }}
            size="small"
            rowClassName="table-row-dayOptimizer"
            rowKey={(record, index) =>
              `day_optimizer_list_${record?.patientId}_${index}`
            }
          />
        </InfiniteScroll>
      )}
      {/* {!hasMore && patients.length > 0 && (
        <p style={{ textAlign: 'center' }}>
          <b>You have seen it all</b>
        </p>
      )} */}
    </div>
  );
};

export default PatientListingTable;
