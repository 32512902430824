// import {useLazyQuery} from '@apollo/client';
import {Spinner, View} from 'native-base';
import {Dimensions} from 'react-native';
// import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
// import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
// import {CareJourneyQueries} from '../../../../../../services';
import {ICommerceCommonProps} from '../interfaces';
import ProductTable from './ProductTable/ProductTable';

const ProductTableMainView = (props: ICommerceCommonProps) => {
  const navigate = useNavigate();
  const screenHeight = Dimensions.get('window').height;

  const getElemForView = (): JSX.Element => {
    let productTableMainViewElem = <></>;
    if (props.productTableMainViewState.isRowSelected) {
      const productId =
        props.productTableMainViewState?.productData?.id || '-1';
      navigate(`/admin/commerce/${props.type}/product/${productId}`, {
        state: {
          productData: props.productTableMainViewState?.productData,
        },
      });
    } else {
      productTableMainViewElem = (
        <>
          {props.productTableMainViewState.loading && (
            <View
              zIndex={10}
              position="absolute"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              top="50%"
              left="50%"
            >
              <Spinner size="lg" />
            </View>
          )}
          <View
            height={screenHeight - 190}
            opacity={props.productTableMainViewState.loading ? 0.5 : 1.0}
          >
            <ProductTable
              searchText={props.searchText}
              onRowClicked={props.onRowClicked}
              tabCategoryId={props.tabCategoryId}
              type={props.type}
            />
          </View>
        </>
      );
    }
    return productTableMainViewElem;
  };

  return <View>{getElemForView()}</View>;
};

export default ProductTableMainView;
