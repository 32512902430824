import {View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import PageBodyContainer from '../../../common/PageBodyContainer/PageBodyContainer';
import AuditLogsList from './AuditLogsList';

const AuditLogs = (props: any) => {
  const [searchString, setSearchString] = useState(props.searchText);
  useEffect(() => {
    setSearchString(props.searchText);
  }, [props.searchText]);
  const screenHeight = Dimensions.get('window').height;
  return (
    <View height={screenHeight - 210}>
      <PageBodyContainer>
        <View flex={1} backgroundColor={'white'}>
          <AuditLogsList />
        </View>
      </PageBodyContainer>
    </View>
  );
};

export default AuditLogs;
