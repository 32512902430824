import * as React from 'react';
import {IStockLocationData} from '../services/ProductAndServices/interfaces/StockLocation';
import {ICommerceOauthTokenResp} from './interfaces';
export interface IProductsAndServicesDataContext {
  tokenData: ICommerceOauthTokenResp;
  stockLocationList: IStockLocationData[];
}
export const defaultData: IProductsAndServicesDataContext = {
  tokenData: {} as ICommerceOauthTokenResp,
  stockLocationList: [] as IStockLocationData[],
};

export const ProductsAndServicesContext = React.createContext(defaultData);
