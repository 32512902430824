import React from 'react';
import {Modal, Button, Text, View} from 'native-base';
import {IDeleteTagModalProps} from '../interfaces';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../../styles';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {BUTTON_TYPE} from '../../../../../constants';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {useIntl} from 'react-intl';
import {StyleSheet} from 'react-native';

const {Body, Content, Footer, Header} = Modal;

const DeleteTagModal = (props: IDeleteTagModalProps) => {
  const {title, isDeleteModalOpen, onDeleteModalClose, onCancel, isLabel} = props;
  const intl = useIntl();
  return (
    <Modal isOpen={isDeleteModalOpen} onClose={onCancel}>
      <Content
        minWidth={600}
        style={styles.content}
      >
        <Modal.CloseButton />
        <Header>
          <Text size={'lgNormal'}>{intl.formatMessage({id: isLabel ? 'deleteLabel' : 'deleteTag'})}</Text>
        </Header>
        <Body>
          <View>
            <Text size={'mdNormal'}>
              {title}
            </Text>
            <Text size={'mdNormal'}>{intl.formatMessage({ id: 'tagDeleteConfirmation' })}</Text>
          </View>
        </Body>
        <Footer
          style={styles.footer}
        >
          <Button.Group space={2}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onCancel();
                },
                style: {marginLeft: 20},
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onDeleteModalClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'delete',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </Footer>
      </Content>
    </Modal>
  );
};

export default DeleteTagModal;

const styles = StyleSheet.create({
  content: {
    backgroundColor: Colors.Custom.BackgroundColor,
  },
  footer: {
    backgroundColor: Colors.Custom.BackgroundColor,
    display: 'flex',
    justifyContent: 'flex-end',
  }
})