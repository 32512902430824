import { color } from 'native-base/lib/typescript/theme/styled-system';
import { ANALYTICS_RESOURCE, EMPLOYER_REPORT_FILTER_CODES } from '../../../../../constants';
import { GraphColors } from '../../../../../styles/Colors';
import { IBarChartData, IPieChartData } from '../interface';
import { isArray } from 'lodash';
import { getTenantForDemo } from '../tempDataFile';
import { StrCaseConverter } from '../../../../../utils/CaseConverter';
import {mapDayNameLongToShort} from '../../../../../constants/dateConst';
import {IDuringOfficeAfterOfficeHours, ISelectedGraphData} from '../DrillDownMemberList/interfaces';
import {OFFICE_HOURS_CONST, EMPLOYER_DRILL_DOWN_SUBTITLE_PLURAL, EMPLOYER_DRILL_DOWN_SUBTITLE_SINGULAR} from '../EmployerUtils/EmployerConst';
import {getTotalAggregateByMonth} from '../EmployerUtils/WidgetUtils';
import { AnalyteStatusSelect } from '../../../../common/AnalyteStatusSelect/AnalyteStatusSelect';
import {getDateStrFromFormat} from '../../../../../utils/DateUtils';
import moment from 'moment';
import { FILTER_CODES } from '../../../../common/AppDashboard/TaskDueToday/TaskDueTodayDashboard';



export const getGraphColorsByName = (
  data: {
    type: string;
    colorType?: string;
  }[]
): string[] => {
  const colors: string[] = [];
  data.forEach((item, index) => {
    if (typeof item?.type === 'string') {
      let typeStr = item?.type || '';
      if (item?.colorType) {
        typeStr = item?.colorType;
      }
      switch (typeStr?.toLowerCase()) {
        case 'male':
          colors.push('#D3756B');
          break;
        case 'female':
          colors.push('#FFC3A1');
          break;
        case 'children':
          colors.push('#F266AB');
          break;
        case '19-25 years':
          colors.push('#D98EC0');
          break;
        case '10-19 years':
          colors.push('#7DC0A6');
          break;
        case 'above 65 years':
          colors.push('#919FC7');
          break;
        case '26-35 years':
          colors.push('#F9DA56');
          break;
        case '36-50 years':
          colors.push('#919FC7');
          break;
        case '0-9 Years':
          colors.push('#ED936C');
          break;
        case 'video':
          colors.push('#00D6C2');
          break;
        case 'in person':
          colors.push('#98AEB8');
          break;
        case 'traditional cost':
        case 'Traditional Cost':
          colors.push('#FF8DC7');
          break;
        case 'our cost':
        case 'Our Cost':
          colors.push('#EE6983');
          break;
        case '51 & over':
          colors.push('#E9A178');
          break;
        case '31 - 50':
          colors.push('#C7B198');
          break;
        case '10 - 20':
          colors.push('#FF9D66');
          break;
        case '5 - 10':
          colors.push('#A68DAD');
          break;
        case 'under 5':
          colors.push('#FFACAC');
          break;
        case 'one off':
        case 'oneOff':
          colors.push('#FEBA68');
          break;
        case 'self sponsored':
        case 'self-sponsored':
        case 'selfSponsored'.toLowerCase():
          colors.push('#01F0E4');
          break;
        case 'employee':
          colors.push('#F8738E');
          break;
        case 'spouse':
          colors.push('#9DCC66');
          break;
        case 'child':
          colors.push('#FFD557');
          break;
        case 'unknown':
          colors.push('#FA9884');
          break;
        case 'dependent':
          colors.push('#8777F1');
          break;
        case 'user':
        case 'sent by practice':
          colors.push('#81C3F8');
          break;
        case 'contact':
        case 'sent by members':
          colors.push('#007AFF');
          break;
        case 'booked':
          colors.push('#81C3F8');
          break;
        case 'no show':
          colors.push('#007AFF');
          break;
        case 'totalCost'.toLowerCase():
          colors.push('#48EA7C');
          break;
        case 'totalClaimsRecords'.toLowerCase():
          colors.push('#C91B5F');
          break;
        case 'averageCostPerActiveLives'.toLowerCase():
          colors.push('#2819AF');
          break;
        case 'totalActivePatients'.toLowerCase():
          colors.push('#FBD8AB');
          break;
        case 'edCareNeededNotPreventableAmount'.toLowerCase():
          colors.push('#FC8EB7');
          break;
        case 'unclassifiedAmount'.toLowerCase():
          colors.push('#FBD8AB');
          break;
        case 'edCareNeededPreventableAvoidableAmount'.toLowerCase():
          colors.push('#C2D6B8');
          break;
        case 'emergentPcTreatableAmount'.toLowerCase():
          colors.push('#FEDD64');
          break;
        case 'emergentPcTreatableAmount'.toLowerCase():
          colors.push('#F09431');
          break;
        case 'injury'.toLowerCase():
          colors.push('#D54C4C');
          break;
        case 'member':
          colors.push('#1B7FBD');
          break;
        case 'appointments_office_hours':
          colors.push('#81C3F8');
          break;
        case 'appointments_out_of_office_hours':
          colors.push('#007AFF');
          break;
        case 'video_calls_office_hours':
          colors.push('#D3756B');
          break;
        case 'video_calls_out_of_office_hours':
          colors.push('#FFC3A1');
          break;
        case 'voice_calls_office_hours':
          colors.push('#FF8DC7');
          break;
        case 'voice_calls_out_of_office_hours':
          colors.push('#EE6983');
          break;
        case 'messages_office_hours':
          colors.push('#E9A178');
          break;
        case 'messages_out_of_office_hours':
          colors.push('#FF9D66');
          break;
        case 'visit_notes_office_hours':
          colors.push('#FBD8AB');
          break;
        case 'visit_notes_out_of_office_hours':
          colors.push('#C2D6B8');
          break;
        case 'members_count':
          colors.push('#00D6C2');
          break;
        case 'hypertension_members_count':
          colors.push('#EE6983');
          break;
        case 'bp sys':
          colors.push('#1B7FBD');
          break;
        case 'bp dia':
          colors.push('#81C3F8');
          break;
        case 'bmi':
          colors.push('#EE6983');
          break;
        case 'inactive members':
        case 'not engaged':
          return '#C91B5F'
          break;
        case 'total active members':
        case 'total engaged members':
          return '#C2D6B8'
          break;
        case 'total membership count':
          return '#FC8EB7'
          break;
        default:
          colors.push(
            GraphColors[Math.floor(Math.random() * GraphColors.length)]
          );
          break;
      }
    }
  });
  return [...new Set(colors)];;
};

export const getColorByTypeCode = (type: string): string => {
  if (typeof type === 'string') {
    switch (type?.toLowerCase()) {
      case 'male':
        return '#D3756B'
        break;
      case 'female':
        return '#FFC3A1'
        break;
      case 'children':
        return '#F266AB'
        break;
      case '19-25 years':
        return '#D98EC0'
        break;
      case '10-19 years':
        return '#7DC0A6'
        break;
      case 'above 65 years':
        return '#919FC7'
        break;
      case '26-35 years':
        return '#F9DA56'
        break;
      case '36-50 years':
        return '#919FC7'
        break;
      case '0-9 Years':
        return '#ED936C'
        break;
      case 'video':
        return '#00D6C2'
        break;
      case 'in person':
        return '#98AEB8'
        break;
      case 'traditional cost':
      case 'Traditional Cost':
        return '#FF8DC7'
        break;
      case 'our cost':
      case 'Our Cost':
        return '#EE6983'
        break;
      case '51 & over':
        return '#E9A178'
        break;
      case '31 - 50':
        return '#C7B198'
        break;
      case '10 - 20':
        return '#FF9D66'
        break;
      case '5 - 10':
        return '#A68DAD'
        break;
      case 'under 5':
        return '#FFACAC'
        break;
      case 'one off':
      case 'oneOff':
        return '#FEBA68'
        break;
      case 'self sponsored':
      case 'self-sponsored':
      case 'selfSponsored':
        return '#01F0E4'
        break;
      case 'employee':
        return '#F8738E'
        break;
      case 'spouse':
        return '#9DCC66'
        break;
      case 'child':
        return '#FFD557'
        break;
      case 'unknown':
        return '#FA9884'
        break;
      case 'dependent':
        return '#8777F1'
        break;
      case 'user':
      case 'sent by practice':
        return '#81C3F8'
        break;
      case 'contact':
      case 'sent by members':
        return '#007AFF'
        break;
      case 'totalCost'.toLowerCase():
        return '#48EA7C'
        break;
      case 'totalClaimsRecords'.toLowerCase():
        return '#C91B5F'
        break;
      case 'averageCostPerActiveLives'.toLowerCase():
        return '#2819AF'
        break;
      case 'totalActivePatients'.toLowerCase():
        return '#FBD8AB'
        break;
      case 'edCareNeededNotPreventableAmount'.toLowerCase():
        return '#FC8EB7'
        break;
      case 'unclassifiedAmount'.toLowerCase():
        return '#FBD8AB'
        break;
      case 'edCareNeededPreventableAvoidableAmount'.toLowerCase():
        return '#C2D6B8'
        break;
      case 'emergentPcTreatableAmount'.toLowerCase():
        return '#FEDD64'
        break;
      case 'emergentPcTreatableAmount'.toLowerCase():
        return '#F09431'
        break;
      case 'injury'.toLowerCase():
        return '#D54C4C'
        break;
      case 'member':
      case 'employerEnrollmentDate':
        return '#1B7FBD'
        break;
      case 'inactive members':
      case 'not engaged':
        return '#C91B5F'
        break;
      case 'total active members':
      case 'total engaged members':
        return '#C2D6B8'
        break;
      case 'total membership count':
        return '#FC8EB7'
        break;
      case 'OFFICE_HOURS'.toLowerCase():
        return '#80A4D5'
        break;
      case 'OUT_OF_OFFICE_HOURS'.toLowerCase():
        return '#BFD7F6'
        break;
      default:
        return ''
        break;
    }
  }
  return ''
}

// should be removed after backend changes
export const getFormmatedTrendsData = (code: string, data: any[]) => {
  if (
    [
      ANALYTICS_RESOURCE.TOP_DIAGNOSIS,
    ].includes(code)
  ) {
    // This change is made because we are sending it formatted from the back end only.
    return data;
    // const tempList = [];
  //   if (isArray(data) && data.length > 0) {
  //     for (const item of data) {
  //       const { displayText, code } = item ?? {};
  //       if(item?.memberCount) {
  //         const member = {
  //           displayText,
  //           code,
  //           type: 'member',
  //           countNum: item?.memberCount,
  //         };
  //         tempList.push(member)
  //       } else {
  //       const employee = {
  //         displayText,
  //         code,
  //         type: 'Employee',
  //         countNum: item?.employeeCount,
  //       };

  //       const dependant = {
  //         displayText,
  //         code,
  //         type: 'Dependent',
  //         countNum: item?.dependentCount,
  //       };

  //       tempList.push(employee, dependant);}
  //     }
  //   }
  //   return tempList ?? [];
  // } else {
  //   return data;
  }
};



export const getColorByPositiveStatus = (isPositive: boolean, isInverted?: boolean) => {
  if (isInverted) {
    return isPositive || getTenantForDemo()
      ? '#D92D20'
      : '#10B981';
  } else {
    return isPositive || getTenantForDemo()
      ? '#10B981'
      : '#D92D20';
  }
}

export const getBackgroundColorByPositiveStatus = (isPositive: boolean, isInverted?: boolean) => {
  if (isInverted) {
    return isPositive || getTenantForDemo()
      ? 'rgb(244, 204, 201)'
      : '#ecfdf3';
  } else {
    return isPositive || getTenantForDemo()
      ? '#ecfdf3'
      : 'rgb(244, 204, 201)';
  }
}


export const xAxisLabelFormatter = (resourceCode: string, labelName: string) => {
  if (
    resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY ||
    resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_DAY
  ) {
    return mapDayNameLongToShort[labelName];
  }
  return labelName
}

export const getSubtitleTextByResourceCode = (
  data: any,
  resourceCode: string,
  selectedValue?: string,
  totalContactCount?: number
) => {
  let subtitleText = '';
  const drillDownFormattedDate = `Month of ${getDateStrFromFormat(
    data?.date,
    'MMM YYYY'
  )}`;
  const drillDownType = data?.type;
  const drillDownCount = data?.count;
  const drillDownTotalContactCount =
    totalContactCount && totalContactCount > 1
      ? `${totalContactCount} Members •`
      : `${totalContactCount} Member •`;
  const memberTypeValue = `${totalContactCount} ${
    totalContactCount && totalContactCount > 1
      ? EMPLOYER_DRILL_DOWN_SUBTITLE_PLURAL[
          getFormattedMemberType(drillDownType).toLocaleLowerCase()
        ]
      : EMPLOYER_DRILL_DOWN_SUBTITLE_SINGULAR[
          getFormattedMemberType(drillDownType).toLocaleLowerCase()
        ]
  }`;
  const timeGroupValue = data?.filterData?.filter((item: any) => {
    return item?.filterCode === EMPLOYER_REPORT_FILTER_CODES.TIME_GROUP;
  });
  if (resourceCode === ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT) {
    const selectedObj = data?.selectedGraphData?.find(
      (graphDataObj: ISelectedGraphData) => {
        return graphDataObj?.code === selectedValue;
      }
    );

    subtitleText = `${drillDownTotalContactCount} ${
      selectedObj?.countNum
    }${selectedObj?.displayText.replace(
      'Number of',
      ''
    )} • ${drillDownFormattedDate}`;
  } else if (
    resourceCode === ANALYTICS_RESOURCE.PATIENT_ABSENCE_ANALYSIS ||
    resourceCode === ANALYTICS_RESOURCE.NOT_ENROLLED_FOR_CARE
  ) {
    const monthCount = data?.filterData[0]?.filterValue?.value;
    const days = monthCount * 30;
    subtitleText = ` ${
      totalContactCount && totalContactCount > 1
        ? totalContactCount + ' ' + 'Members'
        : totalContactCount + ' ' + 'Member'
    } ${
      EMPLOYER_DRILL_DOWN_SUBTITLE_PLURAL[
        getFormattedMemberType(data?.name).toLocaleLowerCase()
      ]
    } in last ${days} days`;
  } else if (
    resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_COUNT ||
    resourceCode === ANALYTICS_RESOURCE.MEMBERSHIP_NET_GROWTH ||
    resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_LOGGED_IN ||
    resourceCode === ANALYTICS_RESOURCE.MEMBERSHIP_INACTIVATED ||
    resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE
  ) {
    subtitleText = `${drillDownCount} ${
      drillDownCount > 1
        ? EMPLOYER_DRILL_DOWN_SUBTITLE_PLURAL[
            getFormattedMemberType(drillDownType).toLocaleLowerCase()
          ]
        : EMPLOYER_DRILL_DOWN_SUBTITLE_SINGULAR[
            getFormattedMemberType(drillDownType).toLocaleLowerCase()
          ]
    } • ${drillDownFormattedDate}`;
  } else if (resourceCode === ANALYTICS_RESOURCE.ENROLLED_FOR_CARE) {
    if (data?.type) {
      subtitleText = `${drillDownCount} ${
        drillDownCount > 1
          ? EMPLOYER_DRILL_DOWN_SUBTITLE_PLURAL[
              getFormattedMemberType(drillDownType).toLocaleLowerCase()
            ]
          : EMPLOYER_DRILL_DOWN_SUBTITLE_SINGULAR[
              getFormattedMemberType(drillDownType).toLocaleLowerCase()
            ]
      } • ${drillDownFormattedDate}`;
    } else {
      const monthCount = data?.filterData[0]?.filterValue?.value;
      const days = monthCount * 30;
      subtitleText = data?.date
        ? getDateStrFromFormat(data?.date, 'MMM YYYY')
        : days
        ? ` ${
            totalContactCount && totalContactCount > 1
              ? totalContactCount + ' ' + 'Members'
              : totalContactCount + ' ' + 'Member'
          } Engaged in Last ${days} days`
        : '';
    }
  } else if (
    resourceCode === ANALYTICS_RESOURCE.FOLD_CONVERSIONS ||
    resourceCode === ANALYTICS_RESOURCE.FOLD_INACTIVE_ENGAGEMENT
  ) {
    subtitleText = `${drillDownCount} ${
      EMPLOYER_DRILL_DOWN_SUBTITLE_PLURAL[drillDownType.toLowerCase()]
    } • Month of ${data?.date}`;
  } else if (resourceCode === ANALYTICS_RESOURCE.MEMBERSHIP_REVENUE_GROWTH) {
    subtitleText = `${drillDownType} - $${drillDownCount} • Month of ${data?.date}`;
  } else if (resourceCode === ANALYTICS_RESOURCE.CRM_ACTIVITY) {
    subtitleText = `${drillDownTotalContactCount} ${drillDownCount} ${drillDownType} • Month of ${data?.date}`;
  } else if (
    resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION ||
    resourceCode === ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE ||
    resourceCode === ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING ||
    resourceCode === ANALYTICS_RESOURCE.SMS_MESSAGING
  ) {
    subtitleText = `${drillDownTotalContactCount} ${drillDownCount} ${
      drillDownCount > 1
        ? EMPLOYER_DRILL_DOWN_SUBTITLE_PLURAL[
            getFormattedMemberType(drillDownType).toLocaleLowerCase()
          ]
        : EMPLOYER_DRILL_DOWN_SUBTITLE_SINGULAR[
            getFormattedMemberType(drillDownType).toLocaleLowerCase()
          ]
    } • ${drillDownFormattedDate}`;
  } else if (resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_DAY) {
    subtitleText = `${drillDownTotalContactCount} ${drillDownCount} ${
      drillDownCount > 1
        ? EMPLOYER_DRILL_DOWN_SUBTITLE_PLURAL[
            getFormattedMemberType(drillDownType).toLocaleLowerCase()
          ]
        : EMPLOYER_DRILL_DOWN_SUBTITLE_SINGULAR[
            getFormattedMemberType(drillDownType).toLocaleLowerCase()
          ]
    } • On ${data?.day}`
  } else if(resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_TIME) {
    subtitleText = `${drillDownTotalContactCount} ${drillDownCount} ${
      drillDownCount > 1
        ? EMPLOYER_DRILL_DOWN_SUBTITLE_PLURAL[
            getFormattedMemberType(drillDownType).toLocaleLowerCase()
          ]
        : EMPLOYER_DRILL_DOWN_SUBTITLE_SINGULAR[
            getFormattedMemberType(drillDownType).toLocaleLowerCase()
          ]
    } • At ${timeGroupValue ? timeGroupValue?.[0]?.filterValue?.[0] : ''} Hrs`
  } else if (resourceCode === ANALYTICS_RESOURCE.ENGAGEMENT_ON_APPLIED_RULES) {
    subtitleText = `${totalContactCount} ${drillDownType}`;
  } else if (resourceCode === ANALYTICS_RESOURCE.VIDEO_CALLING) {
    subtitleText = `${drillDownCount}(Minutes) • ${
      totalContactCount && totalContactCount > 1
        ? totalContactCount +
          ' ' +
          EMPLOYER_DRILL_DOWN_SUBTITLE_PLURAL[
            getFormattedMemberType(drillDownType).toLocaleLowerCase()
          ]
        : totalContactCount +
          ' ' +
          EMPLOYER_DRILL_DOWN_SUBTITLE_SINGULAR[
            getFormattedMemberType(drillDownType).toLocaleLowerCase()
          ]
    } • ${drillDownFormattedDate}`;
  } else if (
    resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS ||
    resourceCode === ANALYTICS_RESOURCE.BILLING_CPT_CODES
  ) {
    subtitleText = `${memberTypeValue} • ${drillDownCount} ${
      drillDownCount > 1 ? 'Appointments' : 'Appointment'
    } • ${drillDownFormattedDate}`;
  } else if (resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_TYPE) {
    const appointmentType = data?.filterData?.filter((item: any) => {
      return item?.filterCode === EMPLOYER_REPORT_FILTER_CODES.APPOINTMENT_TYPE;
    });
    subtitleText = `${memberTypeValue} • ${drillDownCount} ${
      appointmentType ? appointmentType?.[0]?.filterValue?.[0] : ''
    } ${drillDownCount > 1 ? 'Appointments' : 'Appointment'}`;
  } else if (
    resourceCode === ANALYTICS_RESOURCE.ENCOUNTER_TYPES ||
    resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED
  ) {
    subtitleText = `${drillDownTotalContactCount} ${drillDownCount} ${drillDownType} ${
      drillDownCount > 1 ? 'Appointments' : 'Appointment'
    } • ${drillDownFormattedDate}`;
  } else if (
    resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY
  ) {
    subtitleText = `${memberTypeValue} • ${drillDownCount} ${
      drillDownCount > 1 ? 'Appointments' : 'Appointment'
    } • On ${data?.day}`;
  } else if (
    resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME
  ) {
    subtitleText = `${memberTypeValue} • ${drillDownCount} ${
      drillDownCount > 1 ? 'Appointments' : 'Appointment'
    } • At ${timeGroupValue ? timeGroupValue?.[0]?.filterValue?.[0] : ''} Hrs`;
  } else if (
    resourceCode === ANALYTICS_RESOURCE.CHRONIC_RISKS_TREND ||
    resourceCode === ANALYTICS_RESOURCE.TOP_DIAGNOSIS
  ) {
    const chronicConditionValue = data?.filterData?.filter((item: any) => {
      return item?.filterCode === EMPLOYER_REPORT_FILTER_CODES.CONDITION;
    });
    subtitleText = `${drillDownCount} ${
      drillDownCount > 1
        ? EMPLOYER_DRILL_DOWN_SUBTITLE_PLURAL[
            getFormattedMemberType(drillDownType).toLocaleLowerCase()
          ]
        : EMPLOYER_DRILL_DOWN_SUBTITLE_SINGULAR[
            getFormattedMemberType(drillDownType).toLocaleLowerCase()
          ]
    } have ${
      chronicConditionValue ? chronicConditionValue?.[0]?.filterValue : ''
    }`;
  } else if (resourceCode === ANALYTICS_RESOURCE.TOP_CARE_JOURNEYS) {
    subtitleText = `${drillDownType} care journey was prescribed ${drillDownCount} ${
      drillDownCount > 1 ? 'times' : 'time'
    } to ${totalContactCount} ${
      totalContactCount && totalContactCount > 1 ? 'patients' : 'patient'
    }`;
  } else if (
    resourceCode === ANALYTICS_RESOURCE.TOP_SYMPTOMS_CHIEF_COMPLAINTS
  ) {
    subtitleText = `${drillDownCount} ${
      drillDownCount > 1 ? 'patients' : 'patient'
    } have ${drillDownType}`;
  } else if (
    resourceCode === 'POWER_BI_REPORT'
  ) {
    subtitleText = `${drillDownCount} ${
      drillDownCount > 1 ? 'patients' : 'patient'
    }`;
  } else {
    subtitleText = getCountAndDateWithType(data);
  }

  return subtitleText;
};

export const getFilterOfficeOrOutOfficeHours = (
  masterList: IDuringOfficeAfterOfficeHours[],
  selectedList: ISelectedGraphData[]
) => {
  return masterList?.filter((masterListItem) => {
    return selectedList?.some((selectedListItem) => {
      return selectedListItem?.code === masterListItem?.code;
    });
  });
};

export const getYAxisMaxValueWidgetObject = (dataList: any[], resourceCode?: string)=> {
  let maxLimit = 0;
  let formattedLimit = 0;
  if (resourceCode === ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT) {
    dataList.forEach((singleDataObj) => {
      const officeHoursValue  = singleDataObj[OFFICE_HOURS_CONST.OFFICE_HOURS]
      const outOfOfficeHoursValue  = singleDataObj[OFFICE_HOURS_CONST.OUT_OF_OFFICE_HOURS];
      const maxValue = Math.max(officeHoursValue, outOfOfficeHoursValue);
      maxLimit = maxLimit < maxValue ? maxValue : maxLimit;
    })
     formattedLimit = Math.ceil(maxLimit / 50) * 50;
  } else if (
    resourceCode === ANALYTICS_RESOURCE.MEMBERSHIP_NET_GROWTH ||
    resourceCode === ANALYTICS_RESOURCE.MEMBERSHIP_INACTIVATED ||
    resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_COUNT ||
    resourceCode === ANALYTICS_RESOURCE.ENROLLED_FOR_CARE ||
    resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION ||
    resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE ||
    resourceCode === ANALYTICS_RESOURCE.VIDEO_CALLING ||
    resourceCode === ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING ||
    resourceCode === ANALYTICS_RESOURCE.SMS_MESSAGING ||
    resourceCode === ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE ||
    resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS ||
    resourceCode === ANALYTICS_RESOURCE.ENCOUNTER_TYPES ||
    resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED ||
    resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_LOGGED_IN ||
    resourceCode === ANALYTICS_RESOURCE.BILLING_CPT_CODES ||
    resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY ||
    resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME ||
    resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_DAY ||
    resourceCode === ANALYTICS_RESOURCE.TELEPHONE_BY_TIME
    ) {
    const returnTotalList = getTotalAggregateByMonth(resourceCode,dataList)
    returnTotalList.forEach((singleDataObj: any) => {
      const value = singleDataObj.total
      maxLimit = maxLimit < value ? value : maxLimit;
    })
    formattedLimit = Math.round(maxLimit * 1.20);
  }
  return formattedLimit ? {maxLimit: Math.ceil(formattedLimit / 4) * 4} : {}
}


export const getXAxisMaxValueWidgetObject = (dataList: any[], resourceCode?: string)=> {
  let maxLimit = 0;
  let formattedLimit = 0
  if (
    resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_TYPE ||
    resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE ||
    resourceCode === ANALYTICS_RESOURCE.TOP_DIAGNOSIS ||
    resourceCode === ANALYTICS_RESOURCE.USER_MESSAGING ||
    resourceCode === ANALYTICS_RESOURCE.CHRONIC_RISKS_TREND ||
    resourceCode === ANALYTICS_RESOURCE.AUTOMATION_TIME_SAVED ||
    resourceCode === ANALYTICS_RESOURCE.TOP_MEDICATIONS ||
    resourceCode === ANALYTICS_RESOURCE.TOP_LABS ||
    resourceCode === ANALYTICS_RESOURCE.TOP_IMAGING ||
    resourceCode === ANALYTICS_RESOURCE.TOP_CARE_JOURNEYS ||
    resourceCode === ANALYTICS_RESOURCE.TOP_SYMPTOMS_CHIEF_COMPLAINTS
    ) {
    const returnTotalList = getTotalAggregateByMonth(resourceCode,dataList)
    returnTotalList.forEach((singleDataObj: any) => {
      const value = singleDataObj.total
      maxLimit = maxLimit < value ? value : maxLimit;
    })
    formattedLimit = Math.round(maxLimit * 1.25)
  }
  return formattedLimit ? {maxLimit: formattedLimit} : {}
}

export const getCountAndDateWithType = (data: any) => {
  if (data?.date && data?.date?.length < 7) {
    data.date = moment(data.date, 'MMM YY').format('YYYY-MM-DD');
  }
  return data?.type
    ? `${
        data?.type.replace(/_/g, ' ').charAt(0).toUpperCase() +
        data?.type.replace(/_/g, ' ').slice(1).toLowerCase()
      } - ${data?.count || ''}  ${data?.date ? '• ' + getDateStrFromFormat(data?.date, 'MMM YYYY') : ''} ${data?.day ? '• ' + data?.day : '' }`
    : '';
};

export const getMaxXAxisValueTopUtilization = (resourceData: any)=> {
  const combinedResourceData = getCombinedMaxValueForHorizontalStackBar(resourceData) as any;
  let maxValue = 0;
  combinedResourceData.forEach((singleResourceObj: any)=> {
    maxValue = singleResourceObj?.count > maxValue ? singleResourceObj?.count : maxValue;
  })
  maxValue = Math.round(maxValue * 1.20);
  return maxValue ? {maxLimit: Math.ceil(maxValue / 4) * 4} : {}
}

export const getMaxYAxisValueForLineCharts = (resourceData: any)=> {
  let maxValue = 0;
  resourceData.forEach((singleResourceObj: any)=> {
    maxValue = singleResourceObj?.value > maxValue ? singleResourceObj?.value : maxValue;
  })
  return maxValue ? {maxLimit: Math.ceil(maxValue / 4) * 4} : {}
}

export const getCombinedMaxValueForHorizontalStackBar = (resourceData: any) => {
  const combinedResourceData = [];
  const combinedResourceDataObj = {} as any;
  resourceData.forEach((singleResourceObj: any) => {
    if(combinedResourceDataObj.hasOwnProperty(singleResourceObj.code)) {
      combinedResourceDataObj[singleResourceObj.code] += singleResourceObj.count;
    } else {
      combinedResourceDataObj[singleResourceObj.code] = singleResourceObj.count;
    }
  })
  for(const [key, value] of Object.entries(combinedResourceDataObj)) {
    combinedResourceData.push({
      count: value,
      name: key,
      code: key,
    })
  }
  return combinedResourceData;
}

export const getCombinedPairForHorizontalStackBar = (resourceData: any[]) => {
  const groupedByGroupBy = resourceData.reduce((acc, curr) => {
    acc[curr.groupBy] = acc[curr.groupBy] || [];
    acc[curr.groupBy].push(curr.value);
    return acc;
  }, {} as any);

  const sumsByGroupBy = {} as any;
  for (const groupBy in groupedByGroupBy) {
    sumsByGroupBy[groupBy] = groupedByGroupBy[groupBy].reduce(
      (sum: any, value: any) => sum + value,
      0 as any
    );
  }
  const sortedSums = Object.entries(sumsByGroupBy).sort(
    (curr: any, next: any) => {
      return (next[1] - curr[1])
    }
  );
  const top10Sums = sortedSums.slice(0, 10).map((entry) => entry[0]);
  const result = resourceData.filter((entry) => top10Sums.includes(entry.groupBy));
  const sortedArray = result.sort((a, b) => {
    const sumA = sumsByGroupBy[a.groupBy];
    const sumB = sumsByGroupBy[b.groupBy];
    return sumB - sumA;
});
  return sortedArray
};

export const getFormattedMemberType = (value: string) => {
  if (!value) {
    return ''
  }
  if (value.includes('-')) {
    return value.replaceAll('-', '');
  } else if (value.includes(' ')) {
    return value.replaceAll(' ', '');
  } else {
    return value;
  }
};
