import {MedicationRequest, RequestGroup} from 'fhir/r4';
import {IRequestGroup} from '../../LeftContainer/RecentActivity/OrdersInterface';
import {IPersonData} from '../interfaces';
import { IOrderTimelineSelectedFilters } from './OrderTimelineFilter';

export interface ITabsProps {
  key: string;
  tab: string;
}

export enum IOrderTimeLineTabs {
  ALL = 'All',
  ERX = 'eRx',
  LAB = 'Labs',
  IMAGING = 'Imaging',
}

export interface IPatientNotesData {
  id: string;
  formName: string;
  date: string;
  formId: string;
  noteData?: any;
}

export interface ITabViewProps {
  notesData: IPatientNotesData[];
  personData: IPersonData;
  unformmatedContactDetails: any;
  searchQuery: string;
  activeFilter: IOrderTimelineSelectedFilters | undefined;
  handleNoteSelect: (note: any) => void;
}

export interface ITimelineData {
  noteId: string;
  formName: string;
  date: string;
  orders: (RequestGroup | MedicationRequest)[];
  noteData?: any;
}

export interface IMonthData {
  day: string;
  groups: ITimelineData[];
}

export interface ITimelineMap {
  month: string;
  data: IMonthData[];
}
export enum FILTER_MENU_KEYS {
  ALL = 'all',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in-progress',
  FAILED = 'failed',
}
