import {Drawer} from 'antd';
import {Text, View} from 'native-base';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../../../constants';
import {Colors} from '../../../../../../styles';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import {ICostTemplate} from './../helperFiles/interface';
const TemplateInfoDrawer = (props: {
  templateData: ICostTemplate;
  onCloseModal: () => void;
}) => {
  const {templateData, onCloseModal} = props;
  const {width} = Dimensions.get('window');

  const finalWidth = width / 3;
  return (
    <Drawer
      visible={true}
      width={finalWidth}
      onClose={() => {
        onCloseModal();
      }}
      closable={false}
      title={
        <ModalActionTitle
          text={'Cost Template Info'}
          titleColor={''}
          titleSize={24}
          buttonList={[
            {
              show: true,
              id: 2,
              btnText: 'close',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                onCloseModal();
              },
            },
          ]}
        />
      }
    >
      <View width={'100%'} style={{
        borderWidth: 1,
        borderColor: Colors.Custom.Gray100,
        borderRadius: 8,
        paddingHorizontal: 16,
        paddingVertical: 12,
      }}>
        <Text
          noOfLines={2}
          size={'mdMedium'}
          style={{color: Colors.Custom.Gray700, marginBottom: 12}}
        >
          {templateData?.name}
        </Text>
        <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
          <Text size={'smMedium'} style={{color: Colors.Custom.Gray500}}>
            {templateData?.description}
          </Text>
        </View>
      </View>
    </Drawer>
  );
};

export default TemplateInfoDrawer;
