import {StyleSheet} from 'react-native';

const baseMargin = {
  marginTop: 24,
  marginLeft: 24,
  marginRight: 24,
  marginBottom: 24,
};

export const styles = StyleSheet.create({
  shadow: {
    shadowColor: 'black',
    shadowOffset: {width: 2, height: 2},
    shadowOpacity: 0.2,
    shadowRadius: 8,
    elevation: 10,
  },
  loadingView: {
    ...baseMargin,
  },
});
