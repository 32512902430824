import React from 'react';
import {Table} from 'antd';
import {Dimensions, View} from 'react-native';
import TableWrapper from '../../common/TableWrapper/TableWrapper';
import { getCareProgramBillingColumns } from './CareProgramBillingHelper';
import { IBillingData } from './BillingHooks/BillingHookInterfaces';
import { ICareProgramBillingTableProps } from './interfaces';
import { useContext } from 'react';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { IFormCommonData } from '../Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import { getMlovListFromCategory } from '../../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../../constants';

const CareProgramBillingTable = (props: ICareProgramBillingTableProps) => {
  const {
    hookState,
    accountUserList,
    onPagination,
    pageNo,
    pageSize,
    handleTableActions,
    isBilledView,
    attributeAndCodesLoading,
    handleActions,
  } = props;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const careProgramTypes = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_TYPES) || [];
  const screenHeight = Dimensions.get('window').height;
  return (
    <View style={{flex: 1}}>
      <TableWrapper
        pagination={{
          pageSize: pageSize,
          current: pageNo,
          total: props.total,
          onChange(page, pageSize) {
            if (onPagination) {
              onPagination(page, pageSize);
            }
          },
        }}
      >
        <Table
          style={{
            backgroundColor: '#fff',
          }}
          scroll={{x: 700, y: screenHeight - 260}}
          columns={getCareProgramBillingColumns(
            handleTableActions,
            hookState,
            accountUserList,
            attributeAndCodesLoading,
            handleActions,
            careProgramTypes,
            isBilledView,
          )}
          dataSource={hookState.billingData}
          pagination={false}
          rowKey={(record: IBillingData) => record.id}
        />
      </TableWrapper>
    </View>
  );
};

export default CareProgramBillingTable;
