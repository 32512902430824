import {Table} from 'antd';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {IResponseTableProps} from '../interfaces';
import {getcolumns} from './ResponseColumns';

export const ResponseTable = (props: IResponseTableProps) => {
  const {height} = Dimensions.get('window');
  const finalHeight = height - 210;
  return (
    <Table
      rowClassName={(record, index) =>
        index % 2 == 0 ? 'table-row-light' : ''
      }
      style={{minHeight: finalHeight}}
      scroll={{x: 800, y: finalHeight}}
      dataSource={props.dataSource}
      columns={getcolumns(props.setAction)}
      pagination={false}
      onRow={(data) => {
        return {
          onClick: () => props.setAction(COMMON_ACTION_CODES.EDIT, data),
        };
      }}
    />
  );
};
