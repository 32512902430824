import {Table} from 'antd';
import React from 'react';

export default function ListArea({
  data,
  columns,
  loading,
  header,
  onClick,
}: {
  data: any;
  columns: Array<any>;
  loading?: boolean;
  header?: any;
  onClick?: any;
}) {
  if (loading) {
    return <>loading</>;
  }

  return (
    <div style={{width: '100%'}}>
      <Table
        style={{width: '100%'}}
        columns={columns}
        dataSource={data}
        pagination={{position: []}}
        onRow={(obj) => {
          return {
            onClick: () => onClick(obj),
          };
        }}
      />
    </div>
  );
}
