import {
  Button,
  Checkbox,
  FormControl,
  HStack,
  Input,
  ScrollView,
  Select,
  Text,
  VStack,
} from 'native-base';
import {useState} from 'react';
import {Dimensions, Pressable} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../../../styles';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {tagColorList} from '../../../../Contacts/Tags/TagsModal';
import {ColorSelector} from '../../../../Contacts/Tags/TagsModal/ColorSelector';
import {INBOX_CHANNEL_TYPE, INTEGRATION_CONSTANTS} from '../../constants';
import {styles} from '../../IntegrationsStyles';
import {IIntegrationsScreenTwoProps} from '../../interfaces';
const {height} = Dimensions.get('window');
const {Label} = FormControl;

export const IntegrationScreenTwo = (props: IIntegrationsScreenTwoProps) => {
  const [currentData, setCurrentData] = useState<any>({
    websiteName: '',
    websiteDomain: '',
    welcomeHeading: '',
    welcomeTagline: '',
    enablePreChat: 'Yes',
    preChatMessage: '',
  });
  const [color, setColor] = useState('#808080');
  const [greeting, setGreeting] = useState('Enable');
  const [error, setError] = useState<any>({});
  const [visitorsProvideDetails, setVisitorsProvideDetails] = useState(false);

  const validate = () => {
    if (props.currentChannel === INTEGRATION_CONSTANTS.EMAIL) {
      const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

      if (reg.test(currentData.email) == false) {
        setError({...error, invalidEmail: 'Entered Invalid Email'});
        return false;
      }
      return true;
    }
  };

  const validateWebsite = () => {
    setError({});
    if (currentData.websiteName == '') {
      setError({websiteNameError: 'Please fill field'});
      return false;
    }
    if (currentData.websiteDomain == '') {
      setError({websiteDomainError: 'Please fill field'});
      return false;
    }
    if (currentData.welcomeHeading == '') {
      setError({welcomeHeadingError: 'Please fill field'});
      return false;
    }
    if (currentData.welcomeTagline == '') {
      setError({welcomeTaglineError: 'Please fill field'});
      return false;
    }
    if (greeting == '') {
      setError({greetingError: 'Please choose an option'});
      return false;
    }
    if (greeting == 'Enable' && currentData.greetingMessage == '') {
      setError({greetingMessageError: 'Please fill field'});
      return false;
    }
    return true;
  };

  const emailChannel = () => {
    return (
      <VStack space={8}>
        <FormControl style={styles.screenTwo}>
          <Label>{INTEGRATION_CONSTANTS.CHANNEL_NAME}</Label>
          <Input
            fontSize={'sm'}
            placeholder="Please enter a channel name"
            onChangeText={(value) =>
              setCurrentData({...currentData, channelName: value})
            }
          />
        </FormControl>
        <FormControl style={styles.screenTwo}>
          <Label>{INTEGRATION_CONSTANTS.EMAIL}:</Label>
          <Input
            fontSize={'sm'}
            placeholder="Email"
            onChangeText={(value) =>
              setCurrentData({...currentData, email: value})
            }
          />
          {error.invalidEmail != '' ? (
            <Label
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {error.invalidEmail}
            </Label>
          ) : (
            <></>
          )}
        </FormControl>
        <VStack style={styles.leftPad}>
          <Button
            style={styles.emailButton}
            variant="solid"
            colorScheme="primary"
            _text={{color: 'white'}}
            onPress={() => {
              if (validate()) {
                props.handleScreenTwoChange(
                  INTEGRATION_CONSTANTS.SCREEN_THREE,
                  currentData
                );
              }
            }}
          >
            {INTEGRATION_CONSTANTS.CREATE_EMAIL_CHANNEL}
          </Button>
        </VStack>
      </VStack>
    );
  };

  const websiteChannel = () => {
    return (
      <ScrollView overflow={'scroll'} height={height - 210}>
        <VStack space={4}>
          <FormControl style={styles.screenTwo}>
            <Text size={'mdNormal'} marginBottom={1}>
              {INTEGRATION_CONSTANTS.WEBSITE_NAME}
            </Text>
            <Input
              fontSize={'sm'}
              placeholder="Enter your Website Name"
              onChangeText={(value) =>
                setCurrentData({...currentData, websiteName: value})
              }
            />
            {error.websiteNameError && (
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {error.websiteNameError}
              </FormControl.ErrorMessage>
            )}
            <Text size={'smLight'} color={'error.500'}>
              {error.websiteNameError}
            </Text>
          </FormControl>
          <FormControl style={styles.screenTwo}>
            <Text size={'mdNormal'} marginBottom={1}>
              {INTEGRATION_CONSTANTS.WEBSITE_DOMAIN}
            </Text>
            <Input
              fontSize={'sm'}
              placeholder="Enter your Website Domain"
              onChangeText={(value) =>
                setCurrentData({...currentData, websiteDomain: value})
              }
            />
            <Text size={'smLight'} color={'error.500'}>
              {error.websiteDomainError}
            </Text>
          </FormControl>
          <FormControl style={styles.screenTwoColor}>
            <ColorSelector
              color={color}
              title={INTEGRATION_CONSTANTS.WIDGET_COLOR}
              colorList={tagColorList}
              onBtnPress={(color: string) => setColor(color)}
              onChangeHex={(text) => setColor('#' + text)}
            />
          </FormControl>
          <FormControl style={styles.screenTwo}>
            <Text size={'mdNormal'} marginBottom={1}>
              {INTEGRATION_CONSTANTS.WELCOME_HEADING}
            </Text>
            <Input
              fontSize={'sm'}
              placeholder="Hi there!"
              onChangeText={(value) =>
                setCurrentData({...currentData, welcomeHeading: value})
              }
            />
            <Text size={'smLight'} color={'error.500'}>
              {error.welcomeHeadingError}
            </Text>
          </FormControl>
          <FormControl style={styles.screenTwo}>
            <Text size={'mdNormal'} marginBottom={1}>
              {INTEGRATION_CONSTANTS.WELCOME_TAGLINE}
            </Text>
            <Input
              fontSize={'sm'}
              placeholder="We make it simple to connect with us"
              onChangeText={(value) =>
                setCurrentData({...currentData, welcomeTagline: value})
              }
            />
            <Text size={'smLight'} color={'error.500'}>
              {error.welcomeTaglineError}
            </Text>
          </FormControl>
          <FormControl>
            <Text size={'mdNormal'} marginBottom={1}>
              Enable pre chat form
            </Text>
            <Select
              width={300}
              defaultValue={'Yes'}
              accessibilityLabel="Enable pre chat form:"
              placeholder="Enable pre chat form:"
              onValueChange={(itemValue) =>
                setCurrentData({...currentData, enablePreChat: itemValue})
              }
              fontSize={'sm'}
            >
              <Select.Item label="Yes" value="Yes" />
              <Select.Item label="No" value="No" />
            </Select>
          </FormControl>
          {currentData.enablePreChat && currentData.enablePreChat === 'Yes' ? (
            <FormControl>
              <DisplayText
                textLocalId="preChatMessage"
                size={'mdNormal'}
                extraStyles={{marginBottom: 1}}
              />
              <Input
                fontSize={'sm'}
                width={300}
                placeholder="Hi there!"
                onChangeText={(value) =>
                  setCurrentData({...currentData, preChatMessage: value})
                }
              />
              <HStack style={styles.checkBox} space={2}>
                <Checkbox
                  onChange={(isSelected) =>
                    setVisitorsProvideDetails(isSelected)
                  }
                  value={'visitorsProvideDetails'}
                />
                <DisplayText
                  textLocalId={'inboxVisitorCheckBoxTitle'}
                  size={'mdLight'}
                />
              </HStack>
            </FormControl>
          ) : (
            <></>
          )}
          <FormControl style={styles.screenTwo}>
            <Text size={'mdNormal'} marginBottom={1}>
              {INTEGRATION_CONSTANTS.ENABLE_GREETING}
            </Text>
            <Select
              fontSize={'sm'}
              defaultValue={'Enable'}
              selectedValue={greeting}
              accessibilityLabel="Enable Channel Greeting:"
              placeholder="Enable Channel Greeting:"
              onValueChange={(itemValue) => setGreeting(itemValue)}
            >
              <Select.Item label="Enable" value="Enable" />
              <Select.Item label="Disable" value="Disable" />
            </Select>
            <Text size={'smLight'} color={'error.500'}>
              {error.greetingError}
            </Text>
          </FormControl>
          {greeting === 'Enable' ? (
            <FormControl style={styles.screenTwo}>
              <Text size={'mdNormal'} marginBottom={1}>
                Enter Greeting:
              </Text>
              <Input
                fontSize={'sm'}
                placeholder="Enter Greeting"
                onChangeText={(value) =>
                  setCurrentData({...currentData, greetingMessage: value})
                }
              />
              <Text size={'smLight'} color={'error.500'}>
                {error.greetingMessageError}
              </Text>
            </FormControl>
          ) : (
            <></>
          )}
          <VStack>
            <Button
              style={styles.websiteButton}
              variant="solid"
              colorScheme="primary"
              _text={{color: 'white'}}
              onPress={() => {
                validateWebsite() ? (
                  props.handleScreenTwoChange(
                    INTEGRATION_CONSTANTS.SCREEN_THREE,
                    {
                      ...currentData,
                      widgetColor: color,
                      visitorsProvideDetails,
                      greetingStatus: greeting === 'Enable' ? true : false,
                    }
                  )
                ) : (
                  <></>
                );
              }}
            >
              {INTEGRATION_CONSTANTS.CREATE_INBOX}
            </Button>
          </VStack>
        </VStack>
      </ScrollView>
    );
  };

  const getCurrentChannelScreen = () => {
    switch (props.currentChannel) {
      case INTEGRATION_CONSTANTS.EMAIL:
        return emailChannel();

      case INBOX_CHANNEL_TYPE.WEB_SITE:
        return websiteChannel();
    }
  };
  return (
    <VStack>
      <HStack style={styles.titleBar}>
        <HStack space={10} justifyContent={'center'} alignItems={'center'}>
          <Pressable onPress={() => props.onBackClick(0)}>
            <Icon name="left" size={25} color={Colors.primary[400]} />
          </Pressable>
          <VStack>
            <DisplayText
              textType="Heading"
              textLocalId="createInbox"
              size={'lgNormal'}
            />
            <Text color={Colors.Custom.DataKeyColor} size={'smLight'}>
              Create a channel for your website and start supporting your
              customers via our website widget.
            </Text>
          </VStack>
        </HStack>
        {/* <Button
          size="sm"
          variant="solid"
          colorScheme="dark"
          _text={{color: 'white'}}
          onPress={props.onCancelClick}
        >
          <DisplayText
            textLocalId="close"
            extraStyles={{color: Colors.Custom.FontColorWhite}}
            size={'mdNormal'}
          />
        </Button> */}
      </HStack>
      <ScrollView style={styles.leftPadLarge}>
        <VStack space={8}>{getCurrentChannelScreen()}</VStack>
      </ScrollView>
    </VStack>
  );
};
