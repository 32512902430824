export const performanceOverTimeMock = {
  'Body Height': {
    Aug: 168.4810279602599,
    Sep: 168.4810279602599,
    Oct: 168.4810279602599,
    Nov: 168.4810279602599,
    Dec: 168.4810279602599,
    Jan: 168.4810279602599,
    Feb: 168.4810279602599,
    Mar: 168.4810279602599,
    Apr: 168.4810279602599,
    May: 168.4810279602599,
    Jun: 169.77315091867848,
    Jul: 166.24146130948333,
  },
  'Body Weight': {
    Aug: 115.66423905474713,
    Sep: 115.14502807049445,
    Oct: 114.35143898866885,
    Nov: 113.50108031165719,
    Dec: 112.45426231301495,
    Jan: 111.57370137329232,
    Feb: 110.42787992660524,
    Mar: 109.90497540127718,
    Apr: 109.12634759592972,
    May: 108.18891254320891,
    Jun: 107.82995541176429,
    Jul: 117.55100335410746,
  },
  'Body Mass Index': {
    Aug: 41.49063506459898,
    Sep: 41.40482400580448,
    Oct: 41.08472960805058,
    Nov: 40.78628858249762,
    Dec: 40.39098556933339,
    Jan: 40.067249615142934,
    Feb: 39.650158519783204,
    Mar: 39.48713244651541,
    Apr: 39.207465967833016,
    May: 38.87465328377668,
    Jun: 38.530635387464415,
    Jul: 43.54288030854775,
  },
  'Diastolic Blood Pressure': {
    Aug: 96.06800415061531,
    Sep: 102.14621440827217,
    Oct: 101.30353266898706,
    Nov: 101.393655113128,
    Dec: 98.44706156569477,
    Jan: 96.00412787465017,
    Feb: 101.58029149350439,
    Mar: 99.24921546615117,
    Apr: 98.07742125988325,
    May: 102.99489151853803,
    Jun: 101.21710607276054,
    Jul: 99.75981472616965,
  },
  'Systolic Blood Pressure': {
    Aug: 160.69176445956796,
    Sep: 153.8117114646743,
    Oct: 152.89954132022066,
    Nov: 158.52917513645858,
    Dec: 155.9700989076938,
    Jan: 158.98104281678746,
    Feb: 154.3749043105661,
    Mar: 154.8271222669992,
    Apr: 161.33076571450903,
    May: 159.56872461486054,
    Jun: 161.52272305330243,
    Jul: 157.6586548077008,
  },
  Glucose: {
    Aug: 119.5129625768856,
    Sep: 117.76912085085992,
    Oct: 117.71267443800194,
    Nov: 117.984183126549,
    Dec: 114.1562353999732,
    Jan: 118.82633589997049,
    Feb: 122.34725562418392,
    Mar: 110.31483368711467,
    Apr: 105.52675645107752,
    May: 113.56003407554782,
    Jun: 90.8267882088974,
    Jul: 97.1372660576045,
  },
  'Urea Nitrogen': {
    Aug: 13.363492986930073,
    Sep: 12.415808230359577,
    Oct: 15.296004537394955,
    Nov: 12.190711976982024,
    Dec: 11.87947606148231,
    Jan: 13.322905686589593,
    Feb: 13.253440611055149,
    Mar: 12.949085676277832,
    Apr: 14.513527798160327,
    May: 13.477853158862043,
    Jun: 12.322645266211051,
    Jul: 16.850828861840956,
  },
  Creatinine: {
    Aug: 2.3936832250127216,
    Sep: 2.219181734094336,
    Oct: 2.121831799624654,
    Nov: 2.0339579741754577,
    Dec: 2.282229973529181,
    Jan: 2.337795989345277,
    Feb: 2.3074975866280245,
    Mar: 2.346045407511855,
    Apr: 2.210412970565368,
    May: 1.9499041373092345,
    Jun: 2.7168962490255533,
    Jul: 3.2013569946253253,
  },
  Calcium: {
    Aug: 9.436037746089275,
    Sep: 9.101232115237647,
    Oct: 9.356242241596622,
    Nov: 9.411455442632958,
    Dec: 9.453177506198125,
    Jan: 9.303635934212345,
    Feb: 9.531839487375063,
    Mar: 9.143557494253562,
    Apr: 9.360731145507282,
    May: 9.348984586206235,
    Jun: 9.357165286732048,
    Jul: 9.461346269621265,
  },
  Sodium: {
    Aug: 139.806868223411,
    Sep: 140.2715479860909,
    Oct: 139.61791137562844,
    Nov: 139.37295248027684,
    Dec: 139.9699073415942,
    Jan: 140.06622719408296,
    Feb: 140.02332565747935,
    Mar: 140.33846684966855,
    Apr: 140.05633884484592,
    May: 140.36373230547449,
    Jun: 141.39404182576806,
    Jul: 138.51701124964694,
  },
  Potassium: {
    Aug: 4.549951247827484,
    Sep: 4.629038562533742,
    Oct: 4.629732484780415,
    Nov: 4.367663881451622,
    Dec: 4.52816060007884,
    Jan: 4.525089541704616,
    Feb: 4.40356297583366,
    Mar: 4.38602319059682,
    Apr: 4.181137107271677,
    May: 4.3875727969463965,
    Jun: 4.647130356177394,
    Jul: 4.756658000120815,
  },
  Chloride: {
    Aug: 106.69318175778793,
    Sep: 106.02686088512127,
    Oct: 105.87360544848525,
    Nov: 104.21863602808227,
    Dec: 106.97276729815218,
    Jan: 106.20226205229072,
    Feb: 106.94941934875712,
    Mar: 105.37306518981738,
    Apr: 106.24877952428503,
    May: 106.36103770696204,
    Jun: 106.87977616700782,
    Jul: 106.2634616990533,
  },
  'Carbon Dioxide': {
    Aug: 24.241466428042184,
    Sep: 25.975791136403355,
    Oct: 23.314544863643995,
    Nov: 24.733639186176838,
    Dec: 24.73897080269825,
    Jan: 24.20843038696823,
    Feb: 25.69285216820862,
    Mar: 24.256166248479264,
    Apr: 24.97342116113342,
    May: 24.489781290019426,
    Jun: 25.279151579031623,
    Jul: 25.751637132236397,
  },
  'Hemoglobin A1c/Hemoglobin.total in Blood': {
    Aug: 6.711722137715441,
    Sep: 6.658741706951745,
    Oct: 6.637428158435839,
    Nov: 6.599687828988192,
    Dec: 6.519796026041971,
    Jan: 6.501725347730518,
    Feb: 6.460684180245381,
    Mar: 6.467122414956658,
    Apr: 6.196094412613732,
    May: 6.153065154547338,
    Jun: 5.292822886375397,
    Jul: 4.838600807054453,
  },
  'Oral temperature': {
    Aug: 37.42833684272307,
    Sep: 37.926852406208184,
    Oct: 37.252767294890894,
    Nov: 37.457165288056814,
    Dec: 0.0,
    Jan: 0.0,
    Feb: 0.0,
    Mar: 0.0,
    Apr: 0.0,
    May: 0.0,
    Jun: 0.0,
    Jul: 0.0,
  },
  'Total Cholesterol': {
    Aug: 214.40085839737853,
    Sep: 219.86029221958563,
    Oct: 213.59587305525432,
    Nov: 223.41897734308685,
    Dec: 223.21182720864417,
    Jan: 224.58299463328925,
    Feb: 224.64804523708605,
    Mar: 218.80532289631037,
    Apr: 217.0156607021912,
    May: 219.20801286422665,
    Jun: 195.1474729150748,
    Jul: 201.07639962934581,
  },
  Triglycerides: {
    Aug: 206.9124216272335,
    Sep: 249.62809957175398,
    Oct: 210.6752237661127,
    Nov: 244.1911504055874,
    Dec: 273.3522729310111,
    Jan: 294.2690248918806,
    Feb: 261.4754753784296,
    Mar: 273.3776936938532,
    Apr: 237.21965353684936,
    May: 226.06667295288315,
    Jun: 167.23289996350084,
    Jul: 206.50565596660016,
  },
  'Low Density Lipoprotein Cholesterol': {
    Aug: 123.56515830468082,
    Sep: 123.97657379347075,
    Oct: 119.24177398850219,
    Nov: 125.75482515463233,
    Dec: 125.56586392761973,
    Jan: 122.55333119353075,
    Feb: 125.59191337478381,
    Mar: 117.27228780312552,
    Apr: 119.55449008371042,
    May: 127.11663831945738,
    Jun: 102.30951181961852,
    Jul: 97.7855222293803,
  },
  'High Density Lipoprotein Cholesterol': {
    Aug: 49.453215767250974,
    Sep: 46.828882079960714,
    Oct: 51.348270745332954,
    Nov: 46.49908400828498,
    Dec: 45.72540826642916,
    Jan: 46.29566983103509,
    Feb: 44.10836808296031,
    Mar: 45.62015089943278,
    Apr: 49.14657142428755,
    May: 48.51891123000068,
    Jun: 59.39138110275608,
    Jul: 61.989746206645464,
  },
  'Estimated Glomerular Filtration Rate': {
    Aug: 92.65850017732582,
    Sep: 94.53553905360013,
    Oct: 88.2943782945024,
    Nov: 89.26188790092999,
    Dec: 92.63289358399952,
    Jan: 74.83496021355165,
    Feb: 86.43967753408914,
    Mar: 81.27510661544136,
    Apr: 85.67317878018308,
    May: 90.738994151958,
    Jun: 80.60280839971286,
    Jul: 49.35977071662768,
  },
  'Microalbumin Creatinine Ratio': {
    Aug: 57.91753062578155,
    Sep: 55.93708418661697,
    Oct: 76.12534400861246,
    Nov: 44.22565103723037,
    Dec: 56.31331627533201,
    Jan: 34.83114891403133,
    Feb: 58.6650723566944,
    Mar: 54.66532166371091,
    Apr: 61.82238242494904,
    May: 45.49758966369216,
    Jun: 114.80183813108043,
    Jul: 129.70835751843603,
  },
  'Hemoglobin.gastrointestinal [Presence] in Stool by Immunologic method': {
    Aug: 212.5386049206757,
    Sep: 0.0,
    Oct: 0.0,
    Nov: 0.0,
    Dec: 0.0,
    Jan: 0.0,
    Feb: 0.0,
    Mar: 0.0,
    Apr: 0.0,
    May: 0.0,
    Jun: 0.0,
    Jul: 0.0,
  },
};

export const pOverTimeMock = Object.keys(performanceOverTimeMock).map(
  (key) => ({
    key,
    name: key,
    months: Object.keys(performanceOverTimeMock[key]).map((k) => ({
      label: k,
      value: Number(performanceOverTimeMock[key][k].toFixed(2)),
      field: key,
    })),
  })
);
