import {HStack, ScrollView, Text, View, VStack} from 'native-base';
//import { styles } from '../IntegrationsStyles';
import {Dimensions} from 'react-native';
import {CRM_URLs} from '../../../../../constants/BaseUrlConst';
import {formatEditWidgetConfigOld} from '../Helper/formatIntegrationsData';
import {IIntegrationEditWidgetConfig} from '../interfaces';
//import { useMutation } from '@apollo/client';
//import InboxQueries from '../../../../../services/Inbox/InboxQueries';
const {height} = Dimensions.get('window');

export const IntegrationEditWidgetConfig = (
  props: IIntegrationEditWidgetConfig
) => {
  const formattedData = formatEditWidgetConfigOld(
    props.currentDetails.channelWebWidget
  );

  //const [updateWidgetConfig] = useMutation(InboxQueries.UpdateWidgetConfig);

  /*const handleCheckBoxClick = (value: boolean) => {
    updateWidgetConfig({
      variables: {
        id: props.currentDetails.channelWebWidget.id,
        hmacMandatory: value,
      },
    });
  };*/

  return (
    <ScrollView height={height - 200}>
      <VStack marginLeft={10} marginTop={10}>
        <HStack>
          <VStack>
            <Text size={'mdNormal'}>Messenger Script</Text>
            <Text size={'smLight'} color={'gray.500'} marginBottom={3}>
              Place this button inside your body tag
            </Text>
            <View
              background={'gray.100'}
              paddingTop={4}
              paddingBottom={4}
              borderRadius={12}
              paddingRight={5}
            >
              <Text size={'mdLight'} textAlign="start">
                {`           <script>
                (function(d,t) {
                    var BASE_URL="${CRM_URLs.CHATWOOT}";
                    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
                    g.src=BASE_URL+"/packs/js/sdk.js";
                    g.defer = true;
                    g.async = true;
                    s.parentNode.insertBefore(g,s);
                    g.onload=function(){
                    window.chatwootSDK.run({
                        websiteToken: '${formattedData?.websiteToken}',
                        baseUrl: BASE_URL
                    })
                  }
                })(document,"script");
                </script>`}
              </Text>
            </View>
          </VStack>
        </HStack>
        {/* <Divider />
        <HStack space={40}>
          <VStack style={styles.configLeft}>
            <Text fontSize={'md'}>User Identity Validation</Text>
            <Text>
              Inorder to validate the user's identity, the SDK allows you to
              pass an `identifier_hash` for each user. You can generate HMAC
              using 'sha256' with the key shown here.
            </Text>
          </VStack>
          <VStack>
            {formattedData?.hmacToken ? (
              <Box style={styles.boxCodeSmall}>
                <Text fontSize={'xs'}>{formattedData?.hmacToken}</Text>
              </Box>
            ) : (
              <></>
            )}
          </VStack>
        </HStack>
        <Divider />
        <HStack space={40}>
          <VStack style={styles.configLeft}>
            <Text fontSize={'md'}>Enforce User Identity Validation</Text>
            <Text>
              If enabled, Chatwoot SDKs setUser method will not work unless the
              `identifier_hash` is provided for each user.
            </Text>
          </VStack>
          <VStack style={styles.checkBoxBottom}>
            <Checkbox
              defaultIsChecked={formattedData.hmacMandatory}
              value={'enforceIdentityValidation'}
              onChange={(isSelected) => {
                handleCheckBoxClick(isSelected);
              }}
            >
              Enable
            </Checkbox>
          </VStack>
        </HStack> */}
      </VStack>
    </ScrollView>
  );
};
