import moment from 'moment';
import {GROUP_TYPE_CODES} from '../../../../../../constants/MlovConst';
import {IAllowedUserPracticeLocationUuidsAndName, ILoginUserData} from '../../../../../../Interfaces/CommonInterfaces';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import {getDateObjFromCode} from '../../../../../common/CustomFilterView/OneViewFilter/HelperUtils';
import {
  CHANNEL_TYPE,
  CHANNEL_TYPE_CODE,
  CONVERSATION_TAB_CODES
} from '../../ConversationConst';
import {assignStatusValue, CONTACT_TYPE_CODES} from '../ConversationConst';
import {getConversationChannelTabFilter} from './ConversationChannelTabFilterUtils';
import {
  IAdditionFilterData, IConversationContactTypeQuery,
  IConversationFilterData,
  IConversationFilterRef,
  IConversationFilterState,
  IConversationFilterStateV2,
  IDateFilterQuery,
  IFilterDataObj,
  IGetConversationFilterDataV2,
  IInboxFilterQuery,
  IUserLocationData,
  Users
} from './interface';
import { MENTION_READ_STATUS } from '../../../../../../constants';
import {EMAIL_INBOX_USER_CODE} from '../../../../../common/PreferencesSetting/PreserenceConst';
import { IUserPracticeLocation, IUserPracticeLocationResp } from '../../../../../common/CustomUserSearch/interface';

export const getConversationFilterData = (
  filterState: IConversationFilterState,
  selectedTabCode: string,
  additionFilterData: IAdditionFilterData,
  selectedAssignedFilter?: string,
  showOnlyMentioned?: boolean,
  isEFaxMessagingEnabled?: boolean,
  isPatientMessagingEnabled?: boolean,
  emailInboxUserPreference?: string,
  otherContactTypeId?: string,
  isEmailUserPreferenceEnabled?: boolean,
) => {
  const tempFilterData = {
    inboxFilterData: {} as IInboxFilterQuery,
    contactTypeFilterData: {} as IConversationContactTypeQuery,
    dateFilterData: [] as IDateFilterQuery[],
  };
  let conversationFinalFilters: any = {
    accountId: {_eq: additionFilterData.accountId},
    _and: [],
  };


  const tempUserFilterData = getConversationChannelTabFilter(
    selectedTabCode || CHANNEL_TYPE_CODE.CHANNEL_ALL,
    additionFilterData,
    isEFaxMessagingEnabled,
    isPatientMessagingEnabled,
    emailInboxUserPreference,
    otherContactTypeId,
    isEmailUserPreferenceEnabled,
  ) as any;
  if (tempUserFilterData) {
    conversationFinalFilters = {...conversationFinalFilters, ...tempUserFilterData};
  }


  if (filterState.selectedInboxData?.id || filterState.emailSelectedInboxData?.id) {
    const inboxIds: number[] = [];
    if (filterState.selectedInboxData?.id) {
      inboxIds.push(filterState.selectedInboxData?.id);
    }
    if (filterState.emailSelectedInboxData?.id) {
      inboxIds.push(filterState.emailSelectedInboxData?.id);
    }
    conversationFinalFilters.inboxId= {
      _in: inboxIds,
    }
  }else{
    // conversationFinalFilters.inboxId= {
    //   _neq: -1,
    // }
  }

  if (
    filterState.selectedContactType &&
    filterState.selectedContactType != 'ALL'
  ) {
    const contactTypeFilterData = getContactTypeFilter(
      filterState.selectedContactType,
      selectedTabCode,
      additionFilterData
    );
    if (contactTypeFilterData?._and?.length) {
      conversationFinalFilters._and.push(...contactTypeFilterData?._and);
    } else {
      conversationFinalFilters._and.push(contactTypeFilterData);
    }
  }

  if (filterState?.toDate && filterState?.fromDate) {
    tempFilterData.dateFilterData = getDateFilterObjByToFrom(
      selectedTabCode,
      filterState.toDate,
      filterState.fromDate,
    );
    conversationFinalFilters._and.push(...tempFilterData.dateFilterData);
  } else if (filterState.selectedDateCode && filterState.selectedDateCode !== 'ALL') {
    tempFilterData.dateFilterData = getDateFilterObj(
      selectedTabCode,
      filterState.selectedDateCode
    );
    conversationFinalFilters._and.push(...tempFilterData.dateFilterData);
  }

  if (
    selectedTabCode == CHANNEL_TYPE_CODE.CHANNEL_ALL &&
    !conversationFinalFilters?._and?.length
  ) {
    conversationFinalFilters['_or'] =
      getChatConversationFilterForAll(additionFilterData);
  }

  if (selectedAssignedFilter === CONVERSATION_TAB_CODES.ALL) {
    delete conversationFinalFilters.assigneeId;
  } else if (selectedAssignedFilter === CONVERSATION_TAB_CODES.MINE) {
    conversationFinalFilters.assigneeId = {_eq: additionFilterData.id};
  } else if (selectedAssignedFilter === CONVERSATION_TAB_CODES.UN_ASSIGNED) {
    conversationFinalFilters.assigneeId = {_is_null: true};
  }

  if (filterState.selectedAssignedUserData?.id && filterState.selectedAssignedUserData?.id !== 0) {
    conversationFinalFilters.assigneeId = {
      _eq: filterState.selectedAssignedUserData?.id,
    };
  }
  if (filterState.selectedAssignedUserDataWeb?.length) {
    const selectedUserIds: number[] = []
    filterState.selectedAssignedUserDataWeb?.forEach((item)=> {
      if (item.id && item.id !== 0) {
        selectedUserIds.push(item.id)
      }
    })
    conversationFinalFilters.assigneeId = {
      _in: selectedUserIds,
    };
  }

  if (selectedTabCode == CHANNEL_TYPE_CODE.CALL_LOGS) {
    const callLogFilters: any = {};

    if (filterState.direction?.length) {
      callLogFilters.direction = filterState.direction;
    }
    if (filterState.isOutOfOfficeHours?.length) {
      callLogFilters.isOutOfOfficeHours = filterState.isOutOfOfficeHours;
    }
    if (filterState.status?.length) {
      callLogFilters.status = filterState.status;
    }
    if (filterState.searchQuery?.length) {
      callLogFilters.searchQuery = filterState.searchQuery;
    }
    if (filterState.webPhoneToUserId?.length) {
      callLogFilters.webPhoneToUserId = filterState.webPhoneToUserId;
    }
    if (filterState.toNumber?.length) {
      callLogFilters.toNumber = filterState.toNumber;
    }
    conversationFinalFilters.callLogFilters = callLogFilters;
  }

  // if ((selectedTabCode == CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS) || (selectedTabCode == CHANNEL_TYPE_CODE.CHANNEL_EMAIL)) {
  //   if (filterState?.selectedAssignedStatusFilter?.code) {
  //     if (filterState.selectedAssignedStatusFilter?.code !== 'ALL') {
  //       conversationFinalFilters.status = { _eq: filterState.selectedAssignedStatusFilter.value }
  //     }else if(filterState.selectedAssignedStatusFilter?.code === 'ALL'){
  //       //delete conversationFinalFilters.status;
  //       conversationFinalFilters.status = 'All'
  //     }else{
  //       conversationFinalFilters.status = { _neq: 1}// 1= assign status archived
  //     }
  //   }
  // }

  if (isWeb()) {
    if (showOnlyMentioned) {
      conversationFinalFilters.conversationMentions = {
        userType: {
          code: {
            _eq: 'USER',
          },
        },
        userId: {
          _eq: additionFilterData.userUuid,
        },
      };
    } else {
      delete conversationFinalFilters.conversationMentions;
    }
  }
  if (selectedTabCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
    if (filterState.mentionReadStatus === MENTION_READ_STATUS.ALL) {
      delete conversationFinalFilters.isRead;
    } else {
      conversationFinalFilters.isRead = {
        _eq: filterState.mentionReadStatus === MENTION_READ_STATUS.READ,
      };
    }
  }

  return conversationFinalFilters;
};

export const getConversationFilterDataV2 = (
  data: IGetConversationFilterDataV2
) => {
  const {
    filterStateData,
    selectedTabCode,
    additionFilterData,
    isEFaxMessagingEnabled,
    isPatientMessagingEnabled,
    emailInboxUserPreference,
    otherContactTypeId,
    isEmailUserPreferenceEnabled,
  } = data;
  const tempFilterData = {
    inboxFilterData: {} as IInboxFilterQuery,
    contactTypeFilterData: {} as IConversationContactTypeQuery,
    dateFilterData: [] as IDateFilterQuery[],
  };
  let conversationFinalFilters: any = {
    _and: [],
  };

  const tempUserFilterData = getConversationChannelTabFilter(
    selectedTabCode || CHANNEL_TYPE_CODE.CHANNEL_ALL,
    additionFilterData,
    isEFaxMessagingEnabled,
    isPatientMessagingEnabled,
    emailInboxUserPreference,
    otherContactTypeId,
    isEmailUserPreferenceEnabled
  ) as any;
  if (tempUserFilterData) {
    conversationFinalFilters = {
      ...conversationFinalFilters,
      ...tempUserFilterData,
    };
  }

  if (filterStateData?.toDate && filterStateData?.fromDate) {
    tempFilterData.dateFilterData = getDateFilterObjByToFrom(
      selectedTabCode,
      filterStateData.toDate,
      filterStateData.fromDate
    );
    conversationFinalFilters._and.push(...tempFilterData.dateFilterData);
  } else if (
    filterStateData.selectedDateCode &&
    filterStateData.selectedDateCode !== 'ALL'
  ) {
    tempFilterData.dateFilterData = getDateFilterObj(
      selectedTabCode,
      filterStateData.selectedDateCode
    );
    conversationFinalFilters._and.push(...tempFilterData.dateFilterData);
  }

  if (selectedTabCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
    if (filterStateData.mentionReadStatus === MENTION_READ_STATUS.ALL) {
      delete conversationFinalFilters.isRead;
    } else {
      conversationFinalFilters.isRead = {
        _eq: filterStateData.mentionReadStatus === MENTION_READ_STATUS.READ,
      };
    }
  }
  return conversationFinalFilters;
};

export const getInboxFilterObj = (inboxId: number) => {
  return {
    inboxId: {
      _eq: inboxId,
    },
  };
};

export const getDateFilterObjByToFrom = (
  selectedTabCode: string,
  toDate: any,
  fromDate: any,
) => {
  let dateFilter: IDateFilterQuery[] = [] as any;
  if (selectedTabCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
    dateFilter = [
      {
        message: {
          createdAt: {
            _lte: moment(toDate).endOf('day').toISOString(),
          },
        },
      },
      {
        message: {
          createdAt: {
            _gte: moment(fromDate).startOf('day').toISOString(),
          },
        },
      },
    ];
  } else {
    dateFilter = [
      {
        lastActivityAt: {
          _lte: moment(toDate).endOf('day').toISOString(),
        },
      },
      {
        lastActivityAt: {
          _gte: moment(fromDate).startOf('day').toISOString(),
        },
      },
    ];
  }
  return dateFilter;
}

export const getDateFilterObj = (
  selectedTabCode: string,
  dateFilterCode: string,
  dataFilterObj?: IFilterDataObj
): IDateFilterQuery[] => {
  let filterDataObj: IFilterDataObj = {
    fromDate: '',
    toDate: '',
  };
  let dateFilter: IDateFilterQuery[] = [] as any;
  if (dataFilterObj?.fromDate && dataFilterObj?.toDate) {
    filterDataObj = {...dataFilterObj};
  } else if (dateFilterCode) {
    filterDataObj = getDateObjFromCode(dateFilterCode, true) || ({} as any);
  }

  if (filterDataObj?.fromDate && filterDataObj?.toDate) {
    if (selectedTabCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      dateFilter = [
        {
          message: {
            createdAt: {
              _lte: filterDataObj.toDate,
            },
          },
        },
        {
          message: {
            createdAt: {
              _gte: filterDataObj.fromDate,
            },
          },
        },
      ];
    } else {
      dateFilter = [
        {
          lastActivityAt: {
            _lte: filterDataObj.toDate,
          },
        },
        {
          lastActivityAt: {
            _gte: filterDataObj.fromDate,
          },
        },
      ];
    }
  }
  return dateFilter;
};

export const getChannelTypeFilter = (channelTypeCode: string, userData: ILoginUserData, emailInboxUserPreference?: string, otherContactTypeId?: string, isEmailUserPreferenceEnabled?: boolean) => {
  switch (channelTypeCode) {
    case CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET:
      return {
        conversationInbox: {
          channelType: {
            _eq: 'Channel::WebWidget',
          },
          inboxMembers: {
            userId: {
              _eq: userData.id
            }
          }
        },
      };
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS:
      return {
        conversationInbox: {
          channelType: {
            _eq: 'Channel::TwilioSms',
          },
          inboxMembers: {
            userId: {
              _eq: userData.id
            }
          }
        },
        status: {_neq:  assignStatusValue.ARCHIVED}
      };
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_EMAIL:
      const filterQueryObject: any = {
        conversationInbox: {
          channelType: {
            _eq: 'Channel::Email',
          },
          inboxMembers: {
            userId: {
              _eq: userData.id
            }
          }
        },
        ...((isEmailUserPreferenceEnabled && emailInboxUserPreference === EMAIL_INBOX_USER_CODE.OTHER && otherContactTypeId) ? {
          conversationContact: {
            contactType: {
              contactType: {
                id: {
                  _in: [otherContactTypeId]
                }
              }
            }
          }
        } : {}),
        ...((isEmailUserPreferenceEnabled && emailInboxUserPreference === EMAIL_INBOX_USER_CODE.PATIENT_AND_LEAD && otherContactTypeId) ? {
          conversationContact: {
            contactType: {
              contactType: {
                id: {
                  _nin: [otherContactTypeId]
                }
              }
            }
          }
        } : {}),
        status: {_neq:  assignStatusValue.ARCHIVED}
      };
      return filterQueryObject;
    case CHANNEL_TYPE_CODE.CHANNEL_WHATSAPP:
      return {
        conversationInbox: {
          channelType: {
            _eq: 'Channel::Whatsapp',
          },
          inboxMembers: {
            userId: {
              _eq: userData.id
            }
          }
        },
        status: {_neq:  assignStatusValue.ARCHIVED}
      };
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_E_FAX:
      return {
        conversationInbox: {
          channelType: {
            _eq: 'Channel::EFax',
          },
          inboxMembers: {
            userId: {
              _eq: userData.id
            }
          }
        },
        status: {_neq:  assignStatusValue.ARCHIVED}
      }
    case CHANNEL_TYPE_CODE.ALL_CALLS:
      return {
        conversationInbox: {
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
          },
          inboxMembers: {
            userId: {
              _eq: userData.id
            }
          }
        },
        status: {_neq: assignStatusValue.ARCHIVED},
      };
  }
};

export const getContactTypeFilter = (
  selectedContactType: string,
  selectedTabCode: string,
  additionFilterData: IAdditionFilterData
): any => {
  let currentFilterObj = {} as any;
  if (selectedContactType === CONTACT_TYPE_CODES.PATIENT) {
    if (
      selectedTabCode === CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET ||
      selectedTabCode === CHANNEL_TYPE_CODE.CHANNEL_ALL
    ) {
      currentFilterObj._or = [
        {
          conversationContact: {
            contactType: {
              contactType: {
                code: {
                  _eq: 'CUSTOMER',
                },
              },
            },
          },
        },
        {
          groupConversation: {
            groupMembers: {
              groupUserId: {
                _eq: additionFilterData?.userUuid,
              },
            },
          },
        },
      ];
    } else {
      currentFilterObj._and = [
        {
          conversationContact: {
            contactType: {
              contactType: {
                code: {
                  _eq: 'CUSTOMER',
                },
              },
            },
          },
        },
      ];
    }
  } else if (selectedContactType === CONTACT_TYPE_CODES.PROSPECT) {
    currentFilterObj = {
      conversationContact: {
        contactType: {
          contactType: {
            code: {
              _neq: 'CUSTOMER',
            },
          },
        },
      },
    };
  }

  return currentFilterObj;
};

export const getChatConversationFilterForAll = (
  additionFilterData: IAdditionFilterData
) => {
  const {userUuid, groupTypeCodeIdObj, id} = additionFilterData;
  return [
    {
      groupConversation: {
        groupMembers: {
          groupUserId: {
            _eq: userUuid,
          },
        },
        _or: [
          {
            groupTypeId: {
              _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
            },
          },
          {
            groupTypeId: {
              _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PATIENT_BROADCAST],
            },
          },
        ],
      },
    },
    {
      conversationInbox: {
        inboxMembers: {
          userId: {
            _eq: id,
          },
        },
      },
    },
  ];
};

export const getChatConversationTabFilter = (
  selectedInboxTypeCode: string,
  additionFilterData: IAdditionFilterData,
  selectedContactType?: string,
  selectedInboxID?: number
) => {
  const channelTypeObj: any = getChannelTypeFilter(selectedInboxTypeCode, additionFilterData as any) || {};
  const {userUuid, groupTypeCodeIdObj} = additionFilterData;
  let inboxFilter: any = {
    inboxId: {
      _neq: -1,
    },
  };
  if (selectedInboxID) {
    inboxFilter = {
      inboxId: {
        _eq: selectedInboxID,
      },
    };
  }
  if (selectedContactType !== CONTACT_TYPE_CODES.PROSPECT) {
    return {
      _or: [
        {
          ...inboxFilter,
          ...channelTypeObj,
        },
        {
          groupConversation: {
            groupMembers: {
              groupUserId: {
                _eq: userUuid,
              },
            },
            _or: [
              {
                groupTypeId: {
                  _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
                },
              },
              {
                groupTypeId: {
                  _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PATIENT_BROADCAST],
                },
              },
            ],
          },
        },
      ],
    };
  } else {
    return {
      _or: [
        {
          ...inboxFilter,
          ...channelTypeObj,
        }]
    }
  }
};

export const getLocationDataFromLocations = (
  userPracticeLocationsResp: IUserPracticeLocationResp | any
) => {
  if (userPracticeLocationsResp?.userPracticeLocations?.length) {
    const locationData = userPracticeLocationsResp.userPracticeLocations
      .map((userPracticeLocation: any) => {
        const locationUuid =
          userPracticeLocation?.accountLocations?.[0]?.practiceLocation?.uuid;
        const locationName =
          userPracticeLocation?.accountLocations?.[0]?.practiceLocation?.name;
        return {
          uuid: locationUuid,
          name: locationName,
        };
      })
      .filter((locationUuid: any) => locationUuid !== undefined);
    return locationData;
  }
  return [];
};

export const getInboxNameByInboxType = (inbox: any) => {
  switch (inbox?.channelType) {
    case CHANNEL_TYPE.CHANNEL_TWILIO_SMS:
      return `${inbox?.channelTwilioSms?.phoneNumber || ''} (${inbox?.name})`;
    case CHANNEL_TYPE.CHANNEL_EMAIL:
      return ` ${inbox?.emailAddress || inbox?.channelEmail?.email || ''} (${
        inbox?.name
      })`;
    case CHANNEL_TYPE.CHANNEL_EFAX:
      return `${inbox?.channelEfax?.efaxNumber || ''} (${inbox?.name})`;
    default:
      return '';
  }
};

export const getInitialRefData = (): IConversationFilterRef => {
  return {
    date: {
      toDate: '',
      fromDate: '',
    }, // from, to
    assignedUsersUuid: [], // [ids]
    mentionStatus: '', // all, read, unread
    locationUuids: [],
    inboxId: undefined,
    selectedDateCode: '',
    isLocationSelectDisable: false,
  };
};


export const formatUserLocationData = (
  allowedUserPracticeLocationUuidsAndName: IAllowedUserPracticeLocationUuidsAndName[]
): IUserLocationData[] => {
  const formattedUserLocations = [] as IUserLocationData[];
  if (allowedUserPracticeLocationUuidsAndName?.length) {
    allowedUserPracticeLocationUuidsAndName?.forEach(
      (singlePracticeLocation: IAllowedUserPracticeLocationUuidsAndName) => {
        formattedUserLocations?.push({
          name: singlePracticeLocation?.practiceLocationName,
          uuid: singlePracticeLocation?.practiceLocationId,
        });
      }
    );
  }
  return formattedUserLocations;
};

export const formatLocationData = (allowedUserPracticeLocationUuidsAndName: IAllowedUserPracticeLocationUuidsAndName[]) => {
  return allowedUserPracticeLocationUuidsAndName?.map?.(singlePracticeLocation => {
    return {
      name: singlePracticeLocation?.practiceLocationName,
      uuid: singlePracticeLocation?.uuid,
    }
  }) || [];
}

export const filterLocationObject = (locationData: any) => {
  const newData = (locationData || []).map((location: any) => {
    return {
      ...location?.practiceLocation,
      uuid: location.uuid
    }
  });
  return newData;
};