import React from "react";
import { isWeb } from "../../utils/platformCheckUtils";

function NoInternetSVG() {
  if(!isWeb()){
    return null;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="244"
      height="249"
      fill="none"
      viewBox="0 0 244 249"
    >
      <g clipPath="url(#clip0_2397_260040)">
        <path
          fill="#E3E4EA"
          d="M171.688 59.971a3.855 3.855 0 11-5.929-4.928 3.855 3.855 0 015.929 4.928zm36.206-7.528a42.397 42.397 0 00-41.359-34.372 2.89 2.89 0 00-2.908 2.874 2.9 2.9 0 00.835 2.05 2.88 2.88 0 002.039.858 36.618 36.618 0 0135.715 29.681 2.895 2.895 0 002.257 2.286 2.89 2.89 0 003.421-3.377zm-11.46 1.668a30.835 30.835 0 00-29.442-24.468 2.89 2.89 0 00-.135 5.78 25.057 25.057 0 0123.92 19.878 2.89 2.89 0 003.414 2.185 2.894 2.894 0 002.243-3.375zm-11.491 1.621a19.28 19.28 0 00-17.442-14.496 2.893 2.893 0 10-.367 5.772 13.489 13.489 0 0112.202 10.141 2.89 2.89 0 003.513 2.095 2.885 2.885 0 001.774-1.322 2.885 2.885 0 00.32-2.19z"
        ></path>
      </g>
      <path
        fill="#E3E4EA"
        d="M124.918 38.503a1.58 1.58 0 01-1.114-.629 1.432 1.432 0 01-.284-1.206c.153-.804.428-1.485.826-2.045.398-.56.991-1.115 1.779-1.668.873-.602 1.546-1.134 2.021-1.597a2.715 2.715 0 00.827-1.677c.093-.79-.095-1.478-.564-2.06-.469-.583-1.177-.93-2.123-1.041-.617-.072-1.158-.004-1.623.206-.465.21-.855.492-1.169.845-.315.354-.678.575-1.089.664a1.569 1.569 0 01-1.14-.163 1.628 1.628 0 01-.799-1.061c-.112-.463-.029-.874.247-1.233.661-.802 1.498-1.437 2.511-1.905 1.013-.468 2.156-.627 3.429-.478 2.025.238 3.516.984 4.474 2.24.958 1.256 1.344 2.675 1.158 4.257-.113.964-.417 1.765-.912 2.401-.494.637-1.237 1.337-2.226 2.1-.728.54-1.25.987-1.565 1.342a3.424 3.424 0 00-.706 1.266 2.03 2.03 0 01-.747 1.115c-.364.27-.768.38-1.211.327zm-.843 6.94a2.246 2.246 0 01-1.556-.872 2.238 2.238 0 01-.487-1.713 2.242 2.242 0 01.872-1.556 2.239 2.239 0 011.713-.488 2.246 2.246 0 011.556.873c.4.506.563 1.078.488 1.713a2.25 2.25 0 01-.872 1.556c-.507.4-1.079.563-1.714.487z"
      ></path>
      <g filter="url(#filter0_d_2397_260040)">
        <g clipPath="url(#clip1_2397_260040)">
          <ellipse
            cx="117.819"
            cy="128.797"
            fill="url(#paint0_linear_2397_260040)"
            rx="75.738"
            ry="75.738"
          ></ellipse>
          <rect
            width="51.269"
            height="62.921"
            x="88.632"
            y="23.928"
            fill="url(#paint1_linear_2397_260040)"
            rx="25.634"
          ></rect>
          <rect
            width="32.314"
            height="43.043"
            x="60.831"
            y="139.284"
            fill="url(#paint2_linear_2397_260040)"
            rx="16.157"
            transform="rotate(44.937 60.831 139.284)"
          ></rect>
          <ellipse
            cx="163.205"
            cy="101.997"
            fill="url(#paint3_linear_2397_260040)"
            rx="10.487"
            ry="10.487"
          ></ellipse>
          <ellipse
            cx="132.909"
            cy="178.9"
            fill="url(#paint4_linear_2397_260040)"
            rx="10.487"
            ry="10.487"
          ></ellipse>
          <ellipse
            cx="85.136"
            cy="105.493"
            fill="url(#paint5_linear_2397_260040)"
            rx="5.826"
            ry="5.826"
          ></ellipse>
        </g>
      </g>
      <ellipse
        cx="24.603"
        cy="147.44"
        fill="url(#paint6_linear_2397_260040)"
        rx="5.826"
        ry="5.826"
      ></ellipse>
      <ellipse
        cx="220.357"
        cy="121.805"
        fill="url(#paint7_linear_2397_260040)"
        rx="3.496"
        ry="3.496"
      ></ellipse>
      <ellipse
        cx="95.68"
        cy="28.589"
        fill="url(#paint8_linear_2397_260040)"
        rx="2.33"
        ry="2.33"
      ></ellipse>
      <path
        stroke="#C5C9D2"
        strokeWidth="4.859"
        d="M183.322 91.158c45.807-20.318 49.245-15.36 52.332-9.384 4.475 8.659-25.873 33.082-104.119 73.515-78.247 40.432-119.195 53.728-123.669 45.07-3.088-5.976-1.673-14.32 41.4-39.929"
      ></path>
      <defs>
        <filter
          id="filter0_d_2397_260040"
          width="219.991"
          height="219.991"
          x="2.93"
          y="28.589"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-4.894" dy="9.788"></feOffset>
          <feGaussianBlur stdDeviation="17.128"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.596078 0 0 0 0 0.635294 0 0 0 0 0.701961 0 0 0 0.6 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2397_260040"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2397_260040"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_2397_260040"
          x1="42.081"
          x2="193.558"
          y1="53.058"
          y2="204.535"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.271" stopColor="#fff"></stop>
          <stop offset="0.74" stopColor="#EDF0F3"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_2397_260040"
          x1="114.266"
          x2="114.266"
          y1="23.928"
          y2="86.849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2F2F5"></stop>
          <stop offset="1" stopColor="#DDDFE4"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_2397_260040"
          x1="76.989"
          x2="76.989"
          y1="139.284"
          y2="182.327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4D5DC"></stop>
          <stop offset="1" stopColor="#E9EBEC"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_2397_260040"
          x1="163.205"
          x2="163.205"
          y1="91.51"
          y2="112.484"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5D7DB"></stop>
          <stop offset="1" stopColor="#E0E1E8"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_2397_260040"
          x1="132.909"
          x2="132.909"
          y1="168.414"
          y2="189.387"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5D7DB"></stop>
          <stop offset="1" stopColor="#E0E1E8"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_2397_260040"
          x1="85.136"
          x2="85.136"
          y1="99.666"
          y2="111.319"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5D7DB"></stop>
          <stop offset="1" stopColor="#E0E1E8"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_2397_260040"
          x1="24.603"
          x2="24.603"
          y1="141.614"
          y2="153.266"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5D7DB"></stop>
          <stop offset="1" stopColor="#E0E1E8"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_2397_260040"
          x1="220.357"
          x2="220.357"
          y1="118.31"
          y2="125.301"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5D7DB"></stop>
          <stop offset="1" stopColor="#E0E1E8"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_2397_260040"
          x1="95.68"
          x2="95.68"
          y1="26.259"
          y2="30.919"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5D7DB"></stop>
          <stop offset="1" stopColor="#E0E1E8"></stop>
        </linearGradient>
        <clipPath id="clip0_2397_260040">
          <path
            fill="#fff"
            d="M0 0H61.674V61.674H0z"
            transform="rotate(39.728 88.21 244.153)"
          ></path>
        </clipPath>
        <clipPath id="clip1_2397_260040">
          <rect
            width="151.477"
            height="151.477"
            x="42.081"
            y="53.058"
            fill="#fff"
            rx="75.738"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default NoInternetSVG;