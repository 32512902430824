import React from 'react';
import {Colors} from '../../../../styles/Colors';
import {ICommonSvgProps} from '../interfaces';

const PatientsSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';

  return (
    <svg
      style={{width: 'inherit', height: 'inherit'}}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.375 8.33333V1.875C19.375 1.54348 19.2433 1.22554 19.0089 0.991117C18.7745 0.756696 18.4565 0.625 18.125 0.625H1.875C1.54348 0.625 1.22554 0.756696 0.991117 0.991117C0.756696 1.22554 0.625 1.54348 0.625 1.875V13.125C0.625 13.4565 0.756696 13.7745 0.991117 14.0089C1.22554 14.2433 1.54348 14.375 1.875 14.375H6.45833"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.45836 6.86033C7.54728 6.86033 8.43003 5.97758 8.43003 4.88866C8.43003 3.79974 7.54728 2.91699 6.45836 2.91699C5.36944 2.91699 4.48669 3.79974 4.48669 4.88866C4.48669 5.97758 5.36944 6.86033 6.45836 6.86033Z"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.59664 10.8033H3.07831C3.07821 10.1451 3.27034 9.50107 3.63112 8.95045C3.99189 8.39983 4.5056 7.96654 5.10917 7.70379C5.71274 7.44103 6.3799 7.36024 7.02875 7.47134C7.67759 7.58244 8.27987 7.88059 8.76164 8.32918"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.875 3.75H15.625"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.875 6.25H16.875"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.375 19.375C17.1364 19.375 19.375 17.1364 19.375 14.375C19.375 11.6136 17.1364 9.375 14.375 9.375C11.6136 9.375 9.375 11.6136 9.375 14.375C9.375 17.1364 11.6136 19.375 14.375 19.375Z"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.6033 12.9209L14.1825 16.1492C14.1331 16.215 14.0712 16.2703 14.0004 16.3122C13.9296 16.354 13.8513 16.3814 13.7699 16.393C13.6885 16.4045 13.6056 16.3999 13.526 16.3794C13.4463 16.3588 13.3715 16.3228 13.3058 16.2734C13.2825 16.2558 13.2605 16.2366 13.24 16.2159L11.99 14.9659"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default React.memo(PatientsSvg);
