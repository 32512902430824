import React from "react";

const IncreaseArrow = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#027A48'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke={svgColors}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6 9.5v-7m0 0L2.5 6M6 2.5L9.5 6"
      ></path>
    </svg>
  );
}

export default React.memo(IncreaseArrow);
