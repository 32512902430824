import React from 'react';
import {FlatList} from 'native-base';
import {View} from 'react-native';
import {IFormResponse} from './interfaces';
import {
  converGraphDataToVitalGraphData,
  getMeasure,
} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils';
import VitalGraphCard from '../HomeMonitoringView/components/VitalGraphCard';
import {GraphsTypes, IHighLightValues} from '../HomeMonitoringView/interface';
import EmptyStateSvg from '../../../../../common/Svg/EmptyStateSvg.native';
import { GRAPH_TYPES } from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/Activities/Activities';

interface ClinicalAssessmentDrillDownViewProps {
  forms: IFormResponse[];
}

export const ClinicalAssessmentDrillDownView = (
  props: ClinicalAssessmentDrillDownViewProps
) => {
  const {forms} = props;

  const renderItem = ({item}: {item: any}) => {
    const measure = getMeasure(item?.loinc, []);

    let graphData = item;

    const highLightValues: IHighLightValues[] = [
      {
        value: `${graphData.recentFormScore ? graphData.recentFormScore : '-'}`,
        subValue: 'Latest Score',
      },
    ];

    graphData = converGraphDataToVitalGraphData(
      item,
      GraphsTypes.LINE,
      highLightValues
    );

    graphData.code = GRAPH_TYPES.Form;
    return (
      <VitalGraphCard
        key={item.title}
        vital={graphData}
        ccmDate={undefined}
        measure={measure}
        lastRecorded={{value: '', date: ''}}
      />
    );
  };

  return (
    <View>
      <FlatList
        data={forms}
        keyExtractor={(item: IFormResponse) => item.id}
        ListEmptyComponent={<EmptyStateSvg titleId="noData" />}
        renderItem={renderItem}
      />
    </View>
  );
};
