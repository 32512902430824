import { Input, Modal } from 'antd';
import moment from 'moment';
import { Text, VStack } from 'native-base';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAccountUUID } from '../../../../../utils/commonUtils';
import {
  createCampaignSegment, createOrUpdateCampaign
} from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import './style.css';

export default function CreateCampaignModal({
  visible,
  handleCancel,
  selectedItem,
}: {
  visible: boolean;
  handleCancel: any;
  selectedItem: any;
}) {
  const tenantId = getAccountUUID();
  const [input, setInput] = useState('');
  const [error, setError] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const navigate = useNavigate();
  const handleOk = async () => {
    const {contactIds} = selectedItem;
    const startDate = moment(new Date()).format().toString();
    createCampaignSegment({
      name: input,
      tenantId,
      metadata: {patientFilter: {contactIds}},
    }).then((resp: any) => {
      if (resp?.id) {
        createOrUpdateCampaign({
          name: input,
          startDate,
          description: '',
          isImmediate: false,
          numberOfRetry: 1,
          tenantId,
          segmentId: resp?.id,
          retryDelayInDays: 1,
        })
          .then((res) => {
            setConfirmLoading(false);
            handleCancel();
            navigate('/campaignManagement/active')
          })
          .catch((err) => {
            setConfirmLoading(false);
            // TODO: create exception
            handleCancel();
          });
      }
    });
  };

  return (
    <Modal
      title="Create Campaign"
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      okText="Create"
      confirmLoading={confirmLoading}
      className={'modal_container_camp'}
    >
      <VStack space={1}>
        <Text fontSize="xl">Create Campaign</Text>
        <Text fontSize="md">
          Create Campaign group for {selectedItem.members} members
        </Text>
        <Text>{error && 'Error to create a new opportunity'}</Text>
        <div style={{marginTop: '40px'}}>
          <label
            style={{
              fontWeight: 500,
              fontSize: '14px',
            }}
          >
            Campaign Group Name
          </label>
          <Input
            style={{
              borderRadius: '8px',
              padding: '10px 16px 10px 16px',
            }}
            placeholder="My New Campaign"
            size="large"
            onChange={(e) => setInput(e.target.value)}
          />
        </div>
      </VStack>
    </Modal>
  );
}
