import * as React from 'react';
import {IConversationData} from '../components/RightSideContainer/TeamInbox/Conversations/interfaces';
export interface ISelectedConversationContextData {
  selectedConversation: IConversationData;
}
export const defaultSelectedConversation: ISelectedConversationContextData = {
  selectedConversation: {} as IConversationData,
};

export const SelectedConversationContext = React.createContext(
  defaultSelectedConversation
);
