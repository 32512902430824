import {View} from 'native-base';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {IMlov} from '../../../Interfaces';
import {Colors} from '../../../styles/Colors';
import {commonBodyContentContainer} from '../../../styles/CommonBodyContentStyle';
import {getUserEmployerId, isEmployerRole} from '../../../utils/commonUtils';
import {getMlovListByCategory} from '../../../utils/mlovUtils';
import TitleSubtitleView from '../../common/TitleSubtitleView/TitleSubtitleView';
import {styles} from './EmployerManagementStyles';
import {EmployerTable} from './EmployerTable';

export interface EmployerManagementProps {
  showTitleSubtitle?: boolean;
  selectedActionCode: string;
  selectedRawData: any;
  onActionPerformed: (tabCode: any, rawData?: any, extraData?: any) => void;
  updateEmployersCount?: (count: number)=> void;
}

const EmployerManagement = (props: EmployerManagementProps) => {
  const {
    selectedActionCode,
    selectedRawData,
    showTitleSubtitle,
    onActionPerformed,
    updateEmployersCount,
  } = props;
  const [employerTableHeaderTabs, setEmployerTableHeaderTabs] = useState<any[]>(
    []
  );
  const dealStatusList = getMlovListByCategory('DealStatus');
  const isEmployer = isEmployerRole();
  const employerId = getUserEmployerId();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isEmployer && employerId) {
      const path = `/employers/${employerId}/${
        isEmployer ? 'members' : 'dashboard'
      }`;
      navigate(path);
    } else {
      if (dealStatusList) {
        const formattedHeaders = dealStatusList?.map((deal: IMlov) => {
          return {
            title: deal?.value,
            isActive: false,
          };
        });
        setEmployerTableHeaderTabs([
          {title: 'All', isActive: true},
          ...formattedHeaders,
        ]);
      }
    }
  }, []);

  return (
    <View>
      {showTitleSubtitle && (
        <TitleSubtitleView titleLabelId="employers" subtitleLabelId="" />
      )}
      <View
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems="center"
      ></View>

      <View
        style={[
          styles.tabsWrapper,
          commonBodyContentContainer.pageContentContainer,
          {
            backgroundColor: '#fff',
            overflow: 'hidden',
          },
        ]}
      >
        <EmployerTable
          searchString={selectedRawData}
          employerTableHeaderTabs={employerTableHeaderTabs}
          selectedActionCode={selectedActionCode}
          onActionPerformed={onActionPerformed}
          updateEmployersCount={updateEmployersCount}
        />
      </View>
    </View>
  );
};

export default EmployerManagement;
