import {Table} from 'antd';
import {Text, View} from 'native-base';
import React from 'react';
import {Dimensions} from 'react-native';
import {AttachmentDetailsProps} from '../interfaces';
import {styles} from './AttachmentDetailsStyles';
const {height} = Dimensions.get('window');
const finalHeight = height - 330;
const AttachmentDetails = (props: AttachmentDetailsProps) => {
  const {details} = props;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <Text size={'lgBold'} ml={2} style={{marginLeft: 12}}>
          Attachment Uploaded Details
        </Text>
        <Table
          scroll={{x: 700, y: finalHeight}}
          columns={columns}
          rowKey={(row) => row.id}
          dataSource={details}
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
        />
      </View>
    </View>
  );
};

export default AttachmentDetails;
