import React, {useEffect, useRef, useState} from 'react';
import {Box} from 'native-base';
import CalendarHub from './CalendarHub/CalendarHub';
import {StyleSheet, useWindowDimensions} from 'react-native';
import {Colors} from '../../../../../styles/Colors';

const ScheduleManager = ({
  isScheduleManagerVisible,
  setIsScheduleManagerVisible,
}: any) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const {height} = useWindowDimensions();
  const containerHeight = height - 86;

  return (
    <div
      style={{
        width: isScheduleManagerVisible ? '22%' : '0%',
        marginLeft: '6px',
      }}
      className={`day-op animate-scheduler`}
    >
      <Box
        ref={containerRef}
        overflow={'hidden'}
        borderRadius={12}
        borderWidth={isScheduleManagerVisible ? 0.5 : 0}
        background={'white'}
        width={'100%'}
        borderColor={Colors.FoldPixel.GRAY150}
        height={containerHeight}
      >
        <CalendarHub
          onHideCalendarView={() => {
            setIsScheduleManagerVisible(!setIsScheduleManagerVisible);
          }}
        />
      </Box>
    </div>
  );
};

export default ScheduleManager;
