import { Button, HStack, Spacer, VStack, View } from 'native-base';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlowType } from '../../../context/WorkflowContext';
import { Colors } from '../../../styles';
import { commonBodyContentContainer } from '../../../styles/CommonBodyContentStyle';
import CustomTabsView from '../../common/CustomTabsView/CustomTabsView';
import { ITabsList } from '../../common/CustomTabsView/interfaces';
import {
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES
} from '../../common/TableTopBar';
import TitleSubtitleView from '../../common/TitleSubtitleView/TitleSubtitleView';
import { AppointmentTypes } from '../AccountSettings';
import { PracticeAvailability } from '../AccountSettings/PracticeAvailabilityNew/PracticeAvailability';
import AutomationTopBar from '../Contacts/PatientsAdminPage/AutomationTopBar';
import { styles } from '../TeamInbox/styles';
import UserScheduleConfigurationView from '../UserScheduleSetting/UserScheduleConfigurationView';
import WorkflowView from '../Workflow/Workflow/WorkflowView';
import HolidaysList from '../Holidays/HolidaysList';
import CampaignSegmentTable from '../ContentManagement/CampaignManagement/CampaignSegmentTable';
import { Dimensions } from 'react-native';
import { CampaignSegmentTableContext } from '../ContentManagement/CampaignManagement/interfaces';
import PlusIcon from '../../common/Svg/PlusSvg';
import { ModalActionAntSelect } from '../../common/ModalActionCommonComponent/ModalActionAntSelect';
import { FoldButton } from '../../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE } from '../../../constants';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import UserPracticeLocationQueries from '../../../services/Location/UserPracticeLocationQueries';
import { USER_ROLE_CODES } from '../../../constants/MlovConst';
import { IAccountLocation } from '../Contacts/TeamMembers/interfaces';
import { ITimezone } from '../../../services/Location/interfaces';
import { IUser } from '../../../Interfaces';
import { IAvailabilityCalendarData, IComponentStateState } from '../AccountSettings/PracticeAvailabilityNew/PracticeAvailabilityInterfaces';
import { createAvailabilityCalendarGroupData } from '../AccountSettings/PracticeAvailabilityNew/PracticeAvailabilityHelper';
import { getAccountUUID, getBooleanFeatureFlag, isAllowedAllLocationToUser } from '../../../utils/commonUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import FeatureFlags from '../../../constants/FeatureFlags.enums';

const SchedulingConfig = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const accountUUID = getAccountUUID();
  const screenDimensions = Dimensions.get('window');
  const context = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(context?.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const isAllowedAllLocation = isAllowedAllLocationToUser(context?.currentUserRoles || []);
  const canHideAddNewForAvailability = isMultiTenancyEnabled && !isAllowedAllLocation;
  const [searchString, setSearchString] = useState('');
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const timeOutRefs = useRef<NodeJS.Timeout[]>([])
  const onTabClick = (tab: ITabsList) => {
    if (selectedTab?.key !== tab.key) {
      setComponentState(prev => ({
        ...prev,
        selectedRawData: ''
      }))
      setSearchString('');
    }
    const timeOutId = setTimeout(() => {
      setSelectedTab(tab);
    }, 10);
    timeOutRefs.current.push((timeOutId))
  };
  const [componentState,setComponentState] = useState<{
    selectedActionCode: string,
    selectedRawData: any,
    buttonList: any[],
    selectedUserId: any,
  }>({
    selectedActionCode: '',
    selectedRawData: {} as any,
    buttonList: [],
    selectedUserId: undefined,
  })

  const [ componentAvailibilityState, setComponentAvailibilityState ] = useState<IComponentStateState>({
    searchUserText: '',
    selectedAccountLocationId: '',
  });
  useEffect(() => {
    return () => {
      timeOutRefs.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    }
  }, []);

  const getTimezoneOfAccountAndLocationsQuery = useQuery(
    UserPracticeLocationQueries.GetTimezoneOfAccountAndLocations, {
      variables: { tenantId: accountUUID,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
      fetchPolicy: 'no-cache',
      onCompleted: (res) => {
        let accountLocations: IAccountLocation[] = [];
        let timezones: ITimezone[] = [];
        let accountUsers: IUser[] = [];

        accountLocations = res?.accountLocations?.length ? res.accountLocations : [];
        timezones = res?.timezones?.length ? res.timezones : [];
        accountUsers = res?.users?.length ? res.users : [];

        accountUsers = accountUsers?.filter((item) => {
          let roles = '';
          item.userRoles?.forEach((element: any) => {
            roles += element?.userRole?.userRole?.code;
          });
          const users = [] as any[];
          if (users.length && !users.includes(item.uuid)) {
            return false;
          }
          if (
            !roles.includes('WORKFLOW') && !roles.includes('CUSTOMER_SUCCESS')  &&
            item?.accountUsers?.[0]?.isActive
          ) {
            return item;
          }
        });

        setComponentAvailibilityState((prev) => {
          const availabilityCalendarData: IAvailabilityCalendarData[] = createAvailabilityCalendarGroupData({
            practiceAvailabilities: prev.practiceAvailabilities,
            timezones,
            accountLocations,
            accountUsers,
            isUserSchedule: true,
          });
          return {
            ...prev,
            availabilityCalendarData,
            accountLocations,
            accountUsers,
            timezones,
          };
        });
      },
    }
  );

  const tagsTabList = (): ITabsList[] => {
    const array: ITabsList[] = [
      // {
      //   key: 'automation',
      //   path: 'automation',
      //   title: 'Automations',
      //   tabContainerElem: () => {
      //     return (
      //       <View
      //         bgColor={'white'}
      //         borderWidth={1}
      //         borderColor={Colors.Custom.Gray200}
      //         borderRadius={8}
      //         ml={4}
      //         mr={4}
      //       >
      //         <WorkflowView
      //           showTableTopBar={false}
      //           flowType={FlowType.scheduling}
      //           searchText={searchString}
      //         />
      //       </View>
      //     );
      //   },
      // },
      {
        key: 'availability',
        title: 'Practice Availability',
        path: 'availability',
        tabContainerElem: () => {
          return (
            <View
              key={'availability'}
              borderColor={Colors.Custom.Gray200}
              mt={4}
              style={{paddingHorizontal: 14}}
            >
              <PracticeAvailability
                showTableTopBar={false}
                isInboxHours={false}
                isUserSchedule={false}
                searchString={searchString}
                selectedActionCode={componentState.selectedActionCode}
                onClose={onTableTopBarActionPerformed}
              />
            </View>
          );
        },
      },
      {
        key: 'user_availability',
        title: 'User Availability',
        path: 'userAvailability',
        tabContainerElem: () => {
          return (
            <View
              key={'user_availability'}
              borderColor={Colors.Custom.Gray200}
              mt={4}
              style={{paddingHorizontal: 14}}
            >
              <PracticeAvailability
                showTableTopBar={false}
                searchString={searchString}
                isInboxHours={false}
                isUserSchedule={true}
                selectedActionCode={componentState.selectedActionCode}
                selectedRawData={componentState.selectedRawData}
                onClose={onTableTopBarActionPerformed}
              />
            </View>
          );
        },
      },
      {
        key: 'types',
        title: 'Appointment Types',
        path: 'types',
        tabContainerElem: () => {
          return (
            <AppointmentTypes
              searchString={searchString}
              selectedActionCode={componentState.selectedActionCode}
              selectedRawData={componentState.selectedRawData}
              onClose={onTableTopBarActionPerformed}
              showTableTopBar={false}
            />
          );
        },
      },
      {
        key: 'schedule_suggestor_rules',
        title: 'Suggestions',
        path: 'suggestionRules',
        tabContainerElem: () => {
          return (
            <CampaignSegmentTable
              context={CampaignSegmentTableContext.SCHEDULE_SUGGESTION_RULES}
              selectedActionCode={componentState.selectedActionCode}
              selectedRawData={componentState.selectedRawData}
              onActionPerformed={onTableTopBarActionPerformed}
            />
          );
        },
      },
      {
        key: 'user_schedule',
        title: 'Manage Calendar',
        path: 'scheduleAccess',
        tabContainerElem: () => {
          return (
            <UserScheduleConfigurationView
              showTableTopBar={false}
              searchString={searchString}
            />
          );
        },
      },
      {
        key: 'holidays',
        title: 'Holiday Configuration',
        path: 'holidays',
        tabContainerElem: () => {
          return (
            <HolidaysList
              searchString={searchString}
              selectedActionCode={componentState.selectedActionCode}
              onClose={onTableTopBarActionPerformed}
              showTableTopBar={false}
            />
          );
        },
      },

    ];
    return array;
  };

  const handleSearchChange = (actionData: any) => {
    setSearchString(actionData.searchString);
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        if(selectedTab.key === 'schedule_suggestor_rules'){
          setComponentState(prev => ({
            ...prev,
            selectedActionCode: actionCode,
            selectedRawData: actionData
          }))
          return;
        }
        handleSearchChange(actionData);
        break;
        case TABLE_TOP_BAR_ACTION_CODES.BUTTON_LIST:
        setComponentState((prev) => {
          return {
            ...prev,
            buttonList: actionData,
          };
        });
        break;
      case TABLE_TOP_BAR_ACTION_CODES.CLOSE:
        const timeOutId = setTimeout(() => {
          setComponentState((prev) => {
            return {
              ...prev,
              selectedActionCode: TABLE_TOP_BAR_ACTION_CODES.CLOSE,
              selectedUserId: '',
            };
          });
        }, 10);
        timeOutRefs.current.push((timeOutId))
        break;
    }
  };

  const workflowUrl = `create?currentTag=TRIGGER&flowType=${
    FlowType.scheduling || ''
  }`;

  const getUserListView = () => {
    return (
      <VStack space={8}>
        <ModalActionAntSelect
          disabled={false}
          data={componentAvailibilityState.accountUsers}
          loading={false}
          showSearch={true}
          allowClear={false}
          value={componentState.selectedUserId || undefined}
          onChange={(value: string) => {
            setComponentState((prev) => ({
              ...prev,
              selectedUserId: value,
              selectedActionCode: TABLE_TOP_BAR_ACTION_CODES.ADD_NEW_USER_SCHEDULE,
              selectedRawData: value,
            }));
          }}
          filterOption={(input: string, option: any) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          optionProps={{
            key: 'uuid',
            label: 'name',
            value: 'uuid',
          }}
          label={'addUserSchedule'}
          labelInValue={false}
          placeholder="Select User"
        />
        <HStack>
          <Spacer />
          <Button.Group space={2}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    selectedActionCode: TABLE_TOP_BAR_ACTION_CODES.ADD_NEW_USER_SCHEDULE,
                    selectedRawData: componentState.selectedUserId,
                  }));

                  const timeOutId = setTimeout(() => {
                    setComponentState((prev) => {
                      return {
                        ...prev,
                        selectedUserId: undefined,
                      };
                    });
                  }, 2000);
                  timeOutRefs.current.push((timeOutId))
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'continue',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </HStack>
      </VStack>
    )
  }

  const UserScheduleTopBar: JSX.Element = (
    <View width={'93.5%'}>
      <TableTopBar
        selectedTab={selectedTab?.key}
        isDividerNotVisible={true}
        title=""
        alignSearchBarFlexEnd={true}
        searchBarWidth={180}
        searchBarMinWidth={180}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
      />
    </View>
  );

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele: any = false;
    if (selectedTab.key === 'automation') {
      ele = <AutomationTopBar workflowUrl={workflowUrl} onTableTopBarActionPerformed={onTableTopBarActionPerformed}></AutomationTopBar>;
    } else if (selectedTab.key === 'user_schedule') {
      ele = UserScheduleTopBar;
    } else if (selectedTab.key === 'schedule_suggestor_rules') {
      ele = (
        <View width={'93.5%'}>
        <TableTopBar
          title={''}
          selectedTab={selectedTab?.key}
          alignSearchBarFlexEnd={true}
          isDividerNotVisible={true}
          searchBarWidth={180}
          searchBarMinWidth={180}
          onActionPerformed={(actionCode: string, actionData: any) => {
            onTableTopBarActionPerformed(actionCode, actionData);
          }}
          buttonList={componentState.buttonList}
        />
        </View>
      );
    } else if (selectedTab.key === 'availability') {
      ele = (
        <View width={'93.5%'}>
        <TableTopBar
          title={''}
          selectedTab={selectedTab?.key}
          alignSearchBarFlexEnd={true}
          isDividerNotVisible={true}
          searchBarWidth={180}
          searchBarMinWidth={180}
          onActionPerformed={(actionCode: string, actionData: any) => {
            onTableTopBarActionPerformed(actionCode, actionData);
          }}
          buttonList={!canHideAddNewForAvailability ?[
            {
              btnText: 'new',
              backgroundColor: Colors.secondary['100'],
              borderColor: Colors.secondary['200'],
              textColor: '',
              variant: 'PRIMARY',
              size: 'sm',
              leftIcon: <PlusIcon />,
              btnClick: () => {
                setComponentState((prev) => ({
                  ...prev,
                  selectedActionCode: TABLE_TOP_BAR_ACTION_CODES.ADD_NEW_PRACTICE,
                }));
              },
            },
          ] : []}
        />
        </View>
      );
    } else if (selectedTab.key === 'user_availability') {
      ele = (
        <View width={'93.5%'}>
        <TableTopBar
          title={''}
          selectedTab={selectedTab?.key}
          alignSearchBarFlexEnd={true}
          isDividerNotVisible={true}
          searchBarWidth={180}
          searchBarMinWidth={180}
          onActionPerformed={(actionCode: string, actionData: any) => {
            onTableTopBarActionPerformed(actionCode, actionData);
          }}
         // popOverClick={true}
          buttonList={!canHideAddNewForAvailability ? [
            {
              id: 1001,
              btnText: 'new',
              backgroundColor: Colors.secondary['100'],
              borderColor: Colors.secondary['200'],
              textColor: '',
              variant: 'PRIMARY',
              size: 'sm',
              leftIcon: <PlusIcon />,
              btnType: 'Popover',
              dropDownType: 'Popover',
              popoverPosition: 'bottomLeft',
              content: getUserListView(),
              isTransBtn: false,
              btnClick: () => {
                // btnClick
              },
            },
          ] : []}
        />
        </View>
      );
    } else if (selectedTab.key === 'types') {
      ele = (
        <View width={'93.5%'}>
        <TableTopBar
          title={''}
          selectedTab={selectedTab?.key}
          alignSearchBarFlexEnd={true}
          isDividerNotVisible={true}
          searchBarWidth={180}
          searchBarMinWidth={180}
          onActionPerformed={(actionCode: string, actionData: any) => {
            onTableTopBarActionPerformed(actionCode, actionData);
          }}
          buttonList={[
            {
              btnText: 'new',
              backgroundColor: Colors.secondary['100'],
              borderColor: Colors.secondary['200'],
              textColor: '',
              variant: 'PRIMARY',
              size: 'sm',
              leftIcon: <PlusIcon />,
              btnClick: () => {
                setComponentState((prev) => ({
                  ...prev,
                  selectedActionCode: TABLE_TOP_BAR_ACTION_CODES.ADD_NEW_APPOINTMENT_TYPE,
                }));
              },
            },
          ]}
        />
        </View>
      );
    } else if (selectedTab.key === 'holidays') {
      ele = (
        <View width={'93.5%'}>
          <TableTopBar
            title={''}
            selectedTab={selectedTab?.key}
            alignSearchBarFlexEnd={true}
            isDividerNotVisible={true}
            searchBarWidth={180}
            searchBarMinWidth={180}
            onActionPerformed={(actionCode: string, actionData: any) => {
              onTableTopBarActionPerformed(actionCode, actionData);
            }}
            buttonList={[
              {
                btnText: 'new',
                backgroundColor: Colors.secondary['100'],
                borderColor: Colors.secondary['200'],
                textColor: '',
                variant: 'PRIMARY',
                size: 'sm',
                leftIcon: <PlusIcon />,
                btnClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    selectedActionCode: TABLE_TOP_BAR_ACTION_CODES.NEW,
                  }));
                },
              },
            ]}
          />
        </View>
      );
    }
    return ele;
  };

  return (
    <View>
      {/* <View style={styles.titleWrapper}>
        <TitleSubtitleView
          titleLabelId="schedule"
          subtitleLabelId="schedulingSubtitle"
        />
      </View> */}
      <View
        style={[
          styles.tabsWrapper,
          commonBodyContentContainer.pageContentContainer,
        ]}
      >
        <CustomTabsView
          isAddMaxTab={true}
          contentTopMargin={0}
          minScreenSupportWidth={2000}
          showHeading
          leftOffset={1200}
          basePath={'/admin/schedule'}
          heading="scheduleSetting"
          tabsList={tagsTabList()}
          rightView={() => {
            return getSelectedTopBar(selectedTab);
          }}
          onTabClick={(tabItem) => {
            onTabClick(tabItem);
          }}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
          bottomOffset={126}
          canNavigate={true}
        />
      </View>
    </View>
  );
};

export default SchedulingConfig;
