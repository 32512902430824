import {Divider, HStack, Spinner, Text, VStack, View, useToast} from "native-base"
import Ionicons from 'react-native-vector-icons/Ionicons';
import {Colors} from "../../../../../styles"
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import {WORD_DOC_MIME_TYPES} from "../../../../common/DocumentViewer/DocumentViewerHelper";
import {Pressable} from "react-native";
import {ToastType, showToast} from "../../../../../utils/commonViewUtils";
import {getBase64} from "../../../../PersonOmniView/LeftContainer/OtherDetails/PatientDocuments/UploadPatientDocument";
import CareStudioService from "../../../../../services/CommonService/CareStudioService";
import {useState} from "react";
import {DocumentViewer} from "../../../../common/DocumentViewer/DocumentViewer";
import { default as Icon } from 'react-native-vector-icons/AntDesign';
import {EyeOutlined} from "@ant-design/icons";
import {FHAlertDialog} from "../../../../common/FHAlertDialog";
import {useIntl} from "react-intl";
import {BUTTON_TYPE} from "../../../../../constants";

export const CareJourneyDocumentsList = (props: any)=> {
  const {documents} = props;
  const toast = useToast();
  const careStudioInstance = CareStudioService.getCareStudioServiceInstance();
  const [stateData,setStateData] = useState({
    loading: false,
    isPreviewVisible: false,
    previewTitle: '',
    previewObj: {} as any,
    selectedDocumentId: undefined,
    showDeleteAlert: false,
    documentId: ''
  })
  const intl = useIntl();

  async function downloadFileAsBlob(file: any,downloadFile = false) {
    const attachmentId: string = (file as any).id;
    const service = careStudioInstance.careStudioAxiosService;
    let fileUrl = '';
    setStateData((prev)=>{
      return {
        ...prev,
        loading: true,
        selectedDocumentId: file?.id
      }
    })
    try {
      if (!file.blobUrl || WORD_DOC_MIME_TYPES.includes(file.type)) {
        const response = await service.get(`/attachment/${attachmentId}/file?isPreview=${downloadFile === false}`, {
          responseType: 'blob',
        });

        file.blob = response.data;
        file.blobUrl = URL.createObjectURL(file.blob);
        file.preview = await getBase64(response.data);
      }
      if (file.type) {
        fileUrl = (file as any).blobUrl;
      }
      setStateData((prev)=>{
        return {
          ...prev,
          isPreviewVisible: true,
          previewTitle: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
          previewObj: {
            url: fileUrl || '',
            type: file.type as string,
          },
          loading: false,
          selectedDocumentId: undefined
        }
      })
    } catch (error) {
      showToast(toast, 'Error while opening file', ToastType.error);
      setStateData((prev)=>{
        return {
          ...prev,
          loading: false,
          selectedDocumentId: undefined
        }
      })
    }
  }

  const handlePreview =async (document:any)=> {
    const file = {
      id: document?.documentAttachments[0].attachment.id || document?.uid,
      uid: document?.documentAttachments[0].attachment.id || document?.uid,
      name: document?.caption || document?.documentAttachments[0].attachment.name,
      type: document?.documentAttachments[0].attachment.type,
      status: 'done',
    } as any;
    if ((file as any).id && (!(file as any).blobUrl || WORD_DOC_MIME_TYPES.includes(file.type as string))) {
      await downloadFileAsBlob(file);
    }
  }

  const  getFileIcon =(type?: string)=> {
    if (!type) {
      return <></>;
    }

    if (type === 'application/pdf') {
      return (
        <View
          padding={2}
          backgroundColor={Colors.Custom.Gray100}
          borderRadius={'50%'}
        >
          <MaterialCommunityIcons
            name="file-pdf-box"
            size={32}
            color={Colors.Custom.Gray500}
          />
        </View>
      );
    } else if (type?.startsWith('image/')) {
      return (
        <View
          padding={2}
          backgroundColor={Colors.Custom.Gray100}
          borderRadius={'50%'}
        >
          <MaterialCommunityIcons
            name="file-image"
            size={32}
            color={Colors.Custom.Gray500}
          />
        </View>
      );
    } else if (type?.startsWith('video/')) {
      return (
        <View
          padding={2}
          backgroundColor={Colors.Custom.Gray100}
          borderRadius={'50%'}
        >
          <MaterialCommunityIcons
            name="video"
            size={32}
            color={Colors.Custom.Gray500}
          />
        </View>
      );
    } else if (type && WORD_DOC_MIME_TYPES.includes(type)) {
      return (
        <View
          padding={2}
          backgroundColor={Colors.Custom.Gray100}
          borderRadius={'50%'}
        >
          <MaterialCommunityIcons
            name="file-word"
            size={32}
            color={Colors.Custom.Gray500}
          />
        </View>
      );
    }
    return (
      <View
        padding={2}
        backgroundColor={Colors.Custom.Gray100}
        borderRadius={'50%'}
      >
        <Ionicons name="document-text" size={26} color={Colors.Custom.Gray500} />
      </View>
    );
  }

  return <>
    {Array.from(
      new Set(documents?.map((document: any) => document?.documentAttachments[0]?.attachment?.id))
    ).map((uniqueId, index) => {
      const document = documents.find((doc: any) => doc?.documentAttachments[0]?.attachment?.id === uniqueId);
      const attachment = document?.documentAttachments[0]?.attachment;
    return (
      <>
        {!document?.isDeleted && attachment?.id && <View>
          <HStack flex={1} alignItems={'center'} my={1}>
            <View flex={0.15}>
              <View width={50}>
                {getFileIcon(attachment?.type)}
              </View>
            </View>
            <HStack ml={2} flex={0.95} alignItems={'center'} justifyContent={'space-between'}>
              <VStack flex={0.85}>
                <Text size='smMedium'>
                  {document?.caption}
                </Text>
                <View>
                  <Text fontSize="xs" color="gray.500" isTruncated={true} noOfLines={1}>
                    {attachment?.name}
                  </Text>
                </View>
              </VStack>
              <HStack flex={0.15} space={2} alignItems={'center'}>
                <Pressable
                  style={{marginRight:2}}
                  onPress={()=>{
                    handlePreview(document);
                  }}
                >
                  {stateData?.loading && stateData?.selectedDocumentId === (document?.documentAttachments[0].attachment.id || document?.uid) ? <Spinner size="sm" /> : <EyeOutlined style={{ fontSize: '18px', color: Colors.Custom.Gray500}} />}
                </Pressable>
                {!props?.disableAction && <Pressable onPress={()=>{
                  setStateData((prev)=> {
                    return {
                      ...prev,
                      showDeleteAlert: true,
                      documentId: document?.id || document?.uid
                    }
                  })
                }}>
                  <MaterialCommunityIcons
                    name="delete"
                    size={22}
                    color={Colors.Custom.Gray500}
                    style={{marginRight:10}}
                  />
                </Pressable>}
              </HStack>
            </HStack>
          </HStack>
          { (index < documents?.length-1) && <Divider />}
        </View>}
      </>
    )
  })}
    {stateData?.isPreviewVisible && <DocumentViewer
      fileName={stateData.previewTitle}
      fileType={stateData.previewObj.type}
      onClose={() => {
        setStateData((prev) => {
          return {
            ...prev,
            isPreviewVisible: false
          }
        })
      }}
      fileUrl={stateData.previewObj.url}
      isOpen={true}
    />}
    <FHAlertDialog
      isOpen={stateData?.showDeleteAlert}
      header={intl.formatMessage({id: 'deleteDocument'})}
      message={intl.formatMessage({id: 'deleteDocumentMsg'})}
      buttonActions={[
        {
          textLocalId: 'cancel',
          buttonProps: {
            variant: BUTTON_TYPE.SECONDARY
          },
          onPress: () => {
            setStateData((prev) => {
              return {
                ...prev,
                showDeleteAlert: false
              }
            })
          },
        },
        {
          textLocalId: 'delete',
          buttonProps: {
            variant: BUTTON_TYPE.PRIMARY
          },
          onPress: () => {
            setStateData((prev) => {
              return {
                ...prev,
                showDeleteAlert: false
              }
            })
            props?.onDelete(stateData?.documentId)
          },
        },
      ]}
      customHeight={'23%'}
    />
  </>
}