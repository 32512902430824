import {useLazyQuery} from '@apollo/client';
import {Drawer} from 'antd';
import {Pressable, Skeleton, Text, VStack} from 'native-base';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {GROUP_MEMBER_TYPE, PERSON_TYPES} from '../../../constants';
import EmployeeQueries from '../../../services/Employee/EmployeeQueries';
import {Colors} from '../../../styles';
import {DisplayCardAvatar} from '../DisplayCard/DisplayCardAvatar';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {
  IDependentListProps,
  IDependentListState,
  RelatedContact,
} from './interfaces';
import { withMiniContactViewHOC } from '../../MiniContactViewHOC';

const DependentList = (props: IDependentListProps) => {
  const {contactId, isOpen, onClose} = props;
  const [state, setState] = React.useState<IDependentListState>({
    loading: false,
    contactRelations: [],
  });
  const navigate = useNavigate();

  const [getRelationsFromContactId] = useLazyQuery(
    EmployeeQueries.GetRelationsFromContactId,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getData = async () => {
    if (!contactId) {
      return;
    }
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const response = await getRelationsFromContactId({
        variables: {
          contactId,
        },
      });
      const contactRelations = response.data.contactRelations || [];
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          contactRelations,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          contactRelations: [],
        };
      });
    }
  };

  const navigateToProfileView = (contact: RelatedContact) => {
    const id = contact?.id;
    if (isCustomerType(contact)) {
      props?.navigateOrOpenContactIdDrawer?.(id?.toString());
    }
    navigate(`/members/prospect/${id}`);
  };

  const isCustomerType = (contact: RelatedContact) => {
    const contactTypeCode = contact?.contactType?.contactType?.code;
    return contactTypeCode === PERSON_TYPES.CUSTOMER;
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      width={'33vw'}
      title={
        <>
          <ModalActionTitle title={'Dependents'} titleColor={''} />
        </>
      }
    >
      {state.loading ? (
        <VStack
          style={{
            flex: 1
          }}
        >
          <Skeleton.Text lines={4} />
        </VStack>
      ) : (
        <VStack>
          {state.contactRelations.map((item) => {
            const {realatedContact: contact} = item;
            return (
              <Pressable
                style={{
                  paddingVertical: 12,
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
                onPress={() => {
                  navigateToProfileView(contact);
                }}
              >
                <DisplayCardAvatar
                  avatarStyle={{
                    avatarSize: '12',
                  }}
                  userData={{
                    userId: contact?.id,
                    userType: GROUP_MEMBER_TYPE.CONTACT,
                    imgSrc: '',
                    genderCode: contact?.person?.gender?.code as any,
                  }}
                />
                <VStack style={{marginLeft: 12}}>
                  <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                    {`${contact?.person?.firstName} ${contact?.person?.lastName}`}
                  </Text>
                  <Text size={'smNormal'} color={Colors.Custom.Gray500}>
                    {contact?.email}
                  </Text>
                </VStack>
              </Pressable>
            );
          })}
        </VStack>
      )}
    </Drawer>
  );
};

export default withMiniContactViewHOC(DependentList);
