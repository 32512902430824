import {Drawer} from 'antd';
import { Button, FormControl, Input, Modal, useToast, VStack } from 'native-base';
import { useContext, useState } from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import { BUTTON_TYPE, MLOV_CATEGORY, PERSON_TYPES } from '../../../../constants';
import {Colors} from '../../../../styles';
import { getAccountUUID, isLeadGroupsPage } from '../../../../utils/commonUtils';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import { createEmployeeGroup, createOrUpdateLeadGroups } from '../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { ICreateGroupModalProps } from '../interfaces';
import { styles } from './CreateGroupModalStyle';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {getMlovCodeFromId} from '../../../../utils/mlovUtils';

const { Content, CloseButton, Header, Footer, Body } = Modal;
const CreateGroupModal = (props: ICreateGroupModalProps) => {
    const { isOpen, onClose, onConfirm } = props;
    const {width} = Dimensions.get('window');
    const finalWidth = width / 3;
    const [groupName, setGroupName] = useState('');
    const [groupNamerror, setGroupNameError] = useState('')
    const commonData = useContext(CommonDataContext);
    const mlov = commonData.MLOV;
    const customerTypeMlovList = mlov[MLOV_CATEGORY.CUSTOMER_TYPE] || [];
    const mlovCode = getMlovCodeFromId(
      customerTypeMlovList,
      props.contactTypeUuid
    );
    const isCustomer = mlovCode === PERSON_TYPES.CUSTOMER;
    const tenantId = getAccountUUID();
    const toast = useToast();
    const intl= useIntl()
    const promise = isCustomer ? createEmployeeGroup : createOrUpdateLeadGroups;

    const createGroup =()=>{
        const body:any ={
            name: groupName.trim(),
            tenantId: tenantId,
            type: 'STATIC',
            metadata: props.metadata,
          };
          if(props.externalId != undefined  || props.externalId != null){
            body.externalId = props.externalId;
          }
        promise(body).then((resp) => {
            if ( resp?.id) {
                showToast(
                    toast,
                    'Group Created',
                    ToastType.success
                  );
                onConfirm(groupName.trim(), resp?.id)
                setGroupName('');
            } else {
                showToast(
                    toast,
                    'Something went wrong. Please try again later',
                    ToastType.error
                  );
            }
          })
          .catch((error) => {
            if (error?.response?.status === 400) {
              setGroupNameError(intl.formatMessage({ id: 'populationGroupNameExists' }))
            }
          });
    }
    return (
      <Drawer
          visible={isOpen}
          width={finalWidth}
          onClose={() => {
            setGroupName('');
            setGroupNameError('')
            onClose();
          }}
          title={
            <ModalActionTitle
              title={'createGroup'}
              titleColor={''}
              buttonList={[
                {
                  show: true,
                  id: 1,
                  btnText: 'cancel',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: () => {
                    setGroupName('');
                    setGroupNameError('')
                    onClose();
                  },
                },
                {
                  show: true,
                  id: 2,
                  btnText: 'confirm',
                  textColor: Colors.Custom.mainPrimaryPurple,
                  variant: BUTTON_TYPE.PRIMARY,
                  isTransBtn: false,
                  onClick: () => {
                    if (groupName?.trim()) {
                      createGroup();
                    }
                    else{
                      setGroupNameError(intl.formatMessage({id: 'enterValidName'}))
                    }
                  },
                },
              ]}
            />
          }
        >
        <VStack space={3}>
          <FormControl isRequired style={styles.formElement} isInvalid={Boolean(groupNamerror)}>
            <Input
              isRequired
              placeholder="Enter group name"
              value={groupName}
              onChangeText={(text) => {
                setGroupName(text);
                setGroupNameError('')
              }}
            />
            {groupNamerror && (
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                  maxWidth: 380,
                }}
              >
                {groupNamerror}
              </FormControl.ErrorMessage>
            )}
          </FormControl>
        </VStack>
        </Drawer>
    )
};

export default CreateGroupModal;
