import {
  IProductVariantData,
  IProductVariantDataRelationships,
  IProductVariantIncluded,
} from '../interfaces/ProductVariant';

export const getProductVariantRelationDataInMainObj = (
  variantDataList: IProductVariantData[],
  includedList: IProductVariantIncluded[]
): IProductVariantData[] => {
  const tempVariantDataList = [...variantDataList];
  tempVariantDataList.forEach((singleProductVariant) => {
    const relationships = singleProductVariant?.relationships;

    if (
      relationships &&
      Object.keys(relationships) &&
      Object.keys(relationships).length
    ) {
      const relationshipKeys = Object.keys(relationships);
      updateProductRelationsData(relationshipKeys, relationships, includedList);
    }
  });
  return tempVariantDataList;
};

const updateProductRelationsData = (
  relationshipKeys: string[],
  relationships: IProductVariantDataRelationships,
  includedList: IProductVariantIncluded[]
) => {
  relationshipKeys.forEach((dataKey) => {
    const relationshipsData: any =
      relationships[dataKey as keyof IProductVariantDataRelationships] || [];
    if (
      relationshipsData &&
      relationshipsData?.data &&
      relationshipsData?.data?.length
    ) {
      const relationshipsDataList = relationshipsData?.data || [];
      updateRelationshipAttributes(relationshipsDataList, includedList);
    } else if (
      relationshipsData &&
      relationshipsData?.data &&
      relationshipsData?.data?.id
    ) {
      const relationshipsDataList = [relationshipsData?.data] || [];
      updateRelationshipAttributes(relationshipsDataList, includedList);
    }
  });
};

const updateRelationshipAttributes = (
  relationshipsDataList: any,
  includedList: IProductVariantIncluded[]
) => {
  relationshipsDataList.forEach(
    (relationshipElement: {
      id: string;
      type: string;
      attributes: any;
      relationships: any;
    }) => {
      const tempData: any = getDataListFromRelationIdAndType(
        includedList,
        relationshipElement.id,
        relationshipElement.type
      );
      if (tempData && Object.keys(tempData) && Object.keys(tempData).length) {
        relationshipElement.attributes = tempData[0].attributes;
        relationshipElement.relationships = tempData[0].relationships;
      }
    }
  );
};

export const getDataListFromRelationIdAndType = (
  dataList: IProductVariantIncluded[],
  id: string,
  type: string
): IProductVariantIncluded[] => {
  const finalFilterList =
    dataList.filter((productData) => {
      return productData.id === id && productData.type === type;
    }) || [];
  return finalFilterList;
};
