export const getFilterQuery = (args: {
  employerId?: string;
  accountUuid: string;
}) => {
  const where: any = {};
  where.isDeleted = {_eq: false};
  where.accountUuid = {_eq: args.accountUuid};

  if (args.employerId) {
    where.id = {_eq: args.employerId};
  }

  return where;
};

export const getTotalAndLoggedInCount = (
  data: any[]
): {
  total: number;
  loggedIn: number;
} => {
  if (data.length === 0) {
    return {
      total: 0,
      loggedIn: 0,
    };
  }
  const total = data.reduce(
    (previous, current) => previous + current?.total?.aggregate?.count || 0,
    0
  );
  const loggedIn = data.reduce(
    (previous, current) => previous + current?.loggedIn?.aggregate?.count || 0,
    0
  );
  return {loggedIn, total};
};

export const getContactListFilterQuery = (args: {
  selectedSegmentType: string;
  employerId?: string;
  accountUuid?: string;
  accountId: string | number;
  removeLoginFilter?: boolean
}) => {
  const where: any = {};
  where.isDeleted = {_eq: false};
  where.accountId = {_eq: args.accountId};
  if (args.employerId) {
    where.uuid = {
      _is_null:false
    }
    where.employees = {
      employerId: {
        _eq: args.employerId,
      },
      isDeleted: {
        _eq: false,
      },
    };
  }
  if (!args.removeLoginFilter) {
    if (args.selectedSegmentType === SegmentValues.loggedIn) {
      where.additionalAttributes = {
        _has_key: 'loggedInTime',
      };
    } else {
      where._not = {
        additionalAttributes: {
          _has_key: 'loggedInTime',
        },
      };
    }
  }
  return where;
};

export const getFormattedContactList = (
  data: any
): {
  id: number;
  name: string;
  email: string;
  uuid: string;
}[] => {
  const result: {
    id: number;
    name: string;
    email: string;
    uuid: string;
  }[] = [];
  const contacts = data.contacts || [];
  if (contacts?.length == 0) {
    return result;
  }
  contacts.forEach((item: any) => {
    result.push({
      id: item.id,
      name: item.name,
      email: item.email,
      uuid: item.uuid,
    });
  });
  return result;
};

export const getLoggedContactsFilterQuery = (args: {
  accountId: number | string;
  employerId?: string;
}) => {
  const where1: any = {};
  const where2: any = {};

  where1.accountId = {
    _eq: args.accountId,
  };
  where2.accountId = {
    _eq: args.accountId,
  };

  where1.isDeleted = {
    _eq: false,
  };
  where2.isDeleted = {
    _eq: false,
  };
  where1.additionalAttributes = {
    _has_key: 'loggedInTime',
  };

  where2._not = {
    additionalAttributes: {
      _has_key: 'loggedInTime',
    },
  };

  if (args.employerId) {
    where1.employees = {
      employerId: {
        _eq: args.employerId,
      },
      isDeleted: {
        _eq: false,
      },
    };
    where2.employees = {
      employerId: {
        _eq: args.employerId,
      },
      isDeleted: {
        _eq: false,
      },
    };
  }

  return {
    where1,
    where2,
  };
};

export enum SegmentValues {
  loggedIn = 'Logged In',
  neverLoggedIn = 'Never Logged In'
}
