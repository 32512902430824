import React from 'react';
import { View } from "react-native";
import { ITabsList } from "../../common/CustomTabsView/interfaces";
import CustomTabsView from "../../common/CustomTabsView/CustomTabsView";
import { styles } from "../AccountTabsView/AccountTabsStyle";
import { useState } from "react";
import CareProgramBilling from "./CareProgramBilling";
import { CareProgramBillingHistory } from "./CareProgramBillingHistory";

const CareProgramBillingTabsView = ()  => {
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);

  const onTabClick = (tab: ITabsList) => {
    if (selectedTab?.key !== tab.key) {
      setSelectedTab(tab)
    }
  };
    const tagsTabList = (): ITabsList[] => {
        const array: ITabsList[] = [
          {
            key: 'billing',
            title: 'Billable',
            path: 'billable',
            tabContainerElem: () => {
              return (
                <CareProgramBilling
                />
              );
            },
          },
          {
            key: 'billingHistory',
            title: 'Billing History',
            path: 'billingHistory',
            tabContainerElem: () => {
              return (
                <CareProgramBillingHistory
                />
              );
            },
          },
        ];
        return array;
      };

    return (
          <View style={{flex: 1}}>
            <View style={styles.tagContainer}>
              <CustomTabsView
                contentTopMargin={0}
                isAddMaxTab={true}
                minScreenSupportWidth={ 1600}
                showHeading
                basePath={'/admin/CareProgramBilling'}
                heading="teamTitle"
                tabsList={tagsTabList()}
                tabListWrapperStyle={{
                  paddingHorizontal: 16,
                  backgroundColor: '#fff',
                }}
                canNavigate={true}
                bottomOffset={124}
                onTabClick={(tabItem) => {
                  onTabClick(tabItem);
                }}
              />
            </View>
          </View>
    );
}

export default CareProgramBillingTabsView;