import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {Colors} from '../../../styles';
import {TaskPanelType} from '../../TaskCard/TaskEnum';
import {
  RichTextEditor,
  TEXT_ONLY_MODULES_WITHOUT_CLEAN,
} from '../../common/RichTextEditor/RichTextEditor';
import CommunicationModeSelector from './CommunicationModeSelector';
import {PatientNotesLogModeOfContact} from '../constants';
import {Pressable, Spinner} from 'native-base';
import {DatePicker, Select} from 'antd';
import {useIntl} from 'react-intl';
import {reactStyles, styles} from '../styles';
import useOutreachStepOutComes from '../../common/CareProgramStepOutreach/useOutreachStepOutComes';
import {ContactOutreachActivities} from './ContactOutreachActivities/ContactOutreachActivities';
import ChevronDownSvg from '../../common/Svg/ChevronDownSvg';
import CalenderNavSvg from '../../common/Svg/ConversationAllSvg/CalenderNavSvg';
import {DATE_FORMATS} from '../../../constants/StringConst';
import moment from 'moment';
import {useContactOutreachActivity} from './ContactOutreachActivities/useContactOureachActivity';

/* import ReactDOMServer from 'react-dom/server';
import { Quill } from 'react-quill';
import { ArrowDownOutlined } from '@ant-design/icons';
const icons = Quill.import('ui/icons');
icons['bold'] = ReactDOMServer.renderToString(<ArrowDownOutlined />); */

const InsightsPatientNotesView = (props: {
  contactData?: IContact;
  formattedContactData?: IContact;
  onTaskPanelChanged?: (taskPanelType: TaskPanelType) => void;
}) => {
  const intl = useIntl();
  const {categoryWiseList} = useOutreachStepOutComes();

  const {
    data,
    careProgramTypesMlov,
    saveButtonDisabled,
    outreachCommunicationModes,
    saveButtonLoading,
    activeMode,
    setActiveMode,
    setData,
    validateForm,
    saveActivity,
    setDefaultData,
    outreachActivities,
    showMoreActivity,
    loading,
    fetchMoreActivities
  } = useContactOutreachActivity({
    contactUuid: props?.contactData?.uuid || '',
  });

  return (
    <div className="day-op">
      <View style={styles.padding16PaddingLeft10}>
        <View style={styles.insightsPatientNotesViewHeader}>
          <View>
            <Select
              className="day-op custom-select-box"
              placeholder="AWV"
              suffixIcon={<ChevronDownSvg />}
              value={
                data?.careProgramTypeId?.length
                  ? data?.careProgramTypeId
                  : undefined
              }
              onSelect={(value) => {
                setData({...data, careProgramTypeId: value});
              }}
            >
              {careProgramTypesMlov.map((mode) => (
                <Select.Option key={mode.id} value={mode.id}>
                  {mode.value}
                </Select.Option>
              ))}
            </Select>
          </View>
          <View>
            <DatePicker
              suffixIcon={
                <CalenderNavSvg
                  width={16}
                  height={16}
                  customStrokeColor={Colors.FoldPixel.GRAY300}
                />
              }
              value={
                data?.outreachDateTime
                  ? moment(data?.outreachDateTime)
                  : undefined
              }
              className="day-op custom-antd-input-box"
              showTime={{use12Hours: true}}
              allowClear={false}
              style={reactStyles.datePicker}
              onChange={(date) => {
                if (date) {
                  setData({...data, outreachDateTime: date?.toISOString()});
                }
              }}
              format={DATE_FORMATS.MM_DD_YY_COMMA_HH_MM_A_DATE_TIME_FORMAT}
            />
          </View>
        </View>

        <View style={styles.insightsPatientNotesView}>
          <CommunicationModeSelector
            mode={outreachCommunicationModes}
            activeMode={activeMode}
            onModeChange={(mode) => {
              setActiveMode(mode.code);
              setData({...data, communicationModeId: mode.id});
            }}
          />
          <RichTextEditor
            modules={TEXT_ONLY_MODULES_WITHOUT_CLEAN}
            style={{
              borderWidth: 0,
            }}
            placeholder={intl.formatMessage({id: 'addNote'})}
            valueStr={data?.note}
            onChangesValue={(text: string) => {
              const trimedData = text?.trim();
              const newData = {...data, note: trimedData};
              setData(newData);
              validateForm(newData);
            }}
          />
          {/* <Pressable
            onPress={() => {}}
            position={'absolute'}
            bottom={'12.5px'}
            left={'15px'}
            flexDir={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <Tooltip
              title={intl.formatMessage({
                id: 'taskAttachmentUploadSupportAndSize',
              })}
            >
              <PaperClipSvg />
            </Tooltip>
          </Pressable> */}
        </View>

        <View style={styles.mt8}>
          <Select
            className="day-op custom-select-box"
            placeholder={intl.formatMessage({id: 'selectOutreachOutcome'})}
            value={data?.outcomeId?.length ? data?.outcomeId : undefined}
            style={reactStyles.w100p}
            suffixIcon={<ChevronDownSvg />}
            onSelect={(value) => {
              const newData = {...data, outcomeId: value};
              setData(newData);
              validateForm(newData);
            }}
          >
            {Object.keys(categoryWiseList).map((key) => {
              const optionList = categoryWiseList[key];
              return (
                <Select.OptGroup key={key} label={key}>
                  {optionList.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              );
            })}
          </Select>
        </View>

        <View style={styles.buttonContainer}>
          <Pressable
            borderRadius={4}
            borderWidth={0.5}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={4}
            borderColor={
              saveButtonDisabled
                ? Colors.FoldPixel.TRANSPARENT
                : Colors.FoldPixel.PRIMARY300
            }
            padding={'8px'}
            isDisabled={saveButtonDisabled}
            backgroundColor={
              saveButtonDisabled
                ? Colors.FoldPixel.TRANSPARENT
                : Colors.FoldPixel.PRIMARY300
            }
            onPress={() => {
              if (validateForm(data)) {
                saveActivity();
              }
            }}
          >
            {saveButtonLoading ? (
              <Spinner height={'16px'} width={'16px'} />
            ) : (
              <></>
            )}
            <Text
              style={StyleSheet.flatten([
                styles.saveButtonText,
                saveButtonDisabled ? styles.buttonTextDisabled : {},
              ])}
            >
              {intl.formatMessage({id: 'save'})}
            </Text>
          </Pressable>

          <Pressable
            borderRadius={4}
            borderWidth={0.5}
            borderColor={Colors.FoldPixel.GRAY250}
            padding={'8px'}
            backgroundColor={Colors.Custom.White}
            onPress={() => {
              setData((prev)=> ({
                ...prev,
                note: ''
              }))
            }}
          >
            <Text style={styles.buttonText}>
              {intl.formatMessage({id: 'cancel'})}
            </Text>
          </Pressable>
        </View>

        <ContactOutreachActivities
          outreachActivities={outreachActivities}
          loading={loading}
          showMoreActivity={showMoreActivity}
          fetchMoreActivities={fetchMoreActivities}
        />
      </View>
    </div>
  );
};

export default InsightsPatientNotesView;
