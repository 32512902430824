import {useLazyQuery} from '@apollo/client';
import {GET_CONTACT_CARE_TEAM_MEMBER_TYPE_BY_MEMBER_TYPE_ID} from '../../../../services/CareTeam/CareTeamQueries';
import {getPatientChronicConditions} from '../../../../services/CommonService/AidBoxService';
import {ICareProgramAdditionalData} from '../interface';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {GET_FUTURE_APPOINTMENT_DATA} from '../../../../services/Appointment/AppointmentQueries';
import {ADDITIONAL_DATA_CODE} from '../../CareManagementBilling/CareManagementConstants';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import {useContext} from 'react';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {MLOV_CATEGORY} from '../../../../constants';
import {
  APPOINTMENT_PARTICIPANT_TYPE_CODES,
  APPOINTMENT_STATUS_CODES,
  CARE_TEAM,
  CARE_TEAM_MEMBER_TYPE,
} from '../../../../constants/MlovConst';
import React from 'react';
import ContactCareProgram from '../../../../services/ContactCareProgram/ContactCareProgram';
import { GET_CARE_PROGRAM_ACTIVITY_LOGS } from '../../../../services/Task/TaskQueries';

export const useCareProgramAdditionalData = () => {
  const commonDataContext = useContext(CommonDataContext);
  const appointmentStatusList =
    getMlovListFromCategory(
      commonDataContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

  const careTeamList =
    getMlovListFromCategory(
      commonDataContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PRIMARY_CARE_TEAM
    ) || [];
  const careTeamTypeId = getMlovIdFromCode(careTeamList, CARE_TEAM.CODE);
  const careTeamMemberTypesList =
    getMlovListFromCategory(
      commonDataContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_TEAM_MEMBER_TYPE
    ) || [];
  const careManagerTypeId = getMlovIdFromCode(
    careTeamMemberTypesList,
    CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
  );
  const pcpUserTypeId = getMlovIdFromCode(
    careTeamMemberTypesList,
    CARE_TEAM_MEMBER_TYPE.PCP
  );

  const [loading, setLoading] = React.useState(false);

  const [getContactCareTeamMemberTypeByMemberTypeId] = useLazyQuery(
    GET_CONTACT_CARE_TEAM_MEMBER_TYPE_BY_MEMBER_TYPE_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getCareProgramActivityLogs] = useLazyQuery(GET_CARE_PROGRAM_ACTIVITY_LOGS, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const [getCareProgramConsent] = useLazyQuery(ContactCareProgram.GET_CONTACT_CARE_PROGRAM_CONSENT_BY_ID, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const [getFutureAppointmentData] = useLazyQuery(GET_FUTURE_APPOINTMENT_DATA, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const getWhereConditionForUpcomingAppointmentData = (
    pcpUserId: string,
    contactUuid: string,
    appointmentStartDate?: string
  ) => {
    const appointmentStatusCodes = [
      APPOINTMENT_STATUS_CODES.CANCELLED,
      APPOINTMENT_STATUS_CODES.DECLINED,
    ];
    const appointmentStatusIds = [] as string[];
    appointmentStatusList?.forEach((status: any) => {
      if (!appointmentStatusCodes?.includes(status?.code)) {
        appointmentStatusIds.push(status?.id);
      }
    });

    const startDate = appointmentStartDate || new Date();
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 30);

    const upcomingAppointmentWhereCondition: any = {
      statusId: {
        _in: appointmentStatusIds,
      },
      startDateTime: {
        _gt: startDate,
      },
      endDateTime: {
        _lt: endDate,
      },
      _and: [
        {
          participants: {
            contactId: {
              _eq: contactUuid,
            },
            participantType: {
              code: {
                _eq: APPOINTMENT_PARTICIPANT_TYPE_CODES.PATIENT,
              },
            },
          },
        },
        {
          participants: {
            userId: {
              _eq: pcpUserId,
            },
            participantType: {
              code: {
                _eq: APPOINTMENT_PARTICIPANT_TYPE_CODES.PRIMARY_USER,
              },
            },
          },
        },
      ],
    };
    return upcomingAppointmentWhereCondition;
  };

  const getPatientAdditionalData = async (
    params: ICareProgramAdditionalData
  ) => {
    try {
      const {
        patientId,
        location,
        contactUuid,
        additionalDataCodes,
        appointmentStartDate,
        contactCareProgramId
      } = params;
      const results: Partial<Record<ADDITIONAL_DATA_CODE, any>> = {};
      const promiseList: Promise<any>[] = [];

      let pcpAndAppointmentPromise: Promise<void> | null = null;
      setLoading(true);

      for (const dataCode of additionalDataCodes) {
        switch (dataCode) {
          case ADDITIONAL_DATA_CODE.PCP_AND_CARE_MANAGER:
          case ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT:
            if (!pcpAndAppointmentPromise) {
              pcpAndAppointmentPromise = (async () => {
                const careTeamData =
                  await getContactCareTeamMemberTypeByMemberTypeId({
                    variables: {
                      contactId: contactUuid,
                      careTeamTypeId: careTeamTypeId,
                      careTeamMemberTypeId: [pcpUserTypeId, careManagerTypeId],
                    },
                  });

                results[ADDITIONAL_DATA_CODE.PCP_AND_CARE_MANAGER] =
                  careTeamData?.data;

                if (
                  additionalDataCodes.includes(
                    ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT
                  ) &&
                  careTeamData.data
                ) {
                  const pcpUserData =
                    careTeamData?.data?.careTeams?.[0]?.careTeamMemberType?.find(
                      (careTeamMemberType: any) =>
                        careTeamMemberType?.memberType?.code ===
                        CARE_TEAM_MEMBER_TYPE.PCP
                    );

                  const pcpUserId = pcpUserData?.userId;

                  if (pcpUserId) {
                    const whereCondition =
                      getWhereConditionForUpcomingAppointmentData(
                        pcpUserId,
                        contactUuid,
                        appointmentStartDate
                      );
                    const futureAppointmentData =
                      await getFutureAppointmentData({
                        variables: {whereCondition: whereCondition},
                      });
                    results[ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT] =
                      futureAppointmentData?.data;
                  }
                }
              })();
              promiseList.push(pcpAndAppointmentPromise);
            }
            break;

          case ADDITIONAL_DATA_CODE.CHRONIC_CONDITIONS:
            const chronicConditionsPromise = getPatientChronicConditions(
              [{patientId, location}],
              true
            ).then((response) => {
              results[ADDITIONAL_DATA_CODE.CHRONIC_CONDITIONS] = response?.data;
            });
            promiseList.push(chronicConditionsPromise);
            break;
          case ADDITIONAL_DATA_CODE.PATIENT_CONSENT:
            const response = await getCareProgramConsent({
              variables: {
                contactCareProgramId: contactCareProgramId,
              }
            });
            const careProgram = response.data?.contactCarePrograms?.[0];
            results[ADDITIONAL_DATA_CODE.PATIENT_CONSENT] = careProgram;
            break;
          case ADDITIONAL_DATA_CODE.LAST_UPDATED:
            const lastUpdatedResponse = await getCareProgramActivityLogs({
              variables: {
                contactCareProgramId: contactCareProgramId,
              }
            });
            results[ADDITIONAL_DATA_CODE.LAST_UPDATED] = lastUpdatedResponse?.data?.activityLogs?.[0];
            break;
        }
      }

      if (promiseList.length > 0) {
        await Promise.all(promiseList);
      }
      setLoading(false);

      return results;
    } catch (error) {
      return {};
    }
  };

  return {
    loading,
    getPatientAdditionalData,
  };
};
