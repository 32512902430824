import { Select } from "antd"
import { View } from "native-base"
import { useState } from "react"

 
export const TypeDropdown = ({ defaultValue, onChange }: any)=> {
    const [value, setValue] = useState(defaultValue);

    const handleChange = (value: string)=> {
        setValue(value)
        onChange(value)
    }
    return (
        <View>
            <Select
                defaultValue={value}
                style={{ width: 120 }}
                onChange={handleChange}
                options={[{ label: 'EMAIL', value: 'EMAIL'},{ label: 'SMS', value: 'SMS'}]}
            />
        </View>
    )
}