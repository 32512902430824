import React from 'react';
import {View, Text} from 'react-native';
import {
  getPatientNotesLogModeOfContactTabIcon,
  PatientNotesLogModeOfContact,
} from '../constants';
import {Pressable} from 'native-base';
import {Colors} from '../../../styles/Colors';
import {IMlov} from '../../../Interfaces/CommonInterfaces';

const CommunicationModeSelector = (props: {
  mode: IMlov[];
  activeMode: string;
  onModeChange: (mode: IMlov) => void;
}) => {
  return (
    <div className="patient-notes-editor-header">
      {props?.mode.map((mode) => {
        const isActive = mode.code === props.activeMode;
        const Icon = getPatientNotesLogModeOfContactTabIcon(mode.code);
        return (
          <Pressable
            paddingX={'6px'}
            paddingY={'4px'}
            borderWidth={0.5}
            borderRadius={4}
            style={{
              borderColor: isActive ? Colors.FoldPixel.GRAY150 : 'transparent',
              backgroundColor: isActive
                ? Colors.FoldPixel.GRAY50
                : 'transparent',
            }}
            _hover={{
              backgroundColor: Colors.FoldPixel.GRAY50,
            }}
            onPress={() => {
              props.onModeChange(mode);
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {Icon ? (
                <Icon
                  width={16}
                  height={16}
                  customStrokeColor={
                    isActive
                      ? Colors.FoldPixel.GRAY300
                      : Colors.FoldPixel.GRAY250
                  }
                />
              ) : (
                <></>
              )}
              <Text
                style={{
                  marginLeft: '4px',
                  color: isActive
                    ? Colors.FoldPixel.GRAY400
                    : Colors.FoldPixel.GRAY300,
                }}
              >
                {mode.value}
              </Text>
            </View>
          </Pressable>
        );
      })}
    </div>
  );
};

export default CommunicationModeSelector;
