import {Badge, HStack} from 'native-base';
import { StyleSheet } from 'react-native';

export const GetAgentBadges = (props: any) => {
  const colorList = [
    {
      color: 'purple',
      background: 'purple.100',
    },
  ];
  return (
    <HStack style={styles.flexWrap}>
      {props.assignedAgents.map((agent: string, index: any) => {
        const item = colorList[Math.floor(Math.random() * colorList.length)];

        return (
          <Badge
            style={styles.margin4}
            key={index}
            colorScheme={item.color}
            background={item.background}
            alignSelf="center"
            variant="outline"
            borderRadius={10}
          >
            {agent}
          </Badge>
        );
      })}
    </HStack>
  );
};

const styles = StyleSheet.create({
  flexWrap: {
    flexWrap: 'wrap',
  },
  margin4: {
    marginHorizontal: 4,
    marginVertical: 4
  }
  
});
