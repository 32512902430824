import {Button, Modal, Text, View} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {IDeletePEModalProps} from '../interfaces';

const {CloseButton, Content, Body, Footer, Header} = Modal;

const DeletePEModal = (props: IDeletePEModalProps) => {
  const {isOpen, onClose, onConfirm} = props;
  const intl = useIntl();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Content
        style={{
          backgroundColor: Colors.Custom.BackgroundColor,
        }}
      >
        <CloseButton />

        <Header>
          <DisplayText textLocalId="delete" textType="Heading" />
        </Header>

        <Body>
          <View>
            <Text>Are you sure you want to delete this?</Text>
          </View>
        </Body>

        <Footer
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            backgroundColor: Colors.Custom.BackgroundColor,
          }}
        >
          <Button.Group>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onConfirm();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'confirm',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </Footer>
      </Content>
    </Modal>
  );
};

export default DeletePEModal;
