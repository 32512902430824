import { View } from 'react-native'
import { useContext, useEffect, useState } from 'react';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {ICustomToast} from '../../../Contacts/CustomField/interface';
import {CUSTOM_FIELD_GET_DATA_LIMIT, CUSTOM_FIELD_TOAST_ERROR_DURATION, CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG, ENTITY_TYPE} from '../../../Contacts/CustomField/CustomFieldConst';
import {useIntl} from 'react-intl';
import {useToast} from '../../../../Toast/ToastProvider';
import {ToastType} from '../../../../../utils/commonViewUtils';
import {useLazyQuery, useMutation} from '@apollo/client';
import TagQueries from '../../../../../services/Tags/TagQueries';
import {PackagesLabelTable} from './PackagesLabelsTable';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {getMlovListFromCategory} from '../../../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../../../constants';
import {formatGetTags} from '../../../Contacts/Tags/Helper/formatTagsData';
import {AddPackagesLabel} from './AddPackageLabel';
import {CustomFieldNoDataView} from '../../../Contacts/CustomField/CustomFieldNoDataView';
import {TABLE_TOP_BAR_ACTION_CODES} from '../../../../common/TableTopBar';
import {ITagsTableData} from '../../../Contacts';
import {PACKAGE_MLOV} from './PackagesConst';
import {IPackagesLabelView, IPackagesLabelsView} from './interfaces';
import {notification} from 'antd';

export const PackagesLabelView = (props: IPackagesLabelView) => {
  const intl = useIntl();
  const toast = useToast();
  const [stateData, setStateData] = useState<IPackagesLabelsView>({
    openAddPackagesLabel: false,
    loading: true,
    packagesLabelData: [],
    offSet: 0,
    pageNo: 1,
    limit: CUSTOM_FIELD_GET_DATA_LIMIT,
    total: 0,
  })
  const [updateTag] = useMutation(TagQueries.UpdateTag);
  const [deleteTag] = useMutation(TagQueries.DeleteTag);

  const commonData = useContext(CommonDataContext);
  const labels = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.PACKAGE_TYPE
  );

  const filterPackageMlov = labels?.filter((item) => {
    return item.code === PACKAGE_MLOV;
  });
  const packageMlov = filterPackageMlov[0];
  const categoryId = packageMlov?.id;

  const [GetLabelByFilterPagination] = useLazyQuery(TagQueries.GetLabelByFilterPagination, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    notifyOnNetworkStatusChange: true,
  })

  const onDrawerVisibleChange = (isOpen: boolean, rowRecord?: ITagsTableData)=> {
    setStateData((prev)=> {
      return {
        ...prev,
        openAddPackagesLabel: isOpen,
      }
    })
  }

  const setLoading = (loading: boolean) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: loading
      }
    })
  }

  const getPackagesLabel = async (searchString: string, offset?: number) => {
    setLoading?.(true);
    try {
      const response = await GetLabelByFilterPagination({
        variables: {
          category: categoryId,
          searchString: `%${searchString}%`,
          limit: stateData?.limit,
          offset: offset ?? stateData?.offSet,
          orderBy: {updatedAt: 'desc'}
        },
      })
      if (response?.data?.labels?.length) {
        const formattedData = formatGetTags(response?.data?.labels)
        setStateData((prev) => {
          return {
            ...prev,
            packagesLabelData: formattedData,
            total: response?.data?.labelAggregate?.aggregate?.count,
            loading: false
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            packagesLabelData: [],
            loading: false
          };
        });
      }
    } catch (error: any) {
      setLoading?.(false);
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  }

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const onLabelAddUpdateSuccess = (response: ITagsTableData, isEdit?: boolean) => {
    let updatedList: ITagsTableData[] = [];
    if (isEdit) {
      updatedList = stateData?.packagesLabelData?.map?.((item: ITagsTableData) =>
        item?.id === response?.id
          ? {
            ...item,
            color: response?.color,
            title: response?.title,
            description: response.description,
          }
          : item
      );
    } else if (stateData?.packagesLabelData?.length >= CUSTOM_FIELD_GET_DATA_LIMIT || stateData?.pageNo !== 1) {
      getPackagesLabel('');
    } else {
      updatedList = [response, ...stateData?.packagesLabelData];
    }
    setStateData((prev) => ({
      ...prev,
      packagesLabelData: updatedList,
    }));
    notification.success({
      message: intl.formatMessage({id: isEdit ? 'tagUpdated' : 'tagAdded'}),
      duration: 2.0,
      placement: 'topRight'
    });
    onDrawerVisibleChange(false);
  }

  const onLabelDelete = (id: number, offSet: number) => {
    getPackagesLabel('', offSet);
    showToast({
      toastType: ToastType.success,
      message: intl.formatMessage({id: 'tagDeleted'}),
      duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: true,
    })
  }

  const handleEditTag = async (tag: ITagsTableData) => {
    setLoading?.(true);
    try {
      const response = await updateTag({variables: tag});
      if (response?.data?.updateLabel?.id) {
        onLabelAddUpdateSuccess(response?.data?.updateLabel, true);
        setLoading?.(false);
      }
    } catch (error: any) {
      setLoading?.(false);
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  };

  const handleDeleteTag = async (tag: ITagsTableData) => {
    setLoading?.(true);
    try {
      const response = await deleteTag({variables: {id: tag.id}});
      if (response?.data?.updateLabel?.id) {
        let pageNo = stateData?.pageNo;
        let offSet = stateData?.offSet;
        setStateData((prev) => {
          if (stateData?.pageNo !== 1) {
            pageNo = stateData?.packagesLabelData?.length === 1 ? prev.pageNo - 1 : prev.pageNo;
            offSet = stateData?.packagesLabelData?.length === 1 ? prev.offSet - CUSTOM_FIELD_GET_DATA_LIMIT : prev.offSet;
          }
          return {
            ...prev,
            loading: false,
            pageNo: pageNo,
            offSet: offSet,
          }
        })
        onLabelDelete(response?.data?.deleteLabel?.id, offSet);
      }
    } catch (error: any) {
      setLoading?.(false);
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  };

  const onActionPerformed = (
    actionCode: string,
    actionData: ITagsTableData
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.SUCCESS:
        onLabelAddUpdateSuccess(actionData);
        break;
      case COMMON_ACTION_CODES.SAVE:
        handleEditTag(actionData);
        break;
      case COMMON_ACTION_CODES.DELETE:
        handleDeleteTag(actionData);
        break;
      default:
        break;
    }
  };

  const onPagination = (page: number, pageSize: number) => {
    const pageNumber = page;
    const pageLimit = pageSize;
    const offset = pageNumber * pageLimit - pageLimit;
    setStateData((prev) => {
      return {
        ...prev,
        offSet: offset,
        pageNo: page,
        limit: pageLimit,
      }
    })
  };

  useEffect(() => {
    onDrawerVisibleChange(props?.addLabelDrawer || false);
  }, [props.addLabelDrawer]);

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        pageNo: 1,
        offSet: 0,
      }
    })
    if (props?.searchString) {
        getPackagesLabel(props?.searchString, 0);
    } else {
      getPackagesLabel('', 0);
    }
  }, [props?.searchString]);

  useEffect(() => {
    if (props?.searchString) {
        getPackagesLabel(props?.searchString);
    } else {
      getPackagesLabel('');
    }
  }, [stateData?.pageNo]);

  return (
    <View
      style={{
        flex: 1
      }}
    >
      <View
        style={{
          flex: 1
        }}
      >
        {stateData?.openAddPackagesLabel &&
          <AddPackagesLabel
            isDrawerOpen={stateData?.openAddPackagesLabel}
            onDrawerClose={() => {
              onDrawerVisibleChange(false);
              props?.onActionPerformed(TABLE_TOP_BAR_ACTION_CODES.CLOSE,{})
            }}
            onActionPerformed={onActionPerformed}
            category={packageMlov}
            setLoading={setLoading}
          />
        }
        {!stateData?.loading && stateData?.packagesLabelData?.length === 0 ? (
          <View style={{
            height: 600,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <CustomFieldNoDataView
              title={'createPackageTag'}
              buttonMessage={'createFirstPackageTag'}
              onCreateFirstCustomField={()=> {
                setStateData((prev)=> {
                  return {
                    ...prev,
                    openAddPackagesLabel : true
                  }
                })
              }}
            />
          </View>) : <PackagesLabelTable
            onPagination={onPagination}
            pageNo={stateData?.pageNo}
            pageSize={stateData?.limit}
            total={stateData?.total}
            data={stateData?.packagesLabelData}
            onActionPerformed={onActionPerformed}
            loading={stateData?.loading}
          />
        }
      </View>
    </View>
  );
};

