import {Bar, Column, ColumnConfig, DualAxes, DualAxesConfig, Line, LineConfig} from '@ant-design/charts';
import {Skeleton} from 'antd';
import moment from 'moment';
import {Pressable, Tooltip} from 'native-base';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {ANALYTICS_RESOURCE, DATE_FORMATS, DISPLAY_TYPE, EMPLOYER_REPORT_FILTER_CODES, HIDE_TOTAL_COUNT_IN_LEGEND, MOMENT_DATE_CONST} from '../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {getSectionDataByResourceCode} from '../../../../../../services/Employer/EmployerServices/EmployerServices';
import {Colors, GraphColors} from '../../../../../../styles/Colors';
import {isEmployerRole} from '../../../../../../utils/commonUtils';
import {getFormattedDateStringInTimeZone} from '../../../../../../utils/DateUtils';
import RestSvgIcons from '../../../../../common/Svg/RestSvgIcons';
import EyeHideSvg from '../../../../../common/Svg/WidgetsSvg/EyeHideSvg';
import {capitalizeText} from '../../../../ContentManagement/CampaignManagement/Helper';
import FormListView from '../../CommonView/FormListView';
import InfoIconView from '../../CommonView/InfoIconView';
import {DashboardDataContext} from '../../EmployerContext/DashboardContext';
import {COST_TYPE_WORDS, WIDGET_ACTION_CODE} from '../../EmployerUtils/EmployerConst';
import {getFormattedAppliedFilter, getFormattedUtilizationWidgetData, getTopDataViewForAppointmentChartView, wordWrapFromChart} from '../../EmployerUtils/FormateDataUtils';
import {getIconForWidgetByCode} from '../../EmployerUtils/WidgetSvgHelper';
import {getDateRangeFilterFromAppliedFilter, getXAxisTitleByResourceCode, getYAxisTitleByResourceCode, isWidgetDataEmpty} from '../../EmployerUtils/WidgetUtils';
import {graphColorList, graphMetaDataColor} from '../../graphColors';
import {IAppliedFilter, IContactMembershipsData, IResource, IWidgetCardView} from '../../interface';
import {CLAIMS_DATA, ED_UTILIZATION_AMORTIZATION_DATA, ED_UTILIZATION_AMOUNT_DATA, TEMP_TOTAL_CLAIMS_COST, getTenantForDemo, TEMP_ENGAGE_FOR_CARE, EMPLOYEE_COUNT, PATIENT_SATISFACTION, USER_SATISFACTION, MEMBERSHIP_REVENUE_GROWTH, EMPLOYER_SATISFACTION, IP_CLAIM_COST_DATA, CRM_ACTIVITY, FOLD_CONVERSIONS, DPC_UTILIZATION, FOLD_INACTIVE_ENGAGEMENT, getDemoDataForEmployerReportCurrentSection} from '../../tempDataFile';
import WidgetHeader from '../../WidgetHeader/WidgetHeader';
import NodataViewTypeOne from '../NoDataView/NodataViewTypeOne';
import {getColorByTypeCode, getGraphColorsByName, getMaxYAxisValueForLineCharts} from '../utils';
import WidgetActionView from '../WidgetActionView';

const UtilizationWidgetsView = (props: IWidgetCardView) => {
  const {
    sectionData,
    onViewCodeCallBack,
    employerId,
    isAccountDashboard,
    memberType,
    employerEnrollmentData,
    appliedFilter,
    hintMembershipDisabled,
  } = props;
  const containerRef: any = useRef()
  const titleRef: any = useRef();
  const intl = useIntl();
  const [stateData, setStateData] = useState({
    donutChartData: [] as any[],
    resourceData: [] as IResource[] | any,
    loading: true,
    appliedFilter: props.appliedFilter,
    filterValueName: [] as string[],
    filterValueString: '',
    selectedAppliedFilter: [] as any[],
    graphWrapperHeight: 350,
    selectedView: '',
    formId: '',
    legendList: [] as string[]
  });
  const isEmployer = isEmployerRole()
  const isFormAssigned = sectionData?.additionalAttributes?.formId ? true : false;
  const isCustomerSatisfaction = sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION;
  const isNPSForm = sectionData.resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE;
  const isUserSatisfaction = sectionData.resourceCode === ANALYTICS_RESOURCE.USER_SATISFACTION;
  const isEmployerSatisfaction = sectionData.resourceCode === ANALYTICS_RESOURCE.EMPLOYER_SATISFACTION;

  const isMembershipGrowth = sectionData.resourceCode === ANALYTICS_RESOURCE.MEMBERSHIP_REVENUE_GROWTH;

  const isEmployeeCount = sectionData.resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_COUNT;
  const isEngaged = sectionData.resourceCode === ANALYTICS_RESOURCE.ENROLLED_FOR_CARE;
  const isIpClaimCost = sectionData.resourceCode === ANALYTICS_RESOURCE.IP_CLAIM_COST;
  const isUrgentCareClaimCost = sectionData.resourceCode === ANALYTICS_RESOURCE.URGENT_CARE_CLAIM_COST
  const isCrmActivity = sectionData.resourceCode === ANALYTICS_RESOURCE.CRM_ACTIVITY;
  const isFoldConversion = sectionData.resourceCode === ANALYTICS_RESOURCE.FOLD_CONVERSIONS;
  const isInactiveMemberEngagement = sectionData.resourceCode === ANALYTICS_RESOURCE.FOLD_INACTIVE_ENGAGEMENT;

  const isDPCUtilization = sectionData.resourceCode === ANALYTICS_RESOURCE.DPC_STRATIFICATION

  const isDashboardAssign = props.reusableData?.isDashboardAssign;
  const actionText = 'create a form';

  const isAvgRatingPresent = () => {
    let isAvgRating = false;
    if (stateData?.resourceData?.scoreData?.allContactMaxScore && stateData?.resourceData?.scoreData?.allContactScore) {
      const avgRating = parseInt(((stateData?.resourceData?.scoreData?.allContactScore / stateData?.resourceData?.scoreData?.allContactMaxScore) * 100) as any);
      if (avgRating > 1) {
        isAvgRating = true;
      }
    }
    return isAvgRating;
  }
  const dateFilter = getDateRangeFilterFromAppliedFilter(appliedFilter)
  const subHeading = sectionData.subHeading
    ? sectionData.subHeading
    : '';
  const formattedResourceData: any = getFormattedUtilizationWidgetData(stateData.resourceData?.result, dateFilter , sectionData?.resourceCode || '', employerEnrollmentData || {} as IContactMembershipsData, isAccountDashboard);
  const onActionClick = (code: string, data?: any) => {
    switch (code) {
      case WIDGET_ACTION_CODE.RESET_LOCATION_FILTER:
        const filteredWidgetFilter = stateData.selectedAppliedFilter.filter((item: any) => {
          return item.filterCode != 'LOCATION'
        })
        setStateData(prev => {
          return {
            ...prev,
            selectedAppliedFilter: filteredWidgetFilter,
            filterValueString: '',
            filterValueName: []
          }
        })
        getSectionByResourceCode();
        break;
      default:
        props.onActionClick?.(code, data)
        break;
    }
  }
  const getTotalCountForLegend = (dataList1: any[], dataList2?: any[]) => {
    const res: any = {};
    dataList1?.forEach(function (el: any) {
      const type = el?.type || el?.type1 || el?.type2;
      const value = el?.count || el?.value || el?.value1 || el?.value2;
      res[type] = (res[type])
        ? res[type] += +value
        : +value;
    });
    dataList2?.forEach(function (el: any) {
      const type = el?.type || el?.type1 || el?.type2;
      const value = el?.count || el?.value || el?.value1 || el?.value2;
      res[type] = (res[type])
        ? res[type] += +value
        : +value;
    });
    return Object.keys(res).map(function (el) {
      return {type: el, count: res[el]};
    });
  }
  const graphYAxisTitle = getYAxisTitleByResourceCode(sectionData.resourceCode || '')
  const graphXAxisTitle = getXAxisTitleByResourceCode(sectionData.resourceCode || '')
  const getBarChartViewByType = () => {
    let ele = <></>;
    let multipleLineconfigGroup: LineConfig;
    let dualLineConfigGroup: DualAxesConfig;
    let containerWidth = 400;
    if (containerRef?.current?.offsetWidth) {
      containerWidth = parseInt((containerRef?.current?.offsetWidth) as any) - 64;
    }
    switch (sectionData.currentDisplayType) {
      case DISPLAY_TYPE.MULTIPLE_LINE_CHARTS:
        const yAxisMaxValue = getMaxYAxisValueForLineCharts(formattedResourceData)
        multipleLineconfigGroup = {
          data: formattedResourceData as any,
          xField: 'month',
          yField: 'value',
          seriesField: 'type',
          height: 240,
          width: containerWidth,
          tooltip: {
            formatter: (data) => {
              const isCost = COST_TYPE_WORDS.find((typeValue) => {
                const typeName = data.type.toLowerCase();
                return typeName.includes(typeValue)
              })
              let value = data.value;
              if (isCost) {
                value = intl.formatNumber(value, {
                  style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })
              }
              if (isUserSatisfaction || isCustomerSatisfaction || isEmployerSatisfaction) {
                value = `${value}%`
              }
              return {
                name: data.type?.replace(/^[a-z]|[A-Z]/g, (c: any, i: any) => (i ? " " : "") + c.toUpperCase()).replace('Amount', ''),
                value: value
              }
            },
          },
          yAxis: {
            label: {
              formatter: (text) => {
                let value: any = text
                if (sectionData.resourceCode === ANALYTICS_RESOURCE.ED_UTILIZATION_AMOUNT || isMembershipGrowth) {
                  value = intl.formatNumber(value, {
                    style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })
                }
                if (isUserSatisfaction || isCustomerSatisfaction || isEmployerSatisfaction) {
                  value = `${value}%`
                }
                return value
              },
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              }
            },
            title: {
              text: graphYAxisTitle.title,
              description: graphYAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              }
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              }
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            },
            ...(yAxisMaxValue)
          },
          xAxis: {
            title: {
              text: graphXAxisTitle.title,
              description: graphXAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              },
              offset: 60
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              }
            },
            label: {
              offset: 20,
              rotate: -1.1,
              style: {
                fontSize: 9,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          color: graphColorList,
          legend: {
            itemSpacing: 3,
            itemName: {
              formatter(text) {
                const legendText = text
                  ?.replace(
                    /^[a-z]|[A-Z]/g,
                    (c: any, i: any) => (i ? ' ' : '') + c.toUpperCase()
                  )
                  .replace('Amount', '');
                return `${legendText}`.toLocaleUpperCase()
              },
            },
            position: 'top-right',
            layout: 'horizontal',
            marker: {
              symbol: 'circle',
              style(style) {
                return {
                  ...style,
                  fill: style.stroke,
                  stroke: null,
                  r: 4
                }
              },
            },
          },
          lineStyle: {
            lineWidth: 1,
          },
          point: {
            shape: ({type}) => {
              return 'circle';
            },
          },
          onReady: (plot: any) => {
            plot.on('plot:click', (args: any) => {
              if (args?.data?.data?.type) {
                onReady(args);
              }
            });
          },

        };
        ele = <Line {...multipleLineconfigGroup} />;
        break;
      case DISPLAY_TYPE.DUAL_MULTIPLE_LINE_CHARTS:
        dualLineConfigGroup = {
          data: [formattedResourceData[0], formattedResourceData[1]] as any,
          xField: 'month',
          yField: ['value1', 'value2'],
          geometryOptions: [
            {
              geometry: 'line',
              seriesField: 'type1',
              point: {
                shape: () => {
                  return 'circle';
                },
              },
              color: graphColorList,
              lineStyle: {
                lineWidth: 1
              },
            },
            {
              geometry: 'line',
              seriesField: 'type2',
              point: {
                shape: () => {
                  return 'circle';
                },
              },
              color: graphColorList,
              lineStyle: {
                lineWidth: 1
              }
            },
          ],
          height: 240,
          width: containerWidth,
          tooltip: {
            formatter: (data) => {
              const name = data.type1 || data.type2
              let value = data.value1 || data.value2;
              const isCost = COST_TYPE_WORDS.find((typeValue) => {
                const typeName = name ? name.toLowerCase() : ''
                return typeName.includes(typeValue)
              })
              if (isCost) {
                value = intl.formatNumber(value || 0, {
                  style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })
              }
              const enrolmentDate = (data.type2 === 'employerEnrollmentDate' || data.type1 === 'employerEnrollmentDate') ? data.month : '';
              return {
                name: name?.replace(/^[a-z]|[A-Z]/g, (c: any, i: any) => (i ? " " : "") + c.toUpperCase()).replace('Amount', ''),
                value: enrolmentDate ? enrolmentDate : value,
              }
            },
          },
          xAxis: {
            title: {
              text: graphXAxisTitle.title,
              description: graphXAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              },
              offset: 60
            },
            label: {
              offset: 20,
              rotate: -1.1,
              style: {
                fontSize: 9,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          yAxis: [
            {
              label: {
                formatter: (text: any, item) => {
                  const axisLabelText = intl.formatNumber(text || 0, {
                    style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })
                  return `${axisLabelText}`
                },
                style: {
                  fontSize: 12,
                  fontWeight: 400,
                  fill: graphMetaDataColor.axisTextColor
                }
              },
              title: {
                text: graphYAxisTitle.title,
                description: graphYAxisTitle.description,
                style: {
                  fill: graphMetaDataColor.axisTextColor
                }
              },
              line: {
                style: {
                  stroke: '#F2F4F7',
                },
              },
              grid: {
                alignTick: true,
                line: {
                  type: 'line',
                  style: {
                    stroke: '#F2F4F7'
                  }
                },
                closed: true
              }
            }, {
              label: {
                formatter: (text, item) => {
                  const axisLabelText = `${text}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
                  return `${axisLabelText}`
                },
                style: {
                  fontSize: 12,
                  fontWeight: 400,
                  fill: graphMetaDataColor.axisTextColor
                }
              },
              title: {
                text: 'Number of  Members',
                description: graphYAxisTitle.description,
                style: {
                  fill: graphMetaDataColor.axisTextColor
                }
              },
              line: {
                style: {
                  stroke: '#F2F4F7',
                },
              },
              grid: {
                alignTick: true,
                line: {
                  type: 'line',
                  style: {
                    stroke: '#F2F4F7'
                  }
                },
                closed: true
              }
            }],
          legend: {
            itemSpacing: 3,
            itemName: {
              formatter(text) {
                const legendText = text
                  ?.replace(
                    /^[a-z]|[A-Z]/g,
                    (c: any, i: any) => (i ? ' ' : '') + c.toUpperCase()
                  )
                  .replace('Amount', '');
                return `${legendText}`.toLocaleUpperCase()
              },
            },
            layout: 'horizontal',
            position: 'top-right',
            marker: {
              symbol: 'circle',
              style(style) {
                return {
                  ...style,
                  fill: style.stroke,
                  stroke: null,
                  r: 4
                }
              },
            },
          },
          onReady: (plot: any) => {
            plot.on('plot:click', (args: any) => {
              if (args?.data?.data?.type || args?.data?.data?.type1 || args?.data?.data?.type2) {
                onReady(args);
              }
            });
          },
        };
        ele = <DualAxes {...dualLineConfigGroup} />;
        break;
      case DISPLAY_TYPE.MULTIPLE_LINE_CHARTS_WITH_SLIDER:
        multipleLineconfigGroup = {
          data: formattedResourceData as any,
          xField: 'month',
          yField: 'value',
          seriesField: 'type',
          slider: {
            start: 0,
            end: 1,
          },
          height: 240,
          width: containerWidth,
          tooltip: {
            formatter: (data) => {
              const isCost = COST_TYPE_WORDS.find((typeValue) => {
                const typeName = data.type.toLowerCase();
                return typeName.includes(typeValue)
              })
              let value = data.value;
              if (isCost) {
                value = intl.formatNumber(value, {
                  style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })
              }
              if (isUserSatisfaction || isCustomerSatisfaction || isEmployerSatisfaction) {
                value = `${value}%`
              }
              return {
                name: data.type?.replace(/^[a-z]|[A-Z]/g, (c: any, i: any) => (i ? " " : "") + c.toUpperCase()).replace('Amount', ''),
                value: value
              }
            },
          },
          yAxis: {
            label: {
              formatter: (text: any, item) => {
                const axisLabelText = intl.formatNumber(text || 0, {
                  style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })
                return `${axisLabelText}`
              },
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              }
            },
            title: {
              text: graphYAxisTitle.title,
              description: graphYAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              }
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              }
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          xAxis: {
            tickCount: 5,
            title: {
              text: graphXAxisTitle.title,
              description: graphXAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              },
              offset: 60
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              }
            },
            label: {
              offset: 20,
              rotate: -1.1,
              style: {
                fontSize: 9,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            }
          },
          color: graphColorList,
          legend: {
            itemSpacing: 3,
            itemName: {
              formatter(text) {
                const legendText = text
                  ?.replace(
                    /^[a-z]|[A-Z]/g,
                    (c: any, i: any) => (i ? ' ' : '') + c.toUpperCase()
                  )
                  .replace('Amount', '');
                return `${legendText}`.toLocaleUpperCase()
              },
            },
            position: 'top-right',
            layout: 'horizontal',
            marker: {
              symbol: 'circle',
              style(style) {
                return {
                  ...style,
                  fill: style.stroke,
                  stroke: null,
                  r: 4
                }
              },
            },
          },
          lineStyle: {
            lineWidth: 1,
          },
          point: {
            shape: ({type}) => {
              return 'circle';
            },
          },
          onReady: (plot: any) => {
            plot.on('plot:click', (args: any) => {
              if (args?.data?.data?.type) {
                onReady(args);
              }
            });
          },

        };
        ele = <Line {...multipleLineconfigGroup} />;
        break;
    }
    return ele;
  };

  const legendMap: {[index: string]: {[innerIndex: string]: string}} = {
    [ANALYTICS_RESOURCE.CRM_ACTIVITY]: {
      'message': 'Messages',
      'task': 'Completed Tasks',
      'form_response': 'Completed Forms',
      'video_call': 'Video Calls',
      'call': 'Voice Calls'
    }
  };

  const onReady = (data: any) => {
    if(data?.data?.data?.value === 0 || data?.data?.data?.count === 0) {
      return;
    }
    let filterData = {} as any;
    const fromDate = getFormattedDateStringInTimeZone(
      data?.data?.data?.date,
      `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
      MOMENT_DATE_CONST.START_OF
    );
    const toDate = getFormattedDateStringInTimeZone(
      data?.data?.data?.date,
      `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
      MOMENT_DATE_CONST.END_OF
    );
    const dashboardDateRangeFilterObj = appliedFilter?.find(item => {
      return item.filterCode === "DATE_RANGE"
    })
    const dashboardFromDate =  getFormattedDateStringInTimeZone(
      dashboardDateRangeFilterObj?.filterValue?.fromDate || dashboardDateRangeFilterObj?.filterValue?.currentDateRange?.fromDate || '',
      `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
      MOMENT_DATE_CONST.START_OF
    );
    const dashboardToDate =  getFormattedDateStringInTimeZone(
      dashboardDateRangeFilterObj?.filterValue?.toDate || dashboardDateRangeFilterObj?.filterValue?.currentDateRange?.toDate || '',
      `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
      MOMENT_DATE_CONST.END_OF
    );
    const type: string = data?.data?.data?.type?.replace(
      /^[a-z]|[A-Z]/g,
      (c: any, i: any) => (i ? ' ' : '') + c.toUpperCase()
    )
      .replace('Amount', '');;
    const dataType = capitalizeText(type);
    const dateOrMonth = data?.data?.data?.month || data?.data?.data?.date
    switch (sectionData.resourceCode) {
      case ANALYTICS_RESOURCE.CLAIMS:
      case ANALYTICS_RESOURCE.TOTAL_CLAIM_COST:
        filterData = [
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
            count: data?.data?.data?.value || data?.data?.data?.value1 || data?.data?.data?.value2,
          },
        ];
        Object.entries(sectionData.appliedFilter)?.forEach(([key, value]: any) => {
          const temp = {
            filterCode: key,
            filterValue: value
          }
          filterData.push(temp)
        })
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.FOLD_CONVERSIONS:
      case ANALYTICS_RESOURCE.FOLD_INACTIVE_ENGAGEMENT:
      case ANALYTICS_RESOURCE.CRM_ACTIVITY:
        const convType: string = data?.data?.data?.code;
        filterData = [
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
            count: data?.data?.data?.value,
          },
          {
            filterCode: 'MESSAGE_TYPE',
            filterValue: convType
          }
        ];
        Object.entries(sectionData.appliedFilter)?.forEach(([key, value]: any) => {
          const temp = {
            filterCode: key,
            filterValue: value
          }
          filterData.push(temp)
        })
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION:
      case ANALYTICS_RESOURCE.USER_SATISFACTION:
      case ANALYTICS_RESOURCE.EMPLOYER_SATISFACTION:
        const formId = sectionData?.additionalAttributes?.formId;
        filterData = [
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
            count: data?.data?.data?.value,
          },
          {
            filterCode: 'FORM_ID',
            filterValue: formId
          }
        ];
        Object.entries(sectionData.appliedFilter)?.forEach(([key, value]: any) => {
          const temp = {
            filterCode: key,
            filterValue: value
          }
          filterData.push(temp)
        })
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.EMPLOYEE_COUNT:
      case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE:
        const type: string = data?.data?.data?.type;

        let loggedInList: string[] = [];
        if (type?.toLocaleLowerCase() === 'employee') {
          loggedInList = ['employee']
        } else if (type?.toLocaleLowerCase() === 'dependent') {
          loggedInList = ['father', 'spouse', 'child']
        } else if (type?.toLocaleLowerCase() === 'oneoff') {
          loggedInList = ['one off']
        } else if (type?.toLocaleLowerCase() === 'selfsponsored' || type?.toLocaleLowerCase() === 'self-sponsored') {
          loggedInList = ['self-sponsored']
        }

        filterData = [
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
          },
          {
            filterCode: 'EMPLOYEE_TYPE',
            filterValue: loggedInList,
            count: data?.data?.data?.value
          },
        ];
        filterData = [...filterData, ...stateData.selectedAppliedFilter]
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.ENROLLED_FOR_CHRONIC_CARE:
        filterData = [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DASHBOARD_DATE_RANGE,
            filterValue: {
              fromDate: dashboardFromDate,
              toDate: dashboardToDate,
            },
          },
        ];
        if (data?.data?.data?.code) {
          filterData.push({
            filterCode: EMPLOYER_REPORT_FILTER_CODES.TOP_CHRONIC_CONDITION,
            filterValue: data?.data?.data?.code,
          });
        }
        Object.entries(sectionData.appliedFilter)?.forEach(([key, value]: any) => {
          const temp = {
            filterCode: key,
            filterValue: value
          }
          filterData.push(temp)
        })
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.ED_UTILIZATION_AMOUNT:
        filterData = [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DASHBOARD_DATE_RANGE,
            filterValue: {
              fromDate: dashboardFromDate,
              toDate: dashboardToDate,
            },
          },
        ];
        const filterObj = {
          filterCode: 'CATEGORY',
          filterValue: data?.data?.data?.type?.replace('Amount', ''),
          count: data?.data?.data?.value
        };
        filterData.push(filterObj);
        filterData = stateData.selectedAppliedFilter.length ? [...filterData, ...stateData.selectedAppliedFilter] : filterData
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.MEMBERSHIP_REVENUE_GROWTH:
        filterData = [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
          },
        ]
        filterData = stateData.selectedAppliedFilter.length ? [...filterData, ...stateData.selectedAppliedFilter] : filterData
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, date: dateOrMonth, count: data?.data?.data?.value, type: dataType});
        break;

      case ANALYTICS_RESOURCE.ALL_CLAIM_DATA:
        filterData = [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.CLAIM_MEMBER_CODE,
            filterValue: data?.data?.data?.filterCode,
          },
        ];
        filterData = stateData.selectedAppliedFilter.length ? [...filterData, ...stateData.selectedAppliedFilter] : filterData
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData});
        break;
      default:
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value});
        break;
    }
  };

  const barChartView = useMemo(() => {
    return getBarChartViewByType()
  }, [stateData.loading])
  const getSectionByResourceCode = async (appliedFilter?: IAppliedFilter[], SelectedFormId?: string) => {
    let formId = '';
    if ((sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION || sectionData.resourceCode === ANALYTICS_RESOURCE.USER_SATISFACTION
      || sectionData.resourceCode === ANALYTICS_RESOURCE.EMPLOYER_SATISFACTION)
      && (SelectedFormId || stateData.formId || sectionData?.additionalAttributes?.formId)) {
      formId = SelectedFormId || stateData.formId || sectionData?.additionalAttributes?.formId;
    }
    const formattedAppliedFilter = ANALYTICS_RESOURCE.CLAIMS === sectionData.resourceCode ? getFormattedAppliedFilter(sectionData.appliedFilter) : []
    let filterList: any = appliedFilter?.length ? appliedFilter : stateData.appliedFilter
    filterList = [...filterList, ...formattedAppliedFilter]
    if (hintMembershipDisabled) {
      filterList.push({
        filterCode: EMPLOYER_REPORT_FILTER_CODES.MEMBERSHIP,
        filterValue: hintMembershipDisabled
      })
    }
    const response = await getSectionDataByResourceCode(
      employerId || '',
      sectionData.resourceCode || '',
      filterList,
      formId,
      isAccountDashboard,
      memberType,
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          resourceData: [],
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data && isWidgetDataEmpty(response?.data)) {
      const containerHight = containerRef?.current?.offsetHeight;
      const titleWrapperHight = titleRef?.current?.offsetHeight;
      const heightVariable = (props.sectionData?.subHeading?.length || 0) > 100 ? 20 : 10
      const graphWrapper = containerHight - titleWrapperHight - heightVariable - 50
      const resourceData = response.data;
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
          graphWrapperHeight: graphWrapper
        };
      });
    } else {
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: [] as IResource[],
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };
  const controller = new AbortController();
  useEffect(() => {
    if (getTenantForDemo()) {
      let resourceData: any =
        sectionData.resourceCode == ANALYTICS_RESOURCE.ED_UTILIZATION_AMOUNT
          ? ED_UTILIZATION_AMOUNT_DATA
          : sectionData.resourceCode == ANALYTICS_RESOURCE.ED_UTILIZATION_AMORTIZATION
            ? ED_UTILIZATION_AMORTIZATION_DATA
            : sectionData.resourceCode == ANALYTICS_RESOURCE.CLAIMS
              ? CLAIMS_DATA
              : sectionData.resourceCode === ANALYTICS_RESOURCE.TOTAL_CLAIM_COST
                ? TEMP_TOTAL_CLAIMS_COST
                : isEngaged ? TEMP_ENGAGE_FOR_CARE :
                  isEmployeeCount ? EMPLOYEE_COUNT :
                    isCustomerSatisfaction ? PATIENT_SATISFACTION :
                      isUserSatisfaction ? USER_SATISFACTION :
                        isMembershipGrowth ? MEMBERSHIP_REVENUE_GROWTH :
                          isEmployerSatisfaction ? EMPLOYER_SATISFACTION :
                            isIpClaimCost || isUrgentCareClaimCost ? IP_CLAIM_COST_DATA :
                              isCrmActivity ? CRM_ACTIVITY :
                                isFoldConversion ? FOLD_CONVERSIONS :
                                  isDPCUtilization ? DPC_UTILIZATION :
                                    isInactiveMemberEngagement ? FOLD_INACTIVE_ENGAGEMENT : [];
      const containerHight = containerRef?.current?.offsetHeight;
      const titleWrapperHight = titleRef?.current?.offsetHeight;
      const heightVariable = (props.sectionData?.subHeading?.length || 0) > 100 ? 30 : 10
      const graphWrapper = containerHight - titleWrapperHight - heightVariable
      if (resourceData?.length === 0) {
        resourceData = getDemoDataForEmployerReportCurrentSection(props.sectionData);
      }
      setTimeout(() => {
        setStateData((prev) => {
          return {
            ...prev,
            resourceData: resourceData,
            loading: false,
            graphWrapperHeight: graphWrapper
          }
        })
      }, 1000)
      return;
    }
    getSectionByResourceCode();
    return () => controller?.abort();
  }, [sectionData.id]);
  useEffect(() => {
    if (props.filterData?.resourceCode == sectionData.resourceCode) {
      const appliedFilter = stateData.appliedFilter?.length ? [...stateData.appliedFilter] : [];
      const selectedFilter = {
        filterCode: props.filterData?.filterCode,
        filterValue: props.filterData?.filterValue
      }
      let filterValueString = '';
      props.filterData?.filterValueName?.forEach((item: string, index: number) => {
        if (index < 2) {
          return
        }
        filterValueString = filterValueString + (filterValueString.length ? " | " : '') + item
      })
      setStateData(prev => {
        return {
          ...prev,
          filterValueName: props.filterData?.filterValueName,
          filterValueString: filterValueString,
          selectedAppliedFilter: [selectedFilter]
        }
      })
      appliedFilter.push(selectedFilter);
      getSectionByResourceCode(appliedFilter);
    }
    return () => controller?.abort();
  }, [props.filterData?.filterValue])
  const getRefreshButtonOnLocalEnv = () => {
    if (window.location.host.includes('localhost')) {
      return (
        <Pressable
          style={{marginHorizontal: 3}}
          onPress={() => {
            setStateData(prev => {
              return {
                ...prev,
                filterValueName: [],
                filterValueString: ''
              }
            })
            getSectionByResourceCode();
          }}
        >
          <Tooltip label="Refresh">
            <RestSvgIcons />
          </Tooltip>
        </Pressable>
      )
    }
    return <></>;
  }
  return (
    <div ref={containerRef} className="widgetBoxShadow">
      <div ref={titleRef}>
        <WidgetHeader isPrintPreview={props.isPrintPreview} isPrintDownload={props.isPrintDownload} refreshButtonOnLocalEnv={getRefreshButtonOnLocalEnv()} sectionData={sectionData} onActionClick={onActionClick} />
      </div >
      {
        stateData.loading ? (
          <Skeleton style={{padding: 40}} active={true} />
        ) : (
          <>
            {stateData.resourceData
              && (stateData.resourceData?.result?.length
                || stateData.resourceData?.result?.contactData?.length
              ) ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  height: '75%'
                }}
              >
                <main style={{alignSelf: 'center', width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', height: '100%'}}>
                  <main
                    style={{padding: '10px 0px'}}
                    className={
                      (stateData.resourceData.result?.length || stateData.resourceData?.result?.contactData?.length) &&
                        props.isPrintPreview
                        ? 'canvas-wrapper'
                        : ''
                    }
                  >
                    {barChartView}
                  </main>
                </main>
              </div>
            ) : (
              <>

                {!isEmployer && !isFormAssigned && ((isNPSForm || isCustomerSatisfaction || isUserSatisfaction ||
                  isEmployerSatisfaction && isDashboardAssign) && !isAvgRatingPresent()) ? (
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div
                      onClick={() => {
                        setStateData(prev => {
                          return {
                            ...prev,
                            selectedView: WIDGET_ACTION_CODE.FORM_LIST_VIEW
                          }
                        })
                      }}
                      style={{
                        fontSize: 12,
                        color: Colors.primary['400'],
                        fontWeight: 400,
                        cursor: 'pointer',
                        textDecorationLine: 'underline',
                        textDecorationColor: Colors.primary['400'],
                        padding: '15px 1px 15px 23px'
                      }}
                    >
                      {actionText}
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        color: Colors.Custom.Gray400,
                        fontWeight: 400,
                        marginLeft: 3,
                      }}
                    >
                      or link existing to get feedback.
                    </div>
                  </div>

                ) : (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      height: '60%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <NodataViewTypeOne viewCode={'TEXT_ICON'} />
                  </div>
                )}
              </>
            )}
          </>
        )}


      {stateData.selectedView === WIDGET_ACTION_CODE.FORM_LIST_VIEW ? (
        <FormListView
          sectionData={sectionData}
          formId={stateData.formId}
          onClose={(args?: any) => {
            if (args?.code === COMMON_ACTION_CODES.COMPLETED) {
              setStateData(prev => {
                return {
                  ...prev,
                  formId: args.formId,
                  selectedView: '',
                  loading: true
                }
              })
              getSectionByResourceCode([], args.formId)
            }
            setStateData((prev) => {
              return {
                ...prev,
                selectedView: ''
              };
            });
          }}
        />
      ) : (
        <></>
      )}
    </div>

  );
}

export default UtilizationWidgetsView;
