import { StyleSheet } from 'react-native';
import { Colors } from '../../../../styles/Colors';

export const styles = StyleSheet.create({
  spinnerStyle: {
    margin: '0 auto',
    color: 'black',
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0,
  },
  formElement: {
    paddingTop: 10,
    paddingBottom: 12,
  },
  formLabel: {
    marginLeft: 4,
  },
  margin0: {
    margin: 0
  },
  dividerView: {
    // backgroundColor: Colors.Custom.Gray200,
    height: 1,
    width: '100%',
    marginTop: 16,
    marginBottom: 24,
  },
  marginLeft12: {
    marginLeft: 12,
  },
  flexDirectionRow: {
    flexDirection: 'row',
  },
  box16: {
    width: 16,
    height: 16,
  },
  ml12mr16: {
    marginLeft: 12,
    marginRight: 16,
  },
  mt16: {
    marginTop: 16,
  },
  fullWidthFdRowAiCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  h36Br4: {
    height: 36,
    borderRadius: 4,
  },
  ml12: {
    marginLeft: 12,
  },
  mt3: {
    marginTop: 3,
  },
  mt12: {
    marginTop: 12,
  },
  ml12mb8: {
    marginLeft: 12,
    marginBottom: 8,
  },
  mb40ml12: {
    marginBottom: 40,
    marginLeft: 12,
  },
  mt44ml12: {
    marginTop: 44,
    marginLeft: 12,
  },
  ml6mr4: {
    marginLeft: 6,
    marginRight: 4,
  },
  fdRowAiCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ml10: {
    marginLeft: 10,
  },
  fdRowAiCenterMt12: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
  },
  fdRowAiCenterMt44: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 44,
  },
  br12: {
    borderRadius: 12,
  },
  fdRowAiCenterMt20: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  fdColumnAiCenter: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  cGray500Mt30TaCenter: {
    color: Colors.Custom.Gray500,
    marginTop: 30,
    textAlign: 'center',
  },
  fdRowAiCenterJcCenterMt24: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 24,
  },
  mb16: {
    marginBottom: 16,
  },

});
