import {gql} from '@apollo/client';

export const GET_ANALYTIC_ID_BY_CODE = gql`
  query GET_ANALYTIC_ID_BY_CODE($code: String!) {
    analyticalResources(where: {code: {_eq: $code}, isDeleted: {_eq: false}}) {
      id
      code
    }
  }
`;
export const GET_COST_TEMPLATES = gql`
  query GET_COST_TEMPLATES(
    $where: cost_saving_template_bool_exp!
    $offset: Int!
    $limit: Int!
  ) {
    costSavingTemplates(
      where: $where
      order_by: {updatedAt: desc}
      offset: $offset
      limit: $limit
    ) {
      costCategoryId
      name
      createdAt
      updatedAt
      description
      id
      analyticalResourceId
      isDefault
      marketCostSavingTemplateEmployer_aggregate {
        aggregate {
          count
        }
      }
      ourCostSavingTemplateEmployer_aggregate {
       aggregate {
          count
        }
    }
    }
    costSavingTemplatesAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CPT_BY_TEMPLATE = gql`
  query GET_CPT_BY_TEMPLATE(
    $where: cost_saving_template_cpt_bool_exp!
    $limit: Int
    $offset: Int
  ) {
    costSavingTemplateCpts(
      order_by: {updatedAt: desc, price: desc}
      where: $where
      limit: $limit
      offset: $offset
    ) {
      id
      price
      name
      accountCpt {
        code
        name
        id
        analyticalResourceId
        modifier
      }
      templateId
      accountCptId
    }
    costSavingTemplateCptsAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_TEMPLATE_BY_ID = gql`
  query GET_TEMPLATE_BY_ID($id: uuid!) {
    costSavingTemplates(where: {id: {_eq: $id}}) {
      name
      description
      id
      analyticalResourceId
      costCategoryId
    }
  }
`;

export const GET_CPT_ANALYTICS_RESOURCE = gql`
  query GET_CPT_ANALYTICS_RESOURCE($analyticalResourceId: uuid!, $accountUuid: uuid!) {
    accountCpts(
      where: {
        analyticalResourceId: {_eq: $analyticalResourceId}
        isDeleted: {_eq: false}
        accountUuid: {_eq: $accountUuid}
      }
    ) {
      code
      id
      name
      modifier
    }
  }
`;

export const DEFAULT_CPT_BY_ANALYTICS_COST_TYPE = gql`
  query DEFAULT_CPT_BY_ANALYTICS_COST_TYPE(
    $where: cost_saving_template_bool_exp!
  ) {
    costSavingTemplates(where: $where) {
      costSavingTemplateCpt(where: {isDeleted: {_eq: false}, accountCpt: {isDeleted: {_eq: false}}}) {
        id
        price
        accountCpt {
          code
        }
      }
    }
  }
`;
export const GET_ALL_CPT_BY_TEMPLATE = gql`
  query GET_ALL_CPT_BY_TEMPLATE(
    $where: cost_saving_template_cpt_bool_exp!
  ) {
    costSavingTemplateCpts(
      order_by: {updatedAt: desc}
      where: $where
    ) {
      id
      price
      accountCpt {
        code
        name
        modifier
      }
    }
  }
`;

export const COST_TEMPLATE_ID_NAME = gql`
  query COST_TEMPLATE_ID_NAME(
    $accountUuid: uuid!
    $analyticalResourceId: uuid!
    $costCategoryId: uuid!
  ) {
    costSavingTemplates(
      order_by: {updatedAt: desc, isDefault: desc}
      where: {
        accountUuid: {_eq: $accountUuid}
        analyticalResourceId: {_eq: $analyticalResourceId}
        isDeleted: {_eq: false}
        costCategoryId: {_eq: $costCategoryId}
      }
    ) {
      id
      name
      isDefault
    }
  }
`;

export const CPT_LIST_BY_CODES_IN = gql`
  query CPT_LIST_BY_CODES_IN(
    $codes: [String!]!
    $accountUuid: uuid!
    $templateId: uuid!
  ) {
    costSavingTemplateCpts(
      where: {
        accountUuid: {_eq: $accountUuid}
        accountCpt: {
          code: {_in: $codes}
          isDeleted: {_eq: false}
          accountUuid: {_eq: $accountUuid}
        }
        templateId: {_eq: $templateId}
        isDeleted: {_eq: false}
      }
    ) {
      id
      price
      accountCpt {
        code
        name
      }
    }
  }
`;

export const GET_TEMPLATE_FOR_CLONE_BY_ID = gql`
  query GET_TEMPLATE_FOR_CLONE_BY_ID($id: uuid!) {
    costSavingTemplates(where: {id: {_eq: $id}}) {
      name
      description
      costCategoryId
      analyticalResourceId
      costSavingTemplateCpt {
        accountCpt {
          code
          name
          modifier
          description
        }
        price
      }
    }
  }
`;
export default {
  GET_ANALYTIC_ID_BY_CODE,
  GET_COST_TEMPLATES,
  GET_CPT_BY_TEMPLATE,
  GET_TEMPLATE_BY_ID,
  GET_CPT_ANALYTICS_RESOURCE,
  DEFAULT_CPT_BY_ANALYTICS_COST_TYPE,
  GET_ALL_CPT_BY_TEMPLATE,
  COST_TEMPLATE_ID_NAME,
  CPT_LIST_BY_CODES_IN,
  GET_TEMPLATE_FOR_CLONE_BY_ID
};
