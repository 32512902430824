import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles';

export const styles = StyleSheet.create({
  centerAlign: {
    alignItems: 'center',
  },

  flexStart: {
    alignItems: 'flex-start',
  },

  tinyDetail: {
    color: Colors.Custom.DataKeyColor,
    fontSize: 12,
  },

  sendingWidth: {
    width: 60,
  },

  sendingStyle: {
    borderRadius: 30,
    backgroundColor: '#D4C6E7',
  },
});
