import React from 'react';

interface IEmptyIvrStateProps {
  height?: number;
  width?: number;
}

const EmptyIvrState = (props: IEmptyIvrStateProps) => {
  const height = props.height ? props.height.toString() : '100';
  const width = props.width ? props.width.toString() : '108';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 108 100"
    >
      <g clipPath="url(#clip0_20003964_55245)">
        <path
          fill="#FCFDFD"
          d="M22.384 94.864h63.219a6.93 6.93 0 004.695-1.836 53.67 53.67 0 0017.061-38.83C107.629 24.502 83.639.07 54.129 0 24.604-.07.643 23.99.643 53.685a54.077 54.077 0 004.439 21.472 53.491 53.491 0 0012.635 17.885 6.816 6.816 0 004.681 1.822h-.014z"
        ></path>
        <path
          fill="#F2F4F7"
          d="M87.624 53.77c0-18.839-15.495-34.05-34.434-33.608-18.184.427-32.854 15.523-32.797 33.722.042 14.172 8.864 26.266 21.3 31.161 3.102 1.224 5.82 3.216 7.798 5.89l2.845 3.843c.826 1.124 2.505 1.124 3.344 0l2.846-3.842c1.978-2.675 4.695-4.682 7.797-5.891 12.479-4.909 21.3-17.06 21.3-31.275z"
        ></path>
        <path
          fill="#F2F2F2"
          d="M87.653 53.77c0 13.389-7.84 24.957-19.18 30.35 8.21-6.133 13.53-15.908 13.53-26.95 0-18.839-15.494-34.05-34.433-33.608a33.354 33.354 0 00-13.517 3.215c5.392-4.012 12.009-6.46 19.166-6.63 18.938-.456 34.434 14.77 34.434 33.608v.014z"
          opacity="0.78"
        ></path>
        <path
          fill="#E4E7EC"
          d="M41.75 94.85c-2.248-.47-4.938-.883-7.214-1.295-1.153-.214-1.68.156-2.562.683-.44-.029-.796.085-1.166.341-1.082-.37-2.42-.882-3.415-1.351-2.505-1.181-4.838-2.775-6.588-4.696-1.508-1.65-2.462-3.33-3.159-5.307-.37-1.04-.484-1.978-.612-3.031l-1.223-.213c.113 1.252.241 2.39.668 3.614.74 2.12 1.765 3.941 3.373 5.691 1.85 2.02 4.31 3.714 6.943 4.952 1.025.484 2.39 1.01 3.515 1.394.07.527.327.854.797 1.11.455 1.024.825 1.608 2.162 1.907 2.22.483 4.995.796 7.186 1.323.91.213 1.963-.185 2.604-.825 1.508-1.494.725-3.842-1.324-4.269l.015-.028zM96.19 58.55c.669-3.202.939-6.46.84-9.72a39.37 39.37 0 00-.84-7c-.996-4.695-2.704-8.921-5.208-12.99-4.852-7.87-12.436-14.101-21.03-17.417a44.335 44.335 0 00-14.2-2.96A44.14 44.14 0 0041.01 10.3a43.405 43.405 0 00-12.763 6.233 36.092 36.092 0 00-2.632 2.034 40.759 40.759 0 00-2.59 2.362 42.47 42.47 0 00-4.453 5.137c-5.834 8.01-8.609 17.686-7.812 27.547.128 1.608.37 3.201.683 4.795.142.711-.2 1.451-.94 1.65-.668.185-1.493-.227-1.65-.939-2.205-10.77-.298-22.111 5.45-31.502 5.222-8.538 13.261-15.097 22.553-18.711 9.647-3.756 20.589-4.14 30.506-1.181 9.619 2.874 18.128 8.879 24.047 16.975 6.132 8.423 9.092 19.01 8.139 29.382a45.209 45.209 0 01-.769 5.165c-.142.712-.981 1.124-1.65.94-.74-.2-1.082-.94-.94-1.651v.014z"
        ></path>
        <path
          fill="#E4E7EC"
          d="M87.723 28.741a39.808 39.808 0 00-1.707-2.361l.625.81a44.605 44.605 0 00-7.91-7.882l.81.626a45.226 45.226 0 00-9.917-5.791l.953.398a45.891 45.891 0 00-11.724-3.187l1.067.142a47.598 47.598 0 00-12.237 0l1.067-.142a46.05 46.05 0 00-11.724 3.187l.953-.398a45.555 45.555 0 00-9.917 5.79l.81-.625a45.087 45.087 0 00-7.91 7.883l.625-.811a39.855 39.855 0 00-1.707 2.361c-.583.869-1.366 1.566-2.405 1.85-.967.27-2.234.142-3.087-.398-1.736-1.11-2.704-3.629-1.437-5.492a49.49 49.49 0 0113.104-13.162c4.995-3.415 10.757-5.89 16.662-7.214 6.588-1.466 13.318-1.565 19.949-.427 6.005 1.024 11.881 3.287 17.06 6.474 5.35 3.301 9.918 7.584 13.66 12.607.413.555.825 1.138 1.21 1.722.554.825.668 2.148.398 3.087-.256.925-.982 1.935-1.85 2.405-2.035 1.067-4.24.427-5.492-1.437l.07-.015zM11.172 78.058a287.35 287.35 0 015.165-2.547c-1.85-5.065-3.116-10.387-4.126-15.808a218.13 218.13 0 00-5.464 1.081c-1.48.285-2.547 1.936-2.291 3.643.626 4.24 1.58 8.367 3.002 12.223.57 1.55 2.305 2.148 3.714 1.422v-.014z"
        ></path>
        <path
          fill="#E4E7EC"
          d="M18.286 81.444c.598-.356 1.181-.712 1.779-1.067-3.6-8.14-5.492-16.975-7.129-25.94-.697.057-1.394.129-2.091.186-3.102.213-5.493 3.486-5.037 7.228.797 5.99 2.191 11.838 4.51 17.088 1.48 3.245 5.364 4.141 7.983 2.505h-.015z"
        ></path>
        <path
          fill="#E4E7EC"
          d="M20.805 81.657c.569-.355 1.124-.711 1.693-1.053.313-.199.47-.64.327-.996-3.159-8.537-4.909-17.217-7.086-26.166-.085-.37-.483-.655-.882-.64-.697.042-1.38.07-2.063.113a.639.639 0 00-.583.769c1.622 9.604 3.628 19.123 7.697 27.703.171.356.57.484.897.284v-.014zM96.83 78.058a287.674 287.674 0 00-5.166-2.547c1.85-5.065 3.116-10.387 4.126-15.808 1.836.342 3.657.697 5.464 1.081 1.48.285 2.547 1.936 2.291 3.643-.626 4.24-1.579 8.367-3.002 12.223-.57 1.55-2.305 2.148-3.714 1.422v-.014z"
        ></path>
        <path
          fill="#E4E7EC"
          d="M89.714 81.444c-.597-.356-1.18-.712-1.778-1.067 3.6-8.14 5.492-16.975 7.128-25.94.697.057 1.395.129 2.092.186 3.102.213 5.492 3.486 5.037 7.228-.797 5.99-2.191 11.838-4.51 17.088-1.48 3.245-5.365 4.141-7.983 2.505h.014z"
        ></path>
        <path
          fill="#E4E7EC"
          d="M87.197 81.657c-.57-.355-1.125-.711-1.694-1.053-.313-.199-.47-.64-.327-.996 3.159-8.537 4.909-17.217 7.086-26.166.085-.37.484-.655.882-.64.697.042 1.38.07 2.063.113.385.029.655.37.584.769-1.622 9.604-3.629 19.123-7.698 27.703-.17.356-.57.484-.897.284v-.014z"
        ></path>
        <path
          fill="#B692F6"
          d="M63.776 51.45a.729.729 0 00-.726.725v2.789c0 2.419-.939 4.695-2.646 6.403A8.997 8.997 0 0154 64.013a9.022 9.022 0 01-6.403-2.646 8.997 8.997 0 01-2.647-6.403v-2.79a.729.729 0 00-.725-.725.729.729 0 00-.726.726v2.789c0 2.803 1.096 5.435 3.073 7.413a10.426 10.426 0 006.702 3.045v4.169H48.41a.729.729 0 00-.726.726c0 .398.328.725.726.725h11.17a.729.729 0 00.725-.725.729.729 0 00-.726-.726h-4.866v-4.17a10.458 10.458 0 006.702-3.044 10.419 10.419 0 003.073-7.413v-2.79a.729.729 0 00-.725-.725h.014z"
        ></path>
        <path
          fill="#B692F6"
          d="M54 60.955a6.01 6.01 0 004.24-1.75c1.139-1.124 1.75-2.633 1.75-4.24v-11.17a6.011 6.011 0 00-1.75-4.24 5.962 5.962 0 00-4.24-1.75 5.986 5.986 0 00-4.24 1.75 5.986 5.986 0 00-1.75 4.24v11.17a6.01 6.01 0 001.75 4.24c1.124 1.138 2.632 1.75 4.24 1.75z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_20003964_55245">
          <path
            fill="#fff"
            d="M0 0H106.716V100H0z"
            transform="translate(.643)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyIvrState;
