import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles/Colors';

export const styles = StyleSheet.create({
  rceMboxLeft: {
    color: Colors.Custom.TextColor,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 0,
    alignSelf: 'flex-start',
  },
  rceMboxRight: {
    color: Colors.Custom.TextColor,
    shadowRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 20,
    alignSelf: 'flex-end',
  },
  rceMbox: {
    width: 350,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.Custom.BorderColor,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 3,
    flexDirection: 'column',
    marginBottom: 3,
    paddingTop: 10,
    paddingRight: 18,
    paddingBottom: 10,
    paddingLeft: 18,
  },
});
