import {Select, notification} from 'antd';
import {FormControl, HStack, InfoOutlineIcon, View, VStack, Text} from 'native-base';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Colors } from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import { ICcareTeamRoleSuggestions } from '../CareJourneyTeam/CareJourneyTeam';
import { ICareTeamRequiredRole, IJourneyDetailTag } from './Interfaces';
import useAddRoleAndPermission from '../../../UserAccess/CustomHook/useCreateRoleAndPermissions';
import { MLOV_CATEGORY } from '../../../../../constants';

const JourneyDetailTagInput = (props: {
  labelId: string;
  fullWidth?: boolean;
  titleId?: string;
  placeholderId?: string;
  className?: string;
  responsive?: boolean;
  descriptionId?: string;
  value: any[];
  careTeamRoleSuggestions?: ICcareTeamRoleSuggestions;
  careTeamRequiredRoles?: ICareTeamRequiredRole;
  resetCareTeamRoleSuggestions?: () => void;
  onChange: (value: any[]) => void;
  onClear?: () => void;
  isRequired?: boolean;
  options: {value: string; label: string}[];
}) => {

  const [accountUserRoles, setAccountUserRoles] = useState<
    {value: string; label: string}[]
  >([]);

  const {getAccountRoles} = useAddRoleAndPermission({
    fetchRoleCategory: [
      MLOV_CATEGORY.USER_ROLES,
      MLOV_CATEGORY.GLOBAL_USER_ROLES,
    ],
  });

  const fetchAllAccountRole = async () => {
    const accountRolesResponse = await getAccountRoles();
    setAccountUserRoles((accountRolesResponse||[]).map((accountRole:{id:string, roleName:string})=>{
      return {
        value: accountRole?.id,
        label: accountRole.roleName
      }
    }))
  };

  useEffect(()=>{
    fetchAllAccountRole();
  }, [])
  const intl = useIntl();
  const setDefaultJourneyRoleTag = () => {
    const selectedCareTeamRoles = props.value || [];

    const filteredSuggestedRoles = (Object.values(props.careTeamRoleSuggestions?.roles || [])).filter((role: any) => {
      if (role && role.roleId) return true;
    }) || [];
    const filteredRequiredRoles = (Object.values(props.careTeamRequiredRoles?.roles || [])).filter((role: any) => {
      if(role && role.assigneeRoleId) {
        let roleName =""
        accountUserRoles.forEach((option)=>{if(roleName.length === 0) if(option.value === role.assigneeRoleId){roleName = option.label}})
        role.name= roleName
        role.roleId=role?.assigneeRoleId;
        return true
      }
      if (role && role.roleId) return true;
    }) || [];
    (filteredSuggestedRoles) .forEach(role => {
      if (!selectedCareTeamRoles.includes(role?.roleId)) {
        selectedCareTeamRoles.push(role?.roleId)
      }
    })
    filteredRequiredRoles .forEach(role => {
      if(!selectedCareTeamRoles.includes(role?.roleId)){
        selectedCareTeamRoles.push(role?.roleId)
      }
    });

    return {
      hasSuggestedRoles: filteredSuggestedRoles?.length ? true : false,
      hasRequiredRoles: filteredRequiredRoles?.length ? true : false,
      suggestedRoles: filteredSuggestedRoles,
      requiredRoles: filteredRequiredRoles,
      selectedRoles: selectedCareTeamRoles,
    } as IJourneyDetailTag;
  };

  const [journeyCareTeamRoleTag, setJourneyCareTeam]= useState<IJourneyDetailTag>(setDefaultJourneyRoleTag())
  if (props.resetCareTeamRoleSuggestions && Object.values(props.careTeamRoleSuggestions?.roles || {})?.length) props.resetCareTeamRoleSuggestions();

  const updateJourneyRole = (deselectedRole: string) => {
    const roles =  (journeyCareTeamRoleTag.selectedRoles || []).filter(roleId => {
      return roleId !== deselectedRole;
    });
    props.onChange(roles);
    setJourneyCareTeam(prev => {
      return {
       ...prev,
       selectedRoles: roles
      }
    });
  }

  const isRequiredRolesExist = (): boolean => {
    if (props?.careTeamRequiredRoles?.roles && Object.keys(props?.careTeamRequiredRoles?.roles).length) {
      const keyArray = Object.keys(props?.careTeamRequiredRoles?.roles);
      return (keyArray || []).some(key => {
        const role = props?.careTeamRequiredRoles?.roles[key];
        return role?.roleId !== undefined;
      })
    }
    return false;
  }

  return (
    <FormControl paddingY={2}>
      <VStack alignItems="flex-start" space={1}>
        <View flex={1}>
          <VStack>
            <HStack flex={1}>
              {props?.titleId ? (
                <View
                  display={'flex'}
                  alignItems={'center'}
                  flexDirection={'row'}
                >
                  <DisplayText
                    size={'smRegular'}
                    extraStyles={{
                      color: Colors.Custom.Gray500,
                      fontWeight: '400',
                      fontSize: 14,
                      lineHeight: 16.8,
                    }}
                    textLocalId={props?.titleId}
                  />
                  {props?.isRequired && (
                    <span
                      className="required-dot red-dot-4px"
                      style={{
                        backgroundColor: Colors.FoldPixel.STATUS_ERROR,
                        width: '4px',
                        height: '4px',
                        margin: 0,
                        marginLeft: '4px'
                      }}
                    ></span>
                  )}
                </View>
              ) : (
                <>
                  <DisplayText
                    size={'mdSemibold'}
                    extraStyles={{
                      fontWeight: 500,
                      fontSize: 18,
                      fontFamily: 'Manrope',
                    }}
                    textLocalId={'careTeamRoles'}
                  />
                  {isRequiredRolesExist() && <Text color="error.500">*</Text>}
                  <Text
                    marginTop={1}
                    marginLeft={2}
                    fontSize="xs"
                    color="gray.500">
                    {`(Select the required care team for this care journey)`}
                  </Text>
                </>
              )}
            </HStack>
          </VStack>
        </View>
        <View width={props?.fullWidth ? '100%' : undefined}>
          <View minWidth={props?.fullWidth ? undefined : 600}>
            <Select
              allowClear
              mode="multiple"
              maxTagCount={props?.responsive ? "responsive" : undefined}
              filterOption={(input: string, option: any) => {
                return (option?.children || '').toLowerCase().includes(input.toLowerCase());
              }}
              disabled={!accountUserRoles?.length}
              value={accountUserRoles?.length ? journeyCareTeamRoleTag.selectedRoles : []}
              placeholder={
                props?.placeholderId
                  ? intl.formatMessage({
                      id: props?.placeholderId,
                    })
                  : 'Select a role to add in care team'
              }
              style={{height: 'auto'}}
              onSelect={(selectedValue: any) => {
                setJourneyCareTeam(prev => {
                  const prevSelectedRoles =  prev.selectedRoles || [];
                  prevSelectedRoles.push(selectedValue);
                  return {
                    ...prev,
                    selectedRoles: prevSelectedRoles
                  }
                });
                props.onChange(journeyCareTeamRoleTag.selectedRoles);
              }}
              onClear={() => {
                setJourneyCareTeam(prev => {
                  return {
                    ...prev,
                    selectedRoles: []
                  }
                });
                props?.onClear && props.onClear();
              }}
              onDeselect={(deselectedValue: any) => {
                if (journeyCareTeamRoleTag.hasRequiredRoles) {
                  const requiredRole = (Object.values(props.careTeamRequiredRoles?.roles || [])).find((role: any) => {
                    return role.roleId === deselectedValue;
                  });
                  if (requiredRole?.roleId) {
                    notification.destroy();
                    notification.warning({
                      message: `Task, appointment link, lab test, medication, immunization or visit node has attached with ${requiredRole.name} role.`,
                      duration: 3.0,
                      placement: 'top'
                    });
                  } else {
                    updateJourneyRole(deselectedValue);
                  }
                  return;
                }
                updateJourneyRole(deselectedValue);
              }}
              className={
                'participant-auto-complete ' + (props?.className || '')
              }
            >
              {accountUserRoles.map((option, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={option.value}
                    label={option.label}
                  >
                    {option.label}
                  </Select.Option>
                );
              })}
            </Select>
          </View>
        </View>
        {
        journeyCareTeamRoleTag.hasSuggestedRoles &&
          <View>
            <HStack alignItems={'center'} space={1} fontSize={"xs"}>
              <InfoOutlineIcon style={{ color: Colors.info[700] as string }} size="xs" />
              <DisplayText extraStyles={{ color: Colors.info[700] as string }} textLocalId="journeyCareTeamRoleSuggestion" />
            </HStack>
          </View>
        }
      </VStack>
    </FormControl>
  );
};

export default JourneyDetailTagInput;
