import React, { useContext, useState } from 'react';
import { StyleSheet } from 'react-native';
import RadioButton from '../CommonComponents/RadioButton';
import { declinedPatientConditon } from '../../services/CommonService/AidBoxService';
import { IContact } from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import { IDiagnosisGap } from './interfaces';
import { IConditionDetail, IDeclinedPatientConditonInput, IHCCCodeGroupData } from '../../Interfaces';
import { ModalActionTitle } from '../common/ModalActionTitle/ModalActionTitle';
import { CommonDataContext } from '../../context/CommonDataContext';
import { LeftOutlined } from '@ant-design/icons';
import { Colors } from '../../styles/Colors';
import { BUTTON_TYPE, MLOV_CATEGORY } from '../../constants';
import { Button, Drawer } from 'antd';
import { Stack, Text, FormControl, TextArea } from 'native-base';
import { getMlovListFromCategory, getMlovValueFromCode } from '../../utils/mlovUtils';
import { DisplayText } from '../common/DisplayText/DisplayText';

interface DiagnosisGapDismissalProps {
  open: boolean;
  onClose: () => void;
  contactData: IContact | undefined;
  diagnosisGap: IDiagnosisGap;
  onDismissed?: () => void;
}

export const DiagnosisGapDismissal: React.FC<DiagnosisGapDismissalProps> = ({
  open,
  onClose,
  contactData,
  diagnosisGap,
}) => {
  const mlovData = useContext(CommonDataContext);
  const isSideCarContext = !!mlovData.sidecarContext?.isSidecar;
  const practiceLocationId = contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid;
  const patientId = contactData?.patient?.patientId;
  const reasons = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.DIAGNOSIS_GAP_DISMISS_REASONS
  );
  const [componentState, setComponentState] = useState({
    selectedReason: null,
    note: ''
  });
  const [saveLoading, setSaveLoading] = useState(false);

  const backBtn = () => ({
    show: true,
    id: 1,
    btnText: 'cancel',
    textColor: Colors.Custom.mainSecondaryBrown,
    variant: BUTTON_TYPE.SECONDARY,
    isTransBtn: false,
    onClick: () => {
      onClose();
    },
  });

  const handleSave = async () => {
    if (!componentState.selectedReason) return;
    setSaveLoading(true);
    const selectedReasonValue = getMlovValueFromCode(reasons, componentState.selectedReason);

    try {
      const hccCodeGroupData: IHCCCodeGroupData = {
        description: diagnosisGap.hccDescription,
        codeGroup: diagnosisGap.hccCode,
        codeGroupScore: diagnosisGap.hccScore,
        declinedReason: [selectedReasonValue],
        declinedReasonNote: componentState.note
      };

      const conditionDetails: IConditionDetail[] = diagnosisGap.conditions?.map(condition => ({
        ...condition,
        onsetDate: condition.recordedDate,
        conditionUniqueKey: condition.resourceId
      })) || [];

      const payload: IDeclinedPatientConditonInput = {
        hccCodeGroupData,
        conditionDetails
      };

      await declinedPatientConditon(payload, patientId, practiceLocationId || '');
      onClose();
    } catch (error) {
    } finally {
      setSaveLoading(false);
    }
  };

  const renderReasonsView = () => (
    <Stack direction={'column'} space={4}>
      <Text style={styles.reasonTitle}>
        Select a reason for dismissing this diagnosis gap
      </Text>
      {reasons.map((reason, index) => (
        <Stack key={reason.code} direction={'row'} space={2} alignItems="center">
          <RadioButton
            isChecked={componentState.selectedReason === reason.code}
            onChange={() => setComponentState((prev:any) => ({...prev, selectedReason: reason.code}))}
          />
          <Text style={styles.reasonText}>
            {reason.value}
          </Text>
        </Stack>
      ))}
      <FormControl>
        <FormControl.Label>
          <DisplayText
            size={'smMedium'}
            extraStyles={{
              fontWeight: '500',
              fontSize: 16,
              color: Colors.FoldPixel.GRAY400,
            }}
            textLocalId="singleNote"
          />
        </FormControl.Label>
        <TextArea
          multiline
          defaultValue=""
          variant={'outline'}
          value={componentState.note}
          style={styles.textArea}
          placeholder={'Enter Note'}
          placeholderTextColor={Colors.Custom.AlertsDescriptionColor}
          maxH={176}
          autoComplete="off"
          onChangeText={(plainString) => {
            setComponentState((prev) => ({
              ...prev,
              note: plainString,
            }));
          }}
        />
      </FormControl>
    </Stack>
  );

  return (
    <Drawer 
      open={open} 
      width={isSideCarContext ? '100%' : '40%'} 
      title={
        <ModalActionTitle
          title={'Dismiss Diagnosis Gap'}
          titleColor={''}
          leftBackButton={isSideCarContext ? <Button onClick={() => onClose()} type="text" icon={<LeftOutlined />}/> : undefined}
          buttonList={[
            {...backBtn(), show: !isSideCarContext},
            {
              show: true,
              id: 3,
              btnText: 'save',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isLoading: saveLoading,
              isDisabled: !componentState.selectedReason,
              isTransBtn: false,
              onClick: handleSave,
            },
          ]}
        />
      }
    >
      {renderReasonsView()}
    </Drawer>
  );
};

const styles = StyleSheet.create({
  content: {
    margin: 10,
    padding: 20,
    backgroundColor: 'blue',
  },
  reasonText: {
    width: 'max-content',
    color: Colors.FoldPixel.GRAY400,
    fontWeight: '500',
    marginTop: '1px',
  },
  reasonTitle: {
    fontWeight: '500',
    fontSize: 16,
    color: Colors.FoldPixel.GRAY400,
  },
  textArea: {
    color: Colors.Custom.TextColor,
    fontSize: 18,
  },
});