import {Drawer} from 'antd';
import {FormControl, Input, Text, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {tagColorList} from '.';
import {BUTTON_TYPE} from '../../../../../constants';
import { LABEL_TYPE_CODES } from '../../../../../constants/MlovConst';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {IAddTagModalProps} from '../interfaces';
import {ColorSelector} from './ColorSelector';
import {StyleSheet} from 'react-native';

const {Label} = FormControl;

const AddTagModal = (props: IAddTagModalProps) => {
  const {category, isModalOpen, onModalClose, onSave, title} = props;

  const [color, setColor] = useState('#808080');
  const [newTagName, setNewTagName] = useState('');
  const [newTagDescription, setNewTagDescription] = useState('');

  const [errors, setErrors] = useState<any>({
    newTagNameError: '',
    newTagDescriptionError: '',
  });

  useEffect(()=> {
    setNewTagName(props?.name || '');
  },[])

  const validate = () => {
    setErrors({});
    if (newTagName.trimLeft() == '') {
      setErrors({newTagNameError: 'Please fill this field'});
      return false;
    }
    if (category?.code !== LABEL_TYPE_CODES.TASK && newTagDescription?.trimStart() === '') {
      setErrors({newTagDescriptionError: 'Please fill this field'});
      return false;
    }
    return true;
  };

  const handleAddNewTag = () => {
    const categoryId = category.id
    if (validate()) {
      const newTag: any = {
        categoryId,
        newTagName: newTagName.trim(),
        newTagDescription,
        color,
      };
      onSave(newTag);
      setNewTagDescription('');
      setNewTagName('');
      setErrors({});
      onModalClose();
    }
  };
  const {width} = Dimensions.get('window');
  const finalWidth = 600;
  return (
    <Drawer
      width={finalWidth}
      visible={isModalOpen}
      onClose={() => {
        setNewTagDescription('');
        setNewTagName('');
        setErrors({});
        onModalClose();
      }}
      title={
        <>
          <ModalActionTitle
            title={title}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  setNewTagDescription('');
                  setNewTagName('');
                  setErrors({});
                  onModalClose();
                },
              },
              {
                show: true.valueOf,
                id: 2,
                btnText: 'save',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  handleAddNewTag();
                },
              },
            ]}
          />
        </>
      }
    >
      <View mx={0}>
        <FormControl>
          <Label>
            <DisplayText size={'mdNormal'} textLocalId={category?.code === LABEL_TYPE_CODES.TASK ? 'labelName' : 'tagName'} />
            <Text style={styles.required}>*</Text>
          </Label>
          <Input
          _focus={{
            borderColor: Colors.Custom.Gray200
          }}
            size={'mdNormal'}
            value={newTagName}
            onChangeText={(text: string) => setNewTagName(text)}
            placeholder='Enter tag name'
            placeholderTextColor={Colors.Custom.Gray300}
          />
          <Text size={'smLight'} color="error.500">
            {errors?.newTagNameError}
          </Text>
        </FormControl>
        <FormControl marginTop={3}>
          <Label>
            <DisplayText size={'mdNormal'} textLocalId={category?.code === LABEL_TYPE_CODES.TASK ? 'labelDescription' : 'tagDescription'} />
            {(category?.code !== LABEL_TYPE_CODES.TASK) && <Text style={styles.required}>*</Text>}            
          </Label>
          <Input
          _focus={{
            borderColor: Colors.Custom.Gray200
          }}
            size={'mdNormal'}
            value={newTagDescription}
            onChangeText={(text: string) => setNewTagDescription(text)}
            placeholder='Enter tag description'
            placeholderTextColor={Colors.Custom.Gray300}
          />
          <Text size={'smLight'} color="error.500">
            {errors?.newTagDescriptionError}
          </Text>
        </FormControl>
        <FormControl width={'20%'}>
          <ColorSelector
            color={color}
            title={category?.code === LABEL_TYPE_CODES.TASK ? "labelColor" : "tagColor"}
            colorList={tagColorList}
            onBtnPress={(color: string) => setColor(color)}
            onChangeHex={(text) => setColor('#' + text)}
          />
        </FormControl>
      </View>
    </Drawer>
  );
};

export default AddTagModal;

const styles = StyleSheet.create({
  required: {
    color: 'red'
  }
})