import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles';

export const deleteCptModalStyle = StyleSheet.create({
  container: {
    backgroundColor: Colors.Custom.BackgroundColor,
  },
  footerStyle: {
    backgroundColor: Colors.Custom.BackgroundColor,
  },
});
