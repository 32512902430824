
import {Select} from "antd"
import {LOCATION_FILTER_RESOURCE_CODE} from "../../../../constants";
import {Colors} from "../../../../styles"
import {getAccountUUID, isMasterAccount} from "../../../../utils/commonUtils"

const LocationFilterDropdown = (props: any)=> {
  const {accountPracticeLocations, selectedLocation, resourceCode,onSelect, top} = props;
  const isLocationFilterExist = LOCATION_FILTER_RESOURCE_CODE.includes(resourceCode);
  const isMasterAccountConfig = isMasterAccount()
  return (
    <div style={{
        position: 'absolute',
        top: top < 0 ? top : 0,
        left: 28,
        zIndex: 99,
        display: isLocationFilterExist && !isMasterAccountConfig ?
                  'block' :
                  'none'
        }}>
      <Select
          // allowClear={true}
          placeholder="Select location"
          className='location-selector'
          onClear={()=> {
            onSelect?.('',resourceCode)
          }}
          onSelect={(value, option) => {
            onSelect?.(value,resourceCode)
          }}
          value={selectedLocation || null}
          style={{
            borderWidth: 0,
            backgroundColor: 'transparent',
            width: 150
          }}
        >
          {accountPracticeLocations?.map((item: any) => (
            item?.practiceLocation?.name != null ? <Select.Option key={item.uuid}>{item?.practiceLocation?.name}</Select.Option> : <></>
          ))}
        </Select>
    </div>
  )
}
export default LocationFilterDropdown
