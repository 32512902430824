import {Text, View} from 'native-base';
import React from 'react';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import {svgStyles} from './SvgStyles';

const NoConversationsSvg = (props: {titleId?: string; fillColor?: string}) => {
  const fillColor = props.fillColor || Colors.primary['300'];
  return (
    <View style={svgStyles.container}>
      <svg
        width="100"
        height="96"
        viewBox="0 0 75 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.28 66.67H59.71C60.93 66.67 62.11 66.21 63.01 65.38C66.75 61.89 69.75 57.67 71.81 52.98C73.87 48.29 74.96 43.22 75 38.09C75.19 17.22 58.33 0.0499885 37.59 -1.14744e-05C16.84 -0.0500115 0 16.86 0 37.73C0 42.92 1.06 48.06 3.12 52.82C5.18 57.58 8.21 61.86 12 65.39C12.9 66.22 14.07 66.68 15.29 66.67H15.28Z"
          fill="#FCFDFD"
        />
        <path
          d="M59.21 12.49H16.06C12.0173 12.49 8.73999 15.7673 8.73999 19.81V62.96C8.73999 67.0027 12.0173 70.28 16.06 70.28H59.21C63.2527 70.28 66.53 67.0027 66.53 62.96V19.81C66.53 15.7673 63.2527 12.49 59.21 12.49Z"
          fill="#E4E7EC"
        />
        <path
          d="M59.22 11.1H16.06C12.02 11.1 8.73999 14.37 8.73999 18.42V61.57C8.73999 65.61 12.02 68.89 16.06 68.89H52.58L66.54 54.26V18.42C66.54 14.38 63.27 11.1 59.22 11.1Z"
          fill="#F2F4F7"
        />
        <path
          d="M52.8 68.14C52.8 68.08 52.81 68.01 52.82 67.94L52.57 68.2H16.05C12.01 68.2 8.72998 64.92 8.72998 60.88V61.57C8.72998 65.61 12.01 68.89 16.05 68.89H52.57L52.79 68.66C52.79 68.48 52.79 68.31 52.79 68.13L52.8 68.14Z"
          fill="url(#paint0_linear_393_2356)"
        />
        <path
          d="M59.22 11.1H58.54C62.58 11.1 65.86 14.37 65.86 18.42V54.26L60.7701 60.3L66.53 54.26V18.42C66.53 14.38 63.2601 11.1 59.2101 11.1H59.22Z"
          fill="url(#paint1_linear_393_2356)"
        />
        <path
          d="M9.12 61.58V18.42C9.12 14.38 12.4 11.1 16.44 11.1H16.06C12.02 11.1 8.73999 14.38 8.73999 18.42V61.58C8.73999 65.62 12.02 68.9 16.06 68.9H16.44C12.4 68.9 9.12 65.62 9.12 61.58Z"
          fill="white"
        />
        <path
          d="M59.22 11.1H16.06C12.02 11.1 8.73999 14.38 8.73999 18.42V26.99H66.53V18.42C66.53 14.38 63.25 11.1 59.21 11.1H59.22Z"
          fill="#E4E7EC"
        />
        <path
          opacity="0.25"
          d="M59.22 11.1H58.84C62.88 11.1 66.16 14.38 66.16 18.42V26.99H66.54V18.42C66.54 14.38 63.26 11.1 59.22 11.1Z"
          fill="white"
        />
        <path
          opacity="0.25"
          d="M9.12 18.91C9.12 14.87 12.4 11.59 16.44 11.59H59.6C61.84 11.59 63.84 12.59 65.18 14.18C63.85 12.32 61.68 11.1 59.22 11.1H16.06C12.02 11.1 8.73999 14.38 8.73999 18.42V26.99H9.12V18.91Z"
          fill="white"
        />
        <path
          d="M66.53 26.99H8.73999V27.48H66.53V26.99Z"
          fill="url(#paint2_linear_393_2356)"
        />
        <path
          d="M52.5801 68.9V61.59C52.5801 57.55 55.8501 54.27 59.9001 54.27H66.5401L52.5801 68.9Z"
          fill="#DADCE0"
        />
        <path
          d="M19.35 16.41C20.4325 16.41 21.31 15.5325 21.31 14.45C21.31 13.3675 20.4325 12.49 19.35 12.49C18.2675 12.49 17.39 13.3675 17.39 14.45C17.39 15.5325 18.2675 16.41 19.35 16.41Z"
          fill="#FCFDFD"
        />
        <path
          opacity="0.25"
          d="M19.35 12.49C20.41 12.49 21.27 13.33 21.31 14.38C21.31 14.36 21.31 14.33 21.31 14.31C21.31 13.23 20.43 12.35 19.35 12.35C18.27 12.35 17.39 13.23 17.39 14.31C17.39 14.33 17.39 14.36 17.39 14.38C17.43 13.33 18.29 12.49 19.35 12.49Z"
          fill="white"
        />
        <path
          opacity="0.25"
          d="M19.35 16.41C18.31 16.41 17.47 15.61 17.4 14.59C17.4 14.64 17.4 14.68 17.4 14.73C17.4 15.81 18.28 16.69 19.36 16.69C20.44 16.69 21.32 15.81 21.32 14.73C21.32 14.68 21.32 14.64 21.32 14.59C21.25 15.61 20.4 16.41 19.37 16.41H19.35Z"
          fill="white"
        />
        <path
          d="M28.5 16.41C29.5825 16.41 30.4601 15.5325 30.4601 14.45C30.4601 13.3675 29.5825 12.49 28.5 12.49C27.4176 12.49 26.54 13.3675 26.54 14.45C26.54 15.5325 27.4176 16.41 28.5 16.41Z"
          fill="#FCFDFD"
        />
        <path
          opacity="0.25"
          d="M28.5 12.49C29.56 12.49 30.4201 13.33 30.4601 14.38C30.4601 14.36 30.4601 14.33 30.4601 14.31C30.4601 13.23 29.58 12.35 28.5 12.35C27.42 12.35 26.54 13.23 26.54 14.31C26.54 14.33 26.54 14.36 26.54 14.38C26.58 13.33 27.44 12.49 28.5 12.49Z"
          fill="white"
        />
        <path
          opacity="0.25"
          d="M28.5 16.41C27.46 16.41 26.62 15.61 26.55 14.59C26.55 14.64 26.55 14.68 26.55 14.73C26.55 15.81 27.43 16.69 28.51 16.69C29.59 16.69 30.47 15.81 30.47 14.73C30.47 14.68 30.47 14.64 30.47 14.59C30.4 15.61 29.5501 16.41 28.5201 16.41H28.5Z"
          fill="white"
        />
        <path
          d="M37.6401 16.41C38.7225 16.41 39.6001 15.5325 39.6001 14.45C39.6001 13.3675 38.7225 12.49 37.6401 12.49C36.5576 12.49 35.6801 13.3675 35.6801 14.45C35.6801 15.5325 36.5576 16.41 37.6401 16.41Z"
          fill="#FCFDFD"
        />
        <path
          opacity="0.25"
          d="M37.6401 12.49C38.7001 12.49 39.5601 13.33 39.6001 14.38C39.6001 14.36 39.6001 14.33 39.6001 14.31C39.6001 13.23 38.7201 12.35 37.6401 12.35C36.5601 12.35 35.6801 13.23 35.6801 14.31C35.6801 14.33 35.6801 14.36 35.6801 14.38C35.7201 13.33 36.5801 12.49 37.6401 12.49Z"
          fill="white"
        />
        <path
          opacity="0.25"
          d="M37.6401 16.41C36.6001 16.41 35.7601 15.61 35.6901 14.59C35.6901 14.64 35.6901 14.68 35.6901 14.73C35.6901 15.81 36.5701 16.69 37.6501 16.69C38.7301 16.69 39.6101 15.81 39.6101 14.73C39.6101 14.68 39.6101 14.64 39.6101 14.59C39.5401 15.61 38.6901 16.41 37.6601 16.41H37.6401Z"
          fill="white"
        />
        <path
          d="M46.7801 16.41C47.8625 16.41 48.7401 15.5325 48.7401 14.45C48.7401 13.3675 47.8625 12.49 46.7801 12.49C45.6976 12.49 44.8201 13.3675 44.8201 14.45C44.8201 15.5325 45.6976 16.41 46.7801 16.41Z"
          fill="#FCFDFD"
        />
        <path
          opacity="0.25"
          d="M46.7801 12.49C47.8401 12.49 48.7001 13.33 48.7401 14.38C48.7401 14.36 48.7401 14.33 48.7401 14.31C48.7401 13.23 47.8601 12.35 46.7801 12.35C45.7001 12.35 44.8201 13.23 44.8201 14.31C44.8201 14.33 44.8201 14.36 44.8201 14.38C44.8601 13.33 45.7201 12.49 46.7801 12.49Z"
          fill="white"
        />
        <path
          opacity="0.25"
          d="M46.7801 16.41C45.7401 16.41 44.9001 15.61 44.8301 14.59C44.8301 14.64 44.8301 14.68 44.8301 14.73C44.8301 15.81 45.7101 16.69 46.7901 16.69C47.8701 16.69 48.7501 15.81 48.7501 14.73C48.7501 14.68 48.7501 14.64 48.7501 14.59C48.6801 15.61 47.8301 16.41 46.8001 16.41H46.7801Z"
          fill="white"
        />
        <path
          d="M55.9201 16.41C57.0026 16.41 57.8801 15.5325 57.8801 14.45C57.8801 13.3675 57.0026 12.49 55.9201 12.49C54.8376 12.49 53.9601 13.3675 53.9601 14.45C53.9601 15.5325 54.8376 16.41 55.9201 16.41Z"
          fill="#FCFDFD"
        />
        <path
          opacity="0.25"
          d="M55.9201 12.49C56.9801 12.49 57.8401 13.33 57.8801 14.38C57.8801 14.36 57.8801 14.33 57.8801 14.31C57.8801 13.23 57.0001 12.35 55.9201 12.35C54.8401 12.35 53.9601 13.23 53.9601 14.31C53.9601 14.33 53.9601 14.36 53.9601 14.38C54.0001 13.33 54.8601 12.49 55.9201 12.49Z"
          fill="white"
        />
        <path
          opacity="0.25"
          d="M55.92 16.41C54.88 16.41 54.04 15.61 53.97 14.59C53.97 14.64 53.97 14.68 53.97 14.73C53.97 15.81 54.85 16.69 55.93 16.69C57.01 16.69 57.89 15.81 57.89 14.73C57.89 14.68 57.89 14.64 57.89 14.59C57.82 15.61 56.97 16.41 55.94 16.41H55.92Z"
          fill="white"
        />
        <path
          d="M20.39 7.66998C20.03 7.66998 19.74 7.95998 19.74 8.31998V13.79C19.74 14.15 20.03 14.44 20.39 14.44C20.75 14.44 21.04 14.15 21.04 13.79V8.31998C21.04 7.95998 20.75 7.66998 20.39 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M18.38 7.66998C18.02 7.66998 17.73 7.95998 17.73 8.31998V13.79C17.73 14.15 18.02 14.44 18.38 14.44C18.74 14.44 19.03 14.15 19.03 13.79V8.31998C19.03 7.95998 18.74 7.66998 18.38 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M29.53 7.66998C29.17 7.66998 28.88 7.95998 28.88 8.31998V13.79C28.88 14.15 29.17 14.44 29.53 14.44C29.89 14.44 30.18 14.15 30.18 13.79V8.31998C30.18 7.95998 29.89 7.66998 29.53 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M27.52 7.66998C27.16 7.66998 26.87 7.95998 26.87 8.31998V13.79C26.87 14.15 27.16 14.44 27.52 14.44C27.88 14.44 28.17 14.15 28.17 13.79V8.31998C28.17 7.95998 27.88 7.66998 27.52 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M38.67 7.66998C38.31 7.66998 38.02 7.95998 38.02 8.31998V13.79C38.02 14.15 38.31 14.44 38.67 14.44C39.03 14.44 39.32 14.15 39.32 13.79V8.31998C39.32 7.95998 39.03 7.66998 38.67 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M36.66 7.66998C36.3 7.66998 36.01 7.95998 36.01 8.31998V13.79C36.01 14.15 36.3 14.44 36.66 14.44C37.02 14.44 37.31 14.15 37.31 13.79V8.31998C37.31 7.95998 37.02 7.66998 36.66 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M47.8201 7.66998C47.4601 7.66998 47.17 7.95998 47.17 8.31998V13.79C47.17 14.15 47.4601 14.44 47.8201 14.44C48.1801 14.44 48.47 14.15 48.47 13.79V8.31998C48.47 7.95998 48.1801 7.66998 47.8201 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M45.8 7.66998C45.44 7.66998 45.15 7.95998 45.15 8.31998V13.79C45.15 14.15 45.44 14.44 45.8 14.44C46.16 14.44 46.45 14.15 46.45 13.79V8.31998C46.45 7.95998 46.16 7.66998 45.8 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M56.9601 7.66998C56.6001 7.66998 56.3101 7.95998 56.3101 8.31998V13.79C56.3101 14.15 56.6001 14.44 56.9601 14.44C57.3201 14.44 57.6101 14.15 57.6101 13.79V8.31998C57.6101 7.95998 57.3201 7.66998 56.9601 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M54.94 7.66998C54.58 7.66998 54.29 7.95998 54.29 8.31998V13.79C54.29 14.15 54.58 14.44 54.94 14.44C55.3 14.44 55.59 14.15 55.59 13.79V8.31998C55.59 7.95998 55.3 7.66998 54.94 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M20.39 7.66998C20.03 7.66998 19.74 7.95998 19.74 8.31998V8.68998C19.74 8.32998 20.03 8.03998 20.39 8.03998C20.75 8.03998 21.04 8.32998 21.04 8.68998V8.31998C21.04 7.95998 20.75 7.66998 20.39 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M20.39 14.06C20.03 14.06 19.74 13.77 19.74 13.41V13.8C19.74 14.16 20.03 14.45 20.39 14.45C20.75 14.45 21.04 14.16 21.04 13.8V13.41C21.04 13.77 20.75 14.06 20.39 14.06Z"
          fill="#E4E7EC"
        />
        <path
          d="M18.38 7.66998C18.02 7.66998 17.73 7.95998 17.73 8.31998V8.68998C17.73 8.32998 18.02 8.03998 18.38 8.03998C18.74 8.03998 19.03 8.32998 19.03 8.68998V8.31998C19.03 7.95998 18.74 7.66998 18.38 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M18.38 14.06C18.02 14.06 17.73 13.77 17.73 13.41V13.8C17.73 14.16 18.02 14.45 18.38 14.45C18.74 14.45 19.03 14.16 19.03 13.8V13.41C19.03 13.77 18.74 14.06 18.38 14.06Z"
          fill="#E4E7EC"
        />
        <path
          d="M29.53 7.66998C29.17 7.66998 28.88 7.95998 28.88 8.31998V8.68998C28.88 8.32998 29.17 8.03998 29.53 8.03998C29.89 8.03998 30.18 8.32998 30.18 8.68998V8.31998C30.18 7.95998 29.89 7.66998 29.53 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M29.53 14.06C29.17 14.06 28.88 13.77 28.88 13.41V13.8C28.88 14.16 29.17 14.45 29.53 14.45C29.89 14.45 30.18 14.16 30.18 13.8V13.41C30.18 13.77 29.89 14.06 29.53 14.06Z"
          fill="#E4E7EC"
        />
        <path
          d="M27.52 7.66998C27.16 7.66998 26.87 7.95998 26.87 8.31998V8.68998C26.87 8.32998 27.16 8.03998 27.52 8.03998C27.88 8.03998 28.17 8.32998 28.17 8.68998V8.31998C28.17 7.95998 27.88 7.66998 27.52 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M27.52 14.06C27.16 14.06 26.87 13.77 26.87 13.41V13.8C26.87 14.16 27.16 14.45 27.52 14.45C27.88 14.45 28.17 14.16 28.17 13.8V13.41C28.17 13.77 27.88 14.06 27.52 14.06Z"
          fill="#E4E7EC"
        />
        <path
          d="M38.67 7.66998C38.31 7.66998 38.02 7.95998 38.02 8.31998V8.68998C38.02 8.32998 38.31 8.03998 38.67 8.03998C39.03 8.03998 39.32 8.32998 39.32 8.68998V8.31998C39.32 7.95998 39.03 7.66998 38.67 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M38.67 14.06C38.31 14.06 38.02 13.77 38.02 13.41V13.8C38.02 14.16 38.31 14.45 38.67 14.45C39.03 14.45 39.32 14.16 39.32 13.8V13.41C39.32 13.77 39.03 14.06 38.67 14.06Z"
          fill="#E4E7EC"
        />
        <path
          d="M36.66 7.66998C36.3 7.66998 36.01 7.95998 36.01 8.31998V8.68998C36.01 8.32998 36.3 8.03998 36.66 8.03998C37.02 8.03998 37.31 8.32998 37.31 8.68998V8.31998C37.31 7.95998 37.02 7.66998 36.66 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M36.66 14.06C36.3 14.06 36.01 13.77 36.01 13.41V13.8C36.01 14.16 36.3 14.45 36.66 14.45C37.02 14.45 37.31 14.16 37.31 13.8V13.41C37.31 13.77 37.02 14.06 36.66 14.06Z"
          fill="#E4E7EC"
        />
        <path
          d="M47.8201 7.66998C47.4601 7.66998 47.17 7.95998 47.17 8.31998V8.68998C47.17 8.32998 47.4601 8.03998 47.8201 8.03998C48.1801 8.03998 48.47 8.32998 48.47 8.68998V8.31998C48.47 7.95998 48.1801 7.66998 47.8201 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M47.8201 14.06C47.4601 14.06 47.17 13.77 47.17 13.41V13.8C47.17 14.16 47.4601 14.45 47.8201 14.45C48.1801 14.45 48.47 14.16 48.47 13.8V13.41C48.47 13.77 48.1801 14.06 47.8201 14.06Z"
          fill="#E4E7EC"
        />
        <path
          d="M45.8 7.66998C45.44 7.66998 45.15 7.95998 45.15 8.31998V8.68998C45.15 8.32998 45.44 8.03998 45.8 8.03998C46.16 8.03998 46.45 8.32998 46.45 8.68998V8.31998C46.45 7.95998 46.16 7.66998 45.8 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M45.8 14.06C45.44 14.06 45.15 13.77 45.15 13.41V13.8C45.15 14.16 45.44 14.45 45.8 14.45C46.16 14.45 46.45 14.16 46.45 13.8V13.41C46.45 13.77 46.16 14.06 45.8 14.06Z"
          fill="#E4E7EC"
        />
        <path
          d="M54.94 7.66998C54.58 7.66998 54.29 7.95998 54.29 8.31998V8.68998C54.29 8.32998 54.58 8.03998 54.94 8.03998C55.3 8.03998 55.59 8.32998 55.59 8.68998V8.31998C55.59 7.95998 55.3 7.66998 54.94 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M54.94 14.06C54.58 14.06 54.29 13.77 54.29 13.41V13.8C54.29 14.16 54.58 14.45 54.94 14.45C55.3 14.45 55.59 14.16 55.59 13.8V13.41C55.59 13.77 55.3 14.06 54.94 14.06Z"
          fill="#E4E7EC"
        />
        <path
          d="M56.9601 7.66998C56.6001 7.66998 56.3101 7.95998 56.3101 8.31998V8.68998C56.3101 8.32998 56.6001 8.03998 56.9601 8.03998C57.3201 8.03998 57.6101 8.32998 57.6101 8.68998V8.31998C57.6101 7.95998 57.3201 7.66998 56.9601 7.66998Z"
          fill="#F2F4F7"
        />
        <path
          d="M56.9601 14.06C56.6001 14.06 56.3101 13.77 56.3101 13.41V13.8C56.3101 14.16 56.6001 14.45 56.9601 14.45C57.3201 14.45 57.6101 14.16 57.6101 13.8V13.41C57.6101 13.77 57.3201 14.06 56.9601 14.06Z"
          fill="#E4E7EC"
        />
        <path
          d="M36.1701 33.45H16.0801C13.5201 33.45 11.4401 35.53 11.4401 38.09V48.39C11.4401 50.95 13.5201 53.03 16.0801 53.03H30.3201L34.2801 56.99C34.8801 57.59 35.9101 57.17 35.9101 56.31V53.02H36.1701C38.7301 53.02 40.8101 50.94 40.8101 48.38V38.08C40.8101 35.52 38.7301 33.44 36.1701 33.44V33.45ZM18.4301 45.34C17.2701 45.34 16.3301 44.4 16.3301 43.24C16.3301 42.08 17.2701 41.14 18.4301 41.14C19.5901 41.14 20.5301 42.08 20.5301 43.24C20.5301 44.4 19.5901 45.34 18.4301 45.34ZM26.1201 45.34C24.9601 45.34 24.0201 44.4 24.0201 43.24C24.0201 42.08 24.9601 41.14 26.1201 41.14C27.2801 41.14 28.2201 42.08 28.2201 43.24C28.2201 44.4 27.2801 45.34 26.1201 45.34ZM33.8101 45.34C32.6501 45.34 31.7101 44.4 31.7101 43.24C31.7101 42.08 32.6501 41.14 33.8101 41.14C34.9701 41.14 35.9101 42.08 35.9101 43.24C35.9101 44.4 34.9701 45.34 33.8101 45.34Z"
          fill={fillColor}
        />
        <path
          d="M26.38 43.24H6.29002C3.73002 43.24 1.65002 45.32 1.65002 47.88V58.18C1.65002 60.74 3.73002 62.82 6.29002 62.82H20.53L24.49 66.78C25.09 67.38 26.12 66.96 26.12 66.1V62.81H26.38C28.94 62.81 31.02 60.73 31.02 58.17V47.87C31.02 45.31 28.94 43.23 26.38 43.23V43.24Z"
          fill="white"
        />
        <path
          d="M26.38 43.24H6.29002C3.73002 43.24 1.65002 45.32 1.65002 47.88V58.18C1.65002 60.74 3.73002 62.82 6.29002 62.82H20.53L24.49 66.78C25.09 67.38 26.12 66.96 26.12 66.1V62.81H26.38C28.94 62.81 31.02 60.73 31.02 58.17V47.87C31.02 45.31 28.94 43.23 26.38 43.23V43.24ZM8.64002 55.13C7.48002 55.13 6.54002 54.19 6.54002 53.03C6.54002 51.87 7.48002 50.93 8.64002 50.93C9.80002 50.93 10.74 51.87 10.74 53.03C10.74 54.19 9.80002 55.13 8.64002 55.13ZM16.33 55.13C15.17 55.13 14.23 54.19 14.23 53.03C14.23 51.87 15.17 50.93 16.33 50.93C17.49 50.93 18.43 51.87 18.43 53.03C18.43 54.19 17.49 55.13 16.33 55.13ZM24.02 55.13C22.86 55.13 21.92 54.19 21.92 53.03C21.92 51.87 22.86 50.93 24.02 50.93C25.18 50.93 26.12 51.87 26.12 53.03C26.12 54.19 25.18 55.13 24.02 55.13Z"
          fill={fillColor}
        />
        <path
          opacity="0.25"
          d="M26.38 43.24H6.29002C3.73002 43.24 1.65002 45.32 1.65002 47.88V58.18C1.65002 60.74 3.73002 62.82 6.29002 62.82H20.53L24.49 66.78C25.09 67.38 26.12 66.96 26.12 66.1V62.81H26.38C28.94 62.81 31.02 60.73 31.02 58.17V47.87C31.02 45.31 28.94 43.23 26.38 43.23V43.24ZM8.64002 55.13C7.48002 55.13 6.54002 54.19 6.54002 53.03C6.54002 51.87 7.48002 50.93 8.64002 50.93C9.80002 50.93 10.74 51.87 10.74 53.03C10.74 54.19 9.80002 55.13 8.64002 55.13ZM16.33 55.13C15.17 55.13 14.23 54.19 14.23 53.03C14.23 51.87 15.17 50.93 16.33 50.93C17.49 50.93 18.43 51.87 18.43 53.03C18.43 54.19 17.49 55.13 16.33 55.13ZM24.02 55.13C22.86 55.13 21.92 54.19 21.92 53.03C21.92 51.87 22.86 50.93 24.02 50.93C25.18 50.93 26.12 51.87 26.12 53.03C26.12 54.19 25.18 55.13 24.02 55.13Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_393_2356"
            x1="17.15"
            y1="63.36"
            x2="71.4"
            y2="79.75"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="0.08" stop-color="#F8F8F8" />
            <stop offset="0.4" stop-color="#E2E3E3" />
            <stop offset="0.72" stop-color="#D5D6D7" />
            <stop offset="1" stop-color="#D1D2D3" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_393_2356"
            x1="57.93"
            y1="35.66"
            x2="81.3701"
            y2="36.17"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="0.08" stop-color="#F8F8F8" />
            <stop offset="0.4" stop-color="#E2E3E3" />
            <stop offset="0.72" stop-color="#D5D6D7" />
            <stop offset="1" stop-color="#D1D2D3" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_393_2356"
            x1="8.73999"
            y1="27.23"
            x2="66.54"
            y2="27.23"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="0.08" stop-color="#F8F8F8" />
            <stop offset="0.4" stop-color="#E2E3E3" />
            <stop offset="0.72" stop-color="#D5D6D7" />
            <stop offset="1" stop-color="#D1D2D3" />
          </linearGradient>
        </defs>
      </svg>
      {props.titleId && (
        <Text style={svgStyles.svgText}>
          <DisplayText textLocalId={props.titleId} textType="Text" />
        </Text>
      )}
    </View>
  );
};

export default NoConversationsSvg;
