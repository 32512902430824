import React, {useContext, useState} from 'react';
import {View, IconButton} from 'native-base';
import Icon from 'react-native-vector-icons/AntDesign';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {ITagsTableData} from '../..';
import {DeleteTagModal, EditTagModal} from '../../TagsModal';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../../styles';
import { getMlovListFromCategory } from '../../../../../../utils/mlovUtils';
import { LABEL_TYPE_CODES, MLOV_CATEGORY } from '../../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import {PACKAGE_MLOV} from '../../../../Sales/ProductsAndServices/JourneyPackages/PackagesConst';
import {useIntl} from 'react-intl';
import { IEditLabelState } from '../../interfaces';

interface ITagTableAction {
  record: ITagsTableData;
  onActionPerformed: (actionCode: string, recordData: ITagsTableData) => {};
  mlovCategory?: string
}
const TagsActions = (props: ITagTableAction) => {
  const {record} = props;
  const mlovData = useContext(CommonDataContext);
  const labels = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );
  const intl = useIntl();
  const filteredResult = labels?.filter((item) => {
    return item.code === LABEL_TYPE_CODES.TASK;
  });
  const mlov = filteredResult[0];
  const isLabel = record?.labelTypeId === mlov?.id;
  // states for editing tag
  const [openEditModal, setOpenEditModal] = useState(false);

  // states for deleting a tag
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // function to delete a tag
  const handleDeleteTag = () => {
    props.onActionPerformed(COMMON_ACTION_CODES.DELETE, record);
  };

  // function to edit a tag
  const handleEditTag = (data: IEditLabelState) => {
    const newTag = {
      id: record.id,
      color: data.tagColor,
      title: data.tagText,
      description: data.tagDescription,
    };
    props.onActionPerformed(COMMON_ACTION_CODES.SAVE, newTag);
  };

  const getTitle = () => {
    let title = intl.formatMessage({id: 'conversationDeleteMsg'})
    if (record?.labelTypeId === mlov?.id) {
      title = intl.formatMessage({id: 'labelDeleteMsg'})
    } else if (props?.mlovCategory === PACKAGE_MLOV) {
      title = intl.formatMessage({id: 'tagDeleteMsg'})
    }
    return title;
  };

  return (
    <View flex={1} flexDirection={'row'}>
      <IconButton
        icon={<Feather name="edit-2" size={17} color={Colors.Custom.Gray600} />}
        onPress={() => {
          setOpenEditModal(true);
        }}
      />

      {openEditModal && (
      <EditTagModal
        isEditModalOpen={openEditModal}
        onEditModalClose={(data: any) => {
          setOpenEditModal(false);
          handleEditTag(data);
        }}
        onCancel={() => setOpenEditModal(false)}
        record={record}
        isLabel={isLabel}
      />
      )}

      <IconButton
        icon={<Icon name="delete" size={17} color={Colors.Custom.Gray600} />}
        onPress={() => {
          setOpenDeleteModal(true);
        }}
      />

      <DeleteTagModal
        isDeleteModalOpen={openDeleteModal}
        onDeleteModalClose={() => {
          setOpenDeleteModal(false);
          handleDeleteTag();
        }}
        isLabel={record?.labelTypeId === mlov?.id}
        title={getTitle()}
        onCancel={() => setOpenDeleteModal(false)}
      />
    </View>
  );
};

export default TagsActions;
