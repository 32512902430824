import {Drawer, Select, Spin} from 'antd';
import React, {useEffect, useState} from 'react';
import {IAddOrUpdateEFaxInboxDrawer} from './interface';
import {CONVERSATION_ACTION_CODES} from '../../../../constants/ActionConst';
import {
  useMediaQuery,
  Text,
  FormControl,
  useToast,
  Center,
  Spinner,
} from 'native-base';
import {
  EFAX_DRAWER_CODE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../constants/StringConst';
import Stack from '../../../common/LayoutComponents/Stack';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import {Pressable, View, StyleSheet} from 'react-native';
import {Colors} from '../../../../styles/Colors';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {ModalActionInput} from '../../../common/ModalActionCommonComponent/ModalActionInput';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {useLazyQuery, useMutation} from '@apollo/client';
import InboxQueries from '../../../../services/Inbox/InboxQueries';
import {USER_ROLE_CODES} from '../../../../constants/MlovConst';
import UserSvg from '../../../common/Svg/UserSvg';
import EFaxActionSvg from '../../../common/Svg/PersonActionSvgIcons/EFaxActionSvg';
import {
  formatEFaxNumber,
  getUpdatedErrorMessageForInboxMemberSetting,
} from './EFaxInboxSettingUtils';
import {debounce} from 'lodash';
import {getAgentObject} from '../Integrations/Helper/formatIntegrationsData';
import {IInternalUsers} from '../../../common/CustomUserSearch/interface';
import {ToastType, showToast} from '../../../../utils/commonViewUtils';
import {CustomUserSearch} from '../../../common/CustomUserSearch/CustomUserSearch';
import {USER_SEARCH_TYPE} from '../../constants';
import {UserQueries} from '../../../../services';
import {getSelectedUsersUUIDs} from '../../../common/CustomUserSearch/CustomUserSearchUtils';
import {getUserIdsFromUserData} from '../Conversations/MessagingWindow/MessagingUtils';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';

const AddOrUpdateEFaxInboxDrawer = (props: IAddOrUpdateEFaxInboxDrawer) => {
  const intl = useIntl();
  const {Option} = Select;
  const toast = useToast();
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '40vw' : '35%';

  const [state, setState] = useState({
    loading: false,
    selectedUsersUuid: getSelectedUsersUUIDs(
      props?.selectedInbox?.inboxMembers as IInternalUsers[]
    ),
    inboxName: props?.selectedInbox?.name || '',
    errors: {} as any,
    selectedUsersData: props?.selectedInbox?.inboxMembers as IInternalUsers[],
  });

  const [getUsersByUuid] = useLazyQuery(UserQueries.GET_USER_BY_UUID_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      uuidList: state.selectedUsersUuid,
    },
  });

  const [deleteInboxesMembers] = useMutation(InboxQueries.deleteInboxesMembers);
  const [createInboxMembers] = useMutation(InboxQueries.createInboxMembers);
  const [updateInboxName] = useMutation(InboxQueries.updateSMSInboxName);


  const closeModal = () => {
    props?.onActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE);
  };

  const handleOnSave = async () => {
    if (props?.selectedInbox?.id) {
      const errorData = getUpdatedErrorMessageForInboxMemberSetting(state);
      if (errorData?.isErrorPresent) {
        setState((prev) => {
          return {
            ...prev,
            errors: {
              ...prev.errors,
              ...errorData.errors,
            },
            loading: false,
          };
        });
      } else {
        try {
          setState((prev) => {
            return {
              ...prev,
              loading: true,
            };
          });
          const userData = await getUsersByUuid({
            variables: {
              uuidList: state.selectedUsersUuid,
            },
          });
          const selectedUserIds = getUserIdsFromUserData(userData?.data?.users);
          const addNewUserList: any = [];

          const inboxMemberUserIds = props?.selectedInbox?.inboxMembers?.map(
            (item) => {
              return item?.userId;
            }
          );
          const deleteUserList = inboxMemberUserIds?.filter(
            (item) => !selectedUserIds?.includes(item)
          );
          selectedUserIds.forEach((singleUserId) => {
            if (!inboxMemberUserIds?.includes(singleUserId)) {
              addNewUserList.push({
                userId: singleUserId,
                inboxId: props?.selectedInbox?.id,
                isDeleted: false,
              });
            }
          });
          const response = await Promise.all([
            handleAddInboxMember(addNewUserList),
            handleDeleteInboxMember(props?.selectedInbox?.id, deleteUserList),
            handleInboxNameChange(props?.selectedInbox?.id, state?.inboxName),
          ]);
          closeModal();
          showToast(
            toast,
            intl.formatMessage({
              id: 'userAddedSuccessfully',
            }),
            ToastType.success
          );
        } catch (e) {
          setState((prev) => {
            return {
              ...prev,
              loading: false,
            };
          });
          showToast(
            toast,
            intl.formatMessage({
              id: 'apiErrorMsg',
            }),
            ToastType.error
          );
        }
      }
    }
  };

  const handleDeleteInboxMember = async (
    inboxId: number,
    userList?: number[]
  ) => {
    if (userList && userList?.length && inboxId) {
      const deleteMemberData = await deleteInboxesMembers({
        variables: {
          inboxId,
          userIds: userList,
        },
      });
    }
  };

  const handleAddInboxMember = async (addNewUserList: any[]) => {
    if (addNewUserList) {
      const addedMemberData = await createInboxMembers({
        variables: {
          objects: addNewUserList,
        },
      });
    }
  };

  const handleInboxNameChange = async (inboxId: number, inboxName: string) => {
    if (inboxId && inboxName && inboxName !== props?.selectedInbox?.name) {
      const updatedInboxData = await updateInboxName({
        variables: {
          id: inboxId,
          name: inboxName,
          enableAutoAssignment: false,
          inboxType: null,
        },
      });
    }
  };

  const getTitleView = (): JSX.Element => {
    return (
      <Stack direction="row" style={styles.titleContainer}>
        <ModalActionTitle
          title={
            props?.drawerCode === EFAX_DRAWER_CODE.UPDATE_INBOX
              ? 'updateDetails'
              : 'getEFax'
          }
          titleColor=""
        />
        <Stack
          direction="row"
          style={styles.titleButtonsContainer}
        >
          <View style={styles.saveButtonContainer}>
            <Pressable
              style={styles.saveButton}
              onPress={handleOnSave}
            >
              <Text color={'white'} fontSize={'md'}>
                {intl.formatMessage({id: 'save'})}
              </Text>
            </Pressable>
          </View>
          <View style={styles.closeButtonContainer}>
            <Pressable onPress={closeModal}>
              <AntIcon name="close" size={20} color={Colors.Custom.Gray400} />
            </Pressable>
          </View>
        </Stack>
      </Stack>
    );
  };

  const getUpdateInboxElement = () => {
    return (
      <Stack
        direction="column"
        style={{
          paddingHorizontal: 24,
          paddingVertical: 16,
          opacity: state?.loading ? 0.5 : 1,
        }}
      >
        <View style={styles.purchasedNumberContainer}>
          <Text style={styles.purchasedNumberLabel}>
            {intl.formatMessage({id: 'purchasedNumber'})}
          </Text>
          <Stack direction="row" style={styles.purchasedNumberContent}>
            <EFaxActionSvg width={30} height={30} />
            <Stack direction="column">
              <Text paddingLeft={2} color={Colors.Custom.Gray500}>
                {formatEFaxNumber(
                  props?.selectedInbox?.channelEfax?.efaxNumber
                )}
              </Text>
              <Text paddingLeft={2} color={Colors.Custom.Gray500}>
                {props?.selectedInbox?.name}
              </Text>
            </Stack>
          </Stack>
        </View>

        <ModalActionInput
          fieldIs={'input'}
          label={'inboxName'}
          placeholder="Enter inbox name"
          isRequired={true}
          isInvalid={state?.inboxName ? '' : state?.errors?.inboxName}
          errors={state?.inboxName ? '' : state?.errors?.inboxName}
          errorText={state?.inboxName ? '' : state?.errors?.inboxName}
          value={state.inboxName}
          onChangeText={(value: string) => {
            setState((prev) => {
              return {
                ...prev,
                inboxName: value,
              };
            });
          }}
          extraStyle={{flex: 1}}
        />

        <FormControl paddingY={4} isInvalid={state?.errors?.inboxMember}>
          <CustomUserSearch
            userSelectRef={undefined}
            isRequired={true}
            label={intl.formatMessage({id: 'addOrRemoveAgents'})}
            disabledUsers={[]}
            selectedUsersData={state.selectedUsersData}
            userSearchType={
              isCommunicationLocationHandlingEnabled
                ? USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED_WITH_BUSINESS_OWNER
                : USER_SEARCH_TYPE.DEFAULT
            }
            onChange={(selectedUsersUUIDs: string[]) => {
              setState((prev) => {
                return {
                  ...prev,
                  selectedUsersUuid: selectedUsersUUIDs,
                };
              });
            }}
          />
          {state?.errors?.inboxMember && !state?.selectedUsersUuid?.length && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {state?.errors?.inboxMember}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      </Stack>
    );
  };

  const getAddInboxElement = () => {
    return <Text>work in progress</Text>;
  };

  const getBodyElement = (drawerCode: string) => {
    switch (drawerCode) {
      case EFAX_DRAWER_CODE.UPDATE_INBOX:
        return getUpdateInboxElement();
      case EFAX_DRAWER_CODE.ADD_INBOX:
        return getAddInboxElement();
    }
  };

  return (
    <Drawer
      className="custom-drawer-styles"
      visible={props?.visible}
      width={drawerWidth}
      style={{paddingLeft: '0', paddingRight: '0'}}
      closable
      onClose={() => closeModal()}
      title={getTitleView()}
    >
      {state.loading && (
        <View style={styles.loadingOverlay}>
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner size={'lg'} />
          </Center>
        </View>
      )}
      {getBodyElement(props?.drawerCode)}
    </Drawer>
  );
};

const styles = StyleSheet.create({
  titleContainer: {
    alignItems: 'center',
  },
  titleButtonsContainer: {
    alignItems: 'center',
  },
  saveButtonContainer: {
    paddingRight: 20,
  },
  saveButton: {
    backgroundColor: Colors.Custom.mainPrimaryPurple,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
  },
  closeButtonContainer: {
    paddingLeft: 10,
    borderLeftWidth: 1,
    borderLeftColor: Colors.Custom.Gray300,
    marginVertical: 5,
  },
  updateInboxContainer: {
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  loadingOpacity: {
    opacity: 0.5,
  },
  purchasedNumberContainer: {
    paddingBottom: 16,
  },
  purchasedNumberLabel: {
    color: Colors.Custom.Gray500,
    fontSize: 14,
    paddingBottom: 4,
  },
  purchasedNumberContent: {
    alignItems: 'center',
  },
  loadingOverlay: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 10,
    backgroundColor: 'coolGray.50:alpha.70',
  },
});

export default AddOrUpdateEFaxInboxDrawer;
