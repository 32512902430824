//:: AUTHOR:: ABHAY-CHAUDHARY
//:: Common account Level Bulk Consent :: START

import BaseService from "../services/CommonService/BaseService";

export const getConsentDataInBulk = async ()=> {
  const axios = BaseService.getSharedInstance().axios;
  return axios.get('crm-nest/account/contact/consent_count')
}

export const giveConsentInBulk = async (userUuid: string, typeId: string)=> {
  const axios = BaseService.getSharedInstance().axios;
  return axios.post('crm-nest/account/contact/consent', {
    consentProviderId: userUuid,
    consentProviderTypeId: typeId
  })
}

//:: Common account Level Bulk Consent :: END
