import {Table} from 'antd';
import {Spinner, VStack} from 'native-base';
import {useState} from 'react';
import {Dimensions, View} from 'react-native';
import {useParams} from 'react-router-dom';
import PageBodyContainer from '../../../../../../common/PageBodyContainer/PageBodyContainer';
import {TableWrapper} from '../../../../../../common/TableWrapper';
import {IQualityMeasuresPatientList} from '../../../../HelperFiles/interface';
import {getQualifiedPatientList} from '../../../../HelperFiles/TablesColumnsView';

const PatientList = (props: IQualityMeasuresPatientList) => {
  const {stateData, handlePageChange, data, patientType} = props;
  const [patientListState, setPatientListState] = useState({
    selectedItems: [] as any,
    selectedRowKey: [] as any,
  })
  const {height} = Dimensions.get('window');
  const {contractId, qmId} = useParams();
  const finalHeight = height - 220;
  return (
    <PageBodyContainer>
      <TableWrapper
        wrapperStyle={{alignItems: 'center'}}
        minHeight={230}
        pagination={{
          current: stateData?.currentPage,
          pageSize: stateData?.pageSize || 10,
          total: stateData?.total,
          onChange: (currentPage, currentPageSize) => {
            handlePageChange?.(currentPage, currentPageSize);
          },
        }}
        hideOnSinglePage={false}
      >
        <Table
          scroll={{x: 1000, y: finalHeight}}
          rowClassName={(record, index) => {
            let className = index % 2 == 0 ? 'table-row-light' : ''
            className = className + ' quality-measures-report'
            return className
          }
          }
          className="lead-list-table-view patient-list-view"
          columns={getQualifiedPatientList({memberType: patientType})}
          dataSource={data}
          pagination={false}
          loading={{
            spinning: stateData.loading,
            indicator: (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Spinner size={'lg'} />
              </View>
            ),
          }}
          rowSelection={undefined}
          rowKey={(record) => {
            return record.id;
          }}
        />
      </TableWrapper>
    </PageBodyContainer>
  );
};

export default PatientList;
