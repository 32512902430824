import BaseService from "../../../../services/CommonService/BaseService";
import LocalStorage from "../../../../utils/LocalStorage";

const getHeaders = async () => {
    const userDataString = await LocalStorage.getItem('user');
    const formWidgetDataString = await LocalStorage.getItem('formWidgetData');
    const userData = JSON.parse(userDataString);
    const formWidgetData = formWidgetDataString ? JSON.parse(formWidgetDataString) : '';
    const formWidgetAccessToken = formWidgetData?.formWidgetAccessToken;
    const accountUUID =
      userData?.data.accounts.length > 0
        ? userData?.data.accounts[0].uuid || ''
        : '';
    const responseAccessToken = userData?.accessToken || '';
    const fold_access_token = userData?.fold_access_token;
    const headersObj: any = {
      'access-token': responseAccessToken,
      fold_access_token,
      accountUUID,
    };
    if (!fold_access_token && formWidgetAccessToken && formWidgetAccessToken !== 'undefined') {
      headersObj['form-widget-access-token'] = formWidgetAccessToken;
    }
    return headersObj;
  };

export const getCategoriesForCodeGroup = async () => {
    const headers = await getHeaders();
    const baseService = BaseService.getSharedInstance().axios;
    const response = await baseService.get(
      `rule-engine/api/codes?searchType=CodeGroupCategory`,
      {
        headers: headers
      }
    );
    return response
  };

export const createOrUpdateCodeGroup = async (body: any) => {
    const baseService = BaseService.getSharedInstance().axios;
    const method = body.id ? baseService.put : baseService.post;
    const url = `rule-engine/api/code-groups${body.id ? '/' + body.id : ''}`;
    const headers = await getHeaders();
    const response = await method(url, body, {
        headers,
      });
    return response;
};

export const getCodeGroups = async (args?: any) => {
    const baseService = BaseService.getSharedInstance().axios;
    const headers = await getHeaders();
    const response = await baseService.get(
      `rule-engine/api/code-groups`,
      {
        headers: {headers},
        params: {
          limit: args?.limit,
          offset: args?.offset,
          searchString: args?.searchString,
          ...(args?.category && { category: args?.category }),
          ...(args?.categories && { categories: args?.categories }),
          ...(args?.sort && { sort: args?.sort })
        }
      }
    );
    return response;
  };


  export const getProgramContactDataFromPopGroup = async (programCodes: string[]) => {
    const headers = await getHeaders();
    const baseService = BaseService.getSharedInstance().axios;
    const response = await baseService.get(
      `rule-engine/api/results?ruleCode=${programCodes.toString()}`,
      {
        headers: headers
      }
    );
    return response
  };
