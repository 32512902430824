import React from 'react';
import InfoSvg from '../../../../../common/Svg/InfoSvg';
import NoDataFileIcon from '../../../../../common/Svg/WidgetsSvg/NoDataFileIcon';
import NodataWidgetImage from '../../../../../common/Svg/WidgetsSvg/NodataWidgetImage';

const NodataViewTypeOne = (props: {viewCode: string, style?:any,text?:string}) => {
  const {viewCode,style, text} = props;

  const getNodataView = () => {
    let ele = <></>;
    switch (viewCode) {
      case 'ONLY_TEXT':
        ele = (
          <div
            style={
              style
                ? {...style, display: 'flex', alignItems: 'center'}
                : {display: 'flex', alignItems: 'center'}
            }
          >
            {' '}
            <div style={{marginRight: 5}}>
              <InfoSvg customDimension={'14'} customColor={'#D0D5DD'} />
            </div>{' '}
            <div style={{fontSize: 14, color: '#D0D5DD', fontWeight: 500}}>
              { text ? text : 'No data to show'}
            </div>
          </div>
        );
        break;
      case 'ONLY_ICON':
        ele = (
          <div style={{fontSize: 14, color: '#D0D5DD', fontWeight: 500}}>
            <NoDataFileIcon />
          </div>
        );
        break;
      case 'TEXT_ICON':
        ele = (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div>
            <NodataWidgetImage />
            </div>
            <div
              style={{
                fontSize: 14,
                color: '#98A2B3',
                fontWeight: 500,
                marginTop: 4,
              }}
            >
              { text ? text : 'No data to show'}
            </div>
          </div>
        );
        break;
    }
    return ele;
  };
  return <>{getNodataView()}</>;
};

export default React.memo(NodataViewTypeOne);
