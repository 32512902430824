import {View} from 'native-base';
import {useLocation, useRoutes} from 'react-router-dom';
import {getBusinessStudioRoutes} from '../../routes/BusinessStudioRoutes';
import { wrapUseRoutes } from "@sentry/react";
import React, { useEffect } from 'react';
import useMemoryMonitor from '../CustomHooks/useMemoryMonitor';
const RightSideContainer = (props: {
  showScrollIndicator: boolean
}) => {
  // const useSentryRoutes = wrapUseRoutes(useRoutes);

  if (props?.showScrollIndicator) {
    import('./scrollIndicatorStyle.css')
  }
  const {checkMemoryUsage} = useMemoryMonitor()
  const businessStudioRoutesElement = useRoutes(getBusinessStudioRoutes());
  const location = useLocation();
  
  useEffect(() => {
    checkMemoryUsage(location)
  },[location.pathname])

  return <View flex={1}>{businessStudioRoutesElement}</View>;
};

export default React.memo(RightSideContainer);
