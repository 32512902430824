import React from 'react';
import {Colors} from '../../../../styles/Colors';
import {ICommonSvgProps} from '../interfaces';

const AdminSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';
  return (
    <svg
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.325 9.675L4.44 17.56m.85-5.722l-3.861-.912a.558.558 0 01-.267-.936l3.071-3.072c.207-.206.486-.324.78-.326l3.462-.03m4.362-3.963a24.996 24.996 0 016.564 6.564m-9.24 7.546l.912 3.862c.1.425.627.575.936.267l3.071-3.071c.207-.207.325-.486.327-.78l.03-3.462m4.625-6.174l.9-4.336a1.672 1.672 0 00-1.977-1.978l-4.336.901a6.682 6.682 0 00-3.369 1.82L7.724 7.313a8.928 8.928 0 00-2.52 5.016l-.012.082a3.876 3.876 0 004.397 4.396l.082-.013a8.922 8.922 0 005.016-2.519l3.556-3.556a6.682 6.682 0 001.82-3.369z"
      ></path>
    </svg>
  );
};

export default React.memo(AdminSvg);
