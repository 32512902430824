import {Text, View} from 'native-base';
import React from 'react';
import JsonFormatter from 'react-json-formatter';
import {Colors} from '../../../../styles';
import {DisplayText} from '../../../common/DisplayText/DisplayText';

const jsonStyle = {
  propertyStyle: {color: 'red'},
  stringStyle: {color: 'green'},
  numberStyle: {color: 'darkorange'},
};

export const getAuditLogsListViewColumns = (sortedInfo: any): any[] => {
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="userInfo"
        />
      ),
      width: '10%',
      render: (data: any): JSX.Element => {
        const username = data?.userName;
        const userEmail = data?.userEmail;
        return (
          <>
            <View>
              {username ? (
                <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                  {username}
                </Text>
              ) : (
                <Text color={'gray.400'}>--</Text>
              )}
            </View>
            <View>
              {userEmail ? (
                <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                  {userEmail}
                </Text>
              ) : (
                <Text color={'gray.400'}>--</Text>
              )}
            </View>
          </>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="auditUrl"
        />
      ),
      width: '10%',
      render: (data: any): JSX.Element => {
        const type = data?.type;
        return (
          <View>
            {type ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {type}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="clientIp"
        />
      ),
      width: '10%',
      render: (data: any): JSX.Element => {
        const type = data?.clientIp;
        return (
          <View>
            {type ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {type}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="userAgent"
        />
      ),
      width: '10%',
      render: (data: any): JSX.Element => {
        const type = data?.userAgent;
        return (
          <View>
            {type ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {type}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="operation"
        />
      ),
      width: '10%',
      render: (data: any): JSX.Element => {
        const type = data?.jsonEvent?.operationName;
        return (
          <View>
            {type ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {type}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },

    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="variables"
        />
      ),
      width: '10%',
      render: (data: any): JSX.Element => {
        const type = data?.jsonEvent?.variables;
        return (
          <View>
            {type ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                <JsonFormatter
                  json={JSON.stringify(type)}
                  tabWith={4}
                  jsonStyle={jsonStyle}
                />
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="createdAt"
        />
      ),
      width: '10%',
      render: (data: any): JSX.Element => {
        const usageFrom = data?.createdAt;
        const date = new Date(usageFrom).toLocaleDateString('en-US', {
          year: 'numeric',
          day: 'numeric',
          month: 'long',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZoneName: 'short',
        });
        return (
          <View>
            {date ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {date}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="status"
        />
      ),
      width: '10%',
      render: (data: any): JSX.Element => {
        const status = data?.status;

        return (
          <View>
            {status ? (
              <Text
                color={
                  status == '200' ? Colors.success[600] : Colors.danger[600]
                }
                size={'smMedium'}
              >
                {status}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
    //       textLocalId="usageTo"
    //     />
    //   ),
    //   width: '10%',
    //   render: (data: any): JSX.Element => {
    //     const usageFrom = data?.usageTo;
    //     const date = new Date(usageFrom).toLocaleDateString('en-US', {
    //       year: 'numeric',
    //       day: 'numeric',
    //       month: 'long',
    //     });
    //     return (
    //       <View>
    //         {date ? (
    //           <Text size={'smMedium'} color={Colors.Custom.Gray900}>
    //             {date}
    //           </Text>
    //         ) : (
    //           <Text color={'gray.400'}>--</Text>
    //         )}
    //       </View>
    //     );
    //   },
    //   responsive: ['md'],
    // },
  ];
};
