import CommonService from "../CommonService/CommonService";
import { ICloneReportResp, IPowerBITokenParams, IPowerBITokenResp, IPowerBIWorkspaceResp, IPowerBiExportedReports } from "./interface";

export const getPowerBIWorkspaces = async (): Promise<{ data: IPowerBIWorkspaceResp }> => {
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.crmService;
  return axiosService.get('api/v1/power-bi/workspace');
};

export const getPowerBITokenInfoForReport = async (bodyData: IPowerBITokenParams): Promise<{ data: IPowerBITokenResp }> => {
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.crmService;
  return axiosService.post('api/v1/power-bi/token', JSON.stringify(bodyData));
};

export const cloneReportFromDefaultReportPowerBiToken = async (bodyData: {
  reportName: string
}): Promise<{ data: ICloneReportResp }> => {
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.crmService;
  return axiosService.post('api/v1/power-bi/report/clone', JSON.stringify(bodyData));
};

export const getPowerBiExportedReportDetails = async (reportId: string): Promise<{ data: IPowerBiExportedReports[] }> => {
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.crmService;
  return axiosService.get(`api/v1/power-bi/report/${reportId}/export/files`);
};

export const exportPowerBiReport = async (powerBiReportId: string, powerBiWorkspaceId: string, fileFormat: 'PDF' | 'PPTX' | 'PNG') => {
  const body = { powerBiReportId, powerBiWorkspaceId, fileFormat }
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.crmService;
  return axiosService.post('api/v1/power-bi/report/export', JSON.stringify(body));
}

export const getExportedPowerBiReport = async (powerBiReportId: string, powerBiWorkspaceId: string, exportId: string) => {
  const body = { powerBiReportId, powerBiWorkspaceId, exportId }
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.crmService;
  return axiosService.post('api/v1/power-bi/report/export/file', JSON.stringify(body));
}
