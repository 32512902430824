import {useMutation} from '@apollo/client';
import {Drawer} from 'antd';
import {Button, Modal, Skeleton, View, VStack} from 'native-base';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions, StyleSheet} from 'react-native';
import {BUTTON_TYPE} from '../../../../../constants';
import ResponseQueries from '../../../../../services/Responses/ResponseQueries';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {IDeleteResponseProps} from '../interfaces';

const {Content, CloseButton, Header, Footer, Body} = Modal;

export const DeleteResponse = (props: IDeleteResponseProps) => {
  const intl = useIntl();
  const [deleteResponse] = useMutation(ResponseQueries.DeleteResponse);
  const [loading, setLoading] = useState(false);
  const [stateData, setStateData] = useState({
    modalVisible: true,
  });
  const onDeleteAction = async () => {
    setLoading(true);
    const deleteResObj = await deleteResponse({
      variables: {id: props.recordData.id},
    });
    if (deleteResObj?.data?.deleteCannedResponse?.id) {
      setStateData((prev) => {
        return {
          ...prev,
          modalVisible: false,
        };
      });
      props.handleCloseAfterUpdateModal();
    } else {
      setLoading(false);
      props.handleCloseAfterUpdateModal();
    }
  };
  const {width} = Dimensions.get('window');
  const finalWidth = width / 3;
  return (
    <Modal
      isOpen={stateData.modalVisible}
      onClose={() => {
        setStateData((prev) => {
          return {
            ...prev,
            modalVisible: false,
          };
        });
        props.handleCloseModal();
      }}
    >
      <Content style={styles.content}>
        <CloseButton />
        <Header>
          <DisplayText textLocalId="deleteResponse" textType="Heading" />
        </Header>
        <Body>
          <DisplayText
            textLocalId="deleteResponseDescription"
            textType="Text"
          />
        </Body>
        <Footer style={styles.footer}>
          <Button.Group>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      modalVisible: false,
                    };
                  });
                  props.handleCloseModal();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onDeleteAction();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'confirm',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </Footer>
      </Content>
    </Modal>
  );
};

const styles = StyleSheet.create({
  content: {
    backgroundColor: Colors.Custom.BackgroundColor,
  },
  footer: {
    backgroundColor: Colors.Custom.BackgroundColor,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
