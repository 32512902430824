import {Tooltip} from 'antd';
import Stack from '../../../common/LayoutComponents/Stack';
import {IconButton} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../styles/Colors';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {IEFaxInboxAction} from './interface';
import { StyleSheet } from 'react-native';

export const EFaxInboxTableAction = (props: IEFaxInboxAction) => {
  return (
    <Stack direction="row" style={styles.stackContainer}>
      <Tooltip title={'Edit'} placement="top">
        <IconButton
          icon={
            <Feather name="edit-2" size={17} color={Colors.Custom.Gray600} />
          }
          _hover={{background: 'none'}}
          onPress={() => {
            props?.onActionPerformed(COMMON_ACTION_CODES.EDIT, props?.record);
          }}
        />
      </Tooltip>
      {/* <Tooltip title={'Delete'} placement="top"> // code changes required for V2 of eFax
        <IconButton
          icon={
            <Feather name="trash-2" size={17} color={Colors.Custom.Gray600} />
          }
          _hover={{background: 'none'}}
          onPress={() => {
            props.onActionPerformed(COMMON_ACTION_CODES.DELETE, props.record);
          }}
        />
      </Tooltip> */}
    </Stack>
  );
};

const styles = StyleSheet.create({
  stackContainer: {
    alignItems: 'center'
  }
});