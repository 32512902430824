export const PREFFERED_DURATION_CODE = {
  WHILE_ENROLL: 'whileEnrolling',
  FIXED_DATE: 'fixedDate',
};

export const PRODUCT_TYPE_CATEGORY = {
  PRODUCT: 'PRODUCT',
  PACKAGE: 'PACKAGE',
};

export const PRODUCT_PACKAGE_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
};

export const PRODUCT_PRICING_TYPE = {
  FREE: 'FREE',
  PURCHASE: 'PURCHASE',
};

export const FILTER_PRICE = [
  {
    key: 'ALL',
    value: 'ALL',
    label: 'All',
  },
  {
    key: PRODUCT_PRICING_TYPE.FREE,
    value: PRODUCT_PRICING_TYPE.FREE,
    label: 'Free',
  },
  {
    key: PRODUCT_PRICING_TYPE.PURCHASE,
    value: PRODUCT_PRICING_TYPE.PURCHASE,
    label: 'Paid',
  },
];

export const PACKAGE_MLOV = 'PACKAGE'
export const PACKAGES_TAG_VALUE_MAX_DISPLAY_CHAR = 13;
export const PAGE_LIMIT = 10;