import React from "react";

const TaskWidgetIcon =  () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#6F7A90"
        d="M8.043 19.233a.75.75 0 10-1.086 1.034l1.086-1.034zm.886 2.017l-.544.517a.75.75 0 001.087 0l-.543-.517zm4.114-3.233a.75.75 0 10-1.086-1.034l1.086 1.034zm-5-7.534a.75.75 0 10-1.086 1.034l1.086-1.034zM8.93 12.5l-.544.517a.75.75 0 001.087 0l-.543-.517zm4.114-3.233a.75.75 0 10-1.086-1.034l1.086 1.034zM16.25 10.5a.75.75 0 000 1.5v-1.5zM22.5 12a.75.75 0 000-1.5V12zm-6.25 7.25a.75.75 0 000 1.5v-1.5zm6.25 1.5a.75.75 0 000-1.5v1.5zm-7.5 6c-2.967 0-5.118-.002-6.758-.222-1.619-.218-2.628-.636-3.381-1.389L3.8 26.2c1.077 1.079 2.456 1.575 4.241 1.816 1.764.237 4.033.235 6.958.235v-1.5zM1.75 15c0 2.925-.002 5.194.236 6.958.24 1.785.736 3.164 1.814 4.242l1.06-1.06c-.752-.754-1.17-1.763-1.388-3.382-.22-1.64-.222-3.79-.222-6.758h-1.5zm25 0c0 2.968-.002 5.118-.222 6.758-.218 1.619-.636 2.628-1.389 3.381l1.06 1.06c1.079-1.077 1.575-2.456 1.816-4.241.237-1.764.235-4.033.235-6.958h-1.5zM15 28.25c2.925 0 5.194.002 6.958-.235 1.785-.24 3.164-.737 4.242-1.815l-1.06-1.06c-.754.752-1.763 1.17-3.382 1.388-1.64.22-3.79.222-6.758.222v1.5zm0-25c2.968 0 5.118.002 6.758.222 1.619.218 2.628.636 3.381 1.389L26.2 3.8c-1.077-1.079-2.456-1.575-4.241-1.815-1.764-.238-4.033-.236-6.958-.236v1.5zM28.25 15c0-2.925.002-5.194-.235-6.958-.24-1.785-.737-3.164-1.815-4.242l-1.06 1.06c.752.754 1.17 1.763 1.388 3.382.22 1.64.222 3.79.222 6.758h1.5zM15 1.75c-2.925 0-5.194-.002-6.958.236-1.785.24-3.164.736-4.242 1.814l1.06 1.06c.754-.752 1.763-1.17 3.382-1.388 1.64-.22 3.79-.222 6.758-.222v-1.5zM3.25 15c0-2.967.002-5.118.222-6.758.218-1.619.636-2.628 1.389-3.381L3.8 3.8C2.721 4.877 2.225 6.256 1.985 8.041 1.748 9.806 1.75 12.075 1.75 15h1.5zm3.707 5.267l1.428 1.5 1.087-1.034-1.429-1.5-1.086 1.034zm2.515 1.5l3.571-3.75-1.086-1.034-3.572 3.75 1.087 1.034zm-2.515-10.25l1.428 1.5 1.087-1.034-1.429-1.5-1.086 1.034zm2.515 1.5l3.571-3.75-1.086-1.034-3.572 3.75 1.087 1.034zM16.25 12h6.25v-1.5h-6.25V12zm0 8.75h6.25v-1.5h-6.25v1.5z"
      ></path>
    </svg>
  );
}

export default TaskWidgetIcon;
