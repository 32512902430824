import React from "react";

const ProjectedROISvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
     <svg
     xmlns="http://www.w3.org/2000/svg"
     style={{width: 'inherit', height: 'inherit'}}
     fill="none"
     viewBox="0 0 52 52"
   >
     <path
       fill={svgColors}
       d="M26 4.334c-11.946 0-21.666 9.72-21.666 21.667 0 11.947 9.72 21.666 21.667 21.666 11.947 0 21.666-9.72 21.666-21.666 0-11.947-9.72-21.667-21.666-21.667zm2.167 32.316V39h-4.333v-2.34c-5.068-.794-6.5-4.337-6.5-6.326h4.333c.024.31.345 2.167 4.334 2.167 2.99 0 4.333-1.268 4.333-2.167 0-.702 0-2.167-4.333-2.167-7.54 0-8.667-4.073-8.667-6.5 0-2.79 2.23-5.598 6.5-6.316v-2.324h4.333v2.403c3.757.888 5.2 4.014 5.2 6.237h-2.166l-2.167.04c-.03-.824-.466-2.206-3.033-2.206-2.815 0-4.334 1.118-4.334 2.166 0 .81 0 2.167 4.334 2.167 7.54 0 8.666 4.073 8.666 6.5 0 2.79-2.23 5.599-6.5 6.316z"
     ></path>
   </svg>
  );
}

export default React.memo(ProjectedROISvg);
