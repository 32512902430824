import { Popover } from 'antd';
import { Divider, HStack, Text, View, VStack } from 'native-base';
import React from 'react';
import { isCurrentMenuLast } from '../../../../../../../utils/commonUtils';
import { MemberGroupResponse, MemberGroupsProps } from '../../../interfaces';

const MemberGroups = (props: MemberGroupsProps) => {
  const {contactData} = props;
  const groupList: MemberGroupResponse[] = contactData?.groups || []
  const showPopover = groupList.length > 0;
  return (
    <HStack
      style={{
        flexWrap: 'wrap',
      }}
    >
      {groupList.length === 0 && <Text>--</Text>}
      {showPopover && (
        <>
          <Popover
            content={
              <VStack>
                {groupList.map((item, index) => {
                  return (
                    <View
                      style={{
                        marginVertical: 2,
                      }}
                    >
                      <Text
                        style={{
                          marginHorizontal: 4,
                          marginVertical: 2,
                        }}
                      >
                        {item.name || ''}
                      </Text>
                      {isCurrentMenuLast(index, groupList) && <Divider />}
                    </View>
                  );
                })}
              </VStack>
            }
          >
            <Text>{`${groupList.length}`}</Text>
          </Popover>
        </>
      )}
    </HStack>
  );
};

export default MemberGroups;
