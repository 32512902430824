import React from 'react';
import {Colors} from '../../../../styles';
import {ICommonSvgProps} from '../interfaces';

const TimerSvg = (props: ICommonSvgProps) => {
  const {isSelected, defaultColor} = props;
  const strokeColor = isSelected
    ? defaultColor
      ? defaultColor
      : Colors.primary['300']
    : defaultColor
    ? defaultColor
    : '#fff';

  return (
    <svg
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 5v6l4 2m6-2c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10z"
      ></path>
    </svg>
  );
};

export default React.memo(TimerSvg);
