import {useLazyQuery, useMutation} from '@apollo/client';
import {Steps} from 'antd';
import {
  Box,
  Button,
  CheckIcon,
  FormControl, HStack, Select,
  Text,
  useToast,
  View,
  VStack
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {BUTTON_TYPE} from '../../../../constants';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {LeadQueries} from '../../../../services';
import EmployeeQueries, {createEmployee} from '../../../../services/Employee/EmployeeQueries';
import EmployerQueries from '../../../../services/Employer/EmployerQueries';
import {uploadEmployerLogo} from '../../../../services/Employer/EmployerServices/EmployerServices';
import {GET_MANAGER_ROLES} from '../../../../services/User/UserQueries';
import {
  getCityId,
  getStatesById,
  getZipCodeById
} from '../../../../services/ZipCode/ZipCodeQueries';
import {Colors} from '../../../../styles';
import {getAccountId, getAccountUUID, getEmployerId, getUserActionObjectFromActionCode, getUserUUID} from '../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {getContactTypeId, getMlovId, getMlovListByCategory} from '../../../../utils/mlovUtils';
import UserAutoComplete, {IUserSearch} from '../../../common/CalendarWidget/UserAutoComplete/UserAutoComplete';
import {CityAutoComplete} from '../../../common/City';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import {ModalActionInput} from '../../../common/ModalActionCommonComponent/ModalActionInput';
import {ModalActionSelect} from '../../../common/ModalActionCommonComponent/ModalActionSelect';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import {StateAutoComplete} from '../../../common/State';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import {handleCreateEmployerAction} from '../../../common/UsageLevel/UsageLevelHandlers';
import {ZipCodeAutoComplete} from '../../../common/ZipCodeComponent';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import { capitalizeText } from '../../ContentManagement/CampaignManagement/Helper';
import {
  ICompanyAddress,
  ICompanyContactInfo,
  ICompanyDeals,
  ICompanyInfo,
  IEmployerCreateErrors,
  IEmployerDetails
} from '../interfaces';
import {styles} from './EmployerCreateStyles';
import {UploadLogo} from './UploadLogo';

const EmployerQuickCreateModal = (props: any) => {
  const {onModalClose, initData, isEmployerRoleDealEdit} = props;
  const userActionObject = getUserActionObjectFromActionCode(
    'CREATE_AN_EMPLOYER_AND_COUPLE_OF_EMPLOYEES'
  );
  const employerId = getEmployerId()
  const addressId = initData?.raw?.address[0]?.id
  const level = userActionObject.level || '';
  const mlovData = useContext(CommonDataContext);
  const toast = useToast();
  const contactTypeUuid = getContactTypeId('LEAD');
  const [employerDetails, setEmployerDetails] = useState<IEmployerDetails>({
    companyInfo: {
      name: initData?.raw?.name || '',
      industry: initData?.raw?.industryId,
      email: initData?.raw?.email || '',
      phoneNumber: initData?.raw?.phoneNumber || '',
    },
    address: {
      zipcode: initData?.raw?.address?.[0]?.zipcodeId || '',
      address: initData?.raw?.address?.[0]?.line1 || '',
      city: initData?.raw?.address?.[0]?.cityId || '',
      province: initData?.raw?.address?.[0]?.stateId || '',
      zipcodeName: initData?.raw?.address?.[0]?.zipCode?.code,
      provinceName: initData?.raw?.address?.[0]?.state?.name,
      cityName: initData?.raw?.address?.[0]?.city?.name,
    },
    contactInfo: {
      contactName:
        initData?.raw?.employerDeal?.[0]?.contactEmployee?.contact?.name || '',
      contactEmail:
        initData?.raw?.employerDeal?.[0]?.contactEmployee?.contact?.email || '',
      contactPhone:
        initData?.raw?.employerDeal?.[0]?.contactEmployee?.contact
          ?.phoneNumber || '',
    },
    deals: {
      status: initData?.raw?.employerDeal?.[0]?.statusId || '',
      accountManager: initData?.raw?.employerDeal?.[0]?.managerId || '',
    },
  });
  const [stateData, setStateData] = useState({
    profileImgSrc: '',
    profileImageBlob: {} as any,
    uploadedImage: '',
    employerData: {} as any
  });
  const [EmployerById] = useLazyQuery(EmployerQueries.getEmployerById, {
    fetchPolicy: 'no-cache'//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });
  const accountId = getAccountUUID();
  const tenantId = getAccountId();
  const userUuid = getUserUUID();

  const {Step} = Steps;

  const [employerDetailsError, setEmployerDetailsError] =
    useState<IEmployerCreateErrors>({
      address: {},
      companyInfo: {},
      contactInfo: {},
      deals: {},
    });
  const intl = useIntl();

  const [createEmployer] = useMutation(EmployerQueries.createEmployer);
  const [updateEmployer] = useMutation(EmployerQueries.updateEmployer);
  const [createEmployerAddress] = useMutation(EmployerQueries.createEmployerAddress);
  const [updateEmployerAddress] = useMutation(EmployerQueries.updateEmployerAddress);
  const [updateEmployerDeal] = useMutation(EmployerQueries.updateEmployerDeal);
  const [updateEmployees] = useMutation(EmployeeQueries.updateEmployees);
  const [createEmployerDeal] = useMutation(EmployerQueries.createEmployerDeal);
  const [createEmployee] = useMutation(EmployerQueries.createEmployee);
  const [updateContact] = useMutation(EmployerQueries.updateContact);

  const [CreateContactTypeEmp] = useMutation(LeadQueries.CreateContactType)

  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [managers, setManagers] = useState<any[]>([]);
  const dealStatusList = getMlovListByCategory('DealStatus');
  const [getManagers] = useLazyQuery(GET_MANAGER_ROLES, {
    fetchPolicy: 'no-cache',
    variables: {
      tenantId: tenantId,
    },
  });

  const [getCityIdLocal] = useLazyQuery(getCityId, {
    fetchPolicy: 'no-cache',
  });

  const [getStatesByIdLocal] = useLazyQuery(getStatesById, {
    fetchPolicy: 'no-cache',
  });

  const [getZipCodeByIdLocal] = useLazyQuery(getZipCodeById, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getManagers()
      .then((resp) => {
        if (resp?.data?.users) {
          setManagers(resp?.data?.users);
        }
      })
      .catch((e) => {

      });
  }, []);

  useEffect(() => {
    getEmployerById()
    if (props?.initData) {
      getCityIdLocal({
        variables: {
          cityId: initData?.raw?.address?.[0]?.cityId,
        },
      });

      getZipCodeByIdLocal({
        variables: {
          zipCodeId: initData?.raw?.address?.[0]?.zipcodeId,
        },
      });

      getStatesByIdLocal({
        variables: {
          stateId: initData?.raw?.address?.[0]?.stateId,
        },
      });
    }
  }, [props]);

  const validateCompanyInfo = (): boolean => {
    const errorFields: ICompanyInfo = {};
    if (!employerDetails?.companyInfo?.name) {
      errorFields.name = 'required';
    }
    // if (!employerDetails?.companyInfo?.industry) {
    //   errorFields.industry = 'required';
    // }
    if (employerDetails?.companyInfo?.email) {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!employerDetails?.companyInfo?.email.match(emailRegex)) {
        errorFields.email = 'please enter valid email address';
      }
    }
    if (employerDetails?.companyInfo?.phoneNumber) {
      const phoneNumberRegex =
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
      if (!employerDetails?.companyInfo?.phoneNumber.match(phoneNumberRegex)) {
        errorFields.phoneNumber = 'invalid format';
      }
    }
    setEmployerDetailsError({
      ...employerDetailsError,
      companyInfo: errorFields,
    });
    if (JSON.stringify(errorFields) === '{}') {
      return true;
    } else {
      return false;
    }
  };

  const validateCompanyAddress = (): boolean => {
    const errorFields: ICompanyAddress = {};
    if (!employerDetails?.address?.address) {
      errorFields.address = 'required';
    }
    if (!employerDetails?.address?.province) {
      errorFields.province = 'required';
    }
    if (!employerDetails?.address?.city) {
      errorFields.city = 'required';
    }
    if (!employerDetails?.address?.zipcode) {
      errorFields.zipcode = 'required';
    }
    setEmployerDetailsError({
      ...employerDetailsError,
      address: errorFields,
    });
    if (JSON.stringify(errorFields) === '{}') {
      return true;
    } else {
      return false;
    }
  };

  const validateContactInfo = (): boolean => {
    const errorFields: ICompanyContactInfo = {};
    if (!employerDetails?.contactInfo?.contactName) {
      errorFields.contactName = 'required';
    }
    if (!employerDetails?.contactInfo?.contactEmail) {
      errorFields.contactEmail = 'required';
    } else {
      if (employerDetails?.contactInfo?.contactEmail) {
        const emailRegex =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!employerDetails?.contactInfo?.contactEmail.match(emailRegex)) {
          errorFields.contactEmail = 'please enter valid email address';
        }
      }
    }
    if (!employerDetails?.contactInfo?.contactPhone) {
      errorFields.contactPhone = 'required';
    } else {
      const phoneNumberRegex =
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
      if (!employerDetails?.contactInfo?.contactPhone.match(phoneNumberRegex)) {
        errorFields.contactPhone = 'invalid format';
      }
    }
    setEmployerDetailsError({
      ...employerDetailsError,
      contactInfo: errorFields,
    });
    if (JSON.stringify(errorFields) === '{}') {
      return true;
    } else {
      return false;
    }
  };

  const validateDealStatus = (): boolean => {
    const errorFields: ICompanyDeals = {};
    // if (!employerDetails?.deals?.accountManager) {
    //   errorFields.accountManager = 'required';
    // }
    // if (!employerDetails?.deals?.status) {
    //   errorFields.status = 'required';
    // }
    setEmployerDetailsError({
      ...employerDetailsError,
      deals: errorFields,
    });
    if (JSON.stringify(errorFields) === '{}') {
      return true;
    } else {
      return false;
    }
  };

  const onSubmitForm = (): void => {
    switch (current) {
      case 0: {
        if (validateCompanyInfo()) {
          setCurrent(current + 1);
        }
        return;
      }
      case 1: {
        if (validateCompanyAddress()) {
          setCurrent(current + 1);
        }
        return;
      }
      case 2: {
        if (validateContactInfo()) {
          setCurrent(current + 1);
        }
        return;
      }
      default:
        break;
    }
    if (validateDealStatus()) {
      setLoading(true);
      if (initData && initData?.id) {
        const promiseList = [
          updateEmployer({
            variables: {
              employerId: initData?.id,
              employer: {
                name: employerDetails?.companyInfo?.name,
                email: employerDetails?.companyInfo?.email,
                phoneNumber: employerDetails?.companyInfo?.phoneNumber,
                industryId: employerDetails?.companyInfo?.industry
              },
            },
          })
        ]
        if (addressId) {
          promiseList.push(updateEmployerAddress({
            variables: {
              employerAddressData: {
                zipcodeId: employerDetails?.address?.zipcode?.id,
                line1: employerDetails?.address?.address,
                cityId: employerDetails?.address?.city,
                stateId: employerDetails?.address?.province,
              },
              employerId: initData?.id
            },
          }))
        } else {
          promiseList.push(createEmployerAddress({
            variables: {
              employerAddressData: {
                zipcodeId: employerDetails?.address?.zipcode?.id,
                line1: employerDetails?.address?.address,
                cityId: employerDetails?.address?.city,
                stateId: employerDetails?.address?.province,
                accountUuid: accountId,
                employerId: initData?.id
              },
            },
          }))
        }
        const employerDealId = initData?.raw?.employerDeal?.[0]?.id;
        if (employerDealId) {
          promiseList.push(updateEmployerDeal({
            variables: {
              employerDealId: initData?.raw?.employerDeal?.[0]?.id,
              employerDeal: {
                statusId: employerDetails?.deals?.status,
                managerId: employerDetails?.deals?.accountManager,
              }
            },
          }))
        } else {
          if (!employerId) {
            promiseList.push(createEmployerDeal({
              variables: {
                employerDealObject: {
                  managerId: employerDetails?.deals?.accountManager,
                  accountUuid: accountId,
                  employerId: initData?.id,
                  statusId: employerDetails?.deals?.status
                }
              }
            }))
          }
        }
        const contactEmployeeId = initData?.raw?.employerDeal?.[0]?.contactEmployee?.id;
        if (contactEmployeeId) {
          promiseList.push(updateEmployees({
            variables: {
              contactEmployeeId:
                initData?.raw?.employerDeal?.[0]?.contactEmployee?.id,
              contactEmployee: {
                roleId: null,
              },
            }
          }))
        }
        const contactId = initData?.raw?.employerDeal?.[0]?.contactEmployee?.contact?.id;
        if (contactId) {
          updateContact({
            variables: {
              contact: {
                accountId: tenantId,
                name: employerDetails?.contactInfo?.contactName,
                email: employerDetails?.contactInfo?.contactEmail,
                phoneNumber: employerDetails?.contactInfo?.contactPhone,
              },
              contactId: initData?.raw?.employerDeal?.[0]?.contactEmployee?.contact?.id
            }
          })

        } else {
          promiseList.push(createEmployee({
            variables: {
              accountUuid: accountId,
              employerId: stateData?.employerData?.id,
              contact: {
                data: {
                  accountId: tenantId,
                  name: employerDetails?.contactInfo?.contactName,
                  email: employerDetails?.contactInfo?.contactEmail,
                  phoneNumber: employerDetails?.contactInfo?.contactPhone
                },
              },
            }
          }))
        }
        Promise.all(promiseList).then((response) => {
          if (response.length) {
            const newEmployeeId = response[3]?.data?.createEmployee?.id || ''
            if (newEmployeeId) {
              updateEmployerDeal({
                variables: {
                  employerDealId: initData?.raw?.employerDeal?.[0]?.id,
                  employerDeal: {
                    statusId: employerDetails?.deals?.status,
                    managerId: employerDetails?.deals?.accountManager,
                    contactEmployeeId: newEmployeeId
                  }
                },
              })
            }
          }
          if (stateData.profileImgSrc) {
            uploadEmployersLogo(initData?.id)
          }
          onModalClose(true);
          showToast(
            toast,
            'Update successfully',
            ToastType.success,
          );
        }).catch(() => {
          setLoading(false);
        });
        return;
      }
      createEmployer({
        variables: {
          employer: {
            name: employerDetails?.companyInfo?.name,
            accountUuid: accountId,
            email: employerDetails?.companyInfo?.email,
            phoneNumber: employerDetails?.companyInfo?.phoneNumber,
            address: {
              data: [
                {
                  zipcodeId: employerDetails?.address?.zipcode?.id,
                  line1: employerDetails?.address?.address,
                  cityId: employerDetails?.address?.city,
                  stateId: employerDetails?.address?.province,
                  accountUuid: accountId,
                },
              ],
            },
            employerDeal: {
              data: [
                {
                  statusId: employerDetails?.deals?.status,
                  managerId: employerDetails?.deals?.accountManager,
                  accountUuid: accountId,
                  contactEmployee: {
                    data: {
                      accountUuid: accountId,
                      contact: {
                        data: {
                          accountId: tenantId,
                          name: employerDetails?.contactInfo?.contactName,
                          email: employerDetails?.contactInfo?.contactEmail,
                          phoneNumber:
                            employerDetails?.contactInfo?.contactPhone,
                        },
                      },
                    },
                  },
                },
              ],
            },
          },
        },
        onCompleted: (error) => {
          if (
            (error ? error.toString() : '').indexOf(
              'uniq_email_per_account_contact'
            ) != -1
          ) {
            showToast(toast, 'Email already exist', ToastType.error);
          }
          // else {
          //   showToast(toast, 'Something went wrong!!!', ToastType.error);
          // }
          setLoading(false);
        },
      })
        .then(async (resp) => {
          if (resp?.data?.createEmployer?.id) {
            if (stateData.profileImgSrc) {
              uploadEmployersLogo(resp?.data?.createEmployer?.id)
            }
            const contactId = resp?.data?.createEmployer?.employerDeal[0]?.contactEmployee?.contact?.id
            const createContactResp = await CreateContactTypeEmp({
              variables: {
                contactId: contactId,
                typeId: contactTypeUuid
              }
            })
            handleCreateEmployerAction({
              accountUuid: accountId,
              userUuid: userUuid,
              level
            }).then((response) => {
              showToast(
                toast,
                'Employer created successfully',
                ToastType.success
              );
              onModalClose(true);
            });
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      return;
    }
  };

  const renderDealStatus = (): JSX.Element => {
    return (
      <VStack>
        <FormControl
          isInvalid={employerDetailsError?.deals?.status ? true : false}
          style={[styles.formElement]}
        >
          <FormControl.Label style={styles.formLabel}>
            <DisplayText
              textLocalId={isEmployerRoleDealEdit ? 'status' : 'selectDealStatus'}
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray700}}
            />
          </FormControl.Label>
          <Select
            isDisabled={isEmployerRoleDealEdit}
            placeholder={intl.formatMessage({id: isEmployerRoleDealEdit ? 'status' : 'selectDealStatus'})}
            selectedValue={employerDetails?.deals?.status || undefined}
            accessibilityLabel={intl.formatMessage({
              id: isEmployerRoleDealEdit ? 'status' : 'selectDealStatus',
            })}
            _selectedItem={{
              endIcon: <CheckIcon size="5" />,
            }}
            onValueChange={(itemValue) => {
              setEmployerDetails({
                ...employerDetails,
                deals: {
                  ...employerDetails.deals,
                  status: itemValue,
                },
              });
            }}
          >
            {dealStatusList.map((value: any) => {
              return (
                <Select.Item
                  key={value.id}
                  label={value.value}
                  value={value.id}
                />
              );
            })}
          </Select>
          {/* {employerDetailsError?.deals?.status && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {employerDetailsError?.deals?.status}
            </FormControl.ErrorMessage>
          )} */}
        </FormControl>
        <FormControl
          isInvalid={employerDetailsError?.deals?.accountManager ? true : false}
          style={[styles.formElement]}
        >
          <UserAutoComplete
            labelText={intl.formatMessage({id: isEmployerRoleDealEdit ? 'accountManager' : 'selectAccountManager'})}
            label={intl.formatMessage({id: isEmployerRoleDealEdit ? 'accountManager' : 'selectAccountManager'})}
            selectedUserUUID={employerDetails?.deals?.accountManager}
            allowClear={false}
            isDisabled={isEmployerRoleDealEdit}
            onChange={(user: IUserSearch) => {
              setEmployerDetails({
                ...employerDetails,
                deals: {
                  ...employerDetails.deals,
                  accountManager: user.value,
                },
              });
            }}
            usersData={managers}
            backgroundColor={'white'}
          />
          {/* {employerDetailsError?.deals?.accountManager && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {employerDetailsError?.deals?.accountManager}
            </FormControl.ErrorMessage>
          )} */}
        </FormControl>
        {/* <Text size={'smMedium'} marginTop={5} color={Colors.primary[700]}>
          {intl.formatMessage({id: 'createNewAccountManager'})}
        </Text> */}
      </VStack>
    );
  };
  const renderContactInfo = (): JSX.Element => {
    return (
      <VStack>
        <ModalActionInput
          marginTop={4}
          fieldIs={'input'}
          label={'contactName'}
          isRequired={true}
          isInvalid={
            employerDetailsError?.contactInfo?.contactName ? true : false
          }
          errors={employerDetailsError?.contactInfo?.contactName}
          errorText={employerDetailsError?.contactInfo?.contactName}
          defaultValue={employerDetails?.contactInfo?.contactName}
          value={employerDetails?.contactInfo?.contactName}
          onChangeText={(value: string) => {
            setEmployerDetails({
              ...employerDetails,
              contactInfo: {
                ...employerDetails.contactInfo,
                contactName: value,
              },
            });
          }}
          extraStyle={{flex: 1}}
        />

        <ModalActionInput
          marginTop={4}
          fieldIs={'input'}
          label={'contactEmail'}
          isRequired={true}
          isInvalid={
            employerDetailsError?.contactInfo?.contactEmail ? true : false
          }
          errors={employerDetailsError?.contactInfo?.contactEmail}
          errorText={employerDetailsError?.contactInfo?.contactEmail}
          defaultValue={employerDetails?.contactInfo?.contactEmail}
          value={employerDetails?.contactInfo?.contactEmail}
          onChangeText={(value: string) => {
            setEmployerDetails({
              ...employerDetails,
              contactInfo: {
                ...employerDetails.contactInfo,
                contactEmail: value.trim(),
              },
            });
          }}
          extraStyle={{flex: 1}}
        />

        <ModalActionInput
          marginTop={4}
          fieldIs={'input'}
          label={'contactPhone'}
          isRequired={true}
          isInvalid={
            employerDetailsError?.contactInfo?.contactPhone ? true : false
          }
          errors={employerDetailsError?.contactInfo?.contactPhone}
          errorText={employerDetailsError?.contactInfo?.contactPhone}
          defaultValue={employerDetails?.contactInfo?.contactPhone}
          value={employerDetails?.contactInfo?.contactPhone}
          onChangeText={(value: string) => {
            setEmployerDetails({
              ...employerDetails,
              contactInfo: {
                ...employerDetails.contactInfo,
                contactPhone: value,
              },
            });
          }}
          extraStyle={{flex: 1}}
        />
      </VStack>
    );
  };

  const renderCompanyAddress = (): JSX.Element => {
    return (
      <VStack>
        <FormControl
          style={styles.formElement}
          isRequired
          isInvalid={employerDetailsError?.address?.zipcode ? true : false}
        >
          <ZipCodeAutoComplete
            name={employerDetails?.address?.zipcodeName || ''}
            onAutoCompleteChange={(selectedId) => {
              setEmployerDetails({
                ...employerDetails,
                address: {
                  ...employerDetails.address,
                  zipcode: selectedId,
                  zipcodeName: selectedId?.code,
                  province: selectedId?.stateId,
                  provinceName: selectedId?.zipcodeState?.name,
                  city: selectedId?.cityId,
                  cityName: selectedId?.zipcodeCity?.name,
                },
              });
            }}
          />
          {employerDetailsError?.address?.zipcode && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {employerDetailsError?.address?.zipcode}
            </FormControl.ErrorMessage>
          )}
        </FormControl>

        <ModalActionInput
          marginTop={4}
          fieldIs={'input'}
          label={'address'}
          isRequired={true}
          isInvalid={employerDetailsError?.address?.address ? true : false}
          errors={employerDetailsError?.address?.address}
          errorText={employerDetailsError?.address?.address}
          defaultValue={employerDetails?.address?.address}
          placeholder={'Address'}
          value={employerDetails?.address?.address || ''}
          onChangeText={(value: string) => {
            setEmployerDetails({
              ...employerDetails,
              address: {
                ...employerDetails.address,
                address: value,
              },
            });
          }}
          extraStyle={{flex: 1}}
        />

        <FormControl
          style={styles.formElement}
          isRequired
          isInvalid={employerDetailsError?.address?.city ? true : false}
        >
          <CityAutoComplete
            name={employerDetails?.address?.cityName}
            onAutoCompleteChange={(selectedId) => {
              setEmployerDetails({
                ...employerDetails,
                address: {
                  ...employerDetails.address,
                  city: selectedId,
                },
              });
            }}
          />
          {employerDetailsError?.address?.city && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {employerDetailsError?.address?.city}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
        <FormControl
          style={styles.formElement}
          isRequired
          isInvalid={employerDetailsError?.address?.province ? true : false}
        >
          <StateAutoComplete
            name={employerDetails?.address?.provinceName}
            onAutoCompleteChange={(selectedId) => {
              setEmployerDetails({
                ...employerDetails,
                address: {
                  ...employerDetails.address,
                  province: selectedId,
                },
              });
            }}
          />
          {employerDetailsError?.address?.province && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {employerDetailsError?.address?.province}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      </VStack>
    );
  };

  const handleProfileImageChange = async (imageData: any, code: string) => {
    switch (code) {
      case 'delete':
        setStateData({
          ...stateData,
          profileImgSrc: stateData.uploadedImage ? stateData.uploadedImage : '',
          profileImageBlob: {},
        });
        break;
      case 'setImage':
        setStateData({
          ...stateData,
          profileImgSrc: URL.createObjectURL(imageData?.file),
          profileImageBlob: imageData?.file,
        });
        break;
    }
  };
  const getEmployerById = async () => {
    const employerDataRes = await EmployerById({
      variables: {
        employerId: initData?.id
      },
    });
    if (employerDataRes.data?.employers?.length) {
      const employerData = employerDataRes.data?.employers[0];
      const attachmentUrl = employerData.metadata?.attachmentUrl
      setStateData(prev => {
        return {
          ...prev,
          profileImgSrc: attachmentUrl ? attachmentUrl : '',
          uploadedImage: attachmentUrl ? attachmentUrl : '',
          employerData: employerData
        }
      })
    }
  };
  const uploadEmployersLogo = async (id: string) => {
    if (
      stateData.uploadedImage &&
      stateData.uploadedImage === stateData.profileImgSrc
    ) {
      return;
    }
    const response = await uploadEmployerLogo(stateData.profileImageBlob, id);
  };

  const renderCompanyInfo = (): JSX.Element => {
    return (
      <VStack>
        <UploadLogo
          handleProfileImageChange={handleProfileImageChange}
          profileImgSrc={stateData.profileImgSrc}
          fullName={''}
          profileImageBlob={stateData.profileImageBlob}
        />
        <ModalActionInput
          fieldIs={'input'}
          label={'companyName'}
          isRequired={true}
          isInvalid={employerDetailsError?.companyInfo?.name ? true : false}
          errors={employerDetailsError?.companyInfo?.name}
          errorText={employerDetailsError?.companyInfo?.name}
          defaultValue={employerDetails?.companyInfo?.name}
          value={employerDetails?.companyInfo?.name}
          placeholder={intl.formatMessage({id: 'companyNamePlaceHolder'})}
          onChangeText={(value: string) => {
            setEmployerDetails({
              ...employerDetails,
              companyInfo: {
                ...employerDetails.companyInfo,
                name: value,
              },
            });
          }}
          extraStyle={{flex: 1}}
        />

        <ModalActionSelect
          marginTop={4}
          isRequired={false}
          isInvalid={employerDetailsError?.companyInfo?.industry ? true : false}
          label={'industry'}
          placeholder={intl.formatMessage({id: 'selectSIC'})}
          selectedValue={employerDetails?.companyInfo?.industry || undefined}
          defaultValue={employerDetails?.companyInfo?.industry || undefined}
          accessibilityLabel={intl.formatMessage({
            id: 'selectSIC',
          })}
          endIcon={<CheckIcon size="5" />}
          onValueChange={(itemValue) => {
            setEmployerDetails({
              ...employerDetails,
              companyInfo: {
                ...employerDetails.companyInfo,
                industry: itemValue,
              },
            });
          }}
          errors={employerDetailsError?.companyInfo?.industry}
          errorText={employerDetailsError?.companyInfo?.industry}
          data={dealStatusList}
          selectItemProps={{
            key: 'id',
            label: 'value',
            value: 'id',
          }}
          customStyle={{flex: 1}}
        />

        <ModalActionInput
          marginTop={4}
          fieldIs={'input'}
          label={'companyEmailLabel'}
          isRequired={false}
          isInvalid={employerDetailsError?.companyInfo?.email ? true : false}
          errors={employerDetailsError?.companyInfo?.email}
          errorText={employerDetailsError?.companyInfo?.email}
          defaultValue={employerDetails?.companyInfo?.email}
          value={employerDetails?.companyInfo?.email}
          onChangeText={(value: string) => {
            setEmployerDetails({
              ...employerDetails,
              companyInfo: {
                ...employerDetails.companyInfo,
                email: value.trim(),
              },
            });
          }}
          extraStyle={{flex: 1}}
        />

        <ModalActionInput
          marginTop={4}
          fieldIs={'input'}
          label={'companyPhoneLabel'}
          isRequired={false}
          isInvalid={
            employerDetailsError?.companyInfo?.phoneNumber ? true : false
          }
          errors={employerDetailsError?.companyInfo?.phoneNumber}
          errorText={employerDetailsError?.companyInfo?.phoneNumber}
          defaultValue={employerDetails?.companyInfo?.phoneNumber}
          value={employerDetails?.companyInfo?.phoneNumber}
          onChangeText={(value: string) => {
            setEmployerDetails({
              ...employerDetails,
              companyInfo: {
                ...employerDetails.companyInfo,
                phoneNumber: value,
              },
            });
          }}
          extraStyle={{flex: 1}}
        />
      </VStack>
    );
  };

  const steps = [
    {
      title: (
        <Text
          size={'xsMedium'}
          color={current == 0 ? Colors.primary[700] : Colors.Custom.Gray700}
        >
          {'Comapany Info'}
        </Text>
      ),
      content: renderCompanyInfo(),
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          color={current == 1 ? Colors.primary[700] : Colors.Custom.Gray700}
        >
          {'Company Address'}
        </Text>
      ),
      content: renderCompanyAddress(),
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          color={current == 2 ? Colors.primary[700] : Colors.Custom.Gray700}
        >
          {'Contact Info'}
        </Text>
      ),
      content: renderContactInfo(),
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          color={current == 3 ? Colors.primary[700] : Colors.Custom.Gray700}
        >
          {'Deal Status'}
        </Text>
      ),
      content: renderDealStatus(),
    },
  ];

  // const renderProgressDot = (
  //   iconDot: any,
  //   info: {
  //     index: number;
  //     status: any;
  //     title: React.ReactNode;
  //     description: React.ReactNode;
  //   }
  // ): JSX.Element => {
  //   return (
  //     <VStack alignItems={"center"}>
  //       <View
  //         style={{
  //           width: 20,
  //           borderRadius: 20,
  //           borderWidth: 2,
  //           borderColor: Colors.primary[700],
  //           marginBottom: 10,
  //           height: 20,
  //           backgroundColor:
  //             info.index < current
  //               ? Colors.primary[700]
  //               : Colors.Custom.Primary50,
  //         }}
  //       ></View>
  //     </VStack>
  //   );
  // };

  return (
    <View mt={0} mx={0}>
      <HStack marginBottom={5} flex={1}>
        <HStack justifySelf={'flex-start'} flex={0.5}>
          <View width={'80%'}>
          <ModalActionTitle
            isHeadNotSticky={true}
            title={
              initData?.raw?.name ? capitalizeText(initData?.raw?.name) : 'createEmployerTitle'
            }
            titleColor={''}
            titleSize={20}
          />
          </View>
        </HStack>
        <HStack
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 0.5,
            //marginTop: 20,
          }}
        >
          <Button.Group justifyContent={'flex-end'}>

            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onModalClose(false);
                },
                style: {marginRight: 10},
              }}
              customProps={{
                hideButton: (current ? false : true),
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            {current ? (
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    setCurrent(current - 1);
                  },
                  style: {marginRight: 10},
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'previous',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            ) : (
              <></>
            )}
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onSubmitForm();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: current == 3 ? 'update' : 'next',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </HStack>
      </HStack>
      <Steps
        //progressDot={renderProgressDot}
        labelPlacement="vertical"
        current={current}
      >
        {steps.map((item, index) => (
          <Step
            icon={
              <View
                style={{
                  width: 32,
                  height: 32,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <AntIcon
                  size={24}
                  name={index < current ? 'checkcircle' : 'checkcircleo'}
                  color={Colors.primary[700]}
                />
              </View>
            }
            key={index + 'step'}
            title={item.title}
          />
        ))}
      </Steps>
      <View style={{marginTop: 20}}>{steps[current].content}</View>
    </View>
  );
};

export default EmployerQuickCreateModal;
