import {Menu} from 'antd';
import React, {FC, useState} from 'react';
import LocalStorage from '../../../../../utils/LocalStorage';

interface BulkActionsProps {
  opportunities: any[];
  onDataUpdated(opportunity: any): void
}

const allowedDeleteStatuses = ['DELETED', 'ARCHIVED', 'PAUSED'];

const BulkActionsDropMenu: FC<BulkActionsProps> = ({opportunities, onDataUpdated}) => {
  const [loading, setLoading] = useState(false);

  const getHeaders = async () => {
    const userDataString = await LocalStorage.getItem('user');
    const userData = JSON.parse(userDataString);

    const responseAccessToken = userData?.accessToken || '';
    const fold_access_token = userData?.fold_access_token;

    const headersObj: any = {
      'access-token': responseAccessToken,
      fold_access_token,
    };

    return headersObj;
  };

  const handleMerge = async () => {
    setLoading(true);
    await Promise.all(
      opportunities.slice(1).map(async (opportunity) => {
        try {
          const authHeaders = await getHeaders()
          const req = await fetch(
            `https://dev.foldhealth.io/opportunities/${
              opportunities[0]._id || opportunities[0].key
            }/merge`,
            {
              method: 'POST',
              body: JSON.stringify({
                toMerge: opportunities.filter(
                  (opp) =>
                    (opp._id || opp.key) !==
                    (opportunity._id || opportunity.key)
                ),
              }),
              headers: {'Content-Type': 'application/json', ...authHeaders},
            }
          );
          const res = await req.json();
        } catch (error) {
        }
      })
    );
    setLoading(false);
  };

  const handleSoftDelete = async (type: string) => {
    if (!allowedDeleteStatuses.includes(type.toString().trim())) return;

    setLoading(true);
    await Promise.all(
      opportunities.map((opportunity: any) => {
        return new Promise(async (resolve, reject) => {
          try {
            const authHeaders = await getHeaders()
            const req = await fetch(
              `https://dev.foldhealth.io/opportunities/${
                opportunity._id || opportunity.key
              }/status`,
              {
                method: 'POST',
                body: JSON.stringify({
                  statusType: type,
                }),
                headers: {
                  'Content-Type': 'application/json',
                  ...authHeaders
                },
              }
            );
            const res = await req.json();
            // onDataUpdated(res.report || res)
            resolve(res)
          } catch (error: any) {
            reject(error.message)
          }
        })
      })
    );
    onDataUpdated(opportunities)
    setLoading(false);
  };

  const duplicateOpportunities = async () => {
    if (!opportunities.length) return;
    try {
      const authHeaders = await getHeaders()
      const req = await fetch(
        `https://dev.foldhealth.io/opportunities/duplicate`,
        {
          method: 'POST',
          body: JSON.stringify({
            opportunities: opportunities.map((opp) => ({
              ...opp,
              _id: opp.key,
              key: undefined,
            })),
          }),
          headers: {
            'Content-Type': 'application/json',
            ...authHeaders
          },
        }
      );
      const res = await req.json();

    } catch (error: any) {
    }
  };

  const handleBulkFavorite = async () => {
    if (!opportunities.length) return;
    setLoading(true);
    await Promise.all(
      opportunities.map(async (opportunity: any) => {
        try {
          const authHeaders = await getHeaders()
          const req = await fetch(
            `https://dev.foldhealth.io/opportunities/${
              opportunity._id || opportunity.key
            }/favorites/toggle`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                ...authHeaders
              },
            }
          );
          const res = await req.json();
        } catch (error: any) {
        }
      })
    );
    setLoading(false);
  }

  return (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <a
              onClick={(e) => handleBulkFavorite()}
            >
              Add to Favorites
            </a>
          ),
        },
        {
          key: '2',
          disabled: true,
          label: (
            <a
              href="#"
            >
              Create Campaign
            </a>
          ),
        },
        {
          key: '5',
          disabled: true,
          label: <div onClick={(e) => duplicateOpportunities()}>Duplicate</div>,
        },
        {
          key: '3',
          disabled: true,
          label: <div onClick={(e) => handleMerge()}>Merge Opportunities</div>,
        },
        {
          key: '6',
          label: <div onClick={(e) => handleSoftDelete('PAUSED')}>Pause</div>,
        },
        {
          key: '7',
          label: (
            <div onClick={(e) => handleSoftDelete('ARCHIVED')}>Archive</div>
          ),
        },
        {
          key: '8',
          label: <div onClick={(e) => handleSoftDelete('DELETED')}>Delete</div>,
        },
      ]}
    />
  );
};

export default BulkActionsDropMenu;
