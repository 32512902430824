import {useLazyQuery, useMutation} from '@apollo/client';
import {Select} from 'antd';
import React, {useCallback, useContext, useState} from 'react';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {getMlovListFromCategory, getMlovObjectFromCode} from '../../../../utils/mlovUtils';
import { LABEL_TYPE_CODES, MLOV_CATEGORY } from '../../../../constants/MlovConst';
import { GetLabelsByLabelTitleAndTypeId } from '../../../../services/Labels/LabelsQueries';
import { ILabelTask } from '../../../common/CareDashboard/CareDashboardInterfaces';
import { CreateLabel, CreateLabelType } from '../../../../services/Tags/TagQueries';
import { getAccountId } from '../../../../utils/commonUtils';
import { DEFAULT_TASK_LABEL_COLOR } from '../../../common/AddTask/AddEditTaskView/TaskConstant';
import { Pressable } from 'react-native';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';

const LabelSearch = (props: {
  value?: ILabelTask[];
  isShowError?: boolean;
  allowMultiple?: boolean;
  disallowLabels?: string[];
  onChange: (actionData?: any, extraData?: any) => void;
}) => {
  const {value, isShowError, allowMultiple, disallowLabels, onChange} = props;
  const accountId = getAccountId();
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const mlovs = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  ) || [];
  const takLabelMlov = getMlovObjectFromCode(LABEL_TYPE_CODES.TASK, mlovs);

  // States
  const [taskLabelData, setTaskLabelData] = useState<{
    taskLabelsList?: ILabelTask[];
    limit: number;
    offset: number;
    loading: boolean;
    searchString?: string;
    selectedLabels?: ILabelTask[];
  }>({
    taskLabelsList: value || [],
    limit: 10,
    offset: 0,
    searchString: '',
    loading: false,
    selectedLabels: value || [],
  });

  // APIs
  const [getTaskLabels] = useLazyQuery(GetLabelsByLabelTitleAndTypeId, {
    fetchPolicy: 'no-cache'
  });
  const [createLabel] = useMutation(CreateLabel);
  const [createLabelType] = useMutation(CreateLabelType);

  // Other methods
  const getSearchTaskLabel = useCallback(debounce((value) => {
    onSearchTaskLabel(value);
  }, 500), []);

  const onSearchTaskLabel = async (searchString: string) => {
    try {
      setTaskLabelData(prev => {
        return {
          ...prev,
          loading: true,
        }
      });
      const taskLabelParams = {
        limit: 10,
        offset: 0,
        labelTypeId: takLabelMlov?.id,
        search: `%${searchString?.trim()}%`,
      };
      const apiResponse = await getTaskLabels({ variables: taskLabelParams});
      let taskLabels = apiResponse?.data?.labels || [];
      if (disallowLabels?.length) {
        taskLabels = taskLabels.filter((item: ILabelTask) => disallowLabels.includes(item.title || ''))
      }
      setTaskLabelData(prev => {
        return {
          ...prev,
          loading: false,
          taskLabelsList: taskLabels,
        }
      });
    } catch(error) {
      setTaskLabelData(prev => { return { ...prev, loading: false }});
    }
  };

  const createNewLabel = () => {
    const searchedTag = taskLabelData?.searchString?.trim();
    createLabel({
      variables: {
        accountId: accountId,
        color: DEFAULT_TASK_LABEL_COLOR,
        description: '',
        title: searchedTag,
      },
    })
    .then((res: any) => {
      const temp = {
        id: res.data.createLabel.id,
        uuid: res.data.createLabel.uuid,
        title: res.data.createLabel.title,
        color: res.data.createLabel.color,
        description: res.data.createLabel.description
      }
      const currentLabelData = [...taskLabelData.taskLabelsList || []];
      const selectedLabelData = [...taskLabelData.selectedLabels || [], temp];
      currentLabelData.push(temp);
      setTaskLabelData(prev => {
        return {
          ...prev,
          searchString: '',
          taskLabelsList: [...currentLabelData],
          selectedLabels: selectedLabelData,
        }
      });
      onChange(selectedLabelData);
      createLabelType({
        variables: {
          accountId,
          labelId: res.data.createLabel.id,
          labelTypeId: takLabelMlov?.id,
        },
      }).then((res) => {
        onSearchTaskLabel('');
      });
    })
    .catch((error: any) => {
    });
  }

  const getLabelOptions = () => {
    if (taskLabelData?.taskLabelsList?.length) {
      return (taskLabelData?.taskLabelsList || []).map((label: ILabelTask) => {
        return <Select.Option key={label.uuid} value={label.uuid}>
          {label?.title}
        </Select.Option>
      });
    } else {
      return taskLabelData?.searchString ? (
        <Select.Option key={'addLabel'} value={'addLabel'}>
          <Pressable onPress={() => {
            createNewLabel();
          }} >
            {`Add Label - ${taskLabelData?.searchString}`}
          </Pressable>
        </Select.Option>
      ) : (
        <Select.Option key={'noData'} value={'noData'}>
          {intl.formatMessage({ id: 'NoDataSelect' })}
        </Select.Option>
      );
    }
  }

  const checkStringExistInTaskLabels = () => {
    if (taskLabelData?.taskLabelsList?.length) {
      return taskLabelData?.taskLabelsList?.some((taskLabel) => taskLabel.title?.includes(taskLabelData?.searchString || ''))
    }
    return false
  }



  return (
    <Select
      mode={allowMultiple ? "multiple" : undefined}
      allowClear
      placeholder={"Search Labels"}
      showSearch={true}
      status={isShowError ? 'error' : undefined}
      value={
        allowMultiple ? (taskLabelData.selectedLabels?.length
          ? taskLabelData.selectedLabels?.map(item => item.uuid || '') || []
          : []) : (taskLabelData.selectedLabels?.length
            ? taskLabelData.selectedLabels?.[0]?.uuid || ''
            : [])
      }
      searchValue={taskLabelData.searchString}
      filterOption={false}
      onChange={(selectedLabels) => {
        const tempData = Array.isArray(selectedLabels) ?
          selectedLabels?.filter((item: string) => item != 'noData') :
          (selectedLabels === 'noData' ? undefined : [selectedLabels]);
        const updatedLabels = taskLabelData.taskLabelsList?.filter(item => tempData?.includes(item.uuid || ''));
        setTaskLabelData((prev) => {
          return {
            ...prev,
            searchString: '',
            selectedLabels: updatedLabels,
          };
        });
        onChange(updatedLabels);
      }}
      onSearch={(value)=> {
        setTaskLabelData((prev) => {
          return {...prev, searchString: value.trimStart()};
        });
        getSearchTaskLabel(value)
      }}
      onInputKeyDown={(e)=>{
        if(e.key === "Enter" && !checkStringExistInTaskLabels()){
          e.stopPropagation()
        }
      }}
      loading={taskLabelData.loading}
      notFoundContent={intl.formatMessage({
        id: 'NoDataSelect',
      })}
      style={{width: '100%'}}
      onBlur={() => {
        setTaskLabelData(prev => {
          return {
            ...prev,
            taskLabelsList: [],
            searchString: '',
          }
        });
      }}
    >
      {getLabelOptions()}
    </Select>
  );
};

export default LabelSearch;
