import {Badge, HStack, Pressable, Text} from 'native-base';
import React from 'react';
const FilterListView = (props: any) => {
  const {tagList, onFilterSelect} = props;
  return (
    <>
      {tagList.map((tagItem: any, index: number) => {
        return (
          <Pressable
            //   _hover={{bg: Colors.primary[100]}}
            key={index}
            onPress={() => {
              onFilterSelect(tagItem);
            }}
            flex={1}
          >
            <HStack
              style={{
                flex: 2.8,
                flexDirection: 'row',
              }}
            >
              <Badge
                backgroundColor={'transparent'}
                // backgroundColor={tagItem.color['100' + '7a']}
                color={tagItem.color || 'gray.500'}
                alignSelf="center"
                variant="solid"
                style={{
                  paddingHorizontal: 8,
                  paddingVertical: 2,
                  borderColor: 'gray',
                  borderWidth: 1,
                  borderRadius: 16,
                  minHeight: 22,
                  marginVertical: 8,
                }}
              >
                <Text
                  style={{color: tagItem.color || 'gray.500'}}
                  size={'xsMedium'}
                >
                  {tagItem.title ? tagItem.title : tagItem.name}
                </Text>
              </Badge>
            </HStack>
          </Pressable>
        );
      })}
    </>
  );
};

export default FilterListView;
