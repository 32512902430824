import {Button, Col, Row} from 'antd';
import {ReactChild, ReactFragment, ReactPortal} from 'react';

export default function OpportunityDetailDisplayRow({
  opportunity,
  episodes,
}: {
  opportunity: any;
  episodes: any;
}) {
  return (
    <>
      <Row
        style={{
          margin: '32px  0px',
          height: '24px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/** Diagnosis filtered by primary, or find */}
        <p
          style={{
            fontWeight: 'bold',
            fontSize: '14px',
            display: 'inline-block',
          }}
        >
          {episodes.name}{' '}
          <span
            style={{
              color: 'rgb(102 112 133 / 1)',
              fontWeight: 400,
            }}
          >
            {episodes.description}
            {/* {!opportunity.isLoading && opportunity.data
            ? (opportunity?.data as any).name
            : 'Type 2 diabetes mellitus'} */}
          </span>
        </p>
      </Row>
      {episodes.children.map(
        (episode: {members: number; name: string; description: string}) => (
          <Row
            style={{
              height: '24px',
              marginBottom: '16px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Col flex="142px">
              <Button
                className="btnTagDefault"
                shape="round"
                size="small"
                style={{height: '22px'}}
              >
                {episode.members} Members
              </Button>
            </Col>
            <Col flex="auto">
              <span style={{fontWeight: 600}}>{episode.name}</span>
              <span> {episode.description}</span>
            </Col>
          </Row>
        )
      )}
    </>
  );
}
