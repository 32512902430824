//FIXME: styles to comment on mobile
import 'antd/dist/antd.css';
import {HStack, Text, View, VStack} from 'native-base';
import React from 'react';
import {Colors} from '../../../../../styles';
import {getCommerceSubdomain} from '../../../../../utils/commonUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import BillingActionsView from '../../../Billing/BillingActionsView';
import {IProductTableDisplayData} from '../../ProductsAndServices/Products/ProductTableView/ProductTable/interfaces';

export const expandedColumns = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (text: string, selectedRecord: any): JSX.Element => {
      return <>{selectedRecord.name}</>;
    },
  },
  {
    title: 'Tiers',
    dataIndex: 'tiers',
    key: 'Tiers',

    render: (text: string, selectedRecord: any): JSX.Element => {
      return (
        <View>
          {selectedRecord.value && selectedRecord.value.length > 0 ? (
            <>
              {selectedRecord.value.map((tier: any) => {
                if (
                  tier.unit_amount_decimal &&
                  tier.unit_amount_decimal > 0 &&
                  tier.up_to
                ) {
                  return (
                    <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                      ${tier.unit_amount_decimal / 100} per unit upto{' '}
                      {tier.up_to}
                    </Text>
                  );
                } else if (
                  tier.unit_amount_decimal &&
                  tier.unit_amount_decimal > 0 &&
                  !tier.up_to
                ) {
                  return (
                    <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                      ${tier.unit_amount_decimal / 100} per unit thereafter
                    </Text>
                  );
                }
                if (tier.flat_amount_decimal && tier.up_to) {
                  return (
                    <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                      ${tier.flat_amount_decimal / 100} up to {tier.up_to}
                    </Text>
                  );
                } else if (tier.flat_amount_decimal && !tier.up_to) {
                  return (
                    <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                      ${tier.flat_amount_decimal / 100} thereafter
                    </Text>
                  );
                }
              })}
            </>
          ) : (
            <Text color={'gray.400'}>--</Text>
          )}
        </View>
      );
    },
  },
];

export const getBillingTableColumns = (
  onActionPerformed: (
    actionCode: string,
    rowData: any,
    checked?: boolean
  ) => void
): any[] => {
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      fixed: 'left',
      sorter: (nameOne: any, nameTow: any) => {
        nameOne.name.localeCompare(nameTow.name);
      },
      ellipsis: true,
      render: (
        text: string,
        selectedRecord: IProductTableDisplayData
      ): JSX.Element => {
        const imagesList = selectedRecord.imageData;
        let imageUrl = '';
        if (
          imagesList &&
          imagesList.length &&
          imagesList[0] &&
          imagesList[0].attributes &&
          imagesList[0].attributes.original_url
        ) {
          imageUrl = `https://${getCommerceSubdomain()}${
            imagesList[0].attributes.original_url
          }`;
        }
        return (
          <HStack alignItems={'center'} space={2}>
            <VStack marginRight={4}>
              {text ? (
                <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                  {text}
                </Text>
              ) : (
                <Text color={'gray.400'}>-</Text>
              )}
              {selectedRecord.active ? (
                <></>
              ) : (
                <View
                  style={{
                    backgroundColor: Colors.danger['100'],
                    width: 'max-content',
                    paddingHorizontal: 8,
                    paddingVertical: 2,
                    borderRadius: 8,
                    marginTop: 3,
                    borderWidth: 1,
                    borderColor: Colors.danger['200'],
                  }}
                >
                  <Text
                    size={'xsSemibold'}
                    style={{
                      color: Colors.danger['400'],
                    }}
                  >
                    Archived
                  </Text>
                </View>
              )}
            </VStack>
          </HStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="description"
        />
      ),
      width: '25%',
      render: (data: any): JSX.Element => {
        const description = data?.description;
        return (
          <View>
            {description ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {description}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="oneTimePrice"
        />
      ),
      width: '10%',
      render: (data: any): JSX.Element => {
        const price = data?.prices?.oneTime;
        const currency = data?.mrpData?.currency;
        return (
          <View>
            {price && price.length && price != 0 ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {currency.toUpperCase()} {price / 100}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="monthlyPrice"
        />
      ),
      width: '10%',
      sorter: (nameOne: any, nameTow: any) => {
        nameOne.name.localeCompare(nameTow.name);
      },
      ellipsis: true,
      render: (data: any): JSX.Element => {
        const price = data?.prices?.monthly;
        const currency = data?.mrpData?.currency;
        return (
          <View>
            {price && price.length && price != 0 ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {currency.toUpperCase()} {price / 100}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="quarterlyPrice"
        />
      ),
      width: '10%',
      sorter: (nameOne: any, nameTow: any) => {
        nameOne.name.localeCompare(nameTow.name);
      },
      ellipsis: true,
      render: (data: any): JSX.Element => {
        const price = data?.prices?.quarterly;
        const currency = data?.mrpData?.currency;
        return (
          <View>
            {price && price.length && price !== 0 ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {currency.toUpperCase()} {price / 100}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="annuallyPrice"
        />
      ),
      width: '10%',
      sorter: (nameOne: any, nameTow: any) => {
        nameOne.name.localeCompare(nameTow.name);
      },
      ellipsis: true,
      render: (data: any): JSX.Element => {
        const price = data.prices?.yearly;
        const currency = data?.mrpData?.currency;
        return (
          <View>
            {price && price.length && price != 0 ? (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {currency.toUpperCase()} {price / 100}
              </Text>
            ) : (
              <Text color={'gray.400'}>--</Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      key: 'action',
      width: '20%',
      fixed: 'right',
      render: (text: any, selectedRecord: any) => {
        return (
          <>
            <BillingActionsView
              selectedRecord={selectedRecord}
              onActionClick={(actionCode: string): any => {
                onActionPerformed(actionCode, selectedRecord);
              }}
            />
          </>
        );
      },
      responsive: ['md'],
    },
  ];
};
