import {View,Text,Pressable} from "react-native";
import {Tooltip,Popover} from 'antd'
import {useState} from 'react'
import {Colors} from "../../../../../styles";
import {CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR} from "../../../Contacts/CustomField/CustomFieldConst";
import {IProductPackageTag} from "./interfaces";

export const PackageTagsView = (props: {productPackageTag: IProductPackageTag[]}) => {
  const [stateData, setStateData] = useState({
    showMore: false,
    maxTagToShow: 2,
  })

  const renderTags = (tag: IProductPackageTag) => {
    return (
      <View style={{
        marginRight: 8,
        marginBottom: 4,
      }}>
        <Text
          style={{
            backgroundColor: Colors.Custom.Gray100,
            paddingHorizontal: 8,
            paddingVertical: 3,
            borderWidth: 1,
            borderColor: Colors.Custom.Gray200,
            borderRadius: 16,
          }}>
            <Tooltip
              title={tag?.labels?.title?.length > CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR ? tag?.labels?.title : ''}
              placement={'top'}
              destroyTooltipOnHide={true}
            >
              {tag?.labels?.title?.length > CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR ? `${tag?.labels?.title.slice(0,CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR)}...` : tag?.labels?.title}
            </Tooltip>
        </Text>
      </View>
    )
  }
  return <>
    <View style={{flexDirection: 'row',flexWrap: 'wrap'}}>
      {props?.productPackageTag?.map?.((tag: IProductPackageTag, index: number) => {
        if (index < stateData?.maxTagToShow) {
          return (renderTags(tag))
        }
      })}
      {(props?.productPackageTag?.length - stateData?.maxTagToShow) > 0 && <Popover
        overlayInnerStyle={{
          padding: 0,
          borderRadius: 12
        }}
        overlayClassName='patient-search-select-styles'
        className='patient-search-select-styles'
        overlayStyle={{padding: 0}}
        overlay={{padding: 0}}
        style={{
          padding: 0,
          borderRadius: 12,
        }}
        placement={'bottomLeft'}
        trigger={'click'}
        visible={stateData?.showMore}
        onVisibleChange={() => {
          setStateData((prev) => {
            return {
              ...prev,
              showMore: false
            }
          })
        }}
        content={<View style={{flexDirection: 'row',flexWrap: 'wrap', maxWidth: 240}}>
          {props?.productPackageTag?.map?.((tag: IProductPackageTag) => {
            return (renderTags(tag))
          })}
        </View>
        }
      >
        <Pressable
          onPress={() => {
            setStateData((prev) => {
              return {
                ...prev,
                showMore: true
              }
            })
          }}
        >
          <Text style={{
            fontWeight: '500',
            marginTop: 1,
            color: Colors.Custom.PurpleColor,
            paddingVertical: 3,
            marginLeft: 1,
          }}>
            {`+${props?.productPackageTag?.length - stateData?.maxTagToShow} more`}
          </Text>
        </Pressable>
      </Popover>}
      {!props?.productPackageTag?.length && <Text>
        {'-'}
      </Text>}
    </View>
  </>
}