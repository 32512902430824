import React from 'react';

const RightArrow = (props: {color?: string}) => {
  const svgColors = props.color ? props.color : '#7F56D9';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      viewBox="0 0 6 10"
      fill="none"
    >
      <path
        d="M1 9L5 5L1 1"
        stroke={svgColors}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default React.memo(RightArrow);
