import { StyleSheet } from 'react-native';
import { Colors } from '../../../../../styles';

export const patientDashboardStyles = StyleSheet.create({
  patientDetailView: {
    flex: 0.5,
    borderLeftColor: Colors.FoldPixel.GRAY150,
    borderLeftWidth: 0.5,
  },
  patientActionView: {
    width: 28,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
});
