import {gql} from '@apollo/client';

export const SEND_BULK_FORMS = gql`
mutation sendBulkForms($params: BulkFormInput!) {
    sendBulkForms(params: $params) {
      status
    }
  }
`;

export default {
    SEND_BULK_FORMS
  };