import React, {useContext, useState} from 'react'
import {FormContext} from '../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {getAccountUUID, getFoldAccessToken, getUserUUID} from '../../../../../utils/commonUtils';
import useEHRCapabilities from '../../../../../screens/BusinessStudio/useEHRCapabilities';

const useFormOptions = (props: {
  accountLocationUuid?: string;
  patientId?: string;
  contactId?: string;
  contactUuid?: string;
}) => {
  const contextData = useContext(CommonDataContext);
  const {accountLocationUuid, patientId, contactId, contactUuid} = props;
  const accountUUID = getAccountUUID();
  const ehrCapabilities = useEHRCapabilities({locationId: accountLocationUuid});
  const foldAccessToken = getFoldAccessToken();

  const [intakeOptions] = useState({
    foldAccessToken,
    accountUUID,
    patientId,
    contactId,
    contactUuid,
    backgroundSaveEnabled: false,
    ehrCapabilities: ehrCapabilities,
    accountLocationUuid: accountLocationUuid,
    formContext: FormContext.patientCarePlan,
    MLOV: contextData.MLOV,
    CARE_STUDIO_MLOV: contextData.CARE_STUDIO_MLOV,
    CLOUD_TELEPHONY_MLOV: contextData.CLOUD_TELEPHONY_MLOV,
    userSettings: contextData.userSettings,
    userData: contextData.userData,
    sidecarContext: contextData.sidecarContext,
  });

  return {
    intakeOptions,
  };
};

export default useFormOptions