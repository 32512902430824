import React from 'react';
import DisplayProgress from '../DisplayProgressBar';
import {Typography} from 'antd';

const {Text} = Typography;
export default function DisplayListProgress({
  data,
  selectItem,
}: {
  data: any;
  selectItem: any;
}) {
  return (
    <div>
      {data.map((item: any) => (
        <div
          style={{padding: '10px 20px', cursor: 'pointer'}}
          onClick={() => selectItem(item)}
        >
          <Text>{item.condition}</Text>
          <DisplayProgress percent={item.percentage} showInfo />
        </div>
      ))}
    </div>
  );
}
