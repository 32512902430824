import {Moment} from 'moment';
import {
  Box,
  FlatList,
  HStack,
  Pressable,
  Spacer,
  VStack,
  Text,
} from 'native-base';
import {useIntl} from 'react-intl';
import Feather from 'react-native-vector-icons/Feather';
import {DATE_FORMATS} from '../../../../constants/StringConst';
import {Colors} from '../../../../styles/Colors';
import {
  getMomentObj,
  getMomentObjectWithDateStringAndFormat,
} from '../../../../utils/DateUtils';
import {ModalActionTimePicker} from '../../../common/ModalActionCommonComponent/ModalActionTimePicker';
import WeekDaySelector from '../../../common/WeekSelector/WeekDaySelector';
import {VIRTUAL_LOCATION_CODE} from '../PracticeAvailabilityNew/PracticeAvailability';
import {APPOINTMENT_TYPE_AVAILABILITY_ACTION} from './AppointmentTypeConst';
import {
  isInValidEndTimeOfSlot,
  isInValidStartTimeOfSlot,
} from './AppointmentTypesHelper';
import {
  IAppointmentTypeAvailabilityCard,
  IAppointmentTypeAvailabilitySlot,
} from './Interfaces';
import {LocationMultiSelect} from './LocationMultiSelect';

export const AppointmentTypeAvailabilityCard = (
  props: IAppointmentTypeAvailabilityCard,
) => {
  const {
    availability,
    accountLocation,
    onAvailabilityChangeHandler,
    showError,
  } = props;
  const intl = useIntl();

  const slotsToRender = availability.slots.filter((slot) => !slot.isDeleted ?? false);
  const handleAddNewSlot = () => {
    if (
      onAvailabilityChangeHandler &&
      typeof onAvailabilityChangeHandler === 'function'
    ) {
      onAvailabilityChangeHandler(
        APPOINTMENT_TYPE_AVAILABILITY_ACTION.ADD_NEW_SLOT,
        availability,
      );
    }
    return undefined;
  };

  const handleDeleteSlot = (slot: IAppointmentTypeAvailabilitySlot) => {
    if (
      onAvailabilityChangeHandler &&
      typeof onAvailabilityChangeHandler === 'function'
    ) {
      onAvailabilityChangeHandler(
        APPOINTMENT_TYPE_AVAILABILITY_ACTION.DELETE_SLOT,
        availability,
        slot,
      );
    }
  };

  const handleLocationChange = (locationIds: string[]) => {
    availability.locationIds = locationIds || [];
    if (
      onAvailabilityChangeHandler &&
      typeof onAvailabilityChangeHandler === 'function'
    ) {
      availability.isVirtualLocation = locationIds.includes(
        VIRTUAL_LOCATION_CODE,
      );
      onAvailabilityChangeHandler(
        APPOINTMENT_TYPE_AVAILABILITY_ACTION.ADD_NEW_LOCATION,
        availability,
      );
    }
  };

  const handleDeleteAppointmentTypeAvailability = () => {
    if (
      onAvailabilityChangeHandler &&
      typeof onAvailabilityChangeHandler === 'function'
    ) {
      onAvailabilityChangeHandler(
        APPOINTMENT_TYPE_AVAILABILITY_ACTION.DELETE_AVAILABILITY,
        availability,
      );
    }
  };

  const handleWeekDaysChange = (weekDays: number[]) => {
    availability.weekDays = weekDays || [];
    if (
      onAvailabilityChangeHandler &&
      typeof onAvailabilityChangeHandler === 'function'
    ) {
      onAvailabilityChangeHandler(
        APPOINTMENT_TYPE_AVAILABILITY_ACTION.WEEKDAY_UPDATE,
        availability,
      );
    }
  };

  const handleOnSlotTimeChangeHandler = (
    slotTimeKey: string,
    slotTime: string,
    slot: IAppointmentTypeAvailabilitySlot,
  ) => {
    if (slotTimeKey === 'startTime') {
      slot.startTime = slotTime;
    } else {
      slot.endTime = slotTime;
    }
    if (
      onAvailabilityChangeHandler &&
      typeof onAvailabilityChangeHandler === 'function'
    ) {
      onAvailabilityChangeHandler(
        APPOINTMENT_TYPE_AVAILABILITY_ACTION.UPDATE_SLOT,
        availability,
        slot,
      );
    }
  };

  const renderAvailabilitySlot = (data: any) => {
    const slot = data.item as IAppointmentTypeAvailabilitySlot;
    const startTime = slot?.startTime
      ? getMomentObj(slot?.startTime)
      : undefined;
    const endTime = slot?.endTime ? getMomentObj(slot?.endTime) : undefined;
    return (
      <HStack my={2} space={2} flex={1} alignItems="stretch">
        <ModalActionTimePicker
          customStyle={{
            flex: 1,
          }}
          format={DATE_FORMATS.TIME_SELECT_FORMAT}
          value={startTime}
          label={'fromTime'}
          isRequired
          errors={showError && isInValidStartTimeOfSlot(slot, true)}
          errorsText={isInValidStartTimeOfSlot(slot)}
          isHideOkButton={true}
          onSelect={(value: Moment) => {
            handleOnSlotTimeChangeHandler(
              'startTime',
              value.toISOString(),
              slot,
            );
          }}
        />
        <ModalActionTimePicker
          customStyle={{
            flex: 1,
          }}
          isRequired
          label={'toTime'}
          isHideOkButton={true}
          format={DATE_FORMATS.TIME_SELECT_FORMAT}
          value={endTime}
          errors={showError && isInValidEndTimeOfSlot(slot, true)}
          errorsText={isInValidEndTimeOfSlot(slot)}
          onSelect={(value: Moment) => {
            handleOnSlotTimeChangeHandler('endTime', value.toISOString(), slot);
          }}
        />
        <Box justifyContent={'center'} pt={1}>
          {slotsToRender.length > 1 && (
            <Feather
              onPress={() => handleDeleteSlot(slot)}
              name="trash-2"
              color={Colors.Custom.Gray500}
              size={20}
            />
          )}
        </Box>
      </HStack>
    );
  };

  return (
    <VStack
      my={2}
      borderWidth={1}
      borderColor={Colors.Custom.Gray200}
      borderRadius={8}
      px={2}
      py={2}>
      <HStack alignItems="center">
        <VStack>
          <Box
            borderWidth={1}
            borderRadius={8}
            py={0.5}
            borderColor={
              showError && availability?.weekDays.length === 0
                ? Colors.Custom.ErrorColor
                : 'transparent'
            }>
            <WeekDaySelector
              key={availability.cardId}
              values={availability?.weekDays}
              onChange={(ids, days) => {
                handleWeekDaysChange(ids);
              }}
            />
          </Box>
          {showError && availability?.weekDays.length === 0 && (
            <Text fontSize={'xs'} color="error.500" fontWeight={500} my={1}>
              Please select week days
            </Text>
          )}
        </VStack>
        <Spacer />
        <Feather
          onPress={handleDeleteAppointmentTypeAvailability}
          name="trash-2"
          color={Colors.Custom.Gray500}
          size={24}
        />
      </HStack>
      <VStack>
        <LocationMultiSelect
          selectedLocationIds={availability.locationIds}
          onChange={handleLocationChange}
          locations={accountLocation}
          showError={showError}
          isRequired={true}
          labelInfo="Please note that only selected locations will be available for booking with this appointment type."
        />
        <FlatList
          data={slotsToRender}
          renderItem={renderAvailabilitySlot}
          keyExtractor={item => item.id}
        />
        <Pressable flexDir="row" alignItems="center" onPress={handleAddNewSlot}>
          <Feather name="plus" color={Colors.Custom.PurpleColor} />
          <Text ml={1} color={Colors.Custom.PurpleColor}>
            {intl.formatMessage({id: 'addNewTime'})}
          </Text>
        </Pressable>
      </VStack>
    </VStack>
  );
};
