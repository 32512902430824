import { getLighterShadeWithoutOpacity } from "../../../../utils/ColorUtils";
import { AppointmentTypeAction } from "./AppointmentTypeTopBar";

export const APPOINTMENT_TYPE_COLORS = [
  '#825AC7',
  '#5FA2F8',
  '#F9564E',
  '#FFDD33',
  '#FFA727',
  '#5DDB4F',
  '#C445E3',
  '#848484',
  '#00BA97',
  '#0095B6',
];

export const DefaultFallbackAppointmentTypeColor = '#825AC7'

export const defaultAppointmentTypeColor = {
  bgColorPrimary: DefaultFallbackAppointmentTypeColor,
  bgColorSecondary: getLighterShadeWithoutOpacity(
    DefaultFallbackAppointmentTypeColor,
    0.1
  ),
  bgColorPrimaryDisabled: getLighterShadeWithoutOpacity(
    DefaultFallbackAppointmentTypeColor,
    0.5
  ),
};

export const APPOINTMENT_COLOR_INDICATOR_SIZE = 6;

export const APPOINTMENT_TYPE_AVAILABILITY_ACTION = {
  ADD_NEW_SLOT: 'ADD_NEW_SLOT',
  ADD_NEW_AVAILABILITY: 'ADD_NEW_AVAILABILITY',
  ADD_NEW_LOCATION: 'ADD_NEW_LOCATION',
  DELETE_SLOT: 'DELETE_SLOT',
  DELETE_AVAILABILITY: 'DELETE_AVAILABILITY',
  DELETE_LOCATION: 'DELETE_LOCATION',
  WEEKDAY_UPDATE: 'WEEKDAY_UPDATE',
  UPDATE_SLOT: 'UPDATE_SLOT',
}

export const APPOINTMENT_TYPE_CATEGORY = [
  {
    key: AppointmentTypeAction.singleAppointment,
    name: '1:1 Appointment',
  },
  {
    key: AppointmentTypeAction.groupSessionAppointment,
    name: 'Group Appointment',
  }
];

export const MAX_WITHIN_DAYS_LIMIT = 90;