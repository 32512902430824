import BaseService from '../../../../../../services/CommonService/BaseService';
import CommonService from '../../../../../../services/CommonService/CommonService';
import {InboxMembersBodyData, ISmsInboxMemberUpdateParams, ISmsUrlDeleteParams} from './interfaces';

export const updateInboxMembers = (params: ISmsInboxMemberUpdateParams) => {
  const pathParams = params.pathParams;
  const bodyParams = params.bodyParams;
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.axiosService;
  return axiosService.patch(
    `/accounts/${pathParams.accountId}/inbox_members`,
    bodyParams
  );
};

export const deleteInboxSMSUrl = (params: ISmsUrlDeleteParams) => {
  const bodyParams = params.bodyParams;
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.put(`cloud-telephony/phone-numbers/update`, bodyParams);
};

export const createInboxMembersAPI = (body: InboxMembersBodyData) => {
  const bodyParams = body;
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.post(`crm-nest/inbox/inboxMembers`, bodyParams);
}

export const addOrUpdateInboxMembersAPI = (body: InboxMembersBodyData) => {
  const bodyParams = body;
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.post(`crm-nest/inbox/members`, bodyParams);
}
