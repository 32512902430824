import {Platform, StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  container: {
    height: Platform.OS === 'web' ? '93vh' : '93%',
  },
  menuItemContainer: {
    width: '100%',
    height: 85,
    justifyContent: 'center',
    borderBottomColor: '#5938A8',
    borderBottomWidth: 0.5,
  },
  menuItemSubContainer: {
    width: '100%',
    height: 85,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
