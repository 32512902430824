import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  titleWrapper: {
    // marginHorizontal: 22,
    // marginVertical: 12,
  },

  tabsWrapper: {
    // marginHorizontal: 14,
  },
  tabStyles: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    height: 36,
    justifyContent: 'center',
    marginRight: 8,
    alignItems: 'center',
    borderRadius: 20,
    fontSize: 14,
    fontWeight: 500,
  },
});
