import {DualAxes} from '@ant-design/charts';
import React from 'react';
export function DualAxisLineGraph(props: {
  data: Array<any>;
  xField: string;
  yAxis?: any;
  yField: string[];
  seriesField: string;
  smooth: boolean;
  maintainAspectRatio: boolean;
  style?: any;
  geometryOptions?: any;
}) {
  const graphData: any = {
    smooth: props.smooth,
    data: props.data,
    xField: props.xField,
    yField: props.yField,
    seriesField: props.seriesField,
    maintainAspectRatio: props.maintainAspectRatio,
  };
  if (props.yAxis !== undefined) {
    graphData.yAxis = props.yAxis;
  }
  if (props.geometryOptions !== undefined) {
    graphData.geometryOptions = props.geometryOptions;
  }

  return <DualAxes style={props.style} {...graphData}></DualAxes>;
}
