
import React from 'react';
import Svg, {Path} from 'react-native-svg';

const MemberDroppedSvg = () => {
  return (
    <Svg width="17" height="14" viewBox="0 0 17 14" fill="none">
    <Path d="M11.111 13V11.6667C11.111 10.9594 10.8183 10.2811 10.2974 9.78105C9.77648 9.28095 9.06994 9 8.33323 9H3.47211C2.7354 9 2.02886 9.28095 1.50793 9.78105C0.986994 10.2811 0.694336 10.9594 0.694336 11.6667V13M15.9721 6.33333H11.8054M8.68045 3.66667C8.68045 5.13943 7.43679 6.33333 5.90267 6.33333C4.36855 6.33333 3.12489 5.13943 3.12489 3.66667C3.12489 2.19391 4.36855 1 5.90267 1C7.43679 1 8.68045 2.19391 8.68045 3.66667Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"></Path>
    </Svg>

  );
};

export default MemberDroppedSvg;

