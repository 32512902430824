import {Divider} from 'native-base';
import React, {useEffect, useRef, useState} from 'react';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES,
} from '../../../../common/TableTopBar';
import {PipelineModal} from '../PipelineModal';
import {PipelineTable} from '../PipelineTable';
import {getPipelineListTopBarButtons} from './Helper/TopBarButtons';

const Pipeline = (props: any) => {
  const child: any = useRef();
  const selectedBoard = props.selectedBoard || {};
  const [dataSource, setDataSource] = useState([]);
  const [pipelineData, setPipelineData] = useState(props.pipelineData);
  const [editPipeline, setEditPipeline]: any = useState();
  const onPipelineActionPerformed = (actionCode: string, pipelineObj: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.SAVE:
        props.onPipelineAction(COMMON_ACTION_CODES.SAVE, pipelineObj);
        break;
      case COMMON_ACTION_CODES.EDIT:
        props.onPipelineAction(COMMON_ACTION_CODES.EDIT, pipelineObj);
        break;
      case COMMON_ACTION_CODES.CANCEL:
        setEditPipeline(undefined);
        break;
    }
    setEditPipeline(undefined);
  };

  const addNewPipelineButtonClick = () => {
    setEditPipeline(undefined);
    if (child.current) {
      child.current.openModal();
    }
  };

  const handleSearchChange = (actionData: any) => {
    // handleSearchChange
  };
  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        handleSearchChange(actionData);
        break;
    }
  };

  const onTableActionPerformed = (data: any, actionCode: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        setEditPipeline(data);
        if (child.current) {
          child.current.openModal();
        }
        break;
      case COMMON_ACTION_CODES.DELETE:
        props.onPipelineAction(COMMON_ACTION_CODES.DELETE, data);
        break;
    }
  };

  useEffect(() => {
    const filterPipeline: any = pipelineData.filter((item: any) => {
      return item.boardId == props.selectedBoard.id;
    });
    setDataSource(filterPipeline);
  }, [props.selectedBoard]);

  useEffect(() => {
    setPipelineData(props.pipelineData);
    const filterPipeline: any = props.pipelineData.filter((item: any) => {
      return item.boardId == props.selectedBoard.id;
    });
    setDataSource(filterPipeline);
  }, [props.pipelineData]);

  return (
    <>
      <TableTopBar
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
        {...getPipelineListTopBarButtons(selectedBoard, [
          addNewPipelineButtonClick,
        ])}
      />
      <Divider />
      <PipelineTable
        dataSource={dataSource}
        onTableActionPerformed={onTableActionPerformed}
      />

      <>
        <PipelineModal
          onActionPerformed={onPipelineActionPerformed}
          ref={child}
          editPipeline={editPipeline}
          boardList={props.boardList}
        />
      </>
    </>
  );
};
export default Pipeline;
