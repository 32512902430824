import {FormControl, Input, Text} from 'native-base';
import {useState} from 'react';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {styles} from '../../../CampaignStyles';

export const ReplyTo = () => {
  const [replyTo, setReplyTo] = useState('');
  return (
    <FormControl style={styles.selectScreenTwo}>
      <FormControl.Label>
        <DisplayText textLocalId="replyTo" />
        <Text>:</Text>
      </FormControl.Label>
      <Input value={replyTo} onChangeText={(value) => setReplyTo(value)} />
    </FormControl>
  );
};
