import {useMutation} from '@apollo/client';
import {Drawer} from 'antd';
import {Button, Modal, Spinner, Text, View, useToast} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {CloudTelephonyQueries} from '../../../../services';
import BaseService from '../../../../services/CommonService/BaseService';
import {Colors} from '../../../../styles';
import {getAccountUUID} from '../../../../utils/commonUtils';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import PhoneRingSvg from '../../../common/Svg/PhoneRingSvg';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {ToastType, showToast} from '../../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import { styles } from '../cloudTelephonyStyles';

const {Content, CloseButton, Header, Footer, Body} = Modal;

interface IDeleteReleaseNumber {
  onFormCompleteAction: (actionCode: string, data: any, selectedInbox?: any) => void;
  selectedData: any;
  selectedInboxData?: any;
}

const DeleteReleaseNumber = (props: IDeleteReleaseNumber) => {
  const axios = BaseService.getSharedInstance().axios;
  const accountUuid = getAccountUUID();
  const [showModal, setShowModal] = useState(false);
  const [stateData, setStateData] = useState({
    accountUuid: '',
    userUuid: '',
    number: '',
    isLoading: false,
  });
  const intl = useIntl();
  const toast = useToast();

  const [DELETE_VIRTUAL_NUMBER] = useMutation(
    CloudTelephonyQueries.DELETE_VIRTUAL_NUMBER,
    {
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
    }
  );

  const phoneNumberDeletedSuccessfullyToast = ()=> {
    showToast(
      toast,
      `${stateData.number} ${intl.formatMessage({id :'deletedSuccessfully'})}`,
      ToastType.success,
      4000
    );
  }

  const onCancel = () => {
    setShowModal(false);
    setTimeout(() => {
      props.onFormCompleteAction(COMMON_ACTION_CODES.CANCEL, {});
    }, 100);
  };
  const onDeleteAction = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    axios
      .delete(
        `/cloud-telephony/phone-numbers/${props.selectedData.rawData.virtualPhoneNumber}/release`
      )
      .then(async (res) => {
        const responseData: any = await DELETE_VIRTUAL_NUMBER({
          context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
          variables: {
            accountUuid: accountUuid,
            virtualPhoneNumber: stateData.number,
          },
        });
        if (
          responseData?.data?.updateVirtualPhoneNumberAssignees?.returning
            ?.length
        ) {
          const selectedInbox = props?.selectedInboxData
          props.onFormCompleteAction(COMMON_ACTION_CODES.DELETED, {}, selectedInbox);
        } else {
        }
        setStateData((prev) => {
          return {
            ...prev,
            isLoading: false,
          };
        });
        phoneNumberDeletedSuccessfullyToast();
      })
      .catch(async (err) => {
        setStateData((prev) => {
          return {
            ...prev,
            isLoading: false,
          };
        });
        props.onFormCompleteAction(COMMON_ACTION_CODES.CANCEL, {});
      });
  };

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        accountUuid: props.selectedData.rawData.accountUuid,
        number: props.selectedData.rawData.virtualPhoneNumber,
      };
    });
    setTimeout(() => {
      setShowModal(true);
    }, 50);
  }, []);
  const {width} = Dimensions.get('window');
  const finalWidth = (width * 2) / 7;
  return (
    <Modal
      isOpen={showModal}
      onClose={() => {
        setShowModal(false);
        onCancel();
      }}
    >
      <Content
        style={styles.bgBackgroundColorMinWidth600}
      >
        <CloseButton />
        <Header>
          <DisplayText textLocalId="deleteNumber" textType="Heading" />
        </Header>
        <Body>
          {stateData.isLoading ? (
            <Spinner
              size="lg"
              style={styles.spinner}
            />
          ) : (
            <></>
          )}

          <View mx={0}>
            {props?.selectedInboxData?.channel_id && (
              <Text
                size={'smNormal'}
                fontWeight={400}
                color={Colors.Custom.Gray500}
              >
                Selected phone number is having inbox (
                {props?.selectedInboxData?.title}) If you delete this phone
                number inbox will be deleted.
              </Text>
            )}
            <Text
              size={'smNormal'}
              fontWeight={400}
              color={Colors.Custom.Gray500}
            >
              Are you sure want to delete the phone number?
            </Text>
            <View
              flexDirection={'row'}
              alignItems={'center'}
              style={styles.mt40}
            >
              <View style={styles.pt10}>
                <PhoneRingSvg />
              </View>
              <View style={styles.ml12}>
                <Text size={'smSemibold'} color={Colors.Custom.Gray900}>
                  {props.selectedData.phoneNumber.phoneNumber}
                </Text>
                <Text
                  style={styles.mt4}
                  size={'smMedium'}
                  color={Colors.Custom.Gray500}
                >
                  {props.selectedData.phoneNumber.name}
                </Text>
              </View>
            </View>
            <Text
              style={styles.mt40}
              size={'mdNormal'}
              fontWeight={400}
              color={Colors.danger['500']}
            >
              You cannot undo the action after deleting the number
            </Text>
          </View>
        </Body>
        <Footer
          style={styles.bgBackgroundColorDflexJcEnd}
        >
          <Button.Group>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  setShowModal(false);
                  onCancel();
                },
              }}
              customProps={{
                btnText: 'Cancel',
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onDeleteAction();
                },
              }}
              customProps={{
                btnText: 'Confirm',
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </Footer>
      </Content>
    </Modal>
  );
};

export default DeleteReleaseNumber;
