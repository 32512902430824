import {Upload} from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../styles';
import {EMPLOYER_CONST} from '../EmployerUtils/EmployerConst';

const ColorPlates = (props: any) => {
  const colors = [
    '#607D8B',
    '#795548',
    '#EEB200',
    '#8BC34A',
    '#009688',
    '#00BCD4',
    '#2196F3',
    '#5800FF',
    '#9C27B0',
    '#E81E63',
  ];

  return (
    <div style={{backgroundColor: '#fff', borderRadius: 8, width: 156}}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          padding: 8,
          paddingBottom: 0,
        }}
      >
        {colors.map((color, index) => (
          <div
            key={index}
            style={{
              backgroundColor: color,
              width: 24,
              height: 24,
              margin: 2,
              marginBottom: 8,
              borderRadius: 6,
              cursor: 'pointer',
            }}
            onClick={() =>
              props?.handleCoverPageBackGround(EMPLOYER_CONST.COLOR, color)
            }
          />
        ))}
      </div>
      <Upload
        listType="picture"
        multiple={true}
        beforeUpload={() => false}
        onChange={(info: any) => {
          props?.handleCoverPageBackGround(
            EMPLOYER_CONST.BG_IMAGE,
            URL.createObjectURL(info?.file)
          );
        }}
        fileList={[]}
        className="msg-attachment"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderTop: '#E9ECF1',
            borderTopWidth: 0.5,
            borderTopStyle: 'solid',
            padding: 8,
          }}
        >
          <Feather
            name="upload"
            size={16}
            style={{marginLeft: 4}}
            color={Colors.Custom.Gray400}
          />
          <div
            style={{
              color: '#3A485F',
              fontSize: 14,
              fontWeight: 400,
              marginLeft: 8,
            }}
          >
            Upload Image
          </div>
        </div>
      </Upload>
    </div>
  );
};

export default ColorPlates;
