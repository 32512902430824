import React from 'react';

const ThumbIcon = (props: {color?: string}) => {
  const svgColors = props.color ? props.color : '#7F56D9';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      viewBox="0 0 53 50"
      fill="none"
    >
      <path
        d="M5.26316 50H7.89474V15.7895H5.26316C3.86728 15.7895 2.52858 16.344 1.54154 17.331C0.554509 18.3181 0 19.6568 0 21.0526V44.7368C0 46.1327 0.554509 47.4714 1.54154 48.4585C2.52858 49.4455 3.86728 50 5.26316 50ZM47.3684 15.7895H28.9474L31.9 6.92632C32.1635 6.1353 32.2352 5.29301 32.1094 4.46883C31.9836 3.64464 31.6637 2.86214 31.1763 2.18578C30.6888 1.50941 30.0476 0.958532 29.3055 0.578515C28.5634 0.198498 27.7416 0.000216086 26.9079 0H26.3158L13.1579 14.3105V50H42.1053L52.4 27.3789L52.6316 26.3158V21.0526C52.6316 19.6568 52.0771 18.3181 51.09 17.331C50.103 16.344 48.7643 15.7895 47.3684 15.7895Z"
        fill={svgColors}
      />
    </svg>
  );
};

export default React.memo(ThumbIcon);
