import React from 'react';
import {GroupSummaryProps} from '../interfaces';
import {Drawer} from 'antd';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import {Text} from 'native-base';

const GroupSummary = (props: GroupSummaryProps) => {
  const {groupData, isOpen, onClose} = props;
  return (
    <Drawer
      visible={isOpen}
      onClose={onClose}
      width="50vw"
      title={
        <>
          <TitleSubtitleView
            title="Group Summary"
            isHideCommonButton
            containerStyle={{marginLeft: 0, marginBottom: 5}}
          />
        </>
      }
    >
      <Text>In Progress...</Text>
    </Drawer>
  );
};

export default GroupSummary;
