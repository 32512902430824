import {
  Button,
  Icon,
  IconButton,
  Pressable,
  Text,
  Tooltip,
  useMediaQuery,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Colors} from '../../../../../../styles';
import {
  COST_SAVING_CPT_MAIN_ACTION,
  COST_TYPE_CODE,
  SavingTypeConst,
} from '../helperFiles/CostTemplatesConst';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {ICostTemplate} from '../helperFiles/interface';
import CptCostTableView from './CptCostTableView';
import {testID, TestIdentifiers} from '../../../../../../testUtils';
import {Popover, Skeleton} from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import {useLazyQuery} from '@apollo/client';
import CostTemplateQueries from '../../../../../../services/CostSavingTemplates/CostTemplateQueries';
import {getIconByCode} from '../helperFiles/ViewHelper';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import AddSavingCPTsDrawer from '../AddOrUpdateViews/AddSavingCPTsDrawer';
import DeleteCptModal from '../AddOrUpdateViews/DeleteCptModal';
import {getAccountUUID} from '../../../../../../utils/commonUtils';
import {convertJSONtoCSV, getTemplateCptQuery} from '../helperFiles/utils';
import AddOrUpdateCostTemplate from '../AddOrUpdateViews/AddOrUpdateCostTemplate';
import {SearchBar} from '../../../../../common/SearchBar';
import {debounce} from 'lodash';
import {showToast, ToastType} from '../../../../../../utils/commonViewUtils';
import TemplateInfoDrawer from './TemplateInfoDrawer';
import {useIntl} from 'react-intl';

const TemplateDetailView = () => {
  const {costType, id, tabName} = useParams();
  const showModifier = tabName === SavingTypeConst.VISITS_SAVINGS
  const navigate = useNavigate();
  const intl = useIntl()
  const toast = useToast();
  const accountUuid = getAccountUUID();
  const [isSmallScreen] = useMediaQuery([
    {maxWidth: 1400},
  ]);
  const [stateData, setStateData] = useState({
    templateData: {} as ICostTemplate,
    visible: false,
    loading: false,
    drawerData: {
      actionCode: '',
      data: {} as any,
    },
    saveActionPerformed: false,
    searchString: ''
  });
  const characterLimit = 170;
  const [GET_TEMPLATE_BY_ID] = useLazyQuery(
    CostTemplateQueries.GET_TEMPLATE_BY_ID,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [GET_ALL_CPT_BY_TEMPLATE] = useLazyQuery(
    CostTemplateQueries.GET_ALL_CPT_BY_TEMPLATE,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const costTypeCode =
    costType === 'marketCost'
      ? COST_TYPE_CODE.MARKET_COST
      : costType === 'ourCost'
        ? COST_TYPE_CODE.OUR_COST
        : '';
  const content = () => {
    return (
      <View>
        {COST_SAVING_CPT_MAIN_ACTION.map((action) => {
          return (
            <Pressable
              key={action.code}
              onPress={() => {
                onActionPerformed(action.code);
                setStateData((prev) => {
                  return {
                    ...prev,
                    visible: false,
                  };
                });
              }}
              style={{
                paddingBottom: 8,
                paddingTop: 12,
                paddingLeft: 4,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {getIconByCode(action.code)}
              <Text
                size={'smMedium'}
                style={{color: Colors.Custom.Gray700, marginLeft: 8}}
              >
                {action.label}
              </Text>
            </Pressable>
          );
        })}
      </View>
    );
  };
  const handleVisibleChange = (newVisible: boolean) => {
    setStateData((prev) => {
      return {
        ...prev,
        visible: newVisible,
      };
    });
  };
  const getTemplatesCpts = async () => {
    const templateId = id;
    const response = await GET_ALL_CPT_BY_TEMPLATE({
      variables: {
        where: getTemplateCptQuery({
          accountUuid,
          templateId,
        })
      },
    });
    if (response.data?.costSavingTemplateCpts?.length) {
      const formattedResponse = response.data?.costSavingTemplateCpts?.map((cptObj: any) => {
        return {
          CPT: cptObj?.accountCpt?.code || '',
          NAME: cptObj?.accountCpt?.name || '',
          COST: cptObj?.price || 0,
          ...(showModifier ? {MODIFIER: cptObj?.accountCpt?.modifier || ''} : {})
        }
      })
      convertJSONtoCSV(formattedResponse, stateData.templateData?.name)
    } else {
      showToast(
        toast,
        "No CPT's exist for the template",
        ToastType.info,
      );
    }
  };
  const getTemplateById = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const response = await GET_TEMPLATE_BY_ID({
      variables: {
        id: id,
      },
    });
    if (response.data?.costSavingTemplates?.length) {
      const templateObject = response.data?.costSavingTemplates[0];
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          templateData: {
            ...prev.templateData,
            name: templateObject.name,
            description: templateObject.description,
            analyticalResourceId: templateObject.analyticalResourceId,
            costCategoryId: templateObject?.costCategoryId,
            id: templateObject?.id
          },
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          templateData: {} as ICostTemplate,
        };
      });
    }
  };
  const handleSearchChange = (searchString: any) => {
    setStateData(prev=> {
      return {
        ...prev,
        searchString: searchString.trim()
      }
    })
  };
  const onActionPerformed = (code: string, rowData?: any) => {
    switch (code) {
      case COMMON_ACTION_CODES.DOWNLOAD:
        getTemplatesCpts()
        break
      case COMMON_ACTION_CODES.ADD:
      case COMMON_ACTION_CODES.DUPLICATE:
      case COMMON_ACTION_CODES.EDIT:
        setStateData((prev) => {
          return {
            ...prev,
            drawerData: {
              actionCode: code,
              data: rowData,
            },
            saveActionPerformed: false
          };
        });
        break;
      case COMMON_ACTION_CODES.DELETE:
        setStateData((prev) => {
          return {
            ...prev,
            drawerData: {
              actionCode: code,
              data: rowData,
            },
            saveActionPerformed: false
          };
        });
        break;
      case COMMON_ACTION_CODES.UPLOAD:
        setStateData((prev) => {
          return {
            ...prev,
            drawerData: {
              actionCode: code,
              data: rowData,
            },
            saveActionPerformed: false
          };
        });
        break;
      case COMMON_ACTION_CODES.CANCEL:
        setStateData((prev) => {
          return {
            ...prev,
            drawerData: {} as any,
          };
        });
        break;
      case COMMON_ACTION_CODES.SAVE:
        getTemplateById();
        setStateData((prev) => {
          return {
            ...prev,
            drawerData: {} as any,
            saveActionPerformed: true
          };
        });
        break;
    }
  };
  useEffect(() => {
    getTemplateById();
  }, [id]);
  return (
    <View>
      {stateData.loading ? (
        <Skeleton active />
      ) : (
        <>
          <View
            style={{
              borderWidth: 1,
              borderColor: Colors.Custom.Gray100,
              borderRadius: 8,
              paddingHorizontal: 16,
              paddingVertical: 12,
              marginHorizontal: 16,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            flex={1}
          >
            <View flex={ isSmallScreen ? .65 : 7} style={{flexDirection: 'row', alignItems: 'center', marginRight: 70}}>
              <VStack>
                <Button
                  backgroundColor={'white'}
                  style={{
                    height: 40,
                    width: 40,
                    borderRadius: 30,
                    marginLeft: -11,
                  }}
                  onPress={() => {
                    navigate(-1);
                  }}
                >
                  <AntIcon name="arrowleft" size={18} />
                </Button>
              </VStack>
              <View width={'100%'}>
                <Text size={'smMedium'} style={{color: Colors.Custom.Gray700}}>
                  {stateData?.templateData?.name}
                </Text>
                <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                  <Text
                    size={'xsMedium'}
                    style={{color: Colors.Custom.Gray500}}
                    noOfLines={1}
                  >
                    {stateData?.templateData?.description}
                  </Text>
                  <Text
                      style={{
                        display:
                          stateData?.templateData?.description?.length >
                          characterLimit
                            ? 'flex'
                            : 'none',
                            marginLeft: 4,
                      }}
                      color={Colors.Custom.mainPrimaryPurple}
                      minWidth={'max-content'}
                      size='xsMedium'
                      onPress={()=> [
                        setStateData(prev=> {
                          return {
                            ...prev,
                            drawerData: {
                              ...prev.drawerData,
                              actionCode: COMMON_ACTION_CODES.DETAIL_VIEW,
                            }
                          }
                        })
                      ]}
                    >{intl.formatMessage({id: 'viewMore'})}
                  </Text>
                </View>
              </View>
            </View>
            <View flex={ isSmallScreen ? .35 : 3} style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
            <SearchBar
                placeholderText="Search CPT or Name"
                onChange={debounce(handleSearchChange, 500)}
              />
              <Popover
                overlayInnerStyle={{
                  paddingBottom: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  borderRadius: 16,
                  width: 'auto',
                }}
                className="popoverStyle"
                placement={'bottomLeft'}
                trigger="click"
                visible={stateData.visible}
                onVisibleChange={handleVisibleChange}
                content={content}
              >
                <IconButton
                style={{marginLeft: 24}}
                  colorScheme="gray"
                  tintColor="gray.500"
                  variant="ghost"
                  onPress={() =>
                    setStateData((prev) => {
                      return {
                        ...prev,
                        visible: true,
                      };
                    })
                  }
                  {...testID(TestIdentifiers.deleteBtn)}
                  icon={
                    <Feather
                      name="more-horizontal"
                      size={20}
                      color={Colors.Custom.Gray400}
                    />
                  }
                />
              </Popover>
            </View>
          </View>
          <CptCostTableView
            searchString={stateData.searchString}
            onActionPerformed={onActionPerformed}
            costSavingTemplate={stateData.templateData}
            costTypeCode={costTypeCode}
            saveActionPerformed={stateData.saveActionPerformed}
          />
        </>
      )}
      {stateData.drawerData?.actionCode === COMMON_ACTION_CODES.ADD ||
        stateData.drawerData?.actionCode === COMMON_ACTION_CODES.DUPLICATE ||
        stateData.drawerData?.actionCode === COMMON_ACTION_CODES.EDIT ? (
        <AddSavingCPTsDrawer
          templateId={id}
          analyticalResourceId={stateData?.templateData?.analyticalResourceId}
          selectedData={stateData.drawerData?.data}
          actionCode={stateData.drawerData?.actionCode}
          onFormCompleteAction={onActionPerformed}
        />
      ) : (
        <></>
      )}
      {stateData.drawerData.actionCode === COMMON_ACTION_CODES.DELETE ?
        <DeleteCptModal
          templateId={id}
          analyticalResourceId={stateData?.templateData?.analyticalResourceId}
          selectedData={stateData.drawerData?.data}
          onActionPerformed={onActionPerformed}
        />
        : <></>}
        {stateData.drawerData?.actionCode === COMMON_ACTION_CODES.UPLOAD ? (
        <AddOrUpdateCostTemplate
          onActionPerformed={onActionPerformed}
          selectedData={stateData.templateData}
          actionCode={COMMON_ACTION_CODES.EDIT}
          analyticalResourceId={stateData.templateData?.analyticalResourceId}
          analyticalResource={''}
        />
      ) : (
        <></>
      )}
       {stateData?.drawerData?.actionCode === COMMON_ACTION_CODES.DETAIL_VIEW ? (
        <TemplateInfoDrawer
          templateData={stateData.templateData}
          onCloseModal={() => {
            onActionPerformed(COMMON_ACTION_CODES.CANCEL)
          }}
        />
      ) : (
        <></>
      )}
    </View>
  );
};

export default TemplateDetailView;
