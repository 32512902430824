export enum SearchCode {
  CVX = 'http://hl7.org/fhir/sid/cvx',
  CPT = 'CPT',
  FOLD = 'http://fold.health',
  ICD10 = 'ICD10',
  ICD9 = 'ICD9',
  NPI = 'http://hl7.org/fhir/sid/us-npi',
  RXNORM = 'http://www.nlm.nih.gov/research/umls/rxnorm',
  SNOMED = 'http://snomed.info/sct',
  ATHENA = 'athena',
  ELATION = 'elation',
  IDENTIFIER_TYPE = 'http://terminology.hl7.org/CodeSystem/v2-0203',
  LOINC = 'http://loinc.org'
}

export const getCodeSystem = (system?: string) => {
  switch (system) {
    case SearchCode.CVX: return 'CVX';
    case SearchCode.FOLD: return 'FOLD';
    case SearchCode.NPI: return 'NPI';
    case SearchCode.RXNORM: return 'RXNORM';
    case SearchCode.SNOMED: return 'SNOMED';
    case SearchCode.LOINC: return 'LOINC';
    default: break;
  }
  return system;
};
