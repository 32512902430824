import React from 'react';
import {styles} from './CustomBadgeStyles';
import {testID} from '../../../testUtils';
import {View, ViewStyle} from 'react-native';
import {Text} from 'react-native';

interface IcustomBadgeProps {
  varient: BadgeVarient;
  size?: BadgeSize;
  badgeText?: string;
  testId?: string;
  extraStyles?: ViewStyle[];
  extraBadgeTextStyles?: any;
}

export enum BadgeVarient {
  primary = 'primary',
  success = 'success',
  warning = 'warning',
  secondary = 'secondary',
}

export enum BadgeSize {
  s = 'small',
  l = 'large',
}

export const CustomBadge = (props: IcustomBadgeProps) => {
  const {
    varient,
    badgeText,
    size = BadgeSize.s,
    testId = '',
    extraBadgeTextStyles,
    extraStyles,
  } = props;

  const getBadgeStyle = () => {
    let badgeStyle = {};
    switch (varient) {
      case BadgeVarient.primary:
        badgeStyle = styles.primaryBadgeView;
        break;
      case BadgeVarient.success:
        badgeStyle = styles.successBadgeView;
        break;
      case BadgeVarient.warning:
        badgeStyle = styles.warningBadgeView;
        break;
      case BadgeVarient.secondary:
        badgeStyle = styles.secondaryBadgeView;
        break;
    }
    return [
      styles.badgeView,
      badgeStyle,
      {paddingHorizontal: size === BadgeSize.s ? 4 : 8},
      {paddingVertical: size === BadgeSize.s ? 2 : 4},
      {width: 'fit-content'},
    ];
  };

  const getBadgeTextStyle = () => {
    let badgeTextStyle = {};
    switch (varient) {
      case BadgeVarient.primary:
        badgeTextStyle = styles.primaryBadgeText;
        break;
      case BadgeVarient.warning:
        badgeTextStyle = styles.warningBadgeText;
        break;
      case BadgeVarient.success:
        badgeTextStyle = styles.successBadgeText;
        break;
      case BadgeVarient.secondary:
        badgeTextStyle = styles.secondaryBadgeText;
        break;
    }
    return badgeTextStyle;
  };

  return (
    <View>
      <View style={[getBadgeStyle()]} {...testID(testId)}>
        <Text style={[getBadgeTextStyle(), {flex: 1}]} testID={badgeText}>
          {badgeText}
        </Text>
      </View>
    </View>
  );
};
