import {IInboxChannelType, IUserInboxList} from './interfaces';
import {IInbox} from '../../../../../services/Inbox/interfaces';
import {ILoginUserData} from '../../../../../Interfaces';
import {StrCaseConverter} from '../../../../../utils/CaseConverter';

export const getAllUserInboxes = (
  inboxList: IInbox[],
  loginUserData: ILoginUserData
): IUserInboxList => {
  const userInboxList = {} as IUserInboxList;
  for (let index = 0; index < inboxList.length; index++) {
    const singleInbox = inboxList[index];
    if (singleInbox?.inboxMembers?.length) {
      if (loginUserData.role === 'administrator') {
        const channelType = singleInbox.channelType.replace('::', '');
        const channelTypeStr: IInboxChannelType =
          StrCaseConverter.toConstantCase(channelType) as any;
        if (userInboxList[channelTypeStr]?.length) {
          userInboxList[channelTypeStr].push(singleInbox);
        } else {
          userInboxList[channelTypeStr] = [];
          userInboxList[channelTypeStr].push(singleInbox);
        }
      } else {
        const userId = loginUserData.id;
        const userData =
          singleInbox?.inboxMembers.find((singleUser) => {
            return singleUser.userId === userId;
          }) || ({} as any);
        if (userData?.userId) {
          const channelType = singleInbox.channelType.replace('::', '');
          const channelTypeStr: IInboxChannelType =
            StrCaseConverter.toConstantCase(channelType) as any;
          if (userInboxList[channelTypeStr]?.length) {
            userInboxList[channelTypeStr].push(singleInbox);
          } else {
            userInboxList[channelTypeStr] = [];
            userInboxList[channelTypeStr].push(singleInbox);
          }
        }
      }
    }
  }
  return userInboxList;
};
