import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  container: {

  },
  textContainer: {
    fontWeight: '400',
    fontSize: 24,
    color: '#667085',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftText: {
    fontSize: 18,
    lineHeight: 32,
    fontWeight: '500',
    marginLeft: 16,
  },
});
