import { StyleSheet } from "react-native";
import { Colors } from "../../../styles/Colors";

export const modalActionDialogStyles = StyleSheet.create({
  modalStyle: {
    border: '0.5px',
    padding: '0px',
    borderColor: Colors.FoldPixel.GRAY100,
    boxShadow: Colors.Custom.ShadowColor,
  },

  stackStyle: {
    alignItems: 'center',
  },
  
  buttonListStackStyle : {
    width: '360px',
    alignItems: 'center'
  },

  buttonStyle: {
    height: 32,
    width: 156,
    borderRadius: 4,
    borderWidth: 0.5,
  }
});