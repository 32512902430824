import {Drawer} from 'antd';
import {Pressable, Skeleton, View} from 'native-base';
import {StyleSheet} from 'react-native';
import React, {useEffect, useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {
  getDrillDownDataByResourceCode,
} from '../../../../../../services/Analytics';
import {Colors} from '../../../../../../styles';
import {getCurrentTimeZone} from '../../../../../../utils/DateUtils';
import {ModalActionTitle} from '../../../../ModalActionTitle/ModalActionTitle';
import {
  IPageSection,
  ITaskDistributionWidget,
  ITaskFilterState,
  IUserData,
  RESOURCE_LIST,
} from '../../interface';
import TaskDistributionWidget from '../TaskDistributionWidget';
import AverageTimeResolution from '../AverageTimeResolution';
import TaskDelayOverViewDrilLDown from './TaskDelayOverViewDrilLDown';
import TaskAnalyticsFilterView from '../../TaskAnalyticsFilterView';
import {FILTER_TYPE_CODES, TASK_ANALYTICS_DRILL_DOWN_HEADING} from '../../constants';
import {ALL_MEMBERS} from '../../../../../RightSideContainer/Analytics/EmployerAnalytics/EmployerUtils/EmployerConst';
import {TASK_ASSIGNEE_TYPE_CODES} from '../../../CareDashboardTable/CareDashboardTableHelper';
import NodataViewTypeOne from '../../../../../RightSideContainer/Analytics/EmployerAnalytics/SectionAndWidget/NoDataView/NodataViewTypeOne';
const TaskAnalyticsDrillDown = (props: {
  pageSection: IPageSection;
  taskAnalyticState: ITaskFilterState;
  onActionPerformed: (code: string, data?: any) => void;
  userData: IUserData
}) => {
  const {pageSection, taskAnalyticState, onActionPerformed, userData} = props;
  const controller = new AbortController();
  const [showModal, setShowModal] = useState(true);
  const {width} = Dimensions.get('window');
  const finalWidth = [
    RESOURCE_LIST.TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_ASSIGNEE,
    RESOURCE_LIST.TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_TASK_POOL,
  ].includes(pageSection.resource.code)
    ? width * 0.4
    : pageSection.resource.code ===
      RESOURCE_LIST.TASK_ANALYTICS_TASK_DISTRIBUTION_BY_ASSIGNEE
    ? width * 0.65
    : width * 0.4;
  const [renderWidgetState, setRenderWidgetState] = useState({
    widgetData: {} as any,
    loading: false,
    isLoading: false,
  });
  const [drillDownTaskAnalyticState, setDrillDownTaskAnalyticState] = useState({
    employerData: taskAnalyticState.employerData,
    dateRange: taskAnalyticState.dateRange,
    selectedTaskPriorityList: taskAnalyticState.selectedTaskPriorityList,
    patientContext: taskAnalyticState.patientContext,
    taskLabelsIds: taskAnalyticState?.taskLabelsIds,
    taskPoolsIds: taskAnalyticState?.taskPoolsIds
  });
  const [isEndReached, setIsEndReached] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 50;
  const pageOffset = 0;
  const onCloseModal = () => {
    setShowModal(false);
    onActionPerformed(COMMON_ACTION_CODES.CLOSE, {});
  };
  const taskAssigneeIdFilter = !userData?.isBusinessOwner
    ? {
        filterCode: 'TASK_ASSIGNEE_IDS',
        filterValue: {
          value: [userData?.uuid],
        },
      }
    : {};
  const getWidgetByResourceCode = (pageSection: IPageSection) => {
    let ele = <></>;
    switch (pageSection.resource.code) {
      case RESOURCE_LIST.TASK_ANALYTICS_TASK_DISTRIBUTION_BY_ASSIGNEE:
        ele = (
          <TaskDistributionWidget
            fetchMoreItems={getDrillDownData}
            isDrillDown={true}
            widgetData={renderWidgetState.widgetData}
            isEndReached={isEndReached}
            isLoading={renderWidgetState.isLoading}
            loadingView={
              renderWidgetState.isLoading ? (
                <View style={{marginBottom: 20}}>
                  <Skeleton.Text />
                </View>
              ) : (
                <></>
              )
            }
          />
        );
        break;
      case RESOURCE_LIST.TASK_ANALYTICS_AVERAGE_TIME_TO_RESOLUTION_BY_ASSIGNEE:
        ele = (
          <AverageTimeResolution
            pageSection={pageSection}
            fetchMoreItems={getDrillDownData}
            isDrillDown={true}
            widgetData={{
              ...renderWidgetState?.widgetData,
              rowsByAssignee: renderWidgetState?.widgetData?.rows,
            }}
            isEndReached={isEndReached}
            isLoading={renderWidgetState.isLoading}
            loadingView={
              renderWidgetState.isLoading ? (
                <View style={{marginBottom: 20}}>
                  <Skeleton.Text />
                </View>
              ) : (
                <></>
              )
            }
          />
        );
        break;
      default:
        ele = (
          <TaskDelayOverViewDrilLDown
            pageSection={pageSection}
            fetchMoreItems={getDrillDownData}
            isDrillDown={true}
            rowsByAssignee={renderWidgetState?.widgetData?.rows}
            loadingView={
              renderWidgetState.isLoading ? (
                <View style={{marginBottom: 20}}>
                  <Skeleton.Text />
                </View>
              ) : (
                <></>
              )
            }
          />
        );
        break;
    }
    return ele;
  };

  const getRowDataList = (widgetData: any) => {
    if (widgetData.rows?.length) {
      return widgetData.rows;
    } else if (widgetData?.rowsByAssignee?.length) {
      return widgetData?.rowsByAssignee;
    } else {
      return [];
    }
  };
  const getDrillDownData = async (isFirstLoad?: boolean) => {
    if ((renderWidgetState.isLoading) && !isFirstLoad) return;
    if (
      !isFirstLoad &&
      renderWidgetState.widgetData?.total <=
      renderWidgetState?.widgetData?.rows?.length
    ) {
      return;
    }
    setIsEndReached(false);
    if (pageNumber > 1 && !isFirstLoad) {
      setRenderWidgetState((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
    } else {
      setPageNumber(1)
      setRenderWidgetState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
    }
    const patientContextFilter = drillDownTaskAnalyticState.patientContext?.toLowerCase();
    const body: any = {
      resourceCode: pageSection.resource.code,
      isAccountDashboard: false,
      appliedFilter: [
        {
          filterCode: 'DATE_RANGE',
          filterValue: {
            fromDate: drillDownTaskAnalyticState.dateRange.from,
            toDate: drillDownTaskAnalyticState.dateRange.to,
            timezone: getCurrentTimeZone(),
          },
        },
        {
          filterCode: 'IS_TASK_WITH_PATIENT_CONTEXT',
          filterValue: {
            value:
              patientContextFilter == 'yes'
                ? true
                : patientContextFilter == 'no'
                ? false
                : undefined,
          },
        },
        {
          filterCode: 'TASK_ASSIGNEE_TYPE_CODE',
          filterValue: {
            value: TASK_ASSIGNEE_TYPE_CODES.User,
          },
        },
        {
          filterCode: 'TASK_PRIORITY_IDS',
          filterValue: {
            value: drillDownTaskAnalyticState?.selectedTaskPriorityList || [],
          },
        },
        {
          filterCode: 'TASK_POOL_IDS',
          filterValue: {
            value: drillDownTaskAnalyticState?.taskPoolsIds || [],
          },
        },
        {
          filterCode: 'LABEL_IDS',
          filterValue: {
            value: drillDownTaskAnalyticState?.taskLabelsIds || [],
          },
        },
        {
          filterCode: 'PAGE_SIZE',
          filterValue: {value: pageSize},
        },
        {
          filterCode: 'PAGE_OFFSET',
          filterValue: {
            value:
              pageNumber > 1 && !isFirstLoad
                ? (pageNumber - 1) * pageSize
                : pageOffset,
          },
        },
        taskAssigneeIdFilter
      ],
    };
    if (drillDownTaskAnalyticState?.employerData?.id != ALL_MEMBERS) {
      body.employerId = drillDownTaskAnalyticState?.employerData?.id;
    }
    const resp = await getDrillDownDataByResourceCode(
      pageSection.resource.code,
      {...body}
    );
    if (resp.data?.result && getRowDataList(resp.data?.result)?.length) {
      setIsEndReached(false);
      const rows = getRowDataList(resp.data?.result);
      setRenderWidgetState((prev) => {
        return {
          ...prev,
          widgetData: {
            ...prev.widgetData,
            ...resp.data?.result,
            rows: !isFirstLoad && prev.widgetData?.rows?.length
              ? [...prev.widgetData?.rows, ...rows]
              : [...rows],
          },
          loading: false,
          isLoading: false,
        };
      });
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
      setIsEndReached(false);
    } else {
      setIsEndReached(true);
      setRenderWidgetState((prev) => {
        return {
          ...prev,
          widgetData: isFirstLoad ? {} : prev.widgetData as ITaskDistributionWidget,
          loading: false,
          isLoading: false,
        };
      });
    }

  };
  const onFilterChange = (code: string, data: any) => {
    if (code === FILTER_TYPE_CODES.EMPLOYER) {
      setDrillDownTaskAnalyticState((prev) => {
        return {
          ...prev,
          employerData: data,
        };
      });
    } else if (code === FILTER_TYPE_CODES.DATE_RANGE) {
      setDrillDownTaskAnalyticState((prev) => {
        return {
          ...prev,
          dateRange: data,
        };
      });
    } else if (code === FILTER_TYPE_CODES.PRIORITY) {
      setDrillDownTaskAnalyticState((prev) => {
        return {
          ...prev,
          selectedTaskPriorityList: data,
        };
      });
    }  else if (code === FILTER_TYPE_CODES.PATIENT_CONTEXT) {
      setDrillDownTaskAnalyticState((prev) => {
        return {
          ...prev,
          patientContext: data,
        };
      });
    } else if (code === FILTER_TYPE_CODES.LABELS) {
      setDrillDownTaskAnalyticState((prev) => {
        return {
          ...prev,
          taskLabelsIds: data,
        };
      });
    }
    else if (code === FILTER_TYPE_CODES.TASK_POOL) {
      setDrillDownTaskAnalyticState((prev) => {
        return {
          ...prev,
          taskPoolsIds: data,
        };
      });
    }
  };
  useEffect(() => {
    getDrillDownData(true);
    return () => controller?.abort();
  }, [
    pageSection.id,
    drillDownTaskAnalyticState?.employerData?.id,
    drillDownTaskAnalyticState?.dateRange?.from,
    drillDownTaskAnalyticState?.dateRange?.to,
    drillDownTaskAnalyticState?.selectedTaskPriorityList?.length,
    drillDownTaskAnalyticState?.patientContext,
    drillDownTaskAnalyticState?.taskLabelsIds?.length,
    drillDownTaskAnalyticState?.taskPoolsIds?.length
  ]);

  return (
    <Drawer
      visible={showModal}
      width={finalWidth}
      onClose={() => {
        onCloseModal();
      }}
      bodyStyle={{
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
        paddingTop: 0,
      }}
      closable={false}
      title={
        <ModalActionTitle
          text={TASK_ANALYTICS_DRILL_DOWN_HEADING[pageSection?.resource?.code]}
          titleColor={''}
          titleSize={24}
          rightButton={
            <View style={styles.rightButtonContainer}>
              <View style={styles.divider} />
              <Pressable
                onPress={() => {
                  onCloseModal();
                }}
              >
                <AntIcon name="close" size={20} color={Colors.Custom.Gray500} />
              </Pressable>
            </View>
          }
        />
      }
    >
      <TaskAnalyticsFilterView
        code={'TaskAnalyticsFilterView'}
        isDrillDown={true}
        taskLabelsIds={drillDownTaskAnalyticState.taskLabelsIds}
        taskPoolsIds={drillDownTaskAnalyticState.taskPoolsIds}
        onFilterChange={onFilterChange}
        employerData={drillDownTaskAnalyticState?.employerData}
        selectedTaskPriorityList={
          drillDownTaskAnalyticState?.selectedTaskPriorityList
        }
        patientContext={drillDownTaskAnalyticState.patientContext}
        dateRange={drillDownTaskAnalyticState?.dateRange}
      />
      {renderWidgetState?.loading ? (
        <Skeleton.Text />
      ) : (
        !Object.keys(renderWidgetState?.widgetData).length ? (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80%',
          }}>
            <NodataViewTypeOne viewCode={'TEXT_ICON'} />
          </div>
        ) : (
          getWidgetByResourceCode(pageSection)
        )
      )}
    </Drawer>
  );
};

const styles = StyleSheet.create({
  drawerBody: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24,
    paddingTop: 0,
  },
  rightButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    width: 1.5,
    backgroundColor: Colors.Custom.Gray200,
    marginHorizontal: 8,
    height: 20,
  },
  noDataContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
  },
});

export default TaskAnalyticsDrillDown;
