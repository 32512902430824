import {Menu} from 'antd';
import {View} from 'native-base';
import {useLocation} from 'react-router-dom';
import {FHIR_RESOURCE} from '../../constants/FhirConstant';
import {testID, TestIdentifiers} from '../../testUtils';
import {getResourceAbilities} from '../../utils/capabilityUtils';
import { getBooleanFeatureFlag, getUserUUID, isEnableCareProgram } from '../../utils/commonUtils';
import EmployerProfile from '../RightSideContainer/EmployerManagement/EmployerProfile';
import {getUserPermissionByEntityAndActionCode} from '../RightSideContainer/UserAccess/UserAccessPermission';
import {
  getMenuConfig,
  getSideBarObjectFromLocationPath,
} from './SideBarHelper';
import {
  ISideBarLocation,
  ISideBarMenu,
  ISideBarProps,
} from './SideBarInterface';
import './SideMenuBar.css';
import { CommonDataContext } from '../../context/CommonDataContext';
import {isAccountConfigEnabled} from '../../utils/configUtils';
import {CONFIG_CODES} from '../../constants/AccountConfigConst';
import {usePermissions} from '../CustomHooks/usePermissions';
import SideMenuBarItem from './SideMenuBarItem';
import React, { useMemo } from 'react';
import { MAIN_MENU_CODES } from './SideBarConst';
import { CaptureTransaction, TRANSACTION_NAMES } from '../../utils/CaptureTransaction';
import FeatureFlags from '../../constants/FeatureFlags.enums';

const SideMenuBar = (props: ISideBarProps) => {
  const commonData = React.useContext(CommonDataContext)
  const userSettings = commonData.userSettings;
  const enableCareProgram = isEnableCareProgram(userSettings);
  const location = useLocation();
  const integrationAdminCapabilities = getResourceAbilities(
    FHIR_RESOURCE.INTEGRATION_ADMIN,
    '',
    ''
  );
  const {check} = usePermissions()
  const captureTransactionInst = CaptureTransaction.getInstance();

  const showIntegrationTab = integrationAdminCapabilities?.isEnabled || false;
  const userUUID = getUserUUID();
  const menuConfig = getMenuConfig(userUUID, location.pathname, showIntegrationTab, {enableCareProgram: enableCareProgram});
  const menuObj: ISideBarLocation = getSideBarObjectFromLocationPath(
    location.pathname,
    menuConfig.sideBarMenus
  );
  let sideBarOpenKeys = [menuObj.mainMenu || menuConfig.defaultMenu];
  const currentSelectedKey = [menuObj.mainMenu || menuConfig.defaultMenu];
  const [openProfileModal, setOpenProfileModal] = React.useState(false);
  const handleSubMenuClick = (menuCode: string) => {
    sideBarOpenKeys = [menuCode || ''];
  };
  const isNewSettingTabEnabled = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_NEW_SETTING_TAB_ENABLED);

  const onLinkClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, path: string) => {
      if (path === 'profile') {
        e.preventDefault();
        setOpenProfileModal(true);
      }
    },
    []
  );

  const isSettingMenuSelected = useMemo(() => {
    if (!isNewSettingTabEnabled) {
      return false;
    }
    if (!currentSelectedKey || !currentSelectedKey?.length) {
      return false;
    }
    return currentSelectedKey?.[0] === MAIN_MENU_CODES.SETTING
  }, [currentSelectedKey])

  React.useEffect(() => {
    if (currentSelectedKey && currentSelectedKey.length > 0) {
      captureTransactionInst.initiateTransaction({
        name: TRANSACTION_NAMES.SIDEBAR_CHANGE,
        identifier: currentSelectedKey[0],
      });
      captureTransactionInst.finishTransaction(
        TRANSACTION_NAMES.SIDEBAR_CHANGE,
        currentSelectedKey[0]
      );
    }
    return () => {
      if (currentSelectedKey && currentSelectedKey.length > 0) {
        captureTransactionInst.clearTransaction(
          TRANSACTION_NAMES.SIDEBAR_CHANGE,
          currentSelectedKey[0]
        );
      }
    };
  }, [currentSelectedKey?.[0]]);

  return (
    <View {...testID(TestIdentifiers.sidebar)}>
      <Menu
        defaultOpenKeys={currentSelectedKey}
        openKeys={sideBarOpenKeys}
        selectedKeys={currentSelectedKey}
        style={{border: 'none', backgroundColor: 'transparent', margin: '0px'}}
        mode="vertical"
        className="sideBarMenu"
      >
        {menuConfig.sideBarMenus.map(
          (menuItem: ISideBarMenu, menuIndex: number): JSX.Element => {
            const entityCode = !(menuItem.menuCode === MAIN_MENU_CODES.SETTING) && menuItem?.path?.includes('admin')
            ? 'ADMIN_PANEL_WINDOW'
            : 'DASHBOARD_WINDOW'
            const hasMenuAccess  = check(entityCode, menuItem?.menuCode,'').hasAccess

            if (!hasMenuAccess || (isSettingMenuSelected && menuItem.menuCode !== MAIN_MENU_CODES.SETTING)) {
              return <></>;
            }

            return (
              <Menu.Item
                key={menuIndex}
                onClick={() => {
                  handleSubMenuClick(menuItem.menuCode);
                }}
                id={menuItem.menuCode}
                style={{
                  height: 85,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '100%',
                  display: 'flex',
                  padding: 0,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  margin: '0px',
                }}
              >
                <SideMenuBarItem
                  currentSelectedKey={currentSelectedKey[0]}
                  menuCode={menuItem.menuCode}
                  menuCollaps={props.menuCollaps}
                  menuName={menuItem.menuName}
                  onGlobalWarningOpen={props.onGlobalWarningOpen}
                  onLinkClick={onLinkClick}
                  path={menuItem.path}
                />
              </Menu.Item>
            );
          }
        )}
      </Menu>
      {openProfileModal &&
        <EmployerProfile
          close={()=>{
            setOpenProfileModal(false)
          }}
        />
      }
    </View>
  );
};

export default SideMenuBar;
