import React from 'react';
import {Pie, PieConfig} from '@ant-design/charts';
import './style.css';
import {Typography} from 'antd';
import {Spinner, View} from 'native-base';
import { Colors } from '../../../../styles/Colors';
const {Text, Title} = Typography;

export interface AnalyticsWidgetProps {
  pieConfig: PieConfig;
  title: string;
  subtitle?: string;
  loading?: boolean;
  height?: any;
  legend?: {};
  isFormSearchShow?: boolean;
  formValue?: any;
  onChange?: (value: any) => void;
  showNoFormSentText?: any;
  searchComponent?: JSX.Element;
}

const AnalyticsWidget = (props: AnalyticsWidgetProps) => {
  const {
    pieConfig,
    title,
    subtitle,
    loading,
    height,
    legend,
    searchComponent,
    showNoFormSentText,
  } = props;
  return (
    <div className="display_card_container" style={{height: height || '358px'}}>
      <header style={{display: 'flex', justifyContent: 'space-between'}}>
        <div
          style={{
            width: '100%',
          }}
        >
          <Title level={5} className="display_card_title">
            {title}
          </Title>
          {subtitle  ? (
            <Text
              style={{
                color: Colors.Custom.GraphSubTitleColor,
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              {subtitle}
            </Text>
          ) : (
            <></>
          )}
          {searchComponent ? (
            <View width={'100%'} flex={1}>
              {searchComponent}
            </View>
          ) : (
            <></>
            // <Text
            //   //className="display_card_subtitle"
            //   style={{
            //     color: Colors.Custom.GraphSubTitleColor,
            //     fontWeight: 500,
            //     fontSize: 14,
            //   }}
            // >
            //   {subtitle}
            // </Text>
          )}
        </div>
      </header>

      {showNoFormSentText ? (
        <View width={'100%'} flex={1}>
          {showNoFormSentText}
        </View>
      ) : (
        <main style={{overflowY: 'auto', overflowX: 'hidden'}}>
          {loading ? (
            <Spinner size={'lg'} />
          ) : (
            <Pie {...pieConfig} legend={legend || undefined} />
          )}
        </main>
      )}
    </div>
  );
};

export default AnalyticsWidget;
