import {Table} from 'antd';
import {Skeleton, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {MODULE_PAGINATION_COUNT} from '../../../../../constants';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {getProducts} from '../../../../../services/Stripe/StripeServices';
import {testID, TestIdentifiers} from '../../../../../testUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import { TableWrapper } from '../../../../common/TableWrapper';
import ContactSearchOnlyDrawer from '../../../TeamInbox/Conversations/MessagingWindow/ContactSearchOnlyDrawer';
import {IProductTableDisplayData} from '../../ProductsAndServices/Products/ProductTableView/ProductTable/interfaces';
import {formateStripeProductListData} from '../../ProductsAndServices/Products/ProductTableView/ProductUtils/ProductTableUtils';
import {addOrUpdateMembership} from '../MembershipAPI';
import {style} from '../MembershipStyles';
import {getMembershipsTableColumns} from './MembershipTableColumns';

const MembershipTable = (props: {
  searchText: string;
  onActionPerformed: (actionType: string) => void;
  isOneTime: boolean;
}) => {
  const navigate = useNavigate();
  const [showContactDrawer, setShowContactDrawer] = useState<any>(false);
  const [productTableState, setProductTableState] = useState({
    isLoading: true,
    isNoDataFound: false,
    selectedProduct: {} as any,
    searchString: props.searchText,
    productList: [] as any[],
    productDisplayList: [] as any[],
    page: 1,
    pageSize: MODULE_PAGINATION_COUNT.PRODUCTS_LIST_PAGE_SIZE,
    deleteConfirmationOpen: false,
  });
  const enablePackageConfig = isAccountConfigEnabled(CONFIG_CODES.ENABLE_JOURNEY_PACKAGE)
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.columnKey) {
    }
  };
  const fetchProducts = async () => {
    setProductTableState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const productResponse = await getProducts(props.searchText, enablePackageConfig)
    .catch(
      (error) => {
        setProductTableState((prev) => {
          return {
            ...prev,
            isLoading: false,
            isNoDataFound: true,
          };
        });
      }
    );
    if (
      productResponse?.data?.length > 0 ||
      productResponse?.data?.data?.length > 0
    ) {
      const productList =
        productResponse?.data?.length > 0
          ? productResponse
          : productResponse?.data?.data?.length > 0
          ? productResponse?.data
          : [];

      const tempProductDisplayList = formateStripeProductListData(
        productList,
        props?.isOneTime
      );
      setProductTableState((prev) => {
        return {
          ...prev,
          productDisplayList: [...tempProductDisplayList],
          isLoading: false,
        };
      });
    } else {
      setProductTableState((prev) => {
        return {
          ...prev,
          productDisplayList: [],
          isLoading: false,
        };
      });
    }
  };
  const onArchivedUnarchive = (productData: IProductTableDisplayData) => {
    const productFormData = {
      name: productData.name,
      description: productData.description,
      active: !productData.active,
      prices: productData.rowData.prices,
      category: 'membership',
      id: productData.id,
    };
    addOrUpdateMembership(
      {
        product: productFormData,
      },
      (responseMsg: any) => {
        fetchProducts();
        navigate('/admin/membership/products');
      },
      () => {
        // showToastMessage('productDetailFormError', true);
      },
      productData.id
    );
  };
  const onActionPerformed = (
    actionCode: string,
    rowData: IProductTableDisplayData,
    extraData?: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.SEND_MEMBERSHIP_LINK:
        const priceId = rowData.prices[extraData]?.priceId;
        setShowContactDrawer({id: rowData?.id, type: priceId});
        break;
      case COMMON_ACTION_CODES.EDIT:
        if (rowData?.rowData?.metadata?.category === 'oneTime') {
          navigate(`/admin/membership/products/${rowData.id}`, {
            state: {
              isOneTime: true,
            },
          });
        } else {
          navigate(`/admin/membership/products/${rowData.id}`);
        }

        break;
      case COMMON_ACTION_CODES.ARCHIVED:
        onArchivedUnarchive(rowData);
        break;
      case COMMON_ACTION_CODES.DELETE:
        // onDeleteProduct(rowData);
        setProductTableState((prev) => ({
          ...prev,
          deleteConfirmationOpen: true,
          selectedProductForDelete: rowData,
        }));
        break;
      case COMMON_ACTION_CODES.STATUS_CHANGED:
        break;
    }
  };
  const onRowClicked = (rowData: any) => {
    navigate(`/admin/membership/products/${rowData.id}`);
  };

  useEffect(() => {
    if(props?.isOneTime){
      fetchProducts();
    }
  }, [props?.isOneTime]);

  useEffect(() => {
    if (props.searchText.trim().length > 2 || !props.searchText.trim().length) {
      fetchProducts();
    }
  }, [props.searchText]);
  const {height} = Dimensions.get('window');
  const finalHeight = height - 256;
  const [stateData, setStateData] = useState({
    orderType: 'subscription',
  });
  const orderType = [
    {name: 'Subscription', code: 'subscription'},
    {name: 'One Time', code: 'oneTime'},
  ];
  return (
    <>
      {/* <View>
        <View
          style={{
            height: 40,
            borderColor: Colors.Custom.Gray200,
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: 12,
            paddingHorizontal: 4,
            paddingVertical: 8,
          }}
        >
          {orderType.map((type) => {
            return (
              <Pressable
                key={type.code}
                onPress={() => {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      orderType: type?.code,
                    };
                  });
                  props?.onActionPerformed(type?.code);
                }}
              >
                <View
                  style={[
                    styles.swtichBtnStyle,
                    stateData?.orderType === type?.code
                      ? {backgroundColor: Colors.primary['300']}
                      : {},
                  ]}
                >
                  <Text
                    size={'xsBold'}
                    color={
                      stateData?.orderType === type?.code
                        ? '#fff'
                        : Colors.Custom.Gray900
                    }
                  >
                    {type.name}
                  </Text>
                </View>
              </Pressable>
            );
          })}
        </View>
      </View> */}
      <PageBodyContainer style={{marginHorizontal: 0}}>
        {showContactDrawer ? (
          <ContactSearchOnlyDrawer
            onFormCompleteAction={(actionCode: string) => {
              if (actionCode == COMMON_ACTION_CODES.CANCEL) {
                setShowContactDrawer(false);
              }
            }}
            productInfo={showContactDrawer}
            getMembershipLink={true}
          />
        ) : null}
        {productTableState.productList && !productTableState.isLoading && (
          <TableWrapper
            pagination={{
              current: productTableState.page,
              pageSize: productTableState.pageSize,
              // total: productTableState.productMeta.total_count,
              onChange: (currentPage, currentPageSize) => {
                setProductTableState((prev) => {
                  return {
                    ...prev,
                    page: currentPage,
                    pageSize: currentPageSize,
                  };
                });
              },
            }}
          >
            <Table
              scroll={{x: 700, y: finalHeight}}
              columns={getMembershipsTableColumns(onActionPerformed)}
              rowKey={(row) => row.id}
              dataSource={productTableState.productDisplayList}
              rowClassName={(record, index) =>
                index % 2 == 0 ? 'table-row-light' : ''
              }
              onRow={(productData: IProductTableDisplayData) => {
                return {
                  onClick: () => {
                    onRowClicked(productData);
                  },
                };
              }}
              onChange={onTableChange}
              pagination={false}
            />
          </TableWrapper>
        )}
        {productTableState.isLoading && (
          <View
            style={style.loadingView}
            {...testID(TestIdentifiers.pageLoading)}
          >
            <Skeleton.Text lines={4} margin={4} />
          </View>
        )}
      </PageBodyContainer>
    </>
  );
};
export default MembershipTable;
