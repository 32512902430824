import React, {useState} from 'react';
import {View, IconButton, Icon} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../styles';
import DeleteTaskPoolModal from '../TaskManagementModal/DeleteTaskPoolModal';
import {TASK_ACTIONS_TYPE} from '../TaskInterfaces/TaskInterface';

interface ITagTableAction {
  record: any;
  onBottomViewAction: (task: any, action: any) => void;
}
const TaskPoolActions = (props: ITagTableAction) => {
  const {record} = props;

  // states for deleting a tag
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // function to delete a tag
  const handleDeleteTaskPool = () => {
    props.onBottomViewAction(record, TASK_ACTIONS_TYPE.DELETE);
  };

  return (
    <View flex={1} flexDirection={'row'}>
      <IconButton
        colorScheme="gray"
        variant="ghost"
        onPress={() => {
          props.onBottomViewAction(record, TASK_ACTIONS_TYPE.EDIT);
        }}
        icon={
          <Icon
            as={AntIcon}
            name="edit"
            size="4"
            color={Colors.Custom.Gray600}
          />
        }
      />
      <IconButton
        colorScheme="gray"
        variant="ghost"
        onPress={() => {
          setOpenDeleteModal(true);
        }}
        icon={
          <Icon
            as={AntIcon}
            name="delete"
            size="4"
            color={Colors.Custom.Gray600}
          />
        }
      />

      <DeleteTaskPoolModal
        isDeleteModalOpen={openDeleteModal}
        onDeleteModalClose={() => {
          setOpenDeleteModal(false);
          handleDeleteTaskPool();
        }}
        title={record.name}
        onCancel={() => setOpenDeleteModal(false)}
      />
    </View>
  );
};

export default TaskPoolActions;
