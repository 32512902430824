import {View, Text, StyleSheet} from 'react-native';
import React, {useState} from 'react';
import {IDeleteCommunicationTypesModalProps} from './interfaces';
import {Button, Modal, useSafeArea} from 'native-base';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {BUTTON_TYPE} from '../../../../../constants';
import {deleteCommunicationType} from './CommunicationTypesUtils';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../../styles';

const DeleteCommunicationTypesModal = (
  props: IDeleteCommunicationTypesModalProps
) => {
  const {data, isOpen, onClose, onConfirm} = props;
  const [state, setState] = React.useState({
    submitting: false,
  });
  const intl = useIntl();
  const onSubmit = async () => {
    setState((prev) => {
      return {
        ...prev,
        submitting: true,
      };
    });
    try {
      const response = await deleteCommunicationType(data.id || '');
      setState((prev) => {
        return {
          ...prev,
          submitting: false,
        };
      });
      onConfirm();
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          submitting: false,
        };
      });
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content
        style={styles.modalContent}
      >
        <Modal.CloseButton />
        <Modal.Header>
          <DisplayText
            textLocalId="deleteCommunicationType"
            textType="Heading"
          />
        </Modal.Header>

        <Modal.Body>
          <Text>Are you sure you want to delete this communication type?</Text>
        </Modal.Body>

        <Modal.Footer
          style={styles.modalFooter}
        >
          <Button.Group>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'close',
                }),
              }}
            />
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                isLoading: state.submitting,
                onPress: () => {
                  onSubmit();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'confirm',
                }),
                withRightBorder: false,
              }}
            />
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContent: {
    backgroundColor: Colors.Custom.BackgroundColor,
  },
  modalFooter: {
    backgroundColor: Colors.Custom.BackgroundColor,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export default DeleteCommunicationTypesModal;
