import { Column, ColumnConfig, Line, LineConfig } from '@ant-design/charts';
import { useLazyQuery } from '@apollo/client';
import { Skeleton, View } from 'native-base';
import { useEffect, useState } from 'react';
import ContactsQueries from '../../../../services/Contacts/ContactsQueries';
import { GraphColors } from '../../../../styles/Colors';
import { getAccountId } from '../../../../utils/commonUtils';
import { GraphWrapper } from '../GraphWrapper';
import { EngagementGraphData } from './interfaces';
import { getDateMaps, getGraphData } from './PatientEngagementGraphUtils';

const PatientEngagementGraph = () => {
  const dateMaps = getDateMaps();
  const accountId = getAccountId();
  const [getCreatedContactByDate] = useLazyQuery(
    ContactsQueries.GET_CREATED_CONTACT_BY_DATE
  );
  const [getCreatedConversationsByDate] = useLazyQuery(
    ContactsQueries.GET_CREATED_CONVERSATIONS_BY_DATE
  );

  const [state, setState] = useState<{
    data: any;
    graphData: EngagementGraphData[];
    loading: boolean;
  }>({
    data: {},
    graphData: [],
    loading: false,
  });

  const getContactsByDate = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const variables = {
        firstFromDate: dateMaps.first.fromDate,
        secondFromDate: dateMaps.second.fromDate,
        thirdFromDate: dateMaps.third.fromDate,
        fourthFromDate: dateMaps.fourth.fromDate,
        fifthFromDate: dateMaps.fifth.fromDate,
        sixthFromDate: dateMaps.sixth.fromDate,
        firstToDate: dateMaps.first.toDate,
        secondToDate: dateMaps.second.toDate,
        thirdToDate: dateMaps.third.toDate,
        fourthToDate: dateMaps.fourth.toDate,
        fifthToDate: dateMaps.fifth.toDate,
        sixthToDate: dateMaps.sixth.toDate,
        accountId: accountId,
      };
      const conversationResponse = await getCreatedConversationsByDate({
        variables: variables,
      });
      const contactResponse = await getCreatedContactByDate({
        variables: {
          firstFromDate: dateMaps.first.fromDate,
          secondFromDate: dateMaps.second.fromDate,
          thirdFromDate: dateMaps.third.fromDate,
          fourthFromDate: dateMaps.fourth.fromDate,
          fifthFromDate: dateMaps.fifth.fromDate,
          sixthFromDate: dateMaps.sixth.fromDate,
          firstToDate: dateMaps.first.toDate,
          secondToDate: dateMaps.second.toDate,
          thirdToDate: dateMaps.third.toDate,
          fourthToDate: dateMaps.fourth.toDate,
          fifthToDate: dateMaps.fifth.toDate,
          sixthToDate: dateMaps.sixth.toDate,
          accountId: accountId,
        },
      });
      const contactGraphData =
        getGraphData({
          response: contactResponse,
          dateMaps,
          type: 'Members',
        }) || [];
      const conversationGraphData =
        getGraphData({
          response: conversationResponse,
          dateMaps: dateMaps,
          type: 'Engagement',
        }) || [];
      const graphData = [...contactGraphData, ...conversationGraphData];
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          graphData: graphData,
          data: contactResponse.data,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });

    }
  };

  useEffect(() => {
    getContactsByDate();
  }, []);

  const config: ColumnConfig = {
    height: 200,
    // isStack: true,
    data: state.graphData,
    xField: 'fromDate',
    yField: 'count',
    seriesField: 'type',
    color: GraphColors,
    legend: {
      position: 'bottom',
      itemName: {
        formatter(text, item, index) {
          if (text == 'conversation') {
            return 'Engagement'.toUpperCase();
          }
          return (text || '');
        },
      },
    },
    label: {
      position: 'middle',
    },
    columnStyle(datum) {
      return {
        radius: [20, 20, 0, 0],
      };
      // if (datum.type == 'conversation') {
      //   return {
      //     radius: [20, 20, 0, 0],
      //   };
      // } else {
      //   return {
      //     radius: [20, 20, 0, 0],
      //   };
      // }
    },
    xAxis: {
      title: {
        text: 'Months',
      },
      label: {
        formatter(text, item, index) {
          const dateString = new Date(text).toLocaleString('default', {
            month: 'short',
          });
          return dateString;
        },
      },
    },
    yAxis: {
      title: {
        text: '% Member Engagement',
        autoRotate: true,
      },
    },
  };
  const lineConfig: LineConfig = {
    height: 200,
    data: state.graphData,
    xField: 'fromDate',
    yField: 'count',
    seriesField: 'type',
    smooth: true,
    // xAxis: {
    //   type: 'time',
    // },
    // yAxis: {
    //   label: {
    //     // 数值格式化为千分位
    //     formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
    //   },
    // },
    xAxis: {
      title: {
        text: 'Months',
      },
      label: {
        formatter(text, item, index) {
          const dateString = new Date(text).toLocaleString('default', {
            month: 'short',
          });
          return dateString;
        },
      },
    },
    yAxis: {
      title: {
        text: 'Member Engagement',
        autoRotate: true,
      },
    },
    color: GraphColors,
  };

  return (
    <GraphWrapper
      title="Member Engagement Graph"
      subtitle="Number of members added and engaged each month"
    >
      <View
        style={{
          overflow: 'hidden',
          flex: 1,
          width: '100%',
        }}
      >
        {state.loading ? <Skeleton.Text lines={4} /> :
        <Line {...lineConfig} />
        // <Column {...config} />
        }
      </View>
    </GraphWrapper>
  );
};

export default PatientEngagementGraph;
