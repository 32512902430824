import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles/Colors';
import {isWeb} from '../../../../../../utils/platformCheckUtils';

export const searchMsgThreadStyle = StyleSheet.create({
  webMsgThreadContainer: {
    paddingHorizontal: 16,
    marginTop: 16,
    borderBottomWidth: 1,
    paddingBottom: 16,
    borderBottomColor: Colors.Custom.Gray200,
  },
  appMsgThreadContainer: {
    height: 70,
    paddingHorizontal: 8,
    marginTop: 12,
    borderBottomWidth: 1,
    paddingBottom: 16,
    borderBottomColor: Colors.Custom.Gray200,
  },
  messageThreadListStack: {
    marginBottom: 16,
    flex: 1,
  },
  clearText: {
    color: Colors.Custom.mainPrimaryPurple,
    fontSize: 14,
    fontWeight: '500',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  clearTextContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  searchInputContainer: {
    flex: 1,
    borderWidth: 1,
    borderRadius: isWeb() ? 6 : 24,
    borderColor: Colors.Custom.Gray200,
    backgroundColor: Colors.Custom.ContainerBGColor,
  },
  closeIconContainer: {
    flex: 0.06,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  searchInThreadText: {
    fontSize: 16,
    color: Colors.Custom.Gray900,
    fontWeight: '700',
  },
  searchInThreadContainer: {
    flex: 0.94,
  },
  titleSubContainer: {
    flex: 1,
    paddingBottom: 8,
  },
  titleContainer: {
    flex: 1,
  },
  flex1: {
    flex: 1,
  },
  inputContainer: {
    flex: 0.95,
  },
});
