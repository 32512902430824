import React from 'react';
import {ICommonSvgProps} from './interfaces';

const CustomAttributeEmptySvg = (props: ICommonSvgProps) => {
  return (
    <svg width="202" height="202" viewBox="0 0 202 202" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="101" cy="101" r="100" stroke="url(#paint0_radial_785_10409)" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2.92 2.92" />
      <circle cx="101" cy="101" r="85.3334" stroke="url(#paint1_radial_785_10409)" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2.92 2.92" />
      <path d="M30.7085 101.203C30.7085 62.5432 62.0486 31.2031 100.709 31.2031V31.2031C139.368 31.2031 170.709 62.5432 170.709 101.203V101.203C170.709 139.863 139.368 171.203 100.709 171.203V171.203C62.0486 171.203 30.7085 139.863 30.7085 101.203V101.203Z" fill="url(#paint2_linear_785_10409)" />
      <path d="M131.75 101.5C131.75 107.68 129.917 113.722 126.483 118.861C123.049 124 118.169 128.006 112.458 130.371C106.748 132.736 100.465 133.355 94.403 132.149C88.341 130.943 82.7728 127.967 78.4024 123.597C74.032 119.226 71.0558 113.658 69.85 107.596C68.6442 101.534 69.2631 95.2508 71.6283 89.5407C73.9935 83.8305 77.9989 78.9499 83.138 75.5161C88.277 72.0823 94.3189 70.2495 100.5 70.2495M109.875 101.5H100.5M100.5 101.5H91.1245M100.5 101.5V92.1245M100.5 101.5V110.875M108.312 71.2337C119.293 74.0601 127.939 82.7054 130.765 93.6867" stroke="#A7AFBE" stroke-width="4" stroke-linecap="round" />
      <defs>
        <radialGradient id="paint0_radial_785_10409" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(101 146.216) rotate(-90) scale(145.097)">
          <stop stop-color="#E4E7EC" />
          <stop offset="1" stop-color="#E4E7EC" stop-opacity="0.13" />
        </radialGradient>
        <radialGradient id="paint1_radial_785_10409" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(101 139.585) rotate(-90.639) scale(133.917)">
          <stop stop-color="#E4E7EC" />
          <stop offset="1" stop-color="#E4E7EC" stop-opacity="0.35" />
        </radialGradient>
        <linearGradient id="paint2_linear_785_10409" x1="51" y1="47" x2="147" y2="168.5" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F9FAFC" />
          <stop offset="1" stop-color="#F1F2F3" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CustomAttributeEmptySvg;
