import BaseService from "../CommonService/BaseService";

export const updateMarketCostForSelectEmployer = (empReqData: {
  bodyParams: {
    payerId: string;
    employerId: string;
  }
}) => {
  const axiosService = BaseService.getSharedInstance().axios;

  return axiosService.post(`crm-nest/market-cost`,
    empReqData.bodyParams,
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  )
}
