import {Drawer, TreeSelect} from 'antd';
import {AxiosResponse} from 'axios';
import {Button, FormControl, Input, Spinner, View} from 'native-base';
import React, {useState} from 'react';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import CommerceCommonService from '../../../../../../../services/CommonService/CommerceCommonService';
import {Colors} from '../../../../../../../styles';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../../../common/ModalActionBtn/ModalActionBtn';
import {styles} from './AddNewTaxonStyles';

const AddNewTaxon = (props: any) => {
  // const {TreeNode} = TreeSelect;
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [bodyData, setBodyData] = useState({
    taxonomy_id: '',
    parent_id: '',
    name: '',
  } as any);
  const [error, setError] = useState({
    name: '',
    category: '',
  } as any);
  const closeModal = () => {
    setShowModal(false);
    props.onActionPerformed(COMMON_ACTION_CODES.RESET);
  };

  const onAddTaxon = () => {
    if (!bodyData.parent_id || !bodyData.name) {
      if (!bodyData.parent_id) {
        setError({...error, category: 'Please select Category'});
      }
      if (!bodyData.name) {
        setError({...error, name: 'Please Enter Taxon Name'});
      }
      return;
    }
    setError({...error, name: '', category: ''});
    // setBodyData({...bodyData, taxonomy_id: props.taxonomy.id});
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    const commerceCommonService =
      CommerceCommonService.getCommerceCommonServiceInstance();
    const axiosService = commerceCommonService.axiosService;
    axiosService
      .post(`/commerce/api/v2/platform/taxons`, bodyData)
      .then((res: AxiosResponse<any>) => {
        if (res && res?.data) {
          setLoading(false);
          props.onActionPerformed(COMMON_ACTION_CODES.ALL);
          setShowModal(false);
        } else {
          // closeModal();
          setLoading(false);
          setError({...error, name: 'Unable to add taxon.'});
        }
      })
      .catch((err: any) => {

        setError({...error, name: 'Unable to add Taxon.'});
        setLoading(false);
      });
  };

  const {width} = Dimensions.get('window');
  const finalWidth = width / 3;
  return (
    <>
      <Drawer
        visible={showModal}
        width={finalWidth}
        onClose={() => closeModal()}
        title={<DisplayText textType={'Heading'} textLocalId={'addtaxon'} />}
      >
        {loading ? <Spinner size="lg" style={styles.spinnerStyle} /> : <></>}
        <View>
          <View
            justifyContent={'flex-start'}
            alignItems={'center'}
            flex={1}
            flexDirection={'row'}
          >
            <FormControl
              style={styles.formElement}
              isRequired
              isInvalid={!!error.name}
            >
              <FormControl.Label style={styles.formLabel}>
                <DisplayText
                  size={'smMedium'}
                  extraStyles={{color: Colors.Custom.Gray700}}
                  textLocalId={'name'}
                />
              </FormControl.Label>
              <Input
                placeholder="Taxon name"
                onChangeText={(value) => {
                  setBodyData({...bodyData, name: value});
                  setError({...error, name: ''});
                }}
              />
              {error.name && (
                <FormControl.ErrorMessage
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                >
                  {error.name}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
          </View>
          <View>
            <FormControl
              style={styles.formElement}
              isRequired
              isInvalid={!!error.category}
            >
              <FormControl.Label style={styles.formLabel}>
                <DisplayText
                  size={'smMedium'}
                  extraStyles={{color: Colors.Custom.Gray700}}
                  textLocalId={'selectCategory'}
                />
              </FormControl.Label>
              <View>
                <TreeSelect
                  showSearch
                  style={{width: '100%'}}
                  value={bodyData.parent_id}
                  treeData={props.catagoryList}
                  dropdownStyle={{maxHeight: 200}}
                  placeholder="Please select"
                  allowClear
                  treeDefaultExpandAll
                  onChange={(value: any) => {
                    setBodyData({...bodyData, parent_id: value});
                  }}
                />
              </View>
              {error.category && (
                <FormControl.ErrorMessage
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                >
                  {error.category}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
          </View>
        </View>
        <Button.Group
          space={2}
          justifyContent={'flex-end'}
          width={'100%'}
          style={{marginTop: 20}}
        >
          <ModalActionBtn
            btnText="cancel"
            btnStype={BUTTON_TYPE.SECONDARY}
            onClickAction={() => {
              closeModal();
            }}
            customStyle={{marginRight: 20}}
          />
          <ModalActionBtn
            btnText="add"
            btnStype={BUTTON_TYPE.PRIMARY}
            onClickAction={() => {
              onAddTaxon();
            }}
          />
        </Button.Group>
      </Drawer>
    </>
  );
};

export default AddNewTaxon;
