import React, {useContext, useState} from 'react';
import Stack from '../../common/LayoutComponents/Stack';
import {Pressable, ScrollView, View} from 'react-native';
import {Text} from 'native-base';
import {Divider, Popover} from 'antd';
import {
  CARE_MANAGEMENT_ACTION_LIST,
  CARE_MANAGEMENT_PROGRAM_LIST,
} from '../CareManagementBilling/CareManagementConstants';
import {ICareManagementTab, ICareManagementTopBarView} from './interface';
import PlusSvg from '../../common/Svg/PlusSvg';
import {Colors} from '../../../styles/Colors';
import {enrollContactCareProgram} from './CareManagementUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {
  CONTACT_CARE_PROGRAM_SOURCE_CODES,
  MLOV_CATEGORY,
} from '../../../constants/MlovConst';
import {currentYear} from '../../../utils/DateUtils';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {useCustomToast} from '../../Toast/ToastProvider';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {antStyles, styles} from './CareManageMentViewStyles';
import FilterNewIconSvg from '../../common/Svg/FilterNewIconSvg';

const CareManagementTopBarView = (props: ICareManagementTopBarView) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const commonContextData = useContext(CommonDataContext);
  const navigate = useNavigate();
  const intl = useIntl();
  const customToast = useCustomToast();
  const careProgramTypesList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_TYPES
    ) || [];

  const contactCareProgramSourceList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CONTACT_CARE_PROGRAM_SOURCE
    ) || [];

  const enrollCareProgram = async (tabCode: string) => {
    try {
      props?.onCareProgramEnrollmentLoading(true);
      const careProgramTypeId = getMlovIdFromCode(
        careProgramTypesList,
        tabCode.toUpperCase()
      );
      setPopoverVisible(false);
      const contactCareProgramSourceId = getMlovIdFromCode(
        contactCareProgramSourceList,
        CONTACT_CARE_PROGRAM_SOURCE_CODES.FILE_IMPORT
      );
      const response = await enrollContactCareProgram({
        careProgramTypeId,
        contactId: props?.contactUuid,
        eligibilityDateTime: new Date(),
        membershipYear: currentYear(),
        sourceId: contactCareProgramSourceId,
      });
      props?.onCareProgramEnrollmentLoading(false);
      showToast(
        customToast,
        intl.formatMessage({id: 'careProgramSucess'}),
        ToastType.success,
        1000,
        true
      );
      if (response?.id) {
        navigate(
          `/members/patient/${props?.contactId}/careManagement/${tabCode}/${response?.id}`
        );
      }
    } catch (e) {
      setPopoverVisible(false);
      showToast(
        customToast,
        intl.formatMessage({id: 'errorMsg'}),
        ToastType.error,
        1000,
        true
      );
    }
  };

  const handleCareManagementAction = (tabCode: string) => {
    enrollCareProgram(tabCode);
  };

  const getPopoverContent = () => {
    return (
      <View>
        {CARE_MANAGEMENT_ACTION_LIST?.map((item) => {
          return (
            <Pressable
              onPress={() => handleCareManagementAction(item?.tabCode)}
            >
              <Text style={styles.popoverItem}>{item?.tabTitle}</Text>
            </Pressable>
          );
        })}
      </View>
    );
  };

  return (
    <Stack direction="row" style={styles.topBarContainer}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        {CARE_MANAGEMENT_PROGRAM_LIST.map((item: ICareManagementTab) => (
          <Pressable
            key={item.tabCode}
            style={[
              styles.tab,
              props?.activeTabCode === item.tabCode && styles.selectedTab,
            ]}
            onPress={() => props?.onHeaderTabClick(item?.tabCode)}
          >
            <Text
              style={[
                styles.tabText,
                props?.activeTabCode === item.tabCode && styles.selectedTabText,
              ]}
            >
              {item.tabTitle}
            </Text>
          </Pressable>
        ))}
      </ScrollView>
      <Stack direction="row">
        <View style={styles.filterButton}>
          <Pressable onPress={() => props?.onFilterClick()}>
            <FilterNewIconSvg isEnabled={true} />
          </Pressable>
        </View>
        <Divider type="vertical" style={antStyles.dividerStyle} />
        <Popover
          overlayClassName="contact-popover"
          content={getPopoverContent()}
          trigger="click"
          placement="bottomRight"
          visible={popoverVisible}
          onVisibleChange={setPopoverVisible}
          align={{
            offset: [-5, -5],
          }}
          overlayInnerStyle={antStyles.popoverOverlayInnerStyle}
          showArrow={false}
        >
          <Pressable
            style={styles.addButton}
            onPress={() => setPopoverVisible(true)}
          >
            <PlusSvg
              height={16}
              width={16}
              defaultColor={Colors.Custom.Gray500}
            />
            <Text style={styles.addButtonText}>New</Text>
          </Pressable>
        </Popover>
      </Stack>
    </Stack>
  );
};

export default React.memo(CareManagementTopBarView);
