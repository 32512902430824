import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { ICommonSvgProps } from '../interfaces';
import { Colors } from '../../../../styles/Colors';

const AngleLeftSvg = (props: ICommonSvgProps) => {
    return (
        <Svg width={props.width || "16"} height={props.height || "16"} viewBox="0 0 16 16" fill="none">
            <Path d="M10 3.33398L6 8.00065L10 12.6673" stroke={props?.customStrokeColor || Colors.FoldPixel.GRAY300} stroke-linecap="round" stroke-linejoin="round"/>
        </Svg>
    )
}

export default React.memo(AngleLeftSvg);