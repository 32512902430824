import BaseService from "../../../../services/CommonService/BaseService";
import {CUSTOM_FIELD_PATH} from "./CustomFieldApiPath";
import {ICreateCustomFieldParams, ICreateUpdateCustomFieldsForEntityResponse, ICustomFieldAddUpdateFormattedData, ICustomFieldResponse, IEnableDisableCustomField, IGetContactTypesParams, IGetCustomFieldByEntityResponse, IGetCustomFieldResponse, IGetCustomFieldsForEntityParams} from "./interface";
import {AxiosResponse} from 'axios';

export const getCustomFieldData = async (params: IGetContactTypesParams): Promise<
  AxiosResponse<IGetCustomFieldResponse>
> => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.get(CUSTOM_FIELD_PATH.GET_CUSTOM_FIELD, {
    params: params
  })
  return response;
};

export const enableDisableCustomField = async (enableDisableCustomFiledPayload: IEnableDisableCustomField): Promise<
  AxiosResponse<ICustomFieldResponse>
> => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `${CUSTOM_FIELD_PATH.DISABLE_CUSTOM_FIELD}/${enableDisableCustomFiledPayload?.id}`;
  const response = await baseService.patch(url,
    enableDisableCustomFiledPayload?.body
  )
  return response;
};

export const createCustomField = async (createCustomFiledPayload: ICreateCustomFieldParams): Promise<
  AxiosResponse<ICustomFieldResponse>
> => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.post(CUSTOM_FIELD_PATH.CREATE_CUSTOM_FIELD, createCustomFiledPayload?.body)
  return response;
};

export const updateCustomField = async (updateCustomFiledPayload: ICreateCustomFieldParams): Promise<
  AxiosResponse<ICustomFieldResponse>
> => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.patch(CUSTOM_FIELD_PATH.GET_CUSTOM_FIELD + '/' + updateCustomFiledPayload?.id, updateCustomFiledPayload?.body)
  return response;
};

export const getCustomFieldsForEntity = async (params: IGetCustomFieldsForEntityParams): Promise<
  AxiosResponse<IGetCustomFieldByEntityResponse[]>
> => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.get(CUSTOM_FIELD_PATH.GET_CUSTOM_FIELDS_FOR_ENTITY, {
    params: params
  })
  return response;
};

export const createUpdateCustomFieldsForEntity = async (enableDisableCustomFiledBody: ICustomFieldAddUpdateFormattedData): Promise<
  AxiosResponse<ICreateUpdateCustomFieldsForEntityResponse[]>
> => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.post(CUSTOM_FIELD_PATH.CREATE_CUSTOM_FIELD_VALUE, enableDisableCustomFiledBody)
  return response;
};

export const deleteCustomAttributeValue = async (enableDisableCustomFiledPayload: any): Promise<
  AxiosResponse<any>
> => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `${CUSTOM_FIELD_PATH.CREATE_CUSTOM_FIELD_VALUE}/${enableDisableCustomFiledPayload?.id}`;
  const response = await baseService.delete(url);
  return response;
};

export const getCustomFieldValueById = async (params: {searchQuery: string}, selectedCustomAttributeId: string): Promise<
  AxiosResponse<string[]>
> => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.get(CUSTOM_FIELD_PATH.GET_CUSTOM_ATTRIBUTE_VALUE + '/' + selectedCustomAttributeId, {
    params: params
  })
  return response;
};