import {Colors} from '../../../styles/Colors';
import {ICommonSvgProps} from './interfaces';

const HealthMapSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : props.customStrokeColor
    ? props.customStrokeColor
    : props.defaultColor || '#fff';

  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 8.68903C2.91216 9.35509 1 11.7042 1 14.5C1 17.8137 3.68629 20.5 7 20.5C10.3137 20.5 13 17.8137 13 14.5C13 13.7453 12.8607 13.0231 12.6063 12.3579M11 18.9722C12.0615 19.9223 13.4633 20.5 15 20.5C18.3137 20.5 21 17.8137 21 14.5C21 11.7042 19.0878 9.35509 16.5 8.68903M17 6.5C17 9.81371 14.3137 12.5 11 12.5C7.68629 12.5 5 9.81371 5 6.5C5 3.18629 7.68629 0.5 11 0.5C14.3137 0.5 17 3.18629 17 6.5Z"
        stroke="url(#paint0_linear_671_3)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_671_3"
          x1="11"
          y1="-15.2"
          x2="-12.8348"
          y2="-0.151693"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1E9DAE" />
          <stop offset="1" stop-color="#D478FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HealthMapSvg;
