import {View, VStack} from 'native-base';
import {Colors} from '../../../../styles';
import {IResponseProps} from './interfaces';
import {ResponseView} from './ResponseView/ResponseView';

const Responses = (props: IResponseProps) => {
  return (
    <VStack>
      <View
        bgColor={'white'}
      >
        <ResponseView
          isClinicalResponse={props.isClinicalResponse}
          currentModal={props.currentModal}
          modalVisible={props.modalVisible}
          showTableTopBar={props.showTableTopBar}
          searchString={props.searchString}
          onChangeViewCallBack={props.onChangeViewCallBack}
        />
      </View>
    </VStack>
  );
};

export default Responses;
