import { Drawer } from 'antd';
import {
  Checkbox,
  HStack,
  Pressable,
  Skeleton,
  Text,
  useMediaQuery,
  View,
  VStack
} from 'native-base';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import {
  BUTTON_TYPE,
  GROUP_MEMBER_TYPE,
  IPAD_WIDTH
} from '../../../../constants';
import { Colors } from '../../../../styles';
import { findIsAllowToRunAutomation, getAccountUUID } from '../../../../utils/commonUtils';
import { DisplayCardAvatar } from '../../../common/DisplayCard/DisplayCardAvatar';
import FilterNewIconSvg from '../../../common/Svg/FilterNewIconSvg';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import AddOrUpdateCampaign from '../../ContentManagement/CampaignManagement/AddOrUpdateCampaign/AddOrUpdateCampaign';
import {
  createCampaignSegment,
  getMemberListBySegmentId,
  getServiceLogs
} from '../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { AutomationWorkflowEmployee } from '../AutomationWorkflowEmployee';
import { getDifference, getServiceLogsQueryObject } from '../Helper';
import { GroupMemberListDrawerProps } from '../interfaces';
import { GroupMemberListFilter } from './GroupMemberListFilter';

const GroupMemberListDrawer = (props: GroupMemberListDrawerProps) => {
  const {groupData, isOpen, onClose, contactData} = props;
  const intl = useIntl()
  const [state, setState] = useState({
    isFilterOpen: false,
    memberList: [] as any[],
    total: 0,
    loading: false,
    error: false,
    automationOpen: false,
    campaign: {} as any,
    workflow: {} as any,
    query: {} as any,
  });

  const [campaignDrawerState, setCampaignDrawerState] = useState<any>({
    isVisible: false,
    segmentId: null,
  });

  const accountUuid = getAccountUUID();
  const callServiceLogsApi =
    Object.keys(state.workflow || {}).length > 0 ||
    Object.keys(state.campaign || {}).length > 0;

  const [isIPadScreen] = useMediaQuery({maxWidth: IPAD_WIDTH});

  const getMemberList = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const response = await getMemberListBySegmentId({
        segmentId: groupData?.id || '',
        limit: 1000,
      });
      let memberList = response?.data?.contacts || [];
      if (callServiceLogsApi) {
        const serviceResponse = await getServiceLogs(state.query);
        const newList = getDifference({
          serviceLogs: serviceResponse.serviceLogs,
          memberList: memberList,
        });
        memberList = newList;
      }
      const total = response?.data?.contactAggregate?.aggregate?.count || 0;
      setState((prev) => {
        return {
          ...prev,
          memberList: memberList,
          loading: false,
          error: false,
          total: total,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          error: true,
          memberList: [],
        };
      });


    }
  };

  const onChangeSelection = (item: any, isSelected: boolean) => {
    setState((prev) => {
      const memberList = prev.memberList || [];
      const newMemberList: any = [];
      memberList.forEach((item2: any) => {
        const newItem = {...item2};
        if (item.id === newItem.id) {
          newItem.isSelected = isSelected;
        }
        newMemberList.push({...newItem});
      });
      return {
        ...prev,
        memberList: [...newMemberList],
      };
    });
  };

  useEffect(() => {
    if (groupData?.id) {
      getMemberList();
    }
  }, []);

  useEffect(() => {
    if (contactData?.length) {
      setState((prev) => {
        return {
          ...prev,
          memberList: contactData,
          loading: false,
          error: false,
          total: contactData.length,
        };
      });
    }
  }, [contactData]);

  useEffect(() => {
    if (groupData?.id) {
      getMemberList();
    }
  }, [state.query]);

  const showFilter = state.isFilterOpen;
  const showButtonList =
    state.memberList.filter((item) => item?.isSelected).length > 0;

  const buttonList = [
    {
      show: findIsAllowToRunAutomation(),
      id: 2,
      btnText: 'runAutomation',
      textColor: Colors.Custom.mainPrimaryPurple,
      btnStype: BUTTON_TYPE.PRIMARY,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      onClick: () => {
        setState((prev) => {
          return {
            ...prev,
            automationOpen: true,
          };
        });
      },
    },
    {
      show: true,
      id: 3,
      btnText: 'Create Campaign',
      textColor: Colors.Custom.mainPrimaryPurple,
      btnStype: BUTTON_TYPE.PRIMARY,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      onClick: () => {
        const selectedContacts = state.memberList.filter(
          (item) => item?.isSelected
        );
        createCampaignSegment({
          name: '',
          tenantId: accountUuid,
          metadata: {
            patientFilter: {
              contactIds: selectedContacts.map((contact: any) => {
                return contact.id;
              }),
            },
          },
        }).then((response) => {
          if (response?.id) {
            setCampaignDrawerState({
              isVisible: true,
              segmentId: response?.id,
            });
          }
        });
      },
    },
  ];

  const getFilterCount = () => {
    let count = 0;
    if (Object.keys(state.campaign || {}).length > 0) {
      count += 1;
    }
    if (Object.keys(state.workflow || {}).length > 0) {
      count += 1;
    }
    return count;
  };

  return (
    <Drawer
      width={isIPadScreen ? '70vw' : '50vw'}
      visible={isOpen}
      onClose={onClose}
      title={
        <HStack justifyContent={'space-between'} alignItems="center">
          <TitleSubtitleView
            containerStyle={{marginLeft: 0, marginBottom: 5}}
            isHideCommonButton
            title="Group Members"
          />

          <HStack alignItems={'center'}>
            <HStack style={{marginHorizontal: 8}} space={'2'}>
              {showButtonList &&
                buttonList.map((item) => {
                  return (
                    <FoldButton
                      key={item.id}
                      nativeProps={{
                        variant: item.btnStype,
                        onPress: () => {
                          item.onClick();
                        },
                        style: {marginRight: 20},

                      }}
                      customProps={{
                        btnText: intl.formatMessage({id: item.btnText}),
                        withRightBorder: false,
                        hideButton: (item.show === false),
                      }}
                    ></FoldButton>
                  );
                })}
            </HStack>
            <VStack
              style={{
                width: 32,
                height: 32,
              }}
            >
              <Pressable
                onPress={() => {
                  setState((prev) => {
                    return {
                      ...prev,
                      isFilterOpen: true,
                    };
                  });
                }}
              >
               <FilterNewIconSvg
                isEnabled={true}
              />
                {/* {getFilterCount() > 0 && (
                  <Text style={{}}>{`${getFilterCount()}`}</Text>
                )} */}
              </Pressable>
            </VStack>
          </HStack>
        </HStack>
      }
    >
      {state.loading ? (
        <Skeleton.Text lines={4} />
      ) : (
        state.memberList?.map((contact: any) => {
          return (
            <VStack
              borderBottomColor={'gray.100'}
              borderBottomWidth={1}
              marginBottom={5}
              key={contact?.id}
            >
              <HStack alignItems={'center'} flex={1} marginBottom={1}>
                <View>
                  <Checkbox
                    key={contact.id}
                    defaultIsChecked={contact.isSelected}
                    onChange={(isSelected) => {
                      onChangeSelection(contact, isSelected);
                    }}
                    accessibilityLabel={contact.id}
                    value={'1'}
                    marginRight={5}
                  />
                </View>
                <DisplayCardAvatar
                  avatarStyle={{
                    avatarSize: '12',
                  }}
                  isLetterAvatarShow={true}
                  userData={{
                    userId: contact?.id,
                    userType: GROUP_MEMBER_TYPE.CONTACT,
                    imgSrc: contact?.profileImgSrc || '',
                    contactType: contact?.contactType?.contactType?.code || '',
                    genderCode: contact?.person?.gender?.code || 'other',
                  }}
                />
                <VStack style={{marginLeft: 12}}>
                  <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                    {(
                      (contact?.person?.firstName || '') +
                      ' ' +
                      (contact?.person?.lastName || '')
                    ).trim() ||
                      contact.name ||
                      ''}
                  </Text>
                  <Text size={'smNormal'} color={Colors.Custom.Gray500}>
                    {contact?.email}
                  </Text>
                </VStack>
              </HStack>
            </VStack>
          );
        })
      )}

      {showFilter && (
        <GroupMemberListFilter
          isOpen={showFilter}
          selectedCampaign={state.campaign}
          selectedWorkflow={state.workflow}
          onSubmit={(campaign, workflow) => {
            const query = getServiceLogsQueryObject(campaign, workflow);
            setState((prev) => {
              return {
                ...prev,
                isFilterOpen: false,
                campaign: campaign,
                workflow: workflow,
                query: query,
              };
            });
          }}
          onCompleteAction={() => {
            setState((prev) => {
              return {
                ...prev,
                isFilterOpen: false,
              };
            });
          }}
        />
      )}

      <Drawer
        visible={campaignDrawerState?.isVisible}
        width={Dimensions.get('window').width * 0.7}
        onClose={() => {
          setCampaignDrawerState({
            isVisible: false,
            segmentId: null,
          });
        }}
        headerStyle={{
          display: 'none',
        }}
      >
        {campaignDrawerState?.isVisible ? (
          <AddOrUpdateCampaign
            shouldShowEdit={true}
            initData={undefined}
            segmentId={campaignDrawerState?.segmentId}
            close={() => {
              setCampaignDrawerState({
                isVisible: false,
                segmentId: null,
              });
            }}
          />
        ) : null}
      </Drawer>

      {state.automationOpen && (
        <Drawer
          visible={state.automationOpen}
          width={700}
          onClose={() =>
            setState((prev) => {
              return {
                ...prev,
                automationOpen: false,
              };
            })
          }
          closable={true}
          title={<></>}
        >
          <AutomationWorkflowEmployee
            tenantId={accountUuid}
            cancelBtnTxt={'Back'}
            initData={{
              selectedItems: state.memberList.filter(
                (item) => item?.isSelected
              ),
            }}
            onModalClose={() => {
              setState((prev) => {
                return {
                  ...prev,
                  automationOpen: false,
                };
              });
            }}
          />
        </Drawer>
      )}
    </Drawer>
  );
};

export default GroupMemberListDrawer;
