import {VStack, View, Text, HStack, Radio} from 'native-base';
import {FC, useEffect, useState} from 'react';
import {styles} from './PopulationGroupFilterStyles';
import {Colors} from '../../../../../styles';
import {useIntl} from 'react-intl';
import {FilterDrawerContainer} from '../../../../common/FilterDrawerContainer/FilterDrawerContainer';
import {IPopulationGroupFilter} from '../interfaces';
import {PopulationGroupFilterTypes, LeadGroupFilterTypes, POPULATION_GROUP_ALL_FILTER_CODE} from '../constants';

interface IFilterView {
  onReset: () => void;
  onApply: (filterData: IPopulationGroupFilter, appliedFilters: string[]) => void;
  onClose: () => void;
  filterData?: IPopulationGroupFilter;
  isLeadGroup?: boolean
}

export const PopulationGroupFilter: FC<IFilterView> = ({
  onReset,
  onApply,
  onClose,
  filterData,
  isLeadGroup
}) => {
  const intl = useIntl();

  const [selectedFilter, setSelectedFilter] = useState<IPopulationGroupFilter>({
    type: '',
  });

  const filterTypes = isLeadGroup ? LeadGroupFilterTypes : PopulationGroupFilterTypes

  useEffect(()=> {
    if (filterData?.type) {
      setSelectedFilter({...filterData, type: filterData.type});
    }
    else{
      setSelectedFilter({type: POPULATION_GROUP_ALL_FILTER_CODE})
    }
  }, [filterData?.type])

  return (
    <FilterDrawerContainer
      onReset={onReset}
      onApply={() => {
        const appliedFilters: string[] = []
        if(selectedFilter && selectedFilter.type !== POPULATION_GROUP_ALL_FILTER_CODE){
          const selectedFilterLabel = filterTypes.find((filterType)=> filterType.code === selectedFilter.type)?.label
          appliedFilters.push('Type: ' + selectedFilterLabel);
          selectedFilter && onApply({...selectedFilter}, appliedFilters);
        }
        else{
          selectedFilter && onApply({type: ''}, appliedFilters);
        }
      }}
      onClose={onClose}
    >
      <VStack>
        <View style={[styles.filterTitle]}>
          <Text size={'lgSemibold'} color={'#000'}>
            {intl.formatMessage({id: 'filterByType'})}
          </Text>
        </View>

        <Radio.Group
          style={{paddingLeft: 16, marginTop: 24}}
          name="populationGroupType"
          value={selectedFilter?.type || ''}
          onChange={(value) => {
            setSelectedFilter((prev) => {
              return {
                ...prev,
                type: value,
              };
            });
          }}
        >
          <HStack space={6}>
            {filterTypes.map((type) => {
              return (
                <Radio
                  isHovered={false}
                  isFocusVisible={false}
                  isFocused={false}
                  key={type.code}
                  value={type.code}
                >
                  <Text
                    size={'smMedium'}
                    color={Colors.Custom.Gray700}
                    style={{marginLeft: 8}}
                  >
                    {intl.formatMessage({id: type.label})}
                  </Text>
                </Radio>
              );
            })}
          </HStack>
        </Radio.Group>
      </VStack>
    </FilterDrawerContainer>
  );
};
