import { IMlov } from "../../Interfaces";
import { DATE_FORMATS } from "../../constants";
import { TASK_DUE_DATE_CODES, TASK_PRIORITY_CODES, TASK_STATUS, TASK_STATUS_CODES } from "../../constants/MlovConst";
import { getCurrentTimeZone, getEndOfDay, getISODateFromDisplayDate, getMomentObj, getMomentObjFromDateStrAndFormat, getMomentObjFromFormat, getNextDateStartOfDay, getPreviousDateEndOfDay, getStartOfDay, tomorrowDateInDateStringFormat } from "../../utils/DateUtils";
import { getMlovCodeFromId, getMlovIdFromCode, getMlovObjectFromCode } from "../../utils/mlovUtils";
import { IBatchedAccountUsersInterface } from "../CustomHooks/useGetBatchedAccountUsers";
import { TaskPanelType } from "../TaskCard/TaskEnum";
import { ParticipantType } from "../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum";
import { TASK_TABS, TaskField } from "../common/CareDashboard/CareDashboardConstants";
import { ITask, IUpdatedTaskData } from "../common/CareDashboard/CareDashboardInterfaces";
import { TASK_ASSIGNEE_TYPE_CODES } from "../common/CareDashboard/CareDashboardTable/CareDashboardTableHelper";
import { ITopBarData } from "../common/CareDashboard/CareDashboardTopBar/CareDashboardTopBar";
import { BoardType } from "../common/CareDashboard/CareDashboardTopBar/interfaces";
import { getStartAndEndDateByTaskDueDateCategory } from "../common/CareDashboard/CareDashboardUtils/CareDashboardUtils";
import { IAllUserPoolSelect, IUserPool, IUserSearch } from "../common/CareDashboard/CareDashboardWidget/UserAutoComplete";
import { BulkLabelChangeAction, IBulkOperationData, IConflict, ITaskFilter } from "./TaskInterfaces";
import { ITabsList } from "../common/CustomTabsView/interfaces";
import {useRef, useState, useEffect} from "react";
import { isEqual } from "lodash";

export const TASK_DEFAULT_PAGE_SIZE = 25;
export const TASK_STATUS_FILTER_VALUES = {
  PENDING: 'Not Completed',
  COMPLETED: 'Completed',
  MISSED: 'Missed',
}

export enum BulkTaskOperationState {
  none, inProgress, error, success
}

export const TASK_CONFLICT_CODE = {
  SUBTASK_NOT_COMPLETED: 'SUBTASK_NOT_COMPLETED',
  VITALS_NOT_FILLED: 'VITALS_NOT_FILLED',
  FORM_NOT_SUBMITTED: 'FORM_NOT_SUBMITTED',
  FORM_ALREADY_SUBMITTED: 'FORM_ALREADY_SUBMITTED',
  SUBTASK_NOT_DELETED: 'SUBTASK_NOT_DELETED',
  SUBTASK_DUE_DATE: 'SUBTASK_DUE_DATE',
  ASSIGNEE_NOT_IN_POOL: 'ASSIGNEE_NOT_IN_POOL',
  NOTE_NOT_SIGNED: 'NOTE_NOT_SIGNED',
  CARE_PLAN_IN_REVIEW: 'CARE_PLAN_IN_REVIEW',
  JOURNEY_TASK_NOT_DELETE: 'JOURNEY_TASK_NOT_DELETE',
  ORDER_TASK_NOT_DELETE: 'ORDER_TASK_NOT_DELETE',
  TASK_ALREADY_DELETED: 'TASK_ALREADY_DELETED',
  FORM_TASK_NOT_DELETED: 'FORM_TASK_NOT_DELETED',
  SUBTASK_TASK_DELETE: 'SUBTASK_TASK_DELETE',
  VITAL_TASK_NOT_DELETED: 'VITAL_TASK_NOT_DELETED',
  TASK_ALREADY_COMPLETED: 'TASK_ALREADY_COMPLETED',
  TASK_START_DATE_GREATER_THAN_DUE_DATE: 'TASK_START_DATE_GREATER_THAN_DUE_DATE',
  SUB_TASK_NOT_ALLOWED_LABEL: 'SUB_TASK_NOT_ALLOWED_LABEL',
  SUB_TASK_NOT_ALLOWED_POOL:'SUB_TASK_NOT_ALLOWED_POOL',
  SUB_TASK_NOT_ALLOWED_COMMENT:'SUB_TASK_NOT_ALLOWED_COMMENT',
  SUB_TASK_NOT_ALLOWED_PRIORITY:'SUB_TASK_NOT_ALLOWED_PRIORITY',
  TASK_ASSIGNEE_LOCATION_MISMATCH:'TASK_ASSIGNEE_LOCATION_MISMATCH',
  TASK_AND_POOL_LOCATION_MISMATCH:'TASK_AND_POOL_LOCATION_MISMATCH',
}

export const CONFLICT_KEY = {
  ASSIGNEE_ID: 'assigneeId',
  POOL: 'pool',
  DUE_DATE: 'dueDate',
  COMMENT: 'comment',
  PRIORITY: 'priority',
  STATUS_ID: 'statusId',
  LABEL: 'label',
}

export const getCategoriesFromBoardType = (
  boardType?: BoardType,
  taskTab?: string,
  allPools?: IUserPool[],
): {code: string, name: string}[] => {
  if (!boardType) {
    if (taskTab === TASK_TABS.CREATED) {
      return [{code: TASK_TABS.CREATED, name: 'Created'}];
    } else if (taskTab === TASK_TABS.MENTIONED) {
      return [{code: TASK_TABS.MENTIONED, name: 'Mentioned'}];
    }
    return [];
  }

  switch (boardType) {
    case BoardType.Status:
      return [
        { code: TASK_STATUS_CODES.ACCEPTED, name: 'Pending' },
        { code: TASK_STATUS_CODES.MISSED, name: 'Missed' },
        { code: TASK_STATUS_CODES.COMPLETED, name: 'Completed' },
      ];

    case BoardType.Priority:
      return [
        { code: TASK_PRIORITY_CODES.HIGH, name: 'High' },
        { code: TASK_PRIORITY_CODES.MEDIUM, name: 'Medium' },
        { code: TASK_PRIORITY_CODES.LOW, name: 'Low' },
      ];

    case BoardType.dueDate:
      return [
        { code: TASK_DUE_DATE_CODES.PAST, name: 'Past' },
        { code: TASK_DUE_DATE_CODES.TODAY, name: 'Today' },
        { code: TASK_DUE_DATE_CODES.FUTURE, name: 'Future' },
      ];

    case BoardType.taskPool:
      return (allPools || []).map((item) => {
        return {
          code: item.id,
          name: item.name,
        }
      });
    default: return [];
  }
};

export const getTabTitle = (tabKey: string, isCurrentUserSelected: boolean) => {
  switch(tabKey) {
    case TASK_TABS.ASSIGNED: return isCurrentUserSelected ? 'Assigned To Me' : 'Assigned';
    case TASK_TABS.TASK_POOL: return isCurrentUserSelected ? 'My Task Pool' : 'Task Pool';
    case TASK_TABS.CREATED: return isCurrentUserSelected ? 'Created By Me' : 'Created';
    case TASK_TABS.MENTIONED: return 'Mentions';
    default: return '';
  }
}

export const getLabelForAssigneeField = (selectedTab: string) => {
  if (selectedTab === TASK_TABS.TASK_POOL) {
    return 'Task pools of';
  } else if (selectedTab === TASK_TABS.CREATED) {
    return 'Created by';
  } else if (selectedTab === TASK_TABS.MENTIONED) {
    return 'Mentions of'
  }
  return 'Assigned to';
}

export const getUserTasksDefaultParams = (data: {
  selectedTab: string;
  topBarData: ITopBarData;
  defaultUserId: string;
  boardType?: BoardType;
  categoryCode?: string;
  searchString?: string;
  allUsersPool: IUserPool[];
  taskStatusList: IMlov[];
  taskPriorityList: IMlov[];
  notAllowNullValue?: boolean;
}): ITaskFilter => {
  const {
    selectedTab,
    topBarData,
    defaultUserId,
    boardType,
    categoryCode,
    searchString,
    allUsersPool,
    taskStatusList,
    taskPriorityList,
    notAllowNullValue
  } = data;
  const params: ITaskFilter = {
    assigneeIds: getSelectedUserIds(topBarData.selectedUsers || [], defaultUserId, !notAllowNullValue),
    timezone: getCurrentTimeZone(),
    contactIds: topBarData.selectedContactUuid?.map?.((contact: any) => contact.value) || [],
    statusIds: topBarData.selectedStatusList?.map?.((item) => item.id) || [],
    taskDueDateCategoryIds: topBarData.selectedDueDateUuid,
    priorityIds: topBarData.selectedPriorityList?.map?.((item) => item.id) || [],
    userPoolIds: topBarData.selectedPools?.map?.((pool) => pool.key) || [],
    labels: topBarData?.labels?.map(item => item?.uuid || '') || [],
    searchString,
  };

  if (topBarData.selectedDueDateUuid?.includes('date_range')) {
    params.startDateTime = getMomentObjFromFormat(topBarData.start, DATE_FORMATS.DISPLAY_DATE_FORMAT).toISOString();
    params.endDateTime = getMomentObjFromFormat(tomorrowDateInDateStringFormat(topBarData.end), DATE_FORMATS.DISPLAY_DATE_FORMAT).subtract(1, 'millisecond').toISOString();
    delete params.taskDueDateCategoryIds;
  }
  let selectedSortObject: any = {};
  if (topBarData.selectedSortUuid && topBarData.selectedSortUuid !== 'none') {
    selectedSortObject[topBarData.selectedSortUuid] = topBarData.selectedSortValueAscending ? 'asc' : 'desc';
  }

  switch (selectedTab) {
    case TASK_TABS.CREATED:
      params.createdByIds = topBarData?.createdByIds?.map(user => user.key || '') || [];
      break;

    case TASK_TABS.MENTIONED:
      params.mentionedUserOrContactIds = topBarData?.mentionUserIds?.map(user => user.key || '') || [];
      selectedSortObject = {};
      if (topBarData.selectedMentionsSortUuid && topBarData.selectedMentionsSortUuid !== 'none') {
        selectedSortObject[topBarData.selectedMentionsSortUuid] = topBarData.selectedMentionsSortValueAscending ? 'asc' : 'desc';
      }
      break;

    case TASK_TABS.TASK_POOL:
      const userPoolIds: any[] = [];
      allUsersPool.forEach(pool => {
        userPoolIds.push(pool.id);
      });
      params.userPoolIds = params.userPoolIds?.length ? params.userPoolIds : userPoolIds;
      break;
  }
  params.orderBy = selectedSortObject;

  if (categoryCode) {
    switch (boardType) {
      case BoardType.Status:
        params.statusIds = [];
        const statusId = getMlovIdFromCode(taskStatusList, categoryCode);
        if (statusId) {
          params.statusIds.push(statusId);
        }
        break;

      case BoardType.Priority:
        params.priorityIds = [];
        const priorityId = getMlovIdFromCode(taskPriorityList, categoryCode);
        if (priorityId) {
          params.priorityIds.push(priorityId);
        }
        break;

      case BoardType.dueDate:
        delete params.taskDueDateCategoryIds;
        if(categoryCode === TASK_DUE_DATE_CODES.PAST) {
          params.dueDateEndDateTime = getPreviousDateEndOfDay();
          delete params.startDateTime;
          delete params.endDateTime;
        }
        else if(categoryCode === TASK_DUE_DATE_CODES.TODAY) {
          params.dueDateStartDateTime = getStartOfDay()?.toISOString();
          params.dueDateEndDateTime = getEndOfDay()?.toISOString();
          delete params.startDateTime;
          delete params.endDateTime;
        }
        else if(categoryCode === TASK_DUE_DATE_CODES.FUTURE) {
          params.dueDateStartDateTime = getNextDateStartOfDay();
          delete params.startDateTime;
          delete params.endDateTime;
        }
        break;
      case BoardType.taskPool:
        // Category code is pool id in case of task pool
        if (categoryCode) {
          params.userPoolIds = [categoryCode];
        }
        break;
      default: break;
    }
  }

  return params;
}

export const getPatientTasksDefaultParams = (data: {
  topBarData: ITopBarData;
  filters: any;
  personData: any;
  categoryCode?: string | string[];
  taskStatusMlovList: IMlov[];
}): ITaskFilter => {
  const {
    topBarData,
    personData,
    filters,
    categoryCode,
    taskStatusMlovList,
  } = data;

  const params: ITaskFilter = {
    timezone: getCurrentTimeZone(),
    searchString: filters?.formData?.searchString || '',
  };

  const isFromPatientTaskPanel = topBarData.panelType === TaskPanelType.PATIENT;
  const isFromPatientContextTaskPanel = topBarData.panelType === TaskPanelType.INTERNAL;

  const selectedSortObject: any = {};
  if (topBarData.selectedSortUuid && topBarData.selectedSortUuid !== 'none') {
    selectedSortObject[topBarData.selectedSortUuid] = topBarData.selectedSortValueAscending ? 'asc' : 'desc';
  }
  params.orderBy = categoryCode==TASK_STATUS.COMPLETED ? {completedDateTime: 'desc'} : selectedSortObject;

  const assigneeIds: string[] = personData?.contactUUID ? [personData?.contactUUID] : [];

  const apiDateRange = filters?.formData ? getISODateFromDisplayDate(filters.formData) : getISODateFromDisplayDate(topBarData);
  if (apiDateRange?.start) {
    params.startDateTime = apiDateRange.start;
  }
  if (apiDateRange?.end) {
    params.endDateTime = apiDateRange.end;
  }
  const dueDateId = filters?.formData?.selectedDueDateUuid;
  if (dueDateId) {
    params.taskDueDateCategoryIds = Array.isArray(dueDateId) ? dueDateId : [dueDateId];
  }
  params.assigneeIds = isFromPatientTaskPanel ? assigneeIds : [];
  params.contactIds = isFromPatientContextTaskPanel ? assigneeIds : [];
  if (isFromPatientContextTaskPanel) {
    params.assigneeIdsNotIn = assigneeIds;
  }

  if (filters?.formData?.selectedPriorityTypes?.length) {
    params.priorityIds = filters.formData.selectedPriorityTypes?.map((mlov: any) => mlov.id) || [];
  }
  if (filters?.formData?.selectedTaskEntityTypes?.length) {
    params.entityTypeIds = filters.formData.selectedTaskEntityTypes?.map((mlov: any) => mlov.id) || [];
  }
  if (filters?.formData?.selectedStatusTypes?.length) {
    params.statusIds = filters.formData.selectedStatusTypes?.map((mlov: any) => mlov.id) || [];
  }
  if (!filters?.formData?.selectedDueDateUuid?.includes('date_range')) {
    delete params?.startDateTime;
    delete params?.endDateTime;
  } else {
    params.taskDueDateCategoryIds = (params as any).taskDueDateCategoryIds.filter((id: string) => id !== 'date_range')
  }
  if (Array.isArray(categoryCode) && categoryCode.length > 0) {
    const statusIds = categoryCode.map(code => getMlovIdFromCode(taskStatusMlovList, code)).filter(id => id);
    if (statusIds.length > 0) {
      params.statusIds = statusIds;
    }
  } else if (typeof categoryCode === 'string' && categoryCode) {
    const statusId = getMlovIdFromCode(taskStatusMlovList, categoryCode);
    if (statusId) {
      params.statusIds = [statusId];
    }
  }
  return params;
}

const getSelectedUserIds = (selectedUsers: IUserSearch[], defaultUserId: string, allowNullValue: boolean) => {
  const userIds = selectedUsers?.map((user)=>{
    return user?.key
  });
  let selectedAssigneeUserIds: (string | null)[] = userIds?.length ? userIds : [defaultUserId];
  if (allowNullValue) {
    selectedAssigneeUserIds = selectedAssigneeUserIds.map((id)=> {
      if (id === 'null') {
        return null;
      }
      return id;
    })
  } else {
    selectedAssigneeUserIds = selectedAssigneeUserIds.filter(item => !!item);
  }
  return selectedAssigneeUserIds || [];
}

export const setTaskDetailsBasedOnUpdatedData = (
  task: ITask,
  updatedData: IUpdatedTaskData,
  supportingData: {
    taskStatusList: IMlov[];
    taskPriorityList: IMlov[];
  }
) => {
  switch (updatedData.field) {
    case TaskField.isDeleted:
      break;

    case TaskField.status:
      const updatedStatusId = updatedData.value as string;
      const updatedStatusMlov = supportingData.taskStatusList.filter((item) => item.id === updatedStatusId)?.[0];
      task.statusId = updatedStatusId;
      task.status = {
        id: updatedStatusId,
        code: updatedStatusMlov?.code,
        value: updatedStatusMlov?.value,
      };
      task.isCompleted = updatedStatusMlov?.code === TASK_STATUS_CODES.COMPLETED;
      break;

    case TaskField.assignee:
      const type = updatedData.additionalData?.type;
      const isAssigneeTypePatientOrProspect = type === ParticipantType.patient || type === ParticipantType.leads;
      const personData = updatedData.additionalData?.label?.props?.contactData;
      const updatedAssigneeId = updatedData.value as string;
      task.assigneeId = updatedAssigneeId;
      task.assigneeTypeCode = isAssigneeTypePatientOrProspect ? 'CONTACT' : 'USER';
      task.assigneeUser = personData;
      break;

    case TaskField.priority:
      const updatedPriorityCode = updatedData.value as string;
      const priorityMlov = getMlovObjectFromCode(updatedPriorityCode, supportingData.taskPriorityList);
      task.priorityId = priorityMlov?.id;
      task.priority = {
        id: priorityMlov?.id,
        code: priorityMlov?.code || '',
        value: priorityMlov?.value || '',
      };;
      break;
  }
}

export const isStatusMatchingFilters = (statusId: string, filters: ITaskFilter, boardType?: BoardType) => {
  // If board type is status and if status is changed then it will never be part of same list, thus return false
  // If patient task context then it's always status board, so if status is changed then it will never be part of same list, thus return false
  if (boardType === BoardType.Status) {
      return false;
  }
  // Check if the updated status is selected in filter as well
  const selectedStatusList = filters.statusIds || [];
  if (selectedStatusList.length) {
    return !!selectedStatusList.filter((item) => item === statusId).length;
  }
  // By default it should stay in the list
  return true;
}

export const isPriorityMatchingFilters = (priorityId: string, filters: ITaskFilter, boardType?: BoardType) => {
  // If board type is priority and if priority is changed then it will never be part of same list, thus return false
  if (boardType === BoardType.Priority  ) {
    return false;
  }
  // Check if the updated priority is selected in filter as well
  const selectedPriorityList = filters.priorityIds || [];;
  if (selectedPriorityList.length) {
    return !!selectedPriorityList.filter((item) => item === priorityId).length;
  }
  // By default it should stay in the list
  return true;
}

export const isAssigneeMatchingFilters = (assigneeId: string, filters: ITaskFilter) => {
  // Check if the updated assignee is selected in filter as well
  const selectedUsersList = filters.assigneeIds || [];
  if (selectedUsersList.length) {
    return !!selectedUsersList.filter((item) => item === assigneeId).length;
  }
  // By default it should stay in the list
  return true;
}

export const doesTaskSatisfiesFilterConditions = (
  task: ITask,
  filters: ITaskFilter,
  supportingData: {
    taskDueDateCategoryMlovs: IMlov[];
    categoryCode?: string;
    boardType?: BoardType;
}) => {
  const { taskDueDateCategoryMlovs, categoryCode, boardType } = supportingData;
  const selectedDueDateCode: string[] = [];
  const taskDueDateCategoryIds = filters.taskDueDateCategoryIds;
  if (taskDueDateCategoryIds && Array.isArray(taskDueDateCategoryIds)) {
    if (taskDueDateCategoryIds.includes('date_range')) {
      selectedDueDateCode.push('date_range');
    } else {
      taskDueDateCategoryIds.forEach((uuid) => {selectedDueDateCode.push(getMlovCodeFromId(taskDueDateCategoryMlovs, uuid))});
    }
  }

  if (!task?.id || !filters.assigneeIds?.length) {
    return true;
  }

  const taskStatusId = task.statusId || task.status?.id;
  const taskPriorityId = task.priorityId || task.priority?.id;

  if (filters.statusIds?.length && boardType !== BoardType.Status) {
    if (!taskStatusId || !filters.statusIds.includes(taskStatusId)) {
      return false;
    }
  }

  if (filters.priorityIds?.length && boardType !== BoardType.Priority) {
    if (!taskPriorityId || !filters.priorityIds.includes(taskPriorityId)) {
      return false;
    }
  }

  let startDateString = filters.startDateTime;
  let endDateString = filters.endDateTime;

  if (selectedDueDateCode?.includes('All') || selectedDueDateCode?.length === 0) {
    startDateString = undefined;
    endDateString = undefined;
  } else if (selectedDueDateCode && !selectedDueDateCode.includes('date_range')) {
    selectedDueDateCode.forEach((code)=>{
      const { start, end } = getStartAndEndDateByTaskDueDateCategory(code);
      startDateString = start;
      endDateString = end;})
  }

  const startDate = startDateString ? getMomentObjFromDateStrAndFormat(startDateString, DATE_FORMATS.DISPLAY_DATE_FORMAT)?.valueOf() : undefined;
  const endDate = endDateString ? getMomentObjFromDateStrAndFormat(endDateString, DATE_FORMATS.DISPLAY_DATE_FORMAT)?.valueOf() : undefined;

  if (startDate) {
    if (!task.endDateTime || startDate > getMomentObj(task.endDateTime)?.valueOf()) {
      return false;
    }
  }

  if (endDate) {
    if (!task.endDateTime || endDate < getMomentObj(task.endDateTime)?.valueOf()) {
      return false;
    }
  }

  if (filters.contactIds?.length) {
    if (!task.contactId || !filters.contactIds.includes(task.contactId)) {
      return false;
    }
  }

  if (categoryCode === TASK_TABS.CREATED) {
    if (!task.createdBy || !filters.createdByIds?.includes(task.createdBy)) {
      return false;
    }
  } else if (boardType === BoardType.taskPool) {
    if (!task.userPoolId || (filters.userPoolIds?.length && !filters.userPoolIds?.includes(task.userPoolId))) {
      return false;
    }
  } else {
    if (!task.assigneeId || !filters.assigneeIds?.includes(task.assigneeId)) {
      return false;
    }
  }

  return true;
}

export const isTaskCompleted = (task: ITask): boolean => {
  return task.isCompleted || task.status?.code === 'completed';
}

export const getTaskAssignee = (
  task: ITask,
  userList: IBatchedAccountUsersInterface[]
) => {
  let assigneeType = task.assigneeTypeCode;
  if (!assigneeType && task?.id) {
    assigneeType = task?.contactId == task?.assigneeId ? TASK_ASSIGNEE_TYPE_CODES.Contact : TASK_ASSIGNEE_TYPE_CODES.User;
  }
  switch (assigneeType) {
    case TASK_ASSIGNEE_TYPE_CODES.User:
      const assigneeValue = userList
          .filter((user: any) => user.uuid === task?.assigneeId)
          .map((user: any) => ({
            value: user.uuid,
            label: user.name,
            key: user.uuid,
          }));
      return assigneeValue?.[0];

    case TASK_ASSIGNEE_TYPE_CODES.Contact:
        return {
          value: task?.assigneeUser?.uuid,
          label: task?.assigneeUser?.name,
          key: task?.assigneeUser?.uuid,
          type: task?.assigneeTypeCode
        }
  }
  if (!task?.id) {
    const assignee = userList.find(
      (item) => item.uuid === task?.assigneeId
    );
    if (assignee) {
      return {
        value: assignee.uuid,
        label: assignee.name,
        key: assignee.uuid,
      };
    } else {
      return undefined;
    }
  }
  return;
};

export const getActionAndReasonFromConflict = (conflicts: IConflict[], task: ITask, operations?: IBulkOperationData) => {
  const finalResult: {action: string, reason: string}[] = [];
  conflicts.forEach((conflict) => {
    conflict.conflictCodes.forEach((item) => {
      const data = getActionAndReasonFromConflictAndKey(item, conflict.key, task, operations);
      finalResult.push(data);
    });
  });
  return finalResult;
}

export const getActionDisplay = (
  key: string,
  task: ITask,
  operations?: IBulkOperationData
) => {
  let action = '';
  switch (key) {
    case CONFLICT_KEY.ASSIGNEE_ID:
      let oldAssignee = task.assigneeUser?.name || '';
      const newAssignee = operations?.assignee?.label || '';
      if (!oldAssignee) {
        oldAssignee = 'None';
      }
      action = `${oldAssignee}  to  ${newAssignee}`;
      break;

    case CONFLICT_KEY.COMMENT:
      action = 'Add comment';
      break;

    case CONFLICT_KEY.DUE_DATE:
      action = `${getMomentObj(task.endDateTime || new Date()).format(DATE_FORMATS.TASK_DUE_DATE_DISPLAY_FORMAT)}  to  ${getMomentObj(operations?.dueDate || new Date()).format(DATE_FORMATS.TASK_DUE_DATE_DISPLAY_FORMAT)}`;
      break;

    case CONFLICT_KEY.LABEL:
      action = 'Change labels';
      break;

    case CONFLICT_KEY.POOL:
      let oldPool = task.userPool?.name || '';
      const newPool = operations?.taskPool?.label || '';
      if (!oldPool) {
        oldPool = 'None';
      }
      action = `${oldPool}  to  ${newPool}`;
      break;

    case CONFLICT_KEY.PRIORITY:
      action = `${task.priority?.value}  to  ${operations?.priority?.label}`;
      break;

    case CONFLICT_KEY.STATUS_ID:
      action = `${task.status?.value}  to  ${operations?.status?.label}`;
      break;
  }
  return action;
}

export const getActionAndReasonFromConflictAndKey = (
  conflictCode: string,
  key: string,
  task: ITask,
  operations?: IBulkOperationData
) => {
  const actionDisplay = getActionDisplay(key, task, operations);
  switch (conflictCode) {
    case TASK_CONFLICT_CODE.SUBTASK_NOT_COMPLETED:
      return {
        action: actionDisplay,
        reason: 'Task has associated sub tasks which are not complete'
      };
    case TASK_CONFLICT_CODE.VITALS_NOT_FILLED:
      return {
        action: actionDisplay,
        reason: 'Vital value needs to be added before completing task'
      };
    case TASK_CONFLICT_CODE.FORM_ALREADY_SUBMITTED:
      return {
        action: actionDisplay,
        reason: 'Cannot perform this action as form is already submitted'
      };
    case TASK_CONFLICT_CODE.SUBTASK_NOT_DELETED:
      return {
        action: 'Delete',
        reason: 'Task has associated subtasks'
      };
    case TASK_CONFLICT_CODE.SUBTASK_DUE_DATE:
      return {
        action: actionDisplay,
        reason: task.parentId ? 'Subtask due date cannot be greater than parent task due date.' : 'Parent task due date cannot be less than subtask due date.'
      };
    case TASK_CONFLICT_CODE.ASSIGNEE_NOT_IN_POOL:
      return {
        action: actionDisplay,
        reason: 'Assignee is not available in task pool'
      };
    case TASK_CONFLICT_CODE.NOTE_NOT_SIGNED:
      return {
        action: actionDisplay,
        reason: 'Task cannot be completed as the linked note is not signed'
      };
    case TASK_CONFLICT_CODE.CARE_PLAN_IN_REVIEW:
      return {
        action: actionDisplay,
        reason: 'Task cannot be completed as the linked care plan is still in review'
      };
    case TASK_CONFLICT_CODE.JOURNEY_TASK_NOT_DELETE:
      return {
        action: 'Delete',
        reason: 'Cannot delete care journey associated tasks'
      };
    case TASK_CONFLICT_CODE.ORDER_TASK_NOT_DELETE:
      return {
        action: 'Delete',
        reason: 'Cannot delete order associated tasks'
      };

    case TASK_CONFLICT_CODE.TASK_ALREADY_DELETED:
      return {
        action: actionDisplay,
        reason: 'Task already deleted'
      };

    case TASK_CONFLICT_CODE.FORM_TASK_NOT_DELETED:
      return {
        action: 'Delete',
        reason: 'Form task cannot be deleted'
      };

    case TASK_CONFLICT_CODE.SUBTASK_TASK_DELETE:
      return {
        action: 'Delete',
        reason: 'Task is associated with a sub task'
      };

    case TASK_CONFLICT_CODE.VITAL_TASK_NOT_DELETED:
      return {
        action: 'Delete',
        reason: 'Task is associated with a vital'
      };

    case TASK_CONFLICT_CODE.TASK_ALREADY_COMPLETED:
      return {
        action: actionDisplay,
        reason: 'Task is already completed'
      };

    case TASK_CONFLICT_CODE.TASK_START_DATE_GREATER_THAN_DUE_DATE:
      return {
        action: actionDisplay,
        reason: 'Task start date is greater than due date'
      };

    case TASK_CONFLICT_CODE.SUB_TASK_NOT_ALLOWED_LABEL:
      return {
        action: actionDisplay,
        reason: 'Cannot add or update label for a sub task',
      };

    case TASK_CONFLICT_CODE.SUB_TASK_NOT_ALLOWED_POOL:
      return {
        action: actionDisplay,
        reason: 'Cannot assign sub task to a task pool',
      };

    case TASK_CONFLICT_CODE.SUB_TASK_NOT_ALLOWED_COMMENT:
      return {
        action: actionDisplay,
        reason: 'Cannot add comment to a sub task',
      };

    case TASK_CONFLICT_CODE.SUB_TASK_NOT_ALLOWED_PRIORITY:
      return {
        action: actionDisplay,
        reason: 'Cannot update priority for a sub task',
      };

    case TASK_CONFLICT_CODE.TASK_ASSIGNEE_LOCATION_MISMATCH:
      return {
        action: actionDisplay,
        reason: 'Assignee is not associated to the task location',
      };

    case TASK_CONFLICT_CODE.TASK_AND_POOL_LOCATION_MISMATCH:
      return {
        action: actionDisplay,
        reason: 'Task pool is not associated to the task location',
      };

    default:
      return {
        action: '-',
        reason: '-'
      }
  }
}

export const getLabelOptionTitle = (action: BulkLabelChangeAction) => {
  switch (action) {
    case BulkLabelChangeAction.addToExisting: return 'Add to Existing';
    case BulkLabelChangeAction.replaceAllLabels: return 'Replace All Labels';
    case BulkLabelChangeAction.findAndReplace: return 'Find & Replace';
    case BulkLabelChangeAction.findAndDelete: return 'Find & Delete';
    case BulkLabelChangeAction.deleteAll: return 'Delete All';
  }
}

export const getSkipCallForTaskCount = (tab: ITabsList, filter: ITaskFilter):boolean => {
  switch (tab.key) {
    // Dont make api call if in task pool tab and userPoolIds in empty.
    case TASK_TABS.TASK_POOL:
      return filter.userPoolIds?.length === 0;
    default:
      return false;
  }
}

export const getSkipTodayCountCallForTaskCount = (categoryCode: string):boolean => {
  if (categoryCode === TASK_STATUS.ACCEPTED || categoryCode === TASK_STATUS.MISSED) {
    return false;
  }
  return true;
}

export const shouldUpdateCategoriesWithFilters = (
  rawData: any,
  topBarData: ITopBarData,
  allUsersPool: IUserPool[],
  userPoolSelect: IAllUserPoolSelect[],
  userPool: IUserPool[],
  isCreatedTabSelected: boolean,
  isMentionTabSelected: boolean,
): boolean => {
  const fieldsToCompare: Array<keyof ITopBarData | { topBarField: keyof ITopBarData, rawField: keyof typeof rawData }> = [
    'selectedPriorityList',
    'selectedStatusList',
    { topBarField: 'selectedDueDateType', rawField: 'selectedDueDate' },
    'selectedDueDateUuid',
    'start',
    'end',
    'selectedContactUuid',
    'selectedPatientName',
    'selectedPatientLabel',
    'selectedPools',
    'selectedUsers',
    { topBarField: 'labels', rawField: 'selectedLabels' },
  ];
  if (isMentionTabSelected) {
    fieldsToCompare.push('mentionUserIds');
  }
  if (isCreatedTabSelected) {
    fieldsToCompare.push('createdByIds');
  }
  for (const field of fieldsToCompare) {
    let topBarValue, rawValue;
    
    if (typeof field === 'string') {
      topBarValue = topBarData[field];
      rawValue = rawData[field];
    } else {
      topBarValue = topBarData[field.topBarField];
      rawValue = rawData[field.rawField];
    }
    if (!isEqual(topBarValue, rawValue)) {
      return true;
    }
  }

  if (!isEqual(allUsersPool, rawData.tempAllUserPool)) {
    return true
  }
  if (!isEqual(userPoolSelect, rawData.tempAllUserPoolSelect)) {
    return true
  }
  if (!isEqual(userPool, rawData.tempUserPool)) {
    return true
  }

  return false;
};