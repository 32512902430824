import {
  Box,
  Button,
  FlatList,
  HStack,
  Pressable,
  Text,
  VStack,
} from 'native-base';
import React from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {Colors} from '../../../../../styles';
import {styles} from './BoardListViewStyles';

const BoardListView = (props: any) => {
  const onBoardItemSelect = (item: any) => {
    props.onActionPerformed(COMMON_ACTION_CODES.ITEM_SELECT, '', false, item);
  };

  const onEditAction = (item: any) => {
    // setUpdateBoard(item);
    props.onActionPerformed(COMMON_ACTION_CODES.EDIT_MODE, '', true, item);
  };

  const onDeleteBoard = (item: any) => {
    props.onActionPerformed(COMMON_ACTION_CODES.DELETE, '', false, item);
  };

  return (
    <Box style={styles.listBox}>
      <FlatList
        data={props.boardData}
        renderItem={({item}) => (
          <Box
            borderBottomWidth={1}
            borderColor={'coolGray.200'}
            style={styles.itemContainer}
            backgroundColor={
              props.selectedBoard.id == item.id
                ? Colors.Custom.HoverBgColor
                : ''
            }
          >
            <Pressable
              onPress={() => {
                onBoardItemSelect(item);
              }}
            >
              <HStack space={3} style={styles.listItem}>
                <VStack>
                  <Text color="#444444">{item.title}</Text>
                </VStack>
                <VStack>
                  <HStack>
                    <VStack>
                      <Button
                        size={'sm'}
                        colorScheme={'primary'}
                        variant={''}
                        onPress={() => {
                          onEditAction(item);
                        }}
                      >
                        <AntIcon name={'edit'} style={styles.icons} />
                      </Button>
                    </VStack>
                    <VStack>
                      <Button
                        size={'sm'}
                        colorScheme={'primary'}
                        variant={''}
                        onPress={() => {
                          onDeleteBoard(item);
                        }}
                      >
                        <AntIcon name={'delete'} style={styles.icons} />
                      </Button>
                    </VStack>
                  </HStack>
                </VStack>
              </HStack>
            </Pressable>
          </Box>
        )}
        keyExtractor={(item: any) => item.id}
      />
    </Box>
  );
};

export default BoardListView;
