import React from 'react';
import {Checkbox, HStack, Text} from 'native-base';
import {openLinkInBrowser} from '../../../utils/LinkUtils';
import {useIntl} from 'react-intl';
import { TestIdentifiers, testID } from '../../../testUtils';

export interface TermsAndConditionProps {
  isTnCAccepted: boolean;
  onChangeTAndC: (isSelected: boolean) => void;
}

export const TermsAndCondition = (props: TermsAndConditionProps) => {
  const {isTnCAccepted, onChangeTAndC} = props;
  const intl = useIntl();
  return (
    <HStack justifyContent={'flex-start'} alignItems={'center'} mt={4}>
      <Checkbox
        isChecked={isTnCAccepted}
        onChange={onChangeTAndC}
        value={'I_AGREE_TO_111'}
        accessibilityLabel={intl.formatMessage({id: 'iAgreeTo' || ''})}
        {...testID(TestIdentifiers.tnCCheckbox)}
      />
      <Text fontSize={14} fontWeight={'400'} style={{marginLeft: 10}} {...testID('TermsAndConditionPrivacyPolicyText')}>
        {intl.formatMessage({id: 'iAgreeTo' || ''})}
        <Text
          color={'#007bff'}
          size={'md'}
          onPress={() => {
            openLinkInBrowser('https://www.fold.health/terms-of-service');
          }}
          {...testID(TestIdentifiers.tnCLink)}
        >
          {' '}
          {intl.formatMessage({id: 'termsAndCondition' || ''})}
        </Text>
        {' and '}
        <Text
          color={'#007bff'}
          size={'md'}
          onPress={() => {
            openLinkInBrowser('https://www.fold.health/privacy-policy');
          }}
          {...testID(TestIdentifiers.privacyPolicyLink)}
        >
          {intl.formatMessage({id: 'privacyPolicy' || ''})}
        </Text>
      </Text>
    </HStack>
  );
};
