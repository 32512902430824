import React, { useMemo } from 'react';
import { Path, Svg } from 'react-native-svg';

const RestartSvg = React.memo(() => {
  const svgContent = useMemo(() => (
    <Svg width="12" height="13" viewBox="0 0 12 13" fill="none">
      <Path
        d="M10.242 3.86749L9.77059 3.39608C7.68779 1.31329 4.31091 1.31329 2.22811 3.39608C0.145317 5.47888 0.145316 8.85576 2.22811 10.9386C4.31091 13.0214 7.68779 13.0214 9.77059 10.9386C10.9818 9.72734 11.4887 8.07849 11.2911 6.501M10.242 3.86749H7.41356M10.242 3.86749V1.03906"
        stroke="#6F7A90"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  ), []);

  return svgContent;
});

export default RestartSvg;