import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';
export const styles = StyleSheet.create({
  leftContainer: {
    backgroundColor: Colors.Custom.ContainerBGColor,
    borderColor: Colors.Custom.BorderColor,
    borderRadius: 10,
    borderWidth: 0.5,
    flex: 0.2,
    // marginBottom: 6,
    // marginTop: 8,
    // marginLeft: 8,
    justifyContent: 'flex-start',
    minWidth: 250,
    bg: Colors.Custom.BackgroundColor,
  },

  titleWrapper: {
    // marginHorizontal: 22,
    // marginVertical: 12
  },
  productSideBar: {
    width: '100%',
  },
  tagContainer: {
    // marginHorizontal: 14
  }
});
