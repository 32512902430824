import {useLazyQuery, useMutation} from '@apollo/client';
import {useCallback, useContext, useMemo, useState} from 'react';
import {setOriginalNode} from 'typescript';
import ContactCareProgram from '../../../../../services/ContactCareProgram/ContactCareProgram';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {IContactCareProgram} from '../../interface';
import {getFormDetails} from '../ContactCareProgramView.utils';
import {CARE_PROGRAM_STEP_CODES, CARE_PROGRAM_SUB_STEP_CODES, FORM_SOURCE, MLOV_CATEGORY} from '../../../../../constants/MlovConst';
import {getMlovIdFromCode, getMlovListFromCategory, getMlovObjectFromId} from '../../../../../utils/mlovUtils';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {CARE_PROGRAM_STEP_TYPE} from '../../../../common/MemebersView/constant';
import {FormsQueries} from '../../../../../services';
import {getUserUUID} from '../../../../../utils/commonUtils';
import { v4 as uuidv4 } from 'uuid';
import {useCustomToast} from '../../../../Toast/ToastProvider';
import {ToastType} from '../../../../../utils/commonViewUtils';
import {EVENT_NAMES} from '../../../../../constants/EntityAndEventsConst';
import {EventBus} from '../../../../../utils/EventBus';
import {GET_FORM_RESPONSE_BY_FORM_LOG_ID} from '../../../../../services/Forms/FormsQueries';
import { PROCESS_BILLING } from '../../../CareManagementBilling/BillingQueries';

export enum StepAction {
  REVIEW = 'REVIEW',
  SKIP = 'SKIP',
  COMPLETE = 'COMPLETE',
  SEND_TO_PATIENT = 'SEND_TO_PATIENT',
  SEND_CLAIM = 'SEND_CLAIM',
}

interface IUseCareProgramStepActionsParams {
  careProgramDetails?: IContactCareProgram;
  stepId: string;
}

export const useCareProgramStepActions = (props: {
  selectedStepCode: string;
  extraParams?: {
    contactId: string;
    patientId: string;
  };
}) => {
  const {selectedStepCode, extraParams} = props;
  const {contactId, patientId} = extraParams || {};
  const [isLoading, setIsLoading] = useState<StepAction | undefined>(undefined);

  const isAssessmentStep =
    selectedStepCode === CARE_PROGRAM_STEP_TYPE.ASSESSMENT ||
    selectedStepCode === CARE_PROGRAM_STEP_TYPE.USER_ASSESSMENT;

  const toast = useCustomToast();
  const currentUserUuid = getUserUUID();
  const contextData = useContext(CommonDataContext);
  const formSourceList = useMemo(
    () =>
      getMlovListFromCategory(
        contextData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.FORM_SOURCE
      ),
    [Object.keys(contextData.CARE_STUDIO_MLOV).length]
  );

  const careProgramSubStepList =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CONTACT_CARE_PROGRAM_SUB_STEP
    ) || [];

  const careProgramStatusList =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_STEP_STATUS
    ) || [];

  // API Call
  const [updateContactCareProgramStepStatus] = useMutation(
    ContactCareProgram.UPDATE_CONTACT_CARE_PROGRAM_STEP_STATUS,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );
  const [createFormLogLink] = useMutation(FormsQueries.CREATE_FORM_LOG_LINK, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });
  const [getFormResponseByFormLogId] = useLazyQuery(
    GET_FORM_RESPONSE_BY_FORM_LOG_ID,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );
  const [submitForm] = useMutation(FormsQueries.SUBMIT_FORM_RESPONSE, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });
  const [processBilling] = useMutation(PROCESS_BILLING, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT }
  });

  const getFormLogId = useCallback(
    (params: IUseCareProgramStepActionsParams) => {
      const {careProgramDetails, stepId} = params;
      return getFormDetails(careProgramDetails as IContactCareProgram, stepId);
    },
    []
  );

  const submitFormResponse = async (
    formLogId: string,
    formId: string
  ): Promise<string | undefined> => {
    try {
      // Fetch latest form response
      const response = await getFormResponseByFormLogId({
        variables: {formLogId},
      });
      const formLog = response?.data?.formLogs?.[0];
      const subjectId = formLog?.subjectId;
      const sourceId = formLog?.sourceId;
      const formResponseComponents =
        formLog?.formResponse?.formResponse?.components;
      const formResponseId = formLog?.formResponse?.id;
      const foldPrefilledDataProgressPercentage =
        formLog?.formResponse?.foldPrefilledDataProgressPercentage;

      const data = {
        formId,
        reference: {},
        patientId,
        contactId,
        subjectId,
        sourceId,
        isDraft: false,
        formResponseId,
        foldPrefilledDataProgressPercentage,
        formResponse: {components: formResponseComponents},
      };

      const submitFormResponse = await submitForm({variables: {data}});
      const id = submitFormResponse?.data?.submitFormResponse?.id;
      return id || undefined;
    } catch (error) {
      return undefined;
    }
  };

  const getPayLoadForStatusAction = (
    action: StepAction,
    params: IUseCareProgramStepActionsParams
  ) => {
    let formLogId, formId;
    const {careProgramDetails, stepId} = params;
    const step = careProgramDetails?.contactCareProgramSteps?.find(
      (step) => step.id === stepId
    );

    const payload: any = {
      careProgramStepId: step?.id,
      contactCareProgramId: careProgramDetails?.id,
      careProgramSubStepId: '',
      payload: undefined,
    };

    switch (action) {
      case StepAction.REVIEW:
        const reviewedSubStepTypeId = getMlovIdFromCode(
          careProgramSubStepList,
          CARE_PROGRAM_SUB_STEP_CODES.REVIEWED
        );
        const reviewedSubStep = step?.careProgramSubSteps.find(
          (item) => item.careProgramSubStepType.id === reviewedSubStepTypeId
        );
        payload.careProgramSubStepId = reviewedSubStep?.id as string;
        break;
      case StepAction.SKIP:
        const skippedSubStepTypeId = getMlovIdFromCode(
          careProgramSubStepList,
          CARE_PROGRAM_SUB_STEP_CODES.SKIPPED
        );
        const skipSubStep = step?.careProgramSubSteps.find(
          (item) => item.careProgramSubStepType.id === skippedSubStepTypeId
        );
        payload.careProgramSubStepId = skipSubStep?.id as string;
        break;
      case StepAction.COMPLETE:
      case StepAction.SEND_CLAIM:
        const completedSubStepTypeId = getMlovIdFromCode(
          careProgramSubStepList,
          CARE_PROGRAM_SUB_STEP_CODES.COMPLETED
        );
        const completedSubStep = step?.careProgramSubSteps.find(
          (item) => item.careProgramSubStepType.id === completedSubStepTypeId
        );
        payload.careProgramSubStepId = completedSubStep?.id as string;
    }

    if (isAssessmentStep) {
      const formInfo = getFormLogId(params);

      formLogId = formInfo?.formLogId;
      formId = formInfo?.formId;

      if (!formLogId || !formId) {
        return {};
      }

      payload.payload = {
        stepCode: step?.careProgramStepType?.code,
        resourceId: formLogId,
        resourceTypeCode: 'FORM_LOG',
      };
    }

    return payload;
  };

  const handleContentAction = async (
    action: StepAction,
    params: IUseCareProgramStepActionsParams
  ) => {
    setIsLoading(action);
    try {
      switch (action) {
        // Status actions
        case StepAction.SKIP:
        case StepAction.REVIEW:
        case StepAction.COMPLETE:
          const payLoad = getPayLoadForStatusAction(action, params);
          let formResponseId;
          const steps = params.careProgramDetails?.contactCareProgramSteps;
          const step = steps?.find((step) => step.id === params.stepId);
          const stepCode = step?.careProgramStepType?.code;
          if (
            action === StepAction.REVIEW && stepCode &&
            [CARE_PROGRAM_STEP_TYPE.ASSESSMENT, CARE_PROGRAM_STEP_TYPE.USER_ASSESSMENT].includes(stepCode)
          ) {
            // form submit api call
            const {formId, formLogId} = getFormDetails(
              params.careProgramDetails as IContactCareProgram,
              params.stepId as string
            );
            if (formLogId && formId) {
              formResponseId = await submitFormResponse(formLogId, formId);
            } else {
              throw new Error('Form log id not found');
            }
          }

          const response = await updateContactCareProgramStepStatus({
            variables: {
              params: payLoad,
            },
          });

          const newStepLogStatusId =
            response.data?.updateContactCareProgramStatus
              ?.careProgramStepStatusId;

          return newStepLogStatusId;
        case StepAction.SEND_TO_PATIENT:
          const {formId} = getFormDetails(
            params.careProgramDetails as IContactCareProgram,
            params.stepId as string
          );
          const eventBus = EventBus.getEventBusInstance();
          eventBus.broadcastEvent(EVENT_NAMES.REFRESH_TASK, {});
          const sendFormResponse = await createFormLogLink({
            variables: {
              params: {
                contactId: params?.careProgramDetails?.contactId,
                formId,
                sourceId: getMlovIdFromCode(
                  formSourceList,
                  FORM_SOURCE.CARE_PLAN
                ),
                requestedByUserId: currentUserUuid,
                subjectId: uuidv4(),
              },
            },
          });
          toast({
            message: 'Form sent to patient successfully',
            toastType: ToastType.success,
          });
          const formLogId =
            sendFormResponse?.data?.createFormLogLink?.formLogId;
          return formLogId;

        case StepAction.SEND_CLAIM:
          const billingResponse = await processBilling({variables: {
            params: {
              contactCareProgramIds: [params.careProgramDetails?.id],
            }
          }});
          return billingResponse?.data?.processContactCareProgramBillableActivity?.id;
      }
    } catch (error) {
      // handle error
      setIsLoading(undefined);
      toast({
        message: 'Something went wrong',
        toastType: ToastType.error,
      });
      return undefined;
    } finally {
      setIsLoading(undefined);
    }
  };

  const dropdownActionList = useMemo(
    () =>
      getDropdownActionListBasedOnStepCode({
        selectedStepCode,
      }),
    [selectedStepCode]
  );

  return {handleContentAction, isLoading, dropdownActionList};
};


const getDropdownActionListBasedOnStepCode = (params: {
  selectedStepCode: string;
}) => {
  const {selectedStepCode} = params;
  const actionList: {
    label: string;
    action: StepAction;
    icon?: JSX.Element;
  }[] = [];

  switch (selectedStepCode) {
    case CARE_PROGRAM_STEP_TYPE.ASSESSMENT:
    case CARE_PROGRAM_STEP_TYPE.USER_ASSESSMENT:
      actionList.push({
        label: 'Send to Patient',
        action: StepAction.SEND_TO_PATIENT,
      });
  }

  return actionList;
}
