import React from "react";
import { Svg, Path } from "react-native-svg";

function IsShownToPatientIcon() {
  return (
    <Svg
      width="20"
      height="22"
      fill="none"
      viewBox="0 0 20 22"
    >
      <Path
        fill="#12B76A"
        d="M14.05 14.68a.5.5 0 00-.768.64l.769-.64zM14.5 16l-.384.32a.5.5 0 00.743.028L14.5 16zm2.192-1.54a.5.5 0 10-.718-.697l.718.696zM12.5 5A3.5 3.5 0 019 8.5v1A4.5 4.5 0 0013.5 5h-1zM9 8.5A3.5 3.5 0 015.5 5h-1A4.5 4.5 0 009 9.5v-1zM5.5 5A3.5 3.5 0 019 1.5v-1A4.5 4.5 0 004.5 5h1zM9 1.5A3.5 3.5 0 0112.5 5h1A4.5 4.5 0 009 .5v1zM18.5 15a3.5 3.5 0 01-3.5 3.5v1a4.5 4.5 0 004.5-4.5h-1zM15 18.5a3.5 3.5 0 01-3.5-3.5h-1a4.5 4.5 0 004.5 4.5v-1zM11.5 15a3.5 3.5 0 013.5-3.5v-1a4.5 4.5 0 00-4.5 4.5h1zm3.5-3.5a3.5 3.5 0 013.5 3.5h1a4.5 4.5 0 00-4.5-4.5v1zm-1.718 3.82l.834 1 .768-.64-.833-1-.769.64zm1.577 1.028l1.833-1.889-.718-.696-1.833 1.889.718.696zM9 20.5c-1.983 0-3.446-.125-4.526-.344-1.082-.219-1.745-.525-2.158-.862-.79-.642-.816-1.508-.816-2.794h-1c0 1.2-.027 2.583 1.184 3.57.587.477 1.424.83 2.592 1.066 1.17.237 2.707.364 4.724.364v-1zm-7.5-4c0-.982.713-1.972 2.088-2.746C4.948 12.989 6.86 12.5 9 12.5v-1c-2.279 0-4.366.518-5.902 1.382C1.578 13.737.5 14.996.5 16.5h1zm7.5-4c1.025 0 2 .112 2.889.315l.222-.975c-.964-.22-2.014-.34-3.111-.34v1zm7.33 5.847c-.183.573-.585 1.095-1.646 1.49-1.096.408-2.856.663-5.684.663v1c2.86 0 4.765-.254 6.032-.726 1.302-.484 1.96-1.212 2.25-2.121l-.951-.306z"
      ></Path>
    </Svg>
  );
}

export default IsShownToPatientIcon;
