import React from 'react';
import TabLink from '../TabLink';

export default function OpportunityAutomationTabs() {
  return (
    <div
      style={{
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        display: 'flex',
        padding: '1rem 0',
        gap: '1rem',
      }}
    >
      <TabLink label="Vitals" />
      <TabLink disabled label="Clinical Conditions" />
      <TabLink disabled label="Screening" />
    </div>
  );
}
