import axios from "axios";
const instance = axios.create();

// Include authorization token in every request
instance.interceptors.request.use(function (config) {
  return config;
});

// Check if response status is 403 or 401 and logout the user in case of affirmative
// Check if response status is 503 and redirect to MaintenancePage
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
    }
    // if(error.response.status === 503){
    //   window.location.href = "/maintenance";
    // }
    return Promise.reject(error);
  }
);

export default instance;
