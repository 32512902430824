import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { ICommonSvgProps } from '../interfaces';
import { Colors } from '../../../../styles/Colors';

const AngleRightSvg = (props: ICommonSvgProps) => {
    return (
        <Svg width={props.width || "16"} height={props.height || "16"} viewBox="0 0 16 16" fill="none">
            <Path d="M6 3.33398L10 8.00065L6 12.6673" stroke={props?.customStrokeColor || Colors.FoldPixel.GRAY300} strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    )
}

export default React.memo(AngleRightSvg);