import {Switch} from "antd";
import {Tooltip} from "native-base";
import {useEffect, useState} from "react";
import {ANALYTICS_RESOURCE, SECTION_LIST} from "../../../../../constants";
import {Colors} from "../../../../../styles";
import {getDateStrFromFormat} from "../../../../../utils/DateUtils";
import ArrowRightSvg from "../../../../common/Svg/ArrowRightSvg";
import DownloadImageSvg from "../../../../common/Svg/DownloadImageSvg";
import EyeHideSvg from "../../../../common/Svg/WidgetsSvg/EyeHideSvg";
import InfoIconView from "../CommonView/InfoIconView";
import {DashboardDataContext} from "../EmployerContext/DashboardContext";
import {WIDGET_ACTION_CODE} from "../EmployerUtils/EmployerConst";
import WidgetActionView from "../SectionAndWidget/WidgetActionView";
import './widgetHeader.css';

const WidgetHeader = (props: any) => {
  const {
    sectionData,
    onActionClick,
    refreshButtonOnLocalEnv,
    canShowDrillDown,
    isPrintPreview,
    isAccountDashboard,
    onOpenDetails
  } = props;
  const [stateData, setStateData] = useState({
    filterValueName: [] as string[],
    filterValueString: '',
    selectedAppliedFilter: [] as any,
    isVisible: true
  })
  const isHideDate = sectionData.sectionCategory === SECTION_LIST.SAVINGS && !(sectionData.resourceCode === ANALYTICS_RESOURCE.TOTAL_SAVINGS) ? true : false;

  useEffect(() => {
    setStateData(prev => {
      return {
        ...prev,
        isVisible: !sectionData.isDisabled
      }
    })
  }, []);
  const isCostSavingSections = () => {
    const costSavingSections = [
      ANALYTICS_RESOURCE.LAB_SAVINGS,
      ANALYTICS_RESOURCE.VISITS_SAVINGS,
      ANALYTICS_RESOURCE.IMAGING_SAVINGS,
      ANALYTICS_RESOURCE.PROCEDURES_SAVINGS,
      ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING,
      ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING
    ];
    return costSavingSections.includes(sectionData.resourceCode);
  }
  return (
    <DashboardDataContext.Consumer>
      {(context) => {
        return (
          <div
            className="widget-title-container"
            style={{
              display: 'flex',
              justifyContent: 'space-between',

              margin:
                sectionData.resourceCode === ANALYTICS_RESOURCE.TOTAL_SAVINGS ||
                  sectionData.resourceCode === ANALYTICS_RESOURCE.CLAIMS
                  ? 32
                  : sectionData.sectionCategory === SECTION_LIST.SAVINGS
                    ? 16
                    : 32,
              marginLeft:
                sectionData.resourceCode === ANALYTICS_RESOURCE.TOTAL_SAVINGS ||
                  sectionData.resourceCode === ANALYTICS_RESOURCE.CLAIMS
                  ? 32
                  : sectionData.sectionCategory === SECTION_LIST.SAVINGS
                    ? 8
                    : 32,
              marginTop: sectionData.resourceCode === ANALYTICS_RESOURCE.TOTAL_SAVINGS ||
                sectionData.resourceCode === ANALYTICS_RESOURCE.CLAIMS
                ? 24
                : sectionData.sectionCategory === SECTION_LIST.SAVINGS
                  ? 16
                  : 24,
              marginBottom:
                sectionData.resourceCode === ANALYTICS_RESOURCE.TOTAL_SAVINGS ||
                  sectionData.resourceCode === ANALYTICS_RESOURCE.CLAIMS ? 24
                  : sectionData.sectionCategory === SECTION_LIST.SAVINGS ||
                    sectionData.resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE
                    ? 0 : sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION ? 6 : 24,
              ...(isCostSavingSections() && {width: '100%'})

            }}
          >
            <div style={{width: '90%'}}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                {
                  isCostSavingSections() && !isAccountDashboard && !isPrintPreview ?
                    (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          if (isCostSavingSections() && !isAccountDashboard && !isPrintPreview) {
                            onActionClick(WIDGET_ACTION_CODE.OPEN_SAVING_DRILL_DOWN);
                          }
                        }}
                      >

                        <div className="widget-title-style">
                          {sectionData.name}
                        </div>
                        <div style={{width: 18, height: 18, alignItems: 'center', justifyContent: 'center'}}>
                          <ArrowRightSvg customStrokeColor={Colors.Custom.Gray500} />
                        </div>
                      </div>
                    )
                    :
                    (
                      <div className="widget-title-style">
                        {sectionData.name}
                      </div>
                    )
                }

                <div className="widget-date-style" style={{marginRight: 6, display: isHideDate ? 'none' : 'block'}}>
                  {getDateStrFromFormat(context?.uiFromDate || '', 'MMM YYYY')}{' '}
                  - {getDateStrFromFormat(context.uiToDate || '', 'MMM YYYY')}
                </div>
              </div>
              <div>
                {stateData.filterValueName.length ? (
                  <div
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    {stateData.filterValueName.map((item, index) => {
                      return index < 2 ? (
                        <div key={index} style={{marginLeft: 4}}>
                          {item},{' '}
                        </div>
                      ) : (
                        <></>
                      );
                    })}
                    {stateData.filterValueName.length > 2 ? (
                      <Tooltip
                        style={{maxWidth: 320}}
                        label={stateData.filterValueString || ''}
                        placement="top"
                      >
                        <div style={{fontWeight: 500}}>More</div>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {props.isPrintPreview ? (
              !props.isPrintDownload ? (
                <div
                  style={{
                    padding: 7.5,
                    paddingRight: 10,
                    cursor: 'pointer',
                  }}
                // onClick={() => {
                //   props?.onActionClick?.(WIDGET_ACTION_CODE.HIDE_SECTION);
                // }}
                >
                  <Switch
                    // isChecked={item.isVisible}
                    style={{
                      backgroundColor:
                        !sectionData.isDisabled
                          ? Colors.primary[300]
                          : Colors.Custom
                            .Gray200,
                    }}
                    checked={!sectionData.isDisabled ? true : false}
                    onChange={(
                      value: boolean
                    ) => {
                      setStateData(prev => {
                        return {
                          ...prev,
                          isVisible: sectionData.isDisabled
                        }
                      })
                      props?.onActionClick?.(WIDGET_ACTION_CODE.HIDE_SECTION, sectionData);
                    }}
                  />
                </div>
              ) : null
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginTop: 4,
                  marginRight: 4,
                }}
              >
                {refreshButtonOnLocalEnv}
                <InfoIconView
                  sectionCode={sectionData.sectionCategory || ''}
                  employerName={context.employerName || ''}
                  date={{
                    fromDate: context.uiFromDate,
                    toDate: context.uiToDate,
                  }}
                  resourceCode={sectionData.resourceCode || ''}
                  name={sectionData.name}
                  subTitle={sectionData.subHeading}
                />
                {/* <WidgetActionView
                  resourceCode={sectionData.resourceCode}
                  sectionCode={sectionData.name}
                  onActionClick={(code: string, data: any) => {
                    onActionClick?.(code, data);
                  }}
                  widgetFilterList={stateData.selectedAppliedFilter || []}
                /> */}
                <div
                  style={{cursor: 'pointer', marginLeft: 16}}
                  onClick={() => {
                    onActionClick?.(WIDGET_ACTION_CODE.DOWNLOAD_WIDGET, {
                      resourceCode: sectionData.resourceCode,
                      sectionCode: sectionData.name,
                    });
                  }}
                >
                  <DownloadImageSvg />
                </div>
              </div>
            )}
          </div>
        );
      }}
    </DashboardDataContext.Consumer>
  )


}

export default WidgetHeader;
