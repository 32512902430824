import {Table} from 'antd';
import {View} from 'native-base';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {TableWrapper} from '../../../../common/TableWrapper';
import {ICodeGroupsTableData, ICodeGroupsTableProps} from '../interfaces';
import {getCodeGroupColumns} from './Helper';

const CodeGroupsTable = (props: ICodeGroupsTableProps) => {
  const {data, onDelete, onEdit, onPagination, pageNo, pageSize, onSort, sort} = props;
  const screenHeight = Dimensions.get('window').height;
  const onCodeGroupTableActionPerformed = (
    actionCode: string,
    rowRecord: ICodeGroupsTableData
  ): any => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        onEdit(rowRecord);
        break;

      case COMMON_ACTION_CODES.DELETE:
        onDelete(rowRecord);
        break;

      default:
        break;
    }
  };
  return (
    <View flex={1}>
      <TableWrapper
        pagination={{
          pageSize: pageSize,
          current: pageNo,
          total: props.total,
          onChange(page, pageSize) {
            if (props.onPagination) {
              props.onPagination(page, pageSize);
            }
          },
        }}
        customStyle={{zIndex: 999}}
      >
        <Table
          style={{
            backgroundColor: '#fff',
          }}
          onChange={(pagination, filter, sorter: any) => {
            if(sorter) {
              onSort({columnKey: sorter.columnKey, order: sorter.order})
            }
          }}
          scroll={{x: 700, y: screenHeight - 260}}
          columns={getCodeGroupColumns(onCodeGroupTableActionPerformed, sort)}
          dataSource={data}
          pagination={false}
          rowKey={(record: ICodeGroupsTableData) => record.id}
          onRow={(record: ICodeGroupsTableData) => {
            return {
              onClick: () => {
                onCodeGroupTableActionPerformed(COMMON_ACTION_CODES.EDIT, record)
              },
            };
          }}
        />
      </TableWrapper>
    </View>
  );
};

export default CodeGroupsTable;
