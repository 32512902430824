import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  subContainer: {
    backgroundColor: '#fff',
    marginTop: 15,
  },
  rowStyle: {
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    paddingBottom: 5,
    // backgroundColor: 'yellow',
    maxWidth: 320,
  },
  dataText: {
    // marginLeft: 12,
  },
  labelText: {
    // fontWeight: '600',
  },
});
