// import { styles } from '../IntegrationsStyles';
import {useMutation, useQuery} from '@apollo/client';
import {Select as MultiSelect} from 'antd';
import {Box, Spinner, Text, useToast, View, VStack} from 'native-base';
import {useContext, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../../constants';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {ILoginUserData} from '../../../../../Interfaces/CommonInterfaces';
import AgentBotInboxQueries from '../../../../../services/AgentBotInbox/AgentBotInboxQueries';
import InboxQueries from '../../../../../services/Inbox/InboxQueries';
import {Colors} from '../../../../../styles';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {AgentBotDropDown} from '../Helper/AgentBotDropDown';
import {attachAgentBotToInbox} from '../Helper/AgentBotInboxApi';
import {getUpdatedAgents} from '../Helper/formatIntegrationsData';
import {spinnerStype} from '../IntegrationsStyles';
import {IIntegrationEditCommonCollaborators} from '../interfaces';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
const {Option} = MultiSelect;

export const IntegrationEditCommonCollaborators = (
  props: IIntegrationEditCommonCollaborators
) => {
  const [agentBotId, setAgentBotId] = useState<number | undefined>(undefined);
  const {height} = Dimensions.get('window');
  const commonData = useContext(CommonDataContext);
  const toast = useToast();
  const userData = commonData.userData || ({} as ILoginUserData);
  const accountId = userData.account_id;
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const agentNames: any = [];
  props.assignedAgentList.forEach((item: any) =>
    agentNames.push(item.agentName)
  );
  useQuery(AgentBotInboxQueries.GetInboxAgentBots, {
    variables: {accountId: accountId, inboxId: props.inboxId},
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    onError: (error) => {

      alert(error);
    },
    onCompleted: (response: any) => {
      if (response.agentBotInboxes && response.agentBotInboxes.length) {
        setAgentBotId(response.agentBotInboxes[0]?.agentBotId);
      }
    },
  });
  const [finalSelectedAgents, setFinalSelectedAgents] =
    useState<any>(agentNames);

  const onSelectAgents = (value: string[]) => {
    setFinalSelectedAgents(value);
  };

  const [deleteAgents] = useMutation(InboxQueries.DeleteAgents);
  const [addAgent] = useMutation(InboxQueries.AddAgent);

  const handleUpdateAgents = () => {
    setLoading(true);
    const modifiedAgents = getUpdatedAgents(
      finalSelectedAgents,
      props.assignedAgentList,
      props.agentList
    );
    const agentsToAdd: any = [];

    deleteAgents({
      variables: {
        agentIdToDelete: modifiedAgents.agentIdToDelete,
        inboxId: props.inboxId,
      },
    }).then((res) => {});

    modifiedAgents.agentIdToAdd.forEach((agentId: any) => {
      agentsToAdd.push({userId: agentId, inboxId: props.inboxId});
    });

    addAgent({variables: {agentsToAdd: agentsToAdd}}).then((res) => {
      if (res.data && res.data.createInboxMembers) setLoading(false);
      showToast(toast, 'Agents Updated', ToastType.success);
    });
    if (props.handleUpdateAgentCallback) {
      props.handleUpdateAgentCallback();
    }
  };

  return (
    <>
      <View height={height - 200}>
        <VStack paddingLeft={10} paddingTop={10}>
          <VStack>
            <Text size={'mdNormal'} marginBottom={1} marginLeft={1}>
              Add or remove agents from this inbox
            </Text>
            <MultiSelect
              mode="multiple"
              allowClear
              defaultValue={agentNames}
              style={reactStyles.multiSelectStyle}
              placeholder="Pick agents for the inbox"
              onChange={onSelectAgents}
            >
              {props.agentList.map((agentRecord: any) => {
                return (
                  <Option
                    key={agentRecord.agentId}
                    value={agentRecord.agentName}
                  >
                    {agentRecord.agentName}
                  </Option>
                );
              })}
            </MultiSelect>
          </VStack>
          <VStack marginTop={10}>
            <Text size={'mdNormal'} marginBottom={1} marginLeft={1}>
              Select Agent Bot
            </Text>
            <AgentBotDropDown
              agentBotId={agentBotId}
              onChange={(newAgentBotId: number) => {
                setAgentBotId(newAgentBotId);
              }}
            ></AgentBotDropDown>
          </VStack>
          <VStack marginTop={4}>

            <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              attachAgentBotToInbox(props.inboxId, agentBotId, accountId);
                handleUpdateAgents();
            },
            style: {maxWidth: 150},
          }}
          customProps={{
            btnText: intl.formatMessage({id: 'update'}),
            withRightBorder: false,
          }}
        ></FoldButton>
          </VStack>
        </VStack>
      </View>
      {loading ? (
        <Spinner size="lg" style={spinnerStype.spinnerStyle} />
      ) : (
        <></>
      )}
    </>
  );
};

const reactStyles: Record<string, React.CSSProperties> = {
  multiSelectStyle: {
    width: '70%'
  }
};
