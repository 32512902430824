import { Badge, HStack, Icon, Pressable, Spacer, Text, VStack } from "native-base";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { BUTTON_TYPE, DISPLAY_DATE_FORMAT, MLOV_CATEGORY } from "../../../../../constants";
import { CommonDataContext } from "../../../../../context/CommonDataContext";
import { Colors } from "../../../../../styles";
import { getDateStrFromFormat } from "../../../../../utils/DateUtils";
import { getMlovListFromCategory, getMlovValueFromId } from "../../../../../utils/mlovUtils";
import ModalActionBtn from "../../../../common/ModalActionBtn/ModalActionBtn";
import AntIcon from 'react-native-vector-icons/AntDesign';
import { FoldButton } from "../../../../CommonComponents/FoldButton/FoldButton";

const LinkToLatestAppointmentBanner = (props: {
    onClick: () => void;
    name?: string;
    reasonForVisit?: string;
    date?: string;
    statusId?: string;
    location?: string;
  }) => {
    const intl = useIntl();
    const {onClick, name, statusId, date, location} = props;
    const commonContextData = useContext(CommonDataContext);
    const appointmentStatusList =
      getMlovListFromCategory(
        commonContextData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.APPOINTMENT_STATUS
      ) || [];
    return (
      <HStack
        m={2}
        px={4}
        py={4}
        borderRadius={8}
        backgroundColor={Colors.Custom.Gray50}
        alignItems="center"
      >
        <VStack>
          <HStack alignItems={'center'}>
            <Text fontWeight={'bold'} fontSize={16}>
              {name}
            </Text>
            <Badge
              alignItems={'center'}
              rounded="3xl"
              _text={{fontSize: '2xs', color: Colors.Custom.Blue700}}
              ml={2}
              backgroundColor={Colors.Custom.Blue50}
            >
              {getMlovValueFromId(appointmentStatusList, statusId || '')}
            </Badge>
          </HStack>
          <HStack>
            <Text fontSize={12} color={'gray.500'}>
              {date && getDateStrFromFormat(date, DISPLAY_DATE_FORMAT)}
              {location && ' • ' + location}
            </Text>
          </HStack>
        </VStack>
        <Spacer />
        {/* <ModalActionBtn
          isDisabled={false}
          btnText={intl.formatMessage({id: 'addNote'})}
          onClickAction={() => onClick()}
          btnStype={BUTTON_TYPE.PRIMARY}
          leftIcon={
            <Icon
              as={AntIcon}
              name={'plus'}
              size="4"
              color={Colors.secondary['800']}
            />
          }
        /> */}
        <FoldButton
          customProps={{
            btnText: intl.formatMessage({id: 'addVisitNote'}),
          }}
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            backgroundColor: Colors.Custom.BackgroundColor,
            leftIcon: (
              <Icon
                as={AntIcon}
                name={'plus'}
                size="4"
                color={Colors.Custom.mainPrimaryPurple}
              />
            ),
            onPress: () => onClick(),
          }}
        />
      </HStack>
    );
  };
  export default LinkToLatestAppointmentBanner;
