import {HStack, Text, View, VStack} from 'native-base';
import {CRM_URLs} from '../../../../../../constants/BaseUrlConst';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {styles} from '../../IntegrationsStyles';
import {IIntegrationsScreenFiveProps} from '../../interfaces';

export const IntegrationScreenFive = (props: IIntegrationsScreenFiveProps) => {
  return (
    <VStack>
      <HStack style={styles.titleBarEnd}>
        {/* <Button
          size="sm"
          variant="solid"
          colorScheme="dark"
          onPress={props.onCancelClick}
        >
          <DisplayText
            textLocalId="close"
            extraStyles={{color: Colors.Custom.FontColorWhite}}
          />
        </Button> */}
      </HStack>
      <VStack paddingLeft={3}>
        <VStack width={'60%'}>
          <Text size={'lgNormal'}>Your Inbox is Ready!</Text>
          <DisplayText
            textLocalId="inboxScripSuccessMessage"
            size={'smLight'}
            extraStyles={{marginBottom: 3}}
          />
          <View
            background={'gray.100'}
            paddingTop={4}
            paddingBottom={4}
            borderRadius={12}
            paddingRight={5}
          >
            <Text size={'mdLight'} textAlign="start">
              {`        <script>
            (function(d,t) {
              var BASE_URL="${CRM_URLs.CHATWOOT}";
              var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
              g.src=BASE_URL+"/packs/js/sdk.js";
              g.defer = true;
              g.async = true;
              s.parentNode.insertBefore(g,s);
              g.onload=function(){
                window.chatwootSDK.run({
                  websiteToken: '${props.webToken}',
                  baseUrl: BASE_URL
                })
              }
            })(document,"script");
          </script>`}
            </Text>
          </View>
        </VStack>
      </VStack>
    </VStack>
  );
};
