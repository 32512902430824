import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  topbarContainer: {
    alignItems: 'center',
    margin: 5,
  },
  titleText: {},
  inputStyle: {
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  dropdownStyle: {
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  emailContainer: {
    // maxWidth: 400,
  },
  switchView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  mainContainer: {
    margin: 10,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 1,
    borderRadius: 10,
    overflow: 'hidden',
  },
});
