import React from 'react';
import {EmailComponentsTableActionsProps} from '../interfaces';
import {HStack, IconButton, Tooltip} from 'native-base';
import {Colors} from '../../../../../styles';
import Icon from 'react-native-vector-icons/AntDesign';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import FeatherIcon from 'react-native-vector-icons/Feather';

const EmailComponentsTableActions = (
  props: EmailComponentsTableActionsProps
) => {
  const {record, actionFn} = props;
  return (
    <HStack>
      <Tooltip label="Update" placement="top">
        <IconButton
          onPress={() => actionFn(COMMON_ACTION_CODES.EDIT, record)}
          color={Colors.Custom.Gray600}
          icon={<FeatherIcon size={17} name="edit-2" />}
        />
      </Tooltip>

      <Tooltip label="Delete" placement="top">
        <IconButton
          onPress={() => actionFn(COMMON_ACTION_CODES.DELETE, record)}
          color={Colors.Custom.Gray600}
          icon={<Icon size={17} name="delete" />}
        />
      </Tooltip>
    </HStack>
  );
};

export default EmailComponentsTableActions;
