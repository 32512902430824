import {HStack, Pressable, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../styles';
import {DisplayText} from '../../DisplayText/DisplayText';

export interface ITypeData {
  id: number;
  code: string;
  displayNameId: string;
}

export const CONTACT_TYPE_FILTER_LIST: ITypeData[] = [
  {
    id: 1,
    code: 'PATIENT',
    displayNameId: 'Patient',
  },
  {
    id: 2,
    code: 'PROSPECT',
    displayNameId: 'Prospect',
  },
];

export interface IContactTypeFilterList {
  onTypeSelected: (typeData: ITypeData) => void;
}

const ContactTypeFilterList = (props: IContactTypeFilterList) => {
  const {onTypeSelected} = props;
  const [contactTypeList, setContactTypeList] = useState<ITypeData[]>();

  useEffect(() => {
    setContactTypeList(CONTACT_TYPE_FILTER_LIST);
  }, []);
  return (
    <>
      <DisplayText
        size={'xsMedium'}
        extraStyles={{fontSize: 10, color: Colors.Custom.Gray500}}
        textLocalId="Type"
      />
      <VStack>
        {contactTypeList?.map((item) => {
          return (
            <Pressable
              key={item.id}
              onPress={() => {
                onTypeSelected(item);
              }}
            >
              <HStack
                alignItems={'center'}
                flex={1}
                style={{marginVertical: 8}}
              >
                <Feather
                  name={'user'}
                  color={Colors.primary['600']}
                  size={20}
                />

                <DisplayText
                  size={'xsMedium'}
                  extraStyles={{color: Colors.Custom.Gray700, marginLeft: 10}}
                  textLocalId={item.displayNameId}
                />
              </HStack>
            </Pressable>
          );
        })}
      </VStack>
    </>
  );
};

export default ContactTypeFilterList;
