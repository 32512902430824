import {IProductTabBarMenu} from './interface';

export const PRODUCT_SIDEBAR_CODE = {
  ORDERS: 'ORDERS',
  CATEGORY: 'CATEGORY',
  PRODUCT: 'PRODUCT',
};

export const PRODUCT_SIDEBAR_LIST: IProductTabBarMenu[] = [
  // {
  //   tabCode: PRODUCT_SIDEBAR_CODE.ORDERS,
  //   title: 'Orders',
  // },
  {
    tabCode: PRODUCT_SIDEBAR_CODE.CATEGORY,
    title: 'Category',
  },
  {
    tabCode: PRODUCT_SIDEBAR_CODE.PRODUCT,
    title: 'Product',
  },
];
