import { Text, View } from 'native-base';
import { styles } from "./style";
import { Colors } from '../../../../styles/Colors';


const ScoreGroupCard = (props: {
    header: string,
    leftHeader: string,
    leftValue: string | number,
    rightHeader: string,
    rightValue: string
}) => {

    const { header, leftHeader, leftValue, rightHeader, rightValue } = props;
    return (
        <View style={styles.viewStyle2}>
            <View style={styles.viewStyle3}>
                <Text size={'smSemiBold'} fontSize={14}>
                    {header}
                </Text>
            </View>
            <View flexDirection={'row'} justifyContent={'space-between'} >
                {leftHeader && <View style={styles.width50} flexDirection={'column'}>
                    <Text size={'mdSemiBold'} color={Colors.Custom.IATextColor} fontSize={14}>
                        {leftHeader}
                    </Text>
                    <Text size={'mdBold'} fontSize={18}>
                        {leftValue}
                    </Text>
                </View>}
                {
                    rightHeader && <View style={styles.width50} flexDirection={'column'}>
                        <Text size={'mdSemiBold'} color={Colors.Custom.IATextColor} fontSize={14}>
                            {rightHeader}
                        </Text>
                        <Text size={'mdBold'} fontSize={18}>
                            {rightValue}
                        </Text>
                    </View>
                }

            </View>

        </View>
    )
}

export default ScoreGroupCard;