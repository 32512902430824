import { useMutation } from '@apollo/client';
import { Button, Modal, Text } from 'native-base';
import React, { useContext, useState } from 'react';
import { BUTTON_TYPE, PERSON_TYPES } from '../../../constants';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { ConvertContacts } from '../../../services';
import {Colors} from '../../../styles';
import { getContactTypeId, getMlovId } from '../../../utils/mlovUtils';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import { getConvertStrings } from '../../RightSideContainer/Contacts/ContactsUtils';
import { DisplayText } from '../DisplayText/DisplayText';
import ModalActionBtn from '../ModalActionBtn/ModalActionBtn';

const ConvertContactsView = (props: any) => {
  const [showModal, setShowModal] = useState(true);
  const mlovData = useContext(CommonDataContext);
  const isUpdate = !!props?.contactdata.contactType;
  const convertString = getConvertStrings(props?.personType, isUpdate);
  const leadMlovId = getContactTypeId('LEAD');
  const CustomerMlovId = getContactTypeId('CUSTOMER');
  // const VisitorMlovId = getMlovId(mlovData.MLOV, 'CustomerType', 'VISITOR');
  const contactId = props?.contactdata?.id;
  const onCloseModal = () => {
    setShowModal(false);
    props.onFormActionPerformed(COMMON_ACTION_CODES.CANCEL, {});
  };

  const [CreateContactType] = useMutation(ConvertContacts.CreateContactType);
  const [UpdateContactType] = useMutation(ConvertContacts.UpdateContactType);

  const onConvertAction = () => {
    const promiseList = [];
    if (isUpdate) {
      let typeId = '';
      if (PERSON_TYPES.LEAD === props?.personType) {
        typeId = CustomerMlovId;
      }
      if (PERSON_TYPES.CUSTOMER === props?.personType) {
        typeId = leadMlovId;
      }
      promiseList.push(
        UpdateContactType({
          variables: {
            contactId: contactId,
            typeId: typeId,
          },
        })
      );
    } else {
      let typeId = '';
      if (PERSON_TYPES.LEAD === props?.personType) {
        typeId = CustomerMlovId;
      }
      if (PERSON_TYPES.CUSTOMER === props?.personType) {
        typeId = leadMlovId;
      }
      promiseList.push(
        CreateContactType({
          variables: {
            contactId: contactId,
            typeId: typeId,
          },
        })
      );
    }

    Promise.all(promiseList).then(() => {
      onCloseModal();
    });
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        onClose={() => {
          onCloseModal();
        }}
        size="lg"
      >
        <Modal.Content maxWidth="350">
          <Modal.CloseButton />
          <Modal.Header>
            <DisplayText
              textType={'Heading'}
              textLocalId={convertString.heading}
            />
          </Modal.Header>
          <Modal.Body>
            <Text size={'smMedium'}>{props.contactdata.name}</Text>
            <DisplayText textLocalId={convertString.title} />
          </Modal.Body>
          <Modal.Footer>
            <Button.Group
              mx={{
                base: 'auto',
                md: 0,
              }}
            >
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    onCloseModal();
                  },
                }}
                customProps={{
                  btnText: (
                    <DisplayText
                      textLocalId={'cancel'}
                      size={'smBold'}
                      extraStyles={{
                        color: Colors.Custom.mainSecondaryBrown,
                      }}
                    />
                  ),
                  withRightBorder: false,
                }}
              ></FoldButton>
               <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    onConvertAction();
                  },
                }}
                customProps={{
                  btnText: (
                    <DisplayText
                      textLocalId={'Convert'}
                      size={'smBold'}
                      extraStyles={{
                        color: Colors.Custom.mainPrimaryPurple,
                      }}
                    />
                  ),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ConvertContactsView;
