import {Pressable, Text} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {TABLE_TOP_BAR_ACTION_CODES} from '../../../../common/TableTopBar';
import {ILocationData} from './interface';
import {StyleSheet} from 'react-native';

export const accountLocationColumn = (
  actionPerformedByCode: (code: string, data?: any) => void,
  isMsoEnabled?: boolean
) => {
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (name: string, record: ILocationData) => {
        return (
          <Text size="smMedium" color={Colors.FoldPixel.GRAY300}>
            {name}
          </Text>
        );
      },
    },
    ...(isMsoEnabled
      ? [
          {
            title: (
              <DisplayText
                size={'xsMedium'}
                extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
                textLocalId="eHRInstance"
              />
            ),
            dataIndex: 'groupName',
            key: 'groupName',
            width: '30%',
            render: (name: string, record: ILocationData) => {
              return (
                <Text size="smMedium" color={Colors.FoldPixel.GRAY300}>
                  {name}
                </Text>
              );
            },
          },
        ]
      : []),
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="address"
        />
      ),
      dataIndex: 'address',
      key: 'address',
      width: '30%',
      render: (name: string, record: ILocationData) => {
        return (
          <Text size="smMedium" color={Colors.FoldPixel.GRAY300}>
            {record.address1 || ''} {record.address2 || ''}
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="zipcode"
        />
      ),
      dataIndex: 'zipcode',
      key: 'zipcode',
      width: '10%',
      render: (zipcode: string) => {
        return (
          <Text size="smMedium" color={Colors.FoldPixel.GRAY300}>
            {zipcode || ''}
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="action"
        />
      ),
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (communicationNumber: string, record: any) => {
        return (
          <Pressable
            onPress={() => {
              actionPerformedByCode(
                TABLE_TOP_BAR_ACTION_CODES.EDIT_LOCATION,
                record.rawData
              );
            }}
            style={styles.editButton}
          >
            <Feather name="edit-2" size={15} color={Colors.Custom.Gray500} />
          </Pressable>
        );
      },
    },
  ];
};

const styles = StyleSheet.create({
  editButton: {
    paddingBottom: 8,
    paddingTop: 12,
    paddingLeft: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
