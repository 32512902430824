import { useContext } from 'react';
import { CRM_URLs } from '../../../constants/BaseUrlConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { IMlov } from '../../../Interfaces';
import BaseService from '../../../services/CommonService/BaseService';
import { getMlovIdFromCode } from '../../../utils/mlovUtils';
import {IUser} from '../Contacts/TeamMembers/interfaces';
import {ICTMlov} from './../../../utils/interfaces';
import {ASSIGN_TYPE} from './CloudTelePhoneyConst';
import {IFLOWS, IVirtualPhoneNumber} from './interfaces';

export const getFormattedData = (tableData: any) => {
  const tempDataList = [].concat(tableData);
  const returnFormattedData: any = [];

  tempDataList.forEach((data: any) => {
    const tempdata = {
      number: data.virtualPhoneNumber || '',
      userUUID: data?.userUuid || '',
      userRole: getUserRoleForAccount(data),
      status: true,
      name: data || '',
      rawData: data || '',
    };
    returnFormattedData.push(tempdata);
  });

  return returnFormattedData;
};

export const getVirtualNumberFormattedList = (
  tableData: IVirtualPhoneNumber[],
  assigneesType: ICTMlov[],
  userList: IUser[],
  ivrFlowList?: any,
  allDefaultForAccount?: any,
  CommunicationType?: any
) => {
  const tempDataList = [...tableData];
  const assigneesTypeList = [...assigneesType];
  const returnFormattedData: any = [];
  const callTypeId = CommunicationType?.find(
    (item: any) => item.code === 'CALL'
  );
  const smsTypeId = CommunicationType?.find((item: any) => item.code === 'SMS');

  tempDataList.forEach((singleVirtualPhoneData) => {
    let flowTypeIVRName: any = '';
    const tempSingleAssignee: ICTMlov =
      assigneesTypeList.find((item: ICTMlov) => {
        return item.id == singleVirtualPhoneData.assigneeTypeId;
      }) || ({} as any);
    const assigneeName = getAssigneeNameFromAssigneeIdList(
      singleVirtualPhoneData,
      userList,
      tempSingleAssignee
    );
    if (singleVirtualPhoneData?.flowTypeData?.code == 'IVR') {
      flowTypeIVRName = ivrFlowList?.find((item: any) => {
        return item?.uuid == singleVirtualPhoneData?.flowType;
      });
    }
    const defaultNumberData = allDefaultForAccount.filter((number: any) => {
      return number?.virtualPhoneNumberAssigneeId === singleVirtualPhoneData.id;
    });

    let isDefaultCalling = false;
    let isDefaultSMS = false;
    if (defaultNumberData?.length) {
      defaultNumberData.forEach((number: any) => {
        if (number?.defaultCommunicationTypeId === callTypeId?.id) {
          isDefaultCalling = true;
        } else if (number?.defaultCommunicationTypeId === smsTypeId?.id) {
          isDefaultSMS = true;
        }
      });
    }
    const tempdata = {
      id: singleVirtualPhoneData.id,
      phoneNumber: {
        phoneNumber: singleVirtualPhoneData.virtualPhoneNumber,
        name: assigneeName,
      },
      userUuid: singleVirtualPhoneData.assigneeId || '',
      assignedType: tempSingleAssignee.value,
      assignedTypeCode: tempSingleAssignee.code,
      assignTypeUuid: tempSingleAssignee.id,
      flowType: {
        flowTypeCode: singleVirtualPhoneData?.flowTypeData?.code || '',
        value: singleVirtualPhoneData?.flowType || '',
        flowTypeUuid: singleVirtualPhoneData?.flowTypeId || '',
        flowTypeIVRName: flowTypeIVRName?.name || '',
      },
      activityOn: '2022-07-20, 12:45 PM',
      rawData: singleVirtualPhoneData || '',
      isDefaultCalling: isDefaultCalling,
      isDefaultSMS: isDefaultSMS,
      extraData: defaultNumberData?.length ? defaultNumberData : [],
      holidayPhoneTreeId: singleVirtualPhoneData?.holidayPhoneTreeId || ''
    };
    returnFormattedData.push(tempdata);
  });

  return returnFormattedData;
};

export const getFormattedIvrData = (data: IFLOWS[]) => {
  const tempDataList: IFLOWS[] = [...data];
  const returnFormattedData: any = [];
  tempDataList.forEach((flowItem: IFLOWS) => {
    const tempdata = {
      uuid: flowItem.id,
      name: flowItem.friendlyName,
    };
    returnFormattedData.push(tempdata);
  });
  return returnFormattedData;
};

export const getIvrListByType = (data: IFLOWS[], ivrList: IMlov[]) => {
  const inbound: any = [];
  const inboundIvrList: any = [];
  const outOfOfficeIvrList: any = [];
  const holidayHoursIvrList: any = [];
  const practiceOutOfOfficeIvrList: any = [];
  const inboundIvrTypeId = getMlovIdFromCode(ivrList, 'INCOMING_CALLS');
  const outOfOfficeIvrTypeId = getMlovIdFromCode(ivrList, 'OUT_OF_OFFICE');
  const holidayHoursIvrTypeId = getMlovIdFromCode(ivrList, 'HOLIDAY_HOURS');
  data.forEach((flowItem: IFLOWS) => {
    const tempdata = {
      uuid: flowItem.id,
      name: flowItem.friendlyName,
      ivrTypeId: flowItem.ivrUsageTypeId,
    };
    if (tempdata.ivrTypeId === outOfOfficeIvrTypeId) {
      outOfOfficeIvrList.push(tempdata);
    } else if (tempdata.ivrTypeId === inboundIvrTypeId) {
      inboundIvrList.push(tempdata);
    } else if (tempdata.ivrTypeId === holidayHoursIvrTypeId) {
      holidayHoursIvrList.push(tempdata);
    }
  });
  const ivrListByType = {
    inboundIvrList: inboundIvrList,
    outOfOfficeIvrList: outOfOfficeIvrList,
    holidayHoursIvrList: holidayHoursIvrList,
  }
  return ivrListByType;
};

export const getAssigneeNameFromAssigneeIdList = (
  phoneData: IVirtualPhoneNumber,
  userList: IUser[],
  singleAssigneeMlovData: ICTMlov
) => {
  let assignUserText = phoneData?.assignee || '';
  if (
    singleAssigneeMlovData.code === ASSIGN_TYPE.USER &&
    phoneData.assigneeId
  ) {
    const assigneeUserData = getUserDataForAssignee(
      userList,
      phoneData.assigneeId
    );
    if (assigneeUserData?.name) {
      assignUserText = assigneeUserData?.name;
    }
  }
  return assignUserText;
};

const getUserDataForAssignee = (userList: IUser[], assigneeId: string) => {
  return userList.find((singleUser) => {
    return singleUser.uuid === assigneeId;
  });
};

export const getUserRoleForAccount = (singleAccountData: any) => {
  let userRoleStr = '';
  if (singleAccountData?.user?.userRoles?.length) {
    singleAccountData?.user?.userRoles?.forEach((userRoleData: any) => {
      if (userRoleData?.userRole?.userRole?.value) {
        userRoleStr =
          userRoleStr + userRoleData?.userRole?.userRole?.value + ', ';
      }
    });
  }
  return userRoleStr.slice(0, -2);
};

export const getStdCodeAndNumber = (phoneNumber: string) => {
  let stdCode = '';
  let number = '';
  if ( phoneNumber && phoneNumber.includes('+1')) {
    stdCode = phoneNumber.substring(0, 1);
    number = phoneNumber.substring(2);
  } else if (phoneNumber && phoneNumber.includes('+91')) {
    stdCode = phoneNumber.substring(0, 3);
    number = phoneNumber.substring(3);
  }
  return {stdCode, number};
};

export const createSmsInbox = async (args: {
  inboxName: string;
  phoneNumber: string;
  accountId: number;
}) => {
  const {inboxName, phoneNumber, accountId} = args;
  const baseService = BaseService.getSharedInstance().axios;
  const createSmsInboxUrl = `/cloud-telephony/inbox/twillio`;
  const bodyData = {
    twilio_channel: {
      name: inboxName,
      medium: 'sms',
      phone_number: phoneNumber,
    },
    smsCallBackUrl: CRM_URLs.CHATWOOT + '/twilio/callback',
    accountId: accountId,
  };
  return baseService.post(createSmsInboxUrl, JSON.stringify(bodyData));
};

export const createInboxUser = async (args: {
  userId: string | number;
  inboxId: string | number;
  createInboxMembers: (args: any) => Promise<any>;
}) => {
  const {inboxId, userId, createInboxMembers} = args;
  const objects = [{inboxId, userId}];
  await createInboxMembers({
    variables: {
      objects,
    },
  });
};
