import {Dropdown, Menu} from 'antd';
import {HStack, Icon, useMediaQuery, View} from 'native-base';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../constants';
import {Colors} from '../../../../styles';
import {
  isEmployerRole,
} from '../../../../utils/commonUtils';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import PlusIcon from '../../Svg/PlusSvg';
import { StyleSheet } from 'react-native';
import React from 'react';

export interface IDropdownButtonProps {
  title: string;
  showBtnTextForSmallScreen?: boolean;
  onClick?: (key: string) => void;
  isDisabled?: boolean;
}

export enum addTaskButtonKeys {
  addTask = 'AddTask',
  addTaskPool = 'AddTaskPool',
}

const CustomAddTaskButton = (props: IDropdownButtonProps) => {
  const intl = useIntl();
  const addNewMenu = React.useCallback(() => {
    return (
      <Menu
        items={[
          {
            key: addTaskButtonKeys.addTask,
            label: intl.formatMessage({id: 'addTask'}),
          },
          {
            key: addTaskButtonKeys.addTaskPool,
            label: intl.formatMessage({id: 'addTaskPool'}),
          },
        ]}
        onClick={(data: any) => {
          if (props.onClick && typeof props.onClick === 'function') {
            props.onClick(data.key);
          }
        }}
      />
    );
  }, [props.onClick]);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const isEmployer = isEmployerRole();

  const handleClick = React.useCallback(() => {
    if (props.onClick && typeof props.onClick === 'function') {
      props.onClick(addTaskButtonKeys.addTask);
    }
  }, [props.onClick]);

  const customProps = React.useMemo(() => {
    return {
      btnText:
        (!isSmallScreen || props.showBtnTextForSmallScreen) && props.title
          ? props.title
          : '',
      withRightBorder: true,
      isDisableOnPress: true,
    };
  }, [
    props.title,
    props.showBtnTextForSmallScreen,
    isSmallScreen,
    props.isDisabled,
  ]);

  return (
    <View style={styles.container}>
      <FoldButton
        nativeProps={{
          isDisabled: props.isDisabled,
          variant: BUTTON_TYPE.PRIMARY,
          onPress: handleClick,
          leftIcon: (
            <PlusIcon defaultColor={props.isDisabled ? Colors.Custom.Gray300 : '#825AC7'}/>
          ),
          rightIcon: (
            <>
              {!isEmployer ? (
                <HStack>
                  <Dropdown
                    overlay={!props.isDisabled ? addNewMenu : <></>}
                    placement="bottomRight"
                    overlayStyle={{width: 150}}
                  >
                    <Icon
                      onPress={() => {}}
                      color={props.isDisabled ? Colors.Custom.Gray300 : '#825AC7'}
                      as={AntIcon}
                      name={'down'}
                      size="4"
                    />
                  </Dropdown>
                </HStack>
              ) : (
                <></>
              )}
            </>
          ),
          style: styles.foldButtonStyle
        }}
        customProps={customProps}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 5,
    height: 36,
    marginTop: 1,
    paddingTop: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
  foldButtonStyle: {
    minWidth: 'auto',
  },
});

export default CustomAddTaskButton;
