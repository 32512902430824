import {Dropdown, Menu} from 'antd';
import {Button, HStack, Icon, Text, View} from 'native-base';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {BUTTON_TYPE} from '../../../constants/StringConst';
import {Colors} from '../../../styles';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {CalendarTopBarActions} from '../CalendarWidget/CalendarTopBar/CalendarTopBar';
import {MonthCalender} from '../Svg/DateCalendersSvg';
import CalendarSvg from '../Svg/SideMenu/CalendarSvg';
import { isAppointmentTypesGroupEnable } from '../../../utils/commonUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { useContext } from 'react';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useUserSetting } from '../../CustomHooks/useUserSetting';

export interface IDropdownButtonProps {
  title: string;
  onClick?: (action: CalendarTopBarActions) => void;
}

const CustomAppointmentScheduleButton = (props: IDropdownButtonProps) => {
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const userSettings = commonData.userSettings;
  const allowAppointmentTypesGroup = isAppointmentTypesGroupEnable(userSettings);
  const disableBlockTime = useUserSetting('disable_block_time_scheduling');
  const menuItem:ItemType[] = [
    {
      key: CalendarTopBarActions.bookAppointment,
      label: intl.formatMessage({id: 'singleAppointment'}),
    },
  ];

  if (!disableBlockTime) {
    menuItem.push({
      type: 'divider',
    });
    menuItem.push({
      key: CalendarTopBarActions.blockTime,
      label: intl.formatMessage({id: 'blockOutTime'}),
    });
  }

  if (allowAppointmentTypesGroup) {
    menuItem.push({
      type:'divider'
    });
    menuItem.push({
      key: CalendarTopBarActions.groupSessionAppointment,
      label: intl.formatMessage({id: 'groupAppointment'}),
    });
  }

  const addNewMenu = () => {
    return (
      <Menu
        items={menuItem}
        onClick={(data: any) => {
          if(props.onClick && typeof props.onClick === 'function') {
            props.onClick(data.key);
          }
        }}
      />
    );
  };

  return (
    <View
      margin={1}
      style={{
        height: 36,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
      }}
    >
      <FoldButton
        nativeProps={{
          variant: BUTTON_TYPE.PRIMARY,
          onPress: () => {
            if (props.onClick && typeof props.onClick === 'function') {
              props.onClick(CalendarTopBarActions.bookAppointment)
            }
          },
          rightIcon: (
            <Dropdown
              overlay={addNewMenu}
              placement="bottomRight"
              overlayStyle={{width: 180}}
            >
              <Icon color={'#825AC7'} as={AntIcon} name={'down'} size="4" />
            </Dropdown>
          ),
          style: {minWidth: 'auto'}
        }}
        customProps={{
          btnText: props.title,
          withRightBorder: true,
          isDisableOnPress: true
        }}
      />
    </View>
  );
};

export default CustomAppointmentScheduleButton;
