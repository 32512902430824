import React from 'react';
import {View, Text} from 'native-base';
import {Colors} from '../../styles/Colors';
import Feather from 'react-native-vector-icons/Feather';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { Tooltip } from 'antd';
interface IFormResponseAudit {
  successCount: number;
  submittedCount: number;
  draftCount: number;
  pendingCount: number;
}

const FormResponseAudit = (props: IFormResponseAudit) => {
  return (
    <View
      flexDirection="row"
      style={{
        backgroundColor: Colors.Custom.Gray100,
        borderRadius: 20,
        paddingLeft: 12,
        paddingRight: 0,
        height: 30,
      }}
    >
        <Tooltip title={'Sent: ' + props?.successCount} placement="top">
          <View
            flexDirection="row"
            alignItems={'center'}
            style={{
              paddingVertical: 8,
              borderRightColor: Colors.Custom.Gray200,
              borderRightWidth: 1,
              width: 70,
            }}
          >
            <Feather
              size={16}
              name="send"
              color={Colors.Custom.Gray400}
              style={{
                marginHorizontal: 6,
              }}
            />

            <Text
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              whiteSpace={'nowrap'}
              style={{
                marginRight: 3,
              }}
              size={'smMedium'}
              color={Colors.Custom.Gray500}
            >
              {props?.successCount}
            </Text>
          </View>
        </Tooltip>
        <Tooltip title={`Responded: ` + props?.submittedCount} placement="top">
          <View
            flexDirection="row"
            alignItems={'center'}
            style={{
              paddingVertical: 8,
              borderRightColor: Colors.Custom.Gray200,
              borderRightWidth: 1,
              width: 70,
            }}
          >
            <Ionicons
              size={20}
              name="checkmark-done-outline"
              color={Colors.Custom.SuccessColor}
              style={{
                marginHorizontal: 6,
              }}
            />

            <Text
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              whiteSpace={'nowrap'}
              style={{
                marginRight: 3,
              }}
              size={'smMedium'}
              color={Colors.Custom.Gray500}
            >
              {props?.submittedCount}
            </Text>
          </View>
        </Tooltip>
        <Tooltip title={'In Progress: ' + props?.draftCount} placement="top">
          <View
            flexDirection="row"
            alignItems={'center'}
            style={{
              paddingVertical: 8,
              borderRightColor: Colors.Custom.Gray200,
              borderRightWidth: 1,
              width: 70,
            }}
          >
            <Feather
              size={16}
              name="clock"
              color={Colors.warning[400]}
              style={{
                marginHorizontal: 6,
              }}
            />
            <Text
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              whiteSpace={'nowrap'}
              style={{
                marginRight: 3,
              }}
              size={'smMedium'}
              color={Colors.Custom.Gray500}
            >
              {props?.draftCount}
            </Text>
          </View>
        </Tooltip>
        <Tooltip title={'Not Started: ' + props.pendingCount} placement="top">
          <View
            flexDirection="row"
            alignItems={'center'}
            style={{
              paddingVertical: 8,
              width: 70,
            }}
          >
            <Feather
              size={16}
              name="eye-off"
              color={Colors.Custom.Gray400}
              style={{
                marginHorizontal: 6,
              }}
            />
            <Text
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              whiteSpace={'nowrap'}
              size={'smMedium'}
              color={Colors.Custom.Gray500}
            >
              {props?.pendingCount}
            </Text>
          </View>
        </Tooltip>
    </View>
  );
};

export default FormResponseAudit;
