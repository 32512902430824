import {Popover, Tooltip} from 'antd';
import {
  Badge,
  Button,
  HStack,
  Icon,
  Text,
  VStack,
  View,
  useMediaQuery,
} from 'native-base';
import {useState} from 'react';
import Fontisto from 'react-native-vector-icons/Fontisto';
import {Colors} from '../../../styles';
import {styles} from './SideCarConversationTabStyles';
import {TestIdentifiers, testID} from '../../../testUtils';
import SideCarFilterIconSvg from '../Svg/SideCarFilterIconSvg';
import ConversationSearchBar from '../SearchBar/ConversationSearchBar';
import AntIcon from 'react-native-vector-icons/AntDesign';
import SideCarSearchIconSvg from '../Svg/SideCarSearchIconSvg';
import {Pressable} from 'react-native';

export interface ITabsAction {
  rightContainerElem?: () => JSX.Element;
}

export interface ISideCarConversationTabList {
  key: string;
  title: string;
  code: string;
}
export interface ICustomTabsView {
  tabsList: ISideCarConversationTabList[];
  unreadCountList: {
    [key: string]: {
      count: number;
      conversationIdList: {uuid: string}[];
      messageCount: number;
    };
  };
  allUnreadCount: number;
  conversationFilterProps: any;
  filterModal?: (isFilterDrawerOpen: boolean) => void;
  showMaxTabCount?: number;
  onTabClick?: (tabItem: any, index?: number) => void;
  setSearchText?: (text: string) => void;
}

const SideCarConversationTabBar = (props: ICustomTabsView) => {
  const {
    tabsList,
    onTabClick,
    unreadCountList,
    allUnreadCount,
    conversationFilterProps,
    filterModal,
    setSearchText,
  } = props;
  const [selectedTab, setSelectedTab] = useState<ISideCarConversationTabList>();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchString, setSearchString] = useState('');
  const activeTab = (): ISideCarConversationTabList => {
    return selectedTab ?? tabsList[0];
  };
  const [isOpen, setIsOpen] = useState(false);

  const maxTab = props.showMaxTabCount ?? 1;
  const isShowMoreOption = tabsList?.length > maxTab;

  const moreOptionsElements = () => {
    const tempList = tabsList.slice(maxTab);
    return (
      <VStack maxH={700} overflow={'scroll'} space={'2'}>
        {tempList.map((tabItem: ISideCarConversationTabList, index: number) => {
          return (
            <HStack key={`${tabItem.title}_${index}`}>
              <Pressable
                key={index}
                onPress={() => {
                  setIsOpen(!isOpen);
                  setSelectedTab({...selectedTab, ...tabItem});
                  if (onTabClick) {
                    onTabClick(tabItem, index);
                  }
                }}
              >
                <HStack
                  style={[styles.tabStyles]}
                  color={Colors.Custom.Gray500}
                  space={2}
                >
                  {getTabItemUI(tabItem)}
                </HStack>
              </Pressable>
            </HStack>
          );
        })}
      </VStack>
    );
  };

  const getTabList = () => {
    if (isShowMoreOption) {
      return tabsList.slice(0, maxTab);
    }
    return tabsList;
  };

  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };

  const getTabItemUI = (tabItem: ISideCarConversationTabList) => {
    const tabUnreadCount =
      tabItem.code === 'ALL_CHAT'
        ? allUnreadCount
        : unreadCountList[tabItem.code]?.count;
    return (
      <>
        <Text
          style={{
            fontWeight: '400',
            fontSize: 14,
            color:
              activeTab().title === tabItem.title
                ? Colors.primary[300]
                : Colors.Custom.Gray500,
          }}
        >
          {tabItem.title}
        </Text>
        {tabUnreadCount > 0 ? (
          <Tooltip
            title={`${tabUnreadCount} unread`}
          >
            <Badge
              size={tabUnreadCount > 99 ? 6 : 5}
              fontSize={10}
              fontWeight={500}
              backgroundColor={'#F6F7F8'}
              _text={{
                color: '#8A94A8',
              }}
              alignSelf="center"
              rounded="md"
              justifyContent={'center'}
              borderWidth={0.5}
              borderColor={Colors.Custom.Gray200}
            >
              {tabUnreadCount}
            </Badge>
          </Tooltip>
        ) : null}
      </>
    );
  };

  return (
    <View>
      <View
        style={{
          backgroundColor: '#ffffff',
          borderBottomWidth: 1,
          borderBottomColor: Colors.Custom.BorderColor,
        }}
      >
        {showSearchBar ? (
          <HStack>
            <View flex={1}>
              <ConversationSearchBar
                searchText={searchString}
                onChange={(value: string) => {
                  if (
                    setSearchText &&
                    (value.length > 2 || value.length === 0)
                  ) {
                    setSearchText(value || '');
                    setSearchString(value);
                  }
                }}
                borderWidth={1}
                placeholderText="Search here"
                searchBarInputStyles={{
                  marginLeft: 2,
                  marginRight: 2,
                  marginTop: 1,
                  marginBottom: 1,
                  backgroundColor: '#ffffff',
                  hideIcon: 'false',
                }}
              />
            </View>
            <Pressable
              onPress={() => {
                if (setSearchText) {
                  setSearchText('');
                  setSearchString('');
                }
                setShowSearchBar(false);
              }}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                flex: 0.03,
                marginRight: 8,
              }}
            >
              <Icon
                size={5}
                color={Colors.Custom.Gray500}
                as={<AntIcon name="close" />}
                {...testID('image')}
              />
            </Pressable>
          </HStack>
        ) : (
          <HStack
            alignItems={'center'}
            justifyContent={'space-between'}
            style={[
              {
                width: '100%',
                backgroundColor: '#ffffff',
                paddingLeft: 12,
                paddingRight: 4,
                height: 40,
              },
            ]}
          >
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <HStack
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <>
                  {getTabList()?.map(
                    (tabItem: ISideCarConversationTabList, index: number) => {
                      return (
                        <HStack
                          style={{
                            alignItems: 'center',
                          }}
                        >
                          <Pressable
                            key={index}
                            onPress={() => {
                              setSelectedTab(tabItem);
                              if (onTabClick) {
                                onTabClick(tabItem, index);
                              }
                            }}
                          >
                            <VStack
                              style={[
                                styles.tabStyles,
                                {paddingRight: index === 3 ? 4 : 8},
                              ]}
                              color={
                                activeTab().title === tabItem.title
                                  ? Colors.primary[300]
                                  : Colors.Custom.Gray500
                              }
                              fontWeight={
                                activeTab().title === tabItem.title ? 700 : 600
                              }
                              borderBottomWidth={
                                activeTab().title === tabItem.title ? 3 : 0
                              }
                              borderBottomColor={
                                activeTab().title === tabItem.title
                                  ? Colors.primary[300]
                                  : '#fff'
                              }
                            >
                              <HStack alignItems={'center'} space={2}>
                                {getTabItemUI(tabItem)}
                              </HStack>
                            </VStack>
                          </Pressable>
                        </HStack>
                      );
                    }
                  )}
                </>
              </HStack>
              {isShowMoreOption && !showSearchBar && (
                <HStack>
                  <Popover
                    content={moreOptionsElements}
                    trigger="click"
                    overlayInnerStyle={{padding: 0, borderRadius: 16}}
                    overlayStyle={{padding: 0}}
                    overlay={{padding: 0}}
                    style={{padding: 0, borderRadius: 16}}
                    placement={'bottom'}
                    visible={isOpen}
                    onVisibleChange={handleVisibleChange}
                  >
                    <Button
                      style={{
                        height: 24,
                        width: 24,
                        backgroundColor: Colors.Custom.White,
                      }}
                      onPress={() => {
                        setIsOpen(!isOpen);
                      }}
                    >
                      <Icon
                        size={3}
                        color={Colors.Custom.Gray500}
                        as={<AntIcon name="caretdown" />}
                        {...testID('image')}
                      />
                    </Button>
                  </Popover>
                </HStack>
              )}
            </View>
            <HStack
              justifyContent={'flex-end'}
              justifyItems={'center'}
              alignItems={'center'}
              alignContent={'center'}
              flex={1}
            >
              <Pressable
                onPress={() => {
                  filterModal && filterModal(true);
                }}
                style={[
                  {
                    width: 24,
                    height: 24,
                    marginTop: -2,
                    justifyContent: 'center',
                  },
                ]}
                {...testID(TestIdentifiers.filterButton)}
              >
                <Tooltip
                  title={conversationFilterProps?.count?.join(' \n')}
                  arrowPointAtCenter={false}
                  placement={'left'}
                  overlayStyle={{whiteSpace: 'pre-line'}}
                >
                  {conversationFilterProps?.count?.length ? (
                    <View
                      style={{
                        position: 'absolute',
                        top: -6.5,
                        right: -5.5,
                        width: 15,
                        height: 15,
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: Colors.danger[500],
                        borderRadius: 15,
                      }}
                    >
                      <Text
                        style={{
                          color: '#ffffff',
                          fontWeight: '600',
                          fontSize: 10,
                        }}
                      >
                        {conversationFilterProps?.count?.length}
                      </Text>
                    </View>
                  ) : null}
                  <SideCarFilterIconSvg />
                </Tooltip>
              </Pressable>
              <View
                style={{
                  width: 1,
                  height: 20,
                  backgroundColor: Colors.Custom.Gray200,
                  marginHorizontal: 12,
                }}
              ></View>
              <Pressable
                onPress={() => {
                  setShowSearchBar(true);
                }}
                style={[
                  {
                    width: 24,
                    height: 24,
                    justifyContent: 'center',
                    marginHorizontal: 2,
                  },
                ]}
                {...testID(TestIdentifiers.searchBtn)}
              >
                <SideCarSearchIconSvg />
              </Pressable>
            </HStack>
          </HStack>
        )}
      </View>
    </View>
  );
};

export default SideCarConversationTabBar;
