import {gql} from '@apollo/client';

const GetAgentBots = gql`
  query QueryAgentBots {
    agentBots {
      id
      name
      outgoingUrl
    }
  }
`;

export default {
  GetAgentBots,
};
