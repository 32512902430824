import React from 'react';
import { Svg, Path } from 'react-native-svg';

const SideCarSearchIconSvg = () => {
  return (
    <Svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <Path
        stroke="#6F7A90"
        strokeLinecap="round"
        d="M14.875 14.875L17.5 17.5m-.75-7.875a7.125 7.125 0 11-14.25 0 7.125 7.125 0 0114.25 0z"
      ></Path>
    </Svg>
  );
};

export default React.memo(SideCarSearchIconSvg);
