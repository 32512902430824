import ContactCareProgram from '../../../../services/ContactCareProgram/ContactCareProgram';
import React from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {useMutation} from '@apollo/client';
import {useCareProgramStatus} from '../../ContactCareProgram/ContactCareProgramView/hooks/useCareProgramStatus';

export const useCareProgramCloseAction = () => {
  const {careProgramStatus} = useCareProgramStatus();
  const [state, setState] = React.useState({
    isLoading: false,
  });

  const [updateContactCareProgramStatus] = useMutation(
    ContactCareProgram.UPDATE_CONTACT_CARE_PROGRAM_STATUS,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const onCloseCareProgram = async (
    selectedCareProgramList: string[],
    contactId: string,
    closeReason: string
  ): Promise<void> => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    try {
      await Promise.all(
        selectedCareProgramList.map(async (careProgram) => {
          const result = await closeCareProgram(
            contactId,
            careProgram,
            closeReason
          );
          if (!result?.data) {
            throw new Error('Failed to close care program');
          }
          return result;
        })
      );

      setState((prev) => ({
        ...prev,
        isLoading: false,
      }));
    } catch (error: any) {
      setState((prev) => ({
        ...prev,
        isLoading: false,
      }));
      throw new Error(error?.message || 'Failed to close care programs');
    }
  };

  const closeCareProgram = async (
    contactId: string,
    careProgramId: string,
    reason: string
  ) => {
    const closeCareProgramStatusId = careProgramStatus.closed;
    const response = await updateContactCareProgramStatus({
      variables: {
        params: {
          id: careProgramId,
          contactId,
          statusId: closeCareProgramStatusId,
          statusReason: reason,
        },
      },
    });
    return response;
  };

  return {
    onCloseCareProgram,
    isLoading: state.isLoading,
  };
};
