
import {IAppliedFilter, IMixLineStackColumnChartResourceData, IWidgetCardView} from '../../interface';
import GaugeChartView from '../GaugeCharts/GaugeCharts';
import {Colors} from '../../../../../../styles';
export const getNPSWidgetElem = (props: IWidgetCardView, stateData: {resourceData: IMixLineStackColumnChartResourceData; loading: boolean; employeeTotalCount: number; selectedView: string; selectedResourceData: any; masterData: any; appliedFilter: IAppliedFilter[] | undefined; filterValueName: string[]; filterValueString: string; selectedAppliedFilter: any[]; graphWrapperHeight: number; formId: string;}, getMixChartView: () => JSX.Element, ele: JSX.Element) => {
  const gaugeChartView = <GaugeChartView {...props} resourceData={stateData?.resourceData} />;

  const mixChartView = getMixChartView();
  const finalResourceData: any = stateData?.resourceData;
  const formAdditionalData = finalResourceData?.additionalData;
  const averageRatingScoreByType = formAdditionalData?.averageRatingScoreByType;
  const mainEleContainer = (
    <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
      <div style={{width: '38%'}}>
        <div>
          {gaugeChartView}
        </div>
        <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', marginTop: 12, justifyContent: 'space-between'}}>
          {averageRatingScoreByType?.map((item: any) => {
            return (
              <div style={{
                padding: 16,
                borderWidth: 1,
                borderColor: Colors.Custom.Gray200,
                borderStyle: 'solid',
                borderRadius: 8,
                marginBottom: 8
              }} className='score-detail-item'>
                <div style={{
                  color: Colors.Custom.Gray500,
                  fontFamily: 'Manrope',
                  fontSize: 14,
                  fontWeight: 600,
                }}>{item?.displayText}</div>
                <div style={{
                  color: '#000',
                  fontFamily: 'Manrope',
                  fontSize: 18,
                  fontWeight: 600,
                }}>{item?.avgScorePercentage}%</div>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{width: '58%'}}>
        {mixChartView}
      </div>
    </div>);
  ele = mainEleContainer;
  return ele;
}
