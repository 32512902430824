import * as React from 'react';
export interface IDashboardContextData {
  fromDate: any;
  toDate: any;
  dateRangeType: string
  employeeCount?: number
  uiFromDate?: string,
  uiToDate?: string;
  employerName?: string;
  accountPracticeLocations?: any;
}

export const defaultDashboardDataContext: IDashboardContextData = {
  fromDate: '' as any,
  toDate: '' as any,
  dateRangeType: '',
  // employeeCount: 0
};

export const DashboardDataContext = React.createContext(
  defaultDashboardDataContext
);
