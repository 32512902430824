import {HStack, IconButton, Tooltip} from 'native-base';
import Icon from 'react-native-vector-icons/AntDesign';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {ICampaignTableActions} from '../../interfaces';
import {styles} from './TableComponentsStyles';

export const CampaignTableActions = (props: ICampaignTableActions) => {
  return (
    <HStack style={styles.centerAlign}>
      <Tooltip label={'Copy'} openDelay={200} placement="top">
        <IconButton
          icon={<Icon name="copy1" size={15} />}
          _hover={{background: 'none'}}
          onPress={() => {
            props.onActionPerformed(COMMON_ACTION_CODES.COPY, props.record);
          }}
        />
      </Tooltip>
      <Tooltip label={'Edit'} openDelay={200} placement="top">
        <IconButton
          icon={<Icon name="edit" size={15} />}
          _hover={{background: 'none'}}
          onPress={() => {
            props.onActionPerformed(COMMON_ACTION_CODES.EDIT, props.record);
          }}
        />
      </Tooltip>
      <Tooltip label={'Delete'} openDelay={200} placement="top">
        <IconButton
          icon={<Icon name="delete" size={15} />}
          _hover={{background: 'none'}}
          onPress={() => {
            props.onActionPerformed(COMMON_ACTION_CODES.DELETE, props.record);
          }}
        />
      </Tooltip>
    </HStack>
  );
};
