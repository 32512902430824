import {HStack, Pressable, Skeleton, Text, View, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import Icon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../styles';
import {isWeb} from '../../../utils/platformCheckUtils';
import {SearchBar} from '../SearchBar';
import CustomFilterList from './CustomFilterList/CustomFilterList';

const CustomFilterView = (props: any) => {
  const {
    tagList,
    loading,
    onActionPerformed,
    placeHolderText,
    onFilterSelect,
    selectedLabels,
    onRemoveItem,
    displayString,
    title,
    hideSearchbar,
  } = props;
  const [displayTagList, setDisplayTagList] = useState([] as any);

  useEffect(() => {
    const masterList = tagList;
    const selectedList = selectedLabels;
    setDisplayTagList(
      masterList.filter((item: any) => !selectedList.includes(item))
    );
  }, [selectedLabels.length, !loading]);
  return (
    <View
      flex={1}
      style={
        [
          // {borderWidth: 1, borderColor: 'gray', borderRadius: 8, padding: 16},
          // styles.containerStyle,
        ]
      }
      maxWidth={isWeb() ? 264 : 'auto'}
    >
      {!hideSearchbar && (
        <VStack
          justifyContent={'center'}
          // flex={1}
          style={{marginHorizontal: isWeb() ? 0 : 14}}
        >
          <SearchBar
            placeholderText={placeHolderText ? placeHolderText : 'Search tags'}
            onChange={(value: string) => {
              if (props.onActionPerformed) {
                const searchString = value;
                onActionPerformed(searchString);
              }
            }}
          />
        </VStack>
      )}
      {loading ? (
        <View style={{padding: '20px'}}>
          <Skeleton.Text lines={5} />
        </View>
      ) : (
        <View style={{padding: 14}}>
          {selectedLabels && selectedLabels.length > 0 && (
            <>
              <HStack flexWrap={'wrap'} style={{marginVertical: 8}}>
                {selectedLabels?.map((label: any, index: number) => {
                  return (
                    <VStack
                      key={index}
                      style={{
                        paddingVertical: 3,
                        paddingHorizontal: 4,
                        borderRadius: 8,
                        marginHorizontal: 3,
                        marginVertical: 3,
                      }}
                      background={'gray.100'}
                    >
                      <HStack alignItems={'center'}>
                        <VStack>
                          <Text
                            size={'xsMedium'}
                            style={{
                              fontSize: 10,
                              color: Colors.Custom.Gray500,
                            }}
                          >
                            {label.title ? label.title : label.name}
                          </Text>
                        </VStack>
                        <VStack>
                          <Pressable
                            onPress={() => {
                              onRemoveItem(label);
                            }}
                          >
                            <Icon name="close" color={Colors.Custom.Gray500} />
                          </Pressable>
                        </VStack>
                      </HStack>
                    </VStack>
                  );
                })}
              </HStack>
            </>
          )}
          <CustomFilterList
            displayString={displayString}
            onFilterSelect={(labelItem: any) => {
              onFilterSelect(labelItem);
            }}
            tagList={displayTagList}
            title={title}
          />
        </View>
      )}
    </View>
  );
};

export default CustomFilterView;
