import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../styles';

export const styles = StyleSheet.create({
  spinnerStyle: {
    margin: '0 auto',
    color: 'black',
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0,
  },
  formElement: {
    // paddingTop: 10,
    // paddingBottom: 10,
  },
  formLabel: {
    marginLeft: 4,
  },
  previewContainerView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  iconView: {
    flex: 0.8,
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  icons: {
    marginLeft: 2,
    marginRight: 2,
    fontSize: 14.29,
    color: Colors.Custom.Gray600,
  },
  btn: {
    border: 'none',
    backgroundColor: 'transparent',
  },
  locationList: {
    paddingHorizontal: 8,
    marginRight: 4,
    borderRadius: 16,
    marginTop: 5,
    alignItems: 'center',
    backgroundColor: Colors.Custom.Gray100,
    borderColor: Colors.Custom.Gray200,
    borderWidth: 1
  }
});