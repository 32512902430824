import {debounce} from 'lodash';
import {Text, View} from 'native-base';
import React from 'react';
import {Dimensions, FlatList, StyleSheet} from 'react-native';
import {
  IPageSection,
  IRowsByAssignee,
  ITaskDelayOverViewDrillDown,
  RESOURCE_LIST,
} from '../../interface';
import {secondsToHhMm} from '../UtilsConst';

const TaskDelayOverViewDrilLDown = (props: {
  rowsByAssignee: ITaskDelayOverViewDrillDown[];
  pageSection: IPageSection;
  fetchMoreItems?: () => void;
  loadingView?: JSX.Element;
  isDrillDown?: boolean;
  isLoading?: boolean;
  isEndReached?: boolean;
}) => {
  const {
    pageSection,
    rowsByAssignee,
    isDrillDown,
    loadingView,
    fetchMoreItems,
    isLoading,
    isEndReached,
  } = props;
  const height = Dimensions.get('window').height * 0.69;
  const isPoolType =
    pageSection.resource.code ===
    RESOURCE_LIST.TASK_ANALYTICS_TASK_DELAY_PERCENTAGE_OVERVIEW_BY_TASK_POOL;
  const containerRef: any = React.useRef(null);
  const handleEndReached = debounce(() => {
    if (!isLoading && !isEndReached) {
      fetchMoreItems?.();
    }
  }, 500);
  return (
    <View flex={0.5} style={styles.container}>
      <View style={styles.headerContainer}>
        <Text size={'xsSemibold'} color={'#6F7A90'} style={styles.headerText40}>
          {isPoolType ? 'TASK POOL' : 'USERS'}
        </Text>
        <Text size={'xsSemibold'} color={'#6F7A90'} style={styles.headerText16}>
          COMPLETED
        </Text>
        <Text size={'xsSemibold'} color={'#6F7A90'} style={styles.headerText14}>
          ON TIME
        </Text>
        <Text size={'xsSemibold'} color={'#6F7A90'} style={styles.headerText15}>
          DELAY
        </Text>
        <Text size={'xsSemibold'} color={'#6F7A90'} style={styles.headerText15}>
          AVG. DELAY
        </Text>
      </View>
      <FlatList
        style={{
          maxHeight: isDrillDown ? height : 'auto',
        }}
        data={rowsByAssignee || []}
        renderItem={({item}: any) => (
          <View style={styles.rowContainer}>
            <Text
              size={'smMedium'}
              color={'#3A485F'}
              style={[styles.cellText40, isDrillDown ? styles.drillDownPadding : styles.normalPadding]}
              lineBreakMode="clip"
            >
              {item?.assigneeName || item?.userPoolName || '--'}
            </Text>
            <Text size={'smMedium'} color={'#3A485F'} style={styles.cellText16}>
              {item.completedTasksCount}
            </Text>
            <Text size={'smMedium'} color={'#3A485F'} style={styles.cellText14}>
              {(item.completedTasksCount || 0) -
                (item.afterDueDateCompletedTasksCount || 0)}
            </Text>
            <Text size={'smMedium'} color={'#3A485F'} style={styles.cellText15}>
              {item.afterDueDateCompletedTasksCount}
            </Text>
            <Text size={'smMedium'} color={'#3A485F'} style={styles.cellText15}>
              {secondsToHhMm(item?.completionTimeDelayInSecondsAverage || 0)}
            </Text>
          </View>
        )}
        keyExtractor={(item: any, index: number) =>
          `${item.assigneeId || item?.userPoolId}_${index}`
        }
        onEndReached={handleEndReached}
        onEndReachedThreshold={0.5}
        ListFooterComponent={loadingView}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingLeft: 4,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 0.5,
    borderColor: '#D0D6E1',
    borderStyle: 'solid',
  },
  headerText40: {
    width: '40%',
    paddingHorizontal: 12,
    paddingVertical: 9,
  },
  headerText16: {
    width: '16%',
    paddingHorizontal: 12,
    paddingVertical: 9,
  },
  headerText14: {
    width: '14%',
    paddingHorizontal: 12,
    paddingVertical: 9,
  },
  headerText15: {
    width: '15%',
    paddingHorizontal: 12,
    paddingVertical: 9,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cellText40: {
    width: '40%',
    paddingHorizontal: 12,
  },
  cellText16: {
    width: '16%',
    paddingHorizontal: 12,
    paddingVertical: 3,
  },
  cellText14: {
    width: '14%',
    paddingHorizontal: 12,
    paddingVertical: 3,
  },
  cellText15: {
    width: '15%',
    paddingHorizontal: 12,
    paddingVertical: 3,
  },
  drillDownPadding: {
    paddingVertical: 8,
  },
  normalPadding: {
    paddingVertical: 4,
  },
});

export default TaskDelayOverViewDrilLDown;
