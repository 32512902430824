import {Avatar, HStack, Text, VStack} from 'native-base';
import {ICampaignTableFrom} from '../../interfaces';
import {
  getAvatarColorInHsl,
  getInitialsFromFullName,
} from '../../../../../../utils/commonUtils';
import {styles} from './TableComponentsStyles';

export const CampaignTableFrom = (props: ICampaignTableFrom) => {
  return (
    <HStack space={2} style={styles.centerAlign}>
      <Avatar size={8} backgroundColor={getAvatarColorInHsl(props.from.name)}>
        {getInitialsFromFullName(props.from.name)}
      </Avatar>
      <VStack style={styles.flexStart}>
        <Text>{props.from.name}</Text>
        <Text style={styles.tinyDetail}>{props.from.email}</Text>
      </VStack>
    </HStack>
  );
};
