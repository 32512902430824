import {View} from 'native-base';
// import {useContext, useEffect} from 'react';
// import {useParams} from 'react-router-dom';
// import {CommonDataContext} from '../../../context/CommonDataContext';
// import {
//   getAccountId,
//   getAccountName,
//   getAccountUUID,
// } from '../../../utils/commonUtils';
// import {IMeeting} from '../MeetingView/interface';
// import {IMemberData} from './interfaces';
// import {handleOnCallAction} from './ZoomServices';

const ZoomView = () => {
  // const accountId = getAccountId();
  // const accountUUID = getAccountUUID();
  // const accountName = getAccountName();
  // const mlovData = useContext(CommonDataContext);
  // const userData: any = mlovData?.userData;
  // const prams = useParams();

  // const decoded = prams.token ? window.atob(prams.token as string) : '';
  // const meetingInfo = decoded.length
  // ? (JSON.parse(decoded) as IMeeting)
  // : ({} as IMeeting);
  // const formattedContactData = meetingInfo.formattedContactData
  //   ? (meetingInfo.formattedContactData as IMemberData)
  //   : ({} as IMemberData);
  // const leaveUrl = `${window.location.origin}/#/leave_meet/true` as string;

  // useEffect(() => {
  //   if (prams.is_end_meet == 'true') {
  //     window.close();
  //     /*document.getElementById('zmmtg-root')!.style.display = 'none';
  //     navigate(-1);
  //     const redirectUrl = window.location.href.replace('?endmeet=', '');
  //     window.location.replace(redirectUrl);
  //     window.location.reload();*/
  //   }
  // }, [prams.is_end_meet]);

  // const setupZoomListeners = () => {
  //   // ZoomMtg.inMeetingServiceListener('onMeetingStatus', function (data: any) {
  //   //   if (data?.meetingStatus == 3) {
  //   //     window.close();
  //   //   }
  //   // });
  // };

  // useEffect(() => {
  //   if (!window.location.hash.includes('leave_meet')) {
  //     // handleOnCallAction(
  //     //   meetingInfo,
  //     //   leaveUrl,
  //     //   accountId,
  //     //   accountUUID,
  //     //   accountName,
  //     //   30,
  //     //   [formattedContactData],
  //     //   userData,
  //     //   (value: boolean) => {
  //     //   },
  //     //   () => {
  //     //     setupZoomListeners();
  //     //   },
  //     //   ''
  //     // );
  //   }
  // }, []);

  return <View />;
};
export default ZoomView;
