export const getSignUpFormScript = (args: {
  envVariable: string;
  accountUuid: string;
  openInModal: boolean;
  widgetToken: string;
}) => {
  return `
    <script>
      (function (d, tr, inModal, bg) {
        var u = "",
          aId = "";
        function insertModalScript(onLoadCallback) {
          var MODAL_JS = "https://unpkg.com/micromodal/dist/micromodal.min.js";
          var g = d.createElement("script"),
            s = d.getElementsByTagName("script")[0];
          g.src = MODAL_JS;
          g.defer = true;
          g.async = true;
          s.parentNode.insertBefore(g, s);
          g.onload = onLoadCallback;
        }
        function insertModalHTMLAndCSS() {
          var s = d.createElement("style"),
            h = d.getElementsByTagName("head")[0],
            c =".fh-modal-overlay{position:fixed; top:0; left:0; right:0; bottom:0; background:rgba(0,0,0,.6); display:flex; justify-content:center; align-items:center}.fh-modal-container{background-color:#fff; padding:16px; border-radius:4px; overflow-y:auto; box-sizing:border-box}.fh-modal-header{display:flex; justify-content:space-between; align-items:center; float:right}.fh-modal-close{background:0 0; border:0}@keyframes mmfadeIn{from{opacity:0}to{opacity:1}}@keyframes mmfadeOut{from{opacity:1}to{opacity:0}}@keyframes mmslideIn{from{transform:translateY(15%)}to{transform:translateY(0)}}@keyframes mmslideOut{from{transform:translateY(0)}to{transform:translateY(-10%)}}.micromodal-slide{display:none}.micromodal-slide.is-open{display:block}.micromodal-slide[aria-hidden=false] .fh-modal-overlay{animation:mmfadeIn .3s cubic-bezier(0,0,.2,1)}.micromodal-slide[aria-hidden=false] .fh-modal-container{animation:mmslideIn .3s cubic-bezier(0,0,.2,1)}.micromodal-slide[aria-hidden=true] .fh-modal-overlay{animation:mmfadeOut .3s cubic-bezier(0,0,.2,1)}.micromodal-slide[aria-hidden=true] .fh-modal-container{animation:mmslideOut .3s cubic-bezier(0,0,.2,1)}.micromodal-slide .fh-modal-container,.micromodal-slide .fh-modal-overlay{will-change:transform}.fh-form-container{width:96vw; height:90vh; max-width:900px;}";
          h.appendChild(s);
          s.type = "text/css";
          if (s.styleSheet) {
            s.styleSheet.cssText = c;
          } else {
            s.appendChild(d.createTextNode(c));
          }
          const data = '<div class="modal micromodal-slide" id="fh-form-widget" aria-hidden="true"><div class="fh-modal-overlay" tabindex="-1" data-micromodal-close><div class="fh-modal-container fh-form-container" role="dialog" aria-modal="true" aria-labelledby="fh-form-widget-title"><header class="fh-modal-header"><button class="fh-modal-close" aria-label="Close modal" data-micromodal-close>&#x2715</button></header><div id="fh-form-widget-content"></div></div></div></div>';
          var b = d.getElementsByTagName("body")[0];
          var e = d.createElement("div");
          e.innerHTML = data;
          b.appendChild(e);
        }
        function getIframeElementString() {
          var url = "https://${window.location.host}/widget.html#/public/forms?isWidget=true&isEmbedded=true&formCode=CONTACT_SIGN_UP&accountId=${args.accountUuid}&token=${args.widgetToken}&bg=" + bg;
          return '<iframe id="fh-form-iframe" src="'+ url +'" width="100%" height="100%" style="border:none;height:84vh;" frameborder="0"></iframe>';
        }
        function addClickEventListener() {
          var b = d.getElementsByTagName("body")[0];
          b.addEventListener("click", function (e) {
            if (e.target && e.target.id == tr) {
              d.getElementById("fh-form-widget-content").innerHTML = getIframeElementString();
              window.MicroModal.show("fh-form-widget");
            }
          });
        }
        function addInlineWidget() {
          d.getElementById(tr).innerHTML = getIframeElementString();
        }
        if (inModal) {
          insertModalScript(function () {
            insertModalHTMLAndCSS();
            addClickEventListener();
          });
        } else {
          addInlineWidget();
        }
      })(document, "fh-form-trigger", ${args.openInModal});
    </script>
  `;
};
export const getAppointmentWidgetScript = (args: {
  envVariable: string;
  accountUuid: string;
  openInModal: boolean;
}) => {
  return `
  <script>
      (function (d, tr, inModal, bg) {
        var u = "",
          aId = "";
        function insertModalScript(onLoadCallback) {
          var MODAL_JS = "https://unpkg.com/micromodal/dist/micromodal.min.js";
          var g = d.createElement("script"),
            s = d.getElementsByTagName("script")[0];
          g.src = MODAL_JS;
          g.defer = true;
          g.async = true;
          s.parentNode.insertBefore(g, s);
          g.onload = onLoadCallback;
        }
        function insertModalHTMLAndCSS() {
          var s = d.createElement("style"),
            h = d.getElementsByTagName("head")[0],
            c = ".fh-modal-overlay{position:fixed; top:0; left:0; right:0; bottom:0; background:rgba(0,0,0,.6); display:flex; justify-content:center; align-items:center}.fh-modal-container{background-color:#fff; padding:16px; border-radius:4px; overflow-y:auto; box-sizing:border-box}.fh-modal-header{display:flex; justify-content:space-between; align-items:center; float:right}.fh-modal-close{background:0 0; border:0}@keyframes mmfadeIn{from{opacity:0}to{opacity:1}}@keyframes mmfadeOut{from{opacity:1}to{opacity:0}}@keyframes mmslideIn{from{transform:translateY(15%)}to{transform:translateY(0)}}@keyframes mmslideOut{from{transform:translateY(0)}to{transform:translateY(-10%)}}.micromodal-slide{display:none}.micromodal-slide.is-open{display:block}.micromodal-slide[aria-hidden=false] .fh-modal-overlay{animation:mmfadeIn .3s cubic-bezier(0,0,.2,1)}.micromodal-slide[aria-hidden=false] .fh-modal-container{animation:mmslideIn .3s cubic-bezier(0,0,.2,1)}.micromodal-slide[aria-hidden=true] .fh-modal-overlay{animation:mmfadeOut .3s cubic-bezier(0,0,.2,1)}.micromodal-slide[aria-hidden=true] .fh-modal-container{animation:mmslideOut .3s cubic-bezier(0,0,.2,1)}.micromodal-slide .fh-modal-container,.micromodal-slide .fh-modal-overlay{will-change:transform}.fh-appointment-container{width:96vw; height:60vh; max-width:900px;}";
          h.appendChild(s);
          s.type = "text/css";
          if (s.styleSheet) {
            s.styleSheet.cssText = c;
          } else {
            s.appendChild(d.createTextNode(c));
          }
          const data = '<div class="modal micromodal-slide" id="fh-appointment-widget" aria-hidden="true"><div class="fh-modal-overlay" tabindex="-1" data-micromodal-close><div class="fh-modal-container fh-appointment-container" role="dialog" aria-modal="true" aria-labelledby="fh-appointment-widget-title"><header class="fh-modal-header"><button class="fh-modal-close" aria-label="Close modal" data-micromodal-close>&#x2715</button></header><div id="fh-appointment-widget-content"></div></div></div></div>';
          var b = d.getElementsByTagName("body")[0];
          var e = d.createElement("div");
          e.innerHTML = data;
          b.appendChild(e);
        }

        function getIframeElementString() {
          var url = "https://${window.location.host}/widget.html#/public/appointment/booking?isWidget=true&isEmbedded=true&accountId=${args.accountUuid}&bg=" + bg;
          return '<iframe id="fh-appointment-iframe" src="' + url + '" width="100%" height="100%" style="border:none;height:54vh;" frameborder="0"></iframe>';
        }
        function addClickEventListener() {
          var b = d.getElementsByTagName("body")[0];
          b.addEventListener("click", function (e) {
            if (e.target && e.target.id == tr) {
              d.getElementById("fh-appointment-widget-content").innerHTML = getIframeElementString();
              window.MicroModal.show("fh-appointment-widget");
            }
          });
        }
        function addInlineWidget() {
          d.getElementById(tr).innerHTML = getIframeElementString();
        }
        if (inModal) {
          insertModalScript(function () {
            insertModalHTMLAndCSS();
            addClickEventListener();
          });
        } else {
          addInlineWidget();
        }
      })(document, "fh-appointment-trigger", ${args.openInModal});
    </script>

  `;
};
