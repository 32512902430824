import {useLazyQuery} from '@apollo/client';
import {Spinner, View} from 'native-base';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {MLOV_CATEGORY, RECENT_ITEM_TYPES} from '../../../constants';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {RecentItemsQueries} from '../../../services';
import {Colors} from '../../../styles';
import {getAccountId, getUserUUID} from '../../../utils/commonUtils';
import {EventBus} from '../../../utils/EventBus';
import {getMlovId} from '../../../utils/mlovUtils';
import {DisplayText} from '../DisplayText/DisplayText';
import TimerSvg from '../Svg/SideMenu/TimerSvg';
import {IRecentItem, IRecentItemData} from './interface';
import RecentPatientList from './RecentPatientList';

const RecentItemsView = (props: any) => {
  const mlovData = useContext(CommonDataContext);
  const userUuid = getUserUUID();
  const accountId = getAccountId();
  const recentPatientType = getMlovId(
    mlovData.MLOV,
    MLOV_CATEGORY.RECENT_ITEM_TYPES,
    RECENT_ITEM_TYPES.PATIENTS
  );
  const [recentItemState, setRecentItemState] = useState({
    recentItemList: [] as IRecentItem[],
    loading: false,
  });
  const [getRecentItems] = useLazyQuery<IRecentItemData>(
    RecentItemsQueries.GET_RECENT_ITEMS,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const onMemberSelect = useCallback(() => {
    getRecentItemsData();
  }, []);
  const getRecentItemsData = async () => {
    setRecentItemState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const responseData = await getRecentItems({
      variables: {
        recentItemTypeUUID: recentPatientType,
        userUuid: userUuid,
        accountId: accountId,
      },
    });
    if (responseData.data?.recent_items?.length) {
      setRecentItemState((prev: any) => {
        return {
          ...prev,
          recentItemList: responseData.data?.recent_items,
        };
      });
    }
    setRecentItemState((prev) => {
      return {
        ...prev,
        loading: false,
      };
    });
  };
  useEffect(() => {
    getRecentItemsData();
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener('ADD_RECENT_MEMEBER', onMemberSelect);
    // return () => {
    //   eventBus.removeEventListener(onMemberSelect);
    // };
  }, []);
  return (
    <View
      style={{
        backgroundColor: props.isPopover ? '#fff' : Colors.primary['500'],
        paddingHorizontal: 16,
        paddingVertical: 18,
        borderRadius: 8,
        marginBottom: 16,
      }}
    >
      <View flexDirection={'row'} alignItems={'center'}>
        <View style={{width: 20, height: 20}}>
          <TimerSvg
            isSelected={props.isPopover}
            defaultColor={props.isPopover ? Colors.primary['500'] : '#9C7CD3'}
          />
        </View>
        <DisplayText
          textLocalId="recent"
          size={'smMedium'}
          extraStyles={{
            color:
              !props.isPopover && Colors.secondary['500']
                ? '#9C7CD3'
                : Colors.primary['500'] || '',
            marginLeft: 10,
          }}
        />
      </View>
      {recentItemState.loading ? (
        <Spinner size="lg" />
      ) : (
        <RecentPatientList
          isPopover={props.isPopover}
          recent_items={recentItemState.recentItemList}
        />
      )}
    </View>
  );
};
export default RecentItemsView;
