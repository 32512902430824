import React from 'react';
import {Colors} from '../../../../styles';
import {ICommonSvgProps} from '../interfaces';

const MembershipSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        d="M18.6 10.74V7.6M18.6 7.6L12 1M18.6 7.6H12V1M12 1H3.2C2.62 1 2.06 1.23 1.65 1.64C1.24 2.05 1 2.62 1 3.2V20.8C1 21.38 1.23 21.94 1.65 22.35C2.06 22.77 2.62 23 3.2 23H16.4C16.99 23 17.54 22.77 17.96 22.35C18.38 21.93 18.6 21.38 18.6 20.8V18.59M8.35 13.1H5.4M9.55 17.5H5.4M7.6 8.7H5.4M21 21L17.93 17.93M13.93 19.59C17.05 19.59 19.58 17.06 19.58 13.94C19.58 10.82 17.05 8.29 13.93 8.29C10.81 8.29 8.28 10.82 8.28 13.94C8.28 17.06 10.81 19.59 13.93 19.59Z"
        stroke={strokeColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default React.memo(MembershipSvg);
