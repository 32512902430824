import {gql} from '@apollo/client';

export const GET_MEDICATIONS = gql`
  query GetMedications($searchString: String!, $page: Int!, $pageSize: Int!) {
    searchMedicine(
      params: {query: $searchString, pageSize: $pageSize, page: $page}
    ) {
      medicines
    }
  }
`;

export const UPDATE_PRESCRIBED_MEDICATION_COST = gql`
  mutation UpdatePrescribedMedicationCost(
    $price: float8,
    $priceData: jsonb,
    $marketPriceData: jsonb,
    $id:uuid!
    $currentMarketPrice: float8
  ) {
    updatePrescribedMedicationCost(
      pk_columns: {id: $id}
      _set: {
        price: $price
        priceData: $priceData
        marketPriceData: $marketPriceData
        currentMarketPrice: $currentMarketPrice
      }
    ) {
      id
      price
      priceData
      marketPriceData
    }
  }
`;

export const CREATE_PRESCRIBED_MEDICATION_COST = gql`
  mutation CreatePrescribedMedicationCost(
    $id: uuid,
    $accountUuid: uuid,
    $price: float8,
    $marketPriceData: jsonb,
    $priceData: jsonb
    $currentMarketPrice: float8
  ) {
    createPrescribedMedicationCost(
      object: {
        accountUuid: $accountUuid
        accountPrescribedMedicationId: $id
        price: $price
        marketPriceData: $marketPriceData
        priceData: $priceData
        currentMarketPrice: $currentMarketPrice
      }
    ) {
      id
      price
      marketPriceData
    }
  }
`;

export const GET_PRESCRIBED_MEDICATIONS = gql`
  query GetPrescribedMedications($employerUuid: uuid!, $accountUuid: uuid!) {
    prescribedMedication(
      where: {accountPrescribedMedication: {employerUuid: {_eq: $employerUuid},accountUuid: {_eq: $accountUuid}}}
    ) {
      id
      name
      drugCode
      description
      codeType
      accountPrescribedMedication {
        id
        prescribedMedicationId
        accountUuid
        employerUuid
        prescribedMedicationCost {
          id
          price
          priceData
          marketPriceData
          currentMarketPrice
        }
      }
    }
  }
`;

export const GET_PRESCRIBED_MEDICATIONS_ACC = gql`
  query GetPrescribedMedications($employerUuid: uuid!, $accountUuid: uuid!) {
    prescribedMedication(
      where: {accountPrescribedMedication: {accountUuid: {_eq: $accountUuid}}}
    ) {
      id
      name
      drugCode
      description
      codeType
      accountPrescribedMedication {
        id
        prescribedMedicationId
        accountUuid
        employerUuid
        prescribedMedicationCost {
          id
          price
          priceData
          marketPriceData
          currentMarketPrice
        }
      }
    }
  }
`;

export default {GET_MEDICATIONS, GET_PRESCRIBED_MEDICATIONS};
