// Patient Dashboard Actions
export const PATIENT_FETCH_LIST_SUCCESS = 'PATIENT_FETCH_LIST_SUCCESS'
export const PATIENT_FETCH_LIST_ERROR = 'PATIENT_FETCH_LIST_ERROR'
export const PATIENT_FETCH_LIST_START = 'PATIENT_FETCH_LIST_START'

export const PATIENT_ADD_SUCCESS = 'PATIENT_ADD_SUCCESS'
export const PATIENT_ADD_ERROR = 'PATIENT_ADD_ERROR'
export const PATIENT_ADD_START = 'PATIENT_ADD_START'

export const PATIENT_REMOVE_SUCCESS = 'PATIENT_REMOVE_SUCCESS'
export const PATIENT_REMOVE_ERROR = 'PATIENT_REMOVE_ERROR'
export const PATIENT_REMOVE_START = 'PATIENT_REMOVE_START'

export const PATIENT_UPDATE_SUCCESS = 'PATIENT_UPDATE_SUCCESS'
export const PATIENT_UPDATE_ERROR = 'PATIENT_UPDATE_ERROR'
export const PATIENT_UPDATE_START = 'PATIENT_UPDATE_START'

//user actions
export const USER_FETCH_LIST_SUCCESS = 'USER_FETCH_LIST_SUCCESS'
export const USER_FETCH_LIST_ERROR = 'USER_FETCH_LIST_ERROR'
export const USER_FETCH_LIST_START = 'USER_FETCH_LIST_START'

export const DATE_CHANGE = 'DATE_CHANGE'
export const USER_SELECT = 'USER_SELECT'
export const COLUMN_SHUFFLE_DROP_DOWN_LIST = 'COLUMN_SHUFFLE_DROP_DOWN_LIST'
