import { View } from "native-base"
import { TimezoneSelect } from "../../common/TimezoneSelect/TimezoneSelect"
import { useState } from "react"


export const TimeZoneDropdown = ({selectedTimezone, onChange}: any)=> {
    const [ timezone, setTimeZone] = useState(selectedTimezone);

    return (
        <View>
             <TimezoneSelect
                selectedTimezoneValue={timezone}
              onChange={(timezoneObj?: any) => {
              setTimeZone(timezoneObj.displayValue)
              onChange(timezoneObj.displayValue);
            }}

            />
        </View>
    )
}
