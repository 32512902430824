import {StyleSheet} from 'react-native';
import { Colors } from '../../../../styles/Colors';

export const styles = StyleSheet.create({
  tabStyles: {
    // paddingHorizontal: 12,
    // paddingBottom: 20,
    // paddingTop:12,
    // height: 36,
    // justifyContent: 'center',
    // marginRight: 8,
    // alignItems: 'center',
    // fontSize: 14,
    // fontWeight: 500,
    paddingHorizontal: 12,
    paddingVertical: 10,
    // paddingBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
  },
  tabsContainer: {
    marginBottom: 12,
  },

  fullWidth: {
    width: '100%',
  },
  container: {
    width: 250,
  },
  searchContainer: {
    borderBottomColor: Colors.Custom.Gray200,
    borderBottomWidth: 1,
    paddingBottom: 14,
  },
  searchTitle: {
    marginHorizontal: 4,
    marginVertical: 8,
  },
  checkbox: {
    marginVertical: 10,
  },
  checkboxText: {
    marginLeft: 8,
  },
  noDataText: {
    marginTop: 8,
  },

  filterView: {
    overflow: 'scroll'
  },
  memberFilterWrapper: {
    width: 250
  },
  memberFilterHeader: {
    borderBottomColor: Colors.Custom.Gray200,
    borderBottomWidth: 1,
    paddingBottom: 14,
  },
  memberFilterTitle: {
    marginHorizontal: 4,
    marginVertical: 8
  },
  memberList: {
    maxHeight: 400,
    overflow: 'scroll'
  },
  checkboxItem: {
    marginVertical: 10
  },
  checkboxItemContent: {
    marginLeft: 8,
    marginVertical: 6
  },
  statusList: {
    paddingLeft: 16
  },
  statusItem: {
    marginVertical: 10
  },
  statusItemText: {
    marginLeft: 8
  },
  datePickerContainer: {
    marginLeft: 4
  },
  priorityList: {
    paddingLeft: 16,
  },
  dueDateListContainer: {
    paddingLeft: 16,
  },
  dueDateCheckbox: {
    marginVertical: 10,
  },
  datePicker: {
    height: '44px',
    width: 125,
    borderColor: Colors.Custom.Gray300,
    borderRadius: 8,
    fontWeight: '400',
    fontSize: 16,
  },
  dateLabel: {
    fontSize: 14,
    color: 'gray.700',
    fontWeight: '500',
    marginLeft: 3
  },

  container100: {
    width: '100%',
  },
  columnStack: {
    marginRight: -12,
    marginLeft: -12,
  },
  rowStack: {
    alignItems: 'center',
    paddingTop: -6,
    marginTop: -6,
    paddingLeft: 12,
    paddingRight: 8,
  },
  filterText: {
    fontSize: 14,
    fontWeight: '600',
    color: Colors?.Custom?.MonochromeBlack,
  },
  spacer: {
    flex: 1,
  },
  divider: {
    width: 1,
    height: 20,
    // backgroundColor: Colors.Custom.Gray200,
    marginHorizontal: 8,
  },
  dividerMargin: {
    marginTop: 6,
    marginBottom: 12,
  },
  toDateContainer: {
    marginLeft: 4,
  },
});
