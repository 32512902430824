import React from 'react';
import {Svg, Path} from 'react-native-svg';
import {ICommonSvgProps} from '../interfaces';
import {Colors} from '../../../../styles/Colors';

const RevertSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props.width || '16'}
      height={props.height || '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <Path
        d="M3.5 8.49935L6.83333 5.16602M3.5 8.49935L6.83333 11.8327M3.5 8.49935L10.1667 8.49935C11.2778 8.49935 13.5 9.16602 13.5 11.8327"
        stroke={props?.customStrokeColor || Colors.FoldPixel.GRAY300}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default React.memo(RevertSvg);
