import { Colors } from '../../../styles/Colors';
import { ICommonSvgProps } from "./interfaces";

const CopySvg = (props: ICommonSvgProps) => {

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 12.6663C2.89543 12.6663 2 11.7709 2 10.6663V6.66634C2 4.15218 2 2.89511 2.78105 2.11406C3.5621 1.33301 4.81918 1.33301 7.33333 1.33301H10C11.1046 1.33301 12 2.22844 12 3.33301M8 14.6663H10C11.8856 14.6663 12.8284 14.6663 13.4142 14.0806C14 13.4948 14 12.552 14 10.6663V7.33301C14 5.44739 14 4.50458 13.4142 3.91879C12.8284 3.33301 11.8856 3.33301 10 3.33301H8C6.11438 3.33301 5.17157 3.33301 4.58579 3.91879C4 4.50458 4 5.44739 4 7.33301V10.6663C4 12.552 4 13.4948 4.58579 14.0806C5.17157 14.6663 6.11438 14.6663 8 14.6663Z" stroke="url(#paint0_linear_1667_4953)" stroke-linecap="round"/>
    <defs>
    <linearGradient id="paint0_linear_1667_4953" x1="8" y1="-9.13366" x2="-7.11964" y2="-0.542344" gradientUnits="userSpaceOnUse">
    <stop stop-color="#1E9DAE"/>
    <stop offset="1" stop-color="#D478FF"/>
    </linearGradient>
    </defs>
    </svg>
    
  );
};

export default CopySvg; 
