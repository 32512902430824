import {
  ITeamMemberData,
  IStructureData,
  IRoles,
  ITeamRecord,
  IBranchRecord,
} from '../components/RightSideContainer/Contacts/TeamMembers';

export const teamMembersDummyData: ITeamMemberData[] = [
  {
    id: 0.7277339823547124,
    firstName: 'Chandler',
    genderName: '',
    birthDate: '',
    middleName: 'abc',
    lastName: 'Bing',
    email: 'cbing@gmail.com',
    invitationStatus: 'not verified',
    status: 'active',
    role: 'Support Team',
    phone: '9874563210',
  },
  {
    id: 0.9135088194947616,
    firstName: 'Abc',
    genderName: '',
    birthDate: '',
    lastName: 'Def',
    middleName: 'abc',
    email: 'abcdef@gmail.com',
    invitationStatus: 'not verified',
    status: 'inactive',
    role: 'Sales Team',
    phone: '1234567892',
  },
  {
    id: 0.9438181593769182,
    firstName: 'John',
    genderName: '',
    birthDate: '',
    middleName: 'abc',
    lastName: 'Doe',
    email: 'johndoe@gmail.com',
    invitationStatus: 'not verified',
    status: 'inactive',
    role: 'Support Team',
    phone: '7896541230',
  },
  {
    id: 1,
    firstName: 'Abbe',
    genderName: '',
    birthDate: '',
    middleName: 'abc',
    lastName: 'Grebert',
    email: 'agrebert0@themeforest.net',
    invitationStatus: 'not verified',
    status: 'inactive',
    role: 'Sales Team',
    phone: '7713712673',
  },
  {
    id: 2,
    firstName: 'Jefferey',
    genderName: '',
    birthDate: '',
    middleName: 'abc',
    lastName: 'Wiggins',
    email: 'jwiggins1@aol.com',
    invitationStatus: 'not verified',
    status: 'inactive',
    role: 'Sales Team',
    phone: '4253744017',
  },
  {
    id: 3,
    firstName: 'Bo',
    genderName: '',
    birthDate: '',
    middleName: 'abc',
    lastName: 'Branthwaite',
    email: 'bbranthwaite2@usgs.gov',
    invitationStatus: 'not verified',
    status: 'inactive',
    role: 'Sales Team',
    phone: '8254921198',
  },
  {
    id: 4,
    firstName: 'Peyter',
    genderName: '',
    birthDate: '',
    middleName: 'abc',
    lastName: 'Garfirth',
    email: 'pgarfirth3@com.com',
    invitationStatus: 'not verified',
    status: 'inactive',
    role: 'Sales Team',
    phone: '9103647675',
  },
  {
    id: 5,
    firstName: 'Baxter',
    genderName: '',
    birthDate: '',
    middleName: 'abc',
    lastName: 'Axford',
    email: 'baxford4@shutterfly.com',
    invitationStatus: 'not verified',
    status: 'inactive',
    role: 'Sales Team',
    phone: '8698144256',
  },
  {
    id: 6,
    firstName: 'Vincent',
    genderName: '',
    birthDate: '',
    middleName: 'abc',
    lastName: 'Jacqueme',
    email: 'vjacqueme5@harvard.edu',
    invitationStatus: 'not verified',
    status: 'inactive',
    role: 'Sales Team',
    phone: '9311349566',
  },
  {
    id: 7,
    firstName: 'Finlay',
    genderName: '',
    birthDate: '',
    middleName: 'abc',
    lastName: 'Schweir',
    email: 'fschwier6@freewebs.com',
    invitationStatus: 'not verified',
    status: 'inactive',
    role: 'Sales Team',
    phone: '8229433379',
  },
];

export const structureDummyData: IStructureData = {
  id: Math.random(),
  name: 'Lorem Ipsum',
  role: 'Admin',
  phone: '1234567890',
  email: 'loremipsum@gmail.com',
};

export const rolesDummyData: IRoles[] = [
  {
    id: 'Admin',
    value: 'Admin',
  },
  {
    id: 'Marketing Team',
    value: 'Marketing Team',
  },
  {
    id: 'Sales Team',
    value: 'Sales Team',
  },
  {
    id: 'Support Team',
    value: 'Support Team',
  },
];

export const teamRecordDummyData: ITeamRecord[] = [
  {
    id: Math.random(),
    teamAdmin: '0.9438181593769182',
    teamName: 'Team A',
    teamMembers: ['0.7277339823547124', '0.9135088194947616'],
  },
  {
    id: Math.random(),
    teamAdmin: '0.9438181593769182',
    teamName: 'Team B',
    teamMembers: ['0.9135088194947616', '0.7277339823547124'],
  },
  {
    id: Math.random(),
    teamAdmin: '1',
    teamName: 'Team C',
    teamMembers: ['2', '5', '6'],
  },
  {
    id: Math.random(),
    teamAdmin: '4',
    teamName: 'Team D',
    teamMembers: ['3', '5', '6', '7'],
  },
];

export const branchRecordDummyData: IBranchRecord[] = [
  {
    id: Math.random(),
    branchName: 'Branch A',
    branchAddress: '123 ABC st.',
    branchEmail: 'brancha@email.com',
    branchPhone: '9874563258',
    branchTeamMembers: ['1', '2', '3'],
  },
  {
    id: Math.random(),
    branchName: 'Branch B',
    branchAddress: '456 DEF st.',
    branchEmail: 'branchb@email.com',
    branchPhone: '9874632100',
    branchTeamMembers: ['0.9438181593769182', '0.9135088194947616', '6'],
  },
  {
    id: Math.random(),
    branchName: 'Branch C',
    branchAddress: '789 GHI st.',
    branchEmail: 'branchc@email.com',
    branchPhone: '1236547890',
    branchTeamMembers: ['0.9438181593769182', '4', '5', '7'],
  },
];
