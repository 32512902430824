import { BILLING_HOOK } from "./BillingHookInterfaces";

export const BILLING_ACTIVITIES_PAGE_LIMIT = 10;

export const BILLED_ACTIVITIES_PAGE_LIMIT = 10;

export const ALL_SELECTED = {
    value: 'All',
    code: 'ALL'
}

export const ALL_PROGRAM_TYPE = {
    categoryId: BILLING_HOOK.ALL,
    code: BILLING_HOOK.ALL,
    id: BILLING_HOOK.ALL,
    value: ALL_SELECTED.value,
}

export const ALL_PROVIDERS = {
    id: BILLING_HOOK.ALL,
    name: ALL_SELECTED.value,
    uuid: BILLING_HOOK.ALL,
    email: ''
  }

export const ALL_CHRONIC_CONDITIONS = {
    id: BILLING_HOOK.ALL,
    title: ALL_SELECTED.value,
  }

export const BILLING_FILTER_ACTION_CODES = {
    PROGRAM_TYPE: 'PROGRAM_TYPE',
    CPT_CODE: 'CPT_CODE',
    PROVIDER: 'PROVIDER',
    CHRONIC_CONDITION: 'CHRONIC_CONDITION',
    DATE_FILTER: 'DATE_FILTER',
    PAGINATION: 'PAGINATION',
    SEND_CLAIM_TO_EHR: 'SEND_CLAIM_TO_EHR',
    REFRESH: 'REFRESH',
    MEMBER: 'MEMBER',
}

export enum ACTIVITY_RESOURCE_TYPES {
  MESSAGE = 'MESSAGE',
  MEMBERSHIP = 'MEMBERSHIP',
  APPOINTMENT = 'APPOINTMENT',
  AUTOMATION = 'AUTOMATION',
  CALL = 'CALL',
  VOICE_CALL = 'VOICE_CALL',
  FORM = 'FORM',
  TASK = 'TASK',
  VIDEO_CALL = 'VIDEO_CALL',
};