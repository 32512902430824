import {Drawer} from 'antd';
import {HStack, Text, View, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
//import BaseService from '../../../../services/CommonService/BaseService';
import {Colors} from '../../../../styles';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {IAddOrUpdateNewIvrFlowView} from './interfaces';
import { styles } from '../cloudTelephonyStyles';

const AddUpdateIvrFlow = (props: IAddOrUpdateNewIvrFlowView) => {
  //const {userList} = props;
  //const axios = BaseService.getSharedInstance().axios;
  const [stateData, setStateData] = useState({
    showModal: true,
    loading: false,
  });
  const onCancel = () => {
    setStateData((prev) => {
      return {
        ...prev,
        showModal: false,
      };
    });
    props.onFormCompleteAction(COMMON_ACTION_CODES.CANCEL);
  };

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: false,
      };
    });
  }, []);

  const {width} = Dimensions.get('window');
  const finalWidth = (width * 2) / 3;
  return (
    <Drawer
      visible={stateData.showModal}
      width={finalWidth}
      closable={false}
      onClose={() => {
        onCancel();
      }}
      title={
        <HStack
          style={styles.aiCenterJcSpaceBetween}
        >
          <Text>
            <DisplayText
              size={'xslMedium'}
              extraStyles={{color: Colors.Custom.Gray700}}
              textType={'Heading'}
              textLocalId={'newIVRFlow'}
            />
          </Text>
        </HStack>
      }
    >
      <View>
        <VStack flex={1}></VStack>
      </View>
    </Drawer>
  );
};

export default AddUpdateIvrFlow;
