import {HStack, Skeleton, View} from 'native-base';
import {styles} from './MessagingSkeletonStyle';
const MessagingSkeleton = (props: {numOfCOnversation?: number}) => {
  const numOfCOnversation = props.numOfCOnversation || 4;

  const getMessagingSkeletonElem = () => {
    const msgElem = [];
    for (let index = 0; index < numOfCOnversation; index++) {
      let elem = <></>;
      if (index % 2 === 0) {
        elem = (
          <HStack key={index} flex={1} flexDirection={'row'}>
            <View style={[styles.rceMbox, styles.rceMboxLeft]}>
              <Skeleton.Text lines={2} />
            </View>
          </HStack>
        );
      } else {
        elem = (
          <HStack key={index} flex={1} flexDirection={'row-reverse'}>
            <View style={[styles.rceMbox, styles.rceMboxRight]}>
              <Skeleton.Text lines={2} />
            </View>
          </HStack>
        );
      }
      msgElem.push(elem);
    }
    return <>{msgElem}</>;
  };

  return <>{getMessagingSkeletonElem()}</>;
};

export default MessagingSkeleton;
