import {HStack, ScrollView, Text, View, VStack} from 'native-base';
import {IProductVariantData} from '../../../../../../../services/ProductAndServices/interfaces/ProductVariant';
import {IStockInfo} from '../interfaces';
import {StockInfoHeader} from './StockInfoHeader';
import {StockLocationInfo} from './StockLocationInfo';

export const StockInfo = (props: IStockInfo) => {
  const {productVariantList, stockLocationList, onStockInfoActionPerformed} =
    props;
  const getVariantName = (singleVariant: IProductVariantData): string => {
    let variantName =
      (singleVariant?.attributes?.sku || '') +
      (singleVariant?.attributes?.options_text || '');

    if (!variantName) {
      variantName = 'Default';
    }
    return variantName;
  };
  const filterProductVariants =
    productVariantList.filter((singleVariant) => {
      return singleVariant?.relationships?.stock_items?.data?.length > 0;
    }) || [];
  return (
    <View>
      <View height={400}>
        <StockInfoHeader />
        <ScrollView>
          {filterProductVariants.map((singleVariantData) => {
            return (
              <HStack>
                <VStack
                  flex={0.2}
                  justifyContent="center"
                  alignContent={'center'}
                >
                  <View>
                    <HStack flex={1}>
                      <VStack ml={5}>
                        <HStack width={'80%'}>
                          <Text size={'mdMedium'}>
                            {getVariantName(singleVariantData)}
                          </Text>
                        </HStack>
                        {/* <HStack pt={2}>
                            <Checkbox value="" isChecked={true}>
                              <Text ml={2}>Track Inventory</Text>
                            </Checkbox>
                          </HStack> */}
                      </VStack>
                    </HStack>
                  </View>
                </VStack>
                <VStack flex={0.8}>
                  <StockLocationInfo
                    stockLocationList={stockLocationList || []}
                    singleVariantData={singleVariantData}
                    onStockLocationInfoActionPerformed={
                      onStockInfoActionPerformed
                    }
                  />
                </VStack>
              </HStack>
            );
          })}
        </ScrollView>
      </View>
    </View>
  );
};
