import axios from "axios";
import { CRM_BASE_URL } from "../../../../constants/Configs";
import BaseService from "../../../../services/CommonService/BaseService";
import {IAllowOneTwoOnePatientChatBodyData, ICreateOrUpdateAccountConfig} from "./interface";
import LocalStorage from "../../../../utils/LocalStorage";

const getHeaders = async () => {
  const userDataString = await LocalStorage.getItem('user');
  const userData = JSON.parse(userDataString);
  const accountUUID =
    userData?.data.accounts.length > 0
      ? userData?.data.accounts[0].uuid || ''
      : '';
  const responseAccessToken = userData?.accessToken || '';
  const fold_access_token = userData?.fold_access_token;
  const headersObj: any = {
    'access-token': responseAccessToken,
    fold_access_token,
    accountUUID,
  };
  return headersObj;
};

export const addUserToDefaultCommunicationGroup = async (accountId: number, userUuid: string, practiceLocationUuid: string) => {
  const baseService = BaseService.getSharedInstance().axios;
    const addUserUrl = `/crm-nest/account/${accountId}/default-group/member`;
    const bodyData = {
      userIds: [userUuid],
      practiceLocationUuid: practiceLocationUuid
    };
    const groupListInfo = await baseService
      .post(addUserUrl, bodyData)
      .catch(e => {
        // catch
      });
  return groupListInfo;
}


export const removeUserFromDefaultCommunicationGroup = async (accountId: number, userUuid: string, practiceLocationUuid: string) => {
  const baseService = BaseService.getSharedInstance().axios;
    const removeUserUrl = `/crm-nest/account/${accountId}/default-group/member`;
    const bodyData = {
      userIds: [userUuid],
      practiceLocationUuid: practiceLocationUuid
    };
    const groupListInfo = await baseService
      .delete(removeUserUrl, {
        data: bodyData
      }).catch(e => {
        // catch
      });
  return groupListInfo;
}


export const createOrUpdateAccountConfiguration = async (bodyData: ICreateOrUpdateAccountConfig) => {
  const baseService = BaseService.getSharedInstance().axios;
    const createOrUpdateAccountConfigUrl = `/crm-nest/configuration`;
    const groupListInfo = await baseService
      .post(createOrUpdateAccountConfigUrl, {
        ...bodyData
      })
      .catch(() => {
        // catch
      });
  return groupListInfo;
}

export const allowOneTwoOnePatientChat = async (bodyData:IAllowOneTwoOnePatientChatBodyData,accountId:string) => {
  const headers = await getHeaders();
  const baseService = BaseService.getSharedInstance().axios;
  const url = `${CRM_BASE_URL}/account/${accountId}/default-conversation-user`;
  const conversationData = await axios.post(url, bodyData,{headers: headers});
  return conversationData;
}

export const getDefaultConversationData = async (
  accountId?: number | string,
  contactUuid?: string
) => {
  if (contactUuid && accountId) {
    const baseService = BaseService.getSharedInstance().axios;
    const url = `crm-nest/account/${accountId}/default-conversation-user`;
    const response = await baseService.get(url, {
      params: {
        contactUuid: contactUuid,
      },
    });
    return response?.data;
  }
  return '';
};
