import BaseService from '../../services/CommonService/BaseService';
import ConversationsQueries from '../../services/Conversations/ConversationsQueries';
import {IUpdateConversationMentionsData} from '../../utils/interfaces';
import {
  getConversationMentionsData,
  getUserIdsFromMsgContent,
  sendNotificationOnMention,
} from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/ConversationMentions/ConversationMentions';
import {IConversationMentions} from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/ConversationMentions/interface';

export const updateConversationMentionsData = async (
  data: IUpdateConversationMentionsData
) => {
  const {args, groupMemberCodeIdObj, accountUUID, userUuid} = data;
  const {msgTempData, selectedConversation} = args;

  const userListData = getUserIdsFromMsgContent(
    msgTempData.content,
    groupMemberCodeIdObj
  );

  if (userListData?.length) {
    const messageResp = await getMessageDataObject(msgTempData?.id);
    if (messageResp?.data?.message?.id === msgTempData.id) {
      const conversationMentionData = getConversationMentionsData({
        accountUUID: accountUUID,
        userUUID: userUuid,
        msgData: messageResp?.data?.message,
        selectedConversationData: selectedConversation,
        userList: userListData,
      });
      const mentionResponse = await createConversationMentions(
        conversationMentionData
      );
      if (selectedConversation?.uuid && selectedConversation?.displayId)
        await sendNotificationOnMention({
          mentionResponse:
            mentionResponse?.data?.createConversationMentions?.returning || [],
          conversationUuid: selectedConversation?.uuid,
          displayId: selectedConversation?.displayId,
        });
    }
  }
};

const getMessageDataObject = async (messageId: number) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = '/crm-nest/platform';
  const messageObject = {
    operationName: 'getMentionMessageDataIdData',
    query: ConversationsQueries.getMentionMessageDataIdData,
    variables: {
      messageId: messageId,
    },
  };
  const response = await baseService.post(url, messageObject);
  return response?.data;
};

const createConversationMentions = async (
  conversationMentionData: IConversationMentions[]
) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = '/crm-nest/platform';
  const messageObject = {
    operationName: 'createConversationMentionsByMessage',
    query: ConversationsQueries.createConversationMentionsByMessage,
    variables: {
      mentionDataList: conversationMentionData,
    },
  };
  const response = await baseService.post(url, messageObject);
  return response?.data;
};
