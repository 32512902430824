import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {evnName} from '../../../../../constants/BaseUrlConst';
import {getCurrentSubdomainWeb} from '../../../../../utils/commonUtils';
import BillingProductDetail from './BillingProductDetail';
import MembershipProductDetail from './MembershipProductDetail';
const MembershipProductDetailMainView = () => {
  const [productMainViewState, setProductMainViewState] = useState({
    isLoading: true,
    productDetailData: {},
  });

  const location = useLocation();
  let isOneTime = false;
  if (location?.state?.isOneTime) {
    isOneTime = location?.state?.isOneTime;
  }

  const isFoldAccount = () => {
    const subdomain = getCurrentSubdomainWeb();
    if (subdomain === `fold.${evnName()}foldhealth.io`) {
      return true;
    }
    return false;
  };
  const onProductDetailViewActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ADD:
        setProductMainViewState((prev) => {
          return {
            ...prev,
            isLoading: false,
            productDetailData: actionData,
          };
        });
        break;
    }
  };

  return (
    <>
      {!isFoldAccount() && (
        <MembershipProductDetail
          productData={productMainViewState.productDetailData as any}
          onActionPerformed={onProductDetailViewActionPerformed}
          isOneTime={isOneTime}
        />
      )}
      {isFoldAccount() && (
        <BillingProductDetail
          productData={productMainViewState.productDetailData as any}
          onActionPerformed={onProductDetailViewActionPerformed}
        />
      )}
    </>
  );
};

export default MembershipProductDetailMainView;
