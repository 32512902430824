import React from 'react';
import { Pressable } from 'react-native';
import { useQuery } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { Skeleton } from 'native-base';
import { Progress } from 'antd';
import { getGoalProgressBarColor } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Goals/AddOrUpdateGoals/GoalUtils';
import GoalInfoView from './GoalInfoView';
import { GET_CARE_PLAN_GOAL_PROGRESSES_BY_CONTACT_ID } from '../../../services/CarePlan/CarePlanQueries';

interface ICarePlanGoalProgressBarProps {
  contactId: string;
  onActionableClick: () => void;
}

const CarePlanGoalProgressBar = (props: ICarePlanGoalProgressBarProps) => {
  const { contactId, onActionableClick } = props;
  const carePlanGoals = useQuery(
    GET_CARE_PLAN_GOAL_PROGRESSES_BY_CONTACT_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT,},
      variables: {
        contactId: contactId,
      },
    }
  );

  if (carePlanGoals.loading) return <Skeleton.Text lines={1} />;

  const progressSum = carePlanGoals.data?.getContactCarePlans?.carePlans?.[0]?.goals?.reduce((prev: any, curr: { goalProgress: any; }) => {
    return prev + curr.goalProgress || 0;
  }, 0);

  const progress = (progressSum / carePlanGoals.data?.getContactCarePlans?.carePlans?.[0]?.goals?.length) || 0;

  return (
    <Pressable
      onPress={onActionableClick}
    >
      {progress >= 0 && progress < 100 ? (
        <Progress
          percent={progress}
          strokeColor={getGoalProgressBarColor(progress)}
          style={{
            height: 15,
            width: 200,
          }}
        />
      ) : (
        <GoalInfoView goalProgress={progress} />
      )}
    </Pressable>
  );
};

export default CarePlanGoalProgressBar;
