import React, {FC} from 'react';

interface ItemProps {
  label: string;
}

const ItemDescription: FC<ItemProps> = ({label, children}) => {
  return (
    <div
      className="w-full flex flex-row items-center justify-start pb-2"
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flext-start',
        paddingBottom: '1rem',
      }}
    >
      <div style={{fontWeight: 600, width: '33.333333%'}}>{label}</div>
      <div style={{width: '66.666667%', color: '#667085', fontWeight: 500}}>
        {children}
      </div>
    </div>
  );
};

export default ItemDescription;
