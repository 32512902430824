import {Button as AntButton, Upload} from 'antd';
import {HStack, View} from 'native-base';
import {useEffect, useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {GROUP_MEMBER_TYPE} from '../../../../constants';
import {Colors} from '../../../../styles';
import {getCurrentUserRoleCodes, getUserId, getUserName} from '../../../../utils/commonUtils';
import {DisplayCardAvatar} from '../../../common/DisplayCard/DisplayCardAvatar';


export const UploadLogo = (props: any) => {
  const {handleProfileImageChange, profileImgSrc, profileImageBlob} =
    props;
  const currentUserRoles = getCurrentUserRoleCodes();
  const userId = getUserId();
  const userName = getUserName()
  const [profileData, setProfileImgSrc] = useState({
    profileImgSrc: '',
  });

  useEffect(() => {
    setProfileImgSrc({...profileData, profileImgSrc: profileImgSrc});
  }, [profileImgSrc]);

  return (
    <View mb={2}>
      <HStack alignItems={'center'} space={8}>
        <DisplayCardAvatar
          avatarStyle={{
            avatarSize: '12',
          }}
          isLetterAvatarShow={true}
          userData={{
            userId: userId,
            userType: GROUP_MEMBER_TYPE.USER,
            imgSrc: profileData.profileImgSrc,
            roles: currentUserRoles,
            userName: userName
          }}
        />
        {!profileImageBlob.uid ? (
          <Upload
            listType="picture"
            multiple={true}
            beforeUpload={() => false}
            onChange={(info: any) => {
              setProfileImgSrc({
                ...profileData,
                profileImgSrc: URL.createObjectURL(info?.file),
              });
              handleProfileImageChange(info, 'setImage');
            }}
            fileList={[]}
            className="msg-attachment"
          >
            <AntButton
              icon={<AntIcon color={Colors.primary[600]} name="totop" />}
            >
              Upload
            </AntButton>
          </Upload>
        ) : (
          <AntButton
            onClick={() => handleProfileImageChange('', 'delete')}
            icon={<AntIcon color={Colors.primary[600]} name="delete" />}
          >
            Delete
          </AntButton>
        )}
      </HStack>
    </View>
  );
};
