import {gql} from '@apollo/client';

export const getAllPayers = gql`
  query getAllPayers{
    payers {
      id
      displayName
      carrierData {
        id
        displayName
      }
    }
  }
`;



export default {
  getAllPayers,
}
