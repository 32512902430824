declare const window: any;
const isAvergentSandbox = window?.location?.host?.includes?.('avergent.sandbox');

export const DASHBOARD_TAB_CODE = {
  PATIENT: 'patient',
  MY_PATIENT: 'myPatient',
  EMPLOYER: 'employer',
  POPULATION_GROUP: 'populationGroup',
};

export const PROGRAM_STEPS_FILTER_CODE = {
  ALL: 'ALL',
  SCHEDULE_F2F: 'annual_wellness_visit',
  CONSENT: 'consent',
  ASSESSMENT: 'assessment',
  CREATE_PLAN: 'care_plan',
  CCM_ENROLLED: 'enrollment',
  TCM_OUTREACH_2DAY: 'tcm_2_day',
  TCM_OUTREACH_14DAY: 'tcm_7_14_day',
  ECM_OUTREACH: 'ecm_outreach'
};

export const MAIN_TAB_CODE = {
  MY_PATIENT: 'myPatient',
  PATIENTS: 'patients',
}

export const DEFAULT_STEPS_CODES = [
  PROGRAM_STEPS_FILTER_CODE.CONSENT,
  PROGRAM_STEPS_FILTER_CODE.ASSESSMENT,
  PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN,
//  PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F  TODO: disable this step by CS-7245 comment
];

export const PROGRAM_FILTER_CODE = {
  CCM: 'CCM',
  TCM: 'TCM',
  ECM: 'ECM',
  AWV_DUE: 'MEDICARE_AWV_DUE',
  ALERTS: 'ALERTS',
  ACCP: 'ACCP',
  CCM_ENROLLED: 'CCM_ENROLLED'
};

export const TAB_CODE = {
  MY_PATIENT: 'MY_PATIENTS',
  PATIENT: 'PATIENT'
};

export const PROGRAM_SUB_FILTER_CODE = {
  AWV_SCHEDULED: 'AWV_SCHEDULED',
  NOT_SCHEDULED: 'NOT_SCHEDULED',
  OTHER_APPOINTMENT: 'OTHER_APPOINTMENT',

  // CCM Sub Filters
  CCM_VISIT_PENDING: 'CCM_VISIT_FILTER',
  CCM_CONSENT_PENDING: 'CCM_CONSENT_PENDING',
  CCM_ASSESSMENT_PENDING: 'CCM_ASSESSMENT_PENDING',
  CCM_ENROLLMENT_PENDING: 'CCM_ENROLLMENT_PENDING',
  CCM_CARE_PLAN_DUE: 'CCM_CARE_PLAN_DUE',
  CCM_CARE_PLAN_ENROLL: 'CCM_CARE_PLAN_ENROLL',

  // TCM Sub Filters
  TCM_OUTREACH_PENDING: 'TCM_OUTREACH_PENDING',
  TCM_APPOINTMENT_PENDING: 'TCM_APPOINTMENT_PENDING',

  // ECM Sub Filters
  ECM_OUTREACH_PENDING: 'ECM_OUTREACH_PENDING',
  ECM_CONSENT_PENDING: 'ECM_CONSENT_PENDING',
  ECM_ASSESSMENT_PENDING: 'ECM_ASSESSMENT_PENDING',
  ECM_CARE_PLAN_DUE: 'ECM_CARE_PLAN_DUE',
  ECM_CARE_PLAN_ENROLL: 'ECM_CARE_PLAN_ENROLL',
}

export const PROGRAM_STEPS_DEFAULT_FILTERS = [
  // {
  //   categoryCode: PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F,
  //   displayName: 'CCM Visit',
  //   count: 0,
  //   sequence: 1,
  // },
  // {
  //   categoryCode: PROGRAM_STEPS_FILTER_CODE.CONSENT,
  //   displayName: 'Get Consent',
  //   count: 0,
  //   sequence: 4,
  // },
  // {
  //   categoryCode: PROGRAM_STEPS_FILTER_CODE.ASSESSMENT,
  //   displayName: 'Send Assessment',
  //   count: 0,
  //   sequence: 5,
  // },
  // {
  //   categoryCode: PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN,
  //   displayName: 'Create Plan',
  //   count: 0,
  //   sequence: 5,
  // },
];

if (isAvergentSandbox) {
  PROGRAM_STEPS_DEFAULT_FILTERS.shift();
}

export const AWV_FILTER_CODE = {
  AWV_SCHEDULED: 'AWV_SCHEDULED',
  NOT_SCHEDULED: 'NOT_SCHEDULED',
  OTHER_APPOINTMENT: 'OTHER_APPOINTMENT',
};

export const PROGRAM_DEFAULT_FILTERS = [
  {
    categoryCode: PROGRAM_FILTER_CODE.AWV_DUE,
    displayName: 'AWV Due',
    code: 'MEDICARE_AWV_DUE',
    count: 0,
    sequence: 7,
    subFilters: [
      // {
      //   categoryCode: AWV_FILTER_CODE.AWV_SCHEDULED,
      //   displayName: 'AWV Scheduled',
      //   code: 'AWV_SCHEDULED',
      //   count: 0,
      //   sequence: 1,
      //   showLeftDivider: true
      // },
      // {
      //   categoryCode: AWV_FILTER_CODE.NOT_SCHEDULED,
      //   displayName: 'Not Scheduled',
      //   code: 'NOT_SCHEDULED',
      //   count: 0,
      //   sequence: 2,
      // },
      {
        categoryCode: AWV_FILTER_CODE.OTHER_APPOINTMENT,
        displayName: 'Other Upcoming Appointment',
        code: 'OTHER_APPOINTMENT',
        count: 0,
        showLeftDivider: true,
        sequence: 1,
      }
    ]
  },
  {
    categoryCode: PROGRAM_FILTER_CODE.CCM,
    displayName: 'CCM',
    code: 'CCM',
    count: 0,
    sequence: 1,
    subFilters: [
      // {
      //   categoryCode: PROGRAM_SUB_FILTER_CODE.CCM_VISIT_PENDING,
      //   displayName: 'Visit Due',
      //   code: PROGRAM_SUB_FILTER_CODE.CCM_VISIT_PENDING,
      //   count: 0,
      //   showLeftDivider: true,
      //   sequence: 1,
      // },
      // {
      //   categoryCode: PROGRAM_SUB_FILTER_CODE.CCM_CONSENT_PENDING,
      //   displayName: 'Consent Due',
      //   code: PROGRAM_SUB_FILTER_CODE.CCM_CONSENT_PENDING,
      //   count: 0,
      //   showLeftDivider: true,
      //   sequence: 2,
      // },
      // {
      //   categoryCode: PROGRAM_SUB_FILTER_CODE.CCM_ASSESSMENT_PENDING,
      //   displayName: 'Assessment Due',
      //   code: PROGRAM_SUB_FILTER_CODE.CCM_ASSESSMENT_PENDING,
      //   count: 0,
      //   showLeftDivider: true,
      //   sequence: 3,
      // },
      {
        categoryCode: PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_DUE,
        displayName: 'Care Plan Due',
        code: PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_DUE,
        count: 0,
        showLeftDivider: true,
        sequence: 4,
      },
      {
        categoryCode: PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_ENROLL,
        displayName: 'Care Plan Active',
        code: PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_ENROLL,
        count: 0,
        showLeftDivider: true,
        sequence: 5,
      }
    ]
  },
  {
    categoryCode: PROGRAM_FILTER_CODE.TCM,
    displayName: 'TCM',
    code: 'TCM',
    count: 0,
    sequence: 2,
    subFilters: [
      {
        categoryCode: PROGRAM_SUB_FILTER_CODE.TCM_OUTREACH_PENDING,
        displayName: 'Outreach Due',
        code: PROGRAM_SUB_FILTER_CODE.TCM_OUTREACH_PENDING,
        count: 0,
        showLeftDivider: true,
        sequence: 1,
      },
      {
        categoryCode: PROGRAM_SUB_FILTER_CODE.TCM_APPOINTMENT_PENDING,
        displayName: 'Appointment Due',
        code: PROGRAM_SUB_FILTER_CODE.TCM_APPOINTMENT_PENDING,
        count: 0,
        showLeftDivider: true,
        sequence: 2,
      }
    ]
  },
  {
    categoryCode: PROGRAM_FILTER_CODE.ECM,
    displayName: 'ECM',
    code: 'ECM',
    count: 0,
    sequence: 3,
    subFilters: [
      {
        categoryCode: PROGRAM_SUB_FILTER_CODE.ECM_OUTREACH_PENDING,
        displayName: 'Outreach Due',
        code: PROGRAM_SUB_FILTER_CODE.ECM_OUTREACH_PENDING,
        count: 0,
        showLeftDivider: true,
        sequence: 1,
      },
      {
        categoryCode: PROGRAM_SUB_FILTER_CODE.ECM_CONSENT_PENDING,
        displayName: 'Consent Due',
        code: PROGRAM_SUB_FILTER_CODE.ECM_CONSENT_PENDING,
        count: 0,
        showLeftDivider: true,
        sequence: 2,
      },
      {
        categoryCode: PROGRAM_SUB_FILTER_CODE.ECM_ASSESSMENT_PENDING,
        displayName: 'Assessment Due',
        code: PROGRAM_SUB_FILTER_CODE.ECM_ASSESSMENT_PENDING,
        count: 0,
        showLeftDivider: true,
        sequence: 3,
      },
      {
        categoryCode: PROGRAM_SUB_FILTER_CODE.ECM_CARE_PLAN_DUE,
        displayName: 'Care Plan Due',
        code: PROGRAM_SUB_FILTER_CODE.ECM_CARE_PLAN_DUE,
        count: 0,
        showLeftDivider: true,
        sequence: 4,
      },
      {
        categoryCode: PROGRAM_SUB_FILTER_CODE.ECM_CARE_PLAN_ENROLL,
        displayName: 'Care Plan Active',
        code: PROGRAM_SUB_FILTER_CODE.ECM_CARE_PLAN_ENROLL,
        count: 0,
        showLeftDivider: true,
        sequence: 5,
      }
    ]
  },
  // {
  //   categoryCode: PROGRAM_FILTER_CODE.CCM_ENROLLED,
  //   displayName: 'CCM Enrolled',
  //   code: 'CCM',
  //   count: 0,
  //   sequence: 9,
  // },
  {
    categoryCode: PROGRAM_FILTER_CODE.ALERTS,
    displayName: 'Alerts',
    code: 'ALT',
    count: 0,
    sequence: 8,
  },
];

if (isAvergentSandbox) {
  PROGRAM_DEFAULT_FILTERS.length = 0;
  PROGRAM_DEFAULT_FILTERS.push({
    categoryCode: PROGRAM_FILTER_CODE.ACCP,
    displayName: 'ACCP Eligible',
    code: 'ACCP',
    count: 0,
    sequence: 9,
  });
}

export const CCM_PROGRAM_FILTER = {
  categoryCode: PROGRAM_FILTER_CODE.CCM,
  displayName: 'CCM Eligible',
  count: 0,
  sequence: 5,
};

export const ALL_PATIENT_FILTER = {
  categoryCode: PROGRAM_STEPS_FILTER_CODE.ALL,
  displayName: 'All',
  count: 0,
  sequence: 0,
};

export const CONTACT_TABLE_COLUMN_CODE = {
  MEMBER_NAME: 'MEMBER_NAME',
  RAF: 'RAF',
  CONTACT_INFO: 'CONTACT_INFO',
  CONDITION: 'CONDITION',
  ELIGIBLE_FOR: 'ELIGIBLE_FOR',
  BILLING_PRACTITIONER: 'BILLING_PRACTITIONER',
  HCC_V24: 'HCC_V24',
  HCC_V28: 'HCC_V28',
  FOLD_SCORE: 'FOLD_SCORE',
  ACTION: 'ACTION',
  TAGS: 'TAGS',
  ENROLLMENT: 'ENROLLMENT',
  ENROLLMENT_DATE: 'ENROLLMENT_DATE',
  CARE_STATUS: 'CARE_STATUS',
  CARE_PLAN_STATUS: 'CARE_PLAN_STATUS',
  INTERNAL_TASK: 'INTERNAL_TASK',
  // TCM
  ADMITTED_DATE: 'admittedDate',
  DISCHARGE_DATE: 'dischargeDate',
  FACILITY: 'facility',
  FACILITY_TYPE: 'facilityType',
  NEXT_APPOINTMENT: 'NEXT_APPOINTMENT',
  DISCHARGE_TO: 'dischargeTo',
  COMPLEXITY: 'COMPLEXITY',
  PRIMARY_DIAGNOSIS_CODE: 'PRIMARY_DIAGNOSIS_CODE',
  TCM_ATTENDING_PHYSICIAN: 'TCM_ATTENDING_PHYSICIAN',
  DAYS_ELAPSED: 'DAYS_ELAPSED',
  TCM_STATUS: 'TCM_STATUS',
  NEXT_PCP_APPOINTMENT: 'NEXT_PCP_APPOINTMENT',
  // ECM
  ENROLLMENT_STATUS: 'ENROLLMENT_STATUS',
  // AWV
  AWV_DUE: 'AWV_DUE',
  HEALTH_PLAN: 'HEALTH_PLAN',
  // AWV Sub Filters
  AWV_SCHEDULED: 'AWV_SCHEDULED',
  NOT_SCHEDULED: 'NOT_SCHEDULED',
  OTHER_APPOINTMENT: 'OTHER_APPOINTMENT',
  PCP: 'PCP',
  LAST_VIST: 'LAST_VIST',
  GOAL_PROGRESS: 'GOAL_PROGRESS',
  LOCATION: 'LOCATION',
  //CCM
  RISK: 'RISK',
  CCM_STATUS: 'CCM_STATUS',
  BILLABLE: 'BILLABLE',
  FOLD_ID: 'FOLD_ID',
  TIME_SPENT: 'TIME_SPENT',
  CLIENT_ID: 'CLIENT_ID',
  CASE_MANAGEMENT_FLAGS: 'CASE_MANAGEMENT_FLAGS',
  CARE_MANAGEMENT_FLAGS: 'CARE_MANAGEMENT_FLAGS',
  NUMBER_OF_DIAGNOSIS_GAPS: 'NUMBER_OF_DIAGNOSIS_GAPS'
} as const;

export const DEFAULT_DASHBOARD_COLUMN_CODES = [
  CONTACT_TABLE_COLUMN_CODE.MEMBER_NAME,
  CONTACT_TABLE_COLUMN_CODE.CONTACT_INFO,
  CONTACT_TABLE_COLUMN_CODE.CONDITION,
  CONTACT_TABLE_COLUMN_CODE.ELIGIBLE_FOR,
  CONTACT_TABLE_COLUMN_CODE.BILLING_PRACTITIONER,
  CONTACT_TABLE_COLUMN_CODE.TAGS,
  CONTACT_TABLE_COLUMN_CODE.ENROLLMENT,
  CONTACT_TABLE_COLUMN_CODE.ACTION,
];

export const STEP_STATUS = {
  FAIL: 'fail',
  TODO: 'to_do',
  DONE: 'done'
}

export const AFTER_PROCESS_CONTACT_API_CODE  = {
  CONSENT: 'CONTACT_CONSENT',
  TAG: 'CONTACT_TAG',
  CHRONIC_CONDITION: 'CHRONIC_CONDITION',
  CARE_PROGRAM: 'CARE_PROGRAM',
  MEMBERSHIP: 'MEMBERSHIP',
  CARE_TEAM: 'CARE_TEAM'
}

export const DEFAULT_AFTER_PROCESS_CONTACT_API_CODE = [
  AFTER_PROCESS_CONTACT_API_CODE.CONSENT,
  AFTER_PROCESS_CONTACT_API_CODE.TAG,
  AFTER_PROCESS_CONTACT_API_CODE.CHRONIC_CONDITION,
  AFTER_PROCESS_CONTACT_API_CODE.CARE_PROGRAM,
  AFTER_PROCESS_CONTACT_API_CODE.CARE_TEAM
]

export const CARE_PROGRAM_STEP_TYPE = {
  ANNUAL_VISIT: 'annual_wellness_visit',
  CONSENT: 'consent',
  ASSESSMENT: 'assessment',
  ENROLLMENT: 'enrollment',
  CARE_PLAN: 'care_plan',
  TCM_OUTREACH_2DAY: 'tcm_2_day',
  TCM_OUTREACH_14DAY: 'tcm_7_14_day',
  ECM_OUTREACH: 'ecm_outreach',
  USER_ASSESSMENT: 'user_assessment',
  REFERRAL: 'referral',
  BILLING: 'billing',
};

export const ADDITIONAL_ATTRIBUTE_KEY = {
  DISCHARGE_TO: 'dischargeTo',
  FACILITY: 'facility',
  FACILITY_TYPE: 'facilityType',
  ADMITTED_DATE: 'admittedDate',
  DISCHARGE_DATE: 'dischargeDate',
  PRIMARY_DIAGNOSIS_CODE: 'primaryDiagnosisCode',
  ATTENDING_PHYSICIAN: 'attendingPhysician'
} as const;

export const ATHENA_COVERAGE_TYPE = {
  HMO: 'HMO',
  INDEMNITY: 'INDEMNITY',
  OTHER: 'OTHER',
  POS: 'POS',
  PPO: 'PPO',
  BEHAVIORAL_HEALTH: 'Behavioral health',
  CONTRACTS: 'Contracts',
  EPO: 'EPO',
  LEGAL: 'Legal',
  MVA: 'MVA',
  MEDICAID_CHIP: 'Medicaid CHIP',
  MEDICAID_HMO: 'Medicaid HMO',
  MEDICAID_PCCM: 'Medicaid PCCM',
  MEDICAID_TRADITIONAL: 'Medicaid-Traditional',
  MEDICARE_B_TRADITIONAL: 'Medicare B-Traditional',
  MEDICARE_HMO: 'Medicare HMO',
  MEDICARE_POS: 'Medicare POS',
  MEDICARE_PPO: 'Medicare PPO',
  MEDICARE_PRIVATE_FFS: 'Medicare Private FFS',
  MEDICARE_SUPPLEMENTAL_PLAN: 'Medicare Supplemental Plan',
  RADIOLOGY: 'Radiology',
  VISION: 'Vision',
  WORKERS_COMP: 'Workers Comp',
};

export const ELATION_COVERAGE_TYPE = {
  COMMERCIAL: 'Commercial',
  MEDICARE: 'Medicare',
  MEDICAID: 'Medicaid',
  WORKER_COMPENSATION: "Worker's Compensation",
};

export const AWV_VISIT_CODE = {
  INITIAL_VISIT: 'G0402',
  AWV_VISIT: 'G0438',
  SUB_SEQUENT_VISIT: 'G0439',
}

export const AWV_POP_GROUP_CODE = 'MEDICARE_AWV_DUE'

export const FUTURE_AWV_API_CODE = 'MEDICARE_AWV_DUE_NEXT_TWO_MONTH'

export const AWV_SCHEDULED_SUB_FILTER_CODE = {
  AWV_SCHEDULED: 'AWV_SCHEDULED',
  NOT_SCHEDULED: 'AWV_NOT_SCHEDULED',
  OTHER_APPOINTMENT: 'AWV_OTHER_FUTURE_APPOINTMENT_PRESENT',
}

export const TOOLTIP_STATUS_TEXT = {
  PENDING: 'pending',
  IN_PROGRESS: 'form sent',
  COMPLETED: 'completed',
};

export const TOOLTIP_STATUS_CARE_MAP = {
  [TOOLTIP_STATUS_TEXT.PENDING]: 'not created yet',
  [TOOLTIP_STATUS_TEXT.IN_PROGRESS]: 'not assigned yet',
  [TOOLTIP_STATUS_TEXT.COMPLETED]: 'assigned'
};

export const ENROLLMENT_TOOLTIP_STATUS_MAP = {
  [TOOLTIP_STATUS_TEXT.IN_PROGRESS]: 'Pending',
  [TOOLTIP_STATUS_TEXT.PENDING]: 'Pending',
};

export const TOOLTIP_STATUS_CONSENT_MAP = {
  [TOOLTIP_STATUS_TEXT.COMPLETED]: 'received',
};

export const TOOLTIP_STATUS_ANNUAL_VISIT_MAP = {
  [TOOLTIP_STATUS_TEXT.IN_PROGRESS]: 'scheduled',
};

export const AWV_TYPE_LIST = {
  FUTURE_APPOINTMENT: 'futureAppointment',
  NOT_SCHEDULED: 'notScheduled',
  AWV_SCHEDULED: 'awvScheduled',
  AWV_COMPLETED: 'awvCompleted'
}

export const PATIENT_FILTERS_INFO = {
  [PROGRAM_FILTER_CODE.CCM]: "Two chronic conditions lasting at least one year.",
  [PROGRAM_FILTER_CODE.ACCP]: "Three or more chronic conditions lasting at least one year.",
  [PROGRAM_FILTER_CODE.TCM]: "Manages the first 30 days post-discharge.",
  [PROGRAM_FILTER_CODE.AWV_DUE]: "AWV visit not conducted for the patient within the last 12 months.",
  [PROGRAM_FILTER_CODE.ECM]: "Coming from monthly eligibility file.",
  [PROGRAM_FILTER_CODE.CCM_ENROLLED]: "CCM enrolled patients",
  [PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN]: "CCM and ECM enrolled patients that are eligible for care plan assignment",
  [PROGRAM_STEPS_FILTER_CODE.ASSESSMENT]: "Dispatch comprehensive health evaluation.",
  [PROGRAM_STEPS_FILTER_CODE.CONSENT]: "Secure authorization for care management.",
  [PROGRAM_STEPS_FILTER_CODE.SCHEDULE_F2F]: "Comprehensive E&M visits for chronic conditions.",
  [PROGRAM_SUB_FILTER_CODE.AWV_SCHEDULED]: "AWV visit not conducted within the last 12 months but the patient has a future AWV visit scheduled",
  [PROGRAM_SUB_FILTER_CODE.NOT_SCHEDULED]: "AWV visit not conducted within the last 12 months and no future AWV visits scheduled for the patient",
  [PROGRAM_SUB_FILTER_CODE.OTHER_APPOINTMENT]: "Next Upcoming Appointment",
  [PROGRAM_FILTER_CODE.ALERTS]: "Patients having alerts",
}

export enum FoldScoreUsedFacts {
  // Alerts
  PENDING_HIGH_ALERT_7_DAYS = 'PENDING_HIGH_ALERT_7_DAYS',
  PENDING_MEDIUM_ALERT_7_DAYS = 'PENDING_MEDIUM_ALERT_7_DAYS',

  // Appointment
  UPCOMING_APPOINTMENT_7_DAYS = 'UPCOMING_APPOINTMENT_7_DAYS',

  // Care plan
  CARE_PLAN_UPDATED_30_DAYS = 'CARE_PLAN_UPDATED_30_DAYS',

  // Care Program
  CARE_PROGRAM_TCM = 'CARE_PROGRAM_TCM',
  CARE_PROGRAM_CCM = 'CARE_PROGRAM_CCM',
  CARE_PROGRAM_ECM = 'CARE_PROGRAM_ECM',
  CARE_PROGRAM_CCM_ECM = 'CARE_PROGRAM_CCM_ECM',
  NOT_CARE_PROGRAM_CCM_ECM = 'NOT_CARE_PROGRAM_CCM_ECM',

  // Condition
  ANY_CONDITION_30_DAYS = 'ANY_CONDITION_30_DAYS',
  EQ_1_CHRONIC_CONDITION = 'EQ_1_CHRONIC_CONDITION',
  GT_1_CHRONIC_CONDITION = 'GT_1_CHRONIC_CONDITION',

  // Medication Statement
  GTE_5_ACTIVE_MEDICATION = 'GTE_5_ACTIVE_MEDICATION',
  MEDICATION_ADDED_30_DAY = 'MEDICATION_ADDED_30_DAY',
  ACTIVE_5_MEDICATION_MEDICATION_ADDED_30_DAY= '5_ACTIVE_MEDICATION_MEDICATION_ADDED_30_DAY',

  // Patient Engagement
  PATIENT_ENGAGED_30_DAYS = 'PATIENT_ENGAGED_30_DAYS',
  PATIENT_ENGAGED_90_DAYS = 'PATIENT_ENGAGED_90_DAYS',
  PATIENT_ENGAGED_180_DAYS = 'PATIENT_ENGAGED_180_DAYS',
  NOT_PATIENT_ENGAGED_30_DAYS = 'NOT_PATIENT_ENGAGED_30_DAYS',
  NOT_PATIENT_ENGAGED_90_DAYS = 'NOT_PATIENT_ENGAGED_90_DAYS',
  NOT_PATIENT_ENGAGED_180_DAYS = 'NOT_PATIENT_ENGAGED_180_DAYS',

  // Task
  TASK_ADHERENCE_LT_75 = 'TASK_ADHERENCE_LT_75',
  TASK_ADHERENCE_LT_50 = 'TASK_ADHERENCE_LT_50',
  TASK_ADHERENCE_LT_25 = 'TASK_ADHERENCE_LT_25',
  TASK_ADHERENCE_LT_10 = 'TASK_ADHERENCE_LT_10',
}

export const TCM_FILTER_CODES = [
  PROGRAM_FILTER_CODE.TCM,
  PROGRAM_SUB_FILTER_CODE.TCM_APPOINTMENT_PENDING,
  PROGRAM_SUB_FILTER_CODE.TCM_OUTREACH_PENDING
]

export const FILTER_CODES = [
  PROGRAM_FILTER_CODE.TCM,
  PROGRAM_SUB_FILTER_CODE.TCM_APPOINTMENT_PENDING,
  PROGRAM_SUB_FILTER_CODE.TCM_OUTREACH_PENDING,
  PROGRAM_FILTER_CODE.CCM,
  PROGRAM_SUB_FILTER_CODE.CCM_CONSENT_PENDING,
  PROGRAM_SUB_FILTER_CODE.CCM_ASSESSMENT_PENDING,
  PROGRAM_SUB_FILTER_CODE.CCM_ENROLLMENT_PENDING,
  PROGRAM_SUB_FILTER_CODE.CCM_VISIT_PENDING,
  PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_DUE,
  PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_ENROLL,
  PROGRAM_STEPS_FILTER_CODE.ALL
]
 
export const CCM_FILTER_CODES = [
  PROGRAM_FILTER_CODE.CCM,
  PROGRAM_SUB_FILTER_CODE.CCM_CONSENT_PENDING,
  PROGRAM_SUB_FILTER_CODE.CCM_ASSESSMENT_PENDING,
  PROGRAM_SUB_FILTER_CODE.CCM_ENROLLMENT_PENDING,
  PROGRAM_SUB_FILTER_CODE.CCM_VISIT_PENDING,
  PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_DUE,
  PROGRAM_SUB_FILTER_CODE.CCM_CARE_PLAN_ENROLL,
]
