import React, {useCallback, useState} from 'react';
import AlertsView from '../../../SideCar/AlertsView';
import {ITaskAlert} from '../../../SideCar/SideBarCustomHooks/useFetchAlerts';
import {Pressable, ScrollView, View} from 'react-native';
import {ActionableTab} from '../../../common/PatientListInternalTask/InternalTaskDrawer';
import {useIntl} from 'react-intl';
import {ClinicalSectionFilterTabs} from '../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionFilterTabs/ClinicalSectionFilterTabs';
import {Badge, HStack, Stack, Text} from 'native-base';
import {Colors} from '../../../../styles';
import FilterIconSvgForAlerts from '../../../common/Svg/SideCarSvg/FilterIconSvgForAlerts';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import NotificationSvg from '../../../common/Svg/NotificationSvg';
import SearchIconSvg from '../../../common/Svg/TimelineSvg/SearchIconSvg';
import {SearchBar} from '../../../common/SearchBar';
import {debounce} from 'lodash';
import Feather from 'react-native-vector-icons/Feather';
import CareGapDetailView from '../../../SideCar/CareGapDetailView';
import DiagnosisGapDetailView from '../../../SideCar/DiagnosisGapDetailView';
import { IDiagnosisGap } from '../../../SideCar/interfaces';
import { IPersonData } from '../interfaces';

const PatientAlerts = (props: {contactId: string, personData: IPersonData}) => {
  const {contactId, personData} = props;
  const intl = useIntl();
  const [componentState, setComponentState] = useState<{
    selecetedCareGapDetail?: ITaskAlert;
    showFiltersTabInAlerts?: boolean;
    alertSearchText?: string;
    formattedContactData?: any;
    pendingAlertsCount?: number;
    selectedTab?: string;
    alertFilterCount?: number;
    filterCount?: number | null;
    showSearchBar?: boolean;
    selectedDiagnosisGapDetail?: IDiagnosisGap;
  }>({
    selecetedCareGapDetail: {} as ITaskAlert,
    showFiltersTabInAlerts: false,
    alertSearchText: '',
    formattedContactData: {
      ...props.personData.contactData,
      contactUUID: props.personData.contactData?.uuid,
      name: props.personData.name,
      contactData: props.personData.contactData
    },
    pendingAlertsCount: 0,
    selectedTab: ActionableTab.ALERTS,
    alertFilterCount: 0,
    filterCount: 0,
    showSearchBar: false,
    selectedDiagnosisGapDetail: {} as IDiagnosisGap,
  });
  const {
    selecetedCareGapDetail,
    showFiltersTabInAlerts,
    alertSearchText,
    formattedContactData,
    selectedTab,
    alertFilterCount,
    filterCount,
    showSearchBar,
    selectedDiagnosisGapDetail,
  } = componentState;

  const setState = (newState: Partial<typeof componentState>) => {
    setComponentState((prev) => ({...prev, ...newState}));
  };

  const getFilterCountBadge = useCallback(() => {
    const currentFilterCount =
      selectedTab === ActionableTab.ALERTS
        ? alertFilterCount
        : filterCount || 0;

    return currentFilterCount && currentFilterCount > 0 ? (
      <Badge
        mb={-2}
        position={'absolute'}
        top={-6}
        right={-12}
        paddingLeft={1}
        paddingRight={1}
        paddingTop={0}
        paddingBottom={0}
        mt={currentFilterCount > 0 ? -2 : 0}
        zIndex={100}
        variant="solid"
        backgroundColor={Colors.Custom?.badgeColorOrange}
        alignSelf="flex-end"
        size={'smMedium'}
        _text={{
          fontSize: 9,
          fontWeight: 'bold',
        }}
      >
        {currentFilterCount}
      </Badge>
    ) : (
      ''
    );
  }, [alertFilterCount, filterCount, selectedTab]);
  const careGapDetailView = () => {
    return (
      <CareGapDetailView
        contactUuid={contactId}
        style={{
          paddingHorizontal: 0,
          paddingVertical: 0,
        }}
        onBackClick={() => {
          setComponentState((prev) => {
            return {
              ...prev,
              selecetedCareGapDetail: {} as ITaskAlert,
            };
          });
        }}
        careGapDetail={
          componentState.selecetedCareGapDetail || ({} as ITaskAlert)
        }
      />
    );
  };
  const diagnosisGapDetailView = () => {
    return (
      <DiagnosisGapDetailView
        contactData={formattedContactData}
        onClose={() => {
          setComponentState((prev) => {
            return {
              ...prev,
              selectedDiagnosisGapDetail: {} as IDiagnosisGap,
            };
          });
        }}
        diagnosisGapDetail={selectedDiagnosisGapDetail || {} as IDiagnosisGap}
      />
    );
  };
  return (
    <Stack direction="column" style={{flex: 1}}>
      <View
        style={{
          paddingVertical: 8,
          paddingLeft: 8,
          paddingRight: 16,
          backgroundColor: '#fff',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 50,
          flexDirection: 'row',
          borderBottomWidth: 0.5,
          borderBottomColor: Colors.FoldPixel.GRAY150,
          display: selecetedCareGapDetail?.id ? 'none' : 'flex',
        }}
      >
        <View style={{flexDirection: 'row', alignItems: 'center', flex: 2}}>
          <NotificationSvg />
          <View style={{marginLeft: 8}}>
            <DisplayText
              textLocalId={'alerts'}
              extraStyles={{
                color: Colors.FoldPixel.GRAY400,
                fontSize: 16,
              }}
            />
          </View>
        </View>
        <HStack
          style={{alignItems: 'center', flex: 7, justifyContent: 'flex-end'}}
        >
          {showSearchBar ? (
            <Stack
              direction={'row'}
              style={{
                alignItems: 'center',
                marginVertical: 4,
                marginHorizontal: 12,
                flex: 8,
              }}
            >
              <SearchBar
                width={'100%'}
                nativeId="tableTopSearchBarStyle"
                minHeightWrapper={32}
                height={32}
                onChange={debounce(
                  (value: string) => setState({alertSearchText: value}),
                  500
                )}
                searchBarInputStyles={{
                  height: 32,
                }}
                rightElement={
                  <Feather
                    onPress={() =>
                      setState({showSearchBar: false, alertSearchText: ''})
                    }
                    style={{
                      marginRight: 16,
                    }}
                    name="x"
                    size={15}
                    color={Colors.Custom.Gray600}
                  />
                }
              />
            </Stack>
          ) : (
            <Pressable onPress={() => setState({showSearchBar: true})}>
              <SearchIconSvg
                customStrokeColor={Colors.FoldPixel.GRAY300}
                strokeWidth={1}
              />
            </Pressable>
          )}

          <View
            style={{
              width: 1,
              height: 20,
              backgroundColor: Colors.Custom.Gray200,
              marginHorizontal: 12,
            }}
          />
          <Pressable
            onPress={() =>
              setState({showFiltersTabInAlerts: !showFiltersTabInAlerts})
            }
          >
            <Stack direction={'column'} style={{position: 'relative'}}>
              {getFilterCountBadge()}
              <FilterIconSvgForAlerts />
            </Stack>
          </Pressable>
        </HStack>
      </View>
      <ScrollView
        style={{padding: 12, marginTop: selecetedCareGapDetail?.id || selectedDiagnosisGapDetail?.id ? 0 : 12}}
      >
        <Stack direction="column">
          {selecetedCareGapDetail?.id && careGapDetailView()}
          {selectedDiagnosisGapDetail?.id && diagnosisGapDetailView()}
          {!selecetedCareGapDetail?.id && !selectedDiagnosisGapDetail?.id && (
            <AlertsView
              openCareGapDetail={(alert) =>
                setState({selecetedCareGapDetail: alert})
              }
              openDiagnosisGapDetail={(diagnosisGap) =>
                setState({selectedDiagnosisGapDetail: diagnosisGap})
              }
              contactId={contactId}
              filterStyle={{
                marginLeft: 1,
                paddingVertical: 8,
                paddingHorizontal: 12,
              }}
              showFiltersTabInAlerts={showFiltersTabInAlerts || false}
              onFilterApplied={(filterCount) =>
                setState({alertFilterCount: filterCount})
              }
              searchText={alertSearchText || ''}
              onPendingCountChange={(count) =>
                setState({pendingAlertsCount: count})
              }
              isActionableDrawer={true}
              formattedContactData={formattedContactData}
              personData={personData}
              contactData={personData.contactData}
            />
          )}
        </Stack>
      </ScrollView>
    </Stack>
  );
};

export default PatientAlerts;
