import React from 'react';
import { ICommonSvgProps } from '../interfaces';

const ConferenceAdd = (props: ICommonSvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="45"
      fill="none"
      viewBox="0 0 46 45"
    >
      <g filter="url(#filter0_dd_2779_215383)">
        <rect width="38" height="38" x="4" y="2.5" fill={props.isActive ? "#825AC7" : "#fff"} rx="19"></rect>
        <path
          stroke={!props.isEnabled ? "#E4E7EC" : props.isActive ? "#fff" : "#667085"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M26.333 29v-1.667A3.333 3.333 0 0023 24h-5.833a3.334 3.334 0 00-3.334 3.333V29m15.834-10.833v5m2.5-2.5h-5m-3.75-3.334a3.333 3.333 0 11-6.667 0 3.333 3.333 0 016.667 0z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_2779_215383"
          width="45"
          height="45"
          x="0.5"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2779_215383"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_2779_215383"
            result="effect2_dropShadow_2779_215383"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_2779_215383"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default ConferenceAdd;
