import React from "react";

const ErxSavingsSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
     <svg
     xmlns="http://www.w3.org/2000/svg"
     style={{width: 'inherit', height: 'inherit'}}
     fill="none"
     viewBox="0 0 24 24"
   >
     <path
       fill={svgColors}
       d="M19.778 6.222a6 6 0 010 8.485l-2.122 2.12-4.949 4.951a6 6 0 01-8.485-8.485l7.07-7.07a6 6 0 018.486 0zm-4.95 10.606l-5.656-5.656-3.536 3.535a4 4 0 005.657 5.657l3.535-3.536z"
     ></path>
   </svg>
  );
}

export default React.memo(ErxSavingsSvg);
