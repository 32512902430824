import React from 'react';
import {Button, Pressable, Text} from 'native-base';
import {useIntl} from 'react-intl';
import {Modal} from 'antd';
import {IResetForwardNumberAlert} from './interface';
import {BUTTON_TYPE} from '../../../../constants';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import ContinueWithExistingConversationPopup from '../../TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/ContinueWithExistingConversationPopup';
import {TELEPHONY_CONST} from '../../../../constants/TelephonyConst';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';

const ForwardToIvrFlowAlert = (props: IResetForwardNumberAlert) => {
  const intl = useIntl();
  const {isOpen, onActionPerformed} = props;
  const onCloseModal = () => {
    onActionPerformed(TELEPHONY_CONST.CLOSE);
  };
  const onSubmit = () => {
    onActionPerformed(TELEPHONY_CONST.SUBMIT);
  };

  return (
    <>
      <Modal
        visible={isOpen}
        title={
          <Text fontSize={'lg'}>
            {intl.formatMessage({id: 'resetForwardNumber'})}
          </Text>
        }
        onCancel={onCloseModal}
        footer={[
          <Button.Group
            mx={{
              base: 'auto',
              md: 0,
            }}
          >
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onSubmit();
                },
                style: {marginLeft: 10},
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'continue',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onCloseModal();
                },
                style: {marginLeft: 20},
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>,
        ]}
      >
        <Text>
          {`You have already forwarded the to xxxxxxx${props?.number?.slice(
            -2
          )}, do you want reset it and set to IVR flow?`}
        </Text>
      </Modal>
    </>
  );
};

export default ForwardToIvrFlowAlert;
