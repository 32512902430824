import { DATE_FORMATS } from '../../../../../constants';
import { ON_OFF_CONFIG_VALUE } from '../../../../../constants/AccountConfigConst';
import { getDateObjectFromAPIFormatToMyFormat } from '../../../../../utils/DateUtils';
import { CareJourneyLocation } from '../../../../common/CarePlanSearch/interfaces';
import {ITagsTableData} from '../../../Contacts';
import {
  PRODUCT_PACKAGE_STATUS,
  PRODUCT_PRICING_TYPE,
  PRODUCT_TYPE_CATEGORY,
} from './PackagesConst';
import {
  AccountLocations,
  IAccountPracticeLocationList,
  IGetProduct,
  IGetProductList,
  IPackageFormData,
  IPackageFormDataErrors,
  IProductBodyData,
} from './interfaces';

export const filterLocationObject = (locationData: AccountLocations[]) => {
  const newData: IAccountPracticeLocationList[] = (locationData || [])?.map(
    (item: AccountLocations) => {
      return {
        accountLocationUuid: item.uuid,
        practiceLocationUuid: item.practiceLocation.uuid,
        name: item.practiceLocation.name,
      };
    }
  );
  return newData;
};

export const formatFileSize = (kilobytes: number) => {
  if (!kilobytes) {
    return '';
  } else if (kilobytes < 1000) {
    return kilobytes + ' KB';
  } else {
    const megabytes = (kilobytes / 1000).toFixed(2);
    return megabytes + ' MB';
  }
};

export const validatePackageForm = (formData: IPackageFormData) => {
  const errors: IPackageFormDataErrors = {};
  let isValid = true;
  errors.packageName = !formData.packageName.trimStart() ? 'Package name is required' : '';
  errors.expiryDate = !formData?.expiryDate?.length
    ? 'Expiry date is required'
    : '';
  errors.description = !formData.description.trimStart() ? 'Description is required' : '';
  errors.thumbnail = !formData.selectedMedia?.url
    ? 'Thumbnail is required'
    : '';
  errors.careJourney = !formData.selectedJourney?.id
    ? 'Care journey is required'
    : '';
  if (
    errors.packageName ||
    errors.description ||
    errors.thumbnail ||
    errors.location ||
    errors.careJourney ||
    errors.expiryDate
  ) {
    isValid = false;
  }
  return {
    isValid,
    errors,
  };
};

const getLabelTags = (selectedLabels: ITagsTableData[]) => {
  return selectedLabels?.map?.((label) => label?.id) || [];
}

export const formattingCreateVariables = (
  stateData: IPackageFormData,
  isPublished: boolean,
) => {
  const productBodyData: IProductBodyData = {} as IProductBodyData;
  productBodyData.product = {
    features: [],
    prices: [
      {
        intervalCode: 'one_time',
        intervalCount: 1,
        price: !stateData.price?.length ? '0' : stateData.price,
        active: true,
        tiers: [],
        hideTrialInput: false,
      },
    ],
    name: stateData.packageName,
    description: stateData.description,
    active: true,
    category: PRODUCT_TYPE_CATEGORY.PACKAGE,
  };
  productBodyData.productObject = {
    name: stateData.packageName,
    description: stateData.description,
    expiryDate: stateData.expiryDate,
    pricingType:
      stateData.price?.length && parseFloat(stateData.price) !== 0
        ? PRODUCT_PRICING_TYPE.PURCHASE
        : PRODUCT_PRICING_TYPE.FREE,
    media: stateData.selectedMedia,
    status: isPublished
      ? PRODUCT_PACKAGE_STATUS.PUBLISHED
      : PRODUCT_PACKAGE_STATUS.DRAFT,
    category: PRODUCT_TYPE_CATEGORY.PACKAGE,
  };
  productBodyData.packageObj = {
    journeyId: stateData.selectedJourney?.id,
  };
  productBodyData.packageTags = getLabelTags(stateData?.selectedLabels);
  return productBodyData;
};

export const formattingUpdateVariables = (
  stateData: IPackageFormData,
  isPublished: boolean,
  selectedPackageData?: IGetProduct,
) => {
  const productBodyData: IProductBodyData = {} as IProductBodyData;
  productBodyData.product = {
    id: selectedPackageData?.id || undefined,
    features: [],
    prices: [
      {
        id: selectedPackageData?.stripeProductData?.prices?.[0]?.id || undefined,
        intervalCode: 'one_time',
        intervalCount: 1,
        price: !stateData.price?.length ? '0' : stateData.price,
        active: true,
        tiers: [],
        hideTrialInput: false,
      },
    ],
    name: stateData.packageName,
    description: stateData.description,
    active: true,
    category: PRODUCT_TYPE_CATEGORY.PACKAGE,
  };
  productBodyData.productObject = {
    name: stateData.packageName,
    description: stateData.description,
    expiryDate: stateData.expiryDate,
    pricingType:
      stateData.price?.length && parseFloat(stateData.price) !== 0
        ? PRODUCT_PRICING_TYPE.PURCHASE
        : PRODUCT_PRICING_TYPE.FREE,
    media: stateData.selectedMedia,
    status: isPublished
      ? PRODUCT_PACKAGE_STATUS.PUBLISHED
      : PRODUCT_PACKAGE_STATUS.DRAFT,
    category: PRODUCT_TYPE_CATEGORY.PACKAGE,
  };
  productBodyData.packageObj = {
    id: selectedPackageData?.productPackage?.id || undefined,
    journeyId: stateData.selectedJourney?.id,
  };
  productBodyData.packageTags = getLabelTags(stateData?.selectedLabels);
  return productBodyData;
};

export const packageSorter = (a: IGetProduct, b: IGetProduct) => {
  const diff =
    new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
  return diff;
};

export const getAllJourneyLocationName = (allLocations: IAccountPracticeLocationList[], journeyLocation: CareJourneyLocation[]) => {
  const names = journeyLocation?.map(item1 => {
    const matchingItem = allLocations?.find(item2 => item1?.locationId === item2?.accountLocationUuid);
    return matchingItem ? matchingItem?.name : '';
  });

  return names || [];
}

export const formattingGetPackageData = (
  packagesData: IGetProductList,
  accountLocations: IAccountPracticeLocationList[]
) => {
  const allPackages = packagesData?.products?.map((packagesItem) => {
    const locationNamesData = packagesItem?.productLocation?.map(
      (productLocationItem) => {
        return accountLocations?.find((locationItems) => {
          if (
            productLocationItem?.accountLocationUuid ===
            locationItems?.accountLocationUuid
          ) {
            return {
              ...productLocationItem,
              name: locationItems?.name,
            };
          }
        });
      }
    );
    return {
      ...packagesItem,
      productLocation: locationNamesData,
    };
  });

  const finalPackageObject = {
    total: packagesData?.total,
    products: allPackages,
  }

  return finalPackageObject;
};

export const isJourneyPackagesEnable = (journeyPackagesConfig: any) => {
  if (journeyPackagesConfig?.accountConfigurations?.length) {
    if (journeyPackagesConfig?.accountConfigurations[0]?.value === ON_OFF_CONFIG_VALUE.ON) {
      return true;
    }
    return false;
  }
  return false;
}

export const isArchiveOnSendEnable = (archiveOnSendConfig: any) => {
  if (
    archiveOnSendConfig?.accountConfigurations?.length &&
    archiveOnSendConfig?.accountConfigurations[0]?.value ===
      ON_OFF_CONFIG_VALUE.ON
  ) {
    return true;
  }
  return false;
};

export const isExpiredDate = (givenDateTime?: Date) => {
  const currentDate = new Date();
  const currentDateWithoutTime = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  if (givenDateTime && givenDateTime < currentDateWithoutTime) {
    return true;
  }
  return false;
};

export const getFormattedExpiryDate = (dateString: string) => {
  let formattedDate = '--';
  try {
    const inputDate =
      dateString && dateString.length > 0 ? dateString.split('T')[0] : '';
    if (!(inputDate && inputDate.length > 0)) {
      return formattedDate;
    }
    const dateArray = inputDate.split('-');
    if (dateArray.length !== 3) {
      return formattedDate;
    }
    const month = parseInt(dateArray[1]);
    const day = parseInt(dateArray[2]);
    const year = parseInt(dateArray[0]);
    formattedDate =
      (month < 10 ? `0${month}` : month) +
      '/' +
      (day < 10 ? `0${day}` : day) +
      '/' +
      year;
    return formattedDate;
  } catch (error) {
    return formattedDate;
  }
};
