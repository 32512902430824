import React, {useState} from 'react';
import {
  HStack,
  Pressable,
  Spinner,
  Text,
  Tooltip,
  View,
  VStack,
} from 'native-base';
import {Empty, Timeline} from 'antd';
import {
  DiagnosticReport,
  MedicationRequest,
  RequestGroup,
} from 'fhir/r4';
import Feather from 'react-native-vector-icons/Feather';
import {ITimelineMap} from '../../interfaces';
import {Colors} from '../../../../../../styles';
import OrderDetailsDrawer from '../../../Orders/OrdersAndReports/OrderDetailsDrawer/OrderDetailsDrawer';
import {
  IReportResult,
  IDiagnosticReportResource,
  IReportAndResourceMap,
  IRequestGroup,
} from '../../../../LeftContainer/RecentActivity/OrdersInterface';
import { getOrderFromReport, getReportMatchWithOrder, getOrderName, isOrderAddedFromFold, canShowEditButton, canShowPrintButton, canShowDeleteButton} from '../../../Orders/OrdersAndReports/OrderUtils';
import {
  getDiagnosticReportsForServiceRequest,
  getReportResultForDisplay,
  getServiceRequestsFromOrder,
  isAllFoldMediaReportReviewed,
  isAllReportViewedBy,
  REPORT_TYPE,
} from '../../../../LeftContainer/RecentActivity/RecentReport/ReportUtils';
import ResultIcon from '../../assets/ResultIcon';
import {ReportResultView} from '../../../../LeftContainer/RecentActivity/RecentReport/ReportResultView';
import {IPersonData} from '../../../interfaces';
import PDFIcon from '../../assets/PdfIcon';
import DayTimelineTag from './components/DateTag';
import OrderCard from './components/OrderCard';
import { OrderType } from '../../../Orders/OrdersAndReports/OrderConstants';
import AddOrUpdateOrders from '../../../Orders/OrdersAndReports/AddOrUpdateOrders/AddOrUpdateOrders';
import { deleteOrder } from '../../../../../../services/CommonService/OrderService';
import { FHAlertDialog } from '../../../../../common/FHAlertDialog';
import { BUTTON_TYPE } from '../../../../../../constants';
import { getEhrConfig } from '../../../../../../utils/capabilityUtils';
import {StyleSheet} from 'react-native';

interface IOrderLine {
  showModal: boolean;
  showDetails: boolean;
  showAddNewOrderModal: boolean;
  printOrder: boolean;
  deleteConfirmation: boolean;
  deleteLoading: boolean;
  reportLoadingKey?: string;
  selectedReport: {
    reportName: string;
    observation: IReportResult[];
    resources: IDiagnosticReportResource[];
    isReportReviewedByUser: boolean;
  };
  selectedOrder: {
    order?: RequestGroup;
    report?: DiagnosticReport;
    action?: any;
    prescription?: MedicationRequest;
    noteId?: string;
    orderType?: OrderType;
  };
}

const OrderTimeLine = (props: {
  timeLineMap: ITimelineMap[];
  reports: any[];
  orders: IRequestGroup[];
  personData: IPersonData;
  unformmatedContactDetails: any;
  refreshData: () => void;
  handleNoteSelect: (note: any) => void;
}) => {
  const {timeLineMap, reports, personData} = props;
  const accountLocationUuid = personData?.accountLocationUuid || '';
  const ehrConfig = getEhrConfig(accountLocationUuid, '');
  const [componentState, setComponentState] = useState<IOrderLine>({
    showDetails: false,
    showModal: false,
    printOrder: false,
    deleteConfirmation: false,
    deleteLoading: false,
    showAddNewOrderModal: false,
    reportLoadingKey: undefined,
    selectedReport: {
      reportName: '',
      observation: [],
      resources: [],
      isReportReviewedByUser: false,
    },
    selectedOrder: {},
  });

  const getEditButton = (order: RequestGroup | MedicationRequest | undefined, orderType: OrderType, noteId?: string): JSX.Element | undefined => {
    const canShowEdit = canShowEditButton(order);
    if (!canShowEdit || !order) return;
    const isLabRadOrder = order.resourceType === 'RequestGroup';
    const isMedOrder = order.resourceType === 'MedicationRequest';
    return (
      <Tooltip label="Edit Order" placement="top" key={'edit'}>
        <Pressable
          key={'edit'}
          onPress={() => {
            setComponentState((prev) => {
              return {
                ...prev,
                showAddNewOrderModal: true,
                selectedOrder: {
                  ...(isLabRadOrder && {order: order}),
                  ...(isMedOrder && {prescription: order}),
                  noteId: noteId,
                  orderType: orderType
                }
              }
            });
          }}
        >
          <Feather
            name="edit-3"
            style={styles.editButton}
          />
        </Pressable>
      </Tooltip>
    );
  };

  const onDeleteOrder = async (order: RequestGroup | undefined) => {
    if (!order?.id) return;
    setComponentState((prev) => ({...prev, deleteLoading: true}));
    try {
      await deleteOrder(order.id);
      setComponentState((prev) => ({...prev, deleteLoading: false, deleteConfirmation: false, selectedOrder: {}}));
      props.refreshData();
    } catch (error) {
      setComponentState((prev) => ({...prev, deleteLoading: false}));
    }
  }

  const getDeleteButton = (order: RequestGroup | MedicationRequest | undefined, orderType: OrderType, noteId?: string): JSX.Element | undefined => {
    const canShow = canShowDeleteButton(order);
    if (!canShow || !order) return;
    const isLabRadOrder = order?.resourceType === 'RequestGroup';
    return (
      <Tooltip label="Delete Order" placement="top" key={'delete'}>
        <Pressable
          key={'delete'}
          onPress={() => {
            setComponentState((prev) => ({
              ...prev,
              deleteConfirmation: true,
              selectedOrder: {
                ...(isLabRadOrder && {order: order}),
              }
            }))
          }}
        >
          <Feather
            name="trash-2"
            style={styles.editButton}
          />
        </Pressable>
      </Tooltip>
    );
  };

  const getPrintButton = (order: RequestGroup | MedicationRequest | undefined, orderType: OrderType, noteId?: string): JSX.Element | undefined => {
    const canShowPrint = canShowPrintButton(order);
    if (!canShowPrint) return;
    const isLabRadOrder = order?.resourceType === 'RequestGroup';
    return (
      <Tooltip label="Print Requisition Form" placement="top" key={'print'}>
        <Pressable
           key={'print'}
          onPress={() => {
            setComponentState((prev) => {
              return {
                ...prev,
                showAddNewOrderModal: true,
                printOrder: true,
                selectedOrder: {
                  ...(isLabRadOrder && {order: order}),
                  noteId: noteId,
                  orderType: orderType
                }
              }
            });
          }}
        >
          <Feather
            name="printer"
            style={styles.editButton}
          />
        </Pressable>
      </Tooltip>
    );
  };

  const getInfoButton = (params: {
    report?: DiagnosticReport;
    order?: RequestGroup;
    action?: any;
    prescription?: MedicationRequest;
  }): JSX.Element => {
    return (
      <Tooltip label="View Details" placement="top" key={'info'}>
        <Pressable
          key={'info'}
          onPress={() => {
            setComponentState((prev) => {
              return {
                ...prev,
                showDetails: true,
                selectedOrder: {
                  report: params.report,
                  order: params.order,
                  action: params.action,
                  prescription: params.prescription,
                },
              };
            });
          }}
        >
          <Feather
            name="info"
            style={styles.editButton}
          />
        </Pressable>
      </Tooltip>
    );
  };

  const configureReportResult = async (
    selectedReport: IReportAndResourceMap,
    reportType: string,
    key: string,
    order?: RequestGroup
  ) => {
    setComponentState((prev: any) => {
      return {
        ...prev,
        reportLoadingKey: key,
      };
    });
    let orderReport = selectedReport;
    if (reportType == REPORT_TYPE.FOLD_MEDIA_REPORT && order) {
      const serviceRequests = getServiceRequestsFromOrder(order);
      const resources = getDiagnosticReportsForServiceRequest(serviceRequests, reports);
      orderReport = {
        code: selectedReport.code,
        resourceCode: selectedReport.resourceCode,
        display: getOrderName(order),
        type: selectedReport.type,
        showGraph: false,
        resources: resources,
        orderType: selectedReport.orderType,
      }
    }
    const {reportName, observationList} = await getReportResultForDisplay(
      orderReport,
      reportType,
      props.orders as RequestGroup[],
      { ehrConfig, patientId: personData?.patientId || personData?.patientUuid, locationId: accountLocationUuid },
    );
    const isDataAvailable = !!reportName && observationList.length > 0;
    setComponentState((prev: any) => {
      return {
        ...prev,
        showModal: isDataAvailable,
        reportLoadingKey: undefined,
        selectedReport: {
          reportName,
          observation: observationList,
          resources: orderReport?.resources || [],
          isReportReviewedByUser: isAllReportViewedBy(orderReport),
        },
      };
    });
  };
  const getReportButton = (
    report: IReportAndResourceMap,
    reportType: string,
    key: string,
    order?: RequestGroup,
  ): JSX.Element => {
    const isMediaReport = reportType === REPORT_TYPE.MEDIA_REPORT || reportType === REPORT_TYPE.FOLD_MEDIA_REPORT;
    if (key === componentState.reportLoadingKey) {
      return <Spinner size={'sm'} />
    }
    return (
      <Tooltip label={isMediaReport ? 'View Report' : 'View Result'} placement='top' key={'report'}>
        <Pressable
          key={'report'}
          onPress={() => {
            configureReportResult(report, reportType, key, order);
          }}
        >
          {isMediaReport ? (
            <PDFIcon key={'pdf'} />
          ) : (
            <ResultIcon key={'result'} />
          )}
        </Pressable>
      </Tooltip>
    );
  };

  const renderReportButton = (report: IReportAndResourceMap, key: string, order?: RequestGroup): JSX.Element => {
    let matchReport: any = null;
    if (order) {
      matchReport = getReportMatchWithOrder(report, order);
    } else {
      matchReport = report?.resources?.[0];
    }
    if (matchReport) {
      const matchedOrder = getOrderFromReport(
        matchReport,
        props.orders as RequestGroup[]
      );
      switch (report?.type) {
        case REPORT_TYPE.BOTH:
          return (
            <>
              {getReportButton(report, REPORT_TYPE.MEDIA_REPORT, key)}
              {getReportButton(report, REPORT_TYPE.RESULT, key)}
              {getInfoButton({
                report: matchReport,
                order: matchedOrder,
              })}
            </>
          );
        case REPORT_TYPE.MEDIA_REPORT:
          return (
            <>
              {getReportButton(report, REPORT_TYPE.MEDIA_REPORT, key)}
              {getInfoButton({
                report: matchReport,
                order: matchedOrder,
              })}
            </>
          );
        case REPORT_TYPE.RESULT:
          return (
            <>
              {getReportButton(report, REPORT_TYPE.RESULT, key)}
              {getInfoButton({
                report: matchReport,
                order: matchedOrder,
              })}
            </>
          );
        case REPORT_TYPE.FOLD_MEDIA_REPORT:
          return (
            <>
              {getReportButton(report, REPORT_TYPE.FOLD_MEDIA_REPORT, key, order)}
              {getInfoButton({
                report: matchReport,
                order: matchedOrder,
              })}
            </>
          );
      }
    }
    return <></>;
  };

  const renderCardActions = (params: {
    report?: IReportAndResourceMap;
    key?: string;
    order?: RequestGroup;
    action?: any;
    prescription?: MedicationRequest;
    orderType: OrderType;
    noteId?: string;
  }): JSX.Element => {
    const { report, order, orderType, noteId, key, prescription } = params;
    return (<>
      {getEditButton(order || prescription, orderType, noteId)}
      {getDeleteButton(order || prescription, orderType, noteId)}
      {getPrintButton(order || prescription, orderType, noteId)}
      {report ? renderReportButton(report, key || '', order) : getInfoButton({ order: order, prescription: prescription })}
    </>);
  }

  if (!timeLineMap.length) {
    return (
      <VStack h={500} justifyContent="center">
        <Empty />
      </VStack>
    );
  }

  return (
    <View flex={1} pt={2}>
      {timeLineMap.map((item: ITimelineMap, index: number) => (
        <Timeline
          mode="left"
          key={item.month + index}
          className="order-timeline"
        >
          <HStack pt={2}>
            <div className="order-month-text">
              <Text
                style={styles.monthTimelineTag}
              >
                {item.month}
              </Text>
            </div>
            <VStack flex={8.5} ml={3}>
              {item.data.map((card, index) => {
                const groups = card.groups;
                return (
                  <Timeline.Item
                    key={card.day + index}
                    className="order-timeline"
                    dot={<Icon />}
                    color={Colors.Custom.Gray300}
                  >
                    <VStack
                      ml={4}
                      flex={1}
                    >
                      <DayTimelineTag day={card.day} />
                      {groups.map((group, index) => {
                        return (
                          <OrderCard
                            handleNoteSelect={props.handleNoteSelect}
                            key={group.noteId + index}
                            group={group}
                            renderCardActions={renderCardActions}
                            personData={personData}
                          />
                        );
                      })}
                    </VStack>
                  </Timeline.Item>
                );
              })}
            </VStack>
          </HStack>
        </Timeline>
      ))}
      <OrderDetailsDrawer
        isVisible={componentState.showDetails}
        report={componentState.selectedOrder.report}
        order={componentState.selectedOrder.order}
        action={componentState.selectedOrder.action}
        prescription={componentState.selectedOrder.prescription}
        onClose={() => {
          setComponentState((prev) => {
            return {
              ...prev,
              showDetails: false,
              selectedOrder: {},
            };
          });
        }}
      />
      {componentState.showModal && (
        <ReportResultView
          unformmatedContactDetails={props.unformmatedContactDetails}
          contactData={props.personData}
          showModal={componentState.showModal}
          reportName={componentState.selectedReport.reportName}
          observations={componentState.selectedReport.observation}
          resources={componentState.selectedReport.resources as DiagnosticReport[]}
          onMarkedAsReviewed={props.refreshData}
          locationId={accountLocationUuid}
          onClose={() => {
            setComponentState((prev) => {
              return {
                ...prev,
                showModal: false,
                selectedReport: {
                  reportName: '',
                  observation: [],
                  resources: [],
                  isReportReviewedByUser: false,
                  isFoldMediaView: false,
                },
              };
            });
          }}
        />
      )}
      {componentState.showAddNewOrderModal && componentState.selectedOrder?.orderType !== undefined && (
        <AddOrUpdateOrders
          isVisible={componentState.showAddNewOrderModal}
          isExternalOrder={isOrderAddedFromFold(componentState.selectedOrder?.order)}
          printOrder={componentState.printOrder}
          orderType={componentState.selectedOrder.orderType}
          patientContactId={props.personData?.id || ''}
          documentReferenceId={componentState.selectedOrder?.noteId}
          unFormattedContactData={props.unformmatedContactDetails}
          personData={props.personData}
          selectedOrder={componentState.selectedOrder?.order}
          onClose={() => {
            setComponentState((prev) => {
              return {
                ...prev,
                showAddNewOrderModal: false,
                printOrder: false,
                selectedOrder: {},
              };
            });
            props.refreshData();
          }}
        />
      )}
      <FHAlertDialog
        isOpen={componentState.deleteConfirmation}
        header={'Delete Draft Order'}
        message={'Are you sure, you want to delete this draft order?'}
        buttonActions={[
          {
            textLocalId: 'No',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY,
              isDisabled: componentState.deleteLoading,
            },
            onPress: () => {
              setComponentState((prev) => ({
                ...prev,
                deleteConfirmation: false,
                selectedOrder: {},
              }));
            },
          },
          {
            textLocalId: 'Yes',
            textColor: 'white',
            buttonProps: {
              variant: BUTTON_TYPE.PRIMARY,
              isLoading: componentState.deleteLoading,
              isDisabled: componentState.deleteLoading,
              _loading: {

              },
            },
            onPress: () => {
              onDeleteOrder(componentState.selectedOrder.order);
            },
          },
        ]}
      />
    </View>
  );
};

export default OrderTimeLine;

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 9 10"
    >
      <circle cx="4.5" cy="5" r="4" fill="#D0D5DD"></circle>
    </svg>
  );
}

const styles = StyleSheet.create({
  editButton: {
    fontSize: 16,
    color: Colors.Custom.Gray500,
    padding: 0,
  },
  monthTimelineTag: {
    fontSize: 16,
    fontWeight: '700',
    color: '#101828',
    paddingRight: 4,
    top: 0
  }
});

