import React, {useEffect, useRef} from 'react';
import {Colors} from '../../../../../../styles';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {TouchableOpacity, Text} from 'react-native';
import Close from '../../../../../../assets/svg/Close';
import {MessageThreadList} from './MessageThreadList';
import {CONVERSATION_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {ISearchMessageThread} from './interface';
import {IConversationMessage, IMessageBoxData} from '../../interfaces';
import {debounce} from 'lodash';
import {getMessageThreadResult} from './SearchMessageThreadUtils';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import {searchMsgThreadStyle} from './SearchMessageThreadStyle';
import { useIntl } from 'react-intl';
import { ToastType, showToast } from '../../../../../../utils/commonViewUtils';
import { Icon, Input, useToast } from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';

const SearchMessageThread = (props: ISearchMessageThread) => {
  const [state, setState] = React.useState({
    searchText: '',
    pageNo: 1,
    msgList: [] as IMessageBoxData[],
    loading: false,
    isMsgSearched: false,
  });
  const intl = useIntl();
  const toast = useToast();
  const inputRef : any= useRef(null)
  const getSearchMessageList = async (plainString: string, pageNo: number) => {
    setState((prev) => {
      return {
        ...prev,
        searchText: plainString,
        loading: true,
      };
    });
    plainString = plainString.trim();
    if (plainString.length > 2) {
      const result = await getMessageThreadResult(
        props?.conversationUuid,
        plainString,
        pageNo
      );
      if (!result?.error) {
      setState((prev) => {
        return {
          ...prev,
          msgList: result,
          loading: false,
          isMsgSearched: true,
        };
      });
      } else {
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
        setState((prev) => {
          return {
            ...prev,
            loading: false,
            msgList: [],
          };
        });
      }

    } else {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          msgList: [],
          isMsgSearched: (plainString?.length > 0)
        };
      });
    }
  };

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        searchText: '',
        pageNo: 1,
        msgList: [],
        loading: false,
        isMsgSearched: false,
      };
    });
  }, [props?.conversationUuid]);

  const onChangeValue = (plainstring: string) => {
    getSearchMessageList(plainstring, state.pageNo);
  };

  const handleClearAction = () => {
    if (inputRef?.current?.value) {
      inputRef?.current?.setNativeProps({value: ''})
      inputRef?.current?.setNativeProps({text: ''})
    }
    setState((prev) => {
      return {
        ...prev,
        searchText: '',
        msgList: [],
        isMsgSearched: false,
      };
    });
  };

  const getTitleForSearchContainer = () => {
    return (
      <Stack direction="column" style={searchMsgThreadStyle.titleContainer}>
        <Stack
          direction="row"
          style={searchMsgThreadStyle.titleSubContainer}
        >
          <Stack
            direction="row"
            style={searchMsgThreadStyle.searchInThreadContainer}
          >
            <Text
              style={searchMsgThreadStyle.searchInThreadText}
            >
              {intl.formatMessage({id: 'searchInThread'})}
            </Text>
          </Stack>
          <Stack
            direction="row"
            style={searchMsgThreadStyle.closeIconContainer}
          >
            <TouchableOpacity
              onPress={() => {
                if (props?.onActionPerformed) {
                  props?.onActionPerformed(
                    CONVERSATION_ACTION_CODES.SEARCH_MESSAGE,
                    false
                  );
                }
              }}
            >
              <Close fill={Colors.Custom.Gray500} size={20} />
            </TouchableOpacity>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const onMsgClickActionPerformed = React.useCallback((msgData: IConversationMessage) => {
    if (props?.onMsgClickActionPerformed) {
      props?.onMsgClickActionPerformed(msgData);
    }
  }, [props?.onMsgClickActionPerformed]);

  return (
    <Stack direction="column" style={searchMsgThreadStyle.flex1}>
      <Stack
        direction="column"
        style={
          isWeb()
            ? searchMsgThreadStyle.webMsgThreadContainer
            : searchMsgThreadStyle.appMsgThreadContainer
        }
      >
        {props.showTitle ? getTitleForSearchContainer() : <></>}
        <Stack direction="column" style={searchMsgThreadStyle.flex1}>
          <Stack
            direction="row"
            style={searchMsgThreadStyle.searchInputContainer}
          >
            <Stack direction="row" style={searchMsgThreadStyle.inputContainer}>
              <Input
                flex={1}
                ref={inputRef}
                placeholder={intl.formatMessage({id: 'searchMsgPlaceholder'})}
                placeholderTextColor={Colors.Custom.Gray500}
                borderRadius={isWeb() ? 6 : 24}
                borderWidth={0}
                fontSize={15}
                fontWeight='500'
                backgroundColor={'white'}
                onChangeText={debounce((value: string) => {
                  if (!isWeb()) {
                    inputRef.current.value= value;
                  }
                  onChangeValue(value);
                }, 500)}
                InputLeftElement={
                  <Icon
                    ml="3"
                    mr="1"
                    size={isWeb() ? '4' : '5'}
                    color={Colors.Custom.Gray500 || 'gray.400'}
                    as={<AntIcon name="search1" />}
                    backgroundColor={'white'}
                  />
                }
              />
            </Stack>
            <Stack direction="row">
              {inputRef?.current?.value ? (
                <TouchableOpacity
                  onPress={handleClearAction}
                  style={searchMsgThreadStyle.clearTextContainer}
                >
                  <Text
                    style={searchMsgThreadStyle.clearText}
                  >
                    {intl.formatMessage({id: 'clear'})}
                  </Text>
                </TouchableOpacity>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="column" style={searchMsgThreadStyle.messageThreadListStack}>
        <MessageThreadList
          loading={state.loading}
          msgListData={state.msgList}
          isMsgSearched={state.isMsgSearched}
          conversationUuid={props?.conversationUuid}
          pageNo={state.pageNo}
          searchString={state.searchText}
          onActionPerformed={onMsgClickActionPerformed}
        />
      </Stack>
    </Stack>
  );
};

export default SearchMessageThread;
