import React from 'react';
import CalendarView from '../../../../../RightSideContainer/Workbenches/CalendarView/CalendarView';
import {cssPropertiesAntD} from '../../style';

const CalendarHub = (props: {onHideCalendarView: () => void}) => {
  return (
    <div style={cssPropertiesAntD.flexGrow1OverflowHidden}>
      <CalendarView
        isDayOptimizerCalender
        onHideCalendarView={props?.onHideCalendarView}
      />
    </div>
  );
};

export default CalendarHub;
