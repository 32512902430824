import {useLazyQuery, useMutation} from '@apollo/client';
import {
  Box,
  Checkbox,
  FormControl,
  HStack,
  Input,
  ScrollView,
  Select,
  Skeleton,
  Spinner,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {useParams} from 'react-router-dom';
import {BUTTON_TYPE} from '../../../../../constants';
import InboxQueries from '../../../../../services/Inbox/InboxQueries';
import {Colors} from '../../../../../styles';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {ColorSelector} from '../../../Contacts/Tags/TagsModal/ColorSelector';
import {tagColorList} from '../../../Contacts/Tags/TagsModal/TagsColorConst';
import {INTEGRATION_CONSTANTS} from '../constants';
import {
  formatEditWidgetSettingsNew,
  formatEditWidgetSettingsOld,
} from '../Helper/formatIntegrationsData';
import {spinnerStype, styles} from '../IntegrationsStyles';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';

export const IntegrationEditWidgetSettings = () =>
  // props: IIntegrationEditWidgetSettings
  {
    const [updateLoading, setUpdateLoading] = useState(false);
    const toast = useToast();
    const intl = useIntl()
    const [currentData, setCurrentData] = useState<any>({});
    const [filePicker, setFilePicker] = useState<any>();
    const [emojiPicker, setEmojiPicker] = useState<any>();
    const [updateWidgetSettings] = useMutation(
      InboxQueries.UpdateWidgetSettings
    );
    const {id, channel_id} = useParams();
    const [channelData, setChannelData] = useState<any>({});
    const [inboxData, setInboxData] = useState<any>({});
    const [GetInboxFromId] = useLazyQuery<{teams: any[]}>(
      InboxQueries.GetInboxFromId
    );
    const [GetWidgetFromId] = useLazyQuery<{teams: any[]}>(
      InboxQueries.GetWidgetFromId
    );
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(true);
      GetInboxFromId({
        variables: {id: id},
      }).then((res: any) => {
        if (res && res?.data && res?.data?.inbox) {
          const channelIds = res?.data?.inbox.channelId;
          setInboxData(res?.data);
          GetWidgetFromId({
            variables: {id: channelIds},
          }).then((res: any) => {
            if (res && res?.data && res?.data?.channelWebWidget)
              setChannelData({...channelData, ...res?.data});
            setLoading(false);
          });
        }
      });
    }, []);
    // const {loading: inboxLoading} = useQuery(InboxQueries.GetInboxFromId, {
    //   fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    //   variables: {id: id},
    //   onCompleted: (inboxData) => {
    //     setInboxData(inboxData);
    //   },
    // });

    // const channelId = inboxData?.inbox?.channelId;

    // const {loading: channelLoading} = useQuery(InboxQueries.GetWidgetFromId, {
    //   fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    //   variables: {id: channelId},
    //   onCompleted: (channelData) => {
    //     setChannelData(channelData);
    //   },
    // });
    const currentDetails = {
      channel_id: channel_id,
      id: id,
      inbox: inboxData.inbox,
      channelWebWidget: channelData.channelWebWidget,
    };
    const {height} = Dimensions.get('window');

    const handleUpdate = () => {
      setUpdateLoading(true);
      const toUpdateData = formatEditWidgetSettingsNew({
        ...currentData,
        emojiPicker,
        filePicker,
        channel_id: currentDetails.channel_id,
        id: currentDetails.id,
      });
      updateWidgetSettings({variables: {...toUpdateData}}).then((res) => {
        setUpdateLoading(false);
        showToast(toast, 'Inbox setting updated', ToastType.success);
      });
    };

    useEffect(() => {
      const formattedData = formatEditWidgetSettingsOld(
        currentDetails.channelWebWidget,
        currentDetails.inbox
      );
      setCurrentData({
        websiteUrl: formattedData.websiteUrl,
        welcomeTagline: formattedData.welcomeTagline,
        welcomeTitle: formattedData.welcomeTitle,
        name: formattedData.name,
        color: formattedData.widgetColor,
        greeting: formattedData.greetingEnabled,
        greetingMessage: formattedData.greetingMessage,
        replyTime: formattedData.replyTime,
        enableAutoAssignment: formattedData.enableAutoAssignment,
        enableCSAT: formattedData.csatSurveyEnabled,
        allowAfterResolve: formattedData.allowMessagesAfterResolved,
        enableEmailCollect: formattedData.enableEmailCollect,
      });
      setFilePicker(formattedData.filePicker);
      setEmojiPicker(formattedData.emojiPicker);
    }, [!loading]);

    return (
      <>
        {loading ? (
          <View padding={6}>
            <Skeleton.Text lines={4} />
          </View>
        ) : (
          <ScrollView height={height - 200} style={styles.backgroundWhite}>
            <HStack
              flex={1}
              justifyContent="start"
              paddingLeft={10}
              paddingTop={10}
            >
              <VStack flex={0.4} space={4}>
                <FormControl style={styles.screenTwo}>
                  <Text size={'mdNormal'} marginBottom={1}>
                    Website Name
                  </Text>
                  <Input
                  _focus={{borderColor: '#D0D5DD'}}
                    fontSize={'sm'}
                    value={currentData.name}
                    onChangeText={(value) =>
                      setCurrentData({...currentData, name: value})
                    }
                  />
                </FormControl>
                <FormControl style={styles.screenTwo}>
                  <Text size={'mdNormal'} marginBottom={1}>
                    Website Domain
                  </Text>
                  <Input
                  _focus={{borderColor: '#D0D5DD'}}
                    fontSize={'sm'}
                    value={currentData.websiteUrl}
                    onChangeText={(value) =>
                      setCurrentData({...currentData, websiteUrl: value})
                    }
                  />
                </FormControl>
                <FormControl style={styles.screenTwo}>
                  <Text size={'mdNormal'} marginBottom={1}>
                    Welcome Heading
                  </Text>
                  <Input
                  _focus={{borderColor: '#D0D5DD'}}
                    fontSize={'sm'}
                    value={currentData.welcomeTitle}
                    onChangeText={(value) =>
                      setCurrentData({...currentData, welcomeTitle: value})
                    }
                  />
                </FormControl>
                <FormControl style={styles.screenTwo}>
                  <Text size={'mdNormal'} marginBottom={1}>
                    Welcome Tagline
                  </Text>
                  <Input
                  _focus={{borderColor: '#D0D5DD'}}
                    fontSize={'sm'}
                    value={currentData.welcomeTagline}
                    onChangeText={(value) =>
                      setCurrentData({...currentData, welcomeTagline: value})
                    }
                  />
                </FormControl>
                <FormControl style={styles.screenTwoColor}>
                  <ColorSelector
                    color={currentData.color}
                    title="widgetColor"
                    colorList={tagColorList}
                    onBtnPress={(color: string) =>
                      setCurrentData({...currentData, color})
                    }
                    onChangeHex={(text) =>
                      setCurrentData({...currentData, color: '#' + text})
                    }
                  />
                </FormControl>
                <FormControl style={styles.screenTwo}>
                  <Text size={'mdNormal'} marginBottom={1}>
                    Enable Greeting
                  </Text>
                  <Select
                    fontSize={'sm'}
                    selectedValue={currentData.greeting}
                    accessibilityLabel="Enable Channel Greeting"
                    placeholder="Enable Channel Greeting"
                    onValueChange={(itemValue) =>
                      setCurrentData({...currentData, greeting: itemValue})
                    }
                  >
                    <Select.Item label="Enabled" value="Enabled" />
                    <Select.Item label="Disabled" value="Disabled" />
                  </Select>
                  <Text fontSize="xs" italic color="muted.400" fontWeight={500}>
                    Send a greeting message to the user when they start the
                    conversation.
                  </Text>
                </FormControl>
                {currentData.greeting === 'Enabled' ? (
                  <FormControl style={styles.screenTwo}>
                    <Text size={'mdNormal'} marginBottom={1}>
                      Enter Greeting
                    </Text>
                    <Input
                    _focus={{borderColor: '#D0D5DD'}}
                      fontSize={'sm'}
                      value={currentData.greetingMessage}
                      placeholder="Enter Greeting"
                      onChangeText={(value) =>
                        setCurrentData({...currentData, greetingMessage: value})
                      }
                    />
                  </FormControl>
                ) : (
                  <></>
                )}
                <VStack>
                  <FoldButton
                    nativeProps={{
                      variant: BUTTON_TYPE.PRIMARY,
                      onPress: () => {
                        handleUpdate();
                      },
                      style: {maxWidth: 150},
                    }}
                    customProps={{
                      btnText: intl.formatMessage({id: 'update'}),
                      withRightBorder: false,
                    }}
                  ></FoldButton>
                </VStack>
              </VStack>
              <VStack flex={0.4} space={4}>
                <FormControl style={styles.screenTwo}>
                  <Text size={'mdNormal'} marginBottom={1}>
                    Enable email collect box
                  </Text>
                  <Select
                    fontSize={'sm'}
                    selectedValue={currentData.enableEmailCollect}
                    accessibilityLabel="Enable email collect box"
                    placeholder="Enable email collect box"
                    onValueChange={(itemValue) => {
                      setCurrentData({
                        ...currentData,
                        enableEmailCollect: itemValue,
                      });
                    }}
                  >
                    <Select.Item label="Enabled" value="Enabled" />
                    <Select.Item label="Disabled" value="Disabled" />
                  </Select>
                  <Text fontSize="xs" italic color="muted.400" fontWeight={500}>
                    Enable or disable email collect box on new conversation
                  </Text>
                </FormControl>
                <FormControl style={styles.screenTwo}>
                  <Text size={'mdNormal'} marginBottom={1}>
                    Set Reply Time
                  </Text>
                  <Select
                    fontSize={'sm'}
                    selectedValue={currentData.replyTime}
                    accessibilityLabel="Set Reply Time"
                    placeholder="Set Reply Time"
                    onValueChange={(itemValue) =>
                      setCurrentData({...currentData, replyTime: itemValue})
                    }
                  >
                    <Select.Item
                      label={INTEGRATION_CONSTANTS.REPLY_MINUTES}
                      value={INTEGRATION_CONSTANTS.REPLY_MINUTES}
                    />
                    <Select.Item
                      label={INTEGRATION_CONSTANTS.REPLY_HOURS}
                      value={INTEGRATION_CONSTANTS.REPLY_HOURS}
                    />
                    <Select.Item
                      label={INTEGRATION_CONSTANTS.REPLY_DAYS}
                      value={INTEGRATION_CONSTANTS.REPLY_DAYS}
                    />
                  </Select>
                  <Text fontSize="xs" italic color="muted.400" fontWeight={500}>
                    This reply time will be displayed on the live chat widget
                  </Text>
                </FormControl>
                <FormControl style={styles.screenTwo}>
                  <Text size={'mdNormal'} marginBottom={1}>
                    {intl.formatMessage({ id: 'enableRotationalAssignment' })}
                  </Text>
                  <Select
                    fontSize={'sm'}
                    selectedValue={currentData.enableAutoAssignment}
                    accessibilityLabel={intl.formatMessage({ id: 'enableRotationalAssignment' })}
                    placeholder={intl.formatMessage({ id: 'enableRotationalAssignment'})}
                    onValueChange={(itemValue) =>
                      setCurrentData({
                        ...currentData,
                        enableAutoAssignment: itemValue,
                      })
                    }
                  >
                    <Select.Item label="Enabled" value="Enabled" />
                    <Select.Item label="Disabled" value="Disabled" />
                  </Select>
                  <Text fontSize="xs" italic color="muted.400" fontWeight={500}>
                    Enable or disable the automatic assignment of new
                    conversations to the agents added to this inbox.
                  </Text>
                </FormControl>
                <FormControl style={styles.screenTwo}>
                  <Text size={'mdNormal'} marginBottom={1}>
                    Enable CSAT
                  </Text>
                  <Select
                    fontSize={'sm'}
                    selectedValue={currentData.enableCSAT}
                    accessibilityLabel="Enable CSAT"
                    placeholder="Enable CSAT"
                    onValueChange={(itemValue) =>
                      setCurrentData({...currentData, enableCSAT: itemValue})
                    }
                  >
                    <Select.Item label="Enabled" value="Enabled" />
                    <Select.Item label="Disabled" value="Disabled" />
                  </Select>
                  <Text fontSize="xs" italic color="muted.400" fontWeight={500}>
                    Enable/Disable CSAT(Customer satisfaction) survey after
                    resolving a conversation
                  </Text>
                </FormControl>
                <FormControl style={styles.screenTwo}>
                  <Text size={'mdNormal'} marginBottom={1}>
                    Allow messages after conversation resolved
                  </Text>
                  <Select
                    fontSize={'sm'}
                    selectedValue={currentData.allowAfterResolve}
                    accessibilityLabel="Allow messages after conversation resolved"
                    placeholder="Allow messages after conversation resolved"
                    onValueChange={(itemValue) =>
                      setCurrentData({
                        ...currentData,
                        allowAfterResolve: itemValue,
                      })
                    }
                  >
                    <Select.Item label="Enabled" value="Enabled" />
                    <Select.Item label="Disabled" value="Disabled" />
                  </Select>
                  <Text fontSize="xs" italic color="muted.400" fontWeight={500}>
                    Allow the end-users to send messages even after the
                    conversation is resolved
                  </Text>
                </FormControl>
                <FormControl>
                  <Text size={'mdNormal'} marginBottom={1}>
                    Features
                  </Text>
                  <HStack space={2} alignItems={'center'}>
                    <Checkbox
                      isChecked={filePicker}
                      onChange={(selectedValue) => setFilePicker(selectedValue)}
                      value={'filePicker'}
                    />
                    <Text size={'mdLight'}>
                      Display file picker on the widget
                    </Text>
                  </HStack>
                  <HStack
                    style={styles.spaceAbove}
                    space={2}
                    alignItems={'center'}
                  >
                    <Checkbox
                      isChecked={emojiPicker}
                      onChange={(selectedValue) =>
                        setEmojiPicker(selectedValue)
                      }
                      value={'emojiPicker'}
                    />
                    <Text size={'mdLight'}>
                      Display emoji picker on the widget
                    </Text>
                  </HStack>
                </FormControl>
              </VStack>
            </HStack>
            {updateLoading ? (
              <Spinner size="lg" style={spinnerStype.spinnerStyle} />
            ) : (
              <></>
            )}
          </ScrollView>
        )}
      </>
    );
  };
