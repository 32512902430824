import React, {useState} from 'react';
import {Modal, Typography} from 'antd';
import CreateCampaignModal from '../CreateCampaignModal';
import CreateOpportunityModal from '../CreateOpportunityModal';
import ButtonStyled from '../ButtonStyled';
import DisplayCard from '../DisplayCard';
import DisplayListProgress from '../DisplayListProgress';
const {Text} = Typography;

export default function PatientEngagementRiskChart({
  loading,
  data,
}: {
  loading: boolean;
  data: any;
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalCampaign, setIsModalCampaign] = useState(false);
  const [isModalOpportunity, setIsModalOpportunity] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    condition: '',
    percentage: 0,
    members: 0,
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModalCampaign = () => {
    setIsModalCampaign(true);
    handleCancel();
  };

  const handleCancelCampaign = () => {
    setIsModalCampaign(false);
  };

  const showModalOpportunity = () => {
    setIsModalOpportunity(true);
    handleCancel();
  };

  const handleCancelOpportunity = () => {
    setIsModalOpportunity(false);
  };

  return (
    <>
      <DisplayCard
        title="Chronic Patients"
        subtitle="Top 5 chronic conditions and the % of members they affect"
        loading={loading}
      >
        <DisplayListProgress
          data={data}
          selectItem={(item: any) => {
            const defaultPayload = {
              ...item,
              source: 'Patient Engagement Risk',
            };
            setSelectedItem(defaultPayload);
            showModal();
          }}
        />
      </DisplayCard>
      <Modal
        title={selectedItem?.condition}
        width={365}
        className={'patient_engagement_modal'}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Text style={{color: '#667085', marginBottom: '16px'}}>
          Members {selectedItem?.members}
        </Text>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            padding: '10px',
          }}
        >
          <ButtonStyled
            className="primaryFilledBtn"
            shape="round"
            size="large"
            onClick={showModalOpportunity}
          >
            Opportunity
          </ButtonStyled>
          <ButtonStyled
            className="primaryFilledBtn"
            shape="round"
            size="large"
            onClick={showModalCampaign}
          >
            Campaign
          </ButtonStyled>
        </div>
      </Modal>
      <CreateCampaignModal
        visible={isModalCampaign}
        handleCancel={handleCancelCampaign}
        selectedItem={selectedItem}
      />
      <CreateOpportunityModal
        visible={isModalOpportunity}
        handleCancel={handleCancelOpportunity}
        selectedItem={selectedItem}
      />
    </>
  );
}
