import {
  Condition,
  FamilyHistoryApiResponse,
  FamilyMemberHistoryData,
  Patient,
  Relationship,
} from './interfaces';

export const notAvailableString = '--';

export const getFormattedFamilyHistory = (
  response: FamilyHistoryApiResponse
): FamilyMemberHistoryData[] => {
  const entry = response.entry;
  const result: FamilyMemberHistoryData[] = [];
  entry.forEach((item) => {
    const resource = item.resource;
    result.push({
      id: getPatientIdFromPatientObject(resource.patient),
      text:
        resource.condition.length > 0
          ? getTextFromConditionObject(resource.condition[0])
          : notAvailableString,
      display:
        resource.condition.length > 0
          ? getDisplayFromConditionObject(resource.condition[0])
          : notAvailableString,
      relationship: getRelationshipFromRelationshipObject(
        resource.relationship
      ),
      patientId: getPatientIdFromPatientObject(resource.patient),
    });
  });

  return result;
};

export const getPatientIdFromPatientObject = (patientObject: Patient) => {
  const reference = patientObject.reference;
  if (!reference) {
    return '';
  }
  const separator = reference.includes('/') ? '/' : ':';
  return reference.split(separator).pop() || '';
};

export const getTextFromConditionObject = (condition: Condition) => {
  if (!condition?.code?.text) {
    return getDisplayFromConditionObject(condition);
  }
  return condition?.code?.text || notAvailableString;
};

export const getDisplayFromConditionObject = (condition: Condition) => {
  const coding = condition.code.coding;
  if (isEmptyArray(coding)) {
    return notAvailableString;
  }

  return coding[0]?.display || notAvailableString;
};

export const getRelationshipFromRelationshipObject = (
  relationship: Relationship
) => {
  const coding = relationship.coding;
  if (isEmptyArray(coding)) {
    return notAvailableString;
  }
  return coding[0]?.display || notAvailableString;
};

export const isEmptyArray = (array: any[]) => {
  return !array || array?.length === 0;
};
