import React from 'react';
import {Path, Svg} from 'react-native-svg';

function ScheduleSuggestionsIcon() {
  return (
    <Svg width="20" height="20" fill="none" viewBox="0 0 20 20">
      <Path
        fill="#667085"
        fillRule="evenodd"
        d="M7.087 1.75a.75.75 0 01.75.75v.751h4.505V2.5a.75.75 0 011.5 0v.751h1.502a2.256 2.256 0 012.25 2.252v4.503a.75.75 0 01-1.5 0v-.751H4.085v6.756c0 .412.34.752.75.752H9.34a.75.75 0 110 1.5H4.835a2.256 2.256 0 01-2.252-2.252V5.503A2.256 2.256 0 014.835 3.25h1.502V2.5a.75.75 0 01.75-.75zm-.75 3.001H4.835a.756.756 0 00-.752.752v2.252h12.012V5.503a.756.756 0 00-.751-.752h-1.502v.752a.75.75 0 01-1.5 0V4.75H7.837v.752a.75.75 0 11-1.5 0V4.75z"
        clipRule="evenodd"
      ></Path>
      <Path
        stroke="#667085"
        d="M9.372 10.197c.125-.263.571-.263.696 0l.544 1.15c.038.08.114.145.21.176l1.381.454c.316.104.316.476 0 .58l-1.38.453a.35.35 0 00-.21.176l-.545 1.15c-.125.263-.571.263-.696 0l-.544-1.15a.349.349 0 00-.21-.176l-1.381-.454c-.316-.103-.316-.475 0-.58l1.38-.453a.349.349 0 00.21-.175l.545-1.15zM14.519 12.873a.589.589 0 011.095 0l.608 1.54a.59.59 0 00.331.332l1.54.607c.498.196.498.9 0 1.096l-1.54.607a.59.59 0 00-.331.332l-.608 1.54a.589.589 0 01-1.095 0l-.608-1.54a.59.59 0 00-.331-.332l-1.54-.607a.589.589 0 010-1.096l1.54-.607a.59.59 0 00.331-.332l.608-1.54z"
      ></Path>
    </Svg>
  );
}

export default ScheduleSuggestionsIcon;
