import React from 'react';
import {
  BottomViewAction,
  getMissedTaskDaysMessage,
  getPriorityIcon,
} from '../../../TaskCard/TaskCardHelper';
import {ITask} from '../../CareDashboard/CareDashboardInterfaces';
import {HStack, Pressable, Spinner, Text, VStack} from 'native-base';
import {Colors} from '../../../../styles';
import CheckBox from '../../V2/Checkbox';
import {checkIfTaskIsCompleted} from '../../../TaskView/taskUtils';
import {getDateStrFromFormat, getMomentObj, isPastDay} from '../../../../utils/DateUtils';
import {DATE_FORMATS} from '../../../../constants';
import {Tooltip} from 'antd';
import Feather from 'react-native-vector-icons/Feather';

const TaskListItemV2 = (props: {
  task: ITask;
  onAction: (
    task: ITask,
    action: BottomViewAction,
    data?: any,
    context?: any
  ) => void;
  isLoading?: boolean;
  // NOTE: This is added so that we dont need to call the accountUsers api in this component and we can get the user name from the parent component,
  getUserName?: (userId: string) => string; 
}) => {
  const {task, onAction, isLoading} = props;
  const isCompleted = checkIfTaskIsCompleted(task);
  const title = task?.title;
  const priorityIcon = getPriorityIcon(task.priority, undefined, 0);
  const dueDate = getDateStrFromFormat(
    task?.endDateTime || new Date(),
    DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
  );
  const diffDayMessage = getMissedTaskDaysMessage(task);
  const seperator = `  •  `;
  const completedByUserName = (isCompleted && task?.completedBy) ? props.getUserName?.(task?.completedBy) : '';
  const currentTaskDuedate = task?.endDateTime
  const isPastDueDate = currentTaskDuedate ? isPastDay(getMomentObj(currentTaskDuedate)) : false
  const taskAction = isPastDueDate ? BottomViewAction.markAsMissed : BottomViewAction.markAsIncomplete;

  return (
    <HStack
      gap={2}
      alignItems={'flex-start'}
      my={2}
      flex={1}
    >
      {isLoading ? (
        <Spinner style={{marginTop: 4}} />
      ) : (
        <CheckBox
          style={{marginTop: 4}}
          isChecked={isCompleted}
          onChange={() => onAction(task, isCompleted ? taskAction : BottomViewAction.markAsComplete)}
        />
      )}
      <VStack ml={2} flex={1}>
        <Text
          width={'max-content'}
          mb={0.5}
          color={Colors.FoldPixel.GRAY300}
          isTruncated
          noOfLines={2}
        >
          {title}
        </Text>
        <Text color={Colors.FoldPixel.GRAY200}>
          {priorityIcon}
          {seperator}
          <Text color={Colors.FoldPixel.GRAY200}>{dueDate}</Text>
          {diffDayMessage && seperator}
          {!!diffDayMessage && (
            <Feather
              name="clock"
              size={15}
              style={{
                marginRight: 5,
              }}
              color={Colors.Custom.overdueTaskIconColor}
            />
          )}
          <Text
            color={Colors.Custom.overdueTaskIconColor}
            justifyContent={'center'}
          >
            {diffDayMessage}
          </Text>
          {isCompleted && completedByUserName && (
            <>
              {seperator}
              <Text color={Colors.FoldPixel.GRAY200} justifyContent={'center'}>
                Completed by: {completedByUserName}
              </Text>
            </>
          )}
        </Text>
      </VStack>
    </HStack>
  );
};

export default TaskListItemV2;
