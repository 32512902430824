export const EMAIL_INBOX_USER_CODE = {
  ALL: 'ALL',
  PATIENT_AND_LEAD: 'PATIENT_AND_LEAD',
  OTHER: 'OTHER'
}

export const EMAIL_INBOX_USER_VIEW = [
  {
    code: EMAIL_INBOX_USER_CODE.ALL,
    title: 'All (includes Patients, Leads and Contacts & others)'
  },
  {
    code: EMAIL_INBOX_USER_CODE.PATIENT_AND_LEAD,
    title: 'Only Patients, Leads & Contacts'
  },
  {
    code: EMAIL_INBOX_USER_CODE.OTHER,
    title: 'Only Others'
  }
]

export const PREFERENCES_TAB_LIST_CODE = {
  ACCOUNT_AND_PROFILE: 'ACCOUNT_AND_PROFILE',
  NOTIFICATION_SETTING: 'NOTIFICATION_SETTING',
  AUTO_REPLY: 'AUTO_REPLY',
  EMAIL_SETTINGS: 'EMAIL_SETTINGS',
}

export const PREFERENCES_TAB_LIST = [
  {
    code: PREFERENCES_TAB_LIST_CODE.NOTIFICATION_SETTING,
    title: 'Notification Settings'
  },
  {
    code: PREFERENCES_TAB_LIST_CODE.EMAIL_SETTINGS,
    title: 'Email Settings'
  },
  // {
  //   code: PREFERENCES_TAB_LIST_CODE.AUTO_REPLY,
  //   title: 'Auto Reply'
  // },
  {
    code: PREFERENCES_TAB_LIST_CODE.ACCOUNT_AND_PROFILE,
    title: 'Account & Profile'
  }
]

export const EMAIL_SIGNATURE_PREFERENCE_CODE = {
  NO_SIGNATURE: 'NO_SIGNATURE',
}

export const EMAIL_SIGNATURE_NO_SIGNATURE = {
  id: '1',
  title: 'No Signature',
  tempId: '1',
  bodyText: '',
};

export const ADD_SIGNATURE_EDITOR_OPTIONS = [
  ...['link', 'image'],
  ...['bold', 'italic', 'underline', 'strikethrough', 'brush'],
  ...['fontsize', 'font'],
  ...['left', 'center', 'right'],
  ...['ul'],
];
