import React from 'react';

import {Typography} from 'antd';
import AreaChart from '../../widgets/components/AreaChart';
const {Text} = Typography;

export default function DisplayArea({
  data,
  text,
  id,
  color,
  syncId,
  dataKey,
}: {
  data: any;
  text: string;
  id: any;
  color: string;
  syncId?: string;
  dataKey: string;
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Text style={{margin: '0 10px 0 0'}}> {text}</Text>
      <AreaChart
        data={data}
        width={48}
        height={40}
        color={color}
        id={id}
        syncId={syncId}
        dataKey={dataKey}
      />
    </div>
  );
}
