import {gql} from '@apollo/client';

export const CreateContactType = gql`
  mutation CreateContactType($contactId: Int!, $typeId: uuid!) {
    createContactType(object: {contactId: $contactId, typeId: $typeId}) {
      id
    }
  }
`;

export const UpdateContactType = gql`
  mutation UpdateContactType($contactId: Int!, $typeId: uuid!) {
    updateContactTypes(
      where: {contactId: {_eq: $contactId}}
      _set: {typeId: $typeId}
    ) {
      affected_rows
    }
  }
`;

export const GetPatientList = gql`
  query GetPatientList(
    $birthDate: string!
    $gender: string
    $firstName: string
  ) {
    fhir {
      PatientList(
        birthdate: $birthDate
        gender: $gender
        given: $firstName
      ) {
        id
        name {
          prefix
          given
          family
        }
        birthDate
        address {
          state
          city
          postalCode
        }
        gender
      }
    }
  }
`;

export const GetContactIdByPatientUuid = gql`
  query GetContactIdByPatientUuid($patientUuid: uuid) {
    contactPatients(where: {patientUuid: {_eq: $patientUuid}}) {
      patientUuid
      contactId
    }
  }
`;

export const GetPatientIdAndLocationId = gql`
query getPatientIdAndLocationId($patientUuid: uuid!){
  contactPatients(where:{patientUuid:{_eq:$patientUuid}}){
    id
    patientId
    contact {
      contactPracticeLocations {
        accountLocation {
          uuid
        }
      }
    }
  }
}
`;

// gql mutation to update contact type by patient uuid
export const UpdateContactTypeByPatientUuid = gql`
  mutation UpdateContactTypeByPatientUuid($id: uuid, $typeId: uuid!) {
    updateContactTypes(
      where: {id: {_eq: $id}}
      _set: {typeId: $typeId}
    ) {
      affected_rows
    }
  }
`;

export default {
  CreateContactType,
  UpdateContactType,
  GetPatientList,
  GetContactIdByPatientUuid,
  UpdateContactTypeByPatientUuid,
  GetPatientIdAndLocationId
};
