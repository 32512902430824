import React from "react";
import { Svg, Path } from 'react-native-svg';

function DragHandleIcon() {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <Path d="M19.7778 20.4444C20.7596 20.4444 21.5556 21.2404 21.5556 22.2222C21.5556 23.2041 20.7596 24 19.7778 24C18.7959 24 18 23.2041 18 22.2222C18 21.2404 18.7959 20.4444 19.7778 20.4444Z" stroke="#6F7A90" stroke-linecap="round" />
      <Path d="M19.7778 14.2222C20.7596 14.2222 21.5556 15.0182 21.5556 16C21.5556 16.9818 20.7596 17.7778 19.7778 17.7778C18.7959 17.7778 18 16.9818 18 16C18 15.0182 18.7959 14.2222 19.7778 14.2222Z" stroke="#6F7A90" stroke-linecap="round" />
      <Path d="M19.7778 8C20.7596 8 21.5556 8.79594 21.5556 9.77778C21.5556 10.7596 20.7596 11.5556 19.7778 11.5556C18.7959 11.5556 18 10.7596 18 9.77778C18 8.79594 18.7959 8 19.7778 8Z" stroke="#6F7A90" stroke-linecap="round" />
      <Path d="M11.7778 20.4444C12.7596 20.4444 13.5556 21.2404 13.5556 22.2222C13.5556 23.2041 12.7596 24 11.7778 24C10.7959 24 10 23.2041 10 22.2222C10 21.2404 10.7959 20.4444 11.7778 20.4444Z" stroke="#6F7A90" stroke-linecap="round" />
      <Path d="M11.7778 14.2222C12.7596 14.2222 13.5556 15.0182 13.5556 16C13.5556 16.9818 12.7596 17.7778 11.7778 17.7778C10.7959 17.7778 10 16.9818 10 16C10 15.0182 10.7959 14.2222 11.7778 14.2222Z" stroke="#6F7A90" stroke-linecap="round" />
      <Path d="M11.7778 8C12.7596 8 13.5556 8.79594 13.5556 9.77778C13.5556 10.7596 12.7596 11.5556 11.7778 11.5556C10.7959 11.5556 10 10.7596 10 9.77778C10 8.79594 10.7959 8 11.7778 8Z" stroke="#6F7A90" stroke-linecap="round" />
    </Svg>


  );
}

export default DragHandleIcon;