import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  modalContainer: {
    marginBottom: 'auto',
    marginTop: 60,
    minWidth: 600,
  },
  modalHeaderText: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  modalFooter: {
    backgroundColor: 'transparent',
    paddingLeft: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 4,
    padding: 0,
  },
  cancelButton: {
    backgroundColor: 'red',
  },
  inputStyle: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#D0D5DD',
  },
  formControlStyle: {
    marginTop: 4,
  },
  labelStyle: {
    marginTop: 4,
  },
  selectStyle: {
    height: 40,
  },
  errorText: {
    color: 'red',
  },
});
