import {ColumnsType} from 'antd/lib/table';
import {Text} from 'native-base';
import {getUSLocaleDateString} from '../../../common/Notifications/NotificationsUtils';
import {ITemplateCategory} from '../EmailTemplates/interfaces';
import {sorter} from '../SMSTemplates/SMSTemplatesUtils';
import {EmailComponentsTableActions} from './EmailComponentsTableActions';
import {EmailComponentsTableColumnArgs} from './interfaces';

export const getEmailComponentTableColumns = (
  args: EmailComponentsTableColumnArgs
) => {
  const {actionFn, componentTypes, filteredValues} = args;
  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      fixed: 'left',
      render(value, record, index) {
        return <Text>{value}</Text>;
      },
    },
    {
      title: 'Type',
      dataIndex: 'componentType',
      key: 'componentType',
      // filters: componentTypes,
      // filteredValue: filteredValues,
      // onFilter(value: any, record: SingleEmailComponent) {
      //   return record?.componentType?.name?.indexOf(value) == 0;
      // },
      width: '20%',
      render(value: ITemplateCategory, record, index) {
        return <Text>{value?.name}</Text>;
      },
    },
    {
      title: 'Default',
      key: 'isDefault',
      dataIndex: 'isDefault',
      width: '10%',
      render(isDefault: boolean) {
        return <Text>{isDefault ? 'Yes' : 'No'}</Text>;
      },
    },
    {
      title: 'Last Updated',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      width: '20%',
      sorter: sorter,
      // defaultSortOrder: defaultSortOrder,
      render(lastUpdated: string) {
        const date = getUSLocaleDateString(lastUpdated);
        return <Text>{date}</Text>;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '20%',
      fixed: 'right',
      render(value, record, index) {
        return (
          <EmailComponentsTableActions record={record} actionFn={actionFn} />
        );
      },
    },
  ];

  return columns;
};
