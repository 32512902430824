import React from 'react';
import {ICommonSvgProps} from './interfaces';

export const UnArchivedSvg = (props: ICommonSvgProps) => {
  const {defaultColor} = props;
  const strokeColor = defaultColor ? defaultColor : '#8C210F';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 20 20"
    >
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_7543_106814)"
      >
        <path
          stroke={strokeColor}
          d="M17.5 6.667V17.5h-15V6.667M.833 2.5h18.334v4.167H.833V2.5z"
        ></path>
        <path
          stroke={strokeColor}
          d="M10 14.167v-5m0 0l2.5 2.5m-2.5-2.5l-2.5 2.5"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_7543_106814">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
