import React from 'react';
import { ICommonSvgProps } from './interfaces';

const DialPadButtonSvg = (props: ICommonSvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="45"
      fill="none"
      viewBox="0 0 46 45"
    >
      <g filter="url(#filter0_dd_561_50578)">
        <rect width="38" height="38" x="4" y="2.5" fill={props.isActive ? "#825AC7": "#fff"} rx="19"></rect>
        <rect
          width="38"
          height="38"
          x="4"
          y="2.5"
          stroke="#F9FAFB"
          rx="19"
        ></rect>
        <path
          stroke={props.isActive ? "#fff": "#667085"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M23 28.167a.833.833 0 100-1.667.833.833 0 000 1.667zM28.833 28.167a.833.833 0 100-1.667.833.833 0 000 1.667zM17.167 28.167a.833.833 0 100-1.667.833.833 0 000 1.667zM23 22.333a.833.833 0 100-1.666.833.833 0 000 1.666zM28.833 22.333a.833.833 0 100-1.666.833.833 0 000 1.666zM17.167 22.333a.833.833 0 100-1.666.833.833 0 000 1.666zM23 16.5a.833.833 0 100-1.667.833.833 0 000 1.667zM28.833 16.5a.833.833 0 100-1.667.833.833 0 000 1.667zM17.167 16.5a.833.833 0 100-1.667.833.833 0 000 1.667z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_561_50578"
          width="45"
          height="45"
          x="0.5"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_561_50578"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_561_50578"
            result="effect2_dropShadow_561_50578"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_561_50578"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default DialPadButtonSvg;
