import React, { useMemo } from 'react';
import { Path, Svg } from 'react-native-svg';
import { Colors } from '../../styles';

const CloseSideBarSvg = React.memo(() => {
  const svgContent = useMemo(() => (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <Path
        d="M10.666 2.33268V15.2493M14.8327 7.33268L13.166 8.99935L14.8327 10.666M8.99935 17.3327C5.07098 17.3327 3.10679 17.3327 1.8864 16.1123C0.666016 14.8919 0.666016 12.9277 0.666016 8.99935C0.666016 5.07098 0.666016 3.10679 1.8864 1.8864C3.10679 0.666016 5.07098 0.666016 8.99935 0.666016C12.9277 0.666016 14.8919 0.666016 16.1123 1.8864C17.3327 3.10679 17.3327 5.07098 17.3327 8.99935C17.3327 12.9277 17.3327 14.8919 16.1123 16.1123C14.8919 17.3327 12.9277 17.3327 8.99935 17.3327Z"
        stroke={Colors.FoldPixel.GRAY300}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  ), []);

  return svgContent;
});

export default CloseSideBarSvg;
