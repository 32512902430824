import {gql} from '@apollo/client';

export const GET_PRESCRIBED_LAB = gql`
  query GetPrescribedLab($ids: [uuid!]!, $employerUuid: uuid!,$accountUuid: uuid!) {
    prescribedLab(
      where: {
        id: {
          _in: $ids
        }
      }
    ) {
      id
      key:id
      name
      code
      description
      codeType
      prescribedLabCptcodes {
        id
        key: id
        cptCodeData{
          code
          description
        }
        accountPrescribedLab: accountLabCPTPrescribedData(where: {
          employerUuid: {
              _eq: $employerUuid
            },
            accountUuid: {
              _eq: $accountUuid
            }
        }) {
          id
          key:id
          prescribedLabId
          accountUuid
          employerUuid
          prescribedLabCost {
            id
            price
            priceData
            marketPriceData
            currentMarketPrice
          }
        }
      }
      accountPrescribedLab(where: {
        employerUuid: {
          _eq: $employerUuid
        },
        accountUuid: {
          _eq: $accountUuid
        },
        prescribedLabCptcodeId: {
          _is_null: true
        }
      }) {
        id
        key:id
        prescribedLabId
        accountUuid
        employerUuid
        prescribedLabCost {
          id
          price
          priceData
          marketPriceData
          currentMarketPrice
        }
      }
    }
  }
`;


export const GET_PRESCRIBED_LAB_ACC = gql`
  query GetPrescribedLab($ids: [uuid!]!, $accountUuid: uuid!) {
    prescribedLab(
      where: {
        id: {
          _in: $ids
        }
      }
    ) {
      id
      key:id
      name
      code
      description
      codeType
      prescribedLabCptcodes {
        id
        key: id
        cptCodeData{
          code
          description
        }
        accountPrescribedLab: accountLabCPTPrescribedData(where: {
            accountUuid: {
              _eq: $accountUuid
            }
        }) {
          id
          key:id
          prescribedLabId
          accountUuid
          employerUuid
          prescribedLabCost {
            id
            price
            priceData
            marketPriceData
            currentMarketPrice
          }
        }
      }
      accountPrescribedLab(where: {
        accountUuid: {
          _eq: $accountUuid
        },
        prescribedLabCptcodeId: {
          _is_null: true
        }
      }) {
        id
        key:id
        prescribedLabId
        accountUuid
        employerUuid
        prescribedLabCost {
          id
          price
          priceData
          marketPriceData
          currentMarketPrice
        }
      }
    }
  }
`;

export const UPDATE_PRESCRIBED_LAB_COST = gql`
  mutation UpdatePrescribedLabCost(
    $price: float8,
    $priceData: jsonb,
    $marketPriceData: jsonb,
    $id:uuid!,
    $currentMarketPrice: float8
  ) {
    updatePrescribedLabCost(
      pk_columns: {id: $id}
      _set: {
        price: $price,
        priceData: $priceData,
        marketPriceData: $marketPriceData,
        currentMarketPrice: $currentMarketPrice,
      }
    ) {
      id
      price
      priceData
      marketPriceData
    }
  }
`;


export const CREATE_PRESCRIBED_LAB_COST = gql`
  mutation CreatePrescribedLabCost(
    $id: uuid
    $accountUuid: uuid
    $price: float8
    $marketPriceData: jsonb
    $priceData: jsonb
    $currentMarketPrice: float8
  ) {
    createPrescribedLabCost(
      object: {
        accountPrescribedLabId: $id,
        accountUuid: $accountUuid,
        price: $price,
        priceData: $priceData,
        marketPriceData: $marketPriceData,
        currentMarketPrice: $currentMarketPrice,
      }
    ) {
      id
      accountUuid
      accountPrescribedLabId
      price
      priceData
      marketPriceData
      currentMarketPrice
    }
  }
`;
