import {Table, Tooltip} from 'antd';
import {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {Colors} from '../../../../styles/Colors';
import {View, Text, Center, Skeleton} from 'native-base';
import AntDesign from 'react-native-vector-icons/AntDesign';
import JourneyLineChart from '../Analytics/JourneyLineChart';
import Feather from 'react-native-vector-icons/Feather';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import {getSectionDataByResourceCode} from '../../../../services/Analytics';
import moment from 'moment';
import {IJourneyProgressTableColumns} from './type';
import {ColumnType} from 'antd/lib/table';
import { getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../../../constants';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { getCurrentTimeZone } from '../../../../utils/DateUtils';

const JourneyProgressTable = (props: {record: any}) => {
  const [tableSource, setTableSource] = useState<{
    columns: ColumnType<IJourneyProgressTableColumns>[];
    dataSource: any[];
    graphData: any[];
    totalCount: number;
    completedCount: number;
    isLoading: boolean;
  }>({
    columns: [],
    dataSource: [],
    graphData: [],
    completedCount: 0,
    totalCount: 0,
    isLoading: true,
  });
  const mlovData = useContext(CommonDataContext);
  const taskEntityTypes =
  getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_ENTITY_TYPE
  ) || [];

  const {height, width} = Dimensions.get('window');

  const fetchAndUpdateTableData = async () => {
    const resp = await getSectionDataByResourceCode(
      'CARE_JOURNEY_OVERALL_ADHERENCE_BY_ENTITY_TYPE_OF_CONTACT',
      {
        resourceCode:
          'CARE_JOURNEY_OVERALL_ADHERENCE_BY_ENTITY_TYPE_OF_CONTACT',
        isAccountDashboard: false,
        appliedFilter: [
          {
            filterCode: 'CARE_JOURNEY_ID',
            filterValue: {
              value: props?.record.careJourneyId,
            },
          },
          {
            filterCode: 'PATIENT_CARE_JOURNEY_ID',
            filterValue: {
              value: props?.record?.id,
            },
          },
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              timezone: getCurrentTimeZone(),
            },
          },
        ],
      },
      'drill-down-data'
    );

    const record: Record<string, any> = {};
    const dateWiseRecord: Record<string, any> = {};
    let totalCount = 0;
    let completedCount = 0;
    const dates: any[] = [];
    resp.data.result.forEach((item: any) => {
      //create date array
      const date = item?.end_date?.value;
      date && !dates.includes(date) && dates.push(date);

      //overall count
      totalCount = totalCount + item.count;

      //datewise value
      if (!dateWiseRecord[date]) {
        dateWiseRecord[date] = {};
      }

      const totalDateWise = dateWiseRecord[date]['total'] || 0;
      dateWiseRecord[date]['total'] = item.count + totalDateWise;
      if (item.status_code == 'completed') {
        completedCount = completedCount + item.count;
        const existingCount = dateWiseRecord[date]['completed'] || 0;
        dateWiseRecord[date]['completed'] = item.count + existingCount;
      }

      //record extract
      if (!record[item.entity_type_code]) {
        record[item.entity_type_code] = {};
      }
      if (!record[item.entity_type_code][date]) {
        record[item.entity_type_code][date] = {};
      }
      const total = record[item.entity_type_code][date]['total'] || 0;
      record[item.entity_type_code][date]['total'] = item.count + total;

      if (item.status_code == 'completed') {
        const existingCount =
          record[item.entity_type_code][date]['completed'] || 0;
        record[item.entity_type_code][date]['completed'] =
          item.count + existingCount;
      }
    });

    const graphData = Object.keys(dateWiseRecord).map((item) => {
      const record = dateWiseRecord[item];
      return {
        type: 'adherence',
        date: item,
        month: moment(item).format('DD MMM YY'),
        value: record.completed ? (record.completed * 100) / record.total : 0,
      };
    });
    graphData?.sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1));

    const dataSource = Object.keys(record).map((item) => {
      return {
        type: taskEntityTypes.find(entityType => entityType.code === item)?.value,
        ...record[item],
      };
    });
    dates.sort((a, b) => (new Date(a) < new Date(b) ? -1 : 1));

    const columns: ColumnType<IJourneyProgressTableColumns>[] = [
      {
        title: (
          <View
            style={{
              padding: 12,
            }}
          >
            <Text size={'smMedium'} color={Colors.Custom.Gray500}>
              Task Type
            </Text>
          </View>
        ),
        dataIndex: 'contact',
        key: 'contact',
        fixed: 'left',
        width: '180px',
        render: (value: any, record: any, index: number) => {
          return (
            <View
              style={{
                paddingHorizontal: 12,
                paddingVertical: 8,
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: Colors.Custom.ContainerBGColor,
                borderRightColor: Colors.Custom.Gray200,
                borderRightWidth: 1,
              }}
            >
              <Text size={'smMedium'} color={Colors.Custom.ShadowColor}>
                {record.type}
              </Text>
            </View>
          );
        },
      },
    ];
    dates.forEach((item) => {
      const dateWiseRecordVal = dateWiseRecord[item];
      const width =
        ((dateWiseRecordVal.completed || 0) * 100) / dateWiseRecordVal.total;
      columns.push({
        title: (
          <View
            style={{
              padding: 8,
            }}
          >
            <Text
              style={{fontSize: 14}}
              size={'smMedium'}
              color={Colors.Custom.Gray300}
            >
              {moment(item).format('ddd')}
            </Text>
            <Text style={{fontSize: 14, marginVertical: 4}} size={'smBold'}>
              {moment(item).format('DD MMM YY')}
            </Text>
            <View
              style={{
                width: '100%',
                height: 5,
                backgroundColor: Colors.Custom.Gray200,
                borderRadius: 10,
                overflow: 'hidden',
              }}
            >
              <View
                style={{
                  width: `${width}%`,
                  height: 5,
                  backgroundColor:
                    width < 100
                      ? Colors.Custom.brandAccentColor
                      : Colors.success[500],
                }}
              />
            </View>
          </View>
        ),
        dataIndex: 'contact',
        key: 'index1',
        width: '90px',
        render: (value: any, record: any, index: number) => {
          const val: any = record?.[item];
          return (
            <View
              style={{
                paddingHorizontal: 12,
                paddingVertical: 8,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {val?.total ? (
                <Tooltip
                  title={`${val?.completed || 0}/${val?.total} Tasks completed`}
                >
                  {val?.total === val?.completed ? (
                    <AntDesign
                      size={20}
                      name="check"
                      color={Colors.success[500]}
                    />
                  ) : (
                    <AntDesign
                      size={20}
                      name="check"
                      color={Colors.Custom.brandAccentColor}
                    />
                  )}
                </Tooltip>
              ) : (
                <Tooltip
                  title={`No Associated Tasks`}
                >
                  <AntDesign
                    size={20}
                    name="close"
                    color={Colors.Custom.Gray200}
                  />
                </Tooltip>
              )}
            </View>
          );
        },
      });
    });
    setTableSource((prev) => {
      return {
        ...prev,
        graphData,
        dataSource,
        columns,
        totalCount,
        completedCount,
        isLoading: false,
      };
    });
  };

  useEffect(() => {
    fetchAndUpdateTableData();
  }, []);

  const graphWidth = 75 * tableSource.graphData.length;

  if (tableSource.isLoading) {
    return (
      <Center marginY={4} flex={1}>
        <Skeleton.Text lines={10} />
      </Center>
    );
  }

  return (
    <View
      style={{
        borderWidth: 1,
        borderRadius: 8,
        borderColor: Colors.Custom.Gray200,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          marginTop: 10,
        }}
      >
        <View
          style={{
            borderRightWidth: 1,
            borderRightColor: Colors.Custom.Gray200,
            marginRight: 10,
            width: 130,
          }}
        >
          <View
            style={{
              padding: 12,
              borderBottomColor: Colors.Custom.Gray200,
              borderBottomWidth: 1,
              flex: 1,
            }}
          >
            <Text size={'smSemiBold'} fontSize={16}>
              Total Tasks
            </Text>
            <Text size={'smBold'} fontSize={16}>
              {tableSource.totalCount}
            </Text>
          </View>
          <View
            style={{
              paddingHorizontal: 12,
              borderBottomColor: Colors.Custom.Gray200,
              borderBottomWidth: 1,
              flex: 1,
            }}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <Feather
              size={18}
              name="check-circle"
              color={Colors.success[500]}
              style={{marginRight: 8}}
            />
            <Text size={'smSemiBold'} fontSize={16}>
              {tableSource.completedCount}
            </Text>
          </View>
          <View
            style={{
              paddingHorizontal: 12,
              flex: 1,
            }}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <MaterialIcons
              size={18}
              name="call-missed-outgoing"
              color={Colors.error[500]}
              style={{marginRight: 8}}
            />
            <Text size={'smSemiBold'} fontSize={16}>
              {tableSource.totalCount - tableSource.completedCount}
            </Text>
          </View>
        </View>
        <View overflowY="scroll" style={{marginTop: 10, flex: 1}}>
          <View
            style={{
              width: tableSource.graphData.length
                ? graphWidth < 400
                  ? 400
                  : graphWidth
                : width,
            }}
          >
            <JourneyLineChart
              yAxisColor={Colors.Custom.Gray200}
              tooltipText="Overall Adherence"
              xLabel=""
              yLabel="Overall Adherence"
              data={tableSource.graphData}
              height={180}
              width={
                tableSource.graphData.length
                  ? graphWidth < 400
                    ? 400
                    : graphWidth
                  : width
              }
            />
          </View>
        </View>
      </View>
      <Table
        className="calender-table-drawer"
        dataSource={tableSource.dataSource}
        columns={tableSource.columns}
        pagination={false}
        scroll={{x: width * 0.4}}
        rowClassName={(record, index) =>
          index % 2 == 0 ? 'table-row-light' : ''
        }
      />
    </View>
  );
};

export default JourneyProgressTable;
