import {View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import QualityMeasuresService from '../../../../../../../services/Contracts/QualityMeasures.service';
import PatientList from './PatientList';
import {useLazyQuery} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../../constants/Configs';
import {MLOV_CATEGORY, QUALITY_MEASURES_PATIENT_TYPE} from '../../../../../../../constants';
import {ICareTeams, IMeasureReport} from '../../../../HelperFiles/interface';
import {getAccountUUID} from '../../../../../../../utils/commonUtils';
import {getContactAndMeasureFormattedData} from '../../../../HelperFiles/utils';
import {CONTACT_TABLE_COLUMN_CODE} from '../../../../../../common/MemebersView/constant';
import {GET_CARE_TEAM_MEMBER_TYPE} from '../../../../../../../services/CareTeam/CareTeamQueries';
import {getMlovIdFromCode, getMlovListFromCategory} from '../../../../../../../utils/mlovUtils';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {CARE_TEAM} from '../../../../../../../constants/MlovConst';
import {GetTimezoneOfAccountAndLocations} from '../../../../../../../services/Location/UserPracticeLocationQueries';
import UserQueries, {GET_ACTIVE_INACTIVE_ACCOUNT_USERS} from '../../../../../../../services/User/UserQueries';

const PatientView = (props: any) => {
  const {searchString, patientType} = props;
  const {contractId, qmId} = useParams();
  const accountUuid = getAccountUUID();
  const [stateData, setStateData] = useState({
    patientList: [] as any[],
    currentPage: 1,
    pageSize: 10,
    total: 10,
    offSet: 0,
    loading: false,
    accountUsers: [],
    accountUserLoading: false
  });
  const commonContextData = useContext(CommonDataContext);
  const careTeamList = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.PRIMARY_CARE_TEAM) || [];
  const careTeamTypeId = getMlovIdFromCode(careTeamList, CARE_TEAM.CODE);

  const [qualityMeasureReport] = useLazyQuery(
    QualityMeasuresService.qualityMeasureReport,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );
  const [getContactByUuids] = useLazyQuery(
    QualityMeasuresService.getContactByUuids,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [getCareTeamMemberByMemberType] = useLazyQuery(
    GET_CARE_TEAM_MEMBER_TYPE,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  );
  const [getAccountUsers] = useLazyQuery(UserQueries.GET_ACCOUNT_USERS, {
    fetchPolicy: 'no-cache',
  });
  const handlePageChange = (page: number, currentPageSize: number) => {
    const offSet = currentPageSize * (page - 1);
    setStateData((prev) => {
      return {
        ...prev,
        currentPage: page,
        offSet: offSet,
        pageSize: currentPageSize,
      };
    });
  };
  const fetchAccountUsers = async ()=> {
    setStateData(prev=> {
      return {
        ...prev,
        accountUserLoading: true
      }
    })
    const accountUsersResp = await getAccountUsers();
    setStateData(prev=> {
      return {
        ...prev,
        accountUsers: accountUsersResp.data.accountUsers,
        accountUserLoading: false
      }
    })
  }
  const fetchQualityMeasuresPatients = async () => {
    setStateData((prev) => ({...prev, loading: true}));
    const filterObject = {
      isDeleted: {_eq: false},
      isLatest: {_eq: true},
      contractId: {_eq: contractId || ''},
      measureId: {_eq: qmId || ''},
      tenantId: {_eq: accountUuid},
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED && {
        numeratorFlag: {_eq: true},
      }),
      ...(patientType ===
        QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED && {
        numeratorFlag: {_eq: false},
        denominatorFlag: {_eq: true},
      }),
    };

    try {
      const {data} = await qualityMeasureReport({
        variables: {
          where: filterObject,
          offset: stateData.offSet,
          limit: stateData.pageSize,
        },
      });

      const reports: IMeasureReport[] = data?.qmMeasureReports || [];
      const total = data?.aggregateQmMeasureReport?.aggregate?.count || 0;

      if (reports.length) {
        const contactUuids = reports.map((report) => report.contactId);
        const contactsData = await getContactByUuids({
          variables: {uuids: contactUuids},
        });
        const contacts = contactsData?.data?.contacts || [];
        const patientList = getContactAndMeasureFormattedData({
          qmMeasureReports: reports,
          contactData: contacts,
          patientType,
        });
        const response = await getCareTeamMemberByMemberType({
          variables: {
            contactIds: contactUuids,
            careTeamTypeId: careTeamTypeId,
          },
        });
        const careTeams: ICareTeams[] = response?.data?.careTeams;
        for (const careTeam of careTeams) {
          if (careTeam?.contactId) {
            careTeam.careTeamMemberType.forEach((careMember) => {
              const findUser: any = stateData.accountUsers.find(
                (accountUser: any) => {
                  return accountUser.user.uuid === careMember.userId;
                }
              );
              if (findUser?.uuid) {
                careMember.userName = findUser?.user?.name;
              }
            });
          }
        }
        patientList.forEach((contact) => {
          const careTeam = careTeams?.find(
            (careTeam) => careTeam.contactId === contact.contactData.uuid
          );
          contact.pcp = careTeam || ({} as ICareTeams);
          contact.patientType = patientType
        });
        props.updateContactCount?.(total)
        setStateData((prev) => ({
          ...prev,
          patientList,
          loading: false,
          total,
        }));
      } else {
        setStateData((prev) => ({
          ...prev,
          patientList: [],
          loading: false,
        }));
      }
    } catch (error) {
      setStateData((prev) => ({
        ...prev,
        patientList: [],
        loading: false,
      }));
    }
  };

  useEffect(() => {
    fetchQualityMeasuresPatients();
  }, [stateData.currentPage, stateData.pageSize]);

  useEffect(() => {
    if (stateData.currentPage !== 1) {
      setStateData((prev) => {
        return {
          ...prev,
          currentPage: 1,
          pageSize: 10,
          offSet: 0,
        };
      });
    } else {
      if (!stateData.accountUserLoading) {
        fetchQualityMeasuresPatients();
      }
    }
  }, [patientType, stateData.accountUsers.length]);
  useEffect(()=> {
    fetchAccountUsers()
  },[])
  return (
    <View>
      <PatientList
        handlePageChange={handlePageChange}
        stateData={stateData}
        data={stateData.patientList}
        patientType={patientType}
      />
    </View>
  );
};
export default PatientView;
