import {
  Button,
  Divider,
  HStack,
  Icon,
  Spacer,
  useMediaQuery,
  View,
  VStack,
} from 'native-base';
import {useIntl} from 'react-intl';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import PlusIcon from '../../common/Svg/PlusSvg';
import {SearchBar} from '../../common/SearchBar';
import {BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../constants';
import {ModalActionAntSelect} from '../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {HolidayActions, HolidayFilterAction} from './HolidayConst';
import {Colors} from '../../../styles/Colors';
import {HolidayFilterInterface} from './Interfaces';
import { debounce } from 'lodash';

export const HolidayTopBar = (props: {
  filterValue: HolidayFilterInterface;
  showTableTopBar?: boolean;
  onSearch: (text: string) => void;
  onAction: (actionCode: string, actionData?: any) => void;
}) => {
  const {filterValue, showTableTopBar, onSearch, onAction} = props;
  const intl = useIntl();
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  return (
    <View>
      <View paddingX={4} paddingY={2}>
        <HStack>
          <VStack justifyContent={'center'}>
            <View>
              <DisplayText textLocalId="holidays" textType="Heading" />
            </View>
            <View style={{width: '70%'}}>
              <DisplayText textLocalId="holidaySubtitle" size={'xsMedium'} extraStyles={{color: Colors.Custom.Gray500}} />
            </View>
          </VStack>
          <Spacer />
          <VStack>
            <HStack justifyContent={'flex-end'} alignItems="center">
              {showTableTopBar && 
              <VStack justifyContent={'flex-end'}>
                <View marginRight={5}>
                  <SearchBar
                    width={250}
                    placeholderText="Search holidays"
                    searchBarInputStyles={{backgroundColor: 'white'}}
                    placeholderTextColor={Colors.Custom.Gray400}
                    onChange={debounce(onSearch, 500)}
                  />
                </View>
              </VStack>
              }
              <HStack>
                <View mr={5}>
                  <ModalActionAntSelect
                    allowClear={false}
                    showSearch={false}
                    value={filterValue}
                    onChange={(filterHoliday: HolidayFilterInterface, data: any) => {
                      onAction(HolidayActions.APPLY_FILTER, data);
                    }}
                    data={HolidayFilterAction}
                    optionProps={{key: 'key', value: 'value', label: 'label'}}
                    extraStyle={{flex: 1}}
                    customStyle={{
                      flex: 1,
                      fontSize: 15,
                      fontWeight: 400,
                      width: isSmallScreen ? 200 : 150,
                      borderColor: Colors.Custom.Gray300,
                    }}
                  />
                </View>
              {showTableTopBar && 
                <FoldButton
                  nativeProps={{
                    variant: BUTTON_TYPE.PRIMARY,
                    onPress: () => {
                      onAction(HolidayActions.ADD_NEW);
                    },
                    leftIcon: <PlusIcon />,
                  }}
                  customProps={{
                    btnText: intl.formatMessage({id: 'addNewHolidays'}),
                    withRightBorder: false,
                  }}
                ></FoldButton>
              }
              </HStack>
            </HStack>
          </VStack>
        </HStack>
      </View>
      <Divider />
    </View>
  );
};

export default HolidayTopBar;
