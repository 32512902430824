import moment from 'moment';
import { ApiResponse, DateMap, EngagementGraphData } from './interfaces';

export const getStartAndEndDate = (month: any, year: any) => {
  month = month - 1;
  year = month === -1 ? year - 1 : year;
  const lastDate = moment().date(1).month(month).daysInMonth();
  return {
    startDate: moment().date(1).month(month).year(year).startOf('day').toISOString(),
    endDate: moment()
      .date(lastDate)
      .month(month)
      .year(year)
      .endOf('day')
      .toISOString(),
    date: moment().date(1).month(month).year(year),
  };
};

export const getDateMaps = (): {[index in string]: DateMap} => {
  const now = moment();
  const endDate = now.endOf('day').toISOString();
  const startDate = moment()
    .date(1)
    .month(now.month())
    .year(now.year())
    .startOf('day')
    .toISOString();
  const fifthMonth = getStartAndEndDate(now.month(), now.year());
  const fourthMonth = getStartAndEndDate(
    fifthMonth.date.month(),
    fifthMonth.date.year()
  );
  const thirdMonth = getStartAndEndDate(
    fourthMonth.date.month(),
    fourthMonth.date.year()
  );
  const secondMonth = getStartAndEndDate(
    thirdMonth.date.month(),
    thirdMonth.date.year()
  );
  const firstMonth = getStartAndEndDate(
    secondMonth.date.month(),
    secondMonth.date.year()
  );

  const dateMap: {[index in string]: DateMap} = {
    sixth: {
      fromDate: startDate,
      toDate: endDate,
    },
    fifth: {
      fromDate: fifthMonth.startDate,
      toDate: fifthMonth.endDate,
    },
    fourth: {
      fromDate: fourthMonth.startDate,
      toDate: fourthMonth.endDate,
    },
    third: {
      fromDate: thirdMonth.startDate,
      toDate: thirdMonth.endDate,
    },
    second: {
      fromDate: secondMonth.startDate,
      toDate: secondMonth.endDate,
    },
    first: {
      fromDate: firstMonth.startDate,
      toDate: firstMonth.endDate,
    },
  };

  return dateMap;
};

export const getGraphData = (args: {
  response: any;
  dateMaps: {[x: string]: DateMap};
  type: 'Engagement' | 'Members';
}) => {
  const {response, dateMaps, type} = args;
  const data: {
    [index in string]: ApiResponse;
  } = response.data;
  const graphData: EngagementGraphData[] = [];
  Object.keys(data).map((item) => {
    const object = data[item];
    const count = object?.aggregate?.count || 0;
    const fromDate = dateMaps[item].fromDate;
    const toDate = dateMaps[item].toDate;
    graphData.push({
      count,
      fromDate,
      toDate,
      type: type,
    });
  });

  return graphData;
};
