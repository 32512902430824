import React from 'react';
import {Colors} from '../../../styles';

const CheckSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33337 8.06065L5.57373 10.5499C5.957 10.9758 6.61901 10.9932 7.02413 10.5881L12.2788 5.33337"
        stroke="#6F7A90"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default CheckSvg;
