import { Icon } from "native-base";
import { ITableTopBarProps } from "../../../../../common/TableTopBar";
import AntIcon from 'react-native-vector-icons/AntDesign';
export const getTaxonomyTopBarButtons = (
    title:string,
    buttonClickFnList: (() => void)[]
  ): ITableTopBarProps => {
    return {
      title: title,
      hideSearchBar: true,
      buttonList: [
        {
          btnText: 'addNewTaxon',
          size: 'sm',
          colorScheme: 'primary',
          textColor: 'white',
          variant: '',
          leftIcon: (
            <Icon as={AntIcon} name={'plus'} size="3" width={10} height={10} />
          ),
          btnClick: () => {
            buttonClickFnList[0]();
          },
        },
      ],
    };
  };