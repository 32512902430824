import { ColumnsType } from 'antd/lib/table';
import { Text, View, Pressable } from 'react-native';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../styles';
import EditSvg from '../../../../assets/Icons/EditSvg';
import {capitalizeFirstOtherSmallLetter} from '../../ContentManagement/CampaignManagement/Helper';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {IContactTypes, ICustomFieldData} from './interface';
import {ALL_CONTACT_TYPE, CONTACT_TYPES_VALUE} from './CustomFieldConst';
import {useIntl} from 'react-intl';

export const getCustomFiledColumns = (
  onCustomFieldActionPerformed: (actionCode: string, record: ICustomFieldData) => void
) => {
  const intl = useIntl();
  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{
            color: Colors.Custom.Gray500,
            fontWeight: 500,
            fontSize: 14,
            marginLeft: 8
          }}
          textLocalId="name"
        />
      ),
      dataIndex: 'title',
      key: 'title',
      fixed: 'left',
      width: '12%',
      render: (
        title: string,
        record: ICustomFieldData
      ) => (
        <Text
          style={{
            fontSize: 16,
            fontWeight: '500',
            color: Colors.Custom.Gray600
          }}
        >
          {title}
          <Text
            style={{
              fontSize: 14,
              fontWeight: '500',
              color: Colors.Custom.Gray500
            }}
          >
            {record?.isDefault ? ` (${intl.formatMessage({id: 'default'})})` : ''}
          </Text>
        </Text>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{
            color: Colors.Custom.Gray500,
            fontWeight: 500,
            fontSize: 14,
            marginLeft: 8
          }}
          textLocalId="description"
        />
      ),
      dataIndex: 'description',
      key: 'description',
      width: '23%',
      render: (description: string) => (
        <Text
          style={{
            fontSize: 16,
            fontWeight: '500',
            color: Colors.Custom.Gray600
          }}
        >
          {description}
        </Text>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500,
            fontWeight: 500,
            fontSize: 14,
            marginLeft: 8
          }}
          textLocalId="contactTypes"
        />
      ),
      dataIndex: 'contactTypes',
      key: 'contactTypes',
      width: '23%',
      render: (contactTypes: IContactTypes[]) => {
        const visibleInData = contactTypes;
        return (
          <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
            {visibleInData?.map((label: IContactTypes)=> {
              let name = label?.id === ALL_CONTACT_TYPE.id ? capitalizeFirstOtherSmallLetter(ALL_CONTACT_TYPE.id) : label?.value || label;
              if (name === CONTACT_TYPES_VALUE.CUSTOMER) {
                name = CONTACT_TYPES_VALUE.PATIENT;
              }
              return (
                <View style={{
                  marginRight: 8,
                  marginBottom: 4,
                }}>
                  <Text
                    style={{
                      backgroundColor: Colors.Custom.Gray100,
                      paddingHorizontal: 8,
                      paddingVertical: 3,
                      borderWidth: 1,
                      borderColor: Colors.Custom.Gray200,
                      borderRadius: 16,
                    }}>
                      {name}
                    </Text>
                </View>
              )
            })}
          </View>
        )
      }
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500,
            fontWeight: 500,
            fontSize: 14,
            marginLeft: 8
          }}
          textLocalId="type"
        />
      ),
      dataIndex: 'type',
      key: 'type',
      width: '12%',
      render: (type: string) => (
        <Text
          style={{
            fontSize: 16,
            fontWeight: '500',
            color: Colors.Custom.Gray600
          }}
        >
          {capitalizeFirstOtherSmallLetter(type || '')}
        </Text>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500,
            fontWeight: 500,
            fontSize: 14,
            marginLeft: 8
          }}
          textLocalId="status"
        />
      ),
      dataIndex: 'active',
      key: 'active',
      width: '16%',
      render: (active: boolean, record: ICustomFieldData) => {
        return (
          <View style={{
            borderWidth: 1,
            width: 44,
            height: 22,
            borderColor: active
              ? Colors.Custom.mainPrimaryPurple
              : Colors.Custom.Gray300,
            borderRadius: 15,
            alignItems: 'center',
            justifyContent: 'center',
            opacity: record?.isDefault ? 0.3 : 1,
          }}>
            <div className={`custom-switch ${active? 'checked' : ''}`} onClick={() => {
              if (!record?.isDefault) {
                onCustomFieldActionPerformed(COMMON_ACTION_CODES.STATUS_CHANGED, {...record,value: active})
              }
            }}>
              <div className={`custom-switch-handle ${active? 'checked' : ''}`} ></div>
            </div>
          </View>
        )
      }
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500,
            fontWeight: 500,
            fontSize: 14,
            marginLeft: 8
          }}
          textLocalId="actions"
        />
      ),
      key: 'actions',
      fixed: 'right',
      width: '10%',
      render: (record: ICustomFieldData) => {
        return (
          <View>
            <Pressable
              disabled={record?.isDefault}
              style={{
                width: 34,
                height: 34,
                opacity: record?.isDefault ? 0.3 : 1
              }}
              onPress={() => {
                onCustomFieldActionPerformed(COMMON_ACTION_CODES.EDIT, record);
              }}>
              <EditSvg />
            </Pressable>
          </View>
        );
      },
    },
  ];
  return columns;
};