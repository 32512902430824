import { useEffect } from 'react';
import {FormControl, HStack, Icon, Text} from 'native-base';
import {Colors} from '../../../../styles/Colors';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {Select as MultiSelect, Tooltip} from 'antd';
import {ALL_LOCATION_CODE} from '../../../../constants';
import { useIntl } from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';

export interface ILocationKeyValue {
  key: string;
  value: string;
  label: string;
}

export interface ILocationMultiSelect {
  isRequired?: boolean;
  showError?: boolean;
  selectedLocationIds: string[];
  locations: ILocationKeyValue[];
  onChange: (value: string[]) => void;
  labelInfo?: string
}

export const LocationMultiSelect = (props: ILocationMultiSelect) => {
  const {selectedLocationIds, locations, onChange, isRequired, showError,labelInfo} =
    props;
  const intl = useIntl();
  const handleLocationChange = (
    values: string[],
    selectedLocations: any | any[],
  ) => {
    if (!onChange && !(typeof onChange === 'function')) {
      return;
    }
    const selectedLocationIds: any[] =
      (selectedLocations || []).map((item: ILocationKeyValue) => item.value) ||
      [];
    if (
      selectedLocationIds?.length &&
      selectedLocationIds?.includes(ALL_LOCATION_CODE)
    ) {
      const locationIds: any[] = [];
      (locations || []).forEach(location => {
        if (location?.key !== ALL_LOCATION_CODE) {
          locationIds.push(location?.key);
        }
      });
      onChange(locationIds);
      return;
    }
    onChange(selectedLocationIds);
  };

  useEffect(() => {
    if (
      selectedLocationIds?.length === 1 &&
      selectedLocationIds?.includes(ALL_LOCATION_CODE)
    ) {
      const locationIds: any[] = [];
      (locations || []).forEach(location => {
        if (location?.key !== ALL_LOCATION_CODE) {
          locationIds.push(location?.key);
        }
      });
      onChange(locationIds);
      return;
    }
  },[]);

  return (
    <FormControl flexDir="column" flex={1} marginTop={2}>
      <HStack>
      <FormControl.Label
        marginLeft={0}
        isRequired={isRequired}
        isInvalid={showError}
      >
        <HStack>
          <DisplayText
            size={'smMedium'}
            extraStyles={{
              color: Colors.Custom.Gray500,
              fontWeight: '400',
              fontSize: 14,
              marginLeft: 10,
            }}
            textLocalId={'location'}
          />
        </HStack>
       
      </FormControl.Label>
      
      {labelInfo && (
          <Tooltip
            title={intl.formatMessage({id: labelInfo})}
            placement={'top'}
            destroyTooltipOnHide={true}
            showArrow={false}
          >
            <Icon mt={1} ml={-2} color={Colors.Custom.Gray500} as={AntIcon} name="infocirlceo" size="smMedium" />
          </Tooltip>
        )}
      </HStack>
      <MultiSelect
        options={locations}
        mode="multiple"
        size="large"
        allowClear
        value={selectedLocationIds}
        placeholder="Select Location"
        onChange={handleLocationChange}
        maxTagCount={'responsive'}
      />
      {showError && selectedLocationIds?.length == 0 && (
        <Text fontSize={'xs'} color="error.500" fontWeight={500} my={1}>
          Please select Location
        </Text>
      )}
    </FormControl>
  );
};
