import React from "react";

const ChronicRisksSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 39 39"
    >
      <path
        fill={svgColors}
        d="M33.516 11.616h-9.75V7.313c0-.675-.545-1.22-1.22-1.22H7.009V4.57a.306.306 0 00-.305-.304H4.57a.306.306 0 00-.304.304v29.86c0 .167.137.304.304.304h2.133a.306.306 0 00.305-.304V24.375h9.445v4.304c0 .674.545 1.219 1.219 1.219h15.844c.674 0 1.218-.545 1.218-1.22V12.836c0-.674-.544-1.219-1.218-1.219z"
      ></path>
    </svg>
  );
}

export default React.memo(ChronicRisksSvg);
