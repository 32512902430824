import {View, Text, Pressable} from 'native-base';
import {Colors} from '../../../../styles/Colors';
import JourneyGauge from '../Analytics/JourneyGauge';
import JourneyProgressTable from './JourneyProgressTable';
import {useContext, useEffect, useState} from 'react';
import NodataViewTypeOne from '../../Analytics/EmployerAnalytics/SectionAndWidget/NoDataView/NodataViewTypeOne';
import {MLOV_CATEGORY} from '../../../../constants';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {getMlovListFromCategory} from '../../../../utils/mlovUtils';
import AntDesign from 'react-native-vector-icons/AntDesign';

const JourneyProgressAdherence = (props: {
  dataByEntityType: any[];
  record: any;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const mlovData = useContext(CommonDataContext);

  const taskEntityTypes =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_ENTITY_TYPE
    ) || [];

  return (
    <>
      <View
        style={{
          flexDirection: 'column',
        }}
      >
        <Text
          style={{
            color: Colors.Custom.Gray700,
            fontSize: 16,
            padding: 12,
          }}
          size={'smBold'}
        >
          Adherence by Task Types
        </Text>
        <View
          flexDirection={'row'}
          flexWrap={'wrap'}
          justifyContent={'flex-start'}
        >
          {props?.dataByEntityType?.length ? (
            props?.dataByEntityType?.map?.((item) => {
              const overallAdherence =
                item?.overallAdherencePercentage?.toFixed(2) || 0;

              return (
                <View style={{padding: 12, width: 120}}>
                  <Text
                    style={{
                      marginBottom: 8,
                      textAlign: 'center',
                    }}
                    size={'smBold'}
                    fontSize={14}
                  >
                    {taskEntityTypes?.find(
                      (subitem) => subitem.code == item.entityTypeCode
                    )?.value || ''}
                  </Text>
                  <View style={{}}>
                    <JourneyGauge
                      height={100}
                      fontSize="12px"
                      rangeWidth={6}
                      width={100}
                      value={parseFloat(overallAdherence)}
                    />
                  </View>
                </View>
              );
            })
          ) : (
            <View
              style={{
                paddingTop: 5,
                width: '100%',
              }}
            >
              {' '}
              <NodataViewTypeOne viewCode="TEXT_ICON" />
            </View>
          )}
        </View>
        {props?.dataByEntityType?.length ? (
          <Pressable
            flexDirection={'row'}
            alignItems={'center'}
            onPress={() => {
              setIsVisible(!isVisible);
            }}
            style={{
              margin: 12,
            }}
          >
            {isVisible ? (
              <>
                <AntDesign
                  color={Colors.Custom.PurpleColor}
                  size={12}
                  name="up"
                />{' '}
                <Text
                  style={{
                    color: Colors.Custom.PurpleColor,
                    fontSize: 12,
                    marginLeft: 4,
                  }}
                  size={'smBold'}
                >
                  {`Hide Detailed View`}
                </Text>
              </>
            ) : (
              <>
                <AntDesign
                  color={Colors.Custom.PurpleColor}
                  size={12}
                  name="down"
                />{' '}
                <Text
                  style={{
                    color: Colors.Custom.PurpleColor,
                    fontSize: 12,
                    marginLeft: 4,
                  }}
                  size={'smBold'}
                >
                  {`Show Detailed View`}
                </Text>
              </>
            )}
          </Pressable>
        ) : null}
      </View>
      {isVisible ? (
        <>
          <JourneyProgressTable record={props.record} />
        </>
      ) : null}
    </>
  );
};

export default JourneyProgressAdherence;
