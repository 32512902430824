import React from 'react';
import Svg, {Path} from 'react-native-svg';

const MemberAddedSvg = () => {
  return (
    <Svg width="17" height="16" viewBox="0 0 17 16" fill="none">
      <Path
        d="M11.111 14V12.6667C11.111 11.9594 10.8183 11.2811 10.2974 10.781C9.77648 10.281 9.06994 10 8.33323 10H3.47211C2.7354 10 2.02886 10.281 1.50793 10.781C0.986994 11.2811 0.694336 11.9594 0.694336 12.6667V14M13.8888 5.33333V9.33333M15.9721 7.33333H11.8054M8.68045 4.66667C8.68045 6.13943 7.43679 7.33333 5.90267 7.33333C4.36855 7.33333 3.12489 6.13943 3.12489 4.66667C3.12489 3.19391 4.36855 2 5.90267 2C7.43679 2 8.68045 3.19391 8.68045 4.66667Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></Path>
    </Svg>
  );
};

export default MemberAddedSvg;
