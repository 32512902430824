import {IAdditionalAttribute, IStepsLog} from "../../../services/ContactCareProgram/interface";
import { ICareProgramAssignee } from "../../PersonOmniView/MiddleContainer/CarePlan/interfaces";

export interface IContactCareProgram {
  id: string;
  payerCareProgram: IPayerCareProgram;
  contactCareProgramSteps: IContactCareProgramStep[];
  // consentLog: null;
  assessmentLogs: IAssessmentLog[];
  contactId: string;
  eligibilityDateTime: string;
  statusId: string;
  assigneeId?: string;
  assigneeTypeId?: string;
  stepsLog: IStepsLog[];
}


export interface IAssessmentLog {
  id: string;
  careProgramStepId: string;
  resourceId: string;
  resourceTypeCode: string;
  contactCareProgramId: string;
}

export interface IContactCareProgramStep {
  id: string;
  title: string;
  careProgramStepType: IMlov;
  parentStepId?: string;
  careProgramSubSteps: ICareProgramSubStep[];
  sequenceNumber: number;
  displaySequenceNumber: number;
  careProgramStepAdditionalAttributes: IAdditionalAttribute[];
  // used for UI only
  subSteps?: IContactCareProgramStep[];
  isExpanded?: boolean;
}

export interface IMlov {
  id: string;
  code: string;
  value: string;
}

export interface IAttributeValue {
  valueBoolean?: boolean;
  valueString?: string;
  valueJson?: any;
}

export interface ICareProgramSubStep {
  id: string;
  careProgramSubStepType: IMlov;
  careProgramStepStatus: IMlov;
}

export interface IPayerCareProgram {
  careProgramTypeId: string;
  careProgramType: IMlov;
}

export enum ContactCareProgramComponentStatus {
  LOADING = 'loading',
  ERROR = 'error',
  EMPTY = 'empty',
  SUCCESS = 'success',
}


export interface IContactCareProgramState {
  contactCareProgramDetails?: IContactCareProgram;
  status: ContactCareProgramComponentStatus | undefined;
  selectedStepId: string | undefined;
  activeStepId: string | undefined;
  sideBarExpandedStepKeysSet: Set<string>;
  groupedSteps: IContactCareProgramStep[];
  stepProgressMap: Map<string, number>;
}


export interface IFormLogResponse {
  componentWiseProgress: Record<string, number>;
  formPrefilledByFoldPercent: number;
  submittedData: any[];
  formComponents: any[];
  formLog: any;
  previousFormLogIsExisting?: boolean;
}

export enum AddButtonAction {
  SEND_FORM = 'sendForm',
  BILLING = 'billing',
}
export interface ICareProgramAssignmentView {
  contactCareProgramId: string;
  contactId: string;
  careProgramAssigneeId?: string;
  onAssigneeActionPerformed: (assigneeId: string) => void;
  onCareProgramAssigneeChange?: (value: ICareProgramAssignee) => void;
  popoverContent?: React.ReactNode;
  parentPopoverVisible?: boolean;
}


export type CareProgramStepPayload = Omit<
  IContactCareProgramStep & {
    additionalAttributes: IAdditionalAttribute[];
  },
  | 'id'
  | 'careProgramStepType'
  | 'careProgramSubSteps'
  | 'careProgramStepAdditionalAttributes'
>;

export type AddCareProgramStepInput = {
  contactId: string;
  contactCareProgramId: string;
  careProgramSteps: CareProgramStepPayload[];
}