import {StyleSheet} from "react-native";
import {Colors} from "../../../../../styles";

export const style = StyleSheet.create({
  modalContainer: {
    width: '80%',
    marginHorizontal: 'auto',
  },
  formElement: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  formLabel: {
    marginLeft: 4,
  },
  headerText: {
    fontWeight: '400',
    fontSize: 20,
    color: '#344054',
    paddingLeft: 16
  },
  smallInput: {
    width: '20%',
    paddingHorizontal: '10px',
  },
  checkboxStyle: {
    borderColor: Colors.Custom.PrimaryColor,
    marginLeft: '10px'
  },
  largeInput: {
    width: '80%',
  },
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '75%',
  },

})