import React, {useMemo} from 'react';
import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import {SEARCH_USERS_BY_NAME_AND_ROLE_CODES} from '../../../services/User/UserQueries';
import {IUser} from '../../../Interfaces';
import {useQuery} from '@apollo/client';
import {
  getMlovIdFromCode,
  getMlovListByCategory,
} from '../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../constants';
import {
  CARE_TEAM_MEMBER_TYPE,
  USER_ROLE_CODES,
} from '../../../constants/MlovConst';
import {ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP} from '../../PersonOmniView/RightContainer/CareTeamView/CareTeamUtils';

interface ICareManagerSearchProps {
  value: string | undefined;
  onChange: (uuid: string, name: string) => void;
}

const CareManagerSearch = ({value, onChange}: ICareManagerSearchProps) => {
  const careTeamMemberTypesList = getMlovListByCategory(
    MLOV_CATEGORY.CARE_TEAM_MEMBER_TYPE
  );

  const careTeamMemberTypes = {
    [CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]: getMlovIdFromCode(
      careTeamMemberTypesList,
      CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER
    ),
    [CARE_TEAM_MEMBER_TYPE.PCP]: getMlovIdFromCode(
      careTeamMemberTypesList,
      CARE_TEAM_MEMBER_TYPE.PCP
    ),
    [CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]: getMlovIdFromCode(
      careTeamMemberTypesList,
      CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
    ),
  };

  const userWhereSearch = useMemo(() => {
    const condition = {
      userRoles: {
        userRole: {
          userRole: {code: {_in: ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]}},
        },
      },
      _not: {
        userRoles: {
          userRole: {
            userRole: {
              code: {
                _in: [
                  USER_ROLE_CODES.WORFLOW_USER,
                  USER_ROLE_CODES.CUSTOMER_SUCCESS,
                ],
              },
            },
          },
        },
      },
      accountUsers: {
        isActive: { _eq: true } // Added condition to filter only active users
      }
    };
    return condition;
  }, [careTeamMemberTypes.CARE_MANAGER]);

  const userQuery = useQuery<{
    users: IUser[];
  }>(SEARCH_USERS_BY_NAME_AND_ROLE_CODES, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        ...userWhereSearch,
        name: {
          _ilike: '%%',
        },
      },
    },
  });

  return (
    <Select
      size="large"
      showSearch
      allowClear
      filterOption={false}
      value={value || undefined}
      disabled={userQuery.loading}
      onSearch={debounce(
        (value) =>
          userQuery.refetch({
            where: {
              ...userWhereSearch,
              name: {
                _ilike: `%${value}%`,
              },
            },
          }),
        200
      )}
      onChange={(uuid: string) => {
        const selectedUser = userQuery?.data?.users.find(user => user.uuid === uuid);
        if (selectedUser) {
          onChange(uuid, selectedUser ? selectedUser.name : ''); 
        } else {
          onChange('', ''); 
        }
      }}
      placeholder={!userQuery.loading ? 'Search Care Managers' : 'Loading...'}
      loading={userQuery.loading}
      notFoundContent={userQuery.loading && <Spin size="large" />}
      style={{width: '100%'}}
    >
      {userQuery?.data?.users?.map(
        (item: {uuid: string; id: string; name: string}) => {
          return (
            <Select.Option key={item.uuid} value={item.uuid} title={item.name}>
              {item.name}
            </Select.Option>
          );
        }
      )}
    </Select>
  );
};

export default CareManagerSearch;
