import {Button, Popover} from 'antd';
import React, {useState} from 'react';
import Feather from 'react-native-vector-icons/Feather';
import {LOCATION_FILTER_RESOURCE_CODE} from '../../../../../constants';
import {LOCATION_FILTER, RESET_LOCATION_FILTER, WIDGET_ACTIONS, WIDGET_ACTIONS_FORM_UPDATE} from '../EmployerUtils/EmployerConst';

interface IWidgetActionView {
  onActionClick: (code: string, data?: any) => void;
  isFormAssigned?:boolean;
  resourceCode?: string;
  sectionCode?: string;
  widgetFilterList?: {
    filterCode: string,
    filterValue: any | any[]
  }[]
}
interface IWidgetAction {
  code: string;
    id: number;
    name: string
}
const WidgetActionView = (props: IWidgetActionView) => {
  const  {isFormAssigned, resourceCode, widgetFilterList}= props
  const [isOpen, setIsOpen] = useState(false);
  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };
  const actionList: IWidgetAction[] = !isFormAssigned ? [WIDGET_ACTIONS] : [WIDGET_ACTIONS, WIDGET_ACTIONS_FORM_UPDATE]
  if (LOCATION_FILTER_RESOURCE_CODE.includes(resourceCode || '')) {
    if (widgetFilterList?.length) {
      const isLocationFilter = widgetFilterList?.find((item) => {
        return item.filterCode === 'LOCATION'
      })
      if (isLocationFilter?.filterCode) {
        actionList.push(RESET_LOCATION_FILTER)
      } else {
        actionList.push(LOCATION_FILTER)
      }
    } else {
      actionList.push(LOCATION_FILTER)
    }
  }
   const data = {
    resourceCode:  props.resourceCode,
    sectionCode: props.sectionCode
   }
  const content = () => {
    return (
      <div style={{display:'flex', flexDirection: 'column',alignItems: 'flex-start'}}>
        {actionList.map((item, index) => {
          return (
            <button
              style={{
                padding: 0,
                margin: 0,
                color: 'black',
                border: 'none',
                borderBottom: (actionList?.length -1) == index ? 'none' : '1px solid #D0D5DD',
                paddingTop:  index === 0 ? 4 : 10,
                paddingBottom: 10,
                // marginBottom:  WIDGET_ACTIONS.length - 1 !== index ? 4 : 8,
                backgroundColor: '#fff',
                width: '100%',
                textAlign: 'left'
              }}

              key={item.id}
              onClick={() => {
                setIsOpen(false);
                props.onActionClick(item.code, data);
              }}
            >
              {item.name}
            </button>
          );
        })}
      </div>
    );
  };
  return (
    <Popover
      // key={props?.record?.id}
      overlayInnerStyle={{padding: 0, borderRadius: 16}}
      overlayStyle={{padding: 0}}
      overlay={{padding: 0}}
      style={{padding: 0, borderRadius: 16}}
      placement={'bottom'}
      trigger="click"
      visible={isOpen}
      onVisibleChange={handleVisibleChange}
      content={content}
    >
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        style={{
          border: 'none',
          backgroundColor: 'transparent',
          padding: 2
        }}
      >
        <Feather
          name="more-vertical"
          style={{
            fontSize: 16,
            color: '#475467',
          }}
        />
      </Button>
    </Popover>
  );
};

export default React.memo(WidgetActionView);
