import {Popover} from 'antd';
import {Button, HStack, Pressable, Text, VStack} from 'native-base';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import Feather from 'react-native-vector-icons/Feather';
import {useNavigate} from 'react-router-dom';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {IMembersTableActionsArgs} from '../interfaces';
// import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import {styles} from './MembersTableActionsStyles';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {DeleteEmployerModal} from '../../EmployerManagement/DeleteEmployerModal';
import {CONTACT_ACTION_CODES} from '../../Contacts/ContactsConst';

const MembersTableActions = (props: IMembersTableActionsArgs) => {
  const {onActionPerformed, record} = props;
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const currentStatus = props?.record?.contact?.active
  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteEmployee = () => {
    onActionPerformed(COMMON_ACTION_CODES.DELETE, record);
  };

  const quickActionList = [
    {
      displayString: props?.isVbcTenant? 'Edit Member': 'Edit Employee',
      actionCode: COMMON_ACTION_CODES.EDIT_MEMBER,
    },
    {
      displayString: currentStatus ? 'Set Inactive' : 'Activate',
      actionCode: COMMON_ACTION_CODES.DEACTIVATE,
    },
    {
      displayString: 'delete',
      actionCode: COMMON_ACTION_CODES.DELETE,
    },
  ];

  const content = (
    <VStack style={{padding: 0, borderRadius: 16}}>
      {quickActionList.map((action: any, index: any) => {
        return (
          <Pressable
            key={index}
            onPress={() => {
              if (action.actionCode == CONTACT_ACTION_CODES.DEACTIVATE) {
                onActionPerformed(action.actionCode, record);
              }else{
              if (action.actionCode == COMMON_ACTION_CODES.DELETE) {
                setOpenDeleteModal(true);
              } else {
                onActionPerformed(action.actionCode, record);
              }
            }
              setIsOpen(!isOpen);
            }}
          >
            <HStack style={[styles.mainMenuContent, styles.firstMenu]}>
              <Text style={styles.mainMenuTxt}>
                <DisplayText
                  size={'smMedium'}
                  textLocalId={action.displayString}
                />
              </Text>
            </HStack>
          </Pressable>
        );
      })}
    </VStack>
  );

  return (
    <>
      <Popover
        overlayInnerStyle={{padding: 0, borderRadius: 16}}
        overlayStyle={{padding: 0}}
        overlay={{padding: 0}}
        style={{padding: 0, borderRadius: 16}}
        placement={'left'}
        trigger="click"
        visible={isOpen}
        onVisibleChange={handleVisibleChange}
        content={content}
      >
        <Button
          onPress={() => {
            setIsOpen(true);
          }}
          style={styles.btn}
        >
          <Feather
            name="more-vertical"
            style={[
              styles.icons,
              // {color: Colors.Custom.PrimaryBtnColor}
            ]}
          />
        </Button>
      </Popover>

      <DeleteEmployerModal
        isMembers={true}
        isOpen={openDeleteModal}
        onConfirm={() => {
          handleDeleteEmployee();
          setOpenDeleteModal(false);
        }}
        onClose={() => setOpenDeleteModal(false)}
      />
    </>
  );
};

export default MembersTableActions;
