import {useMutation} from '@apollo/client';
import {Drawer} from 'antd';
import {FormControl, Spinner, TextArea} from 'native-base';
import React, {useState} from 'react';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {LeadQueries} from '../../../services';
import {Colors} from '../../../styles/Colors';
import {getAccountId, getAccountUUID, getUserUUID} from '../../../utils/commonUtils';
import {DisplayText} from '../DisplayText/DisplayText';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {styles} from './DeactivateContactViewStyles';

const DeactivateContactView = (props: any) => {
  const currentStatus = props.isMemberDeactivate ? props?.record?.contact?.active : props?.record?.contactData?.isActive;

  const [showModal, setShowModal] = useState(true);
  const [information, setInformation] = useState('');
  const {contactId} = props;
  const [deactivateContact] = useMutation(LeadQueries.DeactivateContact);
  const [deactivateContactData] = useMutation(LeadQueries.DeactivateContactData);

  const closeModal = () => {
    setShowModal(false);
    props.onDeleteCompleteAction(COMMON_ACTION_CODES.ADDED_OR_UPDATED);
  };
  const accountUuid = getAccountUUID();
  const accountId = getAccountId();
  const userUuidId = getUserUUID();

  const onDeactivateAction = () => {
    const promiseList = [
      deactivateContact({
        variables: {
          contactUuid: contactId,
          accountUuid: accountUuid,
          loginUserUUID: userUuidId,
          isActive: currentStatus ? false : true,
          reason: information,
        },
      }),
      deactivateContactData({
        variables: {
          contactUuid: contactId,
          isActive: currentStatus ? false : true,
          accountId: accountId,
        },
      }),
    ];
    Promise.all(promiseList).then(() => {
      closeModal();
    });
  };
  const {width} = Dimensions.get('window');
  const finalWidth = 650;
  return (
    <>
      <Drawer
        visible={showModal}
        width={finalWidth}
        onClose={() => closeModal()}
        title={
          <>
            <ModalActionTitle
              title={currentStatus ? 'confirmDeactivate' : 'confirmActivate'}
              titleColor={''}
              buttonList={[
                {
                  show: true,
                  id: 1,
                  btnText: 'cancel',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: () => {
                    closeModal();
                  },
                },
                {
                  show: true,
                  id: 2,
                  btnText: currentStatus ? 'confirmDeactivate' : 'activate',
                  textColor: Colors.Custom.mainPrimaryPurple,
                  variant: BUTTON_TYPE.PRIMARY,
                  isTransBtn: false,
                  // leftIcon: '',
                  onClick: () => {
                    onDeactivateAction();
                  },
                },
              ]}
            />
          </>
        }
      >
        {false ? <Spinner size="lg" style={styles.spinnerStyle} /> : <></>}
        <FormControl style={[styles.formElement]}>
          <FormControl.Label style={styles.formLabel}>
            <DisplayText
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray700}}
              textLocalId={
                currentStatus
                  ? 'deactivateConfirmation'
                  : 'activationConfirmation'
              }
            />
          </FormControl.Label>
          <TextArea
            borderRadius={8}
            height={'100px'}
            value={information}
            numberOfLines={5}
            onChangeText={(value) => {
              setInformation(value);
            }}
          />
        </FormControl>
        {/* <Button.Group space={2} justifyContent={'flex-end'} width={'100%'}>
          <ModalActionBtn
            btnText="cancel"
            btnStype={BUTTON_TYPE.SECONDARY}
            onClickAction={() => {
              closeModal();
            }}
            customStyle={{marginRight: 20}}
          />
          <ModalActionBtn
            btnText="delete"
            btnStype={BUTTON_TYPE.PRIMARY}
            onClickAction={() => {
              onDeactivateAction();
            }}
          />
        </Button.Group> */}
      </Drawer>
    </>
  );
};

export default DeactivateContactView;
