import {BILLING_API_PATH} from '../../constants/BillingApiPath';
import BaseService from '../CommonService/BaseService';

export const getAuditLogs = async (
  accountUuid: string,
  page: number,
  pageSize: number
) => {
  const baseService = BaseService.getSharedInstance().axios;
  if (accountUuid) {
    return await baseService.get(
      `${BILLING_API_PATH.AUDIT_LOGS_LIST}/${accountUuid}?page=${page}&pageSize=${pageSize}`
    );
  }
};
