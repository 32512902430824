import React from 'react';
import { ICommonSvgProps } from './interfaces';

const CallTransfer = (props: ICommonSvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="45"
      fill="none"
      viewBox="0 0 46 45"
    >
      <g filter="url(#filter0_dd_2779_215380)">
        <rect width="38" height="38" x="4" y="2.5" fill={props.isActive ? "#825AC7" :"#fff"} rx="19"></rect>
        <g clipPath="url(#clip0_2779_215380)">
          <path
            stroke={!props.isEnabled ? "#E4E7EC" : props.isActive ? "#fff" :"#667085"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M28.833 12.333l3.334 3.334m0 0L28.833 19m3.334-3.333H25.5m5.833 9.933v2.5a1.666 1.666 0 01-1.816 1.667 16.492 16.492 0 01-7.192-2.559 16.248 16.248 0 01-5-5 16.49 16.49 0 01-2.558-7.225 1.664 1.664 0 011.658-1.816h2.5a1.666 1.666 0 011.667 1.433c.105.8.3 1.586.583 2.342A1.666 1.666 0 0120.8 18.7l-1.058 1.058a13.333 13.333 0 005 5L25.8 23.7a1.667 1.667 0 011.758-.375 10.7 10.7 0 002.342.583 1.667 1.667 0 011.433 1.692z"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_2779_215380"
          width="45"
          height="45"
          x="0.5"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2779_215380"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_2779_215380"
            result="effect2_dropShadow_2779_215380"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_2779_215380"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_2779_215380">
          <path
            fill="#fff"
            d="M0 0H20V20H0z"
            transform="translate(13 11.5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default CallTransfer;
