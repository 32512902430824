import {Box, View} from 'native-base';
import {Dimensions} from 'react-native';
import {PRODUCT_SERVICES_FLOW_TYPE_CODE} from '../../../../../../../../../constants';
import {Colors} from '../../../../../../../../../styles';
import CustomTabsView from '../../../../../../../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../../../../../../../common/CustomTabsView/interfaces';
import {ProductImages} from '../../../../ProductImages/ProductImages';
import {Stock} from '../../../../Stock/Stock';
import {DetailViewForm} from './Forms/DetailViewForm';
import {IProductDetailViewFormContainerProps} from './interfaces';
import {styles} from './ProductDetailViewFormContainerStyle';

const ProductDetailViewFormContainer = (
  props: IProductDetailViewFormContainerProps
) => {
  const {
    selectedMenuCode,
    productData,
    selectedTaxon,
    onActionPerformed,
    tabType,
  } = props;
  const {height} = Dimensions.get('window');
  const finalHeight = height - 100;

  const productDetailTabs = (): ITabsList[] => {
    const orderDetailTabView: ITabsList[] = [
      {
        key: 'details',
        title: 'Details',
        path: 'details',
        tabContainerElem: () => {
          return (
            <DetailViewForm
              productData={productData}
              journeyData={{isCareJourney: false, isAssignWorkflow: false}}
              selectedTaxon={selectedTaxon}
              onActionPerformed={onActionPerformed}
              tabType={tabType}
            />
          );
        },
      },
      // {
      //   key: 'properties',
      //   title: 'Properties',
      //   path: 'properties',
      //   tabContainerElem: () => {
      //     return (
      //       <PropertiesFormView
      //         productData={productData}
      //         parentHeight={finalHeight}
      //       />
      //     );
      //   },
      // },
      // {
      //   key: 'variant',
      //   title: 'Variant',
      //   path: 'variant',
      //   tabContainerElem: () => {
      //     return <VariantContainerTable productData={productData} />;
      //   },
      // },
      {
        key: 'stock',
        title: 'Stock',
        path: 'stock',
        tabContainerElem: () => {
          return <Stock productData={productData} parentHeight={finalHeight} />;
        },
      },
      {
        key: 'productImages',
        title: 'Product Images',
        path: 'productImages',
        tabContainerElem: () => {
          return (
            <ProductImages
              productData={productData}
              parentHeight={finalHeight}
            />
          );
        },
      },
      // {
      //   key: 'prices',
      //   title: 'Prices',
      //   path: 'prices',
      //   tabContainerElem: () => {
      //     return (
      //       <Prices productData={productData} parentHeight={finalHeight} />
      //     );
      //   },
      // },
    ];
    return orderDetailTabView;
  };

  return (
    <>
      <Box style={styles.mainContainer} rounded="lg">
        <View height={finalHeight}>
          {/* {getFormFromMenuCode()} */}
          <CustomTabsView
            isLoading={props.isLoading}
            tabsList={productDetailTabs()}
            tabListWrapperStyle={{
              paddingHorizontal: 16,
            }}
          />
        </View>
      </Box>
    </>
  );
};

export default ProductDetailViewFormContainer;
