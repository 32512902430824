import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  tabStyles: {
    paddingHorizontal: 8,
    paddingVertical: 8,
    justifyContent: 'center',
    marginRight: 8,
    alignItems: 'center',
    fontSize: 16,
  },
});
