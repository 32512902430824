import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  shadowBoxUserAccessMainTable: {
    overflow: 'scroll',
    rounded: 'lg',
    // marginLeft: 20,
    // marginRight: 20,
    marginBottom: 8,
  },
  view2: {
    height:100,
    justifyContent:'center'
  },
  view3: {
    marginVertical: 5
  },
  cardView: {
    // backgroundColor: '#ffffff',
    borderRadius: 10,
    padding: 10,
    paddingHorizontal:15,
    margin: 10,
    width:250
  },
  marginHorizontal0: {
    marginHorizontal: 0
  }
});
