import React from 'react';
import {Stack, Text} from 'native-base';
import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';
import {
  CONTACT_CARE_PROGRAM_STATUS_CODES,
  CONTACT_CARE_PROGRAM_STATUS_VALUES,
} from '../../../../../constants/MlovConst';

const CPStatusBadge = ({statusCode}: {statusCode?: string}) => {
  const containerStyle = () => {
    switch (statusCode) {
      case CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS:
        return styles.inProgressStatusCode;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED:
        return styles.completedStatus;
    }
    return styles.decliendStatus;
  };

  const textColor = () => {
    switch (statusCode) {
      case CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS:
        return Colors.Custom.crossIconColor;
      case CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED:
        return Colors.Custom.CompletedTaskFontColor;
    }
    return Colors.Custom.DeceasedTagColor;
  };

  if (!statusCode) {
    return null;
  }

  return (
    <Stack direction={'row'} style={[styles.commonStyles, containerStyle()]}>
      <Text wordBreak={'break-word'} color={textColor()} size={'smRegular'}>
        {CONTACT_CARE_PROGRAM_STATUS_VALUES[statusCode]}
      </Text>
    </Stack>
  );
};
const styles = StyleSheet.create({
  commonStyles: {
    paddingHorizontal: 4,
    paddingVertical: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  decliendStatus: {
    backgroundColor: Colors.Custom.Red100,
  },
  inProgressStatusCode: {
    backgroundColor: Colors.Custom.PendingTaskBackgroundColor,
  },
  completedStatus: {
    backgroundColor: Colors.Custom.CompletedTaskBackgroundColor,
  },
});
export default CPStatusBadge;
