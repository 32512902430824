import {STRIPE_INTERVAL_CODE} from '../../../../constants';
import {getCurrentSubdomain} from '../../../../screens/MainScreen/MainScreenHelper';
import {IStripePrice, IStripePriceTier} from './interface';

export const formatPriceList = (price: any): IStripePrice[] => {
  const priceList: IStripePrice[] = [];
  price?.map((item: any) => {
    const temp = {
      id: item.id,
      intervalCode: item?.recurring?.interval || STRIPE_INTERVAL_CODE.ONE_TIME,
      intervalCount: item?.recurring?.interval_count || 1,
      price: item?.unit_amount / 100,
      active: item.active,
      trialPeriodDays: item?.recurring?.trial_period_days,
      tiers: formatTiersList(item.tiers),
      hideTrialInput: false
    };
    priceList.push(temp);
  });
  return priceList;
};

export const formatTiersList = (tier: any): IStripePriceTier[] => {
  const tiersList: IStripePriceTier[] = [];
  let firstUnit = 1;
  let upTo = 0;
  tier?.map((item: any, index: number) => {
    if (index > 0) {
      firstUnit = upTo + 1;
    }
    upTo = item?.up_to;
    const temp = {
      key: index,
      firstUnit: firstUnit,
      type: item?.type,
      upto: item?.up_to ? item?.up_to : '-',
      flatAmountDecimal: (item.flat_amount_decimal / 100).toString(),
      unitAmountDecimal: (item.unit_amount_decimal / 100).toString(),
    };
    tiersList.push(temp);
  });
  return tiersList;
};

export const getMembershipLongLink = (data: {
  token: string;
  productId: string;
  plan: string;
}) => {
  const {plan, productId, token} = data;
  const origin = getCurrentSubdomain();
  const dotIndex = origin.indexOf('.');
  const subString = 'membership';
  const finalString =
    'https://' +
    origin.slice(0, dotIndex) +
    '.' +
    subString +
    origin.slice(dotIndex) +
    `/#/?token=${token}&productId=${productId}&plan=${plan}`;
  return finalString;
};
