import {Path, Svg, ClipPath, G, Defs, Rect} from 'react-native-svg';

const PauseTimelineIcon = (props: any) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <Path d="M0.25 12C0.25 9.16451 0.250531 7.06303 0.467441 5.44968C0.683383 3.84352 1.10965 2.75862 1.93414 1.93414C2.75862 1.10965 3.84352 0.683383 5.44968 0.467441C7.06303 0.250531 9.16451 0.25 12 0.25C14.8355 0.25 16.937 0.250531 18.5503 0.467441C20.1565 0.683383 21.2414 1.10965 22.0659 1.93414C22.8904 2.75862 23.3166 3.84352 23.5326 5.44968C23.7495 7.06303 23.75 9.16451 23.75 12C23.75 14.8355 23.7495 16.937 23.5326 18.5503C23.3166 20.1565 22.8904 21.2414 22.0659 22.0659C21.2414 22.8904 20.1565 23.3166 18.5503 23.5326C16.937 23.7495 14.8355 23.75 12 23.75C9.16451 23.75 7.06303 23.7495 5.44968 23.5326C3.84352 23.3166 2.75862 22.8904 1.93414 22.0659C1.10965 21.2414 0.683383 20.1565 0.467441 18.5503C0.250531 16.937 0.25 14.8355 0.25 12Z" fill="#F6F7F8"/>
    <Path d="M0.25 12C0.25 9.16451 0.250531 7.06303 0.467441 5.44968C0.683383 3.84352 1.10965 2.75862 1.93414 1.93414C2.75862 1.10965 3.84352 0.683383 5.44968 0.467441C7.06303 0.250531 9.16451 0.25 12 0.25C14.8355 0.25 16.937 0.250531 18.5503 0.467441C20.1565 0.683383 21.2414 1.10965 22.0659 1.93414C22.8904 2.75862 23.3166 3.84352 23.5326 5.44968C23.7495 7.06303 23.75 9.16451 23.75 12C23.75 14.8355 23.7495 16.937 23.5326 18.5503C23.3166 20.1565 22.8904 21.2414 22.0659 22.0659C21.2414 22.8904 20.1565 23.3166 18.5503 23.5326C16.937 23.7495 14.8355 23.75 12 23.75C9.16451 23.75 7.06303 23.7495 5.44968 23.5326C3.84352 23.3166 2.75862 22.8904 1.93414 22.0659C1.10965 21.2414 0.683383 20.1565 0.467441 18.5503C0.250531 16.937 0.25 14.8355 0.25 12Z" stroke="#8A94A8" stroke-width="0.5"/>
    <G clip-path="url(#clip0_478_45888)">
    <Path d="M5.33301 8.00016C5.33301 6.74308 5.33301 6.11454 5.72353 5.72402C6.11406 5.3335 6.7426 5.3335 7.99967 5.3335C9.25675 5.3335 9.88529 5.3335 10.2758 5.72402C10.6663 6.11454 10.6663 6.74308 10.6663 8.00016V16.0002C10.6663 17.2572 10.6663 17.8858 10.2758 18.2763C9.88529 18.6668 9.25675 18.6668 7.99967 18.6668C6.7426 18.6668 6.11406 18.6668 5.72353 18.2763C5.33301 17.8858 5.33301 17.2572 5.33301 16.0002V8.00016Z" stroke="#6F7A90"/>
    <Path d="M13.333 8.00016C13.333 6.74308 13.333 6.11454 13.7235 5.72402C14.1141 5.3335 14.7426 5.3335 15.9997 5.3335C17.2568 5.3335 17.8853 5.3335 18.2758 5.72402C18.6663 6.11454 18.6663 6.74308 18.6663 8.00016V16.0002C18.6663 17.2572 18.6663 17.8858 18.2758 18.2763C17.8853 18.6668 17.2568 18.6668 15.9997 18.6668C14.7426 18.6668 14.1141 18.6668 13.7235 18.2763C13.333 17.8858 13.333 17.2572 13.333 16.0002V8.00016Z" stroke="#6F7A90"/>
    </G>
    <Defs>
    <ClipPath id="clip0_478_45888">
    <Rect width="16" height="16" fill="white" transform="translate(4 4)"/>
    </ClipPath>
    </Defs>
    </Svg>

  );
};

export default PauseTimelineIcon;