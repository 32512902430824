import {useQuery} from '@apollo/client';
import {Center, Skeleton} from 'native-base';
import {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {GET_FORM_CATEGORIES} from '../../../../services/Forms/FormsQueries';
import {getAccountUUID, getBooleanFeatureFlag, getFormPrintURL, getUserUUID} from '../../../../utils/commonUtils';
import {ModuleCodes, useModuleSpecificUserLocations} from '../../../CustomHooks/useModuleSpecificUserLocations';
import {usePermissions} from '../../../CustomHooks/usePermissions';
import {MAIN_MENU_CODES} from '../../../SideMenuBar/SideBarConst';
import {USER_ACCESS_PERMISSION} from '../../UserAccess/UserAccessPermission';
import {IAddFormState, IForm} from '../interfaces';
import AddForm from './AddOrUpdateForm/AddForm';
import AddGlobalTheme from './AddOrUpdateForm/AddGlobalTheme';
import FormList from './FormList/FormList';

export enum FormScreenContext {
  main = 'MainScreen',
  addForm = 'AddFormScreen',
  updateForm = 'UpdateFormScreen',
  formResponses = 'FormResponses',
  pendingForms = 'PendingForms',
}

const FormBuilderWidget = (props: {
  isModalOpen: boolean;
  isAddExisting: boolean;
  setIsModalOpen: any;
  setIsAddExisting: any;
  searchString: string;
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalStatus] = useState<boolean>(false);
  const [isGlobalModalOpen, setGlobalModalStatus] = useState<boolean>(false);
  const [isAddExisting, setIsAddExisting] = useState<boolean>(false);
  const [addFormState, setFormState] = useState<IAddFormState>({
    formName: undefined,
    formCategoryId: undefined,
    formCategoryName: undefined,
    isValid: false,
    categoryList: [],
  });
  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code, MAIN_MENU_CODES.FORMS);
  const currentUserAllowedLocations = permissionConfig?.allowedLocationIds || [];
  const context = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(context?.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const accountId = getAccountUUID();
  const getFormCategories = useQuery(GET_FORM_CATEGORIES, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onError(error) {
      setFormState((prev) => {
        return {
          ...prev,
          categoryList: [],
        };
      });
    },
    onCompleted(data) {
      setFormState((prev) => {
        return {
          ...prev,
          categoryList: data.formCategories || [],
        };
      });
    },
  });

  const onPrint = (form: any) => {
    const printUrl = getFormPrintURL(form, accountId);
    window.open(printUrl, '_blank', 'noopener,noreferrer');
  };

  const navigateToScreen = (
    screen: string,
    form: IForm,
    isExisting: boolean,
    formState?: IAddFormState,
    isClone?: boolean
  ) => {
    if (screen === FormScreenContext.formResponses) {
      navigate('/admin/contentManagement/forms/responses', {
        state: {
          selectedForm: form,
          updateFormId: form.id,
        },
      });
    }
    else if (screen === FormScreenContext.pendingForms) {
      navigate('/admin/contentManagement/forms/pending', {
        state: {
          selectedForm: form,
          selectedFormId: form.id,
        },
      });
    }
     else {
      const url =
        screen === FormScreenContext.addForm
          ? '/admin/contentManagement/forms/create'
          : screen === FormScreenContext.updateForm
          ? '/admin/contentManagement/forms/update'
          : '';
      navigate(url, {
        state: {
          selectedForm: form,
          addFormDetail: formState,
          // isAddExisting: isExisting,
          isAddExisting: props.isAddExisting,
          isUpdate: screen === FormScreenContext.updateForm,
          isClone: isClone ? isClone : false,
        },
      });
    }
  };

  return (
    <>
      {
       (getFormCategories?.loading) &&
        <Center margin={4} flex={1}>
          <Skeleton.Text lines={5}/>
        </Center>
      }
      {!getFormCategories?.loading && (
        <FormList
          locationList={currentUserAllowedLocations}
          categoryList={addFormState.categoryList || []}
          searchString={props.searchString}
          addNewForm={() => {
            // setIsAddExisting(false);
            // setModalStatus(!isModalOpen);
            props.setIsAddExisting(false);
            props.setIsModalOpen(!props.isModalOpen);
          }}
          addGlobalTheme={() => {
            setGlobalModalStatus(!isGlobalModalOpen);
          }}
          onAddExistingForm={() => {
            // setIsAddExisting(true);
            // setModalStatus(!isModalOpen);
            props.setIsAddExisting(true);
            props.setIsModalOpen(!props.isModalOpen);
          }}
          onEditForm={(form: any) => {
            // setIsAddExisting(false);
            props.setIsAddExisting(false);
            navigateToScreen(
              FormScreenContext.updateForm,
              form,
              false,
              addFormState
            );
          }}
          onGraphClick={(form:any, screen?:string)=>{
            navigate('/admin/contentManagement/forms/analytics', {
              state: {
                selectedForm: form,
                selectedScreen:screen
              },
            });
          }}
          onFormResponse={(form: any) => {
            navigateToScreen(FormScreenContext.formResponses, form, false);
          }}
          onFormPending={(form: any) => {
            navigateToScreen(FormScreenContext.pendingForms, form, false);
          }}
          onPrintForm={(form: any) => {
            onPrint(form);
          }}
          onAddFormClone={(form: any) => {
            // setIsAddExisting(true);
            props.setIsAddExisting(true);
            const formState = {
              ...addFormState,
              // ALWAYS CARRY FWD ONLY ALLOWED LOCATION
              formLocations: isMultiTenancyEnabled ? form.formLocations.filter(
                (location: {locationId: string}) =>
                  currentUserAllowedLocations.includes(location?.locationId)
              ) : [],
              formName: '',
              formCategoryId: form.formCategoryId,
              formCategoryName: form.formCategoryName,
              isShownToPatient: form.isShownToPatient,
            };
            navigateToScreen(
              FormScreenContext.updateForm,
              form,
              true,
              formState,
              true
            );
          }}
        />
      )}
      {!getFormCategories?.loading && props.isModalOpen &&  (
        <AddForm
          isAddExisting={props.isAddExisting}
          categoryList={addFormState.categoryList || []}
          // isVisible={isModalOpen}
          isVisible={props.isModalOpen}
          onCancel={() => {
            {
              // setModalStatus(!isModalOpen);
              // setIsAddExisting(false);
              props.setIsModalOpen(!props.isModalOpen);
              props.setIsAddExisting(false);
            }
          }}
          onRefetch = {()=>{
            getFormCategories.refetch()
          }}
          onComplete={(formDetail: IAddFormState) => {
            {
              let selectedForm = {} as IForm;
              // if (formDetail.formValue && isAddExisting) {
              //   selectedForm = formDetail.formValue;
              // }
              if (formDetail.formValue && props.isAddExisting) {
                selectedForm = formDetail.formValue;
              }
              const formState = {
                ...addFormState,
                formName: formDetail.formName,
                formCategoryId: formDetail.formCategoryId,
                formCategoryName: formDetail.formCategoryName,
                customEhrCapabilities: formDetail.customEhrCapabilities,
                ehrAndLocationSelect: formDetail.ehrAndLocationSelect,
                formLocations: isMultiTenancyEnabled ? formDetail?.formValue?.formLocations.filter(
                  (location: {locationId: string}) =>
                    currentUserAllowedLocations.includes(location.locationId)
                ) : []
              };
              // setModalStatus(!isModalOpen);
              props.setIsModalOpen(!props.isModalOpen);
              // navigateToScreen(
              //   FormScreenContext.addForm,
              //   selectedForm,
              //   isAddExisting,
              //   formState
              // );
              navigateToScreen(
                FormScreenContext.addForm,
                selectedForm,
                props.isAddExisting,
                formState
              );
            }
          }}
        />
      )}
      {!getFormCategories?.loading && isGlobalModalOpen && (
        <AddGlobalTheme
          isVisible={isGlobalModalOpen}
          onCancel={() => {
            {
              setGlobalModalStatus(!isGlobalModalOpen);
            }
          }}
          onComplete={(submitData: any) => {
            setGlobalModalStatus(!isGlobalModalOpen);
          }}
        />
      )}
    </>
  );
};

export default FormBuilderWidget;
