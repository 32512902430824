import {AxiosResponse} from 'axios';
import {Center, Skeleton, Spinner, View} from 'native-base';
import {useEffect, useState} from 'react';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {getProductVariantRelationDataInMainObj} from '../../../../../../services/ProductAndServices/DataFormatter/ProductVarient';
import {
  IProductVariantRelationshipCommonData,
  IProductVariantResp,
} from '../../../../../../services/ProductAndServices/interfaces/ProductVariant';
import {IStockItemDataApiResp} from '../../../../../../services/ProductAndServices/interfaces/StockItem';
import {IStockLocationData} from '../../../../../../services/ProductAndServices/interfaces/StockLocation';
import {
  addOrUpdateStock,
  deleteStockItems,
  getVariantList,
} from '../../../../../../services/ProductAndServices/ProductAndServicesAPI';
import {Colors} from '../../../../../../styles';
import LocalStorage from '../../../../../../utils/LocalStorage';
import {NoDataFound} from '../../../../../common/NoDataFound';
import {AddStockToLocation} from './AddStockToLocation';
import {IStockProps, IStockState} from './interfaces';
import {StockInfo} from './StockInfo/StockInfo';

export const Stock = (props: IStockProps) => {
  const {productData, parentHeight} = props;
  const [stockState, setStockState] = useState<IStockState>({
    isLoading: true,
    isDataLoadingAfterAction: false,
    isNewProduct: false,
    productVariantList: [],
    stockLocationList: [],
  });

  const onStockAddToLocation = (actionCode: string) => {
    if (actionCode) {
      setStockState((prev) => {
        return {
          ...prev,
          isDataLoadingAfterAction: true,
        };
      });
      fetchVariantList(stockState.stockLocationList);
    }
  };

  const updateStockToLocation = async (actionData: any) => {
    const singleStockItem: IProductVariantRelationshipCommonData =
      actionData.singleStockItem;
    const backOrderable = actionData.backOrderable;
    if (singleStockItem.id) {
      const variantResp: AxiosResponse<IStockItemDataApiResp> =
        await addOrUpdateStock(
          {
            backorderable: backOrderable,
            variant_id: singleStockItem.relationships?.variant?.data?.id || '',
          },
          singleStockItem.id
        );
      fetchVariantList(stockState.stockLocationList);
      if (variantResp && variantResp.data) {
      }
    }
  };

  const deleteStockItem = async (
    singleStockItem: IProductVariantRelationshipCommonData
  ) => {
    if (singleStockItem.id) {
      const variantResp: AxiosResponse<IStockItemDataApiResp> =
        await deleteStockItems(singleStockItem.id);
      if (variantResp && variantResp.data) {
      }
      fetchVariantList(stockState.stockLocationList);
    }
  };

  const onStockInfoActionPerformed = (actionCode: string, actionData: any) => {
    setStockState((prev) => {
      return {
        ...prev,
        isDataLoadingAfterAction: true,
      };
    });
    switch (actionCode) {
      case COMMON_ACTION_CODES.DELETE:
        deleteStockItem(actionData);
        break;
      case COMMON_ACTION_CODES.STATUS_CHANGED:
        updateStockToLocation(actionData);
        break;
    }
  };
  const fetchVariantList = async (stockLocationList: IStockLocationData[]) => {
    const variantResp: AxiosResponse<IProductVariantResp> =
      await getVariantList({
        'filter[product_id_eq]': productData.data.id,
      });

    if (variantResp?.data && variantResp?.data?.data?.length) {
      const variantApiData = variantResp.data;
      const finalVariantDataList = getProductVariantRelationDataInMainObj(
        variantApiData.data,
        variantApiData.included
      );
      // finalVariantDataList = finalVariantDataList.filter((singleItem) => {
      //   return !singleItem.attributes.is_master;
      // });
      setStockState((prev) => {
        return {
          ...prev,
          productVariantList: finalVariantDataList,
          stockLocationList: stockLocationList,
          isLoading: false,
          isDataLoadingAfterAction: false,
        };
      });
    }
  };
  const getStockLocationList = async () => {
    const stockLocationStr = await LocalStorage.getItem('stockLocationList');
    const stockLocationList = JSON.parse(stockLocationStr);
    fetchVariantList(stockLocationList);
  };
  useEffect(() => {
    if (productData?.data?.id) {
      getStockLocationList();
    } else {
      setStockState((prev) => {
        return {
          ...prev,
          isLoading: false,
          isNewProduct: true,
        };
      });
    }
  }, [productData?.data?.id]);
  return (
    <>
      {stockState.isLoading ? (
        <View padding={4}>
          <Skeleton.Text lines={4} />
        </View>
      ) : (
        <>
          {stockState.isDataLoadingAfterAction ? (
            <View
              height={'full'}
              width={'full'}
              position="absolute"
              zIndex={10}
              bg={'coolGray.50:alpha.70'}
            >
              <Center
                top={'50%'}
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                <Spinner size="lg" />
              </Center>
            </View>
          ) : (
            <></>
          )}
          {stockState.isNewProduct ? (
            <View>
              <View height={parentHeight / 2}>
                <Center
                  top={'30%'}
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <NoDataFound
                    isShowIcon={true}
                    iconSize={24}
                    displayString="To add stock please create product."
                  />
                </Center>
              </View>
            </View>
          ) : (
            <View style={{paddingHorizontal:16}}>
              <View
                style={{
                  backgroundColor: '#fff',
                  borderWidth: 1,
                  borderColor: Colors.Custom.Gray200,
                  borderRadius: 8,
                }}
              >
                <AddStockToLocation
                  productData={productData}
                  stockLocationList={stockState?.stockLocationList || []}
                  productVariantList={stockState?.productVariantList || []}
                  onStockAddToLocation={onStockAddToLocation}
                />
                <StockInfo
                  productData={productData}
                  stockLocationList={stockState?.stockLocationList || []}
                  productVariantList={stockState.productVariantList}
                  onStockInfoActionPerformed={(
                    actionCode: string,
                    actionData: any
                  ) => {
                    onStockInfoActionPerformed(actionCode, actionData);
                  }}
                />
              </View>
            </View>
          )}
        </>
      )}
    </>
  );
};
