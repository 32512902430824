import {Dropdown, Menu, Tabs} from 'antd';
import {
  Badge,
  Box,
  Button,
  Divider,
  HStack,
  IconButton,
  Input,
  Spacer,
  Tooltip,
  VStack,
  Text,
  View,
  Pressable,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../../styles';
import {MENU_KEYS} from '../../PatientNotes/components/CustomSendOrderButton';
import {OrderTimeLineTabCode} from '../OrderTimelineConstants';
import {getOrderTimeLineTabs} from '../utils';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {FILTER_MENU_KEYS} from '../interfaces';
import { isOrderTypeEnabled } from '../../../../../utils/capabilityUtils';
import { OrderType } from '../../Orders/OrdersAndReports/OrderConstants';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import Feather from 'react-native-vector-icons/Feather';
import { IFilterTag, ResponsiveTagRender } from '../../../../common/ResponsiveTagRender/ResponsiveTagRender';
import { getDateStrFromFormat } from '../../../../../utils/DateUtils';
import { DISPLAY_DATE_FORMAT } from '../../../../../constants';
import ClearActionIcon from '../../../../common/Svg/ClearActionIcon';
import { IOrderTimelineSelectedFilters, OrderFilterCode, OrderTimelineFilter } from '../OrderTimelineFilter';
import { StyleSheet } from 'react-native';

const Header = (props: {
  activeTab: string;
  searchQuery: string;
  activeFilter: IOrderTimelineSelectedFilters | undefined;
  onNewOrder: (actionCode: any, isExternalOrder: boolean) => void;
  onFilterChange: (filters: IOrderTimelineSelectedFilters | undefined) => void;
  onTabChange: (tab: string) => void;
  onSearchQueryChange: (text: string) => void;
  locationId: string | undefined;
}) => {
  const {activeTab, onTabChange, activeFilter, locationId} = props;
  const intl = useIntl();
  const isLabEnabled = isOrderTypeEnabled(OrderType.LAB, '', locationId);
  const isRadEnabled = isOrderTypeEnabled(OrderType.RAD, '', locationId);
  const isMedEnabled = isOrderTypeEnabled(OrderType.MED, '', locationId);
  const [componentState, setComponentState] = useState({
    isFilterModalOpen: false,
    selectedFilters: activeFilter || {} as IOrderTimelineSelectedFilters | undefined,
  });
  const filterTagCodes = {
    CREATED_BY_USER_IDS: 'CREATED_BY_USER_IDS',
    SIGNED_BY_USER_IDS: 'SIGNED_BY_USER_IDS',
    CREATED_ON_DATE: 'CREATED_ON_DATE',
    SIGNED_ON_DATE: 'SIGNED_ON_DATE',
    CHIEF_COMPLAINT: 'CHIEF_COMPLAINT',
    DIAGNOSIS: 'DIAGNOSIS',
    FORM: 'FORM',
  };

  const onNewOrderActionHandler = (key: string) => {
    let actionCode: any = '';
    let isExternalOrder = false;
    switch (key) {
      case MENU_KEYS.labOrder:
      case MENU_KEYS.externalLabOrder:
        actionCode = OrderTimeLineTabCode.LAB;
        isExternalOrder = key === MENU_KEYS.externalLabOrder;
        break;

      case MENU_KEYS.medOrder:
        actionCode = OrderTimeLineTabCode.ERX;
        break;

      case MENU_KEYS.radOrder:
      case MENU_KEYS.externalRadOrder:
        actionCode = OrderTimeLineTabCode.IMAGING;
        isExternalOrder = key === MENU_KEYS.externalRadOrder;
        break;
    }

    if (props?.onNewOrder && typeof props.onNewOrder === 'function') {
      props?.onNewOrder(actionCode, isExternalOrder);
    }
  };
  const actionMenu = () => {
    const menuItems: ItemType[] = [];
    if (isMedEnabled && activeTab === OrderTimeLineTabCode.ALL) {
      menuItems.push({
        key: MENU_KEYS.medOrder,
        label: intl.formatMessage({id: 'eRx'}),
      });
    }
    if (isLabEnabled && (activeTab === OrderTimeLineTabCode.ALL || activeTab === OrderTimeLineTabCode.LAB)) {
      menuItems.push({
        key: MENU_KEYS.labOrder,
        label: intl.formatMessage({id: 'labOrder'}),
      });
      menuItems.push({
        key: MENU_KEYS.externalLabOrder,
        label: intl.formatMessage({id: 'externalLabOrder'}),
      });
    }
    if (isRadEnabled && (activeTab === OrderTimeLineTabCode.ALL || activeTab === OrderTimeLineTabCode.IMAGING)) {
      menuItems.push({
        key: MENU_KEYS.radOrder,
        label: intl.formatMessage({id: 'radOrder'}),
      });
      menuItems.push({
        key: MENU_KEYS.externalRadOrder,
        label: intl.formatMessage({id: 'externalRadOrder'}),
      });
    }
    return (
      <Menu
        className="custom-menu"
        items={menuItems}
        onClick={(data: any) => {
          onNewOrderActionHandler(data?.key);
        }}
      />
    );
  };
  // const onFilterActionHandler = (key: string) => {
  //   let actionCode: any = '';
  //   switch (key) {
  //     case FILTER_MENU_KEYS.ALL:
  //       actionCode = FILTER_MENU_KEYS.ALL;
  //       break;
  //     case FILTER_MENU_KEYS.COMPLETED:
  //       actionCode = FILTER_MENU_KEYS.COMPLETED;
  //       break;
  //     case FILTER_MENU_KEYS.IN_PROGRESS:
  //       actionCode = FILTER_MENU_KEYS.IN_PROGRESS;
  //       break;
  //     case FILTER_MENU_KEYS.FAILED:
  //       actionCode = FILTER_MENU_KEYS.FAILED;
  //       break;
  //   }
  //   if (props?.onFilterChange && typeof props.onFilterChange === 'function') {
  //     props?.onFilterChange(actionCode);
  //   }
  // };
  // const getFilterValueFromCode = (code: string) => {
  //   switch (code) {
  //     case FILTER_MENU_KEYS.COMPLETED:
  //       return intl.formatMessage({id: 'completed'});
  //     case FILTER_MENU_KEYS.IN_PROGRESS:
  //       return intl.formatMessage({id: 'inProgress'});
  //     case FILTER_MENU_KEYS.FAILED:
  //       return intl.formatMessage({id: 'failed'});
  //     case FILTER_MENU_KEYS.ALL:
  //       return intl.formatMessage({id: 'all'});
  //     default:
  //       return '';
  //   }
  // };
  // const filterMenu = () => {
  //   return (
  //     <Menu
  //       selectedKeys={[props.activeFilter]}
  //       className="custom-menu"
  //       items={[
  //         {
  //           key: FILTER_MENU_KEYS.ALL,
  //           label: getFilterValueFromCode(FILTER_MENU_KEYS.ALL),
  //         },
  //         {
  //           key: FILTER_MENU_KEYS.COMPLETED,
  //           label: getFilterValueFromCode(FILTER_MENU_KEYS.COMPLETED),
  //         },
  //         {
  //           key: FILTER_MENU_KEYS.IN_PROGRESS,
  //           label: getFilterValueFromCode(FILTER_MENU_KEYS.IN_PROGRESS),
  //         },
  //         {
  //           key: FILTER_MENU_KEYS.FAILED,
  //           label: getFilterValueFromCode(FILTER_MENU_KEYS.FAILED),
  //         },
  //       ]}
  //       onClick={(data: any) => {
  //         onFilterActionHandler(data?.key);
  //       }}
  //     />
  //   );
  // };

  const getToolTipName = () => {
    switch (activeTab) {
      case OrderTimeLineTabCode.ERX:
        return 'Add eRx';
      case OrderTimeLineTabCode.LAB:
        return 'Add Lab';
      case OrderTimeLineTabCode.IMAGING:
        return 'Add Imaging';
      default:
        return '';
    }
  };

  const getPlaceHolder = () => {
    switch (activeTab) {
      case OrderTimeLineTabCode.ERX:
        return 'eRx';
      case OrderTimeLineTabCode.LAB:
        return 'Lab';
      case OrderTimeLineTabCode.IMAGING:
        return 'Imaging';
      default:
        return 'By Name';
    }
  };

  function getFilterCount() {
    let filterCount = 0;
    const filters = componentState.selectedFilters;
    if (!filters) {
      return filterCount;
    }

    // if (filters.chiefComplaint?.displayName) {
    //   filterCount += 1;
    // }
    // if (filters.diagnosis?.text) {
    //   filterCount += 1;
    // }
    if (filters.form?.id) {
      filterCount += 1;
    }
    if (filters.createdByUserIds?.length) {
      filterCount += 1;
    }
    // if (filters.signedByUserIds?.length) {
    //   filterCount += 1;
    // }
    if (filters.createdOnStartDate) {
      filterCount += 1;
    }
    if (filters.createdOnEndDate) {
      filterCount += 1;
    }
    // if (filters.signedOnStartDate) {
    //   filterCount += 1;
    // }
    // if (filters.signedOnEndDate) {
    //   filterCount += 1;
    // }
    if (filters.filterCodes?.length) {
      filterCount += filters.filterCodes.length;
    }

    return filterCount;
  }

  function getFilterTags() {
    const filterTags: Array<IFilterTag> = [];

    const filters = componentState.selectedFilters;
    if (!filters) {
      return filterTags;
    }

    if (filters.createdByUsers?.length) {
      filterTags.push({
        code: filterTagCodes.CREATED_BY_USER_IDS,
        displayValue: `Created by ${filters.createdByUsers?.map((item: any) => item.value).join(', ')}`,
      });
    }
    // if (filters.signedByUsers?.length) {
    //   filterTags.push({
    //     code: filterTagCodes.SIGNED_BY_USER_IDS,
    //     displayValue: `Signed by ${filters.signedByUsers?.map((item: any) => item.value).join(', ')}`,
    //   });
    // }
    if (filters.createdOnStartDate || filters.createdOnEndDate) {
      let displayValue = 'Created';
      if (filters.createdOnStartDate) {
        displayValue += ` from ${getDateStrFromFormat(filters.createdOnStartDate, DISPLAY_DATE_FORMAT)}`;
      }
      if (filters.createdOnEndDate) {
        displayValue += ` to ${getDateStrFromFormat(filters.createdOnEndDate, DISPLAY_DATE_FORMAT)}`;
      }

      filterTags.push({
        code: filterTagCodes.CREATED_ON_DATE,
        displayValue: displayValue,
      });
    }
    // if (filters.signedOnStartDate || filters.signedOnEndDate) {
    //   let displayValue = 'Signed';
    //   if (filters.signedOnStartDate) {
    //     displayValue += ` from ${getDateStrFromFormat(filters.signedOnStartDate, DISPLAY_DATE_FORMAT)}`;
    //   }
    //   if (filters.signedOnEndDate) {
    //     displayValue += ` to ${getDateStrFromFormat(filters.signedOnEndDate, DISPLAY_DATE_FORMAT)}`;
    //   }

    //   filterTags.push({
    //     code: filterTagCodes.SIGNED_ON_DATE,
    //     displayValue: displayValue,
    //   });
    // }
    // if (filters.diagnosis?.text) {
    //   filterTags.push({
    //     code: filterTagCodes.DIAGNOSIS,
    //     displayValue: `Diagnosis: ${filters.diagnosis.text}`,
    //   });
    // }
    // if (filters.chiefComplaint?.displayName) {
    //   filterTags.push({
    //     code: filterTagCodes.CHIEF_COMPLAINT,
    //     displayValue: `Cheif complaint: ${filters.chiefComplaint.displayName}`,
    //   });
    // }
    if (filters.form?.name) {
      filterTags.push({
        code: filterTagCodes.FORM,
        displayValue: `Template: ${filters.form.name}`,
      });
    }

    if (filters.filterCodes?.length) {
      filters.filterCodes.forEach((filterKey) => {
        filterTags.push({
          code: filterKey,
          displayValue: `${filterKey}`,
        });
      })
    }

    return filterTags;
  }

  function onRemoveFilter(code: string) {
    switch (code) {
      case filterTagCodes.CREATED_BY_USER_IDS:
        setComponentState((prev => {
          return {
            ...prev,
            selectedFilters: {
              ...prev.selectedFilters,
              createdByUserIds: undefined,
              createdByUsers: undefined,
            }
          };
        }));
        break;
      case filterTagCodes.SIGNED_BY_USER_IDS:
        setComponentState((prev => {
          return {
            ...prev,
            selectedFilters: {
              ...prev.selectedFilters,
              signedByUserIds: undefined,
              signedByUsers: undefined,
            }
          };
        }));
        break;
      case filterTagCodes.CREATED_ON_DATE:
        setComponentState((prev => {
          return {
            ...prev,
            selectedFilters: {
              ...prev.selectedFilters,
              createdOnStartDate: undefined,
              createdOnEndDate: undefined,
            }
          };
        }));
        break;
      case filterTagCodes.SIGNED_ON_DATE:
        setComponentState((prev => {
          return {
            ...prev,
            selectedFilters: {
              ...prev.selectedFilters,
              signedOnStartDate: undefined,
              signedOnEndDate: undefined,
            }
          };
        }));
        break;
      case filterTagCodes.CHIEF_COMPLAINT:
        setComponentState((prev => {
          return {
            ...prev,
            selectedFilters: {
              ...prev.selectedFilters,
              chiefComplaint: undefined,
            }
          };
        }));
        break;
      case filterTagCodes.DIAGNOSIS:
        setComponentState((prev => {
          return {
            ...prev,
            selectedFilters: {
              ...prev.selectedFilters,
              diagnosis: undefined,
            }
          };
        }));
        break;
      case filterTagCodes.FORM:
        setComponentState((prev => {
          return {
            ...prev,
            selectedFilters: {
              ...prev.selectedFilters,
              form: undefined,
            }
          };
        }));
        break;

      default:
        setComponentState((prev => {
          const filterCodes = prev.selectedFilters?.filterCodes || [];
          const index = filterCodes.indexOf(code as OrderFilterCode);
          if (index !== -1) filterCodes.splice(index, 1);
          return {
            ...prev,
            selectedFilters: {
              ...prev.selectedFilters,
              filterCodes: filterCodes,
            }
          };
        }));
      break;
    }
  }

  useEffect(() => {
    if (props?.onFilterChange && typeof props.onFilterChange === 'function') {
      props?.onFilterChange(componentState.selectedFilters);
    }
  }, [componentState.selectedFilters]);

  const filterCount = getFilterCount();
  const filterTags = (filterCount > 0) ? getFilterTags() : [];

  return (
    <>
    <VStack
      w="100%"
      pl={5}
      pr={3}
      paddingY={2}
      alignItems="center"
      backgroundColor={Colors.Custom.Gray100}
      style={styles.headerContainer2}
    >
      <HStack justifyContent="space-between" w="100%" alignItems={'center'}>
        <div className="custom-tab-style">
          <Tabs
            onChange={(tab) => onTabChange(tab)}
            style={antdStyles.tabs}
            items={getOrderTimeLineTabs({
              isLabEnabled, isRadEnabled, isMedEnabled
            })}
            defaultActiveKey={activeTab || OrderTimeLineTabCode['ALL']}
          />
        </div>
        <HStack alignItems="center">
          <Input
            mx="2"
            placeholder={`Search ${getPlaceHolder()}`}
            w="100%"
            background="white"
            borderRadius={8}
            onChangeText={(text) => props.onSearchQueryChange(text)}
          />
          <HStack
            paddingY={2}
            borderRadius={'6px'}
            alignItems={'center'}
          >
            <Tooltip label="Filter Orders">
              <IconButton
                icon={
                  <Feather
                    color={Colors.Custom.Gray500}
                    name={'filter'}
                    size={18}
                  />
                }
                _hover={{background: 'none'}}
                onPress={() => {
                  setComponentState((prev) => ({
                    ...prev,
                    isFilterModalOpen: true,
                  }));
                }}
              />
            </Tooltip>
            {
              filterCount > 0 &&
              <Badge
                rounded={'full'}
                variant="solid"
                backgroundColor={Colors.error[500]}
                size={'smMedium'}
                style={styles.filterBadge}
                _text={styles.filterBadgeText}
              >
                {filterCount}
              </Badge>
            }
          </HStack>
          <View
            bg={Colors.Custom.Gray400}
            marginRight={2}
            marginLeft={filterCount > 0 ? 4 : 2}
            width={0.2}
            height={6}
          />
          {/* <Dropdown
            overlay={filterMenu}
            placement="bottomRight"
            overlayStyle={{width: 120}}
          >
            <Box>
               {props.activeFilter !== FILTER_MENU_KEYS.ALL ? (
              <Badge
                rounded={'full'}
                zIndex={100}
                mt={-2}
                mr={-2}
                position={'absolute'}
                variant="solid"
                backgroundColor={Colors.error[500]}
                alignSelf="flex-end"
                size={'smMedium'}
                style={ {
                  padding: 0,
                }}
                _text={{
                  fontSize: 9,
                  fontWeight: 'bold',
                }}
              >
                1
              </Badge>
            ) : null}
            <Button
              style={ {height: 36, width: 36}}
              backgroundColor={Colors.secondary['100']}
              rounded="full"
              borderWidth={1}
              borderColor={Colors.secondary['200']}
            >
              <FeatherIcon
                name="filter"
                size={16}
                color={Colors.secondary['800']}
              />
            </Button>
            </Box>

          </Dropdown> */}

          {/* <Spacer w={2} /> */}
          {(
            activeTab === OrderTimeLineTabCode.ALL ||
            activeTab === OrderTimeLineTabCode.LAB ||
            activeTab === OrderTimeLineTabCode.IMAGING
            ) ? (
            <>
              <Dropdown
                overlay={actionMenu}
                placement="bottomRight"
                overlayStyle={{width: 160}}
              >
                <IconButton
                  icon={
                    <FeatherIcon
                      name="plus"
                      size={20}
                      color={Colors.Custom.Gray500}
                    />
                  }
                  _hover={{background: 'none'}}
                />
                {/* <Button
                  style={ {width: 36, height: 36}}
                  backgroundColor={Colors.secondary['100']}
                  rounded="full"
                  borderWidth={1}
                  borderColor={Colors.secondary['200']}
                >
                  <FeatherIcon
                    name="plus"
                    size={18}
                    color={Colors.secondary['800']}
                  />
                </Button> */}
              </Dropdown>
            </>
          ) : (
            <Tooltip placement="bottom" label={getToolTipName()}>
              {/* <Button
                style={ {width: 36, height: 36}}
                backgroundColor={Colors.secondary['100']}
                rounded="full"
                borderWidth={1}
                borderColor={Colors.secondary['200']}
                onPress={() => props.onNewOrder(activeTab)}
              >
                <FeatherIcon
                  name="plus"
                  size={18}
                  color={Colors.secondary['800']}
                />
              </Button> */}
              <IconButton
                icon={
                  <FeatherIcon
                    name="plus"
                    size={20}
                    color={Colors.Custom.Gray500}
                  />
                }
                _hover={{background: 'none'}}
                onPress={() => props.onNewOrder(activeTab, false)}
              />
            </Tooltip>
          )}
        </HStack>
      </HStack>
    </VStack>
    {
      filterCount > 0 && filterTags.length > 0 &&
      <HStack
        alignItems={'center'}
        width={'full'}
        // backgroundColor={Colors.Custom.Gray100}
      >
        <View alignItems={'stretch'} flex={7}>
          <ResponsiveTagRender
            filterTags={filterTags}
            onRemoveFilter={onRemoveFilter}
            onClick={() => {
              setComponentState(prev => ({ ...prev, isFilterModalOpen: true }));
            }}
          />
        </View>
        <View
          width={'100px'}
          height={'full'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Pressable
            onPress={() => {
              setComponentState(prev => ({ ...prev, selectedFilters: undefined }));
            }}
            style={styles.clearAllButton}
          >
            <ClearActionIcon />
            <Text style={styles.clearAllText}>Clear All</Text>
          </Pressable>
        </View>
      </HStack>

    }
    {
      filterCount > 0 && filterTags.length > 0 &&
      <Divider thickness={1}/>
    }
    {
      componentState.isFilterModalOpen &&
      <OrderTimelineFilter
        isVisible={true}
        selectedFilters={componentState.selectedFilters}
        onClose={() => {
          setComponentState(prev => ({ ...prev, isFilterModalOpen: false }));
        }}
        onApply={(data) => {
          setComponentState(prev => ({ ...prev, selectedFilters: data, isFilterModalOpen: false }));
        }}
        locationId={locationId}
      />
    }
    </>
  );
};

export default Header;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingLeft: 5,
    paddingRight: 3,
    paddingVertical: 2,
    alignItems: 'center',
    backgroundColor: Colors.Custom.Gray100,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  headerContainer: {
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  headerContainer2: {
    borderTopLeftRadius: 8, 
    borderTopRightRadius: 8
  },
  tabContainer: {
    width: '100%',
  },
  filterIconContainer: {
    paddingVertical: 2,
    borderRadius: 6,
    alignItems: 'center',
  },
  filterBadge: {
    zIndex: 100,
    position: 'absolute',
    marginBottom: 5,
    marginLeft: 5,
    alignSelf: 'flex-end',
    padding: 0,
  },
  filterBadgeText: {
    fontSize: 9,
    fontWeight: 'bold',
  },
  filterTagsContainer: {
    alignItems: 'center',
    width: '100%',
  },
  filterTagsContent: {
    alignItems: 'stretch',
    flex: 7,
  },
  clearAllContainer: {
    width: '100px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clearAllButton: {
    marginRight: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  clearAllText: {
    color: Colors.Custom.mainPrimaryPurple,
    marginLeft: 6,
  },
});

const antdStyles: Record<string, React.CSSProperties> = {
  tabs: {
    width: '100%',
  },
};