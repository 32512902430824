import { useLazyQuery } from '@apollo/client';
import { HStack, Pressable, Spinner, Text, View, useToast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import { LeadQueries } from '../../../services';
import BaseService from '../../../services/CommonService/BaseService';
import { getAccountId, getAccountUUID } from '../../../utils/commonUtils';
import { getPatientSearchORCondition } from '../../RightSideContainer/Contacts/ContactsUtils';
import CreateSmsConversationDrawer from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import { getShortLink } from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import { SMS_INBOX_ACTION_CODES } from '../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import EmailDrawerCommon from '../EmailPopupView/EmailDrawerCommon';
import LoadingSpinner from '../Loader/LoadingSpinner';
import MemberInfoListItem from '../MemberInfoListItem/MemberInfoListItem';
import { SearchBar } from '../SearchBar';
import { filterExistingContacts } from './ContactRelationUtils';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../styles/Colors';
import { debounce } from 'lodash';
import {ToastType, showToast} from '../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import {styles} from '../AddContactRelationView/AddContactRelationViewStyles';
import {EmailDrawerCommonV2} from '../EmailDrawerCommonV2';

const ContactAutoCompleteCustom = (props: any) => {
  const [contacts, setContacts] = useState<any>([]);
  const [isShowList, setIsShowList] = useState<boolean>(false);
  const [contact, setContact] = useState<any>();
  const [drawer, setDrawer] = useState<any>();
  const [content, setContent] = useState<any>();
  const [loading, setLoading] = useState({
    shortLinkLoading: false as boolean
  })
  const [membershipData, setMembershipData] = useState({
    plan: '',
    productId: '',
  });
  const toast = useToast();
  const intl = useIntl();

  const [getContacts] = useLazyQuery<{ contacts: any[] }, any>(
    props?.contactId ? LeadQueries.SearchContacts : LeadQueries.SearchContacts3,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [loadingStates, setLoadingStates] = useState(false);

  const searchContacts = (searchString: string) => {
    setContacts([]);
    if (searchString?.length > 1) {
      setLoadingStates(true);
      let searchStringArray = searchString?.split(' ');
      searchStringArray = searchStringArray?.filter((char: any) => {
        return char.length >= 1;
      });
      const where = {
        id: { _neq: props.contactId },
        _or: getPatientSearchORCondition(searchString, searchStringArray),
      };
      getContacts({
        variables: {
          searchString: '%' + searchString + '%',
          limit: 50,
          contactId: props.contactId,
          where: where,
        },
      })
        .then((contactRespData) => {
          const contactFilterData = filterExistingContacts(
            contactRespData?.data?.contacts || [],
            props.contactId
          );
          setContacts(contactFilterData || []);
          setLoadingStates(false);
        })
        .catch(() => {
          setContacts([]);
          setLoadingStates(false);
        });
    }
  };

  const accountId = getAccountId();
  const accountUuid = getAccountUUID();

  const setShortLinkLoading = (loading: boolean)=> {
    setLoading((prev)=> {
      return {
        ...prev,
        shortLinkLoading: loading
      }
    })
  }

  const renderInvitedStatus = (patientId: string,invited: boolean) => {
    // if (props?.inviteUserIdLoading === patientId) {
    //   return <Spinner size="sm" />;
    // }

    return (
      <HStack alignItems={'center'}>
        <Feather
          name={invited ? 'check-circle' : 'mail'}
          size={18}
          color={!invited ? Colors.Custom.PurpleColor : Colors.Custom.Gray400}
          style={{marginRight: 8, paddingTop: 4}}
        />
        <Text
          color={invited ? Colors.Custom.Gray400 : Colors.Custom.PurpleColor}
        >
          {invited ? 'Invited' : 'Invite'}
        </Text>
      </HStack>
    );
  }

  const renderMemberSideInfo = (contactObj: any) => {
    if (props?.showInvitedString) {
      const invited = props?.externalContactList?.includes(contactObj?.uuid);
      return (
        <Pressable
          style={{marginLeft: 2}}
          disabled={!!props?.inviteUserIdLoading}
          onPress={() => {
            if (!invited) {
              props?.onInviteUser(contactObj);
            }
          }}
          flexDirection={'row'}
          alignItems={'center'}
        >
          {renderInvitedStatus(contactObj?.uuid, invited)}
        </Pressable>
      );
    }
    return null;
  }
  return (
    <View flex={1}>
      <View width={'100%'}>
        <SearchBar
          onChange={debounce((value: string) => {
            searchContacts(value);
            props.onValueChange(value);
            setIsShowList(true);
          }, 500)}
          searchText={props.value}
          placeholderText={props.placeholder || 'Search By Contact'}
          borderRadius={props.borderRadius}
          searchBarInputStyles={props.searchBarInputStyles}
          height={100}
        />
      </View>
      {drawer == 'SMS' ? (
        <CreateSmsConversationDrawer
          isDrawerVisible={true}
          selectedInbox={{} as any}
          onCreateSmsConversationActionPerformed={(
            actionCode: string,
            actionData: any
          ) => {
            if (actionCode == SMS_INBOX_ACTION_CODES.DRAWER_CLOSE) {
              setDrawer(undefined);
              setContact(undefined);
              setContent(undefined);
            }
          }}
          selectedPatient={contact}
          content={content}
        />
      ) : null}
      {drawer == 'MAIL' ? (
        <EmailDrawerCommonV2
          contactIds={[contact.id]}
          isOpen={drawer == 'MAIL'}
          onClose={() => {
            setDrawer(undefined);
            setContact(undefined);
          }}
          onEmailSent={() => {
            setDrawer(undefined);
            setContact(undefined);
          }}
          membershipData={membershipData}
        />
      ) : null}
      {isShowList && (loadingStates || contacts?.length > 0) ? (
        <View flex={1}>
          {loadingStates ? (
            <LoadingSpinner />
          ) : (
            <View
              style={{
                overflow: 'scroll',
                maxHeight: '75vh',
              }}
              position={'relative'}
              opacity={props?.inviteUserIdLoading ? 0.5 : 1}
            >
              {contacts?.length > 0 ? (
                contacts?.map((contactObj: any) => (
                  <HStack
                    justifyContent={'space-between'}
                    style={{
                      paddingTop: 15,
                    }}
                  >
                    <MemberInfoListItem
                      contactData={contactObj}
                      isPatientSearchCard
                      showDateOfBirth={true}
                      showEmail={props?.showEmail}
                      showPhoneNumber={props?.showPhoneNumber}
                      showEmailActionIcon={!props?.hideEmailActionIcon}
                      showSmsActionIcon={!props?.hideSmsActionIcon}
                      onActionClick={(type: string, data: any) => {
                        if (props.getMembershipLink) {
                          setMembershipData((prev) => {
                            return {
                              ...prev,
                              plan: props.productInfo?.type,
                              productId: props.productInfo?.id,
                            };
                          });
                          setDrawer(type);
                          setContact(data);
                          return;
                          setShortLinkLoading(true);
                          const baseService =
                            BaseService.getSharedInstance().axios;
                          const url = `/crm-nest/membership/api/token`;
                          baseService
                            .post(url, {
                              accountId,
                              accountUuid,
                              contactUuid: data.uuid,
                            })
                            .then(async (resp) => {
                              const origin = window.location.origin;
                              const dotIndex = origin.indexOf('.');
                              const subString = 'membership';
                              const finalString =
                                origin.slice(0, dotIndex) +
                                '.' +
                                subString +
                                origin.slice(dotIndex) +
                                `/#/?token=${resp.data}&productId=${props.productInfo?.id}&plan=${props.productInfo?.type}`;
                              const shortLink = await getShortLink(finalString);
                              setShortLinkLoading(false);
                              setContent(shortLink);
                              setDrawer(type);
                              setContact(data);
                            })
                            .catch((error)=> {
                              setShortLinkLoading(false)
                              showToast(toast, intl.formatMessage({ id: 'apiErrorMsg' }), ToastType.error, 2000);
                            })
                        }
                      }}
                    />
                    {renderMemberSideInfo(contactObj)}
                  </HStack>
                ))
              ) : (
                <View key={'no'}>
                  <Text>No Contact Found</Text>
                </View>
              )}
              {loading.shortLinkLoading && <Spinner style={styles.spinnerStyle} size={'lg'}></Spinner>}
            </View>
          )}
          {props?.inviteUserIdLoading && (
            <View
              style={{
                backgroundColor: 'transparent',
                opacity: 1,
                position: 'absolute',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner size={'lg'} />
            </View>
          )}
        </View>
      ) : null}
    </View>
  );
};

export default ContactAutoCompleteCustom;
