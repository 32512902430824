import {DATE_FORMATS} from '../../../constants';
import {
  CARE_PROGRAM_TYPE_CODES,
  CONTACT_CARE_PROGRAM_STATUS_CODES,
} from '../../../constants/MlovConst';
import {IMlov} from '../../../Interfaces';
import BaseService from '../../../services/CommonService/BaseService';
import {
  getEndOfDay,
  getFormattedDate,
  getMomentObj,
  getStartOfDay,
} from '../../../utils/DateUtils';
import {getDiffInMonths} from '../../../utils/DateUtils';
import {getMlovCodeFromId} from '../../../utils/mlovUtils';
import {
  CARE_MANAGEMENT_CATEGORY_CODES,
  CareProgramDateType,
} from '../CareManagementBilling/CareManagementConstants';
import {ICondition} from '../Forms/FHFormio/CustomComponents/Conditions/interfaces';
import {
  ICareProgramDateFilter,
  ICareProgramStepStatus,
  IEnrollInCareProgram,
  IFutureAppointmentData,
} from './interface';

export const getWhereConditionForCareProgram = (
  contactUuid: string,
  careProgramTypeId: string,
  assigneeId?: string,
  statusId?: string,
  selectedDateFilter?: ICareProgramDateFilter
) => {
  const programStartDateFromDate =
    selectedDateFilter?.programStartDate?.[CareProgramDateType.FROM_DATE] &&
    getStartOfDay(
      selectedDateFilter?.programStartDate?.[CareProgramDateType.FROM_DATE]
    );
  const programStartDateToDate =
    selectedDateFilter?.programStartDate?.[CareProgramDateType.TO_DATE] &&
    getEndOfDay(
      selectedDateFilter?.programStartDate?.[CareProgramDateType.TO_DATE]
    );

  const programEndDateFromDate =
    selectedDateFilter?.programEndDate?.[CareProgramDateType.FROM_DATE] &&
    getStartOfDay(
      selectedDateFilter?.programEndDate?.[CareProgramDateType.FROM_DATE]
    );
  const programEndDateToDate =
    selectedDateFilter?.programEndDate?.[CareProgramDateType.TO_DATE] &&
    getEndOfDay(
      selectedDateFilter?.programEndDate?.[CareProgramDateType.TO_DATE]
    );

  const careProgramWhereCondition = {
    contactId: {
      _eq: contactUuid,
    },
    isDeleted: {
      _eq: false,
    },
    ...(assigneeId && {
      assigneeId: {
        _eq: assigneeId,
      },
    }),
    ...(statusId && {
      statusId: {
        _eq: statusId,
      },
    }),
    ...(programStartDateFromDate &&
      programStartDateToDate && {
        _and: [
          {
            startDateTime: {
              _gte: programStartDateFromDate,
            },
          },
          {
            startDateTime: {
              _lte: programStartDateToDate,
            },
          },
        ],
      }),
    ...(programEndDateFromDate &&
      programEndDateToDate && {
        _and: [
          {
            endDateTime: {
              _gte: programEndDateFromDate,
            },
          },
          {
            endDateTime: {
              _lte: programEndDateToDate,
            },
          },
        ],
      }),

    ...(careProgramTypeId && {
      payerCareProgram: {
        careProgramTypeId: {
          _eq: careProgramTypeId,
        },
      },
    }),
  };
  return careProgramWhereCondition;
};

export const getSectionTitle = (statusCode: string) => {
  switch (statusCode) {
    case CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS:
      return CARE_MANAGEMENT_CATEGORY_CODES.ACTIVE;

    case CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED:
    case CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED:
    case CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED:
      return CARE_MANAGEMENT_CATEGORY_CODES.COMPLETED;

    case CONTACT_CARE_PROGRAM_STATUS_CODES.NEW:
      return CARE_MANAGEMENT_CATEGORY_CODES.NEW;
  }
};

export const getGroupedCareProgramData = (
  careProgramList: any[],
  careProgramStatusList: IMlov[]
) => {
  const updatedData: any = [];
  const sectionOrder = {
    [CARE_MANAGEMENT_CATEGORY_CODES.NEW]: 1,
    [CARE_MANAGEMENT_CATEGORY_CODES.ACTIVE]: 2,
    [CARE_MANAGEMENT_CATEGORY_CODES.COMPLETED]: 3,
  };
  careProgramList?.forEach((item: any) => {
    const statusCode = getMlovCodeFromId(careProgramStatusList, item?.statusId);
    const sectionTitle = getSectionTitle(statusCode);
    const key = sectionTitle;
    const formattedData = {
      key: key,
      data: [item],
    };
    if (updatedData.length > 0) {
      const index = updatedData.findIndex((data: any) => data.key === key);
      if (index !== -1) {
        updatedData[index].data = [...updatedData[index].data, item];
      } else {
        updatedData.push(formattedData);
      }
    } else {
      updatedData.push(formattedData);
    }
  });
  updatedData.sort((a: any, b: any) => {
    const orderA = sectionOrder[a?.key] || Number.MAX_VALUE;
    const orderB = sectionOrder[b?.key] || Number.MAX_VALUE;
    return orderA - orderB;
  });

  return updatedData;
};

export const enrollContactCareProgram = async (data: IEnrollInCareProgram) => {
  const bodyData = {
    params: {
      careProgramTypeId: data?.careProgramTypeId,
      contactId: data?.contactId,
      eligibilityDateTime: data?.eligibilityDateTime,
      membershipYear: data?.membershipYear,
      sourceId: data?.sourceId,
    },
  };
  const baseService = BaseService.getSharedInstance().axios;
  const careProgramUrl = `carestudio-nest/contactCareProgram/assign`;
  const careProgramResponse = await baseService.post(careProgramUrl, bodyData);
  return careProgramResponse?.data;
};

export const getCardMainTitle = (careProgramTypeCode: string) => {
  switch (careProgramTypeCode) {
    case CARE_PROGRAM_TYPE_CODES.CCM:
      return 'Chronic Care Management (CCM)';
    case CARE_PROGRAM_TYPE_CODES.TCM:
      return 'Transitional Care Management (TCM)';
    case CARE_PROGRAM_TYPE_CODES.ECM:
      return 'Enhanced Care Management (ECM)';
    default:
      return 'Care Program';
  }
};

export const calculateProgressPercentage = (
  careProgramStepStatus: ICareProgramStepStatus,
  contactCareProgramStepsLog: any[]
) => {
  const totalSteps = contactCareProgramStepsLog?.length;
  let AggergateProgress = 0;
  contactCareProgramStepsLog?.forEach((item: any) => {
    switch (item?.careProgramStepStatusId) {
      case careProgramStepStatus?.done:
      case careProgramStepStatus?.skip:
      case careProgramStepStatus?.reject:
      case careProgramStepStatus?.declined:
        AggergateProgress += 1;
        break;
      default:
        AggergateProgress += 0;
        break;
    }
  });
  return (AggergateProgress / totalSteps) * 100;
};

export const getLastUpdateText = (
  careProgramActivityLogs: any,
  pcpFutureAppointmentData?: IFutureAppointmentData
) => {
  return `Last Update: ${getFormattedDate(
    careProgramActivityLogs?.timestamp,
    DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
  )} ${pcpFutureAppointmentData?.id ? ' • ' : ''}`;
};

export const getSubTitleText = (
  chronicConditionsData: ICondition[],
  careProgramTypeCode: string,
  pcpUserData: any,
  contactCareProgram: any,
  assigneeUserData: any
) => {
  const details: string[] = [];
  if (
    careProgramTypeCode === CARE_PROGRAM_TYPE_CODES.CCM &&
    chronicConditionsData?.length > 0
  ) {
    chronicConditionsData?.forEach((item: ICondition) => {
      const diffInMonth = item?.onSetDateTime
        ? Math.floor(
            getDiffInMonths(item?.onSetDateTime, getMomentObj(new Date()))
          )
        : 0;
      details.push(`${item?.name} (${diffInMonth} mon)`);
    });
  }
  if (pcpUserData?.userData?.name) {
    details.push(`PCP: ${pcpUserData?.userData?.name} (PCP)`);
  }

  if (contactCareProgram?.startDateTime) {
    details.push(
      `Start Date: ${getFormattedDate(contactCareProgram?.startDateTime, DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)}`
    );
  }

  if (contactCareProgram?.endDateTime) {
    details.push(
      `End Date: ${getFormattedDate(contactCareProgram?.endDateTime, DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)}`
    );
  }

  if (assigneeUserData?.name) {
    details.push(`Assigned to: ${assigneeUserData?.name}`);
  }

  return details.join(' • ');
};
