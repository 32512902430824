import {Button, Modal, Text} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../constants';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {IDeleteTemplateModalProps} from '../interfaces';
import {styles} from './DeleteTemplateModalStyle';

const {Content, CloseButton, Header, Footer, Body} = Modal;
const DeleteSMSTemplateModal = (props: IDeleteTemplateModalProps) => {
  const {isOpen, onClose, onConfirm, record} = props;
  const intl = useIntl();
  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <Content style={styles.modalContainer}>
        <CloseButton />
        <Header>
          <DisplayText textLocalId="deleteTemplate" textType="Heading" />
        </Header>
        <Body>
          {record.templateIsDefault ? (
            <Text>
              This template cannot be deleted because it is default template.
              Please change the default status or set another template as
              default
            </Text>
          ) : (
            <DisplayText
              textLocalId="deleteTemplateConfirmation"
              textType="Text"
            />
          )}
        </Body>
        <Footer style={styles.modalFooter}>
          <Button.Group>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            {!record.templateIsDefault ? (
              <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onConfirm();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'confirm',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            ) : (
              <></>
            )}
          </Button.Group>
        </Footer>
      </Content>
    </Modal>
  );
};

export default DeleteSMSTemplateModal;
