import React from 'react';
import {Text, View} from 'native-base';
import {ComingSoon} from '../../../common/ComingSoon';
import {styles} from './SegmentsStyles';
import {ISegmentsProps} from '.';

const Segments = (props: ISegmentsProps) => {
  return (
    <View>
      <View style={styles.left}>
        <Text style={styles.leftText}>Segments</Text>
      </View>
      <View style={styles.container}>
        <View>
          <Text style={styles.textContainer}>{props.tempProp || ''}</Text>
        </View>
        <ComingSoon />
      </View>
    </View>
  );
};

export default Segments;
