import React from "react";

const EmployeeWellnessIndexSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill={svgColors}
        d="M5.833 25.667h16.334a2.336 2.336 0 002.333-2.334V7a2.336 2.336 0 00-2.333-2.333h-2.334V2.333H17.5v2.334h-7V2.333H8.167v2.334H5.833A2.336 2.336 0 003.5 7v16.333a2.336 2.336 0 002.333 2.334zm12.492-5.492l-1.65 1.65L14 19.15l-2.675 2.675-1.65-1.65L12.35 17.5l-2.675-2.675 1.65-1.65L14 15.85l2.675-2.675 1.65 1.65L15.65 17.5l2.675 2.675zM5.833 8.167h16.334V10.5H5.833V8.167z"
      ></path>
    </svg>
  );
}

export default React.memo(EmployeeWellnessIndexSvg);
