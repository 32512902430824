import { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LeadQueries } from '../../../../../../services';
import { getContactTypeId, getMlovListFromCategory, getMlovIdFromCode, getMlovId } from '../../../../../../utils/mlovUtils';
import { getAgeFromDate } from '../../../../../PersonOmniView/LeftContainer/OtherDetails/OtherDeatilsUtils';
import { PatientData, UseDayOptimizerResult } from '../../interfaces';
import { MLOV_CATEGORY, MLOV_CODES, TASK_ALERT_CATEGORY_CODES } from '../../../../../../constants/MlovConst';
import { IContactScore } from '../../interfaces';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { DayOptimizerContext } from '../../../../../../context-provider/DayOptimizerContext/context';
import { formatDateForApi } from '../../DayOptimizerHelper';
import { getUserUUID } from '../../../../../../utils/commonUtils';
import { useToast } from 'native-base';
import { showToast, ToastType } from '../../../../../../utils/commonViewUtils';
import { IContactScoreAudit } from '../../../../MemebersView/interface';
import { getAgeValue } from '../../../../../../utils/DateUtils';
import { USER_ACCESS_PERMISSION } from '../../../../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../../../SideMenuBar/SideBarConst';
import { usePermissions } from '../../../../../CustomHooks/usePermissions';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';

const useDOPatientListing = (): UseDayOptimizerResult => {
  const [patients, setPatients] = useState<PatientData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [offset, setOffset] = useState<number>(0);
  const [prevDate, setPrevDate] = useState<Date| null>(null);
  const [prevUserUuid, setPrevUserUuid] = useState<string>('');
  const totalCountRef = useRef<number>(0);
  const toast = useToast();
  
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code, MAIN_MENU_CODES.HOME);
  const currentUserAllowedLocations = permissionConfig?.allowedLocationIds || [];

  const { selectedDate, columnShuffleDropDownList } = useContext(DayOptimizerContext).state.patientDashboard;
  const { state: dayOptimizerState } = useContext(DayOptimizerContext);
  const commonData = useContext(CommonDataContext);

  const formattedDate = selectedDate ? formatDateForApi(selectedDate.toISOString()) : '';
  const rafFilter = null;
  const { selectedUser } = dayOptimizerState?.patientDashboard;
  const loggedInUserUuid = getUserUUID();
  const userUuids = selectedUser?.uuid ? selectedUser?.uuid : loggedInUserUuid;
  const limit = 10;
  const checked_DO_Id = getMlovId(commonData.MLOV, MLOV_CATEGORY.USER_DAY_OPTIMIZER_SCHEDULE_STATUS, MLOV_CODES.DAY_OPTIMIZER_DONE);
  const unChecked_DO_TodoId = getMlovId(commonData.MLOV, MLOV_CATEGORY.USER_DAY_OPTIMIZER_SCHEDULE_STATUS, MLOV_CODES.DAY_OPTIMIZER_TODO);

  const getAlertDisplayCategoryId = async () => {
    try {
      const taskAlertDisplayCategoryMlovs = getMlovListFromCategory(
        commonData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.TASK_ALERT_DISPLAY_CATEGORIES
      ) || [];
      const alertDisplayCategoryId = getMlovIdFromCode(
        taskAlertDisplayCategoryMlovs,
        TASK_ALERT_CATEGORY_CODES.PENDING
      );
      return alertDisplayCategoryId;
    } catch (error) {
      return null;
    }
  };

  const [getPatients] = useLazyQuery(LeadQueries.searchDayOptimizerPatients, {
    fetchPolicy: 'no-cache',
    onCompleted: async (resp: any) => {
      const patientList = resp?.searchContacts?.contacts || [];
      const alertDisplayCategoryId = await getAlertDisplayCategoryId();
      const formattedPatients = patientList?.map((contact: any) => {
        const userDayOptimizerSchedule = contact?.userDayOptimizerSchedule || [];
        const firstSchedule = userDayOptimizerSchedule[0] || {};
        const checked = firstSchedule?.statusId === checked_DO_Id ? true : firstSchedule?.statusId === unChecked_DO_TodoId ? false : null;
        return {
          priority: firstSchedule?.priorityScore || 0,
          patientId: contact?.id,
          name: contact?.name,
          dayOptimizerId: firstSchedule?.id,
          uuid: contact?.uuid,
          clientId: contact?.patient?.patientId,
          birthDate: contact?.person?.birthDate,
          gender: contact?.person?.gender?.code,
          age: contact?.person?.birthDate && getAgeValue(contact?.person?.birthDate, '', true),
          practiceLocation: contact?.contactPracticeLocations?.[0]?.practiceLocation?.name,
          rafScore: contact?.contactScore?.hccV28 || 0,
          contactScore: (contact?.contactScore || {}) as IContactScore,
          contactScoreAudit: (contact?.contactScoreAudit || []) as IContactScoreAudit[],
          reason: [
            'Last day patient was discharge from ST.Joseph hospital to home. Patient Re-Admision risk is high.',
            'AWV Sceduled for today by patient and HRA is also submitted online.',
            'Last day patient was discharge from ST.Joseph hospital to home. Patient Re-Admision risk is high.',
            'AWV Sceduled for today by patient and HRA is also submitted online.'
          ],
          actions: 'Review TCM',
          alertDisplayCategoryId: alertDisplayCategoryId,
          checked: checked,          
        };
      });
      setPatients(prevPatients => {
        const existingPatientIds = new Set(prevPatients.map(patient => patient.patientId));
        const newPatients = formattedPatients?.filter((patient: any) => !existingPatientIds.has(patient.patientId));
        return [...prevPatients, ...newPatients];
      });

      setOffset(prevOffset => prevOffset + formattedPatients?.length);

      totalCountRef.current = resp?.searchContacts?.contactAggregate?.aggregate?.count || 0;
      setHasMore(totalCountRef.current > offset + formattedPatients.length);
      setIsLoading(false);
    },
    onError: (error) => {
      setError('Failed to fetch patients');
      setIsLoading(false);
    }
  });

  const fetchPatients = useCallback((rafFilter: string | null, formattedDate: string, reset = false, offset: number) => {
    if (!hasMore && totalCountRef.current > offset) return;
    if (reset) {
      setPatients([]);
      setOffset(0);
    }

    setIsLoading(true);
    getPatients({
      variables: {
        params: {
          limit: limit,
          offset: reset ? 0 : offset,
          userDayOptimizerScheduleFilters: {
            fromDate: formattedDate,
            toDate: formattedDate,
            userUuids: [userUuids]
          },
          userDayOptimizerScheduleSelectFilters : {
            fromDate: formattedDate,
            toDate: formattedDate,
            userUuids: [userUuids]
          },
          orderBy: [{userDayOptimizerSchedule: {priorityScore: "desc"}}],
          ...(isMsoEnabled && {
            permittedFor: {
              anyOf: currentUserAllowedLocations?.map((locationId: string) => ({
                resourceCode: `${USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code}/${MAIN_MENU_CODES.HOME}`,
                accountLocationId: locationId,
              })),
            }
          }),
        }
      },
    });
  }, [hasMore, limit, userUuids]);

  useEffect(() => {
    if (prevDate !== selectedDate || JSON.stringify(prevUserUuid) !== JSON.stringify(userUuids)) {
      setPatients([]);
      fetchPatients(rafFilter, formattedDate, true, offset);
      setPrevDate(selectedDate);
      setPrevUserUuid(userUuids);
    } else {
      fetchPatients(rafFilter, formattedDate, false, offset);
    }
  }, [formattedDate, selectedUser?.uuid]);


  const [updatePatientInDayOptimizer] = useMutation(LeadQueries.addPatientToDayOptimizer, {
    onCompleted: (data) => {
      showToast(
        toast,
        'Patient status updated!',
        ToastType.success,
        1500
      );
    },
    onError: (error) => {
      console.error('Error updating patient status in Day Optimizer:', error);
    }
  });

  const updatePatientStatus = useCallback((dayOptimizerId: string, isChecked: boolean,) => {
    const statusId = isChecked ? unChecked_DO_TodoId : checked_DO_Id;
    updatePatientInDayOptimizer({
      variables: {
        params: {
          statusId,
          id: dayOptimizerId
        }
      }
    });
  }, []);

  return {
    patients,
    isLoading,
    hasMore,
    error,
    fetchPatients: () => fetchPatients(rafFilter, formattedDate, false, offset),
    rafFilter,
    formattedDate,
    updatePatientStatus,
    columnShuffleDropDownList
  };
};

export default useDOPatientListing;
