import {Box, Text} from 'native-base';
import React from 'react';
import {Colors} from '../../../../../../../styles';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  dayTimelineTag: {
    borderRadius: 4,
    backgroundColor: Colors.Custom.Info50,
    maxWidth: 65,
    height: 30,
    paddingVertical: 4,
    paddingHorizontal: 8,
    marginVertical: 2,
  }
});

const DayTimelineTag = (props: {day: string}) => {
  if(!props.day) return null;
  return (
    <Box style={styles.dayTimelineTag}>
      <Text color={Colors.Custom.Gray700} fontWeight={'bold'}>
        {props.day}
      </Text>
    </Box>
  );
};

export default DayTimelineTag;
