import React, {useRef} from 'react';
import {WIDGET_ACTION_CODE} from '../EmployerUtils/EmployerConst';
import {IWidgetCardView} from '../interface';
import SavingWidget from './SavingWidget';
import {getSectionHideAndShowCheck, getWidgetBoxClassName, getWidgetBoxResourceClassName} from '../EmployerUtils/WidgetUtils';

const SavingsGroupView = (props: IWidgetCardView) => {
  const {
    sectionData,
    dateRange,
    appliedFilter,
    employerId,
    isAccountDashboard,
    memberType,
    childWidget,
    filterData,
    sectionCode,
    onActionClick,
    reusableData,
    sectionDisabled,
    onOpenDetails,
    onWidgetLoaded
  } = props;
  const containerRef: any = useRef();
  const widgetWidget = containerRef?.current?.offsetWidth ? (parseInt((containerRef?.current?.offsetWidth) as any) * .48) : '48%';
  return <>
    <div ref={containerRef} style={{display: 'flex', flexWrap: 'wrap', width: '100%'}} >
      {sectionData.childSectionWidget?.map((item: any) => {
        if (
          getSectionHideAndShowCheck(
            props.isPrintPreview || false,
            item.isVisible,
            props?.isPrintDownload,
            item.isDisabled
          )
        ){
        return (
          <div
            key={item.resourceCode}
            id={item.resourceCode || ''}
            className={` saving-group-item ${getWidgetBoxClassName(
              item.sectionCategory || '',
              item.resourceCode || ''
            )} ${getWidgetBoxResourceClassName(
              item.resourceCode || ''
            )}  ${item.sectionCategory} print-indivisible  ${props.isPrintPreview || props.isPrintDownload ? 'in-print-drawer' : ''} `}
            style={
              (!item?.isDisabled || !props.isPrintPreview) && (!sectionDisabled || !props.isPrintPreview)
                ? {
                  opacity: 1,
                  background: 'transparent',
                  width: widgetWidget,
                  marginRight: 10,
                  marginBottom: 10
                }
                : {
                  opacity: 0.3,
                  filter: "grayscale(1)",
                  background: 'transparent',
                  width: widgetWidget,
                  marginRight: 10,
                  marginBottom: 10
                }
            }
          >
            <SavingWidget
              filterData={filterData}
              appliedFilter={appliedFilter}
              employerId={employerId}
              sectionCode={item.sectionCategory}
              onActionClick={(code, data) => {
                onActionClick?.(code, data)
              }}
              onWidgetLoaded={onWidgetLoaded}
              sectionData={item}
              resourceData={{}}
              reusableData={reusableData}
              isPrintPreview={props.isPrintPreview}
              isPrintDownload={props.isPrintDownload}
              dateRange={props.dateRange}
              onOpenDetails={(data) => {
                onOpenDetails?.(data)
              }}
              sectionDisabled={sectionDisabled}
              isAccountDashboard={isAccountDashboard}
              memberType={memberType}
            />
          </div>
        )}
      })}
    </div>
  </>
};

export default (SavingsGroupView);
