import React from "react";

const NodataWidgetImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="112"
      height="158"
      fill="none"
      viewBox="0 0 112 158"
    >
      <rect
        width="111.013"
        height="133.48"
        x="0.494"
        y="23.744"
        fill="#F2F4F7"
        rx="9.515"
      ></rect>
      <g filter="url(#filter0_d_601_3975)">
        <path
          fill="#fff"
          d="M92.703 50.756H19.3a4.268 4.268 0 00-4.267 4.267v89.62a4.268 4.268 0 004.267 4.268h73.403a4.268 4.268 0 004.268-4.268v-89.62a4.268 4.268 0 00-4.268-4.267z"
        ></path>
      </g>
      <path
        fill="#D0D5DD"
        d="M47.466 63.559H25.274a2.56 2.56 0 000 5.12h22.192a2.56 2.56 0 000-5.12z"
      ></path>
      <path
        fill="#E4E7EC"
        d="M62.83 74.653H25.274a2.56 2.56 0 000 5.121H62.83a2.56 2.56 0 100-5.12z"
      ></path>
      <path
        fill="#D0D5DD"
        d="M47.466 86.601H25.274a2.56 2.56 0 000 5.122h22.192a2.56 2.56 0 000-5.122z"
      ></path>
      <path
        fill="#E4E7EC"
        d="M62.83 97.698H25.274a2.56 2.56 0 000 5.121H62.83a2.56 2.56 0 100-5.12z"
      ></path>
      <path
        fill="#D0D5DD"
        d="M47.466 109.648H25.274a2.561 2.561 0 000 5.121h22.192a2.56 2.56 0 100-5.121z"
      ></path>
      <path
        fill="#E4E7EC"
        d="M62.83 120.745H25.274a2.56 2.56 0 100 5.121H62.83a2.56 2.56 0 100-5.121z"
      ></path>
      <g filter="url(#filter1_d_601_3975)">
        <path
          fill="#D0D5DD"
          d="M92.703 7.226H19.3a4.268 4.268 0 00-4.267 4.268V37.1a4.268 4.268 0 004.267 4.267h73.403a4.268 4.268 0 004.268-4.267V11.494a4.268 4.268 0 00-4.268-4.268z"
        ></path>
      </g>
      <path
        fill="#FCFDFD"
        d="M47.466 16.617H25.274a2.56 2.56 0 000 5.121h22.192a2.56 2.56 0 000-5.12z"
      ></path>
      <path
        fill="#F2F4F7"
        d="M62.83 27.714H25.274a2.56 2.56 0 000 5.12H62.83a2.56 2.56 0 100-5.12z"
      ></path>
      <defs>
        <filter
          id="filter0_d_601_3975"
          width="92.847"
          height="109.064"
          x="9.578"
          y="48.029"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2.727"></feOffset>
          <feGaussianBlur stdDeviation="2.727"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.596078 0 0 0 0 0.635294 0 0 0 0 0.701961 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_601_3975"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_601_3975"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_601_3975"
          width="98.302"
          height="50.505"
          x="6.851"
          y="0.408"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1.364"></feOffset>
          <feGaussianBlur stdDeviation="4.091"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.596078 0 0 0 0 0.635294 0 0 0 0 0.701961 0 0 0 0.6 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_601_3975"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_601_3975"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default NodataWidgetImage;
