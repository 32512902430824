import {Modal} from 'antd';
import {Box, Button, useToast, View, Text} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {COMMON_ACTION_CODES} from "../../../../../../constants/ActionConst";
import {BUTTON_TYPE} from '../../../../../../constants/StringConst';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';

const PayerConfirmModal = (props: {
  onPayerConfirmModalActionPerformed: (actionCode: string) => void
}) => {
  const {onPayerConfirmModalActionPerformed} = props;
  const intl = useIntl();
  return (
    <Modal
      title="Confirmation"
      open={true}
      onCancel={() => {
        onPayerConfirmModalActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL);
      }}
      footer={null}
      bodyStyle={{
        paddingTop: 12,
        zIndex: 9999999999
      }}
    >
      <View>
        <Text size={'mdMedium'} >
          {
            intl.formatMessage({
              id: 'updateEmployerPayerConfirmMessage',
            })
          }
        </Text>
        <Button.Group style={{marginTop: 12}} justifyContent={'flex-end'}>
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress: () => {
                onPayerConfirmModalActionPerformed(COMMON_ACTION_CODES.UPDATE);
              },
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'yes',
              }),
              withRightBorder: false,
            }}
          ></FoldButton>
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.SECONDARY,
              onPress: () => {
                onPayerConfirmModalActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL);
              },
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'no',
              }),
              withRightBorder: false,
            }}
          ></FoldButton>

        </Button.Group>
      </View>
    </Modal>
  );
};

export default React.memo(PayerConfirmModal);
