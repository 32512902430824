import {Steps, Upload} from 'antd';
import {
  Button,
  FormControl,
  HStack,
  Input,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useContext, useState} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {BUTTON_TYPE, MLOV_CATEGORY} from '../../../../../constants';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import CommonService from '../../../../../services/CommonService/CommonService';
import {Colors} from '../../../../../styles';
import {
  getAccountUUID,
  getContactUuid,
  getUserActionObjectFromActionCode,
  getUserUUID,
} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {getMlovListFromCategory} from '../../../../../utils/mlovUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {insertUserAction} from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {styles} from './BulkImportSpruceStyles';

const BulkImportSpruce = (props: any) => {
  const toast = useToast();
  const intl = useIntl();
  const {onModalClose} = props;
  const [isLoading, setIsLoading] = useState(false);
  const {Step} = Steps;
  const [entity, setEntity] = useState({
    name: '',
  } as any);
  const [contacts, setContacts] = useState({
    name: '',
  } as any);
  const [phoneLines, setPhoneLines] = useState({
    name: '',
  } as any);

  const [conv, setConv] = useState({
    name: '',
  } as any);
  const [messages, setMessages] = useState({
    name: '',
  } as any);
  const [inc, setInc] = useState({
    name: '',
  } as any);
  const [out, setOut] = useState({
    name: '',
  } as any);
  const mlovData = useContext(CommonDataContext);
  const userUuid = getContactUuid() || '';
  const formData = new FormData();
  const consentType = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.CONSENT
  );
  const consentIds = consentType.map((consent) => {
    return consent?.id;
  });
  const [mappedValues, setMappedValues] = useState<any>({
    phoneNumber: '',
    email: '',
    firstName: '',
    middleName: '',
    gender: '',
    birthDate: '',
    lastName: '',
  });
  const [importError, setImportError] = useState<any>(null);
  const [importedCsvMapping, setImportedCsvMapping] = useState<any[]>([]);
  const [current, setCurrent] = useState(0);
  const [final, setFinal] = useState(0);
  const commonService = CommonService.getCommonServiceInstance();
  const integrationService = commonService.integrationService;
  const url = '/attachment/upload/multiple';

  const subTitleHeader =
    'Import your Spruce data by uploading the spreadsheets received from Spruce.';

  const userActionObject = getUserActionObjectFromActionCode('SPRUCE');
  const level = userActionObject.level || '';
  const accountUuid = getAccountUUID();
  const userId = getUserUUID();

  const importEntityCall = () => {
    formData.append('files', entity);
    formData.append('files', contacts);
    formData.append('files', phoneLines);
    setCurrent(current + 1);

    integrationService
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((resp: {data: any}) => {
        if (resp?.data) {
        }
      })
      .catch((e: any) => {

        setIsLoading(false);
        setImportError('Something went wrong');
      });
  };

  const importConv = () => {
    formData.append('files', conv);
    formData.append('files', messages);
    setCurrent(current + 1);
    setFinal(final + 1);

    integrationService
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((resp: {data: any}) => {
        if (resp?.data) {
        }
      })
      .catch((e: any) => {

        setIsLoading(false);
        setImportError('Something went wrong');
      });
  };

  const importConvCall = async () => {
    setImportError(null);

    formData.append('files', inc);
    formData.append('files', out);

    setIsLoading(true);

    integrationService
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((resp: {data: any}) => {
        if (resp?.data) {
          insertUserAction({
            actionCode: 'SPRUCE',
            level,
            status: 'COMPLETED',
            userId: userId,
          });
        }
      })
      .catch((e: any) => {

        setIsLoading(false);
        setImportError('Something went wrong');
      });
    showToast(
      toast,
      `Spruce Data will be imported tonight. We will notify you once the import is complete.`,
      ToastType.info
    );
    onModalClose(true);
  };

  const renderUploadMessages = (): JSX.Element => {
    return (
      <>
        <FormControl isRequired style={[styles.formElement]}>
          <FormControl.Label ml={0} style={styles.formLabel}>
            <DisplayText
              textLocalId={
                'Upload Spruce Conversations (csv named conversations.csv)'
              }
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray700}}
            />
          </FormControl.Label>
          <Upload
            onChange={(e) => {
              setConv(e.file);
            }}
            style={{width: '100%'}}
            showUploadList={false}
            prefixCls=" "
            accept="text/csv"
            beforeUpload={() => false}
          >
            <Input
              borderRadius={8}
              height={44}
              value={conv?.name}
              isReadOnly
              placeholder="Upload File"
              type="file"
            />
          </Upload>

          {importError && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {importError}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
        <FormControl isRequired style={[styles.formElement]}>
          <FormControl.Label ml={0} style={styles.formLabel}>
            <DisplayText
              textLocalId={'Upload Spruce messages (csv named messages.csv)'}
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray700}}
            />
          </FormControl.Label>
          <Upload
            onChange={(e) => {
              setMessages(e.file);
            }}
            style={{width: '100%'}}
            showUploadList={false}
            prefixCls=" "
            accept="text/csv"
            beforeUpload={() => false}
          >
            <Input
              borderRadius={8}
              height={44}
              value={messages?.name}
              isReadOnly
              placeholder="Upload File"
              type="file"
            />
          </Upload>

          {importError && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {importError}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      </>
    );
  };

  const renderUploadCalls = (): JSX.Element => {
    return (
      <>
        <FormControl isRequired style={[styles.formElement]}>
          <FormControl.Label ml={0} style={styles.formLabel}>
            <DisplayText
              textLocalId={
                'Upload Spruce Inbound Calls (csv named inbound_calls.csv)'
              }
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray700}}
            />
          </FormControl.Label>
          <Upload
            onChange={(e) => {
              setInc(e.file);
            }}
            style={{width: '100%'}}
            showUploadList={false}
            prefixCls=" "
            accept="text/csv"
            beforeUpload={() => false}
          >
            <Input
              borderRadius={8}
              height={44}
              value={inc?.name}
              isReadOnly
              placeholder="Upload File"
              type="file"
            />
          </Upload>

          {importError && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {importError}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
        <FormControl isRequired style={[styles.formElement]}>
          <FormControl.Label ml={0} style={styles.formLabel}>
            <DisplayText
              textLocalId={
                'Upload Spruce Outbound Calls (csv named outbound_calls.csv)'
              }
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray700}}
            />
          </FormControl.Label>
          <Upload
            onChange={(e) => {
              setOut(e.file);
            }}
            style={{width: '100%'}}
            showUploadList={false}
            prefixCls=" "
            accept="text/csv"
            beforeUpload={() => false}
          >
            <Input
              borderRadius={8}
              height={44}
              value={out?.name}
              isReadOnly
              placeholder="Upload File"
              type="file"
            />
          </Upload>

          {importError && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {importError}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      </>
    );
  };

  const renderUploadEntities = (): JSX.Element => {
    return (
      <>
        <FormControl isRequired style={[styles.formElement]}>
          <FormControl.Label ml={0} style={styles.formLabel}>
            <DisplayText
              textLocalId={'Upload Spruce Entities (csv named entities.csv)'}
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray700}}
            />
          </FormControl.Label>
          <Upload
            onChange={(e) => {
              setEntity(e.file);
            }}
            style={{width: '100%'}}
            showUploadList={false}
            prefixCls=" "
            accept="text/csv"
            beforeUpload={() => false}
          >
            <Input
              borderRadius={8}
              height={44}
              value={entity?.name}
              isReadOnly
              placeholder="Upload File"
              type="file"
            />
          </Upload>

          {importError && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {importError}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
        <FormControl isRequired style={[styles.formElement]}>
          <FormControl.Label ml={0} style={styles.formLabel}>
            <DisplayText
              textLocalId={
                'Upload Spruce Contacts (csv named entity_contact_info.csv)'
              }
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray700}}
            />
          </FormControl.Label>
          <Upload
            onChange={(e) => {
              setContacts(e.file);
            }}
            style={{width: '100%'}}
            showUploadList={false}
            prefixCls=" "
            accept="text/csv"
            beforeUpload={() => false}
          >
            <Input
              borderRadius={8}
              height={44}
              value={contacts?.name}
              isReadOnly
              placeholder="Upload File"
              type="file"
            />
          </Upload>

          {importError && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {importError}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
        <FormControl isRequired style={[styles.formElement]}>
          <FormControl.Label ml={0} style={styles.formLabel}>
            <DisplayText
              textLocalId={
                'Upload Spruce Phone Lines (csv named voip_phone_lines.csv)'
              }
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray700}}
            />
          </FormControl.Label>
          <Upload
            onChange={(e) => {
              setPhoneLines(e.file);
            }}
            style={{width: '100%'}}
            showUploadList={false}
            prefixCls=" "
            accept="text/csv"
            beforeUpload={() => false}
          >
            <Input
              borderRadius={8}
              height={44}
              value={phoneLines?.name}
              isReadOnly
              placeholder="Upload File"
              type="file"
            />
          </Upload>

          {importError && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {importError}
            </FormControl.ErrorMessage>
          )}
        </FormControl>

        <Text
          size={'smMedium'}
          style={{color: Colors.primary[700], marginTop: 20, marginLeft: 12}}
        ></Text>
      </>
    );
  };

  const steps = [
    {
      title: (
        <Text
          size={'xsMedium'}
          color={current == 0 ? Colors.primary[700] : Colors.Custom.Gray700}
        >
          {'Upload Contacts'}
        </Text>
      ),
      content: renderUploadEntities(),
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          color={current == 1 ? Colors.primary[700] : Colors.Custom.Gray700}
        >
          {'Upload Messages'}
        </Text>
      ),
      content: renderUploadMessages(),
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          color={current == 1 ? Colors.primary[700] : Colors.Custom.Gray700}
        >
          {'Upload Calls'}
        </Text>
      ),
      content: renderUploadCalls(),
    },
  ];

  return (
    <View mt={0} mx={0}>
      <HStack flex={1}>
        <VStack justifySelf={'flex-start'} flex={0.5}>
          <TitleSubtitleView
            isTitleSubtitleOldView={true}
            isHideCommonButton={true}
            containerStyle={{marginBottom: 0, marginLeft: 0, marginTop: 0}}
            titleLabelId={'bulkImportText'}
            titleStyle={{fontSize: 24}}
          />
        </VStack>
        <HStack
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 0.5,
            //marginTop: 20,
          }}
        >
          <Button.Group justifyContent={'flex-end'}>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    onModalClose(false);
                  },
                  style: {marginRight: 10},
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'cancel',
                  }),
                  withRightBorder: false,
                  hideButton: !current,
                }}
              ></FoldButton>
            {current ? (
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    setCurrent(current - 1);
                  },
                  style: {marginRight: 10},
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'back',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            ) : (
              <></>
            )}

            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  if (current === 1) {
                    importConv();
                  } else if (current === 2) {
                    importConvCall();
                    onModalClose();
                  } else {
                    importEntityCall();
                  }
                },
                isDisabled: isLoading,
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: final ? 'Import' : 'next',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </HStack>
      </HStack>
      <Text
        size={'smMedium'}
        marginTop={1}
        color={Colors.Custom.Gray700}
        marginX={0}
      >
        {intl.formatMessage({id: subTitleHeader})}
      </Text>

      <View style={{marginHorizontal: 50, marginTop: 30}}>
        <Steps
          //progressDot={renderProgressDot}
          labelPlacement="vertical"
          current={current}
        >
          {steps.map((item, index) => (
            <Step
              icon={
                <View
                  style={{
                    width: 32,
                    height: 32,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AntIcon
                    size={24}
                    name={index < current ? 'checkcircle' : 'checkcircleo'}
                    color={Colors.primary[700]}
                  />
                </View>
              }
              key={index + 'step'}
              title={item.title}
            />
          ))}
        </Steps>
      </View>

      <View style={{marginTop: 20}}> {steps[current]?.content}</View>
    </View>
  );
};

export default BulkImportSpruce;
