import React from 'react';
import {Center, Text, View} from 'native-base';
import {styles} from './NoRouteMatchStyles';
import { getEntityCodeAndActionCodeFromPath } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { getMenuNameByMenuCode } from '../../SideMenuBar/SideBarHelper';

export const NoRouteMatch = (props: {message?: string}) => {
  const path = window.location.hash.replace('#', "");
  const {entityCode, actionCode} = getEntityCodeAndActionCodeFromPath(path);
  let message = props?.message || 'Page Not found';
  if (actionCode) {
    const singleMenuName = getMenuNameByMenuCode(actionCode);
    message = `You doesn’t have access of ${singleMenuName}, please contact Business Owner`
  }

  return (
    <View>
      <Center>
        <Text style={styles.container}>{message}</Text>
      </Center>
    </View>
  );
};

export default NoRouteMatch;
