import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles/Colors';

export const styles = StyleSheet.create({
  tagListItemFirstItem: {
    paddingTop: 16,
  },
  tagListItemLastItem: {
    paddingBottom: 16,
  },
  tagListItem: {
    paddingTop: 6,
    paddingRight: 20,
    paddingBottom: 6,
    marginLeft: 16,
    maxHeight: 200,
    cursor: 'pointer',
  },
  mb: {
    marginBottom: 4,
  },
  justifyCenter: {
    justifyContent: 'center',
  },

  alignCenter: {
    alignItems: 'center',
  },

  btPadding: {
    paddingTop: 2,
    paddingBottom: 2,
  },

  emailModal: {
    minWidth: 400,
    minHeight: 100,
  },

  topContainer: {
    backgroundColor: 'white',
    paddingTop: 8,
    paddingRight: 0,
    paddingLeft: 24,
    paddingBottom: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  left: {
    paddingRight: 16,
    color: Colors.Custom.TitleColor,
  },

  leftText: {
    fontSize: 14,
    justifyContent: 'center',
    marginRight: 10,
  },

  topIcons: {
    marginLeft: 5,
    marginRight: 5,
  },

  leftStack: {
    padding: 20,
    borderRightWidth: 1,
    borderRightColor: Colors.Custom.BorderColor,
  },

  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.BorderColor,
  },

  blStack: {
    borderTopWidth: 1,
    borderTopColor: Colors.Custom.BorderColor,
    paddingTop: 20,
    marginTop: -20,
  },

  tagList: {
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },

  tagCount: {
    color: Colors.Custom.DataKeyColor,
    fontSize: 12,
    marginTop: 2,
  },

  selectScreenTwo: {
    maxWidth: 300,
  },

  boxesStack: {
    marginTop: '24vh',
    justifyContent: 'center',
  },

  templateHelper: {
    fontSize: 11,
    color: Colors.Custom.DataKeyColor,
  },

  boxes: {
    width: 200,
    height: 200,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
  },
});
