import React from 'react';
import { Svg, Path } from "react-native-svg";

const FormSvg = () => {
  return (
    <Svg
      width="12"
      height="14"
      viewBox="0 0 10 12"
      fill="none"
    >
      <Path
        d="M6.05561 0.722229H1.83339C1.55344 0.722229 1.28495 0.833439 1.087 1.03139C0.889042 1.22935 0.777832 1.49783 0.777832 1.77778V10.2222C0.777832 10.5022 0.889042 10.7707 1.087 10.9686C1.28495 11.1666 1.55344 11.2778 1.83339 11.2778H8.16672C8.44667 11.2778 8.71516 11.1666 8.91311 10.9686C9.11107 10.7707 9.22228 10.5022 9.22228 10.2222V3.8889M6.05561 0.722229L9.22228 3.8889M6.05561 0.722229V3.8889H9.22228M7.11117 6.52778H2.88894M7.11117 8.6389H2.88894M3.9445 4.41667H2.88894"
        stroke="#667085"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default FormSvg;
