import React from 'react';
import {
  View,
  Pressable,
  Text
} from 'native-base';
import {IContact} from '../../../../../TeamInbox/Conversations/interfaces';
import {IContactDrillDownDataList} from '../../../interface';
import {Colors} from '../../../../../../../styles/Colors';
import MemberInfoListItem from '../../../../../../common/MemberInfoListItem/MemberInfoListItem';
import {isAccountConfigEnabled} from '../../../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../../../constants/AccountConfigConst';
const SingleContactColumnView = (props: {
  contactData: IContact;
  contactDrillDownData: IContactDrillDownDataList,
  onViewDetailsClick?: (selectedContact: IContact, isViewDetails: boolean) => void;
}) => {
  const {contactDrillDownData, onViewDetailsClick} = props;
  const hintMembershipDisabled = isAccountConfigEnabled(CONFIG_CODES.CONTACT_MEMBERSHIP_DISABLED)
  const drillDownColumnList = contactDrillDownData?.columnList?.length
    ? contactDrillDownData?.columnList
    : [];
  const previousColumnList = contactDrillDownData?.previousColumnList?.length
    ? contactDrillDownData?.previousColumnList
    : [];
  const contactData: any = props.contactData;
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <View>
        <Pressable
          style={{paddingHorizontal: 3, paddingVertical: 4}}
          onPress={() => {
            if (onViewDetailsClick) {
              onViewDetailsClick(contactData, false);
            }
          }} >
          <MemberInfoListItem
            contactData={contactData as IContact}
            showEmail={false}
            showPhoneNumber={false}
            contactNameMaxWidth={"200px"}
            isNameTruncate={true}
            showHintMembershipInfo={!hintMembershipDisabled}
            hideFoldStatus={true}
            showDateOfBirth={true}
          />
        </Pressable>
      </View>
    </View>
  );
}
export default React.memo(SingleContactColumnView);
