import {useLazyQuery} from '@apollo/client';
import {Drawer} from 'antd';
import {HStack, Skeleton, Text, View, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {GetSubdomainMapAdditionalAttributes} from '../../../../services/AccountSubdomain/AccountSubdomainQuery';
import {Colors} from '../../../../styles';
import {getAccountId, isEmptyObject} from '../../../../utils/commonUtils';
import {ModalActionTitle} from '../../ModalActionTitle/ModalActionTitle';
import {QRCodeView} from '../../QRCodeView';
import {
  AccountSubdomainMap,
  AppQRCodeDrawerProps,
  AppQRCodeDrawerState,
} from './interfaces';

const PROVIDER_APP_URL =
  'https://apps.apple.com/us/app/fold-health/id1626246460';

const AppQRCodeDrawer = (props: AppQRCodeDrawerProps) => {
  const [state, setState] = useState<AppQRCodeDrawerState>({
    loading: false,
    patientAppUrl: '',
    providerAppUrl: '',
  });

  const accountId = getAccountId();

  const [getSubdomainMapAdditionalAttributes] = useLazyQuery(
    GetSubdomainMapAdditionalAttributes,
    {
      variables: {
        accountId,
      },
    }
  );

  const {isOpen, onCompleteAction, isMemberApp, isProviderApp} = props;

  const handleOnClose = () => {
    onCompleteAction();
  };

  const getAppUrls = (
    data: AccountSubdomainMap[]
  ): {
    patientAppUrl: string;
    providerAppUrl: string;
  } => {
    if (data.length == 0) {
      return {
        patientAppUrl: '',
        providerAppUrl: '',
      };
    }
    const additionalAttributes = data[0].additionalAttributes || {};
    if (isEmptyObject(additionalAttributes)) {
      return {
        patientAppUrl: '',
        providerAppUrl: PROVIDER_APP_URL,
      };
    }

    const patientAppUrl = additionalAttributes.patientApp || '';
    const providerAppUrl = additionalAttributes.providerApp || PROVIDER_APP_URL;

    return {
      patientAppUrl,
      providerAppUrl,
    };
  };

  const getUrls = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const response = await getSubdomainMapAdditionalAttributes();
      const data: AccountSubdomainMap[] =
        response.data.accountSubdomainMaps || [];
      const {patientAppUrl, providerAppUrl} = getAppUrls(data);
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          patientAppUrl,
          providerAppUrl,
        };
      });
    } catch (error) {

      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  useEffect(() => {
    getUrls();
  }, []);

  const getTitle = () => {
    if (isMemberApp) {
      return 'Login To Branded Fold Patient App';
    }
    return 'Login To Fold Provider App';
  };

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        handleOnClose();
      }}
      width="50vw"
      closable={false}
      title={<ModalActionTitle text={getTitle()} />}
    >
      <HStack
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          paddingVertical: 12,
        }}
      >
        <Text
          style={{
            color: Colors.primary[400],
            fontSize: 20,
            lineHeight: 32,
          }}
        >
          Please scan the QR code below with your mobile phone to download the
          app
        </Text>
      </HStack>

      {state.loading ? (
        <Skeleton.Text lines={4} />
      ) : (
        <VStack
          style={{padding: 20, alignItems: 'center', justifyContent: 'center'}}
        >
          <View>
            {state.providerAppUrl && isProviderApp && (
              <QRCodeView url={state.providerAppUrl} />
            )}

            {state.patientAppUrl && isMemberApp && (
              <QRCodeView url={state.patientAppUrl} />
            )}
          </View>
        </VStack>
      )}
    </Drawer>
  );
};

export default AppQRCodeDrawer;
