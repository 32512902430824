import React from 'react';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Label,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts';
import AxisLabel from '../AxisLabel';
import useBoxPlot from '../../hooks/useBoxPlot';

const dataMock = [
  {
    min: 72.02244792917108,
    first: 91.76573773874154,
    second: 104.26900431298526,
    third: 151.9361969446785,
    fourth: 196.3526020508382,
    target: 101,
  },
];
export interface DataProps {
  min: number;
  first: number;
  second: number;
  third: number;
  fourth: number;
  target: number;
}

export interface IBarChartProps {
  width?: string;
  height?: string;
  color?: string;
  data?: any;
  id?: string;
}

const renderLegend = (props: any) => {
  const {payload} = props;
  return (
    <ul
      style={{
        textAlign: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1.75rem',
        width: '100%',
      }}
    >
      <li style={{color: '#53389E', fontSize: '16px'}}>
        <span style={{color: '#666'}}>Target</span>
      </li>
      {payload
        .filter((item: any) => item.dataKey !== 'min')
        .map((entry: any, index: number) => (
          <li
            key={`item-${index}-entry`}
            style={{color: entry.color, fontSize: '16px'}}
          >
            <span style={{color: '#666'}}>{entry.value}</span>
          </li>
        ))}
    </ul>
  );
};

export default function BarChartGraph({
  width,
  height,
  color,
  data,
  id,
}: {
  width?: string;
  height?: string;
  color: string;
  data: Array<DataProps> | any;
  id: string;
}) {
  const newData = useBoxPlot(dataMock);
  const target = newData[0].target;
  const max = newData[0].max;

  return (
    <ResponsiveContainer width="100%" height={450}>
      <ComposedChart
        width={500}
        height={300}
        data={newData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <Legend content={renderLegend} verticalAlign="top" height={45} />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          label={
            <AxisLabel x={12} y={225} width={0} height={0}>
              Glucose (mg/dL)
            </AxisLabel>
          }
          domain={[0, max]}
          allowDataOverflow={true}
        />
        <Bar stackId="stack" dataKey="min" fill="transparent" />
        <Bar
          maxBarSize={96}
          dataKey="first"
          name="Bottom 25%"
          fill="#6941C6"
          stackId="stack"
        />

        <Bar
          maxBarSize={96}
          dataKey="second"
          name="Second Quartile"
          fill="#9E77ED"
          stackId="stack"
        />
        <Bar
          maxBarSize={96}
          dataKey="third"
          name="Third Quartile"
          fill="#D6BBFB"
          stackId="stack"
        />
        <Bar
          radius={[8, 8, 0, 0]}
          maxBarSize={96}
          dataKey="fourth"
          name="Top 25%"
          fill="#F4EBFF"
          stackId="stack"
          style={{marginBottom: ''}}
        />

        <ReferenceLine
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            paddingTop: '1rem',
          }}
          y={target}
          stroke="#000"
        >
          <Label
            position="insideTopRight"
            value={`Target: Glucose below ${target} mg/dL`}
            fill="#53389E"
          />
        </ReferenceLine>
      </ComposedChart>
    </ResponsiveContainer>
  );
}
