import { StyleSheet } from 'react-native';
import { Colors } from '../../../styles';

const borderColor = '#D0D0D0';

export const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: borderColor,
    borderRadius: 20,
    overflow: 'hidden',
    backgroundColor: Colors.Custom.BackgroundColor,
    marginVertical: 0,
    minWidth: 400,
    padding: 18,
  },
  titleContainer: {
    paddingBottom: 18,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
  },
  titleText: {
    fontWeight: '600',
    fontSize: 18,
    lineHeight: 23,
  },

  descriptionText: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 20,
    color: '#989898',
  },

  levelContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    // padding: 12,
    paddingVertical: 18,
    borderBottomColor: '#CECECE',
    borderBottomWidth: 1,
  },
  levelText: {
    textTransform: 'uppercase',
  },
  listContainer: {
    // padding: 12,
  },
  // list item styles
  itemContainer: {
    borderBottomColor: '#D0D0D0',
    //borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    //marginVertical: 6,
    height: '80px',
  },
  actionItemText: {
    fontSize: 16,
    fontWeight: '400',
    //lineHeight: 20,
  },
  tryText: {
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 20,
    color: '#6941C7'
  },
  actionItemSubText: {
    fontSize: 12,
    fontWeight: '400',
    color: '#A9A9A9',
    paddingLeft: 8,
  },
});
