import { Divider, Drawer, Skeleton, Table } from "antd";
import { VStack, Text, View, HStack, Input, FlatList, Spacer, Box, useMediaQuery } from "native-base";
import { ModalActionTitle } from "../ModalActionTitle/ModalActionTitle";
import { BUTTON_TYPE, CALENDAR_WIDGET_DATE_FORMATS, DATE_FORMATS, GROUP_MEMBER_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH } from "../../../constants";
import { Colors } from "../../../styles/Colors";
import { useEffect, useRef, useState } from "react";
import { IAppointmentAggregate, IAppointmentDetail, IAppointmentMapVal } from "./CalendarWidgetInterfaces";
import moment from "moment";
import { ILocation, IUser } from "../../../Interfaces";
import './CalenderTable.css';
import { DisplayCardAvatar } from "../DisplayCard/DisplayCardAvatar";
import { Dimensions } from "react-native";
import { FilterWrapperComponent } from "../CareDashboard/CareDashboardTopBar/FilterWrapperComponent";
import LocationAutoComplete, { ILocationSearch, autoCompleteTypeCodes } from "./LocationAutoComplete/LocationAutoComplete";
import _, { debounce } from "lodash";
import { AppointmentQueries } from "../../../services";
import { useLazyQuery } from "@apollo/client";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../constants/Configs";
import { getCurrentTimeZone, getDateStrFromFormat, getListOfDatesWithRange, getMomentObjFromDateStrAndFormat } from "../../../utils/DateUtils";
import { TestIdentifiers, testID } from "../../../testUtils";
import { Content } from "antd/lib/layout/layout";

interface TableWidth {
  tableOuterWidth?: number;
  tableInnerwidthExceptFirstColumn?: number;
}

const CalenderMemberViewDrawer = (props: {
  selectedUsers: IUser[],
  selectedLocations: ILocation[];
  accountLocations: ILocation[],
  selectedDate: string;
  selectedAppointmentTypes?: string[];
  selectedAppointmentStatus?: string[];
  onClose: () => void;
}) => {
  const [inputText, setInputText] = useState<string>('');
  const [selectedLocations, setSelectedLocations] = useState<ILocation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [tableSource, setTableSource] = useState<{
    columns: any[];
    dataSource: any[];
    originalDataSource: any[];
    datesRange: string[]
  }>({
    columns: [],
    dataSource: [],
    originalDataSource: [],
    datesRange: []
  });
  const {height} = Dimensions.get('window');
  const finalHeight = height - 200;
  const numberOfColumns = tableSource?.datesRange?.length;
  const tableWidth: TableWidth = {};

  const getTableWidth = () => {
    const firstColumnWidth = 350
    const columnOtherThanFirstWidth = 162
    tableWidth.tableOuterWidth = firstColumnWidth + (columnOtherThanFirstWidth * numberOfColumns)
    tableWidth.tableInnerwidthExceptFirstColumn = columnOtherThanFirstWidth * numberOfColumns;
    return tableWidth;
  };
  const { tableOuterWidth, tableInnerwidthExceptFirstColumn } = getTableWidth();
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);
  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const screenHeight = isSmallScreen ? 4 : 5

  // APIs
  const [getAppointmentAggregate, getAppointmentAggregateIsLoading] =
    useLazyQuery(AppointmentQueries.GET_APPOINTMENT_AGGREGATE, {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    });

  const formatAppointmentsForTable = (
    appointments: IAppointmentAggregate[],
    calendarRange: string[]
  ) => {
    const userWiseAppointmentMap: Record<
      string,
      Record<string, IAppointmentMapVal>
    > = {};
    const datesRange: string[] = calendarRange;
    appointments.forEach((appointment: IAppointmentAggregate) => {
      const startDateStr = appointment.date;
      const userId = appointment.userId;
      const participantUser = props.selectedUsers.find(
        (item) => item.uuid == userId
      );
      const locationId = appointment.accountLocationId || 'Virtual';
      const totalAppointments =
        (appointment.aggregate?.appointments || 0) +
        (appointment.aggregate?.groupAppointments || 0);

      const dateVal = userWiseAppointmentMap[startDateStr];
      if (dateVal) {
        const appointmentVal = dateVal[userId];
        if (appointmentVal) {
          appointmentVal.appointmentDetails.push(appointment);
          appointmentVal.total =
            appointmentVal.total + (totalAppointments || 0);
          if (appointmentVal.locationMap[locationId]) {
            appointmentVal.locationMap[locationId] =
              appointmentVal.locationMap[locationId] + (totalAppointments || 0);
          } else {
            appointmentVal.locationMap[locationId] = totalAppointments || 0;
          }
        } else {
          dateVal[userId] = {
            locationMap: {
              [locationId]: totalAppointments || 0,
            },
            total: totalAppointments || 0,
            appointmentDetails: [appointment],
            user: participantUser,
          };
        }
      } else {
        userWiseAppointmentMap[startDateStr] = {
          [userId]: {
            locationMap: {
              [locationId]: totalAppointments || 0,
            },
            total: totalAppointments || 0,
            appointmentDetails: [appointment],
            user: participantUser,
          },
        };
      }
    });
    const columns: any[] = datesRange.map((item: string, ind) => {
      const isToday = moment(item).isSame(moment(), 'day');
      return {
        title: (
          <VStack
            alignItems={'center'}
            justifyContent={'center'}
            style={{
              paddingHorizontal: 16,
              paddingVertical: 10,
              backgroundColor: isToday ? '#EBE8F2' : Colors.Custom.Gray50,
              borderTopColor: isToday
                ? Colors.Custom.PurpleColor
                : Colors.Custom.Gray50,
              borderTopWidth: 4,
            }}
          >
            <Text fontSize={14} fontWeight={600}>
              {`${moment(item).format('ddd • DD MMM')}`}
            </Text>
          </VStack>
        ),
        key: item,
        width: 160,
        sorter: false,
        ellipsis: true,
        render: (
          text: any,
          selectedRecord: IAppointmentMapVal[]
        ): JSX.Element => {
          const index = ind + 1;
          if (!selectedRecord?.[index]) {
            return <></>;
          }
          if (!selectedRecord?.[index]?.total) {
            return (
              <HStack
                style={{
                  padding: 12,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Text
                  color={Colors.Custom.Gray400}
                  fontSize={12}
                  textAlign={'center'}
                  width={'100%'}
                >
                  --
                </Text>
              </HStack>
            );
          }
          return (
            <VStack
              style={{
                padding: 12,
              }}
            >
              <Text
                style={{
                  fontSize: 24,
                }}
              >
                {selectedRecord?.[index]?.total}
              </Text>
              {Object.keys(selectedRecord?.[index]?.locationMap)?.map(
                (innerItem) => {
                  const locationDetail = props.accountLocations.find(
                    (subitem) => subitem.uuid == innerItem
                  );
                  const total = selectedRecord?.[index]?.locationMap[innerItem] || 0;
                  if (!total) {
                    return null;
                  }
                  return (
                    <HStack
                      style={{
                        marginBottom: 6,
                      }}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          color: Colors.Custom.Gray400,
                        }}
                      >
                        {locationDetail?.name || 'Virtual'}
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          color: Colors.Custom.Gray700,
                        }}
                      >
                        {total}
                      </Text>
                    </HStack>
                  );
                }
              )}
            </VStack>
          );
        },
      };
    });
    columns.unshift({
      title: (
        <VStack
          alignItems={'center'}
          justifyContent={'center'}
          style={{
            paddingHorizontal: 16,
            paddingVertical: 10,
            backgroundColor: Colors.Custom.Gray50,
            borderColor: Colors.Custom.Gray50,
            borderTopWidth: 4,
          }}
        >
          <Text fontSize={14} fontWeight={600}>
            Staff Name
          </Text>
        </VStack>
      ),
      key: 'users',
      width: 275,
      fixed: 'left',
      sorter: false,
      ellipsis: true,
      render: (text: any, selectedRecord: any): JSX.Element => {
        return (
          <VStack
            style={{
              padding: 12,
              backgroundColor: '#ffffff',
            }}
          >
            <HStack
              width={'100%'}
              alignItems="flex-start"
              paddingY={2}
              paddingX={4}
            >
              <View>
                <DisplayCardAvatar
                  avatarStyle={{
                    avatarSize: '12',
                    width: 48,
                    height: 48,
                  }}
                  userData={{
                    userType: GROUP_MEMBER_TYPE.USER,
                    userId: selectedRecord?.[0]?.id,
                    name: selectedRecord?.[0]?.name,
                    userName: selectedRecord?.[0]?.name,
                    imgSrc: '',
                  }}
                  isLetterAvatarShow
                />
              </View>
              <VStack
                alignSelf="stretch"
                flex={1}
                marginLeft={2}
                justifyContent={'flex-start'}
              >
                <Text fontWeight={'bold'} fontSize={16}>
                  {selectedRecord?.[0]?.name}
                </Text>
                <Text color={Colors?.Custom?.Gray500} numberOfLines={2} textOverflow={'ellipsis'}>
                  {selectedRecord?.[0]?.userRoles
                    ?.map((role: any) => role?.userRole?.userRole?.value)
                    ?.join(', ')}
                </Text>
              </VStack>
            </HStack>
          </VStack>
        );
      },
    });

    const dataSource = props.selectedUsers
      ?.filter((item) =>
        item.name?.toLocaleLowerCase()?.includes(inputText?.toLocaleLowerCase())
          ? true
          : false
      )
      ?.map((item) => {
        const appointmentData: any = datesRange.map(
          (date) =>
            userWiseAppointmentMap?.[date]?.[item.uuid] || {
              locationMap: {},
              appointmentDetails: [],
              total: 0,
              user: item,
            }
        );
        appointmentData?.unshift(item);
        return appointmentData;
      });

    setTableSource((prev) => {
      return {
        ...prev,
        columns: columns,
        dataSource: dataSource,
        originalDataSource: dataSource,
      };
    }
    );
    setSelectedLocations(props.selectedLocations || []);
  };

  const fetchAppointmentDetails = async () => {
    setLoading(true);
    const dateRange = {
      startDate: getMomentObjFromDateStrAndFormat(
        props.selectedDate,
        'YYYY-MM-DD'
      )
        .startOf('month')
        .toISOString(),
      endDate: getMomentObjFromDateStrAndFormat(
        props.selectedDate,
        'YYYY-MM-DD'
      )
        .endOf('month')
        .toISOString(),
    };
    const calendarRange: string[] = getListOfDatesWithRange(
      dateRange.startDate,
      dateRange.endDate,
      'YYYY-MM-DD'
    );
    setTableSource((prev) => {
      return {
        ...prev,
        datesRange: calendarRange
      };
    })

    const userDateLocationParam = {
      startDateTime: dateRange.startDate, // mandatory
      endDateTime: dateRange.endDate, // mandatory
      userIds: props.selectedUsers.map((item) => item.uuid), // list of users is mandatory
      timezone: getCurrentTimeZone(), // mandatory
      // ...(selectedLocations.length && {
      //   accountLocationIds: selectedLocations.map((location) => location.uuid),
      // }), // do not send if not required
      // ...(props.selectedAppointmentTypes?.length && {
      //   appointmentTypeIds: props.selectedAppointmentTypes,
      // }), // do not send if not required
      // ...(props.selectedAppointmentStatus?.length && {
      //   statusIds: props.selectedAppointmentStatus,
      // }), // do not send if not required
      groupBy: 'userDateLocation', // possible values - 'date' | 'userDateLocation'
    };

    try {
      await getAppointmentAggregate({
        variables: {
          params: userDateLocationParam,
        },
      }).then((resp) => {
        const appointments = resp?.data?.appointments || [];
        formatAppointmentsForTable(appointments, calendarRange);
        setLoading(false);
      });
    } catch (error) {

      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppointmentDetails();
  }, []);

  useEffect(() => {
    if (tableSource.originalDataSource?.length) {
      const originalDataSourceCopy = _.cloneDeep(
        tableSource.originalDataSource
      );
      const filteredData = originalDataSourceCopy
        ?.filter((item) => {
          const userName = item[0]?.name?.toLowerCase();
          const inputTextLwr = inputText?.toLowerCase();
          return userName?.includes(inputTextLwr) ? true : false;
        })
        .map((item) => {
          return item.map((subitem: any) => {
            if (subitem?.locationMap && selectedLocations.length) {
              const oldMap = _.cloneDeep(subitem?.locationMap || {});
              subitem.locationMap = {};
              subitem.total = 0;
              selectedLocations.forEach((location) => {
                const total = oldMap[location.uuid] || 0;
                subitem.locationMap[location.uuid] = total;
                subitem.total += total;
              });
            }
            return subitem;
          });
        });
      setTableSource((prev) => {
        return {
          ...prev,
          dataSource: filteredData,
        };
      });
    }
  }, [inputText, selectedLocations]);

  const getFirstColumnData = ():IUser[] => {
    const firstcolumnData = tableSource.dataSource.map((item:any[]) => item?.[0])
    return firstcolumnData;
  }

  return (
    <Drawer
      destroyOnClose
      placement="right"
      open={true}
      closable
      width={'75%'}
      bodyStyle={{
        paddingTop: 0,
        paddingBottom: 0,
      }}
      title={
        <ModalActionTitle
          title={'providerSummary'}
          titleColor={''}
          buttonList={[
            {
              id: 1,
              show: true,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                props.onClose();
              },
            },
          ]}
        />
      }
    >
      {loading && (
        <View height={654} {...testID(TestIdentifiers.pageLoading)}>
          <Skeleton active />
        </View>
      )}
      {!loading && (
        <>
          {/* <HStack
            space={2}
            alignItems={'center'}
            style={{
              paddingBottom: 10,
            }}
          >
            <Input
              width={200}
              onChangeText={debounce((text) => setInputText(text?.trim()), 500)}
              placeholder="Search Staff"
            />
            <FilterWrapperComponent
              label={!selectedLocations?.length ? 'Location' : undefined}
              value={selectedLocations?.map((item) => item.name)}
              textWeight={400}
            >
              <LocationAutoComplete
                selectedLocationUUIDList={selectedLocations?.map(
                  (item) => item.uuid
                )}
                allowClear={false}
                onChange={(locations: any) => {
                  setSelectedLocations(props.accountLocations.filter((location) => locations?.includes(location.uuid)));
                }}
                accountLocations={props.accountLocations}
                autoCompleteType={autoCompleteTypeCodes.INPUT_CHECKBOX_OPTIONS}
              />
            </FilterWrapperComponent>
          </HStack> */}
          <div className="table-scroll new-ui-table-scroll" style={{ height: tableSource.dataSource?.length > screenHeight ? window.innerHeight - 100 : 'auto'}}>
            <HStack borderWidth={1} borderColor={Colors.Custom.Gray200} width={tableOuterWidth || 5050}>
              <VStack
                alignItems={'center'}
                position={"sticky"}
                left={0}
                style={{
                  backgroundColor: Colors.Custom.Gray50,
                  borderColor: Colors.Custom.Gray50,
                  borderTopWidth: 4,
                  width: 350,
                  zIndex: 1,
                }}
                borderWidth={1}
              >
                <Content
                  style={{
                    backgroundColor: Colors.Custom.Gray50, width: '100%', display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: 1,
                    borderColor: Colors.Custom.Gray200,
                    padding: 11,
                  }}
                  className="date-list-header"
                >
                  <Text fontSize={14} fontWeight={600}>
                    Staff Name
                  </Text>
                </Content>
                <FlatList
                  style={{ width: '100%' }}
                  data={getFirstColumnData()}
                  nativeID="first-list-column"
                  keyExtractor={(item: IUser, index: number) => {
                    return 'UserList_' + `${item.id}_` + `${index}` ;
                  }}
                  renderItem={({ item }) =>
                    <VStack
                      style={{
                        backgroundColor: Colors.Custom.MonochromeWhite,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 150
                      }}
                      borderWidth={1}
                      borderColor={Colors.Custom.Gray200}
                    >
                      <HStack
                        width={'100%'}
                        height={100}
                        marginLeft={5}
                      >
                        <View>
                          <DisplayCardAvatar
                            avatarStyle={{
                              avatarSize: '12',
                              width: 48,
                              height: 48,
                            }}
                            userData={{
                              userType: GROUP_MEMBER_TYPE.USER,
                              userId: item?.id,
                              name: item?.name,
                              userName: item?.name,
                              imgSrc: '',
                            }}
                            isLetterAvatarShow
                          />
                        </View>
                        <VStack
                          alignSelf="stretch"
                          flex={1}
                          marginLeft={2}
                          justifyContent={'flex-start'}
                        >
                          <Text fontWeight={'bold'} fontSize={16}>
                            {item?.name}
                          </Text>
                          <Text color={Colors?.Custom?.Gray500} numberOfLines={2} textOverflow={'ellipsis'}>
                            {item?.userRoles
                              ?.map((role: any) => role?.userRole?.userRole?.value)
                              ?.join(', ')}
                          </Text>
                        </VStack>
                      </HStack>
                    </VStack>
                  }
                />
              </VStack>
              <VStack style={{ width: tableInnerwidthExceptFirstColumn || 4700, position: 'relative', height: '100%', borderColor: Colors.Custom.Gray200 }} >
                <FlatList
                  data={tableSource.datesRange}
                  horizontal
                  keyExtractor={(item: string, index: number) => {
                    return 'Date_' + `${item}_` + `${index}` ;
                  }}
                  nativeID="date-list-header"
                  scrollEnabled={false}
                  renderItem={(item: any) => {
                    const isToday = moment(item.item).isSame(moment(), 'day');
                    return (
                      <HStack
                       borderWidth={1}
                       width={162}
                       borderColor={Colors.Custom.Gray200}
                       backgroundColor={isToday ? Colors.Custom.LightPurpleColor : Colors.Custom.Gray50}
                       borderTopColor={isToday
                        ? Colors.Custom.PurpleColor
                        : Colors.Custom.Gray50}
                       borderTopWidth={isToday ? 4 : 0}
                       height={49}
                        >
                        <VStack
                          style={{
                            paddingVertical: 11,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Text fontSize={14} fontWeight={600} marginRight={10} marginLeft={10}>
                            {`${getDateStrFromFormat(item.item,DATE_FORMATS.CALENDAR_WIDGET_DATE_FORMAT)}`}
                          </Text>
                        </VStack>
                      </HStack>
                    )
                  }}
                />
                <FlatList
                  data={tableSource.dataSource}
                  nativeID="columns-after-first"
                  keyExtractor={(item) => item?.[0]?.id}
                  renderItem={(outerItem: any) =>
                    <FlatList
                      horizontal={true}
                      data={outerItem.item}
                      scrollEnabled={false}
                      renderItem={(innerItem: any) => {
                        if (!innerItem?.item?.locationMap) {
                          return <></>
                        }
                        if (!innerItem?.item?.total) {
                          return (
                            <HStack
                              style={{
                                alignItems: 'center',
                                width: 162,
                                height: 150,
                                borderWidth: 1,
                                borderColor: Colors.Custom.Gray200
                              }}
                            >
                              <Text
                                color={Colors.Custom.Gray400}
                                fontSize={12}
                                textAlign={'center'}
                                width={'100%'}
                              >
                                --
                              </Text>
                            </HStack>
                          );
                        }
                        return (
                          <VStack
                            style={{
                              borderColor: Colors.Custom.Gray200,
                              borderWidth: 1,
                              width: 162,
                              height: 150,
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 24,
                                marginLeft: 6,
                                paddingLeft: 8,
                              }}
                            >
                              {innerItem?.item?.total}
                            </Text>
                            {innerItem.item.locationMap && Object.entries(innerItem.item.locationMap).map(
                              ([locationId, count]: [string, any]) => {
                                const locationDetail = props.accountLocations.find(
                                  (subitem) => subitem.uuid === locationId
                                );
                                const total = innerItem.item.locationMap[locationId] || 0;
                                if (!total) {
                                  return null;
                                }
                                return (
                                  <HStack
                                    style={{
                                      marginBottom: 6,
                                      paddingLeft: 8,
                                      paddingRight: 8
                                    }}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 12,
                                        color: Colors.Custom.Gray400,
                                        marginLeft: 6
                                      }}
                                    >
                                      {locationDetail?.name || 'Virtual'}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 12,
                                        color: Colors.Custom.Gray700,
                                        marginRight: 6
                                      }}
                                    >
                                      {total}
                                    </Text>
                                  </HStack>
                                );
                              }
                            )}
                          </VStack>
                        )
                      }
                      }
                      keyExtractor={(index: number) => {
                        return 'LocationList_' + `${index}_`
                      }}
                    />
                  }
                />
              </VStack>
            </HStack>
          </div>
        </>
      )}
    </Drawer>
  );
}

export default CalenderMemberViewDrawer;
