import { GROUP_TYPE_CODES } from './../../../../../../../constants/MlovConst';
import {CHANNEL_TYPE_CODE} from "../../../ConversationConst";

export const ALLOWED_GROUP_TYPE = {
  [CHANNEL_TYPE_CODE.CHANNEL_ALL]: [
    GROUP_TYPE_CODES.PRIVATE,
    GROUP_TYPE_CODES.PATIENT_BROADCAST
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET]: [
    GROUP_TYPE_CODES.PRIVATE,
    GROUP_TYPE_CODES.PATIENT_BROADCAST
  ],
  [CHANNEL_TYPE_CODE.CHANNEL_INTERNAL]: [
    GROUP_TYPE_CODES.INTERNAL,
  ]
}
