import { notification, Checkbox } from 'antd'
import React, { useContext, useState } from 'react'
import { View, Text, Pressable, StyleSheet } from 'react-native'
import Stack from '../../../common/LayoutComponents/Stack'
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton'
import { Colors } from '../../../../styles'
import './MultiSelectView.css';
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces'
import BulkTaskEditView from './BulkTaskEditView'
import { EventBus } from '../../../../utils/EventBus'
import { TASK_EVENTS } from '../../../common/CareDashboard/CareDashboardConstants'
import { MultiSelectAction } from '../../TaskInterfaces'
import Close from '../../../../assets/svg/Close'
import BulkTaskDeleteView from './BulkTaskDeleteView'
import { useIntl } from 'react-intl'
import { CommonDataContext } from '../../../../context/CommonDataContext'

interface IMultiSelectView {
  isVisible: boolean;
  selectedTasks: ITask[];
  onTaskRemoved?: (task: ITask) => void;
}

enum BulkAction {
  edit = 'EDIT',
  delete = 'DELETE'
}

const MultiSelectView = (props: IMultiSelectView) => {
  const { isVisible, selectedTasks, onTaskRemoved } = props;
  const contextData = useContext(CommonDataContext);
  const isSidecar = contextData.sidecarContext?.isSidecar;
  const eventBus = EventBus.getEventBusInstance();
  const intl = useIntl();

  const [componentState, setComponentState] = useState<{
    selectedAction?: BulkAction,
  }>({});

  const closeBulkAction = () => {
    eventBus.broadcastEvent(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, { action: MultiSelectAction.disableMultiSelect })
  }

  const showSelectedTasksError = React.useCallback(() => {
    notification.error({
      message: 'Please select tasks',
      duration: 3.0,
      placement: 'top',
    })
  }, []);

  const bulkTaskEditCancel = React.useCallback(() => {
    setComponentState((prev) => ({...prev, selectedAction: undefined}));
  }, [setComponentState]);

  const bulkTaskDeleteCancel = React.useCallback(() => {
    setComponentState((prev) => ({...prev, selectedAction: undefined}));
  }, [setComponentState]);

  const onEditButtonClick = React.useCallback(() => {
    if (!selectedTasks.length) {
      showSelectedTasksError();
      return;
    }
    setComponentState((prev) => ({...prev, selectedAction: BulkAction.edit}));
  }, [selectedTasks, showSelectedTasksError]);

  const onDeleteButtonClick = React.useCallback(() => {
    if (!selectedTasks.length) {
      showSelectedTasksError();
      return;
    }
    setComponentState((prev) => ({...prev, selectedAction: BulkAction.delete}));
  }, [selectedTasks, showSelectedTasksError]);

  return (
    <View>
      <BulkTaskEditView
        selectedTasks={selectedTasks}
        isVisible={componentState.selectedAction === BulkAction.edit}
        onTaskRemoved={onTaskRemoved}
        onCancel={bulkTaskEditCancel}
      />
      <BulkTaskDeleteView
        selectedTasks={selectedTasks}
        isVisible={componentState.selectedAction === BulkAction.delete}
        onTaskRemoved={onTaskRemoved}
        onCancel={bulkTaskDeleteCancel}
      />
      {isVisible &&
        <div className={isSidecar ? "sidecar-modal-container" : "modal-container"}>
          <Stack direction="row" style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Checkbox checked={selectedTasks.length>0} />
            <Text style={[
              {
                fontSize: 14,
                fontWeight: '400',
                marginLeft: 8,
              },
              { color: selectedTasks.length > 0 ? Colors.Custom.Gray500 : Colors.Custom.Gray300 }
            ]}>
              {selectedTasks.length} selected
            </Text>
            <View style={{
              width: 1,
              height: 24,
              backgroundColor: Colors.Custom.Gray300,
              marginHorizontal: 8,
            }} />
            <Stack direction="row" space={8}>
              <FoldButton
                nativeProps={{
                  variant: 'solid',
                  backgroundColor: selectedTasks.length>0 ? Colors.Custom.Primary300 : Colors.FoldPixel.GRAY50,
                  height: '32px',
                  width: '42px',
                  _text: {
                    color: selectedTasks.length>0 ? Colors.Custom.White : Colors.FoldPixel.GRAY150,
                    textAlign: 'center',
                  },
                  onPress: onEditButtonClick
                }}
                customProps={{
                  btnText: intl.formatMessage({ id: 'edit' }),
                }}
              />
              <FoldButton
                nativeProps={{
                  variant: 'solid',
                  backgroundColor: selectedTasks.length>0 ? Colors.Custom.Primary300 : Colors.FoldPixel.GRAY50,
                  height: '32px',
                  width: '60px',
                  _text: {
                    color: selectedTasks.length>0 ? Colors.Custom.White : Colors.FoldPixel.GRAY150,
                    textAlign: 'center',
                  },
                  onPress: onDeleteButtonClick,
                }}
                customProps={{
                  btnText: intl.formatMessage({ id: 'delete' }),
                }}
              />
            </Stack>
            <View style={{
              width: 1,
              height: 24,
              backgroundColor: Colors.Custom.Gray300,
              marginHorizontal: 8,
            }} />
            <Pressable onPress={closeBulkAction}>
              <Close fill={Colors.Custom.Gray300} size={20} />
            </Pressable>
          </Stack>
        </div>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  stackContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedText: {
    fontSize: 14,
    fontWeight: '400',
    marginLeft: 8,
  },
  divider: {
    width: 1,
    height: 24,
    backgroundColor: Colors.Custom.Gray300,
    marginHorizontal: 8,
  },
});

export default MultiSelectView
