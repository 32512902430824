import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';

export const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: Colors.Custom.BackgroundColor,
  },
  modalFooter: {
    backgroundColor: Colors.Custom.BackgroundColor,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  ml4mr6: {
    marginLeft: 4,
    marginRight: 6,
  },
  fs20fw500: {
    fontSize: 20,
    fontWeight: '500',
  }
});
