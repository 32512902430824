import { Coding } from 'fhir/r4';
import _, {cloneDeep} from 'lodash';
import {IntlShape} from 'react-intl';
import { ADD_NEW_REASON_FOR_VISIT, DATE_FORMATS } from '../../../../../../constants';
import {
  APPOINTMENT_PARTICIPANT_STATUS_CODES,
  APPOINTMENT_STATUS_CODES,
  LOCATION_TYPE_CODES,
  MLOV_CODES,
} from '../../../../../../constants/MlovConst';
import {IMlov, IUser} from '../../../../../../Interfaces';
import {Colors} from '../../../../../../styles/Colors';
import {getPatientName, isVirtualLocationEnabledInAvailability} from '../../../../../../utils/commonUtils';
import {
  DATE_UNIT_CONST,
  addDaysInDate,
  addTimeToDate,
  getCustomDateRangeForAppointments,
  getDateObject,
  getDateStrFromFormat,
  getMomentObj,
  getMomentObjFromDateStrAndFormat,
  isCurrentDateInFutureComparedToOther,
  isPastDateTime,
  isPastDay,
  isTimeBefore,
  isTodayDate,
  isValidDate,
  getDefaultStartDate,
  getDefaultEndDate,
  isDateInNextMonth,
  getCurrentTimeZone,
} from '../../../../../../utils/DateUtils';
import {
  getMlovCodeFromId,
  getMlovIdFromCode,
} from '../../../../../../utils/mlovUtils';
import { defaultAppointmentTypeColor } from '../../../../../RightSideContainer/AccountSettings/AppointmentTypes/AppointmentTypeConst';
import { IAvailableEhr } from '../../../../../PersonOmniView/MiddleContainer/interfaces';
import {
  IAppointmentType,
  IUserGroupData,
} from '../../../../../RightSideContainer/AccountSettings/AppointmentTypes/Interfaces';
import {IFHPopoverAction} from '../../../../FHActionPopover/FHActionPopover';
import {CalendarView} from '../../../CalendarWidgetEnums';
import {
  IAppointmentDetail,
  ICalendarWidgetState,
} from '../../../CalendarWidgetInterfaces';
import {ParticipantType} from '../../../ParticipantAutoComplete/ParticipantEnum';
import {IParticipantSearch} from '../../../ParticipantAutoComplete/ParticipantInterfaces';
import {AppointmentAction, FormStatus} from './AppointmentBookingEnums';
import {
  ExpiryDateTime,
  IAppointmentBookingState,
  IAppointmentDetailState,
  IAppointmentNote,
  IAppointmentTypeWiseUsersMap,
  IAppointmentUserDetail,
  IBookingLinkParams,
  IContact,
  IGroupBookingData,
  IParticipantData,
  IPracticeLocation,
  ISlot,
  IUserPracticeLocation,
  IUserSlots,
} from './AppointmentBookingIntefaces';
import {
  MAX_FUTURE_DATE_RANGE,
  ONE_OFF_VISIT_APPT_CODE,
  ONE_OFF_VISIT_APPT_DURATION,
} from './AppointmentConstant';
import  { Moment } from 'moment';
import { AppointmentAvailabilityCode } from '../../../../../RightSideContainer/AccountSettings/AppointmentTypes/constants';
import moment from 'moment';
import { isWeb } from '../../../../../../utils/platformCheckUtils';
import { CARE_STUDIO_NEST_URL } from '../../../../../../constants/Configs';
import { COMMUNICATION_APP_BASE_URL } from '../../../../../../constants/BaseUrlConst';
import { IReccuringEventAPI, ReccurenceType } from './hooks/useReccuringEvent/interface';
import { IRescheduleData } from './AppointmentBooking';
import { areArraysEqual } from '../../../../../../utils/arrUtils';
import {IUserSettingsByCode} from '../../../../../../services/UserSettings/interfaces';
import {notification} from 'antd';

export const RTE_MODULES = {
  toolbar: [
    [{header: [1, 2, false]}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
    ['link', 'clean'],
  ],
};

export const CONSENT_FORMS = [
  'Practice Policies and Treatment Consent Form',
  'HIPAA Compliance Patient Consent Form',
  'Informed Consent for Telemedicine Services',
  'Client Intake Form',
];
export const TIMESLOT_STEP_IN_MINUTES = 30;
const RSVP_EXPIRY_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const isVirtualAppointmentType = (
  appointmentType: IAppointmentType | undefined,
  selectedLocationType: IMlov | undefined
) => {
  const selectedLocationTypeCode = selectedLocationType?.code;
  if (selectedLocationTypeCode) {
    return selectedLocationTypeCode === 'VIRTUAL';
  } else {
    return appointmentType?.locationType?.code === LOCATION_TYPE_CODES.VIRTUAL;
  }
};

export const isInvalidBooking = (
  data: IAppointmentBookingState,
  reccuringApptDataError: boolean,
  additionalFlags: {
    disAllowVirtualLocation: boolean;
    disallowToScheduleForOtherLocation: boolean;
    isGroupAppointmentEnable?: boolean;
  }
): boolean => {
  const {disAllowVirtualLocation, disallowToScheduleForOtherLocation, isGroupAppointmentEnable} = additionalFlags;
  const isVirtualVisit = isVirtualAppointmentType(
    data.selectedAppointmentType,
    data.selectedLocationType
  );
  const isVirtualLocation = isVirtualVisit && !disAllowVirtualLocation && !disallowToScheduleForOtherLocation;
  // return (
  //   !data.appointmentTypeId ||
  //   !!getParticipantError(data.participants) ||
  //   !data.startDateTime ||
  //   !data.endDateTime ||
  //   !data.name
  // );
  const isValidRSVPExpiryDate = isGroupAppointmentEnable && data.isRsvpExpiryEnabled
    ? !validateExpireDateTime(data.startDateTime,data.expiryDateTimeUnit)
    : true;
  if (
    !isValidRSVPExpiryDate ||
    !data.selectedAppointmentType ||
    data.selectedAppointmentType?.duration === 0 ||
    !data.name ||
    !data.selectedPrimaryUserId ||
    (isGroupAppointmentEnable ? false : !!getParticipantError(data.participants)) ||
    !data.startDateTime ||
    !data.endDateTime ||
    (!data.selectedAccountLocationId && !isVirtualLocation) ||
    !data.duration ||
    (isGroupAppointmentEnable
      ? !data?.description || (!!data?.eventName?.displayName && data?.eventName?.displayName?.trim()?.length > 100)
      : !data?.reasonForVisit || data?.reasonForVisit?.displayName.length === 0) ||
    reccuringApptDataError ||
    !(data.selectedAccountLocationId ||! data.selectedPracticeLocationId ||! data.selectedLocationGroupId)
  ) {
    return true;
  }
  return false;
};

const getParticipantError = (participants: IParticipantSearch[]): string => {
  return participants.length === 0
    ? 'Please select patient to schedule this appointment'
    : '';
};

export const getUpdatedErrorMessages = (
  data: IAppointmentBookingState,
  isGroupAppointmentEnable?: boolean,
): any => {
  return {
    showErrors: true,
    patient: isGroupAppointmentEnable ? '' : getParticipantError(data.participants),
    slots:
      !data.startDateTime || !data.endDateTime
        ? 'You need to select a slot to schedule an appointment'
        : '',
    name: !data.name ? 'Name is required' : '',
    selectedPrimaryUserId: !data.selectedPrimaryUserId
      ? 'Please select user against which appointment can be scheduled'
      : '',
    duration: !data.duration ? 'Duration is required' : '',
    reason:
        !isGroupAppointmentEnable && (!data?.reasonForVisit || data?.reasonForVisit?.displayName.length === 0)
        ? 'Reason for visit is required'
        : '',
    description:
        isGroupAppointmentEnable && (!data?.description || data?.description?.length === 0)
        ? 'Please enter description'
        : '',
    eventName: isGroupAppointmentEnable && (!!data?.eventName?.displayName && data?.eventName?.displayName?.trim()?.length > 100)
      ? 'Event name exceeds 100 characters'
      : '',
    rsvpExpiry: isGroupAppointmentEnable && data.isRsvpExpiryEnabled && validateExpireDateTime(data.startDateTime,data.expiryDateTimeUnit) ?  "RSVP link expiry time must not be in past." : '',
    location: (data.selectedAccountLocationId && data.selectedPracticeLocationId && data.selectedLocationGroupId) ? '' : 'Please select location'
  };
};

export const isInvalidGroupBooking = (
  data: IGroupBookingData,
  slots: IUserSlots[]
): boolean => {
  return data.participants.length === 0 || isSlotError(slots);
};

export const getUpdatedErrorMessagesForGroup = (
  data: IGroupBookingData,
  slots: IUserSlots[]
): any => {
  return {
    patient:
      data.participants.length === 0
        ? 'Please select patient to schedule this appointment'
        : '',
    slots: isSlotError(slots)
      ? 'You need to select a slot for all users to schedule an group appointment'
      : '',
    name: !data.name ? 'Name is required' : '',
  };
};

export const isSlotError = (slots: IUserSlots[]): boolean => {
  let areAllSlotSelected = true;
  slots.forEach((slot: IUserSlots) => {
    areAllSlotSelected = areAllSlotSelected && !!slot.selectedSlot;
  });
  return !areAllSlotSelected;
};

export const getUserIdsToBeFetched = (
  existingUserList: IAppointmentUserDetail[],
  slots: any[]
): string[] => {
  const existingUserIds = existingUserList.map(
    (data: IAppointmentUserDetail): string => {
      return data.uuid;
    }
  );
  const list = slots.map((data: any): string => {
    return data.userId;
  });
  return list.filter((userId: string) => {
    return !existingUserIds.includes(userId);
  });
};

export const removePastSlotTimes = (slotList: any[]): IUserSlots[] => {
  return slotList.map((userSlotData) => {
    return {
      userId: userSlotData.userId,
      duration: userSlotData.duration,
      slots: userSlotData.slots.filter((slot: any) => {
        return isCurrentDateInFutureComparedToOther(
          slot.startDateTime,
          new Date()
        );
      }),
    };
  });
};

export const setUserDetailsToUserSlots = (
  userList: IAppointmentUserDetail[],
  slots: IUserSlots[],
  selectedAppointmentType?: IAppointmentType
) => {
  const participants = selectedAppointmentType?.appointmentTypeGroup;
  slots.forEach((slot: IUserSlots) => {
    const matchedUser = userList.filter((user: IAppointmentUserDetail) => {
      return user.uuid === slot.userId;
    });
    if (matchedUser.length > 0) {
      slot.user = matchedUser[0];
    }
    const matchedParticipant = participants?.filter((user: IUserGroupData) => {
      return user.userId === slot.userId;
    });
    if (matchedParticipant && matchedParticipant.length > 0) {
      slot.duration = matchedParticipant[0].duration;
    }
  });
};

export const getGroupAppointmentBookingData = (
  bookingData: IGroupBookingData,
  slots: IUserSlots[],
  appointmentType?: IAppointmentType
): any[] => {
  const finalData: any[] = [];
  const patientIds: any[] = [];
  bookingData.participants.forEach((participant: any) => {
    if (participant.type === ParticipantType.patient) {
      patientIds.push({
        contactId: participant.value,
      });
    }
  });

  slots.forEach((slot: IUserSlots) => {
    finalData.push({
      name: bookingData.name,
      appointmentTypeId: appointmentType?.id,
      startDateTime: slot.selectedSlot?.startDateTime,
      endDateTime: slot.selectedSlot?.endDateTime,
      participants: [...patientIds, {userId: slot.userId}],
      notes: bookingData.note.length > 0 ? [{content: bookingData.note}] : [],
    });
  });
  return finalData;
};

export const getParticipantsFromAppointmentGroup = (
  participants: IParticipantSearch[],
  userList: IAppointmentUserDetail[],
  appointmentTypeGroup?: IUserGroupData[]
): IParticipantSearch[] => {
  if (appointmentTypeGroup && appointmentTypeGroup.length > 0) {
    appointmentTypeGroup.forEach((group) => {
      const userId = group.userId || group.user?.key;
      if (userId) {
        const filteredData = participants.filter((participant) => {
          return participant.key === userId;
        });
        if (filteredData.length === 0) {
          const matchedData = userList.filter((user) => {
            return user.uuid === userId;
          });
          if (matchedData.length > 0) {
            participants.push({
              value: matchedData[0].uuid,
              label: matchedData[0].name,
              key: matchedData[0].uuid,
              type: ParticipantType.staff,
            });
          }
        }
      }
    });
  }
  return participants;
};

export const getPrimaryAndSecondaryContacts = (data: any, APPOINTMENT_PARTICIPANT_TYPE_IDS:any): any => {
  const userList = data.participants.filter((participant: any) => {
    return (participant.userId || participant.user?.name) ? true : false;
  });

  const primaryUsers = userList.filter((user:any)=>{
    return user.participantTypeId == APPOINTMENT_PARTICIPANT_TYPE_IDS.primaryUser ;
  })

  const secondaryUsers = userList.filter((user:any)=>{
    return user.participantTypeId == APPOINTMENT_PARTICIPANT_TYPE_IDS.secondaryUser ;
  })

  const contactList = data.participants.filter((participant: any) => {
    return (participant.contactId || participant.contact?.name) ? true : false;
  });

  return ({
    userList,
    primaryUsers,
    secondaryUsers,
    contactList
  })
}

export const getPrimaryContactFromAppointment = (data: any): any => {
  let primaryContact: any = undefined;
  const otherParticipants: any[] = [];
  let isPrimaryWithContactId = false;
  if (data.participants.length > 0) {
    const primaryContactList = data.participants.filter((participant: any) => {
      return participant.contact?.contactType?.contactType?.code === 'CUSTOMER';
    });
    if (primaryContactList.length > 0) {
      primaryContact = primaryContactList[0].contact;
      isPrimaryWithContactId = true;
    }

    data.participants.forEach((participant: any) => {
      if (
        participant.contact &&
        participant.contact.uuid !== primaryContact?.uuid
      ) {
        if (primaryContact) {
          otherParticipants.push(cloneDeep(participant.contact));
        } else {
          primaryContact = participant.contact;
          isPrimaryWithContactId = true;
        }
      } else if (
        participant.patient &&
        (!primaryContact?.uuid ||
          primaryContact?.uuid !== participant?.contact?.uuid)
      ) {
        if (primaryContact) {
          otherParticipants.push(cloneDeep(primaryContact));
        }
        primaryContact = participant.patient;
        isPrimaryWithContactId = false;
      } else if (participant.user) {
        otherParticipants.push({...participant.user, participantTypeId: participant.participantTypeId});
      }
    });
  }
  if (!primaryContact && otherParticipants.length > 0) {
    primaryContact = otherParticipants[0];
    otherParticipants.splice(0, 1);
  }
  return {
    primary: primaryContact,
    isPrimaryWithContactId: isPrimaryWithContactId,
    otherParticipants: otherParticipants,
  };
};

export const isCurrentUserAppointmentInitiator = (
  data: any,
  currentUserUuid: string
): any => {
  if (data?.participants?.length) {
    const currentUser = data.participants.find((participant: any) => {
      return participant.user?.uuid === currentUserUuid;
    });

    if (!currentUser || !currentUser.isInitiator) return false;
    return true;
  }
  return false;
};

export const getCurrentUserParticipantStatus = (
  data: any,
  currentUserUuid: string,
  participantStatusMlovList: IMlov[]
): any => {
  if (data.participants.length > 0) {
    const currentUser = data.participants.find((participant: any) => {
      return participant.user?.uuid === currentUserUuid;
    });

    if (!currentUser || !currentUser.statusId) return null;
    return getMlovCodeFromId(participantStatusMlovList, currentUser.statusId);
  }
  return null;
};

export const getAllUserParticipantStatusCodes = (
  data: any,
  participantStatusMlovList: IMlov[]
): string[] => {
  if (data.participants.length > 0) {
    const allParticipantStatusCodes = data.participants.map(
      (participant: any) => {
        return getMlovCodeFromId(
          participantStatusMlovList,
          participant.statusId
        );
      }
    );
    return allParticipantStatusCodes;
  }
  return [];
};

export const getPrimaryContactName = (appointment: any, isGroupAppointment?:boolean): string => {

  if(isGroupAppointment){
    return appointment?.name;
  }

  const participantDetails = getPrimaryContactFromAppointment(appointment);
  if (
    participantDetails &&
    participantDetails.primary &&
    participantDetails.primary.name &&
    participantDetails.primary.contactType
  ) {
    return participantDetails.primary.name;
  } else if (
    participantDetails &&
    participantDetails.primary &&
    participantDetails.primary.name?.length &&
    participantDetails.primary.name[0]?.family &&
    participantDetails.primary.name[0]?.given?.length
  ) {
    return `${participantDetails.primary.name[0]?.given?.[0]} ${participantDetails.primary.name[0]?.family}`;
  } else if (participantDetails?.primary?.name) {
    return participantDetails.primary.name;
  }
  return '';
};

export const getAppointmentUserId = (appointment: IAppointmentDetail) => {
  let userId: string = '' as string;
  (appointment.participants || []).forEach((participant) => {
    if (participant?.user) {
      userId = participant.user.uuid;
    }
  });
  return userId;
};

export const canSelectSlot = (
  calendarWidgetState: ICalendarWidgetState,
  slot: {
    start: string | Date;
    end: string | Date;
    slots: Date[] | string[];
    action: 'select' | 'click' | 'doubleClick';
  }
): boolean => {
  const slotStartTime = getMomentObj(slot.start);
  if (
    calendarWidgetState.currentView === CalendarView.month &&
    (!isPastDay(slot.start as any) || isTodayDate(slot.start as any))
  ) {
    return false;
  }
  if (
    (calendarWidgetState.currentView === CalendarView.week ||
      calendarWidgetState.currentView === CalendarView.day) &&
      isCurrentTimeSlotAvailableToBook(slotStartTime)
  ) {
    return false;
  }
  return true;
};

export const mapParticipantObjectsForSave = (participants: any[]) => {
  return participants.map((participant: any) => {
    if (participant.contact) {
      return {
        contactId: participant.contact.uuid,
        id: participant.id,
        statusId: participant.statusId,
        isInitiator: participant.isInitiator,
        participantTypeId: participant.participantTypeId,
      };
    } else if (participant.user) {
      return {
        userId: participant.user.uuid,
        id: participant.id,
        statusId: participant.statusId,
        isInitiator: participant.isInitiator,
        participantTypeId: participant.participantTypeId,
      };
    } else if (participant.patient) {
      return {
        patientId: participant.patient.id,
        id: participant.id,
        statusId: participant.statusId,
        isInitiator: participant.isInitiator,
        participantTypeId: participant.participantTypeId,
      };
    }
  });
};

export const getDataForAppointmentStatusUpdate = (
  appointment: IAppointmentDetail,
  statusId: string,
  isUnblockAppointment?: boolean,
  cancelReason?: Coding,
  isForSeries?:boolean,
  isDeleted?: boolean
) => {
  return [
    {
      id: appointment.id,
      name: appointment.name,
      appointmentTypeId: appointment.appointmentType?.id,
      startDateTime: appointment.startDateTime,
      endDateTime: appointment.endDateTime,
      statusId: statusId,
      isBlockedInterval: appointment.isBlockedInterval,
      isDeleted: isUnblockAppointment || isDeleted,
      participants:
        appointment.participants && appointment.participants.length > 0
          ? mapParticipantObjectsForSave(appointment.participants)
          : [],
      notes: appointment.notes,
      ...(cancelReason && { cancelReason: cancelReason }),
      seriesId: appointment?.seriesId || undefined,
      isRecurrentAppointment: appointment?.isRecurrentAppointment,
      isApplyForSeries: !!isForSeries,
      locationGroupId: appointment?.locationGroupId,
      ...(appointment?.locationTypeId && { locationTypeId: appointment?.locationTypeId }),
    },
  ];
};

export const getDataForAppointmentWorkflowUpdate = (workflowEventList: any) => {
  const workFlowList = {
    workflowList: getWorkflowSelectedList(workflowEventList),
  };
  return workFlowList;
};

export const getContactId = (participants: any[]): string | undefined => {
  const patientList = participants.filter(
    (participant) => participant.type === ParticipantType.patient
  );
  if (patientList.length > 0) {
    return patientList[0].value;
  } else {
    const leadList = participants.filter(
      (participant) => participant.type === ParticipantType.leads
    );
    if (leadList.length > 0) {
      return leadList[0].value;
    } else {
      const groupList = participants.filter(
        (participant) => participant.type === ParticipantType.group
      );
      if (groupList.length > 0) {
        return groupList[0].value;
      }
    }
  }
};

export const mapParticipantObjectsForRescheduleView = (
  participants: any[]
): IParticipantSearch[] => {
  const patientsAndProspects: IParticipantSearch[] = [];
  participants.forEach((participant: any) => {
    if (participant.contact) {
      patientsAndProspects.push({
        value: participant.contact.uuid,
        label: participant.contact.name,
        key: participant.contact.uuid,
        type:
          participant.contact?.contactType?.contactType?.code === 'CUSTOMER'
            ? ParticipantType.patient
            : ParticipantType.leads,
        id: participant.id,
        statusId: participant?.statusId
      });
    } else if (participant.patient) {
      patientsAndProspects.push({
        value: participant.patient.id,
        label: getPatientName(participant.patient),
        key: participant.patient.id,
        type: ParticipantType.patient,
        id: participant.id,
        statusId: participant?.statusId
      });
    }
  });
  return patientsAndProspects;
};

export const getUserFromParticipantObjectsForRescheduleView = (
  participants: any[],
  primaryParticipantTypeId: string,
): {userId: string; id: string} | undefined => {
  const users = participants
    .filter((participant: any) => participant.user && participant.user.uuid) || [];
  if (users.length === 1) {
    return {userId: users[0].user.uuid, id: users[0].id};
  } else if (users.length) {
    const primaryUser = users.filter((participant: IParticipantData) => participant.participantTypeId === primaryParticipantTypeId) || [];
    if (primaryUser.length) {
      return { userId: primaryUser[0].user.uuid, id: primaryUser[0].id };
    }
  }
  return undefined;
};

export const getSecondaryUsersFromParticipantObjectsForRescheduleView = (
  participants: any[],
  secondaryParticipantTypeId: string,
): string[] => {
  const users = participants
    .filter((participant: IParticipantData) => participant.user && participant.user.uuid && participant.participantTypeId === secondaryParticipantTypeId) || [];
  return users.map(item => item.user.uuid) || [];
};

export const getParticipantsFormBookingAPI = (
  participants: IParticipantSearch[],
  selectedUser?: {
    id?: string;
    userId: string;
    statusId?: string;
    isInitiator?: boolean;
  },
  additionalData?: any
): IParticipantData[] => {
  const APPOINTMENT_PARTICIPANT_TYPE_IDS = additionalData?.APPOINTMENT_PARTICIPANT_TYPE_IDS;
  const finalData = participants.map(
    (participant: IParticipantSearch): IParticipantData => {
      if (
        participant.type === ParticipantType.patient ||
        participant.type === ParticipantType.leads
      ) {
        return {
          contactId: participant.value,
          id: participant.id,
          statusId: participant.statusId,
          isInitiator: participant.isInitiator,
          participantTypeId: APPOINTMENT_PARTICIPANT_TYPE_IDS?.patient,
        };
      }
      return {
        userId: participant.value,
        id: participant.id,
        statusId: participant.statusId,
        isInitiator: participant.isInitiator,
        participantTypeId: APPOINTMENT_PARTICIPANT_TYPE_IDS?.secondaryUser,
      };
    }
  );
  if (selectedUser) {
    finalData.push({
      userId: selectedUser.userId,
      id: selectedUser.id,
      statusId: selectedUser.statusId,
      isInitiator: selectedUser.isInitiator,
      participantTypeId: APPOINTMENT_PARTICIPANT_TYPE_IDS?.primaryUser,
    });
  }
  return finalData;
};

const getUserIdFromParticipant = (participant: IParticipantData) => {
  return participant.userId || participant.user?.uuid;
}

export const getParticipantsForAddUpdate = (
  participants: IParticipantSearch[],
  originalParticipantList: IParticipantData[],
  primaryUserId: string,
  secondaryUserIds: string[],
  additionalData?: any
): IParticipantData[] => {
  const isGroupAppointment = additionalData?.appointmentBookingState?.selectedAppointmentType?.category?.code === MLOV_CODES.GROUP_SESSION;
  const isPrevNonRsvpAndReschuledInRSVP = !!additionalData?.rescheduleData?.isRsvpEnabled === false && !!additionalData?.appointmentBookingState?.isRsvpEnabled === true;
  const isRsvpEnabled = !!additionalData?.appointmentBookingState?.isRsvpEnabled;
  const APPOINTMENT_PARTICIPANT_TYPE_IDS = additionalData?.APPOINTMENT_PARTICIPANT_TYPE_IDS;
  const appointmentParticipantStatusList = additionalData?.appointmentParticipantStatusList || [];
  const isRescheduleWorkflow = additionalData?.isRescheduleWorkflow || false;
  const participantStatusAcceptedId = getMlovIdFromCode(
    appointmentParticipantStatusList,
    APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
  );
  const participantStatusPending = getMlovIdFromCode(
    appointmentParticipantStatusList,
    APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION
  );
  // Patient, prospects, population groups
  const finalData = participants
    .filter(
      (item) =>
        item.type === ParticipantType.patient ||
        item.type === ParticipantType.leads ||
        item.type === ParticipantType.group ||
        item.type === ParticipantType.leadGroup ||
        item.participantTypeId === APPOINTMENT_PARTICIPANT_TYPE_IDS?.patient
    )
    .map((participant: IParticipantSearch): IParticipantData => {
      if (participant.type !== ParticipantType.group && participant.type !== ParticipantType.leadGroup) {
        return {
          contactId: participant.value,
          id: participant.id,
          ...(isGroupAppointment
            ? {statusId: isRsvpEnabled ? null : participantStatusAcceptedId}
            : {statusId: participant?.statusId}),
          isInitiator: participant.isInitiator,
          participantTypeId: APPOINTMENT_PARTICIPANT_TYPE_IDS?.patient,
        };
      } else {
        return {
          groupId: participant.value,
          groupType: 'POPULATION_GROUP',
          ...(isGroupAppointment
            ? {statusId: isRsvpEnabled ? null : participantStatusAcceptedId}
            : {statusId: participant?.statusId}),
        };
      }
    });

  // Primary User
  const users = originalParticipantList.filter(item => !!item.user?.uuid);
  const oldPrimaryUsers =
    users.length === 1
      ? [
          {
            ...users[0],
            isInitiator: isPrevNonRsvpAndReschuledInRSVP,
            statusId: participantStatusAcceptedId,
          } as IParticipantData,
        ]
      : users.filter((item) => {
          if (item.participantTypeId) {
            return (
              item.participantTypeId ===
              APPOINTMENT_PARTICIPANT_TYPE_IDS?.primaryUser
            );
          }
        });

  let addPrimaryAsNew = !oldPrimaryUsers.length;
  if (oldPrimaryUsers.length) {
    const isSameUser = getUserIdFromParticipant(oldPrimaryUsers[0]) === primaryUserId;
    if (!isSameUser) {
      oldPrimaryUsers[0].isDeleted = true;
      addPrimaryAsNew = true;
    }
    finalData.push({
      id: oldPrimaryUsers[0].id,
      userId: getUserIdFromParticipant(oldPrimaryUsers[0]),
      statusId: oldPrimaryUsers[0].statusId,
      isInitiator: oldPrimaryUsers[0].isInitiator,
      participantTypeId: APPOINTMENT_PARTICIPANT_TYPE_IDS?.primaryUser,
      isDeleted: oldPrimaryUsers[0].isDeleted || false,
    });
  }
  if (addPrimaryAsNew) {
    finalData.push({
      userId: primaryUserId,
      statusId: getMlovIdFromCode(
        appointmentParticipantStatusList,
        APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
      ),
      isInitiator: true,
      participantTypeId: APPOINTMENT_PARTICIPANT_TYPE_IDS?.primaryUser,
    })
  }

  // Secondary User
  const oldSecondaryUsers = users.filter((item) => {
    return item.participantTypeId === APPOINTMENT_PARTICIPANT_TYPE_IDS?.secondaryUser;
  });
  oldSecondaryUsers.forEach((olderUser) => {
    const userId = getUserIdFromParticipant(olderUser);
    if (userId && secondaryUserIds.includes(userId)) {
      finalData.push({
        id: olderUser.id,
        userId: userId,
        statusId: olderUser.statusId,
        isInitiator: olderUser.isInitiator,
        participantTypeId: APPOINTMENT_PARTICIPANT_TYPE_IDS?.secondaryUser,
        isDeleted: false,
      });
      secondaryUserIds = secondaryUserIds.filter(item => item !== userId)
    } else {
      finalData.push({
        id: olderUser.id,
        userId: userId,
        statusId: olderUser.statusId,
        isInitiator: olderUser.isInitiator,
        participantTypeId: APPOINTMENT_PARTICIPANT_TYPE_IDS?.secondaryUser,
        isDeleted: true,
      })
    }
  });
  secondaryUserIds.forEach((userId) => {
    finalData.push({
      userId: userId,
      statusId: getMlovIdFromCode(
        appointmentParticipantStatusList,
        APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
      ),
      isInitiator: false,
      participantTypeId: APPOINTMENT_PARTICIPANT_TYPE_IDS?.secondaryUser,
      isDeleted: false,
    });
  })

  return finalData;
};


export const getWorkflowSelectedList = (workflowList: any) => {
  const tempWorkflowList: any[] = [];
  for (let i = 0; i < workflowList?.length; i++) {
    const list = {
      isEnabled: workflowList[i].isEnabled,
      workflowMasterId: workflowList[i].workflowMasterId,
      formNodeList: workflowList[i].formNodeList,
    };
    tempWorkflowList.push(list);
  }
  return tempWorkflowList;
};

export const getNotesDetailsOfAppointment = (appointmentBookingState: IAppointmentBookingState, rescheduleData?: any) => {
  const isRescheduleWorkflow = !!rescheduleData;
  if (isRescheduleWorkflow && appointmentBookingState?.patientInstruction?.content) {
    return [{content: appointmentBookingState?.patientInstruction?.content || '', id: appointmentBookingState?.patientInstruction?.id || undefined}];
  } else {
    return appointmentBookingState?.patientInstruction?.content &&
      appointmentBookingState?.patientInstruction?.content?.length > 0
      ? [{content: appointmentBookingState?.patientInstruction?.content}]
      : [];
  }
}

export const getAppointmentStartDateTime = (
  appointmentBookingState: IAppointmentBookingState,
  isReccuring: boolean,
  reccurencingAppointmentData?: IReccuringEventAPI
): {
  startDateTime: string,
  endDateTime: string,
} => {
  const defaultAppointmentTimeSlot = {
    startDateTime: appointmentBookingState?.startDateTime,
    endDateTime: appointmentBookingState.endDateTime,
  };
  if (isReccuring) {
    const isMonthly =
      reccurencingAppointmentData?.frequency === ReccurenceType?.MONTHLY;
    const isAnnualy =
      reccurencingAppointmentData?.frequency === ReccurenceType?.YEARLY;
    if (isMonthly || isAnnualy) {
      const appointmentDate = getMomentObj(
        appointmentBookingState?.startDateTime,
        appointmentBookingState.selectedTimezone?.timezone
      );
      // generated from repeat on day feild
      const newAppointmentDate = getMomentObj(
        appointmentBookingState?.startDateTime,
        appointmentBookingState.selectedTimezone?.timezone
      )?.date(reccurencingAppointmentData?.repeatOnMonthDays?.[0] || 1);
      // set month also for annual appt
      if (isAnnualy) {
        newAppointmentDate.month(
          reccurencingAppointmentData?.repeatOnMonth as string
        );
      }

      if (newAppointmentDate.isBefore(appointmentDate)) {
        const startDateTime = newAppointmentDate.add(
          1,
          isMonthly ? 'month' : 'year'
        );
        const startDateISOstring = startDateTime.toISOString();
        const endDateTimeISOstring = startDateTime
          .add(appointmentBookingState.duration, 'minutes')
          .toISOString();
        return {
          startDateTime: startDateISOstring,
          endDateTime: endDateTimeISOstring,
        };
      }
      return defaultAppointmentTimeSlot;
    }
    return defaultAppointmentTimeSlot;
  }
  return defaultAppointmentTimeSlot;
};

export const getAppointmentBookingDataForSave = (
  appointmentBookingState: IAppointmentBookingState,
  statusId: string,
  appointmentParticipantStatusList: IMlov[],
  disallowToScheduleForOtherLocation: boolean,
  isReccuring: boolean,
  rescheduleData?: any,
  additionalData?: any,
  reccurencingAppointmentData?: IReccuringEventAPI,
  isApplyForSeries?: boolean
) => {
  const isRescheduleWorkflow = !!rescheduleData;
  const participants: any[] = appointmentBookingState.participants;
  const secondaryUsers: any[] = appointmentBookingState.selectedSecondaryUserIds;

  const participantData = getParticipantsForAddUpdate(
    participants,
     rescheduleData?.originalParticipantList || [],
    appointmentBookingState.selectedPrimaryUserId,
    secondaryUsers,
    {
      ...additionalData,
      appointmentParticipantStatusList,
      isRescheduleWorkflow,
      rescheduleData,
      appointmentBookingState: appointmentBookingState
    }
  );
  // if (isRescheduleWorkflow) {
  //   participants = (participants || []).map((participant) => {
  //     participant.statusId = null;
  //     return participant;
  //   });

  //   if (rescheduleData?.selectedUser) {
  //     rescheduleData.selectedUser.isInitiator = true;
  //   }

  //   if (rescheduleData?.selectedUser?.userId !== appointmentBookingState.selectedPrimaryUserId) {
  //     participants = getParticipantsFormBookingAPI(
  //       participants,
  //       {
  //         userId: appointmentBookingState.selectedPrimaryUserId,
  //         isInitiator: true,
  //         statusId: getMlovIdFromCode(
  //           appointmentParticipantStatusList,
  //           APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
  //         ),
  //       },
  //       additionalData,
  //     );
  //     participants.push(
  //       {
  //         id: rescheduleData?.selectedUser?.id,
  //         userId: rescheduleData?.selectedUser?.userId,
  //         isDeleted: true,
  //       }
  //     )
  //   } else {
  //     participants = getParticipantsFormBookingAPI(
  //       participants,
  //       rescheduleData?.selectedUser,
  //       additionalData,
  //     );
  //   }
  // } else {
  //   participants = getParticipantsFormBookingAPI(
  //     participants,
  //     {
  //       userId: appointmentBookingState.selectedPrimaryUserId,
  //       isInitiator: true,
  //       statusId: getMlovIdFromCode(
  //         appointmentParticipantStatusList,
  //         APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
  //       ),
  //     },
  //     additionalData,
  //   );
  // }

  let selectedAccountLocationId = null;
  if (appointmentBookingState.selectedPracticeLocationId) {
    const userSelectedLocation: any = (
      appointmentBookingState.selectedUserLocations || []
    ).find((userLocation) => {
      return (
        userLocation.uuid === appointmentBookingState.selectedPracticeLocationId
      );
    });
    if (userSelectedLocation && userSelectedLocation?.accountLocation?.uuid) {
      selectedAccountLocationId = userSelectedLocation?.accountLocation?.uuid;
    }
  }
  const selectedForms = appointmentBookingState.consentForms.filter(
    (form) => form.isSelected && form.status === FormStatus.pending
  );

  let selectedAppointmentTypeId: string | undefined = undefined;
  if (
    appointmentBookingState.selectedAppointmentType?.code !== 'ONE_OFF_VISIT'
  ) {
    selectedAppointmentTypeId = appointmentBookingState.appointmentTypeId;
  }

  let reasonForVisit: any = undefined;
  if (
    appointmentBookingState.reasonForVisit &&
    appointmentBookingState?.reasonForVisit?.type === ADD_NEW_REASON_FOR_VISIT
  ) {
    reasonForVisit = {
      code: undefined,
      displayName: appointmentBookingState?.reasonForVisit?.displayName,
    };
  } else {
    reasonForVisit = appointmentBookingState.reasonForVisit;
  }

  let description = '';
  if (appointmentBookingState?.description?.length) {
      description = appointmentBookingState.description;
  }

  const accountLocationId = selectedAccountLocationId || null;

  // if (
  //   disallowToScheduleForOtherLocation &&
  //   appointmentBookingState.contactAccountLocation?.accountLocation?.uuid
  // ) {
  //   accountLocationId =
  //     appointmentBookingState.contactAccountLocation?.accountLocation?.uuid;
  // }

  const appointmentNotes = getNotesDetailsOfAppointment(appointmentBookingState, rescheduleData);
  let expireDateTime = '';
  if (appointmentBookingState?.isRsvpExpiryEnabled) {
    if (isWeb() && appointmentBookingState?.rsvpExpiryDateTime?.length) {
      expireDateTime = new Date(appointmentBookingState?.rsvpExpiryDateTime || new Date()).toISOString();
    } else if (appointmentBookingState?.rsvpExpiryDateTime?.length) {
      const dateTime = appointmentBookingState?.rsvpExpiryDateTime + ' ' +
      getDateStrFromFormat(
        appointmentBookingState?.rsvpExpiryTimeOnly || '',
        DATE_FORMATS.DISPLAY_TIME_FORMAT
      )
      expireDateTime = new Date(dateTime).toISOString();
    }
  }

  const {startDateTime, endDateTime} = getAppointmentStartDateTime(
    appointmentBookingState,
    isReccuring,
    reccurencingAppointmentData
  );

  const isGroupAppointment = appointmentBookingState?.selectedAppointmentType?.category?.code === MLOV_CODES.GROUP_SESSION;
  let eventName = undefined;
  if (isGroupAppointment && appointmentBookingState?.eventName?.displayName && appointmentBookingState?.eventName?.displayName?.trim()?.length > 0) {
    eventName = appointmentBookingState?.eventName?.displayName?.trim();
  } else if (appointmentBookingState?.selectedAppointmentType?.eventName) {
    eventName = appointmentBookingState?.selectedAppointmentType?.eventName;
  } else {
    eventName = 'One Off Visit';
  }

  return [
    {
      id: isRescheduleWorkflow ? rescheduleData?.id : undefined,
      isRescheduleWorkflow: isRescheduleWorkflow,
      name: eventName,
      statusId: statusId || undefined,
      appointmentTypeId: selectedAppointmentTypeId,
      startDateTime,
      endDateTime,
      participants: participantData,
      notes: appointmentNotes,
      formIds: [] || selectedForms.map((form) => form.id),
      reasonForVisit: reasonForVisit,
      // userPracticeLocationId:
      //   appointmentBookingState.selectedPracticeLocationId,
      workflowList: getWorkflowSelectedList(
        appointmentBookingState.workflowList
      ),
      accountLocationId: accountLocationId,
      locationTypeId:
        appointmentBookingState.selectedLocationType?.id || undefined,
      isRsvpEnabled: appointmentBookingState?.isRsvpEnabled,
      tasks: appointmentBookingState?.tasks || [],
      description: description,
      expiryDateTime: appointmentBookingState?.isRsvpExpiryEnabled
        ? expireDateTime
        : '',
      expiryDateTimeUnit: appointmentBookingState?.isRsvpExpiryEnabled
        ? appointmentBookingState?.expiryDateTimeUnit
        : undefined,
      recurrenceData: isReccuring ? reccurencingAppointmentData : undefined,
      isRecurrentAppointment: isReccuring,
      seriesId: isRescheduleWorkflow
        ? rescheduleData?.seriesId || undefined
        : undefined,
      isApplyForSeries: isRescheduleWorkflow ? isApplyForSeries : undefined,
      locationGroupId: appointmentBookingState?.selectedLocationGroupId,
      slotView: appointmentBookingState?.selectSlotByView,
    },
  ];
};


export const isFutureMeeting = (appointment: IAppointmentDetail) => {
  const date = getDateObject(appointment.startDateTime);
  const updatedDate = addTimeToDate(new Date(), 4, 'HOUR');
  return isCurrentDateInFutureComparedToOther(date, updatedDate);
};

export const isPastMeeting = (appointment: IAppointmentDetail) => {
  const date = getDateObject(appointment?.startDateTime);
  const updatedDate = addTimeToDate(date, 4, 'HOUR');
  return isCurrentDateInFutureComparedToOther(new Date(), updatedDate);
};

export const getAppointmentActions = (
  detailState: IAppointmentDetailState,
  intl: IntlShape,
  statusIds: {
    checkedIn: string;
    checkedOut: string;
    cancelled: string;
    pending: string;
    proposed: string;
    decline: string;
    noShow: string;
  },
  loginUserId: string,
  ehrConfig: IAvailableEhr,
  virtualMeetingId?: string | number,
  locationTypeCode?: string,
  disableBlockTime?: boolean
): IFHPopoverAction[] => {
  const actions: IFHPopoverAction[] = [];
  const appointmentDraftStatusIds = [statusIds.proposed, statusIds.decline];
  const isGroupAppointment =
    detailState.detail?.appointmentType?.category?.code ===
    MLOV_CODES.GROUP_APPOINTMENT;
  const isBlockedInterval = detailState.detail?.isBlockedInterval || false;
  const showCancelReschedule =
    detailState.appointmentStatusId !== statusIds.decline &&
    detailState.appointmentStatusId !== statusIds.checkedIn &&
    detailState.appointmentStatusId !== statusIds.checkedOut &&
    detailState.appointmentStatusId !== statusIds.noShow &&
    detailState.appointmentStatusId !== statusIds.cancelled &&
    !isBlockedInterval &&
    !isGroupAppointment &&
    // hide edit buttton when appointment is RSVP enabled and status is pending and current user/provider is not the initiator
    !(
      detailState.detail?.isRsvpEnabled &&
      detailState.appointmentStatusId === statusIds.pending &&
      !detailState.isInitiator
    );
  const isRecurrentAppointment = detailState?.detail?.isRecurrentAppointment && !!detailState.detail?.seriesId;
  const participants = detailState?.detail?.participants || [];
  const isValidMeetingUser = participants.some(participant => {
    return participant?.userId && loginUserId && loginUserId === participant.userId;
  });
  const showJoinMeeting =
    locationTypeCode === LOCATION_TYPE_CODES.VIRTUAL &&
    isValidMeetingUser &&
    detailState.appointmentStatusId &&
    detailState.appointmentStatusId !== statusIds.decline &&
    detailState.appointmentStatusId !== statusIds.checkedOut &&
    detailState.appointmentStatusId !== statusIds.cancelled &&
    !isBlockedInterval &&
    virtualMeetingId;

  if (showJoinMeeting) {
    actions.push({
      name: intl.formatMessage({id: 'joinMeeting'}),
      code: AppointmentAction.joinMeeting,
    });
  }

  // if (showCheckInPatient) {
  //   actions.push({
  //     name: intl.formatMessage({id: 'checkInPatient'}),
  //     code: AppointmentAction.checkInPatient,
  //   });
  // }
  if (showCancelReschedule) {
    actions.push({
      name: intl.formatMessage({id: 'edit'}),
      code: AppointmentAction.reschedule,
    });
    // actions.push({
    //   name: intl.formatMessage({id: 'cancelAppointment'}),
    //   code: AppointmentAction.cancel,
    // });
  }
  if (
    isBlockedInterval &&
    !detailState?.detail?.id?.startsWith('placeholderAppointment') &&
    !disableBlockTime
  ) {
    actions.push(
      {
        name: intl.formatMessage({id: 'unblock'}),
        code: AppointmentAction.unblock,
      },
      {
        name: intl.formatMessage({id: 'edit'}),
        code: AppointmentAction.editBlockOccurence,
      }
    );
  }

  if (
    ((showCancelReschedule && isRecurrentAppointment) || (isBlockedInterval && isRecurrentAppointment)) &&
    !detailState?.detail?.id?.startsWith('placeholderAppointment')
  ) {
    if (!ehrConfig.isAthena) {
      if (isBlockedInterval && !disableBlockTime) {
        actions.push({
          name: intl.formatMessage({id: 'editSeries'}),
          code: AppointmentAction.editBlockSeries,
        });
      }
      if (!isBlockedInterval) {
        actions.push({
          name: intl.formatMessage({id: 'editSeries'}),
          code: AppointmentAction.editSeries,
        });
      }
    }

    if (isBlockedInterval) {
      if (!disableBlockTime) {
        actions.push({
          name: intl.formatMessage({
            id: ehrConfig.isAthena ? 'cancelOccurence' : 'removeOccurence',
          }),
          code: AppointmentAction.removeBlockOccurence
        });
        actions.push({
          name: intl.formatMessage({id: ehrConfig.isAthena ? 'cancelSeries' : 'removeSeries'}),
          code: AppointmentAction.removeBlockSeries
        });
      }
    } else {
      actions.push({
        name: intl.formatMessage({id: ehrConfig.isAthena ? 'cancelOccurence' : 'removeOccurence'}),
        code: AppointmentAction.removeOccurence
      });
      actions.push({
        name: intl.formatMessage({id: ehrConfig.isAthena ? 'cancelSeries' : 'removeSeries'}),
        code: AppointmentAction.removeSeries
      });
    }
  }

  // if (showNoShow) {
  //   actions.push({
  //     name: intl.formatMessage({id: 'noShow'}),
  //     code: AppointmentAction.noShow,
  //   });
  // }

  return actions;
};

export const getAppointmentStatus = (
  detailState: IAppointmentDetailState,
  appointmentStatusList: IMlov[],
  ehrConfig: IAvailableEhr,
): IMlov[] => {
  const actions: IMlov[] = [];
  const isBlockedInterval = detailState.detail?.isBlockedInterval || false;
  const isGroupSessionAppointment =
  detailState?.detail?.appointmentType?.category?.code ===
  MLOV_CODES.GROUP_SESSION;

  const ifPending = isGroupSessionAppointment?  [
    APPOINTMENT_STATUS_CODES.BOOKED,
    APPOINTMENT_STATUS_CODES.CANCELLED,
    APPOINTMENT_STATUS_CODES.CHECKED_OUT,
  ] : [
    APPOINTMENT_STATUS_CODES.BOOKED,
    APPOINTMENT_STATUS_CODES.CANCELLED,
    APPOINTMENT_STATUS_CODES.NO_SHOW,
    APPOINTMENT_STATUS_CODES.CHECKED_IN,
  ];
  const ifConfirmed = isGroupSessionAppointment? [
    APPOINTMENT_STATUS_CODES.CANCELLED,
    APPOINTMENT_STATUS_CODES.CHECKED_OUT,
  ] :  [
    APPOINTMENT_STATUS_CODES.CANCELLED,
    APPOINTMENT_STATUS_CODES.NO_SHOW,
    APPOINTMENT_STATUS_CODES.CHECKED_IN,
  ];
  const isNoShow = isGroupSessionAppointment ?  [
    APPOINTMENT_STATUS_CODES.BOOKED,
    APPOINTMENT_STATUS_CODES.CANCELLED,
    APPOINTMENT_STATUS_CODES.CHECKED_OUT,
  ]: [
    APPOINTMENT_STATUS_CODES.BOOKED,
    APPOINTMENT_STATUS_CODES.CANCELLED,
    APPOINTMENT_STATUS_CODES.CHECKED_IN,
  ];
  const ifCheckedIn =  isGroupSessionAppointment? [] :[APPOINTMENT_STATUS_CODES.CHECKED_OUT];
  const ifCancelled = isGroupSessionAppointment? [
    APPOINTMENT_STATUS_CODES.BOOKED,
    APPOINTMENT_STATUS_CODES.CHECKED_OUT,
  ]: [
    APPOINTMENT_STATUS_CODES.BOOKED,
    APPOINTMENT_STATUS_CODES.CHECKED_IN,
    APPOINTMENT_STATUS_CODES.NO_SHOW,
  ];
  let applicableStatus: string[] = [];
  const currentAppCode =
    appointmentStatusList.find(
      (appStatus) => appStatus.id === detailState.appointmentStatusId
    ) || null;

  switch (currentAppCode?.code) {
    case APPOINTMENT_STATUS_CODES.PENDING:
      applicableStatus = ifPending;
      break;
    case APPOINTMENT_STATUS_CODES.BOOKED:
      applicableStatus = ifConfirmed;
      break;
    case APPOINTMENT_STATUS_CODES.CHECKED_IN:
      applicableStatus = ifCheckedIn;
      break;
    case APPOINTMENT_STATUS_CODES.NO_SHOW:
      applicableStatus = ehrConfig.isAthena ? [] : isNoShow;
      break;
    case APPOINTMENT_STATUS_CODES.CANCELLED:
      applicableStatus = ehrConfig.isAthena ? [] : ifCancelled;
      break;
    default:
      break;
  }
  applicableStatus.forEach((status) => {
    const data = appointmentStatusList.find(
      (appStatus) => appStatus.code === status
    );
    if (data) {
      actions.push(data);
    }
  });
  return actions;
};

export const getCompletePracticeLocation = (
  userPracticeLocation?: IPracticeLocation
): string => {
  const locationItems: string[] = [];
  if (userPracticeLocation?.addressLine1) {
    locationItems.push(userPracticeLocation.addressLine1);
  }
  if (userPracticeLocation?.addressLine2) {
    locationItems.push(userPracticeLocation.addressLine2);
  }
  if (userPracticeLocation?.practiceCity?.name) {
    locationItems.push(userPracticeLocation.practiceCity.name);
  }
  if (userPracticeLocation?.practiceState?.name) {
    locationItems.push(userPracticeLocation.practiceState.name);
  }
  if (userPracticeLocation?.practiceCountry?.name) {
    locationItems.push(userPracticeLocation.practiceCountry.name);
  }
  if (userPracticeLocation?.practiceZipcode?.code) {
    locationItems.push(userPracticeLocation.practiceZipcode.code);
  }
  return locationItems.join(', ');
};

export const getUserNameFromAppointment = (appointment: IAppointmentDetail, primaryUserTypeId: string) => {
  let primaryUserName = '';
  let otherUserCount = 0;
  if (appointment?.participants?.length > 0) {
    const userParticipants = appointment?.participants.filter((item) => item.user?.id);
    if (userParticipants.length === 1) {
      primaryUserName = userParticipants[0].user?.name || '';
    } else {
      userParticipants.forEach((participant) => {
        if (participant.participantTypeId === primaryUserTypeId) {
          primaryUserName = participant.user?.name || '';
        }
      });
      otherUserCount = userParticipants.length - 1;
    }
  }
  const displayValues: string[] = [primaryUserName];
  if (otherUserCount) {
    displayValues.push(`+${otherUserCount} more`);
  }
  return displayValues.join(' • ');
};

export const getUsersMatchingRoles = (users: IUser[], roleIds: string[]) => {
  return users.filter((item) => {
    const userRoleIds: string[] = [];
    item.userRoles?.forEach((element: any) => {
      const roleId = element?.userRole?.userRole?.id || element?.userRole?.roleId;
      if (roleId) {
        userRoleIds.push(roleId);
      }
    });
    return userRoleIds.some(item => roleIds.includes(item)) ;
  });
}

export const getAllUserRolesFromUserList = (users: IUser[]) => {
  const userRoleMlov: IMlov[] = [];
  users.forEach((item) => {
    item.userRoles?.forEach((element: any) => {
      const userRole = element?.userRole?.userRole;
      const alreadyAdded = userRoleMlov.some(item => item.id && item.id === userRole?.id);
      if (userRole?.id && !alreadyAdded) {
        userRoleMlov.push({
          categoryId: '',
          code: userRole?.code || '',
          id: userRole?.id || '',
          value: userRole?.value || '',
        });
      }
    });
  });
  return userRoleMlov;
}

export const getAppointmentUserById = (
  appointmentUserList: IUser[],
  userId?: string
) => {
  let selectedUser: IUser = {} as IUser;
  if (userId) {
    selectedUser =
      (appointmentUserList || []).find((user) => {
        return user?.uuid === userId;
      }) || ({} as IUser);
  }
  if (!selectedUser?.uuid) {
    selectedUser = appointmentUserList?.[0];
  }
  return selectedUser;
};

export const getPrimaryAndSecondaryUsersForAppointmentType = (
  appointmentType: IAppointmentType,
  availableUsers: IUser[],
  metaData: {
    skipSecondaryUsersForCareTeamType: boolean,
  }
) => {
  switch (appointmentType.availabilityTypeCode) {
    case AppointmentAvailabilityCode.PROVIDER:
      const primaryUserUuids =appointmentType.userPool?.userPoolUsers?.filter((item) => item.isDefault)?.map((item) => item.userId).filter((item) => !!item) || [];
      const secondaryUserUuids = appointmentType.userPool?.userPoolUsers?.filter((item) => !item.isDefault)?.map((item) => item.userId).filter((item) => !!item) || [];
      return {
        availablePrimaryUsers: availableUsers.filter((user) => primaryUserUuids?.includes(user.uuid)),
        availableSecondaryUsers: availableUsers.filter((user) => secondaryUserUuids?.includes(user.uuid)),
      };

    case AppointmentAvailabilityCode.CARE_TEAM:
      const primaryUserRoles = (appointmentType?.appointmentTypeGroup || []).map(item => item.roleId)?.filter(item => !!item) || [];
      const primaryUsers = getUsersMatchingRoles(availableUsers, primaryUserRoles as string[]);
      return {
        availablePrimaryUsers: primaryUsers,
        availableSecondaryUsers: metaData.skipSecondaryUsersForCareTeamType ? [] : availableUsers,
      };

    case AppointmentAvailabilityCode.ROLE:
    default:
      const userRoleIds = (appointmentType?.appointmentTypeGroup || []).map(item => item.roleId)?.filter(item => !!item) || [];
      const userList = getUsersMatchingRoles(availableUsers, userRoleIds as string[]);
      return {
        availablePrimaryUsers: userList,
        availableSecondaryUsers: availableUsers,
      };
  }
};

export const getAppointmentTypeForSelectedUser = (appointmentTypeWiseUser: Map<string, IAppointmentTypeWiseUsersMap>, selectedUserId: string) => {
  let matchingAppointmentType: IAppointmentType| undefined;
  appointmentTypeWiseUser.forEach(mapData => {
    const isAvailable = mapData?.availablePrimaryUsers.some(item => selectedUserId && item.uuid === selectedUserId);
    if (isAvailable && !matchingAppointmentType) {
      matchingAppointmentType = mapData.appointmentType;
    }
  });
  return matchingAppointmentType;
}

export const getOneOffVisitAppointmentType = (
  userRoles: IMlov[]
): IAppointmentType => {
  return {
    id: `${Math.random()}`,
    code: ONE_OFF_VISIT_APPT_CODE,
    duration: ONE_OFF_VISIT_APPT_DURATION,
    description: 'One Off Visit - Default Appointment Type',
    eventName: 'One Off Visit',
    isShownToPatient: true,
    appointmentTypeGroup: getOneOffAppointmentTypeGroup(userRoles),
    locationTypeId: '',
    appointmentCardProperties: {
      bgColorPrimary:defaultAppointmentTypeColor?.bgColorPrimary,
      bgColorSecondary: defaultAppointmentTypeColor?.bgColorSecondary,
      bgColorPrimaryDisabled: defaultAppointmentTypeColor?.bgColorPrimaryDisabled,
      bgColorSecondaryDisabled: defaultAppointmentTypeColor?.bgColorPrimary,
    },
    restrictedUsers: []
  };
};

const getOneOffAppointmentTypeGroup = (
  userRoles: IMlov[]
): IUserGroupData[] => {
  const groupList: IUserGroupData[] = [];
  (userRoles || []).forEach((roleMlov: IMlov) => {
    groupList.push({
      duration: ONE_OFF_VISIT_APPT_DURATION,
      roleId: roleMlov.id,
    });
  });
  return groupList;
};

export const getLocationCode = (
  appointment?: IAppointmentDetail,
  scheduleLocationTypeList?: IMlov[]
) => {
  if (!appointment || !scheduleLocationTypeList?.length) {
    return undefined;
  }
  let locationType = {} as any;
  const isOneOffVisit = !appointment.appointmentType && appointment.id && !appointment.isBlockedInterval;
  if (appointment?.locationTypeId) {
    locationType =
      scheduleLocationTypeList.find((locationTypeMlov) => {
        return locationTypeMlov.id === appointment?.locationTypeId;
      }) || ({} as any);
  } else {
    if (isOneOffVisit) {
      return appointment.accountLocationId
        ? LOCATION_TYPE_CODES.AT_CLINIC
        : LOCATION_TYPE_CODES.VIRTUAL;
    }
    locationType = appointment.appointmentType?.locationType || ({} as IMlov);
  }
  return locationType?.code || '';
};

export const getSlotsBackgroundColor = (slotCount: number) => {
  if (slotCount >= 0 && slotCount <= 5) {
    return Colors.danger[400];
  } else if (slotCount >= 5 && slotCount <= 10) {
    return Colors.secondary[300];
  } else {
    return Colors.success[300];
  }
};

export const getApplicableContactLocations = (
  contact?: IContact | any,
  accountLocations?: {
    uuid?: string;
    practiceLocation?: IPracticeLocation;
  }[],
) => {
  const locations = accountLocations || [];
  // Check contact location (department) first, if that is not present then filter all account locations based on patients address state
  if (contact?.contactPracticeLocations?.length) {
    const practiceLocationUuids: string[] = [];
    contact.contactPracticeLocations.forEach((item: any) => {
      if (item.accountLocation?.practiceLocation?.uuid) {
        practiceLocationUuids.push(item.accountLocation.practiceLocation.uuid);
      }
    })
    return locations.filter(item => {
      return item.practiceLocation?.uuid && practiceLocationUuids.includes(item.practiceLocation?.uuid);
    });
  }
  const contactStateId = contact?.personAddress?.[0]?.zipcodes?.stateId || contact?.personAddress?.[0]?.stateId || undefined;
  if (contactStateId) {
    return locations?.filter((item) => {
      const practiceStateId = item.practiceLocation?.practiceZipcode?.stateId || item.practiceLocation?.stateUuid;
      return contactStateId === practiceStateId;
    }) || [];
  }
  return [];
};

export const getUserPracticeLocationStateId = (userPracticeLocation?: IUserPracticeLocation) => {
  return userPracticeLocation?.accountLocation?.practiceLocation?.stateUuid || undefined;
};

export const isContactAndUserPracticeLocationSame = (
  contactLocations?: {
    uuid?: string;
    practiceLocation?: IPracticeLocation;
  }[],
  userPracticeLocation?: IUserPracticeLocation
) => {
  if (!contactLocations?.length || !userPracticeLocation) {
    return false;
  }

  const userPracticeLocationUuid = userPracticeLocation?.accountLocation?.practiceLocation?.uuid;
  return userPracticeLocationUuid && contactLocations.some((item) => {
    return item.practiceLocation?.uuid === userPracticeLocationUuid;
  });
};

export const getAppointmentNote = (notes: any[], subjectTypeId: string) => {
   return (notes || []).find(note => {
    return note?.subjectTypeId && note?.subjectTypeId == subjectTypeId;
   }) || {subjectTypeId} as IAppointmentNote;
};

export const getAppointmentPatientInstruction = (notes: any[], subjectTypeId: string) => {
  const note = (notes || []).find(note => {
   return !note?.subjectTypeId || note?.subjectTypeId && note?.subjectTypeId == subjectTypeId;
  }) || {subjectTypeId} as IAppointmentNote;

  // Added subjectTypeId because old notes don't have subject type id
  if (!note?.subjectTypeId) {
    note.subjectTypeId = subjectTypeId;
  }
  return note;
};

export const isAppointmentBookForSelectedUser = (userId: string, appointment: IAppointmentDetail) => {
  const participants = appointment?.participants || [];
  return participants.some(participant => {
    return participant?.user?.id && userId && userId === participant.user.uuid;
  });
};


export const getSlotAvailabilityDateRange = (appointmentType: IAppointmentType | undefined) => {
  let bookWithinDays = appointmentType?.bookWithinDays || 0;
  if (bookWithinDays) {
    bookWithinDays = bookWithinDays > MAX_FUTURE_DATE_RANGE ? MAX_FUTURE_DATE_RANGE : bookWithinDays - 1;
  }
  return getCustomDateRangeForAppointments(
    bookWithinDays || MAX_FUTURE_DATE_RANGE
  );
}

export const isCurrentTimeSlotAvailableToBook = (timeSlot: Moment) => {
  const currentTime = new Date();
  const timeSlotStart = timeSlot.toISOString();
  const availableTimeFrame = addTimeToDate(
    timeSlotStart,
    TIMESLOT_STEP_IN_MINUTES - 1,
    'MINUTE'
  );
  const isSlotAvailable = isTimeBefore(currentTime, availableTimeFrame);
  return isSlotAvailable;
};

export const getAccountLocationListFromUsersList = (users: IUser[]) => {
  const accountLocations: any[] = [];
  users.forEach((user) => {
    if (user.userPracticeLocations?.length) {
      user.userPracticeLocations.forEach((location) => {
        if (location.accountLocation?.practiceLocation?.uuid) {
          const alreadyAdded = accountLocations.some((item) => item.practiceLocation?.uuid === location.accountLocation?.practiceLocation?.uuid);
          if (!alreadyAdded) {
            accountLocations.push(location.accountLocation);
          }
        }
      })
    }
  });
  return accountLocations;
};

export const getUsersFilteredBasedOnLocation = (users: IAppointmentUserDetail[], accountLocationId: string) => {
  return users.filter((user) => {
    return (user.userPracticeLocations || []).some(
      (location: IUserPracticeLocation) => location.accountLocation?.uuid === accountLocationId
    );
  })
}

export const isFutureDateAllowed = (startDate:string, bookWithinDays:number | undefined)=> {
  if (bookWithinDays) {
    const bookWithinDaysLastDate= moment().add(bookWithinDays, 'day').endOf('day')
    return moment(startDate || new Date().toDateString()).isAfter(moment(bookWithinDaysLastDate), 'month')
  }
  return false
}

export const isFutureDateSelectionAllowed = (
  startDate: string,
  bookWithinDays: number | undefined
) => {
  if (bookWithinDays) {
    const lastAllowedDate = moment().add(bookWithinDays, 'days');
    const selectedDate = moment(startDate).endOf('day');
    if (selectedDate.isAfter(lastAllowedDate)) {
      return false;
    }
    return true;
  }
  return true;
};

export const getAppointmentParticipantByTypeId = (
  participants: any[],
  participantTypeId: string,
) => {
  return (participants || []).filter((participant: any) => {
    return participant && participant?.participantTypeId && participant?.participantTypeId === participantTypeId;
  }) || [];
};

export const generateAppointmentBookingLongLink = (params: IBookingLinkParams) => {
  try {
    const {meetingId, joineeEmail, userUuid, appointmentId, accountUUID, name} = params;
    const joinMeetingObj = {
      meetingId,
      ...(!userUuid && {joineeType: 'GUEST'}),
      userUuid: userUuid,
      contactUuid: params.contactUuid,
      joineeDetails: {
        email: joineeEmail,
        ...(userUuid && {userId: userUuid}),
      },
      name: name,
      appointmentDetails: {
        ...(userUuid && {userId: userUuid}),
        appointmentId: appointmentId,
        startMeetingHook: `${CARE_STUDIO_NEST_URL}/event/appointment/${appointmentId}/communication`,
        endMeetingHook: `${CARE_STUDIO_NEST_URL}/event/appointment/${appointmentId}/communication`,
        accountUUID
      },
    };
    const meetingDetails = JSON.stringify(joinMeetingObj);
    const appointmentLinkToken = window.btoa(meetingDetails);
    const baseUrl = `${COMMUNICATION_APP_BASE_URL()}`;
    if (params.isZoomEnabled) {
      return `${baseUrl}/#/zoom?token=${appointmentLinkToken}&type=MEETING`;
    }
    return `${baseUrl}?token=${appointmentLinkToken}`;
  } catch(error) {
    return undefined;
  }
}

export const getAppointmentActionSuccessToastMessage = (type:AppointmentAction) => {
  switch (type) {
    case AppointmentAction.editBlockOccurence:
      return 'Block occurrence updated successfully';
    case AppointmentAction.editBlockSeries:
      return 'Block series update in progress';
    case AppointmentAction.removeBlockOccurence:
      return 'Block occurrence deleted successfully';
    case AppointmentAction.removeBlockSeries:
      return 'Block series deletion in progress';

    case AppointmentAction.editOccurence:
      return 'Appointment occurrence updated successfully';
    case AppointmentAction.editSeries:
      return 'Appointment series update in progress';
    case AppointmentAction.removeOccurence:
      return 'Appointment occurrence deleted successfully';
    case AppointmentAction.removeSeries:
      return 'Appointment series deletion in progress';
  }
}


export const isDateOrProviderOrDurationChanged = (appointmentBookingState: IAppointmentBookingState, rescheduleData?: IRescheduleData) => {
  const isPrimaryUserChanged =
      rescheduleData?.selectedUser?.userId !==
      appointmentBookingState.selectedPrimaryUserId;
    const isDurationChanged =
      rescheduleData?.duration !== appointmentBookingState.duration;
    const isStartDateChanged =
      rescheduleData?.startDateTime !== appointmentBookingState.startDateTime;
    const isEndDateChanged =
      rescheduleData?.endDateTime !== appointmentBookingState.endDateTime;
    const isSecondaryUserChanged = areArraysEqual(
      rescheduleData?.selectedSecondaryUserIds as string[],
      appointmentBookingState.selectedSecondaryUserIds as string[]
    );

     if (
      isPrimaryUserChanged ||
      isDurationChanged ||
      isStartDateChanged ||
      isEndDateChanged ||
      isSecondaryUserChanged
    ) {
      return true;
    }
}


export const validateExpireDateTime = (
  endDateTime: string | Date,
  expiryDateTimeUnit?: ExpiryDateTime,
  expiryDateTime?: Date | string
) => {
  const expiryTimeValue = expiryDateTimeUnit?.value;
  const expiryUnitValue = expiryDateTimeUnit?.unit;
  const isValidInput =
    expiryTimeValue &&
    expiryUnitValue &&
    typeof expiryTimeValue === 'number' &&
    DATE_UNIT_CONST.includes(expiryDateTimeUnit?.unit);
  let isValidDateTime = false;
  let compareDateTime;
  if (expiryDateTimeUnit) {
    if (isValidInput) {
      const dateUnit: moment.DurationInputArg2 =
        expiryDateTimeUnit?.unit as moment.DurationInputArg2;
      compareDateTime = moment(endDateTime)
        .subtract(expiryTimeValue, dateUnit)
        .toDate();
      isValidDateTime = isValidDate(compareDateTime);
    } else {
      isValidDateTime = false;
    }
  } else if (expiryDateTime) {
    compareDateTime = expiryDateTime;
    isValidDateTime = isValidDate(expiryDateTime);
  }
  return !isValidDateTime || (!!compareDateTime && isPastDateTime(compareDateTime));
};


export const getSelectedLocationName = (appointmentBookingState: IAppointmentBookingState, userSettings: IUserSettingsByCode, disAllowVirtualLocation: boolean) => {
  const locations = appointmentBookingState.selectedUserLocations || [];
  const locationId = appointmentBookingState.selectedAccountLocationId;
  if (
    appointmentBookingState.selectedLocationType?.code ==
      LOCATION_TYPE_CODES.VIRTUAL &&
    locationId &&
    isVirtualLocationEnabledInAvailability(userSettings)
  ) {
    return 'Virtual Appointment';
  }
  if (locationId) {
    const userSelectedLocation = locations.find((userLocation) => {
      return userLocation?.accountLocation?.uuid === locationId;
    });
    if (userSelectedLocation?.accountLocation?.practiceLocation?.name) {
      return userSelectedLocation.accountLocation.practiceLocation.name;
    }
  }
  if (disAllowVirtualLocation) {
    return undefined;
  }
  return appointmentBookingState.selectedLocationType?.code ==
    LOCATION_TYPE_CODES.VIRTUAL
    ? 'Virtual Appointment'
    : undefined;
};

export const getSlotDate = (appointmentBookingState: IAppointmentBookingState, slotRange?: any, isRescheduleWorkflow?: boolean) => {
  const defaultStartDate = getDefaultStartDate();
  const defaultEndDate = getDefaultEndDate();
  if (isRescheduleWorkflow) {
    if (
      isDateInNextMonth(
        getMomentObj(appointmentBookingState.selectedDate)
      )
    ) {
      return {
        slotStartDate: getMomentObj(
          appointmentBookingState.selectedDate
        ).startOf('month'),
        slotEndDate: getMomentObj(
          appointmentBookingState.selectedDate
        ).endOf('month'),
      };
    } else {
      // if same or prev months
      return {
        slotStartDate: getMomentObj(new Date()).startOf('month'),
        slotEndDate: getMomentObj(new Date()).endOf('month'),
      };
    }
  } else {
    if (isDateInNextMonth(getMomentObj(appointmentBookingState.selectedDate))) {
      return {
        slotStartDate: getMomentObj(appointmentBookingState.selectedDate).startOf('month'),
        slotEndDate: getMomentObj(appointmentBookingState.selectedDate).endOf('month'),
      };
    }
    return {
      slotStartDate: slotRange?.slotStartDate || defaultStartDate,
      slotEndDate: slotRange?.slotEndDate || defaultEndDate,
    }
  }
}


export function getSelectedTimezone(appointmentBookingState: IAppointmentBookingState) {
  return (
    appointmentBookingState.selectedTimezone?.timezone || getCurrentTimeZone()
  );
}

export const getDayWiseSlotsFromResponse = (slotList: any[]): ISlot[] => {
  if (slotList && slotList?.length) {
    return slotList.filter((slot: ISlot) => {
      return isCurrentDateInFutureComparedToOther(
        slot.startDateTime,
        new Date()
      );
    });
  }
  return [] as ISlot[];
};


export const getAvailableTimeSlotsDate = (
  dayWiseSlots: Map<string, ISlot[]>,
  dayKey: string,
  disableNextDateSuggestions: boolean,
) => {
  if (dayWiseSlots?.size) {
    const slots: ISlot[] = dayWiseSlots.get(dayKey) || [];
    if (slots?.length) {
      return dayKey;
    }
    // If disableNextDateSuggestions is true then do not check for other dates, return undefined
    if (disableNextDateSuggestions) {
      return undefined;
    }
    let availableSlotDayKey: string | undefined = undefined;
    dayWiseSlots.forEach((slotCount, key) => {
      if (slotCount?.length > 0 && !availableSlotDayKey) {
        availableSlotDayKey = key;
      }
    });
    return availableSlotDayKey;
  }
  return undefined;
};

export const showNextAvailableDaySlots = () => {
  notification.destroy();
  notification.info({
    message: `Taking you to next available slot`,
    duration: 5.0,
  });
};

export const getLocationsByAllowedLocationIdAndLocationGroupId = (params: {
  allowedLocationIdsForLoggedInUser: string[];
  locationGroupId: string;
  locationList: IUserPracticeLocation[];
}) => {
  const {allowedLocationIdsForLoggedInUser, locationGroupId, locationList} =
    params;
  const allowedLocationMap = new Map<string, boolean>();
  allowedLocationIdsForLoggedInUser?.forEach((locationId) => {
    allowedLocationMap.set(locationId, true);
  });
  return locationList.filter(
    (locItem) =>
      allowedLocationMap.has(locItem.accountLocation?.uuid as string) &&
      locItem.accountLocation?.locationGroupId === locationGroupId
  );
};


export function getUniquePracticeLocations(users: IAppointmentUserDetail[]): IUserPracticeLocation[] {
  const uniqueLocationsMap = new Map<string, IUserPracticeLocation>();
  
  users.forEach(user => {
    user.userPracticeLocations.forEach(location => {
      if (location.accountLocation?.uuid) {
        const uuid = location.accountLocation.uuid;
        if (!uniqueLocationsMap.has(uuid)) {
          uniqueLocationsMap.set(uuid, location);
        }
      }
    });
  });
  
  return Array.from(uniqueLocationsMap.values());
}

export const getAccountUserListWithExternalUserWithLocationGroupId = (
  accountUserList: IUser[],
  locationGroupId: string
) => {
  return accountUserList.map((user) => {
    const externalUserMap = user.externalUserMap || [];
    // If locationGroupId is provided, find the externalUserId for that locationGroupId
    // Else if no locationGroupId is provided, use the first externalUserId
    const externalUserMapId = locationGroupId
      ? externalUserMap.find((item) => item.locationGroupId === locationGroupId)
          ?.externalUserId
      : externalUserMap[0]?.externalUserId;

    // Use fallbacks   to get externalUserId
    const externalUserId =
      externalUserMapId ||
      user?.accountUsers?.[0]?.externalUserId ||
      user?.externalUserId;
    return {
      ...user,
      externalUserId: externalUserId !== 'null' ? externalUserId : null,
    };
  });
};