import {
  BILLING_PLAN_TYPES,
  MEMBERSHIP_INTERVAL_CODE,
  MEMBERSHIP_PRICE_CODE,
  STRIPE_INTERVAL_CODE,
} from '../../../../../constants';

export const MemberShipPrice = [
  {
    label: 'oneTimeRegistrationFee',
    code: MEMBERSHIP_PRICE_CODE.ONE_TIME_REGISTRATION_FEE,
    key: MEMBERSHIP_INTERVAL_CODE.ONE_TIME,
    keyLabel: STRIPE_INTERVAL_CODE.ONE_TIME,
    count: 1,
  },
  {
    label: 'monthlyCharges',
    code: MEMBERSHIP_PRICE_CODE.MONTHLY_CHARGES,
    key: MEMBERSHIP_INTERVAL_CODE.MONTHLY,
    keyLabel: STRIPE_INTERVAL_CODE.MONTH,
    count: 1,
  },
  {
    label: 'quarterlyCharges',
    code: MEMBERSHIP_PRICE_CODE.QUARTERLY_CHARGES,
    key: MEMBERSHIP_INTERVAL_CODE.QUARTERLY,
    keyLabel: STRIPE_INTERVAL_CODE.MONTH,
    count: 3,
  },
  {
    label: 'annuallyCharges',
    code: MEMBERSHIP_PRICE_CODE.ANNUALLY_CHARGES,
    key: MEMBERSHIP_INTERVAL_CODE.YEARLY,
    keyLabel: STRIPE_INTERVAL_CODE.YEAR,
    count: 1,
  },
];

export const BillingPlanType = [
  {
    id: 1,
    labelName: 'Chat',
    keyLabel: BILLING_PLAN_TYPES.CHAT,
  },
  {
    id: 2,
    labelName: 'SMS',
    keyLabel: BILLING_PLAN_TYPES.SMS,
  },
  {
    id: 3,
    labelName: 'Video',
    keyLabel: BILLING_PLAN_TYPES.VIDEO,
  },
  {
    id: 4,
    labelName: 'Voice',
    keyLabel: BILLING_PLAN_TYPES.VOICE,
  },
  {
    id: 5,
    labelName: 'Email',
    keyLabel: BILLING_PLAN_TYPES.EMAIL,
  },
  {
    id: 6,
    labelName: 'Active Lives',
    keyLabel: BILLING_PLAN_TYPES.ACTIVE_LIVES,
  },
  ,
  {
    id: 7,
    labelName: 'Prospects',
    keyLabel: BILLING_PLAN_TYPES.PROSPECTS,
  },
  {
    id: 8,
    labelName: 'Employee',
    keyLabel: BILLING_PLAN_TYPES.EMPLOYEE,
  },
  {
    id: 9,
    labelName: 'Employer',
    keyLabel: BILLING_PLAN_TYPES.EMPLOYER,
  },
  {
    id: 10,
    labelName: 'Campaign',
    keyLabel: BILLING_PLAN_TYPES.CAMPAIGN,
  },
];

export const SubscriptionPeriod = [
  {
    id: 4,
    labelName: 'Daily',
    keyLabel: STRIPE_INTERVAL_CODE.DAY,
    count: 1,
    name: 'day'
  },
  {
    id: 1,
    labelName: 'Monthly',
    keyLabel: STRIPE_INTERVAL_CODE.MONTH,
    count: 1,
    name: 'month'
  },
  {
    id: 2,
    labelName: 'Quarterly',
    keyLabel: STRIPE_INTERVAL_CODE.MONTH,
    count: 3,
    name: 'quarter'
  },
  {
    id: 3,
    labelName: 'Yearly',
    keyLabel: STRIPE_INTERVAL_CODE.YEAR,
    count: 1,
    name: 'quarter'
  },
  {
    id: 5,
    labelName: 'One Time',
    keyLabel: STRIPE_INTERVAL_CODE.ONE_TIME,
    count: 1,
    name: ''
  },
];

export const PRICING_MODEL_CODE = {
  STANDARD: 'per_unit',
  TIERED: 'tiered',
};
