import { PERSON_TYPES } from '../../../../../constants';
import { IPatientJourneyAction, IPersonAction } from './interfaces';

export const PERSON_ACTION_CODES = {
  EDIT: 'EDIT',
  EDIT_LEAD: 'EDIT_LEAD',
  MERGE: 'MERGE',
  CREATE_AUTOMATION: 'CREATE_AUTOMATION',
  CONVERT_TO_LEAD: 'CONVERT_TO_LEAD',
  CONVERT_TO_CUSTOMER: 'CONVERT_TO_CUSTOMER',
  DELETE: 'DELETE',
  EDIT_PROFILE: 'EDIT_PROFILE',
  RESET_PASSWORD: 'RESET_PASSWORD',
  DEACTIVATE: 'DEACTIVATE',
  SMS: 'SMS',
  EMAIL: 'EMAIL',
  CALL: 'CALL',
  STEP_CHANGE: 'STEP_CHANGE',
  MARK_AS_COMPLETE: 'MARK_AS_COMPLETE',
  ADD_NOTE: 'ADD_NOTE',
  SCHEDULE_APPOINTMENT: 'SCHEDULE_APPOINTMENT',
  ASSIGN: 'ASSIGN',
  OPEN_IN_EHR: 'OPEN_IN_EHR',
  MEETING_ENTRY: 'MEETING_ENTRY',
  CREATE_MEETING: 'CREATE_MEETING',
  CANCEL: 'CANCEL',
  NEXT: 'NEXT',
  SEND_FORMS: 'SEND_FORMS',
  CREATE_TASK: 'CREATE_TASK',
  PRINT_CLINICAL_PROFILE: 'PRINT_CLINICAL_PROFILE',
  CREATE_NOTES: 'CREATE_NOTES',
  ACTIVATE: 'ACTIVATE',
  PHONE_CALL: 'PHONE_CALL',
  SEND_MEMBERSHIP_LINK: 'SEND_MEMBERSHIP_LINK',
  CHAT: 'CHAT',
  SEND_PROVIDER_REQUEST_FORM: 'SEND_PROVIDER_REQUEST_FORM',
  CARE_PROGRAM_CONSENT_AND_ENROLL: 'CARE_PROGRAM_CONSENT_AND_ENROLL',
  VCALL: 'VCALL'
};

export const PERSON_ACTION_LIST: IPersonAction[] = [
  {
    actionCode: PERSON_ACTION_CODES.EDIT,
    displayString: 'Edit Patient',
  },
  {
    actionCode: PERSON_ACTION_CODES.EDIT_LEAD,
    displayString: 'Edit Contact',
  },
  {
    actionCode: PERSON_ACTION_CODES.MERGE,
    displayString: 'Merge',
  },
  {
    actionCode: PERSON_ACTION_CODES.CONVERT_TO_LEAD,
    displayString: 'Convert To Lead',
  },
  {
    actionCode: PERSON_ACTION_CODES.CONVERT_TO_CUSTOMER,
    displayString: 'Convert To Patient',
  },
  {
    actionCode: PERSON_ACTION_CODES.DELETE,
    displayString: 'Delete',
  },
  {
    actionCode: PERSON_ACTION_CODES.EDIT_PROFILE,
    displayString: 'Edit Profile',
  },
  {
    actionCode: PERSON_ACTION_CODES.RESET_PASSWORD,
    displayString: 'Reset Password',
  },
  {
    actionCode: PERSON_ACTION_CODES.DEACTIVATE,
    displayString: 'Deactivate',
  },
  {
    actionCode: PERSON_ACTION_CODES.ADD_NOTE,
    displayString: 'Add Contact Note',
  },
  {
    actionCode: PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT,
    displayString: 'Appointment',
  },
  {
    actionCode: PERSON_ACTION_CODES.ASSIGN,
    displayString: 'Assign',
  },
  {
    actionCode: PERSON_ACTION_CODES.SEND_FORMS,
    displayString: 'Send Forms',
  },
  {
    actionCode: PERSON_ACTION_CODES.PRINT_CLINICAL_PROFILE,
    displayString: 'Clinical Profile',
  },
  {
    actionCode: PERSON_ACTION_CODES.CREATE_TASK,
    displayString: 'Add Task',
  },
  {
    actionCode: PERSON_ACTION_CODES.CREATE_AUTOMATION,
    displayString: 'Run Automation',
  },
  {
    displayString: 'Send Provider Request Form',
    actionCode: PERSON_ACTION_CODES.SEND_PROVIDER_REQUEST_FORM,
  },
  {
    displayString: 'Consent and Enroll in Care Program',
    actionCode: PERSON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL,
  }
];

export const ACTIONS_BY_PERSON_TYPE = {
  [PERSON_TYPES.VISITOR]: [
    PERSON_ACTION_CODES.EDIT,
    PERSON_ACTION_CODES.MERGE,
    PERSON_ACTION_CODES.CONVERT_TO_LEAD,
    PERSON_ACTION_CODES.CONVERT_TO_CUSTOMER,
    PERSON_ACTION_CODES.DELETE,
    PERSON_ACTION_CODES.SEND_FORMS,
    PERSON_ACTION_CODES.CREATE_TASK,
  ],
  [PERSON_TYPES.LEAD]: [
    PERSON_ACTION_CODES.EDIT_LEAD,
    PERSON_ACTION_CODES.MERGE,
    PERSON_ACTION_CODES.CONVERT_TO_CUSTOMER,
    PERSON_ACTION_CODES.DELETE,
    PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT,
    PERSON_ACTION_CODES.SEND_FORMS,
    PERSON_ACTION_CODES.CREATE_TASK,
  ],
  [PERSON_TYPES.OTHER]: [
    PERSON_ACTION_CODES.EDIT_LEAD,
    PERSON_ACTION_CODES.MERGE,
    PERSON_ACTION_CODES.CONVERT_TO_CUSTOMER,
    PERSON_ACTION_CODES.DELETE,
    PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT,
    PERSON_ACTION_CODES.SEND_FORMS,
    PERSON_ACTION_CODES.CREATE_TASK,
  ],
  [PERSON_TYPES.SUPPORTING_MEMBER]: [
    PERSON_ACTION_CODES.EDIT_LEAD,
    PERSON_ACTION_CODES.MERGE,
    PERSON_ACTION_CODES.CONVERT_TO_CUSTOMER,
    PERSON_ACTION_CODES.DELETE,
    PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT,
    PERSON_ACTION_CODES.SEND_FORMS,
    PERSON_ACTION_CODES.CREATE_TASK,
  ],
  [PERSON_TYPES.CUSTOMER]: [
    PERSON_ACTION_CODES.EDIT,
    PERSON_ACTION_CODES.MERGE,
    PERSON_ACTION_CODES.DELETE,
    PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT,
    PERSON_ACTION_CODES.SEND_FORMS,
    PERSON_ACTION_CODES.PRINT_CLINICAL_PROFILE,
    PERSON_ACTION_CODES.CREATE_TASK,
  ],
  [PERSON_TYPES.TEAM_MEMBER]: [
    PERSON_ACTION_CODES.EDIT_PROFILE,
    PERSON_ACTION_CODES.RESET_PASSWORD,
    PERSON_ACTION_CODES.DEACTIVATE,
    PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT,
    PERSON_ACTION_CODES.SEND_FORMS,
    PERSON_ACTION_CODES.CREATE_TASK,
  ],
};

export const OMNIVIEW_ACTIONS_BY_PERSON_TYPE = {
  [PERSON_TYPES.LEAD]: [
    PERSON_ACTION_CODES.EDIT_LEAD,
    // PERSON_ACTION_CODES.MERGE,
    PERSON_ACTION_CODES.CONVERT_TO_CUSTOMER,
    // PERSON_ACTION_CODES.DELETE,
    PERSON_ACTION_CODES.ADD_NOTE,
    PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT,
    PERSON_ACTION_CODES.SEND_FORMS,
  ],
  [PERSON_TYPES.CUSTOMER]: [
    PERSON_ACTION_CODES.EDIT,
    // PERSON_ACTION_CODES.MERGE,
    // PERSON_ACTION_CODES.CONVERT_TO_LEAD,
    // PERSON_ACTION_CODES.DELETE,
    // PERSON_ACTION_CODES.ADD_NOTE,
    PERSON_ACTION_CODES.RESET_PASSWORD,
    PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT,
    PERSON_ACTION_CODES.SEND_FORMS,
    PERSON_ACTION_CODES.PRINT_CLINICAL_PROFILE,
    PERSON_ACTION_CODES.CREATE_TASK,
    PERSON_ACTION_CODES.CREATE_AUTOMATION,
  ],
  [PERSON_TYPES.TEAM_MEMBER]: [
    PERSON_ACTION_CODES.EDIT_PROFILE,
    PERSON_ACTION_CODES.RESET_PASSWORD,
    PERSON_ACTION_CODES.DEACTIVATE,
    PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT,
    PERSON_ACTION_CODES.CREATE_TASK,
  ],
};

export const PATIENT_JOURNEY_ACTION_CODES = {
  START: 'START',
  STOP: 'STOP',
  PAUSE: 'PAUSE',
  RESTART: 'RESTART',
  EDIT: 'EDIT',
  SHOW_CARE_TEAM: 'SHOW_CARE_TEAM',
  SHOW_JOURNEY: 'SHOW_JOURNEY',
  DELETE: 'DELETE',
  SHOW_GOALS: 'SHOW_GOALS',
  COMPLETE: 'COMPLETE',
  CHANGE_GOAL_STATUS_TO_ACHIEVED: 'CHANGE_GOAL_STATUS_TO_ACHIEVED',
  CHANGE_GOAL_STATUS_TO_NOT_ACHIEVED: 'CHANGE_GOAL_STATUS_TO_NOT_ACHIEVED'
};

export const PATIENT_JOURNEY_ACTION_LIST: IPatientJourneyAction[] = [
  {
    actionCode: PATIENT_JOURNEY_ACTION_CODES.EDIT,
    displayString: 'Edit Journey',
  },
  {
    actionCode: PATIENT_JOURNEY_ACTION_CODES.START,
    displayString: 'Start Journey',
  },
  {
    actionCode: PATIENT_JOURNEY_ACTION_CODES.STOP,
    displayString: 'Stop Journey',
  },
  {
    actionCode: PATIENT_JOURNEY_ACTION_CODES.PAUSE,
    displayString: 'Pause Journey',
  },
  {
    actionCode: PATIENT_JOURNEY_ACTION_CODES.RESTART,
    displayString: 'Restart Journey',
  },
  {
    actionCode: PATIENT_JOURNEY_ACTION_CODES.SHOW_CARE_TEAM,
    displayString: 'Show Care Team',
  },
  {
    actionCode: PATIENT_JOURNEY_ACTION_CODES.SHOW_JOURNEY,
    displayString: 'Show Journey',
  },
  {
    actionCode: PATIENT_JOURNEY_ACTION_CODES.DELETE,
    displayString: 'Delete Journey',
  },
  {
    actionCode: PATIENT_JOURNEY_ACTION_CODES.SHOW_GOALS,
    displayString: 'Show Journey Goal',
  },
  {
    actionCode: PATIENT_JOURNEY_ACTION_CODES.COMPLETE,
    displayString: 'Complete',
  },
  {
    actionCode: PATIENT_JOURNEY_ACTION_CODES.CHANGE_GOAL_STATUS_TO_ACHIEVED,
    displayString: 'Change status to goal achieved'
  },
  {
    actionCode: PATIENT_JOURNEY_ACTION_CODES.CHANGE_GOAL_STATUS_TO_NOT_ACHIEVED,
    displayString: 'Change status to goal not achieved'
  }
];
