import {useLazyQuery} from '@apollo/client';
import {Table} from 'antd';
import {Skeleton, View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import { POPULATION_GROUP_TYPES } from '../../../../../../constants';
import {CONVERSATION_ACTION_CODES} from '../../../../../../constants/ActionConst';
import { FHIR_RESOURCE } from '../../../../../../constants/FhirConstant';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {LeadQueries} from '../../../../../../services';
import {Colors} from '../../../../../../styles';
import { getAccountConfigResourceAbilities, getEhrConfig, getResourceAbilities } from '../../../../../../utils/capabilityUtils';
import { getAccountUUID, isLeadGroupsPage } from '../../../../../../utils/commonUtils';
import {formateLeadListData, getMemberGroupMap, onPagination} from '../../../../Contacts/Leads/LeadView/LeadTableView/LeadListView/LeadListHelper';
import MessagingContactDetailsDrawer from '../../../../TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import {IContactByIdsQuery, IGroupContactList} from '../interface';
import {getLeadTableViewColumns} from './../../../../Contacts/Leads/LeadView/LeadTableView/Helper/LeadListViewColumns';
import { GET_ALL_PATIENT_CARE_JOURNEYS } from '../../../../../../services/CareJourney/CareJourneyQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import { IPatientCareJourney, IPatientCareJourneyMap } from '../../../../Contacts/Leads/interfaces';
import {useParams} from 'react-router-dom';
import {getContactListSearchFilter} from '../GroupDetailsUtils';
import {getMemberGroupListByMultipleContactIds} from '../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';

const GroupContactList = (props: IGroupContactList) => {
  const {groupContactFilter} = props;
  const mlovData = useContext(CommonDataContext);
  const hintCapability = getAccountConfigResourceAbilities(FHIR_RESOURCE.HINT);
  const accountUuid = getAccountUUID();
  const openInHint = hintCapability?.openInHint || false;
  const {id} = useParams();
  const [stateData, setStateData] = useState({
    contactList: [] as any,
    loading: props.loading,
    limit: 10,
    offset: 0,
    page: 1,
    total: 10
  });
  const [careJourneyData, setCareJourneyData] = useState<IPatientCareJourney>({
    loading: false,
    map: new Map(),
  });

  let {sortedInfo}: any = {...stateData};
  sortedInfo = sortedInfo || {};

  const [getPatientCareJourneys] = useLazyQuery(GET_ALL_PATIENT_CARE_JOURNEYS, {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [Get_Contact_By_Uuid] = useLazyQuery(LeadQueries.Get_Contact_By_Uuid);
  const [searchContactsByRuleId] = useLazyQuery(LeadQueries.searchContactsByRuleId,{
    fetchPolicy: 'no-cache',
  })

  const onPaginationChange = (page: number, pageSize: number) => {
    const limit = pageSize
    const offset = onPagination(page, pageSize);

    setStateData(prev=> {
      return {
        ...prev,
        limit: limit,
        offset: offset,
        page: page
      }
    })
  }
  const getContacts = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    if (id) {
      const contactListResponse = await searchContactsByRuleId({
        variables: {
          params: getContactListSearchFilter({
            limit: stateData.limit,
            offset: stateData.offset,
            ruleId: id,
            groupContactFilter
          })
        },
      }).catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
      if (contactListResponse?.data?.searchContacts?.contacts?.length) {
        const contactList = contactListResponse?.data?.searchContacts || [];
        const formattedGroupMemberList = formateLeadListData(
          contactList,
          mlovData,
          props?.contactsDictionary
        );
        setStateData((prev) => {
          return {
            ...prev,
            contactList: formattedGroupMemberList,
            loading: false,
            total: contactListResponse?.data?.searchContacts?.contactAggregate?.aggregate?.count
          };
        });
        const patientIdList: string[] =[]
        const contactIds: string[] = []
        formattedGroupMemberList.map(
          (user: any) => {
            if (user?.contactData?.patient?.patientUuid) {
              patientIdList.push(user?.contactData?.patient?.patientUuid)
              contactIds.push(user?.contactData.id.toString())
            }
          }
        )
      if (isLeadGroupsPage()) {
        if (patientIdList.length) {
          fetchCareJourneys(patientIdList);
        }
        const updateContactListByGroup = await fecthGroups(contactIds, formattedGroupMemberList)
        setStateData((prev) => {
          return {
            ...prev,
            contactList: updateContactListByGroup,
            loading: false,
          };
        });
      }
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
            total: contactListResponse?.data?.searchContacts?.contactAggregate?.aggregate?.count
          };
        });
      }
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };
  const fecthGroups = async (contactIds: string[], contactData: any) => {
    const memberGroupsRes = await getMemberGroupListByMultipleContactIds({
      accountUuid: accountUuid,
      contactIds: [...contactIds]
    })
    const memberGroupMap = getMemberGroupMap(memberGroupsRes);
    const result = contactData?.map((contact: any, index: number) => {
      const groups = memberGroupMap.get(contact.id.toString()) || [];
      return {
        ...contact,
        contactData: {
          ...contact.contactData,
          groups,
        },
        singleLeadData: {
          ...contact.singleLeadData,
          groups,
        },
        consentLoading: false,
      };
    });
    return result;
  }
  const fetchCareJourneys = async (patientIds: string[]) => {

    if (!patientIds.length) {
      return;
    }

    setCareJourneyData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    try {
      const response = await getPatientCareJourneys({
        variables: {
          patientIdList: patientIds,
        },
      });
      const map: IPatientCareJourneyMap = new Map();
      patientIds.forEach((patientId) => {
        map.set(
          patientId,
          response?.data?.patientCareJourneys.filter(
            (item: any) => item.patientId === patientId
          )
        );
      });
      setCareJourneyData((prev) => {
        return {
          ...prev,
          loading: false,
          map,
        };
      });
    } catch (error) {
      setCareJourneyData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
}

  const onChangeSelection = (item: any, isSelected: boolean, allSelectedRows?: any[]) => {
    onAllSelection(isSelected, [item], allSelectedRows);
  };

  const onAllSelection = (isSelected: boolean, changeRows: any, allSelectedRows?: any[]) => {
    let selectedRowKeys = [...props.selectedRowKeys];
    changeRows.forEach((item: any) => {
      const key = item.id;
      const uuid = item.uuid;
      if (isSelected) {
        if (selectedRowKeys.indexOf(key) == -1) {
          selectedRowKeys.push(key);
        }
      } else {
        selectedRowKeys = selectedRowKeys.filter((selectedKey) => {
          return selectedKey != key;
        });
      }
    });

    props.setSelectedRowKeys(selectedRowKeys, allSelectedRows);
  };

  const [selectedRow, setSelectedRow] = useState<any>();

  useEffect(() => {
    getContacts();
  }, [
    stateData.page,
    id,
    stateData?.limit,
    groupContactFilter.isActive,
    groupContactFilter.selectedCampaign,
    groupContactFilter.selectedWorkflow,
    groupContactFilter.hintStatus.length,
    groupContactFilter?.locationIds?.join(',')
  ]);
  const {height} = Dimensions.get('window');
  return (
    <View
      style={{
        backgroundColor: '#fff',
      }}
    >
      {(props.loading || stateData.loading) && !stateData.contactList.length ? (
        <View style={{padding: '20px'}}>
          <Skeleton.Text lines={10} />
        </View>
      ) : (
        <Table
          dataSource={stateData.contactList}
          loading={props.loading}
          columns={getLeadTableViewColumns(
            sortedInfo,
            props.onActionPerformed,
            'CUSTOMER',
            false,
            true,
            undefined,
            openInHint && !isLeadGroupsPage() && props?.groupType === POPULATION_GROUP_TYPES?.DYNAMIC ? true : undefined,
            undefined,
            undefined,
            careJourneyData
          )}
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          className="lead-list-table-view patient-list-view"
          style={{minHeight: height - 200}}
          scroll={{y: height - 277}}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: props.selectedRowKeys,
            onSelect: (item: any, isSelected: boolean, selectedRows: any) => {
              onChangeSelection(item, isSelected, selectedRows);
            },
            onSelectAll(selected, selectedRows, changeRows) {
              onAllSelection(selected, changeRows, selectedRows);
            },
            hideSelectAll: false,
          }}
          // onChange={(pagination, filters, sorter: any) => {
          // }}
          rowKey={(row) => row.id as string}
          onRow={(_record: any) => {
            return {
              onClick: () => {
                setSelectedRow(_record);
              },
            };
          }}
          pagination={{
            position: ['bottomCenter'],
            pageSize: stateData.limit,
            current: stateData.page,
            total: stateData.total,
            onChange(page, pageSize) {
              onPaginationChange(page, pageSize)
            },
          }}
        />
      )}
      {selectedRow && (
        <MessagingContactDetailsDrawer
          contactData={selectedRow?.contactData}
          isDrawerVisible={selectedRow ? true : false}
          contactType={selectedRow?.contactType}
          onSideBarActionPerformed={(action?: any) => {
            if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
              setSelectedRow(null);
            }
          }}
          borderLessView={true}
        />
      )}
    </View>
  );
};
export default GroupContactList;
