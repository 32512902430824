import { View } from 'react-native'
import { useEffect, useState } from 'react';
import { NoDataFound } from '../../../common/NoDataFound';
import PlusIcon from '../../../common/Svg/PlusSvg';
import { BUTTON_TYPE } from '../../../../constants';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { Colors } from '../../../../styles';
import { IContactTypes, ICustomFieldData, ICustomFieldView, ICustomFieldViewState, ICustomToast } from "./interface"
import { CustomFieldTable } from './CustomFieldTable';
import { AddCustomFieldDrawer } from './AddCustomFieldDrawer';
import { enableDisableCustomField, getCustomFieldData } from './CustomFieldService';
import { ALL_CONTACT_TYPE, CUSTOM_FIELD_GET_DATA_LIMIT, CUSTOM_FIELD_TOAST_ERROR_DURATION, CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG, ENTITY_TYPE, SHOW_CUSTOM_CONTACT_TYPES } from './CustomFieldConst';
import {getFormattedCustomFieldData} from './CustomFieldUtils';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {ToastType} from '../../../../utils/commonViewUtils';
import {useToast} from '../../../Toast/ToastProvider';
import {useIntl} from 'react-intl';
import {CustomFieldNoDataView} from './CustomFieldNoDataView';

export const CustomFieldView = (props: ICustomFieldView) => {
  const intl = useIntl();
  const toast = useToast();
  const [stateData, setStateData] = useState<ICustomFieldViewState>({
    openAddCustomFiledDrawer: false,
    loading: true,
    customFieldData: [],
    updateStatusLoading: false,
    selectedCustomField: undefined,
    addUpdateCustomFieldLoading: false,
    offSet: 0,
    pageNo: 1,
    limit: CUSTOM_FIELD_GET_DATA_LIMIT,
    total: 0,
  })

  const onDrawerVisibleChange = (isOpen: boolean, rowRecord?: ICustomFieldData)=> {
    setStateData((prev)=> {
      return {
        ...prev,
        addUpdateCustomFieldLoading: false,
        openAddCustomFiledDrawer: isOpen,
        selectedCustomField: isOpen ? rowRecord : {} as ICustomFieldData,
      }
    })
  }

  const getCustomFiled = async (searchString: string) => {
    setStateData((prev)=> {
      return {
        ...prev,
        loading: true
      }
    })
    const params = {
      pageNo: stateData?.pageNo,
      pageSize: stateData?.limit,
      searchString: searchString,
      entityType: ENTITY_TYPE.CONTACT,
      showCustomContactTypes: SHOW_CUSTOM_CONTACT_TYPES,
    }
    try {
      const response = await getCustomFieldData(params);
      if (response?.data?.customAttributes?.length) {
        const customField = response?.data?.customAttributes;
        const formattedData = getFormattedCustomFieldData(customField);
        setStateData((prev)=> {
          return {
            ...prev,
            customFieldData: formattedData,
            loading: false,
            total: response?.data?.count,
          }
        })
      } else {
        setStateData((prev)=> {
          return {
            ...prev,
            loading: false,
            customFieldData: []
          }
        })
      }
    } catch (error: any) {
      setStateData((prev)=> {
        return {
          ...prev,
          loading: false
        }
      })
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  }

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const onStatusChange = async (rowRecord: ICustomFieldData) => {
    setStateData((prev) => ({
      ...prev,
      updateStatusLoading: true,
    }));
    const enableDisableCustomFiledPayload = {
      id: rowRecord?.id,
      body: {
        isDisabled: rowRecord?.value || false
      }
    }
    try {
      const response = await enableDisableCustomField(enableDisableCustomFiledPayload);
      if (response?.data?.id) {
        const updatedList = stateData?.customFieldData?.map((item: ICustomFieldData) =>
          item?.id === response?.data?.id
            ? {...item, active: response?.data?.isDisabled ? false : true}
            : item
        );
        setStateData((prev) => ({
          ...prev,
          customFieldData: updatedList,
          updateStatusLoading: false,
        }));
        showToast({
          toastType: ToastType.success,
          message: intl.formatMessage({id: 'statusUpdatedSuccessfully'}),
          duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
          closeAllPrevToast: true,
        })
      } else {
        setStateData((prev) => ({
          ...prev,
          updateStatusLoading: false
        }));
      }
    } catch (error: any) {
      setStateData((prev) => ({
        ...prev,
        updateStatusLoading: false,
      }));
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  }

  const onCustomFieldAddUpdateSuccess = (response: ICustomFieldData) => {
    const customFieldId: IContactTypes | any = response?.contactTypes?.[0];
    const contactTypes = customFieldId === ALL_CONTACT_TYPE.id ? [ALL_CONTACT_TYPE] : response?.contactTypes;
    let updatedList: ICustomFieldData[] = [];
    if (stateData?.selectedCustomField?.id) {
      if (stateData?.pageNo === 1) {
        const filteredList = stateData?.customFieldData?.filter((item) => {
          return item?.id !== response?.id;
        })
        updatedList = [
          {...response, contactTypes},
          ...filteredList,
        ];
      } else {
        updatedList = stateData?.customFieldData?.map(item => {
          return item?.id !== response?.id ? item : {...response, contactTypes}
        });
      }
    } else {
      if (stateData?.pageNo === 1) {
        updatedList = [
          {...response, contactTypes},
          ...stateData?.customFieldData
        ].slice(0, 10);
      } else {
        updatedList = stateData?.customFieldData;
      }
    }
    setStateData((prev) => ({
      ...prev,
      customFieldData: updatedList,
    }));
    showToast({
      toastType: ToastType.success,
      message: intl.formatMessage({id: stateData?.selectedCustomField?.id ? 'customFieldUpdateMsg' : 'customFieldAddedMsg'}),
      duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: true,
    })
    onDrawerVisibleChange(false);
    props?.onViewChangeCallback?.();
  }

  const onCustomFieldAddUpdateFailed = (error: any) => {
    onDrawerVisibleChange(false);
    props?.onViewChangeCallback?.();
    showToast({
      toastType: ToastType.error,
      message: error.response.data.message || intl.formatMessage({id: 'apiErrorMsg'}),
      duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
      closeAllPrevToast: true,
    })
  }

  const onActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        onDrawerVisibleChange(true, actionData);
        break;
      case COMMON_ACTION_CODES.STATUS_CHANGED:
        onStatusChange(actionData);
        break;
      case COMMON_ACTION_CODES.SUCCESS:
        onCustomFieldAddUpdateSuccess(actionData);
        break;
      case COMMON_ACTION_CODES.FAILED:
        onCustomFieldAddUpdateFailed(actionData);
        break;
      default:
        break;
    }
  };

  const onPagination = (page: number, pageSize: number) => {
    const pageNumber = page;
    const pageLimit = pageSize;
    const offset = pageNumber * pageLimit - pageLimit;
    setStateData((prev) => {
      return {
        ...prev,
        offSet: offset,
        pageNo: page,
        limit: pageLimit,
      }
    })
  };

  useEffect(() => {
    onDrawerVisibleChange(props?.openModal || false);
  }, [props.openModal]);

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        offSet: 0,
        pageNo: 1,
      }
    })
    if (props?.searchString) {
        getCustomFiled(props?.searchString);
    } else {
      getCustomFiled('');
    }
  }, [props.searchString]);

  useEffect(() => {
    if (props?.searchString) {
        getCustomFiled(props?.searchString);
    } else {
      getCustomFiled('');
    }
  }, [stateData?.pageNo, stateData?.limit]);

  return (
    <View
      style={{
        flex: 1
      }}
    >
      <View
        style={{
          flex: 1
        }}
      >
        {stateData?.openAddCustomFiledDrawer && <AddCustomFieldDrawer
          isDrawerOpen={stateData?.openAddCustomFiledDrawer}
          onDrawerClose={() => {
            onDrawerVisibleChange(false);
            props?.onViewChangeCallback?.();
          }}
          selectedCustomField={stateData?.selectedCustomField}
          onActionPerformed={onActionPerformed}
        />}
        {!stateData?.loading && stateData?.customFieldData?.length === 0 ? (
          <View style={{
            height: 600,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <CustomFieldNoDataView
              title={'createCustomField'}
              buttonMessage={'createFirstCustomField'}
              onCreateFirstCustomField={()=> {
                setStateData((prev)=> {
                  return {
                    ...prev,
                    openAddCustomFiledDrawer: true
                  }
                })
              }}
            />
          </View>) : <CustomFieldTable
            onPagination={onPagination}
            pageNo={stateData?.pageNo}
            pageSize={stateData?.limit}
            total={stateData?.total}
            data={stateData?.customFieldData}
            onActionPerformed={onActionPerformed}
            loading={stateData?.updateStatusLoading || stateData?.loading}
          />
        }
      </View>
    </View>
  );
};

