import React from 'react';
import { ColumnsType } from "antd/lib/table";
import { DisplayText } from "../../common/DisplayText/DisplayText";
import { ITagsTableData } from "../Contacts/Tags/interfaces";
import { Colors } from "../../../styles/Colors";
import { View, Text, Pressable, StyleSheet } from "react-native";
import Stack from "../../common/LayoutComponents/Stack";
import BillingActions from "./CareProgramBIllingComponent/BillingActions";
import MemberInfoListItem from "../../common/MemberInfoListItem/MemberInfoListItem";
import { DATE_FORMATS } from "../../../constants/StringConst";
import { getDiffInMonths, getFormattedDate, getMomentObj, getMonthAndYearFromDate } from "../../../utils/DateUtils";
import { IBilledActivityRecord, IBillingData, ICareProgramCptCode, IContactCareProgramAttributesAndCodes, IHandleBillingHistoryTableActions, IHandleTableActions, IHookState } from "./BillingHooks/BillingHookInterfaces";
import { CARE_PROGRAM_TYPE_CODES } from "../../../constants/MlovConst";
import { BILLING_ATTRIBUTES } from "./CareManagementConstants";
import { COMMON_ACTION_CODES } from "../../../constants/ActionConst";
import { ehrCodeDisplay } from "../Contacts/TeamMembers/AddEditUser/interfaces";
import { getEhrDisplayName } from "../../common/Audit/AuditViewHelper";
import BillingHistoryActions from "./CareProgramBIllingComponent/BillingHistoryActions";
import { IBatchedAccountUsersInterface } from "../../CustomHooks/useGetBatchedAccountUsers";
import { IMlov } from "../../../Interfaces/CommonInterfaces";
import { chronicCondtionsView } from '../Contacts/Leads/LeadView/LeadTableView/Helper/LeadListCommonViews';

const styles = StyleSheet.create({
  columnTitle: {
    color: Colors.Custom.Gray500,
    marginLeft: 8,
  },
  columnContent: {
    color: Colors.Custom.Gray900,
  },
  dotIndicator: {
    width: 5,
    height: 5,
    borderRadius: 4,
  },
  cptCodeText: {
    color: Colors.Custom.Gray900,
    fontWeight: '500',
  },
  cptCodeDuration: {
    color: Colors.FoldPixel.GRAY300,
    fontSize: 12,
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  stackRow: {
    justifyContent: 'flex-start',
  },
});

export const getCareProgramBillingColumns = (
  handleTableActions: (params: IHandleTableActions) => void,
  hookState: IHookState,
  accountUserList: IBatchedAccountUsersInterface[],
  attributeAndCodesLoading: boolean,
  handleActions: any,
  careProgramTypes: IMlov[],
  isBilledView?: boolean
) => {
  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="memberNameCapsOn"
        />
      ),
      dataIndex: 'contactData',
      key: 'member',
      fixed: 'left',
      width: '25%',
      ellipsis: true,
      render: (item: IBillingData['contactData']) => (
        <MemberInfoListItem
          contactData={item}
          showEmail={false}
          showDateOfBirth={true}
        />
      ),
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="ehrId"
        />
      ),
      dataIndex: 'contactData',
      key: 'ehrId',
      width: '10%',
      render: (contactData: IBillingData['contactData']) => (
        <View style={styles.flexStart}>
          <Text style={styles.columnContent}>
            {contactData?.patient?.patientId || '-'}
          </Text>
        </View>
      ),
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="dateOfService"
        />
      ),
      dataIndex: 'serviceDateTime',
      key: 'dateOfService',
      width: '15%',
      render: (serviceDateTime: IBillingData['serviceDateTime']) => (
        <Stack direction={'row'} style={styles.stackRow}>
          <View style={styles.dotIndicator} />
          <Text style={styles.columnContent}>
            {getFormattedDate(
              serviceDateTime,
              DATE_FORMATS.BILLABLE_DATE_OF_SERVICE
            )}
          </Text>
        </Stack>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="programType"
        />
      ),
      dataIndex: 'careProgramTypeId',
      key: 'color',
      width: '15%',
      render: (careProgramTypeId: IBillingData['careProgramTypeId']) => (
        <Stack direction={'row'} style={styles.stackRow}>
          <View style={styles.dotIndicator} />
          <Text style={styles.columnContent}>
            {
              careProgramTypes?.find((type) => type.id === careProgramTypeId)
                ?.value
            }
          </Text>
        </Stack>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="timeSpent"
        />
      ),
      dataIndex: 'totalDurationInMinutes',
      key: 'durationInMinutes',
      width: '15%',
      render: (
        totalDurationInMinutes: IBillingData['totalDurationInMinutes']
      ) => (
        <Stack direction={'row'} style={styles.stackRow}>
          <View style={styles.dotIndicator} />
          <Text style={styles.columnContent}>
            {totalDurationInMinutes} Mins
          </Text>
        </Stack>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="bill"
        />
      ),
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      width: '10%',
      render: (totalAmount: IBillingData['totalAmount']) => (
        <Stack direction={'row'} style={styles.stackRow}>
          <View style={styles.dotIndicator} />
          <Text style={styles.columnContent}>${totalAmount}</Text>
        </Stack>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="provider"
        />
      ),
      dataIndex: 'providerUserDetails',
      key: 'color',
      width: '20%',
      render: (providerUserDetails: IBillingData['providerUserDetails']) => (
        <Stack direction={'row'} style={styles.stackRow}>
          <View style={styles.dotIndicator} />
          <Text style={styles.columnContent}>
            {providerUserDetails?.name || '-'}
          </Text>
        </Stack>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="chronicCondition"
        />
      ),
      dataIndex: 'chronicConditionWithPatientId',
      key: 'chronicCondition',
      width: '25%',
      ellipsis: true,
      render: (
        chronicCondition: IBillingData['chronicConditionWithPatientId'],
        record: IBillingData
      ) => {
        return chronicCondtionsView({
          conditionNametextStyles: styles.columnContent,
          monthTextStyles: styles.columnContent,
          chronicConditions: chronicCondition,
        });
      }
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      key: 'actions',
      fixed: 'right',
      width: '15%',
      render: (record: IBillingData) => {
        return (
          <View>
            <BillingActions
              handleTableActions={handleTableActions}
              record={record}
              hookState={hookState}
              accountUserList={accountUserList}
              attributeAndCodesLoading={attributeAndCodesLoading}
              handleActions={handleActions}
              isBilledView={isBilledView}
            />
          </View>
        );
      },
    },
  ];
  return columns;
};

export const getAttributesListToDisplay = (
  billingData: IBillingData,
  careProgramTypes: IMlov[],
  attributesAndCodes?: IContactCareProgramAttributesAndCodes['contactCareProgram'],
): {labelId: string; value: string}[] => {
  const faceToFaceDate = attributesAndCodes?.additionalAttributes?.find(
    (attribute) =>
      attribute.attributeKey === BILLING_ATTRIBUTES.FACE_TO_FACE_DATE
  )?.attributeValue;
  const dischargeDateTime = attributesAndCodes?.additionalAttributes?.find(
    (attribute) =>
      attribute.attributeKey === BILLING_ATTRIBUTES.DISCHARGE_DATE_TIME
  )?.attributeValue;
  const firstContactDateTime = attributesAndCodes?.additionalAttributes?.find(
    (attribute) =>
      attribute.attributeKey === BILLING_ATTRIBUTES.FIRST_CONTACT_DATE_TIME
  )?.attributeValue;
  const careProgramType =
    careProgramTypes?.find((type) => type.id === billingData.careProgramTypeId)
      ?.value || '-';
  const attributesToDisplay = [
    {
      labelId: 'billedUnder',
      value: careProgramType,
    },
    {
      labelId: 'medicalDecisionMaking',
      value:
        attributesAndCodes?.additionalAttributes.find(
          (attribute) =>
            attribute.attributeKey === BILLING_ATTRIBUTES.COMPLEXITY
        )?.attributeValue || '-',
    },
  ];
  if (careProgramType === CARE_PROGRAM_TYPE_CODES.TCM) {
    attributesToDisplay.push(
      {
        labelId: 'dischargeDate',
        value: dischargeDateTime
          ? getFormattedDate(
              dischargeDateTime,
              DATE_FORMATS.CALENDAR_LIB_FORMAT
            )
          : '-',
      },
      {
        labelId: 'firstContactDate',
        value: firstContactDateTime
          ? getFormattedDate(
              firstContactDateTime,
              DATE_FORMATS.CALENDAR_LIB_FORMAT
            )
          : '-',
      }
    );
  }
  attributesToDisplay.push(
    {
      labelId: 'providerSmall',
      value: billingData?.providerUserDetails?.name || '-',
    },
    {
      labelId: 'F2FVisitDate',
      value: faceToFaceDate
        ? getFormattedDate(faceToFaceDate, DATE_FORMATS.CALENDAR_LIB_FORMAT)
        : '-',
    }
  );
  return attributesToDisplay;
};

export const getCptCodeAmountBillingColumns = () => {
  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="applicableCptCode"
        />
      ),
      dataIndex: 'cptCode',
      key: 'cptCode',
      width: '70%',
      render: (cptCode: string, record: ICareProgramCptCode) => (
        <View style={styles.flexStart}>
          <Text style={styles.cptCodeText}>{cptCode}</Text>
          <Text style={styles.cptCodeDuration}>{record.durationInMinutes} Mins</Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="amountCapsOn"
        />
      ),
      dataIndex: 'amount',
      key: 'amount',
      width: '30%',
      render: (amount: number) => (
        <View style={styles.flexStart}>
          <Text style={styles.cptCodeText}>${amount.toFixed(2)}</Text>
        </View>
      ),
    },
  ];
  return columns;
};

export const getCareProgramBillingHistoryColumns = (
  handleBillingHistoryTableActions: (params: IHandleBillingHistoryTableActions) => void,
  ehrList: ehrCodeDisplay[],
  timezone: string,
) => {
  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="monthlyReport"
        />
      ),
      dataIndex: 'month',
      key: 'monthlyReport',
      width: '30%',
      render: (month: string) => (
        <View style={styles.flexStart}>
          <Text style={styles.cptCodeText}>
            {getMonthAndYearFromDate(month, timezone)}
          </Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="estBillingAmount"
        />
      ),
      dataIndex: 'totalAmount',
      key: 'billingAmount',
      width: '30%',
      render: (totalAmount: number) => (
        <View style={styles.flexStart}>
          <Text style={styles.cptCodeText}>
            $ {totalAmount}
          </Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="totalPatients"
        />
      ),
      dataIndex: 'distinctContactIdCount',
      key: 'totalPatients',
      width: '30%',
      render: (distinctContactIdCount: number) => (
        <View style={styles.flexStart}>
          <Text style={styles.cptCodeText}>
            {distinctContactIdCount}
          </Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="integratedEhr"
        />
      ),
      dataIndex: 'ehrCode',
      key: 'integratedEhr',
      width: '30%',
      render: (ehrCode: string) => (
        <View style={styles.flexStart}>
          <Text style={styles.cptCodeText}>
            {getEhrDisplayName(ehrCode, ehrList)}
          </Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="actionCapsOn"
        />
      ),
      key: 'action',
      width: '30%',
      render: (record: IBilledActivityRecord) => (
        <BillingHistoryActions handleBillingHistoryTableActions={handleBillingHistoryTableActions} record={record} timezone={timezone}/>
      ),
    },
  ];
  return columns;
};