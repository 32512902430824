import { Input,  Text, Box, View } from "native-base";
import { Colors } from "../../../styles/Colors";
import { IInputTextWithCharacterLimit } from "./interfaces";
import React, { useState } from 'react';
import Stack from "../LayoutComponents/Stack";
const InputTextWithCharacterLimit = (props: IInputTextWithCharacterLimit) => {
  const [value, setValue] = useState<string>('')
  return (
    <Stack direction="column" style={{
      alignItems: 'flex-end'
    }}>
      <Input
        _hover={{ borderColor: Colors.FoldPixel.PRIMARY_FOCUS }}
        padding={'8px'}
        width={'100%'}
        fontSize={'14px'}
        fontWeight={'400'}
        fontStyle={'normal'}
        borderRadius={'4px'}
        color={Colors.Custom.Gray600}
        alignSelf={'stretch'}
        _focus={{ borderColor: Colors.FoldPixel.PRIMARY_FOCUS, backgroundColor: '#F9FAFB' }}
        borderWidth={0.5}
        borderColor={Colors.FoldPixel.GRAY200}
        isFullWidth={true}
        placeholder={props?.placeHolder}
        // value={props?.value}
        value={value}
        // lineHeight={props.lineHeight}
        numberOfLines={props.numberOfLines - 1}
        multiline={true}
        maxLength={props.characterLimit} // Set the maxLength prop
        // onChangeText={props.onChangeText}
        onChangeText={(text: string) => {
          setValue(text);
          props?.onChangeText?.(text);
        }}
        // This ensures the position is relative to the containing Box
        // position="relative"
      />
      <View>
      <Text fontSize={12} lineHeight={14.4} fontWeight={400} color={Colors.FoldPixel.GRAY250}>
        {value.length}/{props?.characterLimit}
      </Text>
      </View>
    </Stack>
  );
};

export default InputTextWithCharacterLimit;