import {HStack, Select, VStack} from 'native-base';
import {useState} from 'react';
import {Dimensions, Text} from 'react-native';
import {dummySegmentTypes} from '../../../../../../../dummyData/Campaigns/segmentTypes';
import {CAMPAIGN_CONSTANTS} from '../../../CampaignConstants';
import {styles} from '../../../CampaignStyles';
import {dummyTagsData} from '../../../../../../../dummyData/Campaigns/campaignFilterDummyData';
import {TagListCampaign} from './TagListCampaign';

export const CampaignStepTwoScreen = () => {
  const {height} = Dimensions.get('window');

  const [currentMessage, setCurrentMessage] = useState(
    CAMPAIGN_CONSTANTS.SEGMENT
  );
  const [segmentType, setSegmentType] = useState('');

  return (
    <HStack flex={1} height={height - 182}>
      <VStack flex={0.5} style={styles.leftStack} space={10}>
        <VStack space={3} style={styles.selectScreenTwo}>
          <Text>{CAMPAIGN_CONSTANTS.CHOOSE_MESSAGE}</Text>
          <Select
            selectedValue={currentMessage}
            onValueChange={(itemValue) => setCurrentMessage(itemValue)}
          >
            <Select.Item
              label={CAMPAIGN_CONSTANTS.SEGMENT}
              value={CAMPAIGN_CONSTANTS.SEGMENT}
            />
            <Select.Item
              label={CAMPAIGN_CONSTANTS.TAG}
              value={CAMPAIGN_CONSTANTS.TAG}
            />
          </Select>
        </VStack>

        {currentMessage === CAMPAIGN_CONSTANTS.SEGMENT && (
          <VStack space={3} style={styles.selectScreenTwo}>
            <Text>{CAMPAIGN_CONSTANTS.SEGMENT_TYPE}</Text>
            <Select
              selectedValue={segmentType}
              _selectedItem={{
                bg: 'teal.600',
              }}
              onValueChange={(itemValue) => setSegmentType(itemValue)}
            >
              {dummySegmentTypes.map((type: string, index) => (
                <Select.Item key={index} label={type} value={type} />
              ))}
            </Select>
          </VStack>
        )}
        <Text>Choose a {currentMessage}</Text>
        <VStack style={styles.blStack}>
          {currentMessage === CAMPAIGN_CONSTANTS.TAG && (
            <TagListCampaign tagList={dummyTagsData} />
          )}
        </VStack>
      </VStack>

      <VStack flex={0.5}>Work in Progress</VStack>
    </HStack>
  );
};
