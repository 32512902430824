import { MonitoringActivityCodes } from "../../../utils/VitalUtils";

export enum AllowedWearableDataCategories {
    activity = "activity",
    biomarkers = "biomarkers"
}

export const WEARABLE_DATA_SYNC_CATEGORIES = [
    {
        key: AllowedWearableDataCategories.biomarkers,
        value: "Biomarkers"
    },
    {
        key: AllowedWearableDataCategories.activity,
        value: "Activity",
    },
]

export const HIDE_WEARABLES_DATA_CONFIGURATION : MonitoringActivityCodes[] = []