export interface ITaskPoolTableData {
  newPoolUsers: string[];
  newPoolName: string;
  poolId?: string;
  poolUsers?: string[];
  isDefault?: string;
  poolLocations: { locationId?: string; isDeleted?: boolean ; id?: string }[];
}

export interface IAddPoolModalProps {
  title: string;
  isModalOpen: boolean;
  onModalClose: () => void;
  onSave: (newPool: ITaskPoolTableData) => void;
  usersData: any;
  selectedTaskData?: any;
  isEdit: boolean;
  isUserDataLoading?: boolean;
}

export const TASK_ACTIONS_TYPE = {
  EDIT: 'edit',
  DELETE: 'delete',
  ADD: 'add',
};

export interface ITaskManagementInterface {
  showTableTopBar?: boolean;
  searchString?: string;
  selectedActionCode?: string;
  selectedRawData?: any;
  onClose?: (actionCode: string, actionData?: any) => void;
}

export interface IUser {
  id: number;
  uuid: string;
  name: string;
  email: string;
  userPracticeLocations: {
    accountLocationUuid?: string;
  }[];
  userRoles?: {
    id: string;
    accountLocationId: string | null;
    userRole?: {
      id: string;
      roleId: string;
      roleName: string;
      customRoleCode: string;
    };
  }[];
}