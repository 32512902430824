import {StyleSheet} from "react-native";
import {Colors} from "../../../../../../styles";

export const style = StyleSheet.create({
  stagesContainer: {
    width: '100%',
    overflow: 'hidden',
    borderWidth: 0,
    padding: '20px',
  },
  stagesitems: {
    width: '90%',
    marginHorizontal: 'auto',
    paddingVertical: '7px',
    marginVertical: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7
  },
  closeButton: {
    backgroundColor: 'transparent',
  },
  buttonStyle: {
    backgroundColor: 'transparent',
    width: '80%',
    justifyContent: 'flex-start',
    paddingHorizontal: '40px',
  },
  textStyle: {
    color: Colors.Custom.PrimaryColor,
  },
})