import { notification } from 'antd';
import { useEffect, useState } from 'react';
import {useIntl} from 'react-intl';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {useMutation} from '@apollo/client';
import {AddTagModal} from '../../../Contacts/Tags/TagsModal';
import {ITagsTableData} from '../../../Contacts/Tags';
import TagQueries from '../../../../../services/Tags/TagQueries';
import {getAccountId} from '../../../../../utils/commonUtils';
import {IAddPackagesLabel} from './interfaces';
import {createLabelAndLabelType} from './PackageServices';


export const AddPackagesLabel = (props: IAddPackagesLabel) => {
  const intl = useIntl();
  const accountId = getAccountId();

  const [createLabel] = useMutation(TagQueries.CreateLabel);
  const [createLabelType] = useMutation(TagQueries.CreateLabelType);

  const onSubmit = async (newTag: any) => {
    props?.setLoading?.(true);
    try {
      const createLabelBody = [{
        accountId: accountId,
        color: newTag?.color,
        description: newTag?.newTagDescription,
        title: newTag?.newTagName,
        labelTypeId: newTag?.categoryId
      }]
      const response = await createLabelAndLabelType(createLabelBody);
      const tag = response?.data?.[0];
      props?.setLoading?.(false);
      const responseObj = {
        id: tag?.id,
        color: tag?.color,
        title: tag?.title,
        description: tag?.description,
      }
      props?.onActionPerformed?.(COMMON_ACTION_CODES.SUCCESS, responseObj);
    } catch (error: any) {
      props?.setLoading?.(false);
      const message = error?.response?.data?.message || error?.networkError?.result?.message
      notification.error({
        message: message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: 5.0,
        placement: 'topRight'
      });
    }
  }

  return (
    <AddTagModal
      name={props?.name}
      title={'Add Tag - Package'}
      category={props?.category}
      isModalOpen={props?.isDrawerOpen}
      onModalClose={() => {
        props?.onDrawerClose?.();
      }}
      onSave={(completeTag: ITagsTableData) => onSubmit(completeTag)}
    />
  );
};