import {Button, HStack, Radio, View} from 'native-base';
import React from 'react';
import {Dimensions} from 'react-native';
import {Colors} from '../../../../styles';
import {EmailComponents} from '../EmailComponents';
import EmailTemplatesListView from './EmailTemplatesListView';

const EmailTemplates = (props: any) => {
  const {height} = Dimensions.get('window');

  return (
    <View>
      <EmailTemplatesListView
        page={props.page}
        searchString={props.searchString}
        showTableTopBar={props.showTableTopBar}
      />
    </View>
  );
};

export default EmailTemplates;
