import {Button, FormControl, Input, Modal} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import { BUTTON_TYPE } from '../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
// import {IBoardModalProps} from './Interfaces';

const BoardModal = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors]: any = React.useState({});
  const boardData = props.boardData || {};
  let boardName: string;
  const intl = useIntl()
  const validate = (boardName: any) => {
    if (boardName === undefined) {
      setErrors({
        ...errors,
        name: 'Name is required',
      });
      return false;
    } else if (boardName.length < 3) {
      setErrors({
        ...errors,
        name: 'Name is too short',
      });
      return false;
    } else {
      setErrors({
        ...errors,
        name: 'Name is too short',
      });
    }
    return true;
  };

  const onSubmit = () => {
    if (validate(boardName)) {
      if (boardData.id) {
        const boardObj = {
          id: boardData.id,
          title: boardName,
        };
        setShowModal(false);
        props.onActionPerformed(
          COMMON_ACTION_CODES.EDIT,
          boardName,
          showModal,
          boardObj
        );
        handleModalState(false);
      } else {
        setShowModal(false);
        props.onActionPerformed(
          COMMON_ACTION_CODES.SAVE,
          boardName,
          showModal,
          {}
        );
        handleModalState(false);
      }
      setErrors({});
    } else {
      handleModalState(true);
    }
  };

  const onCanelForm = () => {
    handleModalState(false);
    props.onActionPerformed('CANCEL', {}, false);
  };

  const handleModalState = (modalState: boolean) => {
    props.handleModalState(modalState);
  };

  return (
    <>
      <Modal
        isOpen={props.showModal}
        onClose={() => {
          onCanelForm();
        }}
      >
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton />
          <Modal.Header>
            {boardData.id ? 'Edit Board' : 'New Board'}
          </Modal.Header>
          <Modal.Body>
            <FormControl isRequired isInvalid={errors.name}>
              <FormControl.Label>Name</FormControl.Label>
              <Input
                defaultValue={boardData ? boardData.title : ''}
                onChangeText={(value) => {
                  boardName = value;
                }}
              />
              {errors.name && (
                <FormControl.ErrorMessage
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                >
                  {errors.name}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
                <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    onCanelForm();
                  },
                  style: {marginRight: 20}
                }}
                customProps={{
                  btnText: intl.formatMessage({id: 'cancel'}),
                  withRightBorder: false,
                }}
              ></FoldButton>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    onSubmit();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({id: 'save'}),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default BoardModal;
