import { Dimensions, StyleSheet } from 'react-native';
import { Colors } from '../../../../styles/Colors';

const { width } = Dimensions.get('window');
const borderColor = '#D0D0D0';

export const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: borderColor,
    borderRadius: 20,
    overflow: 'hidden',
    backgroundColor: Colors.Custom.BackgroundColor,
    marginVertical: 0,
    paddingTop: 12,
    paddingBottom: 12,
  },
  titleHeader: {
    paddingBottom: 10,
    fontWeight: '300',
    fontSize: 18,
    color: '#000000',
    marginLeft: 8
  },
  titleContainer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    alignContent: 'center',
  },
  titleText: {
    fontWeight: '600',
    fontSize: 18,
    lineHeight: 23,
  },
  titleCardText: {
    fontWeight: '700',
    fontSize: 18,
    marginTop: 0,
  },
  descriptionText: {
    fontWeight: '400',
    fontSize: 16,
    color: '#989898',
  },

  levelContainer: {
    borderBottomColor: '#CECECE',
    borderBottomWidth: 1,
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  levelText: {
    textTransform: 'uppercase',
  },
  listContainer: {
    //marginTop: 8,
    // padding: 12,
  },
});
