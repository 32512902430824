import {StyleSheet} from 'react-native';
import { Colors } from '../../../../styles/Colors';

export const styles = StyleSheet.create({
  userNameView: {
    flex: 0.2,
  },
  featherIcon: {
    marginLeft: 2,
    marginRight: 2,
    fontSize: 14.29,
    color: Colors.Custom.Gray600,
  },
  transparentButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
  },
});
