
import {Upload, UploadProps} from "antd";
import FeatherIcon from 'react-native-vector-icons/Feather';
import {Pressable, View, VStack, Text, } from "native-base";
import {Colors} from "../../../../../../styles";
import {COMMON_ACTION_CODES} from "../../../../../../constants/ActionConst";
import DownloadCptView from "./DownloadCpt";
import {CSVLink} from "react-csv";
import Papa from "papaparse";
import {IUploadCsvProps} from "../interfaces/savingTemplatesInterface";
import {convertDataToCsvArrays, formatFileSize} from "./utils";
const {Dragger} = Upload;
const UploadCptCsv = (props: IUploadCsvProps) => {
  const {file, dataList, columnNameArrayList} = props;

  const uploadProps: UploadProps = {
    name: 'file',
    beforeUpload(file, FileList) {
      parseCsvFile(file)
    },
    accept: 'text/csv',
    showUploadList: false,
    multiple: false,
    onDrop(e) {
      const file = e.dataTransfer?.files[0]
      parseCsvFile(file)
    },
  };
  const headers = columnNameArrayList?.map((columnName: string) => {
    return {label: columnName, key: columnName}
  })
  const csvUnParseObj = convertDataToCsvArrays(headers, dataList)
  const csvString = Papa.unparse(csvUnParseObj);
  const csvFileSizeBlob = new Blob([csvString]);
  const fileSize = csvFileSizeBlob.size;
  const parseCsvFile = (file: any) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray: any = [];
        const valuesArray: any = [];
        results.data.map((d: any) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });
        const keyAndValueData = results.data;
        const columnNameArrayList = rowsArray[0]
        const columnValueArrayList = valuesArray
        props.onActionPerformed(COMMON_ACTION_CODES.UPLOAD, {
          keyAndValueData,
          columnNameArrayList,
          columnValueArrayList,
          file
        })
      },
      error(error, file) {
        props.onActionPerformed(COMMON_ACTION_CODES.CANCEL, {
          keyAndValueData: [],
          columnNameArrayList: [],
          columnValueArrayList: []
        })
      },
    });
  }
  return (
    <>
      {file.name && <VStack
        style={{
          padding: 24,
          marginTop: 16,
          borderRadius: 8,
          flex: 1,
          borderWidth: 1,
          borderColor: Colors.Custom.Gray100,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <VStack style={{flexDirection: 'row', alignItems: 'center'}}>
          <View style={{
            flexDirection: 'row',
            padding: 8,
            alignItems: 'center',
            borderWidth: 1,
            borderColor: Colors.Custom.Gray100,
            marginRight: 16,
            backgroundColor: Colors.Custom.Gray50
          }}
            borderRadius={'50%'}
          >
            <FeatherIcon
              color={Colors.Custom.Gray300}
              name="file"
              size={24}
            />
          </View>
          <View >
            <Text
              size={'smSemibold'}
              style={{color: '#191D23', marginRight: 10}}
            >
              {file.name}
            </Text>
            <Text
              size={'smMedium'}
              style={{color: Colors.Custom.Gray400}}
            >
              {(file.size || fileSize) ? formatFileSize(file.size || fileSize) : ''}
            </Text>
          </View>
        </VStack>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Upload {...uploadProps}>
            <Text size={'xsMedium'} style={{color: '#8C5AE2'}}>Change File</Text>
          </Upload>
          <View
            style={{
              height: 16,
              width: 1, backgroundColor: Colors.Custom.Gray100, marginHorizontal: 12
            }}
          />
          <CSVLink filename={`${file?.name}`} data={dataList} headers={headers}>
            <FeatherIcon
              color={Colors.Custom.Gray300}
              name="download"
              size={15}
            />
          </CSVLink>
        </View>
      </VStack>}

      {!file.name && <Dragger {...uploadProps} style={{
        borderRadius: 8,
        flex: 1,
        marginTop: 20
      }}>
        <VStack
          style={{
            padding: 12,
            borderRadius: 8,
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          <Pressable
          >
            <VStack justifyContent={'center'} alignItems={'center'}>
              <FeatherIcon
                color={Colors.Custom.Gray300}
                name="upload"
                size={24}
              />
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text
                  size={'xsSemibold'}
                  style={{color: Colors.Custom.Gray400, marginRight: 10}}
                >
                  Drag and drop file here or
                </Text>
                <Upload {...uploadProps}>
                  <Text
                    size={'xsSemibold'}
                    style={{color: Colors.Custom.mainPrimaryPurple}}
                  >
                    {' Choose File'}
                  </Text>
                </Upload>
              </View>
            </VStack>
          </Pressable>
        </VStack>
      </Dragger>
      }
    </>
  )
}
export default UploadCptCsv;
