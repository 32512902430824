import { gql } from '@apollo/client';

export const FETCH_COMMUNICATION_TRACK_RECORDS = gql`
  query fetchCommunicationTrackRecords(
    $value: String
    $limit: Int
    $offset: Int
    $communicationModeId: uuid!
  ) {
    communicationTrackRecords(
      order_by: {updatedAt: desc}
      limit: $limit
      offset: $offset
      where: {
        value: {_ilike: $value}
        isDeleted: {_eq:false}
        communicationModeId: {_eq: $communicationModeId}
      }
    ) {
      id
      value
    }
    communicationTrackRecordsAggregate(
      where: {
        value: {_ilike: $value}
        isDeleted: {_eq:false}
        communicationModeId: {_eq: $communicationModeId}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const ADD_OR_UPDATE_COMMUNICATION_TRACK_RECORDS = gql`
  mutation addOrUpdateCommunicationTrackRecords(
    $record: communication_track_record_insert_input!
  ) {
    createCommunicationTrackRecord(
      object: $record
      on_conflict: {
        constraint: communication_track_record_pkey
        update_columns: [
          value
          communicationModeId
          createdBy
          updatedBy
          updatedAt
        ]
      }
    ) {
      id
      value
    }
  }
`;

export const DELETE_COMMUNICATION_TRACK_RECORDS =  gql`mutation deleteCommunicationTrackRecords($id: uuid!) {
  updateCommunicationTrackRecord(pk_columns: {id: $id}, _set: {isDeleted: true}) {
    id
    isDeleted
  }
}
`;

export default {
  FETCH_COMMUNICATION_TRACK_RECORDS,
  ADD_OR_UPDATE_COMMUNICATION_TRACK_RECORDS,
  DELETE_COMMUNICATION_TRACK_RECORDS
};
