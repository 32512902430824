import React, {useContext} from 'react'
import {CommonDataContext} from '../../context/CommonDataContext';

const usePractionLocationUUIDsByAccountLocationIds = () => {
  const {accountLocationListWithEHR} = useContext(CommonDataContext);
  const allAccountLocationList = accountLocationListWithEHR || [];
  const getPractionLocationUUIDsByAccountLocationIds = (
    accountLocationIds: string[]
  ) => {
    const locationUuids: string[] = [];
    accountLocationIds.forEach((accountLocationId) => {
      const location = allAccountLocationList.find(
        (location) => location.uuid === accountLocationId
      );
      if (location?.practiceLocation?.uuid) {
        locationUuids.push(location.practiceLocation.uuid);
      }
    });

    return locationUuids;
  };

  return {
    getPractionLocationUUIDsByAccountLocationIds
  }
}

export default usePractionLocationUUIDsByAccountLocationIds