import {COMMON_ACTION_CODES} from "../../../../../../constants/ActionConst";

export enum SavingType {
  visitSaving = 'VISITS_SAVINGS',
  labSaving = 'LAB_SAVINGS',
  imageSaving = 'IMAGING_SAVINGS',
  procedureSaving = 'PROCEDURES_SAVINGS',
  avoidableVisitSaving = 'AVOIDABLE_VISIT_SAVING',
  avoidedWorkplaceAbsenceSaving = 'AVOIDED_WORKDAY_ABSENCE_SAVING'
}

export const  ACTIONS_CODE_CT = {
  SET_AS_DEFAULT: 'SET_AS_DEFAULT',
  BULK_ASSIGN: 'BULK_ASSIGN',
  EDIT: 'EDIT',
  DUPLICATE: 'DUPLICATE',
  DELETE: 'DELETE'
}

export const costTemplateAction = [
  {
    code: ACTIONS_CODE_CT.SET_AS_DEFAULT,
    name: 'Set as default'
  },
  {
    code: ACTIONS_CODE_CT.EDIT,
    name: 'Edit'
  },
  {
    code: ACTIONS_CODE_CT.DELETE,
    name: 'Delete'
  },
  {
    code: ACTIONS_CODE_CT.DUPLICATE,
    name: 'Duplicate'
  },
]

export const COST_TYPE_CODE = {
  MARKET_COST: 'MARKET_COST',
  OUR_COST: 'OUR_COST'
}

export const COST_SAVING_TYPE_FILTER = [
  {
    key: 'ALL',
    value: 'ALL',
    label: 'All',
  },

  {
    key: COST_TYPE_CODE.MARKET_COST,
    value: COST_TYPE_CODE.MARKET_COST,
    label: 'Market Cost',
  },
  {
    key: COST_TYPE_CODE.OUR_COST,
    value: COST_TYPE_CODE.OUR_COST,
    label: 'Our Service Cost',
  },
];

export const COST_SAVING_CPT_MAIN_ACTION = [
  {
    code: COMMON_ACTION_CODES.UPLOAD,
    label: 'Create cost template from CSV',
  },
  {
    code: COMMON_ACTION_CODES.DOWNLOAD,
    label: 'Download cost template as CSV',
  },
  {
    code: COMMON_ACTION_CODES.ADD,
    label: "Add CPT's",
  },
];

export const SavingTypeConst = {
  VISITS_SAVINGS: 'visitSaving',
  LAB_SAVINGS: 'labSaving',
  IMAGING_SAVINGS: 'imageSaving',
  PROCEDURES_SAVINGS: 'procedureSaving',
  AVOIDABLE_VISIT_SAVING: 'avoidableVisitSaving',
  AVOIDED_WORKDAY_ABSENCE_SAVING: 'avoidedWorkplaceAbsenceSaving',
};

export const SavingTypeNameConst: any = {
  visitSaving: 'Visit Saving',
  labSaving: 'Lab Saving',
  imageSaving: 'Image Saving',
  procedureSaving: 'Procedure Saving',
  avoidableVisitSaving: 'Avoidable VisitSaving',
  avoidedWorkplaceAbsenceSaving: 'Avoided Workplace Absence Saving',
};
