import {useMutation, useQuery} from '@apollo/client';
import {Steps} from 'antd';
import {HStack, Skeleton, View, VStack} from 'native-base';
import {useContext, useState} from 'react';
import {Dimensions} from 'react-native';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import { USER_ROLE_CODES } from '../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import CommonService from '../../../../../services/CommonService/CommonService';
import InboxQueries from '../../../../../services/Inbox/InboxQueries';
import UserQueries from '../../../../../services/User/UserQueries';
import {Colors} from '../../../../../styles';
import {commonBodyContentContainer} from '../../../../../styles/CommonBodyContentStyle';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import PurchaseNumberView from '../../../CloudTelephony/PurchaseNumberView/PurchaseNumberView';
import {INBOX_CHANNEL_TYPE, INTEGRATION_CONSTANTS} from '../constants';
import {attachAgentBotToInbox} from '../Helper/AgentBotInboxApi';
import {
  formatCreateInboxData,
  getAgentObject,
  getCreateInboxAPIData,
} from '../Helper/formatIntegrationsData';
import {styles} from '../IntegrationsStyles';
import EmailInboxDrawer from './EmailInboxCreate/EmailInboxDrawer';
import {IntegrationScreenFive} from './IntegrationScreens/IntegrationScreenFive';
import {IntegrationScreenFour} from './IntegrationScreens/IntegrationScreenFour';
import {IntegrationScreenOne} from './IntegrationScreens/IntegrationScreenOne';
import {IntegrationScreenThree} from './IntegrationScreens/IntegrationScreenThree';
import {IntegrationScreenTwo} from './IntegrationScreens/IntegrationScreenTwo';
import {IInboxChannelData} from './IntegrationScreens/interfaces';
import {SMS_INBOX_ACTION_CODES} from './SmsInboxCreate/SmsInboxConst';
import SmsInboxDrawer from './SmsInboxCreate/SmsInboxDrawer';

const {Step} = Steps;

export const IntegrationCreate = () => {
  const [searchParams] = useSearchParams();
  const accountUuid = getAccountUUID();
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData;
  const accountId = userData?.account_id;

  const [currentStep, setCurrentStep] = useState(
    INTEGRATION_CONSTANTS.SCREEN_ONE
  );
  const [addAgent] = useMutation(InboxQueries.AddAgent);

  const [currentData, setCurrentData] = useState<any>({});
  const [inboxCreateState, setInboxCreateState] = useState({
    inboxChannelCode: '',
    isBuyVirtualNumber: false,
  });
  const [newInboxId, setNewInboxId] = useState(0);
  const [webToken, setWebToken] = useState('');
  const [updateWidgetPreChat] = useMutation(InboxQueries.UpdateWidgetPreChat);

  const onBackClick = (screen: number): any => {
    setCurrentStep(screen);
  };

  const {loading: agentsLoading, data: accountUserData} = useQuery(
    InboxQueries.GetAgents,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        accountId: accountId,
        roleCode: USER_ROLE_CODES.EMPLOYER,
      },
    }
  );

  const {loading: userListLoading, data: userListResp} = useQuery(
    UserQueries.Get_Users_IVR,
    {
      fetchPolicy: 'no-cache',
      variables: {
        accountUUID: accountUuid,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
    }
  );

  const handleScreenOneChange = (
    screen: number,
    newScreenOneData: IInboxChannelData
  ): any => {
    const {inboxChannelCode} = newScreenOneData;
    switch (inboxChannelCode) {
      case INBOX_CHANNEL_TYPE.WEB_SITE:
        setCurrentStep(screen);
        setCurrentData((prev: any) => {
          return {
            ...prev,
            ...newScreenOneData,
          };
        });
        setInboxCreateState((prev: any) => {
          return {
            ...prev,
            inboxChannelCode: INBOX_CHANNEL_TYPE.WEB_SITE,
            isBuyVirtualNumber: false,
          };
        });
        break;
      case INBOX_CHANNEL_TYPE.SMS:
        setInboxCreateState((prev: any) => {
          return {
            ...prev,
            inboxChannelCode: INBOX_CHANNEL_TYPE.SMS,
            isBuyVirtualNumber: false,
          };
        });
        break;
      case INBOX_CHANNEL_TYPE.EMAIL:
        setInboxCreateState((prev: any) => {
          return {
            ...prev,
            inboxChannelCode: INBOX_CHANNEL_TYPE.EMAIL,
          };
        });
        break;
      case INBOX_CHANNEL_TYPE.WHATSAPP:
        setInboxCreateState((prev: any) => {
          return {
            ...prev,
            inboxChannelCode: INBOX_CHANNEL_TYPE.WHATSAPP,
          };
        });
        break;
    }
  };

  const handleScreenTwoChange = (
    screen: number,
    newScreenTwoData: any
  ): any => {
    setCurrentStep(screen);
    onNewInboxCompleted({...currentData, ...newScreenTwoData});
  };

  const handleScreenThreeChange = (
    screen: number,
    newScreenThreeData: any
  ): any => {
    setCurrentStep(screen);
    onAddAgents(newScreenThreeData);
  };

  const handleScreenFiveChange = (screen: number, agentBotId: number): any => {
    setCurrentStep(screen);
    onAddAgentBot(agentBotId);
  };

  const handleScreenFourChange = (): any => {
    //props?.onCompletedAddition?.();
  };

  const onAddAgents = (agentData: any): any => {
    const agentsToAdd = getAgentObject(agentData, newInboxId);
    addAgent({variables: {agentsToAdd: agentsToAdd}});
  };

  const onAddAgentBot = (agentBotId: number): any => {
    attachAgentBotToInbox(newInboxId, agentBotId, accountId);
  };

  const onCancelClick = (): any => {
    //go back
  };

  const onNewInboxCompleted = (newData: any): any => {
    const formattedData = formatCreateInboxData(newData);
    const accountId = userData?.account_id;
    const commonService = CommonService.getCommonServiceInstance();
    const axiosService = commonService.axiosService;
    const createWidgetData = getCreateInboxAPIData(formattedData);
    axiosService
      .post(`/accounts/${accountId}/inboxes/`, {
        ...createWidgetData,
      })
      .then((responseMsg: any) => {
        setNewInboxId(responseMsg.data.id);
        setWebToken(responseMsg.data.website_token);
        updateWidgetPreChat({
          variables: {
            preChatFormEnabled: formattedData.preChatFormEnabled,
            preChatFormOptions: formattedData.preChatFormOptions,
            id: responseMsg.data.channel_id,
          },
        }).then((res) => {});
      });
  };

  const getCurrentScreen = () => {
    switch (currentStep) {
      case INTEGRATION_CONSTANTS.SCREEN_ONE:
        return (
          <IntegrationScreenOne
            handleScreenOneChange={handleScreenOneChange}
            onCancelClick={onCancelClick}
          />
        );

      case INTEGRATION_CONSTANTS.SCREEN_TWO:
        return (
          <IntegrationScreenTwo
            handleScreenTwoChange={handleScreenTwoChange}
            currentChannel={currentData.inboxChannelCode}
            onCancelClick={onCancelClick}
            onBackClick={onBackClick}
          />
        );

      case INTEGRATION_CONSTANTS.SCREEN_THREE:
        return (
          <IntegrationScreenThree
            handleScreenThreeChange={handleScreenThreeChange}
            onCancelClick={onCancelClick}
            onBackClick={onBackClick}
          />
        );

      case INTEGRATION_CONSTANTS.SCREEN_FOUR:
        return (
          <IntegrationScreenFour
            handleScreenFourChange={handleScreenFiveChange}
            onBackClick={onBackClick}
          />
        );

      case INTEGRATION_CONSTANTS.SCREEN_FIVE:
        return (
          <IntegrationScreenFive
            webToken={webToken}
            handleScreenFiveChange={handleScreenFourChange}
            onCancelClick={onCancelClick}
          />
        );
    }
  };
  const navigate = useNavigate();
  const onHeaderBackClick = () => {
    navigate(-1);
  };

  const isSmsInboxSelected = () => {
    return inboxCreateState.inboxChannelCode === INBOX_CHANNEL_TYPE.SMS;
  };

  const isEmailInboxSelected = () => {
    return inboxCreateState.inboxChannelCode === INBOX_CHANNEL_TYPE.EMAIL;
  };

  const onSmsInboxActionPerformed = (actionCode: string) => {
    switch (actionCode) {
      case SMS_INBOX_ACTION_CODES.DRAWER_CLOSE:
        setInboxCreateState((prev: any) => {
          return {
            ...prev,
            inboxChannelCode: '',
          };
        });
        break;
      case INBOX_CHANNEL_TYPE.SMS:
        setInboxCreateState((prev: any) => {
          return {
            ...prev,
            inboxChannelCode: INBOX_CHANNEL_TYPE.SMS,
            isBuyVirtualNumber: false,
          };
        });
        break;
      case SMS_INBOX_ACTION_CODES.ON_INBOX_SAVE:
        setInboxCreateState((prev) => {
          return {
            ...prev,
            inboxChannelCode: '',
          };
        });
        navigate(-1);
        break;
      case SMS_INBOX_ACTION_CODES.VIRTUAL_NUMBER_DRAWER_OPEN:
        setInboxCreateState((prev) => {
          return {
            ...prev,
            inboxChannelCode: '',
            isBuyVirtualNumber: true,
          };
        });
        break;
      case COMMON_ACTION_CODES.COMPLETED:
      case COMMON_ACTION_CODES.CANCEL:
        setInboxCreateState((prev) => {
          return {
            ...prev,
            inboxChannelCode: '',
            isBuyVirtualNumber: false,
          };
        });
        break;
    }
  };

  return (
    <View>
      {/* <View
        style={[
          styles.titleWrapper,
        ]}
      >
        <TitleSubtitleView
          titleLabelId="createInboxTitle"
          subtitleLabelId="createInboxSubtitle"
          showBackButton={true}
          onBackClick={onHeaderBackClick}
        />
      </View> */}
      {agentsLoading && userListLoading ? (
        <View padding={6}>
          <Skeleton.Text lines={4} />
        </View>
      ) : (
        <View>
          <View
            style={[
              styles.tabsWrapper,
              commonBodyContentContainer.pageContentContainer,
              {padding: 16},
            ]}
          >
            <HStack
              style={styles.height}
            >
              <VStack flex={0.25}>
                <VStack style={styles.leftSteps}>
                  <Steps progressDot direction="vertical" current={currentStep}>
                    <Step
                      title="Choose Channel"
                      description="Choose the provider you want to integrate"
                    />
                    <Step
                      title="Create Inbox"
                      description="Authenticate your account and create an inbox."
                    />
                    <Step
                      title="Add Agents"
                      description="Add agents to the created inbox."
                    />
                    <Step
                      title="Add Agent Bot"
                      description="Add agent Bot to the created inbox."
                    />
                    <Step title="Done" description="You are all set to go!" />
                  </Steps>
                </VStack>
              </VStack>
              <VStack flex={0.75} style={styles.rightInbox}>
                {getCurrentScreen()}
              </VStack>
              {(isSmsInboxSelected() ||
                inboxCreateState.inboxChannelCode ==
                  INBOX_CHANNEL_TYPE.SMS) && (
                <SmsInboxDrawer
                  isDrawerVisible={true}
                  allUserList={accountUserData?.accountUsers || []}
                  selectedInboxData={{} as any}
                  onSmsInboxActionPerformed={(actionCode: string) => {
                    onSmsInboxActionPerformed(actionCode);
                  }}
                />
              )}
              {isEmailInboxSelected() && (
                <EmailInboxDrawer
                  isDrawerVisible={true}
                  selectedInboxData={{} as any}
                  onEmailInboxActionPerformed={(actionCode: string) => {
                    onSmsInboxActionPerformed(actionCode);
                  }}
                />
              )}
              {inboxCreateState.isBuyVirtualNumber && (
                <PurchaseNumberView
                  screenName={'INBOX_SCREEN'}
                  onFormCompleteAction={(actionCode: string) => {
                    setTimeout(() => {
                      onSmsInboxActionPerformed(actionCode);
                    }, 500);
                  }}
                  userList={userListResp?.users || []}
                />
              )}
            </HStack>
          </View>
        </View>
      )}
    </View>
  );
};
