import {Drawer, notification} from 'antd';
import {Spinner, useToast} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {Pressable, Text, View, StyleSheet} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {BUTTON_TYPE} from '../../../constants';
import {Colors} from '../../../styles';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {openOutlookLoginWindow} from '../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/EmailInboxCreate/EmailInboxUtils';
import {IEmailInboxExpiredResponse} from '../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/EmailInboxCreate/interfaces';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {emailReLogin, getExpiredEmailInbox} from './EmailInboxApi';
import {IExpiredInboxDrawerProps} from './interfaces';

const ExpiredInboxDrawer = (props: IExpiredInboxDrawerProps) => {
  const [state, setState] = React.useState({
    inboxes: [] as IEmailInboxExpiredResponse[],
    selectedInbox: {} as IEmailInboxExpiredResponse,
    isLoading: false,
  });
  const intl = useIntl();
  const toast = useToast();

  const getExpiredInboxes = async () => {
    setState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    try {
      const response = await getExpiredEmailInbox();
      if (response.length === 0) {
        props.onClose();
      }
      setState((prev) => {
        return {
          ...prev,
          inboxes: response,
          isLoading: false,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          isLoading: false,
          inboxes: [],
        };
      });
    }
  };

  const onChildWindowMessageReceived = React.useCallback(
    async (event: MessageEvent) => {
      try {
        if (!(event.data.sender === 'FOLD' && event.data.message)) {
          return;
        }
        const code = event.data.message;
        if (!state.selectedInbox.uuid) {
          return;
        }
        await emailReLogin({inboxUuid: state.selectedInbox.uuid, code: code});
        notification.success({message: 'Token refreshed!'});
        getExpiredInboxes();
      } catch (error: any) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          notification.error({message: 'Invalid email id'});
        } else {
          notification.error({
            message: 'Something went wrong. Please try again later!',
          });
        }
      }
    },
    [state.selectedInbox]
  );

  React.useEffect(() => {
    getExpiredInboxes();
  }, []);

  React.useEffect(() => {
    window.addEventListener('message', onChildWindowMessageReceived);
    return () => {
      window.removeEventListener('message', onChildWindowMessageReceived);
    };
  }, [state.selectedInbox]);

  return (
    <Drawer
      open={props.isOpen}
      onClose={props.onClose}
      width={'33vw'}
      title={
        <ModalActionTitle
          title={intl.formatMessage({id: 'reLoginToOutlook'})}
          titleColor={'#16181D'}
          rightButton={
            <View style={styles.closeButtonContainer}>
              <Pressable onPress={() => props.onClose()}>
                <AntIcon name="close" size={20} color={Colors.Custom.Gray500} />
              </Pressable>
            </View>
          }
        />
      }
    >
      <View>
        {state.isLoading ? (
          <View>
            <Spinner />
          </View>
        ) : (
          <View>
            {state.inboxes.map((item) => {
              return (
                <View key={item.id} style={styles.inboxItem}>
                  <View style={styles.inboxDetails}>
                    <Text style={styles.inboxName}>{item.name}</Text>
                    <Text style={styles.inboxEmail}>{item.email}</Text>
                  </View>

                  <View>
                    <Pressable
                      onPress={() => {
                        setState((prev) => {
                          return {
                            ...prev,
                            selectedInbox: item,
                          };
                        });
                        openOutlookLoginWindow();
                      }}
                    >
                      <Text style={styles.reLoginText}>
                        {intl.formatMessage({id: 'reLoginToOutlook'})}
                      </Text>
                    </Pressable>
                  </View>
                </View>
              );
            })}
          </View>
        )}
      </View>
    </Drawer>
  );
};

export default ExpiredInboxDrawer;

const styles = StyleSheet.create({
  closeButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  inboxItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: 10,
    padding: 20,
    borderWidth: 0.5,
    borderColor: Colors.FoldPixel.GRAY200,
    marginVertical: 4,
  },
  inboxDetails: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inboxName: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 21,
    color: 'black',
  },
  inboxEmail: {
    fontWeight: '500',
    lineHeight: 16,
    fontSize: 14,
    marginTop: 5,
    color: Colors.FoldPixel.GRAY200,
  },
  reLoginText: {
    fontWeight: '500',
    color: Colors.Custom.mainPrimaryPurple,
    fontSize: 16,
  },
});

const antdStyles: Record<string, React.CSSProperties> = {
  drawer: {
    width: '33vw',
  },
};
