import {extendTheme} from 'native-base';
import LinearGradient from 'react-native-web-linear-gradient';
import { BUTTON_TYPE } from '../constants/StringConst';
import {updateThemeColors} from '../styles/Colors';
import {DEFAULT_FONT_FAMILY} from '../styles/FontConst';
import {defaultThemeColors} from './ColorConfig';
import {getHeadingComponentThemeConfig} from './ComponentConfigs/HeadingComponentConfig';
import {getTextComponentThemeConfig} from './ComponentConfigs/TextComponentConfig';
export const getNativeBaseTheme = (themeConfig: any) => {
  updateThemeColors(themeConfig.colorConfig);
  const theme = extendTheme({
    colors: themeConfig.colorConfig,
    fonts: {
      heading: themeConfig.fontFamily || DEFAULT_FONT_FAMILY,
      body: themeConfig.fontFamily || DEFAULT_FONT_FAMILY,
      mono: themeConfig.fontFamily || DEFAULT_FONT_FAMILY,
    },
    components: {
      Heading: getHeadingComponentThemeConfig(),
      Text: getTextComponentThemeConfig(),
      Button: {
        _disabled:{
          borderColor:'#D0D5DD',
          _text:{
            color: '#D0D5DD',
          }
        },
        variants: {
          [BUTTON_TYPE.PRIMARY]: ({
          }) => {
            return {
              bg: '#825AC700',
              borderColor:'#825AC7',
              borderWidth: 1,
              borderRadius: 6,
              paddingX:16,
              paddingTop:"0.5",
              paddingBottom:"0.5",
              minWidth: 65,
              minHeight:10,
              _text:{
                color: '#825AC7',
                fontFamily:'Manrope-SemiBold',
                fontWeight: 600,
                fontSize: 14
              },
              fontWeight: 600,
              _disabled:{
                borderColor:'#D0D5DD',
                _text:{
                  color: '#D0D5DD',
                }
              }
            };
          },
          [BUTTON_TYPE.SECONDARY]: ({
          }) => {
            return {
              bg: '#825AC700',
              borderColor:'#8C210F',
              borderWidth: 1,
              borderRadius: 6,
              paddingX:16,
              paddingTop:"0.5",
              paddingBottom:"0.5",
              minWidth: 65,
              minHeight:"40px",
              fontWeight: 600,
              _text:{
                color: '#8C210F',
                fontFamily:'Manrope-SemiBold',
                fontWeight: 600,
                fontSize: 14
              },
              _disabled:{
                borderColor:'#D0D5DD',
                _text:{
                  color: '#D0D5DD',
                }
              }
            };
          },
          [BUTTON_TYPE.TERTIARY]: ({
          }) => {
            return {
              bg: '#825AC700',
              borderColor:'#F37232',
              borderWidth: 1,
              borderRadius: 6,
              paddingX:16,
              paddingY:10,
              minWidth: 65,
              minHeight:"40px",
              fontWeight: 600,
              _text:{
                color: '#F37232',
                fontFamily:'Manrope-SemiBold',
                fontWeight: 600,
                fontSize: 14
              },
              _disabled:{
                borderColor:'#D0D5DD',
                _text:{
                  color: '#D0D5DD',
                }
              }
            };
          }
        }
      },
      Spinner: {
        defaultProps: {color: defaultThemeColors.colorConfig.primary[500]},
      },
    },
  });
  return theme;
};

export const getNativeBaseConfig = {
  dependencies: {
    'linear-gradient': LinearGradient,
  },
};
