import {StyleSheet} from 'react-native';
import { Colors } from '../../../../styles/Colors';

export const styles = StyleSheet.create({
  swtichBtnStyle: {
    paddingHorizontal: 16,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
  },
  container: {
    height: 40,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 12,
    paddingHorizontal: 4,
    paddingVertical: 8,
    // backgroundColor: '#fff',
  },
});
