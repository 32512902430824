export const SIGNATURE_COMPONENT = {
  height: '150px',
  backgroundColor: 'rgb(255,255,255)',
  width: '100%',
  input: true,
  key: 'signature',
  validateOn: 'change',
  footer: 'Sign above',
  type: 'signature',
  penColor: 'black',
  label: 'Signature',
  selectedValue: null as any,
};

export const LOCATION_WITH_SAME_NAME_EXIST = 'A location with this name already exists. Please choose a different name';