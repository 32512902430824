import {StyleSheet} from "react-native";
import {Colors} from "../../../../../styles";

export const styles = StyleSheet.create({
  icons: {
    fontSize: 14,
    color: Colors.Custom.IconColor,
  },
  listBox: {
    width: '100%'
  },
  itemContainer: {
    paddingHorizontal: 5,
    paddingVertical: 1
  },

  listItem: {
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  listAction: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
})