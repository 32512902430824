import React from "react";

function DownloadImageSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g>
        <path
          stroke="#98A2B3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M21 15.224v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4m4-5l5 5m0 0l5-5m-5 5v-12"
        ></path>
      </g>
    </svg>
  );
}

export default DownloadImageSvg;
