import {StyleSheet} from 'react-native';
const baseMargin = {
  marginTop: 24,
  marginLeft: 24,
  marginRight: 24,
  marginBottom: 24,
};
export const style = StyleSheet.create({
  productSideBar: {
    width: '100%',
  },
  loadingView: {
    ...baseMargin,
  },
});
