import {View, Text} from 'react-native';
import React, {useEffect} from 'react';
import {useIsFocused} from '@react-navigation/native';

interface IIsFocusedViewProps {
  onChange: (value: any) => void;
}

const IsFocusedView = (props: IIsFocusedViewProps) => {
  const isFocused = useIsFocused();

  useEffect(() => props.onChange(isFocused), [isFocused]);

  return <></>;
};

export default IsFocusedView;
