import {gql} from '@apollo/client';
export const getZipCodeBySearchString = gql`
  query getZipCode($searchString: String!, $limit: Int) {
    zipcodes(where: {code: {_ilike: $searchString}}, limit: $limit) {
      id
      code
      cityId
      stateId
      zipcodeCity {
        name
        id
      }
      zipcodeState {
        name
        countryId
        id
        abbr
      }
    }
  }
`;

export const getZipcodeList = gql`
  query getZipCodeList($zipList: [uuid]!) {
    zipcodes(where: {id: {_in: $zipList}}) {
      id
      code
      cityId
      stateId
      zipcodeCity {
        name
        id
      }
      zipcodeState {
        name
        countryId
        id
        abbr
      }
    }
  }
`;

export const getZipCodeById = gql`
  query getZipCodeById($zipCodeId: uuid!) {
    zipCode(id: $zipCodeId) {
      code
      city_id
      state_id
      id
    }
  }
`;

export const getStatesBySearchString = gql`
  query getStates($searchString: String!, $limit: Int) {
    states(where: {name: {_ilike: $searchString}}, limit: $limit) {
      name
      id
      abbr
    }
  }
`;

export const getStatesById = gql`
  query getState($stateId: uuid!) {
    state(id: $stateId) {
      name
      id
      country_id
    }
  }
`;

export const getCitiesBySearchString = gql`
  query getCitiesBySearchString($searchString: String!, $limit: Int) {
    cities(where: {name: {_ilike: $searchString}}, limit: $limit) {
      name
      stateId
      id
      cities_state {
        name
        id
      }
    }
  }
`;

export const getCityId = gql`
  query getCityId($cityId: uuid!) {
    cities(where: {id: {_eq: $cityId}}) {
      name
      state_id
      id
    }
  }
`;

export const getZipCodeByCode = gql`
  query getZipCode($code: String!) {
    zipcodes(where: {code: {_eq: $code}}) {
      id
      code
      stateId
      cityId
      zipcodeState {
        name
        countryId
        id
        abbr
      }
    }
  }
`;

export const getStatesByAbbr = gql`
  query getStates($stateCodes: [String!]) {
    states(where: {abbr: {_in: $stateCodes}}) {
      name
      id
      abbr
    }
  }
`;

export default {
  getZipCodeBySearchString,
  getZipCodeById,
  getStatesBySearchString,
  getStatesById,
  getCitiesBySearchString,
  getCityId,
  getZipcodeList,
  getZipCodeByCode,
  getStatesByAbbr,
};
