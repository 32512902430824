import {Button, Modal, Spinner, View} from 'native-base';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {addOrUpdateCPTByTemplate} from '../../../../../../services/CostSavingTemplates/CostSavingService';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import {deleteCptModalStyle} from '../helperFiles/DeleteCptModalStyle';
import {IDeleteCPTModalProps} from '../helperFiles/interface';
import {styles} from '../helperFiles/Styles';


const {Content, Footer, Header, CloseButton, Body} = Modal;

const DeleteCptModal = (props: IDeleteCPTModalProps) => {
  const {onActionPerformed, templateId, selectedData, analyticalResourceId} = props;
  const intl = useIntl();
  const [stateData, setStateData] = useState({
    loading: false
  })
  const handleConfirmation = async () => {
    setStateData(prev=> {
      return {
        ...prev,
        loading: true
      }
    })
    const updatedEntry = [
      {
        id: selectedData.id,
        isDeleted: true
      }
    ]
    const data = {
      id: templateId,
      analyticalResourceId: analyticalResourceId,
      cpts: updatedEntry
    }
    const response = await addOrUpdateCPTByTemplate(data)
    setStateData(prev=> {
      return {
        ...prev,
        loading: false
      }
    })
    onActionPerformed(COMMON_ACTION_CODES.SAVE, response)
  };
  const onClose = () => {
    onActionPerformed(COMMON_ACTION_CODES.CANCEL)
  }
  return (
    <Modal isOpen onClose={onClose}>
       {stateData.loading ? (
        <Spinner size="lg" style={styles.spinnerStyle} />
      ) : (
        <></>
      )}
      <Content style={deleteCptModalStyle.container}>
        <CloseButton />
        <Header>
          <DisplayText textLocalId="deleteCpt" textType="Heading" />
        </Header>

        <Body>
          <View>
            <DisplayText textLocalId="deleteCptConfirmation" />
          </View>
        </Body>

        <Footer style={deleteCptModalStyle.footerStyle}>
          <Button.Group>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  handleConfirmation();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'confirm',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </Footer>
      </Content>
    </Modal>
  );
};

export default DeleteCptModal;
