import React from 'react';
import Svg, { Path } from "react-native-svg";

const AutomationSvg = () => {
  return (
    <Svg
      width="12"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <Path
        d="M9.63878 5.12298V6.53075M9.63878 6.53075H8.19939M9.63878 6.53075L8.52565 5.50777C8.26782 5.25548 7.94884 5.07117 7.59849 4.97206C7.24813 4.87294 6.87781 4.86225 6.52208 4.94097C6.16635 5.01969 5.83681 5.18526 5.56421 5.42223C5.2916 5.6592 5.08482 5.95985 4.96315 6.29613M4.361 8.87705V7.46927M4.361 7.46927H5.8004M4.361 7.46927L5.47413 8.49226C5.73196 8.74455 6.05094 8.92885 6.4013 9.02797C6.75166 9.12708 7.12197 9.13778 7.4777 9.05906C7.83343 8.98034 8.16297 8.81477 8.43558 8.5778C8.70818 8.34082 8.91497 8.04017 9.03663 7.7039M7.93981 1.61181C7.87894 2.80301 9.34663 3.40991 10.1448 2.52477C10.8943 2.48651 11.5134 3.10559 11.4751 3.85508C10.59 4.65327 11.1986 6.12096 12.3881 6.0601C12.9446 6.56266 12.9446 7.43736 12.3881 7.93993C11.1969 7.87906 10.59 9.34675 11.4751 10.1449C11.5134 10.8944 10.8943 11.5135 10.1448 11.4753C9.34663 10.5901 7.87894 11.1988 7.93981 12.3882C7.43724 12.9447 6.56254 12.9447 6.05998 12.3882C6.12084 11.197 4.65315 10.5901 3.85496 11.4753C3.10547 11.5135 2.48639 10.8944 2.52465 10.1449C3.40979 9.34675 2.80115 7.87906 1.61169 7.93993C1.05522 7.43736 1.05522 6.56266 1.61169 6.0601C2.80288 6.12096 3.40979 4.65327 2.52465 3.85508C2.48639 3.10559 3.10547 2.48651 3.85496 2.52477C4.65315 3.40991 6.12084 2.80127 6.05998 1.61181C6.56254 1.05534 7.43724 1.05534 7.93981 1.61181Z"
        stroke="#667085"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default AutomationSvg;
