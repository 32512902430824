import {IconButton, Tooltip, useToast, View} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {Colors} from '../../../../../../styles';
import {IMediaLibraryTableActionProps} from '../../interfaces';
import {getIconNameByMime} from '../../MediaLibraryUtils';
import { showToast, ToastType } from '../../../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';

const MediaLibraryTableActions = (props: IMediaLibraryTableActionProps) => {
  const {onActionPerformed, record} = props;
  const intl = useIntl();

  const toast = useToast();

  const handleDelete = () => {
    onActionPerformed(COMMON_ACTION_CODES.DELETE, record);
  };

  const handlePreview = () => {
    onActionPerformed(COMMON_ACTION_CODES.PREVIEW, record);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(record.url as string);
    showToast(
      toast,
      intl.formatMessage({id: 'copyClickboardMsg'}),
      ToastType.info,
    );
  };

  return (
    <View flex={1} flexDirection={'row'}>
      <Tooltip label="Delete" placement="top">
        <IconButton
          icon={
            <AntIcon name="delete" size={17} color={Colors.Custom.Gray600} />
          }
          onPress={() => handleDelete()}
        />
      </Tooltip>

      <Tooltip label="Copy URL" placement="top">
        <IconButton
          icon={
            <AntIcon name="copy1" size={17} color={Colors.Custom.Gray600} />
          }
          onPress={() => handleCopyToClipboard()}
        />
      </Tooltip>

      <Tooltip label="Preview" placement="top">
        <IconButton
          icon={<AntIcon name={getIconNameByMime(record.mime)} size={17} />}
          onPress={() => handlePreview()}
        />
      </Tooltip>
    </View>
  );
};

export default MediaLibraryTableActions;
