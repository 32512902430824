import {Menu} from 'antd';
import React from 'react';

const SingularActionsDropdownMenu = (
  <Menu
    items={[
      {key: '1', label: <a>Create Campaign</a>},
      {key: '2', label: <a>Pause</a>},
      {key: '3', label: <a>Archive</a>},
      {key: '4', label: <a>Delete</a>},
    ]}
  />
);

export default SingularActionsDropdownMenu;
