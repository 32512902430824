import React from 'react';
import {Badge, Text} from 'native-base';
import UserSvg from '../../../common/Svg/UserSvg';
import {Colors} from '../../../../styles';
import Stack from '../../../common/LayoutComponents/Stack';
import {IEFaxInboxMembers, IGetInboxMemberBadge} from './interface';
import {StyleSheet, ViewStyle} from 'react-native';

export const GetInboxMemberBadge = (props: IGetInboxMemberBadge) => {
  return (
    <Stack direction="row" style={styles.container}>
      {props?.inboxMembers?.map((item: IEFaxInboxMembers, index: any) => {
        return (
          <Badge
            style={styles.badge}
            key={index}
            background={Colors.Custom.Gray200}
            alignSelf="center"
            variant="outline"
            borderWidth={0}
            borderRadius={10}
          >
            <Stack direction="row" style={styles.badgeContent}>
              <UserSvg width={15} height={15} />
              <Text paddingLeft={1}>{item?.user?.name}</Text>
            </Stack>
          </Badge>
        );
      })}
    </Stack>
  );
};

const styles = StyleSheet.create({
  container: {
    flexWrap: 'wrap' as ViewStyle['flexWrap'],
  },
  badge: {
    marginVertical: 4,
    marginHorizontal: 4,
  },
  badgeContent: {
    alignItems: 'center' as ViewStyle['alignItems'],
  },
});
