import React from 'react';
import Stack from '../../../common/LayoutComponents/Stack';
import {ScrollView, View} from 'react-native';
import {debounce} from 'lodash';
import {Colors} from '../../../../styles';
import {Pressable, Spinner, Text, useToast} from 'native-base';
import {Popover, Spin, Modal} from 'antd';
import {
  CARE_TEAM_MEMBER_TYPE,
  MLOV_CATEGORY,
  USER_ROLE_CODES,
  CARE_TEAM,
  USER_POOL_CODES,
} from '../../../../constants/MlovConst';
import {IUser} from '../../../../Interfaces';
import {
  ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP,
  formatRoleArray,
} from './CareTeamUtils';
import CareTeamMemberCard from './components/CareTeamMemberCard';
import './components/BulkAssignCareManagerView/BulkAssignCareManagerView.css';

import {useIntl} from 'react-intl';
import SelectDownArrowSvg from '../../../common/Svg/SelectDownArrowSvg';
import {useContext, useEffect, useState} from 'react';
import {useLazyQuery, useMutation} from '@apollo/client';
import {SEARCH_USERS_FOR_CARE_TEAM} from '../../../../services/User/UserQueries';
import {
  getAgentsList,
  isEnableCareProgram,
} from '../../../../utils/commonUtils';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {
  getMlovIdFromCode,
  getMlovListByCategory,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import {
  ADD_CARE_TEAM,
  ADD_OR_UPDATE_CARE_TEAM_MEMBER_TYPES,
  DELETE_CARE_TEAM_USER,
  GET_USER_POOL_BY_ID,
} from '../../../../services/CareTeam/CareTeamQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {ToastType, showToast} from '../../../../utils/commonViewUtils';
import {SearchBar} from '../../../common/SearchBar';
import {localBroadcastEvent} from '../../../../utils/CustomEventHandler';
import {CONVERSATION_LOCAL_EVENT_CODES} from '../../../../constants/WebSocketConst';
import WriteInfoBackBanner from '../../MiddleContainer/CareTimeline/components/WriteInfoBackBanner';
import Close from '../../../../assets/svg/Close';
import { ISelectedCareManager } from './components/BulkAssignCareManagerView/interface';
import { styles, reactStyles } from './CareManagerAssigneeStyles';

interface ICareManagerAssigneeProps {
  contactUuid?: string;
  accountLocationUuid?: string;
  careTeam?: any;
  careManager?: any;
  showDialog: boolean;
  onSelectCareManager?: (user: ISelectedCareManager) => void;
  onClose?: () => void;
}

export const CareManagerAssignee = (props: ICareManagerAssigneeProps) => {
  const {careManager, careTeam, showDialog, onSelectCareManager} = props;
  const intl = useIntl();
  const [userData, setUserData] = useState<IUser[]>([]);
  const commonContextData = useContext(CommonDataContext);
  const currentTeam = careTeam?.careTeamMemberType || [];

  const [userPoolUsers, setUserPoolUsers] = useState<IUser[]>(
    careTeam?.careTeamMemberType || []
  );

  const [loadingUserId, setLoadingUserId] = useState<string>();

  const toast = useToast();

  const headers = {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  };

  const isCareProgramEnabled = isEnableCareProgram(
    commonContextData.userSettings
  );
  const [popoverVisible, setPopoverVisible] = useState(
    props?.showDialog ? true : false
  );

  const [getUsersByRoles, {loading: searchUserLoading}] = useLazyQuery(
    SEARCH_USERS_FOR_CARE_TEAM
  );

  const [getUserPoolById, {loading: userPoolLoading}] = useLazyQuery(
    GET_USER_POOL_BY_ID,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      onCompleted: (data) => {
        if (data?.userPools?.[0]?.userPoolUsers?.length) {
          setUserPoolUsers(data?.userPools?.[0]?.userPoolUsers);
        }
      },
    }
  );

  useEffect(()=> {
    setUserPoolUsers(careTeam?.careTeamMemberType);
  }, [careTeam?.careTeamMemberType])

  useEffect(() => {
    if (popoverVisible) {
      onSearch('');

      if (props.careTeam?.userPoolId) {
        getUserPoolById({
          variables: {
            id: props.careTeam?.userPoolId,
          },
        });
      }
    }
  }, [popoverVisible]);

  const [addOrUpdateCareTeamMemberType] = useMutation(
    ADD_OR_UPDATE_CARE_TEAM_MEMBER_TYPES,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const [deleteCareTeam, {loading: isDeleteLoading}] = useMutation(
    DELETE_CARE_TEAM_USER,
    {
      ...headers,
      onError: () => {
        showToast(
          toast,
          'Something went wrong. Please try again later',
          ToastType.error
        );
      },
    }
  );

  const onSearch = async (searchString: string, updatedTeam?: IUser[]) => {
    const response = await getUsersByRoles({
      variables: {
        where: getSearchWhereCondition(searchString, currentTeam),
      },
      fetchPolicy: 'no-cache',
    });
    setUserData(response?.data?.users || []);
  };

  const sendLocalBrodcastEvent = (careTeam: any) => {
    localBroadcastEvent(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CARE_MANAGER_CHANGE,
      {
        careTeam: careTeam,
        contactUuid: props.contactUuid,
      }
    );
  };

  const careProgramRoles = [
    CARE_TEAM_MEMBER_TYPE.CARE_MANAGER,
    CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER,
  ];

  const careTeamMemberTypesList = getMlovListByCategory(
    MLOV_CATEGORY.CARE_TEAM_MEMBER_TYPE
  )?.filter((item) =>
    isCareProgramEnabled ? true : !careProgramRoles.includes(item.code)
  );

  const careTeamMemberTypes = {
    [CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]: getMlovIdFromCode(
      careTeamMemberTypesList,
      CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
    ),
  };

  const [selectedMemberType, setSelectedMemberType] = useState<string>(
    isCareProgramEnabled
      ? careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]
      : 'other'
  );

  const careTeamList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PRIMARY_CARE_TEAM
    ) || [];

  const careTeamTypeId = getMlovIdFromCode(careTeamList, CARE_TEAM.CODE);

  const userPoolTypeList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_TEAM_USER_POOL
    ) || [];

  const careTeamUserPoolType = getMlovIdFromCode(
    userPoolTypeList,
    USER_POOL_CODES.CARE_TEAM
  );

  const [addCareTeam, {loading: saveCareTeamLoading}] = useMutation(
    ADD_CARE_TEAM,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const getSearchWhereCondition = (
    searchString: string,
    currentTeam: IUser[]
  ) => {
    const roles = [];
    const where: any = {
      accountUsers: {
        user: {},
      },
    };

    if (
      selectedMemberType ===
      careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]
    ) {
      roles.push(
        ...ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP[
          CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
        ]
      );
    }

    if (roles.length > 0) {
      where.userRoles = {userRole: {userRole: {code: {_in: roles}}}};
      where._not = {
        userRoles: {
          userRole: {
            userRole: {
              code: {
                _in: [
                  USER_ROLE_CODES.WORFLOW_USER,
                  USER_ROLE_CODES.CUSTOMER_SUCCESS,
                ],
              },
            },
          },
        },
      };
    }
    if (searchString.length > 0) {
      where.accountUsers.user.name = {_ilike: `%${searchString}%`};
    }
    where.uuid = {_nin: currentTeam?.map((user) => user.userId)};
    where.accountUsers.isActive = {_eq: true};
    where.userPracticeLocations = {
      accountLocations: {
        uuid: {
          _eq: props.accountLocationUuid,
        },
        isDeleted: {
          _eq: false,
        },
      },
    };

    return where;
  };

  const agentsData = getAgentsList();

  const getUserList = (team: IUser[]) => {
    return (
      team?.map((user) => {
        const matchUser = agentsData?.find((agent: any) => {
          if (user?.userId && user?.userId === agent?.id) {
            return agent;
          } else if (user?.id && user?.id === agent?.id) {
            return agent;
          }
        });
        if (matchUser) return {...user, agent: matchUser};
        else return user;
      }) || []
    );
  };

  const handleSelectAssignee = async (userId: string, memberName: string) => {
    try {
      setLoadingUserId(userId);
      if (props.careTeam.id && props.careManager?.userId) {
        const userPoolUser = userPoolUsers?.find(
          (userPoolUser) => userPoolUser.userId === props.careManager?.userId
        );
        await deleteCareTeam({
          variables: {
            userId: userPoolUser?.id,
            careTeamId: props.careTeam.id,
            userUuid: props.careManager?.userId,
          },
        });
        const careMemberTeamMember = currentTeam?.find(
          (careTeam: any) => careTeam.userId === props.careManager?.userId
        );
        await addOrUpdateCareTeamMemberType({
          variables: {
            params: {
              id: careMemberTeamMember?.id,
              isDeleted: true,
            },
          },
        });
      }

      const response = await addCareTeam({
        variables: {
          params: {
            ...(!!props.careTeam.id && {id: props.careTeam.id}),
            contactId: props.contactUuid,
            careTeamTypeId: careTeamTypeId,
            userPool: {
              on_conflict: {
                constraint: 'user_pool_pkey',
                update_columns: 'isDeleted',
              },
              data: {
                ...(!!props.careTeam.userPoolId && {
                  id: props.careTeam.userPoolId,
                }),
                typeId: careTeamUserPoolType,
                isDeleted: false,
                userPoolUsers: {
                  data: {userId: userId},
                },
              },
            },
          },
        },
      });

      const careTeamId = response?.data?.createCareTeam?.id;
      const variables = {
        params: {
          userId: userId,
          careTeamId: careTeamId,
          careTeamMemberTypeId:
            careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER],
        },
      };
      const careTeamMemberTypeResponse = await addOrUpdateCareTeamMemberType({
        variables,
      });

      const updatedCareTeam = {
        id: response?.data?.createCareTeam?.id,
        userPoolId:
          props.careTeam.userPoolId ||
          response?.data?.createCareTeam?.userPoolId,
        updatedCareTeamMemberType: {
          userId: userId,
          userName: memberName,
          id: careTeamMemberTypeResponse?.data?.addOrUpdateCareTeamMemberTypes
            ?.id,
          careTeamMemberTypeId:
            careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER],
          memberType: {
            code: CARE_TEAM_MEMBER_TYPE.CARE_MANAGER,
            value: 'Care Manager',
          },
        },
      };

      sendLocalBrodcastEvent(updatedCareTeam);
      showToast(toast, 'Assignee updated successfully.', ToastType.success);
      setPopoverVisible(false);
      setLoadingUserId('');
    } catch (error) {
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
      setLoadingUserId('');
    }
  };

  const loading = userPoolLoading || searchUserLoading;

  const renderPopoverContent = () => {
    return (
      <Stack
        direction="column"
        style={{width: showDialog ? 336 : 300, height: 280}}
      >
        <SearchBar
          placeholderText="Search"
          borderColor={Colors.FoldPixel.GRAY200}
          placeholderTextColor={Colors.Custom.Gray400}
          borderRadius={6}
          searchBarInputStyles={{backgroundColor: Colors.Custom.White, borderColor: Colors.Custom.Gray400}}
          onChange={debounce((value) => onSearch(value), 200)}
        />
        {loading && <Spin size="small" style={reactStyles.loadingStyle} />}
        {getUserList(userData)?.length === 0 && !loading && (
          <Text
            style={styles.noUsersFoundText}
          >
            {'No Users Found'}
          </Text>
        )}
        <ScrollView style={styles.scrollViewContainer}>
          {getUserList(userData).map((member: any, index) => {
            return (
              <Pressable
                key={member.id}
                onPress={() => {
                  if (loadingUserId) {
                    return;
                  }
                  if (onSelectCareManager) {
                    onSelectCareManager(member);
                  } else {
                    handleSelectAssignee(member.uuid, member.name);
                  }
                }}
              >
                <Stack direction='row'>
                  <CareTeamMemberCard
                    key={member.id}
                    index={index}
                    paddingHorizontal={2}
                    name={member.name}
                    id={member.id}
                    uuid={member.uuid}
                    roles={formatRoleArray(member.userRoles || [])}
                    isLocal={false}
                    isSearchComponent={true}
                    profileUrl={
                      member?.agent?.thumbnail.includes('d=404')
                        ? ''
                        : member?.agent?.thumbnail
                    }
                    actionView={loadingUserId === member.uuid ? <Spinner /> : undefined}
                  />                  
                </Stack>
              </Pressable>
            );
          })}
        </ScrollView>
      </Stack>
    );
  };

  const getActiveColor = () => {
    if (careManager?.userName) {
      return Colors.FoldPixel.GRAY300;
    }
    return Colors.FoldPixel.GRAY150;
  };

  return (
    <Stack direction="column">
      {showDialog ? (
        <Modal
          className="care-team-assignee-modal"
          open={popoverVisible}
          width={360}
          closeIcon={null}
          closable={false}
          footer={null}
          style={reactStyles.borderRadius12}
        >
          <View
            style={styles.modalView}
          >
            <Stack
              direction="row"
              style={styles.modalHeaderView}
            >
              <Text
                style={styles.modalTitleText}
              >
                {`Select Care Manager For the Patients`}
              </Text>
              <Pressable onPress={props.onClose}>
                <Close fill={Colors.Custom.GRAY350} size={24}></Close>
              </Pressable>
            </Stack>
            {renderPopoverContent()}
          </View>
          <View style={styles.infoBannerView}>
            <WriteInfoBackBanner
              marginBottom={0.1}
              message={
                'Existing care managers will be replaced with the selected one'
              }
              borderRadius={12}
            />
          </View>
        </Modal>
      ) : (
        <Popover
          content={renderPopoverContent()}
          open={popoverVisible}
          trigger="click"
          onOpenChange={(isOpen) => {
            setPopoverVisible(isOpen);
          }}
          overlayInnerStyle={reactStyles.overlayInnerStyle}
          overlayStyle={reactStyles.borderRadius8}
          placement="bottom"
        >
          <Pressable
            onPress={() => {
              setPopoverVisible(true);
            }}
          >
            <Stack direction="row" space={4} style={styles.unassignedViewContainer}>
              <Text color={getActiveColor()}>
                {careManager?.userName ? careManager.userName : 'Unassigned'}
              </Text>
              <View
                style={styles.dividerView}
              />
              <View
                style={styles.arrowContainerView}
              >
                <SelectDownArrowSvg customStrokeColor={getActiveColor()} />
              </View>
            </Stack>
          </Pressable>
        </Popover>
      )}
    </Stack>
  );
};
