import React, { useMemo } from 'react';
import { Path, Svg } from 'react-native-svg';

const SunFogSvg = React.memo(() => {
  const svgContent = useMemo(() => (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path
        d="M5.33398 14.6673H10.6673M3.33398 12.6673H12.6673M1.33398 10.6673H14.6673M8.00065 1.33398V2.00065M14.6673 8.00065H14.0007M2.00065 8.00065H1.33398M12.7145 3.28678L12.4526 3.54868M3.5485 3.5485L3.28661 3.28661M4.00065 8.00065C4.00065 5.79151 5.79151 4.00065 8.00065 4.00065C10.2098 4.00065 12.0007 5.79151 12.0007 8.00065C12.0007 9.0151 11.623 9.94134 11.0007 10.6465H5.00065C4.37829 9.94134 4.00065 9.0151 4.00065 8.00065Z"
        stroke="#3A485F"
        strokeLinecap="round"
      />
    </Svg>
  ), []);

  return svgContent;
});

export default SunFogSvg;