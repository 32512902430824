import {Button, HStack, Text, View, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions, Platform} from 'react-native';
import {Colors} from '../../../styles';
import {testID, TestIdentifiers} from '../../../testUtils';
import {useKeyboard} from '../../../components/RightSideContainer/TeamInbox/Conversations/MessagingWindow/UseKeyBoard.native';
import LoginBg from '../../../components/Login/LoginBg';
import {styles} from '../../../components/Login/LoginStyles';
import FoldhealthLogoSvg from '../../../components/common/Svg/FoldhealthLogoSvg';
import {DisplayText} from '../../../components/common/DisplayText/DisplayText';
import {EXTRA_SMALL_SCREEN, SMALL_SCREEN} from '../../../constants';
import {getSsoUserEmail, getSupportEmailFromSSOConfig} from './SsoUtils';
import {ISsoErrorInterface} from '../../../Interfaces';

export const SsoErrorPage = (props: ISsoErrorInterface) => {
  const {height} = Dimensions.get('window');
  const keyboardHeight = useKeyboard();
  const isSmallScreen = height <= SMALL_SCREEN;
  const extraSmallScreen = height <= EXTRA_SMALL_SCREEN;
  const adminEmail = getSupportEmailFromSSOConfig(props?.ssoLoginData?.account?.accountConfigurations?.value || '{}');
  const [stateData, setStateData] = useState({
    loading: false,
    email: props?.ssoLoginData?.userEmail || '',
  });

  useEffect(() => {
    getUserDetailsByToken();
  }, []);

  const getUserDetailsByToken = async () => {
    const email = await getSsoUserEmail(props?.currentSession || '');
    setStateData((prev) => {
      return {
        ...prev,
        email: email,
      };
    });
  };

  return (
    <>
      <View
        bgColor={'white'}
        height={
          Platform.OS === 'web'
            ? 'auto'
            : keyboardHeight == 0
            ? 'full'
            : height - keyboardHeight
        }
      >
        {Platform.OS === 'web' && (
          <VStack
            height={Platform.OS === 'web' ? '100vh' : 'full'}
            justifyContent={'space-between'}
            style={
              Platform.OS === 'web' && {
                position: 'relative',
                right: 100,
              }
            }
          >
            <View />
            <View {...testID(TestIdentifiers.loginBg)} height={height - 100}>{<LoginBg />}</View>
          </VStack>
        )}
        <View
          flex={1}
          alignItems={'flex-end'}
          mt={Platform.OS === 'web' ? (isSmallScreen ? '2%' : '10%') : '0'}
          mr={Platform.OS === 'web' ? '10%' : '0'}
          style={
            Platform.OS === 'web'
              ? {
                  flex: 1,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                }
              : {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 10,
                }
          }
        >
          <View
            style={[
              Platform.OS === 'web' && styles.mainBoxContainerShadow,
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingHorizontal: Platform.OS === 'web' ? 15 : 10,
                paddingBottom: Platform.OS === 'web' ? 36 : 0,
                overflow: Platform.OS === 'web' ? 'hidden' : 'scroll',
                width: Platform.OS === 'web' ? 445 : '100%',
              },
            ]}
            bg={'white'}
          >
            <VStack w={'100%'}>
              {!extraSmallScreen && (
                <HStack {...testID(TestIdentifiers.foldhealthLogoWithName)} justifyContent="center" alignItems={'center'} flex={1}>
                  {Platform.OS === 'web' ? <FoldhealthLogoSvg /> : <></>}
                </HStack>
              )}
              <Text
                style={{
                  textAlign: 'center',
                  fontWeight: '700',
                  fontSize: 24,
                  lineHeight: 45,
                  color: Colors.Custom.Gray500,
                  marginTop: extraSmallScreen ? 6 : 0,
                }}
                {...testID('Something went wrong')}
              >
                {`Something went wrong`}
              </Text>
              <View
                mt={2}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginHorizontal: 10,
                }}
              >
                <Text
                  style={{
                    fontWeight: '500',
                    fontSize: 18,
                    lineHeight: 21,
                    textAlign: 'center',
                    color: Colors.Custom.Gray400,
                  }}
                  {...testID('Please reach out to your admin')}
                >
                  {`Please reach out to your admin ${
                    adminEmail?.length && `(${adminEmail})`
                  } to get the login`}
                </Text>

                <View
                  mt={4}
                  mb={4}
                  height={1}
                  borderBottomColor={Colors.Custom.Gray200}
                  borderBottomWidth={1}
                  width={'85%'}
                ></View>

                <HStack>
                  <Text
                    style={{
                      color: Colors.Custom.Gray400,
                      fontWeight: '500',
                      fontSize: 16,
                      lineHeight: 21,
                      textAlign: 'center',
                    }}
                    {...testID('You are logged in as')}
                  >
                    {'You are logged in as '}
                  </Text>
                  <Text
                    style={{
                      color: 'black',
                      fontWeight: '500',
                      fontSize: 16,
                      lineHeight: 21,
                      textAlign: 'center',
                    }}
                    {...testID(TestIdentifiers.loginUserName)}
                  >
                    {stateData?.email || ''}
                  </Text>
                </HStack>
              </View>
            </VStack>

            <Button
              borderWidth={1}
              borderColor={Colors.danger[200]}
              w={200}
              h={10}
              style={styles.submitElement}
              justifyContent={'center'}
              alignItems={'center'}
              variant={'solid'}
              onPress={() => {
                props?.onSsoLogin();
              }}
              borderRadius={20}
              {...testID(TestIdentifiers.loginBtn)}
              isLoading={stateData?.loading}
            >
              <DisplayText
                textLocalId="Retry"
                size={'smMedium'}
                extraStyles={
                  {color: 'white', fontWeight: '600', fontSize: 14} || ''
                }
              />
            </Button>
          </View>
        </View>
        <HStack
          justifyContent={'center'}
          style={
            Platform.OS === 'web'
              ? {position: 'absolute', bottom: 10, width: '100%'}
              : {
                  width: '100%',
                  display: 'flex',
                  bottom: 24,
                  alignItems: 'center',
                }
          }
        >
          <Text size={'smMedium'} color={Colors.Custom.Gray400} {...testID(TestIdentifiers.copyRightText)}>
            Copyright 2024 Fold Health, Inc. All Rights Reserved.
          </Text>
        </HStack>
      </View>
    </>
  );
};
