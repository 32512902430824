import moment from 'moment';
import {
  ANALYTICS_RESOURCE,
  DISPLAY_TYPE, EMPLOYER_REPORT_FILTER_CODES, SECTION_LIST
} from '../../../../../constants';
import {IAppliedFilter, IBarChartData, IResource, ISectionListItem} from '../interface';
import {EMPLOYER_CONST, TEMP_HIDE_SECTIONS} from './EmployerConst';
import {IEmployeeTypeCount} from './interface';
import { VOICE_CALLING_DIRECTION_WISE, WIDGET_DUMMY_DATA } from '../tempDataFile';
import { getFormattedDateStringInTimeZone, getMomentObj } from '../../../../../utils/DateUtils';
import { Legend } from 'recharts';

export const getCountSumFromResource = (
  resource: IResource[],
  dateRangeType: string,
  resourceCode: string,
  dateRange: any
) => {
  const mutableResourceData = resource?.length ? [...resource] : [];
  const underDate: any = moment(dateRange.to)
    .subtract(getUiRangeMonthGap(dateRangeType, false), 'months')
    .startOf('month');
  const filterData = mutableResourceData?.filter((item) => {
    const date1 = new Date(item?.monthDt?.value || '');
    const date2 = new Date(underDate || '');
    return (
      date1.getTime() === date2.getTime() || date1.getTime() > date2.getTime()
    );
  });
  let currentMonthNum = 0 as any;
  filterData?.forEach((item) => {
    if (item.countNum && !isNaN(item.countNum)) {
      try {
        const tempCount = parseInt(item.countNum as any);
        currentMonthNum = currentMonthNum + tempCount;
      } catch (error) {
        currentMonthNum = currentMonthNum + 0;
      }
    }
  });
  currentMonthNum = parseInt(currentMonthNum);
  return currentMonthNum;
};

export const getIsSectionHidden = (code: string) => {
  const tempHideArray = [...TEMP_HIDE_SECTIONS];
  return code ? tempHideArray.includes(code) : false;
};


export const getEmployeeCount = (empByTypeData: IEmployeeTypeCount[]) => {
  let empCount = 0;
  for (let index = 0; index < empByTypeData.length; index++) {
    const empTypeObj = empByTypeData[index];
    if (empTypeObj.type === 'employee') {
      empCount = empTypeObj.totalCount;
      break;
    }
  }
  return empCount;
};

export const getDependentCount = (empByTypeData: IEmployeeTypeCount[]) => {
  let empCount = 0;
  for (let index = 0; index < empByTypeData.length; index++) {
    const empTypeObj = empByTypeData[index];
    if (empTypeObj.type !== 'employee') {
      empCount += empTypeObj.totalCount;
    }
  }
  return empCount;
};

export const getDependentCodeList = (empByTypeData: IEmployeeTypeCount[]) => {
  const empTypeList = [];
  for (let index = 0; index < empByTypeData.length; index++) {
    const empTypeObj = empByTypeData[index];
    if (empTypeObj.type !== 'employee') {
      empTypeList.push(empTypeObj.type);
    }
  }
  return empTypeList;
};



export const getSectionHideAndShowCheck = (
  isPrintPreview: boolean,
  isVisible: boolean,
  isPrintDownload?: boolean,
  isDisabled?: boolean
) => {
  if (!(isPrintDownload || isPrintPreview)) {
    return isVisible;
  }
  if (isPrintDownload) {
    return !isDisabled && isVisible;
  }
  if (isPrintPreview) {
    return isVisible;
  }
};

export const getCardWidthBySectionCode = (code: string) => {
  let cardWidth: any = '31.5%';
  const widgetTypeOne = [
    DISPLAY_TYPE.BAR_CHART,
    DISPLAY_TYPE.BAR_CHART_WITH_GROUP_COLUMN,
    DISPLAY_TYPE.BAR_CHART_WITH_STACK_COLUMN,
    DISPLAY_TYPE.LISTING_WIDGET_WITH_LIST,
    DISPLAY_TYPE.LISTING_WIDGET_WITH_PROGRESS,
    DISPLAY_TYPE.PIE_CHART,
  ];
  const widgetTypeTwo = [
    DISPLAY_TYPE.COUNT,
    DISPLAY_TYPE.COUNT_WITH_LINE_GRAPH,
  ];
  const widgetTypeThree = [DISPLAY_TYPE.COUNT_VERTICAL_WITH_LINE_GRAPH];

  if (widgetTypeThree.includes(code)) {
    cardWidth = 375;
  } else if (widgetTypeTwo.includes(code)) {
    cardWidth = 505;
  } else if (widgetTypeOne.includes(code)) {
    cardWidth = 505;
  }

  return cardWidth;
};

export const getPercentageValue = (
  comparedNumber: number,
  initialNumber: number
) => {
  const initialNum = initialNumber;
  const comparedNum = comparedNumber;
  const changeInNumber = initialNum - comparedNum;
  let percentageChange = ((comparedNum / initialNum) * 100).toString();
  percentageChange = parseFloat(percentageChange).toFixed(2);
  return parseFloat(percentageChange);
};

export const getPercentageChange = (
  resourceData: IResource[] | any,
  resourceCode: string,
  dateRangeType: string,
  dateRange: any
) => {
  const mutableResourceData = resourceData?.result?.length ? [...resourceData?.result] : resourceData?.length ? [...resourceData] : [];
  let currentCount = 0;
  let previousCount = 0;
  const currentMonths = [] as IResource[];
  const previousMonths = [] as IResource[];
  const underDate: any = moment(dateRange.to)
    .subtract(getUiRangeMonthGap(dateRangeType, false), 'months')
    .startOf('month');

  mutableResourceData?.forEach((item) => {
    const date1 = new Date(item?.monthDt?.value || '');
    const date2 = new Date(underDate || '');
    if (
      date1.getTime() === date2.getTime() ||
      date1.getTime() > date2.getTime()
    ) {
      currentCount = currentCount + item.countNum;
      currentMonths.push(item);
    } else {
      previousCount = previousCount + item.countNum;
      previousMonths.push(item);
    }
  });

  // const currentData = moment().month()
  // const currentMonth = monthsArray[currentData];
  // const lastMonth = monthsArray[currentData-1];
  // let currentMonthNum = 0;
  // let lastMonthNum = 0;
  // if (resourceCode === ANALYTICS_RESOURCE.CHAT_MESSAGING) {
  //   mutableResourceData?.forEach(item=> {
  //     if(item.month === currentMonth) {
  //       currentMonthNum = currentMonthNum + (item.countNum || 0)
  //     }else if (item.month === lastMonth) {
  //       lastMonthNum = lastMonthNum + (item.countNum || 0)
  //     }
  //   })
  // }else {
  // const currentMonthObjectCount =  mutableResourceData.find(item=> {
  //     return item.month === currentMonth
  //   })?.countNum
  //   currentMonthNum =  currentMonthObjectCount != null ? currentMonthObjectCount : 0
  //   const lastMonthObjectCount =  mutableResourceData.find(item=> {
  //     return item.month === lastMonth
  //   })?.countNum

  //   lastMonthNum = lastMonthObjectCount != null  ?  lastMonthObjectCount : 0
  // }

  let positive = true;
  if (currentMonths.length && previousMonths.length) {
    const changeInNumber = currentCount - previousCount;
    if (Math.sign(changeInNumber) === -1) {
      positive = false;
    } else {
      positive = true;
    }
    if (previousCount == 0 || changeInNumber == 0) {
      return {positive: positive, percentageChange: 0};
    }
    let percentageChange = Math.abs(changeInNumber);
    percentageChange = (changeInNumber / previousCount) * 100;
    percentageChange = parseFloat(percentageChange.toFixed(2));
    return {positive: positive, percentageChange: percentageChange};
  } else {
    return {noView: true};
  }
};

export const getSectionWrapperClassName = (sectionCode: string) => {
  let className = 'widget-wrapper-3';
  if (sectionCode === SECTION_LIST.OVERVIEW) {
    className = 'card-widget-wrapper-1';
  } else if (sectionCode === SECTION_LIST.APP_ANALYTICS_ENGAGEMENT) {
    className = 'card-widget-wrapper-2';
  } else if (sectionCode === SECTION_LIST.PATIENT_STATISTICS) {
    className = 'widget-wrapper-3';
  } else if (sectionCode === SECTION_LIST.UTILIZATION) {
    className = 'widget-wrapper-4';
  } else if (sectionCode === SECTION_LIST.TRENDS) {
    className = 'widget-wrapper-5';
  } else if (sectionCode === SECTION_LIST.AVOIDABLE_EMERGENCY_VISITS) {
    className = 'widget-wrapper-6';
  }
  return className;
};

export const getWidgetBoxClassName = (sectionCode: string, resourceCode: string) => {
  let className = 'card-widget-type';
  if (sectionCode === SECTION_LIST.APP_ANALYTICS_ENGAGEMENT) {
    if (
      resourceCode === ANALYTICS_RESOURCE.NO_SHOWS ||
      // resourceCode === ANALYTICS_RESOURCE.VIDEO_CALLING ||
      resourceCode === ANALYTICS_RESOURCE.VOICE_CALLING ||
      resourceCode === ANALYTICS_RESOURCE.CHAT_MESSAGING
    ) {
      className = 'card-widget-type card-widget-type-2'
    }
  }
  return className
  if (
    resourceCode === ANALYTICS_RESOURCE.SMS_MESSAGING ||
    resourceCode === ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING ||
    resourceCode === ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE ||
    resourceCode === ANALYTICS_RESOURCE.USER_MESSAGING ||
    resourceCode === ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT ||
    resourceCode === ANALYTICS_RESOURCE.ENGAGEMENT_ON_APPLIED_RULES ||
    resourceCode === ANALYTICS_RESOURCE.CRM_ACTIVITY

  ) {
    return 'card-widget-type widget-type-3';
  }
  if (sectionCode === SECTION_LIST.OVERVIEW) {
    className = 'card-widget-type widget-type-3';
  } else if (sectionCode === SECTION_LIST.APP_ANALYTICS_ENGAGEMENT) {
    className = 'card-widget-type card-widget-type-2';
  } else if (sectionCode === SECTION_LIST.PATIENT_STATISTICS) {
    className = 'card-widget-type widget-type-3';
  } else if (
    sectionCode === SECTION_LIST.UTILIZATION
    || sectionCode === SECTION_LIST.PATIENT_ENCOUNTERS
    || sectionCode === SECTION_LIST.AVOIDABLE_EMERGENCY_VISITS
    || sectionCode === SECTION_LIST.CLAIMS_COST_ANALYSIS
    || sectionCode === SECTION_LIST.IP_URGENT_CARE_UTILIZATION
  ) {
    className = 'card-widget-type widget-type-4';
  } else if (sectionCode === SECTION_LIST.TRENDS) {
    className = 'card-widget-type widget-type-5';
  } else if (sectionCode === SECTION_LIST.SAVINGS) {
    className = '';
  }
  return className;
};

export const getWidgetBoxResourceClassName = (sectionCode: string) => {
  let className = '';
  if (
    sectionCode === ANALYTICS_RESOURCE.TOTAL_SAVINGS
    || sectionCode === ANALYTICS_RESOURCE.GROUP_SAVING
  ) {
    className = 'widget-total-saving';
  }
  return className;
};

export const getFromDateByDateRange = (code?: string) => {
  if (code == EMPLOYER_CONST.MONTH) {
    return moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
  } else if (code == EMPLOYER_CONST.QUARTER) {
    return moment().subtract(1, 'Q').startOf('month').format('YYYY-MM-DD');
  } else if (code == EMPLOYER_CONST.YEAR) {
    return moment().subtract(1, 'year').startOf('month').format('YYYY-MM-DD');
  } else {
    return moment().format('YYYY-MM-DD');
  }
};

export const getRangeTypeCode = (code?: string) => {
  if (code == EMPLOYER_CONST.MONTH) {
    return 1;
  } else if (code == EMPLOYER_CONST.QUARTER) {
    return 5;
  } else if (code == EMPLOYER_CONST.YEAR) {
    return 23;
  }
};

export const getUiRangeMonthGap = (code?: string, isBack?: boolean) => {
  if (code == EMPLOYER_CONST.MONTH) {
    return isBack ? 1 : 0;
  } else if (code == EMPLOYER_CONST.QUARTER) {
    return isBack ? 3 : 2;
  } else if (code == EMPLOYER_CONST.YEAR) {
    return isBack ? 12 : 11;
  }
};

export const isWidgetDataEmpty = (data: any) => {
  return (data?.result?.columnStackDataList?.length
    || data?.result?.additionalData?.engagedFromMonthData
    || data?.result?.additionalData?.notEngagedFromMonthData
    ) || data?.result?.length ||
    data?.result?.topChronicConditions?.length ||
    data?.result?.topChronicConditionsPatientCount?.length ||
    (data?.result?.queAnsList &&
      Object.keys(data?.result?.queAnsList).length !== 0) ||
    (data?.result?.contactExpData &&
      Object.keys(data?.result?.contactExpData).length !== 0) ||
    (data?.currentDateResp &&
      data?.currentDateResp &&
      data?.previousDateResp &&
      data?.previousDateResp) ||
    (data?.currentDateRangeTotalCostSavingData?.totalDpcCost &&
      data?.currentDateRangeTotalCostSavingData?.totalMarketCost &&
      data?.totalCostSavingHistoryData)
    || (data?.additionalData?.empByType?.length)
    || (data?.result?.contactData?.length)
    ? true
    : false;
};

export const convertObjectToArray = (data: any) => {
  const tempArray = [] as any[];
  Object.keys(data || {}).map(function (key) {
    const temp = {
      name: key,
      value: data[key],
    };
    tempArray.push(temp);
  });
  return tempArray;
};

export const getQuestionAnswerListInArray = (data: any) => {
  const tempArray = convertObjectToArray(data);
  let count = 0;
  if (tempArray.length) {
    tempArray.forEach((item) => {
      item?.value?.ansOptList.forEach((ele: any) => {
        count = count + ele?.contactAnsCount;
      });
    });
  }
  return {tempArray, count};
};

export const getRawPageSection = (data: any[]) => {
  const stringFyObj = JSON.stringify(data);
  const parseObj = JSON.parse(stringFyObj);
  return parseObj;
};

export const getFormattedMasterDataForDetails = (
  data: any,
  resourceCode: string
) => {
  const keyName =
    resourceCode == 'ERX_SAVINGS'
      ? 'erxCostDatalist'
      : resourceCode == 'IMAGING_SAVINGS'
        ? 'imagingCostDatalist'
        : resourceCode == 'LAB_SAVINGS'
          ? 'labCostDatalist'
          : resourceCode == 'VISITS_SAVINGS' || resourceCode == ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING || resourceCode == ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING
            ? 'visitCostDatalist'
            : 'procedureCostDatalist';
  const mutableData = {...data};
  let masterData: any[] = [];
  if (resourceCode === 'ERX_SAVINGS') {
    mutableData?.[keyName]?.forEach((item: any) => {
      masterData.push({
        id: item?.id,
        quantity: item?.accountPrescribedMedications?.[0]?.totalQuantity,
      });
    });
  } else if (resourceCode === 'IMAGING_SAVINGS') {
    mutableData?.[keyName]?.forEach((item: any) => {
      masterData.push({
        id: item?.id,
        quantity: item?.accountPrescribedImagings?.[0]?.totalQuantity,
      });
    });
  } else if (resourceCode === 'LAB_SAVINGS') {
    masterData = mutableData;
  } else if (resourceCode == 'VISITS_SAVINGS' || resourceCode == ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING || resourceCode == ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING) {
    mutableData?.[keyName]?.forEach((item: any) => {
      masterData.push({
        id: item?.id,
        quantity: item?.accountVisitTypes?.[0]?.totalQuantity,
      });
    });
  } else if (resourceCode === 'PROCEDURES_SAVINGS') {
    mutableData?.[keyName]?.forEach((item: any) => {
      masterData.push({
        id: item?.id,
        quantity: item?.accountPrescribedProcedures?.[0]?.totalQuantity,
      });
    });
  }
  return masterData;
};

export const getInfoTitle = (
  resourceCode: string,
  name: string,
  employerName: string,
  date: any
) => {
  let title = ``;
  const fromDate = date?.fromDate?.format('YYYY-MMM') || '';
  const toDate = date?.toDate?.format('YYYY-MMM') || '';
  switch (resourceCode) {
    case ANALYTICS_RESOURCE.EMPLOYEE_COUNT:
      title = `
The Total Active Members widget shows the number of active (at any time) members (by member type) for the selected date range. It specifically captures the number of members who are either active or unpaid during the specified period. A member is unpaid in Hint if they meet all three of these conditions:
1. Unpaid bills (or invoices, in the case of a sponsored membership)
2. Membership has started (i.e., today is after the membership's start date)
3. Membership has not ended  (i.e., today is before the membership's end date)
    `;
      break;
    case ANALYTICS_RESOURCE.EMPLOYEE_LOGGED_IN:
      title = `The Members Who Logged Into The Patient App widget shows the number of members (by member type) who logged into the Fold patient app during a given month.`;
      break;
    case ANALYTICS_RESOURCE.AUTOMATION_TIME_SAVED:
      title = `The Automated vs Manual Engagement widget calculates the engagement activities based on the automation that has been implemented. It considers the time duration set for each automated node and calculates
the time saved by automating those tasks. The Time Saved widget displays the summation of all the nodes used during the selected date range, providing an overview of the total time saved through automation within that period.`;
      break;
    case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE:
      title = `The Engaged for Care widget tracks unique members (by member type) who have engaged for care in a given month.  Engaging for care includes any of the following: completed appointments, voice calls, video calls, messaging, forms or tasks.  If a member has multiple such engagements within any given month that member is only counted as once for that month.  The widget also tracks the number of active members who have engaged at least once in the last 90, 180 and 360 days;  the denominator for "engaged" is derived from the sum of all active (at any time) members over that time frame.`;
      break;
    case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION:
      title = `The linked form should have scoring added with each question, and based on the score, the average satisfaction score is calculated.
For example, the form has 3 questions and each question answer has 4 answers, each question answer has a score of 0, 1, 2, and 3 so 0 is the lowest score and 3 is the highest score.
So considering 2 patients have submitted the form responses -

P1Q1 (response score - 2), P1Q2 (response score - 3), P1Q3 (response score - 1), P1Q4 (response score - 3) = Total score 9/12
P2Q1 (response score - 3), P2Q2 (response score - 1), P2Q3 (response score - 2), P2Q4 (response score - 2) = Total score 8/12

Note: 12 is calculated from 4 questions - highest score 3, so 4*3 = 12
Average rating - (Total score P1(9) + Total score P2(8))/number of patients(2) = 8.5(average score)

Ratings out of 10 - (8.5(avg rating) / 12(total highest score))*10 = 7(.08)`;
      break;
    case ANALYTICS_RESOURCE.CHRONIC_RISKS_TREND:
      title = `Chronic risks show the patient having at least one or two chronic conditions along with the top 10 chronic conditions.

Chronic risk is based on a diagnosis code(ICD 10) mapping, not on the basis of patient vital ranges/biomarker`;
      break;
    case ANALYTICS_RESOURCE.ERX_SAVINGS:
      title = `Savings done by DPC by dispensing medicines through in-house pharmacy/doctors to ${employerName} members. Medication savings is calculated based on the cost entered by the DPC vs Traditional/Market cost for the ${employerName} members during the ${fromDate} to ${toDate}

Medication grouping is based on the NDC code.

Formula: Market cost - DPC cost = saving/unit || ((Market cost-DPC cost)/Market cost)*100=%saving/unit

If market cost is not entered, that record will not be considered for the calculation`;
      break;
    case ANALYTICS_RESOURCE.LAB_SAVINGS:
      title = `Lab savings is calculated based on EHR Lab orders done for the ${employerName} members during the ${fromDate} to ${toDate}, and it is a difference of DPC cost vs market cost.

Lab grouping is done based on the CPT code.

Formula: Market cost - DPC cost = saving/unit || ((Market cost-DPC cost)/Market cost)*100=%saving/unit

If market cost is not entered, that record will not be considered for the calculation`;
      break;
    case ANALYTICS_RESOURCE.IMAGING_SAVINGS:
      title = `Imaging savings is calculated based on EHR imaging/radiology orders done for the ${employerName} members during the ${fromDate} to ${toDate}, and it is a difference of DPC cost vs market cost.

Imaging grouping is done based on the CPT code.

Formula: Market cost - DPC cost = saving/unit || ((Market cost-DPC cost)/Market cost)*100=%saving/unit

If market cost is not entered, that record will not be considered for the calculation`;
      break;
    case ANALYTICS_RESOURCE.PROCEDURES_SAVINGS:
      title = `Procedures Savings is calculated from the specific types and numbers of procedures. The procedure type is determined by the CPT code, obtained from the EHR billing. Market Cost for each type of procedure is selected or manually entered by the provider organization.

Our Cost for each type of procedure is manually entered by the provider organization. Traditional Cost = the total sum of the Qty* Market Cost for each type of procedure. Our Service Cost = the total sum of  Qty * Our Cost for each type of procedure.

The Savings Amt is determined by subtracting Our Service Cost from Traditional Cost.`;
      break;
    case ANALYTICS_RESOURCE.VISITS_SAVINGS:
      title = `Visit Savings is calculated from the specific types and numbers of visits. The visit type is determined by the CPT code, obtained from the EHR billing. Market Cost for each type of visit is selected or manually entered by the provider organization.

Our Cost for each type of visit is manually entered by the provider organization. Traditional Cost = the total sum of the Qty* Market Cost for each type of visit. Our Service Cost = the total sum of  Qty * Our Cost for each type of visit.

The Savings Amt is determined by subtracting Our Service Cost from Traditional Cost.`;
      break;
    case ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING:
    title = `Avoidable Visits Savings is calculated from the specific types and numbers of visits (UC, ED, Specialist) that were avoided. The avoided visit type is determined by the CPT code, obtained from the EHR billing.

Market Cost for each type of avoided visit is selected or manually entered by the provider organization. Traditional Cost = the total sum of the Qty* Market Cost for each type of avoided visit.

The Savings Amt is determined by subtracting Our Service Cost from Traditional Cost.`;
    break;
    case ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING:
    title = `Avoided Workplace Absence Savings is calculated from the number of workplace absences that were avoided, as determined by the CPT code, obtained from the EHR billing.

Market Cost for each avoided workplace absence is selected or manually entered by the provider organization. Traditional Cost = the total sum of the Qty* Market Cost for each type of avoided workplace absence.

The Savings Amt is determined by subtracting Our Service Cost from Traditional Cost.`;
    break;
    case ANALYTICS_RESOURCE.APPOINTMENT_BOOKED:
      title = `The total count of appointments scheduled by members within the timeframe from ${fromDate} to ${toDate}. The number of appointments that members failed to attend or complete between ${fromDate} and ${toDate}.`;
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED:
      title = `The Self-Scheduled Appointments widget shows the number of visits (both "in person" and "video")  that were scheduled by active members (using the patient app) during a given month.`;
      break;
    case ANALYTICS_RESOURCE.NO_SHOWS:
      title = `The total appointment missed/not done by the ${employerName} members during the ${fromDate} to ${toDate}`;
      break;
    case ANALYTICS_RESOURCE.TOP_DIAGNOSIS:
      title = `The Diagnoses From Visits widget shows the total number of visits for the most common diagnoses (based on ICD-10 codes that were assigned following both "in person" and "video" visits) among active members during a selected date range.`;
      break;
    case ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS:
      title = `The Visits By Member Type widget shows the total number of completed visits (both "in person" and "video") among active members (by member type) during a given month.`;
      break;
    case ANALYTICS_RESOURCE.TOP_MEDICATIONS:
      title = `The Top Prescribed Medications widget shows the total number of individual prescriptions among the most commonly prescribed medications during a selected date range.`;
      break;
    case ANALYTICS_RESOURCE.EXPENSE_PARETO_CURVES_TREND:
      title = `Expense Pareto curve requires claims data. It shows the top X% of patients who are spending more on claim expenses. e.g total number of members is 500, so it will sort(descending) members based on the claim expenses and under the top 5% row, it will show the top 25 highly expensive members`;
      break;
    case ANALYTICS_RESOURCE.EMPLOYEE_AGE_GROUP:
      title = `The Age widget shows the age distribution, by specific age groupings, for all members who were active (at any time) during a selected date range.`;
      break;
    case ANALYTICS_RESOURCE.EMPLOYEE_GENDER_GROUP:
      title = `The Gender widget shows the gender distribution for all members who were active (at any time) during a selected date range.`;
      break;
    case ANALYTICS_RESOURCE.ENCOUNTER_TYPES:
      title = `The Visits: In Person Vs. Video shows the total number of completed visits by type ("in person" vs. "video") among active members during a given month.`;
      break;
    case ANALYTICS_RESOURCE.CHAT_MESSAGING:
      title = `Chat messages show the total number of messages done during the ${fromDate} to ${toDate}`;
      break;
    case ANALYTICS_RESOURCE.VIDEO_CALLING:
      title = `The Video Communication widget shows the total number of minutes of video visits completed with members (by member type) during a given month.`;
      break;
    case ANALYTICS_RESOURCE.VOICE_CALLING:
      title = `Voice calling shows the total number of voice call minutes done with members during the ${fromDate} to ${toDate}.`;
      break;
    case ANALYTICS_RESOURCE.TOP_CARE_JOURNEYS:
      title = `The Top Care Journeys widget shows the total number of individual care journeys prescriptions among the most commonly prescribed care journeys during a selected date range.`;
      break;
    case ANALYTICS_RESOURCE.TOP_LABS:
      title = `The Top Lab Orders widget shows the total number of specific lab test orders among the most commonly ordered lab tests during a selected date range.`;
      break;
    case ANALYTICS_RESOURCE.TOP_IMAGING:
      title = `The Top Imaging Orders widget shows the total number of specific imaging study orders among the most commonly ordered imaging studies during a selected date range.`;
      break;
    case ANALYTICS_RESOURCE.TOTAL_SAVINGS:
      title = `The Total Savings widget estimates the overall cost savings for a specific client by subtracting "our service cost" (the cost of the services provided (including visits, labs, imaging,and procedures) and the membership costs) from "Traditional Cost"(estimated market costs for both the services provided and avoided visits/workplace absences).`;
      break;
    case ANALYTICS_RESOURCE.MEMBERSHIP_REVENUE_GROWTH:
      title = `The Membership Revenue widget calculates the total revenue obtained from all members who have purchased or renewed their membership for the selected date range.`;
      break;
    case ANALYTICS_RESOURCE.MEMBERSHIP_NET_GROWTH:
      title = `The New Memberships widget tracks the number of members (by member type) who obtained a new Hint membership during a selected month, giving insights into the growth of the membership base during the specified time period.`;
      break;
    case ANALYTICS_RESOURCE.MEMBERSHIP_INACTIVATED:
      title = `The Attrition widget helps you track the number of previously active members (by member type) whose Hint membership has ended on a month-to-month basis.`;
      break;
    case ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE:
      title = `The Telephone Calls widget shows the number of inbound calls (made to the practice) and outbound calls (made by the practice) during a given month.`;
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_TYPE:
      title = `The Visits By Appointment Type widget shows the total number of completed visits (by "appointment type," as obtained from the EHR) among active members during a selected date range.`;
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE:
      title = `The Duration of Visit widget shows the minimum, maximum and average duration for both "in-person" and "video" visits among active members during a selected date range.`;
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY:
      title = `The Visits By Day Of The Week widget shows the number of completed visits (both "in person" and "video") among active members (by member type) for each day of the week during a selected date range.`;
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME:
      title = `The Visits Overview by Time shows the number of completed visits (both "in person" and "video") among active members (by member type) for specific time periods of the day (including after hours) during a selected date range.`;
      break;
    case ANALYTICS_RESOURCE.TOTAL_CLAIM_COST:
      title = `The Total Cost of Care among active patients during the specified date range is derived from available claims data, providing a comprehensive assessment of the overall healthcare expenditure incurred by active patients within that period.`;
      break;
    case ANALYTICS_RESOURCE.CLINICAL_INTERVENTION_MESSAGE_NOTES:
      title = `The Encounter Notes From Communication widget displays the count of notes created specifically from communication interactions, providing an overview of the number of notes generated during communication-based encounters.`;
      break;
    case ANALYTICS_RESOURCE.SMS_MESSAGING:
      title = `The SMS widget shows the number of text (SMS) message sent by the practice or sent by members (through use of the Fold patient app) during a given month.`;
      break;
    case ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING:
      title = `The Member App Chat widget shows the number of app chats sent by the practice or sent by members (through use of the Fold patient app) during a given month.`;
      break;
    case ANALYTICS_RESOURCE.USER_MESSAGING:
      title = `The Messaging Utilization widget quantifies the total number of text messages (SMS) and app chats sent by individual staff members during a selected date range, and provides insight into utilization of these modalities by individual staff members.`
      break;
    case ANALYTICS_RESOURCE.CLAIMS:
      if (name.trim().toLocaleLowerCase() == 'Emergency Department Claims Cost'.trim().toLocaleLowerCase()) {
        title = `The Total Cost of Emergency Department Care widget utilizes available claims data to determine
the healthcare expenditure incurred by active patients specifically in the emergency department during a specified date range. It provides a comprehensive assessment of the overall costs associated with emergency care
for those patients within that period.`
      } else if (name.trim().toLocaleLowerCase() == 'Inpatient Claims Cost'.trim().toLocaleLowerCase()) {
        title = `The Inpatient Claims Cost widget analyzes claims data to determine the total cost associated with inpatient care for active patients. It provides insights into the healthcare expenditure incurred
specifically for inpatient services, allowing for a comprehensive assessment of the costs associated with inpatient care within the specified context`;
      } else if (name.trim().toLocaleLowerCase() == 'Outpatient Claims Cost'.trim().toLocaleLowerCase()) {
        title = `The Total Cost of Outpatient Care widget utilizes available claims data to calculate the healthcare expenditure incurred by active patients specifically for outpatient services during a specified date range.
It provides a comprehensive assessment of the overall costs associated with outpatient care for those patients within that period.`
      } else {
        title = name;
      }
      break;
    case ANALYTICS_RESOURCE.BILLING_CPT_CODES:
      if (name.trim().toLocaleLowerCase() == 'Avoided Specialist Visit'.trim().toLocaleLowerCase()) {
        title = `The Avoided Specialist Visits widget shows the total number of "avoided" specialist visits, as determined by a specific corresponding CPT-code (assigned following "in person" and "video" visits), among active members during a given month.  The clinical determination of whether a specialist visit is "avoided" is decided by the treating clinician.`
      } else if (name.trim().toLocaleLowerCase() == 'Avoided WC'.trim().toLocaleLowerCase()) {
        title = `The Avoided Workers Compensation Visits widget shows the total number of "avoided" workers compensation visits, as determined by a specific corresponding CPT-code (assigned following "in person" and "video" visits), among active members during a given month.  The clinical determination of whether a workers compensation visit is "avoided" is decided by the treating clinician.`;
      } else if (name.trim().toLocaleLowerCase() == 'Avoided Workday Absence'.trim().toLocaleLowerCase()) {
        title = `The Avoided Workday Absence widget shows the total number of "avoided" workday absences, as determined by a specific corresponding CPT-code (assigned following "in person" and "video" visits), among active members during a given month.  The clinical determination of whether a workday absence is "avoided" is decided by the treating clinician.`
      } else if (name.trim().toLocaleLowerCase() == 'Avoided ED'.trim().toLocaleLowerCase()) {
        title = `This represents cases where potential emergency department visits were averted through a combination of CPT codes and a list of mapped diagnoses for members.`
      } else if (name.trim().toLocaleLowerCase() == 'Avoidable Urgent Care Visits'.trim().toLocaleLowerCase()) {
        title = `The Avoided Urgent Care Visits widget shows the total number of "avoided" urgent care visits, as determined by a specific corresponding CPT-code (assigned following "in person" and "video" visits), among active members during a given month.  The clinical determination of whether an urgent care visit is "avoided" is decided by the treating clinician.`
      } else {
        title = name;
      }
      break;
    case ANALYTICS_RESOURCE.BILLING_CPT_CODES_AVOIDED_ED:
      title = `The Avoided Emergency Department Visits widget shows the total number of "avoided" emergency department visits, as determined by a specific corresponding CPT-code (assigned following "in person" and "video" visits), among active members during a given month.  The clinical determination of whether an emergency department visit is "avoided" is decided by the treating clinician.`;
      break;
    case ANALYTICS_RESOURCE.ED_UTILIZATION_PERCENT:
      title = `The Classification of ED Visit Severity widget utilizes the NYU-EDA methodology and available claims data to classify the severity of emergency department (ED) visits among active patients during a specific date range.
It provides insights into the severity levels of ED visits, allowing for a comprehensive assessment of the acuity of patient encounters within the specified context.`;
      break;
    case ANALYTICS_RESOURCE.ED_UTILIZATION_AMOUNT:
      title = `The Total Cost of ED Care based on ED visit severity (NYU-ED methodology) widget analyzes available claims data to calculate the healthcare expenditure incurred by active patients during a specific date range,
stratified by the severity levels of emergency department (ED) visits according to the NYU-ED methodology. It provides a comprehensive assessment of the overall costs associated with ED care for patients, categorized based on the
severity of their ED visits within the specified context`
      break;
    case ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT:
      title = `The Office-Hours Vs. After-Hours Engagement widget tracks the total number of engagements (including completed appointments, voice calls, video calls, messaging, forms and tasks) among active members (by member type) occurring during normal business hours (office-hours) vs. after normal business hours (after hours) during a given month.  This widget provides unique insight into the percentage of engagment and cumulative member-related activity that occurs after normal business hours.`;
      break;
    case ANALYTICS_RESOURCE.FOLD_CONVERSIONS:
      title = `The Patient Conversion Post Engagement widget focuses on patients who transitioned from an inactive status to an active status following their engagement with an automation.`;
      break;
    case ANALYTICS_RESOURCE.DPC_STRATIFICATION:
      title = `The Member DPC Utilization Stratification widget identifies and stratifies the top members who are utilizing services, including Lab tests, Visits, Imaging procedures, Procedures, and Messaging, to the greatest extent within the Direct Primary Care (DPC) model.
It analyzes the frequency and extent of utilization across these service categories to determine the members with the highest utilization rates`;
      break;
    case ANALYTICS_RESOURCE.ENGAGEMENT_ON_APPLIED_RULES:
      title = `The Hypertension Engagement Dashboard widget utilizes a set of rules to identify and categorize patients with hypertension based on specific criteria. It tracks engagement levels and provides key metrics for each rule, such as the count of patients who had appointments within different timeframes,
patients with elevated BMI, patients currently on hypertension medications, and patients with elevated blood pressure readings. Please find the below rules :
1.Appointment In The Last 3 Months
2.Appointment In The Last 6 Months
3.Appointment In The Last 12 Months
4.BMI > 25 At The Last Appointment
5.Currently Prescribed Hypertension Medications
6.Elevated BP (140/90) At Last Appointment/BP Check`;
      break;
    case ANALYTICS_RESOURCE.EMPLOYER_SATISFACTION:
      title = `The linked form should have scoring added with each question, and based on the score, the average satisfaction score is calculated.
For example, the form has 3 questions and each question answer has 4 answers, each question answer has a score of 0, 1, 2, and 3 so O is the lowest score and 3 is the highest score.
So considering 2 employers have submitted the form responses -

P1@1 (response score - 2), P1Q2 (response score - 3), P1Q3 (response score - 1), P1Q4
(response score - 3) = Total score 9/12
P2Q1 (response score - 3), P2Q2 (response score - 1), P2Q3 (response score - 2), P2Q4
(response score - 2) = Total score 8/12

Note: 12 is calculated from 4 questions -
highest score 3, so 4*3 = 12
Average rating - (Total score P1(9) + Total score P2(8)) /number of patients (2) =
8.5 (average score)
Ratings out of 10 - (8.5 (avg rating) / 12 (total
highest score))*10 = 7(.08)`;
      break;
    case ANALYTICS_RESOURCE.USER_SATISFACTION:
      title = `The linked form should have scoring added with each question, and based on the score, the average satisfaction score is calculated.
For example, the form has 3 questions and each question answer has 4 answers, each question answer has a score of 0, 1, 2, and 3 so O is the lowest
score and 3 is the highest score.

So considering 2 workplace employees have submitted the form responses -

P1@1 (response score - 2), P1Q2 (response score - 3), P1Q3 (response score - 1), P1Q4
(response score - 3) = Total score 9/12
P2Q1 (response score - 3), P2Q2 (response score - 1), P2Q3 (response score - 2), P2Q4
(response score - 2) = Total score 8/12

Note: 12 is calculated from 4 questions -
highest score 3, so 4*3 = 12
Average rating - (Total score P1(9) + Total score P2(8)) /number of patients (2) =
8.5 (average score)
Ratings out of 10 - (8.5 (avg rating) / 12 (total
highest score))*10 = 7(.08)`;
      break;
    case ANALYTICS_RESOURCE.URGENT_CARE_CLAIM_COST:
      title = `The Urgent Care widget filters and identifies claims that are specifically related to Urgent Care services. It employs an OR condition to consider two criteria: CPT codes (S9088 and S9083) and the Place of Service (POS) field values.
If a claim contains either of the specified CPT codes or has a POS value of 17 (Walk-in Retail Health Clinic) or 20 (Urgent Care Facility), it is considered as an Urgent Care claim.`
      break
    case ANALYTICS_RESOURCE.IP_CLAIM_COST:
      title = `The IP Visit widget filters and identifies claims that are specifically related to Inpatient care. It utilizes an AND condition to consider two criteria: the Revenue Code range of 0100 to 0219 and the Place of Service (POS) codes.
If a claim contains a Revenue Code within the specified range and has a POS code of 21, 51, 56, 61, or no POS code present, it is considered an Inpatient care claim.`
      break
    case ANALYTICS_RESOURCE.NET_PROMOTER_SCORE:
      title = `Based on the configured categories the NPS % is calculated as below \r\n
Net Promoter Score =  NPS = % promoters - % detractors.
Detractors (Score 0-6): Detractors represent dissatisfied customers who are unlikely to return. Their negative experiences can harm your brand's reputation as they may share their grievances with others.
Passives (Score 7-8): Passives are generally satisfied customers, but they don't actively promote your brand. They have the potential to become Promoters with the right engagement or could be swayed by competitors.
Promoters (Score 9-10): Promoters are your most valuable customers. They exhibit strong loyalty and actively advocate for your products and services. They serve as effective word-of-mouth marketers, essentially extending your marketing efforts.`
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_RATING:
      title = `Following each appointment that has been completed or checked out, Fold will distribute a survey to patients. Based on the responses provided in the survey, Fold will then display the ratings given by the patient after the appointment.`
      break
    case ANALYTICS_RESOURCE.COMMUNICATION_STATS:
      title = `Employers assess savings achieved through member engagement in Direct Primary Care (DPC) by analyzing communication statistics, including chats, SMS, emails, and calls, categorized as communication types. This evaluation takes into account both market costs and our cost, with a focus on optimizing cost-efficiency.`
      break
    case ANALYTICS_RESOURCE.CRM_ACTIVITY:
      title = `The Member Engagement Stratification widget shows the total number of the different types of member engagement activities (including voice calls, video calls, messaging, forms and tasks) amongst active members during a given month.`
      break
    case ANALYTICS_RESOURCE.PATIENT_ABSENCE_ANALYSIS:
      title = `The Patients Not Seen widget tracks the number of active members who never had appointment in the last 90, 180 and 360 days; the denominator for "Not Seen" is derived from the sum of all active (at any time) members over that time frame.`
      break
    case ANALYTICS_RESOURCE.NOT_ENROLLED_FOR_CARE:
      title = `The widget tracks the number of active members who have not been engaged in the last 90, 180 and 360 days; the denominator for "Not Engaged" is derived from the sum of all active (at any time) members over that time frame. Engaging for care includes any of the following: completed appointments, voice calls, video calls, messaging, forms or tasks. If a member has at least once such engagements within any given month that member is ignore for calculation of the numerator`
      break
    default:
      title = name;
      break;
  }
  return title;
};

export const getAllSubsectionInfoText = (sectionList: ISectionListItem[], employerName: string, date: any) => {
  let finalInfoString = '';
  sectionList?.forEach((singleSectionItem) => {
    const currentInfoText = getInfoTitle(singleSectionItem?.resourceCode as any, singleSectionItem?.name, employerName, date);
    finalInfoString = finalInfoString + currentInfoText?.trim() + '.\n';
  });
  return finalInfoString;
}

export const getSubHeadingBySectionCode = (sectionCode: string) => {
  let subHeading = '';
  switch (sectionCode) {
    case SECTION_LIST.SAVINGS:
      subHeading = ''
      break;
    default:
      break;
  }
  return subHeading;
}

export const getYAxisTitleByResourceCode = (resourceCode: string, sectionData?: ISectionListItem) => {
  let axisTitle = {
    title: '',
    description: ''
  }
  switch (resourceCode) {
    case ANALYTICS_RESOURCE.EMPLOYEE_COUNT:
      axisTitle = {
        title: 'Number of Active Members',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION:
      axisTitle = {
        title: 'Number of Response',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.MEMBERSHIP_NET_GROWTH:
      axisTitle = {
        title: 'Number of New Memberships',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.FOLD_INACTIVE_ENGAGEMENT:
      axisTitle = {
        title: 'Number of Engagement',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.EMPLOYEE_LOGGED_IN:
    case ANALYTICS_RESOURCE.MEMBERSHIP_INACTIVATED:
    case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE:
    case ANALYTICS_RESOURCE.FOLD_CONVERSIONS:
    case ANALYTICS_RESOURCE.FOLD_INACTIVE_ENGAGEMENT:
    case ANALYTICS_RESOURCE.NET_PROMOTER_SCORE:
      axisTitle = {
        title: 'Number of Members',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.BILLING_CPT_CODES:
      if (
        sectionData &&
        sectionData?.name.trim().toLocaleLowerCase() ==
          'Avoided Workday Absence'.trim().toLocaleLowerCase()
      ) {
        axisTitle = {
          title: 'Number of Avoided Absences',
          description: '',
        };
      } else {
        axisTitle = {
          title: 'Number of Avoided Visits',
          description: '',
        };
      }
      break;
    case ANALYTICS_RESOURCE.BILLING_CPT_CODES_AVOIDED_ED:
      axisTitle = {
        title: 'Number of Avoided Visits',
        description: '',
      };
      break;
    case ANALYTICS_RESOURCE.MEMBERSHIP_REVENUE_GROWTH:
      axisTitle = {
        title: 'Membership Revenue in Dollars',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.EMPLOYER_SATISFACTION:
      axisTitle = {
        title: 'Average Score By Employer',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.MEMBER_SATISFACTION:
      axisTitle = {
        title: 'Average Score By Members',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.USER_SATISFACTION:
      axisTitle = {
        title: 'Average Score By Practice Employees',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION:
      axisTitle = {
        title: 'Average Score By Members',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED:
      axisTitle = {
        title: 'Number of Appointments',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.CLINICAL_INTERVENTION_MESSAGE_NOTES:
      axisTitle = {
        title: 'Number of Notes',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE:
    case ANALYTICS_RESOURCE.TELEPHONE_BY_DAY:
    case ANALYTICS_RESOURCE.TELEPHONE_BY_TIME:
      axisTitle = {
        title: 'Number of Calls',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING:
      axisTitle = {
        title: 'Number of App Chats',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_BOOKED:
      axisTitle = {
        title: 'Number of Appointments',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.VIDEO_CALLING:
      axisTitle = {
        title: 'Duration of Video Calls (Minutes)',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.SMS_MESSAGING:
      axisTitle = {
        title: 'Number of SMS',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT:
      axisTitle = {
        title: 'Total Number of Engagements',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.USER_MESSAGING:
    case ANALYTICS_RESOURCE.USER_VIDEO_USAGE:
      axisTitle = {
        title: 'Users',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.TOP_IMAGING:
      axisTitle = {
        title: 'Imaging Orders',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.TOP_LABS:
      axisTitle = {
        title: 'Lab Orders',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.TOP_CARE_JOURNEYS:
      axisTitle = {
        title: 'Care Journeys',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.TOP_MEDICATIONS:
      axisTitle = {
        title: 'Medication',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_TYPE:
      axisTitle = {
        title: 'Visit Reasons',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.ENCOUNTER_TYPES:
    case ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY:
    case ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME:
    case ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS:
      axisTitle = {
        title: 'Number of Visits',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.TOP_DIAGNOSIS:
      axisTitle = {
        title: 'Diagnoses',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE:
      axisTitle = {
        title: 'Visit Value',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.TOP_SYMPTOMS_CHIEF_COMPLAINTS:
      axisTitle = {
        title: 'Conditions',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.CHRONIC_RISKS_TREND:
      axisTitle = {
        title: 'Chronic Conditions',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.CLAIMS:
    case ANALYTICS_RESOURCE.TOTAL_CLAIM_COST:
    case ANALYTICS_RESOURCE.IP_CLAIM_COST:
    case ANALYTICS_RESOURCE.URGENT_CARE_CLAIM_COST:
    case ANALYTICS_RESOURCE.ED_UTILIZATION_AMOUNT:
      axisTitle = {
        title: 'Claim Cost',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.ENGAGEMENT_ON_APPLIED_RULES:
      axisTitle = {
        title: 'Median of Vitals',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.CRM_ACTIVITY:
      axisTitle = {
        title: 'Number of Engagements',
        description: ''
      }
      break;
  }
  return axisTitle;
}
export const getXAxisTitleByResourceCode = (resourceCode: string) => {
  let axisTitle = {
    title: '',
    description: ''
  }
  switch (resourceCode) {
    case ANALYTICS_RESOURCE.EMPLOYEE_COUNT:
    case ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS:
    case ANALYTICS_RESOURCE.EMPLOYEE_LOGGED_IN:
    case ANALYTICS_RESOURCE.MEMBERSHIP_INACTIVATED:
    case ANALYTICS_RESOURCE.MEMBERSHIP_NET_GROWTH:
    case ANALYTICS_RESOURCE.MEMBERSHIP_REVENUE_GROWTH:
    case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE:
    case ANALYTICS_RESOURCE.EMPLOYER_SATISFACTION:
    case ANALYTICS_RESOURCE.MEMBER_SATISFACTION:
    case ANALYTICS_RESOURCE.USER_SATISFACTION:
    case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION:
    case ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING:
    case ANALYTICS_RESOURCE.SMS_MESSAGING:
    case ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE:
    case ANALYTICS_RESOURCE.ENCOUNTER_TYPES:
    case ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED:
    case ANALYTICS_RESOURCE.APPOINTMENT_BOOKED:
    case ANALYTICS_RESOURCE.VIDEO_CALLING:
    case ANALYTICS_RESOURCE.CLINICAL_INTERVENTION_MESSAGE_NOTES:
    case ANALYTICS_RESOURCE.CLAIMS:
    case ANALYTICS_RESOURCE.IP_CLAIM_COST:
    case ANALYTICS_RESOURCE.TOTAL_CLAIM_COST:
    case ANALYTICS_RESOURCE.URGENT_CARE_CLAIM_COST:
    case ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT:
    case ANALYTICS_RESOURCE.ENGAGEMENT_ON_APPLIED_RULES:
    case ANALYTICS_RESOURCE.FOLD_CONVERSIONS:
    case ANALYTICS_RESOURCE.FOLD_INACTIVE_ENGAGEMENT:
    case ANALYTICS_RESOURCE.CRM_ACTIVITY:
    case ANALYTICS_RESOURCE.BILLING_CPT_CODES:
    case ANALYTICS_RESOURCE.ED_UTILIZATION_AMOUNT:
    case ANALYTICS_RESOURCE.NET_PROMOTER_SCORE:
    case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION:
      axisTitle = {
        title: 'Months',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY:
    case ANALYTICS_RESOURCE.TELEPHONE_BY_DAY:
      axisTitle = {
        title: 'Day of the Week',
        description: ''
      }
      break
    case ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME:
    case ANALYTICS_RESOURCE.TELEPHONE_BY_TIME:
    axisTitle = {
      title: 'Time of Day',
      description: ''
    }
    break
    case ANALYTICS_RESOURCE.USER_MESSAGING:
      axisTitle = {
        title: 'Total Number of Messages (Chat/SMS)',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.USER_VIDEO_USAGE:
    axisTitle = {
      title: 'Total Number of meetings',
      description: ''
    }
    break;
    case ANALYTICS_RESOURCE.USER_APPOINTMENT_TIME_USAGE:
    axisTitle = {
      title: 'Duration (in hours)',
      description: ''
    }
    break;
    case ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE:
      axisTitle = {
        title: 'Duration (Minutes)',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.TOP_IMAGING:
      axisTitle = {
        title: 'Number of Imaging Orders',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_TYPE:
    case ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE:
      axisTitle = {
        title: 'Number of Visits',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.TOP_SYMPTOMS_CHIEF_COMPLAINTS:
      axisTitle = {
        title: 'Number of Members',
        description: ''
      }
      break;
    case ANALYTICS_RESOURCE.TOP_DIAGNOSIS:
    axisTitle = {
      title: 'Number of visits for these diagnoses',
      description: ''
    }
    break;
    case ANALYTICS_RESOURCE.TOP_MEDICATIONS:
      axisTitle = {
        title: 'Number of Prescriptions',
        description: ''
      }
    break;
    case ANALYTICS_RESOURCE.TOP_LABS:
      axisTitle = {
        title: 'Number of Lab Orders',
        description: ''
      }
    break;
    case ANALYTICS_RESOURCE.TOP_CARE_JOURNEYS:
      axisTitle = {
        title: 'Number of Care Journeys',
        description: ''
      }
    break;
    case ANALYTICS_RESOURCE.CHRONIC_RISKS_TREND:
      axisTitle = {
        title: 'Number of Members',
        description: ''
      }
    break;
  }
  return axisTitle;
}

export const getSourceNameByWidget = (resourceCode: string) => {
  let sourceName = ''
  switch (resourceCode) {
    case ANALYTICS_RESOURCE.NOT_ENROLLED_FOR_CARE:
      sourceName = 'EHR FOR APPT/FOLD FOR COMMUNICATION'
      break;
    case ANALYTICS_RESOURCE.PATIENT_ABSENCE_ANALYSIS:
      sourceName = 'EHR FOR APPT'
      break;
    case ANALYTICS_RESOURCE.EMPLOYEE_COUNT:
    case ANALYTICS_RESOURCE.MEMBERSHIP_REVENUE_GROWTH:
    case ANALYTICS_RESOURCE.MEMBERSHIP_NET_GROWTH:
    case ANALYTICS_RESOURCE.MEMBERSHIP_INACTIVATED:
      sourceName = 'Hint'
      break;
    case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE:
      sourceName = 'EHR for appt/Fold communication'
      break;
    case ANALYTICS_RESOURCE.TOTAL_SAVINGS:
      sourceName = 'Hint/EHR/Fold'
      break;
    case ANALYTICS_RESOURCE.VISITS_SAVINGS:
    case ANALYTICS_RESOURCE.IMAGING_SAVINGS:
    case ANALYTICS_RESOURCE.PROCEDURES_SAVINGS:
      sourceName = 'EHR'
      break;
    case ANALYTICS_RESOURCE.LAB_SAVINGS:
      sourceName = 'EHR Lab Orders'
      break;
    case ANALYTICS_RESOURCE.MEMBER_SATISFACTION:
      sourceName = 'Fold Form Score'
      break;
    case ANALYTICS_RESOURCE.CHAT_MESSAGING:
    case ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE:
    case ANALYTICS_RESOURCE.OUT_OFFICE_HOUR_USER_ENGAGEMENT:
    case ANALYTICS_RESOURCE.NET_PROMOTER_SCORE:
    case ANALYTICS_RESOURCE.COMMUNICATION_STATS:
      sourceName = 'Fold'
      break;
    case ANALYTICS_RESOURCE.VIDEO_CALLING:
      sourceName = 'Fold Video Call Logs'
      break;
    case ANALYTICS_RESOURCE.VOICE_CALLING:
      sourceName = 'Fold Voice Call Logs'
      break;
    case ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING:
      sourceName = 'Fold Member App chats log'
      break;
    case ANALYTICS_RESOURCE.SMS_MESSAGING:
      sourceName = 'Fold SMS chats log'
      break;
    case ANALYTICS_RESOURCE.ENGAGEMENT_ON_APPLIED_RULES:
      sourceName = 'EHR/Fold'
      break;
    case ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS:
      sourceName = 'EHR appts Data'
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_TYPE:
      sourceName = 'EHR Description-Chief complaint Field'
      break;
    case ANALYTICS_RESOURCE.ENCOUNTER_TYPES:
      sourceName = 'EHR Appt creation';
      break
    case ANALYTICS_RESOURCE.APPOINTMENT_AGGREGATE:
      sourceName = 'EHR Duration field from appts data'
      break;
    case ANALYTICS_RESOURCE.CLINICAL_INTERVENTION_MESSAGE_NOTES:
      sourceName = 'Fold Communication data'
      break;
    case ANALYTICS_RESOURCE.TOP_MEDICATIONS:
      sourceName = 'EHR Prescribed Medications'
      break;
    case ANALYTICS_RESOURCE.TOP_DIAGNOSIS:
      sourceName = 'EHR Diagnosis'
      break;
    case ANALYTICS_RESOURCE.EMPLOYEE_AGE_GROUP:
      sourceName = 'EHR/Fold DOB Field'
      break;
    case ANALYTICS_RESOURCE.EMPLOYEE_GENDER_GROUP:
      sourceName = 'EHR/Fold gender Field'
      break;
    case ANALYTICS_RESOURCE.CHRONIC_RISKS_TREND:
      sourceName = 'Encounter Diagnosis data from EHR';
      break
    case ANALYTICS_RESOURCE.IP_CLAIM_COST:
    case ANALYTICS_RESOURCE.URGENT_CARE_CLAIM_COST:
      sourceName = 'Claims Data';
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_RATING:
      sourceName = 'Fold/Elation';
      break
  }
  return sourceName;
}

export const aggregateGroupEntry = (resourceCode: string, dataList: any[]) => {
  let returnArray: any = []
  let groupedData: any = {}
  switch (resourceCode) {
    case ANALYTICS_RESOURCE.MEMBERSHIP_NET_GROWTH:
    case ANALYTICS_RESOURCE.MEMBERSHIP_INACTIVATED:
    case ANALYTICS_RESOURCE.EMPLOYEE_COUNT:
    case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE:
    case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION:
    case ANALYTICS_RESOURCE.NET_PROMOTER_SCORE:
    case ANALYTICS_RESOURCE.VIDEO_CALLING:
    case ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING:
    case ANALYTICS_RESOURCE.SMS_MESSAGING:
    case ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE:
    case ANALYTICS_RESOURCE.SMS_MESSAGING:
    case ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS:
    case ANALYTICS_RESOURCE.ENCOUNTER_TYPES:
    case ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED:
    case ANALYTICS_RESOURCE.EMPLOYEE_LOGGED_IN:
    case ANALYTICS_RESOURCE.BILLING_CPT_CODES:
      groupedData = dataList.reduce((acc, obj) => {
        const { month, value } = obj;
        if (!acc[month]) {
          acc[month] = 0;
        }
        acc[month] += value;
        return acc;
      }, {});
       returnArray = Object.keys(groupedData).map((month, index: number) => (
        {
          type: 'text',
          position: [index, groupedData[month]],
          content: convertNumberToDisplayFormat(groupedData[month]),
          offsetY: -10,
          style: {
            fontSize: 10,
            textAlign: 'center',
          },
        }
        ));
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY:
    case ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME:
    case ANALYTICS_RESOURCE.TELEPHONE_BY_DAY:
    case ANALYTICS_RESOURCE.TELEPHONE_BY_TIME:
        groupedData = dataList.reduce((acc, obj) => {
          const { groupBy, value } = obj;
          if(!acc[groupBy]) {
            acc[groupBy] = 0;
          }
          acc[groupBy] += value;
          return acc;
        }, {});
          returnArray = Object.keys(groupedData).map((weekDay, index: number) => (
            {
              type: 'text',
              position: [index, groupedData[weekDay]],
              content: convertNumberToDisplayFormat(groupedData[weekDay]),
              offsetY: -10,
              style: {
                textAlign: 'center',
              },
            }
          ));
      break;
    // Need to right common logic here Akshay
    case ANALYTICS_RESOURCE.APPOINTMENT_TYPE:
      groupedData = dataList.reduce((acc, obj) => {
        const { groupBy, value } = obj;
        if(!acc[groupBy]) {
          acc[groupBy] = 0;
        }
        acc[groupBy] += value;
        return acc;
      }, {});
       returnArray = Object.keys(groupedData).map((headingName, index: number) => (
        {
          type: 'text',
          position: [index, groupedData[headingName]],
          content:  convertNumberToDisplayFormat(groupedData[headingName]),
          offsetY: 0,
          offsetX: 15,
          style: {
            textAlign: 'center',
            fontSize: 10,
          },
        }
        ));
      break;
    case ANALYTICS_RESOURCE.TOTAL_SAVINGS:
      groupedData = dataList.reduce((acc, obj) => {
        const {name, value} = obj;
        if(!acc[name]) {
          acc[name] = 0;
        }
        acc[name] += value
        return acc
      }, {});
      returnArray = Object.keys(groupedData).map((costName, index: number) => (
        {
          type: 'text',
          position: [index, groupedData[costName]],
          content: convertNumberToDisplayFormat(groupedData[costName]),
          offsetY: -10,
          style: {
            textAlign: 'center',
          },
        }
        ));
      break;

  }
  return returnArray
}

export const getTotalAggregateByMonth = (resourceCode: string, dataList: any[]) => {
  let returnArray: any = []
  let groupedData: any = {};
  switch (resourceCode) {
    case ANALYTICS_RESOURCE.MEMBERSHIP_NET_GROWTH:
    case ANALYTICS_RESOURCE.MEMBERSHIP_INACTIVATED:
    case ANALYTICS_RESOURCE.EMPLOYEE_COUNT:
    case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE:
    case ANALYTICS_RESOURCE.VIDEO_CALLING:
    case ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING:
    case ANALYTICS_RESOURCE.SMS_MESSAGING:
    case ANALYTICS_RESOURCE.NET_PROMOTER_SCORE:
    case ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE:
    case ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS:
    case ANALYTICS_RESOURCE.ENCOUNTER_TYPES:
    case ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED:
    case ANALYTICS_RESOURCE.EMPLOYEE_LOGGED_IN:
    case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION:
    case ANALYTICS_RESOURCE.BILLING_CPT_CODES:
      groupedData = dataList.reduce((acc, obj) => {
        const { month, value } = obj;
        if (!acc[month]) {
          acc[month] = 0;
        }
        acc[month] += value;
        return acc;
      }, {});
      returnArray = Object.keys(groupedData).map((month, index) => ({
        month,
        total: groupedData[month],
      }));
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY:
    case ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME:
    case ANALYTICS_RESOURCE.TELEPHONE_BY_DAY:
    case ANALYTICS_RESOURCE.TELEPHONE_BY_TIME:
      groupedData = dataList.reduce((acc, obj) => {
        const { groupBy, value } = obj;
        if (!acc[groupBy]) {
          acc[groupBy] = 0;
        }
        acc[groupBy] += value;
        return acc;
      }, {});
      returnArray = Object.keys(groupedData).map((weekDay, index) => ({
        weekDay,
        total: groupedData[weekDay],
      }));
      break;
    // Need to right common logic here Akshay
    case ANALYTICS_RESOURCE.APPOINTMENT_TYPE:
      groupedData = dataList.reduce((acc, obj) => {
        const { groupBy, value } = obj;
        if(!acc[groupBy]) {
          acc[groupBy] = 0;
        }
        acc[groupBy] += value;
        return acc;
      }, {});
        returnArray = Object.keys(groupedData).map((month, index) => ({
          month,
          total: groupedData[month],
        }));
      break;
  }
    return returnArray
}

export const convertNumberToDisplayFormat = (text: string) => {
  const value = parseInt(text);
  const suffixes = ["", "k", "M", "G", "T"];
  const absValue = Math.abs(value);
  const suffixNum = Math.floor(Math.log10(absValue) / Math.log10(1000));
  const formattedNumber = absValue / Math.pow(1000, suffixNum);
  if (formattedNumber >= 1 && value > 999) {
    return formattedNumber.toFixed(1) + suffixes[suffixNum];
  } else {
    return value;
  }
}

export const getUniqueLegendNames = (resourceData: any, legendKey: string) => {
  const legendName: string[] = [];
  for(const entry of resourceData) {
    if(!legendName.includes(entry?.[legendKey])) {
      legendName.push(entry[legendKey])
    }
  }
  return legendName;
}

export const getDummyDataArr = (dateFilter: IAppliedFilter, resourceCode: string, legendKey: string, uniqueLegendName: any) => {
  const newResourceData: any[] = [];
  const fromDate = new Date(dateFilter?.filterValue.fromDate)
  const toDate = new Date(dateFilter?.filterValue.toDate)
  const fromDateM = getMomentObj(fromDate);
  const toDateM = getMomentObj(toDate);
  // if (Math.round(toDateM.diff(fromDateM, 'days')/30) < 4) {
  //   return;
  // }
  const monthArr: string[] = ["January","February","March","April","May","June","July",
  "August","September","October","November","December"];
  if (fromDateM.year() < toDateM.year()) {
    const rotations = 12 - fromDateM.month();
    for (let i = 0; i < rotations; i++) {
      const lastMonth = monthArr.pop()
      if (lastMonth)
        monthArr.unshift(lastMonth)
    }
  }
  let year = fromDateM.year();
  if (toDateM.year() - fromDateM.year() > 1) {
    year = toDateM.year() - 1 //TODO We don't know when specifically for Video calling widget this is happening
  }
  monthArr.map((monthName, index) => {
    uniqueLegendName.map((name:string) => {
      if (monthName === 'January') {
        year = toDateM.year()
      }
      const emptyObject = getDummyDataForWidget(resourceCode, legendKey, monthName, name, year)
      newResourceData.push((emptyObject));
    })
  })
  return newResourceData;
}

function getDummyDataForWidget(resourceCode: string, legendKey: string, monthName: string, legendName: string, year: number ) {
  const retVal: any = {
    month: monthName,
    monthDt: {
      value: getMomentObj(new Date())
      .year(year)
      .month(monthName)
      .set('date', 1)
      .format('YYYY-MM-DD'),
    },
    countNum: 0
  }
  retVal[legendKey] = legendName;
  if (resourceCode === ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE
    || resourceCode === ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING
    || resourceCode === ANALYTICS_RESOURCE.SMS_MESSAGING
    || resourceCode === ANALYTICS_RESOURCE.VIDEO_CALLING) {
      retVal.monthDt.value = retVal.monthDt.value + 'T00:00:00.000Z'
  }
  return retVal;
}

function getLegendKeyForResouce(resourceCode: string) {
  if (resourceCode === ANALYTICS_RESOURCE.VOICE_CALLING_DIRECTION_WISE || resourceCode === ANALYTICS_RESOURCE.BILLING_CPT_CODES) {
    return 'type'
  } else if (resourceCode === ANALYTICS_RESOURCE.VIDEO_CALLING) {
    return 'employeeType'
  } else if (resourceCode === ANALYTICS_RESOURCE.PATIENT_APP_MESSAGING
    || resourceCode === ANALYTICS_RESOURCE.SMS_MESSAGING) {
    return 'senderType'
  } else if (resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS || resourceCode === ANALYTICS_RESOURCE.VIDEO_CALLING) {
    return 'employeeType'
  } else if (resourceCode === ANALYTICS_RESOURCE.ENCOUNTER_TYPES || resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED) {
    return 'apptType'
  }
  return 'displayText'
}

export const handleResponseDataAddMonth = (resourceData: any, resourceCode: string, appliedFilter: IAppliedFilter | undefined) => {
  const legendKey = getLegendKeyForResouce(resourceCode);
  const uniqueLegendName = getUniqueLegendNames(resourceData, legendKey);
  if (!appliedFilter) {
    return resourceData;
  }
  const newDummyData: any = getDummyDataArr(appliedFilter, resourceCode, legendKey, uniqueLegendName);
  if (!newDummyData) {
    return resourceData;
  }
  const fromDate = getFormattedDateStringInTimeZone(
    appliedFilter?.filterValue?.fromDate,
    'YYYY-MM-DD'
  )
  const toDate = getFormattedDateStringInTimeZone(
    appliedFilter?.filterValue?.toDate,
    'YYYY-MM-DD'
  )
  const newData = newDummyData.filter((currentObj: any) => {
    const currentDate = getFormattedDateStringInTimeZone(
      currentObj?.monthDt?.value,
      'YYYY-MM-DD'
    )
    return currentDate >= fromDate && currentDate <= toDate;
  })
  const monthArr: string[] = ["January","February","March","April","May","June","July",
  "August","September","October","November","December"];
  for(let i=0; i<newData.length; i++) {
    for(let j=0; j<resourceData.length; j++) {
      if((newData[i]?.[legendKey] == resourceData[j]?.[legendKey]) && (newData[i]?.month == resourceData[j]?.month)) {
        //newData[i].countNum = resourceData[j].countNum;
        //newData[i].monthDt.value = resourceData[j].monthDt.value;
        const newObject = {...newData[i], ...resourceData[j]}
        newData[i] = newObject;
      }

      if(resourceCode === ANALYTICS_RESOURCE.BILLING_CPT_CODES) {
        const d = new Date(resourceData[j]?.monthDt?.value);
        const name = monthArr[d.getMonth()];
        if((newData[i]?.[legendKey] == resourceData[j]?.[legendKey]) && (newData[i]?.month == name)) {
          //newData[i].countNum = resourceData[j].countNum;
          //newData[i].monthDt.value = resourceData[j].monthDt.value;
          const newObject = {...newData[i], ...resourceData[j]}
          newData[i] = newObject;
        }
      }
    }
  }

  return newData;
}

export const getDateRangeFilterFromAppliedFilter = (appliedFilter: IAppliedFilter[] | undefined)  => {
  const dateFilter = appliedFilter?.find((filter: IAppliedFilter) => {
    return filter.filterCode === EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE
  })
  return dateFilter
}
