import { HStack, Skeleton, VStack } from "native-base";

const FormLoaderSkeleton = () => {
    const dummyArray = new Array(7).fill(0);
    return (
      <HStack flexWrap="wrap" alignItems='center'>
        {dummyArray.map((item, index) => (
          <VStack key={index} mr={2} mb={4} w="80%">
            <Skeleton.Text lines={2} my={2} />
            <Skeleton borderRadius={8} w={'100%'} />
          </VStack>
        ))}
      </HStack>
    );
  };

  export default FormLoaderSkeleton;