import { ColumnsType } from 'antd/lib/table';
import {HStack, Text, VStack} from 'native-base';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {CHANNEL_TYPE} from '../../Conversations/ConversationConst';
import {GetAgentBadges} from '../Helper/GetAgentBadges';
import {IIntegrationsDataProps} from '../interfaces';
import {IntegrationsTableActions} from './IntegrationsTableActions';
import Feather from 'react-native-vector-icons/Feather';
import { SmsInboxIconSvg } from '../../../../../assets/Icons/SMSInboxIconSvg';
import EFaxActionSvg from '../../../../common/Svg/PersonActionSvgIcons/EFaxActionSvg';
import GmailSvg from '../../../../common/Svg/GmailSvg';
import OutlookSvg from '../../../../common/Svg/OutlookSvg';

export const getcolumns = (
  actionFn: (actionCode: string, recordData: IIntegrationsDataProps) => {},
  filterIds: string[]
) => {
  const getInboxtypeIcon = (inboxType: string) => {
    switch (inboxType) {
      case 'SMS':
        return <SmsInboxIconSvg />;
      case 'Gmail':
        return <GmailSvg />;
      case 'Outlook':
        return <OutlookSvg />;
      case 'E-Fax':
        return <EFaxActionSvg width={16} height={16} />;
      default:
        <></>;
        break;
    }
  };
  const columns = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="inboxName"
        />
      ),
      dataIndex: 'title',
      key: 'title',
      width: '30%',
      fixed: 'left',
      render: (title: string, rowData: IIntegrationsDataProps) => {
        return (
          <VStack>
            <HStack>
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {title}
              </Text>
            </HStack>
            <HStack>
              {rowData?.singleInboxData?.channelType === CHANNEL_TYPE.CHANNEL_TWILIO_SMS && rowData?.singleInboxData?.channelTwilioSms?.phoneNumber && (
                  <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                    ({rowData?.singleInboxData?.channelTwilioSms?.phoneNumber})
                  </Text>
                )}
            </HStack>
          </VStack>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="inboxType"
        />
      ),
      dataIndex: 'inbox_type',
      key: 'inbox_type',
      width: '10%',
      filterIcon: (filtered: boolean) => (
        <Feather
          name="filter"
          color={
            filterIds?.length
              ? Colors.Custom.PrimaryColor
              : Colors.Custom.IATextColor
          }
        />
      ),
      filters: [
        {
          text: 'SMS',
          value: 'SMS',
        },
        {
          text: 'Website',
          value: 'Website',
        },
        {
          text: 'Gmail',
          value: 'Gmail',
        },
        {
          text: 'Outlook',
          value: 'Outlook',
        },
        {
          text: 'Default',
          value: 'Default',
        },
      ],
      onFilter: (value: any, record: any) => {
        return record.inbox_type?.includes(value as string) || false;
      },
      filterSearch: true,
      filteredValue: filterIds,
      render: (inbox_type: string) => (
        <HStack alignItems={'center'}>
          {getInboxtypeIcon(inbox_type)}
          <Text ml={1} size={'smMedium'} color={Colors.Custom.Gray900}>
            {inbox_type}
          </Text>
        </HStack>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="collaborators"
        />
      ),
      dataIndex: 'agents',
      key: 'agents',
      width: '40%',
      render: (agents: string[]) => <GetAgentBadges assignedAgents={agents} />,
    },

    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      fixed: 'right',
      onCell: () => {
        return {
          onClick: (e: Event) => {
            e.stopPropagation();
          },
        };
      },
      render: (id: number, record: IIntegrationsDataProps) => (
        <IntegrationsTableActions
          id={id}
          onActionPerformed={(
            actionCode: string,
            recordData: IIntegrationsDataProps
          ) => {
            actionFn(actionCode, recordData);
          }}
          record={record}
        />
      ),
    },
  ];

  return columns;
};
