import {
  IconButton,
  View,
  Tooltip,
  Divider,
} from 'native-base';
import React from 'react';
import Feather from 'react-native-vector-icons/Feather';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {Colors} from '../../../../../styles';
import {IPackageActionProps} from './interfaces';

const PackagesActionsView = (props: IPackageActionProps) => {

  return (
    <View flex={1} flexDirection={'row'} alignItems={'center'}>
      <Tooltip label="Edit" placement="top">
        <IconButton
          icon={
            <Feather name="edit-2" size={17} color={Colors.Custom.Gray500} />
          }
          onPress={() => {
            props.onActionPerformed(
              COMMON_ACTION_CODES.EDIT,
              props.selectedRecord
            );
          }}
        />
      </Tooltip>

      <Divider ml={1} mr={1} orientation="vertical" height={'20px'}/>

      <Tooltip label="Copy link" placement="top">
        <IconButton
          icon={
            <Feather name="link" size={18} color={Colors.Custom.Gray500} />
          }
          onPress={() => {
            props.onActionPerformed?.(
              COMMON_ACTION_CODES.COPY,
              props?.selectedRecord
            );
          }}
        />
      </Tooltip>
    </View>
  );
};

export default PackagesActionsView;
