import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserUUID } from "../../../utils/commonUtils";
import { getDefaultPath } from "../../SideMenuBar/SideBarHelper";

const DefaultPageNavigation = () => {
  const navigateToScreen = useNavigate();
  const userUUID = getUserUUID();
  const navigationPath = getDefaultPath(userUUID);
  useEffect(() => {
    const pathList = (window.location + '').split('#')
    if (pathList && pathList.length > 1 && pathList[1].length && pathList[1] !== '/') {
      navigateToScreen(pathList[1]);
    } else{
      navigateToScreen('/'+ navigationPath);
    }
  }, []);
  return <></>
};

export default DefaultPageNavigation;
