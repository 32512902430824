import React from "react";

const AnalyticsGraphSvg = ()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#6F7A90"
        d="M6 25.5a.5.5 0 000 1v-1zm20 0H6v1h20v-1zm-5.5-8h3v-1h-3v1zm4 1V26h1v-7.5h-1zm-5 7.5v-7.5h-1V26h1zm4-8.5a1 1 0 011 1h1a2 2 0 00-2-2v1zm-3-1a2 2 0 00-2 2h1a1 1 0 011-1v-1zm-2-7.5v17h1V9h-1zm-5 17V9h-1v17h1zM16 6.5c.721 0 1.212.001 1.58.05.352.048.516.132.627.243l.707-.707c-.328-.328-.738-.464-1.202-.527C17.263 5.5 16.694 5.5 16 5.5v1zM19.5 9c0-.693.001-1.263-.06-1.713-.062-.463-.198-.873-.526-1.201l-.707.707c.111.111.195.275.242.628.05.367.051.858.051 1.579h1zM16 5.5c-.693 0-1.263-.001-1.713.06-.463.062-.873.198-1.201.526l.707.707c.111-.111.275-.195.628-.243.367-.049.858-.05 1.579-.05v-1zM13.5 9c0-.721.001-1.212.05-1.58.048-.352.132-.516.243-.627l-.707-.707c-.328.328-.464.738-.527 1.201-.06.45-.059 1.02-.059 1.713h1zm-5 3.5h3v-1h-3v1zm4 1V26h1V13.5h-1zM7.5 26V13.5h-1V26h1zm4-13.5a1 1 0 011 1h1a2 2 0 00-2-2v1zm-3-1a2 2 0 00-2 2h1a1 1 0 011-1v-1z"
      ></path>
    </svg>
  );
}

export default AnalyticsGraphSvg;
