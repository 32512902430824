import {useMutation} from '@apollo/client';
import {Drawer, Select} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {cloneDeep} from 'lodash';
import {
  Box,
  FormControl,
  Input,
  useMediaQuery,
  useToast,
  View,
  VStack,
  Skeleton
} from 'native-base';
import {useEffect, useState} from 'react';
import {BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {UpdateUserScheduleAccess} from '../../../services/UserScheduleAccess/UserScheduleAccessQueries';
import {Colors} from '../../../styles';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import { ModalActionUserMultiSelect } from '../../common/ModalActionCommonComponent/ModalActionUserMultiSelect';
import {ModalActionTitle} from '../../common/ModalActionTitle/ModalActionTitle';
import {styles} from '../../RightSideContainer/Contacts/TeamMembers/AddEditUser/AddEditUserStyles';
import {IScheduleAccessProps, IScheduleAccessUser} from './interfaces';
import { useIntl } from 'react-intl';

const AddEditUserScheduleAccess = (props: IScheduleAccessProps) => {
  const toast = useToast();
  const ALL_USERS_CODE = 'ALL_USERS'
  const intl = useIntl();
  const getDefaultUsers = () => {
    const defaultUser = {
      userId: ALL_USERS_CODE,
      userName: 'All users',
      email: '',
      scheduleUsers: [],
      userRoles: [],
    } as IScheduleAccessUser;
    const accountUsers = props.scheduleUsers || [];
    accountUsers.splice(0, 0, defaultUser);
    return accountUsers;
  };

  const [userFormData, setUserFormData]: any = useState({
    loading: false,
    userId: props.userId,
    userName: props.userName,
    email: props.email,
    userIds: cloneDeep(props.userIds || []).filter(userId => !!userId),
    userList: [],
  });

  const [addUserScheduleAccess] = useMutation(UpdateUserScheduleAccess);

  const handleSubmit = async () => {
    try {
      let userScheduleAccesses = prepareUserScheduleAccessData() || [];
      const updatedUserScheduleAccess =
        prepareUserScheduleAccessDataForUpdate() || [];
      let addUpdateUserResponse = null;

      userScheduleAccesses = userScheduleAccesses.concat(
        updatedUserScheduleAccess
      );

      if (userScheduleAccesses && userScheduleAccesses?.length) {
        addUpdateUserResponse = await addUserScheduleAccess({
          variables: {
            group: userScheduleAccesses,
          },
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
        });
      }
      setUserFormData((prev: any) => {
        return {
          ...prev,
          loading: true,
        };
      });
      showToast(
        toast,
        intl.formatMessage({id: 'userAccessUpdateSuccess'}),
        ToastType.info,
      );
      props.onClose(true);
    } catch (error) {
      setUserFormData((prev: any) => {
        return {
          ...prev,
          loading: false,
        };
      });
      props.onClose(false);
      showToast(toast, 'Error while updating user schedule access', ToastType.error);
    }
  };

  const prepareUserScheduleAccessDataForUpdate = () => {
    return (props.userScheduleAccessList || []).map((user) => {
      return {
        userId: userFormData.userId,
        scheduleAccessUserId: user.scheduleAccessUserId,
        isDeleted: (!user.scheduleAccessUserId || !isOldUserScheduleAccessUpdated(user.scheduleAccessUserId)),
        id: user.id,
      };
    }).filter((user) => {
      return !!user.id;
    });
  };

  const prepareUserScheduleAccessData = () => {
    return (userFormData.userIds || [])
      .filter((id: string) => {
        const selectedUser = getUserFromOriginalPropList(id);
        return !selectedUser?.id && id;
      })
      .map((id: string) => {
        return {
          userId: userFormData.userId,
          scheduleAccessUserId: id,
          isDeleted: false,
        };
      });
  };

  const getUserFromOriginalPropList = (accessId: string) => {
    return (props.userScheduleAccessList || []).find((user: any) => {
      return user.scheduleAccessUserId === accessId;
    });
  };

  const isOldUserScheduleAccessUpdated = (userId: string) => {
    return (userFormData.userIds || []).some((id: string) => {
      return id === userId;
    });
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '80vw' : '38vw';

  useEffect(() => {
    setUserFormData((prev: any) => {
      let accountUserList = prev.userList;
      if (!prev.userList?.length) {
        accountUserList = getDefaultUsers();
      }
      return {
        ...prev,
        userList: accountUserList
      }
    });
  }, [props.scheduleUsers]);

  return (
    <Drawer
      visible={props.showDrawer}
      style={{minWidth: 700}}
      width={drawerWidth}
      onClose={() => {
        props.onClose(false);
      }}
      title={
        <>
          <ModalActionTitle
            title={'editScheduleAccessUser'}
            titleColor={''}
            titleSize={24}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  props.onClose(false);
                },
              },
              {
                show: true,
                id: 2,
                isLoading: userFormData.loading,
                btnText: 'submit',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  setUserFormData((prev: any) => {
                    return {
                      ...prev,
                      loading: true,
                    };
                  });
                  handleSubmit();
                },
              },
            ]}
          />
        </>
      }
    >
      <Content className={'addEDitUserMultiSelect'}>
      {userFormData.loading && (
        <View>
          <Skeleton rounded="md" />
        </View>
      )}
      {!userFormData.loading && (
        <View mx={0}>
          <VStack>
            <FormControl style={styles.formElement}>
              <FormControl.Label style={styles.formLabel}>
                <DisplayText
                  size={'smMedium'}
                  extraStyles={{color: Colors.Custom.Gray700}}
                  textLocalId={'name'}
                />
              </FormControl.Label>
              <Input isDisabled={true} value={userFormData.userName} />
            </FormControl>
            <FormControl style={styles.formElement}>
              <FormControl.Label style={styles.formLabel}>
                <DisplayText
                  size={'smMedium'}
                  extraStyles={{color: Colors.Custom.Gray700}}
                  textLocalId={'users'}
                />
              </FormControl.Label>
              <ModalActionUserMultiSelect
                value={userFormData.userIds}
                onChange={(selectedData: any)=> {
                  setUserFormData((prev: any) => {
                    return {
                      ...prev,
                      userIds: selectedData
                    };
                  });
                }}
                data={userFormData.userList}
              />
            </FormControl>
          </VStack>
        </View>
      )}
      </Content>
    </Drawer>
  );
};

export default AddEditUserScheduleAccess;
