import React from "react";
import { Colors } from "../../../styles/Colors";
import { ICommonSvgProps } from "./interfaces";

const CopyIconSvg = (props: ICommonSvgProps)=> {
  const strokeColor = props.customStrokeColor ? props.customStrokeColor : Colors.Custom.mainPrimaryPurple;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ||  "20"}
      height={props?.height ||  "20"}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4.167 12.5h-.834a1.667 1.667 0 01-1.666-1.667v-7.5a1.667 1.667 0 011.666-1.666h7.5A1.667 1.667 0 0112.5 3.333v.834M9.167 7.5h7.5c.92 0 1.666.746 1.666 1.667v7.5c0 .92-.746 1.666-1.666 1.666h-7.5c-.92 0-1.667-.746-1.667-1.666v-7.5c0-.92.746-1.667 1.667-1.667z"
      ></path>
    </svg>
  );
}

export default CopyIconSvg;
