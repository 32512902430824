import {PERSON_ACTION_CODES} from '../../../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {CONTACT_ACTION_CODES} from '../../../../ContactsConst';

export const getActionTypeImgSrc = (code: string) => {
  switch (code) {
    case CONTACT_ACTION_CODES?.CONVERT_TO_CUSTOMER:
      return require('../../../../../../../assets/images/ProspectPatientActions/convertUser.png');
    case CONTACT_ACTION_CODES?.ADD_RELATION:
      return require('../../../../../../../assets/images/ProspectPatientActions/addRelative.png');
    case CONTACT_ACTION_CODES?.SET_TAG:
      return require('../../../../../../../assets/images/ProspectPatientActions/tag.png');
    case PERSON_ACTION_CODES?.EDIT_LEAD || PERSON_ACTION_CODES?.EDIT:
      return require('../../../../../../../assets/images/ProspectPatientActions/editUser.png');
    case PERSON_ACTION_CODES?.EDIT:
      return require('../../../../../../../assets/images/ProspectPatientActions/editUser.png');
    case PERSON_ACTION_CODES?.ADD_NOTE:
      return require('../../../../../../../assets/images/ProspectPatientActions/addNote.png');
    case PERSON_ACTION_CODES?.SCHEDULE_APPOINTMENT:
      return require('../../../../../../../assets/images/ProspectPatientActions/schedule.png');
    case PERSON_ACTION_CODES?.SEND_FORMS:
      return require('../../../../../../../assets/images/ProspectPatientActions/sendForm.png');
    case PERSON_ACTION_CODES?.DEACTIVATE:
      return require('../../../../../../../assets/images/ProspectPatientActions/inactiveUser.png');
    case PERSON_ACTION_CODES?.ACTIVATE:
      return require('../../../../../../../assets/images/ProspectPatientActions/activeUser.png');
    case PERSON_ACTION_CODES?.CREATE_TASK:
      return require('../../../../../../../assets/images/MessageActionImages/create_task.png');
    case PERSON_ACTION_CODES?.SEND_MEMBERSHIP_LINK:
      return require('../../../../../../../assets/images/ProspectPatientActions/sendForm.png');
    case CONTACT_ACTION_CODES?.SEND_PROVIDER_REQUEST_FORM:
      return require('../../../../../../../assets/images/ProspectPatientActions/sendForm.png');  
    default:
      return require('../../../../../../../assets/images/ProspectPatientActions/editUser.png');
  }
};
