import {Pressable, Tooltip, View} from 'native-base';
import React, {useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {Colors} from '../../../../../../styles';
import {DeletePEModal} from '../../DeletePEModal';
import {IPETableActionsProps} from '../../interfaces';
import {Divider} from 'antd';

const PETableActions = (props: IPETableActionsProps) => {
  const {onActionPerformed, record} = props;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDelete = () => {
    onActionPerformed(COMMON_ACTION_CODES.DELETE, record);
  };

  const handleEdit = () => {
    onActionPerformed(COMMON_ACTION_CODES.EDIT, record);
  };

  const handlePreviewArticle = () => {
    onActionPerformed(COMMON_ACTION_CODES.PREVIEW, record);
  };

  return (
    <View flex={1} flexDirection="row">
      <Tooltip label="Preview" placement="top">
        <Pressable
          onPress={() => {
            handlePreviewArticle();
          }}
          style={{
            marginHorizontal: 16,
          }}
        >
          <AntIcon name="eyeo" size={20} color={Colors.Custom.Gray600} />
        </Pressable>
      </Tooltip>

      <View
        style={{
          width: 1,
          height: 20,
          backgroundColor: Colors.Custom.Gray200,
        }}
      ></View>

      <Tooltip label="Update" placement="top">
        <Pressable
          onPress={() => {
            handleEdit();
          }}
          style={{
            marginHorizontal: 16,
          }}
        >
          <Feather name="edit-2" size={20} color={Colors.Custom.Gray600} />
        </Pressable>
      </Tooltip>
      <View
        style={{
          width: 1,
          height: 20,
          backgroundColor: Colors.Custom.Gray200,
        }}
      />

      <Tooltip label="Delete" placement="top">
        <Pressable
          style={{
            marginHorizontal: 16,
          }}
          onPress={() => setDeleteModalOpen(true)}
        >
          <Feather name="trash-2" size={20} color={Colors.Custom.Gray600} />
        </Pressable>
      </Tooltip>

      <DeletePEModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onConfirm={() => {
          setDeleteModalOpen(false);
          handleDelete();
        }}
        record={record}
      />
    </View>
  );
};

export default PETableActions;
