import {useQuery} from '@apollo/client';
import {Skeleton} from 'antd';
import {cloneDeep} from 'lodash';
import {
  Button,
  Divider,
  HStack,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {MLOV_CATEGORY} from '../../../../../constants';
import { USER_ROLE_CODES } from '../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {UserQueries} from '../../../../../services';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {
  getMlovListFromCategory,
  getMlovValueFromId,
} from '../../../../../utils/mlovUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import { ICareTeamRequiredRole } from '../DetailsAndMembers/Interfaces';
import JourneyDetailTagInput from '../DetailsAndMembers/JourneyDetailTagInput';
export interface ICcareTeamRoleSuggestions {
  roles: {
    [nodeId: string]: {
      name: string;
      roleId: string;
    }
  }
}
export interface ICareJourneyTeamState {
  careTeam: any[];
  loading: boolean;
  usersData: any[];
}

const CareJourneyTeam = (props: {
  careTeam: string[];
  careTeamUsers: {roleId: string; name: string; uuid: string}[];
  isAssignWorkflow: boolean;
  careTeamRoleSuggestions?: ICcareTeamRoleSuggestions;
  careTeamRequiredRoles?: ICareTeamRequiredRole;
  resetCareTeamRoleSuggestions?: () => void;
  onChange: (careTeam: any[]) => void;
}) => {
  const toast = useToast();
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const [careJourneyTeamState, setCareJourneyTeamState] =
    useState<ICareJourneyTeamState>({
      careTeam: props.careTeam,
      usersData: [],
      loading: props.isAssignWorkflow,
    });

  let userRoles = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.USER_ROLES
  );
  userRoles = userRoles?.filter((mlov) => mlov.code !== USER_ROLE_CODES.EMPLOYER);
  const options = userRoles.map((mlov) => {
    return {
      label: mlov.value,
      value: mlov.id,
    };
  });

  const isUserSelected = (userId: string, roleId: string) => {
    const matchedData = props.careTeamUsers.filter(
      (user) => user.uuid === userId && user.roleId === roleId
    );
    return matchedData.length > 0;
  };

  const getFilteredUsersByRoleId = (users: any[], roleId: string) => {
    return users
      .filter((user) => {
        if (user.userRoles && user.userRoles.length > 0) {
          const matchedData = user.userRoles.filter((userRole: any) => {
            if (userRole.userRole && userRole.userRole.userRole) {
              return userRole.userRole.userRole.id === roleId;
            }
            return false;
          });
          return matchedData.length > 0;
        }
        return false;
      })
      .map((user) => {
        return {
          name: user.name,
          uuid: user.uuid,
          roleId: roleId,
          isSelected: isUserSelected(user.uuid, roleId),
        };
      });
  };

  if (props.isAssignWorkflow) {
    useQuery(UserQueries.GET_USERS_BY_ROLE_IDS, {
      fetchPolicy: 'no-cache',
      variables: {
        roleIds: props.careTeam || [],
        accountId: getAccountUUID(),
      },
      onCompleted: (response: any) => {
        const userRoleMapping: any[] = [];
        if (response && response.users && response.users.length > 0) {
          const users = cloneDeep(response.users);
          careJourneyTeamState.careTeam.forEach((roleId) => {
            userRoleMapping.push({
              roleId: roleId,
              userList: getFilteredUsersByRoleId(users, roleId),
            });
          });
        }
        setCareJourneyTeamState((prev) => ({
          ...prev,
          usersData: userRoleMapping,
          loading: false,
        }));
      },
      onError: (error: any) => {

        showErrorToast();
        setCareJourneyTeamState((prev) => ({...prev, loading: false}));
      },
    });
  }

  const showErrorToast = () => {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  };

  useEffect(() => {
    if (!props.isAssignWorkflow && !careJourneyTeamState.loading) {
      props.onChange(careJourneyTeamState.careTeam);
    }
  }, [careJourneyTeamState.careTeam]);

  useEffect(() => {
    if (props.isAssignWorkflow && !careJourneyTeamState.loading) {
      const selectedUsers: any[] = [];
      careJourneyTeamState.usersData.forEach((userRoleMap) => {
        userRoleMap.userList.forEach((user: any) => {
          if (user.isSelected) {
            selectedUsers.push(user);
          }
        });
      });
      props.onChange(selectedUsers);
    }
  }, [careJourneyTeamState.usersData]);

  return (
    <View style={{paddingHorizontal: 2}}>
      <PageBodyContainer>
        <VStack space={4} padding={4}>
          {!props.isAssignWorkflow && (
            <JourneyDetailTagInput
              isRequired
              labelId="careTeam"
              descriptionId="journeyCareTeamDescription"
              value={careJourneyTeamState.careTeam}
              careTeamRoleSuggestions={props.careTeamRoleSuggestions}
              careTeamRequiredRoles={props.careTeamRequiredRoles}
              resetCareTeamRoleSuggestions={props.resetCareTeamRoleSuggestions}
              options={options}
              onChange={(value) => {
                setCareJourneyTeamState((prev) => ({...prev, careTeam: value}));
                props.onChange(value);
              }}
            />
          )}
          {careJourneyTeamState.loading && (
            <View width="full" padding={4}>
              <Skeleton active />
            </View>
          )}
          {props.isAssignWorkflow && !careJourneyTeamState.loading && (
            <VStack space={4}>
              <DisplayText
                textLocalId="selectCareTeam"
                textType="Heading"
                size="lgMedium"
              />
              <Divider marginBottom={2} />
              <VStack space={4} justifyContent="center">
                {careJourneyTeamState.usersData.map((roleUser: any, index) => {
                  return (
                    <HStack alignItems="center" space={2} key={index}>
                      <Text flex={0.3}>
                        {getMlovValueFromId(userRoles, roleUser.roleId)}
                      </Text>
                      <View flex={0.7} flexDirection="row" flexWrap="wrap">
                        {roleUser.userList.length === 0 && (
                          <Text>No user is configured for this role.</Text>
                        )}
                        {roleUser.userList.map(
                          (user: any, roleIndex: number) => {
                            return (
                              <View
                                key={'User_' + roleIndex}
                                marginRight={4}
                                marginBottom={2}
                              >
                                <Button
                                  onPress={() => {
                                    setCareJourneyTeamState((prevState) => {
                                      const newStateValue = [
                                        ...prevState.usersData,
                                      ];
                                      newStateValue[index].userList.forEach(
                                        (user: any) => {
                                          user.isSelected = false;
                                        }
                                      );
                                      newStateValue[index].userList[
                                        roleIndex
                                      ].isSelected = true;
                                      return {
                                        ...prevState,
                                        usersData: newStateValue,
                                      };
                                    });
                                  }}
                                  variant={
                                    user.isSelected ? 'solid' : 'outline'
                                  }
                                >
                                  {user.name}
                                </Button>
                              </View>
                            );
                          }
                        )}
                      </View>
                    </HStack>
                  );
                })}
              </VStack>
            </VStack>
          )}
        </VStack>
      </PageBodyContainer>
    </View>
  );
};

export default CareJourneyTeam;
