import {useLazyQuery} from '@apollo/client';
import {Select, Spin, Checkbox} from 'antd';
import {debounce} from 'lodash';
import {
  Button,
  HStack,
  Icon,
  Switch,
  Text,
  Select as NativeSelect,
  VStack,
  useToast,
  Box,
  View,
  Input,
  Divider,
  Pressable,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {IUser} from '../../../../Interfaces';
import {UserQueries} from '../../../../services';
import {Colors} from '../../../../styles';
import {getAccountUUID, getUserRolesNameList, getUserUUID, isEmployerRole, isLoginUserBusinessOwner} from '../../../../utils/commonUtils';
import {ModalActionAntSelect} from '../../ModalActionCommonComponent/ModalActionAntSelect';
import {ModalActionSelect} from '../../ModalActionCommonComponent/ModalActionSelect';
import './UserAutoCompleteStyles.css';
import { DisplayCardAvatar } from '../../DisplayCard/DisplayCardAvatar';
import { GROUP_MEMBER_TYPE } from '../../../../constants';
import { USER_SEARCH_TYPE } from '../../../RightSideContainer/constants';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { GLOBAL_ROLE_CODES } from '../../../../constants/MlovConst';

export interface IUserSearch {
  value: string;
  label: string;
  key: string;
  type?: string;
  data?: any;
}

export const userAutoCompleteTypeCodes = {
  DEFAULT: 'DEFAULT',
  MENU: 'MENU',
  INPUT_MENU: 'INPUT_MENU',
  INPUT_CHECKBOX_OPTIONS:"INPUT_CHECKBOX_OPTIONS",
  INPUT_OPTIONS:"INPUT_OPTIONS"
};

export const UserAutoComplete = (props: {
  label?: string;
  usersData?: IUser[];
  selectedValue?: IUserSearch;
  selectedUserName?: string;
  selectedUserUUID?: string;
  selectedUserUUIDList?: string[];
  isShowError?: boolean;
  userAutoCompleteType?: string;
  onChange: (participants: IUserSearch | any) => void;
  onValueChange?: (value: boolean) => void;
  labelText?: string;
  minWidth?: number | string;
  backgroundColor?: any;
  isDisabled?: boolean;
  isRequired?: boolean;
  allowClear?: boolean;
  minHeight?:number;
  excludeOtherEmployers?: boolean;
  roleCodes?: string[];
  isInvalid?: boolean;
  enableSorting?: boolean;
  hideSelectAll?: boolean;
  errors?: boolean;
  errorText?: string;
  userSearchType?: string;
  locationIds?: string[];
}) => {
  const { roleCodes, isInvalid, errors, errorText } = props;
  const intl = useIntl();
  const accountId = getAccountUUID();
  const isBusinessOwner = isLoginUserBusinessOwner();
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const [value, setValue] = useState<IUserSearch[]>(
    props.selectedValue ? [props.selectedValue] : []
  );
  const [userLoading, setUserLoading] = useState(false);
  const [userData, setUserData] = useState<IUserSearch[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [memberSelected, setMemberSelected] = useState(
    props.selectedValue ? props.selectedValue.value : ''
  );

  const loggedInUserId = getUserUUID();
  const isEmployer = isEmployerRole();

  const userAutoCompleteType =
    props.userAutoCompleteType || userAutoCompleteTypeCodes.DEFAULT;

  const formatUserRole = (currentUserRoleData: IUser) => {
    const currentUserRoles: any = [];
    if (currentUserRoleData) {
      currentUserRoleData?.userRoles?.forEach((data: any) => {
        currentUserRoles.push({
          code: data?.userRole?.customRoleCode || data?.userRole?.userRole?.code,
          value: data?.userRole?.roleName || data?.userRole?.userRole?.value,
        });
      });
    }
    return currentUserRoles;
  };

  const filterOtherEmployers = (users: IUser[]) => {
    users = users.filter((user: IUser) => {
      if (user.uuid === loggedInUserId) {
        return true;
      }
      const currentUserRoles = formatUserRole(user);
      const roles = currentUserRoles.map((userRole: any) => {
        return userRole.code;
      });
      const isEmployer = roles.includes('EMPLOYER') || false;
      return !isEmployer;
    });

    return users;
  }

  const userDataPostProcessing = (users: IUser[]) => {
    let transformedUsers: IUserSearch[] = [];
    if (users && users.length > 0) {
      if (props.excludeOtherEmployers && isEmployer) {
        users = filterOtherEmployers(users);
      }
      transformedUsers = users.map((user: IUser): IUserSearch => {
        return {
          label: user?.name,
          value: user?.uuid,
          key: user?.uuid,
          data: user,
        };
      });
    }
    setUserData(transformedUsers);
    setOriginalData(transformedUsers);

    if ((props.selectedUserName || props.selectedUserUUID)) {
      const user = transformedUsers.find(
        (item) =>
          item.label === props.selectedUserName ||
          item.key === props.selectedUserUUID
      );
      if (user) {
        setValue([user]);

        setMemberSelected(user.value);
      }
    }
    return transformedUsers;
  };

  const [getUsers] = useLazyQuery(roleCodes && roleCodes.length ? UserQueries.GET_USERS_WITH_ROLE : props?.userSearchType && props?.userSearchType === USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
    ? UserQueries.GET_USERS_FOR_CALENDAR_V2
    : UserQueries.GET_USERS_FOR_CALENDAR, {
    onCompleted: (data: any) => {
      userDataPostProcessing(data?.users);
      setUserLoading(false);
    },
    onError: (error) => {
      setUserData([]);
      setUserLoading(false);
    },
  });

  useEffect(() => {
    const { locationIds, userSearchType } = props;
    if (props.usersData && props.usersData.length) {
      userDataPostProcessing(props.usersData);
    } else {
      setUserLoading(true);
      const userboolExp = userSearchType === USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
        ? {
          _or: [
            {
              userPracticeLocations: {
                isDeleted: {
                  _eq: false
                },
                accountLocation: {
                  uuid: {
                    _in: locationIds,
                  }
                },
              },
            },
            ...(isMsoEnabled
              ? [{
                userRoles: {
                  userRole: {
                    userRole: {
                      code: { _in: [GLOBAL_ROLE_CODES.GLOBAL_ADMIN] }
                    }
                  }
                }
              }]
              : [])
          ]
        }
        : null;
        
      getUsers({
        variables: {
          accountId: accountId,
          ...(roleCodes && roleCodes.length && { roleCodes: roleCodes }),
          ...(userboolExp && { userboolExp }),
          offset: 0,
        },
      });
    }
  }, [props.usersData]);

  useEffect(() => {
    if (props.selectedValue) {
      setValue([props.selectedValue]);
    }
  }, [props.selectedValue])

  const onSearch = (searchString: string) => {
    if (searchString.length > 0) {
      const filteredData = originalData.filter((item) => {
        return item.label.toLowerCase().includes(searchString.toLowerCase());
      });
      setUserData([...filteredData]);
    } else {
      setUserData([...originalData]);
    }
  };

  const getUserDataFromId = (id: any): IUserSearch => {
    const matchedData = userData.filter((item) => item.key === id);
    return {
      label: matchedData[0].label,
      key: matchedData[0].key,
      value: matchedData[0].value,
      data: matchedData[0].data,
    };
  };

  // function MenuComponent() {
  //   return (
  //     <Menu
  //       onClick={(info) => {
  //         const user = userData.find(
  //           (data) => data.key === info.key
  //         ) as IUserSearch;
  //         setValue([user]);
  //         props.onChange(user);
  //       }}
  //       style={{
  //         overflow: 'scroll',
  //         maxHeight: 250,
  //       }}
  //       selectedKeys={value?.map((item) => item.key)}
  //       items={userData?.map((item) => {
  //         return {label: item.label, key: item.key};
  //       })}
  //     />
  //   );
  // }

  function MenuComponent() {
    return (
      <VStack space={1}>
        {props.labelText && (
          <Text fontSize={14} color={'gray.700'} fontWeight={500} ml={0}>
            {props.labelText ? props.labelText : 'Member'}
          </Text>
        )}

        {/* <NativeSelect
          fontSize={15}
          fontWeight={400}
          selectedValue={memberSelected}
          backgroundColor={'white'}
          height={44}
          width={
            props.minWidth ? props.minWidth : props.onValueChange ? 200 : 335
          }
          borderRadius={8}
          borderColor={Colors.Custom.Gray300}
          onValueChange={(value) => {
            props.onChange(getUserDataFromId(value));
            setMemberSelected(value);
          }}
        >
          {userData.map((item, index) => {
            return (
              <NativeSelect.Item
                label={item.label}
                value={item.value}
                key={index}
              />
            );
          })}
        </NativeSelect> */}
        <ModalActionSelect
          label={props.label || ''}
          isRequired={false}
          isInvalid={isInvalid}
          selectedValue={memberSelected}
          onValueChange={(value) => {
            props.onChange(getUserDataFromId(value));
            setMemberSelected(value);
          }}
          data={userData}
          selectItemProps={{
            key: 'value',
            label: 'label',
            value: 'value',
          }}
          customStyle={{
            flex: 1,
            fontSize: 15,
            fontWeight: 400,
            width: props.minWidth || 335,
            minHeight: props.minHeight || 'auto',
            borderColor: Colors.Custom.Gray300,
            backgroundColor: props?.backgroundColor || 'transparent'
          }}
          errors={errors}
          errorText={errorText}
        />
      </VStack>
    );
  }

  const checkBoxContent = (item: IUserSearch) => {
    return <>
      <HStack
        width={'100%'}
        alignItems="flex-start"
        paddingY={2}
        paddingX={4}
      >
        <View>
          <DisplayCardAvatar
            avatarStyle={{
              avatarSize: '12',
              width: 48,
              height: 48,
            }}
            userData={{
              userType: GROUP_MEMBER_TYPE.USER,
              userId: item.value,
              name:  item?.label,
              userName:  item?.label,
              imgSrc:  '',
            }}
            isLetterAvatarShow
          />
        </View>
        <VStack
          alignSelf="stretch"
          flex={1}
          marginLeft={2}
          justifyContent={'flex-start'}
        >
          <Text fontWeight={'bold'} fontSize={16}>
            {item?.label}
          </Text>
          {/* <Text color={Colors?.Custom?.Gray500} numberOfLines={2} textOverflow={'ellipsis'}>{item?.data?.userRoles?.map((role:any) =>{
            return role?.userRole?.roleName || role?.userRole?.userRole?.value
          } )?.join(", ")}</Text> */}
           <Text
            color={Colors?.Custom?.Gray500}
            numberOfLines={2}
            textOverflow={'ellipsis'}
          >
            {getUserRolesNameList(item?.data?.userRoles)?.join(', ')}
          </Text>
        </VStack>
      </HStack>
    </>
  }

  if(
    userAutoCompleteType === userAutoCompleteTypeCodes.INPUT_OPTIONS
  ){
    const sortedUserData = userData;
    // const selectedUserUUIDList = props?.selectedUserUUIDList;
    // console.log(sortedUserData, selectedUserUUIDList);
    return <View style={{
      width: 350
    }}>
      {sortedUserData?.length ?
      sortedUserData?.map((item: IUserSearch, index: number) => {
        return (<>
          <Pressable
            onPress={(event) => {
              props.onChange([item.value]);
              setUserData([...originalData]);
            }}
          >
            {checkBoxContent(item)}
          </Pressable>
        </>)
      })
      : <>No User Found</>}
    </View>
  }

  if(
    userAutoCompleteType === userAutoCompleteTypeCodes.INPUT_CHECKBOX_OPTIONS
  ){
    const rawEmptyData = props?.selectedUserUUIDList ?[...props.selectedUserUUIDList] : [];
    const rawUserData = userData?.map((item)=> item.value);
    return (
      <View style={{
        width: 350
      }}>
        {!props?.hideSelectAll && userData?.length ? <><Checkbox
                  value={"select all"}
                  checked={props?.selectedUserUUIDList?.length == userData?.length  ? true : false}
                  onChange={(event) => {
                    if(event.target.checked){
                      props.onChange(rawUserData);
                    }else{ 
                      props.onChange([] );
                    }
                    setUserData([...originalData]);
                  }}
                >
                  <HStack
                width={'100%'}
                alignItems="flex-start"
                paddingY={2}
                paddingX={4}
              >
                <VStack
                  alignSelf="stretch"
                  flex={1}
                  marginLeft={2}
                  justifyContent={'flex-start'}
                >
                  <Text fontSize={16}>
                    {"Select All"}
                  </Text>
                </VStack>
              </HStack>
                </Checkbox><Divider marginX={-2} /></> : null}
        <View overflowY={'scroll'} style={{
          maxHeight:400,
        }}>
          {userData?.length ?
            userData?.map((item: IUserSearch, index: number) => {
              return (<>
                <Checkbox
                  key={item.key + rawEmptyData?.length}
                  value={item.value}
                  checked={props?.selectedUserUUIDList?.includes(item.value) ? true : false}
                  style={{ marginLeft: 0 }}
                  onChange={(event) => {
                    if(event.target.checked){
                      rawEmptyData?.push(item.value);
                    }else{
                      const index = rawEmptyData?.findIndex(subitem => subitem == item.value);
                      if (index !== -1 && index!=undefined) {rawEmptyData?.splice(index, 1)};
                    }
                    if (item && item.value) {
                      props.onChange(rawEmptyData|| []);
                    }
                    setUserData([...originalData]);
                  }}
                >
                  {checkBoxContent(item)}
                </Checkbox>{index !== userData.length - 1 && <Divider marginX={-2} />}</>
              )
            }) :
            <Text style={{
              marginTop: 8
            }} size={'smMedium'}
              color={Colors.Custom.Gray700}>
              {intl.formatMessage({
                id: 'noDataFound',
              })}
            </Text>
          }
        </View>
      </View>
    )
  }

  return (
    <HStack space={2}>
      {userAutoCompleteType === userAutoCompleteTypeCodes.DEFAULT && (
        <ModalActionAntSelect
          enableSorting={props?.enableSorting}
          isInvalid={isInvalid}
          disabled={props.isDisabled}
          label={props.labelText || props.label}
          style={{width:'100%'}}
          allowClear={props.allowClear !== undefined ? props.allowClear : true}
          onClear={() => {
            setValue([]);
            setUserData([]);
            props.onChange(undefined);
          }}
          showSearch={true}
          leftMargin={'0'}
          onSearch={debounce(onSearch, 500)}
          labelInValue={true}
          filterOption={false}
          value={value}
          loading={userLoading}
          // notFoundContent={userLoading && <Spin size="small" />}
          notFoundContent={intl.formatMessage({
            id: 'NoData',
          })}
          isRequired={props?.isRequired || false}
          placeholder={intl.formatMessage({id: 'NoData'})}
          onChange={(value: any[], data: any) => {
            setValue(data);
            if (data && data.value) {
              props.onChange(getUserDataFromId(data.key));
            }
            setUserData([...originalData]);
          }}
          data={userData}
          optionProps={{key: 'key', value: 'value', label: 'label'}}
          // extraStyle={{flex: 1}}
          customStyle={{
            flex: 1,
            fontSize: 15,
            fontWeight: 400,
            width: props.minWidth || '100%',
            borderColor: Colors.Custom.Gray300,
            backgroundColor: props?.backgroundColor || 'transparent'
          }}
          errors={errors}
          errorText={errorText}
        />
      )}

      {userAutoCompleteType === userAutoCompleteTypeCodes.MENU &&
        !userLoading && <MenuComponent />}

      {props.onValueChange && (
        <HStack top={2} space={2}>
          <Text style={{fontWeight: 'bold', fontSize: 14}}>List</Text>
          <Switch
            onValueChange={(value: boolean) => {
              if (props.onValueChange && typeof props.onValueChange === 'function') {
                props.onValueChange(value)
              }
            }}
          />
          <Text style={{fontWeight: 'bold', fontSize: 14}}>Board</Text>
        </HStack>
      )}
    </HStack>
  );
};

export default UserAutoComplete;
