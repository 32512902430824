import React, {useState} from 'react';
import {Input, Modal} from 'antd';
import {Text, VStack} from 'native-base';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import moment from 'moment';
import opportunity from '../../services/opportunity';
import './style.css';

export default function CreateCampaignModal({
  visible,
  handleCancel,
  selectedItem,
}: {
  visible: boolean;
  handleCancel: any;
  selectedItem: any;
}) {
  const tenantId = getAccountUUID();
  const [input, setInput] = useState('');

  const [error, setError] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    const {contactIds} = selectedItem;
    const startDate = moment(new Date()).format().toString();

    const formatFields = {
      target: tenantId,
      name: input,
      startDate,
      members: contactIds,
    };

    try {
      await opportunity.webhookCreateOpportunity(formatFields);
      setConfirmLoading(false);
      setError(null);
      handleCancel();
    } catch (e: any) {
      setError(e.Message);
      setConfirmLoading(false);
    }
  };

  return (
    <Modal
      title="Create Opportunity"
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      okText="Create"
      confirmLoading={confirmLoading}
      className={'modal_container_opp'}
    >
      <VStack space={1}>
        <Text fontSize="xl">Create Opportunity</Text>
        <Text fontSize="md">
          Create Opportunity cohort for {selectedItem.members} members
        </Text>
        <Text>{error && 'Error to create a new opportunity'}</Text>
        <div style={{marginTop: '40px'}}>
          <label
            style={{
              fontWeight: 500,
              fontSize: '14px',
            }}
          >
            Opportunity Name
          </label>
          <Input
            style={{
              borderRadius: '8px',
              padding: '10px 16px 10px 16px',
            }}
            placeholder="My New Opportunity"
            size="large"
            onChange={(e) => setInput(e.target.value)}
          />
        </div>
      </VStack>
    </Modal>
  );
}
