import React from 'react';
import { isWeb } from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';
import { Colors } from '../../../styles/Colors';

const CreateNewSmsSvg= () => {
  return (
    <>
    {isWeb() ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      fill="none"
      viewBox="0 0 20 19"
    >
      <path
        stroke={Colors.FoldPixel.GRAY300}
        strokeLinecap="round"
        strokeLinejoin="round"
    
        d="M10 11V8m0 0V5m0 3H7m3 0h3m-7.876 7.701L3.6 16.921c-.833.665-1.249.998-1.599.998a1 1 0 01-.783-.376C1 17.27 1 16.737 1 15.671V4.201c0-1.12 0-1.681.218-2.11.192-.376.497-.681.874-.873C2.52 1 3.08 1 4.2 1h11.6c1.12 0 1.68 0 2.108.218a2 2 0 01.874.874c.218.427.218.987.218 2.105v7.607c0 1.117 0 1.676-.218 2.104a2 2 0 01-.874.874c-.427.218-.986.218-2.104.218H7.123c-.416 0-.625 0-.824.04a2.001 2.001 0 00-.507.179c-.18.092-.342.221-.665.48l-.003.002z"
      ></path>
    </svg>
    ):(
      <Image
      size={'5'}
      source={require('../../../assets/images/CreateNewSms.png')}
      alt="aa"
    />
    )}
    </>
  );
};

export default CreateNewSmsSvg;
