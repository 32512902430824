import React, { useContext, useEffect, useState } from 'react';
import {
  Divider,
  Pressable,
  View,
  Text,
  HStack,
  VStack,
  FlatList,
  Modal,
  Image,
  Icon,
} from 'native-base';
import {Colors} from '../../../styles/Colors';
import _ from 'lodash';
import {DisplayCardAvatar} from '../DisplayCard/DisplayCardAvatar';
import {
  DATE_FORMATS,
  GROUP_MEMBER_TYPE,
  PERSON_TYPES,
} from '../../../constants';
import {getRoles, isActiveContact, isEmployerRole} from '../../../utils/commonUtils';
import AntDesign from 'react-native-vector-icons/AntDesign';
import CallHoldActive from '../Svg/TelephonySvg/CallHoldActive';
import CallHold from '../Svg/TelephonySvg/CallHold';
import ConferenceUserRemove from '../Svg/TelephonySvg/ConferenceUserRemove';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {getAgeValue, getDateStrFromMomentObj} from '../../../utils/DateUtils';
import moment from 'moment';
import {ConferenceEventReasonCode} from './interface';
import {isWeb} from '../../../utils/platformCheckUtils';
import StatusErrorSvg from '../Svg/StatusErrorSvg';
import { getDeceasedDate } from '../../../services/CommonService/AidBoxService';
import { getCurrentPatientDeceasedData} from '../../../services/CommonService/AidBoxService';
import { getEhrConfig, getEHRUrl } from '../../../utils/capabilityUtils';
import { testID } from '../../../testUtils';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { isPatientDetailViewAllowed } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getViewInEHRLink, redirectToNewTab } from '../../SideCar/SidecarUtils';

export interface IAddedMemberProps {
  title: string;
  contact: IContact;
  participants: any[];
  holdParticipant?: (participant: any) => void;
  removeParticipant?: (participant: any) => void;
  onCloseModal?: () => void;
  showCallActions?: boolean;
  locationId?: string
}

const AddedMembers = (props: IAddedMemberProps) => {
  const {
    title,
    contact,
    participants,
    onCloseModal,
    holdParticipant,
    removeParticipant,
    showCallActions,
    locationId
  } = props;

  const[deceasedDate,setDeceasedDate] = useState('')
  const getPatientDeceasedData = async (contactData: IContact | undefined) => {
    const patientDeceasedDate = await getCurrentPatientDeceasedData(contactData || {});
    setDeceasedDate(patientDeceasedDate)
  }
  const isEmployer = isEmployerRole();
  const ehrConfig = getEhrConfig(locationId, '');
  const ehrURL = getEHRUrl(locationId, '');
  useEffect(() => {
    ehrConfig.isAthena &&
    getPatientDeceasedData(contact)
  }, [contact?.patient?.patientId]);
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData?.sidecarContext?.isSidecar;
  const patientDetailViewAllowed = isPatientDetailViewAllowed();

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const gif = require('../../../assets/gifs/callRinging.gif');

  const getCallerDescription = (contact: IContact) => {
    const contactType =
      contact?.contactType?.contactType?.code == 'CUSTOMER'
        ? 'Patient'
        : contact?.contactType?.contactType?.code == 'LEAD'
        ? 'Prospect'
        : contact?.contactType?.contactType?.value;
    const dateOfBirth = contact?.person?.birthDate
      ? getDateStrFromMomentObj(
          moment(contact?.person?.birthDate),
          DATE_FORMATS.CONVERSATION_DATE_PICKER
        )
      : '';
    const ageInYears = ''+ getAgeValue(contact?.person?.birthDate || '', deceasedDate)
    const detailArray = [
      contactType ?? '',
      // getGenderTextByGenderCode(contact) ?? '',
      dateOfBirth ? `${ageInYears} (${dateOfBirth})` : '',
    ].filter((value) => value.length > 0);
    return detailArray.length ? detailArray?.join(' • ') : 'Patient';
  };

  const getCallActions = (participant: any): JSX.Element => {
    if (participant.eventReason === ConferenceEventReasonCode.ringing) {
      return (
        <Image
          resizeMode="cover"
          width={'30px'}
          height={'30px'}
          source={gif}
          alt="image"
          marginLeft={10}
        />
      );
    } else if (participant.eventReason === ConferenceEventReasonCode.accepted) {
      return (
        <>
          <Pressable
            onPress={() => {
              holdParticipant?.(participant);
            }}
          >
            {participant.hold ? (
              <CallHoldActive width={32} height={32} />
            ) : (
              <CallHold isEnabled={true} width={32} height={32} />
            )}
          </Pressable>
          <Pressable
            onPress={() => {
              removeParticipant?.(participant);
            }}
          >
            <ConferenceUserRemove />
          </Pressable>
        </>
      );
    } else {
      return (
        <>
          <StatusErrorSvg width={32} height={32} />
          <Pressable
            onPress={() => {
              removeParticipant?.(participant);
            }}
          >
            <ConferenceUserRemove />
          </Pressable>
        </>
      );
    }
  };

  const renderParticipantItem = (participant: any) => {
    const {extensionName} = participant
    const contactType = participant?.contactType?.contactType?.code;
    return (
      <View key={participant.uuid + new Date()}>
        <HStack p={2}>
          {!!participant?.person ? (
            <HStack flex={1} space={2}>
              <View flex={0.15}>
                <DisplayCardAvatar
                  avatarStyle={{
                    avatarSize: '12',
                  }}
                  isLetterAvatarShow={true}
                  userData={{
                    userId: participant?.id || '',
                    userType: GROUP_MEMBER_TYPE.CONTACT,
                    genderCode: participant?.person?.gender?.code as any,
                    contactType: PERSON_TYPES.CUSTOMER,
                    userName: participant?.name ?? '',
                  }}
                />
              </View>
              <HStack flex={0.85} alignItems={'center'}>
                <VStack flex={1}>
                  <Text size={'mdNormal'} fontWeight={600}>
                    {participant?.name ?? ''}
                  </Text>
                  <Text
                    size={'smNormal'}
                    fontWeight={400}
                    color={Colors.Custom.Gray500}
                  >
                    {getCallerDescription(participant)}
                  </Text>
                </VStack>
                {isWeb() &&
                !isEmployer &&
                (patientDetailViewAllowed ||
                  contactType !== PERSON_TYPES.CUSTOMER) ? (
                  <Pressable
                    style={{
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                    onPress={() => {
                      const currentStatus = isActiveContact(participant);
                      if (!currentStatus) {
                        return;
                      }
                      if (contactType === PERSON_TYPES.CUSTOMER) {
                        if (isSideCarContext) {
                          const ehrPatientId =
                            participant?.patient?.patientId || '';
                          const openInEhrUrl = getViewInEHRLink(
                            ehrPatientId,
                            ehrURL
                          );
                          if (openInEhrUrl) {
                            window.open(
                              openInEhrUrl,
                              '_blank',
                              'noopener,noreferrer'
                            );
                          } else {
                            redirectToNewTab(
                              `/members/patient/${participant.id}`
                            );
                          }
                        } else {
                          window.open(
                            `/#/members/patient/${participant.id}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }
                      } else {
                        if (isSideCarContext) {
                          redirectToNewTab(
                            `/members/prospect/${participant.id}`
                          );
                        } else {
                          window.open(
                            `/#/members/prospect/${participant.id}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }
                      }
                    }}
                    {...testID('ExternalLink')}
                  >
                    <HStack space={2}>
                      <Icon
                        as={FeatherIcon}
                        name="external-link"
                        size={5}
                        color={'#825AC7'}
                      />
                    </HStack>
                  </Pressable>
                ) : null}
              </HStack>
            </HStack>
          ) : (
            <>
              <HStack flex={0.78} space={2}>
                <View flex={0.2}>
                  <DisplayCardAvatar
                    avatarStyle={{
                      avatarSize: '12',
                    }}
                    isLetterAvatarShow={true}
                    userData={{
                      userId: participant.id,
                      userType: GROUP_MEMBER_TYPE.USER,
                      userName: participant?.name ?? extensionName ?? participant?.label ?? '',
                    }}
                  />
                </View>
                <VStack flex={0.8}>
                  <Text size={'mdNormal'} fontWeight={600}>
                    {participant?.name ?? extensionName ?? participant?.label ?? ''}
                  </Text>
                  <Text
                    size={'smNormal'}
                    fontWeight={400}
                    color={Colors.Custom.Gray500}
                  >
                    {getRoles(participant)}
                  </Text>
                </VStack>
              </HStack>
              {showCallActions && (
                <View flex={0.22} justifyContent={'center'}>
                  <HStack justifyContent={'space-between'} space={2}>
                    {getCallActions(participant)}
                  </HStack>
                </View>
              )}
            </>
          )}
        </HStack>
        <Divider></Divider>
      </View>
    );
  };

  const getListData = () => {
    return isWeb()
      ? [...participants]
      : Object.keys(contact).length > 0
      ? [contact, ...participants]
      : [...participants];
  };

  const getListView = () => {
    return (
      <View flex={1} width={'100%'} overflow={'hidden'}>
        <Divider marginLeft={-4} width={'120%'} marginBottom={1} />
        <FlatList
          data={getListData()}
          renderItem={({item, index}) => {
            return renderParticipantItem(item);
          }}
          keyExtractor={(item: any, index: number) => {
            return 'UserList_' + `${index}_` + item.id;
          }}
        />
      </View>
    );
  };

  return (
    <>
      {isWeb() ? (
        getListView()
      ) : (
        <Modal
          onClose={() => {
            onCloseModal?.();
          }}
          animationPreset="slide"
          isOpen={true}
          overlayVisible={true}
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <View
            flex={1}
            backgroundColor={Colors.Custom.MonochromeWhite}
            width={'100%'}
            style={{paddingHorizontal: 12}}
            pt={4}
            borderTopRadius={16}
            marginTop={64}
          >
            <HStack height={41} justifyContent={'space-between'}>
              <Text size={'lgNormal'} fontWeight={600}>
                {title}
              </Text>
              <Pressable
                onPress={() => {
                  onCloseModal?.();
                }}
              >
                <AntDesign
                  size={24}
                  name={'close'}
                  color={Colors.Custom.Gray500}
                />
              </Pressable>
            </HStack>
            {getListView()}
          </View>
        </Modal>
      )}
    </>
  );
};

export default AddedMembers;
