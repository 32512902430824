import React, {useState} from 'react';
import {useCareProgramAdditionalData} from '../../../CareManagementView/CareManagementViewHooks/useCareProgramAdditionalData';
import {usePersonOmniViewContext} from '../../../../PersonOmniView/PersonOmniView.context';
import {ADDITIONAL_DATA_CODE} from '../../../CareManagementBilling/CareManagementConstants';
import {ICondition} from '../../../Forms/FHFormio/CustomComponents/Conditions/interfaces';
import {getRecordListFormatted} from '../../../Forms/FHFormio/CustomComponents/Conditions/AddOrUpdateConditions/AddOrUpdateConditionsHelper';
import {Text} from 'native-base';
import {Pressable, StyleSheet} from 'react-native';
import {getDiffInMonths, getMomentObj} from '../../../../../utils/DateUtils';
import Stack from '../../../../common/LayoutComponents/Stack';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../../styles';
import moment from 'moment';
import {DATE_FORMATS} from '../../../../../constants';
import AppointmentDetail from '../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentDetail';
import { Popover } from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import CareProgramConsent from '../../../../common/CareProgramConsent/CareProgramConsent';
import ContactCareProgram from '../../../../../services/ContactCareProgram/ContactCareProgram';
import { useLazyQuery } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';

interface IKeyPointsViewProps {
  contactCareProgramId: string;
}

const KeyPointsView = (props: IKeyPointsViewProps) => {
  const {contactCareProgramId} = props;
  const {data: contactData, formattedData} = usePersonOmniViewContext();
  const {getPatientAdditionalData} = useCareProgramAdditionalData();
  const intl = useIntl();

  const [getCareProgramConsent] = useLazyQuery(ContactCareProgram.GET_CONTACT_CARE_PROGRAM_CONSENT_BY_ID, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const [state, setState] = useState({
    careProgram: {} as any,
    chronicConditions: [] as ICondition[],
    pcpAppointmentData: {} as any,
    loading: true,
    isAppointmentDetailDrawerOpen: false,
    isChronicConditionPopoverOpen: false,
    isPatientConsentPopoverOpen: false,
    hasConsent: false,
    lastUpdated: {} as any
  });

  const handleConsentClose = async () => {
    const response = await getCareProgramConsent({
      variables: {
        contactCareProgramId: contactCareProgramId,
      }
    });
    const careProgram = response.data?.contactCarePrograms?.[0];
    setState(prev => ({
      ...prev,
      isPatientConsentPopoverOpen: false,
      hasConsent: careProgram?.consentLog?.hasConsent,
      careProgram: careProgram
    }));
  };


  const getInitData = async () => {
    try {
      const careProgramAdditionalData = await getPatientAdditionalData({
        patientId: contactData?.patient?.patientId,
        contactUuid: contactData?.uuid,
        location: formattedData?.accountLocationUuid || '',
        additionalDataCodes: [
          ADDITIONAL_DATA_CODE.CHRONIC_CONDITIONS,
          ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT,
          ADDITIONAL_DATA_CODE.PATIENT_CONSENT,
          ADDITIONAL_DATA_CODE.LAST_UPDATED
        ],
        contactCareProgramId: contactCareProgramId
      });
      const chronicConditionResources =
        careProgramAdditionalData?.CHRONIC_CONDITIONS?.resources || [];
      let chronicConditions: ICondition[] = [];
      if (chronicConditionResources) {
        chronicConditionResources?.forEach((resource: any) => {
          chronicConditions = getRecordListFormatted(
            resource.conditions?.entry || []
          );
        });
      }
      const careProgramData = careProgramAdditionalData?.PATIENT_CONSENT;
      const hasConsent = careProgramData?.consentLog?.hasConsent;
      const futureAppointmentData =
        careProgramAdditionalData?.FUTURE_APPOINTMENT?.appointments?.[0] || {};
      setState((prev) => {
        return {
          ...prev,
          chronicConditions: chronicConditions,
          pcpAppointmentData: futureAppointmentData,
          loading: false,
          hasConsent: hasConsent,
          careProgram: careProgramData
        };
      });
    } catch (e) {
      setState((prev) => {
        return {
          ...prev,
          chronicConditions: [],
          pcpAppointmentData: {},
          loading: false,
        };
      });
    }
  };

  React.useEffect(() => {
    getInitData();
  }, []);

const renderChronicConditionView = (conditions: ICondition[]) => {
  const popoverContent = (
    <Stack direction="column" style={{width:200}}>
      {conditions.map((item) => {
        const diffInMonth = item?.onSetDateTime
          ? Math.floor(getDiffInMonths(item?.onSetDateTime, getMomentObj(new Date())))
          : 0;
        return (
          <Stack direction="row" style={{justifyContent:'space-between', alignItems:'center'}}>
            <Text style={styles.chronicCondition}>{item.name}</Text>
            <Text style={styles.chronicCondition}>{` ${diffInMonth}m`}</Text>
          </Stack>
        );
      })}
    </Stack>
  );

  return (
    <Popover overlayInnerStyle={{padding:8}} open={state.isChronicConditionPopoverOpen} onOpenChange={(visible) => setState(prev => ({...prev, isChronicConditionPopoverOpen: visible}))} content={popoverContent} trigger="click" style={{borderRadius: 24}}
    placement={'bottom'} >
      <Pressable onPress={() => setState(prev => ({ ...prev, isChronicConditionPopoverOpen: true }))}>
        <Stack direction="row" style={{alignItems:'center' , marginRight:2}}>
        <Text style={styles.chronicCondition}>
          {` • ${conditions.length} Chronic Condition${conditions.length !== 1 ? 's' : ''}  `}
        </Text>
        <Feather name="info" size={12} color={Colors.Custom.Gray500} />
        </Stack>
      </Pressable>
    </Popover>
  );
};

  const renderNextAppointmentDate = (pcpAppointmentData: any) => {
    return (
      <Stack direction="row">
        <Text
          style={styles.chronicCondition}
        >{` • Next PCP Appointment : ${moment(
          pcpAppointmentData?.startDateTime
        ).format(DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)}`}</Text>
      </Stack>
    );
  };

  const renderLastUpdatedDate = () => {
    return (
      <Text style={styles.chronicCondition}>{` • Last updated : ${moment(
        state?.lastUpdated?.timestamp
      ).format(DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)} `}</Text>
    );
  };

  const renderPatientConsent = () => {
    return (
      <Pressable onPress={() => setState(prev => ({
        ...prev,
        isPatientConsentPopoverOpen: true
      }))}>
      <Text style={styles.chronicCondition}>{` • Patient Consent : ${state?.hasConsent ? 'Yes' : 'No'}  `}</Text>
      </Pressable>
    );
  }

  return (
    <div className="key-points-view">
      <div className="patient-info">
        <Stack direction="row">
          {state?.loading ? (
            <Text style={styles.chronicCondition}>
              {intl.formatMessage({id: 'loadingText'})}
            </Text>
          ) : (
            <Stack direction="row">
              {renderLastUpdatedDate()}
              {state.chronicConditions?.length > 0 &&
                renderChronicConditionView(state.chronicConditions)}
              {renderPatientConsent()}
              <Pressable onPress={() => setState(prev => ({
                ...prev,
                isAppointmentDetailDrawerOpen: true
              }))}>
                {state?.pcpAppointmentData?.id &&
                  renderNextAppointmentDate(state?.pcpAppointmentData)}
              </Pressable>
            </Stack>
          )}
        </Stack>
        { state?.isAppointmentDetailDrawerOpen &&
           <AppointmentDetail
           event={{ detail: state?.pcpAppointmentData }}
           isVisible={true}
           onClose={() => {
            setState(prev => ({ ...prev, isAppointmentDetailDrawerOpen: false }))
           }}
         />
        }
        {
          <CareProgramConsent
            isVisible={state.isPatientConsentPopoverOpen}
            contactId={contactData?.uuid || ''}
            contactCareProgramId={contactCareProgramId}
            onClose={() => {
              handleConsentClose();
            }}
            programName={state?.careProgram?.payerCareProgram?.careProgramType?.value}
          />
        }
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  chronicCondition: {
    color: Colors.Custom.Gray500,
    fontSize: 12,
    fontWeight: '500',
  },
});

export default KeyPointsView;
