import {useQuery} from '@apollo/client';
import {HStack, Skeleton, Text, View, VStack} from 'native-base';
import React from 'react';
import {StyleSheet} from 'react-native';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {FormsQueries} from '../../../../services';
import {Colors} from '../../../../styles';
import {CommonCollapse} from '../../../RightSideContainer/Contacts/TeamMembers/LeftTeamContainer';
import {IContact} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {IPersonData} from '../../PersonHeaderBar/interfaces';

export interface ISubmittedFormsListProps {
  personData: IPersonData;
  personType: string;
  contactData?: IContact;
}

export interface FormResponseArray {
  formResponses: FormResponse[];
}

interface FormResponse {
  form: Form;
  createdOn: any;
  contactId: any;
  updatedOn: any;
}

interface Form {
  id: string;
  name: string;
  code?: string;
}

export const getFormattedSubmittedFormList = (data: FormResponseArray) => {
  const formResponse = data?.formResponses;
  const submittedFormsList: any = [];
  formResponse?.map((item) => {
    const form = item?.form;
    submittedFormsList.push({
      name: form?.name,
      code: form?.code,
      id: form?.id,
      createdOn: item?.createdOn,
      updatedOn: item?.updatedOn,
      contactId: item?.contactId,
    });
  });
  return submittedFormsList;
};

export const styles = StyleSheet.create({
  formListContainer: {},
  formList: {},
  formListItem: {
    paddingTop: 6,
    paddingRight: 18,
    paddingLeft: 18,
    paddingBottom: 6,
  },
  formName: {
    color: Colors.Custom.Gray900,
  },
});

const SubmittedFormsList = (props: ISubmittedFormsListProps) => {
  const {data, loading, error} = useQuery(FormsQueries.GET_SUBMITTED_FORM, {
    variables: {
      contactId: props.contactData?.uuid,
    },
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  if (error) {
    return (
      <View>
        <Text>something went wrong</Text>
      </View>
    );
  }

  const formattedFormResponse = getFormattedSubmittedFormList(data);

  return (
    <View style={styles.formListContainer}>
      <CommonCollapse textLocalId="submittedForms" btnList={[]}>
        {loading ? (
          <Skeleton.Text lines={4} />
        ) : (
          <VStack style={styles.formList}>
            {formattedFormResponse.map((singleForm: any) => {
              return (
                <HStack style={styles.formListItem}>
                  <View>
                    <Text>{singleForm.name}</Text>
                  </View>
                </HStack>
              );
            })}
          </VStack>
        )}
      </CommonCollapse>
    </View>
  );
};

export default SubmittedFormsList;
