import { Line } from "@ant-design/charts";
import React from 'react';
export function LineGraph(props: {
  data: Array<any>,
  xField: string,
  yField: string,
  seriesField: string,
  smooth: boolean,
  yAxis?: any,
  maintainAspectRatio: boolean,
  style?: any
}) {

  const graphData: any = {
    smooth: props.smooth,
    data: props.data,
    xField: props.xField,
    yField: props.yField,
    seriesField: props.seriesField,
    maintainAspectRatio: props.maintainAspectRatio,
  }
  if (props.yAxis !== undefined) {
    graphData.yAxis = props.yAxis;
  }

  return <Line style={props.style} {...graphData}></Line>
}
