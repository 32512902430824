import React from 'react';
import { Defs, G, Path, Svg, Rect } from 'react-native-svg';

function SingleUserIcon() {
  return (
    <Svg width="12" height="12" viewBox="0 0 12 12" fill="none">
      <Path d="M8 3C8 4.10457 7.10457 5 6 5C4.89543 5 4 4.10457 4 3C4 1.89543 4.89543 1 6 1C7.10457 1 8 1.89543 8 3Z" stroke="#A7AFBE" />
      <Path d="M10 8.75C10 9.99264 10 11 6 11C2 11 2 9.99264 2 8.75C2 7.50736 3.79086 6.5 6 6.5C8.20914 6.5 10 7.50736 10 8.75Z" stroke="#A7AFBE" />
    </Svg>
  );
}

export default SingleUserIcon;