import {DATE_FORMATS} from '../../../../../../constants';
import {IMlov} from '../../../../../../Interfaces';
import {
  extractDateBeforeT,
  getDateStrFromFormat,
} from '../../../../../../utils/DateUtils';
import {getMlovCodeFromId} from '../../../../../../utils/mlovUtils';
import {IAddOrUpdateTemplateBody, ICostSavingTemplate, ICostSavingTemplateTable, ICptBody, IPreviewCptTableData, ITemplateSelectedData} from '../interfaces/savingTemplatesInterface';
import {COST_TYPE_CODE} from './CostTemplatesConst';
import {
  ICostSavingCptResponse,
  ISavingCptTable,
} from './interface';

export const getCostSavingFormattedData = (
  costSavingTemplateResponse: ICostSavingTemplate[],
  costTypeMlov: IMlov[]
): ICostSavingTemplateTable[] => {
  return costSavingTemplateResponse.map((template) => {
    const createdDate = extractDateBeforeT(template.createdAt);
    const updatedDate = extractDateBeforeT(template.updatedAt);
    const formattedCreatedDate = getDateStrFromFormat(
      createdDate,
      DATE_FORMATS.MONTH_DATE_YEAR
    );
    const formattedUpdatedDate = getDateStrFromFormat(
      updatedDate,
      DATE_FORMATS.MONTH_DATE_YEAR
    );
    const costTypeCode = getMlovCodeFromId(
      costTypeMlov,
      template.costCategoryId
    );
    const assignedToCount =
      COST_TYPE_CODE.MARKET_COST === costTypeCode
        ? template?.marketCostSavingTemplateEmployer_aggregate?.aggregate?.count
        : COST_TYPE_CODE.OUR_COST === costTypeCode
        ? template?.ourCostSavingTemplateEmployer_aggregate?.aggregate?.count
        : '';
    return {
      id: template?.id,
      name: template?.name,
      description: template?.description,
      isDefault: template?.isDefault || false,
      costType: getMlovCodeFromId(costTypeMlov, template.costCategoryId),
      costCategoryId: template.costCategoryId,
      createdOn: formattedCreatedDate,
      updatedOn: formattedUpdatedDate,
      assignedTo: assignedToCount || 0,
    };
  });
};

export const getSavingCptFormattedData = (
  costSavingCptResponse: ICostSavingCptResponse[]
): ISavingCptTable[] => {
  return costSavingCptResponse.map((cptObj)=> {
    return {
      id: cptObj.id,
      name: cptObj.name || cptObj?.accountCpt?.name || '',
      cptCode: cptObj?.accountCpt?.code || '',
      rawData: cptObj,
      costPrice: cptObj.price,
      modifier: cptObj?.accountCpt?.modifier === '[]' ? '' : cptObj?.accountCpt?.modifier
    }
  })
};

export const getCostSavingTemplateQuery = (data: any) => {
  const {accountUuid, savingTypeId, costTypeId, searchText} = data;
  let query: any = {
    accountUuid: {
      _eq: accountUuid,
    },
    analyticalResourceId: {
      _eq: savingTypeId,
    },
    isDeleted: {_eq: false},
    _and: {
      _or: [
        {
          name: {
            _ilike: `%${searchText || ''}%`,
          },
        },
        {
          description: {
            _ilike: `%${searchText || ''}%`,
          },
        },
      ],
    },
  };
  if (costTypeId?.length) {
    query = {
      ...query,
      costCategoryId: {
        _eq: costTypeId,
      },
    };
  }
  return query;
};

export const getTemplateCptQuery = (data: any) => {
  const {accountUuid, templateId, searchString} = data;
  const query = {
    isDeleted: {
      _eq: false,
    },
    templateId: {
      _eq: templateId,
    },
    accountUuid: {
      _eq: accountUuid,
    },
    accountCpt: {
      _or: [
        {
          code: {
            _ilike: `%${searchString || ''}%`,
          },
        },
        {
          name: {
            _ilike: `%${searchString || ''}%`,
          },
        },
      ],
    },
  };
  return query;
};


export const convertToInteger = (value: any) => {
  if (value === '') {
    return 0; // Change empty strings to 0 or any other desired default value
  }
  return parseInt(value, 10); // Convert non-empty strings to integers
};

export const convertJSONtoCSV = (jsonData: any, templateName: string) => {
  const header = Object.keys(jsonData[0]);
  const csv = [
    header.join(','),
    ...jsonData.map((row: any) => header.map((fieldName) => JSON.stringify(row[fieldName])).join(',')),
  ].join('\n');

  // Create download link
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${templateName}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return
}

export const getCreateTemplateBody = (
  templateData: ITemplateSelectedData,
  showModifier?: boolean
) => {
  const cptsList: ICptBody[] = [];
  templateData?.costSavingTemplateCpt?.forEach((templateCpt) => {
    cptsList.push({
      code: templateCpt?.accountCpt?.code || '',
      name: templateCpt?.accountCpt?.name || '',
      price: templateCpt?.price || 0,
      description: templateCpt?.accountCpt?.description || '',
      ...(showModifier && {modifier: templateCpt?.accountCpt?.modifier || ''}),
    });
  });
  const templateBody: IAddOrUpdateTemplateBody = {
    name: templateData?.name + ' (Copy)' || '',
    description: templateData?.description || '',
    costCategoryId: templateData?.costCategoryId,
    analyticalResourceId: templateData?.analyticalResourceId,
    cpts: cptsList
  }
  return templateBody
};


function normalizeModifier(modifier?: any): string {
  if (!modifier || modifier === "[]") {
    return "";
  } else {
    return modifier.toString();
  }
}

export const  removeDuplicatesCpts = (
  cpts: any
):any => {
  const seenCombinations = new Set<string>();
  const uniqueCpts: any = [];

  cpts.forEach((cpt: any) => {
    let code: string;
    let modifier: string | undefined;

    if ("code" in cpt) {
      code = cpt.code;
      modifier = normalizeModifier(cpt.modifier);
    } else if ("CPT" in cpt) {
      code = cpt.CPT;
      modifier = normalizeModifier(cpt.MODIFIER);
    } else {
      return;
    }

    const combinationKey = `${code}-${modifier ?? ""}`;

    if (!seenCombinations.has(combinationKey)) {
      seenCombinations.add(combinationKey);
      uniqueCpts.push(cpt);
    }
  });

  return uniqueCpts;
}

export const formatFileSize = (bytes: number) => {
  if (bytes < 1024) {
    return bytes + " B";
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + " KB";
  } else {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  }
}

export const convertDataToCsvArrays = (headers: any, dataList: any) => {
  const headerKeys = headers.map((header: any) => header.key)
  const csvHeader = [headerKeys];
  const csvData = dataList.map((item: any) => {
    return headerKeys.map((key: string) => {
      return String(item[key] ?? '');
    });
  });
  const formattedCsvData = [...csvHeader, ...csvData];
  return {fields: csvHeader, data: formattedCsvData};
}
