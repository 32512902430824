import React from "react";

const NewScheduleCalenderSvg = (props: { customColor?: string; width?: number }) => {
  const { customColor, width = 20 } = props;
  const strokeColor = customColor ? customColor : "#8C210F";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.5 10V5c0-.92-.746-1.667-1.667-1.667H4.167C3.247 3.333 2.5 4.08 2.5 5v11.667c0 .92.746 1.666 1.667 1.666h5m4.166-16.666V5M6.667 1.667V5M2.5 8.333h15M14.583 14v1.417l1.25.833m2.5-.833a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
      ></path>
    </svg>
  );
}

export default NewScheduleCalenderSvg;
