import {ColumnsType} from 'antd/lib/table';
import {HStack, Image, Text, View} from 'native-base';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {
  MEDIA_TYPES,
  getMediaTypeFromMime,
  mediaFilter,
} from '../MediaLibraryUtils';
import {MediaLibraryTableActions} from './MediaLibraryTableActions';
import React from 'react';
import ReactPlayer from 'react-player';
import {Tooltip} from 'antd';
export const getMediaLibraryTableColumns = (
  actionFn: (actionCode: string, record: any) => void,
  defaultSortOrder?: 'ascend' | 'descend'
) => {
  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      fixed: 'left',
      render: (name: string, record) => {
        const type = getMediaTypeFromMime(record.mime || '');
        const url = record.thumbnail || record.url || '';
        const previewElement = getMediaPreviewElementForList({url, type});
        return (
          <HStack
            style={{
              alignItems: 'center',
            }}
          >
            <View
              style={[
                {
                  height: 50,
                  width: 80,
                  marginRight: 8,
                  borderRadius: 5,
                  overflow: 'hidden',
                },
              ]}
            >
              {previewElement}
            </View>
            <View>
              <Tooltip title={name}>
                <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                  {getShortenedString({str: name, length: 30})}
                </Text>
              </Tooltip>
            </View>
          </HStack>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="mime"
        />
      ),
      dataIndex: 'type',
      key: 'type',
      width: '10%',
      filters: mediaFilter,
      onFilter: (value: any, record: any) => {
        return record.type.includes(value);
      },
      render: (type: string) => {
        return (
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {type}
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="size"
        />
      ),
      dataIndex: 'size',
      width: '10%',
      key: 'size',
      sorter: (a: any, b: any) => a.size - b.size,
      render: (size: number) => {
        const sizeInMB = (size / 1000).toFixed(2);
        return (
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {sizeInMB} MB
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="lastUpdated"
        />
      ),
      dataIndex: 'lastUpdated',
      width: '20%',
      key: 'lastUpdated',
      defaultSortOrder: defaultSortOrder,
      sorter: (a: any, b: any) =>
        new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime(),
      render: (lastUpdated: string) => {
        const date = new Date(lastUpdated).toLocaleDateString('en-US', {
          year: 'numeric',
          day: 'numeric',
          month: 'long',
        });
        return (
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {date}
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      key: 'Actions',
      width: '20%',
      fixed: 'right',
      render: (record: any) => {
        return (
          <MediaLibraryTableActions
            record={record}
            onActionPerformed={actionFn}
          />
        );
      },
    },
  ];

  return columns;
};

export const getMediaPreviewElementForList = (args: {
  url: string;
  type: string;
}): React.ReactNode => {
  const {type, url} = args;
  switch (type) {
    case MEDIA_TYPES.VIDEO:
    case MEDIA_TYPES.AUDIO:
      return (
        <ReactPlayer width={'100%'} height={'100%'} controls={true} url={url} />
      );
    case MEDIA_TYPES.IMAGE:
      return (
        <Image
          style={{
            height: '100%',
            width: '100%',
          }}
          src={url}
        />
      );
    case MEDIA_TYPES.PDF:
      return <iframe height={'100%'} src={url}></iframe>;

    default:
      return <></>;
  }
};

export const getShortenedString = (args: {str: string; length?: number}) => {
  const {str} = args;
  const length = args.length || 100;
  if (str.length < length) {
    return str;
  }
  return str.slice(0, length) + '...';
};
