
export const ACTION_CODES = {
    ElationHealth: 'ElationHealth',
    Hint: 'Hint',
    Spruce: 'Spruce',
  };

export const UserActionLevels = {
    beginners: 'MOBILITY',
    intermediate: 'CONSUMERISM',
    patientEngagement: 'ENGAGEMENT',
    advanced: 'INTELLIGENCE',
    integrateSupercharge: 'DATA'
}

export const BusinessGoalDescription = {
    beginners: 'Download Fold mobility suite to connect with your practice and patient on the go, access business dashboard, set up automations.',
    intermediate: 'You can automate daily mandane task and free staff bandwidth. From birthday mailers, to automatic followup calls, you can automate everything.',
    advanced: 'Setup a hotline for patients, divert calls automatically to right experts, set rules, reminders with automate messages that engage patient in right context.',
    patientEngagement: 'Setup automate birthday campaign, annual visits free workshop for your patients. Engage then for better healthcare goals.',
    integrateSupercharge: 'Fold can be integrated with arrays of HIS, membership, employer management, communication management system.',
    beginnersCollapseText: 'See how you can save 30 hrs / month',
    intermediateCollapseText: 'Let’s Automate your daily work items',
    advancedCollapseText: 'Set up a patient hotline number',
    patientEngagementCollapseText: 'Create diagnosis wise campaign',
    integrateSuperchargeCollapseText: 'See list of integrations we support',
}