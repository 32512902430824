import { Spinner } from 'native-base';
import { StyleProp, ViewStyle } from 'react-native';
import { Colors } from '../../../styles';

interface IAvatarWithSpinner {
    backgroundStyle?: React.CSSProperties;
    spinnerStyle?: StyleProp<ViewStyle>;
    otherStyle?: any
}

const AvatarWithSpinner = (props: IAvatarWithSpinner) => {
    const defaultStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        borderColor: Colors.Custom.PurpleColor,
        borderRadius: '50%',
        backgroundColor: Colors.Custom.Primary50,
        width: '80px',
        height: '80px',
    };

    const mergedStyle = { ...defaultStyle, ...props?.backgroundStyle };

    return (
        <div style={mergedStyle}>
            <Spinner style={props?.spinnerStyle} size={props?.otherStyle?.size || 'lg'}/>
        </div>
    );
};

export default AvatarWithSpinner;