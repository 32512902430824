import {HStack, Text} from "native-base";
import {ANALYTICS_RESOURCE} from "../../../../../constants";
import {Colors} from "../../../../../styles";
import {DisplayText} from "../../../../common/DisplayText/DisplayText";

export const costSavingColumns = (resourceCode: string): any => {
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId={ resourceCode === ANALYTICS_RESOURCE.VISITS_SAVINGS ? "cptAndModifier" : 'cpt'}
        />
      ),
      dataIndex: 'cpt',
      key: 'cpt',
      width: '20%',
      fixed: 'left',
      ellipsis: true,
      render: (name: string, data: any): JSX.Element => {
        return (
          <HStack
            style={{
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Text
              size={'smSemibold'}
              style={{
                lineHeight: 24,
                color: 'Colors/Grey/400',
              }}
            >
              {name}
            </Text>
            <Text
              size={'smSemibold'}
              style={{
                lineHeight: 22,
                color: 'Colors/Grey/400',
                marginLeft: 6
              }}
            >
              {data?.modifier}
            </Text>
          </HStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      fixed: 'left',
      ellipsis: true,
      render: (name: string): JSX.Element => {
        return (
          <HStack
            style={{
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Text
              size={'smSemibold'}
              style={{
                lineHeight: 24,
                color: 'Colors/Grey/400',
              }}
            >
              {name}
            </Text>
          </HStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="qty"
        />
      ),
      dataIndex: 'qty',
      key: 'qty',
      width: '15%',
      render: (qty: string): JSX.Element => {
        return (
          <HStack
            style={{
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Text
              size={'smSemibold'}
              style={{
                lineHeight: 24,
                color: '#98A2B3',
              }}
            >
              {qty || 0}
            </Text>
          </HStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="marketCost"
        />
      ),
      dataIndex: 'marketCost',
      key: 'marketCost',
      width: '20%',
      ellipsis: true,
      render: (marketCost: number, record: any): JSX.Element => {
        const marketCostValue =
          record.marketCost == 'NA'
            ? 'NA'
            : (marketCost || 0).toFixed(2);
        return (
          <HStack
            style={{
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Text
              size={'smSemibold'}
              style={{
                lineHeight: 24,
                color: '#98A2B3',
              }}
            >
              {marketCostValue == 'NA' ? 'NA' : `$${marketCostValue}` }
            </Text>
          </HStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="ourCost"
        />
      ),
      dataIndex: 'ourCost',
      key: 'ourCost',
      width: '15%',
      ellipsis: true,
      render: (ourCost: number, record: any): JSX.Element => {
        const ourCostValue =
          record.ourCost == 'NA'
            ? 'NA'
            : (ourCost || 0).toFixed(2);
        return (
          <HStack
            style={{
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Text
              size={'smSemibold'}
              style={{
                lineHeight: 24,
                color: '#98A2B3',
              }}
            >
              {ourCostValue == 'NA' ? 'NA' : `$${ourCostValue}` }
            </Text>
          </HStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="savings"
        />
      ),
      dataIndex: 'savings',
      key: 'savings',
      width: '15%',
      ellipsis: true,
      render: (marketCost: string, record: any): JSX.Element => {
        const totalSaving = getTotalSavingValue(record)
        return (
          <HStack
            style={{
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Text
              size={'smSemibold'}
              style={{
                lineHeight: 24,
                color: '#98A2B3',
              }}
            >
              {totalSaving == 'NA' ? 'NA' : `$${totalSaving}` }
            </Text>
          </HStack>
        );
      },
      responsive: ['md'],
    },
  ]
}

const getTotalSavingValue = (costRecord: any) => {
  const ourCost = costRecord?.ourCost == 'NA' ? 0 : costRecord?.ourCost
  return costRecord?.marketCost == 'NA'
    ? 'NA'
    : costRecord?.marketCost
    ? (
        (costRecord?.marketCost - ourCost) * (costRecord.qty || 0)
      ).toFixed(2)
    : 0;
};
