import React, { useContext } from "react";
import {Table} from "antd";
import {Spinner, VStack} from "native-base";
import {Dimensions} from "react-native";
import {useNavigate} from "react-router-dom";
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";
import PageBodyContainer from "../../common/PageBodyContainer/PageBodyContainer";
import {TableWrapper} from "../../common/TableWrapper";
import {IContractListView} from "./HelperFiles/interface";
import {getContractsListColumns} from "./HelperFiles/TablesColumnsView";
import { CommonDataContext } from "../../../context/CommonDataContext";
import { getBooleanFeatureFlag } from "../../../utils/commonUtils";
import FeatureFlags from "../../../constants/FeatureFlags.enums";

const ContractsList = (props: IContractListView) => {
  const {stateData, handlePageChange, data} = props;
  const commonData = useContext(CommonDataContext);
  const hideProjectedRevenueInContract = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.HIDE_PROJECTED_REVENUE_IN_CONTRACT);
  const {height} = Dimensions.get('window');
  const navigate = useNavigate()
  const finalHeight = height - 220;
  const onActionPerformed = (code: string, data?: any) => {
    switch (code) {
      case COMMON_ACTION_CODES.DETAIL_VIEW:
        navigate(`/members/contracts/${data.id}/qualityMeasures`);
        break;
    }
  }
  return (
    <PageBodyContainer>
      <TableWrapper
        minHeight={140}
        pagination={{
          current: stateData?.currentPage,
          pageSize: stateData?.pageSize || 10,
          total: stateData?.total,
          onChange: (currentPage, currentPageSize) => {
            handlePageChange?.(currentPage, currentPageSize);
          },
        }}
        hideOnSinglePage={false}
      >
        <Table
          scroll={{x: 1000, y: finalHeight}}
          rowClassName={(record, index) => {
            let className = index % 2 == 0 ? 'table-row-light' : ''
            className = className + ' contracts'
            return className
          }
          }
          columns={getContractsListColumns({hideProjectedRevenueInContract}, onActionPerformed)}
          dataSource={data}
          pagination={false}
          loading={{
            spinning: stateData.loading,
            indicator: (
              <VStack justifyContent="center" height={'100%'}>
                <Spinner size={'lg'} />
              </VStack>
            ),
          }}
          rowKey={(row: any) => `contracts_list_${row.id}`}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                onActionPerformed(COMMON_ACTION_CODES.DETAIL_VIEW, record)
              },
            };
          }}
        />
      </TableWrapper>
    </PageBodyContainer>
  )
}

export default ContractsList;
