import {View, HStack, Text, VStack, Pressable} from 'native-base';
import {Colors} from '../../../../../styles';
import {IPackageNoData} from './interfaces';
import PlusIcon from '../../../../common/Svg/PlusSvg';
import PackagesEmptySvg from '../../../../common/Svg/PackagesEmptySvg';
import { Dimensions } from 'react-native';

const PackagesNoDataView = (props: IPackageNoData) => {
  const {height} = Dimensions.get('window');
  return (
    <VStack
      justifyContent={'center'}
      alignContent={'center'}
      alignItems={'center'}
      flex={1}
      height={height-270}
    >
      <View>
        <PackagesEmptySvg />
      </View>
      <Text mt={5} fontWeight={600} fontSize={16} color={'black'}>
        {props?.searchString?.length ? 'No Package Found' : 'Create Package'}
      </Text>
      <View display={'flex'} mb={3} maxW={500}>
        <Text fontWeight={400} fontSize={14} color={Colors.Custom.Gray400}>
          {
            'Create comprehensive health packages that include articles, media, tasks, and more, all in one place'
          }
        </Text>
      </View>
      <Pressable
        onPress={() => {
          props?.openCreateDrawer();
        }}
      >
        <HStack justifyContent={'center'} alignItems={'center'} mt={2}>
          <PlusIcon />
          <Text
            fontWeight={600}
            fontSize={14}
            color={Colors.Custom.PrimaryBtnColor}
          >
            {props?.searchString?.length ? 'Create package' : 'Create first package'}
          </Text>
        </HStack>
      </Pressable>
    </VStack>
  );
};

export default PackagesNoDataView;
