import {AxiosResponse} from 'axios';
import {View} from 'native-base';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {PRODUCT_SERVICES_FLOW_TYPE_CODE} from '../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
// import { COMMON_ACTION_CODES } from '../../../../../../constants/ActionConst';
import {
  getProductDetails,
  getTaxonsList,
} from '../../../../../../services/ProductAndServices/ProductAndServicesAPI';
import {commonBodyContentContainer} from '../../../../../../styles/CommonBodyContentStyle';
import TitleSubtitleView from '../../../../../common/TitleSubtitleView/TitleSubtitleView';
import {PRODUCT_DETAIL_SIDEBAR} from '../ProductConst';
import {IProductDetailMainViewLocationProps} from './interface';
import {styles} from './ProductDetailMainViewStyles';
import ProductDetailViewFormContainer from './ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/ProductDetailViewFormContainer';
import {
  ITaxonData,
  ITaxonsApiResp,
} from './ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/SearchComponents/interfaces';

const ProductDetailMainView = () => {
  // const {onActionPerformed, productData} = props;
  const {id, type} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const locationData = location.state as IProductDetailMainViewLocationProps;
  const productData = locationData?.productData;
  const [productMainViewState, setProductMainViewState] = useState({
    selectedMenuCode: PRODUCT_DETAIL_SIDEBAR[0].menuCode,
    isLoading: true,
    productDetailData: {},
    taxons: [] as ITaxonData[],
  });

  const getSelectedTypeTaxon = () => {
    const tabType =
      type === PRODUCT_SERVICES_FLOW_TYPE_CODE.JOURNEYS
        ? 'care-journeys'
        : type;
    const selectedTabCategory = productMainViewState.taxons.find((item) => {
      return item.attributes?.permalink === tabType;
    });
    if (selectedTabCategory) {
      return selectedTabCategory;
    }
  };

  const getTaxons = async () => {
    const taxonsApiResp: AxiosResponse<ITaxonsApiResp> = await getTaxonsList({
      'filter[name_cont]': '',
    });
    const taxonsApiRespData = taxonsApiResp?.data || {};
    if (
      taxonsApiRespData &&
      Object.keys(taxonsApiRespData) &&
      Object.keys(taxonsApiRespData).length &&
      taxonsApiRespData?.data &&
      taxonsApiRespData?.data?.length
    ) {
      setProductMainViewState((prev) => {
        return {
          ...prev,
          taxons: taxonsApiRespData?.data,
          isLoading: false,
        };
      });
    } else {
      setProductMainViewState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }
  };

  useEffect(() => {
    if (id !== '-1') {
      setProductMainViewState((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
      getProductDetails(
        id,
        (productResp: any) => {
          if (productResp) {
            productResp.included;
            productResp = {
              ...productResp,
              included: [...productResp.included, ...productData.imageData],
            };
            setProductMainViewState((prev) => {
              return {
                ...prev,
                isLoading: false,
                productDetailData: productResp,
              };
            });
          } else {
            setProductMainViewState((prev) => {
              return {
                ...prev,
                isLoading: false,
                isNoDataFound: true,
              };
            });
          }
        },
        () => {
          setProductMainViewState((prev) => {
            return {
              ...prev,
              isLoading: false,
              isNoDataFound: true,
            };
          });
        },
        type
      );
    } else {
      setProductMainViewState((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
      getTaxons();
    }
  }, [id]);

  const onProductDetailViewActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    if (!actionData.included) {
      actionData.included = [];
    }
    switch (actionCode) {
      case COMMON_ACTION_CODES.ADD:
        setProductMainViewState((prev) => {
          return {
            ...prev,
            isLoading: false,
            productDetailData: actionData,
          };
        });
        break;
      case COMMON_ACTION_CODES.ON_REFRESH_ACTION:
        let response = actionData;
        if (productData?.imageData?.length > 0) {
          response = {
            ...response,
            included: [...response.included, ...productData.imageData],
          };
        }
        setProductMainViewState((prev) => {
          return {
            ...prev,
            isLoading: false,
            productDetailData: response,
          };
        });
        break;
    }
  };

  const onBackBtnClick = () => {
    navigate(-1);
  };
  const getTitleName = () => {
    return id !== '-1' ? 'productDetail' : 'newProducts';
  };
  return (
    <View>
      {/* <View style={styles.titleWrapper}>
        <TitleSubtitleView
          showBackButton={true}
          onBackClick={onBackBtnClick}
          titleLabelId={getTitleName()}
          subtitleLabelId="productDetailSubtitle"
        />
      </View> */}

      {!productMainViewState.isLoading && (
        <View
          style={[
            styles.tabsWrapper,
            commonBodyContentContainer.pageContentContainer,
          ]}
        >
          <ProductDetailViewFormContainer
            selectedMenuCode={productMainViewState.selectedMenuCode}
            productData={productMainViewState.productDetailData as any}
            onActionPerformed={onProductDetailViewActionPerformed}
            selectedTaxon={getSelectedTypeTaxon()}
            isLoading={productMainViewState.isLoading}
            tabType={type as string}
          />
        </View>
      )}
    </View>
  );
};

export default ProductDetailMainView;
