import { View, Text, Skeleton, IconButton } from "native-base"
import { Colors } from "../../../../styles/Colors"
import Feather from 'react-native-vector-icons/Feather';
import FormPieChart from "./FormPieChart";
import { useEffect, useState, useContext } from "react";
import { getSectionDataByResourceCode } from "../../../../services/Analytics";
import { useLocation } from "react-router-dom";
import { IFormReportResponse, IFormResponseWidgetDisplayType, ISelectedValue, ITableCell, ITableCellType } from "./types";
import { styles, reactStyles } from "./style";
import moment from "moment";
import NodataViewTypeOne from "../../Analytics/EmployerAnalytics/SectionAndWidget/NoDataView/NodataViewTypeOne";
import { BUTTON_TYPE, DATE_FORMATS, GROUP_MEMBER_TYPE, MLOV_CATEGORY } from "../../../../constants";
import { ModalActionTitle } from "../../../common/ModalActionTitle/ModalActionTitle";
import { Drawer } from "antd";
import { DatePicker, DatePickerProps } from 'antd';
import { RangePickerProps } from "antd/lib/date-picker";
import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import AppInfoQueries from "../../../../services/AppInfo/AppInfoQueries";
import { DisplayCardAvatar } from "../../../common/DisplayCard/DisplayCardAvatar";
import { getPrimaryGenderCode, splitArrayIntoChunks } from "../../../../utils/commonUtils";
import MemberInfoListItem from "../../../common/MemberInfoListItem/MemberInfoListItem";
import { FormTable } from "./FormTable";
import { getTableDataFromHealthComponents, getTableDataFromNestedHealthComponents, tableDataFormatter } from "./helper";
import { CommonDataContext } from "../../../../context/CommonDataContext";
import { getMlovListByCategoryCode } from "../../../../utils/mlovUtils";
import { IEhrCapability } from "../../../../Interfaces";
import { componentKeys } from "../FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper";
import { vitalData } from "./raw";
import ContactsQueries from "../../../../services/Contacts/ContactsQueries";
import { getPractitioners } from "../../../../services/CommonService/AidBoxService";
import EmployerDropDown from "../../Analytics/CommonComponents/EmployerDropDown";
import { Employer } from "../../BrazilCodeMR/pages/analytics/AnalyticsController";
import { ALL_MEMBERS } from "../../Analytics/EmployerAnalytics/EmployerUtils/EmployerConst";
import { getFormattedDate, isCurrentDateInFutureComparedToOther } from "../../../../utils/DateUtils";
import {IFormCommonData} from "../FHFormio/CustomComponents/CustomWrapper/CustomWrapper";
import useEHRCapabilities from "../../../../screens/BusinessStudio/useEHRCapabilities";

const FormReport = (props: {
  dateRange?: Record<string, string>,
  setDateRange?: (range: Record<string, string>) => void,
  onEmployerChange: (employer: Employer) => void;
  employerData: Employer,
  locationIds?: string[]
}) => {
  const {onEmployerChange, employerData} = props;
  const location = useLocation();
  const client = useApolloClient();
  const commonContextData = useContext(CommonDataContext) as IFormCommonData;
  const [formResponse, setFormResponses] = useState<IFormReportResponse[]>([]);
  const [totalFormCount, setTotalFormCount] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [contactData, setContactData] = useState<Record<string, any>>({});
  const [practitionersData, setPractitionersData] = useState<Record<string, any>>({})

  const [selectedFormResponse, setSelectedFormResponse] = useState<IFormReportResponse>();
  const [selectedSection, setSelectedSection] = useState<any>();
  const [dateRange, setDateRange] = useState<Record<string, string>>({
    from: props?.dateRange?.["from"] || moment().subtract(1, 'month').startOf('month').format('yyyy-MM-DDTHH:mm:ss'),
    to: props?.dateRange?.["to"] || moment().endOf('month').format('yyyy-MM-DDTHH:mm:ss'),
  });

  useEffect(() => {
    fetchFormResponses(
      employerData?.id === ALL_MEMBERS ? undefined : employerData?.id
    );
  }, [dateRange]);

  const [getContactsData] = useLazyQuery(ContactsQueries.GET_CONTACTS_BY_CONTACT_IDS, {
    fetchPolicy: 'no-cache',
  });


  const [GetContactByUuidApi] = useLazyQuery(AppInfoQueries.GET_CONTACT_BY_UUID, {

  });


  const context = useContext(CommonDataContext);
  const ehrCapabilities = useEHRCapabilities({
    locationId: commonContextData?.locationId || props?.locationIds?.[0],
  });
  const genderMlovList = getMlovListByCategoryCode(
    MLOV_CATEGORY.PERSON_GENDER
  ) || []

  useEffect(() => {
    getPractitioners({
      successHandler(response) {
        if (response.data?.entry?.length) {
          const practitioners: any = {};
          response.data?.entry.forEach((entry: any) => {
            if (entry?.resource?.id) {
              const practitionerId = entry.resource.id;
              practitioners[practitionerId] = entry.resource;
            }
          });
          setPractitionersData(practitioners);
        }
      },
      errorHandler(error) {
      },
      locationId: commonContextData?.locationId || props?.locationIds?.[0]
    });
    fetchFormResponses(
      employerData?.id === ALL_MEMBERS ? undefined : employerData?.id
    );

  }, []);


  const fetchContactData = async (selectedValue?: ISelectedValue[]) => {
    if (selectedValue?.length) {
      const resourceDataToReturn: Record<string, any> = {};
      const contactIdList: string[] = [];
      selectedValue.forEach((item) => {
        if (item.contactId && !contactIdList.includes(item.contactId)) {
          contactIdList.push(item.contactId);
        }
      })
      const batchedList = splitArrayIntoChunks(contactIdList, 100);
      const promiseList: Promise<any>[] = [];
      batchedList.forEach((ids) => {
        promiseList.push(client.query({
          query: ContactsQueries.GET_CONTACTS_BY_CONTACT_IDS,
          variables: {
            contactIds: ids,
          },
          fetchPolicy: 'no-cache',
        }));
      })

      const contactData = await Promise.all(promiseList);
      contactData.forEach((data) => {
        data?.data?.contacts?.forEach((contactData: any) => {
          resourceDataToReturn[contactData.uuid] = contactData;
        })
      });
      setContactData({ ...contactData, ...resourceDataToReturn });
      // for (let i = 0; i < selectedValue.length; i++) {
      //     try {
      //         if (selectedValue[i]?.contactId) {
      //             const resp = await GetContactByUuidApi(
      //                 {
      //                     variables: {
      //                         contactUUID: selectedValue[i]?.contactId,
      //                     },
      //                 }
      //             )
      //             resourceDataToReturn[selectedValue[i]?.contactId] = resp.data?.contacts?.[0];
      //         }
      //     } catch (e) {
      //
      //     }

      // }

    }
  }

  const fetchFormResponses = async (employerId?: string) => {
    setLoading(true);
    const resp = await getSectionDataByResourceCode(
      'FORM_RESPONSES',
      {
        "resourceCode": 'FORM_RESPONSES',
        "isAccountDashboard": false,
        "appliedFilter": [
          {
            "filterCode": "DATE_RANGE",
            "filterValue": {
              "fromDate": dateRange.from,
              "toDate": dateRange.to,
            }
          },
          {
            "filterCode": "FORM_ID",
            "filterValue": {
              "value": location?.state?.selectedForm?.id
            }
          },
          {
            "filterCode": "RESOURCE_CODE",
            "filterValue": {
              "value": "form_question"
            }
          },
          {
            filterCode: 'LOCATION',
            filterValue: props?.locationIds && props?.locationIds?.length > 0 ? props?.locationIds : undefined,
          },
        ],
        ...(employerId && { employerId: employerId}),
      },
      "drill-down-data"
    )
    setLoading(false);

    if (resp?.data?.result?.questionResponses?.length) {
      const formResponses: IFormReportResponse[] = [];
      resp?.data?.result?.questionResponses.forEach((formResponse: IFormReportResponse) => {
        if (formResponse.type == 'customsurvey') {
          formResponse.groups?.forEach((group) => {
            formResponses.push({
              ...formResponse,
              label: group.label || formResponse.label,
              key: group.value || formResponse.key,
              type: "selectboxes",
              selectedValues: group.selectedValues
            });
          })
        } else {
          formResponses.push(formResponse);
        }
      })

      setTotalFormCount(resp?.data?.result?.totalFormCount || 0);
      setFormResponses(formResponses as IFormReportResponse[])
      fetchContactDataForTable(formResponses as IFormReportResponse[]);
    }
  }

  const fetchContactDataForTable = (response: IFormReportResponse[]) => {
    let selectedValueList: ISelectedValue[] = [];
    response?.forEach((item) => {
      if ([
        componentKeys.CONDITIONS,
        componentKeys.ALLERGIES,
        componentKeys.MEDICATIONS,
        componentKeys.IMMUNIZATIONS,
        componentKeys.SURGICAL_HISTORY,
        componentKeys.FAMILY_HISTORY,
        componentKeys.VITALS,
        componentKeys.CHIEF_COMPLAINT,
        componentKeys.PATIENT_DEMOGRAPHICS,
        componentKeys.PATIENT_COMMUNICATION,
        componentKeys.PATIENT_ADDRESS,
        componentKeys.EMERGENCY_CONTACT,
        'customsurvey',
      ].includes(item.type)) {
        selectedValueList = selectedValueList.concat(item?.selectedValues || []);
      } else if ([componentKeys.SOCIAL_HISTORY].includes(item.type)) {
        let selectedValues: ISelectedValue[] = [];
        item.groups?.forEach((group) => {
          selectedValues = selectedValues.concat(group.selectedValues || []);
        })
        selectedValueList = selectedValueList.concat(selectedValues || []);
      }
    })
    fetchContactData(selectedValueList);
  }

  const renderWidgetDisplayType = (response?: IFormReportResponse): IFormResponseWidgetDisplayType => {
    switch (response?.type) {
      case 'radio':
      case 'checkbox':
      case 'selectboxes':
      case 'select':
      case 'rating':
      case 'signature':
        return 'graph'
      case "number":
      case "email":
      case "textarea":
      case "textfield":
      case "currency":
      case "date":
      case "phoneNumber":
      case 'subjectiveComplaint':
      case 'objectiveFindings':
      case 'assessment':
      case 'plan':
        return 'options'
      case componentKeys.CONDITIONS:
      case componentKeys.ALLERGIES:
      case componentKeys.MEDICATIONS:
      case componentKeys.IMMUNIZATIONS:
      case componentKeys.SURGICAL_HISTORY:
      case componentKeys.FAMILY_HISTORY:
      case componentKeys.VITALS:
      case componentKeys.CHIEF_COMPLAINT:
      case componentKeys.PATIENT_DEMOGRAPHICS:
      case componentKeys.PATIENT_COMMUNICATION:
      case componentKeys.PATIENT_ADDRESS:
      case componentKeys.EMERGENCY_CONTACT:
        // case componentKeys.SOCIAL_HISTORY:
        return 'table'

      case componentKeys.SOCIAL_HISTORY:
      case 'customsurvey':
        return 'nested_table'

      default:
        return undefined;
    }
  }

  const getPeopleAnsweredLine = (selectedValues?: ISelectedValue[], sectionName?: string) => {
    const nullValues = selectedValues?.filter((val) => val?.selectedValue ? true : false);
    return (`In ${nullValues?.length || 0} out of ${selectedValues?.length || 0} responses, this ${sectionName || 'question'} is answered.`)
  }




  const renderWidgets = (response: IFormReportResponse, inDrawer: boolean) => {
    const type = renderWidgetDisplayType(response);
    const maxRowCount = 3;
    const selectedValues = response.selectedValues
      ?.map?.((res) => {
        try {
          const json = JSON.parse(res["selectedValue"]);
          return json;

        } catch (e) {
          //
          return res["selectedValue"]
        }
      })
      ?.flat().filter(item => item !== null && item !== undefined && item !== '');
    let values = response.values;
    switch (type) {
      case 'nested_table':
        const normalizedNestedData = getTableDataFromNestedHealthComponents(
          response,
          ehrCapabilities as IEhrCapability[],
          {
            genderMlovList,
            maxRow: inDrawer ? undefined : maxRowCount,
          }
        );
        return (
          <>
            {normalizedNestedData.map((section) => {
              const formattedTableNestedData = tableDataFormatter(section);
              if (inDrawer) {
                if (selectedSection && selectedSection.name !== section.name) {
                  return <></>;
                }
                return <>
                  <Text
                    style={styles.textStyle}
                    size={'smRegular'}
                    fontSize={14}
                  >
                    {`In ${section.totalAnswered || 0} out of ${section.totalResponses || 0} responses, this questionnaire is answered.`}
                  </Text>
                  {!section?.rows?.length || !section?.columns?.length ? (
                    <View style={styles.width100}>
                      <NodataViewTypeOne viewCode="TEXT_ICON" />
                    </View>
                  ) : (
                    <FormTable
                      contactData={contactData}
                      data={
                        formattedTableNestedData?.length
                          ? formattedTableNestedData
                          : []
                      }
                    />
                  )}
                </>
              }
              return (
                <View
                  key={response.key}
                  style={styles.formResponseContainer}
                >
                  <View
                    style={styles.alignItemsFlexStart}
                  >
                    <IconButton
                      cursor={'default'}
                      _hover={{
                        backgroundColor: '#00000000',
                      }}
                      background={Colors.Custom.PurpleColor}
                      borderRadius={5}
                      style={styles.padding6}
                      icon={
                        <Feather
                          size={16}
                          name={
                            renderWidgetDisplayType(response) == 'options'
                              ? 'align-left'
                              : 'bar-chart'
                          }
                          color={'#ffffff'}
                        />
                      }
                    />
                  </View>
                  <View
                    style={styles.flex1}
                  >
                    <Text
                      style={styles.marginRight6}
                      size={'smSemiBold'}
                      fontSize={18}
                    >
                      {section.name}
                    </Text>
                    <Text
                      style={styles.textStyle}
                      size={'smRegular'}
                      fontSize={14}
                    >
                      {`In ${section.totalAnswered || 0} out of ${section.totalResponses || 0} responses, this questionnaire is answered.`}
                    </Text>
                    {!section?.rows?.length || !section?.columns?.length ? (
                      <View style={styles.width100}>
                        <NodataViewTypeOne viewCode="TEXT_ICON" />
                      </View>
                    ) : (
                      <FormTable
                        contactData={contactData}
                        data={
                          formattedTableNestedData?.length
                            ? formattedTableNestedData
                            : []
                        }
                      />
                    )}
                    {section?.totalAnswered > maxRowCount &&
                      !inDrawer && (
                        <Text
                          color={Colors.Custom.PurpleColor}
                          size={'smBold'}
                          fontSize={14}
                          style={styles.marginVertical12}
                          onPress={async () => {
                            setSelectedSection(section);
                            setSelectedFormResponse(response);
                          }}
                        >
                          Show more
                        </Text>
                      )}
                  </View>
                </View>
              );
            })}
          </>
        );
      case 'table':
        const normalisedData = getTableDataFromHealthComponents(
          response,
          ehrCapabilities as IEhrCapability[],
          {
            genderMlovList,
            maxRow: inDrawer ? undefined : maxRowCount,
            practitionersData
          }
        );
        const formattedTableData = tableDataFormatter(normalisedData);
        return (
          <>
            <Text
              style={styles.textStyle}
              size={'smRegular'}
              fontSize={14}
            >
              {`In ${normalisedData.totalAnswered || 0} out of ${normalisedData.totalResponses || 0} responses, this question is answered.`}
            </Text>
            {!normalisedData?.rows?.length ||
              !normalisedData?.columns?.length ? (
              <View style={styles.width100}>
                <NodataViewTypeOne viewCode="TEXT_ICON" />
              </View>
            ) : (
              <FormTable
                contactData={contactData}
                data={formattedTableData?.length ? formattedTableData : []}
              />
            )}
            {normalisedData?.totalAnswered > maxRowCount &&
              !inDrawer && (
                <Text
                  color={Colors.Custom.PurpleColor}
                  size={'smBold'}
                  fontSize={14}
                  style={styles.marginVertical12}
                  onPress={async () => {
                    setSelectedFormResponse(response);
                  }}
                >
                  Show more
                </Text>
              )}
          </>
        );
      case 'graph':
        const order = response?.values?.map((item) => item.label)
        let selectedValuesCount: Record<string, number> =
          selectedValues?.reduce?.(function (
            a: Record<string, number>,
            c: string
          ) {
            return a[c] ? ++a[c] : (a[c] = 1), a;
          },
            {}) || {};
        if (response.type === 'signature') {
          values = [{ value: 'signed', label: 'Signed', riskScore: 0, groupRiskScore: 0 }, { value: 'notSigned', label: 'Not signed', riskScore: 0, groupRiskScore: 0 }];
          selectedValuesCount = {};
          selectedValuesCount['signed'] = selectedValues?.length || 0;
          selectedValuesCount['notSigned'] = (response?.selectedValues?.length || 0) - selectedValuesCount['signed'];
        }
        let maxNum = 0;
        let totalScore = 0;
        let maxVal:string[] = [];
        const pieChartData = Object.keys(selectedValuesCount)?.map(
          (value: string) => {
            const displayValue =
              values?.find((val) => val.value?.toString() == value)
                ?.label || value;
            const score =
              values?.find((val) => val.value?.toString() == value)
                ?.riskScore || 0;
            totalScore =
              totalScore + score * (selectedValuesCount[value] || 0);
            if (selectedValuesCount[value] > maxNum) {
              maxNum = selectedValuesCount[value];
              maxVal = [displayValue];
            }
            if(selectedValuesCount[value] == maxNum){
             !maxVal.includes(displayValue) ? maxVal.push(displayValue) : maxVal;
            }
            return {
              type: displayValue,
              value: selectedValuesCount[value],
            };
          }
        )?.sort((a, b) => {
          if (order && order.length) {
            const pos_a = order?.indexOf(a.type)
            const pos_b = order?.indexOf(b.type)
            return pos_a > pos_b ? 1 : -1
          }
          return 0;
        });
        let averageScore: number | string = totalFormCount ? totalScore / totalFormCount : 0;
        if (averageScore % 1 === 0) {
          averageScore = averageScore?.toString();
        } else {
          averageScore = averageScore?.toFixed(1);
        }
        return (
          <>
            <Text
              style={styles.textStyle}
              size={'smRegular'}
              fontSize={14}
            >
              {getPeopleAnsweredLine(response?.selectedValues)}
            </Text>

            <View
              style={styles.flexDirectionRowFlex1}
            >
              {pieChartData?.length ? (
                <>
                  <View
                    style={styles.flex1}
                  >
                    <FormPieChart
                      data={pieChartData}
                      legendPosition="left"
                    />
                  </View>
                  <View
                    style={styles.flex1AllCentered}
                  >
                    {response.type !== 'signature' ? (
                      <View
                        style={styles.headerContainer2}
                        backgroundColor={Colors.Custom.Gray200}
                      >
                        {totalScore ? (
                          <Text
                            color={Colors.Custom.Gray500}
                            size={'smRegular'}
                            fontSize={18}
                          >
                            Avg Score: ~{' '}
                            <Text size={'smSemiBold'} fontSize={18}>
                              {selectedValues?.length && averageScore}
                            </Text>
                          </Text>
                        ) : (
                          maxVal?.length? <Text
                            color={Colors.Custom.Gray500}
                            size={'smRegular'}
                            fontSize={18}
                          >
                            Most Answered:{' '}
                            <Text size={'smSemiBold'} fontSize={18}>
                              {maxVal?.join(", ")}
                            </Text>
                          </Text> : null
                        )}
                      </View>
                    ) : null}
                  </View>
                </>
              ) : (
                <View style={styles.width100}>
                  {' '}
                  <NodataViewTypeOne viewCode="TEXT_ICON" />
                </View>
              )}
            </View>
          </>
        );
      case 'options':
        const filteredValues = selectedValues?.filter(item => !!item);
        return (
          <>
            <Text
              style={styles.textStyle}
              size={'smRegular'}
              fontSize={14}
            >
              {getPeopleAnsweredLine(response?.selectedValues)}
            </Text>
            <View
              style={styles.flexDirectionColumn}
            >
              {filteredValues?.length ? (
                <>
                  {filteredValues?.map((value, index) => {
                    const formattedValue =
                      response.type == 'date'
                        ? moment(value)?.format('DD MMM yyyy')
                        : value;
                    if (index > 2 || !value) {
                      return null;
                    }
                    return (
                      <View
                        style={styles.formResponseContainer2}
                        backgroundColor={Colors.Custom.Gray50}
                      >
                        <Text size={'smRegular'} fontSize={16}>
                          {formattedValue}
                        </Text>
                      </View>
                    );
                  })}
                  {filteredValues?.length > 3 ? (
                    <Text
                      color={Colors.Custom.PurpleColor}
                      size={'smBold'}
                      fontSize={14}
                      style={styles.marginVertical12}
                      onPress={async () => {
                        setSelectedFormResponse(response);
                        await fetchContactData(response?.selectedValues);
                      }}
                    >
                      Show more
                    </Text>
                  ) : null}
                </>
              ) : (
                <View style={styles.width100}>
                  {' '}
                  <NodataViewTypeOne viewCode="TEXT_ICON" />
                </View>
              )}
            </View>
          </>
        );
      default:
        return (
          <NodataViewTypeOne
            viewCode="TEXT_ICON"
            text="Type Not Configured"
          />
        );
    }
  }

  const renderQuestionResponses = (questionResponses: IFormReportResponse[]) => {

    return (
      <>
        {questionResponses?.map((response: IFormReportResponse, index: number) => {
          if (response.type === componentKeys.SOCIAL_HISTORY || response.type === 'customsurvey') {
            return renderWidgets(response, false);
          }
          return (
            <View key={response.key} style={styles.formResponseContainer}>
              <View style={styles.alignItemsFlexStartMarginRight16}>
                <IconButton
                  _hover={{
                    backgroundColor: '#00000000'
                  }}
                  background={Colors.Custom.PurpleColor}
                  cursor={'default'}
                  borderRadius={5}
                  style={styles.padding6}
                  icon={
                    <Feather size={16} name={renderWidgetDisplayType(response) == 'options' ? "align-left" : "bar-chart"} color={'#ffffff'} />
                  }
                />
              </View>
              <View style={styles.flex1}>
                <Text style={styles.marginRight6} size={'smSemiBold'} fontSize={18}>
                  {response.label}
                </Text>
                {
                  renderWidgets(response, false)
                }

              </View>

            </View>)
        })
        }
      </>
    )
  }

  const renderDrawerContent = () => {
    if (!selectedFormResponse) {
      return null;
    }
    const type = renderWidgetDisplayType(selectedFormResponse);
    switch (type) {
      case 'table':
      case 'nested_table':
        return (
          renderWidgets(selectedFormResponse, true)
        )
      case 'options':
        return (
          <View>
            <Text style={styles.marginRight6FontSize18} size={'smSemiBold'} >
              {selectedFormResponse?.label}
            </Text>
            <Text style={styles.text2} size={'smRegular'} >
              {getPeopleAnsweredLine(selectedFormResponse?.selectedValues)}
            </Text>
            <View>
              {
                selectedFormResponse?.selectedValues?.map((item) => {
                  const contact = contactData[item?.contactId];
                  return (
                    <View style={styles.formResponseContainer3} backgroundColor={Colors.Custom.Gray100}>
                      <View style={styles.flexDirectionRowJustifySpaceBetweenAlignItemsCenter}>
                        <View style={styles.flexDirectionRowAlignItemsCenter}>
                          <DisplayCardAvatar
                            avatarStyle={{
                              avatarSize: '12',
                            }}
                            isLetterAvatarShow={true}
                            userData={{
                              userId: item?.contactId,
                              userType: GROUP_MEMBER_TYPE.CONTACT,
                              genderCode: getPrimaryGenderCode(contact),
                              userName: contact?.name
                            }}
                          />
                          <Text style={styles.marginLeft8FontSize14} size={'smSemiBold'} >
                            {contact?.name}
                          </Text>
                        </View>
                        <Text style={styles.marginLeft8FontSize14} color={Colors.Custom.Gray500} size={'smSemiBold'} >
                          {moment(item?.submittedDateTime)?.format("DD MMM")}
                        </Text>

                      </View>
                      <View style={styles.viewStyle} backgroundColor={Colors.Custom.Gray200} />
                      <Text style={styles.marginLeft8FontSize16} size={'smSemiBold'} >
                        {selectedFormResponse?.type == 'date' ? moment(item?.selectedValue)?.format("DD MMM yyyy") : item?.selectedValue}
                      </Text>

                    </View>

                  )
                })
              }

            </View>
          </View>
        )
      default:
        return null;

    }

  }

  const renderFormResponsedDrawer = () => {
    const type = renderWidgetDisplayType(selectedFormResponse);
    return (
      <Drawer
        open={selectedFormResponse ? true : false}
        style={reactStyles.minWidth700}
        width={type == 'table' || type == 'nested_table' ? '50vw' : '25vw'}
        onClose={() => {
          setSelectedFormResponse(undefined);
          setSelectedSection(undefined);
        }}
        closable={false}
        className={'filterDrawer'}
        bodyStyle={{
          paddingTop: 0
        }}
        title={
          <>
            <ModalActionTitle
              title={'responses'}
              titleColor={''}
              onActionClick={(code: string) => {
                if (code === 'cancel') {
                  setSelectedFormResponse(undefined);
                  setSelectedSection(undefined);
                };
              }}
              // actionButtons={[{code: 'cancel', name: 'Cancel'}]}
              buttonList={[
                {
                  show: true,
                  id: 1,
                  btnText: 'close',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: () => {
                    setSelectedFormResponse(undefined);
                    setSelectedSection(undefined);
                  },
                },

              ]}
            />
          </>
        }
      >
        {renderDrawerContent()}
      </Drawer>
    )
  }


  return (
    <>
      <View style={styles.headerContainer}>
        <View>
          <Text style={styles.marginRight6} size={'smBold'} fontSize={18}>
            {"Question wise Report"}
          </Text>
        </View>
        <View style={styles.flexDirectionRow}>
        <EmployerDropDown
            onEmployerChange={(employer: Employer) => {
              fetchFormResponses(
                employer?.id === ALL_MEMBERS ? undefined : employer?.id
              );
              onEmployerChange(employer);
            }}
            employerData={employerData}
            customStyles={{marginRight: 12}}
        />
          <View
            style={styles.datePickerContainer}
          >
            <DatePicker
              clearIcon={false}
              style={reactStyles.noBorderWidth}
              suffixIcon={null}
              format={
                'yyyy-MMM-DD'
              }
              disabledDate={(date) => {
                return isCurrentDateInFutureComparedToOther(date?.toString(), dateRange?.to)
              }}
              onChange={(
                value: DatePickerProps['value'] | RangePickerProps['value'],
              ) => {
                const dateRangeValue = {
                  from: value
                    ? getFormattedDate(
                        value?.toString(),
                        DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                      )
                    : '',
                  to: dateRange.to,
                };
              props?.setDateRange?.(dateRangeValue);
              setDateRange(dateRangeValue);

              }}
              picker="month"
              value={moment(dateRange.from || '')}
            />
            <DatePicker
              clearIcon={false}
              style={reactStyles.noBorderWidth}
              format={
                'YYYY-MMM-DD'
              }
              disabledDate={(date) => {
                return isCurrentDateInFutureComparedToOther(dateRange?.from, date?.toString())
              }}
              onChange={(
                value: DatePickerProps['value'] | RangePickerProps['value'],
              ) => {
                const dateRangeValue = {
                  to: value
                    ? getFormattedDate(
                        value?.toString(),
                        DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                      )
                    : '',
                  from: dateRange.from,
                };
              props?.setDateRange?.(dateRangeValue)
              setDateRange(dateRangeValue)
              }}
              picker="month"
              value={moment(dateRange.to || '')}
            />
          </View>
        </View>
      </View>
      <View style={styles.drawerContentContainer}>
        {renderFormResponsedDrawer()}
        {
          loading ? <> <Skeleton.Text lines={20} /></> :
            //  renderQuestionResponses(vitalData.result.questionResponses as any)
            renderQuestionResponses(formResponse)
        }

      </View>
    </>
  )
}

export default FormReport;
