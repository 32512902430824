import moment from 'moment';
import {DATE_FORMATS} from '../../../constants';
import {getMomentObjectWithDateStringAndFormat} from '../../../utils/DateUtils';
import {IHolidayLocation} from './Interfaces';

export const formatTime = (time: string) => {
  return getMomentObjectWithDateStringAndFormat(
    time,
    DATE_FORMATS.DISPLAY_TIME_FORMAT
  );
};

export const validateHolidayform = (formData: any) => {
  const {holidayname, fromDate, toDate} = formData;
  const errors: any = {};
  let isValid = true;
  let isEmpty = true;
  const currentDate = moment().startOf('day');
  const fromDateObj = moment(fromDate).startOf('day');
  const toDateObj = moment(toDate).startOf('day');
  errors.holidayname = !holidayname ? 'Holiday Name is required' :   '';
  errors.fromDate = !fromDate?.length
    ? 'From date is required'
    : '';
  errors.toDate = !toDate?.length ? 'To date is required' : toDateObj && toDateObj.isBefore(currentDate)  ? 'To date cannot be less than current date' :  '';
  if (!holidayname && !fromDate && !toDate) {
    isEmpty = true;
  } else {
    isEmpty = false;
  }
  errors.isDateRangeIssue = fromDateObj > toDateObj ? '"To Date" can not be less than "From Date"' : ''
  if (errors.holidayname || errors.fromDate || errors.toDate ||  errors.isDateRangeIssue ) {
    isValid = false;
  }

  return {
    isValid,
    errors,
    isEmpty,
  };
};

export const getHolidayLocationValueByKey = (keyName: string, locations: IHolidayLocation[] | any[]): any => {
  const returnedArray: any = []
  locations?.forEach((locationObj)=> {
    if (keyName === 'name'){
      returnedArray.push(locationObj?.practiceLocations[0]?.name);
    } else if (keyName === 'locationId') {
      return returnedArray.push(locationObj?.practiceLocationUuid)
    } else if (keyName === 'locationName') {
      return returnedArray.push(locationObj?.practiceLocation?.name)
    }
  })
  return returnedArray
}
