import { Button, Center, HStack, Icon, Input, Spacer, Text, useToast, View, VStack } from 'native-base';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BUTTON_TYPE } from '../../../../constants';
import { IUser } from '../../../../Interfaces';
import { Colors } from '../../../../styles';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { Popover, Select, Tooltip } from 'antd';
import { ModalActionAntSelect } from '../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import { SearchBar } from '../../../common/SearchBar';
import { IAccountLocation } from '../../Contacts/TeamMembers/interfaces';
import { saveInboxBusinessHours } from './PracticeAvailabilityService';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import InfoSvg from '../../../common/Svg/InfoSvg';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import PlusIcon from '../../../common/Svg/PlusSvg';

interface IProps {
  isUserSchedule: boolean;
  accountUsers?: IUser[];
  accountLocations?: IAccountLocation[];
  isInboxHours?: boolean;
  onClick: any;
  onInboxHoursSave?: any;
  inboxData?: any;
  onSearch: (searchText?: string) => void;
  onChangeLocation: (selectedLocation?: string) => void;
}

interface IComponentState {
  isPopoverOpen: boolean;
  selectedUserId?: string;
  searchText?: string;
  selectedAccountLocationId?: string;
  outOfOfficeMessage?: string;
  loading: boolean;
}

export const PracticeAvailabilityTopBar = (props: IProps) => {
  const intl = useIntl();
  const toast = useToast();
  const [ componentState, setComponentState ] = useState<IComponentState>({
    isPopoverOpen: false,
    outOfOfficeMessage: props.inboxData?.inbox?.outOfOfficeMessage,
    selectedAccountLocationId: props.inboxData?.inbox?.inboxAvailabilities?.[0]?.accountLocationUuid,
    loading: false,
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.onSearch?.(componentState.searchText);
    }, 800);
    return () => clearTimeout(delayDebounceFn);
  }, [ componentState.searchText ]);

  async function onInboxHoursSave(selectedAccountLocationId?: string, outOfOfficeMessage?: string) {
    const selectedInboxId = props.inboxData.inbox.uuid;
    const availabilityOject = {
      inboxId: selectedInboxId,
      locationId: selectedAccountLocationId,
      outOfOfficeMessage: outOfOfficeMessage,
    };
    const inboxAvailabilityResponse = await saveInboxBusinessHours(availabilityOject).catch(() => {
      showToast(toast, 'Error in saving business hours', ToastType.error);
    });
    setComponentState((prev) => ({
      ...prev,
      loading: false,
    }));
    if (inboxAvailabilityResponse?.data) {
      showToast(toast, 'Successfully saved business hours', ToastType.success);
    }

    return inboxAvailabilityResponse;
  }

  const getSelectedLocationId = () => {
    let selectedLocationId;
    if (props.accountLocations && componentState.selectedAccountLocationId) {
      const selectedLocation = props.accountLocations.find((location) => {
        return location.uuid === componentState.selectedAccountLocationId
      })
      selectedLocationId = selectedLocation ? selectedLocation.uuid: undefined;
    }
    return selectedLocationId;
  }
  return (
    <HStack mb={2}>
      {!props.isInboxHours && (
        <Text fontSize="2xl" fontWeight="bold">
          {intl.formatMessage({
            id: props.isUserSchedule
              ? 'configUserSchedule'
              : 'configPracticeSchedule',
          })}
        </Text>
      )}
      {props.isInboxHours && (
        <HStack space={2} justifyContent={'center'} alignItems={'center'}>
          <View>
            <Text noOfLines={2}>
              {
                'Associate the preferred location\nbusiness hour with the chat widget:'
              }
            </Text>
          </View>
          <Tooltip
            placement="top"
            title="Selected location business hours will be applicable to the chat widget"
          >
            <Icon
              color={Colors.Custom.Gray400}
              as={AntIcon}
              name={'infocirlceo'}
              size="4"
            />
          </Tooltip>
          <Select
            size="large"
            value={
              getSelectedLocationId()
            }
            onChange={(value: string) => {
              setComponentState((prev) => ({
                ...prev,
                selectedAccountLocationId: value,
              }));
              props.onChangeLocation?.(value);
            }}
            placeholder="Search Practice Locations"
            style={{height: '36px', width: '200px'}}
          >
            {props.accountLocations &&
              props.accountLocations.map((practiceLocation: any) => {
                return (
                  <Select.Option
                    key={`${practiceLocation.uuid}`}
                    value={practiceLocation.uuid}
                  >
                      {'  '}
                      {practiceLocation.practiceLocation.name}
                  </Select.Option>
                );
              })}
          </Select>

          <View pl={2}>
            <Text noOfLines={2}>
              {'Automated reply for\nout-of-business hours:'}
            </Text>
          </View>
          <Input
            backgroundColor={'white'}
            placeholder="Enter message"
            width={'300px'}
            _focus={{
              borderColor: Colors.Custom.Gray200
            }}
            value={componentState.outOfOfficeMessage}
            onChangeText={(value: string) => {
              setComponentState((prev) => ({
                ...prev,
                outOfOfficeMessage: value,
              }));
            }}
            marginTop={1}
          />
        </HStack>
      )}

      <Spacer />
      {!props.isUserSchedule && !props.isInboxHours && (
         <HStack space={4} alignItems="center">
         <View>
           <SearchBar
             width={350}
             minWidth={'auto'}
             placeholderText={'Search Location'}
             onChange={(value: string) => {
               setComponentState((prev) => ({
                 ...prev,
                 searchText: value,
               }));
             }}
           />
         </View>
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              props.onClick?.();
            },
            leftIcon: (
              <Icon
                as={AntIcon}
                name={'plus'}
                size="4"
                color={Colors.Custom.mainPrimaryPurple}
              />
            ),
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: props.isUserSchedule ? 'new' : 'new',
            }),
            withRightBorder: false,
          }}
        ></FoldButton>
        </HStack>
      )}

      {props.isInboxHours && (
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              setComponentState((prev) => ({
                ...prev,
                loading: true,
              }));
              onInboxHoursSave?.(
                componentState.selectedAccountLocationId,
                componentState.outOfOfficeMessage
              );
            },
            leftIcon: (
              <Icon
                as={AntIcon}
                name={'plus'}
                size="4"
                color={Colors.Custom.mainPrimaryPurple}
              />
            ),
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'saveInboxBusinessHours',
            }),
            withRightBorder: false,
          }}
        ></FoldButton>
      )}

      {props.isUserSchedule && (
        <HStack space={1}>
          <View>
            <SearchBar
              width={350}
              minWidth={'auto'}
              placeholderText={'Search Users'}
              onChange={(value: string) => {
                setComponentState((prev) => ({
                  ...prev,
                  searchText: value,
                }));
              }}
            />
          </View>
          <Popover
            key={'addUserSchedule'}
            overlayInnerStyle={{
              bottom: 3,
              borderRadius: 16,
              padding: 0,
            }}
            content={
              <VStack space={8}>
                <ModalActionAntSelect
                  disabled={false}
                  data={props.accountUsers}
                  loading={false}
                  showSearch={true}
                  allowClear={false}
                  value={componentState.selectedUserId || undefined}
                  onChange={(value: string) => {
                    setComponentState((prev) => ({
                      ...prev,
                      selectedUserId: value,
                      isPopoverOpen: false,
                    }));
                    props.onClick?.({selectedUserId: value});
                  }}
                  filterOption={(input: string, option: any) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  optionProps={{
                    key: 'uuid',
                    label: 'name',
                    value: 'uuid',
                  }}
                  label={'addUserSchedule'}
                  labelInValue={false}
                  placeholder="Select User"
                />
                <HStack>
                  <Spacer />
                  <Button.Group space={2}>
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          props.onClick?.({
                            selectedUserId: componentState.selectedUserId,
                          });
                          setComponentState((prev) => {
                            return {
                              ...prev,
                              selectedUserId: undefined,
                              isPopoverOpen: false,
                            };
                          });
                        },
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: 'continue',
                        }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  </Button.Group>
                </HStack>
              </VStack>
            }
            title=""
            trigger="click"
            visible={componentState?.isPopoverOpen}
            onVisibleChange={() => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  selectedUserId: undefined,
                  isPopoverOpen: !(prev?.isPopoverOpen || false),
                };
              });
            }}
            placement={'bottom'}
          >
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  setComponentState((prev) => {
                    return {
                      ...prev,
                      selectedUserId: undefined,
                      isPopoverOpen: !(prev?.isPopoverOpen || false),
                    };
                  });
                },
                leftIcon: <PlusIcon />,
              }}
              customProps={{
                btnText: intl.formatMessage({id: 'addUserSchedule'}),
              }}
            ></FoldButton>
          </Popover>
        </HStack>
      )}
    </HStack>
  );
};
