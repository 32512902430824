import {gql} from '@apollo/client';
export const GetAccountIdBySubdomain = gql`
  query GetAccountIdBySubdomain($subdomain: String!) {
    accountSubdomainMaps(where: {subDomain: {_eq: $subdomain}}) {
      id
      accountId
      subDomain
      customDomain
      accountToSubdomain {
        name
        supportEmail
      }
    }
  }
`;

export const GetSubdomainMapAdditionalAttributes = gql`
  query GetSubdomainMapAdditionalAttributes($accountId: Int) {
    accountSubdomainMaps(where: {accountId: {_eq: $accountId}}) {
      accountId
      additionalAttributes
      subDomain
      customDomain
      accountToSubdomain {
        name
        supportEmail
      }
    }
  }
`;
