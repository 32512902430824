import {FormControl, Select, Text} from 'native-base';
import {useState} from 'react';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {styles} from '../../../CampaignStyles';

export const Schedule = () => {
  const [scheduleFor, setScheduleFor] = useState('');
  return (
    <FormControl style={styles.selectScreenTwo}>
      <FormControl.Label>
        <DisplayText textLocalId="schedule" />
        <Text>:</Text>
      </FormControl.Label>
      <Select
        selectedValue={scheduleFor}
        accessibilityLabel="Schedule for Later"
        placeholder="Choose Schedule"
        onValueChange={(itemValue) => setScheduleFor(itemValue)}
      >
        <Select.Item label="Every Minute" value="Every Minute" />
      </Select>
    </FormControl>
  );
};
