import {ACCESS_PROVISION_VIEW,PERSON_TYPES} from '../../constants';
import {getEnvVariable} from '../../constants/BaseUrlConst';
import {USER_ACCESS_PERMISSION} from '../RightSideContainer/UserAccess/UserAccessPermission';
import {ISideBarMenu} from './SideBarInterface';

export const MAIN_MENU_CODES = {
  SETTING: 'SETTING',
  CONSUMER: 'CONSUMER',
  DASHBOARD: 'DASHBOARD',
  PATIENT: 'PATIENT',
  MY_PATIENT: 'MY_PATIENT',
  CALENDAR: 'CALENDAR',
  CALENDARCHECK: 'CALENDARCHECK',
  TASKS: 'TASKS',
  INBOX: 'INBOX',
  EMPLOYER: 'EMPLOYER',
  ANALYTICS: 'ANALYTICS',
  ANALYTICS_EMPLOYER: 'ANALYTICS_EMPLOYER',
  REPORTS: 'REPORTS',
  OPPORTUNITY: 'OPPORTUNITY',
  MEMBERS: 'MEMBERS',
  LEAD: 'LEADS',
  PAYMENTS: 'PAYMENTS',
  DESIGNER: 'DESIGNER',
  FORMS: 'FORMS',
  FORMS2: 'FORMS2',
  PRODUCTS: 'PRODUCTS',
  SERVICES: 'SERVICES',
  CARE_JOURNEY: 'CARE_JOURNEY',
  CAMPAIGN_MANAGER: 'CAMPAIGN_MANAGER',
  GROUPS: 'GROUPS',
  TAGS_MANAGEMENT: 'TAGS_MANAGEMENT',
  COMMUNICATION: 'COMMUNICATION',
  PRACTICE: 'PRACTICE',
  CONTENT_MANAGEMENT_EMAIL_TEMPLATES: 'CONTENT_MANAGEMENT_EMAIL_TEMPLATES',
  CONTENT_MANAGEMENT_PATIENT_EDUCATION: 'CONTENT_MANAGEMENT_PATIENT_EDUCATION',
  SCHEDULE: 'SCHEDULE',
  ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS',
  TASK_MANAGEMENT: 'TASK_MANAGEMENT',
  LINK: 'LINK',
  CONTENT_MANAGEMENT: 'CONTENT_MANAGEMENT',
  ADMIN_PATIENTS: 'ADMIN_PATIENTS',
  USER_ACCESS: 'USER_ACCESS',
  HOME: 'HOME',
  WEB_WIDGETS: 'WEB_WIDGETS',
  INTEGRATION_CONFIG: 'INTEGRATION_CONFIG',
  VIRTUAL_CALL_CENTER: 'VIRTUAL_CALL_CENTER',
  MEMBERSHIP: 'MEMBERSHIP',
  MEMBERSHIPS: 'MEMBERSHIPS',
  BILLING: 'BILLING',
  LOGS: 'LOGS',
  AUTOMATION: 'AUTOMATION',
  PROFILE: 'PROFILE',
  CALL: 'CALL',
  CALL_LOGS: 'CALL_LOGS',
  PATIENT_DETAILS:'PATIENT_DETAILS',
  PACKAGES: 'PACKAGES',
  SIGN_AMEND_EHR_NOTE: 'SIGN_AMEND_EHR_NOTE',
  IMPORT_SUMMARY: 'IMPORT_SUMMARY',
  WEARABLES: 'WEARABLES',
  PATIENTS: 'PATIENTS',
  CONTRACTS: 'CONTRACTS',
  ASSIGN_CARE_MANAGER: 'ASSIGN_CARE_MANAGER'
};

const ALL_DASHBOARD_SIDE_BAR_TAB = [
  MAIN_MENU_CODES.HOME,
  MAIN_MENU_CODES.ANALYTICS_EMPLOYER,
  MAIN_MENU_CODES.CONSUMER,
  MAIN_MENU_CODES.CALENDAR,
  MAIN_MENU_CODES.TASKS,
  // MAIN_MENU_CODES.AUTOMATION,
  MAIN_MENU_CODES.INBOX,
  MAIN_MENU_CODES.CALL_LOGS,
  MAIN_MENU_CODES.LEAD,
  // MAIN_MENU_CODES.EMPLOYER,
  MAIN_MENU_CODES.CAMPAIGN_MANAGER,
  MAIN_MENU_CODES.ANALYTICS,
  // MAIN_MENU_CODES.CONTENT_MANAGEMENT,
  MAIN_MENU_CODES.SETTING,
  // MAIN_MENU_CODES.GROUPS,
  //MAIN_MENU_CODES.AUTOMATION,
  //MAIN_MENU_CODES.PRODUCTS,
  //MAIN_MENU_CODES.INTEGRATION_CONFIG,
  //MAIN_MENU_CODES.PRACTICE,

  // MAIN_MENU_CODES.REPORTS,
  // MAIN_MENU_CODES.OPPORTUNITY,
  //MAIN_MENU_CODES.PAYMENTS,
  // MAIN_MENU_CODES.DESIGNER,
];

const ALL_DASHBOARD_SIDE_BAR_TAB_EMPLOYER = [
  MAIN_MENU_CODES.ANALYTICS,
];

const ALL_DASHBOARD_SIDE_BAR_TAB_FOR_MASTER = [
  MAIN_MENU_CODES.HOME,
  MAIN_MENU_CODES.CONSUMER,
  MAIN_MENU_CODES.ANALYTICS,
  MAIN_MENU_CODES.SETTING,
];

export const PROFILE_SIDE_BAR_TAB = MAIN_MENU_CODES.PROFILE;

export const EMPLOYER_ACCESS_CONTROL = [
  MAIN_MENU_CODES.ANALYTICS,
  MAIN_MENU_CODES.CONSUMER,
  MAIN_MENU_CODES.TASKS,
  MAIN_MENU_CODES.INBOX,
  MAIN_MENU_CODES.PROFILE,
];

// isDemoEnv
const envVariable = getEnvVariable();

if (
  envVariable === 'dev' ||
  envVariable === 'qa' ||
  envVariable === 'sandbox'
) {
  // ALL_DASHBOARD_SIDE_BAR_TAB.push(MAIN_MENU_CODES.OPPORTUNITY);
}

if (
  envVariable === 'dev' ||
  envVariable === 'qa' ||
  envVariable === 'sandbox'
) {
  // ALL_DASHBOARD_SIDE_BAR_TAB.push(MAIN_MENU_CODES.REPORTS);
}

const ALL_ADMIN_PANEL_SIDE_BAR_TAB = [
  MAIN_MENU_CODES.ADMIN_PATIENTS,
  MAIN_MENU_CODES.COMMUNICATION,
  MAIN_MENU_CODES.SCHEDULE,
  MAIN_MENU_CODES.FORMS,
  MAIN_MENU_CODES.CONTENT_MANAGEMENT,
  // MAIN_MENU_CODES.SERVICES,
  MAIN_MENU_CODES.CARE_JOURNEY,
  MAIN_MENU_CODES.WEB_WIDGETS,
  // MAIN_MENU_CODES.TAGS_MANAGEMENT,
  ACCESS_PROVISION_VIEW.IVR_VIEW ? MAIN_MENU_CODES.VIRTUAL_CALL_CENTER : '',
  MAIN_MENU_CODES.TASK_MANAGEMENT,
  MAIN_MENU_CODES.PRODUCTS,
  MAIN_MENU_CODES.MEMBERSHIPS,
  MAIN_MENU_CODES.ACCOUNT_SETTINGS,
  // MAIN_MENU_CODES.LOGS,
  // MAIN_MENU_CODES.BILLING,
  MAIN_MENU_CODES.INTEGRATION_CONFIG,
  MAIN_MENU_CODES.AUTOMATION,
  MAIN_MENU_CODES.CALL,
  MAIN_MENU_CODES.WEARABLES,

  // MAIN_MENU_CODES.USER_ACCESS,
];

export const USER_ROLE_SIDE_BAR_CODES = {
  USER: ALL_DASHBOARD_SIDE_BAR_TAB,
  ADMIN: ALL_ADMIN_PANEL_SIDE_BAR_TAB,
  EMPLOYER: ALL_DASHBOARD_SIDE_BAR_TAB_EMPLOYER,
};

export const USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE = {
  USER: ALL_DASHBOARD_SIDE_BAR_TAB,
  ADMIN: ALL_ADMIN_PANEL_SIDE_BAR_TAB,
  EMPLOYER: ALL_DASHBOARD_SIDE_BAR_TAB_EMPLOYER,
  MASTER_ACCOUNT: ALL_DASHBOARD_SIDE_BAR_TAB_FOR_MASTER
};

export const SIDE_MENU_CONST: ISideBarMenu[] = [
  {
    menuName: 'settings',
    menuCode: MAIN_MENU_CODES.SETTING,
    path: '/admin/setting',
  },
  {
    menuName: 'home',
    menuCode: MAIN_MENU_CODES.HOME,
    path: 'home',
    subMenu: [
      {
        menuName: 'clinical',
        subMenuCode: MAIN_MENU_CODES.DASHBOARD,
        path: 'home',
      },
      // {
      //   menuName: 'employers',
      //   subMenuCode: MAIN_MENU_CODES.ANALYTICS,
      //   path: 'analytics',
      // },
    ],
  },
  {
    menuName: 'calendar',
    menuCode: MAIN_MENU_CODES.CALENDAR,
    path: 'schedule',
    hasSetting: true,
    settingPath: 'admin/schedule/automation',
    settingMenuCode: MAIN_MENU_CODES.SCHEDULE,
    subMenu: [
      {
        menuName: 'calendar',
        subMenuCode: MAIN_MENU_CODES.CALENDAR,
        path: 'schedule',
      },
    ],
  },
  {
    menuName: 'tasks',
    menuCode: MAIN_MENU_CODES.TASKS,
    path: 'tasks/my_tasks',
    hasSetting: true,
    settingPath: 'admin/taskManagement/automation',
    settingMenuCode: MAIN_MENU_CODES.TASK_MANAGEMENT,
    subMenu: [
      {
        menuName: 'tasks',
        subMenuCode: MAIN_MENU_CODES.TASKS,
        path: 'tasks/my_tasks',
      },
      {
        menuName: 'tasks',
        subMenuCode: MAIN_MENU_CODES.TASKS,
        path: 'tasks/team_tasks',
      },
      {
        menuName: 'tasks',
        subMenuCode: MAIN_MENU_CODES.TASKS,
        path: 'tasks/created_tasks',
      },
      {
        menuName: 'tasks',
        subMenuCode: MAIN_MENU_CODES.TASKS,
        path: 'tasks/mentions_tasks',
      },
      {
        menuName: 'tasks',
        subMenuCode: MAIN_MENU_CODES.TASKS,
        path: 'tasks/:taskId/comment/:commentId',
      },
      {
        menuName: 'tasks',
        subMenuCode: MAIN_MENU_CODES.TASKS,
        path: 'tasks/analytics',
      },
    ],
  },
  {
    menuName: 'communicationInfo',
    menuCode: MAIN_MENU_CODES.INBOX,
    path: 'inbox/conversation',
    settingPath: 'admin/communication/automation',
    hasSetting: true,
    settingMenuCode: MAIN_MENU_CODES.INBOX,
    subMenu: [
      {
        menuName: 'messages',
        subMenuCode: MAIN_MENU_CODES.INBOX,
        path: 'inbox/allConversations',
      },
      {
        menuName: 'messages',
        subMenuCode: MAIN_MENU_CODES.INBOX,
        path: 'inbox/conversations',
      },
      {
        menuName: 'messages',
        subMenuCode: MAIN_MENU_CODES.INBOX,
        path: 'inbox/sms',
      },
      {
        menuName: 'messages',
        subMenuCode: MAIN_MENU_CODES.INBOX,
        path: 'inbox/email',
      },
      {
        menuName: 'messages',
        subMenuCode: MAIN_MENU_CODES.INBOX,
        path: 'inbox/fax',
      },
      {
        menuName: 'messages',
        subMenuCode: MAIN_MENU_CODES.INBOX,
        path: 'inbox/all_calls',
      },
      {
        menuName: 'messages',
        subMenuCode:MAIN_MENU_CODES.INBOX,
        path: 'inbox/internal',
      },
      {
        menuName: 'messages',
        subMenuCode: MAIN_MENU_CODES.INBOX,
        path: 'inbox/assigned_me',
      },
      {
        menuName: 'messages',
        subMenuCode: MAIN_MENU_CODES.INBOX,
        path: 'inbox/assigned_other',
      },
      {
        menuName: 'messages',
        subMenuCode: MAIN_MENU_CODES.INBOX,
        path: 'inbox/unassigned',
      },
      {
        menuName: 'messages',
        subMenuCode: MAIN_MENU_CODES.INBOX,
        path: 'inbox/mentions',
      },
      {
        menuName: 'messages',
        subMenuCode: MAIN_MENU_CODES.INBOX,
        path: 'inbox/starrted',
      },
      {
        menuName: 'messages',
        subMenuCode: MAIN_MENU_CODES.INBOX,
        path: 'inbox/archived',
      },
      {
        menuName: 'messages',
        subMenuCode: MAIN_MENU_CODES.INBOX,
        path: 'inbox/missed',
      },
      // {
      //   menuName: 'campaigns',
      //   subMenuCode: MAIN_MENU_CODES.CAMPAIGN_MANAGER,
      //   path: 'campaignManagement/list',
      // },
    ],
  },
  {
    menuName: 'employers',
    menuCode: MAIN_MENU_CODES.EMPLOYER,
    path: 'employers',
  },

  {
    menuName: 'reports',
    menuCode: MAIN_MENU_CODES.REPORTS,
    path: 'reports',
  },
  {
    menuName: 'opportunities',
    menuCode: MAIN_MENU_CODES.OPPORTUNITY,
    path: 'opportunities',
  },
  {
    menuName: 'leads',
    menuCode: MAIN_MENU_CODES.LEAD,
    path: 'members/lead',
    subMenu: [
      {
        menuName: 'leads',
        subMenuCode: MAIN_MENU_CODES.LEAD,
        path: 'members/lead',
      },
      {
        menuName: 'leads',
        subMenuCode: MAIN_MENU_CODES.LEAD,
        path: 'members/prospect',
      },
      {
        menuName: 'groups',
        subMenuCode: MAIN_MENU_CODES.GROUPS,
        path: 'members/lead_groups',
      },
      {
        menuName: 'importSummary',
        subMenuCode: MAIN_MENU_CODES.IMPORT_SUMMARY,
        resourceCode: MAIN_MENU_CODES.CONSUMER,
        path: 'members/importSummary',
      },
    ],
  },
  {
    menuName: 'patients',
    menuCode: MAIN_MENU_CODES.CONSUMER,
    path: 'members/patient',
    hasSetting: true,
    settingPath: 'admin/patients/automation',
    settingMenuCode: MAIN_MENU_CODES.ADMIN_PATIENTS,
    subMenu: [
      {
        menuName: 'patient',
        subMenuCode: MAIN_MENU_CODES.PATIENT,
        path: 'members/patient',
      },
      {
        menuName: 'myPatient',
        subMenuCode: MAIN_MENU_CODES.MY_PATIENT,
        path: 'members/myPatient',
      },
      // {
      //   menuName: 'leads',
      //   subMenuCode: MAIN_MENU_CODES.LEAD,
      //   path: 'members/prospect',
      // },
      {
        menuName: 'employers',
        subMenuCode: MAIN_MENU_CODES.EMPLOYER,
        path: 'members/employers',
      },
      {
        menuName: 'groups',
        subMenuCode: MAIN_MENU_CODES.GROUPS,
        path: 'members/groups',
      },
      {
        menuName: 'contracts',
        subMenuCode: MAIN_MENU_CODES.CONTRACTS,
        path: 'members/contracts',
      },
    ],
  },
  {
    menuName: 'payments',
    menuCode: MAIN_MENU_CODES.PAYMENTS,
    path: 'payments',
  },
  {
    menuName: 'designer',
    menuCode: MAIN_MENU_CODES.DESIGNER,
    path: 'admin',
  },
  {
    menuName: 'forms',
    menuCode: MAIN_MENU_CODES.FORMS,
    path: 'admin/forms/automation',
  },
  // This needs to be validated when the products tab is enabled
  // admin/commerce is common path for journeys and product
  // previously for journey permission check was performed on product code as path was common and product config was returned because it was declared first
  // Now that we don't have products page, added journey config ahead of product config
  {
    menuName: 'journey',
    menuCode: MAIN_MENU_CODES.CARE_JOURNEY,
    path: 'admin/commerce/journeys/journeys',
    subMenu: [
      {
        menuName: 'journeys',
        subMenuCode: MAIN_MENU_CODES.CARE_JOURNEY,
        path: 'admin/commerce/journeys/journeys',
      },
      {
        menuName: 'packages',
        subMenuCode: MAIN_MENU_CODES.PACKAGES,
        path: 'admin/commerce/journeys/packages',
      },
    ],
  },
  {
    menuName: 'products',
    menuCode: MAIN_MENU_CODES.PRODUCTS,
    path: 'admin/commerce/products/products',
  },
  // {
  //   menuName: 'carejourney',
  //   menuCode: MAIN_MENU_CODES.CARE_JOURNEY,
  //   path: 'admin/commerce/journeys',
  // },
  {
    menuName: 'practice',
    menuCode: MAIN_MENU_CODES.PRACTICE,
    path: 'admin/commerce/journeys',
    subMenu: [
      {
        menuName: 'membership',
        subMenuCode: MAIN_MENU_CODES.MEMBERSHIPS,
        path: 'admin/membership/products',
      },
      {
        menuName: 'account',
        subMenuCode: MAIN_MENU_CODES.ACCOUNT_SETTINGS,
        path: 'admin/account/teamMembers',
      },
      // {
      //   menuName: 'logs',
      //   subMenuCode: MAIN_MENU_CODES.LOGS,
      //   path: 'admin/logs',
      // },
      // {
      //   menuName: 'userSettingsTitle',
      //   subMenuCode: MAIN_MENU_CODES.USER_ACCESS,
      //   path: 'admin/userSettings/dashboard',
      // }
    ],
  },
  {
    menuName: 'automation',
    menuCode: MAIN_MENU_CODES.AUTOMATION,
    path: 'admin/commerce/journeys',
    subMenu: [
      {
        menuName: 'journey',
        subMenuCode: MAIN_MENU_CODES.CARE_JOURNEY,
        path: 'admin/commerce/journeys',
      },
      {
        menuName: 'automationLibrary',
        subMenuCode: MAIN_MENU_CODES.AUTOMATION,
        path: 'workflow/all',
      },
    ],
  },
  // {
  //   menuName: 'membership',
  //   menuCode: MAIN_MENU_CODES.MEMBERSHIP,
  //   path: 'admin/commerce/membership',
  // },
  {
    menuName: 'membership',
    menuCode: MAIN_MENU_CODES.MEMBERSHIPS,
    path: 'admin/membership',
  },
  {
    menuName: 'billing',
    menuCode: MAIN_MENU_CODES.BILLING,
    path: 'admin/billing',
  },
  {
    menuName: 'tagManagement',
    menuCode: MAIN_MENU_CODES.TAGS_MANAGEMENT,
    path: 'admin/tagManagement',
  },
  {
    menuName: 'messages',
    menuCode: MAIN_MENU_CODES.COMMUNICATION,
    path: 'admin/communication/automation',
  },
  {
    menuName: 'call',
    menuCode: MAIN_MENU_CODES.CALL,
    path: 'admin/call',
  },
  {
    menuName: 'templates',
    menuCode: MAIN_MENU_CODES.CONTENT_MANAGEMENT,
    path: 'admin/contentManagement',
    subMenu: [
      {
        menuName: 'content',
        subMenuCode: MAIN_MENU_CODES.CONTENT_MANAGEMENT,
        path: 'admin/contentManagement',
      },
      {
        menuName: 'forms',
        subMenuCode: MAIN_MENU_CODES.FORMS,
        path: 'admin/forms/automation',
      },
    ],
  },
  {
    menuName: 'campaigns',
    menuCode: MAIN_MENU_CODES.CAMPAIGN_MANAGER,
    path: 'campaignManagement/active',
    subMenu: [
      {
        menuName: 'active',
        subMenuCode: MAIN_MENU_CODES.CAMPAIGN_MANAGER,
        path: 'campaignManagement/active',
      },
      {
        menuName: 'draft',
        subMenuCode: MAIN_MENU_CODES.CAMPAIGN_MANAGER,
        path: 'campaignManagement/draft',
      },
      {
        menuName: 'ended',
        subMenuCode: MAIN_MENU_CODES.CAMPAIGN_MANAGER,
        path: 'campaignManagement/ended',
      },
    ],
  },
  {
    menuName: 'groups',
    menuCode: MAIN_MENU_CODES.GROUPS,
    path: 'group',
  },
  {
    menuName: 'contentManagementPatientEducation',
    menuCode: MAIN_MENU_CODES.CONTENT_MANAGEMENT_PATIENT_EDUCATION,
    path: 'admin/contentManagement/patientEducation',
  },
  {
    menuName: 'contentManagementEmailTemplates',
    menuCode: MAIN_MENU_CODES.CONTENT_MANAGEMENT_EMAIL_TEMPLATES,
    path: 'admin/contentManagement/emailTemplates',
  },
  {
    menuName: 'calendar',
    menuCode: MAIN_MENU_CODES.SCHEDULE,
    path: 'admin/schedule/automation',
  },
  {
    menuName: 'account',
    menuCode: MAIN_MENU_CODES.ACCOUNT_SETTINGS,
    path: 'admin/account/teamMembers',
  },
  // {
  //   menuName: '',
  //   menuCode: MAIN_MENU_CODES.ACCOUNT_SETTINGS,
  //   path: 'admin/account/teamMembers',
  // },
  {
    menuName: 'tasks',
    menuCode: MAIN_MENU_CODES.TASK_MANAGEMENT,
    path: 'admin/taskManagement/automation',
  },
  {
    menuName: 'Patient/Leads',
    menuCode: MAIN_MENU_CODES.ADMIN_PATIENTS,
    path: 'admin/patients/automation',
  },
  // {
  //   menuName: 'userSettingsTitle',
  //   menuCode: MAIN_MENU_CODES.USER_ACCESS,
  //   path: 'admin/userSettings/dashboard',
  // },
  {
    menuName: 'CRM Widgets',
    menuCode: MAIN_MENU_CODES.WEB_WIDGETS,
    path: 'admin/webWidgets',
  },
  // {
  //   menuName: 'logs',
  //   menuCode: MAIN_MENU_CODES.LOGS,
  //   path: 'admin/logs',
  // },
  // {
  //   menuName: 'virtualCallCenter',
  //   menuCode: MAIN_MENU_CODES.VIRTUAL_CALL_CENTER,
  //   path: 'admin/virtualCallCenter/automation',
  // },
  {
    menuName: 'integrationConfig',
    menuCode: MAIN_MENU_CODES.INTEGRATION_CONFIG,
    path: 'admin/integration/config',
    subMenu: [
      {
        menuName: 'integrationConfig',
        subMenuCode: MAIN_MENU_CODES.INTEGRATION_CONFIG,
        path: 'admin/integration/config',
      },
      {
        menuName: 'Lead Gen',
        subMenuCode: MAIN_MENU_CODES.WEB_WIDGETS,
        path: 'admin/webWidgets',
      },
    ],
  },
  {
    menuName: 'analytics',
    menuCode: MAIN_MENU_CODES.ANALYTICS,
    path: 'analytics',
  },
  {
    menuName: 'analytics',
    menuCode: MAIN_MENU_CODES.ANALYTICS_EMPLOYER,
    path: 'analytics_employer',
  },
  {
    menuName: 'call',
    menuCode: MAIN_MENU_CODES.CALL_LOGS,
    path: 'call-logs',
    subMenu: [
      {
        menuName: 'all',
        subMenuCode: MAIN_MENU_CODES.CALL_LOGS,
        path: 'call-logs/allCalls',
      },
      {
        menuName: 'incoming',
        subMenuCode: MAIN_MENU_CODES.CALL_LOGS,
        path: 'call-logs/incoming',
      },
      {
        menuName: 'outgoing',
        subMenuCode: MAIN_MENU_CODES.CALL_LOGS,
        path: 'call-logs/outgoing',
      },
      {
        menuName: 'missed',
        subMenuCode: MAIN_MENU_CODES.CALL_LOGS,
        path: 'call-logs/missed',
      },
    ],
  },
];

export const PROFILE_MENU_CONST: ISideBarMenu =
  {
    menuName: 'profile',
    menuCode: MAIN_MENU_CODES.PROFILE,
    path: 'profile',
  };
export const updateLeadTabsList = (filteredContactTypes: any) => {
  const leadsIndex = SIDE_MENU_CONST.findIndex(menu => menu.menuName === 'leads');
  const leadMenu = SIDE_MENU_CONST[leadsIndex];
  const leadsSubMenu = SIDE_MENU_CONST[leadsIndex]?.subMenu;
  const leadContactType = filteredContactTypes?.find((contactType: any) => contactType?.code === PERSON_TYPES.LEAD);
  if (leadsIndex !== -1 && SIDE_MENU_CONST[leadsIndex]?.subMenu !== undefined) {
    if (filteredContactTypes?.length) {
      filteredContactTypes?.forEach?.((contactType: any) => {
        const leadsSubMenu = SIDE_MENU_CONST[leadsIndex].subMenu!;
        if (leadsSubMenu) {
          leadsSubMenu.push({
            menuName: contactType?.value,
            subMenuCode: contactType?.id,
            path: `members/${contactType?.id}`
          });
        }
      })
    }
  }
}
