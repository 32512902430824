import {Text} from 'native-base';
import Stack from '../../../common/LayoutComponents/Stack';
import {styles} from './ContactOutreachActivitiesStyles';
import {View, useWindowDimensions} from 'react-native';
import {IFormattedContactOutreachActivity} from './interfaces';
import {
  PatientNotesLogModeOfContact,
  getPatientNotesLogModeOfContactTabIcon,
} from '../../constants';
import {Colors} from '../../../../styles';
import UserSvgV2 from '../../../common/Svg/UserSvgV2';
import {getOutComeColorByOutComeCode} from '../../../common/CareProgramStepOutreach/CareProgramStepOutreachUtils';
import RenderHTML from 'react-native-render-html';
import {useMemo} from 'react';
import { SMALL_WINDOW_1500 } from '../../../../constants';

interface IContactOutreachActivityItemProps {
  isLastItem: boolean;
  outreachActivity: IFormattedContactOutreachActivity;
}

export const ContactOutreachActivityItem = (
  props: IContactOutreachActivityItemProps
) => {
  const {isLastItem, outreachActivity} = props;
  const Icon = getPatientNotesLogModeOfContactTabIcon(
    outreachActivity.communicationMode as PatientNotesLogModeOfContact
  );

  const color = getOutComeColorByOutComeCode(outreachActivity.outcomeMlov);
  const { width } = useWindowDimensions();
  const isSmallScreen = width < SMALL_WINDOW_1500;

  const memoizedRenderHTML = useMemo(
    () => (
      <RenderHTML
        source={{
          html: outreachActivity.note || '',
        }}
        enableExperimentalMarginCollapsing={true}
        enableExperimentalBRCollapsing={true}
        baseStyle={{
          whiteSpace: 'pre',
          overflow: 'hidden',
          padding: 0,
          marginBottom: -10,
          marginTop: -10,
          color: Colors.FoldPixel.GRAY300
        }}
      />
    ),
    [outreachActivity.note]
  );

  return (
    <Stack direction="row" style={styles.outreachActivityItemView}>
      <View style={styles.timelineViewContainer}>
        <View style={styles.timeLineTopLineView} />
        <View style={styles.timeLineDotView} />
        {!isLastItem && <View style={styles.timeLineBottomLineView} />}
      </View>

      <Stack direction="column">
        <Stack direction="row" style={styles.timeLineTitleViewContainer}>
          <Text style={styles.outreachTitleText}>{'Patient Outreach'}</Text>
        </Stack>
        <Stack direction="column" style={styles.timeLineDetailView}>
          <Stack direction="row">
            <Stack direction="row">
              <View style={styles.outreachCommunicationModeViewIcon}>
                <Icon
                  width={12}
                  height={12}
                  customStrokeColor={Colors.FoldPixel.GRAY300}
                />
              </View>

              <Text style={styles.outreachSubText}>
                {outreachActivity.communicationModeText}
              </Text>
            </Stack>
            <Stack direction="row">
              <Text style={styles.outreachSubText}> • </Text>
              <View style={styles.outreachCommunicationModeViewIcon}>
                <UserSvgV2></UserSvgV2>
              </View>
              <Text style={styles.outreachSubText}>
                {outreachActivity.userName}
              </Text>
            </Stack>
            <Text style={styles.outreachSubText}>
              {' '}
              {`• ${outreachActivity.formattedDate}`}
            </Text>
          </Stack>
          <Stack direction="column">
            <Text style={styles.inabilityToContactText} color={color}>
              {outreachActivity.outcomeText}
            </Text>
            <View style={{width: isSmallScreen ? 450 : 550}}>
              {outreachActivity.note ? memoizedRenderHTML : <></>}
            </View>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
