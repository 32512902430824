import {gql} from '@apollo/client';

export const GET_VISITS = gql`
  query GetVisitType(
    $ids: [uuid!]!
    $employerUuid: uuid!
    $accountUuid: uuid!
  ) {
    visitTypes(where: {id: {_in: $ids}}) {
      id
      name
      code
      description
      codeType
      category
      categoryDescription
      accountVisits(
        where: {
          employerUuid: {_eq: $employerUuid}
          accountUuid: {_eq: $accountUuid}
        }
      ) {
        id
        displayName
        visitTypeId
        accountUuid
        employerUuid
        visitCost {
          id
          price
          priceData
          marketPriceData
          currentMarketPrice
        }
      }
    }
  }
`;

export const GET_VISITS_ACC = gql`
  query GetVisitType($ids: [uuid!]!, $accountUuid: uuid!) {
    visitTypes(
      where: {
        id: {_in: $ids}
        accountVisits: {visitCost: {currentMarketPrice: {_gt: "0"}}}
      }
      distinct_on: code
    ) {
      id
      name
      code
      description
      codeType
      category
      categoryDescription
      accountVisits(where: {accountUuid: {_eq: $accountUuid}}) {
        id
        displayName
        visitTypeId
        accountUuid
        employerUuid
        visitCost {
          id
          price
          priceData
          marketPriceData
          currentMarketPrice
        }
      }
    }
  }
`;

export const UPDATE_VISIT_COST = gql`
  mutation UpdatePracticeVisitCost(
    $price: float8
    $priceData: jsonb
    $marketPriceData: jsonb
    $id: uuid!
    $currentMarketPrice: float8
  ) {
    updatePracticeVisitCost(
      pk_columns: {id: $id}
      _set: {
        price: $price
        priceData: $priceData
        marketPriceData: $marketPriceData
        currentMarketPrice: $currentMarketPrice
      }
    ) {
      id
      price
      priceData
      marketPriceData
    }
  }
`;

export const CREATE_VISIT_COST = gql`
  mutation CreatePracticeVisitCost(
    $id: uuid
    $accountUuid: uuid
    $price: float8
    $marketPriceData: jsonb
    $priceData: jsonb
    $currentMarketPrice: float8
  ) {
    createPracticeVisitCost(
      object: {
        accountVisitTypeId: $id
        accountUuid: $accountUuid
        price: $price
        priceData: $priceData
        marketPriceData: $marketPriceData
        currentMarketPrice: $currentMarketPrice
      }
    ) {
      id
      price
      marketPriceData
    }
  }
`;
