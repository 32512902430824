import React from 'react';
import {ANALYTICS_RESOURCE, SECTION_LIST} from '../../../../../constants';
import {EMERGENCY_DEPARTMENT_CLAIMS_CODE_PDF_LINK, INFO_TEXT, INPATIENT_CLAIMS_CODE_PDF_LINK, OUTPATIENT_CLAIMS_CODE_PDF_LINK} from '../EmployerUtils/EmployerConst';
import {getInfoTitle} from '../EmployerUtils/WidgetUtils';
import WidgetsInfoSvg from '../../../../common/Svg/WidgetsInfoSvg';

interface IPrintActionBtn {
  resourceCode: string;
  style?: any;
  name: string;
  employerName:string;
  date:any;
  sectionCode:string;
  filterByTitle?:string;
  subTitle?: string
}

export const InfoIconView = (props: IPrintActionBtn) => {
  const {resourceCode, style, name, employerName, date,sectionCode, filterByTitle} = props;
  const title = getInfoTitle(resourceCode,name,employerName,date)
  const isStyleInclude = sectionCode === SECTION_LIST.OVERVIEW || sectionCode === SECTION_LIST.SAVINGS
  const getClaimsWidgetCodeLink = () => {
    let link  = ''
    let titleText = ''
    if (resourceCode === ANALYTICS_RESOURCE.CLAIMS) {
      if (name.trim().toLocaleLowerCase() == 'Emergency Department Claims Cost'.trim().toLocaleLowerCase()) {
        link = EMERGENCY_DEPARTMENT_CLAIMS_CODE_PDF_LINK
        titleText = 'Click to view CPT code'
      } else if (name.trim().toLocaleLowerCase() == 'Inpatient Claims Cost'.trim().toLocaleLowerCase()) {
        link = INPATIENT_CLAIMS_CODE_PDF_LINK;
        titleText = 'Click to view Revenue code'
      } else if (name.trim().toLocaleLowerCase() == 'Outpatient Claims Cost'.trim().toLocaleLowerCase()) {
        link = OUTPATIENT_CLAIMS_CODE_PDF_LINK
        titleText = 'Click to view CPT code'
      }
    }
    return {link, titleText}
  }
  return (
    <div
      style={
        isStyleInclude
          ? {
              backgroundColor: 'transparent',
              borderRadius: 100,
              display: 'flex',
              alignItems: 'center',
            }
          : {}
      }
    >
      {getClaimsWidgetCodeLink().link ?
        <a href={getClaimsWidgetCodeLink().link} target='_blank'>
          <WidgetsInfoSvg
            title={getClaimsWidgetCodeLink().titleText}
            isToolTip={true}
            customDimension={'20'}
            customColor={'gray'}
            subTitle={props.subTitle || ''}
            resourceCode={resourceCode}
          /> </a> : <WidgetsInfoSvg
          title={title}
          isToolTip={true}
          customDimension={'20'}
          customColor={'gray'}
          subTitle={props.subTitle || ''}
          resourceCode={resourceCode}
        />}
    </div>
  );
};

export default React.memo(InfoIconView);
