import { Pressable, Stack, Text } from 'native-base';
import React, { useState } from 'react';
import { Colors } from '../../../../../../styles';
import { useIntl } from 'react-intl';
import PlusIcon from '../../../../../../components/common/Svg/PlusSvg';
import InsightsReferralOrdersView from '../../../../../SideCar/components/InsightsReferralOrdersView';
import { usePersonOmniViewContext } from '../../../../../PersonOmniView/PersonOmniView.context';
import { useCareProgramStatus } from '../../hooks/useCareProgramStatus';
import { useContactCareProgramContext } from '../../ContactCareProgram.context';
import useCareProgramStepStatus from '../../hooks/useCareProgramStepStatus';



const DiagnosisRefferals: React.FC = () => {
  const {dispatch, state, onUpdateCareProgramStatus} = useContactCareProgramContext();
  const {formattedData: formattedContactData} = usePersonOmniViewContext();
  const [showInsightsReferralOrders, setShowInsightsReferralOrders] = useState(false);
  const {careProgramStatus} = useCareProgramStatus();
  const {careProgramStepStatus} = useCareProgramStepStatus();
  const selectedStepLog = state.contactCareProgramDetails?.stepsLog.find(stepLog => stepLog.careProgramStepId === state.selectedStepId);
  const isReadOnlyView = selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.done || selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.skip;

  return (
    <Stack direction="column" mt={4} px={40}>
       <InsightsReferralOrdersView
          formattedContactData={formattedContactData}
          contactData={formattedContactData?.contactData}
          onBack={() => {
            setShowInsightsReferralOrders(false);
          }}
          isReadOnlyView={isReadOnlyView}
        />
    </Stack>
  );
};

export default DiagnosisRefferals;
