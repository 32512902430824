import { View } from 'native-base';
import { useEffect, useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { Colors } from '../../../../styles/Colors';
import CustomTabsView from '../../../common/CustomTabsView/CustomTabsView';
import { ITabsList } from '../../../common/CustomTabsView/interfaces';
import { TABLE_TOP_BAR_ACTION_CODES, TableTopBar } from '../../../common/TableTopBar';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import CampaignManagementTable from './CampaignManagementTable';

const CampaignManagementMainView = () => {
  const basePath = '/campaignManagement';
  const [campaignState, setCampaignState] = useState({
    showAddOrEdit: false,
    duplicate: false,
    selectedTab: {} as ITabsList,
    searchString: '',
  });

  const tagsTabList = (): ITabsList[] => {
    const array: ITabsList[] = [
      {
        key: 'ACTIVE',
        title: 'Active',
        path: 'active',
        tabContainerElem: () => {
          return (
            <CampaignManagementTable
              key={campaignState.selectedTab}
              campaignState={campaignState}
              closeDrawer={()=> {
                setCampaignState({
                  ...campaignState,
                  showAddOrEdit: false,
                  duplicate: false,
                });
              }}
            />
          );
        },
      },
      {
        key: 'DRAFT',
        title: 'Draft',
        path: 'draft',
        tabContainerElem: () => {
          return (
          <CampaignManagementTable
              key={campaignState.selectedTab}
              campaignState={campaignState}
              closeDrawer={()=> {
                setCampaignState({
                  ...campaignState,
                  showAddOrEdit: false,
                  duplicate: false,
                });
              }}
            />
          );
        },
      },
      {
        key: 'ENDED',
        title: 'Ended',
        path: 'ended',
        tabContainerElem: () => {
          return (
          <CampaignManagementTable
            key={campaignState.selectedTab}
            campaignState={campaignState}
            closeDrawer={()=> {
              setCampaignState({
                ...campaignState,
                showAddOrEdit: false,
                duplicate: false,
              });
            }}
          />
          );
        },
      },
    ];
    return array;
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setCampaignState((prev) => {
          return {
            ...prev,
            searchString: actionData?.searchString?.trim() || '',
          };
        });
        break;
    }
  };

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele = <></>;
    ele = (
      <TableTopBar
      title=""
      isDividerNotVisible={true}
      onActionPerformed={(actionCode: string, actionData: any) => {
        onTableTopBarActionPerformed(actionCode, actionData);
      }}
      buttonList={[
        {
          btnClick: () => {
            setCampaignState({
              ...campaignState,
              showAddOrEdit: true,
              duplicate: false,
            });
          },
          btnText: 'createCampaign',
          colorScheme: 'solid',
          size: 'smBold',
          textColor: Colors.Custom.mainPrimaryPurple,
          borderColor: Colors.Custom.mainPrimaryPurple,
          backgroundColor: '#ffffff00',
          variant: 'PRIMARY',
          style: {
            paddingHorizontal: 12
          },
          leftIcon: (
            <AntIcon
              name="plus"
              color={Colors.Custom.mainPrimaryPurple}
              size={16}
            />
          ),
        },
      ]}
    />
    );
    return ele;
  };

  const onTabClick = (tab: ITabsList) => {
    setCampaignState({
      ...campaignState,
      selectedTab: tab,
    });
  };

  useEffect(() => {
    const tagsList = tagsTabList();
    tagsList.some((tab: any) => {
      if (location?.pathname?.indexOf(tab.path) != -1) {
        setCampaignState({
          ...campaignState,
          selectedTab: tab
        });
      }
    });
  }, []);

  return (
    <View>
      <View style={{backgroundColor: '#fff', flex: 1}}>
        <TitleSubtitleView
          titleLabelId="campaignManagement"
          subtitleLabelId=""
        />
      </View>
      <CustomTabsView
        contentTopMargin={0}
        showHeading={true}
        heading={campaignState.selectedTab?.key}
        canNavigate={true}
        basePath={basePath}
        tabsList={tagsTabList()}
        extraBigRightView
        tabListWrapperStyle={{
          paddingHorizontal: 16,
          paddingVertical: 16,
          backgroundColor: '#fff',
        }}
        selectedTab={campaignState.selectedTab}
        rightView={() => {
          return getSelectedTopBar(campaignState.selectedTab);
        }}
        onTabClick={(tabItem) => {
          onTabClick(tabItem);
        }}
      />
    </View>
  );
};

export default CampaignManagementMainView;
