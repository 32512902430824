import {Colors} from '../../../styles';
import {ICommonSvgProps} from './interfaces';

const VideoCallSvg = (props: ICommonSvgProps) => {
  const {
    isEnabled,
    defaultColor,
    customStrokeColor,
    customBgColor,
    borderColor,
  } = props;
  const strokeColor = isEnabled
  ? '#667085' :  "#D0D5DD"
  const bgColor = 'transparent'
  // const bgColor = isEnabled
  //   ? customBgColor
  //     ? customBgColor
  //     : Colors.primary['500']
  //   : customBgColor
  //   ? customBgColor
  //   : Colors.primary['200'];

  return (
    <svg
      style={{width: 'inherit', height: 'inherit'}}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="35"
        rx="17.5"
        fill={defaultColor ? defaultColor : bgColor}
      />
      <path
        d="M27.1666 13.8327L21.3333 17.9993L27.1666 22.166V13.8327Z"
        stroke={strokeColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.6666 12.166H10.5C9.5795 12.166 8.83331 12.9122 8.83331 13.8327V22.166C8.83331 23.0865 9.5795 23.8327 10.5 23.8327H19.6666C20.5871 23.8327 21.3333 23.0865 21.3333 22.166V13.8327C21.3333 12.9122 20.5871 12.166 19.6666 12.166Z"
        stroke={strokeColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default VideoCallSvg;
