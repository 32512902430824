import { Center, HStack, Skeleton, useToast, View } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import Icon from 'react-native-vector-icons/AntDesign';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { ILoginUserData, IMlov } from '../../../../Interfaces';
import { TABLE_TOP_BAR_ACTION_CODES, TableTopBar } from '../../../common/TableTopBar';
import { ICodeGroupsTableData, ICodeGroupData, ICodeGroupStateData, ICodeGroupFilter, ICodeGroupSorter } from './interfaces';
import { styles } from './CodeGroupsStyles';
import AddCodeGroupModal from './CodeGroupsModal/AddCodeGroupModal';
import CodeGroupsTable from './CodeGroupsTable/CodeGroupsTable';
import { createOrUpdateCodeGroup, getCodeGroups } from './CodeGroupApi';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { NoDataFound } from '../../../common/NoDataFound';
import PlusIcon from '../../../common/Svg/PlusSvg';
import { BUTTON_TYPE, SORT_TYPE } from '../../../../constants';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { Colors } from '../../../../styles';
import { CodeGroupsFilter } from './CodeGroupsFilter/CodeGroupsFilter';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';

const codeGroupsView = (props: {
    category: IMlov;
    searchString?: string;
    openModal?: boolean;
    showTableTopBar?: boolean;
    selectedActionCode: string;
    onActionPerformed?: (actionCode: string, actionData: any) => void;
    onViewChangeCallback?: () => void;
}) => {
    const commonData = useContext(CommonDataContext);
    const userData = commonData.userData || ({} as ILoginUserData);

    const { category, onActionPerformed, selectedActionCode } = props;
    const toast = useToast();
    const [openModal, setOpenModal] = useState(false);
    const [stateData, setStateData] = useState<any>([]);
    const [searchString, setSearchString] = useState('');
    const [codeGroupStateData, setCodeGroupStateData] = useState<ICodeGroupStateData>({
        limit: 10,
        offSet: 0,
        pageNo: 1,
        total: undefined,
        loading: false,
        filter : {
            categories: []
        },
    })
    const [codeGroupDataForEditOrView, setCodeGroupDataForEditOrView] = useState<ICodeGroupData>()

    const getSearch = (actionCode: string, actionData: any) => {
        setSearchString(actionData.searchString);
    };

    const handleEditingCodeGroup = (codeGroup: ICodeGroupsTableData) => {
        setOpenModal(true)
        setCodeGroupDataForEditOrView(codeGroup)
    };

    const handleDeleteCodeGroup = async (codeGroup: ICodeGroupsTableData) => {
        const newCodeGroup: any = {
            name: codeGroup?.name,
            description: codeGroup?.description,
            category: codeGroup?.category,
            codes: codeGroup?.codes,
            id: codeGroup?.id,
            isDeleted: true
        }
        try {
            const response = await createOrUpdateCodeGroup(newCodeGroup);
            if (response?.data) {
                showToast(toast, 'Code group deleted', ToastType.success)
            }
        }
        catch (error: any) {
            showToast(toast, 'Something went wrong', ToastType.error)
        }
        getCodeGroupsByPagination()
    };

    const onPagination = (page: any, pageSize: any) => {
        const pageNumber = page;
        const pageLimit = pageSize;
        const offset = pageNumber * pageLimit - pageLimit;
        setCodeGroupStateData((prev) => {
            return {
                ...prev,
                offSet: offset,
                pageNo: page,
                limit: pageLimit,
            }
        })
    };

    const onSort = (sort: ICodeGroupSorter) => {
        setCodeGroupStateData((prev) => {
            return {
                ...prev,
                sort: sort,
                offSet: 0,
                pageNo: 1,
            }
        })       
    };

    const getCodeGroupsByPagination = async () => {        
        setCodeGroupStateData((prev) => {
            return {
                ...prev,
                loading: true
            };
        });

        let sort = ''

        if(codeGroupStateData.sort?.order && codeGroupStateData.sort?.columnKey){
            sort = codeGroupStateData.sort?.order === SORT_TYPE.DESC?  `-${codeGroupStateData.sort?.columnKey}` : codeGroupStateData.sort?.columnKey
        }

        const variables = {
            searchString: props.searchString,
            limit: codeGroupStateData.limit,
            offset: codeGroupStateData.offSet,
            categories: codeGroupStateData?.filter?.categories?.toString() || '',
            sort: sort
        }

        try {
            const response = await getCodeGroups(variables).catch(() => {
                setCodeGroupStateData((prev) => {
                    return {
                        ...prev,
                        loading: false
                    };
                });
            })
            if (response?.data) {
                setStateData(response?.data?.codeGroups)
                setCodeGroupStateData((prev) => {
                    return {
                        ...prev,
                        total: response?.data?.totalCount,
                        loading: false
                    };
                });
            } else {
                setStateData([]);
                setCodeGroupStateData((prev) => {
                    return {
                        ...prev,
                        loading: false
                    };
                });
            }
        }
        catch {
            showToast(toast, 'Something went wrong', ToastType.error)
        }
    };

    useEffect(() => {
      getCodeGroupsByPagination();
    }, [
      codeGroupStateData.pageNo,
      codeGroupStateData.limit,
      codeGroupStateData?.filter?.categories,
      codeGroupStateData?.sort?.order,
    ]);

    useEffect(() => {
        if (props.searchString) {
            setSearchString(props.searchString);
            setCodeGroupStateData((prev) => {
                return {
                    ...prev,
                    offSet: 0,
                    pageNo: 1,
                };
            });
            getCodeGroupsByPagination();
        } else {
            setSearchString('');
            setCodeGroupStateData((prev) => {
                return {
                    ...prev,
                    offSet: 0,
                    pageNo: 1,
                };
            });
            getCodeGroupsByPagination();
        }
    }, [props.searchString]);
    useEffect(() => {
        if (props.openModal) {
            setOpenModal(props.openModal);
        }
    }, [props.openModal]);

    const handleOnFilterChange = (filterData: ICodeGroupFilter) => {        
        setCodeGroupStateData((prev) => {
            return {
                ...prev,
                filter: filterData,
                offSet: 0,
                pageNo: 1
            };
        });

        const appliedFilters = []
        if(onActionPerformed){
          if(filterData?.categories?.length) {
            if (filterData?.categories) {
              appliedFilters.push('Categories: ' + filterData?.categories?.join(', '));
            }
          }
          onActionPerformed(TABLE_TOP_BAR_ACTION_CODES.CLOSE, {});
          onActionPerformed(COMMON_ACTION_CODES.SAVE, appliedFilters)
        }
    }    

    return (
      <View flex={1}>
        <HStack flex={1}>
          <View flex={1} style={styles.container}>
            {props.showTableTopBar && (
              <TableTopBar
                title={`${category?.value} CodeGroups`}
                onActionPerformed={getSearch}
                buttonList={[
                  {
                    btnText: 'codeGroupAdd',
                    colorScheme: 'primary',
                    textColor: 'white',
                    variant: 'PRIMARY',
                    size: 'sm',
                    leftIcon: <Icon name="plus" color={'white'} />,
                    btnClick: () => setOpenModal(true),
                  },
                ]}
              />
            )}
            {openModal ? (
              <AddCodeGroupModal
                categoryId={category?.id}
                isModalOpen={openModal}
                onModalClose={() => {
                  setOpenModal(false);
                  setCodeGroupDataForEditOrView(undefined);
                  if (props.onViewChangeCallback) {
                    props.onViewChangeCallback();
                  }
                }}
                codeGroupDataForEditOrView={codeGroupDataForEditOrView}
                isEdit={codeGroupDataForEditOrView?.accountUuid ? true : false}
                onlyViewList={
                  !codeGroupDataForEditOrView
                    ? false
                    : !codeGroupDataForEditOrView?.accountUuid
                    ? true
                    : false
                }
                onSave={() => getCodeGroupsByPagination()}
              />
            ) : (
              <></>
            )}
            {codeGroupStateData.loading ? (
              <View background={'#fff'} padding={3}>
                <Skeleton.Text lines={6} />
              </View>
            ) : !codeGroupStateData.loading &&
              codeGroupStateData?.total === 0 ? (
              <View>
                <View>
                  <Center
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    height={600}
                  >
                    <NoDataFound
                      iconColor={Colors.Custom.Gray300}
                      isShowIcon={true}
                      iconSize={35}
                      displayString="Code groups are list of standard codes representing the same clinical entity which can be used while creating custom population groups"
                    />
                    <FoldButton
                      nativeProps={{
                        size: 'smBold',
                        variant: BUTTON_TYPE.PRIMARY,
                        leftIcon: <PlusIcon />,
                        onPress: () => setOpenModal(true),
                        marginRight: 1,
                      }}
                      key={'createCodeGroup'}
                      customProps={{
                        btnText: 'Create Code Group',
                      }}
                    />
                  </Center>
                </View>
              </View>
            ) : (
              <CodeGroupsTable
                onSort={onSort}
                sort={codeGroupStateData?.sort}
                onPagination={onPagination}
                pageNo={codeGroupStateData.pageNo}
                pageSize={codeGroupStateData.limit}
                total={codeGroupStateData.total}
                data={stateData}
                onDelete={(codeGroup: ICodeGroupsTableData) =>
                  handleDeleteCodeGroup(codeGroup)
                }
                onEdit={(codeGroup: ICodeGroupsTableData) =>
                  handleEditingCodeGroup(codeGroup)
                }
              />
            )}
          </View>
        </HStack>
        {selectedActionCode === TABLE_TOP_BAR_ACTION_CODES.FILTER_VIEW ? (
          <CodeGroupsFilter
            onApply={(filterData: ICodeGroupFilter) => {
              handleOnFilterChange(filterData)
            }}
            onReset={() => {
              handleOnFilterChange({categories:[]})
            }}
            filterData={codeGroupStateData?.filter}
            onClose={() => {
              onActionPerformed && onActionPerformed(TABLE_TOP_BAR_ACTION_CODES.CLOSE, {});
            }}
          ></CodeGroupsFilter>
        ) : <></>}
      </View>
    );
};

export default codeGroupsView;
