import {Path, Svg} from 'react-native-svg';

const PlayButtonSvg = (props: {strokeColor?: string; size?: number;}) => {

  const strokeColor = props.strokeColor || "#8A94A8";
  const size = props?.size || 16;

  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <Path d="M13.6057 6.23522C15.0203 7.00448 15.0203 8.99585 13.6057 9.76511L5.06441 14.4098C3.68957 15.1575 2 14.1844 2 12.6449L2 3.35543C2 1.81595 3.68957 0.842845 5.06441 1.59048L13.6057 6.23522Z" 
      stroke={strokeColor}/>
    </Svg>

  );
};

export default PlayButtonSvg;
