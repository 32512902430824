import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  acceptButton: {
    height: 32,
    borderColor: Colors.Custom.Gray300,
    backgroundColor: Colors.Custom.White,
    borderWidth: 0.5,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4,
    flexDirection: 'row'
  },
  disabledAcceptButton: {
    height: 32,
    borderColor: Colors.FoldPixel.GRAY50,
    backgroundColor: Colors.FoldPixel.GRAY50,
    borderWidth: 0.5,
    padding: 8,
    borderRadius: 4,
    color: Colors.FoldPixel.GRAY150
  },
  declineButton: {
    height: 32,
    backgroundColor: Colors.FoldPixel.GRAY50,
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginRight: 8,
    flexDirection: 'row'
  },
  declinedStatusButton: {
    height: 32,
    backgroundColor: Colors.Custom.Red100,
    padding: 8,
  },
});
