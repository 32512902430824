import {AxiosInstance} from 'axios';
import {v4} from 'uuid';
import {getHintPracticeDetails} from '../../../services/CommonService/AidBoxService';
import {
  getAccountLevelUserAction,
  getSingleUserAction,
} from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {
  UsageLevelItem,
  UsageLevelListItemControllerArgs,
  UsageLevelListItemState,
  UserActionMaster,
} from './interfaces';
import {USER_ACTION_CODES} from './UsageLevelConsts';

export class UsageLevelListItemController {
  bgColor: string;
  showTryOption: boolean;

  private actionItem: UserActionMaster;
  private userId: string;
  private state: UsageLevelListItemState;
  private nullObject: UsageLevelItem;
  private isAccountLevelAction?: boolean;
  private tenantId: string;
  private integrationService: AxiosInstance;
  private setState: React.Dispatch<
    React.SetStateAction<UsageLevelListItemState>
  >;

  constructor(args: UsageLevelListItemControllerArgs) {
    this.actionItem = args.actionItem;
    this.userId = args.userId;
    this.state = args.state;
    this.nullObject = {
      actionCode: this.actionItem.actionCode,
      createdOn: '',
      id: v4(),
      isDeleted: false,
      level: '',
      metadata: {},
      numberOfTry: 0,
      status: 'pending',
      userId: this.userId,
    };
    this.showTryOption =
      !this.state.item.status || this.state.item.status == 'pending';
    this.bgColor = this.showTryOption ? 'white' : 'white';
    this.isAccountLevelAction = args.isAccountLevelAction;
    this.tenantId = args.tenantId;
    this.integrationService = args.integrationService;
    this.setState = args.setState;

    // binding
    this.getSingleActionItem = this.getSingleActionItem.bind(this);
    this.onMount = this.onMount.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setNullObject = this.setNullObject.bind(this);
    this.setItemState = this.setItemState.bind(this);
    this.setActionStatus = this.setActionStatus.bind(this);
    this.setActionStatusForElationAction =
      this.setActionStatusForElationAction.bind(this);
    this.setActionStatusForHintAction =
      this.setActionStatusForHintAction.bind(this);
    this.setActionStatusForSpruceAction =
      this.setActionStatusForSpruceAction.bind(this);
    this.setItemStateByActionCode = this.setItemStateByActionCode.bind(this);
  }

  onMount() {
    this.getSingleActionItem();
  }

  private async getSingleActionItem() {
    this.setLoading(true);
    try {
      let response: any;
      if (this.isAccountLevelAction) {
        response = await getAccountLevelUserAction({
          tenantId: this.tenantId,
          actionCode: this.actionItem.actionCode,
        });
      } else {
        response = await getSingleUserAction({
          userId: this.userId,
          actionCode: this.actionItem.actionCode,
        });
      }
      const result = response.userActions || [];
      await this.setItemStateByActionCode(result);
      this.setLoading(false);
    } catch (error) {

      this.setLoading(false);
    }
  }

  private setLoading(value: boolean) {
    this.setState((prev) => {
      return {
        ...prev,
        loading: value,
      };
    });
  }

  private setNullObject() {
    this.setState((prev) => {
      return {
        ...prev,
        item: this.nullObject,
      };
    });
  }

  private setItemState(item: any) {
    this.setState((prev) => {
      return {
        ...prev,
        item: item,
      };
    });
  }

  private async setItemStateByActionCode(result: any) {
    const code = this.actionItem.actionCode;
    switch (code) {
      case USER_ACTION_CODES.HINT:
        await this.setActionStatusForHintAction();
        break;
      case USER_ACTION_CODES.SPRUCE:
        await this.setActionStatusForSpruceAction();
        break;
      case USER_ACTION_CODES.ELATION:
        await this.setActionStatusForElationAction();
        break;
      default:
        if (result?.length > 0) {
          this.setItemState(result[0]);
        } else {
          this.setNullObject();
        }
    }
  }

  private setActionStatus(value: string) {
    this.setState((prev) => {
      return {
        ...prev,
        item: {
          ...prev.item,
          status: value,
        },
      };
    });
  }

  private async setActionStatusForHintAction() {
    try {
      const response = await getHintPracticeDetails();
      this.setActionStatus('COMPLETED');
    } catch (error) {
      this.setState((prev) => {
        return {
          ...prev,
          item: {
            ...prev.item,
            status: '',
          },
        };
      });
    }
  }

  private async setActionStatusForElationAction() {
    try {
      const url = '/config/ehr-info';
      const response = await this.integrationService.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = response.data || {};
      if (data.isIntegrated) {
        this.setActionStatus('COMPLETED');
      } else {
        this.setActionStatus('');
      }
    } catch (error) {
      this.setActionStatus('');
    }
  }

  private async setActionStatusForSpruceAction() {
    try {
      const url = '/attachment/attachments';
      const response = await this.integrationService.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = response.data || [];
      if (data.length > 0) {
        this.setActionStatus('COMPLETED');
      } else {
        this.setActionStatus('');
      }
    } catch (error) {
      this.setActionStatus('');
    }
  }
}
