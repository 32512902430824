import {gql} from '@apollo/client';
import axios from 'axios';
import {CLOUD_TELEPHONY_SERVICE_URL} from '../../constants/Configs';
import BaseService from '../CommonService/BaseService';

export const CREATE_VIRTUAL_PHONE_NUMBER = gql`
  mutation CREATE_VIRTUAL_PHONE_NUMBER(
    $createVNObject: virtual_phone_number_assignees_insert_input!
  ) {
    createVirtualPhoneNumberAssignee(object: $createVNObject) {
      id
    }
  }
`;
export const GET_VIRTUAL_PHONE_NUMBER_BY_USER_LOCATIONS = gql`
  query GET_VIRTUAL_PHONE_NUMBER_BY_USER_LOCATIONS(
    $accountUuid: uuid!
    $assigneeIds: [uuid!]
    $searchString: String!
    $limit: Int
    $offset: Int
  ) {
    virtualPhoneNumberAssignees(
      order_by: {assignee: asc}
      limit: $limit
      offset: $offset
      where: {
        accountUuid: {_eq: $accountUuid}
        assigneeId: {_in: $assigneeIds}
        _or: [
          {assignee: {_ilike: $searchString}}
          {virtualPhoneNumber: {_ilike: $searchString}}
        ]
      }
    ) {
      id
      assignee
      assigneeId
      assigneeTypeId
      flowType
      flowTypeId
      flowTypeData {
        value
        code
      }
      virtualPhoneNumber
      updatedBy
      createdBy
      unavailabilityFlowId
      holidayPhoneTreeId
      isAnnouncementEnabled
    }
    virtualPhoneNumberAssigneeAggregate(
      where: {
        accountUuid: {_eq: $accountUuid}
        _or: [
          {assignee: {_ilike: $searchString}}
          {virtualPhoneNumber: {_ilike: $searchString}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const GET_VIRTUAL_PHONE_NUMBER = gql`
  query GET_VIRTUAL_PHONE_NUMBER($accountUuid: uuid!, $searchString: String!, $limit: Int,$offset: Int) {
    virtualPhoneNumberAssignees(
      order_by: {assignee: asc},
      limit: $limit,
      offset: $offset,
      where: {accountUuid: {_eq: $accountUuid}
      _or: [
        {
          assignee: {_ilike: $searchString}
        }
        {
          virtualPhoneNumber: {_ilike: $searchString}
        }
      ]
    }
    ) {
      id
      assignee
      assigneeId
      assigneeTypeId
      flowType
      flowTypeId
      flowTypeData {
        value
        code
      }
      virtualPhoneNumber
      updatedBy
      createdBy
      unavailabilityFlowId
      holidayPhoneTreeId
      isAnnouncementEnabled
    },
    virtualPhoneNumberAssigneeAggregate(where: {accountUuid: {_eq: $accountUuid}, _or: [{assignee: {_ilike: $searchString}}, {virtualPhoneNumber: {_ilike: $searchString}}]}) {
    aggregate {
      count
    }
  }
  }
`;

export const GET_USER_PRACTICE_LOCATION_BASED_VIRTUAL_PHONE_NUMBERS = gql`
  query GET_USER_PRACTICE_LOCATION_BASED_VIRTUAL_PHONE_NUMBERS(
    $accountUuid: uuid!
    $assigneeIds: [uuid!]
    $searchString: String!
    $limit: Int
    $offset: Int
  ) {
    virtualPhoneNumberAssignees(
      order_by: {assignee: asc}
      limit: $limit
      offset: $offset
      where: {
        accountUuid: {_eq: $accountUuid}
        assigneeId: {_in: $assigneeIds}
        _or: [
          {assignee: {_ilike: $searchString}}
          {virtualPhoneNumber: {_ilike: $searchString}}
        ]
      }
    ) {
      id
      assignee
      assigneeId
      assigneeTypeId
      flowType
      flowTypeId
      flowTypeData {
        value
        code
      }
      virtualPhoneNumber
      updatedBy
      createdBy
      unavailabilityFlowId
      holidayPhoneTreeId
    }
    virtualPhoneNumberAssigneeAggregate(
      where: {
        accountUuid: {_eq: $accountUuid}
        _or: [
          {assignee: {_ilike: $searchString}}
          {virtualPhoneNumber: {_ilike: $searchString}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_SINGLE_VIRTUAL_PHONE_NUMBER = gql`
  query GET_SINGLE_VIRTUAL_PHONE_NUMBER($uuid: uuid!) {
    virtualPhoneNumberAssignees(where: {id: {_eq: $uuid}}) {
      assignee
      assigneeId
      assigneeTypeId
      virtualPhoneNumber
      updatedBy
      createdBy
      id
      assigneeType {
        code
        value
      }
      flowType
      flowTypeId
      updatedAt
      createdAt
      flowTypeData {
        value
        code
      }
    }
  }
`;

export const UPDATE_VIRTUAL_NUMBER = gql`
  mutation UPDATE_VIRTUAL_NUMBER(
    $updateObject: virtual_phone_number_assignees_set_input!
    $id: uuid!
  ) {
    updateVirtualPhoneNumberAssignees(
      _set: $updateObject
      where: {id: {_eq: $id}}
    ) {
      returning {
        id
      }
    }
  }
`;
//TODO: looks like duplicate of above query, please remove
export const UPDATE_FLOW_FLOW = gql`
  mutation UPDATE_FLOW_FLOW(
    $updateObject: virtual_phone_number_assignees_set_input!
    $id: uuid!
  ) {
    updateVirtualPhoneNumberAssignees(
      _set: $updateObject
      where: {id: {_eq: $id}}
    ) {
      returning {
        id
      }
    }
  }
`;
export const DELETE_VIRTUAL_NUMBER = gql`
  mutation DELETE_VIRTUAL_NUMBER(
    $accountUuid: uuid!
    $virtualPhoneNumber: String!
  ) {
    updateVirtualPhoneNumberAssignees(
      where: {
        accountUuid: {_eq: $accountUuid}
        virtualPhoneNumber: {_eq: $virtualPhoneNumber}
      }
      _set: {isDeleted: true}
    ) {
      returning {
        id
      }
    }
  }
`;
export const GET_NUMBER_BY_ASSIGNEE_TYPE = gql`
  query GET_NUMBER_BY_ASSIGNEE_TYPE(
    $accountUuid: uuid!
    $filter_object: [virtual_phone_number_assignees_bool_exp!]!
  ) {
    virtualPhoneNumberAssignees(
      where: {
        accountUuid: {_eq: $accountUuid}
        isDeleted: {_eq: false}
        _or: $filter_object
      }
    ) {
      assignee
      assigneeId
      assigneeTypeId
      virtualPhoneNumber
      id
      assigneeType {
        code
        value
      }
    }
  }
`;
export const GET_NUMBER_CALL_SMS = gql`
  query GET_NUMBER_CALL_SMS(
    $accountUuid: uuid! # $filter_object: [virtual_phone_number_assignees_bool_exp!]!
  ) {
    virtualPhoneNumberAssignees(
      where: {
        accountUuid: {_eq: $accountUuid}
        isDeleted: {_eq: false}
        # _or: $filter_object
      }
    ) {
      assignee
      assigneeId
      assigneeTypeId
      virtualPhoneNumber
      id
      assigneeType {
        code
        value
      }
    }
  }
`;

export const SET_DEFAULT_NUMBER = gql`
  mutation SET_DEFAULT_NUMBER(
    $defaultCommObj: number_default_communication_insert_input!
  ) {
    createNumberDefaultCommunication(object: $defaultCommObj) {
      id
    }
  }
`;

export const CHECK_DEFAULT_NUMBER = gql`
  query CHECK_DEFAULT_NUMBER(
    $accountUuid: uuid!
    $defaultCommunicationTypeId: uuid!
    $tenantId: uuid!
  ) {
    numberDefaultCommunications(
      where: {
        accountUuid: {_eq: $accountUuid}
        defaultCommunicationTypeId: {_eq: $defaultCommunicationTypeId}
        tenant_id: {_eq: $tenantId}
      }
    ) {
      id
      virtualNumberAssignee {
        virtualPhoneNumber
      }
    }
  }
`;

export const DELETE_DEFAULT_NUMBER = gql`
  mutation DELETE_DEFAULT_NUMBER(
    $accountUuid: uuid!
    $defaultCommunicationTypeId: uuid!
    $tenantId: uuid!
  ) {
    updateNumberDefaultCommunications(
      where: {
        accountUuid: {_eq: $accountUuid}
        defaultCommunicationTypeId: {_eq: $defaultCommunicationTypeId}
        tenant_id: {_eq: $tenantId}
      }
      _set: {isDeleted: true}
    ) {
      returning {
        id
      }
    }
  }
`;

export const GET_DEFAULT_NUMBER = gql`
  query GET_DEFAULT_NUMBER(
    $virtualPhoneNumberAssigneeId: uuid!
    $accountUuid: uuid!
    $tenantId: uuid!
  ) {
    numberDefaultCommunications(
      where: {
        virtualPhoneNumberAssigneeId: {_eq: $virtualPhoneNumberAssigneeId}
        accountUuid: {_eq: $accountUuid}
        tenant_id: {_eq: $tenantId}
      }
    ) {
      id
      defaultCommunicationTypeId
    }
  }
`;

export const GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT = gql`
  query GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT($accountUuid: uuid!, $tenantId: uuid!) {
    numberDefaultCommunications(where: {accountUuid: {_eq: $accountUuid},tenant_id: {_eq: $tenantId}}) {
      virtualPhoneNumberAssigneeId
      defaultCommunicationTypeId
    }
  }
`;

export const GET_IVR_PHONE_NUMBERS = gql`
  query GET_IVR_PHONE_NUMBERS($workflowMasterId: uuid!) {
    flows(where: {workflowMasterId: {_eq: $workflowMasterId}, isDeleted: {_eq: false}, virtualPhoneNumberAssignees: {isDeleted: {_eq: false}}}) {
      virtualPhoneNumberAssignees {
        virtualPhoneNumber
      }
    }
  }
`;

export const GET_IVR_LIST = gql`
  query GET_IVR_LIST($accountId: uuid!) {
    flows(where: {accountId: {_eq: $accountId}, isDeleted: {_eq: false}}) {
      unavailabilityFlowTypeId,
      workflowMasterId,
      ivrUsageTypeId
    }
  }
`;

export const GET_IVR_BY_WORKFLOW_MASTER_ID = gql`
  query GET_IVR_BY_WORKFLOW_MASTER_ID($accountId: uuid!, $workflowMasterId: uuid!) {
    flows(where: {accountId: {_eq: $accountId}, isDeleted: {_eq: false}, workflowMasterId: {_eq: $workflowMasterId}}) {
      unavailabilityFlowTypeId,
      workflowMasterId,
      ivrUsageTypeId
    }
  }
`;

export const GET_DEFAULT_CALL_NUMBER = gql`
  query GET_DEFAULT_CALL_NUMBER(
    $accountUuid: uuid!
    $defaultCommunicationTypeId: uuid!
    $tenantId: uuid!
  ) {
    numberDefaultCommunications(
      where: {
        accountUuid: {_eq: $accountUuid}
        defaultCommunicationTypeId: {_eq: $defaultCommunicationTypeId}
        tenant_id: {_eq: $tenantId}
      }
    ) {
      id
      defaultCommunicationTypeId
      virtualNumberAssignee {
        virtualPhoneNumber
        assignee
      }
    }
  }
`;

export const GET_DEFAULT_OUTGOING_CALL_NUMBER = gql`
  query GET_DEFAULT_OUTGOING_CALL_NUMBER(
    $accountUuid: uuid!
    $defaultCommunicationTypeId: uuid!
    $tenantId: uuid!
  ) {
    numberDefaultCommunications(
      where: {
        accountUuid: {_eq: $accountUuid}
        defaultCommunicationTypeId: {_eq: $defaultCommunicationTypeId}
        tenant_id: {_eq: $tenantId}
      }
    ) {
      id
      defaultCommunicationTypeId
      virtualNumberAssignee {
        assignee
        assigneeId
        assigneeTypeId
        virtualPhoneNumber
        id
        disableOutgoingCalls
        assigneeType {
          code
          value
        }
      }
    }
  }
`;

export const GET_OUTGOING_CALL_LOCATION_NUMBERS = gql`
  query GET_OUTGOING_CALL_LOCATION_NUMBERS(
    $accountUuid: uuid!
    $assigneeIds: [uuid!]
  ) {
    virtualPhoneNumberAssignees(
      where: {
        accountUuid: {_eq: $accountUuid}
        isDeleted: {_eq: false}
        assigneeId: {_in: $assigneeIds}
      }
    ) {
      assignee
      assigneeId
      assigneeTypeId
      virtualPhoneNumber
      id
      disableOutgoingCalls
      assigneeType {
        code
        value
      }
    }
  }
`;

export const GET_AND_SEARCH_OUTGOING_CALL_LOCATION_NUMBERS = gql`
  query GET_OUTGOING_CALL_LOCATION_NUMBERS(
    $accountUuid: uuid!
    $assigneeIds: [uuid!]
    $searchString: String!
    $limit: Int
    $offset: Int
  ) {
    virtualPhoneNumberAssignees(
      where: {
        accountUuid: {_eq: $accountUuid}
        isDeleted: {_eq: false}
        assigneeId: {_in: $assigneeIds}
        _or: [
          {
            assignee: {_ilike: $searchString}
          }
          {
            virtualPhoneNumber: {_ilike: $searchString}
          }
        ]
      }
        limit: $limit,
        offset: $offset,
    ) {
      assignee
      assigneeId
      assigneeTypeId
      virtualPhoneNumber
      id
      disableOutgoingCalls
      assigneeType {
        code
        value
      }
    }
  }
`;

export const GET_OUTGOING_CALL_NUMBERS = gql`
  query GET_OUTGOING_CALL_NUMBERS(
    $accountUuid: uuid!
  ) {
    virtualPhoneNumberAssignees(
      where: {
        accountUuid: {_eq: $accountUuid}
        isDeleted: {_eq: false}
      }
    ) {
      assignee
      assigneeId
      assigneeTypeId
      virtualPhoneNumber
      id
      disableOutgoingCalls
      assigneeType {
        code
        value
      }
    }
  }
`;

export const CheckIfNumberPrimary = gql`
  query MyQuery($rank: Int , $value: String ) {
    personContacts(where: {value: {_eq: $value}, rank: {_eq: $rank}}) {
      id
      personContactId
      contact {
      contactConsents {
        id
      }
      name
    }
    }
  }
`;

export const GET_PHONE_NUMBERS_BY_LOCATION = gql`
  query GET_PHONE_NUMBERS_BY_LOCATION(
    $accountUuid: uuid!
    $assigneeId: uuid!
    $assigneeTypeId: uuid
  ) {
    virtualPhoneNumberAssignees(
      where: {
        accountUuid: {_eq: $accountUuid}
        assigneeId: {_eq: $assigneeId}
        assigneeTypeId: {_eq: $assigneeTypeId}
      }
    ) {
      id
      assigneeTypeId
      assigneeType {
        code
      }
      assignee
      virtualPhoneNumber
      numberDefaultCommunication {
        locationId
      }
    }
  }
`;
export const getDefaultPhoneNumberByLocation = async (reqParams: any) => {
  const {accountUuid, communicationTypeId} = reqParams;
  const axios = BaseService.getSharedInstance().axios;
  const requestBody:any = {
    accountUuid,
    communicationTypeId,
  };
  return await axios.get(
    `/cloud-telephony/default-communication/location/default-number`,
    {
      params: {
        ...requestBody,
      },
    }
  );
};

export const addOrUpdateDefaultNumberForLocation = async (reqBody: any) => {
  const axios = BaseService.getSharedInstance().axios;
  const body: any = reqBody
  return axios.post(
    '/cloud-telephony/default-communication/location/default-number',
    body
  );
};

export const deleteDefaultNumberForLocation = async (reqBody: any) => {
  const axios = BaseService.getSharedInstance().axios;
  const body: any = reqBody;
  return axios.delete(
    '/cloud-telephony/default-communication/location/default-number',
    {data: body}
  );
};

export const getSipPhoneCredentialList = async (params?: {
  limit: number,
  searchString: string,
}) => {
  const axios = BaseService.getSharedInstance().axios;
  return await axios.get(
    `/cloud-telephony/sip/phone-credential-list`,{
      params
    }
  );
};

export const resetDefaultNumberCachedResponse = async (reqBody: any) => {
  const axios = BaseService.getSharedInstance().axios;
  const body: any = reqBody
  return axios.post(
    '/cloud-telephony/default-communication/location/default-number/reset-cache',
    body
  );
}

export const getAccountLocationIdByPracticeLocationId = gql`
  query getAccountLocationIdByPracticeLocationId(
    $locationUuid: uuid!
    $accountUuid: uuid!
  ) {
    accountLocations(
      where: {
        locationUuid: {_eq: $locationUuid}
        accountUuid: {_eq: $accountUuid}
      }
    ) {
      uuid
    }
  }
`;

export const GET_VIRTUAL_PHONE_NUMBER_BY_ACCOUNT_UUID = gql`
  query GET_VIRTUAL_PHONE_NUMBER_BY_ACCOUNT_UUID(
    $accountUuid: uuid!
    $limit: Int
    $offset: Int
  ) {
    virtualPhoneNumberAssignees(
      order_by: {assignee: asc}
      limit: $limit
      offset: $offset
      where: {accountUuid: {_eq: $accountUuid}}
    ) {
      id
      assignee
      assigneeId
      assigneeTypeId
      flowType
      flowTypeId
      flowTypeData {
        value
        code
      }
      virtualPhoneNumber
      updatedBy
      createdBy
      unavailabilityFlowId
      holidayPhoneTreeId
    }
    virtualPhoneNumberAssigneeAggregate(
      where: {accountUuid: {_eq: $accountUuid}}
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_SINGLE_NUMBER_BY_VIRTUAL_NUMBER = gql`
  query GET_OUTGOING_CALL_NUMBERS(
    $accountUuid: uuid!
    $virtualPhoneNumber: String!
  ) {
    virtualPhoneNumberAssignees(
      where: {
        accountUuid: {_eq: $accountUuid}
        isDeleted: {_eq: false}
        virtualPhoneNumber: {_eq: $virtualPhoneNumber}
      }
    ) {
      assignee
      assigneeId
      assigneeTypeId
      virtualPhoneNumber
      id
      disableOutgoingCalls
      assigneeType {
        code
        value
      }
    }
  }
`;

export default {
  CREATE_VIRTUAL_PHONE_NUMBER,
  GET_VIRTUAL_PHONE_NUMBER,
  GET_USER_PRACTICE_LOCATION_BASED_VIRTUAL_PHONE_NUMBERS,
  GET_VIRTUAL_PHONE_NUMBER_BY_USER_LOCATIONS,
  UPDATE_VIRTUAL_NUMBER,
  DELETE_VIRTUAL_NUMBER,
  GET_SINGLE_VIRTUAL_PHONE_NUMBER,
  UPDATE_FLOW_FLOW,
  GET_NUMBER_BY_ASSIGNEE_TYPE,
  GET_NUMBER_CALL_SMS,
  SET_DEFAULT_NUMBER,
  CHECK_DEFAULT_NUMBER,
  DELETE_DEFAULT_NUMBER,
  GET_DEFAULT_NUMBER,
  GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT,
  GET_DEFAULT_CALL_NUMBER,
  GET_IVR_PHONE_NUMBERS,
  GET_IVR_LIST,
  GET_IVR_BY_WORKFLOW_MASTER_ID,
  CheckIfNumberPrimary,
  GET_OUTGOING_CALL_NUMBERS,
  GET_OUTGOING_CALL_LOCATION_NUMBERS,
  GET_DEFAULT_OUTGOING_CALL_NUMBER,
  GET_PHONE_NUMBERS_BY_LOCATION,
  getAccountLocationIdByPracticeLocationId,
  GET_VIRTUAL_PHONE_NUMBER_BY_ACCOUNT_UUID,
  GET_SINGLE_NUMBER_BY_VIRTUAL_NUMBER,
  GET_AND_SEARCH_OUTGOING_CALL_LOCATION_NUMBERS
};
