// Helper
// Views
import { useLazyQuery, useQuery } from '@apollo/client';
import { Skeleton, Table } from 'antd';
import { Box, useToast, View } from 'native-base';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import { JOURNEYS_TABLE_VIEW } from '../../../../../../constants/StringConst';
import { FlowType } from '../../../../../../context/WorkflowContext';
import {
  CareJourneyQueries, UserQueries
} from '../../../../../../services';
import { Colors } from '../../../../../../styles';
import { testID, TestIdentifiers } from '../../../../../../testUtils';
import { getAccountUUID } from '../../../../../../utils/commonUtils';
import { showToast, ToastType } from '../../../../../../utils/commonViewUtils';
import {
  ITableTopBarProps,
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES
} from '../../../../../common/TableTopBar';
import CareTeamFlow from '../../../../../PersonOmniView/LeftContainer/CareTeamDetails/CareTeamFlow';
import {
  deleteWorkflowById,
  getWorkflowList
} from '../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { IWorkflowData } from '../../../../Workflow/Workflow/WorkflowTableView/Interfaces';
import PatientCareJourneyDetail from '../../PatientCareJourneyDetail/PatientCareJourneyDetail';
import { TableSortOrders } from '../Enums';
// Styles
import { GET_CARE_TEAM } from '../../../../../../services/CareTeam/CareTeamQueries';
import { GET_USERS_WITH_IDS } from '../../../../../../services/User/UserQueries';
import { capitalizeText } from '../../../../../common/ContactRelationView/ContactRelationUtils';
import { IItemToDelete } from '../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowTriggerView/interfaces';
import { styles } from './ProgramsTableStyles';
import { getActionButtons, getColumns } from './ProgramTableHelper';

export interface ICareTeam {
  members: any[];
  primaryCarePhysician: string;
}

const ProgramsTable = (props: any) => {
  const intl = useIntl();
  const toast = useToast();
  const navigate = useNavigate();
  const accountUuid = getAccountUUID();
  const [accountUserList, setAccountUserList] = useState<any[]>([]);
  const [isDrawerVisible, updateDrawerState] = useState<boolean>(false);
  const [careTeam, setCareTeam] = useState<ICareTeam>({
    members: [],
    primaryCarePhysician: '',
  });
  const [journeyTableState, setJourneyTableState] = useState<{
    searchString: string;
    page: number;
    pageSize: number;
    loading: boolean;
    dataSource: any[];
    // Detail
    selectedJourney?: any;
  }>({
    searchString: '',
    page: 1,
    pageSize: 20,
    loading: true,
    dataSource: [],
  });
  const headers = {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  };

  useEffect(() => {
    if (props.view === JOURNEYS_TABLE_VIEW.PATIENT_JOURNEYS) {
      if (props.personData.patientUuid) {
        getPatientJourneys({
          variables: {
            patientId: props.personData.patientUuid,
          },
        });
      } else {
        setJourneyTableState((prev) => ({...prev, loading: false}));
        showToast(
          toast,
          'This contact is not registered as a patient',
          ToastType.info
        );
      }
    } else {
      getJourneyList();
    }
  }, []);
  // GET USER DETAILS FROM IDS
  const [getUserDetailData] = useLazyQuery(GET_USERS_WITH_IDS);
  const {loading: careTeamLoading} = useQuery(GET_CARE_TEAM, {
    ...headers,
    variables: {
      contactId: props.personData?.contactUUID,
    },
    onCompleted: (careT) => {
      getUserDetailData({
        variables: {
          userIds:
            careT?.careTeams[0]?.userPool?.userPoolUsers?.map(
              (user: any) => user.userId
            ) || [],
          accountId: accountUuid,
        },
        onCompleted: (data) => {
          setCareTeam({
            ...careTeam,
            members: data?.users,
            primaryCarePhysician:
              careT?.careTeams[0]?.primaryCarePhysicianUserId,
          });
        },
      });
    },
  });

  const [getPatientJourneys] = useLazyQuery(
    CareJourneyQueries.GET_PATIENT_CARE_JOURNEYS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (response) => {
        if (
          response &&
          response.patientCareJourneys &&
          response.patientCareJourneys.length > 0
        ) {
          setJourneyTableState((prev) => ({
            ...prev,
            dataSource: response.patientCareJourneys,
            loading: false,
          }));
        } else {
          setJourneyTableState((prev) => ({...prev, loading: false}));
        }
      },
      onError: (error: any) => {

        setJourneyTableState((prev) => ({...prev, loading: false}));
        showErrorToast();
      },
    }
  );

  const showErrorToast = () => {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  };

  const getJourneyList = () => {
    getWorkflowList(
      false,
      [],
      journeyTableState.searchString,
      FlowType.careJourney,
      accountUuid,
      (dataList: any) => {
        dataList = dataList || [];
        dataList = dataList.sort(function (data1: any, data2: any) {
          return data2.date - data1.date;
        });
        setJourneyTableState((prev) => ({
          ...prev,
          dataSource: dataList,
          loading: false,
        }));
      },
      (error: any) => {

        setJourneyTableState((prev) => ({...prev, loading: false}));
        showErrorToast();
      },false
    );
  };

  // const {loading, error, data} = useQuery(CareProgramQueries.GET_ALL_JOURNEYS, {
  //   fetchPolicy: 'no-cache',
  //   variables: {
  //     searchString: `%${searchString}%`,
  //     offset: (page - 1) * pageSize,
  //     limit: pageSize,
  //     orderBy: getSortByQuery(sortBy, sortOrder),
  //   },
  //   context: {
  //     service: CARESTUDIO_APOLLO_CONTEXT,
  //   },
  // });
  // let apiData = data;
  // if (error) {
  //   apiData = {
  //     carePrograms: [],
  //   };
  // }

  const getCustomerListTopBarButtons = (view: any): ITableTopBarProps => {
    let title = 'all';
    const showCareTeamButton = careTeam?.members?.length > 0;
    if (props.view === JOURNEYS_TABLE_VIEW.PATIENT_JOURNEYS) {
      title = props.personData.firstName + "'s Journeys";
    }
    return {
      title: title,
      buttonList: getActionButtons(
        view,
        onTableTopBarActionPerformed,
        props.personData,
        accountUserList,
        showCareTeamButton
      ),
    };
  };

  const onEdit = (record: IWorkflowData) => {
    navigate('/admin/journey/update' + '/' + record.id);
  };

  const onDelete = (record: IWorkflowData) => {
    setJourneyTableState((prev) => ({...prev, loading: true}));
    const itemToDelete: IItemToDelete = {
      id: record.id,
      workflowMasterId: record.workflowMasterId,
    }
    deleteWorkflowById(
      itemToDelete,
      () => {
        const dataSourceCopy = journeyTableState.dataSource.filter((data) => {
          return data.id != record.id;
        });
        setJourneyTableState((prev) => ({
          ...prev,
          dataSource: dataSourceCopy,
          loading: false,
        }));
      },
      (error: any) => {

        showToast(toast, intl.formatMessage({id: 'apiError'}), ToastType.error);
        setJourneyTableState((prev) => ({...prev, loading: false}));
      }
    );
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setJourneyTableState((prev) => ({
          ...prev,
          searchString: actionData.searchString,
        }));
        break;
      case TABLE_TOP_BAR_ACTION_CODES.NEW_JOURNEY:
        navigate('/admin/journey/create');
        break;
      case TABLE_TOP_BAR_ACTION_CODES.ASSIGN_JOURNEY:
        if (
          props.personData &&
          props.personData.id &&
          actionData.productId &&
          actionData.id
        ) {
          navigate(
            `/members/patient/${props.personData.id}/product/${actionData.productId}/assignJourney/${actionData.id}`,
            {
              state: {
                personData: props.personData,
              },
            }
          );
        } else {
        }
        break;
      case TABLE_TOP_BAR_ACTION_CODES.VIEW_CARE_TEAM:
        updateDrawerState(!isDrawerVisible);
        break;
      default:
    }
  };

  const onRowClicked = (careJourney: any) => {
    setJourneyTableState((prev) => ({...prev, selectedJourney: careJourney}));
  };

  const getAccountUsers = useQuery(UserQueries.GET_USERS_FOR_CALENDAR, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId: accountUuid,
    },
    onCompleted: (data: any) => {
      setAccountUserList(data.users);
    },
    onError: (error) => {

      setAccountUserList([]);
    },
  });

  return (
    <>
      <Box
        style={[styles.shadow, {marginHorizontal: 16}]}
        borderColor={Colors.Custom.BorderColor}
        borderWidth={1}
        overflow={'hidden'}
        rounded="lg"
      >
        {!getAccountUsers.loading &&
          !journeyTableState.loading &&
          !careTeamLoading && (
            <TableTopBar
              hideSearchBar={props.hideSearchBar}
              onActionPerformed={(actionCode: string, actionData: any) => {
                onTableTopBarActionPerformed(actionCode, actionData);
              }}
              {...getCustomerListTopBarButtons(props.view)}
            />
          )}
        {!journeyTableState.loading && (
          <Table
            columns={getColumns(
              TableSortOrders.ascend,
              intl,
              props.view,
              onEdit,
              onDelete
            )}
            rowClassName={(record, index) =>
              index % 2 == 0 ? 'table-row-light' : ''
            }
            rowKey={(row) => row.id}
            dataSource={journeyTableState.dataSource}
            onRow={(careJourney) => {
              return {
                onClick: () => {
                  onRowClicked(careJourney);
                },
              };
            }}
            pagination={{
              current: journeyTableState.page,
              pageSize: journeyTableState.pageSize,
              position: ['bottomCenter'],
              total: journeyTableState.dataSource.length,
              onChange: (currentPage, currentPageSize) => {
                setJourneyTableState((prev) => ({
                  ...prev,
                  page: currentPage,
                  pageSize: currentPageSize,
                }));
              },
            }}
          />
        )}
        {journeyTableState.loading && (
          <View
            style={styles.loadingView}
            {...testID(TestIdentifiers.pageLoading)}
          >
            <Skeleton active />
          </View>
        )}
      </Box>

      {/* Care journey detail view  */}
      <PatientCareJourneyDetail
        visible={!!journeyTableState.selectedJourney}
        patientCareJourney={journeyTableState.selectedJourney}
        onClose={() => {
          setJourneyTableState((prev) => ({
            ...prev,
            selectedJourney: undefined,
          }));
        }}
      />
      {isDrawerVisible && (
        <CareTeamFlow
          careTeam={careTeam}
          onClose={() => {
            updateDrawerState(!isDrawerVisible);
          }}
          personData={props.personData}
          userList={accountUserList || []}
        />
      )}
    </>
  );
};

export default ProgramsTable;
