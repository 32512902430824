import {Skeleton, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import CommonService from '../../../../services/CommonService/CommonService';
import PageBodyContainer from '../../../common/PageBodyContainer/PageBodyContainer';
import AttachmentDetails from './AttachmentDetails/AttachmentDetails';
import AccessForm from './ImportForm/AccessForm';
import {AttachmentDetailApiResponse} from './interfaces';
import {SpruceViewCodes} from './SpruceConfigConsts';

const SpruceConfig = () => {
  const [spruceConfigState, setSpruceConfigState] = useState({
    viewCode: SpruceViewCodes.ACCESS_FORM,
    attachmentData: [] as AttachmentDetailApiResponse[],
    loading: true,
  });

  const commonService = CommonService.getCommonServiceInstance();
  const integrationService = commonService.integrationService;
  const url = '/attachment/attachments';

  const {height} = Dimensions.get('window');

  const fetchSpruceAttachmentDetails = async () => {
    setSpruceConfigState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    try {
      integrationService
        .get(url, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((resp: {data: any}) => {
          if (resp?.data.length > 0) {
            setSpruceConfigState((prev) => {
              return {
                ...prev,
                loading: false,
                viewCode: SpruceViewCodes.ATTACHMENT_DETAIL,
                attachmentData: resp?.data,
              };
            });
          } else {
            setSpruceConfigState((prev) => {
              return {
                ...prev,
                viewCode: SpruceViewCodes.ACCESS_FORM,
                loading: false,
              };
            });
          }
        });
    } catch (error) {

      setSpruceConfigState((prev) => {
        return {
          ...prev,
          viewCode: SpruceViewCodes.ACCESS_FORM,
          loading: false,
        };
      });
    }
  };

  const getViewFromSelectedCode = () => {
    switch (spruceConfigState.viewCode) {
      case SpruceViewCodes.ACCESS_FORM:
      case SpruceViewCodes.ATTACHMENT_DETAIL:
        return (
          <View height={height - 180}>
            <PageBodyContainer>
              {spruceConfigState.loading ? (
                <Skeleton.Text lines={4} />
              ) : (
                <>
                  {spruceConfigState.viewCode ===
                    SpruceViewCodes.ACCESS_FORM && <AccessForm />}

                  {spruceConfigState.viewCode ===
                    SpruceViewCodes.ATTACHMENT_DETAIL && (
                    <AttachmentDetails
                      details={spruceConfigState.attachmentData}
                    />
                  )}
                </>
              )}
            </PageBodyContainer>
          </View>
        );
    }
  };

  useEffect(() => {
    fetchSpruceAttachmentDetails();
  }, []);

  return <>{getViewFromSelectedCode()}</>;
};

export default SpruceConfig;
