
import {Path, Svg} from 'react-native-svg';

const DuplicateSvgV2 = (props: {strokeColor?: string; size?: number;}) => {
  const strokeColor = props.strokeColor || "#8A94A8";
  const size = props?.size || 16;

  return (
      <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <Path d="M4 12.6668C2.89543 12.6668 2 11.7714 2 10.6668V6.66683C2 4.15267 2 2.89559 2.78105 2.11454C3.5621 1.3335 4.81918 1.3335 7.33333 1.3335H10C11.1046 1.3335 12 2.22893 12 3.3335M8 14.6668H10C11.8856 14.6668 12.8284 14.6668 13.4142 14.081C14 13.4953 14 12.5524 14 10.6668V7.3335C14 5.44788 14 4.50507 13.4142 3.91928C12.8284 3.3335 11.8856 3.3335 10 3.3335H8C6.11438 3.3335 5.17157 3.3335 4.58579 3.91928C4 4.50507 4 5.44788 4 7.3335V10.6668C4 12.5524 4 13.4953 4.58579 14.081C5.17157 14.6668 6.11438 14.6668 8 14.6668Z" 
        stroke={strokeColor} stroke-linecap="round"/>
      </Svg>
  );
};


export default DuplicateSvgV2;

