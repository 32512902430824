import React from 'react';
import {Text, View} from 'native-base';
import {styles} from './SkillsStyles';
import {ISkillsProps} from '.';
import {ComingSoon} from '../../../common/ComingSoon';

const Skills = (props: ISkillsProps) => {
  return (
    <View>
      <View style={styles.left}>
        <Text style={styles.leftText}>Skills</Text>
      </View>
      <View style={styles.container}>
        <View>
          <Text style={styles.textContainer}>{props.tempProp || ''}</Text>
        </View>
        <ComingSoon />
      </View>
    </View>
  );
};

export default Skills;
