import {
  Drawer,
  Pagination,
  PaginationProps,
  Skeleton,
  notification,
} from 'antd';
import {
  VStack,
  HStack,
  Text,
  Badge,
  Pressable,
  FlatList,
  useToast,
} from 'native-base';
import {BUTTON_TYPE, DEFAULT_PAGE_SIZE_OPTIONS, MODULE_PAGINATION_COUNT, getInActiveContactError} from '../../../../../constants';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../../../styles';
import {IRuleMemberData, SelectedTab} from './Hooks/interface';
import {useLazyQuery} from '@apollo/client';
import {GET_ALL_CONTACTS_BY_CONTACT_IDS} from '../../../../../services/Contacts/ContactsQueries';
import React, {useContext, useEffect, useState} from 'react';
import {View} from 'react-native';
import {testID} from '../../../../../testUtils';
import {COMPONENT_TEST_IDENTIFIER} from '../../../../../testUtils/ComponentTestIdentifiers';
import {IContact} from '../../../TeamInbox/Conversations/interfaces';
import MemberInfoListItem from '../../../../common/MemberInfoListItem/MemberInfoListItem';
import {Divider} from 'react-native-paper';
import Stack from '../../../../common/LayoutComponents/Stack';
import {isActiveContact} from '../../../../../utils/commonUtils';
import {getContactTypeId} from '../../../../../utils/mlovUtils';
import {isPatientDetailViewAllowed} from '../../../UserAccess/UserAccessPermission';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {useIntl} from 'react-intl';
import {ToastType, showToast} from '../../../../../utils/commonViewUtils';
import { TableWrapper } from '../../../../common/TableWrapper';

interface IGroupDetailMemberListDrawer {
  isDrawerVisible?: boolean;
  onCancel: () => void;
  auditLogData: IRuleMemberData;
}

const GroupDetailMemberListDrawer = (props: IGroupDetailMemberListDrawer) => {
  const {isDrawerVisible, auditLogData, onCancel} = props;

  const commonData = useContext(CommonDataContext);
  const isPatientDetailViewAllowedConfig = isPatientDetailViewAllowed(
    commonData.currentUserRoles,
    commonData.userPermissions
  );
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(
    SelectedTab.NEWLY_ADDED
  );
  const [newlyAddedContactData, setNewlyAddedContactData] = useState<
    IContact[]
  >([]);
  const [droppedOffContactData, setDroppedOffContactData] = useState<
    IContact[]
  >([]);

  const intl = useIntl();
  const toast = useToast();
  const [getContactsByUuids, {loading: contactDetailsLoading}] = useLazyQuery(
    GET_ALL_CONTACTS_BY_CONTACT_IDS,
    {
      fetchPolicy: 'no-cache',
      onError: (error) => {
        showToast(
          toast,
          'Error in fetching attachment patient/prospect for logs',
          ToastType.error,
          4000
        );
      },
    }
  );

  const getContactsData = async (code: SelectedTab, offset: number, limit: number) => {
    const contactIdsTofetch = 
      code == SelectedTab.NEWLY_ADDED
        ? auditLogData?.contactIdsAdded
        : auditLogData?.contactIdsDropped;

    if (!contactIdsTofetch || contactIdsTofetch?.length === 0) {
      return;
    }

    const {data} = await getContactsByUuids({
      variables: {
        offset: offset,
        limit: limit,
        contactIds: contactIdsTofetch,
      },
    });

    if (code === SelectedTab.NEWLY_ADDED) {
      setNewlyAddedContactData(data.contacts || []);
    } else {
      setDroppedOffContactData(data.contacts || []);
    }
  };

  useEffect(() => {
    getContactsData(SelectedTab.NEWLY_ADDED, 0, MODULE_PAGINATION_COUNT.PRODUCTS_LIST_PAGE_SIZE);
  }, []);

  const openContactInWindowOrOpenContactIdDrawer = (contactId?: string) => {
    if (!contactId) {
      return;
    }

    if (
      isPatientDetailViewAllowedConfig
    ) {
      window.open(`/#/members/patient/${contactId}`, '_blank', 'noopener,noreferrer');
    }
  };

  const customerTypeId = getContactTypeId('CUSTOMER');

  const redirectToProfile = (
    contactId?: number | string,
    contactTypeId?: string
  ) => {
    if (!contactId) {
      return;
    }
    if (contactTypeId === customerTypeId) {
      openContactInWindowOrOpenContactIdDrawer(contactId.toString());
    } else {
      window.open(`/#/members/prospect/${contactId}`, '_blank', 'noopener,noreferrer');
    }
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: MODULE_PAGINATION_COUNT.PRODUCTS_LIST_PAGE_SIZE,
      total: auditLogData?.membersAddedCount,
    },
  });

  const renderSingleActivityCard = ({item, index}: any): JSX.Element => {
    return (
      <Stack direction="column" key={item.id}>
        <View style={{marginBottom: 10, marginTop: 10}} key={item.id}>
          <MemberInfoListItem
            contactData={item}
            showRedirectIcon={true}
            onRedirectToProfile={() => {
              const isActive = isActiveContact(item);
              if (!isActive) {
                const message = getInActiveContactError(item);
                notification.error({
                  message,
                });
                return;
              }
              const contactId = item?.id;
              const contactTypeId = item?.contactType?.contactType?.id;
              redirectToProfile(contactId, contactTypeId);
            }}
          />
        </View>
        <Divider />
      </Stack>
    );
  };

  return (
    <>
      <Drawer
        title={
          <ModalActionTitle
            title={'Members'}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'done',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  onCancel();
                },
              },
            ]}
          />
        }
        open={isDrawerVisible}
        width={'37%'}
        closable={false}
        onClose={() => {
          onCancel();
        }}
      >
        <VStack height="full" overflow={'scroll'}>
          <HStack paddingBottom={2} marginBottom={2}>
            <Pressable
              onPress={() => {
                setSelectedTab(SelectedTab.NEWLY_ADDED);
                setTableParams((prev) => ({
                  ...prev,
                  pagination: {
                    ...prev.pagination,
                    total: auditLogData.membersAddedCount
                  },
                }));
                getContactsData(SelectedTab.NEWLY_ADDED, 0, tableParams.pagination.pageSize);
              }}
            >
              <VStack
                borderBottomWidth={
                  selectedTab === SelectedTab.NEWLY_ADDED ? 2 : 0
                }
                borderBottomColor={
                  selectedTab === SelectedTab.NEWLY_ADDED
                    ? Colors.Custom.PurpleColor
                    : Colors.Custom.BackgroundColor
                }
                marginRight={3}
              >
                <HStack alignItems={'center'} space={2} paddingRight={2}>
                  <Text
                    color={
                      selectedTab === SelectedTab.NEWLY_ADDED
                        ? Colors.Custom.PurpleColor
                        : Colors.Custom.Gray500
                    }
                    fontWeight={600}
                    paddingBottom={2}
                  >
                    {intl.formatMessage({id: 'newlyAdded'})}
                  </Text>
                  <Badge
                    fontSize={12}
                    fontWeight={600}
                    backgroundColor={Colors.Custom.Gray100}
                    _text={{
                      color: '#344054',
                    }}
                    alignSelf="center"
                    rounded="full"
                    justifyContent={'center'}
                    paddingX={2}
                    paddingY={1}
                    marginBottom={2}
                  >
                    {auditLogData?.membersAddedCount}
                  </Badge>
                </HStack>
              </VStack>
            </Pressable>
            <Pressable
              onPress={() => {
                setSelectedTab(SelectedTab.DROPPED_OFF);
                setTableParams((prev) => ({
                  ...prev,
                  pagination: {
                    ...prev.pagination,
                    total: auditLogData.membersDroppedCount
                  },
                }));
                  getContactsData(SelectedTab.DROPPED_OFF, 0, tableParams.pagination.pageSize);
              }}
            >
              <VStack
                color={
                  selectedTab === SelectedTab.DROPPED_OFF
                    ? '#825AC7'
                    : Colors.Custom.Gray500
                }
                borderBottomWidth={
                  selectedTab === SelectedTab.DROPPED_OFF ? 2 : 0
                }
                borderBottomColor={
                  selectedTab === SelectedTab.DROPPED_OFF
                    ? Colors.Custom.PurpleColor
                    : Colors.Custom.BackgroundColor
                }
              >
                <HStack alignItems={'center'} space={2}>
                  <Text
                    color={
                      selectedTab === SelectedTab.DROPPED_OFF
                        ? Colors.Custom.PurpleColor
                        : Colors.Custom.Gray500
                    }
                    fontWeight={600}
                    paddingBottom={2}
                  >
                    {intl.formatMessage({id: 'droppedOff'})}
                  </Text>
                  <Badge
                    fontSize={12}
                    fontWeight={600}
                    backgroundColor={Colors.Custom.Gray100}
                    _text={{
                      color: Colors.Custom.Gray700,
                    }}
                    alignSelf="center"
                    rounded="full"
                    justifyContent={'center'}
                    paddingX={2}
                    paddingY={1}
                    marginBottom={2}
                  >
                    {auditLogData?.membersDroppedCount}
                  </Badge>
                </HStack>
              </VStack>
            </Pressable>
          </HStack>
          {contactDetailsLoading ? (
            <Skeleton />
          ) : (
            <VStack maxHeight="85%">
              <TableWrapper
              wrapperStyle={{maxHeight: '70%'}}
                showDivierAbovePagination={false}
                pagination={{
                  ...tableParams.pagination,
                  showSizeChanger: true,
                  pageSizeOptions: [...DEFAULT_PAGE_SIZE_OPTIONS, MODULE_PAGINATION_COUNT.PRODUCTS_LIST_PAGE_SIZE].sort((a, b) => a - b),
                  onChange: (page, pageSize) => {
                    setTableParams((prev) => ({
                      ...prev,
                      pagination: {
                        ...prev.pagination,
                        current: page,
                        pageSize: pageSize,
                      },
                    }));
                    getContactsData(selectedTab, (page-1)*pageSize, pageSize);
                  },
                }}
              >
                <FlatList
                  {...testID(COMPONENT_TEST_IDENTIFIER.flatList)}
                  data={
                    selectedTab === SelectedTab.NEWLY_ADDED
                      ? newlyAddedContactData
                      : droppedOffContactData
                  }
                  maxToRenderPerBatch={50}
                  initialNumToRender={50}
                  keyExtractor={(item, index) => {
                    return 'msgBox_' + item;
                  }}
                  key={1}
                  renderItem={renderSingleActivityCard}
                />
              </TableWrapper>
            </VStack>
          )}
        </VStack>
      </Drawer>
    </>
  );
};

export default GroupDetailMemberListDrawer;
