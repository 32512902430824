import {View} from 'native-base';
import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {TagView} from '..';
import {MLOV_CATEGORY} from '../../../../../constants';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {IMlov} from '../../../../../Interfaces';
import {commonBodyContentContainer} from '../../../../../styles/CommonBodyContentStyle';
import {getMlovListFromCategory} from '../../../../../utils/mlovUtils';
import {capitalizeText} from '../../../../common/ContactRelationView/ContactRelationUtils';
import CustomTabsView from '../../../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../../../common/CustomTabsView/interfaces';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import {styles} from './TagsMenuStyles';

const TagsMenu = () => {
  const commonData = useContext(CommonDataContext);
  const labels = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );

  const tagsTabList = (): ITabsList[] => {
    const orderDetailTabView: ITabsList[] = labels.map((label: IMlov) => {
      return {
        key: label.value,
        title: capitalizeText(label.value),
        tabContainerElem: () => {
          return <TagView category={label} />;
        },
      };
    });
    return orderDetailTabView;
  };
  const navigate = useNavigate();
  const onHeaderBackClick = () => {
    navigate(-1);
  };
  return (
    <View>
      <View style={styles.titleWrapper}>
        <TitleSubtitleView
          titleLabelId="tagManagement"
          subtitleLabelId="tagManagementSubtitle"
          showBackButton={true}
          onBackClick={onHeaderBackClick}
        />
      </View>
      <View
        style={[
          commonBodyContentContainer.pageContentContainer,
          {paddingHorizontal: 16},
        ]}
      >
        <CustomTabsView
          tabsList={tagsTabList()}
          tabListWrapperStyle={{marginHorizontal: 8}}
        />
      </View>
    </View>
  );
};

export default TagsMenu;
