import React from "react";

const VisitsSavingsSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{width: 'inherit', height: 'inherit'}}
    fill="none"
    viewBox="0 0 24 25"
  >
    <g>
      <g
        stroke="#98A2B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M21 6.938a3.214 3.214 0 11-6.429 0 3.214 3.214 0 016.429 0zM13.286 16.26a4.821 4.821 0 014.821-4.822A2.893 2.893 0 0121 14.33v7.393h-7.714v-5.465zM3 6.938a3.214 3.214 0 106.429 0 3.214 3.214 0 00-6.429 0zM10.714 16.26a4.821 4.821 0 00-4.821-4.822A2.893 2.893 0 003 14.33v7.393h7.714v-5.465z"></path>
      </g>
    </g>
  </svg>
  );
}

export default React.memo(VisitsSavingsSvg);
