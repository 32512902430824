import {gql} from '@apollo/client';

const GetInboxAgentBots = gql`
  query QueryAgentBots($inboxId: Int!) {
    agentBotInboxes(where: {inboxId: {_eq: $inboxId}}) {
      id
      inboxId
      agentBotId
    }
  }
`;

export default {
  GetInboxAgentBots,
};
