import {Checkbox, View, Text, Pressable} from 'native-base';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../styles/Colors';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import EditPenSvg from '../../../common/Svg/EditPenSvg';
import EyeHideSvg from '../../../common/Svg/WidgetsSvg/EyeHideSvg';
import {useState} from 'react';

const LinkPhoneItem = (props: {
  linkedPhone: any;
  updateRingOnIncoming: (phoneId: string, value: boolean) => Promise<void>;
  getPasswordVisibleText: (text: string) => string;
}) => {
  const intl = useIntl();

  const [state, setState] = useState({showPassword: false} as {
    showPassword: boolean;
  });

  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: Colors.Custom.Gray200,
        width: '100%',
        borderRadius: 16,
        padding: 16,
        marginTop: 20,
      }}
    >
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <DisplayText
          size={'smSemibold'}
          textLocalId={props?.linkedPhone?.phoneName || ""}
        />
        <EditPenSvg width={18} height={18} />
      </View>
      <View style={{marginVertical: 10}}>
        <Checkbox
          value="ringOnIncoming"
          accessibilityLabel="This is a dummy checkbox"
          isChecked={props?.linkedPhone?.ringOnIncoming || ""}
          onChange={(ringOnIncoming) => {
            props.updateRingOnIncoming(props?.linkedPhone?.id || "", ringOnIncoming);
          }}
          _text={{
            color: 'black',
            fontSize: 12,
          }}
        >
          Ring Phone on Incoming call
        </Checkbox>
      </View>
      <View>
        <Text
          size={'smSemibold'}
          style={{textDecorationLine: 'underline', marginBottom: 8}}
        >
          {intl.formatMessage({id: 'phoneConfigurations'})}
        </Text>
        <View>
          <View style={{flexDirection: 'row', marginVertical: 6}}>
            <Text size={'smBold'}>{intl.formatMessage({id: 'sipUrl'})}: </Text>
            <Text size={'smSemibold'}>{props?.linkedPhone?.sipUri || ""}</Text>
          </View>
          <View style={{flexDirection: 'row', marginVertical: 6}}>
            <Text size={'smBold'}>
              {intl.formatMessage({id: 'userName'})}:{' '}
            </Text>
            <Text size={'smSemibold'}>{props?.linkedPhone?.username || ""}</Text>
          </View>
          <View style={{flexDirection: 'row', marginVertical: 6}}>
            <Text size={'smBold'}>
              {intl.formatMessage({id: 'passWord'})}:{' '}
            </Text>
            {state.showPassword ? (
              <Text style={{minWidth: 140}} size={'smSemibold'}>
                {props?.linkedPhone?.password || ""}
              </Text>
            ) : (
              <Text style={{minWidth: 140}} size={'smSemibold'}>
                {props.getPasswordVisibleText(props?.linkedPhone?.password || "")}
              </Text>
            )}
            <Pressable
              style={{marginLeft: 24}}
              onPress={() => {
                setState((prev) => {
                  return {
                    ...prev,
                    showPassword: !state.showPassword,
                  };
                });
              }}
            >
              <EyeHideSvg />
            </Pressable>
          </View>
        </View>
      </View>
      <View
        style={{
          padding: 3,
          backgroundColor: Colors.primary['300'],
          borderRadius: 6,
          paddingVertical: 8,
        }}
      >
        <Text color={'#fff'} size={'exsBold'}>
          Download Instructions for setup configurations on real IP Phone
        </Text>
      </View>
    </View>
  );
};

export default LinkPhoneItem;
