import {HStack, View} from 'native-base';
import React, {useState} from 'react';
import {Pressable} from 'react-native';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
} from '../../../../constants/ActionConst';
import {Colors} from '../../../../styles';
import {
  CHANNEL_TYPE_CODE,
  CONVERSATION_TAB_CODES,
} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {DisplayText} from '../../DisplayText/DisplayText';
import ActiveListitem from './ActiveListItem';
import ContactTypeFilterList from './ContactTypeFilterList';
import DateFilterList from './DateFilterList';
import InboxTypeFilter from './InboxTypeFilter';
import MessageTypeFilterList from './MessageTypeFilterList';
import {styles} from './OneViewFilterStyle';
import StatusFilterList from './StatusFilterList';

const OneViewFilter = (props: {
  onActionPerformed: (actionCode: string, data: any) => void;
  selectedMessageTabCode?: string;
}) => {
  const {onActionPerformed, selectedMessageTabCode} = props;
  const showstatueFilter =
    selectedMessageTabCode == CONVERSATION_TAB_CODES.PROSPECTS ? false : true;
  const showDateFilter = true;
  const showContactTypeFilter = true;
  const showInboxTypeFilter = false;
  const showMessageTypeFilter =
    selectedMessageTabCode == CONVERSATION_TAB_CODES.PROSPECTS ? false : true;
  const [selectedStatus, setSelectedStatus]: any = useState(null);
  const [selectedDate, setSelectedDate]: any = useState(null);
  const [selectedMessageType, setSelectedMessageType]: any = useState(null);
  const [selectedContactType, setSelectedContactType] = useState<any>();
  const [selectedInboxType, setSelectedInboxType] = useState<any>();

  const onFilterAction = (actionCode: any, data: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.STATUS_CHANGED:
        onActionPerformed(actionCode, data);
        break;
      case COMMON_ACTION_CODES.DATE_RANGED_CHANGED:
        onActionPerformed(actionCode, data.value);
        break;
      case COMMON_ACTION_CODES.MESSAGE_TYPE_CHANGE:
      case COMMON_ACTION_CODES.FILTER_CHANGED:
        onActionPerformed(actionCode, data);
        break;
      case COMMON_ACTION_CODES.INBOX_TYPE_CHANGE:
        onActionPerformed(actionCode, data);
        break;
    }
  };

  return (
    <View style={styles.mainWrapper}>
      {(selectedStatus ||
        selectedDate ||
        selectedMessageType ||
        selectedContactType ||
        selectedInboxType) && (
        <>
          <DisplayText
            size={'xsMedium'}
            extraStyles={{
              fontSize: 10,
              color: Colors.Custom.Gray500,
              marginBottom: 8,
              paddingHorizontal: 16,
            }}
            textLocalId="active"
          />
          <View style={{marginBottom: 8}}>
            {selectedStatus && (
              <ActiveListitem
                itemType={'STATUS_CHANGED'}
                selectedStaus={selectedStatus}
                oncallback={(type: any, data: any) => {
                  if (data.code === 'mentions') {
                    setSelectedStatus(null);
                    onActionPerformed(
                      CONVERSATION_ACTION_CODES.CONVERSATION_HEADER_BTN_CLICKED,
                      {code: 'ALL_CONVERSATIONS', name: 'allConversations'}
                    );
                  } else {
                    const clearStatus: any = {
                      showAll: true,
                      code: 'All',
                    };
                    onFilterAction(type, clearStatus);
                    setSelectedStatus(null);
                  }
                }}
              />
            )}
            {selectedMessageType && (
              <ActiveListitem
                itemType={'MESSAGE_TYPE_CHANGE'}
                selectedStaus={selectedMessageType}
                oncallback={(type: any, data: any) => {
                  const clearStatus: any = {
                    tabCode: 'ALL',
                    title: 'All',
                    code: 'all',
                  };
                  onFilterAction(type, clearStatus);
                  setSelectedMessageType(null);
                }}
              />
            )}
            {selectedDate && (
              <ActiveListitem
                itemType={'DATE_RANGED_CHANGED'}
                selectedDate={selectedDate}
                oncallback={(type: any, data: any) => {
                  const date = {
                    value: {
                      fromDate: '',
                      toDate: '',
                    },
                  };
                  onFilterAction(type, date);
                  setSelectedDate(null);
                }}
              />
            )}

            {selectedContactType && (
              <ActiveListitem
                itemType={COMMON_ACTION_CODES.FILTER_CHANGED}
                selectedContactType={selectedContactType}
                oncallback={(type: any, data: any) => {
                  const contactTypeData = {
                    id: null,
                    code: '',
                    displayNameId: '',
                  };
                  onFilterAction(type, contactTypeData);
                  setSelectedContactType(null);
                }}
              />
            )}
            {selectedInboxType && (
              <ActiveListitem
                itemType={COMMON_ACTION_CODES.INBOX_TYPE_CHANGE}
                selectedInboxType={selectedInboxType}
                oncallback={(type: any, data: any) => {
                  const contactTypeData = {
                    id: 1,
                    code: CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET,
                    displayNameId: 'Chats',
                  };
                  onFilterAction(type, contactTypeData);
                  setSelectedInboxType(null);
                }}
              />
            )}
          </View>
        </>
      )}

      <HStack my={2}>
        <View
          alignSelf={'center'}
          style={{
            width: 6,
            height: 6,
            borderRadius: 50,
            backgroundColor: 'green',
            marginRight: 10,
          }}
        ></View>
        <View>
          <Pressable
            onPress={() => {
              setSelectedStatus({code: 'mentions'});
              onActionPerformed(
                CONVERSATION_ACTION_CODES.CONVERSATION_HEADER_BTN_CLICKED,
                {code: 'MENTIONS', name: 'mentions'}
              );
            }}
          >
            <DisplayText
              size={'xsMedium'}
              extraStyles={{color: Colors.Custom.Gray700}}
              textLocalId={'mentions'}
            />
          </Pressable>
        </View>
      </HStack>
      <View style={styles.actionListWrapper}>
        {showInboxTypeFilter && (
          <InboxTypeFilter
            onTypeSelected={(typeData) => {
              setSelectedInboxType(typeData);
              onActionPerformed(
                COMMON_ACTION_CODES.INBOX_TYPE_CHANGE,
                typeData
              );
            }}
          />
        )}
        {showstatueFilter && (
          <StatusFilterList
            onStatusChange={(data: any) => {
              setSelectedStatus(data);
              onFilterAction(COMMON_ACTION_CODES.STATUS_CHANGED, data);
            }}
          />
        )}
        {showDateFilter && (
          <DateFilterList
            onDateSelcted={(data: any) => {
              setSelectedDate(data);
              onFilterAction(COMMON_ACTION_CODES.DATE_RANGED_CHANGED, data);
            }}
          />
        )}
        {showMessageTypeFilter && (
          <MessageTypeFilterList
            onStatusChange={(data: any) => {
              setSelectedMessageType(data);
              onFilterAction(COMMON_ACTION_CODES.MESSAGE_TYPE_CHANGE, data);
            }}
          />
        )}
        {showContactTypeFilter && (
          <ContactTypeFilterList
            onTypeSelected={(typeData) => {
              setSelectedContactType(typeData);
              onActionPerformed(COMMON_ACTION_CODES.FILTER_CHANGED, typeData);
            }}
          />
        )}
      </View>
    </View>
  );
};

export default OneViewFilter;
