import React, {useState} from 'react';
import {Modal, Typography} from 'antd';
import ButtonStyled from '../../ButtonStyled';
import CreateCampaignModal from '../../CreateCampaignModal';
import CreateOpportunityModal from '../../CreateOpportunityModal';
import DisplayArea from '../../DisplayAreaChar';
import DisplayProgressBar from '../../DisplayProgressBar';
import DisplayCard from '../../DisplayCard';
import ListArea from '../../../widgets/components/ListArea';

const {Text} = Typography;

const columnsList = [
  {
    title: 'Status',
    dataIndex: 'group',
    render: (text: string) => (text ? text : 'No Data'),
  },
  {
    title: 'Number of Members',
    dataIndex: 'members',
    render: (text: string, item: any) => (
      <DisplayProgressBar percent={item.percentage} text={text} />
    ),
  },
];

export default function ExpenseTable({
  loading,
  data,
}: {
  loading: boolean;
  data: any;
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalCampaign, setIsModalCampaign] = useState(false);
  const [isModalOpportunity, setIsModalOpportunity] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    group: '',
    members: 0,
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModalCampaign = () => {
    setIsModalCampaign(true);
    handleCancel();
  };

  const handleCancelCampaign = () => {
    setIsModalCampaign(false);
  };

  const showModalOpportunity = () => {
    setIsModalOpportunity(true);
    handleCancel();
  };

  const handleCancelOpportunity = () => {
    setIsModalOpportunity(false);
  };

  return (
    <>
      <DisplayCard
        title="Expense Pareto Curves"
        subtitle="Group of most cost intensive patients in the panel"
        loading={loading}
      >
        <ListArea
          data={data}
          columns={columnsList}
          onClick={(e: any) => {
            const defaultPayload = {
              ...e,
              source: 'Expense Pareto Curves',
            };
            setSelectedItem(defaultPayload);
            showModal();
          }}
        />
      </DisplayCard>
      <Modal
        title={selectedItem?.group}
        width={345}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Text style={{color: '#667085', marginBottom: '16px'}}>
          {selectedItem?.members} members
        </Text>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            padding: '10px',
          }}
        >
          <ButtonStyled
            className="primaryFilledBtn"
            shape="round"
            size="large"
            onClick={showModalOpportunity}
          >
            Opportunity
          </ButtonStyled>
          <ButtonStyled
            className="primaryFilledBtn"
            shape="round"
            size="large"
            onClick={showModalCampaign}
          >
            Campaign
          </ButtonStyled>
        </div>
      </Modal>
      <CreateCampaignModal
        visible={isModalCampaign}
        handleCancel={handleCancelCampaign}
        selectedItem={selectedItem}
      />
      <CreateOpportunityModal
        visible={isModalOpportunity}
        handleCancel={handleCancelOpportunity}
        selectedItem={selectedItem}
      />
    </>
  );
}
