import {Path, Svg} from 'react-native-svg';

const HorizontalEllipsisSvg = (props: {strokeColor?: string; size?: number;}) => {
  
  const strokeColor = props.strokeColor || "#8A94A8";
  const size = props?.size || 16;
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" >
    <Path d="M4.66667 7.99984C4.66667 8.73622 4.06971 9.33317 3.33333 9.33317C2.59695 9.33317 2 8.73622 2 7.99984C2 7.26346 2.59695 6.6665 3.33333 6.6665C4.06971 6.6665 4.66667 7.26346 4.66667 7.99984Z" 
      stroke={strokeColor} stroke-linecap="round"/>
    <Path d="M9.33333 7.99984C9.33333 8.73622 8.73638 9.33317 8 9.33317C7.26362 9.33317 6.66667 8.73622 6.66667 7.99984C6.66667 7.26346 7.26362 6.6665 8 6.6665C8.73638 6.6665 9.33333 7.26346 9.33333 7.99984Z" 
      stroke={strokeColor} stroke-linecap="round"/>
    <Path d="M14 7.99984C14 8.73622 13.403 9.33317 12.6667 9.33317C11.9303 9.33317 11.3333 8.73622 11.3333 7.99984C11.3333 7.26346 11.9303 6.6665 12.6667 6.6665C13.403 6.6665 14 7.26346 14 7.99984Z" 
      stroke={strokeColor} stroke-linecap="round"/>
    </Svg>

  );
};

export default HorizontalEllipsisSvg;

