import React, { useState, useEffect } from 'react';
import { ITaskContext, TaskContext } from "../context/TaskContext";
import { useLazyQuery } from '@apollo/client';
import { GET_TASK_POOLS_WITHOUT_AGGREGATE, GetUsersTaskAccess, GetUsersTaskPoolAccess } from '../services/TaskPool/TaskPoolQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../constants/Configs';
import { GET_SEARCH_USERS_WITHOUT_AGGREGATE } from '../services/User/UserQueries';
import { getMlovId } from '../utils/mlovUtils';
import { CommonDataContext } from '../context/CommonDataContext';
import { USER_ROLE_CODES } from '../constants/MlovConst';
import { ILoginUserData, IUser } from '../Interfaces';
import { IUserPool, IUserPoolUser } from '../components/common/CareDashboard/CareDashboardWidget/UserAutoComplete';
import { GetMyPracticeLocations } from '../services/Location/UserPracticeLocationQueries';
import { IUserPracticeLocation } from '../services/Location/interfaces';
import { GetUserPreferencesByTypeId } from '../services/UserPreferences/UserPreferencesQueries';
import { v4 as uuidv4 } from 'uuid';

export const TaskDataProvider = ({ children }: { children: React.ReactNode }) => {
    const commonData = React.useContext(CommonDataContext);
    const [taskdata, setTaskData] = useState<ITaskContext>({
        taskPools: [],
        taskAccessUsers: [],
        manageAccessUsersTaskPools: [],
        taskContextDataLoading: true,
        taskMasterUsers: [],
        loginUserLocations: [],
        taskPoolsLoading: true
    });
    const userData = commonData?.userData || ({} as ILoginUserData);
    const currentUserUUID = userData.uuid || '';
    const accessUserTypeId = getMlovId(commonData.CARE_STUDIO_MLOV, 'UserPoolType', 'access_user_task');
    const taskPoolTypeId = getMlovId(commonData.CARE_STUDIO_MLOV, 'UserPoolType', 'task_user_pool');
    const userPreferencesTypeId = getMlovId(commonData.CARE_STUDIO_MLOV, 'UserPreferencesType', 'user_task_filters');

    // fetch users whos task can be accessed by the login user
    const [getTaskAccessUsers] = useLazyQuery(GetUsersTaskPoolAccess, {
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        fetchPolicy: 'no-cache',
        variables: {
            userId: currentUserUUID,
            userPoolTypeId: accessUserTypeId,
        }
    });

    // fetch task account task pools
    const [getTaskPools] = useLazyQuery(GET_TASK_POOLS_WITHOUT_AGGREGATE, {
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        fetchPolicy: 'no-cache',
        variables: { params: { userPoolTypeId: taskPoolTypeId } },
    });

    // fetch account users
    const [getAccountUsers] = useLazyQuery(GET_SEARCH_USERS_WITHOUT_AGGREGATE, {
        fetchPolicy: 'no-cache',
        variables: {
            params: {
                isActive: true,
                userRoleCodesNotIn: [USER_ROLE_CODES.EMPLOYER, USER_ROLE_CODES.WORFLOW_USER, USER_ROLE_CODES.WORKFLOW]
            }
        },
    });


    // fetch users who can access the pools
    const [getAcessUsersTaskPools] = useLazyQuery(GetUsersTaskAccess, {
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        fetchPolicy: 'no-cache',
    });

    const [getUserPracticeLocation] = useLazyQuery(
        GetMyPracticeLocations,
        {
          fetchPolicy: 'no-cache',
          variables: {
            userUuid: currentUserUUID,
          },
        }
      );
    
      const [getUserTaskPreferences] = useLazyQuery(GetUserPreferencesByTypeId, {
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
        fetchPolicy: 'no-cache',
        variables: {
          userId: currentUserUUID,
          userPreferencesTypeId: userPreferencesTypeId,
        },
      });

    const fetchInitialData = async () => {
        try {
            let taskPools: IUserPool[] = [];
            let accountUsers: IUser[] = [];
            let manageTaskAccessUsers: any[] = [];
            let manageUsersAccessTaskPools: any[] = [];
            let loginUserLocations: IUserPracticeLocation[] = [];
            const promiseList = [
                getTaskPools(),
                getAccountUsers(),
                getTaskAccessUsers(),
                getUserPracticeLocation(),
                getUserTaskPreferences(),
            ]
            const responseData = await Promise.all(promiseList);
            (responseData || []).forEach((response) => {
                if (response?.data?.getUserPools?.userPools?.length) {
                    taskPools = response?.data?.getUserPools?.userPools;
                }
                if (response?.data?.searchUsers?.users?.length) {
                    accountUsers = response?.data?.searchUsers?.users;
                }
                if (response?.data?.userPoolAccesses?.length) {
                    manageTaskAccessUsers = response?.data?.userPoolAccesses;
                    if (manageTaskAccessUsers?.length) {
                        const userPoolUsers = manageTaskAccessUsers?.[0]?.user_pool?.userPoolUsers || [];
                        const userIds = userPoolUsers.map((user: any) => user?.userId);
                        if (!userIds?.includes(currentUserUUID)) {
                            userPoolUsers.push({
                                id: uuidv4(),
                                isDefault: false,
                                isDeleted: false,
                                userId: currentUserUUID
                            });
                        }
                    }
                }
                if (response?.data?.userPracticeLocations?.length) {
                    loginUserLocations = response?.data?.userPracticeLocations;
                }

                if (response?.data?.userPreferences?.length) {
                    const selectedUserPreference = response?.data?.userPreferences;
                    if (selectedUserPreference?.length) {
                        sessionStorage.setItem('userPreferencesData',  JSON.stringify(selectedUserPreference));
                    }
                }

            });
            if (!manageTaskAccessUsers?.length) {
                // add default manage task access user
                manageTaskAccessUsers = [{
                    id: uuidv4(),
                    userId: currentUserUUID,
                    user_pool: {
                        id: uuidv4(),
                        name: 'Manage Task Access',
                        typeId: accessUserTypeId,
                        userPoolUsers: [
                            {
                                id: uuidv4(),
                                isDefault: false,
                                isDeleted: false,
                                userId: currentUserUUID
                            }
                        ]
                    }
                }]
            }
            setTaskData((prevTaskData) => ({
                ...prevTaskData,
                taskPools: taskPools,
                taskAccessUsers: manageTaskAccessUsers,
                taskContextDataLoading: false,
                taskMasterUsers: accountUsers,
                loginUserLocations: loginUserLocations
            }));
            if (manageTaskAccessUsers?.length) {
                const userPoolUsers = manageTaskAccessUsers?.[0]?.user_pool?.userPoolUsers || [];
                const userIds = userPoolUsers.map((user: any) => user?.userId);
                if (!userIds?.includes(currentUserUUID)) {
                    userIds.push(currentUserUUID);
                }
                const manageUsersAccessTaskPoolsResponse = await getAcessUsersTaskPools({
                    variables: {
                        userId: userIds,
                        userPoolTypeId: taskPoolTypeId,
                    },
                });
                if (manageUsersAccessTaskPoolsResponse?.data?.userPoolUsers?.length) {
                    manageUsersAccessTaskPools = manageUsersAccessTaskPoolsResponse?.data?.userPoolUsers;
                }
                setTaskData((prevTaskData) => ({
                    ...prevTaskData,
                    manageAccessUsersTaskPools: manageUsersAccessTaskPools,
                    taskPoolsLoading: false
                }));
            }
        } catch (error) {
            setTaskData((prevTaskData) => ({
                ...prevTaskData,
                taskPools: [],
                taskAccessUsers: [],
                manageAccessUsersTaskPools: [],
                taskMasterUsers: [],
                loginUserLocations: [],
                taskPoolsLoading: false
            }));
        }
    }

    // This effect will only run once when the Provider is mounted
    useEffect(() => {
        fetchInitialData();
    }, []);


    return (
        <TaskContext.Provider value={{...taskdata}} key={`task-context-provider-${currentUserUUID}`}>
            {children}
        </TaskContext.Provider>
    );
}

export default TaskDataProvider;