import {Checkbox, Popover} from 'antd';
import {Text, View} from 'native-base';
import {useEffect, useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../styles';
import {IMlov} from '../../Journeys/JourneysOfCare/Table/Interfaces';
import {IPriorityDropDown} from './Interfaces';
import {styles} from './style';

const TaskPriorityDropDown = (props: IPriorityDropDown) => {
  const {taskPriorityMlovs, selectedTaskPriorityList, onPriorityChange} = props;
  const [taskPriority, setTaskPriority] = useState({
    isOpen: false,
    taskPrioritySelectedValues: [] as IMlov[],
    selectedPriorityValues: '',
  });

  const handleOpenChange = (newOpen: boolean) => {
    setTaskPriority((prev) => {
      return {
        ...prev,
        isOpen: newOpen,
      };
    });
  };

  useEffect(() => {
    if (selectedTaskPriorityList?.length === 0) {
      setTaskPriority((prev) => {
        return {
          ...prev,
          isOpen: false,
          taskPrioritySelectedValues: [] as IMlov[],
          selectedPriorityValues: '',
        };
      });
      return;
    }
    let selectedPriorityValues = '';
    let taskPrioritySelectedValues = taskPriority?.taskPrioritySelectedValues;
    if (!taskPrioritySelectedValues?.length) {
      taskPrioritySelectedValues = taskPriorityMlovs.filter((item) =>
        selectedTaskPriorityList.includes(item.id)
      );
    }
    if (taskPrioritySelectedValues?.length === 1) {
      selectedPriorityValues = `: ${taskPrioritySelectedValues?.[0]?.value?.toString()}`;
    } else if (taskPrioritySelectedValues?.length > 1) {
      selectedPriorityValues = `: ${taskPrioritySelectedValues?.[0]?.value?.toString()} • + ${
        taskPrioritySelectedValues?.length - 1
      }`;
    }
    setTaskPriority((prev) => {
      return {
        ...prev,
        selectedPriorityValues: selectedPriorityValues,
      };
    });
  }, [selectedTaskPriorityList?.length]);
  
  return (
    <View style={styles.checkBoxDropDownContainer}>
      <Popover
        content={
          <View>
            <Checkbox.Group
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              value={selectedTaskPriorityList}
              defaultValue={selectedTaskPriorityList}
              onChange={(values: any) => {
                onPriorityChange(values);
                const updatedValues = taskPriorityMlovs?.filter((item: any) =>
                  values.includes(item?.id)
                );
                setTaskPriority((prev) => {
                  return {
                    ...prev,
                    taskPrioritySelectedValues: updatedValues,
                  };
                });
              }}
            >
              {taskPriorityMlovs?.map((option: IMlov) => (
                <Checkbox
                  style={{
                    margin: 5,
                    fontWeight: 500,
                    color: '#3A485F',
                    fontSize: 15,
                  }}
                  key={option?.id}
                  value={option?.id}
                >
                  {option?.value}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </View>
        }
        placement="bottom"
        trigger="click"
        open={taskPriority?.isOpen}
        onOpenChange={handleOpenChange}
      >
        <Text cursor={'pointer'}>
          Priority <Text fontWeight={'bold'}>{taskPriority.selectedPriorityValues}</Text> 
        </Text>
        <AntIcon
          name="down"
          color={Colors.Custom.Gray600}
          size={12}
          style={{marginLeft: 4}}
        />
      </Popover>
    </View>
  );
};

export default TaskPriorityDropDown;
