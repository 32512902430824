import {HolidayFilterInterface} from './Interfaces';

export const HolidayFilterAction = [
  {
    label: 'Current Year',
    value: 'Current Year',
    key: 'currentYear',
  },
  {
    label: 'All',
    value: 'All',
    key: 'all',
  },
  // {
  //   label: 'Upcoming Holidays',
  //   value: 'Upcoming Holidays',
  //   key: 'upcoming_holidays',
  // },
  // {
  //   label: 'Past Holidays',
  //   value: 'Past Holidays',
  //   key: 'past_holydays',
  // },
] as HolidayFilterInterface[];

export const HolidayActions = {
  ADD_NEW: 'ADD_NEW',
  APPLY_FILTER: 'APPLY_FILTER',
  EDIT_HOLIDAY: 'EDIT_HOLIDAY',
  DELETE_HOLIDAY: 'DELETE_HOLIDAY',
  COPY_HOLIDAY: 'COPY_HOLIDAY',
  ADD_OR_EDIT: 'ADD_OR_EDIT',
};
