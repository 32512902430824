import React from 'react';
import {isWeb} from '../../../../utils/platformCheckUtils';
import { Image } from 'react-native';

const NoConversationFoundSvg = () => {
  return (
    <>
      {isWeb() ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="247"
          height="144"
          fill="none"
          viewBox="0 0 247 144"
        >
          <path
            fill="#F2F4F7"
            d="M218.72 33.984a6.403 6.403 0 00-6.403-6.404H61.299a6.404 6.404 0 00-6.404 6.404v40.618a6.404 6.404 0 006.341 6.403l75.572.739 40.956.4 20.478.2 7.785.076c1.625.016 3.237.297 4.772.832l6.503 2.266a1.067 1.067 0 001.418-1.007V33.984z"
          ></path>
          <rect
            width="75.776"
            height="4.803"
            fill="#D0D5DD"
            rx="2.401"
            transform="matrix(-1 0 0 1 142.41 39.054)"
          ></rect>
          <g filter="url(#filter0_d_775_16958)">
            <path
              fill="#fff"
              d="M25.811 63.066a6.404 6.404 0 016.404-6.404h151.018a6.404 6.404 0 016.404 6.404v40.618a6.403 6.403 0 01-6.341 6.403l-75.572.739-40.956.4-20.478.2-7.785.076a14.942 14.942 0 00-4.772.832L27.23 114.6a1.067 1.067 0 01-1.419-1.008V63.066z"
            ></path>
            <rect
              width="75.776"
              height="4.803"
              x="37.818"
              y="68.136"
              fill="#D0D5DD"
              rx="2.401"
            ></rect>
            <rect
              width="128.072"
              height="4.803"
              x="37.818"
              y="81.476"
              fill="#E3E6EB"
              rx="2.401"
            ></rect>
            <rect
              width="53.363"
              height="4.803"
              x="37.818"
              y="94.817"
              fill="#E3E6EB"
              rx="2.401"
            ></rect>
          </g>
          <circle cx="211.782" cy="105.226" r="4.002" fill="#D0D5DD"></circle>
          <circle cx="21.81" cy="45.458" r="4.002" fill="#D0D5DD"></circle>
          <circle
            cx="50.626"
            cy="7.037"
            r="3.469"
            stroke="#D0D5DD"
            strokeWidth="1.067"
          ></circle>
          <g clipPath="url(#clip0_775_16958)">
            <path
              fill="#D0D5DD"
              d="M117.063 9.438h-3.202v3.202h-1.067V9.438h-3.202V8.37h3.202V5.169h1.067V8.37h3.202v1.067z"
            ></path>
          </g>
          <g clipPath="url(#clip1_775_16958)">
            <path
              fill="#D0D5DD"
              d="M15.138 93.753h-3.201v3.202h-1.068v-3.202H7.667v-1.067h3.202v-3.202h1.068v3.202h3.201v1.067z"
            ></path>
          </g>
          <g filter="url(#filter1_d_775_16958)">
            <circle cx="218.529" cy="27.583" r="19.058" fill="#fff"></circle>
          </g>
          <path
            stroke="#98A2B3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M224.5 22.5l-11 11m0-11l11 11"
          ></path>
          <g clipPath="url(#clip2_775_16958)">
            <path
              fill="#D0D5DD"
              d="M240.103 68.824h-3.202v3.202h-1.067v-3.202h-3.202v-1.067h3.202v-3.202h1.067v3.201h3.202v1.068z"
            ></path>
          </g>
          <circle
            cx="179.078"
            cy="5.666"
            r="3.469"
            stroke="#D0D5DD"
            strokeWidth="1.067"
          ></circle>
          <defs>
            <filter
              id="filter0_d_775_16958"
              width="208.651"
              height="103.259"
              x="0.197"
              y="40.653"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset dx="-3.202" dy="6.404"></feOffset>
              <feGaussianBlur stdDeviation="11.206"></feGaussianBlur>
              <feComposite in2="hardAlpha" operator="out"></feComposite>
              <feColorMatrix values="0 0 0 0 0.596078 0 0 0 0 0.635294 0 0 0 0 0.701961 0 0 0 0.6 0"></feColorMatrix>
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_775_16958"
              ></feBlend>
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_775_16958"
                result="shape"
              ></feBlend>
            </filter>
            <filter
              id="filter1_d_775_16958"
              width="56.413"
              height="56.413"
              x="190.323"
              y="2.426"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset dy="3.049"></feOffset>
              <feGaussianBlur stdDeviation="4.574"></feGaussianBlur>
              <feComposite in2="hardAlpha" operator="out"></feComposite>
              <feColorMatrix values="0 0 0 0 0.596373 0 0 0 0 0.635165 0 0 0 0 0.701667 0 0 0 0.6 0"></feColorMatrix>
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_775_16958"
              ></feBlend>
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_775_16958"
                result="shape"
              ></feBlend>
            </filter>
            <clipPath id="clip0_775_16958">
              <path
                fill="#fff"
                d="M0 0H12.807V12.807H0z"
                transform="translate(106.923 2.502)"
              ></path>
            </clipPath>
            <clipPath id="clip1_775_16958">
              <path
                fill="#fff"
                d="M0 0H12.807V12.807H0z"
                transform="translate(5 86.814)"
              ></path>
            </clipPath>
            <clipPath id="clip2_775_16958">
              <path
                fill="#fff"
                d="M0 0H12.807V12.807H0z"
                transform="translate(229.964 61.888)"
              ></path>
            </clipPath>
          </defs>
        </svg>
      ) : (
        <Image
          style={{
            width: 240,
            height: 130,
          }}
          resizeMode='stretch'
          source={require('../../../../assets/images/Conversations/NoConversationFound.png')}
          // alt={'aa'}
        />
      )}
    </>
  );
};

export default NoConversationFoundSvg;
