import { AddCustomContactTypeStatus, ICustomContactTypesTableData } from "./interfaces";

export const getFormattedData = (data: any, leadContactTypeId: string) => {
  const responseData: ICustomContactTypesTableData[] = [];
  data.forEach((element: any) => {
    if (element.value === 'Customer') {
      element.value = 'Patient';
    }
    responseData.push({
      id: element.id,
      name: element.value || '-',
      description: element.description || '-',
      status: element.isActive ? AddCustomContactTypeStatus.Active : AddCustomContactTypeStatus.Inactive,
      isDefault: element.isDefault || false,
      contactCount: element.contactCount || 0,
      canConvertToPatient: (element?.id === leadContactTypeId || element.isDefault) ? true : element?.canConvertToPatient,
    })
  });
  return responseData;
}
