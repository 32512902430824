export const pipelineArray = [
  {
    key: 1,
    boardId: 1,
    created_at: 'Jan 11,2022',
    pName: 'v1 Pipeline',
    status: 'Active',
    visibility: 'Private',
    board: 'Lead Tracking',
    created_by: 'Bhavesh Patel',
    attribution: '',
    attrName: '',
    fractionalPart: '',
    assignedUser: false,
    stages: [],
    users: [],
    members: []
  },
  {
    key: 2,
    boardId: 1,
    created_at: 'Nov 12,2021',
    pName: 'v2 CRM Pipeline',
    status: 'Active',
    visibility: 'Public',
    board: 'Lead Tracking',
    created_by: 'Abhay Chaudhary',
    attribution: '',
    attrName: '',
    fractionalPart: '',
    assignedUser: false,
    stages: [],
    users: [],
    members: []

  },
  {
    key: 3,
    boardId: 1,
    created_at: 'Jan 8,2021',
    pName: 'v1 Sales Pipeline',
    status: 'Active',
    visibility: 'Public',
    board: 'Lead Tracking',
    created_by: 'Rohit',
    attribution: '',
    attrName: '',
    fractionalPart: '',
    assignedUser: false,
    stages: [],
    users: [],
    members: []
  },
  {
    key: 4,
    boardId: 2,
    created_at: 'Nov 12,2021',
    pName: 'v2 CRM Pipeline',
    status: 'Archived',
    visibility: 'Public',
    board: 'Care Plane Board',
    created_by: 'Vinay',
    attribution: '',
    attrName: '',
    fractionalPart: '',
    assignedUser: false,
    stages: [],
    users: [],
    members: []

  },
  {
    key: 5,
    boardId: 2,
    created_at: 'Jan 8,2021',
    pName: 'v1 Sales Pipeline',
    status: 'Active',
    visibility: 'Public',
    board: 'Care Plane Board',
    created_by: 'Shiva',
    attribution: '',
    attrName: '',
    fractionalPart: '',
    assignedUser: false,
    stages: [],
    users: [],
    members: []
  }
]

export const boardData = [
  {
    id: 1,
    title: 'Lead Tracking',
    count: 3
  },
  {
    id: 2,
    title: 'Care Plane Board',
    count: 2
  }
]