import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  formElement: {
    paddingTop: 10,
    paddingBottom: 12,
  },
  formLabel: {
    marginLeft: 4,
  },
  padding5: {
    padding: 5,
  },
  marginLeft12: {
    marginLeft: 12,
  },
  marginTop0: {
    marginTop: 0,
  },
  marginHorizontal10: {
    marginHorizontal: 10,
  },
  text: {
    marginLeft: 4,
    marginRight: 6,
  },
});
