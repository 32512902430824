import {Modal} from "antd"
import {Button, Text} from 'native-base';
import {BUTTON_TYPE} from "../../../constants"
import {FoldButton} from "../../CommonComponents/FoldButton/FoldButton"
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";
import {useIntl} from "react-intl";
import {Colors} from "../../../styles";

const ShowMicroPhoneOffPopUp = (props:{onActionPerformed: (code: string)=> void}) => {
  const intl = useIntl()
  return (
    <Modal
      title={intl.formatMessage({id: 'microphoneIsBlocked'})}
      open={true}
      onOk={() => {
        props.onActionPerformed(COMMON_ACTION_CODES.CANCEL)
      }}
      onCancel={()=> {
        props.onActionPerformed(COMMON_ACTION_CODES.CANCEL)
      }}
      footer={null}
    >
      <Text style={{color: Colors.Custom.Gray600, fontSize: 16}}>
        {intl.formatMessage({id: 'foldMicrophonePermission'})}
      </Text>
      <Button.Group style={{ marginTop: 12, justifyContent: 'flex-end' }}>
      <FoldButton
        nativeProps={{
          variant: BUTTON_TYPE.PRIMARY,
          style: {margin: 3},
          onPress: () => {
           props.onActionPerformed(COMMON_ACTION_CODES.CANCEL)
          },
        }}
        customProps={{
          btnText: 'Dismiss',
          withRightBorder: false,
        }}
      ></FoldButton>
      </Button.Group>
    </Modal>
  )
}

export default ShowMicroPhoneOffPopUp;
