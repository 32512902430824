import {Text, View} from 'react-native';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {Checkbox} from 'antd';
import {Colors} from '../../../../../../styles';

interface SummaryCountViewProps {
  count: number;
  showSelection?: boolean;
  titleText?: string;
  countTextColor?: string;
  onCheckChanged?: (code: string, isSelected: boolean) => void;
  summaryCode?: string;
  isSelected?: boolean;
  showRightBorder?: boolean;
}

export const SummaryCountView = (props: SummaryCountViewProps) => {
  const {
    onCheckChanged,
    count,
    titleText,
    showSelection,
    countTextColor,
    isSelected,
    showRightBorder,
  } = props;
  return (
    <Stack
      direction="row"
      style={{
        flex: 1,
        justifyContent: 'space-between',
      }}
    >
      <Stack
        direction="column"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          padding: 10,
          flex: 1,
        }}
      >
        <Text style={{fontSize: 16, fontWeight: '500', color: countTextColor}}>
          {count}
        </Text>
        <Stack direction="row" style={{alignItems: 'center', marginTop: 4}}>
          {count && showSelection && (
            <Checkbox
              onChange={(event) => {
                if (props.summaryCode) {
                  onCheckChanged?.(props.summaryCode, event.target.checked);
                }
              }}
              checked={isSelected}
            />
          )}
          <Text style={{color: Colors.FoldPixel.GRAY300, marginLeft: 6}}>
            {titleText}
          </Text>
        </Stack>
      </Stack>
      {showRightBorder && (
        <View style={{width: 1, backgroundColor: Colors.Custom.Gray300}} />
      )}
    </Stack>
  );
};
