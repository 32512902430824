import {Skeleton} from 'antd';
import {AxiosResponse} from 'axios';
import {View} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {useNavigate, useParams} from 'react-router-dom';
import {TAXONOMIES_CONTAINER_CODE} from '../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import CommerceCommonService from '../../../../../../services/CommonService/CommerceCommonService';
import { commonBodyContentContainer } from '../../../../../../styles/CommonBodyContentStyle';
import {IDisplayListItem} from '../../../../../common/DisplayCard/interface';
import {NoDataFound} from '../../../../../common/NoDataFound';
import PageBodyContainer from '../../../../../common/PageBodyContainer/PageBodyContainer';
import {TableTopBar} from '../../../../../common/TableTopBar';
import TitleSubtitleView from '../../../../../common/TitleSubtitleView/TitleSubtitleView';
import {getFormatedTaxonomyRes} from '../CategoriesUtils';
import TaxonomiesDnDView from '../TaxonomiesDnDView/TaxonomiesDnDView';
import {getFormatedTaxons} from '../TaxonomiesDnDView/TaxonsUtils';
import AddNewTaxon from './AddNewTaxonView/AddNewTaxon';
import DeleteTaxons from './DeleteTaxonsView/DeleteTaxonsView';
import {styles} from './TaxonomiesDeatailViewStyles';
import {getFormatedResponse} from './TaxonomiesUtils';
import {getTaxonomyTopBarButtons} from './TaxonomyTopBarUtils';
import UpdateTaxonView from './UpdateTaxonView/UpdateTaxonView';

const TaxonomiesDeatailView = () => {
  const [taxonomy, setTaxonomy] = useState({} as any);
  const {type, taxonmoyId} = useParams();
  const navigate = useNavigate();
  const [taxons, setTaxons] = useState([] as any);
  const [fullFormatedtaxons, setfullFormatedtaxons] = useState(
    [] as IDisplayListItem[]
  );
  const [selectedViewCode, setSelectedViewCode] = useState(
    COMMON_ACTION_CODES.ALL
  );
  const [rawData, setRawData] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const commerceCommonService =
    CommerceCommonService.getCommerceCommonServiceInstance();
  const axiosService = commerceCommonService.axiosService;

  useEffect(() => {
    setLoading(true);
    axiosService
      // .get(`/commerce/api/v2/platform/taxons/?filter[taxonomy_id_eq]=${taxonomy.id}&include=taxonomy`)
      .get(`/commerce/api/v2/platform/taxonomies/${taxonmoyId}?include=taxons`)
      .then((responseMsg: AxiosResponse<any>) => {
        if (responseMsg && responseMsg?.data) {
          const taxonRes = responseMsg.data.included;
          setTaxonomy((prev: any) => {
            prev = getFormatedTaxonomyRes(responseMsg.data.data);
            return prev;
          });
          const formatedRes = getFormatedResponse(taxonRes);
          const displayTaxonsList = getFormatedTaxons(formatedRes);
          setfullFormatedtaxons((prev: any) => {
            prev = displayTaxonsList;
            return prev;
          });
          if (taxonRes && taxonRes.length > 1) {
            setTaxons(displayTaxonsList);
            setLoading(false);
          } else {
            setTaxons([]);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);

      });
  }, [selectedViewCode === COMMON_ACTION_CODES.ALL]);

  const onViewActionPerformed = (actionCode: string, rowData?: any): any => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ADD:
        setSelectedViewCode(TAXONOMIES_CONTAINER_CODE.ADD);
        break;
      case COMMON_ACTION_CODES.EDIT:
        setRawData(rowData);
        setSelectedViewCode(TAXONOMIES_CONTAINER_CODE.EDIT);
        break;
      case COMMON_ACTION_CODES.DELETE:
        setRawData(rowData);
        setSelectedViewCode(TAXONOMIES_CONTAINER_CODE.DELETE);
        break;
      case COMMON_ACTION_CODES.RESET:
        setSelectedViewCode(TAXONOMIES_CONTAINER_CODE.RESET);
        break;
      case COMMON_ACTION_CODES.ALL:
        setSelectedViewCode(TAXONOMIES_CONTAINER_CODE.ALL);
        break;
      default:
        setSelectedViewCode(TAXONOMIES_CONTAINER_CODE.ALL);
        break;
    }
  };
  const createTaxon = () => {
    onViewActionPerformed(TAXONOMIES_CONTAINER_CODE.ADD);
  };

  const {height} = Dimensions.get('window');
  const finalHeight = height - 270;
  const onHeaderBackClick = () => {
    navigate(`/admin/commerce/${type}/categories`);
  };
  return (
    <>
      {/* <View style={styles.titleWrapper}>
        <TitleSubtitleView
          title="Edit category"
          subtitle={'Manage Category'}
          showBackButton={true}
          onBackClick={onHeaderBackClick}
        />
      </View> */}
      <View style={[commonBodyContentContainer.pageContentContainer]}>
      <PageBodyContainer>
        <TableTopBar
          {...getTaxonomyTopBarButtons(taxonomy.name, [createTaxon])}
        />
        {loading ? (
          <View style={styles.loadingView}>
            <Skeleton active />
          </View>
        ) : taxons && taxons.length > 0 ? (
          <View pb={3} maxHeight={finalHeight} overflow={'scroll'}>
            <TaxonomiesDnDView
              onActionPerformed={onViewActionPerformed}
              taxons={taxons}
            />
          </View>
        ) : (
          <NoDataFound
            textStyle={{
              fontSize: 14,
            }}
            isShowIcon={true}
            displayString="noDataFound"
          />
        )}
        {selectedViewCode === TAXONOMIES_CONTAINER_CODE.DELETE && (
          <DeleteTaxons
            taxon={rawData}
            onActionPerformed={onViewActionPerformed}
          />
        )}
        {selectedViewCode === TAXONOMIES_CONTAINER_CODE.EDIT && (
          <UpdateTaxonView
            taxon={rawData}
            taxonmoy={taxonomy}
            catagoryList={fullFormatedtaxons}
            onActionPerformed={onViewActionPerformed}
          />
        )}
        {selectedViewCode === TAXONOMIES_CONTAINER_CODE.ADD && (
          <View flex={1}>
            <AddNewTaxon
              onActionPerformed={onViewActionPerformed}
              catagoryList={fullFormatedtaxons}
              taxonmoy={taxonomy}
            />
          </View>
        )}
      </PageBodyContainer>
      </View>
    </>
  );
};

export default TaxonomiesDeatailView;
