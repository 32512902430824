import {ITeamMemberObj} from "../../../CreateTeam/Interface";
import {IGroupConversation} from "./interface";

export const getGroupCountForAdd = (groupList: IGroupConversation[], userUuid: string) => {
  let userAddCount = 0;
  for (let index = 0; index < groupList.length; index++) {
    const groupMembers = groupList?.[index]?.groupMembers;
    const allMemberUserIds = groupMembers.map((singleGroupMember) => singleGroupMember.groupUserId);
    if (allMemberUserIds.includes(userUuid)) {
      for (let memberIndex = 0; memberIndex < groupMembers.length; memberIndex++) {
        const singleMember = groupMembers[memberIndex];
        if (singleMember?.groupUserId === userUuid && singleMember?.isDeleted) {
          userAddCount++;
        }

      }
    } else {
      userAddCount++;
    }
  }
  return userAddCount;
};


export const getGroupCountToRemove = (groupList: IGroupConversation[], userUuid: string) => {
  let userRemoveCount = 0;
  for (let index = 0; index < groupList.length; index++) {
    const groupMembers = groupList?.[index]?.groupMembers;
    const allMemberUserIds = groupMembers.map((singleGroupMember) => singleGroupMember.groupUserId);
    if (allMemberUserIds.includes(userUuid)) {
      for (let memberIndex = 0; memberIndex < groupMembers.length; memberIndex++) {
        const singleMember = groupMembers[memberIndex];
        if (singleMember?.groupUserId === userUuid && !singleMember?.isDeleted) {
          userRemoveCount++;
        }

      }
    } else {
      userRemoveCount++;
    }
  }
  return userRemoveCount;
};


export const getMembersToAdd = (
  teamId: number,
  teamUuid: string,
  userToAdd: string[],
  addActionType: string,
  currentUserUuid: string,
  teamMemberData?: any,
) => {
  const memberArray: ITeamMemberObj[] = [];
  const selectedUser = userToAdd;
  selectedUser?.forEach((member: any) => {
    let additionalAttributes = {};
    const currentActionData = {
      updatedBy: currentUserUuid,
      actionType: addActionType,
    };
    if (teamMemberData?.length) {
      const singleTeamMemberData = teamMemberData?.find(
        (item: any) => item?.userUuid == member
      );
      if (singleTeamMemberData?.additionalAttributes?.addActionData) {
        const newActionData = [
          ...singleTeamMemberData?.additionalAttributes?.addActionData,
          currentActionData,
        ];
        additionalAttributes = {
          ...singleTeamMemberData?.additionalAttributes,
          addActionData: newActionData,
        };
      } else {
        additionalAttributes = {
          ...singleTeamMemberData?.additionalAttributes,
          addActionData: [currentActionData],
        };
      }
    } else {
      additionalAttributes = {
        addActionData: [currentActionData],
      };
    }
    const teamMemberObj: ITeamMemberObj = {
      teamId: teamId,
      teamUuid: teamUuid,
      userUuid: member,
      addActionType: addActionType,
      additionalAttributes: additionalAttributes,
    };
    memberArray.push(teamMemberObj);
  });
  return memberArray;
};

export const getMembersToDelete = (
  teamId: number,
  teamUuid: string,
  userToAdd: string[]
) => {
  const memberArray: ITeamMemberObj[] = [];
  const selectedUser = userToAdd;
  selectedUser?.forEach((member: any) => {
    const teamMemberObj: ITeamMemberObj = {
      teamId: {_eq: teamId},
      teamUuid: {_eq: teamUuid},
      userUuid: {_eq: member},
    };
    memberArray.push(teamMemberObj);
  });
  return memberArray;
};
