import React, {useEffect, useState} from 'react';
import {Modal, Typography} from 'antd';
import ButtonStyled from '../ButtonStyled';
import DisplayCard from '../DisplayCard';
import CreateCampaignModal from '../CreateCampaignModal';
import CreateOpportunityModal from '../CreateOpportunityModal';
import PieChart from '../../widgets/components/PieChart';

const {Text} = Typography;
const Colors = [
  {color: '#F4EBFF'},
  {color: '#B692F6'},
  {color: '#9E77ED'},
  {color: '#7F56D9'},
  {color: '#5B54F0'},
];

const LegendBall = ({data}: {data: any}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {data?.map((item: any) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            minWidth: '150px',
          }}
        >
          <div
            style={{
              width: '8px',
              height: '8px',
              backgroundColor: item.color,
              color: item.color,
              borderRadius: '50%',
              marginRight: '8px',
              marginLeft: '8px',
            }}
          />
          <div
            style={{
              color: '#667085',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20px',
            }}
          >
            {item.name}
          </div>
        </div>
      ))}
    </div>
  );
};

export default function PatientEngagementRiskChart({
  loading,
  data,
}: {
  loading: boolean;
  data: any;
}) {
  const [engagementData, setEngagementData] = useState(data);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalCampaign, setIsModalCampaign] = useState(false);
  const [isModalOpportunity, setIsModalOpportunity] = useState(false);
  const [selectedItem, setSelectedItem] = useState({name: '', members: 0});

  useEffect(() => {
    const mapData = data?.map((item: any, index: any) => {
      return {
        name: `${item.interaction} interactions`,
        members: item.members,
        color: Colors[Number(index)].color,
        contactIds: item.contactIds,
      };
    });

    setEngagementData(mapData);
  }, [data]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModalCampaign = () => {
    setIsModalCampaign(true);
    handleCancel();
  };

  const handleCancelCampaign = () => {
    setIsModalCampaign(false);
  };

  const showModalOpportunity = () => {
    setIsModalOpportunity(true);
    handleCancel();
  };

  const handleCancelOpportunity = () => {
    setIsModalOpportunity(false);
  };

  return (
    <>
      <DisplayCard
        title="Patient Engagement Risk"
        subtitle="Number of member activities in the current period"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            minHeight: 282,
          }}
        >
          <PieChart
            data={engagementData}
            loading={loading}
            dataKey="members"
            onClick={(e: any) => {
              const defaultPayload = {
                ...e.payload.payload,
                source: 'Patient Engagement Risk',
              };
              setSelectedItem(defaultPayload);
              showModal();
            }}
          />
        </div>
        <div className="flex w-full items-center justify-center">
          <LegendBall data={engagementData} />
        </div>
      </DisplayCard>
      <Modal
        title={selectedItem?.name}
        width={365}
        className={'patient_engagement_modal'}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Text style={{color: '#667085', marginBottom: '16px'}}>
          {selectedItem?.members} members
        </Text>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            padding: '10px',
          }}
        >
          <ButtonStyled
            className="primaryFilledBtn"
            shape="round"
            size="large"
            onClick={showModalOpportunity}
          >
            Opportunity
          </ButtonStyled>
          <ButtonStyled
            className="primaryFilledBtn"
            shape="round"
            size="large"
            onClick={showModalCampaign}
          >
            Campaign
          </ButtonStyled>
        </div>
      </Modal>
      <CreateCampaignModal
        visible={isModalCampaign}
        handleCancel={handleCancelCampaign}
        selectedItem={selectedItem}
      />
      <CreateOpportunityModal
        visible={isModalOpportunity}
        handleCancel={handleCancelOpportunity}
        selectedItem={selectedItem}
      />
    </>
  );
}
