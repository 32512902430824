import React from 'react';
import {View} from 'react-native';
import CustomTabsView from '../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../common/CustomTabsView/interfaces';
import {styles} from '../AccountTabsView/AccountTabsStyle';
import WearablesDataSyncConfigurationView from './WearablesDataSyncConfigurationView';

const WearablesSettingsView = () => {
  const tagsTabList = (): ITabsList[] => {
    const array: ITabsList[] = [
      {
        key: 'dataSyncConfiguration',
        title: 'Access Configuration',
        path: 'dataSyncConfiguration',
        tabContainerElem: () => {
          return <WearablesDataSyncConfigurationView />;
        },
      },
    ];
    return array;
  };

  return (
    <View style={{flex: 1}}>
      <View style={styles.tagContainer}>
        <CustomTabsView
          contentTopMargin={0}
          isAddMaxTab={true}
          minScreenSupportWidth={1600}
          showHeading
          basePath={'/admin/wearables'}
          heading="wearables"
          tabsList={tagsTabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
          canNavigate={true}
          bottomOffset={124}
        />
      </View>
    </View>
  );
};

export default WearablesSettingsView;
