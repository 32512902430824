import {HStack, Pressable, View, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {Colors} from '../../../../styles';
import {CHANNEL_TYPE_CODE} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {DisplayText} from '../../DisplayText/DisplayText';
import {IContactTypeFilterList} from './ContactTypeFilterList';
import {getColors} from './HelperUtils';

export interface ITypeData {
  id: number;
  code: string;
  displayNameId: string;
}

export const CONTACT_TYPE_FILTER_LIST: ITypeData[] = [
  {
    id: 1,
    code: CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET,
    displayNameId: 'Chats',
  },
  {
    id: 2,
    code: CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
    displayNameId: 'SMS',
  },
];

export interface InboxTypeFilter {
  onTypeSelected: (typeData: ITypeData) => void;
}

const InboxTypeFilter = (props: IContactTypeFilterList) => {
  const {onTypeSelected} = props;
  const [contactTypeList, setContactTypeList] = useState<ITypeData[]>();

  useEffect(() => {
    setContactTypeList(CONTACT_TYPE_FILTER_LIST);
  }, []);
  return (
    <>
      <DisplayText
        size={'xsMedium'}
        extraStyles={{fontSize: 10, color: Colors.Custom.Gray500}}
        textLocalId="inboxType"
      />
      <VStack>
        {contactTypeList?.map((item) => {
          return (
            <Pressable
              key={item.id}
              onPress={() => {
                onTypeSelected(item);
              }}
            >
              <HStack
                alignItems={'center'}
                flex={1}
                style={{marginVertical: 8}}
              >
                <View
                  style={{
                    width: 6,
                    height: 6,
                    borderRadius: 50,
                    backgroundColor: getColors(''),
                    marginRight: 10,
                  }}
                ></View>
                <DisplayText
                  size={'xsMedium'}
                  extraStyles={{color: Colors.Custom.Gray700, marginLeft: 10}}
                  textLocalId={item.displayNameId}
                />
              </HStack>
            </Pressable>
          );
        })}
      </VStack>
    </>
  );
};

export default InboxTypeFilter;
