import {Input, Select} from 'antd';
import {FormControl, Text} from 'native-base';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {Colors} from '../../../styles/Colors';
import AlertBanner from '../../common/AlertBanner/AlertBanner';
import CustomDrawer from '../../common/CustomDrawer/CustomDrawer';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import CreateNewGlobalActionSvg from '../../common/Svg/CreateNewGlobalActionSvg';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {getNewCallScheduleDuration} from './common';
import {
  ICreateOnCallScheduleDrawerProps,
  ICreateOnCallScheduleDrawerState,
  IOnCallSchedule,
  IOnCallScheduleDuration,
} from './interface';
import ScheduleBox from './ScheduleBox';
import { StyleSheet } from 'react-native';

const CreateOrEditOnCallScheduleDrawer = (
  props: ICreateOnCallScheduleDrawerProps
) => {
  const intl = useIntl();
  const commonData = React.useContext(CommonDataContext);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  let IvrUsageTypeList = mlovData['IvrUsageType'] || [];
  const isEditMode = props?.selectedOnCallScheduleData ? true : false;

  const stopLoading = () => {
    setStateData((prev) => {
      return {
        ...prev,
        isLoading: false,
        errors: {
          baseError: intl.formatMessage({id: 'unableToCreateSchedule'}),
        },
      };
    });
  };

  // removing duplicates
  IvrUsageTypeList = Array.from(
    new Map(IvrUsageTypeList.map((item) => [item.code, item])).values()
  );

  IvrUsageTypeList = IvrUsageTypeList.filter((item) => {
    switch (item.code) {
      case 'OUT_OF_OFFICE':
        return true;
      case 'HOLIDAY_HOURS':
        return true;
    }
    return false;
  });

  const [stateData, setStateData] = React.useState({
    scheduleEventTypes: ['Out Of Office Hours (OoO)', 'Holiday Hours'],
    isLoading: false,
    errors: {},
    formData: {
      onCallScheduleDurations: [getNewCallScheduleDuration()] as any,
    },
  } as ICreateOnCallScheduleDrawerState);

  const removeScheduleBox = (removeIndex: number) => {
    setStateData((prev) => {
      return {
        ...prev,
        formData: {
          ...prev.formData,
          onCallScheduleDurations:
            prev.formData?.onCallScheduleDurations?.filter((_, index) => {
              return removeIndex !== index;
            }),
        },
      };
    });
  };

  const isOnCallScheduleDataValid = (): boolean => {
    const schedule = stateData.formData;
    if (
      !schedule?.scheduleName?.trim() ||
      !schedule?.phoneTreeTypeId ||
      !schedule?.onCallScheduleDurations ||
      schedule?.onCallScheduleDurations?.length === 0
    ) {
      return false;
    }

    for (const duration of schedule?.onCallScheduleDurations) {
      if (
        !duration?.fromDate ||
        !duration?.toDate ||
        !duration?.daysOfWeek ||
        duration?.daysOfWeek === '[]' ||
        !duration?.onCallScheduleUsers ||
        duration?.onCallScheduleUsers?.length === 0
      ) {
        return false;
      }

      for (const user of duration?.onCallScheduleUsers) {
        if (!user?.userId) {
          return false;
        }
      }
    }
    return true;
  };

  const updateFormDataForRemovedUsers = (
    editedSchedule: IOnCallSchedule,
    originalSchedule: IOnCallSchedule | null
  ): IOnCallSchedule => {
    if (originalSchedule) {
      // Iterate over each onCallScheduleDuration in the originalSchedule
      originalSchedule.onCallScheduleDurations.forEach((originalDuration) => {
        // Find the corresponding duration in the editedSchedule
        const editedDuration = editedSchedule.onCallScheduleDurations.find(
          (duration) => duration.id === originalDuration.id
        );

        if (editedDuration) {
          // Create a Set of user IDs in the editedDuration for quick lookup
          const editedUserIds = new Set(
            editedDuration.onCallScheduleUsers.map((user) => user.userId)
          );

          // Mark users as deleted if they are in originalDuration but not in editedDuration
          originalDuration.onCallScheduleUsers.forEach((originalUser) => {
            if (!editedUserIds.has(originalUser.userId)) {
              editedDuration.onCallScheduleUsers.push({
                ...originalUser,
                isDeleted: true,
              });
            }
          });
        } else {
          // If the duration doesn't exist in the edited schedule, mark it as deleted
          editedSchedule.onCallScheduleDurations.push({
            ...originalDuration,
            isDeleted: true,
          });
        }
      });
    }
    return editedSchedule;
  };

  useEffect(() => {
    if (props?.selectedOnCallScheduleData) {
      setStateData((prev: any) => {
        return {
          ...prev,
          formData: props?.selectedOnCallScheduleData,
        };
      });
    }
  }, [props?.selectedOnCallScheduleData]);

  return (
    <>
      <CustomDrawer
        title={isEditMode ? 'updateSchedule' : 'createSchedule'}
        open={true}
        customization={{
          width: props.width,
        }}
        onClose={() => props.onActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL)}
        isLoading={false}
        headerButtons={[
          <FoldButton
            customProps={{
              btnText: intl.formatMessage({id: isEditMode ? 'save' : 'create'}),
            }}
            nativeProps={{
              onPress() {
                setStateData((prev) => {
                  return {...prev, isLoading: true};
                });
                if (isEditMode) {
                  const updatedFormData = updateFormDataForRemovedUsers(
                    JSON.parse(JSON.stringify(stateData.formData)),
                    props?.selectedOnCallScheduleData
                  );
                  props.onActionPerformed(
                    COMMON_ACTION_CODES.UPDATE,
                    updatedFormData,
                    stopLoading
                  );
                } else {
                  props.onActionPerformed(
                    COMMON_ACTION_CODES.CREATE_ON_CALL_SCHEDULE,
                    stateData.formData,
                    stopLoading
                  );
                }
              },
              isDisabled: !isOnCallScheduleDataValid(),
              isLoading: stateData.isLoading,
              backgroundColor: !isOnCallScheduleDataValid()
                ? Colors.FoldPixel.GRAY50
                : Colors.Custom.Primary300,
              _text: {
                fontSize: 14,
                lineHeight: 16.8,
                fontWeight: 'bold',
                color: !isOnCallScheduleDataValid()
                  ? Colors.FoldPixel.GRAY150
                  : '#fff',
              },
            }}
          />,
        ]}
      >
        <AlertBanner
          message={intl.formatMessage({id: 'unableToCreateSchedule'})}
          visible={stateData.errors?.baseError}
          customContainerStyle={{marginBottom: '16px'}}
        />

        <AlertBanner
          message={intl.formatMessage({id: 'allFieldsAreMandatory'})}
          visible={true}
          customTextStyle={{fontSize: 14}}
          backgroundColor={Colors.Custom.White}
          iconColor={Colors.FoldPixel.GRAY250}
          textColor={Colors.FoldPixel.GRAY250}
          customContainerStyle={{
            marginBottom: '16px',
            paddingHorizontal: 0,
            paddingVertical: 0,
          }}
        />

        <FormControl isInvalid={!!stateData.errors?.scheduleName}>
          <FormControl.Label>
            <DisplayText
              size={'smMedium'}
              extraStyles={{
                color: Colors.Custom.Gray500,
                fontWeight: 400,
                fontSize: 14,
              }}
              textLocalId={'scheduleName'}
            />
          </FormControl.Label>

          <Input
            style={antdStyles.input}
            className="custom-antd-input-box"
            color={Colors.FoldPixel.GRAY400}
            placeholder={intl.formatMessage({
              id: 'enterScheduleName',
            })}
            disabled={false}
            value={stateData.formData.scheduleName}
            onChange={(event) => {
              setStateData((prev: any) => {
                return {
                  ...prev,
                  formData: {
                    ...prev.formData,
                    scheduleName: event.target.value,
                  },
                };
              });
            }}
          />

          {stateData.errors?.scheduleName ? (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {intl.formatMessage({
                id: stateData.errors?.scheduleName,
              })}
            </FormControl.ErrorMessage>
          ) : (
            <></>
          )}
        </FormControl>

        <FormControl
          isInvalid={!!stateData.errors?.eventType}
          marginTop={'16px'}
        >
          <FormControl.Label>
            <DisplayText
              size={'smMedium'}
              extraStyles={{
                color: Colors.Custom.Gray500,
                fontWeight: 400,
                fontSize: 14,
              }}
              textLocalId={'phoneTreeType'}
            />
          </FormControl.Label>
          <Select
            style={antdStyles.select}
            placeholder={intl.formatMessage({
              id: 'selectPhoneTreeType',
            })}
            value={stateData.formData.phoneTreeTypeId}
            popupClassName="custom-select-box-dropdown"
            status={!!stateData.errors?.eventType ? 'error' : ''}
            disabled={isEditMode}
            className="custom-select-box"
            onSelect={(value) => {
              setStateData((prev: any) => {
                return {
                  ...prev,
                  formData: {
                    ...prev.formData,
                    phoneTreeTypeId: value,
                  },
                };
              });
            }}
          >
            {IvrUsageTypeList?.map((flow: any) => {
              return (
                <Select.Option key={flow.id} value={flow.id}>
                  {flow.value || ''}
                </Select.Option>
              );
            })}
          </Select>
          {stateData.errors?.eventType ? (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {intl.formatMessage({id: stateData.errors?.eventType})}
            </FormControl.ErrorMessage>
          ) : (
            <></>
          )}
        </FormControl>

        {stateData.formData?.onCallScheduleDurations?.map(
          (scheduleDuration, index) => {
            return (
              <ScheduleBox
                index={index}
                key={
                  (scheduleDuration?.key || scheduleDuration?.id) +
                  'scheduleSection'
                }
                scheduleDuration={scheduleDuration}
                accountUsers={props?.accountUsers || []}
                onRemove={removeScheduleBox}
                isEditMode={isEditMode}
                listLength={
                  stateData?.formData?.onCallScheduleDurations?.length
                }
                onChange={(key, value, onIndex) => {
                  setStateData((prev: any) => {
                    return {
                      ...prev,
                      formData: {
                        ...prev.formData,
                        onCallScheduleDurations:
                          prev.formData?.onCallScheduleDurations?.map(
                            (
                              schedule: IOnCallScheduleDuration,
                              index: number
                            ) => {
                              if (index === onIndex) {
                                return {
                                  ...schedule,
                                  [key]: value,
                                };
                              }
                              return schedule;
                            }
                          ),
                      },
                    };
                  });
                }}
              />
            );
          }
        )}

        <FoldButton
          nativeProps={{
            leftIcon: (
              <CreateNewGlobalActionSvg
                customColor={Colors.FoldPixel.PRIMARY300}
              />
            ),
            variant: BUTTON_TYPE.PRIMARY,
            style: styles.addButton,
            _hover: {
              backgroundColor: Colors.FoldPixel.PRIMARY_20,
            },
            onPress: () => {
              setStateData((prev) => {
                return {
                  ...prev,
                  formData: {
                    ...prev.formData,
                    onCallScheduleDurations: [
                      ...prev.formData?.onCallScheduleDurations,
                      getNewCallScheduleDuration(),
                    ],
                  },
                };
              });
            },
          }}
          customProps={{
            btnText: (
              <Text
                cursor={'pointer'}
                size="smMedium"
                fontWeight={'400'}
                fontSize={14}
                color={Colors.Custom.mainPrimaryPurple}
              >
                {intl.formatMessage({
                  id: 'addAnotherSchedule',
                })}
              </Text>
            ),
            withRightBorder: false,
          }}
        ></FoldButton>
      </CustomDrawer>
    </>
  );
};

const styles = StyleSheet.create({
  addButton: {
    width: 'max-content',
    marginTop: 16,
    borderWidth: 0,
    paddingLeft: 4,
    paddingRight: 4,
    minHeight: 'max-content',
  },
});

const antdStyles: Record<string, React.CSSProperties> = {
  input: {
    fontSize: 14,
    fontWeight: 400,
  },
  select: {
    width: '100%',
  }
}

export default CreateOrEditOnCallScheduleDrawer;
