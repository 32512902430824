import {useLazyQuery} from '@apollo/client';
import {Pressable, Text} from 'native-base';
import React, {memo, useEffect, useState} from 'react';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {GetContactByStripeCustomerId} from '../../../../../../../services/Stripe/StripeServices';
import {Colors} from '../../../../../../../styles';
import {getAccountUUID} from '../../../../../../../utils/commonUtils';
interface IContactProfileName {
  id: string | number;
  onActionPerformed: (actionCode: string, actionData?: any) => void;
  name: string;
  extraStyle?: any;
  size?: string;
}
const ContactProfileName = (props: IContactProfileName) => {
  const {onActionPerformed, id, name, extraStyle, size} = props;
  const accountUuid = getAccountUUID();
  const [stateData, setStateData] = useState({
    contactAddInfo: {},
  });

  const [getContactAddInfo] = useLazyQuery(GetContactByStripeCustomerId, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });
  const getContactAddInfoByStripeId = async () => {
    const response = await getContactAddInfo({
      variables: {
        stripeCustomerId: id,
        accountUuid: accountUuid,
      },
    }).catch((err) => {
      // catch
    });
    if (response?.data?.contactAdditionalInfos?.length) {
      const contact = response?.data?.contactAdditionalInfos[0];
      setStateData((prev) => {
        return {
          ...prev,
          contactAddInfo: contact,
        };
      });
    }
  };
  useEffect(() => {
    getContactAddInfoByStripeId();
  }, []);

  return (
    <>
      <Pressable
        onPress={() => {
          onActionPerformed(COMMON_ACTION_CODES.ON_NAVIGATE_TO_DETAIL, {
            ...stateData.contactAddInfo,
          });
        }}
        style={{paddingRight: 12, paddingVertical: 5}}
      >
        <Text
          size={size ? size : 'smNormal'}
          style={[{color: Colors.info['900']}, extraStyle ? extraStyle : {}]}
        >
          {name}
        </Text>
      </Pressable>
    </>
  );
};
export default memo(ContactProfileName);
