import {Button, Divider, HStack, Input, View, VStack} from 'native-base';
import {CAMPAIGN_CONSTANTS} from '../CampaignConstants';
import {styles} from '../CampaignStyles';
import {ITitleBarProps} from '../interfaces';
import Icon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';

export const TitleBar = (props: ITitleBarProps) => {
  return (
    <View>
      <View style={styles.topContainer}>
        <HStack flex={1}>
          <HStack flex={0.5}>
            <View style={styles.left}>
              <HStack>
                <VStack style={styles.leftText}>
                  <DisplayText textLocalId="title" />
                </VStack>
                <Input
                  _focus={{borderColor: Colors.Custom.PrimaryColor}}
                  variant={'underlined'}
                  minWidth={400}
                  onChangeText={props.onTitleChange}
                />
              </HStack>
            </View>
          </HStack>
          <VStack flex={0.5}>
            <HStack justifyContent={'flex-end'}>
              <VStack alignItems={'flex-end'}>
                <View style={styles.left}>
                  <Button.Group>
                    <Button
                      colorScheme="dark"
                      _text={{color: 'white'}}
                      leftIcon={<Icon name="closecircleo" />}
                      onPress={() => {
                        props.handleScreenChange(CAMPAIGN_CONSTANTS.LANDING);
                      }}
                    >
                      <DisplayText
                        extraStyles={{
                          color: Colors.Custom.FontColorWhite,
                        }}
                        textLocalId="cancel"
                      />
                    </Button>
                    <Button
                      colorScheme="yellow"
                      _text={{color: 'white'}}
                      leftIcon={<Icon name="filetext1" />}
                      onPress={() => {
                        props.handleScreenChange;
                      }}
                    >
                      <DisplayText
                        extraStyles={{
                          color: Colors.Custom.FontColorWhite,
                        }}
                        textLocalId="saveAndDraft"
                      />
                    </Button>
                    <Button
                      colorScheme="success"
                      _text={{color: 'white'}}
                      leftIcon={<Icon name="checkcircleo" />}
                      onPress={() => {
                        props.handleScreenChange;
                      }}
                    >
                      <DisplayText
                        extraStyles={{
                          color: Colors.Custom.FontColorWhite,
                        }}
                        textLocalId="sendLive"
                      />
                    </Button>
                  </Button.Group>
                </View>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      </View>
      <Divider />
    </View>
  );
};
