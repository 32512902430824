import { ITaskFilter, ITaskFilterObjectList } from '../../components/common/CareDashboard/CareDashboardTopBar/interfaces';
import {ITabDataProps} from '../../components/common/TabList/interface';
import { TASK_STATUS } from '../../constants/MlovConst';
import { IMlov } from '../../Interfaces';
import { getMlovByCategory, getMlovIdFromCode, getMlovValueFromCode } from '../../utils/mlovUtils';

export const TASK_OMNIVIEW_TAB_CODE = {
  // IMPORTANT: 'Important',
  // PENDING: 'Pending',
  // MISSED: 'Missed',
  COMPLETED: 'Completed',
  NOT_COMPLETED: 'NotCompleted'
};

export const TASK_DISPLAY_CATEGORY_CODE = {
  ALL_ASSIGN: 'ALL_ASSIGNED',
  DO_TODAY: 'DO_TODAY',
  DO_TOMORROW: 'DO_TOMORROW',
  DO_LATER: 'DO_LATER',
};

export const TASK_DUE_DATE_CATEGORY_CODE = {
  ALL_ASSIGN: 'ALL_ASSIGNED',
  DO_TODAY: 'DO_TODAY',
  DO_TOMORROW: 'DO_TOMORROW',
  DO_LATER: 'DO_LATER',
};

export const TASK_TAB_LIST: ITabDataProps[] = [
  {
    tabCode: TASK_DISPLAY_CATEGORY_CODE.ALL_ASSIGN,
    title: 'All Assigned',
    code: 'all_assigned',
  },
  {
    tabCode: TASK_DISPLAY_CATEGORY_CODE.DO_TODAY,
    title: 'Due Today',
    code: 'do_today',
  },
  {
    tabCode: TASK_DISPLAY_CATEGORY_CODE.DO_TOMORROW,
    title: 'Due Tomorrow',
    code: 'do_tomorrow',
  },
  {
    tabCode: TASK_DISPLAY_CATEGORY_CODE.DO_LATER,
    title: 'Due Later',
    code: 'do_later',
  },
];

export const TASK_PRIORITY_STATUS = {
  ROUTINE: 'routine',
  ASAP: 'asap',
  URGENT: 'urgent',
  ALL: 'all',
}

export const DUE_DATE_STATUS = {
  DATE_RANGE: 'date_range',
  ALL: 'all',
}

export const getTaskStatusForFilter = (statusList: IMlov[]) => {
  const statusMlovList = statusList.filter((mlov: IMlov) => {
    if (mlov.code === TASK_STATUS.ACCEPTED ) {
      mlov.value = 'Not Completed';
    }
    return mlov.code === TASK_STATUS.ACCEPTED || mlov.code === TASK_STATUS.COMPLETED;
  });
  return statusMlovList;
};

export const getTaskPriorityForFilter = (priorityList: IMlov[]) => {
  const priorityMlovList = priorityList.filter((mlov: IMlov) => {
    return (
      mlov.code === TASK_PRIORITY_STATUS.ASAP ||
      TASK_PRIORITY_STATUS.ROUTINE ||
      TASK_PRIORITY_STATUS.URGENT
    );
  });
  return priorityMlovList;
};

export const getDefaultTaskFilter = (taskFilterObj: ITaskFilterObjectList, routeParamTaskFilter: any) => {
  const statusFilter = ((routeParamTaskFilter?.selectedStatusList || [])?.map?.((mlov: any) => mlov?.code) || []);
  const priorityFilter = ((routeParamTaskFilter?.selectedPriorityList || [])?.map?.((mlov: any) => mlov?.code) || []);
  let dateRangeFilter ={}
  if(routeParamTaskFilter?.start && routeParamTaskFilter?.end){
    dateRangeFilter= {startDate: routeParamTaskFilter?.start, endDate: routeParamTaskFilter?.end} || {}
  }
  let dueDateFilter = [];
  if (routeParamTaskFilter?.selectedDueDateUuid) {
    dueDateFilter = routeParamTaskFilter?.selectedDueDateUuid || []
  }
  let labels = [];
  if (routeParamTaskFilter?.labels?.length) {
    labels = routeParamTaskFilter?.labels || []
  }
  let selectedTaskEntityTypesIds = [];
  if (routeParamTaskFilter?.selectedTaskEntityTypesIds?.length) {
    selectedTaskEntityTypesIds = routeParamTaskFilter?.selectedTaskEntityTypesIds || []
  }
  let selectedPriorityTypes: any = [];
  if (routeParamTaskFilter?.selectedPriorityTypes?.length) {
    selectedPriorityTypes = routeParamTaskFilter?.selectedPriorityTypes || []
  }
  return {statusFilter, priorityFilter, dueDateFilter, dateRangeFilter, labels, selectedTaskEntityTypesIds, selectedPriorityTypes};
};

export const getTaskForDueDateFilter = (dueDateTypeList: IMlov[]) => {
  dueDateTypeList.push({
    categoryId: 'TaskPriority',
    code: DUE_DATE_STATUS.DATE_RANGE,
    id: DUE_DATE_STATUS.DATE_RANGE,
    value: 'Date Range',
  });
  return dueDateTypeList;
};

export const TASK_TAB_PATIENT_LIST: ITabDataProps[] = [
  // {
  //   tabCode: TASK_OMNIVIEW_TAB_CODE.IMPORTANT,
  //   title: 'Important',
  //   code: 'Important',
  // },
  // {
  //   tabCode: TASK_OMNIVIEW_TAB_CODE.PENDING,
  //   title: 'Pending',
  //   code: 'Pending',
  // },
  // {
  //   tabCode: TASK_OMNIVIEW_TAB_CODE.MISSED,
  //   title: 'Missed',
  //   code: 'Missed',
  // },
  {
    tabCode: TASK_OMNIVIEW_TAB_CODE.NOT_COMPLETED,
    title: 'Not Completed',
    code: 'NotCompleted',
  },
  {
    tabCode: TASK_OMNIVIEW_TAB_CODE.COMPLETED,
    title: 'Completed',
    code: 'Completed',
  },
];
