import { useState, useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { DayOptimizerContext } from '../../../../../../context-provider/DayOptimizerContext/context';
import { changeDate as changeDateHelper, columnShuffleDropDownList, resetDefaultColumns } from '../../DayOptimizerHelper';
import { COLUMN_SHUFFLE_DROP_DOWN_LIST, DATE_CHANGE, USER_FETCH_LIST_SUCCESS, USER_SELECT } from '../../../../../../context-provider/DayOptimizerContext/actionTypes';
import { UserQueries } from '../../../../../../services';
import { CARE_TEAM_MEMBER_TYPE } from '../../../../../../constants/MlovConst';
import { IProvider } from '../../interfaces';
import { ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP } from '../../../../../PersonOmniView/RightContainer/CareTeamView/CareTeamUtils';
import { getUserUUID } from '../../../../../../utils/commonUtils';

const useListingHeader = () => {
  const { userList } = useContext(DayOptimizerContext).state.patientDashboard;
  const dispatch = useContext(DayOptimizerContext).dispatch;
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedProvider, setSelectedProvider] = useState<IProvider | null>(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState(columnShuffleDropDownList);
  const [filteredColumns, setFilteredColumns] = useState(columnShuffleDropDownList);
  const loggedInUserId = getUserUUID();
  
  const getUserSearchParams = (
    searchString: string
  ) => {
    return {
      isActive: true,
      name: searchString ? `%${searchString}%` : '',
      limit: 20,
      userRoleCodes: ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER],
      orderBy: { name: 'asc' }
    };
  };

  const [getUsers] = useLazyQuery(UserQueries.GET_USERS_BY_RESOURCE_CODES, {
    onCompleted: (data: any) => {
      let users = data?.searchUsers?.users || data?.users || [];
      const loggedInUser = users.find((user: { uuid: string }) => user?.uuid === loggedInUserId);
      users = users.filter((user: { uuid: string }) => user.uuid !== loggedInUserId);
      
      if (loggedInUser) {
        const modifiedLoggedInUser = {
          ...loggedInUser,
          userRoles: [{
            userRole: {
              customRoleCode: 'YOU',
              roleName: 'You'
            }
          }]
        };
        users.unshift(modifiedLoggedInUser);
      }
      dispatch({
        type: USER_FETCH_LIST_SUCCESS,
        payload: users
      });
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
    },
  });

  const fetchUsers = (searchText: string) => {
    setLoading(true);
    getUsers({
      variables: {
        params: getUserSearchParams(searchText),
      },
    });
  };

  const handleUserSelect = (selectedUser: any) => {
    setSelectedProvider(selectedUser);
    dispatch({ type: USER_SELECT, payload: selectedUser });
  };

  const walkDate = (direction: number) => {
    const newDate = changeDateHelper(currentDate, direction);
    setCurrentDate(newDate);
    dispatch({ type: DATE_CHANGE, payload: newDate });
  };

  const updateColumnShuffleDropDownList = (newList: any) => {
   const columnShuffleDropDownList = newList.map((item: any, index: any) => ({
        ...item,
        priority: index + 1
    }));
    dispatch({ type: COLUMN_SHUFFLE_DROP_DOWN_LIST, payload: columnShuffleDropDownList });
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(filteredColumns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = items.map((item, index) => ({
      ...item,
      priority: index + 1
    }));
    setFilteredColumns(updatedItems);
    setColumns(updatedItems);
    updateColumnShuffleDropDownList(updatedItems);
  };

  const handleResetToDefault = () => {
    resetDefaultColumns();
    setColumns([...columnShuffleDropDownList]);
    setFilteredColumns([...columnShuffleDropDownList]);
    updateColumnShuffleDropDownList(columnShuffleDropDownList);
  };

  const toggleVisibility = (index: number) => {
    const updatedList = [...filteredColumns];
    updatedList[index].hidden = !updatedList[index].hidden;
    setFilteredColumns(updatedList);
    setColumns(prevColumns => {
      const updatedColumns = [...prevColumns];
      const originalIndex = updatedColumns.findIndex(col => col.key === updatedList[index].key);
      if (originalIndex !== -1) {
        updatedColumns[originalIndex] = { ...updatedList[index] };
      }
      return updatedColumns;
    });
    updateColumnShuffleDropDownList(updatedList);
  };

  const searchColumns = (searchText: string) => {
    if (!searchText) {
      setFilteredColumns(columns);
    } else {
      const filtered = columns.filter(column => 
        column.label.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredColumns(filtered);
    }
  };

  const changeDate = (date: Date) => {
    setCurrentDate(date);
    dispatch({ type: DATE_CHANGE, payload: date });
  };

  return {
    currentDate,
    selectedProvider,
    userList,
    handleUserSelect,
    walkDate,
    changeDate,
    fetchUsers,
    loading,
    onDragEnd,
    handleResetToDefault,
    columns: filteredColumns,
    toggleVisibility,
    searchColumns
  };
};

export default useListingHeader;