import { CONVERSATION_STATUS, CONVERSATION_STATUS_TYPES, MENTION_READ_STATUS } from "../../../../constants";
import { VIRTUAL_ASSIGNEE_TYPE_CODES } from "../../../../constants/MlovConst";
import { IUser } from "../../../../Interfaces";
import { IUserPracticeLocation } from "../../../../services/Location/interfaces";
import { CHANNEL_TYPE_CODE, ERROR_CODE } from "./ConversationConst";
import { getConversationFilterData } from "./ConversationContainer/ConversationFilters/ConversationFilterUtils";
import { FilterSectionCode, ICommonFilterData, IConversationFilterState, IVirtualPhoneNumberAssignee } from "./ConversationContainer/ConversationFilters/interface";
import { getSingleSelectedFilterOptionFromList } from "./ConversationFilterUtils.native";

export const getLayoutFlexObjForConversationContainer = (width: number) => {
  let containerFlexWidth = {
    conversationListContainer: 0.25,
    msgListContainer: 0.75,
  };
  if (width < 1500) {
    containerFlexWidth = {
      conversationListContainer: 0.35,
      msgListContainer: 0.65,
    };
  }
  return containerFlexWidth;
};

export const updateAssigneeNameInVirtualNumber = (virtualPhoneNumber: IVirtualPhoneNumberAssignee[],
  accountLocationData: IUserPracticeLocation[],
  userDataList:
  {
    user: IUser;
  }[]
) => {
  const virtualPhoneNumberAssignees = [...virtualPhoneNumber];
  virtualPhoneNumberAssignees.forEach((singlePhoneNumber) => {
    singlePhoneNumber.assigneeName = singlePhoneNumber?.assignee;
    if (singlePhoneNumber?.assigneeId) {
      if (singlePhoneNumber.assigneeType?.code === VIRTUAL_ASSIGNEE_TYPE_CODES.USER) {
        for (let index = 0; index < userDataList.length; index++) {
          const singleUser = userDataList[index];
          if (singleUser?.user?.uuid === singlePhoneNumber?.assigneeId) {
            singlePhoneNumber.assigneeName = singleUser?.user?.name;
            break;
          }
        }
      } else if (singlePhoneNumber.assigneeType?.code === VIRTUAL_ASSIGNEE_TYPE_CODES.CLINIC) {
        for (let index = 0; index < accountLocationData.length; index++) {
          const singleLocation = accountLocationData[index];
          if (singleLocation?.uuid === singlePhoneNumber?.assigneeId) {
            singlePhoneNumber.assigneeName = singleLocation?.practiceLocation?.name;
            break;
          }
        }
      }
    }
  });
  return virtualPhoneNumberAssignees;
}

export const handleErrorMsgForAssign = (errorData: any) => {
  let errMsg = '';
  if (!errorData) {
    return errMsg;
  }
  if (errorData?.code === ERROR_CODE.CONVERSATION_ALREADY_ASSIGN_TO_SAME_USER) {
    errMsg = errorData?.CONVERSATION_ALREADY_ASSIGN_TO_SAME_USER;
  } else if (
    errorData?.code === ERROR_CODE.CONVERSATION_ASSIGNMENT_REQUIRED_DATA_MISSING
  ) {
    errMsg = errorData?.CONVERSATION_ASSIGNMENT_REQUIRED_DATA_MISSING;
  } else if (errorData?.code === ERROR_CODE.ASSIGNEE_NOT_EXIST_IN_INBOX) {
    errMsg = errorData?.ASSIGNEE_NOT_EXIST_IN_INBOX;
  } else if (
    errorData?.code === ERROR_CODE.ASSIGNEE_NOT_EXIST_IN_SAME_ACCOUNT
  ) {
    errMsg = errorData?.ASSIGNEE_NOT_EXIST_IN_SAME_ACCOUNT;
  } else if (errorData?.code === ERROR_CODE.ASSIGNEE_USER_NOT_FOUND) {
    errMsg = errorData?.ASSIGNEE_USER_NOT_FOUND;
  } else if (errorData?.code === ERROR_CODE.ACCOUNT_USER_NOT_FOUND) {
    errMsg = errorData?.ACCOUNT_USER_NOT_FOUND;
  } else if (errorData?.code === ERROR_CODE.CONVERSATION_NOT_FOUND) {
    errMsg = errorData?.CONVERSATION_NOT_FOUND;
  } else if (errorData?.code === ERROR_CODE.CONVERSATION_INBOX_NOT_FOUND) {
    errMsg = errorData?.CONVERSATION_INBOX_NOT_FOUND;
  } else if (errorData?.code === ERROR_CODE.ASSIGNEE_USER_NOT_FOUND_IN_INBOX) {
    errMsg = errorData?.ASSIGNEE_USER_NOT_FOUND_IN_INBOX;
  } else if (errorData?.code === ERROR_CODE.ASSIGNEE_USER_NOT_FOUND_IN_GROUP) {
    errMsg = errorData?.ASSIGNEE_USER_NOT_FOUND_IN_GROUP;
  } else if (errorData?.code === ERROR_CODE.ASSIGNEE_VALIDATION_ERROR) {
    errMsg = errorData?.ASSIGNEE_VALIDATION_ERROR;
  }
  return errMsg;
};

export const getAssignedToOthersConversationFilterAdditionalData = (data: {
  selectedCommonFilter: ICommonFilterData;
  accountId: string | number;
  userUuid: string;
  groupTypeCodeIdObj: Record<string, string>;
  userId: number;
  isEFaxMessagingEnabled: boolean;
}): any => {
  const {
    selectedCommonFilter,
    accountId,
    groupTypeCodeIdObj,
    userId,
    userUuid,
    isEFaxMessagingEnabled
  } = data;
  const count: string[] = []
  const selectedTabCode = CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER;
  if (!selectedCommonFilter.filterSections.length) {
    return {};
  }
  const assigneeFilterType = selectedTabCode || '';
  const selectedDateFilterOption = getSingleSelectedFilterOptionFromList(
    selectedCommonFilter?.filterSections,
    FilterSectionCode.dateType
  );

  if (selectedDateFilterOption?.code !== 'ALL') {
    count.push(`Time: ${selectedDateFilterOption?.code}`);
  }

  if (selectedCommonFilter.selectedAssignedUsersData?.length) {
    const assignedUsers = selectedCommonFilter.selectedAssignedUsersData
      .map((item) => item.name)
      .join(',');
    count.push(`Assigned Users: ${assignedUsers}`);
  }

  const selectedMentionReadStatusOption = getSingleSelectedFilterOptionFromList(
    selectedCommonFilter?.filterSections,
    FilterSectionCode.mentionReadStatusType
  );
  const conversationFilterState: IConversationFilterState = {
    selectedInboxId: 0,
    emailSelectedInboxId: 0,
    selectedContactType: 'ALL',
    selectedDateCode: selectedDateFilterOption?.code ?? 'ALL',
    selectedAssignedFilter: 'ALL',
    selectedInboxData: {} as any,
    emailSelectedInboxData: {} as any,
    direction: '',
    isOutOfOfficeHours: '',
    status: '',
    searchQuery: '',
    webPhoneToUserId: '',
    selectedAssignedUserData: {
      uuid: selectedCommonFilter?.selectedAssignedUserData?.uuid || '',
      name: selectedCommonFilter?.selectedAssignedUserData?.name || '',
      id: !isNaN(Number(selectedCommonFilter?.selectedAssignedUserData?.id))
        ? Number(selectedCommonFilter?.selectedAssignedUserData?.id)
        : 0,
    },
    mentionReadStatus:
      selectedMentionReadStatusOption?.code ?? MENTION_READ_STATUS.READ,
    selectedAssignedUserDataWeb:
      selectedCommonFilter?.selectedAssignedUsersData || [],
  };
  return {
    conversationFilterProps: {...conversationFilterState, count},
    conversationFilterQuery: getConversationFilterData(
      conversationFilterState,
      selectedTabCode,
      {
        accountId,
        userUuid,
        groupTypeCodeIdObj,
        id: userId,
      } as any,
      assigneeFilterType,
      false,
      isEFaxMessagingEnabled,
    ),
  };
};

export const getBodyForAssignUser = (userId: any, conversationUuid?: string) => {
  const data ={
    conversationUuid: conversationUuid || '',
    assigneeUuid: userId,
  }
  return data;
}
