import { DATE_FORMATS } from "../../../constants";
import { APPOINTMENT_STATUS_CODES, CareProgramComplexityCode } from "../../../constants/MlovConst";
import { IContactCareProgramResponse, IContactCareProgramSteps, IStepsLog } from "../../../services/ContactCareProgram/interface";
import { Colors } from "../../../styles";
import { getDateStrFromFormat, getDiffInDays, getDiffInHours, getMomentObj } from "../../../utils/DateUtils";
import { CARE_PROGRAM_STEP_TYPE } from "../MemebersView/constant";


export const getAttributeByKey = (attributeKey: string, contactCarePrograms: IContactCareProgramResponse): string | undefined => {
  if (!contactCarePrograms?.additionalAttributes?.length) {
    return undefined;
  }
  const findAttribute = (contactCarePrograms.additionalAttributes).find(additionalAttribute => {
    return additionalAttribute?.attributeKey === attributeKey;
  });
  if (!findAttribute) {
    return undefined;
  }
  const attributeDateTypeCode = findAttribute.attributeValueDataTypeCode;
  let attributeValue: any = undefined;
  switch (attributeDateTypeCode) {
    case 'string':
      attributeValue = findAttribute?.attributeValue?.valueString || '';
      break;
    case 'date':
      attributeValue = getDateStrFromFormat(findAttribute?.attributeValue?.value, DATE_FORMATS.CARE_DASHBOARD_DATE_FORMAT);
      break;
  }
  return attributeValue;
};

const getEnrollmentStepId = (contactCarePrograms: IContactCareProgramResponse): string | undefined => {
  const findContactCareProgramStep: IContactCareProgramSteps | undefined = (contactCarePrograms?.contactCareProgramSteps || []).find(contactCareProgramStep => {
    return contactCareProgramStep?.careProgramStepType?.code === CARE_PROGRAM_STEP_TYPE.ENROLLMENT;
  });
  return findContactCareProgramStep?.id;
}


export const getProgramEnrollmentDateAndStatus = (contactCarePrograms: IContactCareProgramResponse, fetchStatus?: boolean) => {
  let resultString = ``;
  if (contactCarePrograms?.enrollmentLog?.enrollmentDateTime) {
    resultString = getDateStrFromFormat(contactCarePrograms.enrollmentLog.enrollmentDateTime, DATE_FORMATS.CARE_DASHBOARD_DATE_FORMAT);
  }
  if (!fetchStatus) {
    return resultString;
  }
  const enrollmentStepId: string | undefined = getEnrollmentStepId(contactCarePrograms);
  if (fetchStatus && enrollmentStepId) {
    const findStepLog: IStepsLog | undefined = (contactCarePrograms?.stepsLog || []).find(currentStepsLog => {
      return currentStepsLog?.careProgramStepId === enrollmentStepId;
    });
    if (!findStepLog?.careProgramStepId && findStepLog?.subStep?.careProgramSubStepType?.value) {
      resultString = `${findStepLog.subStep.careProgramSubStepType.value}`;
    }
  }
  return resultString;
}

export const getDayLapse = (dischargeDate: string) => {
  let diffDayHourMessage = '';
  if (dischargeDate) {
    const diffDays = getDiffInDays(
      getMomentObj(dischargeDate),
      getMomentObj(new Date())
    );
    if (diffDays > 0) {
      diffDayHourMessage = `${diffDays} ${diffDays === 1 ? 'day' : 'days'}`;
    } else {
      const diffHours = getDiffInHours(
        getMomentObj(dischargeDate),
        getMomentObj(new Date())
      );
      diffDayHourMessage =
        diffHours > 0
          ? `${diffHours} ${diffHours === 1 ? 'hour' : 'hours'}`
          : '';
    }
  }
  return diffDayHourMessage;
};

export const getDayLapseColorCode = (
  dischargeDate: string,
  pcpAppointmentDate?: string,
  programComplexity?: string,
  pcpAppointmentStatus?: string
) => {
  if (!dischargeDate) {
    return Colors.Custom.Gray500;
  }
  const diffDays = getDiffInDays(
    getMomentObj(dischargeDate),
    getMomentObj(pcpAppointmentDate || new Date())
  );
  const careProgramComplexity =
    programComplexity || CareProgramComplexityCode.SIMPLE;

  if (careProgramComplexity === CareProgramComplexityCode.COMPLEX) {
    if (
      diffDays > 7 &&
      pcpAppointmentStatus === APPOINTMENT_STATUS_CODES.CHECKED_OUT
    ) {
      return Colors.Custom.DeceasedTagColor;
    } else if (diffDays > 7) {
      return Colors.Custom.crossIconColor;
    } else {
      return Colors.Custom.Gray500;
    }
  } else {
    if (
      diffDays > 14 &&
      pcpAppointmentStatus === APPOINTMENT_STATUS_CODES.CHECKED_OUT
    ) {
      return Colors.Custom.crossIconColor;
    } else if (diffDays > 14) {
      return Colors.Custom.crossIconColor;
    } else {
      return Colors.Custom.Gray500;
    }
  }
};
