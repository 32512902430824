import {Path, Svg} from 'react-native-svg';

const NewSearchIconSvg = (props: {strokeColor?: string; width?: number; height?: number}) => {
  const strokeColor = props.strokeColor || "#6F7A90";
  const width = props.width || 16;
  const height = props.height || 16;
  return (
    <Svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
    >
      <Path
        stroke={strokeColor}
        strokeLinecap="round"
        d="M12.875 12.875L15.5 15.5m-.75-7.875a7.125 7.125 0 11-14.25 0 7.125 7.125 0 0114.25 0z"
      ></Path>
    </Svg>
  );
};

export default NewSearchIconSvg;
