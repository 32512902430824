import {useLazyQuery} from '@apollo/client';
import {Button, Center, View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {ILoginUserData} from '../../../../Interfaces';
import ConversationsQueries from '../../../../services/Conversations/ConversationsQueries';
import {IInbox} from '../../../../services/Inbox/interfaces';
import {getUserUUID} from '../../../../utils/commonUtils';
import {NoConversationsSvg} from '../../../common/Svg';
import {CHANNEL_TYPE_CODE} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {IConversationData} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import MessagingWindow from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingWindow';
import {ITimelineViewProps} from '../interfaces';
import {ILatestConversationState} from './interfaces';
import MessagingSkeleton from './MessagingSkeleton';

const LatestConversation = (props: ITimelineViewProps) => {
  const {conversation, conversationInbox} = props;
  const commonData = useContext(CommonDataContext);
  const userData = commonData?.userData || ({} as ILoginUserData);
  const intl = useIntl();
  const userUuid = getUserUUID();
  const [getConversationsById] = useLazyQuery(
    ConversationsQueries.GetConversationByConversationId,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        conversationId: props.conversation ? props.conversation.id : '',
        loginUserIntId: userData.id,
        loginUserUuid: userUuid,
      },
    }
  );

  const [latestConversation, setLatestConversation] =
    useState<ILatestConversationState>({
      isLoading: true,
      isNoDataFound: false,
      isDrawerVisible: false,
      latestConversationData: {} as IConversationData,
      inboxData: {} as IInbox,
    });

  const getConversationForSelectedContact = async () => {
    let conversationResp: any;
    if (props.conversation) {
      const response = await getConversationsById();
      conversationResp = response?.data?.conversations?.[0];
      if (response?.data?.conversations?.[0]?.id) {
        setLatestConversation((prev) => {
          return {
            ...prev,
            isLoading: false,
            isNoDataFound: false,
            inboxData: {} as any,
            latestConversationData:
              response?.data?.conversations?.[0] || ({} as IConversationData),
          };
        });
      }
    }
    if (conversationResp.id) {
      setLatestConversation((prev) => {
        return {
          ...prev,
          isLoading: false,
          isNoDataFound: false,
          inboxData: {} as any,
          latestConversationData: conversationResp || ({} as IConversationData),
        };
      });
    } else {
      setLatestConversation((prev) => {
        return {
          ...prev,
          isLoading: false,
          isNoDataFound: true,
          inboxData: {} as any,
        };
      });
    }
  };

  useEffect(() => {
    if (conversation?.id) {
      getConversationForSelectedContact();
    } else {
      setLatestConversation((prev) => {
        return {
          ...prev,
          isLoading: false,
          isNoDataFound: true,
          inboxData: {} as any,
        };
      });
    }
  }, [conversation?.id]);
  return (
    <>
      {latestConversation.isLoading ? (
        <MessagingSkeleton numOfCOnversation={8} />
      ) : (
        <>
          {latestConversation.isNoDataFound ? (
            <View height={'70vh'} justifyContent={'center'}>
              <Center
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                {/* <Image
                  resizeMode="cover"
                  width={'80px'}
                  height={'80px'}
                  source={{
                    uri: require('../../../../assets/images/conversationNew.png'),
                  }}
                  alt={'Alternate Text xs'}
                /> */}

                {/* <NoDataFound displayString="noConversationFound" /> */}
                <NoConversationsSvg titleId="noConversationFound" />
                {props.personData?.patientUuid && (
                  <>
                    <Button
                      // mt={5}
                      onPress={() => {
                        setLatestConversation((prev) => {
                          return {
                            ...prev,
                            isDrawerVisible: true,
                          };
                        });
                      }}
                      minWidth={8}
                      variant="solid"
                    >
                      {intl.formatMessage({id: 'startConversation'})}
                    </Button>
                  </>
                )}
              </Center>
            </View>
          ) : (
            <View flex={1}>
            <MessagingWindow
              moduleCode={`MessagingWindow/${CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET}`}
              isDisplayHeader={false}
              selectedConversation={latestConversation.latestConversationData}
              onConversationActionPerformed={(actionCode: any, actionData: any) => {
              }}
              selectedInboxTypeCode={CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET}
              conversationInbox={conversationInbox}
            />
            </View>
          )}
        </>
      )}
    </>
  );
};
export default LatestConversation;
