
import {Path, Svg} from 'react-native-svg';

const EditSvgV2 = (props: {strokeColor?: string; size?: number;}) => {
  const strokeColor = props.strokeColor || "#6F7A90";
  const size = props?.size || 16;

  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <Path d="M9.25878 3.77537L9.75312 3.28103C10.5722 2.46199 11.9001 2.46199 12.7191 3.28103C13.5382 4.10007 13.5382 5.428 12.7191 6.24704L12.2248 6.74138M9.25878 3.77537C9.25878 3.77537 9.32058 4.82583 10.2475 5.75271C11.1743 6.67959 12.2248 6.74138 12.2248 6.74138M9.25878 3.77537L4.71412 8.32003C4.4063 8.62785 4.25239 8.78176 4.12003 8.95146C3.96389 9.15165 3.83002 9.36825 3.7208 9.59743C3.62821 9.79171 3.55938 9.9982 3.42171 10.4112L2.83838 12.1612M12.2248 6.74138L7.68014 11.286C7.37232 11.5939 7.21841 11.7478 7.0487 11.8801C6.84852 12.0363 6.63192 12.1701 6.40274 12.2794C6.20845 12.372 6.00196 12.4408 5.58898 12.5784L3.83897 13.1618M3.83897 13.1618L3.4112 13.3044C3.20796 13.3721 2.9839 13.3192 2.83242 13.1677C2.68094 13.0163 2.62804 12.7922 2.69579 12.589L2.83838 12.1612M3.83897 13.1618L2.83838 12.1612"
     stroke={strokeColor}/>
    </Svg>
  );
};


export default EditSvgV2;

