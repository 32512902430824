import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../styles/Colors';
export const styles = StyleSheet.create({
  iconView: {
    // flex: 1,
    // flexDirection: 'row',
    backgroundColor: 'transparent',
    // marginRight: 16,
    // paddingRight: 16
  },
  icons: {
    fontSize: 18,
    color: '#4F2D90',
    padding: 0,
  },
  stopIcons: {
    fontSize: 18,
    color: '#C11B34',
  },
  actionIcons: {
    fontSize: 18,
    color: '#4F2D90',
  },
  btn: {
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
  },
  lastMenu: {
    paddingBottom: 15,
  },
  mainMenuTxt: {
    marginLeft: 10
  },
  dividerStyle: {
    width: '100%',
  },
  mainMenuContent: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 2,
    cursor: 'pointer',
  },
  firstMenu: {
    paddingTop: 10,
  },
  personActionsPopoverBody: {
    minWidth: 170,
    height: 'auto',
    boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 15px 1px',
    backgroundColor: '#FFF',
    paddingTop: 3,
  },
});
