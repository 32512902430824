import {ANALYTICS_RESOURCE} from "../../../../../constants";
import {getUniqueObjectFromKey} from "../../../../../utils/commonUtils";
import {IMultiPlotResult, IMultiViewResourceData} from "../interface";

export const isDataPresentForRenderWidget = (resourceData: any, resourceCode: string, additionalCode?: string) => {
  let isDataPresent = false;
  if (additionalCode) {
    isDataPresent = isDataPresentForRenderWidgetFromAdditionalCode(resourceData, additionalCode);
  } else if (resourceCode) {
    isDataPresent = isDataPresentForRenderWidgetFromResourceCode(resourceData, resourceCode);
  }
  return isDataPresent;
}

const isDataPresentForRenderWidgetFromResourceCode = (resourceData: any, resourceCode: string) => {
  let isDataPresent = false;
  switch (resourceCode) {
    case ANALYTICS_RESOURCE.PRE_DPC_VS_POST_DPC: {
      const tempResourceData: IMultiPlotResult = resourceData;
      if (tempResourceData?.perDpcDataAvgList?.length || tempResourceData?.postDpcDataAvgList?.length) {
        isDataPresent = true;
      }
      break;
    }
  }
  return isDataPresent;
}

const isDataPresentForRenderWidgetFromAdditionalCode = (resourceData: any, code: string) => {
  const isDataPresent = false;

  return isDataPresent
}


export const getDataForTooltipForPreAndPostDPC = (data: {
  preOrPostDpcCost: any[],
  avgPreDpcCost: any[],
  avgPostDpcCost: any[],
  preDpcMemberCount: any[],
  postDpcMemberCount: any[],
}, selectedData: any) => {
  const tempDataList: any = [];
  if (selectedData?.week) {
    tempDataList.push({
      name: selectedData?.type,
      type: selectedData?.type,
      value: selectedData?.value,
      code: selectedData?.filterCode
    });
    if (selectedData?.filterCode === 'PRE_DPC') {
      for (let index = 0; index < data?.avgPreDpcCost.length; index++) {
        const singleItem = data?.avgPreDpcCost[index];
        if (singleItem?.week === selectedData?.week) {
          tempDataList.push({
            name: singleItem?.type,
            type: singleItem?.type,
            value: singleItem?.value
          });
        }
      }
      for (let index = 0; index < data?.preDpcMemberCount.length; index++) {
        const singleItem = data?.preDpcMemberCount[index];
        if (singleItem?.week === selectedData?.week) {
          tempDataList.push({
            name: singleItem?.type,
            type: singleItem?.type,
            value: singleItem?.value
          });
        }
      }
    } else {
      for (let index = 0; index < data?.avgPostDpcCost.length; index++) {
        const singleItem = data?.avgPostDpcCost[index];
        if (singleItem?.week === selectedData?.week) {
          tempDataList.push({
            name: singleItem?.type,
            type: singleItem?.type,
            value: singleItem?.value
          });
        }
      }
      for (let index = 0; index < data?.postDpcMemberCount.length; index++) {
        const singleItem = data?.postDpcMemberCount[index];
        if (singleItem?.week === selectedData?.week) {
          tempDataList.push({
            name: singleItem?.type,
            type: singleItem?.type,
            value: singleItem?.value
          });
        }
      }
    }
  }
  const finalDataList = getUniqueObjectFromKey(tempDataList, 'type');
  return finalDataList;
}
