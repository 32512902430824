import React, { createContext, useContext, useReducer, Dispatch } from 'react';
import { patientDashboardReducer, initialPatientDashboardState } from './patientDashboardReducer';
import { DayOptimizerState, DayOptimizerContextType, DayOptimizerAction } from './interfaces';

export const DayOptimizerContext = createContext({} as DayOptimizerContextType);

export const useDayOptimizer = () => {
  const context = useContext(DayOptimizerContext);
  if (!context) {
    throw new Error('useDayOptimizer must be used within a DayOptimizerProvider');
  }
  return context;
};

export const DayOptimizerProvider = ({ children }: { children: React.ReactNode }) => {
  const [patientDashboardState, patientDashboardDispatch] = useReducer(patientDashboardReducer, initialPatientDashboardState);

  const state: DayOptimizerState = {
    patientDashboard: patientDashboardState,
  };

  const dispatch = (action: DayOptimizerAction) => {
    patientDashboardDispatch(action);
  };

  const value: DayOptimizerContextType = {
    state,
    dispatch
  };
  return (
    <DayOptimizerContext.Provider value={value}>
      {children}
    </DayOptimizerContext.Provider>
  );
};
