import React, {useEffect, useState} from 'react';
import Stack from '../../../common/LayoutComponents/Stack';
import EFaxInboxTable from './EFaxInboxTable';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
} from '../../../../constants/ActionConst';
import {IEFaxInbox} from '../Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/interface';
import AddOrUpdateEFaxInboxDrawer from './AddOrUpdateEFaxInboxDrawer';
import {EFAX_DRAWER_CODE} from '../../../../constants';
import {IEFaxInboxSettings} from './interface';

const EFaxInboxSettings = (props: IEFaxInboxSettings) => {
  const [state, setState] = useState({
    showUpdateDrawer: false,
    selectedInbox: {} as IEFaxInbox,
  });

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        showUpdateDrawer: props?.addEFaxInboxDrawer,
      };
    });
  }, [props?.addEFaxInboxDrawer]);

  const setAction = (actionCode: string, record?: IEFaxInbox): any => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        setState((prev) => {
          return {
            ...prev,
            showUpdateDrawer: true,
            selectedInbox: record || ({} as IEFaxInbox),
          };
        });
        break;
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        setState((prev) => {
          return {
            ...prev,
            showUpdateDrawer: false,
            selectedInbox: {} as IEFaxInbox,
          };
        });
        props?.onActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE);
    }
  };

  return (
    <Stack direction="column">
      <EFaxInboxTable setAction={setAction} enableUpdateDrawer={state.showUpdateDrawer}/>

      {state.showUpdateDrawer && (
        <AddOrUpdateEFaxInboxDrawer
          visible={state.showUpdateDrawer}
          selectedInbox={state.selectedInbox}
          onActionPerformed={setAction}
          drawerCode={
            props?.addEFaxInboxDrawer
              ? EFAX_DRAWER_CODE.ADD_INBOX
              : EFAX_DRAWER_CODE.UPDATE_INBOX
          }
        />
      )}
    </Stack>
  );
};

export default EFaxInboxSettings;
