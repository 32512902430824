import { HStack, View } from 'native-base';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlowType } from '../../../context/WorkflowContext';
import { Colors } from '../../../styles';
import CustomTabsView from '../../common/CustomTabsView/CustomTabsView';
import { ITabsList } from '../../common/CustomTabsView/interfaces';
import { TABLE_TOP_BAR_ACTION_CODES, TableTopBar } from '../../common/TableTopBar';
import TitleSubtitleView from '../../common/TitleSubtitleView/TitleSubtitleView';
import AutomationTopBar from '../Contacts/PatientsAdminPage/AutomationTopBar';
import WorkflowView from '../Workflow/Workflow/WorkflowView';
import TaskManagementTableList from './TaskManagementTable/TaskManagementTable';
import TaskPoolConfigurationView from './TaskPoolConfigurationView/TaskPoolConfigurationView';
import TagView from '../Contacts/Tags/TagView';
import { getMlovListFromCategory } from '../../../utils/mlovUtils';
import { BUTTON_TYPE, MLOV_CATEGORY } from '../../../constants';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { LABEL_TYPE_CODES } from '../../../constants/MlovConst';
import PlusIcon from '../../common/Svg/PlusSvg';
import { Dimensions } from 'react-native';
import {useIntl} from 'react-intl';

const TaskManagementView = () => {
  const basePath = '/admin/taskManagement';
  const commonData = useContext(CommonDataContext);
  const labels = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );

  const filteredResult = labels?.filter((item) => {
    return item.code === LABEL_TYPE_CODES.TASK;
  });
  const mlov = filteredResult[0];
  const [searchString, setSearchString] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const screenDimensions = Dimensions.get('window');
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const [componentState,setComponentState] = useState<{
    selectedActionCode: string,
    selectedRawData: any,
  }>({
    selectedActionCode: '',
    selectedRawData: '' as any,
  })
  const timeOutRefs = useRef<NodeJS.Timeout[]>([]);
  useEffect(() => {
    return () => {
      timeOutRefs.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    }
  }, []);

  const tagsTabList = (): ITabsList[] => {
    const taskDetailTabView: ITabsList[] = [
            // {
      //   key: 'automation',
      //   path: 'automation',
      //   title: 'Automation',
      //   tabContainerElem: () => {
      //     return <View>Automation</View>;
      //   },
      // },
      // {
      //   key: 'automation',
      //   path: 'automation',
      //   title: 'Automations',
      //   tabContainerElem: () => {
      //     return (
      //       <View
      //         bgColor={'white'}
      //         borderWidth={1}
      //         borderColor={Colors.Custom.Gray200}
      //         borderRadius={8}
      //         style={{marginHorizontal: 16}}
      //       >
      //         <WorkflowView
      //           searchText={searchString}
      //           showTableTopBar={false}
      //           flowType={FlowType.task}
      //         />
      //       </View>
      //     );
      //   },
      // },
      {
        key: 'task_pool',
        path: 'task_pool',
        title: 'Task Pools',
        tabContainerElem: () => {
          return <TaskManagementTableList
              searchString={searchString}
              selectedActionCode={componentState.selectedActionCode}
              selectedRawData={componentState.selectedRawData}
              onClose={onTableTopBarActionPerformed}
              showTableTopBar={false}
          />;
        },
      },
      {
        key: 'manage_tasks',
        title: 'Manage Tasks',
        path: 'manage_tasks',
        tabContainerElem: () => {
          return <TaskPoolConfigurationView
          showTableTopBar={true}
          searchString={searchString}
          />;
        },
      },
      {
        key: 'tags',
        path: 'tags',
        title: 'Labels',
        tabContainerElem: () => {
          return (
            <TagView
              searchString={searchString}
              openModal={openModal}
              category={mlov}
              showTableTopBar={false}
              onViewChangeCallback={() => {
                setOpenModal(false);
              }}
            />
          );
        },
      }
    ];

    return taskDetailTabView;
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        handleSearchChange(actionData);
        break;
      case TABLE_TOP_BAR_ACTION_CODES.CLOSE:
            setComponentState((prev) => {
              return {
                ...prev,
                selectedActionCode: TABLE_TOP_BAR_ACTION_CODES.CLOSE,
              };
            });
          break;
    }
  };

  const onTabClick = (tab: ITabsList) => {
    if (selectedTab?.key !== tab.key) {
    setComponentState(prev => ({
      ...prev,
      selectedRawData: ''
    }))
    setSearchString('');
    const timeOutId = setTimeout(() => {
      setSelectedTab(tab);
    }, 10);
    timeOutRefs.current.push((timeOutId))
  }
  };

  const handleSearchChange = (actionData: any) => {
    setSearchString(actionData.searchString);
  };
  const navigate = useNavigate();
  const workflowUrl = `create?currentTag=TRIGGER&flowType=${FlowType.task}`;

  const intl = useIntl();
  const TagBar: JSX.Element = (
    <View>
      <TableTopBar
        title={``}
        onActionPerformed={onTableTopBarActionPerformed}
        alignSearchBarFlexEnd={true}
        isDividerNotVisible={true}
        searchText={searchString}
        placeHolderText={intl.formatMessage({id: 'searchLabel'})}
        buttonList={[
          {
            btnText: 'new',
            size: 'smBold',
            textColor: Colors.Custom.mainPrimaryPurple,
            borderColor: Colors.Custom.mainPrimaryPurple,
            backgroundColor: '#ffffff00',
            variant: BUTTON_TYPE.PRIMARY,
            leftIcon: <PlusIcon/>,
            btnClick: () => setOpenModal(true),
          },
        ]}
      />
    </View>
  );

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele = <HStack padding={30}></HStack>;
    if (selectedTab.key === 'automation') {
      ele = <AutomationTopBar workflowUrl={workflowUrl} onTableTopBarActionPerformed={onTableTopBarActionPerformed}></AutomationTopBar>;
    } else if (selectedTab.key === 'tags') {
      ele = TagBar
    } else if (selectedTab.key ===  'task_pool') {
      ele = (
        <TableTopBar
          title={''}
          selectedTab={selectedTab?.key}
          alignSearchBarFlexEnd={true}
          isDividerNotVisible={true}
          hideSearchBar={
            screenDimensions?.width < 1700
          }
          onActionPerformed={(actionCode: string, actionData: any) => {
            onTableTopBarActionPerformed(actionCode, actionData);
          }}
          placeHolderText={intl.formatMessage({id: 'searchTaskPool'})}
          buttonList={[
            {
              btnText: 'new',
              backgroundColor: Colors.secondary['100'],
              borderColor: Colors.secondary['200'],
              textColor: '',
              variant: 'PRIMARY',
              size: 'sm',
              leftIcon: <PlusIcon />,
              isTransBtn: true,
              btnClick: () => {
                setComponentState((prev) => ({
                  ...prev,
                  selectedActionCode: TABLE_TOP_BAR_ACTION_CODES.NEW,
                }));
              },
            },
          ]}
        />
      );
    } else if (selectedTab.key ===  'manage_tasks') {
      ele = (
        <TableTopBar
          title={''}
          selectedTab={selectedTab?.key}
          alignSearchBarFlexEnd={true}
          isDividerNotVisible={true}
          hideSearchBar={
            screenDimensions?.width < 1700
          }
          onActionPerformed={(actionCode: string, actionData: any) => {
            onTableTopBarActionPerformed(actionCode, actionData);
          }}
          placeHolderText={intl.formatMessage({id: 'searchUser'})}
        />
      );
    }
    return ele;
  };

  return (
    <View>
      {/* <View backgroundColor={'#fff'}>
        <TitleSubtitleView
          titleLabelId="taskManagementTitle"
          subtitleLabelId="taskManagementTitleSubtitle"
        />
      </View> */}
      {/* <View
        style={[
          styles.tabsWrapper,
          commonBodyContentContainer.pageContentContainer,
        ]}
      > */}
        <CustomTabsView
          contentTopMargin={0}
          bottomOffset={126}
          heading="taskSettingTitle"
          extraBigRightView
          showHeading
          canNavigate={true}
          basePath={basePath}
          rightView={(activeTab) => {
            return getSelectedTopBar(activeTab);
          }}
          tabsList={tagsTabList()}
          onTabClick={(tabItem) => {
            onTabClick(tabItem);
          }}
          tabListWrapperStyle={{
            //paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
        />
      {/* </View> */}
    </View>
  );
};

export default TaskManagementView;
