import {PERSON_TYPES} from '../../../constants';
import {ITabDataProps} from '../../common/TabList/interface';
import {IMiddleContainerTab} from './interfaces';
import {getActionIconFromCode} from './QuickActions/QuickActionsIcons';

export const QUICK_ACTION_CODES = {
  NEW_NOTE: 'NEW_NOTE',
  NEW_MESSAGE: 'NEW_MESSAGE',
  NEW_EMAIL: 'NEW_EMAIL',
  FILTER: 'FILTER',
};

export const TAB_CODES = {
  ACTIVITY: 'ACTIVITY',
  NOTES: 'NOTES',
  CONVERSATION: 'CONVERSATION',
  EMAIL: 'EMAIL',
  TASK: 'TASK',
  SMS: 'SMS',
  CAMPAIGN: 'CAMPAIGN',
  JOURNEYS: 'JOURNEYS',
  TASKS: 'TASKS',
  CRM: 'CRM',
  PAMI: 'PAMI',
};

export const QUICK_ACTION_LIST = [
  {
    displayString: 'New Note',
    actionCode: QUICK_ACTION_CODES.NEW_NOTE,
    leftIcon: getActionIconFromCode(QUICK_ACTION_CODES.NEW_NOTE),
  },
  {
    displayString: 'New Message',
    actionCode: QUICK_ACTION_CODES.NEW_MESSAGE,
    leftIcon: getActionIconFromCode(QUICK_ACTION_CODES.NEW_MESSAGE),
  },
  {
    displayString: 'New Email',
    actionCode: QUICK_ACTION_CODES.NEW_EMAIL,
    leftIcon: getActionIconFromCode(QUICK_ACTION_CODES.NEW_EMAIL),
  },
  {
    displayString: 'Filter',
    actionCode: QUICK_ACTION_CODES.FILTER,
    leftIcon: getActionIconFromCode(QUICK_ACTION_CODES.NEW_EMAIL),
  },
];

export const TAB_LIST: ITabDataProps[] = [
  {
    tabCode: TAB_CODES.ACTIVITY,
    title: 'Activity',
  },
  {
    tabCode: TAB_CODES.NOTES,
    title: 'Notes',
  },
  {
    tabCode: TAB_CODES.CONVERSATION,
    title: 'Conversation',
  },
  {
    tabCode: TAB_CODES.EMAIL,
    title: 'Email',
  },
  {
    tabCode: TAB_CODES.TASK,
    title: 'Task',
  },
  {
    tabCode: TAB_CODES.SMS,
    title: 'SMS',
  },
  {
    tabCode: TAB_CODES.CAMPAIGN,
    title: 'Campaign',
  },
  {
    tabCode: TAB_CODES.JOURNEYS,
    title: 'Journeys',
  },
  {
    tabCode: TAB_CODES.TASKS,
    title: 'Tasks',
  },
];

export const DISPLAY_VALUES_BY_PERSON_TYPE = {
  [PERSON_TYPES.VISITOR]: [
    QUICK_ACTION_CODES.NEW_NOTE,
    QUICK_ACTION_CODES.NEW_MESSAGE,
    QUICK_ACTION_CODES.NEW_EMAIL,
    QUICK_ACTION_CODES.FILTER,
  ],
  [PERSON_TYPES.LEAD]: [
    QUICK_ACTION_CODES.NEW_NOTE,
    QUICK_ACTION_CODES.NEW_MESSAGE,
    QUICK_ACTION_CODES.NEW_EMAIL,
    QUICK_ACTION_CODES.FILTER,
  ],
  [PERSON_TYPES.SUPPORTING_MEMBER]: [
    QUICK_ACTION_CODES.NEW_NOTE,
    QUICK_ACTION_CODES.NEW_MESSAGE,
    QUICK_ACTION_CODES.NEW_EMAIL,
    QUICK_ACTION_CODES.FILTER,
  ],
  [PERSON_TYPES.OTHER]: [
    QUICK_ACTION_CODES.NEW_NOTE,
    QUICK_ACTION_CODES.NEW_MESSAGE,
    QUICK_ACTION_CODES.NEW_EMAIL,
    QUICK_ACTION_CODES.FILTER,
  ],
  [PERSON_TYPES.CUSTOMER]: [
    QUICK_ACTION_CODES.NEW_NOTE,
    QUICK_ACTION_CODES.NEW_MESSAGE,
    QUICK_ACTION_CODES.NEW_EMAIL,
  ],
  [PERSON_TYPES.TEAM_MEMBER]: [
    QUICK_ACTION_CODES.NEW_NOTE,
    QUICK_ACTION_CODES.NEW_MESSAGE,
    QUICK_ACTION_CODES.NEW_EMAIL,
  ],
};

export const DISPLAY_TABS_BY_PERSON_TYPE = {
  [PERSON_TYPES.VISITOR]: [
    TAB_CODES.ACTIVITY,
    TAB_CODES.NOTES,
    TAB_CODES.CONVERSATION,
    TAB_CODES.EMAIL,
    TAB_CODES.TASK,
    TAB_CODES.SMS,
    TAB_CODES.CAMPAIGN,
    TAB_CODES.JOURNEYS,
  ],
  [PERSON_TYPES.LEAD]: [
    TAB_CODES.ACTIVITY,
    TAB_CODES.NOTES,
    TAB_CODES.CONVERSATION,
    TAB_CODES.EMAIL,
    TAB_CODES.TASK,
    TAB_CODES.SMS,
    TAB_CODES.JOURNEYS,
  ],
  [PERSON_TYPES.SUPPORTING_MEMBER]: [
    TAB_CODES.ACTIVITY,
    TAB_CODES.NOTES,
    TAB_CODES.CONVERSATION,
    TAB_CODES.EMAIL,
    TAB_CODES.TASK,
    TAB_CODES.SMS,
    TAB_CODES.JOURNEYS,
  ],
  [PERSON_TYPES.OTHER]: [
    TAB_CODES.ACTIVITY,
    TAB_CODES.NOTES,
    TAB_CODES.CONVERSATION,
    TAB_CODES.EMAIL,
    TAB_CODES.TASK,
    TAB_CODES.SMS,
    TAB_CODES.JOURNEYS,
  ],
  [PERSON_TYPES.CUSTOMER]: [
    TAB_CODES.ACTIVITY,
    TAB_CODES.NOTES,
    TAB_CODES.CONVERSATION,
    TAB_CODES.EMAIL,
    TAB_CODES.TASK,
    TAB_CODES.SMS,
    TAB_CODES.JOURNEYS,
    TAB_CODES.TASKS,
  ],
  [PERSON_TYPES.TEAM_MEMBER]: [
    TAB_CODES.ACTIVITY,
    TAB_CODES.NOTES,
    TAB_CODES.CONVERSATION,
    TAB_CODES.EMAIL,
    TAB_CODES.TASK,
    TAB_CODES.SMS,
  ],
};

export const MIDDLE_CONTAINER_TABS = {
  TIMELINE: 'TIMELINE',
  DETAILS: 'DETAILS',
  MESSAGES: 'MESSAGES',
  JOURNEYS: 'JOURNEYS',
  TASKS: 'TASKS',
  NOTES: 'NOTES',
  FORM_RESPONSES: 'FORM_RESPONSES',
  ORDERS: 'ORDERS',
  DOCUMENTS: 'DOCUMENTS',
  CARE_PLAN: 'CARE_PLAN',
  ALERTS: 'ALERTS',
  CARE_MANAGEMENT: 'CARE_MANAGEMENT'
};

export const MIDDLE_CONTAINER_TABS_LIST: IMiddleContainerTab[] = [
  {
    tabCode: MIDDLE_CONTAINER_TABS.TIMELINE,
    tabTitle: 'timeline',
  },
  {
    tabCode: MIDDLE_CONTAINER_TABS.ALERTS,
    tabTitle: 'Alerts',
  },
  {
    tabCode: MIDDLE_CONTAINER_TABS.CARE_PLAN,
    tabTitle: 'carePlan',
  },
  {
    tabCode: MIDDLE_CONTAINER_TABS.NOTES,
    tabTitle: 'notes',
  },
  {
    tabCode: MIDDLE_CONTAINER_TABS.ORDERS,
    tabTitle: 'orders',
  },
  {
    tabCode: MIDDLE_CONTAINER_TABS.JOURNEYS,
    tabTitle: 'journeys',
  },
  {
    tabCode: MIDDLE_CONTAINER_TABS.CARE_MANAGEMENT,
    tabTitle: 'careManagement',
  },

  // {
  //   tabCode: MIDDLE_CONTAINER_TABS.TASKS,
  //   tabTitle: 'tasks',
  // },
  // {
  //   tabCode: MIDDLE_CONTAINER_TABS.NOTES,
  //   tabTitle: 'notes',
  // },
  {
    tabCode: MIDDLE_CONTAINER_TABS.FORM_RESPONSES,
    tabTitle: 'formResponses',
  },
  {
    tabCode: MIDDLE_CONTAINER_TABS.DOCUMENTS,
    tabTitle: 'documents',
  },

];

export const MIDDLE_CONTAINER_TABS_BY_PERSON_TYPE = {
  [PERSON_TYPES.CUSTOMER]: [
    MIDDLE_CONTAINER_TABS.DETAILS,
    MIDDLE_CONTAINER_TABS.TIMELINE,
    MIDDLE_CONTAINER_TABS.ALERTS,
    MIDDLE_CONTAINER_TABS.JOURNEYS,
    MIDDLE_CONTAINER_TABS.CARE_MANAGEMENT,
    MIDDLE_CONTAINER_TABS.MESSAGES,
    MIDDLE_CONTAINER_TABS.TASKS,
    MIDDLE_CONTAINER_TABS.NOTES,
    MIDDLE_CONTAINER_TABS.FORM_RESPONSES,
    MIDDLE_CONTAINER_TABS.ORDERS,
    MIDDLE_CONTAINER_TABS.DOCUMENTS,
    MIDDLE_CONTAINER_TABS.CARE_PLAN,
  ],
  [PERSON_TYPES.LEAD]: [
    MIDDLE_CONTAINER_TABS.MESSAGES,
    MIDDLE_CONTAINER_TABS.FORM_RESPONSES,
  ],
  [PERSON_TYPES.SUPPORTING_MEMBER]: [
    MIDDLE_CONTAINER_TABS.MESSAGES,
    MIDDLE_CONTAINER_TABS.FORM_RESPONSES,
  ],
  [PERSON_TYPES.OTHER]: [
    MIDDLE_CONTAINER_TABS.MESSAGES,
    MIDDLE_CONTAINER_TABS.FORM_RESPONSES,
  ],
};

export const MOBILE_OMNIVIEW_TABS = [
  {
    tabCode: TAB_CODES.CRM,
    title: 'Crm',
  },
  {
    tabCode: TAB_CODES.PAMI,
    title: 'Crm',
  },
];
