import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles/Colors';
export const styles = StyleSheet.create({
  titleWrapper: {
    // marginHorizontal: 22,
    // marginVertical: 12,
  },

  tabsWrapper: {
    // marginHorizontal: 14,
  },

  title: {
    textTransform: 'uppercase',
    fontWeight: '400',
    letterSpacing: 0.5,
    // fontSize: 15,
    fontSize: 30,
    lineHeight: 35,
  },
  titleView: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 20,
    backgroundColor: '#FAFAFA',
  },

  menuItem: {
    padding: 15,
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  menuItemFocused: {
    padding: 15,
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderRightColor: Colors.Custom.PrimaryColor,
    borderRightWidth: 3,
    backgroundColor: '#EDEDED',
  },

  menuListView: {
    flex: 1,
    flexDirection: 'column',
    borderTopColor: Colors.Custom.BorderColor,
    borderTopWidth: 1,
  },
  left: {
    paddingTop: 15,
    paddingRight: 0,
    paddingLeft: 24,
    paddingBottom: 15,
  },
  leftText: {
    fontSize: 14,
    lineHeight: 32,
    marginRight: 10,
  },
});
