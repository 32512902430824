import React from 'react';
import {MemberCareJourneyProps, PatientCareJourney} from '../../../interfaces';
import {Divider, HStack, Text, View, VStack, Skeleton} from 'native-base';
import {Popover} from 'antd';

const MemberCareJourney = (props: MemberCareJourneyProps) => {
  const {contactData, data} = props;
  // For care journey we only use patient uuid not the external patientId
  const patientId =
    /*contactData?.patient?.patientId ||*/ contactData?.patient?.patientUuid;
  const isJourneyDataPropAvailable = !!(Object.keys(data || {}).length > 0);
  const patientCareJourneys: PatientCareJourney[] = isJourneyDataPropAvailable
    ? data?.map?.get?.(patientId)
    : contactData?.careJourney;

  const showPopover = patientCareJourneys?.length > 0;

  const isCurrentMenuLast = (
    index: number,
    careJourneyList: any[]
  ): boolean => {
    return index < careJourneyList.length - 1;
  };

  if (!!isJourneyDataPropAvailable ? data?.loading : (patientCareJourneys === undefined)) {
    return <Skeleton.Text lines={1} w={20} />;
  }

  return (
    <HStack style={{flexWrap: 'wrap'}}>
      {patientCareJourneys?.length === 0 && <Text>--</Text>}
      {showPopover && (
        <Popover
          content={
            <VStack>
              {patientCareJourneys?.map((item, index) => {
                return (
                  <View
                    style={{
                      marginVertical: 2,
                    }}
                  >
                    <Text
                      style={{
                        marginHorizontal: 4,
                        marginVertical: 2,
                      }}
                    >
                      {item?.title || item?.careJourney?.title || ''}
                    </Text>
                    {isCurrentMenuLast(index, patientCareJourneys) && (
                      <Divider />
                    )}
                  </View>
                );
              })}
            </VStack>
          }
        >
          <Text>{`${patientCareJourneys?.length}`}</Text>
        </Popover>
      )}
    </HStack>
  );
};

export default MemberCareJourney;
