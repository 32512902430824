import { useLazyQuery } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../constants/Configs';
import { useContext, useEffect, useState } from 'react';
import { GET_CARE_PROGRAM_BILLABLE_CODES } from '../../../../../CareManagementBilling/BillingQueries';
import { useContactCareProgramContext } from '../../../ContactCareProgram.context';
import { IContactCareProgramBillableActivity } from './BillingInterface';
import { getMlovIdFromCode, getMlovListFromCategory, getMlovValueFromId } from '../../../../../../../utils/mlovUtils';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import { MLOV_CATEGORY } from '../../../../../../../constants';
import { BILLING_ACTIVITY_STATUS } from '../../../../../../../constants/MlovConst';
import { getMonthRangeFromDate } from '../../../../../../../utils/DateUtils';

interface IContactCareProgramBillingHookState {
  attributesAndCodes: IContactCareProgramBillableActivity[];
  totalBilling: number;
}


export const useContactCareProgramBilling = (params: {
  selectedDate?: string;
  showOnlyCompleted?: boolean;
}) => {
  // Constants
  const {state} = useContactCareProgramContext();
  const contextData = useContext(CommonDataContext);
  const billableActivityStatusMlov = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CONTACT_CARE_PROGRAM_BILLABLE_ACTIVITY_STATUS) || [];
  const pendingStatusId = getMlovIdFromCode(billableActivityStatusMlov, BILLING_ACTIVITY_STATUS.PENDING);
  const completedStatusId = getMlovIdFromCode(billableActivityStatusMlov, BILLING_ACTIVITY_STATUS.COMPLETED);

  // States
  const [hookState, setHookState] = useState<IContactCareProgramBillingHookState>({
    attributesAndCodes: [],
    totalBilling: 0,
  });

  // Hooks
  const [getBillAttributeAndCodes, { loading: attributeAndCodesLoading }] = useLazyQuery(GET_CARE_PROGRAM_BILLABLE_CODES, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const billableActivities: IContactCareProgramBillableActivity[] = data?.contactCareProgramBillableActivities || [];
      let totalBilling = 0;
      billableActivities.forEach((activity) => {
        activity.totalDollars = activity.billableActivityCptCodes.reduce((acc, curr) => acc + curr.careProgramCptCode.amount, 0);
        activity.durationInMinutes = activity.billableActivityCptCodes.reduce((acc, curr) => acc + curr.careProgramCptCode.durationInMinutes, 0);
        activity.cptCodes = activity.billableActivityCptCodes.map((cpt) => cpt.careProgramCptCode.cptCode);
        activity.status = getMlovValueFromId(billableActivityStatusMlov, activity.statusId);
        totalBilling += activity.totalDollars;
      });
      setHookState((prev) => {
        return {
          ...prev,
          attributesAndCodes: billableActivities,
          totalBilling
        }
      })
    }
  });

  // Side Effects
  useEffect(() => {
    if (state.contactCareProgramDetails?.id) {
      const monthRange = getMonthRangeFromDate(params.selectedDate);
      getBillAttributeAndCodes({
        variables: {
          where: {
            isDeleted: {
              _eq: false
            },
            statusId: {
              _in: params.showOnlyCompleted ? [completedStatusId] : [pendingStatusId, completedStatusId]
            },
            contactCareProgramId: {
              _eq: state.contactCareProgramDetails?.id
            },
            timestamp: {
              _gte: monthRange.startDate,
              _lte: monthRange.endDate
            }
          }
        }
      });
    }
  }, [state.contactCareProgramDetails?.id, params.selectedDate]);


  return {
    hookState,
    attributeAndCodesLoading,
  }

};
