import {
  Button,
  FormControl,
  Input,
  Text,
  useToast,
  View,
  VStack
} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../constants';
import CommonService from '../../../../../services/CommonService/CommonService';
import {Colors} from '../../../../../styles';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import LoadingSpinner from '../../../../common/Loader/LoadingSpinner';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {styles} from '../PracticeDetails/PracticeDetailsStyles';
const {Label, ErrorMessage} = FormControl;

const PracticeDetails = () => {
  const commonService = CommonService.getCommonServiceInstance();
  const integrationService = commonService.integrationService;
  const toast = useToast();
  const intl = useIntl();
  const [state, setState] = useState({
    submitting: false,
    token: '',
    sourceId: '',
    formError: {} as any,
    error: false
  });

  const [showLoader, setShowLoader] = useState(false);

  const setupToken = () => {
    const url = '/api/integrate/caliber-token';
    setShowLoader(true);
    const body = {
      token: state.token,
      sourceId: state.sourceId,
      ehrName: 'ELATION_CALIBER'
    };
    integrationService
      .post(url, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp: {data: any}) => {
        if (resp?.data) {

          showToast(
            toast,
            `Client info saved`,
            ToastType.info
          );
          setShowLoader(false);
        }
      })
      .catch((e: any) => {

        setShowLoader(false);
        false;
        showToast(
          toast,
          `Token could not be synced. Please contact support at hello@fold.health`,
          ToastType.error
        );
      });
    setShowLoader(false);
    showToast(
      toast,
      `Token Data is being synced. We will notify you once the import is complete.`,
      ToastType.info
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <Text size={'lgBold'} ml={2}>
          Elation Setup
        </Text>

        <View style={styles.dataContainer}>
          <FormControl style={styles.formContainer} isRequired>
            <Label style={styles.formLabel}>
              <Text size={'smMedium'} color={Colors.Custom.Gray700}>
                Elation SourceId
              </Text>
            </Label>
            <Input
              height={44}
              fontSize={16}
              onChangeText={(value) => {
                setState((prev) => {
                  return {
                    ...prev,
                    sourceId: value
                  };
                });
              }}
            />
          </FormControl>
          <FormControl style={styles.formContainer} isRequired>
            <Label style={styles.formLabel}>
              <Text size={'smMedium'} color={Colors.Custom.Gray700}>
                Elation Token
              </Text>
            </Label>
            <Input
              height={44}
              fontSize={16}
              onChangeText={(value) => {
                setState((prev) => {
                  return {
                    ...prev,
                    token: value
                  };
                });
              }}
            />
          </FormControl>
        </View>
        <VStack
          style={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flex: 0.5,
            marginTop: 20
          }}
        >
          <Button.Group justifyContent={'flex-end'}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  setupToken();
                },
                style: {marginRight: 20}
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'Save Token'
                }),
                withRightBorder: false
              }}
            ></FoldButton>
          </Button.Group>
        </VStack>
      </View>
      {showLoader && <LoadingSpinner />}
    </View>
  );
};

export default PracticeDetails;
