import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';

export const styles = StyleSheet.create({
  listContainer: {
    margin: 4,
    padding: 4,
  },
  listItem: {
    flex: 1,
    borderRadius: 8,
    borderWidth: 0.3,
    borderColor: '#D0D0D0',
    //backgroundColor: Colors.Custom.Gray200,
    margin: 4,
  },
  itemHeader: {
    fontWeight: '600',
    fontSize: 14,
    padding: 8,
  },
  textButton: {
    color: Colors.Custom.mainPrimaryPurple,
    paddingVertical: 4,
    paddingHorizontal: 8,
    alignSelf: 'center',
    justifyContent: 'center',
    fontSize: 14,
  },
  textAll: {
    backgroundColor: 'black',
    color: 'white',
    padding: 8,
    height: 33,
    width: 33,
    borderRadius: 16,
    alignSelf: 'center',
  },
  filterContainer: {
    borderBottomWidth: 0.3,
    borderBottomColor: '#D0D0D0',
  },
  filterTab: {
    height: '100%',
    padding: 8,
  },
  filterTabSelected: {
    borderBottomWidth: 2,
    height: '100%',
    borderBottomColor: '#825AC7',
    // backgroundColor: '#FAF5FE',
    padding: 8,
  },
  filterTabText: {
    color: '#667085',
    padding: 8,
    alignSelf: 'center',
  },
  selectedText: {
    color: '#825AC7',
    padding: 8,
    alignSelf: 'center',
  },
  tabBudgeView: {
    height: 24,
    width: 24,
    borderRadius: 20,
    backgroundColor: '#F2F4F7',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  tabBudgeText: {
    color: '#344054'
  }
});
