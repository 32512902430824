import BaseService from "../../../../services/CommonService/BaseService";

export const saveInboxBusinessHours = (params: any) => {
  const baseService = BaseService.getSharedInstance().axios;
    const inboxAvailabilityUrl = `crm-nest/inbox/availablity`;
    const bodyData = {
      locationId: params.locationId,
      inboxId: params.inboxId,
      outOfOfficeMessage: params.outOfOfficeMessage,
    };
    return baseService
      .post(inboxAvailabilityUrl, bodyData);
}

