import {useLazyQuery} from '@apollo/client';
import {Skeleton} from 'antd';
import {
  Divider,
  HStack,
  Pressable,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import CostTemplateQueries from '../../../../../services/CostSavingTemplates/CostTemplateQueries';
import {Colors} from '../../../../../styles';
import {ModalActionAntSelect} from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import ClearActionIcon from '../../../../common/Svg/ClearActionIcon';
import FilterNewIconSvg from '../../../../common/Svg/FilterNewIconSvg';
import {ACTIONS_CODE_CT, COST_SAVING_TYPE_FILTER, COST_TYPE_CODE, SavingTypeConst, SavingTypeNameConst} from './helperFiles/CostTemplatesConst';
import CostSavingTemplateTable from './CostSavingTemplateTable';
import {ICostSavingTemplatesProps, ICostTemplate} from './helperFiles/interface';
import {createCostSavingTemplate, saveTemplateAsDefault} from '../../../../../services/CostSavingTemplates/CostSavingService';
import AddOrUpdateCostTemplate from './AddOrUpdateViews/AddOrUpdateCostTemplate';
import DeleteCostTemplateModal from './AddOrUpdateViews/DeleteCostTemplateModal';
import {IAddOrUpdateTemplateBody, ITemplateSelectedData} from './interfaces/savingTemplatesInterface';
import {getCreateTemplateBody} from './helperFiles/utils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';

const CostSavingTemplates = (props: ICostSavingTemplatesProps) => {
  const {tabName}: any = useParams();
  const toast = useToast();
  const showModifier = tabName === SavingTypeConst.VISITS_SAVINGS
  const {filterVisible, savingType, actionCode, searchText} = props;
  const [stateData, setStateData] = useState({
    visibleFilterView: false,
    filterTypeValue: COST_SAVING_TYPE_FILTER[0].value,
    savingTypeId: '',
    savingTypeName: SavingTypeNameConst[tabName],
    loading: false,
    saveActionPerformed: false,
    drawerData: {
      actionCode: '',
      data: {} as any,
    },
  });
  const navigate = useNavigate();
  const [GET_ANALYTIC_ID_BY_CODE] = useLazyQuery(
    CostTemplateQueries.GET_ANALYTIC_ID_BY_CODE,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [GET_TEMPLATE_FOR_CLONE_BY_ID] = useLazyQuery(
    CostTemplateQueries.GET_TEMPLATE_FOR_CLONE_BY_ID,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  const onActionPerformed = async (code: string, rowData?: any) => {
    const costType =
      rowData?.costType === COST_TYPE_CODE.MARKET_COST
        ? 'marketCost'
        : rowData?.costType === COST_TYPE_CODE.OUR_COST
          ? 'ourCost'
          : '';
    switch (code) {
      case COMMON_ACTION_CODES.DETAIL_VIEW:
        navigate(`/admin/costTemplate/${tabName}/${costType}/detail/${rowData.id}`, {
          replace: false
        });
        break;
      case ACTIONS_CODE_CT.SET_AS_DEFAULT:
        if (rowData?.id) {
          setStateData(prev => {
            return {
              ...prev,
              saveActionPerformed: false,
            }
          })
          await saveTemplateAsDefault(rowData.id);
          onActionPerformed(COMMON_ACTION_CODES.SAVE)
        }
        break;
      case COMMON_ACTION_CODES.SAVE:
        setStateData(prev => {
          return {
            ...prev,
            saveActionPerformed: true,
            drawerData: {} as any,
          }
        })
        props.onActionPerformed(code)
        break;
      case COMMON_ACTION_CODES.CANCEL:
        setStateData((prev) => {
          return {
            ...prev,
            drawerData: {} as any,
          };
        });
        props.onActionPerformed(code)
        break;
      case COMMON_ACTION_CODES.EDIT:
        setStateData((prev) => {
          return {
            ...prev,
            saveActionPerformed: false,
            drawerData: {
              actionCode: code,
              data: rowData
            }
          };
        });
        // navigate(`/admin/costTemplate/${tabName}/${costType}/detail/${rowData.id}`, {
        //   replace: false
        // });
      break;
      case COMMON_ACTION_CODES.ADD:
        setStateData((prev) => {
          return {
            ...prev,
            saveActionPerformed: false,
            drawerData: {
              actionCode: code,
              data: rowData
            }
          };
        });
        break;
      default:
      case COMMON_ACTION_CODES.DELETE:
        setStateData((prev) => {
          return {
            ...prev,
            saveActionPerformed: false,
            drawerData: {
              actionCode: code,
              data: rowData
            }
          };
        });
        break;
      case ACTIONS_CODE_CT.DUPLICATE:
        duplicateTemplate(rowData)
        break;
    }
  };
  const getTemplateById = async (id: string) => {
    const templateResp = await GET_TEMPLATE_FOR_CLONE_BY_ID({
      variables: {
        id: id,
      },
    });
    return templateResp?.data?.costSavingTemplates?.length
      ? templateResp?.data?.costSavingTemplates
      : [];
  };
  const duplicateTemplate = async (rowData: {id: string}) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const templateData: ITemplateSelectedData[] = await getTemplateById(
      rowData?.id
    );
    if (templateData?.length) {
      const createTemplateBody: IAddOrUpdateTemplateBody =
        getCreateTemplateBody(templateData[0], showModifier);
      const response = await createCostSavingTemplate({
        ...createTemplateBody,
      }).catch(() => {
        showToast(
          toast,
          'Cost template duplication failed.',
          ToastType.error,
          1500
        );
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
      if (response?.data) {
        showToast(
          toast,
          'Cost template duplicated successfully!.',
          ToastType.success,
          1500
        );
        onActionPerformed(COMMON_ACTION_CODES.SAVE);
      } else {
        showToast(
          toast,
          'Cost template duplication failed.',
          ToastType.error,
          1500
        );
      }
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };
  const getAndSetAnalyticsId = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const response = await GET_ANALYTIC_ID_BY_CODE({
      variables: {
        code: savingType,
      },
    });
    if (response.data?.analyticalResources?.length) {
      const savingTypeResp = response.data?.analyticalResources[0];
      setStateData((prev) => {
        return {
          ...prev,
          savingTypeId: savingTypeResp.id,
          savingTypeName: SavingTypeNameConst[tabName],
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          savingTypeId: '',
          savingTypeName: '',
          loading: false,
        };
      });
    }
  };
  useEffect(() => {
    getAndSetAnalyticsId();
    props.onActionPerformed(COMMON_ACTION_CODES.CLOSE)
  }, [savingType]);
  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        visibleFilterView: filterVisible,
        drawerData: {
          actionCode: '',
          data: {}
        }
      };
    });
    onActionPerformed(actionCode)
  }, [filterVisible, actionCode]);
  return (
    <View>
      {stateData.visibleFilterView ? (
        <>
          <VStack mt={2} mb={2}>
            <HStack alignItems={'center'}>
              <FilterNewIconSvg isEnabled={true} />
              <Divider orientation="vertical" height={'20px'} />
              <HStack flex={0.9} ml={3} alignItems={'center'}>
                <Text>Type: </Text>
                <ModalActionAntSelect
                  allowClear={false}
                  showSearch={false}
                  value={stateData.filterTypeValue}
                  onChange={(type: any) => {
                    props.onActionPerformed(COMMON_ACTION_CODES.FILTER_CHANGED, {
                      type,
                    });
                    setStateData((prev: any) => {
                      return {
                        ...prev,
                        filterTypeValue: type,
                      };
                    });
                  }}
                  data={COST_SAVING_TYPE_FILTER}
                  optionProps={{key: 'key', value: 'value', label: 'label'}}
                  customStyle={{
                    fontSize: 15,
                    fontWeight: 400,
                    width: 150,
                    borderColor: Colors.Custom.Gray300,
                  }}
                />
              </HStack>
              <HStack flex={0.1} marginRight={2}>
                <Pressable
                  onPress={() => {
                    props.onActionPerformed(
                      COMMON_ACTION_CODES.FILTER_CHANGED,
                      {type: COST_SAVING_TYPE_FILTER?.[0].value}
                    );
                    setStateData((prev: any) => {
                      return {
                        ...prev,
                        filterTypeValue: COST_SAVING_TYPE_FILTER?.[0].value,
                      };
                    });
                  }}
                  style={{
                    marginRight: 12,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <ClearActionIcon />
                  <Text
                    color={Colors.Custom.mainPrimaryPurple}
                    style={{marginLeft: 6}}
                  >
                    Clear All
                  </Text>
                </Pressable>
              </HStack>
            </HStack>
          </VStack>
          <Divider orientation="horizontal" width={'100%'} />
        </>
      ) : (
        <></>
      )}
      {stateData.loading ? (
        <Skeleton active loading={stateData.loading}></Skeleton>
      ) : (
        <CostSavingTemplateTable
          searchText={searchText}
          isFilterVisible={stateData.visibleFilterView}
          savingTypeId={stateData.savingTypeId}
          savingType={savingType}
          onActionPerformed={onActionPerformed}
          costType={stateData.filterTypeValue}
          saveActionPerformed={stateData.saveActionPerformed}
        />
      )}
      {stateData.drawerData?.actionCode === COMMON_ACTION_CODES.ADD ||
        stateData.drawerData?.actionCode === COMMON_ACTION_CODES.EDIT ? (
        <AddOrUpdateCostTemplate
          onActionPerformed={onActionPerformed}
          selectedData={stateData.drawerData.data}
          actionCode={stateData.drawerData.actionCode}
          analyticalResourceId={stateData.savingTypeId}
          analyticalResource={stateData.savingTypeName}
        />
      ) : (
        <></>
      )}
      {stateData.drawerData.actionCode === COMMON_ACTION_CODES.DELETE ? (
        <DeleteCostTemplateModal
          selectedData={stateData.drawerData.data}
          onActionPerformed={onActionPerformed}
        />
      ) : (
        <></>
      )}
    </View>
  );
};

export default CostSavingTemplates;
