import React from "react";
import Svg, { Path } from "react-native-svg";
function NoteTitleIcon(props:{
  color?:string
}) {
  return (
    <Svg
      width="24"
      height="22"
      fill="none"
      viewBox="0 0 24 22"
      key={'noteTitleIcon'}
    >
      <Path
        key={'noteTitleIconPath'}
        fill={props.color ? props.color :"#98A2B3"}
        d="M8.437 6.078V1.156a.703.703 0 011.407 0v4.922H8.437zM6.328 20.14c-.563 0-1.093-.219-1.491-.617A21.338 21.338 0 010 12.066v8.778c0 .388.315.703.703.703h16.875a.703.703 0 00.703-.703v-.703H6.328z"
      ></Path>
      <Path
        key={'noteTitleIconPath2'}
      fill={props.color ? props.color :"#98A2B3"}
        d="M23.794 17.534c-3.503-3.503-5.513-8.272-5.513-13.083V3.97a.703.703 0 00-.703-.703H11.25V6.78a.703.703 0 01-.703.703H7.734a.703.703 0 01-.703-.703V3.266H.703A.703.703 0 000 3.969v.482A19.9 19.9 0 005.83 18.53c.133.131.312.205.498.205h16.969a.703.703 0 00.497-1.2zM9.141 8.89h5.625a.703.703 0 010 1.406H9.14a.703.703 0 010-1.406zm5.625 7.03H7.734a.703.703 0 010-1.405h7.032a.703.703 0 010 1.406zm1.406-2.812H6.328a.703.703 0 010-1.406h9.844a.703.703 0 010 1.406z"
      ></Path>
    </Svg>
  );
}

export default NoteTitleIcon;
