import {IconButton, Tooltip, View} from 'native-base';
import React, {useState} from 'react';
import Icon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {useNavigate} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {Colors} from '../../../../../../styles';
import {DeleteTemplateModal} from '../../../DeleteTemplateModal';
import {getEmailEditorRoute} from '../../EmailTemplatesUtils';
import {IEmailTemplateTableActionProps} from '../../interfaces';
import EmailActionsPopover from './EmailActionsPopover';

const EmailTemplatesTableActions = (props: IEmailTemplateTableActionProps) => {
  const {onActionPerformed, record} = props;

  const [state, setState] = useState({
    isOpen: false,
  });

  const navigate = useNavigate();

  // modal states
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteTemplate = () => {
    onActionPerformed(COMMON_ACTION_CODES.DELETE, record);
  };

  return (
    <View flex={1} flexDirection={'row'}>
      <Tooltip label="Update" placement="top">
        <IconButton
          icon={
            <Feather name="edit-2" size={17} color={Colors.Custom.Gray600} />
          }
          onPress={() => {
            const route = getEmailEditorRoute(record);
            navigate(route);
          }}
        />
      </Tooltip>

      <Tooltip label="Delete" placement="top">
        <IconButton
          icon={<Icon name="delete" size={17} color={Colors.Custom.Gray600} />}
          onPress={() => setOpenDeleteModal(true)}
        />
      </Tooltip>
      <Tooltip label="Copy Template" placement="top">
        <IconButton
          icon={<Icon name="copy1" size={17} color={Colors.Custom.Gray600} />}
          onPress={() => {
            const route = getEmailEditorRoute(record);
            navigate(route + '?isDuplicate=true');
          }}
        />
      </Tooltip>

      <EmailActionsPopover {...props} />

      <DeleteTemplateModal
        isOpen={openDeleteModal}
        onConfirm={() => {
          handleDeleteTemplate();
          setOpenDeleteModal(false);
        }}
        record={record}
        onClose={() => setOpenDeleteModal(false)}
      />
    </View>
  );
};

export default EmailTemplatesTableActions;
