import {ColumnsType} from 'antd/lib/table';
import React from 'react';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../styles/Colors';
import {Text} from 'native-base';
import {capitalizeText} from '../../ContentManagement/CampaignManagement/Helper';
import {
  IChannelEFax,
  IEFaxInbox,
} from '../Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/interface';
import {IEFaxInboxMembers} from './interface';
import {GetInboxMemberBadge} from './GetInboxMemberBadge';
import {EFaxInboxTableAction} from './EFaxInboxTableAction';
import { formatEFaxNumber } from './EFaxInboxSettingUtils';

export const getInboxColumns = (
  actionFn: (actionCode: string, recordData: any) => {}
) => {
  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="inboxName"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      fixed: 'left',
      render: (inboxName: string) => (
        <Text size={'smMedium'} color={Colors.Custom.Gray900}>
          {capitalizeText(inboxName || '')}
        </Text>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="eFaxNumber"
        />
      ),
      dataIndex: 'channelEfax',
      key: 'eFaxNumber',
      width: '20%',
      render: (channelEfax: IChannelEFax) => (
        <Text noOfLines={4} size={'smMedium'} color={Colors.Custom.Gray900}>
          {formatEFaxNumber(channelEfax?.efaxNumber)}
        </Text>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="agents"
        />
      ),
      dataIndex: 'inboxMembers',
      key: 'inboxMembers',
      width: '40%',
      render: (inboxMembers: IEFaxInboxMembers[]) => (
        <GetInboxMemberBadge inboxMembers={inboxMembers} />
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="action"
        />
      ),
      width: '20%',
      render: (id: number, record: IEFaxInbox) => {
        return (
          <EFaxInboxTableAction
            id={id}
            record={record}
            onActionPerformed={actionFn}
          />
        );
      },
    },
  ];

  return columns;
};
