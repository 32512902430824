import { useLazyQuery } from '@apollo/client';
import { Text, View } from 'native-base';
import { useEffect, useMemo, useState } from 'react';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import EmployerQueries from '../../../../services/Employer/EmployerQueries';
import {
  getAccountId,
  getAccountUUID,
  getUserEmployerId
} from '../../../../utils/commonUtils';
import { GraphMemberList } from '../../../common/AppDashboard/GraphMemberList';
import { GraphConfig } from '../../../common/AppDashboard/GraphUtils';
import FormSearch from '../../../common/FormSearch/FormSearch';
import { GRAPH_COLOR_CONSTS } from '../AnalyticsConsts';
import { AnalyticsWidget } from '../AnalyticsWidget';
import {
  getContactListFilterQuery,
  getFormattedContactList
} from '../LoginEmployeeGraph/LoginEmployeeUtils';

import { SingleForm } from './interfaces';

const FormResponseGraph = (props: {
  employerId?: string;
  isDashboard?: boolean;
}) => {
  const employerId = getUserEmployerId() || props.employerId;
  const accountUuid = getAccountUUID();
  const accountId = getAccountId();
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 1;
  const [state, setState] = useState<{
    initialLoading: boolean;
    formId: string;
    contactIdList: string[];
    showNoFormSentText: boolean;
    formLoading: boolean;
    total: number;
    submitted: number;
    notSubmitted: number;
    percent: number;
    showGraph: boolean;
    formLogs: SingleForm[];
    contactUuidList: string[];
    formLogContactUuidList: string[];
    selectedCode: string;
    page: number;
    pageSize: number;
    listLoading: boolean;
    isOpen: boolean;
    contactList: any[];
    openContactListDrawer: boolean;
  }>({
    initialLoading: false,
    formId: '',
    isOpen:false,
    contactIdList: [],
    showNoFormSentText: false,
    formLoading: false,
    total: 0,
    notSubmitted: 0,
    submitted: 0,
    percent: 0,
    showGraph: false,
    formLogs: [] as SingleForm[],
    contactUuidList: [],
    formLogContactUuidList: [],
    selectedCode: '',
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    listLoading: false,
    contactList: [],
    openContactListDrawer: false,
  });

  const [getEmployeesByLoginFilter] = useLazyQuery(
    EmployerQueries.GetEmployeesByLoginFilter,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getFormLogCountByStatus] = useLazyQuery(
    EmployerQueries.GetFormLogCountByStatus,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    }
  );

  const [getContactUuidListBySelectedFormStatus] = useLazyQuery(
    EmployerQueries.GetContactUuidListBySelectedFormStatus,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    }
  );



  const getContactList = () => {
    if (!employerId) {
      setState((prev) => {
        return {
          ...prev,
          contactIdList: [],
          contactList: [],
          initialLoading: false,
        };
      });
      return

    }
    setState((prev) => {
      return {
        ...prev,
        initialLoading: true,
        formId: '',
        contactIdList: [],
        showGraph: false,
      };
    });
    const where = getContactListFilterQuery({
      employerId,
      accountUuid,
      selectedSegmentType: '',
      accountId,
      removeLoginFilter: true
    });

    getEmployeesByLoginFilter({
      variables: {
        where: where,
        // limit: state.pageSize,
        // offset: state.page * state.pageSize,
      },
    })
      .then((response) => {
        const contactList = getFormattedContactList(response.data);
        const contactUuidList = contactList.map((item) => item.uuid);
        setState((prev) => {
          return {
            ...prev,
            contactIdList: contactUuidList,
            contactList: contactList,
            initialLoading: false,
          };
        });
      })
      .catch((error) => {
        setState((prev) => {
          return {
            ...prev,
            initialLoading: false,
          };
        });

      });
  };

  const getFormList = async () => {
    setState((prev) => {
      return {
        ...prev,
        formLoading: true,
        showNoFormSentText: false,
        total: 0,
        percent: 0,
        showGraph: false,
      };
    });
    try {
      const variables: any = {
        formId: {
          _eq: state.formId
        },
      }
      if (employerId) {
        variables.contactId = { _in: state.contactIdList };
      }

      const response = await getFormLogCountByStatus({
        variables: {
          pendingBoolExp: { ...variables, status: {
            code: {
              _neq: 'SUBMITTED'
            }
          } },
          submittedBoolExp: {
            ...variables, status: {
              code: {
                _eq: 'SUBMITTED'
              }
            }
          }
        },
      });
      const submitted = response?.data?.submitted?.aggregate?.count;
      const notSubmitted = response?.data?.pending?.aggregate?.count;
      setState((prev) => {
        return {
          ...prev,
          submitted,
          notSubmitted
        };
      });
    } catch (error) {

      setState((prev) => {
        return {
          ...prev,
          formLoading: false,
        };
      });
    }
  };

  const onGraphClick = async () => {
    const boolExp: any = {
      formId: {
        _eq: state.formId
      },

    }
    if (employerId) {
      boolExp.contactId = { _in: state.contactIdList };
    }
    let total=0;
    if(state.selectedCode == 'SUBMITTED'){
      boolExp.status = {
        code: {
          _eq: 'SUBMITTED'
        }
      }
      total = state.submitted;
    } else {
      boolExp.status = {
        code: {
          _neq: 'SUBMITTED'
        }
      }
      total = state.notSubmitted;
    }
    const response = await getContactUuidListBySelectedFormStatus({
      variables:{
        boolExp: boolExp,
        limit: state.pageSize,
        offset: state.pageSize * (state.page-1)
      }
    });
    const formLogContactUuidList = response?.data?.formLogs.map((item:any)=>{return item.contactId})
    setState((prev) => {
      return {
        ...prev,
        formLogContactUuidList: formLogContactUuidList,
        total:total,
        openContactListDrawer: true,
      };
    });
  };

  useEffect(() => {
    getContactList();
  }, [employerId]);

  useEffect(() => {
    if (state.formId.length > 0) {
      getFormList();
    }
  }, [state.formId]);

  useEffect(() => {
    if (state.selectedCode.length > 0 && state.isOpen) {
      onGraphClick();
    }
  }, [state.isOpen, state.page, state.pageSize]);

  const [config, setConfig] = useState<any>()

  useEffect(()=>{
    const graphData: {
      count: number;
      name: string;
      color: string;
      code: string;
    }[] = [
        {
          count: state.submitted,
          name: 'Submitted',
          color: GRAPH_COLOR_CONSTS.POSITIVE,
          code: 'submitted'.toUpperCase(),
        },
        {
          count: state.notSubmitted,
          name: 'Pending',
          color: GRAPH_COLOR_CONSTS.NEGATIVE,
          code: 'pending'.toUpperCase(),
        },
      ];

    const config = {
      ...GraphConfig,
      data: graphData,
      angleField: 'count',
      colorField: 'name',
      height: 220,
      width: 250,
      onReady: (plot: any) => {
        plot.on('element:click', (args: any) => {
          const code = args?.data?.data?.code || '';
          setState((prev) => {
            return {
              ...prev,
              isOpen:true,
              selectedCode: code,
            };
          });
        });
      },
    };
    setConfig(config)

  },[state.submitted, state.notSubmitted ])



  const getPieChart = useMemo(() => {
    return (
      config ?
      <AnalyticsWidget
        pieConfig={{
          ...config,
        }}
        height="358px"
        legend={{
          position: 'bottom',
          layout: 'horizontal',
          maxRow: 3,
          title: {
            title: undefined,
            spacing: 10,
            style: { fontSize: 0, lineWidth: 0, lineHeight: 0 },
          },
        }}
        title="Form Responses"
        // subtitle="Showing form response-wise breakup of your population below"
        searchComponent={
          <>
            <FormSearch
              value={state.formId}
              isShowError={false}
              onChange={(value) => {
                setState((prev) => {
                  return {
                    ...prev,
                    formId: value?.id || '',
                  };
                });
              }}
            />
          </>
        }
        showNoFormSentText={
          state.showNoFormSentText ? (
            <View
              style={{
                margin: 30,
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
                alignContent: 'center',
                alignSelf: 'center',
              }}
            >
              <Text>
                {`Selected form was not sent to the ${props.isDashboard ? 'members' : 'employees'
                  }`}
              </Text>
            </View>
          ) : null
        }
      /> : <></>
    );
  }, [config]);

  return (
    <div
      // className="display_card_container"
      style={{ height: '358px' }}
    >
      {getPieChart}

      {state.openContactListDrawer && (
        <GraphMemberList
          page={state.page}
          pageSize={state.pageSize}
          total={state.total || 0}
          title={'Form Responses'}
          isOpen={state.openContactListDrawer}
          contactUuidList={state.formLogContactUuidList}
          onPaginationChange={(page: number, pageSize: number) => {
            setState((prev) => {
              return {
                ...prev,
                page,
                pageSize
              }
            });
          }}
          onCompleteAction={() => {
            setState((prev) => {
              return {
                ...prev,
                contactList: [],
                isOpen:false,
                page: DEFAULT_PAGE,
                pageSize: DEFAULT_PAGE_SIZE,
                selectedSegmentType: '',
                openContactListDrawer: false,
                contactUuidList: [],
              };
            });
          }}
        />
      )}
    </div>
  );
};

export default FormResponseGraph;
