import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const AccessLockSvg = () => {
  return (
    <>
      {isWeb() ? (
        <svg
          width="314"
          height="151"
          viewBox="0 0 314 151"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1841_32217)">
            <g opacity="0.5">
              <path
                d="M137 84.7118C135.675 84.7118 134.404 84.1857 133.466 83.2491C132.529 82.3125 132.001 81.042 132 79.7168V64.7118C132.002 59.7702 133.468 54.94 136.212 50.8307C138.957 46.7214 142.857 43.5172 147.421 41.6225C151.985 39.7279 157.008 39.2277 161.855 40.185C166.703 41.1424 171.159 43.5144 174.66 47.0018C177.811 50.1806 180.065 54.1359 181.195 58.4668C181.359 59.1027 181.397 59.7648 181.306 60.4153C181.214 61.0657 180.996 61.6918 180.662 62.2578C180.329 62.8238 179.887 63.3185 179.363 63.7138C178.838 64.1092 178.241 64.3973 177.605 64.5618C176.969 64.7262 176.307 64.7639 175.657 64.6725C175.006 64.5811 174.38 64.3624 173.814 64.0291C172.671 63.3558 173.042 62.2561 172.71 60.9718V60.9568C172.03 58.3525 170.674 55.974 168.78 54.0618C166.676 51.9753 162.802 49.3582 159.895 48.7887C156.987 48.2193 153.976 48.5232 151.241 49.6619C148.506 50.8007 146.168 53.9236 144.524 56.3882C142.88 58.8528 142.001 61.7489 142 64.7118V79.7118C142 81.037 141.474 82.308 140.537 83.2455C139.601 84.1831 138.33 84.7104 137.005 84.7118H137ZM164.5 102.212C164.5 100.223 163.71 98.315 162.303 96.9085C160.897 95.5019 158.989 94.7118 157 94.7118C155.011 94.7118 153.103 95.5019 151.697 96.9085C150.29 98.315 149.5 100.223 149.5 102.212C149.5 104.427 150.475 106.392 152 107.762V114.722C152.003 116.046 152.531 117.315 153.468 118.251C154.405 119.186 155.676 119.712 157 119.712H157.005C158.33 119.71 159.601 119.183 160.537 118.246C161.474 117.308 162 116.037 162 114.712V107.762C163.525 106.392 164.5 104.427 164.5 102.212Z"
                fill="#98A2B3"
              />
              <path
                d="M177.354 84.3124C178.68 84.3124 179.951 83.7863 180.888 82.8497C181.826 81.9131 182.353 80.6426 182.354 79.3174V64.3124C182.353 59.3708 180.887 54.5406 178.142 50.4313C175.398 46.322 171.498 43.1178 166.934 41.2231C162.37 39.3285 157.347 38.8283 152.499 39.7856C147.651 40.743 143.195 43.115 139.694 46.6024C136.544 49.7812 134.289 53.7365 133.159 58.0674C132.995 58.7033 132.957 59.3654 133.049 60.0159C133.14 60.6663 133.359 61.2924 133.692 61.8584C134.025 62.4244 134.467 62.9191 134.992 63.3144C135.516 63.7098 136.113 63.9979 136.749 64.1624C137.385 64.3268 138.047 64.3645 138.698 64.2731C139.348 64.1817 139.974 63.963 140.54 63.6297C141.683 62.9564 141.312 61.8567 141.644 60.5724V60.5574C142.324 57.9531 144.88 55.5746 146.774 53.6624C148.878 51.5759 151.552 50.1588 154.46 49.5893C157.367 49.0199 160.378 49.3238 163.114 50.4625C165.849 51.6013 168.186 53.5242 169.83 55.9888C171.475 58.4534 172.353 61.3495 172.354 64.3124V79.3124C172.354 80.6376 172.88 81.9086 173.817 82.8461C174.754 83.7837 176.024 84.311 177.349 84.3124H177.354ZM149.854 101.812C149.854 99.8232 150.645 97.9156 152.051 96.5091C153.458 95.1025 155.365 94.3124 157.354 94.3124C159.344 94.3124 161.251 95.1025 162.658 96.5091C164.064 97.9156 164.854 99.8232 164.854 101.812C164.854 104.027 163.879 105.992 162.354 107.362V114.322C162.352 115.647 161.824 116.916 160.886 117.851C159.949 118.787 158.679 119.312 157.354 119.312H157.349C156.024 119.311 154.754 118.784 153.817 117.846C152.88 116.909 152.354 115.638 152.354 114.312V107.362C150.829 105.992 149.854 104.027 149.854 101.812Z"
                fill="#98A2B3"
              />
            </g>
            <g filter="url(#filter0_d_1841_32217)">
              <path
                d="M182 74.7118H132C128.022 74.7118 124.206 76.2921 121.393 79.1052C118.58 81.9182 117 85.7335 117 89.7118V124.712C117 128.69 118.58 132.505 121.393 135.318C124.206 138.131 128.022 139.712 132 139.712H182C185.978 139.712 189.794 138.131 192.607 135.318C195.42 132.505 197 128.69 197 124.712V89.7118C197 85.7335 195.42 81.9182 192.607 79.1052C189.794 76.2921 185.978 74.7118 182 74.7118ZM162 107.762V114.712C162 116.037 161.474 117.308 160.537 118.246C159.601 119.183 158.33 119.71 157.005 119.712H157C155.675 119.712 154.404 119.186 153.466 118.249C152.529 117.312 152.001 116.042 152 114.717V107.762C151.216 107.065 150.588 106.21 150.157 105.254C149.726 104.297 149.503 103.261 149.5 102.212C149.5 100.223 150.29 98.315 151.697 96.9085C153.103 95.502 155.011 94.7118 157 94.7118C158.989 94.7118 160.897 95.502 162.303 96.9085C163.71 98.315 164.5 100.223 164.5 102.212C164.5 104.427 163.525 106.392 162 107.762Z"
                fill="#E4E7EC"
              />
            </g>
          </g>
          <circle cx="228.176" cy="108.176" r="6.17647" fill="#D0D5DD" />
          <circle cx="76.25" cy="69.25" r="5.25" fill="#D0D5DD" />
          <circle
            cx="102.25"
            cy="29.25"
            r="4.55"
            stroke="#D0D5DD"
            stroke-width="1.4"
          />
          <circle
            cx="187.25"
            cy="24.4618"
            r="4.55"
            stroke="#D0D5DD"
            stroke-width="1.4"
          />
          <g clip-path="url(#clip1_1841_32217)">
            <path
              d="M222.359 54.14L219.39 57.1099L222.359 60.0797L221.369 61.0697L218.4 58.0998L215.43 61.0697L214.44 60.0797L217.41 57.1099L214.44 54.14L215.43 53.1501L218.4 56.1199L221.369 53.1501L222.359 54.14Z"
              fill="#D0D5DD"
            />
          </g>
          <g clip-path="url(#clip2_1841_32217)">
            <path
              d="M84.2593 118.644L81.2895 121.614L84.2593 124.584L83.2694 125.574L80.2995 122.604L77.3297 125.574L76.3397 124.584L79.3096 121.614L76.3397 118.644L77.3297 117.654L80.2995 120.624L83.2694 117.654L84.2593 118.644Z"
              fill="#D0D5DD"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_1841_32217"
              x="105"
              y="66.7118"
              width="104"
              height="89"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="6" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.596078 0 0 0 0 0.635294 0 0 0 0 0.701961 0 0 0 0.15 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1841_32217"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1841_32217"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_1841_32217">
              <rect
                width="120"
                height="120"
                fill="white"
                transform="translate(97 29.7118)"
              />
            </clipPath>
            <clipPath id="clip1_1841_32217">
              <rect
                width="16.8"
                height="16.8"
                fill="white"
                transform="translate(210 48.7118)"
              />
            </clipPath>
            <clipPath id="clip2_1841_32217">
              <rect
                width="16.8"
                height="16.8"
                fill="white"
                transform="translate(71.8999 113.212)"
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <Image
          resizeMode="contain"
          height={100}
          width={200}
          source={require('../../../assets/images/access_lock_png.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default AccessLockSvg;
