import {Skeleton} from 'antd';
import {Pressable, Text, View} from 'native-base';
import React, {useEffect} from 'react';
import {ANALYTICS_RESOURCE, DISPLAY_TYPE} from '../../../../../../constants';
import {Colors} from '../../../../../../styles';
import EyeHideSvg from '../../../../../common/Svg/WidgetsSvg/EyeHideSvg';
import InfoIconView from '../../CommonView/InfoIconView';
import PrintActionBtn from '../../CommonView/PrintActionBtn';
import {DashboardDataContext} from '../../EmployerContext/DashboardContext';
import {WIDGET_ACTION_CODE} from '../../EmployerUtils/EmployerConst';
import {getFormattedDataForListingWidget} from '../../EmployerUtils/FormateDataUtils';
import {IWidgetCardView} from '../../interface';
import {TOP_IMAGING, TOP_LABS, TOP_CARE_JOURNEYS, getTenantForDemo, TOP_MEDICATIONS, TOP_SYMPTOMS_CHIEF_COMPLAINTS} from '../../tempDataFile';
import NodataViewTypeOne from '../NoDataView/NodataViewTypeOne';
import NoDataWidgetView from '../NoDataView/NoDataWidgetView';
import WidgetActionView from '../WidgetActionView';
import {WidgetStyles} from '../WidgetStyles';
import ListingWidgetWithList from './ListingWidgetWIthList';
import ListingWidgetWithProgress from './ListingWidgetWithProgress';

const ListingWidgetView = (props: IWidgetCardView) => {
  const {
    sectionData,
    loading,
    reusableData,
    ref,
    onImageDownloadClick,
    onActionClick,
    memberSatisfactionFormList
  } = props;
  const isTopImaging = sectionData.resourceCode === ANALYTICS_RESOURCE.TOP_IMAGING;
  const isTopLabs = sectionData.resourceCode === ANALYTICS_RESOURCE.TOP_LABS;
  const isTopCareJourney = sectionData.resourceCode === ANALYTICS_RESOURCE.TOP_CARE_JOURNEYS;
  const isTopMedications = sectionData.resourceCode === ANALYTICS_RESOURCE.TOP_MEDICATIONS;
  const isTopSymptoms = sectionData.resourceCode === ANALYTICS_RESOURCE.TOP_SYMPTOMS_CHIEF_COMPLAINTS;
  const resourceData: any[] = getTenantForDemo() ?
    (
      isTopImaging
        ? TOP_IMAGING
        : isTopLabs
          ? TOP_LABS
          : isTopCareJourney
            ? TOP_CARE_JOURNEYS
            : isTopMedications
              ? TOP_MEDICATIONS
              : isTopSymptoms
                ? TOP_SYMPTOMS_CHIEF_COMPLAINTS
                : props.resourceData)
    : props.resourceData;

  const formattedResourceData = getFormattedDataForListingWidget(
    sectionData,
    resourceData
  );
  const getListWidgetView = () => {
    let ele = <></>;
    switch (sectionData.currentDisplayType) {
      case DISPLAY_TYPE.LISTING_WIDGET_WITH_LIST:
        ele = (
          <ListingWidgetWithList
            memberSatisfactionFormList={memberSatisfactionFormList}
            loading={loading}
            sectionData={sectionData}
            resourceData={formattedResourceData}
            reusableData={reusableData}
            onActionClick={onActionClick}
          />
        );
        break;
      case DISPLAY_TYPE.LISTING_WIDGET_WITH_PROGRESS:
        ele = (
          <ListingWidgetWithProgress
            memberSatisfactionFormList={memberSatisfactionFormList}
            loading={loading}
            sectionData={sectionData}
            resourceData={formattedResourceData}
            reusableData={reusableData}
          />
        );
        break;
    }
    return ele;
  };
  return (
    <div className="widgetBoxShadow">

      {loading ? (
        <Skeleton active={true} />
      ) : (
        <>
          {resourceData && resourceData.length ? (
            getListWidgetView()
          ) : (
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '60%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NodataViewTypeOne viewCode={'TEXT_ICON'} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(ListingWidgetView);
