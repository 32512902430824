import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles';

export const styles = StyleSheet.create({

  spinnerStyle: {
    margin: '0 auto',
    color: 'black',
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0,
  },
  cptCountInfo: {
    borderRadius: 4,
    paddingHorizontal: 6,
    paddingVertical: 2.5,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 12,
    marginTop: 5
  }
});
