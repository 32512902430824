import { View } from 'native-base';
import { Colors } from '../../../../styles/Colors';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import CampaignSegmentTable from './CampaignSegmentTable';
import { CampaignSegmentTableContext } from './interfaces';

const CampaignManagementSegmentView = (props: any) => {
  const {
    selectedActionCode,
    selectedRawData,
    onActionPerformed,
    updatePopulationGroupCount,
  } = props;
  return (
    <View>
      {/* <View style={{backgroundColor: '#fff', flex: 1}} paddingBottom={'16px'}>
        <TitleSubtitleView
          titleLabelId="groups"
          subtitleLabelId=""
        />
      </View> */}
      <View
        style={{
          backgroundColor: '#fff',
          overflow: 'hidden',
        }}
      >
        <CampaignSegmentTable
          context={CampaignSegmentTableContext.POPULATION_GROUP}
          selectedActionCode={selectedActionCode}
          selectedRawData={selectedRawData}
          onActionPerformed={onActionPerformed}
          updatePopulationGroupCount={updatePopulationGroupCount}
        />
      </View>
    </View>
  );
};

export default CampaignManagementSegmentView;
