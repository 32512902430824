import { ColumnsType } from 'antd/lib/table';
import {HStack, Text, View} from 'native-base';
import {ITagsTableData} from '..';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {TagComponent} from '../../../../common/TagComponent';
import TagsActions from './TagsActions/TagsActions';

export const getTagColumns = (
  actionFn: (actionCode: string, record: any) => {}
) => {
  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'title',
      sorter: (a: any, b: any) =>
      a?.title?.localeCompare(b?.title),
      key: 'title',
      fixed: 'left',
      width: '25%',
      render: (tagName: string, record: ITagsTableData) => (
        <View justifyContent={'flex-start'} flexDirection={'row'}>
          <TagComponent
            tagText={tagName}
            tagColor={record.color}
            tagStyle={{borderRadius: 30}}
            tagTextStyle={{
              fontSize: 12,
              fontWeight: '700',
            }}
          />
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="description"
        />
      ),
      dataIndex: 'description',
      key: 'description',
      width: '45%',
      render: (color: string) => (
        <View justifyContent={'flex-start'}>
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {color}
          </Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="color"
        />
      ),
      dataIndex: 'color',
      key: 'color',
      width: '15%',
      render: (color: string) => (
        <HStack space={2} justifyContent={'flex-start'}>
          <View
            width={5}
            height={5}
            backgroundColor={color}
            borderRadius={4}
          ></View>
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {color}
          </Text>
        </HStack>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      key: 'actions',
      fixed: 'right',
      width: '15%',
      render: (record: ITagsTableData) => {
        return (
          <View>
            <TagsActions onActionPerformed={actionFn} record={record} />
          </View>
        );
      },
    },
  ];
  return columns;
};
