import {Button, Modal } from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../constants';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {IDeleteCampaignModalProps} from '../../interfaces';
import {styles} from './DeleteCampaignModalStyle';

const {Content, CloseButton, Header, Footer, Body} = Modal;
const DeleteCampaignModal = (props: IDeleteCampaignModalProps) => {
  const {isOpen, onClose, onConfirm} = props;
  const intl = useIntl();
  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <Content style={styles.modalContainer}>
        <CloseButton />
        <Header>
          <DisplayText textLocalId="deleteCampaign" textType="Heading" />
        </Header>
        <Body>
          <DisplayText
            textLocalId="deleteCampaignConfirmation"
            textType="Text"
          />
        </Body>
        <Footer style={styles.modalFooter}>
          <Button.Group>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onConfirm();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'confirm',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </Footer>
      </Content>
    </Modal>
  );
};

export default DeleteCampaignModal;
