import {Button, Modal, Text} from 'native-base';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {DeleteSMSTemplateProps} from '../interfaces';
import {deleteSMSTemplates} from '../SMSTemplatesUtils';

const {Body, CloseButton, Content, Footer, Header} = Modal;

const DeleteSMSTemplate = (props: DeleteSMSTemplateProps) => {
  const {singleSMSTemplate, onFormCompleteAction} = props;
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const onClose = () => {
    onFormCompleteAction(COMMON_ACTION_CODES.CANCEL);
  };

  const onConfirm = () => {
    onFormCompleteAction(COMMON_ACTION_CODES.LIST_VIEW);
  };

  const handleConfirmation = () => {
    setLoading(true);
    deleteSMSTemplates(singleSMSTemplate?.id)
      .then((response) => {
        setLoading(false);
        onConfirm();
      })
      .catch((error) => {

        setLoading(false);
        onClose();
      });
  };

  const isDefault = singleSMSTemplate?.isDefault;

  return (
    <Modal isOpen onClose={onClose}>
      <Content
        style={{
          backgroundColor: Colors.Custom.BackgroundColor,
        }}
      >
        <CloseButton />
        <Header>
          <DisplayText textLocalId="deleteTemplate" textType="Heading" />
        </Header>

        <Body>
          {singleSMSTemplate?.isDefault ? (
            <Text>
              This template cannot be deleted because it is default template.
              Please change the default status or set another template as
              default
            </Text>
          ) : (
            <DisplayText
              textLocalId="deleteTemplateConfirmation"
              textType="Text"
            />
          )}
        </Body>

        <Footer
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            backgroundColor: Colors.Custom.BackgroundColor,
          }}
        >
          <Button.Group>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'Cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            {!isDefault ? (
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    handleConfirmation();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'Confirm',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            ) : (
              <></>
            )}
          </Button.Group>
        </Footer>
      </Content>
    </Modal>
  );
};

export default DeleteSMSTemplate;
