import {Button, Card, Dropdown, Menu, Modal, Table} from 'antd';
import {Input, View, VStack} from 'native-base';
import React, {FC, useEffect, useRef, useState} from 'react';

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MoreOutlined,
  PlusOutlined,
  SettingOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';

import {Outlet, useNavigate, useParams} from 'react-router-dom';
import BaseService from '../../../../services/CommonService/BaseService';
import {Colors} from '../../../../styles';
import {getUserData} from '../../../../utils/commonUtils';
import LocalStorage from '../../../../utils/LocalStorage';
import {SearchBar} from '../../../common/SearchBar';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import {AnalyticsTableOptions} from '../components/Analytics/AnalyticsTableOptions';
import BulkActionsDropMenu from '../components/BulkActions';
import OpportunityAssociationModal from '../components/OpportunityAssociationModal';
import OpportunityAutomation from '../components/OpportunityAutomation';
import OpportunityAutomationResults from '../components/OpportunityAutomation/response';
import OpportunitySelection from '../components/OpportunitySelection';
import TableTab from '../components/Table/TableTab';
import {gOppStyles} from './stylesheet';
import { InputStyle } from '../../Contacts/Leads/LeadView/LeadViewStyle';

const {dataSource} = AnalyticsTableOptions;

export interface IModalStep {
  step: string;
  isVisible: boolean;
  opportunity: any;
  automation: any;
}

const AnalyticsPage: FC = () => {
  const [selectedTab, setSelectedTab] = useState('PENDING');
  const baseService = BaseService.getSharedInstance().axios;
  const handleStatusChange = async (
    e: React.MouseEvent,
    opportunityId: string,
    status: string
  ) => {
    e.preventDefault();

    try {
      const req = await fetch(
        `https://dev.foldhealth.io/opportunities/${opportunityId}/status`,
        {
          method: 'POST',
          body: JSON.stringify({
            statusType: status,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const res = await req.json();
      if (status == 'DELETED') {
        setOpps(
          opportunities.filter(
            (opp: any) => (opp._id || opp.id) !== opportunityId
          )
        );
      }
    } catch (error: any) {
    }
  };

  const columns: any = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.length - b.name.length,
      onCell: (record: any, rowIndex: any) => {
        return {
          onClick: (ev: any) => {
            navHistory('/opportunities/' + (record._id || record.id) + '/view');
          },
        };
      },
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
      sorter: (a: any, b: any) => {
        return a?.author?.length - b?.author?.length;
      },
    },
    {
      title: 'Source',
      dataIndex: 'source',
      // key: "source",
      render: (text: string, _record: any) => {
        return (
          <Button size="small" type="default" icon={<SettingOutlined />}>
            {text}
          </Button>
        );
      },
    },
    {
      title: 'Members',
      key: 'members',
      sorter: (a: any, b: any) => a.members.length - b.members.length,
      render: (_text: string, record: any) => {
        return <span>{record.members.length}</span>;
      },
    },
    {
      title: 'Care Journey',
      dataIndex: 'careJourney',
      key: 'careJourney',
    },
    {
      title: 'Remaining Time',
      dataIndex: 'remainingTime',
      key: 'remainingTime',
      render: (text: string) => {
        return <span>{text ? new Date(text).toDateString() : '-'}</span>;
      },
    },
    {
      title: 'Creation Date',
      dataIndex: 'creationDate',
      key: 'creationDate',
      sorter: (a: any, b: any) =>
        new Date(a.creationDate || a.createdAt || Date.now()).getTime() -
        new Date(b.creationDate || b.createdAt || Date.now()).getTime(),
      render: (text: string, record: any) => {
        return (
          <span>
            {new Date(
              record.creationDate || record.createdAt || Date.now()
            ).toDateString()}
          </span>
        );
      },
    },

    {
      title: 'Favorite',
      dataIndex: 'favorites',
      key: 'favorite',
      sorter: (a: any, b: any) =>
        (a.favorites || []).length - (b.favorites || []).length,
      render: (text: string, record: any) => {
        return (
          <Button
            onClick={(e) => toggleFavorite(record)}
            className="btnFavorite"
            shape="circle"
            type="text"
            icon={
              record.favorites && record.favorites.length > 0 ? (
                <StarFilled />
              ) : (
                <StarOutlined />
              )
            }
          />
        );
      },
    },
    {
      render: (_: any, record: any) => {
        return (
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: '5',
                    disabled: true,
                    label: (
                      <a
                        // onClick={(e) =>
                        //   handleRowClick(record._id || record.key)
                        // }
                        href="#"
                      >
                        Duplicate
                      </a>
                    ),
                  },
                  {
                    key: '1',
                    disabled: record.status !== 'PENDING',
                    label: (
                      <a onClick={(e) => alert('@TODO - Interaction')}>
                        Apply Care Journey
                      </a>
                    ),
                  },
                  {
                    key: '2',
                    disabled: true,
                    label: (
                      <a
                        onClick={(e) =>
                          handleStatusChange(
                            e,
                            record._id || record.key,
                            'PAUSED'
                          )
                        }
                      >
                        Pause
                      </a>
                    ),
                  },
                  {
                    key: '3',
                    disabled: selectedTab === 'ARCHIVED',
                    label: (
                      <a
                        onClick={(e) =>
                          handleStatusChange(
                            e,
                            record._id || record.key,
                            'ARCHIVED'
                          )
                        }
                      >
                        Archive
                      </a>
                    ),
                  },
                  {
                    key: '4',
                    label: (
                      <a
                        onClick={(e) =>
                          handleStatusChange(
                            e,
                            record._id || record.key,
                            'DELETED'
                          )
                        }
                      >
                        Delete
                      </a>
                    ),
                  },
                ]}
              />
            }
            placement="bottomRight"
          >
            <Button
              className="btnMoreOptions"
              shape="circle"
              type="text"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        );
      },
    },
  ];
  const [loading, setLoading] = useState(false);
  const [opportunities, setOpps] = useState([]);
  const [opportunity, setOpportunity] = useState(null);

  const [showCreate, setShowCreate] = useState(false);
  const [newOpportunityName, setNewOpportunityName] = useState('');
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [episodeDetails, setEpisodeDetails] = useState({
    isVisible: false,
    selected: null,
  });
  const searchTimeoutRef = useRef<any>(null);

  const [newOpportunity, setNewOpportunity] = useState({
    name: '',
    members: [],
    contacts: [],
    occurrences: [],
    step: 'QUICKSTART',
    isVisible: false,
  });

  const [pagination, setPagination] = useState({
    page: 1,
    pages: 0,
    total: 0,
    limit: 20,
  });
  const navHistory = useNavigate();
  const {opportunityId} = useParams();

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], cSelectedRows: any[]) => {
      setSelectedRows(cSelectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const getHeaders = async () => {
    const userDataString = await LocalStorage.getItem('user');
    const userData = JSON.parse(userDataString);

    const responseAccessToken = userData?.accessToken || '';
    const fold_access_token = userData?.fold_access_token;

    const headersObj: any = {
      'access-token': responseAccessToken,
      fold_access_token,
    };

    return headersObj;
  };

  const currentUserData = getUserData();
  const fetchOpportunities = async ({
    status,
    term,
    limit,
    page,
  }: {
    status?: string;
    term?: string;
    page?: number | string;
    limit?: number | string;
  }) => {
    try {
      setLoading(true);
      const urlSearch = new URLSearchParams();
      if (status) {
        urlSearch.append('status', status.toString() || 'PENDING');
      } else {
        urlSearch.append('status', 'PENDING');
      }
      if (term) {
        urlSearch.append('term', term);
      }
      if (typeof limit !== 'undefined') {
        urlSearch.append(
          'limit',
          limit.toString() || pagination.limit.toString()
        );
      }
      if (typeof page !== 'undefined') {
        urlSearch.append('page', page.toString());
      }

      const authHeaders = await getHeaders();
      const req = await fetch(
        `https://dev.foldhealth.io/opportunities${
          urlSearch.toString() ? `?${urlSearch.toString()}` : ''
        }`,
        {
          headers: authHeaders,
        }
      );
      const res = await req.json();

      if (res.status) {
        setOpps(res.results);
        setPagination({
          ...pagination,
          pages: res.pages,
          total: res.count,
          ...(typeof page !== 'undefined'
            ? {page: Number(page)}
            : {page: pagination.page}),
          ...(typeof limit !== 'undefined'
            ? {limit: Number(limit)}
            : {limit: pagination.limit}),
        });
      } else {
        if (res.opportunities) {
          setOpps(res.opportunities);
        }
      }
    } catch (err: any) {
    } finally {
      setLoading(false);
    }
  };

  const toggleFavorite = async (opportunity: any) => {
    try {
      setLoading(true);
      const authHeaders = await getHeaders();
      const req = await fetch(
        'https://dev.foldhealth.io/opportunities/' +
          opportunity._id +
          '/favorites/toggle',
        {
          method: 'POST',
          headers: authHeaders,
        }
      );
      const res = await req.json();

      setOpps(
        (opportunities as any).map((opp: any) =>
          opp._id === opportunity._id ? res : {...opp}
        )
      );
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (
    e: React.MouseEvent<HTMLButtonElement>,
    value?: string
  ) => {
    e.preventDefault();

    const newValue = value;
    newValue && setSelectedTab(newValue);
    newValue && fetchOpportunities({status: newValue});
  };

  const handleRowClick = (opportunityId?: string | null) => {
    if (!opportunityId) return;

    navHistory('/opportunities/' + opportunityId + '/view');
  };

  useEffect(() => {
    fetchOpportunities({status: 'PENDING'});
  }, []);

  const handleCreateQuickstart = async () => {
    if (!newOpportunity.name) return alert('Opportunity name is missing');
    const authHeaders = await getHeaders();
    try {
      setLoading(true);
      const req = await fetch(
        'https://dev.foldhealth.io/opportunities/quickstart',
        {
          method: 'POST',
          body: JSON.stringify({
            name: newOpportunity.name,
          }),
          headers: {'Content-Type': 'application/json', ...authHeaders},
        }
      );
      const res = await req.json();

      setNewOpportunity({
        ...newOpportunity,
        step: 'ASSOCIATE',
        isVisible: true,
      });
      setShowCreate(false);
      onNewData([{...res, creationDate: res.createdAt}]);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };

  const [modalStep, setModalStep] = useState<IModalStep>({
    step: 'AUTOMATE_QUICKSTART',
    isVisible: false,
    opportunity: null,
    automation: null,
  });
  const closeAutomation = () => {
    setModalStep({
      ...modalStep,
      isVisible: false,
    });
  };

  const handleSendAutomation = () => {
    alert('send');
  };

  const [tableCols, setTableCols] = useState(dataSource);
  const onNewData = (added: any) => {
    setOpps(opportunities.concat(added));
    closeAutomation();
  };

  const handleToggleFavorite = async (oppId: string) => {
    try {
      const authHeaders = await getHeaders();
      const req = await fetch(
        `https://dev.foldhealth.io/opportunities/${oppId}/favorites/toggle`,
        {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            ...authHeaders,
          },
        }
      );
      const res = await req.json();
    } catch (error: any) {
    }
  };
  return (
    <View>
      <div className="opportunityCreateModal">
        <OpportunityAutomation
          onOkClick={() => handleSendAutomation()}
          onClose={closeAutomation}
          isVisible={
            modalStep.isVisible && modalStep.step === 'AUTOMATE_QUICKSTART'
          }
          setModalStep={setModalStep}
          modalStep={modalStep}
        />
        {modalStep.isVisible && modalStep.step === 'AUTOMATE_RESULTS' && (
          <OpportunityAutomationResults
            onNewData={onNewData}
            automationId={
              modalStep.automation?._id || modalStep.opportunity?._id
            }
            setModalStep={setModalStep}
            modalStep={modalStep}
            okText={'Save'}
          />
        )}
        <OpportunityAssociationModal
          title={
            <div className="flex flex-col items-start justify-start w-full pt-4">
              <h1 className="text-[24px] capitalize">{newOpportunity.name}</h1>
              <p>
                Review your selection and associate it with a Care Journey
                (optional)
              </p>
            </div>
          }
          isVisible={
            newOpportunity.step === 'ASSOCIATE' && newOpportunity.isVisible
          }
          onClose={() =>
            setNewOpportunity({...newOpportunity, isVisible: false})
          }
          onOkClick={() =>
            setNewOpportunity({...newOpportunity, isVisible: false})
          }
        >
          <OpportunitySelection />
        </OpportunityAssociationModal>
        <Modal
          title="Create New Opportunity"
          visible={showCreate}
          width={416}
          onCancel={(e) => setShowCreate(false)}
          cancelButtonProps={{
            className: 'secondaryFilledBtn',
          }}
          onOk={handleCreateQuickstart}
          okText="Continue"
        >
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <label
              style={{
                fontWeight: 500,
                fontSize: '14px',
              }}
            >
              Opportunity Name
            </label>
            <Input
              borderRadius="8px"
              padding="10px 16px 10px 16px"
              height="40px"
              value={newOpportunity.name}
              onChangeText={(val) =>
                setNewOpportunity({...newOpportunity, name: val})
              }
              type="text"
              placeholder="My New Opportunity"
            />
          </div>
        </Modal>
      </div>
      {opportunityId && opportunityId !== '' && <Outlet />}
      {!opportunityId && (
        <div className="analyticsOpportunitiesContainer">
          <View background={'#fff'}>
            <TitleSubtitleView titleLabelId="opportunities" />
          </View>
          <View
            paddingX="5px"
            paddingY="0px"
            background={'#fff'}
            borderBottomWidth={1}
            borderBottomColor={'#E4E7EC'}
          >
            <VStack direction="row">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',

                  justifyContent: 'flex-start',
                }}
              >
                <TableTab
                  text="Ongoing"
                  isActive={selectedTab === 'ONGOING'}
                  value="ONGOING"
                  handleSelect={handleTabChange}
                />
                <TableTab
                  text="Pending"
                  isActive={selectedTab === 'PENDING'}
                  value="PENDING"
                  handleSelect={handleTabChange}
                />
                <TableTab
                  text="Completed"
                  isActive={selectedTab === 'COMPLETED'}
                  value="COMPLETED"
                  handleSelect={handleTabChange}
                />
                <TableTab
                  text="Archived"
                  isActive={selectedTab === 'ARCHIVED'}
                  value="ARCHIVED"
                  handleSelect={handleTabChange}
                />

                <SearchBar
                  onChange={(val) => {
                    clearTimeout(searchTimeoutRef.current);
                    searchTimeoutRef.current = setTimeout(() => {
                      fetchOpportunities({
                        status: selectedTab || 'ONGOING',
                        term: val,
                      });
                    }, 300);
                  }}
                  placeholderText="Search"
                  variant={'filled'}
                  searchBarInputStyles={{
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                    marginBottom: 4,
                    fontSize: 14,
                  }}
                  borderWidth={1}
                  borderRadius={12}
                  borderColor={Colors.Custom.Gray200}
                  placeholderTextColor={InputStyle.placeholderTextColor}
                  iconColor={Colors.Custom.Gray600}
                />
              </div>

              <div
                className="actionButtonsContainer"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {/* <Dropdown
                    overlay={SingularActionsDropdownMenu}
                    placement="bottom"
                  >
                    <Button
                      className="primarySmoothBtn"
                      shape="round"
                      size="large"
                      icon={<FilterFilled style={{fontSize: '16px'}} />}
                      style={gOppStyles.secondaryBtn}
                    >
                      Filter
                    </Button>
                  </Dropdown> */}

                <Dropdown
                  overlay={
                    <BulkActionsDropMenu
                      onDataUpdated={(opportunity) => {
                        setOpps(
                          opportunities.filter(
                            (opp: any) =>
                              (opp._id || opp.id) ===
                              (opportunity._id || opportunity.id)
                          )
                        );
                      }}
                      opportunities={selectedRows}
                    />
                  }
                  placement="bottom"
                >
                  <Button
                    className="primarySmoothBtn"
                    shape="round"
                    size="large"
                    icon={<MoreOutlined style={{fontSize: '16px'}} />}
                    style={gOppStyles.secondaryBtn}
                  >
                    Bulk Actions
                  </Button>
                </Dropdown>

                {/* TODO: */}

                <Dropdown
                  overlay={
                    <Menu
                      items={[
                        {key: 1, label: 'Opportunity Finder', disabled: true},
                        {
                          key: 2,
                          label: 'Opportunity Automation',
                          onClick: () =>
                            setModalStep({
                              ...modalStep,
                              step: 'AUTOMATE_QUICKSTART',
                              isVisible: true,
                            }),
                        },
                      ]}
                    />
                  }
                  placement="bottom"
                >
                  <Button
                    className="primaryFilledBtn"
                    type="primary"
                    shape="round"
                    size="large"
                    icon={<PlusOutlined />}
                    // onClick={(e) => setShowCreate(true)}
                    style={gOppStyles.primaryBtn}
                  >
                    New Opportunity
                  </Button>
                </Dropdown>
              </div>
            </VStack>
          </View>
          <View padding={'16px'}>
            <Card>
              <Table
                rowClassName={(record, index) =>
                  index % 2 == 0 ? 'table-row-light' : ''
                }
                rowKey="_id"
                dataSource={
                  opportunities
                    ? opportunities.map((opp: any) => ({
                        ...opp,
                        name: opp.name.replace(/latest_/gi, ''),
                      }))
                    : opportunities
                }
                columns={columns}
                loading={loading}
                rowSelection={{type: 'checkbox', ...rowSelection}}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      // handleRowClick(record.key || (record as any)._id);
                    }, // click row
                  };
                }}
                pagination={{
                  current: pagination.page,
                  defaultCurrent: 1,
                  pageSize: pagination.limit,
                  total: pagination.pages,
                  onChange: (page, pageSize) => {
                    fetchOpportunities({page, limit: pageSize});
                  },
                  nextIcon: (
                    <div className="paginationNext flex items-baseline justify-center border border-gray-300 rounded px-4">
                      <span className="mr-2 font-medium">Next</span>{' '}
                      <ArrowRightOutlined />
                    </div>
                  ),
                  prevIcon: (
                    <div className="paginationPrev flex items-baseline justify-center border border-gray-300 rounded px-4">
                      <ArrowLeftOutlined />{' '}
                      <span className="ml-2 font-medium">Previous</span>
                    </div>
                  ),
                }}
              />
            </Card>
          </View>
        </div>
      )}
    </View>
  );
};

export default AnalyticsPage;
