import {Divider, HStack, Text, View, VStack} from 'native-base';
import {Colors} from '../../../../../../../styles';
import {IStockLocationInfoProps} from '../interfaces';
import {SingleVariantStockInfo} from './SingleVariantStockInfo';

export const StockLocationInfo = (props: IStockLocationInfoProps) => {
  const {
    singleVariantData,
    stockLocationList,
    onStockLocationInfoActionPerformed,
  } = props;
  const stockItems = singleVariantData?.relationships?.stock_items?.data || [];
  const getHeaderElem = () => {
    return (
      <HStack
        borderWidth={1}
        borderColor={Colors.Custom.BorderColor}
        space={3}
        divider={<Divider />}
        w="90%"
      >
        <VStack flex={0.3} pl={2} pt={2} pb={2} justifyContent="space-between">
          <Text size={'mdMedium'}>Stock Location</Text>
        </VStack>
        <VStack flex={0.3} pl={2} pt={2} pb={2} justifyContent="space-between">
          <Text size={'mdMedium'}>Count On Hand</Text>
        </VStack>
        <VStack flex={0.2} pl={2} pt={2} pb={2} justifyContent="space-between">
          <Text size={'mdMedium'}>Back Orderable </Text>
        </VStack>
        <VStack
          flex={0.2}
          pl={2}
          pt={2}
          pb={2}
          justifyContent="space-between"
        ></VStack>
      </HStack>
    );
  };
  return (
    <VStack mt={2} pt={2} pb={2} flex={0.64}>
      {getHeaderElem()}
      {stockItems.map((singleStockItem) => {
        return (
          <View key={singleStockItem.id}>
            <SingleVariantStockInfo
              singleStockItem={singleStockItem}
              stockLocationList={stockLocationList || []}
              onStockLocationInfoActionPerformed={
                onStockLocationInfoActionPerformed
              }
            />
          </View>
        );
      })}
    </VStack>
  );
};
