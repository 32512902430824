import { Typography } from 'antd';
import { HStack, Skeleton, Text, View, VStack } from 'native-base';
import { useEffect, useState } from 'react';
import { Colors } from '../../../styles/Colors';
import { getAccountUUID, getDisplayTimeSaved } from '../../../utils/commonUtils';
import { getTotalTimeSavedByWorkflow } from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import ClockIcon from '../Svg/ClockIcon';
import {getEnvVariable} from '../../../constants/BaseUrlConst';
import {getSectionDataByResourceCode} from '../../../services/Analytics/index';

const {Title} = Typography;

const FoldAdvantangeReview = () => {
  const tenantId = getAccountUUID();
  const [timeSaveInSeconds, setTimeSaveInSeconds] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const variables: any = {
      nodeLogBoolExp: {
        tenantId: {
          _eq: tenantId,
        },
      },
    };
    const env = getEnvVariable();
    if (env === 'dev' || env === 'qa') {
      getTotalTimeSavedByWorkflow(variables).then((response) => {
        const timeSaveInSeconds =
          response?.timeSaveInSeconds?.aggregate?.sum?.timeSaveInSeconds;
        setTimeSaveInSeconds(timeSaveInSeconds);
        setIsLoading(false);
      });
    } else {
      const reqBody = {
        resourceCode: 'AUTOMATION_TIME_SAVED',
        isAccountDashboard: true,
        memberType: 'ALL_MEMBERS',
        appliedFilter: [],
      };

      const resourceCode = 'AUTOMATION_TIME_SAVED';

      const endpoint = '/section-data?AUTOMATION_TIME_SAVED';
      getSectionDataByResourceCode(resourceCode, reqBody, endpoint).then(
        (response) => {
          let time = 0;
          (response?.data?.result || []).forEach((item: {countNum: number}) => {
            time = time + (item?.countNum || 0);
          });
          setTimeSaveInSeconds(time * 60);
          setIsLoading(false);
        }
      );
    }
  }, [tenantId]);

  return (
    <div className="display_card_container" style={{height: '358px'}}>
      <header style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <Title level={5} className="display_card_title">
            {'Fold Advantage'}
          </Title>
          <Text
            lineHeight={20}
            style={{fontSize: 14, fontWeight: '500', color: Colors.Custom.GraphSubTitleColor}}
          >
            {'Benefits with Fold'}
          </Text>
        </div>
      </header>
      {/* <HStack bgColor={Colors.Custom.Gray100} height={60} alignItems={'center'} borderTopLeftRadius={8} borderTopRightRadius={8} paddingLeft={2}>
                <ThumbsUpIcon></ThumbsUpIcon>
                <Text style={{ fontSize: 20, fontWeight: '700', color: '#101828', marginLeft: 6 }}>
                    {'Fold Advantage'}
                </Text>
            </HStack> */}

      <HStack flex={1}>
        {timeSaveInSeconds ? (
          <VStack
            flex={1}
            ml={5}
            mr={5}
            alignContent={'center'}
            justifyContent={'center'}
            paddingTop={4}
          >
            <HStack
              mt={2}
              flex={1}
              justifyContent={'space-between'}
              alignContent={'center'}
              alignItems={'center'}
            >
              <View flex={1}>
                {isLoading ? (
                  <Skeleton.Text lines={1} />
                ) : (
                  <Text
                    style={{fontSize: 36, fontWeight: '700', color: '#101828'}}
                  >
                    {getDisplayTimeSaved(timeSaveInSeconds)}
                  </Text>
                )}
              </View>
              {/* <View flex={1} ml={5} mr={0} alignSelf={'flex-end'} justifyItems={'center'}> */}
              <ClockIcon></ClockIcon>
              {/* </View> */}
            </HStack>
            <Text
              mt={3}
              flex={1}
              alignSelf={'center'}
              style={{
                paddingTop: 12,
                fontSize: 14,
                fontWeight: '400',
                color: '#667085',
              }}
            >
              You're saving {getDisplayTimeSaved(timeSaveInSeconds)} worth of time using Fold automations
            </Text>
          </VStack>
        ) : (
          <VStack
            flex={1}
            ml={5}
            mr={5}
            alignContent={'center'}
            justifyContent={'center'}
            paddingTop={4}
          >
            <HStack
              mt={2}
              flex={1}
            >
              <ClockIcon></ClockIcon>
            </HStack>
            <Text
              mt={3}
              flex={1}
              alignSelf={'center'}
              style={{
                paddingTop: 12,
                fontSize: 14,
                fontWeight: '400',
                color: '#667085',
              }}
            >
              Use Fold Automations to save up to 30% of your time
            </Text>
          </VStack>
        )}

        {/* <View bgColor={Colors.Custom.Gray200} width={0.3} ></View>

                <VStack flex={1} ml={5} mr={5} alignContent={'center'} justifyContent={'center'} paddingTop={4}>
                    <HStack mt={2} flex={1} justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
                        <Text flex={1} style={{ fontSize: 36, fontWeight: '700', color: '#101828' }}>
                            {'90%'}
                        </Text>

                        <LeadIcon></LeadIcon>

                    </HStack>
                    <Text mt={3} justifySelf={'center'} flex={1} style={{paddingTop: 12, fontSize: 14, fontWeight: '400', color: '#667085' }}>
                        In last 3 months, you've converted 90 leads out of 100
                    </Text>
                </VStack> */}
      </HStack>
    </div>
  );
};

export default FoldAdvantangeReview;
