import {Tooltip} from 'antd';
import React from 'react';
import {StyleSheet} from 'react-native';
import './Widgets.css'

export const ToolTipView = (props: any) => {
  return (
    <Tooltip
      overlayClassName="task-tool-tip"
      placement={'left'}
      overlayInnerStyle={{
        backgroundColor: '#fff',
        borderRadius: 8,
        width: '200px',
        padding: 12
      }}
      arrowPointAtCenter={false}
      overlayStyle={{backgroundColor: '#fff', width: '200px'}}
      overlay={props.overlay}
      style={{background: 'white', borderColor: '#fff'}}
    >
      {props.children}
    </Tooltip>
  );
};

const styles = StyleSheet.create({
  overlayInner: {
    backgroundColor: '#fff',
    borderRadius: 8,
    width: '200px',
    padding: 12
  },
  overlay: {
    backgroundColor: '#fff',
    width: '200px'
  },
  tooltip: {
    background: 'white',
    borderColor: '#fff'
  }
});
