import { DatePicker } from 'antd';
import { Moment } from 'moment';
import { View, useToast } from 'native-base';
import { DATE_FORMATS } from '../../../../constants';
import {
  getDiffInMonths,
  getDiffInYears,
  getFormattedDate,
  getFormattedMomentObj,
  isFutureDate,
} from '../../../../utils/DateUtils';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
import { RangeValue } from '../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/hooks/useReccuringEvent/interface';
import { IDateRangePicker } from './Interfaces';
import { TimeUnitValues } from './constants';
import { styles } from './style';
import './styles.css';
const {RangePicker} = DatePicker;

const DateRangePicker = (props: IDateRangePicker) => {
  const {onDateRangeChange, dateRange, isDateRangeRestricted, dateComponents} =
    props;
  const toast = useToast();
  const disabledDate = (current: Moment) => {
    return current && isFutureDate(current);
  };
  
  const handleDateRangeChange = (values: RangeValue<Moment>) => {
    if (values && values[0] && values[1]) {
      let diffInDate = 0;
      if (dateComponents?.timeUnit === TimeUnitValues.month) {
        diffInDate = getDiffInMonths(values[0], values[1]);
      } else {
        diffInDate = getDiffInYears(values[0], values[1]);
      }
      const fromDate = getFormattedDate(
        values?.[0]?.toString(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      );
      let toDate = getFormattedDate(
        values?.[1]?.toString(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      );
      if (
        isDateRangeRestricted &&
        dateComponents?.dateRange &&
        diffInDate > dateComponents?.dateRange
      ) {
        toDate = values[0]
          .add(dateComponents?.dateRange, dateComponents?.timeUnit)
          .format(DATE_FORMATS.DISPLAY_DATE_FORMAT);
        onDateRangeChange({
          from: fromDate,
          to: toDate,
        });
        showToast(
          toast,
          `Selected date range cannot exceed ${dateComponents?.dateRange} ${dateComponents?.timeUnit}.`,
          ToastType.info,
          4000
        );
      } else {
        onDateRangeChange({
          from: fromDate,
          to: toDate,
        });
      }
    }
  };
  return (
    <View flexDirection={'row'} style={styles.datePickerContainer}>
      <RangePicker
        dropdownClassName="date-range-analytics"
        placeholder={['Select start date', 'Select end date']}
        allowClear={false}
        showTime={false}
        style={{
          height: 44,
          width: '100%',
          borderRadius: 8,
          color: '#3A485F',
        }}
        value={[
          getFormattedMomentObj(
            dateRange?.from || new Date(),
            DATE_FORMATS.DISPLAY_DATE_FORMAT
          ),
          getFormattedMomentObj(
            dateRange?.to || new Date(),
            DATE_FORMATS.DISPLAY_DATE_FORMAT
          )
        ]}
        format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
        disabledDate={disabledDate}
        onChange={(value, dateString) => {
          if (value?.length) {
            handleDateRangeChange(value);
          }
        }}
      />
    </View>
  );
};

export default DateRangePicker;
