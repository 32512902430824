import {Divider, Skeleton} from 'antd';
import {parseInt} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {ANALYTICS_RESOURCE} from '../../../../../../constants';
import {getSectionDataByResourceCode} from '../../../../../../services/Employer/EmployerServices/EmployerServices';
import {Colors} from '../../../../../../styles';
import {isEmployerRole} from '../../../../../../utils/commonUtils';
import EyeHideSvg from '../../../../../common/Svg/WidgetsSvg/EyeHideSvg';
import IncreaseArrow from '../../../../../common/Svg/WidgetsSvg/IncreaseArrow';
import RightArrow from '../../../../../common/Svg/WidgetsSvg/RightArrow';
import InfoIconView from '../../CommonView/InfoIconView';
import {DashboardDataContext} from '../../EmployerContext/DashboardContext';
import {EMPLOYEE_CODE, WIDGET_ACTION_CODE} from '../../EmployerUtils/EmployerConst';
import {getIconForWidgetByCode} from '../../EmployerUtils/WidgetSvgHelper';
import {
  getCountSumFromResource,
  getDependentCodeList,
  getDependentCount,
  getEmployeeCount,
  getPercentageChange,
  isWidgetDataEmpty
} from '../../EmployerUtils/WidgetUtils';
import {IAppliedFilter, IResource, IWidgetCardView} from '../../interface';
import {EMPLOYEE_COUNT, PATIENT_SATISFACTION_DRILL_DOWN, TEMP_ENGAGE_FOR_CARE, getTenantForDemo} from '../../tempDataFile';
import NodataViewTypeOne from '../NoDataView/NodataViewTypeOne';
import WidgetActionView from '../WidgetActionView';
import {Pressable, Tooltip} from 'native-base';
import RestSvgIcons from '../../../../../common/Svg/RestSvgIcons';
import WidgetHeader from '../../WidgetHeader/WidgetHeader';

const WidgetCardView = (props: IWidgetCardView) => {
  const {
    sectionData,
    onActionClick,
    dateRangeType,
    dateRange,
    onViewCodeCallBack,
    employerId,
    appliedFilter,
    isAccountDashboard,
    memberType,
  } = props;
  const intl = useIntl();
  const controller = new AbortController();
  const isEmployer = isEmployerRole()
  const [stateData, setStateData] = useState({
    resourceData: [] as IResource[] | any,
    loading: false,
    employeeTotalCount: 0,
    selectedView: '',
    selectedResourceData: {} as any,
    masterData: {} as any,
    appliedFilter: props.appliedFilter
  });
  const isCustomerSatisfaction = sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION;
  const isNPSForm = sectionData.resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE;
  const isEmployerCount = sectionData.resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_COUNT;
  const isEnrolledForCare = sectionData.resourceCode === ANALYTICS_RESOURCE.ENROLLED_FOR_CARE
  const isDashboardAssign = props.reusableData?.isDashboardAssign;
  const subHeading = sectionData.subHeading
    ? sectionData.subHeading : '';
  const isFormAssigned = sectionData?.additionalAttributes?.formId ? true : false;
  const getCountValue = () => {
    if (isCustomerSatisfaction) {
      let avgRating = 0;
      if (stateData?.resourceData?.scoreData?.allContactMaxScore && stateData?.resourceData?.scoreData?.allContactScore) {
        avgRating = parseInt(((stateData?.resourceData?.scoreData?.allContactScore / stateData?.resourceData?.scoreData?.allContactMaxScore) * 100) as any);
      }
      return avgRating;
    }
    if (
      isEmployerCount
    ) {
      return stateData?.resourceData?.totalCount || stateData.employeeTotalCount;
    } else {
      return getCountSumFromResource(
        stateData?.resourceData,
        dateRangeType || '',
        sectionData.resourceCode || '',
        dateRange,
      );
    }
  };
  const getSectionByResourceCode = async (appliedFilter?: IAppliedFilter[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let formId = '';
    if (sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION && sectionData?.additionalAttributes?.formId) {
      formId = sectionData?.additionalAttributes?.formId;
    }
    const filterList = appliedFilter?.length ? appliedFilter : stateData.appliedFilter
    const response = await getSectionDataByResourceCode(
      employerId,
      sectionData.resourceCode || '',
      filterList,
      formId,
      isAccountDashboard,
      memberType,
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          resourceData: [],
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data && isWidgetDataEmpty(response?.data)) {

      const resourceData = response.data?.totalCount ? response.data : response.data?.result || response.data;
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: [] as IResource[],
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };
  const isAvgRatingPresent = () => {
    let isAvgRating = false;
    if (stateData?.resourceData?.scoreData?.allContactMaxScore && stateData?.resourceData?.scoreData?.allContactScore) {
      const avgRating = parseInt(((stateData?.resourceData?.scoreData?.allContactScore / stateData?.resourceData?.scoreData?.allContactMaxScore) * 100) as any);
      if (avgRating > 1) {
        isAvgRating = true;
      }
    }
    return isAvgRating;
  }

  const getCountValueElem = () => {
    if (isCustomerSatisfaction) {
      let avgRating = -1;
      if (stateData?.resourceData?.scoreData?.allContactMaxScore && stateData?.resourceData?.scoreData?.allContactScore) {
        avgRating = parseInt(((stateData?.resourceData?.scoreData?.allContactScore / stateData?.resourceData?.scoreData?.allContactMaxScore) * 100) as any);
      }
      if (avgRating > 1) {
        return (
          <div
            style={{
              paddingTop: '4px',
            }}>
            <div>
              <p
                style={{
                  margin: 0,
                  fontSize: 24,
                  fontFamily: 'Manrope-Bold',
                }}
              >
                {avgRating + '%'}
              </p>

            </div>
            {
              (stateData?.resourceData?.totalFormSend && stateData?.resourceData?.totalFormSubmitted) &&
              (
                <div
                  style={{
                    color: 'rgb(152, 162, 179)',
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: 'Manrope-Medium',
                  }}
                >
                  {'based on ' + stateData?.resourceData?.totalFormSubmitted + '/' + stateData?.resourceData?.totalFormSend + ' responses'}
                </div>
              )
            }

          </div>
        );
      } else {
        return <div></div>
      }
    }
    // if (isEnrolledForCare) {
    //   return getTenantForDemo() ? TEMP_ENGAGE_FOR_CARE.totalCount : props.resourceData?.totalCount;
    // }
    if (sectionData.resourceCode === ANALYTICS_RESOURCE.EMPLOYEE_COUNT
      || sectionData.resourceCode === ANALYTICS_RESOURCE.ENROLLED_FOR_CARE) {
      const empTypeCountList = stateData.resourceData?.additionalData?.empByType || [];
      // if (getTenantForDemo()) {
      //   empTypeCountList = EMPLOYEE_COUNT.additionalData?.empByType || []
      // }

      return (
        <div
          style={{
            paddingTop: '4px',
            display: 'flex'
          }}>
          <div
            onClick={() => {
              const tempList = getDependentCodeList(empTypeCountList);
              const filterData = {
                filterCode: 'EMPLOYEE_TYPE',
                filterValue: [EMPLOYEE_CODE],
              };
              props?.onActionClick?.(
                sectionData.resourceCode || '',
                {filterData}
              );
            }}
            style={{
              paddingRight: '16px',
              cursor: 'pointer',
            }}>
            <div
              style={{
                color: '#98A2B3',
                fontSize: 12,
                fontWeight: 500,
                fontFamily: 'Manrope-Medium',
                lineHeight: '16px'
              }}
            >
              {intl.formatMessage({id: 'employees'})}
            </div>

            <p
              style={{
                margin: 0,
                fontSize: 24,
                fontFamily: 'Manrope-Bold',
              }}
            >
              {getEmployeeCount(empTypeCountList)}
            </p>

          </div>
          <Divider type="vertical" style={{
            height: 'auto',
            borderColor: '#E4E7EC',
            marginTop: '8px',
          }} />
          <div
            onClick={() => {
              const dependentCodeList = getDependentCodeList(empTypeCountList);
              const filterData = {
                filterCode: 'EMPLOYEE_TYPE',
                filterValue: [...dependentCodeList],
              };
              props?.onActionClick?.(
                sectionData.resourceCode || '',
                {filterData}
              );
            }}
            style={{
              cursor: 'pointer',
              paddingLeft: '16px',
            }}>
            <div
              style={{
                color: '#98A2B3',
                fontSize: 12,
                fontWeight: 500,
                fontFamily: 'Manrope-Medium',
                lineHeight: '16px'
              }}
            >
              {intl.formatMessage({id: 'dependents'})}
            </div>

            <p
              style={{
                margin: 0,
                fontSize: 24,
                fontFamily: 'Manrope-Bold',
              }}
            >
              {getDependentCount(empTypeCountList)}
            </p>

          </div>
        </div>
      )
    } else if (sectionData.resourceCode !== ANALYTICS_RESOURCE.EMPLOYEE_COUNT) {
      return getCountSumFromResource(
        stateData.resourceData,
        dateRangeType || '',
        sectionData.resourceCode || '',
        dateRange
      );
    } else {
      return stateData?.resourceData?.totalCount || stateData.employeeTotalCount;
    }
  };

  const percentageChange: any = !isCustomerSatisfaction
    ? getPercentageChange(
      stateData?.resourceData,
      sectionData.resourceCode || '',
      dateRangeType || '',
      dateRange
    )
    : ({} as any);
  const actionText = 'create a form';

  useEffect(() => {
    if (getTenantForDemo()) {
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          loading: true
        }
      })
      const resourceData: any = getTenantForDemo()
        ? (isCustomerSatisfaction
          ? PATIENT_SATISFACTION_DRILL_DOWN.result
          : isEnrolledForCare
            ? TEMP_ENGAGE_FOR_CARE.result
            : isEmployerCount ? EMPLOYEE_COUNT : stateData.resourceData)
        : isCustomerSatisfaction
          ? stateData.resourceData
          : stateData.resourceData?.result?.length
            ? stateData.resourceData?.result
            : stateData.resourceData;

      setTimeout(() => {
        setStateData((prev) => {
          return {
            ...prev,
            resourceData: resourceData,
            loading: false
          }
        })
      }, 3000)
      return
    }
    getSectionByResourceCode();
    return () => controller?.abort();
  }, [sectionData.id])
  useEffect(() => {
    if (props.filterData?.resourceCode == sectionData.resourceCode) {
      const appliedFilter = stateData.appliedFilter?.length ? [...stateData.appliedFilter] : [];
      const selectedFilter = {
        filterCode: props.filterData?.filterCode,
        filterValue: props.filterData?.filterValue
      }
      appliedFilter.push(selectedFilter);
      getSectionByResourceCode(appliedFilter);
    }
    return () => controller?.abort();
  }, [props.filterData?.filterValue])

  const getRefreshButtonOnLocalEnv = () => {
    if (window.location.host.includes('localhost')) {
      return (
        <Pressable
          style={{marginHorizontal: 3}}
          onPress={() => {
            setStateData(prev => {
              return {
                ...prev,
                filterValueName: [],
                filterValueString: ''
              }
            })
            getSectionByResourceCode();
          }}
        >
          <Tooltip label="Refresh">
            <RestSvgIcons />
          </Tooltip>
        </Pressable>
      )
    }
    return <></>;
  }

  return (
    <div className="widgetBoxShadow">
      <WidgetHeader isPrintPreview={props.isPrintPreview} isPrintDownload={props.isPrintDownload} sectionData={sectionData} onActionClick={onActionClick} />
      {stateData.loading ? (
        <Skeleton style={{padding: 40}} active={true} />
      ) : (
        <>

          {(stateData?.resourceData.length || getCountValue()) || !isFormAssigned ? (
            <div style={{flex: 1}}>
              <div>

                <div
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex',
                    padding: '15px 26px 15px 23px',
                  }}
                >
                  <div style={{display: 'flex', flex: 0.1, width: 34, height: 45, marginRight: '15px'}}>
                    {getIconForWidgetByCode(sectionData.resourceCode || '')}
                  </div>
                  <p
                    style={{
                      margin: 0,
                      fontSize: 24,
                      fontFamily: 'Manrope-Bold',
                    }}
                  >
                    {getCountValueElem()}

                  </p>
                  {!isCustomerSatisfaction &&
                    !percentageChange?.noView &&
                    !isEmployerCount ? (
                    <div style={{marginLeft: 12}}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      // "display: flex; flex-direction: row; align-items: center;"
                      >
                        <div
                          style={{
                            display: 'flex',
                            minHeight: 22,
                            height: 22,
                            paddingLeft: 4,
                            paddingRight: 4,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 16,
                            background: (percentageChange.positive || getTenantForDemo()) ? '#ecfdf3' : 'rgb(244, 204, 201)',
                          }}
                        >
                          <div
                            style={{
                              width: 9,
                              height: 9,
                              rotate: (percentageChange.positive || getTenantForDemo())
                                ? '0deg'
                                : '180deg',
                              position: 'relative',
                              top: (percentageChange.positive || getTenantForDemo()) ? -7 : 7,
                              marginRight: 3,
                            }}
                          >
                            <IncreaseArrow
                              color={
                                (percentageChange.positive || getTenantForDemo())
                                  ? '#027A48'
                                  : '#D92D20'
                              }
                            />
                          </div>
                          <div>
                            <div
                              style={{
                                margin: 0,
                                color: (percentageChange.positive || getTenantForDemo())
                                  ? '#027A48'
                                  : '#D92D20',
                                fontSize: 10,
                                fontFamily: 'Manrope-Regular',
                              }}
                            >
                              {percentageChange.percentageChange}%
                            </div>
                          </div>
                        </div>
                        <div style={{marginLeft: 14}}>
                          <div
                            style={{
                              margin: 0,
                              color: (percentageChange.positive || getTenantForDemo())
                                ? '#027A48'
                                : '#D92D20',
                              fontSize: 10,
                              fontFamily: 'Manrope-Regular',
                            }}
                          >
                            {' '}
                            {`vs ${props.dateRangeType?.toLocaleLowerCase()}`}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NodataViewTypeOne viewCode={'ONLY_TEXT'} />
            </div>
          )}
          {!isEmployer && !isFormAssigned && ((isNPSForm || isCustomerSatisfaction && isDashboardAssign) && !isAvgRatingPresent()) ? (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div
                onClick={() => {
                  onViewCodeCallBack?.(WIDGET_ACTION_CODE.FORM_LIST_VIEW);
                }}
                style={{
                  fontSize: 12,
                  color: Colors.primary['400'],
                  fontWeight: 400,
                  cursor: 'pointer',
                  textDecorationLine: 'underline',
                  textDecorationColor: Colors.primary['400'],
                  padding: '15px 1px 15px 23px'
                }}
              >
                {actionText}
              </div>
              <div
                style={{
                  fontSize: 12,
                  color: Colors.Custom.Gray400,
                  fontWeight: 400,
                  marginLeft: 3,
                }}
              >
                or link existing to get feedback.
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(WidgetCardView);

// {isLineGraphView() ? (
//   <div
//     style={{justifyContent: 'center', flex: .3, height: 110, display: 'flex'}}
//   >
// <LineGraphWidget
//   reusableData={{
//     isPostive: percentageChange.positive
//   }}
//   loading={loading}
//   sectionData={sectionData}
//   resourceData={resourceData}
// />
//   </div>
// ) : null}
// getIconForWidget(0.1)}
