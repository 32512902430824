import React from "react";

const AppointmentBookedSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
   <svg
   xmlns="http://www.w3.org/2000/svg"
   style={{width: 'inherit', height: 'inherit'}}
   fill="none"
   viewBox="0 0 34 34"
 >
   <path
     fill={svgColors}
     d="M26.917 1.417H12.75A2.842 2.842 0 009.917 4.25V8.5h2.833V5.667h14.167v22.667H12.75V25.5H9.917v4.25a2.842 2.842 0 002.833 2.834h14.167a2.842 2.842 0 002.833-2.834V4.25a2.842 2.842 0 00-2.833-2.833zM9.931 19.083L6.32 15.47l-1.8 1.8 5.398 5.397 10.186-10.186-1.8-1.799-8.372 8.4z"
   ></path>
 </svg>
  );
}

export default React.memo(AppointmentBookedSvg);
