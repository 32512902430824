import axios from 'axios';
import { IPatientNotesSelectedFilters } from '../../components/PersonOmniView/MiddleContainer/PatientNotes/components/FilterPatientNotesDrawer';
import { EHRName } from '../../components/PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import {
  createPatientBodyObjec,
  createPatientBodyObjectForIntegration,
  getLeadObjectForIntegration,
  getQueryStringForPatientSearch,
  updatePatientObject,
} from '../../components/RightSideContainer/Contacts/Customer/CustomerView/AddOrUpdateCustomer/AddOrUpdateCustomerUtils';
import {
  AIDBOX_AUTHORIZATION_TOKEN,
  CRM_URLs,
  FHIR_BASE_URL,
  INTEGRATION_BASE_URL,
  OTHER_INTEGRATION_BASE_URL,
} from '../../constants/BaseUrlConst';
import {BASE_URL, CARE_STUDIO_NEST_URL} from '../../constants/Configs';
import {
  FHIR_RESOURCE,
  PatientRecordActions,
  SEARCH_TYPES,
} from '../../constants/FhirConstant';
import {IDeclinedPatientConditonInput, IEhrCapability, IMlov} from '../../Interfaces';
import LocalStorage from '../../utils/LocalStorage';
import { Bundle, Patient } from 'fhir/r4';
import { CodeSearchType } from '../../components/common/CodeSearch/CodeSearchConstants';
import { isWeb } from '../../utils/platformCheckUtils';
import { getPatientLocationUuidFromPracticeLocations, objectToQueryString } from '../../utils/commonUtils';
import { getCurrentTimeZone, getDateStrFromFormat } from '../../utils/DateUtils';
import { DATE_FORMATS, EXTERNAL_USER_FILTER_CODE } from '../../constants';
import { IContact, IContactPracticeLocations } from '../../components/RightSideContainer/TeamInbox/Conversations/interfaces';
import { IMapLocationonRequest } from '../../components/RightSideContainer/Contacts/TeamMembers/AddEditUser/interfaces';
import { IHintPatientDetails } from '../../components/RightSideContainer/Integration/interfaces';
import { getSearchParamsForNotes } from '../../components/PersonOmniView/MiddleContainer/CareTimeline/CareTimelineUtils';
import BaseService from './BaseService';
import {ObservationSortBy, ObservationSourceType} from '../../components/RightSideContainer/Forms/FHFormio/CustomComponents/HomeMonitoringView/interface';
import { ReportCategory } from '../../components/PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderUtils';
import { getAccountLocationIdByPracticeLocationId } from '../CloudTelephony/CloudTelephonyQueries';
import { getSidecarLocationGroupId } from '../../sidecar/common/SideCardCommonUtils';

export const CreatePatient = async (
  customerFormData: any,
  callback: any,
  error: any
) => {
  const body = createPatientBodyObjec(customerFormData);
  const headers = await getHeaders();
  axios
    .post(`${INTEGRATION_BASE_URL}/fhir/${FHIR_RESOURCE.PATIENT}`, body, {
      headers,
    })
    .then((response: any) => callback(response))
    .catch((err: any) => {
      error(err);
    });
};

export const createPatientUsingIntegration = async (args: {
  patientData: any;
  addTagObjectList: any[];
  sendInvite: boolean;
  defaultLocationUuid?: string;
  userId: string | number;
  userUuid: string;
  successHandler: (response: any) => void;
  errorHandler: (error: any) => void;
}) => {
  const {
    patientData,
    addTagObjectList,
    sendInvite,
    userId,
    successHandler,
    errorHandler,
  } = args;
  const body = createPatientBodyObjectForIntegration({
    data: patientData,
    addTagObjectList: addTagObjectList,
    sendInvite: sendInvite,
    defaultLocationUuid: args.defaultLocationUuid,
    userId: userId,
    userUuid: args.userUuid,
  });
  const headers = await getHeaders();
  axios
    .post(`${INTEGRATION_BASE_URL}/api/${FHIR_RESOURCE.PATIENT}`, body, {
      headers,
    })
    .then((response) => successHandler(response))
    .catch((err) => {
      errorHandler(err);
    });
};

export const updatePatient = async (args: {
  patientData: any;
  addTagObjectList: any[];
  defaultLocationUuid?: string;
  isSexAtBirthRequired?: boolean;
  sendInvite?: boolean;
  userId: string | number;
  pronounList:IMlov[],
  successHandler: (response: any) => void;
  errorHandler: (error: any) => void;
}) => {
  const {patientData, addTagObjectList, userId, successHandler, errorHandler,pronounList} =
    args;

  const body = updatePatientObject({
    data: patientData,
    addTagObjectList: addTagObjectList,
    defaultLocationUuid: args.defaultLocationUuid,
    isSexAtBirthRequired: args.isSexAtBirthRequired,
    sendInvite: args.sendInvite,
    userId: userId,
    pronounList: pronounList
  });
  const headers = await getHeaders();
  axios
    .put(`${INTEGRATION_BASE_URL}/api/${FHIR_RESOURCE.PATIENT}`, body, {
      headers,
    })
    .then((response) => successHandler(response))
    .catch((err) => {
      errorHandler(err);
    });
};

export const getPractitioners = async (args: {
  successHandler: (response: any) => void;
  errorHandler: (error: any) => void;
  locationId?: string | undefined;
}) => {
  const {successHandler, errorHandler, locationId} = args;
  const headers = await getHeaders();
  let path = `${INTEGRATION_BASE_URL}/fhir/${FHIR_RESOURCE.PRACTITIONER}?include=all-provider-ids`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  axios
    .get(path, {
      headers,
    })
    .then((response) => successHandler(response))
    .catch((err) => {
      errorHandler(err);
    });
};

export const GetPatients = (customerFormData: any) => {
  const searchQueryString = `?address-postalcode=${customerFormData.zipCode}&given=${customerFormData.firstName}&family=${customerFormData.lastName}&birthDate=${customerFormData.birthDate}&email=${customerFormData.email}`;
  const headers = {
    Authorization: AIDBOX_AUTHORIZATION_TOKEN,
  };
  axios
    .get(`${FHIR_BASE_URL}/Patient${searchQueryString}`, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {

    });
};

export const getVitals = async (
  patientUuid: string,
  callback: any,
  errorHandler: any,
  locationId?: string
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/Observation?patient=${patientUuid}&category=vital-signs`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  axios
    .get(path, {headers})
    .then((response) => callback(response))
    .catch((error) => errorHandler(error));
};

export const getSurgicalHistory = async (
  patientId: string,
  successHandler: (response: any) => void,
  errorHandler: (error: any) => void,
  locationId?: string
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/Procedure?subject=${patientId}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  axios
    .get(path, {headers})
    .then((response) => successHandler(response))
    .catch((error) => errorHandler(error));
};

export const getVisitHistory = async (
  patientUuid: string,
  callback: any,
  errorHandler: any,
  locationId?: string
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/Encounter?subject=${patientUuid}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  axios
    .get(path, {headers})
    .then((response) => callback(response))
    .catch((error) => errorHandler(error));
};

export const getSocialHistory = async (
  patientUuid: string,
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void,
  locationId?: string
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/Observation?subject=${patientUuid}&category=social-history`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  axios
    .get(path, {headers})
    .then((response) => responseHandler(response))
    .catch((error) => errorHandler(error));
};

export const getDiagnosticReport = async (
  patientUuid: string,
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void,
  locationId?: string
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/DiagnosticReport?type=LAB&patient=${patientUuid}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  axios
    .get(path, {headers})
    .then((response) => responseHandler(response))
    .catch((error) => errorHandler(error));
};

export const getDiagnosticReportById = async (
  reportId: string,
  patientUuid: string,
  locationId?: string,
  reportCategory?: ReportCategory,
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/DiagnosticReport/${reportId}`;
  if(reportCategory === 'IMAGING') {
    path += `?type=IMAGING&patient=${patientUuid}`
  }
  else {
    path += `?type=LAB&patient=${patientUuid}`
  }
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  return axios.get(path, {headers});
};

export const getClaims = async (
  patientUuid: string,
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void,
  locationId?: string
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/Claim?patient=${patientUuid}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  axios
    .get(path, {headers})
    .then((response) => responseHandler(response))
    .catch((error) => errorHandler(error));
};

export const getSingleClaimDetails = async (
  claimId: string,
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void,
  locationId?: string
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/Claim/${claimId}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  axios
    .get(path, {headers})
    .then((response) => responseHandler(response))
    .catch((error) => errorHandler(error));
};

export const getPatientDetails = async (
  patientUuid: string,
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void,
  locationId?: string
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/Patient/${patientUuid}`;
  if (locationId) {
    path += `?location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `?locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  axios
    .get(path, {headers})
    .then((response) => responseHandler(response))
    .catch((error) => errorHandler(error));
};

export const getDeceasedDate = async(PatientId :string, locationId: string) => {
  const data = await getPatientById(PatientId, locationId)
  const deceasedDate = data?.data?.deceasedDateTime ? getDateStrFromFormat(
    data?.data?.deceasedDateTime,
    DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
  ) : ''
 return deceasedDate
 };

 export const getCurrentPatientDeceasedData = async <
   T extends {patient?: {patientId: string}, contactPracticeLocations?: IContactPracticeLocations[]}
 >(
   contactData: T
 ): Promise<string> => {
   if (!contactData?.patient?.patientId) {
     return '';
   }
   const accountLocationUuid = getPatientLocationUuidFromPracticeLocations(contactData?.contactPracticeLocations || [])
   const deceasedDatePatient = await getDeceasedDate(
     contactData?.patient?.patientId,
     accountLocationUuid || ''
   );
   return deceasedDatePatient;
 };

export const getCustomEHRAttributes = async (
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void,
  locationId?: string
) => {
  let path = `${INTEGRATION_BASE_URL}/api/codes?searchType=CustomFields`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  axios
    .get(path, {headers})
    .then((response) => responseHandler(response))
    .catch((error) => errorHandler(error));
}

export const getDocumentsReference = async (
  encounterId: string,
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void,
  locationId?: string
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/DocumentReference?encounter=${encounterId}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  axios
    .get(path, {headers})
    .then((response) => responseHandler(response))
    .catch((error) => errorHandler(error));
};

export const getEncounters = async (
  contactUUID: string,
  patientUUID: string,
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void
) => {
  let path = `${CARE_STUDIO_NEST_URL}/fhir/encounter?contactId=${contactUUID}`;
  if (patientUUID) {
    path += `&patientId=${patientUUID}`;
  }
  const headers = await getHeaders();
  axios
    .get(path, {headers: headers})
    .then((response) => responseHandler(response))
    .catch((error) => errorHandler(error));
};

export const getEhrCapabilitiesWithLocation = async (
  params: {
    locationId?: string,
    locationGroupId?: string,
    resourceType?: string,
  },
  headerObject?: any,
) => {
  let path = `${INTEGRATION_BASE_URL}/config/capability?`;
  if (params?.resourceType) {
    path += `${params.resourceType}=true`;
  }
  if (params.locationId) {
    path += `&location=${params.locationId}`;
  }

  if (params.locationGroupId) {
    path += `&locationGroupId=${params.locationGroupId}`;
  }

  const headers = headerObject ?? await getHeaders();
  return axios.get(path, {headers});
};

export const getEhrCapabilitiesV2WithLocation = async (
  params: {
    locationId?: string,
    locationGroupId?: string,
  },
  headerObject?: any,
) => {
  let path = `${INTEGRATION_BASE_URL}/config/v2/capability?`;
  if (params.locationId) {
    path += `&location=${params.locationId}`;
  }

  if (params.locationGroupId) {
    path += `&locationGroupId=${params.locationGroupId}`;
  }

  const headers = headerObject ?? await getHeaders();
  return axios.get(path, {headers});
};

export const getEhrCapabilities = async (
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void
) => {
  const path = `${INTEGRATION_BASE_URL}/config/capability`;

  const headers = await getHeaders();
  return axios
    .get(path, {headers})
    .then((response) => responseHandler(response))
    .catch((error) => {
      errorHandler(error);

    });
};


export const updateEhrCapabilities = async (
  payLoad: IEhrCapability,
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void
) => {
  const path = `${INTEGRATION_BASE_URL}/config/capability`;

  const headers = await getHeaders();
  return axios
    .put(path, payLoad, {headers})
    .then((response) => responseHandler(response))
    .catch((error) => {
      errorHandler(error);


    });
};

export const getResourceContent = async (
  resourceName: string,
  query: string | undefined,
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void,
  locationId?: string
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/${resourceName}?${query}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  return axios
    .get(path, {
      headers: headers,
    })
    .then((response) => responseHandler(response?.data))
    .catch((error) => {
      errorHandler(error);


    });
};

export const getResourceContentWithHeaders = async (
  resourceName: string,
  query: string | undefined,
  useProxy: boolean,
  additionalHeaders: {[index: string]: any},
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void,
  locationId?: string,
  getEHRAndPatientReported?: boolean
) => {
  const context = getEHRAndPatientReported ? 'api/patient' : 'fhir';
  let path = useProxy
    ? `${BASE_URL}/carestudio-nest/proxy/integration/${context}/${resourceName}?${query}`
    : `${INTEGRATION_BASE_URL}/${context}/${resourceName}?${query}`;

  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  // const path = `http://localhost:9090/proxy/integration/fhir/${resourceName}?${query}`;
  const headers = await getHeaders();
  return axios
    .get(path, {
      headers: {
        ...headers,
        ...additionalHeaders,
      },
    })
    .then((response) => responseHandler(response?.data))
    .catch((error) => {
      errorHandler(error);


    });
};

export const deleteResource = async (
  resourceName: string,
  query: string | undefined,
  useProxy: boolean,
  additionalHeaders: {[index: string]: any},
  additionalParams: {
    patientId?: string,
    locationId?: string,
    primarySource?: boolean,
    type?: string
  },
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void,
) => {
  const { patientId, locationId, primarySource, type } = additionalParams;
  let path = useProxy
    ? `${BASE_URL}/carestudio-nest/proxy/integration/fhir/${resourceName}/${query}`
    : `${INTEGRATION_BASE_URL}/fhir/${resourceName}/${query}?${locationId ? `&location=${locationId}` : ''}${`&primary-source=${primarySource || false}`}`;
  if (type){
    path += `&type=${type}`;
  }
  if (patientId) path += `&patient=${patientId}`;

  const headers = await getHeaders();
  return axios
    .delete(path, {
      headers: {
        ...headers,
        ...additionalHeaders,
      },
    })
    .then((response) => responseHandler(response?.data))
    .catch((error) => {
      errorHandler(error);


    });
};

export const getEhrCapabilitiesWithResourcePromise = async (
  resourceName: string,
  useProxy: boolean,
  additionalHeaders?: {[index: string]: any},
  location?: string
) => {
  let path = useProxy
    ? `${BASE_URL}/carestudio-nest/proxy/integration/config/capability?resourceName=${resourceName}`
    : `${INTEGRATION_BASE_URL}/config/capability?resourceName=${resourceName}`;
  if (location) {
    path = path + `&location=${location}`;
  }
  const headers = await getHeaders();
  return axios.get(path, {
    headers: {
      ...headers,
      ...additionalHeaders,
    },
  });
};
export const getEhrCapabilitiesWithResource = async (
  resourceName: string,
  useProxy: boolean,
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void,
  additionalHeaders?: {[index: string]: any},
  location?: string
) => {
  let path = useProxy
    ? `${BASE_URL}/carestudio-nest/proxy/integration/config/capability?resourceName=${resourceName}`
    : `${INTEGRATION_BASE_URL}/config/capability?resourceName=${resourceName}`
    if (location) {
      path = path + `&location=${location}`
    }
  const headers = await getHeaders();
  return axios
    .get(path, {
      headers: {
        ...headers,
        ...additionalHeaders,
      },
    })
    .then((response) => responseHandler(response))
    .catch((error) => {
      errorHandler(error);


    });
};

export const getPatientDetailsWithHeaders = async (
  patientId: string,
  useProxy: boolean,
  additionalHeaders: {[index: string]: any},
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void,
  locationId?: string,
) => {
  let path = useProxy
    ? `${BASE_URL}/carestudio-nest/proxy/integration/fhir/Patient/${patientId}`
    : `${INTEGRATION_BASE_URL}/fhir/Patient/${patientId}`;
  // const path = `http://localhost:9090/proxy/integration/fhir/Patient/${patientId}`;
  if (locationId) {
    path += `?location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `?locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  axios
    .get(path, {
      headers: {
        ...headers,
        ...additionalHeaders,
      },
    })
    .then((response) => responseHandler(response))
    .catch((error) => errorHandler(error));
};

export const getPractitionersWithHeaders = async (args: {
  name: string;
  useProxy: boolean;
  additionalHeaders: {[index: string]: any};
  successHandler: (response: any) => void;
  errorHandler: (error: any) => void;
  locationId?: string
}) => {
  const {name, useProxy, additionalHeaders, successHandler, errorHandler, locationId} =
    args;
  const headers = await getHeaders();
  let path = useProxy
    ? `${BASE_URL}/carestudio-nest/proxy/integration/fhir/${FHIR_RESOURCE.PRACTITIONER}?name=${name}`
    : `${INTEGRATION_BASE_URL}/fhir/${FHIR_RESOURCE.PRACTITIONER}`;
  if (locationId) {
    path += useProxy ? `&` : `?`;
    path += `location=${locationId}`
  } else {
    path += useProxy ? `&` : `?`;
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `locationGroupId=${locationGroupId}` : '';
  }
  // const path = `http://localhost:9090/proxy/integration/fhir/${FHIR_RESOURCE.PRACTITIONER}?name=${name}`;
  axios
    .get(path, {
      headers: {
        ...headers,
        ...additionalHeaders,
      },
    })
    .then((response) => successHandler(response))
    .catch((err) => {
      errorHandler(err);
    });
};

export const getQuestionnaireWithHeaders = async (
  useProxy: boolean,
  additionalHeaders: {[index: string]: any},
  additionalParams: {
    locationId?: string,
    patientId?: string,
  },
  successHandler: (response: any) => void,
  errorHandler: (error: any) => void
) => {
  let path = useProxy
    ? `${BASE_URL}/carestudio-nest/proxy/integration/fhir/${FHIR_RESOURCE.QUESTIONNAIRE}`
    : `${INTEGRATION_BASE_URL}/fhir/${FHIR_RESOURCE.QUESTIONNAIRE}`;
    path = setQueryParamsToPath(path, additionalParams);
  // const path = `http://localhost:9090/proxy/integration/fhir/${FHIR_RESOURCE.QUESTIONNAIRE}`;
  const headers = await getHeaders();

  return axios
    .get(path, {
      headers: {
        ...headers,
        ...additionalHeaders,
      },
    })
    .then((response) => successHandler(response))
    .catch((err) => {
      errorHandler(err);
    });
};

const setQueryParamsToPath = (
  path: string,
  params?: {
    patientId?: string;
    locationId?: string;
  }
) => {
  const queryParams: string[] = [];
  if (params?.patientId) queryParams.push(`patient=${params.patientId}`);
  if (params?.locationId) queryParams.push(`location=${params.locationId}`);

  if (queryParams.length) {
    path += `?${queryParams.join('&')}`;
  }
  return path;
}

export const fhirAddOrUpdate = async (
  resource: string,
  resourceId?: string,
  payload?: any,
  additionalParams?: {
    patientId?: string;
    locationId?: string;
    isMedicalHistory?: boolean;
  },
  responseHandler?: (response: any) => void,
  errorHandler?: (error: any) => void,
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/${resource}`;
  let apiMethod = axios.post;
  if (resourceId) {
    path += `/${resourceId}`;
    apiMethod = axios.put;
  }
  path = setQueryParamsToPath(path, additionalParams);
  if (additionalParams?.isMedicalHistory) {
    path += '&type=medical-history'
  }

  const headers = await getHeaders();
  return apiMethod(path, payload, {headers})
    .then((response) => {
      if (responseHandler) responseHandler(response);
    })
    .catch((error) => {
      if (errorHandler) errorHandler(error);


    });
};

export const addOrUpdateResource = async (
  resource: string,
  resourceId?: string,
  payload?: any,
  additionalParams?: {
    patientId?: string;
    locationId?: string;
    isMedicalHistory?: boolean;
  },
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/${resource}`;
  let apiMethod = axios.post;
  if (resourceId) {
    path += `/${resourceId}`;
    apiMethod = axios.put;
  }
  path = setQueryParamsToPath(path, additionalParams);
  if (additionalParams?.isMedicalHistory) {
    path += '&type=medical-history'
  }

  const headers = await getHeaders();
  return apiMethod(path, payload, {headers});
};

export const getLanguages = async (locationId?: string) => {
  let path = `${INTEGRATION_BASE_URL}/api/codes?searchType=${SEARCH_TYPES.LANGUAGE}&limit=10&offset=1`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  return axios.get(path, {headers: headers});
};

export const getCodes = async (params: {
  searchType: string;
  searchString?: string;
  offset?: number;
  pageSize?: number;
}) => {
  const headers = await getHeaders();
  const path = `${INTEGRATION_BASE_URL}/api/codes`;

  const queryParams = new URLSearchParams();
  queryParams.append('searchType', params.searchType);
  if (params.searchString) {
    queryParams.append('searchString', params.searchString);
  }
  if (params.offset) {
    queryParams.append('offset', `${params.offset}`);
  }
  if (params.pageSize) {
    queryParams.append('pageSize', `${params.pageSize}`);
  }

  return axios.get(path, { headers, params: queryParams });
}

const getHeaders = async () => {
  const userDataString = await LocalStorage.getItem('user');
  const formWidgetDataString = await LocalStorage.getItem('formWidgetData');
  const userData = JSON.parse(userDataString);
  const formWidgetData = formWidgetDataString ? JSON.parse(formWidgetDataString) : '';
  const formWidgetAccessToken = formWidgetData?.formWidgetAccessToken;
  const accountUUID =
    userData?.data.accounts.length > 0
      ? userData?.data.accounts[0].uuid || ''
      : '';
  const responseAccessToken = userData?.accessToken || '';
  const fold_access_token = userData?.fold_access_token;
  const headersObj: any = {
    'access-token': responseAccessToken,
    fold_access_token,
    accountUUID,
  };
  if (!fold_access_token && formWidgetAccessToken && formWidgetAccessToken !== 'undefined') {
    headersObj['form-widget-access-token'] = formWidgetAccessToken;
  }
  return headersObj;
};

export const getQuestionnaire = async (locationId: string) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/${FHIR_RESOURCE.QUESTIONNAIRE}?location=${locationId}`;
  const locationGroupId = await getSidecarLocationGroupId();
  path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  const headers = await getHeaders();
  return axios.get(path, {headers: headers});
};

export const getQuestionnaireResponse = async (args: {
  patientId: string;
  locationId?: string;
}) => {
  const {patientId, locationId} = args;
  let path = `${INTEGRATION_BASE_URL}/fhir/${FHIR_RESOURCE.QUESTIONNAIRE_RESPONSE}?patient=${patientId}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  return axios.get(path, {headers: headers});
};

export const getFamilyMemberHistory = async (args: {
  patientId: string;
  successHandler: (response: any) => void;
  errorHandler: (error: any) => void;
  locationId?: string;
}) => {
  const {patientId, locationId, successHandler, errorHandler} = args;
  let path = `${INTEGRATION_BASE_URL}/fhir/${FHIR_RESOURCE.FAMILY_MEMBER_HISTORY}?patient=${patientId}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  axios
    .get(path, {headers: headers})
    .then((response) => {
      successHandler(response);
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export const getPatientsByNameAndBirthDate = async (args: {
  birthDate: string;
  firstName: string;
  lastName: string;
  successHandler: (response: any) => void;
  errorHandler: (error: any) => void;
  locationId?: string;
}) => {
  const {locationId, successHandler, errorHandler} = args;
  const queryString = getQueryStringForPatientSearch(args);
  let path = `${INTEGRATION_BASE_URL}/fhir/Patient` + queryString;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }

  const headers = await getHeaders();
  axios
    .get(path, {headers: headers})
    .then((response) => {
      successHandler(response);
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export const getObservations = async (ids: string, locationId?: string) => {
  let url = `${INTEGRATION_BASE_URL}/fhir/Observation?id=${ids}`;
  if (locationId) {
    url += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    url += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  return axios.get(url, {headers: headers});
};

export const getOrganizations = async (locationId?: string) => {
  let url = `${INTEGRATION_BASE_URL}/fhir/Organization`;
  if (locationId) {
    url += `?location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    url += locationGroupId ?  `?locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  return axios.get(url, {headers: headers});
};

export const getPatientDetailsFromHint = async (
  contactId: string | number,
  locationId: string | undefined
) => {
  let url = `${INTEGRATION_BASE_URL}/api/hint/patient/${contactId}`;
  if(locationId) {
    url += `?location=${locationId}`
  }
  const headers = await getHeaders();
  return axios.get<IHintPatientDetails>(url, {headers: headers});
};

export const getVisitSummary = async (body: any)=>{
  const url =`${CRM_URLs.MIDDLEWARE}/account/api/employer/section-data?VISITS_SUMMARY`;
  const headers = await getHeaders();
  return axios.post(url, body, {headers: headers});
}

export const getEncounterDataById = async (body: any) => {
  const headers = await getHeaders();
  const path = `${BASE_URL}/oth-integration/document/get/docUrl`;
  return axios.post(path, body, {headers: headers});
};

export const getPractitionersData = async (locationId?: string, accountUuid?: string, locationGroupIds?: string[]) => {
  let url = `${INTEGRATION_BASE_URL}/api/${FHIR_RESOURCE.PRACTITIONER}?fetchFromEhr=true`;
  if (locationId) {
    url += `&location${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    url += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  if(locationGroupIds?.length) {
    url += `&locationGroupId=${locationGroupIds.join(',')}`;
  }
  const headers = await getHeaders();
  headers["account-uuid"]=accountUuid
  return axios.get(url, {
    headers,
  });
};

export const getAccountDataByAccountId = async (token: string) => {
  const url = '/crm-nest/platform/switch_accounts';
  const baseService = BaseService.getSharedInstance().axios;
  const body = {
    token: token
  }
  return baseService.post(url, body);
}

// Fold access token of associated account
export const getFoldAccessTokenByAccountId = async (accountId: number) => {
  const url = '/crm-nest/platform/switch_code';
  const baseService = BaseService.getSharedInstance().axios;
  const headers = await getHeaders();
  headers["accountid"]=accountId
  return baseService.get(url,{
    headers: headers,
  });
}
export const getPractitionersDataByAccount = async (uuid: string , type?: string) => {
  let query = ``
  let url = '';
  if (type === EXTERNAL_USER_FILTER_CODE.LOCATION) {
    query = `?location=${uuid}&fetchFromEhr=true`
    url = `${INTEGRATION_BASE_URL}/api/${FHIR_RESOURCE.PRACTITIONER}${query}`
  } else if (type === EXTERNAL_USER_FILTER_CODE.ACCOUNT) {
    query = `?accountUuid=${uuid}`
    url = `${INTEGRATION_BASE_URL}/fhir/${FHIR_RESOURCE.PRACTITIONER}${query}`;
  }
  const headers = await getHeaders();
  return axios.get(url, {
    headers,
  });
};

export const getPracticeLocations = async (locationGroupId: string | undefined) => {
  const headers = await getHeaders();
  const url = `${INTEGRATION_BASE_URL}/fhir/${FHIR_RESOURCE.LOCATION}${locationGroupId ? `?locationGroupId=${locationGroupId}` : ``}`;
  return axios.get(url, {headers: headers});
};

export const createLocation = async (body: any, locationGroupId: string | undefined) => {
  const headers = await getHeaders();
  const url = `${INTEGRATION_BASE_URL}/api/${FHIR_RESOURCE.LOCATION}${locationGroupId ? `?locationGroupId=${locationGroupId}` : ``}`;
  return axios.post(url, body, {headers: headers});
};

export const getPracticeLocationDetails = async (locationGroupId?: string | undefined) => {
  const headers = await getHeaders();
  const url = `${INTEGRATION_BASE_URL}/api/ResourceMap/${FHIR_RESOURCE.LOCATION}${locationGroupId ? `?locationGroupId=${locationGroupId}` : ``}`;
  return axios.get(url, {headers: headers})
}

export const updatePracticeLocationDetails = async (body: IMapLocationonRequest, locationGroupId: string | undefined) => {
  const headers = await getHeaders();
  const url = `${INTEGRATION_BASE_URL}/api/ResourceMap/${FHIR_RESOURCE.LOCATION}${locationGroupId ? `?locationGroupId=${locationGroupId}` : ``}`;
  return axios.put(url, body, {headers: headers})
}

export const createPracticeLocationDetails = async (body: IMapLocationonRequest, locationGroupId: string | undefined) => {
  const headers = await getHeaders();
  const url = `${INTEGRATION_BASE_URL}/api/ResourceMap/${FHIR_RESOURCE.LOCATION}${locationGroupId ? `?locationGroupId=${locationGroupId}` : ``}`;
  return axios.post(url, body, {headers: headers})
}

export const getDocumentsReferenceWithPatientId = async (
  patientId: string,
  encounterId?: string,
  pagination?:{
    limit: number,
    offset: number,
  },
  locationId?: string,
) => {
  const headers = await getHeaders();
  let url = `${INTEGRATION_BASE_URL}/fhir/${FHIR_RESOURCE.DOCUMENT_REFERENCE}?patient=${patientId}`;
  if (encounterId) {
    url += `&encounterId=${encounterId}`;
  }
  if(pagination){
    url += `&limit=${pagination.limit}&offset=${pagination.offset}`;
  }
  if (locationId) {
    url += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    url += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  return axios.get(url, {headers: headers});
};
export const deleteDocumentReference = async (id: string,foldSource?: boolean, locationId?: string) => {
  const headers = await getHeaders();
  const url = `${INTEGRATION_BASE_URL}/fhir/${
    FHIR_RESOURCE.DOCUMENT_REFERENCE
  }/${id}${foldSource ? '?foldSource=true' : ''}${locationId ? `${foldSource ? '&' : '?'}location=${locationId}` : ''}`;
  return axios.delete(url, {headers: headers});
}

export const getDocumentsReferenceWithFilters = async (
  patientId: string,
  filters?: IPatientNotesSelectedFilters,
  encounterId?: string,
  pagination?:{
    limit: number,
    offset: number,
  },
  foldSource?: boolean,
  locationId?: string,
) => {
  const url = `${INTEGRATION_BASE_URL}/fhir/${FHIR_RESOURCE.DOCUMENT_REFERENCE}`;

  if(!isWeb()){
    const queryParams: any = {
      patient: patientId,
    };

    if (encounterId) {
      queryParams.encounterId = encounterId;
    }
    if (pagination) {
      queryParams.limit = pagination.limit;
      queryParams.offset = pagination.offset;
    }
    if(locationId) {
      queryParams.location = locationId;
    }
    if (filters) {
      if (filters.chiefComplaint?.displayName) {
        queryParams['chief-complaint'] = filters.chiefComplaint.displayName;
      }
      if (filters.diagnosis?.text) {
        queryParams['diagnosis'] = filters.diagnosis?.text;
      }
      if (filters.form?.id) {
        queryParams['form-id'] = filters.form?.id;
      }
      if (filters.createdByUserIds?.length) {
        filters.createdByUserIds.forEach((createdByUserId) => {
          queryParams['author'] = `Practitioner/${createdByUserId}`
        });
      }
      if (filters.signedByUserIds?.length) {
        filters.signedByUserIds.forEach((signedByUserId) => {
          queryParams['authenticator'] = `Practitioner/${signedByUserId}`
        });
      }
      if (filters.createdOnStartDate) {
        queryParams['date'] = `ge${filters.createdOnStartDate}`;
      }
      if (filters.createdOnEndDate) {
        queryParams['date'] = `le${filters.createdOnEndDate}`;
      }
      if (filters.signedOnStartDate) {
        queryParams['signed-date'] =  `ge${filters.signedOnStartDate}`;
      }
      if (filters.signedOnEndDate) {
        queryParams['signed-date'] = `le${filters.signedOnEndDate}`
      }
      if (filters._sort) {
        queryParams['_sort'] = `${filters?._sort}`;
      }
    }
    const headers = await getHeaders();
    const fullURL =
      url + (Object.keys(queryParams).length > 0 ? `?${objectToQueryString(queryParams)}` : '');
    return axios.get(fullURL, { headers });
  }

  const searchParams = getSearchParamsForNotes({patientId, foldSource, filters, encounterId, pagination})

  const headers = await getHeaders();

  return axios.get(url, { params: searchParams, headers: headers });
};

export const postDocumentsReferenceWithPatientId = async (
  patientId: string,
  data: any,
  extraData?:{
    isUpdate?: boolean,
    id: string;
    currentEHR?:EHRName,
    locationId?: string,
  },
  foldSource?: boolean
) => {
  const headers = await getHeaders();
  const id = extraData?.id;
  const isElation = extraData?.currentEHR === EHRName.ELATION;
  const isUpdate = extraData?.isUpdate;
  let url = `${INTEGRATION_BASE_URL}/fhir/${
    FHIR_RESOURCE.DOCUMENT_REFERENCE
  }${id && isUpdate ? '/' + id : ''}`;

  const queryParams = new URLSearchParams();
  if (foldSource) {
    queryParams.append('foldSource', foldSource.toString());
  }
  if (extraData?.locationId) {
    queryParams.append('location', extraData?.locationId);
  }
  const queryString = queryParams.toString();
  if (queryString) {
    url += `?${queryString}`;
  }
  return isUpdate ? axios.put(url, data, {headers: headers}) : axios.post(url, data, {headers: headers});
};


export const postDocumentsReferenceWithHandler = async (
  patientId: string,
  data: any,
  extraData?:{
    isUpdate?: boolean,
    id: string;
    currentEHR?:EHRName,
  }
) => {
  const headers = await getHeaders();
  const id = extraData?.id;
  const isElation = extraData?.currentEHR === EHRName.ELATION;
  const isUpdate = extraData?.isUpdate;
  const url = `${INTEGRATION_BASE_URL}/fhir/${
    FHIR_RESOURCE.DOCUMENT_REFERENCE
  }${id && isUpdate ? '/' + id : ''}`;

  if (isUpdate) {
    await axios
    .put(url, data, {headers: headers})
    .catch((error) => {

    });
  } else {
  await axios
    .post(url, data, {headers: headers})
    .catch((error) => {

    });
  }
  return {success: true};
};

export const getEncountersFromIntegration = async (patientId: string, locationId: string, foldSource?: boolean, filters?: IPatientNotesSelectedFilters) => {
  const headers = await getHeaders();
  const url = `${INTEGRATION_BASE_URL}/fhir/${FHIR_RESOURCE.ENCOUNTER}?location=${locationId}`;

  const searchParams = getSearchParamsForNotes({patientId, foldSource, filters, appendSourceRegardlessOfFilters: true})
  return axios.get(url, { params: searchParams, headers: headers });
};

export const reconcilePatient = async (
  payload: any,
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void
) => {
  const path = `${INTEGRATION_BASE_URL}/api/reconcile/Patient`;
  const headers = await getHeaders();
  return axios
    .post(path, payload, {headers})
    .then((response) => responseHandler(response?.data))
    .catch((error) => errorHandler(error));
};

export const getPatientReconcile = async (
  responseHandler: (response: any) => void,
  errorHandler: (error: any) => void
) => {
  const path = `${INTEGRATION_BASE_URL}/api/reconcile/Patient`;
  const headers = await getHeaders();
  axios
    .get(path, {headers})
    .then((response) => {
      responseHandler(response);
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export const getHintPracticeDetails = async () => {
  const headers = await getHeaders();
  const url = `${INTEGRATION_BASE_URL}/api/hint/practice`;
  return axios.get(url, {headers: headers});
};

export const connectToHint = async (payload: {hintSecretToken: string}) => {
  const url = `${INTEGRATION_BASE_URL}/api/hint/integration`;
  const headers = await getHeaders();
  return axios.post(url, payload, {headers: headers});
};

export const getActivities = async (
  types: string[],
  startDate: string,
  endDate: string,
  patientUuid: string[],
  callback: any,
  errorHandler: any,
  isDaily = false,
  accountLocationUuid?: string,
) => {
  const path = `${
    isDaily ? 'dailyData' : 'activityData'
  }?patientId=${JSON.stringify(
    patientUuid
  )}&startDate=${startDate}&endDate=${endDate}&dataType=${JSON.stringify(
    types
  )}${accountLocationUuid ? `&location=${accountLocationUuid}` : ''}&timezone=${getCurrentTimeZone()}`;
  const url = `${INTEGRATION_BASE_URL}/api/` + path;
  const headers = await getHeaders();
  axios
    .get(url, {headers})
    .then((response) => callback(response))
    .catch((error) => errorHandler(error));
};

export const getActivityPromise = async (params:{
  types: string[],
  startDate?: string,
  endDate?: string,
  patientUuid: string[],
  isDaily:boolean,
  accountLocationUuid?: string,
  limit?: number,
  orderBy?: string
}) => {

  let path = ``;

  path += params.isDaily ? 'dailyData' : 'activityData';
  path += `?patientId=${JSON.stringify(params.patientUuid)}`;
  path += params.startDate ? `&startDate=${params.startDate}` : ``;
  path += params.endDate ? `&endDate=${params.endDate}` : ``;
  path += `&dataType=${JSON.stringify(params.types)}`;
  path += params.accountLocationUuid
    ? `&location=${params.accountLocationUuid}`
    : '';
  path += `&timezone=${getCurrentTimeZone()}`;

  if(params.limit) {
    path += `&limit=${params.limit}`;
  }

  if(params.orderBy) {
    path += `&orderBy=${params.orderBy}`;
  }

  const url = `${INTEGRATION_BASE_URL}/api/` + path;
  const headers = await getHeaders();
  return axios.get(url, {headers});
}

export const getHintPlansByEmployerId = async (employerId: string, locationId?: string) => {
  // http://localhost:9898/integration/api/hint/plan?employerId=658abc9c-d92b-4fc2-b5e0-6f2f51b8399b
  let url = `${INTEGRATION_BASE_URL}/api/hint/plan?employerId=${employerId}`;
  if (locationId) {
    url += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    url += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  return axios.get(url, {headers});
};

export const acceptIgnorePatientReportedRecords = async (
  resource: string,
  action: PatientRecordActions,
  resourceId?: string,
  payload?: any,
  additionalParams?: {
    patientId?: string;
    locationId?: string;
    isMedicalHistory?: boolean

  },
  responseHandler?: (response: any) => void,
  errorHandler?: (error: any) => void,
) => {
  const actionPath = action === PatientRecordActions.accept ? 'sign' : 'deny';
  let path = `${INTEGRATION_BASE_URL}/api/patient/${resource}`;
  let apiMethod = axios.post;
  if (resourceId) {
    path += `/${resourceId}`;
    apiMethod = axios.put;
  }
  path += `/${actionPath}`;
  path = setQueryParamsToPath(path, additionalParams);
  if (additionalParams?.isMedicalHistory) {
    path += '&type=medical-history'
  }
  const headers = await getHeaders();
  return apiMethod(path, payload, {headers})
    .then((response) => {
      if (responseHandler) responseHandler(response);
    })
    .catch((error) => {
      if (errorHandler) errorHandler(error);


    });
};

export const getVitalsWithFilters = async (
  patientId: string,
  code: string,
  limit?: number,
  offset?: number,
  locationId?: string,
  startDate?: string,
  endDate?: string,
  source?: ObservationSourceType,
  sort?: ObservationSortBy,
  category?: 'vital-signs' | 'activity' | 'laboratory',
  isISOTime?: boolean,
  fetchFromGoogleHealthCare?: boolean
) => {
  const dateTimeQueryKey = isISOTime ? 'dateTime' : 'date';

  let path = `${INTEGRATION_BASE_URL}/fhir/Observation?subject=${patientId}&category=${category || 'vital-signs'}&code=${code}`;

  if (fetchFromGoogleHealthCare) {
    path = `${INTEGRATION_BASE_URL}/api/google-healthcare/fhir/Observation?patient=${patientId}&category=${
      category || 'vital-signs'
    }&code=${code}`;
  }

  if (limit !== undefined && offset !== undefined) {
    path += `&offset=${offset}&limit=${limit}`;
  }

  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }

  if (startDate && endDate) {
    path += `&${dateTimeQueryKey}=le${endDate}&${dateTimeQueryKey}=ge${startDate}`;
  }

  if (source) {
    path += `&source=${source}`;
  }

  if (sort) {
    path += `&_sort=${sort}`;
  }

  const headers = await getHeaders();
  return axios.get(path, {headers});
};

export const getLatestRecordForObservation = async (
  accountId: string | number,
  patientId: string,
  code: string,
  componentCode?: string,
  limit?: number,
  source?: ObservationSourceType,
  sort?: ObservationSortBy,
  category?: 'vital-signs' | 'activity',
) => {
  let path = `${INTEGRATION_BASE_URL}/api/google-healthcare/fhir/Observation?patient=${accountId}-${patientId}&category=${
    category || 'vital-signs'
  }&code=${code}`;
  if (limit !== undefined) {
    path += `&_count=${limit}`;
  }

  if (componentCode) {
    path += `&component-code=${componentCode}`;
  }

  if (source) {
    path += `&source=${source}`;
  }

  if (sort) {
    path += `&_sort=${sort}`;
  }

  const headers = await getHeaders();
  return axios.get(path, {headers});
};

export const getAllLocations = async () => {
  const path = `${INTEGRATION_BASE_URL}/fhir/Location?provider-list=true`;
  const headers = await getHeaders();
  return axios.get(path, {headers});
};

export const getAppointmentCancelReason = async (
  locationId: string | undefined
) => {
  let path = `${INTEGRATION_BASE_URL}/api/codes?searchType=${SEARCH_TYPES.APPOINTMENT_CANCEL_REASON}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  return axios.get(path, {headers: headers});
};

export const updateSectionDetails = async (resource: string, body: Bundle, patientId: string, locationId?: string) => {
  const headers = await getHeaders();
  let path = `${INTEGRATION_BASE_URL}/fhir/${resource}/-1?patient=${patientId}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  return axios.put(path, body, { headers });
}

export const updateEmergencyContact = async (body: {[index: string]: any}, locationId?: string) => {
  const headers = await getHeaders();
  let path = `${INTEGRATION_BASE_URL}/api/Patient/emergency-contact`;
  if (locationId) {
    path += `?location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `?locationGroupId=${locationGroupId}` : '';
  }
  return axios.put(path, body, { headers });
}

export const getPatientById = async (
  patientId: string,
  locationId: string
) => {
  let path = `${INTEGRATION_BASE_URL}/fhir/Patient/${patientId}`;
  if (locationId) {
    path += `?location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `?locationGroupId=${locationGroupId}` : '';
  }
  const headers = await getHeaders();
  return axios.get(path, {headers});
};

export const getPatientDiagnosisGap = async (
  patientId: string,
  locationId: string
) => {
  const headers = await getHeaders();
  let path = `${INTEGRATION_BASE_URL}/api/patient-diagnosis-gap?patientId=${patientId}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  return axios.get(path, {headers: headers});
};

export const declinedPatientConditon = async (
  payload: IDeclinedPatientConditonInput,
  patientId: string,
  locationId: string
) => {
  const headers = await getHeaders();
  let url = `${INTEGRATION_BASE_URL}/api/patient/declined-condition?patientId=${patientId}`;
  if (locationId) {
    url += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    url += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  return axios.post(url, payload, {headers: headers});
};

export const getCodesWithHeaders = async (
  searchType: CodeSearchType,
  useProxy: boolean,
  additionalHeaders: {[index: string]: any},
  searchString?: string,
  locationId?: string | string[],
  additionalParams?: {
    limit?: number,
    offset?: number,
  }
) => {
  let path = useProxy
    ? `${BASE_URL}/carestudio-nest/proxy/integration/api/codes?searchType=${searchType}`
    : `${INTEGRATION_BASE_URL}/api/codes?searchType=${searchType}`;
  if (searchString) {
    path += `&searchString=${searchString}`;
  }
  if (Array.isArray(locationId)) {
    path += `&location=${locationId.join(',')}`;
  } else if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  if (additionalParams?.limit !== undefined) {
    path += `&limit=${additionalParams.limit}`;
  }
  if (additionalParams?.offset !== undefined) {
    path += `&offset=${additionalParams.offset}`;
  }
  const headers = await getHeaders();
  return axios
    .get(path, {
      headers: {
        ...headers,
        ...additionalHeaders,
      },
    });
};

export const addOrUpdateLeadInIntegration = async (args: {
  data: any;
  addTagObjectList: any[];
  defaultLocationUuid?: string;
  userId: string | number;
  userUuid: string;
}) => {
  const {data, addTagObjectList, userId, userUuid, defaultLocationUuid} =
    args;
  const body = getLeadObjectForIntegration({
    data: data,
    addTagObjectList: addTagObjectList,
    defaultLocationUuid: defaultLocationUuid,
    userId: userId,
    userUuid: userUuid,
  });
  const headers = await getHeaders();
  return axios.post<Patient>(`${INTEGRATION_BASE_URL}/api/${FHIR_RESOURCE.LEAD}`, body, {
    headers,
  });
};

export const getEncounterDetailById = async (
  patientId: string,
  encounterId: string,
  locationId?: string,
) => {
  const headers = await getHeaders();
  let path = `${INTEGRATION_BASE_URL}/fhir/DocumentReference?encounter=${encounterId}&patient=${patientId}`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  return axios.get(path, {headers: headers});
};

export const getPdfFromHTML = async (
  htmlString?: string,
  fileUrl?: string,
  styleTag?: string,
  margin?: any
) => {
  const headers = await getHeaders();
  const url = `${CARE_STUDIO_NEST_URL}/documentConvert/convertHtmlToPdf`;
  const payload = {
    params: {
      htmlString: htmlString,
      url: fileUrl,
      styleTag: styleTag,
      margin: margin,
    },
  };

  return axios.post(url, payload, {headers: headers, responseType: 'blob'});
};

export const postInsuranceCardImage = async (body: any) => {
  const headers = await getHeaders();
  const response = `${FHIR_BASE_URL}/Media`;
  return axios.post(response, body, {headers: headers});
};

export const getInsuranceCardImage = async (patientId:string) => {
  const headers = await getHeaders();
  const path = `${FHIR_BASE_URL}/Media?patient=${patientId}&category=insurance-card`;
  return axios.get(path, {headers: headers})
}

export const getInsuranceCardImageElation = async (patientId: string | undefined) => {
  const headers = await getHeaders();
  const path = `${FHIR_BASE_URL}/Media?patient=${patientId}`;
  return axios.get(path, { headers: headers })
}

export const postInsuranceCardImageElation = async (body: any) => {
  const headers = await getHeaders();
  const response = `${FHIR_BASE_URL}/Media`;
  return axios.post(response, body, { headers: headers });
};

export const getInsuranceFieldData = async (patientId:string, patientLocationUuid: string) => {
  const headers = await getHeaders();
  const path = `${FHIR_BASE_URL}/Coverage?patient=${patientId}&location=${patientLocationUuid}`;
  return axios.get(path, {headers: headers})
}

export const postInsuranceFieldData = async (body:any) => {
  const headers = await getHeaders();
  const response = `${FHIR_BASE_URL}/Coverage`;
  return axios.post(response, body, {headers: headers})
}

export const putInsuranceFielddata = async (body:any,insuranceId:string) => {
  const headers = await getHeaders();
  return axios.put(`${FHIR_BASE_URL}/Coverage/${insuranceId}`, body, {headers: headers})
}

export const getCopayAmountTypeData = async (
  locationId: string | undefined
) => {
  const headers = await getHeaders();
  let path = `${INTEGRATION_BASE_URL}/api/codes?limit=25&offset=0&searchType=InsuranceCopayType`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  return axios.get(path, {headers: headers});
};

export const getPatientRelationTypeData = async (
  locationId: string | undefined
) => {
  const headers = await getHeaders();
  let path = `${INTEGRATION_BASE_URL}/api/codes?limit=25&offset=0&searchType=CoverageRelationship`;
  if (locationId) {
    path += `&location=${locationId}`;
  } else {
    const locationGroupId = await getSidecarLocationGroupId();
    path += locationGroupId ?  `&locationGroupId=${locationGroupId}` : '';
  }
  return axios.get(path, {headers: headers});
};

export const getSearchInsuranceCarrieOrPlanName = async (searchText: string, memberID: string) => {
  const headers = await getHeaders();
  const path = memberID ? `${FHIR_BASE_URL}/InsurancePlan?insurance-plan-name=${searchText}&member-id=${memberID}` : `${FHIR_BASE_URL}/InsurancePlan?insurance-plan-name=${searchText}`;
  return axios.get(path, {headers: headers})
}

export const getSearchInsuranceCarrieNameElation = async (searchText: string) => {
  const headers = await getHeaders();
  const path = `${FHIR_BASE_URL}/Organization?name=${searchText}&type=ins`;
  return axios.get(path, {headers: headers})
}

export const getSearchPlanNameElation = async (searchText: string, id:string) => {
  const headers = await getHeaders();
  const path = `${FHIR_BASE_URL}/InsurancePlan?owned-by=${id}&name=${searchText}`;
  return axios.get(path, {headers: headers})
}

export const getPatientChronicConditions = async (
  patientAndLocationMap: { patientId: string, location?: string}[],
  isChronic: boolean,
) => {
  const headers = await getHeaders();
  const path = `${INTEGRATION_BASE_URL}/api/fetch/Condition`;
  const body = {
    patients: patientAndLocationMap,
    isChronic: isChronic,
  }
  return axios.post(path, body, {headers});
};


export const getPatientCoverage = async (patientId: string, patientLocationUuid: string) => {
  const headers = await getHeaders();
  const path = `${INTEGRATION_BASE_URL}/fhir/Coverage?patient=${patientId}&location=${patientLocationUuid}`;
  return axios.get(path, {headers: headers});
};

export const getPatientClaim = async (patientId: string, patientLocationUuid: string) => {
  const headers = await getHeaders();
  const path = `${INTEGRATION_BASE_URL}/fhir/Claim?patient=${patientId}&location=${patientLocationUuid}`;
  return axios.get(path, {headers: headers});
};

export const getPractitionerIdentifierDetails = async (
  useProxy?: boolean,
  additionalHeaders?: {[index: string]: any},
) => {
  const path = useProxy ? `${BASE_URL}/carestudio-nest/proxy/integration/api/practitioner/identifier?type=ProviderLicense` : `${INTEGRATION_BASE_URL}/api/practitioner/identifier?type=ProviderLicense`;
  const headers = await getHeaders();
  return axios.get(path, { headers: {
    ...headers,
    ...additionalHeaders,
  } });
}

export const deleteInsurance = async (insuranceId: string, patientId: string ) => {
  const headers = await getHeaders();
  const path = `${INTEGRATION_BASE_URL}/fhir/Coverage/${insuranceId}?patient=${patientId}`;
  return axios.delete(path, {headers: headers});
}

export const getAppointmentTypesForRuleEngine = async (
  locationIds?: string[]
) => {
  let path = `${BASE_URL}/integration/api/codes?searchType=AppointmentType`;
  if(locationIds?.length) {
    path += `&location=${locationIds.join(',')}`;
  }
  const headers = await getHeaders();
  return axios
    .get(path, {
      headers: headers,
    });
};
export const postImagingReport = async (body:any) => {
  const headers = await getHeaders();
  const response = `${INTEGRATION_BASE_URL}/api/DiagnosticReport`;
  return axios.post(response, body, {headers: headers})
}


export const getEHRs = async () => {
  const path = `${INTEGRATION_BASE_URL}/api/ehr`;
  const headers = await getHeaders();
  return axios.get(path, {headers: headers});
}


export const getLocationsByEHRs = async (params: {ehrCode: string}) => {
  const path = `${INTEGRATION_BASE_URL}/api/FoldLocations?ehrCode=${params.ehrCode}`;
  const headers = await getHeaders();
  return axios.get(path, {headers: headers});
};
export const getHieDataReportByUrl = async (url: string) => {
  const headers = await getHeaders();
  const requestUrl = `${OTHER_INTEGRATION_BASE_URL}/${url}`;
  return axios.get(requestUrl, {headers: headers})
}

export const getClinicalProviderByFilter = async (params: { providerReferralId?: string, zipCode?: string, name?: string }) => {
  const { providerReferralId, zipCode, name } = params;
  const path = `${INTEGRATION_BASE_URL}/fhir/Practitioner${providerReferralId ? `/${providerReferralId}?category=clinical-provider` : `?category=clinical-provider&name=${name}&zip=${zipCode}`}`;
  const headers = await getHeaders();
  return axios.get(path, {headers: headers});
}

export const getPatientCondition = async (
  patientId: string,
  locationId: string
) => {
  const headers = await getHeaders();
  const url = `${INTEGRATION_BASE_URL}/api/google-healthcare/fhir/Condition?patient=${patientId}&category=encounter-diagnosis&location=${locationId}`;
  return axios.get(url, {headers: headers});
}
