import { HStack, Text } from "native-base";
import React from "react";
import AntIcon from 'react-native-vector-icons/AntDesign';

const NoDataField = ()=> {

  return(
    <>
    <HStack justifyContent={'center'}>
    <AntIcon name="minuscircleo" color="gray" size={20} />
    </HStack>
    <HStack justifyContent={'center'} mt={2}>
      <Text color={'gray'} >There is no fields</Text>
    </HStack>
    </>
  )
}

export default NoDataField;
