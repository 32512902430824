import { Divider, HStack, Spacer, View, VStack, Text } from "native-base";
import { Pressable } from "react-native";
import { GROUP_MEMBER_TYPE } from "../../../constants";
import { DisplayCardAvatar } from "../DisplayCard/DisplayCardAvatar";
import ConferenceAdd from "../Svg/TelephonySvg/ConferenceAdd";
import ConferenceUserAdd from "../Svg/TelephonySvg/ConferenceUserAdd";


const UserInfoListItem = (props: any) => {

    return (
      <View>
        <HStack
          key={props.user?.id}
          flex={1}
          space={2}
        >
          <View width={10}>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '10',
                  width: 40,
                  height: 40,
                  textStyle: {
                    fontSize: 14
                  }
                }}
                userData={{
                  userId: props.user?.uuid || '',
                  userType: GROUP_MEMBER_TYPE.USER,
                  // roles: userRoles,
                  userName: props.user?.name,
                  // imgSrc: profileUrl,
                }}
                isLetterAvatarShow={true}
              />
          </View>
          <View alignItems="center" justifyContent={'center'}>
            <Text>
              {props.user?.name}
            </Text>
          </View>
          <Spacer/>
          <View width={10} alignItems="center" justifyContent={'center'}>
            <Pressable
              onPress={() => {
              }}
            >
              <ConferenceUserAdd/>
            </Pressable>
          </View>
        </HStack>
        <HStack marginTop={2}>
          <Divider/>
        </HStack>
      </View>
    );
};
export default UserInfoListItem;
