import React, {useContext, useState} from 'react';
import {View} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../styles/Colors';
import {IconButton, useToast} from 'native-base';
import DetailedBill from './DetailedBill';
import {
  IBillingData,
  IHandleTableActions,
  IHookState,
} from '../BillingHooks/BillingHookInterfaces';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import { IBatchedAccountUsersInterface } from '../../../CustomHooks/useGetBatchedAccountUsers';
import { BILLING_FILTER_ACTION_CODES } from '../BillingHooks/BillingHooksConstants';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';

interface IBillingActions {
  handleTableActions: (params: IHandleTableActions) => void;
  record: IBillingData;
  hookState: IHookState,
  accountUserList: IBatchedAccountUsersInterface[];
  attributeAndCodesLoading: boolean;
  handleActions: any;
  isBilledView?: boolean;
}

const BillingActions = (props: IBillingActions) => {
  const {handleTableActions, record, hookState, accountUserList, attributeAndCodesLoading, isBilledView} = props;
  const [openDetailedBill, setOpenDetailedBill] = useState(false);
  const toast = useToast();
  const intl = useIntl();

  return (
    <View style={{flex: 1, flexDirection: 'row'}}>
      <IconButton
        icon={<Icon name="eye" size={17} color={Colors.Custom.Gray600} />}
        onPress={() => {
          handleTableActions({
            actionCode: COMMON_ACTION_CODES.DETAIL_VIEW,
            actionData: record,
          });
          setOpenDetailedBill(true);
        }}
      />
      {openDetailedBill && (
        <DetailedBill
          billingData={record}
          onClose={() => {
            handleTableActions({
              actionCode: COMMON_ACTION_CODES.CLOSE,
              actionData: undefined,
            });
            setOpenDetailedBill(false);
          }}
          onSendClaim={() => {
            setOpenDetailedBill(false);
            showToast(
              toast,
              intl.formatMessage({id: 'claimSentSuccessfully'}),
              ToastType.success,
              4000
            );
            props?.handleActions(
              BILLING_FILTER_ACTION_CODES.REFRESH,
              undefined
            );
          }}
          handleTableActions={handleTableActions}
          hookState={hookState}
          accountUserList={accountUserList}
          attributeAndCodesLoading={attributeAndCodesLoading}
          handleActions={props.handleActions}
          isBilledView={isBilledView}
        />
      )}
    </View>
  );
};

export default BillingActions;
