import {Tooltip} from 'antd';
import {HStack, Text, VStack} from 'native-base';
import {useIntl} from 'react-intl';
import {Dimensions, View, StyleSheet} from 'react-native';
import {GROUP_MEMBER_TYPE} from '../../../constants';
import {Colors} from '../../../styles/Colors';
import {IInternalUsers} from '../../common/CustomUserSearch/interface';
import {DisplayCardAvatar} from '../../common/DisplayCard/DisplayCardAvatar';
import {
  bindUuidsWithAccountUsers,
  collectAllUserUuidsFromOnCallScheduleDuration,
  getFormattedOnCallScheduleDateTimeString,
} from './commonUtils';
import {IOnCallScheduleDuration} from './interface';

const ViewerScheduleBox = (props: {
  index: number;
  scheduleDuration: IOnCallScheduleDuration;
  accountUsers: IInternalUsers[];
  listLength: number;
}) => {
  const intl = useIntl();
  const {width} = Dimensions.get('window');
  const drawerWidth = width / 3;

  const isCardView = props.listLength > 1;
  const cardViewStyles = isCardView ? styles.cardView : {};

  const fromDate = props?.scheduleDuration?.fromDate
    ? new Date(props?.scheduleDuration?.fromDate)
    : null;
  const toDate = props?.scheduleDuration?.toDate
    ? new Date(props?.scheduleDuration?.toDate)
    : null;
  const dateString = getFormattedOnCallScheduleDateTimeString(
    fromDate,
    toDate,
    JSON.parse(props?.scheduleDuration?.daysOfWeek || '[]') || []
  );

  const uuids = collectAllUserUuidsFromOnCallScheduleDuration(
    props?.scheduleDuration
  );
  const scheduledAccountUsers = bindUuidsWithAccountUsers(
    uuids,
    props?.accountUsers
  );

  return (
    <View style={[cardViewStyles, styles.container]}>
      <View>
        <Text style={styles.dateLabel}>
          {intl.formatMessage({
            id: 'date',
          })}
        </Text>
        <Text style={styles.dateText}>
          {dateString}
        </Text>
      </View>

      <View style={styles.usersSection}>
        <Text style={styles.usersAssignedLabel}>
          {intl.formatMessage({
            id: 'usersAssigned',
          })}
        </Text>
        {scheduledAccountUsers.map((user) => {
          const userRoles: string =
            user?.user?.userRoles
              ?.map((role) => {
                return role?.userRole?.userRole?.value;
              })
              .join(', ') || '';
          return (
            <HStack
              style={styles.userRow}
            >
              <View
                style={styles.avatarContainer}
              >
                <DisplayCardAvatar
                  avatarStyle={{
                    avatarSize: '8',
                    width: 32,
                    height: 32,
                    borderWidth: 0.5,
                    textStyle: {
                      fontSize: 12,
                    },
                  }}
                  hideOnlineIndicator={true}
                  userData={{
                    userId: user?.user?.id || '',
                    userType: GROUP_MEMBER_TYPE.USER,
                    userName: user?.user.name,
                    // roles: user?.user?.userRoles?.map((role) => {
                    //   return role.userRole.userRole.value;
                    // }),
                  }}
                  isLetterAvatarShow
                />
              </View>
              <VStack
                style={styles.userInfoContainer}
              >
                <Text style={styles.userName}>
                  {user?.user?.name || ''}
                </Text>
                <Tooltip title={userRoles} placement="left">
                  <Text
                    style={[styles.userRoles, { maxWidth: drawerWidth - 140 || 150 }]}
                    numberOfLines={1}
                  >
                    {userRoles}
                  </Text>
                </Tooltip>
              </VStack>
            </HStack>
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
  },
  cardView: {
    borderWidth: 0.5,
    borderColor: Colors.FoldPixel.GRAY150,
    borderRadius: 8,
    padding: 8,
  },
  dateLabel: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY300,
    marginBottom: 4,
  },
  dateText: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY400,
  },
  usersSection: {
    marginTop: 16,
  },
  usersAssignedLabel: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY300,
  },
  userRow: {
    marginTop: '4px',
    alignItems: 'center',
  },
  avatarContainer: {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingRight: '8px',
    paddingLeft: 0,
  },
  userInfoContainer: {
    paddingRight: '7.5px',
    paddingTop: '7.5px',
    paddingBottom: '7.5px',
    paddingLeft: 0,
  },
  userName: {
    color: Colors.FoldPixel.GRAY400,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 16.8,
  },
  userRoles: {
    color: Colors.FoldPixel.GRAY200,
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 14.4,
  },
});

export default ViewerScheduleBox;
