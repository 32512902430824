export const OrderTimeLineTabCode = {
  ALL: 'ALL',
  ERX: 'ERX',
  LAB: 'LAB',
  IMAGING: 'IMAGING',
};

export const RequestGroupStatus = {
  completed: ['completed'],
  inProgress: ['draft', 'active', 'on-hold', 'unknown'],
  failed: ['revoked', 'entered-in-error'],
};
export const MedicationRequestStatus = {
  completed: ['completed'],
  inProgress: ['draft', 'active', 'on-hold', 'unknown'],
  failed: ['cancelled', 'entered-in-error', 'stopped'],
};
