import { ViewStyle } from 'react-native';
import {IUserPracticeLocation} from '../../../services/Location/interfaces';
import { ILeadListViewProps } from '../../RightSideContainer/Contacts/Leads/interfaces';
import {ITagsProps} from '../../RightSideContainer/Contacts/Tags/interfaces';
import {IInboxFilter} from '../FilterView/interfaces';
import { IcdConditionsList } from '../../RafScore/interface';

export interface IContactType {
  id: number;
  contactType: string;
  contactTypeUuid: string;
  label: string;
}
export interface IAllMembersView {
  selectedActionCode?: string;
  selectedRawData?: any;
  selectedExtraData?: any;
  contactType?: string;
  onActionPerformed?: (tabCode: any, rawData?: any, extraData?: any) => void;
  updateContactCount?: (count: number) => void;
  tab?: string;
  contactTypeId?: string;
  hideConvertToPatient?: boolean;
}

export interface IContactScoreAudit {
  scoreDate: string;
  scoreValue: number;
  scoreType: string;
}

export interface IHccScoreData {
  hccScore: number;
  hccScoreDiff: number;
  hccScoreValue: string | number;
  formattedHccScoreDiff: string;
}

export interface IScoreType {
  HCC_V_24: string,
  HCC_V_28: string,
  FOLD_SCORE: string,
}


export interface IBulkContactData {
  name: string;
  email: string;
  id: number;
  contactConsents: any | [];
}

export interface IPatientCategoryCountParams {
  isProgramBasedDashboard: boolean;
  params: IPatientCategoryWiseCountParams;
}

export interface IPatientDashboardFilter {
  searchText?: string;
  tagItem?: ITagsProps[];
  inboxItem?: IInboxFilter;
  personType?: string;
  personTypeUuid?: string;
  selectedUserPracticeLocation?: IUserPracticeLocation | any;
  addOrUpdateEvent?: string;
  clearAllEvent?: boolean;
  status?: string;
  formData?: any;
  currentPage?: number;
  contactUpdated?: boolean;
  tab?: string;
  loggedInStatus?: string;
  careManagerId?: string;
  categoryCode?: string;
  isCategoryChanged?: boolean;
  careProgramStepTypeIds?: string[];
  careProgramTypeIds?: string[];
  careProgramStepStatusIds?: string[];
}

export interface IGetContactIdWiseResponseParams {
  contactIds?: string[];
  careProgramStepTypeIds?: (string | undefined)[];
  careProgramStepStatusIds?: string[];
  careProgramTypeIds?: string[];
  ruleCodes?: string[];
  customFilterCode?: string;
  showContactsWithPendingAlerts?: boolean;
  carePlanStatusIds?: string[];
  isCarePlanNotAssigned?: boolean;
}

export interface IPatientCategoryWiseCountParams {
  categoryCodes?: string[];
  tabCode: string;
  filter: IPatientDashboardFilter;
}
export interface IPatientCategoryCount {
  categoryWiseCount?: {
    categoryCode: string;
    count: number;
  }[];
  tabCode: string;
  count?: number;
}

export interface IPagination {
  pageSize: number;
  pageNumber: number;
}

export interface IPaginationData extends IPagination {
  total: number;
}

export interface IPatientManagerParams {
  isProgramBasedDashboard: boolean;
  params: IPatientCategoryWiseCountParams[];
  pagination: IPagination;
}

export interface IPatientDashboardManager {
  categoryCode?: string;
  tabCode: string;
  filter: IPatientDashboardFilter;
  pagination: IPagination;
  config: {
    isMembershipEnabled: boolean;
  };
  careProgramDashboardColumns: string[];
  orderBy?: {
    foldScore?: string,
    hccV24?: string,
    hccV28?: string,
  }
  name?: string;
  icdConditionsList?: IcdConditionsList[] | undefined;
}

export interface IGetContactFilterParams {
  filter: IPatientDashboardFilter;
  tabCode: string;
  accountId: number | '';
  commonContextData: any;
  patientList?: string[];
  careProgramStepTypeIds?: (string | undefined)[];
  careProgramTypeIds?: (string | undefined)[];
  careProgramStepStatusIds?: (string | undefined)[];
  ruleCodes?: string[];
  showContactsWithPendingAlerts?: boolean;
  customFilterCode?: string;
  carePlanStatusIds?: string[];
  isCarePlanNotAssigned?: boolean;
}

export interface  IContactFilterParams {
  contactIds?: string[];
  param: IPatientCategoryWiseCountParams;
  careProgramStepTypeIds?: (string | undefined)[];
  careProgramTypeIds?: (string | undefined)[];
  careProgramStepStatusIds?: string[];
  ruleCodes?: string[];
  showContactsWithPendingAlerts?: boolean;
  customFilterCode?: string;
  carePlanStatusIds?: string[];
  isCarePlanNotAssigned?: boolean;
}
export interface IDashboardProgramFilterEle{
  categoryCode: string;
  sequence: number; // later we can use show order wise filter
  displayName: string;
  count?: number;
  showLeftDivider?: boolean;
  isLoading?: boolean;
}
export interface IDashboardProgramFilter extends  IDashboardProgramFilterEle{
  subFilters?: IDashboardProgramFilterEle[]
}

export interface  IContactsFilterParams {
  contactIds?: string[];
  careProgramStepTypeIds?: (string | undefined)[];
  careProgramTypeIds?: (string | undefined)[];
  careProgramStepStatusIds?: string[];
  ruleCodes?: string[];
  showContactsWithPendingAlerts?: boolean;
  customFilterCode?: string;
  carePlanStatusIds?: string[];
  isCarePlanNotAssigned?: boolean;
}

export interface IContactListViewProp extends ILeadListViewProps {
  filterCodes: IDashboardProgramFilter[];
  onChangeCategoryCode: (categoryCode: string) => void;
  customerDataList: any[];
  selectedCategoryCode: string;
  onPageChange: (pageNumber: number, pageSize: number) => void;
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  tableLoader: boolean;
  tabCode: string;
  handleFoldScoreSort: (order?: string) => void;
  handleHccV24Sort: (order?: string) => void;
  handleHccV28Sort: (order?: string) => void;
  handleNameSort: (order?: string) => void;
  sortOrderBy?: {
    foldScore?: string,
    hccV24?: string,
    hccV28?: string
  }
  sortOrderByName?: string;
}

export interface IContactTopBarViewProp {
  filterCodes: IDashboardProgramFilter[];
  onChangeCategoryCode: (categoryCode: string) => void;
  selectedCategoryCode: string;
  showInfoIcon?: boolean
  showSearchBar?:boolean
  tabHeaderStyle?: ViewStyle
  timeSourceValue?: string[]
  dateFilterValue?: string
  onActionPerformed?: (value: string) => void
  onDateFilterChange?: (value: string) => void
  showFilterIcon?: boolean
  filterLoading?: boolean;
}

export interface IFilterCategoryApiResponseMap {
  categoryCode: string;
  contactIds: string[];
  count: number;
}

export interface IContactProgramCountResponse {
  contactCareProgram: {
    contactId: string;
    id: string;
  };
  careProgramStep: {
    careProgramStepTypeId: string;
  };
}

export interface IStepsLogResponse {
  contactCareProgramStepsLogs: IContactProgramCountResponse[];
}

export interface IPopGroupResult {
  ruleCode: string;
  ruleResult: {
    data: any[];
    contactUuids: string[];
    count: number;
    inActiveCount: number;
    passedCount: number;
    failedCount: number;
    contacts: string[];
  };
}
export interface IPopGroupResponse {
  result: IPopGroupResult[]
};

export enum StepFilter {
  ASSESSMENT,
  CONSENT,
  SCHEDULE,
  PLAN,
  ALL,
  CCM,
  ACCP,
  AWV,
  TCM,
  ECM,
  AWV_SCHEDULED,
  NOT_SCHEDULED,
  OTHER_APPOINTMENT,
  CCM_ENROLLED,
  ALERTS,

  // CCM Sub Filters
  CCM_VISIT_PENDING,
  CCM_CONSENT_PENDING,
  CCM_ASSESSMENT_PENDING,
  CCM_ENROLLMENT_PENDING,
  CCM_CARE_PLAN_DUE,
  CCM_CARE_PLAN_ENROLL,

  // TCM Sub Filters
  TCM_OUTREACH_PENDING,
  TCM_APPOINTMENT_PENDING,

  // ECM Sub Filters
  ECM_OUTREACH_PENDING,
  ECM_CONSENT_PENDING,
  ECM_ASSESSMENT_PENDING,
  ECM_CARE_PLAN_DUE,
  ECM_CARE_PLAN_ENROLL
};

export enum AwvSubFilter {
  AWV_SCHEDULED,
  NOT_SCHEDULED,
  OTHER_APPOINTMENT
};

export enum CONTACT_SUB_DATA_FILTER {
  TAGS = 0,
  CONSENT = 1,
  MEMBERSHIP = 2,
  CHRONIC_CONDITION = 3,
  CARE_PROGRAM = 4,
  CARE_TEAM = 5,
  CLAIM = 6,
  CARE_PLAN = 7,
  APPOINTMENT = 8,
  LAST_VIST = 9,
  TIME_SPENT = 10
};

export interface ICareTeamMemberType {
  userId: string,
  userName?: string,
  careTeamMemberTypeId: string,
  memberType: {
    code: string,
    value: string,
  }
}

export interface ICareTeams {
  contactId: string,
  careTeamMemberType: ICareTeamMemberType[]
}

export interface IMemberTypeCareTeams {
  careTeams: ICareTeams[]
}

export interface IPayerProgramWiseProgramResponse {
  contactCareProgram: {
    contactId: string;
    id: string;
    payerCareProgram: {
        id: string;
        careProgramTypeId: string;
    };
  };
}

export interface IGridColumn {
  id: string;
  accountUuid?: string;
  tableCodeId: string;
  sequence: number;
  displayName: string;
  columnCode: string;
  dataType: string;
  isVisible: boolean;
  isSortingApplicable: boolean;
  filterValue?: Record<string, any>;
  isDeleted: boolean;
  isDefault: boolean;
}
