import { useMutation } from '@apollo/client';
import { Alert, Drawer } from 'antd';
import { Spinner, Text, useMediaQuery, View, VStack } from 'native-base';
import React, { useContext, useState } from 'react';
import { Dimensions } from 'react-native';
import { BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH } from '../../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../../constants/ActionConst';
import { Colors } from '../../../../../styles/Colors';
import ContactAutoComplete from '../../../../../components/common/ContactRelationView/ContactAutoCompleteCustom';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { styles } from './ContactSearchDrawerStyles';

const ContactSearchOnlyDrawer = (props: any) => {
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const closeModal = () => {
    setShowModal(false);
    props.onFormCompleteAction(COMMON_ACTION_CODES.CANCEL);
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);

  const { width } = Dimensions.get('window');

  const finalWidth = isIPadMiniScreen || isIPadScreen ? width / 2 : width / 2.5;

  return (
    <>
      <Drawer
        visible={showModal}
        width={finalWidth}
        onClose={() => closeModal()}
        closable={false}
        title={ <ModalActionTitle
          title={'Browse Contact'}
          titleColor={''}
          titleSize={'24'}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                closeModal();
              },
            }
          ]}
        />}
      >
        {loading ? <Spinner size="lg" style={styles.spinnerStyle} /> : <></>}
        <View ml={0}>
          <VStack flex={1} marginTop={1}>
            <ContactAutoComplete
              searchBarInputStyles={{paddingVertical: 3.5}}
              borderRadius={14}
              onValueChange={(value: string) => {
              }}
              getMembershipLink={props.getMembershipLink}
              productInfo={props?.productInfo}
            />
          </VStack>
        </View>
      </Drawer>
    </>
  );
};

export default ContactSearchOnlyDrawer;
