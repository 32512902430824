import {Popover, Tooltip} from 'antd';
import {Pressable, View, VStack} from 'native-base';
import {useState} from 'react';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {IS_FEATURE_FLAG_ENABLED} from '../../../../utils/commonUtils';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {ArchivedSvg} from '../../../common/Svg/ArchivedSvg';
import EditSvg from '../../../common/Svg/EditSvg';
import {styles} from './actionViewStyles';

interface IActionViewProps {
  selectedRecord: any;
  onActionClick: (actionCode: string, actionData: any, type?: any) => void;
}

const ActionsView = (props: IActionViewProps) => {
  const [popupVisible, setPopupVisible] = useState(false);
  return (
    <View style={styles.iconView}>
      <Tooltip title={props.selectedRecord.active ? 'Archive' : ''}>
        <Pressable
          style={{paddingHorizontal: 12, paddingVertical: 11, marginRight: 4}}
          onPress={() => {
            props.onActionClick(
              COMMON_ACTION_CODES.ARCHIVED,
              props.selectedRecord
            );
          }}
        >
          <View style={{width: 20, height: 20}}>
            {props.selectedRecord.active ? <ArchivedSvg /> : <></>}
          </View>
          {/* <DeleteSvg /> */}
        </Pressable>
      </Tooltip>
      <Tooltip title={'Edit'}>
        <Pressable
          style={{paddingHorizontal: 12, paddingVertical: 11}}
          onPress={() => {
            props.onActionClick(COMMON_ACTION_CODES.EDIT, props.selectedRecord);
          }}
        >
          <EditSvg />
        </Pressable>
      </Tooltip>
      {IS_FEATURE_FLAG_ENABLED('ENABLE_MEMBERSHIP') && (
        <Tooltip title={'Send Membership Link'}>
          <Popover
            content={
              <VStack>
                {props?.selectedRecord?.prices &&
                  Object.keys(props?.selectedRecord?.prices).map(
                    (item: any) => {
                      if (props?.selectedRecord?.prices[item].priceId) {
                      return (
                        <Pressable
                          key={item}
                          onPress={() => {
                            setPopupVisible(!popupVisible);
                            props.onActionClick(
                              COMMON_ACTION_CODES.SEND_MEMBERSHIP_LINK,
                              props.selectedRecord,
                              item
                            );
                          }}
                        >
                          <View
                            style={{
                              marginVertical: 8,
                            }}
                          >
                            <DisplayText textLocalId={item} />
                          </View>
                        </Pressable>
                      );
                    }
                    }
                  )}
              </VStack>
            }
            trigger="click"
            overlayInnerStyle={{padding: 0, borderRadius: 16}}
            overlayStyle={{padding: 0}}
            overlay={{padding: 0}}
            style={{padding: 0, borderRadius: 16}}
            placement={'bottom'}
            visible={popupVisible}
            onVisibleChange={() => {
              setPopupVisible(!popupVisible);
            }}
          >
            <Pressable
              style={{paddingHorizontal: 12, paddingVertical: 11}}
              onPress={() => {
                setPopupVisible(!popupVisible);
              }}
            >
              <MaterialIcon size={22} color={'#4F2D90'} name="person" />
            </Pressable>
          </Popover>
        </Tooltip>
      )}
    </View>
  );
};

export default ActionsView;
