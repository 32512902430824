import {ICampaignDataProps} from '../../components/RightSideContainer/Marketing/Campaign/interfaces';

export const campaignDummyData: ICampaignDataProps[] = [
  {
    key: '1',
    title: 'Pricing',
    status: 'sending',
    total: 0,
    type: {channel: 'Messenger', kind: 'Manual Campaign'},
    brand: 'Brand Name',
    from: {name: 'Bhavesh Patel', email: 'patelb.e2012@gmail.com'},
    creator: 'Patelbhavesh',
    createdDate: '31 Jan 2022',
    scheduleDate: '-----------:--',
    tags: '',
    actions: '',
  },
  {
    key: '2',
    title: 'Pricing',
    status: 'sending',
    total: 0,
    type: {channel: 'Messenger', kind: 'Manual Campaign'},
    brand: 'Brand Name',
    from: {name: 'Bhavesh Patel', email: 'patelb.e2012@gmail.com'},
    creator: 'Patelbhavesh',
    createdDate: '20 Aug 2020',
    scheduleDate: '-----------:--',
    tags: '',
    actions: '',
  },
  {
    key: '3',
    title: 'Plans',
    status: 'sending',
    total: 0,
    type: {channel: 'Messenger', kind: 'Manual Campaign'},
    brand: 'Brand Name',
    from: {name: 'Bhavesh Patel', email: 'patelb.e2012@gmail.com'},
    creator: 'Patelbhavesh',
    createdDate: '27 Aug 2018',
    scheduleDate: '-----------:--',
    tags: '',
    actions: '',
  },
];
