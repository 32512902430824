import {Divider, HStack, View, VStack} from 'native-base';
import {DisplayText} from '../DisplayText/DisplayText';
import {styles} from './HeaderBarStyles';
import {IHeaderBarProps} from './interface';

export const HeaderBar = (props: IHeaderBarProps) => {
  const {title, containerExtraStyles, hStackMinHight} = props;
  return (
    <View style={containerExtraStyles || {}}>
      <View
        style={[
          styles.topContainer,
          {height: hStackMinHight ? hStackMinHight : 64},
        ]}
      >
        <HStack flex={1}>
          <VStack flex={0.5} justifyContent={'center'}>
            <View style={styles.left}>
              {title && (
                <DisplayText
                  size={'lgMedium'}
                  textLocalId={title}
                  extraStyles={styles.leftText}
                />
              )}
            </View>
          </VStack>
        </HStack>
      </View>
      <Divider />
    </View>
  );
};

export default HeaderBar;
