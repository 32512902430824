import { Drawer, Select } from 'antd';
import {
  HStack,
  FormControl,
} from 'native-base';
import { StyleSheet } from 'react-native';
import { useContext, useEffect, useState } from 'react';
import {
  BUTTON_TYPE,
  DISPLAY_DATE_FORMAT,
} from '../../../../../constants/StringConst';
import { Colors } from '../../../../../styles/Colors';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { ICompleteOrChangeGoalStatusCareJourneyDrawerState, IPatientJourneyProps } from './PatientJourneyInterface';
import { ModalActionDatePicker } from '../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import { getMomentObj, isPastDay } from '../../../../../utils/DateUtils';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import { capitalizeText } from '../../../ContentManagement/CampaignManagement/Helper';
import FHDrawerAlert from '../../../../common/FHDrawerAlert/FHDrawerAlert';
import ReactQuill from 'react-quill';
import { RTE_MODULES } from '../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import { MLOV_CATEGORY } from '../../../../../constants';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { IMlov } from '../../../../../Interfaces/CommonInterfaces';
import { getNoteStringToSave } from './PatientCareJourneyHelper';
import { useIntl } from 'react-intl';

const CompleteOrChangeGoalStatusCareJourneyDrawer = (props: IPatientJourneyProps) => {
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const goalStatusMlov = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.PatientCareJourneyGoalStatus,
  );

  const [stateData, setStateData] = useState<ICompleteOrChangeGoalStatusCareJourneyDrawerState>({
    selectedDate: '',
    showConfirmation: false,
    message: '',
    header: '',
    goalStatus: {
      code: '',
      id: '',
      value: '',
    },
    note: ''
  });

  const setInitialState = () => {
    setStateData((prev) => {
      return {
        ...prev,
        goalStatus: props?.initialGoalState,
        selectedDate: getMomentObj(new Date).toISOString(),
        message: intl.formatMessage({id: 'completeJourneyConfirmation'}),
        header: props?.changeGoalStatus ? 'Update Goal Status' : 'Complete Journey',
        note: props?.selectedJourney?.note || '',
        showConfirmation: false,
      }
    })
  }

  const isSidecarContext = mlovData?.sidecarContext?.isSidecar;

  const resetContent = () => {
    setStateData(prev => {
      return {
        ...prev,
        selectedDate: '',
      };
    });
  };

  useEffect(() => {
    setInitialState()
  }, [props?.selectedJourney?.goalStatusId])

  useEffect(() => {
    if (!props?.enabledDrawer) {
      resetContent()
    }
  }, [props?.enabledDrawer])

  return (
    <>
      <Drawer
        destroyOnClose
        placement="right"
        onClose={() => {
          resetContent();
          props.onCancel();
        }}
        open={props.enabledDrawer}
        closable={false}
        width={isSidecarContext? '100%' : '40%'}
        title={<ModalActionTitle
          title={capitalizeText(props?.selectedJourney?.title || props?.selectedJourney?.careJourney?.title)}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                resetContent();
                props.onCancel();
              },
            },
            {
              show: true,
              isDisabled: (!props?.changeGoalStatus && !stateData.selectedDate),
              id: 2,
              btnText: props?.changeGoalStatus ? 'updateGoalStatus' : 'completeJourney',
              isLoading: props.isLoading,
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                if (!props?.changeGoalStatus) {
                  setStateData(prev => {
                    return {
                      ...prev,
                      showConfirmation: true,
                    };
                  });
                }
                else {
                  props.onActionPerformed({ goalStatusId: stateData?.goalStatus?.id, note: getNoteStringToSave(stateData?.note), changeGoalStatus: true }, props.selectedJourney);
                }
              },
            },
          ]}
        />}
      >
        {!props?.changeGoalStatus &&
          <FormControl style={[styles.formElement]}>
            <FormControl.Label isRequired style={styles.formLabel}>
              <DisplayText
                textLocalId={'completionDate'}
                size={'smMedium'}
                extraStyles={{ color: Colors.Custom.Gray900 }}
              />
            </FormControl.Label>
            <ModalActionDatePicker
              isRequired={false}
              label={''}
              format={DISPLAY_DATE_FORMAT}
              onChange={(date: any) => {
                if (date) {
                  setStateData(prev => {
                    return {
                      ...prev,
                      selectedDate: date
                    };
                  });
                }
              }}
              disabledDate={(current: any) => {
                return current && isPastDay(current);
              }}
              value={stateData.selectedDate && getMomentObj(stateData.selectedDate)}
            />
          </FormControl>
        }
        <FormControl style={[styles.formElement]}>
          <FormControl.Label isRequired style={styles.formLabel}>
            <DisplayText
              textLocalId={'goalStatus'}
              size={'smMedium'}
              extraStyles={{ color: Colors.Custom.Gray900 }}
            />
          </FormControl.Label>
          <Select
            dropdownMatchSelectWidth={3}
            size="large"
            value={stateData?.goalStatus?.value}
            onChange={(value: any, option: any) => {
              setStateData((prev) => {
                return {
                  ...prev,
                  goalStatus: option
                }
              })
            }}
            style={{ height: '36px' }}
            maxTagCount={'responsive'}
          >
            {goalStatusMlov?.map((goal: IMlov) => {
              return (
                <Select.Option
                  id={goal?.id}
                  value={goal?.value}
                  code={goal?.code}
                >
                  {goal.value}
                </Select.Option>
              );
            })}
          </Select>
        </FormControl>
        <HStack>
          <FormControl style={[styles.formElement]}>
            <FormControl.Label style={styles.formLabel}>
              <DisplayText
                textLocalId={'comment'}
                size={'smMedium'}
                extraStyles={{ color: Colors.Custom.Gray900 }}
              />
            </FormControl.Label>
            <ReactQuill
              className="appointment-notes"
              theme="snow"
              value={stateData.note || ''}
              onChange={(value: string) => {
                setStateData(prev => {
                  return {
                    ...prev,
                    note: value
                  };
                });
              }}
              modules={RTE_MODULES}
            />
          </FormControl>
        </HStack>
        {stateData.showConfirmation && (
          <FHDrawerAlert
            isOpen={stateData.showConfirmation}
            header={stateData.header}
            message={stateData.message}
            closeModal={() => {
              setStateData(prev => {
                return {
                  ...prev,
                  showConfirmation: false,
                };
              });
            }}
            buttonActions={[
              {
                textLocalId: 'Close',
                buttonProps: {
                  colorScheme: 'muted',
                  variant: 'outline',
                },
                onPress: () => {
                  setStateData(prev => {
                    return {
                      ...prev,
                      showConfirmation: false,
                    };
                  });
                },
              },
              {
                textLocalId: 'Ok',
                textColor: 'white',
                buttonProps: {
                  colorScheme: 'primary',
                },
                onPress: () => {
                  setStateData(prev => {
                    return {
                      ...prev,
                      showConfirmation: false,
                    };
                  });
                  props.onActionPerformed({ completionDateTime: stateData.selectedDate, goalStatusId: stateData?.goalStatus?.id, note: getNoteStringToSave(stateData?.note)}, props.selectedJourney);
                },
              },
            ]}
          />
        )}
      </Drawer>
    </>
  );
};

export default CompleteOrChangeGoalStatusCareJourneyDrawer;

export const styles = StyleSheet.create({
  swtichBtnStyle: {
    paddingHorizontal: 16,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 40,
  },
  formElement: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  formLabel: {
    marginLeft: 4,
  },
});
