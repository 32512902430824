import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: '400',
  },
  statusContainer: {
    padding: '0px, 16px, 0px, 16px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1.5,
  },
  activeStatus: {
    color: '#32D583',
    marginRight: 5,
    fontSize: 20,
  },
  fullStatus: {
    color: '#FDB022',
    marginRight: 5,
    fontSize: 20,
  },
  otherStatus: {
    color: '#D0D5DD',
    marginRight: 5,
    fontSize: 20,
  },
});
