import { useLazyQuery } from "@apollo/client";
import { Collapse } from "antd";
import { Skeleton, Text, VStack, View } from "native-base";

import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../../../constants/Configs";
import { FormsQueries } from "../../../../../../services";
import { Colors } from "../../../../../../styles";
import { DonutChartGraph } from "../../../../../common/Graph/DonutChartGraph";
import { getEmailCommunicationLogData } from "../../AddOrUpdateWorkflow/WorkflowApi";
import { WorkflowStatusDetail } from "./WorflowStatusDetail";
import { WorkflowStatus } from "./WorkflowStatus";

import { getFormattedDate } from "../../../../../../utils/DateUtils";
import { TeamMemberProfile } from "../../../../Contacts/TeamMembers/TeamMembersTable/TeamMemberProfile";
import { getErrorMessageFromMetadata } from "../../../workflowUtils";
import { isAccountConfigEnabled } from "../../../../../../utils/configUtils";
import { CONFIG_CODES } from "../../../../../../constants/AccountConfigConst";
import { getLocationNames, isLoginUserBusinessOwner } from "../../../../../../utils/commonUtils";
import TagsColumnView from "../../../../Contacts/TeamMembers/TeamMembersTable/TagsColumnView";
import { ILocation } from "../../../../ContentManagement/CampaignManagement/interfaces";
import { styles } from "../../../workflowStyles";
import { GROUP_MEMBER_TYPE } from "../../../../../../constants";
const { Panel } = Collapse;



const TableColumn = ({ value, align}: any)=> {
  return (
        <View >
            <Text style={{
              color: '#101828',
              fontSize: 16,
              fontFamily: 'Manrope',
              fontWeight: '400',
              lineHeight: 24,
              textAlign: align || 'left'
            }}>{value}</Text>
        </View>
    )
}

const ShowFormStatus = (props: { formId: string, subjectId: string }) => {
  const [getFormLogs] = useLazyQuery(FormsQueries.GET_FORMS_LOGS_BY_SUBJECT_ID, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });
  const [formData, setFormData] = useState<any>()
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    getFormLogs({
      variables: {
        formId: props.formId,
        subjectId: props.subjectId,
      },
    }).then((formData) => {
      setFormData(formData.data);
      setIsLoading(false);
    })
  }, [props.formId, props.subjectId])
  let status = formData?.formLogs && formData?.formLogs.length ? formData?.formLogs[0]?.status?.code : '';
  // DRAFT status means partially filled, not submitted
  if (status === 'DRAFT') {
    status = 'PENDING';
  }
  return (isLoading ? <></> : <VStack>
    <Text>{formData?.form?.name}</Text>
    <Text size={'smNormal'} color={Colors.Custom.Gray500}>{status}</Text>
  </VStack>)
}

const WorkflowPersonLevelEmailInfoByExecutionId = (props: { isShowCheckbox?: boolean, setSelectedList?: any, logTypeList?: string[], total: number, campaignId?: string, workflowExecutionId?: string, contactId?: string, uiNodeId?: string, channelType?: string, userPracticeLocations?: string[], foldLocations?: ILocation[] }): JSX.Element => {
  const defaultPageSize = 20;
  const [pageState, setPageState] = useState({
    limit: defaultPageSize,
    currentPage: 1,
    offset: 0,
    total: defaultPageSize + 1,
  });
  const [pendingCount, setPendingCount] = useState<number|undefined>();
  const [submitCount, setSubmitCount] = useState<number|undefined>();
  const isMsoEnabled = isAccountConfigEnabled( CONFIG_CODES.IS_MSO_ENABLED )
  const isBusinessOwner = isLoginUserBusinessOwner();

  const [isLoading, setIsLoading] = useState(false);
  const pathParams = useParams();
  const [searchParams] = useSearchParams();
  const workflowMasterId = pathParams.workflowId;
  const [communicationLogList, setCommunicationLogList] = useState<any>([]);


  const  TabData: any = {
    completed: {
      label: 'Completed',
      color: Colors.tertiary[300],
      status: 'COMPLETE',
      key: 'completed'
    },
    inProgress: {
      label: 'In Progress',
      color: Colors.info[300],
      status: 'IN_PROGRESS',
      key: 'incomplete'
    },
    failed: {
      label: 'Failed',
      color: Colors.danger[300],
      status: 'ERROR',
      key: 'failed'
    }
  };


  const [activeTabData, setActiveTabData] = useState(TabData.completed);

  const [workflowExecutionGraphData, setWorkflowExecutionGraphData] = useState<any>();
  const [workflowExecutionData, setWorkflowExecutionData] = useState<any>();
  const [getFormLogsBySubjectIdList] = useLazyQuery(FormsQueries.GET_FORMS_LOGS_BY_SUBJECT_ID_LIST, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });
  const createWorkflowExecutionGraphData = (workflowExecutionData: any) => {
    workflowExecutionData = workflowExecutionData || {}
    const completed = workflowExecutionData?.completed || 0;
    const failed = workflowExecutionData?.failed || 0;
    const incomplete = workflowExecutionData?.incomplete || 0;
    const queued = workflowExecutionData?.queued || 0;
    // props.setDisabledStatus ? props.setDisabledStatus(props.workflowData, workflowExecutionData.totalCount === 0) : undefined;
    setWorkflowExecutionData(workflowExecutionData)

    setWorkflowExecutionGraphData([
      {
        type: 'Completed Automation',
        value: completed
      },
      {
        type: 'In Progress Automation',
        value: incomplete
      },
      {
        type: 'Failed Automation',
        value: failed
      },
      {
        type: 'Queued',
        value: queued
      }
    ])
  }
  useEffect(() => {
    setIsLoading(true);

    createWorkflowExecutionGraphData({
      ...workflowExecutionData,
      serviceLogs: null,
    });
    loadCommunicationLogData(false, false, async(response: any) => {
      const data = response;
      const formatedData: any = {
        completed: data?.completed?.aggregate?.count,
        failed: data?.failed?.aggregate?.count,
        incomplete: data?.inprocess?.aggregate?.count,
        queued: data?.queued?.aggregate?.count,
        totalCount: data?.aggregateServiceLog?.aggregate?.count,
        serviceLogs: data?.serviceLogs,
      };

      // const contactIds:any = [];
      //     data?.serviceLogs.forEach((item: any)=> {
      //       item?.resourceLevelLog.forEach((itemVal: any)=>{
      //         if(itemVal.resourceType === 'CONTACT'){
      //           contactIds.push(itemVal?.resourceId);
      //         }
      //       })
      //     })
      // formatedData.resourceIds = contactIds;
      if (data?.serviceLogs && isMsoEnabled) {
        const updatedServiceLogs = await Promise.all(
          data.serviceLogs.map(async (log: any) => {
            if (log?.campaignContactLocations) {
              const updatedLocations = await Promise.all(
                log?.campaignContactLocations?.map(async (location: { locationId : string}) => {
                  const result = await getLocationNames(props?.foldLocations, [location?.locationId]);
                  const names = result?.map((loc: ILocation) => loc?.name) || [];
                  return {
                    ...location,
                    name: names,
                  };
                })
              );
              return {
                ...log,
                campaignContactLocations: updatedLocations,
              };
            }
            return log;
          })
        );
        formatedData.serviceLogs = updatedServiceLogs;
      }
      setActiveTabData((data: any) => {
        return {
          ...data,
          count: formatedData[activeTabData.key],
        };
      });
      createWorkflowExecutionGraphData(formatedData);
      // setResourceIds(contactIds)
    });
  }, [
    activeTabData?.status,
    pageState.limit,
    pageState.offset,
    searchParams?.get('emailStatus'),
    searchParams?.get('smsStatus'),
  ]);




  useEffect(()=>{
    if((props.logTypeList || []).indexOf('FORM_SEND') != -1 ){
      loadCommunicationLogData(false, true, (response:any)=>{
        const subjectIdList =(response?.serviceLogs || []).map((serviceLog:any)=>{return serviceLog?.metadata?.subjectId}).filter((subjectId?:string)=>{return subjectId})
        getFormLogsBySubjectIdList({variables:{subjectIdList}}).then(formLogResponse=>{
          setPendingCount(formLogResponse?.data?.pending?.aggregate?.count);
          setSubmitCount(formLogResponse?.data?.submitted?.aggregate?.count);
        });
      })
    }
  }, [props.uiNodeId])



  const loadCommunicationLogData = async (isReset: boolean, isFetchOnlyMetadata:boolean, callBack?:any) => {
    setIsLoading(true)

    try {
      const data: any = {
        isFetchOnlyMetadata: isFetchOnlyMetadata,
        isBusinessOwner:isBusinessOwner
      }
      if(workflowExecutionData){
        data.status = activeTabData.status;
      }
      if(!isFetchOnlyMetadata){
        data.limit = pageState.limit;
        data.offset = isReset ? 0 : pageState.offset;
      }
      if (props.logTypeList) {
        data.logTypeList = props.logTypeList;
      }
      if (props.workflowExecutionId) {
        data.workflowExecutionId = props.workflowExecutionId;
      }
      if (props.campaignId) {
        data.campaignId = props.campaignId;
      }
      if (props.contactId) {
        data.contactId = props.contactId;
      }
      if (props.uiNodeId) {
        data.uiNodeId = props.uiNodeId;
      }
      if (workflowMasterId) {
        data.workflowMasterId = workflowMasterId;
      }

      if(props?.userPracticeLocations && isMsoEnabled) {
        data.userPracticeLocations = props.userPracticeLocations;
        data.isMsoEnabled = isMsoEnabled;
      }
      // const emailStatus = searchParams?.get('emailStatus');
      // if(emailStatus){
      //   data.emailStatus = emailStatus;
      // }
      // const smsStatus = searchParams?.get('smsStatus');
      // if(smsStatus){
      //   data.smsStatus = smsStatus;
      // }
      if (activeTabData?.status) {
        data.status = activeTabData.status;
      }
      const response: any = await getEmailCommunicationLogData(data);
      if(callBack){
        setIsLoading(false);
        return callBack(response)
      }

      const total = response?.aggregateServiceLog?.aggregate?.count || 0;
      setPageState((prev) => {
        return {...prev, total};
      });
      setCommunicationLogList((prev: any) => {
        const newList = (response?.serviceLogs || []).filter((item1: any) => {
          const isMatch = (prev || []).some((item2: any) => {
            return item1.id === item2.id;
          })
          return !isMatch;
        })
        const list = [...prev, ...newList]
        return list
      });
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  };


  const onChangeSelection = (item: any, isSelected: boolean) => {

    setCommunicationLogList((communicationLogList: any) => {
      const newCommunicationLogList: any = []
      communicationLogList.forEach((item2: any) => {
        const newItem = { ...item2 }
        if (item.id === newItem.id) {
          newItem.isSelected = isSelected;
        }
        newCommunicationLogList.push({ ...newItem })
      })
      if (props.setSelectedList) {
        const selectedList = newCommunicationLogList.filter((item: any) => {
          return !!item.isSelected;
        })
        props.setSelectedList(selectedList);
      }
      return [...newCommunicationLogList]
    })
  }

  const getMailTitleMessage = (metadata: any, createdOn?: string) => {
    const serviceResponse = metadata?.serviceResponse;
    const response = {
      message: '',
      email: metadata?.email,
      subject: metadata?.requestData?.subject,
      createdOn: createdOn
    }
    if (serviceResponse) {
      let jsonResponse: any = {}
      try {
        jsonResponse = JSON.parse(serviceResponse);
      } catch {
      }
      response.message = jsonResponse?.message;
      const recipientList = jsonResponse?.recipientList || [];
      const emailList = recipientList.map((recipient: any) => {
        response.subject = recipient?.subject || '';
        return (recipient?.to || '');
      }).filter((email: string) => { return !!email })
      response.email = emailList.join(', ')
    }
    return response
  }

  // useEffect(()=> {

  // }, [campaignId])


  enum LOADING_STATUS {
    loadingValue,
    loadedValue,
    needToLoad,
  }
  const [workflowExecutionDataLoadingStatus, setWorkflowExecutionDataLoadingStatus] = useState(LOADING_STATUS.needToLoad)
  if (!props?.campaignId) {
    return <></>
  }



  const config = {
    appendPadding: 0,
    data: workflowExecutionGraphData,
    radius: 0.5,
    totalCount: workflowExecutionData?.totalCount,
    legend: false,
    style: {
      height:  250 ,
      left: 0
    },
    angleField: 'count',
    tooltip:false,
    colorField: 'workflowStatus',
    label: {
      type: 'inner',
      content: '',
    },
    color: [Colors.tertiary[300], Colors.info[300], Colors.danger[300], Colors.muted[300]],
    interactions: [

    ],
  };

  const isSMSCampaign = props.channelType && props.channelType === 'SMS'

  const getCampaignColumn = (contacts: any) => {
    return [
      {
        title: 'Name',
        dataIndex: 'resourceLevelLog',
        key: 'resourceLevelLog',
        width: isMsoEnabled ? '30%' : '40%',
        render: (resourceLevelLog: any) => {
          const resource = resourceLevelLog.filter(
            (item: any) =>
              item.resourceType === 'CONTACT' || item.resourceType === 'TASK'
          );
          const id = resource?.[0]?.resourceId;
          const name = contacts?.[id]?.name;
          return (
            <TeamMemberProfile
              fullName={name}
              phone={contacts?.[id]?.phoneNumber}
              userType={GROUP_MEMBER_TYPE.PATIENT}
            />
          );
        },
      },
      {
        title: 'Date and Time',
        dataIndex: 'createdOn',
        key: 'createdOn',
        render: (createdOn: string) => {
          return (
            <TableColumn
              value={getFormattedDate(createdOn, 'MM/DD/YYYY, h:mm a')}
            />
          );
        },
        width: '20%',
      },
      ...(isMsoEnabled ? [
        {
          title: 'Location',
          dataIndex: 'campaignContactLocations',
          key: 'campaignContactLocations',
          render: (campaignContactLocations: { locationId: string; name: string[] }[], record: any) => {
            const locationNames = (campaignContactLocations || [])?.flatMap((loc) => loc?.name || []);
            return (
              <TagsColumnView
                color={Colors.Custom.Gray900}
                bgColor={Colors.Custom.Primary50}
                labelData={locationNames}
              />
            )
          },
          width: '30%',
        }] : []),

      ...(!isSMSCampaign
        ? [
            {
              title: 'Opened',
              dataIndex: 'emailAnalyticTag',
              key: 'emailAnalyticTag',
              width: '15',
              render: (emailAnalyticTag: any) => {
                return (
                  <TableColumn
                    value={emailAnalyticTag?.opened?.aggregate?.count}
                  />
                );
              },
            },
          ]
        : []),
      ...(!isSMSCampaign
        ? [
            {
              title: 'Clicked',
              dataIndex: 'emailAnalyticTag',
              key: 'emailAnalyticTag',
              width: '15',
              render: (emailAnalyticTag: any) => {
                return (
                  <TableColumn
                    value={emailAnalyticTag?.clicked?.aggregate?.count}
                  />
                );
              },
            },
          ]
        : [])
    ];
  };


  return (
    <View>

          <View flex={1} flexDirection={'row'} gap={3} height={600}>
            <View  flexDirection={'column'} alignItems={"left"} justifyContent='left' style={styles.width30pHeight400}>
              <View flex={20} marginTop={5}>

              { workflowExecutionData?.completed || workflowExecutionData?.incomplete || workflowExecutionData?.failed || workflowExecutionData?.queued ? <>
                {/* {!props.isIcon && <Text color={Colors.Custom.Gray500} size='smMedium' >Execution Results</Text>} */}
              <DonutChartGraph {...config} ></DonutChartGraph> </>:
              <>No Execution</> }
              </View>
              <View flex={1} flexDirection={'row'}  alignItems={"center"} justifyContent='center' height={'100%'} paddingTop={4}>
                  <View padding={4}
                    background={Colors.Custom.Gray100}
                    flexGrow={1}
                    alignItems={"center"}
                    borderRightRadius={1}
                    gap={1}
                    >
                    <Text fontSize={12} color={'#667085'} >Delivery rate</Text>
                    <Text fontSize={16} fontWeight={'600'}>{workflowExecutionData?.completed ? Math.trunc((workflowExecutionData?.completed * 100)/workflowExecutionData?.totalCount) + '%': '0%'}</Text>
                  </View>
                  <View
                    padding={4}
                    background={Colors.Custom.Gray100}
                    flexGrow={1}
                    alignItems={"center"}
                    borderLeftRadius={1}
                    gap={1}
                  >
                    <Text fontSize={12} color={'#667085'}>Failed rate</Text>
                    <Text fontSize={16} fontWeight={'600'}>{workflowExecutionData?.failed >0 ? Math.trunc((workflowExecutionData?.failed * 100)/workflowExecutionData?.totalCount) + '%' : '0%'}</Text>
                  </View>
              </View>
              <View flex={1} paddingTop={16} flexDirection={'column'} gap={0}>
                <WorkflowStatus
                  iconColor={TabData.completed.color}
                  isActive={activeTabData.label === TabData.completed.label}
                  status={TabData.completed.label}
                  value={workflowExecutionData?.completed || 0}
                  onClick={() => {
                    setPageState((prev) => {
                      return {
                        ...prev,
                        limit: defaultPageSize,
                        currentPage: 1,
                        offset: 0,
                      };
                    });
                    setActiveTabData({...TabData.completed, count: workflowExecutionData?.completed || 0});
                  }}
                />

                <WorkflowStatus

                  iconColor={TabData.inProgress.color}
                  key={TabData.inProgress.label}
                  onClick={() => {
                    setPageState((prev) => {
                      return {
                        ...prev,
                        limit: defaultPageSize,
                        currentPage: 1,
                        offset: 0,
                      };
                    });
                    setActiveTabData({...TabData.inProgress, count: workflowExecutionData?.incomplete || 0});

                  }}
                  isActive={TabData.inProgress.label === activeTabData.label}
                  status={TabData.inProgress.label}
                  value={workflowExecutionData?.incomplete || 0}
                />
                <WorkflowStatus
                  iconColor={TabData.failed.color}
                  key={TabData.failed.label}
                  onClick={() => {
                    setPageState((prev) => {
                      return {
                        ...prev,
                        limit: defaultPageSize,
                        currentPage: 1,
                        offset: 0,
                      };
                    });
                    setActiveTabData({...TabData.failed, count: workflowExecutionData?.failed || 0});
                  }}
                  isActive={activeTabData.label === TabData.failed.label}
                  status={TabData.failed.label}
                  value={workflowExecutionData?.failed || 0}
                />

              </View>
            </View >
            <View flex={1} style={styles.width70p}>
              {!isLoading && workflowExecutionData?.serviceLogs ?
                <WorkflowStatusDetail
                  activeTab={activeTabData.label}
                  activeTabCount={activeTabData.count}
                  workflowExecutionData={workflowExecutionData}
                  iconColor={activeTabData.color}
                  getColumn={(contacts: any)=> getCampaignColumn(contacts)}
                  activePage={pageState.currentPage}
                  limit={pageState.limit}
                  onPageChange={(page: any, pageSize:any)=> {
                    setPageState((prev) => {
                      return {
                        ...prev,
                        limit: pageSize,
                        currentPage: page,
                        offset: (page - 1) * pageSize,
                      };
                    });
                  }}

                />:
                <View padding={3}>
                    <Skeleton.Text lines={6} />
                </View>

              }
            </View>
          </View>



    </View>
  )
}

export default WorkflowPersonLevelEmailInfoByExecutionId;
