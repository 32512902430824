import {useQuery} from '@apollo/client';
import {Select as MultiSelect} from 'antd';
import {
  Button,
  HStack,
  Pressable,
  Skeleton,
  Text,
  View,
  VStack,
} from 'native-base';
import {useContext, useState} from 'react';
import Icon from 'react-native-vector-icons/AntDesign';
import { USER_ROLE_CODES } from '../../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {ILoginUserData} from '../../../../../../Interfaces/CommonInterfaces';
import InboxQueries from '../../../../../../services/Inbox/InboxQueries';
import {Colors} from '../../../../../../styles';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {INTEGRATION_CONSTANTS} from '../../constants';
import {getAgentRecord} from '../../Helper/formatIntegrationsData';
import {reactStyles, styles} from '../../IntegrationsStyles';
import {IIntegrationsScreenThreeProps} from '../../interfaces';
const {Option} = MultiSelect;

export const IntegrationScreenThree = (
  props: IIntegrationsScreenThreeProps
) => {
  const [selectedAgents, setSelectedAgents] = useState(['']);

  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);

  const onSelectAgents = (value: string[], list: any) => {
    const agentIds= [] as any;
    list.forEach((item: any) => {
      agentIds.push(item.agentId)
  });
    setSelectedAgents(agentIds);
  };

  const {loading: agentsLoading, data: agentsData} = useQuery(
    InboxQueries.GetAgents,
    {
      variables: {accountId: userData.account_id,
        roleCode: USER_ROLE_CODES.EMPLOYER,
      },
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const formattedData = getAgentRecord(agentsData);

  return agentsLoading ? (
    <View padding={3}>
      <Skeleton.Text lines={6} />
    </View>
  ) : (
    <VStack space={4} style={styles.leftPad}>
      <HStack style={styles.titleBar}>
        <HStack flex={1} justifyContent={'center'} alignItems={'center'}>
          <VStack flex={0.05}>
            <Pressable
              onPress={() =>
                props.onBackClick(INTEGRATION_CONSTANTS.SCREEN_TWO)
              }
            >
              <Icon name="left" size={25} color={Colors.primary[400]} />
            </Pressable>
          </VStack>
          <VStack flex={0.9}>
            <DisplayText
              textType="Heading"
              textLocalId="agents"
              size={'lgNormal'}
            />
            <HStack flex={1} mt={1}>
              <Text color={Colors.Custom.DataKeyColor} size={'smLight'}>
                {INTEGRATION_CONSTANTS.AGENTS_DESCRIPTION}
              </Text>
            </HStack>
          </VStack>
        </HStack>
        <HStack>
          {/* <Button
            size="sm"
            variant="solid"
            colorScheme="dark"
            _text={{color: 'white'}}
            onPress={props.onCancelClick}
          >
            <DisplayText
              textLocalId="close"
              size={'mdNormal'}
              extraStyles={{color: Colors.Custom.FontColorWhite}}
            />
          </Button> */}
        </HStack>
      </HStack>
      <HStack flex={1} style={styles.agents}>
        <VStack flex={0.15}></VStack>
        <VStack space={5}>
          <MultiSelect
            mode="multiple"
            allowClear
            style={reactStyles.multiSelect}
            placeholder="Pick agents for the inbox"
            onChange={onSelectAgents}
          >
            {formattedData.map((agentRecord: any) => (
              <Option key={agentRecord.agentId} agentId={agentRecord.agentId} value={agentRecord.agentName}>
                {agentRecord.agentName}
              </Option>
            ))}
          </MultiSelect>
          <Button
            style={styles.agentButton}
            variant="solid"
            colorScheme="primary"
            _text={{color: 'white'}}
            onPress={() =>
              props.handleScreenThreeChange(
                INTEGRATION_CONSTANTS.SCREEN_FOUR,
                selectedAgents
              )
            }
          >
            {INTEGRATION_CONSTANTS.ADD_AGENTS}
          </Button>
        </VStack>
      </HStack>
    </VStack>
  );
};
