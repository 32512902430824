import React from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {View, IconButton, Icon} from 'native-base';
import {styles} from './ActionsViewStyles';
import {testID, TestIdentifiers} from '../../../../../../testUtils';

export interface IActionViewProps {
  onDelete?: () => void;
  onEdit?: () => void;
  onDetails?: () => void;
}

const ActionsView = (props: IActionViewProps) => {
  return (
    <View style={styles.iconView}>
      <IconButton
        colorScheme="gray"
        tintColor="gray.500"
        variant="ghost"
        onPress={props.onDelete}
        icon={
          <Icon
            as={AntIcon}
            name="delete"
            size="5"
            color="gray.400"
            {...testID(TestIdentifiers.deleteBtn)}
          />
        }
      />
      <IconButton
        colorScheme="gray"
        variant="ghost"
        onPress={props.onEdit}
        icon={
          <Icon
            as={AntIcon}
            name="edit"
            size="5"
            color="gray.400"
            {...testID(TestIdentifiers.editBtn)}
          />
        }
      />
    </View>
  );
};

export default ActionsView;
