import {Text, View} from 'react-native';
import {styles} from './Styles';
import {Select, Spin} from 'antd';
import {useContext, useEffect, useState} from 'react';
import UserPracticeLocationQueries from '../../../services/Location/UserPracticeLocationQueries';
import {IAccountPracticeLocationResp} from '../../../services/Location/interfaces';
import {useLazyQuery} from '@apollo/client';
import {
  getAccountUUID,
  getBooleanFeatureFlag,
  getUserUUID,
  isLoggedInUserGlobalAdmin,
  isLoggedInUserWorkFlowOrCustomerSuccess,
  isLoginUserBusinessOwner,
} from '../../../utils/commonUtils';
import {CommonDataContext} from '../../../context/CommonDataContext';
import FeatureFlags from '../../../constants/FeatureFlags.enums';

const {Option} = Select;

export const FoldLocationInput = ({
  selectedLocationIds,
  onLocationChange,
  showTitle
}: {
  selectedLocationIds?: string[];
  onLocationChange: (
    selectedLocationIds: string[],
    selectedLocationName?: string[]
  ) => void;
  showTitle?: boolean;
}) => {
  const accountUuid = getAccountUUID();
  const commonData = useContext(CommonDataContext);
  const userUuid = getUserUUID();
  const isMultiTenancyEnabled = getBooleanFeatureFlag(
    commonData.userSettings,
    FeatureFlags.IS_MULTI_TENANCY_ENABLED
  );
  const allAccountLocations = commonData.accountLocationListWithEHR;

  const isAllowedAllLocation =
    isLoginUserBusinessOwner() ||
    isLoggedInUserGlobalAdmin() ||
    isLoggedInUserWorkFlowOrCustomerSuccess() ||
    !isMultiTenancyEnabled;

  const [foldLocationInputState, setFoldLocationInputState] = useState({
    locationLoading: false,
    userPracticeLocations: [] as any[],
    selectedLocationUuid: selectedLocationIds,
    userPracticeLocationsName: [] as string[],
  });

  const [getUserPracticeLocationsByUserId, {data, loading}] =
    useLazyQuery<IAccountPracticeLocationResp>(
      UserPracticeLocationQueries.GetUserPracticeLocationsByUserId(),
      {
        variables: {accountUuid: accountUuid, userId: userUuid},
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          const userLocations = data?.accountLocations || [];
          setFoldLocationInputState((prev) => ({
            ...prev,
            userPracticeLocations: userLocations,
            locationLoading: false,
          }));
        },
        onError: () => {
          setFoldLocationInputState((prev) => ({
            ...prev,
            locationLoading: false,
          }));
        },
      }
    );

  useEffect(() => {
    if (isAllowedAllLocation) {
      setFoldLocationInputState((prev) => ({
        ...prev,
        userPracticeLocations: allAccountLocations || [],
        locationLoading: false,
      }));
    } else {
      setFoldLocationInputState((prev) => ({
        ...prev,
        locationLoading: true,
      }));
      getUserPracticeLocationsByUserId();
    }
  }, []);

  const handleLocationChange = (nextValue: string[]) => {
    const userPracticeLocationsName = nextValue.map((location) => {
      const locationName = foldLocationInputState.userPracticeLocations.find(
        (userLocation) => userLocation.uuid === location
      );
      return locationName?.practiceLocation?.name;
    });

    setFoldLocationInputState((prev) => ({
      ...prev,
      selectedLocationUuid: nextValue,
      userPracticeLocationsName,
    }));
    onLocationChange(nextValue,userPracticeLocationsName)
  };

  return (
    <View>
      <View
        style={[styles.filterTitle, {display: showTitle ? 'flex' : 'none'}]}
      >
        <Text>Filter by Location</Text>
      </View>
      <View style={styles.inputContainer}>
        <Select
          mode="multiple"
          allowClear
          placeholder="Search Location"
          showSearch
          filterOption={(input: string, option: any) => {
            return (option?.children || '')
              ?.toLowerCase?.()
              ?.includes(input?.toLowerCase());
          }}
          value={!loading ? foldLocationInputState.selectedLocationUuid : undefined}
          onChange={handleLocationChange}
          loading={loading}
          notFoundContent={loading && <Spin size="large" />}
          style={{width: '100%'}}
        >
          {foldLocationInputState.userPracticeLocations.map((userLocation) => (
            <Option key={userLocation.uuid} value={userLocation.uuid}>
              {userLocation.practiceLocation.name}
            </Option>
          ))}
        </Select>
      </View>
    </View>
  );
};

export default FoldLocationInput;
