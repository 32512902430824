import {Skeleton} from 'antd';
import {Bar} from '@ant-design/plots';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import EyeHideSvg from '../../../../common/Svg/WidgetsSvg/EyeHideSvg';
import {IAppliedFilter, IResource, IWidgetCardView} from '../interface';
import NodataViewTypeOne from './NoDataView/NodataViewTypeOne';
import WidgetActionView from './WidgetActionView';
import {BarConfig, IGroup, Pie, PieConfig} from '@ant-design/charts';
import {GraphColors} from '../../../../../styles/Colors';
import {getHorizontalTrendBarChartData, getTopDataViewForAppointmentChartView, wordWrapFromChart} from '../EmployerUtils/FormateDataUtils';
import InfoIconView from '../CommonView/InfoIconView';
import {DashboardDataContext} from '../EmployerContext/DashboardContext';
import {WIDGET_ACTION_CODE} from '../EmployerUtils/EmployerConst';
import {AUTOMATION_TIME_SAVED, getTenantForDemo, TEMP_CHROMIC_DATA, TOP_CARE_JOURNEYS, TOP_DIAGNOSIS, TOP_IMAGING, TOP_LABS, TOP_MEDICATIONS, TOP_SYMPTOMS_CHIEF_COMPLAINTS} from '../tempDataFile';
import {ANALYTICS_RESOURCE, DATE_FORMATS, DISPLAY_TYPE, EMPLOYER_REPORT_FILTER_CODES, MOMENT_DATE_CONST} from '../../../../../constants';
import {getSectionDataByResourceCode} from '../../../../../services/Employer/EmployerServices/EmployerServices';
import {aggregateGroupEntry, getXAxisTitleByResourceCode, getYAxisTitleByResourceCode, isWidgetDataEmpty} from '../EmployerUtils/WidgetUtils';
import {Pressable, Tooltip} from 'native-base';
import RestSvgIcons from '../../../../common/Svg/RestSvgIcons';
import {getFormmatedTrendsData, getGraphColorsByName, getMaxXAxisValueTopUtilization, getXAxisMaxValueWidgetObject} from './utils';
import moment from 'moment';
import WidgetHeader from '../WidgetHeader/WidgetHeader';
import {graphColorList, graphMetaDataColor} from '../graphColors';
import {capitalizeText} from '../../../ContentManagement/CampaignManagement/Helper';
import {getFormattedDateStringInTimeZone} from '../../../../../utils/DateUtils';
import CustomTooltip from './MixMultipleCharts/CustomTooltip';

const TrendsHorizBarWidget = (props: IWidgetCardView) => {
  const {
    sectionData,
    reusableData,
    ref,
    onImageDownloadClick,
    employerId,
    appliedFilter,
    isAccountDashboard,
    memberType,
    hintMembershipDisabled,
  } = props;
  const isTopImaging = sectionData.resourceCode === ANALYTICS_RESOURCE.TOP_IMAGING;
  const isTopLabs = sectionData.resourceCode === ANALYTICS_RESOURCE.TOP_LABS;
  const isTopCareJourney = sectionData.resourceCode === ANALYTICS_RESOURCE.TOP_CARE_JOURNEYS;
  const isTopMedications = sectionData.resourceCode === ANALYTICS_RESOURCE.TOP_MEDICATIONS;
  const isTopSymptoms = sectionData.resourceCode === ANALYTICS_RESOURCE.TOP_SYMPTOMS_CHIEF_COMPLAINTS;
  const isChronicRiskTrends = sectionData.resourceCode == ANALYTICS_RESOURCE.CHRONIC_RISKS_TREND
  const isTopDiagnosis = sectionData.resourceCode == ANALYTICS_RESOURCE.TOP_DIAGNOSIS
  const isAutomationTimeSaved = sectionData.resourceCode == ANALYTICS_RESOURCE.AUTOMATION_TIME_SAVED;

  const employeeCount = getTenantForDemo() ? 430 : reusableData?.employeeCount;

  const [stateData, setStateData] = useState({
    donutChartData: [] as any[],
    resourceData: [] as IResource[] | any,
    loading: true,
    appliedFilter: props.appliedFilter,
    filterValueName: [] as string[],
    filterValueString: '',
    selectedAppliedFilter: [] as any[]
  });
  const totalChronicLevel: any[] = stateData.resourceData
    ?.topChronicConditionsPatientCount?.length
    ? stateData.resourceData?.topChronicConditionsPatientCount
    : [];
  const onActionClick = (code: string, data?: any) => {
    switch (code) {
      case WIDGET_ACTION_CODE.RESET_LOCATION_FILTER:
        const filteredWidgetFilter = stateData.selectedAppliedFilter.filter(item => {
          return item.filterCode != 'LOCATION'
        })
        setStateData(prev => {
          return {
            ...prev,
            selectedAppliedFilter: filteredWidgetFilter,
            filterValueString: '',
            filterValueName: []
          }
        })
        getSectionByResourceCode();
        break;
      default:
        props.onActionClick?.(code, data)
        break;
    }
  }
  const getTopChronicLevelPercentage = () => {
    const donutChartData: any[] = [] as any;
    const sortedArray: any = totalChronicLevel?.sort(
      (a, b): any => a.totalChronicCondition - b.totalChronicCondition
    );
    sortedArray?.forEach((data: any) => {
      const outOfCount = (employeeCount - data.countNum) / employeeCount;
      const countPercentage: any = outOfCount * 100;
      const emptyPercentage: any = countPercentage;
      const percentageValue: any = 100 - emptyPercentage;
      const temp = {
        title: `At least ${data.totalChronicCondition}`,
        data: [
          {
            title: 'Number',
            percentage: percentageValue,
            label: parseFloat(percentageValue).toFixed(2),
            code: data.totalChronicCondition,
            count: data.countNum
          }
        ],
      };
      donutChartData.push(temp);
    });
    setStateData((prev) => {
      return {
        ...prev,
        donutChartData: [...donutChartData],
      };
    });
  };

  const donutChartView = (data: any, title: string, index: number) => {
    return (
      <main
        key={index}
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '50%'
        }}
      >
        <div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
            <div
              style={{
                color: '#000',
                fontSize: '23px',
                fontWeight: '700',
                width: '100%',
              }}
            >
              {data[0]?.count}
            </div>
            <div style={{
              color: '#98A2B3',
              fontSize: '14px',
              fontWeight: '300',
              maxWidth: 150,
              marginLeft: 2
            }}>Members</div>
          </div>
          <div
            style={{
              color: '#98A2B3',
              fontSize: '14px',
              fontWeight: '500',
              maxWidth: 150,
            }}
          >
            {title}
          </div>
        </div>
      </main>
    );
  };

  const donutChartViewListView = () => {
    return employeeCount ? (
      stateData.donutChartData?.map((item, index: number) => {
        if (index > 1) {
          return null;
        }
        return donutChartView(item.data, item?.title, index);
      })
    ) : (
      <></>
    )
  }

  const data = getHorizontalTrendBarChartData(sectionData.resourceCode || '', stateData.resourceData);

  const topViewData = getTopDataViewForAppointmentChartView(
    data,
    sectionData.resourceCode || ''
  );
  useEffect(() => {
    if (sectionData.resourceCode == ANALYTICS_RESOURCE.CHRONIC_RISKS_TREND) {
      getTopChronicLevelPercentage();
    }
  }, [stateData.loading]);
  const graphYAxisTitle = getYAxisTitleByResourceCode(sectionData.resourceCode || '')
  const graphXAxisTitle = getXAxisTitleByResourceCode(sectionData.resourceCode || '')
  const getBarChartViewByType = () => {
    let ele = <></>;
    let configGroup: BarConfig;
    const maxValueXValue = getMaxXAxisValueTopUtilization(data)
    switch (sectionData.currentDisplayType) {
      case DISPLAY_TYPE.H_BAR_CHART_WITH_TOP_ITEM:
        configGroup = {
          data,
          xField: 'count',
          color: graphColorList,
          yField: 'name',
          tooltip: {
            formatter:(data)=>{
              return {
                name:
                  sectionData.resourceCode ===
                  ANALYTICS_RESOURCE.AUTOMATION_TIME_SAVED
                    ? 'Minutes Saved'
                    : 'Count',
                value: data.count,
              };
            }
          },
          xAxis: {
            label: {
              offset: 20,
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              },
            },
            title: {
              text: graphXAxisTitle.title,
              description: graphXAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              },
              offset: 59
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              }
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            },
            ...(maxValueXValue)
          },
          yAxis: {
            tickLine: {
              style: {
                fill: '#fff',
                stroke: '#fff',
              },
            },
            label: {
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor,
              },
              formatter: (text: string) => {
                // return wordWrapFromChart(text, 50);
                return `${text.substring(0, 40)} ${text?.length < 40 ? '' :'...'}`;
              },
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              }
            },
          },
          legend: {
            itemSpacing: 3,
            position: 'top-right',
            layout: 'horizontal',
            itemName: {
              formatter(text, item, index) {
                  return text?.toLocaleUpperCase()
              },
            },
            marker: {
              symbol: 'circle',
              style(style) {
                  return {
                    ...style,
                    r: 4
                  }
              },
            }
          },
          height: 240,
          width: 470,
          barBackground: {
            style: {
              fill: 'transparent',
            },
          },
          barStyle(datum) {
            return {
              radius: [8, 8, 8, 8],
              fill: '#80A4D5',
              points: [{}],
            };
          },
          interactions: [
            {
              type: 'element-selected',
            },
            {
              type: 'element-active',
            },
          ],
          onReady: (plot: any) => {
            plot.on('element:click', (args: any) => {
              if (args?.data?.data?.name) {
                let filterData = [] as any[];
                const dataType = capitalizeText(args?.data?.data?.name);
                const dateOrMonth = data?.data?.data?.date || data?.data?.data?.month
                let filterValue;
                if(sectionData.resourceCode === ANALYTICS_RESOURCE.TOP_LABS) {
                  filterValue = !dataType ? '' : dataType;
                }
                if(sectionData.resourceCode === ANALYTICS_RESOURCE.TOP_IMAGING) {
                  filterValue = args?.data?.data?.name
                }
                const filterObj = {
                  filterCode: 'CONDITION',
                  filterValue: filterValue ? filterValue : args?.data?.data?.code,
                  count: args?.data?.data?.count,
                  flowType: args?.data?.data?.flowType || '',
                  workflowMasterId: args?.data?.data?.workflowMasterId || ''
                };
                const dateRangeFilterObj = appliedFilter?.find(item => {
                  return item.filterCode === "DATE_RANGE"
                })
                const fromDate = getFormattedDateStringInTimeZone(
                  dateRangeFilterObj?.filterValue?.fromDate || dateRangeFilterObj?.filterValue?.currentDateRange?.fromDate || '',
                  `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
                  MOMENT_DATE_CONST.START_OF
                );
                const toDate = getFormattedDateStringInTimeZone(
                  dateRangeFilterObj?.filterValue?.toDate || dateRangeFilterObj?.filterValue?.currentDateRange?.toDate || '',
                  `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
                  MOMENT_DATE_CONST.END_OF
                );
                const dateRangeFilter = {
                  filterCode: 'DATE_RANGE',
                  filterValue: {
                    fromDate: fromDate,
                    toDate: toDate,
                  }
                }
                filterData.push(filterObj);
                filterData.push(dateRangeFilter);
                filterData = [
                  ...filterData,
                  ...stateData.selectedAppliedFilter,
                ];
                props.onActionClick?.(sectionData.resourceCode || '', {
                  filterData,
                  count: args?.data?.data?.count,
                  type: dataType,
                  date: dateOrMonth
                });
              }
            });
          },
        };
        ele = <Bar {...configGroup} />;
        break;
      case DISPLAY_TYPE.HORIZONTAL_STACK_BAR_VIEW:
        configGroup = {
          data,
          xField: 'count',
          yField: 'name',
          color: graphColorList,
          seriesField: 'type',
          isStack: true,
          autoFit: true,
          tooltip: {
            shared: true,
            customContent : (title: string, data: any[]) => {
              const allMembersColumns: any[] = [];
              data.forEach((singleItem) => {
                allMembersColumns.push(singleItem?.data?.type?.toLowerCase())
              })
              const totalMetaData: any[] = [
                {
                  title: 'Total',
                  columnList: allMembersColumns,
                }
              ];
              if (title && data?.length) {
                return <CustomTooltip title={title} data={data} totalMetaData={totalMetaData} />
              }
            }
          },
          xAxis: {
            label: {
              offset: 20,
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor
              }
            },
            title: {
              text: graphXAxisTitle.title,
              description: graphXAxisTitle.description,
              style: {
                fill: graphMetaDataColor.axisTextColor
              },
              offset: 59
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              }
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#F2F4F7'
                }
              },
              closed: true
            },
            ...(maxValueXValue)
          },
          yAxis: {
            tickLine: {
              style: {
                fill: '#fff',
                stroke: '#fff',
              },
            },
            line: {
              style: {
                stroke: '#F2F4F7',
              }
            },
            grid: {
              alignTick: true,
              line: {
                type: 'line',
                style: {
                  stroke: '#fff'
                }
              },
              closed: true
            },
            label: {
              style: {
                fontSize: 12,
                fontWeight: 400,
                fill: graphMetaDataColor.axisTextColor,
              },
              formatter: (text: string) => {
                // return wordWrapFromChart(text, 50);
                return `${text.substring(0, 40)} ${text?.length < 40 ? '' :'...'}`;
              },
            },
          },
          legend: {
            itemSpacing: 3,
            position: 'top-right',
            layout: 'horizontal',
            itemName: {
              formatter(text, item, index) {
                  return text?.toLocaleUpperCase()
              },
            },
            marker: {
              symbol: 'circle',
              style(style) {
                  return {
                    ...style,
                    r: 4
                  }
              },
            }
          },
          height: 240,
          width: 470,
          interactions: [
            {
              type: 'element-selected',
              enable: true
            },
          ],
          onReady: (plot: any) => {
            plot.on('element:click', (args: any) => {
              if (args?.data?.data?.name) {
                let typeList: string[] = [];
                const type: string = args?.data?.data?.type;
                const dataType = capitalizeText(type);
                const dateOrMonth = data?.data?.data?.date || data?.data?.data?.month
                if (type?.toLocaleLowerCase() === 'employee') {
                  typeList = ['employee']
                } else if (type?.toLocaleLowerCase() === 'dependent') {
                  typeList = ['father', 'spouse', 'child']
                } else {
                  typeList = [type]
                }
                const dateRangeFilterObj = appliedFilter?.find(item => {
                  return item.filterCode === "DATE_RANGE"
                })
                const fromDate = getFormattedDateStringInTimeZone(
                  dateRangeFilterObj?.filterValue?.fromDate || dateRangeFilterObj?.filterValue?.currentDateRange?.fromDate || '',
                  `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
                  MOMENT_DATE_CONST.START_OF
                );
                const toDate = getFormattedDateStringInTimeZone(
                  dateRangeFilterObj?.filterValue?.toDate || dateRangeFilterObj?.filterValue?.currentDateRange?.toDate || '',
                  `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
                  MOMENT_DATE_CONST.END_OF
                );
                let filterData = [{
                  filterCode: 'CONDITION',
                  filterValue: args?.data?.data?.code,
                  count: args?.data?.data?.count
                },
                {
                  filterCode: 'EMPLOYEE_TYPE',
                  filterValue: typeList
                },
                {
                  filterCode: 'DATE_RANGE',
                  filterValue: {
                    fromDate: fromDate,
                    toDate: toDate,
                  },
                  count: data?.data?.data?.value || data?.data?.data?.xField
                }
                ];
                filterData = [...filterData, ...stateData.selectedAppliedFilter]
                props.onActionClick?.(sectionData.resourceCode || '', {
                  filterData,
                  count: args?.data?.data?.count,
                  type: dataType,
                  date: dateOrMonth
                });
              }
            });
          },
        }
        ele = <Bar {...configGroup} />;
        break;
    }
    return ele;
  }

  const barChartView = useMemo(() => {
    return getBarChartViewByType()
  }, [stateData.loading])

  const topDonutView = () => {
    return donutChartViewListView()
  };

  const getStyleForMainBarChart = (): any => {
    // if (sectionData.resourceCode == ANALYTICS_RESOURCE.CHRONIC_RISKS_TREND) {
    //   return {
    //     height: '70%',
    //     overflow: 'auto'
    //   }
    // }
    return {
      // height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    }
  }
  const getSectionByResourceCode = async (appliedFilter?: IAppliedFilter[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let formId = '';
    if (sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION && sectionData?.additionalAttributes?.formId) {
      formId = sectionData?.additionalAttributes?.formId;
    }
    const filterList: any = appliedFilter?.length ? appliedFilter : stateData.appliedFilter
    if (hintMembershipDisabled) {
      filterList.push({
        filterCode: EMPLOYER_REPORT_FILTER_CODES.MEMBERSHIP, 
        filterValue: hintMembershipDisabled
      })
    }
    const response = await getSectionDataByResourceCode(
      employerId,
      sectionData.resourceCode || '',
      filterList,
      formId,
      isAccountDashboard,
      memberType,
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          resourceData: [],
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data && isWidgetDataEmpty(response?.data)) {
      const resourceData = response.data?.totalCount ? response.data : response.data?.result || response.data;
      const formattedData = isTopDiagnosis ? getFormmatedTrendsData(sectionData?.resourceCode || '', resourceData) : resourceData
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: formattedData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
        };
      });
    } else {
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: [] as IResource[],
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };
  const controller = new AbortController();
  useEffect(() => {
    if (getTenantForDemo()) {
      // setStateData((prev) => {
      //   return {
      //     ...prev,
      //     loading: true
      //   }
      // })
      const resourceData: any = getTenantForDemo()
        ? sectionData.resourceCode == ANALYTICS_RESOURCE.CHRONIC_RISKS_TREND
          ? TEMP_CHROMIC_DATA.result
          : isTopImaging
            ? TOP_IMAGING
            : isTopLabs
              ? TOP_LABS
              : isTopCareJourney
                ? TOP_CARE_JOURNEYS
                : isTopMedications
                  ? TOP_MEDICATIONS
                  : isTopSymptoms
                    ? TOP_SYMPTOMS_CHIEF_COMPLAINTS
                    : isTopDiagnosis
                      ? TOP_DIAGNOSIS
                      : isAutomationTimeSaved
                        ? AUTOMATION_TIME_SAVED
                        : {}
        : {};
      const formattedData = isTopDiagnosis ? getFormmatedTrendsData(sectionData?.resourceCode || '', resourceData) : resourceData
      setTimeout(() => {
        setStateData((prev) => {
          return {
            ...prev,
            resourceData: formattedData,
            loading: false
          }
        })
      }, 3000)
      return;
    }
    getSectionByResourceCode();
    return () => controller?.abort();
  }, [sectionData.id]);
  useEffect(() => {
    if (props.filterData?.resourceCode == sectionData.resourceCode) {
      const appliedFilter = stateData.appliedFilter?.length ? [...stateData.appliedFilter] : [];
      const selectedFilter = {
        filterCode: props.filterData?.filterCode,
        filterValue: props.filterData?.filterValue
      }
      let filterValueString = '';
      props.filterData?.filterValueName?.forEach((item: string, index: number) => {
        if (index < 2) {
          return
        }
        filterValueString = filterValueString + (filterValueString.length ? " | " : '') + item
      })
      setStateData(prev => {
        return {
          ...prev,
          filterValueName: props.filterData?.filterValueName,
          filterValueString: filterValueString,
          selectedAppliedFilter: [selectedFilter]
        }
      })
      appliedFilter.push(selectedFilter);
      getSectionByResourceCode(appliedFilter);
    }
    return () => controller?.abort();
  }, [props.filterData?.filterValue])

  const getRefreshButtonOnLocalEnv = () => {
    if (window.location.host.includes('localhost')) {
      return (
        <Pressable
          style={{marginHorizontal: 3}}
          onPress={() => {
            setStateData(prev => {
              return {
                ...prev,
                filterValueName: [],
                filterValueString: ''
              }
            })
            getSectionByResourceCode();
          }}
        >
          <Tooltip label="Refresh">
            <RestSvgIcons />
          </Tooltip>
        </Pressable>
      )
    }
    return <></>;
  }

  return (
    <div className={'widgetBoxShadow'}>
      <WidgetHeader isPrintPreview ={props.isPrintPreview} isPrintDownload={props.isPrintDownload} refreshButtonOnLocalEnv={getRefreshButtonOnLocalEnv()} sectionData={sectionData} onActionClick={onActionClick} />
      {stateData.loading ? (
        <Skeleton style={{padding: 40}} active={true} />
      ) : (
        <>
          {data?.length ? (
            <div
              style={{
                height: '75%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <main
                style={{
                  alignSelf: 'center',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}
              >
                {/* {stateData.donutChartData?.length ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 10,
                      justifyItems: 'center',
                      padding: '0px 20px',
                      borderWidth: 0,
                      borderBottomWidth: 1,
                      borderBottomColor: '#ECECEC',
                      borderStyle: 'solid',
                      paddingBottom: 20,
                      width: '100%'
                    }}
                  >
                    {topDonutView()}
                  </div>
                ) : (
                  <></>
                )} */}

                  <main style={{
                alignSelf: 'center',
                width: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                height: '100%'
                }}>
                  <main
                    style={{padding: '10px 0px', width: '88%'}}
                    className={
                      stateData.donutChartData && props.isPrintPreview
                        ? 'canvas-wrapper'
                        : ''
                    }
                  >
                    {barChartView}
                  </main>
                  </main>
              </main>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '60%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NodataViewTypeOne viewCode={'TEXT_ICON'} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default React.memo(TrendsHorizBarWidget);
