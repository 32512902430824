import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles/Colors';
export const styles = StyleSheet.create({
  flagContainer: {
    padding: 5,
  },
  numberContainer: {
    marginLeft: 12,
  },
  assigneeName: {
    marginTop: 0,
    width: 175,
  },
  divider: {
    borderColor: Colors.Custom.Gray200,
    borderWidth: 0.5,
  },
  titleWrapper: {},
  container: {},
  textContainer: {
    fontWeight: '400',
    fontSize: 24,
    color: '#667085',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftText: {
    fontSize: 18,
    lineHeight: 32,
    fontWeight: '500',
    marginLeft: 16,
  },
  conversationContainer: {
    borderColor: Colors.Custom.Gray200,
    borderWidth: 1,
    borderTopWidth: 0,
    overflow: 'hidden',
  },
  msgWindow: {
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: Colors.Custom.ContainerBGColor,
    borderColor: Colors.Custom.Gray200,
    borderTopWidth: 0,
    borderBottomWidth:0
  },
  membersWindow: {
    borderWidth: 1,
    backgroundColor: Colors.Custom.ContainerBGColor,
    borderColor: Colors.Custom.Gray200,
    paddingHorizontal: 8,
    borderTopWidth: 0,
    borderLeftWidth: 0
  },
  noDataFound: {
    backgroundColor: Colors.Custom.ContainerBGColor,
    borderColor: Colors.Custom.BorderColor,
  },
  filterSlider: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
  },
});

export const getConversationStyles = ({
  isSidecarContext,
}: {
  isSidecarContext: boolean | undefined;
}) => {
  return StyleSheet.create({
    conversationContainer: {
      borderColor: Colors.Custom.Gray200,
      borderTopWidth: 0,
      overflow: 'hidden',
      borderWidth: isSidecarContext ? 0 : 1,
    },
    msgWindow: {
      borderLeftWidth: 0,
      backgroundColor: Colors.Custom.ContainerBGColor,
      borderColor: Colors.Custom.Gray200,
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderWidth: isSidecarContext ? 0 : 1,
    },
    conversationFilterContainer: {
      flex: 1,
    },
    noDataFound: {
      backgroundColor: Colors.Custom.ContainerBGColor,
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderColor: Colors.Custom.Gray200,
    },
    noDataFoundImg: {
      width: 240,
      height: 83,
    },
    pageBodyContainer: {
      marginHorizontal: 0,
    },
    selectConversationContainer: {
      height: 49,
      overflow: 'hidden',
      borderBottomWidth: 1,
      borderBottomColor: Colors.Custom.Gray200,
      borderLeftWidth: 1,
      borderLeftColor: Colors.Custom.Gray200,
    },
    rightSideContainerView: {
      flex: 1,
      overflow: 'hidden',
    },
    rightSideContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  });
};
