import {StyleSheet} from 'react-native';
import { Colors } from '../../../../styles';


export const styles = StyleSheet.create({
  subtitleStyle: {
    paddingLeft: 1.5,
  },

  templateSectionCard: {
    padding: 8,
    backgroundColor: Colors.FoldPixel.GRAY50,
    borderWidth: 0.5,
    borderRadius: 8,
    borderColor: Colors.FoldPixel.GRAY150,
    width: '100%',
  },

  templateSectionInput: {
    borderWidth: 0.5,
    borderColor: Colors.FoldPixel.GRAY200,
    // backgroundColor: 'white'
  },

  templateSectionInputOnFocus: {
    borderColor: Colors.FoldPixel.PRIMARY_FOCUS,
  },

  tableContent: {
    fontWeight: '400',
    lineHeight: 16.8,
    fontSize: 14,
    color: Colors.FoldPixel.GRAY300,
  },

  accessRoleCardStyle: {
    gap: 4,
    borderRadius: 4,
    backgroundColor: Colors.FoldPixel.GRAY50,
    width: 'fit-content',
    flexDirection: 'row',
    alignItems: 'center',
  },

  scrollView: {
    borderColor: Colors.FoldPixel.GRAY150,
    borderWidth: 0.5,
    borderRadius: 4,
    padding: 8,
  },
  rightButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  divider: {
    width: 1.5,
    height: 20,
    marginHorizontal: 8,
    backgroundColor: Colors.Custom.Gray400,
  },
  viewStyle: {
    height: '100%',
  },
});

export const antStyles: Record<string, React.CSSProperties> = {
  dividerStyle: {
    backgroundColor: Colors.FoldPixel.GRAY50,
    alignSelf: 'center',
    height: 16,
  },
  buttonStyle: {
    width: 156,
    height: 32,
  },
  antdModalStyle: {
    padding: 0,
    textAlign: 'center',
  }
};
