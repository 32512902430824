


import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Drawer, Form, Select, Space, Table } from 'antd';
import 'antd/dist/antd.css';
import { HStack, Icon, IconButton, Input, useToast } from 'native-base';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { BUTTON_TYPE, MLOV_CATEGORY } from '../../../constants';
import { CommonDataContext } from '../../../context/CommonDataContext';
import CommunicationTrackRecordsQuerie from '../../../services/CommunicationTrackRecords/CommunicationTrackRecordsQuerie';
import { getAccountUUID } from '../../../utils/commonUtils';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import { getMlovListFromCategory } from '../../../utils/mlovUtils';
import { FHAlertDialog } from '../../common/FHAlertDialog';
import USFlagSVG from '../../common/Svg/FlagIcon/USFlagSVG';
import { formatPhoneNumber } from '../CloudTelephony/ActiveNumbersTable/SelectPatientCallDrawer';


interface CommunicationTrackRecord {
  id: string;
  value: string;
}

const CommunicationWhiteListView: React.FC<{ searchString:string,mode: string, addOrUpdateState:any, setAddOrUpdateState:any }> = (props) => {
  const columnValueDisplayName = props.mode == 'SMS' ? 'Phone Number' : 'Email';
  const [searchString, setSearchString] = useState(props.searchString||'');
  const [communicationTrackRecords, setCommunicationTrackRecords] = useState<CommunicationTrackRecord[]>([]);
  const [isLoading, setIsLoading] = useState(true)
  const toast = useToast();
  const inputRef = useRef(null);

  const [pagination, setPagination] = useState<any>({
    pageSize: 8, // Number of items per page
    current: 1, // Current page number
    total: 0,
    position: ['bottomCenter'],
    onChange: (page: number, pageSize: number) => {
      setPagination((pageInfo: any) => {
        return { ...pageInfo, pageSize: pageSize, current: page }
      })
    } // Total number of items
  });

  const [isRecordPresent] = useLazyQuery(
    CommunicationTrackRecordsQuerie.FETCH_COMMUNICATION_TRACK_RECORDS,
    {
      fetchPolicy: 'no-cache'
    }
  );
  const [getCommunicationTrackRecords] = useLazyQuery(
    CommunicationTrackRecordsQuerie.FETCH_COMMUNICATION_TRACK_RECORDS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        setIsLoading(false);
        setPagination((pageInfo: any) => {
          return { ...pageInfo, total: data?.communicationTrackRecordsAggregate?.aggregate?.count || 0 }
        })
        setCommunicationTrackRecords(data.communicationTrackRecords || []);
        // setIsOpen(true);
      },
      onError: (error) => {
        showToast(toast, 'Something went wrong', ToastType.error, 4000);
        setIsLoading(false);
        setCommunicationTrackRecords([])
        // setIsOpen(true);
      },
    }
  );

  const [deleteCommunicationTrackRecords] = useMutation(
    // ContactsQueries.SEARCH_CONTACTS_WITH_CONTACT_TYPE,
    CommunicationTrackRecordsQuerie.DELETE_COMMUNICATION_TRACK_RECORDS,
    {
      onCompleted: (data: any) => {
        setDeleteConfirmationState({ isDelete: false, record: { id: '', value: '' } })
        setIsLoading(false);
        refreshData()
        // setIsOpen(true);
      },
      onError: (error) => {
        showToast(toast, 'Something went wrong', ToastType.error, 4000);
        setIsLoading(false);
        refreshData()
        // setIsOpen(true);
      },
    }
  );

  const [addOrUpdateCommunicationTrackRecords] = useMutation(
    // ContactsQueries.SEARCH_CONTACTS_WITH_CONTACT_TYPE,
    CommunicationTrackRecordsQuerie.ADD_OR_UPDATE_COMMUNICATION_TRACK_RECORDS,
    {
      onCompleted: (data: any) => {
        if (addOrUpdateState.type == 'add') {
          showToast(toast, 'Record Added Successfully', ToastType.success, 4000);
        } else {
          showToast(toast, 'Record Updated Successfully', ToastType.success, 4000);
        }

        props.setAddOrUpdateState((addOrUpdateState: any) => {
          return { ...addOrUpdateState, type: '', value: '', id: '', isOpen: false, isError: false }
        });
        setIsLoading(false);
        refreshData()
        // setIsOpen(true);
      },
      onError: (error) => {
        showToast(toast, 'Something went wrong', ToastType.error, 4000);
        setIsLoading(false);
        refreshData()
        // setIsOpen(true);
      },
    }
  );


  const commonData = useContext(CommonDataContext);
  const preferredCommunicationType = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.PREFERRED_COMMUNICATION_CHANNEL
  );
  const preferredCommunicationTypeList = preferredCommunicationType.filter((mlov) => { return mlov.code === props.mode });
  const preferredCommunicationTypeId = preferredCommunicationTypeList[0]?.id;
  if (!preferredCommunicationTypeId) {
    return <></>
  }


  const refreshData = (searchStringParam?:string) => {
    let searchStr = searchStringParam != undefined ? searchStringParam : (searchString|| '');
    searchStr = props.mode == 'SMS' ? searchStr.replace(/[^0-9]/g, '') : searchStr;
    const searchStringValue = `%${searchStr}%`
    setIsLoading(true);
    getCommunicationTrackRecords({
      variables: {
        value: searchStringValue,
        communicationModeId: preferredCommunicationTypeId,
        limit: pagination.pageSize,
        offset: (pagination.current - 1) * pagination.pageSize
      }
    });
  }

  useEffect(() => {
    refreshData()
  }, [pagination.current, pagination.pageSize])


  const addOrUpdateState = props.addOrUpdateState;


  const isValidateEmail = (email: string): boolean => {
    // Regular expression for a valid email address
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Use the test method to check if the email matches the regex pattern
    return emailRegex.test(email);
  };
  const isValidPhoneNumber = (newPhoneNumber: any) => {
    newPhoneNumber = newPhoneNumber || '';
    if (newPhoneNumber.trim() === '') {
      return false
    }

    const phonePattern = /^\d{10}$/;

    if (!phonePattern.test(newPhoneNumber)) {
      // Invalid phone number format


      return false
    }

    const parsedNumber = parseFloat(newPhoneNumber);

    if (isNaN(parsedNumber)) {
      // Invalid numeric value


      return false
    }

    return true;

  }

  const isValidInputValue = (text: string) => {
    if (props.mode == 'SMS') {
      return isValidPhoneNumber(text)
    } else {
      return isValidateEmail(text)
    }
  }

  const accountUuid = getAccountUUID();
  const handleAddPhoneNumber = async () => {
    if (addOrUpdateState?.value?.trim() === '') return;
    if (!isValidInputValue(addOrUpdateState?.value)) {
      // Invalid phone number format
      props.setAddOrUpdateState((addOrUpdateState: any) => {
        return { ...addOrUpdateState, isError: true }
      });

      return;
    }


    setIsLoading(true);
    const record = await isRecordPresent({
      variables: {
        value: addOrUpdateState?.value?.toLowerCase(),
        communicationModeId: preferredCommunicationTypeId,
        limit: 1,
        offset: 0
      }
    });
    if (record?.data?.communicationTrackRecordsAggregate?.aggregate?.count && record?.data?.communicationTrackRecords[0]?.id != addOrUpdateState?.id) {
      showToast(toast, 'Duplicate Record', ToastType.error, 4000);
      setIsLoading(false);
      props.setAddOrUpdateState((addOrUpdateState: any) => {
        return { ...addOrUpdateState, isError: true }
      });
      return;
    }

    if (addOrUpdateState.type == 'add') {
      addOrUpdateCommunicationTrackRecords({
        variables: {
          record: {
            value: addOrUpdateState?.value?.toLowerCase(),
            communicationModeId: preferredCommunicationTypeId,
            accountUuid: accountUuid
          }
        }
      })
    } else {
      setIsLoading(true);
      addOrUpdateCommunicationTrackRecords({
        variables: {
          record: {
            id: addOrUpdateState.id,
            value: addOrUpdateState?.value?.toLowerCase(),
            communicationModeId: preferredCommunicationTypeId,
            accountUuid: accountUuid
          }
        }
      })
    }

  };
  const [deleteConfirmationState, setDeleteConfirmationState] = useState({
    isDelete: false,
    record: {
      id: '',
      value: ''
    }
  })
  const handleRemoveRecord = (id: string) => {
    setIsLoading(true);
    deleteCommunicationTrackRecords({
      variables: {
        id: id
      }
    })
  };

  useEffect(()=>{
    refreshData(props.searchString)
  }, [props.searchString])




  const countryCodeColumn = [
    {
      title: 'Country',
      dataIndex: 'value',

      key: 'value',
      width: '10%',
      render: (text: string, record: any) => {
        return <><USFlagSVG /> {' +1'}</>
      },
    }
  ]

  const getFormattedValue = (text: any) => {
    text = text || '';
    return props.mode == 'SMS' ? formatPhoneNumber(text) : text

  }

  const columns = [
    ...(props.mode == 'SMS' ? countryCodeColumn : []),
    {
      title: columnValueDisplayName,
      dataIndex: 'value',
      key: 'value',
      width: '50%',
      render: (text: string, record: any) => {
        return <HStack space={3}>{getFormattedValue(text)}</HStack>
      },
    },
    {
      title: 'Actions',
      width: '30%',
      key: 'actions',
      render: (text: string, record: any) => (
        <Space size="middle">

          <>
            <IconButton
              colorScheme="gray"
              key={record.id + 'edit'}
              variant="ghost"
              onPress={() => {
                props.setAddOrUpdateState((addOrUpdateState: any) => {
                  return { ...addOrUpdateState, value: record?.value, isError: false, id: record?.id, type: 'update', isOpen: true }
                });
              }}
              icon={<Icon as={AntIcon} name="edit" size="4" color="gray.400" />}
            />

            <IconButton
              colorScheme="gray"
              tintColor="gray.500"
              key={record.id + 'delete'}
              onPress={() => {
                setDeleteConfirmationState((state: any) => {
                  return { isDelete: true, record: record }
                });

              }}
              variant="ghost"
              icon={
                <Icon as={AntIcon} name="delete" size="4" color="gray.400" />
              }
            />

          </>

        </Space>
      ),
    },
  ];

  const data: any = communicationTrackRecords.map((phoneNumber) => ({
    id: phoneNumber.id,
    value: phoneNumber.value,
  }));


  return (
    <div>
      <Drawer
        visible={
          addOrUpdateState.isOpen
        }
        width={400}
        closable={true}
        onClose={() => {
          props.setAddOrUpdateState((addOrUpdateState: any) => {
            return { ...addOrUpdateState, isOpen: false, value: '', id: '', type: '', isError: false }
          })
        }}
        extra={
          <Space>
            <Button onClick={() => {
              props.setAddOrUpdateState((addOrUpdateState: any) => {
                return { ...addOrUpdateState, isOpen: false, value: '', id: '', type: '', isError: false }
              })
            }}>Cancel</Button>
            <Button loading={isLoading} type="primary" onClick={handleAddPhoneNumber}>
              {addOrUpdateState?.type == 'add' ? 'Save' : 'Update'}
            </Button>
          </Space>
        }
      >
        <HStack space={3} justifyContent={'flex-start'} alignItems={'center'} flex={1}>
          <HStack justifyContent={'flex-start'} alignItems={'center'} marginTop={'17px'} flex={1}>
            <Form.Item
              style={{ flex: 1 }}
              validateStatus={addOrUpdateState.isError ? 'error' : ''}
              help={addOrUpdateState.isError ? props.mode == 'SMS' ? 'Please enter a valid 10-digit phone number.' : 'Please enter a valid email.' : ''}
            >

              <HStack pt={5} >
                {
                  props?.mode == 'SMS' &&
                  <Select
                    style={{
                      height: 39,
                      fontSize: 14,
                      lineHeight: 21,
                      fontWeight: 500,
                      width: 110,
                      alignSelf: 'center',
                      alignContent: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    value={'+1'}

                  >
                    <Select.Option
                      key={'+1'}
                      value={'+1'}
                    >
                      <HStack>
                        <HStack marginTop={1} paddingRight={1}><USFlagSVG /></HStack>
                        <HStack> {'+1'}</HStack>
                      </HStack>
                    </Select.Option>
                  </Select>}
                <Input
                  marginLeft={2}
                  placeholder={`Enter ${columnValueDisplayName}`}
                  ref={inputRef}
                  keyboardType={props.mode == 'SMS' ? 'numeric' : 'email-address'}
                  width={props.mode == 'SMS' ? '77.5%' : '100%'}
                  fontSize={14}
                  lineHeight={21}
                  size={10}
                  fontWeight={500}
                  borderRadius={6}
                  textAlign={'center'}
                  value={getFormattedValue(addOrUpdateState.value)}

                  onChangeText={(text) => {
                    text = text || '';
                    const valueInput = props.mode == 'SMS' ? text.replace(/[^0-9]/g, '') : text;
                    if (valueInput?.length <= 10 || props.mode != 'SMS') {
                      props.setAddOrUpdateState((addOrUpdateState: any) => {
                        return { ...addOrUpdateState, value: valueInput }
                      })
                      if (isValidInputValue(valueInput)) {
                        props.setAddOrUpdateState((addOrUpdateState: any) => {
                          return { ...addOrUpdateState, isError: false }
                        })
                      }
                    }
                  }}
                />
              </HStack>
            </Form.Item>
          </HStack>

        </HStack>
      </Drawer>

      <FHAlertDialog
        isOpen={deleteConfirmationState.isDelete}
        header={'Delete'}
        message={`Are you sure you want to delete the record with the ${columnValueDisplayName} ${getFormattedValue(deleteConfirmationState?.record?.value || '')}?`}
        buttonActions={[
          {
            textLocalId: 'No',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY,
              isDisabled: isLoading,
            },
            onPress: () => {
              setDeleteConfirmationState(() => {
                return {
                  record: {
                    id: '',
                    value: ''
                  }, isDelete: false
                }
              })
            },
          },
          {
            textLocalId: 'Yes',
            textColor: 'white',
            buttonProps: {
              variant: BUTTON_TYPE.PRIMARY,
              isLoading: isLoading,
              isDisabled: isLoading,
              _loading: {

              },
            },
            onPress: () => {
              handleRemoveRecord(deleteConfirmationState.record?.id)
            },
          },
        ]}
      />
      <Table rowClassName={(record, index) =>
        index % 2 == 0 ? 'table-row-light' : ''
      } loading={isLoading && !addOrUpdateState?.isOpen} pagination={pagination} columns={columns} dataSource={data} />
    </div>
  );
};





export default CommunicationWhiteListView;
