import { StyleSheet } from 'react-native';
import { Colors } from '../../../../styles/Colors';

export const styles = StyleSheet.create({
    header:{
        alignItems:'center',
        flexDirection:'row',
        padding: 16,
        paddingVertical: 12,
        borderBottomWidth: 1,
        borderBottomColor: Colors.Custom.Gray200,
        justifyContent: 'space-between',
    },
    headerLeftContainer:{
        flexDirection:'row',
        alignItems:'center',
    },
    headerRightContainer:{
        flexDirection:'row',
        alignItems:'center',
        flex:1,
        justifyContent:'flex-end'
    },
    datePickerContainer :{
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 12,
        borderWidth: 1,
        borderColor: '#e4e7ec',
        borderRadius: 8,
        backgroundColor: '#fff',
        overflow: 'hidden',
        height: 40
    },

});
