import { PieConfig } from '@ant-design/charts';
import { useLazyQuery } from '@apollo/client';
import { View } from 'native-base';
import { useEffect, useState } from 'react';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { FormsQueries, LeadQueries, ScheduleQueries } from '../../../../services';
import ContactsQueries from '../../../../services/Contacts/ContactsQueries';
import EmployerQueries from '../../../../services/Employer/EmployerQueries';
import WorkflowExecutionLogQueries from '../../../../services/WorkflowExecutionLog/WorkflowExecutionLogQueries';
import {
  getAccountId,
  getAccountUUID,
  getUserEmployerId
} from '../../../../utils/commonUtils';
import { GraphMemberList } from '../../../common/AppDashboard/GraphMemberList';
import { GraphConfig } from '../../../common/AppDashboard/GraphUtils';
import { AnalyticsWidget } from '../AnalyticsWidget';
import { AppointmentTypeGraphController } from './AppointmentTypeGraphUtils';
import { AppointmentTypeGraphState } from './interfaces';

const DisplayAppointmentTypeGraph = (props: {
  appointmentTypes: any;
  patientIdList: any;
  employerId?: string;
  isDashboard?: boolean;
}) => {

  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 1;
  const [contactsState, setContactsState] = useState({
    isLoading: false,
    membersData: [] as any,
    page: DEFAULT_PAGE,
    isOpen:false,
    typeId:'',
    pageSize: DEFAULT_PAGE_SIZE,
    total: DEFAULT_PAGE_SIZE,
    openContactListDrawer: false,
    contactUuidList: [],
  });

  if (!props.appointmentTypes.length) {
    return <></>
  }
  const [graphData, setGraphData] = useState([]);
  let finalQuery = `
  query aggregateAppointments($where1: appointment_bool_exp) {
    aggregateAppointments(where: $where1) {
      aggregate {
        count
      }
    }
  }
  `;

  let queryParam = `query aggregateAppointments(`;
  let queryBody = ``;
  const variables: any = {};
  (props.appointmentTypes || []).forEach(
    (appointmentType: any, index: number) => {
      queryParam = queryParam + `$where_${index}: appointment_bool_exp,`;
      const key = `where_${index}`;
      variables[key] = {
        appointmentTypeId: { _eq: appointmentType.id },
      };
      if (props.patientIdList?.length || props.employerId) {
        variables[key]['participants'] = { contactId: { _in: props.patientIdList } };
      }
      queryBody =
        queryBody +
        `appointmentType${index}:aggregateAppointments(where: $where_${index}) {
        aggregate {
          count
        }
      }`;
    }
  );

  finalQuery = queryParam + '){' + queryBody + '}';
  const gplAggregateAppointments =
    WorkflowExecutionLogQueries.getDynamicQuery(finalQuery);
  const [aggregateAppointments] = useLazyQuery(gplAggregateAppointments, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const [getContactIdByPatientUUIDAndAppointId] = useLazyQuery(
    FormsQueries.GET_CONTACT_UUID_FROM_PATIENT_ID_AND_APPOINT_TYPE_ID,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );

  const [getPatientsFromIds] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_BY_CONTACT_UUIDS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {

    aggregateAppointments({
      variables,
    }).then((response: any) => {
      const graphData: any = [];
      props.appointmentTypes.forEach((appointmentType: any, index: number) => {
        const key = `appointmentType${index}`;
        const count = response?.data[key]?.aggregate?.count;
        graphData.push({
          typeId: appointmentType.id,
          value: count,
          type: appointmentType.eventName,
        });
      });
      setGraphData(graphData);
    });
  }, [props.employerId]);

  const getContactIdsList = (appointmentTypeId: any) => {


    const variables: any = {
      offset: (contactsState.page - 1) * contactsState.pageSize,
      limit: contactsState.pageSize,
      where: {
        contactId: {_is_null: false},
        appointment: {
          appointmentTypeId: {
            _eq: appointmentTypeId,
          },
        },
      },
    }

    if (props.patientIdList?.length || props.employerId) {
      variables.where.contactId = { _in: props.patientIdList };
    }


    getContactIdByPatientUUIDAndAppointId({
      variables: variables,
    }).then((response: any) => {
      if (response && response.data && response.data.appointmentParticipants) {
        const contactIdsList = response.data.appointmentParticipants;
        const contactUUIDArray = [] as any;
        contactIdsList.map((item: any) => {
          contactUUIDArray.push(item?.contactId);
        });
        setContactsState((prev) => {
          return {
            ...prev,
            total: response?.data?.aggregateAppointmentParticipants?.aggregate?.count || 0,
            contactUuidList: contactUUIDArray,
            openContactListDrawer: true,
          };
        });
        // getParticipantContactData(contactUUIDArray)
      }
    });
  };

  const [config, setConfig] = useState<any>();

  useEffect(()=>{
    const config: PieConfig = {
      data: graphData,
      angleField: 'value',
      colorField: 'type',
      ...GraphConfig,
      onReady: (plot: any) => {
        plot.on('element:click', (args: any) => {
          const typeId = args.data.data.typeId;
          setContactsState((prev)=>{
            return  {
              ...prev,
              typeId:typeId,
              isOpen:true
            }
          });


        });
      },
    };
    setConfig(config)
  },[graphData])




  useEffect(()=>{
    if(contactsState.isOpen && contactsState.typeId){
      getContactIdsList(contactsState.typeId);
    }
  },[contactsState.typeId, contactsState.isOpen, contactsState.page, contactsState.pageSize ])

  return (
    <>
      {config ? (
        <AnalyticsWidget
          pieConfig={{
            ...config,
          }}
          height="358px"
          legend={{
            position: 'bottom',
            layout: 'horizontal',
            maxRow: 3,
            title: {
              title: undefined,
              spacing: 10,
              style: { fontSize: 0, lineWidth: 0, lineHeight: 0 },
            },
          }}
          title="Appointment Types"
          subtitle="Breakdown by Appointment Types"
        />
      ) : (
        ''
      )}


      {contactsState.openContactListDrawer && (
        <GraphMemberList
          page={contactsState.page}
          pageSize={contactsState.pageSize}
          total={contactsState.total}
          onPaginationChange={(page: number, pageSize: number) => {
            setContactsState((prev) => {
              return {
                ...prev,
                page,
                pageSize
              }
            });
          }}
          title={'Appointment Types'}
          isOpen={contactsState.openContactListDrawer}
          contactUuidList={contactsState.contactUuidList}
          onCompleteAction={() => {
            setContactsState((prev) => {
              return {
                ...prev,
                page: DEFAULT_PAGE,
                isOpen:false,
                pageSize: DEFAULT_PAGE_SIZE,
                openContactListDrawer: false,
                contactUuidList: [],
              };
            });
          }}
        />
      )}
    </>
  );
};

const AppointmentTypeGraph = (props: {
  employerId?: string;
  isDashboard?: boolean;
  setIsShowAppointmentTypesGraph?:any
}) => {
  const employerId = getUserEmployerId() || props.employerId;
  const [state, setState] = useState<AppointmentTypeGraphState>({
    loading: false,
    patientUuidList: [],
    appointmentTypes: [],
    graphData: [],
    selectedAppointmentTypeId: '',
    contactList: [],
    page: 1,
    employerId: employerId,
    pageSize: 15,
    listLoading: false,
  });

  const tenantId = getAccountUUID();
  const accountId = getAccountId();

  const [getAppointmentTypes] = useLazyQuery(
    ScheduleQueries.GET_APPOINTMENT_TYPES,
    {
      variables: {
        tenantId: tenantId,
      },
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    }
  );

  const [getContactUuidsByAppointmentTypes] = useLazyQuery(
    ScheduleQueries.GET_CONTACT_UUIDS_BY_APPOINTMENT_TYPES,
    {
      variables: {
        tenantId: tenantId,
        appointmentTypeId: state.selectedAppointmentTypeId,
      },
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
    }
  );

  const [getOnlyContactUuidByEmployerBoolExp] = useLazyQuery(
    EmployerQueries.GetOnlyContactUuidByEmployerBoolExp,
    {
      variables: {
        where: AppointmentTypeGraphController.getFilterQuery({
          accountUuid: tenantId,
          employerId,
        }),
      },
      fetchPolicy: 'no-cache',
    }
  );

  const [getContactsByUuid] = useLazyQuery(LeadQueries.getContactsByUuid, {
    fetchPolicy: 'no-cache',
  });

  const {
    onMount,
    getAppointmentTypeList,
    handleSelectedAppointmentType,
    setSelectedAppointmentTypeId,
  } = new AppointmentTypeGraphController({
    state,
    employerId,
    accountId,
    setState,
    getAppointmentTypes,
    getOnlyContactUuidByEmployerBoolExp,
    getContactUuidsByAppointmentTypes,
    getContactsByUuid,
    setIsShowAppointmentTypesGraph:props.setIsShowAppointmentTypesGraph
  });

  useEffect(() => {
    if (!state.appointmentTypes.length) {
      onMount();
    }
  }, [props.employerId]);



  // useEffect(() => {
  //   getAppointmentTypeList();
  // }, []);

  useEffect(() => {
    handleSelectedAppointmentType();
  }, [state.selectedAppointmentTypeId]);

  return (
    <View flex={1} key={employerId}>
      {state.appointmentTypes.length && (employerId ? !state.loading : true) ? (
        <DisplayAppointmentTypeGraph

          appointmentTypes={state.appointmentTypes}
          patientIdList={state.patientUuidList}
          employerId={employerId}
        ></DisplayAppointmentTypeGraph>
      ) : (
        ''
      )}
    </View>
  );
};

export default AppointmentTypeGraph;
