import React from 'react';
import SingleContactColumnView from './CommonColumnRenderer/SingleContactColumnView';
import {DRILL_DOWN_RESOURCE_CODE} from '../../EmployerUtils/EmployerConst';
import LastAppointmentDetails from './CommonColumnRenderer/LastAppointmentDetails';
import ChronicConditions from './CommonColumnRenderer/ChronicConditions';
import LastCommunicationDetails from './CommonColumnRenderer/LastCommunication/LastCommunicationDetails';
import ClaimCost from './CommonColumnRenderer/ClaimCost';
import {IContact} from '../../../../TeamInbox/Conversations/interfaces';
import CommunicationTypeSavings from './CommonColumnRenderer/CommunicationTypeSavings';
import {Text} from 'native-base';
import {Colors} from '../../../../../../styles';
const CommonColumnRenderer = (props: {
  columnData: any;
  rowRecordData: any;
  columnConfig: any;
  contactDrillDownData: any;
  isOnFirstView: boolean;
  filterData?: any;
  onDetailsActionPerformed?: (value: any, selectedFilter?: any) => void;
  onColumnActionPerformed?: (actionCode: string, actionData?: any) => void;
}) => {
  const {isOnFirstView, columnData, columnConfig, rowRecordData, contactDrillDownData, filterData, onDetailsActionPerformed, onColumnActionPerformed} = props;
  const contactData: IContact = rowRecordData?.contact || {} as any;
  const getColumnValueRenderer = () => {
    if (isOnFirstView) {
      switch (columnConfig?.columnKey) {
        case DRILL_DOWN_RESOURCE_CODE.VOICE_CALL:
        case DRILL_DOWN_RESOURCE_CODE.VIDEO_CALL:
        case DRILL_DOWN_RESOURCE_CODE.APP_CHAT:
        case DRILL_DOWN_RESOURCE_CODE.FORM_COUNT:
        case DRILL_DOWN_RESOURCE_CODE.TASK_COUNT:
        case DRILL_DOWN_RESOURCE_CODE.APPOINTMENT_COUNT:
        case DRILL_DOWN_RESOURCE_CODE.VIDEO_CALL_DURATION:
        case DRILL_DOWN_RESOURCE_CODE.CALLS_COUNTS:
        case DRILL_DOWN_RESOURCE_CODE.COUNT:
           {
          return (
            <Text color={Colors.Custom.Gray500}>{columnData?.countNum || '--'}</Text>
          );
        }
        case DRILL_DOWN_RESOURCE_CODE.CONTACT: {
          return (
            <SingleContactColumnView
              contactData={columnData}
              contactDrillDownData={contactDrillDownData}
              onViewDetailsClick={onDetailsActionPerformed}
            />
          );
        }
        case DRILL_DOWN_RESOURCE_CODE.APPOINTMENT: {
          return (
            <LastAppointmentDetails
              columnData={columnData}
              contactData={contactData}
              columnConfig={columnConfig}
              contactDrillDownData={contactDrillDownData}
              isOnFirstView={isOnFirstView}
              filterData={filterData}
              onColumnActionPerformed={onColumnActionPerformed}
            />
          );
        }
        case DRILL_DOWN_RESOURCE_CODE.COMMON_COMMUNICATION: {
          return (
            <LastCommunicationDetails
              columnData={columnData}
              contactData={contactData}
              columnConfig={columnConfig}
              contactDrillDownData={contactDrillDownData}
              isOnFirstView={isOnFirstView}
              filterData={filterData}
              onColumnActionPerformed={onColumnActionPerformed}

            />
          );
        }
        case DRILL_DOWN_RESOURCE_CODE.CHRONIC_CONDITIONS: {
          return (
            <ChronicConditions
              columnData={columnData}
              contactData={contactData}
              columnConfig={columnConfig}
              contactDrillDownData={contactDrillDownData}
              isOnFirstView={isOnFirstView}
              filterData={filterData}
            />
          );
        }
        case DRILL_DOWN_RESOURCE_CODE.CLAIMS: {
          return (
            <ClaimCost
              columnData={columnData}
              contactData={contactData}
              columnConfig={columnConfig}
              contactDrillDownData={contactDrillDownData}
              isOnFirstView={isOnFirstView}
              filterData={filterData}
              onColumnActionPerformed={onColumnActionPerformed}
            />
          );
        }

        case DRILL_DOWN_RESOURCE_CODE.OFFICE_HOURS_SAVINGS:
          case DRILL_DOWN_RESOURCE_CODE.OUT_OF_OFFICE_HOURS_SAVINGS:
            return (
              <CommunicationTypeSavings
                columnData={columnData}
                contactData={contactData}
                columnConfig={columnConfig}
                contactDrillDownData={contactDrillDownData}
                isOnFirstView={isOnFirstView}
                filterData={filterData}
                onColumnActionPerformed={onColumnActionPerformed}
              />
            );
      }
    } else {
      return (
        <SingleContactColumnView
          contactData={columnData}
          contactDrillDownData={contactDrillDownData}
          onViewDetailsClick={onDetailsActionPerformed}
        />
      )
    }
  };

  return (
    <>
      {getColumnValueRenderer()}
    </>

  );
}
export default React.memo(CommonColumnRenderer);
