import {StyleSheet} from 'react-native';


export const styles = StyleSheet.create({
  mainWrapper: {
    minWidth: 200,
    // borderRadius: 8,
    // borderColor: Colors.Custom.Gray100,
    // boxShadow:
    //   '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
    //   paddingVertical: 16,
    // //   margin: 50
  },
  actionListWrapper: {
    // paddingHorizontal: 16,
  }
});
