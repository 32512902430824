import { useQuery } from '@apollo/client';
import { Drawer, notification } from 'antd';
import { Divider } from 'native-base';
import { useContext, useState } from 'react';
import { Text, View } from 'react-native';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../constants/Configs';
import { FHIR_RESOURCE } from '../../constants/FhirConstant';
import {
  MLOV_CATEGORY,
  USER_PREFERENCE_CODE
} from '../../constants/MlovConst';
import { CommonDataContext } from '../../context/CommonDataContext';
import useEHRCapabilities from '../../screens/BusinessStudio/useEHRCapabilities';
import { FormsQueries } from '../../services';
import { Colors } from '../../styles';
import { getEHRName, getResourceAbilities } from '../../utils/capabilityUtils';
import {
  getCarePlanProps,
  getUserUUID,
  isPhysicianRole
} from '../../utils/commonUtils';
import { EventBus } from '../../utils/EventBus';
import {
  getMlovIdFromCode,
  getMlovListFromCategory
} from '../../utils/mlovUtils';
import CarePlan from '../PersonOmniView/MiddleContainer/CarePlan/CarePlan';
import { ICarePlanProps } from '../PersonOmniView/MiddleContainer/CarePlan/interfaces';
import { NOTES_EVENTS } from '../common/AddNoteView/NotesConst';
import Stack from '../common/LayoutComponents/Stack';
import CrossIcon from '../common/Svg/CrossIcon';
import TimeLineSkeletonLoader from '../common/TimeLineSkeletonLoader/TimeLineSkeletonLoader';
import AddOrUpdatePatientNote from '../PersonOmniView/MiddleContainer/CareTimeline/AddOrUpdatePatientNote';
import {
  getEhrConfig,
  getUserDetailByUserUuid
} from '../PersonOmniView/MiddleContainer/CareTimeline/CareTimelineUtils';
import {
  IPatientNoteCardProps,
  ViewType
} from '../PersonOmniView/MiddleContainer/interfaces';
import { DocStatus } from '../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { IContact } from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import { SidecarPatientInfoBanner } from './SidecarPatientInfoBanner';

interface ISidecarAddNotesDrawerProps {
  contactData: IContact | undefined;
  formattedContactData: IContact;
  onClose: () => void;
  onSaveNote: (noteData: {id: string; docStatus: string}) => void;
  noteData: IPatientNoteCardProps;
  isNewNote: boolean;
  accountUserList: any[];
}

interface ISidecarAddNotesDrawerState {
  defaultTemplate?: {
    formId: string;
    id?: string;
  };
  defaultNoteLoading: boolean;
  currentUserData: any;
}

export const SidecarAddNotesDrawer = (props: ISidecarAddNotesDrawerProps) => {
  const commonData = useContext(CommonDataContext);
  const contactLocationUuid =
    props?.contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid;
  const ehrCapabilities = useEHRCapabilities({locationId: contactLocationUuid});
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    contactLocationUuid
  );
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;

  const elationFormData = {
    categories: resourceAbilities?.allowedCategories,
    noteComponents: resourceAbilities?.allowedNoteComponents,
  };

  const currentEHR: any = getEHRName(contactLocationUuid, '');
  const ehrConfig = getEhrConfig(currentEHR);
  const isPhysicianUser = isPhysicianRole();
  const userUUID = getUserUUID();

  const currentUserData = getUserDetailByUserUuid(
    props.accountUserList || [],
    userUUID
  );
  const [isNavigatingToCarePlan, setIsNavigatingToCarePlan] = useState(false);  const [componentState, setComponentState] =
    useState<ISidecarAddNotesDrawerState>({
      currentUserData: currentUserData || ({} as any),
      defaultNoteLoading: ehrConfig?.isElation ? false : true,
    });

  const carePlanProps = getCarePlanProps(props?.formattedContactData, props?.contactData, setIsNavigatingToCarePlan);

  const userPreferenceList = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCE_TYPE
  );
  const userPreferenceId = getMlovIdFromCode(
    userPreferenceList,
    USER_PREFERENCE_CODE.DEFAULT_FORM
  );

  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;

  useQuery(
    FormsQueries.GET_DEFAULT_FORM,
    {
      fetchPolicy: 'no-cache',
      skip: !foldVisitNoteWithEncountersEnabled,
      variables: {
        userId: userUUID,
        userPreferencesTypeId: userPreferenceId,
      },
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      onError: (error) => {
        setComponentState((prev) => ({
          ...prev,
          defaultNoteLoading: false
        }));
      },
      onCompleted: (data) => {
        let defaultForm =
          data?.userPreferences.length > 0 &&
          data?.userPreferences[data?.userPreferences.length - 1]
            ?.preferencesJson;
        const id = data?.userPreferences[data?.userPreferences.length - 1]?.id;
        if (defaultForm && id) {
          defaultForm = JSON.parse(defaultForm);
          setComponentState((prev) => ({
            ...prev,
            defaultTemplate: {formId: defaultForm.defaultForm, id},
            defaultNoteLoading: false
          }));
        }
        else {
          setComponentState((prev) => ({
            ...prev,
            defaultNoteLoading: false
          }));
        }
      },
    }
  );

  const showSuccessNotification = (message: string, noteData: any) => {
    notification.open({
      message: (
        <Stack
          direction="row"
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text
            style={{
              color: Colors.Custom.White,
              fontSize: 16,
            }}
          >
            {message}
          <Text
            onPress={()=> {
              const eventBus = EventBus.getEventBusInstance();
              eventBus.broadcastEvent(NOTES_EVENTS.OPEN_NOTE_DETAIL, { noteData });
              notification.destroy();
            }}
            style={{
              color: Colors.Custom.White,
              fontSize: 14,
              marginLeft: 4,
              textDecorationLine: 'underline'
            }}
          >
            {'View Note'}
          </Text>
          </Text>
          <CrossIcon customStrokeColor={Colors.Custom.White} size={32} />
        </Stack>
      ),
      style: {
        backgroundColor: Colors.Custom.SuccessColor,
        padding: 0,
        paddingLeft: 16,
        paddingTop: 8,
        borderRadius: 8,
      },
      icon: false,
      closeIcon: <></>,
      duration: 3.0,
      placement: 'topRight',
      onClick: () => {
        notification.destroy();
      },
    });
  };

  const handleCreateCarePlanClick = () => {
    setIsNavigatingToCarePlan(true);
  }

  const getNoteConfigureForAddNewNote = () => {
    return {
      createdByUserName: componentState.currentUserData?.name,
      status: DocStatus.PRELIMINARY,
      authorUuid: componentState.currentUserData?.uuid,
      linkedAppointmentId: undefined,
      resourceId: '',
      isAllowToDelete: true,
      isAllowToPrint: true,
      isAllowToUnlock: true,
      isAllowToAutoSave: true,
      isAllowToSign: true,
      isAllowToSave: true,
      formId: componentState?.defaultTemplate?.formId,
      isFoldDrivenNote: componentState?.defaultTemplate?.formId ? true : false
    } as IPatientNoteCardProps;
  };

  const componentLoading = componentState.defaultNoteLoading;

  return (
    <Drawer
      className="create-note-from-alert-drawer"
      open={true}
      width={isSidecarContext ? '100%' : '40%'}
      style={{padding: 0}}
    >
      {componentLoading ? (
        <TimeLineSkeletonLoader />
      ) : (
        <Stack direction="column">
          <View style={{paddingVertical: 12, paddingHorizontal: 12}}>
            <SidecarPatientInfoBanner
              handleCreateCarePlanClick={handleCreateCarePlanClick}
              contactData={props?.contactData} />
          </View>

          <Divider height={'1px'} />

          {isNavigatingToCarePlan ? (
            <CarePlan {...(carePlanProps as ICarePlanProps)} />
          ) : (
            <AddOrUpdatePatientNote
            isNewNote={props.isNewNote}
            paddingHorizontal={12}
            isNewView={true}
            hideBottomOrderActionBar
            disableDefaultTemplateChange={false}
            viewType={ViewType.DRAWER}
            ehrCapabilities={ehrCapabilities}
            elationFormData={elationFormData}
            personData={props.formattedContactData}
            isLinkAppointment={false}
            ehrConfig={ehrConfig}
            unFormattedContactData={props.contactData}
            isPhysicianUser={isPhysicianUser}
            goBackHandler={props.onClose}
            noteData={
              props.isNewNote ? getNoteConfigureForAddNewNote() : props.noteData
            }
            accountUserList={props.accountUserList || []}
            onAddNoteSuccessCallback={(noteData) => {
              showSuccessNotification(noteData.successMessage, noteData.noteData);
              props?.onSaveNote({
                id: noteData.id,
                docStatus: noteData.docStatus,
              });
            }}
            defaultTemplate={componentState?.defaultTemplate}
          />
          )}
        </Stack>
      )}
    </Drawer>
  );
};
