import { Procedure } from 'fhir/r4';
import {IKeyOperation} from '../../../../../../../Interfaces';
import { IFormatHieMetaData, IHieFormattedData, IHieInvalidField } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import {
  IAddOrUpdateSurgicalHistoryParams,
  ISurgicalHistory,
  ISurgicalHXComponentValue,
} from '../interfaces';
import { capitalize } from 'lodash';
import { FHIR_CODE_SYSTEMS } from '../../../../../../../constants/FhirConstant';
import { getTextFromCoding, getValidCoding } from '../../../../../Integration/FhirUtils';
import { HieInvalidFields } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';
import { PAMISearchType } from '../../../../../../common/PAMISearch/PAMISearch';
import { getEHRCapability } from '../../../../../../../utils/commonUtils';
import { CapabilityResource } from '../../CustomWrapper/CustomComponentHelper';
import { InputType, getFieldCapabilities } from '../../../../../../../utils/capabilityUtils';

export enum SurgicalHistoryFields {
  code,
  status,
  performedDateTime,
}

export const getRecordListFormatted = (list: any[]) => {
  const entries = [];
  for (const el of list || []) {
    entries.push(el.resource);
  }
  const formattedData = getRecordDataFormatted(entries);
  return sortItems(formattedData);
}

export const getRecordDataFormatted = (listData: any[]): ISurgicalHistory[] => {
  const tempFormattedList: ISurgicalHistory[] = [];
  listData.map((item: any) => {
    if (item?.code) {
      const tempObj: ISurgicalHistory = {
        id: item?.id,
        performedDateTime: item?.performedDateTime,
        surgicalHistory: item?.code,
        name: getHistoryName(item.code),
        ...(item?.status && {
          status: {
            code: item?.status,
            display: item?.status,
          },
        }),
        uniqueId: item?.id,
        isFreeTextRecord: !item.code?.coding?.length,
      };
      tempFormattedList.push(tempObj);
    }
  });
  return tempFormattedList;
};

export const getSampleData = (): ISurgicalHXComponentValue => {
  return {
    surgicalHistory: [{
      id: '34234324',
      performedDateTime: '2020-12-24',
      surgicalHistory: {
        text: 'Sample Procedure',
        coding: []
      },
      name: 'Sample Procedure',
      status: {code: 'completed', display: 'Completed'},
      uniqueId: '1234',
      isFreeTextRecord: false,
    }],
  }
};

export const sortItems = (records: ISurgicalHistory[]) => {
  return records.sort((a, b) => {
    const statusA = a.status?.code || '';
    const statusB = b.status?.code || '';
    if (statusA !== statusB) {
      return statusA.toLowerCase().localeCompare(statusB.toLowerCase());
    }
    return (
      new Date(b.performedDateTime || new Date()).getTime() -
      new Date(a.performedDateTime || new Date()).getTime()
    );
  })
};

export const getHistoryName = (item: any) => {
  let name = item?.text;
  if (!name && item?.coding?.length) {
    name = item.coding[0].display;
  }
  return name;
};

export const isInvalid = (
  field: SurgicalHistoryFields,
  data: ISurgicalHistory,
  submitTriggered: boolean,
  fields?: IKeyOperation
) => {
  switch (field) {
    case SurgicalHistoryFields.performedDateTime:
      if ((fields?.performedDateTime?.isRequired || false) && submitTriggered) {
        return !data.performedDateTime;
      }
      return false;

    case SurgicalHistoryFields.status:
      if ((fields?.status?.isRequired || false) && submitTriggered) {
        return !data.status || !data.status.code;
      }
      return false;

    default:
      return false;
  }
};

export const getFHIRTemplate = (params: IAddOrUpdateSurgicalHistoryParams) => {
  return {
    resourceType: 'Procedure',
    ...(params.id && {id: params.id}),
    ...(params.status && {status: params.status.code}),
    code: params.surgicalHistory,
    subject: {
      reference: `Patient/${params.patientId}`,
    },
    ...(params.performedDateTime && {
      performedDateTime: params.performedDateTime,
    }),
  };
};


export const formatHieSurgicalHistoryData = (
  resource: any,
  metaData: IFormatHieMetaData
): IHieFormattedData | undefined => {
  const procedureResource: Procedure = resource;

  const capabilities = getEHRCapability(metaData.ehrCapabilities, [
    CapabilityResource.procedure,
  ]);

  const fieldCapabilities = getFieldCapabilities(capabilities);

  const validatedData = validateAndFormatSurgicalHistoryHieResource(
    resource,
    fieldCapabilities
  );

  const display = {
    title: getHieSurgicalhistoryCodes(resource, fieldCapabilities?.code?.allowedCodeSystems)?.text || '',
    date: procedureResource.performedDateTime,
    status: capitalize(procedureResource.status).split('-').join(' '),
  };

  return {
    display,
    resourceData: validatedData.resource,
    invalidFields: validatedData.invalidFields,
  };
};

const getHieSurgicalhistoryCodes = (
  resource: Procedure,
  allowedCodeSystems?: string[]
) => {
  const codeObj = resource.code;
  const coding = getValidCoding(codeObj, allowedCodeSystems);
  const text = getTextFromCoding(coding) || codeObj?.text;
  return {coding: coding, text: text};
};

const getHieClinicalStatus = (
  resource: Procedure,
  possibleValues?: {code: string; display: string}[]
) => {
  const status = resource?.status;

  return possibleValues?.find(
    (possibleValue) => possibleValue.code === status?.toLowerCase()
  );
};

export const getSurgicalInvalidCodeField = () => {
  return {
    field: SurgicalHistoryFields.code,
    inputType: HieInvalidFields.code,
    invalid: true,
    label: 'Surgical History',
    placeholder: 'Search and add new Procedure',
    extraData: {
      searchType: PAMISearchType.procedure,
    },
  }
}

export const validateAndFormatSurgicalHistoryHieResource = (
  resource: Procedure,
  fields: any
) => {
  const invalidFields: IHieInvalidField[] = [];

  Object.keys(fields).forEach(function (key, index) {
    const field =
      SurgicalHistoryFields[key as keyof typeof SurgicalHistoryFields];
    switch (field) {
      case SurgicalHistoryFields.code:
        const surgicalHistoryCodes = getHieSurgicalhistoryCodes(
          resource,
          fields?.code?.allowedCodeSystems
        );

        if (
          fields?.code.inputType === InputType.openChoice &&
          surgicalHistoryCodes.text
        ) {        
          resource.code = {
            text: surgicalHistoryCodes?.text,
            ...(surgicalHistoryCodes?.coding?.length && {
              coding: surgicalHistoryCodes.coding,
            }),
          };
        }
        else if ((!surgicalHistoryCodes?.coding?.length || !surgicalHistoryCodes.text) && fields?.code?.isRequired) {
          invalidFields.push(getSurgicalInvalidCodeField());
        } else if (surgicalHistoryCodes?.coding?.length) {
          resource.code = {
            text: surgicalHistoryCodes?.text,
            coding: surgicalHistoryCodes.coding,
          };
        }
        break;

      case SurgicalHistoryFields.status:
        const status = getHieClinicalStatus(
          resource,
          fields?.clinicalStatus?.possibleValues
        );

        if (!status?.code && fields?.clinicalStatus?.isRequired) {
          invalidFields.push({
            field: SurgicalHistoryFields.status,
            inputType: HieInvalidFields.select,
            invalid: true,
            extraData: {
              statusList: fields?.clinicalStatus?.possibleValues,
            },
          });
        }
        break;
    }
  });

  return {
    invalidFields: invalidFields,
    resource: resource,
  };
};

export const updateHieSurgicalHistoryMatchedData = (
  resource: Procedure,
  invalidFields: IHieInvalidField[],
  metaData: IFormatHieMetaData
) => {
  invalidFields.map((invalidField) => {
    if (invalidField.value) {
      switch (invalidField.field) {
        case SurgicalHistoryFields.code:
          resource.code = invalidField.value;
          break;

        case HieInvalidFields.select:
          resource.status = invalidField.value;
          break;
      }
    }
    return invalidField;
  });

  return formatHieSurgicalHistoryData(resource, metaData);
};

export const formatSurgicalHistoryRequestResourceObj = (
  resource: Procedure,
  metaData: IFormatHieMetaData
) => {
  return {
    resourceType: 'Procedure',
    ...(resource.status && {status: resource.status}),
    code: resource.code,
    subject: {
      reference: `Patient/${metaData.patientId}`,
    },
    ...(resource.performedDateTime && {
      performedDateTime: resource.performedDateTime,
    }),
  };
};
