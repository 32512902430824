import {ColumnsType} from 'antd/lib/table';
import {IEmailTemplate} from '@foldhealth/easy-email-editor';
import {Text, Tooltip, View} from 'native-base';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {IEmailTemplateTableColumnsArgs} from '../EmailTemplatesListView/interfaces';
import {IEmailTemplateData} from '../interfaces';
import {EmailTemplatesTableActions} from './EmailTemplatesTableActions';

export const getEmailTemplatesColumns = (
  args: IEmailTemplateTableColumnsArgs
): any => {
  const {
    actionFn,
    templateCategories,
    defaultFilteredValues,
    defaultSortOrder,
  } = args;

  const columns = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'templateName',
      key: 'templateName',
      width: '20%',
      fixed: 'left',
      render: (templateName: string) => {
        return (
          <Tooltip label={templateName} placement="top">
            <Text size={'smMedium'} color={Colors.Custom.Gray900}>
              {templateName.length < 30
                ? templateName
                : templateName.slice(0, 30) + '...'}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="category"
        />
      ),
      dataIndex: 'templateCategory',
      key: 'templateCategory',
      width: '20%',
      filters: templateCategories,
      defaultFilteredValue: defaultFilteredValues,
      filterSearch: true,
      onFilter: (value: any, record: IEmailTemplateData) => {
        return record.templateCategory.indexOf(value) === 0;
      },
      render: (templateCategory: string) => {
        return (
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {templateCategory}
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="subject"
        />
      ),
      dataIndex: 'templateData',
      key: 'templateData',
      width: '25%',
      render: (templateData: IEmailTemplate) => {
        const subject = templateData.subject;
        return (
          <Tooltip label={subject} placement="top">
            <Text size={'smMedium'} color={Colors.Custom.Gray900}>
              {subject.length < 40 ? subject : subject.slice(0, 40) + '...'}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="default"
        />
      ),
      dataIndex: 'templateIsDefault',
      key: 'isDefault',
      width: '10%',
      render: (isDefault: boolean) => {
        let text = 'No';
        if (isDefault) {
          text = 'Yes';
        }
        return (
          <View>
            <Text>{text}</Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="lastUpdated"
        />
      ),
      dataIndex: 'templateLastUpdated',
      defaultSortOrder: defaultSortOrder,
      width: '15%',
      key: 'templateLastUpdated',
      sorter: (a: any, b: any) =>
        new Date(a.templateLastUpdated).getTime() -
        new Date(b.templateLastUpdated).getTime(),
      render: (updatedOn: string) => {
        const date = new Date(updatedOn).toLocaleDateString('en-US', {
          year: 'numeric',
          day: 'numeric',
          month: 'long',
        });
        return (
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {String(date)}
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      key: 'Actions',
      width: '20%',
      fixed: 'right',
      onCell: () => {
        return {
          onClick: (e: Event) => {
            e.stopPropagation();
          },
        };
      },
      render: (record: IEmailTemplateData) => {
        return (
          <EmailTemplatesTableActions
            record={record}
            onActionPerformed={actionFn}
          />
        );
      },
    },
  ];

  return columns;
};
