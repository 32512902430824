import React from "react";

const MediumSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="6"
      fill="none"
      viewBox="0 0 13 6"
    >
      <path
        fill="#FFAB00"
        d="M1.323 3.667a.832.832 0 100 1.666h10a.832.832 0 100-1.666h-10zm0-3.334a.832.832 0 100 1.667h10a.832.832 0 100-1.667h-10z"
      ></path>
    </svg>
  );
}

export default MediumSvg;
