import {VStack} from 'native-base';
import {Dimensions} from 'react-native';
import {Content} from 'antd/lib/layout/layout';
import {TagFilterList} from './TagFilterList';
import {CommonCollapsible} from './CommonCollapsible';
import {CommonStatusKindList} from './CommonStatusKindList';
import {
  dummyStatusData,
  dummyKindData,
  dummyTagsData,
} from '../../../../../dummyData/Campaigns/campaignFilterDummyData';
import {FILTER_COMPONENT_CODE} from '../../../../../constants';

export const LeftSideFilters = () => {
  const {height} = Dimensions.get('window');

  const getCurrentTag = (item: string) => {
    // getCurrentTag
  };

  const getCurrentStatus = (item: string) => {
    // getCurrentStatus
  };

  const getCurrentKind = (item: string) => {
    // getCurrentKind
  };

  return (
    <Content
      className="filter-expand"
      style={{height: height - 84, overflow: 'auto'}}
    >
      <VStack>
        <CommonCollapsible
          header={FILTER_COMPONENT_CODE.STATUS}
          showArrow={false}
        >
          <CommonStatusKindList
            detailsList={dummyStatusData}
            getCurrentStatus={getCurrentStatus}
            label={FILTER_COMPONENT_CODE.STATUS}
          />
        </CommonCollapsible>
        <CommonCollapsible
          header={FILTER_COMPONENT_CODE.KIND}
          showArrow={false}
        >
          <CommonStatusKindList
            detailsList={dummyKindData}
            getCurrentKind={getCurrentKind}
            label={FILTER_COMPONENT_CODE.KIND}
          />
        </CommonCollapsible>
        <CommonCollapsible header={FILTER_COMPONENT_CODE.TAG} showArrow={true}>
          <TagFilterList
            tagsListData={dummyTagsData}
            getCurrentTag={getCurrentTag}
          />
        </CommonCollapsible>
      </VStack>
    </Content>
  );
};
