import {Drawer, Table} from 'antd';
import {HStack, Text, View, VStack} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {GraphConfig} from '../../../../common/AppDashboard/GraphUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import ThumbIcon from '../../../../common/Svg/WidgetsSvg/ThumbIcon';
import {ICustomerSatisfactionDrawer} from '../interface';
import {Pie, PieConfig} from '@ant-design/charts';
import PieSvg from '../../../../common/Svg/WidgetsSvg/PieSvg';
import {getQuestionAnswerListInArray} from '../EmployerUtils/WidgetUtils';
import {formateDataForCustomSatisfaction, getParseIntToFixed, getReplyInPercent, getTopResponseObj} from '../EmployerUtils/FormateDataUtils';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {GraphMemberList} from '../../../../common/AppDashboard/GraphMemberList';

const CustomerSatisfactionDrawer = (props: ICustomerSatisfactionDrawer) => {
  const [stateData, setStateData] = useState({
    visible: true,
    contactUuids: [],
  })
  const {drillDownData} = props
  const {height, width} = Dimensions.get('window');
  const intl = useIntl();
  const formattedDrillDown = getQuestionAnswerListInArray(drillDownData?.queAnsList)
  const questionList = formattedDrillDown?.tempArray || []
  const totalCount = drillDownData.totalFormSubmitted;
  const getAvgRatingPresent = () => {
    let avgRating = 0;
    if (drillDownData?.scoreData?.allContactMaxScore && drillDownData?.scoreData?.allContactScore) {
      avgRating = parseInt(((drillDownData?.scoreData?.allContactScore / drillDownData?.scoreData?.allContactMaxScore) * 100) as any);
      return avgRating + '%';
    }
    return avgRating;
  }

  const onClose = () => {
    setStateData(prev => {
      return {
        ...prev,
        visible: false
      }
    })
    props?.onCancel?.();
  }
  const onPieChartOptionClick = (args: any) => {
    if (args?.data?.data?.contactUuidList?.length) {
      setStateData((prev) => {
        return {
          ...prev,
          contactUuids: args?.data?.data?.contactUuidList
        }
      });
    }
  }
  return (
    <>
      <Drawer
        title={<>
          <HStack justifyContent={'space-between'} alignItems="center">
            <VStack>
              <DisplayText
                size={'smBold'}
                extraStyles={{
                  fontSize: 30,
                }}
                textLocalId="customerSatisfactionTitle"
              />
            </VStack>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  props?.onCancel?.();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'done',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </HStack>
          <HStack style={{marginTop: 30, marginBottom: 20}} alignItems={'center'}>
            <div
              style={{
                width: 50,
                height: 50,
                marginRight: 10,
                marginLeft: 10,
              }}
            >
              <ThumbIcon />
            </div>
            <VStack
              style={{
                marginLeft: 10,
              }}
            >
              <Text
                size={'smNormal'}
                style={{
                  color: Colors.Custom.Gray700,
                  fontSize: 18,
                }}
              >
                {intl.formatMessage({id: 'customerSatisfactionSubtitle'})}
              </Text>
              <Text
                size={'mdBold'}
                style={{
                  color: Colors.Custom.Gray900,
                  fontSize: 36,
                  fontWeight: '700',
                }}
              >
                {
                  (drillDownData?.scoreData?.allContactMaxScore && drillDownData?.scoreData?.allContactScore) &&
                  getAvgRatingPresent()
                }
              </Text>
            </VStack>
          </HStack>
        </>}
        destroyOnClose
        placement="right"
        onClose={() => {
          onClose();
        }}
        visible={stateData.visible}
        // closable={false}
        width={width * 0.35}
        bodyStyle={{
          paddingRight: 40,
          paddingLeft: 40,
        }}
      >

        <View
          style={{
            marginBottom: 15,
            overflow: 'scroll',
          }}
        >
          {questionList.map((resp: any) => {
            const data = formateDataForCustomSatisfaction(
              resp?.value?.ansOptList
            );
            const topResponseObject = getTopResponseObj(
              resp?.value?.ansOptList
            );
            const replyInPercent = getReplyInPercent(
              totalCount,
              topResponseObject?.contactAnsCount
            );
            const replyInPercentStr = replyInPercent;
            const pieConfig: PieConfig = {
              data: data,
              angleField: 'value',
              colorField: 'type',
              ...GraphConfig,
              legend: false,
              label: false,
              width: 150,
              height: 100,
              appendPadding: 10,
              radius: 1,
              innerRadius: 0.6,
              onReady: (plot: any) => {
                plot.on('element:click', (args: any) => {
                  onPieChartOptionClick(args);
                });
              },
            };

            return (
              <HStack
                style={{
                  paddingBottom: 16,
                  borderBottomColor: Colors.Custom.Gray200,
                  borderStyle: 'solid',
                  borderBottomWidth: 1,
                  marginBottom: 16,
                  justifyContent: 'space-between',
                }}
              >
                <VStack>
                  <View style={{maxWidth: width * 0.22}}>
                    <Text
                      size={'mdBold'}
                      style={{
                        color: Colors.Custom.Gray900,
                        fontSize: 16,
                        fontWeight: '500',
                      }}
                    >
                      {resp?.value?.queDisplayString}
                    </Text>
                  </View>
                  <HStack style={{maxWidth: width * 0.22}}>
                    <Text
                      size={'smNormal'}
                      style={{
                        color: '#7C5CC1',
                        fontSize: 16,
                        fontWeight: '400',
                        marginTop: 5,
                      }}
                    >
                      {
                        replyInPercent > 0 ?
                          (<>
                            {`${replyInPercentStr}% responded as : ${topResponseObject?.label}`}
                          </>)
                          :
                          (
                            <>
                              No answer yet
                            </>
                          )
                      }

                    </Text>
                  </HStack>
                </VStack>
                {/* <div style={{
                  width:48,
                  height:48
                }}>
                  <PieSvg/>
                </div> */}
                {
                  replyInPercent > 0 && (
                    <div>
                      <Pie {...pieConfig} />
                    </div>
                  )
                }

              </HStack>
            );
          })}
        </View>
        {stateData.contactUuids?.length ? (
          <GraphMemberList
            isOpen={true}
            page={1}
            pageSize={stateData.contactUuids?.length}
            total={stateData.contactUuids?.length}
            title={'Contact Data'}
            isFromNewEmployer={true}
            contactUuidList={stateData.contactUuids}
            onCompleteAction={() => {
              setStateData((prev) => {
                return {
                  ...prev,
                  contactUuids: [],
                };
              });
            }}
            onClose={() => {
              setStateData((prev) => {
                return {
                  ...prev,
                  contactUuids: [],
                };
              });
            }}
          />
        ) : (
          <></>
        )}
      </Drawer>
    </>
  );
};

export default React.memo(CustomerSatisfactionDrawer);
