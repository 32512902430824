import React, {useEffect} from 'react';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  CreateContactOutreachActivity,
  GetContactOutreachActivities,
} from '../../../../services/ContactOutreach/ContactOutreachQueries';
import {getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../../constants';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import moment from 'moment';
import {
  CARE_PROGRAM_OUTREACH_MODE_CODES,
  CARE_PROGRAM_TYPES,
  OUTREACH_STATUS_CODES,
  TASK_STATUS,
} from '../../../../constants/MlovConst';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {PatientNotesLogModeOfContact} from '../../constants';
import {IFormattedContactOutreachActivity} from './interfaces';
import CareProgramStepOutreachUtils from '../../../common/CareProgramStepOutreach/CareProgramStepOutreachUtils';
import {GET_USERS_WITH_IDS} from '../../../../services/User/UserQueries';
import {getUserData} from '../../../../utils/commonUtils';
import {formatOutreachData} from './ContactOutreachActivitiesHelper';

export interface IUseContactOutreachActivity {
  contactUuid: string;
}

export interface IContaxtOutreachActivityData {
  statusId: string;
  typeId: string;
  communicationModeId: string;
  outcomeId: string;
  note: string;
  outreachDateTime: string;
  careProgramTypeId: string;
  contactId: string;
  outreachActivities: IFormattedContactOutreachActivity[];
  offset: number;
  loading: boolean;
  showMoreLoading: boolean;
  totalActivityCount: number;
  showMoreActivity: boolean;
}

export const useContactOutreachActivity = (
  params: IUseContactOutreachActivity
) => {
  const LIMIT = 10;
  const mlovData = React.useContext(CommonDataContext);

  const [addOrUpdateContactOutreachActivity] = useMutation(
    CreateContactOutreachActivity,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getUserByIds] = useLazyQuery(GET_USERS_WITH_IDS, {
    fetchPolicy: 'no-cache',
  });

  const outreachStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_OUTREACH_STATUS
    ) || [];

  const pendingOutreachStatus = outreachStatusMlov?.find((mlov) => {
    if (mlov.code === OUTREACH_STATUS_CODES.PENDING) return true;
  });

  const outreachModeMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_OUTREACH_MODE
    ) || [];

  const manualOutreachModeType = outreachModeMlov?.find((mlov) => {
    if (mlov.code === CARE_PROGRAM_OUTREACH_MODE_CODES.MANUAL) return true;
  });

  let careProgramTypesMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_TYPES
    ) || [];

  careProgramTypesMlov = careProgramTypesMlov.filter((mlov) => {
    if (mlov.code === CARE_PROGRAM_TYPES.ECM) {
      return false;
    }
    return true;
  });

  const outreachCommunicationModes =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CONTACT_OUTREACH_COMMUNICATION_MODE
    ) || [];

  const defaultData = React.useRef<IContaxtOutreachActivityData>({
    statusId: pendingOutreachStatus?.id || '',
    typeId: manualOutreachModeType?.id || '',
    communicationModeId: outreachCommunicationModes[0]?.id || '',
    outcomeId: '',
    note: '',
    outreachDateTime: moment().toISOString(),
    careProgramTypeId: careProgramTypesMlov[0]?.id || '',
    contactId: params.contactUuid,
    outreachActivities: [],
    offset: 0,
    loading: false,
    showMoreLoading: false,
    totalActivityCount: 0,
    showMoreActivity: false,
  });

  const [data, setData] = React.useState<IContaxtOutreachActivityData>(
    defaultData.current
  );

  useEffect(() => {
    clearDataAndFetchActivities();
  }, [data.careProgramTypeId]);

  const clearDataAndFetchActivities = () => {
    setData((prev) => {
      return {
        ...prev,
        outreachActivities: [],
      };
    });
    fetchActivities(0);
  };

  const [activeMode, setActiveMode] = React.useState<
    PatientNotesLogModeOfContact | string
  >(
    outreachCommunicationModes[0]?.code || PatientNotesLogModeOfContact.GENERAL
  );

  const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(true);
  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);
  const userData = getUserData();

  const validateForm = (newData: IContaxtOutreachActivityData) => {
    let valid: boolean;
    if (newData?.note?.length && newData?.outcomeId?.length) {
      valid = true;
    } else {
      valid = false;
    }
    setSaveButtonDisabled(!valid);

    return valid;
  };

  const [getOutreachActivity] = useLazyQuery(GetContactOutreachActivities, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const setDefaultData = () => {
    setData((prev) => {
      return {
        ...defaultData.current,
        outreachDateTime: prev.outreachDateTime,
        careProgramTypeId: prev.careProgramTypeId,
      };
    });
  };

  const fetchMoreActivities = () => {
    fetchActivities();
  };

  const fetchActivities = async (offset?: number) => {
    try {
      setData((prev) => ({
        ...prev,
        loading: true,
      }));
      const currentOffset = offset !== undefined ? offset : data.offset;
      const outreachActivityResponse = await getOutreachActivity({
        variables: {
          contactId: params.contactUuid,
          careProgramTypeId: data.careProgramTypeId,
          limit: LIMIT,
          offset: currentOffset,
        },
      });

      const availableUsers =
        outreachActivityResponse?.data?.ContactOutreachActivities?.map(
          (item: any) => item.createdBy
        );

      const totalCount =
        outreachActivityResponse?.data?.aggregateContactOutreachActivity
          ?.aggregate?.count;

      const userResponse = await getUserByIds({
        variables: {
          userIds: availableUsers,
        },
      });

      const userMap = CareProgramStepOutreachUtils.getUserMapByUUID(
        userResponse?.data?.users,
        userData
      );

      const formattedData = formatOutreachData(
        mlovData,
        outreachActivityResponse?.data?.ContactOutreachActivities,
        userMap
      );

      setData((prev) => {
        const updatedActivities = [
          ...prev.outreachActivities,
          ...formattedData,
        ];
        return {
          ...prev,
          outreachActivities: updatedActivities,
          offset: currentOffset + LIMIT,
          loading: false,
          showMoreActivity:
            totalCount > LIMIT && updatedActivities.length < totalCount,
        };
      });
    } catch (error) {}
  };

  const saveActivity = async () => {
    setSaveButtonLoading(() => true);
    const response = await addOrUpdateContactOutreachActivity({
      variables: {
        data: {
          statusId: data.statusId,
          typeId: data.typeId,
          communicationModeId: data.communicationModeId,
          outcomeId: data.outcomeId,
          note: data.note,
          outreachDateTime: data.outreachDateTime,
          careProgramTypeId: data.careProgramTypeId,
          contactId: params.contactUuid,
        },
      },
    });

    if (response?.data?.addOrUpdateContactOutreachActivity?.id) {
      setDefaultData();
      clearDataAndFetchActivities();
    }
    setSaveButtonLoading(() => false);
    setSaveButtonDisabled(() => true);
  };

  return {
    data,
    careProgramTypesMlov,
    outreachCommunicationModes,
    saveButtonDisabled,
    saveButtonLoading,
    activeMode,
    setActiveMode,
    fetchActivities,
    setData,
    saveActivity,
    validateForm,
    setDefaultData,
    outreachActivities: data.outreachActivities,
    fetchMoreActivities,
    loading: data.loading,
    showMoreActivity: data.showMoreActivity,
  };
};
