import {useLazyQuery} from '@apollo/client';
import {useContext, useEffect, useMemo, useState} from 'react';
import {
  GET_FORM_CATEGORY_BY_CODE, SEARCH_FORMS_WITH_LOCATION
} from '../../../../../../services/Forms/FormsQueries';
import {FORM_CATEGORY_TYPES} from '../../../../Forms/FormsConstants';
import {Checkbox, Drawer, Skeleton} from 'antd';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../../../../styles/Colors';
import {BUTTON_TYPE} from '../../../../../../constants/StringConst';
import {getAccountUUID} from '../../../../../../utils/commonUtils';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {ScrollView, Text} from 'native-base';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {AddCareProgramStepInput, CareProgramStepPayload, IContactCareProgramStep} from '../../../interface';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {MLOV_CATEGORY} from '../../../../../../constants';
import {getMlovListFromCategory} from '../../../../../../utils/mlovUtils';
import {CARE_PROGRAM_STEP_TYPE} from '../../../../../common/MemebersView/constant';
import {useContactCareProgramContext} from '../../ContactCareProgram.context';

export interface IAddAssessmentProps {
  onCancel: () => void;
  onAdd: (payload: AddCareProgramStepInput) => Promise<void>;
  contactAccountLocationId: string | undefined;
  step: IContactCareProgramStep;
}



const AddAssessment = (props: IAddAssessmentProps): JSX.Element => {
  const {onCancel, onAdd, contactAccountLocationId, step: parentStep} = props;
  const context = useContext(CommonDataContext);
  const tenantId = getAccountUUID();

  const {state: {
    contactCareProgramDetails
  }} = useContactCareProgramContext()
  const careProgramStepTypeList =  getMlovListFromCategory(
    context.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_STEP_TYPE
  );

  const assessmentTypeId = useMemo(
    () =>
      careProgramStepTypeList?.find(
        (item) => item.code === CARE_PROGRAM_STEP_TYPE.ASSESSMENT
      )?.id,
    [careProgramStepTypeList.length]
  );
  const [
    getFormCategoryId,
    {loading: formCategoryLoading, error: formCategoryError},
  ] = useLazyQuery(GET_FORM_CATEGORY_BY_CODE, {
    variables: {
      searchString: `%${FORM_CATEGORY_TYPES.ASSESSMENT_FORM_CATEGORY}%`,
      tenantId: tenantId,
    },
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [state, setState] = useState<{
    status: 'loading' | 'error' | 'success' | 'empty';
    selectedFormIds: Set<string>;
    forms: {
      id: string;
      name: string;
    }[];
  }>({
    status: 'loading',
    selectedFormIds: new Set(),
    forms: [],
  });

  const [getForms, {loading: formDataLoading, error: formDataError}] =
    useLazyQuery(SEARCH_FORMS_WITH_LOCATION, {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    });

  const fetchFormByCategoryId = async () => {
    try {
      setState({
        ...state,
        status: 'loading',
      });
      const formCategoryResponse = await getFormCategoryId();
      const formCategoryId = formCategoryResponse?.data?.formCategories[0]?.id;

      if (!formCategoryId) {
        setState({
          ...state,
          status: 'empty',
        });
        return;
      }

      const formResponse = await getForms({
        variables: {
          params: {
            categoryIds: [formCategoryId],
            offset: 0,
            limit: 200,
            locationIds: contactAccountLocationId
              ? [contactAccountLocationId]
              : undefined,
          },
        },
      });
      const forms = formResponse?.data?.searchForms?.forms;
      if (forms?.length) {
        setState({
          ...state,
          status: 'success',
          forms: forms,
        });
      } else {
        setState({
          ...state,
          status: 'empty',
        });
      }
    } catch (e) {
      setState({
        ...state,
        status: 'error',
      });
    }
  };

  useEffect(() => {
    fetchFormByCategoryId();
  }, []);

  const onSelectForms = (e: any, form: {id: string}) => {
    setState((prev) => {
      const set = new Set(prev.selectedFormIds);
      if (e.target.checked) {
        set.add(form.id);
      } else {
        set.delete(form.id);
      }
      return {...prev, selectedFormIds: set};
    });
  };

  const onAddAssessment = async () => {
    const selectedForms = state.forms.filter((form) =>
      state.selectedFormIds.has(form.id)
    );

    const initDisplaySequenceNumber = parentStep.subSteps?.[parentStep.subSteps?.length - 1]?.displaySequenceNumber || 0;

    if (
      !assessmentTypeId ||
      !contactCareProgramDetails?.id ||
      !contactCareProgramDetails?.contactId ||
      !parentStep?.id
    ) {
      return;
    }

    const careProgramSteps: CareProgramStepPayload[] = selectedForms.map(
      (form, index) => ({
        isRequired: false,
        // Assessment typeId
        careProgramStepTypeId: assessmentTypeId as string,
        title: form.name,
        sequenceNumber: parentStep.sequenceNumber,
        parentStepId: parentStep.id,
        displaySequenceNumber: (index + initDisplaySequenceNumber) + 1,
        additionalAttributes: [
          {
            attributeKey: 'formId',
            attributeValue: {
              valueString: form.id,
            },
            attributeValueDataTypeCode: 'string',
          },
        ],
      })
    );

    const payload: AddCareProgramStepInput = {
      contactId: contactCareProgramDetails.contactId,
      contactCareProgramId: contactCareProgramDetails.id,
      careProgramSteps,
    };
    await onAdd(payload);
  }


  const renderForms = () => {
    return (
      <ScrollView flexDirection="column" gap={2}>
        {state.forms.map((form) => {
          return (
            <Stack direction="row" space={2} style={{marginBottom: 8}}>
              <Checkbox
                value={form.id}
                onChange={(e) => onSelectForms(e, form)}
              />
              <Text
                ml={2}
                color={Colors.FoldPixel.GRAY400}
                isTruncated
                fontSize={16}
              >
                {form.name}
              </Text>
            </Stack>
          );
        })}
      </ScrollView>
    );
  };

  return (
    <Drawer
      className="add-assessment-drawer"
      destroyOnClose
      placement="right"
      open
      closable
      width={'30%'}
      title={
        <ModalActionTitle
          title={'Add Assessment'}
          titleSize={24}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                onCancel?.();
              },
            },
            {
              show: true,
              btnText: 'add',
              size: 'sm',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              isDisabled: state.selectedFormIds.size === 0,
              onClick: onAddAssessment,
            },
          ]}
        />
      }
    >
      {(() => {
        switch (state.status) {
          case 'loading':
            return <Skeleton active />;
          case 'empty':
            return <DisplayText textLocalId="noFormsFound" />;
          case 'error':
            return <DisplayText textLocalId="apiErrorMessage" />;
          case 'success':
            return renderForms();
        }
      })()}
    </Drawer>
  );
};

export default AddAssessment;
