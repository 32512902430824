import {Collapse} from 'antd';
import {FILTER_COMPONENT_CODE} from '../../../../../constants';
import {CAMPAIGN_CONSTANTS} from '../CampaignConstants';
import {ICommonCollapsibleProps} from '../interfaces';
const {Panel} = Collapse;

export const CommonCollapsible = (props: ICommonCollapsibleProps) => {
  const {header, showArrow} = props;

  const getHeader = () => {
    if (header == FILTER_COMPONENT_CODE.TAG) {
      return CAMPAIGN_CONSTANTS.TAG_FILTER_HEADER;
    } else {
      return header;
    }
  };

  return (
    <Collapse
      style={{marginBottom: 6}}
      defaultActiveKey={['1']}
      expandIconPosition={'right'}
    >
      <Panel header={getHeader()} showArrow={showArrow} key={1}>
        {props.children}
      </Panel>
    </Collapse>
  );
};
