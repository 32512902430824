import {NavigateFunction} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import { CARE_STUDIO_NEST_URL } from '../../../../constants/Configs';
import CMSService from '../../../../services/CommonService/CMSService';
import LocalStorage from '../../../../utils/LocalStorage';
import {ContentTypes} from '../ContentManagementConsts';
import {
  createTemplate,
  deleteTemplate,
  getTemplateById,
  getTemplateCategoryList,
  getTemplates,
  ITemplateQueryParams,
  updateTemplate,
} from '../ContentManagementUtils';
import {getCategoryQueryString} from '../EmailTemplates/EmailTemplatesUtils';
import {getFormattedCategoryData} from '../SMSTemplates/SMSTemplatesUtils';
import {
  EmailComponentApiResponse,
  EmailComponentData,
  EmailComponentsControllerArgs,
  EmailComponentsState,
  SingleEmailComponent,
} from './interfaces';

const cmsInstance = CMSService.getCMSServiceInstance();
const cmsService = cmsInstance.cmsAxiosService;

export class EmailComponentsController {
  showTable: boolean;
  showLoading: boolean;

  private state: EmailComponentsState;
  private searchString: string;
  private navigate: NavigateFunction;
  private setState: React.Dispatch<React.SetStateAction<EmailComponentsState>>;

  constructor(args: EmailComponentsControllerArgs) {
    this.state = args.state;
    this.searchString = args.searchString;
    this.showLoading = this.state.loading;
    this.showTable = this.state.displayList && !this.state.loading;
    this.navigate = args.navigate;
    this.setState = args.setState;

    // binding
    this.onMount = this.onMount.bind(this);
    this.getData = this.getData.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setErrorState = this.setErrorState.bind(this);
    this.onChangeFilters = this.onChangeFilters.bind(this);
    this.onTableActionPerformed = this.onTableActionPerformed.bind(this);
    this.onDeleteComponent = this.onDeleteComponent.bind(this);
    this.setDeleteModal = this.setDeleteModal.bind(this);
    this.setSelectedRow = this.setSelectedRow.bind(this);
  }

  onMount() {
    this.getData();
  }

  async onChangeFilters() {
    this.setLoading(true);
    try {
      const categoryQueryString = getCategoryQueryString(
        this.state.componentTypeList,
        this.state.selectedComponentTypes,
        true
      );
      const response = await getEmailComponents({
        categoryQueryString,
        queryParams: {
          name: this.searchString,
          page: this.state.page,
          pageSize: this.state.pageSize,
          sort: this.state.sort,
        },
      });
      const displayList = getFormattedEmailComponents({response: response});
      this.setState((prev) => {
        return {
          ...prev,
          apiData: response,
          metaData: response.meta,
          displayList,
          loading: false,
        };
      });
    } catch (error) {

      this.setErrorState();
    }
  }

  onTableActionPerformed(actionCode: string, record: SingleEmailComponent) {
    switch (actionCode) {
      case COMMON_ACTION_CODES.DELETE:
        this.setDeleteModal(true);
        this.setSelectedRow(record);
        break;

      case COMMON_ACTION_CODES.EDIT:
      case COMMON_ACTION_CODES.ROW_CLICKED:
        const url = `/admin/contentManagement/emailComponent/edit/${record.id}`;
        this.navigate(url);
        break;
    }
  }

  onDeleteComponent(actionCode: string) {
    if (actionCode == COMMON_ACTION_CODES.CANCEL) {
      this.setDeleteModal(false);
      return;
    }
    this.setDeleteModal(false);
    this.onChangeFilters();
  }

  private setDeleteModal(value: boolean) {
    this.setState((prev) => {
      return {
        ...prev,
        deleteModalOpen: value,
      };
    });
  }

  private setSelectedRow(selectedRow: SingleEmailComponent) {
    this.setState((prev) => {
      return {
        ...prev,
        selectedRow: selectedRow,
      };
    });
  }

  private async getData() {
    this.setLoading(true);
    const categoryQueryString = getCategoryQueryString(
      this.state.componentTypeList,
      this.state.selectedComponentTypes,
      true
    );
    const componentPromise = getEmailComponents({
      categoryQueryString,
      queryParams: {
        name: this.searchString,
        page: this.state.page,
        pageSize: this.state.pageSize,
        sort: this.state.sort,
      },
    });
    const componentTypePromise = getComponentTypes();
    try {
      const response = await Promise.all([
        componentPromise,
        componentTypePromise,
      ]);
      const componentData = response[0];
      const typeData = response[1];
      const displayList = getFormattedEmailComponents({
        response: componentData,
      });
      const componentTypeList = getTemplateCategoryList(typeData);
      this.setState((prev) => {
        return {
          ...prev,
          apiData: componentData,
          metaData: response[0].meta,
          componentTypeList,
          displayList,
          loading: false,
        };
      });
    } catch (error) {

      this.setErrorState();
    }
  }

  private setLoading(value: boolean) {
    this.setState((prev) => {
      return {
        ...prev,
        loading: value,
      };
    });
  }

  private setErrorState() {
    this.setState((prev) => {
      return {
        ...prev,
        componentTypeList: [],
        displayList: [],
        loading: false,
      };
    });
  }
}

export const getEmailComponents = (args: {
  categoryQueryString?: string;
  queryParams?: ITemplateQueryParams;
  useProxy?: boolean;
  proxyHeaders?: any;
}) => {
  const {categoryQueryString, queryParams} = args;
  const url = `${ContentTypes.emailComponents.path}?${categoryQueryString}`;
  return getTemplates(url, queryParams, undefined, args.useProxy, args.proxyHeaders);
};

export const getEmailComponentById = (args: {id: string | undefined}) => {
  const {id} = args;
  return getTemplateById(ContentTypes.emailComponents.path, id);
};

export const deleteEmailComponent = (args: {id: string | undefined}) => {
  const {id} = args;
  const url = `${ContentTypes.emailComponents.path}`;
  return deleteTemplate(url, id);
};

export const createEmailComponent = (args: {
  singleEmailComponent: SingleEmailComponent;
  override?: boolean;
}) => {
  const {singleEmailComponent, override} = args;
  const url = `${ContentTypes.emailComponents.path}`;
  const body = {
    data: {
      name: singleEmailComponent.name,
      bodyJson: singleEmailComponent.bodyJson,
      bodyHtml: singleEmailComponent.bodyHtml,
      bodyMjml: singleEmailComponent.bodyMjml,
      isDefault: singleEmailComponent.isDefault,
      forceDefault: override || false,
      component_type: singleEmailComponent.componentType.id,
    },
  };
  return createTemplate(url, body);
};

export const updateEmailComponent = (args: {
  id: string | undefined;
  singleEmailComponent: SingleEmailComponent;
  override?: boolean;
}) => {
  const {id, singleEmailComponent, override} = args;
  const url = `${ContentTypes.emailComponents.path}`;
  const body = {
    data: {
      name: singleEmailComponent.name,
      bodyJson: singleEmailComponent.bodyJson,
      bodyHtml: singleEmailComponent.bodyHtml,
      bodyMjml: singleEmailComponent.bodyMjml,
      isDefault: singleEmailComponent.isDefault,
      forceDefault: override || false,
      component_type: singleEmailComponent.componentType.id,
    },
  };
  return updateTemplate(url, id, body);
};

export const getComponentTypes = async (useProxy?: boolean, proxyHeaders?: any) => {
  if (useProxy) {
    const formWidgetDataString = await LocalStorage.getItem('formWidgetData');
    const formWidgetData = formWidgetDataString ? JSON.parse(formWidgetDataString) : '';
    const formWidgetAccessToken = formWidgetData?.formWidgetAccessToken;

    const response = await cmsService.get('/proxy/cms/api/component-types', {
      baseURL: CARE_STUDIO_NEST_URL,
      headers: {
        'form-widget-access-token': formWidgetAccessToken,
        ...(proxyHeaders || {}),
      },
    });

    return response.data;
  } else {
    const response = await cmsService.get('/api/component-types');
    return response.data;
  }
};

export const getFormattedEmailComponents = (args: {
  response: EmailComponentApiResponse;
}): SingleEmailComponent[] => {
  const {response} = args;
  const result: SingleEmailComponent[] = [];
  const data = response.data;
  data.forEach((item) => {
    const attributes = item.attributes;
    result.push({
      id: item.id,
      bodyHtml: attributes.bodyHtml,
      bodyJson: attributes.bodyJson,
      bodyMjml: attributes.bodyMjml,
      isDefault: attributes.isDefault,
      lastUpdated: attributes.updatedAt,
      name: attributes.name,
      componentType: getFormattedCategoryData(attributes.component_type),
    });
  });
  return result;
};

export const getFormattedSingleEmailComponent = (args: {
  response: {data: EmailComponentData};
}): SingleEmailComponent => {
  const data = args.response.data;
  const attributes = data.attributes;
  return {
    name: attributes.name,
    bodyHtml: attributes.bodyHtml,
    bodyJson: attributes.bodyJson,
    bodyMjml: attributes.bodyMjml,
    componentType: getFormattedCategoryData(attributes.component_type),
    id: data.id,
    isDefault: attributes.isDefault,
    lastUpdated: attributes.updatedAt,
  };
};

export const validateComponent = (
  component: SingleEmailComponent
): {
  errors: {type: string; name: string};
  valid: boolean;
} => {
  const errors = {
    type: '',
    name: '',
  };
  let valid = true;
  errors.name = !component?.name ? 'Name is required' : '';
  errors.type = !component?.componentType?.code ? 'Type is required' : '';
  if (errors.name || errors.type) {
    valid = false;
  }
  return {
    errors,
    valid,
  };
};

export const toSnakeCase = (str: string) => {
  if (!str) {
    return '';
  }
  return str.toLowerCase().split(' ').join('_');
};

export const getComponentId = (componentName: string) => {
  return `global_${toSnakeCase(componentName)}`;
};

export const isEmptyComponent = (
  singleEmailComponent: SingleEmailComponent
) => {
  const check = singleEmailComponent.bodyJson?.children?.length > 0;
  return !check;
};
