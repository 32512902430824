import {StyleSheet} from 'react-native';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import React from 'react';

export const getStyles = ({finalListHeight}: {finalListHeight?: string}) =>
  StyleSheet.create({
    conversationList: {
      height: '100%',
      overflow: 'scroll',
      scrollBehavior: 'smooth',
      marginRight: 0,
    },
    conversationListItem: {
      overflow: 'hidden',
      flexDirection: 'row',
      flex: 1,
    },
    flex1: {
      flex: 1,
    },
    pv4: {
      paddingVertical: 4,
    },
    noDataFoundMainContainer: {
      height: finalListHeight,
    },
    noDataFoundContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      top: isWeb() ? '36vh' : undefined,
    },
    noDataFoundText: {
      textAlign: 'center',
    },
  });

export const getCSSProperties = ({
  finalListHeight,
}: {
  finalListHeight?: string;
}): Record<string, React.CSSProperties> => {
  return {
    renderConversationBlock: {
      height: finalListHeight,
      overflow: 'scroll',
      scrollBehavior: 'smooth',
      marginRight: 0,
    },
  };
};
