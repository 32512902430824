import {View, Text} from 'react-native';
import React from 'react';
import {Colors} from '../../../../../styles';

interface ICommunicationStatDetailsProps {
  totalSavings: number;
  memberCount: number;
  recordCount: number;
}

const CommunicationStatDetails = (props: ICommunicationStatDetailsProps) => {
  const {memberCount, recordCount, totalSavings} = props;
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const numberFormatter = new Intl.NumberFormat('en-US');
  return (
    <div style={{width: '40%'}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 8,
            padding: 12,
            width: '90%',
            border: '1px solid #E4E7EC',
          }}
        >
          <div
            style={{
              color: Colors.Custom.Gray500,
              fontFamily: 'Manrope',
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            Total Savings
          </div>
          <div
            style={{
              color: '#000',
              fontFamily: 'Manrope',
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            {USDollar.format(totalSavings)}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '90%',
            padding: 16,
            borderRadius: 8,
            border: `1px solid #E4E7EC`,
          }}
        >
          <div
            style={{
              color: Colors.Custom.Gray500,
              fontFamily: 'Manrope',
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            Total Patients Engaged
          </div>
          <div
            style={{
              color: '#000',
              fontFamily: 'Manrope',
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            {numberFormatter.format(memberCount)}
          </div>
          <div
            style={{
              height: 1,
              background: '#E4E7EC',
              width: '100%',
              marginTop: 10,
              marginBottom: 10,
              marginRight: 'auto',
              marginLeft: 'auto',
            }}
          ></div>
          <div
            style={{
              color: Colors.Custom.Gray500,
              fontFamily: 'Manrope',
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            Total Instances
          </div>
          <div
            style={{
              color: '#000',
              fontFamily: 'Manrope',
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            {numberFormatter.format(recordCount)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunicationStatDetails;
