import {Drawer, Table} from 'antd';
import {Text, useMediaQuery, View} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import MemberInfoListItem from '../../../../common/MemberInfoListItem/MemberInfoListItem';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {IContact} from '../../../TeamInbox/Conversations/interfaces';
import {getColumnValueByDataType} from '../EmployerUtils/DrillDownMemberListUtils';
import {IContactDrillDownRowDataList, IDrillDownColumnList} from '../interface';
import {IDrillDownRowDataView} from './interfaces';

const DrillDownRowDataView = (props: IDrillDownRowDataView) => {
  const {onClose, selectedContact} = props;
  const intl = useIntl();
  const [drillDownRowDataViewState, setDrillDownRowDataViewState] = useState({
    isVisible: true,
  });
  const getDrillDownColumn = () => {
    const columnList: any = props.columnList?.map((item) => {
      const columnItem = {
        title: (
          <Text size="xsMedium" color={Colors.Custom.Gray500}>
            {item.columnName}
          </Text>
        ),
        dataIndex: item.columnKey,
        key: item.columnKey,
        render(value: any) {
          const valueData = getColumnValueByDataType(value, item);
          return (
            <Text size={'smMedium'} color={Colors.Custom.Gray700}>
              {valueData}
            </Text>
          );
        },
      };
      return columnItem;
    });
    return columnList;
  };
  const getDrillDownData = () => {
    const contactRowData = props.selectedDrillDownRowData.contactRowData?.map(
      (item) => {
        const obj: any = {};
        item.forEach((item) => {
          obj[item.columnKey] = item.value;
        });
        return obj;
      }
    );
    return contactRowData;
  };
  const [isSmallScreen] = useMediaQuery([{maxWidth: 1300}]);
  return (
    <Drawer
      visible={drillDownRowDataViewState.isVisible}
      onClose={() => {
        setDrillDownRowDataViewState((prev) => {
          return {
            ...prev,
            isVisible: false,
          };
        });
        onClose();
      }}
      width={isSmallScreen ? '85vw' : '78vw'}
      title={
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <MemberInfoListItem
            contactData={selectedContact}
            showDateOfBirth={true}
            showEmail
            showPhoneNumber
          />
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.SECONDARY,
              onPress: () => {
                setDrillDownRowDataViewState((prev) => {
                  return {
                    ...prev,
                    isVisible: false,
                  };
                });
                onClose();
              },
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'back',
              }),
              withRightBorder: false,
            }}
          />
        </View>
      }
    >
      <Table
        dataSource={getDrillDownData()}
        columns={getDrillDownColumn()}
        pagination={false}
      />
    </Drawer>
  );
};

export default DrillDownRowDataView;
