import React from 'react';

function MedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="26"
      fill="none"
      viewBox="0 0 12 22"
      id="med-icon"
    >
      <path
        fill="#C2C2C2"
        d="M6 0a6 6 0 016 6v10a6.001 6.001 0 01-12 0V6a6.001 6.001 0 016-6zm4 12H2v4a4 4 0 108 0v-4.002V12zM6 2a4 4 0 00-4 4V10h8v-4a3.998 3.998 0 00-4-4V2z"
      ></path>
    </svg>
  );
}

export default MedIcon;
