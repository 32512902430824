import BaseService from "../../../../../../services/CommonService/BaseService";
import { Colors } from "../../../../../../styles/Colors";
import { MESSAGE_TYPE } from "../../interfaces";
import { SEARCH_MSG_CONST, mentionRegexForMessaging } from "../MsgConst";

export const getMessageThreadResult = async (
  conversationUuid: string,
  plainString: string,
  pageNo: number,
) => {
  try {
    if (conversationUuid && plainString && pageNo)
    {
      const baseService = BaseService.getSharedInstance().axios;
      const searchMessageThread = `crm-nest/account/conversation/${conversationUuid}/message/search`;
      const searchResponse = await baseService.get(searchMessageThread, {
        params: {
          searchString: plainString,
          pageNo: pageNo,
          pageSize: 10,
        },
      });
      return searchResponse?.data;
    }
    return [];
  } catch (error) {
    return {error: error};
  }
  
};

export const getFilteredMentionMessage = (plainString: string) => {
  let filteredString = plainString;
    const isMentionPresent = mentionRegexForMessaging.test(plainString);
    if (isMentionPresent) {
      filteredString = plainString?.replace(mentionRegexForMessaging, (match, userName, type, uuid) => {
      return `@${userName}`;
      });
    }
    return filteredString
}

export const getMessageContent = (requiredMsgLength: number, searchText: string, plainText?: string) => {
  if (plainText && searchText) {
    const plainString = getFilteredMentionMessage(plainText); 
    const searchTextLength = searchText?.length;
    const plainStringLength = plainString?.length;
    const plainStringWithLowerCase = plainString?.toLowerCase();
    const serachTextWithLowerCase = searchText?.toLowerCase();
    const index = plainStringWithLowerCase.indexOf(serachTextWithLowerCase);
    if (searchTextLength > requiredMsgLength) {
      if (index > 0) {
        return `...${searchText.slice(0, requiredMsgLength)}...`;
      } else {
        return `${searchText}...`;
      }
    } else if (plainStringLength > requiredMsgLength) {
      if (index > 0) {
        const filteredLength = (requiredMsgLength - searchTextLength) / 2;
        let prevLength = index - filteredLength;
        let nextLength = index + filteredLength;
        if (prevLength < 0) {
          nextLength = nextLength + Math.abs(prevLength);
          prevLength = 0;
          return `${plainString.slice(
            prevLength,
            nextLength
          )}...`;
        }
        if (nextLength >= plainStringLength) {
          prevLength = prevLength - (nextLength - plainStringLength - 1);
          nextLength = plainStringLength - 1;
          return `...${plainString.slice(
            prevLength,
            nextLength
          )}`;
        }
        return `...${plainString.slice(
          index - filteredLength,
          index + searchTextLength + filteredLength
        )}...`;
      } else {
        return `${plainString.slice(0, requiredMsgLength)}...`;
      }
    } else {
      return plainString;
    }
  }
  return '';
};

export const getMessageSenderName = (msgData: any, currentUserUuid?: string, currentUserDataId?: number) => {
  const isAutoResponse = msgData?.contentAttributes?.isAutoResponse || false;
  let senderName = '';
  if (msgData?.senderType?.toLowerCase() == SEARCH_MSG_CONST.USER) {
    if (
      msgData?.senderUser?.uuid == currentUserUuid ||
      msgData?.senderUser?.id == currentUserDataId
    ) {
      senderName = SEARCH_MSG_CONST.YOU;
    } else {
      senderName = msgData?.senderUser?.name || msgData?.senderFullName || '';
    }
  } else {
    senderName =
      msgData?.senderContact?.name || msgData?.senderFullName || '';
  }
  if (
    isAutoResponse ||
    msgData?.messageType === MESSAGE_TYPE.automation_message
  ) {
    senderName = SEARCH_MSG_CONST.AUTOMATED_MSG;
  }
  return senderName.length ? senderName : '';
};


export const getMsgBoxColor = (isPrivateMsg: boolean, messageType?: number) => {
  if (messageType === 0) {
    return Colors.Custom.ContainerBGColor;
  }
  if (isPrivateMsg === true) {
    return Colors.Custom.brandAccentColor;
  } else {
    return Colors.Custom.mainPrimaryPurple;
  }
};