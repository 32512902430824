import { View, Text } from "native-base";
import { Colors } from "../../../../styles/Colors";
import { Column, ColumnConfig, Gauge, GaugeConfig } from "@ant-design/charts";

const JourneyGauge = (props:{
    value:number;
    width?:number;
    height?:number;
    fontSize?:string;
    rangeWidth?:number;
}) => {
    const gaugeConfig: GaugeConfig = {
        percent:(props?.value)/100,
        height:props?.height || 108,
        width: props?.width || 200,
        renderer: 'svg',
        range: {
            color: '#80A4D5',
            width: props?.rangeWidth || 12,
        },
        tooltip: {

        },
        indicator: {
            pointer: {
                style: {
                    stroke: Colors.Custom.Gray300,
                },
            },
            pin: {
                style: {
                    stroke: Colors.Custom.Gray300,
                },
            },
        },
        axis: {
            label: {
                formatter(v) {
                    return '';
                },
            },
            subTickLine: null,
            tickLine: null
        },
        statistic: {
            content: {
                formatter: () => `${props?.value}%`,
                style: {
                    fontSize: props?.fontSize ||  '20px',
                    lineHeight: '36px',
                },
            },
        },
    }

    return (
        <Gauge {...gaugeConfig} />
    )
}

export default JourneyGauge;