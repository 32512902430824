import React from "react";
import { StyleSheet } from "react-native";
import { Colors } from "../../../styles/Colors";

export const styles = StyleSheet.create({
  margin0: {
    margin: 0
  },
  dividerView: {
    backgroundColor: Colors.Custom.Gray200,
    height: 1,
    width: '100%',
    marginTop: 16,
    marginBottom: 24,
  },
  marginLeft12: {
    marginLeft: 12,
  },
  flexDirectionRow: {
    flexDirection: 'row',
  },
  box16: {
    width: 16,
    height: 16,
  },
  ml12mr16: {
    marginLeft: 12,
    marginRight: 16,
  },
  mt16: {
    marginTop: 16,
  },
  fullWidthFdRowAiCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  h36Br4: {
    height: 36,
    borderRadius: 4,
  },
  ml12: {
    marginLeft: 12,
  },
  mt3: {
    marginTop: 3,
  },
  mt12: {
    marginTop: 12,
  },
  ml12mb8: {
    marginLeft: 12,
    marginBottom: 8,
  },
  mb40ml12: {
    marginBottom: 40,
    marginLeft: 12,
  },
  mt44ml12: {
    marginTop: 44,
    marginLeft: 12,
  },
  ml6mr4: {
    marginLeft: 6,
    marginRight: 4,
  },
  fdRowAiCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ml10: {
    marginLeft: 10,
  },
  fdRowAiCenterMt12: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
  },
  fdRowAiCenterMt44: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 44,
  },
  br12: {
    borderRadius: 12,
  },
  fdRowAiCenterMt20: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  fdColumnAiCenter: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  cGray500Mt30TaCenter: {
    color: Colors.Custom.Gray500,
    marginTop: 30,
    textAlign: 'center',
  },
  fdRowAiCenterJcCenterMt24: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 24,
  },
  mb16: {
    marginBottom: 16,
  },
  mt4: {
    marginTop: 4,
  },
  p10: {
    padding: 10,
  },
  bgBackgroundColorMinWidth600: {
    backgroundColor: Colors.Custom.BackgroundColor,
    minWidth: 600,
  },
  bgBackgroundColor: {
    backgroundColor: Colors.Custom.BackgroundColor,
  },
  bgBackgroundColorDflexJcEnd: {
    backgroundColor: Colors.Custom.BackgroundColor,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  spinner: {
    margin: '0 auto',
    position: 'absolute',
    zIndex: 100011111,
    backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0,
  },
  mt40: {
    marginTop: 40,
  },
  pt10: {
    paddingTop: 10,
  },
  colorError500: {
    color: Colors.error['500'],
  },
  overflowHiddenBgWhite: {
    overflow: 'hidden',
    // backgroundColor: '#fff',
  },
  aiCenterJcSpaceBetween: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mt30: {
    marginTop: 30,
  },

});

export const reactStyles: Record<string, React.CSSProperties> = {
  antSelect:{
    backgroundColor: '#fff',
    borderRadius: 32,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray300,
  },
  p0br16: {
    padding: 0,
    borderRadius: 16,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  fs12: {
    fontSize: 12,
  },
  selectStyle: {
    height: 39,
    fontSize: 14,
    lineHeight: 21,
    fontWeight: 500,
    width: 110,
    alignSelf: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  }
}
