import React, {useCallback, useState} from 'react';
import {Spin} from 'antd';
import {PieChart, Pie, Tooltip, Cell, Sector} from 'recharts';

const renderActiveShape = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
  payload,
  value,
  onClick,
}: {
  cx: any;
  cy: any;
  midAngle: any;
  innerRadius: any;
  outerRadius: any;
  startAngle: any;
  endAngle: any;
  fill: any;
  payload: any;
  value: any;
  onClick: any;
}) => {
  const RADIAN = Math.PI / 180;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const mx = cx + (outerRadius + 5) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        style={{cursor: 'pointer'}}
        onClick={onClick && onClick}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <text x={ex + cos} y={ey} textAnchor={textAnchor} fill="#333">
        {payload.name}
      </text>
      <text x={ex + cos} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {value}
      </text>
    </g>
  );
};

export default function PieChartStyled({
  data,
  loading = true,
  dataKey = 'value',
  onClick,
}: {
  data: any;
  loading: boolean;
  dataKey: string;
  onClick: any;
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  if (loading) {
    return <Spin />;
  }

  return (
    <PieChart width={500} height={282}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        innerRadius={40}
        outerRadius={80}
        fill="#8884d8"
        dataKey={dataKey}
        onClick={onClick && onClick}
        onMouseEnter={onPieEnter}
      >
        <Tooltip />
        {data.map((entry: any) => (
          <Cell key={`cell-${entry.color}`} fill={entry.color} />
        ))}
      </Pie>
    </PieChart>
  );
}
