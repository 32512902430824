import {FC, useState} from 'react';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../styles';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../constants';
import {Drawer} from 'antd';
import {useMediaQuery} from 'native-base';

interface IFilterDrawerProps {
  onReset: () => void;
  onApply: () => void;
  onClose: () => void;
  children: JSX.Element;
}

export const FilterDrawerContainer: FC<IFilterDrawerProps> = ({
  onReset,
  onApply,
  onClose,
  children,
}) => {
  const [visible, setVisible] = useState(true);
 
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const finalWidth = isIPadScreen || isIPadMiniScreen ? '40vw' : '30vw';

  return (
    <Drawer
      visible={visible}
      style={{minWidth: 700}}
      width={finalWidth}
      closable={false}
      onClose={() => {
        setVisible(false);
        onClose();
      }}
      title={
        <>
          <ModalActionTitle
            title={'filters'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'reset',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onReset();
                },
              },
              {
                show: true,
                id: 2,
                btnText: 'Apply',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  onApply();
                },
              },
            ]}
          />
        </>
      }
    >
      {children}
    </Drawer>
  );
};
