import {View} from 'native-base';
import {useContext} from 'react';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {getEnvVariable} from '../../../../../constants/BaseUrlConst';
import {
  CommonDataContext,
  ICommonData,
} from '../../../../../context/CommonDataContext';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import NewAnalyticsDataComponentView from '../../../BrazilCodeMR/pages/analytics/NewAnalytics';
import OldAnalyticsDataComponentView from '../../../BrazilCodeMR/pages/analytics/OldAnalytics';
import AccountDashboard from './AccountDashboard';
import EmpDashboard from './AccountDashboard';

export default function AnalyticsDataComponentView(props: any) {
  const {isHideMainTitleBar, isFromEmployerListing} = props;
  const host = window?.location?.host || '';
  const commonData: any = useContext(CommonDataContext);

  const getFeatureFlag = (code: string, commonData?: ICommonData) => {
    const configObj = commonData?.accountConfigData?.[code];
    if (configObj?.accountConfigurations?.length) {
      if (configObj?.accountConfigurations?.[0].value == 'ON') {
        return true;
      } else {
        return false;
      }
    } else {
      if (configObj?.defaultValue == 'ON') {
        return true;
      } else {
        return false;
      }
    }
  };
  const isDashboardView = getFeatureFlag(
    CONFIG_CODES.ENABLE_EMPLOYER_REPORTING,
    commonData
  );

  return (
    <View>
      <>
        <AccountDashboard
          state={props.state}
          dateRange={props.dateRange}
          dashboardCallback={props.dashboardCallback}
          isFromEmployerListing={isFromEmployerListing}
          dashboardAction={props.dashboardAction}
        />
      </>
    </View>
  );
}
