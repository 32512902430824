
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  ANALYTICS_RESOURCE,
  DISPLAY_TYPE,
  EMPLOYER_REPORT_FILTER_CODES,
} from '../../../../../../constants';
import {IAppliedFilter, IMultiViewResourceData, IWidgetCardView} from '../../interface';
// import ReactToPrint, {useReactToPrint} from 'react-to-print';
import {Skeleton, Typography} from 'antd';
import NodataViewTypeOne from '../NoDataView/NodataViewTypeOne';
import {
  getDemoDataForEmployerReportCurrentSection,
  getTenantForDemo,
} from '../../tempDataFile';
import {getSectionDataByResourceCode} from '../../../../../../services/Employer/EmployerServices/EmployerServices';
import {Pressable} from 'react-native';
import RestSvgIcons from '../../../../../common/Svg/RestSvgIcons';
import {Tooltip} from 'native-base';
import {getEmployerReportFilterList} from '../../../../../../utils/commonUtils';
import WidgetHeader from '../../WidgetHeader/WidgetHeader';
import {isDataPresentForRenderWidget} from '../EmployerReportUtils';
import {PreVSPostDPCWidget} from './PreVsPostDpc/PreVSPostDPCWidget';
import {WIDGET_ACTION_CODE} from '../../EmployerUtils/EmployerConst';
const {Text} = Typography;
const MixLineStackColumnChart = (props: IWidgetCardView) => {
  const {
    sectionData,
    employerId,
    isAccountDashboard,
    memberType,
    hintMembershipDisabled,
  } = props;
  const containerRef: any = useRef()
  const titleRef: any = useRef();
  const [stateData, setStateData] = useState({
    resourceData: {} as IMultiViewResourceData,
    loading: false,
    employeeTotalCount: 0,
    selectedView: '',
    selectedResourceData: {} as any,
    masterData: {} as any,
    appliedFilter: props.appliedFilter,
    filterValueName: [] as string[],
    filterValueString: '',
    selectedAppliedFilter: [] as any[],
    graphWrapperHeight: 350,
  });

  const onActionClick = (code: string, data?: any) => {
    props.onActionClick?.(code, data)
  }


  const getMultiViewChartPlotByType = () => {
    let ele = <></>;
    switch (sectionData.currentDisplayType) {
      case DISPLAY_TYPE.MULTI_PLOT_VIEW_CHART: {
        ele = getMultiViewChartElem();
        break;
      }
    }
    return ele;
  };



  const getMultiViewChartElem = () => {
    const ele = <></>;
    switch (props?.sectionData?.resourceCode) {
      case ANALYTICS_RESOURCE.PRE_DPC_VS_POST_DPC: {
        const tempStateData = {
          resourceData: stateData?.resourceData,
          loading: stateData?.loading,
          selectedView: stateData?.selectedView,
          selectedResourceData: stateData?.selectedResourceData,
          masterData: stateData?.masterData,
          appliedFilter: stateData?.appliedFilter,
          filterValueName: stateData?.filterValueName,
          filterValueString: stateData?.filterValueString,
          selectedAppliedFilter: stateData?.selectedAppliedFilter,
          graphWrapperHeight: stateData?.graphWrapperHeight,
        };
        return (
          <PreVSPostDPCWidget
            stateData={tempStateData}
            onActionClick={props?.onActionClick}
            sectionData={sectionData}
          />
        )
      }
    }
    return ele;
  }
  const multiViewPlot = useMemo(() => {
    return getMultiViewChartPlotByType()
  }, [stateData.loading])

  const getSectionByResourceCode = async (appliedFilter?: IAppliedFilter[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    let filterList: any = appliedFilter?.length ? appliedFilter : stateData.appliedFilter;
    if (!filterList?.length) {
      filterList = [];
    }
    let appliedFilterList = [];
    if (sectionData?.appliedFilter) {
      appliedFilterList = getEmployerReportFilterList(sectionData?.appliedFilter);
      if (appliedFilterList?.length && filterList?.length) {
        filterList = [...filterList, ...appliedFilterList];
      } else if (appliedFilterList?.length) {
        filterList = [...appliedFilterList];
      }
    }
    if (hintMembershipDisabled) {
      filterList.push({
        filterCode: EMPLOYER_REPORT_FILTER_CODES.MEMBERSHIP, 
        filterValue: hintMembershipDisabled
      })
    }
    const response = await getSectionDataByResourceCode(
      employerId,
      sectionData.resourceCode || '',
      filterList,
      '',
      isAccountDashboard,
      memberType,
    ).catch(() => {
      setStateData((prev: any) => {
        return {
          ...prev,
          loading: false,
          resourceData: {
            columnStackDataList: [],
            lineDataList: []
          },
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data && (response?.data?.result?.postDpcDataList?.length || response?.data?.result?.preDpcDataList?.length)) {
      const containerHight = containerRef?.current?.offsetHeight;
      const titleWrapperHight = titleRef?.current?.offsetHeight;
      const heightVariable = (props.sectionData?.subHeading?.length || 0) > 100 ? 20 : 10
      const graphWrapper = containerHight - titleWrapperHight - heightVariable
      const resourceData = response.data?.result;
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
          graphWrapperHeight: graphWrapper
        };
      });
    } else {
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev: any) => {
        return {
          ...prev,
          resourceData: {
            columnStackDataList: [],
            lineDataList: []
          },
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };
  const controller = new AbortController();
  useEffect(() => {
    if (getTenantForDemo()) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: true
        }
      })
      let resourceData: any = getDemoDataForEmployerReportCurrentSection(sectionData);
      resourceData = resourceData?.result;
      setTimeout(() => {
        setStateData((prev) => {
          return {
            ...prev,
            resourceData: resourceData,
            loading: false
          }
        })
      }, 3000)
      return
    } else {
      getSectionByResourceCode();
    }
    return () => controller?.abort();
  }, [sectionData.id])
  useEffect(() => {
    if (props.filterData?.resourceCode == sectionData.resourceCode) {
      const appliedFilter = stateData.appliedFilter?.length ? [...stateData.appliedFilter] : [];
      const selectedFilter = {
        filterCode: props.filterData?.filterCode,
        filterValue: props.filterData?.filterValue
      }
      let filterValueString = '';
      props.filterData?.filterValueName?.forEach((item: string, index: number) => {
        if (index < 2) {
          return
        }
        filterValueString = filterValueString + (filterValueString.length ? " | " : '') + item
      })
      setStateData(prev => {
        return {
          ...prev,
          filterValueName: props.filterData?.filterValueName,
          filterValueString: filterValueString,
          selectedAppliedFilter: [selectedFilter]
        }
      })
      appliedFilter.push(selectedFilter);
      getSectionByResourceCode(appliedFilter);
    }
    return () => controller?.abort();
  }, [props.filterData?.filterValue])

  const getRefreshButtonOnLocalEnv = () => {
    if (window.location.host.includes('localhost')) {
      return (
        <Pressable
          style={{marginHorizontal: 3}}
          onPress={() => {
            setStateData(prev => {
              return {
                ...prev,
                filterValueName: [],
                filterValueString: ''
              }
            })
            getSectionByResourceCode();
          }}
        >
          <Tooltip label="Refresh">
            <RestSvgIcons />
          </Tooltip>
        </Pressable>
      )
    }
    return <></>;
  }
  const getMainWidgetWidth = () => {
    let width = '88%';
    switch (sectionData.resourceCode) {
      case ANALYTICS_RESOURCE.PRE_DPC_VS_POST_DPC:
        width = '97%';
        break;
    }
    return width;
  }

  return (
    <div ref={containerRef} className="widgetBoxShadow">
      <div ref={titleRef}>
        <WidgetHeader isPrintPreview={props.isPrintPreview} isPrintDownload={props.isPrintDownload} refreshButtonOnLocalEnv={getRefreshButtonOnLocalEnv()} sectionData={sectionData} onActionClick={onActionClick} />
      </div >
      {
        stateData.loading ? (
          <Skeleton style={{padding: 40}} active={true} />
        ) : (
          <>
            {isDataPresentForRenderWidget(stateData.resourceData, props?.sectionData?.resourceCode || '')
              ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    height: '60%'
                  }}
                >
                  <main style={{alignSelf: 'center', width: '100%', height: '100%', justifyContent: 'center', display: 'flex', alignItems: 'flex-start'}}>
                    <main
                      style={{padding: '10px 0px', width: getMainWidgetWidth()}}
                      className={isDataPresentForRenderWidget(stateData.resourceData, props?.sectionData?.resourceCode || '') && props.isPrintPreview ? 'canvas-wrapper' : ''}
                    >
                      {multiViewPlot}
                    </main>
                  </main>
                </div>
              ) :
              (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '60%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <NodataViewTypeOne viewCode={'TEXT_ICON'} />
                </div>
              )
            }
          </>
        )
      }
    </div >
  );
};

export default React.memo(MixLineStackColumnChart);



