import {Box, Text, HStack, Spacer, VStack, Pressable, View} from 'native-base';
import {IFormCategoryProps} from '../../interfaces';
import {Colors} from '../../../../../styles';

const FormCategoryCard = (props: IFormCategoryProps) => {
  const {category, selectedCategoryId} = props;

  return (
    <View
      bgColor={
        category.id === selectedCategoryId
          ? Colors.primary['100'] + '7a'
          : 'white'
      }
      textAlign={'start'}
      borderWidth="1"
      shadow="2"
      borderRadius={10}
      marginY={2}
      marginX={0.5}
      borderColor={'white'}
    >
      <Pressable
        _hover={{bg: Colors.primary['100'] + '7a'}}
        onPress={() => {
          if (props.onPress) {
            props.onPress(category);
          }
        }}
        borderRadius={10}
      >
        <Box height={60} width={40} m={4} overflow={'hidden'}>
          <VStack flexWrap={'wrap'}>
            <Text fontWeight={'bold'} fontSize={14} color={'black'}>
              {category.name}
            </Text>
          </VStack>
        </Box>
      </Pressable>
    </View>
  );
};

export default FormCategoryCard;
