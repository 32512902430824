import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { IDiagnosisGap } from './interfaces';
import { Divider, Pressable, Text, View, VStack, HStack } from 'native-base'
import { Colors } from '../../styles/Colors';
import BackArrowSvg from '../common/Svg/SideCarSvg/BackArrowSvg';
import { ModalActionTitle } from '../common/ModalActionTitle/ModalActionTitle';
import { FoldButton } from '../CommonComponents/FoldButton/FoldButton';
import { DiagnosisGapDismissal } from './DiagnosisGapDismissal';
import { IContact } from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import DocumentMedicineSvg from '../common/Svg/DocumentMedicine';
import { getDateStrFromFormat } from '../../utils/DateUtils';
import { DISPLAY_SLASH_DATE_FORMAT } from '../../constants';

interface DiagnosisGapDetailsProps {
  onClose: () => void;
  diagnosisGapDetail?: IDiagnosisGap;
  contactData: IContact | undefined;
  style?: {
    paddingHorizontal: number
    paddingVertical: number
  }
}

const DiagnosisGapDetailView = (props: DiagnosisGapDetailsProps) => {
  const { onClose, contactData, diagnosisGapDetail } = props;
  const conditionDetails = diagnosisGapDetail?.conditions;
  const intl = useIntl();
  const [isDeclined, setIsDeclined] = useState(false);

  const onDeclineClick = () => {
    setIsDeclined(true);
  };

  if(isDeclined){
    return (
      <DiagnosisGapDismissal
        open={isDeclined}
        onClose={() => {
          onClose();
          setIsDeclined(false);
        }}
        contactData={contactData}
        diagnosisGap={diagnosisGapDetail as IDiagnosisGap}
      />
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Pressable
          onPress={() => {
            onClose();
          }}
          style={styles.backButton}
        >
          <BackArrowSvg />
        </Pressable>
        <Text style={styles.headerText}>
          {intl.formatMessage({id: 'reviewDiagnosisGap'})}
        </Text>
      </View>
      <View backgroundColor={Colors.Custom.White} padding={3}>
        <ModalActionTitle
          shouldUseFlexInTitle={true}
          titleNode={
            <View mb={1} width={'190%'}>
              <Text
                style={styles.titleText}
                numberOfLines={0}
              >
                {diagnosisGapDetail?.hccDescription}
              </Text>
            </View>
          }
          // titleSize={14}
          titleColor={''}
          rightButton={
            <View style={styles.buttonContainer}>
              <FoldButton
                customProps={{
                  btnText: intl.formatMessage({id: 'decline'}),
                }}
                nativeProps={{
                  onPress:() => {
                    onDeclineClick()
                  },
                  isLoading: false,
                  borderRadius: 4,
                  borderWidth: 0.5,
                  borderColor: Colors.FoldPixel.GRAY200,
                  paddingX: 4,
                  backgroundColor: Colors.Custom.White,
                  _text: {
                    fontSize: 12,
                    color: Colors.FoldPixel.GRAY300,
                    fontWeight: 500,
                  },
                  _hover: {
                    backgroundColor: Colors.FoldPixel.GRAY50,
                    _text: {
                      color: Colors.FoldPixel.GRAY300,
                      fontWeight: 500,
                    },
                  },
                }}
              />
              {/* comment this button for now */}
              {/* <FoldButton
                customProps={{
                  btnText: intl.formatMessage({id: 'accept'}),
                }}
                nativeProps={{
                  onPress:() => {
                  },
                  isLoading: false,
                  borderRadius: 4,
                  borderWidth: 0.5,
                  borderColor: Colors.Custom.Primary200,
                  paddingX: 4,
                  marginLeft: 4,
                  backgroundColor: Colors.Custom.PrimaryColor100,
                  _text: {
                    fontSize: 12,
                    color: Colors.Custom.Primary300,
                    fontWeight: 500,
                  },
                  _hover: {
                    backgroundColor: Colors.Custom.Primary300,
                    _text: {
                      color: Colors.Custom.PrimaryColor100,
                      fontWeight: 500,
                    },
                  },
                }}
              /> */}
            </View>
          }
          subtitleBlock={
            <VStack>
              <HStack space={2} alignItems="center">
                <HStack>
                  <Text style={{ fontSize: 12, fontWeight: '400', color: Colors.FoldPixel.GRAY200 }}>HCC: </Text>
                  <View
                    style={{
                      paddingHorizontal: 4,
                      borderRadius: 4,
                      backgroundColor: Colors.FoldPixel.GRAY100,
                    }}
                  >
                    <Text style={{ fontSize: 12, fontWeight: '400', color: Colors.FoldPixel.GRAY200 }}>
                      {diagnosisGapDetail?.hccCode}
                    </Text>
                  </View>
                </HStack>
                <Text style={{ fontSize: 12, fontWeight: '400', color: Colors.FoldPixel.GRAY200 }}>
                  RAF: <Text style={{ color: Colors.FoldPixel.STATUS_IN_PROGRESS}}>{diagnosisGapDetail?.hccScore}</Text>
                </Text>
              </HStack>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 4,
                }}
              >
                <Text style={{ fontSize: 12, fontWeight: '400', color: Colors.FoldPixel.GRAY200 }}>Diagnosis Gaps</Text>
              </View>
            </VStack>
          }
        />

        <Divider marginY={2}/>
        {conditionDetails?.map((condition, index) => (
          <VStack key={`condition_${index}`}>
            <Text style={styles.icd10Label}>ICD-10</Text>
            <View display={'flex'} flexDirection={'row'} alignItems={'center'} marginBottom={2} paddingLeft={3}>
              <Text style={styles.conditionLabel}>
                {condition.icd10Code}:
              </Text>
              <Text style={styles.conditionText}>
                {condition.name}
              </Text>
            </View>
            {!!condition?.recordedDate && <View display={'flex'} flexDirection={'row'} alignItems={'center'} paddingLeft={3}>
              <Text style={styles.conditionLabel}>
                Last Recorded on:
              </Text>
              <Text style={styles.conditionText}>
                {getDateStrFromFormat(condition.recordedDate, DISPLAY_SLASH_DATE_FORMAT)}
              </Text>
            </View>}
            {!!condition?.recordedBy && <View display={'flex'} flexDirection={'row'} alignItems={'center'} marginBottom={2} paddingLeft={3}>
              <Text style={styles.conditionLabel}>
              Recorded by:
              </Text>
              <Text style={styles.conditionText}>
                {condition.recordedBy.split('/')[1]}
              </Text>
            </View>}
            {!!condition?.source && <View display={'flex'} flexDirection={'row'} alignItems={'center'} marginBottom={2} paddingLeft={3}>
              <Text style={styles.conditionLabel}>
                Source: 
              </Text>
              <Text style={styles.conditionText}>
                {condition.source}
              </Text>
            </View>}
            <Divider marginY={2}/>
          </VStack>
        ))}
      </View>
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    gap: 16,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 6,
    backgroundColor: '#ffffff',
  },
  backButton: {
    width: 28,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerText: {
    marginLeft: 6,
  },
  titleText: {
    fontSize: 14,
    lineHeight: 18,
    color: Colors.FoldPixel.GRAY400,
    fontWeight: '500',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  icd10Label: {
    fontSize: 12,
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY300,
    borderLeftColor: Colors.FoldPixel.PRIMARY300,
    borderLeftWidth: 2,
    paddingLeft: 10,
  },
  conditionLabel: {
    fontSize: 12,
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY300,
  },
  conditionText: {
    fontSize: 12,
    fontWeight: '400',
    color: Colors.FoldPixel.GRAY300,
  },
});

export default DiagnosisGapDetailView;
