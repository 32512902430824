import {Divider, Input, TextArea} from 'native-base';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {isEmployerRole} from '../../../../utils/commonUtils';
import EditTextSvg from '../../../common/Svg/EditTextSvg';
import EyeHideSvg from '../../../common/Svg/WidgetsSvg/EyeHideSvg';
import './customStyles.css';
import {DashboardDataContext} from './EmployerContext/DashboardContext';
import {WIDGET_ACTION_CODE} from './EmployerUtils/EmployerConst';
import {
  getAllSubsectionInfoText,
  getSectionHideAndShowCheck,
  getSectionWrapperClassName,
  getSubHeadingBySectionCode,
} from './EmployerUtils/WidgetUtils';
import {IAnalyticsSections, ISectionListItem} from './interface';
import SectionWidgetView from './SectionAndWidget/SectionWidgetView';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../styles';
import {Switch} from 'antd';
import {getAccountConfigValue} from '../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../constants/AccountConfigConst';

const AnalyticsSections = (props: IAnalyticsSections) => {
  const isEmployerRoleCheck = props.isEmployerRole || isEmployerRole() || false;
  const {employerData, dateRange, onWidgetLoaded} = props;
  const intl = useIntl();
  const [isHeaderInput, setIsHeaderInput] = useState<any>(null);
  const [sectionList, setSectionList] = useState<ISectionListItem[]>(
    [...props.sectionList]
  );
  const showSectionOrWidgetListConfig = getAccountConfigValue(CONFIG_CODES.SHOW_SECTION_OR_WIDGET)
  let showSectionOrWidgetListValue: any = []
  try {
    showSectionOrWidgetListValue = JSON.parse(showSectionOrWidgetListConfig)
  } catch  {
    showSectionOrWidgetListValue = []
  }
  const hideSectionOrWidgetListConfig = getAccountConfigValue(CONFIG_CODES.HIDE_SECTION_OR_WIDGET)
  let hideSectionOrWidgetListValue: any = []
  try {
    hideSectionOrWidgetListValue = JSON.parse(hideSectionOrWidgetListConfig)
  } catch  {
    hideSectionOrWidgetListValue = []
  }
  const showWidgetByCode = (code?: string)=> {
    return (showSectionOrWidgetListValue.length ?
      showSectionOrWidgetListValue.includes((code || '')
      ) : true)
  }
  const hideWidgetByCode = (code?: string)=> {
    return (hideSectionOrWidgetListValue.length ?
      !hideSectionOrWidgetListValue.includes((code || '')
      ) : true)
  }
  const sectionDescription = useRef<any>({} as any);
  useEffect(() => {
    setSectionList([...props.sectionList]);
  }, [props.sectionList]);
  return (
    <>
      {sectionList?.map((item: ISectionListItem, index: number) => {
        if (
          (item.childSectionWidget?.length > 0 &&
          getSectionHideAndShowCheck(
            props.isPrintPreview || false,
            item.isVisible,
            props.isPrintDownload,
            item.isDisabled
          ) && showWidgetByCode(item.sectionCategory) && hideWidgetByCode(item.sectionCategory))
        ) {
          const infoMergeText = getAllSubsectionInfoText(item.childSectionWidget, employerData.name, {
            fromDate: dateRange?.from,
            toDate: dateRange?.to
          });
          item.description = infoMergeText || '';
          return (
            <div
              key={index}
              style={{
                paddingLeft: props?.isPrintPreview ? 0 : 20,
                paddingRight: props?.isPrintPreview ? 0 : 20,
                marginTop: index == 0 ? 0 : 16,
              }}
            >
              <main
                key={index}
                id={item.sectionCategory}
                className={item.printClassName}
              >
                <div
                  key={index}
                  style={{
                  }}
                  className="common-style"
                >
                  <div
                    style={{
                      display: 'flex',
                      marginBottom: 16,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginLeft: 5,
                      alignItems: 'center',
                    }}
                    className="common-style"
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {props?.isPrintPreview && !props.isPrintDownload ? (
                        isHeaderInput == item.id ? (
                          <>
                            <Input
                              placeholder={intl.formatMessage({
                                id: 'reportTitlePlaceholder',
                              })}
                              _focus={{
                                borderColor: Colors.Custom.Gray200
                              }}
                              fontSize={16}
                              width={'500px'}
                              marginBottom={'8px'}
                              defaultValue={item.name}
                              onChangeText={(value: string) => {
                                const sectionListTemp = [...sectionList];
                                sectionListTemp[index].name = value;
                                setSectionList(sectionListTemp);
                              }}
                            />
                            <div
                              style={{
                                borderWidth: 0,
                                borderStyle: 'solid',
                                borderColor: '#C2A6F3',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 5,
                                borderRadius: 5,
                                marginLeft: 10,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setIsHeaderInput(null);
                              }}
                            >
                              <AntDesign name="close" size={15} />
                            </div>
                          </>
                        ) : (
                          <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                              <p
                                className="section-title-text"
                                style={{margin: 0, marginRight: 6}}
                              >
                                {item.name}
                              </p>
                              <div
                                style={{
                                  borderWidth: 1,
                                  borderStyle: 'solid',
                                  borderColor: '#C2A6F3',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  padding: 7.5,
                                  borderRadius: 5,
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setIsHeaderInput(item.id);
                                }}
                              >
                                <EditTextSvg />
                              </div>
                            </div>
                            <p
                              className="section-subtitle-title-text"
                              style={{margin: 0, marginRight: 6}}
                            >
                              {item.subHeading}
                            </p>
                          </div>
                        )
                      ) : (
                        <div>
                          <p
                            className="section-title-text"
                            style={{margin: 0, marginRight: 6}}
                          >
                            {item.name}
                          </p>
                          <p
                            className="section-subtitle-title-text"
                            style={{margin: 0, marginRight: 6}}
                          >
                            {item.subHeading}
                          </p>
                        </div>
                      )}
                      {!item.isVisible && !props.isPrintPreview && (
                        <EyeHideSvg />
                      )}
                    </div>
                    {props?.isPrintPreview && !props.isPrintDownload && (
                      <Switch
                        // isChecked={item.isVisible}
                        style={{
                          backgroundColor:
                            !sectionList[index].isDisabled
                              ? Colors.primary[300]
                              : Colors.Custom
                                .Gray200,
                        }}
                        checked={!sectionList[index].isDisabled}
                        onChange={(
                          value: boolean
                        ) => {
                          const sectionListTemp = sectionList.slice();
                          sectionListTemp[index].isDisabled = !value;
                          setSectionList(sectionListTemp);
                        }}
                      />
                    )}
                  </div>
                  <div className={`${item.sectionCategory}-container`}>
                    <div
                      id={`${item.sectionCategory}-wrapper`}
                      className={`card-widget-wrapper ${getSectionWrapperClassName(
                        item.sectionCategory || ''
                      )} ${props?.isPrintPreview ? 'in-print-drawer-wrapper' : ''}`}
                    >
                      {item?.childSectionWidget?.map(
                        (widgetItem: ISectionListItem, indexChild) => {
                          if (
                            getSectionHideAndShowCheck(
                              props.isPrintPreview || false,
                              widgetItem.isVisible,
                              props?.isPrintDownload,
                              widgetItem.isDisabled
                            )
                            && showWidgetByCode(widgetItem.resourceCode)
                            && hideWidgetByCode(widgetItem.resourceCode)
                          ) {
                            return (
                              <DashboardDataContext.Consumer key={indexChild}>
                                {(context) => {
                                  return (
                                    <SectionWidgetView
                                      onWidgetLoaded={onWidgetLoaded}
                                      memberSatisfactionFormList={props.memberSatisfactionFormList}
                                      dateRange={{
                                        fromDate: context.fromDate,
                                        toDate: context.toDate,
                                        uiFromDate: context.uiFromDate,
                                        uiToDate: context.uiToDate,
                                      }}
                                      memberType={props.memberType}
                                      dateRangeType={props.dateRangeType}
                                      isAccountDashboard={props.isAccountDashboard}
                                      sectionCode={item.sectionCategory}
                                      isPrintPreview={props.isPrintPreview}
                                      isPrintDownload={props.isPrintDownload}
                                      sectionVisible={item.isVisible}
                                      sectionDisabled={item.isDisabled}
                                      key={indexChild}
                                      index={indexChild}
                                      reusableData={props.reusableData}
                                      employerId={props.employerId}
                                      employerEnrollmentData={props.employerEnrollmentData}
                                      sectionData={widgetItem}
                                      onAction={(type: any, data: any) => {
                                        if (
                                          type ==
                                          WIDGET_ACTION_CODE.HIDE_SECTION
                                        ) {
                                          props?.onActionPerformed?.(
                                            type,
                                            indexChild,
                                            {index, data},
                                          );
                                        } else {
                                          props?.onActionPerformed?.(
                                            type,
                                            widgetItem.name,
                                            data
                                          );
                                        }
                                      }}
                                    />
                                  );
                                }}
                              </DashboardDataContext.Consumer>
                            );
                          } else {
                            return <></>
                          }
                        }
                      )}
                      {/* </View> */}
                    </div>
                  </div>
                  {props?.isPrintPreview ? (
                    props.isPrintDownload ? (item.description ?
                      <div
                        style={{
                          fontFamily: 'Manrope-Regular',
                          fontSize: 14,
                          paddingRight: 10,
                          marginBottom: 20
                        }}
                        className="print-indivisible"
                      >
                        {sectionDescription?.current?.[item?.name]?.value}
                      </div> : null
                    ) : (
                      <TextArea
                        borderRadius={8}
                        numberOfLines={5}
                        defaultValue={item.description}
                        ref={(ref) =>
                          (sectionDescription.current[item?.name] = ref as any)
                        }
                        fontSize={16}
                        placeholder={intl.formatMessage({
                          id: 'printPreviewDespPlaceholder',
                        })}
                        onChangeText={(value: string) => {
                          const sectionListTemp = [...sectionList];
                          sectionListTemp[index].description = value;
                          setSectionList(sectionListTemp);
                        }}
                      />
                    )
                  ) : null}
                </div>
              </main>
              {/* <div style={{pageBreakAfter: 'always', clear: 'both'}}></div> */}
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};
export default React.memo(AnalyticsSections);
