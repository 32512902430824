import { GROUP_EVENT_CODE_ARRAY,SENDER_TYPE } from '../../../../../constants';
import { GROUP_TYPE_CODES } from '../../../../../constants/MlovConst';
import {
  COMPONENT_SUPPORTED_EVENT,
  CUSTOM_MESSAGE_EVENT_CODES,
  SUPPORTED_EVENT_CODE,
} from '../../../../../constants/WebSocketConst';
import { EventBus } from '../../../../../utils/EventBus';
import { MessageBus } from '../../../../../utils/MessageBus';
import { IMentionObject } from '../../../../../utils/interfaces';
import { messageDataKeysConverter } from '../MessagingWindow/MessagingUtils';
import {
  CONVERSATION_TYPES,
  IConversationTypes,
  isConversationArchived,
  isConversationArchivedFromMessage,
  isInternalChat,
} from './ConversationSidebarUtils';
import { MentionEventNotification } from './Interfaces';

export class ConversationSidebarV2Event {
  private static instance: ConversationSidebarV2Event;
  private getConversationCountData: any;
  private setUnreadCountMap: any;
  private getSearchParams: any;
  private onCreateGroupPopupActionPerformed: any;
  private getSingleMessageDataById: any;
  private userId: any;
  private userUUID: string;
  private selectedConversation: any;
  private groupTypeIdCodeObj?: any;
  constructor(args: {
    userUUID: any;
    getSingleMessageDataById: any;
    userId: any;
    getSearchParams: any;
    getConversationCountData: any;
    setUnreadCountMap: any;
    onCreateGroupPopupActionPerformed: any;
    selectedConversation: any;
    groupTypeIdCodeObj?: any;
  }) {
    this.selectedConversation = args.selectedConversation;
    this.onCreateGroupPopupActionPerformed =
      args.onCreateGroupPopupActionPerformed;
    this.getConversationCountData = args.getConversationCountData;
    this.setUnreadCountMap = args.setUnreadCountMap;
    this.getSearchParams = args.getSearchParams;
    this.userId = args.userId;
    this.getSingleMessageDataById = args.getSingleMessageDataById;
    this.userUUID = args.userUUID;
    this.groupTypeIdCodeObj = args?.groupTypeIdCodeObj
  }
  private onConversationOpened = () => {
    if (this.getConversationCountData) {
      this.getConversationCountData();
    }
  };
  getSenderId = (data: any) => {
    return data?.sender_id || data?.senderId;
  };
  getSenderType = (data: any) => {
    return data?.sender_type || data?.senderType;
  };
  increaseUnreadCount = (args: {
    key: IConversationTypes;
    conversationUuid: string;
  }) => {
    const {key, conversationUuid} = args;

    this.setUnreadCountMap((prev: any) => {
      let prevData = prev[key];
      if (!prevData) {
        prevData = {
          conversationIdList: [],
          count: 0,
          messageCount: 0,
        };
      } else {
        prevData = JSON.parse(JSON.stringify(prev[key]));
      }
      prevData.conversationIdList = prevData.conversationIdList || [];
      const isExisting = prevData.conversationIdList.some((convData: any) => {
        return convData.uuid === conversationUuid;
      });
      if (!isExisting) {
        prevData.conversationIdList.push({uuid: conversationUuid});
        prevData.count = prevData.conversationIdList?.length;
        return {
          ...prev,
          [key]: prevData,
        };
      }
      return prev
    });
  };
  increaseCountOnMessageReceived = (msgData: any) => {
    const eventCode = msgData?.eventCode || msgData?.event_code || '';
    if (GROUP_EVENT_CODE_ARRAY.includes(eventCode)) {
      return;
    }
    const conversationIdFromMessage =
      msgData.conversation_id || msgData.conversationId;
    const conversationUuid =
      msgData.conversation_uuid || msgData.conversationUuid;

    const senderId = this.getSenderId(msgData);
    const senderType = this.getSenderType(msgData);
    const messageType = msgData.message_type || msgData.messageType;
    const isMissedCall = messageType && messageType === 4;

    const inboxId = msgData.inbox_id || msgData.inboxId;
    const channel: string = msgData.channel || '';
    const isEmailInboxMessage =
      channel && channel.toLowerCase().includes('email');
    const isSMSInboxMessage = channel && channel.toLowerCase().includes('sms');
    const isEFaxInboxMessage = channel && channel.toLocaleLowerCase().includes('efax');
    let isInternalChatMessage = false;
    if (msgData?.group_type_id) {
      const groupTypeCode = this?.groupTypeIdCodeObj?.[msgData?.group_type_id];
      isInternalChatMessage = groupTypeCode === GROUP_TYPE_CODES.INTERNAL;
    }


    const assigneeId =
      msgData?.conversation?.assignee_id || msgData?.conversation?.assigneeId;
    if (assigneeId == this.userId) {
      this.increaseUnreadCount({
        key: 'ASSIGN_TO_ME_CHAT',
        conversationUuid,
      });
    } else if (!assigneeId && !isInternalChatMessage) {
      this.increaseUnreadCount({
        key: 'UNASSIGN_CHAT',
        conversationUuid,
      });
    }

    if (isEmailInboxMessage) {
      this.increaseUnreadCount({
        key: 'EMAIL_CHAT',
        conversationUuid,
      });
    }
    if (isSMSInboxMessage && !isMissedCall) {
      this.increaseUnreadCount({
        key: 'SMS_CHAT',
        conversationUuid,
      });
    }
    if (isMissedCall) {
      this.increaseUnreadCount({
        key: 'MISSED_CALL_CHAT',
        conversationUuid,
      });
    }
    if (isEFaxInboxMessage) {
      this.increaseUnreadCount({
        key: 'FAX_CHAT',
        conversationUuid,
      });
    }
    this.getSingleMessageDataById({
      variables: {
        messageId: msgData.id,
        userUuid: this.userUUID,
      },
    }).then((response: any) => {
      const messageData = response?.data?.message || {};
      if (isInternalChat({messageData})) {
        this.increaseUnreadCount({
          key: 'INTERNAL_CHAT',
          conversationUuid,
        });
      } else if (inboxId == -1 && !isConversationArchived({messageData})) {
        this.increaseUnreadCount({
          key: 'GROUP_CHAT',
          conversationUuid,
        });
      }
    });
  };
  isExistingUnreadConversation = (conversationUuid: string) => {
    let isExisting = false;
    this.setUnreadCountMap((prev: any) => {
      isExisting = Object.values(prev).some((value: any) => {
        const conversationIdList = value?.conversationIdList || [];
        return conversationIdList.some((conversationData: any) => {
          return conversationData?.uuid === conversationUuid;
        });
      });
      return prev;
    });

    return isExisting;
  };
  onMsgReceivedListenerFn = (eventInfo: any) => {
    const data = eventInfo?.data || eventInfo;
    const senderId = this.getSenderId(data);
    let conversationUuid;
    if (this.getSearchParams) {
      const searchParamsResult = this.getSearchParams();
      conversationUuid = searchParamsResult?.conversationUuid;
    } else if (this.selectedConversation) {
      conversationUuid = this.selectedConversation?.conversationUuid;
    }
    const senderType = this.getSenderType(data);
    const conversationUuidReceived =
      data.conversation_uuid || data.conversationUuid;
    if (
      (conversationUuid && senderId == this.userId && senderType == SENDER_TYPE.USER) ||
      conversationUuid == conversationUuidReceived ||
      !senderType
    ) {
      return;
    }
    const finalData =
      Object.keys(data?.data || {}).length > 0
        ? messageDataKeysConverter(data.data)
        : data;
    this.increaseCountOnMessageReceived(finalData);
  };
  onConversationResolved = (eventInfo: any) => {
    const msgData = eventInfo?.data || eventInfo;
    const conversationUuid =
      msgData.conversation_uuid || msgData.conversationUuid;
    this.setUnreadCountMap((prev: any) => {
      const copy: any = JSON.parse(JSON.stringify(prev));
      Object.keys(copy).forEach((key) => {
        if (copy[key] && key !== CONVERSATION_TYPES.MENTION_CHAT) {
          try {
            if (typeof copy[key] === 'string') {
              copy[key] = JSON.parse(copy[key]);
            }
          } catch (error) {}
          const conversationIdList = copy[key].conversationIdList || [];
          const newList = conversationIdList.filter((conversationData: any) => {
            return conversationData?.uuid !== conversationUuid;
          });
          copy[key].conversationIdList = newList;
          copy[key].count = newList.length;
        }
      });
      return copy;
    });
  };
  decrementUnreadCount = (args: {
    key: IConversationTypes;
    conversationUuid: string;
  }) => {
    const {key, conversationUuid} = args;

    this.setUnreadCountMap((prev: any) => {
      const prevData = prev[key];
      const conversationIdList = prevData.conversationIdList.filter(
        (convData: any) => {
          return !(convData.uuid === conversationUuid);
        }
      );
      if (prevData.conversationIdList?.length != conversationIdList?.length) {
        prevData.conversationIdList = conversationIdList;
        prevData.count = prevData.conversationIdList?.length;
        return {
          ...prev,
          [key]: prevData,
        };
      }
      return prev;
    });
  };
  onMsgAssigneeChangedListenerFn = (eventInfo: any) => {
    const data = eventInfo?.data || eventInfo;
    const isArchived = isConversationArchived({messageData: data});
    if (!isArchived) {
      const assigneeId = data?.additionalData?.assigneeId;
      const conversationUuid = data?.additionalData?.conversationUuid;
      const isExisting = this.isExistingUnreadConversation(conversationUuid);
      if (isExisting && assigneeId == this.userId) {
        this.increaseUnreadCount({
          key: 'ASSIGN_TO_ME_CHAT',
          conversationUuid,
        });
        this.decrementUnreadCount({
          key: 'UNASSIGN_CHAT',
          conversationUuid,
        });
      } else if (!assigneeId && isExisting) {
        this.increaseUnreadCount({
          key: 'UNASSIGN_CHAT',
          conversationUuid,
        });
        this.decrementUnreadCount({
          key: 'ASSIGN_TO_ME_CHAT',
          conversationUuid,
        });
      } else {
        this.decrementUnreadCount({
          key: 'UNASSIGN_CHAT',
          conversationUuid,
        });
        this.decrementUnreadCount({
          key: 'ASSIGN_TO_ME_CHAT',
          conversationUuid,
        });
      }
    }
  };
  reduceCountOnConversationSelect = (data: any) => {
    let conversationUuid: any;
    let conversationMentionId: any;
    if (this.getSearchParams) {
      const searchParamResult = this.getSearchParams();
      conversationUuid = searchParamResult?.conversationUuid;
      conversationMentionId = searchParamResult?.conversationMentionId;
    } else if (this.selectedConversation) {
      conversationUuid = this.selectedConversation?.conversationUuid;
    }
    if (conversationMentionId) {
      return;
    }
    if (conversationUuid) {
      this.setUnreadCountMap((prev: any) => {
        
        const copy: any = JSON.parse(JSON.stringify(prev))
        let isUpdate = false;
        
        Object.keys(copy).forEach((key) => {
          try {
            if (copy[key]) {
              try {
                if (typeof copy[key] === 'string') {
                  copy[key] = JSON.parse(copy[key]);
                }
              } catch (error) {}
              const conversationIdList = copy[key]?.conversationIdList || [];
              const newList = conversationIdList.filter(
                (conversationData: any) => {
                  return conversationData?.uuid !== conversationUuid;
                }
              );
              let count = newList.length;
              if (key === CONVERSATION_TYPES.MENTION_CHAT) {
                count = (newList || []).reduce((prev: any, current: any) => {
                  return prev + current.count;
                }, 0);
              }
              try {
                if (typeof copy[key]?.conversationIdList === 'string') {
                  copy[key].conversationIdList = JSON.parse(copy[key]?.conversationIdList);
                }
                if(copy[key].conversationIdList?.length != newList?.length || copy[key].count != count){
                  copy[key].conversationIdList = newList;
                  copy[key].count = count;
                  isUpdate = true;
                }
              } catch (error) {
                delete copy[key].conversationIdList;
                copy[key].conversationIdList = newList;
                copy[key].count = 0;
                isUpdate = true;
              }
            }
          } catch (error) {
          }
        });
        if(isUpdate){
          return copy;
        }
        return prev;
      });
    }
  };
  onConversationMentionedListenerFn = (
    mentionEvent: MentionEventNotification
  ) => {
    const data = mentionEvent?.data || mentionEvent;
    const senderId = this.getSenderId(data);
    let conversationUuid: any;
    if (this.getSearchParams) {
      const searchParamResult = this.getSearchParams();
      conversationUuid = searchParamResult?.conversationUuid;
    } else if (this.selectedConversation) {
      conversationUuid = this.selectedConversation?.conversationUuid;
    }
    if (conversationUuid) {
      const conversationUuidReceived = data.conversationUuid;
      if (conversationUuid === conversationUuidReceived) {
        return;
      }
    }
    this.getConversationCountData();
  };
  onConversationStatusChangedListenerFn = (eventInfo: any) => {
    const data = eventInfo?.data;

    if (!data) {
      return;
    }
    if (!isConversationArchivedFromMessage(data)) {
      return;
    }
    this.getConversationCountData();
  };
  onMentionStatusChangeCallback = (args: IMentionObject[]) => {
    this.getConversationCountData();
  };
  onConversationUnreadCountUpdate = () => {
    this.getConversationCountData();
  };
  onConversationPopupActionPerformed = (data: {
    actionCode: string;
    actionData?: any;
  }) => {
    if (this.onCreateGroupPopupActionPerformed) {
      this.onCreateGroupPopupActionPerformed(data.actionCode, data?.actionData);
    }
  };
  onReadSelectedConversation = (data: {
    account_id: number;
    userUuid: string;
  }) => {
    if (data.userUuid === this.userUUID) {
      this.getConversationCountData();
    }
  }
  public addEventListener() {
    const eventBus = EventBus.getEventBusInstance();
    const messageBus = MessageBus.getMessageBusInstance();
    eventBus.addEventListener(
      COMPONENT_SUPPORTED_EVENT.CONVERSATION_MORE_ACTIONS,
      this.onConversationPopupActionPerformed
    );
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.MESSAGE_CREATED,
      this.onMsgReceivedListenerFn
    );
    // eventBus.addEventListener(
    //   SUPPORTED_EVENT_CODE.GROUP_MESSAGE_CREATED,
    //   onMsgReceivedListenerFn
    // );

    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_OPENED,
      this.onConversationOpened
    );

    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_RESOLVED,
      this.onConversationResolved
    );

    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.ASSIGNEE_CHANGED,
      this.onMsgAssigneeChangedListenerFn
    );
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_CHANGED,
      this.reduceCountOnConversationSelect
    );
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_MENTIONED,
      this.onConversationMentionedListenerFn
    );
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_STATUS_CHANGED,
      this.onConversationStatusChangedListenerFn
    );
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_UNREAD_COUNT,
      this.onConversationUnreadCountUpdate
    );
    eventBus.addEventListener(
      CUSTOM_MESSAGE_EVENT_CODES.MARK_US_UNREAD_MENTION,
      this.onConversationUnreadCountUpdate
    );
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.SELECTED_CONVERSATION_READ,
      this.onReadSelectedConversation
    );
    messageBus.registerCallbacks({
      onMentionStatusChanged: this.onMentionStatusChangeCallback,
    });
    return () => {
      eventBus.removeEventListener(this.onConversationOpened);
      eventBus.removeEventListener(this.onConversationResolved);
      eventBus.removeEventListener(this.onConversationPopupActionPerformed);
      eventBus.removeEventListener(this.onMsgReceivedListenerFn);
      eventBus.removeEventListener(this.onMsgAssigneeChangedListenerFn);
      eventBus.removeEventListener(this.reduceCountOnConversationSelect);
      eventBus.removeEventListener(this.onConversationMentionedListenerFn);
      eventBus.removeEventListener(this.onConversationStatusChangedListenerFn);
      eventBus.removeEventListener(this.onReadSelectedConversation);
      messageBus.removeCallback({
        onMentionStatusChanged: this.onMentionStatusChangeCallback,
      });
      eventBus.removeEventListener(this.onConversationUnreadCountUpdate);
    };
  }
}
