import * as React from 'react';
import {IConversationData} from '../components/RightSideContainer/TeamInbox/Conversations/interfaces';
export interface IConversationContextData {
  conversationList: IConversationData[];
}
export const defaultConversationList: IConversationContextData = {
  conversationList: [],
};

export const ConversationListContext = React.createContext(
  defaultConversationList
);
