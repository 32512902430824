import {Path, Svg} from 'react-native-svg';

const AutomationAnalyticsV2Svg = (props: {strokeColor?: string; size?: number;}) => {
  const strokeColor = props.strokeColor || "#8A94A8";
  const size = props?.size || 16;

  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <Path d="M1.33325 14.1668C1.05711 14.1668 0.833252 14.3907 0.833252 14.6668C0.833252 14.943 1.05711 15.1668 1.33325 15.1668V14.1668ZM14.6666 14.1668H1.33325V15.1668H14.6666V14.1668ZM10.9999 9.16683H12.9999V8.16683H10.9999V9.16683ZM13.4999 9.66683V14.6668H14.4999V9.66683H13.4999ZM10.4999 14.6668V9.66683H9.49992V14.6668H10.4999ZM12.9999 9.16683C13.2761 9.16683 13.4999 9.39069 13.4999 9.66683H14.4999C14.4999 8.8384 13.8283 8.16683 12.9999 8.16683V9.16683ZM10.9999 8.16683C10.1715 8.16683 9.49992 8.8384 9.49992 9.66683H10.4999C10.4999 9.39069 10.7238 9.16683 10.9999 9.16683V8.16683ZM9.49992 3.3335V14.6668H10.4999V3.3335H9.49992ZM6.49992 14.6668V3.3335H5.49992V14.6668H6.49992ZM7.99992 1.8335C8.48546 1.8335 8.79931 1.83456 9.03057 1.86565C9.24744 1.89481 9.31541 1.94188 9.35347 1.97994L10.0606 1.27284C9.80575 1.018 9.49157 0.918632 9.16381 0.874566C8.85044 0.832434 8.45719 0.833496 7.99992 0.833496V1.8335ZM10.4999 3.3335C10.4999 2.87623 10.501 2.48298 10.4588 2.1696C10.4148 1.84184 10.3154 1.52767 10.0606 1.27284L9.35347 1.97994C9.39153 2.018 9.43861 2.08598 9.46777 2.30285C9.49886 2.5341 9.49992 2.84796 9.49992 3.3335H10.4999ZM7.99992 0.833496C7.54265 0.833496 7.1494 0.832434 6.83602 0.874566C6.50827 0.918632 6.19409 1.018 5.93926 1.27284L6.64637 1.97994C6.68443 1.94188 6.7524 1.89481 6.96927 1.86565C7.20052 1.83456 7.51438 1.8335 7.99992 1.8335V0.833496ZM6.49992 3.3335C6.49992 2.84796 6.50098 2.5341 6.53207 2.30285C6.56123 2.08598 6.6083 2.018 6.64637 1.97994L5.93926 1.27284C5.68443 1.52767 5.58505 1.84184 5.54099 2.1696C5.49886 2.48298 5.49992 2.87623 5.49992 3.3335H6.49992ZM2.99992 5.8335H4.99992V4.8335H2.99992V5.8335ZM5.49992 6.3335V14.6668H6.49992V6.3335H5.49992ZM2.49992 14.6668V6.3335H1.49992V14.6668H2.49992ZM4.99992 5.8335C5.27606 5.8335 5.49992 6.05735 5.49992 6.3335H6.49992C6.49992 5.50507 5.82835 4.8335 4.99992 4.8335V5.8335ZM2.99992 4.8335C2.17149 4.8335 1.49992 5.50507 1.49992 6.3335H2.49992C2.49992 6.05735 2.72378 5.8335 2.99992 5.8335V4.8335Z" 
      fill={strokeColor}/>
    </Svg>
  )
}


export default AutomationAnalyticsV2Svg;
