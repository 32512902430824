import React, { useEffect, useState } from 'react';
import { View, Text } from 'native-base';
import { Tooltip } from 'antd';
import { Colors } from '../../../../styles';
import { IMembersDatas } from '../../MembersManagement/RulesInterface';
import { getContactListSearchFilter } from './GroupDetails/GroupDetailsUtils';
import { useLazyQuery } from '@apollo/client';
import { GetContactAggregateCountByRuleId } from '../../../../services/Lead/LeadQueries';

interface ActiveMembersCellProps {
  data: IMembersDatas;
  isMultiTenancyEnabled: boolean;
  isBusinessOwner: boolean;
}

const ActiveMembersCell = (props:ActiveMembersCellProps) => {
  const { data, isMultiTenancyEnabled, isBusinessOwner } = props;
  const [aggregateCount, setAggregateCount] = useState<number | null>(null);
  const [getContactAggregateCount] = useLazyQuery(GetContactAggregateCountByRuleId, {
    fetchPolicy: 'no-cache'
  });

  const getGroupAggregateCount = async (group: IMembersDatas): Promise<number> => {
    const params = getContactListSearchFilter({
      limit: 1000,
      offset: 0,
      ruleId: group.id,
      groupContactFilter: {
        isActive: true,
        selectedCampaign: '',
        selectedWorkflow: '',
        hintStatus: [],
        locationIds: [],
      }
    });
    try {
      const { data } = await getContactAggregateCount({ variables: { params } });
      return data?.searchContacts?.contactAggregate?.aggregate?.count || 0;
    } catch (error) {
      return 0;
    }
  };

  const fetchAggregateCount = async () => {
    if (isMultiTenancyEnabled && !isBusinessOwner) {
      const count = await getGroupAggregateCount(data);
      setAggregateCount(count);
    }
  };

  useEffect(() => {
    fetchAggregateCount();
  }, []);

  return (
    <Tooltip
      title={
        aggregateCount !== null ? (
          <View>
            <Text color={Colors.Custom.White}>Total Members: {data?.count || 0} (across all locations)</Text>
            <Text color={Colors.Custom.White}>Accessible Members: {aggregateCount} (based on your location access)</Text>
          </View>
        ) : null
      } 
      placement="top"
      onOpenChange={(visible) => {
        if (visible && aggregateCount === null) {
          fetchAggregateCount();
        }
      }}
    >
      <View style={{alignItems: 'center'}}>
        <Text size={'mdMedium'} color={Colors.Custom.Gray900}>
          {`${
            data?.count
              ? `${data?.count}`
              : data?.nextExecutionDateTime
              ? '-'
              : '0'
          }`}
        </Text>
      </View>
    </Tooltip>
  );
};

export default ActiveMembersCell;