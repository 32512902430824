import {useLazyQuery, useMutation} from '@apollo/client';
import {
  Button, CheckIcon, FormControl, HStack, Select, Text, View, VStack
} from 'native-base';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../constants';
import EmployerQueries from '../../../../services/Employer/EmployerQueries';
import {GET_MANAGER_ROLES} from '../../../../services/User/UserQueries';
import {Colors} from '../../../../styles';
import {getAccountId} from '../../../../utils/commonUtils';
import {getMlovListByCategory} from '../../../../utils/mlovUtils';
import UserAutoComplete, {IUserSearch} from '../../../common/CalendarWidget/UserAutoComplete/UserAutoComplete';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import './ManageDeal.css';
import {styles} from './ManageDealStyles';

const ManageDealModal = (props: any) => {
  const {onModalClose, initData} = props;
  const [employerDetails, setEmployerDetails] = useState({
    status: initData?.raw?.employerDeal?.[0]?.statusId || '',
    accountManager: initData?.raw?.employerDeal?.[0]?.managerId || '',
  });
  const [employerDetailsError, setEmployerDetailsError] = useState<any>(null);
  const intl = useIntl();
  const tenantId = getAccountId();
  const [getManagers] = useLazyQuery(GET_MANAGER_ROLES, {
    fetchPolicy: 'no-cache',
    variables: {
      tenantId: tenantId
    }
  });
  const [managers, setManagers] = useState<any[]>([]);

  const dealStatusList = getMlovListByCategory('DealStatus');

  useEffect(() => {
    getManagers()
      .then((resp) => {
        if (resp?.data?.users && resp?.data?.users.length) {
          const NonEmpManagers = resp?.data?.users;
          resp?.data?.users.map((managers: any, index: number) => {
            managers?.userRoles && managers.userRoles.map((userRoles: any) => {
              if (userRoles.userRole.userRole.code === 'EMPLOYER') {
                NonEmpManagers.splice(index, 1);
              }
            })
          });
          setManagers(NonEmpManagers);
        }
      })
      .catch((e) => {

      });
  }, []);
  const [updateEmployer] = useMutation(EmployerQueries.updateEmployer);
  const [updateEmployerDeal] = useMutation(EmployerQueries.updateEmployerDeal);
  const onSubmit = (): void => {
    updateEmployerDeal({
      variables: {
        employerDealId: initData?.raw?.employerDeal?.[0]?.id,
        employerDeal: {
          statusId: employerDetails?.status,
            managerId: employerDetails?.accountManager,
        }
      },
    }).then(() => {
      onModalClose(true);
    })
    return
    // need to disucuss why this code :: ABHAY
    updateEmployer({
      variables: {
        employerId: initData?.id,
        employerDealId: initData?.raw?.employerDeal?.[0]?.id,
        employer: {
          name: initData?.raw?.name,
          email: initData?.raw?.email,
          phoneNumber: initData?.raw?.phoneNumber,
          employerDeal: {
            statusId: employerDetails?.status,
            managerId: employerDetails?.accountManager,
          }
        },
        contactEmployeeId:
          initData?.raw?.employerDeal?.[0]?.contactEmployee?.id,
        contactEmployee: {
          roleId: null,
        },
        contactId:
          initData?.raw?.employerDeal?.[0]?.contactEmployee?.contact?.id,
        contact: {
          accountId: tenantId,
          name: initData?.raw?.employerDeal?.[0]?.contactEmployee?.contact?.name,
          email: initData?.raw?.employerDeal?.[0]?.contactEmployee?.contact?.email,
          phoneNumber: initData?.raw?.employerDeal?.[0]?.contactEmployee?.contact
            ?.phoneNumber,
        },
      },
    })
      .then(() => {
        onModalClose(true);
      })
  }
  return (
    <View mt={0}>
      <HStack marginBottom={0} justifyContent={'space-between'} alignItems={'center'}>
        <VStack justifyContent={'flex-start'} mt={0}>
          <TitleSubtitleView
            isHideCommonButton={true}
            containerStyle={{marginHorizontal: 0, marginTop: 0}}
            titleLabelId={props?.title}
            subtitleLabelId=""
          />
          <Text
            size={'smMedium'}
            marginTop={0}
            marginX={0}
            color={Colors.Custom.Gray700}
          >
            {intl.formatMessage({id: 'manageDealText'})}
          </Text>
        </VStack>
          <Button.Group justifyContent={'flex-end'}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onModalClose();
                },
                style: {marginRight: 20}
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onSubmit();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'update',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
      </HStack>
      <Text>{props?.manageDealText}</Text>
      <FormControl style={[styles.formElement]} mt={5}>
        <FormControl.Label style={styles.formLabel}>
          <DisplayText
            textLocalId={'selectDealStatus'}
            size={'smMedium'}
            extraStyles={{color: Colors.Custom.Gray700}}
          />
        </FormControl.Label>
        <Select
          placeholder={intl.formatMessage({id: 'selectDealStatus'})}
          selectedValue={employerDetails.status || undefined}
          accessibilityLabel={intl.formatMessage({id: 'selectDealStatus'})}
          _selectedItem={{
            endIcon: <CheckIcon size="5" />,
          }}
          onValueChange={(itemValue) => {
            setEmployerDetails({
              ...employerDetails,
              status: itemValue,
            });
          }}
        >
          {dealStatusList.map((value: any) => {
            return (
              <Select.Item
                key={value.id}
                label={value.value}
                value={value.id}
              />
            );
          })}
        </Select>
      </FormControl>
      <FormControl style={[styles.formElement]}>
        <UserAutoComplete
          labelText={intl.formatMessage({id: 'selectAccountManager'})}
          label={intl.formatMessage({id: 'selectAccountManager'})}
          selectedUserUUID={employerDetails.accountManager}
          allowClear={false}
          onChange={(user: IUserSearch) => {
            setEmployerDetails({
              ...employerDetails,
              accountManager: user.value,
            });
          }}
          usersData={managers}
          backgroundColor={'white'}
        />
      </FormControl>
      {/* <Text size={"smMedium"} marginTop={5} color={Colors.primary[700]}>
          {intl.formatMessage({id:"createNewAccountManager"})}
        </Text> */}
    </View>
  );
};

export default ManageDealModal;
