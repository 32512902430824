import {FHIR_RESOURCE} from '../../../../../constants/FhirConstant';
import {
  formatConditionsRequestResourceObj,
  formatHieConditionData,
  getConditionCodeInvalidField,
  getConditionName,
  updateHieConditionMatchedData,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/AddOrUpdateConditions/AddOrUpdateConditionsHelper';
import {
  HieStatus,
  IFormatHieMetaData,
  IHieDisplay,
  IHieRequest,
  IHieRequestGroup,
  IHieRequestResponse,
  IHieInvalidField,
} from './interface';
import {
  formatHieSurgicalHistoryData,
  formatSurgicalHistoryRequestResourceObj,
  getHistoryName,
  getSurgicalInvalidCodeField,
  updateHieSurgicalHistoryMatchedData,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/SurgicalHistory/AddOrUpdateSurgicalHistory/AddOrUpdateSurgicalHistoryHelper';
import {capitalize} from 'lodash';
import {
  formatHieImmunizationData,
  formatHieImmunizationResourceObj,
  getImmunizationInvalidCodeField,
  getImmunizationName,
  updateHieImmunizationMatchedData,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Immunizations/AddOrUpdateImmunizations/AddOrUpdateImmunizationsHelper';
import {
  formatAllergyRequestResourceObj,
  formatHieAllergyData,
  getAllergyCodeInvalidField,
  getAllergyName,
  updateHieAllergyMatchedData,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Allergies/AddOrUpdateAllergies/AddOrUpdateAllergyHelper';
import {
  formatHieFamilyHistoryData,
  formatHieFamilyHistoryResourceObj,
  getAdditionalDetails,
  getFamilyMemberInvalidCodeField,
  updateHieFamilyHistoryMatchedData,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/FamilyHistory/AddOrUpdateFamilyHistory/AddOrUpdateFamilyHistoryHelper';
import {
  formatHieObservationData,
  formatHieObservationRequestObj,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/AddOrUpdateVitals/AddOrUpdateVitalsHelper';
import {IntlShape} from 'react-intl';
import {getMomentObj} from '../../../../../utils/DateUtils';
import {
  formatHieMedicationResourceObj,
  formatHieMedicationStatementData,
  getMedicationInvalidCodeField,
  updateHieMedicationStatementMatchedData,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Medications/AddOrUpdateMedications/AddOrUpdateMedicationsHelper';

export enum HieInvalidFields {
  code,
  date,
  select,
  textArea,
  input,
  practitioner,
}

export const resourcesSortingOrderList = [
  FHIR_RESOURCE.OBSERVATION,
  FHIR_RESOURCE.CONDITION,
  FHIR_RESOURCE.ALLERGY,
  FHIR_RESOURCE.MEDICATION_STATEMENT,
  FHIR_RESOURCE.IMMUNIZATION,
  FHIR_RESOURCE.PROCEDURE,
  FHIR_RESOURCE.FAMILY_MEMBER_HISTORY,
];

export const formatHieDataResponse = (
  responseData: IHieRequestResponse[],
  metaData: IFormatHieMetaData
) => {
  const formattedHieData: IHieRequest[] = [];

  responseData.map((response) => {
    let data = response.data;

    if (typeof data === 'string') {
      try {
        data = JSON.parse(data);
      } catch (error) {

      }
    }

    const hieDisplay = getHieFormattedData(data, metaData);

    if (hieDisplay) {

      const hieRequest: IHieRequest = {
        resourceData: hieDisplay.resourceData || data,
        id: response.id,
        resourceType: response.display_type,
        syncDate: response.time_stamp,
        syncStatus: response.sync_status,
        display: hieDisplay.display,
        invalidFields: hieDisplay.invalidFields,
      };

      formattedHieData.push(hieRequest);
    }
  });

  const sortedData = formattedHieData?.sort((hieA, hieB) => {
    const recordDate1 = hieA.syncDate;
    const recordDate2 = hieB.syncDate;
    if (recordDate1 && recordDate2) {
      const date1 = getMomentObj(recordDate1);
      const date2 = getMomentObj(recordDate2);
      return date2 > date1 ? 1 : -1;
    }
    return 0;
  });

  return sortedData;
};

export const getUpdatedMatchedHieRequest = (
  hieRequest: IHieRequest,
  invalidFields: IHieInvalidField[],
  metaData: IFormatHieMetaData
) => {
  let formattedData;
  switch (hieRequest.resourceType) {
    case FHIR_RESOURCE.CONDITION:
      formattedData = updateHieConditionMatchedData(
        hieRequest.resourceData,
        invalidFields || [],
        metaData
      );
      break;
    case FHIR_RESOURCE.ALLERGY:
      formattedData = updateHieAllergyMatchedData(
        hieRequest.resourceData,
        invalidFields || [],
        metaData
      );
      break;
    case FHIR_RESOURCE.IMMUNIZATION:
      formattedData = updateHieImmunizationMatchedData(
        hieRequest.resourceData,
        invalidFields || [],
        metaData
      );
      break;
    case FHIR_RESOURCE.PROCEDURE:
      formattedData = updateHieSurgicalHistoryMatchedData(
        hieRequest.resourceData,
        invalidFields || [],
        metaData
      );
      break;
    case FHIR_RESOURCE.FAMILY_MEMBER_HISTORY:
      formattedData = updateHieFamilyHistoryMatchedData(
        hieRequest.resourceData,
        invalidFields || [],
        metaData
      );
      break;
    case FHIR_RESOURCE.MEDICATION_STATEMENT:
      formattedData = updateHieMedicationStatementMatchedData(
        hieRequest.resourceData,
        invalidFields || [],
        metaData
      );
      break;
  }

  if(formattedData?.invalidFields?.length === 0){
    hieRequest.error = undefined;
  }

  hieRequest.invalidFields = formattedData?.invalidFields;
  hieRequest.resourceData = formattedData?.resourceData;
  hieRequest.display = formattedData?.display;
  return hieRequest;
};

export const groupHieRequests = (hieRequests: IHieRequest[]) => {
  const data = hieRequests.reduce((b: any, a) => {
    const ind = b.findIndex((e: any) => e.resourceType === a.resourceType);
    if (ind > -1) {
      b[ind].orders.push(a);
    } else {
      b.push({
        resourceType: a.resourceType,
        orders: [a],
      });
    }
    return b;
  }, []);

  return data
    ?.sort((groupA: IHieRequest, groupB: IHieRequest) => {
      return (
        resourcesSortingOrderList.indexOf(groupA.resourceType) -
        resourcesSortingOrderList.indexOf(groupB.resourceType)
      );
    })
    ?.map((group: IHieRequestGroup) => {
      group.orders = sortGroupOrdersByDate(group.orders);
      return group;
    });
};

export const sortGroupOrdersByDate = (hieRequests: IHieRequest[]) => {
  return hieRequests?.sort((hieA, hieB) => {
    const recordDate1 = hieA.display?.date;
    const recordDate2 = hieB.display?.date;
    if (recordDate1 && recordDate2) {
      const date1 = getMomentObj(recordDate1);
      const date2 = getMomentObj(recordDate2);
      return date2 > date1 ? 1 : -1;
    }
    return 0;
  });
};

export const getUpdateGroupHieRequestsStatus = (
  hieRequests: IHieRequest[],
  hieRequestGroups: IHieRequestGroup[],
  status: HieStatus
) => {
  const hieRequestIds = hieRequests.map((hieRequest) => hieRequest.id);
  return hieRequestGroups.map((hieGroupOrder: IHieRequestGroup) => {
    hieGroupOrder.orders = hieGroupOrder.orders.map((order) => {
      if (hieRequestIds.includes(order.id)) {
        order.syncStatus = status;
      }
      return order;
    });
    return hieGroupOrder;
  });
};

export const getUpdatedErrorGroupHieRequests = (
  hieRequests: IHieRequest[],
  hieRequestGroups: IHieRequestGroup[]
) => {
  return hieRequestGroups.map((hieGroupOrder: IHieRequestGroup) => {
    hieGroupOrder.orders = hieGroupOrder.orders.map((order) => {
      const errorObj = hieRequests.find(
        (hieRequest) => hieRequest.id === order.id
      );

      if (errorObj && errorObj.error) {
        order.error = errorObj.error;
      }

      if (errorObj?.invalidFields?.length) {
        order.invalidFields = errorObj?.invalidFields;
      }

      return order;
    });
    hieGroupOrder.acceptAllLoading = false;
    return hieGroupOrder;
  });
};

export const updateHieGroupInArr = (
  hieRequestGroups: IHieRequestGroup[],
  hieRequestGroup: IHieRequestGroup,
  updatedObj: any
) => {
  return hieRequestGroups.map((hieRequestGroupObj: IHieRequestGroup) => {
    if (hieRequestGroupObj.resourceType === hieRequestGroup.resourceType) {
      return {...hieRequestGroupObj, ...updatedObj};
    }
    return hieRequestGroupObj;
  });
};

export const updateGroupHieRequests = (
  hieRequest: IHieRequest,
  hieRequestGroups: IHieRequestGroup[]
) => {
  return hieRequestGroups.map((hieGroupOrder: IHieRequestGroup) => {
    hieGroupOrder.orders = hieGroupOrder.orders.map((order) => {
      if (order.id === hieRequest.id) {
        return hieRequest;
      }
      return order;
    });
    return hieGroupOrder;
  });
};

export const formatFhirRequestObj = (
  resourceType: string,
  resource: any,
  metaData: IFormatHieMetaData,
  isMultiple: boolean
) => {
  switch (resourceType) {
    case FHIR_RESOURCE.CONDITION:
      return formatConditionsRequestResourceObj(resource, metaData);
    case FHIR_RESOURCE.IMMUNIZATION:
      return formatHieImmunizationResourceObj(resource, metaData);
    case FHIR_RESOURCE.ALLERGY:
      const allergyData = formatAllergyRequestResourceObj(resource, metaData);
      return allergyData;
    case FHIR_RESOURCE.FAMILY_MEMBER_HISTORY:
      const familymemberFhirData = formatHieFamilyHistoryResourceObj(
        resource,
        metaData
      );
      return familymemberFhirData;
    case FHIR_RESOURCE.OBSERVATION:
      return formatHieObservationRequestObj(resource, metaData, isMultiple);
    case FHIR_RESOURCE.PROCEDURE:
      const procedureData = formatSurgicalHistoryRequestResourceObj(
        resource,
        metaData
      );
      return procedureData;
    case FHIR_RESOURCE.MEDICATION_STATEMENT:
      return formatHieMedicationResourceObj(resource, metaData);
  }

  return resource;
};

export const getHieFormattedData = (
  resource: any,
  metaData: IFormatHieMetaData
):
  | {
      display: IHieDisplay;
      resourceData: any;
      invalidFields?: IHieInvalidField[];
    }
  | undefined => {
  switch (resource.resourceType) {
    case FHIR_RESOURCE.CONDITION:
      return formatHieConditionData(resource, metaData);

    case FHIR_RESOURCE.OBSERVATION:
      return formatHieObservationData(resource, metaData);

    case FHIR_RESOURCE.ALLERGY:
      return formatHieAllergyData(resource, metaData);

    case FHIR_RESOURCE.IMMUNIZATION:
      return formatHieImmunizationData(resource, metaData);

    case FHIR_RESOURCE.FAMILY_MEMBER_HISTORY:
      return formatHieFamilyHistoryData(resource, metaData);

    case FHIR_RESOURCE.PROCEDURE:
      return formatHieSurgicalHistoryData(resource, metaData);

    case FHIR_RESOURCE.MEDICATION_STATEMENT:
      if (metaData?.ehrConfig?.isElation) {
        return;
      }
      return formatHieMedicationStatementData(resource, metaData);
  }
};

export const getResourceGroupText = (resourceType: string, intl: IntlShape) => {
  const textObj = {
    headerText: intl.formatMessage({id: 'allergies'}),
    matchedWithTitle: intl.formatMessage({id: 'matchToData'}),
  };
  switch (resourceType) {
    case FHIR_RESOURCE.ALLERGY:
      textObj.headerText = intl.formatMessage({id: 'allergies'});
      break;
    case FHIR_RESOURCE.CONDITION:
      textObj.headerText = intl.formatMessage({id: 'problems'});
      break;
    case FHIR_RESOURCE.PROCEDURE:
      textObj.headerText = intl.formatMessage({id: 'surgicalHistory'});
      break;
    case FHIR_RESOURCE.FAMILY_MEMBER_HISTORY:
      textObj.headerText = intl.formatMessage({id: 'familyHistory'});
      break;
    case FHIR_RESOURCE.OBSERVATION:
      textObj.headerText = intl.formatMessage({id: 'vitals'});
      break;
    case FHIR_RESOURCE.IMMUNIZATION:
      textObj.headerText = intl.formatMessage({id: 'immunizations'});
      break;
    case FHIR_RESOURCE.MEDICATION_STATEMENT:
      textObj.headerText = intl.formatMessage({id: 'medications'});
      break;
  }
  return textObj;
};

export const getFilteredHieRequests = (hieRequests: IHieRequest[]) => {
  return hieRequests.filter((hieRequest) => !hieRequest.syncStatus);
};

export const getInValidCodeObj = (resourceType: string) => {
  switch (resourceType) {
    case FHIR_RESOURCE.CONDITION:
      return getConditionCodeInvalidField();
    case FHIR_RESOURCE.ALLERGY:
      return getAllergyCodeInvalidField();
    case FHIR_RESOURCE.MEDICATION_STATEMENT:
      return getMedicationInvalidCodeField();
    case FHIR_RESOURCE.IMMUNIZATION:
      return getImmunizationInvalidCodeField();
    case FHIR_RESOURCE.FAMILY_MEMBER_HISTORY:
      return getFamilyMemberInvalidCodeField();
    case FHIR_RESOURCE.PROCEDURE:
      return getSurgicalInvalidCodeField();
  }
};
