import {Content} from 'antd/lib/layout/layout';
import {VStack} from 'native-base';
import {isOrderingEnabled} from '../../../utils/capabilityUtils';
import {EHRName} from '../MiddleContainer/PatientNotes/interfaces';
import {getCurrentEHR} from '../MiddleContainer/PatientNotes/PatientNotesHelper';
import {ILeftContainerProps} from './interfaces';
import './LeftContainer.css';
import {PAMIView} from './PAMIView';
import {RecentPatientActivity} from './RecentActivity/RecentPatientActivity';
import {AlertTable} from './AlertTable';
import {LLMMap} from './LLMMap';
import {getAccountUUID, getUserId, healthMapName, isAlertComponentVisible, isCarePlanRecommendationComponentVisible, isDiagnosesComponentVisible, isEnableCareProgram, isHealthMapComponentVisible, isSummaryComponentVisible} from '../../../utils/commonUtils';
import {LLMView} from './llmView';
import HomeMonitoring from './HomeMonitoring';
import {useContext} from 'react';
import {CommonDataContext} from '../../../context/CommonDataContext';
import { DiagnosisSurfacing } from './DiagnosisSurfacing';
import { Colors } from '../../../styles';
import { ClaimsRecommendation } from './ClaimsRecommendation';

export const LeftContainerMainView = (props: ILeftContainerProps) => {
  const accountUUID = getAccountUUID();
  const userId: number | string = getUserId();
  const mlovData = useContext(CommonDataContext);
  const isCareProgramEnabled = isEnableCareProgram(mlovData.userSettings);
  const {personData, personType} = props;

  const antdStyles: Record<string, React.CSSProperties> = {
    content: {
      overflow: 'scroll',
      background: Colors.Custom.Gray50,
    },
  };

  return (
    <Content
      className="common-scroll common-collapse"
      style={antdStyles.content}
    >
      <VStack pl={2} pt={2}>
      {isCarePlanRecommendationComponentVisible(accountUUID, userId) && (
          <ClaimsRecommendation contactUUID={personData?.contactUUID}/>
        )}
        {isHealthMapComponentVisible(accountUUID, userId) && (
          <LLMMap contactUUID={personData?.contactUUID} widgetName ={healthMapName()}/>
        )}
        {isSummaryComponentVisible(accountUUID, userId) && (
          <LLMView contactUUID={personData?.contactUUID} />
        )}
        {isAlertComponentVisible(accountUUID, userId) && (
          <AlertTable contactUUID={personData?.contactUUID} />
        )}
        {isDiagnosesComponentVisible(accountUUID, userId) && (
          <DiagnosisSurfacing personType={props.personType} personData={personData} contactUUID={personData.contactUUID}/>
        )}
        <RecentPatientActivity
          unformmatedContactData={props.unformmatedContactData}
          personType={props.personType}
          personData={personData}
        />
        <PAMIView personType={personType} personData={personData} />
      </VStack>
    </Content>
  );
};
