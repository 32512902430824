export const graphMetaDataColor = {
  axisTextColor: '#344054'
}

export const graphColorList = [
  '#80A4D5',
  '#BFD7F6',
  '#8DE3D4',
  '#BD9ECF',
  '#BBC892',
  '#EEA47B'
]

export const graphColorListForGenderWidget = [
  '#80A4D5',
  '#BD9ECF',
  '#8DE3D4',
  '#BFD7F6',
  '#BBC892',
  '#EEA47B'
]

export const taskAnalyticsWidgetColorList = [
  '#BFD7F6',
  '#80A4D5',
]