import { Select } from "antd";
import React from "react";

const UserOrMemberInput = (props:any)=> {

  const { Option } = Select;
  const children = [];
  for (let i = 0; i < props.listData.length; i++) {
    children.push(<Option key={props.listData[i]}>{props.listData[i]}</Option>);
  }
  return (
    <>
    <Select
        mode="tags"
        // size={'Default'}
        placeholder="Please select"
        defaultValue={props.users}
        onChange={r=>{
          props.onActionPerform(r);
        }}
        style={{ width: '100%' }}
      >
        {children}
      </Select>
    </>
  )
}

export default UserOrMemberInput;
