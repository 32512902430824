import React, {useEffect, useState} from 'react';
import {DependentCountProps} from '../interfaces';
import {LeadQueries} from '../../../../services';
import {useLazyQuery} from '@apollo/client';
import {View, Text, Skeleton} from 'native-base';

const DependentCount = (props: DependentCountProps) => {
  const {contactId} = props;

  const [state, setState] = useState({
    count: 0,
    loading: true,
  });

  const [getDependentCount] = useLazyQuery(
    LeadQueries.GetContactRelationCountByContactId
  );

  const getCount = async () => {
    try {
      const response = await getDependentCount({
        variables: {
          contactId: contactId,
        },
      });
      const count =
        response?.data?.contactRelationsAggregate?.aggregate?.count || 0;
      setState((prev) => {
        return {
          ...prev,
          count: count,
          loading: false,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          count: 0,
          loading: false,
        };
      });
    }
  };

  useEffect(() => {
    getCount();
  }, []);

  return (
    <View>
      {state.loading ? <Skeleton.Text lines={2} /> : <Text>{state.count}</Text>}
    </View>
  );
};

export default DependentCount;
