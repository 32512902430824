import React from "react";

function RadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="27"
      fill="none"
      viewBox="0 0 18 23"
      id='rad-icon'
    >
      <path
        fill="#C2C2C2"
        d="M9.5 5.425a.675.675 0 00-1.35 0V6.1H6.125a.675.675 0 000 1.35H8.15V8.8H4.775a.675.675 0 000 1.35H8.15v1.35H4.775a.675.675 0 100 1.35H8.15v1.35H6.125a2.025 2.025 0 101.91 1.35h1.58a2.025 2.025 0 101.91-1.35H9.5v-1.35h3.375a.675.675 0 100-1.35H9.5v-1.35h3.375a.675.675 0 100-1.35H9.5V7.45h2.025a.675.675 0 100-1.35H9.5v-.675zm2.025 10.125a.675.675 0 110 1.35.675.675 0 010-1.35zm-6.075.675a.675.675 0 111.35 0 .675.675 0 01-1.35 0zM3.425.7A3.375 3.375 0 00.05 4.075v14.85A3.375 3.375 0 003.425 22.3h10.8a3.375 3.375 0 003.375-3.375V4.075A3.375 3.375 0 0014.225.7h-10.8zM1.4 4.075A2.025 2.025 0 013.425 2.05h10.8a2.025 2.025 0 012.025 2.025v14.85a2.025 2.025 0 01-2.025 2.025h-10.8A2.025 2.025 0 011.4 18.925V4.075z"
      ></path>
    </svg>
  );
}

export default RadIcon;
