import {Button, Divider, Pressable, Spinner, Text, View} from 'native-base';
import Stack from '../LayoutComponents/Stack';
import {Colors} from '../../../styles';
import {styles} from './PendingRequestListItemStyles';
import {DisplayText} from '../DisplayText/DisplayText';
import {
  ACTION_CODES,
  LOADING_TYPES,
  SYNC_STATUS_TYPES,
} from './PendingRequestLisItemConst';
import {capitalizeText} from '../ContactRelationView/ContactRelationUtils';
import {DoubleTickIconSvg} from '../../../assets/Icons/DoubleTickIconSvg';
import CrossIconSvg from '../Svg/CrossIconSvg';
import {ErrorCircleIconSvg} from '../../../assets/Icons/ErrorCircleIconSvg';
import { Tooltip } from 'antd';

interface PendingRequestListItemProps {
  title: string;
  subTitle?: string;
  onActionPerform: (id: string, code: string) => void;
  id: string;
  status?: string;
  syncStatus?: string;
  syncStatusType: string;
  date?: string;
  loading?: {
    id: string;
    type: string;
  };
  isDataNotMatched: boolean;
  matchToView: JSX.Element;
  matchToDataText?: string;
  showError?: boolean;
  isMatchDataViewVisible?: boolean;
  error?: string;
}

export const PendingRequestListItem = (props: PendingRequestListItemProps) => {
  const {
    title,
    subTitle,
    onActionPerform,
    id,
    status,
    syncStatus,
    date,
    loading,
    syncStatusType,
    isDataNotMatched,
    matchToView,
    isMatchDataViewVisible,
    error,
  } = props;

  const isAcceptedSyncStatus = syncStatusType === SYNC_STATUS_TYPES.ACCEPTED;
  const isErrorVisible = error && !syncStatus && !loading && !isMatchDataViewVisible;
  const isAcceptLoading = loading?.id === id && loading.type === LOADING_TYPES.ACCEPT;
  const isDeclinedLoading = loading?.id === id && loading.type === LOADING_TYPES.DECLINE;
  const isDisabledAction = isAcceptLoading || isDeclinedLoading;

  return (
    <Stack direction="column" style={{marginVertical: 8, flex: 1}}>
      <Stack
        direction="row"
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Stack direction="column" style={{flex: 1}}>
          <Stack direction="row" style={{alignItems: 'center', flex: 1}}>
            <Text fontSize={14} color={Colors.FoldPixel.GRAY400}>
              {title ? title : 'Unknown'}
              <Text marginLeft={2}>
                {isDataNotMatched && !syncStatus ? (
                  <>
                    <Tooltip 
                      title='Data needs to be matched to record or is missing mandatory information.' 
                      placement={'top'}
                      destroyTooltipOnHide={true}
                    > 
                      <View>
                        <ErrorCircleIconSvg />
                      </View>                      
                    </Tooltip>                                                          
                  </>
                ) : (
                  <></>
                )}
              </Text>
            </Text>
          </Stack>
          {status && (
            <Text
              fontSize={12}
              style={{
                paddingVertical: 2,
              }}
              color={Colors.FoldPixel.GRAY300}
            >
              {status}
            </Text>
          )}

          {subTitle && (
            <Text
              fontSize={14}
              color={Colors.FoldPixel.GRAY300}
              flex={1}
              overflow={'hidden'}
            >
              {subTitle}
            </Text>
          )}
          <Text fontSize={14} color={Colors.FoldPixel.GRAY300}>
            {date}
          </Text>
        </Stack>
        <Stack direction="row" style={{alignItems: 'center', marginLeft: 8}}>
          {syncStatus ? (
            <Button
              variant="unstyled"
              style={
                isAcceptedSyncStatus
                  ? styles.acceptButton
                  : styles.declinedStatusButton
              }
              cursor={'not-allowed'}
              leftIcon={
                isAcceptedSyncStatus ? (
                  <DoubleTickIconSvg size={20} />
                ) : (
                  <CrossIconSvg
                    customStrokeColor={Colors.Custom.crossIconColor}
                    size={18}
                  />
                )
              }
            >
              <DisplayText
                textLocalId={capitalizeText(syncStatus)}
                extraStyles={{
                  color: isAcceptedSyncStatus
                    ? Colors.FoldPixel.GRAY300
                    : Colors.Custom.crossIconColor,
                  fontSize: 14,
                }}
              />
            </Button>
          ) : (
            <>
              <Pressable
                style={styles.declineButton}
                onPress={() => {
                  onActionPerform(id, ACTION_CODES.DECLINE);
                }}
                cursor={isDisabledAction ? 'not-allowed': 'pointer'}               
                isDisabled={isDisabledAction}
              >
                {isDeclinedLoading && <Spinner mr={2}/>}
                <DisplayText
                  textLocalId="decline"
                  extraStyles={{color: Colors.FoldPixel.GRAY300, fontSize: 14}}
                />
              </Pressable>
              <Pressable
                style={styles.acceptButton}
                onPress={() => {
                  if (isDataNotMatched) {
                    onActionPerform(id, ACTION_CODES.SHOW_MATCH_DATA_VIEW);
                  } else {
                    onActionPerform(id, ACTION_CODES.ACCEPT);
                  }
                }}
                cursor={isDisabledAction ? 'not-allowed': 'pointer'}               
                isDisabled={isDisabledAction}
              > 
                {isAcceptLoading && <Spinner mr={2}/>}
                <DisplayText
                  textLocalId= {isDataNotMatched ? 'requiresAction' : 'accept'}
                  extraStyles={{
                    color: Colors.FoldPixel.GRAY300,
                    fontSize: 14,
                  }}
                />
              </Pressable>
            </>
          )}
        </Stack>
      </Stack>
      {isErrorVisible && (
        <Text fontSize={14} color={Colors.FoldPixel.STATUS_ERROR}>
          {error}
        </Text>
      )}
      {isMatchDataViewVisible && matchToView}
    </Stack>
  );
};
