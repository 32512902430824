import {gql} from '@apollo/client';

export const GetUsersScheduleAccess = gql`
  query GetUsersScheduleAccess {
    userScheduleAccesses(where: {isDeleted: {_eq: false}}) {
      id
      userId
      scheduleAccessUserId
      accountLocationId
      isDeleted
    }
  }
`;

export const GrantUserScheduleAccess = gql`
  mutation createUserScheduleAccesses($objects: [user_schedule_access_insert_input!]!,  $upsert: true) {
    createUserScheduleAccesses(objects: $objects) {
      affected_rows
    }
  }
`;

export const UpdateUserScheduleAccess = gql`
  mutation updateUserScheduleAccesses(
    $group: [user_schedule_access_insert_input!]!
  ) {
    createUserScheduleAccesses(
      objects: $group
      on_conflict: {
        constraint: user_schedule_access_pkey
        update_columns: [
          isDeleted
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const GetScheduleAccessByUserId = gql`
  query GetUsersScheduleAccess ($userId: uuid!) {
    userScheduleAccesses(where: {userId: {_eq: $userId}, isDeleted: {_eq: false}}) {
      id
      userId
      scheduleAccessUserId
      accountLocationId
      isDeleted
    }
  }
`;

export default {
  UpdateUserScheduleAccess,
  GrantUserScheduleAccess,
  GetUsersScheduleAccess,
};
