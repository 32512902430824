import { Center, HStack, Skeleton, useToast, View } from 'native-base';
import { useEffect, useState } from 'react';
import { styles } from '../CodeGroups/CodeGroupsStyles';
import { NoDataFound } from '../../../common/NoDataFound';
import { Colors } from '../../../../styles';
import { getAccountUUID } from '../../../../utils/commonUtils';
import { notification } from 'antd';
import { useIntl } from 'react-intl';
import { IImportContactLog } from './interface';
import ImportContactLogTable from './ImportContactLogTable';
import { getImportContactLog } from '../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';

const ImportContactLogView = () => {
    const intl = useIntl();
    const [searchString, setSearchString] = useState('');
    const accountUUID = getAccountUUID();

    const [bulkImportStateData, setBulkImportStateData] = useState<IImportContactLog>({
        data: [],
        total: undefined,
        loading: false
    })
    const getSearch = (actionCode: string, actionData: any) => {
        setSearchString(actionData.searchString);
    };

    const fetchImportContactLog = async () => {
      try {
        const importLogData = await getImportContactLog({tenantId: accountUUID});
        if (importLogData?.bulkImportSummaries?.length) {
          const id = importLogData?.bulkImportSummaries?.[0]?.batchId;
          if (id) {
            setBulkImportStateData((prev: any) => {
              return {
                ...prev,
                data: importLogData?.bulkImportSummaries,
                total: importLogData?.bulkImportSummaries?.length,
                loading: false
              }
            })
          }
        }
      } catch (error: any) {
        notification.error({
          message: error.response.data.message || intl.formatMessage({id: 'apiErrorMsg'}),
          duration: 4.0,
          placement: 'topRight'
        });
      }
    }

    useEffect(() => {
        fetchImportContactLog();
    }, []);

    return (
        <View flex={1}>
            <HStack flex={1}>
                <View flex={1} style={styles.container}>
                    {(!bulkImportStateData.loading && bulkImportStateData?.total === 0) ? (
                      <View>
                          <View>
                              <Center
                                  justifyContent="center"
                                  alignItems="center"
                                  alignContent="center"
                                  height={600}
                              >
                                <NoDataFound
                                    iconColor={Colors.Custom.Gray300}
                                    isShowIcon={true}
                                    iconSize={35}
                                    displayString="No Bulk Import Data Found"
                                />
                              </Center>
                          </View>
                      </View>
                  ):(
                        <ImportContactLogTable
                            total={bulkImportStateData.total}
                            data={bulkImportStateData.data}
                        />
                      )}
                </View>
            </HStack>
        </View>
    );
};

export default ImportContactLogView;
