
export const validates = (
    stateData: any,

) => {
    const errors: any = {};
    let validate = true;

    errors.name = !stateData.name
        ? 'Name is required'
        : stateData.name.length < 3
            ? 'Name is too short'
            : '';

    if (stateData.websiteLink && !isValidHttpUrl(stateData.websiteLink)) {
        errors.websiteLink = 'Please enter valid website link';
    }

    if (stateData.facebookLink && !isValidHttpUrl(stateData.facebookLink)) {
        errors.facebookLink = 'Please enter valid facebook link';
    }
    if (stateData.twitterLink && !isValidHttpUrl(stateData.twitterLink)) {
        errors.twitterLink = 'Please enter valid twitter link';
    }
    if (stateData.instagramLink && !isValidHttpUrl(stateData.instagramLink)) {
        errors.instagramLink = 'Please enter valid instagram link';
    }

    if (
        errors.name ||
        errors.wesiteLink ||
        errors.twitterLink ||
        errors.facebookLink ||
        errors.instagramLink
    ) {
        validate = false;
    }
    return { validate: validate, errors: errors };
}

export function isValidHttpUrl(link: string) {
    let url;
    try {
        url = new URL(link);
    } catch (e) { return false; }
    return /https?/.test(url.protocol);
}
