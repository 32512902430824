import {Table} from 'antd';
import {View} from 'native-base';
import {Dimensions} from 'react-native';
import {ITeamMemberTableProps} from '..';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import {TableWrapper} from '../../../../common/TableWrapper';
import {IUser} from '../interfaces';
import {getTeamMemberColumns} from './Helper';

const TeamMembersTable = (props: ITeamMemberTableProps) => {
  const {
    data,
    onPasswordBtnClick,
    onProfileBtnClick,
    onSwitchToggle,
    onEditUser,
    onDeactivateUser,
    onCancelInvite,
    onResendInvite,
    onInstantChat,
    onInstantMeet,
    externalUserList,
    stateData,
    handlePageChange
  } = props;  

  const {height} = Dimensions.get('window');
  const finalHeight = height - 300;

  const onTeamMemberTableActionPerformed = (
    actionCode: string,
    rowRecord: IUser
  ): any => {
    switch (actionCode) {
      case 'EDIT':
        onEditUser(rowRecord);
        break;
      case 'profile':
        onProfileBtnClick(rowRecord);
        break;

      case 'toggle':
        onSwitchToggle(rowRecord);
        break;

      case 'password':
        onPasswordBtnClick(rowRecord);
        break;

      case 'deactivate':
        onDeactivateUser(rowRecord);
        break;

      case 'RESEND_INVITE':
        onResendInvite(rowRecord);
        break;

      case 'CANCEL_INVITE':
        onCancelInvite(rowRecord);
        break;

      case 'CHAT':
        onInstantChat(rowRecord);
        break;

      case 'MEET':
          onInstantMeet(rowRecord);
          break;
      default:
        break;
    }
  };  

  return (
    <PageBodyContainer>
      <TableWrapper
        wrapperStyle={{alignItems: 'center'}}
        minHeight={230}
        pagination={{
          current: stateData?.currentPage,
          pageSize: stateData?.pageSize || 10,
          total: stateData?.total,
          onChange: (currentPage, currentPageSize) => {
            handlePageChange?.(currentPage, currentPageSize);
          },
        }}
        hideOnSinglePage={false}
      >
      <Table
        scroll={{x: 1000, y: finalHeight}}
        rowClassName={(record, index) =>
          index % 2 == 0 ? 'table-row-light' : ''
        }
        columns={getTeamMemberColumns(onTeamMemberTableActionPerformed, externalUserList || [])}
        dataSource={data}
        pagination={false}
        rowKey={(row: IUser) => `team_user_list_${row.uuid}_${row.id}`}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              if (record.isInvited) {
                return;
              }
              onTeamMemberTableActionPerformed('EDIT', record);
            },
          };
        }}
      />
      </TableWrapper>
    </PageBodyContainer>
  );
};

export default TeamMembersTable;
