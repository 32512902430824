import { ColumnsType } from 'antd/lib/table';
import { Text, View, Badge, HStack} from 'native-base';
import { Pressable } from 'react-native';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import { ICustomContactTypesTableData } from '../CustomContactTypes/interfaces';
import { Colors } from '../../../../styles';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { getFormattedDate } from '../../../../utils/DateUtils';
import { DATE_FORMATS } from '../../../../constants/StringConst';
import { Tooltip } from 'antd';
import DownloadSvg from '../../../common/Svg/DownloadSvg';

export const getImportContactLogColumns = (
  actionFn: (actionCode: string, record: any) => {}
) => {
  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 12, fontWeight: 500, fontSize: 14}}
          textLocalId="Import Date"
        />
      ),
      dataIndex: 'createdOn',
      key: 'title',
      fixed: 'left',
      width: '10%',
      render: (
        createdOn: string,
      ) => (
        <HStack alignItems={'center'} space={1}>
          <Text bold={true}>{getFormattedDate(
            createdOn,
            DATE_FORMATS.CALL_LOG_DETAILS_DATE
          )}</Text>
        </HStack>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 12, fontWeight: 500, fontSize: 14}}
          textLocalId="File Name"
        />
      ),
      dataIndex: 'fileName',
      key: 'fileName',
      fixed: 'left',
      width: '10%',
      render: (
        fileName: string,
      ) => (
        <View marginLeft={3}>
          <Text bold={true}>{fileName}</Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8, fontWeight: 500, fontSize: 14}}
          textLocalId="Total Contacts"
        />
      ),
      dataIndex: 'totalCount',
      key: 'totalCount',
      width: '10%',
      render: (
        totalCount: number
      ) => (
        <View marginLeft={9}>
          <Text size={'smMedium'} color={Colors.Custom.Gray900} alignContent={'flex-start'}>
            {totalCount}
          </Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8, fontWeight: 500, fontSize: 14}}
          textLocalId="Passed"
        />
      ),
      dataIndex: 'passCount',
      key: 'passCount',
      width: '10%',
      render: (passCount: number) => (
        <View justifyContent={'center'} marginLeft={5}>
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {passCount}
          </Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8, fontWeight: 500, fontSize: 14}}
          textLocalId="Failed"
        />
      ),
      dataIndex: 'failedCount',
      key: 'failedCount',
      width: '10%',
      render: (
        failedCount: number,
        record: ICustomContactTypesTableData
      ) => (
          <View justifyContent={'flex-start'} marginLeft={5}>
            <Text size={'smMedium'} color={Colors.Custom.Gray900} style={{
              fontSize: 16,
              fontWeight: '500',
              color: Colors.Custom.Gray600
            }}>
              {failedCount}
            </Text>
          </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8, fontWeight: 500, fontSize: 14}}
          textLocalId="Action"
        />
      ),
      dataIndex: 'failedCount',
      key: 'failedCount1',
      width: '10%',
      render: (
        failedCount: number,
        record: ICustomContactTypesTableData
      ) => (
        failedCount === 0 ?
          <View></View>
          :
          <View justifyContent={'flex-start'} marginLeft={5}>
            <Tooltip title={'Download Failed'} placement='left'>
            <Pressable disabled={failedCount === 0}
              onPress={() => {
                actionFn(COMMON_ACTION_CODES.DOWNLOAD, record)
            }}>
                <DownloadSvg
                  customColor={
                    Colors.Custom.Gray400
                  }
                  customStrokeWidth="1"
                />

            </Pressable>
            </Tooltip>
          </View>
      ),
    },
  ];
  return columns;
};
