import React, { useEffect, useState } from 'react';
import { Divider, Table, Tooltip, Modal as ModalAntd , Button} from 'antd';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { Colors } from '../../../../styles/Colors';
import { Center, HStack, Pressable, Text, VStack , View } from 'native-base';
import CMSLoading from '../CMSLoading/CMSLoading';
import CommonService from '../../../../services/CommonService/CommonService';
import { getDateObjectFromAPIFormatToMyFormat } from '../../../../utils/DateUtils';
import { DATE_FORMATS } from '../../../../constants';
import PenIcon from '../../../../assets/Icons/PenIcon';
import TrashBin2Icon from '../../../../assets/Icons/TrashBin2Icon';
import EyeIcon from '../../../../assets/Icons/EyeIcon';
import { getAccountId, getCurrentUserRole, getUserAccountLocationUuids, getUserId, getUserUUID, isLoggedInUserGlobalAdmin, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner } from '../../../../utils/commonUtils';
import { useLazyQuery } from '@apollo/client';
import UserQueries from '../../../../services/User/UserQueries';
import { Value } from 'liquidjs';
import ShieldCheckSvg from '../../../common/Svg/ShieldCheckSvg';
import { useIntl } from 'react-intl';
import TrashBinSvg from '../../../common/Svg/TrashBinSvg';
import { GLOBAL_ROLE_CODES, USER_ROLE_CODES } from '../../../../constants/MlovConst';
import { Dimensions } from 'react-native';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { styles } from '../LLMTemplates/llmTemplateStyles';
import { antStyles } from '../LLMTemplates/llmTemplateStyles';
import { RenderCreatedBy, RenderStatusSwitch, RoleView } from '../LLMTemplates/Helper';
import { LLMTemplateViewCodes } from '../LLMTemplates/llmTemplatesConsts';
import { deleteLLMTemplate } from '../LLMTemplates/llmTemplateUtils';
import AddOrUpdateHealthMapTemplates from './HealthMapTemplateActions/AddOrUpdateHealthMapTemplates';
import { SingleHealthMapTemplate } from './interfaces';
import { deleteHealthMapTemplate } from './healthMapTemplateUtils';
import { RenderHeathMapStatusSwitch } from './Helper';


const HealthMapList = (props: {
  viewCode: string,
  selectedRow?: SingleHealthMapTemplate,
  onActionPerformed: (actionCode: string, record: SingleHealthMapTemplate, newViewCode?: LLMTemplateViewCodes) => void,
  searchString?: string
}) => {
  const userUUID = getUserUUID()
  const {height} = Dimensions.get('window');
  const currentUserRoles = getCurrentUserRole();
  const adminAccess = isLoginUserBusinessOwner() || isLoggedInUserGlobalAdmin() || isLoggedInUserWorkFlowOrCustomerSuccess()

  const { viewCode, selectedRow, onActionPerformed, searchString } = props;
  const [llmTemplates, setLlmTemplates] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState<any>([])
  const [roleList, setRoleList] = useState<any>([])
  const accountId = getAccountId();
  const commonService = CommonService.getCommonServiceInstance();
  const llmflowService = commonService.llmService;
  const [total, setTotal] = useState(10)
  const [currentPage, setCurrentPage] = useState(1);
  const [actionState , setActionState] = useState({isShowDelete:false, templateId : ''});
  const [pageSize, setPageSize] = useState(10);
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const userAccountLocationUuids = getUserAccountLocationUuids();
  const isBusinessOwner = isLoginUserBusinessOwner();
  const [userPrompt , setUserPrompt] = useState('')
  const [systemPrompt , setSystemPrompt] = useState('')


  const fetchTemplates = async () => {
    try {
      setLoading(true);

      llmflowService.get(
        '/healthmap/template?searchQuery=' + searchString + '&page=' + currentPage + '&pageSize=' + pageSize
      ).then((responseData: any) => {
         setLlmTemplates(responseData?.data?.templateList || [])
        setTotal(responseData?.data?.total)
        setUserPrompt(responseData?.data?.userPrompt)
        setSystemPrompt(responseData?.data?.systemPrompt)
        setLoading(false);
      })

    } catch (err: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, [searchString, currentPage, pageSize]);

  useEffect(() => {
    if (viewCode === LLMTemplateViewCodes.LIST) {
      fetchTemplates();
    }
  }, [viewCode]);


  const handleActionPerformed = (
    actionCode: string,
    record: SingleHealthMapTemplate,
    newViewCode?: LLMTemplateViewCodes
  ) => {
    onActionPerformed(actionCode, record, newViewCode);
  };

  const handleEdit = (template: SingleHealthMapTemplate) => {
    onActionPerformed(COMMON_ACTION_CODES.EDIT, template, LLMTemplateViewCodes.EDIT);
  };

  const handleDelete = async (templateId: string) => {
    await deleteHealthMapTemplate(templateId);
    fetchTemplates();
  };

  const ActionViews = () => {
    return (
      <>
        <AddOrUpdateHealthMapTemplates
          viewCode={viewCode}
          template={selectedRow}
          onActionPerformed={handleActionPerformed}
          adminAccess={adminAccess}
          userList={userList}
          roleList={roleList}
          userPrompt={userPrompt || ''}
          systemPrompt={systemPrompt || ''}
        />
      </>
    )
  }
  const getTableColumns = () => {
    const llmcolumns = [
      {
        title: 'Name',
        key: 'templateName',
        render: (template: any) => {
          return (
            <Tooltip title={template?.templateName} placement='top'>
              <Text style={[styles.tableContent, { color: Colors.FoldPixel.GRAY400 }]}>
                {template?.templateName} {template?.isDefault ? ' (Default)' : null}
              </Text>
            </Tooltip>
          );
        },
      },
      {
        title: 'Created By',
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: '15%',
        render: (createdBy: any) => {
          return (
            <RenderCreatedBy
              userUuid={createdBy}
            />
          )
        },
      },
      {
        title: 'Access',
        key: 'accessRole',
        width: '15%',
        render: (template: any) => {

          return (
            <RoleView
              template={template}
              userList={userList}
              roleList={roleList}
            />
          )
        }
      },
      {
        title: 'Date Updated',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        width: '15%',
        render: (updatedAt: string) => {
          const date = getDateObjectFromAPIFormatToMyFormat(updatedAt, DATE_FORMATS.MONTH_DATE_YEAR)
          return (
            <Text style={styles.tableContent}>
              {date}
            </Text>)
        }
      },
      {
        title: 'Status',
        key: 'isActive',
        width: '8%',
        render: (record: any) => {
          const createdByLoginUser = (userUUID === record?.createdBy)
          return (
            <RenderHeathMapStatusSwitch
              data={record}
              isDisabled={!adminAccess && !createdByLoginUser}
            />)
        }
      },
      {
        title: 'Actions',
        key: 'actions',
        width: '10%',
        render: (template: any) => {
          const createdByLoginUser  = (userUUID === template?.createdBy)
          return (
            <HStack space={2}>
              <Pressable onPress={() => handleEdit(template)}>
                <Tooltip title='Edit'>
                  {(template?.isDefault && !adminAccess) ?
                    <EyeIcon /> :
                    <PenIcon />
                  }
                </Tooltip>
              </Pressable>
              <Divider type='vertical' style={antStyles.dividerStyle} ></Divider>
              <Pressable
                cursor={template?.isDefault || !createdByLoginUser ? 'not-allowed' : 'pointer'}
                onPress={() => {
                  setActionState({isShowDelete:true, templateId :template?.id})
                }}
                isDisabled={template?.isDefault || !createdByLoginUser}
                >
                <Tooltip title='Delete'>
                  <TrashBin2Icon
                    color={template?.isDefault || !createdByLoginUser ? Colors.FoldPixel.GRAY150 : undefined}
                  />
                </Tooltip>
              </Pressable >

            </HStack>
          );
        },
      },
    ];

    return llmcolumns
  }


  const [getAccountUsers] = useLazyQuery((isMsoEnabled && !isBusinessOwner)? UserQueries.GET_USERS_FOR_CALENDAR_V2 : UserQueries.GET_ALL_ACTIVE_USERS, {
    fetchPolicy: 'no-cache',
  });

  const fetchAccountUsers = async () => {
    const userboolExp = isMsoEnabled
    ? {
        _or: [
          {
            userPracticeLocations: {
              isDeleted: {
                _eq: false,
              },
              accountLocation: {
                uuid: {
                  _in: userAccountLocationUuids,
                },
              },
            },
          },
          {
            userRoles: {
              userRole: {
                userRole: {
                  code: { _in: [GLOBAL_ROLE_CODES.GLOBAL_ADMIN] },
                },
              },
            },
          },
        ],
      }
    : {};

    const response = await getAccountUsers({
      variables: {
        accountId: accountId,
        ...(isMsoEnabled && { userboolExp }),
      }
    })
    const userList = response?.data?.users || [];
    setUserList(userList)
  }

  useEffect(() => {
    fetchAccountUsers()
  }, [])

  const [getRoleList] = useLazyQuery(UserQueries.GET_ALL_DISTINCT_USER_ROLES, {
    fetchPolicy: 'no-cache',
  });

  const fetchUserRoles = async () => {
    const response = await getRoleList()
    const roleList = response?.data?.accountRoles  || [];
    setRoleList(roleList);
  }

  useEffect(() => {
    fetchUserRoles();
  }, [])


  return (
    <View style={styles.viewStyle}>
      {loading ? <CMSLoading /> :
        <Table
          scroll={{x: 850, y: height - 265}}
          columns={getTableColumns()}
          dataSource={llmTemplates}
          pagination={
            {
              position: ['bottomCenter'],
              pageSize: pageSize,
              total: total,
              current: currentPage,
              onChange(page, pageSize) {
                setCurrentPage(page)
                setPageSize(pageSize)

              }
            }
          }
        />
      }

      <ActionViews />
      <DeleteModal
       templateId={actionState.templateId}
       isShowDelete={actionState.isShowDelete}
       setActionState = {setActionState}
       handleDelete={handleDelete}/>
    </View>
  )
}

const DeleteModal = (props: {
  templateId: any;
  isShowDelete: boolean;
  setActionState: any;
  handleDelete: any;
}) => {
  const intl = useIntl();
  const {templateId, setActionState, isShowDelete, handleDelete} = props;

  return (
    <ModalAntd
      className='llmModalV1'
      title={
        <View>
          <VStack alignItems={'Center'}>
            <TrashBinSvg></TrashBinSvg>
            <Text fontWeight={500} fontSize={15} paddingTop={2}>
              {'Delete Template ?'}
            </Text>
          </VStack>
        </View>
      }
      open={isShowDelete}
      onCancel={() => {
        setActionState({isShowDelete: false, templateId: ''});
      }}
      bodyStyle={antStyles.antdModalStyle}
      footer={
        <View alignItems={'Center'}>
          <HStack space={4}>
            <Button
              key='cancel'
              className='btn'
              onClick={() => {
                setActionState({isShowDelete: false, templateId: ''});
              }}
              style={antStyles.buttonStyle}
            >
              {intl.formatMessage({
                id: 'cancel',
              })}
            </Button>
            <Button
              key='yes'
              type='primary'
              className='btn'
              style={antStyles.buttonStyle}
              onClick={() => {
                handleDelete(templateId);
                setActionState({isShowDelete: false, templateId: ''});
              }}
            >
              {intl.formatMessage({
                id: 'yes',
              })}
            </Button>
          </HStack>
        </View>
      }
      width={500}
    >
      <Text color={Colors.FoldPixel.GRAY200}>
        {intl.formatMessage({id: 'deleteTemplateConfirmation'})}
      </Text>
    </ModalAntd>
  );
};




export default HealthMapList;
