export enum AppointmentAvailabilityCode {
  ROLE = 'ROLE_BASED',
  CARE_TEAM = 'CARE_TEAM_BASED',
  PROVIDER = 'PROVIDER_BASED'
};

export enum AppointmentApplicableBy {
  EMPLOYER = 'EMPLOYER',
  POP_GROUPS = 'POP_GROUPS'
}

export enum SelectMembersBy {
  ALL_MEMBERS = 'ALL_MEMBERS',
  SELECTED_MEMBERS = 'SELECTED_MEMBERS'
}

export const VALUE_TYPE_CODES = {
  DURATION: 'DURATION'
}

export const APPOINTMENT_CUSTOM_FIELD_TYPES = {
  APPOINTMENT_TYPE: 'APPOINTMENT_TYPE'
}

export const APPOINTMENT_TYPE_INTERVAL_TYPES = {
  ANY_TIME :'ANY_TIME',
  BOOK_WITHIN_DAYS: 'BOOK_WITHIN_DAYS',
  CANCEL_BEFORE_TIME: 'CANCEL_BEFORE_TIME',
  RESCHEDULE_BEFORE_TIME: 'RESCHEDULE_BEFORE_TIME'
}