
export enum TrackEventName {
  VOICE_CALL = 'VoiceCall',
  LOGIN_ERROR = 'LoginError',
  APP_UPDATE_ERROR = 'AppUpdateError',
  FORGOT_PW_ERROR = 'ForgotPasswordError',
}

export interface ITrackEventData {
  eventType: string;
  [key: string]: any;
}
