import React from 'react';
import {Colors} from '../../../styles';
import {ICommonSvgProps} from './interfaces';

const TagsSvg = (props: ICommonSvgProps) => {
  const {
    isEnabled,
    defaultColor,
    customStrokeColor,
    customBgColor,
    borderColor,
  } = props;
  const bgColor = isEnabled
    ? customBgColor
      ? customBgColor
      : Colors.primary['500']
    : customBgColor
    ? customBgColor
    : Colors.primary['200'];
  return (
    <svg
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={defaultColor ? defaultColor : bgColor}
        d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z"
      ></path>
      <path
        stroke={customStrokeColor ? customStrokeColor : '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13 13h.007m9.053 4.273l-4.78 4.78a1.335 1.335 0 01-1.887 0l-5.726-5.72V9.667h6.666l5.727 5.726a1.333 1.333 0 010 1.88z"
      ></path>
      <path
        fill={borderColor ? borderColor : '#FDD7AD'}
        d="M16 31C7.716 31 1 24.284 1 16h-2c0 9.389 7.611 17 17 17v-2zm15-15c0 8.284-6.716 15-15 15v2c9.389 0 17-7.611 17-17h-2zM16 1c8.284 0 15 6.716 15 15h2c0-9.389-7.611-17-17-17v2zm0-2C6.611-1-1 6.611-1 16h2C1 7.716 7.716 1 16 1v-2z"
      ></path>
    </svg>
  );
};

export default TagsSvg;
