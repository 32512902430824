import {useLazyQuery} from '@apollo/client';
import {DatePicker, Steps} from 'antd';
import moment from 'moment';
import {FormControl, HStack, Text, useToast, View, VStack} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useInterval} from 'react-use';
import {BUTTON_TYPE, DATE_FORMATS} from '../../../../../constants';
import {getEnvVariable} from '../../../../../constants/BaseUrlConst';
import {CRM_BASE_URL} from '../../../../../constants/Configs';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import CommonService from '../../../../../services/CommonService/CommonService';
import {
  ITimezone,
  IUserPracticeLocationData
} from '../../../../../services/Location/interfaces';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import {Colors} from '../../../../../styles';
import {getAccountId, getAccountUUID} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {getMomentObj} from '../../../../../utils/DateUtils';
import {Consent} from '../../../../common/Consent/Consent';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import LoadingSpinner from '../../../../common/Loader/LoadingSpinner';
import {TimezoneSelect} from '../../../../common/TimezoneSelect/TimezoneSelect';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {styles} from './PracticeDetailsStyles';

const {Step} = Steps;

const PracticeDetails = () => {
  const commonService = CommonService.getCommonServiceInstance();
  const integrationService = commonService.integrationService;
  const crmService = commonService.crmService;
  const intl = useIntl();
  const toast = useToast();
  const [timezone, setTimeZone] = useState<ITimezone>();
  const [location, setLocation] = useState(0);
  const [users, setUsers] = useState(0);
  const [patient, setPatient] = useState(0);

  const [appointment, setAppointMent] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [current, setCurrent] = useState(0);
  const [consent, setConsent] = useState(true);
  const [accountPracticeLocationsQuery] = useLazyQuery<any>(
    UserPracticeLocationQueries.GetAccountPracticeLocations,
    {
      fetchPolicy: 'no-cache'//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const onChange = (value: number) => {
    setCurrent(value);
  };
  const [appointmentDates, setAppointmentDates] = useState({
    fromDate: new Date(),
    toDate: new Date()
  });
  const [syncState, setSyncState] = useState({
    locationSync: '' as any,
    userSync: '' as any,
    patientSync: '' as any,
    appointmentSync: '' as any,
    userPracticeLocations: [] as any[],
    tenantName: '' as any
  });

  const [getUserPracticeLocation] = useLazyQuery<IUserPracticeLocationData>(
    UserPracticeLocationQueries.GetUserPracticeLocations,
    {
      fetchPolicy: 'no-cache'//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const envStr = getEnvVariable();
  const commonData = useContext(CommonDataContext);
  const accountId = getAccountId();
  const accountUuid = getAccountUUID();
  const getTenantName = async () => {
    let tenantName = '';
    const subdomainMap: any = commonData.accountSubdomain || {};
    const subdomainName: string = subdomainMap?.subDomain;
    if (subdomainName && envStr.length) {
      tenantName = subdomainName.replace(`.${envStr}.foldhealth.io`, '');
    } else if (subdomainName && !envStr.length) {
      tenantName = subdomainName.replace('.foldhealth.io', '');
    }
    setSyncState((prev) => {
      return {
        ...prev,
        tenantName: tenantName
      };
    });
  };

  const getUserPracticeLocations = async () => {
    const userPracticeLocations = await getUserPracticeLocation();
    setSyncState((prev) => {
      return {
        ...prev,
        userPracticeLocations:
          userPracticeLocations.data?.accountLocations || []
      };
    });
  };
  useEffect(() => {
    getUserPracticeLocations();
    getTenantName();
  }, []);

  const interval = useInterval(async () => {
    if (!location) {
      const url = 'api/service-request?serviceType=EhrLocationImport';
      integrationService
        .get(url, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((resp: {data: any}) => {
          if (resp?.data) {
            setSyncState((prev) => {
              return {
                ...prev,
                locationSync: resp?.data[0]?.serviceStatus
              };
            });
            if (resp?.data[0]?.serviceStatus === 'COMPLETED') {
              setLocation(1);
              setUsers(0);
              setCurrent(1);
            }
          }
        })
        .catch((e: any) => {

          setLocation(0);
        });
    }

    if (!users) {
      const url = 'api/service-request?serviceType=EhrPractitionerUserImport';
      integrationService
        .get(url, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((resp: {data: any}) => {
          if (resp?.data) {
            setSyncState((prev) => {
              return {
                ...prev,
                userSync: resp?.data[0]?.serviceStatus
              };
            });
            if (resp?.data[0]?.serviceStatus === 'COMPLETED') {
              setUsers(1);
              setPatient(0);
              setCurrent(2);
            }
          }
        })
        .catch((e: any) => {

          setUsers(0);
        });
    }
    if (!patient) {
      const url = 'api/service-request?serviceType=EhrPatientImport';
      integrationService
        .get(url, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((resp: {data: any}) => {
          if (resp?.data) {
            setSyncState((prev) => {
              return {
                ...prev,
                patientSync: resp?.data[0]?.serviceStatus
              };
            });
            if (resp?.data[0]?.serviceStatus === 'COMPLETED') {
              setPatient(1);
              setAppointMent(0);
              setCurrent(3);
            }
          }
        })
        .catch((e: any) => {

          setPatient(0);
        });
    }

    if (!appointment) {
      const url = 'api/service-request?serviceType=EhrAppointmentImport';
      integrationService
        .get(url, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((resp: {data: any}) => {
          if (resp?.data) {
            setSyncState((prev) => {
              return {
                ...prev,
                appointmentSync: resp?.data[0]?.serviceStatus
              };
            });
            if (resp?.data[0]?.serviceStatus === 'COMPLETED') {
              setAppointMent(1);
              setCurrent(4);
            }
          }
        })
        .catch((e: any) => {

          setAppointMent(0);
        });
    }
  }, 1000);

  const parseDate = (
    dateString: string | undefined,
    fromFormat?: string,
    toFormat?: string
  ): string | undefined => {
    if (dateString) {
      return moment(dateString, fromFormat).format(toFormat);
    }
  };

  const steps = [
    {
      key: 'Location Sync',
      title: (
        <>
          <Text>Elation Location Sync</Text>{' '}
          {syncState.locationSync === 'FAILED' ? (
            <Text color={Colors.Custom.Danger100} size={'smMedium'}>
              {syncState.locationSync}
            </Text>
          ) : (
            <Text
              color={
                syncState.locationSync === 'COMPLETED'
                  ? Colors.success[600]
                  : Colors.Custom.Gray400
              }
              size={'smMedium'}
            >
              {syncState.locationSync}
            </Text>
          )}
        </>
      ),
      content: (
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              syncTimezone();
            },
            isDisabled: location ? true : false,
            style: {marginRight: 20}
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'Sync Elation Location'
            }),
            withRightBorder: false
          }}
        ></FoldButton>
      )
    },

    {
      key: 'User Sync',
      title: (
        <>
          <Text>Elation User Sync</Text>{' '}
          {syncState.userSync === 'FAILED' ? (
            <Text color={Colors.Custom.Danger100} size={'smMedium'}>
              {syncState.userSync}
            </Text>
          ) : (
            <Text
              color={
                syncState.userSync === 'COMPLETED'
                  ? Colors.success[600]
                  : Colors.Custom.Gray400
              }
              size={'smMedium'}
            >
              {syncState.userSync}
            </Text>
          )}
        </>
      ),
      content: location ? (
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              syncUserCall();
            },
            isDisabled: users ? true : false,
            style: {marginRight: 10}
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'Sync Elation Users'
            }),
            withRightBorder: false
          }}
        ></FoldButton>
      ) : (
        <></>
      )
    },
    {
      key: 'Patient Sync',
      title: (
        <>
          <Text>Elation Patient Sync</Text>{' '}
          {syncState.patientSync === 'FAILED' ? (
            <Text color={Colors.Custom.Danger100} size={'smMedium'}>
              {syncState.patientSync}
            </Text>
          ) : (
            <Text
              color={
                syncState.patientSync === 'COMPLETED'
                  ? Colors.success[600]
                  : Colors.Custom.Gray400
              }
              size={'smMedium'}
            >
              {syncState.patientSync}
            </Text>
          )}
        </>
      ),
      content: users ? (
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              syncPatientCall();
            },
            isDisabled: patient ? true : false,
            style: {marginRight: 10}
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'Sync Elation Patients'
            }),
            withRightBorder: false
          }}
        ></FoldButton>
      ) : (
        <></>
      )
    },
    {
      key: 'Appointment Sync',
      title: (
        <>
          <Text>Elation Appointment Sync</Text>{' '}
          {syncState.appointmentSync === 'FAILED' ? (
            <Text color={Colors.Custom.Danger100} size={'smMedium'}>
              {syncState.appointmentSync}
            </Text>
          ) : (
            <Text
              color={
                syncState.appointmentSync === 'COMPLETED'
                  ? Colors.success[600]
                  : Colors.Custom.Gray400
              }
              size={'smMedium'}
            >
              {syncState.appointmentSync}
            </Text>
          )}
        </>
      ),
      content: (
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              appointmentSync();
            },
            isDisabled: appointment ? true : false,
            style: {marginRight: 10}
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'Sync Elation Appointments'
            }),
            withRightBorder: false
          }}
        ></FoldButton>
      )
    }
  ];

  const syncTimezone = () => {
    const url = '/api/sync/Location';
    setShowLoader(true);
    const data = {
      timeZone: timezone
    };
    integrationService
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp: {data: any}) => {
        if (resp?.data) {
          setShowLoader(false);
          showToast(
            toast,
            `Location Data is being synced. We will notify you once the import is complete.`,
            ToastType.info
          );
          setUsers(1);
        }
      })
      .catch((e: any) => {

        setShowLoader(false);
        false;
        showToast(
          toast,
          `Location Data could not be synced. Please contact support at hello@fold.health`,
          ToastType.error
        );
      });
  };

  const syncUserCall = () => {
    const url = '/fhir/Practitioner';

    const userList: {
      email: any;
      name: any;
      password: string;
      role: string;
      tenant: string;
    }[] = [];

    const serviceRequest = {
      serviceType: 'EhrPractitionerUserImport'
    };

    setShowLoader(true);

    const createSR = 'api/service-request';
    let serviceRequestId: any;
    integrationService
      .post(createSR, serviceRequest, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp: {data: any}) => {
        serviceRequestId = resp?.data?.id;
      });

    integrationService
      .get(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp: {data: any}) => {
        if (resp?.data) {
          const entries = resp?.data?.entry;
          if (entries) {
            for (const entry of entries) {
              let name;
              let email;
              let id;
              let isActive = true;
              if (entry?.resource) {
                id = entry?.resource?.id;
                isActive = entry?.resource?.active ?? true;
                name = entry?.resource?.name[0]?.given[0];
                // isActive = entry?.resource?.extension;
                const contacts = entry?.resource?.telecom || [];
                for (const contact of contacts) {
                  if (contact.system === 'email') {
                    email = contact.value;
                  }
                }
              }

              const user = {
                email: email,
                name: name,
                password: 'Asdf1234$',
                role: 'PHYSICIAN',
                tenant: syncState.tenantName,
                externalUserId: `${id}`,
                isActive: isActive
              };
              userList.push(user);
            }
            if (userList.length > 0) {
              const users = {
                users: userList,
                accountId: accountId,
                locationUuid:
                  syncState.userPracticeLocations[0]?.practiceLocation?.uuid,
                accountUuid: accountUuid,
                accountLocationUuid: syncState.userPracticeLocations[0]?.uuid
              };
              setSyncState((prev) => {
                return {
                  ...prev,
                  userSync: 'IN-PROGRESS'
                };
              });
              crmService
                .post(
                  CRM_BASE_URL + `/seeding/platform/accountUser`,
                  JSON.stringify(users),
                  {
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }
                )
                .then((resp: {data: any}) => {
                  setShowLoader(false);
                  showToast(
                    toast,
                    `User Data is being synced. We will notify you once the import is complete.`,
                    ToastType.info
                  );
                  setSyncState((prev) => {
                    return {
                      ...prev,
                      userSync: 'COMPLETED'
                    };
                  });
                  const serviceRequest = {
                    serviceType: 'EhrPractitionerUserImport',
                    serviceStatus: 'COMPLETED',
                    serviceRequestId: serviceRequestId
                  };

                  const createSR = 'api/service-request';

                  integrationService
                    .post(createSR, serviceRequest, {
                      headers: {
                        'Content-Type': 'application/json'
                      }
                    })
                    .then((resp: {data: any}) => {
                      serviceRequestId = resp?.data?.id;
                    });

                  setUsers(1);
                  setCurrent(2);
                })
                .catch((e: any) => {

                  setShowLoader(false);
                  false;
                  showToast(
                    toast,
                    `User Data could not be synced. Please contact support at hello@fold.health`,
                    ToastType.error
                  );
                });
            }
          }
        }
      })
      .catch((e: any) => {

        setShowLoader(false);
        false;
        showToast(
          toast,
          `User Data could not be synced. Please contact support at hello@fold.health`,
          ToastType.error
        );
      });

  };

  const syncPatientCall = () => {
    const url = '/api/sync/Patient';
    setShowLoader(true);
    const body = {
      bulkImport: true
    };
    integrationService
      .post(url, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp: {data: any}) => {
        if (resp?.data) {
          setShowLoader(false);
          showToast(
            toast,
            `Patient Data is being synced. We will notify you once the import is complete.`,
            ToastType.info
          );
          setPatient(1);
        }
      })
      .catch((e: any) => {

        setShowLoader(false);
        false;
        showToast(
          toast,
          `Patient Data could not be synced. Please contact support at hello@fold.health`,
          ToastType.error
        );
      });
  };

  const appointmentSync = () => {
    const url = '/api/sync/Appointment';
    setShowLoader(true);
    const body = {
      fromDate: moment(appointmentDates.fromDate).format('YYYY-MM-DD'),
      toDate: moment(appointmentDates.toDate).format('YYYY-MM-DD')
    };
    integrationService
      .post(url, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp: {data: any}) => {
        if (resp?.data) {
          setAppointMent(1);
          setShowLoader(false);
        }
      })
      .catch((e: any) => {

        setShowLoader(false);
        false;
        showToast(
          toast,
          `Appointment Data could not be synced. Please contact support at hello@fold.health`,
          ToastType.error
        );
      });
    setShowLoader(false);
    showToast(
      toast,
      `Appointment Data is being synced. We will notify you once the import is complete.`,
      ToastType.info
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <Text size={'lgBold'} ml={2}>
          Elation Setup
        </Text>

        <View style={styles.dataContainer}>
          <TimezoneSelect
            className="appointment-timezone"
            showLabel={true}
            showSelectBorder={false}
            selectColor="transparent"
            isRequired={true}
            onChange={(timezone?: ITimezone) => {
              setTimeZone((prev: any) => ({
                ...prev,
                timezone
              }));
            }}
          />

          <FormControl>
            <FormControl.Label isRequired>
              <DisplayText textLocalId="fromDate" />
            </FormControl.Label>
            <DatePicker
              style={{height: '40px'}}
              allowClear={false}
              defaultValue={getMomentObj(appointmentDates.fromDate)}
              format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
              onChange={(date: any) => {
                if (date) {
                  setAppointmentDates((prev: any) => {
                    return {
                      ...prev,
                      fromDate: date
                    };
                  });
                }
              }}
            />
          </FormControl>
          <FormControl>
            <FormControl.Label isRequired>
              <DisplayText textLocalId="toDate" />
            </FormControl.Label>
            <DatePicker
              style={{height: '40px'}}
              allowClear={false}
              defaultValue={getMomentObj(appointmentDates.toDate)}
              format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
              onChange={(date: any) => {
                if (date) {
                  setAppointmentDates((prev: any) => {
                    return {
                      ...prev,
                      toDate: date
                    };
                  });
                }
              }}
            />
          </FormControl>
        </View>
        <VStack
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            flex: 0.5,
            marginTop: 20
          }}
        ></VStack>
        <Steps direction="vertical" current={current} onChange={onChange}>
          {steps.map((item) => (
            <Step key={item?.key} title={item?.title} />
          ))}
        </Steps>

        <div className="steps-content">{steps[current]?.content}</div>
        <HStack style={{marginTop: 20}}>
          <Consent
            isChecked={consent}
            textLocaleId={'consents'}
            setConsent={() => {
              setConsent(!consent);
            }}
          />
        </HStack>
      </View>
      {showLoader && <LoadingSpinner />}
    </View>
  );
};
export default PracticeDetails;
