import React from 'react';
import {ITag} from '../Interfaces';
import {Badge, HStack} from 'native-base';
import {styles} from './TagViewStyles';
import TagViewHelper from './TagViewHelper';

export const TagView = (props: {data: ITag[]}) => {
  return (
    <HStack style={styles.tag}>
      {props.data.map(tag => {
        const tagName = tag.tagName.tagName;
        const colors = TagViewHelper.getTagColorScheme(tagName);
        return (
          <Badge
            colorScheme={colors?.tag}
            backgroundColor={colors?.background}
            alignSelf="center"
            variant="outline"
            marginRight={2}
            key={tag.id}>
            {tagName}
          </Badge>
        );
      })}
    </HStack>
  );
};

export default TagView;
