import React from 'react';
import {IMlov} from '../Interfaces';

import {Text, View} from 'native-base';
import {styles} from './StatusViewStyles';
import StatusViewHelper from './StatusViewHelper';

export const StatusView = (props: {status: IMlov}) => {
  return (
    <View style={styles.statusContainer}>
      <Text style={styles.text}>
        <Text style={StatusViewHelper.getStyle(props.status)}>{'\u2022'}</Text>
        {props.status.value}
      </Text>
    </View>
  );
};

export default StatusView;
