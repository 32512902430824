import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';

export const styles = StyleSheet.create({
  outreachTitleText: {
    color: Colors.FoldPixel.GRAY400,
    fontSize: 14,
    fontWeight: '600',
  },
  outreachSubText: {
    color: Colors.FoldPixel.GRAY300,
    fontSize: 12,
  },
  inabilityToContactText: {
    fontSize: 12,
    fontWeight: '600',
  },
  timeLineDotView: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: Colors.FoldPixel.GRAY250,
    marginRight: 16,
    marginLeft: -2.5,
  },
  timeLineTitleViewContainer: {
    marginTop: 16,
    marginLeft: 16,
    alignItems: 'center',
  },
  timeLineDetailView: {
    marginLeft: 18,
  },
  timeLineTopLineView: {
    width: 0.5,
    height: 24,
    flexDirection: 'column',
    backgroundColor: Colors.FoldPixel.GRAY250,
  },
  timeLineBottomLineView: {
    width: 0.5,
    flex: 1,
    height: 20,
    flexDirection: 'column',
    backgroundColor: Colors.FoldPixel.GRAY250,
  },
  timelineViewContainer: {flex: 0, flexDirection: 'column'},
  outreachActivityContainer: {
    marginTop: 24,
  },
  outreachNoteText: {
    color: Colors.FoldPixel.GRAY300,
    fontSize: 12,
    marginLeft: 4,
  },
  outreachCommunicationModeViewIcon: {
    width: 12,
    height: 12,
    marginRight: 4,
    marginTop: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loaderView: {
    marginTop: 12,
  },
  loadMoreButtonText: {
    fontSize: 14,
    lineHeight: 21,
    fontWeight: '500',
    color: Colors.Custom.PurpleColor,
  },
  noDataFoundText: {
    fontSize: 14,
    lineHeight: 21,
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY300,
  },
  loadMoreButtonContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  },
  outreachActivityListView: {
    marginTop: 12,
    paddingLeft: 4,
  },
  outreachActivityItemView: {
    position: 'relative'
  }

});

export const reactStyles: Record<string, React.CSSProperties> = {
  skeletonText1: {width: 160, height: 16, marginTop: 12},
  skeletonText2: {width: 260, height: 14, marginTop: 8},
};
