import {gql} from '@apollo/client';

export const GET_PRESCRIBED_IMAGING = gql`
  query GetPrescribedImaging($ids: [uuid!]!, $employerUuid: uuid!, $accountUuid: uuid!) {
    prescribedImaging(
      where: {
        id: {
          _in: $ids
        }
      }
    ) {
      id
      name
      code
      description
      codeType
      prescribedImagingCptcodes {
        id
        key: id
        cptCodeData{
          code
          description
        }
        accountPrescribedImaging: accountImgingCPTPrescribedData(where: {
          employerUuid: {
            _eq: $employerUuid
          },
          accountUuid: {
            _eq: $accountUuid
          }
        }) {
          id
          prescribedImagingId
          accountUuid
          employerUuid
          prescribedImagingCost {
            id
            price
            priceData
            marketPriceData
            currentMarketPrice
          }
        }
      }
      accountPrescribedImaging(where: {
        employerUuid: {
          _eq: $employerUuid
        },
        accountUuid: {
          _eq: $accountUuid
        }
      }) {
        id
        prescribedImagingId
        accountUuid
        employerUuid
        prescribedImagingCost {
          id
          price
          priceData
          marketPriceData
          currentMarketPrice
        }
      }
    }
  }
`;

export const GET_PRESCRIBED_IMAGING_ACC = gql`
  query GetPrescribedImaging($ids: [uuid!]!, $accountUuid: uuid!) {
    prescribedImaging(
      where: {
        id: {
          _in: $ids
        }
      }
    ) {
      id
      name
      code
      description
      codeType
      prescribedImagingCptcodes {
        id
        key: id
        cptCodeData{
          code
          description
        }
        accountPrescribedImaging: accountImgingCPTPrescribedData(where: {
          accountUuid: {
            _eq: $accountUuid
          }
        }) {
          id
          prescribedImagingId
          accountUuid
          employerUuid
          prescribedImagingCost {
            id
            price
            priceData
            marketPriceData
            currentMarketPrice
          }
        }
      }
      accountPrescribedImaging(where: {
        accountUuid: {
          _eq: $accountUuid
        }
      }) {
        id
        prescribedImagingId
        accountUuid
        employerUuid
        prescribedImagingCost(
          order_by: {
            createdAt: desc_nulls_last
          },
          limit: 1
        ) {
          id
          price
          priceData
          marketPriceData
          currentMarketPrice
        }
      }
    }
  }
`;

export const UPDATE_PRESCRIBED_IMAGING_COST = gql`
  mutation UpdatePrescribedImagingCost(
    $price: float8,
    $priceData: jsonb,
    $marketPriceData: jsonb,
    $id:uuid!,
    $currentMarketPrice: float8
  ) {
    updatePrescribedImagingCost(
      pk_columns: {id: $id}
      _set: {
        price: $price,
        priceData: $priceData,
        marketPriceData: $marketPriceData,
        currentMarketPrice: $currentMarketPrice,
      }
    ) {
      id
      price
      priceData
      marketPriceData
    }
  }
`;

export const CREATE_PRESCRIBED_IMAGING_COST = gql`
  mutation CreatePrescribedImagingCost(
    $id: uuid,
    $accountUuid: uuid,
    $price: float8,
    $marketPriceData: jsonb,
    $priceData: jsonb,
    $currentMarketPrice: float8
  ) {
    createPrescribedImagingCost(
      object: {
        accountPrescribedImagingId: $id,
        accountUuid: $accountUuid,
        price: $price,
        priceData: $priceData,
        marketPriceData: $marketPriceData,
        currentMarketPrice: $currentMarketPrice,
      }
    ) {
      id
      accountPrescribedImagingId
      accountUuid
      price
      priceData
      marketPriceData
      currentMarketPrice
    }
  }
`;
