import {Table} from 'antd';
import {campaignDummyData} from '../../../../../dummyData/Campaigns/campaignDummyData';
import {getColumns} from './ColumnsHelper';
import {ICampaignDataProps, ICampaignRightSideTable} from '../interfaces';
import TopBar from './TopBar';
import {View} from 'native-base';
import React from 'react';
import {CAMPAIGN_CONSTANTS} from '../CampaignConstants';
import {NewCampaign} from '../NewCampaign/NewCampaign';

export const RightSideTable = (props: ICampaignRightSideTable) => {
  const getAction = (
    actionCode: string,
    recordData: ICampaignDataProps
  ): any => {
    // getAction
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    },
  };

  switch (props.currentScreen) {
    case CAMPAIGN_CONSTANTS.LANDING:
      return (
        <View>
          <TopBar handleScreenChange={props.handleScreenChange} />
          <Table
            rowClassName={(record, index) =>
              index % 2 == 0 ? 'table-row-light' : ''
            }
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            dataSource={campaignDummyData}
            columns={getColumns(getAction)}
            pagination={false}
            scroll={{x: 1300}}
          />
        </View>
      );

    case CAMPAIGN_CONSTANTS.AUTO_CAMPAIGN:

    case CAMPAIGN_CONSTANTS.MANUAL_CAMPAIGN:

    case CAMPAIGN_CONSTANTS.VISITOR_CAMPAIGN:
      return <NewCampaign handleScreenChange={props.handleScreenChange} />;

    default:
      return <></>;
  }
};
