import {IMembersGroupData} from '../../../MembersManagement/interfaces';

export interface IAssignJourneyListViewProps {
  fetchContactsBy: FetchContactsBy;
  visible: boolean;
  onClose: () => void;
  onAssign: (record: IMembersGroupData, unassignedContactsUuids: string[]) => void;
  ruleData?: IMembersGroupData;
  selectedContactIds?: string[];
  selectedJourney?: {id: string, title: string, careJourneyLocations?: any}
  locationAndRuleIdBasedContactIdsFilter?: boolean
}

export interface IAssignJourneyListViewComponentState {
  contactIdsList: string[];
  contactList: any[];
  loading: boolean;
  selectAllLoading: boolean;
  error: boolean;
  showAllMembers: boolean;
  patientUuidsOfAlreadyAssignedJourney?: Set<string>;
  unassignedJourneyContacts?: any[];
  unassignedContactsUuids?: string[]
}

export enum FetchContactsBy {
  CONTACT_IDS,
  GROUP_ID,
}
