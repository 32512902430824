import { Select,Spin } from 'antd';
import { debounce } from 'lodash';
import { Text } from 'native-base';
import { useEffect,useState } from 'react';
import { ISearchFieldProps } from '../../../Interfaces';
import { getCodesWithHeaders } from '../../../services/CommonService/AidBoxService';
import { CodeSearchType } from '../CodeSearch/CodeSearchConstants';

const MultipleLabAnalyteSearch = (props: ISearchFieldProps) => {
  const {value, isShowError, onChange} = props;
  const [loading, setLoading] = useState(false);
  const [analyteList, setAnalyteList] = useState<any[]>([]);
  const [isInitLoading, setIsInitLoading] = useState(false);

  

  useEffect(() => {
    if (value) {
      setIsInitLoading(true);
      const promiseList = value.map((code:string)=>{
        return getCodesWithHeaders(
          CodeSearchType.analyte,
          false,
          {},
          code || ''
        );
      })
      Promise.all(promiseList).then((results)=>{
        const analyteList: any = [];
        results.forEach((codes)=>{
          codes?.data?.expansion?.contains?.forEach((item: any) => {
            analyteList.push({
              title: item.display,
              value: item.code,
            });
          });

        });
        setAnalyteList(analyteList);
        setIsInitLoading(false);
      }).catch(()=>{
        setIsInitLoading(false);
      })
    }
  }, []);

  const onSearch = async (searchString: string) => {
    if (searchString.length > 0) {
      setLoading(true);

      const codes = await getCodesWithHeaders(
        CodeSearchType.analyte,
        false,
        {},
        searchString || ''
      );
      setLoading(false);
      const analyteList: any = [];
      codes?.data?.expansion?.contains?.forEach((item: any) => {
        analyteList.push({
          title: item.display,
          value: item.code,
        });
      });
      setAnalyteList(analyteList)
    }

  };

  return (
    <Select
      size="large"
      showSearch
      mode="multiple" // Enable multi-select mode
      allowClear
      maxTagCount={3}
      filterOption={false}
      value={value && !isInitLoading ? value : undefined} // Convert single value to an array for multi-select
      onSearch={debounce(onSearch, 500)}
      onChange={(selectedValues: any[]) => {
        onChange(selectedValues);
      }}
      placeholder="Search Lab Test"
      loading={loading || isInitLoading}
      notFoundContent={loading && <Spin size="small" />}
      style={{ width: '100%'}}
      className={
        isShowError && !value ? 'pami-search field-error' : 'pami-search'
      }
    >
      {analyteList.map((analyte, index) => {
        return (
          <Select.Option key={`${analyte.value}_${index}`} value={analyte.value}>
            <Text>
              {analyte.title}
            </Text>
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default MultipleLabAnalyteSearch;
