import { View } from 'native-base';
import {Dimensions} from 'react-native';
import TitleSubtitleView from '../../common/TitleSubtitleView/TitleSubtitleView';
import { MembersTable } from './MembersTable';

const MembersManagement = (props:any) => {
  const {isHideMainTitleBar} = props
  const screenHeight = Dimensions.get('window').height;
  return (
    <View>
       {!isHideMainTitleBar ? <TitleSubtitleView titleLabelId="employees" /> : null}
        <MembersTable
          activeTab={'employees'} title={'Employees'} callBackToParent={props.callBackToParent} />
    </View>
  );
};

export default MembersManagement;
