import Modal from 'antd/lib/modal/Modal';
import React, {FC, PropsWithChildren, ReactElement, ReactNode} from 'react';

interface EpisodeDetailsModalProps {
  isVisible: boolean;
  title: string | ReactNode | ReactElement;
  onClose: any;
  width?: number;
}

const EpisodeDetailsModal: FC<EpisodeDetailsModalProps> = ({
  title,
  isVisible,
  children,
  onClose,
  width,
}) => {
  return (
    <div className="opportunityCreateModal">
      <Modal title={title} visible={isVisible} onCancel={onClose} width={width}>
        {children}
      </Modal>
    </div>
  );
};

export default EpisodeDetailsModal;
