import React from 'react';

const SideCarCreateNewSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_2850_72867)">
        <path
          fill="#fff"
          d="M10 8.503a.5.5 0 100-1v1zm-4-1a.5.5 0 000 1v-1zm2.5-1.5a.5.5 0 00-1 0h1zm-1 4a.5.5 0 001 0h-1zm.5 4.166c-1.586 0-2.724 0-3.591-.117-.852-.115-1.366-.333-1.746-.713l-.707.707c.597.597 1.357.867 2.32.997.949.127 2.167.126 3.724.126v-1zM.833 8.003c0 1.557 0 2.775.127 3.724.13.963.4 1.723.996 2.32l.707-.708c-.38-.38-.598-.893-.712-1.745-.117-.867-.118-2.006-.118-3.591h-1zm13.334 0c0 1.585-.002 2.724-.118 3.59-.115.853-.332 1.367-.712 1.746l.707.707c.596-.596.867-1.356.996-2.32.128-.948.127-2.166.127-3.723h-1zM8 15.169c1.557 0 2.775.001 3.724-.126.963-.13 1.723-.4 2.32-.996l-.707-.708c-.38.38-.894.598-1.746.713-.867.116-2.006.117-3.591.117v1zM8 1.836c1.585 0 2.724.001 3.59.118.853.114 1.367.332 1.747.712l.707-.707c-.597-.597-1.357-.867-2.32-.997C10.775.835 9.557.836 8 .836v1zm7.167 6.167c0-1.558 0-2.776-.127-3.725-.13-.963-.4-1.723-.996-2.32l-.707.708c.38.38.597.893.712 1.746.117.866.118 2.005.118 3.59h1zM8 .836c-1.557 0-2.776-.001-3.724.126-.963.13-1.723.4-2.32.997l.707.707c.38-.38.894-.598 1.746-.712.867-.117 2.005-.118 3.59-.118v-1zM1.833 8.003c0-1.586.001-2.725.118-3.591.114-.853.332-1.366.712-1.746l-.707-.707c-.597.596-.867 1.356-.996 2.32-.128.948-.127 2.166-.127 3.724h1zm8.167-.5H8v1h2v-1zm-2 0H6v1h2v-1zm-.5-1.5v2h1v-2h-1zm0 2v2h1v-2h-1z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2850_72867">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default SideCarCreateNewSvg;
