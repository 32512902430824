import {gql} from '@apollo/client';
import BaseService from '../CommonService/BaseService';
import {contractBaseUrl} from './contractServiceConst';

const axios = BaseService.getSharedInstance().axios;

export const getQualityMeasures = (body: {
  searchString: string;
  pageSize: number;
  pageNo: number;
  contractId: string;
}) => {
  const {searchString, pageNo, pageSize, contractId} = body;
  const url = `${contractBaseUrl}/${contractId}/QualityMeasures?page_size=${pageSize}&page_number=${pageNo}&name=${searchString}`;
  return axios.get(url);
};

export const getTopPrograms = (body: {
  searchString: string;
  contractId: string;
}) => {
  const {searchString, contractId} = body;
  const url = `${contractBaseUrl}/${contractId}/Programs`;
  return axios.get(url);
};

export const getQualityMeasuresPatients = (body: {
  searchString: string;
  pageSize: number;
  pageNo: number;
  contractId: string;
  qualityMeasureId: string;
}) => {
  const {searchString, pageNo, pageSize, contractId, qualityMeasureId} = body;
  const url = `${contractBaseUrl}/${contractId}/QualityMeasures/${qualityMeasureId}/patient?page_size=${pageSize}&page_number=${pageNo}&name=${searchString}`;
  return axios.get(url);
};

export const QualityMeasuresQuery = gql`
  query QualityMeasuresQuery(
    $contractId: uuid!
    $name: String!
    $limit: Int!
    $offset: Int!
  ) {
    qmContractQualityMeasures(
      where: {
        contractId: {_eq: $contractId}
        qualityMeasure: {
          name: {_ilike: $name},
          isDeleted: {_eq: false}
        }
      },
      limit: $limit,
      offset: $offset
    ) {
      id
      goal
      qualityMeasure {
        name
        id
        improvementNotation
      }
      qualityMeasureId
      contractId
      contract {
        name
      }
    }
    qggregateQmContractQualityMeasures(
      where: {contractId: {_eq: $contractId}, qualityMeasure: {name: {_ilike: $name}, isDeleted: {_eq: false}}}
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getContactByUuids= gql`
query getContactByUuids($uuids: [uuid!]) {
  contacts(where: {uuid: {_in: $uuids}}) {
    uuid
    name
    person {
      gender {
        value
      },
      sexAtBirth{
        value
      }
      birthDate
    }
    contactType {
      contactType {
        value
        code
      }
    }
  }
}
`
export const qualityMeasureReport = gql`
  query qualityMeasureReport(
    $limit: Int = 10
    $offset: Int = 0
    $where: qmMeasureReport_bool_exp
  ) {
    qmMeasureReports(
      where: $where
      limit: $limit
      offset: $offset
      order_by: {updatedOn: desc}
    ) {
      id
      contactId
      contractId
      initialPopulationFlag
      denominatorExceptionFlag
      denominatorExclusionFlag
      denominatorFlag
      measureId
      measureScore
      isLatest
      disqualificationReason
      numeratorExclusionFlag
      numeratorFlag
      numeratorInclusionEvidenceDate
      numeratorInclusionEvidenceValue
      qualificationReason
      runTimestamp
      tenantId
      qmQualityMeasure {
        id
        name
        improvementNotation
      }
      qmContract {
        id
        name
        payer
      }
    }
    aggregateQmMeasureReport(
      where: $where
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const qualityMeasureReportById = gql`
  query qualityMeasureReport(
    $filterObjectTotal: qmMeasureReport_bool_exp
    $filterObjectMeasureNotMeet: qmMeasureReport_bool_exp
  ) {
    qmMeasureReports(
      where: $filterObjectTotal
      order_by: {updatedOn: desc}
    ) {
      id
      contactId
      contractId
      initialPopulationFlag
      denominatorExceptionFlag
      denominatorExclusionFlag
      denominatorFlag
      measureId
      measureScore
      isLatest
      disqualificationReason
      numeratorExclusionFlag
      numeratorFlag
      numeratorInclusionEvidenceDate
      numeratorInclusionEvidenceValue
      qualificationReason
      runTimestamp
      tenantId
      qmQualityMeasure {
        id
        name
        improvementNotation
      }
      qmContract {
        id
        name
        payer
      }
    }

    aggregateQmMeasureReportTotal: aggregateQmMeasureReport(
      where: $filterObjectTotal
    ) {
      aggregate {
        count
      }
    }

    aggregateQmMeasureReportMeasureNotMeet: aggregateQmMeasureReport(
      where: $filterObjectMeasureNotMeet
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const qualityMeasureReportCount = gql`
  query qualityMeasureReportCount(
    $where: qmMeasureReport_bool_exp
  ) {
    aggregateQmMeasureReport(
      where: $where
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getQualityMeasuresIdByName = gql`
  query QualityMeasuresIdByName($name: String!) {
    qmQualityMeasures(where: {name: {_eq: $name}, isDeleted: {_eq: false}}) {
      id
    }
  }
`;

export const getMesaureReportCriteraReasons = gql`
  query getMesaureReportCriteraReasons(
    $measureId: uuid!
    $contactId: uuid!
    $denominatorFlag: Boolean
    $numeratorFlag: Boolean
  ) {
    qmMeasureReports(
      where: {
        measureId: {_eq: $measureId}
        isLatest: {_eq: true}
        contactId: {_eq: $contactId}
        denominatorFlag: {_eq: $denominatorFlag}
        numeratorFlag: {_eq: $numeratorFlag}
      }
    ) {
      disqualificationReason
      qualificationReason
      evidence
      id
    }
  }
`;
export default {QualityMeasuresQuery,qualityMeasureReport,getContactByUuids,getQualityMeasuresIdByName,getMesaureReportCriteraReasons,qualityMeasureReportById};
