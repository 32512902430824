import BaseService from '../../../../../services/CommonService/BaseService';
import {
  ICommunicationType,
  ICommunicationTypeByMessageUuidData,
  ICommunicationTypesQueryParams,
} from './interfaces';

const baseService = BaseService.getSharedInstance().axios;
const communicationTypeBaseUrl = 'crm-nest/communication-types';

export const getCommunicationTypes = async (
  params: ICommunicationTypesQueryParams
) => {
  const url = communicationTypeBaseUrl;
  const response = await baseService.get(url, {params});
  return response;
};

export const getSingleCommunicationType = async (id: string) => {
  const url = `${communicationTypeBaseUrl}/${id}`;
  const response = await baseService.get<ICommunicationType>(url);
  return response;
};

export const createCommunicationType = async (
  body: Omit<ICommunicationType, 'id'>
) => {
  const url = `${communicationTypeBaseUrl}`;
  const response = await baseService.post(url, transformRequestBody(body));
  return response;
};

export const updateCommunicationType = async (body: ICommunicationType) => {
  const id = body.id;
  const url = `${communicationTypeBaseUrl}/${id}`;
  const response = await baseService.put(url, transformRequestBody(body));
  return response;
};

export const deleteCommunicationType = async (id: string) => {
  const url = `${communicationTypeBaseUrl}/${id}`;
  const response = await baseService.delete(url);
  return response;
};

export const getCommunicationTypesByMessageUuids = async (
  messageUuids: string[]
) => {
  const url = `${communicationTypeBaseUrl}/message-communication-type`;
  const response = await baseService.post<
    ICommunicationTypeByMessageUuidData[]
  >(url, {messageUuids});
  return response;
};

export const createMessageCommunicationType = async (data: {
  messageUuid: string;
  communicationTypeId: string;
}) => {
  const url = `${communicationTypeBaseUrl}/add-message-communication-type`;
  const response = await baseService.post(url, data);
  return response;
};

export const removeMessageCommunicationType = async (data: {
  messageUuid: string;
  communicationTypeId: string;
}) => {
  const url = `${communicationTypeBaseUrl}/remove-message-communication-type`;
  const response = await baseService.post(url, data);
  return response;
};

export const getCptCodesByCommunicationTypeId = async (id: string) => {
  const url = `${communicationTypeBaseUrl}/cptcodes-communication-type/${id}`;
  const response = await baseService.get(url);
  return response;
};

export const addCptCodesToCommunicationType = async (data: {
  cptCodeId: string;
  communicationTypeId: string;
}) => {
  const url = `${communicationTypeBaseUrl}/add-cptcodes-communication-type`;
  const response = await baseService.post(url, data);
  return response;
};

export const removeCptCodesFromCommunicationType = async (data: {
  cptCodeId: string;
  communicationTypeId: string;
}) => {
  const url = `${communicationTypeBaseUrl}/remove-cptcodes-communication-type`;
  const response = await baseService.post(url, data);
  return response;
};

export const validateCommunicationTypeBody = (data: {
  name: string;
  description: string;
  marketCost?: string;
  ourCost?: string;
}) => {
  const {name, description, marketCost, ourCost} = data;
  let valid = true;
  const errors = {
    name: '',
    description: '',
    marketCost: '',
    ourCost: '',
  };
  if (!name) {
    errors.name = 'Name is mandatory';
    valid = false;
  }
  if (marketCost && !isFloat(marketCost)) {
    errors.marketCost = 'Please enter valid value';
    valid = false;
  }
  if (ourCost && !isFloat(ourCost)) {
    errors.ourCost = 'Please enter valid value';
    valid = false;
  }
  return {
    error: errors,
    valid,
  };
};

export const getUserUuidMap = (accountUsers: any[]) => {
  const userUuidMap: any = {};
  accountUsers.forEach((item) => {
    const userUuid = item?.user?.uuid;
    userUuidMap[userUuid] = {
      ...item.user,
    };
  });
  return userUuidMap;
};

export const getFormattedCommunicationTypeData = (data: {
  communicationTypes: ICommunicationType[];
  accountUsers: any[];
}) => {
  const {communicationTypes, accountUsers} = data;
  if (accountUsers.length === 0) {
    return communicationTypes;
  }
  const userUuidMap = getUserUuidMap(accountUsers);
  const result = [...communicationTypes];
  result.forEach((item) => {
    const createdBy = userUuidMap[item.createdBy]?.name || '';
    const updatedBy = userUuidMap[item.updatedBy]?.name || '';
    item.createdBy = createdBy;
    item.updatedBy = updatedBy;
  });
  return result;
};

export const getAddedAndRemovedIds = (data: {
  currentIds: string[];
  selectedIds: string[];
}) => {
  const {currentIds, selectedIds} = data;
  const added: string[] = [];
  const removed: string[] = [];
  currentIds.forEach((item) => {
    if (!selectedIds.includes(item)) {
      removed.push(item);
    }
  });
  selectedIds.forEach((item) => {
    if (!currentIds.includes(item)) {
      added.push(item);
    }
  });
  return {
    added,
    removed,
  };
};

export const addOrRemoveCptCodesIdsFromCommunicationType = async (data: {
  communicationTypeId: string;
  currentCptCodeIds: string[];
  selectedCptCodeIds: string[];
}) => {
  const {communicationTypeId, currentCptCodeIds, selectedCptCodeIds} = data;
  const {added, removed} = getAddedAndRemovedIds({
    currentIds: currentCptCodeIds,
    selectedIds: selectedCptCodeIds,
  });
  if (added.length === 0 && removed.length === 0) {
    return;
  }
  const addedPromiseList = added.map((item) =>
    addCptCodesToCommunicationType({communicationTypeId, cptCodeId: item})
  );
  const removedPromiseList = removed.map((item) =>
    removeCptCodesFromCommunicationType({communicationTypeId, cptCodeId: item})
  );
  const allPromises = [...addedPromiseList, removedPromiseList];
  const response = await Promise.all(allPromises);
  return response;
};

export const addOrRemoveMessageCommunicationTypes = async (data: {
  messageUuid: string;
  currentIds: string[];
  selectedIds: string[];
}) => {
  const {messageUuid, currentIds, selectedIds} = data;
  const {added, removed} = getAddedAndRemovedIds({
    currentIds,
    selectedIds,
  });
  const addedPromiseList = added.map((item) =>
    createMessageCommunicationType({messageUuid, communicationTypeId: item})
  );
  const removedPromiseList = removed.map((item) =>
    removeMessageCommunicationType({messageUuid, communicationTypeId: item})
  );
  const allPromises = [...addedPromiseList, ...removedPromiseList];
  const response = await Promise.all(allPromises);
  return response;
};

export const formatDate = (timestamp: string): string => {
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  return formattedDate;
};

export const createMessageCommunicationTypeInBulk = async (data: {
  messageUuids: string[];
  communicationTypeId: string;
  contactUuid: string;
}) => {
  const url = `${communicationTypeBaseUrl}/message-communication-type/multiple`;
  const response = await baseService.post(url, data);
  return response;
};

export const isFloat = (value: string) => {
  const result = /^\d*(?:\.\d+)?$/.test(value);
  return result;
};

export const transformRequestBody = (body: ICommunicationType | any) => {
  const marketCost = parseFloat(body.marketCost);
  const ourCost = parseFloat(body.ourCost);
  return {
    ...body,
    marketCost,
    ourCost,
  };
};

export const updateExistingContactSavings = async (body: {
  communicationTypeId: string;
  marketCost: string;
  ourCost: string;
}) => {
  const url = `${communicationTypeBaseUrl}/update-savings-cost`;
  const response = await baseService.post(url, transformRequestBody(body));
  return response;
};

export const createCptCode = async (body: {
  code: string;
  description: string;
}) => {
  const url = `${communicationTypeBaseUrl}/cpt-code/add`;
  const response = await baseService.post(url, body);
  return response;
};

export const validateCptCode = (code: string) => {
  if (!code) {
    return false;
  }
  const regex = /^[A-Z0-9]+$/;
  return regex.test(code);
};

export const getCommunicationTypeSavingsDataByTypeId = async (body: {
  communicationTypeId?: string;
  contactUuidList: string[];
  fromDate: string;
  toDate: string;
}) => {
  const url = `${communicationTypeBaseUrl}/savings`;
  const response = await baseService.post(url, body);
  return response;
};

export const getDateRangeFromFilterData = (data: any) => {
  const filterObj = data.filterData.find(
    (item: any) => item.filterCode === 'DATE_RANGE'
  );
  return {
    fromDate: filterObj?.filterValue?.fromDate,
    toDate: filterObj?.filterValue?.toDate,
  };
};
