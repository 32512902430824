import React, {useContext, useEffect, useState} from 'react';
import {Pressable, View} from 'react-native';
import {Text} from 'native-base';
import {MLOV_CATEGORY} from '../../../constants/MlovConst';
import {
  getMlovCodeFromId,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {usePersonOmniViewContext} from '../../PersonOmniView/PersonOmniView.context';
import CPStatusBadge from '../ContactCareProgram/ContactCareProgramView/components/CPStatusBadge';
import Stack from '../../common/LayoutComponents/Stack';
import {IContactCareProgramCard} from './interface';
import {Checkbox, Divider, Progress, Tooltip} from 'antd';
import {useIntl} from 'react-intl';
import {
  calculateProgressPercentage,
  getCardMainTitle,
  getLastUpdateText,
  getSubTitleText,
} from './CareManagementUtils';
import {antStyles, styles} from './CareManageMentViewStyles';
import {Colors} from '../../../styles';
import CareProgramMoreActionView from './CareProgramMoreActionView';
import useCareProgramStepStatus from '../ContactCareProgram/ContactCareProgramView/hooks/useCareProgramStepStatus';
import {useCareProgramStatus} from '../ContactCareProgram/ContactCareProgramView/hooks/useCareProgramStatus';
import {useContactCareProgramCardAdditionalData} from './CareManagementViewHooks/useContactCareProgramCardAdditionalData';
import {DATE_FORMATS} from '../../../constants/StringConst';
import moment from 'moment';
import AppointmentDetail from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentDetail';

const ContactCareProgramCard = (props: IContactCareProgramCard) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {code: activeTabCode} = useParams();
  const commonContextData = useContext(CommonDataContext);
  const intl = useIntl();
  const [state, setState] = useState({
    isLoading: false,
    assigneeUserData: {} as any,
    careProgramActivityLogs: {} as any,
    showAppointmentDetailDrawer: false,
  });

  const careProgramTypesList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_TYPES
    ) || [];

  const {careProgramStepStatus} = useCareProgramStepStatus();
  const {careProgramStatus, careProgramStatusList} = useCareProgramStatus();
  const {getContactCareProgramAdditionalData} =
    useContactCareProgramCardAdditionalData();

  const progressPercentage = calculateProgressPercentage(
    careProgramStepStatus,
    props?.contactCareProgram?.stepsLog
  );

  const {data: contactData} = usePersonOmniViewContext();

  const careProgramTypeCode = getMlovCodeFromId(
    careProgramTypesList,
    props?.contactCareProgram?.payerCareProgram?.careProgramTypeId
  );
  const careProgramStatusCode = getMlovCodeFromId(
    careProgramStatusList,
    props?.contactCareProgram?.statusId
  );

  const checkCompletedCareProgram = [
    careProgramStatus?.completed,
    careProgramStatus.autoClosed,
    careProgramStatus.closed,
  ].includes(props?.contactCareProgram?.statusId);

  const handeSelectCareProgram = (id: string) => {
    if (activeTabCode) {
      navigate(`${pathname}/${id}`);
    } else {
      navigate(`${pathname}/all/${id}`);
    }
  };

  const handleAppointmentDetailDrawerClose = (visible: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        showAppointmentDetailDrawer: visible,
      };
    });
  };

  const getInitData = async () => {
    try {
      setState((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
      const response = await getContactCareProgramAdditionalData(
        props?.contactCareProgram?.assigneeId,
        props?.contactCareProgram?.id
      );
      setState((prev) => {
        return {
          ...prev,
          isLoading: false,
          assigneeUserData: response?.user,
          careProgramActivityLogs: response?.careProgramActivityLogs,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }
  };

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <View style={styles.card}>
      <View style={{flexDirection: 'column', flex: 1}}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <View style={{flex: 1, paddingRight: 16}}>
            <Stack direction="row">
              {[
                careProgramStatus?.inProgress,
                careProgramStatus?.newStatus,
              ]?.includes(props?.contactCareProgram?.statusId) &&
                props?.enableBulkSelection && (
                  <Checkbox
                    checked={props?.checked}
                    style={{paddingRight: 8, borderRadius: 4}}
                    onChange={(value) => {
                      props?.onCheckBoxChange(
                        value?.target?.checked,
                        props?.contactCareProgram?.id
                      );
                    }}
                  />
                )}
              <Progress
                type="circle"
                percent={progressPercentage}
                width={16}
                style={{
                  marginRight: 4,
                }}
                showInfo={false}
                strokeWidth={10}
                strokeColor={Colors.Custom.SuccessColor}
              />
              <Text style={styles.cardTitle} numberOfLines={1}>
                {getCardMainTitle(careProgramTypeCode)}
              </Text>
              <CPStatusBadge statusCode={careProgramStatusCode} />
            </Stack>
            <Tooltip
              title={getSubTitleText(
                props?.chronicConditionsData,
                careProgramTypeCode,
                state?.assigneeUserData,
                props?.contactCareProgram,
                state?.assigneeUserData
              )}
              placement="bottom"
            >
              <Text style={styles.cardSubtitle} numberOfLines={1}>
                {props?.patientAdditionalDataLoading || state?.isLoading
                  ? intl.formatMessage({id: 'loadingText'})
                  : getSubTitleText(
                      props?.chronicConditionsData,
                      careProgramTypeCode,
                      props?.pcpUserData,
                      props?.contactCareProgram,
                      state?.assigneeUserData
                    )}
              </Text>
            </Tooltip>
          </View>
          <Stack direction="row">
            <Pressable
              style={({pressed}) =>
                pressed ? styles.buttonHover : styles.button
              }
              onPress={() =>
                handeSelectCareProgram(props?.contactCareProgram?.id)
              }
            >
              <Text style={styles.buttonText}>
                {checkCompletedCareProgram
                  ? `View ${careProgramTypeCode}`
                  : `Review ${careProgramTypeCode}`}
              </Text>
            </Pressable>
            <Divider
              type="vertical"
              orientationMargin={8}
              style={antStyles.dividerStyle}
            />
            <View style={styles.menuHorizontalIcon}>
              <CareProgramMoreActionView
                contactCareProgramId={props?.contactCareProgram?.id}
                contactId={contactData?.uuid}
                careProgramStatusCode={careProgramStatusCode}
                onCareProgramAssigneeChange={(value) => {
                  setState((prev) => {
                    return {
                      ...prev,
                      assigneeUserData: value,
                    };
                  });
                }}
                onActionCloseCareProgram={() => {
                  props?.onActionCloseCareProgram();
                }}
              />
            </View>
          </Stack>
        </View>

        {careProgramStatus?.inProgress ===
          props?.contactCareProgram?.statusId &&
          (state?.careProgramActivityLogs?.timestamp ||
            props?.pcpFutureAppointmentData?.id) && (
            <View style={{flexDirection: 'column', flex: 1}}>
              <Divider
                type="horizontal"
                orientationMargin={0}
                style={antStyles.horizontalDivider}
              />
              <Stack direction="row">
                {state?.careProgramActivityLogs && (
                  <Text style={styles.cardSubtitle}>
                    {getLastUpdateText(
                      state?.careProgramActivityLogs,
                      props?.pcpFutureAppointmentData
                    )}
                  </Text>
                )}
                {props?.pcpFutureAppointmentData?.startDateTime && (
                  <Pressable
                    onPress={() =>
                      handleAppointmentDetailDrawerClose(
                        !state?.showAppointmentDetailDrawer
                      )
                    }
                  >
                    <Text style={styles.cardSubtitle}>
                      {`Next PCP Appointment: ${moment(
                        props?.pcpFutureAppointmentData?.startDateTime
                      ).format(DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)}`}
                    </Text>
                  </Pressable>
                )}
              </Stack>
            </View>
          )}
      </View>
      {state?.showAppointmentDetailDrawer && (
        <AppointmentDetail
          event={{detail: props?.pcpFutureAppointmentData}}
          isVisible={true}
          onClose={() => handleAppointmentDetailDrawerClose(false)}
        />
      )}
    </View>
  );
};

export default React.memo(ContactCareProgramCard);
