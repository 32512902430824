import Icon from 'react-native-vector-icons/AntDesign';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import PlusIcon from '../../../../common/Svg/PlusSvg';
import {ITableTopBarButtonProps} from '../../../../common/TableTopBar';

export const addResponseButton = (
  onAddResponse: () => void
): ITableTopBarButtonProps[] => {
  return [
    {
      btnText: 'addResponse',
      size: 'sm',
      colorScheme: 'primary',
      backgroundColor: Colors.secondary['100'],
      borderColor: Colors.secondary['200'],
      textColor: Colors.secondary['800'],
      variant: BUTTON_TYPE.PRIMARY,
      leftIcon: <PlusIcon />,
      btnClick: onAddResponse,
    },
  ];
};
