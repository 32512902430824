import {HStack, Text, VStack} from 'native-base';
import {ICampaignTableType} from '../../interfaces';
import Icon from 'react-native-vector-icons/AntDesign';
import {styles} from './TableComponentsStyles';

export const CampaignTableType = (props: ICampaignTableType) => {
  return (
    <VStack>
      <HStack style={styles.centerAlign} space={1}>
        <Icon name="message1" />
        <Text>{props.type.channel}</Text>
      </HStack>
      <HStack style={styles.centerAlign} space={1}>
        <Icon name="filetext1" color={'gray'} />
        <Text style={styles.tinyDetail}>{props.type.kind}</Text>
      </HStack>
    </VStack>
  );
};
