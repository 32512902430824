import { HStack, Spacer, Text, View, VStack } from 'native-base';
import { Colors } from '../../../../styles';
import { IAvailabilitiesByDateRangeKey, IUserAvailability } from './PracticeAvailabilityInterfaces';
import Feather from 'react-native-vector-icons/Feather';
import { convertDate, getFormattedDate } from '../../../../utils/DateUtils';
import { DATE_FORMATS } from '../../../../constants';

interface IProps{
  availabilitiesByDateRangeKey: IAvailabilitiesByDateRangeKey;

  onEditAvailabilities: any;
  onDeleteAvailabilities: any;
}

export function PracticeAvailabilityOverrideCard(props: IProps) {
  if (!props.availabilitiesByDateRangeKey?.isAvailabilitiesPresent) {
    return <></>;
  }

  const primaryBorderColor = Colors.secondary[200];
  const secondaryBorderColor = Colors.secondary[100];
  const backgroundColor = Colors.secondary[50];
  const primaryTextColor = Colors.secondary[800];

  return (
    <VStack
      borderWidth={1}
      borderColor={primaryBorderColor}
      backgroundColor={backgroundColor}
      m={2}
      borderRadius={8}
      space={1}
    >
      <HStack
        borderBottomWidth={1}
        borderColor={primaryBorderColor}
        height={'45px'}
        padding={2}
        alignItems={'center'}
      >
        <View flex={7}>
          <Text fontWeight={'bold'} fontSize={'xs'}>
            {'Schedule Overrides'}
          </Text>
        </View>

        <Spacer />

        <HStack flex={2}>
          <Feather
            onPress={props.onEditAvailabilities}
            name="edit"
            color={primaryTextColor}
            size={15}
            style={{marginHorizontal: 4}}
          />
          <Feather
            onPress={props.onDeleteAvailabilities}
            name="trash-2"
            size={14}
            color={primaryTextColor}
          />
        </HStack>
      </HStack>

      {
        props.availabilitiesByDateRangeKey?.isAvailabilitiesPresent &&
        props.availabilitiesByDateRangeKey.data?.map((data) => {
          const beginDateText = getFormattedDate(data.beginDate, DATE_FORMATS.SLOT_DATE_FORMAT);
          const endDateText = getFormattedDate(data.endDate, DATE_FORMATS.SLOT_DATE_FORMAT);

          return (
            <VStack
              padding={2}
              borderBottomWidth={1}
              borderColor={Colors.secondary[200]}
              space={1}
            >
              <View>
                <Text fontWeight={'bold'} fontSize={'xs'} color={primaryTextColor}>
                  {`${beginDateText} - ${endDateText}`}
                </Text>
              </View>
              {
                data.availabilities.map((availability) => {
                  const startTimeText = convertDate(availability.startTime || '', DATE_FORMATS.API_TIME_FORMAT, DATE_FORMATS.DISPLAY_TIME_FORMAT);
                  const endTimeText = convertDate(availability.endTime || '', DATE_FORMATS.API_TIME_FORMAT, DATE_FORMATS.DISPLAY_TIME_FORMAT);

                  return (
                    <HStack
                      borderBottomWidth={1}
                      borderColor={secondaryBorderColor}
                    >
                      <Text fontSize={'xs'} flex={7} color={primaryTextColor}>
                        {`${startTimeText} - ${endTimeText}`}
                      </Text>

                      <Spacer/>

                      <Text fontSize={'xs'} color={primaryTextColor}>
                        {availability.isVirtualLocation ? 'Virtual' : availability?.locationData?.practiceLocation.name}
                      </Text>
                    </HStack>
                  );
                })
              }
            </VStack>
          );
        })
      }

    </VStack>
  );
}
