import React, {useContext, useMemo} from 'react'
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {MLOV_CATEGORY} from '../../../../../constants';
import {getMlovListFromCategory} from '../../../../../utils/mlovUtils';
import {CARE_PROGRAM_STEP_STATUS_CODES} from '../../../../../constants/MlovConst';

type Statuses = keyof typeof CARE_PROGRAM_STEP_STATUS_CODES;
type StatusValues = typeof CARE_PROGRAM_STEP_STATUS_CODES[Statuses];

const useCareProgramStepStatus = () => {
  const contextData = useContext(CommonDataContext);

  const careProgramStepStatusList = getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_STEP_STATUS
  );

  const careProgramStepStatus = useMemo(() => {
    const obj: {
      [key in StatusValues]: string;
    } = {} as {
      [key in StatusValues]: string;
    };
    careProgramStepStatusList.forEach((item) => {
      obj[item.code as StatusValues] = item.id;
    });
    return obj;
  }, [careProgramStepStatusList]);

  return {
    careProgramStepStatusList,
    careProgramStepStatus,
  };
}

export default useCareProgramStepStatus