

export const RELEVANT_DATA_CONSTANTS = {
    VITAL: 'vital',
    ASSESSMENT: 'assessment',
    ACTIVITY: 'activity',
    LAB_RESULT: 'labResult'
}

export const RESOURCE_CONFIGURATION_TYPES = {
  HOME_MONITORING: 'HOME_MONITORING',
  RELEVANT_CLINICAL_RESOURCES: 'RELEVANT_CLINICAL_RESOURCES'
};

export const RESOURCE_TYPES = {
  OBSERVATION: 'Observation',
};