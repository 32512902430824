import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: '400',
  },
  statusContainer: {
    padding: '0px, 16px, 0px, 16px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1.5,
  },
});
