import { Path, Svg } from 'react-native-svg';
import {Colors} from '../../../styles/Colors';
import {ICommonSvgProps} from './interfaces';

const UnitySvg = (props: {iconKey : any}) => {

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8554 2.13926L12.5248 0.666687L13.1941 2.13926L14.6667 2.80861L13.1941 3.47797L12.5248 4.95054L11.8554 3.47797L10.3829 2.80861L11.8554 2.13926Z" fill="url(#paint0_linear_871_18287)"/>
    <path d="M13.1226 10.4102C13.5505 9.98225 13.7909 9.40185 13.7909 8.79668C13.7909 8.1915 13.5505 7.61111 13.1226 7.18318C12.6947 6.75525 12.1143 6.51485 11.5091 6.51485H10.4607C10.3789 6.51485 10.3005 6.54734 10.2427 6.60516C10.1848 6.66299 10.1523 6.74142 10.1523 6.8232C10.1523 6.90498 10.1848 6.98342 10.2427 7.04124C10.3005 7.09907 10.3789 7.13156 10.4607 7.13156H11.5091C11.9507 7.13156 12.3742 7.30699 12.6865 7.61926C12.9988 7.93153 13.1742 8.35506 13.1742 8.79668C13.1742 9.23829 12.9988 9.66182 12.6865 9.97409C12.3742 10.2864 11.9507 10.4618 11.5091 10.4618H7.56215C7.11085 10.4618 6.66968 10.5956 6.29444 10.8464C5.91919 11.0971 5.62672 11.4535 5.45402 11.8704C5.28131 12.2874 5.23612 12.7462 5.32417 13.1888C5.41221 13.6314 5.62953 14.038 5.94865 14.3571C6.26777 14.6762 6.67436 14.8936 7.11699 14.9816C7.55962 15.0697 8.01842 15.0245 8.43537 14.8518C8.85232 14.6791 9.20869 14.3866 9.45942 14.0113C9.71015 13.6361 9.84398 13.1949 9.84398 12.7436V11.6952C9.84398 11.6134 9.81149 11.535 9.75366 11.4772C9.69584 11.4193 9.61741 11.3869 9.53562 11.3869C9.45384 11.3869 9.37541 11.4193 9.31758 11.4772C9.25976 11.535 9.22727 11.6134 9.22727 11.6952V12.7436C9.22727 13.073 9.12961 13.3949 8.94665 13.6687C8.76368 13.9425 8.50362 14.156 8.19936 14.282C7.8951 14.408 7.5603 14.441 7.2373 14.3767C6.9143 14.3125 6.61761 14.1539 6.38473 13.921C6.15186 13.6882 5.99328 13.3915 5.92903 13.0685C5.86478 12.7455 5.89775 12.4107 6.02378 12.1064C6.14981 11.8022 6.36323 11.5421 6.63706 11.3591C6.91089 11.1762 7.23282 11.0785 7.56215 11.0785H11.5091C12.1143 11.0785 12.6947 10.8381 13.1226 10.4102Z" fill={`url(#${props.iconKey + '1'})`}/>
    <path d="M5.80672 7.53023C5.86454 7.58806 5.89703 7.66649 5.89703 7.74827V8.79668C5.89703 9.24798 5.76321 9.68915 5.51248 10.0644C5.26174 10.4396 4.90537 10.7321 4.48842 10.9048C4.07147 11.0775 3.61267 11.1227 3.17004 11.0347C2.72741 10.9466 2.32083 10.7293 2.00171 10.4102C1.68259 10.0911 1.46526 9.68447 1.37722 9.24184C1.28917 8.79921 1.33436 8.34041 1.50707 7.92346C1.67978 7.50651 1.97224 7.15014 2.34749 6.89941C2.72273 6.64867 3.1639 6.51485 3.6152 6.51485H8.61056C8.69234 6.51485 8.77077 6.54734 8.8286 6.60516C8.88643 6.66299 8.91891 6.74142 8.91891 6.8232C8.91734 6.90449 8.88434 6.98201 8.82685 7.0395C8.76936 7.09699 8.69184 7.12998 8.61056 7.13156H3.6152C3.28587 7.13156 2.96394 7.22922 2.69011 7.41218C2.41629 7.59515 2.20286 7.8552 2.07684 8.15946C1.95081 8.46372 1.91783 8.79852 1.98208 9.12153C2.04633 9.44453 2.20492 9.74122 2.43779 9.97409C2.67066 10.207 2.96735 10.3656 3.29036 10.4298C3.61336 10.494 3.94816 10.4611 4.25242 10.335C4.55668 10.209 4.81673 9.99559 4.9997 9.72177C5.18266 9.44794 5.28032 9.12601 5.28032 8.79668V7.74827C5.28032 7.66649 5.31281 7.58806 5.37064 7.53023C5.42847 7.4724 5.5069 7.43991 5.58868 7.43991C5.67046 7.43991 5.74889 7.4724 5.80672 7.53023Z" fill={`url(#${props.iconKey + '2'})`}/>
    <path d="M5.80497 6.11443C5.74748 6.17192 5.66996 6.20491 5.58868 6.20649C5.5069 6.20649 5.42847 6.17401 5.37064 6.11618C5.31281 6.05835 5.28032 5.97992 5.28032 5.89814V4.84973C5.28032 4.55008 5.33934 4.25336 5.45402 3.97651C5.56869 3.69967 5.73677 3.44812 5.94865 3.23623C6.16054 3.02434 6.41209 2.85627 6.68893 2.74159C6.96578 2.62692 7.2625 2.5679 7.56215 2.5679C7.8618 2.5679 8.15853 2.62692 8.43537 2.74159C8.71221 2.85627 8.96376 3.02434 9.17565 3.23623C9.38754 3.44812 9.55561 3.69967 9.67029 3.97651C9.78496 4.25336 9.84398 4.55008 9.84398 4.84973V9.84508C9.8424 9.92637 9.80941 10.0039 9.75192 10.0614C9.69443 10.1189 9.61691 10.1519 9.53562 10.1534C9.45384 10.1534 9.37541 10.121 9.31758 10.0631C9.25976 10.0053 9.22727 9.92687 9.22727 9.84508V4.84973C9.22727 4.40811 9.05184 3.98458 8.73957 3.67231C8.4273 3.36004 8.00377 3.18461 7.56215 3.18461C7.12053 3.18461 6.697 3.36004 6.38473 3.67231C6.07246 3.98458 5.89703 4.40811 5.89703 4.84973V5.89814C5.89545 5.97942 5.86246 6.05694 5.80497 6.11443Z"  fill={`url(#${props.iconKey + '3'})`}/>
    <defs>
    <linearGradient id={props.iconKey + '1'} x1="8.00004" y1="-10.6049" x2="-8.53907" y2="-0.908573" gradientUnits="userSpaceOnUse">
    <stop stop-color="#1E9DAE"/>
    <stop offset="1" stop-color="#D478FF"/>
    </linearGradient>
    <linearGradient id={props.iconKey + '2'} x1="8.00004" y1="-10.6049" x2="-8.53907" y2="-0.908573" gradientUnits="userSpaceOnUse">
    <stop stop-color="#1E9DAE"/>
    <stop offset="1" stop-color="#D478FF"/>
    </linearGradient>
    <linearGradient id={props.iconKey + '3'} x1="8.00004" y1="-10.6049" x2="-8.53907" y2="-0.908573" gradientUnits="userSpaceOnUse">
    <stop stop-color="#1E9DAE"/>
    <stop offset="1" stop-color="#D478FF"/>
    </linearGradient>
    </defs>
    </svg>
  );
};

export default UnitySvg;
