import {View} from 'native-base';
import React, { useState } from 'react';
import {FlowType} from '../../../../../context/WorkflowContext';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import {IProductDetailForm} from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/interfaces';
import {ILibNode} from '../../../Workflow/Workflow/AddOrUpdateWorkflow/IWorkflow';
import {WorkflowRender} from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowRenderView';

const JourneyDesign = (props: {
  journeyDetails: IProductDetailForm;
  nodes: any[];
  edges: any[];
  setWorkflowContext: any;
  libNodeIdList: ILibNode[];
  isAssignWorkflow: boolean;
  onNodeDataChange?: (nodeId: string, config: any) => void;
  onNodesChange: (nodes: any[]) => void;
  onEdgesChange: (edges: any[]) => void;
}) => {
  const [nodes, setNodes] = useState(props.nodes);
  const [edges, setEdges] = useState(props.edges)
  const [nodeTimerId, setNodeTimerId] = useState<any>();
  const [edgeTimerId, setEdgeTimerId] = useState<any>()
  return (
    <View>
      <PageBodyContainer>
        <View height="86vh" paddingY={4}>
          <WorkflowRender
            setWorkflowContext={props.setWorkflowContext}
            onNodeDataChange={props.onNodeDataChange}
            flowType={FlowType.careJourney}
            libNodeIdList={props.libNodeIdList}
            nodes={nodes}
            edges={edges}
            setNodes={(nodes1: any[]) => {
              setNodes(nodes1);
              if(nodeTimerId){
                clearTimeout(nodeTimerId)
                setNodeTimerId(undefined)
              }
              const newTimerId = setTimeout(()=>{
                if(nodeTimerId){
                  props.onNodesChange(nodes);
                }
                setNodeTimerId(undefined)
              }, 500);
              setNodeTimerId(newTimerId)
              
            }}
            setEdges={(edges1: any[]) => {
              setEdges(edges1);
              if(edgeTimerId){
                clearTimeout(edgeTimerId)
                setEdgeTimerId(undefined)
              }
              const newTimerId = setTimeout(()=>{
                props.onEdgesChange(edges);
                setNodeTimerId(undefined)
              }, 500);
              setEdgeTimerId(newTimerId)
              
            }}
          />
        </View>
      </PageBodyContainer>
    </View>
  );
};

export default JourneyDesign;
