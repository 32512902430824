import {HStack, Icon, Pressable, ScrollView, Text, View} from 'native-base';
import {Select as MultiSelect, Tooltip} from 'antd';
import {Colors} from '../../../styles';
import AntIcon from 'react-native-vector-icons/AntDesign';
import './ResponsiveTagRender.scss';

interface IProps {
  filterTags?: IFilterTag[];
  canRemoveTag?: boolean;
  onClick?: () => void;
  onRemoveFilter: (code: string) => void;
}

export interface IFilterTag {
  code: string;
  displayValue: string;
  color?: string;
}

export function ResponsiveTagRender(props: IProps) {
  return (
    <View width={'full'}>
      <MultiSelect
        className={'responsive-tag-render'}
        mode="multiple"

        size="large"
        disabled={true}
        allowClear={false}
        value={props.filterTags?.map(filterTag => filterTag.code) || []}
        maxTagCount={'responsive'}
        onClick={props.onClick}
      >
        {props.filterTags?.map?.((filterTag) => {
          return (
            <MultiSelect.Option
              key={filterTag?.code}
              value={filterTag?.code}
            >
              <View key={filterTag.code}>
                <HStack space={1} justifyContent="space-between">
                  <Text color={Colors.Custom.Gray700}>
                    {filterTag.displayValue}
                  </Text>
                  {
                    props?.canRemoveTag !== false && (
                      <Tooltip title={'Clear'}>
                        <Pressable
                          onPress={() => {
                            props.onRemoveFilter?.(filterTag.code);
                          }}
                          style={{marginTop: 4}}
                        >
                          <Icon
                            name="close"
                            as={AntIcon}
                            size="3"
                            width={6}
                            height={6}
                          />
                        </Pressable>
                      </Tooltip>
                    )
                  }

                </HStack>
              </View>
            </MultiSelect.Option>
          );
        })}
      </MultiSelect>
    </View>
  );
}
