import {Drawer} from 'antd';
import {Button, FormControl, Spinner, Text, View} from 'native-base';
import React, {useState} from 'react';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import CommerceCommonService from '../../../../../../../services/CommonService/CommerceCommonService';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../../../common/ModalActionBtn/ModalActionBtn';
import {styles} from '../AddNewTaxonView/AddNewTaxonStyles';

const DeleteTaxons = (props: any) => {
  const [showModal, setShowModal] = useState(true);
  const closeModal = () => {
    setShowModal(false);
    props.onActionPerformed(COMMON_ACTION_CODES.RESET);
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    name: '',
  });

  const onDeleteAction = () => {
    setLoading(true);
    const commerceCommonService =
      CommerceCommonService.getCommerceCommonServiceInstance();
    const axiosService = commerceCommonService.axiosService;
    axiosService
      .delete(`/commerce/api/v2/platform/taxons/${props.taxon.id}`)
      .then(() => {
        props.onActionPerformed(COMMON_ACTION_CODES.ALL);
        setShowModal(false);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError({...error, name: 'Error in Deleting Taxonomy'});
      });
  };
  const {width} = Dimensions.get('window');
  const finalWidth = width / 3;
  return (
    <>
      <Drawer
        visible={showModal}
        width={finalWidth}
        onClose={() => closeModal()}
        title={
          <DisplayText textType={'Heading'} textLocalId={'confirmDeletion'} />
        }
      >
        {loading ? <Spinner size="lg" style={styles.spinnerStyle} /> : <></>}
        <View>
          <View
            justifyContent={'flex-start'}
            alignItems={'center'}
            flex={1}
            flexDirection={'row'}
          >
            <Text fontSize={16}>
              <DisplayText textLocalId="taxonomyConfirmation" />
            </Text>
          </View>
          <FormControl isInvalid={!!error.name}>
            {error.name && (
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {error.name}
              </FormControl.ErrorMessage>
            )}
          </FormControl>
        </View>
        <Button.Group
          space={2}
          justifyContent={'flex-end'}
          width={'100%'}
          style={{marginTop: 20}}
        >
          <ModalActionBtn
            btnText="cancel"
            btnStype={BUTTON_TYPE.SECONDARY}
            onClickAction={() => {
              closeModal();
            }}
            customStyle={{marginRight: 20}}
          />
          <ModalActionBtn
            btnText="delete"
            btnStype={BUTTON_TYPE.PRIMARY}
            onClickAction={() => {
              onDeleteAction();
            }}
          />
        </Button.Group>
      </Drawer>
    </>
  );
};

export default DeleteTaxons;
