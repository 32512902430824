import React from "react";

function CarePlanStatusTodo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#fff"
        d="M.333 8.5a7.667 7.667 0 1115.334 0 7.667 7.667 0 01-15.334 0z"
      ></path>
      <path
        stroke="#8A94A8"
        strokeWidth="0.667"
        d="M.333 8.5a7.667 7.667 0 1115.334 0 7.667 7.667 0 01-15.334 0z"
      ></path>
    </svg>
  );
}

export default React.memo(CarePlanStatusTodo);