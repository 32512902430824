import { useLazyQuery, useMutation } from '@apollo/client';
import { DatePicker, Drawer, Select, Checkbox as AntdCheckbox, Spin } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import { Checkbox, Radio, Text, useMediaQuery, View, VStack } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH
} from '../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { LABEL_TYPE_CODES, MLOV_CATEGORY, TASK_PRIORITY_CODES, TASK_STATUS, TASK_STATUS_CODES } from '../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { IMlov } from '../../../../Interfaces';
import { LeadQueries, TaskQueries } from '../../../../services';
import { CreateOrUpdateUserPreference } from '../../../../services/UserPreferences/UserPreferencesQueries';
import { Colors } from '../../../../styles';
import {
  getAccountId,
  getUserEmployerId,
  getUserUUID,
  isEmployerRole
} from '../../../../utils/commonUtils';
import { getDateStrFromFormat, getMomentObj } from '../../../../utils/DateUtils';
import { getMlovId, getCareStudioMlovListByCategoryCode, getMlovObjectFromCode, getMlovValue, getContactTypeId, getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { getContactFiltersObject } from '../../../RightSideContainer/Contacts/ContactsUtils';
import { isEmployerOwnerShowFilterByContact } from '../../../RightSideContainer/UserAccess/UserPermissionConst';
import MemberInfoListItem from '../../MemberInfoListItem/MemberInfoListItem';
import { ModalActionAntSelect } from '../../ModalActionCommonComponent/ModalActionAntSelect';
import { ModalActionTitle } from '../../ModalActionTitle/ModalActionTitle';
import { ITopBarData } from './CareDashboardTopBar';
import { BoardType } from './interfaces';
import { DisplayText } from '../../DisplayText/DisplayText';
import { ILabelTask } from '../CareDashboardInterfaces';
import { GetLabelsByLabelTitleAndTypeId } from '../../../../services/Labels/LabelsQueries';

const {Option} = Select;

interface IFilterByTaskStatus {
  statusType: string;
  statusTypeUuid: string;
  id: number;
  label: string;
}

interface IFilterByTaskPriority {
  priorityType: string;
  priorityTypeUuid: string;
  id: number;
  label: string;
}

export interface IFilterByDueDate {
  dueDateType: string;
  dueDateTypeUuid: string;
  id: number;
  label: string;
}

export const dateRangeStatusUuid = 'date_range';
export const dateRangeStatusValue = 'Date Range';

const FilterDrawerView = (props: {
  selectedStatusList?: Array<IMlov>;
  selectedPriorityList?: Array<IMlov>;
  // selectedStatusType: string;
  // selectedStatusUuid: string;
  // selectedPriorityType: string;
  // selectedPriorityUuid: string;
  selectedDueDateType: string[];
  selectedDueDateUuid: string[];
  onActionPerformed: (
    tabCode: any,
    rawData: any,
  ) => void;
  topBarData: ITopBarData;
  onTopBarDataChange: (topBarData: ITopBarData) => void;
  isPatientView?: boolean;
  selectedContactUuid?: string[];
  selectedPatientName?: string[];
  selectedPatientLabel?: any[];
  setContactsData?: any;
  contactsData?: any;
  userPools?: any;
}) => {
  const mlovData = useContext(CommonDataContext);
  const [placeholder, setPlaceholder] = useState<any>(
    'Search members or prospects'
  );
  const {topBarData} = props;
  const showStatusFilters = topBarData.selectedBoardType !== BoardType.Status
  const showPriorityFilters = topBarData.selectedBoardType !== BoardType.Priority
  const showDueDateFilter = topBarData.selectedBoardType !== BoardType.dueDate
  const intl = useIntl();
  const currentUserId = getUserUUID();
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const drawerWidth = isSmallScreen ? '40vw' : '30vw';
  const {setContactsData, contactsData} = props;
  const [contactsDataState, setContactsDataState] = useState(contactsData)
  // For local filter store
  const userPreferencesTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPreferencesType',
    'user_task_filters'
  );
  const employerId = getUserEmployerId();

  // For task status
  const completedStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS,
    TASK_STATUS.ACCEPTED,
  );

  const taskStatusMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_STATUS);
  const acceptedStatusMlov = getMlovObjectFromCode(TASK_STATUS.ACCEPTED, taskStatusMlovs);
  const completedStatusMlov = getMlovObjectFromCode(TASK_STATUS.COMPLETED, taskStatusMlovs);
  const missedStatusMlov = getMlovObjectFromCode(TASK_STATUS_CODES.MISSED,taskStatusMlovs);
  const taskPriorityMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_PRIORITY);
  const highPriorityMlov = getMlovObjectFromCode(TASK_PRIORITY_CODES.HIGH, taskPriorityMlovs) as IMlov;
  const mediumPriorityMlov = getMlovObjectFromCode(TASK_PRIORITY_CODES.MEDIUM, taskPriorityMlovs) as IMlov;
  const lowPriorityMlov = getMlovObjectFromCode(TASK_PRIORITY_CODES.LOW, taskPriorityMlovs) as IMlov;

  const completedStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS,
    TASK_STATUS.COMPLETED
  );
  const requestedStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS,
    TASK_STATUS.ACCEPTED
  );
  const requestedStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS,
    TASK_STATUS.ACCEPTED
  );
  //For task priority
  const routinePriorityUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskPriority',
    'routine'
  );
  const routinePriorityValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskPriority',
    'routine'
  );
  const urgentPriorityUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskPriority',
    'urgent'
  );
  const urgentPriorityValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskPriority',
    'urgent'
  );
  const asapPriorityUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskPriority',
    'asap'
  );
  const asapPriorityValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskPriority',
    'asap'
  );
  //For Due Date
  const allStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );
  const allStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );
  const overdueStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'overdue'
  );
  const overdueStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'overdue'
  );
  const todayStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'today'
  );
  const todayStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'today'
  );
  const tomorrowStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'tomorrow'
  );
  const tomorrowStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'tomorrow'
  );
  const laterStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'later'
  );
  const laterStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'later'
  );

  const labels = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );
  const filteredResult = labels?.filter((item) => {
    return item.code === LABEL_TYPE_CODES.TASK;
  });
  const labelMlov = filteredResult[0];

  const [visible, setVisible] = useState(true);
  const [stateData, setStateData] = useState({
    statusTypeList: [] as IMlov[],
    priorityTypeList: [] as IMlov[],
    dueDateTypeList: [] as IFilterByDueDate[],
    selectedDueDateCodes: (props.selectedDueDateUuid || []),
    selectedStatusCodes: (props.selectedStatusList?.map?.((mlov) => mlov.code) || []),
    selectedPriorityCodes: (props.selectedPriorityList?.map?.((mlov) => mlov.code) || []),
    formData: {
      selectedContactUuid: props.selectedContactUuid
        ? props.selectedContactUuid
        : [],
      selectedPatientLabel: props.selectedPatientLabel
        ? props.selectedPatientLabel
        : [],
      selectedPatientName: props.selectedPatientName
        ? props.selectedPatientName
        : [],
      selectedStatusList: props.selectedStatusList
        ? props.selectedStatusList
        : [],
      selectedPriorityList: props.selectedPriorityList
        ? props.selectedPriorityList
        : [],
      // selectedStatusUuid: props.selectedStatusUuid
      //   ? props.selectedStatusUuid
      //   : 'all',
      // selectedPriority: props.selectedPriorityType
      //   ? props.selectedPriorityType
      //   : 'All',
      // selectedPriorityUuid: props.selectedPriorityUuid
      //   ? props.selectedPriorityUuid
      //   : 'all',
      selectedDueDate: props.selectedDueDateType
        ? props.selectedDueDateType
        : allStatusValue,
      selectedDueDateUuid: props.selectedDueDateUuid
        ? props.selectedDueDateUuid
        : allStatusUuid,
      // selectedSortUuid: props.selectedSortUuid,
      // selectedSortType: props.selectedSortType,
      // selectedSortValueAscending: props.selectedSortValueAscending,
      start: getDateStrFromFormat(
        props.topBarData.start ? props.topBarData.start : new Date(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      ),
      end: getDateStrFromFormat(
        props.topBarData.end ? props.topBarData.end : new Date(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      ),
      selectedPools: props?.topBarData?.selectedPools
        ? props.topBarData.selectedPools
        : [],
    },
  });

  const [taskLabelData, setTaskLabelData] = useState<{
    taskLabelsList?: ILabelTask[];
    limit: number;
    offset: number;
    loading: boolean;
    searchString?: string;
    selectedLabels?: string[];
  }>({
    taskLabelsList: [],
    limit: 10,
    offset: 0,
    searchString: '',
    loading: false,
    selectedLabels: props?.topBarData?.labels
    ? props.topBarData.labels?.map(item => item?.uuid || '') || []
    : [],
  });

  const [customerData, setCustomerData] = useState<
    {
      value: string | undefined;
      label: string;
      key: string;
      contactData: any;
    }[]
  >([]);
  const [customerLoading, setCustomerLoading] = useState(false);
  const filter: any = {
    _or: [],
    contactTagging: {},
    contactInboxes: {},
  };
  const contactTypeId = getContactTypeId('CUSTOMER');
  const accountId = getAccountId();

  const onClose = () => {
    setVisible(false);
    setStateData((prev) => {
      return {
        ...prev,
      };
    });
    setTimeout(() => {
      props.onActionPerformed(COMMON_ACTION_CODES.CANCEL, {});
    }, 100);
  };

  const onReset = () => {
    setVisible(false);
    setStateData((prev) => {
      return {
        ...prev,
      };
    });
    setTimeout(() => {
      props.onActionPerformed(COMMON_ACTION_CODES.CANCEL, {});
    }, 100);
  };

  const [addUpdateUserFilterPreference] = useMutation(
    CreateOrUpdateUserPreference,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getTaskLabels] = useLazyQuery(GetLabelsByLabelTitleAndTypeId, {
    fetchPolicy: 'no-cache'
  });

  const [getLabelsData] = useLazyQuery(TaskQueries.GET_LABELS_BY_IDS, {
    fetchPolicy: 'no-cache',
  });

  async function fetchLabelsForBatch(batchIds: string[]) {
    const getLabelsRes = await getLabelsData({
      variables: {
        labelIds: batchIds,
         labelTypeId: labelMlov.id,
      },
    });
    return getLabelsRes?.data?.labels || [] as ILabelTask[];
  }

  const onSubmit = async () => {
    let getLabelsDataRes = [] as ILabelTask[];
    if (taskLabelData.selectedLabels?.length) {
       getLabelsDataRes = await fetchLabelsForBatch(taskLabelData.selectedLabels);
    }
    props.onActionPerformed(
      COMMON_ACTION_CODES.SAVE,
      {...stateData.formData, selectedLabels: getLabelsDataRes}
    );
    setVisible(false);
  }

  const onSubmitOld = async () => {
    try {
      const updatedFormData = {
        ...stateData.formData,
        selectedContactUuid: stateData.formData.selectedContactUuid.map(
          (dataItem: any) => ({
            key: dataItem.key,
            value: dataItem.value,
            label: dataItem?.label?.props?.contactData?.name || dataItem?.label,
          })
        ),
      };

      // return;
      const addUpdateFilterPreferenceObject: any = {
        userId: currentUserId,
        userPreferencesTypeId: userPreferencesTypeId,
        preferencesJson: JSON.stringify(updatedFormData),
        preferencesModelVersion: 'v1',
      };

      if (topBarData.userPreferenceId) {
        addUpdateFilterPreferenceObject.id = topBarData.userPreferenceId;
      }

      await addUpdateUserFilterPreference({
        variables: {
          object: addUpdateFilterPreferenceObject,
        },
      });
      setVisible(false);
      setTimeout(() => {
        props.onActionPerformed(
          COMMON_ACTION_CODES.SAVE,
          stateData.formData,
        );
      }, 100);
    } catch (error: any) {
    }
  };

  const [getContacts] = useLazyQuery(
    // ContactsQueries.SEARCH_CONTACTS_WITH_CONTACT_TYPE,
    LeadQueries.GetContacts,
    {
      onCompleted: (data: any) => {
        let contactsData = [];
        if (data && data.contacts && data.contacts.length > 0) {
          contactsData = data.contacts
            .filter((contactItem: any) => contactItem?.uuid)
            .map((contact: any) => {
              return {
                label: contact.name,
                value: contact?.uuid,
                key: contact?.uuid,
                contactData: contact,
              };
            });
        }
        setCustomerData(contactsData);
        setCustomerLoading(false);
        // setIsOpen(true);
      },
      onError: (error) => {
        setCustomerData([]);
        setCustomerLoading(false);
        // setIsOpen(true);
      },
    }
  );

  const onChangeCustomer = (searchText: string) => {
    if (searchText) onSearchCustomer(searchText);
  };

  const onSearchCustomer = async (searchText: string) => {
    try {
      setCustomerLoading(true);
      await getContacts({
        variables: {
          // accountId: accountId,
          // contactType: leadContactType,
          limit: 10,
          offset: 0,
          searchString: '%' + searchText + '%',
          order_by: {
            updatedAt: 'desc',
          },
          contact_filters: getContactFiltersObject(
            filter,
            searchText,
            accountId,
            [],
            [],
            '',
            [] as any,
            '',
            '',
            employerId
          ),
        },
      });
    } catch (e) {}
  };

  useEffect(() => {
    const initialStatusType = [
      // {
      //   id: 1,
      //   statusType: 'All',
      //   statusTypeUuid: 'all',
      //   label: 'All',
      // },
      {
        ...completedStatusMlov,
      } as IMlov,
      {
        ...acceptedStatusMlov,
        value: 'Not Completed'
      } as IMlov,
      {
        ...missedStatusMlov,
      } as IMlov
      // {
      //   id: 2,
      //   statusType: completedStatusValue,
      //   statusTypeUuid: completedStatusUuid,
      //   label: completedStatusValue,
      // },
      // {
      //   id: 3,
      //   statusType: requestedStatusValue,
      //   statusTypeUuid: requestedStatusUuid,
      //   label: 'Not Completed',
      // },
    ];
    const initialPriorityType = [
      highPriorityMlov,
      mediumPriorityMlov,
      lowPriorityMlov,
      // {
      //   id: 1,
      //   priorityType: 'All',
      //   priorityTypeUuid: 'all',
      //   label: 'All',
      // },
      // {
      //   id: 2,
      //   priorityType: asapPriorityValue,
      //   priorityTypeUuid: asapPriorityUuid,
      //   label: asapPriorityValue,
      // },
      // {
      //   id: 3,
      //   priorityType: urgentPriorityValue,
      //   priorityTypeUuid: urgentPriorityUuid,
      //   label: urgentPriorityValue,
      // },
      // {
      //   id: 4,
      //   priorityType: routinePriorityValue,
      //   priorityTypeUuid: routinePriorityUuid,
      //   label: routinePriorityValue,
      // },
    ];
    const initialDueDateType = [
      {
        id: 1,
        dueDateType: overdueStatusValue,
        dueDateTypeUuid: overdueStatusUuid,
        label: overdueStatusValue,
      },
      {
        id: 2,
        dueDateType: todayStatusValue,
        dueDateTypeUuid: todayStatusUuid,
        label: todayStatusValue,
      },
      {
        id: 3,
        dueDateType: tomorrowStatusValue,
        dueDateTypeUuid: tomorrowStatusUuid,
        label: tomorrowStatusValue,
      },
      {
        id: 4,
        dueDateType: laterStatusValue,
        dueDateTypeUuid: laterStatusUuid,
        label: laterStatusValue,
      },
      {
        id: 5,
        dueDateType: dateRangeStatusValue,
        dueDateTypeUuid: dateRangeStatusUuid,
        label: dateRangeStatusValue,
      },
    ];

    setStateData((prev) => {
      return {
        ...prev,
        statusTypeList: initialStatusType,
        priorityTypeList: initialPriorityType,
        dueDateTypeList: initialDueDateType,
        formData: {
          ...prev.formData,
          selectedStatusList: props.selectedStatusList
            ? props.selectedStatusList
            : [],
          selectedPriorityList: props.selectedPriorityList
            ? props.selectedPriorityList
            : [],
          // selectedStatusUuid: props.selectedStatusUuid
          //   ? props.selectedStatusUuid
          //   : 'all',
          // selectedPriority: props.selectedPriorityType
          //   ? props.selectedPriorityType
          //   : 'All',
          // selectedPriorityUuid: props.selectedPriorityUuid
          //   ? props.selectedPriorityUuid
          //   : 'all',
          selectedDueDate: props.selectedDueDateType
            ? props.selectedDueDateType
            : allStatusValue,
          selectedDueDateUuid: props.selectedDueDateUuid
            ? props.selectedDueDateUuid
            : allStatusUuid,
          selectedContactUuid: props.selectedContactUuid
            ? props.selectedContactUuid
            : [],
          selectedPatientLabel: props.selectedPatientLabel
            ? props.selectedPatientLabel
            : [],
          selectedPatientName: props.selectedPatientName
            ? props.selectedPatientName
            : [],
          selectedPools: props.topBarData.selectedPools
            ? props.topBarData.selectedPools
            : [],
        },
      };
    });
    setTaskLabelData(prev => {
      return {
        ...prev,
        taskLabelsList: props?.topBarData?.labels
        ? props.topBarData.labels
        : [],
        selectedLabels: props?.topBarData?.labels
        ? props.topBarData.labels?.map(item => item?.uuid || '') || []
        : [],
      }
    });
  }, [
    props.selectedStatusList,
    props.selectedPriorityList,
    props.selectedDueDateType,
    props.selectedPatientName,
    props.topBarData.selectedPools,
    props.topBarData?.labels,
  ]);

  const allDueDateUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );

  const onSearchTaskLabel = async (searchString: string) => {
    try {
      const taskLabelParams = {
        limit: 10,
        offset: 0,
        labelTypeId: labelMlov.id,
        search: `%${searchString?.trim()}%`,
      };
      const apiResponse = await getTaskLabels({ variables: taskLabelParams});
      const taskLabels = apiResponse?.data?.labels || [];
      setTaskLabelData(prev => {
        return {
          ...prev,
          loading: false,
          taskLabelsList: taskLabels,
        }
      });
    } catch(error) {
      setTaskLabelData(prev => { return { ...prev, loading: false }});
    }
  };

  const getLabelOptions = () => {
    if (taskLabelData?.taskLabelsList?.length) {
      return (taskLabelData?.taskLabelsList || []).map((label: ILabelTask) => {
        return <Option key={label.uuid} value={label.uuid}>
          {label?.title}
        </Option>
      });
    }
  }

  const renderTaskLabel = () => {
    return (
      <VStack height={140} style={styles.taskLabelContainer}>
        <View style={styles.labelHeader} backgroundColor={Colors.Custom.Gray50}>
          <Text size={'lgSemibold'} color={'#000'}>
            Filter by Labels
          </Text>
        </View>
        <VStack style={styles.labelSelectContainer}>
          <Select
            mode="multiple"
            allowClear
            placeholder="Search Labels"
            showSearch={true}
            value={
              taskLabelData.selectedLabels?.length
                ? taskLabelData.selectedLabels
                : []
            }
            filterOption={false}
            notFoundContent={intl.formatMessage({
              id: 'NoDataSelect',
            })}
            onChange={(selectedLabels) => {
              const tempData = selectedLabels?.filter((item: string) => {
                return item != 'noData';
              });
              setTaskLabelData((prev) => {
                return {
                  ...prev,
                  selectedLabels: tempData,
                };
              });
            }}
            onSearch={debounce((value) => {
              setTaskLabelData((prev) => {
                return {...prev, searchString: value?.trim()};
              });
              onSearchTaskLabel(value);
            }, 500)}
            loading={taskLabelData.loading}
            style={{width: '100%', height: 40}}
          >
            {getLabelOptions()}
          </Select>
        </VStack>
      </VStack>
    );
  };

  return (
    <Drawer
      visible={visible}
      style={{minWidth: 700}}
      width={drawerWidth}
      onClose={onClose}
      closable={false}
      className={'filterDrawer'}
      title={
        <>
          <ModalActionTitle
            title={'filters'}
            titleColor={''}
            onActionClick={(code: string) => {
              if (code === 'cancel') onClose();
            }}
            // actionButtons={[{code: 'cancel', name: 'Cancel'}]}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'close',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onClose();
                },
              },
              {
                show: true,
                id: 1,
                btnText: 'reset',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onReset();
                },
              },
              {
                show: true,
                id: 2,
                btnText: 'Apply',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  onSubmit();
                },
              },
            ]}
          />
        </>
      }
    >
      <VStack>
        {!props.isPatientView &&
          (isEmployerRole() ? isEmployerOwnerShowFilterByContact() : true) && (
            <VStack height={140}>
              <View style={styles.sectionHeader} backgroundColor={Colors.Custom.Gray50}>
                <Text size={'lgSemibold'} color={'#000'}>
                  Filter by Member
                </Text>
              </View>
              <VStack style={styles.sectionContent}>
                <ModalActionAntSelect
                  mode={'multiple'}
                  allowClear={false}
                  leftMargin={'0'}
                  showSearch={true}
                  onSearch={debounce(onChangeCustomer, 500)}
                  labelInValue={true}
                  filterOption={false}
                  value={stateData.formData?.selectedContactUuid}
                  isRequired={false}
                  notFoundContent={intl.formatMessage({
                    id: 'NoDataSelect',
                  })}
                  loading={customerLoading}
                  placeholder={'Select Members'}
                  accessibilityLabel="Select Employer"
                  onChange={(value: any) => {
                    if (value) {
                      const labelArray: string[] = [];
                      value.map((valueItem: any) => {
                        (valueItem?.label?.props?.contactData?.name ||
                          valueItem?.label) &&
                          labelArray.push(
                            valueItem?.label?.props?.contactData?.name ||
                              valueItem?.label
                          );
                      });
                      const newContactDataArray = value.reduce(
                        (acc: any, item: any) => {
                          const id = item.key;
                          const foundContact = contactsDataState.find(
                            (contact: any) => contact.key === id
                          );
                          if (foundContact) {
                            acc.push(foundContact);
                          } else {
                            const customerInfo = customerData.find(
                              (customer) => customer.key === id
                            );
                            if (customerInfo) {
                              acc.push({
                                key: customerInfo.key,
                                label: customerInfo.label,
                                value: customerInfo.value,
                                contactData: customerInfo.contactData,
                              });
                            }
                          }
                          return acc;
                        },
                        []
                      );
                      setStateData((prev) => {
                        return {
                          ...prev,
                          formData: {
                            ...prev.formData,
                            selectedContactUuid: newContactDataArray,
                            selectedPatientName: labelArray,
                          },
                        };
                      });
                      setContactsData(newContactDataArray);
                      setContactsDataState(newContactDataArray);
                    }
                  }}
                  data={customerData}
                  optionProps={{key: 'key', value: 'value', label: 'label'}}
                  extraStyle={{flex: 1}}
                  optionsArray={
                    customerData &&
                    customerData.map((option: any) => {
                      return (
                        <Option key={option['key']} value={option['value']}>
                          <MemberInfoListItem
                            showDateOfBirth={true}
                            contactData={option?.contactData}
                            isPatientSearchCard
                          />
                        </Option>
                      );
                    })
                  }
                />
              </VStack>
            </VStack>
          )}
        {!props.isPatientView && showStatusFilters && (
          <VStack style={styles.sectionContainer}>
            <View style={styles.sectionHeader} backgroundColor={Colors.Custom.Gray50}>
              <Text size={'lgSemibold'} color={'#000'}>
                Filter by Task Status
              </Text>
            </View>
            <VStack style={styles.sectionContent}>
              {stateData.statusTypeList.length && (
                <View style={styles.checkboxContainer}>
                  {stateData.statusTypeList.map((mlov) => {
                    return (
                      <Checkbox
                        key={mlov.id}
                        value={mlov.code}
                        style={styles.checkbox}
                        alignItems={'center'}
                        isChecked={stateData.selectedStatusCodes?.includes(
                          mlov.code
                        )}
                        onChange={(isChecked) => {
                          setStateData((stateData) => {
                            const selectedStatusCodes =
                              stateData.selectedStatusCodes || [];
                            if (isChecked) {
                              if (!selectedStatusCodes.includes(mlov.code)) {
                                selectedStatusCodes.push(mlov.code);
                              }
                            } else {
                              if (selectedStatusCodes.includes(mlov.code)) {
                                selectedStatusCodes.splice(
                                  selectedStatusCodes.indexOf(mlov.code),
                                  1
                                );
                              }
                            }

                            const selectedStatusList: IMlov[] = [];
                            selectedStatusCodes?.length &&
                              stateData.statusTypeList?.forEach?.((status) => {
                                if (
                                  selectedStatusCodes.indexOf?.(status.code) !==
                                  -1
                                ) {
                                  selectedStatusList.push(status);
                                }
                              });

                            return {
                              ...stateData,
                              selectedStatusCodes: [...selectedStatusCodes],
                              formData: {
                                ...stateData.formData,
                                selectedStatusList: [...selectedStatusList],
                              },
                            };
                          });
                        }}
                      >
                        <Text
                          size={'smMedium'}
                          color={Colors.Custom.Gray700}
                          style={styles.checkboxLabel}
                        >
                          {mlov.value}
                        </Text>
                      </Checkbox>
                    );
                  })}
                </View>
              )}
            </VStack>
          </VStack>
        )}

        {!props.isPatientView && showPriorityFilters && (
          <VStack style={styles.sectionContainer}>
            <View style={styles.sectionHeader} backgroundColor={Colors.Custom.Gray50}>
              <Text size={'lgSemibold'} color={'#000'}>
                Filter by Task Priority
              </Text>
            </View>
            <VStack style={styles.sectionContent}>
              {stateData.priorityTypeList.length && (
                <View style={styles.checkboxContainer}>
                  {stateData.priorityTypeList.map((mlov) => {
                    return (
                      <Checkbox
                        key={mlov.id}
                        value={mlov.code}
                        style={styles.checkbox}
                        alignItems={'center'}
                        isChecked={stateData.selectedPriorityCodes?.includes(
                          mlov.code
                        )}
                        onChange={(isChecked) => {
                          setStateData((stateData) => {
                            const selectedPriorityCodes =
                              stateData.selectedPriorityCodes || [];
                            if (isChecked) {
                              if (!selectedPriorityCodes.includes(mlov.code)) {
                                selectedPriorityCodes.push(mlov.code);
                              }
                            } else {
                              if (selectedPriorityCodes.includes(mlov.code)) {
                                selectedPriorityCodes.splice(
                                  selectedPriorityCodes.indexOf(mlov.code),
                                  1
                                );
                              }
                            }

                            const selectedPriorityList: IMlov[] = [];
                            selectedPriorityCodes?.length &&
                              stateData.priorityTypeList?.forEach?.(
                                (status) => {
                                  if (
                                    selectedPriorityCodes.indexOf?.(
                                      status.code
                                    ) !== -1
                                  ) {
                                    selectedPriorityList.push(status);
                                  }
                                }
                              );

                            return {
                              ...stateData,
                              selectedPriorityCodes: [...selectedPriorityCodes],
                              formData: {
                                ...stateData.formData,
                                selectedPriorityList: [...selectedPriorityList],
                              },
                            };
                          });
                        }}
                      >
                        <Text
                          size={'smMedium'}
                          color={Colors.Custom.Gray700}
                          style={styles.checkboxLabel}
                        >
                          {mlov.value}
                        </Text>
                      </Checkbox>
                    );
                  })}
                </View>
              )}
            </VStack>
          </VStack>
        )}

        {!props.isPatientView && showDueDateFilter && (
        <VStack style={{marginTop: !props.isPatientView ? 24 : 0}}>
            <View style={styles.sectionHeader} backgroundColor={Colors.Custom.Gray50}>
              <Text size={'lgSemibold'} color={'#000'}>
                Filter by Due Date
              </Text>
            </View>
          <VStack style={styles.sectionContent}>
            {stateData.dueDateTypeList.length && (
              <View style={styles.checkboxContainer}>
                {stateData.dueDateTypeList.map((type) => {
                  return (
                    <Checkbox
                      key={type.dueDateTypeUuid}
                      value={type.label}
                      style={styles.checkbox}
                      alignItems={'center'}
                      isChecked={stateData.selectedDueDateCodes?.includes(
                        type.dueDateTypeUuid
                      )}
                      onChange={(isChecked) => {
                        if (type.dueDateTypeUuid === dateRangeStatusUuid) {
                          if (isChecked) {
                            setStateData((stateData) => {
                              return {
                                ...stateData,
                                selectedDueDateCodes: [type.dueDateTypeUuid],
                                formData: {
                                  ...stateData.formData,
                                  selectedDueDate: [type.label],
                                  selectedDueDateUuid: [type.dueDateTypeUuid],
                                },
                              };
                            });
                          } else {
                            setStateData((stateData) => {
                              return {
                                ...stateData,
                                selectedDueDateCodes: [],
                                formData: {
                                  ...stateData.formData,
                                  selectedDueDate: [],
                                  selectedDueDateUuid: [],
                                },
                              };
                            });
                          }
                        } else {
                          setStateData((stateData) => {
                            let selectedDueDateCodes =
                              stateData.selectedDueDateCodes || [];
                            if (isChecked) {
                              if (
                                !selectedDueDateCodes.includes(
                                  type.dueDateTypeUuid
                                )
                              ) {
                                if (
                                  selectedDueDateCodes.includes(
                                    dateRangeStatusUuid
                                  )
                                ) {
                                  selectedDueDateCodes = [type.dueDateTypeUuid];
                                } else {
                                  if (Array.isArray(selectedDueDateCodes)) {
                                    selectedDueDateCodes.push(
                                      type.dueDateTypeUuid
                                    );
                                  } else {
                                    selectedDueDateCodes = [
                                      type.dueDateTypeUuid,
                                    ];
                                  }
                                }
                              }
                            } else {
                              if (
                                selectedDueDateCodes.includes(
                                  type.dueDateTypeUuid
                                )
                              ) {
                                selectedDueDateCodes.splice(
                                  selectedDueDateCodes.indexOf(
                                    type.dueDateTypeUuid
                                  ),
                                  1
                                );
                              }
                            }

                            const selectedDueDateType: string[] = [];
                            selectedDueDateCodes?.length &&
                              stateData.dueDateTypeList?.forEach?.(
                                (dueDateType) => {
                                  if (
                                    selectedDueDateCodes.indexOf?.(
                                      dueDateType.dueDateTypeUuid
                                    ) !== -1
                                  ) {
                                    selectedDueDateType.push(dueDateType.label);
                                  }
                                }
                              );
                            if (
                              selectedDueDateCodes.length > 1 &&
                              selectedDueDateCodes.includes(allDueDateUuid)
                            ) {
                              selectedDueDateCodes =
                                selectedDueDateCodes.filter(
                                  (code) => code !== allDueDateUuid
                                );
                            }
                            if (selectedDueDateCodes.length === 0) {
                              selectedDueDateCodes.push(allDueDateUuid);
                            }

                            return {
                              ...stateData,
                              selectedDueDateCodes: [...selectedDueDateCodes],
                              formData: {
                                ...stateData.formData,
                                selectedDueDate: [...selectedDueDateType],
                                selectedDueDateUuid: [...selectedDueDateCodes],
                              },
                            };
                          });
                        }
                      }}
                    >
                      <Text
                        size={'smMedium'}
                        color={Colors.Custom.Gray700}
                        style={styles.checkboxLabel}
                      >
                        {type.dueDateType}
                      </Text>
                    </Checkbox>
                  );
                })}
              </View>
            )}
          </VStack>

          {(stateData.formData.selectedDueDateUuid.includes('date_range') ||
            props.isPatientView) && (
            <VStack style={styles.datePickerContainer}>
              <VStack space={1}>
                <Text fontSize={14} color={'gray.700'} fontWeight={500} ml={3}>
                  From Date
                </Text>
                <DatePicker
                  style={{
                    height: '44px',
                    width: 335,
                    borderColor: Colors.Custom.Gray300,
                    borderRadius: 8,
                    fontWeight: 400,
                    fontSize: 16,
                  }}
                  disabledDate={(date) => {
                    const currentDate = getMomentObj(new Date()).add(
                      14,
                      'days'
                    );
                    return date && date.valueOf() > currentDate.valueOf();
                  }}
                  value={
                    stateData.formData.start
                      ? moment(
                          stateData.formData.start,
                          DATE_FORMATS.DISPLAY_DATE_FORMAT
                        )
                      : undefined
                  }
                  // disabledDate={(current: moment.Moment) => {
                  //   return current && isPastDay(current);
                  // }}
                  format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                  onChange={(dates: any, formatStrings: string) => {
                    if (formatStrings && formatStrings.length > 1) {
                      setStateData((prev) => {
                        return {
                          ...prev,
                          formData: {
                            ...prev.formData,
                            start: formatStrings,
                            end: stateData.formData.end,
                          },
                        };
                      });
                    }
                  }}
                />
              </VStack>
              <VStack space={1} style={styles.marginTop6}>
                <Text fontSize={14} color={'gray.700'} fontWeight={500} ml={3}>
                  To Date
                </Text>
                <DatePicker
                  style={{
                    height: '44px',
                    width: 335,
                    borderColor: Colors.Custom.Gray300,
                    borderRadius: 8,
                    fontWeight: 400,
                    fontSize: 16,
                  }}
                  disabledDate={(date) => {
                    const currentDate = getMomentObj(new Date()).add(
                      14,
                      'days'
                    );
                    return date && date.valueOf() > currentDate.valueOf();
                  }}
                  value={
                    stateData.formData.end
                      ? moment(
                          stateData.formData.end,
                          DATE_FORMATS.DISPLAY_DATE_FORMAT
                        )
                      : undefined
                  }
                  // disabledDate={(current: moment.Moment) => {
                  //   return current && isPastDay(current);
                  // }}
                  format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                  onChange={(dates: any, formatStrings: string) => {
                    if (formatStrings && formatStrings.length > 1) {
                      setStateData((prev) => {
                        return {
                          ...prev,
                          formData: {
                            ...prev.formData,
                            end: formatStrings,
                            start: stateData.formData.start,
                          },
                        };
                      });
                    }
                  }}
                />
              </VStack>
            </VStack>
          )}
        </VStack>
        )}

        {!props.isPatientView && (
          <VStack height={140} style={styles.sectionContainer}>
            <View style={styles.sectionHeader} backgroundColor={Colors.Custom.Gray50}>
              <Text size={'lgSemibold'} color={'#000'}>
                Filter by Task Pool
              </Text>
            </View>
            <VStack style={styles.sectionContent}>
              <ModalActionAntSelect
                mode={'multiple'}
                allowClear={true}
                leftMargin={'0'}
                showSearch={true}
                onSearch={debounce(onChangeCustomer, 500)}
                labelInValue={true}
                filterOption={false}
                value={stateData.formData?.selectedPools}
                isRequired={false}
                notFoundContent={intl.formatMessage({
                  id: 'NoDataSelect',
                })}
                loading={customerLoading}
                placeholder={'Select Task Pool'}
                // accessibilityLabel="Select Employer"
                onChange={(value: any) => {
                  if (value) {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        formData: {
                          ...prev.formData,
                          selectedPools: value,
                        },
                      };
                    });
                  }
                }}
                data={props.userPools}
                optionProps={{key: 'id', value: 'id', label: 'name'}}
                extraStyle={{flex: 1}}
              />
            </VStack>
          </VStack>
        )}

        {renderTaskLabel()}

      </VStack>
    </Drawer>
  );
};

const styles = StyleSheet.create({
  drawer: {
    minWidth: 700,
  },
  taskLabelContainer: {
    marginTop: 10,
  },
  labelHeader: {
    // backgroundColor: Colors.Custom.Gray50,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 18,
    maxWidth: 240,
  },
  labelSelectContainer: {
    marginTop: 24,
  },
  labelSelect: {
    width: '100%',
    height: 40,
  },
  sectionHeader: {
    // backgroundColor: Colors.Custom.Gray50,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 18,
    maxWidth: 240,
  },
  sectionContent: {
    marginTop: 24,
  },
  sectionContainer: {
    marginTop: 24,
  },
  checkboxContainer: {
    paddingLeft: 16,
  },
  checkbox: {
    marginVertical: 10,
  },
  checkboxLabel: {
    marginLeft: 8,
  },
  modalActionAntSelect: {
    flex: 1,
  },
  datePickerContainer: {
    marginTop: 24,
  },
  datePicker: {
    height: 44,
    width: 335,
    borderColor: Colors.Custom.Gray300,
    borderRadius: 8,
    fontWeight: '400',
    fontSize: 16,
  },
  marginTop6: {
    marginTop: 6,
  },
  marginTop24: {
    marginTop: 24,
  },
});

export default FilterDrawerView;
