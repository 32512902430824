import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  buttonTitle: {
    fontSize: 8,
    fontWeight: 'normal',
    color: Colors.Custom.Gray500,
    marginTop: 1,
  },
  dialPadNumberTitle: {
    paddingX: 16,
    marginTop: 32,
    width: '100%',
    textAlign: 'center',
    fontWeight: '400',
    color: Colors.Custom.MonochromeBlack,
    marginBottom: -16,
    minHeight: 40,
  },
});
