import {Button, FormControl, Input, Modal, Select, Text} from 'native-base';
import {useState} from 'react';
import {IAddMemberModalProps, IRoles} from '..';
import {rolesDummyData} from '../../../../../dummyData';
import {styles} from './TeamMembersModalStyles';

const {Content, CloseButton, Header, Body, Footer} = Modal;
const {Label} = FormControl;
const {Item} = Select;

const AddMemberModal = (props: IAddMemberModalProps) => {
  const {isModalOpen, onModalClose, branchList, teamList} = props;

  const [role, setRole] = useState('Admin');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errors, setErrors]: any = useState({});


  const validateEmail = (): boolean => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email.match(emailRegex)) {
      setErrors({
        ...errors,
        email: 'Enter a valid email address',
      });
      return false;
    } else {
      setErrors({
        ...errors,
        email: '',
      });
    }
    return true;
  };

  // validation for phone number
  const validatePhoneNumber = (): boolean => {
    const phoneNumberRegex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    if (!phoneNumber.match(phoneNumberRegex)) {
      setErrors({
        ...errors,
        phoneNumber: 'Enter a valid phone number',
      });
      return false;
    } else {
      setErrors({
        ...errors,
        phoneNumber: '',
      });
    }
    return true;
  };

  const handleAddMember = () => {
    if (validateEmail() && validatePhoneNumber()) {
      onModalClose();
    } else {
    }
  };

  return (
    <Modal isOpen={isModalOpen} onClose={() => onModalClose()}>
      <Content style={styles.modalContainer}>
        <CloseButton />
        <Header>
          <Text style={styles.modalHeaderText}>Add a Team Member</Text>
        </Header>
        <Body>
          <FormControl isRequired style={styles.formControlStyle}>
            <Label style={styles.labelStyle}>Name</Label>
            <Input
              style={styles.inputStyle}
              isRequired
              fontSize={'lg'}
              _focus={{borderColor: '#D0D5DD'}}
              onChangeText={(fullName: string) => setFullName(fullName)}
            />
          </FormControl>
          <FormControl isRequired style={styles.formControlStyle}>
            <Label style={styles.labelStyle}>Role</Label>
            <Select
              style={styles.selectStyle}
              selectedValue={role}
              onValueChange={(role: string) => setRole(role)}
            >
              {rolesDummyData.map((singleRole: IRoles) => {
                return (
                  <Item
                    key={singleRole.id}
                    label={singleRole.value}
                    value={singleRole.id}
                  />
                );
              })}
            </Select>
          </FormControl>
          <FormControl isRequired style={styles.formControlStyle}>
            <Label style={styles.labelStyle}>Phone</Label>
            <Input
              type="phone"
              style={styles.inputStyle}
              fontSize={'lg'}
              maxLength={15}
              _focus={{borderColor: '#D0D5DD'}}
              onChangeText={(phoneNumber) => {
                setPhoneNumber(phoneNumber);
                validatePhoneNumber();
              }}
            />
            {errors.phoneNumber && (
              <Text style={styles.errorText}>{errors.phoneNumber}</Text>
            )}
          </FormControl>
          <FormControl isRequired style={styles.formControlStyle}>
            <Label style={styles.labelStyle}>Email</Label>
            <Input
              type="email"
              style={styles.inputStyle}
              fontSize={'lg'}
              _focus={{borderColor: '#D0D5DD'}}
              onChangeText={(email) => {
                setEmail(email);
                validateEmail();
              }}
            />
            {errors.email && (
              <Text style={styles.errorText}>{errors.email}</Text>
            )}
          </FormControl>

          <FormControl style={styles.formControlStyle}>
            <Label style={styles.labelStyle}>Assign a Team</Label>
            <Select placeholder="Assign a Team">
              {teamList.map((team, index) => {
                return <Item key={index} value={team} label={team} />;
              })}
            </Select>
          </FormControl>

          <FormControl style={styles.formControlStyle}>
            <Label style={styles.labelStyle}>Assign a Branch</Label>
            <Select placeholder="Assign a Branch">
              {branchList.map((branch, index) => {
                return <Item key={index} value={branch} label={branch} />;
              })}
            </Select>
          </FormControl>

          <Footer style={styles.modalFooter}>
            <Button.Group>
              <Button
                onPress={() => {
                  handleAddMember();
                }}
              >
                Add
              </Button>
              <Button colorScheme="dark" onPress={() => onModalClose()}>
                Cancel
              </Button>
            </Button.Group>
          </Footer>
        </Body>
      </Content>
    </Modal>
  );
};

export default AddMemberModal;
