import React from "react";

const ProceduresSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{width: 'inherit', height: 'inherit'}}
    fill="none"
    viewBox="0 0 24 25"
  >
    <g>
      <path
        stroke="#98A2B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13.83 10.24l7.58 7.58a.596.596 0 010 .842l-.148.147a3.55 3.55 0 01-2.526 1.047 3.55 3.55 0 01-2.527-1.047 3.55 3.55 0 01-1.046-2.526c0-.824.277-1.605.787-2.238l-.857-.857-1.831 1.831a.595.595 0 01-.843 0l-1.684-1.684m3.095-3.095l-3.095 3.095m3.095-3.095L6.953 3.363a2.19 2.19 0 00-3.095 0 2.19 2.19 0 000 3.095l6.877 6.877M3 22.724h2.54m2.542 0h2.54m2.542 0h2.54m2.541 0h2.541"
      ></path>
    </g>
  </svg>
  );
}

export default React.memo(ProceduresSvg);
