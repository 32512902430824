import {Modal, ModalProps} from 'antd';
import {View, Text, TextStyle, TextProps} from 'react-native';
import {Colors} from '../../../styles';
import './style.css';
import {useIntl} from 'react-intl';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import MessageDeleteSvg from '../Svg/MessageDeleteSvg';
import TrashSvg from '../Svg/TrashSvg';

const CustomConfirmationModal = (props: {
  customCancelBtnText?: string;
  customOkBtnText?: string;
  customCancelBtnStyle?: any;
  customOkBtnStyle?: any;
  customCancelBtnTextStyle?: any;
  customOkBtnTextStyle?: any;
  customTextHeaderStyle?: TextStyle;
  customTextHeaderProps?: TextProps;
  customTextContentStyle?: TextStyle;
  customTextContentProps?: TextProps;
  customModalProps?: ModalProps;
  width?: string | number;
  isOpen?: boolean;
  isClosable?: boolean;
  customIcon?: JSX.Element;
  headerText: string;
  message?: string;
  hideCancelBtn?: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
  modalContent?: JSX.Element;
  disabled?: boolean;
}) => {
  const intl = useIntl();
  return (
    <>
      <Modal
        className="custom-confirmation-modal"
        width={props.width || 310}
        open={props?.isOpen || false}
        closable={props?.isClosable || false}
        {...(props.customModalProps || {})}
        title={
          <View
            style={{
              alignItems: 'center',
            }}
          >
            {props?.customIcon || <TrashSvg />}
            <Text
              style={[
                {
                  color: Colors.FoldPixel.GRAY400,
                  fontSize: 16,
                  lineHeight: 19.2,
                  fontWeight: '500',
                  marginTop: 8,
                  textAlign: 'center',
                },
                props?.customTextHeaderStyle || {},
              ]}
              {...(props.customTextHeaderProps || {})}
            >
              {intl.formatMessage({id: props.headerText || ''})}
            </Text>
          </View>
        }
        footer={
          <View
            style={{
              justifyContent: 'center',
              flexDirection: 'row',
              marginTop: 16,
            }}
          >
            {!props.hideCancelBtn ? (
              <FoldButton
                customProps={{
                  btnText: props.customCancelBtnText || 'Cancel',
                }}
                nativeProps={{
                  onPress() {
                    props.onCancel && props.onCancel();
                  },
                  backgroundColor: 'transparent',
                  borderWidth: 0.5,
                  height: '32px',
                  borderColor: Colors.FoldPixel.GRAY200,
                  borderRadius: 4,
                  _text: {
                    fontSize: 14,
                    lineHeight: 16.8,
                    color: Colors.FoldPixel.GRAY300,
                    ...{...(props.customCancelBtnTextStyle || {})},
                  },
                  paddingX: 10,
                  ...{...(props.customCancelBtnStyle || {})},
                }}
              />
            ) : (
              <></>
            )}
            <FoldButton
              customProps={{
                btnText: props.customOkBtnText || 'Confirm',
              }}
              nativeProps={{
                disabled: props.disabled,
                onPress() {
                  props?.onConfirm();
                },
                opacity: props.disabled ? 0.3 : 1,
                backgroundColor: Colors.Custom.Red100,
                borderWidth: 0.5,
                height: '32px',
                borderColor: Colors.FoldPixel.STATUS_ERROR,
                borderRadius: 4,
                _text: {
                  fontSize: 14,
                  lineHeight: 16.8,
                  color: Colors.FoldPixel.STATUS_ERROR,
                  ...{...(props.customOkBtnTextStyle || {})},
                },
                paddingX: 10,
                marginLeft: '8px',
                ...{...(props.customOkBtnStyle || {})},
              }}
            />
          </View>
        }
      >
        {props?.modalContent
          ? props.modalContent
          : props?.message && (
              <View
                style={{
                  marginTop: 2,
                }}
              >
                <Text
                  style={[
                    {
                      color: Colors.FoldPixel.GRAY250,
                      fontSize: 14,
                      lineHeight: 16.8,
                      fontWeight: '400',
                      textAlign: 'center',
                    },
                    props?.customTextContentStyle || {},
                  ]}
                  {...(props.customTextContentProps || {})}
                >
                  {intl.formatMessage({id: props?.message})}
                </Text>
              </View>
            )}
      </Modal>
    </>
  );
};

export default CustomConfirmationModal;
