import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  listStyles: {
    height: 70,
    paddingHorizontal: 32,
    paddingVertical: 8,
  },
  taxonTextStyle: {
    fontSize: 15,
  },
});
