import React from "react";

const ChatMessagingSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 29 29"
    >
      <path
        fill={svgColors}
        d="M29 14.5c0 7.007-6.492 12.688-14.5 12.688a16.422 16.422 0 01-4.254-.555c-1.058.536-3.489 1.566-7.578 2.236-.363.058-.638-.319-.495-.656.642-1.515 1.222-3.534 1.396-5.376C1.349 20.608 0 17.69 0 14.5 0 7.493 6.492 1.812 14.5 1.812S29 7.492 29 14.5zM8.156 9.062a.906.906 0 000 1.813h12.688a.907.907 0 000-1.813H8.156zm0 4.532a.906.906 0 100 1.812h12.688a.906.906 0 100-1.812H8.156zm0 4.531a.906.906 0 000 1.813h7.25a.907.907 0 000-1.813h-7.25z"
      ></path>
    </svg>
  );
}

export default React.memo(ChatMessagingSvg);
