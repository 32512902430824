import { useLazyQuery } from '@apollo/client';
import { Drawer } from 'antd';
import { Spinner } from 'native-base';
import React from 'react';
import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import EmployerQueries from '../../../services/Employer/EmployerQueries';
import { GET_MANAGER_ROLES } from '../../../services/User/UserQueries';
import { getAccountId, getDrawerWidthByScreens, isEmployerRole } from '../../../utils/commonUtils';
import LocalStorage from '../../../utils/LocalStorage';
import { getMlovListByCategory } from '../../../utils/mlovUtils';
import { EmployerCreate } from './EmployerCreate';
import { getFormattedEmployerData } from './Helper';

const EmployerProfile = (props: any) =>  {
  const {close} = props;
    const {width} = Dimensions.get('window');
    const accountId = getAccountId();
    const [openModal, setOpenModal] = useState({
        type: 'employerCreate',
        data: null as any,
        loading: true,
      });
  const finalWidth = getDrawerWidthByScreens({screenWidth: width, divider: 2.5});
  const [EmployerById] = useLazyQuery(EmployerQueries.getEmployerById, {
    fetchPolicy: 'no-cache'//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });

  const [getManagers] = useLazyQuery(GET_MANAGER_ROLES, {
    fetchPolicy: 'no-cache',
    variables: {
      tenantId: accountId,
    },
  });
  const dealStatusList = getMlovListByCategory('DealStatus');
  useEffect(() => {
    setOpenModal({
      type: 'employerCreate',
      data: null,
      loading: true,
    });

    LocalStorage.getItem('user')
    .then((result) => {
      const userData = JSON.parse(result);
      const updateUserData: any = userData;
      if(updateUserData?.data?.employerId){
        getEmployerById(updateUserData.data.employerId)
      }
    });
  }, []);

  const getEmployerById = async (employerId: string) => {
    const employerDataRes = await EmployerById({
      variables: {
        employerId: employerId
      },
    });
    if (employerDataRes.data?.employers?.length) {
      const managersResp = await getManagers();
      const formattedEmployerData = getFormattedEmployerData(
        employerDataRes.data?.employers,
        dealStatusList,
        managersResp.data?.users,
        ''
      );
      setOpenModal({
        type: 'employerCreate',
        data: formattedEmployerData[0],
        loading: false,
      });
    }
  };

  return (
      <Drawer
        className="headerBorder"
        width={finalWidth}
        visible={openModal?.type ? true : false}
        closeIcon={null}
        closable
        maskClosable={true}
        onClose={() => {
          setOpenModal({
            type: '',
            data: null,
            loading: false,
          });
          close && close();
        }}
      >
      {openModal.loading ? (
        <Spinner size="lg" />
      ) : (
        <EmployerCreate
          isModalOpen={openModal?.type == 'employerCreate'}
          initData={openModal?.data}
          isEmployerRoleDealEdit={isEmployerRole()}
          onModalClose={(shouldRefetch?: boolean) => {
            if (shouldRefetch) {
              //loadEmployers();
            }
            setOpenModal({
              type: '',
              data: null,
              loading: false,
            });
            close && close();
          }}
          title="quickCreate"
        />
      )}
      </Drawer>
  );
}

export default React.memo(EmployerProfile);