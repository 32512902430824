import * as htmlToImage from 'html-to-image';
import {useCallback, useRef, useState} from 'react';
import {
  ANALYTICS_RESOURCE,
  DATE_FORMATS,
  DISPLAY_TYPE,
  EMPLOYER_REPORT_FILTER_CODES
} from '../../../../../constants';
import {getSectionDataByResourceCode} from '../../../../../services/Employer/EmployerServices/EmployerServices';
import {IResource, ISectionWidgetView} from '../interface';
import ListingWidgetView from './ListingWidget/ListingWidgetView';
import WidgetBarChartView from './WidgetBarChartView';
import WidgetCardsViews from './WidgetCardTypes/WidgetCardsViews';
import WidgetPieChartView from './WidgetPieChartView';
// import * as htmlToImage from 'html-to-image';
import moment from 'moment';
import {QUICK_ACTION_CODES} from '../../../../PersonOmniView/MiddleContainer/MiddleContainerConst';
import FormListView from '../CommonView/FormListView';
import LocationFilters from '../CommonView/WidgetFilters/LocationFilters';
import {DashboardDataContext} from '../EmployerContext/DashboardContext';
import {
  APPLIED_PREVIOUS_RANGE,
  VERSES_WIDGETS,
  VIEW_CODE,
  WIDGET_ACTION_CODE
} from '../EmployerUtils/EmployerConst';
import {
  getWidgetBoxClassName,
  getWidgetBoxResourceClassName,
  isWidgetDataEmpty
} from '../EmployerUtils/WidgetUtils';
import AppointMentWidgets from './AppointMentWidgets/AppointMentWidgets';
import MixLineStackColumnChart from './MixMultipleCharts/MixLineStackColumnChart';
import MixMultiViewChart from './MixMultipleCharts/MixMultiViewChart';
import SavingsGroupView from './SavingsGroupView';
import SavingWidget from './SavingWidget';
import SavingWidgetWithPieAndLine from './SavingWidgetWithPieAndLine';
import StarRatingChartView from './StarRatingChartView';
import TrendsHorizBarWidget from './TrendsHorizBarWidget';
import TrendWidget from './TrendWidget';
import UtilizationWidgetsView from './UtilizationWidgets/UtilizationWidgetsView';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import CommunicationLogChart from '../../../../common/AppDashboard/CommunicationLogChart';

const SectionWidgetView = (props: ISectionWidgetView) => {
  const {
    employerId,
    sectionData,
    reusableData,
    sectionCode,
    onAction,
    memberType,
    sectionDisabled,
    isAccountDashboard,
    employerEnrollmentData,
    memberSatisfactionFormList,
    onWidgetLoaded
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const hintMembershipDisabled = isAccountConfigEnabled(CONFIG_CODES.CONTACT_MEMBERSHIP_DISABLED)
  const [stateData, setStateData] = useState({
    employeeTotalCount: 0,
    selectedView: '',
    selectedData: {} as any,
    filterData: {} as any
  });
  const from = VERSES_WIDGETS.includes(sectionData.resourceCode || '')
    ? props.dateRange?.fromDate
    : props.dateRange?.uiFromDate;
  const to = VERSES_WIDGETS.includes(sectionData.resourceCode || '')
    ? props.dateRange?.toDate
    : props.dateRange?.uiToDate;
  const appliedFilter: any[] = [];

  if (APPLIED_PREVIOUS_RANGE.includes(sectionData.resourceCode || '')) {
    const previousToDate: any = moment(props.dateRange?.uiFromDate)?.subtract(
      '1',
      'days'
    );
    appliedFilter.push({
      filterCode: 'DATE_RANGE',
      filterValue: {
        currentDateRange: {
          fromDate: props.dateRange?.uiFromDate && props.dateRange?.uiFromDate?.format(
            `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`
          ),
          toDate: props.dateRange?.uiToDate && props.dateRange?.uiToDate?.format(
            `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`
          ),
        },
        previousDateRange: {
          fromDate: props.dateRange?.fromDate && props.dateRange?.fromDate?.format(
            `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`
          ),
          toDate: previousToDate?.format(
            `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`
          ),
        },
      },
    });
  } else {
    appliedFilter.push({
      filterCode: 'DATE_RANGE',
      filterValue: {
        fromDate: from && from?.format(
          `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`
        ),
        toDate: to && to?.format(
          `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`
        ),
      },
    });
  }

  const getSectionByResourceCode = async (resourceCode?: string) => {
    let formId = '';
    if (sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION && sectionData?.additionalAttributes?.formId) {
      formId = sectionData?.additionalAttributes?.formId;
    }
    const response = await getSectionDataByResourceCode(
      employerId,
      resourceCode || sectionData.resourceCode || '',
      appliedFilter,
      formId,
      isAccountDashboard,
      memberType
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          resourceData: [],
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data && isWidgetDataEmpty(response?.data)) {

      let resourceData = response.data?.totalCount ? response.data : response.data?.result || response.data;
      if (response.data?.previousResults) {
        resourceData = response.data;
      }
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: [] as IResource[],
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };
  const onButtonClick = useCallback((data) => {
    const node: any = document.getElementById(data.resourceCode || sectionData.resourceCode || '');
    if (node === null) {
      return;
    }
    htmlToImage
      .toPng(node)
      .then((dataUrl) => {
        ref?.current?.setAttribute('style', 'background-color: #fff;');
        const link: any = document.createElement('a');
        document.body.appendChild(link);
        (link.download = data.resourceCode || sectionData.resourceCode || ''), (link.href = dataUrl);
        link.setAttribute('style', 'position: fixed;');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {

      });
  }, [ref]);
  const onActionPerformed = (code: string, data?: any) => {
    switch (code) {
      case WIDGET_ACTION_CODE.SELECT_LOCATION:
        setStateData(prev => {
          return {
            ...prev,
            selectedView: code,
            selectedData: data
          }
        })
        break;
      case WIDGET_ACTION_CODE.DOWNLOAD_WIDGET:
        onButtonClick(data);
        break;
      case WIDGET_ACTION_CODE.OPEN_SAVING_MODAL:
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: VIEW_CODE.SAVING_DRILL_DOWN,
            selectedResourceData: data,
          };
        });
        break;
      case WIDGET_ACTION_CODE.OPEN_CUSTOMER_SATISFACTION_MODAL:
        onAction?.(code, data);
        break;
      case QUICK_ACTION_CODES.FILTER:
        setStateData(prev => {
          return {
            ...prev,
            filterData: data,
            selectedView: '',
            selectedData: null
          }
        })
        break;
      case WIDGET_ACTION_CODE.WIDGET_LOADED:
        onAction?.(code, data);
        break
      default:
        const dashBoardDateRange = {
          filterCode: EMPLOYER_REPORT_FILTER_CODES.DASHBOARD_DATE_RANGE,
          filterValue: props?.dateRange
        }
        data?.filterData.push(dashBoardDateRange);
        onAction?.(code, data);
        break;
    }
  };

  const getWidgetView = (context: any) => {
    let ele = <></>;
    switch (sectionData.currentDisplayType) {
      case DISPLAY_TYPE.STATUS_NUMBER_LINE:
        ele = (
          <TrendWidget
            onWidgetLoaded={onWidgetLoaded}
            filterData={stateData.filterData}
            appliedFilter={appliedFilter}
            hintMembershipDisabled={hintMembershipDisabled}
            employerId={employerId}
            onImageDownloadClick={onButtonClick}
            sectionData={sectionData}
            resourceData={{}}
            reusableData={reusableData}
            onActionClick={onActionPerformed}
            isPrintPreview={props.isPrintPreview}
            isPrintDownload={props.isPrintDownload}
            isAccountDashboard={isAccountDashboard}
            memberType={memberType}
            dateRange={props.dateRange}
            memberSatisfactionFormList={memberSatisfactionFormList}
          />
        );
        break;
      case DISPLAY_TYPE.MULTIPLE_LINE_CHARTS:
      case DISPLAY_TYPE.DUAL_MULTIPLE_LINE_CHARTS:
      case DISPLAY_TYPE.MULTIPLE_LINE_CHARTS_WITH_SLIDER:
        ele = (
          <UtilizationWidgetsView
            onWidgetLoaded={onWidgetLoaded}
            filterData={stateData.filterData}
            appliedFilter={appliedFilter}
            hintMembershipDisabled={hintMembershipDisabled}
            employerId={employerId}
            employerEnrollmentData={employerEnrollmentData}
            onImageDownloadClick={onButtonClick}
            sectionData={sectionData}
            onViewCodeCallBack={(code) => {
              setStateData((prev) => {
                return {
                  ...prev,
                  selectedView: code,
                };
              });
            }}
            resourceData={{}}
            reusableData={reusableData}
            onActionClick={onActionPerformed}
            isPrintPreview={props.isPrintPreview}
            isPrintDownload={props.isPrintDownload}
            isAccountDashboard={isAccountDashboard}
            memberType={memberType}
            memberSatisfactionFormList={memberSatisfactionFormList}
          />
        );
        break;
      case 'NEEDS_ATTENTION':
        ele = (
          <CommunicationLogChart
            dashboardType={'EMPLOYER_REPORTING'}
          />
        )
        break;

      case DISPLAY_TYPE.H_BAR_CHART_WITH_TOP_ITEM:
      case DISPLAY_TYPE.HORIZONTAL_STACK_BAR_VIEW:
        ele = (
          <TrendsHorizBarWidget
            onWidgetLoaded={onWidgetLoaded}
            filterData={stateData.filterData}
            appliedFilter={appliedFilter}
            hintMembershipDisabled={hintMembershipDisabled}
            employerId={employerId}
            onImageDownloadClick={onButtonClick}
            sectionData={sectionData}
            resourceData={{}}
            reusableData={reusableData}
            onActionClick={onActionPerformed}
            isPrintPreview={props.isPrintPreview}
            isPrintDownload={props.isPrintDownload}
            isAccountDashboard={isAccountDashboard}
            memberType={memberType}
            memberSatisfactionFormList={memberSatisfactionFormList}
          />
        );
        break;
      case DISPLAY_TYPE.APPOINTMENT_WITH_GROUP_BAR_VIEW:
      case DISPLAY_TYPE.APPOINTMENT_WITH_HORIZONTAL_VIEW:
      case DISPLAY_TYPE.APPOINTMENT_WITH_HORIZONTAL_STACK_VIEW:
      case DISPLAY_TYPE.APPOINTMENT_WITH_HORIZONTAL_GROUP_VIEW:
      case DISPLAY_TYPE.HORIZONTAL_GROUP_BAR_WITH_LINE_VIEW:
      case DISPLAY_TYPE.APPOINTMENT_WITH_STACK_BAR_VIEW:
        ele = (
          <AppointMentWidgets
            onWidgetLoaded={onWidgetLoaded}
            accountPracticeLocations={context.accountPracticeLocations}
            filterData={stateData.filterData}
            appliedFilter={appliedFilter}
            hintMembershipDisabled={hintMembershipDisabled}
            employerId={employerId}
            onImageDownloadClick={onButtonClick}
            sectionData={sectionData}
            resourceData={{}}
            reusableData={reusableData}
            onActionClick={onActionPerformed}
            dateRange={context}
            isPrintPreview={props.isPrintPreview}
            isPrintDownload={props.isPrintDownload}
            isAccountDashboard={isAccountDashboard}
            memberType={memberType}
            memberSatisfactionFormList={memberSatisfactionFormList}
          />
        );
        break;
      case DISPLAY_TYPE.SAVING_WIDGET:
        ele = (<SavingWidget
          onWidgetLoaded={onWidgetLoaded}
          filterData={stateData.filterData}
          appliedFilter={appliedFilter}
          hintMembershipDisabled={hintMembershipDisabled}
          employerId={employerId}
          sectionCode={sectionCode}
          onActionClick={onActionPerformed}
          sectionData={sectionData}
          resourceData={{}}
          reusableData={reusableData}
          isPrintPreview={props.isPrintPreview}
          isPrintDownload={props.isPrintDownload}
          dateRange={props.dateRange}
          onOpenDetails={(data) => {
            // onAction?.(WIDGET_ACTION_CODE.OPEN_SAVING_MODAL, data);
            onActionPerformed(WIDGET_ACTION_CODE.OPEN_SAVING_MODAL, data)
          }}
          isAccountDashboard={isAccountDashboard}
          memberType={memberType}
          memberSatisfactionFormList={memberSatisfactionFormList}
        />)
        break;
      case DISPLAY_TYPE.SAVING_GROUP_CUSTOM:
        ele = (<SavingsGroupView
          onWidgetLoaded={onWidgetLoaded}
          filterData={stateData.filterData}
          appliedFilter={appliedFilter}
          hintMembershipDisabled={hintMembershipDisabled}
          employerId={employerId}
          sectionCode={sectionCode}
          onActionClick={onActionPerformed}
          sectionData={sectionData}
          resourceData={{}}
          reusableData={reusableData}
          isPrintPreview={props.isPrintPreview}
          isPrintDownload={props.isPrintDownload}
          dateRange={props.dateRange}
          onOpenDetails={(data) => {
            // onAction?.(WIDGET_ACTION_CODE.OPEN_SAVING_MODAL, data);
            onActionPerformed(WIDGET_ACTION_CODE.OPEN_SAVING_MODAL, data)
          }}
          isAccountDashboard={isAccountDashboard}
          memberType={memberType}
          memberSatisfactionFormList={memberSatisfactionFormList}
        />)
        break;
      case DISPLAY_TYPE.SAVING_WIDGET_LINE_PIE:
        ele = (
          <SavingWidgetWithPieAndLine
            onWidgetLoaded={onWidgetLoaded}
            filterData={stateData.filterData}
            appliedFilter={appliedFilter}
            hintMembershipDisabled={hintMembershipDisabled}
            sectionCode={sectionCode}
            onActionClick={onActionPerformed}
            sectionData={sectionData}
            resourceData={{}}
            reusableData={reusableData}
            isPrintPreview={props.isPrintPreview}
            isPrintDownload={props.isPrintDownload}
            dateRange={props.dateRange}
            employerId={employerId}
            onOpenDetails={(data) => {
              // onAction?.(WIDGET_ACTION_CODE.OPEN_SAVING_MODAL, data);
              onActionPerformed(WIDGET_ACTION_CODE.OPEN_SAVING_MODAL, data)
            }}
            isAccountDashboard={isAccountDashboard}
            memberType={memberType}
            memberSatisfactionFormList={memberSatisfactionFormList}
          />)
        break;
      case DISPLAY_TYPE.SAVING_WIDGET_LINE_PIE:
        ele = (
          <SavingWidgetWithPieAndLine
            onWidgetLoaded={onWidgetLoaded}
            filterData={stateData.filterData}
            sectionCode={sectionCode}
            onActionClick={onActionPerformed}
            sectionData={sectionData}
            resourceData={{}}
            reusableData={reusableData}
            isPrintPreview={props.isPrintPreview}
            isPrintDownload={props.isPrintDownload}
            dateRange={props.dateRange}
            onOpenDetails={(data) => {
              onAction?.(WIDGET_ACTION_CODE.OPEN_SAVING_MODAL, data);
            }}
            isAccountDashboard={isAccountDashboard}
            memberType={memberType}
            memberSatisfactionFormList={memberSatisfactionFormList}
          />
        );
        break;
      case DISPLAY_TYPE.COUNT:
      case DISPLAY_TYPE.COUNT_WITH_LINE_GRAPH:
      case DISPLAY_TYPE.COUNT_VERTICAL_WITH_LINE_GRAPH:
        ele = <WidgetCardsViews
          onWidgetLoaded={onWidgetLoaded}
          filterData={stateData.filterData}
          appliedFilter={appliedFilter}
          hintMembershipDisabled={hintMembershipDisabled}
          sectionCode={sectionCode}
          onActionClick={onActionPerformed}
          sectionData={sectionData}
          resourceData={{}}
          reusableData={reusableData}
          dateRange={props.dateRange}
          isPrintPreview={props.isPrintPreview}
          isPrintDownload={props.isPrintDownload}
          employerId={employerId}
          isAccountDashboard={isAccountDashboard}
          memberType={memberType}
          memberSatisfactionFormList={memberSatisfactionFormList}
        />
        break;
      case DISPLAY_TYPE.PIE_CHART:
        ele = (
          <WidgetPieChartView
            filterData={stateData.filterData}
            onWidgetLoaded={onWidgetLoaded}
            appliedFilter={appliedFilter}
            hintMembershipDisabled={hintMembershipDisabled}
            onImageDownloadClick={onButtonClick}
            sectionData={sectionData}
            resourceData={{}}
            reusableData={reusableData}
            onActionClick={onActionPerformed}
            dateRange={context}
            isPrintPreview={props.isPrintPreview}
            isPrintDownload={props.isPrintDownload}
            employerId={employerId}
            isAccountDashboard={isAccountDashboard}
            memberType={memberType}
            memberSatisfactionFormList={memberSatisfactionFormList}
          />
        );
        break;
      case DISPLAY_TYPE.BAR_CHART:
      case DISPLAY_TYPE.HORIZONTAL_BAR_CHART:
      case DISPLAY_TYPE.BAR_CHART_WITH_STACK_COLUMN:
      case DISPLAY_TYPE.BAR_CHART_WITH_GROUP_COLUMN:
      case DISPLAY_TYPE.BAR_CHART_WITH_STACK_AND_GROUP_COLUMN:
      case DISPLAY_TYPE.BIDIRECTIONAL_BAR_GRAPH:
      case DISPLAY_TYPE.DETAIL_WITH_BAR_CHART_WITH_STACK_COLUMN:
        ele = (
          <WidgetBarChartView
            onWidgetLoaded={onWidgetLoaded}
            accountPracticeLocations={context.accountPracticeLocations}
            filterData={stateData.filterData}
            appliedFilter={appliedFilter}
            hintMembershipDisabled={hintMembershipDisabled}
            onImageDownloadClick={onButtonClick}
            sectionData={sectionData}
            resourceData={{}}
            reusableData={reusableData}
            onActionClick={onActionPerformed}
            dateRange={context}
            isPrintPreview={props.isPrintPreview}
            isPrintDownload={props.isPrintDownload}
            employerId={employerId}
            isAccountDashboard={isAccountDashboard}
            memberType={memberType}
            memberSatisfactionFormList={memberSatisfactionFormList}
          />
        );
        break;
      case DISPLAY_TYPE.LISTING_WIDGET_WITH_LIST:
      case DISPLAY_TYPE.LISTING_WIDGET_WITH_PROGRESS:
        ele = (
          <ListingWidgetView
            onWidgetLoaded={onWidgetLoaded}
            filterData={stateData.filterData}
            appliedFilter={appliedFilter}
            hintMembershipDisabled={hintMembershipDisabled}
            onImageDownloadClick={onButtonClick}
            sectionData={sectionData}
            resourceData={{}}
            reusableData={reusableData}
            onActionClick={onActionPerformed}
            isPrintPreview={props.isPrintPreview}
            isPrintDownload={props.isPrintDownload}
            employerId={employerId}
            isAccountDashboard={isAccountDashboard}
            memberType={memberType}
            memberSatisfactionFormList={memberSatisfactionFormList}
          />
        );
        break;
      case DISPLAY_TYPE.MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE:
      case DISPLAY_TYPE.DETAIL_WITH_MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE:
        ele = (
          <MixLineStackColumnChart
            onWidgetLoaded={onWidgetLoaded}
            filterData={stateData.filterData}
            appliedFilter={appliedFilter}
            hintMembershipDisabled={hintMembershipDisabled}
            onImageDownloadClick={onButtonClick}
            sectionData={sectionData}
            resourceData={{}}
            reusableData={reusableData}
            onActionClick={onActionPerformed}
            dateRange={context}
            isPrintPreview={props.isPrintPreview}
            isPrintDownload={props.isPrintDownload}
            employerId={employerId}
            isAccountDashboard={isAccountDashboard}
            memberType={memberType}
            memberSatisfactionFormList={memberSatisfactionFormList}
          />
        );
        break;
      case DISPLAY_TYPE.STAR_RATING_CHART:
        ele = (
          <StarRatingChartView
            onWidgetLoaded={onWidgetLoaded}
            filterData={stateData.filterData}
            appliedFilter={appliedFilter}
            hintMembershipDisabled={hintMembershipDisabled}
            onImageDownloadClick={onButtonClick}
            sectionData={sectionData}
            resourceData={{}}
            reusableData={reusableData}
            onActionClick={onActionPerformed}
            dateRange={props.dateRange}
            isPrintPreview={props.isPrintPreview}
            isPrintDownload={props.isPrintDownload}
            employerId={employerId}
            isAccountDashboard={isAccountDashboard}
            memberType={memberType}
            memberSatisfactionFormList={memberSatisfactionFormList}
          />
        );
        break;
      case DISPLAY_TYPE.MULTI_PLOT_VIEW_CHART:
        ele = (
          <MixMultiViewChart
            onWidgetLoaded={onWidgetLoaded}
            filterData={stateData.filterData}
            appliedFilter={appliedFilter}
            hintMembershipDisabled={hintMembershipDisabled}
            onImageDownloadClick={onButtonClick}
            sectionData={sectionData}
            resourceData={{}}
            reusableData={reusableData}
            onActionClick={onActionPerformed}
            dateRange={context}
            isPrintPreview={props.isPrintPreview}
            isPrintDownload={props.isPrintDownload}
            employerId={employerId}
            isAccountDashboard={isAccountDashboard}
            memberType={memberType}
            memberSatisfactionFormList={memberSatisfactionFormList}
          />
        );
        break;
    }
    return ele;
  };

  return (
    <>
      <div
        id={sectionData.resourceCode === ANALYTICS_RESOURCE.TOTAL_CLAIM_COST
          ? ANALYTICS_RESOURCE.CLAIMS
          : sectionData.resourceCode
        }
        className={`${getWidgetBoxClassName(
          sectionCode || '',
          sectionData.resourceCode || ''
        )} ${getWidgetBoxResourceClassName(
          sectionData.resourceCode || ''
        )}  ${sectionCode} print-indivisible  ${props.isPrintPreview || props.isPrintDownload ? 'in-print-drawer' : ''} `}
        style={
          (!props?.sectionData?.isDisabled || !props.isPrintPreview) && (!sectionDisabled || !props.isPrintPreview)
            ? {
              opacity: 1,
              background: 'transparent'
            }
            : {
              opacity: 0.3,
              filter: "grayscale(1)",
              background: 'transparent'
            }
        }
      >
        <DashboardDataContext.Consumer>
          {(context) => {
            return (
              <>
                {getWidgetView(context)}
              </>
            )
          }}
        </DashboardDataContext.Consumer>
      </div>

      {stateData.selectedView === WIDGET_ACTION_CODE.SELECT_LOCATION && (
        <LocationFilters
          data={stateData.selectedData}
          onClose={() => {
            setStateData(prev => {
              return {
                ...prev,
                selectedView: '',
                selectedData: null
              }
            })
          }}
          onActionPerformed={onActionPerformed}
        />
      )}
      {stateData.selectedView === WIDGET_ACTION_CODE.FORM_LIST_VIEW ? (
        <FormListView
          sectionData={sectionData}
          onClose={() => {
            setStateData((prev) => {
              return {
                ...prev,
                selectedView: ''
              };
            });
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SectionWidgetView;
