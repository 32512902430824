
import {IAppliedFilter, IMixLineStackColumnChartResourceData, IWidgetCardView} from '../../interface';
import GaugeChartView from '../GaugeCharts/GaugeCharts';
import {Colors} from '../../../../../../styles';
import {INotEngagedInMonths} from './interface';
import ArrowRightSvg from '../../../../../common/Svg/ArrowRightSvg';
import {ANALYTICS_RESOURCE} from '../../../../../../constants';
export const getEngageForCareElem = (
  props: IWidgetCardView,
  stateData: {
    resourceData: IMixLineStackColumnChartResourceData;
    loading: boolean; employeeTotalCount: number;
    selectedView: string;
    selectedResourceData: any;
    masterData: any;
    appliedFilter: IAppliedFilter[] | undefined;
    filterValueName: string[];
    filterValueString: string;
    selectedAppliedFilter: any[];
    graphWrapperHeight: number;
    formId: string;
  },
  getMixChartView: () => JSX.Element,
  ele: JSX.Element,
  onNotEngagedInMonths: (singleMonthData: INotEngagedInMonths) => void
) => {

  const mixChartView = getMixChartView();
  const finalResourceData: any = stateData?.resourceData;
  // TEMP HANDLING FOR PATIENT_ABSENCE_ANALYSIS WIDGET || WILL REMOVE once finalize
  const isChartExist =
    props.sectionData?.resourceCode ===
      ANALYTICS_RESOURCE.PATIENT_ABSENCE_ANALYSIS || props.sectionData?.resourceCode === ANALYTICS_RESOURCE.NOT_ENROLLED_FOR_CARE;
  const formAdditionalData = finalResourceData?.additionalData;
  const notEngagedFromMonthData: INotEngagedInMonths[] = formAdditionalData
  ?.engagedFromMonthData?.length
  ? formAdditionalData?.engagedFromMonthData
  : formAdditionalData?.notEngagedFromMonthData?.length
  ? formAdditionalData?.notEngagedFromMonthData
  : [];
  const activeMemberCount: number = formAdditionalData?.activeMemberCountData?.result.totalCount;
  const getCountAndPercentageElem = (singleMonthData: INotEngagedInMonths) => {
    if (activeMemberCount && singleMonthData?.countNum && singleMonthData?.currentEngagedPercentage) {
      return (
        <div style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <div style={{
              color: '#000',
              fontFamily: 'Manrope',
              fontSize: 18,
              fontWeight: 600,
            }}>
              {singleMonthData?.currentEngagedPercentage}%
            </div>
            <div>•</div>
            <div style={{
              color: Colors.Custom.Gray500,
              fontFamily: 'Manrope',
              fontSize: 12,
              fontWeight: 600,
            }}>
              {singleMonthData?.countNum} / {singleMonthData?.totalActiveMemberCount}
            </div>
          </div>
          <div
            style={{width: 20, height: 20, cursor: 'pointer'}}
            onClick={() => {
              onNotEngagedInMonths(
                singleMonthData
              );
            }}
          >
            <ArrowRightSvg customStrokeColor="black"></ArrowRightSvg>
          </div>
        </div>
      );
    } else if (activeMemberCount && singleMonthData?.countNum && !singleMonthData?.currentEngagedPercentage) {
      return (
        <div style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <div style={{
              color: Colors.Custom.Gray500,
              fontFamily: 'Manrope',
              fontSize: 12,
              fontWeight: 600,
            }}>
              {singleMonthData?.countNum} / {singleMonthData?.totalActiveMemberCount}
            </div>
          </div>
          <div style={{
            width: 20,
            height: 20,
            cursor: 'pointer',
          }}
            onClick={() => {
              onNotEngagedInMonths(
                singleMonthData
              );
            }}
          >
            <ArrowRightSvg customStrokeColor="black"></ArrowRightSvg>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div style={{
            color: '#000',
            fontFamily: 'Manrope',
            fontSize: 18,
            fontWeight: 600,
          }}>No Data</div>
        </div>

      )
    }
  }
  const mainEleContainer = (
    <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
      <div style={{width: !isChartExist ? '33%' : '100%'}}>
        <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', marginTop: 12, justifyContent: 'space-between'}}>
          {notEngagedFromMonthData?.map((item: any) => {
            return (
              <div style={{
                padding: 16,
                borderWidth: 1,
                borderColor: Colors.Custom.Gray200,
                borderStyle: 'solid',
                borderRadius: 8,
                marginBottom: 8,
                minWidth: '210px',
              }} className='score-detail-item'>
                <div style={{
                  color: Colors.Custom.Gray500,
                  fontFamily: 'Manrope',
                  fontSize: 12,
                  fontWeight: 600,
                }}>{item?.displayText}</div>
                <div style={{
                  color: '#000',
                  fontFamily: 'Manrope',
                  fontSize: 18,
                  fontWeight: 600,
                }}>{getCountAndPercentageElem(item)}</div>
              </div>
            );
          })}
        </div>
      </div>
      {!isChartExist ? <div style={{width: '65%'}}>
        {mixChartView}
      </div> : <></>}
    </div>);
  ele = mainEleContainer;
  return ele;
}

