import {AxiosResponse} from 'axios';
import {ISingleProductApiRespData} from '../../components/RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/interface';
import {ITaxonsApiResp} from '../../components/RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/SearchComponents/interfaces';
import {IProductResp} from '../../components/RightSideContainer/Sales/ProductsAndServices/Products/ProductTableView/ProductTable/interfaces';
import {COMMERCE_API_PATH} from '../../constants/BaseUrlConst';
import {addQueryParamsInApiPath} from '../../utils/commonUtils';
import CommerceCommonService from '../CommonService/CommerceCommonService';
import {
  IOptionTypesListQueryParams,
  IProductListQueryParams,
  ITaxonsListQueryParams,
  IVariantListQueryParams,
} from './interfaces';
import {IProductVariantResp} from './interfaces/ProductVariant';
import {
  IAddOrUpdateStockBodyData,
  IStockItemDataApiResp,
} from './interfaces/StockItem';
import {IStockLocationResp} from './interfaces/StockLocation';

const commerceCommonService =
  CommerceCommonService.getCommerceCommonServiceInstance();
const productService = commerceCommonService.axiosService;
export const getProductList = async (
  queryParams: IProductListQueryParams,
  successHandler: any,
  errorHandler: any,
  type: string
) => {
  const apiPath = addQueryParamsInApiPath(
    COMMERCE_API_PATH.PRODUCT_LIST,
    queryParams
  );
  productService
    .get(apiPath)
    .then((result: AxiosResponse<IProductResp>) => {
      successHandler(result?.data || {});
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export const getProductDetails = async (
  productId: any,
  successHandler: any,
  errorHandler: any,
  type?: string
) => {
  const apiPath = `${COMMERCE_API_PATH.PRODUCT_DETAILS}/${productId}?include=default_variant,variants,option_types,product_properties,taxons,primary_variant,images`;
  productService
    .get(apiPath)
    .then((result: AxiosResponse<ISingleProductApiRespData>) => {
      successHandler(result?.data || {});
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export const addOrUpdateProduct = async (
  productData: any,
  successHandler: any,
  errorHandler: any,
  productId?: any
) => {
  let apiPath = COMMERCE_API_PATH.ADD_OR_UPDATE;
  if (productId) {
    apiPath += `/${productId}`;
    productService
      .patch(apiPath + '?include=default_variant,variants,option_types,product_properties,taxons,primary_variant,images', productData)
      .then((result: AxiosResponse<ISingleProductApiRespData>) => {
        successHandler(result?.data || {});
      })
      .catch((error) => {
        errorHandler(error);
      });
  } else {
    productService
      .post(apiPath, {...productData})
      .then((result: AxiosResponse<ISingleProductApiRespData>) => {
        successHandler(result?.data || {});
      })
      .catch((error) => {
        errorHandler(error);
      });
  }
};

export const isValidTaxonResponse = (response?: ITaxonsApiResp) => {
  return (
    response &&
    Object.keys(response) &&
    Object.keys(response).length &&
    response?.data &&
    response?.data?.length
  );
};

export const getTaxonsList = (queryParams: ITaxonsListQueryParams) => {
  const apiPath = addQueryParamsInApiPath(
    COMMERCE_API_PATH.TAXONS_LIST,
    queryParams
  );
  return productService.get(apiPath);
};

export const getOptionTypeList = (queryParams: IOptionTypesListQueryParams) => {
  const apiPath = addQueryParamsInApiPath(
    COMMERCE_API_PATH.OPTION_TYPE_LIST,
    queryParams
  );
  return productService.get(apiPath);
};

export const getVariantList = (
  queryParams: IVariantListQueryParams
): Promise<AxiosResponse<IProductVariantResp>> => {
  const apiPath = addQueryParamsInApiPath(
    COMMERCE_API_PATH.VARIANTS_LIST,
    queryParams
  );
  return productService.get(apiPath);
};

export const getStockLocations = (): Promise<
  AxiosResponse<IStockLocationResp>
> => {
  return productService.get(COMMERCE_API_PATH.STOCK_LOCATIONS);
};

export const addOrUpdateStock = (
  stockItemPayload: IAddOrUpdateStockBodyData,
  stockId?: string
): Promise<AxiosResponse<IStockItemDataApiResp>> => {
  let apiPath = COMMERCE_API_PATH.STOCK_ITEMS;
  if (stockId) {
    apiPath += `/${stockId}`;
    return productService.patch(apiPath, stockItemPayload);
  } else {
    return productService.post(apiPath, stockItemPayload);
  }
};

export const getStockItems = (
  stockId: string
): Promise<AxiosResponse<IStockLocationResp>> => {
  const apiPath = COMMERCE_API_PATH.STOCK_ITEMS + '/' + stockId;
  return productService.get(apiPath);
};

export const deleteStockItems = (
  stockId: string
): Promise<AxiosResponse<IStockItemDataApiResp>> => {
  const apiPath = COMMERCE_API_PATH.STOCK_ITEMS + '/' + stockId;
  return productService.delete(apiPath);
};
