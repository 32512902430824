import {Select, Skeleton, View} from 'native-base';
import React, {useEffect} from 'react';
import {
  ANALYTICS_RESOURCE,
  DRILL_DOWN_FILTERS,
  DRILL_DOWN_FILTERS_MAP,
} from '../../../../../constants';
import {getCommunicationTypes} from '../../../TeamInbox/Conversations/CommunicationTypes/CommunicationTypesUtils';

interface IDrillDownFiltersProps {
  resourceCode: string;
  onValueChange: (data: {filterCode: string; filterValue: any}) => void;
}

const DrillDownFilters = (props: IDrillDownFiltersProps) => {
  const {resourceCode, onValueChange} = props;
  const [state, setState] = React.useState({
    loading: false,
    filterData: {} as {[index in string]: any[]},
  });

  const drillDownFilterList = DRILL_DOWN_FILTERS_MAP[props.resourceCode] || [];
  const getCommunicationTypeData = async () => {
    const response = await getCommunicationTypes({pageSize: 100});
    const communicationTypes = response.data.communicationTypes || [];
    const filterData = state.filterData;
    filterData[DRILL_DOWN_FILTERS.COMMUNICATION_TYPE_DROPDOWN] =
      communicationTypes;
    setState((prev) => {
      return {
        ...prev,
        filterData,
      };
    });
  };

  const setDataByResourceCode = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    switch (resourceCode) {
      case ANALYTICS_RESOURCE.COMMUNICATION_STATS:
        await getCommunicationTypeData();
        break;
    }
    setState((prev) => {
      return {
        ...prev,
        loading: false,
      };
    });
  };

  const getFilterElements = (): JSX.Element[] => {
    const result: JSX.Element[] = [];
    drillDownFilterList.forEach((item) => {
      switch (item) {
        case DRILL_DOWN_FILTERS.COMMUNICATION_TYPE_DROPDOWN:
          result.push(
            <View
              style={{
                width: 200,
              }}
            >
              <Select
                placeholder="Communication Type (All)"
                onValueChange={(value) => {
                  onValueChange({
                    filterCode: DRILL_DOWN_FILTERS.COMMUNICATION_TYPE_DROPDOWN,
                    filterValue: value,
                  });
                }}
              >
                {(
                  state.filterData[
                    DRILL_DOWN_FILTERS.COMMUNICATION_TYPE_DROPDOWN
                  ] || []
                ).map((item) => {
                  return (
                    <Select.Item value={item.id || ''} label={item.name} />
                  );
                })}
              </Select>
            </View>
          );
      }
    });

    return result;
  };

  useEffect(() => {
    setDataByResourceCode();
  }, []);

  if (drillDownFilterList.length === 0) {
    return <></>;
  }

  return (
    <View>
      {state.loading ? (
        <Skeleton.Text lines={2} />
      ) : (
        <>{getFilterElements().map((item) => item)}</>
      )}
    </View>
  );
};

export default DrillDownFilters;
