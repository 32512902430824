import {Table} from 'antd';
import {ScrollView, View} from 'native-base';
import React, {useEffect} from 'react';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {Colors} from '../../../../styles/Colors';
import {TableWrapper} from '../../../common/TableWrapper';
import {CMSLoading} from '../CMSLoading';
import {
  getSortString,
  getTemplateCategories,
  getTemplateCategoryList,
} from '../ContentManagementUtils';
import {getCategoryQueryString} from '../EmailTemplates/EmailTemplatesUtils';
import {ITemplateCategory} from '../EmailTemplates/interfaces';
import {AddOrUpdateSMSTemplates} from './AddOrUpdateSMSTemplates';
import {DeleteSMSTemplate} from './DeleteSMSTemplate';
import {getSMSTemplatesTableColumns} from './Helper';
import {
  ISMSTemplateTableColumnArgs,
  SingleSMSTemplate,
  SMSTemplateApiResponse,
  SMSTemplatesMetaData,
  SMSTemplateTableState,
} from './interfaces';
import {SMSTemplatesViewCodes} from './SMSTemplatesConsts';
import {getFormattedSMSTemplates, getSMSTemplates} from './SMSTemplatesUtils';
import { filteredFormsCategoryList } from '../../../../utils/commonUtils';

export interface SMSTemplatesProps {
  selectedRow: SingleSMSTemplate;
  viewCode: string;
  searchString: string;
  onActionPerformed: (actionCode: string, record: SingleSMSTemplate) => void;
}

const SMSTemplates = (props: SMSTemplatesProps) => {
  const {selectedRow, viewCode, onActionPerformed, searchString} = props;

  const {height} = Dimensions.get('window');

  const [tableState, setTableState] = React.useState<SMSTemplateTableState>({
    apiData: {} as SMSTemplateApiResponse,
    metaData: {} as SMSTemplatesMetaData,
    page: 1,
    pageSize: 10,
    categoryList: [] as ITemplateCategory[],
    displayList: [] as SingleSMSTemplate[],
    isLoading: true,
    isNoDataFound: false,
    selectedCategories: [],
    sort: 'desc',
    tableSortOrderString: 'descend',
  });

  const setLoading = (loading: boolean) => {
    setTableState((prev) => {
      return {
        ...prev,
        isLoading: loading,
      };
    });
  };

  const categoryQueryString = getCategoryQueryString(
    tableState.categoryList,
    tableState.selectedCategories
  );

  const getData = () => {
    setLoading(true);
    const smsTemplatePromise = getSMSTemplates(categoryQueryString, {
      name: searchString,
      page: tableState.page,
      pageSize: tableState.pageSize,
      sort: tableState.sort,
    });

    const categoryPromise = getTemplateCategories();

    Promise.all([smsTemplatePromise, categoryPromise]).then((response) => {
      const list = getFormattedSMSTemplates(response[0]);
      const categoryList = getTemplateCategoryList(response[1]);
      const filteredCategories = filteredFormsCategoryList(categoryList)
      setTableState((prev) => {
        return {
          ...prev,
          apiData: response[0].data,
          metaData: response[0].meta,
          categoryList: filteredCategories,
          displayList: list,
          isLoading: false,
        };
      });
    });
  };

  const getSMSTemplatesAsPerFilters = async () => {
    setLoading(true);
    const response = await getSMSTemplates(categoryQueryString, {
      name: searchString,
      page: tableState.page,
      pageSize: tableState.pageSize,
      sort: tableState.sort,
    });
    const list = getFormattedSMSTemplates(response);
    setTableState((prev) => {
      return {
        ...prev,
        apiData: response.data,
        metaData: response.meta,
        displayList: list,
        isLoading: false,
      };
    });
  };

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    try {
      getSMSTemplatesAsPerFilters();
    } catch (error) {
      setLoading(false);
    }
  }, [
    tableState.page,
    tableState.pageSize,
    searchString,
    tableState.selectedCategories,
  ]);

  const tableColumnArgs: ISMSTemplateTableColumnArgs = {
    actionFn: onActionPerformed,
    categories: tableState.categoryList.map((item) => ({
      text: item.name,
      value: item.name,
    })),
    defaultFilteredValues: tableState.selectedCategories as string[],
    defaultSortOrder: tableState.tableSortOrderString,
  };

  const getViewFromSelectedCode = () => {
    switch (viewCode) {
      case SMSTemplatesViewCodes.LIST:
      case SMSTemplatesViewCodes.ADD:
      case SMSTemplatesViewCodes.EDIT:
      case SMSTemplatesViewCodes.DELETE:
        return (
          <View bgColor={'white'} borderRadius={0}>
            {tableState.displayList && !tableState.isLoading && (
              <TableWrapper
                hideOnSinglePage={false}
                pagination={{
                  current: tableState.page,
                  pageSize: tableState.pageSize,
                  total: tableState.metaData?.pagination?.total,
                  onChange(page, pageSize) {
                    setTableState((prev) => {
                      return {
                        ...prev,
                        page: page,
                        pageSize: pageSize,
                      };
                    });
                  },
                }}
              >
                <Table
                  rowClassName={(record, index) =>
                    index % 2 == 0 ? 'table-row-light' : ''
                  }
                  dataSource={tableState.displayList || []}
                  columns={getSMSTemplatesTableColumns(tableColumnArgs)}
                  rowKey={(row) => row.id}
                  scroll={{x: 700, y: height - 280}}
                  onRow={(data) => {
                    return {
                      onClick: () => {
                        onActionPerformed(COMMON_ACTION_CODES.EDIT, data);
                      },
                    };
                  }}
                  onChange={(_, filters, sorter: any) => {
                    const sortOrderString = getSortString(sorter?.order);
                    const categories = filters.category;
                    setTableState((prev) => {
                      return {
                        ...prev,
                        selectedCategories: categories,
                        sort: sortOrderString,
                        tableSortOrderString: sorter?.order,
                      };
                    });
                  }}
                  pagination={false}
                />
              </TableWrapper>
            )}

            {viewCode === SMSTemplatesViewCodes.ADD && (
              <AddOrUpdateSMSTemplates
                categoryList={tableState.categoryList}
                onFormCompleteAction={(actionCode) => {
                  if (actionCode === COMMON_ACTION_CODES.ADDED_OR_UPDATED) {
                    onActionPerformed(actionCode, selectedRow);
                    getSMSTemplatesAsPerFilters();
                  } else {
                    onActionPerformed(actionCode, selectedRow);
                  }
                }}
              />
            )}

            {viewCode === SMSTemplatesViewCodes.EDIT && (
              <AddOrUpdateSMSTemplates
                categoryList={tableState.categoryList}
                singleSMSTemplate={selectedRow}
                onFormCompleteAction={(actionCode) => {
                  if (actionCode === COMMON_ACTION_CODES.ADDED_OR_UPDATED) {
                    onActionPerformed(actionCode, selectedRow);
                    getSMSTemplatesAsPerFilters();
                  } else {
                    onActionPerformed(actionCode, selectedRow);
                  }
                }}
              />
            )}

            {viewCode === SMSTemplatesViewCodes.DELETE && (
              <DeleteSMSTemplate
                singleSMSTemplate={selectedRow}
                onFormCompleteAction={(actionCode) => {
                  if (actionCode === COMMON_ACTION_CODES.LIST_VIEW) {
                    getSMSTemplatesAsPerFilters();
                    onActionPerformed(actionCode, selectedRow);
                  } else {
                    onActionPerformed(actionCode, selectedRow);
                  }
                }}
              />
            )}

            {tableState.isLoading && <CMSLoading />}
          </View>
        );
    }
  };

  return <>{getViewFromSelectedCode()}</>;
};

export default SMSTemplates;
