import {HStack, Text} from 'native-base';
import Icon from 'react-native-vector-icons/AntDesign';
import {ICampaignTableTotal} from '../../interfaces';
import {styles} from './TableComponentsStyles';

export const CampaignTableTotal = (props: ICampaignTableTotal) => {
  return (
    <HStack style={styles.centerAlign} space={1}>
      <Icon name="CodeSandbox" size={10} color={'blue'} />
      <Text bold color={'blue.600'}>
        {props.total}
      </Text>
    </HStack>
  );
};
