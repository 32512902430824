import {HStack, Text, Tooltip, View, VStack} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import PlusIcon from '../../../../common/Svg/PlusSvg';
import {
  ITableTopBarProps,
  TABLE_TOP_BAR_ACTION_CODES,
} from '../../../../common/TableTopBar';
import ActionView from './ActionView';
import {COST_TYPE_CODE} from './helperFiles/CostTemplatesConst';
import {ICostSavingTemplateTable} from './interfaces/savingTemplatesInterface';

export const getPackagesTopBarButtons = (
  onActionPerformed: (actioncode: any, rawData: any) => void
): ITableTopBarProps => {
  return {
    title: '',
    buttonList: [
      {
        id: 0,
        btnText: `new`,
        size: 'sm',
        colorScheme: 'primary',
        backgroundColor: Colors.secondary['100'],
        borderColor: Colors.secondary['200'],
        textColor: Colors.secondary['800'],
        variant: '',
        leftIcon: <PlusIcon />,
        btnClick: () => {
          onActionPerformed(TABLE_TOP_BAR_ACTION_CODES.NEW_PACKAGE, '');
        },
      },
    ],
  };
};

export const getCostSavingTemplateTableColumns = (
  onActionPerformed: (actionCode: string, rowData: any) => void
): any[] => {
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="ctemplateName"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      fixed: 'left',
      ellipsis: true,
      render: (
        name: string,
        selectedRecord: ICostSavingTemplateTable
      ): JSX.Element => {
        return (
          <HStack
            style={{
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <VStack flex={1}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Tooltip label={name} placement="top">
                  <Text
                    noOfLines={1}
                    size={'smSemibold'}
                    style={{
                      lineHeight: 24,
                      color: '#000',
                    }}
                  >
                    {name}
                  </Text>
                </Tooltip>
                <View
                  style={{
                    paddingHorizontal: 4,
                    paddingVertical: 1,
                    borderRadius: 4,
                    backgroundColor: Colors.Custom.Gray100,
                    marginLeft: 8,
                    display: selectedRecord.isDefault ? 'flex' : 'none',
                  }}
                >
                  <Text
                    size={'xsMedium'}
                    style={{color: Colors.Custom.Gray400}}
                  >
                    Default
                  </Text>
                </View>
              </View>
              <Text
                noOfLines={2}
                size={'xsMedium'}
                style={{
                  color: Colors.Custom.Gray500,
                  lineHeight: 21,
                }}
              >
                Updated on {selectedRecord.updatedOn}
              </Text>
            </VStack>
          </HStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="description"
        />
      ),
      dataIndex: 'description',
      key: 'description',
      width: '35%',
      render: (description: string): JSX.Element => {
        return (
          <View flex={1}>
            <Text
              noOfLines={2}
              size={'smSemibold'}
              style={{
                color: Colors.Custom.Gray500,
                lineHeight: 21,
              }}
            >
              {description || '--'}
            </Text>
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="costType"
        />
      ),
      dataIndex: 'costType',
      key: 'costType',
      width: '10%',
      render: (costType: string): JSX.Element => {
        const costTypeText =
          costType == COST_TYPE_CODE.MARKET_COST
            ? 'Market Cost'
            : costType == COST_TYPE_CODE.OUR_COST
            ? 'Our Service Cost'
            : '';
        return (
          <View width={250} flex={1}>
            <Text
              noOfLines={2}
              style={{
                color: Colors.Custom.Gray500,
                fontSize: 14,
                fontWeight: '400',
                lineHeight: 21,
              }}
            >
              {costTypeText}
            </Text>
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="assignedTo"
        />
      ),
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      width: '10%',
      render: (assignedTo: number): JSX.Element => {
        return (
          <HStack
            style={{
              alignItems: 'center',
              paddingHorizontal: 4,
              paddingVertical: 1,
              borderRadius: 4,
              backgroundColor: Colors.Custom.Gray100,
              width: 'fit-content',
            }}
          >
            <Feather name="briefcase" size={16} color={Colors.Custom.Gray400} />
            <Text
              color={Colors.Custom.Gray500}
              fontWeight={500}
              fontSize={14}
              marginLeft={1}
            >
              {assignedTo}
            </Text>
          </HStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId=" "
        />
      ),
      key: 'action',
      width: '10%',
      fixed: 'right',
      onCell: () => {
        return {
          onClick: (e: Event) => {
            e.stopPropagation();
          },
        };
      },
      render: (text: any, selectedRecord: ICostSavingTemplateTable) => {
        return (
          <>
            <ActionView
              selectedRecord={selectedRecord}
              onActionClick={(actionCode: string): any => {
                onActionPerformed(actionCode, selectedRecord);
              }}
              isDefault={selectedRecord.isDefault}
            />
          </>
        );
      },
      responsive: ['md'],
    },
  ];
};
