import {HStack, IconButton, Tooltip} from 'native-base';
import Icon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import { Colors } from '../../../../../styles';
import {IResponseTableActionsProps} from '../interfaces';

export const ResponseTableActions = (props: IResponseTableActionsProps) => {
  return (
    <HStack>
      <Tooltip label={'Edit'} openDelay={200} placement="top">
        <IconButton
          icon={<Feather name="edit-2" size={17} color={Colors.Custom.Gray600} />}
          _hover={{background: 'none'}}
          onPress={() => {
            props.onActionPerformed(COMMON_ACTION_CODES.EDIT, props.record);
          }}
        />
      </Tooltip>
      <Tooltip label={'Delete'} openDelay={200} placement="top">
        <IconButton
          icon={<Icon name="delete" size={17} color={Colors.Custom.Gray600} />}
          _hover={{background: 'none'}}
          onPress={() => {
            props.onActionPerformed(COMMON_ACTION_CODES.DELETE, props.record);
          }}
        />
      </Tooltip>
    </HStack>
  );
};
