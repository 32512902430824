import { ColumnsType } from 'antd/lib/table';
import { Text, View } from 'react-native';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../../styles';
import PlusIcon from '../../../../common/Svg/PlusSvg';
import {TABLE_TOP_BAR_ACTION_CODES} from '../../../../common/TableTopBar';
import {TagsActions} from '../../../Contacts/Tags/TagsTable/TagsActions';
import {ITagsTableData} from '../../../Contacts';
import {PACKAGE_MLOV} from './PackagesConst';

export const getPackagesLabelTopBarButtons = (
  onActionPerformed: (actionCode: string, rawData: ITagsTableData) => void
) => {
  return {
    title: '',
    buttonList: [
      {
        id: 0,
        btnText: `new`,
        size: 'sm',
        colorScheme: 'primary',
        backgroundColor: Colors.secondary['100'],
        borderColor: Colors.secondary['200'],
        textColor: Colors.secondary['800'],
        variant: '',
        leftIcon: <PlusIcon />,
        btnClick: () => {
          onActionPerformed(TABLE_TOP_BAR_ACTION_CODES.NEW_LABEL, {} as ITagsTableData);
        },
      },
    ],
  };
};

export const getPackagesLabelsColumns = (
  onCustomFieldActionPerformed: (actionCode: string, record: ITagsTableData) => any
) => {
  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{
            color: Colors.Custom.Gray500,
            fontWeight: 500,
            fontSize: 14,
            marginLeft: 8
          }}
          textLocalId="name"
        />
      ),
      dataIndex: 'title',
      key: 'title',
      fixed: 'left',
      width: '12%',
      render: (
        title: string,
      ) => (
        <Text
          style={{
            fontSize: 16,
            fontWeight: '500',
            color: Colors.Custom.Gray600
          }}
        >
          {title}
        </Text>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{
            color: Colors.Custom.Gray500,
            fontWeight: 500,
            fontSize: 14,
            marginLeft: 8
          }}
          textLocalId="description"
        />
      ),
      dataIndex: 'description',
      key: 'description',
      width: '23%',
      render: (description: string) => (
        <Text
          style={{
            fontSize: 16,
            fontWeight: '500',
            color: Colors.Custom.Gray600
          }}
        >
          {description}
        </Text>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{
            color: Colors.Custom.Gray500,
            fontWeight: 500,
            fontSize: 14,
            marginLeft: 8
          }}
          textLocalId="color"
        />
      ),
      dataIndex: 'color',
      key: 'color',
      width: '15%',
      render: (color: string) => (
        <View style={{
          justifyContent: 'flex-start',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
          <View
            style={{
              width: 20,
              height: 20,
              backgroundColor: color,
              borderRadius: 4,
            }}
          ></View>
          <Text style={{
              color: Colors.Custom.Gray900,
              marginLeft: 6,
            }}
          >
            {color}
          </Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500,
            fontWeight: 500,
            fontSize: 14,
            marginLeft: 8
          }}
          textLocalId="actions"
        />
      ),
      key: 'actions',
      fixed: 'right',
      width: '10%',
      render: (record: ITagsTableData) => {
        return (
          <View>
            <TagsActions
              onActionPerformed={onCustomFieldActionPerformed}
              record={record}
              mlovCategory={PACKAGE_MLOV}
            />
          </View>
        );
      },
    },
  ];
  return columns;
};