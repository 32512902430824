import {Table} from 'antd';
import {ScrollView, View} from 'native-base';
import React from 'react';
import {Dimensions} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {Colors} from '../../../../styles';
import {TableWrapper} from '../../../common/TableWrapper';
import {CMSLoading} from '../CMSLoading';
import {getSortString} from '../ContentManagementUtils';
import {ITemplateCategory} from '../EmailTemplates/interfaces';
import {DeleteEmailComponent} from './DeleteEmailComponent';
import {EmailComponentsController} from './EmailComponentsUtils';
import {getEmailComponentTableColumns} from './Helper';
import {
  EmailComponentApiResponse,
  EmailComponentMetaData,
  EmailComponentsProps,
  EmailComponentsState,
  EmailComponentsTableColumnArgs,
  SingleEmailComponent,
} from './interfaces';

const EmailComponents = (props: EmailComponentsProps) => {
  const {searchString} = props;
  const [state, setState] = React.useState<EmailComponentsState>({
    apiData: {} as EmailComponentApiResponse,
    metaData: {} as EmailComponentMetaData,
    componentTypeList: [] as ITemplateCategory[],
    displayList: [] as SingleEmailComponent[],
    loading: false,
    page: 1,
    pageSize: 9,
    sort: 'desc',
    tableSortOrderString: 'ascend',
    deleteModalOpen: false,
    selectedRow: {} as SingleEmailComponent,
    selectedComponentTypes: [],
  });

  const {height} = Dimensions.get('window');
  const navigate = useNavigate();

  const {
    showLoading,
    showTable,
    onMount,
    onChangeFilters,
    onDeleteComponent,
    onTableActionPerformed,
  } = new EmailComponentsController({
    searchString: searchString || '',
    state,
    navigate,
    setState,
  });

  React.useEffect(() => {
    onMount();
  }, []);

  React.useEffect(() => {
    onChangeFilters();
  }, [state.page, state.pageSize, searchString, state.selectedComponentTypes]);

  const tableColumnArgs: EmailComponentsTableColumnArgs = {
    actionFn(actionCode, record) {
      onTableActionPerformed(actionCode, record);
    },
    componentTypes: state.componentTypeList.map((item) => {
      return {
        text: item.name,
        value: item.code,
      };
    }),
    filteredValues: state.selectedComponentTypes,
  };

  return (
    <View height={height - 140}>
      <View
        borderRadius={0}
        style={{
          backgroundColor: 'white',
        }}
      >
        {showTable && (
          <TableWrapper
            hideOnSinglePage={false}
            pagination={{
              current: state.page,
              pageSize: state.pageSize,
              total: state.metaData?.pagination?.total,
              onChange(page, pageSize) {
                setState((prev) => {
                  return {
                    ...prev,
                    page: page,
                    pageSize: pageSize,
                  };
                });
              },
            }}
          >
            <Table
              rowClassName={(record, index) =>
                index % 2 == 0 ? 'table-row-light' : ''
              }
              dataSource={state.displayList || []}
              columns={getEmailComponentTableColumns(tableColumnArgs)}
              rowKey={(row) => row.id}
              scroll={{x: 700, y: height - 312}}
              onRow={(data) => {
                return {
                  onClick: () => {
                    onTableActionPerformed(
                      COMMON_ACTION_CODES.ROW_CLICKED,
                      data
                    );
                  },
                };
              }}
              onChange={(_, filters, sorter: any) => {
                const sortOrderString = getSortString(sorter?.order);
                const selectedComponentTypes =
                  filters.componentType as string[];
                setState((prev) => {
                  return {
                    ...prev,
                    sort: sortOrderString,
                    tableSortOrderString: sorter?.order,
                    selectedComponentTypes: selectedComponentTypes,
                  };
                });
              }}
              pagination={false}
            />
          </TableWrapper>
        )}
        {showLoading && <CMSLoading />}
      </View>

      {state.deleteModalOpen && (
        <DeleteEmailComponent
          singleEmailComponent={state.selectedRow}
          onCompleteAction={(actionCode) => {
            onDeleteComponent(actionCode);
          }}
        />
      )}
    </View>
  );
};

export default EmailComponents;
