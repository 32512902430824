import {Skeleton, Text, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {ANALYTICS_RESOURCE} from '../../../../../constants';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {getSectionDataByResourceCode} from '../../../../../services/Employer/EmployerServices/EmployerServices';
import {Colors} from '../../../../../styles';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {EventBus} from '../../../../../utils/EventBus';
import ArrowRightSvg from '../../../../common/Svg/ArrowRightSvg';
import {DashboardDataContext} from '../EmployerContext/DashboardContext';
import {VIEW_CODE, WIDGET_ACTION_CODE} from '../EmployerUtils/EmployerConst';
import {getIconForWidgetByCode} from '../EmployerUtils/WidgetSvgHelper';
import {getFormattedMasterDataForDetails} from '../EmployerUtils/WidgetUtils';
import {IAppliedFilter, IResource, IWidgetCardView} from '../interface';
import CostSavingDrawer from '../SavingDrawer/CostSavingDrawer';
import SavingDrawer from '../SavingDrawer/SavingDrawer';
import {
  getTenantForDemo,
  IMAGING_SAVINGS,
  LAB_SAVINGS,
  PROCEDURES_SAVINGS,
  TOTAL_SAVINGS,
  VISITS_SAVINGS,
} from '../tempDataFile';
import WidgetHeader from '../WidgetHeader/WidgetHeader';
import NodataViewTypeOne from './NoDataView/NodataViewTypeOne';
import WidgetActionView from './WidgetActionView';

const SavingWidget = (props: IWidgetCardView) => {
  const {onOpenDetails, sectionData, appliedFilter, employerId, isAccountDashboard,
    memberType, } =
    props;
  const intl = useIntl();
  const isNewCostSavingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_NEW_COST_SAVING_API)
  const isTotalSaving =
    ANALYTICS_RESOURCE.TOTAL_SAVINGS === sectionData.resourceCode;
  const isLabSaving =
    sectionData.resourceCode === ANALYTICS_RESOURCE.LAB_SAVINGS;
  const isImagingSaving =
    sectionData.resourceCode === ANALYTICS_RESOURCE.IMAGING_SAVINGS;
  const isProceduresSaving =
    sectionData.resourceCode === ANALYTICS_RESOURCE.PROCEDURES_SAVINGS;
  const isVisitSaving =
    sectionData.resourceCode === ANALYTICS_RESOURCE.VISITS_SAVINGS;
  const [stateData, setStateData] = useState({
    resourceData: [] as IResource[] | any,
    loading: false,
    selectedView: '',
    selectedResourceData: {} as any,
    masterData: [] as any,
    appliedFilter: props.appliedFilter,
  });
  let totalSavingTraditionalCost =
    stateData.resourceData?.currentDateRangeTotalCostSavingData?.totalMarketCost;
  const totalSavingOurCost =
    stateData.resourceData?.currentDateRangeTotalCostSavingData?.totalDpcCost;
  totalSavingTraditionalCost = parseInt(totalSavingTraditionalCost);
  let totalSavingDifference = totalSavingTraditionalCost - totalSavingOurCost;
  if (totalSavingDifference && typeof totalSavingDifference?.toFixed === 'function') {
    totalSavingDifference = parseInt(totalSavingDifference.toFixed(2));
  }

  let totalSavingDifferencePercentage =
    ((totalSavingOurCost - totalSavingTraditionalCost) /
      totalSavingTraditionalCost) *
    100;
  if (totalSavingDifferencePercentage && typeof totalSavingDifferencePercentage?.toFixed === 'function') {
    totalSavingDifferencePercentage = parseInt(totalSavingDifferencePercentage.toFixed(2));
  }
  let sectionCost = 0;
  if (!isNaN(stateData.resourceData?.currentDateResp?.totalMarketCost)) {
    sectionCost = parseInt(stateData.resourceData?.currentDateResp?.totalMarketCost);
  }
  let totalCostSaving = isTotalSaving
    ? totalSavingDifference
    : (sectionCost || 0) -
    (stateData.resourceData?.currentDateResp?.totalDpcCost || 0);

  if (totalCostSaving && typeof totalCostSaving?.toFixed === 'function') {
    totalCostSaving = parseInt(totalCostSaving.toFixed(2));
  }

  let totalPreviousCostSaving: any = isTotalSaving
    ? true
    : (stateData.resourceData?.previousDateResp?.totalMarketCost || 0) -
    (stateData.resourceData?.previousDateResp?.totalDpcCost || 0);
  if (totalPreviousCostSaving && typeof totalPreviousCostSaving?.toFixed === 'function') {
    totalPreviousCostSaving = parseInt(totalPreviousCostSaving.toFixed(2));
  }
  const isNegative = totalCostSaving < 0;
  let totalPercentSaving = isTotalSaving
    ? totalSavingDifferencePercentage
    : ((totalCostSaving - totalPreviousCostSaving) / totalPreviousCostSaving) *
    100;
  if (totalPercentSaving && typeof totalPercentSaving?.toFixed === 'function') {
    totalPercentSaving = parseInt(totalPercentSaving.toFixed(2));
  }
  const isPositive = Math.sign(totalPercentSaving) === -1 ? false : true;
  const getSectionByResourceCode = async (resourceCode?: string, appliedFilter?: IAppliedFilter[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const filterList = appliedFilter?.length ? appliedFilter : stateData.appliedFilter
    const response = await getSectionDataByResourceCode(
      employerId,
      resourceCode || sectionData.resourceCode || '',
      filterList,
      '',
      isAccountDashboard,
      memberType,
      '',
      isNewCostSavingEnabled
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          resourceData: [],
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data) {

      const resourceData = response.data;
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
        };
      });
    } else {
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: [] as IResource[],
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };
  const getCostValueWithCurrency = (cost: any) => {
    return intl.formatNumber(cost || 0, {
      style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
  }
  const onOpenDetailsView = () => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedView: VIEW_CODE.SAVING_DRILL_DOWN,
        selectedResourceData: {
          resourceCode: sectionData?.resourceCode,
          data: stateData.resourceData
        },
        ...( !isNewCostSavingEnabled && {masterData: getFormattedMasterDataForDetails(
          stateData.resourceData?.currentDateResp,
          sectionData?.resourceCode || ''
        )})
      };
    });
  }
  const controller = new AbortController();
  useEffect(() => {
    if (getTenantForDemo()) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: true
        }
      })
      const resourceData: any = getTenantForDemo()
        ? isTotalSaving
          ? TOTAL_SAVINGS
          : isLabSaving
            ? LAB_SAVINGS
            : isImagingSaving
              ? IMAGING_SAVINGS
              : isProceduresSaving
                ? PROCEDURES_SAVINGS
                : isVisitSaving
                  ? VISITS_SAVINGS
                  : props.resourceData
        : props.resourceData;

      setTimeout(() => {
        setStateData((prev) => {
          return {
            ...prev,
            resourceData: resourceData,
            loading: false
          }
        })
      }, 3000)
      return
    }
    getSectionByResourceCode();
    return () => controller?.abort();
  }, [sectionData.id])
  useEffect(() => {
    if (props.filterData?.resourceCode == sectionData.resourceCode) {
      const appliedFilter = stateData.appliedFilter?.length ? [...stateData.appliedFilter] : [];
      const selectedFilter = {
        filterCode: props.filterData?.filterCode,
        filterValue: props.filterData?.filterValue
      }
      appliedFilter.push(selectedFilter);
      getSectionByResourceCode('', appliedFilter);
    }
    return () => controller?.abort();
  }, [props.filterData?.filterValue])
  const onActionClick = (code: string, data: any) => {
    switch (code) {
      case WIDGET_ACTION_CODE.DOWNLOAD_WIDGET:
        props.onActionClick?.(code, data);
        break;
      case WIDGET_ACTION_CODE.OPEN_SAVING_DRILL_DOWN:
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: VIEW_CODE.SAVING_DRILL_DOWN,
            selectedResourceData: {
              resourceCode: sectionData?.resourceCode,
              data: stateData.resourceData,
              resourceId: sectionData?.resourceId,
              widgetName: sectionData?.name
            },
            ...( !isNewCostSavingEnabled && {masterData: getFormattedMasterDataForDetails(
              stateData.resourceData?.currentDateResp,
              sectionData?.resourceCode || ''
            )})
          };
        });
        break
      default:
        props.onActionClick?.(code, data)
        break;
    }
  }
  const canShowDrillDown = onOpenDetails && !isAccountDashboard && !props.isPrintPreview
  return (
    <div
      className='widgetBoxShadow'
      style={{
        borderRadius: 16,
        marginRight: 20,
        width: '100%',
        padding: 6
      }}
    >
      <div style={{display: 'flex', width: '100%', paddingLeft: 16, alignItems: 'center'}}>
        <div style={{width: 24, height: 24, marginTop: 20}}>
          {getIconForWidgetByCode(sectionData?.resourceCode || '')}
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
          <WidgetHeader
            isPrintPreview={props.isPrintPreview}
            isPrintDownload={props.isPrintDownload}
            canShowDrillDown={canShowDrillDown}
            sectionData={sectionData}
            isAccountDashboard={isAccountDashboard}
            onActionClick={onActionClick}
          />
        </div>
      </div>
      {stateData.loading ? (
        <>
          <Skeleton.Text style={{padding: 32}} lines={2} />
        </>
      ) : (
        <>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 16,
            paddingTop: 0,
          }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'space-between',
                paddingBottom: 4,
                paddingTop: 4,
                marginBottom: isAccountDashboard ? 12 : 0
              }}
            >
              <div
                style={{
                  fontSize: 16,
                  color: Colors.Custom.Gray500,
                  fontWeight: '400',
                  fontFamily: 'Manrope-Regular',
                }}
              >
                Traditional cost
              </div>
              {stateData.resourceData?.currentDateResp || totalSavingTraditionalCost ? (
                <div
                  style={{
                    fontSize: 16,
                    color: '#000',
                    fontWeight: 400,
                    fontFamily: 'Manrope-Medium',
                  }}
                >
                  {getCostValueWithCurrency(isTotalSaving
                    ? totalSavingTraditionalCost ? Math.trunc(totalSavingTraditionalCost) : 0
                    : sectionCost ? Math.trunc(sectionCost) : 0)}
                </div>
              ) : (
                <NodataViewTypeOne viewCode={'ONLY_TEXT'} />
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'space-between',
                paddingBottom: 4,
                paddingTop: 4,
                marginBottom: isAccountDashboard ? 13 : 0
              }}
            >
              <div
                style={{
                  fontSize: 16,
                  color: Colors.Custom.Gray500,
                  fontWeight: '200',
                  fontFamily: 'Manrope-Regular',
                }}
              >
                Our service cost
              </div>
              {stateData.resourceData?.currentDateResp || totalSavingOurCost ? (
                <div
                  style={{
                    fontSize: 16,
                    color: '#000',
                    fontWeight: 400,
                    fontFamily: 'Manrope-Medium',
                  }}
                >
                  {getCostValueWithCurrency(isTotalSaving
                    ? totalSavingOurCost ? Math.trunc(totalSavingOurCost) : 0
                    : stateData.resourceData?.currentDateResp?.totalDpcCost
                      ? Math.trunc(
                        stateData.resourceData?.currentDateResp
                          ?.totalDpcCost
                      )
                      : 0)}
                </div>
              ) : (
                <NodataViewTypeOne
                  viewCode={'ONLY_TEXT'}
                />
              )}
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'space-between',
              padding: 8,
              paddingBottom: 4,
              paddingTop: 4,
              marginTop: 4
            }}>
              <div
                style={{
                  color: '#000',
                  fontFamily: 'Manrope-Regular',
                  fontSize: 16,
                  fontWeight: 600,
                  textAlign: 'right'
                }}
              >
                Savings Amt
              </div>
              {stateData.resourceData?.currentDateResp || totalSavingDifference ? (
                <div
                  style={{
                    fontSize: 20,
                    color: isNegative ? Colors.error[600] : '#000',
                    fontWeight: 600,
                    fontFamily: 'Manrope-Medium',
                    textAlign: 'right'
                  }}
                >
                  {getCostValueWithCurrency(totalCostSaving ? Math.trunc(totalCostSaving) : 0)}
                </div>
              ) : (
                <div style={{
                  paddingTop: 4.5,
                  paddingBottom: 4.5,
                }}>
                  <NodataViewTypeOne
                    viewCode={'ONLY_TEXT'}
                  />
                </div>
              )}
            </div>
            {false && onOpenDetails && !isAccountDashboard && !props.isPrintPreview && (
              <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 12}}>
                <Text
                  style={{
                    fontSize: 14,
                    color: '#000',
                    fontWeight: '600'
                  }}
                  onPress={() => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        selectedView: VIEW_CODE.SAVING_DRILL_DOWN,
                        selectedResourceData: {
                          resourceCode: sectionData?.resourceCode,
                          data: stateData.resourceData
                        },
                        ...( !isNewCostSavingEnabled && {masterData: getFormattedMasterDataForDetails(
                          stateData.resourceData?.currentDateResp,
                          sectionData?.resourceCode || ''
                        )})
                      };
                    });
                  }}
                >
                  View Details
                </Text>
                <View style={{width: 18, height: 18, alignItems: 'center', justifyContent: 'center'}}>
                  <ArrowRightSvg customStrokeColor={'#000'} />
                </View>
              </View>
            )
            }
          </div>
        </>
      )}
      <DashboardDataContext.Consumer>
        {(context) => {
          return (
            <>
              {stateData.selectedView === VIEW_CODE.SAVING_DRILL_DOWN ? (
                <>
                {!isNewCostSavingEnabled ?
                  <SavingDrawer
                  onCancel={() => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        selectedView: '',
                        selectedResourceData: null
                      };
                    });
                  }}
                  onSendCallBackData={(code) => {
                    getSectionByResourceCode(code, []);
                    // getSectionByResourceCode(ANALYTICS_RESOURCE.TOTAL_SAVINGS);
                  }}
                  dateRange={{
                    fromDate: context.uiFromDate,
                    toDate: context.uiToDate,
                  }}
                  masterData={stateData?.masterData}
                  employerId={employerId}
                  data={stateData.selectedResourceData}
                  isAccountDashboard={isAccountDashboard}
                /> :
                <CostSavingDrawer
                  onCancel={() => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        selectedView: '',
                        selectedResourceData: null
                      };
                    });
                  }}
                  onSendCallBackData={(code: any) => {
                    getSectionByResourceCode(code, []);
                    const eventBus = EventBus.getEventBusInstance();
                    eventBus.broadcastEvent('RE_FETCH_TOTAL_SAVING', {
                      resourceCode: ANALYTICS_RESOURCE.TOTAL_SAVINGS
                    });
                  }}
                  dateRange={{
                    fromDate: context.uiFromDate,
                    toDate: context.uiToDate,
                  }}
                  masterData={stateData?.masterData}
                  employerId={employerId}
                  selectedResourceData={stateData.selectedResourceData}
                  isAccountDashboard={isAccountDashboard}
                />
                }
                </>
              ) : (
                <></>
              )}
            </>
          )
        }}
      </DashboardDataContext.Consumer>
    </div>
  );
};
export default React.memo(SavingWidget);
