import {IInbox} from '../../../../services/Inbox/interfaces';
import {ILoginUserData} from '../../../../Interfaces/CommonInterfaces';
import {CHANNEL_TYPE_CODE, CONVERSATION_TAB_CODES} from './ConversationConst';
import {
  getChannelTypeFilter,
  getChatConversationFilterForAll,
  getChatConversationTabFilter,
  getContactTypeFilter,
  getDateFilterObj,
  getInboxFilterObj,
} from './ConversationContainer/ConversationFilters/ConversationFilterUtils';
import {updateConversationFilterObj} from './ConversationContainer/ConversationUtils';
import {
  FilterSectionCode,
  IAdditionFilterData,
  ICommonFilterData,
  ICommonFilterSection,
  IConversationChannelFilterQuery,
  IConversationContactTypeQuery,
  IDateFilterQuery,
  IInboxFilterQuery,
} from './ConversationContainer/ConversationFilters/interface';
import {CONTACT_TYPE_CODES} from './ConversationContainer/ConversationConst';
import {getConversationChannelTabFilter} from './ConversationContainer/ConversationFilters/ConversationChannelTabFilterUtils';

export const getConversationFilter = (
  selectedFilter: any,
  selectedTabCode: string,
  selectInboxTypeCode: string,
  conversationState: any,
  userData: ILoginUserData,
  selectedInbox?: IInbox
) => {
  switch (selectInboxTypeCode) {
    case CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET:
      return getWebChannelFilter(
        selectedFilter,
        selectedTabCode,
        selectInboxTypeCode,
        conversationState,
        userData
      );
      break;
    case CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS:
      return getSmsChannelFilter(
        selectedTabCode,
        selectInboxTypeCode,
        userData
      );
      break;

    case CHANNEL_TYPE_CODE.CHANNEL_EMAIL:
      return getEmailChannelFilter(
        selectedTabCode,
        selectInboxTypeCode,
        userData,
        selectedInbox
      );
      break;
  }
};

const getWebChannelFilter = (
  selectedFilter: any,
  selectedTabCode: string,
  selectInboxTypeCode: string,
  conversationState: any,
  userData: ILoginUserData
): any => {
  let currentFilterObj;
  if (
    conversationState.conversationFilter &&
    Object.keys(conversationState.conversationFilter).length
  ) {
    conversationState.conversationFilter.accountId = {_eq: userData.account_id};
    conversationState.conversationFilter.inboxId = {_neq: -1};
    currentFilterObj = conversationState.conversationFilter;
  } else {
    currentFilterObj = {
      accountId: {_eq: userData.account_id},
      inboxId: {_neq: -1},
    };
  }
  if (selectedTabCode === CONVERSATION_TAB_CODES.RECENT) {
    conversationState.conversationFilter.assigneeId = {_eq: userData.id};
  } else if (selectedTabCode === CONVERSATION_TAB_CODES.PROSPECTS) {
    const channelTypeObj: any = getChannelTypeFilter(selectInboxTypeCode, userData) || {};
    currentFilterObj = {
      ...currentFilterObj,
      ...channelTypeObj,
    };
    delete conversationState.conversationFilter.assigneeId;
  }

  if (selectedTabCode === CONVERSATION_TAB_CODES.ALL) {
    delete conversationState.conversationFilter.assigneeId;
  } else if (selectedTabCode === CONVERSATION_TAB_CODES.MINE) {
    conversationState.conversationFilter.assigneeId = {_eq: userData.id};
  } else if (selectedTabCode === CONVERSATION_TAB_CODES.UN_ASSIGNED) {
    conversationState.conversationFilter.assigneeId = {_is_null: true};
  }
  updateConversationFilterObj(currentFilterObj, selectedFilter);

  return currentFilterObj;
};

const getSmsChannelFilter = (
  selectedTabCode: string,
  selectInboxTypeCode: string,
  userData: ILoginUserData
): any => {
  const channelTypeObj: any = getChannelTypeFilter(selectInboxTypeCode, userData) || {};
  const currentFilterObj = {
    accountId: {_eq: userData.account_id},
    inboxId: {_neq: -1},
    ...channelTypeObj,
  };
  if (selectedTabCode === CONVERSATION_TAB_CODES.PATIENTS) {
    currentFilterObj.conversationContact = {
      contactType: {
        contactType: {
          code: {
            _eq: 'CUSTOMER',
          },
        },
      },
    };
  } else if (selectedTabCode === CONVERSATION_TAB_CODES.PROSPECTS) {
    currentFilterObj.conversationContact = {
      contactType: {
        contactType: {
          code: {
            _neq: 'CUSTOMER',
          },
        },
      },
    };
  }

  return currentFilterObj;
};

const getEmailChannelFilter = (
  selectedTabCode: string,
  selectInboxTypeCode: string,
  userData: ILoginUserData,
  selectedInbox?: IInbox
): any => {
  const channelTypeObj: any = getChannelTypeFilter(selectInboxTypeCode, userData) || {};
  const currentFilterObj = {
    accountId: {_eq: userData.account_id},
    inboxId: {_neq: -1},
    ...channelTypeObj,
  };
  if (selectedInbox?.id) {
    currentFilterObj.inboxId = {_neq: selectedInbox?.id};
  }
  if (selectedTabCode === CONVERSATION_TAB_CODES.PATIENTS) {
    currentFilterObj.conversationContact = {
      contactType: {
        contactType: {
          code: {
            _eq: 'CUSTOMER',
          },
        },
      },
    };
  } else if (selectedTabCode === CONVERSATION_TAB_CODES.PROSPECTS) {
    currentFilterObj.conversationContact = {
      contactType: {
        contactType: {
          code: {
            _neq: 'CUSTOMER',
          },
        },
      },
    };
  }

  return currentFilterObj;
};

export const getNewConversationFilter = (
  selectedFilter: ICommonFilterData,
  selectedTabCode: string,
  additionFilterData: IAdditionFilterData,
  selectedInbox?: IInbox,
  isEFaxMessagingEnabled?: boolean
) => {
  const selectedContactTypeOption = getSingleSelectedFilterOptionFromList(
    selectedFilter?.filterSections,
    FilterSectionCode.contactType
  );
  const selectedDateFilterOption = getSingleSelectedFilterOptionFromList(
    selectedFilter?.filterSections,
    FilterSectionCode.dateType
  );
  const selectedAssignmentOption = getSingleSelectedFilterOptionFromList(
    selectedFilter?.filterSections,
    FilterSectionCode.assignment
  );
  const selectedAssignmentStatusOption = getSingleSelectedFilterOptionFromList(
    selectedFilter?.filterSections,
    FilterSectionCode.assignmentStatus
  );

  const mentionedOption = getSingleSelectedFilterOptionFromList(
    selectedFilter?.filterSections,
    FilterSectionCode.mention
  );
  const tempFilterData = {
    inboxFilterData: {} as IInboxFilterQuery,
    contactTypeFilterData: {} as IConversationContactTypeQuery,
    dateFilterData: [] as IDateFilterQuery[],
    conversationChannelFilter: {} as IConversationChannelFilterQuery,
  };

  let conversationFinalFilters: any = {
    accountId: {_eq: additionFilterData.accountId},
    _and: [],
  };

  const tempUserFilterData = getConversationChannelTabFilter(
    selectedTabCode || CHANNEL_TYPE_CODE.CHANNEL_ALL,
    additionFilterData,
    isEFaxMessagingEnabled,
  ) as any;
  if (tempUserFilterData) {
    conversationFinalFilters = {...conversationFinalFilters, ...tempUserFilterData};
  }

  if (selectedTabCode === CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET) {
    tempFilterData.conversationChannelFilter = getChatConversationTabFilter(
      selectedTabCode,
      additionFilterData,
      selectedContactTypeOption?.code,
      selectedInbox?.id
    ) as any;
    if (tempFilterData.conversationChannelFilter) {
      conversationFinalFilters._and.push(
        tempFilterData.conversationChannelFilter
      );
    }
  } else {
    tempFilterData.conversationChannelFilter = getChannelTypeFilter(
      selectedTabCode, additionFilterData as any
    ) as any;
    if (tempFilterData.conversationChannelFilter) {
      conversationFinalFilters = {...conversationFinalFilters, ...tempFilterData.conversationChannelFilter}
      // .push(
      //   tempFilterData.conversationChannelFilter
      // );
    }
  }

  if (selectedInbox?.id) {
    tempFilterData.inboxFilterData = getInboxFilterObj(selectedInbox?.id);
    conversationFinalFilters._and.push(tempFilterData.inboxFilterData);
  }

  if (selectedContactTypeOption && selectedContactTypeOption.code != 'ALL') {
    const contactTypeFilterData = getContactTypeFilter(
      selectedContactTypeOption?.code ?? CONTACT_TYPE_CODES.PATIENT,
      selectedTabCode,
      additionFilterData
    );
    if (contactTypeFilterData?._and?.length) {
      conversationFinalFilters._and.push(...contactTypeFilterData?._and);
    } else {
      conversationFinalFilters._and.push(contactTypeFilterData);
    }
  }

  if (selectedDateFilterOption?.code) {
    tempFilterData.dateFilterData = getDateFilterObj(
      selectedTabCode,
      selectedDateFilterOption?.code,
    );
    conversationFinalFilters._and.push(...tempFilterData.dateFilterData);
  }

  if (
    selectedTabCode == CHANNEL_TYPE_CODE.CHANNEL_ALL &&
    !conversationFinalFilters?._and?.length
  ) {
    conversationFinalFilters['_or'] =
      getChatConversationFilterForAll(additionFilterData);
  }

  if (selectedAssignmentOption?.code === CONVERSATION_TAB_CODES.ALL) {
    delete conversationFinalFilters.assigneeId;
  } else if (selectedAssignmentOption?.code === CONVERSATION_TAB_CODES.MINE) {
    conversationFinalFilters.assigneeId = {_eq: additionFilterData.id};
  } else if (
    selectedAssignmentOption?.code === CONVERSATION_TAB_CODES.UN_ASSIGNED
  ) {
    conversationFinalFilters.assigneeId = {_is_null: true};
  }

  if (selectedAssignmentStatusOption?.code === CONVERSATION_TAB_CODES.ALL) {
    delete conversationFinalFilters.status;
  } else if(selectedAssignmentStatusOption?.value){
    conversationFinalFilters.status = { _eq: selectedAssignmentStatusOption?.value }
  }else{
    conversationFinalFilters.status = { _neq: 1}
  }

  if (mentionedOption?.code === 'Mentions') {
    conversationFinalFilters.conversationMentions = {
      userType: {
        code: {
          _eq: 'USER',
        },
      },
      userId: {
        _eq: additionFilterData.userUuid,
      },
    };
  } else {
    delete conversationFinalFilters.conversationMentions;
  }
  return conversationFinalFilters;
};

export const getSingleSelectedFilterOptionFromList = (
  filterSections?: ICommonFilterSection[],
  filterSectionCode?: FilterSectionCode
) => {
  const filterSection = filterSections?.find(
    (section) => section.filterSectionCode === filterSectionCode
  );
  return filterSection?.filterOptions.find(
    (option) => option.isSelected === true
  );
};

export const getMultiSelectedFilterOptionFromList = (
  filterSections?: ICommonFilterSection[],
  filterSectionCode?: FilterSectionCode
) => {
  const filterSection = filterSections?.find(
    (section) => section.filterSectionCode === filterSectionCode
  );
  return filterSection?.filterOptions.filter(
    (option) => option.isSelected === true
  );
};
