import React from 'react'
import { getEhrCapabilitiesV2WithLocation, getEhrCapabilitiesWithLocation } from '../../services/CommonService/AidBoxService'
import { IEhrCapability } from '../../Interfaces';
import { isWeb } from '../../utils/platformCheckUtils';

const useGetCapabilities = () => {

  // States
  const [hookState, setHookState] = React.useState({
    loading: false,
    locationGroupMap: new Map<string, IEhrCapability[]>(),
    accountConfigCapabilities: [] as IEhrCapability[] ,
    popGroupCapabilities: [] as IEhrCapability[]
  });

  const getPopGroupCapabilities = isWeb();

  // Other methods
  const fetchCapabilities = async (
    locationGroupIds: string[],
  ) => {
    try {
      const locationGroupMap = new Map<string, any>();
      const locationGroupIdsCommaSeparated = locationGroupIds.join();

      setHookState((prev) => ({
        ...prev,
        loading: true,
      }));

      const promises = [
        getEhrCapabilitiesV2WithLocation({
          locationGroupId: locationGroupIdsCommaSeparated,
        }),
      ];

      if (getPopGroupCapabilities) {
        promises.push(
          getEhrCapabilitiesWithLocation({
            resourceType: 'popGroup',
          })
        );
      }

      const [locationResponse, popGroupResponse] = await Promise.all(promises);

      let accountConfigCapabilities: IEhrCapability[] = [];
      let popGroupCapabilities: any[] = [];

      if (locationResponse?.data) {
        const locationGroupCapabilities = locationResponse?.data?.locationGroupCapabilities;
        locationGroupIds?.forEach((locationGroupId) => {
          const locationGroup = locationGroupCapabilities?.find(
            (locationGroupCpability: any) =>
              locationGroupCpability?.locationGroupId === locationGroupId
          );
          if (locationGroup) {
            const capabilities = locationGroup?.capabilities?.map(
              (capability: any) => {
                capability.locationGroupId = locationGroupId;
                return capability;
              }
            );
            locationGroupMap.set(locationGroupId, capabilities);
          }
        });

        accountConfigCapabilities = locationResponse?.data?.accountConfigs;
      }
      if (getPopGroupCapabilities && popGroupResponse?.data) {
        popGroupCapabilities = popGroupResponse?.data || [];
      }
      setHookState((prev) => ({
        ...prev,
        loading: false,
        locationGroupMap,
        accountConfigCapabilities,
        popGroupCapabilities,
      }));
    } catch (error) {
      setHookState({
        loading: false,
        locationGroupMap: new Map<string, any>(),
        accountConfigCapabilities: [],
        popGroupCapabilities: [],
      });
    }
  };

  return {
    loading: hookState.loading,
    locationGroupMap: hookState.locationGroupMap,
    popGroupCapabilities: hookState.popGroupCapabilities,
    fetchCapabilities: fetchCapabilities,
    accountConfigCapabilities: hookState.accountConfigCapabilities
  };
}

export default useGetCapabilities
