import React from 'react';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {getUserData} from '../../../../../../utils/commonUtils';
import {Text, TouchableOpacity, View, StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles';
import {DATE_FORMATS} from '../../../../../../constants/StringConst';
import moment from 'moment';
import {ISingleMessageThread} from './interface';
import MsgHighlighter from '../../ConversationContainer/ConversationList/MsgHighlighter';
import {
  getMessageContent,
  getMessageSenderName,
  getMsgBoxColor,
} from './SearchMessageThreadUtils';
import {SEARCH_MSG_CONST} from '../MsgConst';

const SingleMessageThread = (props: ISingleMessageThread) => {
  const currentUserData = getUserData();
  const messageType = props?.singleMsgData?.messageType;
  const messageBoxColor = getMsgBoxColor(
    props?.singleMsgData?.private,
    messageType
  );

  const getMsgHeader = (msgData: any) => {
    return (
      <Stack
        direction="row"
        style={styles.headerStack}
      >
        <Text
          numberOfLines={1}
          style={[
            styles.senderName,
            messageType === 1 && styles.whiteText,
          ]}
        >
          {getMessageSenderName(
            msgData,
            currentUserData?.uuid,
            currentUserData?.id
          )}
        </Text>
        <Text
          style={[
            styles.dateText,
            messageType === 1 && styles.whiteText,
          ]}
        >
          {moment(msgData?.updatedAt || msgData?.createdAt).format(DATE_FORMATS.MESSAGE_DATE_FORMAT)}
        </Text>
      </Stack>
    );
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => {
          props?.onActionPerformed(props?.singleMsgData);
        }}
      >
        <Stack
          direction="column"
          style={
            StyleSheet.flatten([
              styles.messageStack, 
              {
                backgroundColor: messageBoxColor
              }
            ])
          }
        >
          <Stack direction="column">{getMsgHeader(props?.singleMsgData)}</Stack>
          <Stack
            direction="column"
            style={styles.contentStack}
          >
            <Text
              style={messageType === 1 ? styles.whiteText : styles.darkText}
            >
              <MsgHighlighter
                searchString={props?.searchString}
                contentString={getMessageContent(
                  SEARCH_MSG_CONST.SEARCH_MSG_LENGTH,
                  props?.searchString,
                  props?.singleMsgData?.content
                )}
              />
            </Text>
          </Stack>
        </Stack>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  headerStack: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.Gray200,
    flex: 1,
  },
  senderName: {
    flex: 0.56,
    fontSize: 14,
    color: Colors.Custom.Gray700,
    fontWeight: '700',
    paddingRight: 4,
    paddingBottom: 4,
  },
  dateText: {
    flex: 0.44,
    fontSize: 12,
    color: Colors.Custom.Gray400,
    fontWeight: '400',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    paddingBottom: 4,
  },
  messageStack: {
    marginVertical: 8,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  contentStack: {
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  whiteText: {
    color: 'white',
  },
  darkText: {
    color: Colors.Custom.Gray900,
  },
});

export default SingleMessageThread;
