import React from "react";

const VideoCallingSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{width: 'inherit', height: 'inherit'}}
    fill="none"
    viewBox="0 0 38 38"
  >
    <path
      fill={svgColors}
      d="M34.833 19c0-8.745-7.089-15.834-15.834-15.834-8.744 0-15.833 7.089-15.833 15.833 0 2.602.629 5.114 1.813 7.363l-1.767 6.792a1.346 1.346 0 001.64 1.64l6.793-1.768a15.77 15.77 0 007.354 1.807c8.745 0 15.834-7.089 15.834-15.834zm-15.834-6.334a3.166 3.166 0 013.167 3.167v6.333a3.167 3.167 0 01-3.167 3.167h-4.75a3.166 3.166 0 01-3.166-3.167v-6.333a3.167 3.167 0 013.166-3.167H19zm4.75 8.173v-3.68l2.746-2.6a1.187 1.187 0 012.004.864v7.153a1.188 1.188 0 01-2.004.863l-2.746-2.6z"
    ></path>
  </svg>
  );
}

export default React.memo(VideoCallingSvg);
