import {useLazyQuery, useMutation} from '@apollo/client';
import {Skeleton, Spinner, useToast, View} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {
  ANALYTICS_RESOURCE, DISPLAY_TYPE, EMPLOYER_REPORT_FILTER_CODES, scrollViewEmployer, SECTION_LIST,
} from '../../../../constants/StringConst';
import {EmployerDashboard, FormsQueries} from '../../../../services';
import EmployerQueries from '../../../../services/Employer/EmployerQueries';
import {
  getAccountUUID,
  getFilterObjByCode,
  getMemberSatisfactionFormsOnly,
  getUniqueObjectFromKey,
  getUserEmployerId,
  getUserUUID,
  isEmployerRole,
  isMasterAccount,
  removeFilterObjFromList,
  updateFilterValue,
} from '../../../../utils/commonUtils';
import {NoDataFound} from '../../../common/NoDataFound';
import './customStyles.css';
import {EMPLOYER_DASHBOARD_TABS, VIEW_CODE, WIDGET_ACTION_CODE} from './EmployerUtils/EmployerConst';
import {
  getContactUuidsFromContactData,
  getContactUuidsFromContactDrillDownDataList,
  getContactDrillDownColumnDataList,
  getFlatListPageSections,
  getFormattedDashboardPage,
  updatePageIdToList,
} from './EmployerUtils/FormateDataUtils';
import {
  isWidgetDataEmpty,
} from './EmployerUtils/WidgetUtils';
import {
  IContactDrillDownDataList,
  IContactMembershipsData,
  IEmployerAnalytics,
  IMemberSatisfactionForm,
  IPageResponse,
  IPageSection,
  IPageView,
  ISectionListItem,
} from './interface';
import {capitalizeText} from '../../../common/ContactRelationView/ContactRelationUtils';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {getMlovId} from '../../../../utils/mlovUtils';
import UpdateDashboardDrawer from './UpdateDashboardView/UpdateDashboardDrawer';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import CustomerSatisfactionDrawer from './CustomerSatisfactionDrawer/CustomerSatisfactionDrawer';
import {
  drillDownByEmployerId,
  getSectionDataByResourceCode,
} from '../../../../services/Employer/EmployerServices/EmployerServices';
import AnalyticsSections from './AnalyticsSections';
import {AUTOMATION_TIME_SAVED_DRILL_DOWN, getTenantForDemo} from './tempDataFile';
import DrillDownMemberList from '../EmployerAnalytics/DrillDownMemberList/DrillDownMemberList';
import {ITabsList} from '../../../common/CustomTabsView/interfaces';
import CustomTabsView from '../../../common/CustomTabsView/CustomTabsView';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import WorkflowGraphPopup from '../../Workflow/Workflow/WorkflowTableView/Helper/WorkflowGraphPopup';
declare const window: any;

// const htmlToImage = require('html-to-image');

const EmployerAnalytics = (props: IEmployerAnalytics) => {
  const isEmployerRoleCheck = props.isEmployerRole || isEmployerRole() || false;
  const employerId = getUserEmployerId() || props.employerId;
  const selectedPayerId = props.selectedPayerId || '';
  const toast = useToast();
  const accountUuid = getAccountUUID();
  const userUuid = getUserUUID();
  const screenWidth = Dimensions.get('window').width;
  const mlovData = useContext(CommonDataContext);
  const EmployerTypeDashboardId = getMlovId(
    mlovData.MLOV,
    'PageType',
    'EMPLOYER_DASHBOARD'
  );
  const isEmployer = isEmployerRole();
  const [stateData, setStateData] = useState({
    sectionList: [] as ISectionListItem[],
    loading: true,
    pageResponse: {} as IPageView,
    reusableData: {
      employeeCount: null,
    } as any,
    employerData: {} as any,
    rawPageSections: ['a', 'c'] as IPageSection[] | any[],
    isDashboardAssign: false,
    dashboardLoading: true,
    drillDownData: {} as any,
    selectedView: '',
    selectedResourceData: null,
    masterData: [] as any[],
    employerEnrollmentData: {} as IContactMembershipsData,
    memberSatisfactionFormList: [] as IMemberSatisfactionForm[],
    selectedFilterData: {} as any,
  });
  // } as IEmployerAnalyticsState);
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 1;
  const isMasterConfig = isMasterAccount()
  const [contactsState, setContactsState] = React.useState({
    isLoading: false,
    isOpen: false,
    membersData: [] as any,
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
    toDate: '',
    fromDate: '',
    openContactListDrawer: false,
    contactUuidList: [] as string[],
    contactDrillDownDataList: {} as IContactDrillDownDataList,
    isCustomerFilterOpen: false,
    resourceCode: '',
    name: '',
    data: {} as any,
    selectedView: '',
    dummyContactList: [] as string[],
    responseData: {} as any,
    typeName: ''
  });

  const [customerSatisfactiomDrawer, setCustomerSatisfactiomDrawer] =
    useState(false);

  const [EmployerById] = useLazyQuery(EmployerQueries.getEmployerById, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });
  const [GetDashboard] = useLazyQuery(
    EmployerDashboard.GetDashboardSectionBySectionCode
  );
  const [GetMembersSatisfaction] = useLazyQuery(
    FormsQueries.GET_MEMBERS_SATISFACTION,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [GetDashboardAllSection] = useLazyQuery(
    EmployerDashboard.GetDashboardByEmployerId
  );
  const [GetEmployerEnrollmentDateFromEmployee] = useLazyQuery(
    EmployerDashboard.GetEmployerEnrollmentDateFromEmployee
  );
  const [GetDefaultPage] = useLazyQuery(
    EmployerDashboard.GetDefaultPageForDashboard
  );
  const [GetContactByCount] = useLazyQuery(
    EmployerDashboard.GetContactByCount,
    {
      fetchPolicy: 'no-cache'//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  )
  const getContactByCount = async () => {
    const response = await GetContactByCount({
      variables: {
        limit: 100,
        accountUuid: accountUuid
      }
    })
    if (response?.data?.contacts?.length) {
      const contactList = response?.data?.contacts?.map((item: any) => item?.uuid);
      setContactsState(prev => {
        return {
          ...prev,
          dummyContactList: [...contactList]
        }
      })
    }
  }
  const [CreatePageSection] = useMutation(EmployerDashboard.CreatePageSections);
  const [CreatePage] = useMutation(EmployerDashboard.CreatePage);
  const [CreateDashboard] = useMutation(EmployerDashboard.CreateDashboard);
  const [CreateEmployerDashboard] = useMutation(
    EmployerDashboard.CreateEmployerDashboard
  );

  const getDashboardByEmployerId = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
        dashboardLoading: true,
      };
    });
    let dashboardRes: any;
    if (scrollViewEmployer || true) {
      dashboardRes = await GetDashboardAllSection({
        variables: {
          employerId: employerId,
          accountUuid: accountUuid
        },
        fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }).catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
            pageResponse: {} as IPageView,
            dashboardLoading: false,
            reusableData: {
              ...prev.reusableData,
              isDashboardAssign: false,
            },
          };
        });
      });
    } else {
      dashboardRes = await GetDashboard({
        variables: {
          employerId: employerId,
          accountUuid: accountUuid,
          sectionCategoryCode: props.selectedTab?.key
        },
        fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }).catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
            pageResponse: {} as IPageView,
            dashboardLoading: false,
            reusableData: {
              ...prev.reusableData,
              isDashboardAssign: false,
            },
          };
        });
      });
    }
    const employerEnrollmentData = await GetEmployerEnrollmentDateFromEmployee({
      variables: {
        employerId: employerId,
      },
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }).catch((err)=> {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          dashboardLoading: false,
        };
      });
    })
    let memberSatisfactionFormList = [] as any[];
    const memberSatisfactionFormResp = await GetMembersSatisfaction();
    if (memberSatisfactionFormResp?.data?.forms?.length) {
      memberSatisfactionFormList = getMemberSatisfactionFormsOnly(memberSatisfactionFormResp?.data?.forms) as any[];
    }
    setStateData((prev: any) => {
      return {
        ...prev,
        isDashboardAssign: true,
        reusableData: {
          ...prev.reusableData,
          isDashboardAssign: true,
        },
        employerEnrollmentData: employerEnrollmentData?.data || {contactMemberships: []} as any,
        memberSatisfactionFormList: [...memberSatisfactionFormList]
      };
    });
    if (
      dashboardRes &&
      dashboardRes?.data?.employerDashboards[0]?.employerDashboards[0]?.page
        ?.pageSections?.length
    ) {
      const dashboardPage: IPageResponse =
        dashboardRes?.data?.employerDashboards[0]?.employerDashboards[0].page;
      const formattedData = getFormattedDashboardPage(dashboardPage)
      const rawPageSections = dashboardPage.pageSections
      const newFormattedDataForSaving = formattedData.sectionData.map((data) => {
        if (data.sectionCategory === SECTION_LIST.SAVINGS) {
          const groupWidgetsList: ISectionListItem[] = []
          const filteredChildSectionWidget = data.childSectionWidget.filter(item => {
            if (item.resourceCode == ANALYTICS_RESOURCE.TOTAL_SAVINGS) {
              return true
            } else {
              groupWidgetsList.push(item)
              return false
            }
          })
          const singleWidgetsGroup: ISectionListItem = {
            ...groupWidgetsList[0],
            name: 'Group saving',
            childSectionWidget: groupWidgetsList,
            resourceCode: ANALYTICS_RESOURCE.GROUP_SAVING,
            currentDisplayType: DISPLAY_TYPE.SAVING_GROUP_CUSTOM
          }
          filteredChildSectionWidget.push(singleWidgetsGroup)
          data['childSectionWidget'] = filteredChildSectionWidget
        }
        return data
      })
      formattedData.sectionData = newFormattedDataForSaving;
      const newFormattedData: ISectionListItem[] = []
      const newRawPageSections: IPageSection[] = []
      if (props?.selectedTab?.childSection?.[0] !== 'ALL') {
        props?.selectedTab?.childSection?.forEach((sectionName: string) => {
          const selectedSection = formattedData.sectionData.find((item) => {
            return item.sectionCategory === sectionName
          })
          const selectedRawSection = rawPageSections.find((item) => {
            return item.sectionCategory?.code === sectionName
          })
          if (selectedRawSection?.id) {
            newRawPageSections.push(selectedRawSection)
          }
          if (selectedSection?.id) {
            newFormattedData.push(selectedSection)
          }
        });
        formattedData.sectionData = newFormattedData
      }
      props?.sendDashboardCallBack?.(rawPageSections)
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          pageResponse: formattedData,
          sectionList: formattedData.sectionData,
          dashboardLoading: false,
          rawPageSections: newRawPageSections?.length ? newRawPageSections : rawPageSections,
          isDashboardAssign: true,
          reusableData: {
            ...prev.reusableData,
            isDashboardAssign: true,
          },
        };
      });
    }
    else {
      await getDefaultPageForDashboard().catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
            dashboardLoading: false,
          };
        });
      });
    }
  };
  const getDefaultPageForDashboard = async () => {
    const defaultPageRes = await GetDefaultPage({
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          pageResponse: {} as IPageView,
          isDashboardAssign: false,
          dashboardLoading: false,
          reusableData: {
            ...prev.reusableData,
            isDashboardAssign: false
          }
        };
      });
    });
    if (defaultPageRes?.data?.pages?.length) {
      const defaultPage: IPageResponse[] = defaultPageRes?.data?.pages;
      const rawPageSections = defaultPage[0].pageSections;
      onSaveOrUpdateDashboard(rawPageSections);
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          pageResponse: {} as IPageView,
          sectionList: [],
          isDashboardAssign: false,
          dashboardLoading: false,
          reusableData: {
            ...prev.reusableData,
            isDashboardAssign: false
          }
        };
      });
    }
  };
  const getEmployeeCount = async () => {
    const response = await getSectionDataByResourceCode(
      employerId,
      ANALYTICS_RESOURCE.EMPLOYEE_COUNT || ''
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          reusableData: {},
        };
      });
    });
    if (response?.data && isWidgetDataEmpty(response?.data)) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          reusableData: {
            ...prev.reusableData,
            employeeCount: response.data?.totalCount || null,
          },
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          reusableData: {},
        };
      });
    }
  };
  const getEmployerById = async () => {
    const employerDataRes = await EmployerById({
      variables: {
        employerId: employerId,
      },
    });
    if (employerDataRes.data?.employers?.length) {
      const employerData = employerDataRes.data?.employers[0];
      setStateData((prev) => {
        return {
          ...prev,
          employerData: employerData,
        };
      });
    }
  };

  const createDashboard = async (pageId: string) => {
    const dashboardResp = await CreateDashboard({
      variables: {
        pageId: pageId,
        accountUuid: accountUuid,
        name: `${capitalizeText(props.employerName || '')} Dashboard`,
        employerId: props.employerId
      },
    }).catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          dashboardLoading: false,
          loading: false,
        };
      });
    });
    if (dashboardResp?.data?.createDashboard?.id) {
      const dashboardId = dashboardResp?.data?.createDashboard?.id;
      await createEmployerDashboard(
        dashboardId,
        pageId
      );
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          dashboardLoading: false,
          loading: false,
        };
      });
    }
  };
  const createEmployerDashboard = async (
    dashboardId: string,
    pageId: string
  ) => {
    const response = await CreateEmployerDashboard({
      variables: {
        dashboardId: dashboardId,
        accountUuid: accountUuid,
        employerUuid: props.employerId,
      },
    }).catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          dashboardLoading: false,
          loading: false,
        };
      });
    });
    if (response?.data?.createEmployerDashboard?.id) {
      getEmployerById();
      getDashboardByEmployerId();
      getEmployeeCount();
      setStateData((prev) => {
        return {
          ...prev,
          dashboardLoading: false,
          loading: false,
          isDashboardAssign: true,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          dashboardLoading: false,
          loading: false,
        };
      });
    }
  };
  const addPageSectionsToNewPage = async (pageId: string, rawPageSections: IPageSection[]) => {
    const rawUpdatedSection = getFlatListPageSections(
      rawPageSections,
      userUuid
    );
    const updatedPageSectionsList = updatePageIdToList(
      pageId,
      rawUpdatedSection,
      userUuid
    );
    CreatePageSection({
      variables: {
        pageSections: updatedPageSectionsList,
      },
    })
      .then((res) => {
        if (res?.data?.createPageSections?.returning?.length) {
          createDashboard(pageId)
        } else {
          setStateData((prev) => {
            return {
              ...prev,
              dashboardLoading: false,
              loading: false,
            };
          });
        }
      })
      .catch((err) => {
        setStateData((prev) => {
          return {
            ...prev,
            dashboardLoading: false,
            loading: false,
          };
        });
      });
  };

  const onSaveOrUpdateDashboard = async (rawPageSections: IPageSection[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        dashboardLoading: true,
        loading: true
      };
    });
    const pageResponse = await CreatePage({
      variables: {
        name: `${capitalizeText(props.employerName || '')} Page`,
        pageType: EmployerTypeDashboardId,
      },
    }).catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          dashboardLoading: false,
          loading: false,
        };
      });
    });
    if (pageResponse?.data?.createPage?.id) {
      const pageId = pageResponse?.data?.createPage?.id;
      addPageSectionsToNewPage(pageId, rawPageSections);
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          dashboardLoading: false,
          loading: false,
        };
      });
    }
  };

  const setDummyContacts = (
    resourceCode: string,
    resourceName: string,
    data: any
  ) => {
    const name = data.name ? capitalizeText(data?.name) : '';
    const count = data?.filterData?.count || data?.count
    if (contactsState?.dummyContactList) {
      setContactsState((prev) => {
        return {
          ...prev,
          openContactListDrawer: true,
          name: name ? name : resourceName,
          resourceCode: resourceCode,
          data: data,
          selectedView: VIEW_CODE.GROUP_MEMBER_VIEW,
          isLoading: false,
          typeName: data?.type || ''
        };
      });
      const mutableData = [...contactsState?.dummyContactList];
      const countTedList = count < 100 || count < 100 ? mutableData.slice(0, count) : [...contactsState?.dummyContactList];
      setContactsState((prev) => {
        return {
          ...prev,
          contactUuidList: [...countTedList],
          total: countTedList?.length,
        };
      });
    }
  }

  const onDrillDownViewCall = async (
    resourceCode: string,
    resourceName: string,
    data: any
  ) => {
    if (isEmployer) {
      return;
    }
    setContactsState((prev) => {
      return {
        ...prev,
        isLoading: true
      }
    })
    if (getTenantForDemo()) {
      setDummyContacts(resourceCode, resourceName, data);
      return
    }
    const name = data.name ? capitalizeText(data?.name) : '';

    const response = await drillDownByEmployerId(
      resourceCode,
      employerId,
      contactsState.page,
      contactsState.pageSize,
      data,
      props.dateRange
    ).catch((err) => {
      setContactsState((prev) => {
        return {
          ...prev,
          isLoading: false
        }
      })
    });
    const responseData = response?.data?.data || {};
    if (resourceCode === ANALYTICS_RESOURCE.COMMUNICATION_STATS) {
      data.count = response?.data?.data?.totalCount;
    }
    if (response?.data?.data?.contactIds?.length) {
      setContactsState((prev) => {
        return {
          ...prev,
          openContactListDrawer: true,
          name: name ? name : resourceName,
          resourceCode: resourceCode,
          data: data,
          selectedView: VIEW_CODE.GROUP_MEMBER_VIEW,
          isLoading: false,
          responseData: responseData,
          typeName: data?.type || '',
          contactDrillDownDataList: {} as any
        };
      });
      const contactList = response?.data?.data?.contactIds;
      setContactsState((prev) => {
        return {
          ...prev,
          contactUuidList: [...contactList],
          total: response?.data?.data?.totalCount,
        };
      });
    } else if (response?.data?.data?.contactData?.length) {
      const contactUuidList = getContactUuidsFromContactData(response?.data?.data?.contactData);
      setContactsState((prev) => {
        return {
          ...prev,
          openContactListDrawer: true,
          name: name ? name : resourceName,
          resourceCode: resourceCode,
          data: data,
          selectedView: VIEW_CODE.GROUP_MEMBER_VIEW,
          isLoading: false,
          contactUuidList: [...contactUuidList],
          total: response?.data?.data?.totalCount,
          responseData: responseData,
          typeName: data?.type || '',
          contactDrillDownDataList: {} as any
        };
      });
    } else if (response?.data?.data?.contactDrillDownDataList?.length) {
      const contactUuidList = getContactUuidsFromContactDrillDownDataList(response?.data?.data?.contactDrillDownDataList);
      const contactDrillDownDataList = getContactDrillDownColumnDataList(response?.data?.data);
      setContactsState((prev) => {
        return {
          ...prev,
          openContactListDrawer: true,
          name: name ? name : resourceName,
          resourceCode: resourceCode,
          data: data,
          contactDrillDownDataList: contactDrillDownDataList as any,
          selectedView: VIEW_CODE.GROUP_MEMBER_VIEW,
          isLoading: false,
          contactUuidList: [...contactUuidList],
          typeName: data?.type || '',
          total: response?.data?.data?.totalCount,
          responseData: responseData
        };
      });
    } else if (
      response?.data?.result?.queAnsList &&
      response?.data?.result?.scoreData
    ) {
      setStateData((prev) => {
        return {
          ...prev,
          drillDownData: response?.data?.result,
          selectedView: VIEW_CODE.CUSTOMER_SATISFACTION_DRAWER,
          isLoading: false,
          responseData: responseData,
          contactDrillDownDataList: {} as any
        };
      });
      setContactsState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    } else if (response?.data?.data?.selectedDrillDownRowData) {
      const selectedFilterData = data?.filterData?.find((item: any) => {
        return item?.filterCode === 'CONDITION'
      })
      const selectedDrillDownData = response?.data?.data?.selectedDrillDownRowData?.find((item: any) => {
        return (
          item?.workflowMasterId === selectedFilterData?.workflowMasterId
        );
      })
      setStateData((prev) => {
        return {
          ...prev,
          drillDownData: response?.data?.data?.selectedDrillDownRowData,
          columnList: response?.data?.data?.columnList,
          selectedView: VIEW_CODE.TIME_SAVED_AUTOMATION_DRAWER,
          isLoading: false,
          typeName: data?.type || '',
          responseData: responseData,
          selectedFilterData: selectedDrillDownData,
        };
      });
      setContactsState((prev) => {
        return {
          ...prev,
          isLoading: false,
          name: name ? name : resourceName,
        };
      });
    } else {
      setContactsState((prev) => {
        return {
          ...prev,
          contactUuidList: [],
          openContactListDrawer: true,
          name: name ? name : resourceName,
          resourceCode: resourceCode,
          data: data,
          selectedView: VIEW_CODE.GROUP_MEMBER_VIEW,
          isLoading: false,
          responseData: responseData,
          typeName: data?.type || '',
          contactDrillDownDataList: {} as any
        };
      });
    }
  };

  const onActionPerformed = (code: string, name: string, data?: any) => {
    switch (code) {
      case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION:
        onDrillDownViewCall(code || '', name, data);
        break;
      case ANALYTICS_RESOURCE.COMMUNICATION_STATS:
        data.type = ANALYTICS_RESOURCE.COMMUNICATION_STATS;
        onDrillDownViewCall(code || '', name, data);
        break;
      case ANALYTICS_RESOURCE.AUTOMATION_TIME_SAVED:
        if (isMasterConfig) {
          return
        }
        if (getTenantForDemo()) {
          setStateData((prev) => {
            return {
              ...prev,
              drillDownData:
                AUTOMATION_TIME_SAVED_DRILL_DOWN.data.selectedDrillDownRowData,
              columnList: [],
              selectedView: VIEW_CODE.TIME_SAVED_AUTOMATION_DRAWER,
              isLoading: false,
            };
          });
          setContactsState((prev) => {
            return {
              ...prev,
              isLoading: false,
              name: name,
            };
          });
        } else {
          onDrillDownViewCall(code || '', name, data);
        }
      break;
      default:
        onDrillDownViewCall(code || '', name, data);
        break;
    }
  };


  useEffect(() => {
    if (employerId) {
      getEmployerById();
      getDashboardByEmployerId();
      getEmployeeCount();
    }
  }, [props.dateRange?.to, props?.selectedTab?.key, employerId, selectedPayerId]);

  useEffect(() => {
    if (!props.dashboardAction) {
      return;
    }
    if (!stateData.loading) {
      if (stateData.isDashboardAssign) {
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: VIEW_CODE.UPDATE_SECTION_VIEW,
          };
        });
      } else {
        props.dashboardCallback?.();
      }
    } else {
      showToast(
        toast,
        'WAIT... Dashboard Publishing is in progress.. ',
        ToastType.info
      );
      props.dashboardCallback?.();
    }
  }, [props.dashboardAction]);

  useEffect(() => {
    if (!contactsState?.responseData?.isNonMemberData && contactsState.selectedView === VIEW_CODE.GROUP_MEMBER_VIEW) {
      onDrillDownViewCall(
        contactsState.resourceCode,
        contactsState.name,
        contactsState.data
      );
    }
  }, [contactsState.page, contactsState.pageSize]);

  useEffect(() => {
    if (getTenantForDemo()) {
      getContactByCount()
    }
    getEmployerById();
    getEmployeeCount();
  }, [])

  const onDrillDownMemberListPaginationChange = (page: number, pageSize: number, extraData?: any) => {
    if (extraData.isDropDownCodeChange && contactsState.page === 1) {
      setContactsState(prev => {
        return {
          ...prev,
          filterCodeValue: extraData.filterValue
        }
      })
      const formattedFilterData = contactsState.data.filterData.map((filterObj: any) => {
        if (filterObj.filterCode !== 'DATE_RANGE' && filterObj.filterCode !== EMPLOYER_REPORT_FILTER_CODES.DASHBOARD_DATE_RANGE) {
          filterObj = {...filterObj, filterValue: extraData.filterValue};
          return filterObj
        } else {
          return filterObj
        }
      })
      const formattedDrillDownData = {...contactsState.data, filterData: formattedFilterData}
      if (extraData.filterCode && extraData.filterValue) {
        formattedDrillDownData.filterData.push({
          filterCode: extraData.filterCode,
          filterValue: extraData.filterValue,
        });
      }
      setContactsState(prev => {
        return {
          ...prev,
          data: formattedDrillDownData
        }
      })
      onDrillDownViewCall(
        contactsState.resourceCode,
        contactsState.name,
        formattedDrillDownData
      );
    } else if (extraData?.isFilterApplied && contactsState.page === 1) {
      if (contactsState.data.filterData?.length) {
        const uniqueFilterList = getUniqueObjectFromKey(contactsState.data.filterData, 'filterCode');
        if (extraData?.filterValue) {
          const givenFilterData = getFilterObjByCode(uniqueFilterList, extraData?.filterCode);
          if (givenFilterData && givenFilterData?.filterValue) {
            const tempFilterDataList = updateFilterValue(uniqueFilterList, extraData?.filterCode, extraData?.filterValue);
            const formattedDrillDownData = {...contactsState.data, filterData: tempFilterDataList}
            setContactsState(prev => {
              return {
                ...prev,
                data: formattedDrillDownData
              }
            })
            onDrillDownViewCall(
              contactsState.resourceCode,
              contactsState.name,
              formattedDrillDownData
            );
          } else {
            uniqueFilterList.push({
              filterCode: extraData?.filterCode,
              filterValue: {
                value: extraData?.filterValue
              }
            });
            const formattedDrillDownData = {...contactsState.data, filterData: uniqueFilterList}
            setContactsState(prev => {
              return {
                ...prev,
                data: formattedDrillDownData
              }
            })
            onDrillDownViewCall(
              contactsState.resourceCode,
              contactsState.name,
              formattedDrillDownData
            );
          }
        } else {
          const formattedFilterData = removeFilterObjFromList(contactsState.data.filterData, extraData?.filterCode);
          const formattedDrillDownData = {...contactsState.data, filterData: formattedFilterData}
          setContactsState(prev => {
            return {
              ...prev,
              data: formattedDrillDownData
            }
          })
          onDrillDownViewCall(
            contactsState.resourceCode,
            contactsState.name,
            formattedDrillDownData
          );
        }
      }
    } else {
      setContactsState((prev) => {
        return {
          ...prev,
          page: prev.pageSize !== pageSize ? 1 : page,
          pageSize: pageSize,
        };
      });
    }

  }
  return (
    <>
      {stateData?.loading || stateData.dashboardLoading ? (
        <div style={{
          margin: '0 auto',
          position: 'absolute',
          zIndex: 100011111,
          backgroundColor: '#0000000f',
          height: '100%',
          width: '100%',
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: 0,
          left: 0
        }}>
          <Spinner size="lg" />
        </div>
      ) : stateData?.sectionList?.length ? (
        <>
          <div className="common-style" >
            {contactsState.isLoading ? (
              <div style={{
                margin: '0 auto',
                position: 'fixed',
                zIndex: 100011111,
                backgroundColor: '#0000000f',
                height: '100%',
                width: '100%',
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0
              }}>
                <Spinner size="lg" />
              </div>
            ) : (
              <></>
            )}
              <WorkflowGraphPopup
              flowType={stateData.selectedFilterData.flowType}
              isShow={stateData.selectedFilterData.flowType ? true : false}
              workflowData={stateData.selectedFilterData}
              onClose={() => {
                setStateData(prev => {
                  return {
                    ...prev,
                    selectedView: '',
                    selectedFilterData: {}
                  }
                })
              }}
            ></WorkflowGraphPopup>
            {stateData.selectedView === VIEW_CODE.CUSTOMER_SATISFACTION_DRAWER ? (
              <CustomerSatisfactionDrawer
                drillDownData={stateData.drillDownData}
                isVisible={customerSatisfactiomDrawer}
                onCancel={() => {
                  // setCustomerSatisfactiomDrawer(false);
                  setStateData((prev) => {
                    return {
                      ...prev,
                      drillDownData: {} as any,
                      selectedView: '',
                      selectedResourceData: null
                    };
                  });
                }}
              />
            ) : <></>}
            <AnalyticsSections
              memberSatisfactionFormList={stateData.memberSatisfactionFormList}
              sectionList={stateData.sectionList}
              employerData={stateData.employerData}
              employerEnrollmentData={stateData.employerEnrollmentData}
              employerId={employerId}
              dateRangeType={props.dateRangeType}
              isEmployerRole={isEmployerRole()}
              reusableData={{...stateData.reusableData, isDashboardAssign: stateData.isDashboardAssign}}
              dateRange={props.dateRange}
              onActionPerformed={(type?: any, name?: any, data?: any) => {
                onActionPerformed?.(type, name, data);
              }}
              setStateData={(type: any, data: any) => {
                if (type == WIDGET_ACTION_CODE.OPEN_SAVING_MODAL) {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      selectedView: VIEW_CODE.SAVING_DRILL_DOWN,
                      selectedResourceCode: data,
                    };
                  });
                }
              }}
            />
          </div>
          {stateData.selectedView === VIEW_CODE.UPDATE_SECTION_VIEW ? (
            <UpdateDashboardDrawer
              rawPageSection={[...stateData.sectionList]}
              onCancel={() => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    selectedView: '',
                  };
                });
                props.dashboardCallback?.();
              }}
              onCallBackData={(data: any[]) => {
                if (data?.length) {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      sectionList: data,
                      selectedView: '',
                    };
                  });
                  props.dashboardCallback?.();
                }
              }}
            />
          ) : (
            <></>
          )}
          {contactsState.selectedView === VIEW_CODE.GROUP_MEMBER_VIEW ? (
            <DrillDownMemberList
              appliedFilterData={contactsState?.data?.filterData}
              resourceCode={contactsState.resourceCode}
              isNonMemberDrillDown={contactsState?.responseData?.isNonMemberData}
              responseData={contactsState?.responseData}
              page={contactsState.page}
              pageSize={contactsState.pageSize}
              total={contactsState.total}
              data={contactsState.data}
              onPaginationChange={onDrillDownMemberListPaginationChange}
              title={contactsState.name}
              isFromNewEmployer={true}
              contactUuidList={contactsState.contactUuidList}
              contactDrillDownData={contactsState?.contactDrillDownDataList}
              onCompleteAction={() => {
                setContactsState((prev) => {
                  return {
                    ...prev,
                    contactList: [],
                    page: DEFAULT_PAGE,
                    pageSize: DEFAULT_PAGE_SIZE,
                    selectedSegmentType: '',
                    isOpen: false,
                    openContactListDrawer: false,
                    contactUuidList: [],
                    name: '',
                    resourceCode: '',
                    data: {},
                    total: 0,
                    selectedView: '',
                  };
                });
              }}
              onClose={() => {
                setContactsState((prev) => {
                  return {
                    ...prev,
                    contactList: [],
                    page: DEFAULT_PAGE,
                    pageSize: DEFAULT_PAGE_SIZE,
                    selectedSegmentType: '',
                    isOpen: false,
                    openContactListDrawer: false,
                    contactUuidList: [],
                    name: '',
                    resourceCode: '',
                    data: {},
                    total: 0,
                    selectedView: '',
                  };
                });
              }}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        // </View>
        <View>
          <NoDataFound />
        </View>
      )}
    </>
  );
};
export default React.memo(EmployerAnalytics);
