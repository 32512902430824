import { Questionnaire } from "fhir/r4";
import { IAbility, IEhrCapability, IMlov } from "../../../../Interfaces";
import { DISPLAY_DATE_FORMAT } from "../../../../constants";
import { FHIR_RESOURCE } from "../../../../constants/FhirConstant";
import { getMomentObj, getMomentObjectWithDateStringAndFormat } from "../../../../utils/DateUtils";
import { getEnabledVitals } from "../../../../utils/VitalUtils";
import { EHR_CAPABILITY_CONST, getEHRResourceAbilities, getEHRResourceCapability } from "../../../../utils/capabilityUtils";
import { QUESTION_TYPES } from "../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/QuestionnaireConsts";
import { getAnswerOfDateQuestion, getAnswerOfMultiSelectQuestion, getAnswerOfSingleSelectQuestion, getAnswerOfTextQuestion } from "../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/QuestionnaireUtils";
import { IAllergyComponentValue } from "../FHFormio/CustomComponents/Allergies/interfaces";
import { IChiefComplaintComponentValue } from "../FHFormio/CustomComponents/ChiefComplaint/AddOrUpdateChiefComplaint/AddOrUpdateChiefComplaint";
import { IConditionComponentValue } from "../FHFormio/CustomComponents/Conditions/interfaces";
import { componentKeys } from "../FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper";
import { IEmergencyContactComponentValue } from "../FHFormio/CustomComponents/EmergencyContact/AddOrUpdateEmergencyContact/AddOrUpdateEmergencyContact";
import { getConditionName, getGroupedHXByRelation } from "../FHFormio/CustomComponents/FamilyHistory/AddOrUpdateFamilyHistory/AddOrUpdateFamilyHistoryHelper";
import { IFamilyHistoryComponentValue } from "../FHFormio/CustomComponents/FamilyHistory/interfaces";
import { IImmunizationComponentValue } from "../FHFormio/CustomComponents/Immunizations/interfaces";
import { IMedicationComponentValue } from "../FHFormio/CustomComponents/Medications/interfaces";
import { IPatientCommunicationComponentValue } from "../FHFormio/CustomComponents/PatientCommunication/AddOrUpdatePatientCommunication/AddOrUpdatePatientCommunication";
import { IPatientDemographicsComponentValue } from "../FHFormio/CustomComponents/PatientDemographics/AddOrUpdatePatientDemographics/AddOrUpdatePatientDemographics";
import { ISocialHistoryComponentValue } from "../FHFormio/CustomComponents/SocialHistory/interfaces";
import { ISurgicalHXComponentValue } from "../FHFormio/CustomComponents/SurgicalHistory/interfaces";
import { getFormattedVitalValue } from "../FHFormio/CustomComponents/Vitals/AddOrUpdateVitals/AddOrUpdateVitalsHelper";
import { IVitalsComponentValue } from "../FHFormio/CustomComponents/Vitals/interfaces";
import { IFormReportResponse, ITableCell, ITableColumn, ITableRow } from "./types";
import { Question, QuestionnaireObject } from "../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/interfaces";

export enum FieldKeys {
  firstName = 'firstName',
  lastName = 'lastName',
  birthDate = 'birthDate',
  birthSex = 'birthSex',
  gender = 'gender',
  name = 'name',
  clinicalStatus = 'clinicalStatus',
  onSetDateTime = 'onsetDateTime',
  type = 'type',
  note = 'note',
  reaction = 'reaction',
  criticality = 'criticality',
  status = 'status',
  informationSource = 'informationSource',
  effectivePeriod = 'effectivePeriod',
  discontinuedDate = 'discontinuedDate',
  stopDate = 'stopDate',
  statusReason = 'statusReason',
  sig = 'sig',
  occurrenceDateTime = 'occurrenceDateTime',
  performer = 'performer',
  doseQuantity = 'doseQuantity',
  doseQuantityUnit = 'doseQuantityUnit',
  performedDateTime = 'performedDateTime',
  relationship = 'relationship',
  condition = 'condition',
  chiefComplaint = 'chiefComplaint',
  email = 'email',
  phoneNumber = 'phoneNumber',
  relationType = 'relationType',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  zipCode = 'zipCode',
  state = 'state',
  city = 'city',
}

export const getTableDataFromHealthComponents = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  additionalData?: {
    genderMlovList?: IMlov[],
    maxRow?: number;
    practitionersData?: Record<string, any>,
  }
): {
  rows: ITableRow[];
  columns: ITableColumn[];
  totalResponses: number;
  totalAnswered: number;
} => {
  switch (component.type) {
    case componentKeys.CONDITIONS: return getTableDataForProblems(component, capabilities, additionalData?.maxRow);
    case componentKeys.ALLERGIES: return getTableDataForAllergies(component, capabilities, additionalData?.maxRow);
    case componentKeys.MEDICATIONS: return getTableDataForMedications(component, capabilities, additionalData?.maxRow);
    case componentKeys.IMMUNIZATIONS: return getTableDataForImmunization(component, capabilities, additionalData?.maxRow,additionalData?.practitionersData);
    case componentKeys.SURGICAL_HISTORY: return getTableDataForSurgicalHX(component, capabilities, additionalData?.maxRow);
    case componentKeys.FAMILY_HISTORY: return getTableDataForFamilyHX(component, capabilities, additionalData?.maxRow);
    case componentKeys.VITALS: return getTableDataForVitals(component, capabilities, additionalData?.maxRow);
    case componentKeys.CHIEF_COMPLAINT: return getTableDataForChiefComplaint(component, capabilities, additionalData?.maxRow);
    case componentKeys.PATIENT_DEMOGRAPHICS: return getTableDataForPatientDemographics(component, capabilities, additionalData?.genderMlovList || [], additionalData?.maxRow);
    case componentKeys.PATIENT_COMMUNICATION: return getTableDataForPatientCommunication(component, capabilities, additionalData?.maxRow);
    case componentKeys.PATIENT_ADDRESS: return getTableDataForPatientCommunication(component, capabilities, additionalData?.maxRow, true);
    case componentKeys.EMERGENCY_CONTACT: return getTableDataForEmergencyContact(component, capabilities, additionalData?.maxRow);
    default: return { rows: [], columns: [], totalResponses: 0, totalAnswered: 0 };
  }
}

export const getTableDataFromNestedHealthComponents = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  additionalData?: {
    genderMlovList?: IMlov[],
    maxRow?: number;
  }
): {
  rows: ITableRow[];
  columns: ITableColumn[];
  name: string;
  totalResponses: number;
  totalAnswered: number;
}[] => {
  switch (component.type) {
    case componentKeys.SOCIAL_HISTORY: return getTableDataSocialHXSections(component, capabilities, additionalData?.maxRow);
    case 'customsurvey': return getTableDataSurveyComponent(component, capabilities, additionalData?.maxRow);
    default: return [];
  }
}

const getTableDataSurveyComponent = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  maxRow?: number
): {rows: ITableRow[]; columns: ITableColumn[], name: string; totalResponses: number; totalAnswered: number;}[] => {
  const sections: {rows: ITableRow[]; columns: ITableColumn[]; name: string; totalResponses: number; totalAnswered: number;}[] = [];
  const isMultiSelect = component.allowMultiSelection;
  const selectedValues = component.selectedValues || [];
  const tableRows: ITableRow[] = [];
  const tableColumns: ITableColumn[] = [
    {
      type: 'header',
      value: 'Responder',
      code: 'contact',
      width: 300
    }
  ];
  (component.questions || []).forEach((question) => {
    tableColumns.push({
      type: 'header',
      value: question.label || '',
      code: question.value || '',
      width: 300
    });
  });
  const totalResponses = selectedValues.length;
  let totalAnswered = 0;
  selectedValues.forEach((item) => {
    const response: {[index: string]: string | string[]} =
      typeof item.selectedValue === 'string'
        ? JSON.parse(item.selectedValue)
        : item.selectedValue || {};
    const tableCells: ITableCell[] = [];
    let isDataAvailable = false;
    tableColumns.forEach((column) => {
      switch (column.code) {
        case 'contact':
          tableCells.push({
            type: 'contact',
            value: item.contactId || '-',
            width: 300,
          });
          break;
        default:
          const value = response?.[column.code];
          let display = '-';
          if (value) {
            if (isMultiSelect && Array.isArray(value)) {
              const displayList: string[] = [];
              value.forEach((singleValue) => {
                const singleValueLabel = component.values?.find(item => item.value === singleValue)?.label;
                if (singleValueLabel) {
                  displayList.push(singleValueLabel);
                }
              });
              display = displayList.join(', ');
            } else {
              display = component.values?.find(item => item.value === value)?.label || '-';
            }
          }
          isDataAvailable = isDataAvailable || (!!display && display !== '-');
          tableCells.push({
            type: 'string',
            value: display || '-',
            columnCode: FieldKeys.firstName,
            width: 200,
          });
          break;
      }
    });
    if (isDataAvailable) {
      totalAnswered += 1;
      tableRows.push({
        contactId: item.contactId,
        cells: tableCells,
      })
    }
  });

  return [{rows: maxRow ? tableRows.slice(0, maxRow) : tableRows, columns: tableColumns, name: component.label || '', totalAnswered, totalResponses}];
}

const getTableDataSocialHXSections = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  maxRow?: number
): {rows: ITableRow[]; columns: ITableColumn[], name: string; totalResponses: number; totalAnswered: number;}[] => {
  const sections: {rows: ITableRow[]; columns: ITableColumn[]; name: string; totalResponses: number; totalAnswered: number;}[] = [];
  if (component.isSocialHistoryText) {
    (component.groups || []).forEach((group) => {
      const groupName = group.questionnaire?.resource?.name || 'Social History';
      const tableRows: ITableRow[] = [];
      const tableColumns: ITableColumn[] = [
        {
          type: 'header',
          value: 'Responder',
          code: 'contact',
          width: 300
        },
        {
          type: 'header',
          value: groupName,
          code: 'socialHX',
          width: 700
        }
      ];
      const selectedValues = group.selectedValues || [];
      let totalAnswered = 0;
      selectedValues.forEach((item) => {
        const response: ISocialHistoryComponentValue =
          typeof item.selectedValue === 'string'
            ? JSON.parse(item.selectedValue)
            : item.selectedValue || {};
        const tableCells: ITableCell[] = [];
        let isDataAvailable = false;
        tableColumns.forEach((column) => {
          switch (column.code) {
            case 'contact':
              tableCells.push({
                type: 'contact',
                value: item.contactId || '-',
                width: 300,
              });
              break;
            case 'socialHX':
              const socialHXList = (response.questionnaire || []).map((item) => item.text || '-');
              isDataAvailable = isDataAvailable || socialHXList.length > 0;
              tableCells.push({
                type: socialHXList.length > 1 ? 'points' : 'string',
                value: socialHXList.length > 1 ? socialHXList : (socialHXList.length === 1 ? socialHXList[0] : '-'),
                columnCode: 'socialHX',
                width: 700,
              });
              break;
            default: break;
          }
        });
        if (isDataAvailable) {
          totalAnswered += 1;
          tableRows.push({
            contactId: item.contactId,
            cells: tableCells,
          });
        }
      });

      sections.push({ rows: maxRow ? tableRows.slice(0, maxRow) : tableRows, columns: tableColumns, name: groupName, totalAnswered, totalResponses: selectedValues.length});
    });
  } else {
    (component.groups || []).forEach((group) => {
      const tableRows: ITableRow[] = [];
      const tableColumns: ITableColumn[] = [
        {
          type: 'header',
          value: 'Responder',
          code: 'contact',
          width: 300
        }
      ];
      const groupName = group.questionnaire?.resource?.name || '';
      group.questionnaire?.resource?.item?.forEach((question) => {
        tableColumns.push({
          value: question.text || '',
          code: question.linkId,
          type: 'header',
          width: 300,
          inputType: question.type || '',
        })
      });
      const questions = getQuestionListFromQuestionnaire(group.questionnaire?.resource);
      const selectedValues = group.selectedValues || [];
      let totalAnswered = 0;
      let isDataAvailable = false;
      selectedValues.forEach((item, index) => {
        const response: QuestionnaireObject =
          typeof item.selectedValue === 'string'
            ? JSON.parse(item.selectedValue)
            : item.selectedValue || {};
        const tableCells: ITableCell[] = [];
        tableColumns.forEach((column) => {
          switch (column.code) {
            case 'contact':
              tableCells.push({
                type: 'contact',
                value: item.contactId || '-',
                width: 300,
              });
              break;
            default:
              const questionnaire = response;
              const currentQuestion = questions.find((question) => question.id === column.code);
              if (currentQuestion) {
                const answers = questionnaire?.item || [];
                const type = column.inputType || '';
                let answer = '-';
                switch (type) {
                  case QUESTION_TYPES.CHOICE:
                  case QUESTION_TYPES.OPEN_CHOICE:
                    const selectedAnswer = getAnswerOfSingleSelectQuestion(currentQuestion, answers);
                    answer = selectedAnswer?.display || '-';
                    break;

                  case QUESTION_TYPES.FREE_TEXT:
                  case QUESTION_TYPES.TEXT:
                  case QUESTION_TYPES.STRING:
                    const textAnswer = getAnswerOfTextQuestion(currentQuestion, answers);
                    answer = textAnswer || '-';
                    break;

                  case QUESTION_TYPES.NUMERIC:
                    const numericAnswer = getAnswerOfTextQuestion(currentQuestion, answers);
                    answer = numericAnswer || '-';
                    break;

                  case QUESTION_TYPES.RADIO:
                    const radioAnswer = getAnswerOfSingleSelectQuestion(currentQuestion, answers);
                    answer = radioAnswer?.display || '-';
                    break;

                  case QUESTION_TYPES.MULTI_SELECT:
                    const multiAnswer = getAnswerOfMultiSelectQuestion(currentQuestion, answers);
                    answer = multiAnswer.map((answer) => answer.display).join(', ') || '-';
                    break;

                  case QUESTION_TYPES.DATE:
                  case QUESTION_TYPES.DATE_NEW:
                    const dateAnswer = getAnswerOfDateQuestion(currentQuestion, answers);
                    const formattedDate = dateAnswer ? getMomentObj(new Date(dateAnswer)).format(DISPLAY_DATE_FORMAT) : undefined;
                    answer = formattedDate || '-';
                    break;
                }
                isDataAvailable = isDataAvailable || (!!answer && answer !== '-');
                tableCells.push({
                  type: 'string',
                  value: answer || '-',
                  columnCode: column.code,
                  width: 300,
                });
              } else {
                tableCells.push({
                  type: 'string',
                  value: '-',
                  columnCode: column.code,
                  width: 300,
                });
              }
              break;
          }
        });
        if (isDataAvailable) {
          totalAnswered += 1;
          tableRows.push({
            contactId: item.contactId,
            cells: tableCells,
          });
        }
      });
      sections.push({ rows: maxRow ? tableRows.slice(0, maxRow) : tableRows, columns: tableColumns, name: groupName, totalAnswered, totalResponses: selectedValues.length });
    })
  }

  return sections;
};

const getTableDataForPatientCommunication = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  maxRow?: number,
  isPatientAddress?: boolean,
): { rows: ITableRow[]; columns: ITableColumn[]; totalResponses: number; totalAnswered: number; } => {
  const selectedValues = component.selectedValues || [];
  const totalResponses = selectedValues.length;
  let totalAnswered = 0;
  const tableRows: ITableRow[] = [];
  let tableColumns: ITableColumn[] = [
    {
      type: 'header',
      value: 'Responder',
      code: 'contact',
      width: 300
    }
  ];
  if (!isPatientAddress) {
    tableColumns =  tableColumns.concat([{
      value: 'Email',
      code: FieldKeys.email,
      type: 'header',
      width: 300
    },
    {
      value: 'Phone Number',
      code: FieldKeys.phoneNumber,
      type: 'header',
      width: 200
    }])
  }
  tableColumns = tableColumns.concat([{
    value: 'Address Line 1',
    code: FieldKeys.addressLine1,
    type: 'header',
    width: 200
  },
  {
    value: 'Address Line 2',
    code: FieldKeys.addressLine2,
    type: 'header',
    width: 200
  },
  {
    value: 'Zip Code',
    code: FieldKeys.zipCode,
    type: 'header',
    width: 200
  },
  {
    value: 'State',
    code: FieldKeys.state,
    type: 'header',
    width: 200
  },
  {
    value: 'City',
    code: FieldKeys.city,
    type: 'header',
    width: 200
  }]);
  selectedValues.forEach((item) => {
    const response: IPatientCommunicationComponentValue =
      typeof item.selectedValue === 'string'
        ? JSON.parse(item.selectedValue)
        : item.selectedValue || {};
    const tableCells: ITableCell[] = [];
    let isDataAvailable = false;
    tableColumns.forEach((column) => {
      switch (column.code) {
        case 'contact':
          tableCells.push({
            type: 'contact',
            value: item.contactId || '-',
            width: 300,
          });
          break;
        case FieldKeys.email:
          isDataAvailable = isDataAvailable || !!response.email;
          tableCells.push({
            type: 'string',
            value: response.email || '-',
            columnCode: FieldKeys.email,
            width: 300,
          });
          break;
        case FieldKeys.phoneNumber:
          isDataAvailable = isDataAvailable || !!response.phoneNumber;
          tableCells.push({
            type: 'string',
            value: response.phoneNumber || '-',
            columnCode: FieldKeys.phoneNumber,
            width: 200,
          });
          break;
        case FieldKeys.addressLine1:
          isDataAvailable = isDataAvailable || !!response.addressLine1;
          tableCells.push({
            type: 'string',
            value: response.addressLine1 || '-',
            columnCode: FieldKeys.addressLine1,
            width: 200,
          });
          break;
        case FieldKeys.addressLine2:
          isDataAvailable = isDataAvailable || !!response.addressLine2;
          tableCells.push({
            type: 'string',
            value: response.addressLine2 || '-',
            columnCode: FieldKeys.addressLine2,
            width: 200,
          });
          break;
        case FieldKeys.zipCode:
          isDataAvailable = isDataAvailable || !!response.zipCode?.code;
          tableCells.push({
            type: 'string',
            value: response.zipCode?.code || '-',
            columnCode: FieldKeys.zipCode,
            width: 200,
          });
          break;
        case FieldKeys.state:
          isDataAvailable = isDataAvailable || !!response.state?.name;
          tableCells.push({
            type: 'string',
            value: response.state?.name || '-',
            columnCode: FieldKeys.state,
            width: 200,
          });
          break;
        case FieldKeys.city:
          isDataAvailable = isDataAvailable || !!response.city?.name;
          tableCells.push({
            type: 'string',
            value: response.city?.name || '-',
            columnCode: FieldKeys.city,
            width: 200,
          });
          break;
      }
    });
    if (isDataAvailable) {
      totalAnswered += 1;
      tableRows.push({
        contactId: item.contactId,
        cells: tableCells,
      });
    }
  });
  return { rows: maxRow ? tableRows.slice(0, maxRow) : tableRows, columns: tableColumns, totalAnswered, totalResponses };
};

const getTableDataForEmergencyContact = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  maxRow?: number,
): { rows: ITableRow[]; columns: ITableColumn[]; totalResponses: number; totalAnswered: number; } => {
  const selectedValues = component.selectedValues || [];
  const tableRows: ITableRow[] = [];
  const tableColumns: ITableColumn[] = [
    {
      type: 'header',
      value: 'Responder',
      code: 'contact',
      width: 300
    },
    {
      value: 'First Name',
      code: FieldKeys.firstName,
      type: 'header',
      width: 200
    },
    {
      value: 'Last Name',
      code: FieldKeys.lastName,
      type: 'header',
      width: 200
    },
    {
      value: 'Email',
      code: FieldKeys.email,
      type: 'header',
      width: 200
    },
    {
      value: 'Phone Number',
      code: FieldKeys.phoneNumber,
      type: 'header',
      width: 200
    },
    {
      value: 'Relation',
      code: FieldKeys.relationType,
      type: 'header',
      width: 200
    },
  ];
  const totalResponses = selectedValues.length;
  let totalAnswered = 0;
  selectedValues.forEach((item, index) => {
    const response: IEmergencyContactComponentValue =
      typeof item.selectedValue === 'string'
        ? JSON.parse(item.selectedValue)
        : item.selectedValue || {};
    const tableCells: ITableCell[] = [];
    let isDataAvailable = false;
    tableColumns.forEach((column) => {
      switch (column.code) {
        case 'contact':
          tableCells.push({
            type: 'contact',
            value: item.contactId || '-',
            width: 300,
          });
          break;
        case FieldKeys.firstName:
          isDataAvailable = isDataAvailable || !!response.firstName;
          tableCells.push({
            type: 'string',
            value: response.firstName || '-',
            columnCode: FieldKeys.firstName,
            width: 200,
          });
          break;
        case FieldKeys.lastName:
          isDataAvailable = isDataAvailable || !!response.lastName;
          tableCells.push({
            type: 'string',
            value: response.lastName || '-',
            columnCode: FieldKeys.lastName,
            width: 200,
          });
          break;
        case FieldKeys.email:
          isDataAvailable = isDataAvailable || !!response.email;
          tableCells.push({
            type: 'string',
            value: response.email || '-',
            columnCode: FieldKeys.email,
            width: 200,
          });
          break;
        case FieldKeys.phoneNumber:
          isDataAvailable = isDataAvailable || !!response.phoneNumber;
          tableCells.push({
            type: 'string',
            value: response.phoneNumber || '-',
            columnCode: FieldKeys.phoneNumber,
            width: 200,
          });
          break;
        case FieldKeys.relationType:
          isDataAvailable = isDataAvailable || !!response.relationType?.code;
          tableCells.push({
            type: 'string',
            value: response.relationType?.code || '-',
            columnCode: FieldKeys.relationType,
            width: 200,
          });
          break;
      }
    });
    if (isDataAvailable) {
      totalAnswered += 1;
      tableRows.push({
        contactId: item.contactId,
        cells: tableCells,
      })
    }
  });
  return { rows: maxRow ? tableRows.slice(0, maxRow) : tableRows, columns: tableColumns, totalAnswered, totalResponses };
};

const getTableDataForChiefComplaint = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  maxRow?: number,
): { rows: ITableRow[]; columns: ITableColumn[]; totalResponses: number; totalAnswered: number; } => {
  const selectedValues = component.selectedValues || [];
  const tableRows: ITableRow[] = [];
  const tableColumns: ITableColumn[] = [
    {
      type: 'header',
      value: 'Responder',
      code: 'contact',
      width: 300
    },
    {
      value: 'Chief Complaint',
      code: FieldKeys.chiefComplaint,
      type: 'header',
      width: 200
    }
  ];
  const totalResponses = selectedValues.length;
  let totalAnswered = 0;
  selectedValues.forEach((item, index) => {
    const response: IChiefComplaintComponentValue =
      typeof item.selectedValue === 'string'
        ? JSON.parse(item.selectedValue)
        : item.selectedValue || {};
    const tableCells: ITableCell[] = [];
    let isDataAvailable = false;
    tableColumns.forEach((column) => {
      switch (column.code) {
        case 'contact':
          tableCells.push({
            type: 'contact',
            value: item.contactId || '-',
            width: 300,
          });
          break;
        case FieldKeys.chiefComplaint:
          isDataAvailable = isDataAvailable || !!(response?.chiefComplaint?.displayName || response?.chiefComplaint?.code);
          tableCells.push({
            type: 'string',
            value: response?.chiefComplaint?.displayName || response?.chiefComplaint?.code || '-',
            columnCode: FieldKeys.chiefComplaint,
            width: 200,
          });
          break;
      }
    });
    if (isDataAvailable) {
      totalAnswered += 1;
      tableRows.push({
        contactId: item.contactId,
        cells: tableCells,
      })
    }
  });
  return { rows: maxRow ? tableRows.slice(0, maxRow) : tableRows, columns: tableColumns, totalAnswered, totalResponses };
};

const getTableDataForFamilyHX = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  maxRow?: number,
): { rows: ITableRow[]; columns: ITableColumn[]; totalResponses: number; totalAnswered: number; } => {
  const selectedValues = component.selectedValues || [];
  const tableRows: ITableRow[] = [];
  const tableColumns: ITableColumn[] = [
    {
      value: 'Responder',
      code: 'contact',
      type: 'header',
      width: 300

    },
    {
      value: 'Relationship',
      code: FieldKeys.relationship,
      type: 'header',
      width: 300
    },
    {
      value: 'Conditions',
      code: FieldKeys.condition,
      type: 'header',
      width: 300
    },
  ];

  const totalResponses = selectedValues.length;
  let totalAnswered = 0;
  selectedValues.forEach((item, index) => {
    const response: IFamilyHistoryComponentValue =
      typeof item.selectedValue === 'string'
        ? JSON.parse(item.selectedValue)
        : item.selectedValue || {};

    const tableCells: ITableCell[] = [];
    tableCells.push({
      value: item.contactId,
      type: 'contact',
      width: 300,
    });
    const internalTableCells: {[index: string]: ITableCell[]} = {};
    const historyList = getGroupedHXByRelation(response.history || []);
    (historyList || []).forEach((item) => {
      tableColumns.forEach((column) => {
        switch (column.code) {
          case FieldKeys.relationship:
            if (!internalTableCells[FieldKeys.relationship]) {
              internalTableCells[FieldKeys.relationship] = [];
            }
            internalTableCells[FieldKeys.relationship].push({
              type: 'string',
              value: item.relation || '-',
              columnCode: FieldKeys.name,
              width: 300,
            });
            break;

          case FieldKeys.condition:
            const conditionList: string[] = item.list?.map((condition) => {
              return getConditionName(condition);
            }) || [];

            if (!internalTableCells[FieldKeys.condition]) {
              internalTableCells[FieldKeys.condition] = [];
            }
            internalTableCells[FieldKeys.condition].push({
              type: conditionList.length > 1 ? 'points' : 'string',
              value: conditionList.length > 1 ? conditionList : (conditionList.length === 1 ? conditionList[0] : '-'),
              columnCode: FieldKeys.condition,
              width: 300,
            });
            break;
        }
      });
    });
    let isDataAvailable = false;
    tableColumns.forEach((column) => {
      if (column.code !== 'contact') {
        if (internalTableCells[column.code]) {
          isDataAvailable = true;
          tableCells.push({
            type: 'array',
            value: internalTableCells[column.code],
            width: 300,
          });
        } else {
          tableCells.push({
            type: 'array',
            value: [{
              type: 'string',
              value: '-',
              columnCode: column.code,
              width: 300,
            }],
            width: 300,
          });
        }
      }
    })

    if (isDataAvailable) {
      totalAnswered += 1;
      tableRows.push({
        contactId: item.contactId,
        cells: tableCells,
      });
    }
  });
  return { rows: maxRow ? tableRows.slice(0, maxRow) : tableRows, columns: tableColumns, totalAnswered, totalResponses };
};

const getTableDataForSurgicalHX = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  maxRow?: number,
): { rows: ITableRow[]; columns: ITableColumn[]; totalResponses: number; totalAnswered: number; } => {
  const selectedValues = component.selectedValues || [];
  const tableRows: ITableRow[] = [];
  const tableColumns: ITableColumn[] = [
    {
      value: 'Responder',
      code: 'contact',
      type: 'header',
      width: 300

    },
    {
      value: 'Name',
      code: FieldKeys.name,
      type: 'header',
      width: 300
    },
  ];
  const capabilityData = getEHRResourceAbilities(
    FHIR_RESOURCE.PROCEDURE,
    capabilities
  );
  if (canShowField(FieldKeys.status, capabilityData)) {
    tableColumns.push({
      value: 'Status',
      code: FieldKeys.status,
      type: 'header',
      width: 200
    });
  }
  const dateFormat =
    capabilityData?.keyAllowedOperations?.performedDateTime?.format;
  const isOnlyYearFormat = dateFormat === 'YYYY';
  if (canShowField(FieldKeys.performedDateTime, capabilityData)) {
    tableColumns.push({
      value: 'Performed Date',
      code: FieldKeys.performedDateTime,
      type: 'header',
      width: 200
    });
  }

  const totalResponses = selectedValues.length;
  let totalAnswered = 0;
  selectedValues.forEach((item, index) => {
    const response: ISurgicalHXComponentValue =
      typeof item.selectedValue === 'string'
        ? JSON.parse(item.selectedValue)
        : item.selectedValue || {};

    const tableCells: ITableCell[] = [];
    tableCells.push({
      value: item.contactId,
      type: 'contact',
      width: 300,
    });
    const internalTableCells: {[index: string]: ITableCell[]} = {};
    (response.surgicalHistory || []).forEach((item) => {
      tableColumns.forEach((column) => {
        switch (column.code) {
          case FieldKeys.name:
            if (!internalTableCells[FieldKeys.name]) {
              internalTableCells[FieldKeys.name] = [];
            }
            internalTableCells[FieldKeys.name].push({
              type: 'string',
              value: item.name || '-',
              columnCode: FieldKeys.name,
              width: 300,
            });
            break;

          case FieldKeys.performedDateTime:
            let displayDate = item.performedDateTime || '-';
            if (item.performedDateTime) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.performedDateTime,
                dateFormat || ''
              );
              displayDate = isOnlyYearFormat ? item.performedDateTime : date.format(DISPLAY_DATE_FORMAT);
            }
            if (!internalTableCells[FieldKeys.performedDateTime]) {
              internalTableCells[FieldKeys.performedDateTime] = [];
            }
            internalTableCells[FieldKeys.performedDateTime].push({
              type: 'string',
              value: displayDate,
              columnCode: FieldKeys.performedDateTime,
              width: 200,
            });
            break;

          case FieldKeys.status:
            const statusList =
              capabilityData?.keyAllowedOperations?.status
                ?.possibleValues || [];
            const statusValue = statusList.find((status: any) => {
              return status.code === item.status?.code;
            })?.display;
            if (!internalTableCells[FieldKeys.status]) {
              internalTableCells[FieldKeys.status] = [];
            }
            internalTableCells[FieldKeys.status].push({
              type: 'string',
              value: statusValue || '-',
              columnCode: FieldKeys.status,
              width: 200,
            });
            break;
        }
      });
    });
    let isDataAvailable = false;
    tableColumns.forEach((column) => {
      if (column.code !== 'contact') {
        if (internalTableCells[column.code]) {
          isDataAvailable = true;
          tableCells.push({
            type: 'array',
            value: internalTableCells[column.code],
            width: column.code === FieldKeys.name ? 300 : 200,
          });
        } else {
          tableCells.push({
            type: 'array',
            value: [{
              type: 'string',
              value: '-',
              columnCode: column.code,
              width: column.code === FieldKeys.name ? 300 : 200,
            }],
            width: column.code === FieldKeys.name ? 300 : 200,
          });
        }
      }
    })

    if (isDataAvailable) {
      totalAnswered += 1;
      tableRows.push({
        contactId: item.contactId,
        cells: tableCells,
      });
    }
  });
  return { rows: maxRow ? tableRows.slice(0, maxRow) : tableRows, columns: tableColumns, totalAnswered, totalResponses };
};

const getTableDataForImmunization = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  maxRow?: number,
  practitionersData?: Record<string, any>,
): { rows: ITableRow[]; columns: ITableColumn[]; totalResponses: number; totalAnswered: number; } => {
  const selectedValues = component.selectedValues || [];
  const tableRows: ITableRow[] = [];
  const tableColumns: ITableColumn[] = [
    {
      value: 'Responder',
      code: 'contact',
      type: 'header',
      width: 300

    },
    {
      value: 'Name',
      code: FieldKeys.name,
      type: 'header',
      width: 300
    },
  ];
  const capabilityData = getEHRResourceAbilities(
    FHIR_RESOURCE.IMMUNIZATION,
    capabilities
  );
  const dateFormat =
    capabilityData?.keyAllowedOperations?.occurrenceDateTime?.format;
  const isOnlyYearFormat = dateFormat === 'YYYY';
  if (canShowField(FieldKeys.occurrenceDateTime, capabilityData)) {
    tableColumns.push({
      value: 'Date Administered',
      code: FieldKeys.occurrenceDateTime,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.performer, capabilityData)) {
    tableColumns.push({
      value: 'Practitioner',
      code: FieldKeys.performer,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.doseQuantity, capabilityData)) {
    tableColumns.push({
      value: 'Dose Quantity',
      code: FieldKeys.doseQuantity,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.doseQuantityUnit, capabilityData)) {
    tableColumns.push({
      value: 'Dose Quantity Unit',
      code: FieldKeys.doseQuantityUnit,
      type: 'header',
      width: 200
    });
  }

  const totalResponses = selectedValues.length;
  let totalAnswered = 0;
  selectedValues.forEach((item, index) => {
    const response: IImmunizationComponentValue =
      typeof item.selectedValue === 'string'
        ? JSON.parse(item.selectedValue)
        : item.selectedValue || {};

    const tableCells: ITableCell[] = [];
    tableCells.push({
      value: item.contactId,
      type: 'contact',
      width: 300,
    });
    const internalTableCells: {[index: string]: ITableCell[]} = {};
    (response.immunizations || []).forEach((item) => {
      tableColumns.forEach((column) => {
        switch (column.code) {
          case FieldKeys.name:
            if (!internalTableCells[FieldKeys.name]) {
              internalTableCells[FieldKeys.name] = [];
            }
            internalTableCells[FieldKeys.name].push({
              type: 'string',
              value: item.name || '-',
              columnCode: FieldKeys.name,
              width: 300,
            });
            break;

          case FieldKeys.occurrenceDateTime:
            let displayDate = item.occurrenceDateTime || '-';
            if (item.occurrenceDateTime) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.occurrenceDateTime,
                dateFormat || ''
              );
              displayDate = isOnlyYearFormat ? item.occurrenceDateTime : date.format(DISPLAY_DATE_FORMAT);
            }
            if (!internalTableCells[FieldKeys.occurrenceDateTime]) {
              internalTableCells[FieldKeys.occurrenceDateTime] = [];
            }
            internalTableCells[FieldKeys.occurrenceDateTime].push({
              type: 'string',
              value: displayDate,
              columnCode: FieldKeys.occurrenceDateTime,
              width: 200,
            });
            break;

            
          case FieldKeys.performer:
            if (!internalTableCells[FieldKeys.performer]) {
              internalTableCells[FieldKeys.performer] = [];
            }
            internalTableCells[FieldKeys.performer].push({
              type: 'string',
              value: practitionersData && item.practitioner?.id ? (practitionersData[item.practitioner?.id]?.name?.[0]?.given?.[0] || item.practitioner?.id) : '-',
              columnCode: FieldKeys.performer,
              width: 200,
            });
            break;

          case FieldKeys.doseQuantity:
            if (!internalTableCells[FieldKeys.doseQuantity]) {
              internalTableCells[FieldKeys.doseQuantity] = [];
            }
            internalTableCells[FieldKeys.doseQuantity].push({
              type: 'string',
              value: item.doseQuantity || '-',
              columnCode: FieldKeys.doseQuantity,
              width: 200,
            });
            break;

          case FieldKeys.doseQuantityUnit:
            if (!internalTableCells[FieldKeys.doseQuantityUnit]) {
              internalTableCells[FieldKeys.doseQuantityUnit] = [];
            }
            internalTableCells[FieldKeys.doseQuantityUnit].push({
              type: 'string',
              value: item.doseQuantityUnit || '-',
              columnCode: FieldKeys.doseQuantityUnit,
              width: 200,
            });
            break;
        }
      });
    });
    let isDataAvailable = false;
    tableColumns.forEach((column) => {
      if (column.code !== 'contact') {
        if (internalTableCells[column.code]) {
          isDataAvailable = true;
          tableCells.push({
            type: 'array',
            value: internalTableCells[column.code],
            width: column.code === FieldKeys.name ? 300 : 200,
          });
        } else {
          tableCells.push({
            type: 'array',
            value: [{
              type: 'string',
              value: '-',
              columnCode: column.code,
              width: column.code === FieldKeys.name ? 300 : 200,
            }],
            width: column.code === FieldKeys.name ? 300 : 200,
          });
        }
      }
    })
    if (isDataAvailable) {
      totalAnswered += 1;
      tableRows.push({
        contactId: item.contactId,
        cells: tableCells,
      });
    }
  });
  return { rows: maxRow ? tableRows.slice(0, maxRow) : tableRows, columns: tableColumns, totalAnswered, totalResponses };
};

const getTableDataForMedications = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  maxRow?: number,
): { rows: ITableRow[]; columns: ITableColumn[]; totalResponses: number; totalAnswered: number; } => {
  const selectedValues = component.selectedValues || [];
  const tableRows: ITableRow[] = [];
  const tableColumns: ITableColumn[] = [
    {
      value: 'Responder',
      code: 'contact',
      type: 'header',
      width: 300

    },
    {
      value: 'Name',
      code: FieldKeys.name,
      type: 'header',
      width: 300
    },
  ];
  const capabilityData = getEHRResourceAbilities(
    FHIR_RESOURCE.MEDICATION_STATEMENT,
    capabilities
  );
  if (canShowField(FieldKeys.status, capabilityData)) {
    tableColumns.push({
      value: 'Status',
      code: FieldKeys.status,
      type: 'header',
      width: 200
    });
  }

  if (canShowField(FieldKeys.informationSource, capabilityData)) {
    tableColumns.push({
      value: 'Practitioner',
      code: FieldKeys.informationSource,
      type: 'header',
      width: 200
    });
  }
  const dateFormat =
    capabilityData?.keyAllowedOperations?.effectivePeriod?.format;
  const isOnlyYearFormat = dateFormat === 'YYYY';
  if (canShowField(FieldKeys.effectivePeriod, capabilityData)) {
    tableColumns.push({
      value: 'Started Medication On',
      code: FieldKeys.effectivePeriod,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.discontinuedDate, capabilityData)) {
    tableColumns.push({
      value: 'Discontinued Date',
      code: FieldKeys.discontinuedDate,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.stopDate, capabilityData)) {
    tableColumns.push({
      value: 'Stop Date',
      code: FieldKeys.stopDate,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.sig, capabilityData)) {
    tableColumns.push({
      value: 'Sig',
      code: FieldKeys.sig,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.note, capabilityData)) {
    tableColumns.push({
      value: 'Note',
      code: FieldKeys.note,
      type: 'header',
      width: 200
    });
  }

  const totalResponses = selectedValues.length;
  let totalAnswered = 0;
  selectedValues.forEach((item, index) => {
    const response: IMedicationComponentValue =
      typeof item.selectedValue === 'string'
        ? JSON.parse(item.selectedValue)
        : item.selectedValue || {};

    const tableCells: ITableCell[] = [];
    tableCells.push({
      value: item.contactId,
      type: 'contact',
      width: 300,
    });
    const internalTableCells: {[index: string]: ITableCell[]} = {};
    (response.medicationStatements || []).forEach((item) => {
      tableColumns.forEach((column) => {
        switch (column.code) {
          case FieldKeys.name:
            if (!internalTableCells[FieldKeys.name]) {
              internalTableCells[FieldKeys.name] = [];
            }
            internalTableCells[FieldKeys.name].push({
              type: 'string',
              value: item.name || '-',
              columnCode: FieldKeys.name,
              width: 300,
            });
            break;

          case FieldKeys.status:
            const statusList =
              capabilityData?.keyAllowedOperations?.status
                ?.possibleValues || [];
            const statusValue = statusList.find((status: any) => {
              return status.code === item.status?.code;
            })?.display;
            if (!internalTableCells[FieldKeys.status]) {
              internalTableCells[FieldKeys.status] = [];
            }
            internalTableCells[FieldKeys.status].push({
              type: 'string',
              value: statusValue || '-',
              columnCode: FieldKeys.status,
              width: 200,
            });
            break;

          case FieldKeys.informationSource:
            if (!internalTableCells[FieldKeys.informationSource]) {
              internalTableCells[FieldKeys.informationSource] = [];
            }
            internalTableCells[FieldKeys.informationSource].push({
              type: 'string',
              value: item.informationSource?.id || '-',
              columnCode: FieldKeys.informationSource,
              width: 200,
            });
            break;

          case FieldKeys.effectivePeriod:
            let displayDate = item.effectivePeriod || '-';
            if (item.effectivePeriod) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.effectivePeriod,
                dateFormat || ''
              );
              displayDate = isOnlyYearFormat ? item.effectivePeriod : date.format(DISPLAY_DATE_FORMAT);
            }
            if (!internalTableCells[FieldKeys.effectivePeriod]) {
              internalTableCells[FieldKeys.effectivePeriod] = [];
            }
            internalTableCells[FieldKeys.effectivePeriod].push({
              type: 'string',
              value: displayDate,
              columnCode: FieldKeys.effectivePeriod,
              width: 200,
            });
            break;

          case FieldKeys.discontinuedDate:
            let discontinuedDisplayDate = item.discontinuedDate || '-';
            if (item.discontinuedDate) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.discontinuedDate,
                dateFormat || ''
              );
              discontinuedDisplayDate = isOnlyYearFormat ? item.discontinuedDate : date.format(DISPLAY_DATE_FORMAT);
            }
            if (!internalTableCells[FieldKeys.discontinuedDate]) {
              internalTableCells[FieldKeys.discontinuedDate] = [];
            }
            internalTableCells[FieldKeys.discontinuedDate].push({
              type: 'string',
              value: discontinuedDisplayDate,
              columnCode: FieldKeys.discontinuedDate,
              width: 200,
            });
            break;

          case FieldKeys.stopDate:
            const stopDateFormat = capabilityData?.keyAllowedOperations?.stopDate?.format;
            const isOnlyYearFormatStopDate = stopDateFormat === 'YYYY';
            let stopDisplayDate = item.stopDate || '-';
            if (item.stopDate) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.stopDate,
                stopDateFormat || ''
              );
              stopDisplayDate = isOnlyYearFormatStopDate ? item.stopDate : date.format(DISPLAY_DATE_FORMAT);
            }
            if (!internalTableCells[FieldKeys.stopDate]) {
              internalTableCells[FieldKeys.stopDate] = [];
            }
            internalTableCells[FieldKeys.stopDate].push({
              type: 'string',
              value: stopDisplayDate,
              columnCode: FieldKeys.stopDate,
              width: 200,
            });
            break;

          case FieldKeys.sig:
            if (!internalTableCells[FieldKeys.sig]) {
              internalTableCells[FieldKeys.sig] = [];
            }
            internalTableCells[FieldKeys.sig].push({
              type: 'string',
              value: item.sig || '-',
              columnCode: FieldKeys.sig,
              width: 200,
            });
            break;

          case FieldKeys.note:
            if (!internalTableCells[FieldKeys.note]) {
              internalTableCells[FieldKeys.note] = [];
            }
            internalTableCells[FieldKeys.note].push({
              type: 'string',
              value: item.note || '-',
              columnCode: FieldKeys.note,
              width: 200,
            });
            break;
        }
      });
    });
    let isDataAvailable = false;
    tableColumns.forEach((column) => {
      if (column.code !== 'contact') {
        if (internalTableCells[column.code]) {
          isDataAvailable = true;
          tableCells.push({
            type: 'array',
            value: internalTableCells[column.code],
            width: column.code === FieldKeys.name ? 300 : 200,
          });
        } else {
          tableCells.push({
            type: 'array',
            value: [{
              type: 'string',
              value: '-',
              columnCode: column.code,
              width: column.code === FieldKeys.name ? 300 : 200,
            }],
            width: column.code === FieldKeys.name ? 300 : 200,
          });
        }
      }
    })

    if (isDataAvailable) {
      totalAnswered += 1;
      tableRows.push({
        contactId: item.contactId,
        cells: tableCells,
      });
    }
  });
  return { rows: maxRow ? tableRows.slice(0, maxRow) : tableRows, columns: tableColumns, totalAnswered, totalResponses };
};

const getTableDataForAllergies = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  maxRow?: number,
): { rows: ITableRow[]; columns: ITableColumn[]; totalResponses: number; totalAnswered: number; } => {
  const selectedValues = component.selectedValues || [];
  const tableRows: ITableRow[] = [];
  const tableColumns: ITableColumn[] = [
    {
      value: 'Responder',
      code: 'contact',
      type: 'header',
      width: 300

    },
    {
      value: 'Name',
      code: FieldKeys.name,
      type: 'header',
      width: 300
    },
  ];
  const capabilityData = getEHRResourceAbilities(
    FHIR_RESOURCE.ALLERGY,
    capabilities
  );
  const onsetDateFormat =
    capabilityData?.keyAllowedOperations?.onsetDateTime?.format;
  const isOnlyYearFormat = onsetDateFormat === 'YYYY';
  if (canShowField(FieldKeys.onSetDateTime, capabilityData)) {
    const onsetLabel = isOnlyYearFormat ? 'Since Year' : 'Since Date';
    tableColumns.push({
      value: onsetLabel,
      code: FieldKeys.onSetDateTime,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.clinicalStatus, capabilityData)) {
    tableColumns.push({
      value: 'Status',
      code: FieldKeys.clinicalStatus,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.type, capabilityData)) {
    tableColumns.push({
      value: 'Type',
      code: FieldKeys.type,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.criticality, capabilityData)) {
    tableColumns.push({
      value: 'Criticality',
      code: FieldKeys.reaction,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.reaction, capabilityData)) {
    tableColumns.push({
      value: 'Reactions',
      code: FieldKeys.reaction,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.note, capabilityData)) {
    tableColumns.push({
      value: 'Note',
      code: FieldKeys.note,
      type: 'header',
      width: 200
    });
  }

  const totalResponses = selectedValues.length;
  let totalAnswered = 0;
  selectedValues.forEach((item, index) => {
    const response: IAllergyComponentValue =
      typeof item.selectedValue === 'string'
        ? JSON.parse(item.selectedValue)
        : item.selectedValue || {};

    const tableCells: ITableCell[] = [];
    tableCells.push({
      value: item.contactId,
      type: 'contact',
      width: 300,
    });
    const internalTableCells: {[index: string]: ITableCell[]} = {};
    (response.allergies || []).forEach((item) => {
      tableColumns.forEach((column) => {
        switch (column.code) {
          case FieldKeys.name:
            if (!internalTableCells[FieldKeys.name]) {
              internalTableCells[FieldKeys.name] = [];
            }
            internalTableCells[FieldKeys.name].push({
              type: 'string',
              value: item.name || '-',
              columnCode: FieldKeys.name,
              width: 300,
            });
            break;

          case FieldKeys.onSetDateTime:
            let displayDate = item.onSetDateTime || '-';
            if (item.onSetDateTime) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.onSetDateTime,
                onsetDateFormat || ''
              );
              displayDate = isOnlyYearFormat ? item.onSetDateTime : date.format(DISPLAY_DATE_FORMAT);
            }
            if (!internalTableCells[FieldKeys.onSetDateTime]) {
              internalTableCells[FieldKeys.onSetDateTime] = [];
            }
            internalTableCells[FieldKeys.onSetDateTime].push({
              type: 'string',
              value: displayDate,
              columnCode: FieldKeys.onSetDateTime,
              width: 200,
            });
            break;

          case FieldKeys.clinicalStatus:
            const statusList =
              capabilityData?.keyAllowedOperations?.clinicalStatus
                ?.possibleValues || [];
            const statusValue = statusList.find((status: any) => {
              return status.code === item.clinicalStatus?.code;
            })?.display;
            if (!internalTableCells[FieldKeys.clinicalStatus]) {
              internalTableCells[FieldKeys.clinicalStatus] = [];
            }
            internalTableCells[FieldKeys.clinicalStatus].push({
              type: 'string',
              value: statusValue || '-',
              columnCode: FieldKeys.clinicalStatus,
              width: 200,
            });
            break;

          case FieldKeys.type:
            const typeList =
              capabilityData?.keyAllowedOperations?.type?.possibleValues || [];
            const typeValue = typeList.find((status: any) => {
              return status.code === item.type;
            })?.display;
            if (!internalTableCells[FieldKeys.type]) {
              internalTableCells[FieldKeys.type] = [];
            }
            internalTableCells[FieldKeys.type].push({
              type: 'string',
              value: typeValue || '-',
              columnCode: FieldKeys.type,
              width: 200,
            });
            break;

          case FieldKeys.criticality:
            const criticalityList =
              capabilityData?.keyAllowedOperations?.criticality?.possibleValues || [];
            const criticalityValue = criticalityList.find((status: any) => {
              return status.code === item.type;
            })?.display;
            if (!internalTableCells[FieldKeys.criticality]) {
              internalTableCells[FieldKeys.criticality] = [];
            }
            internalTableCells[FieldKeys.criticality].push({
              type: 'string',
              value: criticalityValue || '-',
              columnCode: FieldKeys.criticality,
              width: 200,
            });
            break;

          case FieldKeys.reaction:
            const severityList =
              capabilityData?.keyAllowedOperations?.criticality?.possibleValues || [];
            const reactionList: string[] = item.reactions?.map((reactionData) => {
              let display = '';
              const reactionName = reactionData.manifestation?.display || reactionData.manifestation?.code;
              if (reactionName) {
                display = reactionName;
              }
              if (reactionData.severity) {
                const severityValue = severityList.find((status: any) => {
                  return status.code === reactionData.severity;
                })?.display;
                if (severityValue) {
                  display += ` (${severityValue})`
                }
              }
              return display;
            }) || [];

            if (!internalTableCells[FieldKeys.reaction]) {
              internalTableCells[FieldKeys.reaction] = [];
            }
            internalTableCells[FieldKeys.reaction].push({
              type: reactionList.length > 1 ? 'points' : 'string',
              value: reactionList.length > 1 ? reactionList : (reactionList.length === 1 ? reactionList[0] : '-'),
              columnCode: FieldKeys.reaction,
              width: 200,
            });
            break;

          case FieldKeys.note:
            if (!internalTableCells[FieldKeys.note]) {
              internalTableCells[FieldKeys.note] = [];
            }
            internalTableCells[FieldKeys.note].push({
              type: 'string',
              value: item.note || '-',
              columnCode: FieldKeys.note,
              width: 200,
            });
            break;
        }
      });
    });
    let isDataAvailable = false;
    tableColumns.forEach((column) => {
      if (column.code !== 'contact') {
        if (internalTableCells[column.code]) {
          isDataAvailable = true;
          tableCells.push({
            type: 'array',
            value: internalTableCells[column.code],
            width: column.code === FieldKeys.name ? 300 : 200,
          });
        } else {
          tableCells.push({
            type: 'array',
            value: [{
              type: 'string',
              value: '-',
              columnCode: column.code,
              width: column.code === FieldKeys.name ? 300 : 200,
            }],
            width: column.code === FieldKeys.name ? 300 : 200,
          });
        }
      }
    })

    if (isDataAvailable) {
      totalAnswered += 1;
      tableRows.push({
        contactId: item.contactId,
        cells: tableCells,
      });
    }
  });
  return { rows: maxRow ? tableRows.slice(0, maxRow) : tableRows, columns: tableColumns, totalAnswered, totalResponses };
};

const getTableDataForProblems = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  maxRow?: number,
): { rows: ITableRow[]; columns: ITableColumn[]; totalResponses: number; totalAnswered: number; } => {
  const selectedValues = component.selectedValues || [];
  const tableRows: ITableRow[] = [];
  const tableColumns: ITableColumn[] = [
    {
      value: 'Responder',
      code: 'contact',
      type: 'header',
      width: 300

    },
    {
      value: 'Name',
      code: FieldKeys.name,
      type: 'header',
      width: 300
    },
  ];
  const capabilityData = getEHRResourceAbilities(
    FHIR_RESOURCE.CONDITION,
    capabilities
  );
  const onsetDateFormat =
    capabilityData?.keyAllowedOperations?.onsetDateTime?.format;
  const isOnlyYearFormat = onsetDateFormat === 'YYYY';
  if (canShowField(FieldKeys.onSetDateTime, capabilityData)) {
    const onsetLabel = isOnlyYearFormat ? 'Since Year' : 'Since Date';
    tableColumns.push({
      value: onsetLabel,
      code: FieldKeys.onSetDateTime,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.clinicalStatus, capabilityData)) {
    tableColumns.push({
      value: 'Status',
      code: FieldKeys.clinicalStatus,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.type, capabilityData)) {
    tableColumns.push({
      value: 'Type',
      code: FieldKeys.type,
      type: 'header',
      width: 200
    });
  }
  if (canShowField(FieldKeys.note, capabilityData)) {
    tableColumns.push({
      value: 'Note',
      code: FieldKeys.note,
      type: 'header',
      width: 200
    });
  }

  const totalResponses = selectedValues.length;
  let totalAnswered = 0;
  selectedValues.forEach((item, index) => {
    const response: IConditionComponentValue =
      typeof item.selectedValue === 'string'
        ? JSON.parse(item.selectedValue)
        : item.selectedValue || {};

    const tableCells: ITableCell[] = [];
    tableCells.push({
      value: item.contactId,
      type: 'contact',
      width: 300,
    });
    const internalTableCells: {[index: string]: ITableCell[]} = {};
    (response.conditions || []).forEach((item) => {
      tableColumns.forEach((column) => {
        switch (column.code) {
          case FieldKeys.name:
            if (!internalTableCells[FieldKeys.name]) {
              internalTableCells[FieldKeys.name] = [];
            }
            internalTableCells[FieldKeys.name].push({
              type: 'string',
              value: item.name || '-',
              columnCode: FieldKeys.name,
              width: 300,
            });
            break;

          case FieldKeys.onSetDateTime:
            let displayDate = item.onSetDateTime || '-';
            if (item.onSetDateTime) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.onSetDateTime,
                onsetDateFormat || ''
              );
              displayDate = isOnlyYearFormat ? item.onSetDateTime : date.format(DISPLAY_DATE_FORMAT);
            }
            if (!internalTableCells[FieldKeys.onSetDateTime]) {
              internalTableCells[FieldKeys.onSetDateTime] = [];
            }
            internalTableCells[FieldKeys.onSetDateTime].push({
              type: 'string',
              value: displayDate,
              columnCode: FieldKeys.onSetDateTime,
              width: 200,
            });
            break;

          case FieldKeys.clinicalStatus:
            const statusList =
              capabilityData?.keyAllowedOperations?.clinicalStatus
                ?.possibleValues || [];
            const statusValue = statusList.find((status: any) => {
              return status.code === item.clinicalStatus?.code;
            })?.display;
            if (!internalTableCells[FieldKeys.clinicalStatus]) {
              internalTableCells[FieldKeys.clinicalStatus] = [];
            }
            internalTableCells[FieldKeys.clinicalStatus].push({
              type: 'string',
              value: statusValue || '-',
              columnCode: FieldKeys.clinicalStatus,
              width: 200,
            });
            break;

          case FieldKeys.type:
            const typeList =
              capabilityData?.keyAllowedOperations?.type?.possibleValues || [];
            const typeValue = typeList.find((status: any) => {
              return status.code === item.conditionType?.code;
            })?.display;
            if (!internalTableCells[FieldKeys.type]) {
              internalTableCells[FieldKeys.type] = [];
            }
            internalTableCells[FieldKeys.type].push({
              type: 'string',
              value: typeValue || '-',
              columnCode: FieldKeys.type,
              width: 200,
            });
            break;

          case FieldKeys.note:
            if (!internalTableCells[FieldKeys.note]) {
              internalTableCells[FieldKeys.note] = [];
            }
            internalTableCells[FieldKeys.note].push({
              type: 'string',
              value: item.note || '-',
              columnCode: FieldKeys.note,
              width: 200,
            });
            break;
        }
      });
    });
    let isDataAvailable = false;
    tableColumns.forEach((column) => {
      if (column.code !== 'contact') {
        if (internalTableCells[column.code]) {
          isDataAvailable = true;
          tableCells.push({
            type: 'array',
            value: internalTableCells[column.code],
            width: column.code === FieldKeys.name ? 300 : 200,
          });
        } else {
          tableCells.push({
            type: 'array',
            value: [{
              type: 'string',
              value: '-',
              columnCode: column.code,
              width: column.code === FieldKeys.name ? 300 : 200,
            }],
            width: column.code === FieldKeys.name ? 300 : 200,
          });
        }
      }
    })

    if (isDataAvailable) {
      totalAnswered += 1;
      tableRows.push({
        contactId: item.contactId,
        cells: tableCells,
      });
    }
  });
  return { rows: maxRow ? tableRows.slice(0, maxRow) : tableRows, columns: tableColumns, totalAnswered, totalResponses };
};

const getTableDataForVitals = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  maxRow?: number,
  componentDetails?: any,
): { rows: ITableRow[]; columns: ITableColumn[]; totalResponses: number; totalAnswered: number; } => {
  const selectedValues = component.selectedValues || [];
  const tableRows: ITableRow[] = [];

  const vitalCapability = getEHRResourceCapability(
    FHIR_RESOURCE.OBSERVATION,
    capabilities
  );
  const enabledVitals = getEnabledVitals(
    vitalCapability,
    componentDetails?.enabledVitals
  );
  const list = (vitalCapability?.abilities?.allowedVitalList || []).filter(item => !item.isHidden);
  const tableColumns: ITableColumn[] = enabledVitals.map((vitalCode) => {
    return {
      value: list.find((item) => {
        return item.loinc === vitalCode;
      })?.foldDisplay || vitalCode,
      code: vitalCode,
      type: 'header',
      width: 200
    }
  });
  tableColumns.unshift({
    type: 'header',
    value: 'Responder',
    code: 'contact',
    width: 300
  })

  const totalResponses = selectedValues.length;
  let totalAnswered = 0;
  selectedValues.forEach((item, index) => {
    const response: IVitalsComponentValue =
      typeof item.selectedValue === 'string'
        ? JSON.parse(item.selectedValue)
        : item.selectedValue || {};
    const tableCells: ITableCell[] = [];
    let isDataAvailable = false;
    tableColumns.forEach((column) => {
      const selected = response.observations.find((observation) => {
        return observation.loinc === column.code;
      });
      const vitalConfig = list.find((item) => {
        return item.loinc === column.code;
      });
      if (selected) {
        const vitalFormattedData = getFormattedVitalValue(selected, vitalConfig, true) as { value?: string, recordedDate?: string };
        isDataAvailable = isDataAvailable || !!vitalFormattedData.value;
        tableCells.push({
          type: 'string',
          value: `${vitalFormattedData.value || '-'}`,
          columnCode: column.code,
          width: 200,
        });
      } else if (column.code === 'contact') {
        tableCells.push({
          type: 'contact',
          value: item.contactId,
          width: 300,
        });
      } else {
        tableCells.push({
          type: 'string',
          value: '-',
          columnCode: column.code,
          width: 200,
        });
      }
    });
    if (isDataAvailable) {
      totalAnswered += 1;
      tableRows.push({
        contactId: item.contactId,
        cells: tableCells,
      });
    }
  });
  return { rows: maxRow ? tableRows.slice(0, maxRow) : tableRows, columns: tableColumns, totalResponses, totalAnswered };
}

const getTableDataForPatientDemographics = (
  component: IFormReportResponse,
  capabilities: IEhrCapability[],
  genderMlovList: IMlov[],
  maxRow?: number,
): { rows: ITableRow[]; columns: ITableColumn[]; totalResponses: number; totalAnswered: number; } => {
  const selectedValues = component.selectedValues || [];
  const tableRows: ITableRow[] = [];
  const tableColumns: ITableColumn[] = [
    {
      type: 'header',
      value: 'Responder',
      code: 'contact',
      width: 300
    },
    {
      value: 'First Name',
      code: FieldKeys.firstName,
      type: 'header',
      width: 200
    },
    {
      value: 'Last Name',
      code: FieldKeys.lastName,
      type: 'header',
      width: 200
    },
    {
      value: 'Date of birth',
      code: FieldKeys.birthDate,
      type: 'header',
      width: 200
    },
  ];
  const patientCapability = getEHRResourceAbilities(
    FHIR_RESOURCE.PATIENT,
    capabilities
  );
  const canShowBirthSex = canShowField(FieldKeys.birthSex, patientCapability);
  if (canShowBirthSex) {
    tableColumns.push({
      value: getFieldLabel(FieldKeys.birthSex, patientCapability),
      code: FieldKeys.birthSex,
      type: 'header',
      width: 200
    });
  }
  const canShowGender = canShowField(FieldKeys.gender, patientCapability);
  if (canShowGender) {
    tableColumns.push({
      value: getFieldLabel(FieldKeys.gender, patientCapability),
      code: FieldKeys.gender,
      type: 'header',
      width: 200
    });
  }
  const totalResponses = selectedValues.length;
  let totalAnswered = 0;
  selectedValues.forEach((item, index) => {
    const response: IPatientDemographicsComponentValue =
      typeof item.selectedValue === 'string'
        ? JSON.parse(item.selectedValue)
        : item.selectedValue || {};
    const tableCells: ITableCell[] = [];
    let isDataAvailable = false;
    tableColumns.forEach((column) => {
      switch (column.code) {
        case 'contact':
          tableCells.push({
            type: 'contact',
            value: item.contactId || '-',
            width: 300,
          });
          break;
        case FieldKeys.firstName:
          isDataAvailable = isDataAvailable || !!response.firstName;
          tableCells.push({
            type: 'string',
            value: response.firstName || '-',
            columnCode: FieldKeys.firstName,
            width: 200,
          });
          break;
        case FieldKeys.lastName:
          isDataAvailable = isDataAvailable || !!response.lastName;
          tableCells.push({
            type: 'string',
            value: response.lastName || '-',
            columnCode: FieldKeys.lastName,
            width: 200,
          });
          break;
        case FieldKeys.birthDate:
          isDataAvailable = isDataAvailable || !!response.birthDate;
          tableCells.push({
            type: 'string',
            value: response.birthDate || '-',
            columnCode: FieldKeys.birthDate,
            width: 200,
          });
          break;
        case FieldKeys.birthSex:
          isDataAvailable = isDataAvailable || !!response.birthSex;
          tableCells.push({
            type: 'string',
            value:
              patientCapability?.keyAllowedOperations?.birthSex?.possibleValues?.find(
                (value) => value.code === response.birthSex
              )?.display ||
              response.birthSex ||
              '-',
            columnCode: FieldKeys.birthSex,
            width: 200,
          });
          break;
        case FieldKeys.gender:
          isDataAvailable = isDataAvailable || !!response.genderCode;
          tableCells.push({
            type: 'string',
            value: genderMlovList.find(item => item.code === response.genderCode)?.value || response.genderCode || '-',
            columnCode: FieldKeys.gender,
            width: 200,
          });
          break;
      }
    });
    if (isDataAvailable) {
      totalAnswered += 1;
      tableRows.push({
        contactId: item.contactId,
        cells: tableCells,
      })
    }
  });
  return { rows: maxRow ? tableRows.slice(0, maxRow) : tableRows, columns: tableColumns, totalAnswered, totalResponses };
};

const canShowField = (field: string, capabilities?: IAbility, component?: any) => {
  if (component?.enabledFields) {
    return component?.enabledFields[field];
  }
  const keyAllowedOperations = capabilities?.keyAllowedOperations;
  return keyAllowedOperations?.[field] && !keyAllowedOperations?.[field]?.isHidden;
};

const getFieldLabel = (field: string, capabilities?: IAbility): string => {
  const keyAllowedOperations = capabilities?.keyAllowedOperations;
  switch (field) {
    case FieldKeys.birthSex: return keyAllowedOperations?.birthSex?.label || EHR_CAPABILITY_CONST.DEFAULT_BIRTH_SEX_LABEL;
    case FieldKeys.gender: return keyAllowedOperations?.gender?.label || EHR_CAPABILITY_CONST.DEFAULT_GENDER_IDENTITY_LABEL;
  }
  return '';
};

const getQuestionListFromQuestionnaire = (
  questionnaire?: Questionnaire
): Question[] => {
  return (questionnaire?.item || []).map((question) => {
    return {
      id: question.linkId || '',
      text: question.text || '',
      repeats: question.repeats || false,
      type: question.type || '',
      answerOptions: (question?.answerOption || []).map((answer) => {
        return {
          display: answer.valueCoding?.display,
          code: answer.valueCoding?.code,
          system: answer.valueCoding?.system,
        };
      }) || [],
    };
  });
};

export const tableDataFormatter = (data: {
  rows: ITableRow[],
  columns: ITableColumn[]
}) => {
  if(data?.columns?.length==1){
    return ([
      [...data.columns,{
        value:'Value',
        type:'header',
        code:'-',
        width:300
      } as ITableCell],
      ...data.rows.map((item) => [...item.cells,{
        value:'-',
        type:'string',
        code:'-',
        width:300
      } as ITableCell])

    ])
  }
  return ([
    data.columns,
    ...data.rows.map((item) => item.cells)

  ])
}

