import { useLazyQuery } from '@apollo/client';
import { Drawer, Progress } from 'antd';
import {
  FlatList,
  HStack,
  Image,
  Skeleton,
  Text,
  useToast,
  View,
  VStack
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { Dimensions, Pressable } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { useNavigate } from 'react-router';
import { GROUP_TYPES } from '../../../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES
} from '../../../../constants/ActionConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { ILoginUserData } from '../../../../Interfaces';
import { UserQueries } from '../../../../services';
import { GET_FORM_CATEGORIES } from '../../../../services/Forms/FormsQueries';
import { GetUsersTaskAccess } from '../../../../services/TaskPool/TaskPoolQueries';
import { Colors } from '../../../../styles/Colors';
import {
  getAccountUUID,
  getUserActionObjectFromActionCode,
  getUserFullName,
  getUserUUID
} from '../../../../utils/commonUtils';
import { getContactTypeId, getMlovId } from '../../../../utils/mlovUtils';
import PurchaseNumberView from '../../../RightSideContainer/CloudTelephony/PurchaseNumberView/PurchaseNumberView';
import { AddOrUpdateLead } from '../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import { InviteUserDrawer } from '../../../RightSideContainer/Contacts/TeamMembers/InviteUserDrawer';
import { EmployerCreate } from '../../../RightSideContainer/EmployerManagement/EmployerCreate';
import AddForm from '../../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddForm';
import ElationConfig from '../../../RightSideContainer/Integration/ElationConfig/ElationConfig';
import { HintConfig } from '../../../RightSideContainer/Integration/HintConfig';
import { HintViewCodes } from '../../../RightSideContainer/Integration/HintConfig/HintConfigConsts';
import { IPracticeDetail } from '../../../RightSideContainer/Integration/HintConfig/interfaces';
import SpruceConfig from '../../../RightSideContainer/Integration/SpruceConfig/SpruceConfig';
import {
  aggregateUserAction,
  aggregateUserActionMaster
} from '../../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { ExecuteWorkflow } from '../../../RightSideContainer/Workflow/Workflow/ExecuteWorkflow';
import AddOrUpdateTask from '../../AddTask/AddOrUpdateTask';
import AppointmentBooking from '../../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBooking';
import { ParticipantType } from '../../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import CreateChannelView from '../../CreateChannelView/CreateChannelView';
import EmailDrawerCommon from '../../EmailPopupView/EmailDrawerCommon';
import { ModalActionTitle } from '../../ModalActionTitle/ModalActionTitle';
import { UsageLevelState } from '../../UsageLevel/interfaces';
import { USER_ACTION_CODES } from '../../UsageLevel/UsageLevelConsts';
import UsageLevelListItem from '../../UsageLevel/UsageLevelListItem';
import { UsageLevelController } from '../../UsageLevel/UsageLevelUtils';
import { AppQRCodeDrawer } from '../AppQRCodeDrawer';
import { styles } from './BusinessGoalsStyles';
import { UserActionLevels } from './Const';
import { BusinessGoalsTabsProps, UserActionMaster } from './Interfaces';

const BusinessGoalsTab = (props: BusinessGoalsTabsProps) => {
  const {
    title,
    level,
    description,
    list,
    isLoading,
    isCollapse,
    setCollapse,
    afterCollapseText,
  } = props;
  const { height } = Dimensions.get('window');
  const userId = getUserUUID();

  const [state, setState] = useState<UsageLevelState>({
    list: [] as UserActionMaster[],
    loading: false,
    actionViewCode: '',
    personType: '',
    personTypeUuid: '',
    userList: [],
    userTaskDisplayCategory: [],
    userPoolSelect: [],
    userListForNumbers: [],
    categoryList: [],
  });

  const navigate = useNavigate();
  const mlovData = useContext(CommonDataContext);
  const userData = mlovData.userData || ({} as ILoginUserData);
  const prospectTypeUuid = getContactTypeId('LEAD');
  const patientTypeUuid = getContactTypeId('CUSTOMER');
  const accessUserTaskPoolTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool'
  );
  const currentUserName = getUserFullName();
  const currentUserUUID = getUserUUID();
  const accountUuid = getAccountUUID();
  const [completedState, setCompletedState] = useState({
    completedCount: 0,
    percentage: 0,
  });
  const [hintConfigState, setHintConfigState] = useState({
    viewCode: HintViewCodes.ACCESS_FORM,
    practiceData: {} as IPracticeDetail,
    loading: true,
  });
  const [getUsers] = useLazyQuery(UserQueries.GET_USERS_FOR_CALENDAR, {
    variables: {
      accountId: accountUuid,
    },
  });
  const [getUsersTaskDisplayCategory] = useLazyQuery(
    UserQueries.GET_USER_TASK_DISPLAY_CATEGORY,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
      variables: {
        userId: currentUserUUID,
      },
    }
  );
  const [getUsersIvr] = useLazyQuery(UserQueries.Get_Users_IVR, {
    fetchPolicy: 'no-cache',
  });

  const [getAllTaskAccessUserTaskPools] = useLazyQuery(GetUsersTaskAccess, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const [getFormCategories] = useLazyQuery(GET_FORM_CATEGORIES, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const toast = useToast();

  const userActionObject = getUserActionObjectFromActionCode(
    USER_ACTION_CODES.CHECK_SCREENING_GROUPS
  );
  const checkScreeningGroupLevel = userActionObject.level || '';

  const {
    openPatientAddView,
    openScheduleView,
    openInviteUserView,
    openAddTaskView,
    openChatWithTeammateView,
    openSendFormToMemberView,
    openConfigurePhoneNumberView,
    openAddFormView,
    openExecuteExistingWorkflowView,
    openCreateEmployerView,
    openConnectToHintView,
    openConnectToSpruceView,
    openConnectToElationView,
    openPatientAppQRCodeView,
    openProviderAppQRCodeView,
    onMount,
    onItemClickedActionPerformed,
    onFormComplete,
    closeModals,
    getUsersListIVR,
    getFormCategoryList,
  } = new UsageLevelController({
    state,
    prospectTypeUuid,
    patientTypeUuid,
    accessUserTaskPoolTypeId,
    toast,
    accountUuid,
    userUuid: currentUserUUID,
    checkScreeningGroupLevel,
    getUsers,
    getUsersTaskDisplayCategory,
    getAllTaskAccessUserTaskPools,
    getUsersIvr,
    getFormCategories,
    setState,
    navigate,
  });

  useEffect(() => {
    onMount();
    getFormCategoryList();
    getPercentageCount();
    getUsersListIVR();
  }, []);

  const getPercentageCount = () => {
    try {
      Promise.all([
        aggregateUserAction({ level, userId }),
        aggregateUserActionMaster({ level }),
      ]).then((responses) => {
        const userActionAgg =
          responses[0]?.aggregateUserAction?.aggregate?.count || 0;
        const levelActionAgg =
          responses[1]?.aggregateUserActionMaster?.aggregate?.count || 0;
        const percent = (userActionAgg / levelActionAgg) * 100;
        setCompletedState((prev) => {
          return {
            ...prev,
            completedCount: userActionAgg,
            percentage: percent,
          };
        });
      });
    } catch (error) {
      setCompletedState((prev) => {
        return {
          ...prev,
          completedCount: 0,
          percentage: 0,
        };
      });
    }
  };

  const checkAccountLevelAction = (item: any) => {
    const check =
      item.actionCode == USER_ACTION_CODES.HINT ||
      item.actionCode == USER_ACTION_CODES.ELATION ||
      item.actionCode == USER_ACTION_CODES.SPRUCE;
    return check;
  };

  return (
    <VStack
      style={[styles.container]}
      flex={1}
    >
      <VStack>
        <Pressable
          onPress={() => {
            setCollapse(!isCollapse);
          }}
        >
          <VStack >
            <HStack style={styles.levelContainer} justifyContent={'center'} alignItems={'center'}>
              <View flex={1} height={50} style={styles.titleContainer} justifyContent={'center'} alignItems={'center'}>
                <Text paddingLeft={18} style={styles.titleCardText}>{title}</Text>
              </View>
              <View
                justifyContent={'center'}
                alignItems={'center'}
                alignSelf={'center'}
                style={{
                  flex: 0.2
                }}
              >
                {level === UserActionLevels.beginners ? (
                  // <BeginnersIcon></BeginnersIcon>
                  <Image
                    borderWidth={0}
                    resizeMode="contain"
                    size={'24px'}
                    source={require('../../../../assets/images/PNGImages/TabsPngIcons/setupMobility.png')}
                    alt="image"
                  ></Image>
                ) : level == UserActionLevels.intermediate ? (
                  <Image
                    borderWidth={0}
                    resizeMode="contain"
                    size={'24px'}
                    source={require('../../../../assets/images/PNGImages/TabsPngIcons/setupCommunication.png')}
                    alt="image"
                  ></Image>
                ) : level == UserActionLevels.patientEngagement ? (
                  <Image
                    borderWidth={0}
                    resizeMode="contain"
                    size={'24px'}
                    source={require('../../../../assets/images/PNGImages/TabsPngIcons/setupTelephony.png')}
                    alt="image"
                  ></Image>
                ) : level == UserActionLevels.advanced ? (
                  <Image
                    borderWidth={0}
                    resizeMode="contain"
                    size={'24px'}
                    source={require('../../../../assets/images/PNGImages/TabsPngIcons/setupAutomation.png')}
                    alt="image"
                  ></Image>
                ) : (
                  <Image
                    borderWidth={0}
                    resizeMode="contain"
                    size={'24px'}
                    source={require('../../../../assets/images/PNGImages/TabsPngIcons/setupIntegration.png')}
                    alt="image"
                  ></Image>
                )}
              </View>
              <HStack justifyContent={'center'} alignItems={'center'}>
                <HStack paddingRight={18}><Feather

                  name={isCollapse ? 'chevron-down' : 'chevron-up'}
                  style={[
                    {
                      height: 4,
                      width: 8,
                      marginLeft: 2,
                      marginRight: 2,

                      color: Colors.Custom.Gray500,
                    },
                  ]}
                />
                </HStack>
              </HStack>
            </HStack>
            <VStack paddingX={18} paddingY={2} justifyContent={'center'}>
              <Text
                noOfLines={2}
                style={[styles.descriptionText, { color: '#825AC7' }]}
              >
                {afterCollapseText}
              </Text>
            </VStack>

            {isCollapse && (<VStack paddingX={18} paddingY={'5px'}>
                <Progress
                  percent={completedState.percentage || 0}
                  trailColor={'#F2F4F7'}
                  strokeColor={'#12B76A'}
                  showInfo={false}
                  style={{ alignSelf: 'center', justifySelf: 'center' }}
                />
            </VStack>)}

          </VStack>
        </Pressable>
      </VStack>
      {!isCollapse && (
        <VStack>
          <Pressable
            onPress={() => {
              setCollapse(!isCollapse);
            }}
          >
            <View
              style={{
                borderBottomColor: '#D0D0D0',
                borderBottomWidth: 1,
              }}
            >
            </View>
          </Pressable>
          <View>
            {isLoading ? (
              <View style={{ padding: 12 }}>
                <Skeleton.Text lines={5} />
              </View>
            ) : (
              <VStack style={styles.listContainer} flex={1}>
                <FlatList
                  contentContainerStyle={{ flexGrow: 1 }}
                  data={list}
                  keyExtractor={(item: any) => {
                    return '_' + item.id;
                  }}
                  renderItem={({ item, index }) => {
                    const currentIndex= index;
                    const isAccountLevelActionItem =
                      checkAccountLevelAction(item);
                    return (
                      <UsageLevelListItem
                        isAccountLevelAction={isAccountLevelActionItem}
                        isShowBottomLine={ !(list.length == 4 && currentIndex == 3)}
                        key={index}
                        serialNumber={index + 1}
                        actionItem={item}
                        onActionPerformed={(actionCode: any) => {
                          onItemClickedActionPerformed(actionCode);
                        }}
                      />
                    );
                  }}
                />
              </VStack>
            )}
          </View>
        </VStack>
      )}

      {openPatientAddView && (
        <AddOrUpdateLead
          singleLeadData={undefined}
          onFormCompleteAction={(actionCode: string) => {
            closeModals();
            onMount();
          }}
          personTypeUuid={state.personTypeUuid}
          personType={state.personType}
        />
      )}

      {openScheduleView && (
        <AppointmentBooking
          isVisible={true}
          onComplete={() => {
            closeModals();
            onMount();
          }}
          onCancel={() => {
            closeModals();
          }}
        />
      )}

      {openInviteUserView && (
        <InviteUserDrawer
          isOpen={openInviteUserView}
          onClose={() => {
            onMount();
            closeModals();
          }}
        />
      )}

      {openAddTaskView && (
        <AddOrUpdateTask
          isVisible={true}
          onComplete={(data) => {
            onMount();
          }}
          onCancel={() => {
            closeModals();
          }}
          assignee={{
            value: currentUserUUID,
            label: currentUserName,
            key: currentUserUUID,
            type: ParticipantType.staff,
            details: userData,
          }}
        />
      )}

      {openChatWithTeammateView && (
        <CreateChannelView
          selectedTabCode={GROUP_TYPES.INTERNAL}
          isDrawerVisible={openChatWithTeammateView}
          onCreateChannelActionPerformed={(actionCode) => {
            if (actionCode === CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
              closeModals();
            } else {
              onMount();
            }
          }}
        />
      )}

      {openSendFormToMemberView && (
        <EmailDrawerCommon
          onMailCompleteAction={() => {
            closeModals();
            onMount();
          }}
        />
      )}

      {openConfigurePhoneNumberView && (
        <PurchaseNumberView
          onFormCompleteAction={(actionCode: string, data: any) => {
            onMount();
            closeModals();
          }}
          userList={state.userListForNumbers}
        />
      )}

      {openAddFormView && (
        <AddForm
          categoryList={state.categoryList}
          isAddExisting={false}
          isVisible={true}
          onCancel={() => {
            closeModals();
          }}
          onComplete={(formDetail) => {
            onFormComplete({ ...formDetail });
          }}
        />
      )}

      {openExecuteExistingWorkflowView && (
        <ExecuteWorkflow
          isOpen
          onCompleteAction={(actionCode) => {
            if (actionCode == COMMON_ACTION_CODES.CANCEL) {
              closeModals();
            } else {
              closeModals();
              onMount();
            }
          }}
          showWorkflowList
        />
      )}

      {openCreateEmployerView && (
        <Drawer
          className="headerBorder"
          width={'40vw'}
          visible={openCreateEmployerView}
          closeIcon={null}
          closable
          maskClosable={true}
          onClose={() => {
            closeModals();
          }}
        >
          <View>
            <EmployerCreate
              isModalOpen={openCreateEmployerView}
              initData={null}
              onModalClose={(shouldRefetch?: boolean) => {
                if (shouldRefetch) {
                  onMount();
                }
                closeModals();
              }}
              title="quickCreate"
            />
          </View>
        </Drawer>
      )}

      {openConnectToHintView && (
        <Drawer
          className="headerBorder"
          width={'50%'}
          visible={openConnectToHintView}
          closeIcon={null}
          closable
          maskClosable={true}
          onClose={() => {
            closeModals();
          }}
          title={
            <>
              <ModalActionTitle
                title="Practice Details"
                titleColor={''}
                titleSize={24}
              />
            </>
          }
        >
          <View>
            <HintConfig />
          </View>
        </Drawer>
      )}

      {openConnectToElationView && (
        <Drawer
          className="headerBorder"
          width={'50%'}
          visible={openConnectToElationView}
          closeIcon={null}
          closable
          maskClosable={true}
          onClose={() => {
            closeModals();
          }}
          title={
            <>
              <ModalActionTitle
                title="Elation"
                titleColor={''}
                titleSize={24}
              />
            </>
          }
        >
          <View>
            <ElationConfig />
          </View>
        </Drawer>
      )}

      {openConnectToSpruceView && (
        <Drawer
          className="headerBorder"
          width={'50%'}
          visible={openConnectToSpruceView}
          closeIcon={null}
          closable
          maskClosable={true}
          onClose={() => {
            closeModals();
          }}
          title={
            <>
              <ModalActionTitle title="Spruce" titleColor={''} titleSize={24} />
            </>
          }
        >
          <View>
            <SpruceConfig></SpruceConfig>
          </View>
        </Drawer>
      )}

      {(openPatientAppQRCodeView || openProviderAppQRCodeView) && (
        <AppQRCodeDrawer
          isMemberApp={openPatientAppQRCodeView}
          isProviderApp={openProviderAppQRCodeView}
          isOpen={openPatientAppQRCodeView || openProviderAppQRCodeView}
          onCompleteAction={() => {
            closeModals();
          }}
        />
      )}
    </VStack>
  );
};

export default BusinessGoalsTab;
