import {useLazyQuery} from '@apollo/client';
import {
  Box,
  HStack,
  Pressable,
  Skeleton,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {BUTTON_TYPE} from '../../../../constants';
import {evnName} from '../../../../constants/BaseUrlConst';
import {AccountApiCred} from '../../../../services';
import {Colors} from '../../../../styles';
import {commonBodyContentContainer} from '../../../../styles/CommonBodyContentStyle';
import {
  getAccountUUID,
  getCurrentSubdomainWeb,
} from '../../../../utils/commonUtils';
import CustomTabsView from '../../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../../common/CustomTabsView/interfaces';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES,
} from '../../../common/TableTopBar';
import PaymentSetup from '../ProductsAndServices/PaymentSetup/PaymentSetup';
import {styles} from '../ProductsAndServices/ProductsAndServicesStyles';
import './Membership.css';
import MembershipOrders from './MembershipOrders/MembershipOrders';
import BillingMembershipTable from './MembershipProducts/BillingMembershipTable';
import MembershipTable from './MembershipProducts/MembershipTable';
import {
  getMembershipOrderTopbar,
  getMembershipProductListTopBarButtons,
} from './OrdersTableTopbar';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
const MembershipAndSubscriptions = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const accountUuid = getAccountUUID();
  const paymentSetupFormToast = useToast();
  const [GetApiCredentials] = useLazyQuery(AccountApiCred.GetApiCredentials, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });

  const isFoldAccount = () => {
    const subdomain = getCurrentSubdomainWeb();
    if (subdomain === `fold.${evnName()}foldhealth.io`) {
      return true;
    }
    return false;
  };

  const [searchStringText, setSearchStringText] = useState('');
  const [productTableMainViewState, setProductTableMainViewState] = useState({
    productData: {} as any,
    isRowSelected: false,
    loading: false,
    addEditPayment: false,
    isLoading: false,
    stripeId: '',
    isOneTime: false,
  });
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const membershipTabView̉: ITabsList[] = [
    {
      key: 'orders',
      title: 'Orders',
      path: 'orders',
    },
    {
      key: 'products',
      title: 'Membership',
      path: 'products',
    },
    {
      key: 'paymentSetup',
      title: 'Payment Setup',
      path: 'paymentSetup',
    },
  ];
  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab);
    setSearchStringText('');
  };
  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        handleSearchChange(actionData);
        break;
    }
  };
  const handleSearchChange = (actionData: any) => {
    setSearchStringText(actionData.searchString);
  };
  const onProductTableRowClick = (productData: any) => {
    setProductTableMainViewState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
  };

  const showToastMessage = (msg: string, isError: boolean, isI18N = true) => {
    showToast(paymentSetupFormToast, isI18N ? intl.formatMessage({id: msg}) : msg, isError ? ToastType.error : ToastType.success);
  };
  const getPaymentSetupData = async () => {
    const stripResponse = await GetApiCredentials({
      variables: {
        accountUuid: accountUuid,
        type: 'STRIPE',
      },
    });
    if (stripResponse?.data?.accountApiCredentials.length > 0) {
      const stripeData = stripResponse.data.accountApiCredentials[0];
      setProductTableMainViewState((prev) => {
        return {
          ...prev,
          stripeId: stripeData?.id,
        };
      });
    }
  };
  useEffect(() => {
    getPaymentSetupData();
  }, []);
  useEffect(() => {
    getPaymentSetupData();
  }, [selectedTab]);
  const orderType = [
    {name: 'Subscription', code: 'subscription'},
    {name: 'One Time', code: 'oneTime'},
  ];
  const ordertabBar: JSX.Element = (
    <>
      <TableTopBar
        alignSearchBarFlexEnd={true}
        searchText={searchStringText}
        hideSearchBar={true}
        placeHolderText={'Search'}
        isDividerNotVisible={true}
        // headerElement={getCheckBoxElement()}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
        {...getMembershipOrderTopbar()}
      />
    </>
  );
  const ProductTabBar: JSX.Element = (
    <>
      <TableTopBar
        alignSearchBarFlexEnd={true}
        searchText={searchStringText}
        isDividerNotVisible={true}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
        {...getMembershipProductListTopBarButtons(
          [
            () => {
              if (false) {
                if (productTableMainViewState.stripeId) {
                  navigate(`/admin/membership/products/${'-1'}`, {
                    state: {isOneTime: productTableMainViewState?.isOneTime},
                  });
                } else {
                  showToastMessage('stripIdValidationMsg', true);
                }
              }
            },
          ],
          <VStack style={{padding: 0, borderRadius: 16}}>
            {orderType.map((order: any) => {
              return (
                <Pressable
                  onPress={() => {
                    if (productTableMainViewState?.stripeId) {
                      if (order?.code === 'subscription') {
                        navigate(`/admin/membership/products/${'-1'}`, {
                          state: {
                            isOneTime: false,
                          },
                        });
                      } else {
                        navigate(`/admin/membership/products/${'-1'}`, {
                          state: {
                            isOneTime: true,
                          },
                        });
                      }
                    } else {
                      showToastMessage('stripIdValidationMsg', true);
                    }
                  }}
                >
                  <HStack p={2}>
                    <Text>{order?.name}</Text>
                  </HStack>
                </Pressable>
              );
            })}
          </VStack>
        )}
      />
    </>
  );
  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele: any = <></>;
    if (selectedTab?.key === 'orders') {
      ele = ordertabBar;
    } else if (selectedTab?.key === 'products') {
      ele = ProductTabBar;
    } else if (selectedTab?.key === 'paymentSetup') {
      ele = false;
    }
    return ele;
  };
  const {height} = Dimensions.get('window');
  return (
    <>
      <View backgroundColor={'white'} flex={1}>
        {/* <View style={styles.titleWrapper}>
          <TitleSubtitleView
            title={'Membership Management'}
            subtitleLabelId="servicesManagementSubtitle"
          />
        </View> */}
        <View style={styles.tagContainer}>
          <CustomTabsView
            contentTopMargin={0}
            showHeading
            heading="membership"
            canNavigate={true}
            basePath={`/admin/membership`}
            tabListWrapperStyle={{
              paddingHorizontal: 16,
              backgroundColor: '#fff',
            }}
            handleWithOnTab={true}
            tabsList={membershipTabView̉}
            onTabClick={(tabItem) => {
              onTabClick(tabItem);
            }}
            rightView={() => {
              return getSelectedTopBar(selectedTab);
            }}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'Membership widget',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  const origin = window.location.origin;
                  const dotIndex = origin.indexOf('.');
                  const subString = 'membership';
                  const finalString =
                    origin.slice(0, dotIndex) +
                    '.' +
                    subString +
                    origin.slice(dotIndex);
                  window.open(finalString, '_blank', 'noopener,noreferrer');
                },
              },
            ]}
            bottomOffset={150}
          />
        </View>
        <HStack
          flex={1}
          style={[
            commonBodyContentContainer.pageContentContainer,
            {
              height: height - 300,
              overflow: 'scroll',
            },
          ]}
        >
          {productTableMainViewState.isLoading ? (
            <Skeleton.Text margin={4} lines={4} />
          ) : (
            <>
              <Box style={styles.productSideBar}>
                <View
                  bgColor={'white'}
                  position={'relative'}
                >
                  {selectedTab?.key === 'orders' && <MembershipOrders />}
                  {selectedTab?.key === 'products' && !isFoldAccount() && (
                    <>
                      <MembershipTable
                        onActionPerformed={(type) => {
                          if (type === 'oneTime') {
                            setProductTableMainViewState((prev) => {
                              return {
                                ...prev,
                                isOneTime: true,
                              };
                            });
                          } else {
                            setProductTableMainViewState((prev) => {
                              return {
                                ...prev,
                                isOneTime: false,
                              };
                            });
                          }
                        }}
                        isOneTime={productTableMainViewState?.isOneTime}
                        // productTableMainViewState={productTableMainViewState}
                        searchText={searchStringText}
                      />
                    </>
                  )}

                  {selectedTab?.key === 'products' && isFoldAccount() && (
                    <>
                      <BillingMembershipTable
                        // productTableMainViewState={productTableMainViewState}
                        searchText={searchStringText}
                      />
                    </>
                  )}

                  {selectedTab?.key === 'paymentSetup' && (
                    <PaymentSetup
                      addEditPaymentEvent={
                        productTableMainViewState.addEditPayment
                      }
                    />
                  )}
                </View>
              </Box>
            </>
          )}
        </HStack>
      </View>
    </>
  );
};

export default MembershipAndSubscriptions;
