import {useLazyQuery} from '@apollo/client';
import {Skeleton, View, VStack} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';
// import {LeadFilterView} from '../../FilterView/LeadFilterView';
import {ILeadMainTableViewProps} from '../..';
import {
  MLOV_CATEGORY,
  PERSON_TYPES,
} from '../../../../../../constants';
import {
  COMMON_ACTION_CODES,
  CONTACT_ACTION_CODES,
} from '../../../../../../constants/ActionConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {
  IUserPracticeLocation,
  IUserPracticeLocationData,
} from '../../../../../../services/Location/interfaces';
import UserPracticeLocationQueries from '../../../../../../services/Location/UserPracticeLocationQueries';
import {getAccountId, isEnableCareProgram} from '../../../../../../utils/commonUtils';
import {getContactTypeId, getMlovId, getMlovIdFromCode, getMlovListByCategoryCode, getMlovListFromCategory} from '../../../../../../utils/mlovUtils';
import FilterDrawerView from '../../../../../common/FilterDrawerView/FilterDrawerView';
// import {getUserUUID} from '../../../../../../utils/commonUtils';
import { IInboxFilter } from '../../../../../common/FilterView/interfaces';
import {getContactSMSData} from '../../../../../common/SMSDrawer/SMSDrawerUtils';
import {
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES,
} from '../../../../../common/TableTopBar';
import {ITagsProps} from '../../../Tags';
import {
  getBulkActionTopBarButton,
  getLeadListTopBarButtons,
} from './Helper/TopBarButtons';
import LeadListView from './LeadListView/LeadListView';
import {styles} from './Styles';
import {MyPatientView} from '../../../../AllContactsView/MyPatientView';
import { ContactListView } from '../../../../../common/MemebersView/ContactListView';


export const LeadMainTableView = (props: ILeadMainTableViewProps) => {
  // const userUuid = getUserUUID();
  const location = useLocation();
  const navigate = useNavigate();

  const {selectedActionCode, selectedExtraData, selectedRawData, updateContactCount, tab, userPracticeLocation} = props;
  const mlovData = useContext(CommonDataContext);
  const patientTypeUuid = getContactTypeId('CUSTOMER');
  const prospectTypeUuid = getContactTypeId('LEAD');
  const accountId = getAccountId();
  const [searchString, setSearchString] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedViewCode, setSelectedViewCode] = useState('');
  const [tagItem, setTagItem] = useState([] as ITagsProps[]);
  const [filtersApplied, setFiltersApplied] = useState([]);
  const [inboxItem, setInboxItem] = useState({} as IInboxFilter);
  const [searchParams] = useSearchParams();
  const labelTypeList = mlovData.MLOV[MLOV_CATEGORY.LABEL_TYPE];
  const labelTypeId = getMlovIdFromCode(labelTypeList, 'CONTACT');
  const filters = searchParams.get('filters');
  const currentLocation = searchParams.get('location')
    ? searchParams.get('location')
    : 'All';
  const [mainTableState, setMainTableState] = useState({
    topBarTitle: '',
    contactType: '',
  });
  const initialContactType = [
    {
      id: 1,
      contactType: 'CUSTOMER',
      contactTypeUuid: patientTypeUuid,
      label: 'Patients',
    },
    {
      id: 1,
      contactType: 'LEAD',
      contactTypeUuid: prospectTypeUuid,
      label: 'Prospects',
    },
  ];
  const [leadMainTableViewState, setLeadMainTableViewState] = useState({
    isLoading: true,
    userPracticeLocation: userPracticeLocation || [],
    selectedUserPracticeLocation: [] as IUserPracticeLocation[],
    customContactTypes: [],
    showBulkActionTopBar: false,
    selectedMember: [],
    clearAllEmail: false,
    selectedTagsLabels: [],
    formData: {} as any,
    labelsTagList: [] as any,
  });

  const userSettings = mlovData.userSettings;
  const enableCareProgram = isEnableCareProgram(userSettings);

  const [getUserPracticeLocation] = useLazyQuery<IUserPracticeLocationData>(
    UserPracticeLocationQueries.GetUserPracticeLocations,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  useEffect(() => {
    try {
      if (filters && JSON.parse(filters) && JSON.parse(filters)?.rawData) {
        onActionPerformed(
          COMMON_ACTION_CODES.SAVE,
          JSON.parse(filters)?.rawData,
          JSON.parse(filters)?.extraData
        );
      }
      if (filters && JSON.parse(filters) && JSON.parse(filters)?.search) {
        setSearchString(JSON.parse(filters)?.search);
      }

      if (filters && JSON.parse(filters) && JSON.parse(filters)?.page) {
        setCurrentPage(JSON.parse(filters)?.page);
      }
    } catch (e) { }
  }, [leadMainTableViewState.userPracticeLocation]);

  const {personTypeUuid, personType} = props;

  const downloadButtonClick = () => {
    props.onActionPerformed(COMMON_ACTION_CODES.IMPORT, {});
  };
  const createLeadButtonClick = () => {
    props.onActionPerformed(COMMON_ACTION_CODES.EDIT, {});
  };

  const createCampaignButtonClick = () => {
    props.onActionPerformed(
      COMMON_ACTION_CODES.CREATE_CAMPAIGN,
      leadMainTableViewState.selectedMember
    );
  };

  const createAutomation = () => {
    props.onActionPerformed(
      COMMON_ACTION_CODES.CREATE_AUTOMATION,
      leadMainTableViewState.selectedMember
    );
  };

  const createGroup = () => {
    props.onActionPerformed(
      COMMON_ACTION_CODES.CREATE_GROUP,
      leadMainTableViewState.selectedMember
    );
  };

  const handleSearchChange = (actionData: any) => {
    setSearchString(actionData.searchString);
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        let newQuery: any = {};
        try {
          if (filters && JSON.parse(filters)) {
            newQuery = JSON.parse(filters);
          }
        } finally {
          if (actionData.searchString) {
            newQuery['search'] = actionData.searchString;
          } else if (newQuery['search']) {
            delete newQuery['search'];
          }
        }
        if (Object.keys(newQuery)?.length) {
          navigate(
            `${location.pathname}?filters=${encodeURIComponent(
              JSON.stringify(newQuery)
            )}`
          );
        } else {
          navigate(`${location.pathname}`);
        }
        handleSearchChange(actionData);
        break;
      case TABLE_TOP_BAR_ACTION_CODES.FILTER_VIEW:
        setSelectedViewCode('FILTER_VIEW');
        break;
    }
  };

  const onActionPerformed = (tabCode: any, rawData: any, extraData?: any) => {
    switch (tabCode) {
      case 'TAG':
        setTagItem(rawData);
        setLeadMainTableViewState((prev) => {
          return {
            ...prev,
            selectedTagsLabels: rawData,
          };
        });
        break;
      case 'INBOX':
        setInboxItem(rawData);
        break;
      case COMMON_ACTION_CODES.CONTACT_TYPE_SELECTED:
        props.onActionPerformed(tabCode, rawData);
        break;
      case COMMON_ACTION_CODES.ADD:
        props.onActionPerformed(tabCode, rawData);
        break;
      case CONTACT_ACTION_CODES.ON_USER_PRACTICE_LOCATION_SELECT:
        props.onActionPerformed(tabCode, rawData);
        setLeadMainTableViewState((prev) => {
          return {
            ...prev,
            selectedUserPracticeLocation: [rawData],
          };
        });
        break;
      case COMMON_ACTION_CODES.SAVE:
        let newQuery: any = {};
        try {
          if (filters && JSON.parse(filters)) {
            newQuery = JSON.parse(filters);
          }
        } finally {
          if (Object.keys(rawData)?.length && extraData?.length) {
            newQuery['rawData'] = rawData;
            newQuery['extraData'] = extraData;
          } else if (newQuery['rawData'] || newQuery['extraData']) {
            delete newQuery['rawData'];
            delete newQuery['extraData'];
          }
        }
        if (Object.keys(newQuery)?.length) {
          navigate(
            `${location.pathname}?filters=${encodeURIComponent(
              JSON.stringify(newQuery)
            )}`
          );
        } else {
          navigate(`${location.pathname}`);

        }
        props.onActionPerformed(COMMON_ACTION_CODES.SAVE, extraData);
        setFiltersApplied(extraData);
        if (rawData.userPracticeLOcation) {
          // const selectedPracticeLocation: any =
          //   leadMainTableViewState.userPracticeLocation.find((item) => {
          //     return item.uuid === rawData.userPracticeLOcation;
          //   });
          const selectedLocations =
            leadMainTableViewState.userPracticeLocation.filter((o1) =>
              rawData.userPracticeLOcation.some((o2: any) => o1?.uuid === o2)
            );

          setLeadMainTableViewState((prev) => {
            return {
              ...prev,
              selectedUserPracticeLocation: selectedLocations,
            };
          });
        } else {
          setLeadMainTableViewState((prev) => {
            return {
              ...prev,
              selectedUserPracticeLocation: [],
            };
          });
        }
        if (rawData?.contactLabel?.length) {
          const selectedLabels = rawData?.selectedLabelsData?.filter(
            (item: any) => rawData.contactLabel.includes(item.id)
          );
          setTagItem(selectedLabels);
          setLeadMainTableViewState((prev) => {
            return {
              ...prev,
              selectedTagsLabels: selectedLabels,
            };
          });
        } else {
          setTagItem([] as any);
          setLeadMainTableViewState((prev) => {
            return {
              ...prev,
              selectedTagsLabels: [],
            };
          });
        }
        if (rawData.contactTypeSelected) {
          // const selectedContactType = initialContactType.find((item) => {
          //   return item.contactTypeUuid === rawData.contactTypeSelected;
          // });
          const selectedContactType = {
            contactType: rawData.contactTypeSelected.code,
            contactTypeUuid: rawData.contactTypeSelected.id,
          }
          props.onActionPerformed('CONTACT_TYPE_SELECTED', selectedContactType);
        }
        setLeadMainTableViewState((prev) => {
          return {
            ...prev,
            formData: rawData,
          };
        });
        setSelectedViewCode('');
        setCurrentPage(1);


        break;
      case COMMON_ACTION_CODES.CANCEL:
        setSelectedViewCode('');
        break;
      case COMMON_ACTION_CODES.BULK_EMAIL:
        bulkEmailAction();
        break;
      case COMMON_ACTION_CODES.BULK_SMS:
        bulkSmsAction();
        break;
      case COMMON_ACTION_CODES.CREATE_CAMPAIGN:
        createCampaignButtonClick();
        break;
      case COMMON_ACTION_CODES.CREATE_AUTOMATION:
        createAutomation();
        break;
      case COMMON_ACTION_CODES.CREATE_GROUP:
        createGroup();
        break;
      case COMMON_ACTION_CODES.CLEAR_MAIL:
        clearAllEmail();
        break;
      case COMMON_ACTION_CODES.IMPORT:
        downloadButtonClick();
        break;
      case COMMON_ACTION_CODES.IMPORT:
        createLeadButtonClick();
        break;
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        onTableTopBarActionPerformed(tabCode, rawData);
        break;
      case TABLE_TOP_BAR_ACTION_CODES.FILTER_VIEW:
        onTableTopBarActionPerformed(tabCode, rawData);
        break;
      case COMMON_ACTION_CODES.ASSIGN_CARE_MANAGER:
        bulkAssigCareManagerAction();
        break;  
  }

  };

  const getAllUserPracticeLocation = async () => {
    let userPracticeLocations: any[] = [];
    if (props?.userPracticeLocation?.length) {
      userPracticeLocations = props?.userPracticeLocation || [];
    } else {
      const response = await getUserPracticeLocation();
      userPracticeLocations = response?.data?.accountLocations || [];
    }
    if (userPracticeLocations?.length) {
      props.onActionPerformed(
        COMMON_ACTION_CODES.ON_USER_PRACTICE_LOCATION_SELECT,
        userPracticeLocations,
      );
      const selectedUserPracticeLocation =
      (userPracticeLocations || []).find(
          (location: IUserPracticeLocation) => {
            return location.practiceLocation?.name === currentLocation;
          }
        );

      setLeadMainTableViewState((prev) => {
        return {
          ...prev,
          userPracticeLocation: userPracticeLocations || [],
          selectedUserPracticeLocation: selectedUserPracticeLocation
            ? [selectedUserPracticeLocation]
            : ([] as any),
          isLoading: false,
        };
      });
    } else {
      setLeadMainTableViewState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }
  };

  const getFilterPropsWithCode = () => {
    return {
      FILTER_BY_PRACTICE_LOCATION: {
        userPracticeLocation: leadMainTableViewState.userPracticeLocation,
        selectedUserPracticeLocation:
          leadMainTableViewState.selectedUserPracticeLocation,
      },
    };
  };
  const bulkEmailAction = () => {
    const tempMemebersArray = leadMainTableViewState.selectedMember;
    const emailDataArray: any = [];
    tempMemebersArray.forEach((member: any) => {
      emailDataArray.push(member?.nameData?.email);
    });
    props.onActionPerformed(COMMON_ACTION_CODES.MAIL, emailDataArray, leadMainTableViewState.selectedMember);
  };
  const bulkSmsAction = () => {
    const array = getContactSMSData(leadMainTableViewState.selectedMember);
    props.onActionPerformed(COMMON_ACTION_CODES.TEXT_MESSAGE, array);
  };

  const bulkAssigCareManagerAction = () => {
    props.onActionPerformed(COMMON_ACTION_CODES.ASSIGN_CARE_MANAGER, leadMainTableViewState.selectedMember);
  };


  const clearAllEmail = () => {
    setLeadMainTableViewState((prev) => {
      return {
        ...prev,
        clearAllEmail: !leadMainTableViewState.clearAllEmail,
      };
    });
  };

  const getTopBarCode = () => {
    let tableTopbarEle = <></>;
    if (leadMainTableViewState.showBulkActionTopBar) {
      tableTopbarEle = (
        <>
          <TableTopBar
            hStackMinHight={'auto'}
            searchInputStyle={{minWidth: 340}}
            onActionPerformed={(actionCode: string, actionData: any) => {
              onTableTopBarActionPerformed(actionCode, actionData);
            }}
            topContainerStyle={{borderRadius: 0}}
            hideSearchBar={false}
            {...getBulkActionTopBarButton(
              [
                bulkEmailAction,
                bulkSmsAction,
                clearAllEmail,
                createCampaignButtonClick,
                createAutomation,
                createGroup,
              ],
              onActionPerformed,
              //mainTableState.topBarTitle
              ''
            )}
          />
        </>
      );
    } else {
      tableTopbarEle = (
        <TableTopBar
          hStackMinHight={'auto'}
          searchInputStyle={{width: 340}}
          topContainerStyle={{borderRadius: 0}}
          onActionPerformed={(actionCode: string, actionData: any) => {
            onTableTopBarActionPerformed(actionCode, actionData);
          }}
          {...getLeadListTopBarButtons(
            [downloadButtonClick, createLeadButtonClick],
            onActionPerformed,
            '',
            personType || '',
            leadMainTableViewState.selectedTagsLabels,
            getFilterPropsWithCode()
          )}
          showFilterIcon={true}
          appliedFilters={filtersApplied}
        />
      );
    }
    return tableTopbarEle;
  };
  useEffect(() => {
    if (personType === PERSON_TYPES.CUSTOMER) {
      setMainTableState((prev) => {
        return {
          ...prev,
          topBarTitle: 'customers',
          contactType: personType,
        };
      });
    } else if (personType === PERSON_TYPES.LEAD) {
      setMainTableState((prev) => {
        return {
          ...prev,
          topBarTitle: 'leads',
          contactType: personType,
        };
      });
    }
  }, [personType]);


  const fetchAllData = async () => {
    await getAllUserPracticeLocation();
  };


  useEffect(() => {
    fetchAllData();
  }, []);

  // Removing this for selected members should not reset
  // useEffect(() => {
  //   clearAllEmail();
  // }, [RIGHT_SIDE_CONTAINER_CODE.EMAIL_SENT_EVENT == props.addOrUpdateEvent]);

  useEffect(() => {
    onActionPerformed(selectedActionCode || '', selectedRawData || {});
  }, [selectedActionCode, selectedExtraData, selectedRawData]);

  return (
    <>
      {/* <View
        style={{
          backgroundColor: '#fff',
          borderBottomWidth: 0,
          borderColor: Colors.Custom.Gray200,
          paddingVertical: 0,
        }}
      >
        {getTopBarCode()}
      </View> */}

      <View
        style={{
          marginBottom: 0,
        }}
      >
        <VStack>
          {(leadMainTableViewState.isLoading) && (
            <View padding={5}>
              <Skeleton.Text lines={4} />
            </View>
          )}
          {(!leadMainTableViewState?.isLoading &&  (props?.tab === 'prospect' || !enableCareProgram)) && (
            <>
            <View style={styles.leadTableViewContainer}>
              {props?.tab !== 'myPatient' ? <LeadListView
                hideConvertToPatient={props?.hideConvertToPatient}
                addOrUpdateEvent={props.addOrUpdateEvent}
                searchText={searchString}
                status={leadMainTableViewState?.formData?.status}
                loggedInStatus={leadMainTableViewState?.formData?.loggedInStatus}
                formData={leadMainTableViewState?.formData}
                tagItem={tagItem}
                currentPage={currentPage}
                onActionPerformed={(
                  actionCode: string,
                  selectedData: any
                ) => {
                  if (COMMON_ACTION_CODES.CHANGE_PAGE == actionCode) {
                    setCurrentPage(selectedData);
                    let newQuery: any = {};
                    try {
                      if (filters && JSON.parse(filters)) {
                        newQuery = JSON.parse(filters);
                      }
                    } finally {
                      if (selectedData) {
                        newQuery['page'] = selectedData;
                      } else if (newQuery['page']) {
                        delete newQuery['page'];
                      }
                    }
                    if (Object.keys(newQuery)?.length) {
                      navigate(
                        `${location.pathname}?filters=${encodeURIComponent(
                          JSON.stringify(newQuery)
                        )}`
                      );
                    } else {
                      navigate(`${location.pathname}`);
                    }
                    return;
                  }
                  if (COMMON_ACTION_CODES.ON_BULK_ACTION === actionCode) {
                    const showBulkActionTopBar =
                      selectedData.length > 0 ? true : false;
                    props.onActionPerformed(actionCode, selectedData);
                    setLeadMainTableViewState((prev) => {
                      return {
                        ...prev,
                        showBulkActionTopBar: showBulkActionTopBar,
                        selectedMember: selectedData,
                      };
                    });
                  } else {
                    props.onActionPerformed(actionCode, selectedData);
                  }
                }}
                inboxItem={inboxItem}
                personTypeUuid={personTypeUuid}
                personType={personType}
                selectedUserPracticeLocation={
                  leadMainTableViewState.selectedUserPracticeLocation
                }
                clearAllEvent={leadMainTableViewState.clearAllEmail}
                updateContactCount={updateContactCount}
                contactUpdated={props?.contactUpdated}
                tab={tab}
              /> : <MyPatientView
                addOrUpdateEvent={props.addOrUpdateEvent}
                searchText={searchString}
                status={leadMainTableViewState?.formData?.status}
                loggedInStatus={leadMainTableViewState?.formData?.loggedInStatus}
                formData={leadMainTableViewState?.formData}
                tagItem={tagItem}
                currentPage={currentPage}
                onActionPerformed={(
                  actionCode: string,
                  selectedData: any
                ) => {
                  if (COMMON_ACTION_CODES.CHANGE_PAGE == actionCode) {
                    setCurrentPage(selectedData);
                    let newQuery: any = {};
                    try {
                      if (filters && JSON.parse(filters)) {
                        newQuery = JSON.parse(filters);
                      }
                    } finally {
                      if (selectedData) {
                        newQuery['page'] = selectedData;
                      } else if (newQuery['page']) {
                        delete newQuery['page'];
                      }
                    }
                    if (Object.keys(newQuery)?.length) {
                      navigate(
                        `${location.pathname}?filters=${encodeURIComponent(
                          JSON.stringify(newQuery)
                        )}`
                      );
                    } else {
                      navigate(`${location.pathname}`);
                    }
                    return;
                  }
                  if (COMMON_ACTION_CODES.ON_BULK_ACTION === actionCode) {
                    const showBulkActionTopBar =
                      selectedData.length > 0 ? true : false;
                    props.onActionPerformed(actionCode, selectedData);
                    setLeadMainTableViewState((prev) => {
                      return {
                        ...prev,
                        showBulkActionTopBar: showBulkActionTopBar,
                        selectedMember: selectedData,
                      };
                    });
                  } else {
                    props.onActionPerformed(actionCode, selectedData);
                  }
                }}
                inboxItem={inboxItem}
                personTypeUuid={personTypeUuid}
                personType={personType}
                selectedUserPracticeLocation={
                  leadMainTableViewState.selectedUserPracticeLocation
                }
                clearAllEvent={leadMainTableViewState.clearAllEmail}
                updateContactCount={updateContactCount}
                contactUpdated={props?.contactUpdated}
                tab={tab}
              />}
            </View>
          </>
          )}
          {(!leadMainTableViewState?.isLoading && enableCareProgram && personType === PERSON_TYPES.CUSTOMER) && (
            <>
             <View key={`care_program_base_view`}>
                <ContactListView
                  addOrUpdateEvent={props.addOrUpdateEvent}
                  searchText={searchString}
                  status={leadMainTableViewState?.formData?.status}
                  loggedInStatus={leadMainTableViewState?.formData?.loggedInStatus}
                  formData={leadMainTableViewState?.formData}
                  tagItem={tagItem}
                  currentPage={currentPage}
                  inboxItem={inboxItem}
                  personTypeUuid={personTypeUuid}
                  personType={personType}
                  selectedUserPracticeLocation={
                    leadMainTableViewState.selectedUserPracticeLocation
                  }
                  clearAllEvent={leadMainTableViewState.clearAllEmail}
                  updateContactCount={updateContactCount}
                  contactUpdated={props?.contactUpdated}
                  tab={props?.tab}
                  onActionPerformed={(actionCode: string, selectedData: any) => {
                    if (COMMON_ACTION_CODES.CHANGE_PAGE == actionCode) {
                      setCurrentPage(selectedData);
                      let newQuery: any = {};
                      try {
                        if (filters && JSON.parse(filters)) {
                          newQuery = JSON.parse(filters);
                        }
                      } finally {
                        if (selectedData) {
                          newQuery['page'] = selectedData;
                        } else if (newQuery['page']) {
                          delete newQuery['page'];
                        }
                      }
                      if (Object.keys(newQuery)?.length) {
                        navigate(
                          `${location.pathname}?filters=${encodeURIComponent(
                            JSON.stringify(newQuery)
                          )}`
                        );
                      } else {
                        navigate(`${location.pathname}`);
                      }
                      return;
                    }
                    if (COMMON_ACTION_CODES.ON_BULK_ACTION === actionCode) {
                      const showBulkActionTopBar =
                        selectedData.length > 0 ? true : false;
                      props.onActionPerformed(actionCode, selectedData);
                      setLeadMainTableViewState((prev) => {
                        return {
                          ...prev,
                          showBulkActionTopBar: showBulkActionTopBar,
                          selectedMember: selectedData,
                        };
                      });
                    } else {
                      props.onActionPerformed(actionCode, selectedData);
                    }
                  }}
                  onSubFilterChange={(categoryCode: string, dashboardCount: number) => {
                    if (updateContactCount && typeof updateContactCount === 'function') {
                      updateContactCount(dashboardCount)
                    }
                  }}
                  refreshData={props?.refreshData}
                />
             </View>
            </>
          )}
        </VStack>
      </View>
      {selectedViewCode === 'FILTER_VIEW' && (
        <FilterDrawerView
          initialContactType={initialContactType}
          formData={leadMainTableViewState.formData}
          onActionPerformed={onActionPerformed}
          labelsTagList={leadMainTableViewState.labelsTagList}
          personTypeUuid={personTypeUuid}
        />
      )}
    </>
  );
};
