import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  formElement: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  formLabel: {
    marginLeft: 4,
  },
  selectOption: {
    display: 'flex',
    flexDirection: 'row',
    padding: 8,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
  },
  view: {
    marginRight: 10,
    flex: 0.05,
  },
  textView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 0.95,
  },
  marginVertical8: {
    marginVertical: 8,
  },
  marginVertical10: {
    marginVertical: 10,
  },
});
