import {gql} from '@apollo/client';

export const GET_ALL_JOURNEYS = gql`
  query GetAllJourney(
    $searchString: String!
    $offset: Int!
    $limit: Int!
    $orderBy: [CareProgram_order_by!]
  ) {
    carePrograms(
      offset: $offset
      limit: $limit
      order_by: $orderBy
      where: {title: {_ilike: $searchString}}
    ) {
      description
      id
      status {
        code
        id
        value
      }
      title
      tags {
        tagName {
          tagName
        }
        id
      }
      patientCarePrograms_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_CONTACT_CARE_PROGRAM_ID = gql`
  query getContactCareProgramId($params: contact_care_program_bool_exp!) {
    contactCarePrograms (where: $params) {
      id
      payerCareProgram {
        careProgramTypeId
      }
    }
  }
`;

export const GET_BILLABLE_TIME_MINUTES = gql`
    mutation getBillableMinutes($params: GetBillableMinutesInput!) {
      getBillableMinutes(params: $params) {
        totalBillingMinutes
      }
  }
`;

export const GET_PREVIOUS_RUNNING_TIME = gql`
  query getPreviousRunningTime($where: contact_care_program_timer_logging_bool_exp!) {
    contactCareProgramTimerLoggings(where: $where) {
      timerSeconds
    }
  }
`;

export const LOG_BILLING_ACTIVITY = gql`
  mutation addContactCareProgramBillableActivity($params: AddContactCareProgramBillableActivityInput!) {
    addContactCareProgramBillableActivity(params: $params) {
      billableActivityData {
          totalBillingMinutes
      }
      updatedTimerSeconds {
          id
          timerSeconds
      }
    }
  }
`;

export const SAVE_RECORDED_TIME_FOR_BILLING = gql`
  mutation logTimerActivityMinutes($params: TimerLoggingInput!) {
    logTimerActivityMinutes(params: $params) {
      id
      timerSeconds
    }
  }
`;


export const ADD_CARE_PROGRAM_STEP = gql`
  mutation addCareProgramStep($params: AddCareProgramStepInput!) {
    addCareProgramStep(params: $params) {
      careProgramSteps
    }
  }
`;

export default {
  GET_ALL_JOURNEYS,
  GET_CONTACT_CARE_PROGRAM_ID,
  GET_BILLABLE_TIME_MINUTES,
  GET_PREVIOUS_RUNNING_TIME,
  LOG_BILLING_ACTIVITY,
  SAVE_RECORDED_TIME_FOR_BILLING,
};
