import React from 'react';
import { isWeb } from '../../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const CallHoldActive = () => {
  return (
    <>
    {isWeb() ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      fill="none"
      viewBox="0 0 16 14"
    >
      <path
        stroke="#D0D5DD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.667 13v-1.333A2.667 2.667 0 008 9H3.333a2.667 2.667 0 00-2.666 2.667V13m12.666-8.667v4m2-2h-4m-3-2.666a2.667 2.667 0 11-5.333 0 2.667 2.667 0 015.333 0z"
      ></path>
    </svg>
    ) : (
      <Image
        resizeMode="contain"
        size={'24px'}
        source={require('../../../../assets/images/user.png')}
        alt="image"
      />
    )}
  </>
  );
};

export default CallHoldActive;
