import moment from "moment";
import {IDateRangeEmp} from "../../components/RightSideContainer/Analytics/EmployerAnalytics/interface";
import {ANALYTICS_RESOURCE, DATE_FORMATS} from "../../constants";
import BaseService from "../CommonService/BaseService";
import {ISingleContactDrillDownBodyData} from "./interface";
import {DRILL_DOWN_RESOURCE_CODE} from "../../components/RightSideContainer/Analytics/EmployerAnalytics/EmployerUtils/EmployerConst";

export const drillDownByAccountId = async (resourceCode: string, pageNo: number, pageSize: number, data: any, dateRange?: IDateRangeEmp, memberType?: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  let appliedFilter: any[] = []
  const filterData = {
    filterCode: 'DATE_RANGE',
    filterValue: {
      fromDate: moment(dateRange?.uiFromDate).format(`${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`),
      toDate: moment(dateRange?.uiToDate).format(`${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`),
    },
  };
  if (data?.filterData?.length) {
    appliedFilter = [...data?.filterData]
  } else {
    appliedFilter.push(filterData)
    if (data?.filterData) {
      appliedFilter.push(data?.filterData)
    }
  }

  const body = {
    resourceCode: resourceCode,
    isAccountDashboard: true,
    memberType: memberType,
    paging: {
      pageNo: pageNo,
      pageSize: pageSize,
    },
    appliedFilter: [...appliedFilter]
  };
  return baseService.post(`/crm-nest/account/api/employer/drill-down-data`, {
    ...body
  });
}


export const getSingleContactDrillDownData = async (bodyData: ISingleContactDrillDownBodyData) => {
  const baseService = BaseService.getSharedInstance().axios;

  return baseService.post(`/crm-nest/account/api/employer/single-contact-drill-down-data`, {
    ...bodyData
  });

}
