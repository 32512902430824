import {Popover, Radio, RadioChangeEvent} from 'antd';
import {Text, View} from 'native-base';
import {useState} from 'react';
import {IPatientContextDropDown, PatientContextValues} from './Interfaces';
import {styles} from './style';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../styles';

const PatientContextDropDown = (props: IPatientContextDropDown) => {
  const {patientContext, patientContextValues, onPatientContextChange} = props;
  const [popoverState, setPopoverState] = useState(false);

  const handleOpenChange = (newPopoverValue: boolean) => {
    setPopoverState(newPopoverValue);
  };

  return (
    <View style={styles.checkBoxDropDownContainer}>
      <Popover
        content={
          <Radio.Group
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            value={patientContext}
            defaultValue={patientContext}
            onChange={(values: RadioChangeEvent) => {
              onPatientContextChange(values?.target?.value);
            }}
          >
            {patientContextValues.map((option: PatientContextValues) => (
              <Radio
                style={{
                  margin: 5,
                  fontWeight: 500,
                  color: '#3A485F',
                  fontSize: 15,
                }}
                key={option?.code}
                value={option?.code}
              >
                {option?.code}
              </Radio>
            ))}
          </Radio.Group>
        }
        placement="bottomLeft"
        trigger="click"
        open={popoverState}
        onOpenChange={handleOpenChange}
      >
        <Text cursor={'pointer'}>
          Patient Context
          {patientContext && (
            <Text fontWeight={'bold'}> : {patientContext}</Text>
          )}
        </Text>
        <AntIcon
          name="down"
          color={Colors.Custom.Gray600}
          size={12}
          style={{marginLeft: 4}}
        />
      </Popover>
    </View>
  );
};

export default PatientContextDropDown;
