import React from 'react';
import {Modal, Button, Text, View} from 'native-base';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../styles';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {BUTTON_TYPE} from '../../../../constants';
import {useIntl} from 'react-intl';

const {Body, Content, Footer, Header} = Modal;

interface IDeleteTaskPoolModalProps {
  title: string;
  isDeleteModalOpen: boolean;
  onDeleteModalClose: () => void;
  onCancel: () => void;
}

const DeleteTaskPoolModal = (props: IDeleteTaskPoolModalProps) => {
  const {isDeleteModalOpen, onDeleteModalClose, onCancel, title} = props;
  const intl = useIntl();

  return (
    <Modal isOpen={isDeleteModalOpen} onClose={onCancel}>
      <Content
        style={{
          backgroundColor: Colors.Custom.BackgroundColor,
          minWidth: 600,
        }}
      >
        <Modal.CloseButton />
        <Header>
          <DisplayText textLocalId={'Delete Task Pool'} textType="Heading" />
        </Header>
        <Body>
          <View>
            <Text size={'mdNormal'}>
              This action will remove {title} task pool from system.
            </Text>
            <Text size={'mdNormal'}>Are you sure?</Text>
          </View>
        </Body>
        <Footer
          style={{
            backgroundColor: Colors.Custom.BackgroundColor,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button.Group>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onCancel();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onDeleteModalClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'delete',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </Footer>
      </Content>
    </Modal>
  );
};

export default DeleteTaskPoolModal;
