import React from "react";
import { Svg, Path } from 'react-native-svg';

function PenIcon() {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <Path d="M18.3601 8.07866L19.2869 7.15178C20.8226 5.61607 23.3125 5.61607 24.8482 7.15178C26.3839 8.68748 26.3839 11.1774 24.8482 12.7131L23.9213 13.6399M18.3601 8.07866C18.3601 8.07866 18.4759 10.0483 20.2138 11.7862C21.9517 13.5241 23.9213 13.6399 23.9213 13.6399M18.3601 8.07866L9.83882 16.5999C9.26166 17.1771 8.97308 17.4656 8.7249 17.7838C8.43213 18.1592 8.18114 18.5653 7.97634 18.995C7.80273 19.3593 7.67368 19.7465 7.41556 20.5208L6.32181 23.8021M23.9213 13.6399L15.4001 22.1612C14.8229 22.7383 14.5344 23.0269 14.2162 23.2751C13.8408 23.5679 13.4347 23.8189 13.005 24.0237C12.6407 24.1973 12.2535 24.3263 11.4792 24.5844L8.19792 25.6782M8.19792 25.6782L7.39584 25.9456C7.01478 26.0726 6.59466 25.9734 6.31063 25.6894C6.0266 25.4053 5.92743 24.9852 6.05445 24.6042L6.32181 23.8021M8.19792 25.6782L6.32181 23.8021" stroke="#6F7A90" />
    </Svg>
  );
}

export default PenIcon;