import { gql } from '@apollo/client';




export const updateEmployee = gql`
  mutation updateEmployee($employerId: uuid, $contactId: uuid!) {
    updateEmployees(
      where: {isDeleted: {_eq: false}, contactId: {_eq: $contactId}}
      _set: {employerId: $employerId}
    ) {
      returning {
        id
      }
    }
  }
`;

export const GET_MERGE_DELAY_TRIGGERS = `
query getTriggers($limit: Int, $offset: Int){
    mergeAndDelayedTriggers(limit: $limit, offset: $offset, where: {isDeleted: {_eq: false}}) {
        id
        isDeleted
        minute
        tenantId
        hour
        type
        timezone
      }
    }
`;

export const ADD_UPDATE_MERGE_DELAY_TRIGGER = `
mutation createMergeAndDelayedTrigger(
    $data: mergeAndDelayedTrigger_insert_input!
   ) {
     insertMergeAndDelayedTrigger(
       object: $data,on_conflict:{constraint: merge_and_delayed_trigger_pkey,update_columns: [hour, minute, timezone, isDeleted, type]}
     ) {
         id
     }
   }
`;


export default {
    ADD_UPDATE_MERGE_DELAY_TRIGGER,
};
