import React from "react";

const ImagingSavingsSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
   <svg
   xmlns="http://www.w3.org/2000/svg"
   style={{width: 'inherit', height: 'inherit'}}
   fill="none"
   viewBox="0 0 24 25"
 >
   <g>
     <path
       stroke="#98A2B3"
       strokeLinecap="round"
       strokeLinejoin="round"
       strokeWidth="2"
       d="M8.447 7.974h7.106m-8.053 3h9m-9 3h9m-8.053 3h7.106m-3.79-10.5v11.5m-6.868 5.75h14.21c1.047 0 1.895-.896 1.895-2v-18c0-1.105-.848-2-1.895-2H4.895c-1.047 0-1.895.895-1.895 2v18c0 1.104.848 2 1.895 2z"
     ></path>
   </g>
 </svg>
  );
}

export default React.memo(ImagingSavingsSvg);
