import {Checkbox, HStack, VStack} from 'native-base';
import React from 'react';
import {DisplayText} from '../DisplayText/DisplayText';
const FilterByDoneOrder = (props: any) => {
  const {returnFilterItems} = props;
  return (
        <HStack alignItems={'center'} my={1.5}>
          <VStack>
            <Checkbox
              defaultIsChecked={true}
              onChange={(isSelected) => {
                returnFilterItems(isSelected);
              }}
              value={'1'}
            />
          </VStack>
          <VStack>
            <DisplayText
              textLocalId="onlyCompleteOrder"
              size={'smMedium'}
              extraStyles={{marginLeft: 4}}
            ></DisplayText>
          </VStack>
        </HStack>
  );
};

export default FilterByDoneOrder;
