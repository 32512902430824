import { setAxiosCommonHeaders } from './utils/APIUtils';
setAxiosCommonHeaders();
import axios from 'axios';
import {NativeBaseProvider, useToast} from 'native-base';
import React, {useEffect} from 'react';
import {Platform, StatusBar} from 'react-native';
import { enableScreens } from 'react-native-screens';
import {MAIN_MENU_CODES} from './components/SideMenuBar/SideBarConst';
import {BASE_URL} from './constants/Configs';
import AppStateProvider from './context/HmsVideoMeetingContext';
import {I18nProvider} from './i18n';
import MainScreenBodyView from './screens/MainScreen/MainScreenBodyView';
import {showToast, ToastType} from './utils/commonViewUtils';
import LocalStorage from './utils/LocalStorage';
import {isAndroid, isWeb} from './utils/platformCheckUtils';
import {openEventsScreen, setShortKeysCombination} from './utils/ShortKeys';
import { Colors } from './styles';
import ToastProvider from './components/Toast/ToastProvider';

const App = () => {
  if (window.gc && (typeof window.gc === 'function')) {
    setInterval(() => {
      window.gc?.();
    }, 1000 * 60 * 3); // 3 minutes
  } else {
    console.log('Browser does not support garbage collection');
  }
  useEffect(() => {
    StatusBar.setBarStyle(Platform.OS === 'ios' ? 'dark-content' : 'default');
    if (isAndroid()) {
      StatusBar.setBackgroundColor(Colors.Custom.MonochromeBlack, true);
    }

    if (!isWeb()) {
      enableScreens(false);
    } else {
      // session expired handling is for web here and for mobile, it is done under Mainscreen.native file
      handleServiceInterceptorResponse();
    }
  }, []);
  const toast = useToast();
  if (isWeb() && window) {
    window.name =
      (window?.location?.href || '').indexOf('#/admin/') != -1
        ? MAIN_MENU_CODES.DESIGNER
        : MAIN_MENU_CODES.HOME;
    window.addEventListener(
      'keydown',
      (event) => {
        const keyName = event.key;
        setShortKeysCombination(event);
      },
      false
    );

    window.addEventListener(
      'keyup',
      (event) => {
        const keyName = event.key;
        if (keyName === 'Control') {
          //alert('Control key was released');
        }
      },
      false
    );

    window.addEventListener(
      'message',
      (event) => {
        const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
        if (!window.location.href.includes('/admin')) {
          openEventsScreen(JSON.stringify(event.data).replace(regex, ''));
        }
      },
      false
    );
  }

  const handleServiceInterceptorResponse = () => {

    axios.interceptors.response.use(
        (response) => {
        return response;
      },
      (error) => {
        handleUnauthorizedError(error);
        return Promise.reject(error);
      }
    );
  };
  const movedToLoginScreen = () => {
    LocalStorage.getItem('user').then((result) => {
      if (result !== null) {
        LocalStorage.removeItem('consent_Obj');
        LocalStorage.removeItem('user').then(() => {
          showToast(
            toast,
            'Your session has expired, please login again to continue!',
            ToastType.error,
            1500
          );
          // setIsLoginSuccess(false);
        });
      }
      window.location.reload()
    });
  };
  const handleUnauthorizedError = (error: any) => {
    if (
      error?.response?.status == 401 &&
      error.response?.request?.responseURL?.startsWith(BASE_URL)
    ) {
      movedToLoginScreen();
    }
  };

  return (
    <AppStateProvider>
      <I18nProvider>
        <NativeBaseProvider >
          <ToastProvider>
            <MainScreenBodyView />
          </ToastProvider>
        </NativeBaseProvider>
      </I18nProvider>
    </AppStateProvider>
  );
};
export default App;
