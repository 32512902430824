import {createContext, useContext} from 'react';
import { IPersonData } from './MiddleContainer/interfaces';
import { IContact } from '../RightSideContainer/TeamInbox/Conversations/interfaces';


export const PersonOmniViewContext = createContext<{
    formattedData: IPersonData,
    data: IContact,
}>({
    formattedData: {} as IPersonData,
    data: {} as IContact,
});


export const usePersonOmniViewContext = () => {
    return useContext(PersonOmniViewContext);
}

const PersonOmniViewProvider = PersonOmniViewContext.Provider;

export default PersonOmniViewProvider;
