import React from 'react';
import {Image} from 'native-base';
import {isWeb} from '../../../../utils/platformCheckUtils';

const SearchMessageSvg = () => {
  return (
    <>
     {isWeb() ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="247"
        height="143"
        fill="none"
        viewBox="0 0 247 143"
      >
        <path
          fill="#F2F4F7"
          d="M218.72 33.484a6.403 6.403 0 00-6.403-6.404H61.299a6.404 6.404 0 00-6.404 6.404v40.618a6.404 6.404 0 006.341 6.403l75.572.739 40.956.4 20.478.2 7.785.076c1.625.016 3.237.297 4.772.832l6.503 2.266a1.067 1.067 0 001.418-1.007V33.484z"
        ></path>
        <rect
          width="75.776"
          height="4.803"
          fill="#D0D5DD"
          rx="2.401"
          transform="matrix(-1 0 0 1 142.41 38.554)"
        ></rect>
        <g filter="url(#filter0_d_455_32628)">
          <path
            fill="#fff"
            d="M25.811 62.566a6.404 6.404 0 016.404-6.404h151.018a6.404 6.404 0 016.404 6.404v40.618a6.403 6.403 0 01-6.341 6.403l-75.572.739-40.956.4-20.478.2-7.785.076a14.942 14.942 0 00-4.772.832L27.23 114.1a1.067 1.067 0 01-1.419-1.008V62.566z"
          ></path>
          <rect
            width="75.776"
            height="4.803"
            x="37.818"
            y="67.636"
            fill="#D0D5DD"
            rx="2.401"
          ></rect>
          <rect
            width="128.072"
            height="4.803"
            x="37.818"
            y="80.976"
            fill="#E3E6EB"
            rx="2.401"
          ></rect>
          <rect
            width="53.363"
            height="4.803"
            x="37.818"
            y="94.317"
            fill="#E3E6EB"
            rx="2.401"
          ></rect>
        </g>
        <circle cx="211.782" cy="104.726" r="4.002" fill="#D0D5DD"></circle>
        <circle cx="21.81" cy="44.958" r="4.002" fill="#D0D5DD"></circle>
        <circle
          cx="50.626"
          cy="6.537"
          r="3.469"
          stroke="#D0D5DD"
          strokeWidth="1.067"
        ></circle>
        <g clipPath="url(#clip0_455_32628)">
          <path
            fill="#D0D5DD"
            d="M117.063 8.938h-3.202v3.202h-1.067V8.938h-3.202V7.87h3.202V4.669h1.067V7.87h3.202v1.067z"
          ></path>
        </g>
        <g clipPath="url(#clip1_455_32628)">
          <path
            fill="#D0D5DD"
            d="M15.138 93.253h-3.201v3.202h-1.068v-3.202H7.667v-1.067h3.202v-3.202h1.068v3.202h3.201v1.067z"
          ></path>
        </g>
        <g filter="url(#filter1_d_455_32628)">
          <circle cx="218.529" cy="27.083" r="19.058" fill="#fff"></circle>
        </g>
        <path
          stroke="#98A2B3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M227.25 35.75l-3.988-3.988m2.155-5.179a7.334 7.334 0 11-14.668 0 7.334 7.334 0 0114.668 0z"
        ></path>
        <g clipPath="url(#clip2_455_32628)">
          <path
            fill="#D0D5DD"
            d="M240.102 68.324h-3.201v3.202h-1.068v-3.202h-3.201v-1.067h3.201v-3.202h1.068v3.201h3.201v1.068z"
          ></path>
        </g>
        <circle
          cx="179.078"
          cy="5.166"
          r="3.469"
          stroke="#D0D5DD"
          strokeWidth="1.067"
        ></circle>
        <defs>
          <filter
            id="filter0_d_455_32628"
            width="208.651"
            height="103.259"
            x="0.197"
            y="40.153"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset dx="-3.202" dy="6.404"></feOffset>
            <feGaussianBlur stdDeviation="11.206"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0.596078 0 0 0 0 0.635294 0 0 0 0 0.701961 0 0 0 0.6 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_455_32628"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_455_32628"
              result="shape"
            ></feBlend>
          </filter>
          <filter
            id="filter1_d_455_32628"
            width="56.413"
            height="56.413"
            x="190.323"
            y="1.926"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset dy="3.049"></feOffset>
            <feGaussianBlur stdDeviation="4.574"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0.596373 0 0 0 0 0.635165 0 0 0 0 0.701667 0 0 0 0.6 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_455_32628"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_455_32628"
              result="shape"
            ></feBlend>
          </filter>
          <clipPath id="clip0_455_32628">
            <path
              fill="#fff"
              d="M0 0H12.807V12.807H0z"
              transform="translate(106.924 2.002)"
            ></path>
          </clipPath>
          <clipPath id="clip1_455_32628">
            <path
              fill="#fff"
              d="M0 0H12.807V12.807H0z"
              transform="translate(5 86.314)"
            ></path>
          </clipPath>
          <clipPath id="clip2_455_32628">
            <path
              fill="#fff"
              d="M0 0H12.807V12.807H0z"
              transform="translate(229.964 61.388)"
            ></path>
          </clipPath>
        </defs>
      </svg>
      ) : (
        <Image
          style={{
            width: 240,
            height: 130,
          }}
          resizeMode='stretch'
          source={require('../../../../assets/images/Conversations/SearchMessageSvg.png')}
          // alt={'aa'}
        />
      )}
    </>
  );
};

export default SearchMessageSvg;
