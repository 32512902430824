import {Text, View} from 'native-base';
import React from 'react';
import {Colors} from '../../../../styles';
// import Icon from 'react-native-vector-icons/AntDesign';
// import {Colors} from '../../../../styles';
import {NoDataFound} from '../../NoDataFound';
import FilterListView from './FilterListView';

const CustomFilterList = (props: any) => {
  const {tagList, title, onFilterSelect, displayString} = props;
  return (
    <View>
      {tagList && tagList.length > 0 ? (
        <>
          {title && (
            <View>
              <Text
                size={'xsMedium'}
                style={{
                  fontSize: 10,
                  marginVertical: 8,
                  color: Colors.Custom.Gray500,
                }}
              >
                {title}
              </Text>
            </View>
          )}
          <FilterListView tagList={tagList} onFilterSelect={onFilterSelect} />
          {/* {tagList.map((tagItem: any, index: number) => {
            return (
              <Pressable
                //   _hover={{bg: Colors.primary[100]}}
                key={index}
                onPress={() => {
                  onFilterSelect(tagItem);
                }}
                flex={1}
              >
                <HStack
                  style={{
                    flex: 2.8,
                    flexDirection: 'row',
                  }}
                >
                  <Badge
                    backgroundColor={'transparent'}
                    // backgroundColor={tagItem.color['100' + '7a']}
                    color={tagItem.color || 'gray.500'}
                    alignSelf="center"
                    variant="solid"
                    style={{
                      paddingHorizontal: 8,
                      paddingVertical: 2,
                      borderColor: 'gray',
                      borderWidth: 1,
                      borderRadius: 16,
                      minHeight: 22,
                      marginVertical: 8,
                    }}
                  >
                    <Text
                      style={{color: tagItem.color || 'gray.500'}}
                      size={'xsMedium'}
                    >
                      {tagItem.title ? tagItem.title : tagItem.name}
                    </Text>
                  </Badge>
                </HStack>
              </Pressable>
            );
          })} */}
        </>
      ) : (
        <NoDataFound
          size="smMedium"
          textStyle={{fontSize: 14}}
          displayString={displayString}
        />
      )}
    </View>
  );
};

export default CustomFilterList;
