import {Steps} from 'antd';
import {HStack, Text, useToast, View} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useInterval} from 'react-use';
import {BUTTON_TYPE} from '../../../../../constants';
import CommonService from '../../../../../services/CommonService/CommonService';
import {Colors} from '../../../../../styles';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {Consent} from '../../../../common/Consent/Consent';
import LoadingSpinner from '../../../../common/Loader/LoadingSpinner';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {PracticeDetailsProps} from '../interfaces';
import {styles} from './PracticeDetailsStyles';
const {Step} = Steps;

const PracticeDetails = (props: PracticeDetailsProps) => {
  const {details} = props;
  const intl = useIntl();
  const commonService = CommonService.getCommonServiceInstance();
  const integrationService = commonService.integrationService;
  const toast = useToast();
  const [employer, setEmployer] = useState(0);
  const [patient, setPatient] = useState(0);
  const [membership, setMembership] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [current, setCurrent] = useState(0);
  const [syncState, setSyncState] = useState({
    employerSync: '' as any,
    employeeSync: '' as any,
    membershipSync: '' as any,
  });
  const [consent, setConsent] = useState(true);

  const interval = useInterval(async () => {
    if (!employer) {
      const url = 'api/service-request?serviceType=HintCompanySync';
      integrationService
        .get(url, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((resp: {data: any}) => {
          if (resp?.data) {
            setSyncState((prev) => {
              return {
                ...prev,
                employerSync: resp?.data[0]?.serviceStatus,
              };
            });
            if (resp?.data[0]?.serviceStatus === 'COMPLETED') {
              setEmployer(1);
              setCurrent(1);
            }
          }
        })
        .catch((e: any) => {

          setEmployer(0);
        });
    }

    if (!patient) {
      const url = 'api/service-request?serviceType=HintPatientSync';
      integrationService
        .get(url, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((resp: {data: any}) => {
          if (resp?.data) {
            setSyncState((prev) => {
              return {
                ...prev,
                employeeSync: resp?.data[0]?.serviceStatus,
              };
            });
            if (resp?.data[0]?.serviceStatus === 'COMPLETED') {
              setPatient(1);
              setCurrent(2);
            }
          }
        })
        .catch((e: any) => {

          setPatient(0);
        });
    }
    if (!membership) {
      const url = 'api/service-request?serviceType=HintMembershipSync';
      integrationService
        .get(url, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((resp: {data: any}) => {
          if (resp?.data) {
            setSyncState((prev) => {
              return {
                ...prev,
                membershipSync: resp?.data[0]?.serviceStatus,
              };
            });
            if (resp?.data[0]?.serviceStatus === 'COMPLETED') {
              setMembership(1);
              setCurrent(2);
            }
          }
        })
        .catch((e: any) => {

          setMembership(0);
        });
    }
  }, 1000);

  const steps = [
    {
      key: 'Employer Sync',
      title: (
        <>
          <Text>Hint Employer/Company Sync</Text>{' '}
          {syncState.employerSync === 'FAILED' ? (
            <Text color={Colors.Custom.Danger100} size={'smMedium'}>
              {syncState.employerSync}
            </Text>
          ) : (
            <Text
              color={
                syncState.employerSync === 'COMPLETED'
                  ? Colors.success[600]
                  : Colors.Custom.Gray400
              }
              size={'smMedium'}
            >
              {syncState.employerSync}
            </Text>
          )}
        </>
      ),
      content: (
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              syncEmployerCall();
            },
            isDisabled: employer ? true : false,
            style: {marginRight: 20},
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'Sync Hint Employer/Company',
            }),
            withRightBorder: false,
          }}
        ></FoldButton>
      ),
    },

    {
      key: 'Employee Sync',
      title: (
        <>
          <Text>Hint Employee/patient Sync</Text>{' '}
          {syncState.employeeSync === 'FAILED' ? (
            <Text color={Colors.Custom.Danger100} size={'smMedium'}>
              {syncState.employeeSync}
            </Text>
          ) : (
            <Text
              color={
                syncState.employeeSync === 'COMPLETED'
                  ? Colors.success[600]
                  : Colors.Custom.Gray400
              }
              size={'smMedium'}
            >
              {syncState.employeeSync}
            </Text>
          )}
        </>
      ),
      content: employer ? (
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              syncPatientCall();
            },
            isDisabled: patient ? true : false,
            style: {marginRight: 10},
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'Sync Hint Employee/patient',
            }),
            withRightBorder: false,
          }}
        ></FoldButton>
      ) : (
        <></>
      ),
    },
    {
      key: 'Membership Sync',
      title: (
        <>
          <Text>Hint Membership Sync</Text>{' '}
          {syncState.membershipSync === 'FAILED' ? (
            <Text color={Colors.Custom.Danger100} size={'smMedium'}>
              {syncState.membershipSync}
            </Text>
          ) : (
            <Text
              color={
                syncState.membershipSync === 'COMPLETED'
                  ? Colors.success[600]
                  : Colors.Custom.Gray400
              }
              size={'smMedium'}
            >
              {syncState.membershipSync}
            </Text>
          )}
        </>
      ),
      content: patient ? (
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              syncMembsershipCall();
            },
            isDisabled: membership ? true : false,
            style: {marginRight: 10},
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'Sync Hint Membership',
            }),
            withRightBorder: false,
          }}
        ></FoldButton>
      ) : (
        <></>
      ),
    },
  ];

  const syncEmployerCall = () => {
    const url = '/api/sync/hint/company';
    setShowLoader(true);
    integrationService
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((resp: {data: any}) => {
        if (resp?.data) {
          setShowLoader(false);
          showToast(
            toast,
            `Employer Data is being synced. We will notify you once the import is complete.`,
            ToastType.info
          );
          setEmployer(1);
        }
      })
      .catch((e: any) => {

        setShowLoader(false);
        false;
        showToast(
          toast,
          `Employer Data could not be synced. Please contact support at hello@fold.health`,
          ToastType.error
        );
      });
  };

  const syncPatientCall = () => {
    const url = '/api/sync/hint/patient';
    setShowLoader(true);
    integrationService
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((resp: {data: any}) => {
        if (resp?.data) {
          setShowLoader(false);
          showToast(
            toast,
            `Patient Data is being synced. We will notify you once the import is complete.`,
            ToastType.info
          );
          setPatient(1);
        }
      })
      .catch((e: any) => {

        setShowLoader(false);
        false;
        showToast(
          toast,
          `Patient Data could not be synced. Please contact support at hello@fold.health`,
          ToastType.error
        );
      });
  };

  const syncMembsershipCall = () => {
    const url = '/api/sync/hint/membership';
    setShowLoader(true);
    integrationService
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((resp: {data: any}) => {
        if (resp?.data) {
          setShowLoader(false);
        }
      })
      .catch((e: any) => {

        setShowLoader(false);
        false;
        showToast(
          toast,
          `Membership Data could not be synced. Please contact support at hello@fold.health`,
          ToastType.error
        );
      });
    setShowLoader(false);
    showToast(
      toast,
      `Membership Data is being synced. We will notify you once the import is complete.`,
      ToastType.info
    );
  };

  const onChange = (value: number) => {
    setCurrent(value);
  };

  const {
    city,
    country,
    email,
    integrationRecordId,
    line1,
    line2,
    logoUrl,
    name,
    phone,
    state,
    url,
    zipCode,
  } = details;
  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <Text size={'lgBold'} ml={2}>
          Practice Details
        </Text>
        {name && (
          <HStack style={styles.rowStyle}>
            <Text style={styles.labelText}>Name</Text>
            <Text style={styles.dataText}>{name}</Text>
          </HStack>
        )}
        {email && (
          <HStack style={styles.rowStyle}>
            <Text style={styles.labelText}>Email</Text>
            <Text style={styles.dataText}>{email}</Text>
          </HStack>
        )}
        {logoUrl && (
          <HStack style={styles.rowStyle}>
            <Text style={styles.labelText}>Logo URL </Text>
            <Text style={styles.dataText}>{logoUrl}</Text>
          </HStack>
        )}
        {url && (
          <HStack style={styles.rowStyle}>
            <Text style={styles.labelText}>URL</Text>
            <Text style={styles.dataText}>{url}</Text>
          </HStack>
        )}
        {line1 && (
          <HStack style={styles.rowStyle}>
            <Text style={styles.labelText}>Address Line 1</Text>
            <Text style={styles.dataText}>{line1}</Text>
          </HStack>
        )}
        {line2 && (
          <HStack style={styles.rowStyle}>
            <Text style={styles.labelText}>Address Line 2</Text>
            <Text style={styles.dataText}>{line2}</Text>
          </HStack>
        )}
        {city && (
          <HStack style={styles.rowStyle}>
            <Text style={styles.labelText}>City</Text>
            <Text style={styles.dataText}>{city}</Text>
          </HStack>
        )}
        {state && (
          <HStack style={styles.rowStyle}>
            <Text style={styles.labelText}>State</Text>
            <Text style={styles.dataText}>{state}</Text>
          </HStack>
        )}
        {country && (
          <HStack style={styles.rowStyle}>
            <Text style={styles.labelText}>Country</Text>
            <Text style={styles.dataText}>{country}</Text>
          </HStack>
        )}
        {zipCode && (
          <HStack style={styles.rowStyle}>
            <Text style={styles.labelText}>Zipcode</Text>
            <Text style={styles.dataText}>{zipCode}</Text>
          </HStack>
        )}

        <Steps direction="vertical" current={current} onChange={onChange}>
          {steps.map((item) => (
            <Step key={item.key} title={item.title} />
          ))}
        </Steps>

        <div className="steps-content">{steps[current].content}</div>
        <HStack style={{marginTop: 20}}>
          <Consent
            isChecked={consent}
            setConsent={() => {
              setConsent(!consent);
            }}
            textLocaleId={'consents'}
          />
        </HStack>
        {showLoader && <LoadingSpinner />}
      </View>
    </View>
  );
};

export default PracticeDetails;
