import React, {useContext, useEffect, useState} from 'react';
import {Empty, Select, Tag, Tooltip} from 'antd';
import './styles.scss';
import {getGroupsList} from '../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {getAccountUUID, getBooleanFeatureFlag} from '../../../../../../utils/commonUtils';
import {debounce, isArray} from 'lodash';
import {getFormattedGroupData} from '../../../../ContentManagement/CampaignManagement/utils';
import {formatAndCombinePrevAndNewOptions} from './utils';
import { Spinner } from 'native-base';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import FeatureFlags from '../../../../../../constants/FeatureFlags.enums';
const {Option} = Select;

interface IPopulationGroupSearchAndSelectProps {
  value?: any;
  isMultiSelect: boolean;
  showError?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onChange?: (populationGroupList: string[]) => void;
  locationGroupId?: string;
}

export const PopulationGroupSearchAndSelect = (
  props: IPopulationGroupSearchAndSelectProps
) => {
  const mlovData = useContext(CommonDataContext);
  const accountUUID = getAccountUUID();
  const {
    value,
    onChange,
    placeholder,
    isMultiSelect,
    showError,
    isLoading,
    isDisabled,
    locationGroupId
  } = props;
  const { accountLocationListWithEHR , userSettings} = mlovData;
  const isMultiTenancyEnabled = getBooleanFeatureFlag(userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const locationIdsByLocationGroup = accountLocationListWithEHR
    ?.filter((location) => location.locationGroupId === locationGroupId)
    .map((location) => location.uuid);

  const [componentState, setComponentState] = useState<{
    list: any[];
    query: string;
    loading: boolean;
    searchLoading: boolean;
    selectedPopGroups: {id:string,label:string,value:string}[]
  }>({
    list: [],
    query: '',
    loading: false,
    selectedPopGroups:[],
    searchLoading: false
  });

  const searchGroups = async (
    searchString: string,
    handleLoading?: boolean
  ) => {
    setComponentState((prev) => ({
      ...prev,
      searchLoading: true,
    }));
    const response = await getGroupsList(
      accountUUID,
      10,
      searchString || '',
      0,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      isMultiTenancyEnabled ? locationIdsByLocationGroup : undefined,
    );
    const formmattedGroups = getFormattedGroupData(response?.data?.data, []);
    setComponentState((prev) => ({
      ...prev,
      list: formmattedGroups,
      ...(handleLoading && {loading: false}),
      searchLoading: false,
    }));
  };

  const getPopulationGroupName = (id: string) =>
    componentState.list.find((group: any) => group?.id === id)?.name;

  const tagRender = (tagProps: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const label:string = tagProps?.label;
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={tagProps.closable}
        onClose={tagProps.onClose}
        className="custom-tag"
      >
       <Tooltip title={label.length > 100 ? label : undefined}>
          {label.length > 100 ? `${label.slice(0,40)}...` : label}
        </Tooltip>
      </Tag>
    );
  };

  const getExistingDataAndFirstSet = async (searchString: string) => {
    const promiseList = [];
    //data for exisiting selected groups
    promiseList.push(
      getGroupsList(
        accountUUID,
        10,
        searchString || '',
        0,
        undefined,
        undefined,
        value,
        undefined,
        undefined,
        isMultiTenancyEnabled ? locationIdsByLocationGroup : undefined,
      )
    );
    // data for first set
    promiseList.push(
      getGroupsList(
        accountUUID,
        10,
        searchString || '',
        0,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        isMultiTenancyEnabled ? locationIdsByLocationGroup : undefined
      )
    );

    const response = await Promise.all(promiseList);
    const data = formatAndCombinePrevAndNewOptions(response);
    setComponentState((prev) => ({
      ...prev,
      list: data.dropdownList,
      loading: false,
      selectedPopGroups: data.selectedPopGrps
    }));
  };

  useEffect(() => {
    setComponentState((prev) => ({...prev, loading: true}));
    if (isArray(value) && value.length) {
      getExistingDataAndFirstSet('');
      return;
    }
    searchGroups('', true);
  }, []);

  const onChangeSelect = (value: any, options: any) => {
    setComponentState((prev) => ({...prev, selectedPopGroups: options}));
    onChange && onChange(value);
  }
  
  const isSelectLoading = isLoading || componentState.loading;
  return (
    <Select
      mode={isMultiSelect ? 'multiple' : undefined}
      disabled={isDisabled || componentState.loading}
      loading={isSelectLoading}
      size="large"
      allowClear
      showSearch
      value={
        isMultiSelect
          ? isSelectLoading
            ? []
            : componentState.selectedPopGroups
          : isSelectLoading
          ? undefined
          : componentState.selectedPopGroups?.[0]
      }
      placeholder={isSelectLoading ? 'Loading...' : placeholder}
      tagRender={isMultiSelect ? tagRender : undefined}
      onChange={onChangeSelect}
      status={showError ? 'error' : undefined}
      filterOption={(input: string, option: any) => {
        return (option?.label || '')
          .toLowerCase()
          .includes(input.toLowerCase());
      }}
      onSearch={debounce(searchGroups, 500)}
      notFoundContent={componentState.searchLoading ? <Spinner /> : <Empty  />}
    >
      {componentState.list.map((group: any,index:number) => {
        const name = group.name;
        const uuid = group.id;
        return (
          <Option key={uuid + '_' + index} value={uuid} label={name}>
            {name}
          </Option>
        );
      })}
    </Select>
  );
};
