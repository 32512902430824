import {Button, Popover} from 'antd';
import {Pressable, Text, VStack} from 'native-base';
import {useState} from 'react';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../styles';
import {
  ACTIONS_CODE_CT,
  costTemplateAction,
} from './helperFiles/CostTemplatesConst';
import {IActionViewProps} from './helperFiles/interface';
import {getIconByCode} from './helperFiles/ViewHelper';

const ActionView = (props: IActionViewProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {selectedRecord, onActionClick} = props;

  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };
  const content = (): JSX.Element => {
    let actionList = costTemplateAction;
    if (selectedRecord.isDefault) {
      actionList = actionList.filter(
        (item) => item.code !== ACTIONS_CODE_CT.SET_AS_DEFAULT
      );
    }
    return (
      <VStack>
        {actionList.map((item) => {
          return (
            <Pressable
              key={item.code}
              onPress={() => {
                onActionClick(item.code, selectedRecord)
                setIsOpen(false);
              }}
              style={{
                paddingBottom: 8,
                paddingTop: 12,
                paddingLeft: 4,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {getIconByCode(item.code)}
              <Text
                size={'smMedium'}
                style={{
                  color:
                    item.code === ACTIONS_CODE_CT.DELETE
                      ? '#D92D20'
                      : Colors.Custom.Gray700,
                  marginLeft: 8,
                }}
              >
                {item.name}
              </Text>
            </Pressable>
          );
        })}
      </VStack>
    );
  };
  return (
    <Popover
      // key={props?.record?.id}
      overlayInnerStyle={{padding: 0, borderRadius: 16}}
      overlayStyle={{padding: 0}}
      overlay={{padding: 0}}
      style={{padding: 0, borderRadius: 16}}
      placement={'left'}
      trigger="click"
      visible={isOpen}
      onVisibleChange={handleVisibleChange}
      content={content}
    >
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        style={{
          border: 'none',
          backgroundColor: 'transparent',
        }}
      >
        <Feather
          name="more-vertical"
          size={14}
          style={{
            marginLeft: 2,
            marginRight: 2,
            fontSize: 16,
            color: Colors.Custom.Gray600,
          }}
        />
      </Button>
    </Popover>
  );
};

export default ActionView;
