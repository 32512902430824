import {Popover} from 'antd';
import {HStack, Pressable, Skeleton, Text, VStack, View} from 'native-base';
import {useEffect, useRef, useState} from 'react';
import {LocationDataViewProps, ProductLocation} from './interfaces';
import {useContainerDimensions} from '../../../../CustomHooks/ContainerDimensionHook';
import {Colors} from '../../../../../styles';
import { styles } from './styles';

export const LocationDataListView = (props: LocationDataViewProps) => {
  const [stateData, setStateData] = useState({
    moreActionPopup: false,
    loading: true,
  });
  const componentRef = useRef();
  const {width} = useContainerDimensions(componentRef);

  const handleMoreActionVisibleChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        moreActionPopup: visible,
      };
    });
  };
  const [maxTagsToShow, setLabelToShow] = useState<ProductLocation[]>([]);

  useEffect(() => {
    getMaxWidth(props.locationList);
  }, [props.locationList]);

  const getMaxWidth = (labels: ProductLocation[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const maxTagsToShow: ProductLocation[] = [];
    let currentWidth = 0;
    const maxWidth = (props?.style?.width || width) / 12;

    if (labels?.length === 1) {
      setLabelToShow(labels);
    } else {
      for (const label of labels) {
        if (
          currentWidth + (label?.name || '').length <=
          maxWidth
        ) {
          currentWidth =
            currentWidth + (label?.name || '').length;
          maxTagsToShow.push(label);
        } else {
          if (maxTagsToShow?.length === 0) {
            maxTagsToShow.push(label);
          }
          break;
        }
      }
      setLabelToShow(maxTagsToShow);
    }
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
  };

  const renderLocations = (index: number, labelsItem: ProductLocation) => {
    return (
      <HStack
        key={index}
        style={[styles.locationList]}
      >
        <Text
          wordBreak={'break-word'}
          color={Colors.Custom.Gray700}
          fontWeight={500}
          fontSize={14}
        >
          {labelsItem?.name}
        </Text>
      </HStack>
    );
  };

  return (
    <>
      <View
        ref={componentRef}
        style={[
          {
            backgroundColor: props.hideAddMoreButton ? 'transparent' : '#fff',
            borderColor: Colors.Custom.Gray200,
            padding: props.hideAddMoreButton ? 0 : 16,
            paddingVertical: props.hideAddMoreButton ? 0 : 10,
            width: '100%',
            flex: 1,
            paddingTop: 4,
            paddingBottom: 4,
          },
        ]}
      >
        {stateData.loading ? (
          <VStack key={props.key} space={2}>
            <Skeleton
              height={4}
              borderRadius="lg"
              color={'gray'}
              bgColor={'gray'}
            />
          </VStack>
        ) : (
          <>
            <View flexDirection="row" width={'full'}>
              <View
                style={{flexDirection: 'row', alignItems: 'center'}}
                maxWidth={'100%'}
              >
                <HStack
                  alignItems={'center'}
                  maxWidth={'100%'}
                  flexWrap={'wrap'}
                  overflow={'auto'}
                >
                  <View
                    maxWidth={'100%'}
                    style={{flexDirection: 'row', alignItems: 'center'}}
                  >
                    {maxTagsToShow && maxTagsToShow.length ? (
                      maxTagsToShow.map(
                        (lablesItem: ProductLocation, index: number) => {
                          return lablesItem?.accountLocationUuid ? (
                            <>{renderLocations(index, lablesItem)}</>
                          ) : (
                            <></>
                          );
                        }
                      )
                    ) : (
                      <>
                        {props?.locationList?.length ? (
                          <></>
                        ) : (
                          <Text>{'-'}</Text>
                        )}
                      </>
                    )}
                  </View>
                  {(props?.locationList || [])?.length - maxTagsToShow?.length >
                    0 && (
                    <Popover
                      key={props?.key}
                      overlayInnerStyle={{padding: 0, borderRadius: 12}}
                      overlayClassName="patient-search-select-styles"
                      className="patient-search-select-styles"
                      overlayStyle={{padding: 0}}
                      overlay={{padding: 0}}
                      style={{padding: 0, borderRadius: 12}}
                      placement={'bottomLeft'}
                      trigger={'click'}
                      visible={stateData.moreActionPopup}
                      onVisibleChange={handleMoreActionVisibleChange}
                      content={
                        <View
                          key={props?.key}
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: 330,
                          }}
                        >
                          <View
                            style={{flexDirection: 'row', alignItems: 'center'}}
                            flexWrap={'wrap'}
                            overflow={'auto'}
                            maxWidth={'90%'}
                          >
                            {props?.locationList &&
                              props?.locationList.length &&
                              props?.locationList?.map(
                                (
                                  lablesItem: ProductLocation,
                                  index: number
                                ) => {
                                  return lablesItem?.accountLocationUuid ? (
                                    <>{renderLocations(index, lablesItem)}</>
                                  ) : (
                                    <></>
                                  );
                                }
                              )}
                          </View>
                        </View>
                      }
                    >
                      <span
                        onMouseEnter={() => {
                          setStateData((prev) => {
                            return {
                              ...prev,
                              moreActionPopup: true,
                            };
                          });
                        }}
                        onMouseLeave={() => {
                          setStateData((prev) => {
                            return {
                              ...prev,
                              moreActionPopup: false,
                            };
                          });
                        }}
                      >
                        <HStack
                          key={new Date().getTime()}
                          style={[styles.locationList]}
                        >
                          <Text
                            wordBreak={'break-word'}
                            color={Colors.Custom.Gray700}
                            fontWeight={500}
                            fontSize={14}
                          >
                            {`+${
                              (props.locationList || []).length -
                              maxTagsToShow?.length
                            }`}
                          </Text>
                        </HStack>
                      </span>
                    </Popover>
                  )}
                </HStack>
              </View>
            </View>
          </>
        )}
      </View>
    </>
  );
};
