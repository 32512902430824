import { Pressable, Text, View } from "native-base";
import AntDesign from 'react-native-vector-icons/AntDesign';
import { DisplayText } from "../../../common/DisplayText/DisplayText";
import { Colors } from "../../../../styles";
import MemberInfoListItem from "../../../common/MemberInfoListItem/MemberInfoListItem";
import {IContact} from '../../TeamInbox/Conversations/interfaces';
import { getDateStrFromFormat } from "../../../../utils/DateUtils";
import { JOURNEYS_STATUS_CODE, getCareJourneyColor, getCareJourneyProgress, getCareJourneyStatus, getCareJourneyStatusTextColor } from "../JourneysOfCare/PatientCareJourney/PatientCareJourneyHelper";
import { IMlov } from "../../../../Interfaces";
import { Progress } from "antd";

export const getColumns = (params:{
    openContactInWindowOrOpenContactIdDrawer: (id:string) => void;
    removeExpandedContacts: (record:any) => void;
    fetchandUpdateShowMore: (record:any) => void;
    statusText: (code:string) => void;
    setSelectedRecord: (record:any) => void;
    patientJourneyStatusList: IMlov[];
}) => {
    return [
        {
          title: (
            <View
              style={{
                paddingHorizontal: 16,
                paddingVertical: 16,
              }}
            >
              <DisplayText
                size={'smMedium'}
                extraStyles={{color: Colors.Custom.Gray500}}
                textLocalId="members"
              />
            </View>
          ),
          dataIndex: 'contact',
          width: 350,
          render: (value: any, record: any, index: number) => {
            return (
              <View
                style={{
                  paddingHorizontal: 12,
                  paddingVertical: 8,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <View
                  flexDirection={'column'}
                  style={{
                    marginLeft: 10,
                  }}
                >
                  {value ? (
                    <MemberInfoListItem
                      contactData={value as IContact}
                      showEmail
                      showPhoneNumber
                      showRedirectIcon
                      showDateOfBirth={true}
                      onRedirectToProfile={() => {
                        params?.openContactInWindowOrOpenContactIdDrawer?.(value.id);
                      }}
                    />
                  ) : null}
                  {record?.totalAssignedCount > 1 ? (
                    <Text
                      onPress={() => {
                        if(record?.childFetched){
                          params?.removeExpandedContacts(record);
                          return;
                        }
                        params?.fetchandUpdateShowMore(record);
                      }}
                      size={'smBold'}
                      style={{
                        color: Colors.Custom.PurpleColor,
                        fontSize: 14,
                        marginLeft: 65,
                      }}
                    >
                      {record?.childFetched ? `Show less` : `Show ${record?.totalAssignedCount - 1} more`}
                    </Text>
                  ) : null}
                </View>
              </View>
            );
          },
          onCell: (record: any) => ({
            rowSpan: record.childFetched
              ? record?.totalAssignedCount
              : record.isChild
              ? 0
              : 1,
          }),
        },
        {
          title: (
            <View
              style={{
                paddingHorizontal: 16,
                paddingVertical: 16,
              }}
            >
              <DisplayText
                size={'smMedium'}
                extraStyles={{color: Colors.Custom.Gray500}}
                textLocalId="duration"
              />
            </View>
          ),
          width: 220,
          render: (value: any, record: any, index: number) => {
            return (
              <View
                style={{
                  paddingHorizontal: 12,
                  paddingVertical: 8,
                }}
              >
                <Text
                  style={{
                    color: Colors.Custom.Gray900,
                    fontSize: 16,
                    textTransform: 'capitalize',
                  }}
                >
                  {record.duration
                    ? `${
                        record.duration
                      } ${record.durationUnitCode?.toLowerCase()}`
                    : '-'}
                    <Text textTransform={"lowercase"}>{`(s)`}</Text>
                </Text>
                <Text
                  style={{
                    color: Colors.Custom.Gray500,
                    fontSize: 14,
                  }}
                >
                  {`${getDateStrFromFormat(
                    record.startDateTime,
                    'DD MMM yy'
                  )} - ${
                    record.completedDateTime || record.endDateTime
                      ? getDateStrFromFormat(
                          record.completedDateTime || record.endDateTime,
                          'DD MMM yy'
                        )
                      : 'NA'
                  }`}
                </Text>
              </View>
            );
          },
        },
        {
          title: (
            <View
              style={{
                paddingHorizontal: 16,
                paddingVertical: 16,
              }}
            >
              <DisplayText
                size={'smMedium'}
                extraStyles={{color: Colors.Custom.Gray500}}
                textLocalId="journeyProgress"
              />
            </View>
          ),
          render: (value: any, record: any, index: number) => {
            return (
              <View
                style={{
                  paddingHorizontal: 12,
                  paddingVertical: 8,
                  paddingRight: 24,
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                }}
              >
                <View flexDirection={'column'} flex={1}>
                  <Text
                    style={{
                      color: getCareJourneyStatusTextColor(record.statusCode),
                      fontSize: 16,
                    }}
                  >
                    {getCareJourneyStatus(record.statusCode,params?.patientJourneyStatusList)}
                  </Text>
                  <Progress
                    percent={parseFloat(
                      getCareJourneyProgress(record)?.toFixed(2)
                    )}
                    strokeColor={getCareJourneyColor(record.statusCode)}
                    strokeWidth={8}
                    showInfo={false}
                    style={{marginRight: 16, color: Colors.primary['400']}}
                  />
                </View>
                <Text
                  style={{
                    color: Colors.Custom.Gray900,
                    fontSize: 14,
                    marginLeft: 15,
                  }}
                >
                  {getCareJourneyProgress(record) > 100
                    ? 100
                    : parseFloat(
                        getCareJourneyProgress(record)?.toFixed(2)
                      )}{' '}
                  %
                </Text>
              </View>
            );
          },
        },
        {
          title: (
            <View
              style={{
                paddingHorizontal: 16,
                paddingVertical: 16,
              }}
            >
              <DisplayText
                size={'smMedium'}
                extraStyles={{color: Colors.Custom.Gray500}}
                textLocalId="goals"
              />
            </View>
          ),
          width: '180px',
          render: (value: any, record: any, index: number) => {
            return (
              <View
                style={{
                  paddingHorizontal: 12,
                  paddingVertical: 8,
                }}
              >
                <Text
                  style={{
                    color: Colors.Custom.Gray900,
                  }}
                >
                  {params?.statusText(record?.goalStatusCode)}
                </Text>
              </View>
            );
          },
        },
        {
          title: (
            <View
              style={{
                paddingHorizontal: 16,
                paddingVertical: 16,
              }}
            >
              <DisplayText
                size={'smMedium'}
                extraStyles={{color: Colors.Custom.Gray500}}
                textLocalId="adherence"
              />
            </View>
          ),
          width: '140px',
          render: (value: any, record: any, index: number) => {
            return (
              <View
                style={{
                  paddingHorizontal: 12,
                  paddingVertical: 8,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text
                  size={'smBold'}
                  style={{
                    color: Colors.Custom.Gray400,
                    fontSize: 18,
                    marginRight: 20,
                    width: 100,
                  }}
                >
                  {record.overallAdherencePercentage != undefined
                    ? `${parseFloat(
                        record.overallAdherencePercentage?.toFixed(2)
                      )} %`
                    : '0%'}
                </Text>
              </View>
            );
          },
        },
        {
          title: (
            <View
              style={{
                paddingHorizontal: 16,
                paddingVertical: 16,
              }}
            >
              <DisplayText
                size={'smMedium'}
                extraStyles={{color: Colors.Custom.Gray500}}
                textLocalId="actions"
              />
            </View>
          ),
          width: '180px',
          render: (value: any, record: any, index: number) => {
            const isAssignedStatusCode =
              record?.statusCode === JOURNEYS_STATUS_CODE.ASSIGNED;
            if (isAssignedStatusCode) {
              return (
                <Text style={{fontSize: 14, paddingHorizontal: 12}}>NA</Text>
              );
            }
            return (
              <View
                style={{
                  paddingHorizontal: 12,
                  paddingVertical: 8,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Pressable
                  onPress={() => {
                    params?.setSelectedRecord(record);
                  }}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    size={'smBold'}
                    style={{
                      color: Colors.Custom.PurpleColor,
                      fontSize: 14,
                    }}
                  >
                    View Details
                  </Text>
                  <AntDesign
                    size={15}
                    name="right"
                    style={{
                      color: Colors.Custom.PurpleColor,
                      marginTop: 4,
                      marginLeft: 4,
                    }}
                  />
                </Pressable>
              </View>
            );
          },
          onCell: (record: any) => ({
            rowSpan: record.childFetched
              ? record?.totalAssignedCount
              : record.isChild
              ? 0
              : 1,
          }),
        },
      ];
}