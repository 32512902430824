import { HStack, Pressable, Spinner, Text, View } from 'native-base';
import { useEffect, useState } from 'react';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { ADD_NEW_TASK, CHAT_WITH_A_TEAM_MATE, CHECK_SCREENING_GROUPS, CONFIGURE_AN_EMAIL_TEMPLATE, CONFIGURE_A_CARE_JOURNEY, CONFIGURE_PHONE_NUMBER_FOR_SMS_AND_VOICE, CREATE_AN_ONBOARDING_AUTOMATION, CREATE_A_CAMPAIGN, CREATE_A_MEMBER_GROUP, CREATE_A_NEW_FORM_USING_HEALTH_COMPONENT, CREATE_A_TASK_POOL, LoginProvider, LOGIN_TO_MEMBER_PORTAL, RUN_EXISTING_AUTOMATION_ON_A_MEMBER, SCHEDULE_PATIENT, SEND_AN_EXISTING_FORM_TO_A_MEMBER, SETUP_A_LEAD_GENERATION_WIDGET, SETUP_A_PHONE_TREE } from '../../../assets/svg/BusinessSvg';
import CommonService from '../../../services/CommonService/CommonService';
import { Colors } from '../../../styles/Colors';
import { getAccountUUID, getUserUUID } from '../../../utils/commonUtils';
import {
  UsageLevelItem,
  UsageLevelListItemProps,
  UsageLevelListItemState
} from './interfaces';
import { USER_ACTION_CODES } from './UsageLevelConsts';
import { UsageLevelListItemController } from './UsageLevelListItemUtils';
import { styles } from './UsageLevelStyles';

const UsageLevelListItem = (props: UsageLevelListItemProps) => {
  const { actionItem,isAccountLevelAction, onActionPerformed} = props;
  const userId = getUserUUID();
  const tenantId = getAccountUUID();
  const [state, setState] = useState<UsageLevelListItemState>({
    loading: false,
    item: {} as UsageLevelItem,
  });

  const commonService = CommonService.getCommonServiceInstance();
  const integrationService = commonService.integrationService;

  const {showTryOption, bgColor, onMount} = new UsageLevelListItemController({
    state,
    userId,
    actionItem,
    isAccountLevelAction,
    tenantId,
    integrationService,
    onActionPerformed,
    setState,
  });

  useEffect(() => {
    onMount();
  }, []);

  const setIntegrateSuperchargeItem = (): JSX.Element => {
    // if (actionItem.actionCode === USER_ACTION_CODES.HINT) {
    //   return (
    //     <>
    //       <VStack flex={0.85} pl={0}>
    //         <HINT_IMAGE />
    //       </VStack>
    //     </>
    //   );
    // }
    // if (actionItem.actionCode === USER_ACTION_CODES.ELATION) {
    //   return (
    //     <>
    //       <VStack flex={0.85} pl={0}>
    //         <ELATION_IMAGE />
    //       </VStack>
    //     </>
    //   );
    // } 
    //  if (actionItem.actionCode === USER_ACTION_CODES.SPRUCE) {
    //   return (
    //     <>
    //       <VStack flex={0.85} pl={0}>
    //         <SPRUCE_IMAGE />
    //       </VStack>
    //     </>
    //   );
    // }
     //else {
      return (
        <>{defaultItemDesign()}</>
      )
   // }
  }

  const defaultItemDesign = (): JSX.Element => {
    return (
      <>
        <View
          style={{ width: 20, height: 20, marginLeft: 0, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}
          flex={0.1} alignItems={'center'} justifyContent={'center'}>
          {getActionTypeImgSrc(actionItem.actionCode, showTryOption ? Colors.Custom.PurpleColor : Colors.Custom.GrayDisable)}
        </View>
        <View flex={0.75} pl={2}>
          <Text
            color={showTryOption ? 'black' : 'gray.400'}
            style={styles.actionItemText}>
            {actionItem.display}
          </Text>
        </View>
      </>
    )
  }

  const getActionTypeImgSrc = (actionCode: string, color: string): JSX.Element => {
    // add remaining actions here
    switch (actionCode) {
      case USER_ACTION_CODES.INVITE_STAFF_MEMBER:
        return (<LoginProvider color={color}/>);
      case USER_ACTION_CODES.ADD_NEW_PATIENT:
        return  (<LoginProvider color={color}/>);
      case USER_ACTION_CODES.ADD_NEW_PROSPECT:
        return  (<LoginProvider color={color}/>);
  
      case USER_ACTION_CODES.SCHEDULE_PATIENT:
        return (<SCHEDULE_PATIENT color={color}/>);
  
      case USER_ACTION_CODES.ADD_NEW_TASK:
        return (<ADD_NEW_TASK color={color}/>);
  
      case USER_ACTION_CODES.CHAT_WITH_A_TEAM_MATE:
        return (<CHAT_WITH_A_TEAM_MATE color={color}/>);
  
      case USER_ACTION_CODES.SEND_AN_EXISTING_FORM_TO_A_MEMBER:
        return (<SEND_AN_EXISTING_FORM_TO_A_MEMBER color={color}/>);
  
      case USER_ACTION_CODES.CONFIGURE_AN_EMAIL_TEMPLATE:
        return (<CONFIGURE_AN_EMAIL_TEMPLATE color={color}/>);
  
      case USER_ACTION_CODES.CREATE_A_MEMBER_GROUP:
        return (<CREATE_A_MEMBER_GROUP color={color}/>);
  
      case USER_ACTION_CODES.CONFIGURE_PHONE_NUMBER_FOR_SMS_AND_VOICE:
        return (<CONFIGURE_PHONE_NUMBER_FOR_SMS_AND_VOICE color={color}/>);
  
      case USER_ACTION_CODES.CREATE_A_CAMPAIGN:
        return (<CREATE_A_CAMPAIGN color={color}/>);
  
      case USER_ACTION_CODES.CREATE_A_TASK_POOL:
        return (<CREATE_A_TASK_POOL color={color}/>);
  
      case USER_ACTION_CODES.CONFIGURE_A_CARE_JOURNEY:
        return (<CONFIGURE_A_CARE_JOURNEY color={color}/>);
  
      case USER_ACTION_CODES.CREATE_A_NEW_FORM_USING_HEALTH_COMPONENT:
        return (<CREATE_A_NEW_FORM_USING_HEALTH_COMPONENT color={color}/>);
  
      case USER_ACTION_CODES.RUN_EXISTING_AUTOMATION_ON_A_MEMBER:
        return (<RUN_EXISTING_AUTOMATION_ON_A_MEMBER color={color}/>);
  
      case USER_ACTION_CODES.SETUP_A_PHONE_TREE:
        return (<SETUP_A_PHONE_TREE color={color}/>);
  
      case USER_ACTION_CODES.LOGIN_TO_FOLD_PROVIDER_APP:
        return (<LoginProvider color={color}/>);
  
      case USER_ACTION_CODES.CREATE_AN_ONBOARDING_AUTOMATION:
        return (<CREATE_AN_ONBOARDING_AUTOMATION color={color}/>);
  
      case USER_ACTION_CODES.CREATE_AN_EMPLOYER_AND_COUPLE_OF_EMPLOYEES:
        return (<SETUP_A_PHONE_TREE color={color}/>);
      case USER_ACTION_CODES.SETUP_A_LEAD_GENERATION_WIDGET:
        return (<SETUP_A_LEAD_GENERATION_WIDGET color={color}/>);
      case USER_ACTION_CODES.CHECK_SCREENING_GROUPS:
        return (<CHECK_SCREENING_GROUPS color={color}/>);

      case USER_ACTION_CODES.LOGIN_TO_MEMBER_PORTAL:
        return (<LOGIN_TO_MEMBER_PORTAL color={color}/>);
      case USER_ACTION_CODES.HINT:
        return (<LoginProvider color={color}/>);
      case USER_ACTION_CODES.ELATION:
        return (<LoginProvider color={color}/>);
      case USER_ACTION_CODES.SPRUCE:
        return (<LoginProvider color={color}/>);
      default:
       return (<SETUP_A_PHONE_TREE color={color}/>);
    }
  };

  return (
    <Pressable
      flex={1}
      onPress={() => {
        onActionPerformed(actionItem.actionCode);
      }}
    >
      <HStack borderBottomWidth={props.isShowBottomLine ? 1 : 0} style={[styles.itemContainer, { backgroundColor: bgColor }]} paddingX={18} height={80}>
        {setIntegrateSuperchargeItem()}
        <View flex={0.15} alignItems={'flex-end'} justifyItems={'flex-end'}>
          {state.loading ? (
            <Spinner />
          ) : (
            <>
              {showTryOption ? (
                <Text style={styles.tryText}>Try</Text>
              ) : (
                <FeatherIcon name="check-circle" size={20} color={Colors.Custom.GrayDisable} />
              )}
            </>
          )}
        </View>
      </HStack>
    </Pressable>
  );
};

export default UsageLevelListItem;
