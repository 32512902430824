import React, { useState } from 'react';
import { Pressable, Stack, Text } from 'native-base';
import { Colors } from '../styles';
import InternalTaskDrawer from './common/PatientListInternalTask/InternalTaskDrawer';

interface IRenderDiagnosisGapProps {
  numberOfDiagnosisGaps: number;
  selectedRecord: any;
}

const DiagnosisGap= (props: IRenderDiagnosisGapProps) => {
  const [componentState, setComponentState] = useState({
    showDiagnosisGaps: false,
  });

  const handleShowDiagnosisGaps = () => {
    setComponentState((prev) => ({
      ...prev,
      showDiagnosisGaps: true,
    }));
  }

  return (
    <>
      <Stack direction={'column'}>
        <Pressable
          onPress={handleShowDiagnosisGaps}
        >
          <Text
            wordBreak={'break-word'}
            size={'smLight'}
            color={Colors.Custom.Gray500}
            fontSize={14}
            alignSelf={'center'}
          >
            {props?.numberOfDiagnosisGaps}
          </Text>
        </Pressable>
      </Stack>

      {componentState.showDiagnosisGaps && (
        <InternalTaskDrawer
          onClose={() => {
            setComponentState((prev) => ({
              ...prev,
              showDiagnosisGaps: false,
            }));
          }}
          contactId={props?.selectedRecord?.contactData?.uuid}
          shouldOpenAlertsTab={true}
          toShowOnlyDiagnosisGaps={true}
        />
      )}
    </>
  );
};

export default DiagnosisGap;