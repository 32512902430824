import { ColumnsType } from 'antd/lib/table';
import { IHealthMapTemplateTableColumnArgs } from './interfaces';
import { HStack, Pressable, Text, Tooltip } from 'native-base';
import { antStyles, styles } from '../LLMTemplates/llmTemplateStyles';
import { Colors } from '../../../../styles/Colors';
import { RenderCreatedBy, RenderStatusSwitch, RoleView } from '../LLMTemplates/Helper';
import { getDateObjectFromAPIFormatToMyFormat } from '../../../../utils/DateUtils';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import EyeIcon from '../../../../assets/Icons/EyeIcon';
import PenIcon from '../../../../assets/Icons/PenIcon';
import { Divider, Switch } from 'antd';
import TrashBin2Icon from '../../../../assets/Icons/TrashBin2Icon';
import { DATE_FORMATS } from '../../../../constants';
import { updateHealthMapTemplateStatus } from './healthMapTemplateUtils';
import { useState } from 'react';

export const getHealthMapTableColumns = (args: IHealthMapTemplateTableColumnArgs) => {
  const { actionFn, userList, roleList, userUUID, adminAccess } = args;

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'templateName',
      key: 'templateName',
      width: '25%',
      fixed: 'left',
      render: (template: any) => {
        return (
          <Tooltip label={template?.templateName} placement='top'>
            <Text style={[styles.tableContent, { color: Colors.FoldPixel.GRAY400 }]}>
              {template?.templateName} {template?.isDefault ? ' (Default)' : null}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '15%',
      render: (createdBy: any) => {
        return <RenderCreatedBy userUuid={createdBy} />;
      },
    },
    {
      title: 'Access',
      key: 'accessRole',
      width: '15%',
      render: (template: any) => {
        return (
          <RoleView
            template={template}
            userList={userList}
            roleList={roleList}
          />
        );
      },
    },
    {
      title: 'Date Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '15%',
      sorter: (a: any, b: any) =>
        new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
      render: (updatedAt: string) => {
        const date = getDateObjectFromAPIFormatToMyFormat(updatedAt, DATE_FORMATS.MONTH_DATE_YEAR);
        return <Text style={styles.tableContent}>{date}</Text>;
      },
    },
    {
      title: 'Status',
      key: 'isActive',
      width: '10%',
      render: (record: any) => {
        const createdByLoginUser = userUUID === record?.createdBy;
        return (
          <RenderStatusSwitch
            data={record}
            isDisabled={!adminAccess && !createdByLoginUser}
          />
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '20%',
      fixed: 'right',
      render: (template: any) => {
        const createdByLoginUser  = (userUUID === template?.createdBy)
        return (
          <HStack space={2}>
            <Pressable onPress={() => actionFn(COMMON_ACTION_CODES.EDIT, template)}>
              <Tooltip label='Edit'>
                {(template?.isDefault && !adminAccess) ?
                  <EyeIcon /> :
                  <PenIcon />
                }
              </Tooltip>
            </Pressable>
            <Divider type='vertical' style={antStyles.dividerStyle} ></Divider>
            <Pressable
              cursor={template?.isDefault || !createdByLoginUser ? 'not-allowed' : 'pointer'}
              onPress={() => actionFn(COMMON_ACTION_CODES.DELETE, template)}
              isDisabled={template?.isDefault || !createdByLoginUser}
              >
              <Tooltip label='Delete'>
                <TrashBin2Icon
                  color={template?.isDefault || !createdByLoginUser ? Colors.FoldPixel.GRAY150 : undefined}
                />
              </Tooltip>
            </Pressable >
          </HStack>
        );
      },
    },
  ];

  return columns;
};


export const RenderHeathMapStatusSwitch = (props: { data: any , isDisabled: boolean}) => {
  const [localIsActive, setLocalIsActive] = useState(props?.data?.isActive);

  const handleStatusChange = async (checked: boolean, record: any) => {
    try {
      setLocalIsActive(checked);
      await updateHealthMapTemplateStatus(record?.id, checked, record);
    } catch (error) {
    }
  };

  return (
    <>
      <Switch
        disabled={props?.isDisabled || props?.data?.isDefault}
        size='small'
        style={{
          backgroundColor: localIsActive ? Colors.primary[300] : Colors.muted[500],
        }}
        checked={localIsActive}
        onChange={(value: boolean) => handleStatusChange(value, props?.data)}
      />
    </>
  );
};
