import { Icon, View } from 'native-base';
import { useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useNavigate } from 'react-router-dom';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import { TELEPHONY_VIEW } from '../../../constants/TelephonyConst';
import { FlowType } from '../../../context/WorkflowContext';
import { Colors } from '../../../styles';
import { commonBodyContentContainer } from '../../../styles/CommonBodyContentStyle';
import CustomTabsView from '../../common/CustomTabsView/CustomTabsView';
import { ITabsList } from '../../common/CustomTabsView/interfaces';
import PlusIcon from '../../common/Svg/PlusSvg';
import {
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES
} from '../../common/TableTopBar';
import TitleSubtitleView from '../../common/TitleSubtitleView/TitleSubtitleView';
import { SMS_INBOX_ACTION_CODES } from '../TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import { getWorkflowListTopBarButtons } from '../Workflow/Workflow/WorkflowTableView/Helper/TopBarButtons';
import WorkflowView from '../Workflow/Workflow/WorkflowView';
import CloudTelephonyView from './CloudTelephonyView/CloudTelephonyView';
import { ICloudTelephonyState } from './interfaces';
import PurchaseNumberView from './PurchaseNumberView/PurchaseNumberView';
import { styles } from './styles';

const CloudTelephony = () => {
  const [cloudTelephonyState, setCloudTelephonyState] =
    useState<ICloudTelephonyState>({
      selectedTab: {} as any,
      selectedDrawerCode: '',
      searchString: '',
      selectedData: {} as any
    });
  const navigate = useNavigate();
  const flowType = FlowType.ivr || '';
  const getSearch = (actionCode: string, actionData: any) => {
    setCloudTelephonyState((prev) => {
      return {
        ...prev,
        searchString: actionData.searchString,
      };
    });
  };

  const tagsTabList = (): ITabsList[] => {
    const array: ITabsList[] = [
      {
        key: 'automation',
        path: 'automation',
        title: 'Phone Trees',
        tabContainerElem: () => {
          return (
            <View
              bgColor={'white'}
            >
              <WorkflowView
                searchText={cloudTelephonyState.searchString}
                showTableTopBar={false}
                flowType={FlowType.ivr}
              />
            </View>
          );
        },
      },
      {
        key: 'number',
        title: 'Number',
        path: 'number',
        tabContainerElem: () => {
          return (
            <CloudTelephonyView
              selectedCode={cloudTelephonyState.selectedDrawerCode}
              selectedData={cloudTelephonyState.selectedData}
              onActionPerformed={(actionCode: string, actionData?: any) => {
                setCloudTelephonyState((prev) => {
                  return {
                    ...prev,
                    selectedDrawerCode: actionCode,
                    selectedData: actionData
                  };
                });
              }}
            />
          );
        },
      },
    ];
    return array;
  };

  const onPurchaseNumber = (): any => {
    setCloudTelephonyState((prev) => {
      return {
        ...prev,
        selectedDrawerCode: TELEPHONY_VIEW.PURCHASE_NEW_NUMBER,
      };
    });
  };

  const NumberTopBar: JSX.Element = (
    <>
      <TableTopBar
        title={``}
        alignSearchBarFlexEnd={true}
        buttonList={[
          {
            btnText: 'purchaseNewNumber',
            backgroundColor: Colors.secondary['100'],
            borderColor: Colors.secondary['200'],
            textColor: '',
            variant: 'PRIMARY',
            size: 'sm',
            leftIcon: (
             <PlusIcon />
            ),
            btnClick: onPurchaseNumber,
          },
        ]}
      />
    </>
  );

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        handleSearchChange(actionData);
        break;
    }
  };

  const handleSearchChange = (actionData: any) => {
    setCloudTelephonyState((prev) => {
      return {
        ...prev,
        searchString: actionData.searchString,
      };
    });
  };
  const workflowUrl = `create?currentTag=TRIGGER&flowType=${flowType}`;
  const ivrTopBar: JSX.Element = (
    <>
      <TableTopBar
        alignSearchBarFlexEnd={true}
        onActionPerformed={(actionCode: string, actionData: any) => {
          onTableTopBarActionPerformed(actionCode, actionData);
        }}
        {...getWorkflowListTopBarButtons(
          [
            () => {
              navigate(workflowUrl, {});
            },
          ],
          workflowUrl,
          'New IVR'
        )}
      />
    </>
  );

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele = <></>;
    if (selectedTab.key === 'number') {
      ele = NumberTopBar;
    } else if (selectedTab.key === 'automation') {
      ele = ivrTopBar;
    }
    return ele;
  };

  const onTabClick = (tab: ITabsList) => {
    setCloudTelephonyState((prev) => {
      return {
        ...prev,
        selectedTab: tab,
      };
    });
  };

  const onSmsInboxActionPerformed = (actionCode: string) => {
    switch (actionCode) {
      case SMS_INBOX_ACTION_CODES.DRAWER_CLOSE:
      case COMMON_ACTION_CODES.CANCEL:
      case COMMON_ACTION_CODES.COMPLETED:
        setCloudTelephonyState((prev: any) => {
          return {
            ...prev,
            selectedDrawerCode: '',
          };
        });
        break;
    }
  };

  return (
    <View>
      <View style={styles.titleWrapper}>
        <TitleSubtitleView titleLabelId="virtualCallCenter" />
      </View>
      <View
        style={[
          styles.tabsWrapper,
          commonBodyContentContainer.pageContentContainer,
        ]}
      >
        <CustomTabsView
          basePath={'/admin/virtualCallCenter'}
          tabsList={tagsTabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
            borderBottomWidth: 1,
            borderColor: Colors.Custom.Gray200,
          }}
          canNavigate={true}
          rightView={() => {
            return getSelectedTopBar(cloudTelephonyState.selectedTab);
          }}
          selectedTab={cloudTelephonyState.selectedTab}
          onTabClick={(tabItem) => {
            onTabClick(tabItem);
          }}
        />
      </View>
      {cloudTelephonyState.selectedDrawerCode ===
      TELEPHONY_VIEW.PURCHASE_NEW_NUMBER ? (
        <PurchaseNumberView
          onFormCompleteAction={(actionCode: string) => {
            setTimeout(() => {
              onSmsInboxActionPerformed(actionCode);
            }, 500);
          }}
          userList={[]}
        />
      ) : null}
    </View>
  );
};

export default CloudTelephony;
