import {createContext, Dispatch, useContext} from "react";
import {IContactCareProgram, IContactCareProgramState, IContactCareProgramStep} from '../interface';
import {ContactCareProgramReducer, IContactCareProgramReducerAction, ContactCareProgramReducerAction} from './reducer';
import {CareProgramStatusAction} from "./hooks/useCareProgramStatusAction";
import {ApolloQueryResult, OperationVariables} from "@apollo/client";

export const ContactCareProgramContext = createContext<{
  dispatch: Dispatch<IContactCareProgramReducerAction> | undefined;
  state: IContactCareProgramState;
  onUpdateCareProgramStatus: (action: CareProgramStatusAction) => Promise<void>;
  refetchMasterData: ((variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>) | undefined
}>({
  dispatch: undefined,
  state: {} as IContactCareProgramState,
  onUpdateCareProgramStatus: async () => {},
  refetchMasterData: undefined,
});

export const useContactCareProgramContext = () => {
  return useContext(ContactCareProgramContext);
};

export const ContactCareProgramProvider = ContactCareProgramContext.Provider;

export default {
  ContactCareProgramContext,
  useContactCareProgramContext,
  ContactCareProgramProvider,
};
