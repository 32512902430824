import React from 'react';
import {Colors} from '../../../../styles/Colors';
import {ICommonSvgProps} from '../interfaces';

const WebWidgetsSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';

  return (
    <svg
      style={{width: 'inherit', height: 'inherit'}}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.48417 0.625H1.45833C0.998096 0.625 0.625 0.998096 0.625 1.45833V7.48417C0.625 7.9444 0.998096 8.3175 1.45833 8.3175H7.48417C7.9444 8.3175 8.3175 7.9444 8.3175 7.48417V1.45833C8.3175 0.998096 7.9444 0.625 7.48417 0.625Z"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5417 0.625H12.5158C12.0556 0.625 11.6825 0.998096 11.6825 1.45833V7.48417C11.6825 7.9444 12.0556 8.3175 12.5158 8.3175H18.5417C19.0019 8.3175 19.375 7.9444 19.375 7.48417V1.45833C19.375 0.998096 19.0019 0.625 18.5417 0.625Z"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.48417 11.6826H1.45833C0.998096 11.6826 0.625 12.0557 0.625 12.516V18.5418C0.625 19.002 0.998096 19.3751 1.45833 19.3751H7.48417C7.9444 19.3751 8.3175 19.002 8.3175 18.5418V12.516C8.3175 12.0557 7.9444 11.6826 7.48417 11.6826Z"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5292 12.6445V18.4137"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.4133 15.5293H12.6442"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default React.memo(WebWidgetsSvg);
