import {View, Text, HStack, VStack, Pressable} from 'native-base';
import Icon from 'react-native-vector-icons/AntDesign';
import {styles} from '../CampaignStyles';
import {Colors} from '../../../../../styles';
import {ICampaignTagListProps, ICampaignTagsProps} from '../interfaces';

export const TagFilterList = (props: ICampaignTagListProps) => {
  return (
    <View style={styles.btPadding}>
      {props.tagsListData.map((tagItem: ICampaignTagsProps, index: number) => {
        return (
          <Pressable
            onPress={() => {
              props.getCurrentTag(tagItem.tagName);
            }}
            _hover={{bg: Colors.Custom.HoverBgColor}}
            _focus={{
              borderRightColor: Colors.Custom.PrimaryColor,
              borderRightWidth: 3,
              backgroundColor: Colors.Custom.HoverBgColor,
            }}
            key={index}
          >
            <HStack style={[styles.tagListItem]} flex={1}>
              <VStack flex={0.1} style={styles.justifyCenter}>
                <Icon name="tago" color={tagItem.tagColor} />
              </VStack>
              <VStack flex={0.85}>
                <Text>{tagItem.tagName}</Text>
              </VStack>
              <VStack flex={0.05}>
                <Text>{tagItem.tagCount}</Text>
              </VStack>
            </HStack>
          </Pressable>
        );
      })}
    </View>
  );
};
