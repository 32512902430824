import React from 'react';
import {useOutreach} from './hooks/useOutreach';
import OutreachStepNoteView from '../../../../../common/CareProgramStepOutreach/components/OutreachStepNoteView';
import {IStepOutreachLog} from '../../../../../../services/ContactCareProgram/interface';

const OutreachView: React.FC = () => {
  const {enabledContactModes} = useOutreach();
  return (
    <div className="content-view">
      <OutreachStepNoteView
        onSave={() => {}}
        onCancel={() => {}}
        log={{} as IStepOutreachLog}
        showNotSaved={false}
        enabledContactModes={enabledContactModes}
        stepSaveLoading={false}
      />
    </div>
  );
};

export default OutreachView;
