import {Dimensions, StyleSheet} from 'react-native';

const {height, width} = Dimensions.get('screen');

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  sidebar: {
    flex: 1,
  },
  content: {
    flex: 4,
  },
  menuItemStyle: {
    backgroundColor: '#F9F5FF',
    margin: 16,
    height: 40,
    padding: '8px, 12px, 8px, 12px',
  },
  journeyIconStyle: {
    fontSize: 20,
    marginRight: 14,
    color: '#6941C6',
  },
  journeyTextStyle: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '500',
    fontStyle: 'normal',
    color: '#6941C6',
  },
  loaderContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: height,
    width: width,
  },
});
