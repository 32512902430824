import {useMutation} from '@apollo/client';
import {Editor} from '@toast-ui/react-editor';
import {Drawer} from 'antd';
import {Content} from 'antd/lib/layout/layout';
// import {} from 'antd';
import {
  Center,
  FormControl,
  Input,
  Select,
  Skeleton,
  Text,
  TextArea,
  View,
  VStack,
} from 'native-base';
import React, {useContext, useState} from 'react';
import {Dimensions, StyleSheet} from 'react-native';
import {BUTTON_TYPE, RESPONSE_CATEGORY_CODE} from '../../../../../constants';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {ILoginUserData} from '../../../../../Interfaces/CommonInterfaces';
import ResponseQueries from '../../../../../services/Responses/ResponseQueries';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {IAddResponseProps, TemplateData} from '../interfaces';

export const AddResponse = (props: IAddResponseProps) => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const editorRef: any = React.createRef();

  const [templateData, setTemplateData] = useState<TemplateData>({
    shortCode: '',
    content: '',
    categoryId: props.categoryList?.length === 1 ? props.categoryList[0].id : '',
    categoryCode: '',
    modalVisible: true,
  });

  const [createResponse] = useMutation(ResponseQueries.CreateResponse);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const validate = () => {
    setErrors({});
    if (templateData.shortCode == '') {
      setErrors({shortCodeError: 'Please fill field'});
      return false;
    }
    if (templateData.content == '') {
      setErrors({contentError: 'Please fill field'});
      return false;
    }
    if (templateData.categoryId == '') {
      setErrors({categoryError: 'Please Select Category Code'});
      return false;
    }
    return true;
  };

  const handleAddResponse = async () => {
    setLoading(true);

    const responseObj = await createResponse({
      variables: {
        id: templateData.categoryId,
        shortCode: templateData.shortCode,
        content: templateData.content,
        accountId: userData.account_id,
      },
    });
    if (responseObj.data.createCannedResponse.id) {
      setTemplateData((prev) => {
        return {
          ...prev,
          modalVisible: false,
          shortCode: '',
          content: '',
        };
      });
      props.handleCloseAfterUpdateModal();
    } else {
      setLoading(false);
    }
  };
  const {width} = Dimensions.get('window');
  const finalWidth = 650;
  return (
    <Center>
      <Drawer
        width={finalWidth}
        visible={templateData.modalVisible}
        onClose={() => {
          setLoading(false);
          setTemplateData((prev) => {
            return {
              ...prev,
              modalVisible: false,
              shortCode: '',
              content: '',
            };
          });
          props.handleCloseModal();
        }}
        closable={false}
        title={ <ModalActionTitle
          title={'addResponse'}
          titleColor={''}
          titleSize={24}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                props.handleCloseModal();
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'save',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                if (validate()) {
                  handleAddResponse();
                }
              },
            },
          ]}
        />}
      >
        {loading ? (
          <VStack height={'100%'}>
            <Skeleton padding={10} height="60%" />
            <Skeleton.Text padding={10} />
          </VStack>
        ) : (
            <View mx={0}>
              <FormControl>
                <FormControl.Label>
                  <DisplayText textLocalId="shortCode" size={'mdNormal'} />
                </FormControl.Label>
                <Input
                _focus={{borderColor: '#D0D5DD'}}
                  size={'mdNormal'}
                  value={templateData?.shortCode}
                  placeholder="Enter Short Code"
                  onChangeText={(value) =>
                    setTemplateData({...templateData, shortCode: value})
                  }
                />
                <Text size={'smLight'} color={'error.500'}>
                  {errors.shortCodeError}
                </Text>
              </FormControl>
              <FormControl style={styles.formControlMargin}>
                <FormControl.Label>
                  <DisplayText textLocalId="category" size={'mdNormal'} />
                </FormControl.Label>
                <Select
                  selectedValue={templateData.categoryId}
                  placeholder="Select Category"
                  onValueChange={(itemValue) => {
                    const selectedCategory = props.categoryList?.find(
                      (item) => {
                        return item.id == itemValue;
                      }
                    );
                    setTemplateData((prev: any) => {
                      return {
                        ...prev,
                        categoryId: itemValue,
                        categoryCode: selectedCategory?.code,
                      };
                    });
                  }}
                >
                  {props.categoryList?.map((item) => {
                    return (
                      <Select.Item
                        key={item.id}
                        label={item.value}
                        value={item.id}
                      />
                    );
                  })}
                </Select>
                <Text size={'smLight'} color={'error.500'}>
                  {errors.categoryError}
                </Text>
              </FormControl>
              <FormControl marginTop={6}>
                <FormControl.Label>
                  <DisplayText textLocalId="content" size={'mdNormal'} />
                </FormControl.Label>
                <Content
                  style={{height: '100%'}}
                  className="messagewindow-editors"
                >
                  {templateData.categoryCode == RESPONSE_CATEGORY_CODE.CHAT ||
                  templateData.categoryCode == RESPONSE_CATEGORY_CODE.EMAIL ? (
                    <Editor
                      placeholder=""
                      initialValue={''}
                      height="420px"
                      initialEditType="wysiwyg"
                      useCommandShortcut={true}
                      previewHighlight={false}
                      ref={editorRef}
                      onKeyup={(editorType: any, event: KeyboardEvent) => {
                        const keyCode = event.which || event.keyCode;

                        if (keyCode === 13 && !event.shiftKey) {
                          event.preventDefault();
                          event.stopPropagation();
                          // handleAddResponse();
                        }
                      }}
                      onChange={() => {
                        if (editorRef && editorRef?.current) {
                          const inst = editorRef.current.getInstance();
                          const plainString = inst.getMarkdown();
                          setTemplateData((prev) => {
                            return {
                              ...prev,
                              content: plainString,
                            };
                          });
                        }
                      }}
                    />
                  ) : (
                    <View paddingTop={2}>
                      <TextArea
                        defaultValue=""
                        variant={'outline'}
                        value={templateData.content}
                        style={styles.textArea}
                        height={65}
                        maxH={100}
                        width={'100%'}
                        autoComplete="off"
                        multiline={true}
                        ref={editorRef}
                        onKeyPress={(event: any) => {
                          const keyCode = event.which || event.keyCode;
                          if (keyCode === 13 && !event.shiftKey) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        onChangeText={(plainString) => {
                          setTemplateData({
                            ...templateData,
                            content: plainString,
                          });
                        }}
                      />
                    </View>
                  )}
                </Content>
                <Text size={'smLight'} color={'error.500'}>
                  {errors.contentError}
                </Text>
              </FormControl>
            </View>
        )}
      </Drawer>
    </Center>
  );
};

const styles = StyleSheet.create({
  formControlMargin: {
    marginVertical: 5,
  },
  contentHeight: {
    height: '100%',
  },
  textArea: {
    color: Colors.Custom.TextColor,
    fontSize: 18,
  },
});