import {
  VStack,
  Text,
  Button,
  HStack,
  Pressable,
} from 'native-base';
import {styles} from '../../IntegrationsStyles';
import {INTEGRATION_CONSTANTS} from '../../constants';
import {IIntegrationsScreenFourProps} from '../../interfaces';
import { useState} from 'react';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../../../styles';
import Icon from 'react-native-vector-icons/AntDesign';
import  {AgentBotDropDown} from '../../Helper/AgentBotDropDown'

export const IntegrationScreenFour = (
  props: IIntegrationsScreenFourProps
) => {
  const [selectedAgentBotId, setSelectedAgentBotId] = useState<any>();

  const onSelectAgentBot = (agentBotId: number) => {
    setSelectedAgentBotId(agentBotId);
  };

  return   <VStack space={4} style={styles.leftPad}>
      <HStack style={styles.titleBar}>
        <HStack flex={1} justifyContent={'center'} alignItems={'center'}>
          <VStack flex={0.05}>
            <Pressable onPress={() => props.onBackClick(INTEGRATION_CONSTANTS.SCREEN_THREE)}>
              <Icon name='left' size={25} color={Colors.primary[400]} />
            </Pressable>
          </VStack>
          <VStack flex={0.9}>
            <DisplayText
              textType='Heading'
              textLocalId='Agent Bot'
              size={'lgNormal'}
            />
            <HStack flex={1} mt={1}>
              <Text color={Colors.Custom.DataKeyColor} size={'smLight'}>
                {INTEGRATION_CONSTANTS.AGENT_BOT_DESCRIPTION}
              </Text>
            </HStack>
          </VStack>
        </HStack>
      </HStack>
      <HStack flex={1} style={styles.agents}>
        <VStack flex={0.15}></VStack>
        <VStack space={5}>
          <AgentBotDropDown
            agentBotId={selectedAgentBotId}
            placeholder='Pick agent bot for the inbox'
            onChange={onSelectAgentBot}
          ></AgentBotDropDown>
          <Button
            style={styles.agentBotButton}
            variant='solid'
            colorScheme='primary'
            _text={{color: 'white'}}
            onPress={() =>
              props.handleScreenFourChange(
                INTEGRATION_CONSTANTS.SCREEN_FIVE,
                selectedAgentBotId
              )
            }
          >
            { selectedAgentBotId ? INTEGRATION_CONSTANTS.ADD_AGENT_BOT : 'Next'}
          </Button>
        </VStack>
      </HStack>
    </VStack>
};
