import { useLazyQuery } from '@apollo/client';
import { debounce } from 'lodash';
import {
  Badge,
  Divider,
  ScrollView,
  Spinner,
  useToast
} from 'native-base';
import { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Pressable, View } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { MLOV_CATEGORY } from '../../constants';
import { CONFIG_CODES } from '../../constants/AccountConfigConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../constants/Configs';
import { TASK_ALERT_CATEGORY_CODES } from '../../constants/MlovConst';
import { CommonDataContext } from '../../context/CommonDataContext';
import { FormsQueries, LeadQueries } from '../../services';
import { getCurrentPatientDeceasedData } from '../../services/CommonService/AidBoxService';
import { GET_AGGREGATE_TASK_COUNT } from '../../services/Task/TaskQueries';
import { Colors } from '../../styles';
import { getCarePlanProps, getUserUUID, isEnableCareProgram, showAlertsInSideCar, showHomeMonitoringInSideCar, showSummaryInSideCar } from '../../utils/commonUtils';
import { showToast, ToastType } from '../../utils/commonViewUtils';
import { isAccountConfigEnabled } from '../../utils/configUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory
} from '../../utils/mlovUtils';
import {ClinicalSectionFilterTabs} from '../PatientOmniView/Sections/ClinicalSection/ClinicalSectionFilterTabs/ClinicalSectionFilterTabs';
import {getFormDataFromLeadData} from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {formatFormsResponse} from '../RightSideContainer/Forms/FHFormio/CustomComponents/ClinicalAssessment/ClinicalAssessmentHelper';
import {IFormResponse} from '../RightSideContainer/Forms/FHFormio/CustomComponents/ClinicalAssessment/interfaces';
import {IContact} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import Stack from '../common/LayoutComponents/Stack';
import SearchBar from '../common/SearchBar/SearchBar';
import SearchIconSvg from '../common/Svg/SearchMessageAllSvg/SearchIconSvg';
import FilterIconSvgForAlerts from '../common/Svg/SideCarSvg/FilterIconSvgForAlerts';
import AlertsView from './AlertsView';
import CareGapDetailView from './CareGapDetailView';
import InsightsCareBoardView from './components/InsightsCareBoardView';
import InsightsCreateReferral from './components/InsightsCreateReferral';
import InsightsReferralOrdersView from './components/InsightsReferralOrdersView';
import InsightsSummaryView from './components/InsightsSummaryView';
import { ASSESSMENT_FORM_CATEGORY, SIDE_BAR_CARE_MENU_CODES } from './constants';
import { ITaskAlert } from './SideBarCustomHooks/useFetchAlerts';
import SidecarAssignCareJourney from './SidecarAssignCareJourney';
import { SidecarPatientInfoBanner } from './SidecarPatientInfoBanner';
import { IDiagnosisGap } from './interfaces';
import DiagnosisGapDetailView from './DiagnosisGapDetailView';

import CarePlan from '../PersonOmniView/MiddleContainer/CarePlan/CarePlan';
import { ICarePlanProps } from '../PersonOmniView/MiddleContainer/CarePlan/interfaces';
import SideCarPatientComms from './SideCarPatientComms';
import InsightsTasksView from './components/InsightsTasksView';
import { TaskPanelType } from '../TaskCard/TaskEnum';
import InsightsPatientNotesView from './components/InsightsPatientNotesView';
interface ISidecarDrawerProps {
  onClose: () => void;
  isInsightsDrawerVisible?: boolean;
  onActionClick?: (action: string) => void;
  contactId: string;
  selectedAction: string;
  isFoldView?: boolean;
}

export interface ISidecarInsightsDrawerState {
  forms: IFormResponse[];
  showFiltersTabInAlerts: boolean;
  alertsFilterCount: number;
  alertsCount: number;
  showSearchBar: boolean;
  searchText: string;
  contactData?: IContact;
  formattedContactData?: IContact;
  isLoading?: boolean;
  selectedTab: string;
  showAssignCareJourney: boolean;
  showReferralOrders: boolean;
  showCreateOrEditReferral: boolean;
  referralData: any;
  selecetedCareGapDetail?:  ITaskAlert;
  taskPanelType: TaskPanelType
  selectedDiagnosisGapDetail?: IDiagnosisGap;
}

enum IInsightsTabs {
  SUMMARY = 'summary',
  DASHBOARD = 'dashboard',
  ALERTS = 'alerts',
  REFERRAL = 'referral',
  REFERRALADD = 'referralAdd',
  COMMS = 'comms',
  TASKS = 'tasks',
  PATIENT_NOTES = 'patientNotes'
}

export const SidecarInsightsDrawer = (props: ISidecarDrawerProps) => {
  const {contactId} = props;
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const isCareProgramEnabled = isEnableCareProgram(mlovData.userSettings);
  const isEFaxMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION);
  const isReferralEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_REFERRAL_ENABLED)
  const showSummary = showSummaryInSideCar();
  const showHomeMonitoring = showHomeMonitoringInSideCar();
  const showAlerts = showAlertsInSideCar();
  const toast = useToast();
  const userUuid = getUserUUID();
  const headerContainerRef: any = useRef();
  const [isNavigatingToCarePlan, setIsNavigatingToCarePlan] = useState(false);

  const getDefaultSelectedTab = () => {
    if(showAlerts) {
      return IInsightsTabs.ALERTS;
    }
    if(showSummary) {
      return IInsightsTabs.SUMMARY;
    }
    if(showHomeMonitoring) {
      return IInsightsTabs.DASHBOARD;
    }
    return IInsightsTabs.TASKS;
  }

  const [componentState, setComponentState] =
    useState<ISidecarInsightsDrawerState>({
      forms: [],
      isLoading: true,
      selectedTab: getDefaultSelectedTab(),
      showFiltersTabInAlerts: false,
      alertsFilterCount: 0,
      alertsCount: 0,
      showSearchBar: false,
      searchText: '',
      showAssignCareJourney: false,
      showCreateOrEditReferral: false,
      showReferralOrders: false,
      referralData: {},
      selecetedCareGapDetail: {} as ITaskAlert,
      taskPanelType: TaskPanelType.INTERNAL,
      selectedDiagnosisGapDetail: {} as IDiagnosisGap,
    });

  const carePlanProps = getCarePlanProps(componentState?.formattedContactData, componentState?.contactData, setIsNavigatingToCarePlan, props?.isFoldView);

  const taskAlertDisplayCategoryMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_ALERT_DISPLAY_CATEGORIES
    ) || [];
  const [GetContact] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',
  });

  const [getPatientForms] = useLazyQuery(
    FormsQueries.GET_FORM_RESPONSES_SCORE_BY_FORM_CATEGORY,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const [getAlertsCount, {loading: alertsCountLoading}] = useLazyQuery(
    GET_AGGREGATE_TASK_COUNT,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      variables: {
        params: {
          assigneeIds: [userUuid],
          includeOnlyAlerts: true,
          contactIds: [componentState?.contactData?.uuid],
          taskAlertDisplayCategoryIds: getMlovIdFromCode(
            taskAlertDisplayCategoryMlovs,
            TASK_ALERT_CATEGORY_CODES.PENDING
          ),
        },
      },
      onCompleted: (response) => {
        setComponentState((prev) => {
          return {
            ...prev,
            alertsCount: response?.getTasks?.aggregate?.total || 0,
          };
        });
      },
    }
  );

  useEffect(() => {
    getContactData(contactId);
  }, [componentState?.showReferralOrders, props.contactId]);

  const getContactData = async (contactId: string) => {
    if (!contactId) {
      return;
    }

    setComponentState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });

    try {
      const response = await GetContact({
        variables: {
          id: contactId,
        },
      });

      if (response?.data?.contact) {
        const formattedContactaData = getFormDataFromLeadData(
          response?.data?.contact,
          mlovData
        );
        setComponentState((prev) => {
          return {
            ...prev,
            contactData: response?.data?.contact,
            contactType: response?.data?.contact?.contactType?.contactType,
            formattedContactData: formattedContactaData,
            isLoading: false,
          };
        });
        getAlertsCount();
        getCurrentPatientDeceasedData(response?.data?.contact);
        getPatientFormsData(response?.data?.contact?.uuid);
      }
    } catch (error) {
      setComponentState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
  };

  const handleCreateCarePlanClick = () => {
    setIsNavigatingToCarePlan(true);
  }

  const getPatientFormsData = async (contactId: string) => {
    try {
      const response = await getPatientForms({
        variables: {
          contactId: contactId,
          category: ASSESSMENT_FORM_CATEGORY,
        },
      });
      const forms = formatFormsResponse(response?.data?.forms);
      setComponentState((prev) => {
        return {
          ...prev,
          forms,
        };
      });
    } catch (error) {}
  };

  const getInsightTabs = () => {
    const items: {
      code: string;
      label: string;
      element: JSX.Element;
      tabContainsCount?: boolean;
      tabCountLoading?: boolean;
      tabCount?: number;
      countTooltip?: string;
    }[] = [];
    if (showAlerts) {
      items.push({
        code: IInsightsTabs.ALERTS,
        label: intl.formatMessage({id: 'alerts'}),
        tabContainsCount: true,
        tabCountLoading: alertsCountLoading,
        tabCount: componentState?.alertsCount,
        countTooltip: `${componentState?.alertsCount} pending alerts`,
        element: (
        <View style={{paddingHorizontal: 12, paddingVertical: 12}}>
          <AlertsView
            openCareGapDetail={(alert)=> {
              setComponentState(prev=>{
                return {
                  ...prev,
                  selecetedCareGapDetail: alert
                }
              })
            }}
            openDiagnosisGapDetail={(diagnosisGap)=> {
              setComponentState(prev=>{
                return {
                  ...prev,
                  selectedDiagnosisGapDetail: diagnosisGap
                }
              })
            }}
            contactId={componentState?.contactData?.uuid}
            showFiltersTabInAlerts={componentState?.showFiltersTabInAlerts}
            onFilterApplied={(filterCount: number, isResetFilter?: boolean) => {
              setComponentState((prev) => ({
                ...prev,
                alertsFilterCount: filterCount,
                ...(isResetFilter && {showFiltersTabInAlerts: false}),
              }));
            }}
            searchText={componentState.searchText}
            formattedContactData={componentState.formattedContactData}
            contactData={componentState?.contactData}
            isCompactView={props.isFoldView}
            onPendingCountChange={(count: number) => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  alertsCount: count,
                };
              });
            }}
          />
        </View>
        ),
      });
    }
    if (showSummary) {
      items.push({
        code: IInsightsTabs.SUMMARY,
        label: intl.formatMessage({id: 'summary'}),
        element: (
          <InsightsSummaryView
            forms={componentState.forms}
            contactData={componentState.contactData}
            formattedContactData={componentState.formattedContactData}
            assignJourneyOptionClicked={() => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  showAssignCareJourney: true,
                };
              });
            }}
          />
        ),
      });
    }
    if (showHomeMonitoring && !props.isFoldView) {
      items.push({
        code: IInsightsTabs.DASHBOARD,
        label: intl.formatMessage({id: 'dashboard'}),
        element: (
          <InsightsCareBoardView
            contactData={componentState.contactData}
            formattedContactData={componentState.formattedContactData}
          />
        ),
      });
    }

    if (props.isFoldView) {
      items.push({
        code: IInsightsTabs.COMMS,
        label: intl.formatMessage({id: 'comms'}),
        element: (
          <SideCarPatientComms contactData={componentState.contactData} />
        ),
      });
    }

    if (isCareProgramEnabled && props.isFoldView) {
      items.push({
        code: IInsightsTabs.PATIENT_NOTES,
        label: intl.formatMessage({id: 'patientNotes'}),
        element: (
          <InsightsPatientNotesView
            contactData={componentState.contactData}
            formattedContactData={componentState.formattedContactData}
            onTaskPanelChanged={(taskPanelType)=> {
              setComponentState((prev) => {
                return {
                  ...prev,
                  taskPanelType: taskPanelType,
                };
              });
            }}
          />
        ),
      })
    }

    if (props.isFoldView) {
      items.push({
        code: IInsightsTabs.TASKS,
        label: intl.formatMessage({id: 'tasks'}),
        element: (
          <InsightsTasksView
            contactData={componentState.contactData}
            formattedContactData={componentState.formattedContactData}
            onTaskPanelChanged={(taskPanelType)=> {
              setComponentState((prev) => {
                return {
                  ...prev,
                  taskPanelType: taskPanelType,
                };
              });
            }}
          />
        ),
      });
    }

    if (isEFaxMessagingEnabled && isReferralEnabled) {
      items.push({
        code: IInsightsTabs.REFERRAL,
        label: intl.formatMessage({id: 'referral'}),
        element: (
          <InsightsReferralOrdersView
          formattedContactData={componentState?.formattedContactData}
          contactData={componentState?.contactData}
          createReferralClicked={(val:any) => {
            setComponentState((prev) => {
              return {
                ...prev,
                showCreateOrEditReferral: true,
                referralData: val
              };
            });
          }}
          onBack={() => {
            setComponentState((prev) => {
              return {
                ...prev,
                selectedTab: IInsightsTabs.REFERRAL,
                showReferralOrders: false,
              };
            });
          }}
          />
        ),
      });
    }

    return items;
  };
  const careGapDetailView = () => {
    return (
      <CareGapDetailView
        contactUuid={componentState.contactData?.uuid || componentState.formattedContactData?.uuid || ''}
        onBackClick={() => {
          setComponentState((prev) => {
            return {
              ...prev,
              selecetedCareGapDetail: {} as ITaskAlert,
            };
          });
        }}
        careGapDetail={componentState.selecetedCareGapDetail || {} as ITaskAlert}
      />
    );
  };

  const diagnosisGapDetailView = () => {
    return (
      <DiagnosisGapDetailView
        onClose={() => {
          setComponentState((prev) => {
            return {
              ...prev,
              selectedDiagnosisGapDetail: {} as IDiagnosisGap,
            };
          });
        }}
        contactData={componentState.contactData}
        diagnosisGapDetail={componentState.selectedDiagnosisGapDetail || {} as IDiagnosisGap}
      />
    )
  }

  const selectedViewElement = () =>
    getInsightTabs().find((item) => item.code === componentState.selectedTab)
      ?.element;

  const showFilterIcon =
    componentState?.selectedTab === SIDE_BAR_CARE_MENU_CODES.ALERTS;
  const showSearchIcon =
    componentState?.selectedTab === SIDE_BAR_CARE_MENU_CODES.ALERTS;

  const getBottomPadding = () => {
    if(componentState.selectedTab === IInsightsTabs.COMMS) {
      return 0;
    }
    return 20;
  }

  return (
    <>
      {componentState?.isLoading ? (
        <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
          <Spinner size={'lg'} />
        </View>
      ) : (
        <Stack direction="column" style={{flex: 1}}>
          <Stack
            direction="row"
            style={{
              justifyContent: 'space-between',
              paddingLeft: 16,
              paddingTop: 16,
            }}
          >
            <Stack
              direction="row"
              style={{
                width: '40%',
                flex: 1,
                paddingBottom: 16,
              }}
            >
              {componentState?.contactData && (
                <View ref={headerContainerRef} style={{flex: 1}}>
                  <SidecarPatientInfoBanner
                    handleCreateCarePlanClick={handleCreateCarePlanClick}
                    contactData={componentState?.contactData}
                    headerContainerRef={headerContainerRef}
                    showAssignJourney={true}
                    assignJourneyOptionClicked={() => {
                      setComponentState((prev) => {
                        return {
                          ...prev,
                          showAssignCareJourney: true,
                        };
                      });
                    }}
                    referralOrderOptionClicked={() => {
                      setComponentState((prev) => {
                        return {
                          ...prev,
                          showReferralOrders: true,
                        };
                      });
                    }}
                    showCloseButton={props?.isFoldView}
                    handleCloseClick={props?.onClose}
                    isCompactView={props?.isFoldView}
                    taskPanelType={componentState?.taskPanelType}
                  />
                </View>
              )}
            </Stack>
          </Stack>
          <Divider />
          {isNavigatingToCarePlan ? (
            <View style={{flex: 1, height: 400}}>
              <CarePlan {...(carePlanProps as ICarePlanProps)} />
            </View>
          ) : (
            <>
          {componentState.showAssignCareJourney ? (
            <SidecarAssignCareJourney
              contactData={componentState.contactData}
              onComplete={(record: any) => {
                setComponentState((prev) => {
                  return {
                    ...prev,
                    selectedTab: IInsightsTabs.SUMMARY,
                    showAssignCareJourney: false,
                  };
                });
              }}
              onBack={() => {
                setComponentState((prev) => {
                  return {
                    ...prev,
                    showAssignCareJourney: false,
                  };
                });
              }}
            />
          )
          : componentState.showCreateOrEditReferral ? (
            <InsightsCreateReferral
              contactData={componentState?.contactData}
              formattedContactData={componentState?.formattedContactData}
              preview={componentState.referralData?.preview}
              note={componentState?.referralData?.note}
              receiverDetails={componentState?.referralData?.receiverDetails}
              documents={componentState?.referralData?.documents}
              isEdit={componentState?.referralData?.isEdit}
              details={componentState?.referralData?.details}
              inboxId={componentState?.referralData?.inboxId}
              setCreateReferralFormVisible={componentState?.referralData?.setCreateReferralFormVisible}
              onBack={() => {
                componentState.referralData.onBack();
                setComponentState((prev) => ({
                  ...prev,
                showCreateOrEditReferral: false,
                }));
              }}
              onComplete={() => {
                setComponentState((prev) => ({
                  ...prev,
                  selectedTab: IInsightsTabs.REFERRAL,
                  showCreateOrEditReferral: false,
                }));
              }}
            />
          )
          // : componentState.showReferralOrders ? (
          //   <InsightsCreateReferral
          //     contactData={componentState?.contactData}
          //     formattedContactData={componentState?.formattedContactData}
          //     preview={componentState.referralData?.preview}
          //     note={componentState.referralData?.note}
          //     receiverDetails={componentState.referralData?.receiverDetails}
          //     documents={componentState.referralData?.documents}
          //     isEdit={componentState.referralData?.isEdit}
          //     details={componentState.referralData?.details}
          //     inboxId={componentState.referralData?.inboxId}
          //     setCreateReferralFormVisible={componentState.referralData?.setCreateReferralFormVisible}
          //     onComplete={() => {
          //       setComponentState((prev) => {
          //         return {
          //           ...prev,
          //           selectedTab: IInsightsTabs.REFERRAL,
          //           showCreateOrEditReferral: false,
          //           showReferralOrders: true
          //         };
          //       });
          //     }}
          //     onBack={() => {
          //       setComponentState((prev) => {
          //         return {
          //           ...prev,
          //           selectedTab: IInsightsTabs.REFERRAL,
          //           showReferralOrders: true,
          //           showCreateOrEditReferral: false
          //         };
          //       });
          //     }}
          //   />
          // )
          : (
            <>
              { componentState.selecetedCareGapDetail?.id || componentState.selectedDiagnosisGapDetail?.id ? <></> : componentState.showSearchBar ? (
                <Stack
                  direction={'row'}
                  style={{
                    alignItems: 'center',
                    marginVertical: 4,
                    marginHorizontal: 12,
                  }}
                >
                  <SearchBar
                    width={'100%'}
                    onChange={debounce((value: string) => {
                      setComponentState((prev) => {
                        return {
                          ...prev,
                          searchText: value,
                        };
                      });
                    }, 500)}
                    rightElement={
                      <Feather
                        onPress={() =>
                          setComponentState((prev) => ({
                            ...prev,
                            showSearchBar: false,
                            searchText: '',
                          }))
                        }
                        style={{
                          marginRight: 16,
                        }}
                        name="x"
                        size={15}
                        color={Colors.Custom.Gray600}
                      />
                    }
                  />
                </Stack>
              ) : (
                getInsightTabs()?.length > 1 && (
                  <>
                    <Stack
                      direction={'row'}
                      style={{
                        alignItems: 'center',
                        marginVertical: 4,
                        marginLeft: 7,
                        marginRight: 16,
                      }}
                    >
                      <ClinicalSectionFilterTabs
                        useNewDesign
                        tabs={getInsightTabs()}
                        selectedTabCode={componentState.selectedTab}
                        onTabClick={(code: string) => {
                          if (code === IInsightsTabs.ALERTS) {
                            getAlertsCount();
                          }
                          setComponentState((prev) => {
                            return {
                              ...prev,
                              selectedTab: code,
                              alertsFilterCount: 0,
                              searchText: '',
                              taskPanelType: TaskPanelType.INTERNAL
                            };
                          });
                        }}
                      />
                      <View style={{flex: 1}} />
                      {componentState.selectedTab ===
                        SIDE_BAR_CARE_MENU_CODES.ALERTS && (
                        <>
                          {showFilterIcon && (
                            <Pressable
                              onPress={() => {
                                setComponentState((prev) => {
                                  return {
                                    ...prev,
                                    showFiltersTabInAlerts:
                                      !prev.showFiltersTabInAlerts,
                                  };
                                });
                              }}
                            >
                              {
                                <Stack
                                  direction={'column'}
                                  style={{position: 'relative'}}
                                >
                                  {componentState.alertsFilterCount > 0 && (
                                    <>
                                      {
                                        <Badge
                                          mb={-2}
                                          position={'absolute'}
                                          top={-6}
                                          right={-12}
                                          mt={
                                            componentState.alertsFilterCount > 0
                                              ? -2
                                              : 0
                                          }
                                          zIndex={100}
                                          variant="solid"
                                          backgroundColor={
                                            Colors.Custom?.badgeColorOrange
                                          }
                                          alignSelf="flex-end"
                                          size={'smMedium'}
                                          style={{
                                            paddingLeft: 6,
                                            paddingRight: 6,
                                          }}
                                          _text={{
                                            fontSize: 9,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {componentState.alertsFilterCount}
                                        </Badge>
                                      }
                                    </>
                                  )}
                                  <FilterIconSvgForAlerts />
                                </Stack>
                              }
                            </Pressable>
                          )}
                          <View
                            style={{
                              width: 1,
                              height: 20,
                              backgroundColor: Colors.Custom.Gray200,
                              marginHorizontal: 20,
                            }}
                          ></View>
                          {showSearchIcon && (
                            <Pressable
                              onPress={() => {
                                setComponentState((prev) => ({
                                  ...prev,
                                  showSearchBar: true,
                                }));
                              }}
                            >
                              <SearchIconSvg
                                customStrokeColor={Colors.FoldPixel.GRAY300}
                                strokeWidth={1}
                              />
                            </Pressable>
                          )}
                        </>
                      )}
                    </Stack>
                    {componentState?.showFiltersTabInAlerts && <Divider />}
                  </>
                )
              )}
              <ScrollView
                style={{
                  backgroundColor:  componentState.selectedTab ===
                  SIDE_BAR_CARE_MENU_CODES.TASKS ? Colors.Custom.White : Colors.Custom.Gray50,
                  ...(componentState.selectedTab !== 'referral' && {paddingBottom: 60}),
                  paddingBottom: props?.isFoldView ? getBottomPadding() : 60,
                  ...((componentState.selectedTab !==
                    (SIDE_BAR_CARE_MENU_CODES.ALERTS) && 
                    componentState.selectedTab !== SIDE_BAR_CARE_MENU_CODES.TASKS &&
                    componentState.selectedTab !== SIDE_BAR_CARE_MENU_CODES.REFERRAL) && {paddingLeft: 6}),
                  height: props?.isFoldView ? 400 : undefined,
                }}
                scrollEnabled={componentState.selectedTab !== SIDE_BAR_CARE_MENU_CODES.TASKS}
              >
                {componentState.selecetedCareGapDetail?.id
                  ? careGapDetailView()
                    : componentState.selectedDiagnosisGapDetail?.id ? diagnosisGapDetailView()
                    : selectedViewElement()}
              </ScrollView>
            </>
          )}
          </>
        )}
      </Stack>
      )}
    </>
  );
};
