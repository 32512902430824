import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    spinnerStyle: {
        margin: '0 auto',
        color: 'black',
        position: 'absolute',
        zIndex: 100011111,
        backgroundColor: '#0000000f',
        height: '100%',
        width: '100%',
        padding: 0
      },
      formElement: {
        paddingBottom: 10,
      },
      formLabel: {
        marginLeft: 4,
      },
})