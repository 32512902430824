import {
  IStepsLog
} from '../../../../services/ContactCareProgram/interface';
import {
  IContactCareProgramState
} from '../interface';
import {
  getInitSelectedStepAndCollapseIds,
} from './ContactCareProgramView.utils';

export enum ContactCareProgramReducerAction {
  SET_ACTIVE_STEP_ID = 'SET_ACTIVE_STEP_ID',
  SET_SELECTED_STEP_ID = 'SET_SELECTED_STEP_ID',
  SET_CONTACT_CARE_PROGRAM_DETAILS = 'SET_CONTACT_CARE_PROGRAM_DETAILS',
  SET_STATUS = 'SET_STATUS',
  EXPAND_SIDEBAR_STEP = 'EXPAND_SIDEBAR_STEP',
  COLLAPSE_SIDEBAR_STEP = 'COLLAPSE_SIDEBAR_STEP',
  SET_GROUPED_STEPS = 'SET_GROUPED_STEPS',
  SET_INIT_DATA = 'SET_INIT_DATA',
  UPDATE_STEP_STATUS = 'UPDATE_STEP_STATUS',
  SET_STEP_PROGRESS_MAP = 'SET_STEP_PROGRESS_MAP',
  UPDATE_CARE_PROGRAM_STATUS = 'UPDATE_CARE_PROGRAM_STATUS',
  UPDATE_CARE_PROGRAM_ASSIGNEE = 'UPDATE_CARE_PROGRAM_ASSIGNEE',
}

export interface IContactCareProgramReducerAction {
  type: keyof typeof ContactCareProgramReducerAction;
  payload: any;
}

export type ContactCareProgramReducer = (
  state: IContactCareProgramState,
  action: IContactCareProgramReducerAction
) => IContactCareProgramState;

export const contactCareProgramReducer = (
  state: IContactCareProgramState,
  action: IContactCareProgramReducerAction
) => {
  switch (action.type) {
    case ContactCareProgramReducerAction.SET_ACTIVE_STEP_ID:
      return {...state, activeStepId: action.payload};
    // MAIN VIEW DEPENDENT STATE
    case ContactCareProgramReducerAction.SET_SELECTED_STEP_ID:
      return {...state, selectedStepId: action.payload};
    case ContactCareProgramReducerAction.SET_CONTACT_CARE_PROGRAM_DETAILS:
      return {...state, contactCareProgramDetails: action.payload};
    case ContactCareProgramReducerAction.SET_STATUS:
      return {...state, status: action.payload};
    case ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_STATUS:
      const newStatusId = action.payload;
      return {
        ...state,
        contactCareProgramDetails: {
          ...state.contactCareProgramDetails,
          statusId: newStatusId,
        } as IContactCareProgramState['contactCareProgramDetails'],
      };
    case ContactCareProgramReducerAction.EXPAND_SIDEBAR_STEP:
      return {
        ...state,
        sideBarExpandedStepKeysSet: new Set([
          ...state.sideBarExpandedStepKeysSet,
          action.payload,
        ]),
      };
    case ContactCareProgramReducerAction.COLLAPSE_SIDEBAR_STEP:
      return {
        ...state,
        sideBarExpandedStepKeysSet: new Set(
          [...state.sideBarExpandedStepKeysSet].filter(
            (key) => key !== action.payload
          )
        ),
      };
    case ContactCareProgramReducerAction.SET_GROUPED_STEPS:
      return {...state, groupedSteps: action.payload};
    case ContactCareProgramReducerAction.SET_INIT_DATA:
      const newState = action.payload as IContactCareProgramState;
      return {
        ...state,
        ...newState,
      };

    case ContactCareProgramReducerAction.UPDATE_STEP_STATUS:
      const {newStepLogStatusId, id, statusesToCalculateNextStep} =
        action.payload;
      // Find the existing step log entry
      const existingStepLogEntry =
        state?.contactCareProgramDetails?.stepsLog?.find(
          (item) => item.careProgramStepId === id
        );
      if (existingStepLogEntry) {
        // Create a new step log entry with new status
        const newStepLogEntry: IStepsLog = {
          ...existingStepLogEntry,
          careProgramStepStatusId: newStepLogStatusId,
        };
        // Update the steps log with the new step log entry
        const newStepsLog = state?.contactCareProgramDetails?.stepsLog?.map(
          (item) => (item.careProgramStepId === id ? newStepLogEntry : item)
        );

        // MOVE TO NEXT STEP
        const {initSelectedStepId, expandStepIds} = action.payload?.skipMoveToNextStep ?
          {initSelectedStepId: undefined, expandStepIds: undefined} :
          getInitSelectedStepAndCollapseIds(
            state?.groupedSteps || [],
            newStepsLog || [],
            statusesToCalculateNextStep
          );

        // Return the new state with the updated steps log
        return {
          ...state,
          contactCareProgramDetails: {
            ...state.contactCareProgramDetails,
            stepsLog: newStepsLog,
          },
          ...(initSelectedStepId ? {selectedStepId: initSelectedStepId} : {}),
          ...(expandStepIds?.length
            ? {sideBarExpandedStepKeysSet: new Set(expandStepIds)}
            : {}),
        };
      }
      return state;
    case ContactCareProgramReducerAction.SET_STEP_PROGRESS_MAP:
      const {id: stepId, progress} = action.payload;
      return {
        ...state,
        stepProgressMap: state?.stepProgressMap?.set(stepId, progress),
      };

    case ContactCareProgramReducerAction.UPDATE_CARE_PROGRAM_ASSIGNEE:
      return {
        ...state,
        contactCareProgramDetails: {
          ...state.contactCareProgramDetails,
          assigneeId: action.payload,
        },
      };
    default:
      return state;
  }
};
