import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Dimensions, View} from 'react-native';
import {GraphConfig} from '../../../../common/AppDashboard/GraphUtils';
import {IAppliedFilter, IResource, IWidgetCardView} from '../interface';
import TotalSavingDetailView from './TotalSavingDetailView';
import {Column, ColumnConfig, LineConfig, Pie} from '@ant-design/charts';
// import {Line, LineChart, XAxis, YAxis} from 'recharts';
import {getFormattedDataForLineChart} from '../EmployerUtils/FormateDataUtils';
import {Line} from '@ant-design/plots';
import moment from 'moment';
import NodataViewTypeOne from './NoDataView/NodataViewTypeOne';
import {getTenantForDemo, TOTAL_SAVINGS} from '../tempDataFile';
import {getSectionDataByResourceCode} from '../../../../../services/Employer/EmployerServices/EmployerServices';
import {aggregateGroupEntry, convertNumberToDisplayFormat, isWidgetDataEmpty} from '../EmployerUtils/WidgetUtils';
import {getGraphColorsByName} from './utils';
import WidgetHeader from '../WidgetHeader/WidgetHeader';
import {graphColorList} from '../graphColors';
import {Colors} from '../../../../../styles';
import {Skeleton} from 'antd';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {EventBus} from '../../../../../utils/EventBus';
import CustomTooltip from './MixMultipleCharts/CustomTooltip';
import {WIDGET_ACTION_CODE} from '../EmployerUtils/EmployerConst';
const SavingWidgetWithPieAndLine = (props: IWidgetCardView) => {
  const {
    item,
    onOpenDetails,
    onActionClick,
    sectionData,
    reusableData,
    sectionCode,
    employerId,
    appliedFilter,
    isAccountDashboard,
    memberType,
  } = props;
  const containerRef: any = useRef();
  const [stateData, setStateData] = useState({
    resourceData: [] as IResource[] | any,
    loading: true,
    appliedFilter: props.appliedFilter
  });
  const isTotalEmployeeMembershipCostOn = isAccountConfigEnabled(
    CONFIG_CODES.TOTAL_EMPLOYEE_MEMBERSHIP_COST
  );
  const isNewCostSavingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_NEW_COST_SAVING_API)
  const totalSavingTraditionalCost =
    stateData.resourceData?.currentDateRangeTotalCostSavingData?.totalMarketCost;
  const totalSavingOurCost =
    stateData.resourceData?.currentDateRangeTotalCostSavingData?.totalDpcCost;
  const totalMembershipCost =
    stateData.resourceData?.currentDateRangeTotalCostSavingData?.totalMembershipCost;
  const totalServiceCost =
    stateData.resourceData?.currentDateRangeTotalCostSavingData?.totalServiceCost;

  const graphData =
    totalSavingTraditionalCost || totalSavingOurCost
      ? isTotalEmployeeMembershipCostOn ? [
        {
          value: totalSavingTraditionalCost ? Math.trunc(totalSavingTraditionalCost) : totalSavingTraditionalCost,
          type: 'Total Cost',
          name: 'Traditional Cost',
        },
        {
          value: totalMembershipCost ? Math.trunc(totalMembershipCost) : totalMembershipCost,
          type: 'Membership Cost',
          name: 'Our Cost',
        },
        {
          value: totalServiceCost ? Math.trunc(totalServiceCost) : totalServiceCost,
          type: 'Service Cost',
          name: 'Our Cost',
        },
      ] : [
        {
          value: totalSavingTraditionalCost ? Math.trunc(totalSavingTraditionalCost) : totalSavingTraditionalCost,
          type: 'Total Cost',
          name: 'Traditional Cost',
        },
        {
          value: totalServiceCost ? Math.trunc(totalServiceCost) : parseInt(totalSavingOurCost.toFixed(2)),
          type: 'Service Cost',
          name: 'Our Cost',
        },
      ]
      : [];

  const getGraphView = () => {
    let containerWidth = 200;
    if (containerRef?.current?.offsetWidth) {
      containerWidth = parseInt((containerRef?.current?.offsetWidth) as any) * .35;
    }
    const configStack: ColumnConfig = {
      data: graphData,
      color: [graphColorList[0], graphColorList[1]],
      isStack: true,
      xField: 'name',
      yField: 'value',
      seriesField: 'type',
      legend: false,
      height: 294,
      width: containerWidth,
      yAxis: {
        tickLine: {
          style: {
            fill: '#fff',
            stroke: '#fff',
          },
        },
        label: {
          style: {
            fontSize: 12,
            fontWeight: 400,
            fill: Colors.Custom.Gray700
          },
          formatter(text, item, index) {
            return convertNumberToDisplayFormat(text)
          },
        },
        line: {
          style: {
            stroke: '#F2F4F7',
          },
        },
        grid: {
          alignTick: true,
          line: {
            type: 'line',
            style: {
              stroke: '#F2F4F7'
            }
          },
          closed: true
        }
      },
      renderer: 'svg',
      xAxis: {
        label: {
          style: {
            fontSize: 12,
            fontWeight: 400,
            fill: Colors.Custom.Gray700
          },
          formatter() {
            return '';
          },
        },
      },
      tooltip: {
        shared: true,
        customContent : (title: string, data: any[]) => {
          data.forEach((singleItem) => {
            singleItem.value =  convertNumberToDisplayFormat(singleItem.value || 0)
          })
          if (title && data?.length) {
            return <CustomTooltip title={title} data={data}/>
          }
        }
      },
      columnStyle(datum: any) {
        return {
          radius: [2, 2, 0, 0],
          fill: null
        };
      },
      annotations: aggregateGroupEntry(sectionData.resourceCode || '', graphData),
      onReady: (plot: any) => {
        plot.on('element:click', (args: any) => {
          if (args?.data?.data?.type) {
          }
        });
      },
    };
    return <Column {...configStack} />
  }
  const peiChartView = useMemo(() => {
    return getGraphView()
  }, [stateData.loading])

  const getSectionByResourceCode = async (appliedFilter?: IAppliedFilter[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const filterList = appliedFilter?.length ? appliedFilter : stateData.appliedFilter
    const response = await getSectionDataByResourceCode(
      employerId,
      sectionData.resourceCode || '',
      filterList,
      '',
      isAccountDashboard,
      memberType,
      '',
      isNewCostSavingEnabled
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          resourceData: [],
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data) {

      const resourceData = response.data;
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
        };
      });
    } else {
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: [] as IResource[],
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };
  const controller = new AbortController();
  useEffect(() => {
    if (getTenantForDemo()) {
      const resourceData: any = getTenantForDemo() ? TOTAL_SAVINGS : stateData.resourceData;
      setTimeout(() => {
        setStateData((prev) => {
          return {
            ...prev,
            resourceData: resourceData,
            loading: false
          }
        })
      }, 1500)
      return
    }
    getSectionByResourceCode();

    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener('RE_FETCH_TOTAL_SAVING', getSectionByResourceCode);
    return () => {
      controller?.abort();
      eventBus.removeEventListener(getSectionByResourceCode);
    };
  }, [sectionData.id])
  useEffect(() => {
    if (props.filterData?.resourceCode == sectionData.resourceCode) {
      const appliedFilter = stateData.appliedFilter?.length ? [...stateData.appliedFilter] : [];
      const selectedFilter = {
        filterCode: props.filterData?.filterCode,
        filterValue: props.filterData?.filterValue
      }
      appliedFilter.push(selectedFilter);
      getSectionByResourceCode(appliedFilter);
    }
    return () => controller?.abort();
  }, [props.filterData?.filterValue])
  return (
    <>
      <div ref={containerRef} className="widgetBoxShadow" style={{width: '100%', height: '447px'}}>
        <div>
          <WidgetHeader isPrintPreview={props.isPrintPreview} isPrintDownload={props.isPrintDownload} sectionData={sectionData} onActionClick={onActionClick} />
        </div>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingBottom: graphData.length ? 0 : 24}}>
          <div style={{width: containerRef?.current?.offsetWidth ? containerRef?.current?.offsetWidth * .58 : '58%'}}>
            {stateData.loading ? (
              <Skeleton style={{padding: 16}} />
            ) : (
              <TotalSavingDetailView
                appliedFilter={appliedFilter}
                employerId={employerId}
                sectionCode={sectionCode}
                onActionClick={onActionClick}
                sectionData={sectionData}
                resourceData={stateData.resourceData}
                reusableData={reusableData}
                isGraphCard={true}
                item={sectionData}
                isPrintDownload={props.isPrintDownload}
              />
            )
            }
          </div>
          <div
            style={{
              padding: graphData.length ? '15px 5px' : '15px 5px 0px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 32,
              width: containerRef?.current?.offsetWidth ? containerRef?.current?.offsetWidth * .35 : '35%'
            }}
          >
            {stateData.loading ? (
              <Skeleton style={{padding: 16, width: 250, height: 250}} active={true} />
            ) : graphData.length  ? (
              peiChartView
            ) : (
              <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 250,
                height: 283,
              }}
            >
              <NodataViewTypeOne viewCode="TEXT_ICON" />{' '}
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(SavingWidgetWithPieAndLine);
