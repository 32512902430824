import {useLazyQuery} from '@apollo/client';
import {Skeleton, Text, View} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {Tooltip} from 'antd';
import EmployerQueries from '../../../../services/Employer/EmployerQueries';
import {getAccountUUID, isLoginUserBusinessOwner, isMultiTenancyEnabled} from '../../../../utils/commonUtils';
import {EmployeeCountProps} from '../interfaces';
import {Colors} from '../../../../styles';
import EmployeeQueries from '../../../../services/Employee/EmployeeQueries';
import { getSearchContactActionFilter } from '../../MembersManagement/MembersManagementHelper';
import { CommonDataContext } from '../../../../context/CommonDataContext';

const EmployeeCount = (props: EmployeeCountProps) => {
  const {employerId, count} = props;
  const [state, setState] = useState({
    count: count || 0,
    actualCount: null,
    loading: true,
  });
  const accountUuid = getAccountUUID();
  const isBusinessOwner = isLoginUserBusinessOwner()
  const context = useContext(CommonDataContext);
  const userSettings = context.userSettings;
  const isMultiTenantEnabled = isMultiTenancyEnabled(userSettings);

  const [getEmployeeCount] = useLazyQuery(EmployerQueries.GetEmployeeCount);
  const [getEmployeeBySearchContactActions] = useLazyQuery(EmployeeQueries.getEmployeeBySearchContactAction,{
    fetchPolicy: 'no-cache',
  })

  const getCount = async () => {
    try {
      const response = await getEmployeeCount({
        variables: {
          employerId,
          accountUuid,
          currentDate: new Date()
        },
      });
      const count = response.data?.employeeAggregate?.aggregate?.count || 0;
      setState((prev) => {
        return {
          ...prev,
          count: count,
          loading: false,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          count: 0,
          loading: false,
        };
      });
    }
  };

  const getActualCount = async () => {
    if(isMultiTenantEnabled && !isBusinessOwner){
      try {
        const searchEmplyeeFilter = getSearchContactActionFilter({
          employerId: employerId || '',
          searchString: '',
          type: [],
          startFrom: '',
          startTo:'',
          endFrom: '',
          endTo: '',
          selectedPlans: [],
          selectedStatus: [],
        });
        searchEmplyeeFilter.limit = 1000;
        searchEmplyeeFilter.offset = 0;
        const employeeResponse = await getEmployeeBySearchContactActions({
          variables: {
            params: searchEmplyeeFilter,
          },
        });
        const actualCount = employeeResponse.data?.searchContacts?.contactAggregate?.aggregate?.count || 0;
        setState((prev) => {
          return {
            ...prev,
            actualCount,
          };
        });
      } catch (error) {
      }
    }
  };

  useEffect(() => {
    if (count) {
      setState((prev) => {
        return {
          ...prev,
          count: count,
          loading: false,
        };
      });
    } else {
      getCount();
    }
    getActualCount();
  }, []);

  return (
    <View>
      {state.loading ? <Skeleton.Text lines={2} /> : (
        <Tooltip
          title={
            state.actualCount !== null ? (
              <View>
                <Text color={Colors.Custom.White}>Total Employees: {state.count} (across all locations)</Text>
                <Text color={Colors.Custom.White}>Accessible Employees: {state.actualCount} (based on your location access)</Text>
              </View>
            ) : null
          }
          placement="topLeft"
          onOpenChange={(visible) => {
            if (visible && state.actualCount === null) {
              getActualCount();
            }
          }}
        >
          <Text flex={1}>{state.count}</Text>
        </Tooltip>
      )}
    </View>
  );
};

export default EmployeeCount;
