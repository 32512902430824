import React, {useEffect, useRef, useState} from 'react';
import {
  ANALYTICS_RESOURCE,
  DATE_FORMATS,
  EMPLOYER_REPORT_FILTER_CODES,
} from '../../../../../constants';
import {IAppliedFilter, IResource, IWidgetCardView} from '../interface';
// import ReactToPrint, {useReactToPrint} from 'react-to-print';
import {Rate, Skeleton} from 'antd';
import {Text} from 'native-base';
import NodataViewTypeOne from './NoDataView/NodataViewTypeOne';
import {WIDGET_ACTION_CODE} from '../EmployerUtils/EmployerConst';
import {getSectionDataByResourceCode} from '../../../../../services/Employer/EmployerServices/EmployerServices';
import {isWidgetDataEmpty} from '../EmployerUtils/WidgetUtils';
import {Dimensions, Pressable} from 'react-native';
import RestSvgIcons from '../../../../common/Svg/RestSvgIcons';
import {Tooltip} from 'native-base';
import {getEmployerReportFilterList} from '../../../../../utils/commonUtils';
import WidgetHeader from '../WidgetHeader/WidgetHeader';
import {Colors} from '../../../../../styles/Colors';
import moment from 'moment';
import {getDemoDataForEmployerReportCurrentSection, getTenantForDemo} from '../tempDataFile';
const StarRatingChartView = (props: IWidgetCardView) => {
  const {
    sectionData,
    dateRange,
    employerId,
    isAccountDashboard,
    hintMembershipDisabled,
    memberType} = props;
  const containerRef: any = useRef()
  const titleRef: any = useRef();
  const {width} = Dimensions.get('window');
  const [stateData, setStateData] = useState({
    resourceData: [] as IResource[] | any,
    loading: false,
    employeeTotalCount: 0,
    selectedView: '',
    selectedResourceData: {} as any,
    masterData: {} as any,
    appliedFilter: props.appliedFilter,
    filterValueName: [] as string[],
    filterValueString: '',
    selectedAppliedFilter: [] as any[],
    graphWrapperHeight: 350,
    selectedFormId: '',
  });


  const onActionClick = (code: string, data?: any) => {
    switch (code) {
      case WIDGET_ACTION_CODE.RESET_LOCATION_FILTER:
        const filteredWidgetFilter = stateData.selectedAppliedFilter.filter(item => {
          return item.filterCode != 'LOCATION'
        })
        setStateData(prev => {
          return {
            ...prev,
            selectedAppliedFilter: filteredWidgetFilter,
            filterValueString: '',
            filterValueName: []
          }
        })
        getSectionByResourceCode();
        break;
      default:
        props.onActionClick?.(code, data)
        break;
    }
  };


  const getSectionByResourceCode = async (appliedFilter?: IAppliedFilter[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let formId = '';

    let filterList: any = appliedFilter?.length ? appliedFilter : stateData.appliedFilter;
    let appliedFilterList = [
    ];
    if (!filterList?.length) {
      filterList = [];
    }
    if (sectionData?.appliedFilter) {
      appliedFilterList = getEmployerReportFilterList(sectionData?.appliedFilter);
      if (appliedFilterList?.length && filterList?.length) {
        filterList = [
          ...filterList,
          ...appliedFilterList
        ];
      } else if (appliedFilterList?.length) {
        filterList = [...appliedFilterList];
      }
    }
    if (sectionData.resourceCode === ANALYTICS_RESOURCE.APPOINTMENT_RATING && sectionData?.additionalAttributes?.formId) {
      formId = stateData?.selectedFormId || sectionData?.additionalAttributes?.formId;
      filterList = [
        ...filterList,
        {
          filterCode: EMPLOYER_REPORT_FILTER_CODES.FORM_ID,
          filterValue: {
            value: formId
          }
        }
      ];

    }
    if (hintMembershipDisabled) {
      filterList.push({
        filterCode: EMPLOYER_REPORT_FILTER_CODES.MEMBERSHIP, 
        filterValue: hintMembershipDisabled
      })
    }
    const response = await getSectionDataByResourceCode(
      employerId,
      sectionData.resourceCode || '',
      filterList,
      formId,
      isAccountDashboard,
      memberType,
      sectionData.id
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          resourceData: [],
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data && isWidgetDataEmpty(response?.data)) {
      const containerHight = containerRef?.current?.offsetHeight;
      const titleWrapperHight = titleRef?.current?.offsetHeight;
      const heightVariable = (props.sectionData?.subHeading?.length || 0) > 100 ? 20 : 10
      const graphWrapper = containerHight - titleWrapperHight - heightVariable - 50
      let resourceData = response.data?.totalCount ? response.data : response.data?.result || response.data;
      if (sectionData?.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION) {
        resourceData = response.data;
      }
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
          graphWrapperHeight: graphWrapper
        };
      });
    } else {
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: [] as IResource[],
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };

  useEffect(() => {
    if (getTenantForDemo()) {
      const resourceData = getDemoDataForEmployerReportCurrentSection(sectionData);
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: 0,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          selectedFormId: sectionData?.additionalAttributes?.formId
        };
      });
      getSectionByResourceCode();
    }

  }, []);

  const getRefreshButtonOnLocalEnv = () => {
    if (window.location.host.includes('localhost')) {
      return (
        <Pressable
          style={{marginHorizontal: 3}}
          onPress={() => {
            setStateData(prev => {
              return {
                ...prev,
                filterValueName: [],
                filterValueString: ''
              }
            })
            getSectionByResourceCode();
          }}
        >
          <Tooltip label="Refresh">
            <RestSvgIcons />
          </Tooltip>
        </Pressable>
      )
    }
    return <></>;
  }

  const onSingleQuestingClick = (singleQueData: {
    displayText: string,
    filterCode: string,
  }) => {
    let filterData = [] as any[];
    const dashBoardDateRange = {
      fromDate: moment(props.dateRange?.uiFromDate).format(
        `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`
      ),
      toDate: moment(props.dateRange?.uiToDate).format(
        `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`
      ),
    };
    const formId = stateData?.selectedFormId || sectionData?.additionalAttributes?.formId;
    filterData = [
      {
        filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
        filterValue: dashBoardDateRange,
      },
      {
        filterCode: EMPLOYER_REPORT_FILTER_CODES.FORM_QUESTION_ID,
        filterValue: {
          value: singleQueData?.filterCode
        },
      },
      {
        filterCode: EMPLOYER_REPORT_FILTER_CODES.FORM_ID,
        filterValue: {
          value: formId
        }
      },
    ];
    props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: 0});
  }
  const getStarRatingListElem = () => {
    return stateData?.resourceData.map((singleQueData: any) => {
      return (
        <div
          onClick={() => {
            onSingleQuestingClick(singleQueData);
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            paddingTop: width < 1560 ? 12 : 0
          }}>
          <Tooltip label={singleQueData?.displayText}>
            <div style={{
              maxWidth: 200,
              width: 200,
              height: 24,
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              <Text
                noOfLines={1}
                fontSize={width < 1560 ? 14 : 16}
                fontWeight={600}
                lineHeight={'24px'}
                fontStyle={'normal'}
                color={Colors.Custom.textBlackColor}
              >
                {singleQueData?.displayText}
              </Text>
            </div>
          </Tooltip>
          <div style={{
            paddingLeft: '12px',
            cursor: 'pointer',
          }} >
            <Rate
              allowHalf
              disabled
              defaultValue={singleQueData?.countNum}
              style={{
                fontSize: width < 1560 ? 20 : 32,
                color: '#FFA477',
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
      )
    });
  };
  return (
    <div ref={containerRef} className="widgetBoxShadow">
      <div ref={titleRef}>
        <WidgetHeader isPrintPreview={props.isPrintPreview} isPrintDownload={props.isPrintDownload} refreshButtonOnLocalEnv={getRefreshButtonOnLocalEnv()} sectionData={sectionData} onActionClick={onActionClick} />
      </div>
      {stateData.loading ? (
        <Skeleton style={{padding: 40}} active={true} />
      ) : (
        <>
          {stateData.resourceData && stateData.resourceData?.length ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                height: '75%'
              }}
            >
              <main style={{alignSelf: 'center', width: '100%', height: '100%', justifyContent: 'center', display: 'flex', alignItems: 'flex-start'}}>
                <main
                  style={{padding: '10px 0px', width: '88%'}}
                  className={stateData.resourceData.length && props.isPrintPreview ? 'canvas-wrapper' : ''}
                >
                  {getStarRatingListElem()}
                </main>
              </main>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '60%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NodataViewTypeOne viewCode={'TEXT_ICON'} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(StarRatingChartView);
