import {Path, Svg, Defs, Rect, G, ClipPath} from 'react-native-svg';

const PauseButtonSvg = (props: {strokeColor?: string; size?: number;}) => {
  const strokeColor = props.strokeColor || "#8A94A8";
  const size = props?.size || 16;

  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <G clip-path="url(#clip0_862_5403)">
    <Path d="M1.33325 4.00016C1.33325 2.74308 1.33325 2.11454 1.72378 1.72402C2.1143 1.3335 2.74284 1.3335 3.99992 1.3335C5.257 1.3335 5.88554 1.3335 6.27606 1.72402C6.66659 2.11454 6.66659 2.74308 6.66659 4.00016V12.0002C6.66659 13.2572 6.66659 13.8858 6.27606 14.2763C5.88554 14.6668 5.257 14.6668 3.99992 14.6668C2.74284 14.6668 2.1143 14.6668 1.72378 14.2763C1.33325 13.8858 1.33325 13.2572 1.33325 12.0002V4.00016Z"
     stroke={strokeColor}/>
    <Path d="M9.33325 4.00016C9.33325 2.74308 9.33325 2.11454 9.72378 1.72402C10.1143 1.3335 10.7428 1.3335 11.9999 1.3335C13.257 1.3335 13.8855 1.3335 14.2761 1.72402C14.6666 2.11454 14.6666 2.74308 14.6666 4.00016V12.0002C14.6666 13.2572 14.6666 13.8858 14.2761 14.2763C13.8855 14.6668 13.257 14.6668 11.9999 14.6668C10.7428 14.6668 10.1143 14.6668 9.72378 14.2763C9.33325 13.8858 9.33325 13.2572 9.33325 12.0002V4.00016Z"
     stroke={strokeColor}/>
    </G>
    <Defs>
    <ClipPath id="clip0_862_5403">
    <Rect width={size} height={size} fill="white"/>
    </ClipPath>
    </Defs>
    </Svg>

  )
}


export default PauseButtonSvg;
