import _ from 'lodash';
import {getId} from '../../../Workflow/FlowComponent';
import {
  JOURNEY_PRESCRIBE_NODE,
  JOURNEY_PRESCRIBE_TRIGGER_EVENT,
  JOURNEY_PURCHASE_NODE,
  JOURNEY_PURCHASE_TRIGGER_EVENT,
  JOURNEY_USER_IS_PRIMARY_NODE,
} from '../JourneyTabs';

export const getDefaultNodesToBeRendered = (
  nodes: any[],
  edges: any[],
  triggerEvents: string[],
  nodeMasterDataMap: any
) => {
  if (!triggerEvents.length) {
    return {edges: [], nodes: []};
  }
  let defaultNodes: any[] = nodes;
  let defaultEdges: any[] = edges;
  if (triggerEvents.length && Object.keys(nodeMasterDataMap).length > 0) {
    const userPrimaryNode = getNodeElement(
      JOURNEY_USER_IS_PRIMARY_NODE,
      nodeMasterDataMap,
      {x: 230, y: 220},
      defaultNodes
    );
    const existingTriggersInNodes = defaultNodes.map(
      (node) => node.data?.nodeType
    );
    if (!existingTriggersInNodes.includes(JOURNEY_USER_IS_PRIMARY_NODE)) {
      defaultNodes.push(userPrimaryNode);
    }

    if (
      triggerEvents.includes(JOURNEY_PURCHASE_TRIGGER_EVENT) &&
      !existingTriggersInNodes.includes(JOURNEY_PURCHASE_NODE)
    ) {
      const data = removeNodeOfNodeType(
        defaultNodes,
        defaultEdges,
        JOURNEY_PRESCRIBE_NODE
      );
      defaultNodes = data.nodes;
      defaultEdges = data.edges;

      const purchasedNode = getNodeElement(
        JOURNEY_PURCHASE_NODE,
        nodeMasterDataMap,
        {x: 230, y: 20},
        defaultNodes
      );
      defaultNodes = [purchasedNode].concat(defaultNodes);
      const edge = getEdge(purchasedNode, userPrimaryNode);
      defaultEdges.push(edge);
    } else if (
      triggerEvents.includes(JOURNEY_PRESCRIBE_TRIGGER_EVENT) &&
      !existingTriggersInNodes.includes(JOURNEY_PRESCRIBE_NODE)
    ) {
      const data = removeNodeOfNodeType(
        defaultNodes,
        defaultEdges,
        JOURNEY_PURCHASE_NODE
      );
      defaultNodes = data.nodes;
      defaultEdges = data.edges;

      const prescribedNode = getNodeElement(
        JOURNEY_PRESCRIBE_NODE,
        nodeMasterDataMap,
        {x: 230, y: 20},
        defaultNodes
      );
      defaultNodes = [prescribedNode].concat(defaultNodes);
      const edge = getEdge(prescribedNode, userPrimaryNode);
      defaultEdges.push(edge);
    }
  }
  return {edges: defaultEdges, nodes: defaultNodes};
};

const removeNodeOfNodeType = (
  nodes: any[],
  edges: any[],
  nodeType: string
): {nodes: any[]; edges: any[]} => {
  const removedNodes = _.remove(nodes, (node) => {
    return node.data?.nodeType === nodeType;
  });
  _.remove(edges, (edge) => {
    const edgesOfRemovedNodes: any[] = [];
    removedNodes.forEach((removedNode) => {
      if (removedNode.id === edge.source) {
        edgesOfRemovedNodes.push(edge);
      }
    });
    return edgesOfRemovedNodes.length;
  });
  return {nodes, edges};
};

const getNodeElement = (
  nodeType: string,
  nodeMasterDataMap: any,
  position: any,
  nodes: any[]
) => {
  const existingNode = _.find(nodes, (node) => {
    return node.data?.nodeType === nodeType;
  });
  if (existingNode) {
    return existingNode;
  }
  const newNode = nodeMasterDataMap[nodeType];
  const node = nodeMasterDataMap[newNode.type];
  return {
    id: getId(node.type),
    type: node.awsState,
    data: {
      nodeType: node.type,
      nodeMasterDataMap,
    },
    title: newNode.displayName,
    position,
  };
};

const getEdge = (source: any, target: any) => {
  return {
    source: source.id,
    sourceHandle: 'out',
    target: target.id,
    targetHandle: 'in',
    id: getId('edge'),
    type: 'bezier',
  };
};
