import {Linking} from 'react-native';

export const openLinkInBrowser = (linkUrl: string) => {
  Linking.canOpenURL(linkUrl).then((supported) => {
    if (supported) {
      Linking.openURL(linkUrl);
    } else {
    }
  });
};
