import React from 'react';
import {Path, Rect, Svg} from 'react-native-svg';

function ScheduleRuleIcon() {
  return (
    <Svg
      width="42"
      height="43"
      fill="none"
      viewBox="0 0 42 43"
      key={'scheduleRuleIcon'}
    >
      <Rect
        width="41"
        height="41"
        x="0.5"
        y="0.724"
        fill="#F9F5FF"
        rx="20.5"
      ></Rect>
      <Path
        stroke="#825AC7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M25.098 13.93h1.383a2.61 2.61 0 012.605 2.604v10.793a2.61 2.61 0 01-2.605 2.605H15.688a2.61 2.61 0 01-2.605-2.605V16.534a2.61 2.61 0 012.605-2.605h1.383m8.027 0c0-.78-.633-1.414-1.414-1.414h-5.2c-.78 0-1.413.633-1.413 1.414m8.027 0c0 .781-.633 1.415-1.414 1.415h-5.2a1.414 1.414 0 01-1.413-1.415m6.89 6.476l-4.413 4.423-1.33-1.329"
      ></Path>
      <Rect
        width="41"
        height="41"
        x="0.5"
        y="0.724"
        stroke="#825AC7"
        strokeLinecap="round"
        rx="20.5"
      ></Rect>
    </Svg>
  );
}

export default React.memo(ScheduleRuleIcon);
