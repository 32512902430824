import {Icon, Input, Pressable, Text, View, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {Colors} from '../../../../../styles';
import {commonBodyContentContainer} from '../../../../../styles/CommonBodyContentStyle';
import CustomTabsView from '../../../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../../../common/CustomTabsView/interfaces';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES,
} from '../../../../common/TableTopBar';
import CostSavingTemplates from './CostSavingTemplates';
import {COST_TYPE_CODE, SavingType} from './helperFiles/CostTemplatesConst';
import Feather from 'react-native-vector-icons/Feather';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {SearchBar} from '../../../../common/SearchBar';
import {debounce} from 'lodash';

const CostTemplatesMainView = () => {
  const location = useLocation();
  const basePath = '/admin/costTemplate';
  const [searchString, setSearchString] = useState('');
  const [stateData, setStateData] = useState({
    filterVisible: false,
    actionCode:'',
    showSearchBar: false,
    showBadge: false
  })

  const handleSearchChange = (searchString: any) => {
    setSearchString(searchString.trim());
  };



  const tagsTabList = (): ITabsList[] => {
    const orderDetailTabView: ITabsList[] = [
      {
        key: 'visitSaving',
        path: 'visitSaving',
        title: 'Visit Savings',
        tabContainerElem: () => {
          return (
            <View key={'visitSaving'}>
              <CostSavingTemplates
                actionCode={stateData.actionCode}
                savingType={SavingType.visitSaving}
                searchText={searchString}
                filterVisible={stateData.filterVisible}
                onActionPerformed={onActionPerformed}
              />
            </View>
          );
        },
      },
      {
        key: 'labSavings',
        path: 'labSaving',
        title: 'Lab Savings',
        tabContainerElem: () => {
          return (
            <View key={'LabSavings'}>
              <CostSavingTemplates
                actionCode={stateData.actionCode}
                savingType={SavingType.labSaving}
                searchText={searchString}
                filterVisible={stateData.filterVisible}
                onActionPerformed={onActionPerformed}
              />
            </View>
          );
        },
      },
      {
        key: 'imageSaving',
        path: 'imageSaving',
        title: 'Imaging Savings',
        tabContainerElem: () => {
          return (
            <View key={'imageSaving'}>
              <CostSavingTemplates
                actionCode={stateData.actionCode}
                savingType={SavingType.imageSaving}
                searchText={searchString}
                filterVisible={stateData.filterVisible}
                onActionPerformed={onActionPerformed}
              />
            </View>
          );
        },
      },
      {
        key: 'procedureSaving',
        path: 'procedureSaving',
        title: 'Procedure Savings',
        tabContainerElem: () => {
          return (
            <View key={'procedureSaving'}>
              <CostSavingTemplates
                actionCode={stateData.actionCode}
                savingType={SavingType.procedureSaving}
                searchText={searchString}
                filterVisible={stateData.filterVisible}
                onActionPerformed={onActionPerformed}
              />
            </View>
          );
        },
      },
      {
        key: 'avoidableVisitSaving',
        path: 'avoidableVisitSaving',
        title: 'Avoidable Visit Savings',
        tabContainerElem: () => {
          return (
            <View key={'avoidableVisitSaving'}>
              <CostSavingTemplates
                actionCode={stateData.actionCode}
                savingType={SavingType.avoidableVisitSaving}
                searchText={searchString}
                filterVisible={stateData.filterVisible}
                onActionPerformed={onActionPerformed}
              />
            </View>
          );
        },
      },
      {
        key: 'avoidedWorkplaceAbsenceSaving',
        path: 'avoidedWorkplaceAbsenceSaving',
        title: 'Avoided Workplace Absence Savings',
        tabContainerElem: () => {
          return (
            <View key={'imageSaving'}>
              <CostSavingTemplates
                actionCode={stateData.actionCode}
                savingType={SavingType.avoidedWorkplaceAbsenceSaving}
                searchText={searchString}
                filterVisible={stateData.filterVisible}
                onActionPerformed={onActionPerformed}
              />
            </View>
          );
        },
      },
    ];
    return orderDetailTabView;
  };
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const onActionPerformed = (code: string, data?: any) => {
    switch (code) {
      case COMMON_ACTION_CODES.CLOSE:
      case COMMON_ACTION_CODES.CANCEL:
      case COMMON_ACTION_CODES.SAVE:
        setStateData(prev=> {
          return {
            ...prev,
            filterVisible: false,
            actionCode: '',
            showBadge: false
          }
        })
        break;
      case COMMON_ACTION_CODES.FILTER_CHANGED:
        const showBadge = [COST_TYPE_CODE.MARKET_COST,COST_TYPE_CODE.OUR_COST].includes(data?.type);
        setStateData(prev=> {
          return {
            ...prev,
            showBadge: showBadge,
          }
        })
        break;
      default:
        break;
    }
  }
  const AutomationTopBar: JSX.Element = (
    <View width={'93.5%'}>
      <TableTopBar
        isDividerNotVisible={true}
        alignSearchBarFlexEnd={true}
        title={''}
        searchBarWidth={180}
        searchBarMinWidth={180}
        hideSearchBar={true}
        buttonList={[
          {
            btnText: '',
            id: 3,
            size: 'sm',
            colorScheme: '',
            textColor: '',
            variant: '',
            backgroundColor: 'transparent',
            borderColor: 'white',
            leftIcon: (
              <Feather name="filter" size={20} color={Colors.Custom.Gray400} />
            ),
            badgeView: stateData.showBadge ? <View
              style={{
                width: 12,
                height: 12,
                backgroundColor: Colors.error[500],
                position: 'absolute',
                top: 4,
                right: 22,
                zIndex: 11
              }}
              borderRadius={'50%'}
            ></View> : <></>,
            style: {
              borderWidth: 0
            },
            btnClick() {
              setStateData((prev)=> {
                return {
                  ...prev,
                  filterVisible: !prev.filterVisible,
                }
              })
            },
          },
          {
            btnText: 'Import',
            id: 2,
            size: 'sm',
            colorScheme: 'primary',
            textColor: Colors.secondary['900'],
            variant: '',
            backgroundColor: Colors.secondary['100'],
            borderColor: Colors.secondary['200'],
            leftIcon: (
              <Icon
                as={AntIcon}
                color={'#6941C7'}
                name={'download'}
                size="3"
                width={10}
                height={10}
              />
            ),
            btnClick() {
              setStateData((prev)=> {
                return {
                  ...prev,
                  actionCode: COMMON_ACTION_CODES.ADD
                }
              })
            },
          },
        ]}
      />
    </View>
  );

  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab);
  };

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    return AutomationTopBar;
  };

  useEffect(() => {
    const tagsList = tagsTabList();
    tagsList.some((tab: any) => {
      if (location?.pathname?.indexOf(tab.path) != -1) {
        setSelectedTab(tab);
      }
    });
  }, []);
  const searchBar = () => {
    return  <SearchBar
      width={320}
    placeholderText="Search Template name or Description"
      onChange={debounce(handleSearchChange, 500)}
  />
  }
  const searchAndClose = () => {
    return (
      <>
        <Pressable onPress={() => {
          if (stateData.showSearchBar) {
            handleSearchChange('')
          }
          setStateData(prev => {
            return {
              ...prev,
              showSearchBar: !prev.showSearchBar,
            }
          })
        }} > {!stateData.showSearchBar ? <Feather
          name="search"
          size={24}
          color={Colors.Custom.Gray500}
        /> : <Feather
          name="x"
          size={24}
          color={Colors.Custom.Gray500}
        />}</Pressable>
      </>
    )
  }
  return (
    <View>
      <View
        style={[
          commonBodyContentContainer.pageContentContainer,
        ]}
      >
        <CustomTabsView
          searchAndClose={searchAndClose()}
          showSearchBar={stateData.showSearchBar}
          searchBar={searchBar()}
          isAddMaxTab={true}
          contentTopMargin={0}
          leftOffset={1000}
          minScreenSupportWidth={1600}
          canNavigate={true}
          heading="costTemplate"
          showHeading
          basePath={basePath}
          tabsList={tagsTabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
          rightView={() => {
            return getSelectedTopBar(selectedTab);
          }}
          bottomOffset={150}
          onTabClick={(tabItem) => {
            onTabClick(tabItem);
          }}
        />
      </View>
    </View>
  );
};

export default CostTemplatesMainView;
