import {Gauge, GaugeConfig, Pie, PieConfig} from '@ant-design/plots';
import {Pressable, Text, View} from 'native-base';
import React, {memo, useState} from 'react';
import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';
import {graphColorList} from '../../../../RightSideContainer/Analytics/EmployerAnalytics/graphColors';
import {capitalizeText} from '../../../../RightSideContainer/ContentManagement/CampaignManagement/Helper';
import JourneyGauge from '../../../../RightSideContainer/Journeys/Analytics/JourneyGauge';
import GaugeSvg from '../../../Svg/GaugeSvg';
import TaskWidgetIcon from '../../../Svg/TaskWidgetIcon';
import {ITaskOverViewWidgetData} from '../interface';
import {getIconByCode} from './helper';
import {getFormattedStatusListByPriority, PRIORITY_LIST} from './UtilsConst';

const TaskOverViewWidget = (props: {widgetData: ITaskOverViewWidgetData}) => {
  const {widgetData} = props;
  const [stateData, setStateData] = useState({
    selectedPriority: PRIORITY_LIST[0],
  });
  const formattedStatusListByPriority = getFormattedStatusListByPriority(
    widgetData,
    stateData.selectedPriority?.code
  );
  const gaugeConfig: GaugeConfig = {
    percent: parseFloat((widgetData?.completionRate/100)?.toFixed(2)),
    height: 30,
    width: 33,
    renderer: 'svg',
    range: {
        color: '#12B76A',
        width: 4,
    },
    gaugeStyle: {
      lineCap: 'round'
    },
    indicator: {
      pointer: {
        style: {
            stroke: '#D0D6E1',
            lineWidth: 2
        },
    },
        pin: undefined
    },
    axis: {
        label: {
            formatter(v) {
                return '';
            },
        },
        subTickLine: null,
        tickLine: null
    },

}
  const config: PieConfig = {
    // appendPadding: 10,
    data: formattedStatusListByPriority,
    angleField: 'count',
    colorField: 'name',
    appendPadding: 10,
    radius: 1,
    innerRadius: 0.6,
    height: 180,
    color: graphColorList,
    label: false,
    legend: false,
    tooltip: {
      formatter: (data) => {
        return {
          name: capitalizeText(data?.name) || '',
          value: data?.count,
        };
      },
    },
    renderer: 'canvas',
    statistic: {
      title: undefined,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: '',
      },
    },
  };
  return (
    <View style={styles.container} flex={1}>
      <View style={styles.rowSpaceBetween}>
        <View style={styles.boxContainer}>
          <Text size={'xsSemibold'} color={'#6F7A90'}>
            TOTAL TASKS
          </Text>
          <View style={styles.rowAlignCenter}>
            <TaskWidgetIcon />
            <Text
              size={'xslBold'}
              color={'#3A485F'}
              style={styles.taskCountText}
            >
              {widgetData.totalTaskCount}
            </Text>
          </View>
        </View>
        <View style={styles.boxContainer}>
          <Text size={'xsSemibold'} color={'#6F7A90'}>
            TASK COMPLETION RATE
          </Text>
          <View style={styles.rowAlignCenter}>
            <View>
              <Gauge {...gaugeConfig} />
            </View>
            <Text
              size={'xslBold'}
              color={'#3A485F'}
              style={styles.completionRateText}
            >
              {widgetData?.completionRate?.toFixed(2)}%
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.pieChartContainer}>
        <View style={styles.pieChartWrapper}>
          {formattedStatusListByPriority?.length ? <Pie {...config} /> : <></>}
        </View>
        <View flex={0.6}>
          <View style={styles.prioritySelector}>
            {PRIORITY_LIST.map((priority) => {
              return (
                <Pressable
                  style={[
                    styles.priorityButton,
                    stateData.selectedPriority.code === priority.code
                      ? styles.selectedPriorityButton
                      : {},
                  ]}
                  key={priority.code}
                  onPress={() => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        selectedPriority: priority,
                      };
                    });
                  }}
                >
                  {getIconByCode(priority.code)}
                  <Text
                    color={
                      stateData.selectedPriority.code === priority.code
                        ? '#344054'
                        : '#A7AFBE'
                    }
                    style={styles.priorityButtonText}
                  >
                    {priority.text}
                  </Text>
                </Pressable>
              );
            })}
          </View>
          <View style={styles.statusList}>
            {formattedStatusListByPriority?.map((statusAndCount) => {
              return (
                <View
                  style={styles.statusItem}
                  key={statusAndCount?.name}
                >
                  <View style={styles.rowAlignCenter}>
                    <View
                      style={[
                        styles.statusDot,
                        {
                          backgroundColor:
                            statusAndCount.name == 'pending'
                              ? '#80A4D5'
                              : statusAndCount.name == 'missed'
                              ? '#BFD7F6'
                              : statusAndCount.name == 'completed'
                              ? '#8DE3D4'
                              : '',
                        },
                      ]}
                      borderRadius={'50%'}
                    ></View>
                    <Text color={'#3A485F'} size={'smMedium'}>
                      {capitalizeText(statusAndCount.name)}
                    </Text>
                  </View>
                  <Text color={'#3A485F'} size={'xlSemibold'}>
                    {statusAndCount.count}
                  </Text>
                </View>
              );
            })}
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 12,
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rowAlignCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  boxContainer: {
    borderRadius: 8,
    borderWidth: 0.5,
    borderColor: '#D0D6E1',
    borderStyle: 'solid',
    padding: 16,
    flex: 0.49,
  },
  taskCountText: {
    marginTop: 0.5,
    marginLeft: 10.5,
  },
  completionRateText: {
    marginTop: 0.5,
    marginLeft: 10.5,
  },
  pieChartContainer: {
    borderRadius: 8,
    borderWidth: 0.5,
    borderColor: '#D0D6E1',
    borderStyle: 'solid',
    paddingVertical: 16,
    paddingLeft: 16,
    paddingRight: 2,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 12,
  },
  pieChartWrapper: {
    flex: 0.4,
    height: 'auto',
  },
  prioritySelector: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    borderRadius: 4,
    height: 32,
    backgroundColor: '#F6F7F8',
    justifyContent: 'space-between',
    padding: 2,
    marginBottom: 24,
  },
  priorityButton: {
    height: 28,
    width: '33%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  selectedPriorityButton: {
    borderWidth: 0.5,
    borderColor: '#CED4DD',
    borderStyle: 'solid',
    borderRadius: 4,
    backgroundColor: '#fff',
  },
  priorityButtonText: {
    marginLeft: 4,
  },
  statusList: {
    width: '100%',
    paddingRight: 24,
    paddingLeft: 8,
  },
  statusItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 14,
  },
  statusDot: {
    width: 8,
    height: 8,
    marginRight: 4,
  },
});

export default memo(TaskOverViewWidget);
