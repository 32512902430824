import { filter, find } from 'lodash';
import { useContext } from 'react';
import { IMlov, IMlovByCategory, IMlovResponse } from '../Interfaces';
import { CommonDataContext } from './../context/CommonDataContext';
import { ICTMlovRespData } from './interfaces';
import {Member_Screen_Tab_Code} from '../screens/MembersScreens/MemberScreenConst';
import { MLOV_CATEGORY } from '../constants';

export const getMlovByCategory = (mlovData: IMlovResponse, accountUuid?: string): IMlovByCategory => {
  const mlovByCategory: IMlovByCategory = {};
  if (mlovData) {
    const mlovList = mlovData?.mlovs || [];
    const mlovCategoryList = mlovData?.mlovCategories || [];
    const accountMlovs = mlovData?.accountMlovsDeprecated || [];
    mlovCategoryList.forEach((singleMlovCategory) => {
      const allMlovForCurrentCategory = filter(mlovList, {
        categoryId: singleMlovCategory.id,
      });
      const accountMlovList = accountMlovs.filter((item) => {
        return item.categoryId === singleMlovCategory.id && (!item.tenantId || item.tenantId === accountUuid)
      });
      mlovByCategory[singleMlovCategory.category] = accountMlovList.length ? accountMlovList : allMlovForCurrentCategory;
    });
  }
  return mlovByCategory;
};

export const getCloudTelePhonyMlovByCategory = (
  mlovData: ICTMlovRespData
): any => {
  const mlovByCategory: any = {};
  if (mlovData?.mlovCategories) {
    const mlovCategoryList = mlovData?.mlovCategories || [];
    mlovCategoryList.forEach((singleMlovCategory) => {
      mlovByCategory[singleMlovCategory.category] = singleMlovCategory.mlovs;
    });
  }
  return mlovByCategory;
};

export const getMlovListFromCategory = (
  mlovCategoryList: IMlovByCategory,
  categoryName: string,
  returnIsDeleted = false
): IMlov[] => {

  const result = mlovCategoryList[categoryName];

  if (!returnIsDeleted) {
    return result?.filter((mlov) => !mlov.isDeleted);
  }
  return result;
};

export const getMlovListByCategory = (categoryName: string, returnIsDeleted = true): IMlov[] => {
  const mlovData = useContext(CommonDataContext);

  let list = mlovData.MLOV[categoryName] || mlovData.CARE_STUDIO_MLOV[categoryName];
  if (!returnIsDeleted) {
    list = list?.filter((mlov) => !mlov?.isDeleted);
  }
  return list;
};

export const getCareStudioMlovListByCategory = (
  categoryName: string,
  returnIsDeleted = true
): IMlov[] => {
  const mlovData = useContext(CommonDataContext);
  let list = mlovData.CARE_STUDIO_MLOV[categoryName];
  if (!returnIsDeleted) {
    list = list?.filter((mlov) => !mlov?.isDeleted);
  }
  return list;
};

export const getMlovIdFromCode = (
  mlovList: IMlov[],
  mlovCode: string
): string => {
  const mlovObj = find(mlovList, { code: mlovCode });

  return mlovObj?.id || '';
};

export const getMlovIdFromCodeCategory = (
  code: string,
  category: string,
  mlovs: IMlov[],
) => {
  const matchedData = mlovs?.filter((mlov) => {
    return mlov?.code === code && mlov?.mlovCategory?.category === category;
  });
  if (matchedData?.length > 0) {
    return matchedData[0].id;
  }
  return '';
};

// export const getMlovIdFromCodeS= (code: string, FormSource: any) => {
//   const matchedData = FormSource?.filter((form: any) => {
//     return form?.code === code;
//   });
//   if (matchedData?.length > 0) {
//     return matchedData[0].id;
//   }
//   return '';
// };

export const getMlovCodeIdObj = (
  mlovList: IMlov[]
): { [index: string]: string } => {
  const mlovObj: { [index: string]: string } = {};
  mlovList?.forEach((mlov) => {
    const code = mlov.code;
    const id = mlov.id;
    mlovObj[code] = id;
  });
  return mlovObj;
};

export const getMlovIdCodeObj = (
  mlovList: IMlov[]
): { [index: string]: string } => {
  const mlovObj: { [index: string]: string } = {};
  mlovList?.forEach((mlov) => {
    const code = mlov.code;
    const id = mlov.id;
    mlovObj[id] = code;
  });
  return mlovObj;
};

export const getMlovCodeFromId = (
  mlovList: IMlov[],
  mlovId: string
): string => {
  const mlovObj = find(mlovList, { id: mlovId });
  return mlovObj?.code || '';
};

export const getMlovId = (
  mlovCategoryList: IMlovByCategory,
  categoryName: string,
  mlovCode: string
): string => {
  const mlovList =
    getMlovListFromCategory(mlovCategoryList, categoryName) || [];

  return getMlovIdFromCode(mlovList, mlovCode) || '';
};

export const getContactTypeId = (
  selectedTab: string
): string => {
  let contactTypeCode = selectedTab;
  if (selectedTab === Member_Screen_Tab_Code.MY_PATIENT) {
    contactTypeCode = Member_Screen_Tab_Code.PATIENTS
  }
  const commonData = useContext(CommonDataContext);
  const defaultContactTypes = commonData.defaultContactTypes?.customContactTypes;
  const defaultContactType = find(defaultContactTypes, { code: contactTypeCode });

  return defaultContactType?.id || '';
};

export const getContactTypeIdInDefaultContactTypes = (
  defaultContactTypes: any,
  contactTypeCode: string
): string => {
  const defaultContactType = find(defaultContactTypes, { code: contactTypeCode });

  return defaultContactType?.id || '';
};

export const getMlovValue = (
  mlovCategoryList: IMlovByCategory,
  categoryName: string,
  mlovCode: string
): string => {
  const mlovList =
    getMlovListFromCategory(mlovCategoryList, categoryName) || [];
  return getMlovValueFromCode(mlovList, mlovCode) || '';
};

export const getMlovById = (mlovId: string, categoryCode: string): IMlov => {
  const mlovList = getMlovListByCategoryCode(categoryCode);
  const mlovObj = find(mlovList, { id: mlovId });
  return mlovObj || ({} as IMlov);
};

export const getMlovListByCategoryCode = (categoryCode: string): IMlov[] => {
  const mlovs = getAllMlovs();
  return mlovs[categoryCode];
};

export const getCareStudioMlovListByCategoryCode = (categoryCode: string): IMlov[] => {
  const mlovs = getAllCareStudioMlovs();
  return mlovs[categoryCode];
};

export const getMlovValueFromId = (
  mlovList: IMlov[],
  mlovId: string
): string => {
  const mlovObj = find(mlovList, { id: mlovId });
  return mlovObj?.value || '';
};

export const getMlovValueFromCode = (
  mlovList: IMlov[],
  mlovCode: string
): string => {
  const mlovObj = find(mlovList, { code: mlovCode });
  return mlovObj?.value || '';
};

export const getAllMlovs = (): IMlovByCategory => {
  const commonData = useContext(CommonDataContext);
  return commonData.MLOV;
};

export const getAllCareStudioMlovs = (): IMlovByCategory => {
  const commonData = useContext(CommonDataContext);
  return commonData.CARE_STUDIO_MLOV;
};

export const getMlovIdFromCodeAndCategory = (
  code: string,
  categoryName: string,
  isCareStudio: boolean
) => {
  const commonData = useContext(CommonDataContext);
  const allMlovList = isCareStudio
    ? commonData.CARE_STUDIO_MLOV
    : commonData.MLOV;
  const mlovList = getMlovListFromCategory(allMlovList, categoryName);
  return getMlovIdFromCode(mlovList, code);
};

export const getMlovValueFromIdAndCategory = (
  id: string,
  categoryName: string,
  isCareStudio: boolean
) => {
  const commonData = useContext(CommonDataContext);
  const allMlovList = isCareStudio
    ? commonData.CARE_STUDIO_MLOV
    : commonData.MLOV;
  const mlovList = getMlovListFromCategory(allMlovList, categoryName);
  return getMlovValueFromId(mlovList, id);
};

export const getMlovObjectFromCode = (
  code: string,
  mlovs: IMlov[]
): IMlov | undefined => {
  const matchedData = mlovs?.filter((mlov) => {
    return mlov?.code === code;
  });
  if (matchedData?.length > 0) {
    return matchedData[0];
  }
};

export const getMlovObjectFromId = (
  id: string,
  mlovs: IMlov[]
): IMlov | undefined => {
  const matchedData = mlovs?.filter((mlov) => {
    return mlov?.id === id;
  });
  if (matchedData?.length > 0) {
    return matchedData[0];
  }
};
