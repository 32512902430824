import React from 'react';
import {View} from 'native-base';
import TitleSubtitleView from '../../../../../common/TitleSubtitleView/TitleSubtitleView';
import ProductLayoutView from './ProductLayoutView/ProductLayoutView';
import {Colors} from '../../../../../../styles';
import {Dimensions} from 'react-native';

const ProductWidgetView = () => {
  const height = Dimensions.get('window').height - 70;

  return (
    <View backgroundColor={'white'} flex={1}>
      {/* <View>
        <TitleSubtitleView
          title={'createNewWidget'}
          subtitleLabelId="servicesManagementSubtitle"
        />
      </View> */}
      <View
        style={{
          backgroundColor: Colors.Custom.Gray50,
          height: height,
          overflow: 'scroll',
        }}
      >
        <ProductLayoutView />
      </View>
    </View>
  );
};
export default ProductWidgetView;
