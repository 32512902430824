import {ORDER_SUMMARY_DATE_FROMATED} from '../../../../../constants';
import {ConvertUnixTimestamp} from '../../../../../utils/DateUtils';
import {ISubscription} from '../../MembershipAndSubscriptions/MembershipOrders/interfaces';
import {
  IBillingSubscriptionDisplayList,
  IOrderApiData,
  IOrderContact,
  IOrderTableDisplayData,
  IStripeCharges,
  IStripeChargesDisplayList,
  ISubscriptionDetails,
} from './interfaces';

export const getFormattedOrderListData = (
  orderResList: IOrderApiData[],
  type: string,
  contactDataListObj: {
    [index: string]: IOrderContact;
  }
): IOrderTableDisplayData[] => {
  const orderDisplayList: IOrderTableDisplayData[] = [];
  orderResList.forEach((singleOrder: IOrderApiData) => {
    const orderAttribute = singleOrder.attributes;
    let contactEmailStr = singleOrder?.attributes?.email || '';
    if (contactEmailStr) {
      const tempList = contactEmailStr.split('@');
      const uuidStr = tempList[0];
      if (contactDataListObj?.[uuidStr] && contactDataListObj?.[uuidStr]?.id) {
        contactEmailStr = contactDataListObj[uuidStr]?.email || '';
      }
    }
    const tempObject = {
      number: orderAttribute.number,
      order_at: orderAttribute.created_at,
      state: orderAttribute.state,
      payment_state: orderAttribute.payment_state,
      shipment_state: orderAttribute.shipment_state,
      email: contactEmailStr,
      total: orderAttribute.display_total,
      rawOrderDataobj: singleOrder,
    };
    //// Will remove this code once the  getOrderByCategory (membership type) changes done in backend
    orderDisplayList.push(tempObject);
  });
  return orderDisplayList;
};

export const getFormattedMembershipOrderList = (
  data: ISubscription[]
): any[] => {
  const chargesDisplayList: any[] = [];
  data?.forEach((subscription: ISubscription) => {
    // const tempObject: ISubscriptionDisplayList = {
    //   id: charge.id,
    //   customer: {
    //     name: charge?.contact?.Contacts?.name || charge.billing_details.email,
    //     id: charge?.contact?.Contacts?.id,
    //   },
    //   date: ConvertUnixTimestamp(
    //     charge.created,
    //     // 'DD-MMM, LT'
    //     ORDER_SUMMARY_DATE_FROMATED
    //   ),
    //   amount: `${charge.currency.toUpperCase()} ${charge.amount / 100}`,
    //   status: charge.status,
    //   rowData: charge,
    // };
    const tempObject: any = {
      id: subscription.id,
      customer: {
        name:
          subscription?.customer?.name ||
          subscription?.customer?.email ||
          subscription?.id,
        id: subscription?.customer?.id,
      },
      product: {
        name: subscription?.plan?.product?.name || '',
        id: subscription?.plan?.product?.id
      },
      status: subscription.status.toUpperCase(),
      date: ConvertUnixTimestamp(
        subscription?.created,
        // 'DD-MMM, LT'
        ORDER_SUMMARY_DATE_FROMATED
      ),
      rowData: subscription,
    };
    chargesDisplayList.push(tempObject);
  });
  return chargesDisplayList;
};

export const getFormattedMembershipOrderListOneTime = (
  data: IStripeCharges[]
): IStripeChargesDisplayList[] => {
  const chargesDisplayList: IStripeChargesDisplayList[] = [];
  data?.forEach((charge: IStripeCharges) => {
    const tempObject: IStripeChargesDisplayList = {
      id: charge.id,
      customer: {
        name: charge?.contact?.Contacts?.name || charge.billing_details.email,
        id: charge?.contact?.Contacts?.id,
      },
      date: ConvertUnixTimestamp(
        charge.created,
        // 'DD-MMM, LT'
        ORDER_SUMMARY_DATE_FROMATED
      ),
      amount: `${charge.currency.toUpperCase()} ${charge.amount / 100}`,
      status: charge.status,
      rowData: charge,
    };
    chargesDisplayList.push(tempObject);
  });
  return chargesDisplayList;
};

export const getFormatteBillingOrderList = (
  data: ISubscriptionDetails[]
): IBillingSubscriptionDisplayList[] => {
  const chargesDisplayList: IBillingSubscriptionDisplayList[] = [];
  data?.forEach((charge: ISubscriptionDetails) => {
    const tempObject: IBillingSubscriptionDisplayList = {
      id: charge.id,
      customer: {
        email: charge?.customer?.email,
      },
      name: charge.plan?.product?.name,
      date: ConvertUnixTimestamp(
        charge.created,
        // 'DD-MMM, LT'
        ORDER_SUMMARY_DATE_FROMATED
      ),
      latestAmount: `${charge.latest_invoice?.amount_due / 100}`,
      upcomingAmount: `${charge.upcoming_invoice?.amount_due / 100}`,
      upcomingInvoice: charge.upcoming_invoice,
      status: charge.status,
      rowData: charge,
    };
    chargesDisplayList.push(tempObject);
  });
  return chargesDisplayList;
};

export const getFormattedOneTimeOrderList = (
  data: IStripeCharges[]
): IStripeChargesDisplayList[] => {
  const chargesDisplayList: IStripeChargesDisplayList[] = [];
  data?.forEach((charge: IStripeCharges) => {
    const tempObject: IStripeChargesDisplayList = {
      id: charge.id,
      customer: {
        name: charge?.contact?.Contacts?.name || charge.billing_details.email,
        id: charge?.contact?.Contacts?.id,
      },
      date: ConvertUnixTimestamp(
        charge.created,
        // 'DD-MMM, LT'
        ORDER_SUMMARY_DATE_FROMATED
      ),
      amount: `${charge.currency.toUpperCase()} ${charge.amount / 100}`,
      status: charge.status,
      rowData: charge,
    };
    chargesDisplayList.push(tempObject);
  });
  return chargesDisplayList;
};
