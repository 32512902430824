import {Button, Skeleton, View} from 'native-base';
import {useNavigate} from 'react-router-dom';
import {Colors} from '../../../styles';
import {NoDataFound} from '../NoDataFound';
import {IRecentItem, IRecentItemData} from './interface';
import { withMiniContactViewHOC } from '../../MiniContactViewHOC';
const RecentPatientList = (props: IRecentItemData) => {
  const {recent_items, isPopover} = props;
  const loading = false;

  const navigate = useNavigate();
  const handleOnClick = (contact: any) => {
    props?.navigateOrOpenContactIdDrawer?.(contact.id);
  };
  return (
    <View style={{marginTop: 10}}>
      {loading ? (
        <Skeleton.Text lines={3} />
      ) : recent_items && recent_items.length > 0 ? (
        recent_items.map((recent_item: IRecentItem, index: number) => {
          return (
            <Button
              key={index}
              onPress={() => {
                handleOnClick(recent_item.recentItemsMember);
              }}
              _hover={{
                backgroundColor: 'transparent',
              }}
              _text={{
                color: isPopover ? Colors.secondary['500'] : '#fff',
                size: 'mdMedium',
              }}
              variant="ghost"
              padding={0}
              style={{
                marginVertical: 8,
                justifyContent: 'flex-start',
              }}
            >
              {recent_item.recentItemsMember?.name}
            </Button>
          );
        })
      ) : (
        <NoDataFound
          textStyle={{
            fontSize: 14,
            color: Colors.primary['500'],
          }}
          isShowIcon={true}
          displayString="noRecentItems"
          iconColor={Colors.primary['500']}
        />
      )}
    </View>
  );
};
export default withMiniContactViewHOC(RecentPatientList);
