import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles';
const baseMargin = {
  marginTop: 24,
  marginLeft: 24,
  marginRight: 24,
  marginBottom: 24,
};
export const styles = StyleSheet.create({
  titleWrapper: {
    // marginHorizontal: 22,
    // marginVertical: 12,
  },
  loadingView: {...baseMargin},
  TaxonomydetailsContainer: {
    shadowColor: 'black',
    shadowOffset: {width: 2, height: 2},
    shadowOpacity: 0.2,
    shadowRadius: 8,
    elevation: 10,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 1,
    overflow: 'hidden',
    marginRight: 8,
    marginBottom: 24,
    marginTop: 8,
  },
});
