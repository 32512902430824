import {ColumnsType} from 'antd/lib/table';
import {Text, Tooltip} from 'native-base';
import {getUSLocaleDateString} from '../../../common/Notifications/NotificationsUtils';
import {ITemplateCategory} from '../EmailTemplates/interfaces';
import {ISMSTemplateTableColumnArgs, SingleSMSTemplate} from './interfaces';
import {SMSTemplatesTableActions} from './SMSTemplatesTableActions';
import {onFilter, sorter} from './SMSTemplatesUtils';

export const getSMSTemplatesTableColumns = (
  args: ISMSTemplateTableColumnArgs
) => {
  const {actionFn, categories, defaultFilteredValues, defaultSortOrder} = args;
  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      fixed: 'left',
      render(name: string) {
        return (
          <Tooltip label={name} placement="top">
            <Text>{name}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: categories,
      filterSearch: true,
      defaultFilteredValue: defaultFilteredValues,
      width: '20%',
      onFilter: onFilter,
      render(category: ITemplateCategory) {
        return <Text>{category?.name}</Text>;
      },
    },
    {
      title: 'Default',
      key: 'isDefault',
      dataIndex: 'isDefault',
      width: '10%',
      render(isDefault: boolean) {
        return <Text>{isDefault ? 'Yes' : 'No'}</Text>;
      },
    },
    {
      title: 'Last Updated',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      width: '20%',
      sorter: sorter,
      defaultSortOrder: defaultSortOrder,
      render(lastUpdated: string) {
        const date = new Date(lastUpdated).toLocaleDateString('en-US', {
          year: 'numeric',
          day: 'numeric',
          month: 'long',
        });
        return <Text>{date}</Text>;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '20%',
      fixed: 'right',
      render(record: SingleSMSTemplate) {
        return (
          <SMSTemplatesTableActions
            record={record}
            onActionPerformed={actionFn}
          />
        );
      },
    },
  ];

  return columns;
};
