import React from "react";

function LabIcon() {
  return (
    <svg width="22" height="23" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.48851 18.4667H14.8237C15.4489 18.4667 15.9839 18.2563 16.4287 17.8355C16.8735 17.4147 17.108 16.8797 17.132 16.2305V16.1583C17.132 15.5813 16.9757 15.0402 16.6631 14.5353L10.9644 6.925V1.55091H11.7219C11.9142 1.55091 12.0946 1.46675 12.2629 1.29844C12.4312 1.13012 12.5153 0.949784 12.5153 0.757422C12.5153 0.565061 12.4312 0.390734 12.2629 0.23444C12.0946 0.0781467 11.9142 0 11.7219 0H5.59034C5.39797 0 5.21764 0.0781467 5.04932 0.23444C4.881 0.390734 4.79685 0.565061 4.79685 0.757422C4.79685 0.949784 4.881 1.13012 5.04932 1.29844C5.21764 1.46675 5.39797 1.55091 5.59034 1.55091H6.34776V6.85287L0.721192 14.4632C0.408605 14.9681 0.252311 15.4851 0.252311 16.0141L0.180176 16.1583C0.204221 16.8076 0.444672 17.3546 0.90153 17.7994C1.35839 18.2443 1.88738 18.4667 2.48851 18.4667ZM6.34776 9.44975L6.7445 9.01693C7.32159 8.1994 7.70631 7.27366 7.89867 6.23972V1.55091H9.41352V6.16758C9.62992 7.2977 10.0146 8.21142 10.5677 8.90873L10.9644 9.37761L12.5153 11.4695H4.79685L6.34776 9.44975ZM1.94749 15.4009L4.03942 12.5516V13.0926H13.6695L15.3647 15.4009C15.4128 15.449 15.4849 15.6774 15.5811 16.0862C15.5811 16.3026 15.503 16.495 15.3467 16.6633C15.1904 16.8316 15.016 16.9158 14.8237 16.9158H2.48851C2.29615 16.9158 2.12182 16.8316 1.96553 16.6633C1.80924 16.495 1.73109 16.3026 1.73109 16.0862C1.73109 15.7736 1.80322 15.5452 1.94749 15.4009Z" fill="#C2C2C2"/>
    </svg>
  );
}

export default LabIcon;
