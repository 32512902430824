import {useLazyQuery} from '@apollo/client';
import {Table} from 'antd';
import React from 'react';
import {Dimensions, View} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {UserQueries} from '../../../../../services';
import {getAccountId} from '../../../../../utils/commonUtils';
import {TableWrapper} from '../../../../common/TableWrapper';
import {CMSLoading} from '../../../ContentManagement/CMSLoading';
import AddOrUpdateCommunicationType from './AddOrUpdateCommunicationType';
import {getCommunicationTypesTableColumns} from './CommunicationTypesTableHelper';
import {
  getCommunicationTypes,
  getFormattedCommunicationTypeData,
} from './CommunicationTypesUtils';
import DeleteCommunicationTypesModal from './DeleteCommunicationTypesModal';
import {
  ICommunicationType,
  ICommunicationTypesTableProps,
  ICommunicationTypesTableState,
} from './interfaces';

const CommunicationTypesTable = (props: ICommunicationTypesTableProps) => {
  const {searchString, selectedData, viewCode, onActionPerformed} = props;
  const [state, setState] = React.useState<ICommunicationTypesTableState>({
    data: [],
    loading: false,
    page: 1,
    pageSize: 10,
    sort: 'desc',
    total: 0,
  });
  const {height} = Dimensions.get('window');
  const accountId = getAccountId();

  const [getAccountUsers] = useLazyQuery(UserQueries.GET_ACCOUNT_USERS, {
    fetchPolicy: 'no-cache',
  });

  const getCommunicationTypesData = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const communicationTypesPromise = getCommunicationTypes({
        page: state.page,
        pageSize: state.pageSize,
        searchString: searchString,
        sort: state.sort,
      });
      const accountUsersPromise = getAccountUsers();
      Promise.all([communicationTypesPromise, accountUsersPromise]).then(
        (response) => {
          const first = response[0];
          const second = response[1];
          const data: ICommunicationType[] =
            first?.data?.communicationTypes || [];
          const total = first?.data?.total || 0;
          const accountUsers = second?.data?.accountUsers || [];
          const result = getFormattedCommunicationTypeData({
            communicationTypes: data,
            accountUsers,
          });
          setState((prev) => {
            return {
              ...prev,
              data: result,
              total,
              loading: false,
            };
          });
        }
      );
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          data: [],
        };
      });
    }
  };

  React.useEffect(() => {
    getCommunicationTypesData();
  }, []);

  React.useEffect(() => {
    getCommunicationTypesData();
  }, [state.page, state.pageSize, searchString]);

  return (
    <View>
      {state.loading && <CMSLoading />}

      {!state.loading && (
        <TableWrapper
          pagination={{
            current: state.page,
            pageSize: state.pageSize,
            total: state.total,
            onChange(page, pageSize) {
              setState((prev) => {
                return {
                  ...prev,
                  page: page,
                  pageSize: pageSize,
                };
              });
            },
          }}
        >
          <Table
            dataSource={state.data}
            rowClassName={(record, index) =>
              index % 2 == 0 ? 'table-row-light' : ''
            }
            rowKey={(row, index) => `${index}_${row.id}`}
            scroll={{y: height - 280}}
            pagination={false}
            columns={getCommunicationTypesTableColumns({onActionPerformed})}
            onRow={(row) => {
              return {
                onClick: () => {
                  onActionPerformed(COMMON_ACTION_CODES.EDIT, row);
                },
              };
            }}
          />
        </TableWrapper>
      )}

      {viewCode === COMMON_ACTION_CODES.EDIT && (
        <AddOrUpdateCommunicationType
          isOpen={viewCode === COMMON_ACTION_CODES.EDIT}
          onClose={() => {
            onActionPerformed('', {} as ICommunicationType);
          }}
          onSave={(data) => {
            onActionPerformed('', {} as ICommunicationType);
            getCommunicationTypesData();
          }}
          communicationTypeId={selectedData.id}
        />
      )}

      {viewCode === COMMON_ACTION_CODES.ADD && (
        <AddOrUpdateCommunicationType
          isOpen={viewCode === COMMON_ACTION_CODES.ADD}
          onClose={() => {
            onActionPerformed('', {} as ICommunicationType);
          }}
          onSave={(data) => {
            onActionPerformed('', {} as ICommunicationType);
            getCommunicationTypesData();
          }}
        />
      )}

      {viewCode === COMMON_ACTION_CODES.DELETE && (
        <DeleteCommunicationTypesModal
          isOpen={viewCode === COMMON_ACTION_CODES.DELETE}
          data={selectedData}
          onClose={() => {
            onActionPerformed('', {} as ICommunicationType);
          }}
          onConfirm={() => {
            onActionPerformed('', {} as ICommunicationType);
            getCommunicationTypesData();
          }}
        />
      )}
    </View>
  );
};

export default CommunicationTypesTable;
