import React, { useContext, useEffect } from 'react';
import { Drawer, Skeleton } from 'antd';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';
import { Colors } from '../../../styles';
import {
  BUTTON_TYPE,
} from '../../../constants';
import { IContact } from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { useLazyQuery } from '@apollo/client';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import { getAccountUUID } from '../../../utils/commonUtils';
import { LeadQueries } from '../../../services';
import { View } from 'react-native';
import InfoSection from '../CareProgramStepOutreach/components/InfoSection';
import { ContactRelationCommunication } from '../CareProgramStepOutreach/components/Communication';
import CarePlan from '../../PersonOmniView/MiddleContainer/CarePlan/CarePlan';
import { getFormDataFromLeadData } from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { useIntl } from 'react-intl';

interface ICarePlanGoalsDrawerProps {
  onClose: (syncParent: boolean) => void;
  contactId: string;
}

interface ICarePlanGoalsDrawerStateProps {
  contactData: IContact | undefined;
  contactDataLoading: boolean;
  consentData?: any[];
  formattedContactData?: any;
}

const CarePlanGoalsDrawer = (props: ICarePlanGoalsDrawerProps) => {
  const { contactId, onClose } = props;
  const accountUuid = getAccountUUID();
  const mlovData = useContext(CommonDataContext);
  const intl = useIntl();

  const [componentState, setComponentState] = React.useState<ICarePlanGoalsDrawerStateProps>({
    contactData: undefined,
    contactDataLoading: true,
    consentData: [],
    formattedContactData: {},
  });

  const [getContactsFromIds] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_BY_CONTACT_IDS,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [getContactConsentByIds] = useLazyQuery(
    LeadQueries.GetConsentsByContactIds,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const fetchContactData = async () => {
    setComponentState((prev) => ({
      ...prev,
      contactDataLoading: true,
    }))
    try {
      const promiseList: Promise<any>[] = [];
      promiseList.push(
        getContactsFromIds({
          variables: {
            contactIds: [contactId],
          },
        })
      );

      const variables = {
        accountUuid: accountUuid,
        contactIds: {
          _in: [contactId],
        },
      };

      promiseList.push(
        getContactConsentByIds({
          variables: variables,
        })
      );

      const [contactDataResponse, consentResponseObj] = await Promise.all(promiseList);
      const consentData = consentResponseObj?.data?.contactConsents;
      const contactInfo = contactDataResponse?.data?.contacts?.[0];
      const formattedContactData = getFormDataFromLeadData(
        contactInfo,
        mlovData
      );
      setComponentState((prev) => ({
        ...prev,
        contactData: contactInfo,
        consentData: consentData,
        formattedContactData: formattedContactData,
      }));
    } catch (error) {
    } finally {
      setComponentState((prev) => ({
        ...prev,
        contactDataLoading: false,
      }))
    }
  };

  useEffect(() => {
    fetchContactData();
  }, [contactId]);

  return (
    <Drawer
      destroyOnClose
      placement="right"
      className="custom-drawer-styles alert-task-drawer"
      open={true}
      closable
      width={'40%'}
      onClose={() => {
        onClose(true);
      }}
      title={
        <>
          <ModalActionTitle
            title={intl.formatMessage({ id: 'carePlanGoals' })}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'close',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onClose(false);
                },
              },
            ]}
          />
        </>
      }
    >
      <View style={{
        marginHorizontal: 12,
        marginVertical: 16,
      }}>
        <View>
          {componentState.contactDataLoading ? (
            <Skeleton
              active
              avatar
              paragraph={{ rows: 1 }}
            />
          ) : (
            <InfoSection
              contactData={componentState.contactData}
              infoItems={[]}
              actionView={
                <View>
                  <ContactRelationCommunication
                    handleActions={() => null}
                    contact={
                      {
                        ...componentState.contactData,
                        contactConsents: componentState.consentData,
                      } as IContact
                    }
                  />
                </View>
              }
            />
          )}
        </View>
        <CarePlan
          accountLocationUuid={componentState?.formattedContactData?.accountLocationUuid || ''}
          contactUuid={contactId}
          contactName={componentState?.formattedContactData?.name}
          contactId={componentState?.formattedContactData?.id}
          personData={componentState?.formattedContactData}
          isCarePlanGoalsDrawerView={true}
        />
      </View>
    </Drawer>
  );
};

export default CarePlanGoalsDrawer;
