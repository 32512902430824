export const CustomPrintStyle = `
.widgetBoxShadow {
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
  }

  .graph-wrapper {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  }


  .print-renderer {
    display: none;
  }

  .renderer {
    display: block;
  }

  .header,
  .header-space,
  .footer,
  .footer-space {
    height: 200px;
    width: 100%;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .header,
  .header-space,
  .footer,
  .footer-space {
    display: none;
  }

  .section-title-text {
    font-size: 24px;
    font-weight: 300;
    color: #000;
    font-family: 'Manrope-Medium';
  }
  .section-subtitle-title-text {
    font-size: 14px;
    font-weight: 300;
    color: #98A2B3;
    font-family: 'Manrope-Medium';
  }

  .widgetBoxShadow {
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    -webkit-box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
      border: 1px solid rgb(192 192 192);
  }

  .common-style {
    -webkit-box-align: stretch;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    align-items: stretch;
    border: 0px solid black;
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0px;
    min-height: 0px;
    min-width: 0px;
    padding: 0px;
    position: relative;
    z-index: 0;
  }

  .card-widget-wrapper {
    flex-direction: row;
    flex-flow: row wrap;
    align-items: flex-start;
    flex: 1 1 0%;
    display: flex;
  }

  .card-widget-type {
    background-color: rgb(255, 255, 255);
  }

  .card-widget-type .percent-bg {
    display: flex;
    min-width: 54px;
    height: 22px;
    padding-left: 4px;
    padding-right: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: rgb(244, 204, 201);
  }

  .card-widget-type-1 {
    width: 550px;
  }

  .card-widget-type-1 .widgetBoxShadow {
    border-width: 1px;
    border-color: rgb(192 192 192);;
    border-radius: 8px;
    padding: 15px 26px 15px 24px;
    justify-content: center;
    margin-right: 12px;
    margin-bottom: 24px;
    min-height: 120px;
    display: flex;
    align-items: center;
  }

  .card-widget-type-2 {
    width: 385px;
  }

  .card-widget-type-2 .widgetBoxShadow {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 15px 26px 15px 23px;
    justify-content: center;
    margin-right: 12px;
    margin-bottom: 20px;
    min-height: 154px;
  }

  .widget-wrapper-3 {
    flex-direction: row;
    flex-flow: row wrap;
    align-items: flex-start;
    flex: 1 1 0%;
    display: flex;
  }
  .widget-wrapper-6 {
    flex-direction: row;
    flex-flow: row wrap;
    align-items: flex-start;
    flex: 1 1 0%;
    display: flex;
  }

  .widget-type-3 {
    background-color: rgb(255, 255, 255);
    width: 46%;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .widget-type-3 .widgetBoxShadow {
    border-width: 1px;
    border-color: rgb(192 192 192);;
    border-radius: 8px;
    margin-right: 12px;
    margin-bottom: 20px;
    min-height: 382px;
    max-height: 382px;
    height: 382px;
  }

  .widget-wrapper-5 {
    display: flex;
    /* justify-content: space-between; */
  }

  .widget-wrapper-4 {
    flex-direction: row;
    flex-flow: row wrap;
    align-items: flex-start;
    flex: 1 1 0%;
    display: flex;
  }

  .widget-type-4 {
    background-color: rgb(255, 255, 255);
    width: 48%;
  }

  .widget-type-4 .widgetBoxShadow {
    border-width: 1px;
    border-color: rgb(192 192 192);;
    border-radius: 8px;
    margin-right: 12px;
    margin-bottom: 20px;
    min-height: 382px;
    max-height: 382px;
    height: 382px;
  }

  .widget-type-4 .listing-widget-list-wrapper {
    height: 84%;
    overflow: scroll;
  }
  .widget-wrapper-5 {
    flex-direction: row;
    flex-flow: row wrap;
    align-items: flex-start;
    flex: 1 1 0%;
    display: flex;
  }

  .widget-type-5 {
    background-color: rgb(255, 255, 255);
    width: 550px;
  }

  .widget-type-5 .widgetBoxShadow {
    border-width: 1px;
    border-color: rgb(192 192 192);;
    border-radius: 8px;
    ;
    margin-right: 12px;
    margin-bottom: 20px;
    min-height: 382px;
    max-height: 382px;
    height: 382px;
  }

  .widget-type-5 .listing-widget-list-wrapper {
    height: 84%;
    overflow: scroll;
  }

  // .widget-title-wrapper {
  //   background-color: #F8F8F8;
  //   border-top-left-radius: 8px;
  //   border-top-right-radius: 8px;
  //   min-height: 60px;
  //   padding: 15px 16px;
  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: center;
  //   border-bottom: 1px solid #E4E7EC;
  // }

  // .widget-title-style {
  //   font-size: 18px;
  //   font-weight: 500;
  //   color: #101828;
  //   font-family: 'Manrope-Regular';
  // }


  @media screen and (max-width: 1800px) {

    .card-widget-type-1,
    .widget-type-3,
    .widget-type-4,
    .widget-type-5 {
      width: 48%;
      margin-bottom: 8px;
      margin-right: 8px;
    }
  }


.header,
.header-space,
.footer,
.footer-space {
    display: flex;
    flex-direction: column;
}

// .widget-total-saving {
//     width: 100%;
// }

canvas {
    display: none !important;
}

@page {
    margin: 0px;
}
.cover-page-view {
  // page-break-before: always;
  background-size: cover;
  background-position: 'center';
  background-position-x: right;
  height: 100vh;
  position: relative;
}
.print-renderer {
    display: block;
}

.hideOnPrint {
    display: none;
}

.renderer {
    display: none;
}

.header,
.header-space {
    display: flex;
    height: 150px;
    width: 100%;
    background-color: #fff;
    z-index: 9999;
}

.footer,
.footer-space {
    height: 100px;
    width: 100vw;
    background-color: #fff;
    z-index: 9999;
    margin-top: 30px;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    border: 1px solid black;
}


.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    border: 1px solid black;
}
@media print {
  body {
      margin: 0;
      color: #000;
      background-color: #ffffff;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust:exact !important;
  }

  ::-webkit-scrollbar {
    display: none;
}

  .print-indivisible {
    break-inside: avoid;
  }

  .hidePrintBtn {
      display: none !important;
  }

  .woot-widget-bubble {
      display: none;
  }
  .cover-page-view {
    background-size: cover;
    background-position: 'center';
    background-position-x: right;
    height: 99.8vh;
    position: relative;
  }
  @page {
      size: A4;
      margin: 10mm 0mm 10mm 0mm;
  }

  @page:first {
      size: A4;
      margin: 0mm 0mm 0mm 0mm;
  }
}
// .widget-title-wrapper {
//     background-color: rgb(248, 248, 248);
//     border-top-left-radius: 8px;
//     border-top-right-radius: 8px;
//     min-height: 60px;
//     padding-right: 30px;
//     padding-left: 30px;
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     border-bottom: 1px solid rgb(228, 231, 236);
// }

.section-title-text {
    font-size: 24px;
    font-weight: 300;
    color: #000;
    font-family: 'Manrope-Medium';
}
.section-subtitle-title-text {
  font-size: 14px;
  font-weight: 300;
  color: #98A2B3;
  font-family: 'Manrope-Medium';
}

.widget-title-text {
    fontSize: 18px;
        color: 010828;
        fontFamily: 'Manrope-bold'
}

.widgetBoxShadow {
  border: 1px solid rgb(192 192 192);
}

.card-widget-wrapper-1 {
    display: flex;
    grid-template-columns: 1fr 1fr;
}

.card-widget-type-1 .widgetBoxShadow {
    border-width: 1px;
    border-color: rgb(192 192 192);;
    border-radius: 8px;
    padding: 15px 26px 15px 24px;
    justify-content: center;
    margin-right: 27px;
    margin-bottom: 24px;
    min-height: 120px;
    display: flex;
    align-items: center;
}

.card-widget-wrapper-2 {
    display: flex;
    grid-template-columns: 1fr 1fr;
}

.card-widget-type-2 .widgetBoxShadow {
    border-width: 1px;
    border-color: rgb(192 192 192);;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 15px 26px 15px 23px;
    justify-content: center;
    margin-right: 15px;
    margin-bottom: 20px;
    min-height: 154px;
}

.widget-wrapper-3 {
  flex-wrap: wrap;
  max-width: 100%;
}
.widget-wrapper-6 {
  flex-wrap: wrap;
  max-width: 100%;
}

.widget-type-3 .widgetBoxShadow {
    border-width: 1px;
    border-color: rgb(192 192 192);;
    border-radius: 8px;
    margin-right: 12px;
    margin-bottom: 20px;
    min-height: 382px;
    max-height: 382px;
    height: 382px;
}

.widget-wrapper-4 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
}

.widget-type-4 .widgetBoxShadow {
    border-width: 1px;
    border-color: rgb(192 192 192);;
    border-radius: 8px;
    max-height: none;
    break-inside: avoid;
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
}

.widget-type-4 .listing-widget-list-wrapper {
    height: auto;
}

.card-widget-type .percent-bg {
    display: flex;
    min-width: 54px;
    height: 22px;
    padding-left: 4px;
    padding-right: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: rgb(244, 204, 201);
}

// .widget-title-wrapper {
//     background-color: #F8F8F8;
//     border-top-left-radius: 8px;
//     border-top-right-radius: 8px;
//     min-height: 60px;
//     padding: 15px 16px;
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     border-bottom: 1px solid #F8F8F8;
// }

// .widget-title-style {
//     font-size: 18px;
//     font-weight: 500;
//     color: #101828;
// }
// #SMS_MESSAGING,#PATIENT_APP_MESSAGING, #VOICE_CALLING_DIRECTION_WISE,#USER_MESSAGING,#CRM_ATIVITY  {
//   width: 100%;
//   margin-right: 8px;
// }
//  #SMS_MESSAGING.in-print-drawer, #PATIENT_APP_MESSAGING.in-print-drawer, #VOICE_CALLING_DIRECTION_WISE.in-print-drawer, #USER_MESSAGING.in-print-drawer,#CRM_ACTIVITY.in-print-drawer{
//   margin-right: 8px;
//   width: 100%;
// }
// #SMS_MESSAGING .widgetBoxShadow ,#PATIENT_APP_MESSAGING .widgetBoxShadow, #VOICE_CALLING_DIRECTION_WISE .widgetBoxShadow,  #USER_MESSAGING .widgetBoxShadow, #CRM_ACTIVITY .widgetBoxShadow{
//   width: 100%;
//   border-width: 1px;
//   border-color: #E4E7EC;
//   border-radius: 8px;
//   ;
//   margin-right: 12px;
//   margin-bottom: 20px;
//   min-height: 382px;
//   max-height: 382px;
//   height: 382px;
// }
.in-print-drawer.card-widget-type:not(.SAVINGS.card-widget-type) {
  width: 48%;
  margin-right: 8px;
  margin-right: 8px;
  height: 400px;
}
#OUT_OFFICE_HOUR_USER_ENGAGEMENT.in-print-drawer {
  width: 98%;
  margin-right: 8px;
  min-width: 100%;
}
#SAVINGS-wrapper.widget-wrapper-3 {
  flex-direction: column;
}
#TOTAL_SAVINGS.widget-total-saving, #GROUP_SAVING.widget-total-saving {
  width: 100%;
  margin-bottom: 20px;
  margin-right: 0;
}
.card-widget-type:not(.SAVINGS.card-widget-type) {
  width: 49%;
  margin-right: 8px;
  margin-right: 8px;
  height: 400px;
}
#ENGAGEMENT_ON_APPLIED_RULES.card-widget-type {
  width: 98%;
  margin-right: 8px;
  margin-right: 8px;
  height: 400px;
}
#ED_UTILIZATION_AMOUNT.card-widget-type {
  width: 98%;
}
.saving-group-item {
  width: 48% !important;
}

.canvas-wrapper {
  width: auto !important;
  padding: 10px 20px !important;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Manrope-ExtraLight';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggqxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Manrope-ExtraLight';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggOxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */
@font-face {
    font-family: 'Manrope-ExtraLight';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggSxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
    font-family: 'Manrope-ExtraLight';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggixSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Manrope-ExtraLight';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Manrope-ExtraLight';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Manrope-Light';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggqxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Manrope-Light';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggOxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */
@font-face {
    font-family: 'Manrope-Light';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggSxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
    font-family: 'Manrope-Light';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggixSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Manrope-Light';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Manrope-Light';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Manrope-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggqxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Manrope-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggOxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */
@font-face {
    font-family: 'Manrope-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggSxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
    font-family: 'Manrope-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggixSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Manrope-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Manrope-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Manrope-Medium';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggqxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Manrope-Medium';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggOxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */
@font-face {
    font-family: 'Manrope-Medium';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggSxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
    font-family: 'Manrope-Medium';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggixSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Manrope-Medium';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Manrope-Medium';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Manrope-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggqxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Manrope-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggOxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */
@font-face {
    font-family: 'Manrope-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggSxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
    font-family: 'Manrope-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggixSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Manrope-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Manrope-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Manrope-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggqxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Manrope-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggOxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */
@font-face {
    font-family: 'Manrope-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggSxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
    font-family: 'Manrope-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggixSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Manrope-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Manrope-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v13/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
`;
