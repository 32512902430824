import { View, Text } from "native-base";
import { Colors } from "../../../../styles/Colors";
import JourneyGauge from "../Analytics/JourneyGauge";

const JourneyProgressOverall = (props:{
        value:any
}) => {

    return (
        <>
            <View style={{ padding: 12, width: 200 }}>
                <Text style={{
                    marginBottom: 8,
                    textAlign: 'center',
                }} size={'smBold'} fontSize={18}>
                    Overall Adherence
                </Text>
                <View style={{ padding: 12 }}>
                    <JourneyGauge value={props.value} />
                </View>
            </View>
            {/* <View style={{ backgroundColor: Colors.Custom.Gray200, height: 'auto', width: 2 }} /> */}
        </>
    )
}

export default JourneyProgressOverall;