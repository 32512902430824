import {FormControl, Input, Text} from 'native-base';
import {useState} from 'react';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {styles} from '../../../CampaignStyles';

export const Sender = () => {
  const [sender, setSender] = useState('');
  return (
    <FormControl style={styles.selectScreenTwo}>
      <FormControl.Label>
        <DisplayText textLocalId="sender" />
        <Text>:</Text>
      </FormControl.Label>
      <Input onChangeText={(value) => setSender(value)} />
    </FormControl>
  );
};
