import BaseService from '../CommonService/BaseService';
import {BASE_PATH} from './baseUrlPath';

const baseService = BaseService.getSharedInstance().axios;

// use to Add Cpt AND Delete Cpt
export const addOrUpdateCPTByTemplate = async (data: any) => {
  return await baseService.put(`${BASE_PATH}`, {...data});
};

export const saveTemplateAsDefault = async (templateId: string) => {
  return await baseService.put(`${BASE_PATH}/${templateId}/default`);
};

export const costSavingCptDiffByAnalyticId = async (
  analyticsId: string,
  cptList: string[]
) => {
  const body = {
    cptCodes: [...cptList],
  };
  return await baseService.post(
    `${BASE_PATH}/analytical-resource/${analyticsId}/cpt/diff`,
    {...body}
  );
};
export const costSavingCptExistByExcludingAnalyticId = async (
  analyticsId: string,
  cptList: string[]
) => {
  const body = {
    cptCodes: [...cptList],
  };
  return await baseService.post(
    `${BASE_PATH}/analytical-resource/${analyticsId}/cpt/exist`,
    {...body}
  );
};

export const createCostSavingTemplate = async (data: any) => {
  return await baseService.post(`${BASE_PATH}`, {...data});
};

export const deleteCostSavingTemplate = async (templateId: string) => {
  return await baseService.delete(`${BASE_PATH}/${templateId}`);
};
export const addOrUpdateSavingTemplateAndEmployer = async (data: any) => {
  return await baseService.post(`${BASE_PATH}/employer-mapping`, {...data});
};
export const getMappedTemplateAndEmployer = async (
  analyticalResourceId: string,
  employerId: string
) => {
  return await baseService.get(
    `${BASE_PATH}/employer-mapping?employerId=${employerId}&analyticalResourceId=${analyticalResourceId}`
  );
};
