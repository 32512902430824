import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  formElement: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  formLabel: {
    marginLeft: 4,
  },
  zipCode: {
    paddingTop: 10,
  },
  city: {
    paddingTop: 10,
  },
  spinnerStyle: {
    margin: '0 auto',
    color: 'black',
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0,
  },
  image: {height: 8, width: 8},
  box8: {height: 8, width: 8},
  marginTop32: {marginTop: 32}
});

export const reactStyles: Record<string, React.CSSProperties> = {
  drawer: {
    minWidth: 700
  },
  steps: {
    width: 250
  }
}