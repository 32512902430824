import React from 'react';
import PIP from './PIP';
import {ResizableBox} from 'react-resizable';
import 'react-resizable/css/styles.css';
import './ResizablePIP.module.css';
import { IResizablePipProps } from './interface';

const ResizablePip = ({
  children,
  width = 400,
  height = 'max-content',
  minConstraints = [300, 300],
  maxConstraints = [300, 300],
  boxPosition
}: IResizablePipProps) => {
  return (
    <PIP boxPosition={boxPosition}>
      <ResizableBox
        onResize={(e: any) => {
          e.stopPropagation();
        }}
        className={'pipResizableContent'}
        width={width}
        height={height}
        minConstraints={minConstraints}
        maxConstraints={maxConstraints}
      >
        {children}
      </ResizableBox>
    </PIP>
  );
};

export default ResizablePip;
