import React from 'react';
import {capitalizeText} from '../../../../../common/ContactRelationView/ContactRelationUtils';
import {graphColorList} from '../../graphColors';
import {getColorByTypeCode} from '../utils';
const CustomTooltip = (props: {
  title: string,
  data: any[],
  totalMetaData?: {
    title: string;
    columnList: string[]
  }[],
  videoCallingFlag?: boolean
}) => {
  const {
    data,
    title,
    totalMetaData,
    videoCallingFlag
  } = props;

  const getAdditionalRowFromTotalMetaData = () => {
    let additionalElem = [<></>];
    const separatorElem = (
      <li
        className="g2-tooltip-list-item"
        style={{
          listStyleType: 'none',
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          marginTop: 12,
          marginRight: 0,
          marginBottom: 12,
          marginLeft: 0,
        }}
      >
      </li>
    );
    if (totalMetaData?.length) {

      additionalElem = totalMetaData.map((singleColumnData) => {
        let totalCount = 0 as any;
        if (singleColumnData?.columnList) {
          data.forEach((singleItem: any) => {
            const name = singleItem?.name?.toLowerCase() || '';
            if (singleColumnData?.columnList?.includes(name)) {
              if(videoCallingFlag && singleItem?.value) {
                singleItem.value.replace('(minutes)', '');
                singleItem.value = parseInt(singleItem.value)
              }
              if (!isNaN(singleItem?.value)) {
                totalCount += parseInt(singleItem?.value);
              }
            }
          })
        }
        if(videoCallingFlag) {
          totalCount = totalCount + ' (minutes)';
        }
        return (
          <li
            className="g2-tooltip-list-item"
            style={{
              listStyleType: 'none',
              paddingTop: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              marginTop: 12,
              marginRight: 0,
              marginBottom: 12,
              marginLeft: 0,
            }}
          >
            <span
              className="g2-tooltip-marker"
              style={{
                backgroundColor: 'rgb(1, 240, 228)',
                width: 8,
                height: 8,
                borderTopRightRadius: '50%',
                borderBottomRightRadius: '50%',
                borderBottomLeftRadius: '50%',
                borderTopLeftRadius: '50%',
                display: 'inline-block',
                marginRight: 8,
              }}
            ></span>
            <span className="g2-tooltip-name">
              {singleColumnData?.title}
            </span>:
            <span
              className="g2-tooltip-value"
              style={{
                display: 'inline-block',
                marginLeft: 30,
              }}>
              {totalCount}
            </span>
          </li>
        )
      });
      return (
        <>
          {separatorElem}
          {additionalElem}
        </>
      )
    }
    return <></>

  };
  if (title && data?.length) {
    return (
      <div style={{minWidth: 150, marginRight: 12}}>
        <div
          className="g2-tooltip-title"
          style={{
            marginBottom: 12,
            marginTop: 12
          }}>
          {title}
        </div>
        <ul
          className="g2-tooltip-list"
          style={{
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
            listStyleType: 'none',
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
          }}>
          {
            data?.map((singleItem, index) => {
              if (singleItem?.name === 'one off') {
                return (
                  <div>
                    <li
                      className="g2-tooltip-list-item"
                      style={{
                        listStyleType: 'none',
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 12,
                        marginRight: 0,
                        marginBottom: 12,
                        marginLeft: 0,
                      }}
                    >
                      <span
                        className="g2-tooltip-marker"
                        style={{
                          backgroundColor: graphColorList[index],
                          width: 8,
                          height: 8,
                          borderTopRightRadius: '50%',
                          borderBottomRightRadius: '50%',
                          borderBottomLeftRadius: '50%',
                          borderTopLeftRadius: '50%',
                          display: 'inline-block',
                          marginRight: 8,
                        }}
                      ></span>
                      <span className="g2-tooltip-name">
                        {capitalizeText(singleItem?.name)}
                      </span>:
                      <span
                        className="g2-tooltip-value"
                        style={{
                          display: 'inline-block',
                          marginLeft: 30,
                        }}>
                        {singleItem?.value}
                      </span>
                    </li>
                  </div>
                )
              } else {
                return (
                  <li
                    className="g2-tooltip-list-item"
                    style={{
                      listStyleType: 'none',
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      marginTop: 12,
                      marginRight: 0,
                      marginBottom: 12,
                      marginLeft: 0,
                    }}
                  >
                    <span
                      className="g2-tooltip-marker"
                      style={{
                        backgroundColor: graphColorList[index],
                        width: 8,
                        height: 8,
                        borderTopRightRadius: '50%',
                        borderBottomRightRadius: '50%',
                        borderBottomLeftRadius: '50%',
                        borderTopLeftRadius: '50%',
                        display: 'inline-block',
                        marginRight: 8,
                      }}
                    ></span>
                    <span className="g2-tooltip-name">
                      {capitalizeText(singleItem?.name)}
                    </span>:
                    <span
                      className="g2-tooltip-value"
                      style={{
                        display: 'inline-block',
                        marginLeft: 30,
                      }}>
                      {singleItem?.value}
                    </span>
                  </li>
                );
              }
            })
          }
          {getAdditionalRowFromTotalMetaData()}
        </ul>
      </div>
    );
  } else {
    return <></>;
  }
};

export default React.memo(CustomTooltip);
