import {Icon, IconButton, View} from 'native-base';
import React from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {IActionViewProps} from './ActionsView';
import {styles} from './ActionsViewStyles';

const AssignJourneyActionsView = (props: IActionViewProps) => {
  return (
    <View style={styles.iconView}>
      <IconButton
        colorScheme="gray"
        tintColor="gray.500"
        onPress={props.onDetails}
        icon={
          <Icon as={AntIcon} name="infocirlceo" size="5" color="gray.400" />
        }
      />
    </View>
  );
};

export default AssignJourneyActionsView;
