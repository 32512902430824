import React, {useRef, useState} from 'react';
import {
  IAccount,
  IMigrateTemplateDrawerProps,
  IMigrationType,
} from './interfaces';
import {Drawer, Form, FormInstance, notification, Select, Spin} from 'antd';
import {
  getEmailTemplates,
  getFormattedEmailTemplateData,
} from '../EmailTemplates/EmailTemplatesUtils';
import {IEmailTemplateData} from '../EmailTemplates';
import {debounce} from 'lodash';
import BaseService from '../../../../services/CommonService/BaseService';
import {getAccountUUID} from '../../../../utils/commonUtils';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../../styles';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {getEnvVariable} from '../../../../constants/BaseUrlConst';
import {ITemplateQueryParams} from '../ContentManagementUtils';
import {
  getFormattedPushNotifications,
  getPushNotifications,
} from '../PushNotifications/PushNotificationsUtils';
import {
  getFormattedSMSTemplates,
  getSMSTemplates,
} from '../SMSTemplates/SMSTemplatesUtils';
import {
  SMSTemplateApiResponse,
  SingleSMSTemplate,
} from '../SMSTemplates/interfaces';
import {
  NotificationApiResponse,
  SingleNotification,
} from '../PushNotifications/interfaces';

const baseService = BaseService.getSharedInstance().axios;

const environments = [
  {
    label: 'Development',
    value: 'dev',
  },
  {
    label: 'QA',
    value: 'qa',
  },
  {
    label: 'Sandbox',
    value: 'sandbox',
  },
  {
    label: 'Main',
    value: 'main',
  },
];

const functionMap: {
  [index in IMigrationType]: (
    categoryQueryString?: string,
    queryParams?: ITemplateQueryParams
  ) => Promise<any>;
} = {
  emails: getEmailTemplates,
  'push-notifications': getPushNotifications,
  'text-messages': getSMSTemplates,
};

const formatFunctionMap: {
  [index in IMigrationType]: (
    response: SMSTemplateApiResponse | NotificationApiResponse | any
  ) => SingleSMSTemplate[] | SingleNotification[] | IEmailTemplateData[];
} = {
  emails: getFormattedEmailTemplateData,
  'push-notifications': getFormattedPushNotifications,
  'text-messages': getFormattedSMSTemplates,
};

const MigrateTemplateDrawer: React.FC<IMigrateTemplateDrawerProps> = ({
  migrationType,
  isVisible,
  onClose,
}) => {
  const [state, setState] = useState({
    templates: [] as any[],
    accounts: [] as IAccount[],
    loading: false,
    migrating: false,
  });

  const formRef = useRef<FormInstance>(null);
  const accountUuid = getAccountUUID();
  const envName = getEnvVariable();
  const searchFunction = functionMap[migrationType];
  const formatFunction = formatFunctionMap[migrationType];

  const searchTemplates = async (value: string) => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const response = await searchFunction('', {
      name: value,
      page: 1,
      pageSize: 10,
    });
    const formattedData = formatFunction(response);
    setState((prev) => {
      return {
        ...prev,
        loading: false,
        templates: formattedData,
      };
    });
  };

  const getAccountsByEnvironment = async (environment: string) => {
    setState((prev) => {
      return {
        ...prev,
        accounts: [],
      };
    });
    const formInstance = formRef.current;
    formInstance?.setFieldValue('accounts', []);
    const url = `cms/seeding/accounts`;
    const response = await baseService.get(url, {
      params: {
        env: environment,
      },
    });
    setState((prev) => {
      return {
        ...prev,
        accounts: response.data,
      };
    });
  };

  const validateForm = async () => {
    if (!formRef.current) {
      return;
    }
    const formInstance = formRef.current;
    try {
      await formInstance.validateFields([
        'templates',
        'environment',
        'accounts',
      ]);
    } catch (error) {
      return false;
    }
    return true;
  };

  const migrateTemplates = async (
    resourceIds: number[],
    targetAccountUuid: string,
    targetEnv: string
  ) => {
    const url = `cms/seeding/${migrationType}`;
    const response = await baseService.post(url, {
      source: {
        accountUuid: accountUuid,
        env: envName || 'main',
      },
      target: {
        accountUuid: targetAccountUuid,
        env: targetEnv,
      },
      resourceIds,
    });
    return response.data;
  };

  const onSubmit = async () => {
    if (!formRef.current) {
      return;
    }
    const formInstance = formRef.current;
    const isValid = await validateForm();
    if (!isValid) {
      notification.error({
        message: 'Please fill in all required fields!',
        placement: 'topRight',
        duration: 2,
      });
      return;
    }
    const selectedTemplates: number[] = formInstance.getFieldValue('templates');
    const selectedEnvironment: string =
      formInstance.getFieldValue('environment');
    const selectedAccounts: string[] = formInstance.getFieldValue('accounts');
    setState((prev) => {
      return {
        ...prev,
        migrating: true,
      };
    });
    try {
      const promiseList = selectedAccounts.map((accountUuid) => {
        return migrateTemplates(
          selectedTemplates,
          accountUuid,
          selectedEnvironment
        );
      });
      await Promise.all(promiseList);
      setState((prev) => {
        return {
          ...prev,
          migrating: false,
        };
      });
      notification.success({
        message: 'Templates migrated successfully!',
        placement: 'topRight',
        duration: 2,
      });
      onClose();
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          migrating: false,
        };
      });
      notification.error({
        message: 'Failed to migrate templates!',
        placement: 'topRight',
        duration: 2,
      });
    }
  };

  return (
    <Drawer
      title={
        <ModalActionTitle
          title={'Migrate'}
          titleColor={Colors.primary[400]}
          rightButton={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <FoldButton
                customProps={{
                  btnText: 'Submit',
                }}
                nativeProps={{
                  onPress() {
                    onSubmit();
                  },
                  isLoading: state.migrating,
                  backgroundColor: Colors.Custom.Primary300,
                  _text: {
                    fontSize: 14,
                    lineHeight: 16.8,
                    fontWeight: 'bold',
                  },
                }}
              />
              <div
                style={{
                  width: 1.5,
                  backgroundColor: Colors.Custom.Gray400,
                  margin: '0 8px',
                }}
              />
              <div
                className="pressable"
                onClick={(e) => {
                  e.stopPropagation();
                  if (state.migrating) {
                    return;
                  }
                  onClose();
                }}
              >
                <AntIcon name="close" size={20} color={Colors.Custom.Gray500} />
              </div>
            </div>
          }
        />
      }
      onClose={onClose}
      open={isVisible}
      width={'33vw'}
      style={{
        pointerEvents: state.migrating ? 'none' : 'auto',
      }}
    >
      <Form
        labelCol={{span: 100}}
        wrapperCol={{span: 100}}
        layout="vertical"
        ref={formRef}
      >
        <Form.Item
          label="Select Templates"
          name="templates"
          rules={[{required: true, message: 'Please select templates!'}]}
        >
          <Select
            mode="multiple"
            size="large"
            showSearch
            allowClear
            filterOption={false}
            loading={state.loading}
            notFoundContent={state.loading && <Spin size="small" />}
            onSearch={debounce(searchTemplates, 500)}
          >
            {state.templates.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Select Environment"
          name="environment"
          rules={[{required: true, message: 'Please select environment!'}]}
        >
          <Select
            size="large"
            allowClear
            filterOption={false}
            onChange={(value) => {
              getAccountsByEnvironment(value);
            }}
          >
            {environments.map((item) => {
              return (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Select Account"
          name="accounts"
          rules={[{required: true, message: 'Please select account!'}]}
        >
          <Select
            mode="multiple"
            size="large"
            allowClear
            showSearch={false}
            filterOption={false}
            placeholder={'Select Account'}
          >
            {state.accounts.map((item) => {
              return (
                <Select.Option key={item.uuid} value={item.uuid}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default MigrateTemplateDrawer;
