import {useQuery} from '@apollo/client';
import {Skeleton, View, VStack} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {MLOV_CATEGORY, RESPONSE_CATEGORY_CODE, RESPONSE_VIEW_CODE} from '../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import ResponseQueries from '../../../../../services/Responses/ResponseQueries';
import {TableTopBar} from '../../../../common/TableTopBar';
import {addResponseButton} from '../Helper/ResponseTableTopBarButtons';
import {IResponseDataProps, IResponseViewProps} from '../interfaces';
import {AddResponse} from '../ResponseActionModals/AddResponse';
import {DeleteResponse} from '../ResponseActionModals/DeleteResponse';
import {EditResponse} from '../ResponseActionModals/EditResponse';
import {ResponseTable} from './ResponseTable';

export const ResponseView = (props: IResponseViewProps) => {
  // const [modalVisible, setModalVisible] = useState(false);
  // const [currentModal, setCurrentModal] = useState('');
  const [responseViewState, setResponseViewState] = useState({
    currentModal: '',
    searchString: '',
    dataList: [],
  });
  const [currentRecordData, setCurrentRecordData] =
    useState<IResponseDataProps>({
      id: 0,
      content: '',
      createdAt: '',
      shortCode: '',
      type_id: '',
    });
  const [stateData, setStateData] = useState<any>([]);
  const commonData = useContext(CommonDataContext);
  const mlovData = commonData.MLOV;
  let categoryTypeList = mlovData[MLOV_CATEGORY.CANNED_RESPONSE_TYPE];
  if (props.isClinicalResponse) {
    categoryTypeList = categoryTypeList.filter((item) => item.code === RESPONSE_CATEGORY_CODE.NOTES);
  } else {
    categoryTypeList = categoryTypeList.filter((item) => item.code !== RESPONSE_CATEGORY_CODE.NOTES);
  }

  const getSearch = (actionCode: string, actionData: any) => {
    setResponseViewState((prev) => {
      return {
        ...prev,
        searchString: actionData.searchString,
      };
    });
  };

  const {loading, refetch} = useQuery(ResponseQueries.GetFilteredResponses, {
    variables: {
      searchString: `%${responseViewState.searchString}%`,
      categoryCodeUuids: categoryTypeList.map((item) => item.id) || [],
    },
    onCompleted: (responseData) => {
      setResponseViewState((prev) => {
        return {
          ...prev,
          dataList: responseData?.cannedResponses,
        };
      });
      // setStateData(responseData?.cannedResponses);
    },
    notifyOnNetworkStatusChange: true,
  });

  const onAddResponse = () => {
    setAction(COMMON_ACTION_CODES.ADD, {} as IResponseDataProps);
  };

  const handleCloseAfterUpdateModal = () => {
    props.onChangeViewCallBack('');
    refetch();
    setResponseViewState((prev) => {
      return {
        ...prev,
        currentModal: '',
      };
    });
    setCurrentRecordData({} as IResponseDataProps);
  };

  const handleCloseModal = () => {
    props.onChangeViewCallBack('');
    setResponseViewState((prev) => {
      return {
        ...prev,
        currentModal: '',
      };
    });
    setCurrentRecordData({} as IResponseDataProps);
  };

  const setAction = (
    actionCode: string,
    recordData: IResponseDataProps
  ): any => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        setCurrentRecordData(recordData);
        setResponseViewState((prev) => {
          return {
            ...prev,
            currentModal: RESPONSE_VIEW_CODE.EDIT_RESPONSE,
          };
        });
        break;
      case COMMON_ACTION_CODES.DELETE:
        setCurrentRecordData(recordData);
        setResponseViewState((prev) => {
          return {
            ...prev,
            currentModal: RESPONSE_VIEW_CODE.DELETE_RESPONSE,
          };
        });
        break;
      case COMMON_ACTION_CODES.ADD:
        setCurrentRecordData({} as IResponseDataProps);
        // setCurrentModal(RESPONSE_VIEW_CODE.ADD_RESPONSE);
        break;
      default:
        setCurrentRecordData({} as any);
        setResponseViewState((prev) => {
          return {
            ...prev,
            currentModal: '',
          };
        });
        break;
    }
  };

  useEffect(() => {
    setResponseViewState((prev) => {
      return {
        ...prev,
        currentModal: props.currentModal,
      };
    });
  }, [props.currentModal]);

  useEffect(() => {
    setResponseViewState((prev) => {
      return {
        ...prev,
        searchString: props.searchString,
      };
    });
  }, [props.searchString]);

  return (
    <VStack>
      {props.showTableTopBar && (
        <VStack padding={1}>
          <TableTopBar
            onActionPerformed={getSearch}
            title="responses"
            buttonList={addResponseButton(onAddResponse)}
          />
        </VStack>
      )}
      {loading ? (
        <View padding={3}>
          <Skeleton.Text lines={4} />
        </View>
      ) : (
        <ResponseTable
          dataSource={responseViewState.dataList}
          setAction={setAction}
        />
      )}
      {responseViewState.currentModal == RESPONSE_VIEW_CODE.ADD_RESPONSE && (
        <AddResponse
          handleCloseModal={handleCloseModal}
          handleCloseAfterUpdateModal={handleCloseAfterUpdateModal}
          categoryList={categoryTypeList}
        />
      )}
      {responseViewState.currentModal == RESPONSE_VIEW_CODE.EDIT_RESPONSE && (
        <EditResponse
          editRecordData={currentRecordData}
          handleCloseModal={handleCloseModal}
          handleCloseAfterUpdateModal={handleCloseAfterUpdateModal}
          categoryList={categoryTypeList}
        />
      )}
      {responseViewState.currentModal == RESPONSE_VIEW_CODE.DELETE_RESPONSE && (
        <DeleteResponse
          recordData={currentRecordData}
          handleCloseModal={handleCloseModal}
          handleCloseAfterUpdateModal={handleCloseAfterUpdateModal}
        />
      )}
    </VStack>
  );
};
