import {IKeyOperation} from '../../../../../../../Interfaces';
import {IAddOrUpdateAllergyParams, IAllergy, IAllergyComponentValue} from '../interfaces';
import { AllergyFields } from '../../CustomComponentUtils';
import { IFormatHieMetaData, IHieFormattedData, IHieInvalidField } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import { AllergyIntolerance } from 'fhir/r4';
import { getTextFromCoding, getValidCoding } from '../../../../../Integration/FhirUtils';
import { HieInvalidFields } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';
import { getEHRCapability } from '../../../../../../../utils/commonUtils';
import { CapabilityResource } from '../../CustomWrapper/CustomComponentHelper';
import { InputType, getFieldCapabilities } from '../../../../../../../utils/capabilityUtils';
import { PAMISearchType } from '../../../../../../common/PAMISearch/PAMISearch';

export enum AllergySeverity {
  mild = 'mild',
  severe = 'severe',
  moderate = 'moderate',
}

export const getRecordListFormatted = (list: any[],fields?:IKeyOperation) => {
  const entries = [];
  for (const el of list || []) {
    entries.push(el.resource);
  }
  const formattedData = getRecordDataFormatted(entries,fields);
  return sortItems(formattedData);
}

const getReactionBtnStatus = (reactionListLength:number,disableMultiple?:boolean) => {
  if (disableMultiple && reactionListLength < 1) {
    return false;
  }
  return true;
}

export const getRecordDataFormatted = (listData: any[],fields?:IKeyOperation): IAllergy[] => {
  const tempFormattedList: IAllergy[] = [];
  listData.map((item: any) => {
    if (item?.code) {
      const status = item?.clinicalStatus?.coding?.length
        ? item.clinicalStatus?.coding[0]
        : {code: item.clinicalStatus?.text};

      const tempObj: IAllergy = {
        id: item?.id,
        onSetDateTime: item?.onsetDateTime,
        allergy: item.code,
        name: getAllergyName(item.code),
        clinicalStatus: status,
        recordedDate: item?.recordedDate,
        uniqueId: item?.id,
        criticality: item?.criticality,
        isFreeTextRecord: !item.code?.coding?.length,
        type: item.type,
        ...(item.note?.length > 0 && {
          note: item.note[item.note.length - 1].text,
        }),
        ...(item?.reaction?.length && {
          reactions: item.reaction.map((reaction: any) => {
            return {
              ...(reaction.manifestation?.length > 0 &&
                (reaction.manifestation?.[0]?.text || reaction.manifestation?.[0]?.coding?.length > 0) && {
                  manifestation: {
                    code: reaction.manifestation?.[0]?.coding?.[0]?.code,
                    display: reaction.manifestation?.[0]?.text || reaction.manifestation?.[0].coding?.[0]?.display,
                  },
                }),
              severity: reaction?.severity,
            };
          }),
        }),
        showAddReaction: true,
        showReactionInput: false,
        showNote:
          item.note?.length > 0 &&
          item.note[item.note.length - 1].text.length > 0,
        meta: item?.meta
      };
      tempFormattedList.push(tempObj);
    }
  });
  return tempFormattedList;
};

export const getSampleData = (): IAllergyComponentValue => {
  return {
    allergies: [{
      id: '34234324',
      onSetDateTime: '2020-12-24',
      allergy: {
        text: 'Sample Allergy',
        coding: []
      },
      name: 'Sample Allergy',
      clinicalStatus: {code: 'active', display: 'Active'},
      criticality: 'low',
      note: 'Test notes',
      uniqueId: '1234',
      isFreeTextRecord: false,
      type: 'allergy',
      reactions: [
        {
          manifestation: {
            code: 'SAMPLE_REACTION',
            display: 'Test Reaction',
          },
          severity: 'moderate',
        }
      ]
    }],
  }
};

export const sortItems = (records: IAllergy[]) => {
  return records.sort((a, b) => {
    const statusA = a?.clinicalStatus?.code || a?.status || '';
    const statusB = b?.clinicalStatus?.code || b?.status || '';
    if (statusA !== statusB) {
      return statusA.toLowerCase().localeCompare(statusB.toLowerCase());
    }
    const dateA = a?.recordedDate || a?.onSetDateTime || a?.date || new Date();
    const dateB = b?.recordedDate || b?.onSetDateTime || b?.date || new Date();
    return (new Date(dateB).getTime() - new Date(dateA).getTime());
  })
};

export const getAllergyName = (item: any) => {
  let name = item?.text;
  if (!name && item?.coding?.length) {
    name = item.coding[0].display;
  }
  return name;
};

export const isInvalid = (
  field: AllergyFields,
  item: IAllergy,
  submitTriggered: boolean,
  fields?: IKeyOperation
) => {
  switch (field) {
    case AllergyFields.onSetDateTime:
      if ((fields?.onsetDateTime?.isRequired || false) && submitTriggered) {
        return !item.onSetDateTime;
      }
      return false;

    case AllergyFields.clinicalStatus:
      if ((fields?.clinicalStatus?.isRequired || false) && submitTriggered) {
        return (
          !item.clinicalStatus || !item.clinicalStatus.code
        );
      }
      return false;

    case AllergyFields.reaction:
      if ((fields?.reaction?.isRequired || false) && submitTriggered) {
        return !item.reactions || item.reactions?.length === 0;
      }
      if (item.reactions?.length && submitTriggered) {
        return item.reactions.some((reaction) => !reaction.manifestation?.display);
      }
      return false;

    case AllergyFields.criticality:
      if ((fields?.criticality?.isRequired || false) && submitTriggered) {
        return !item.criticality;
      }
      return false;

    case AllergyFields.note:
      if ((fields?.note?.isRequired || false) && submitTriggered) {
        return !item.note;
      }
      return false;

    case AllergyFields.type:
      if ((fields?.type?.isRequired || false) && submitTriggered) {
        return !item.type;
      }
      return false;

    default:
      return false;
  }
};

export const getFHIRTemplate = (params: IAddOrUpdateAllergyParams) => {
  return {
    resourceType: 'AllergyIntolerance',
    ...(params.id && {id: params.id}),
    patient: {
      reference: `Patient/${params.patientId}`,
    },
    code: params.allergy,
    ...(params.recordedDate && {recordedDate: params.recordedDate}),
    ...(params.reactions && {
      reaction: params.reactions.map((reaction) => {
        return {
          manifestation: [
            {
              coding: [
                {
                  display: reaction.manifestation?.display,
                  ...(reaction.manifestation?.code && {
                    system: "http://snomed.info/sct",
                    code: reaction.manifestation?.code,
                  }),
                },
              ],
            },
          ],
          severity: reaction.severity
        }
      }),
    }),
    ...(params.note && {
      note: [
        {
          text: params.note,
        },
      ],
    }),
    ...(params.type && { type: params.type }),
    ...(params.criticality && { criticality: params.criticality }),
    ...(params.onSetDateTime && {onsetDateTime: params.onSetDateTime}),
    ...(params.clinicalStatus && {
      clinicalStatus: {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/allergyintolerance-clinical',
            code: params.clinicalStatus.code,
            display: params.clinicalStatus.display,
          },
        ],
      },
    }),
    ...(params?.meta && { meta: params?.meta })
  };  
};

export const getAllergySeverityDisplayName = (severity: string) => {
  switch (severity) {
    case AllergySeverity.mild:
      return 'Mild';
    case AllergySeverity.moderate:
      return 'Moderate';
    case AllergySeverity.severe:
      return 'Severe';
    default:
      return '';
  }
}

export const formatHieAllergyData = (
  resource: any,
  metaData: IFormatHieMetaData
): IHieFormattedData | undefined => {
  const allergyResource: AllergyIntolerance = resource;

  const capabilities = getEHRCapability(metaData.ehrCapabilities, [
    CapabilityResource.allergyIntolerance,
  ]);

  const fieldCapabilities = getFieldCapabilities(capabilities);
  const allStatus = allergyResource?.clinicalStatus?.coding?.length
    ? allergyResource.clinicalStatus?.coding[0]
    : {code: allergyResource.clinicalStatus?.text};

  const allergyTitle = getHieAllergyTitle(resource);  

  const display =  {
    title: allergyTitle || '',
    date: allergyResource.onsetDateTime || allergyResource.onsetPeriod?.start,
    status: allStatus?.code,
  };

  const validatedData = validateAndFormatConditionHieResource(
    resource,
    fieldCapabilities
  );

  return {
    display,
    resourceData: validatedData.resource,
    invalidFields: validatedData.invalidFields
  };
};

const getHieAllergyTitle = (resource: AllergyIntolerance) => {
  return resource?.code?.text || resource.reaction?.[0]?.substance?.text;
}

export const getAllergyCodeInvalidField = () => {
  return {
    inputType: HieInvalidFields.code,
    field: AllergyFields.code,
    invalid: true,
    label: 'Allergy',
    placeholder: 'Search Allergy',
    extraData: {
      searchType: PAMISearchType.allergy,
    },
  }
}

const getHieAllergyCodes = (
  resource: AllergyIntolerance,
  allowedCodeSystems?: string[]
) => {
  const codeObj = resource.code || resource.reaction?.[0]?.substance;
  const coding = getValidCoding(codeObj, allowedCodeSystems);
  const text = getTextFromCoding(coding) || codeObj?.text
  return {coding: coding, text: text};
};


export const validateAndFormatConditionHieResource = (
  resource: AllergyIntolerance,
  fields: any
) => {
  const invalidFields: IHieInvalidField[] = [];

  Object.keys(fields).forEach(function (key, index) {
    const field = AllergyFields[key as keyof typeof AllergyFields];
    switch (field) {
      case AllergyFields.code:
        const conditionCodes = getHieAllergyCodes(
          resource,
          fields?.code?.allowedCodeSystems
        );

        if (
          fields?.code.inputType === InputType.openChoice &&
          conditionCodes.text
        ) {
          resource.code = {
            text: conditionCodes.text,
            ...(conditionCodes?.coding?.length && {
              coding: conditionCodes.coding,
            }),
          };
        } else if (
          (!conditionCodes?.coding?.length || !conditionCodes.text) &&
          fields?.code?.isRequired
        ) {
          invalidFields.push(getAllergyCodeInvalidField());
        } else {
          resource.code = {
            coding: conditionCodes?.coding,
            text: conditionCodes?.text,
          };
        }
        break;

      case AllergyFields.reaction:
        const reactions = getHieAllergyReactions(
          resource,
          fields?.reaction?.allowedCodeSystems
        );
        if(reactions?.length){
          resource.reaction = [
            {
              manifestation: [
                {
                  coding: reactions,
                },
              ],
            },
          ];
        }
        else {
          delete resource.reaction;
        }
        break;
    }
  });

  return {
    invalidFields: invalidFields,
    resource: resource,
  };
};

export const updateHieAllergyMatchedData = (
  resource: AllergyIntolerance,
  invalidFields: IHieInvalidField[],
  metaData: IFormatHieMetaData
) => {
  invalidFields.map((invalidField) => {
    if (invalidField.value) {
      switch (invalidField.field) {
        case AllergyFields.code:
          resource.code = invalidField.value;
          break;      
      }
    }
    return invalidField;
  });

  return formatHieAllergyData(resource, metaData);
};

export const getHieAllergyReactions = (
  resource: AllergyIntolerance,
  allowedCodeSystems?: string[]
) => {
  const manifestationCodes = resource.reaction?.[0]?.manifestation;
  return getValidCoding(undefined, allowedCodeSystems, manifestationCodes);
}; 

export const formatAllergyRequestResourceObj = (
  resource: AllergyIntolerance,
  metaData: any
) => {

  const onsetDateTime = resource.onsetDateTime || resource.onsetPeriod?.start;

  const requestObj = {
    resourceType: 'AllergyIntolerance',
    patient: {
      reference: `Patient/${metaData.patientId}`,
    },
    ...(resource.code && {code: resource.code}),
    ...(resource.reaction && {reaction: resource.reaction}),   
    ...(resource.note && {
      note: resource.note,
    }),
    ...(resource.type && { type: resource.type }),
    ...(resource.criticality && { criticality: resource.criticality }),
    ...(onsetDateTime && {onsetDateTime: onsetDateTime}),
    ...(resource.clinicalStatus && {
      clinicalStatus: resource.clinicalStatus,
    }),
    ...(resource?.meta && { meta: resource?.meta })
  }; 
  return requestObj; 
};

