import { ICommonSvgProps } from '../interfaces';

const CallForwardLoading = (props: ICommonSvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      fill="none"
      viewBox="0 0 19 20"
    >
      <path
        stroke="#D0D5DD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.833 14.1v2.5a1.666 1.666 0 01-1.816 1.667 16.492 16.492 0 01-7.192-2.559 16.25 16.25 0 01-5-5 16.491 16.491 0 01-2.558-7.225 1.667 1.667 0 011.658-1.816h2.5A1.667 1.667 0 017.092 3.1c.105.8.3 1.586.583 2.342A1.667 1.667 0 017.3 7.2L6.242 8.258a13.333 13.333 0 005 5L12.3 12.2a1.666 1.666 0 011.758-.375 10.7 10.7 0 002.342.583 1.667 1.667 0 011.433 1.692z"
      ></path>
    </svg>
  );
};

export default CallForwardLoading;
