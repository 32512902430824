import BaseService from '../../../../services/CommonService/BaseService';
import {
  createBilling,
  createProducts,
  getProductsDetail,
  updateBillingDetail,
  updateProductsDetail,
} from '../../../../services/Stripe/StripeServices';

export const addOrUpdateMembership = async (
  productData: any,
  successHandler: any,
  errorHandler: any,
  productId?: any
) => {
  // const clonedBodyData = Object.assign({}, productData);
  // const newPrices: any = [];
  // clonedBodyData?.product?.prices?.forEach((_element: any) => {
  //   const tempObj = {..._element, price: _element.price * 100};
  //   newPrices.push(tempObj);
  // });
  // clonedBodyData.product.prices = newPrices;
  if (productId) {
    const updateProductResp = await updateProductsDetail(
      productId,
      productData
    ).catch((err) => {
      errorHandler(err);
    });
    if (updateProductResp?.data) {
      successHandler(updateProductResp?.data || {});
    }
  } else {
    const productResponse = await createProducts(productData).catch((error) => {
      errorHandler(error);
      return;
    });
    if (productResponse?.data) {
      successHandler(productResponse?.data || {});
    }
  }
};

export const addOrUpdateBilling = async (
  productData: any,
  successHandler: any,
  errorHandler: any,
  productId?: any
) => {
  // const clonedBodyData = Object.assign({}, productData);
  // const newPrices: any = [];
  // clonedBodyData?.product?.prices?.forEach((_element: any) => {
  //   const tempObj = {..._element, price: _element.price * 100};
  //   newPrices.push(tempObj);
  // });
  // clonedBodyData.product.prices = newPrices;
  if (productId) {
    const updateProductResp = await updateBillingDetail(
      productId,
      productData
    ).catch((err) => {
      errorHandler(err);
    });
    if (updateProductResp?.data) {
      successHandler(updateProductResp?.data || {});
    }
  } else {
    const productResponse = await createBilling(productData).catch((error) => {
      errorHandler(error);
      return;
    });
    if (productResponse?.data) {
      successHandler(productResponse?.data || {});
    }
  }
};

export const getMembershipDetails = async (
  productId: any,
  successHandler: any,
  errorHandler: any
) => {
  const productDetailResp: any = await getProductsDetail(productId).catch(
    (err) => {
      errorHandler(err);
      return;
    }
  );
  if (productDetailResp?.data?.id) {
    successHandler(productDetailResp.data || {});
  }
};

export const getMembershipAPIToken = async (data: {
  accountId: string | number;
  accountUuid: string;
  contactUuid: string;
}) => {
  const {accountId, accountUuid, contactUuid} = data;
  const url = `/crm-nest/membership/api/token`;
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.post<string>(url, {
    accountId,
    accountUuid,
    contactUuid,
  });
  return response.data;
};
