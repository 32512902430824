import {Divider, HStack, Text} from 'native-base';
import {DATE_FORMATS} from '../../../../constants';
import {getDateStrFromFormat} from '../../../../utils/DateUtils';
import {ICareTimelineProps} from '../interfaces';

export const PatientDemographicPrintView = (props: ICareTimelineProps) => {
  const {personData} = props;
  return (
    <div className="page-break">
      <Divider backgroundColor={'black'} my={2}></Divider>
      <HStack space={2} flex={1}>
        <Text fontSize={18} fontWeight={700} flex={0.33}>
          {personData?.name}
        </Text>
        {personData?.birthDate && (
          <Text
            textAlign={'center'}
            flex={0.33}
            fontSize={18}
            fontWeight={700}>{`DOB : ${getDateStrFromFormat(
            personData?.birthDate,
            DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT,
          )}`}</Text>
        )}
        {personData?.age && (
          <Text
            textAlign={'end'}
            flex={0.33}
            fontSize={18}
            fontWeight={700}>{`Age : ${personData?.age}`}</Text>
        )}
      </HStack>
      <Divider backgroundColor={'black'} my={2}></Divider>
    </div>
  );
};
