import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  nameContainer: {
    flex: 3.2,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  titleContainer: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 22,
  },
  subtitle: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 22,
    color: '#00000073',
  },
  avatarText: {
    color: 'white',
  },
});
