import {Box, HStack, Skeleton, View} from 'native-base';
import {useState} from 'react';
import {Colors} from '../../../styles';
import {commonBodyContentContainer} from '../../../styles/CommonBodyContentStyle';
import CustomTabsView from '../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../common/CustomTabsView/interfaces';
import TitleSubtitleView from '../../common/TitleSubtitleView/TitleSubtitleView';
import {styles} from '../Sales/ProductsAndServices/ProductsAndServicesStyles';
import AuditLogs from './AuditLogs/AuditLogs';

const Logs = () => {
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const [searchStringText, setSearchStringText] = useState('');
  const [logsTableMainViewState, setLogsTableMainViewState] = useState({
    logsData: {} as any,
    isLoading: false,
  });

  const logsTabView: ITabsList[] = [
    {
      key: 'audit',
      title: 'Audit Logs',
      path: 'audit',
    },
  ];

  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab);
    setSearchStringText('');
  };

  //const getAuditLogsData = async () => {};

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele: any = <></>;
    if (selectedTab?.key === 'audit') {
      ele = false;
    }
    return ele;
  };

  return (
    <>
      <View backgroundColor={'white'} flex={1}>
        <View style={styles.titleWrapper}>
          <TitleSubtitleView
            title={'Audit Logs'}
            subtitleLabelId="servicesManagementSubtitle"
          />
        </View>
        <View style={styles.tagContainer}>
          <CustomTabsView
            canNavigate={true}
            heading="logsTitle"
            showHeading
            basePath={`/admin/logs`}
            tabListWrapperStyle={{
              paddingHorizontal: 16,
              backgroundColor: '#fff',
            }}
            handleWithOnTab={true}
            tabsList={logsTabView}
            onTabClick={(tabItem) => {
              onTabClick(tabItem);
            }}
            rightView={() => {
              return getSelectedTopBar(selectedTab);
            }}
          />
        </View>
        <HStack
          backgroundColor={Colors.Custom.Gray100}
          flex={1}
          style={[commonBodyContentContainer.pageContentContainer]}
        >
          {logsTableMainViewState.isLoading ? (
            <Skeleton.Text style={{marginHorizontal: 16}} lines={4} />
          ) : (
            <>
              <Box style={styles.productSideBar}>
                <View
                  bgColor={'white'}
                  borderWidth={1}
                  borderColor={Colors.Custom.Gray200}
                  borderRadius={8}
                  style={{marginHorizontal: 16}}
                >
                  {selectedTab?.key === 'audit' && <AuditLogs />}
                </View>
              </Box>
            </>
          )}
        </HStack>
      </View>
    </>
  );
};

export default Logs;
