import {Typography} from 'antd';
import React from 'react';
import { Colors } from '../../../../styles/Colors';
import {GraphWrapperProps} from './interfaces';
const {Text, Title} = Typography;
import './styles.css';

const GraphWrapper = (props: GraphWrapperProps) => {
  const {title, subtitle, children, height} = props;
  return (
    <div className="display_card_container" style={{height: height || '358px'}}>
      <header style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <Title level={5} className="display_card_title">
            {title}
          </Title>
          <Text
            //className="display_card_subtitle"
            style={{color: Colors.Custom.GraphSubTitleColor, fontWeight: 500, fontSize: 14,}}
          >
            {subtitle}
          </Text>
        </div>
      </header>
      <div style={{padding: '20px', alignSelf: 'center'}}>{children}</div>
    </div>
  );
};

export default GraphWrapper;
