import { ColumnsType } from 'antd/lib/table';
import { Text, View } from 'native-base';
import { ICodeGroupSorter, ICodeGroupsTableData } from '../interfaces';
import { Colors } from '../../../../../styles';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import CodeGroupsActions from './CodeGroupsActions/CodeGroupsActions';
import { Pressable } from 'react-native';
import { COMMON_ACTION_CODES } from '../../../../../constants/ActionConst';
import { SORT_TYPE } from '../../../../../constants';

export const getCodeGroupColumns = (
  actionFn: (actionCode: string, record: any) => {},
  sort?: ICodeGroupSorter
) => {
  const sortOrder = sort?.order ? sort.order === SORT_TYPE.ASC ? 'ascend' : 'descend' : null;

  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{ color: Colors.Custom.Gray500, marginLeft: 8 }}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: '30%',
      sorter: true,
      sortOrder : sort?.columnKey === 'name' && sortOrder ? sortOrder : null,
      render: (codeGroupName: string, record: ICodeGroupsTableData) => (
        <Pressable onPress={() => actionFn(COMMON_ACTION_CODES.EDIT, record)}>
          <View justifyContent={'flex-start'} flexDirection={'row'}>
            <Text>{codeGroupName}</Text>
          </View>
        </Pressable>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{ color: Colors.Custom.Gray500, marginLeft: 8 }}
          textLocalId="category"
        />
      ),
      dataIndex: 'category',
      key: 'category',
      fixed: 'left',
      width: '20%',
      render: (codeGroupCategory: string, record: ICodeGroupsTableData) => (
        <View justifyContent={'flex-start'} flexDirection={'row'}>
          <Text>{codeGroupCategory}</Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{ color: Colors.Custom.Gray500, marginLeft: 8 }}
          textLocalId="description"
        />
      ),
      dataIndex: 'description',
      key: 'description',
      width: '25%',
      render: (text: string) => (
        <View justifyContent={'flex-start'}>
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {text || '-'}
          </Text>
        </View>
      ),
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{ color: Colors.Custom.Gray500, marginLeft: 8 }}
          textLocalId="actions"
        />
      ),
      key: 'actions',
      fixed: 'right',
      width: '15%',
      render: (record: ICodeGroupsTableData) => {
          return (
            <View>
              <CodeGroupsActions onActionPerformed={actionFn} record={record} />
            </View>
          );
      },
    },
  ];
  return columns;
};
