import { View } from 'native-base';
import React from 'react';
import { BoardPipeline } from './BoardPipeline';

const SalesPipeline = () => {
  return (
    <View flex={1}>
      <BoardPipeline />
    </View>
  );
};

export default SalesPipeline;
