export const TEAM_MEMBERS_VIEW_CODE = {
  ADD_NEW_PRACTICE_LOCATION: 'ADD_NEW_PRACTICE_LOCATION',
  EDIT_USER: 'EDIT_USER',
  EDIT_ACCOUNT: 'EDIT_ACCOUNT',
  RESET_PASSWORD: 'RESET_PASSWORD',
  DELETE: 'DELETE'
};

export const TEAM_MEMBERS_HANDLE_CODE = {
  SET_IMAGE: 'setImage',
  DELETE: 'delete'
};

export const TEAM_MEMBERS_ROLES_CODE = {
  WORKFLOW: 'WORKFLOW',
  CUSTOMER_SUCCESS: 'CUSTOMER_SUCCESS'
};