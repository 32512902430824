// import {Skeleton} from 'antd';
import {View} from 'native-base';
import React from 'react';
import {ITaxonomiesDeatils} from './Interface';
// import {styles} from './TaxonomiesDnDViewStyles';
import TaxonsDnDContext from './TaxonsDnDContext/TaxonsDnDContext';

const TaxonomiesDnDView = (props: ITaxonomiesDeatils) => {
  const {taxons} = props;
  return (
    <>
      <View flex={1} style={{marginHorizontal:12}}>
        <View>
          {taxons &&
            taxons.length > 0 &&
            taxons.map((taxons: any, index: any) => {
              return <TaxonsDnDContext onActionPerformed={props.onActionPerformed} key={index} taxon={taxons} />;
            })}
        </View>
      </View>
    </>
  );
};
export default TaxonomiesDnDView;
