import {Drawer, Modal} from 'antd';
import {Button, View} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../constants';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';

interface IConfirmChangeDefaultLocation {
  onConfirm:() => void;
  isOpen: boolean;
  onClose:() => void;
  titleMessage: string;
  heading: string;
}

const ConfirmChangeDefaultLocation = (props: IConfirmChangeDefaultLocation) => {
  const {onClose, onConfirm, isOpen, heading, titleMessage} = props;
  const intl = useIntl();
  return (
    <>
      <Modal title={heading} open={isOpen}
        onCancel={() => {
          onClose()
        }}
        footer={null}
      >
        <View>
          <DisplayText size='mdMedium' textLocalId={titleMessage} />
          <Button.Group justifyContent={'flex-end'} style={{marginTop: 20}}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onClose()
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            />
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onConfirm();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'confirm',
                }),
                withRightBorder: false,
              }}
            />
          </Button.Group>
        </View>
      </Modal>

    </>
  );
};

export default ConfirmChangeDefaultLocation;
