import {useContext, useEffect, useState} from 'react';
import {DayOptimizerSelectedViewCode} from '../DayOptimizerConstants';
import {PatientData} from '../interfaces';
import {useLazyQuery} from '@apollo/client';
import {IParticipantSearch} from '../../../CalendarWidget/ParticipantAutoComplete/ParticipantInterfaces';
import {UserQueries} from '../../../../../services';
import {ParticipantType} from '../../../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {USER_ROLE_CODES} from '../../../../../constants/MlovConst';
import {getBooleanFeatureFlag} from '../../../../../utils/commonUtils';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import FeatureFlags from '../../../../../constants/FeatureFlags.enums';
import {USER_ACCESS_PERMISSION} from '../../../../RightSideContainer/UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../../../SideMenuBar/SideBarConst';
import {USER_FETCH_LIST_SUCCESS} from '../../../../../context-provider/DayOptimizerContext/actionTypes';
import {getPatientCondition} from '../../../../../services/CommonService/AidBoxService';

interface IPatientDODashboardParams {
  accountLocationUuids: string[];
  dispatch: any;
}

interface IPatientDODashboardState {
  selectedViewCode: DayOptimizerSelectedViewCode | undefined;
  selectedPatient: PatientData | undefined;
  users: IParticipantSearch[];
}

export const useDOPatientListingHeader = (
  params: IPatientDODashboardParams
) => {
  const commonData = useContext(CommonDataContext);

  const [componentState, setComponentState] =
    useState<IPatientDODashboardState>({
      selectedViewCode: undefined,
      selectedPatient: undefined,
      users: [],
    });

  const isMultiTenancyEnabled = getBooleanFeatureFlag(
    commonData.userSettings,
    FeatureFlags.IS_MULTI_TENANCY_ENABLED
  );

  const searchUsers = (searchText: string) => {
    getUsers({
      variables: {
        params: getUserSearchParams(searchText, params.accountLocationUuids),
      },
    });
  };

  const getUserSearchParams = (
    searchString: string,
    accountLocationUuids?: string[]
  ) => {
    return {
      isActive: true,
      name: searchString ? `%${searchString}%` : '',
      limit: 20,
      userRoleCodes: [
        USER_ROLE_CODES.CARE_PROGRAM_MANAGER,
        USER_ROLE_CODES.CLINICAL_NURSE_SPECIALIST,
        USER_ROLE_CODES.CERTIFIED_NURSE_MIDWIVES,
        USER_ROLE_CODES.NURSE_PRACTITIONER,
        USER_ROLE_CODES.PHYASST,
      ],
      orderBy: {name: 'asc'},
      ...(isMultiTenancyEnabled && {
        permittedFor: {
          allOf: accountLocationUuids?.map((locationId) => {
            return {
              resourceCode: `${USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}/${MAIN_MENU_CODES.DASHBOARD}`,
              accountLocationId: locationId,
            };
          }),
        },
      }),
    };
  };

  const [getUsers] = useLazyQuery(UserQueries.GET_USERS_BY_RESOURCE_CODES, {
    onCompleted: (data: any) => {
      const users = data?.searchUsers?.users || data?.users;
      params.dispatch({
        type: USER_FETCH_LIST_SUCCESS,
        payload: users || [],
      });
    },
    onError: (error) => {
      // setIsOpen(true);
    },
  });

  useEffect(() => {
    searchUsers('');
  }, []);

  return {
    searchUsers,
    users: componentState?.users,
  };
};

export const fetchICDConditionsList = async (
  patientId: string,
  practiceLocationId: string
) => {
  try {
    const response = await getPatientCondition(
      patientId || '0',
      practiceLocationId || ''
    );
    const mappedConditions = response?.data?.entry?.map((entry: any) => {
      const recordedDate = entry?.resource?.meta?.lastUpdated || '';
      return entry?.resource?.code?.coding?.map((coding: any) => ({
        recordedDate: recordedDate,
        code: coding?.code,
        display: coding?.display,
      }));
    });
    const flatConditions = mappedConditions?.flat();
    return flatConditions;
  } catch (error) {
    return [];
  }
};
