import React from 'react';

const CallEnd = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="48"
      fill="none"
      viewBox="0 0 49 48"
    >
      <g filter="url(#filter0_d_2779_215392)">
        <rect
          width="44"
          height="44"
          x="2.5"
          y="1"
          fill="#D92D20"
          rx="22"
        ></rect>
        <g clipPath="url(#clip0_2779_215392)">
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18.825 23.675a16.492 16.492 0 01-2.558-7.192 1.666 1.666 0 011.658-1.816h2.5a1.666 1.666 0 011.667 1.433c.105.8.3 1.586.583 2.342A1.666 1.666 0 0122.3 20.2l-1.058 1.058m12.425-7.425L15.333 32.167m8.067-8.075a13.332 13.332 0 002.842 2.166L27.3 25.2a1.667 1.667 0 011.758-.375c.756.282 1.542.478 2.342.583a1.666 1.666 0 011.433 1.667v2.5a1.667 1.667 0 01-1.816 1.667 16.492 16.492 0 01-7.192-2.559 16.18 16.18 0 01-2.775-2.225l2.35-2.366z"
          ></path>
        </g>
        <rect
          width="43"
          height="43"
          x="3"
          y="1.5"
          stroke="#D92D20"
          rx="21.5"
        ></rect>
      </g>
      <defs>
        <filter
          id="filter0_d_2779_215392"
          width="48"
          height="48"
          x="0.5"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2779_215392"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2779_215392"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_2779_215392">
          <path
            fill="#fff"
            d="M0 0H20V20H0z"
            transform="translate(14.5 13)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default CallEnd;
