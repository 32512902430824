import React from 'react';
import {Colors} from '../../../../styles/Colors';
import {ICommonSvgProps} from '../interfaces';

const ProductSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';
  return (
    <svg
      style={{width: 'inherit', height: 'inherit'}}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.10261 17.1056H1.92015C1.77746 17.1057 1.63615 17.0777 1.5043 17.0232C1.37244 16.9687 1.25262 16.8887 1.15169 16.7879C1.05075 16.687 0.970688 16.5672 0.916063 16.4354C0.86143 16.3036 0.833313 16.1623 0.833313 16.0196V4.61351C0.833313 4.32526 0.947821 4.04881 1.15164 3.845C1.35546 3.64117 1.6319 3.52666 1.92015 3.52666H4.63644C4.64877 2.81452 4.94033 2.13571 5.44831 1.63645C5.9563 1.13718 6.64006 0.857422 7.35231 0.857422C8.06457 0.857422 8.74831 1.13718 9.25631 1.63645C9.76431 2.13571 10.0558 2.81452 10.0682 3.52666H12.7836C12.9263 3.52666 13.0676 3.55479 13.1995 3.60941C13.3312 3.66404 13.4511 3.74411 13.5519 3.84505C13.6527 3.94598 13.7327 4.0658 13.7872 4.19766C13.8418 4.32951 13.8698 4.47082 13.8696 4.61351V5.63191"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.35114 3.45912C7.23865 3.45912 7.14746 3.36792 7.14746 3.25544C7.14746 3.14295 7.23865 3.05176 7.35114 3.05176"
        stroke={strokeColor}
        stroke-width="1.25"
      />
      <path
        d="M7.35114 3.45912C7.46363 3.45912 7.55482 3.36792 7.55482 3.25544C7.55482 3.14295 7.46363 3.05176 7.35114 3.05176"
        stroke={strokeColor}
        stroke-width="1.25"
      />
      <path
        d="M15.6996 7.73633V12.7705L18.7189 15.9773C18.9566 16.2622 19.1071 16.6096 19.1523 16.9779C19.1975 17.3461 19.1356 17.7196 18.9739 18.0535C18.8123 18.3875 18.5578 18.6678 18.2409 18.8608C17.924 19.0538 17.5582 19.1513 17.1873 19.1416H11.3131C10.9422 19.1515 10.5762 19.054 10.2592 18.8611C9.94219 18.6682 9.68753 18.388 9.52578 18.054C9.36403 17.72 9.30203 17.3464 9.34728 16.978C9.39244 16.6097 9.54294 16.2622 9.78061 15.9773L12.8024 12.7705V7.73633H15.6996Z"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.78308 15.9775H18.7214"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.80872 6.71973H10.3477"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.80872 9.60938H8.71826"
        stroke={strokeColor}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.80872 12.499H6.68142"
        stroke="#EAE3F6"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default React.memo(ProductSvg);
