import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../../styles';
export const baseMargin = {
  marginTop: 24,
  marginLeft: 24,
  marginRight: 24,
  marginBottom: 24,
};
export const styles = StyleSheet.create({
  iconView: {
    flex: 0.8,
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  loadingView: {
    ...baseMargin,
  },
  spinnerStyle: {
    margin: '0 auto',
    color: 'black',
    position: 'absolute',
    zIndex: 100011111,
    backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0
  },
  formElement: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  formLabel: {
    marginLeft: 4,
  },
  wrapper: {
    // paddingVertical: 4
  },
  orderListContainer: {
    shadowColor: 'black',
    shadowOffset: {width: 2, height: 2},
    shadowOpacity: 0.2,
    shadowRadius: 8,
    elevation: 10,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 1,
    overflow: 'hidden',
    marginRight: 8,
    // marginBottom: 24,
    marginTop: 8,
  },
});
