export const TIME_ZONE_LIST: {[index: string]: string} = {
  'International Date Line West (GMT−12:00)': 'Etc/GMT+12',
  'Midway Island (GMT-11:00)': 'Pacific/Midway',
  'American Samoa (GMT-11:00)': 'Pacific/Pago_Pago',
  'Hawaii (GMT-10:00)': 'Pacific/Honolulu',
  'Alaska (GMT-08:00)': 'America/Juneau',
  'Pacific Time (US & Canada) (GMT-07:00)': 'America/Los_Angeles',
  'Tijuana (GMT-07:00)': 'America/Tijuana',
  'Mountain Time (US & Canada) (GMT-06:00)': 'America/Denver',
  'Arizona (GMT-07:00)': 'America/Phoenix',
  'Chihuahua (GMT-06:00)': 'America/Chihuahua',
  'Mazatlan (GMT-06:00)': 'America/Mazatlan',
  'Central Time (US & Canada) (GMT-05:00)': 'America/Chicago',
  'Saskatchewan (GMT-06:00)': 'America/Regina',
  'Guadalajara (GMT-05:00)': 'America/Mexico_City',
  'Mexico City (GMT-05:00)': 'America/Mexico_City',
  'Monterrey (GMT-05:00)': 'America/Monterrey',
  'Central America (GMT−06:00)': 'America/Guatemala',
  'Eastern Time (US & Canada) (GMT-04:00)': 'America/New_York',
  'Indiana (East) (GMT-04:00)': 'America/Indiana/Indianapolis',
  'Bogota (GMT-05:00)': 'America/Bogota',
  'Lima (GMT-05:00)': 'America/Lima',
  'Quito (GMT-05:00)': 'America/Lima',
  'Atlantic Time (Canada) (GMT-03:00)': 'America/Halifax',
  'Caracas (GMT-04:00)': 'America/Caracas',
  'La Paz (GMT-04:00)': 'America/La_Paz',
  'Santiago (GMT-04:00)': 'America/Santiago',
  'Newfoundland (GMT-02:30)': 'America/St_Johns',
  'Brasilia (GMT-03:00)': 'America/Sao_Paulo',
  'Buenos Aires (GMT-03:00)': 'America/Argentina/Buenos_Aires',
  'Montevideo (GMT-03:00)': 'America/Montevideo',
  'Georgetown (GMT-04:00)': 'America/Guyana',
  'Puerto Rico (GMT-04:00)': 'America/Puerto_Rico',
  'Greenland (GMT-02:00)': 'America/Godthab',
  'Mid-Atlantic (GMT-02:00)': 'Atlantic/South_Georgia',
  'Azores (GMT-01:00)': 'Atlantic/Azores',
  'Cape Verde Is. (GMT-01:00)': 'Atlantic/Cape_Verde',
  'Dublin (GMT+01:00)': 'Europe/Dublin',
  'Edinburgh (GMT+01:00)': 'Europe/London',
  'Lisbon (GMT+01:00)': 'Europe/Lisbon',
  'London (GMT+01:00)': 'Europe/London',
  'Casablanca (GMT+01:00)': 'Africa/Casablanca',
  'Monrovia (GMT+00:00)': 'Africa/Monrovia',
  'UTC (UTC+00:00)': 'Etc/UTC',
  'Belgrade (GMT+02:00)': 'Europe/Belgrade',
  'Bratislava (GMT+02:00)': 'Europe/Bratislava',
  'Budapest (GMT+02:00)': 'Europe/Budapest',
  'Ljubljana (GMT+02:00)': 'Europe/Ljubljana',
  'Prague (GMT+02:00)': 'Europe/Prague',
  'Sarajevo (GMT+02:00)': 'Europe/Sarajevo',
  'Skopje (GMT+02:00)': 'Europe/Skopje',
  'Warsaw (GMT+02:00)': 'Europe/Warsaw',
  'Zagreb (GMT+02:00)': 'Europe/Zagreb',
  'Brussels (GMT+02:00)': 'Europe/Brussels',
  'Copenhagen (GMT+02:00)': 'Europe/Copenhagen',
  'Madrid (GMT+02:00)': 'Europe/Madrid',
  'Paris (GMT+02:00)': 'Europe/Paris',
  'Amsterdam (GMT+02:00)': 'Europe/Amsterdam',
  'Berlin (GMT+02:00)': 'Europe/Berlin',
  'Bern (GMT+02:00)': 'Europe/Zurich',
  'Zurich (GMT+02:00)': 'Europe/Zurich',
  'Rome (GMT+02:00)': 'Europe/Rome',
  'Stockholm (GMT+02:00)': 'Europe/Stockholm',
  'Vienna (GMT+02:00)': 'Europe/Vienna',
  'West Central Africa (GMT+01:00)': 'Africa/Algiers',
  'Bucharest (GMT+03:00)': 'Europe/Bucharest',
  'Cairo (GMT+02:00)': 'Africa/Cairo',
  'Helsinki (GMT+03:00)': 'Europe/Helsinki',
  'Kyiv (GMT+03:00)': 'Europe/Kiev',
  'Riga (GMT+03:00)': 'Europe/Riga',
  'Sofia (GMT+03:00)': 'Europe/Sofia',
  'Tallinn (GMT+03:00)': 'Europe/Tallinn',
  'Vilnius (GMT+03:00)': 'Europe/Vilnius',
  'Athens (GMT+03:00)': 'Europe/Athens',
  'Istanbul (GMT+03:00)': 'Europe/Istanbul',
  'Minsk (GMT+03:00)': 'Europe/Minsk',
  'Jerusalem (GMT+03:00)': 'Asia/Jerusalem',
  'Harare (GMT+02:00)': 'Africa/Harare',
  'Pretoria (GMT+02:00)': 'Africa/Johannesburg',
  'Kaliningrad (GMT+02:00)': 'Europe/Kaliningrad',
  'Moscow (GMT+03:00)': 'Europe/Moscow',
  'St. Petersburg (GMT+03:00)': 'Europe/Moscow',
  'Volgograd (GMT+03:00)': 'Europe/Volgograd',
  'Samara (GMT+04:00)': 'Europe/Samara',
  'Kuwait (GMT+03:00)': 'Asia/Kuwait',
  'Riyadh (GMT+03:00)': 'Asia/Riyadh',
  'Nairobi (GMT+03:00)': 'Africa/Nairobi',
  'Baghdad (GMT+03:00)': 'Asia/Baghdad',
  'Tehran (GMT+04:30)': 'Asia/Tehran',
  'Abu Dhabi (GMT+04:00)': 'Asia/Muscat',
  'Muscat (GMT+04:00)': 'Asia/Muscat',
  'Baku (GMT+04:00)': 'Asia/Baku',
  'Tbilisi (GMT+04:00)': 'Asia/Tbilisi',
  'Yerevan (GMT+04:00)': 'Asia/Yerevan',
  'Kabul (GMT+04:30)': 'Asia/Kabul',
  'Ekaterinburg (GMT+05:00)': 'Asia/Yekaterinburg',
  'Islamabad (GMT+05:00)': 'Asia/Karachi',
  'Karachi (GMT+05:00)': 'Asia/Karachi',
  'Tashkent (GMT+05:00)': 'Asia/Tashkent',
  'Chennai (GMT+05:30)': 'Asia/Kolkata',
  'Kolkata (GMT+05:30)': 'Asia/Kolkata',
  'Mumbai (GMT+05:30)': 'Asia/Kolkata',
  'New Delhi (GMT+05:30)': 'Asia/Kolkata',
  'Kathmandu (GMT+05:45)': 'Asia/Kathmandu',
  'Astana (GMT+06:00)': 'Asia/Dhaka',
  'Dhaka (GMT+06:00)': 'Asia/Dhaka',
  'Sri Jayawardenepura (GMT+05:30)': 'Asia/Colombo',
  'Almaty (GMT+06:00)': 'Asia/Almaty',
  'Novosibirsk (GMT+07:00)': 'Asia/Novosibirsk',
  'Rangoon (GMT+06:30)': 'Asia/Rangoon',
  'Bangkok (GMT+07:00)': 'Asia/Bangkok',
  'Hanoi (GMT+07:00)': 'Asia/Bangkok',
  'Jakarta (GMT+07:00)': 'Asia/Jakarta',
  'Krasnoyarsk (GMT+07:00)': 'Asia/Krasnoyarsk',
  'Beijing (GMT+08:00)': 'Asia/Shanghai',
  'Chongqing (GMT+08:00)': 'Asia/Chongqing',
  'Hong Kong (GMT+08:00)': 'Asia/Hong_Kong',
  'Urumqi (GMT+08:00)': 'Asia/Urumqi',
  'Kuala Lumpur (GMT+08:00)': 'Asia/Kuala_Lumpur',
  'Singapore (GMT+08:00)': 'Asia/Singapore',
  'Taipei (GMT+08:00)': 'Asia/Taipei',
  'Perth (GMT+08:00)': 'Australia/Perth',
  'Irkutsk (GMT+08:00)': 'Asia/Irkutsk',
  'Ulaanbaatar (GMT+08:00)': 'Asia/Ulaanbaatar',
  'Seoul (GMT+09:00)': 'Asia/Seoul',
  'Osaka (GMT+09:00)': 'Asia/Tokyo',
  'Sapporo (GMT+09:00)': 'Asia/Tokyo',
  'Tokyo (GMT+09:00)': 'Asia/Tokyo',
  'Yakutsk (GMT+09:00)': 'Asia/Yakutsk',
  'Darwin (GMT+09:30)': 'Australia/Darwin',
  'Adelaide (GMT+09:30)': 'Australia/Adelaide',
  'Canberra (GMT+10:00)': 'Australia/Melbourne',
  'Melbourne (GMT+10:00)': 'Australia/Melbourne',
  'Sydney (GMT+10:00)': 'Australia/Sydney',
  'Brisbane (GMT+10:00)': 'Australia/Brisbane',
  'Hobart (GMT+10:00)': 'Australia/Hobart',
  'Vladivostok (GMT+10:00)': 'Asia/Vladivostok',
  'Guam (GMT+10:00)': 'Pacific/Guam',
  'Port Moresby (GMT+10:00)': 'Pacific/Port_Moresby',
  'Magadan (GMT+11:00)': 'Asia/Magadan',
  'Srednekolymsk (GMT+11:00)': 'Asia/Srednekolymsk',
  'Solomon Is. (GMT+11:00)': 'Pacific/Guadalcanal',
  'New Caledonia (GMT+11:00)': 'Pacific/Noumea',
  'Fiji (GMT+12:00)': 'Pacific/Fiji',
  'Kamchatka (GMT+12:00)': 'Asia/Kamchatka',
  'Marshall Is. (GMT+12:00)': 'Pacific/Majuro',
  'Auckland (GMT+12:00)': 'Pacific/Auckland',
  'Wellington (GMT+12:00)': 'Pacific/Auckland',
  "Nuku'alofa (GMT+13:00)": 'Pacific/Tongatapu',
  'Tokelau Is. (GMT+13:00)': 'Pacific/Fakaofo',
  'Chatham Is. (GMT+12:45)': 'Pacific/Chatham',
  'Samoa (GMT+13:00)': 'Pacific/Apia',
};

export const DEFAULT_TIME_ZONE = 'Eastern Time (US & Canada) (GMT-04:00)';
