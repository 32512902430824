import {Alert, Drawer, Switch} from 'antd';
import {Skeleton, Spinner, useMediaQuery} from 'native-base';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions, Text, View} from 'react-native';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  MLOV_CATEGORY,
} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {Colors} from '../../../styles';
import {createCodeByName, getAccountUUID} from '../../../utils/commonUtils';
import {ToastType} from '../../../utils/commonViewUtils';
import {ModalActionInput} from '../../common/ModalActionCommonComponent/ModalActionInput';
import {ModalActionTitle} from '../../common/ModalActionTitle/ModalActionTitle';

import {getMlovListByCategory} from '../../../utils/mlovUtils';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';
import {useToast} from '../../Toast/ToastProvider';
import {ModalActionSubTitle} from '../../common/ModalActionTitle/ModalActionSubTitle';
import useAddRoleAndPermission from './CustomHook/useCreateRoleAndPermissions';
import {
  getDefaultPermissionCode,
  USER_ACCESS_PERMISSION,
} from './UserAccessPermission';
import {IPermissionsList} from './interface';
import {StyleSheet} from 'react-native';

const AddRoleAndPermission = (props: any) => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const accountUuid = getAccountUUID();
  const toast = useToast();
  const [stateData, setStateData] = useState({
    loading: false,
    roleName: '',
    roleCode: '',
    dashboardtabs: [] as IPermissionsList[],
    settingtabs: [] as IPermissionsList[],
    isSettingIsDisabled: false,
  });
  const onCloseModal = () => {
    setShowModal(false);
    props.onFormActionPerformed(COMMON_ACTION_CODES.CLOSE, {});
  };
  const onSave = () => {
    setShowModal(false);
    props.onFormActionPerformed(COMMON_ACTION_CODES.SAVE, {});
  };
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const {width} = Dimensions.get('window');

  const finalWidth = isIPadMiniScreen || isIPadScreen ? width / 2 : width * 0.5;
  const {error, submitLoading, createRoleAndPermission} =
    useAddRoleAndPermission({
      accountUuid: accountUuid,
      roleCode: stateData.roleCode,
      roleName: stateData.roleName,
      permissionList: [...stateData.dashboardtabs, ...stateData.settingtabs],
    });
  const dashboardPermissionList = getMlovListByCategory(
    MLOV_CATEGORY.DASHBOARD
  );
  const settingPermissionList = getMlovListByCategory(MLOV_CATEGORY.SETTING);
  const submit = async () => {
    const createRoleAndPermissionResp = await createRoleAndPermission();
    if (
      createRoleAndPermissionResp?.id &&
      createRoleAndPermissionResp.permission
    ) {
      toast({
        message: intl.formatMessage({id: 'roleCreateSuccessMessage'}),
        toastType: ToastType.success,
      });
      onSave();
    }
  };
  const init = () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const dashboardPermissions = dashboardPermissionList?.map((permission) => {
      return {
        entityCode: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
        actionCode: permission.code,
        actionId: permission.id,
        name: permission.value,
        permissionCode: getDefaultPermissionCode(),
      };
    });
    const settingPermissions = settingPermissionList?.map((permission) => {
      return {
        entityCode: USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
        actionCode: permission.code,
        actionId: permission.id,
        name: permission.value,
        permissionCode: getDefaultPermissionCode(),
      };
    });
    setStateData((prev) => {
      return {
        ...prev,
        loading: false,
        settingtabs: settingPermissions,
        dashboardtabs: dashboardPermissions,
      };
    });
  };

  useEffect(() => {
    init();
    setShowModal(true);
  }, []);
  return (
    <Drawer
      visible={showModal}
      width={finalWidth}
      onClose={() => {
        onCloseModal();
      }}
      closable={false}
      title={
        <ModalActionTitle
          title={'addRole'}
          titleColor={''}
          titleSize={24}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                onCloseModal();
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'add',
              isDisabled: stateData.loading,
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                submit();
              },
            },
          ]}
        />
      }
    >
      {error && (
        <View style={styles.marginBottom6}>
          <Alert message={error} type="error" />
        </View>
      )}
      {stateData?.loading && <Skeleton.Text lines={4} />}
      {submitLoading && (
        <Spinner
          style={styles.spinnerStyle}
          size="lg"
        />
      )}
      {!stateData.loading && (
        <View>
          <ModalActionInput
            fieldIs={'input'}
            label={'enterRoleName'}
            placeholder="Role Name"
            isRequired={true}
            value={stateData.roleName}
            onChangeText={(value: any) => {
              const name = value || '';
              const code = createCodeByName(value);
              setStateData((prev) => {
                return {
                  ...prev,
                  roleName: name,
                  roleCode: code,
                  errorName: '',
                };
              });
            }}
            extraStyle={{flex: 1}}
          />
          <View
            style={styles.viewStyle}
          >
            <View style={styles.dashboardViewStyle}>
              <ModalActionSubTitle
                marginTop={5}
                subTitle={'Dashboard Permissions'}
              />
              {stateData.dashboardtabs?.map(
                (singlePermission, index: number) => {
                  return (
                    <View
                      key={singlePermission.actionCode}
                      style={styles.dashboardPermissionViewStyle}
                    >
                      <Text
                        style={styles.text}
                      >
                        {singlePermission.name}
                      </Text>
                      <Switch
                        checked={
                          singlePermission.permissionCode ===
                          USER_ACCESS_PERMISSION.ALL_PERMISSION_CODE
                        }
                        style={{
                          backgroundColor:
                            singlePermission.permissionCode ===
                            USER_ACCESS_PERMISSION.ALL_PERMISSION_CODE
                              ? Colors.FoldPixel.PRIMARY300
                              : '',
                        }}
                        onChange={(checked) => {
                          setStateData((prev) => {
                            let isSettingIsDisabled = false;
                            const updatedPermissions = [...prev.dashboardtabs];
                            let settingPermissions = [...prev.settingtabs];
                            const currentPermissionObj =
                              updatedPermissions[index];
                            const isCheck = checked
                              ? USER_ACCESS_PERMISSION.ALL_PERMISSION_CODE
                              : USER_ACCESS_PERMISSION.NO_PERMISSION_CODE;
                            currentPermissionObj.permissionCode = isCheck;
                            if (
                              singlePermission.actionCode ===
                                MAIN_MENU_CODES.SETTING &&
                              !checked
                            ) {
                              isSettingIsDisabled = true;
                              settingPermissions = settingPermissions.map(
                                (permission) => ({
                                  ...permission,
                                  permissionCode: isCheck,
                                })
                              );
                            }
                            return {
                              ...prev,
                              dashboardtabs: updatedPermissions,
                              settingtabs: settingPermissions,
                              isSettingIsDisabled: isSettingIsDisabled,
                            };
                          });
                        }}
                      />
                    </View>
                  );
                }
              )}
            </View>
            <View style={styles.dashboardViewStyle}>
              <ModalActionSubTitle
                marginTop={5}
                subTitle={'Setting Permissions'}
              />
              {stateData.settingtabs?.map((singlePermission, index: number) => {
                return (
                  <View
                    key={singlePermission.actionCode}
                    style={styles.view1}
                  >
                    <Text
                      style={styles.textStyle}
                    >
                      {singlePermission.name}
                    </Text>
                    <Switch
                      checked={
                        singlePermission.permissionCode ===
                        USER_ACCESS_PERMISSION.ALL_PERMISSION_CODE
                      }
                      disabled={stateData.isSettingIsDisabled}
                      style={{
                        backgroundColor:
                          singlePermission.permissionCode ===
                          USER_ACCESS_PERMISSION.ALL_PERMISSION_CODE
                            ? Colors.FoldPixel.PRIMARY300
                            : '',
                      }}
                      onChange={(checked) => {
                        setStateData((prev) => {
                          const updatedPermissions = [...prev.settingtabs];
                          const currentPermissionObj =
                            updatedPermissions[index];

                          currentPermissionObj.permissionCode = checked
                            ? USER_ACCESS_PERMISSION.ALL_PERMISSION_CODE
                            : USER_ACCESS_PERMISSION.NO_PERMISSION_CODE;

                          return {
                            ...prev,
                            settingtabs: updatedPermissions,
                          };
                        });
                      }}
                    />
                  </View>
                );
              })}
            </View>
          </View>
        </View>
      )}
    </Drawer>
  );
};

const styles = StyleSheet.create({
  marginBottom6: {
    marginBottom: 6,
  },
  spinnerStyle: {
    marginVertical: 0,
    marginHorizontal: 'auto',
    position: 'absolute',
    zIndex: 1000,
    height: '100%',
    width: '100%',
    padding: 0,
  },
  viewStyle: {
    height: '77vh',
    overflow: 'scroll',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  dashboardViewStyle: {
    flex: 0.45,
  },
  text: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.FoldPixel.GRAY300,
  },
  textStyle: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.FoldPixel.GRAY300,
  },
  view1: {
    marginBottom: 4,
    paddingHorizontal: 6,
    paddingVertical: 10,
    borderBottomWidth: 0,
    borderColor: Colors.FoldPixel.GRAY100,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dashboardPermissionViewStyle: {
    marginBottom: 4,
    paddingHorizontal: 6,
    paddingVertical: 10,
    borderBottomWidth: 0,
    borderColor: Colors.FoldPixel.GRAY100,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
});

export default AddRoleAndPermission;
