import {Skeleton, Table} from 'antd';
import {View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {MODULE_PAGINATION_COUNT} from '../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {getOrdersListOneTime} from '../../../../../services/Stripe/StripeServices';
import { TableWrapper } from '../../../../common/TableWrapper';
import {getMemberShipOrderColumnsOneTime} from '../../ProductsAndServices/Orders/OrderMainTableView/OrderListView/OrderListViewColumns';
import {getFormattedMembershipOrderListOneTime} from '../../ProductsAndServices/Orders/OrdersHelperUtils';
import {styles} from '../CommonStyles';
import { withMiniContactViewHOC } from '../../../../MiniContactViewHOC';

const ChargesOrdersList = (props: any) => {
  const navigate = useNavigate();
  const {searchText} = props;
  const [programsTableState, setProgramsTableState] = useState({
    isLoading: true,
    isNoDataFound: false,
    selectedOrder: {} as any,
    searchString: '',
    orderList: [] as any[],
    orderDisplyList: [] as any[],
    ordertMeta: {} as any,
    page: 1,
    sortedInfo: null,
    pageSize: MODULE_PAGINATION_COUNT.PRODUCTS_LIST_PAGE_SIZE,
  });
  let {sortedInfo}: any = {...programsTableState};
  sortedInfo = sortedInfo || {};
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setProgramsTableState({
      ...programsTableState,
      page: pagination.current,
      sortedInfo: sorter,
      pageSize: pagination.pageSize,
    });
  };
  const onRowClicked = (rowData: any) => {
    navigate(`/admin/oneTime/order/${rowData.id}`, {
      state: {
        orderDetail: rowData.rowData,
        isOneTime: true,
      },
      replace: false,
    });
  };
  const getOrderOfMembership = async () => {
    const orderListResponse = await getOrdersListOneTime().catch(() => {
      setProgramsTableState((prev) => {
        return {
          ...prev,
          isLoading: false,
          isNoDataFound: true,
        };
      });
    });

    if (orderListResponse?.data) {
      const formattedDataList = getFormattedMembershipOrderListOneTime(
        orderListResponse?.data.data
      );
      setProgramsTableState((prev) => {
        return {
          ...prev,
          orderList: [],
          orderDisplyList: [...formattedDataList],
          isLoading: false,
        };
      });
    }
  };
  const onActionPerformed = (actionCode: string, data: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ON_NAVIGATE_TO_DETAIL:
        props?.navigateOrOpenContactIdDrawer?.(data.id);
        break;
    }
  };
  useEffect(() => {
    setProgramsTableState((prev) => {
      return {
        ...prev,
        searchString: props.searchText || '',
      };
    });
    setProgramsTableState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    getOrderOfMembership();
  }, [
    programsTableState.page,
    programsTableState.pageSize,
    programsTableState.searchString,
    searchText,
  ]);
  const {height} = Dimensions.get('window');
  const finalHeight = height - 310;

  return (
    <>
      {programsTableState.orderList && !programsTableState.isLoading && (
        <TableWrapper
          pagination={{
            pageSize: programsTableState.pageSize,
            current: programsTableState.page,
          }}
        >
          <Table
            rowKey={(record) => {
              return record.id;
            }}
            rowClassName={(record, index) =>
              index % 2 == 0 ? 'table-row-light' : ''
            }
            columns={getMemberShipOrderColumnsOneTime(
              sortedInfo,
              onActionPerformed
            )}
            dataSource={programsTableState.orderDisplyList}
            scroll={{x: 700, y: finalHeight}}
            className="lead-list-table-view strip-payment"
            onChange={onTableChange}
            onRow={(_record: any) => {
              return {
                onClick: () => {
                  onRowClicked(_record);
                },
              };
            }}
            pagination={false}
          />
        </TableWrapper>
      )}
      {programsTableState.isLoading && (
        <View style={styles.loadingView}>
          <Skeleton active />
        </View>
      )}
    </>
  );
};

export default withMiniContactViewHOC(ChargesOrdersList);
