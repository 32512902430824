
import React from 'react';
import { Colors } from '../../../../styles/Colors';
import { ICommonSvgProps } from '../interfaces';

const DashboardSvg = (props: ICommonSvgProps) => {
    const { isSelected } = props;
    const strokeColor = isSelected
        ? Colors.primary['500']
        : props.isPrimary
            ? Colors.primary['300']
            : props?.defaultColor || '#fff';

    return (
        <svg
            style={{ width: 'inherit', height: 'inherit' }}
            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 3H14V14H21V3Z" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 3H3V7H10V3Z" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 10H3V21H10V10Z" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21 17H14V21H21V17Z" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );
};

export default React.memo(DashboardSvg);
