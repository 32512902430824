import {StyleProp, View, ViewStyle} from 'react-native';
import {WorkflowContext, FlowType} from '../../context/WorkflowContext';
import {WorkflowRender} from '../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowRenderView';

const ViewCareJourneyFlow = (props: {
  workflowContextData: any;
  setWorkflowContextData: any;
  containerStyles?: StyleProp<ViewStyle>;
  nodes: any;
  edges: any;
}) => {
  return (
    <>
      <WorkflowContext.Provider value={props.workflowContextData}>
        <View style={[{height: '70vh'}, props.containerStyles]}>
          <WorkflowRender
            setWorkflowContext={props.setWorkflowContextData}
            onNodeDataChange={() => {}}
            isViewOnly={true}
            isWorkFlowEditable={false}
            flowType={FlowType.careJourney}
            libNodeIdList={[]}
            nodes={props.nodes || []}
            setNodes={() => {}}
            edges={props.edges || []}
            setEdges={() => {}}
          />
        </View>
      </WorkflowContext.Provider>
    </>
  );
};

export default ViewCareJourneyFlow;
