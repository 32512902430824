import React from "react";

const HighSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 12 10"
    >
      <path
        fill="url(#paint0_linear_1621_4739)"
        fillRule="evenodd"
        d="M10.964 5.13a1.113 1.113 0 01-.633.186c-.238 0-.465-.067-.633-.185L5.853 2.419 2.007 5.131a1.121 1.121 0 01-.63.177 1.11 1.11 0 01-.625-.185c-.166-.117-.26-.275-.262-.441-.003-.166.088-.325.25-.444L5.22 1.08c.167-.119.395-.185.633-.185.237 0 .465.066.633.185l4.478 3.158c.168.118.262.279.262.446 0 .168-.094.328-.262.447zm0 3.79a1.113 1.113 0 01-.633.185c-.238 0-.465-.066-.633-.185L5.853 6.21 2.007 8.92a1.121 1.121 0 01-.63.178 1.11 1.11 0 01-.625-.185c-.166-.117-.26-.276-.262-.441-.003-.166.088-.326.25-.445L5.22 4.87c.167-.118.395-.185.633-.185.237 0 .465.067.633.185l4.478 3.158c.168.119.262.28.262.447 0 .167-.094.328-.262.446z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1621_4739"
          x1="5.858"
          x2="5.858"
          y1="0.895"
          y2="9.105"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF623E"></stop>
          <stop offset="1" stopColor="#ED876F"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default HighSvg;
