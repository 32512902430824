import {View, StyleSheet} from 'react-native';
import React, { useContext, useEffect } from 'react';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {IFormResponse} from '../../RightSideContainer/Forms/FHFormio/CustomComponents/ClinicalAssessment/interfaces';
import HomeMonitoringView from '../../RightSideContainer/Forms/FHFormio/CustomComponents/HomeMonitoringView/HomeMonitoringView';
import AddOrUpdateVitals from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/AddOrUpdateVitals/AddOrUpdateVitals';
import { CapabilityResource } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { CommonDataContext } from '../../../context/CommonDataContext';
import LabReports from '../../RightSideContainer/Forms/FHFormio/CustomComponents/LabReports/LabReports';
import { useCarePlanGoalsApi } from '../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/CarePlanGoalsHook';
import { Colors } from '../../../styles';
import { getCapability } from '../../../utils/capabilityUtils';

const InsightsCareBoardView = (props: {
  contactData?: IContact;
  formattedContactData?: IContact;
}) => {
  const {contactData, formattedContactData} = props;
  const mlovData = useContext(CommonDataContext);
  const patientId =
    contactData?.patient?.patientId || contactData?.patient?.patientUuid;

  const accountLocationUuid =
    contactData?.contactPracticeLocations &&
    contactData?.contactPracticeLocations?.length > 0
      ? contactData.contactPracticeLocations[0]?.accountLocation?.uuid
      : '';
  // const ehrCapabilitiesForObservations = mlovData.ehrCapabilities.find((item: { resourceName: any; }) => item.resourceName && [CapabilityResource.observation].includes(item.resourceName));

  const ehrCapabilitiesForObservations = getCapability(
    CapabilityResource.observation,
    '',
    accountLocationUuid
  );

  const [fetchedGoals, goalsLoading, fetchGoals] = useCarePlanGoalsApi();

  useEffect(() => {
    fetchGoals(contactData?.uuid);
  }, [])

  return (
    <>
      <View
        style={styles.container}
      >
        <HomeMonitoringView
          contactId={`${contactData?.uuid}`}
          patientId={patientId || ''}
          locationId={accountLocationUuid || ''}
          defaultCollapse={false}
          component={{
            label: 'Home Monitoring',
          }}
          isSidecar
          goals={fetchedGoals}
        />
      </View>
      <View
        style={styles.container}
      >
        <AddOrUpdateVitals
          validateRef={undefined}
          disabled={true}
          component={{
            label: 'Vitals',
          }}
          options={{
            capabilities: ehrCapabilitiesForObservations,
            contactId: `${contactData?.uuid}`,
            patientId: patientId || '',
            locationId: accountLocationUuid || '',
            isSidecar: true,
            goals: fetchedGoals,
          }}
          onChange={() => {}}
        />
      </View>
      <View
        style={styles.container}
      >
        <LabReports
          contactId={contactData?.uuid}
          accountLocationId={accountLocationUuid || ''}
          patientId={patientId}
          ccmDate={''}
          relevantCodes={[]}
          isSideCar
          goals={fetchedGoals}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    backgroundColor: Colors.Custom.White,
    marginHorizontal: 12,
    overflow: 'hidden',
    marginTop: 20
  },
});

export default InsightsCareBoardView;
