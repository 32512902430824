import {Skeleton, Table} from 'antd';
import {View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {MODULE_PAGINATION_COUNT} from '../../../../constants';
import {getAuditLogs} from '../../../../services/Logs/AuditLogs';
import {getAccountUUID} from '../../../../utils/commonUtils';
import {TableWrapper} from '../../../common/TableWrapper';
import {orderDetailsTab} from '../../Sales/ProductsAndServices/Orders/OrderDetailView/OrderDetailsTabs';
import {styles} from '../CommonStyles';
import {getAuditLogsListViewColumns} from './AuditLogsListViewColumns';

const AuditLogsList = (props: any) => {
  const navigate = useNavigate();
  const accountUuid = getAccountUUID();

  const {searchText} = props;
  const [programsTableState, setProgramsTableState] = useState({
    isLoading: true,
    isNoDataFound: false,
    selectedOrder: {} as any,
    searchString: '',
    orderList: [] as any[],
    orderDisplyList: {pagination: {total: 0}, data: []} as any,
    ordertMeta: {} as any,
    page: 1,
    sortedInfo: null,
    pageSize: MODULE_PAGINATION_COUNT.PRODUCTS_LIST_PAGE_SIZE,
  });
  let {sortedInfo}: any = {...programsTableState};
  sortedInfo = sortedInfo || {};
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setProgramsTableState({
      ...programsTableState,
      page: pagination.current,
      sortedInfo: sorter,
      pageSize: pagination.pageSize,
    });
  };
  const getOrderOfMembership = async () => {
    const orderListResponse = await getAuditLogs(
      accountUuid,
      programsTableState.page,
      programsTableState.pageSize
    ).catch(() => {
      setProgramsTableState((prev) => {
        return {
          ...prev,
          isLoading: false,
          isNoDataFound: true,
        };
      });
    });
    const orderDisplayList = orderListResponse
      ? orderListResponse?.data
      : {pagination: {total: 0}, data: []};


    setProgramsTableState((prev) => {
      return {
        ...prev,
        orderList: [],
        orderDisplyList: orderDisplayList,
        isLoading: false,
      };
    });
    //
  };

  const handlePaginationChange = async (
    currentPage: any,
    currentPageSize: any
  ) => {
    setProgramsTableState((prev) => {
      return {
        ...prev,
        page: currentPage,
        pageSize: currentPageSize,
      };
    });
  };

  useEffect(() => {
    setProgramsTableState((prev) => {
      return {
        ...prev,
        searchString: props.searchText || '',
      };
    });
    setProgramsTableState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    getOrderOfMembership();
  }, [programsTableState.searchString, searchText, programsTableState.page]);
  const {height} = Dimensions.get('window');
  const finalHeight = height - 300;
  return (
    <>
      {programsTableState.orderList && !programsTableState.isLoading && (
        <Table
          rowKey={(record) => {
            return record.id;
          }}
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          columns={getAuditLogsListViewColumns(sortedInfo)}
          dataSource={programsTableState.orderDisplyList.data}
          scroll={{x: 800, y: finalHeight}}
          className="lead-list-table-view strip-payment"
          onChange={onTableChange}
          pagination={{
            position: ['bottomCenter'],
            pageSize: programsTableState.pageSize,
            current: programsTableState.page,
            total: programsTableState.orderDisplyList.pagination.total,
            onChange: handlePaginationChange,
          }}
        />
      )}
      {programsTableState.isLoading && (
        <View style={styles.loadingView}>
          <Skeleton active />
        </View>
      )}
    </>
  );
};

export default AuditLogsList;
