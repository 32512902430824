import {JourneyStatus} from '../Enums';
import {IMlov} from '../Interfaces';
import {styles} from './StatusViewStyles';

const getStyle = (status: IMlov) => {
  switch (status.code) {
    case JourneyStatus.Active:
      return styles.activeStatus;

    case JourneyStatus.Full:
      return styles.fullStatus;

    case JourneyStatus.Draft:
      return styles.otherStatus;

    default:
      break;
  }
};

export default {getStyle};
