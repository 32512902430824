import {Box} from 'native-base';
import React from 'react';

function AppointmentLinkedIcon() {
  return (
    <Box mx={2}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill="#825AC7"
          d="M14.188 1.25h-2.25V.687a.563.563 0 00-1.126 0v.563H5.189V.687a.562.562 0 10-1.125 0v.563h-2.25A1.687 1.687 0 00.125 2.938v11.25a1.687 1.687 0 001.688 1.687h12.374a1.687 1.687 0 001.688-1.688V2.938a1.687 1.687 0 00-1.688-1.687zM1.812 2.375h12.376a.562.562 0 01.562.563v1.687H1.25V2.937a.563.563 0 01.563-.562zM14.188 14.75H1.812a.563.563 0 01-.562-.563V5.75h13.5v8.438a.562.562 0 01-.563.562z"
        ></path>
        <path
          fill="#825AC7"
          d="M10.447 7.573l-3.544 3.038-1.32-1.32a.563.563 0 00-.795.796l1.688 1.687a.563.563 0 00.763.03l3.937-3.375a.561.561 0 10-.73-.854v-.002z"
        ></path>
      </svg>
    </Box>
  );
}

export default AppointmentLinkedIcon;
