import {CRM_PUBLIC_BASE_URL} from '../../constants/Configs';
import BaseService from '../../services/CommonService/BaseService';
import {AccountConfiguration, IAppData, ILoginForm, IUserAccountsResp, SimplePromptResult} from './interface';
import { shouldUpdateApp } from '../../utils/commonUtils';
import { MOBILE_APP_DATA_URL } from '../../services/Employer/EmployerServices/baseUrlPath';

import { removeSpacesFromText } from '../../utils/commonUtils';
import { Platform } from 'react-native';
import ReactNativeBiometrics, { BiometryTypes } from 'react-native-fold-biometrics';
import { evnName } from '../../constants/BaseUrlConst';

const dummyData: ILoginForm = {
  userName: 'test',
  password: 'test',
};
export const validateLogin = (loginFormData: ILoginForm): boolean => {
  return (
    loginFormData.userName === dummyData.userName &&
    loginFormData.password === dummyData.password
  );
};

export const validate = (formData: any) => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const errors: any = {};
  let validate = true;
  const inputUserName = removeSpacesFromText(formData.userName);
  errors.userName = !inputUserName
    ? 'Username or email address is required'
    : !inputUserName.match(emailRegex)
    ? 'Username or email address is not valid'
    : '';
  errors.password = !formData.password ? 'Password is required' : '';
  if (errors.userName || errors.password) {
    validate = false;
  }
  return {validate: validate, errors: errors};
};

export const validateEmail = (email: string) => {
  email = removeSpacesFromText(email);
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const errors: any = {};
  let validate = true;
  if (
    !evnName()?.length &&
    !isPlusInEmail(email) &&
    extractEmailDomainFromEmail(email) === 'fold.health' &&
    getAccountSubDomainNameAfterHashFromEmail(email)?.length
  ) {
    email = email.split('#')[0]; // Extract username part
  }
  errors.userName = !email.match(emailRegex)
    ? 'Email address is not valid'
    : '';
  if (errors.userName) {
    validate = false;
  }
  return {validate: validate, errors: errors};
}

export const getAccountSubDomainNameAfterHashFromEmail = (email: string) => {
  const atIndex = email.indexOf('@');
  if (atIndex !== -1) {
    const emailDomainPart = email.substring(atIndex + 1);
    const hashIndex = emailDomainPart.indexOf('#');
    if (hashIndex !== -1) {
      const accountSubDomain = email.split('#')[1];
      return accountSubDomain;
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const extractEmailDomainFromEmail = (email: string) => {
  const atIndex = email.indexOf('@');
  if (atIndex !== -1) {
    const emailDomainPart = email.substring(atIndex + 1);
    const hashIndex = emailDomainPart.indexOf('#');
    const domainName = hashIndex !== -1 ? emailDomainPart.substring(0, hashIndex) : emailDomainPart;
    return domainName;
  } else {
    // Handle case where '@' symbol is missing (invalid email format)
    return '';
  }
};

export const checkHashAndExtractEmail = (email: string) => {
  const hashIndex = email.indexOf('#');
  if (hashIndex !== -1) {
    const validEmail = email.substring(0, hashIndex);
    return validEmail;
  } else {
    // No "#" character found in the email.
    return email;
  }
};

export const getEmailAndAccountFromKeychainUsername = (input: string) => {
  if (input.includes('#')) {
    const [extractedEmail, extractedValue] = input.split('#');
    return {
      username: extractedEmail,
      accountId: extractedValue,
    };
  } else {
    return {
      username: input,
    };
  }
};

export const isPlusInEmail = (email: string) => {
  const plusIndex = email.indexOf('+');
  if (plusIndex !== -1) {
    return true;
  }
  return false;
};

export const getUserAccounts = async (
  email: string
): Promise<IUserAccountsResp> => {
  const axios = BaseService.getSharedInstance().axios;
  const bodyStr = JSON.stringify({email: email});
  return axios.post(CRM_PUBLIC_BASE_URL + `/user-accounts`, bodyStr);
};

export const getSsoEnabledAccountsForSidecar = async (): Promise<Array<{
  id: number;
  uuid: string;
  name: string;
  accountSubdomainMap: Array<{
    id: number;
    uuid: string;
    subDomain: string;
  }>;
}>> => {
  const axios = BaseService.getSharedInstance().axios;
  const response = await axios.get(CRM_PUBLIC_BASE_URL + `/sso_enabled_accounts_for_sidecar`);
  return response?.data;
};

export const getAppDataFromBundleId = async (args: {
  bundleId: string;
}): Promise<IAppData | undefined> => {
  const {bundleId} = args;
  const baseService = BaseService.getSharedInstance().axios;
  try {
    const response = await baseService.post(MOBILE_APP_DATA_URL, {
      appId: bundleId,
      os: Platform.OS,
    });
    return response?.data;
  } catch (error) {
    return;
  }
};

export const checkIfUpdatedApp = async (args: {
  bundleId: string
  currentVersion: string
}) => {
  const {bundleId, currentVersion} = args
  const response = await getAppDataFromBundleId({bundleId});
  const minVersion = response?.minimumVersion;
  const appLink = response?.link;
  const shouldUpdate = shouldUpdateApp({
    currentVersion,
    requiredVersion: minVersion || '',
  });
  return {
    shouldUpdate,
    appLink: appLink || '',
  };
}

export const getTrimmedUserNameAndPassword = (args: {
  userName: string
  password: string
}) => {
  const {userName, password} = args;
  return {
    userName: removeSpacesFromText(userName),
    password: removeSpacesFromText(password),
  };
}

export const isSSOEnableForAccount = (
  accountConfigurations: AccountConfiguration[]
) => {
  return accountConfigurations?.filter(
    (item: AccountConfiguration) => item?.configuration?.code === 'SSO_CONFIG'
  );
};

export const openAuthPrompt = async () => {
  const biometrics = new ReactNativeBiometrics({
    allowDeviceCredentials: false,
  });

  try {
    const {available, biometryType} = await biometrics.isSensorAvailable();
    if (available) {
      if (
        biometryType === BiometryTypes.TouchID ||
        biometryType === BiometryTypes.FaceID ||
        biometryType === BiometryTypes.Biometrics
      ) {
        const result = await biometrics.simplePrompt({
          promptMessage: 'Authentication Required',
          cancelButtonText: 'Cancel',
          fallbackPromptMessage: 'Login with credentials',
        });
        return result;
      } else {
        return handleAuthFailed('Biometrics not supported');
      }
    } else {
      return handleAuthFailed('Biometrics is not available on this device');
    }
  } catch (e) {
    return handleAuthFailed(
      'Biometrics failed or Device not Support Fingerprint'
    );
  }
};

export const handleAuthFailed = (message: string) => {
  const failedData: SimplePromptResult = {
    success: false,
    error: message,
  };
  return failedData;
};
