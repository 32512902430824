import {Popover} from 'antd';
import {HStack, Pressable, Text, View} from 'native-base';
import {useEffect, useRef, useState} from 'react';
import {TooltipPlacement} from 'antd/es/tooltip';
import {useContainerDimensions} from '../../CustomHooks/ContainerDimensionHook';
import {ILabelDataView} from '../FilterView/interfaces';

import {Colors} from '../../../styles/Colors';
import {styles} from '../../PersonOmniView/LeftContainer/OtherDetails/OtherDetailsStyles';
import React from 'react';
interface TagDataViewProps {
  tagList: ILabelDataView[];
  noDataString: string;
  popOverConfig?: {
    placement?: TooltipPlacement;
    trigger?: 'click' | 'hover';
    offset?: [number, number];
  }
}
const TagListView = (props: TagDataViewProps) => {
  const {noDataString, tagList} = props;

  const [stateData, setStateData] = useState({
    moreActionPopup: false,
    loading: false
  })
  const componentRef = useRef();
  const {width} = useContainerDimensions(componentRef);
  const popOverWidth = 330;
  const handleMoreActionVisibleChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        moreActionPopup: visible,
      };
    });
  };

  const [maxTagsToShow, setMaxTagsToShow] = useState<ILabelDataView[]>([]);

  const getMaxWidth = () => {
    const tagsToShow: ILabelDataView[] = [];
    if (props?.tagList?.length > 1) {
      tagsToShow.push(props?.tagList[0]);
      setMaxTagsToShow((prev) => {
        return [
          ...tagsToShow
        ]
      });
    }
  };

  useEffect(() => {
    getMaxWidth();
  }, [])

  const renderTags = (index: number, tagItem: ILabelDataView) => {
    return (
      <HStack
        key={index}
        justifyContent={'space-between'}
        alignItems="center"
        backgroundColor={tagItem?.color + '40'}
        style={{
          paddingHorizontal: 8,
          marginHorizontal: 2,
          borderRadius: 16,
          marginTop: 5,
        }}
      >
        <Text
          wordBreak={'break-word'}
          color={Colors.Custom.Gray500}
          size={'smLight'}
          style={{marginRight: 5, maxWidth: popOverWidth}}
        >
          {tagItem?.title}
        </Text>
      </HStack>
    )
  }


  return (
    <>
      <View ref={componentRef}
        style={[
          styles.listContainer,
          {
            backgroundColor: '#fff',
            borderColor: Colors.Custom.Gray200,
            padding: 16,
            paddingVertical: 10,
            width: '100%',
            flex: 1
          },
        ]}
      >

        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <View
            style={{flexDirection: 'row', alignItems: 'center'}}
            maxWidth={'90%'}
          >
            <HStack alignItems={'center'} maxWidth={'100%'} flexWrap={'wrap'} overflow={'auto'}>
              <View maxWidth={'100%'} style={{flexDirection: 'row', alignItems: 'center'}}>
                {maxTagsToShow.map((item, index) => {
                  return <>
                    {renderTags(index, item)}
                  </>
                })}
              </View>
              {(tagList?.length) > 0 && <Popover
                overlayInnerStyle={{padding: 0, borderRadius: 12}}
                overlayClassName='patient-search-select-styles'
                className='patient-search-select-styles'
                overlayStyle={{padding: 0}}
                overlay={{padding: 0}}
                style={{padding: 0, borderRadius: 12}}
                placement={props?.popOverConfig?.placement || 'bottomLeft'}
                trigger={props?.popOverConfig?.trigger || 'click'}
                visible={stateData.moreActionPopup}
                onVisibleChange={handleMoreActionVisibleChange}
                content={
                  <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: popOverWidth}}>
                    <View
                      style={{flexDirection: 'row', alignItems: 'center'}}
                      flexWrap={'wrap'}
                      overflow={'auto'}
                      maxWidth={'90%'}
                    >
                      {tagList && tagList.length && (
                        tagList.map((tagItem: ILabelDataView, index: number) => {
                          return tagItem?.id ? (
                            <>
                              {renderTags(index, tagItem)}
                            </>
                          ) : <></>;
                        })
                      )}

                    </View>
                  </View>
                }
              >
                <Pressable
                  onPress={() => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        moreActionPopup: true,
                      };
                    });
                  }}
                >
                  <Text fontWeight={500} marginTop={1} marginLeft={1} color={'#825AC7'}>
                    {`+${tagList?.length - maxTagsToShow?.length} more`}
                  </Text>
                </Pressable>
              </Popover>}
            </HStack>
          </View>
        </View>
      </View>
    </>
  );
};
export default React.memo(TagListView);
