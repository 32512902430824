import React, {useState} from 'react';
import {Modal, Typography} from 'antd';
import ButtonStyled from '../../ButtonStyled';
import CreateCampaignModal from '../../CreateCampaignModal';
import CreateOpportunityModal from '../../CreateOpportunityModal';
import DisplayArea from '../../DisplayAreaChar';
import DisplayProgressBar from '../../DisplayProgressBar';
import DisplayCard from '../../DisplayCard';
import ListArea from '../../../widgets/components/ListArea';
import HealthStatusData from '../../../widgets/mockData/HealthStatus';

const {Text} = Typography;
const columnsList = [
  {
    title: 'Status',
    dataIndex: 'status',
    render: (text: string) => (text ? <div>{text}</div> : 'No Data'),
  },
  {
    title: 'Number of Members',
    dataIndex: 'members',
    render: (text: string, item: any) => (
      <DisplayProgressBar percent={item.percent.replace('%', '')} text={text} />
    ),
  },
  {
    title: 'Change Since Last Month',
    dataIndex: 'percent',
    render: (text: string) => text,
  },
  {
    title: 'Average of Days Since Last Encounter',
    dataIndex: 'percent',
    render: (text: string, item: any) => (
      <DisplayArea
        data={item.data}
        text={`${item.day} days`}
        id={item.id}
        dataKey="uv"
        color={item.color}
      />
    ),
  },
];

export default function HealthStatusTable({loading}: {loading: boolean}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalCampaign, setIsModalCampaign] = useState(false);
  const [isModalOpportunity, setIsModalOpportunity] = useState(false);
  const [selectedItem, setSelectedItem] = useState({status: '', members: 0});

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModalCampaign = () => {
    setIsModalCampaign(true);
    handleCancel();
  };

  const handleCancelCampaign = () => {
    setIsModalCampaign(false);
  };

  const showModalOpportunity = () => {
    setIsModalOpportunity(true);
    handleCancel();
  };

  const handleCancelOpportunity = () => {
    setIsModalOpportunity(false);
  };

  return (
    <>
      <DisplayCard
        title="Health Status"
        subtitle="Health status distribution in the panel"
        loading={loading}
      >
        <ListArea
          data={HealthStatusData}
          columns={columnsList}
          onClick={(e: any) => {
            const defaultPayload = {
              ...e,
              source: 'Health Status',
            };

            setSelectedItem(defaultPayload);
            showModal();
          }}
        />
      </DisplayCard>
      <Modal
        title={selectedItem?.status}
        width={365}
        className={'patient_engagement_modal'}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Text style={{color: '#667085', marginBottom: '16px'}}>
          {selectedItem?.members} members
        </Text>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            padding: '10px',
          }}
        >
          <ButtonStyled
            className="primaryFilledBtn"
            shape="round"
            size="large"
            onClick={showModalOpportunity}
          >
            Opportunity
          </ButtonStyled>
          <ButtonStyled
            className="primaryFilledBtn"
            shape="round"
            size="large"
            onClick={showModalCampaign}
          >
            Campaign
          </ButtonStyled>
        </div>
      </Modal>
      <CreateCampaignModal
        visible={isModalCampaign}
        handleCancel={handleCancelCampaign}
        selectedItem={selectedItem}
      />
      <CreateOpportunityModal
        visible={isModalOpportunity}
        handleCancel={handleCancelOpportunity}
        selectedItem={selectedItem}
      />
    </>
  );
}
