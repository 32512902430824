import {View} from 'react-native';
import React, { useEffect, useState } from 'react';
import Stack from '../../common/LayoutComponents/Stack';
import {
  getAccountUUID,
  getUserId,
  healthMapName,
  isAlertComponentVisible,
  isHealthMapComponentVisible,
  isSummaryComponentVisible,
  showAssessmentInSideCar,
  showCareJourneyInSideCar,
} from '../../../utils/commonUtils';
import {Table, Tooltip} from 'antd';
import {LLMMap} from '../../PersonOmniView/LeftContainer/LLMMap';
import {LLMView} from '../../PersonOmniView/LeftContainer/llmView';
import {AlertTable} from '../../PersonOmniView/LeftContainer/AlertTable';
import {AssessmentIonSvg} from '../assets/images/AssessmentIconSvg';
import {Divider, Text} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {useIntl} from 'react-intl';
import {Colors} from '../../../styles';
import {getColumns} from '../FormAssessmentHelper';
import PatientCareJourneyListView from '../../RightSideContainer/Journeys/JourneysOfCare/PatientCareJourney/PatientCareJourneyListView';
import {INSIGHTS_JOURNEYS_STATUSES} from '../constants';
import { IFormResponse } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/ClinicalAssessment/interfaces';
import { useHieRequests } from '../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/useHieRequests';
import { AvailableHieDataView } from '../../PersonOmniView/LeftContainer/RecentActivity/AvailableHieDataView';
import { PendingHieRequestsDrawer } from '../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/PendingHieRequestsDrawer';
import { VisitSummary } from '../../PersonOmniView/LeftContainer/RecentActivity/VisitSummary/VisitSummary';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
const InsightsSummaryView = (props: {
  contactData?: IContact;
  formattedContactData?: IContact;
  forms: IFormResponse[];
  assignJourneyOptionClicked?: () => void;
}) => {
  const intl = useIntl();
  const showAssessment = showAssessmentInSideCar();
  const showCareJourney = showCareJourneyInSideCar();

  const {contactData} = props;
  const isHIEEnabled = isAccountConfigEnabled(CONFIG_CODES.HIE_ENABLE) || false;

  const accountLocationUuid =
  contactData?.contactPracticeLocations &&
  contactData?.contactPracticeLocations?.length > 0
    ? contactData.contactPracticeLocations[0]?.accountLocation?.uuid
    : '';

  const [componentState, setComponentState] = useState<{
    showHieRequestsDrawer: boolean;
  }>({
    showHieRequestsDrawer: false,
  });

  const {
    pendingHieRequests,
    fetchHieRequests,
  } = useHieRequests({
    patientUuid: contactData?.patient?.patientUuid,
    accountLocationUuid: accountLocationUuid || '',
    patientId: contactData?.patient?.patientId,
    contactUuid: contactData?.uuid,
    skip: true
  });

  useEffect(()=> {
    if(contactData?.id && !pendingHieRequests.length){
      fetchHieRequests(false);
    }
  }, [contactData?.id])

  const accountUUID = getAccountUUID();
  const userId : number | string = getUserId();
  return (
    <Stack direction="column">
      {pendingHieRequests?.length > 0 && isHIEEnabled && (
        <View
          style={{
            backgroundColor: Colors.Custom.White,
            borderRadius: 8,
            padding: 8,
            marginHorizontal: 14,
            marginTop: 20
          }}
        >
          <AvailableHieDataView
            hieRequestsLoading={false}
            hieRequests={pendingHieRequests}
            onReviewClick={() => {
              setComponentState((prev) => ({
                ...prev,
                showHieRequestsDrawer: true,
              }));
            }}
          />
        </View>
      )}

      <Stack direction="column" style={{paddingHorizontal: 12, marginTop: 20}}>
        {isHealthMapComponentVisible(accountUUID, userId) && (
          <LLMMap contactUUID={props?.contactData?.uuid} widgetName ={healthMapName()}/>
        )}
        {isSummaryComponentVisible(accountUUID, userId) && (
          <LLMView contactUUID={props?.contactData?.uuid} />
        )}
        {isHIEEnabled && <VisitSummary
            personData={props?.formattedContactData} />
        }
        
        {/* 
        /* Commented for future use
        {isAlertComponentVisible(accountUUID, userId) && (
          <AlertTable contactUUID={props?.contactData?.uuid} />
        )} */}
      </Stack>

      {showAssessment && <View
        style={{
          borderRadius: 8,
          backgroundColor: 'white',
          marginHorizontal: 12,
          overflow: 'hidden',
          marginBottom: 8,
        }}
      >
        <Stack
          direction="row"
          style={{
            alignItems: 'center',
            marginVertical: 16,
            marginLeft: 18,
            marginBottom: 10,
          }}
        >
          <AssessmentIonSvg />
          <Text
            color={'#0e0333'}
            fontSize={20}
            fontWeight={600}
            fontFamily={'Manrope-Bold'}
            marginLeft={3}
          >
            {intl.formatMessage({id: 'assessments'})}
          </Text>
          <Tooltip
            title={intl.formatMessage({
              id: 'clinicalFormCategoryNote',
            })}
            placement="top"
          >
            <Feather
              name="info"
              style={{
                fontSize: 16,
                marginLeft: 12,
                marginTop: 4,
                color: Colors.Custom.Gray500,
              }}
            />
          </Tooltip>
        </Stack>
        <Divider />
        <Table
          columns={getColumns(intl)}
          rowKey={(row) => row.id}
          dataSource={props.forms}
          pagination={false}
          size="middle"
        />
      </View>}

      {showCareJourney && props?.formattedContactData && (
        <View
          style={{
            borderRadius: 8,
            backgroundColor: 'white',
            marginHorizontal: 12,
            overflow: 'hidden',
            marginBottom: 12,
          }}
        >
          <PatientCareJourneyListView
            personData={props?.formattedContactData}
            filterStatuses={INSIGHTS_JOURNEYS_STATUSES}
            assignJourneyOptionClicked={props?.assignJourneyOptionClicked}
            isCompactView={true}
          />
        </View>
      )}
      {componentState.showHieRequestsDrawer &&
        <PendingHieRequestsDrawer
          patientId={contactData?.patient?.patientId || ''}
          patientUuid={contactData?.patient?.patientUuid || ''}
          accountLocationId={accountLocationUuid || ''}
          contactUuid={contactData?.uuid}
          onClose={()=> {
            fetchHieRequests(false);
            setComponentState((prev) => ({...prev, showHieRequestsDrawer: false}));
          }}
          contactLocationId={
            contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid
          }
      />}
      {/* {
      <HomeMonitoringView
        patientId={patientId}
        locationId={accountLocationUuid || ''}
        contactId={contactId}
        component={{
          label: 'Home Monitoring',
        }}
        defaultCollapse={false}
      />
    } */}
    </Stack>
  );
};

export default InsightsSummaryView;
