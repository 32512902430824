import React, { useMemo } from 'react';
import { Path, Svg, Circle, Defs, RadialGradient, Stop, LinearGradient } from 'react-native-svg';
import { Colors } from '../../styles';

function SearchRecordsSvg() {
  const memoizedSvg = useMemo(() => (
    <Svg width="102" height="101" viewBox="0 0 102 101" fill="none">
      <Circle cx="51" cy="50.5" r="50" stroke="url(#paint0_radial_2245_904311)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1.46 1.46"/>
      <Circle cx="51.0007" cy="50.4987" r="42.6667" stroke="url(#paint1_radial_2245_904311)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1.46 1.46"/>
      <Path d="M15.8555 50.6016C15.8555 31.2716 31.5255 15.6016 50.8555 15.6016C70.1854 15.6016 85.8555 31.2716 85.8555 50.6016C85.8555 69.9315 70.1854 85.6016 50.8555 85.6016C31.5255 85.6016 15.8555 69.9315 15.8555 50.6016Z" fill="url(#paint2_linear_2245_904311)"/>
      <Path d="M60.3438 59.8418L65.375 64.873M63.9375 49.7793C63.9375 57.3214 57.8234 63.4355 50.2812 63.4355C42.7391 63.4355 36.625 57.3214 36.625 49.7793C36.625 42.2372 42.7391 36.123 50.2812 36.123C57.8234 36.123 63.9375 42.2372 63.9375 49.7793Z" stroke="#8A94A8" strokeWidth="1.59722" strokeLinecap="round"/>
      <Defs>
        <RadialGradient id="paint0_radial_2245_904311" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(51.0001 73.1082) rotate(-90.639) scale(78.4672)">
          <Stop stopColor="#A1AABA"/>
          <Stop offset="1" stopColor="#E4E7EC"/>
        </RadialGradient>
        <RadialGradient id="paint1_radial_2245_904311" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(51.0007 69.791) rotate(-90.639) scale(66.9587)">
          <Stop stopColor="#A1AABA"/>
          <Stop offset="1" stopColor="#E4E7EC"/>
        </RadialGradient>
        <LinearGradient id="paint2_linear_2245_904311" x1="26.0012" y1="23.5" x2="74.0012" y2="84.25" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#F9FAFC"/>
          <Stop offset="1" stopColor="#F1F2F3"/>
        </LinearGradient>
      </Defs>
    </Svg>
  ), []);

  return memoizedSvg;
}

export default SearchRecordsSvg;
