import React from 'react';
import {Text, View} from 'native-base';
import {ComingSoon} from '../../../common/ComingSoon';
import {styles} from './TicketsStyles';
import {ITicketsProps} from '.';

const Tickets = (props: ITicketsProps) => {
  return (
    <View>
      <View style={styles.left}>
        <Text style={styles.leftText}>Tickets</Text>
      </View>
      <View style={styles.container}>
        <View>
          <Text style={styles.textContainer}>{props.tempProp || ''}</Text>
        </View>
        <ComingSoon />
      </View>
    </View>
  );
};

export default Tickets;
