import React from 'react';
import {Colors} from '../../../../styles';
import {ICommonSvgProps} from '../interfaces';

const TeamManagmentSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';
  return (
    <svg
      style={{width: 'inherit', height: 'inherit'}}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1393_129567)">
        <path
          d="M15.8333 0.623047H4.58331C4.23814 0.623047 3.95831 0.902869 3.95831 1.24805V3.74805C3.95831 4.09322 4.23814 4.37305 4.58331 4.37305H15.8333C16.1785 4.37305 16.4583 4.09322 16.4583 3.74805V1.24805C16.4583 0.902869 16.1785 0.623047 15.8333 0.623047Z"
          stroke={strokeColor}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.2083 4.37305V15.623"
          stroke="#EAE3F6"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.95831 15.623V11.873C3.95831 11.5415 4.09001 11.2236 4.32443 10.9892C4.55885 10.7547 4.87679 10.623 5.20831 10.623H15.2083C15.5398 10.623 15.8578 10.7547 16.0922 10.9892C16.3266 11.2236 16.4583 11.5415 16.4583 11.873V15.623"
          stroke="#EAE3F6"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.95831 19.373C4.99385 19.373 5.83331 18.5336 5.83331 17.498C5.83331 16.4625 4.99385 15.623 3.95831 15.623C2.92278 15.623 2.08331 16.4625 2.08331 17.498C2.08331 18.5336 2.92278 19.373 3.95831 19.373Z"
          stroke="#EAE3F6"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.2083 19.373C11.2438 19.373 12.0833 18.5336 12.0833 17.498C12.0833 16.4625 11.2438 15.623 10.2083 15.623C9.17278 15.623 8.33331 16.4625 8.33331 17.498C8.33331 18.5336 9.17278 19.373 10.2083 19.373Z"
          stroke="#EAE3F6"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.4583 19.373C17.4938 19.373 18.3333 18.5336 18.3333 17.498C18.3333 16.4625 17.4938 15.623 16.4583 15.623C15.4228 15.623 14.5833 16.4625 14.5833 17.498C14.5833 18.5336 15.4228 19.373 16.4583 19.373Z"
          stroke="#EAE3F6"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1393_129567">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default React.memo(TeamManagmentSvg);
