import React from 'react';
import {ICommonSvgProps} from './interfaces';

const PackagesEmptySvg = (props: ICommonSvgProps) => {
  return (
    <svg
      width="343"
      height="344"
      viewBox="0 0 343 344"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="171.5"
        cy="171.652"
        r="170.857"
        stroke="url(#paint0_radial_291_44477)"
        stroke-width="1.14286"
      />
      <circle
        cx="171.5"
        cy="171.652"
        r="145.714"
        stroke="url(#paint1_radial_291_44477)"
        stroke-width="1.14286"
      />
      <circle
        cx="171.5"
        cy="171.652"
        r="120.571"
        stroke="url(#paint2_radial_291_44477)"
        stroke-width="1.14286"
      />
      <g filter="url(#filter0_dd_291_44477)">
        <rect
          x="27.5001"
          y="66.5093"
          width="288"
          height="240"
          rx="13.7143"
          fill="white"
        />
        <path
          d="M179.656 220.041L170.649 198.569L160.367 223.28L121.84 206.175L122.737 226.483L171.085 249.224L226.979 221.33L226.211 195.387L179.656 220.041ZM243.061 140.058L187.044 123.224L171.648 137.105L155.817 124.846L100.071 148.273L119.519 164.114L101.947 191.685L157.687 216.412L170.649 185.228L182.207 212.825L240.576 181.932L224.669 157.444L243.071 140.053L243.061 140.058ZM170.429 181.443L124.012 161.531L171.536 140.204L220.755 157.127L170.429 181.443Z"
          fill="#E4E7EC"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_291_44477"
          x="9.21442"
          y="57.3664"
          width="324.571"
          height="276.571"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="9.14286" />
          <feGaussianBlur stdDeviation="9.14286" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_291_44477"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.28571" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_291_44477"
            result="effect2_dropShadow_291_44477"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_291_44477"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_291_44477"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(171.5 249.166) rotate(-90) scale(248.737)"
        >
          <stop stop-color="#E4E7EC" />
          <stop offset="1" stop-color="#E4E7EC" stop-opacity="0.13" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_291_44477"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(171.5 237.797) rotate(-90.639) scale(229.573)"
        >
          <stop stop-color="#E4E7EC" />
          <stop offset="1" stop-color="#E4E7EC" stop-opacity="0.35" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_291_44477"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(171.5 226.428) rotate(-90.639) scale(190.115)"
        >
          <stop stop-color="#E4E7EC" />
          <stop offset="1" stop-color="#E4E7EC" stop-opacity="0.35" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default PackagesEmptySvg;
