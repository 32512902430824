import {IAddStockState} from "./interfaces";

export const getAllVariantList = () => {
  return '';
};
export const validateStockView = (data:IAddStockState)=> {
  let validationOject = ''
  if(data.countOnHand < 0) {
    validationOject = 'Quantity can not be less then 0'
  }
  return validationOject
}
