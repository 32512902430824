import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useContactCareProgramContext} from '../../../ContactCareProgram.context';
import FormsQueries, {
  GET_FORM_BY_ID,
  GET_FORM_LOG,
  GET_FORM_RESPONSE_BY_FORM_LOG_ID,
} from '../../../../../../../services/Forms/FormsQueries';
import {useLazyQuery, useMutation} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../../constants/Configs';
import {usePersonOmniViewContext} from '../../../../../../PersonOmniView/PersonOmniView.context';
import {
  getFormComponentsDataForProgress,
  getPercentageScore,
} from '../../../../../../PublicPages/PublicForm/PublicFormHelper';
import {IExtensiveComponentData} from '../../../../../../PublicPages/PublicForm/interface';
import {cloneDeep, debounce} from 'lodash';
import {forEachExtensiveFormComponent} from '../../../../../Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import {IFormPrefilledByFoldProgressState} from '../../../../../../PublicPages/PublicForm/PublicFormInterfaces';
import {IContactCareProgram} from '../../../../interface';
import {fetchFormResponseFromFormOrFormLog, getFormDetails} from '../../../ContactCareProgramView.utils';
import {FormComponentType} from '../../../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import useFormOptions from '../../../hooks/useFormOptions';
import {useCareProgramStatus} from '../../../hooks/useCareProgramStatus';
import {ContactCareProgramReducerAction} from '../../../reducer';
import {useMainContentContext} from '../../MainContentView.context';
import useFormSubmitRestAPI from '../../../../../../CustomHooks/useFormSubmitRestAPI';
import {CareProgramStatusAction} from '../../../hooks/useCareProgramStatusAction';
import {useCustomToast} from '../../../../../../Toast/ToastProvider';
import {ToastType} from '../../../../../../../utils/commonViewUtils';
import {getMlovIdFromCode, getMlovListFromCategory} from '../../../../../../../utils/mlovUtils';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {MLOV_CATEGORY} from '../../../../../../../constants';
import {FORM_STATUS_CODE} from '../../../../../../../constants/MlovConst';
import useCareProgramStepStatus from '../../../hooks/useCareProgramStepStatus';

export const useAssessment = () => {
  const {dispatch, state, onUpdateCareProgramStatus} = useContactCareProgramContext();
  const {formattedData} = usePersonOmniViewContext();
  const {careProgramStatus} = useCareProgramStatus();
  const {careProgramStepStatus} = useCareProgramStepStatus();
  const isCareProgramInProgress = careProgramStatus.inProgress === state.contactCareProgramDetails?.statusId;
  const isCareProgramCompleted = careProgramStatus.completed === state.contactCareProgramDetails?.statusId;
  const selectedStepLog = state.contactCareProgramDetails?.stepsLog.find(stepLog => stepLog.careProgramStepId === state.selectedStepId);
  const isReadOnlyView = selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.done;

  const mlovData = useContext(CommonDataContext);
  const formStatusMlovList = useMemo(
    () =>
      getMlovListFromCategory(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.FORM_STATUS
      ),
    [mlovData?.CARE_STUDIO_MLOV]
  );

  const submittedStatusId = useMemo(
    () => getMlovIdFromCode(formStatusMlovList, FORM_STATUS_CODE.SUBMITTED),
    [formStatusMlovList?.length]
  );

  const {updateMainContentContextState} = useMainContentContext();
  const [progress, setProgress] = useState(0);
  const toast = useCustomToast();
  const componentStateRef = useRef<IExtensiveComponentData>({
    components: [],
    total: 0,
  });
  const {intakeOptions} = useFormOptions({
    accountLocationUuid: formattedData?.accountLocationUuid,
    patientId: formattedData?.patientId,
    contactId: formattedData?.contactUUID,
    contactUuid: formattedData?.contactUUID,
  });
  const [draftChanges, setDraftChanges] = useState<any>();
  const stepId = state.selectedStepId;
  const [formResponseId, setFormResponseId] = useState<string | undefined>();
  const [assessmentState, setAssessmentState] = useState<{
    status: 'loading' | 'loaded' | 'error' | 'empty' | undefined;
    formComponents: any[];
    formResponse: any;
    formId: string;
    formLogId: string;
    subjectId: string;
    sourceId: string;
    isPreviousFormInstanceExists: boolean;
    isFormSubmitted: boolean;
  }>({
    status: undefined,
    formComponents: [],
    formResponse: null,
    formId: '',
    formLogId: '',
    subjectId: '',
    sourceId: '',
    isPreviousFormInstanceExists: false,
    isFormSubmitted: false,
  });
  const [
    formPrefilledByFoldProgressState,
    setFormPrefilledByFoldProgressState,
  ] = useState<IFormPrefilledByFoldProgressState>({
    componentWiseProgress: {},
    disallowProgressUpdates: true,
    formPrefilledByFoldPercent: 0,
  });

  const [getForm] = useLazyQuery(GET_FORM_RESPONSE_BY_FORM_LOG_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getPrevFormFilledByContact] = useLazyQuery(GET_FORM_LOG, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getFormById] = useLazyQuery(GET_FORM_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const {submitFrom, isLoading: isFormSubmitLoading, isSuccess, isError} = useFormSubmitRestAPI();

  //   Fetch Form
  const fetchForm = useCallback(async () => {
    try {
      setAssessmentState((prev) => ({...prev, status: 'loading'}));
      //   Selected Step
      const selectedStep =
        state?.contactCareProgramDetails?.contactCareProgramSteps?.find(
          (step) => step.id === stepId
        );

      const title = selectedStep?.title;

      //   Form Log
      const {formLogId, formId} = getFormDetails(
        state.contactCareProgramDetails as IContactCareProgram,
        stepId as string
      );

      const {formComponents, submittedData, formLog, previousFormLogIsExisting} =
        await fetchFormResponseFromFormOrFormLog({
          formId,
          formLogId,
          formLogPromise: (formLogId) => getForm({variables: {formLogId}}),
          formByIdPromise: (formId) => getFormById({variables: {id: formId}}),
          prevFormLogPromise: (formId: string, formLogId: string) =>
            getPrevFormFilledByContact({
              variables: {
                where: {
                  contactId: {_eq: formattedData?.contactUUID},
                  formId: {_eq: formId},
                  id: {
                    _nin: [formLogId],
                  },
                },
              },
            }),
          fetchPreviousFormLog: true,
        });

      const isFormSubmitted = submittedStatusId === formLog?.statusId;

      setAssessmentState((prev) => ({
        ...prev,
        ...(Object.keys(submittedData)?.length
          ? {formResponse: {data: submittedData}}
          : {}),
        formComponents:
          formComponents.filter(
            (component: any) => component.type !== FormComponentType.BUTTON
          ) || [],
        subjectId: formLog?.subjectId,
        sourceId: formLog?.sourceId,
        formId: formId as string,
        formLogId: formLogId as string,
        isPreviousFormInstanceExists: previousFormLogIsExisting || false,
        isFormSubmitted: isFormSubmitted || false,
      }));

      const data = getFormComponentsDataForProgress(
          formComponents || [],
          intakeOptions
        );
      componentStateRef.current = data;
    } catch (error) {
      console.log('error', error);
      setAssessmentState((prev) => ({...prev, status: 'error'}));
    } finally {
      setAssessmentState((prev) => ({...prev, status: 'loaded'}));
    }
  }, []);

  useEffect(() => {
    fetchForm();
  }, []);

  // FORM
  const updateProgress = (formData: any) => {
    const percent = getPercentageScore(
      componentStateRef.current,
      formData?.data
    );
    setFormPrefilledByFoldProgressState(prev => ({
      ...prev,
      formPrefilledByFoldPercent: percent,
    }));
    setProgress(percent);
    dispatch?.({
      type: ContactCareProgramReducerAction.SET_STEP_PROGRESS_MAP,
      payload: {id: stepId, progress: percent},
    });
  };

  const debouncedSave = useCallback(
    debounce(async (newData) => {
      setDraftChanges({...newData});
    }, 500),
    [setDraftChanges]
  );

  const onChange = useCallback((formUpdates: any) => {
    if (formUpdates.changed) {
      debouncedSave(formUpdates.data);
    }
  }, []);

  const submitFormResponse = async (data: any) => {
    updateMainContentContextState({headerSavingIndicator: true});
    const response = await submitFrom(data);
    if (response?.id && !formResponseId) {
      setFormResponseId(response?.id);
    }
    updateMainContentContextState({headerSavingIndicator: false});
    // Update Care Program Status to In Progress if it is not already in progress when form is submitted
    if (!isCareProgramInProgress) {
      onUpdateCareProgramStatus(CareProgramStatusAction.IN_PROGRESS);
    }
  }

  useEffect(() => {
    if (draftChanges && assessmentState.formId ) {
      const updatedComponents = cloneDeep(assessmentState.formComponents);
      forEachExtensiveFormComponent(updatedComponents, (component) => {
        if (
          component.key &&
          (draftChanges[component.key] || draftChanges[component.key] == 0)
        ) {
          component.selectedValue = draftChanges[component.key];
        }
      });
      const data: any = {
        formId: assessmentState.formId,
        // eventId: props.params.eventId,
        formResponse: {components: updatedComponents},
        reference: {},
        // patientId: formattedData?.patientId,
        contactId: formattedData?.contactUUID,
        subjectId: assessmentState.subjectId,
        sourceId: assessmentState.sourceId,
        isDraft: true,
        formResponseId: formResponseId,
        foldPrefilledDataProgressByComponent: {
          ...(formPrefilledByFoldProgressState?.componentWiseProgress || {}),
        },
        foldPrefilledDataProgressPercentage:
          formPrefilledByFoldProgressState?.formPrefilledByFoldPercent || 0,
      };
      submitFormResponse(data);
    }
  }, [draftChanges]);


  const isFormReadOnly = isCareProgramCompleted || assessmentState.isPreviousFormInstanceExists || assessmentState.isFormSubmitted || isReadOnlyView;

  const handleOnChange = useCallback((data: any) => {
    if (onChange) {
      if (!isCareProgramCompleted && !isFormReadOnly && assessmentState.status === 'loaded') {
        onChange(data);
      }
      updateProgress(data);
    }
  }, [
    isCareProgramCompleted,
    isFormReadOnly,
    assessmentState.status
  ]);


  const handleAcceptPreviousFormInstanceAction = (action: 'accept' | 'createNew') => {
    // This is simulation of loading state
    setAssessmentState((prev) => ({...prev, status: 'loading'}));
    setTimeout(() => {
      setAssessmentState((prev) => {
        const newState = {...prev};
        if (action === 'accept') {
          newState.isPreviousFormInstanceExists = false;
        } else {
          newState.formResponse = null;
          newState.isPreviousFormInstanceExists = false;
        }
        return {...newState, status: 'loaded'};
      });
      toast({
        message:
          action === 'accept'
            ? 'Form accepted successfully'
            : 'New form instance created',
        toastType: ToastType.success,
      });
    }, 800);
  }

  return {
    ...assessmentState,
    intakeOptions,
    handleOnChange,
    disableForm: isFormReadOnly,
    formSubmitLoading: isFormSubmitLoading,
    handleAcceptPreviousFormInstanceAction
  };
};
