import React from 'react';
import {Colors} from '../../../../styles/Colors';
import {ICommonSvgProps} from '../interfaces';

const AnalyticsSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected ? Colors.primary['500'] : '#fff';

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 15.5524C18.8263 19.2893 15.3351 22 11.2108 22C6.12383 22 2 17.8762 2 12.7892C2 8.66488 4.71065 5.1737 8.44759 4M14.0527 2.0869C17.8556 3.14845 20.8515 6.14438 21.9131 9.94727C22.3719 11.5909 20.9458 13 19.2394 13H12.5449C11.6917 13 11 12.3083 11 11.4551V4.76062C11 3.05419 12.4091 1.6281 14.0527 2.0869Z"
        stroke={strokeColor}
        stroke-linecap="round"
      />
    </svg>
  );
};

export default React.memo(AnalyticsSvg);
