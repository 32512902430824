import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';

export const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: Colors.Custom.BackgroundColor,
  },
  modalFooter: {
    backgroundColor: Colors.Custom.BackgroundColor,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formElement: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  readonlyLineContainer:{marginTop: 10},
  readonlyLineContainerForBulkModal:{marginTop: 5},
  readPillContainer: {
    backgroundColor: Colors.Custom.Gray300,
    padding: 2.5,
    paddingHorizontal: 5,
    marginRight: 5,
    borderRadius: 5,
    marginTop:10
  },
  readonlyContainer: {paddingHorizontal: 24, paddingBottom: 24},
});
