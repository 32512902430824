import {View, Text, Button} from "native-base";
import {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {QUALITY_MEASURES_PATIENT_TYPE} from "../../../../../../constants";
import {getAccountUUID, isEmployerRole} from "../../../../../../utils/commonUtils";
import CustomTabsView from "../../../../../common/CustomTabsView/CustomTabsView";
import {ITabsList} from "../../../../../common/CustomTabsView/interfaces";
import {TABLE_TOP_BAR_ACTION_CODES, TableTopBar} from "../../../../../common/TableTopBar";
import TitleSubtitleView from "../../../../../common/TitleSubtitleView/TitleSubtitleView";
import {getPathFromHash} from "../../../../ContentManagement/ContentManagementUtils";
import PatientView from "./PatientView/PatientView";
import {useLazyQuery} from "@apollo/client";
import QualityMeasuresService from "../../../../../../services/Contracts/QualityMeasures.service";
import {CARESTUDIO_APOLLO_CONTEXT} from "../../../../../../constants/Configs";
import {Colors} from "../../../../../../styles";
import AntIcon from 'react-native-vector-icons/AntDesign';
import {getProgressBarColor} from "../../../HelperFiles/TablesColumnsView";
import {extractNumber} from "../../../HelperFiles/utils";
import LoopSvg from "../../../../../common/Svg/LoopSvg";
import {Tooltip} from "antd";
import InfoSvg from "../../../../../common/Svg/InfoSvg";
import {useIntl} from "react-intl";
import LocalStorage from "../../../../../../utils/LocalStorage";
import PerformanceSvg from "../../../../../common/Svg/PerformanceSvg";
import {QUALITY_MEASURE_STORAGE_KEY} from "../../../HelperFiles/const";
import {IMeasureDetails} from "../../../HelperFiles/interface";

const QualityMeasuresDetails = () => {
  const {contractId,qmId} = useParams();
  const accountUuid = getAccountUUID();
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const navigate = useNavigate();
  const [stateData, setStateData] = useState({
    selectedActionCode: '',
    selectedRawData: {} as any,
    measureMetCount: 0,
    measureNotMetCount: 0,
    measureDetails: {} as IMeasureDetails,
  })

  useEffect(() => {
    LocalStorage.getItem(QUALITY_MEASURE_STORAGE_KEY.MEASURE_DETAILS).then((res) => {
      const measureDetails = JSON.parse(res);
      const data = {
        measureGoal: extractNumber(measureDetails?.goal || ''),
        measurePerformanceScore: extractNumber(measureDetails?.performanceScore || '0'),
        measureInverse: measureDetails?.isInverse,
        measureName: measureDetails?.measureName,
        contractName: measureDetails?.contractName
      }
      setStateData((prev) => {
        return {
          ...prev,
          measureDetails: data
        }
      })
    });
  },[])
  const intl = useIntl();
  const tabList = (): ITabsList[] => {
    const tabs: ITabsList[] = [
      {
        key: 'patients-qualified',
        title: 'Measure Met',
        path: 'patients-qualified',
        count: stateData.measureMetCount ?? 0,
        tabContainerElem: () => {
          return (
            <PatientView
              updateContactCount={(contactCount: number) => {
                setStateData(prev=> {
                  return {
                    ...prev,
                    measureMetCount: contactCount
                  }
                })
              }}
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED}
            />
          )
        }
      },
      {
        key: 'patients-unqualified',
        title: 'Measure Not Met',
        path: 'patients-unqualified',
        count: stateData.measureNotMetCount ?? 0,
        tabContainerElem: () => {
          return (
            <PatientView 
              updateContactCount={(contactCount: number) => {
                setStateData(prev=> {
                  return {
                    ...prev,
                    measureNotMetCount: contactCount
                  }
                })
              }}
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED}
            />
          )
        }
      }
    ]
    return tabs
  }

  const [qualityMeasureReport] = useLazyQuery(
    QualityMeasuresService.qualityMeasureReport,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );
  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any,
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: actionCode,
            selectedRawData: actionData,
          };
        });
      break;
    }
  };
  const getSelectedTopBar = (selectedTab: ITabsList) => {
    if ( selectedTab.key === 'programs') {
      return false
    }
    return  <TableTopBar
    selectedTab={selectedTab.key}
    title=""
    alignSearchBarFlexEnd={true}
    hStackMinHight={70}
    isDividerNotVisible={true}
    onActionPerformed={(actionCode: string, actionData: any) => {
      onTableTopBarActionPerformed(actionCode, actionData);
    }}
  />
  }
  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab)
  }
  const qualityMeasuresCountByType = async (patientType: string) => {
    const filterObject = {
      isLatest: { _eq: true },
      contractId: { _eq: contractId || '' },
      measureId: { _eq: qmId || '' },
      tenantId: { _eq: accountUuid },
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED && {
        numeratorFlag: { _eq: true },
      }),
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED && {
        numeratorFlag: { _eq: false },
        denominatorFlag: { _eq: true },
      }),
    };
  
    try {
      const { data } = await qualityMeasureReport({
        variables: {
          where: filterObject,
        },
      });
      return data?.aggregateQmMeasureReport?.aggregate?.count || 0;
    } catch (error) {
      return 0;
    }
  };
  
  const getCountData = async () => {
    try {
      const measureMetCount = await qualityMeasuresCountByType(QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED);
      const measureNotMetCount = await qualityMeasuresCountByType(QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED);
  
      setStateData(prev => {
        return {
          ...prev,
          measureMetCount,
          measureNotMetCount,
        };
      });
    } catch (error) {
      setStateData(prev => ({
        ...prev,
        measureMetCount: 0,
        measureNotMetCount: 0,
        error: "Error fetching data",
      }));
    }
  };
  const showBackButton= isEmployerRole() ? false : true;
  useEffect(() => {
    const path = getPathFromHash(window.location.hash);
    const tabIndex = tabList().findIndex((item) => item.path === path);
    setSelectedTab(tabList()[tabIndex]);
    getCountData();
  }, [])
  return (
    <View flex={1}>
      <View>
        <TitleSubtitleView
          title={'contracts'}
        />
      </View>
      <View padding={2} flexDirection={'row'} borderBottomWidth={1} borderBottomColor={Colors.FoldPixel.GRAY150}>
        <View flexDirection={'row'} width={'50%'}>
          <View>
            {showBackButton? (
              <View>
                <Button
                  backgroundColor={'white'}
                  _hover={{bg: Colors.primary['100'] + '7a' || ''}}
                  onPress={() => {
                    LocalStorage.removeItem(QUALITY_MEASURE_STORAGE_KEY.MEASURE_DETAILS);
                    navigate(`/members/contracts/${contractId}/qualityMeasures`)
                  }}
                >
                  <AntIcon color={Colors.FoldPixel.GRAY300} name="arrowleft" size={18} />
                </Button>
              </View>
            ):(
              <></>
            )}
          </View>
          <View>
            <Text style={{
              color: Colors.FoldPixel.GRAY300,
              fontSize: 12,
              fontWeight: '400',
              lineHeight: 16.8,
            }}>
              {stateData?.measureDetails?.contractName}
            </Text>
            <Text style={{
              color: Colors.FoldPixel.GRAY400,
              fontSize: 14,
              fontWeight: '500',
              lineHeight: 16.8,
              fontFamily: 'Manrope-Bold'
            }}>
              {stateData?.measureDetails?.measureName}
            </Text>
          </View>
        </View>
        <View alignItems={'flex-end'} justifyContent={'center'} width={'50%'}>
          <View borderColor={Colors.FoldPixel.GRAY150} borderRadius={6} padding={2} borderWidth={0.5} flexDirection={'row'} alignItems={'center'}>
            <View style= {{
              marginRight: 8,
            }}>
              <PerformanceSvg/>
            </View>
            {stateData?.measureDetails?.measureInverse && <View style={{
              alignItems: 'center',
              flexDirection: 'row',
              backgroundColor: Colors.FoldPixel.GRAY50,
              borderRadius: 4,
              width: 85,
              paddingHorizontal: 6,
              height: 20,
              marginRight: 6,
            }}>
              <View style={{
                marginTop: 1
              }}>
                <LoopSvg />
              </View>
              <Text style={{marginLeft: 4,color: Colors.FoldPixel.GRAY300,fontSize: 12}}>{'Inverse'}</Text>
              <Tooltip
                title={intl.formatMessage({id: 'lowScore'})}
                placement={'top'} destroyTooltipOnHide={true}
              >
                <View style={{
                  marginLeft: 4,
                  marginTop: 2,
                }}>
                  <InfoSvg customDimension={'12'} customColor={Colors.FoldPixel.GRAY250} />
                </View>
              </Tooltip>
            </View>}
            <Text style ={{
              color: Colors.FoldPixel.GRAY300,
              fontSize: 14,
              fontWeight: '500',
              lineHeight: 16.8,
            }}>{'Performance rate:  '}</Text>
            <View width={500} style={{flexDirection: 'row',alignItems: 'center'}}>
              <View style={{width: '88%'}}>
                <View>
                  <div
                    style={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: '#F6F7F8',
                      width: '100%',
                      flexDirection: 'row',
                      alignItems: 'center',
                      display: 'flex',
                      position: 'relative',
                    }}
                  >
                    <div className="fold-progress-container">
                      <div className="fold-progress-bar">
                        <div
                          className="fold-progress-fill"
                          style={{
                            width: `${stateData?.measureDetails?.measureGoal}%`,
                            background: `repeating-linear-gradient(
                              135deg,
                              ${getProgressBarColor(
                                stateData?.measureDetails?.measurePerformanceScore||0, stateData?.measureDetails?.measureGoal||0, stateData?.measureDetails?.measureInverse
                            )},
                              ${getProgressBarColor(
                                stateData?.measureDetails?.measurePerformanceScore ||0, stateData?.measureDetails?.measureGoal || 0, stateData?.measureDetails?.measureInverse
                            )} 2.5px,
                              transparent 2.5px,
                              transparent 3.5px
                            )`,
                          }}
                        >
                          {' '}
                          <div className="fold-progress-overlay"></div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        height: 8,
                        backgroundColor: getProgressBarColor(
                          stateData?.measureDetails?.measurePerformanceScore||0,stateData?.measureDetails?.measureGoal||0,stateData?.measureDetails?.measureInverse
                        ),
                        width: `${stateData?.measureDetails?.measurePerformanceScore||0}%`,
                        borderRadius: 4,
                        position: 'relative',
                      }}
                    ></div>
                    <div
                      style={{
                        height: 8,
                        backgroundColor: 'transparent',
                        width: `${100-(stateData?.measureDetails?.measurePerformanceScore||0)}%`,
                        borderRadius: 4,
                      }}
                    ></div>
                  </div>
                </View>
              </View>
              <Text size="smMedium" ml={3} color={Colors.FoldPixel.GRAY200}>
                {stateData?.measureDetails?.measurePerformanceScore||0}%
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View>
        <CustomTabsView
          contentTopMargin={0}
          showHeading={true}
          backButtonRouteFunc={()=> {
            navigate(`/members/contracts/${contractId}/qualityMeasures`);
          }}
          basePath={`/members/contracts/${contractId}/qualityMeasures/${qmId}`}
          tabsList={tabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
          canNavigate={true}
          onTabClick={(tabItem) => {
            onTabClick(tabItem);
          }}
        />
      </View>
    </View>
  )
}

export default QualityMeasuresDetails;
