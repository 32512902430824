export const EHR = {
  ATHENA_CALIBER: 'ATHENA_CALIBER',
  AIDBOX: 'AIDBOX',
  CANVAS: 'CANVAS',
  ELATION_CALIBER: 'ELATION_CALIBER',
  FOLD: 'FOLD',
  FHIR_STORE: 'FHIR_STORE',
};

export const FHIR_RESOURCE = {
  ALLERGY: 'AllergyIntolerance',
  CLAIM: 'Claim',
  APPOINTMENT: 'Appointment',
  CONDITION: 'Condition',
  DIAGNOSTIC_REPORT: 'DiagnosticReport',
  ENCOUNTER: 'Encounter',
  IMMUNIZATION: 'Immunization',
  MEDICATION_REQUEST: 'MedicationRequest',
  MEDICATION_STATEMENT: 'MedicationStatement',
  OBSERVATION: 'Observation',
  PATIENT: 'Patient',
  PRACTITIONER: 'Practitioner',
  PROCEDURE: 'Procedure',
  QUESTIONNAIRE: 'Questionnaire',
  QUESTIONNAIRE_RESPONSE: 'QuestionnaireResponse',
  FAMILY_MEMBER_HISTORY: 'FamilyMemberHistory',
  HINT: 'Hint',
  ELATION: 'Elation',
  SPRUCE: 'Spruce',
  LOCATION: 'Location',
  DOCUMENT_REFERENCE: 'DocumentReference',
  INTEGRATION_ADMIN: 'IntegrationAdmin',
  SERVICE_REQUEST: "ServiceRequest",
  LEAD: "Lead",
  ORGANIZATION: 'Organization',
  CLINICAL_DATA: "ClinicalData",
  PAST_MEDICAL_HISTORY: "MedicalHistory",
  BARRIER: 'Barrier',
  COVERAGE: 'Coverage'
};

export const CUSTOM_FIELDS =  'Custom Field'

export const SEARCH_TYPES = {
  LANGUAGE: 'Language',
  APPOINTMENT_CANCEL_REASON: 'AppointmentCancelReason',
  EHR: 'EHR',
  LAB_RESULT_STATUS: 'LabResultStatus',
};

export const WORKFLOW = {
  APPOINTMENT: 'Appointment',
  CARE_PLAN: 'CarePlan',
  PATIENT_CREATE: 'PatientCreate',
};

export const OPERATION_CODE = {
  APPLICABLE: 'APPLICABLE',
  REQUIRED: 'REQUIRED',
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const TELECOM_SYSTEM = {
  EMAIL: 'email',
  PHONE: 'phone',
};

export const KEY_ALLOWED_OPERATIONS = {
  BIRTH_SEX: 'birthSex',
  LEGAL_SEX: 'legalSex',
  GENDER: 'gender',
  MARITAL_STATUS: 'maritalStatus',
  SEXUAL_ORIENTATION: 'sexualOrientation',
  PRONOUN : 'pronoun',
};

export enum PatientRecordActions {
  accept, deny
}

export const RESOURCE_BLOCK_MESSAGE_ID = 'resourceBlockedError'

export const FHIR_CODE_SYSTEMS = {
  SNOMED: 'http://snomed.info/sct',
  CPT: 'CPT',
  LOINC: 'http://loinc.org',
  RXNORM: 'http://www.nlm.nih.gov/research/umls/rxnorm',
  ATHENA: 'Athena',
  ICD_10: 'ICD10',
  CVX: 'http://hl7.org/fhir/sid/cvx'
}

export const FHIR_SYSTEM_CODE_TYPES = [
  {
    code: 'SNOMED',
    system: FHIR_CODE_SYSTEMS.SNOMED
  },
  {
    code: 'CPT',
    system: FHIR_CODE_SYSTEMS.CPT
  },
  {
    code: 'LOINC',
    system: FHIR_CODE_SYSTEMS.LOINC
  },
  {
    code: 'RXNORM',
    system: FHIR_CODE_SYSTEMS.RXNORM
  },
  {
    code: 'ATHENA',
    system: FHIR_CODE_SYSTEMS.ATHENA
  },
  {
    code: 'CVX',
    system: FHIR_CODE_SYSTEMS.CVX
  },
  {
    code: 'ICD_10',
    system: FHIR_CODE_SYSTEMS.ICD_10
  }
]