import CommonService from '../../../../../services/CommonService/CommonService';
export const attachAgentBotToInbox = (
  inboxId: number,
  agentBotId: number | undefined,
  accountId: number | undefined
) => {
  const commonService = CommonService.getCommonServiceInstance();
  return commonService.axiosService.post(
    `/accounts/${accountId}/inboxes/${inboxId}/set_agent_bot`,
    {
      agent_bot: agentBotId == -1 ? null : agentBotId,
    }
  );
};
