import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {Colors} from '../../../../styles/Colors';
import {ICommonSvgProps} from '../interfaces';

const ArrowDownSvg = (props: ICommonSvgProps) => {
  return (
    <Svg width={props?.width || "12"} height={props?.height || "13"} viewBox="0 0 12 13" fill="none">
      <Path
        d="M6 2L6 10M6 10L9 7M6 10L3 7"
        stroke={props?.customStrokeColor || Colors.FoldPixel.GRAY300}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default React.memo(ArrowDownSvg);
