import {Pressable, View} from 'native-base';
import React, { useContext } from 'react';
import {Colors} from '../../styles';
import {TestIdentifiers, testID} from '../../testUtils';
import {Link} from 'react-router-dom';
import {getIconsFromMainMenu} from './SideBarHelper';
import {DisplayText} from '../common/DisplayText/DisplayText';
import {IGlobalWarnings} from '../../utils/interfaces';
import {GLOBAL_WARNINGS} from '../../utils/commonViewUtils';
import {FilterDataContext} from '../../context/FilterDataContext';
import {styles} from './SideMenuBarStyle';
import { getBooleanFeatureFlag, navigateToNewSettingTab } from '../../utils/commonUtils';
import { CommonDataContext } from '../../context/CommonDataContext';
import FeatureFlags from '../../constants/FeatureFlags.enums';

export interface ISideMenuBarItemProps {
  currentSelectedKey: string;
  menuCode: string;
  menuName: string;
  menuCollaps: boolean;
  path: string;
  onGlobalWarningOpen: (menuData: IGlobalWarnings) => void;
  onLinkClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    path: string
  ) => void;
}

const SideMenuBarItem = (props: ISideMenuBarItemProps) => {
  const filterContext = React.useContext(FilterDataContext);
  const commonData = useContext(CommonDataContext);
  const isNewSettingTabEnabled = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_NEW_SETTING_TAB_ENABLED);

  const onMenuPress = () => {
    filterContext?.clearFilterData?.();
    const menuData = GLOBAL_WARNINGS.find(
      (menu: IGlobalWarnings) => menu.menuCode === props.menuCode
    );
    if (!menuData?.menuCode) {
      props?.onGlobalWarningOpen({} as IGlobalWarnings);
    } else {
      props?.onGlobalWarningOpen(menuData);
    }
  };

  const onMenuLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const menuPath = props.path;
    if (menuPath && menuPath == '/admin/setting' && isNewSettingTabEnabled) {
      navigateToNewSettingTab(menuPath, () => {
        props.onLinkClick(e, menuPath);
      });
      e.preventDefault();
    } else {
      props.onLinkClick(e, menuPath);
    }
  }

  return (
    <View
      backgroundColor={
        props.currentSelectedKey === props.menuCode ? '#7F56D9' : 'transparent'
      }
      style={[
        styles.menuItemContainer,
        props.menuCollaps ? {alignItems: 'center'} : {},
      ]}
    >
      <Pressable
        key={`${props.menuCode}_pressable`}
        onPress={onMenuPress}
        _hover={{
          bg: Colors.primary[400],
        }}
        style={styles.menuItemSubContainer}
        {...testID(`${props.menuCode}_${TestIdentifiers.sidebar}`)}
      >
        <Link
          key={`${props.menuCode}_link`}
          to={props.path || ''}
          className="link-visited"
          onClick={onMenuLinkClick}
        >
          <View style={{alignItems: 'center'}} key={`${props.menuCode}_view`}>
            {getIconsFromMainMenu(
              props.menuCode,
              props.currentSelectedKey,
              props.menuCollaps
            )}
            {props.menuCollaps ? (
              <DisplayText
                key={props.menuCode}
                size={'xsNormal'}
                textLocalId={props.menuName}
                extraStyles={{
                  color: '#fff',
                  fontWeight: '400',
                  fontSize: 12,
                  lineHeight: 20,
                }}
              />
            ) : null}
          </View>
        </Link>
      </Pressable>
    </View>
  );
};

export default SideMenuBarItem;
