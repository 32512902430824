import { FormControl, Select, Text } from 'native-base';
import { useState } from 'react';
import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import { styles } from '../../../CampaignStyles';

export const EmailTemplate = () => {
  const [template, setTemplate] = useState('');
  return (
    <FormControl style={styles.selectScreenTwo}>
      <FormControl.Label>
        <DisplayText textLocalId="emailTemplate" />
        <Text>:</Text>
      </FormControl.Label>
      <Select
        selectedValue={template}
        accessibilityLabel="Choose Template"
        placeholder="Choose Template"
        onValueChange={(itemValue) => setTemplate(itemValue)}
      >
        <Select.Item label="Bloom" value="Bloom" />
        <Select.Item label="Awesome" value="Awesome" />
      </Select>
    </FormControl>
  );
};
