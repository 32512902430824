import {useLazyQuery} from '@apollo/client';
import {DatePicker, Drawer, Select} from 'antd';
import moment from 'moment';
import {Box, Divider, HStack, Radio, Skeleton, Text, View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  MENTION_READ_STATUS,
  MLOV_CATEGORY,
  PERSON_TYPES,
} from '../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {ILoginUserData} from '../../../../../../Interfaces';
import {UserQueries} from '../../../../../../services';
import {Colors} from '../../../../../../styles/Colors';
import {
  getAccountId,
  getAllowedUserPracticeLocationUuidsAndName,
  getUserId,
  getUserUUID,
} from '../../../../../../utils/commonUtils';
import {
  getMomentObjFromFormat,
} from '../../../../../../utils/DateUtils';
import LocalStorage from '../../../../../../utils/LocalStorage';
import {
  getContactTypeId,
  getMlovCodeIdObj,
  getMlovListFromCategory,
} from '../../../../../../utils/mlovUtils';
import {ModalActionRadioSelect} from '../../../../../common/ModalActionCommonComponent/ModalActionRadioSelect';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import {CHANNEL_TYPE_CODE} from '../../ConversationConst';
import {
  dateType,
  FILTER_ICONS,
} from '../ConversationConst';
import {
  canShowAssignedUsers,
  canShowInboxFilter,
  canShowLocationFilter,
  getChannelTypeBasedOnTab,
} from '../ConversationUtils';
import {
  getInboxNameByInboxType,
  getConversationFilterDataV2,
  formatUserLocationData,
} from './ConversationFilterUtils';
import {
  AllUsers,
  IConversationFilterInbox,
  IConversationFilterProps,
  IConversationFilterPropsData,
  IConversationFilterStateV2,
  IUserLocationData,
} from './interface';
import {getAllAccountUsersWithUnreadMsgCountAPI} from '../../ConversationChannelNew/ConversationAPIService';
import React from 'react';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';
import InboxQueries from '../../../../../../services/Inbox/InboxQueries';
import {useIntl} from 'react-intl';
import {CustomUserSearch} from '../../../../../common/CustomUserSearch/CustomUserSearch';
import {USER_SEARCH_TYPE} from '../../../../constants';
import MentionSvg from '../../../../../common/Svg/MentionSvg';
import LocationSvg from '../../../../../common/Svg/LocationSvg';
import InboxSvg from '../../../../../common/Svg/InboxSvg';
import CalendarSvg from '../../../../../common/Svg/CalendarSvg';
import AssignedUsersSvg from '../../../../../common/Svg/AssignedUsersSvg';
import {IInternalUsers, IUser} from '../../../../../common/CustomUserSearch/interface';
import {getConversationFilterPropsCount} from '../../ConversationChannelNew/ConversationSidebarUtils';
import { USER_ACCESS_PERMISSION } from '../../../../UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../../../SideMenuBar/SideBarConst';
import { StyleSheet } from 'react-native';

const {RangePicker} = DatePicker;
const {Option} = Select;
const ConversationFilterV2 = (props: IConversationFilterProps) => {
  const commonData = useContext(CommonDataContext);
  const intl = useIntl();
  const userData = commonData.userData || ({} as ILoginUserData);
  const accountId = getAccountId();
  const userUuid = getUserUUID();
  const userId = getUserId();
  const groupTypeList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.GroupType
  );
  const groupTypeCodeIdObj = getMlovCodeIdObj(groupTypeList);
  const {showModal, onModalAction, selectedTab} = props;
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const isPatientMessagingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.PATIENT_MESSAGING_ENABLED
  );
  const isEFaxMessagingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION
  );
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const otherContactTypeId = getContactTypeId(PERSON_TYPES.OTHER);
  const isEmailUserPreferenceEnabled = isAccountConfigEnabled(
    CONFIG_CODES.EMAIL_VIEW_USER_PREFERENCE
  );
  const allowedUserPracticeLocationUuidsAndName = getAllowedUserPracticeLocationUuidsAndName(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.COMMUNICATION,
  );
  const [conversationFilterState, setConversationFilterState] =
    useState<IConversationFilterStateV2>({
      selectedInboxId: undefined,
      selectedDateCode: 'ALL',
      readType: 'ALL',
      fromDate: '',
      toDate: '',
      internalUsers: [] as AllUsers[],
      fetchUserLoading: false,
      mentionReadStatus: MENTION_READ_STATUS.ALL,
      isLocationSelectDisable: false,
      fetchLocationLoading: false,
      selectedLocationUuids: [] as string[],
      userLocationData: [] as IUserLocationData[],
      fetchInboxesLoading: false,
      selectedInbox: {} as IConversationFilterInbox,
      inboxList: [] as IConversationFilterInbox[],
      selectedUserUuids: [] as string[],
      selectedUserData: [] as IInternalUsers[],
    });

  const [getUsersByUuid] = useLazyQuery(UserQueries.GET_USER_BY_UUID_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      uuidList: conversationFilterState.selectedUserUuids,
    },
  });

  const [getInternalUsersDataByUUIDs] = useLazyQuery(
    InboxQueries.GetInternalUsersByUUIDs,
    {
      fetchPolicy: 'no-cache',
      variables: {
        userUuids: conversationFilterState.selectedUserUuids,
      },
    }
  );

  const [getInternalUsersByUuidAndLocationsUuids] = useLazyQuery(
    InboxQueries.GetInternalUsersByUuidAndLocationUuids,
    {
      fetchPolicy: 'no-cache',
      variables: {
        userUuids: conversationFilterState.selectedUserUuids,
        locationUuids: conversationFilterState.selectedLocationUuids,
      },
    }
  );

  const [getInboxesByChannelTypeFilter] = useLazyQuery(
    InboxQueries.GetInboxesByChannelTypeFilter,
    {
      fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      notifyOnNetworkStatusChange: true,
      variables: {
        whereCondition: {
          isDeleted: {
            _eq: false,
          },
          channelType: {
            _in: getChannelTypeBasedOnTab(selectedTab, isEFaxMessagingEnabled),
          },
          inboxMembers: {
            userId: {
              _eq: userId,
            },
            isDeleted: {
              _eq: false,
            },
          },
        },
      },
    }
  );

  const getAllUsersWithUnreadCount = async () => {
    setConversationFilterState((prev) => {
      return {
        ...prev,
        fetchUserLoading: true,
        internalUsers: [] as AllUsers[],
        mainUsersList: [] as AllUsers[],
      };
    });
    const allUserRes = await getAllAccountUsersWithUnreadMsgCountAPI().catch(
      (err) => {
        if (err?.response?.status === 400) {
          setConversationFilterState((prev) => {
            return {
              ...prev,
              internalUsers: [] as AllUsers[],
              mainUsersList: [] as AllUsers[],
              fetchUserLoading: false,
            };
          });
        }
      }
    );
    if (allUserRes?.data?.users?.length) {
      setConversationFilterState((prev) => {
        return {
          ...prev,
          internalUsers: allUserRes?.data?.users as AllUsers[],
          fetchUserLoading: false,
          mainUsersList: allUserRes?.data?.users as AllUsers[],
        };
      });
    } else {
      setConversationFilterState((prev) => {
        return {
          ...prev,
          internalUsers: [] as AllUsers[],
          fetchUserLoading: false,
          mainUsersList: [] as AllUsers[],
        };
      });
    }
  };

  const getPracticeLocationsData = async () => {
    try {
      setConversationFilterState((prev) => {
        return {
          ...prev,
          fetchLocationLoading: true,
        };
      });
      const locationData = formatUserLocationData(allowedUserPracticeLocationUuidsAndName)
      setConversationFilterState((prev) => {
        return {
          ...prev,
          fetchLocationLoading: false,
          userLocationData: locationData,
        };
      });
    } catch (error) {
      setConversationFilterState((prev) => {
        return {
          ...prev,
          fetchLocationLoading: false,
          userLocationData: [],
        };
      });
    }
  };

  const getInboxesData = async () => {
    try {
      setConversationFilterState((prev) => {
        return {
          ...prev,
          fetchInboxesLoading: true,
        };
      });
      const inboxResp = await getInboxesByChannelTypeFilter({
        variables: {
          whereCondition: {
            isDeleted: {
              _eq: false,
            },
            channelType: {
              _in: getChannelTypeBasedOnTab(selectedTab, isEFaxMessagingEnabled),
            },
            inboxMembers: {
              userId: {
                _eq: userId,
              },
              isDeleted: {
                _eq: false,
              },
            },
          },
        },
      });
      setConversationFilterState((prev) => {
        return {
          ...prev,
          fetchInboxesLoading: false,
          inboxList: inboxResp?.data?.inboxes,
        };
      });
    } catch (error) {
      setConversationFilterState((prev) => {
        return {
          ...prev,
          fetchInboxesLoading: false,
          inboxList: [],
        };
      });
    }
  };

  const getSelectedUserData = async () => { 
    const selectedUserUuids =
      props?.userFilterData?.filterValue?.assignedByUserList?.map(
        (user: IUser) => {
          return user?.uuid;
        }
      );
    const userData = await getInternalUsersDataByUUIDs({
      variables: {
        userUuids: conversationFilterState.selectedUserUuids?.length
          ? conversationFilterState.selectedUserUuids
          : selectedUserUuids,
      },
    });
    setConversationFilterState((prev) => {
      return {
        ...prev,
        selectedUserData: userData?.data?.accountUsers,
      };
    });
  };

  const getMentionReadStatus = (readFilter: boolean | undefined) => {
    if (readFilter === true) {
      return MENTION_READ_STATUS.READ;
    } else if (readFilter === false) {
      return MENTION_READ_STATUS.UNREAD;
    }
    return MENTION_READ_STATUS.ALL;
  };

  useEffect(() => {
    if (selectedTab === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) {
      getAllUsersWithUnreadCount();
      if (
        (conversationFilterState.selectedUserUuids &&
          conversationFilterState.selectedUserUuids?.length) ||
        props?.userFilterData?.filterValue?.assignedByUserList?.length
      ) {
        getSelectedUserData();
      }
    }
    if (canShowLocationFilter(selectedTab)) {
      getPracticeLocationsData();
    }
    if (canShowInboxFilter(selectedTab)) {
      getInboxesData();
    }
    if (props?.userFilterData && Object.keys(props?.userFilterData)?.length) {
      const additionalData = props?.userFilterData?.additionalData;
      if (selectedTab === props?.userFilterData?.filterCode) {
        const locationUuids =
          props?.userFilterData?.additionalData?.conversationFilterProps
            ?.selectedLocationUuids;
        const userIds =
          props?.userFilterData?.additionalData?.conversationFilterProps
            ?.selectedUserIds;
        const isLocationSelectDisable =
          !locationUuids?.length && userIds?.length;
        const selectedUsersUuids =
          props?.userFilterData?.additionalData?.conversationFilterProps?.selectedUserData?.map(
            (user: IInternalUsers) => {
              return user?.user?.uuid;
            }
          );
          const mentionReadStatus = getMentionReadStatus(
            additionalData?.conversationFilterProps?.readFilter
          );
        setConversationFilterState((prev) => {
          return {
            ...prev,
            ...additionalData?.conversationFilterProps,
            isLocationSelectDisable: isLocationSelectDisable,
            selectedUserUuids: selectedUsersUuids,
            mentionReadStatus: mentionReadStatus,
          };
        });
      }
    }
  }, [props?.selectedTab]);

  const getFilterUserData = async (locationUuids: string[]) => {
    if (conversationFilterState.selectedUserUuids?.length) {
      const usersData = await getInternalUsersByUuidAndLocationsUuids({
        variables: {
          locationUuids: locationUuids,
          userUuids: conversationFilterState.selectedUserUuids,
        },
      });
      const selectedUserUuids = conversationFilterState.selectedUserUuids || [];
      const filteredUsers =
        (usersData?.data?.accountUsers || []).filter((user: IInternalUsers) => {
          return selectedUserUuids.includes(user?.user?.uuid);
        }) || [];
      const filteredUsersUuids =
        filteredUsers?.map((user: IInternalUsers) => {
          return user?.user?.uuid;
        }) || [];
      setConversationFilterState((prev) => {
        return {
          ...prev,
          selectedLocationUuids: locationUuids,
          selectedUserData: filteredUsers,
          selectedUserUuids: filteredUsersUuids,
        };
      });
    } else {
      setConversationFilterState((prev) => {
        return {
          ...prev,
          selectedLocationUuids: locationUuids,
        };
      });
    }
  };

  const getFilterIcons = (icon: string) => {
    switch (icon) {
      case FILTER_ICONS.MENTION:
        return <MentionSvg />;
      case FILTER_ICONS.LOCATION:
        return <LocationSvg />;
      case FILTER_ICONS.INBOX:
        return <InboxSvg />;
      case FILTER_ICONS.DATE:
        return <CalendarSvg />;
      case FILTER_ICONS.ASSIGNED_USER:
        return <AssignedUsersSvg />;
    }
  };

  const getHeader = (title: string, icon: string) => {
    return (
      <Box
        justifyContent={'center'}
        borderRadius={8}
        borderColor={'gray.200'}
        borderWidth={1}
        height={44}
        width={'full'}
        backgroundColor={'gray.50'}
      >
        <HStack ml={2} alignItems={'center'} space={2}>
          <View
            justifyItems={'center'}
            alignItems={'center'}
            alignSelf={'center'}
            justifySelf={'center'}
            height={'24px'}
            width={'24px'}
          >
            {getFilterIcons(icon)}
          </View>
          <Text fontSize={18} fontWeight={600}>
            {title}
          </Text>
        </HStack>
      </Box>
    );
  };

  const clearAllFilter = () => {
    setConversationFilterState((prev) => {
      return {
        ...prev,
        selectedInboxId: undefined,
        selectedDateCode: 'ALL',
        readType: 'ALL',
        fromDate: null,
        toDate: null,
        mentionReadStatus: MENTION_READ_STATUS.ALL,
        fetchLocationLoading: false,
        selectedLocationUuids: [] as string[],
        userLocationData: [] as IUserLocationData[],
        fetchInboxesLoading: false,
        selectedInbox: {} as any,
        inboxList: [] as any,
        selectedUserUuids: [] as string[],
        selectedUserData: [] as IInternalUsers[],
      };
    });

    const filterData = getConversationFilterDataV2({
      filterStateData: {
        fromDate: '',
        toDate: '',
        selectedDateCode: 'ALL',
        mentionReadStatus: MENTION_READ_STATUS.ALL,
      },
      selectedTabCode: selectedTab,
      additionFilterData: {
        accountId,
        userUuid,
        groupTypeCodeIdObj,
        id: userData?.id,
      } as any,
      isEFaxMessagingEnabled,
      isPatientMessagingEnabled,
      emailInboxUserPreference: props?.emailInboxUserPreference,
      otherContactTypeId,
      isEmailUserPreferenceEnabled,
    });

    const conversationFilterPropsData = {
      selectedDateCode: 'ALL',
      count: [],
    };
    onModalAction(COMMON_ACTION_CODES.RESET_FILTER_TO_DEFAULT, {
      conversationFilterProps: conversationFilterPropsData,
      conversationFilterQuery: filterData,
      filterValue: {},
    });
    if (
      selectedTab !== CHANNEL_TYPE_CODE.CALL_LOGS &&
      selectedTab !== CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER
    ) {
      const filterByChannelType = 'conversationLastFilterSelected';
      LocalStorage.removeItem(filterByChannelType);
    }
  };

  const applyFilter = async () => {
    let selectedUserData;
    let selectedUserIds = [] as number[];
    if (conversationFilterState.selectedUserUuids?.length) {
      selectedUserData = await getUsersByUuid({
        variables: {
          uuidList: conversationFilterState.selectedUserUuids,
        },
      });
      if (selectedUserData?.data?.users?.length) {
        selectedUserIds = selectedUserData?.data?.users?.map((item: any) => {
          return item?.id;
        });
      }
    }
    const filterData = getConversationFilterDataV2({
      filterStateData: {
        fromDate: conversationFilterState.fromDate,
        toDate: conversationFilterState?.toDate,
        selectedDateCode: conversationFilterState?.selectedDateCode,
        mentionReadStatus: conversationFilterState?.mentionReadStatus,
      },
      selectedTabCode: selectedTab,
      additionFilterData: {
        accountId,
        userUuid,
        groupTypeCodeIdObj,
        id: userData?.id,
        customFilterData: {
          selectedLocationUuids: conversationFilterState?.selectedLocationUuids,
          selectedInboxId: conversationFilterState?.selectedInboxId,
          assigneeIds: selectedUserIds,
        },
      } as any,
      isEFaxMessagingEnabled,
      isPatientMessagingEnabled,
      emailInboxUserPreference: props?.emailInboxUserPreference,
      otherContactTypeId,
      isEmailUserPreferenceEnabled,
    });

    const count = getConversationFilterPropsCount({
      selectedTabCode: selectedTab,
      mentionReadStatus: conversationFilterState.mentionReadStatus,
      selectedInbox: conversationFilterState?.selectedInbox,
      conversationFilterPropsCount: {
        toDate: conversationFilterState?.toDate,
        fromDate: conversationFilterState?.fromDate,
        selectedDateCode: conversationFilterState?.selectedDateCode,
        selectedInboxId: conversationFilterState?.selectedInboxId,
        selectedLocationUuids: conversationFilterState?.selectedLocationUuids,
      },
      userLocationData: conversationFilterState?.userLocationData,
      selectedUserData: selectedUserData?.data?.users,
    });
    const conversationFilterPropsData: IConversationFilterPropsData = {
      selectedDateCode: conversationFilterState.selectedDateCode,
      selectedLocationUuids: conversationFilterState.selectedLocationUuids,
      selectedInboxId: conversationFilterState.selectedInboxId,
      count: count,
      ...(conversationFilterState.mentionReadStatus !==
        MENTION_READ_STATUS.ALL && {
        readFilter:
          conversationFilterState.mentionReadStatus ===
          MENTION_READ_STATUS.READ,
      }),
      selectedUserIds: selectedUserIds,
    };
    const apiFilterValue: any = {};
    if (selectedUserData?.data?.users?.length) {
      apiFilterValue.assignedByUserList = selectedUserData?.data?.users;
    }
    if (conversationFilterState?.toDate && conversationFilterState?.fromDate) {
      conversationFilterPropsData.toDate = conversationFilterState?.toDate;
      conversationFilterPropsData.fromDate = conversationFilterState?.fromDate;
      apiFilterValue.conversationCreatedDateRangeFilter = {
        toDate: conversationFilterState?.toDate,
        fromDate: conversationFilterState?.fromDate,
      };
    }

    if (conversationFilterState.selectedDateCode !== 'ALL') {
      apiFilterValue.selectedDateCode =
        conversationFilterState.selectedDateCode;
    }
    onModalAction(COMMON_ACTION_CODES.FILTER_CHANGED, {
      conversationFilterProps: conversationFilterPropsData,
      conversationFilterQuery: filterData,
      filterValue: apiFilterValue,
    });
  };

  const disabledDate = (current: any) => {
    return current && current > moment().endOf('day');
  };

  const getRangePickerDefaultValue = (): any => {
    let defaultValue: any[] = [];
    if (conversationFilterState.fromDate && conversationFilterState.toDate) {
      defaultValue = [
        getMomentObjFromFormat(
          conversationFilterState.fromDate,
          DATE_FORMATS.CONVERSATION_DATE_PICKER
        ),
        getMomentObjFromFormat(
          conversationFilterState.toDate,
          DATE_FORMATS.CONVERSATION_DATE_PICKER
        ),
      ];
    } else {
      defaultValue = [];
    }
    return defaultValue;
  };

  const onDateRangeChanged = (value: any) => {
    if (value) {
      const fromDateObj = value[0].toISOString();
      const toDateObj = value[1].toISOString();
      setConversationFilterState((prev) => {
        return {
          ...prev,
          fromDate: fromDateObj,
          toDate: toDateObj,
          selectedDateCode: 'ALL',
        };
      });
    } else {
      setConversationFilterState((prev) => {
        return {
          ...prev,
          fromDate: '',
          toDate: '',
          selectedDateCode: 'ALL',
        };
      });
    }
  };

  const getDateRangePicker = (): JSX.Element => {
    return (
      <RangePicker
        size="small"
        format={DATE_FORMATS.CONVERSATION_DATE_PICKER}
        allowClear={true}
        suffixIcon
        disabledDate={disabledDate}
        value={getRangePickerDefaultValue()}
        style={reactStyles.rangePicker}
        placeholder={['Start', 'End']}
        onChange={(val) => {
          onDateRangeChanged(val);
        }}
      />
    );
  };

  const getInternalUsers = () => {
    return (
      <CustomUserSearch
        userSelectRef={undefined}
        isRequired={false}
        placeholder={intl.formatMessage({id: 'selectAgent'})}
        disabledUsers={[]}
        selectedUsersData={conversationFilterState?.selectedUserData || []}
        label={''}
        onChange={(selectedUsersUUIDs: string[]) => {
          const searchDisable = !!(
            !conversationFilterState.selectedLocationUuids?.length &&
            selectedUsersUUIDs?.length
          ); 
          setConversationFilterState((prev) => {
            return {
              ...prev,
              isLocationSelectDisable: searchDisable,
              selectedUserUuids: selectedUsersUUIDs,
            };
          });
        }}
        filterLoggedInUser={true}
        userSearchType={
          isCommunicationLocationHandlingEnabled
            ? USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
            : USER_SEARCH_TYPE.DEFAULT
        }
        selectedLocationUuids={
          isCommunicationLocationHandlingEnabled
            ? conversationFilterState.selectedLocationUuids
            : []
        }
      />
    );
  };

  const getUserLocations = () => {
    return (
      <Select
        size="large"
        allowClear
        disabled={conversationFilterState.isLocationSelectDisable}
        style={reactStyles.select}
        showSearch={true}
        mode="multiple"
        maxTagCount={'responsive'}
        filterOption={(input: string, option: any) => {
          return (option?.children || '')
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        placeholder={intl.formatMessage({id: 'selectLocations'})}
        value={conversationFilterState.selectedLocationUuids || []}
        onChange={(locationUuids: string[]) => {
          getFilterUserData(locationUuids);
        }}
        loading={conversationFilterState.fetchLocationLoading}
        className={'chat-inbox-select'}
      >
        {conversationFilterState?.userLocationData?.map((location) => {
          return (
            <Option
              key={location?.uuid}
              searchString={`${location?.name || ''}`}
              value={location?.uuid}
            >
              {location?.name}
            </Option>
          );
        })}
      </Select>
    );
  };

  const getInboxes = () => {
    return (
      <Select
        size="large"
        allowClear
        style={reactStyles.select}
        showSearch={true}
        placeholder={intl.formatMessage({id: 'selectInbox'})}
        value={conversationFilterState.selectedInboxId}
        filterOption={(input: string, option: any) => {
          return (option?.children || '')
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        onChange={(inboxId: number) => {
          const selectedInbox = conversationFilterState?.inboxList?.find(
            (item: any) => item?.id === inboxId
          );
          setConversationFilterState((prev) => {
            return {
              ...prev,
              selectedInbox: selectedInbox,
              selectedInboxId: inboxId,
            };
          });
        }}
        loading={conversationFilterState.fetchInboxesLoading}
        className={'chat-inbox-select'}
      >
        {conversationFilterState?.inboxList?.map((inbox: any) => {
          return (
            <Option
              key={inbox?.id}
              searchString={getInboxNameByInboxType(inbox)}
              value={inbox?.id}
            >
              {getInboxNameByInboxType(inbox)}
            </Option>
          );
        })}
      </Select>
    );
  };
  return (
    <View>
      <Drawer
        visible={showModal}
        width={isSideCarContext ? '100%' : 600}
        mask={isSideCarContext ? false : true}
        closable={false}
        onClose={() => {
          onModalAction(COMMON_ACTION_CODES.CLOSE_MODAL);
        }}
        title={
          <ModalActionTitle
            title={'filters'}
            titleColor={''}
            buttonList={[
              {
                ...(isSideCarContext && {
                  show: true,
                  id: 1,
                  btnText: 'back',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: () => {
                    onModalAction(COMMON_ACTION_CODES.CLOSE_MODAL);
                  },
                }),
              },
              {
                show: true,
                id: 2,
                btnText: 'resetFilter',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  clearAllFilter();
                },
              },
              {
                show: true,
                id: 1,
                btnText: 'apply',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  applyFilter();
                },
              },
            ]}
          />
        }
      >
        <View mb={4}>
          {selectedTab === CHANNEL_TYPE_CODE.CHANNEL_MENTION && (
            <View>
              {getHeader('Mentions', FILTER_ICONS.MENTION)}
              <View mt={6} mx={3}>
                <Radio.Group
                  name="mentionReadStatus"
                  value={conversationFilterState.mentionReadStatus}
                  onChange={(value) => {
                    setConversationFilterState((prev) => {
                      return {
                        ...prev,
                        mentionReadStatus: value,
                      };
                    });
                  }}
                >
                  <HStack space={6}>
                    <Radio
                      style={styles.radio}
                      value={MENTION_READ_STATUS.UNREAD}
                    >
                      Unread
                    </Radio>
                    <Radio
                      style={styles.radio}
                      value={MENTION_READ_STATUS.READ}
                    >
                      Read
                    </Radio>
                    <Radio
                      style={styles.radio}
                      value={MENTION_READ_STATUS.ALL}
                    >
                      All
                    </Radio>
                  </HStack>
                </Radio.Group>
              </View>
            </View>
          )}

          <View mt={6}>
            {getHeader('By Date', FILTER_ICONS.DATE)}
            <View mt={6} mx={0}>
              <ModalActionRadioSelect
                data={dateType}
                selectedValue={conversationFilterState.selectedDateCode}
                onChange={(dateType) => {
                  setConversationFilterState((prev) => {
                    return {
                      ...prev,
                      selectedDateCode: dateType,
                      fromDate: '',
                      toDate: '',
                    };
                  });
                }}
              />
            </View>
            {true && (
              <HStack alignItems={'center'} mt={6}>
                <Divider color={'gray.200'} flex={0.49} />
                <View color={'gray.400'} mx={0}>
                  or
                </View>
                <Divider color={'gray.200'} flex={0.49} />
              </HStack>
            )}
            {true && (
              <HStack mt={6} alignItems={'center'} space={6}>
                {getDateRangePicker()}
              </HStack>
            )}
          </View>

          {canShowInboxFilter(selectedTab) && (
            <View mt={6}>
              {getHeader('Inboxes', FILTER_ICONS.INBOX)}
              <View mt={6} mx={0}>
                {conversationFilterState.fetchLocationLoading ? (
                  <Skeleton.Text lines={2} />
                ) : (
                  getInboxes()
                )}
              </View>
            </View>
          )}

          {((isCommunicationLocationHandlingEnabled &&
            canShowLocationFilter &&
            canShowLocationFilter(selectedTab)) ||
            selectedTab === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER) && (
            <HStack alignItems={'center'} mt={6}>
              <Divider color={'gray.200'} flex={0.49} />
              <View color={'gray.400'} mx={0}>
                or
              </View>
              <Divider color={'gray.200'} flex={0.49} />
            </HStack>
          )}

          {isCommunicationLocationHandlingEnabled &&
            canShowLocationFilter &&
            canShowLocationFilter(selectedTab) && (
              <View mt={6}>
                {getHeader('Locations', FILTER_ICONS.LOCATION)}
                <View mt={6} mx={0}>
                  {conversationFilterState.fetchLocationLoading ? (
                    <Skeleton.Text lines={2} />
                  ) : (
                    getUserLocations()
                  )}
                </View>
              </View>
            )}

          {canShowAssignedUsers(selectedTab) && (
            <View mt={6}>
              {getHeader('Assigned Users', FILTER_ICONS.ASSIGNED_USER)}
              <View mt={6} mx={0}>
                {conversationFilterState.fetchUserLoading ? (
                  <Skeleton.Text lines={2} />
                ) : (
                  getInternalUsers()
                )}
              </View>
            </View>
          )}
        </View>
      </Drawer>
    </View>
  );
};

const styles = StyleSheet.create({
  radio: {
    marginVertical: 10
  }
});

const reactStyles: Record<string, React.CSSProperties> = {
  select: {
    height: '40px'
  },
  rangePicker: {
    width: '99%',
    height: '36px',
    fontSize: 12,
    borderRadius: 18,
  },
};

export default React.memo(ConversationFilterV2);
