import {DISALLOWED_SEND_FORM_CATEGORIES, DISALLOWED_VIEW_ANALYTICS_CATEGORIES, FORM_CATEGORY_TYPES} from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';
import {IFormCategory} from '../../interfaces';

export const checkNoteCategoryForm = (
  formCategory: IFormCategory | undefined | null
) => {
  return formCategory?.code === FORM_CATEGORY_TYPES.NOTES
};

export const checkProviderRequestForm = (
  formCategory: IFormCategory | undefined | null
) => {
  return formCategory?.code === FORM_CATEGORY_TYPES.PROVIDER_REQUEST_FORM;
};

export const checkCarePlanCategoryForm = (
  formCategory: IFormCategory | undefined | null
) => {
  return formCategory?.code === FORM_CATEGORY_TYPES.CARE_PLAN
};

export const isDisallowedSendForm = (
  formCategory: IFormCategory | undefined | null
) => {
  if (formCategory?.code) {
    return DISALLOWED_SEND_FORM_CATEGORIES.includes(formCategory.code);
  }

  return false;
};

export const isDisallowedViewAnalytics = (
  formCategory: IFormCategory | undefined | null
) => {
  if (formCategory?.code) {
    return DISALLOWED_VIEW_ANALYTICS_CATEGORIES.includes(formCategory.code);
  }

  return false;
};