import {DatePicker, Skeleton} from 'antd';
import moment from 'moment';
import {FormControl, HStack, Pressable, Text} from 'native-base';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {View, StyleSheet} from 'react-native';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {Colors} from '../../../styles/Colors';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {CustomUserSearch} from '../../common/CustomUserSearch/CustomUserSearch';
import {removeDuplicatesUsers} from '../../common/CustomUserSearch/CustomUserSearchUtils';
import {IInternalUsers} from '../../common/CustomUserSearch/interface';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import CalenderNavSvg from '../../common/Svg/ConversationAllSvg/CalenderNavSvg';
import MessageDeleteSvg from '../../common/Svg/MessageDeleteSvg';
import {WeekDaySelector} from '../../common/WeekSelector';
import {USER_SEARCH_TYPE} from '../constants';
import {
  bindUuidsWithAccountUsers,
  collectAllUserUuidsFromOnCallScheduleDuration,
  formattedDateTime,
} from './commonUtils';
import {IScheduleBoxProps} from './interface';
import CustomConfirmationModal from '../../common/CustomConfirmationModal/CustomConfirmationModal';
import { DATE_FORMATS } from '../../../constants';

const ScheduleBox = (props: IScheduleBoxProps) => {
  const intl = useIntl();
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const isCardView = props.listLength > 1;
  const cardViewStyles = isCardView
    ? styles.cardView
    : {};

  const selectUsersRef = React.useRef<any>();
  const [stateData, setStateData] = React.useState({
    loadingUserSearch: true,
    openConfirmationModal: false,
  } as {
    loadingUserSearch: boolean;
    openConfirmationModal: boolean;
  });

  const [selectedAccountUsers, updateSelectedAccountUsers] = React.useState(
    [] as IInternalUsers[]
  );

  React.useEffect(() => {
    const uuids = collectAllUserUuidsFromOnCallScheduleDuration(
      props?.scheduleDuration
    );
    const scheduledAccountUsersList = bindUuidsWithAccountUsers(
      uuids,
      props?.accountUsers
    );
    updateSelectedAccountUsers((prev) => {
      return removeDuplicatesUsers([...prev, ...scheduledAccountUsersList]);
    });
  }, [props?.scheduleDuration]);

  const userSearchMemorizedComponent = useMemo(() => {
    return (
      <CustomUserSearch
        userSelectRef={selectUsersRef}
        isRequired={false}
        className={`custom-select-box ${
          stateData.loadingUserSearch ? 'hide' : ''
        }`}
        placeholder={intl.formatMessage({id: 'selectUserText'})}
        label={'singleUser'}
        disabledUsers={[]}
        selectedUsersData={selectedAccountUsers}
        onActionPerformed={(actionCode) => {
          switch (actionCode) {
            case COMMON_ACTION_CODES.LOADING_COMPLETE:
              setStateData((prev) => {
                return {
                  ...prev,
                  loadingUserSearch: false,
                };
              });
              break;
          }
        }}
        onChange={(selectedUsersUuids, selectedInternalUsers) => {
          if (selectedInternalUsers) {
            updateSelectedAccountUsers(selectedInternalUsers);
          }
          props.onChange(
            'onCallScheduleUsers',
            selectedUsersUuids.map((selectedUserUuid) => {
              return {
                userId: selectedUserUuid,
              };
            }),
            props.index
          );
        }}
        userSearchType={
          isCommunicationLocationHandlingEnabled
            ? USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED_WITH_BUSINESS_OWNER
            : USER_SEARCH_TYPE.DEFAULT
        }
      />
    );
  }, [stateData.loadingUserSearch]);

  return (
    <View style={[cardViewStyles, styles.container]}>
      {isCardView ? (
        <View style={styles.cardHeader}>
          <HStack
            style={styles.cardHeaderContent}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Text style={styles.scheduleText}>
              Schedule {props.index + 1}
            </Text>

            <Pressable
              onPress={() => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    openConfirmationModal: true,
                  };
                });
              }}
              disabled={false}
            >
              <MessageDeleteSvg customStrokeColor={Colors.FoldPixel.GRAY300} />
            </Pressable>
          </HStack>
        </View>
      ) : (
        <></>
      )}

      <View style={isCardView ? styles.cardContent : {}}>
        <HStack>
          <FormControl flex={0.5} marginRight={'4px'}>
            <FormControl.Label>
              <DisplayText
                size={'smMedium'}
                extraStyles={styles.labelText}
                textLocalId={'from'}
              />
            </FormControl.Label>
            <DatePicker
              suffixIcon={
                <CalenderNavSvg
                  width={16}
                  height={16}
                  customStrokeColor={Colors.FoldPixel.GRAY300}
                />
              }
              className="custom-antd-input-box"
              showTime={{use12Hours: true}}
              style={reactStyles.datePicker}
              format={DATE_FORMATS.MM_DD_YY_HH_MM_A_DATE_TIME_FORMAT}
              disabledDate={(current) => {
                return current && current < moment().startOf('day');
              }}
              disabledTime={(current) => {
                const now = moment();
                if (!current) return {};

                const twoMinutesLater = now.clone().add(2, 'minutes');

                if (current.isSame(now, 'day')) {
                  if (current.isBefore(twoMinutesLater)) {
                    return {
                      disabledHours: () =>
                        [...Array(24).keys()].filter(
                          (hour) => hour < now.hour()
                        ),
                      disabledMinutes: () =>
                        [...Array(60).keys()].filter(
                          (min) => min < now.minute() + 0
                        ),
                    };
                  }
                }

                return {};
              }}
              onChange={(_, dateString) => {
                if (dateString) {
                  const fromDate = new Date(dateString);
                  const toDate = new Date(props?.scheduleDuration?.toDate);
                  props.onChange('fromDate', fromDate, props.index);
                  if (fromDate > toDate) {
                    props.onChange('toDate', '', props.index);
                  }
                } else {
                  props.onChange('fromDate', '', props.index);
                }
              }}
              value={
                props?.scheduleDuration?.fromDate
                  ? moment(
                      formattedDateTime(
                        new Date(props?.scheduleDuration.fromDate)
                      ),
                      DATE_FORMATS.MM_DD_YY_HH_MM_A_DATE_TIME_FORMAT
                    )
                  : undefined
              }
            />
          </FormControl>

          <FormControl flex={0.5} marginLeft={'4px'}>
            <FormControl.Label>
              <DisplayText
                size={'smMedium'}
                extraStyles={styles.labelText}
                textLocalId={'to'}
              />
            </FormControl.Label>
            <DatePicker
              suffixIcon={
                <CalenderNavSvg
                  width={16}
                  height={16}
                  customStrokeColor={Colors.FoldPixel.GRAY300}
                />
              }
              className="custom-antd-input-box"
              showTime={{use12Hours: true}}
              style={reactStyles.datePicker}
              format={DATE_FORMATS.MM_DD_YY_HH_MM_A_DATE_TIME_FORMAT}
              disabled={!props?.scheduleDuration?.fromDate}
              placement="bottomRight"
              disabledDate={(current) => {
                const fromDate = props?.scheduleDuration?.fromDate
                  ? moment(props.scheduleDuration.fromDate, DATE_FORMATS.CALENDAR_LIB_FORMAT)
                  : false;

                return current && fromDate && current < fromDate.startOf('day');
              }}
              disabledTime={(current) => {
                const now = moment();
                const fromDate = props?.scheduleDuration?.fromDate
                  ? moment(
                      props.scheduleDuration.fromDate,
                      DATE_FORMATS.MM_DD_YY_HH_MM_A_DATE_TIME_FORMAT
                    )
                  : null;

                if (!current) return {};

                // Disable times based on fromDate if it exists
                if (fromDate && current.isSame(fromDate, 'day')) {
                  return {
                    disabledHours: () =>
                      [...Array(24).keys()].filter(
                        (hour) => hour < fromDate.hour()
                      ),
                    disabledMinutes: () =>
                      [...Array(60).keys()].filter((minute) => {
                        if (current.hour() === fromDate.hour()) {
                          return minute < fromDate.minute();
                        }
                        return false;
                      }),
                  };
                }

                if (current.isSame(now, 'day')) {
                  const twoMinutesLater = now.clone().add(0, 'minutes');
                  if (current.isBefore(twoMinutesLater)) {
                    return {
                      disabledHours: () =>
                        [...Array(24).keys()].filter(
                          (hour) => hour < now.hour()
                        ),
                      disabledMinutes: () =>
                        [...Array(60).keys()].filter(
                          (min) => min < now.minute()
                        ),
                    };
                  }
                }

                return {};
              }}
              onChange={(_, dateString) => {
                if (dateString) {
                  props.onChange('toDate', new Date(dateString), props.index);
                } else {
                  props.onChange('toDate', '', props.index);
                }
              }}
              value={
                props?.scheduleDuration?.toDate
                  ? moment(
                      formattedDateTime(
                        new Date(props?.scheduleDuration.toDate)
                      ),
                      DATE_FORMATS.MM_DD_YY_HH_MM_A_DATE_TIME_FORMAT
                    )
                  : undefined
              }
            />
          </FormControl>
        </HStack>

        <FormControl marginTop={'16px'} marginBottom={'16px'}>
          <FormControl.Label>
            <DisplayText
              size={'smMedium'}
              extraStyles={styles.labelText}
              textLocalId={'on'}
            />
          </FormControl.Label>
          <WeekDaySelector
            values={
              JSON.parse(props?.scheduleDuration?.daysOfWeek || '[]') || []
            }
            onChange={(selectedIds) => {
              props.onChange(
                'daysOfWeek',
                JSON.stringify(selectedIds),
                props.index
              );
            }}
          />
        </FormControl>
        {userSearchMemorizedComponent}
        {stateData.loadingUserSearch && (
          <Skeleton.Button
            active={true}
            size={'default'}
            shape={'default'}
            block={true}
          />
        )}
      </View>
      {stateData.openConfirmationModal && (
        <CustomConfirmationModal
          isOpen={stateData.openConfirmationModal}
          headerText="areYouSure"
          message="deleteScheduleSectionConfirmationMessage"
          onCancel={() => {
            setStateData((prev) => {
              return {
                ...prev,
                openConfirmationModal: false,
              };
            });
          }}
          onConfirm={() => {
            setStateData((prev) => {
              return {
                ...prev,
                openConfirmationModal: false,
              };
            });
            props.onRemove(props.index);
          }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
  },
  cardView: {
    borderWidth: 0.5,
    borderColor: Colors.FoldPixel.GRAY150,
    borderRadius: 8,
  },
  cardHeader: {
    borderBottomWidth: 0.5,
    borderColor: Colors.FoldPixel.GRAY150,
  },
  cardHeaderContent: {
    padding: 8,
  },
  scheduleText: {
    color: Colors.FoldPixel.GRAY400,
    fontSize: 14,
    fontWeight: '500',
  },
  cardContent: {
    padding: 8,
  },
  labelText: {
    color: Colors.Custom.Gray500,
    fontWeight: '400',
    fontSize: 14,
  },
});

const reactStyles: Record<string, React.CSSProperties> = {
  datePicker: {
    height: '32px',
    fontSize: 14,
    color: Colors.FoldPixel.GRAY400
  }
}

export default ScheduleBox;
