import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {SafeAreaView, SectionList, Text, View, StyleSheet} from 'react-native';
import {Button, Skeleton, useToast} from 'native-base';
import Stack from '../../../../../common/LayoutComponents/Stack';
import SingleMessageThread from './SingleMessageThread';
import {IMessageThreadList} from './interface';
import NoConversationFoundSvg from '../../../../../common/Svg/SearchMessageAllSvg/NoConversationFoundSvg';
import {Colors} from '../../../../../../styles';
import SearchMessageSvg from '../../../../../common/Svg/SearchMessageAllSvg/SearchMessageSvg';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import {IConversationMessage, IMessageBoxData} from '../../interfaces';
import {getMessageThreadResult} from './SearchMessageThreadUtils';
import { ToastType, showToast } from '../../../../../../utils/commonViewUtils';

export const MessageThreadList = (props: IMessageThreadList) => {
  const [state, setState] = useState({
    groupDateByMsgData: [] as any,
    msgData: [] as IMessageBoxData[],
    showFetchMore: true,
    isFetchMore: false,
    pageNo: 1,
    searchString: '',
  });
  const intl = useIntl();
  const toast = useToast();
  useEffect(() => {
    const groupMsgData = groupByFeedDataByTimeStamp(props?.msgListData);
    setState((prev) => {
      return {
        ...prev,
        msgData: props?.msgListData,
        groupDateByMsgData: groupMsgData,
        searchString: props?.searchString || '',
        pageNo: props?.pageNo || 1,
      };
    });
  }, [props?.msgListData]);

  const loadMore = async () => {
    setState((prev) => {
      return {
        ...prev,
        isFetchMore: true,
      };
    });
    const result = await getMessageThreadResult(
      props?.conversationUuid,
      state.searchString,
      state.pageNo + 1
    );
    if (!result?.error) {
      setState((prev) => {
        const updatedData = [...prev?.msgData, ...result];
        const groupMsgData = groupByFeedDataByTimeStamp(updatedData);
        return {
          ...prev,
          msgData: updatedData,
          loading: false,
          isMsgSearched: true,
          pageNo: state.pageNo + 1,
          groupDateByMsgData: groupMsgData,
        };
      });
      updateFetchMore(result);
    } else {
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
      setState((prev) => {
        return {
          ...prev,
          isFetchMore: false,
        };
      });
    } 
  };

  const updateFetchMore = (fetchMoreResult: any) => {
    if (fetchMoreResult && fetchMoreResult?.length < 10) {
      setState((prev) => {
        return {
          ...prev,
          isFetchMore: false,
          showFetchMore: false,
        };
      });
    } else {
      setState((prev) => {
        return {
          ...prev,
          isFetchMore: false,
        };
      });
    }
  };
  const groupByFeedDataByTimeStamp = (msgDataList: any) => {
    const updatedData: any = [];
    msgDataList.forEach((item: any) => {
      const date = item?.createdAt;
      const key = new Date(date).toLocaleDateString('en-US', {
        month: 'long',
        year: 'numeric',
      });
      const formattedData = {
        key: key,
        data: [item],
      };
      if (updatedData.length > 0) {
        const index = updatedData.findIndex((data: any) => data.key === key);
        if (index !== -1) {
          updatedData[index].data = [...updatedData[index].data, item];
        } else {
          updatedData.push(formattedData);
        }
      } else {
        updatedData.push(formattedData);
      }
    });
    return updatedData;
  };

  const renderSingleMsgCard = ({item}: any) => {
    return (
      <SingleMessageThread
        searchString={state.searchString}
        singleMsgData={item}
        onActionPerformed={(msgData: IConversationMessage) => {
          props?.onActionPerformed(msgData);
        }}
      />
    );
  };

  const renderFooter = (): JSX.Element => {
    let footerElem = <></>;
    if (state?.showFetchMore && state.msgData.length >= 10) {
      if (state.isFetchMore) {
        footerElem = <Skeleton.Text mx={4} mt={2} lines={6}></Skeleton.Text>;
      } else {
        footerElem = (
          <Button
            size="md"
            variant="link"
            onPress={() => {
              loadMore();
            }}
            marginBottom={6}
          >
            {intl.formatMessage({
              id: 'loadMoreMessage',
            })}
          </Button>
        );
      }
    }
    return footerElem;
  };

  return (
    <View>
      {props?.loading ? (
        <View style={styles.loadingView}>
          <Skeleton.Text lines={4} />
        </View>
      ) : state?.msgData?.length > 0 ? (
        <SafeAreaView style={styles.safeAreaView}>
          <SectionList
            keyboardShouldPersistTaps="handled"
            keyboardDismissMode="on-drag"
            sections={state?.groupDateByMsgData}
            stickySectionHeadersEnabled={false}
            renderSectionHeader={({section}: any) =>
              section.data.length ? (
                <Stack
                  direction="row"
                  style={styles.sectionHeaderStack}
                >
                  <Text style={styles.sectionHeaderText}>
                    {section.key}
                  </Text>
                </Stack>
              ) : null
            }
            ListFooterComponent={renderFooter}
            renderItem={renderSingleMsgCard}
            keyExtractor={(item: any) => item?.id}
          />
        </SafeAreaView>
      ) : (
        <Stack
          direction="column"
          style={styles.emptyStateContainer}
        >
          <Stack
            direction="column"
            style={styles.emptyStateInnerContainer}
          >
            {props?.isMsgSearched ? (
              <NoConversationFoundSvg />
            ) : (
              <SearchMessageSvg />
            )}
            <Text style={styles.emptyStateTitle}>
              {intl.formatMessage({
                id: props?.isMsgSearched ? 'noMsgFound' : 'searchMessage',
              })}
            </Text>
            <Text style={styles.emptyStateDescription}>
              {intl.formatMessage({
                id: props?.isMsgSearched
                  ? 'noConversationFoundInThread'
                  : 'startSearchInMsgThread',
              })}
            </Text>
          </Stack>
        </Stack>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  loadingView: {
    flex: 1,
    paddingTop: 4,
    backgroundColor: 'coolGray.50:alpha.70',
  },
  safeAreaView: {
    height: isWeb() ? '85vh' : '100%',
    paddingBottom: 20,
  },
  sectionHeaderStack: {
    justifyContent: 'space-between',
    marginTop: 0,
    paddingHorizontal: 16,
  },
  sectionHeaderText: {
    fontSize: 14,
    lineHeight: 21,
    fontWeight: '600',
    color: 'rgb(102, 112, 133)',
    marginTop: 17,
    textTransform: 'uppercase',
    letterSpacing: 2,
    fontFamily: 'Manrope',
  },
  emptyStateStack: {
    flex: 1,
    marginTop: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyStateInnerStack: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyStateTitle: {
    width: '100%',
    fontSize: isWeb() ? 18 : 14,
    height: 24,
    fontWeight: '600',
    color: Colors.Custom.Gray500,
    lineHeight: 27,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyStateDescription: {
    fontSize: isWeb() ? 14 : 12,
    height: 50,
    fontWeight: '600',
    color: Colors.Custom.Gray400,
    lineHeight: 21,
    textAlign: 'center',
    paddingHorizontal: 16,
  },
  emptyStateContainer: {
    flex: 1,
    marginTop: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyStateInnerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});