import {gql} from '@apollo/client';

const GetApiCredentials = gql`
  query GetApiCredentials($type: String) {
    accountApiCredentials(where: {credType: {_eq: $type}}) {
      apiSecret
      apiKey
      credType
      id
    }
  }
`;

export const CreateCredentials = gql`
  mutation CreateCredentials(
    $cred_type: String!
    $apiSecret: String!
    $apiKey: String!
    $accountUuid: uuid!
  ) {
    createAccountApiCredential(
      object: {
        accountUuid: $accountUuid
        apiKey: $apiKey
        apiSecret: $apiSecret
        credType: $cred_type
      }
    ) {
      id
    }
  }
`;

export const UpdateCredentials = gql`
  mutation UpdateCredentials(
    $id: uuid!
    $apiKey: String!
    $apiSecret: String!
  ) {
    updateAccountApiCredentials(
      where: {id: {_eq: $id}}
      _set: {apiSecret: $apiSecret, apiKey: $apiKey}
    ) {
      returning {
        id
      }
    }
  }
`;

export default {
  GetApiCredentials,
  CreateCredentials,
  UpdateCredentials,
};
