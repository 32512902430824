import { Drawer, Radio, Select as AntSelect, InputNumber, Select } from 'antd';
import { HealthMapTemplateViewCodes, HEALTH_MAP_VIEW_ROLE, UI_STATE_ACTIONS, VALIDATION_STATE } from '../healthMapTemplatesConsts';
import { HStack, Pressable, VStack, Text, Input, FormControl, useToast, View, ScrollView, Icon } from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../styles/Colors';
import React, { useEffect, useState } from 'react';
import { COMMON_ACTION_CODES } from '../../../../../constants/ActionConst';
import { styles } from '../../LLMTemplates/llmTemplateStyles'
import { SingleHealthMapTemplate } from '../interfaces';
import { createNewHealthMapTemplate, updateHealthMapTemplate } from '../healthMapTemplateUtils';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import { getAccountUUID, getUserId, getUserUUID, isLanguageModelComponentVisible, isLoggedInUserGlobalAdmin, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner } from '../../../../../utils/commonUtils';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import { background } from 'native-base/lib/typescript/theme/styled-system';
import DraggableList from '../../LLMTemplates/LLMTemplateActions/DraggableList';
import DragHandleIcon from '../../../../../assets/Icons/DragHandleIcon';
import TrashBin2Icon from '../../../../../assets/Icons/TrashBin2Icon';
import SkeletonLoader from '../../../Contacts/TeamMembers/AddEditUser/components/SkeletonLoader';
import ReactMarkdown from 'react-markdown';
import AntIcon from 'react-native-vector-icons/AntDesign';


const { Option } = AntSelect;

const LLM_LANGUAGE_MODELS = [
  {
    label: 'Claude 3 Sonnet',
    value: 'claude-3-sonnet'
  },
  {
    label: 'GPT-4o',
    value: 'gpt-4o'
  }
]

const GPT_4O = 'gpt-4o';

const AddOrUpdateHealthMapTemplates = (props: {
  viewCode: string;
  onActionPerformed: any;
  template?: SingleHealthMapTemplate;
  id?: string;
  adminAccess: boolean;
  userList: string[];
  roleList: string[];
  userPrompt: string;
  systemPrompt: string;
}) => {
  // Main template state
  const [templateData, setTemplateData] = useState({
    templateName: '',
    isActive: true,
    accessRole: props?.template?.accessRole || HEALTH_MAP_VIEW_ROLE.PRIVATE,
    selectedUsers: props.template?.userIds || [],
    languageModel: props.template?.languageModel || GPT_4O,
    selectedRoles: props.template?.userRoleIds || [],
    healthMapSections: [{
      parameter: '',
      lowScore: 0,
      highScore: 10,
      inference: '',
    }],
  });

  // UI state
  const [uiState, setUiState] = useState({
    isDrawerOpen: false,
    isSubmitPressed: false,
    items: [],
  });
  const accountUUID = getAccountUUID();
  const userId: number | string = getUserId();

  // Validation state
  const [validationState, setValidationState] = useState({
    isValidName: true,
    isValidRole: true,
    isValidUser: true,
  });
  const [expandedUserContext, setExpandedUserContext] = useState(false);
  const [expandedSystemContext, setExpandedSystemContext] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  // Derived state
  const isBusinessOwner = isLoginUserBusinessOwner() || isLoggedInUserGlobalAdmin() || isLoggedInUserWorkFlowOrCustomerSuccess();
  const isDefault = props?.template?.isDefault || false;
  const userUUID = getUserUUID();
  const createdByLoginUser = ((userUUID === props?.template?.createdBy) || (!props?.template?.id));

  const toast = useToast();
  const intl = useIntl();

  // Update functions
  const updateTemplateData = (field: string, value: any) => {
    setTemplateData(prev => ({ ...prev, [field]: value }));
  };

  const updateUiState = (field: string, value: any) => {
    setUiState(prev => ({ ...prev, [field]: value }));
  };

  const updateValidationState = (field: string, value: boolean) => {
    setValidationState(prev => ({ ...prev, [field]: value }));
  };

  // Effect to update drawer state
  useEffect(() => {
    updateUiState(UI_STATE_ACTIONS.DRAWER_OPEN,
      (props.viewCode === HealthMapTemplateViewCodes.ADD) ||
      (props.viewCode === HealthMapTemplateViewCodes.EDIT)
    );
  }, [props.viewCode]);

  // Effect to populate form when editing
  useEffect(() => {
    if (props?.viewCode === HealthMapTemplateViewCodes.EDIT && props?.template) {
      setTemplateData({
        languageModel: props.template.languageModel || GPT_4O,
        templateName: props.template.templateName || '',
        isActive: props.template.isActive || false,
        accessRole: props.template.accessRole || HEALTH_MAP_VIEW_ROLE.PRIVATE,
        selectedUsers: props.template.userIds || [],
        selectedRoles: props.template.userRoleIds || [],
        healthMapSections: props.template.healthMapSections || [{
          parameter: '',
          lowScore: 0,
          highScore: 10,
          inference: '',
        }],
      });
    }
  }, [props.template, props.viewCode]);

  const handleActionPerformed = async (actionCode: string, viewCode: any) => {
    updateUiState(UI_STATE_ACTIONS.SUBMIT_PRESSED, true);

    if (actionCode === COMMON_ACTION_CODES.SAVE) {
      let isValid = true;
      if (templateData.isActive === null) {
        isValid = false;
      }
      if (templateData.accessRole === '') {
        isValid = false;
      }
      if (templateData.templateName.trim() === '') {
        isValid = false;
        updateValidationState(VALIDATION_STATE.VALID_NAME, false);
      }
      if (!templateData.healthMapSections.every((section: { parameter: string; inference: string; }) => section.parameter.trim() !== '' && section.inference.trim() !== '')) {
        isValid = false
      }
      if (templateData.accessRole == HEALTH_MAP_VIEW_ROLE.SELECTED_ROLES && templateData.selectedRoles.length === 0) {
        isValid = false;
        updateValidationState(VALIDATION_STATE.VALIED_ROLE, false);
      }
      if (templateData.accessRole == HEALTH_MAP_VIEW_ROLE.SELECTED_USERS && templateData.selectedUsers.length === 0) {
        isValid = false;
        updateValidationState(VALIDATION_STATE.VALID_USER, false);
      }

      if (isValid) {
        const templateBody = {
          accessRole: templateData.accessRole,
          isActive: templateData.isActive,
          templateName: templateData.templateName,
          userRoleIds: templateData.selectedRoles,
          templateType: 'regular',
          userIds: templateData.selectedUsers,
          isDefault: isDefault,
          healthMapSections: templateData.healthMapSections,
          languageModel: templateData.languageModel,
        };

        if (props?.template?.id) {
          await updateHealthMapTemplate(templateBody, props?.template?.id)
          props.onActionPerformed(COMMON_ACTION_CODES.ADDED_OR_UPDATED, null, viewCode);
          showToast(
            toast,
            intl.formatMessage({
              id: 'healthMapTemplateUpdatedMsg',
            }),
            ToastType.success,
            3000
          );
        } else {
         try {
           await createNewHealthMapTemplate(templateBody)
           props.onActionPerformed(COMMON_ACTION_CODES.ADDED_OR_UPDATED, null, viewCode);
           showToast(
             toast,
             intl.formatMessage({
               id: 'healthMapTemplateCreatedMsg',
             }),
             ToastType.success,
             3000
           );
         } catch (error) {
           showToast(
             toast,
             'Error in Creating template',
             ToastType.error,
             3000
           );
         }

        }
        props.onActionPerformed(actionCode, null, viewCode);
      }
      else {
        updateUiState(UI_STATE_ACTIONS.SUBMIT_PRESSED, false);
      }
    } else {
      updateUiState(UI_STATE_ACTIONS.SUBMIT_PRESSED, false);
      props.onActionPerformed(actionCode, null, viewCode);
    }
  };

  const formatJSONView = (
    title: string,
    json: string,
    isExpanded: boolean,
    setExpanded: (isExpanded: boolean) => void,
    isLoading: boolean
  ) => {
    const formattedContent = json
      .replace(/\\n/g, '\n')
      .replace(/\n\s*/g, '\n')
      .replace(/^\s*(\d+)\.\s+/gm, '$1. ')
      .replace(/\*/g, '-')
      .replace(/\s\./g, '.')
      .replace(/([^\n])\n\s*\n/g, '$1\n\n')
      .replace(/(?<=\S)\n{2,}/g, '\n')
      .replace(/\n{2,}/g, '\n')
      .trim();

    return (
      <VStack space={4}>
        <FormControl.Label
          padding={0}
          margin={0}
        >
          <Pressable flexDirection={'row'} alignItems={'center'} onPress={() => setExpanded(!isExpanded)}>
            <Text style={styles.subtitleStyle} fontWeight={400} fontSize={14} lineHeight={16.8} color={Colors.FoldPixel.GRAY200}>
              {title}
            </Text>
            <Icon
              as={Feather}
              name={isExpanded ? 'chevron-up' : 'chevron-down'}
              size='xs'
              color={Colors.FoldPixel.GRAY150}
              marginLeft={1}
            />
          </Pressable>
        </FormControl.Label>
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <ScrollView style={[
            styles.scrollView,
            { maxHeight: isExpanded ? 200 : 30 }
          ]}>
            <Text fontSize={12} fontWeight={'400'} lineHeight={16.8} color={Colors.FoldPixel.GRAY300}>
              <ReactMarkdown>
                {formattedContent}
              </ReactMarkdown>
            </Text>
          </ScrollView>
        )}
      </VStack>
    );
  };

  const onChange = () => {
    updateTemplateData('isActive', !templateData.isActive);
  }

  const onChangeText = (text: any) => {
    updateTemplateData('templateName', text);
    updateValidationState(VALIDATION_STATE.VALID_NAME, true);
  }

  const onChangeAccessRole = (role: any) => {
    updateTemplateData('accessRole', role);
    updateTemplateData(VALIDATION_STATE.VALIED_ROLE, role);
  }

  const handleSelectedUsersChange = (selectedUserUuids: any) => {
    updateTemplateData('selectedUsers', selectedUserUuids);
    if (selectedUserUuids.length > 0) {
      updateValidationState(VALIDATION_STATE.VALID_USER, true);
    }
  };

  const handleSelectedRolesChange = (selectedRoles: any) => {
    updateTemplateData('selectedRoles', selectedRoles);
    if (selectedRoles.length > 0) {
      updateValidationState(VALIDATION_STATE.VALIED_ROLE, true);
    }
  }

  const addNewHealthMapSection = () => {
    updateTemplateData('healthMapSections', [...templateData.healthMapSections, {
      parameter: '',
      lowScore: 0,
      highScore: 10,
      inference: '',
    }]);
  };

  const updateHealthMapSection = (index: number, field: string, value: any) => {
    const updatedSections = [...templateData.healthMapSections];
    (updatedSections[index] as any)[field] = value;
    updateTemplateData('healthMapSections', updatedSections);
  };

  const deleteHealthMapSection = (index: number) => {
    const updatedSections = [...templateData.healthMapSections];
    updatedSections.splice(index, 1);
    updateTemplateData('healthMapSections', updatedSections);
  };

  const onChangeLanguageModel = (model: string) =>{
    updateTemplateData('languageModel', model)
  }

  useEffect(() => {
    const items = templateData.healthMapSections.map((section, index) => {
      return {
        'id': index + '',
        parameter: section.parameter,
        lowScore: section.lowScore,
        highScore: section.highScore,
        inference: section.inference,
        content: (
          <HStack flex={1} key={index} space={2} style={styles.templateSectionCard} marginBottom={2}>
            <VStack flex={20} space={2} gap={2}>
              <Input
                isDisabled={(!props?.adminAccess && !createdByLoginUser && !isBusinessOwner) || props?.template?.isDefault}
                _focus={{ borderColor: Colors.FoldPixel.PRIMARY_FOCUS, }}
                _hover={{ borderColor: Colors.FoldPixel.PRIMARY_FOCUS }}
                backgroundColor={Colors.Custom.White}
                placeholder='Parameter'
                borderColor={Colors.FoldPixel.GRAY200}
                borderWidth={0.5}
                padding={2}
                value={section.parameter || ''}
                onChangeText={(value) => updateHealthMapSection(index, 'parameter', value)}
              />
              <Input
                multiline
                isDisabled={(!props?.adminAccess && !createdByLoginUser && !isBusinessOwner) || props?.template?.isDefault}
                numberOfLines={8}
                placeholder='Inference'
                value={section.inference}
                _hover= {{
                  background: Colors.Custom.White,
                }}
                bg={Colors.Custom.White}
                style={styles.templateSectionInput}
                onChangeText={(text) => updateHealthMapSection(index, 'inference', text)}
              />
            </VStack>
            {templateData.healthMapSections.length > 1 && !(!isBusinessOwner && props?.template?.isDefault) ?
              <VStack width={'100%'} flex={1} alignItems={'center'} justifyContent={'space-between'}>
                <DragHandleIcon />
                <Pressable
            isDisabled={(!props?.adminAccess && !createdByLoginUser && !isBusinessOwner) || props?.template?.isDefault}
            onPress={() => deleteHealthMapSection(index)}
                >
                  <TrashBin2Icon color={Colors.error[600]} />
                </Pressable>
              </VStack> : <></>}
          </HStack>
        )
      }
    });
    const sortedItems = items.sort((a : any, b : any) => parseInt(a.id) - parseInt(b.id));
    updateUiState('items', sortedItems as any);
  }, [templateData.healthMapSections])

  return (
    uiState.isDrawerOpen ?
    <Drawer
      destroyOnClose
      placement='right'
      className='custom-drawer-styles'
      closable
      open={uiState.isDrawerOpen}
      width={700}
      // closeIcon
      // height={'100%'}
      title={
        <ModalActionTitle
          title={props.viewCode == HealthMapTemplateViewCodes.EDIT ? intl.formatMessage({
            id: 'updateHealthMapTemplate',
          }) : intl.formatMessage({
            id: 'createHealthMapTemplate',
          })}
          titleSize={24}
          titleColor={'#000000'}
          rightButton={
            <View style={styles.rightButtonContainer}>
              <FoldButton
                customProps={{
                  btnText: props.viewCode === HealthMapTemplateViewCodes.EDIT ? 'Update' : 'Create',
                }}
                nativeProps={{
                  onPress:() => {
                    handleActionPerformed(COMMON_ACTION_CODES.SAVE, props.viewCode);
                  },
                  isLoading: uiState.isSubmitPressed,
                  backgroundColor: Colors.Custom.Primary300,
                  _text: {
                    fontSize: 14,
                    lineHeight: 16.8,
                    fontWeight: 'bold',
                    color: Colors.Custom.White,
                  },
                  // leftIcon: <Icon name='check' color={isFormValid ? Colors.Custom.White : Colors.Custom.Gray400} size={15} />,
                  disabled: uiState.isSubmitPressed,
                }}
              />
              <View style={styles.divider} />
              <Pressable
                onPress={() => {
                  handleActionPerformed(COMMON_ACTION_CODES.CANCEL, props.viewCode);
                }}
              >
                <Icon as={AntIcon} name='close' size={7} color={Colors.Custom.Gray500} />
              </Pressable>
            </View>
          }
        />
      }
    >
      <VStack mx={6} mt={2} space={6} mb={5}>
        <FormControl>
          <VStack space={1}>
            <FormControl.Label isRequired padding={0} margin={0} alignItems={'center'}>
              <Text style={styles.subtitleStyle} fontWeight={400} fontSize={14} lineHeight={16.8} color={Colors.FoldPixel.GRAY200}>{'Status'}</Text>
            </FormControl.Label>
            <Radio.Group onChange={onChange} defaultValue={true} value={templateData.isActive} size='large' disabled={(!props?.adminAccess && !createdByLoginUser && !isBusinessOwner) || props?.template?.isDefault}>
              <Radio value={true} >{'Active'}</Radio>
              <Radio value={false}>{'Inactive'}</Radio>
            </Radio.Group>
          </VStack>
        </FormControl>

        <FormControl isRequired>
          <VStack space={1}>
            <FormControl.Label padding={0} margin={0} alignItems={'center'}>
              <Text style={styles.subtitleStyle} fontWeight={400} fontSize={14} lineHeight={16.8} color={Colors.FoldPixel.GRAY200}>
                {'Template Name'}
              </Text>
            </FormControl.Label>
            <Input
              isDisabled={(!props?.adminAccess && !createdByLoginUser && !isBusinessOwner) || props?.template?.isDefault}
              _focus={{ borderColor: validationState.isValidName ? Colors.FoldPixel.PRIMARY_FOCUS : 'red.600' }}
              _hover={{ borderColor: validationState.isValidName ? Colors.FoldPixel.PRIMARY_FOCUS : 'red.600' }}
              backgroundColor={'white'}
              placeholder='Add Title'
              borderColor={validationState.isValidName ? Colors.FoldPixel.GRAY200 : 'red.600'}
              borderWidth={validationState.isValidName ? 0.5 : 1}
              padding={2}
              onChangeText={onChangeText}
              value={templateData.templateName}
              onChange={() => updateValidationState(VALIDATION_STATE.VALID_NAME, true)}
            >
            </Input>
            {
              !validationState.isValidName ? <Text color={'error.600'}>Enter Valid Name</Text> : <></>
            }
          </VStack>
        </FormControl>

        <VStack space={1}>
          <FormControl.Label isRequired padding={0} margin={0} alignItems={'center'}>
            <Text style={styles.subtitleStyle} fontWeight={400} fontSize={14} lineHeight={16.8} color={Colors.FoldPixel.GRAY200}>
              {'Accessed By'}
            </Text>
          </FormControl.Label>
          <Radio.Group
            onChange={(value) => { onChangeAccessRole(value.target.value) }}
            defaultValue={'PRIVATE'}
            value={templateData.accessRole}
            style={{ borderWidth: 20 }}
            disabled={(!props?.adminAccess && !createdByLoginUser && !isBusinessOwner) || props?.template?.isDefault}
          >
            <Radio value={HEALTH_MAP_VIEW_ROLE.SELECTED_ROLES}>Roles</Radio>
            <Radio value={HEALTH_MAP_VIEW_ROLE.SELECTED_USERS}>Users</Radio>
            <Radio value={HEALTH_MAP_VIEW_ROLE.ALL_USERS}>Everyone</Radio>
            <Radio value={HEALTH_MAP_VIEW_ROLE.PRIVATE}>Only Me</Radio>
          </Radio.Group>

          {
            (templateData.accessRole == 'SELECTED_ROLES') && (
              <VStack>
                <AntSelect
                  allowClear
                  disabled={(!props?.adminAccess && !createdByLoginUser && !isBusinessOwner) || props?.template?.isDefault}
                  mode='multiple'
                  placeholder='Select Roles'
                  loading={!props?.roleList?.length}
                  value={props?.roleList?.length ? templateData.selectedRoles : []}
                  onChange={handleSelectedRolesChange}
                  showSearch
                  filterOption={(inputValue: string, option: any) =>
                    option?.children?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {
                    props?.roleList?.map((role: any) => {
                      return (
                        <Option key={role.roleId} value={role.roleId}>
                          {role?.userRole?.value}
                        </Option>
                      )
                    })
                  }
                </AntSelect>
                {
                  !validationState.isValidRole ? <Text color={'error.600'}>Select Valid Role</Text> : <></>
                }
              </VStack>
            )
          }
          {
            (templateData.accessRole == 'SELECTED_USERS') && (
              <VStack>
                <AntSelect
                  allowClear
                  disabled={(!props?.adminAccess && !createdByLoginUser && !isBusinessOwner) || props?.template?.isDefault}
                  mode='multiple'
                  placeholder='Select Users'
                  loading={!props?.userList?.length}
                  value={props?.userList?.length ? templateData.selectedUsers : []}
                  onChange={handleSelectedUsersChange}
                  showSearch
                  filterOption={(inputValue: string, option: any) =>
                    option?.children?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {
                    props?.userList?.map((user: any) => {
                      return (
                        <Option key={user.uuid} value={user.uuid}>
                          {user.name}
                        </Option>
                      )
                    })
                  }
                </AntSelect>
                {
                  !validationState.isValidUser ? <Text color={'error.600'}>Select Valid User</Text> : <></>
                }
              </VStack>
            )
          }
        </VStack>
        {isLanguageModelComponentVisible(accountUUID, userId) && (
          <FormControl isRequired>
            <VStack space={1}>
              <FormControl.Label padding={0} margin={0} alignItems={'center'}>
                <Text style={styles.subtitleStyle} fontWeight={400} fontSize={14} lineHeight={16.8} color={Colors.FoldPixel.GRAY200}>
                  {'Language Model'}
                </Text>
              </FormControl.Label>
              <AntSelect
                style={{ width: '100%' }}
                placeholder='Select Parameter'
                value={templateData.languageModel || GPT_4O}
                onChange={onChangeLanguageModel}
                >
                {LLM_LANGUAGE_MODELS.map((param) => (
                  <Option key={param.value} value={param.value}>{param.label}</Option>
                ))}
              </AntSelect>
            </VStack>
          </FormControl>
         )} 
        {isBusinessOwner && formatJSONView(
            'System Context Prompt',
            props.systemPrompt,
            expandedSystemContext,
            setExpandedSystemContext,
            isLoading
        )}

        <VStack space={3}>
          <FormControl.Label padding={0} margin={0} alignItems={'center'} backgroundColor={Colors.Custom.White}>
          <Text style={styles.subtitleStyle} fontWeight={400} fontSize={14} lineHeight={16.8} color={Colors.FoldPixel.GRAY200}>
              Input Prompt (Template Sections)
            </Text>
          </FormControl.Label>

          <DraggableList initialItems={uiState.items} setItems={(items: any) => {
            updateTemplateData('healthMapSections', items.map((item: any) => {
              return {
                parameter: item.parameter,
                lowScore: item.lowScore,
                highScore: item.highScore,
                inference: item.inference
              }
            }))
          }} />

          <Pressable onPress={addNewHealthMapSection}>
            <Text color={Colors.Custom.Primary300}>
              <Feather name='plus' size={18} color={Colors.Custom.Primary300} />
              Add New Section
            </Text>
          </Pressable>
        </VStack>
        {isBusinessOwner && formatJSONView(
          'Output Prompt',
          props.userPrompt,
          expandedUserContext,
          setExpandedUserContext,
          isLoading
        )}
      </VStack>
    </Drawer> : <></>
  );
};

export default AddOrUpdateHealthMapTemplates;
