import BaseService from "../../../services/CommonService/BaseService";
import { IHolidayInput } from "./Interfaces";

export const getAllHolidays = (searchString: any, holidayFilter: string, accountLocations?: string[]) => {
    const baseService = BaseService.getSharedInstance();
    const axiosService = baseService.axios;
    const params = {
      searchString: searchString || '',
      holidayFilterKey: holidayFilter,
      locationUuids: accountLocations,
    }
    return axiosService.get(
      `crm-nest/api/v1/holiday`,
      {params: params}
    );
  };

  export const addOrUpdateHoliday = async (body: IHolidayInput) => {
    const baseService = BaseService.getSharedInstance().axios;
    try {
      const response = await baseService.post(
        'crm-nest/api/v1/holiday',
        body,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      return response;
    } catch (error) {

      return error;
    }
  };

  export const updateHolidayInTelephonyCache = async () => {
    const baseService = BaseService.getSharedInstance().axios;
    try {
      const response = await baseService.post(
        'crm-nest/api/v1/holiday/telephony-cache',
        {},
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      return response;
    } catch (error) {

      return error;
    }
  };
