import React from 'react';
import Svg, { Path } from "react-native-svg";
import { Colors } from '../../../styles/Colors';

const LoopSvg = (props: {strokeColor?: string}) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.33325 3.33351L6.66659 1.66684M8.33325 3.33351L6.66659 5.00017M8.33325 3.33351L3.74992 3.3335C3.19436 3.3335 2.08325 3.66684 2.08325 5.00017M2.08325 6.66684L3.74992 8.33351M2.08325 6.66684L3.74992 5.00017M2.08325 6.66684L6.66659 6.66684C7.22214 6.66684 8.33325 6.33351 8.33325 5.00017" stroke="#6F7A90" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default LoopSvg;
