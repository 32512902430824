import React from 'react';
import {Text, View} from 'native-base';
import {styles} from './PageTitleViewStyles';
import {IPageTitleViewProps} from './interfaces';

export const PageTitleView = (props: IPageTitleViewProps) => {
  return (
    <View style={[styles.pageTitleContainer, props.style]}>
      <Text style={styles.pageTitle}>{props.title}</Text>
      <Text style={styles.pageSubtitle}>{props.description}</Text>
    </View>
  );
};
export default PageTitleView;
