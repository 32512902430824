export const CODE_GROUP_CATEGORY = {
    EMPLOYER: 'Employer',
    ZIP_CODE: 'Zip Code',
    PATIENT_AGE: 'Patient Age',
    PATIENT_TAG: 'Patient Tag',
    VITAL: 'Vital',
    LAB_ORDER: 'Lab Order',
    LAB_RESULTS: 'Lab Results',
    RAD_ORDERS: 'Rad Orders',
    PROBLEM: 'Problem',
    DIAGNOSIS: 'Diagnosis',
    ALLERGY: 'Allergy',
    MEDICATION: 'Medication',
    MEDICATION_ORDER: 'Medication Order',
    IMMUNIZATION: 'Immunization',
    PROCEDURE: 'Procedure',
    SOCIAL_HISTORY: 'Social History',
    FAMILY_HISTORY: 'Family History',
    APPOINTMENT: 'Appointment',
    SEX_AT_BIRTH: 'Sex At Birth',
    GENDER_IDENTITY: 'Gender Identity'
  };
  