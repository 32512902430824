import { Button, HStack, Icon, Skeleton, View, VStack } from 'native-base';
import { useEffect, useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  useNavigate,
  useParams, useSearchParams
} from 'react-router-dom';
import { WORKFLOW_TAGS } from '../../../../../constants';
import { Colors } from '../../../../../styles';
import { getAccountUUID } from '../../../../../utils/commonUtils';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import { getWorkflowBasePath } from '../../workflowUtils';
import WorkflowExecutionLevelLog from '../WorkflowTableView/Helper/WorkflowExecutionLevelLog';
import { getWorkflowInfoByMasterId } from './WorkflowApi';
import { styles } from '../../workflowStyles';

const WorkflowAudit = () => {

  const [workflowData, setWorkflowData] = useState<any>()
  const [searchParams, setSearchParams] = useSearchParams();
  const [flowType] = useState(searchParams.get('flowType') || '')
  const pathParams = useParams();
  const workflowMasterId = pathParams.workflowId;
  const [isLoading, setLoading] = useState(false)
  const accountUUID = getAccountUUID();
  const [isShowViewAllButton, setIsShowViewAllButton] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (workflowMasterId) {
      setLoading(true)
      getWorkflowInfoByMasterId(workflowMasterId, accountUUID).then((workflowData) => {
        setLoading(false)
        setWorkflowData(workflowData)
      })
    }

  }, [workflowMasterId])
  return (
    <View width="full">
      <View backgroundColor={'white'}>
        <TitleSubtitleView
          titleLabelId={workflowData?.name ? "Workflow Audit | " + (workflowData?.name) : "Workflow Audit"}
          actionContainer={
            <View style={styles.marginRight25}>

            </View>
          }
        />
        <HStack backgroundColor={'gray.100'} width={'100%'} flexDirection="row" display={'flex'}  alignItems={'flex-end'} justifyContent={'flex-end'}>
          <VStack
           margin={5}
          >
          {isShowViewAllButton &&  <Button
              justifyContent={'center'}
              borderWidth={1}
              backgroundColor={
                Colors.secondary['100']
              }
              borderColor={
                Colors.secondary['800']
              }

              size={'sm'}
              colorScheme={'muted'}
              variant={''}
              onPress={() => {
                const navigationPath = getWorkflowBasePath(flowType);

                navigate(
                  navigationPath + '/execution/audit' +
                  '/' +
                  workflowMasterId + '/graph' +
                  '?flowType=' +
                  flowType + '&currentTab=' + WORKFLOW_TAGS.WORKFLOW + '&isExecutionLog=true'
                );
              }}
              leftIcon={(
                <Icon
                  as={AntIcon}
                  name={'plus'}
                  color={Colors.secondary['800']}
                  size="4"
                />
              )}
              style={styles.buttonStyle}
            >
              <DisplayText
                extraStyles={{
                  color: Colors.secondary['800'] || '',
                  display: 'flex',
                  alignItems: 'center',
                }}
                textLocalId={'View All'}
              />
            </Button> }
          </VStack>
        </HStack>
      </View>
      <View padding={5} overflow={'scroll'} height={700} justifyContent={'center'} alignItems={'center'}>
        {isLoading ? <Skeleton.Text lines={4}></Skeleton.Text> : <></>}
        {workflowMasterId && !isLoading ? <WorkflowExecutionLevelLog width={1200} isShowViewAllButton={isShowViewAllButton} setIsShowViewAllButton={setIsShowViewAllButton} flowType={flowType} isShowPersonLevelCommunicationInfo={true} height={800}  workflowMasterId={workflowMasterId}></WorkflowExecutionLevelLog> : <></>}
      </View>
    </View>
  );
};

export default WorkflowAudit;
