import {StyleSheet} from "react-native";
import {Colors} from '../../../../../styles';
export const styles = StyleSheet.create({
  container: {
    margin: '6.5px',
  },
  leftContainer: {
    backgroundColor: 'white',
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 0.5,
    flex: 0.2,
    marginBottom: 6,
    marginTop: 8,
    marginLeft: 8,
    justifyContent: 'flex-start',
    minWidth: 250,
    bg: Colors.Custom.BackgroundColor,
  },
  leftHeaderContainer: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#e5e7eb',
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 12,
    paddingLeft: 12,
  },
  rightContainer: {
    backgroundColor: 'white',
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 0.5,
    margin: 8,
  },
  buttonStyle: {
    paddingRight: 3,
    paddingLeft: 3,
    borderRadius: 25,
    paddingTop: 7,
    paddingBottom: 7
  },
  buttonText: {
    fontSize: 12,
    color: 'white'
  },
  listContainer: {
    width: '100%',
  },
  itemContainer: {
    paddingHorizontal: 5,
    paddingVertical: 1
  },

  listItem: {
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  listAction: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  icons: {
    fontSize: 14,
    color: "gray",
  },
})
