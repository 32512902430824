import {Table} from 'antd';
import {View} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useNavigate} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {Colors} from '../../../../../styles';
import {
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES,
} from '../../../../common/TableTopBar';
import {TableWrapper} from '../../../../common/TableWrapper';
import {CMSLoading} from '../../CMSLoading';
import {ContentTypes} from '../../ContentManagementConsts';
import {
  deleteTemplate,
  getSortString,
  getTableSortOrderString,
  getTemplates,
} from '../../ContentManagementUtils';
import {PEApiData, PEApiMetaData} from '../interfaces';
import {
  getFormattedPEContent,
  getPreviewLinkFromSlug,
} from '../PatientEducationUtils';
import {getPETableColumns} from '../PETable/Helper';

const PatientEducationMainView = (props: any) => {
  // const [patientEduData, setPatientEduData] = useState<any[]>([]);
  const {height} = Dimensions.get('window');

  const [articlesTableState, setArticlesTableState] = useState({
    isLoading: true,
    isNoDataFound: false,
    searchString: '',
    articlesList: [] as PEApiData[],
    articlesDisplayList: [] as any[],
    articlesMeta: {} as PEApiMetaData,
    page: 1,
    pageSize: 10,
    sort: 'desc',
  });

  const getArticles = async () => {
    const articleResponse = await getTemplates(
      ContentTypes.patientEducationArticles.path,
      {
        title: articlesTableState.searchString,
        page: articlesTableState.page,
        pageSize: articlesTableState.pageSize,
        sort: articlesTableState.sort,
      }
    );

    const data = getFormattedPEContent(articleResponse);
    setArticlesTableState((prev) => {
      return {
        ...prev,
        articlesDisplayList: data,
        articlesList: articleResponse.data,
        isLoading: false,
        isNoDataFound: false,
        articlesMeta: articleResponse.meta,
      };
    });
  };

  const navigate = useNavigate();

  const handlePreviewArticle = (actionData: any) => {
    const url = getPreviewLinkFromSlug(actionData.slug);
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const onPETableActionPerformed = (actionCode: string, actionData: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        const route = `/admin/contentManagement/patientEducation/${actionData.id}`;
        navigate(route);
        break;

      case COMMON_ACTION_CODES.DELETE:
        handleDelete(actionData);
        break;
      case COMMON_ACTION_CODES.PREVIEW:
        handlePreviewArticle(actionData);
        break;

      case COMMON_ACTION_CODES.ROW_CLICKED:
        navigate(`/admin/contentManagement/patientEducation/${actionData.id}`);
        break;
    }
  };

  const handleDelete = (patientEducationData: any) => {
    const id = patientEducationData.id;
    deleteTemplate(ContentTypes.patientEducationArticles.path, id)
      .then((response) => {
        const newData = articlesTableState.articlesDisplayList.filter(
          (data) => data.id !== id
        );
        setArticlesTableState((prev) => {
          return {
            ...prev,
            articlesDisplayList: newData,
          };
        });
      })
      .catch((error) => {});
  };

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setArticlesTableState((prev) => {
          return {
            ...prev,
            page: 1,
            searchString: actionData?.searchString || '',
          };
        });
    }
  };

  useEffect(() => {
    setArticlesTableState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    try {
      getArticles();
    } catch (error) {
      setArticlesTableState((prev) => {
        return {
          ...prev,
          isLoading: false,
          isNoDataFound: false,
        };
      });
    }
  }, [
    articlesTableState.page,
    articlesTableState.pageSize,
    articlesTableState.searchString,
    articlesTableState.sort,
  ]);

  useEffect(() => {
    setArticlesTableState((prev) => {
      return {
        ...prev,
        page: props.page,
      };
    });
  }, [props.page]);

  useEffect(() => {
    setArticlesTableState((prev) => {
      return {
        ...prev,
        searchString: props.searchString,
      };
    });
  }, [props.searchString]);

  return (
    <View height={height - 200}>
      <View>
        <View
          bgColor={'white'}
          borderRadius={0}
          // p={2}
        >
          {props.showTableTopBar && (
            <TableTopBar
              title=""
              onActionPerformed={onTableTopBarActionPerformed}
              buttonList={[
                {
                  btnClick: () => {
                    navigate(
                      '/admin/contentManagement/patientEducation/create'
                    );
                  },
                  btnText: 'createArticle',
                  backgroundColor: Colors.secondary['100'],
                  textColor: Colors.secondary['800'],
                  borderColor: Colors.secondary['200'],
                  size: 'sm',
                  variant: 'PRIMARY',
                  leftIcon: (
                    <AntIcon
                      name="plus"
                      color={Colors.secondary['800']}
                      size={16}
                    />
                  ),
                },
              ]}
            />
          )}
          <View>
            {articlesTableState.articlesDisplayList &&
              !articlesTableState.isLoading && (
                <TableWrapper
                  pagination={{
                    current: articlesTableState.page,
                    pageSize: articlesTableState.pageSize,
                    total: articlesTableState.articlesMeta.pagination.total,
                    onChange: (currentPage, currentPageSize) => {
                      setArticlesTableState((prev) => {
                        return {
                          ...prev,
                          page: currentPage,
                          pageSize: currentPageSize,
                        };
                      });
                    },
                  }}
                  hideOnSinglePage={false}
                >
                  <Table
                    rowClassName={(record, index) =>
                      index % 2 == 0 ? 'table-row-light' : ''
                    }
                    dataSource={articlesTableState.articlesDisplayList || []}
                    columns={getPETableColumns(
                      onPETableActionPerformed,
                      getTableSortOrderString(articlesTableState.sort)
                    )}
                    rowKey={(row) => row.id}
                    onChange={(_, __, sorter: any) => {
                      setArticlesTableState((prev) => {
                        return {
                          ...prev,
                          sort: getSortString(sorter['order']),
                        };
                      });
                    }}
                    scroll={{x: 700, y: height - 275}}
                    pagination={false}
                    onRow={(data) => {
                      return {
                        onClick: () => {
                          onPETableActionPerformed(
                            COMMON_ACTION_CODES.ROW_CLICKED,
                            data
                          );
                        },
                      };
                    }}
                  />
                </TableWrapper>
              )}
            {articlesTableState.isLoading && <CMSLoading />}
          </View>
        </View>
      </View>
    </View>
  );
};

export default PatientEducationMainView;
