import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';
import {isWeb} from '../../../../../utils/platformCheckUtils';

export const getStyles = () => {
  return StyleSheet.create({
    noMemberImgStyle: {
      marginTop: 20,
      width: 90,
      height: 90,
    },
    noMemberTextStyle: {
      textAlign: 'center',
      color: 'gray.300',
      fontWeight: '400',
      fontSize: 18,
    },
    membersWindow: {
      borderWidth: 1,
      backgroundColor: Colors.Custom.ContainerBGColor,
      borderColor: Colors.Custom.Gray200,
      paddingHorizontal: 8,
      borderTopWidth: 0,
      borderLeftWidth: 0,
    },
    videoCallContainer: {
      width: 36,
      height: 36,
    },
    deleteBtnImg: {
      width: 32,
      height: 32,
    },
    displayCardAvatarContainer: {
      width: isWeb() ? 90 : 16,
      height: isWeb() ? 90 : 16,
    },
    contentContainerStyle: {
      flexGrow: 1,
      overflow: 'scroll',
    },
  });
};

export const avatarStyle = {
  avatarSize: '16',
};
