import { View, IconButton, Tooltip } from 'native-base';
import { COMMON_ACTION_CODES } from '../../../../../../constants/ActionConst';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../../styles';
import { ICustomContactTypesTableAction } from '../../interfaces';

const CustomContactTypesActions = (props: ICustomContactTypesTableAction) => {
  const { record } = props;

  const handleEditContactType = () => {
    props.onActionPerformed(COMMON_ACTION_CODES.EDIT, record);
  };

  return (
    <View flex={1} flexDirection={'row'}>
      <Tooltip label={record.isDefault ? 'Default contact types cannot be edited' : 'Edit'} >
        <IconButton
          icon={<Feather name="edit-2" size={17} color={Colors.Custom.Gray600} />}
          isDisabled={record.isDefault}
          onPress={() => {
            !record.isDefault && handleEditContactType()
          }}
        />
      </Tooltip>
    </View>
  );
};

export default CustomContactTypesActions;
