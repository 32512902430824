import {useLazyQuery} from '@apollo/client';
import {Table} from 'antd';
import {Spinner, Text, View, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {useParams} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import CostTemplateQueries from '../../../../../../services/CostSavingTemplates/CostTemplateQueries';
import {getAccountUUID} from '../../../../../../utils/commonUtils';
import PageBodyContainer from '../../../../../common/PageBodyContainer/PageBodyContainer';
import {TableWrapper} from '../../../../../common/TableWrapper';
import {ICptCostTableProps} from '../helperFiles/interface';
import NoTemplateView from '../helperFiles/NoTemplateView';
import {getSavingCptFormattedData, getTemplateCptQuery} from '../helperFiles/utils';
import {getCostTableColumns} from './CptCostTableColumnView';

const CptCostTableView = (props: ICptCostTableProps) => {
  const {costTypeCode, onActionPerformed, saveActionPerformed, searchString} = props;
  const {id, tabName} = useParams();
  const accountUuid = getAccountUUID();
  const [stateData, setStateData] = useState({
    currentPage: 1,
    loading: false,
    dataList: [] as any,
    pageSize: 10,
    offset: 0,
    total: 0
  });
  const {height} = Dimensions.get('window');
  const finalHeight = height - 280;

  const [GET_CPT_BY_TEMPLATE] = useLazyQuery(
    CostTemplateQueries.GET_CPT_BY_TEMPLATE,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const handlePageChange = (page: number, currentPageSize: number) => {
    const offset = currentPageSize * (page - 1);
    setStateData((prev) => {
      return {
        ...prev,
        currentPage: page,
        pageSize: currentPageSize,
        offset: offset,
      };
    });
  };
  const getTemplatesCpts = async (offSet?: number) => {
    const templateId = id;
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const response = await GET_CPT_BY_TEMPLATE({
      variables: {
        where: getTemplateCptQuery({
          accountUuid,
          templateId,
          searchString
        }),
        limit: stateData.pageSize,
        offset: offSet == 0 ? offSet : stateData.offset,
      },
    });
    if (response.data?.costSavingTemplateCpts?.length) {
      const costSavingData = getSavingCptFormattedData(
        response.data?.costSavingTemplateCpts
      );
      const totalAgr =
        response?.data?.costSavingTemplateCptsAggregate?.aggregate?.count;
      setStateData((prev) => {
        return {
          ...prev,
          dataList: costSavingData,
          loading: false,
          total: totalAgr,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          dataList: [],
          loading: false,
          total: 0,
        };
      });
    }
  };

  useEffect(() => {
    if (stateData.currentPage !== 1) {
      setStateData((prev) => {
        return {
          ...prev,
          currentPage: 1,
          offset: 0,
        };
      });
    } else {
      getTemplatesCpts(0)
    }
  }, [
    costTypeCode,
    searchString
  ]);

  useEffect(()=> {
    getTemplatesCpts()
  },[stateData.currentPage, stateData.pageSize])

  useEffect(()=> {
    if(saveActionPerformed) {
      getTemplatesCpts()
    }
  },[saveActionPerformed])

  return (
    <PageBodyContainer>
      <TableWrapper
        wrapperStyle={{alignItems: 'center'}}
        pagination={{
          current: stateData.currentPage,
          pageSize: stateData.pageSize,
          total: stateData.total || 0,
          onChange: (currentPage, currentPageSize) => {
            handlePageChange(currentPage, currentPageSize);
          },
        }}
      >
        <Table
          scroll={{x: 700, y: finalHeight}}
          columns={getCostTableColumns(onActionPerformed, costTypeCode, tabName)}
          rowKey={(row: any) => row?.id}
          loading={{
            spinning: stateData.loading,
            indicator: (
              <VStack justifyContent="center" height={'100%'}>
                <Spinner size={'lg'} />
              </VStack>
            ),
          }}
          dataSource={stateData.dataList}
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          onRow={(data) => {
            return {
              onClick: () => {
                props.onActionPerformed?.(
                  COMMON_ACTION_CODES.DETAIL_VIEW,
                  data
                );
              },
            };
          }}
          pagination={false}
          locale={{
            emptyText: !stateData.loading && <NoTemplateView />,
          }}
        />
      </TableWrapper>
    </PageBodyContainer>
  );
};

export default CptCostTableView;
