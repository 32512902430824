import {StyleSheet} from 'react-native';
import { Colors } from '../../../../styles/Colors';

export const styles = StyleSheet.create({
  selectedFilter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 2,
    paddingRight: 8,
    paddingBottom: 2,
    paddingLeft: 10,
    backgroundColor: '#F2F4F7',
    borderRadius: 16,
    height: 24,
    fontWeight: 500
  },
  selectedNativeFilter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 2,
    paddingRight: 8,
    paddingBottom: 2,
    paddingLeft: 10,
    borderRadius: 16,
    height: 24,
    fontWeight: 500
  },

  alignItemsCenter: {
    alignItems: 'center',
  },
  underlinedText: {
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline',
  },
  vStackContainer: {
    width: '100%',
    borderBottomColor: Colors.Custom.BorderColor,
  },
  hStackContainer: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.BorderColor,
    justifyContent: 'space-between',
  },
  sidecarView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    justifyContent: 'flex-end',
  },
  filterButton: {
    width: 22,
    height: 20,
    justifyContent: 'center',
  },
  filterCountContainer: {
    position: 'absolute',
    top: -6.5,
    right: -10.5,
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterCountText: {
    color: Colors.Custom.Gray500,
    fontWeight: '600',
    fontSize: 10,
  },
  divider: {
    width: 1,
    height: 20,
    // backgroundColor: Colors.Custom.Gray200,
    marginHorizontal: 8,
  },
  headerContainer: {
    marginRight: 5,
  },
  rangePicker: {
    height: 44,
    width: '100%',
    borderRadius: 8,
  },
  bulkTaskButton: {
    marginLeft: 4,
  },
  closeButton: {
    marginLeft: 8,
    padding: 4,
  },
  filterHStack: {
    borderBottomColor: Colors.Custom.Gray200,
    justifyContent: 'space-between',
  },
  filterRowHStack: {
    alignItems: 'center',
    flex: 1,
  },
  clearAllContainer: {
    marginRight: 2,
    marginLeft: 4,
  },
  clearAllButton: {
    marginRight: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  clearAllText: {
    marginLeft: 6,
  },
});
