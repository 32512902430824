import CMSService from '../../../../services/CommonService/CMSService';
import {
  getCategoryId,
  getTemplates,
  ITemplateQueryParams,
} from '../ContentManagementUtils';
import {IEmailTemplateData, ITemplateCategory} from './interfaces';
import {getEmailComponents} from '../EmailComponents/EmailComponentsUtils';
import {ContentTypes} from '../ContentManagementConsts';
const cmsInstance = CMSService.getCMSServiceInstance();
const fileUploadService = cmsInstance.fileUploadService;
// function to get formatted data for templates
export const getFormattedEmailTemplateData = (
  template: any
): IEmailTemplateData[] => {
  const formattedEmailTemplatesData: IEmailTemplateData[] = [];
  const data = template.data;
  data.map((item: any) => {
    const tempObj: IEmailTemplateData = {
      id: item.id,
      templateName: item.attributes.name,
      name: item.attributes.name,
      templateCategory: item.attributes.category.data.attributes.name,
      templateCategoryCode: item.attributes.category.data.attributes.code,
      templateLastUpdated: item.attributes.updatedAt,
      templateIsDefault: item.attributes.isDefault,
      templateData: {
        content: item.attributes.bodyJson,
        subject: item.attributes.subject,
        subTitle: '',
      },
      templateHtml: item.attributes.bodyHtml,
      templateMjml: item.attributes.bodyMjml,
    };
    formattedEmailTemplatesData.push(tempObj);
  });

  return formattedEmailTemplatesData || [];
};

// function to get formatted data for a single template
export const getFormattedSingleEmailTemplate = (
  templateObj: any
): IEmailTemplateData => {
  const data = templateObj?.data;
  const tempObj: IEmailTemplateData = {
    id: data?.id,
    templateName: data?.attributes.name,
    name: data.attributes.name,
    templateCategory: data?.attributes.category.data.attributes.name,
    templateCategoryCode: data?.attributes.category.data.attributes.code,
    templateData: {
      content: data?.attributes.bodyJson,
      subTitle: '',
      subject: data?.attributes.subject,
    },
    templateIsDefault: data?.attributes.isDefault,
    templateLastUpdated: data?.attributes.updatedAt,
    templateHtml: data?.attributes.bodyHtml,
    templateMjml: data?.attributes.bodyMjml,
  };

  return tempObj;
};

export function mergeTagGenerator(m: string): string {
  const resultString = `{{${m}}}`;
  return resultString;
}

export const emailTemplateValidation = (template: IEmailTemplateData) => {
  const errors: any = {};
  let validate = true;
  const subject = template?.templateData?.subject;

  //template name
  errors.templateName = !template.templateName
    ? 'Name is required'
    : template.templateName.length < 3
    ? 'Name is too short'
    : '';

  errors.subject = !subject
    ? 'Subject is required'
    : subject.length < 5
    ? 'Subject is too short'
    : '';

  // template category
  errors.templateCategory = !template.templateCategory
    ? 'Category is required'
    : '';

  if (errors.templateName || errors.templateCategory || errors.subject) {
    validate = false;
  }

  return {
    validate: validate,
    errors: errors,
  };
};

export const getEmailTemplateFormattedObj = (
  template: IEmailTemplateData,
  categories: ITemplateCategory[],
  override?: boolean
) => {
  const data = {
    data: {
      name: template.templateName,
      subject: template.templateData.subject,
      bodyHtml: template.templateHtml,
      bodyJson: template.templateData.content,
      bodyMjml: template.templateMjml,
      isDefault: template.templateIsDefault,
      category: getCategoryId(template.templateCategory, categories),
      forceDefault: override,
    },
  };
  return data;
};

export const getCategoryQueryString = (
  categoryList: ITemplateCategory[],
  selectedCategories?: any,
  isType?: boolean
) => {
  let path = '';
  selectedCategories &&
    selectedCategories?.length &&
    selectedCategories?.forEach((item: any) => {
      const categoryCode = categoryList.find(
        (category) => item === category.name || item === category.code
      )?.code;
      path += `&${isType ? 'component_types' : 'category'}=${categoryCode}`;
    });
  return path;
};

export const getEmailEditorRoute = (rowData: IEmailTemplateData) => {
  const content = rowData?.templateData?.content;
  const isHtmlTemplate = Object.keys(content).length === 1 && content.isHTML;
  if (isHtmlTemplate) {
    return `/admin/contentManagement/emailTemplates/html/${rowData.id}`;
  }
  return `/admin/contentManagement/emailTemplates/editor/${rowData.id}`;
};

export const uploadImage = async (blob: File | Blob): Promise<string> => {
  const formData = new FormData();
  formData.append('files', blob);
  const response = await fileUploadService.post('', formData);
  const fileList = response?.data?.data?.attributes?.file?.data;
  const url = fileList && fileList.length ? fileList[0].attributes.url : '';
  return url;
};

export const getDefaultComponent = (args: {type: 'HEADER' | 'FOOTER'}, useProxy = false, proxyHeaders?: any) => {
  const {type} = args;
  return getEmailComponents({
    categoryQueryString: `component_types=${type}&isDefault=true`,
    useProxy,
    proxyHeaders,
  });
};

export const getEmailTemplates = (
  categoryQueryString?: string,
  queryParams?: ITemplateQueryParams
) => {
  const url = `${ContentTypes.emails.path}?${categoryQueryString}`;
  return getTemplates(url, queryParams);
};

export const getReplacedContentWithAccountMergeTags = (data: {
  global: any;
  content: any;
}) => {
  const {content, global} = data;
  let stringContent = JSON.stringify(content);
  Object.keys(global).forEach((item) => {
    const tag = `{{global.${item}}}`;
    const value = global[item];
    stringContent = stringContent.replaceAll(tag, value);
  });
  return JSON.parse(stringContent);
};
