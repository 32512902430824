import { getYearBack } from '../../../../utils/DateUtils';
import { IAgeFilterData } from './interfaces';

export const getFilterObject = (args: {
  fromAge: number;
  toAge: number;
}): IAgeFilterData => {
  const {fromAge, toAge} = args;
  const fromDate = getYearBack(toAge);
  const toDate = getYearBack(fromAge);
  const code = `age_${fromAge}_${toAge}`;
  const type = `${fromAge}-${toAge} Years`;
  return {
    fromAge,
    toAge,
    fromDate,
    toDate,
    code,
    type,
  };
};

const getQueryTemplate = (args: {
  code: string;
  fromDate: string;
  toDate: string;
  employerId?: string;
  tenantId: string | number;
}) => {
  const {code, fromDate, toDate, employerId, tenantId} = args;
  return `${code}:contactAggregate(distinct_on:[id],where:{uuid:{_is_null:false},accountId:{_eq:${tenantId}},person:{birthDate:{_gte:"${fromDate}", _lte:"${toDate}"}}${
    employerId
      ? `employees:{isDeleted:{_eq:false},employerId:{_eq:"${employerId}"}}`
      : ''
  }}) {
  aggregate{
    count
  }
}`;
};

export const getFinalQuery = (args: {
  data: IAgeFilterData[];
  tenantId: number | '';
  employerId?: string;
}): string => {
  const {data, tenantId, employerId} = args;
  let finalQuery = '{';
  data.forEach((item) => {
    finalQuery =
      finalQuery +
      getQueryTemplate({
        code: item.code,
        fromDate: item.fromDate,
        toDate: item.toDate,
        tenantId,
        employerId,
      });
  });
  finalQuery = finalQuery + '}';
  return finalQuery;
};

export const initialGraphData = [
  {
    fromDate: getYearBack(9),
    toDate: getYearBack(0),
    code: 'age_0_9',
    type: '0-9 Years',
    fromAge: 0,
    toAge: 9,
  },
  {
    fromDate: getYearBack(18),
    toDate: getYearBack(10),
    code: 'age_10_18',
    type: '10-18 Years',
    fromAge: 10,
    toAge: 18,
  },
  {
    fromDate: getYearBack(25),
    toDate: getYearBack(19),
    code: 'age_19_25',
    type: '19-25 Years',
    fromAge: 19,
    toAge: 25,
  },
  {
    fromDate: getYearBack(35),
    toDate: getYearBack(26),
    code: 'age_26_35',
    type: '26-35 Years',
    fromAge: 26,
    toAge: 35,
  },
  {
    fromDate: getYearBack(50),
    toDate: getYearBack(36),
    code: 'age_36_50',
    type: '36-50 Years',
    fromAge: 36,
    toAge: 50,
  },
  {
    fromDate: getYearBack(65),
    toDate: getYearBack(51),
    code: 'age_51_65',
    type: '51-65 Years',
    fromAge: 51,
    toAge: 65,
  },
  {
    fromDate: getYearBack(1000),
    toDate: getYearBack(66),
    code: 'above_65',
    type: 'Above 65 Years',
    fromAge: 66,
    toAge: 1000,
  },
];

export const DEFAULT_FROM_VALUE = 10;
export const DEFAULT_TO_VALUE = 18;
