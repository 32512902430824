import {Modal, Progress, Upload, UploadFile} from 'antd';
import {Center, View} from 'native-base';
import {useState} from 'react';
import CommerceCommonService from '../../../../../../services/CommonService/CommerceCommonService';
import {getCommerceSubdomain} from '../../../../../../utils/commonUtils';
import LocalStorage from '../../../../../../utils/LocalStorage';
import HeaderBar from '../../../../../common/HeaderBar/HeaderBar';
import NoDataFound from '../../../../../common/NoDataFound/NoDataFound';
import PageBodyContainer from '../../../../../common/PageBodyContainer/PageBodyContainer';
import {IProductDefaultVariantDatum} from '../ProductTableView/ProductTable/interfaces';
import {getProductRelationDataInMainObj} from '../ProductTableView/ProductUtils/ProductTableUtils';
import {IProductImagesProps} from './interfaces';
import type {RcFile, UploadProps} from 'antd/es/upload';
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
export const ProductImages = (props: IProductImagesProps) => {
  const {parentHeight, productData} = props;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const productFinalData = getProductRelationDataInMainObj(
    productData.data,
    productData.included
  );
  const getDefaultFileList = (
    imageList: IProductDefaultVariantDatum[]
  ): any => {
    const tempFileList: any = [];
    imageList.forEach((singleImage) => {
      if (singleImage?.includedAttributes?.original_url) {
        const imageUrl = `https://${getCommerceSubdomain()}${
          singleImage?.includedAttributes?.original_url
        }`;
        tempFileList.push({
          uid: singleImage.id,
          name: singleImage.includedAttributes?.name || 'Image_' + Date.now(),
          status: 'done',
          url: imageUrl,
        });
      }
    });
    return tempFileList;
  };
  let tempDefaultImageList = [];
  if (productData?.data?.id) {
    const imageList = productFinalData?.relationships?.images.data || [];
    if (imageList) {
      tempDefaultImageList = getDefaultFileList(imageList);
    }
  }
  const [defaultFileList, setDefaultFileList] = useState([
    ...tempDefaultImageList,
  ]);
  const [progress, setProgress] = useState(0);

  const uploadImage = async (options: any) => {
    const {onSuccess, onError, file, onProgress} = options;
    const productId = productData.data.id;
    const fmData = new FormData();
    const commerceUserTokenStr = await LocalStorage.getItem(
      'commerceUserToken'
    );
    const commerceUserTokenData = JSON.parse(commerceUserTokenStr || '{}');
    let xSpreeToken = '';
    if (commerceUserTokenData) {
      xSpreeToken = commerceUserTokenData.x_spree_token || '';
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'X-Spree-Token': xSpreeToken,
      },
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({percent: (event.loaded / event.total) * 100});
      },
    };
    fmData.append('image[attachment]', file);
    try {
      const commerceCommonService =
        CommerceCommonService.getCommerceCommonServiceInstance();
      const axiosService = commerceCommonService.axiosService;
      const res = await axiosService.post(
        `/commerce/api/v1/products/${productId}/images`,
        fmData,
        config
      );

      onSuccess('Ok');
    } catch (err) {
      const error = new Error('Some error');
      onError({error});
    }
  };

  const handleOnChange = ({file, fileList}: any) => {
    setDefaultFileList(fileList);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    );
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      {productData?.data?.id ? (
        <PageBodyContainer>
          <View height={parentHeight - 300}>
            <HeaderBar title="productImages" />
            <View padding={5}>
              <Upload
                accept="image/png,image/gif,image/jpg,image/jpeg,image/pjpeg,image/tiff,image/bmp,image/webp"
                onPreview={handlePreview}
                customRequest={uploadImage}
                onChange={handleOnChange}
                listType="picture-card"
                defaultFileList={defaultFileList}
                className="image-upload-grid"
              >
                <div>Upload Button</div>
              </Upload>
              {progress > 0 ? <Progress percent={progress} /> : null}
            </View>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{width: '100%'}} src={previewImage} />
            </Modal>
          </View>
        </PageBodyContainer>
      ) : (
        <PageBodyContainer>
          <View height={parentHeight / 2}>
            <Center
              top={'30%'}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <NoDataFound
                isShowIcon={true}
                iconSize={24}
                displayString="To add product images please create product."
              />
            </Center>
          </View>
        </PageBodyContainer>
      )}
    </>
  );
};
