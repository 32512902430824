import {gql} from '@apollo/client';

export const GetDashboardByEmployerId = gql`
  query GetDashboardByEmployerId($employerId: uuid!) {
    employerDashboards(
      where: {employerUuid: {_eq: $employerId}}
      order_by: {createdAt: desc}
    ) {
      employer {
        metadata
      }
      employerDashboards {
        page {
          id
          pageType
          name
          pageSections(
            where: {parentSectionId: {_is_null: true}}
            order_by: {sequence: asc}
          ) {
            id
            heading
            sequence
            additionalAttributes
            parentSectionId
            subHeading
            sectionCategory {
              code
            }
            pageId
            isVisible
            sectionCategoryId
            parentSectionId
            resourceId
            type
            childSections(order_by: {sequence: asc}) {
              id
              heading
              additionalAttributes
              sequence
              parentSectionId
              resource {
                code
                allowedGroupBy
                allowedFilters
              }
              sectionCategory {
                code
              }
              appliedFilter
              subHeading
              pageId
              isVisible
              resourceId
              sectionCategoryId
              type
            }
          }
        }
      }
    }
  }
`;

export const GetDefaultPageForFormAnalytics = gql`
  query GetDefaultPageForDashboard($dashboardType: String) {
    pages(
      where: {
        pageTypeData: {code: {_eq: $dashboardType}}
        pageSections: {parentSectionId: {_is_null: false}}
      }
    ) {
      id
      pageType
      name
      pageSections(
        where: {parentSectionId: {_is_null: true}}
        order_by: {sequence: asc}
      ) {
        id
        heading
        sequence
        additionalAttributes
        parentSectionId
        subHeading
        sectionCategory {
          code
        }
        sectionDisplayConfig
        pageId
        isVisible
        sectionCategoryId
        parentSectionId
        resourceId
        type
        childSections(order_by: {sequence: asc}) {
          id
          heading
          sequence
          additionalAttributes
          parentSectionId
          resource {
            code
            allowedGroupBy
            allowedFilters
          }
          sectionDisplayConfig
          sectionCategory {
            code
          }
          subHeading
          pageId
          isVisible
          resourceId
          sectionCategoryId
          type
          childSections(order_by: {sequence: asc}) {             
            id             
            heading             
            sequence             
            additionalAttributes             
            parentSectionId             
            resource {               
                code               
                allowedGroupBy               
                allowedFilters             
            }             
            sectionCategory {               
                code             
            }             
            subHeading             
            pageId             
            isVisible             
            resourceId         
            sectionDisplayConfig    
            sectionCategoryId             
            type           
        }   
        }
      }
    }
  }
`;

export const GetDefaultPageForJourneyAnalytics = gql`
  query GetDefaultPageForDashboard($code: String) {
    pages(
      where: {
        pageTypeData: {code: {_eq: $code}}
        pageSections: {parentSectionId: {_is_null: false}}
      }
    ) {
      id
      pageType
      name
      pageSections(
        where: {parentSectionId: {_is_null: true}}
        order_by: {sequence: asc}
      ) {
        id
        heading
        sequence
        additionalAttributes
        parentSectionId
        subHeading
        sectionCategory {
          code
        }
        sectionDisplayConfig
        pageId
        isVisible
        sectionCategoryId
        parentSectionId
        resourceId
        type
        childSections(order_by: {sequence: asc}) {
          id
          heading
          sequence
          additionalAttributes
          parentSectionId
          resource {
            code
            allowedGroupBy
            allowedFilters
          }
          sectionDisplayConfig
          sectionCategory {
            code
          }
          subHeading
          pageId
          isVisible
          resourceId
          sectionCategoryId
          type
          childSections(order_by: {sequence: asc}) {
            id
            heading
            sequence
            additionalAttributes
            parentSectionId
            resource {
              code
              allowedGroupBy
              allowedFilters
            }
            sectionCategory {
              code
            }
            subHeading
            pageId
            isVisible
            resourceId
            sectionDisplayConfig
            sectionCategoryId
            type
            childSections(order_by: {sequence: asc}) {
              id
              heading
              sequence
              additionalAttributes
              parentSectionId
              resource {
                code
                allowedGroupBy
                allowedFilters
              }
              sectionCategory {
                code
              }
              subHeading
              pageId
              isVisible
              resourceId
              sectionDisplayConfig
              sectionCategoryId
              type
              childSections(order_by: {sequence: asc}) {
                id
                heading
                sequence
                additionalAttributes
                parentSectionId
                resource {
                  code
                  allowedGroupBy
                  allowedFilters
                }
                sectionCategory {
                  code
                }
                subHeading
                pageId
                isVisible
                resourceId
                sectionDisplayConfig
                sectionCategoryId
                type
                childSections(order_by: {sequence: asc}) {
                  id
                  heading
                  sequence
                  additionalAttributes
                  parentSectionId
                  resource {
                    code
                    allowedGroupBy
                    allowedFilters
                  }
                  sectionCategory {
                    code
                  }
                  subHeading
                  pageId
                  isVisible
                  resourceId
                  sectionDisplayConfig
                  sectionCategoryId
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GetDefaultPageForDashboard = gql`
  query GetDefaultPageForDashboard {
    pages(
      where: {
        pageTypeData: {code: {_eq: "EMPLOYER_DEFAULT_DASHBOARD"}}
        pageSections: {parentSectionId: {_is_null: false}}
      }
    ) {
      id
      pageType
      name
      pageSections(
        where: {parentSectionId: {_is_null: true}}
        order_by: {sequence: asc}
      ) {
        id
        heading
        sequence
        additionalAttributes
        parentSectionId
        subHeading
        sectionCategory {
          code
        }
        pageId
        isVisible
        sectionCategoryId
        parentSectionId
        resourceId
        type
        childSections(order_by: {sequence: asc}) {
          id
          heading
          sequence
          additionalAttributes
          parentSectionId
          resource {
            code
            allowedGroupBy
            allowedFilters
          }
          sectionCategory {
            code
          }
          subHeading
          pageId
          isVisible
          resourceId
          sectionCategoryId
          type
        }
      }
    }
  }
`;

export const CreatePageSections = gql`
mutation CreatePageSections($pageSections: [page_sections_insert_input!]!) {
  createPageSections(objects: $pageSections) {
    returning {
      id
    }
  }
}


`
export const CreatePage = gql`
mutation CreatePage($pageType: uuid!, $name: String! ) {
   createPage(object:{pageType: $pageType,name: $name}){
    id
  }
}
`

export const CreateDashboard = gql`
  mutation CreateDashboard($accountUuid: uuid!, $pageId: uuid!, $name: String!, $employerId: uuid!) {
    createDashboard(object: {
      pageId: $pageId,
      accountUuid: $accountUuid,
      employerId: $employerId,
      name:$name
      }) {
      id
      name
    }
  }
`
export const CreateEmployerDashboard = gql`
mutation CreateEmployerDashboard($dashboardId: uuid!, $employerUuid: uuid!,$accountUuid: uuid!) {
  createEmployerDashboard(object: {dashboardId: $dashboardId, employerUuid: $employerUuid,accountUuid: $accountUuid}) {
    dashboardId
    id
    employerUuid
  }
}
`

export const Employee_Aggregate = gql`
  query Employee_Aggregate($employerId: uuid!) {
    employeeAggregate(where: {employerId: {_eq: $employerId}}) {
      aggregate {
        count
      }
    }
  }
`;

export const UPDATE_SECTION_HIDE_SHOW = gql`
mutation UPDATE_SECTION_HIDE_SHOW($id: uuid!, $isVisible: Boolean!) {
  updatePageSections(where: {id: {_eq: $id}}, _set: {isVisible: $isVisible}) {
    returning {
      id
      isVisible
      sequence
    }
  }
}
`
export const UPDATE_SECTION_POSITION = gql`
mutation UPDATE_SECTION_POSITION($id: uuid!, $sequence: Int!) {
  updatePageSections(where: {id: {_eq: $id}}, _set: {sequence: $sequence}) {
    returning {
      id
      heading
      sequence
    }
  }
}
`

export const UPDATE_SECTION_ADDITIONAL = gql`
mutation UPDATE_SECTION_ADDITIONAL($id: uuid!, $additionalAttributes: jsonb!) {
  updatePageSections(where: {id: {_eq: $id}}, _set: {additionalAttributes: $additionalAttributes}) {
    returning {
      id
      additionalAttributes
    }
  }
}
`
export const GetContactByCount = gql`
  query GetContactByCount($limit: Int = 100) {
    contacts(
      limit: $limit
      where: {isDeleted: {_eq: false}}
      order_by: {updatedAt: desc}
    ) {
      uuid
    }
  }
`;

export const GetEmployerEnrollmentDateFromEmployee = gql`
  query GetEmployerEnrollmentDateFromEmployee($employerId: uuid!) {
    contactMemberships(
      where: {
        employeeData: {
          employerId: {
            _eq: $employerId
          }
        }
      },
      order_by: {
        startDate: asc
      },
      limit: 1
    ) {
      employeeData{
        employer{
          name
        }
      }
      startDate
      endDate
    }
  }
`;

export const GetDashboardSectionBySectionCode = gql`
query GetDashboardSectionBySectionCode($employerId: uuid!, $accountUuid: uuid!, $sectionCategoryCode: String!) {
  employerDashboards(
    where: {employerUuid: {_eq: $employerId}, accountUuid: {_eq: $accountUuid}}
    order_by: {createdAt: desc}
  ) {
    employer {
      metadata
    }
    employerDashboards {
      page {
        id
        pageType
        name
        pageSections(
          where: {parentSectionId: {_is_null: true}, sectionCategory: {
            code: {
              _eq: $sectionCategoryCode
            }
          }}
          order_by: {sequence: asc}
        ) {
          id
          heading
          sequence
          additionalAttributes
          parentSectionId
          subHeading
          sectionCategory {
            code
          }
          pageId
          isVisible
          sectionCategoryId
          parentSectionId
          resourceId
          type
          childSections(order_by: {sequence: asc}) {
            id
            heading
            additionalAttributes
            sequence
            parentSectionId
            resource {
              code
              allowedGroupBy
              allowedFilters
            }
            sectionCategory {
              code
            }
            appliedFilter
            subHeading
            pageId
            isVisible
            resourceId
            sectionCategoryId
            type
          }
        }
      }
    }
  }
}
`

export default {
  GetDashboardByEmployerId,
  GetDashboardSectionBySectionCode,
  GetDefaultPageForDashboard,
  Employee_Aggregate,
  CreatePageSections,
  CreatePage,
  CreateDashboard,
  CreateEmployerDashboard,
  UPDATE_SECTION_HIDE_SHOW,
  UPDATE_SECTION_POSITION,
  UPDATE_SECTION_ADDITIONAL,
  GetContactByCount,
  GetEmployerEnrollmentDateFromEmployee,
  GetDefaultPageForFormAnalytics,
  GetDefaultPageForJourneyAnalytics
};
