import React from "react";
import {Colors} from "../../../../styles";
import {ICommonSvgProps} from "../interfaces";

const PrintSvg  = (props: ICommonSvgProps) => {
  const {isSelected, height, width} = props;
  const strokeColor = isSelected
    ?  props.customStrokeColor ?  props.customStrokeColor : Colors.primary['500']
    : props.isPrimary
    ? '#7B7B7B'
    : '#ffffff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: width ? width : 'inherit', height: height ? height : 'inherit'}}
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6 9.224v-7h12v7m-12 9H4a2 2 0 01-2-2v-5a2 2 0 012-2h16a2 2 0 012 2v5a2 2 0 01-2 2h-2m-12-4h12v8H6v-8z"
      ></path>
    </svg>
  );
}

export default PrintSvg;
