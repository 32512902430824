import {  Line, LineConfig } from '@ant-design/plots';
import { graphColorList, graphMetaDataColor } from '../../Analytics/EmployerAnalytics/graphColors';
import NodataViewTypeOne from '../../Analytics/EmployerAnalytics/SectionAndWidget/NoDataView/NodataViewTypeOne';
import { View } from 'native-base';
import { styles } from '../formStyle';

const FormLineChart = (props:{
    data:{type:string,value:number}[],
    xLabel:string,
    yLabel:string,
    height?:number,
    tooltipText?:string
}) => {

    const multipleLineconfigGroup: LineConfig = {
        data:props.data,
        xField: 'month',
        yField: 'value',
        seriesField: 'type',
        height:props?.height||300,
        tooltip: {
            formatter: (data) => {

                return {
                    name: props.tooltipText||'Amount',
                    value: data.value
                }
            },
        },
        yAxis: {
            label: {
                formatter: (text) => {
                    const value: any = text
                    return value
                },
                style: {
                    fontSize: 12,
                    fontWeight: 400,
                    fill: graphMetaDataColor.axisTextColor
                }
            },
            title: {
                text: props.yLabel,
                description: '',
                style: {
                    fill: graphMetaDataColor.axisTextColor
                }
            },
            line: {
                style: {
                    stroke: '#F2F4F7',
                }
            },
            grid: {
                alignTick: true,
                line: {
                    type: 'line',
                    style: {
                        stroke: '#F2F4F7'
                    }
                },
                closed: true
            }
        },
        xAxis: {
            title: {
                text: props.xLabel,
                description: '',
                style: {
                    fill: graphMetaDataColor.axisTextColor
                },
                offset: 60
            },
            line: {
                style: {
                    stroke: '#F2F4F7',
                }
            },
            label: {
                offset: 20,
                style: {
                    fontSize: 12,
                    fontWeight: 400,
                    fill: graphMetaDataColor.axisTextColor
                }
            },
            grid: {
                alignTick: true,
                line: {
                    type: 'line',
                    style: {
                        stroke: '#F2F4F7'
                    }
                },
                closed: true
            }
        },
        color: graphColorList,
        legend: {
            itemName: {
                formatter(text) {
                    return `${text}`
                },
            },
            position: 'top-right',
            layout: 'horizontal',
            marker: {
                symbol: 'circle',
                style(style) {
                    return {
                        ...style,
                        fill: style.stroke,
                        stroke: null,
                        r: 4
                    }
                },
            },
        },
        lineStyle: {
            lineWidth: 1,
        },
        point: {
            shape: ({ type }) => {
                return 'circle';
            },
        },
    };

    if(props.data.find((item)=>item.value!=0)){
        return ( <Line {...multipleLineconfigGroup} />)
    }


    return <View style={styles.nodataView}> <NodataViewTypeOne viewCode="TEXT_ICON" /></View>;


}

export default FormLineChart;