import {useLazyQuery} from '@apollo/client';
import {Badge, HStack, Text, TextArea, View, useToast} from 'native-base';
import React, {useContext, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {
  AUTO_RESPONSE_TYPES,
  BUTTON_TYPE,
  MLOV_CATEGORY,
} from '../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {AVAILABILITY_TYPE_CODES} from '../../../constants/MlovConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {ScheduleQueries} from '../../../services';
import InboxQueries from '../../../services/Inbox/InboxQueries';
import {IInbox} from '../../../services/Inbox/interfaces';
import {Colors} from '../../../styles';
import {getAccountUUID, getUserUUID} from '../../../utils/commonUtils';
import {ToastType, showToast} from '../../../utils/commonViewUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import {CHANNEL_TYPE} from '../TeamInbox/Conversations/ConversationConst';
import {
  addOrUpdateAutoResponse,
  addTimeZonesToAvailabilities,
  getAutoResponsesFromApi,
} from './AutoReplyViewUtils';
import {
  IAutoResponseConfig,
  IPracticeAvailabilityConfig,
  Schedule,
  UserPracticeLocationResponse,
} from './interfaces';
import UserPracticeLocationQueries from '../../../services/Location/UserPracticeLocationQueries';

export interface ISMSAutoResponseViewProps {
  phoneNumber: string;
  assigneeTypeCode: string;
  userUuid: string;
  locationUuid: string;
}

const SMSAutoResponseView = (props: ISMSAutoResponseViewProps) => {
  const {phoneNumber, assigneeTypeCode} = props;
  const [state, setState] = React.useState({
    loading: false,
    isSubmitting: false,
    reply: '',
    responseId: '',
    inboxId: 0,
    availabilities: [] as IPracticeAvailabilityConfig[],
    assigneeId: '',
  });
  const toast = useToast();
  const mlovData = useContext(CommonDataContext);
  const accountUuid = getAccountUUID();
  const userUuid = getUserUUID();
  const intl = useIntl();

  const availabilityTypes =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.AVAILABILITY_TYPE
    ) || [];

  const practiceScheduleAvailabilityTypeId = getMlovIdFromCode(
    availabilityTypes,
    AVAILABILITY_TYPE_CODES.PRACTICE_SCHEDULE
  );

  const [getAvailabilitiesAPI] = useLazyQuery(
    ScheduleQueries.GET_PRACTICE_SCHEDULE
  );
  const [getInboxesDataByPhoneNumber] = useLazyQuery<{inboxes: IInbox[]}>(
    InboxQueries.GetInboxesData,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getTimezonesByUuids] = useLazyQuery(
    ScheduleQueries.GET_TIMEZONES_BY_UUIDS
  );

  const [getAccountLocationUuidByUserUuid] =
    useLazyQuery<UserPracticeLocationResponse>(
      UserPracticeLocationQueries.GetAccountLocationUuidByUserUuid
    );

  const onChangeReply = (value: string) => {
    setState((prev) => {
      return {
        ...prev,
        reply: value,
      };
    });
  };

  const isUserAssigneeTypeCode = () => {
    return assigneeTypeCode === 'USER';
  };

  const getPracticeAvailabilityWhereCondition = (locationIds: string[]) => {
    let whereCondition: any = {};
    whereCondition = {
      isDeleted: {_eq: false},
      typeId: {_eq: practiceScheduleAvailabilityTypeId},
    };
    if (isUserAssigneeTypeCode()) {
      whereCondition._or = [
        {
          locationId: {
            _in: locationIds,
          },
          userId: {
            _is_null: true,
          },
        },
        {
          userId: {
            _eq: props.userUuid,
          },
        },
      ];
    } else {
      whereCondition.userId = {_is_null: true};
      whereCondition.locationId = {
        _eq: props.locationUuid,
      };
    }
    return whereCondition;
  };

  const getAccountLocationUuids = async () => {
    try {
      const response = await getAccountLocationUuidByUserUuid({
        variables: {
          userUuid: props.userUuid,
        },
      });
      const userPracticeLocations = response.data?.userPracticeLocations;
      if (!userPracticeLocations) {
        return [];
      }
      const accountLocationUuids = userPracticeLocations.map(
        (item) => item.accountLocationUuid
      );
      return accountLocationUuids;
    } catch (error) {
      return [];
    }
  };

  const getAvailabilitiesPromise = async () => {
    if (!props.userUuid) {
      return getAvailabilitiesAPI({
        variables: {
          whereCondition: getPracticeAvailabilityWhereCondition([]),
        },
        fetchPolicy: 'no-cache',
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
      });
    }
    const accountLocationUuids = await getAccountLocationUuids();
    return getAvailabilitiesAPI({
      variables: {
        whereCondition:
          getPracticeAvailabilityWhereCondition(accountLocationUuids),
      },
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    });
  };

  const filterAvailabilitiesResponse = (availabilitiesResponse: any) => {
    if (props.locationUuid) {
      return availabilitiesResponse;
    }
    const schedules: Schedule[] = availabilitiesResponse?.data?.schedules || [];
    const userSchedules = schedules.filter(
      (item) => item.userId === props.userUuid
    );
    if (userSchedules.length > 0) {
      availabilitiesResponse.data.schedules = userSchedules;
      return availabilitiesResponse;
    }
    const practiceSchedules = schedules.filter((item) => !item.userId);
    availabilitiesResponse.data.schedules = practiceSchedules;
    return availabilitiesResponse;
  };

  const getInboxId = async () => {
    try {
      const response = await getInboxesDataByPhoneNumber({
        variables: {
          whereCondition: {
            channelType: {
              _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
            },
            channelTwilioSms: {
              phoneNumber: {
                _eq: phoneNumber,
              },
            },
          },
        },
      });
      return response.data?.inboxes?.[0]?.id;
    } catch (error) {

    }
  };

  const getAutoResponseData = async () => {
    const inboxId = await getInboxId();
    if (!inboxId) {
      showToast(toast, 'Inbox not created for selected number', ToastType.info);
      return;
    }
    const autoResponses = await getAutoResponsesFromApi({inboxId: inboxId});
    setState((prev) => {
      return {
        ...prev,
        inboxId: inboxId,
        loading: false,
        reply: autoResponses?.replyText || '',
        responseId: autoResponses?.id || '',
        assigneeId:
          autoResponses?.assigneeUuid ||
          autoResponses?.accountLocationUuid ||
          isUserAssigneeTypeCode()
            ? props.userUuid
            : props.locationUuid || '',
      };
    });
  };

  const getAvailabilitiesByUserOrLocationId = async () => {
    let availabilitiesResponse = await getAvailabilitiesPromise();
    availabilitiesResponse = filterAvailabilitiesResponse(
      availabilitiesResponse
    );
    const timezoneIds = availabilitiesResponse.data?.schedules.map(
      (item: any) => item.timezoneId
    );
    const timezoneResponse = await getTimezonesByUuids({
      variables: {
        uuids: timezoneIds,
      },
    });
    const availabilities = addTimeZonesToAvailabilities({
      timezones: timezoneResponse.data.timezones,
      availabilities: availabilitiesResponse.data?.schedules || [],
    });
    return availabilities;
  };

  const getAutoResponseConfig = (): IAutoResponseConfig => {
    return {
      id: state.responseId,
      inboxId: state.inboxId,
      accountId: accountUuid,
      accountLocationUuid: isUserAssigneeTypeCode()
        ? props.userUuid
        : props.locationUuid,
      assigneeUuid: isUserAssigneeTypeCode()
        ? props.userUuid
        : props.locationUuid,
      assigneeType: isUserAssigneeTypeCode() ? 'USER' : 'LOCATION',
      replyText: state.reply,
      replyType: AUTO_RESPONSE_TYPES.SMS,
      userId: userUuid,
      practiceAvailabilityConfig: state.availabilities,
    };
  };

  const errorHandler = (error: any) => {

    showToast(
      toast,
      'Error in saving responses. Please try again later',
      ToastType.success
    );
    setState((prev) => {
      return {
        ...prev,
        isSubmitting: false,
      };
    });
  };

  const onSubmit = async () => {
    setState((prev) => {
      return {
        ...prev,
        isSubmitting: true,
      };
    });
    try {
      const body = getAutoResponseConfig();
      const availabilities = await getAvailabilitiesByUserOrLocationId();
      body.practiceAvailabilityConfig = availabilities;
      const response = await addOrUpdateAutoResponse({body});
      const data = response.data;
      if (!data?.id) {
        errorHandler({});
        return;
      }
      showToast(toast, 'Responses saved successfully', ToastType.success);
      setState((prev) => {
        return {
          ...prev,
          isSubmitting: false,
          responseId: data.id,
        };
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getAutoResponseData();
  }, []);

  return (
    <View
      style={{
        backgroundColor: Colors.Custom.BackgroundColor,
        padding: 24,
        borderRadius: 16,
        borderColor: Colors.Custom.Gray200,
        borderWidth: 1,
      }}
    >
      <HStack justifyContent={'space-between'}>
        <HStack flex={1} space={1}>
          <Badge
            rounded="full"
            backgroundColor={Colors.primary[300]}
            alignSelf="center"
            variant="solid"
          >
            6
          </Badge>
          <DisplayText
            textLocalId="chooseSMSAutoReply"
            size={'mdMedium'}
            extraStyles={{
              color: Colors.primary[300],
            }}
          />
        </HStack>
      </HStack>
      <View style={{marginVertical: 8}}>
        <Text size={'smMedium'} color={'gray.400'}>
          Auto replies get triggered in OOO and it respects to the selected
          user/locations business hours
        </Text>
      </View>

      <View style={{marginVertical: 12}}>
        <TextArea
          placeholder="Auto Response"
          value={state.reply}
          onChangeText={(value) => {
            onChangeReply(value);
          }}
        />
      </View>

      <View>
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            isLoading: state.isSubmitting,
            onPress: () => {
              onSubmit();
            },
            width: 'fit-content',
            style: {marginLeft: 12},
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'Save',
            }),
            withRightBorder: false,
          }}
        ></FoldButton>
      </View>
    </View>
  );
};

export default SMSAutoResponseView;
