import { View, StyleSheet } from 'react-native'
import React, { useContext, useEffect, useState } from 'react'
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { HStack, VStack, useMediaQuery, Text, Image, Spinner } from 'native-base';
import { BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH, MLOV_CATEGORY } from '../../../../constants';
import { BulkViewCode, ITaskConflict, MultiSelectAction } from '../../TaskInterfaces';
import { Colors } from '../../../../styles';
import { Checkbox, Drawer, Progress, notification } from 'antd';
import { ORDERED_TASK_PRIORITY_CODES_ASC, ORDERED_TASK_STATUS_CODES_DESC, SQS_MESSAGE_STATUS_CODES } from '../../../../constants/MlovConst';
import { ModalActionTitle } from '../../../common/ModalActionTitle/ModalActionTitle';
import WarningSvg from '../../../common/Svg/WarningSvg';
import { BulkTaskOperationState } from '../../TaskModuleHelper';
import BulkTaskCardListView from './BulkTaskCardListView';
import Stack from '../../../common/LayoutComponents/Stack';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { TaskQueries } from '../../../../services';
import { TASK_EVENTS } from '../../../common/CareDashboard/CareDashboardConstants';
import { EventBus } from '../../../../utils/EventBus';
import BulkTaskOperationAutomationList from './BulkTaskOperationAutomationList';

interface IBulkTaskDeleteViewProps {
  selectedTasks: ITask[];
  isVisible: boolean;
  onCancel?: () => void;
  onTaskRemoved?: (task: ITask) => void;
}

const BulkTaskDeleteView = (props: IBulkTaskDeleteViewProps) => {
  // Constants and variables
  const {
    selectedTasks,
    isVisible,
    onCancel,
    onTaskRemoved,
  } = props;
  const eventBus = EventBus.getEventBusInstance();
  const mlovData = useContext(CommonDataContext);
  const isSidecarContext = mlovData?.sidecarContext?.isSidecar;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);
  let taskPriorityMlov = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_PRIORITY,
    false
  );
  taskPriorityMlov = taskPriorityMlov?.sort((currentMlov, nextMlov) => {
    const currentMlovScore = ORDERED_TASK_PRIORITY_CODES_ASC.indexOf(
      currentMlov.code
    );
    const nextMlovScore = ORDERED_TASK_PRIORITY_CODES_ASC.indexOf(
      nextMlov.code
    );
    return currentMlovScore - nextMlovScore;
  });
  let taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];

  taskStatusMlov = taskStatusMlov
  ?.filter(item => ORDERED_TASK_STATUS_CODES_DESC.includes(item.code))
  ?.sort((currentMlov, nextMlov) => {
    const currentMlovScore = ORDERED_TASK_STATUS_CODES_DESC.indexOf(
      currentMlov.code
    );
    const nextMlovScore = ORDERED_TASK_STATUS_CODES_DESC.indexOf(
      nextMlov.code
    );
    return currentMlovScore - nextMlovScore;
  });
  const sqsStatusList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.SQS_MESSAGE_STATUS,
    false
  );
  const SQS_STATUS_IDS = {
    processed: getMlovIdFromCode(sqsStatusList || [], SQS_MESSAGE_STATUS_CODES.PROCESSED),
    failed: getMlovIdFromCode(sqsStatusList || [], SQS_MESSAGE_STATUS_CODES.FAILED),
  }

  // States
  const [componentState, setComponentState] = useState<{
    bulkViewCode: BulkViewCode,
    validationLoading: boolean;
    taskConflicts: ITaskConflict[];
    taskWithoutConflicts: ITaskConflict[];
    bulkTaskOperationState: BulkTaskOperationState;
    transactionId?: string;
    processedCount?: number;
    allowToTriggerNotification: boolean,
    allowToSendCompletionNotification: boolean,
  }>({
    bulkViewCode: BulkViewCode.preview,
    validationLoading: false,
    taskConflicts: [],
    taskWithoutConflicts: [],
    bulkTaskOperationState: BulkTaskOperationState.none,
    allowToTriggerNotification: true,
    allowToSendCompletionNotification: false,
  });

  // APIs
  const [validateBulkTasks] = useLazyQuery(TaskQueries.VALIDATE_BULK_TASKS, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const [applyBulkTaskChanges] = useMutation(TaskQueries.PERFORM_BULK_TASK_ACTION, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const [bulkOperationStatus] = useLazyQuery(TaskQueries.BULK_OPERATION_STATUS, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  // Life cycle methods
  useEffect(() => {
    if (componentState.transactionId) {
      setComponentState((prev) => ({...prev, processedCount: 0 }));
      getBulkOperationStatus();
    }
  }, [componentState.transactionId]);

  useEffect(() => {
    if (isVisible) {
      checkTaskValidation(selectedTasks);
    }
  }, [isVisible])


  // Other methods
  const getBulkOperationStatus = async () => {
    try {
      const total = componentState.taskWithoutConflicts.length;
      if (componentState.bulkTaskOperationState === BulkTaskOperationState.inProgress) {
        const response = await bulkOperationStatus({
          variables: {
            transactionId: componentState.transactionId,
            processedStatusIds: [SQS_STATUS_IDS.processed, SQS_STATUS_IDS.failed]
          },
        });
        const count = response.data.processed?.aggregate?.count ?? -1;
        if (count > -1 && count <= total) {
          setComponentState((prev) => ({...prev, processedCount: count }));
          if (count < total) {
            setTimeout(getBulkOperationStatus, 1000);
          }
        } else {
          setComponentState((prev) => ({...prev, bulkTaskOperationState: BulkTaskOperationState.none }));
        }
      } else {
        setComponentState((prev) => ({...prev, bulkTaskOperationState: BulkTaskOperationState.none }));
      }
    } catch {
      setComponentState((prev) => ({...prev, bulkTaskOperationState: BulkTaskOperationState.none }));
    }
  }

  const renderTaskErrors = () => {
    if (componentState.validationLoading && !componentState.taskConflicts.length) {
      return <Spinner />;
    }
    if (!componentState.taskConflicts.length && !componentState.taskWithoutConflicts.length) {
      return <></>;
    }

    return (
      <Stack direction="column" space={24}>
        <Checkbox
          checked={componentState.allowToSendCompletionNotification}
          onChange={(event) => {
            setComponentState((prev) => ({...prev, allowToSendCompletionNotification: event.target.checked}));
          }}>
          <Text style={styles.checkboxText}>{'Notify me when bulk update is completed'}</Text>
        </Checkbox>
        {componentState.taskConflicts.length > 0 && (
          <VStack space={2} borderRadius={8}>
            <HStack space={2} flex={1} marginTop={4} marginBottom={-2} alignItems={'center'}>
              <HStack alignItems={'center'}>
                <Text style={styles.conflictHeader}>{'TASKS  WITH  CHANGE  CONFLICT'}</Text>
                <Text style={styles.conflictCount}>
                  {componentState.taskConflicts.length}
                </Text>
              </HStack>
              {componentState.validationLoading && <View style={styles.spinnerContainer}><Spinner /></View>}
            </HStack>
            <View style={styles.taskListContainer}>
              <BulkTaskCardListView
                taskList={componentState.taskConflicts}
                showConflictWithoutAction
                onRemove={(task) => {
                  setComponentState((prev) => {
                    return {
                      ...prev,
                      taskConflicts: prev.taskConflicts.filter(item => item.task.id !== task.id)
                    };
                  });
                  onTaskRemoved?.(task);
                }}
              />
            </View>
          </VStack>
        )}
        {componentState.taskWithoutConflicts.length > 0 && (
          <VStack space={2}>
            <HStack alignItems={'center'}>
              <Text style={styles.tasksToDeleteHeader}>FOLLOWING  TASKS  WILL  BE  DELETED</Text>
              <Text style={styles.tasksToDeleteCount}>
                {componentState.taskWithoutConflicts.length}
              </Text>
            </HStack>
            <BulkTaskCardListView
              taskList={componentState.taskWithoutConflicts}
              onRemove={(task) => {
                setComponentState((prev) => {
                  return {
                    ...prev,
                    taskWithoutConflicts: prev.taskWithoutConflicts.filter(item => item.task.id !== task.id)
                  };
                });
                const filteredTasks = selectedTasks.filter(item => item.id !== task.id);
                checkTaskValidation(filteredTasks);
                onTaskRemoved?.(task);
              }} />
          </VStack>
        )}
        <View>
          <BulkTaskOperationAutomationList
            allowToTriggerNotification={componentState.allowToTriggerNotification}
            onAllowToTriggerNotificationChange={(value) => {
              setComponentState((prev) => ({ ...prev, allowToTriggerNotification: value }));
            }}
          />
        </View>
      </Stack>
    );
  }

  const getTitle = () => {
    switch (componentState.bulkViewCode) {
      case BulkViewCode.preview: return 'Bulk Delete Preview';
      case BulkViewCode.bulkInProgress: return 'Confirmation';
    }
  }

  const renderBulkInProgressView = () => {
    const total = componentState.taskWithoutConflicts.length;
    const processed = componentState.processedCount || 0;
    const progress = total ? Math.round(processed * 100 / total) : 0;
    return (
      <VStack style={styles.bulkInProgressContainer} flex={1}>
        <View style={styles.imageContainer}>
          <Image
            size={160}
            source={progress === 100 ? require('../../../../assets/gifs/success.gif') : require('../../../../assets/gifs/task_bulk.gif')}
            alt='image'
            key={Math.random()}
          />
        </View>
        <VStack space={3} style={styles.progressContainer} flex={1}>
          <VStack space={1}>
            <Text style={styles.applyingChangesText}>{'Applying bulk changes to the selected tasks'}</Text>
            <Text style={styles.selectedTasksText}>{`${total} selected tasks`}</Text>
          </VStack>
          <Progress percent={progress} />
        </VStack>
      </VStack>
    );
  }

  const getBulkTaskAPIParams = (taskIds: string[]) => {
    return {
      operationCode: 'DELETE',
      taskIds: taskIds,
      allowToTriggerNotification: componentState.allowToTriggerNotification,
      allowToSendCompletionNotification: componentState.allowToSendCompletionNotification,
    }
  }

  const checkTaskValidation = async (taskList: ITask[]) => {
    try {
      const selectedTaskIds = taskList.map((item) => item.id);
      if (!selectedTaskIds.length) {
        return;
      }
      setComponentState((prev) => ({...prev, validationLoading: true}));
      const response = await validateBulkTasks({
        variables: {
          data: getBulkTaskAPIParams(selectedTaskIds),
        },
        fetchPolicy: 'no-cache',
      });
      if (response.data?.bulkTaskInputValidation?.tasks?.length) {
        const tasks: {id: string, conflicts: {conflictCodes: string[]; key: string;}[]}[] = response.data?.bulkTaskInputValidation?.tasks || [];
        const taskConflicts: ITaskConflict[] = [];
        const taskWithoutConflicts: ITaskConflict[] = [];
        tasks.forEach((item) => {
          const taskDetail = taskList.find(task => task.id === item.id);
          if (taskDetail) {
            if (item.conflicts.length) {
              taskConflicts.push({ task: taskDetail, conflicts: item.conflicts });
            } else {
              taskWithoutConflicts.push({ task: taskDetail, conflicts: [] });
            }
          }
        })
        setComponentState((prev) => ({
          ...prev,
          validationLoading: false,
          taskConflicts,
          taskWithoutConflicts,
        }));
      } else {
        setComponentState((prev) => ({...prev, validationLoading: false}));
      }
    } catch {
      notification.error({
        message: 'Something went wrong while validating tasks',
        duration: 3.0,
        placement: 'top',
      });
      setComponentState((prev) => ({...prev, validationLoading: false}));
    }
  }

  const applyChanges = async () => {
    try {
      const selectedTaskIds = componentState.taskWithoutConflicts.map((item) => item.task.id);
      if (!selectedTaskIds.length) {
        return;
      }
      setComponentState((prev) => ({...prev,
        bulkTaskOperationState: BulkTaskOperationState.inProgress,
        bulkViewCode: BulkViewCode.bulkInProgress,
        transactionId: undefined,
      }));
      const response = await applyBulkTaskChanges({
        variables: {
          data: getBulkTaskAPIParams(selectedTaskIds),
        },
        fetchPolicy: 'no-cache',
      });
      const transactionId = response.data?.bulkTaskUpdate?.processId;
      if (transactionId) {
        setComponentState((prev) => ({
          ...prev,
          transactionId,
        }));
      } else {
        setComponentState((prev) => ({
          ...prev,
          bulkTaskOperationState: BulkTaskOperationState.error
        }));
      }
    } catch {
      notification.error({
        message: 'Something went wrong while validating tasks',
        duration: 3.0,
        placement: 'top',
      });
      setComponentState((prev) => ({
        ...prev,
        bulkTaskOperationState: BulkTaskOperationState.error
      }));
    }
  }

  const closeDrawer = () => {
    if (componentState.bulkViewCode === BulkViewCode.bulkInProgress) {
      eventBus.broadcastEvent(TASK_EVENTS.REFRESH_TASKS, {});
    }
    setComponentState((prev) => ({ ...prev,
      bulkViewCode: BulkViewCode.preview,
      transactionId: undefined,
      taskConflicts: [],
      taskWithoutConflicts: [],
      validationLoading: false,
      bulkTaskOperationState: BulkTaskOperationState.none,
      allowToTriggerNotification: true,
      allowToSendCompletionNotification: false,
      processedCount: 0,
    }));
    onCancel?.();
  }

  return (
    <Drawer
      destroyOnClose
      placement="right"
      open={isVisible}
      closable
      width={isSidecarContext ? '100%' : (isIPadScreen || isIPadMiniScreen ? '70%' : '50%')}
      mask={isSidecarContext ? false : true}
      title={
        <ModalActionTitle
          title={getTitle()}
          titleColor={''}
          buttonList={[
            {
              show: componentState.bulkViewCode === BulkViewCode.preview,
              id: 7,
              btnText: isSidecarContext ? 'back' : 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: closeDrawer,
            },
            {
              show: componentState.bulkViewCode === BulkViewCode.preview,
              id: 8,
              btnText: 'Confirm Delete',
              size: 'sm',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              isDisabled: !componentState.taskWithoutConflicts.length,
              onClick: applyChanges,
            },
            {
              show: componentState.bulkViewCode === BulkViewCode.bulkInProgress,
              id: 9,
              btnText: 'close',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                eventBus.broadcastEvent(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, { action: MultiSelectAction.disableMultiSelect });
                closeDrawer();
              },
            },
          ]}
        />
      }
    >
      <VStack>
        {componentState.bulkViewCode === BulkViewCode.preview && (
          <VStack space={6}>
            {renderTaskErrors()}
          </VStack>
        )}
        {componentState.bulkViewCode === BulkViewCode.bulkInProgress && renderBulkInProgressView()}
      </VStack>
    </Drawer>
  );
}

const styles = StyleSheet.create({
  checkboxText: {
    color: Colors.Custom.Gray600,
    fontSize: 14,
  },
  conflictHeader: {
    color: Colors.Custom.Gray500,
    fontWeight: '600',
    fontSize: 14,
  },
  conflictCount: {
    backgroundColor: '#F47A3E',
    paddingHorizontal: 8,
    paddingVertical: 2,
    color: Colors.Custom.White,
    marginLeft: 12,
    borderRadius: 4,
    fontSize: 12,
  },
  spinnerContainer: {
    alignItems: 'flex-end',
  },
  taskListContainer: {
    marginTop: 8,
  },
  tasksToDeleteHeader: {
    color: Colors.Custom.Gray500,
    fontWeight: '600',
    fontSize: 14,
  },
  tasksToDeleteCount: {
    backgroundColor: '#F47A3E',
    paddingHorizontal: 8,
    paddingVertical: 2,
    color: Colors.Custom.White,
    marginLeft: 12,
    borderRadius: 4,
    fontSize: 12,
  },
  bulkInProgressContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageContainer: {
    marginVertical: 40,
  },
  progressContainer: {
    width: '100%',
    borderRadius: 8,
    borderColor: Colors.Custom.Gray300,
    borderWidth: 0.5,
    padding: 4,
  },
  applyingChangesText: {
    color: Colors.Custom.Gray700,
    fontWeight: '600',
    fontSize: 14,
    flex: 1,
  },
  selectedTasksText: {
    color: Colors.Custom.Gray500,
    fontWeight: '500',
    fontSize: 12,
    flex: 1,
  },
});

export default BulkTaskDeleteView
