import BaseService from "../../../../../services/CommonService/BaseService";
import {IMessageReadInfoMainResp} from "./interface";

export const readMessageEntriesInMessageLastSeen = async (conversationUuid: string, abortSignal: AbortSignal) => {
  const baseService = BaseService.getSharedInstance().axios;
  const readMessagesUrl = `/crm-nest/account/conversation/${conversationUuid}/message/read`;

  const readInfoResp = await baseService.post(readMessagesUrl, {}, {
    signal: abortSignal
  })
    .catch((err: any) => {

    });
  if (readInfoResp?.data) {
    return readInfoResp.data;
  }
  return {};
}

export const unReadMessageEntriesInMessageLastSeen = async (conversationUuid: string, messageUuid: string, abortSignal?: AbortSignal) => {
  const baseService = BaseService.getSharedInstance().axios;
  const readMessagesUrl = `/crm-nest/account/conversation/${conversationUuid}/message/un-read/${messageUuid}`;

  const readInfoResp = await baseService.post(readMessagesUrl, {
    signal: abortSignal
  })
    .catch((err: any) => {

    });
  if (readInfoResp?.data) {
    return readInfoResp.data;
  }
  return {};
}

export const getCommunicationLog = async (startDate: string, endDate: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  const communicationLogUrl = `/crm-nest/communication-log/aggregateLogs?startDate=${startDate}&endDate=${endDate}`;

  try {
    const response = await baseService.get(communicationLogUrl, {});
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching communication logs:', error);
  }

  return {};
};


export const getReadMessageEntriesInMessageLastSeen = async (conversationUuid: string, messageUuid: string, abortSignal: AbortSignal): Promise<IMessageReadInfoMainResp> => {
  const baseService = BaseService.getSharedInstance().axios;
  const readMessagesUrl = `/crm-nest/account/conversation/${conversationUuid}/message/read/${messageUuid}`;

  const readInfoResp = await baseService.get(readMessagesUrl, {
    signal: abortSignal
  })
    .catch((err: any) => {

    });
  if (readInfoResp?.data) {
    return readInfoResp.data;
  }
  return {} as any;
}
