import React from 'react';
import { Defs, G, Path, Svg, Rect } from 'react-native-svg';

function UserGroupIcon() {
  return (
    <Svg width="12" height="12" viewBox="0 0 12 12" fill="none">
      <G clip-path="url(#clip0_1576_7524)">
        <Path d="M8.0189 1.5C8.8381 1.5 9.51188 2.19 9.51188 3.04C9.51188 3.89 8.84295 4.575 8.0189 4.575M10.3165 8.87C11 8.56 11 8.04 11 7.455C11 6.595 9.90936 5.875 8.48425 5.745M6.81677 3C6.81677 4.105 5.9491 5 4.87785 5C3.80659 5 2.93892 4.105 2.93892 3C2.93892 1.895 3.80659 1 4.87785 1C5.9491 1 6.81677 1.895 6.81677 3ZM8.7557 8.75C8.7557 9.995 8.7557 11 4.87785 11C1 11 1 9.995 1 8.75C1 7.505 2.73534 6.5 4.87785 6.5C7.02036 6.5 8.7557 7.505 8.7557 8.75Z" stroke="#A7AFBE" stroke-linecap="round" stroke-linejoin="round" />
      </G>
      <Defs>
        <clipPath id="clip0_1576_7524">
          <Rect width="12" height="12" fill="white" />
        </clipPath>
      </Defs>
    </Svg>

  );
}

export default UserGroupIcon;