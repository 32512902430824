import {Path, Svg} from 'react-native-svg';

export const CancelAndDeclineIcon = (props:{
  color?: string;
}) => {
  return (
    <Svg width="13" height="15" viewBox="0 0 13 15" fill="none">
      <Path
        d="M8.86111 1V3.6M3.83889 1V3.6M0.699997 5.55H12M4.78055 8.15L6.38239 9.775M6.38239 9.775L7.91944 11.3343M6.38239 9.775L7.91944 8.21572M6.38239 9.775L4.78055 11.4M1.95555 2.3H10.7444C11.4379 2.3 12 2.88203 12 3.6V12.7C12 13.418 11.4379 14 10.7444 14H1.95555C1.26213 14 0.699997 13.418 0.699997 12.7V3.6C0.699997 2.88203 1.26213 2.3 1.95555 2.3Z"
        stroke={props.color || "#9E77ED"}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};
