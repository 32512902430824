import { useLazyQuery, useQuery } from '@apollo/client';
import { VStack } from 'native-base';
import { useEffect, useMemo, useState } from 'react';
import { MLOV_CATEGORY } from '../../../constants';
import { LeadQueries } from '../../../services';
import WorkflowExecutionLogQueries from '../../../services/WorkflowExecutionLog/WorkflowExecutionLogQueries';
import { getAccountId } from '../../../utils/commonUtils';
import { getMlovListByCategoryCode } from '../../../utils/mlovUtils';
import { AnalyticsWidget } from '../../RightSideContainer/Analytics/AnalyticsWidget';
import { GraphMemberList } from './GraphMemberList';
import { GraphConfig } from './GraphUtils';

const GenderGroupsChart = (props: {employerId?: string}) => {
  const tenantId = getAccountId();
  const [genderGroupCountList, setGenderGroupCountList] = useState([]);
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 1;
  const [contactsState, setContactsState] = useState({
    isLoading: false,
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
    isOpen: false,
    genderCode: '',
    openContactListDrawer: false,
    contactUuidList: [] as string[],
  });


  const mlovListDefault: any = getMlovListByCategoryCode(
    MLOV_CATEGORY.PERSON_GENDER
  ) || [];
  const noneGender = {value: 'None', code: 'none'};
  const mlovList = mlovListDefault ? [...mlovListDefault] : [];
  mlovList.push(noneGender);
  const codeList = mlovList?.map((item: any) => {
    return item.code;
  });
  const variables: any = {
    tenantId: tenantId,
  };
  let queryParams = 'query GetGenderGroupCount(';
  const getqueryBodyTemplate = (genderKey: string, code: string) => {
    if (code == noneGender.code) {
      return `
      contactAggregate(
        distinct_on:[id],where: {_not:{person:{gender:{code:{_in:$genderList}}}},accountId: {_eq: $tenantId}, isDeleted: {_eq: false}, ${
          props.employerId
            ? `employees:{isDeleted:{_eq:false},employerId:{_eq:"${props.employerId}"}}`
            : ''
        }}) {
        aggregate {
          count
        }
      }
      `;
    }
    return `
      contactAggregate(distinct_on:[id], where: {accountId:{_eq:$tenantId},person:{gender:{code:{_eq:${genderKey}}}},isDeleted:{_eq:false},${
      props.employerId
        ? `employees:{isDeleted:{_eq:false},employerId:{_eq:"${props.employerId}"}}`
        : ''
    }}) {
      aggregate {
        count
      }
    }
  `;
  };
  let queryBody = '';

  const genderList: any = [];
  codeList.forEach((code: string, index: number) => {
    queryParams = queryParams + '$gender_' + code + ':String,';
    queryBody =
      queryBody +
      '\n' +
      code +
      ':' +
      getqueryBodyTemplate('$gender_' + code, code);
    variables['gender_' + code] = code;
    genderList.push(code);
  });
  queryParams = queryParams + '$tenantId: Int, $genderList:[String]) {';
  const finalQuery = queryParams + queryBody + '}';
  variables.genderList = genderList;
  useQuery(WorkflowExecutionLogQueries.getDynamicQuery(finalQuery), {
    variables: variables,
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    onCompleted: (response) => {
      const genderGroupCountList: any = [];
      mlovList.forEach((item: any) => {
        if (response[item.code]?.aggregate?.count > 0) {
          genderGroupCountList.push({
            value: response[item.code]?.aggregate?.count,
            type: item.value,
            code: item.code,
          });
        }
      });
      setGenderGroupCountList(genderGroupCountList);
    },
  });

  const [getContacts] = useLazyQuery<{contactPersons: any[]}, any>(
    LeadQueries.GetContactsByGenderCode,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if(contactsState.isOpen){
      getContactsList();
    }
  }, [contactsState.page, contactsState.pageSize, contactsState.isOpen]);

  const getContactsList = () => {
    setContactsState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });

    const boolExp: any = {accountId: {_eq: tenantId}};
    if (contactsState.genderCode != noneGender.code) {
      boolExp.person = {gender: {code: {_eq: contactsState.genderCode}}};
    } else {
      boolExp._not = {
        person: {gender: {code: {_in: codeList}}},
      };
    }
    if (props.employerId) {
      boolExp.employees = {
        employerId: {
          _eq: props.employerId,
        },
      };
    }

    getContacts({
      variables: {
        offset: (contactsState.page-1) * contactsState.pageSize,
        limit: contactsState.pageSize,
        boolExp: boolExp,
      },
    })
      .then((contactRespData: any) => {
        setContactsState((prev) => {

          const contactUuidList:any =  [];
          contactRespData?.data?.contacts.forEach((contact: any) => {
            if (contact?.uuid) {
              contactUuidList.push(contact?.uuid);
            }
          });

          return {
            ...prev,
            contactUuidList,
            isLoading: false,
            openContactListDrawer: contactUuidList.length > 0,
            //total: total,
          };
        });
      })
      .catch(() => {
        setContactsState((prev) => {
          return {
            ...prev,
            isLoading: false,
          };
        });
      });
  };

  const config = {
    data: genderGroupCountList,
    angleField: 'value',
    colorField: 'type',
    ...GraphConfig,
    onReady: (plot: any) => {
      plot.on('element:click', (args: any) => {

        if (args?.data?.data?.type) {

          mlovList.map((item: any) => {
            if (item.code === args?.data?.data?.code) {
              setContactsState((prev) => {
                return {
                  ...prev,
                  genderCode: item.code,
                  isOpen: true,
                  pageSize: DEFAULT_PAGE_SIZE, page: DEFAULT_PAGE,
                  total: args?.data?.data?.value,
                };
              });
            }
          });
        }
      });
    },
  };

  const getPieChart = useMemo(() => {
    return (
      <AnalyticsWidget
        pieConfig={{
          ...config,
        }}
        height="358px"
        legend={{
          position: 'bottom',
          layout: 'horizontal',
          maxRow: 3,
          label: {style: {fill: '#ffffff', stroke: '#ffffff'}},
          title: {
            title: undefined,
            spacing: 10,
            style: {fontSize: 0, lineWidth: 0, lineHeight: 0},
          },
        }}
        title="Gender Groups"
        subtitle="Gender Groups of Active and Inactive members."
      />
    );
  }, [genderGroupCountList]);

  return (
    <VStack style={{flex: 1}}>
      {getPieChart}

      {contactsState.openContactListDrawer && (
        <GraphMemberList
          title={'Members By Gender'}
          total={contactsState.total}
          pageSize={contactsState.pageSize}
          page={contactsState.page}
          isOpen={contactsState.openContactListDrawer}
          contactUuidList={contactsState.contactUuidList}
          onPaginationChange={(page:number, pageSize:number)=>{

            setContactsState((prev) => {
              return {
                ...prev,
                page,
                pageSize
              }
            });
          }}
          onCompleteAction={() => {
            setContactsState((prev) => {
              return {
                ...prev,
                contactList: [],
                page: DEFAULT_PAGE,
                isOpen:false,
                pageSize: DEFAULT_PAGE_SIZE,
                selectedSegmentType: '',
                openContactListDrawer: false,
                contactUuidList: [],
              };
            });
          }}
        />
      )}
    </VStack>
  );
};

export default GenderGroupsChart;
