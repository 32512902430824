import {IKeyOperation} from '../../../../../../../Interfaces';
import {IAddOrUpdateConditionParams, ICodeableParam, ICondition, IConditionComponentValue} from '../interfaces';
import { ConditionFields } from '../../CustomComponentUtils';
import { ComponentType } from '../../Diagnosis/interfaces';
import { IFormatHieMetaData, IHieDisplay, IHieInvalidField } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import { getEHRCapability } from '../../../../../../../utils/commonUtils';
import { CapabilityResource } from '../../CustomWrapper/CustomComponentHelper';
import { Condition } from 'fhir/r4';
import { getDateStrFromFormat, getMomentObj } from '../../../../../../../utils/DateUtils';
import { getFhirCodeBySystem, getTextFromCoding, getValidCoding } from '../../../../../Integration/FhirUtils';
import { HieInvalidFields } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';
import { PAMISearchType } from '../../../../../../common/PAMISearch/PAMISearch';
import { FHIR_RESOURCE } from '../../../../../../../constants/FhirConstant';
import { InputType } from '../../../../../../../utils/capabilityUtils';

const extension_url_type = 'type'
export const getRecordListFormatted = (list: any[],fields?:IKeyOperation) => {
  const entries = [];
  for (const el of list || []) {
    entries.push(el.resource);
  }
  const formattedData = getRecordDataFormatted(entries, fields);
  return sortItems(formattedData);
}

export const getRecordDataFormatted = (listData: any[],fields?:IKeyOperation): ICondition[] => {
  const tempFormattedList: ICondition[] = [];
  listData.map((item: any) => {
    if (item?.code) {
      const status = item?.clinicalStatus?.coding?.length
        ? item.clinicalStatus?.coding[0]
        : {code: item.clinicalStatus?.text};
      let note = '';
      if (item?.note?.length) {
        note = item.note[0].text || '';
      }
      const conditionType = item?.extension?.length && fields?.type?.extensionUrl ? item.extension.find((ext: {url:string,valueString:string}) => ext.url == fields?.type?.extensionUrl)?.valueString : null;
      const type = item?.extension?.length ? item.extension.filter((ext: {url:string,valueString:string}) => ext.url.includes(extension_url_type))?.[0]?.valueString : null;
      const tempObj: ICondition = {
        id: item?.id,
        onSetDateTime: item?.onsetDateTime,
        condition: item.code,
        name: getConditionName(item.code),
        clinicalStatus: status,
        note,
        uniqueId: item?.id,
        isFreeTextRecord: !item.code?.coding?.length,
        type,
        showNote: note.length > 0 || fields?.note?.isRequired,
        conditionType: {code: conditionType},
        fieldType: item?.category?.[0]?.code || ComponentType.Condition, // if no category then set it to Condition for backward compatibility
        extension: item.extension,
        meta: item?.meta
      };

      tempFormattedList.push(tempObj);
    }
  });
  return tempFormattedList;
};

export const getSampleData = (type?:ComponentType): IConditionComponentValue => {
  return {
    conditions: [
      {
        id: '34234324',
        onSetDateTime: '2020-12-24',
        condition: {
          text: `Sample ${
            type === ComponentType.Condition ? 'Problem' : 'Diagnosis'
          }`,
          coding: [],
        },
        name: `Sample ${
          type === ComponentType.Condition ? 'Problem' : 'Diagnosis'
        }`,
        clinicalStatus: {code: 'active', display: 'Active'},
        note: 'Test notes',
        uniqueId: '1234',
        isFreeTextRecord: false,
      },
    ],
  };
};

export const sortItems = (records: ICondition[]) => {
  return records.sort((a: ICondition, b: ICondition) => {
    const statusA = a.clinicalStatus?.code || '';
    const statusB = b.clinicalStatus?.code || '';
    if (statusA !== statusB) {
      return statusA.toLowerCase().localeCompare(statusB.toLowerCase());
    }
    return (
      new Date(
        b.recordedDate || b.onSetDateTime || new Date()
      ).getTime() -
      new Date(
        a.recordedDate || a.onSetDateTime || new Date()
      ).getTime()
    );
  })
};

export const getConditionName = (item: any) => {
  let name = item?.text;
  if (!name && item?.coding?.length) {
    name = item.coding[0].display;
  }
  return name;
};

export const isInvalid = (
  field: ConditionFields,
  conditionData: ICondition,
  submitTriggered: boolean,
  fields?: IKeyOperation
) => {
  if (field !== ConditionFields.code && conditionData.isFreeTextRecord && fields?.code?.hideFieldsForFreeText) {
    return false;
  }
  switch (field) {
    case ConditionFields.onSetDateTime:
      if ((fields?.onsetDateTime?.isRequired || false) && submitTriggered) {
        return !conditionData.onSetDateTime;
      }
      return false;

    case ConditionFields.clinicalStatus:
      if ((fields?.clinicalStatus?.isRequired || false) && submitTriggered) {
        return (
          !conditionData.clinicalStatus || !conditionData.clinicalStatus.code
        );
      }
      return false;

    case ConditionFields.note:
      if ((fields?.note?.isRequired || false) && submitTriggered) {
        return !conditionData.note;
      }
      return false;

    case ConditionFields.conditionType:
      if ((fields?.type?.isRequired || false) && submitTriggered) {
        return !conditionData.conditionType || !conditionData.conditionType.code;
      }
      return false;

    default:
      return false;
  }
};

export const getFHIRTemplate = (params: IAddOrUpdateConditionParams) => {
  return {
    resourceType: 'Condition',
    ...(params.id && {id: params.id}),
    clinicalStatus: {
      coding: [
        {
          code: params.clinicalStatus?.code,
          display: params.clinicalStatus?.display,
        },
      ],
    },
    subject: {
      reference: `Patient/${params.patientId}`,
    },
    ...(params.note && {
      note: [
        {
          text: params.note,
        },
      ],
    }),
    ...((params.extension || []).length > 0 && {extension: params.extension}),
    onsetDateTime: params.onSetDateTime,
    code: params.condition,
    category: [
      {
        coding: [
          {
            code: params?.componentType,
          },
        ],
      },
    ],
    ...(params?.meta && { meta: params?.meta })
  };
};



const getFieldCapabilities = (capabilities: any): IKeyOperation | undefined => {
  return capabilities?.abilities?.keyAllowedOperations;
};

export const formatHieConditionData = (
  resource: Condition,
  metaData: IFormatHieMetaData
):
  | {display: IHieDisplay; resourceData: any; invalidFields: IHieInvalidField[]}
  | undefined => {
  const capabilities = getEHRCapability(metaData.ehrCapabilities, [
    CapabilityResource.condition,
  ]);

  const conditionResource: Condition = resource;
  const conditionDate = getHieConditionDate(resource);
  const fieldCapabilities = getFieldCapabilities(capabilities);

  const status = resource?.clinicalStatus?.coding?.length
  ? resource.clinicalStatus?.coding[0]
  : {code: resource.clinicalStatus?.text};

  const name = getHieConditionCodes(resource, fieldCapabilities?.code?.allowedCodeSystems)?.text || '';
  const display = {
    title: name,
    date: conditionDate,
    status: status?.code,
  };

  const validatedData = validateAndFormatConditionHieResource(
    resource,
    fieldCapabilities
  );

  return {
    resourceData: validatedData.resource,
    display,
    invalidFields: validatedData.invalidFields,
  };
};

const getHieConditionDate = (
  resource: Condition,
  onsetDateFormat?: string | undefined
) => {
  const conditionDate = resource.onsetDateTime || resource.onsetPeriod?.start;

  if (conditionDate) {
    const onsetDate = onsetDateFormat
      ? getDateStrFromFormat(conditionDate, onsetDateFormat)
      : getMomentObj(conditionDate || new Date()).toISOString();

    return onsetDate;
  }
};

const getHieClinicalStatus = (
  resource: Condition,
  possibleValues?: {code: string; display: string}[]
) => {
  const clinicalStatus = resource?.clinicalStatus?.coding?.length
    ? resource.clinicalStatus?.coding[0]
    : {
        code: resource.clinicalStatus?.text,
        display: resource.clinicalStatus?.text,
      };

  return possibleValues?.find(
    (possibleValue) => possibleValue.code === clinicalStatus.code?.toLowerCase()
  );
};

const getHieNote = (resource: Condition) => {
  const noteText = resource?.note?.[0]?.text;
  return noteText;
};

const getHieConditionCodes = (
  resource: Condition,
  allowedCodeSystems?: string[]
) => {
  const codeObj = resource.code;
  const coding = getValidCoding(codeObj, allowedCodeSystems);
  const text = getTextFromCoding(coding) || codeObj?.text;
  return {coding: getValidCoding(codeObj, allowedCodeSystems), text: text};
};

export const getConditionCodeInvalidField = () => {
  return {
    inputType: HieInvalidFields.code,
    field: ConditionFields.code,
    invalid: true,
    label: 'Problem',
    placeholder: 'Search Problem',
    extraData: {
      searchType: PAMISearchType.problem,
    }
  }
}

export const validateAndFormatConditionHieResource = (
  resource: Condition,
  fields: any
) => {
  const invalidFields: IHieInvalidField[] = [];

  Object.keys(fields).forEach(function (key, index) {
    const field = ConditionFields[key as keyof typeof ConditionFields];
    switch (field) {
      case ConditionFields.code:
        const conditionCodes = getHieConditionCodes(
          resource,
          fields?.code?.allowedCodeSystems
        );

        if (
          fields?.code.inputType === InputType.openChoice &&
          conditionCodes.text
        ) {
          resource.code = {
            text: conditionCodes.text           
          };
        } else if (
          (!conditionCodes?.coding?.length || !conditionCodes.text) &&
          fields?.code?.isRequired
        ) {
          invalidFields.push(getConditionCodeInvalidField());
        } else if (conditionCodes?.coding?.length) {
          resource.code = {
            coding: conditionCodes?.coding,
            text: conditionCodes.text,
          };
        }
        break;

      case ConditionFields.onsetDateTime:
        const onsetDateFormat = fields?.onsetDateTime?.format;
        const onsetDateTime = getHieConditionDate(resource, onsetDateFormat);
        if (!onsetDateTime && fields?.onsetDateTime?.isRequired) {
          invalidFields.push({
            inputType: HieInvalidFields.date,
            field: ConditionFields.onsetDateTime,
            invalid: true,
            extraData: {
              dateFormat: onsetDateFormat,
            },
          });
        } else {
          resource.onsetDateTime = onsetDateTime;
        }
        break;

      case ConditionFields.clinicalStatus:
        const status = getHieClinicalStatus(
          resource,
          fields?.clinicalStatus?.possibleValues
        );

        if (!status?.code && fields?.clinicalStatus?.isRequired) {
          invalidFields.push({
            field: ConditionFields.clinicalStatus,
            inputType: HieInvalidFields.select,
            invalid: true,
            extraData: {
              statusList: fields?.clinicalStatus?.possibleValues,
            },
          });
        } else if (status) {
          resource.clinicalStatus = {
            coding: [status],
          };
        }
        break;

      case ConditionFields.note:
        const hieNote = getHieNote(resource);

        if (!hieNote && fields?.note?.isRequired) {
          invalidFields.push({
            field: ConditionFields.note,
            inputType: HieInvalidFields.textArea,
            invalid: true,
          });
        } else if (hieNote) {
          resource.note = [{text: hieNote}];
        }
    }
  });

  return {
    invalidFields: invalidFields,
    resource: resource,
  };
};

export const updateHieConditionMatchedData = (
  resource: Condition,
  invalidFields: IHieInvalidField[],
  metaData: IFormatHieMetaData
) => {
  invalidFields.map((invalidField) => {
    if (invalidField.value) {
      switch (invalidField.field) {
        case ConditionFields.code:
          resource.code = invalidField.value;
          break;
        case ConditionFields.onsetDateTime:
          resource.onsetDateTime = invalidField.value;
          break;
        case ConditionFields.note:
          resource.note = [{text: invalidField.value}];
          break;
        case ConditionFields.clinicalStatus:
          resource.clinicalStatus = {
            coding: [invalidField.value],
          };
          break;
      }
    }
    return invalidField;
  });

  return formatHieConditionData(resource, metaData);
};

export const formatConditionsRequestResourceObj = (
  resource: Condition,
  metaData: IFormatHieMetaData
) => {
  return {
    resourceType: FHIR_RESOURCE.CONDITION,
    ...(resource.clinicalStatus && {
      clinicalStatus: resource.clinicalStatus,
    }),
    subject: {
      reference: `Patient/${metaData.patientId}`,
    },
    ...(resource.note && {
      note: resource.note,
    }),
    ...((resource.extension || []).length > 0 && {
      extension: resource.extension,
    }),
    onsetDateTime: resource.onsetDateTime,
    code: resource.code,
    category: [
      {
        coding: [
          {
            code: ComponentType.Condition,
          },
        ],
      },
    ],
    ...(resource?.meta && {meta: resource?.meta}),
    ...(resource?.extension && {meta: resource?.extension}),
  };
};


