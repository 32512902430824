import React from "react";

const EmployeeWellnessIndexSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
     <svg
     xmlns="http://www.w3.org/2000/svg"
     style={{width: 'inherit', height: 'inherit'}}
     fill="none"
     viewBox="0 0 41 41"
   >
     <g clipPath="url(#clip0_770_112097)">
       <path
         fill={svgColors}
         d="M20.5 7.042V35.35c8.846-6.068 13.748-11.64 16.108-16.29 2.447-4.833 2.147-8.615.804-11.237C34.558 2.25 26.65.718 22.337 5.151L20.5 7.04zm0 31.396C-18.791 12.474 8.402-7.79 20.049 2.929c.154.141.305.287.45.438.145-.15.296-.296.452-.435 11.644-10.727 38.84 9.54-.451 35.506z"
       ></path>
     </g>
     <defs>
       <clipPath id="clip0_770_112097">
         <path fill="#fff" d="M0 0H41V41H0z"></path>
       </clipPath>
     </defs>
   </svg>
  );
}

export default React.memo(EmployeeWellnessIndexSvg);
