import React, {useCallback, useState} from 'react';
import Feather from 'react-native-vector-icons/Feather';
import {AddButtonAction, IContactCareProgramStep} from '../interface';
import {View} from 'native-base';
import {CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons';
import {canShowAddButtonWithAction} from './ContactCareProgramView.utils';
import {Colors} from '../../../../styles';
import SideBarAddActionView from './components/SideBarAddActionView';
import {usePersonOmniViewContext} from '../../../PersonOmniView/PersonOmniView.context';
import {useContactCareProgramContext} from './ContactCareProgram.context';
import {testID} from '../../../../testUtils';

const SideBarCollapsibleStep = (props: {
  subStepsArray: IContactCareProgramStep[];
  onClick: () => void;
  isExpanded: boolean;
  id: string;
  title: string;
  step: IContactCareProgramStep;
}) => {
  const {subStepsArray, onClick, isExpanded, id, title, step} = props;
  const {formattedData: {accountLocationUuid: contactAccountLocationId}} = usePersonOmniViewContext();
  const {refetchMasterData} = useContactCareProgramContext();
  const [addActionView, setAddActionView] = useState<
    AddButtonAction | undefined
  >(undefined);

  const onAddButtonClick = (action: AddButtonAction) => {
    if (!isExpanded) {
      onClick();
    }
    if (action === AddButtonAction.BILLING) {
      // add new step
    } else {
      setAddActionView(action);
    }
  };

  const addButtonAction = canShowAddButtonWithAction(step);

  const onAddComplete = () => {
    setAddActionView(undefined);
    refetchMasterData?.();
  };

  const onCancel = useCallback(() => {
    setAddActionView(undefined);
  }, []);

  return (
    <>
      <div className="sidebar-item-collapsible" onClick={onClick} {...testID(`sidebar-item-${title}`)}>
        <View style={{marginRight: 8}}>
          {isExpanded ? (
            <CaretUpOutlined style={{fontSize: 8}} />
          ) : (
            <CaretDownOutlined style={{fontSize: 8}} />
          )}
        </View>
        <span>{title}</span>
        <div className="spacer" />
        {addButtonAction && (
          <Feather
            name="plus"
            size={16}
            color={Colors.FoldPixel.GRAY200}
            onPress={() => onAddButtonClick(addButtonAction)}
            {...testID(`add-button-${title}`)}
          />
        )}
      </div>
      {addActionView && (
        <SideBarAddActionView
          contactAccountLocationId={contactAccountLocationId}
          actionCode={addActionView}
          onCancel={onCancel}
          onAddComplete={onAddComplete}
          step={step}
        />
      )}
    </>
  );
};

export default React.memo(SideBarCollapsibleStep);
