import {useContext} from 'react';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../../../constants';
import {CONTACT_CARE_PROGRAM_STATUS_CODES} from '../../../../../constants/MlovConst';



export const useCareProgramStatus = () => {
  const contextData = useContext(CommonDataContext);
  const careProgramStatusList =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CONTACT_CARE_PROGRAM_STATUS
    ) || [];

  const careProgramStatus = {
    inProgress: getMlovIdFromCode(
      careProgramStatusList,
      CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS
    ),
    completed: getMlovIdFromCode(
      careProgramStatusList,
      CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED
    ),
    autoClosed: getMlovIdFromCode(
      careProgramStatusList,
      CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED
    ),
    newStatus: getMlovIdFromCode(
      careProgramStatusList,
      CONTACT_CARE_PROGRAM_STATUS_CODES.NEW
    ),
    toDo: getMlovIdFromCode(
      careProgramStatusList,
      CONTACT_CARE_PROGRAM_STATUS_CODES.TO_DO
    ),
    closed: getMlovIdFromCode(
      careProgramStatusList,
      CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED
    ),
  };

  return {careProgramStatus, careProgramStatusList};
};
