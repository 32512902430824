import React from 'react';
import {Text} from 'native-base';
import {IContactCareProgramResponse} from '../../../services/ContactCareProgram/interface';
import Stack from '../LayoutComponents/Stack';
import {CareProgramComplexityCode} from '../../../constants/MlovConst';
import {Colors} from '../../../styles/Colors';
import {useIntl} from 'react-intl';
import {StyleSheet} from 'react-native';

const CareProgramComplexity = ({
  contactCarePrograms,
}: {
  contactCarePrograms: IContactCareProgramResponse[];
}) => {
  const intl = useIntl();
  return (
    <Stack direction={'column'}>
      {(contactCarePrograms || []).map((contactCareProgram, index) => {
        if (contactCareProgram?.complexity) {
          const complexityText =
            CareProgramComplexityCode.COMPLEX === contactCareProgram?.complexity
              ? 'complex'
              : 'moderate';
          const containerStyle =
            CareProgramComplexityCode.COMPLEX === contactCareProgram?.complexity
              ? styles.complexCareProgram
              : styles.moderateCareProgram;

          const textColor =
            CareProgramComplexityCode.COMPLEX === contactCareProgram?.complexity
              ? Colors.Custom.crossIconColor
              : Colors.Custom.DeceasedTagColor;

          return (
            <Stack
              direction={'row'}
              key={`${contactCareProgram?.id}_${index}`}
              style={containerStyle}
            >
              <Text
                wordBreak={'break-word'}
                color={textColor}
                size={'smRegular'}
              >
                {intl.formatMessage({id: complexityText})}
              </Text>
            </Stack>
          );
        } else {
          return (
            <Stack
              direction={'row'}
              key={`${contactCareProgram?.id}_${index}`}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                wordBreak={'break-word'}
                color={Colors.Custom.Gray500}
                size={'smLight'}
              >
                {'-'}
              </Text>
            </Stack>
          );
        }
      })}
    </Stack>
  );
};

const styles = StyleSheet.create({
  complexCareProgram: {
    backgroundColor: Colors.Custom.Red100,
    margin: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  moderateCareProgram: {
    backgroundColor: Colors.Custom.PendingTaskBackgroundColor,
    margin: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
});
export default CareProgramComplexity;
