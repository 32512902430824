import {IAddOrUpdateFamilyHistoryParams, IFamilyHistory, IFamilyHistoryComponentValue} from '../interfaces';
import {v4 as uuidv4} from 'uuid';
import {IKeyOperation} from '../../../../../../../Interfaces';
import { IFormatHieMetaData, IHieFormattedData, IHieInvalidField } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import { FamilyMemberHistory } from 'fhir/r4';
import { ICodeableParam } from '../../Conditions/interfaces';
import { getEHRCapability } from '../../../../../../../utils/commonUtils';
import { CapabilityResource } from '../../CustomWrapper/CustomComponentHelper';
import { getTextFromCoding, getValidCoding } from '../../../../../Integration/FhirUtils';
import { HieInvalidFields } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';
import { PAMISearchType } from '../../../../../../common/PAMISearch/PAMISearch';
import { text } from 'stream/consumers';
import { InputType } from '../../../../../../../utils/capabilityUtils';

export enum FamilyHXFields {
  relationShip,
  condition,
  relationship,
}

export const getRecordListFormatted = (list: any[]) => {
  const entries = [];
  for (const el of list || []) {
    entries.push(el.resource);
  }
  return getRecordDataFormatted(entries);
}

export const getRecordDataFormatted = (listData: any[]): {list: IFamilyHistory[]; hasIncompleteData: boolean;} => {
  const tempFormattedList: IFamilyHistory[] = [];
  let incompleteData = false;
  listData.map((item: any) => {
    const problem = item?.condition?.length
      ? item.condition[0].code
      : undefined;
    const relation = item?.relationship?.coding?.length
      ? item.relationship.coding[0]
      : undefined;

    if (!problem?.text && problem?.coding?.length) {
      problem.text = problem.coding.length
        ? problem.coding[0].display //|| problem.coding[0].code
        : undefined;
    }
    if (problem?.text && item?.condition?.length) {
      const tempObj: IFamilyHistory = {
        id: item?.id,
        condition: problem,
        relationship: {
          code: relation?.display,
          display: relation?.display,
        },
        originalCondition: item.condition?.[0],
        note: item.condition?.[0]?.note?.[0]?.text,
        onsetAge: item.condition?.[0]?.onsetAge?.value,
        deceasedAge: item.deceasedAge?.value,
        uniqueId: uuidv4(),
        isFreeTextRecord: !problem?.coding?.length,
      };
      tempFormattedList.push(tempObj);
    } else {
      incompleteData = true;
    }
  });
  return {list: tempFormattedList, hasIncompleteData: incompleteData};
};

export const getSampleData = (): IFamilyHistoryComponentValue => {
  return {
    history: [{
      id: '34234324',
      relationship: {
        code: 'Father',
        display: 'Father',
      },
      condition: {
        text: 'Sample Problem 1',
        coding: [],
      },
      uniqueId: '1234',
      isFreeTextRecord: false,
    },
    {
      id: '34234324',
      relationship: {
        code: 'Father',
        display: 'Father',
      },
      condition: {
        text: 'Sample Problem 2',
        coding: [],
      },
      uniqueId: '1235',
      isFreeTextRecord: false,
    },
    {
      id: '34234324',
      relationship: {
        code: 'Mother',
        display: 'Mother',
      },
      condition: {
        text: 'Sample Problem 3',
        coding: [],
      },
      uniqueId: '1236',
      isFreeTextRecord: false,
    }],
  }
};

export const getConditionName = (item: any) => {
  let name = item?.condition?.text;
  if (!name && item?.condition?.coding?.length) {
    name = item.condition.coding[0].display;
  }
  return name;
};

export const isInvalid = (
  field: FamilyHXFields,
  item: IFamilyHistory,
  submitTriggered: boolean,
  fields?: IKeyOperation
) => {
  switch (field) {
    case FamilyHXFields.relationShip:
      if ((fields?.relationship?.isRequired || false) && submitTriggered) {
        return !item.relationship || !item.relationship.code;
      }
      return false;

    case FamilyHXFields.condition:
      if ((fields?.condition?.isRequired || false) && submitTriggered) {
        return !item.condition;
      }
      return false;

    default:
      return false;
  }
};

export const getGroupedHXByRelation = (items: IFamilyHistory[]) => {
  const groups: {relation: string; list: IFamilyHistory[]}[] = [];
  const noRelationFamilyHX: IFamilyHistory[] = [];
  items.forEach((item) => {
    if (item.relationship.display) {
      const relation = item.relationship.display;
      const group = groups.find((group) => group.relation === relation);
      if (group) {
        group.list.push(item);
      } else {
        groups.push({relation, list: [item]});
      }
    } else {
      noRelationFamilyHX.push(item);
    }
  });
  if (noRelationFamilyHX.length) {
    return [...groups, {relation: '', list: noRelationFamilyHX}];
  }
  return groups;
};

export const getFHIRTemplate = (params: IAddOrUpdateFamilyHistoryParams) => {
  if (params?.condition?.coding?.[0]?.hasOwnProperty('code')) {
    const isProblemChanged =
      params.condition?.coding?.[0]?.code !==
      params.originalCondition?.code?.coding?.[0]?.code;
    return {
      resourceType: 'FamilyMemberHistory',
      ...(params.id && {id: params.id}),
      relationship: {
        coding: [
          {
            display: params.relationship.display,
          },
        ],
      },
      patient: {
        reference: `Patient/${params.patientId}`,
      },
    ...(!isProblemChanged && params.originalCondition && {
      condition: [params.originalCondition]
        }),
      ...(isProblemChanged && {
        condition: [
          {
            ...(params.note && {
              note: [
                {
                  text: params.note,
              }
            ]
            }),
            ...(params.onsetAge && {
              onsetAge: [
                {
                  value: params.onsetAge,
              }
            ]
            }),
            ...params.originalCondition,
            code: params.condition,
          },
      ]
      }),
      ...(params.deceasedAge && {
        deceasedAge: {
          value: params.deceasedAge,
        },
      }),
    };
  } else {
    return {
      resourceType: 'FamilyMemberHistory',
      ...(params.id && {id: params.id}),
      relationship: {
        coding: [
          {
            display: params.relationship.display,
          },
        ],
      },
      patient: {
        reference: `Patient/${params.patientId}`,
      },

      condition: [
        {
          code: {
            coding: [
              {
                display: params.freeTextPatientHistoryName || params.condition?.text,
              },
            ],
          },
        },
      ],
      ...(params.deceasedAge && {
        deceasedAge: {
          value: params.deceasedAge,
        },
      }),
    };
  }
};

export const getAdditionalDetails = (item: IFamilyHistory, shortVersion: boolean) => {
  let details: string[] = [];
  if (item.onsetAge) {
    details.push(`Onset Age: ${item.onsetAge}`);
  }
  if (item.deceasedAge) {
    details.push(`Died: ${item.deceasedAge}`);
  }
  if (details.length) {
    const firstLine = details.join(' • ');
    details = [firstLine];
  }
  if (item.note && !shortVersion) {
    details.push(`Note: ${item.note}`);
  }
  return details.join('\n');
}


export const formatHieFamilyHistoryData = (
  resource: any,
  metaData: IFormatHieMetaData
): IHieFormattedData | undefined => {
    
    const familyMemberHistoryResource: FamilyMemberHistory = resource;
    const formattedFamilyHistories = getRecordDataFormatted([
      familyMemberHistoryResource,
    ]);

    const capabilities = getEHRCapability(metaData.ehrCapabilities, [
      CapabilityResource.familyHistory,
    ]);
  
    const fieldCapabilities = capabilities?.abilities?.keyAllowedOperations;  

    const title = getConditionName(familyMemberHistoryResource.condition?.[0]?.code) || getConditionName({condition: familyMemberHistoryResource.condition?.[0]?.code});

    const relationText = getRelationshipObj(resource)?.display;
    const display = {
      title: title,
      subTitle: relationText,
      status: familyMemberHistoryResource?.status,
    };

    const validatedData = validateAndFormatFamilyHistoryHieResource(resource, fieldCapabilities);

    return {
      display,
      resourceData: validatedData.resource,
      invalidFields: validatedData.invalidFields
    };
};

const getRelationshipObj = (resource: FamilyMemberHistory) => {
  return resource.relationship?.coding?.[0];
};

const getHieFamilyMemberHistoryConditions = (
  resource: FamilyMemberHistory,
  allowedCodeSystems?: string[]
) => {
  const codeObj = resource.condition?.[0]?.code;
  const coding = getValidCoding(codeObj, allowedCodeSystems);
  const text = getTextFromCoding(coding) || codeObj?.text;
  return {coding: coding, text: text};
};

export const getFamilyMemberInvalidCodeField = () => {
  return {
    field: FamilyHXFields.condition,
    inputType: HieInvalidFields.code,
    invalid: true,
    label: 'Condition',
    placeholder: 'Search and add new problem',
    extraData: {
      searchType: PAMISearchType.problem,
    },
  }
}

export const validateAndFormatFamilyHistoryHieResource = (
  resource: FamilyMemberHistory,
  fields: any
) => {
  const invalidFields: IHieInvalidField[] = [];

  Object.keys(fields).forEach(function (key, index) {
    const field = FamilyHXFields[key as keyof typeof FamilyHXFields];
    switch (field) {
      case FamilyHXFields.condition:
        const conditions = getHieFamilyMemberHistoryConditions(
          resource,
          fields?.condition?.allowedCodeSystems
        );

        if (
          fields?.condition?.inputType === InputType.openChoice &&
          conditions.text
        ) {
          resource.condition = [{
            code: {
              ...(conditions?.coding?.length && {
                coding: conditions.coding,
              }),
              text: conditions.text,
            },
          }]
        }
        else if ((!conditions.coding?.length || !conditions.text) && fields?.condition?.isRequired) {
          invalidFields.push(getFamilyMemberInvalidCodeField());
        } else if (conditions?.coding?.length) {
          resource.condition = [
            {
              code: {
                coding: conditions.coding,
                text: conditions.text
              },
            },
          ];
        }
        break;

      case FamilyHXFields.relationship:
        const relationshipList = fields?.relationship?.possibleValues;
        const relationshipText = getRelationshipObj(resource)?.display;
        const relationFromCapability = relationshipList.find(
          (relationship: any) => relationship.display === relationshipText
        );

        if (!relationFromCapability && fields?.relationship?.isRequired) {
          invalidFields.push({
            inputType: HieInvalidFields.select,
            field: FamilyHXFields.relationship,
            invalid: true,
            extraData: {
              statusList: fields?.relationship?.possibleValues,
              placeholder: 'Add new relation',
            },
            label: 'Relationship',
          });
        } else if (relationFromCapability) {
          resource.relationship = {
            coding: [
              {
                display: relationFromCapability?.display,
              },
            ],
          };
        }
        break;
    }
  });

  return {
    invalidFields: invalidFields,
    resource: resource,
  };
};

export const updateHieFamilyHistoryMatchedData = (
  resource: FamilyMemberHistory,
  invalidFields: IHieInvalidField[],
  metaData: any
) => {
  invalidFields.map((invalidField) => {
    if (invalidField.value) {
      switch (invalidField.field) {
        case FamilyHXFields.condition:
          resource.condition = [
            {
              code: invalidField.value,
            },
          ];
          break;

        case FamilyHXFields.relationship:
          resource.relationship = {
            coding: [
              {
                display: invalidField.value,
              },
            ],
          };
          break;
      }
    }
    return invalidField;
  });

  return formatHieFamilyHistoryData(resource, metaData);
};

export const formatHieFamilyHistoryResourceObj = (
  resource: FamilyMemberHistory,
  metaData: any
) => {
  return {
    resourceType: 'FamilyMemberHistory',
    ...(resource.relationship && {relationship: resource.relationship}),
    patient: {
      reference: `Patient/${metaData.patientId}`,
    },
    ...(resource.condition && {condition: resource.condition}),
    ...(resource.deceasedAge && {
      deceasedAge: {
        value: resource.deceasedAge,
      },
    }),
  };
};
