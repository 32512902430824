import {AxiosInstance} from 'axios';
import {
  Button,
  FormControl,
  HStack,
  Icon,
  Image,
  Input,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import React, {useState} from 'react';
import {Dimensions, Platform} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {PasswordOptions, validate} from 'secure-password-validator';
import { BUTTON_TYPE } from '../../constants/StringConst';
import BaseService from '../../services/CommonService/BaseService';
import {Colors} from '../../styles';
import {showToast, ToastType} from '../../utils/commonViewUtils';
import {isWeb} from '../../utils/platformCheckUtils';
import {DisplayText} from '../common/DisplayText/DisplayText';
import FoldhealthLogoSvg from '../common/Svg/FoldhealthLogoSvg';
import LoginBg from '../Login/LoginBg';
import {useKeyboard} from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/UseKeyBoard.native';
import PasswordChecklist from './PasswordChecklist';
import {styles} from './SetPasswordStyle';
import {getFirstNameAndLastName} from '../RightSideContainer/Contacts/TeamMembers/TeamMembersUtils';
import {useIntl} from 'react-intl';
import {checkIfOffline} from '../../screens/MainScreen/MainScreenHelper';
import { captureError } from '../../utils/SentryEventUtils';
import { TrackEventName } from '../../constants/SentryConst';
import { testID, TestIdentifiers } from '../../testUtils';
const MIN_PASSWORD_LENGTH = 8;

const validateOptions: PasswordOptions = {
  symbols: true,
  digits: true,
  letters: true,
  minLength: MIN_PASSWORD_LENGTH,
  uppercase: true,
  lowercase: true,
};

const setPassword = (args: {
  baseService: AxiosInstance;
  body: {
    uuid: string;
    email: string;
    password: string;
  };
}) => {
  const {baseService, body} = args;
  const url = '/crm-nest/auth/setUserPassword';

  return baseService.post(url, body);
};

interface SetPasswordProps {
  data: any;
  onSuccess: () => void;
}

const validatePassword = (args: {
  password: string;
  confirmPassword: string;
}): {
  error: string;
  valid: boolean;
} => {
  const {password, confirmPassword} = args;

  if (password !== confirmPassword) {
    return {
      error: 'Password and Confirm Password should be same',
      valid: false,
    };
  }

  const {valid} = validate(password, validateOptions);
  if (!valid) {
    return {
      valid: valid,
      error: `Password does not meet following requirements`,
    };
    // return {
    //   valid: valid,
    //   error: `Make sure your password meets following requirements \n
    //   1. At least 1 number\n
    //   2. At least 1 upper case letter\n
    //   3. At least 1 special character\n
    //   4. Minimum length: 8`,
    // };
  }


  return {
    error: '',
    valid: true,
  };
};

const SetPassword = (props: SetPasswordProps) => {
  const {data, onSuccess} = props;
  const intl = useIntl()
  const {height} = Dimensions.get('window');
  if (data?.userName) {
    data.email = data?.userName;
  }
  const keyboardHeight = useKeyboard();
  const [passwordState, setPasswordState] = React.useState({
    password: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = React.useState(false);

  const toast = useToast();

  const baseService = BaseService.getSharedInstance().axios;

  const [errors, setErrors]: any = useState({});
  const [show, setShow] = useState(false);
  const [showConfirmPw, setConfirmPwShow] = useState(false);

  const onSubmit = () => {
    const {password, confirmPassword} = passwordState;

    const {valid, error} = validatePassword({password, confirmPassword});

    setErrors({password: error});

    if (valid) {
      setLoading(true);
      const body = {
        email: data.email,
        uuid: data.uuid,
        password: password,
      };

      setPassword({
        baseService: baseService,
        body: body,
      })
        .then((response) => {
          successHandler(response);
        })
        .catch((error) => {
          if (checkIfOffline(error)) {
            setLoading(false);
            showToast(
              toast,
              intl.formatMessage({id: 'networkConnectionFailed'}),
              ToastType.info,
              3500,
            );
          } else {
            errorHandler(error);
          }
        });
    }
  };

  const successHandler = (response: any) => {
    if (response.data) {
      setLoading(false);
      onSuccess();
    }
  };

  const errorHandler = (error: any) => {
    setLoading(false);
    showToast(
      toast,
      'Something went wrong. Please try again later',
      ToastType.error
    );
    captureError(error, TrackEventName.FORGOT_PW_ERROR);
  };

  const handleClick = () => setShow(!show);
  const handleConfirmClick = () => setConfirmPwShow(!showConfirmPw);
  const {firstName} = getFirstNameAndLastName(data?.name || '');

  return (
    <View
      bgColor={'white'}
      height={
        Platform.OS === 'web'
          ? 'auto'
          : keyboardHeight == 0
          ? 'full'
          : height - keyboardHeight
      }
    >
      {Platform.OS === 'web' && (
        <VStack
          height={Platform.OS === 'web' ? '100vh' : 'full'}
          justifyContent={'space-between'}
          style={
            Platform.OS === 'web' && {
              position: 'relative',
              right: 100,
            }
          }
        >
          <View />
          <View {...testID(TestIdentifiers.loginBg)} height={height - 100}>{<LoginBg />}</View>
        </VStack>
      )}
      {!isWeb() ? (
        <View
          style={{
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginRight: 34,
            marginTop: 60,
          }}
        >
          <View
            style={{
              width: 201,
              height: 130,
              backgroundColor: 'transparent',
            }}
          >
            <Image
              resizeMode="contain"
              source={require('../../assets/images/PNGImages/login-top-image.png')}
              alt={TestIdentifiers.foldhealthLogoIcon}
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
              }}
            />
          </View>
        </View>
      ) : null}
      <View
        flex={1}
        alignItems={'flex-end'}
        mt={Platform.OS === 'web' ? '10%' : '0'}
        mr={Platform.OS === 'web' ? '10%' : '0'}
        style={
          Platform.OS === 'web'
            ? {
                flex: 1,
                position: 'absolute',
                right: 0,
                top: 0,
              }
            : {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 16,
              }
        }
      >
        {!isWeb() ? (
          <Text
            mb={2}
            ml={1}
            style={{
              textAlign: 'left',
              alignSelf: 'flex-start',
              color: Colors.Custom.Gray700,
              fontSize: 20,
              fontWeight: '900',
            }}
            fontSize="md"
            {...testID('Set New Password')}
          >
            Set New Password
          </Text>
        ) : (
          <></>
        )}
        <View
          style={[
            styles.mainBoxContainer,
            styles.mainBoxContainerShadow,
            !isWeb() && {paddingBottom: 20},
          ]}
          bg={'white'}
        >
          <VStack w={'100%'}>
            <VStack w={'100%'}>
              <HStack {...testID(TestIdentifiers.foldhealthLogoWithName)} justifyContent="center" alignItems={'center'} flex={1}>
                {Platform.OS === 'web' ? <FoldhealthLogoSvg /> : <></>}
              </HStack>
              <VStack
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: -12,
                }}
              >
                <Text
                  style={{
                    fontWeight: '700',
                    fontSize: 30,
                    lineHeight: 45,
                  }}
                  {...testID('Hey!')}
                >
                  {`Hey ${firstName || ''}!`}
                </Text>

                <Text
                  style={{
                    textAlign: 'center',
                    color: Colors.Custom.Gray700,
                    fontSize: 14,
                    fontWeight: '400',
                    lineHeight: 21,
                  }}
                  {...testID(`Let's set a new password`)}
                >
                  Let's set a new password
                </Text>
              </VStack>
            </VStack>
            <FormControl {...testID(TestIdentifiers.isRequiredInput)} isRequired isInvalid={errors.userName}>
              <FormControl.Label>
                <Text {...testID(TestIdentifiers.passwordText)} style={styles.labelStyle}>Password</Text>
              </FormControl.Label>
              <Input
              _focus={{borderColor: '#D0D5DD'}}
                height={44}
                autoCapitalize="none"
                placeholder="Password"
                type={show ? 'text' : 'password'}
                fontSize={16}
                onChangeText={(value) => {
                  setPasswordState((prev) => {
                    return {
                      ...prev,
                      password: value,
                    };
                  });
                }}
                InputRightElement={
                  <View paddingRight={2}>
                    <MaterialCommunityIcons
                      name={show ? 'eye-off' : 'eye'}
                      size={24}
                      color={Colors.Custom.Gray400}
                      onPress={() => {
                        handleClick();
                      }}
                      {...testID(TestIdentifiers.showPasswordBtn)}
                    />
                  </View>
                }
                {...testID(TestIdentifiers.loginPassword)}
              />
            </FormControl>
            <FormControl {...testID(TestIdentifiers.isRequiredInput)} mt={6} isRequired isInvalid={errors.password}>
              <FormControl.Label>
                <Text {...testID('Confirm Password')} style={styles.labelStyle}>Confirm Password</Text>
              </FormControl.Label>
              <Input
              _focus={{borderColor: '#D0D5DD'}}
                height={44}
                fontSize={16}
                type={showConfirmPw ? 'text' : 'password'}
                placeholder="Confirm Password"
                autoCapitalize="none"
                onChangeText={(value) => {
                  setPasswordState((prev) => {
                    return {
                      ...prev,
                      confirmPassword: value,
                    };
                  });
                }}
                InputRightElement={
                  <View paddingRight={2}>
                    <MaterialCommunityIcons
                      name={showConfirmPw ? 'eye-off' : 'eye'}
                      size={24}
                      color={Colors.Custom.Gray400}
                      onPress={() => {
                        handleConfirmClick();
                      }}
                      {...testID(TestIdentifiers.showPasswordBtn)}
                    />
                  </View>
                }
                {...testID('ConfirmLoginPassword')}
              />
              {errors.password && (
                <FormControl.ErrorMessage
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                  {...testID(TestIdentifiers.errorMessage)}
                >
                  {errors.password}
                </FormControl.ErrorMessage>
              )}

              <VStack mt={3}>
                <FormControl.Label isRequired={false}>
                  <Text
                    style={{
                      color: 'black',
                      fontWeight: '400',
                      fontSize: 14,
                      lineHeight: 21,
                    }}
                    {...testID('Password should meet following requirements')}
                  >
                    Password should meet following requirements
                  </Text>
                </FormControl.Label>
                <PasswordChecklist
                  rules={[
                    'minLength',
                    'specialChar',
                    'number',
                    'capital',
                    'match',
                  ]}
                  minLength={8}
                  value={passwordState.password}
                  valueAgain={passwordState.confirmPassword}
                  iconSize={12}
                  // className="PassworkChecklist"
                  iconComponents={{
                    ValidIcon: (
                      <Icon
                        {...testID('image')}
                        size="4"
                        color={Colors.success[400]}
                        as={<AntIcon name="checkcircle" />}
                      />
                    ),
                    InvalidIcon: (
                      <Icon
                        {...testID('image')}
                        size="4"
                        color={Colors.Custom.Gray300}
                        as={<AntIcon name="checkcircle" />}
                      />
                    ),
                  }}
                  messages={{
                    minLength: 'Minimum length: 8',
                    specialChar: 'At least 1 special character',
                    number: 'At least 1 number',
                    capital: 'At least 1 uppercase letter',
                    match: 'Password match',
                  }}
                />
              </VStack>
            </FormControl>
          </VStack>
          <Button
            w={'100%'}
            h={10}
            style={styles.submitElement}
            variant={BUTTON_TYPE.PRIMARY}
            onPress={onSubmit}
            {...testID(TestIdentifiers.submitBtn)}
          >
            <DisplayText
              textLocalId="submit"
              size={'smMedium'}
              extraStyles={
                {color: 'white', fontWeight: '600', fontSize: 14} || ''
              }
            />
          </Button>
        </View>
      </View>
    </View>
  );
};

export default SetPassword;
