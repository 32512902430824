import { GraphColors } from "../../../styles/Colors";

export const GraphConfig = {
    appendPadding: 10,
    radius: 1,
    innerRadius: 0.6,
    color: GraphColors,
    // label: {
    //   autoRotate: false,
    //   type: 'inner',
    //   offset: '-50%',
    //   content: '{value}',
    //   style: {
    //     textAlign: 'center',
    //     fontSize: 10,
    //   },
    // },
    height: 250,
    width: 250,
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: undefined,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: '',
      },
    },
}
