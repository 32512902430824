import {Skeleton} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {ANALYTICS_RESOURCE} from '../../../../../constants';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {getSectionDataByResourceCode} from '../../../../../services/Employer/EmployerServices/EmployerServices';
import {Colors} from '../../../../../styles';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {IAppliedFilter, IResource, IWidgetCardView} from '../interface';
import {
  getTenantForDemo, TOTAL_SAVINGS
} from '../tempDataFile';
import NodataViewTypeOne from './NoDataView/NodataViewTypeOne';

const TotalSavingDetailView = (props: IWidgetCardView) => {
  const {sectionData, employerId, isAccountDashboard,
    memberType, } =
    props;
  const intl = useIntl();
  const [stateData, setStateData] = useState({
    resourceData: [] as IResource[] | any,
    loading: false,
    selectedView: '',
    selectedResourceData: {} as any,
    masterData: {} as any,
    appliedFilter: props.appliedFilter,
  });
  const screenWidth = Dimensions.get('window').width;
  const isTotalEmployeeMembershipCostOn = isAccountConfigEnabled(CONFIG_CODES.TOTAL_EMPLOYEE_MEMBERSHIP_COST);
  const isNewCostSavingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_NEW_COST_SAVING_API)
  let totalSavingTraditionalCost =
    stateData.resourceData?.currentDateRangeTotalCostSavingData?.totalMarketCost;
  const totalSavingOurCost =
    stateData.resourceData?.currentDateRangeTotalCostSavingData?.totalDpcCost;
  const totalMembershipCost =
    stateData.resourceData?.currentDateRangeTotalCostSavingData?.totalMembershipCost;
  const totalServiceCost =
    stateData.resourceData?.currentDateRangeTotalCostSavingData?.totalServiceCost;
  totalSavingTraditionalCost = parseInt(totalSavingTraditionalCost);
  let totalSavingDifference = totalSavingTraditionalCost - totalSavingOurCost;
  if (totalSavingDifference && typeof totalSavingDifference?.toFixed === 'function') {
    totalSavingDifference = parseInt(totalSavingDifference.toFixed(2));
  }

  let totalSavingDifferencePercentage =
    ((totalSavingOurCost - totalSavingTraditionalCost) /
      totalSavingTraditionalCost) *
    100;
  if (totalSavingDifferencePercentage && typeof totalSavingDifferencePercentage?.toFixed === 'function') {
    totalSavingDifferencePercentage = parseInt(totalSavingDifferencePercentage.toFixed(2));
  }
  let sectionCost = 0;
  if (!isNaN(stateData.resourceData?.currentDateResp?.totalMarketCost)) {
    sectionCost = parseInt(stateData.resourceData?.currentDateResp?.totalMarketCost);
  }
  let totalCostSaving = totalSavingDifference;

  if (totalCostSaving && typeof totalCostSaving?.toFixed === 'function') {
    totalCostSaving = parseInt(totalCostSaving.toFixed(2));
  }
  const isNegative = totalCostSaving < 0;
  let totalPreviousCostSaving: any = true
  if (totalPreviousCostSaving && typeof totalPreviousCostSaving?.toFixed === 'function') {
    totalPreviousCostSaving = parseInt(totalPreviousCostSaving.toFixed(2));
  }

  let totalPercentSaving = totalSavingDifferencePercentage;
  if (totalPercentSaving && typeof totalPercentSaving?.toFixed === 'function') {
    totalPercentSaving = parseInt(totalPercentSaving.toFixed(2));
  }
  const isPositive = Math.sign(totalPercentSaving) === -1 ? false : true;
  const getSectionByResourceCode = async (resourceCode?: string, appliedFilter?: IAppliedFilter[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const filterList = appliedFilter?.length ? appliedFilter : stateData.appliedFilter
    const response = await getSectionDataByResourceCode(
      employerId,
      resourceCode || sectionData.resourceCode || '',
      filterList,
      '',
      isAccountDashboard,
      memberType,
      '',
      isNewCostSavingEnabled
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          resourceData: [],
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data) {

      const resourceData = response.data;
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: [] as IResource[],
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };
  const getCostValueWithCurrency = (cost: any) => {
    return intl.formatNumber(cost || 0, {
      style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
  }
  const controller = new AbortController();
  useEffect(() => {
    if (getTenantForDemo()) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: true
        }
      })
      const resourceData: any = getTenantForDemo()
        ? TOTAL_SAVINGS
        : props.resourceData;

      setTimeout(() => {
        setStateData((prev) => {
          return {
            ...prev,
            resourceData: resourceData,
            loading: false
          }
        })
      }, 3000)
      return
    }
    if (props.sectionData.resourceCode === ANALYTICS_RESOURCE.TOTAL_SAVINGS) {
      return
    }
    getSectionByResourceCode();
    return () => controller?.abort();
  }, [sectionData.id])
  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        resourceData: props.resourceData,
        loading: false,
      };
    });
  }, [props.resourceData, sectionData.id])
  useEffect(() => {
    if (props.filterData?.resourceCode == sectionData.resourceCode) {
      const appliedFilter = stateData.appliedFilter?.length ? [...stateData.appliedFilter] : [];
      const selectedFilter = {
        filterCode: props.filterData?.filterCode,
        filterValue: props.filterData?.filterValue
      }
      appliedFilter.push(selectedFilter);
      getSectionByResourceCode('', appliedFilter);
    }
    return () => controller?.abort();
  }, [props.filterData?.filterValue])
  return (
    <div style={{width: '100%'}}>
      {stateData.loading ? (
        <>
          <Skeleton.Text style={{padding: 16}} lines={5} />
        </>
      ) : (
        <div style={{width: '100%', padding: 24, paddingBottom: 0, paddingTop: 0, paddingLeft: 32}}>
          <div style={{width: '100%'}}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'space-between',
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: '#E4E7EC',
              paddingBottom: 20
            }}>
              <div
                style={{
                  color: '#667085',
                  fontStyle: 'normal',
                  fontFamily: 'Manrope-Regular',
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                Traditional cost
              </div>
              {stateData.resourceData?.currentDateResp || totalSavingTraditionalCost ? (
                <div
                  style={{
                    color: '#000',
                    fontStyle: 'normal',
                    fontFamily: 'Manrope-Regular',
                    fontSize: 18,
                    fontWeight: 500,
                  }}
                >
                  {getCostValueWithCurrency(totalSavingTraditionalCost ? Math.trunc(totalSavingTraditionalCost) : 0)}
                </div>
              ) : (
                <NodataViewTypeOne viewCode={'ONLY_TEXT'} />
              )}
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'space-between',
                  paddingBottom: 8,
                  paddingTop: 16
                }}
              >
                <div
                  style={{
                    color: '#667085',
                    fontFamily: 'Manrope-Regular',
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  Our service cost
                </div>
                {stateData.resourceData?.currentDateResp || totalSavingOurCost ? (
                  <div
                    style={{
                      color: '#000',
                      fontFamily: 'Manrope-Regular',
                      fontSize: 18,
                      fontWeight: 500,
                    }}
                  >
                    {getCostValueWithCurrency(totalSavingOurCost ? Math.trunc(totalSavingOurCost) : 0)}
                  </div>
                ) : (
                  <NodataViewTypeOne
                    viewCode={'ONLY_TEXT'}
                  />
                )}
              </div>
              {
                isTotalEmployeeMembershipCostOn ?
                  (
                    <div style={{display: isTotalEmployeeMembershipCostOn ? 'block' : 'none'}}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          justifyContent: 'space-between',
                          paddingBottom: 4,
                          paddingTop: 4
                        }}
                      >
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                          <span
                            className="g2-tooltip-marker"
                            style={{
                              backgroundColor: '#80A4D4',
                              width: 8,
                              height: 8,
                              borderTopRightRadius: '50%',
                              borderBottomRightRadius: '50%',
                              borderBottomLeftRadius: '50%',
                              borderTopLeftRadius: '50%',
                              display: 'inline-block',
                              marginRight: 8,
                            }}
                          ></span>
                          <div
                            style={{
                              fontSize: 16,
                              color: Colors.Custom.Gray500,
                              fontWeight: 400,
                              fontFamily: 'Manrope',
                            }}
                          >
                            Membership Cost
                          </div>
                        </div>
                        {stateData.resourceData?.currentDateResp || totalMembershipCost ? (
                          <div
                            style={{
                              fontSize: 16,
                              color: Colors.Custom.Gray500,
                              fontWeight: 400,
                              fontFamily: 'Manrope',
                            }}
                          >
                            {getCostValueWithCurrency(totalMembershipCost ? Math.trunc(totalMembershipCost) : 0)}
                          </div>
                        ) : (
                          <NodataViewTypeOne
                            viewCode={'ONLY_TEXT'}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          justifyContent: 'space-between',
                          paddingBottom: 4,
                          paddingTop: 4
                        }}
                      >
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                          <span
                            className="g2-tooltip-marker"
                            style={{
                              backgroundColor: '#BFD7F6',
                              width: 8,
                              height: 8,
                              borderTopRightRadius: '50%',
                              borderBottomRightRadius: '50%',
                              borderBottomLeftRadius: '50%',
                              borderTopLeftRadius: '50%',
                              display: 'inline-block',
                              marginRight: 8,
                            }}
                          ></span>
                          <div
                            style={{
                              fontSize: 16,
                              color: Colors.Custom.Gray500,
                              fontWeight: 400,
                              fontFamily: 'Manrope',
                            }}
                          >
                            Service Cost
                          </div>
                        </div>
                        {stateData.resourceData?.currentDateResp || totalServiceCost ? (
                          <div
                            style={{
                              fontSize: 16,
                              color: Colors.Custom.Gray500,
                              fontWeight: 400,
                              fontFamily: 'Manrope',
                            }}
                          >
                            {getCostValueWithCurrency(totalServiceCost ? Math.trunc(totalServiceCost) : 0)}
                          </div>
                        ) : (
                          <NodataViewTypeOne
                            viewCode={'ONLY_TEXT'}
                          />
                        )}
                      </div>
                    </div>
                  )
                  :
                  (
                    <div style={{height: '106px'}}></div>
                  )
              }

            </div>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'space-between',
              borderTopWidth: 1,
              borderTopStyle: 'solid',
              borderTopColor: '#E4E7EC',
              paddingBottom: 8,
              paddingTop: 16,
              marginTop: 12
            }}>
              <div
                style={{
                  color: '#000',
                  fontFamily: 'Manrope-Regular',
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Total Savings
              </div>
              {stateData.resourceData?.currentDateResp || totalSavingDifference ? (
                <div
                  style={{
                    fontSize: 24,
                    color: isNegative ? Colors.error[600] : Colors.Custom.SuccessColor,
                    fontWeight: 600,
                    fontFamily: 'Manrope-Medium',
                  }}
                >
                  {getCostValueWithCurrency(totalCostSaving ? Math.trunc(totalCostSaving) : 0)}
                </div>
              ) : (
                <NodataViewTypeOne
                  viewCode={'ONLY_TEXT'}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default React.memo(TotalSavingDetailView);
