import {getEnvVariable} from '../constants/BaseUrlConst';
import * as Sentry from "@sentry/browser";
import { ITrackEventData } from '../constants/SentryConst';
import { getUserUUIDFromStorage } from './commonUtils';

export const sendCallTrackEvent = async (eventData: ITrackEventData) => {
  const getUserUuid = await getUserUUIDFromStorage();
  const event_name = eventData.eventType;
  const level = 'log';
  const timestamp = Date.now();
  const environment = getEnvVariable();

  const event: Sentry.Event = {
    message: event_name,
    level: level,
    timestamp: timestamp,
    user: {
      id: getUserUuid,
    },
    environment: environment === '' ? 'Production' : environment,
    extra: {
      event_type: event_name,
      call_sid: eventData?.callSid || '',
      date_time: new Date().toISOString(),
      errorCode: eventData?.errorCode || '',
      errorMessage: eventData?.errorMessage || '',
    },
  };
  callSentryCaptureEvent(event);
};

export const callSentryCaptureEvent = async (event: Sentry.Event) => {
  captureEventAsync(event)
    .then((eventId) => {
    })
    .catch((error) => {
    });
};

export const captureEventAsync = (event: Sentry.Event) => {
  return new Promise((resolve, reject) => {
    try {
      const eventId = Sentry.captureEvent(event);
      resolve(eventId);
    } catch (error) {
      reject(error);
    }
  });
};

export const captureError = (error: any, errorType: string) => {
  //enable sentry
  Sentry.captureException(error, (scope) => {
    scope.setExtras({
      errorType: errorType,
    });
    return scope;
  });
};
