import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {Button, Card, Checkbox, Input, Select, Table} from 'antd';
import React, {FC, useEffect, useState} from 'react';
import OpportunityAssociationModal from '../OpportunityAssociationModal';
import OpportunityAutomationTabs from '../OpportunityAutomationTabs';
import './style.css';
import '../../index.css';
import LocalStorage from '../../../../../utils/LocalStorage';
interface AutomationProps {
  setModalStep?: any;
  isVisible?: boolean;
  onClose?: any;
  onOkClick: any;
  onCancelClick?: any;
  modalStep?: any;
}

const OpportunityAutomation: FC<AutomationProps> = ({
  setModalStep,
  isVisible = true,
  onClose,
  onOkClick,
  onCancelClick,
  modalStep,
}) => {
  const [newOpportunity, setNewOpportunity] = useState<any>({
    isLoading: false,
    data: null,
    error: null,
    form: {
      name: '',
      goals: [
        {
          key: 'BMI',
          title: 'BMI',
          description: 'Body Mass Index',
          operators: ['Below', 'Above', 'Equal'],
          operator: 'Below',
          operatorValue: 0,
          matchValue: true,
          default: 'Below',
          vital: 'BMI',
          measure: 'kg/m2',
        },
        {
          key: 'HDL Female',
          title: 'HDL Female',
          description: 'High-density Lipoprotein (Female)',
          operators: ['Above', 'Below', 'Equal'],
          operator: 'Below',
          operatorValue: 0,
          matchValue: true,
          default: 'Below',
          vital: 'HDL',
          measure: 'mg/dL',
        },
        {
          key: 'HDL Male',
          title: 'HDL Male',
          description: 'High-density Lipoprotein (Male)',
          operators: ['Above', 'Below', 'Equal'],
          operator: 'Below',
          operatorValue: 0,
          matchValue: true,
          default: 'Below',
          vital: 'HDL',
          measure: 'mg/dL',
        },
        {
          key: 'Glucose',
          title: 'Blood Glucose',
          operators: ['Below', 'Above', 'Equal'],
          operator: 'Below',
          operatorValue: 0,
          matchValue: true,
          default: 'Below',
          vital: 'Glucose',
          measure: 'mg/dL',
        },
        {
          key: 'Triglycerides',
          title: 'Triglycerides',
          operators: ['Below', 'Above', 'Equal'],
          operator: 'Below',
          operatorValue: 0,
          matchValue: true,
          default: 'Below',
          vital: 'Triglycerides',
          measure: 'mg/dL',
        },
      ],
    },
    table: {
      selectedRows: [],
    },
  });
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 0,
    total: 0,
    limit: 10,
  });

  const setSelectedRows = (newRows: any[]) => {
    setNewOpportunity({
      ...newOpportunity,
      form: {
        ...newOpportunity.form,
        goals: newOpportunity.form.goals.map((goal: any) => {
          return newRows.find((nr) => nr.vital === goal.vital)
            ? {...goal, matchValue: true}
            : {...goal, matchValue: false};
        }),
      },
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], cSelectedRows: any[]) => {
      setSelectedRows(cSelectedRows);
    },
  };

  const handleRowClick = (params: any) => {
    // alert("To be implemented.");
  };

  // const fetchGoals = async () => {
  //   try {
  //     const req = await fetch('https://dev.foldhealth.io/opportunities/api/fhir')
  //     const res = await req.json()

  //     pushGoal(res.observations.filter((obs: any) => (
  //       obs.code.coding.find((cd: any) => cd.display === "BMI") ||
  //       obs.code.coding.find((cd: any) => cd.display === "Glucose")
  //     )))
  //   } catch (error: any) {
  //   }
  // }

  // useEffect(() => {
  //   fetchGoals()
  // }, [])

  const pushGoal = (goal: any) => {
    setNewOpportunity({
      ...newOpportunity,
      form: {
        ...newOpportunity.form,
        goals: newOpportunity.form.goals.concat(
          Array.isArray(goal) ? goal : [goal]
        ),
      },
    });
  };

  const handleAddGoal = () => {
    pushGoal([
      {
        key: 'title',
        title: 'BMI',
        description: 'BMI',
        operators: ['Below', 'Above', 'Equal'],
      },
    ]);
  };

  const handleUpdateField = (record: any, value: string, field: string) => {
    const updatedOpp = {
      ...newOpportunity,
      form: {
        ...newOpportunity.form,
        goals: newOpportunity.form.goals.map((goal: any) => {
          return goal.key == record.key ? {...goal, [field]: value} : goal;
        }),
      },
    };

    setNewOpportunity(updatedOpp);
  };

  const getHeaders = async () => {
    const userDataString = await LocalStorage.getItem('user');
    const userData = JSON.parse(userDataString);

    const responseAccessToken = userData?.accessToken || '';
    const fold_access_token = userData?.fold_access_token;

    const headersObj: any = {
      'access-token': responseAccessToken,
      fold_access_token,
    };

    return headersObj;
  };

  const handleSaveAutomation = async () => {
    try {
      const authHeaders = await getHeaders();
      const req = await fetch(
        'https://dev.foldhealth.io/opportunities/automation/quickstart',
        {
          method: 'POST',
          body: JSON.stringify({
            criteria: newOpportunity.form.goals
              .filter((v: any) => v.matchValue)
              .map((goal: any) => ({
                operator: goal.operator,
                operatorValue: goal.operatorValue,
                vital: goal.vital || 'VITAL_NOT_SET',
                matchValue: goal.matchValue || 0,
              })),
          }),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...authHeaders,
          },
        }
      );
      const res = await req.json();

      setModalStep({
        ...modalStep,
        step: 'AUTOMATE_RESULTS',
        opportunity: res.opportunity,
        automation: res.automation,
      });
    } catch (error: any) {
    }
  };

  return (
    <div className="automationModalContainer" style={{width: '100%'}}>
      <OpportunityAssociationModal
        className="automationModalContainer"
        width={916}
        title={
          <div
            style={{
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexDirection: 'column',
              width: '100%',
              display: 'flex',
              paddingTop: '1rem',
            }}
          >
            <h1 className="text-[24px] capitalize">Opportunity Automation</h1>
            <p>
              Set data entries to enable automatic scanning of Opportunities
            </p>
          </div>
        }
        isVisible={isVisible}
        onClose={() => onClose()}
        onOkClick={() => handleSaveAutomation()}
      >
        <OpportunityAutomationTabs />
        <div className="flex flex-col items-start justify-start w-full gap-6">
          <Card style={{width: '100%'}}>
            <Table
              rowKey="key"
              className="w-full"
              dataSource={newOpportunity.form.goals}
              columns={[
                {
                  title: 'Criteria',
                  dataIndex: 'criteria',
                  key: 'criteria',
                  render: (text: string, record: any) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <span>{record.title}</span>
                        <span style={{color: '#667085'}}>
                          {record.description}
                        </span>
                      </div>
                    );
                  },
                },
                {
                  title: 'Operator',
                  dataIndex: 'operator',
                  key: 'operator',
                  render: (text: string, record: any) => {
                    return (
                      <Select
                        className="customAutomationSelect"
                        // defaultValue={record.default}
                        value={record.operator}
                        style={{
                          width: '100%',
                          display: 'inline-block',
                          height: '36px',
                        }}
                        onChange={(e) =>
                          handleUpdateField(record, e, 'operator')
                        }
                      >
                        {record.operators.map((operator: string) => (
                          <Select.Option
                            {...(record.operatorValue == operator
                              ? {selected: true}
                              : {})}
                            value={operator}
                            key={operator}
                          >
                            {operator}
                          </Select.Option>
                        ))}
                      </Select>
                    );
                  },
                },
                {
                  title: 'Value',
                  dataIndex: 'value',
                  key: 'value',
                  render: (text: string, record: any) => {
                    return (
                      <Input
                        className="customAutomationInput"
                        type="number"
                        suffix={<span>{record.measure}</span>}
                        value={record.operatorValue}
                        defaultValue={record.operatorValue}
                        onChange={(e) =>
                          handleUpdateField(
                            record,
                            e.target.value,
                            'operatorValue'
                          )
                        }
                      />
                    );
                  },
                },
              ]}
              loading={newOpportunity.isLoading}
              rowSelection={{type: 'checkbox', ...rowSelection}}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    handleRowClick(record.key);
                  }, // click row
                };
              }}
              pagination={false}
            />
          </Card>
        </div>
        {/* <div className="mt-8">
          <Button
            onClick={handleAddGoal}
            shape="round"
            type="default"
            className="primaryDefaultBtn"
          >
            Add new goal
          </Button>
        </div> */}
      </OpportunityAssociationModal>
    </div>
  );
};

export default OpportunityAutomation;
