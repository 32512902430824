import {orderBy} from "lodash";

export const updateQtyInSection = (dataList: any, qtyDataList: any) => {
  let finalList = [...dataList];
  if (dataList?.length && qtyDataList?.length) {
    dataList.forEach((singleSectionItem: any) => {
      const singleQtrDataItem = qtyDataList.find((singleQtyItem: any) => {
        return singleQtyItem.id === singleSectionItem.id;
      });
      singleSectionItem.key = singleSectionItem.id;
      if (singleQtrDataItem?.id) {
        singleSectionItem.quantity = singleQtrDataItem.quantity || 1;
      }
    });
    finalList = orderBy(dataList, ['quantity'], ['desc']);
  }
  return finalList
}

export const formatLabCostSavingResp = (labDataList: any) => {
  const dataList = labDataList?.labCostDatalist;
  let finalList;
  if (dataList) {
    finalList = [...dataList];
    dataList?.forEach((singleSectionItem: any) => {
      singleSectionItem?.accountPrescribedLabs?.forEach((accountPrescribedLab: any) => {
        accountPrescribedLab['prescribedLabCost'] = accountPrescribedLab?.prescribedLabCosts[0];
        accountPrescribedLab['currentMarketPrice'] = accountPrescribedLab?.prescribedLabCosts[0]?.currentMarketPrice;
      });
      if (singleSectionItem?.accountPrescribedLabs?.length) {
        singleSectionItem.quantity = singleSectionItem?.accountPrescribedLabs[0].totalQuantity;
      } else {
        singleSectionItem.quantity = 1;
      }
      singleSectionItem.key = singleSectionItem.id;
    });
    finalList = orderBy(dataList, ['quantity'], ['desc']);
  }
  return finalList;
}

export const formatLabCostSavingFormAPIResp = (labDataList: any) => {
  const dataList = labDataList;
  let finalList;
  if (dataList) {
    finalList = [...dataList];
    dataList?.forEach((singleSectionItem: any) => {
      singleSectionItem?.accountPrescribedLabs?.forEach((accountPrescribedLab: any) => {
        accountPrescribedLab['prescribedLabCost'] = accountPrescribedLab?.prescribedLabCosts[0];
        accountPrescribedLab['currentMarketPrice'] = accountPrescribedLab?.prescribedLabCosts[0]?.currentMarketPrice;
      });
      if (singleSectionItem?.accountPrescribedLabs?.length) {
        singleSectionItem.quantity = singleSectionItem?.accountPrescribedLabs[0].totalQuantity;
      } else {
        singleSectionItem.quantity = 1;
      }
      singleSectionItem.key = singleSectionItem.id;
    });
    finalList = orderBy(dataList, ['quantity'], ['desc']);
  }
  return finalList;
}
