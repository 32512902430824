export interface IAddCustomContactTypeModalProps {
  isModalOpen: boolean;
  onModalClose: () => void;
  onSave: () => void;
  isEdit?: boolean;
  contactTypeData?: any;
  contactTypeList?: ICustomContactTypesTableData[];
}

export interface IAddCustomContactTypeState {
  newCustomContactTypeStatus: string;
  newCustomContactTypeName: string;
  newCustomContactTypeDescription: string;
  loading?: boolean;
  isDefault?: boolean;
  code?: string;
  id?: string;
  convertToPatientStatus?: boolean;
}

export enum AddCustomContactTypeStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export interface ICustomContactTypeStateData {
  data: ICustomContactTypesTableData[];
  total: undefined | number;
  loading: boolean;
}

export interface ICustomContactTypesTableData {
  id: string;
  name: string;
  description?: string;
  status: string;
  isDefault: boolean;
  contactCount: number;
  canConvertToPatient?: boolean;
}

export interface ICustomContactTypesTableProps {
  data: ICustomContactTypesTableData[];
  onEdit: (contactType: ICustomContactTypesTableData) => void;
  total?: number;
}

export interface ICustomContactTypesTableAction {
  record: ICustomContactTypesTableData;
  onActionPerformed: (
    actionCode: string,
    recordData: ICustomContactTypesTableData
  ) => {};
}
