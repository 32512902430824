import { Collapse } from 'antd';
import { HStack, Skeleton, Spacer, Text, View, VStack } from 'native-base';
import { useEffect, useState, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { IVitalData } from '../../../../../../Interfaces';
import { getVitals } from '../../../../../../services/CommonService/AidBoxService';
import { Colors } from '../../../../../../styles';
import { getVitalUnitDisplay, Vital } from '../../../../../../utils/VitalUtils';
import { DualAxisLineGraph } from '../../../../../common/Graph/DualAxisLineGraph';
import { LineGraph } from '../../../../../common/Graph/LineGraph';
import { IObservation } from '../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/interfaces';
import { IPAMIProps, IVitals } from '../../interfaces';
import { getFormattedVitalData } from '../DetailTableUtils';
import {
  getBloodPressureGraphData,
  getHeartRateAndRespiratoryRateGraphData,
  getVitalGraphData,
  getWeightAndBMIGraphData,
} from './VitalsGraphUtils';

interface IVitalGraphViewProps extends IPAMIProps {
  existingData?: IObservation[];
  vitalList: IVitalData[];
  accountLocationUuid?: string;
  isCheckInVitalList?: boolean;
  startDate?: string;
  endDate?: string;
  carePlanStartDate: string;
  isExpandAll: boolean;
  setVitalDisplayInfo(input: {
    numberOfRowDisplayed: number;
    isLoading: boolean;
  }): void;
}

const CareJourneyAnalyticVitalsGraphView = (props: IVitalGraphViewProps) => {
  const carePlanStartDate = new Date(props.carePlanStartDate);
  const {patientId: patientUuid, vitalList, accountLocationUuid} = props;
  const [vitalData, setVitalData] = useState<IVitals[]>([]);
  const [loading, setLoading] = useState(false);
  const [allowedLoinc] = useState(
    (vitalList || []).map((vital) => {
      return vital.loinc;
    })
  );

  const getBeforeAndAfterCarePlanResult = (results: any) => {
    let beforeCarePlanResult: any;
    let afterCarePlanResult: any;
    let lastSelectedDateForBeforeCarePlan: any;
    let lastSelectedDateForAfterCarePlan: any;
    results.forEach((result: any) => {
      if (result?.date) {
        const issuedDate = new Date(result?.date);
        if (
          carePlanStartDate > issuedDate &&
          (!lastSelectedDateForBeforeCarePlan ||
            lastSelectedDateForBeforeCarePlan < issuedDate)
        ) {
          lastSelectedDateForBeforeCarePlan = issuedDate;
          beforeCarePlanResult = result;
        }
        if (
          !lastSelectedDateForAfterCarePlan ||
          lastSelectedDateForAfterCarePlan < issuedDate
        ) {
          lastSelectedDateForAfterCarePlan = issuedDate;
          afterCarePlanResult = result;
        }
      }
    });
    return {afterCarePlanResult, beforeCarePlanResult};
  };
  const headCircumferenceGraphData = getVitalGraphData(
    Vital.headCircumference,
    vitalData,
    vitalList
  );
  const bodyTemperatureGraphData = getVitalGraphData(
    Vital.temperature,
    vitalData,
    vitalList
  );
  const waistCircumferenceGraphData = getVitalGraphData(
    Vital.waistCircumference,
    vitalData,
    vitalList
  );
  const heightGraphData = getVitalGraphData(Vital.height, vitalData, vitalList);
  const weightGraphData = getVitalGraphData(Vital.weight, vitalData, vitalList);
  const oxygenSaturationGraphData = getVitalGraphData(
    Vital.oxygenSaturationByPulseOximetry,
    vitalData,
    vitalList
  );
  const painScaleGraphData = getVitalGraphData(
    Vital.painScale,
    vitalData,
    vitalList
  );
  const bloodPressureGraphData = getBloodPressureGraphData(
    vitalData,
    vitalList
  );
  const weightAndBmiGraphData = getWeightAndBMIGraphData(vitalData, vitalList);
  const heartRateAndRespiratoryRateGraphData =
    getHeartRateAndRespiratoryRateGraphData(vitalData, vitalList);

  const CollapseVitalView = (props: {
    display: string;
    isExpandAll: boolean;
    beforeValue: string | number;
    beforeUnit: string;
    afterValue: string | number;
    afterUnit: string;
    childComponent: any;
  }) => {
    const [selectedIndexList, setSelectedIndexList] = useState<Array<string>>(
      []
    );

    useEffect(() => {
      if (props.isExpandAll) {
        setSelectedIndexList(['1']);
      } else {
        setSelectedIndexList([]);
      }
    }, [props.isExpandAll]);

    const antdStyles: Record<string, React.CSSProperties> = {
      collapse: {
        marginTop: 10,
      },
      collapsePanel: {
        padding: '5px',
      },
    };

    return (
      <div className="custom-lab-track-collapse">
        <Collapse
          key={selectedIndexList?.length}
          defaultActiveKey={selectedIndexList}
          style={antdStyles.collapse}
        >
          <Collapse.Panel
            style={antdStyles.collapsePanel}
            header={
              <HStack height={'full'} alignItems={'center'}>
                <HStack height={'full'} alignItems={'center'}>
                  <Text style={styles.displayText}>
                    {props?.display}
                  </Text>
                </HStack>
                <Spacer></Spacer>
                <HStack space={4}>
                  <VStack>
                    <Text style={styles.beforeAfterText}>
                      Before Care Journey
                    </Text>
                    <Text style={styles.beforeAfterValue}>
                      {(props?.beforeValue || '-') +
                        ' ' +
                        ( props?.beforeValue ? props?.beforeUnit || '' : '')}
                    </Text>
                  </VStack>
                  <VStack>
                    <Text style={styles.beforeAfterText}>
                      Last Measured
                    </Text>
                    <Text style={styles.beforeAfterValue}>
                      {(props?.afterValue || '-') +
                        ' ' +
                        (props?.afterValue  ? props?.afterUnit  || '' : '')}
                    </Text>
                  </VStack>
                </HStack>
              </HStack>
            }
            key="1"
          >
            <HStack
              justifyContent={'center'}
              alignItems={'center'}
              width={'full'}
            >
              {props.childComponent}
            </HStack>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  };

  const responseHandler = (response: any) => {
    setLoading(false);
    let data = response?.data?.entry || [];
    data = data.map((item: any) => item.resource);
    const listData = getFormattedVitalData(
      data,
      vitalList,
      props.startDate,
      props.endDate
    );
    setVitalData(listData);
  };
  const errorHandler = (error: any) => {
    setLoading(false);
  };

  useEffect(() => {
    if (props.existingData?.length) {
      const listData = getFormattedVitalData(props.existingData, vitalList);
      setVitalData(listData);
    } else if (patientUuid) {
      setLoading(true);
      getVitals(
        patientUuid,
        (response: any) => responseHandler(response),
        (error: any) => errorHandler(error),
        accountLocationUuid
      );
    }
  }, []);

  const  getInitials = (inputString: string): string => {

    if(!inputString){
      return '';
    }
    if(inputString.toLowerCase() == 'bmi'){
      return inputString;
    }
    const words = inputString.split(/\s+/); // Split the input string into words
    const initials = words.map(word => word.charAt(0).toUpperCase()); // Get the first letter of each word and capitalize it
    return initials.join(''); // Join the initials to form the short form
  }

  useEffect(() => {
    props.setVitalDisplayInfo({
      numberOfRowDisplayed: (vitalData || []).filter((vital) => {
        return allowedLoinc.includes(vital.text);
      })?.length,
      isLoading: loading,
    });
  }, [vitalData?.length, loading]);

  let isShowBloodPressure = false;
  if (props.isCheckInVitalList) {
    isShowBloodPressure = (props.vitalList || []).some((vitalConfig) => {
      return vitalConfig.loinc == Vital?.bloodPressure;
    });
  }
  const getBeforeAfterValueForDualAxisGraph = (vitalGraphData: any) => {
    const resultForAxis1 = getBeforeAndAfterCarePlanResult(
      vitalGraphData?.graphData?.data?.[0] || []
    );
    const resultForAxis2 = getBeforeAndAfterCarePlanResult(
      vitalGraphData?.graphData?.data?.[1] || []
    );
    const unit1 = vitalGraphData?.graphData?.yAxis?.[vitalGraphData?.graphData?.yField[0]]?.title?.unit;
    const unit2 = vitalGraphData?.graphData?.yAxis?.[vitalGraphData?.graphData?.yField[1]]?.title?.unit;
    const beforeValueAxis1 = resultForAxis1?.beforeCarePlanResult?.label
      ? getInitials(resultForAxis1?.beforeCarePlanResult?.label) +
        ':' +
        (resultForAxis1?.beforeCarePlanResult?.[
          resultForAxis1?.beforeCarePlanResult?.label
        ] || '')
      : '';
    const beforeValueAxis2 = resultForAxis2?.beforeCarePlanResult?.label
      ? getInitials(resultForAxis2?.beforeCarePlanResult?.label) +
        ':' +
        (resultForAxis2?.beforeCarePlanResult?.[
          resultForAxis2?.beforeCarePlanResult?.label
        ] || '')
      : '';

    const afterValueAxis1 = resultForAxis1?.afterCarePlanResult?.label
      ? getInitials(resultForAxis1?.afterCarePlanResult?.label) +
        ':' +
        (resultForAxis1?.afterCarePlanResult?.[
          resultForAxis1?.afterCarePlanResult?.label
        ] || '')
      : '';
    const afterValueAxis2 = resultForAxis2?.afterCarePlanResult?.label
      ? getInitials(resultForAxis2?.afterCarePlanResult?.label) +
        ':' +
        (resultForAxis2?.afterCarePlanResult?.[
          resultForAxis2?.afterCarePlanResult?.label
        ] || '')
      : '';

    // const unit =  getVitalUnitDisplay(vitalGraph?.loinc, vitalList);

    return {
      before:
        beforeValueAxis1 && beforeValueAxis2
          ? beforeValueAxis1 + (unit1 ? ' '+ unit1 :'') + '\n' + beforeValueAxis2 +  (unit2 ? ' '+ unit2 :'')
          : beforeValueAxis1
          ? beforeValueAxis1 + (unit1 ? ' '+ unit1 :'')
          : beforeValueAxis2 ? (unit2 ? ' '+ unit2 :'')  : '',
      after:
      afterValueAxis1 && afterValueAxis2
      ? afterValueAxis1 + (unit1 ? ' '+ unit1 :'') + '\n' + afterValueAxis2 +  (unit2 ? ' '+ unit2 :'')
      : afterValueAxis1
      ? afterValueAxis1 + (unit1 ? ' '+ unit1 :'')
      : afterValueAxis2 ? (unit2 ? ' '+ unit2 :'')  : '',
    };
  };

  const getBeforeAfterValueForBloodPressureGraph = (vitalGraphData: any) => {
    const uniqueLabelList:any =  []
    vitalGraphData?.graphData?.data?.forEach((data:any)=>{
      if(data?.label && !uniqueLabelList.includes(data?.label)){
        uniqueLabelList.push(data?.label);
      }
    });
    const list1:any = [], list2:any = [];
    vitalGraphData?.graphData?.data?.forEach((data:any)=>{
      if(uniqueLabelList[0] && data?.label  == uniqueLabelList[0]){
        list1.push(data);
      }
      if(uniqueLabelList[1] && data?.label  == uniqueLabelList[1]){
        list2.push(data);
      }
    });
    const resultForAxis1 = getBeforeAndAfterCarePlanResult(
      list1 || []
    );
    const resultForAxis2 = getBeforeAndAfterCarePlanResult(
      list2 || []
    );
    const beforeValueAxis1 = (resultForAxis1?.beforeCarePlanResult?.value || '')
    const beforeValueAxis2 = (resultForAxis2?.beforeCarePlanResult?.value || '');

    const afterValueAxis1 = (resultForAxis1?.afterCarePlanResult?.value || '')
    const afterValueAxis2 =  (resultForAxis2?.afterCarePlanResult?.value || '')
    return {
      before: beforeValueAxis1 && beforeValueAxis2 ? beforeValueAxis1 + '/' + beforeValueAxis2   : '',
      after: afterValueAxis1 && afterValueAxis2 ? afterValueAxis1 + '/' + afterValueAxis2   : '',
    };
  };

  const getBeforeAfterValueForLineGraph = (vitalGraphData: any) => {
    const resultForAxis1 = getBeforeAndAfterCarePlanResult(
      vitalGraphData?.graphData?.data || []
    );
    const beforeValueAxis1 = resultForAxis1?.beforeCarePlanResult?.value || '';
    const afterValueAxis1 = resultForAxis1?.afterCarePlanResult?.value || ''
    return {
      before: beforeValueAxis1,
      after: afterValueAxis1,
    };
  };

  let bloodPressureBeforeAfterDate;
  if (!bloodPressureGraphData.isEmpty && isShowBloodPressure) {
    bloodPressureBeforeAfterDate = getBeforeAfterValueForBloodPressureGraph(
      bloodPressureGraphData
    );
  }
  const bloodPressure = getVitalUnitDisplay(Vital.bloodPressure, vitalList);
  return (
    <View flex={1} width={'full'}>
      {loading ? (
        <Skeleton.Text lines={5} />
      ) : (
        <VStack flex={1} width={'full'}>
          {!bloodPressureGraphData.isEmpty && isShowBloodPressure && (
            <CollapseVitalView
              display={bloodPressureGraphData.title}
              beforeValue={bloodPressureBeforeAfterDate?.before || ''}
              beforeUnit={bloodPressure}
              afterUnit={bloodPressure}
              isExpandAll={props.isExpandAll}
              afterValue={bloodPressureBeforeAfterDate?.after || ''}
              childComponent={
                <View style={styles.graphContainer2}
                  width={'full'}
                  alignItems={'start'}
                  justifyContent={'start'}
                >
                  <LineGraph
                    {...bloodPressureGraphData.graphData}
                    style={styles.graph}
                  />
                </View>
              }
            ></CollapseVitalView>
          )}

          {[weightAndBmiGraphData, heartRateAndRespiratoryRateGraphData].map(
            (vitalGraph) => {
              let isShowGraph = false;
              if (props.isCheckInVitalList) {
                isShowGraph = (props.vitalList || []).some((vitalConfig) => {
                  return (
                    vitalConfig.loinc == vitalGraph?.loinc ||
                    (vitalGraph.loinc === Vital.respirationRate &&
                      vitalConfig.loinc === Vital.heartRate)
                  );
                });
              }
              if (vitalGraph.isEmpty || !isShowGraph) return <></>;
              const beforeAfterValue =
                getBeforeAfterValueForDualAxisGraph(vitalGraph);

              return (
                <CollapseVitalView
                  display={vitalGraph.title}
                  beforeValue={beforeAfterValue.before}
                  beforeUnit={''}
                  afterUnit={''}
                  isExpandAll={props.isExpandAll}
                  afterValue={beforeAfterValue.after}
                  childComponent={
                    <View
                      style={styles.graphContainer2}
                      width={'full'}
                      alignItems={'start'}
                      justifyContent={'start'}
                    >
                      <DualAxisLineGraph
                        {...vitalGraph.graphData}
                        style={styles.graph}
                      />
                    </View>
                  }
                ></CollapseVitalView>
              );
            }
          )}

          {[
            painScaleGraphData,
            bodyTemperatureGraphData,
            waistCircumferenceGraphData,
            heightGraphData,
            weightGraphData,
            oxygenSaturationGraphData,
            headCircumferenceGraphData,
          ].map((vitalGraph) => {
            let isShowGraph = false;
            if (props.isCheckInVitalList) {
              isShowGraph = (props.vitalList || []).some((vitalConfig) => {
                return vitalConfig.loinc == vitalGraph?.loinc;
              });
            }
            if (vitalGraph.isEmpty || !isShowGraph) return <></>;
            const beforeAfterValue = getBeforeAfterValueForLineGraph(vitalGraph);
            const unit = getVitalUnitDisplay(vitalGraph.loinc, vitalList);
            return (
              <CollapseVitalView
                display={vitalGraph.title}
                beforeValue={beforeAfterValue.before}
                beforeUnit={unit}
                afterUnit={unit}
                isExpandAll={props.isExpandAll}
                afterValue={beforeAfterValue.after}
                childComponent={
                  <View
                    style={styles.graphContainer2}
                    width={'full'}
                    alignItems={'start'}
                    justifyContent={'start'}
                  >
                    <LineGraph
                      {...vitalGraph.graphData}
                      style={styles.graph}
                    />
                  </View>
                }
              ></CollapseVitalView>
            );
          })}
        </VStack>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  displayText: {
    color: Colors.Custom.Gray600,
    fontSize: 16,
    fontWeight: '600',
    lineHeight: 14.4,
  },
  beforeAfterText: {
    fontSize: 14,
    color: Colors.Custom.Gray400,
  },
  beforeAfterValue: {
    fontSize: 16,
    color: Colors.Custom.Gray300,
  },
  graphContainer: {
    padding: 15,
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  graphContainer2: {
    padding: 15
  },
  graph: {
    maxHeight: 200,
    width: '100%',
  },
});

export default CareJourneyAnalyticVitalsGraphView;
