import {Card, Table, Input, Button, Menu, Dropdown} from 'antd';
import React from 'react';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FilterFilled,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import SingularActionsDropdownMenu from '../SingularActions';

export const AnalyticsTableOptions = {
  dataSource: [
    {
      key: '62c9e00c5c3d618b7407e525',
      name: 'Suggested Opportunity',
      author: 'None',
      source: 'ADP',
      members: 42,
      careJourney: 'Assign',
      remainingTime: 'None',
      creationDate: new Date(),
      favorite: true,
    },
    {
      key: '62c9e014ce493db5f597e466',
      name: 'Suggested Opportunity',
      author: 'None',
      source: 'Goals',
      members: 142,
      careJourney: 'Assign',
      remainingTime: 'None',
      creationDate: new Date(),
      favorite: false,
    },
  ],
  columns: [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
    },
    {
      title: 'Source',
      dataIndex: 'source',
      // key: "source",
      render: (text: string, _record: any) => {
        return (
          <Button size="small" type="default" icon={<SettingOutlined />}>
            {text}
          </Button>
        );
      },
    },
    {
      title: 'Members',
      dataIndex: 'members',
      key: 'members',
    },
    {
      title: 'Care Journey',
      dataIndex: 'careJourney',
      key: 'careJourney',
    },
    {
      title: 'Remaining Time',
      dataIndex: 'remainingTime',
      key: 'remainingTime',
    },
    {
      title: 'Creation Date',
      dataIndex: 'creationDate',
      key: 'creationDate',
      render: (text: string, record: any) => {
        return <span>{record.creationDate.toDateString()}</span>;
      },
    },
    {
      title: 'Favorite',
      dataIndex: 'favorite',
      key: 'favorite',
      render: (text: string, record: any) => {
        return (
          <Button
            className="btnFavorite"
            shape="circle"
            type="text"
            icon={record.favorite ? <StarFilled /> : <StarOutlined />}
          />
        );
      },
    },
    {
      render: () => {
        return (
          <Dropdown
            overlay={SingularActionsDropdownMenu}
            placement="bottomRight"
          >
            <Button
              className="btnMoreOptions"
              shape="circle"
              type="text"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        );
      },
    },
  ],
};

export const DetailsMembers = {
  dataSource: [],
  columns: [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Performance',
      dataIndex: 'performance',
      key: 'performance',
    },
    {
      title: 'Membership',
      dataIndex: 'membership',
      key: 'membership',
    },
    {
      title: 'Patient Risk',
      dataIndex: 'patientRisk',
      key: 'patientRisk',
    },
    {
      title: 'BMI (kg/m2)',
      dataIndex: 'bmi',
      key: 'bmi',
    },
    {
      title: 'HDL (mg/dL)',
      dataIndex: 'hdl',
      key: 'hdl',
    },
    {
      title: 'Glucose (mg/dL)',
      dataIndex: 'glucose',
      key: 'glucose',
    },
  ],
};
