import {HStack, View} from 'native-base';
import React from 'react';
import {
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd';
// import { useIntl } from 'react-intl';
import {ITaxonsDisplayList} from '../Interface';
import TaxonListItemView from '../TaxonListItemView/TaxonListItemView';
import TaxonsDnDContext from '../TaxonsDnDContext/TaxonsDnDContext';
const TaxonDndDroppable = (props: any) => {
  const {taxon} = props;
  //   const intl = useIntl();
  return (
    <>
      <Droppable
        droppableId={
          taxon.parentId
        }
        isDropDisabled={props.isDropDisabled}
      >
        {(
          provided: DroppableProvided
          // snapshot: DroppableStateSnapshot
        ) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {taxon &&
              taxon.children &&
              taxon.children.length > 0 &&
              taxon.children.map((taxons: ITaxonsDisplayList, indexs: any) => {
                const key = taxons.id
                return (
                  <View key={key} flex={1}>
                    <Draggable key={key} draggableId={key} index={indexs}>
                      {(provide: DraggableProvided) => (
                        <div
                          {...provide.draggableProps}
                          {...provide.dragHandleProps}
                          ref={provide.innerRef}
                        >
                          <TaxonListItemView
                            index={indexs}
                            key={indexs}
                            taxon={taxons}
                            onActionPerformed={(actionCode:any, rawData:any)=> {
                               props.onActionPerformed(actionCode,rawData);
                              }}
                          />
                        </div>
                      )}
                    </Draggable>
                    { false && taxons.children && taxons.children.length > 0 ? (
                      <HStack
                        justifyContent={'flex-end'}
                        borderColor={'red.100'}
                        borderWidth={2}
                      >
                        <View flex={1}>
                          <TaxonsDnDContext onActionPerformed={props.onActionPerFormed} taxon={taxons} />
                          {/* <TaxonDndDroppable taxon={taxons.children} /> */}
                        </View>
                      </HStack>
                    ) : (
                      <></>
                    )}
                  </View>
                );
              })}
            {/* {taxon.children.length === 0 && !snapshot.isDraggingOver && (
              <Box padding={4} marginTop={100}>
                <Text
                  fontWeight="semiBold"
                  fontSize="md"
                  color="gray.400"
                  marginLeft={-2}
                  marginTop={2}
                >
                  {props.showNoDataMessage &&
                    intl.formatMessage({id: 'kanBanNoDataMsg'})}
                </Text>
              </Box>
            )} */}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};
export default TaxonDndDroppable;
