import { DiagnosticReport, DocumentReferenceContent, Extension, MedicationRequest, RequestGroup } from "fhir/r4";
import { DATE_FORMATS } from "../../../../../constants";
import { getCurrentSubdomain } from "../../../../../screens/MainScreen/MainScreenHelper";
import { addOrUpdateOrder } from "../../../../../services/CommonService/OrderService";
import { toBase64 } from "../../../../../utils/commonUtils";
import { getDateStrFromFormat, getMomentObj } from "../../../../../utils/DateUtils";
import { getMedicationName } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Medications/AddOrUpdateMedications/AddOrUpdateMedicationsHelper";
import { IReportAndResourceMap, IRequestGroup } from "../../../LeftContainer/RecentActivity/OrdersInterface";
import { getReviewedByReportDetails, isAllReportViewedBy } from "../../../LeftContainer/RecentActivity/RecentReport/ReportUtils";
import { EXTENSION_URLS } from "../../PatientNotes/components/AddOrUpdateTemplate/constant";
import { OrderStatus } from "./AddOrUpdateExternalOrders/Helper";
import { OrderType } from "./OrderConstants";

export enum ReportCategory {
  LAB = 'LAB',
  RAD = 'RAD',
  IMAGING = 'IMAGING'
}

export const ORDER_SOURCE = {
  FOLD: 'FOLD',
}

export const getOrderType = (orderType: OrderType) => {
  switch (orderType) {
    case OrderType.LAB: return 'LAB';
    case OrderType.RAD: return 'RADIOLOGY';
    case OrderType.MED: return 'MEDICATION';
  }
}

export const getOrderTypeFromCode = (code: string) => {
  switch (code) {
    case 'LAB': return OrderType.LAB;
    case 'RADIOLOGY': return OrderType.RAD;
    case 'MEDICATION': return OrderType.MED;
    default: break;
  }
}

export const getPriority = (priority:string) => {
  switch (priority) {
    case 'stat':
      return 'STAT';
    default:
      return priority;
  }
}

export const getContainedNotes = (data: RequestGroup, id?: string) => {
  if (!id) {
    return '';
  }
  const serviceRequestId = id.split('/')[1];
  const noteList = data.contained?.map((item: any) => {
    if(item.id === serviceRequestId && item.note){
      return  item.note?.map((note: any) => note.text);
    }
  });
  return noteList?.filter((item) => !!item).join(', ');
}

export const getOrdersFormattedForDocRef = (list: IRequestGroup[], orderType: OrderType): DocumentReferenceContent[] => {
  return list.map((order) => {
    const orderName = order.action?.map((action) => action.resource.display).join(', ') || '';
    return {
      attachment: {
        contentType: 'text/plain',
        title: 'Order',
        // hash: 'md5 hash of base64 section content',
        data: orderName ? toBase64(orderName) : orderName,
        extension: [
          {
            url: EXTENSION_URLS.orderId,
            valueString: order.id
          },
          {
            url: EXTENSION_URLS.orderCategory,
            valueString: getOrderType(orderType)
          }
        ]
      }
    }
  });
}

export const getMedOrdersFormattedForDocRef = (list: MedicationRequest[], orderType: OrderType): DocumentReferenceContent[] => {
  return list.map((order) => {
    const orderName = getMedicationOrderName(order);
    return {
      attachment: {
        contentType: 'text/plain',
        title: 'Order',
        hash: 'md5 hash of base64 section content',
        data: orderName ? toBase64(orderName) : orderName,
        extension: [
          {
            url: EXTENSION_URLS.orderId,
            valueString: order.id
          },
          {
            url: EXTENSION_URLS.orderCategory,
            valueString: getOrderType(orderType)
          }
        ]
      }
    }
  });
}

export const getServiceRequestReference = (list: IRequestGroup[]): string[] => {
  const serviceRequests: string[] = [];
  list.forEach((order) => {
    order.action?.forEach((action) => {
      if (action?.resource?.reference) {
        serviceRequests.push(action.resource.reference);
      }
    })
  })
  return serviceRequests;
}

export const getMedicationOrderName = (order: MedicationRequest): string =>  {
  const medicationObject = order.medicationCodeableConcept || order.medicationReference;
  return getMedicationName(medicationObject);
}

export const getReviewedByDetail = (reviewedByUserName?: string, reviewedByDate?: string) => {
  let reviewedByDetails = '';
  if (reviewedByUserName) {
    reviewedByDetails = `Reviewed by ${reviewedByUserName}`;
    if (reviewedByDate) {
      const reviewDate = getDateStrFromFormat(reviewedByDate, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT);
      reviewedByDetails += ' on ' + reviewDate;
    }
  } else if (reviewedByDate) {
    const reviewDate = getDateStrFromFormat(reviewedByDate, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT);
    reviewedByDetails = `Reviewed on ${reviewDate}`
  }
  return reviewedByDetails;
};

export const getOrderName = (order?: RequestGroup) => {
  if (order?.action?.length) {
    const orders: string[] = [];
    order.action.forEach((item) => item.resource?.display && orders.push(item.resource.display));
    return orders.join(', ');
  }
  // If order is not selected then show facility name
  const extension = order?.extension?.find((item) => (item.url === EXTENSION_URLS.performer || item.url === EXTENSION_URLS.requestGroupPerformer));
  if (extension?.valueReference?.display) {
    return extension.valueReference.display;
  }
  if (order?.status == 'draft') {
    return 'No order selected'
  }
  return '';
}

export const getFacilityName = (order?: RequestGroup) => {
  const extension = order?.extension?.find((item) => (item.url === EXTENSION_URLS.performer || item.url === EXTENSION_URLS.requestGroupPerformer));
  if (extension?.valueReference?.display) {
    return extension.valueReference.display;
  }
  return '';
}

export const getOrderSubtitle = (order?: RequestGroup, report?: any): string => {
  const details: string[] = [];
  const firstLine: string[] = [];
  const date = order && getOrderDateTime(order);
  if (order?.status) {
    firstLine.push(getStatusDisplay(order.status));
  }
  if (order?.author?.display) {
    let display = `Added by ${order.author.display}`;
    if (date) {
      display += ' on ' + date;
    }
    firstLine.push(display);
  } else if (date) {
    firstLine.push(`Added on ${date}`);
  }
  details.push(firstLine.join(' • '))
  if (order && report) {
    const diagnosticReport = getReportMatchWithOrder(report, order);
    const reviewedByUserName = getExtensionValue(diagnosticReport, EXTENSION_URLS.reviewedBy);
    const reviewedDate = getExtensionValue(diagnosticReport, EXTENSION_URLS.reviewedDate);
    const reviewedByText = getReviewedByDetail(reviewedByUserName, reviewedDate);
    if (reviewedByText && reviewedByText?.length) {
      details.push(reviewedByText);
    }
  }
  if (!order && report && isAllReportViewedBy(report)) {
    const reviewDetails = getReviewedByReportDetails(report);
    const reviewedByText = getReviewedByDetail(reviewDetails?.reviewedByUserName, reviewDetails?.reviewedDate);
    if (reviewedByText && reviewedByText?.length) {
      details.push(reviewedByText);
    }
  }
  return details.join('\n');
}

export const getAuthorDisplay = (order?: RequestGroup) => {
  const date = order && getOrderDateTime(order);
  if (order?.author?.display) {
    let display = `Added by ${order.author.display}`;
    if (date) {
      display += ` on ${date}`;
    }
    return display;
  } else if (date) {
    return `Added on ${date}`;
  }
}

export const getExtensionValue = (order: MedicationRequest | any, url: string): string | undefined => {
  const statusExtension = order?.extension?.find((item: any) => item?.url === url);
  return statusExtension?.valueString;
}
export const getExtensionValueBoolean = (order: MedicationRequest | any, url: string): boolean | undefined => {
  const statusExtension: Extension = order?.extension?.find((item: Extension) => item?.url === url);
  return statusExtension?.valueBoolean;
}

export const getMedicationSubTitle = (order: MedicationRequest, isShortVersion?: boolean): string => {
  const enteredBy = order?.requester?.display;
  let display = '';

  if (!isShortVersion && order.dispenseRequest) {
    const dispenseRequest = order.dispenseRequest;
    const dispenseDetails: string[] = [];
    if (dispenseRequest.quantity?.value) {
      let dispenseDisplay = `${dispenseRequest.quantity.value}`
      if (dispenseRequest.quantity?.unit) {
        dispenseDisplay += ` ${dispenseRequest.quantity.unit}`
      }
      dispenseDetails.push(dispenseDisplay);
    }
    if (dispenseRequest.expectedSupplyDuration?.value) {
      dispenseDetails.push(`${dispenseRequest.expectedSupplyDuration?.value} day(s) supply`)
    }
    if (dispenseRequest.numberOfRepeatsAllowed) {
      dispenseDetails.push(`${dispenseRequest.numberOfRepeatsAllowed} refill(s)`)
    }

    if (dispenseRequest.validityPeriod?.start) {
      const date = getDateStrFromFormat(dispenseRequest.validityPeriod.start, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT);
      dispenseDetails.push(`${date}`);
    }
    display += dispenseDetails.join(' • ');
  }

  if (!isShortVersion && order.dosageInstruction?.length) {
    order.dosageInstruction.forEach((instruction) => {
      if (instruction.text) {
        display += `\n${instruction.text}`
      }
    })
  }

  const status = getExtensionValue(order, EXTENSION_URLS.externalStatus) || order.status;
  if (status) {
    if (display) display += '\n';
    display += `Prescription Status: ${getPrescriptionStatusDisplay(status)}`
  }
  const medStatus = getExtensionValue(order, EXTENSION_URLS.medicationStatus) || order.status;
  if (medStatus) {
    display += `\nMedications Status: ${getMedicationStatusDisplay(medStatus)}`
  }

  if (enteredBy) {
    display += `\nAdded by ${enteredBy}`
  }
  if (order.authoredOn) {
    const date = getDateStrFromFormat(order.authoredOn, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT);
    display += ` on ${date}`
  }

  if (!isShortVersion && order.note?.length) {
    const note = order.note.map((note) => note.text).join('\n');
    display += `\nNote: ${note}`
  }

  const note = getExtensionValue(order, EXTENSION_URLS.pharmacyNote);
  if (!isShortVersion && note) {
    display += `\nPharmacy Note: ${note}`
  }

  return display;
}

export const getPrescriptionStatusDisplay = (status: string) => {
  switch (status) {
    case 'eRxSent': return 'eRx Sent';
    case 'FaxSent': return 'Fax Sent';
    case 'EpcsError': return 'EPCS Error';
    case 'EpcsSigned': return 'EPCS Signed';
    case 'ReadyToSign': return 'Ready to Sign';
    case 'PharmacyVerified': return 'Pharmacy Verified';
    default: return status;
  }
};

export const getMedicationStatusDisplay = (status: string) => {
  switch (status) {
    case 'CancelRequested': return 'Cancel Requested';
    case 'CancelPending': return 'Cancel Pending';
    case 'CancelDenied': return 'Cancel Denied';
    case 'FullFill ': return 'Full Fill';
    case 'PartialFill': return 'Partial Fill';
    case 'NoFill': return 'No Fill';
    default: return status;
  }
};
export const getStatusDisplay = (status: string) => {
  switch (status) {
    case 'active': return 'Active';
    case 'on-hold': return 'On hold';
    case 'cancelled': return 'Cancelled';
    case 'completed': return 'Completed';
    case 'entered-in-error': return 'Entered in error';
    case 'stopped': return 'Stopped';
    case 'draft': return 'Draft';
    case 'unknown': return 'Unknown'
    case 'routine': return 'Routine'
    case 'urgent': return 'Urgent'
    case 'asap': return 'ASAP'
    case 'stat': return 'STAT'
    case 'registered': return 'Registered'
    case 'partial': return 'Partial'
    case 'preliminary': return 'Preliminary'
    case 'final': return 'Final'
    case 'corrected': return 'Corrected'
    case 'appended': return 'Appended'
    case 'amended': return 'Amended'
    case 'DATAENTRY': return 'Data Entry'
    case 'REVIEW': return 'Review'
    case 'CLOSED': return 'Closed'
    default: return status;
  }
};

export const getOrderCallbackUrl = () => {
  const subdomain = getCurrentSubdomain()
  return `https://${subdomain}/widget.html?isWidget=true`;
}

export const getOrderDateTime = (order: RequestGroup | MedicationRequest) => {
  if (order?.authoredOn) {
    return getDateStrFromFormat(order?.authoredOn, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT);
  }
  const futureOrderExtension = order?.extension?.find((item) => item.url == EXTENSION_URLS.requestGroupTiming);
  if (futureOrderExtension?.valueTiming?.event?.length) {
    const date = futureOrderExtension.valueTiming.event[0];
    return getDateStrFromFormat(date, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT)
  }
  if (order.meta?.lastUpdated) {
    return getDateStrFromFormat(order.meta.lastUpdated, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT);
  }
  return '';
}

export const hasReportFor = (order: RequestGroup, reports: IReportAndResourceMap[]): boolean => {
  for (const report of reports) {
    const references = report.resources?.[0]?.basedOn?.map(
      (item) => item.reference
    );
    if (references && order?.action?.length) {
      for (const action of order.action) {
        if (references.includes(action?.resource?.reference || '')) {
          return true;
        }
      }
    }
  }
  return false;
}

export const getOrderFromReport = (report: DiagnosticReport, orders: RequestGroup[]): RequestGroup | undefined => {
  let serviceRequestId = '';
  report.basedOn?.forEach((item) => {
    const serviceRequest = (item.reference || '').split('/');
    if (serviceRequest.length > 1) {
      serviceRequestId = serviceRequest[1];
    }
  });
  return orders.find((order) => {
    if (order.action?.length) {
      const matchedOrder = order.action.find((item) => item.resource?.reference?.includes(serviceRequestId));
      return !!matchedOrder;
    }
    return false;
  });
}

export const getReportMatchWithOrder = (report: IReportAndResourceMap, order: RequestGroup) => {
  const orderReferenceIds: string[] = [];
  let diagnosticReport: DiagnosticReport = {} as DiagnosticReport;
  (order?.action || []).forEach(actionItem => {
    const reference = actionItem?.resource?.reference;
    if (reference) {
      orderReferenceIds.push(reference);
    }
  });
  if (orderReferenceIds?.length && report?.resources?.length) {
    (orderReferenceIds || []).some(referenceId => {
      diagnosticReport = getMatchReportWithOrderReferenceId(referenceId, report);
      if (diagnosticReport?.id) {
        return true;
      }
    });
  }
  return diagnosticReport;
}

export const getMatchReportWithOrderReferenceId = (referenceId: string, report: IReportAndResourceMap) => {
  return (report?.resources || []).find(resource => {
    return (resource?.basedOn || []).some((item) => {
      if (item?.reference && item.reference === referenceId) {
        return true;
      }
    }) === true;
  }) || {} as DiagnosticReport;
}

export const getOrderTitleSubtitleForPrint = (order?: RequestGroup): string => {
  const details: string[] = [];
  const date = order && getOrderDateTime(order);
  if (order?.status) {
    details.push(`Status: ${getStatusDisplay(order.status)}`);
  }
  if (order?.author?.display) {
    let display = `Added by ${order.author.display}`;
    if (date) {
      display += ' on ' + date;
    }
    details.push(display);
  } else if (date) {
    details.push(`Added on: ${date}`);
  }
  return details.join(' • ');
}

export const isPendingDraftOrder = (order: RequestGroup): boolean => {
  const futureOrderExtension = order.extension?.find((item) => item.url == EXTENSION_URLS.requestGroupTiming);
  // For future orders we get requestGroupTiming extension
  // So the future order which is not signed yet has both event array with date and repeat object inside with all the future order configs
  // But the future order which is signed and has future recurring order planned, has only event array with date
  // So do not show edit button for future draft orders which are signed already
  const isFutureOrderRecurrence = !futureOrderExtension?.valueTiming?.repeat;
  return (!futureOrderExtension || !isFutureOrderRecurrence) && order.status === 'draft';
}

export const getOrderTypeFromOrder = (order: RequestGroup | MedicationRequest) => {
  let type = '';
  order.extension?.forEach((item: Extension) => {
    if (item.url === EXTENSION_URLS.requestGroupCategory || item.url === EXTENSION_URLS.orderCategory) {
      type = item?.valueString || '';
    }
  });
  switch (type) {
    case 'LAB':
      return OrderType.LAB;
    case 'RADIOLOGY':
      return OrderType.RAD;
    case 'MEDICATION':
      return OrderType.MED;
    default:
      return OrderType.LAB;
  }
};

export const getDocumentReferenceIdFromOrder = (order: RequestGroup | MedicationRequest) => {
  return order.basedOn?.[0]?.reference?.split('/')?.[1];
}

export const isPendingMedicationOrder = (order: MedicationRequest) => {
  const status = getExtensionValue(order, EXTENSION_URLS.externalStatus) || order?.status;
  return status === 'Entered';
}

export const isOrderFaxed = (order: RequestGroup) => {
  const deliveryOption = order.extension?.find((item: any) => item?.url === EXTENSION_URLS.requestGroupDeliveryOption);
  if (deliveryOption?.extension?.length) {
    const deliveryMethod = deliveryOption.extension?.find((item: any) => item?.url === 'method');
    return deliveryMethod?.valueContactPoint?.system === 'fax';
  }
  return false;
}

export const isOrderAddedFromFold = (order: RequestGroup | undefined) => {
  const orderSource = order?.extension?.find((item: any) => item?.url === EXTENSION_URLS.source);
  return orderSource?.valueString === ORDER_SOURCE.FOLD;
}

export const getRequesterId = (order: MedicationRequest) => {
  if (order.requester?.identifier?.system === EXTENSION_URLS.foldUserUuid) {
    return order.requester?.identifier?.value;
  }
  const details = order.requester?.reference?.split('/') || [];
  if (details.length > 1) {
    return details[1];
  }
}

export const getAuthorId = (order: RequestGroup) => {
  const details = order.author?.reference?.split('/') || [];
  if (details.length > 1) {
    return details[1];
  }
}

export const canShowUploadReportBtn = (order: RequestGroup) => {
  return isOrderFaxed(order) || (isOrderAddedFromFold(order) && order.status !== OrderStatus.draft)
}

export const canShowEditButton = (order: RequestGroup | MedicationRequest | undefined) => {
  const isLabRadOrder = order?.resourceType === 'RequestGroup';
  const isMedOrder = order?.resourceType === 'MedicationRequest';
  return (isLabRadOrder && isPendingDraftOrder(order)) || (isMedOrder && isPendingMedicationOrder(order));
}

export const canShowDeleteButton = (order: RequestGroup | MedicationRequest | undefined) => {
  const isLabRadOrder = order?.resourceType === 'RequestGroup';
  return isLabRadOrder && isOrderAddedFromFold(order) && order.status === 'draft';
}

export const canShowPrintButton = (order: RequestGroup | MedicationRequest | undefined) => {
  const isLabRadOrder = order?.resourceType === 'RequestGroup';
  return isLabRadOrder && isOrderAddedFromFold(order) && order.status !== 'draft';
}

export const getApplicableOrderStatusList = (order: RequestGroup) => {
  return [
    { code: 'draft', display: 'Draft' },
    { code: 'active', display: 'Active' },
    { code: 'completed', display: 'Completed' },
  ]
}

export const updateOrderStatus  = async (status: string, order: RequestGroup) => {
  const currentDate = getMomentObj(new Date()).toISOString();
  const oldStatus = order.status;
  order.status = status as RequestGroup["status"];
  if (order.meta) {
    order.meta.lastUpdated = currentDate;
  }
  try {
    await addOrUpdateOrder(order);
  } catch (error) {
    order.status = oldStatus;

  }
}
