import {StyleSheet} from 'react-native';
import {Colors} from '../../styles';

export const styles = StyleSheet.create({
  container: {
    flex: 4,
  },
  sideBarWidthFull: {
    minWidth: 305,
    // overflow: 'scroll',
  },
  sideBarWidthCollapse: {},
  headerStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 8,
    marginTop: 20,
  },
  headerStyleCollapse: {
    paddingLeft: 12,
    marginBottom: 18,
    marginTop: 20,
  },
  logoView: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginRight: 8,
  },
  logoImg: {
    borderRadius: 50,
    width: 40,
    height: 40,
  },
  logoFullWidthImg: {
    width: 170,
    height: 40,
  },
  headerText: {
    fontSize: 22,
    paddingTop: 2,
    paddingBottom: 2,
    textAlign: 'center',
  },
  searchBarView: {
    marginBottom: 22,
    marginRight: 24,
    marginLeft: 24,
  },
  footerHStack: {
    marginTop: 16,
    marginBottom: 16,
  },
  footerBtn: {
    marginRight: 15,
  },
  rightSideContainer: {
    padding: 0,
    backgroundColor: '#fff',
    // overflow: 'scroll',
  },
});

export default styles;
