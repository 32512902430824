const CalendarReverseSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1848_73823)">
      <path
        d="M7.38276 4.15313V0.923438C7.38276 0.417188 6.96558 0 6.45933 0C5.95308 0 5.53589 0.417188 5.53589 0.923438V4.15313C5.53589 4.65938 5.95308 5.07656 6.45933 5.07656C6.96558 5.07656 7.38276 4.65938 7.38276 4.15313Z"
        fill="#98A2B3"
      />
      <path
        d="M18.4593 4.15313V0.923438C18.4593 0.417188 18.0468 0 17.5406 0C17.0343 0 16.6171 0.417188 16.6171 0.923438V4.15313C16.6171 4.65938 17.0343 5.07656 17.5406 5.07656C18.0468 5.07656 18.4593 4.65938 18.4593 4.15313Z"
        fill="#98A2B3"
      />
      <path
        d="M20.6953 2.77031H19.3828V4.15312C19.3828 5.175 18.5578 6 17.5359 6C16.514 6 15.689 5.175 15.689 4.15312V2.77031H8.3062V4.15312C8.3062 5.175 7.4812 6 6.45933 6C5.43745 6 4.61245 5.175 4.61245 4.15312V2.77031H3.29995C1.98276 2.77031 0.918701 3.83438 0.918701 5.15156V21.6187C0.918701 22.9359 1.98745 24 3.29995 24H20.6906C22.0078 24 23.0718 22.9312 23.0718 21.6187V5.15156C23.0765 3.83438 22.0125 2.77031 20.6953 2.77031ZM13.9265 19.8797H10.8421C10.6312 19.8797 10.4578 19.7062 10.4578 19.4953V17.9531C10.4578 17.7422 10.6312 17.5687 10.8421 17.5687H13.9265C15.0562 17.5687 15.9609 16.5938 15.8437 15.4406C15.7406 14.4375 14.8218 13.7109 13.814 13.7109H10.4531V15.6375C10.4531 15.7875 10.3687 15.9187 10.2375 15.9844C10.1859 16.0078 10.125 16.0219 10.0687 16.0219C9.98433 16.0219 9.89526 15.9937 9.82495 15.9375L5.97183 12.8531C5.88276 12.7781 5.82651 12.6703 5.82651 12.5531C5.82651 12.4359 5.87808 12.3234 5.97183 12.2531L9.82495 9.16875C9.94214 9.075 10.0968 9.06094 10.2328 9.12187C10.364 9.1875 10.4484 9.32344 10.4484 9.46875V11.3953H13.7343C15.9468 11.3953 17.9296 13.0078 18.1453 15.2062C18.3984 17.7422 16.4062 19.8797 13.9265 19.8797Z"
        fill="#98A2B3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1848_73823">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default CalendarReverseSvg;
