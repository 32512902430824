import {AreaChart as AreaRechart, Area, CartesianGrid} from 'recharts';
import React from 'react';

export interface IAreaChartProps {
  width?: string;
  height?: string;
  color?: string;
  data?: any;
}

export default function AreaChart({
  width = 500,
  height = 200,
  color = '',
  syncId = 'anyId',
  margin = {top: 0, right: 0, left: 0, bottom: 0},
  data = [],
  id = `${Date.now()}`,
  cartesian = false,
  dataKey = 'pv',
}) {
  return (
    <AreaRechart
      width={width}
      height={height}
      data={data}
      syncId={syncId}
      margin={margin}
    >
      {cartesian && <CartesianGrid strokeDasharray="3 3" />}
      <defs>
        <linearGradient id={id + 'colorPV'} x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor={color} stopOpacity={0.8} />
          <stop offset="95%" stopColor={color} stopOpacity={0} />
        </linearGradient>
      </defs>
      <Area
        type="monotone"
        dataKey={dataKey}
        stroke={color}
        fillOpacity={1}
        fill={'url(#' + id + 'colorPV)'}
      />
    </AreaRechart>
  );
}
