import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {Colors} from '../../../../styles/Colors';
import {ICommonSvgProps} from '../interfaces';

const ArrowUpSvg = (props: ICommonSvgProps) => {
  return (
    <Svg width={props?.width || "12"} height={props?.height || "13"} viewBox="0 0 12 13" fill="none">
      <Path
        d="M6 10.5L6 2.5M6 2.5L9 5.5M6 2.5L3 5.5"
        stroke={props?.customStrokeColor || Colors.FoldPixel.GRAY300}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default React.memo(ArrowUpSvg);
