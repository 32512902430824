import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  singleScriptContainer: {
    margin: 10,
    flex: 0.5,
  },
  titleStack: {
    marginTop: 10,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperStyle: {
    overflow: 'scroll',
    flex: 1.0,
    marginLeft:-8,
  },
  titleText: {},
});
