import {Tooltip} from 'antd';
import {Pressable, View} from 'native-base';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {ArchivedSvg} from '../../common/Svg/ArchivedSvg';
import {styles} from './actionViewStyles';

interface IActionViewProps {
  selectedRecord: any;
  onActionClick: (actionCode: string, actionData: any) => void;
}

const BillingActionsView = (props: IActionViewProps) => {
  return (
    <View style={styles.iconView}>
      <Tooltip title={props.selectedRecord.active ? 'Archive' : ''}>
        <Pressable
          style={{paddingHorizontal: 12, paddingVertical: 11, marginRight: 4}}
          onPress={() => {
            props.onActionClick(
              COMMON_ACTION_CODES.ARCHIVED,
              props.selectedRecord
            );
          }}
        >
          <View style={{width: 20, height: 20}}>
            {props.selectedRecord.active ? <ArchivedSvg /> : <></>}
          </View>
          {/* <DeleteSvg /> */}
        </Pressable>
      </Tooltip>
      {/* <Tooltip title={'Edit'}>
        <Pressable
          style={{paddingHorizontal: 12, paddingVertical: 11}}
          onPress={() => {
            props.onActionClick(COMMON_ACTION_CODES.EDIT, props.selectedRecord);
          }}
        >
          <EditSvg />
        </Pressable>
      </Tooltip> */}
    </View>
  );
};

export default BillingActionsView;
