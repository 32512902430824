import React from "react";

const LabSavingsSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
     <svg
     xmlns="http://www.w3.org/2000/svg"
     style={{width: 'inherit', height: 'inherit'}}
     fill="none"
     viewBox="0 0 24 25"
   >
     <g>
       <path
         stroke="#98A2B3"
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth="2"
         d="M15.228 5.635h-2.63m-1.275 3.989h-2.63m9.246 5.322c-.429 1.6-1.53 2.222-2.92 2.389m-4.287-.378c-.475-.078-.95-.156-1.402-.222-.22-.034-.428-.067-.637-.09-3.441-.388-4.994 1.256-4.994 1.256m.348 4.823c-1.668 0-2.642-1.823-1.645-3.112l6.268-8.21V5.634h-.186a1.6 1.6 0 01-1.077-.423 1.404 1.404 0 01-.44-1.033c0-.8.683-1.455 1.517-1.455h6.94c.418 0 .8.166 1.078.422.278.266.44.633.44 1.033 0 .8-.683 1.456-1.518 1.456h-.185V11.4l6.268 8.211c.985 1.29.023 3.112-1.645 3.112H4.047zm10.984-5.445c0 1.148-.97 2.078-2.167 2.078-1.197 0-2.167-.93-2.167-2.078 0-1.148.97-2.078 2.167-2.078 1.197 0 2.167.93 2.167 2.078z"
       ></path>
     </g>
   </svg>
  );
}

export default React.memo(LabSavingsSvg);
