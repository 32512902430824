import { useContext, useEffect, useState } from "react";
import { COMMON_ACTION_CODES } from "../../../../constants/ActionConst";
import { IBillingHistoryHookState, IGetBilledActivitiesByMonthResponse, IHandleBillingHistoryTableActions, IParamsForBillingHistory } from "./BillingHookInterfaces";
import { GET_BILLABLE_ACTIVITIES_BY_MONTH, GET_BILL_ACTIVITIES_SUM } from "../BillingQueries";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../constants/Configs";
import { ApolloError, useLazyQuery, useQuery } from "@apollo/client";
import { BILLED_ACTIVITIES_PAGE_LIMIT, BILLING_FILTER_ACTION_CODES } from "./BillingHooksConstants";
import { useToast } from "native-base";
import { useIntl } from "react-intl";
import { ToastType, showToast } from "../../../../utils/commonViewUtils";
import { ehrCodeDisplay } from "../../Contacts/TeamMembers/AddEditUser/interfaces";
import { getEhrList } from "../../../../services/CommonService/IntegrationService";
import { GET_ACCOUNT_MLOVS_BY_CATEGORY } from "../../../../services/Mlov/MlovQueries";
import { CommonDataContext } from "../../../../context/CommonDataContext";
import { IFormCommonData } from "../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper";
import { IMlov } from "../../../../Interfaces";
import { BILLABLE_ACTIVITY_STATUS_STATUS_CODES, MLOV_CATEGORY } from "../../../../constants/MlovConst";
import { getMlovListFromCategory } from "../../../../utils/mlovUtils";

const UseBillingHistoryTab = (params: any) => {
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const billableActivityStatusMlov = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CONTACT_CARE_PROGRAM_BILLABLE_ACTIVITY_STATUS) || [];
  const [billingHistoryHookState, setBillingHistoryHookState] = useState<IBillingHistoryHookState>(
    {
      billedRecords: [],
      timezone: '',
      totalAmountSum: 0,
    }
  );
  const [paginationState, setPaginationState] = useState({
    paginationLoading: false,
    limit: BILLED_ACTIVITIES_PAGE_LIMIT,
    offset: 0,
    pageNo: 1,
    total: 0,
  });
  const [ehrList, setEhrList] = useState<ehrCodeDisplay[]>([]);
  const [ehrLoading, setEhrLoading] = useState(false);
  const toast = useToast();
  const intl = useIntl();
  const showErrorToast = (errorMessage: string) => (error: ApolloError) => {
    return showToast(
      toast,
      intl.formatMessage({ id: 'errorMessage' }),
      ToastType.error,
      4000
    )
  }

  const [getBilledActivitiesByMonth, { loading: getBilledActivitiesByMonthLoading }] = useLazyQuery<IGetBilledActivitiesByMonthResponse>(GET_BILLABLE_ACTIVITIES_BY_MONTH, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    onError: showErrorToast('apiErrorBilledActivitiesByMonth'),
  })

  const [getBillActivitiesAmountSum, { loading: getBillActivitiesAmountSumLoading }] = useLazyQuery(GET_BILL_ACTIVITIES_SUM, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    variables: {
      params: {
        billableActivityStatusIds: [billableActivityStatusMlov.find((mlov) => mlov.code === BILLABLE_ACTIVITY_STATUS_STATUS_CODES.COMPLETED)?.id || '']
      }
    },
    onError: showErrorToast('apiErrorInFetchingAmountSum'),
  })

  const getParamsForBilledActivitiesData = (args?: {
    limit?: number;
    offset?: number;
  }): IParamsForBillingHistory => {
    return {
      offset: args?.offset || paginationState.offset,
      limit: args?.limit || paginationState.limit,
    }
  }

  const handleActions = async (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case BILLING_FILTER_ACTION_CODES.PAGINATION:
        const pageNumber = actionData.page;
        const pageLimit = actionData.pageSize;
        const offset = pageNumber * pageLimit - pageLimit;
        setPaginationState((prev) => {
          return {
            ...prev,
            offSet: offset,
            pageNo: actionData.page,
            limit: pageLimit,
          }
        })
        params = getParamsForBilledActivitiesData({ offset: offset, limit: pageLimit });
        break;
    }
  }


  const getBilledData = async (params?: IParamsForBillingHistory) => {
    const paramsForQuery = params || getParamsForBilledActivitiesData();
    const promiseList = [
      getBilledActivitiesByMonth(
        {
          variables: { params: paramsForQuery }
        }
      ),
      getBillActivitiesAmountSum()
    ]
    const apiResponses = await Promise.all(promiseList);
    if (apiResponses?.[0]?.data) {
      setBillingHistoryHookState((prev) => {
        return {
          ...prev,
          billedRecords: apiResponses?.[0]?.data?.getBilledActivitiesByMonth.records || [],
          timezone: apiResponses?.[0]?.data?.getBilledActivitiesByMonth.timezone,
          totalAmountSum: apiResponses?.[1]?.data?.getBillableActivitiesAmountSum.totalAmount,
        }
      })
      setPaginationState((prev) => {
        return {
          ...prev,
          total: apiResponses?.[0]?.data?.getBilledActivitiesByMonth?.totalRecords || 0
        }
      })
    }
  }


  const setEhr = async () => {
    try {
      setEhrLoading(true);
      const ehrResponse = await getEhrList();
      if (ehrResponse?.data?.expansion?.contains?.length > 0) {
        setEhrList(ehrResponse?.data?.expansion?.contains);
      }
      setEhrLoading(false);
    } catch (error) {
      setEhrLoading(false);
    }
  };

  useEffect(() => {
    getBilledData();
    setEhr();
  }, [])

  const handleBillingHistoryTableActions = (params: IHandleBillingHistoryTableActions) => {
    const { actionCode, actionData } = params;
    switch (actionCode) {
      case COMMON_ACTION_CODES.DETAIL_VIEW:
        setBillingHistoryHookState((prev) => {
          return {
            ...prev,
            selectedRecordForBillingReport: actionData,
          }
        })
        break;
      case COMMON_ACTION_CODES.CLOSE:
        setBillingHistoryHookState((prev) => {
          return {
            ...prev,
            selectedRecordForBillingReport: undefined,
          }
        })
    }
  }

  const monthlyDataLoading =  ehrLoading || getBilledActivitiesByMonthLoading || getBillActivitiesAmountSumLoading;
  return {
    billingHistoryHookState,
    handleBillingHistoryTableActions,
    ehrList,
    monthlyDataLoading,
    handleActions,
    paginationState,
  }
}

export default UseBillingHistoryTab;
