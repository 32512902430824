import React from 'react';
import { Svg, Path } from 'react-native-svg';

const SideCarFilterIconSvg = () => {
  return (
    <Svg
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
    >
      <Path
        stroke="#6F7A90"
        strokeLinecap="round"
        d="M12.116 2.5H9.3c-3.917 0-5.876 0-6.246 1.186-.37 1.187 1.19 2.494 4.31 5.108.928.777 1.391 1.166 1.64 1.714.247.548.247 1.183.247 2.452v3.104c0 .484 0 .726.055.879.143.4.532.627.917.537.148-.034.333-.167.704-.433.364-.262.546-.393.697-.546.382-.39.637-.906.726-1.47.035-.22.035-.458.035-.933v-1.323c0-1.181 0-1.772.218-2.292.218-.52.628-.906 1.448-1.678l.168-.158c2.737-2.58 4.105-3.87 3.716-5.008-.39-1.139-2.2-1.139-5.819-1.139z"
      ></Path>
    </Svg>
  );
};

export default SideCarFilterIconSvg;
