import React from 'react';
import {Progress} from 'antd';
import {Typography} from 'antd';
import './styles.module.css';
const {Text} = Typography;

export default function DisplayProgress({
  text,
  percent,
  showInfo = false,
}: {
  text?: string | any;
  percent?: number | any;
  showInfo?: boolean | any;
}) {
  return (
    <div className="progress_container">
      <Progress
        percent={percent}
        size="small"
        showInfo={showInfo}
        className="progress_custom_progress"
        strokeColor={{
          '0%': '#7F56D9',
          '100%': '#7F56D9',
        }}
      />
      <Text style={{margin: '0 0 0 10px'}}>{text}</Text>
    </div>
  );
}
