import { Drawer } from "antd";
import { VStack, Text, HStack, FormControl } from "native-base";
import { useState } from "react";
import { BUTTON_TYPE, DISPLAY_DATE_FORMAT } from "../../../../../constants/StringConst";
import { Colors } from "../../../../../styles/Colors";
import { getMomentObj, isPastDay } from "../../../../../utils/DateUtils";
import { DisplayText } from "../../../../common/DisplayText/DisplayText";
import { ModalActionDatePicker } from "../../../../common/ModalActionCommonComponent/ModalActionDatePicker";
import { ModalActionTitle } from "../../../../common/ModalActionTitle/ModalActionTitle";
import { IPatientJourneyProps } from "./PatientJourneyInterface";
import {StyleSheet} from 'react-native';
import { capitalizeText } from "../../../ContentManagement/CampaignManagement/Helper";

const RestartCareJourneyDrawer = (props: IPatientJourneyProps) => {

  const [stateData, setStateData] = useState<{
    selectedDate?: string;
    comment?: string;
  }>({
    selectedDate: getMomentObj(new Date()).toISOString(),
    comment: undefined,
  });

  function resetStateData() {
    setStateData({ selectedDate: getMomentObj(new Date()).toISOString() });
  }

  return (
    <Drawer
      destroyOnClose
      placement="right"
      onClose={() => {
        resetStateData();
        props.onCancel();
      }}
      visible={props.enabledDrawer}
      closable={false}
      width={'40%'}
      title={
        <ModalActionTitle
        title={capitalizeText(props?.selectedJourney?.title || props?.selectedJourney?.careJourney?.title)}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: 'cancel',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              resetStateData();
              props.onCancel();
            },
          },
          {
            show: true,
            id: 2,
            btnText: 'restartJourney',
            textColor: Colors.Custom.mainPrimaryPurple,
            isLoading: props.isLoading,
            variant: BUTTON_TYPE.PRIMARY,
            isTransBtn: false,
            onClick: () => {
              props.onActionPerformed({ restartDateTime: stateData.selectedDate, note: stateData.comment?.trim() }, props.selectedJourney);
            },
          },
        ]}
      />
      }
    >
      <HStack flex={1}>
        <FormControl style={[styles.formElement]}>
          <FormControl.Label style={styles.formLabel} isRequired>
            <DisplayText
              textLocalId={'selectJourneyRestartDate'}
              size={'mdMedium'}
              extraStyles={{color: Colors.Custom.Gray900}}
            />
          </FormControl.Label>
          <ModalActionDatePicker
                isRequired={true}
                label={''}
                format={DISPLAY_DATE_FORMAT}
                onChange={(date: any) => {
                  if (date) {
                    setStateData(prev => {
                      return {
                        ...prev,
                        selectedDate: date
                      };
                    });
                  }
                }}
                disabledDate={(current: any) => {
                  return current && isPastDay(current);
                }}
                value={stateData.selectedDate ? getMomentObj(stateData.selectedDate || '') : ''}
              />
        </FormControl>
      </HStack>
    </Drawer>
  );
};

export default RestartCareJourneyDrawer;

export const styles = StyleSheet.create({
  formElement: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  formLabel: {
    marginLeft: 4,
  },
});
