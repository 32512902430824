import React from 'react';
import { ICommonSvgProps } from '../interfaces';

const CallMute = (props: ICommonSvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="45"
      fill="none"
      viewBox="0 0 46 45"
    >
      <g filter="url(#filter0_dd_2779_215374)">
        <rect width="38" height="38" x="4" y="2.5" fill={props.isActive ? "#825AC7": "#fff"} rx="19"></rect>
        <g clipPath="url(#clip0_2779_215374)">
          <path
            stroke={props.isActive ? "#fff": "#667085"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M28.833 19.833V21.5A5.834 5.834 0 0123 27.333m0 0a5.833 5.833 0 01-5.833-5.833v-1.667m5.833 7.5v3.334m-3.333 0h6.666M23 12.333a2.5 2.5 0 00-2.5 2.5V21.5a2.5 2.5 0 005 0v-6.667a2.5 2.5 0 00-2.5-2.5z"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_2779_215374"
          width="45"
          height="45"
          x="0.5"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2779_215374"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_2779_215374"
            result="effect2_dropShadow_2779_215374"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_2779_215374"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_2779_215374">
          <path
            fill="#fff"
            d="M0 0H20V20H0z"
            transform="translate(13 11.5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default CallMute;
