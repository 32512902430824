import {Table} from 'antd';
import {Skeleton, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {MODULE_PAGINATION_COUNT} from '../../../../../constants';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {evnName} from '../../../../../constants/BaseUrlConst';
import {getProducts} from '../../../../../services/Stripe/StripeServices';
import {testID, TestIdentifiers} from '../../../../../testUtils';
import {getCurrentSubdomainWeb} from '../../../../../utils/commonUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import {IProductTableDisplayData} from '../../ProductsAndServices/Products/ProductTableView/ProductTable/interfaces';
import {formatBillingProductListData} from '../../ProductsAndServices/Products/ProductTableView/ProductUtils/ProductTableUtils';
import {addOrUpdateMembership} from '../MembershipAPI';
import {style} from '../MembershipStyles';
import {getMembershipsTableColumns} from './MembershipTableColumns';
import {expandedColumns, getBillingTableColumns} from './SubTableColumns';

const MembershipTable = (props: {searchText: string}) => {
  const navigate = useNavigate();
  const [productTableState, setProductTableState] = useState({
    isLoading: true,
    isNoDataFound: false,
    selectedProduct: {} as any,
    searchString: props.searchText,
    productList: [] as any[],
    productDisplayList: [] as any[],
    page: 1,
    pageSize: MODULE_PAGINATION_COUNT.PRODUCTS_LIST_PAGE_SIZE,
    deleteConfirmationOpen: false,
  });
  const isFoldAccount = () => {
    const subdomain = getCurrentSubdomainWeb();
    if (subdomain === `fold.${evnName()}foldhealth.io`) {
      return true;
    }
    return false;
  };
  const enablePackageConfig = isAccountConfigEnabled(CONFIG_CODES.ENABLE_JOURNEY_PACKAGE)
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.columnKey) {
    }
  };
  const fetchProducts = async () => {
    setProductTableState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const productResponse = await getProducts(props.searchText, enablePackageConfig).catch(
      (error) => {
        setProductTableState((prev) => {
          return {
            ...prev,
            isLoading: false,
            isNoDataFound: true,
          };
        });
      }
    );
    if (
      productResponse?.data?.length > 0 ||
      productResponse?.data?.data?.length > 0
    ) {
      const productList =
        productResponse?.data?.length > 0
          ? productResponse
          : productResponse?.data?.data?.length > 0
          ? productResponse?.data
          : [];
      const tempProductDisplayList = formatBillingProductListData(productList);
      setProductTableState((prev) => {
        return {
          ...prev,
          productDisplayList: [...tempProductDisplayList],
          isLoading: false,
        };
      });
    } else {
      setProductTableState((prev) => {
        return {
          ...prev,
          productDisplayList: [],
          isLoading: false,
        };
      });
    }
  };
  const onArchivedUnarchive = (productData: IProductTableDisplayData) => {
    const productFormData = {
      name: productData.name,
      description: productData.description,
      active: !productData.active,
      prices: productData.rowData.prices,
      category: 'membership',
      id: productData.id,
    };
    addOrUpdateMembership(
      {
        product: productFormData,
      },
      (responseMsg: any) => {
        fetchProducts();
        navigate('/admin/membership/products');
      },
      () => {
        // showToastMessage('productDetailFormError', true);
      },
      productData.id
    );
  };
  const onActionPerformed = (
    actionCode: string,
    rowData: IProductTableDisplayData
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        // onEditProduct(rowData);
        navigate(`/admin/membership/products/${rowData.id}`);
        break;
      case COMMON_ACTION_CODES.ARCHIVED:
        onArchivedUnarchive(rowData);
        break;
      case COMMON_ACTION_CODES.DELETE:
        // onDeleteProduct(rowData);
        setProductTableState((prev) => ({
          ...prev,
          deleteConfirmationOpen: true,
          selectedProductForDelete: rowData,
        }));
        break;
      case COMMON_ACTION_CODES.STATUS_CHANGED:
        break;
    }
  };
  const onRowClicked = (rowData: any) => {
    navigate(`/admin/membership/products/${rowData.id}`);
  };
  useEffect(() => {
    fetchProducts();
  }, []);
  useEffect(() => {
    if (props.searchText.trim().length > 2) fetchProducts();
  }, [props.searchText]);
  const {height} = Dimensions.get('window');
  const finalHeight = height - 380;
  return (
    <>
      <PageBodyContainer style={{marginHorizontal: 0}}>
        {!isFoldAccount() &&
          productTableState.productList &&
          !productTableState.isLoading && (
            <Table
              scroll={{x: 700, y: finalHeight}}
              columns={getMembershipsTableColumns(onActionPerformed)}
              rowKey={(row) => row.id}
              dataSource={productTableState.productDisplayList}
              rowClassName={(record, index) =>
                index % 2 == 0 ? 'table-row-light' : ''
              }
              onRow={(productData: IProductTableDisplayData) => {
                return {
                  onClick: () => {
                    onRowClicked(productData);
                  },
                };
              }}
              onChange={onTableChange}
              pagination={{
                current: productTableState.page,
                pageSize: productTableState.pageSize,
                position: ['bottomCenter'],
                // total: productTableState.productMeta.total_count,
                onChange: (currentPage, currentPageSize) => {
                  setProductTableState((prev) => {
                    return {
                      ...prev,
                      page: currentPage,
                      pageSize: currentPageSize,
                    };
                  });
                },
              }}
            />
          )}
        {productTableState.isLoading && (
          <View
            style={style.loadingView}
            {...testID(TestIdentifiers.pageLoading)}
          >
            <Skeleton.Text lines={4} margin={4} />
          </View>
        )}
        {isFoldAccount() &&
          productTableState.productList &&
          !productTableState.isLoading && (
            <Table
              scroll={{x: 700, y: finalHeight}}
              columns={getBillingTableColumns(onActionPerformed)}
              rowKey={(row) => row.id}
              dataSource={productTableState.productDisplayList}
              expandable={{
                expandedRowRender: (record) => (
                  <>
                    <Table
                      columns={expandedColumns}
                      dataSource={record.prices?.tiers}
                      pagination={{pageSize: 5}}
                      rowClassName={(record, index) =>
                        index % 2 == 0 ? 'table-row-light' : ''
                      }
                    />
                  </>
                ),
                // rowExpandable: (record) => record.prices?.tiers?.length === 0,
              }}
              rowClassName={(record, index) =>
                index % 2 == 0 ? 'table-row-light' : ''
              }
              onChange={onTableChange}
              pagination={{
                current: productTableState.page,
                pageSize: productTableState.pageSize,
                position: ['bottomCenter'],
                // total: productTableState.productMeta.total_count,
                onChange: (currentPage, currentPageSize) => {
                  setProductTableState((prev) => {
                    return {
                      ...prev,
                      page: currentPage,
                      pageSize: currentPageSize,
                    };
                  });
                },
              }}
            />
          )}
        {productTableState.isLoading && (
          <View
            style={style.loadingView}
            {...testID(TestIdentifiers.pageLoading)}
          >
            <Skeleton.Text lines={4} margin={4} />
          </View>
        )}
      </PageBodyContainer>
    </>
  );
};
export default MembershipTable;
