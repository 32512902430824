import Icon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../../styles';
import PlusIcon from '../../../../common/Svg/PlusSvg';
import {ITableTopBarButtonProps} from '../../../../common/TableTopBar';

export const addInboxButton = (
  onAddInbox: () => {}
): ITableTopBarButtonProps[] => {
  return [
    {
      btnText: 'new',
      size: 'sm',
      backgroundColor: Colors.secondary['100'],
      borderColor: Colors.secondary['200'],
      textColor: Colors.secondary['800'],
      variant: 'PRIMARY',
      leftIcon: <PlusIcon />,
      btnClick: onAddInbox,
    },
  ];
};
