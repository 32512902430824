import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {ISearchFieldProps} from '../../../Interfaces';
import { SEARCH_ACTIVE_CARE_JOURNEYS } from '../../../services/CareJourney/CareJourneyQueries';
import { ICareJourneyStatusIDs } from '../../RightSideContainer/Sales/ProductsAndServices/Products/ProductTableView/ProductTable/interfaces';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../utils/mlovUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { MLOV_CATEGORY } from '../../../constants';
import { CARE_PROGRAM_STATUS_CODES } from '../../../constants/MlovConst';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { GetContactAccountPracticeLocation } from '../../../services/Lead/LeadQueries';
import { getLocationIntersectionList } from '../../RightSideContainer/Workflow/workflowUtils';
import ChevronDownSvg from '../Svg/ChevronDownSvg';
import { accountLocationUuidListByUserPracticeLocationList, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner } from '../../../utils/commonUtils';

const CareJourneySearch = (props: ISearchFieldProps) => {
  const {value, isShowError, onChange} = props;
  const [loading, setLoading] = useState(false);
  const [careJourneyData, setCareJourneyData] = useState<any[]>([]);
  const mlovData = useContext(CommonDataContext);
  const userPracticeLocationsObj = mlovData.userPracticeLocations;
  const isBusinessOwner = isLoginUserBusinessOwner() || isLoggedInUserWorkFlowOrCustomerSuccess();
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const [userPracticeLocations, setUserPracticeLocations] = useState<string[]>([])
  const careJourneyStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_STATUS
    ) || [];

  const careJourneyStatusIds: ICareJourneyStatusIDs = {
    draft: getMlovIdFromCode(
      careJourneyStatusMlov,
      CARE_PROGRAM_STATUS_CODES.DRAFT
    ),
    published: getMlovIdFromCode(
      careJourneyStatusMlov,
      CARE_PROGRAM_STATUS_CODES.ACTIVE
    ),
  };
  const [searchCareJourneys] = useLazyQuery(
    SEARCH_ACTIVE_CARE_JOURNEYS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getContactAccountPracticeLocation] = useLazyQuery(
    GetContactAccountPracticeLocation,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const locationList = accountLocationUuidListByUserPracticeLocationList(userPracticeLocationsObj);

        if (props?.contactId) {
          const contactAccountLocationUuidList: string[] = [];
          const result = await getContactAccountPracticeLocation({
            variables: {
              id: props?.contactId
            }
          });

          if (
            result?.data &&
            result?.data?.contact &&
            result?.data?.contact?.contactPracticeLocations &&
            result?.data?.contact?.contactPracticeLocations?.length > 0
          ) {
            const contactPracticeLocations = result?.data.contact.contactPracticeLocations;
            const accountLocationUuid = contactPracticeLocations[0]?.accountLocation?.uuid;
            if (accountLocationUuid) {
              contactAccountLocationUuidList.push(accountLocationUuid);
            }
          }
          const locations = getLocationIntersectionList(contactAccountLocationUuidList, locationList, isBusinessOwner);
          setUserPracticeLocations(locations);
        } else if (props?.locationIds && props?.assignJourneyToSelectedMembers) {
          setUserPracticeLocations(props?.locationIds);
        } else {
          if(props?.locationIds && props?.popGroupAndPracticeLocationFilter){
            const locations = getLocationIntersectionList(props?.locationIds, locationList, isBusinessOwner);
            setUserPracticeLocations(locations);
          }else{
            setUserPracticeLocations(locationList);
          }
        }
      } catch (error) {
        console.error('Error fetching user practice locations:', error);
      }
    };

    if (isMsoEnabled) {
      fetchData();
    }
  }, [isMsoEnabled, props?.contactId]);


  useEffect(() => {
    if (value && value.id && careJourneyData.length === 0) {
      setCareJourneyData([
        {
          id: value.id,
          title: value.title,
          productId: value.productId,
        },
      ]);
    }
    searchCareJourney('', false);
  }, [userPracticeLocations]);

  const searchCareJourney = (searchString: string, checkLength?: boolean) => {
    setCareJourneyData([]);
    setLoading(true);
    const searchVariables = {
      searchString: `%${searchString}%`,
      statusId: careJourneyStatusIds.published,
      boolExp: {}
    }
    if (isMsoEnabled && (props?.contactId ? true : !isBusinessOwner) ) {
      searchVariables.boolExp = {
        careJourneyLocations: {
          isDeleted: {
            _eq: false
          },
          locationId: {
            _in: userPracticeLocations
          }
        }
      }
    }
    searchCareJourneys({
      variables: searchVariables
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.careJourneys &&
          response.data.careJourneys.length > 0
        ) {
          setCareJourneyData(response.data.careJourneys);
        }
        setLoading(false);
      })
      .catch((error: any) => {

        setLoading(false);
      });
  };

  const getDataFromId = (id: any) => {
    const matchedData = careJourneyData.filter((careJourney) => {
      return careJourney.id === id;
    });
    if (matchedData.length > 0) {
      return {
        id: matchedData[0].id,
        title: matchedData[0].title,
        productId: matchedData[0].productId,
        startDateTime: matchedData[0]?.startDateTime,
        careJourneyLocations: matchedData[0]?.careJourneyLocations,
      };
    }
  };

  return (
    <Select
      size="large"
      showSearch
      allowClear
      filterOption={false}
      disabled={props?.isDisabled}
      value={value && !loading ? value.id : undefined}
      onSearch={debounce(searchCareJourney, 500)}
      suffixIcon={
        <ChevronDownSvg />
      }
      onChange={(value: any[], data: any) => {
        if (data && data.value) {
          onChange(getDataFromId(data.value));
        } else {
          onChange(undefined);
          searchCareJourney('', false);
        }
      }}
      onClear={() => {
        props?.onClear && props.onClear();
      }}
      placeholder={props.placeHolder ?? "Search Care Journey"}
      loading={loading}
      notFoundContent={loading && <Spin size="small" />}
      className={isShowError && !value ? 'custom-select-box field-error' : 'custom-select-box'}
      {...(props?.extraSelectProps ? props.extraSelectProps : {})}
    >
      {careJourneyData.map((careJourney, index) => {
        return (
          <Select.Option
            key={`${careJourney.id}_${index}`}
            value={careJourney.id}
          >
            {careJourney.title}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default CareJourneySearch;
