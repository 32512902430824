import { APPOINTMENT_STATUS_CODES } from '../../../constants/MlovConst';
import {ILocation, IUser} from '../../../Interfaces';
import { Colors } from '../../../styles';
import { StrCaseConverter } from '../../../utils/CaseConverter';
import { isDayAndDateInPast } from '../../../utils/DateUtils';
import { isWeb } from '../../../utils/platformCheckUtils';
import { EHRName } from '../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { defaultAppointmentTypeColor } from '../../RightSideContainer/AccountSettings/AppointmentTypes/AppointmentTypeConst';
import {IScheduleUserResponse} from '../../RightSideContainer/UserScheduleSetting/interfaces';
import {getAccountUserListWithExternalUserWithLocationGroupId} from './BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import {IAppointmentDetail, ICalendarEvent, ICalendarWidgetMetaData} from './CalendarWidgetInterfaces';

const defaultLocationId: string = Math.random().toString();
const defaultAccountId: string = Math.random().toString();

export const isAppointmentWithExternalUser = (
  currentEHR: string,
  rescheduleUserId: string,
  accountUserList: any[],
  locationGroupId: string
) => {
  const isFold = currentEHR === EHRName.FOLD;
  if (!isFold && rescheduleUserId) {
    const rescheduledUser = accountUserList.find(
      (user) => rescheduleUserId && user.uuid === rescheduleUserId
    );
    const rescheduledUserExternalId =
      getAccountUserListWithExternalUserWithLocationGroupId(
        [rescheduledUser],
        locationGroupId || ''
      )?.[0]?.externalUserId;
    return !!(rescheduledUserExternalId);
  }
  return false;
}

const filterNonActiveUsers = (accountUsers: any[]) => {
  const filteredAccountUsers = ((accountUsers).filter(
    (user: any) => user.accountUsers[0].isActive === true))
  return filteredAccountUsers
}

export const processCalendarWidgetUsersData = (
  userScheduleAccess: IScheduleUserResponse[],
  accountUserList: any[],
  addAllValuesOptionInList: boolean,
  selectedUserId?: string,
  filterParams?: {
    currentEHR: string;
    rescheduleUserId: string;
    locationGroupId?: string;
  },
  // This is used only in case of AppointmentBooking.tsx, as here selectedUserId can be different from loggedInUserId
  loggedInUserId?: string,
): ICalendarWidgetMetaData => {
  const accountUsers: IUser[] = [];
  const accountLocations: ILocation[] = [];
  const userLocationMap: Map<string, ILocation[]> = new Map<
    string,
    ILocation[]
  >();
  const locationUserMap: Map<string, IUser[]> = new Map<string, IUser[]>();
  let selectedLocation: ILocation = {} as ILocation;
  const allowOnlyExternalUsers = false;
  // NO need of this code
  // if (filterParams) {
  //   allowOnlyExternalUsers = isAppointmentWithExternalUser(
  //     filterParams.currentEHR,
  //     filterParams.rescheduleUserId,
  //     accountUserList || [],
  //     filterParams?.locationGroupId || ''
  //   );
  // }
  filterNonActiveUsers(accountUserList || []).forEach((user: IUser) => {
    const userExternalId =  getAccountUserListWithExternalUserWithLocationGroupId(
      [user],
      filterParams?.locationGroupId || ''
    )?.[0]?.externalUserId;
    if (
      (
        // This first condition is used only in case of AppointmentBooking.tsx, as here selectedUserId can be different from loggedInUserId
        user?.uuid === loggedInUserId ||
        user?.uuid === selectedUserId ||
        isUserExistInScheduleAccessUser(userScheduleAccess, user?.uuid)
      ) &&
      ((allowOnlyExternalUsers && userExternalId) || !allowOnlyExternalUsers)
    ) {
      setUserWiseLocationMap(user, accountUsers, userLocationMap);
      setLocationWiseUserMap(user, locationUserMap, accountLocations);
    }
  });
  if (addAllValuesOptionInList) {
    // Updated following for All Locations
    updateDefaultLocationForUser(userLocationMap);
    updateDefaultUserForLocation(locationUserMap);

    //accountLocations.push(getAllLocationField());
    accountLocations.splice(0, 0, getAllLocationField());
    accountUsers.splice(0, 0, getAllUserField());
  }

  if (selectedUserId) {
    selectedLocation = getDefaultLocation(userLocationMap, selectedUserId);
  }
  if (!selectedLocation?.name) {
    selectedLocation = accountLocations[0];
  }

  return {
    accountUsers,
    accountLocations,
    userLocationMap,
    locationUserMap,
    selectedLocation,
  } as ICalendarWidgetMetaData;
};

const isUserExistInScheduleAccessUser = (
  userScheduleAccess: IScheduleUserResponse[],
  userId: string
) => {
  return (userScheduleAccess || []).some((user) => {
    return user.scheduleAccessUserId === userId;
  });
};

const setUserWiseLocationMap = (
  user: any,
  userList: IUser[],
  userLocationMap: Map<string, ILocation[]>
) => {
  const userLocationList: ILocation[] = [];
  (user?.userPracticeLocations || []).forEach((location: any) => {
    if (location?.accountLocation?.uuid && location?.accountLocation?.practiceLocation?.name) {
      userLocationList.push(getLocationDetailForMap(location.accountLocation));
    }
  });
  userList.push(getUserDetailForMap(user));
  userLocationMap.set(user.uuid, userLocationList);
};

const getLocationDetailForMap = (location: any): ILocation => {
  return {
    id: location?.uuid,
    name: location?.practiceLocation?.name || '',
    key: location?.uuid,
    uuid: location?.uuid,
  } as ILocation;
};

const getUserDetailForMap = (user: any): IUser => {
  return {
    id: user?.uuid,
    name: user?.name || '',
    key: user?.uuid,
    uuid: user?.uuid,
    userRoles: user.userRoles,
    userPracticeLocations: user?.userPracticeLocations,
    externalUserId: user.externalUserId || user.accountUsers?.[0]?.externalUserId
  } as IUser;
};

const setLocationWiseUserMap = (
  user: any,
  locationUserMap: Map<string, IUser[]>,
  accountLocations: ILocation[]
) => {
  (user?.userPracticeLocations || []).forEach((location: any) => {
    let locationUsers: IUser[] = [];

    if (location?.accountLocation?.uuid && location?.accountLocation?.practiceLocation?.name) {
      const locationId: string = location.accountLocation.uuid;
      if (locationUserMap.has(locationId)) {
        locationUsers = locationUserMap.get(locationId) || [];
      } else {
        accountLocations.push(
          getLocationDetailForMap(location.accountLocation)
        );
      }
      locationUserMap.set(locationId, [
        ...locationUsers,
        getUserDetailForMap(user),
      ]);
    }
  });
};

const updateDefaultLocationForUser = (
  userLocationMap: Map<string, ILocation[]>
) => {
  userLocationMap.forEach((locationList: ILocation[], userKey: string) => {
    //locationList.push(getAllLocationField());
    locationList.splice(0, 0, getAllLocationField());
    userLocationMap.set(userKey, locationList);
  });
};

const updateDefaultUserForLocation = (
  locationUserMap: Map<string, IUser[]>
) => {
  locationUserMap.forEach((userList: IUser[], locationKey: string) => {
    userList.splice(0, 0, getAllUserField());
    locationUserMap.set(locationKey, userList);
  });
};

const getAllLocationField = () => {
  return {
    id: defaultLocationId,
    name: StrCaseConverter.toSentenceCase('All Locations'),
    key: 'ALL_LOCATION',
    uuid: defaultLocationId,
  } as ILocation;
};

export const getAllUserField = () => {
  return {
    id: defaultAccountId,
    name: StrCaseConverter.toSentenceCase('All providers'),
    key: 'ALL_USER',
    uuid: defaultAccountId,
  } as IUser;
};

const getDefaultLocation = (
  userLocationMap: Map<string, ILocation[]>,
  selectedUserId: string
): ILocation => {
  const userLocationList = userLocationMap.get(selectedUserId);
  return userLocationList?.length ? userLocationList[0] : ({} as ILocation);
};

export const getCalenderEventCardStyles = (
  event: ICalendarEvent
): React.CSSProperties => {
  const bgColorPrimary =
    event?.detail?.appointmentType?.appointmentCardProperties?.bgColorPrimary ||
    defaultAppointmentTypeColor?.bgColorPrimary;
  const bgColorSecondary =
    event?.detail?.appointmentType?.appointmentCardProperties
      ?.bgColorSecondary || defaultAppointmentTypeColor?.bgColorSecondary;
  const bgColorPrimaryDisabled =
    event?.detail?.appointmentType?.appointmentCardProperties
      ?.bgColorPrimaryDisabled ||
    defaultAppointmentTypeColor?.bgColorPrimaryDisabled;

  const isBlockedInterval = event?.detail?.isBlockedInterval;
  const isPastAppointment = isDayAndDateInPast(
    event?.detail?.endDateTime || ''
  );
  const isPendingappointment = (event?.statusCode === APPOINTMENT_STATUS_CODES.PENDING)

  const styles: React.CSSProperties = {
    borderWidth: 0,
    borderRadius: 0,
    borderLeftWidth: 4,
    borderLeftStyle: 'solid',
  };
  styles.borderColor = isPastAppointment
    ? bgColorPrimaryDisabled
    : bgColorPrimary;
  styles.backgroundColor = bgColorSecondary;

  if (isBlockedInterval) {
    styles.borderLeftWidth = 0;
    styles.backgroundColor = isWeb() ? Colors.Custom.Gray100 : Colors.Custom.Gray200;
  }
  if (isPendingappointment) {
    styles.backgroundImage = `repeating-linear-gradient(135deg, ${Colors.Custom.stripeColorForPendingAppointment} 0, ${Colors.Custom.stripeColorForPendingAppointment} 5px, ${bgColorSecondary} 0, ${bgColorSecondary} 14px)`
  }
  return styles;
};
