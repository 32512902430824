import {AxiosResponse} from 'axios';
import BaseService from '../../../../../services/CommonService/BaseService';
import {ICreateLabelAndLabelType, IGetProductList, IProductBodyData, IStripeProductSearchQuery} from './interfaces';
import {PACKAGES_PATH} from './PackagesApiPath';

export const createStripePackageApi = (params: IProductBodyData) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.post(`crm-nest/stripe/package`, params);
};

export const updateStripePackageApi = (params: IProductBodyData) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.put(`crm-nest/stripe/package/${params.product?.id}`, params);
};

export const getStripePackageApi = async (params: IStripeProductSearchQuery) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  const res = await axiosService.get(`crm-nest/stripe/package-products`, {
    params: {
      ...params
    }
  });
  return res?.data
};

export const createCopyLinkPackageApi = async (
  id: string
): Promise<{
  link: string;
  name: string;
}> => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  const res = await axiosService.get(`crm-nest/stripe/package/${id}/link`);
  return res?.data;
};

export const updatePackageStatusApi = (id: string, params: any) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.put(`crm-nest/stripe/package/${id}/status`, params);
};

export const createLabelAndLabelType = async (createLabelBody: ICreateLabelAndLabelType[]) => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.post(PACKAGES_PATH.ADD_LABEL, createLabelBody)
  return response;
};