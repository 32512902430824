import { Modal, Button, Text, View } from 'native-base';
import { IDeleteCodeGroupModalProps } from '../interfaces';
import { Colors } from '../../../../../styles';
import { BUTTON_TYPE } from '../../../../../constants';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import { useIntl } from 'react-intl';

const { Body, Content, Footer, Header } = Modal;

const DeleteCodeGroupModal = (props: IDeleteCodeGroupModalProps) => {
  const { isDeleteModalOpen, onDeleteModalClose, onCancel } = props;
  const intl = useIntl();
  return (
    <Modal isOpen={isDeleteModalOpen} onClose={onCancel}>
      <Content
        minWidth={600}
        style={{
          backgroundColor: Colors.Custom.BackgroundColor,
        }}
      >
        <Modal.CloseButton />
        <Header>
          <Text size={'lgNormal'}>Delete Code Group</Text>
        </Header>
        <Body>
          <View>
            <Text size={'mdNormal'}>Are you sure you want to delete this code group?</Text>
          </View>
        </Body>
        <Footer
          style={{
            backgroundColor: Colors.Custom.BackgroundColor,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button.Group space={2}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onCancel();
                },
                style: { marginLeft: 20 },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onDeleteModalClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'delete',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </Footer>
      </Content>
    </Modal>
  );
};

export default DeleteCodeGroupModal;