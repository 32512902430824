import {} from 'native-base';
import * as React from 'react';
import {useIntl} from 'react-intl';
import {
  Animated,
  Platform,
  Pressable,
  Text,
  TouchableOpacity,
  NativeModules,
  View,
} from 'react-native';

import {isWeb} from '../../utils/platformCheckUtils';
import {ToastType, getBackgroundColor} from '../../utils/commonViewUtils';
import Close from '../../assets/svg/Close';

export type ToastConfig = {
  animationType?: 'slide' | 'scale' | 'fade';
  duration?: number;
  message: string;
  onPress?: () => void;
  shouldVibrate?: boolean;
  hideCloseIcon?: boolean;
  toastType: ToastType;
  position?: 'TOP' | 'BOTTOM';
  closeAllPrevToast?: boolean;
};

const statusBarHeight = isWeb()
  ? 20
  : Platform.OS === 'ios'
  ? 50
  : NativeModules.StatusBarManager.HEIGHT || 0;

export type ToastInternalConfig = {
  id?: string;
  index?: number;
  position?: 'TOP' | 'BOTTOM';
  onClose?: (id: string) => void;
};

const offset = statusBarHeight + 16;

const DEFAULT_PROPS: ToastConfig = {
  duration: 4000,
  onPress: () => false,
  shouldVibrate: false,
  message: 'Toast message!',
  animationType: 'slide',
  toastType: ToastType.success,
  position: 'TOP',
};

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity);

export const Toast: React.FC<ToastConfig & ToastInternalConfig> = ({
  animationType,
  duration,
  id,
  index,
  message,
  onClose,
  // onPress,
  position,
  toastType,
}) => {
  const intl = useIntl();
  const topOffset = offset + 60 * (index || 0);

  const animation = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    Animated.timing(animation, {
      toValue: 0.5,
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      if (duration !== 0) {
        const timer = setTimeout(() => {
          if (index === 0) {
            clearTimeout(timer);
          }
          id && onClose && onClose(id);
        }, duration);
      }
    });
  }, []);

  const translateY = animation.interpolate({
    inputRange: [0, 0.5],
    outputRange: [position === 'BOTTOM' ? topOffset : -20, 0],
  });

  const scale = animation.interpolate({
    inputRange: [0, 0.5],
    outputRange: [0.8, 1],
    extrapolate: 'clamp',
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.5],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  const getStyles = () => {
    switch (animationType) {
      case 'fade':
        return {opacity};
      case 'scale':
        return {transform: [{scale}], opacity};
      default:
        return {
          transform: [{translateY}, {scale}],
        };
    }
  };

  return (
    <Animated.View
      style={[
        getStyles(),
        {
          marginVertical: 4,
          alignSelf: 'center',
          maxWidth: '100%',
        },
      ]}
    >
      <View
        style={{
          borderRadius: 8,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: getBackgroundColor(toastType),
          paddingVertical: 8,
          paddingHorizontal: 8,
        }}
      >
        <Text
          style={{
            color: 'white',
            fontSize: 14,
            fontWeight: '400',
            marginRight: 4,
            maxWidth: isWeb() ? '100%' : '95%',
            textAlign: 'center',
          }}
        >
          {message}
        </Text>
        <Pressable onPress={() => onClose && id && onClose(id)}>
          <Close size={18} />
        </Pressable>
      </View>
    </Animated.View>
  );
};

export default React.memo(Toast);

Toast.defaultProps = DEFAULT_PROPS;
