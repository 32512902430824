import {Drawer} from 'antd';
import {
  HStack,
  FormControl,
  TextArea,
  Checkbox,
} from 'native-base';
import {StyleSheet} from 'react-native';
import {useState} from 'react';
import {BUTTON_TYPE} from '../../../../../constants/StringConst';
import {Colors} from '../../../../../styles/Colors';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {IPatientJourneyProps} from './PatientJourneyInterface';
import { capitalizeText } from '../../../ContentManagement/CampaignManagement/Helper';
import ReactQuill from 'react-quill';
import { RTE_MODULES } from '../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';

const DeleteCareJourneyDrawer = (props: IPatientJourneyProps) => {
  const [stateData, setStateData] = useState<{
    comment?: string;
    confirmToDelete?: boolean;
  }>({
    comment: undefined,
    confirmToDelete: false,
  });

  const resetContent = () => {
    setStateData(prev => {
      return {
        ...prev,
        comment: undefined,
        confirmToDelete: false,
      };
    });
  };

  return (
    <Drawer
      destroyOnClose
      placement="right"
      onClose={() => {
        resetContent();
        props.onCancel();
      }}
      visible={props.enabledDrawer}
      closable={false}
      width={'50%'}
      title={<ModalActionTitle
        title={capitalizeText(props?.selectedJourney?.title || props?.selectedJourney?.careJourney?.title)}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: 'cancel',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              resetContent();
              props.onCancel();
            },
          },
          {
            show: true,
            id: 2,
            isDisabled: !(stateData.confirmToDelete && (stateData?.comment || '').trim().length),
            btnText: 'deleteJourney',
            textColor: Colors.Custom.mainPrimaryPurple,
            variant: BUTTON_TYPE.PRIMARY,
            isLoading: props.isLoading,
            isTransBtn: false,
            onClick: () => {
              props.onActionPerformed(null, props.selectedJourney);
              resetContent();
            },
          },
        ]}
      />}
      >
      <HStack flex={1}>
        <FormControl style={[styles.formElement]}>
          <FormControl.Label style={styles.formLabel} isRequired>
            <DisplayText
              textLocalId={'comment'}
              size={'smMedium'}
              extraStyles={{color: Colors.Custom.Gray900}}
            />
          </FormControl.Label>
          <ReactQuill
            className="appointment-notes"
            theme="snow"
            value={stateData.comment || ''}
            onChange={(value: string) => {
              setStateData(prev => {
                return {
                  ...prev,
                  comment: value,
                };
              });
            }}
            modules={RTE_MODULES}
          />
        </FormControl>
      </HStack>
      <HStack marginTop={2}>
        <FormControl>
          <HStack key={'deleteCareJourney'} flex={1} flexWrap={'wrap'}>
            <Checkbox
              marginTop={0.5}
              marginRight={2}
              isChecked={stateData.confirmToDelete}
              onChange={isSelected => {
                setStateData(prev => {
                  return {
                    ...prev,
                    confirmToDelete: isSelected,
                  };
                });
              }}
              value={'confirmToDelete'}></Checkbox>
            <DisplayText
              textLocalId={'deleteJourneyMsg'}
              extraStyles={{
                color: Colors.Custom.Gray700,
                fontWeight: 500,
                fontSize: 14,
              }}
            />
          </HStack>
        </FormControl>
      </HStack>
    </Drawer>
  );
};

export default DeleteCareJourneyDrawer;

export const styles = StyleSheet.create({
  formElement: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  formLabel: {
    marginLeft: 4,
  },
});
