import {HStack, Pressable, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../styles';
import {DisplayText} from '../../DisplayText/DisplayText';
import {DATE_FILTER_LIST} from './HelperUtils';
import {IDateFilterList} from './interfaces';

const DateFilterList = (props: IDateFilterList) => {
  const {onDateSelcted} = props;
  const [selected, setSelected]: any = useState();
  const [dateList, setDateList]: any = useState();
  useEffect(() => {
    setDateList(DATE_FILTER_LIST);
    selected;
  }, []);
  return (
    <>
      <DisplayText
        size={'xsMedium'}
        extraStyles={{fontSize: 10, color: Colors.Custom.Gray500}}
        textLocalId="date"
      />
      <VStack>
        {dateList?.map((item: any) => {
          return (
            <Pressable
              key={item.id}
              onPress={() => {
                setSelected(item);
                onDateSelcted(item);
              }}
            >
              <HStack
                alignItems={'center'}
                flex={1}
                style={{marginVertical: 8}}
              >
                <Feather
                  name={'calendar'}
                  color={Colors.primary['600']}
                  size={20}
                />

                <DisplayText
                  size={'xsMedium'}
                  extraStyles={{color: Colors.Custom.Gray700, marginLeft: 10}}
                  textLocalId={item.name}
                />
              </HStack>
            </Pressable>
          );
        })}
      </VStack>
    </>
  );
};

export default DateFilterList;
