import {Center, Icon, Text, VStack, View} from 'native-base';
import React from 'react';
import NoInternetSVG from '../../assets/svg/NoInternetSvg.web';
import {useIntl} from 'react-intl';
import {Colors} from '../../styles';

const NoInternet = () => {
  const intl = useIntl();
  return (
    <View flex={1} h="100vh" alignItems={'center'} justifyContent={'center'}>
      <Icon as={NoInternetSVG} w={200} h={200} />
      <Text fontWeight={700} fontSize={22} color={Colors.Custom.Gray500}>
        {intl.formatMessage({
          id: 'offlineMessage',
        })}
      </Text>
      <Text fontWeight={500} mt={2} fontSize={18} color={Colors.Custom.Gray300}>
        {intl.formatMessage({
          id: 'noInternetMessageSubTitle',
        })}
      </Text>
    </View>
  );
};

export default NoInternet;
