import {Drawer} from 'antd';
import {
  Divider,
  FormControl,
  HStack,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useContext, useState} from 'react';
import {useIntl} from 'react-intl';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../constants';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import {Colors} from '../../../../../styles';
import { getAccountUUID, getConfigDataFromCode } from '../../../../../utils/commonUtils';
import { showToast, ToastType } from '../../../../../utils/commonViewUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionAntSelect} from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import PublicFormView from '../../../../PublicPages/PublicForm/PublicFormView';
import {styles} from '../../../../RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/CommonStyles';
import {tagColorList} from '../../../Contacts/Tags/TagsModal';
import {ColorSelector} from '../../../Contacts/Tags/TagsModal/ColorSelector';
import { COLOR_SELECTOR_VIEW_CODES } from '../../../Contacts/Tags/TagsModal/ColorSelector/ColorSelector';
import { createOrUpdateAccountConfiguration } from '../../../TeamInbox/DefaultCommunicationGroup/DefaultCommunicationGroupService';
import { ICreateOrUpdateAccountConfig } from '../../../TeamInbox/DefaultCommunicationGroup/interface';
import {
  IAddGlobalThemeErrorState,
  IAddGlobalThemeProps,
  IAddGlobalThemeState,
  IDefaultFormTheme,
} from '../../interfaces';
import WebPreview, { PREVIEW_TYPE } from '../WebPreview/WebPreview';

export const logoPositions = [
  {
    id: 'left',
    code: 'left',
    value: 'Left Align',
  },
  {
    id: 'center',
    code: 'center',
    value: 'Center Align',
  },
  {
    id: 'right',
    code: 'right',
    value: 'Right Align',
  },
];

const AddGlobalTheme = (props: IAddGlobalThemeProps) => {
  const intl = useIntl();
  const accountUUID = getAccountUUID();
  const toast = useToast();
  const defaultThemeConfig = getConfigDataFromCode(CONFIG_CODES.DEFAULT_THEME);
  const commonData: any = useContext(CommonDataContext);
  let configJson = undefined;
  let formThemeConfig: IDefaultFormTheme | undefined  = {} as IDefaultFormTheme;

  if (defaultThemeConfig?.accountConfigurations && defaultThemeConfig?.accountConfigurations?.length) {
    configJson = JSON.parse(defaultThemeConfig?.accountConfigurations[0]?.value);
    formThemeConfig = configJson?.form || undefined;
  } else if (defaultThemeConfig?.defaultValue){
    configJson = JSON.parse(defaultThemeConfig?.defaultValue);
    formThemeConfig = configJson?.form || undefined;
  }
  const [addGlobalThemeState, setGlobalThemeState] =
    useState<IAddGlobalThemeState>({
      headerColor: formThemeConfig?.topBar?.backgroundColor || '#808080',
      fontColor: formThemeConfig?.accountName?.color || '#808080',
      logoPosition: formThemeConfig?.accountLogo?.position || undefined,
      titlePosition: formThemeConfig?.accountName?.position || undefined,
      loading: false,
      isValid: false,
      defaultGlobalTheme: configJson || undefined,
      screenType: PREVIEW_TYPE.DESKTOP,
    });
  const [addGlobalThemeErrorState, setGlobalThemeErrorState] =
    useState<IAddGlobalThemeErrorState>({
      headerColorError: undefined,
      fontColorError: undefined,
      logoPositionError: undefined,
      titlePositionError: undefined,
    });
  const [formValue, setFormValue] = useState<any>(undefined);
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const resetFormDetail = () => {
    setGlobalThemeState((prev) => {
      return {
        ...prev,
        headerColor: undefined,
        fontColor: undefined,
        logoPosition: undefined,
        titlePosition: undefined,
        isValid: false,
      };
    });
  };

  const isValid = () => {
    if (
      !addGlobalThemeState.headerColor ||
      !addGlobalThemeState.fontColor ||
      !addGlobalThemeState.logoPosition ||
      !addGlobalThemeState.titlePosition
    ) {
      setGlobalThemeErrorState((prev) => {
        return {
          ...prev,
          headerColorError: !addGlobalThemeState.headerColor
            ? 'Please select header color'
            : undefined,
          fontColorError: !addGlobalThemeState.fontColor
            ? 'Please select font color'
            : undefined,
          logoPositionError: !addGlobalThemeState.logoPosition
            ? 'Please select logo position'
            : undefined,
          titlePositionError: !addGlobalThemeState.titlePosition ? 'Please select title position' : undefined
        };
      });
      return false;
    }

    return true;
  };

  const onSaveGlobalTheme = async () => {
    if (isValid()) {
      setGlobalThemeState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });

      const configValue = addGlobalThemeState?.defaultGlobalTheme || {};
      const formThemeConfig: IDefaultFormTheme = {
        accountLogo: {position: ''},
        accountName: {color: '', position: ''},
        topBar: {
          backgroundColor: '',
        },
      };

      formThemeConfig.accountLogo['position'] = addGlobalThemeState?.logoPosition;
      formThemeConfig.accountName['position'] = addGlobalThemeState?.titlePosition;
      formThemeConfig.accountName['color'] = addGlobalThemeState?.fontColor;
      formThemeConfig.topBar['backgroundColor'] = addGlobalThemeState.headerColor;
      configValue['form'] = formThemeConfig;

      const params = {configCode: CONFIG_CODES.DEFAULT_THEME, value: JSON.stringify(configValue)};
      const bodyData: ICreateOrUpdateAccountConfig = {...params, accountUuid: accountUUID};
      const accountConfigData = await createOrUpdateAccountConfiguration(bodyData);

      if (accountConfigData?.data && params?.configCode) {
        props.onComplete({...addGlobalThemeState});
        resetFormDetail();
        showToast(
          toast,
          'Form Default theme set successfully',
          ToastType.info,
          undefined,
        );

        const configObj = commonData.accountConfigData[params?.configCode]
        if (configObj?.accountConfigurations?.length) {
          commonData.accountConfigData[params?.configCode].accountConfigurations = [accountConfigData?.data];
        } else {
          if (configObj) {
            if (!configObj?.accountConfigurations) {
              configObj.accountConfigurations = [];
            }
            commonData.accountConfigData[params?.configCode].accountConfigurations = [accountConfigData?.data];
          }
          // commonData.accountConfigData[params?.configCode] = accountConfigData?.data;
        }
        setGlobalThemeState((prev) => {
          return {
            ...prev,
            loading: false,
            defaultGlobalTheme: accountConfigData?.data
          }
        });
      } else {
        showToast(
          toast,
          'Error while updating form default theme',
          ToastType.error,
          undefined,
        );
        setGlobalThemeState((prev) => {
          return {
            ...prev,
            loading: false,
          }
        });
      }
    }
  };

  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '70%' : '60%';

  return (
    <Drawer
      destroyOnClose
      placement="right"
      onClose={() => {
        resetFormDetail();
        props.onCancel();
      }}
      visible={props.isVisible}
      closable
      closeIcon={null}
      width={drawerWidth}
      title={
        <ModalActionTitle
          title="createGlobalTheme"
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: intl.formatMessage({id: 'cancel'}),
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                resetFormDetail();
                props.onCancel();
              },
            },
            {
              show: true,
              id: 2,
              //   isDisabled: !addGlobalThemeState.isValid,
              btnText: intl.formatMessage({id: 'save'}),
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                onSaveGlobalTheme();
              },
            },
          ]}
        />
      }
    >
      <VStack mr={5} overflow={'hidden'}>
        <VStack space={2}>
          <VStack flex={1}>
            <HStack flex={1} space={2}>
              <View flex={1}>
                <FormControl style={styles.formElement} isRequired>
                  <FormControl.Label style={styles.formLabel}>
                    <DisplayText
                      textLocalId="headerColor"
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray700}}
                    />
                  </FormControl.Label>
                  <ColorSelector
                    viewCode={COLOR_SELECTOR_VIEW_CODES.COLOR_AND_NAME}
                    color={addGlobalThemeState.headerColor}
                    colorList={tagColorList}
                    onBtnPress={(color: string) => {
                      setGlobalThemeState((prev) => {
                        return {
                          ...prev,
                          headerColor: color,
                        };
                      });
                    }}
                    onChangeHex={(text: any) => {
                      setGlobalThemeState((prev) => {
                        return {
                          ...prev,
                          headerColor: '#' + text,
                        };
                      });
                    }}
                  />
                  {addGlobalThemeErrorState.headerColorError && (
                    <HStack marginTop={2} space={2}>
                      <Text fontSize="xs" color={Colors.danger[500]}>
                        {addGlobalThemeErrorState.headerColorError}
                      </Text>
                    </HStack>
                  )}
                </FormControl>
              </View>

              <View flex={1}>
                <FormControl style={styles.formElement} isRequired>
                  <FormControl.Label style={styles.formLabel}>
                    <DisplayText
                      textLocalId="fontColor"
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray700}}
                    />
                  </FormControl.Label>
                  <ColorSelector
                    viewCode={COLOR_SELECTOR_VIEW_CODES.COLOR_AND_NAME}
                    color={addGlobalThemeState.fontColor}
                    colorList={tagColorList}
                    onBtnPress={(color: string) => {
                      setGlobalThemeState((prev) => {
                        return {
                          ...prev,
                          fontColor: color,
                        };
                      });
                    }}
                    onChangeHex={(text: any) => {
                      setGlobalThemeState((prev) => {
                        return {
                          ...prev,
                          fontColor: '#' + text,
                        };
                      });
                    }}
                  />
                  {addGlobalThemeErrorState.fontColorError && (
                    <HStack marginTop={2} space={2}>
                      <Text fontSize="xs" color={Colors.danger[500]}>
                        {addGlobalThemeErrorState.fontColorError}
                      </Text>
                    </HStack>
                  )}
                </FormControl>
              </View>
            </HStack>

            <HStack flex={1} space={2}>
              <View flex={1}>
                <FormControl style={styles.formElement} isRequired>
                  <FormControl.Label style={styles.formLabel}>
                    <DisplayText
                      textLocalId="logoPosition"
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray700}}
                    />
                  </FormControl.Label>
                  <ModalActionAntSelect
                    allowClear={false}
                    leftMargin={'0'}
                    showSearch={false}
                    labelInValue={true}
                    filterOption={false}
                    value={addGlobalThemeState.logoPosition}
                    isRequired={false}
                    placeholder={'Select Position'}
                    onChange={(position: any) => {
                      setGlobalThemeState((prev) => {
                        return {
                          ...prev,
                          logoPosition: position?.key,
                        };
                      });
                    }}
                    data={logoPositions}
                    optionProps={{key: 'id', value: 'code', label: 'value'}}
                    extraStyle={{flex: 1}}
                  />
                  {addGlobalThemeErrorState.logoPositionError && (
                    <HStack marginTop={2} space={2}>
                      <Text fontSize="xs" color={Colors.danger[500]}>
                        {addGlobalThemeErrorState.logoPositionError}
                      </Text>
                    </HStack>
                  )}
                </FormControl>
              </View>

              <View flex={1}>
                <FormControl style={styles.formElement} isRequired>
                  <FormControl.Label style={styles.formLabel}>
                    <DisplayText
                      textLocalId="headerTitlePosition"
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray700}}
                    />
                  </FormControl.Label>
                  <ModalActionAntSelect
                    allowClear={false}
                    leftMargin={'0'}
                    showSearch={false}
                    labelInValue={true}
                    filterOption={false}
                    value={addGlobalThemeState.titlePosition}
                    isRequired={false}
                    placeholder={'Select Position'}
                    onChange={(position: any) => {
                      setGlobalThemeState((prev) => {
                        return {
                          ...prev,
                          titlePosition: position?.key,
                        };
                      });
                    }}
                    data={logoPositions}
                    optionProps={{key: 'id', value: 'code', label: 'value'}}
                    extraStyle={{flex: 1}}
                  />
                  {addGlobalThemeErrorState.titlePositionError && (
                    <HStack marginTop={2} space={2}>
                      <Text fontSize="xs" color={Colors.danger[500]}>
                        {addGlobalThemeErrorState.titlePositionError}
                      </Text>
                    </HStack>
                  )}
                </FormControl>
              </View>
            </HStack>

            <Divider marginTop={5} marginBottom={5}/>

            <View height={'750'}>
              {/* <FormControl> */}
                {/* <FormControl.Label style={styles.formLabel}>
                  <DisplayText
                    textLocalId="formGlobalThemePreviewTitle"
                    size={'smMedium'}
                    extraStyles={{color: Colors.Custom.Gray700}}
                  />
                </FormControl.Label> */}

                <View
                  // borderColor={Colors.Custom.Gray700}
                  // borderWidth={1}
                  // borderRadius={5}
                >
                  <WebPreview
                    onScreenTypeChange={(type) => {
                      setGlobalThemeState((prev) => ({...prev, screenType: type}));
                    }}
                  >
                    <PublicFormView
                      accountNameHeaderBgColor={addGlobalThemeState.headerColor}
                      accountNameTextColor={addGlobalThemeState.fontColor}
                      accountLogoPosition={addGlobalThemeState.logoPosition}
                      accountNamePosition={addGlobalThemeState.titlePosition}
                      formData={{
                        name: 'Test Form',
                        components: [
                          {
                            content: '<p><br></p><p><br></p><center><p><span style=\"background-color: rgb(255, 255, 255); color: rgb(36, 36, 36);\">Form content shows up here.</span></p><center><p><br></p><p><br></p><p><span style=\"background-color: rgb(255, 255, 255); color: rgb(36, 36, 36);\"><span class=\"ql-cursor\">﻿</span></span></p>',
                            key: 'paragraph',
                            label: 'Paragraph',
                            type: 'paragraph',
                          },
                          {
                            type: 'button',
                            label: 'Submit',
                            key: 'submit',
                            size: 'md',
                            block: false,
                            action: 'submit',
                            disableOnInvalid: true,
                            theme: 'primary',
                          },
                        ],
                        noData: false,
                        id: 'TestFormPreview',
                      }}
                      optionData={{
                        accountUUID,
                      }}
                      hideBorder={addGlobalThemeState.screenType !== PREVIEW_TYPE.DESKTOP}
                      extraStyles={
                        addGlobalThemeState.screenType === PREVIEW_TYPE.DESKTOP
                          ? {
                              marginX: {
                                base: 0,
                                lg: 20,
                              },
                            }
                          : {
                              marginX: {
                                base: 0,
                                lg: 0,
                              },
                              marginY: {
                                base: 0,
                                lg: 0,
                              },
                              padding: 2,
                            }
                      }
                    />
                  </WebPreview>
                </View>
              {/* </FormControl> */}
            </View>
          </VStack>
        </VStack>
      </VStack>
    </Drawer>
  );
};

export default AddGlobalTheme;
