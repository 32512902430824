import {HStack, Icon, IconButton, Text, View} from 'native-base';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {Colors} from '../../../../../../styles';
import {testID, TestIdentifiers} from '../../../../../../testUtils';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {COST_TYPE_CODE, SavingTypeConst} from '../helperFiles/CostTemplatesConst';
import AntIcon from 'react-native-vector-icons/AntDesign';
import CopyIconSvg from '../../../../../common/Svg/CopyIconSvg';
import {ISavingCptTable} from '../helperFiles/interface';

export const getCostTableColumns = (
  onActionPerformed: (actionCode: string, rowData?: any) => void,
  costType?: string,
  tabName?: string
): any[] => {
  const costTypeText =
    costType == COST_TYPE_CODE.MARKET_COST
      ? 'marketCost'
      : costType == COST_TYPE_CODE.OUR_COST
        ? 'ourServiceCost'
        : ' ';
    const isVisitSaving = tabName === SavingTypeConst.VISITS_SAVINGS
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId={ isVisitSaving ? "cptAndModifier" : 'cpt'}
        />
      ),
      dataIndex: 'cptCode',
      key: 'cptCode',
      width: '10%',
      fixed: 'left',
      ellipsis: true,
      render: (cptCode: string, data: any): JSX.Element => {
        return (
          <HStack
          >
            <Text
              noOfLines={1}
              size={'smSemibold'}
              style={{
                lineHeight: 24,
                color: '#000',
              }}
            >
              {cptCode}
            </Text>
            <Text
              noOfLines={1}
              size={'smSemibold'}
              style={{
                lineHeight: 24,
                color: Colors.Custom.Gray600,
                marginLeft: 12,
                display: isVisitSaving ? 'flex' : 'none'
              }}
            >
              {data?.modifier}
            </Text>
          </HStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '45%',
      render: (name: any): JSX.Element => {
        return (
          <View flex={1}>
            <Text
              noOfLines={2}
              size={'smSemibold'}
              style={{
                color: Colors.Custom.Gray500,
                lineHeight: 21,
              }}
            >
              {name || '--'}
            </Text>
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId={costTypeText}
        />
      ),
      dataIndex: 'costPrice',
      key: 'costPrice',
      width: '10%',
      render: (costPrice: any): JSX.Element => {
        return (
          <View width={250} flex={1}>
            <Text
              noOfLines={2}
              style={{
                color: Colors.Custom.Gray500,
                fontSize: 14,
                fontWeight: '400',
                lineHeight: 21,
              }}
            >
              $ {costPrice}
            </Text>
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId=" "
        />
      ),
      key: 'action',
      width: '10%',
      fixed: 'right',
      render: (selectedRecord: ISavingCptTable) => {
        return (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <IconButton
              colorScheme="gray"
              tintColor="gray.500"
              variant="ghost"
              onPress={() =>
                onActionPerformed(COMMON_ACTION_CODES.EDIT, selectedRecord)
              }
              {...testID(TestIdentifiers.deleteBtn)}
              icon={
                <Icon as={AntIcon} name="edit" size="4" color="gray.400" />
              }
            />
            <IconButton
              colorScheme="gray"
              tintColor="gray.500"
              variant="ghost"
              onPress={() =>
                onActionPerformed(COMMON_ACTION_CODES.DELETE, selectedRecord)
              }
              {...testID(TestIdentifiers.deleteBtn)}
              icon={
                <Icon as={AntIcon} name="delete" size="4" color="gray.400" />
              }
            />
          </View>
        );
      },
      responsive: ['md'],
    },
  ];
};
