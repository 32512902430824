import React from 'react';
import {ICommonSvgProps} from './interfaces';

const OutlookSvg = (props: ICommonSvgProps) => {
  const height = props.height || '16';
  const width = props.width || '16';
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_202_7325)">
        <path
          d="M14 8.27896C14.0007 8.18521 13.9522 8.09779 13.8723 8.04881H13.8709L13.866 8.046L9.70769 5.58459C9.68964 5.5724 9.67113 5.56139 9.65191 5.55131C9.49136 5.46857 9.30058 5.46857 9.14004 5.55131C9.12082 5.56139 9.10207 5.5724 9.08425 5.58459L4.92597 8.04576L4.92105 8.04857C4.79402 8.12756 4.75511 8.29467 4.8341 8.4217C4.8573 8.4592 4.88941 8.49037 4.92761 8.51264L9.08566 10.974C9.10371 10.986 9.12222 10.9972 9.14144 11.0073C9.30199 11.0901 9.49277 11.0901 9.65332 11.0073C9.67254 10.9972 9.69105 10.9862 9.7091 10.974L13.8671 8.51264C13.9503 8.46436 14.001 8.37506 14 8.27896Z"
          fill="#0A2767"
        />
        <path
          d="M5.41088 6.63557H8.13971V9.13682H5.41088V6.63557ZM13.442 4.09283V2.94861C13.4485 2.66244 13.2221 2.42525 12.936 2.41846H5.85479C5.56862 2.42525 5.34221 2.66268 5.34877 2.94861V4.09283L9.53471 5.20916L13.442 4.09283Z"
          fill="#0364B8"
        />
        <path
          d="M5.34863 4.09277H8.13934V6.60434H5.34863V4.09277Z"
          fill="#0078D4"
        />
        <path
          d="M10.9301 4.09277H8.1394V6.60434L10.9301 9.1159H13.4417V6.60457L10.9301 4.09277Z"
          fill="#28A8EA"
        />
        <path
          d="M8.1394 6.60498H10.9301V9.11654H8.1394V6.60498Z"
          fill="#0078D4"
        />
        <path
          d="M8.1394 9.11621H10.9301V11.6278H8.1394V9.11621Z"
          fill="#0364B8"
        />
        <path
          d="M5.41089 9.13672H8.13972V11.4106H5.41089V9.13672Z"
          fill="#14447D"
        />
        <path
          d="M10.9302 9.11621H13.4417V11.6278H10.9302V9.11621Z"
          fill="#0078D4"
        />
        <path
          d="M13.8723 8.49748L13.867 8.50029L9.70891 10.8389C9.69086 10.8501 9.67234 10.8607 9.65313 10.8701C9.58258 10.9036 9.5057 10.9228 9.42766 10.9258L9.20055 10.7929C9.18133 10.7833 9.16281 10.7726 9.14477 10.7608L4.9307 8.35592H4.92883L4.79102 8.27881V13.0132C4.79313 13.3291 5.05094 13.5834 5.36664 13.5813H13.4334C13.438 13.5813 13.4423 13.579 13.4474 13.579C13.5142 13.5747 13.5798 13.5609 13.6427 13.5382C13.6698 13.5267 13.6961 13.5131 13.7212 13.4974C13.7399 13.4869 13.772 13.4637 13.772 13.4637C13.915 13.3579 13.9996 13.1908 14.0003 13.0129V8.27904C14.0001 8.36951 13.9511 8.45295 13.8723 8.49748Z"
          fill="url(#paint0_linear_202_7325)"
        />
        <path
          opacity="0.5"
          d="M13.7766 8.26016V8.55031L9.42866 11.544L4.92726 8.35789C4.92726 8.35625 4.92608 8.35508 4.92444 8.35508L4.51147 8.10664V7.89734L4.68163 7.89453L5.04163 8.10102L5.05007 8.10383L5.08077 8.12328C5.08077 8.12328 9.31147 10.5373 9.32272 10.5427L9.48468 10.6377C9.49874 10.632 9.51257 10.6264 9.52944 10.621C9.53788 10.6154 13.7294 8.25734 13.7294 8.25734L13.7766 8.26016Z"
          fill="#0A2767"
        />
        <path
          d="M13.8723 8.49773L13.867 8.50078L9.70891 10.8394C9.69086 10.8506 9.67234 10.8612 9.65313 10.8705C9.49164 10.9495 9.30273 10.9495 9.14125 10.8705C9.12203 10.8612 9.10352 10.8506 9.08547 10.8394L4.92742 8.50078L4.9225 8.49773C4.84211 8.45414 4.79195 8.37047 4.79102 8.2793V13.0134C4.79313 13.3291 5.0507 13.5837 5.36641 13.5816H13.4249C13.7406 13.5837 13.9984 13.3291 14.0003 13.0134V8.2793C14.0001 8.36977 13.9511 8.4532 13.8723 8.49773Z"
          fill="#1490DF"
        />
        <path
          opacity="0.1"
          d="M9.76938 10.8047L9.70704 10.8396C9.68899 10.8511 9.67048 10.8616 9.65126 10.8712C9.58259 10.905 9.50806 10.9247 9.43188 10.9294L11.0139 12.8002L13.7737 13.4651C13.8494 13.4079 13.9094 13.3329 13.9488 13.2466L9.76938 10.8047Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M10.0513 10.646L9.70728 10.8394C9.68924 10.8508 9.67072 10.8614 9.6515 10.871C9.58283 10.9047 9.5083 10.9244 9.43213 10.9291L10.1732 12.9726L13.7746 13.4641C13.9164 13.3577 14.0001 13.1906 14.0001 13.0132V12.952L10.0513 10.646Z"
          fill="black"
        />
        <path
          d="M5.37413 13.5816H13.424C13.548 13.5823 13.6687 13.5431 13.7685 13.47L9.20007 10.7939C9.18085 10.7843 9.16233 10.7735 9.14429 10.7618L4.93022 8.35687H4.92835L4.79077 8.2793V12.9973C4.79054 13.3195 5.05163 13.5813 5.37413 13.5816Z"
          fill="#28A8EA"
        />
        <path
          opacity="0.1"
          d="M8.6978 5.16277V11.1154C8.69733 11.324 8.5703 11.5118 8.37694 11.5898C8.31694 11.6156 8.25249 11.6289 8.1871 11.6289H4.79077V4.93051H5.34882V4.65137H8.18687C8.46905 4.6523 8.6971 4.88082 8.6978 5.16277Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.41866 5.44183V11.3945C8.41936 11.462 8.40507 11.5286 8.37671 11.5897C8.29913 11.7807 8.11397 11.9061 7.90796 11.9071H4.79077V4.93019H7.90796C7.98882 4.92949 8.06874 4.94965 8.13952 4.98879C8.31062 5.07504 8.41866 5.25035 8.41866 5.44183Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.41866 5.44183V10.8362C8.41726 11.1179 8.18968 11.3464 7.90796 11.3488H4.79077V4.93019H7.90796C7.98882 4.92949 8.06874 4.94965 8.13952 4.98879C8.31062 5.07504 8.41866 5.25035 8.41866 5.44183Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.13952 5.44182V10.8362C8.13929 11.1184 7.91101 11.3474 7.62882 11.3488H4.79077V4.93018H7.62882C7.91101 4.93041 8.13976 5.15916 8.13952 5.44135C8.13952 5.44158 8.13952 5.44158 8.13952 5.44182Z"
          fill="black"
        />
        <path
          d="M2.51164 4.93018H7.62805C7.91047 4.93018 8.13969 5.15916 8.13969 5.44182V10.5582C8.13969 10.8406 7.9107 11.0699 7.62805 11.0699H2.51164C2.22898 11.0699 2 10.8406 2 10.5582V5.44182C2 5.15939 2.22898 4.93018 2.51164 4.93018Z"
          fill="url(#paint1_linear_202_7325)"
        />
        <path
          d="M3.59898 7.07571C3.72507 6.80712 3.92851 6.58235 4.18327 6.43025C4.46546 6.26876 4.78679 6.18814 5.11163 6.19751C5.4128 6.19095 5.70999 6.26735 5.97085 6.41829C6.21601 6.56454 6.41358 6.77876 6.53921 7.03493C6.67632 7.31736 6.74452 7.62814 6.73843 7.94196C6.74499 8.26986 6.67491 8.59493 6.53335 8.89071C6.40468 9.15626 6.20077 9.37798 5.94741 9.52892C5.67647 9.68454 5.36827 9.76282 5.05608 9.75556C4.74835 9.76306 4.4446 9.68571 4.17788 9.5322C3.93062 9.38571 3.73069 9.17126 3.60179 8.91439C3.46397 8.63618 3.39483 8.32868 3.40023 8.01837C3.3946 7.69329 3.46257 7.37103 3.59898 7.07571ZM4.22171 8.59118C4.28898 8.7611 4.40288 8.90829 4.55054 9.01587C4.70077 9.12087 4.88077 9.17501 5.06405 9.17056C5.25929 9.17829 5.45171 9.12228 5.61226 9.01095C5.75804 8.9036 5.8689 8.75595 5.93148 8.58626C6.00155 8.39665 6.03601 8.19579 6.03343 7.99376C6.03554 7.78985 6.00319 7.58689 5.93733 7.39376C5.87921 7.21939 5.77187 7.06564 5.62819 6.95126C5.47163 6.83478 5.27991 6.77548 5.08491 6.78392C4.89765 6.779 4.71366 6.8336 4.55944 6.94001C4.40944 7.04806 4.29319 7.19665 4.22452 7.36845C4.07218 7.76196 4.07124 8.19814 4.22218 8.59212L4.22171 8.59118Z"
          fill="white"
        />
        <path
          d="M10.9302 4.09277H13.4417V6.60434H10.9302V4.09277Z"
          fill="#50D9FF"
        />
      </g>
      <rect
        x="2.01172"
        y="2.01172"
        width="11.9766"
        height="11.9766"
        stroke="#667085"
        stroke-width="0.0234375"
      />
      <defs>
        <linearGradient
          id="paint0_linear_202_7325"
          x1="9.39542"
          y1="8.27898"
          x2="9.39542"
          y2="13.5813"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#35B8F1" />
          <stop offset="1" stop-color="#28A8EA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_202_7325"
          x1="3.06656"
          y1="4.53055"
          x2="7.07297"
          y2="11.4695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1784D9" />
          <stop offset="0.5" stop-color="#107AD5" />
          <stop offset="1" stop-color="#0A63C9" />
        </linearGradient>
        <clipPath id="clip0_202_7325">
          <rect x="2" y="2" width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OutlookSvg;
