import {Divider, HStack, Spacer, View, VStack} from 'native-base';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import {SearchBar} from '../../common/SearchBar';

export const UserScheduleTopBar = (props: {
  onSearch: (text: string) => void;
}) => {
  return (
    <View>
      <View paddingX={4} paddingY={2}>
        <HStack>
          <VStack justifyContent={'center'}>
            <View>
              <DisplayText textLocalId="scheduleAccessUser" size="lgNormal" textType="Heading" />
            </View>
          </VStack>
          <Spacer />
          <VStack>
            <HStack justifyContent={'flex-end'} alignItems="center">
              <VStack justifyContent={'flex-end'}>
                <View marginRight={5}>
                  <SearchBar
                    onChange={(value: string) => {
                      props.onSearch(value);
                    }}
                  />
                </View>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      </View>
      <Divider />
    </View>
  );
};

export default UserScheduleTopBar;
