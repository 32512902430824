import React from 'react';
import { HStack, Pressable, Divider, Text as NativeText, VStack, Input, View } from 'native-base';
import { Colors } from '../../../../../../styles';
import EyeOpenSvg from '../../../../Svg/WidgetsSvg/EyeOpenSvg';
import DraggableIconSvg2024 from '../../../../Svg/DraggableIconSvg2024';
import CloseSvg from '../../../../Svg/CloseSvg';
import NewSearchIconSvg from '../../../../../../assets/svg/NewSearchIconSvg';
import { DragDropContext, Droppable, Draggable, DroppableProvided, DroppableStateSnapshot, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import FoldButtonV2, { ButtonType } from '../../../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import RestartSvg from '../../../../../../assets/Icons/RestartSvg';
import EyeClosedSvgNew from '../../../../Svg/WidgetsSvg/EyeClosedSvgNew';

interface ColumnFilterDropdownProps {
  setPopoverVisible: (visible: boolean) => void;
  onDragEnd: (result: any) => void;
  handleResetToDefault: () => void;
  columns: any[];
  toggleVisibility: (index: number) => void;
  searchColumns: (searchText: string) => void;
}

const ColumnFilterDropdown = ({ setPopoverVisible, onDragEnd, handleResetToDefault, columns, toggleVisibility, searchColumns }: ColumnFilterDropdownProps) => {
  return (
    <VStack padding={'12px'} space={'10px'} >
      <HStack justifyContent="space-between" alignItems="center">
        <NativeText color={Colors.FoldPixel.GRAY400} fontSize={14} fontWeight={500}>Customize Table View</NativeText>
        <Pressable onPress={() => setPopoverVisible(false)}>
          <CloseSvg />
        </Pressable>
      </HStack>
      <Input
        width={'full'}
        borderColor={Colors.FoldPixel.GRAY200}
        placeholder="Search"
        paddingLeft={2}
        InputLeftElement={<View paddingLeft={2}><NewSearchIconSvg width={14} height={14} strokeColor={Colors.FoldPixel.GRAY300} /></View>}
        onChangeText={searchColumns}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="columns">
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <VStack
              gap={'8px'}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {columns?.map((column, index) => (
                <Draggable key={column?.key} draggableId={column?.key} index={index} isDragDisabled={false}>
                  {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      style={{
                        ...(provided.draggableProps.style as any),
                        opacity: snapshot.isDragging ? 0.5 : 1
                      }}
                    >
                      <HStack
                        height={'32px'}
                        width={'232px'}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <HStack space={4} alignItems="center">
                        <Pressable>
                            <DraggableIconSvg2024 />
                          </Pressable>
                          <NativeText fontWeight={400} fontSize={14} color={Colors.FoldPixel.GRAY400}>{column?.label}</NativeText>
                        </HStack>
                          <Pressable onPress={() => toggleVisibility(index)}>
                            {column?.hidden ? (
                              <EyeClosedSvgNew/>
                            ) : (
                              <EyeOpenSvg width={22} height={18} customStrokeColor={Colors.FoldPixel.GRAY300} /> 
                          )}
                        </Pressable>
                      </HStack>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided?.placeholder}
            </VStack>
          )}
        </Droppable>
      </DragDropContext>
      <Divider />
      <FoldButtonV2
        leftIcon={<RestartSvg />}
        label="Reset to Default"
        onPress={handleResetToDefault}
        buttonType={ButtonType.secondary}
        borderColor={Colors.FoldPixel.GRAY250}
      />
    </VStack>
  );
};

export default ColumnFilterDropdown;