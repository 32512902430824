import React, { useMemo } from 'react';
import styles from './BillingCompletion.module.css';
import StatusItem from './StatusItem';
import { useContactCareProgramContext } from '../../../ContactCareProgram.context';
import { getCardMainTitle } from '../../../../../CareManagementView/CareManagementUtils';
import { useContactCareProgramBilling } from '../BillingView/useContactCareProgramBilling';
import { Image } from 'native-base';

interface BillingCompletionProps {}

const BillingCompletion: React.FC<BillingCompletionProps> = () => {
  const {state} = useContactCareProgramContext();
  const {hookState: {attributesAndCodes, totalBilling}} = useContactCareProgramBilling({
    showOnlyCompleted: true,
  });
  const {contactCareProgramDetails} = state;
  const careProgramTypeCode = contactCareProgramDetails?.payerCareProgram?.careProgramType?.code;
  const totalDuration = useMemo(() => attributesAndCodes.reduce((acc, curr) => acc + curr.durationInMinutes, 0), [attributesAndCodes]);

  // Temp change for testing, to be replace with SVG
  const statusItems = [
    { iconSrc: "https://cdn.builder.io/api/v1/image/assets/2a00861c785e4375bbbcc18363c15940/a362afde3727fe007511259dffe2996f8b2322cae91d8dcc78a561a580e02ba5?apiKey=2a00861c785e4375bbbcc18363c15940&", text: `${careProgramTypeCode} Completed`, alt: "Completed Icon" },
    { iconSrc: "https://cdn.builder.io/api/v1/image/assets/2a00861c785e4375bbbcc18363c15940/f0f180d5ffc801894314e7ad8b62f97e21aa1000cb70c12c069329bb30088b9e?apiKey=2a00861c785e4375bbbcc18363c15940&", text: `$${totalBilling} Sent to EHR for Billing`, alt: "Billing Icon" },
  ];

  const getTitle = () => {
    return getCardMainTitle(careProgramTypeCode || '');
  }

  return (
    <section className={styles.completionArea}>
      <header className={styles.headerContent}>
        <Image
          width={'200px'}
          height={'160px'}
          source={require('../../../../../../../assets/images/Done.gif')}
          alt={'Success Icon'}
          alignItems={'center'}
        />
        {/* <Image
          width={'60px'}
          height={'360px'}
          source={require('../../../../../../../assets/images/SuccessConfetti.gif')}
          alt={'Success Icon'}
          alignItems={'center'}
          style={{position: 'absolute'}}
        /> */}
        <h1 className={styles.title}>{getTitle()} Completed</h1>
        <p className={styles.subtitle}>{`Total Time: ${totalDuration} mins`}</p>
      </header>
      <div className={styles.statusContainer}>
        {statusItems.map((item, index) => (
          <StatusItem key={index} iconSrc={item.iconSrc} text={item.text} alt={item.alt} />
        ))}
      </div>
      {/* <div className={styles.actionArea}>
        <button className={styles.actionButton}>
          <span className={styles.buttonContent}>
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/2a00861c785e4375bbbcc18363c15940/bb51b577a3e22da2d55d287d9fc760af3a36494b601193b9223d8d9deb7fa4e3?apiKey=2a00861c785e4375bbbcc18363c15940&" className={styles.buttonIcon} alt="" />
            <span className={styles.buttonText}>Download as PDF</span>
          </span>
        </button>
      </div> */}
    </section>
  );
};

export default BillingCompletion;
