export interface IAuditLogData {
  auditId: string;
  parentResourceId: string;
  parentResourceTypeCode: ActionTypeNames;
  actionBy?: string;
  createdOn?: string;
  submittedCount?: number;
  draftCount?: number;
  pendingCount?: number;
  failedCount?: number;
  successCount?: number;
  contactIdsAdded?: string[];
  contactIdsDropped?: string[];
  membersAddedCount?: number;
  membersDroppedCount?: number;
}

export interface IMappedAccountUsers {
  id: string;
  name: string;
  email: string;
}

export interface IAllDataMappedData {
  id: string;
  name: string;
}

export enum ActionTypeNames {
  RULE_UPDATED = 'RULE_UPDATED',
  FORM = 'FORM',
  CAMPAIGN = 'CAMPAIGN',
  AUTOMATION = 'AUTOMATION',
  JOURNEY = 'JOURNEY',
  TAG = 'TAG',
  RULE_CREATION = 'RULE_CREATION',
}

export enum SelectedTab {
  NEWLY_ADDED = 'NEWLY_ADDED',
  DROPPED_OFF = 'DROPPED_OFF',
}

export interface IRuleMemberData {
  contactIdsAdded: string[];
  contactIdsDropped: string[];
  membersAddedCount: number;
  membersDroppedCount: number;
};
