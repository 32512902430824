import {HolderOutlined} from '@ant-design/icons';
import {useMutation} from '@apollo/client';
import {Drawer, Switch} from 'antd';
import {HStack, Spinner, Text, useToast, View, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DragStart,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../../constants';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import EmployerDashboardQueries from '../../../../../services/Employer/EmployerServices/EmployerDashboardQueries';
import {updateSectionViewAndPosition} from '../../../../../services/Employer/EmployerServices/EmployerServices';
import {Colors} from '../../../../../styles';
import {getSectionUpdateError} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {getAccountConfigValue} from '../../../../../utils/configUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {SECTION_UPDATE_TYPE} from '../EmployerUtils/EmployerConst';
import {getRawPageSection} from '../EmployerUtils/WidgetUtils';
import {IPageSection, ISectionListItem, IUpdateDashboardDrawer} from '../interface';

const UpdateDashboardDrawer = (props: IUpdateDashboardDrawer) => {
  const rawPageSection = getRawPageSection(props.rawPageSection)
  const [visible, setVisible] = useState(true)
  const [stateData, setStateData] = useState({
    rawPageSection: rawPageSection.slice() as ISectionListItem[],
    resetRawData: props.rawPageSection.slice() as ISectionListItem[],
    loading: false
  });
  const intl = useIntl();
  const toast = useToast();
  const {height, width} = Dimensions.get('window');

  const [UPDATE_SECTION_HIDE_SHOW] = useMutation(
    EmployerDashboardQueries.UPDATE_SECTION_HIDE_SHOW
  );

  const showSectionOrWidgetListConfig = getAccountConfigValue(CONFIG_CODES.SHOW_SECTION_OR_WIDGET)
  let showSectionOrWidgetListValue: any = []
  try {
    showSectionOrWidgetListValue = JSON.parse(showSectionOrWidgetListConfig)
  } catch  {
    showSectionOrWidgetListValue = []
  }
  const hideSectionOrWidgetListConfig = getAccountConfigValue(CONFIG_CODES.HIDE_SECTION_OR_WIDGET)
  let hideSectionOrWidgetListValue: any = []
  try {
    hideSectionOrWidgetListValue = JSON.parse(hideSectionOrWidgetListConfig)
  } catch  {
    hideSectionOrWidgetListValue = []
  }

  const onClose = ()=> {
    props.onCancel?.();
    setVisible(false);
  }

  const updateSectionItems = async (
    value: boolean,
    id: string,
    updateType: string,
    name: string
  ) => {
    updateAllListElement(value, id, stateData.rawPageSection);
  };
  const updateAllListElement = (
    value: boolean,
    id: string,
    array: ISectionListItem[]
  ) => {
    array.forEach((item, index1) => {
      const previousVisibleValue = item.isVisible;
      if (item.id == id) {
        item.isVisible = value;
        array[index1] = item;
        item?.childSectionWidget?.forEach((item2, index2) => {
          array[index1].childSectionWidget[index2].isVisible = value;
        });
      } else if (item.childSectionWidget) {
        item.childSectionWidget.forEach((childItem, childIndex) => {
          if (item.isVisible) {
            if (childItem.id == id) {
              childItem.isVisible = value;
              array[index1].childSectionWidget[childIndex] = childItem;
            }
          }
        });
      }
    });
    setStateData((prev) => {
      return {
        ...prev,
        rawPageSection: array,
      };
    });
  };

  const onDragHandler = async (result: DropResult) => {
    if (
      result.source.droppableId !== result.destination?.droppableId &&
      result.source.index !== result.destination?.index
    ) {
      return;
    }
    if (!result.destination) {
      return;
    }
    if(result.destination.droppableId == 'PARENT_SECTION') {
      onDragHandlerParentSection(result)
      return
    }
    const droppableId = result.source.droppableId;
    const sourceIndex: any = result.source.index;
    const destinationIndex: any = result.destination?.index;
    const mutableArray = stateData.rawPageSection.slice()
    let sectionIndex = 0
    const selectedSection: any = mutableArray.find((item, index) => {
      sectionIndex = index
      return item.id === droppableId;
    });
    const subSections = selectedSection?.childSectionWidget.slice()

    const [reorderedItem] = subSections.splice(sourceIndex, 1);
    subSections.splice(destinationIndex, 0, reorderedItem);

    const formattedSubSection = subSections?.map((item:any, index:number)=> {
      item = {...item, sequence: index}
      return item
    })
    mutableArray[sectionIndex].childSectionWidget = [...formattedSubSection]
    setStateData((prev)=> {
      return {
        ...prev,
        rawPageSection: mutableArray
      }
    })
  };
  const onDragHandlerParentSection = async (result:DropResult)=> {
    const sourceIndex: any = result.source.index;
    const destinationIndex: any = result.destination?.index;
    const mutableArray = [...stateData.rawPageSection]

    const [reorderedItem] = mutableArray.splice(sourceIndex, 1);
    mutableArray.splice(destinationIndex, 0, reorderedItem);

    const formattedSection = mutableArray.map((item:any, index)=> {
      item = {...item, sequence: index}
      return item
    })
    setStateData((prev)=> {
      return {
        ...prev,
        rawPageSection: [...formattedSection]
      }
    })
  }

  const onReset = ()=> {
    const rawPageSection = getRawPageSection(stateData.resetRawData)
    setStateData((prev)=> {
      return {
        ...prev,
        rawPageSection: rawPageSection
      }
    })
  }
  const onSubmit = async () => {
    setStateData(prev=> {
      return {
        ...prev,
        loading: true
      }
    })
    const response = await updateSectionViewAndPosition(
      stateData.rawPageSection
    ).catch((err) => {
      setStateData(prev=> {
        return {
          ...prev,
          loading: false
        }
      })
    });
    if (response?.data?.length) {
      props.onCallBackData?.(stateData.rawPageSection)
      setStateData(prev=> {
        return {
          ...prev,
          loading: false
        }
      })
      setVisible(false);
    }else {
      setStateData(prev=> {
        return {
          ...prev,
          loading: false
        }
      })
    }
  };
  const showWidgetByCode = (code?: string)=> {
    return (showSectionOrWidgetListValue.length ?
      showSectionOrWidgetListValue.includes((code || '')
      ) : true)
  }
  const hideWidgetByCode = (code?: string)=> {
    return (hideSectionOrWidgetListValue.length ?
      !hideSectionOrWidgetListValue.includes((code || '')
      ) : true)
  }
  return (
    <>
      <Drawer
        title={null}
        destroyOnClose
        placement="right"
        onClose={() => {
          onClose();
        }}
        visible={visible}
        closable={false}
        // width={width * 0.28}
        width={590}
      >
        {stateData.loading ? (
          <Spinner
            size="lg"
            style={{
              margin: '0 auto',
              position: 'absolute',
              zIndex: 100011111,
              backgroundColor: '#0000000f',
              height: '100%',
              width: '100%',
              padding: 0,
            }}
          />
        ) : (
          <></>
        )}
        <HStack
          justifyContent={'space-between'}
          alignItems="center"
          style={{
            position: 'absolute',
            width: '100%',
            top: 0,
            left: 0,
            padding: 24,
            backgroundColor: '#fff',
            zIndex: 1,
          }}
        >
          <VStack>
            <DisplayText
              size={'smBold'}
              extraStyles={{
                fontSize: 24,
              }}
              textLocalId="updateDashboard"
            />
          </VStack>
          <VStack my={3} flexDirection={'row-reverse'}>
            <HStack space={3}>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    onReset();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'reset',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    onSubmit();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'submit',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </HStack>
          </VStack>
        </HStack>
        <View style={{marginTop: 110}}>
          <DragDropContext onDragEnd={onDragHandler}>
            <Droppable
              droppableId={'PARENT_SECTION'}
              type={'SECTION_PARENT'}
              isDropDisabled={false}
            >
              {(
                provided: DroppableProvided,
                snapshot: DroppableStateSnapshot
              ) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <View style={{width: 550}}>
                      {stateData.rawPageSection.map((item, index) => {
                        if (item.childSectionWidget?.length > 0
                          && showWidgetByCode(item.sectionCategory)
                          && hideWidgetByCode(item.sectionCategory)) {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provide: DraggableProvided) => (
                                <div
                                  key={index}
                                  ref={provide.innerRef}
                                  {...provide.draggableProps}
                                  {...provide.dragHandleProps}
                                >
                                  <Droppable
                                    droppableId={item.id}
                                    type={'SECTION'}
                                    isDropDisabled={false}
                                  >
                                    {(
                                      provided: DroppableProvided,
                                      snapshot: DroppableStateSnapshot
                                    ) => {
                                      return (
                                        <div
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                        >
                                          <View
                                            flex={1}
                                            style={{
                                              padding: 15,
                                              backgroundColor: '#F2F2F2',
                                              borderWidth: 1,
                                              borderColor: '#D9D9D9',
                                              borderRadius: 9.3,
                                              marginBottom: 12,
                                            }}
                                          >
                                            <View>
                                              <View
                                                style={{
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  justifyContent:
                                                    'space-between',
                                                  marginBottom: 12,
                                                  paddingHorizontal: 4,
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                  }}
                                                >
                                                  <HolderOutlined
                                                    style={{
                                                      marginRight: 10,
                                                      fontSize: 22,
                                                    }}
                                                  />
                                                  <Text
                                                    size={'xlBold'}
                                                    style={{color: '#000'}}
                                                  >
                                                    {item.name}
                                                  </Text>
                                                </View>
                                                <View style={{marginRight: 16}}>
                                                  <Switch
                                                    // isChecked={item.isVisible}
                                                    style={{
                                                      backgroundColor:
                                                        item.isVisible
                                                          ? Colors.primary[300]
                                                          : Colors.Custom
                                                              .Gray200,
                                                    }}
                                                    checked={item.isVisible}
                                                    onChange={(
                                                      value: boolean
                                                    ) => {
                                                      updateSectionItems(
                                                        value,
                                                        item.id,
                                                        SECTION_UPDATE_TYPE.HIDE_SHOW,
                                                        item.name
                                                      );
                                                    }}
                                                  />
                                                </View>
                                              </View>
                                              {item.childSectionWidget
                                                ?.length ? (
                                                item.childSectionWidget?.map(
                                                  (item2, index2) => {
                                                  if (showWidgetByCode(item2.resourceCode)
                                                  && hideWidgetByCode(item2.resourceCode)) {
                                                    return (
                                                      // <View key={index2}>
                                                      <Draggable
                                                        key={item2.id}
                                                        draggableId={item2.id}
                                                        index={index2}
                                                      >
                                                        {(
                                                          provide: DraggableProvided
                                                        ) => (
                                                          <div
                                                            ref={
                                                              provide.innerRef
                                                            }
                                                            {...provide.draggableProps}
                                                            {...provide.dragHandleProps}
                                                          >
                                                            <View
                                                              style={{
                                                                backgroundColor:
                                                                  '#fff',
                                                                borderWidth: 1,
                                                                borderColor:
                                                                  '#D9D9D9',
                                                                borderRadius: 9.3,
                                                                height: 64,
                                                                flexDirection:
                                                                  'row',
                                                                alignItems:
                                                                  'center',
                                                                justifyContent:
                                                                  'space-between',
                                                                paddingHorizontal: 21,
                                                                marginBottom: 12,
                                                              }}
                                                            >
                                                              <View
                                                                style={{
                                                                  width: "80%",
                                                                  flexDirection:
                                                                    'row',
                                                                  alignItems:
                                                                    'center',
                                                                }}
                                                              >
                                                                <HolderOutlined
                                                                  style={{
                                                                    marginRight: 10,
                                                                    fontSize: 18,
                                                                  }}
                                                                />
                                                                <Text
                                                                  size={
                                                                    'mdMedium'
                                                                  }
                                                                  style={{
                                                                    color:
                                                                      '#000',
                                                                  }}
                                                                  textOverflow={"ellipsis"}
                                                                  numberOfLines={1}
                                                                >
                                                                  {item2.name}
                                                                </Text>
                                                              </View>
                                                              <View>
                                                                <Switch
                                                                  style={{
                                                                    backgroundColor:
                                                                      item2.isVisible
                                                                        ? Colors
                                                                            .primary[300]
                                                                        : Colors
                                                                            .Custom
                                                                            .Gray200,
                                                                  }}
                                                                  checked={
                                                                    item2.isVisible
                                                                  }
                                                                  disabled={!item.isVisible}
                                                                  onChange={(
                                                                    value: boolean
                                                                  ) => {
                                                                    updateSectionItems(
                                                                      value,
                                                                      item2.id,
                                                                      SECTION_UPDATE_TYPE.HIDE_SHOW,
                                                                      item.name
                                                                    );
                                                                  }}
                                                                />
                                                              </View>
                                                            </View>
                                                          </div>
                                                        )}
                                                      </Draggable>
                                                      // </View>
                                                    )
                                                    }
                                                  }
                                                )
                                              ) : (
                                                <></>
                                              )}
                                              {provided.placeholder}
                                            </View>
                                          </View>
                                          {/* {provided.placeholder} */}
                                        </div>
                                      );
                                    }}
                                  </Droppable>
                                </div>
                              )}
                            </Draggable>
                          );
                        } else {
                          return null;
                        }
                      })}
                      {provided.placeholder}
                    </View>
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </View>
      </Drawer>
    </>
  );
};
export default React.memo(UpdateDashboardDrawer);
