import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  formLabel: {
    marginVertical: 4,
  },
  formInput: {
    marginVertical: 4,
    fontSize: 16,
    height: 44,
  },
  container: {
    padding: 12,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  formContainer: {
    // maxWidth: 416,
  },
 dataContainer: {
    maxWidth: 320,
  },
  btnContainer: {
    marginTop: 12,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    maxWidth: 416,
  },
  btnStyle: {
    height: 40,
    borderRadius: 20,
    borderWidth: 1,
    minWidth: 64,
    paddingHorizontal: 14,
    paddingVertical: 8,
    backgroundColor: Colors.secondary['100'],
    borderColor: Colors.secondary['200'],
  },
  cancelBtnStyle: {
    borderWidth: 1,
    backgroundColor: Colors.danger['100'],
    borderColor: Colors.danger['200'],
    height: 40,
    borderRadius: 20,
    minWidth: 64,
    paddingHorizontal: 14,
    paddingVertical: 8,
  },
});
