import { Skeleton, useToast } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getAccountUUID } from '../../../../utils/commonUtils';
import InboxQueries from '../../../../services/Inbox/InboxQueries';
import { IUser } from '../../../../Interfaces';
import {ScheduleQueries} from '../../../../services';
import { IAccountLocation } from '../../Contacts/TeamMembers/interfaces';
import { ITimezone } from '../../../../services/Location/interfaces';
import UserPracticeLocationQueries from '../../../../services/Location/UserPracticeLocationQueries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { BUTTON_TYPE, MLOV_CATEGORY } from '../../../../constants';
import { AVAILABILITY_TYPE_CODES, USER_ROLE_CODES } from '../../../../constants/MlovConst';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES, createAvailabilityCalendarGroupData, groupAvailabilitiesByDaysOfWeek } from './PracticeAvailabilityHelper';
import { IAddOrUpdateAvailability, IAvailabilitiesByDateRangeKey, IAvailabilitiesByDaysOfWeek, IAvailabilityCalendarData, IAvailabilityCalendarTableData, IComponentStateState, IPracticeAvailability, IUserAvailability } from './PracticeAvailabilityInterfaces';
import { PracticeAvailabilityCalendarView } from './PracticeAvailabilityCalendarView';
import FHAlertDialog from '../../../common/FHAlertDialog/FHAlertDialog';
import { AddOrUpdateAvailabilityDrawer } from './AddOrUpdateAvailabilityDrawer';
import { message } from 'antd';
import { PracticeAvailabilityTopBar } from './PracticeAvailabilityTopBar';
import BaseService from '../../../../services/CommonService/BaseService';
import { saveInboxBusinessHours } from './PracticeAvailabilityService';
import { TABLE_TOP_BAR_ACTION_CODES } from '../../../common/TableTopBar';


interface IProps {
  isInboxHours: boolean;
  isUserSchedule: boolean;
  inboxData?: any;
  users?: string[];
  isReadOnly?: boolean;
  searchString?: string;
  showTableTopBar?: boolean;
  selectedActionCode?: string;
  selectedRawData?: any;
  onClose?: (actionCode: string, actionData?: any) => void;
}

const API_ERROR_MESSAGE = 'Something went wrong, Please try again later';
export const VIRTUAL_LOCATION_CODE = 'VIRTUAL_LOCATION';

const loadingDummyArray = new Array(5).fill(0);

export function PracticeAvailability (props: IProps) {
  const intl = useIntl();
  const toast = useToast();
  const accountUUID = getAccountUUID();

  const [ componentState, setComponentState ] = useState<IComponentStateState>({
    searchUserText: '',
    selectedAccountLocationId: props.inboxData?.inbox?.inboxAvailabilities[0]?.accountLocationUuid,
  });

  const [paginationPage, setPaginationPage] = useState(1);

  const mlovData = useContext(CommonDataContext);

  const availabilityTypes = getMlovListFromCategory(mlovData.CARE_STUDIO_MLOV, MLOV_CATEGORY.AVAILABILITY_TYPE) || [];
  const practiceScheduleAvailabilityTypeId = getMlovIdFromCode( availabilityTypes, AVAILABILITY_TYPE_CODES.PRACTICE_SCHEDULE);
  const inboxAvailabilityTypeId = getMlovIdFromCode( availabilityTypes, AVAILABILITY_TYPE_CODES.INBOX);

  const defaultAvailability: IAddOrUpdateAvailability = {
    isDeleted: false,
    localId: '',
    typeId: props.isInboxHours ? inboxAvailabilityTypeId : practiceScheduleAvailabilityTypeId,
  };

  const getTimezoneOfAccountAndLocationsQuery = useQuery(
    UserPracticeLocationQueries.GetTimezoneOfAccountAndLocations, {
      variables: { tenantId: accountUUID,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
      fetchPolicy: 'no-cache',
      onCompleted: (res) => {
        let accountLocations: IAccountLocation[] = [];
        let timezones: ITimezone[] = [];
        let accountUsers: IUser[] = [];

        accountLocations = res?.accountLocations?.length ? res.accountLocations : [];
        timezones = res?.timezones?.length ? res.timezones : [];
        accountUsers = res?.users?.length ? res.users : [];

        accountUsers = accountUsers?.filter((item) => {
          let roles = '';
          item.userRoles?.forEach((element: any) => {
            roles += element?.userRole?.userRole?.code;
          });
          const users = props?.users || [];
          if (users.length && !users.includes(item.uuid)) {
            return false;
          }
          if (
            !roles.includes('WORKFLOW') && !roles.includes('CUSTOMER_SUCCESS')  &&
            item?.accountUsers?.[0]?.isActive
          ) {
            return item;
          }
        });

        setComponentState((prev) => {
          const availabilityCalendarData: IAvailabilityCalendarData[] = createAvailabilityCalendarGroupData({
            practiceAvailabilities: prev.practiceAvailabilities,
            timezones,
            accountLocations,
            accountUsers,
            isUserSchedule: props.isUserSchedule,
          });
          return {
            ...prev,
            availabilityCalendarData,
            accountLocations,
            accountUsers,
            timezones,
          };
        });
      },
    }
  );

  const [ getAvailabilitiesAPI, { loading: isGetAvailabilitiesAPILoading }] = useLazyQuery(ScheduleQueries.GET_PRACTICE_SCHEDULE, {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      variables: {
        whereCondition: getPracticeAvailabilityWhereCondition(),
      },
      onCompleted: (response) => {
        setComponentState((prev) => {
          const availabilityCalendarData: IAvailabilityCalendarData[] = createAvailabilityCalendarGroupData({
            practiceAvailabilities: response?.schedules || [],
            timezones: (prev.timezones || []),
            accountLocations: prev.accountLocations,
            accountUsers: prev.accountUsers,
            isUserSchedule: props.isUserSchedule,
          });
          return {
            ...prev,
            practiceAvailabilities: response?.schedules?.length ? response.schedules : [],
            availabilityCalendarData,
          };
        });
      },
      onError: (error) => {

        setComponentState((prev) => ({
          ...prev,
          practiceAvailabilities: [],
        }));
        showToast(toast, API_ERROR_MESSAGE, ToastType.error);
      },
    }
  );

  const [deleteAvailabilitiesAPI, {loading: isDeleteAvailabilitiesAPILoading}] = useMutation(ScheduleQueries.DELETE_SCHEDULE, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  function getPracticeAvailabilityWhereCondition() {
    let whereCondition: any = {};
      whereCondition = {
        isDeleted: {_eq: false},
        typeId: {_eq: practiceScheduleAvailabilityTypeId},
      };

      if (!props.isUserSchedule) {
        whereCondition.userId = {_is_null: true};
      }
    return whereCondition;
  }

  function fetchAvailabilityData() {
    getAvailabilitiesAPI();
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setComponentState((prev) => ({
        ...prev,
        searchUserText: props.searchString?.toLocaleLowerCase() || '',
      }));
      setPaginationPage(1);
    }, 10);
    return () => clearTimeout(delayDebounceFn);
  }, [props?.searchString]);

  useEffect(() => {
    if (props?.selectedActionCode === TABLE_TOP_BAR_ACTION_CODES.ADD_NEW_PRACTICE) {
      onAddScheduleBtnClick({selectedUserId: ''});
    } else if (props?.selectedActionCode === TABLE_TOP_BAR_ACTION_CODES.ADD_NEW_USER_SCHEDULE) {
      onAddScheduleBtnClick({selectedUserId: props?.selectedRawData});
    } else if (props?.selectedActionCode === TABLE_TOP_BAR_ACTION_CODES.CLOSE) {
      setComponentState((prev) => ({
        ...prev,
        isAddOrUpdateDrawerOpen: false,
        currentAvailabilitiesByDaysOfWeek: undefined,
        selectedUserData: undefined
      }));
    }
  }, [props?.selectedActionCode]);

  useEffect(() => {
    fetchAvailabilityData();
  }, []);

  function onEditAvailabilities(data: IAvailabilityCalendarTableData) {
    const uniqueRowKey = props.isUserSchedule ? 'userId' : 'locationId';
    const uniqueRowId = data.primaryData[uniqueRowKey];

    editAvailabilitiesByLocationOrUser(uniqueRowKey, uniqueRowId);
  }

  function editAvailabilitiesByLocationOrUser(uniqueRowKey: 'userId' | 'locationId', uniqueRowId?: string) {
    if (!uniqueRowId) {
      return;
    }
    const availabilityCalendarDataObject = componentState.availabilityCalendarData?.find((data) => {
      const id = data[uniqueRowKey];

      if (id && id === uniqueRowId) {
        return true;
      }
    });

    if (availabilityCalendarDataObject) {
      setComponentState((prev) => ({
        ...prev,
        isAddOrUpdateDrawerOpen: true,
        currentAvailabilitiesByDaysOfWeek: JSON.parse(JSON.stringify(availabilityCalendarDataObject.availabilitiesByDaysOfWeek)),
        currentOverriddenAvailabilitiesByDateRangeKey: props.isUserSchedule
          ? JSON.parse(JSON.stringify(availabilityCalendarDataObject.overriddenAvailabilitiesByDateRangeKey))
          : undefined,
        selectedUserData: (props.isUserSchedule ? availabilityCalendarDataObject.userData : undefined),
      }));
    }
  }

  function onDeleteAvailabilities(data: IAvailabilityCalendarTableData) {
    setComponentState((prev) => ({
      ...prev,
      isDeleteModalOpen: true,
      selectedDeletedData: data,
      deleteOverrideAvailabilities: false
    }));
  }

  function onDeleteOverrideAvailabilities(data: IAvailabilityCalendarTableData) {
    setComponentState((prev) => ({
      ...prev,
      isDeleteModalOpen: true,
      selectedDeletedData: data,
      deleteOverrideAvailabilities: true,
    }));
  }

  async function deleteAvailabilities(data?: IAvailabilityCalendarTableData, deleteOverrideAvailabilities?: boolean) {
    if (!data) {
      setComponentState((prev) => ({
        ...prev,
        isDeleteModalOpen: false,
        selectedDeletedData: undefined,
        deleteOverrideAvailabilities: false,
      }));
      showToast(toast, 'No data available to delete', ToastType.info);
      return;
    }

    const availabilityIds: string[] = [];

    if (deleteOverrideAvailabilities) {
      data.primaryData.overriddenAvailabilitiesByDateRangeKey?.data?.forEach((data) => {
        data.availabilities?.forEach((availability) => {
          if (availability.id) {
            availabilityIds.push(availability.id)
          }
        });
      });
    } else {
      Object.keys(AVAILABILITY_CALENDAR_WEEK_COLUMN_CODES).forEach((weekDayKey) => {
        const availabilities = data[weekDayKey];
        availabilities.forEach((availability) => {
          if (availability.id) {
            availabilityIds.push(availability.id);
          }
        });
      });
    }

    try {
      setComponentState((prev) => ({ ...prev, showDeleteModalLoading: true }));

      await deleteAvailabilitiesAPI({
        variables: { ids: availabilityIds },
      });

      const locationOrUseName = props.isUserSchedule ?
      `user ${data.primaryData.userData?.name}` :
      `location ${data.primaryData.locationData?.practiceLocation?.name}`;

      showToast(toast, `Schedule deleted for ${locationOrUseName}`, ToastType.success);
    } catch (error) {
      showToast(toast, API_ERROR_MESSAGE, ToastType.error);
    }

    setComponentState((prev) => ({
      ...prev,
      isDeleteModalOpen: false,
      showDeleteModalLoading: false,
      selectedDeletedData: undefined,
      deleteOverrideAvailabilities: false,
    }));

    fetchAvailabilityData();
  }

  function onAddScheduleBtnClick(data: {selectedUserId?: string}) {
    if (props.isUserSchedule) {
      editAvailabilitiesByLocationOrUser('userId', data.selectedUserId);
      return;
    }

    const availabilities = componentState.availabilityCalendarData?.reduce((prevValue, data) => {
      if (data.availabilities?.length) {
        prevValue = prevValue.concat(data.availabilities);
      }
      return prevValue;
    }, [] as IUserAvailability[]);

    const availabilitiesByDaysOfWeek = groupAvailabilitiesByDaysOfWeek(availabilities || [], true);

    setComponentState((prev) => ({
      ...prev,
      isAddOrUpdateDrawerOpen: true,
      currentAvailabilitiesByDaysOfWeek: JSON.parse(JSON.stringify(availabilitiesByDaysOfWeek)),
      selectedUserData: undefined,
    }));
  }

  function filterCalenderDataBasedOnSearch(calenderData: IAvailabilityCalendarData[]) {
    if (!componentState.searchUserText || componentState.searchUserText === '') {
      return calenderData;
    } else {
      return calenderData?.filter((singleCalenderData: IAvailabilityCalendarData) => {
        if (componentState.searchUserText) {
          const userName = singleCalenderData.userData?.name;
          if (userName) {
            return userName?.toLocaleLowerCase().includes(componentState.searchUserText);
          }
        }
      })
    }
  }

  const isLoading = (isGetAvailabilitiesAPILoading || getTimezoneOfAccountAndLocationsQuery.loading);

  return <>
    {
      !isLoading &&
      !props.isReadOnly &&
      props?.showTableTopBar &&
      (!props.isUserSchedule || componentState.accountUsers?.length) &&
      <PracticeAvailabilityTopBar
        accountUsers={componentState.accountUsers}
        accountLocations={componentState.accountLocations}
        isUserSchedule={props.isUserSchedule}
        isInboxHours={props.isInboxHours}
        onClick={onAddScheduleBtnClick}
        inboxData={props.inboxData}
        onSearch={(searchText?: string) => {
          setComponentState((prev) => ({ ...prev, searchUserText: searchText?.toLocaleLowerCase() || '' }));
        }}
        onChangeLocation={(selectedAccountLocationId?: string) => {
          setComponentState((prev) => ({ ...prev, selectedAccountLocationId: selectedAccountLocationId || '' }));
        }}
      />
    }
    {
      isLoading && (
        loadingDummyArray.map((a, index) => (
        <Skeleton
          key={index}
          height={'10'}
          marginY={1}
          borderRadius="lg"
          startColor={'white'}
          endColor={'blueGray.300'}
        />
      )))
    }
    {
      !isLoading &&
      componentState.availabilityCalendarData &&
      <PracticeAvailabilityCalendarView
        isReadOnly={props.isReadOnly}
        searchUserText={componentState.searchUserText}
        selectedAccountLocationId={componentState.selectedAccountLocationId}
        availabilityCalendarData={props.isUserSchedule ? filterCalenderDataBasedOnSearch(componentState.availabilityCalendarData) : componentState.availabilityCalendarData}
        isUserSchedule={props.isUserSchedule}
        isInboxHours={props.isInboxHours}
        onEditAvailabilities={onEditAvailabilities}
        onDeleteAvailabilities={onDeleteAvailabilities}
        onDeleteOverrideAvailabilities={onDeleteOverrideAvailabilities}
        setPaginationPage={setPaginationPage}
        paginationPage={paginationPage}
      />
    }
    {
      <FHAlertDialog
        isOpen={componentState.isDeleteModalOpen || false}
        header={intl.formatMessage({id: 'deleteAvailability'})}
        message={intl.formatMessage({id: 'deleteAvailabilityAlertMessage'})}
        buttonActions={[
          {
            textLocalId: 'Cancel',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY
            },
            onPress: () => {
              setComponentState((prev) => ({
                ...prev,
                isDeleteModalOpen: false,
                selectedDeletedData: undefined,
              }));
            },
          },
          {
            textLocalId: 'Delete',
            isDeleting: isDeleteAvailabilitiesAPILoading,
            buttonProps: {
              variant: BUTTON_TYPE.PRIMARY
            },
            onPress: () => {
              deleteAvailabilities(componentState.selectedDeletedData, componentState.deleteOverrideAvailabilities);
            },
          },
        ]}
      />
    }
    {
      componentState.isAddOrUpdateDrawerOpen &&
      componentState.timezones &&
      componentState.accountLocations &&
      componentState.currentAvailabilitiesByDaysOfWeek &&
      defaultAvailability &&
      <AddOrUpdateAvailabilityDrawer
        accountLocations={componentState.accountLocations}
        availabilitiesByDaysOfWeek={componentState.currentAvailabilitiesByDaysOfWeek}
        overriddenAvailabilitiesByDateRangeKey={componentState.currentOverriddenAvailabilitiesByDateRangeKey}
        isUserSchedule={props.isUserSchedule}
        selectedUserData={componentState.selectedUserData}
        timezones={componentState.timezones}
        isOpen={componentState.isAddOrUpdateDrawerOpen}
        defaultAvailability={props.isUserSchedule ? { ...defaultAvailability, userId: componentState.selectedUserData?.uuid} : defaultAvailability }

        onClose={() => {
          setComponentState((prev) => ({
            ...prev,
            isAddOrUpdateDrawerOpen: false,
            currentAvailabilitiesByDaysOfWeek: undefined,
            selectedUserData: undefined
          }));
          props?.onClose?.(TABLE_TOP_BAR_ACTION_CODES.CLOSE)
        }}
        onSave={(response: { isSuccess: boolean; message?: string; updateRecords?: boolean}) => {
          if (response.message) {
            showToast(toast, response.message, response.isSuccess ? ToastType.success : ToastType.error);
          }
          setComponentState((prev) => ({
            ...prev,
            isAddOrUpdateDrawerOpen: false,
            currentAvailabilitiesByDaysOfWeek: undefined,
            selectedUserData: undefined
          }));
          props?.onClose?.(TABLE_TOP_BAR_ACTION_CODES.CLOSE)
          if (response.isSuccess && response.updateRecords) {
            fetchAvailabilityData();
          }
        }}
      />
    }
  </>;
}
