import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles/Colors';

export const opportunityStyles = StyleSheet.create({
  flexNoWidth: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  btnPrimary: {
    backgroundColor: Colors.Custom.BackgroundColor,
  },
});

export const gOppStyles = {
  primaryBtn: {
    backgroundColor: '#7F56D9',
    borderColor: '#7F56D9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  secondaryBtn: {
    backgroundColor: '#F9F5FF',
    borderColor: 'transparent',
    color: '#6941C6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  pageHeading: {
    fontSize: '30px',
    color: '#101828',
    fontWeight: '500',
    lineHeight: '38px'
  },
  pageHeadingColored: {
    fontSize: '30px',
    color: '#7F56D9',
    fontWeight: '500',
    lineHeight: '38px'
  }
};
