import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../../../styles';
export const styles = StyleSheet.create({
  iconView: {
    flex: 0.8,
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  icons: {
    // marginLeft: 2,
    // marginRight: 2,
    fontSize: 14.29,
    color: Colors.Custom.Gray600,
  },
  btn: {
    border: 'none',
    backgroundColor: 'transparent',
  },
});
