import  React,{useState} from 'react';
import {Modal} from 'antd';
import {Button, Text, View} from 'native-base';
import {useIntl} from 'react-intl';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {BUTTON_TYPE} from '../../../../constants';
import {Colors} from '../../../../styles';
import { StyleSheet } from 'react-native';

interface IDuplicateUsersInfoModal {
  isOpen: boolean;
  onClose: ()=> void;
  dataList: {
    name: string;
    email: string;
  }[]
}

const styles = StyleSheet.create({
  container: {
    marginTop: 24,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    flex: 1,
    width: '100%',
  },
  nameText: {
    minWidth: '50%',
  },
  emailText: {
    minWidth: '50%',
    color: Colors.Custom.Gray500,
  },
  buttonGroup: {
    marginTop: 16,
  },
  marginTop4: {
    marginTop: 4,
  },
});

const DuplicateUsersInfoModal = (props: IDuplicateUsersInfoModal) => {
  const { onClose, dataList, isOpen} = props;
  const intl = useIntl();
  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => {
          onClose();
        }}
        closable={false}
        footer={null}
      >
        <View>
          <View>
            <Text size={'lgBold'}>
              {intl.formatMessage({
                id: 'usersExistInAccount',
              })}
            </Text>
            <Text style={styles.marginTop4} size={'smNormal'} >
              {intl.formatMessage({
                id: 'existingUserInfo',
              })}
            </Text>
          </View>
          <View style={styles.container}>
            {dataList?.map((item) => {
              return (
                <View style={styles.itemContainer}>
                  <Text
                    size="smBold"
                    style={styles.nameText}
                  >
                    {item.name}
                  </Text>
                  <Text
                    flex={0.5}
                    size="smMedium"
                    style={styles.emailText}
                  >
                    {item.email}
                  </Text>
                </View>
              );
            })}
          </View>
          <Button.Group justifyContent={'flex-end'} style={styles.buttonGroup}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'okGotIt',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </View>
      </Modal>
    </>
  );
};

export default DuplicateUsersInfoModal;
