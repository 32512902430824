import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';
import { isWeb } from '../../../../../utils/platformCheckUtils';

export const styles = StyleSheet.create({
  conversationListItem: {
    //padding: 16,
    paddingVertical: 12,
  },
  conversationListItemNative: {
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.BorderColor,
  },

  topBar: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 55,
  },

  flexStart: {
    alignItems: 'center',
    // paddingLeft: 10,
  },

  flexEnd: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 5,
  },

  xsFontSize: {
    color: 'gray',
  },

  sFontSize: {
    fontSize: 13,
  },

  xsFontSizeBlack: {
    fontSize: 12.5,
  },
  inboxStyle: {
    alignContent: 'center',
    alignItems: 'center',
  },
  conversationSearchBar: {
    paddingVertical: 12,
  },
  msgTextOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    // marginRight: 16,
    paddingRight: 16,
  },
  icons: {
    marginLeft: 2,
    marginRight: 2,
    fontSize: 14.29,
    color: Colors.Custom.IconColor,
  },
  btn: {
    border: 'none',
    backgroundColor: 'transparent',
  },
  dividerStyle: {
    width: '100%',
  },
  lastMenu: {
    paddingBottom: 15,
  },
  mainMenuTxt: {
    marginLeft: 10,
  },
  mainMenuContent: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 2,
    cursor: 'pointer',
  },
  firstMenu: {
    paddingTop: 10,
  },
  personActionsPopoverBody: {
    minWidth: 170,
    height: 'auto',
    boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 15px 1px',
    backgroundColor: '#FFF',
    paddingTop: 3,
  },
  tabStyles: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    height: 36,
    justifyContent: 'center',
    marginRight: 8,
    alignItems: 'center',
    borderRadius: 6,
    fontSize: 14,
    fontWeight: 500,
  },
  tabsContainer: {
    marginVertical: 12,
  },
  emailSubtabStyles: {
    padding: 4,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: Colors.Custom.shadeGray,
  },
  conversationListContainer: {
    height: '100%',
    // backgroundColor: Colors.Custom.ScreenBGColor,
  },
  mentionIcon: {
    marginRight: 4,
  },
  patientStatusContainer: {
    marginRight: 16,
    maxWidth: '90%',
  },
  patientStatusLabel: {
    color: Colors.Custom.Gray500,
  },
  inboxName: {
    width: isWeb() ? 'auto' : 240,
  },
  lastMessageContent: {
    marginLeft: 0,
    marginTop: 0,
  },
  lastMessageText: {
    height: 20,
    width: isWeb() ? 'auto' : 240,
  },
  text2: {
    color: Colors.Custom.Gray500,
    marginTop: isWeb() ? 0 : 6,
    fontSize: 12,
  },
  text3: {
    height: 20,
    width: isWeb() ? 'auto' : 240
  }
});

export const getConversationContainerStyles = () => {
  return StyleSheet.create({
    inboxNameView: {
      marginLeft: 5,
    },
    conversationSearchBar: {
      paddingVertical: 12,
    },
    tooltipPressable: {
      padding: 4,
      justifyContent: 'center',
      marginHorizontal: 2,
    },
    tooltipStyles: {
      textAlign: 'center',
    },
    conversationFilterCountView: {
      position: 'absolute',
      top: -7.5,
      right: -7.5,
      backgroundColor: Colors.danger[500],
      width: 20,
      height: 20,
      borderRadius: 20,
      justifyContent: 'center',
      alignItems: 'center',
    },
    conversationFilterCountText: {
      color: '#ffffff',
      lineHeight: 12,
      fontSize: 10,
    },
    popoverView: {
      marginTop: 16,
      marginLeft: 3,
      marginRight: 4,
    },
    filterIconPressable: {
      width: 32,
      height: 32,
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 2,
    },
    tagsPressable: {
      width: 32,
      height: 32,
      borderRadius: 100,
      justifyContent: 'center',
      marginHorizontal: 2,
      alignItems: 'center',
      paddingHorizontal: 8,
    },
    emailSubtabStyles: {
      padding: 4,
      height: 32,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
      borderColor: Colors.Custom.shadeGray,
    },
    p2: {
      padding: 2,
    },
    ml5: {
      marginLeft: 5,
    },
  });
};

export const cssProperties: Record<string, React.CSSProperties> = {
  overlayStyle: {
    whiteSpace: 'pre-line',
  },
};
