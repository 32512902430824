import {Button, Divider, HStack, Menu, Modal, View, VStack} from 'native-base';
import React, {useState} from 'react';
import Icon from 'react-native-vector-icons/AntDesign';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {SearchBar} from '../../../../common/SearchBar';
import {CAMPAIGN_CONSTANTS} from '../CampaignConstants';
import {styles} from '../CampaignStyles';
import {ICampaignTopBarProps} from '../interfaces';

export const TopBar = (props: ICampaignTopBarProps) => {
  const [showEmailModal, setShowEmailModal] = useState(false);

  const handleEmailStatisticsClick = () => {
    setShowEmailModal(true);
  };

  const newCampaignMenu = () => {
    return (
      <Menu
        placement="bottom left"
        trigger={(triggerProps) => {
          return (
            <Button
              size="sm"
              colorScheme="success"
              leftIcon={<Icon name="pluscircleo" color="white" />}
              variant="solid"
              {...triggerProps}
            >
              {CAMPAIGN_CONSTANTS.NEW_CAMPAIGN}
            </Button>
          );
        }}
      >
        <Menu.Item
          onPress={() =>
            props.handleScreenChange(CAMPAIGN_CONSTANTS.AUTO_CAMPAIGN)
          }
        >
          {CAMPAIGN_CONSTANTS.AUTO_CAMPAIGN}
        </Menu.Item>
        <Menu.Item
          onPress={() =>
            props.handleScreenChange(CAMPAIGN_CONSTANTS.MANUAL_CAMPAIGN)
          }
        >
          {CAMPAIGN_CONSTANTS.MANUAL_CAMPAIGN}
        </Menu.Item>
        <Menu.Item
          onPress={() =>
            props.handleScreenChange(CAMPAIGN_CONSTANTS.VISITOR_CAMPAIGN)
          }
        >
          {CAMPAIGN_CONSTANTS.VISITOR_CAMPAIGN}
        </Menu.Item>
      </Menu>
    );
  };

  const emailStatsModal = (): JSX.Element => {
    return (
      <Modal isOpen={showEmailModal} onClose={() => setShowEmailModal(false)}>
        <Modal.Content style={styles.emailModal}>
          <Modal.CloseButton />
          <Modal.Header>{CAMPAIGN_CONSTANTS.EMAIL_MODAL_HEADER}</Modal.Header>
          <Modal.Body>{CAMPAIGN_CONSTANTS.EMAIL_MODAL_BODY}</Modal.Body>
        </Modal.Content>
      </Modal>
    );
  };

  return (
    <View>
      <View style={styles.topContainer}>
        <HStack flex={1} style={styles.alignCenter}>
          <VStack flex={0.5}>
            <View style={styles.left}>
              <DisplayText textLocalId="campaigns" textType="Heading" />
            </View>
          </VStack>
          <VStack flex={0.5}>
            <HStack justifyContent={'flex-end'}>
              <VStack justifyContent={'flex-end'}>
                <View marginRight={5}>
                  <SearchBar
                    onChange={(value: string) => {
                      const searchString = value || '';
                    }}
                  />
                </View>
              </VStack>
              <VStack alignItems={'flex-end'}>
                {emailStatsModal()}
                <View style={styles.left}>
                  <Button.Group>
                    <Button
                      size={'sm'}
                      colorScheme="yellow"
                      variant={'solid'}
                      _text={{color: 'white'}}
                      leftIcon={<Icon name="linechart" />}
                      onPress={handleEmailStatisticsClick}
                    >
                      {CAMPAIGN_CONSTANTS.EMAIL_MODAL_HEADER}
                    </Button>
                    {newCampaignMenu()}
                  </Button.Group>
                </View>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      </View>
      <Divider />
    </View>
  );
};

export default TopBar;
