import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles/Colors';

export const styles = StyleSheet.create({
  container: {
    elevation: 10,
    // borderColor: Colors.Custom.Gray200,
    // borderWidth: 1,
    overflow: 'hidden',
  },
  titleWrapper: {
    marginHorizontal: 22,
    marginVertical: 12,
  },

  tabsWrapper: {
    marginHorizontal: 14,
  },
  textContainer: {
    fontWeight: '400',
    fontSize: 24,
    color: '#667085',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftText: {
    fontSize: 18,
    lineHeight: 32,
    fontWeight: '500',
    marginLeft: 16,
  },
});
