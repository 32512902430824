import React from 'react';
import {IContactCareProgramMultiSelect} from './interface';
import {Text} from 'native-base';
import {View, StyleSheet, Pressable} from 'react-native';
import Stack from '../../common/LayoutComponents/Stack';
import {Colors} from '../../../styles/Colors';
import {Checkbox, Divider} from 'antd';
import AntIcon from 'react-native-vector-icons/AntDesign';
import ReadMsgSvg from '../../common/Svg/ReadMsgSvg';
import {antStyles} from './CareManageMentViewStyles';
import {useIntl} from 'react-intl';
import CustomConfirmationModal from '../../common/CustomConfirmationModal/CustomConfirmationModal';
import WarningSvgV2 from '../../common/Svg/WarningSvgV2';
import {Radio, Input} from 'antd';
import {useCareProgramCloseAction} from './CareManagementViewHooks/useCareProgramCloseAction';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {useCustomToast} from '../../Toast/ToastProvider';
import {CARE_PROGRAM_CLOSE_REASONS} from '../CareManagementBilling/CareManagementConstants';

const ContactCareProgramMultiSelectView = (
  props: IContactCareProgramMultiSelect
) => {
  const {
    contactId,
    visible,
    onClose,
    selectedCareProgramList,
    careProgramUuids,
    onSelectAll,
    onActionCloseCareProgram,
  } = props;
  const intl = useIntl();
  const customToast = useCustomToast();
  const {TextArea} = Input;
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false);
  const selectedCareProgramCount = selectedCareProgramList?.length || 0;
  const selectAllCareProgram =
    selectedCareProgramCount === careProgramUuids?.length;

  const {onCloseCareProgram} = useCareProgramCloseAction();

  const [state, setState] = React.useState({
    reason: '',
    showTextArea: false,
  });

  const onRadioChange = (e: any) => {
    const isOtherStatusReason =
      e?.target?.value === CARE_PROGRAM_CLOSE_REASONS.OTHER;
    setState((prev) => {
      return {
        ...prev,
        ...(!isOtherStatusReason && {reason: e?.target?.value}),
        showTextArea: isOtherStatusReason,
      };
    });
  };

  const handleCloseCareProgram = async () => {
    try {
      await onCloseCareProgram(
        selectedCareProgramList,
        contactId,
        state.reason
      );
      setShowConfirmationModal(false);
      onClose();
      onActionCloseCareProgram();
      showToast(
        customToast,
        intl.formatMessage({id: 'careProgramClosedSuccessMsg'}),
        ToastType.success,
        1000,
        true
      );
    } catch (error) {
      setShowConfirmationModal(false);
      onClose();
      showToast(
        customToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
        1000,
        true
      );
    }
  };

  return (
    <View style={styles.wrapper}>
      {visible && (
        <View style={styles.container}>
          <View style={styles.card}>
            <Checkbox
              indeterminate={
                selectedCareProgramCount !== careProgramUuids?.length
              }
              checked={selectAllCareProgram}
            />
            <Text
              style={styles.selectedTextStyle}
            >{`${selectedCareProgramCount} Selected`}</Text>
            <Pressable onPress={onSelectAll} disabled={selectAllCareProgram}>
              <Stack
                direction="row"
                style={
                  selectAllCareProgram
                    ? styles.disabledButtonStyle
                    : styles.buttonStyle
                }
              >
                <ReadMsgSvg color={Colors.Custom.Gray400} />
                <Text
                  style={styles.actionText}
                >{`Select All ${careProgramUuids.length} Programs`}</Text>
              </Stack>
            </Pressable>
            <Pressable
              disabled={!(selectedCareProgramCount > 0)}
              onPress={() => setShowConfirmationModal(true)}
            >
              <Stack
                direction="row"
                style={
                  selectedCareProgramCount > 0
                    ? styles.buttonStyle
                    : styles.disabledButtonStyle
                }
              >
                <ReadMsgSvg color={Colors.Custom.Gray400} />
                <Text style={styles.actionText}>
                  {intl.formatMessage({id: 'markAsClosed'})}
                </Text>
              </Stack>
            </Pressable>
            <Divider
              type="vertical"
              orientationMargin={0}
              style={antStyles.dividerStyle}
            />
            <Pressable onPress={onClose}>
              <AntIcon name="close" size={16} color={Colors.Custom.Gray400} />
            </Pressable>
          </View>
        </View>
      )}
      {showConfirmationModal && (
        <CustomConfirmationModal
          isOpen={showConfirmationModal}
          onCancel={() => setShowConfirmationModal(false)}
          onConfirm={handleCloseCareProgram}
          disabled={!state.reason}
          headerText="closeCareProgramHeaderText"
          customIcon={
            <WarningSvgV2 strokeColor={Colors.Custom.crossIconColor} />
          }
          modalContent={
            <View style={styles.confirmationModalContent}>
              <Text style={styles.confirmationModalText}>
                {intl.formatMessage({id: 'closeCareProgramMsg'})}
              </Text>
              <Radio.Group onChange={onRadioChange}>
                <View style={{marginTop: 16}}>
                  <Radio
                    value={CARE_PROGRAM_CLOSE_REASONS.PATIENT_DECEASED}
                    style={{marginBottom: 8, color: Colors.Custom.Gray500}}
                  >
                    {intl.formatMessage({id: 'patientDeceased'})}
                  </Radio>
                  <Radio
                    value={
                      CARE_PROGRAM_CLOSE_REASONS.NO_LONGER_MANAGING_PATIENT
                    }
                    style={{marginBottom: 8, color: Colors.Custom.Gray500}}
                  >
                    {intl.formatMessage({id: 'noLongerManagingPatient'})}
                  </Radio>
                  <Radio
                    value={CARE_PROGRAM_CLOSE_REASONS.OTHER}
                    style={{color: Colors.Custom.Gray500}}
                  >
                    {intl.formatMessage({id: 'otherReasons'})}
                  </Radio>
                </View>
              </Radio.Group>
              {state.showTextArea && (
                <View style={styles.confirmationModalRadioGroup}>
                  <TextArea
                    placeholder="Please add any specific reason"
                    maxLength={150}
                    onChange={(e) =>
                      setState((prev) => {
                        return {
                          ...prev,
                          reason: e?.target?.value,
                        };
                      })
                    }
                    style={{
                      marginTop: 8,
                      borderRadius: 4,
                      padding: 8,
                      height: 90,
                      resize: 'none',
                    }}
                    showCount
                  />
                </View>
              )}
            </View>
          }
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    zIndex: 1000,
    alignItems: 'center',
  },
  container: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    width: 'auto',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 12,
    elevation: 5,
  },
  selectedCount: {
    backgroundColor: '#F3F0FF',
    padding: 8,
    borderRadius: 4,
    marginRight: 12,
  },
  actionText: {
    marginHorizontal: 4,
    color: Colors.Custom.Gray500,
  },
  disabledButtonStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.Custom.Gray200,
    opacity: 0.2,
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginHorizontal: 8,
  },
  buttonStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginHorizontal: 8,
  },
  selectedTextStyle: {
    color: Colors.Custom.Gray500,
    paddingHorizontal: 8,
  },
  card: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 'auto',
  },
  confirmationModalContent: {
    marginTop: 2,
  },
  confirmationModalRadioGroup: {
    marginTop: 16,
  },
  confirmationModalText: {
    color: Colors.Custom.Gray300,
    fontSize: 14,
    lineHeight: 16.8,
    fontWeight: '400',
    textAlign: 'center',
  },
});

export default React.memo(ContactCareProgramMultiSelectView);
