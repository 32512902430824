export const PRE_DPC_VS_POST_DPC_DEMO_DATA = {
  "result": {
    "perDpcDataAvgList": [
      {
        "week": "-52",
        "type": "Avg Pre DPC Member Cost",
        "value": 30,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-48",
        "type": "Avg Pre DPC Member Cost",
        "value": 25,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-44",
        "type": "Avg Pre DPC Member Cost",
        "value": 2,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-40",
        "type": "Avg Pre DPC Member Cost",
        "value": 41,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-36",
        "type": "Avg Pre DPC Member Cost",
        "value": 18,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-32",
        "type": "Avg Pre DPC Member Cost",
        "value": 208,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-28",
        "type": "Avg Pre DPC Member Cost",
        "value": 6,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-24",
        "type": "Avg Pre DPC Member Cost",
        "value": 47,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-20",
        "type": "Avg Pre DPC Member Cost",
        "value": 23,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-16",
        "type": "Avg Pre DPC Member Cost",
        "value": 685,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-12",
        "type": "Avg Pre DPC Member Cost",
        "value": 68,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-8",
        "type": "Avg Pre DPC Member Cost",
        "value": 33,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-4",
        "type": "Avg Pre DPC Member Cost",
        "value": 34,
        "filterCode": "PRE_DPC"
      }
    ],
    "preDpcDataList": [
      {
        "type": "Before Joining DPC",
        "filterCode": "PRE_DPC",
        "week": -52,
        "value": 2013,
        "memberCount": 3
      },
      {
        "type": "Before Joining DPC",
        "filterCode": "PRE_DPC",
        "week": -48,
        "value": 1789,
        "memberCount": 4
      },
      {
        "type": "Before Joining DPC",
        "filterCode": "PRE_DPC",
        "week": -44,
        "value": 216,
        "memberCount": 1
      },
      {
        "type": "Before Joining DPC",
        "filterCode": "PRE_DPC",
        "week": -40,
        "value": 3153,
        "memberCount": 4
      },
      {
        "type": "Before Joining DPC",
        "filterCode": "PRE_DPC",
        "week": -36,
        "value": 1405,
        "memberCount": 4
      },
      {
        "type": "Before Joining DPC",
        "filterCode": "PRE_DPC",
        "week": -32,
        "value": 15863,
        "memberCount": 3
      },
      {
        "type": "Before Joining DPC",
        "filterCode": "PRE_DPC",
        "week": -28,
        "value": 485,
        "memberCount": 2
      },
      {
        "type": "Before Joining DPC",
        "filterCode": "PRE_DPC",
        "week": -24,
        "value": 3831,
        "memberCount": 4
      },
      {
        "type": "Before Joining DPC",
        "filterCode": "PRE_DPC",
        "week": -20,
        "value": 1896,
        "memberCount": 4
      },
      {
        "type": "Before Joining DPC",
        "filterCode": "PRE_DPC",
        "week": -16,
        "value": 57572,
        "memberCount": 2
      },
      {
        "type": "Before Joining DPC",
        "filterCode": "PRE_DPC",
        "week": -12,
        "value": 6063,
        "memberCount": 4
      },
      {
        "type": "Before Joining DPC",
        "filterCode": "PRE_DPC",
        "week": -8,
        "value": 2982,
        "memberCount": 3
      },
      {
        "type": "Before Joining DPC",
        "filterCode": "PRE_DPC",
        "week": -4,
        "value": 3150,
        "memberCount": 2
      }
    ],
    "postDpcDataList": [
      {
        "type": "After Joining DPC",
        "filterCode": "POST_DPC",
        "week": 4,
        "value": 3663,
        "memberCount": 3
      },
      {
        "type": "After Joining DPC",
        "filterCode": "POST_DPC",
        "week": 8,
        "value": 2598,
        "memberCount": 4
      },
      {
        "type": "After Joining DPC",
        "filterCode": "POST_DPC",
        "week": 12,
        "value": 60573,
        "memberCount": 7
      },
      {
        "type": "After Joining DPC",
        "filterCode": "POST_DPC",
        "week": 16,
        "value": 7261,
        "memberCount": 7
      },
      {
        "type": "After Joining DPC",
        "filterCode": "POST_DPC",
        "week": 20,
        "value": 964,
        "memberCount": 7
      },
      {
        "type": "After Joining DPC",
        "filterCode": "POST_DPC",
        "week": 24,
        "value": 1468,
        "memberCount": 4
      },
      {
        "type": "After Joining DPC",
        "filterCode": "POST_DPC",
        "week": 28,
        "value": 10299,
        "memberCount": 4
      },
      {
        "type": "After Joining DPC",
        "filterCode": "POST_DPC",
        "week": 32,
        "value": 542,
        "memberCount": 4
      },
      {
        "type": "After Joining DPC",
        "filterCode": "POST_DPC",
        "week": 36,
        "value": 3972,
        "memberCount": 5
      },
      {
        "type": "After Joining DPC",
        "filterCode": "POST_DPC",
        "week": 40,
        "value": 3504,
        "memberCount": 2
      },
      {
        "type": "After Joining DPC",
        "filterCode": "POST_DPC",
        "week": 44,
        "value": 3662,
        "memberCount": 1
      },
      {
        "type": "After Joining DPC",
        "filterCode": "POST_DPC",
        "week": 48,
        "value": 3652,
        "memberCount": 2
      }
    ],
    "postDpcDataAvgList": [
      {
        "week": "4",
        "type": "Avg Post DPC Member Cost",
        "value": 25,
        "filterCode": "POST_DPC"
      },
      {
        "week": "8",
        "type": "Avg Post DPC Member Cost",
        "value": 20,
        "filterCode": "POST_DPC"
      },
      {
        "week": "12",
        "type": "Avg Post DPC Member Cost",
        "value": 488,
        "filterCode": "POST_DPC"
      },
      {
        "week": "16",
        "type": "Avg Post DPC Member Cost",
        "value": 62,
        "filterCode": "POST_DPC"
      },
      {
        "week": "20",
        "type": "Avg Post DPC Member Cost",
        "value": 8,
        "filterCode": "POST_DPC"
      },
      {
        "week": "24",
        "type": "Avg Post DPC Member Cost",
        "value": 13,
        "filterCode": "POST_DPC"
      },
      {
        "week": "28",
        "type": "Avg Post DPC Member Cost",
        "value": 98,
        "filterCode": "POST_DPC"
      },
      {
        "week": "32",
        "type": "Avg Post DPC Member Cost",
        "value": 5,
        "filterCode": "POST_DPC"
      },
      {
        "week": "36",
        "type": "Avg Post DPC Member Cost",
        "value": 40,
        "filterCode": "POST_DPC"
      },
      {
        "week": "40",
        "type": "Avg Post DPC Member Cost",
        "value": 36,
        "filterCode": "POST_DPC"
      },
      {
        "week": "44",
        "type": "Avg Post DPC Member Cost",
        "value": 77,
        "filterCode": "POST_DPC"
      },
      {
        "week": "48",
        "type": "Avg Post DPC Member Cost",
        "value": 81,
        "filterCode": "POST_DPC"
      }
    ],
    "preMemberCountDataList": [
      {
        "week": "-52",
        "type": "Members",
        "value": 66,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-48",
        "type": "Members",
        "value": 71,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-44",
        "type": "Members",
        "value": 75,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-40",
        "type": "Members",
        "value": 76,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-36",
        "type": "Members",
        "value": 76,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-32",
        "type": "Members",
        "value": 76,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-28",
        "type": "Members",
        "value": 78,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-24",
        "type": "Members",
        "value": 80,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-20",
        "type": "Members",
        "value": 82,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-16",
        "type": "Members",
        "value": 84,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-12",
        "type": "Members",
        "value": 88,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-8",
        "type": "Members",
        "value": 90,
        "filterCode": "PRE_DPC"
      },
      {
        "week": "-4",
        "type": "Members",
        "value": 91,
        "filterCode": "PRE_DPC"
      }
    ],
    "postMemberCountDataList": [
      {
        "week": "4",
        "type": "Members",
        "value": 142,
        "filterCode": "POST_DPC"
      },
      {
        "week": "8",
        "type": "Members",
        "value": 127,
        "filterCode": "POST_DPC"
      },
      {
        "week": "12",
        "type": "Members",
        "value": 124,
        "filterCode": "POST_DPC"
      },
      {
        "week": "16",
        "type": "Members",
        "value": 116,
        "filterCode": "POST_DPC"
      },
      {
        "week": "20",
        "type": "Members",
        "value": 110,
        "filterCode": "POST_DPC"
      },
      {
        "week": "24",
        "type": "Members",
        "value": 108,
        "filterCode": "POST_DPC"
      },
      {
        "week": "28",
        "type": "Members",
        "value": 105,
        "filterCode": "POST_DPC"
      },
      {
        "week": "32",
        "type": "Members",
        "value": 102,
        "filterCode": "POST_DPC"
      },
      {
        "week": "36",
        "type": "Members",
        "value": 98,
        "filterCode": "POST_DPC"
      },
      {
        "week": "40",
        "type": "Members",
        "value": 96,
        "filterCode": "POST_DPC"
      },
      {
        "week": "44",
        "type": "Members",
        "value": 47,
        "filterCode": "POST_DPC"
      },
      {
        "week": "48",
        "type": "Members",
        "value": 45,
        "filterCode": "POST_DPC"
      },
      {
        "week": "52",
        "type": "Members",
        "value": 45,
        "filterCode": "POST_DPC"
      }
    ],
    "additionalData": {
      "preVsPostDPCPercentage": -16,
      "dpcPercentageDataList": [
        {
          "countNum": 97.21006776379477,
          "displayText": "Average Pre DPC Member Cost"
        },
        {
          "countNum": 83.73606557377049,
          "percentage": -16,
          "displayText": "Average Post DPC Member Cost",
          "isClaimCostDown": true
        }
      ],
      "postAvgDpcCost": 83.73606557377049,
      "preAvgDpcCost": 97.21006776379477
    }
  }
};
