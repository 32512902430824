import LowPriorityIcon from "../../../../../assets/Icons/LowPriorityIcon";
import {TASK_PRIORITY_STATUS} from "../../../../../screens/TaskScreen/TaskScreenConst";
import HighSvg from "../../../Svg/HighSvg";
import LowSvg from "../../../Svg/LowSvg";
import MediumSvg from "../../../Svg/MediumSvg";

export const getIconByCode = (code: string) => {
  let ele = <></>;
  switch (code) {
    case TASK_PRIORITY_STATUS.ASAP:
      ele = <HighSvg />
      break;
    case TASK_PRIORITY_STATUS.URGENT:
      ele = <MediumSvg />
      break;
    case TASK_PRIORITY_STATUS.ROUTINE:
    ele = <LowPriorityIcon />;
  }
  return ele
}
