import React from 'react';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {Image} from 'native-base';
import { ICommonSvgProps } from '../interfaces';

const ConferenceUserRemove = (props: ICommonSvgProps) => {
  const isWebsite = isWeb();
  return (
    <>
      {isWebsite ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width ?? 32}
          height={props.height ?? 32}
          fill="none"
          viewBox="0 0 34 35"
        >
          <rect
            width="33"
            height="33"
            x="0.5"
            y="1"
            fill="#FCFDFD"
            rx="16.5"
          ></rect>
          <rect
            width="33"
            height="33"
            x="0.5"
            y="1"
            stroke="#E4E7EC"
            rx="16.5"
          ></rect>
          <path
            stroke="#667085"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.125"
            d="M20 24.25v-1.5a3 3 0 00-3-3h-5.25a3 3 0 00-3 3v1.5m16.5-7.5h-4.5m-3.375-3a3 3 0 11-6 0 3 3 0 016 0z"
          ></path>
        </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'32px'}
          source={require('../../../../assets/images/PNGImages/remove_user.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default ConferenceUserRemove;
