import { StyleSheet } from 'react-native';
import { Colors } from '../../../../../styles';

export const styles = StyleSheet.create({
    filterTitle: {
        backgroundColor: Colors.Custom.Gray50,
        borderWidth: 1,
        borderColor: Colors.Custom.Gray200,
        borderRadius: 8,
        paddingVertical: 8,
        paddingHorizontal: 18,
        maxWidth: 250,
        marginTop: 10
    }
});