import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles/Colors';

export const styles = StyleSheet.create({
  headerContainer: {
    paddingHorizontal: 0,
    paddingVertical: 8,
    borderBottomColor: Colors.Custom.Gray200,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.Custom.BackgroundColor,
  },
  datePickerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 12,
    borderWidth: 1,
    borderColor: '#e4e7ec',
    borderRadius: 8,
    backgroundColor: '#fff',
    overflow: 'hidden',
    height: 40,
    marginTop: 12
  },
  checkBoxDropDownContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 12,
    borderWidth: 1,
    borderColor: '#e4e7ec',
    borderRadius: 8,
    backgroundColor: '#fff',
    overflow: 'hidden',
    height: 40,
    padding: 16,
    marginTop: 12
  },
  backButtonStyle: {
    height: 40,
    width: 40,
    borderRadius: 30,
    marginLeft: -11,
    marginTop: 12
  },
  labelsDropDown: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 12,
    borderWidth: 1,
    borderColor: '#e4e7ec',
    borderRadius: 8,
    backgroundColor: '#fff',
    overflow: 'hidden',
    height: 40,
    padding: 16,
    marginTop: 12
  },
});
