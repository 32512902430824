export const BILLING_ATTRIBUTES = {
    COMPLEXITY: 'complexity',
    FACE_TO_FACE_DATE: 'faceTofaceDate',
    DISCHARGE_DATE_TIME: 'dischargeDateTime',
    FIRST_CONTACT_DATE_TIME: 'firstContactDateTime'
}

export const RESOURCE_ENTITIES = {
    TASK: 'TASK',
    FORM: 'FORM',
    MESSAGE: 'MESSAGE',
    APPOINTMENT: 'APPOINTMENT',
    VOICE_CALL: 'VOICE_CALL',
    CALL: 'CALL',
    VIDEO_CALL: 'VIDEO_CALL',
    care_plan_activity: 'care_plan_activity',
    appointment_scheduled: 'appointment_scheduled',
    patient_interaction: 'patient_interaction',
    assessment_activity: 'assessment_activity',
    care_management_activity: 'care_management_activity',
    access_assistance: 'access_assistance',
    health_records: 'health_records',
}

export enum CarePlanTaskType {
    care_plan_activity = 'Care Plan Established / Reviewed',
    appointment_scheduled = 'Scheduling Specialist or Doctor Appointments',
    patient_interaction = 'Calling Patient',
    assessment_activity = 'Assessment Established / Reviewed',
    care_management_activity = 'Care Management Activity',
    access_assistance = 'Assistance accessing tests, X-rays and labs',
    health_records = 'Recording Patient health information (includes Medications and allergies,Demographics,Care coordination, On-going clinical care)',
}

export const CARE_MANAGEMENT_PROGRAM_CODES = {
  ALL: 'all',
  CCM: 'ccm',
  TCM: 'tcm',
  AWV: 'awv',
};

export const CARE_MANAGEMENT_PROGRAM_LIST = [
  {
    tabCode: CARE_MANAGEMENT_PROGRAM_CODES.ALL,
    tabTitle: 'All',
  },
  {
    tabCode: CARE_MANAGEMENT_PROGRAM_CODES.CCM,
    tabTitle: 'CCM',
  },
  {
    tabCode: CARE_MANAGEMENT_PROGRAM_CODES.TCM,
    tabTitle: 'TCM',
  },
  {
    tabCode: CARE_MANAGEMENT_PROGRAM_CODES.AWV,
    tabTitle: 'AWV',
  }
];

export const CARE_MANAGEMENT_CATEGORY_CODES = {
    ACTIVE: 'Active',
    COMPLETED: 'Past',
    NEW: 'New'
}

export enum ADDITIONAL_DATA_CODE {
  PCP_AND_CARE_MANAGER = 'PCP_AND_CARE_MANAGER',
  FUTURE_APPOINTMENT = 'FUTURE_APPOINTMENT',
  CHRONIC_CONDITIONS = 'CHRONIC_CONDITIONS',
  PATIENT_CONSENT = 'PATIENT_CONSENT',
  LAST_UPDATED = 'LAST_UPDATED'
}

export const CARE_MANAGEMENT_ACTION_LIST = [
  {
    tabCode: CARE_MANAGEMENT_PROGRAM_CODES.CCM,
    tabTitle: 'CCM',
  },
  {
    tabCode: CARE_MANAGEMENT_PROGRAM_CODES.TCM,
    tabTitle: 'TCM',
  },
  {
    tabCode: CARE_MANAGEMENT_PROGRAM_CODES.AWV,
    tabTitle: 'AWV',
  },
];

export const CARE_PROGRAM_ASSIGN_ACTION_CODE = {
  ASSIGN_CARE_PROGRAM: 'ASSIGN_CARE_PROGRAM',
  UNASSIGN_CARE_PROGRAM: 'UNASSIGN_CARE_PROGRAM'
}

export const CONTACT_CARE_PROGRAM_FITER_ACTION_CODES = {
  ASSIGNEE_FILTER_CHANGE: 'ASSIGNEE_FILTER_CHANGE',
  STATUS_CHANGE: 'STATUS_CHANGE',
  DATE_FILTER_CHANGE: 'DATE_FILTER_CHANGE',
  CLEAR_ALL: 'CLEAR_ALL'
};

export const CARE_PROGRAM_CLOSE_REASONS = {
  PATIENT_DECEASED: 'Patient Deceased',
  NO_LONGER_MANAGING_PATIENT: 'No Longer Managing Patient',
  OTHER: 'Other',
};

export enum CareProgramDateType {
  FROM_DATE = 'fromDate',
  TO_DATE = 'toDate'
}
