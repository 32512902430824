import {Drawer, Select} from 'antd';
import {
  VStack,
  Text,
  View,
  Pressable,
  HStack,
  FormControl,
  TextArea,
} from 'native-base';
import {StyleSheet} from 'react-native';
import {useContext, useEffect, useState} from 'react';
import {
  BUTTON_TYPE,
  DISPLAY_DATE_FORMAT,
  GOAL_STATUS_CODES,
} from '../../../../../constants/StringConst';
import {Colors} from '../../../../../styles/Colors';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {IPatientJourneyProps} from './PatientJourneyInterface';
import {ModalActionDatePicker} from '../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import {getMomentObj, isPastDay} from '../../../../../utils/DateUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import { getNoteStringToSave, STOP_JOURNEY_ACTION, STOP_JOURNEY_BUTTONS } from './PatientCareJourneyHelper';
import { capitalizeText } from '../../../ContentManagement/CampaignManagement/Helper';
import { FHAlertDialog } from '../../../../common/FHAlertDialog';
import { useIntl } from 'react-intl';
import FHDrawerAlert from '../../../../common/FHDrawerAlert/FHDrawerAlert';
import ReactQuill from 'react-quill';
import { RTE_MODULES } from '../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import { MLOV_CATEGORY } from '../../../../../constants';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { IMlov } from '../../../../../Interfaces/CommonInterfaces';

const StopCareJourneyDrawer = (props: IPatientJourneyProps) => {
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const goalStatusMlov = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.PatientCareJourneyGoalStatus,
  );
  const [stateData, setStateData] = useState({
    selectedBtn: STOP_JOURNEY_ACTION.IMMEDIATELY_STOP,
    selectedDate: undefined,
    showConfirmation: false,
    message: intl.formatMessage({id: 'stopCareJourneyMessage'}),
    header: intl.formatMessage({id: 'stopCareJourneyHeader'}),
    goalStatus: goalStatusMlov?.find((goalStatus) => goalStatus?.code === GOAL_STATUS_CODES.NOT_ACHIEVED),
    note: props?.selectedJourney?.note || ''
  });

  const isSidecarContext = mlovData?.sidecarContext?.isSidecar;

  const resetContent = () => {
    setStateData(prev => {
      return {
        ...prev,
        selectedBtn: STOP_JOURNEY_ACTION.IMMEDIATELY_STOP,
        selectedDate: undefined,
        goalStatus: goalStatusMlov?.find((goalStatus) => goalStatus?.code === GOAL_STATUS_CODES.NOT_ACHIEVED),
        note: '',
      };
    });
  };

  useEffect(() => {
    if (!props?.enabledDrawer) {
      resetContent()
    }
  }, [props?.enabledDrawer]);

  return (
    <>
    <Drawer
      destroyOnClose
      placement="right"
      onClose={() => {
        resetContent();
        props.onCancel();
      }}
      visible={props.enabledDrawer}
      closable={false}
      width={isSidecarContext? '100%': '40%'}
      title={<ModalActionTitle
        title={capitalizeText(props?.selectedJourney?.title || props?.selectedJourney?.careJourney?.title)}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: 'cancel',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              resetContent();
              props.onCancel();
            },
          },
          {
            show: true,
            isDisabled:(stateData.selectedBtn === STOP_JOURNEY_ACTION.STOP_DATE && !stateData.selectedDate),
            id: 2,
            btnText: 'stopJourney',
            isLoading: props.isLoading,
            textColor: Colors.Custom.mainPrimaryPurple,
            variant: BUTTON_TYPE.PRIMARY,
            isTransBtn: false,
            onClick: () => {
              setStateData(prev => {
                return {
                  ...prev,
                  showConfirmation: true,
                };
              });
            },
          },
        ]}
      />}
      >

      <HStack flex={1}>
        <HStack marginTop={2}>
          <View
            style={{
              height: 44,
              borderRadius: 44,
              borderWidth: 1,
              borderColor: Colors.Custom.Gray200,
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: '#fff',
            }}>
            {STOP_JOURNEY_BUTTONS.map(btn => {
              return (
                <Pressable
                  key={btn.Code}
                  onPress={() => {
                    setStateData(prev => {
                      return {
                        ...prev,
                        selectedDate: undefined,
                        selectedBtn: btn.Code,
                      };
                    });
                  }}>
                  <View
                    style={[
                      styles.swtichBtnStyle,
                      stateData.selectedBtn === btn.Code
                        ? {backgroundColor: Colors.primary['300']}
                        : {},
                    ]}>
                    <Text
                      size={'xsBold'}
                      color={
                        stateData.selectedBtn === btn.Code
                          ? '#fff'
                          : Colors.Custom.Gray900
                      }>
                      {btn.text}
                    </Text>
                  </View>
                </Pressable>
              );
            })}
          </View>
        </HStack>
      </HStack>
        {stateData.selectedBtn === STOP_JOURNEY_ACTION.STOP_DATE && (
            <View paddingTop={5}>
              <ModalActionDatePicker
                isRequired={false}
                label={''}
                format={DISPLAY_DATE_FORMAT}
                onChange={(date: any) => {
                  if (date) {
                    setStateData(prev => {
                      return {
                        ...prev,
                        selectedDate: date
                      };
                    });
                  }
                }}
                disabledDate={(current: any) => {
                  return current && isPastDay(current);
                }}
                value={stateData.selectedDate ? getMomentObj(stateData.selectedDate || '') : ''}
              />
            </View>
        )}
        <FormControl style={[styles.formElement]}>
          <FormControl.Label isRequired style={styles.formLabel}>
            <DisplayText
              textLocalId={'goalStatus'}
              size={'smMedium'}
              extraStyles={{ color: Colors.Custom.Gray900 }}
            />
          </FormControl.Label>
          <Select
            dropdownMatchSelectWidth={3}
            size="large"
            value={stateData?.goalStatus?.value}
            onChange={(value: any, option: any) => {
              setStateData((prev) => {
                return {
                  ...prev,
                  goalStatus: option
                }
              })
            }}
            style={{ height: '36px' }}
            maxTagCount={'responsive'}
          >
            {goalStatusMlov?.map((goal: IMlov) => {
              return (
                <Select.Option
                  id={goal?.id}
                  value={goal?.value}
                  code={goal?.code}
                >
                  {goal.value}
                </Select.Option>
              );
            })}
          </Select>
        </FormControl>
        <HStack>
          <FormControl style={[styles.formElement]}>
            <FormControl.Label style={styles.formLabel}>
              <DisplayText
                textLocalId={'comment'}
                size={'smMedium'}
                extraStyles={{ color: Colors.Custom.Gray900 }}
              />
            </FormControl.Label>
            <ReactQuill
              className="appointment-notes"
              theme="snow"
              value={stateData.note || ''}
              onChange={(value: string) => {
                setStateData(prev => {
                  return {
                    ...prev,
                    note: value,
                  };
                });
              }}
              modules={RTE_MODULES}
            />
          </FormControl>
        </HStack>
      {stateData.showConfirmation && (
        <FHDrawerAlert
          isOpen={stateData.showConfirmation}
          header={stateData.header}
          message={stateData.message}
          closeModal={() => {
            setStateData(prev => {
              return {
                ...prev,
                showConfirmation: false,
              };
            });
          }}
          buttonActions={[
            {
              textLocalId: 'Close',
              buttonProps: {
                colorScheme: 'muted',
                variant: 'outline',
              },
              onPress: () => {
                setStateData(prev => {
                  return {
                    ...prev,
                    showConfirmation: false,
                  };
                });
              },
            },
            {
              textLocalId: 'Ok',
              textColor: 'white',
              buttonProps: {
                colorScheme: 'primary',
              },
              onPress: () => {
                setStateData(prev => {
                  return {
                    ...prev,
                    showConfirmation: false,
                  };
                });
                props.onActionPerformed({ stopDateTime: stateData.selectedDate, goalStatusId: stateData?.goalStatus?.id, note: getNoteStringToSave(stateData?.note)}, props.selectedJourney);
              },
            },
          ]}
        />
      )}
    </Drawer>
    </>
  );
};

export default StopCareJourneyDrawer;

export const styles = StyleSheet.create({
  swtichBtnStyle: {
    paddingHorizontal: 16,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 40,
  },
  formElement: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  formLabel: {
    marginLeft: 4,
  },
});
