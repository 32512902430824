import React from "react";

const VideoCallingSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
   <svg
   xmlns="http://www.w3.org/2000/svg"
   style={{width: 'inherit', height: 'inherit'}}
   fill="none"
   viewBox="0 0 36 36"
 >
   <path
     fill={svgColors}
     d="M7.88 21h16.483c.156.259.345.497.57.71l1.079 1.023a5.938 5.938 0 01-.63 1.505c-.282.49-.629.94-1.03 1.34l-1.192-.398a3.285 3.285 0 00-3.762 1.3l-1.081 1.59a3.54 3.54 0 00.405 4.463l.34.342A24.92 24.92 0 0116.5 33c-4.335 0-7.692-.984-10.036-3a5.625 5.625 0 01-1.96-4.266v-1.36a3.375 3.375 0 013.376-3.376zm17.635-2.633l.756-1.78c.354-.835 1.202-1.29 2.034-1.115l.177.045.945.303c.938.3 1.656 1.102 1.889 2.11.55 2.397-.111 5.315-1.983 8.754-1.87 3.435-3.921 5.5-6.15 6.195a2.64 2.64 0 01-2.496-.514l-.185-.17-.718-.72a2.04 2.04 0 01-.333-2.388l.106-.174 1.082-1.59c.426-.625 1.155-.92 1.857-.772l.19.052 1.998.666a7.62 7.62 0 001.995-2.278c.438-.736.74-1.543.894-2.385l.057-.405-1.663-1.578a2.03 2.03 0 01-.522-2.06l.07-.195.756-1.782-.756 1.78zM16.5 3.007a7.5 7.5 0 110 15 7.5 7.5 0 010-15z"
   ></path>
 </svg>
  );
}

export default React.memo(VideoCallingSvg);
