import {SUPPORTED_EVENT_CODE} from '../../constants/WebSocketConst';
import {sendConversationNotificationNoMessageEvent} from '../../services/SendNotification';
import {CHANNEL_TYPE_CODE} from '../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {updateMentionWhenOnMentionTab} from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import { updateSingleNotificationReadStatus } from '../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { NOTIFICATION_TYPES } from '../common/Notifications/NotificationsUtils';
import {IConversationAssigneeBusAction} from './interface';

export const onConversationMsgRead = (
  actionData: IConversationAssigneeBusAction,
  userUuid: string
) => {
  const {conversationData, selectedInboxTypeCode} = actionData;
  const conversationId = conversationData.id + '';
  if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
    updateMentionWhenOnMentionTab(conversationData, selectedInboxTypeCode);
  }
  sendConversationNotificationNoMessageEvent({
    conversationUuid: conversationData?.uuid,
    eventCode: SUPPORTED_EVENT_CODE.MESSAGE_READ,
    eventData: {
      conversationUuid: conversationData?.uuid,
      userUuid: userUuid,
    },
  });
  updateSingleNotificationReadStatus({
    type: NOTIFICATION_TYPES.CONVERSATION_UPDATED,
    typeId: conversationId,
    userId: userUuid,
    isRead: true,
  });
};
