import { IMlov } from "../../../../Interfaces";
import { IBatchedAccountUsersInterface } from "../../../CustomHooks/useGetBatchedAccountUsers";
import { ICondition } from "../../Forms/FHFormio/CustomComponents/Conditions/interfaces";
import { IContact } from "../../TeamInbox/Conversations/interfaces";

export interface IBillingTab {
  isBilledView?: boolean;
  serviceStartDateTime?: string;
  serviceEndDateTime?: string;
}

export interface IProgramType {
  code: string;
  value: string;
}

export interface IChronicCondition {
  id: string;
  title: string;
}

export enum BILLING_HOOK {
  ALL = 'ALL',
}

export interface IBillingSelectedFilters {
  programTypes: IMlov[];
  cptCodes: string[];
  providers: IBatchedAccountUsersInterface[];
  chronicConditions: IChronicCondition[];
  dateOfService: {
    startDate: string;
    endDate: string;
  },
  members?: {
    value: string | undefined;
    label: string;
    key: string;
    contactData: any;
  }[];
}

export interface IHookState {
  cptCodes: string[];
  billingData: IBillingData[];
  totalAmountSum: number;
  selectedBills?: IBillingData[];
  selectedItemForDetailView?: IBillingData;
  attributesAndCodes?: IContactCareProgramAttributesAndCodes[];
  customerData?: {
    value: string | undefined;
    label: string;
    key: string;
    contactData: any;
  }[]
}

export interface ICareProgramCptCode {
  amount: number;
  durationInMinutes: number;
  cptCode: number;
}

export interface IContactCareProgram {
  additionalAttributes: {
    attributeKey: string;
    attributeValue: string;
  }[];
}

export interface IContactCareProgramAttributesAndCodes {
  contactCareProgram: IContactCareProgram;
  billableActivityCptCodes: IBillableActivityCptCodes[]
}

export interface IBillableActivityCptCodes {
  careProgramCptCode: ICareProgramCptCode;
}

export interface IBillingData extends IBillableActivityRecord {
  contactData: IContact;
  providerUserDetails: IBatchedAccountUsersInterface;
  chronicConditionWithPatientId?: ICondition[];
}

export interface IBillableActivityEntities {
  resourceId: string;
  resourceTypeCode: string;
  durationInMinutes: number;
  createdBy: string;
  updatedBy: string;
  createdOn: string;
  performedByUserId: string;
  timestamp: string;
}

export interface IParamsForBillableActivites {
  limit: number;
  offset: number;
  billableActivityStatusIds: string[];
  careProgramTypeIds: string[];
  cptCodes: string[];
  primaryCarePhysicianUserIds: string[];
  serviceEndDateTime: string;
  serviceStartDateTime: string;
  contactIds: string[];
}

export interface IParamsForBillingHistory {
  limit: number;
  offset: number;
}

export interface IHandleTableActions {
  actionCode: string;
  actionData: any;
  args?: { isSelected?: boolean }
}

export interface IHandleBillingHistoryTableActions {
  actionCode: string;
  actionData: any;
}

export interface IBillingHistoryHookState {
  billedRecords: IBilledActivityRecord[];
  timezone: string;
  selectedRecordForBillingReport?: IBilledActivityRecord;
  totalAmountSum: number;
}

export interface IBilledActivityRecord {
  month: string;
  totalAmount: number;
  distinctContactIdCount: number;
  ehrCode: string;
}

export interface IGetBilledActivitiesByMonthResponse {
  getBilledActivitiesByMonth: {
    records: IBilledActivityRecord[];
    totalRecords: number;
    timezone: string;
  };
}

export interface IBillableActivityRecord {
  id: string;
  careProgramTypeId: string;
  contactId: string;
  serviceDateTime: string;
  totalAmount: number;
  totalDurationInMinutes: number;
  primaryCarePhysicianUserId: string;
}

export interface IGetBillableActivities {
  getBillableActivities: {
    records: IBillableActivityRecord[];
    totalRecords: number;
  };
  performedByUserId?: string;
}