import {de} from "react-native-paper-dates";
import {CARE_PROGRAM_TYPE_CODES, CARE_PROGRAM_TYPES} from "../../../../constants/MlovConst";
import { AddButtonAction, IContactCareProgram, IContactCareProgramStep, IFormLogResponse } from "../interface";
import CarePlanStatusComplete from "../../../../assets/svg/CarePlanStatusComplete";
import CarePlanStatusTodo from "../../../../assets/svg/CarePlanStatusTodo";
import CarePlanStatusSkipped from "../../../../assets/svg/CarePlanStatusSkipped";
import {getFormComponentsDataForProgress, getPercentageScore} from "../../../PublicPages/PublicForm/PublicFormHelper";
import {forEachExtensiveFormComponent} from "../../Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper";
import {FormComponentType} from "../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper";
import {CARE_PROGRAM_STEP_TYPE} from "../../../common/MemebersView/constant";

export const groupStepsNested = (steps: IContactCareProgramStep[]): IContactCareProgramStep[] => {
  const stepsMap = new Map<string, IContactCareProgramStep>();
  const rootSteps: IContactCareProgramStep[] = [];

  // First pass: Create a map of all steps and identify root steps
  steps.forEach(step => {
    stepsMap.set(step.id, { ...step, subSteps: [] });
    if (!step.parentStepId) {
      rootSteps.push(stepsMap.get(step.id)!);
    }
  });

  // Second pass: Group child steps under their parents
  steps.forEach(step => {
    if (step.parentStepId) {
      const parentStep = stepsMap.get(step.parentStepId);
      if (parentStep) {
        parentStep.subSteps = parentStep.subSteps || [];
        parentStep.subSteps.push(stepsMap.get(step.id)!);
      }
    }
  });

  // Sort function for steps
  const sortSteps = (a: IContactCareProgramStep, b: IContactCareProgramStep) => {
    if (a.sequenceNumber !== b.sequenceNumber) {
      return a.sequenceNumber - b.sequenceNumber;
    }
    return a.displaySequenceNumber - b.displaySequenceNumber;
  };

  // Sort root steps
  rootSteps.sort(sortSteps);

  // Recursively sort substeps
  const sortSubSteps = (step: IContactCareProgramStep) => {
    if (step.subSteps && step.subSteps.length > 0) {
      step.subSteps.sort(sortSteps);
      step.subSteps.forEach(sortSubSteps);
    }
  };

  rootSteps.forEach(sortSubSteps);

  return rootSteps;
};


export const getCareProgramNameByCode = (code: string) => {
  switch (code) {
    case CARE_PROGRAM_TYPE_CODES.CCM:
      return 'Chronic Care Management';
    case CARE_PROGRAM_TYPE_CODES.TCM:
      return 'Transitional Care Management';
    case CARE_PROGRAM_TYPE_CODES.ECM:
      return 'Enhanced Care Management';
    default:
      return '';
  }
}


export const getSubstepIcon = (status: string) => {
  switch (status) {
    case 'complete':
    case 'done':
      return CarePlanStatusComplete;
    case 'to_do':
      return CarePlanStatusTodo;
    case 'skip':
    case 'skipped':
      return CarePlanStatusSkipped;
    default:
      return CarePlanStatusTodo;
  }
}

export const getFormDetails = (careProgramDetails: IContactCareProgram, subStepId: string) => {
  const selectedStep = careProgramDetails?.contactCareProgramSteps?.find(
    (step) => step.id === subStepId
  );
  const formId = selectedStep?.careProgramStepAdditionalAttributes?.find(
    (attr) => attr.attributeKey === 'formId'
  )?.attributeValue?.valueString;
  const formLogId = careProgramDetails?.assessmentLogs?.find(
    (log) =>
      log.careProgramStepId === subStepId && log.resourceTypeCode === 'FORM_LOG'
  )?.resourceId;
  return {formId, formLogId};
}

const getStepLogByStepId = (stepId: string, stepLog: IContactCareProgram['stepsLog']) => {
  return stepLog.find((log) => log.careProgramStepId === stepId);
}


export const getInitSelectedStepAndCollapseIds = (
  groupedSteps: IContactCareProgramStep[],
  stepLog: IContactCareProgram['stepsLog'],
  careProgramStatuses: string[]
) => {
  const allowedStatuses = careProgramStatuses;
  let initSelectedStepId = '';
  const expandStepIds: string[] = [];

  for (const step of groupedSteps) {
    if (step?.subSteps?.length) {
      const stepWithInProgressStatus = step.subSteps.find((subStep) => {
        const currStepLog = getStepLogByStepId(subStep.id, stepLog || []);
        if (currStepLog?.careProgramStepStatusId) {
          return allowedStatuses.includes(currStepLog?.careProgramStepStatusId);
        }
        return false;
      });

      if (stepWithInProgressStatus) {
        initSelectedStepId = stepWithInProgressStatus.id;
        expandStepIds.push(step.id);
        break; // Stop looping after finding the first in-progress step
      }
    }
  }

  return {
    initSelectedStepId,
    expandStepIds,
  };
};


export const getFormResponseProgressByAssessmentLogs = async (
  assessmentLogs: IContactCareProgram['assessmentLogs'],
  formResponsePromise: (formLogIds: string[]) => Promise<any>,
) => {
  // Filter and map in one operation
  const formLogs = assessmentLogs
    .filter(log => log.resourceTypeCode === 'FORM_LOG' && log.resourceId)
    .map(log => ({
      formLogId: log.resourceId,
      stepId: log.careProgramStepId,
    }));

  if (!formLogs.length) {
    return new Map<string, number>();
  }

  const formLogResponses = await formResponsePromise(
    formLogs.map(log => log.formLogId)
  );

  // Create map of progress percentages
  return new Map(
    formLogs.map(log => {
      const formLog = formLogResponses?.formLogs?.find(
        (res: {id: string}) => res.id === log.formLogId
      );
      const percent = formLog?.formResponse?.foldPrefilledDataProgressPercentage;
      return [
        log.stepId,
        percent
      ];
    })
  );
};

export const fetchFormResponseFromFormOrFormLog = async (params: {
  formId: string | undefined;
  formLogId: string | undefined;
  fetchPreviousFormLog: boolean;
  formLogPromise: (formLogId: string) => Promise<any>;
  formByIdPromise: (formId: string) => Promise<any>;
  prevFormLogPromise: (formId: string, formLogId: string) => Promise<any>;
}): Promise<IFormLogResponse> => {
  const {formId, formLogId, fetchPreviousFormLog, formLogPromise, formByIdPromise, prevFormLogPromise} = params;
  const promises: Promise<any>[] = [];
  let inputFormComponents: any[] = [];
  promises.push(
    new Promise(async (resolve) => {
      try {
        if (formLogId) {
          const response = await formLogPromise(formLogId);
          const formLog = response?.data?.formLogs[0];
          // if formLog is not prefilled, fetch the form from formId
          if (!formLog?.formResponse && formId) {
            const formResponse = await formByIdPromise(formId);
            const formResponseData = formResponse?.data?.form;
            inputFormComponents = formResponseData?.components || [];
          }

          const {
            componentWiseProgress,
            formPrefilledByFoldPercent,
            submittedData,
            formComponents,
          } = processFormLog(formLog, inputFormComponents);

          resolve({
            componentWiseProgress,
            formPrefilledByFoldPercent,
            submittedData,
            formComponents,
            formLog,
          });
        }
        resolve({});
      } catch (error) {
        resolve({});
      }
    })
  );


  promises.push(
    new Promise(async (resolve) => {
      if (fetchPreviousFormLog && formId && formLogId) {
        const response = await prevFormLogPromise(formId, formLogId);
        const formLog = response?.data?.formLogs[0];
        const {
          componentWiseProgress,
          formPrefilledByFoldPercent,
          submittedData,
          formComponents,
        } = processFormLog(formLog);

        resolve({
          componentWiseProgress,
          formPrefilledByFoldPercent,
          submittedData,
          formComponents,
          formLog,
        });
      } else {
        resolve({});
      }
    })
   );

  const responses = await Promise.all(promises);

  const previouslyFilledFormInstance: IFormLogResponse | undefined = Boolean(
    responses[1]?.formLog?.formResponse
  )
    ? responses[1]
    : undefined;

  if (previouslyFilledFormInstance) {
    previouslyFilledFormInstance['previousFormLogIsExisting'] = Boolean(responses[1]?.formLog?.formResponse);
  }

  const newInstanceForm = responses[0];

  // we need to consider subjectId always from the newInstanceForm
  if (newInstanceForm?.formLog?.subjectId && previouslyFilledFormInstance) {
    previouslyFilledFormInstance.formLog.subjectId = newInstanceForm?.formLog?.subjectId;
  }
  

  return fetchPreviousFormLog && previouslyFilledFormInstance
    ? previouslyFilledFormInstance
    : newInstanceForm;
}

export const processFormLog = (formLog: any, newformComponents?: any[]): IFormLogResponse => {
  let componentWiseProgress: Record<string, number> = {};
  let formPrefilledByFoldPercent = 0;
  const submittedData: any = {};
  let formComponents: any[] = newformComponents || [];
  if (formLog?.formResponse?.foldPrefilledDataProgressByComponent) {
    componentWiseProgress =
      formLog?.formResponse?.foldPrefilledDataProgressByComponent;
    formPrefilledByFoldPercent = parseFloat(
      formLog?.formResponse?.foldPrefilledDataProgressPercentage || '0'
    );
  }
  formComponents = formLog?.formResponse?.formResponse?.components || newformComponents || [];

  forEachExtensiveFormComponent(formComponents, (component) => {
    if (component.type === FormComponentType.BUTTON) {
      component.disableOnInvalid = false;
    } else if (
      component.selectedValue ||
      component.selectedValue == false ||
      component.selectedValue == 0
    ) {
      submittedData[component.key] = component.selectedValue;
    }
  });

  return {
    componentWiseProgress,
    formPrefilledByFoldPercent,
    submittedData,
    formComponents,
    formLog,
  };
}


export const canShowAddButtonWithAction = (step: IContactCareProgramStep): AddButtonAction | undefined => {
  const actionDetails = step.careProgramStepAdditionalAttributes?.find((attr) => attr.attributeKey === 'supportedActions');
  const supportedActions = actionDetails?.attributeValue?.valueJson || [];
  if (
    supportedActions.includes(CARE_PROGRAM_STEP_TYPE.ASSESSMENT) ||
    supportedActions.includes(CARE_PROGRAM_STEP_TYPE.USER_ASSESSMENT)
  ) {
    return AddButtonAction.SEND_FORM;
  }
  if (supportedActions.includes(CARE_PROGRAM_STEP_TYPE.BILLING)) {
    return AddButtonAction.BILLING;
  }
  return undefined;
}

//  KEPT FOR FUTURE USE
// export const processInsertStepResponse = (
//   response: AddCareProgramStepResponse,
//   contactCareProgramDetails: IContactCareProgram
// ): Pick<
//   IContactCareProgram,
//   'assessmentLogs' | 'stepsLog' | 'contactCareProgramSteps'
// > => {
//   let existingAssessmentLogs = contactCareProgramDetails?.assessmentLogs || [];

//   const existingAssessmentLogsMap = new Map(
//     existingAssessmentLogs.map((log) => [log.id, log.id])
//   );

//   let existingStepLogs = contactCareProgramDetails?.stepsLog || [];

//   const existingStepLogsMap = new Map(
//     existingStepLogs.map((log) => [log.id, log.id])
//   );

//   let existingSteps = contactCareProgramDetails?.contactCareProgramSteps || [];

//   const existingStepsMap = new Map(
//     existingSteps.map((step) => [step.id, step.id])
//   );

//   response.careProgramSteps.forEach((newStepToInsert) => {
//     const assessmentLog = newStepToInsert?.assesmentLogs;
//     const stepLog = newStepToInsert?.stepLogs;
//     const additionalAttributes = newStepToInsert?.additionalAttributes;

//     const newStep: IContactCareProgramStep = {
//       id: newStepToInsert.id,
//       title: newStepToInsert.title,
//       careProgramStepType: newStepToInsert.careProgramStepType,
//       careProgramSubSteps: newStepToInsert.careProgramSubSteps,
//       careProgramStepAdditionalAttributes: additionalAttributes,
//       sequenceNumber: newStepToInsert.sequenceNumber,
//       displaySequenceNumber: newStepToInsert.displaySequenceNumber,
//       parentStepId: newStepToInsert.parentStepId,
//     };

//     // Push new step to steps array overwrite if already exists
//     if (!existingStepsMap.has(newStep.id)) {
//       existingSteps.push(newStep);
//     } else {
//       existingSteps = existingSteps.map((step) =>
//         step.id === newStep.id ? newStep : step
//       );
//     }

//     // Push new assessment log to assessment logs array overwrite if already exists
//     if (!existingAssessmentLogsMap.has(assessmentLog?.id)) {
//       existingAssessmentLogs.push(assessmentLog);
//     } else {
//       existingAssessmentLogs = existingAssessmentLogs.map((log) =>
//         log.id === assessmentLog?.id ? assessmentLog : log
//       );
//     }

//     // Push new step log to step logs array overwrite if already exists
//     if (!existingStepLogsMap.has(stepLog?.id)) {
//       existingStepLogs.push(stepLog);
//     } else {
//       existingStepLogs = existingStepLogs.map((log) =>
//         log.id === stepLog?.id ? stepLog : log
//       );
//     }
//   });

//   return {
//     assessmentLogs: existingAssessmentLogs,
//     stepsLog: existingStepLogs,
//     contactCareProgramSteps: existingSteps,
//   };
// };

export const getStepAddSuccessMessage = (actionCode: AddButtonAction) => {
  switch (actionCode) {
    case AddButtonAction.SEND_FORM:
      return 'Assessment added successfully';
    default:
      return 'Step added successfully';
  }
}

