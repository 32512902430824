export const POWER_BI_FORMS_TABLE_LIST = [
  'FormComponent',
  'FormComponentAssociation',
  'FormComponentResponse',
  'FormLogMemberships',
  'FormQuestionResponses',
  'FormResponseRiskScores',
  'RiskScoreInterpretation',
  'Forms',
];

export const POWER_BI_CUSTOM_TABLE_COLUMN = {
  TaskDetails: 'taskdetails',
  ContactDetails: 'contactdetails',
};


export const POWER_BI_ENTITY_TYPE_CODE = {
  FORMS: 'FORMS'
};
