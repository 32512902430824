import React, {useState, useEffect} from 'react';
import {Mix} from '@ant-design/plots';
import {each} from '@antv/util';
import {getDataForTooltipForPreAndPostDPC} from '../../EmployerReportUtils';
import CustomTooltip from '../CustomTooltip';
import {IPreVsPostDpcChartProps} from './interface';
import {EMPLOYER_REPORT_FILTER_CODES} from '../../../../../../../constants';
import {MixConfig} from '@ant-design/charts';
const PreVsPostDpcChart = (props: IPreVsPostDpcChartProps) => {
  const containerWidth = 800;
  const plots: any = [];
  const total = 2;
  const preOrPostDpcCost = [props?.resourceData?.preDpcDataList, props?.resourceData?.postDpcDataList] || [];
  const avgPreAndPostDpcMemberCost = [props?.resourceData?.perDpcDataAvgList, props?.resourceData?.postDpcDataAvgList] || [];
  const preOrPostDpcMemberCount = [props?.resourceData?.preMemberCountDataList, props?.resourceData?.postMemberCountDataList] || [];
  const avgPreDpcCost = avgPreAndPostDpcMemberCost[0] || [];
  const avgPostDpcCost = avgPreAndPostDpcMemberCost[1] || [];
  const preDpcMemberCount = preOrPostDpcMemberCount[0] || [];
  const postDpcMemberCount = preOrPostDpcMemberCount[1] || [];

  // if (props?.resourceData?.preDpcDataList || props?.resourceData?.postDpcDataList) {
  //   each(preOrPostDpcCost, (data, idx) => {
  //     const startX = idx / total;
  //     plots.push({
  //       type: 'column',
  //       legend: true,
  //       region: {
  //         start: {
  //           x: startX,
  //           y: 0,
  //         },
  //         end: {
  //           x: (idx + 1) / total,
  //           y: 1,
  //         },
  //       },
  //       options: {
  //         data,
  //         xField: 'week',
  //         yField: 'value',
  //         seriesField: 'type',
  //         color: "#BFD7F6",
  //         isGroup: true,
  //         legend: true,
  //         meta: {
  //           value: {
  //             sync: true,
  //           },
  //         },
  //         xAxis: {
  //           label: {
  //             autoRotate: true,
  //           },
  //         },
  //         yAxis: {
  //           label: {
  //             formatter: (v: any) => '$' + v,
  //           },
  //           tickCount: 10,
  //         },
  //         minColumnWidth: 12,
  //         appendPadding: [40, 40],
  //         annotations: [
  //           {
  //             type: 'text',
  //             content: data[0].type,
  //             position: ['50%', '0%'],
  //             offsetY: -15,
  //             style: {
  //               textAlign: 'center',
  //             },
  //           }
  //         ],
  //       },
  //     });
  //   });
  // }


  if (props?.resourceData?.preMemberCountDataList || props?.resourceData?.postMemberCountDataList) {

    each(preOrPostDpcMemberCount, (data, idx) => {
      const startX = idx / total;
      plots.push({
        type: 'column',
        legend: true,
        region: {
          start: {
            x: startX,
            y: 0,
          },
          end: {
            x: (idx + 1) / total,
            y: 1,
          },
        },
        options: {
          data,
          xField: 'week',
          yField: 'value',
          seriesField: 'type',
          color: "#BFD7F6",
          isGroup: true,
          legend: true,
          meta: {
            value: {
              sync: true,
            },
          },
          xAxis: {
            label: {
              autoRotate: true,
            },
          },
          yAxis: {
            position: 'right',
            line: null,
            grid: null,
            tickCount: 10,
          },
          minColumnWidth: 12,
          appendPadding: [40, 40],
          annotations: [
            {
              type: 'text',
              content: data[0].filterCode === 'POST_DPC' ? 'After Joining DPC' : 'Before Joining DPC',
              position: ['50%', '0%'],
              offsetY: -15,
              style: {
                textAlign: 'center',
              },
            }
          ],
        },
      });
    });
  }

  if (props?.resourceData?.postDpcDataAvgList || props?.resourceData?.perDpcDataAvgList) {
    each(avgPreAndPostDpcMemberCost, (data, idx) => {
      const startX = idx / total;

      plots.push({
        type: 'line',
        region: {
          start: {
            x: startX,
            y: 0,
          },
          end: {
            x: (idx + 1) / total,
            y: 1,
          },
        },
        options: {
          data,
          xField: 'week',
          yField: 'value',
          seriesField: 'type',
          xAxis: false,
          isGroup: false,
          yAxis: {
            position: 'left',
            label: {
              formatter: (v: any) => '$' + v,
            },
            tickCount: idx === 1 ? 5 : 10,
          },
          smooth: true,
          meta: {
            value: {
              sync: false,
            },
          },
          color: data[0].filterCode === 'POST_DPC' ? '#80A4D5' : '#EEA47B',
        },
      });
    });
  }

  // if (props?.resourceData?.postDpcDataAvgList || props?.resourceData?.perDpcDataAvgList) {
  //   each(avgPreAndPostDpcMemberCost, (data, idx) => {
  //     const startX = idx / total;
  //     plots.push({
  //       type: 'line',
  //       legend: true,
  //       region: {
  //         start: {
  //           x: startX,
  //           y: 0,
  //         },
  //         end: {
  //           x: (idx + 1) / total,
  //           y: 1,
  //         },
  //       },
  //       options: {
  //         data,
  //         xField: 'week',
  //         yField: 'value',
  //         seriesField: 'type',
  //         color: "#EEA47B",
  //         isGroup: true,
  //         legend: true,
  //         meta: {
  //           value: {
  //             sync: true,
  //           },
  //         },
  //         xAxis: {
  //           label: {
  //             autoRotate: true,
  //           },
  //         },
  //         yAxis: {
  //           label: {
  //             formatter: (v: any) => '$' + v,
  //           },
  //           tickCount: 10,
  //         },
  //         minColumnWidth: 12,
  //         appendPadding: [40, 40],
  //       },
  //     });
  //   });
  // }

  const onReady = (data: any) => {
    let filterData = {} as any;
    const type: string = data?.data?.data?.filterCode;
    const week = data?.data?.data?.week;
    if (type === 'POST_DPC') {
      let gtWeek = week - 3;
      let ltWeek = week;
      if (ltWeek <= 0) {
        gtWeek = 1;
        ltWeek = 4;
      }
      filterData = [
        {
          filterCode: EMPLOYER_REPORT_FILTER_CODES.PRE_VS_POST_DCP_WEEK,
          filterValue: {
            gtWeek: week - 3,
            ltWeek: week
          },
        },
      ];
    } else {
      let gtWeek = -week;
      let ltWeek = gtWeek - 3;
      if (ltWeek <= 0) {
        gtWeek = 1;
        ltWeek = 4;
      }
      filterData = [
        {
          filterCode: EMPLOYER_REPORT_FILTER_CODES.PRE_VS_POST_DCP_WEEK,
          filterValue: {
            gtWeek: -gtWeek,
            ltWeek: -ltWeek
          },
        },
      ];
    }

    filterData = [...filterData]
    props?.onActionClick?.(props?.sectionData.resourceCode || '', {
      filterData,
      count: data?.data?.data?.memberCount, type: type
    });
  };
  //MixConfig
  const config: any = {
    plots,
    slider: false,
    syncViewPadding: true,
    height: 280,
    width: containerWidth,
    legend: {
      position: 'top-right',
      items: [
        {
          name: 'Avg Pre DPC Member Cost',
          marker: {
            symbol: 'circle',
            spacing: 8,
            style: {
              r: 4,
              fill: '#EEA47B',
            },
          },
        },
        {
          name: 'Avg Post DPC Member Cost',
          marker: {
            symbol: 'circle',
            spacing: 8,
            style: {
              r: 4,
              fill: '#80A4D5',
            },
          },
        },
        {
          name: 'Members',
          marker: {
            symbol: 'circle',
            spacing: 8,
            style: {
              r: 4,
              fill: '#BFD7F6',
            },
          },
        },
      ],
    },
    tooltip: {
      customContent(title: any, data: any) {
        if (data?.[0]?.data?.week) {
          const titleStr = 'Week ' + data?.[0]?.data?.week;
          const tooltipData: any = getDataForTooltipForPreAndPostDPC({
            preOrPostDpcCost: preOrPostDpcCost,
            avgPreDpcCost: avgPreDpcCost,
            avgPostDpcCost: avgPostDpcCost,
            preDpcMemberCount: preDpcMemberCount,
            postDpcMemberCount: postDpcMemberCount,
          }, data?.[0]?.data);
          if (tooltipData?.length) {
            return <CustomTooltip title={titleStr} data={tooltipData} />
          }
        }
        return '';
      },
    },
    onReady: (plot: any) => {
      plot.on('element:click', (args: any) => {
        if (args?.data?.data?.type) {
          onReady(args);
        }
      });
    },
  };

  return <Mix {...config} />;
};

export default React.memo(PreVsPostDpcChart);
