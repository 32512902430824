import { Center, HStack, Skeleton, useToast, View } from 'native-base';
import { useEffect, useState } from 'react';
import Icon from 'react-native-vector-icons/AntDesign';
import { TableTopBar } from '../../../common/TableTopBar';
import { styles } from '../CodeGroups/CodeGroupsStyles';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { NoDataFound } from '../../../common/NoDataFound';
import PlusIcon from '../../../common/Svg/PlusSvg';
import { BUTTON_TYPE, PERSON_TYPES } from '../../../../constants';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { Colors } from '../../../../styles';
import AddCustomContactTypeModal from './CustomContactTypesModal/AddCustomContactTypeModal';
import { ICustomContactTypesTableData, ICustomContactTypeStateData } from './interfaces';
import CustomContactTypesTable from './CustomContactTypesTable/CustomContactTypesTable';
import { getContactTypes } from './CustomContactTypeApi';
import { getFormattedData } from './utils';
import {getContactTypeId} from '../../../../utils/mlovUtils';

const CustomContactTypes = (props: {
    searchString?: string;
    openModal?: boolean;
    showTableTopBar?: boolean;
    onViewChangeCallback?: () => void;
}) => {
    const toast = useToast();
    const [openModal, setOpenModal] = useState(false);
    const [stateData, setStateData] = useState<any>([]);
    const [searchString, setSearchString] = useState('');
    const isEditEnabled = true;
    const leadContactTypeId = getContactTypeId(PERSON_TYPES.LEAD);

    const [contactTypeStateData, setContactTypeStateData] = useState<ICustomContactTypeStateData>({
        data: [],
        total: undefined,
        loading: false
    })
    const [contactTypeDataForEditOrView, setContactTypeDataForEditOrView] = useState<ICustomContactTypesTableData>()

    const getSearch = (actionCode: string, actionData: any) => {
        setSearchString(actionData.searchString);
    };

    const handleEditingContactType = (contactType: ICustomContactTypesTableData) => {
        if (isEditEnabled) {
            setOpenModal(true)
            setContactTypeDataForEditOrView(contactType)
        }
    };

    const getCustomContactTypes = async (searchString: string) => {
        setContactTypeStateData((prev) => {
            return {
                ...prev,
                loading: true
            };
        });
        try {
            const response = await getContactTypes(searchString).catch(() => {
                setContactTypeStateData((prev) => {
                    return {
                        ...prev,
                        loading: false
                    };
                });
            })
            if (response?.data) {
                const formattedData = getFormattedData(response.data, leadContactTypeId);
                setStateData(formattedData)
                setContactTypeStateData((prev) => {
                    return {
                        ...prev,
                        data: formattedData,
                        total: response?.data?.length,
                        loading: false
                    };
                });
            } else {
                setStateData([]);
                setContactTypeStateData((prev) => {
                    return {
                        ...prev,
                        loading: false
                    };
                });
            }
        }
        catch {
            showToast(toast, 'Something went wrong', ToastType.error)
        }
    };

    const getSearchResults = () => {
        setContactTypeStateData((prev) => {
            return {
                ...prev,
                loading: true
            };
        });
        if (stateData.length > 0) {
            const filteredData = stateData.filter((obj: any) => {
                return obj.name.indexOf(props.searchString) !== -1;
            });
            setContactTypeStateData((prev) => {
                return {
                    ...prev,
                    loading: true,
                    data: filteredData,
                    total: filteredData.length,
                };
            });
        }
    }

    useEffect(() => {
        if (props.searchString) {
            setSearchString(props.searchString);
            getCustomContactTypes(props.searchString);
        } else {
            setSearchString('');
            getCustomContactTypes('');
        }
    }, [props.searchString]);

    useEffect(() => {
        if (props.openModal) {
            setOpenModal(props.openModal);
        }
    }, [props.openModal]);

    return (
        <View flex={1}>
            <HStack flex={1}>
                <View flex={1} style={styles.container}>
                    {props.showTableTopBar && (
                        <TableTopBar
                            title={`Custom Contact Types`}
                            onActionPerformed={getSearch}
                            buttonList={[
                                {
                                    btnText: 'Add Custom Contact Type',
                                    colorScheme: 'primary',
                                    textColor: 'white',
                                    variant: 'PRIMARY',
                                    size: 'sm',
                                    leftIcon: <Icon name="plus" color={'white'} />,
                                    btnClick: () => setOpenModal(true),
                                },
                            ]}
                        />
                    )}
                    {openModal ?
                        <AddCustomContactTypeModal
                            isModalOpen={openModal}
                            onModalClose={() => {
                                setOpenModal(false);
                                setContactTypeDataForEditOrView(undefined)
                                if (props.onViewChangeCallback) {
                                    props.onViewChangeCallback();
                                }
                            }}
                            isEdit={contactTypeDataForEditOrView?.id ? true : false}
                            onSave={() => getCustomContactTypes('')}
                            contactTypeData={contactTypeDataForEditOrView}
                            contactTypeList={contactTypeStateData.data}
                        /> :
                        <></>
                    }
                    {contactTypeStateData.loading ? (
                        <View background={'#fff'} padding={3}>
                            <Skeleton.Text lines={6} />
                        </View>
                    ) :
                        (!contactTypeStateData.loading && contactTypeStateData?.total === 0) ? (
                            <View>
                                <View>
                                    <Center
                                        justifyContent="center"
                                        alignItems="center"
                                        alignContent="center"
                                        height={600}
                                    >
                                        <NoDataFound
                                            iconColor={Colors.Custom.Gray300}
                                            isShowIcon={true}
                                            iconSize={35}
                                            displayString="No custom contact types created"
                                        />
                                        <FoldButton
                                            nativeProps={{
                                                size: 'smBold',
                                                variant:
                                                    BUTTON_TYPE.PRIMARY,
                                                leftIcon: <PlusIcon />,
                                                onPress: () => setOpenModal(true),
                                                marginRight: 1
                                            }}
                                            key={'createCustomContactType'}
                                            customProps={{
                                                btnText: 'Create Custom Contact Type',
                                            }}
                                        />
                                    </Center>
                                </View>
                            </View>
                        )
                            :
                            (
                                <CustomContactTypesTable
                                    total={contactTypeStateData.total}
                                    data={contactTypeStateData.data}
                                    onEdit={(contactType: ICustomContactTypesTableData) => handleEditingContactType(contactType)}
                                />
                            )}
                </View>
            </HStack>
        </View>
    );
};

export default CustomContactTypes;
