import { initialData } from '../../../../../dummyData/Sales/KanBanDummyData';
import {
  IAbility,
  IALlowedCategory,
  IAllowedNoteComponent,
  PossibleValue,
} from '../../../../../Interfaces';
import { getSanitizeString } from '../../../../../utils/commonUtils';
import { getComponentKey } from '../../../../PublicPages/PublicForm/PublicFormHelper';
import {CustomComponentKey} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomComponentUtils';
import {forEachExtensiveFormComponent} from '../../../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import { IPatientNotePrefillDataItem } from '../../interfaces';
import { ORDER, REASON } from '../constants';
import {DocStatus, EncounterStatus, ISelectedAppointment} from '../interfaces';
import {IPatientNotesInitData} from '../PatientNotes';
import {hasJsonStructure} from '../PatientNotesHelper';
import { IFormListState, IFormState, IGroupField, ISingleField} from './DocumentationView';
// FOR FUTRUE REFERENCE
// export const getSubmittedResponseForNonCapability = (initData: any) => {
//   const obj: any = {};
//   initData?.formContent?.forEach((item: {key: string; data: string}) => {
//     obj[item.key] = item.data;
//   }) || {};
//   return {
//     data: obj,
//   };
// };

export const getFieldGroupsData = (
  response: any[],
  template?: IALlowedCategory
) => {
  const fields: ISingleField[] = [];
  if (!template) {
    return fields;
  }
  const allowedFieldGroup = template.allowedFieldGroup || {};
  const uniqueTitles = [...new Set(response?.map((item) => item.title))];
  uniqueTitles.forEach((title) => {
    const item: string[] = [];
    response.forEach((field) => {
      if (field.title === title) {
        if (field.title === REASON) {
          item.push(field?.data?.chiefComplaint?.displayName || '');
        } else {
          item.push(field?.data || '');
        }
      }
    });
    fields.push({
      name: title,
      fields: item,
    });
  });
  if (Object.keys(allowedFieldGroup)?.length > 0) {
    const fieldGroups: IGroupField[] = [];
    const allowedFieldGroupKeys: string[] = Object.keys(allowedFieldGroup);
    allowedFieldGroupKeys?.forEach((key) => {
      const groups = allowedFieldGroup[key];
      const groupData = fields?.filter((item) => groups.includes(item?.name));
      fieldGroups.push({
        groupName: key,
        data: groupData,
      });
    });
    return fieldGroups.filter((groups) => groups?.data?.length > 0);
  }
  return fields;
};

export const getOrders = (initialData: IPatientNotesInitData) => {
  if(initialData?.id === ''){
    return {};
  }
  const orders = initialData?.formContent?.filter((item:any) => item?.key === ORDER);
  const orderTypes = [...new Set(orders?.map((item:any) => item?.orderType))];
  const orderData: any = {};

  orderTypes?.forEach((type) => {
    const orderList = orders?.filter((item: any) => item?.orderType === type);
    orderData[type as keyof typeof orderData] = orderList;
  });
  if (initialData.additionalSections) {
    const sections = initialData.additionalSections.split('|');
    sections.forEach((section) => {
      if (!orderData[section]) {
        orderData[section] = [];
      }
    });
  }
  return orderData;
}

export const getSubmittedResponse = (
  initData: any,
  appointmentLinked?: any,
  isLinked?: boolean,
  hasChiefComplaint?: boolean,
  prefillData?: IPatientNotePrefillDataItem[]
) => {
  if (!initData?.formContent && !isLinked) {
    return;
  }
  const formAnswers = initData.formContent || [];
  const formResponse: any = {};
  let data: any = {};
  formAnswers.forEach((item: any) => {
    // Sanitizing string to avoid breaking of JSON.parse
    const valueData = typeof item.data === 'string' ? getSanitizeString(item.data) : item.data;
    data[item.key] = hasJsonStructure(valueData)
      ? JSON.parse(valueData)
      : valueData;
    // title is component type
    if (item.title === 'checkbox') {
      data[item.key] = valueData === 'true';
    }
  });
  // prefill data
  if(prefillData){
    prefillData.forEach((item) => {
      data[item.key] = item.data;
    });
  }
  //add reason for visit from appointment to chief complaint if it exist
  if (appointmentLinked && hasChiefComplaint) {
    data = {
      ...data,
      [CustomComponentKey.CHIEF_COMPLAINT]: {
        chiefComplaint: appointmentLinked?.reasonForVisit,
      },
    };
  }
  formResponse.data = data;

  return formResponse;
};

export const getFormComponents = (
  componentList: any,
  appointmentLinked: any,
  initData: any,
  isLinked: boolean,
  prefillData?: IPatientNotePrefillDataItem[]
) => {
  const reasonForVisitFromAppointment = appointmentLinked?.reasonForVisit;
  const response = getSubmittedResponse(initData)?.data || {};
  let pushNewChiefComplaint:boolean = isLinked && reasonForVisitFromAppointment?.displayName?.length > 0;
  forEachExtensiveFormComponent(componentList, (component) => {
    if (component.key && response[component.key]) {
      component.selectedValue = response[component.key];
    } else if (
      isLinked &&
      reasonForVisitFromAppointment &&
      component.type === CustomComponentKey.CHIEF_COMPLAINT
    ) {
      pushNewChiefComplaint = false;
      component.selectedValue = {
        chiefComplaint: reasonForVisitFromAppointment,
      };
    }
    // check if component key includes in prefill data
    if (prefillData) {
      const prefillItem = prefillData.find(
        (item) =>
          item.key === getComponentKey(component.key) &&
          item.label &&
          component?.label?.includes?.(item.label)
      );
      if (prefillItem) {
        component.selectedValue = prefillItem.data;
      }
    }
  });
  if (pushNewChiefComplaint) {
    componentList.unshift({
      key: CustomComponentKey.CHIEF_COMPLAINT,
      selectedValue: {
        chiefComplaint: reasonForVisitFromAppointment,
      },
      type: CustomComponentKey.CHIEF_COMPLAINT,
      label: 'Chief Complaint',
    });
  }
  // remove submit component and components depended on parent
  return componentList.filter((component: any) => {
    if ((component.action !== 'submit' && component.key !== 'submit') && !component?.parentComponentId) {
      return true;
    }
  });
};

export const appointmentDataExist = (
  appointmentLinked: ISelectedAppointment | undefined
): boolean => {
  return !!(
    appointmentLinked?.name &&
    appointmentLinked?.externalAppointmentId &&
    appointmentLinked?.reasonForVisit &&
    appointmentLinked?.startDateTime &&
    appointmentLinked?.statusId
  );
};
export const getComponentType = (
  code: string,
  allowedNoteComponents: IAllowedNoteComponent[]
) => {
  const requiredComponent = allowedNoteComponents?.filter(
    (item) => item?.code === code
  )?.[0];
  return requiredComponent?.componentType || 'textarea';
};
export const getCapabilitiesFormComponents = (
  localFormData: {
    categories?: IALlowedCategory[];
    noteComponents?: IAllowedNoteComponent[];
  },
  templateCode: string,
  initData?: {
    formContent?: any;
    formName?: string;
  },
  prefillData?: IPatientNotePrefillDataItem[]
): IFormState => {
  const {categories, noteComponents} = localFormData;
  let templateComponents: any[] = [];

  const requiredTemplate = categories?.filter(
    (item) => item?.code === templateCode
  )?.[0];

  const isReadOnlyDocument = !!requiredTemplate
    ? !!requiredTemplate?.readOnly
    : true;

  const formAnswers = requiredTemplate
    ? getSubmittedResponse(
        {formContent: initData?.formContent},
        undefined,
        false,
        false,
        prefillData
      ) || {}
    : [];
  const chiefComplaint = formAnswers?.data?.[REASON];
  if (!isReadOnlyDocument) {
    requiredTemplate?.allowedFields?.forEach((component: string) => {
      const data = noteComponents?.filter(
        (item) => item?.code === component
      )[0];
      templateComponents.push(data);
    });
    templateComponents = templateComponents.map((item) => ({
      type: getComponentType(item?.code, noteComponents || []),
      input: true,
      key: item?.code,
      label: item?.display,
      ...((item?.code === REASON && !!chiefComplaint) ? {selectedValue: chiefComplaint} : null),
    }));
  }

  return {
    categoryId: requiredTemplate?.category,
    id: requiredTemplate?.code || requiredTemplate?.display || '',
    code: requiredTemplate?.code || requiredTemplate?.display || '',
    name: requiredTemplate?.foldDisplay || initData?.formName || '',
    components: templateComponents,
    system: requiredTemplate?.system,
    answers: formAnswers,
    group: isReadOnlyDocument
      ? getFieldGroupsData(initData?.formContent, requiredTemplate)
      : [],
    isGroup:
      requiredTemplate?.allowedFieldGroup &&
      !!Object.keys(requiredTemplate?.allowedFieldGroup).length,
  };
};
export const getFormList = (
  isElation: boolean,
  data: {
    categories?: IALlowedCategory[];
    noteComponents?: PossibleValue[];
  }
) => {
  const {categories, noteComponents} = data;
  const formList: IFormListState[] = [];
  categories &&
    categories.forEach((item: IALlowedCategory) => {
      const data: IFormListState = {
        categoryId: item.category,
        categoryName: item.category,
        code: item.code || '',
        id: item.code || '',
        name: item.foldDisplay,
        isReadOnly: !!item?.readOnly,
      };
      formList.push(data);
    });

  return formList.filter(form => !form.isReadOnly);
};

export const getDocumentStatusLabel = (status?: DocStatus, isUnlockDocumentRef?: boolean) => {
  switch (status) {
    case DocStatus.PRELIMINARY:
      return 'openNote';
    case DocStatus.FINAL:
      return 'Signed';
    case DocStatus.AMENDED:
      return 'amendedAndSignedNote'
    default:
      return '';
  }
};

export const getDocumentStatusLabelInCareTimeline = (status?: DocStatus, isUnlockDocumentRef?: boolean) => {
  switch (status) {
    case DocStatus.PRELIMINARY:
      return 'In Progress';
    case DocStatus.FINAL:
      return 'Signed';
    case DocStatus.AMENDED:
      return 'Amended and Signed'
    default:
      return '';
  }
};

export const getEncounterStatus = (status: EncounterStatus) => {
  switch (status) {
    case EncounterStatus.FINAL:
      return 'finished';
    case EncounterStatus.IN_PROGGRESS:
      return 'openNote';
    default:
      return status;
  }
}
