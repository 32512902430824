import {useCallback, useState} from 'react';
import {Input, IconButton} from 'native-base';
import {Divider} from 'antd';
import {useIntl} from 'react-intl';
import {cssPropertiesAntD, styles} from '../../style';
import usePatientAdd from './usePatientAdd';
import SearchActionSvg from '../../../../Svg/PersonActionSvgIcons/SearchActionSvg';
import {Colors} from '../../../../../../styles/Colors';
import PatientSearchListing from './PatientSearchListing';
import NewCloseSvg from '../../../../Svg/NewCloseSvg';
import {debounce} from 'lodash';
import {NativeSyntheticEvent, TextInputChangeEventData} from 'react-native';

interface PatientAddSectionProps {
  onClose: () => void;
  containerHeight: number;
}

const PatientAddSection = ({
  onClose,
  containerHeight,
}: PatientAddSectionProps) => {
  const intl = useIntl();
  const {contactsData, loading, searchPatientContacts, addPatient} =
    usePatientAdd();
  const [hasSearched, setHasSearched] = useState(false);

  const handleSearch = useCallback(
    (event: NativeSyntheticEvent<TextInputChangeEventData>) => {
      const text = event?.nativeEvent?.text;
      const trimmedText = text?.trim?.();
      if (trimmedText.length > 2) {
        searchPatientContacts(text);
        setHasSearched(true);
      } else if (text?.length === 0) {
        searchPatientContacts('');
        setHasSearched(false);
      }
    },
    [searchPatientContacts]
  );

  const handleAddPatient = useCallback(
    (contactUuid: string) => {
      const date = new Date().toISOString(); // Assuming you want to add the patient with the current date
      addPatient(contactUuid, date);
    },
    [addPatient]
  );

  const onActionPerformed = useCallback((action: string, record: any) => {
    switch (action) {
      case 'add':
        handleAddPatient(record?.key);
        break;
    }
  }, []);

  return (
    <div
      style={{
        height: containerHeight ? `${containerHeight - 4.5}px` : 'auto',
        overflowY: 'hidden',
      }}
    >
      <div style={cssPropertiesAntD.p8_12_0_12}>
        <Input
          placeholder={intl.formatMessage({id: 'searchAndAddToList'})}
          InputLeftElement={
            <IconButton
              icon={
                <SearchActionSvg
                  width={16}
                  height={16}
                  customStrokeColor={Colors.FoldPixel.GRAY250}
                />
              }
              variant="unstyled"
              style={styles.iconBtnOpen}
            />
          }
          InputRightElement={
            <IconButton
              icon={
                <NewCloseSvg
                  width={16}
                  height={16}
                  customStrokeColor={Colors.FoldPixel.GRAY250}
                />
              }
              onPress={onClose}
              variant="unstyled"
              _icon={{
                padding: 0,
                margin: 0,
              }}
              style={styles.iconBtnClose}
              _hover={{
                backgroundColor: Colors.Custom.HoverBgColor,
              }}
            />
          }
          _hover={{
            backgroundColor: Colors.Custom.White,
          }}
          onChange={debounce(handleSearch, 500)}
          borderRadius={4}
          borderWidth={0.5}
          borderColor={Colors.FoldPixel.GRAY250}
          backgroundColor={Colors.Custom.White}
          padding={'8px'}
        />
      </div>
      <Divider style={cssPropertiesAntD.divider1} />
      <div
        style={{
          height: containerHeight ? `${containerHeight - 55}px` : 'auto',
          overflowY: 'auto',
          ...cssPropertiesAntD.scrollableDiv,
        }}
      >
        <PatientSearchListing
          hasSearched={hasSearched}
          loading={loading}
          dataSource={contactsData}
          onActionPerformed={onActionPerformed}
        />
      </div>
    </div>
  );
};

export default PatientAddSection;
