import {Modal} from "antd"
import {View,Text,Image} from "react-native"
import {FoldButton} from "../../CommonComponents/FoldButton/FoldButton"
import {Colors} from "../../../styles"
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst"
import './style.css'
import {useIntl} from "react-intl"
import {StyleSheet} from "react-native"

export const ExtensionDeleteConfirmationModal = (props: {
  onActionPerformed: (actionCode: string, actionData?: any) => void,
}) => {
  const intl = useIntl();
  return (
    <Modal
      className="delete-extension"
      title={
        <View style={styles.titleContainer}>
          <Image
            resizeMode="contain"
            style={styles.titleImage}
            source={require('../../../assets/images/trash_red.png')}
          />
          <Text style={styles.titleText}>
            {intl.formatMessage({id: 'deleteExtensionTitle'})}
          </Text>
        </View>
      }
      width={310}
      visible={true}
      closable={false}
      footer={
        <View style={styles.footerContainer}>
          <FoldButton
              customProps={{
                btnText: 'Cancel',
              }}
              nativeProps={{
                onPress() {
                  props?.onActionPerformed(COMMON_ACTION_CODES.CANCEL)
                },
                backgroundColor: 'transparent',
                borderWidth: 0.5,
                borderColor: Colors.FoldPixel.GRAY200,
                borderRadius: 4,
                _text: {
                  fontSize: 14,
                  lineHeight: 16.8,
                  color: Colors.FoldPixel.GRAY300
                },
                paddingX: 10,
              }}
            />
            <FoldButton
              customProps={{
                btnText: 'Confirm',
              }}
              nativeProps={{
                onPress() {
                  props?.onActionPerformed(COMMON_ACTION_CODES.CONFIRM)
                },
                backgroundColor: Colors.Custom.Red100,
                borderWidth: 0.5,
                borderColor: Colors.FoldPixel.STATUS_ERROR,
                borderRadius: 4,
                _text: {
                  fontSize: 14,
                  lineHeight: 16.8,
                  color: Colors.FoldPixel.STATUS_ERROR
                },
                paddingX: 10,
                marginLeft: 2
              }}
            />
        </View>
      }
    >
      <View style={styles.contentContainer}>
        <Text style={styles.contentText}>
          {intl.formatMessage({id: 'deleteExtensionMessage'})}
        </Text>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  titleContainer: {
    alignItems: 'center',
  },
  titleImage: {
    width: 18,
    height: 18,
  },
  titleText: {
    color: Colors.FoldPixel.GRAY400,
    fontSize: 16,
    lineHeight: 19.2,
    fontWeight: '500',
    marginTop: 8,
    textAlign: 'center',
  },
  footerContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 12,
  },
  contentContainer: {
    marginTop: 2,
  },
  contentText: {
    color: Colors.FoldPixel.GRAY200,
    fontSize: 14,
    lineHeight: 16.8,
    fontWeight: '400',
    textAlign: 'center'
  },
});