import {
  ICampaignTagsProps,
  IStatusKindProps,
} from '../../components/RightSideContainer/Marketing/Campaign/interfaces';

export const dummyStatusData: IStatusKindProps[] = [
  {
    label: 'All',
    count: 5,
  },
  {
    label: 'Auto',
    count: 0,
  },
  {
    label: 'Visitor Auto',
    count: 0,
  },
  {
    label: 'Manual',
    count: 5,
  },
];

export const dummyKindData: IStatusKindProps[] = [
  {
    label: 'Live',
    count: 5,
  },
  {
    label: 'Draft',
    count: 0,
  },
  {
    label: 'Paused',
    count: 0,
  },
  {
    label: 'Your Messages',
    count: 5,
  },
];

export const dummyTagsData: ICampaignTagsProps[] = [
  {
    tagName: 'Angry',
    tagColor: 'red',
    tagCount: 0,
  },
  {
    tagName: 'Disappointed',
    tagColor: 'blue',
    tagCount: 0,
  },
  {
    tagName: 'Email Sent',
    tagColor: 'cyan',
    tagCount: 2,
  },
  {
    tagName: 'Happy',
    tagColor: 'geekblue',
    tagCount: 1,
  },
  {
    tagName: 'Influencer',
    tagColor: 'gold',
    tagCount: 0,
  },
  {
    tagName: 'Lose',
    tagColor: 'error',
    tagCount: 1,
  },
  {
    tagName: 'Meet personally',
    tagColor: 'green',
    tagCount: 0,
  },
];
