import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import { AppointmentAvailabilityCode } from '../../../../RightSideContainer/AccountSettings/AppointmentTypes/constants';

export interface ScheduleSuggestionResponse {
  contactIds: string[];
  rules: Rule[];
}

export interface Rule {
  id: string;
  name: string;
}

export interface IScheduleRuleMemberListProps {
  isOpen: boolean;
  onClose: () => void;
  contactIdList: string[];
  refetchAppointments: () => void;
  rulesIds: string[];
}

export interface ContactListItem {
  uuid: string;
  name: string;
  email: any;
  isActive: boolean;
  id: number;
  phoneNumber: any;
  person: {
    sexAtBirth: SexAtBirth;
    chosenName: string;
    pronounId: any;
    firstName: string;
    lastName: string;
    birthDate: string;
    bloodGroup: any;
    gender: any;
  };
  patient: {
    patientId: string;
    patientUuid: string;
  };
  additionalAttributes: {
    createdUserUuid: string;
    appInvitationLinkStatus: string;
    loggingTimeBeforeAppInvitation: any;
  };
}

export interface SexAtBirth {
  code: string;
  value: string;
}

export enum BulkSelectActionType {
  All = 'All',
  None = 'None'
}

export interface IScheduleRuleMemberListComponentState {
  selectedRowKey: any[];
  selectedItems: any[];
  contactIdsList: string[];
  contactList: any[];
  loading: boolean;
  showConfirmation: boolean;
  selectAllLoading: boolean;
  showSendAppointmentToBulkDrawer: boolean
  showAppointmentBookingDrawer: boolean;
  selectedUser?: {
    userUUID: string;
    userName: string;
    contactData: any
  };
  ruleIds: string[];
  appointmentData:IAppoinmentDataConfig;
  sendLinkLoading: boolean;
  sendNotification: boolean;
}

export interface IAppoinmentDataConfig {
  templateId: string;
  appointmentTypeId: string;
  appointmentUsers: string;
  availabilityTypeCode: AppointmentAvailabilityCode;
}

export enum ScheduleRuleMemberListActionTypes {
  BOOK_APPOINMENT = 'BOOK_APPOINMENT'
}

export interface ISendAppointmentLinkToPopulationGroup {
  ruleIds: string[];
  allowToSendNotification: boolean;
  contactIds?: string[];
  appointmentType: {
    appointmentTypeId: string;
    userIds?: string[];
    isCareTeamBased?: boolean;
  };
  templateId:string
}

export enum FeatureKey {
  ScheduleSuggestion = 'ScheduleSuggestion',
  isArchiveConversationOnMessageSend= 'isArchiveConversationOnMessageSend',
  isNewLineOnEnter= 'isNewLineOnEnter',
}