import {Drawer, Table} from 'antd';
import {Center, Pressable, Spinner, Text, View, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../../styles';
import {useLazyQuery} from '@apollo/client';
import CostTemplateQueries from '../../../../../services/CostSavingTemplates/CostTemplateQueries';
import {
  getMlovIdFromCode,
  getMlovListByCategory,
} from '../../../../../utils/mlovUtils';
import {BUTTON_TYPE, MLOV_CATEGORY} from '../../../../../constants';
import {COST_TYPE_CODE} from '../CostTemplates/helperFiles/CostTemplatesConst';
import {ICostSavingTemplate, ICostTemplatesResponse, ISavingTemplateMappedEmployerBody} from './interface';
import {ModalActionSelect} from '../../../../common/ModalActionCommonComponent/ModalActionSelect';
import {TableWrapper} from '../../../../common/TableWrapper';
import {costSavingColumns} from './CostSavingColumns';
import NoTemplateView from '../CostTemplates/helperFiles/NoTemplateView';
import {
  ICostSavingTemplateCpt,
  ICostSavingTemplateCptsDataResponse,
} from '../CostTemplates/interfaces/savingTemplatesInterface';
import {addOrUpdateSavingTemplateAndEmployer, getMappedTemplateAndEmployer} from '../../../../../services/CostSavingTemplates/CostSavingService';
import {costSavingKeyNameByResourceCode} from '../EmployerUtils/EmployerConst';
import {useIntl} from 'react-intl';
import Feather from 'react-native-vector-icons/Feather';
const CostSavingDrawer = (props: any) => {
  const {selectedResourceData, employerId} = props;
  const {resourceCode, resourceId, widgetName} = selectedResourceData;
  const {height, width} = Dimensions.get('window');
  const finalWidth = width * 0.5;
  const finalHeight = height - 375;
  const intl = useIntl()
  const accountUuid = getAccountUUID();
  const [visible, setVisible] = useState(true);
  const [stateData, setStateData] = useState({
    tableData: [] as any[],
    isLoading: false,
    templates: {
      marketCostTemplates: [] as ICostSavingTemplate[],
      ourCostTemplates: [] as ICostSavingTemplate[],
      loading: false,
    },
    selectedTemplateData: {
      selectedMarketCostTemplate: '',
      selectedOurCostTemplate: '',
      templateEmpMapId: ''
    },
    pagination: {
      pageSize: 10,
      pageNo: 1,
      totalAgr: 0,
      offset: 0,
      loading: false,
    },
  });
  const [COST_TEMPLATE_ID_NAME] = useLazyQuery(
    CostTemplateQueries.COST_TEMPLATE_ID_NAME,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [CPT_LIST_BY_CODES_IN] = useLazyQuery(
    CostTemplateQueries.CPT_LIST_BY_CODES_IN,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const costTypeList = getMlovListByCategory(MLOV_CATEGORY.COST_CATEGORY);
  const marketCostTypeId = getMlovIdFromCode(
    costTypeList,
    COST_TYPE_CODE.MARKET_COST
  );
  const ourCostTypeId = getMlovIdFromCode(
    costTypeList,
    COST_TYPE_CODE.OUR_COST
  );
  const onClose = () => {
    setVisible(false);
    props.onCancel?.();
  };
  const onSave = async () => {
    const employerMappingObject: ISavingTemplateMappedEmployerBody = {
      analyticalResourceId: resourceId,
      employerId: employerId
    }
    if (stateData.selectedTemplateData?.selectedOurCostTemplate) {
      employerMappingObject.ourCostTemplateId = stateData.selectedTemplateData?.selectedOurCostTemplate
    }
    if (stateData.selectedTemplateData?.selectedMarketCostTemplate) {
      employerMappingObject.marketCostTemplateId = stateData.selectedTemplateData?.selectedMarketCostTemplate
    }
    if (stateData.selectedTemplateData?.templateEmpMapId) {
      employerMappingObject.id = stateData.selectedTemplateData?.templateEmpMapId
    }
    const body = {
      employerTemplateMapping: [
        {...employerMappingObject}
      ],
    };
    const saveMappingResp = await addOrUpdateSavingTemplateAndEmployer(body).catch(() => {
      setStateData(prev => {
        return {
          ...prev,
          isLoading: false
        }
      })
    })
    if (saveMappingResp?.data?.data?.employerTemplateMapping?.length) {
      setVisible(false);
      props.onSendCallBackData?.(resourceCode);
      props.onCancel?.();
    }
    setStateData(prev => {
      return {
        ...prev,
        isLoading: false
      }
    })
  }
  const getCostTemplates = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        templates: {
          ...prev.templates,
          loading: true,
        },
        isLoading: true,
      };
    });
    let marketCostTemplateId = ''
    let ourCostTemplateId = ''
    const templateMappedRes = await getMappedTemplateAndEmployer(resourceId, employerId)
    if (templateMappedRes?.data?.length || templateMappedRes?.data?.id) {
      const templateMapped = templateMappedRes?.data;
      marketCostTemplateId = templateMapped?.marketCostTemplateId
      ourCostTemplateId = templateMapped?.ourCostTemplateId;
      setStateData(prev=> {
        return {
          ...prev,
          selectedTemplateData: {
            ...prev.selectedTemplateData,
            templateEmpMapId: templateMapped?.id
          }
        }
      })
    }
    const marketCostTemplates: ICostTemplatesResponse =
      await COST_TEMPLATE_ID_NAME({
        variables: {
          accountUuid: accountUuid,
          analyticalResourceId: resourceId,
          costCategoryId: marketCostTypeId,
        },
      }).catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            templates: {
              ...prev.templates,
              loading: false,
            },
            isLoading: false,
          };
        });
        return {} as ICostTemplatesResponse;
      });
    const ourCostTemplates: ICostTemplatesResponse =
      await COST_TEMPLATE_ID_NAME({
        variables: {
          accountUuid: accountUuid,
          analyticalResourceId: resourceId,
          costCategoryId: ourCostTypeId,
        },
      }).catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            templates: {
              ...prev.templates,
              loading: false,
            },
            isLoading: false,
          };
        });
        return {} as ICostTemplatesResponse;
      });
    if (marketCostTemplates?.data?.costSavingTemplates?.length) {
      let selectedMarketCostTemplateId: string = marketCostTemplateId;
      if (!marketCostTemplateId) {
        const defaultMarketCostTemplate =
          marketCostTemplates?.data?.costSavingTemplates?.find((item) => {
            return item.isDefault;
          });
        selectedMarketCostTemplateId = defaultMarketCostTemplate?.id || '';
      }
      setStateData((prev) => {
        return {
          ...prev,
          templates: {
            ...prev.templates,
            marketCostTemplates: marketCostTemplates?.data?.costSavingTemplates,
          },
          selectedTemplateData: {
            ...prev.selectedTemplateData,
            selectedMarketCostTemplate: selectedMarketCostTemplateId,
          },
        };
      });
    }
    if (ourCostTemplates?.data?.costSavingTemplates?.length) {
      let selectedOurCostTemplateId: string = ourCostTemplateId;
      if (!ourCostTemplateId) {
        const defaultMarketCostTemplate =
          ourCostTemplates?.data?.costSavingTemplates?.find((item) => {
            return item.isDefault;
          });
        selectedOurCostTemplateId = defaultMarketCostTemplate?.id || '';
      }
      setStateData((prev) => {
        return {
          ...prev,
          templates: {
            ...prev.templates,
            ourCostTemplates: ourCostTemplates?.data?.costSavingTemplates,
          },
          selectedTemplateData: {
            ...prev.selectedTemplateData,
            selectedOurCostTemplate: selectedOurCostTemplateId,
          },
        };
      });
    }
    setStateData((prev) => {
      return {
        ...prev,
        templates: {
          ...prev.templates,
          loading: true,
        },
        isLoading: false,
      };
    });
  };
  const getCostSavingData = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          loading: true,
        },
      };
    });
    const keyName = costSavingKeyNameByResourceCode(resourceCode)
    const CPT_LIST_FROM_DATA = selectedResourceData?.data?.currentDateResp[keyName]?.length ?
      selectedResourceData?.data?.currentDateResp[keyName] : []
    const responseData = [...CPT_LIST_FROM_DATA];
    const cptsCodeList: string[] = []
    responseData.forEach((cpt) => {
      if (cpt?.code) {
        cptsCodeList.push(cpt.code)
      }
    });
    const cptListBody = {
      codes: [...cptsCodeList],
      accountUuid: accountUuid,
    };
    let marketCostCptByCode: ICostSavingTemplateCptsDataResponse;
    let ourCostCptByCode: ICostSavingTemplateCptsDataResponse;
    if (cptListBody?.codes?.length) {
      marketCostCptByCode =
      await CPT_LIST_BY_CODES_IN({
        variables: {
          ...cptListBody,
          templateId:
            stateData.selectedTemplateData?.selectedMarketCostTemplate,
        },
      }).catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            pagination: {
              ...prev.pagination,
              loading: true,
            },
          };
        });
        return {} as ICostSavingTemplateCptsDataResponse;
      });
      ourCostCptByCode =
      await CPT_LIST_BY_CODES_IN({
        variables: {
          ...cptListBody,
          templateId: stateData.selectedTemplateData?.selectedOurCostTemplate,
        },
      }).catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            pagination: {
              ...prev.pagination,
              loading: true,
            },
          };
        });
        return {} as ICostSavingTemplateCptsDataResponse;
      });
    }
    const formattedSavingData = responseData?.map((responseObj) => {
      let marketCostObj = {} as ICostSavingTemplateCpt;
      let ourCostObj = {} as ICostSavingTemplateCpt;
      if (marketCostCptByCode?.data?.costSavingTemplateCpts?.length) {
        marketCostObj =
          marketCostCptByCode?.data?.costSavingTemplateCpts?.find(
            (savingCpts) => {
              return savingCpts?.accountCpt?.code === responseObj?.code;
            }
          ) || ({} as ICostSavingTemplateCpt);
      }
      if (ourCostCptByCode?.data?.costSavingTemplateCpts?.length) {
        ourCostObj =
          ourCostCptByCode?.data?.costSavingTemplateCpts?.find((savingCpts) => {
            return savingCpts?.accountCpt?.code === responseObj?.code;
          }) || ({} as ICostSavingTemplateCpt);
      }
      return {
        name: responseObj?.displayText || '',
        qty: responseObj?.countNum,
        ourCost: ourCostObj?.price
        ? ourCostObj?.price
        : ourCostObj?.price == 0
        ? 0
        : 'NA',
        marketCost: marketCostObj?.price
          ? marketCostObj?.price
          : marketCostObj?.price == 0
          ? 0
          : 'NA',
        cpt: responseObj?.code || '',
        modifier: responseObj?.modifier || '',
      };
    });
    setStateData((prev) => {
      return {
        ...prev,
        tableData: formattedSavingData || [],
        pagination: {
          ...prev.pagination,
          loading: false,
        },
      };
    });
  };

  useEffect(() => {
    getCostTemplates();
  }, []);
  useEffect(() => {
    getCostSavingData();
  }, [
    stateData.selectedTemplateData?.selectedMarketCostTemplate,
    stateData.selectedTemplateData?.selectedOurCostTemplate
  ]);
  return (
    <>
      <Drawer
        destroyOnClose
        onClose={() => {
          onClose();
        }}
        visible={visible}
        width={finalWidth}
        title={
          <ModalActionTitle
            text={widgetName}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 2,
                btnText: 'save',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                isDisabled: stateData.isLoading ? true : false,
                onClick: () => {
                  onSave();
                },
              },
            ]}
            rightButton={
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <View
                  style={{
                    width: 1.5,
                    backgroundColor: Colors.Custom.Gray200,
                    marginHorizontal: 8,
                    height: 20,
                  }}
                />
                <Pressable
                  onPress={() => {
                    onClose();
                  }}
                >
                  <AntIcon
                    name="close"
                    size={20}
                    color={Colors.Custom.Gray500}
                  />
                </Pressable>
              </View>
            }
          />
        }
      >
        {stateData.isLoading && (
          <View
            height={'full'}
            width={'full'}
            position="absolute"
            zIndex={10}
            bg={'coolGray.50:alpha.70'}
          >
            <Center
              top={'30%'}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Spinner size={'lg'} />
            </Center>
          </View>
        )}
        <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 12}}>
          <Feather
            name="info"
            size={20}
            color={Colors.Custom.Gray500}
          />
          <Text style={{fontSize: 12, marginLeft: 8}} color={Colors.Custom.Gray500}>
            {intl.formatMessage({id: 'costTemplateOurCostInfo'})}
          </Text>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <ModalActionSelect
            label="Market Cost Templates"
            data={[...stateData.templates?.marketCostTemplates]}
            selectedValue={
              stateData?.selectedTemplateData?.selectedMarketCostTemplate
            }
            onValueChange={(e) => {
              setStateData((prev) => {
                return {
                  ...prev,
                  selectedTemplateData: {
                    ...prev.selectedTemplateData,
                    selectedMarketCostTemplate: e,
                  },
                };
              });
            }}
            selectItemProps={{
              key: 'id',
              value: 'id',
              label: 'name',
            }}
          />
          <ModalActionSelect
            label="Our Cost Templates"
            data={[...stateData.templates?.ourCostTemplates]}
            selectedValue={
              stateData?.selectedTemplateData?.selectedOurCostTemplate
            }
            onValueChange={(e) => {
              setStateData((prev) => {
                return {
                  ...prev,
                  selectedTemplateData: {
                    ...prev.selectedTemplateData,
                    selectedOurCostTemplate: e,
                  },
                };
              });
            }}
            selectItemProps={{
              key: 'id',
              value: 'id',
              label: 'name',
            }}
          />
        </View>
        <View>
            <Table
              scroll={{x: width * 0.3, y: finalHeight}}
              columns={costSavingColumns(resourceCode)}
              rowKey={(row: any) => row?.id}
              loading={{
                spinning: stateData.pagination.loading,
                indicator: (
                  <VStack justifyContent="center" height={'100%'}>
                    <Spinner size={'lg'} />
                  </VStack>
                ),
              }}
              dataSource={stateData?.tableData}
              rowClassName={(record, index) =>
                index % 2 == 0 ? 'table-row-light' : ''
              }
              locale={{
                emptyText: !stateData.pagination.loading && <NoTemplateView />,
              }}
            />
        </View>
      </Drawer>
    </>
  );
};

export default CostSavingDrawer;
