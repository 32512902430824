import { Dropdown, Menu } from 'antd';
import { Button, Icon, useMediaQuery, View } from 'native-base';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { BUTTON_TYPE, SELECTED_VIEW_TYPE, SMALL_WINDOW_1400 } from '../../../constants';
import { getEnvVariable } from '../../../constants/BaseUrlConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { Colors } from '../../../styles';
import { FoldButton } from '../../CommonComponents/FoldButton/FoldButton';
import CustomTabsView from '../../common/CustomTabsView/CustomTabsView';
import { ITabsList } from '../../common/CustomTabsView/interfaces';
import PlusIcon from '../../common/Svg/PlusSvg';
import { TABLE_TOP_BAR_ACTION_CODES, TableTopBar } from '../../common/TableTopBar';
import CommunicationWhiteListView from '../CommunicationWhiteList/CommunicationWhiteListView';
import { TeamMembersView } from '../Contacts/TeamMembers';
import { getPathFromHash } from '../ContentManagement/ContentManagementUtils';
import HolidaysList from '../Holidays/HolidaysList';
import { MergedOrDelayed } from '../MergedOrDelayed/MergedOrDelayed';
import AppTabPermissions from '../UserAccess/AppTabPermissions';
import { USER_ACCESS_PERMISSION } from '../UserAccess/UserAccessPermission';
import { styles } from './AccountTabsStyle';
import AccountLocations from '../Contacts/TeamMembers/AccountLocations/AccountLocations';
import Feather from 'react-native-vector-icons/Feather';
import Setting from '../../common/Svg/Setting';
import {TEAM_MEMBERS_VIEW_CODE} from '../Contacts/TeamMembers/AddEditUser/TeamMemberConst';
import {getAccountUUID, isChildAccount, isMasterAccount} from '../../../utils/commonUtils';
import {getTeamCount} from '../Contacts/TeamMembers/InviteUserDrawer/InviteUserDrawerUtils';
import {useLazyQuery} from '@apollo/client';
import TeamQueries from '../../../services/Team/TeamQueries';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';

const AccountTabsView = () => {
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const subdomainMap: any = commonData.accountSubdomain || {};
  const subDomain = subdomainMap?.subDomain || '';
  const accountUuid = getAccountUUID()
  const screenDimensions = Dimensions.get('window');
  const isMasterAccountFlag = isMasterAccount();
  const isChildAccountFlag = isChildAccount();
  const [isSmallScreen] = useMediaQuery([
    { maxWidth: SMALL_WINDOW_1400 },
  ]);
  const [LOCATION_TOTAL_COUNT] = useLazyQuery(TeamQueries.getLocationTotalCount,{
    fetchPolicy: 'no-cache'
  })
  const [stateData, setStateData] = useState({
    accessControlKey: 'dashboard',
    inviteUserDrawer: '',
    selectedActionCode: '',
    selectedRawData: '' as any,
    selectedExtraData: '',
    searchString: '',
    viewType: '',
  });
  const [tabsCountData, setTabsCountData] = useState<{
    teamCount: number;
    locationCount: number;
  }>({
    teamCount: 0,
    locationCount: 0,
  });
  const [communicationWhiteListViewState, setCommunicationWhiteListViewState] = useState({
    isOpen: false,
    value: '',
    id: '',
    type: '',
    isError: false
  })
  const tagsTabList = (): ITabsList[] => {
    const array: ITabsList[] = [
      ...((!isChildAccountFlag) ? [
        {
          key: 'teamMembers',
          title: 'Team',
          path: 'teamMembers',
          count: tabsCountData.teamCount,
          tabContainerElem: () => {
            return (
              <TeamMembersView
                stateData={stateData}
                onClose={onTableTopBarActionPerformed}
                inviteUser={stateData.inviteUserDrawer}
                updateContactCount={(teamCount) => {
                  setTabsCountData((prev) => {
                    return {
                      ...prev,
                      teamCount: teamCount,
                    }
                  })
                }}
                resetCheck={() => {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      inviteUserDrawer: '',
                    };
                  });
                }}
              />
            );
          },
        },
        {
          key: 'accessControl',
          title: 'Access Control',
          path: 'accessControl',
          tabContainerElem: () => {
            return (
              <AppTabPermissions
                key={stateData.accessControlKey}
                resetCheck={() => {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      viewType: '',
                    };
                  });
                }}
                viewType={stateData.viewType}
                parentTabCode={
                  stateData.accessControlKey == 'settings'
                    ? USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code
                    : USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code
                }
              ></AppTabPermissions>
            );
          },
        },
      ] : []),
      ...((isChildAccountFlag || !isMasterAccountFlag) ?[{
        key: 'locations',
        title: 'Locations',
        path: 'locations',
        count: tabsCountData.locationCount,
        tabContainerElem: () => {
          return (
          <AccountLocations
            searchString={stateData?.selectedRawData?.searchString}
            selectedActionCode={stateData.selectedActionCode}
            onClose={onTableTopBarActionPerformed}
            updateContactCount={(locationCount: number) => {
              setTabsCountData((prev) => {
                return {
                  ...prev,
                  locationCount: locationCount,
                }
              })
            }}
          />
          );
        },
      },
      // {
      //   key: 'audit',
      //   title: 'Access Logs',
      //   path: 'logs',
      //   tabContainerElem: () => {
      //     return <AuditLogs />;
      //   },
      // },
      {
        key: 'holidays',
        title: 'Holiday Configuration',
        path: 'holidays',
        tabContainerElem: () => {
          return (
            <HolidaysList
              searchString={stateData?.selectedRawData?.searchString}
              selectedActionCode={stateData.selectedActionCode}
              onClose={onTableTopBarActionPerformed}
              showTableTopBar={false}
            />
          );
        },
      }]: []),
    ];
    if (isChildAccountFlag || !isMasterAccountFlag){
    if((getEnvVariable() || subDomain.indexOf('preview') != -1) && ['demo.sandbox.foldhealth.io', 'fold.sandbox.foldhealth.io'].indexOf(subDomain) == -1){
      array.push(
        {
          key: 'mergedOrDelayed',
          title: 'Merged Or Delayed',
          path: 'mergedOrDelayed',
          tabContainerElem: () => {
            return (
             <MergedOrDelayed />
            );
          },
        }
      )

      array.push({
        key: 'phoneWhiteList',
        title: 'Allowed Phone',
        path: 'phoneWhiteList',
        tabContainerElem: () => {
          return (
            <CommunicationWhiteListView
            searchString={stateData?.selectedRawData?.searchString}
            addOrUpdateState = {communicationWhiteListViewState}
            setAddOrUpdateState = {setCommunicationWhiteListViewState}
            key={'SMS'}
             mode='SMS'
            />
          );
        },
      })
      array.push({
        key: 'emailWhiteList',
        title: 'Allowed Email',
        path: 'emailWhiteList',
        tabContainerElem: () => {
          return (
            <CommunicationWhiteListView
            searchString={stateData?.selectedRawData?.searchString}
            addOrUpdateState = {communicationWhiteListViewState}
            setAddOrUpdateState = {setCommunicationWhiteListViewState}
            key={'EMAIL'}
             mode='EMAIL'
            />
          );
        },
      })
    }
  }
    return array;
  };

  const communicationListTopBar = (
    <TableTopBar
      title=""
      selectedTab={selectedTab?.key}
      isDividerNotVisible={true}
      alignSearchBarFlexEnd={true}
      onActionPerformed={(actionCode: string, actionData: any) => {
        onTableTopBarActionPerformed(actionCode, actionData);
      }}
      buttonList={[
        // {
        //   btnText: 'Add New Location',
        //   size: 'sm',
        //   colorScheme: 'primary',

        //   backgroundColor: Colors.secondary['100'],
        //   textColor: Colors.secondary['800'],
        //   variant: '',
        //   leftIcon: (
        //     <Icon
        //       as={AntIcon}
        //       name={'plus'}
        //       size="4"
        //       color={Colors.secondary['800']}
        //     />
        //   ),
        //   btnClick: () => {
        //     onTableTopBarActionPerformed(TABLE_TOP_BAR_ACTION_CODES.NEW, {});
        //   },
        // },
        {
          btnText: 'Add New',
          leftIcon: (
            <PlusIcon />
          ),
          btnClick() {
            setCommunicationWhiteListViewState({  value: '', id: '', type: 'add', isOpen: true, isError: false });
          },
          size: 'md',
          colorScheme: 'primary',
          id: 2,
          backgroundColor: Colors.secondary['100'],
          textColor: Colors.secondary['800'],
          variant: 'PRIMARY',
        },
      ]}
      // hideSearchBar={true}
    />
  );


  const teamMembersTopBar = (
    <TableTopBar
      title=""
      selectedTab={selectedTab?.key}
      searchBarWidth={200}
      isDividerNotVisible={true}
      alignSearchBarFlexEnd={true}
      onActionPerformed={(actionCode: string, actionData: any) => {
        onTableTopBarActionPerformed(actionCode, actionData);
      }}
      buttonList={[
        // {
        //   btnText: 'Add New Location',
        //   size: 'sm',
        //   colorScheme: 'primary',

        //   backgroundColor: Colors.secondary['100'],
        //   textColor: Colors.secondary['800'],
        //   variant: '',
        //   leftIcon: (
        //     <Icon
        //       as={AntIcon}
        //       name={'plus'}
        //       size="4"
        //       color={Colors.secondary['800']}
        //     />
        //   ),
        //   btnClick: () => {
        //     onTableTopBarActionPerformed(TABLE_TOP_BAR_ACTION_CODES.NEW, {});
        //   },
        // },
        {
          btnText: 'inviteUser',
          leftIcon: (
            <PlusIcon />
          ),
          btnClick() {
            setStateData((prev) => {
              return {
                ...prev,
                inviteUserDrawer: 'INVITE_USER_DRAWER',
              };
            });
          },
          size: 'md',
          colorScheme: 'primary',
          id: 2,
          backgroundColor: Colors.secondary['100'],
          textColor: Colors.secondary['800'],
          variant: 'PRIMARY',
        },
        {
          btnText: '',
          id: 3,
          size: 'sm',
          colorScheme: '',
          textColor: '',
          variant: '',
          backgroundColor: 'transparent',
          borderColor: 'white',
          rightIcon: (
            <Setting />
          ),
          style: {
            borderWidth: 0,
            minWidth: 'auto',
            paddingLeft: 6,
            paddingRight: 0
          },
          btnClick() {
           onTableTopBarActionPerformed(TEAM_MEMBERS_VIEW_CODE.EDIT_ACCOUNT,{})
          },
        },
      ]}
      // hideSearchBar={true}
    />
  );
  const addNewMenu = () => {
    return (
      <Menu
        items={[
          {
            key: 'dashboard',
            label: intl.formatMessage({id: 'dashboard'}),
          },
          {
            key: 'settings',
            label: intl.formatMessage({id: 'settings'}),
          },
        ]}
        onClick={(data: any) => {
          setStateData((prev) => {
            return {
              ...prev,
              accessControlKey: data.key,
            };
          });
        }}
      />
    );
  };
  const accessControlTopBarButton = (
    <TableTopBar
      title=""
      selectedTab={selectedTab?.key}
      isDividerNotVisible={true}
      alignSearchBarFlexEnd={true}
      hideSearchBar={true}
      buttonList={[
        {
          btnText: 'addRole',
          leftIcon: (
            <PlusIcon />
          ),
          btnClick() {
            setStateData((prev) => {
              return {
                ...prev,
                viewType: SELECTED_VIEW_TYPE.ADD_ROLE_DRAWER
              };
            });
          },
          size: 'md',
          colorScheme: 'primary',
          id: 2,
          backgroundColor: Colors.secondary['100'],
          textColor: Colors.secondary['800'],
          variant: 'PRIMARY',
        },
        {
          btnText: intl.formatMessage({id: stateData.accessControlKey}),
          rightIcon:   <Icon
          color={Colors.Custom.mainPrimaryPurple}
          as={AntIcon}
          name={'down'}
          size="4"
        />,
          btnClick() {
          },
          size: 'md',
          colorScheme: 'primary',
          id: 2,
          backgroundColor: Colors.secondary['100'],
          textColor: Colors.secondary['800'],
          borderColor: Colors.secondary['200'],
          variant: 'PRIMARY',
          content: addNewMenu(),
          withRightBorder:true,
          style: {minWidth: 'auto', marginLeft: 0}
        },
      ]}
      // hideSearchBar={true}
    />
  );
  const accessControlTopBarBautton = (
    <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
      <Button.Group isAttached>
        <Dropdown
          overlay={addNewMenu}
          placement="bottomLeft"
          overlayStyle={{width: 120}}
        >
          <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: ()=> {
            },
            rightIcon:    <Icon
            color={Colors.Custom.mainPrimaryPurple}
            as={AntIcon}
            name={'down'}
            size="4"
          />,
          style: {minWidth: 'auto'}
          }}
          customProps={{
            btnText: intl.formatMessage({id: stateData.accessControlKey}),
            withRightBorder: true
          }}
          />
          {/* <Button
            style={{
              backgroundColor: Colors.secondary['100'],
              height: 36,
              borderColor: Colors.secondary['200'],
              borderWidth: 1,
              borderLeftWidth: 1,
              width: 120,
              borderBottomLeftRadius: 16,
              borderTopStartRadius: 16,
              borderBottomRightRadius: 16,
              borderTopRightRadius: 16,
            }}
            onPress={() =>{}}
            alignSelf="center"
            variant="transparent"
            minH={8}
            rightIcon={
              <Icon
                color={Colors.secondary['800']}
                as={AntIcon}
                name={'down'}
                size="4"
              />
            }
          >
            <Text size="smBold" color={Colors.secondary['800']}>
              {capitalizeText(stateData.accessControlKey)}
            </Text>
          </Button> */}
        </Dropdown>
      </Button.Group>
    </View>
  );

  const getSelectedTopBar = (selectedTab: ITabsList) => {
    let ele: any = false;
    if(selectedTab?.key == 'emailWhiteList' || selectedTab?.key == 'phoneWhiteList'){
      return communicationListTopBar;
    }else if (selectedTab?.key === 'teamMembers') {
      ele = teamMembersTopBar;
    } else if (selectedTab?.key === 'accessControl') {
      ele = accessControlTopBarButton;
    } else if (
      selectedTab?.key === 'holidays' ||
      selectedTab?.key === 'locations'
      ) {
      ele = (
        <TableTopBar
          title={''}
          alignSearchBarFlexEnd={true}
          selectedTab={selectedTab?.key}
          isDividerNotVisible={true}
          hideSearchBar={
            screenDimensions?.width < 1700
          }
          onActionPerformed={(actionCode: string, actionData: any) => {
            onTableTopBarActionPerformed(actionCode, actionData);
          }}
          buttonList={[
            {
              btnText: selectedTab.key === 'locations' ? 'newLocation' : 'new',
              backgroundColor: Colors.secondary['100'],
              textColor: Colors.secondary['800'],
              borderColor: Colors.secondary['200'],
              variant: 'PRIMARY',
              size: 'md',
              colorScheme: 'primary',
              leftIcon: <PlusIcon />,
              btnClick: () => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    selectedActionCode: TABLE_TOP_BAR_ACTION_CODES.NEW,
                  };
                });
              },
            },
          ]}
        />
      );
    } else {
      ele = <></>;
    }
    return ele;
  };
  const onTabClick = (tab: ITabsList) => {
    if (selectedTab?.key !== tab.key) {
      setStateData((prev) => {
        return {
          ...prev,
          accessControlKey: 'dashboard',
          selectedRawData: ''
        };
      });
    }
    setSelectedTab(tab);
    // setSearchStringText('');
  };
  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        onActionPerformed(TABLE_TOP_BAR_ACTION_CODES.SEARCH, actionData);
        break;
      case TABLE_TOP_BAR_ACTION_CODES.CLOSE:
          setStateData((prev) => {
            return {
              ...prev,
              selectedActionCode: TABLE_TOP_BAR_ACTION_CODES.CLOSE,
            };
          });
        break;
      case TEAM_MEMBERS_VIEW_CODE.EDIT_ACCOUNT:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: TEAM_MEMBERS_VIEW_CODE.EDIT_ACCOUNT,
          };
        });
      break;
    }
  };
  const getLeftOfSet = () => {
    return  [
      'phoneWhiteList',
      'emailWhiteList',
      'teamMembers',
      'locations',
      'accessControl',
      'holidays',
    ].indexOf(selectedTab?.key) == -1
      ? 800
      : isSmallScreen
      ? 1000
      : 1200
  }
  const onActionPerformed = (
    tabCode: any,
    rawData?: any,
    extraData?: any
  ): any => {
    switch (tabCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: tabCode,
            selectedRawData: rawData,
            selectedExtraData: extraData,
          };
        });
        break;
      default:
        break;
    }
  };
  const getTabsCount = () => {
    getTeamCount().then((res)=> {
      const teamCount = res?.data?.teamCount || 0;
      setTabsCountData((prev=> {
        return {
          ...prev,
          teamCount: teamCount
        }
      }))
    })
    LOCATION_TOTAL_COUNT({
      variables: {
        accountUuid: accountUuid
      }
    }).then((res)=> {
      const locationCount = res.data?.accountLocationsAggregate?.aggregate?.count || 0
      setTabsCountData((prev=> {
        return {
          ...prev,
          locationCount: locationCount
        }
      }))
    })
  }
  useEffect(() => {
    const path = getPathFromHash(window.location.hash);
    const tabIndex = tagsTabList().findIndex((item) => item.path === path);
    setSelectedTab(tagsTabList()[tabIndex])
    getTabsCount()
  }, []);
  return (
    <>
      <View flex={1}>
        {/* <View style={styles.titleWrapper}>
          <TitleSubtitleView
            title={'Audit Logs'}
            subtitleLabelId="servicesManagementSubtitle"
          />
        </View> */}
        <View style={styles.tagContainer}>
          <CustomTabsView
            contentTopMargin={0}
            isAddMaxTab={true}
            minScreenSupportWidth={ 1800}
            leftOffset={getLeftOfSet()}
            showHeading
            basePath={'/admin/account'}
            heading="teamTitle"
            tabsList={tagsTabList()}
            tabListWrapperStyle={{
              paddingHorizontal: 16,
              backgroundColor: '#fff',
            }}
            canNavigate={true}
            rightView={() => {
              return getSelectedTopBar(selectedTab);
            }}
            bottomOffset={124}
            onTabClick={(tabItem) => {
              onTabClick(tabItem);
            }}
          />
        </View>
      </View>
    </>
  );
};

export default React.memo(AccountTabsView);
