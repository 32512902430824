import { View, Text, Skeleton } from "native-base"
import { Colors } from "../../../../styles/Colors"
import { ITableCellType, ITableCell } from "./types"
import MemberInfoListItem from "../../../common/MemberInfoListItem/MemberInfoListItem"
import { IContact } from "../../TeamInbox/Conversations/interfaces"
import { tableData } from "./raw"
import { reactStyles, styles } from "../formStyle"

export const FormTable = (props: {
    contactData: Record<string, IContact>,
    data: ITableCell[][],
}) => {


    const renderTableCell = (type?: ITableCellType, value?: any, width?: number, isLastCell?: boolean) => {

        switch (type) {
            case 'header':
                return (
                    <View style={[styles.headerCell, { width }]} backgroundColor={Colors.Custom.Gray200}>
                        <Text style={styles.headerText} size={'smRegular'}>
                            {value}
                        </Text>
                    </View>
                )
            case 'points':
                return (<View style={[styles.pointsCell, { width }]} backgroundColor={'#fff'}>

                    {
                        value?.map?.((item: any) => {
                            return (
                                <Text style={styles.pointText} size={'smRegular'} >
                                    {`• ${item}`}
                                </Text>
                            )
                        })
                    }


                </View>)
            case 'array':
                return (
                    <div style={reactStyles.arrayContainer}>
                        {
                            value?.map((item: any) => {
                                return renderTableCell(item?.type, item?.value, item?.width)
                            })
                        }
                    </div>
                )
            case 'contact':
                return renderContactInfoCell(value, width, isLastCell);
            case 'string':
                return <View style={[styles.stringCell, { width }]} backgroundColor={'#fff'}>
                        <Text style={styles.pointText} size={'smRegular'}>
                            {value}
                        </Text>
                    </View>
            default:
                return (
                    <View style={[styles.defaultCell, { width }]} backgroundColor={'#fff'}>
                        <Text style={styles.pointText} size={'smRegular'}>
                            {'N/A'}
                        </Text>
                    </View>
                )
        }

    }


    const renderContactInfoCell = (contactId?: string, width?: number, isLastCell?: boolean) => {
        const contact = contactId && props?.contactData?.[contactId];
        return (
            <View style={[styles.contactCell, {
                borderBottomWidth: isLastCell ? 0 : 1,
                width,
            }]} backgroundColor={'#fff'}>
                <View style={styles.height50}>
                    {contact ? <MemberInfoListItem contactData={contact} showDateOfBirth={true}/> : <Skeleton.Text lines={1} />}

                </View>

            </View>
        )
    }

    return (
        <View style={styles.container}>
            <View overflowX={'scroll'} overflowY={'clip'} style={styles.scrollContainer}>
                <View style={styles.columnContainer}>
                    {
                        props?.data?.map((item, index) => (
                            <View style={styles.rowContainer} key={index + "table"}>
                                {
                                    item.map((subitem) => renderTableCell(subitem?.type, subitem?.value, subitem?.width, ((index + 1) == props?.data.length)))
                                }
                            </View>
                        ))
                    }
                </View>
            </View>

        </View>
    )
}
