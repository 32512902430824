import React from 'react';
import Svg, {Path} from 'react-native-svg';

const RuleCreatedSvg = () => {
  return (
    <Svg width="12" height="14" fill="none" viewBox="0 0 14 12">
      <Path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.639 11.75v-1.056a2.111 2.111 0 00-2.111-2.11H3.305a2.111 2.111 0 00-2.11 2.11v1.056m11.61 0v-1.056a2.11 2.11 0 00-1.583-2.042M9.111 2.319a2.11 2.11 0 010 4.09M7.528 4.36a2.111 2.111 0 11-4.223 0 2.111 2.111 0 014.223 0z"
      ></Path>
    </Svg>
  );
};

export default RuleCreatedSvg;
