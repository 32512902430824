import React from 'react';
import Svg, { Path } from "react-native-svg";

const CampaignSvg = () => {
  return (
    <Svg
      width="12"
      height="14"
      viewBox="0 0 12 10"
      fill="none"
    >
      <Path
        d="M11.2777 1.83333C11.2777 1.25277 10.8027 0.777771 10.2222 0.777771H1.77772C1.19717 0.777771 0.722168 1.25277 0.722168 1.83333M11.2777 1.83333V8.16666C11.2777 8.74722 10.8027 9.22222 10.2222 9.22222H1.77772C1.19717 9.22222 0.722168 8.74722 0.722168 8.16666V1.83333M11.2777 1.83333L5.99995 5.52777L0.722168 1.83333"
        stroke="#667085"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default CampaignSvg;
