import {Dimensions} from 'react-native';
import React, {useEffect, useState} from 'react';
import {HintViewCodes} from './HintConfigConsts';
import {Skeleton, View} from 'native-base';
import PageBodyContainer from '../../../common/PageBodyContainer/PageBodyContainer';
import {AccessForm} from './AccessForm';
import {IPracticeDetail} from './interfaces';
import {PracticeDetails} from './PracticeDetails';
import {getHintPracticeDetails} from '../../../../services/CommonService/AidBoxService';
import {getFormattedPracticeDetails} from './HintConfigHelper';

const HintConfig = () => {
  const [hintConfigState, setHintConfigState] = useState({
    viewCode: HintViewCodes.ACCESS_FORM,
    practiceData: {} as IPracticeDetail,
    loading: true,
  });

  const {height} = Dimensions.get('window');

  const fetchPracticeDetails = async () => {
    setHintConfigState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const response = await getHintPracticeDetails();
      const practiceDetails = getFormattedPracticeDetails(response.data);
      setHintConfigState((prev) => {
        return {
          ...prev,
          loading: false,
          viewCode: HintViewCodes.PRACTICE_DETAIL,
          practiceData: practiceDetails,
        };
      });
    } catch (error) {

      setHintConfigState((prev) => {
        return {
          ...prev,
          viewCode: HintViewCodes.ACCESS_FORM,
          loading: false,
        };
      });
    }
  };

  const getViewFromSelectedCode = () => {
    switch (hintConfigState.viewCode) {
      case HintViewCodes.ACCESS_FORM:
      case HintViewCodes.PRACTICE_DETAIL:
        return (
          <View height={height - 180}>
            <PageBodyContainer>
              {hintConfigState.loading ? (
                <Skeleton.Text lines={4} />
              ) : (
                <>
                  {hintConfigState.viewCode === HintViewCodes.ACCESS_FORM && (
                    <AccessForm
                      onFormCompleteAction={() => {
                        fetchPracticeDetails();
                      }}
                    />
                  )}

                  {hintConfigState.viewCode ===
                    HintViewCodes.PRACTICE_DETAIL && (
                    <PracticeDetails details={hintConfigState.practiceData} />
                  )}
                </>
              )}
            </PageBodyContainer>
          </View>
        );
    }
  };

  useEffect(() => {
    fetchPracticeDetails();
  }, []);

  return <>{getViewFromSelectedCode()}</>;
};

export default HintConfig;
