import React from 'react';
import {
  AddButtonAction,
  AddCareProgramStepInput,
  IContactCareProgramStep,
} from '../../interface';
import AddAssessment, {
  IAddAssessmentProps,
} from './AddActionComponents/AddAssessment';
import {useMutation} from '@apollo/client';
import {ADD_CARE_PROGRAM_STEP} from '../../../../../services/CareProgram/CareProgramQueries';
import {useCustomToast} from '../../../../Toast/ToastProvider';
import {ToastType} from '../../../../../utils/commonViewUtils';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {getStepAddSuccessMessage} from '../ContactCareProgramView.utils';

const SideBarAddActionView: React.FC<{
  actionCode: AddButtonAction;
  onCancel: () => void;
  onAddComplete: () => void;
  contactAccountLocationId: string | undefined;
  step: IContactCareProgramStep;
}> = ({
  actionCode,
  onCancel,
  onAddComplete,
  contactAccountLocationId,
  step,
}) => {
  const toast = useCustomToast();
  const [
    addCareProgramStep,
  ] = useMutation(ADD_CARE_PROGRAM_STEP, {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const handleAddCareProgramStep = async (payload: AddCareProgramStepInput) => {
    try {
      await addCareProgramStep({variables: {params: payload}});
      onAddComplete();
      onCancel();
      toast({
        message: getStepAddSuccessMessage(actionCode),
        toastType: ToastType.success,
      });
    } catch (error) {
      toast({
        message: 'Something went wrong',
        toastType: ToastType.error,
      });
      console.error(error);
    }
  };

  switch (actionCode) {
    case AddButtonAction.SEND_FORM:
      return (
        <AddAssessment
          onCancel={onCancel}
          onAdd={handleAddCareProgramStep}
          contactAccountLocationId={contactAccountLocationId}
          step={step}
        />
      );

    default:
      return <></>;
  }
};

export default SideBarAddActionView;
