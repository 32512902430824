import {
  View,
  Text,
  Pressable,
  Input,
  Center,
  Skeleton,
  VStack,
} from 'native-base';
import {styles} from './style';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../styles/Colors';
import {
  Drawer,
  Progress,
  Table,
  Checkbox,
  DatePicker,
  DatePickerProps,
} from 'antd';

import {useContext, useEffect, useMemo, useState} from 'react';
import {Dimensions} from 'react-native';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import MemberInfoListItem from '../../../common/MemberInfoListItem/MemberInfoListItem';
import ContactsQueries from '../../../../services/Contacts/ContactsQueries';
import {useLazyQuery} from '@apollo/client';
import moment from 'moment';
import {
  JOURNEYS_STATUS,
  JOURNEYS_STATUS_CODE,
  getCareJourneyColor,
  getCareJourneyProgress,
  getCareJourneyStatus,
  getCareJourneyStatusTextColor,
} from '../JourneysOfCare/PatientCareJourney/PatientCareJourneyHelper';
import {getSectionDataByResourceCode} from '../../../../services/Analytics';
import JourneyProgressReport from '../JourneyProgressReport/JourneyProgressReport';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {FilterWrapperComponent} from '../../../common/CareDashboard/CareDashboardTopBar/FilterWrapperComponent';
import {getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {DATE_FORMATS, MLOV_CATEGORY} from '../../../../constants';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {IMlov} from '../JourneysOfCare/Table/Interfaces';
import {RangePickerProps} from 'antd/lib/date-picker';
import {withMiniContactViewHOC} from '../../../MiniContactViewHOC';
import {
  getCurrentMonthEnd,
  getDateSixMonthAgo,
  getCurrentTimeZone,
  getDateStrFromFormat,
  getDateYearAgo,
  isCurrentDateInFutureComparedToOther,
} from '../../../../utils/DateUtils';
import { JourneyGoalStatus } from './interfaces';
import { PATIENT_CARE_JOURNEY_STATUS_CODE } from '../../../../constants/MlovConst';
import { getColumns } from './helper';
import {useIntl} from 'react-intl';

const JourneyContactList = (props: any) => {
  const [tableSource, setTableSource] = useState<{
    columns: any[];
    dataSource: any[];
    isLoading: boolean;
  }>({
    columns: [],
    dataSource: [],
    isLoading: true,
  });
  const {journeyId} = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const intl = useIntl();
  const careJourneyDashboardDateRange = location?.state?.careJourneyDashboardDateRange;
  const [dateRange, setDateRange] = useState<Record<string, string>>({
    from: careJourneyDashboardDateRange?.from || getDateSixMonthAgo(),
    to: careJourneyDashboardDateRange?.to || getCurrentMonthEnd(),
  });

  const [pageOptions, setPageOptions] = useState({
    pageSize: 10,
    currentPage: 1,
    total: 1,
  });

  const [countByStatus, setCountByStatus] = useState<any[]>([]);
  const mlovData = useContext(CommonDataContext);
  const patientJourneyStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PATIENT_CARE_JOURNEY_STATUS
    ) || [];
  const type = searchParams.get('type');
  const defaultType = type
    ? patientJourneyStatusList?.find((item) => item.code == type)
    : undefined;

  const [status, setStatus] = useState<IMlov | undefined>(defaultType);
  const [searchString, setSearchString] = useState('');

  const navigate = useNavigate();

  if (!journeyId) {
    navigate('/admin/commerce/journeys/journeys', {replace: true});
    return null;
  }

  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const {height} = Dimensions.get('window');
  const finalHeight = height - 250;

  const [getContactDetails, contactDetailAPIQuery] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_BY_CONTACT_IDS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const fetchandUpdateShowMore = async (record: any) => {
    const contactId = record?.contactId;
    setTableSource((prev) => {
        return ({
            ...prev,
            isLoading: true
        })
    })
    const resp = await getSectionDataByResourceCode(
    'CARE_JOURNEY_TOTAL_ASSIGNED_JOURNEY_OF_CONTACT',
      {
        resourceCode: 'CARE_JOURNEY_TOTAL_ASSIGNED_JOURNEY_OF_CONTACT',
        isAccountDashboard: false,
        appliedFilter: [
          {
            filterCode: 'CARE_JOURNEY_ID',
            filterValue: {
              value: journeyId,
            },
          },
          {
            filterCode: 'CONTACT_ID',
            filterValue: {
              value: contactId,
            },
          },
        ],
      },
      'drill-down-data'
    );
    if (resp?.data?.result?.patientCareJourneys?.length) {
      setTableSource((prev) => {
        const newDataSource: any[] = [];
        prev.dataSource?.forEach((item) => {
          if (item.contactId == contactId) {
            const childrenData = resp?.data?.result?.patientCareJourneys
              ?.filter((item: any) => item.id != record.id)
              ?.map((item: any) => {
                return {...item, isChild: true, contact: record.contact};
              }) || [];
            newDataSource.push({...item, childFetched: true, children: childrenData});
          } else {
            newDataSource.push(item);
          }
        });
        return {
          ...prev,
          dataSource: newDataSource,
          isLoading: false,
        };
      });
      return;
    }
    setTableSource((prev) => {
      return {
        ...prev,
        isLoading: false,
      };
    });
  };

  const statusText = (code:string) => {
    switch (code) {
      case JourneyGoalStatus.NotAchieved:
        return 'Not Achieved';
      case JourneyGoalStatus.Achieved:
        return 'Achieved';
      default:
        return 'Not Achieved';
    }
  }

  const removeExpandedContacts = (record: any) => {
    const newData = tableSource.dataSource.filter((source) => {
      if (source.contactId !== record?.contactId) {
        return true;
      }
      if (source.contactId === record?.contactId) {
        return !source.isChild;
      }
    });
    const newDataParent = newData.map((source, index) =>
      source.contactId === record?.contactId
        ? {...source, childFetched: false}
        : source
    );
    setTableSource(prev => ({...prev,dataSource: newDataParent}));
  };

  const getExpandedIds = () => {
    return tableSource?.dataSource?.filter((dataObj)=> (dataObj.childFetched)).map((dataObj)=> (dataObj.id)) || []
  }

  const fetchAndUpdateData = async () => {
    setTableSource((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });

    const [countByStatusResp, resp] = await Promise.all([
      getSectionDataByResourceCode(
        'CARE_JOURNEY_TOTAL_ASSIGNED_COUNT_BY_STATUS',
        {
          resourceCode: 'CARE_JOURNEY_TOTAL_ASSIGNED_COUNT_BY_STATUS',
          isAccountDashboard: false,
          appliedFilter: [
            {
              filterCode: 'CARE_JOURNEY_ID',
              filterValue: {
                value: journeyId,
              },
            },
            {
              filterCode: 'DATE_RANGE',
              filterValue: {
                fromDate: dateRange.from,
                toDate: dateRange.to,
                timezone: getCurrentTimeZone(),
              },
            },
          ],
        }
      ),
      getSectionDataByResourceCode(
        'CARE_JOURNEY_TOTAL_ASSIGNED_JOURNEYS',
        {
          resourceCode: 'CARE_JOURNEY_TOTAL_ASSIGNED_JOURNEYS',
          isAccountDashboard: false,
          appliedFilter: [
            {
              filterCode: 'CARE_JOURNEY_ID',
              filterValue: {
                value: journeyId,
              },
            },
            {
              filterCode: 'PAGE_SIZE',
              filterValue: {
                value: pageOptions.pageSize,
              },
            },
            {
              filterCode: 'PAGE_OFFSET',
              filterValue: {
                value: pageOptions.pageSize * (pageOptions.currentPage - 1),
              },
            },
            {
              filterCode: 'DATE_RANGE',
              filterValue: {
                fromDate: dateRange.from,
                toDate: dateRange.to,
                timezone: getCurrentTimeZone(),
              },
            },
            {
              filterCode: 'STATUS_CODES',
              filterValue: {
                value: status ? [status.code] : [],
              },
            },
          ],
        },
        'drill-down-data'
      ),
    ]);

    if (countByStatusResp?.data?.result?.patientCareJourneyCountResult) {
      setCountByStatus(
        countByStatusResp?.data?.result?.patientCareJourneyCountResult
      );
    }
    if (!resp?.data?.result?.patientCareJourneys?.data?.length) {
      setTableSource((prev) => {
        return {
          ...prev,
          isLoading: false,
          dataSource: [],
        };
      });
      return;
    }

    const data = resp?.data?.result?.patientCareJourneys?.data || [];
    const dataSource: any[] = [];
    const contactIds: string[] = [];
    data.forEach((item: any) => {
      const contactId = item.contactId;
      if (contactId && !contactIds.includes(contactId)) {
        contactIds.push(contactId);
      }
    });
    const contactMap: {[index: string]: any} = {};
    if (contactIds.length) {
      const result = await getContactDetails({
        variables: {
          contactIds: contactIds,
        },
        fetchPolicy: 'no-cache'
      });
      result?.data?.contacts?.forEach((contactData: any) => {
        contactMap[contactData.uuid] = contactData;
      })
    }
    data.forEach((item: any) => {
      dataSource.push({...item, contact: item.contactId ? contactMap[item.contactId] : undefined});
    });

     setPageOptions((prev) => {
      return {
        ...prev,
        total: resp?.data?.result?.patientCareJourneys?.totalRows,
      };
    });

    setTableSource((prev) => {
      return {
        ...prev,
        dataSource,
        isLoading: false,
      };
    });
  };
  useEffect(() => {
    fetchAndUpdateData();
  }, [pageOptions.currentPage, pageOptions.pageSize, dateRange, status]);


  const statusFilter = patientJourneyStatusList.filter((item) =>
    [
      PATIENT_CARE_JOURNEY_STATUS_CODE.ACTIVE,
      PATIENT_CARE_JOURNEY_STATUS_CODE.ASSIGNED,
      PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED,
      PATIENT_CARE_JOURNEY_STATUS_CODE.STOPPED,
      PATIENT_CARE_JOURNEY_STATUS_CODE.COMPLETE,
    ].includes(item.code)
  );
  const renderHeader = () => {
    return (
      <View style={styles.header}>
        <VStack>
          <View style={styles.headerLeftContainer}>
            <Feather
              onPress={() => {
                navigate(`/admin/commerce/journeys/${journeyId}/analytics`,{
                  state: { careJourneyDashboardDateRange: dateRange}
                });
              }}
              style={{
                marginRight: 10,
              }}
              size={25}
              name="arrow-left"
              color={Colors.Custom.Gray500}
            />
            <Text
              style={{
                marginRight: 6,
              }}
              size={'smBold'}
              fontSize={18}
            >
              Back to Journey Overview
            </Text>
          </View>
          <Text
            style={{
              color: Colors.Custom.Gray400,
              padding: 4,
              paddingTop: 0,
              fontSize: 12,
              marginLeft: 20,
            }}
          >
            • {intl.formatMessage({id: 'analyticsSyncMessage'})}
          </Text>
        </VStack>
        <View style={styles.headerRightContainer}>
          {/* <Input
                        placeholder="Search in List"
                        onChangeText={(value) => {
                            setSearchString(value?.trim());
                        }}
                        size={"md"}
                        width={"30%"}
                        leftElement={<AntDesign color={Colors.Custom.Gray500} name="search1" size={18} style={{
                            marginLeft: 16,
                            fontSize: 14
                        }} />}
                    /> */}
          <FilterWrapperComponent
            onClose={() => {
              // onClose
            }}
            textWeight={400}
            value={status ? [status.value] : ['All Journeys']}
            minWidthDropdown={200}
          >
            {statusFilter
              .map((item, index) => {
                const count =
                  countByStatus?.find(
                    (subitem) => subitem?.statusCode == item.code
                  )?.count || 0;

                return (
                  <Pressable
                    onPress={() => {
                      setStatus(item);
                    }}
                    style={{
                      width: 200,
                      paddingVertical: 12,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderTopColor: Colors.Custom.Gray200,
                      borderTopWidth: !index ? 0 : 1,
                    }}
                  >
                    <Text
                      color={
                        item.id === status?.id
                          ? Colors.Custom.mainPrimaryPurple
                          : Colors.Custom.Gray500
                      }
                      size={'smMedium'}
                      fontSize={16}
                    >
                      {item.value}
                    </Text>
                    <Text size={'smMedium'} fontSize={16}>
                      {count}
                    </Text>
                  </Pressable>
                );
              })}
            <Pressable
              onPress={() => {
                setStatus(undefined);
              }}
              style={{
                width: 200,
                paddingVertical: 12,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderTopColor: Colors.Custom.Gray200,
                borderTopWidth: 1,
              }}
            >
              <Text
                color={Colors.Custom.Gray500}
                size={'smMedium'}
                fontSize={16}
              >
                Clear
              </Text>
            </Pressable>
          </FilterWrapperComponent>
          <View style={styles.datePickerContainer}>
            <DatePicker
              clearIcon={false}
              style={{
                borderWidth: 0,
              }}
              suffixIcon={null}
              format={'yyyy-MMM-DD'}
              onChange={(
                value: DatePickerProps['value'] | RangePickerProps['value'],
                dateString: string
              ) => {
                setDateRange({
                  from: getDateStrFromFormat(
                    value?.toString(),
                    DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                  ),
                  to: dateRange.to,
                });
              }}
              value={moment(dateRange.from || '')}
            />
            <DatePicker
              clearIcon={false}
              style={{
                borderWidth: 0,
              }}
              format={'YYYY-MMM-DD'}
              onChange={(
                value: DatePickerProps['value'] | RangePickerProps['value'],
                dateString: string
              ) => {
                setDateRange({
                  to: getDateStrFromFormat(
                    value?.toString(),
                    DATE_FORMATS.CARE_JOURNEY_DASHBOARD_DATE_FORMAT
                  ),
                  from: dateRange.from,
                });
              }}
              value={moment(dateRange.to || '')}
            />
          </View>
        </View>
      </View>
    );
  };

  return (
    <View>
      {selectedRecord ? (
        <JourneyProgressReport
          journeyId={journeyId}
          record={selectedRecord}
          title={`${selectedRecord?.contact?.name}'s Progress`}
          isVisible={selectedRecord ? true : false}
          onCancel={() => {
            setSelectedRecord(null);
          }}
        />
      ) : null}
      {renderHeader()}
      <View>
        <Table
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          className="journey-table-drawer"
          dataSource={tableSource.dataSource?.filter((item) =>
            item.contact?.name?.includes(searchString)
          )}
          columns={getColumns({
            openContactInWindowOrOpenContactIdDrawer: (id) =>
              props?.openContactInWindowOrOpenContactIdDrawer(id),
            removeExpandedContacts: removeExpandedContacts,
            fetchandUpdateShowMore: fetchandUpdateShowMore,
            setSelectedRecord: (record) => setSelectedRecord(record),
            patientJourneyStatusList: patientJourneyStatusList,
            statusText: statusText,
          })}
          expandable={{
            expandedRowKeys: getExpandedIds(),
            expandIcon: () => {
              return <></>;
            },
          }}
          loading={tableSource.isLoading}
          pagination={{
            position: ['bottomCenter'],
            pageSize: pageOptions.pageSize,
            pageSizeOptions: [10, 20, 25, 50],
            current: pageOptions.currentPage,
            total: pageOptions.total,
            onChange(page, pageSize) {
              setPageOptions((prev) => {
                return {
                  ...prev,
                  currentPage: page,
                  pageSize: pageSize,
                };
              });
            },
          }}
          rowKey={(record) => {
            return record.id;
          }}
          scroll={{x: 1200, y: finalHeight}}
        />
      </View>
    </View>
  );
};
export default withMiniContactViewHOC(JourneyContactList);
