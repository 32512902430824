import LeadListView from "../Contacts/Leads/LeadView/LeadTableView/LeadListView/LeadListView";

export const MyPatientView=(props: any) => {

  return <>
    <LeadListView
      addOrUpdateEvent={props.addOrUpdateEvent}
      searchText={props?.searchText}
      status={props?.status}
      loggedInStatus={props?.loggedInStatus}
      formData={props?.formData}
      tagItem={props?.tagItem}
      currentPage={props?.currentPage}
      onActionPerformed={props?.onActionPerformed}
      inboxItem={props?.inboxItem}
      personTypeUuid={props?.personTypeUuid}
      personType={props?.personType}
      selectedUserPracticeLocation={props?.selectedUserPracticeLocation}
      clearAllEvent={props?.clearAllEvent}
      updateContactCount={props?.updateContactCount}
      contactUpdated={props?.contactUpdated}
      tab={props?.tab}
    />
  </>
}
