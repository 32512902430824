import React from 'react';
import { isWeb } from '../../../utils/platformCheckUtils';
import { Image } from 'native-base';

const FolderIcon = () => {

    return (
        <>
            {isWeb() ? (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.3334 25.3333C29.3334 26.0406 29.0525 26.7189 28.5524 27.219C28.0523 27.719 27.374 28 26.6667 28H5.33341C4.62617 28 3.94789 27.719 3.4478 27.219C2.9477 26.7189 2.66675 26.0406 2.66675 25.3333V6.66667C2.66675 5.95942 2.9477 5.28115 3.4478 4.78105C3.94789 4.28095 4.62617 4 5.33341 4H12.0001L14.6667 8H26.6667C27.374 8 28.0523 8.28095 28.5524 8.78105C29.0525 9.28115 29.3334 9.95942 29.3334 10.6667V25.3333Z" fill="#98A2B3" />
                </svg>
            ) :
                (
                    <Image
                        resizeMode="contain"
                        size={'30px'}
                        source={require('../../../assets/images/PNGImages/FolderIconPng.png')}
                        alt="image"
                    />
                )
            }
        </>

    )
};
export default FolderIcon;