import {Text} from 'native-base';
import {
  ICampaignDataFromProps,
  ICampaignDataProps,
  ICampaignDataTypeProps,
} from '../interfaces';
import {CampaignTableActions} from './TableComponents/CampaignTableActions';
import {CampaignTableFrom} from './TableComponents/CampaignTableFrom';
import {CampaignTableSending} from './TableComponents/CampaignTableSending';
import {CampaignTableTotal} from './TableComponents/CampaignTableTotal';
import {CampaignTableType} from './TableComponents/CampaignTableType';

export const getColumns = (
  actionFn: (actionCode: string, recordData: ICampaignDataProps) => {}
) => {
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center' as const,
      render: (title: string) => <Text>{title}</Text>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as const,
      render: (status: string) => <CampaignTableSending status={status} />,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'center' as const,
      render: (total: number) => <CampaignTableTotal total={total} />,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center' as const,
      render: (type: ICampaignDataTypeProps) => (
        <CampaignTableType type={type} />
      ),
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      align: 'center' as const,
      render: (brandName: string) => <Text>{brandName}</Text>,
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      align: 'center' as const,
      render: (from: ICampaignDataFromProps) => (
        <CampaignTableFrom from={from} />
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'creator',
      key: 'creator',
      align: 'center' as const,
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center' as const,
    },
    {
      title: 'Schedule Date',
      dataIndex: 'scheduleDate',
      key: 'scheduleDate',
      align: 'center' as const,
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      align: 'center' as const,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center' as const,
      render: (id: number, record: ICampaignDataProps) => (
        <CampaignTableActions
          id={id}
          onActionPerformed={actionFn}
          record={record}
        />
      ),
    },
  ];

  return columns;
};
