import {Box, Button, HStack, Input, Select, Text, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  stagePercentMetaData,
  stageStateMetaData,
} from '../../../../../../dummyData/Sales';
import {NodataField} from '../../../../../common/NoDataField';
import {style} from './AddStageViewStyles';

const AddStagesView = React.forwardRef((props: any, ref: any) => {
  const [dataSource, setDataSource] = useState<any>([]);
  const [count, setCount] = useState(1);

  React.useImperativeHandle(ref, () => ({
    sendStage: () => {
      props.sendStagesData(dataSource);
    },
  }));

  const addAnotherStage = () => {
    const newData: any = {
      key: count,
      stageName: '',
      percent: '10',
      state: 'Active',
    };
    setDataSource([...dataSource, newData]);
    setCount((prv) => prv + 1);
  };

  const deleteStageItem = (sItem: any) => {
    const newStageArray = dataSource.filter((item: any) => {
      return sItem.key !== item.key;
    });
    setDataSource(newStageArray);
  };

  useEffect(() => {
    if (props.stages != undefined) {
      setCount(props.stages.length + 1)
      setDataSource(props.stages);
    }
  }, [props.stages]);

  const handleOnDragEnd = (result: any) => {
    if (result.destination) {
      const items = Array.from(dataSource);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setDataSource(items);
    }
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    ...draggableStyle,
    position: isDragging ? 'absolute' : '',
    top: 'auto !important',
    left: 'auto !important',
  });
  return (
    <Box style={style.stagesContainer} shadow={2}>
      {dataSource.length > 0 ? (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="stage">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {dataSource.map((stageItem: any, index: any) => {
                  return (
                    <Draggable
                      key={stageItem.key}
                      draggableId={stageItem.key.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <HStack shadow={4} style={style.stagesitems}>
                            <VStack mx={2}>
                              <AntIcon name="arrowsalt" color="black" />
                            </VStack>
                            <VStack mx={2}>
                              <HStack>
                                <VStack mx={2}>
                                  <Input
                                    variant="outline"
                                    placeholder="Stage name"
                                    defaultValue={stageItem.stageName}
                                    onChangeText={(stage) => {
                                      stageItem.stageName = stage;
                                    }}
                                  />
                                </VStack>
                                <VStack mx={2}>
                                  <Select
                                    variant="outline"
                                    defaultValue={
                                      stagePercentMetaData[0].percentLabel
                                    }
                                    accessibilityLabel="percent"
                                    onValueChange={(perct) =>
                                      (stageItem.percent = perct)
                                    }
                                  >
                                    {stagePercentMetaData.map((item, index) => {
                                      return (
                                        <Select.Item
                                          key={index}
                                          label={item.percentLabel}
                                          value={item.value}
                                        />
                                      );
                                    })}
                                  </Select>
                                </VStack>
                                <VStack mx={2}>
                                  <Select
                                    defaultValue={
                                      stageStateMetaData[0].titleLabel
                                    }
                                    variant="outline"
                                    accessibilityLabel="state"
                                    onValueChange={(value) =>
                                      (stageItem.state = value)
                                    }
                                  >
                                    {stageStateMetaData.map((item, index) => {
                                      return (
                                        <Select.Item
                                          key={index}
                                          label={item.titleLabel}
                                          value={item.value}
                                        />
                                      );
                                    })}
                                  </Select>
                                </VStack>
                                <VStack
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                >
                                  <Button
                                    style={style.closeButton}
                                    onPress={() => {
                                      deleteStageItem(stageItem);
                                    }}
                                  >
                                    <AntIcon name="close" color="black" />
                                  </Button>
                                </VStack>
                              </HStack>
                            </VStack>
                          </HStack>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <NodataField />
      )}
      <Button
        onPress={() => {
          addAnotherStage();
        }}
        style={style.buttonStyle}
      >
        <Text style={style.textStyle}>Add another stage</Text>
      </Button>
    </Box>
  );
});

export default AddStagesView;