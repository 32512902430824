import React from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Icon} from 'native-base';
import {ITableTopBarProps} from '../../../../../common/TableTopBar';

export const getPipelineListTopBarButtons = (
  boardobj: any,
  buttonClickFnList: (() => void)[]
): ITableTopBarProps => {
  return {
    title: boardobj.title,
    count: boardobj.count,
    buttonList: [
      {
        btnText: 'Add Pipeline',
        size: 'sm',
        textColor: 'white',
        variant: '',
        leftIcon: (
          <Icon as={AntIcon} name={'plus'} size="3" width={10} height={10} />
        ),
        btnClick: () => {
          buttonClickFnList[0]();
        },
      },
    ],
  };
};
