import {
  Button,
  FormControl,
  HStack,
  Icon,
  Image,
  Input,
  Pressable,
  Text,
  VStack,
  View,
  useToast
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { Dimensions, Platform } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { CommonDataContext } from '../../context/CommonDataContext';
import { Colors } from '../../styles';
import { TestIdentifiers, testID } from '../../testUtils';

import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { ForgotPassword } from '../../components/ForgotPassword';
import LoginBg from '../../components/Login/LoginBg';
import { styles } from '../../components/Login/LoginStyles';
import { RoleCategory } from '../../components/RightSideContainer/Contacts/TeamMembers/AddEditUser/interfaces';
import { useKeyboard } from '../../components/RightSideContainer/TeamInbox/Conversations/MessagingWindow/UseKeyBoard.native';
import { DisplayText } from '../../components/common/DisplayText/DisplayText';
import FoldhealthLogoSvg from '../../components/common/Svg/FoldhealthLogoSvg';
import { EXTRA_SMALL_SCREEN, SMALL_SCREEN } from '../../constants';
import BaseService from '../../services/CommonService/BaseService';
import LocalStorage from '../../utils/LocalStorage';
import { getCurrentUserRoleCodes, isValidObject } from '../../utils/commonUtils';
import { LOCAL_STORAGE_CONST } from './MainScreenConst';
import { SsoErrorPage } from './SSOLogin/SsoErrorPage';
import { ToastType, showToast } from '../../utils/commonViewUtils';
import { TEAM_MEMBERS_ROLES_CODE } from '../../components/RightSideContainer/Contacts/TeamMembers/AddEditUser/TeamMemberConst';
import useMemoryMonitor from '../../components/CustomHooks/useMemoryMonitor';

export const LockScreen = ({userData,switchLockScreen,navigation}:any) => {
  const {height} = Dimensions.get('window');
  const keyboardHeight = useKeyboard();
  const commonData = useContext(CommonDataContext);
  const accountThemeConfig: any = commonData.accountThemeConfig || {};
  const [show, setShow] = useState(false);
  const [errors, setErrors]: any = useState({});
  const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);
  const [stateData,setStateData] = useState({
    email: '',
    password: '',
    accountId: -1,
    loading: false,
    showSSOError: false,
  })
  const toast = useToast();
  const intl = useIntl();
  const isSmallScreen = height <= SMALL_SCREEN;
  const extraSmallScreen = height <= EXTRA_SMALL_SCREEN;

  const ssoConfig =
  commonData.accountConfigData?.SSO_CONFIG?.accountConfigurations;

  const loggedInUserRole = getCurrentUserRoleCodes();
  const isSso = (ssoConfig && ssoConfig.length > 0) || loggedInUserRole.includes(TEAM_MEMBERS_ROLES_CODE.CUSTOMER_SUCCESS)
  const {checkMemoryUsage} = useMemoryMonitor()

  const onSubmit = () => {
    setStateData((prev)=>{
      return {
        ...prev,
        loading: true
      }
    })
    if (isSso) {
      getToken().then((userToken) => {
        const token = userToken?.getIdToken()?.getJwtToken();
        if (token?.length) {
          ssoService(token);
        } else {
          window.location.reload();
        }
      });
    } else {
      loginService(stateData?.email,stateData?.password,stateData?.accountId)
    }
  };
  const handleClick = () => setShow(!show);
  const loginService = async (
    username: string,
    password: string,
    accountId?: number
  ) => {
    const axios = BaseService.getSharedInstance().axios;
    const bodyData: any = {email: stateData?.email, password: stateData?.password};
    if (accountId) {
      bodyData.accountId = accountId;
    }
    axios
      .post(`/crm-nest/platform/sign_in`, JSON.stringify(bodyData), {
        headers: {
          accountId: accountId || '',
          fold_access_token: '',
        },
      })
      .then((response) => {
        if (response?.data?.data?.id) {
          LocalStorage.removeItem(LOCAL_STORAGE_CONST?.lockScreen).then(()=>{
            switchLockScreen()
          }).then(()=>{
            if (window?.location) {
              window.location.reload();
            }
          })
        }
        setStateData((prev)=>{
          return {
            ...prev,
            loading: false
          }
        })
      })
      .catch((err) => {
        showToast(toast, intl.formatMessage({id: 'invalidCredential'}), ToastType.error);
        setStateData((prev)=>{
          return {
            ...prev,
            loading: false
          }
        });
      });
  };

  function getToken() {
    return Auth.currentSession()
      .then((session) => session)
      .catch(()=>{});
  }

  const ssoService = async (token: any) => {
    const axios = BaseService.getSharedInstance().axios;
    axios
      .post(`/crm-nest/platform/sso/sign_in`, JSON.stringify({token: token}), {
        headers: {
          accountId: stateData?.accountId,
          fold_access_token: '',
        },
      })
      .then(async (response) => {
        if (response.status == 200 && isValidObject(response.data)) {
          LocalStorage.removeItem(LOCAL_STORAGE_CONST?.lockScreen).then(() => {
            switchLockScreen();
          });
          setStateData((prev) => {
            return {
              ...prev,
              loading: false,
              showSSOError: false,
            };
          });
        }
      })
      .catch((err) => {

        showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
            showSSOError: true,
          };
        });
      });
  };

  useEffect(()=>{
    setStateData((prev)=>{
      return {
        ...prev,
        email: userData?.email || '',
        accountId : userData?.account_id || -1,
      }
    })
    checkMemoryUsage();
  },[])

  const userLogout = () => {
    LocalStorage.removeItem('isWorkflowLogin');
    LocalStorage.removeItem('loginDone');
    Auth.signOut({global: true});
    LocalStorage.removeItem(LOCAL_STORAGE_CONST?.lockScreen)
    LocalStorage.removeItem('user').then(() => {
      window.location.href = window.location.origin + '/#/home';
      window.location.reload();
    });
  }

  const forgetScreen = () =>{
    return (
      <ForgotPassword
          screen={'lockScreen'}
          onLoginClick={() => setForgotPasswordClicked(false)}
          onSuccess={() => {
            setTimeout(() => {
              userLogout();
            }, 3000);
          }}
        />
    )
  }

  const ssoErrorScreen = () =>{
    return (
      <SsoErrorPage
          ssoLoginData={{
            userEmail: stateData?.email,
            account: {
              accountId: -1,
              accountConfigurations: {
                configuration: {} as RoleCategory,
                value: ssoConfig?.[0]?.value || '',
              }
            },
          }}
        onSsoLogin={() => {
          window.location.reload();
        }}
      />
    )
  }

  return (
    <>
      {forgotPasswordClicked ? (
        <>{forgetScreen()}</>
      ) : stateData.showSSOError ? (
        <>{ssoErrorScreen()}</>
      ) : (
        <View
          bgColor={'white'}
          height={
            Platform.OS === 'web'
              ? 'auto'
              : keyboardHeight == 0
              ? 'full'
              : height - keyboardHeight
          }
        >
          {Platform.OS === 'web' && (
            <VStack
              height={Platform.OS === 'web' ? '100vh' : 'full'}
              justifyContent={'space-between'}
              style={
                Platform.OS === 'web' && {
                  position: 'relative',
                  right: 100,
                }
              }
            >
              <View />
              <View {...testID(TestIdentifiers.loginBg)} height={height - 100}>{<LoginBg />}</View>
            </VStack>
          )}
          <View
            flex={1}
            alignItems={'flex-end'}
            mt={Platform.OS === 'web' ? (isSmallScreen ? '2%' : '10%') : '0'}
            mr={Platform.OS === 'web' ? '10%' : '0'}
            style={
              Platform.OS === 'web'
                ? {
                    flex: 1,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  }
                : {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 10,
                  }
            }
          >
            <View
              style={[
                styles.mainBoxContainer,
                Platform.OS === 'web' && styles.mainBoxContainerShadow,
              ]}
              bg={'white'}
            >
              <VStack w={'100%'}>
                {!extraSmallScreen && (
                  <HStack
                    justifyContent="center"
                    alignItems={'center'}
                    flex={1}
                    {...testID(TestIdentifiers.foldhealthLogoWithName)}
                  >
                    {Platform.OS === 'web' ? <FoldhealthLogoSvg /> : <></>}
                  </HStack>
                )}
                <Text
                  style={{
                    textAlign: 'center',
                    fontWeight: '700',
                    fontSize: 30,
                    lineHeight: 45,
                    marginTop: extraSmallScreen ? 6 : 0,
                  }}
                  {...testID('Hey!')}
                >
                  {`Hey ${userData?.name || userData?.available_name}!`}
                </Text>
                <View
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginHorizontal: 20,
                  }}
                >
                  <Text
                    style={{
                      color: 'black',
                      fontWeight: '400',
                      fontSize: 14,
                      lineHeight: 21,
                      textAlign: 'center',
                    }}
                    {...testID('LockScreenMessage')}
                  >
                    {
                      `Your screen is locked for security purposes. ${!isSso ? 'Enter password to unlock.' : 'Click below to unlock.'}`
                    }
                  </Text>
                </View>
              </VStack>
              {isSso ? (
                <></>
              ) : (
                <VStack mt="4" w={'100%'}>
                  <FormControl {...testID(TestIdentifiers.isRequiredInput)} mt={4} isRequired isInvalid={errors.password}>
                    <FormControl.Label>
                      <Text {...testID(TestIdentifiers.passwordText)} style={styles.labelStyle}>Password</Text>
                    </FormControl.Label>
                    <Input
                      _focus={{
                        borderColor: Colors.Custom.Gray200,
                      }}
                      height={44}
                      fontSize={16}
                      type={show ? 'text' : 'password'}
                      placeholder="Password"
                      autoCapitalize="none"
                      value={stateData?.password}
                      onKeyPress={(event: any) =>
                        event?.keyCode === 13 && onSubmit()
                      }
                      onChangeText={(value) => {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            password: value,
                          };
                        });
                      }}
                      InputRightElement={
                        <View paddingRight={2}>
                          <MaterialCommunityIcons
                            name={show ? 'eye-off' : 'eye'}
                            size={24}
                            color={Colors.Custom.Gray400}
                            onPress={() => {
                              handleClick();
                            }}
                            {...testID(TestIdentifiers.showPasswordBtn)}
                          />
                        </View>
                      }
                      {...testID(TestIdentifiers.loginPassword)}
                    />
                    {errors.password && (
                      <FormControl.ErrorMessage
                        _text={{
                          fontSize: 'xs',
                          color: 'error.500',
                          fontWeight: 500,
                        }}
                        {...testID(TestIdentifiers.errorMessage)}
                      >
                        {errors.password}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                </VStack>
              )}

              <Button
                borderWidth={1}
                borderColor={Colors.danger[200]}
                w={'100%'}
                h={10}
                style={styles.submitElement}
                variant={'solid'}
                onPress={onSubmit}
                borderRadius={20}
                {...testID(TestIdentifiers.loginBtn)}
                isLoading={stateData?.loading}
              >
                <DisplayText
                  textLocalId="unlock"
                  size={'smMedium'}
                  extraStyles={
                    {color: 'white', fontWeight: '600', fontSize: 14} || ''
                  }
                />
              </Button>

              {isSso ? (
                <></>
              ) : (
                <>
                  <HStack
                    space={2}
                    mt={6}
                    width={'100%'}
                    justifyContent={'center'}
                  >
                    <Pressable
                      // style={}
                      onPress={() => {
                        setForgotPasswordClicked(true);
                      }}
                    >
                      <HStack space={2}>
                        <Icon
                          size={5}
                          color={'#825AC7'}
                          as={<AntIcon name="questioncircleo" />}
                          {...testID('image')}
                        />
                        <Text
                          style={{
                            color: '#825AC7',
                            fontSize: 14,
                            fontWeight: '600',
                            alignSelf: 'center',
                            lineHeight: 21,
                          }}
                          {...testID(TestIdentifiers.forgotPasswordText)}
                        >
                          Forgot Password?
                        </Text>
                      </HStack>
                    </Pressable>
                  </HStack>
                  <HStack
                    marginTop={8}
                    width={'445px'}
                    justifyContent={'center'}
                    marginX={-60}
                    borderTopWidth={1}
                    borderColor={'gray.200'}
                  >
                    <Pressable
                      onPress={() => {
                        userLogout();
                      }}
                    >
                      <HStack marginTop={6} space={2} alignItems={'center'}>
                        <Image
                          resizeMode="cover"
                          style={{width: 14, height: 14}}
                          source={require('../../assets/images/login.png')}
                          alt="image"
                        />
                        <Text
                          style={{
                            color: '#825AC7',
                            fontSize: 14,
                            fontWeight: '600',
                            alignSelf: 'center',
                            lineHeight: 21,
                          }}
                          {...testID('loginAsADifferentUser')}
                        >
                          {intl.formatMessage({id: 'loginAsADifferentUser'})}
                        </Text>
                      </HStack>
                    </Pressable>
                  </HStack>
                </>
              )}
            </View>
          </View>
        </View>
      )}
    </>
  );
};


