import {HStack, Image, Modal, Pressable, Text, View, VStack} from 'native-base';
import React from 'react';
import {useWindowDimensions} from 'react-native';
import QRCode from 'react-qr-code';
import {PROVIDER_APP_URL} from '../../../constants';
import {isWidthBelowThreshold} from '../../../utils/commonUtils';
import {DisplayText} from '../DisplayText/DisplayText';
import {Colors} from '../../../styles';
import LocalStorage from '../../../utils/LocalStorage';

const SmallScreenAlert = () => {
  const [state, setState] = React.useState({
    providerAppUrl: PROVIDER_APP_URL,
    modalOpen: false,
  });

  const {width, height} = useWindowDimensions();

  const handleOnClose = () => {
    LocalStorage.setItem('continueOnWeb', 'true').then(() => {
      setState((prev) => {
        return {
          ...prev,
          modalOpen: false,
        };
      });
    });
  };

  const handleWidthChange = () => {
    LocalStorage.getItem('continueOnWeb').then((value) => {
      if (value == 'true') {
        return;
      }
      if (isWidthBelowThreshold(width)) {
        setState((prev) => {
          return {
            ...prev,
            modalOpen: true,
          };
        });
      } else {
        setState((prev) => {
          return {
            ...prev,
            modalOpen: false,
          };
        });
      }
    });
  };

  React.useEffect(() => {
    handleWidthChange();
  }, [width]);

  return (
    <Modal isOpen={state.modalOpen}>
      <Modal.Content
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 20,
          minWidth: 'min-content',
          // width: 799,
        }}
      >
        <Modal.Header
          style={{
            width: '100%',
          }}
        >
          <HStack
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              flex: 1,
            }}
          >
            <DisplayText
              textType={'Heading'}
              textLocalId={'appDownloadTitle'}
              size="smNormal"
            />
            <Pressable
              onPress={handleOnClose}
            >
              <Text
                style={{
                  color: Colors.Custom.mainPrimaryPurple,
                }}
              >
                {'Continue on web >'}
              </Text>
            </Pressable>
          </HStack>
        </Modal.Header>

        <Modal.Body
          style={{
            borderRadius: 0,
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 20,
            paddingBottom: 40,
            paddingHorizontal: 30,
          }}
        >
          <View>
            <Text size={'lgBold'} style={{marginLeft: 15}}>
              Working on smaller screen sizes?
            </Text>
            <Text size={'lgBold'} style={{marginLeft: 15}}>
              Download our app to work on the go
            </Text>
            <HStack alignItems={'center'} marginTop={5}>
              <Image
                resizeMode="contain"
                source={require('../../../assets/images/Front.png')}
                alt="image"
                style={{
                  width: 220,
                  height: 400,
                }}
              ></Image>
              <VStack
                style={{
                  marginLeft: 20,
                  // width: 350
                }}
              >
                <QRCode
                  size={100}
                  value={state.providerAppUrl}
                  viewBox={`0 0 100px 100px`}
                />
                <Text
                  style={{
                    fontSize: 12,
                    marginTop: 5,
                  }}
                  size={'xsNormal'}
                >
                  SCAN QR TO DOWNLOAD
                </Text>

                <DisplayText
                  size={'xlNormal'}
                  textLocalId={'appTitle'}
                  extraStyles={{
                    color: '#6938EF',
                    marginTop: 12,
                    marginBottom: 12,
                    width: 310,
                  }}
                />
                <Text
                  size={'mdNormal'}
                  style={{
                    fontWeight: '500',
                    fontSize: 18,
                    lineHeight: 27,
                    color: Colors.Custom.Gray700,
                    width: 283,
                  }}
                >
                  Manage appointments, have virtual visits, event reminders,
                  track business performance, task management. Its all in here.
                </Text>
                <Pressable
                  onPress={() => {
                    window.open(state.providerAppUrl, '_blank', 'noopener,noreferrer');
                  }}
                >
                  <Image
                    resizeMode="contain"
                    source={require('../../../assets/images/appstore.png')}
                    alt="image"
                    style={{
                      width: 120,
                      height: 40,
                      marginTop: 16,
                    }}
                  ></Image>
                </Pressable>
              </VStack>
            </HStack>
          </View>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default SmallScreenAlert;
