import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichTextEditorStyle.css';

interface IProps {
  style?:any,
  valueStr?: string;
  addBulletPointsAsDefault?: boolean;
  isReadOnly?: boolean;
  placeholder?: string;
  modules?: any;
  onChangesValue?: (value: any) => void;
  hideBottomBorder?: boolean;
  hideDefaultPlaceholder?: boolean;
}


export const TEXT_ONLY_MODULES = {
  toolbar: [
    // [{header: [1, 2, false]}],
    ['bold', 'italic', 'underline', 'strike'],
    [{list: 'ordered'}, {list: 'bullet'}],
    // ['link'],
    ['clean'],
  ],
};

export const TEXT_ONLY_MODULES_WITHOUT_CLEAN = {
  toolbar: [
    ['link', 'bold', 'italic', 'underline', 'strike'],
    [{list: 'ordered'}, {list: 'bullet'}],
  ],
};

export const FORM_ONLY_MODULES = {
  toolbar: [
    [{header: [1, 2, false]}],
    ['bold', 'italic', 'underline', 'strike'],
    // [{list: 'ordered'}, {list: 'bullet'}],
    ['link'],
    // ['clean'],
  ],
};

interface IComponentState {
  value?: string;
  areBulletPointsAddedAsDefault?: boolean;
}

const UNORDERED_LIST_TEMPLATE = '<ul><li><br></li></ul>';
const ORDERED_LIST_TEMPLATE = '<ol><li><br></li></ol>'

export const RichTextEditor = (props: IProps) => {
  const {hideDefaultPlaceholder} = props;
  const [ componentState, setComponentState ] = useState<IComponentState>({
    value: props.valueStr,
    areBulletPointsAddedAsDefault: (props.addBulletPointsAsDefault && !props.valueStr?.trim?.().length) ? false : true,
  });

  useEffect(() => {
    if (props.valueStr !== componentState.value && componentState.value !== ORDERED_LIST_TEMPLATE) {
      setComponentState((prev) => ({ ...prev, value: props.valueStr }));
    }
  }, [props.valueStr]);

  const modules = props.isReadOnly ? { toolbar: [] } : (props.modules || {
    toolbar: [
      [{header: [1, 2, false]}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
      ['link', 'image'],
      ['clean'],
    ],
  });

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  if (props.addBulletPointsAsDefault && !componentState.areBulletPointsAddedAsDefault &&  !componentState.value?.trim()) {
    setComponentState((prev) => ({ ...prev, value: UNORDERED_LIST_TEMPLATE, areBulletPointsAddedAsDefault: true}));
  }

  return (
    <div data-testid='EnterText'>
      <ReactQuill
        className={
          'conversation-messaging rich-text-editor ' +
          (props.isReadOnly
            ? 'read-only'
            : props?.hideBottomBorder
              ? 'hideBottomBorder'
              : '')
        }
        style={props.style}
        theme="snow"
        readOnly={props.isReadOnly}
        value={componentState.value}
        onChange={(value, delta, source, editor) => {
          if (source === 'api') {
            return;
          }
          setComponentState((prev) => ({
            ...prev,
            value: value,
            areBulletPointsAddedAsDefault: true,
          }));
          const textValue = editor.getText();
          if (textValue?.trim()?.length) {
            props.onChangesValue?.(value);
          } else {
            props.onChangesValue?.('');
          }
        }}
        modules={modules}
        formats={formats}
        placeholder={props.placeholder || (hideDefaultPlaceholder ? '' : 'Please enter text')}
      />
    </div>
  );
};
