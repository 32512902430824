import {useState} from 'react';
import {CARE_STUDIO_NEST_URL} from '../../constants/Configs';
import CareStudioService from '../../services/CommonService/CareStudioService';
import {SUBMIT_FORM_RESPONSE} from '../../services/Forms/FormsQueries';
import {parse, print} from 'graphql';

enum Status {
  NONE = 'none',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

const useFormSubmitRestAPI = () => {
  const [status, setStatus] = useState<Status>(Status.NONE);
  // to acheive singleton pattern
  const [careStudioService] = useState(() =>
    CareStudioService.getCareStudioServiceInstance()
  );

  const submitFrom = async (variables: any): Promise<{
    id: string;
  } | undefined> => {
    const body = {
      params: variables,
    };
    setStatus(Status.LOADING);
    try {
      const response = await careStudioService.fromSubmitRestAPI.post(``, body);
      setStatus(Status.SUCCESS);
      return response.data;
    } catch (error) {
      setStatus(Status.ERROR);
      return;
    }
  };

  return {
    submitFrom,
    isLoading: status === Status.LOADING,
    isSuccess: status === Status.SUCCESS,
    isError: status === Status.ERROR,
  };
};

export default useFormSubmitRestAPI;
