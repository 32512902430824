import React from 'react';
import {Colors} from '../../../styles';
import {ICommonSvgProps} from './interfaces';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';
import {StyleSheet} from 'react-native';

const BrandedSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';
  return (
    <Svg
      style={styles.svg}
      fill="none"
      viewBox="0 0 20 20"
    >
      <G
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_622_129478)"
      >
        <Path d="M17.5 1.248h-15c-.69 0-1.25.56-1.25 1.25v15c0 .69.56 1.25 1.25 1.25h15c.69 0 1.25-.56 1.25-1.25v-15c0-.69-.56-1.25-1.25-1.25zM1.25 6.247h17.5M6.25 6.247v12.5M18.75 12.498H6.25"></Path>
      </G>
      <Defs>
        <ClipPath id="clip0_622_129478">
          <Path fill="#fff" d="M0 0H20V20H0z"></Path>
        </ClipPath>
      </Defs>
    </Svg>
  );
};

const styles = StyleSheet.create({
  svg: {
    width: 'inherit',
    height: 'inherit',
  },
});

export default React.memo(BrandedSvg);
