import {Button as AntButton, Drawer, TreeSelect, Upload} from 'antd';
import {
  Button,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Spinner,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useState} from 'react';
import {Dimensions} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {BUTTON_TYPE} from '../../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import CommerceCommonService from '../../../../../../../services/CommonService/CommerceCommonService';
import {Colors} from '../../../../../../../styles';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../../../common/ModalActionBtn/ModalActionBtn';
import {RichText} from '../../../../../TeamInbox/Conversations/MessagingWindow/RichText/RichText';
import {styles} from '../AddNewTaxonView/AddNewTaxonStyles';
import {
  getFoamtedParamLink,
  getFormatedResponseObject,
} from './updateTaxonsUtils';

const UpdateTaxonView = (props: any) => {
  const taxon = props.taxon;
  const catagoryList = props.catagoryList;
  const taxonmoy = props.taxonmoy;
  const formatedObj = getFormatedResponseObject(taxon, taxonmoy);
  const paramLink = taxon.rawObjRes.attributes.permalink;
  const [showModal, setShowModal] = useState(true);
  const closeModal = () => {
    setShowModal(false);
    props.onActionPerformed(COMMON_ACTION_CODES.RESET);
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    name: '',
  });
  const [updatedBodyData, setUpdatedBodyData] = useState(formatedObj as any);
  const onUpdateAction = () => {
    setLoading(true);
    delete updatedBodyData.tempParam;
    const commerceCommonService =
      CommerceCommonService.getCommerceCommonServiceInstance();
    const axiosService = commerceCommonService.axiosService;
    axiosService
      .patch(`/commerce/api/v2/platform/taxons/${taxon.id}`, updatedBodyData)
      .then(() => {
        props.onActionPerformed(COMMON_ACTION_CODES.ALL);
        setShowModal(false);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError({...error, name: 'Error in Deleting Taxonomy'});
      });
  };
  const {width} = Dimensions.get('window');
  const finalWidth = width / 3;
  return (
    <>
      <Drawer
        visible={showModal}
        width={finalWidth}
        onClose={() => closeModal()}
        title={
          <>
            <HStack
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text>
                <DisplayText
                  size={'xslMedium'}
                  textType={'Heading'}
                  textLocalId={'updateTaxon'}
                />
              </Text>
              <Button.Group justifyContent={'flex-end'}>
                <ModalActionBtn
                  btnText="cancel"
                  btnStype={BUTTON_TYPE.SECONDARY}
                  onClickAction={() => {
                    closeModal();
                  }}
                  customStyle={{marginRight: 20}}
                />
                <ModalActionBtn
                  btnText="update"
                  btnStype={BUTTON_TYPE.PRIMARY}
                  onClickAction={() => {
                    onUpdateAction();
                  }}
                />
              </Button.Group>
            </HStack>
          </>
        }
      >
        {loading ? <Spinner size="lg" style={styles.spinnerStyle} /> : <></>}
        <View flex={1}>
          <HStack justifyContent={'space-between'}>
            <VStack flex={0.5} paddingX={2}>
              <FormControl
                style={styles.formElement}
                isRequired
                isInvalid={!!error.name}
              >
                <FormControl.Label style={styles.formLabel}>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{color: Colors.Custom.Gray700}}
                    textLocalId={'name'}
                  />
                </FormControl.Label>
                <Input
                  value={updatedBodyData.name}
                  placeholder="Taxon name"
                  onChangeText={(value: any) => {
                    setUpdatedBodyData({...updatedBodyData, name: value});
                  }}
                />
                {error.name && (
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {error.name}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
            </VStack>
            <VStack flex={0.5} paddingX={2}>
              <View>
                <FormControl
                  style={styles.formElement}
                  isRequired
                  isInvalid={!!error.name}
                >
                  <FormControl.Label style={styles.formLabel}>
                    <DisplayText
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray700}}
                      textLocalId={'selectCategory'}
                    />
                  </FormControl.Label>
                  <TreeSelect
                    showSearch
                    style={{width: '100%'}}
                    value={updatedBodyData.parent_id}
                    treeData={catagoryList}
                    dropdownStyle={{maxHeight: 200}}
                    placeholder="Please select"
                    allowClear
                    treeDefaultExpandAll
                    onChange={(value: any) => {
                      setUpdatedBodyData({
                        ...updatedBodyData,
                        parent_id: value,
                      });
                    }}
                  />
                </FormControl>
              </View>
            </VStack>
          </HStack>
          <HStack>
            <VStack flex={1} paddingX={2}>
              <FormControl
                style={styles.formElement}
                isRequired
                isInvalid={!!error.name}
              >
                <FormControl.Label style={styles.formLabel}>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{color: Colors.Custom.Gray700}}
                    textLocalId={'permaLinkL'}
                  />
                </FormControl.Label>
                <InputGroup
                  w={{
                    base: '100%',
                  }}
                >
                  <InputLeftAddon
                    children={`t/${getFoamtedParamLink(paramLink).rest}`}
                  />
                  <Input
                    w={{
                      base: '100%',
                    }}
                    placeholder="Enter Params Link"
                    value={updatedBodyData.tempParam}
                    onChangeText={(value: any) => {
                      const updatedParamLink = `${
                        getFoamtedParamLink(paramLink).rest
                      }${value}`;
                      setUpdatedBodyData({
                        ...updatedBodyData,
                        tempParam: value,
                        permalink: updatedParamLink,
                      });
                    }}
                  />
                </InputGroup>
              </FormControl>
            </VStack>
          </HStack>
          <HStack>
            {/* <VStack  px={2}> */}
            <View flex={1} px={2}>
              <FormControl
                style={styles.formElement}
                isRequired
                isInvalid={!!error.name}
              >
                <FormControl.Label style={styles.formLabel}>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{color: Colors.Custom.Gray700}}
                    textLocalId={'description'}
                  />
                </FormControl.Label>
                {true && (
                  <View
                    borderWidth={1}
                    borderColor={Colors.Custom.IconColorGray}
                    borderRadius={16}
                  >
                    <RichText
                      valueStr={updatedBodyData.description}
                      isEnterToSubmit={false}
                      onRichTextAction={() => {
                        // onRichTextAction
                      }}
                      onChangesValue={(value: any) => {
                        // setRichTextValue(value);
                        setUpdatedBodyData({
                          ...updatedBodyData,
                          description: value,
                        });
                      }}
                    />
                  </View>
                )}
              </FormControl>
              {/* </VStack> */}
            </View>
          </HStack>
          <HStack justifyContent={'space-between'}>
            <VStack flex={0.5} paddingX={2}>
              <FormControl
                style={styles.formElement}
                isRequired
                isInvalid={!!error.name}
              >
                <FormControl.Label style={styles.formLabel}>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{color: Colors.Custom.Gray700}}
                    textLocalId={'metaTitle'}
                  />
                </FormControl.Label>
                <Input
                  value={updatedBodyData.meta_title}
                  placeholder="Enter meta_title"
                  onChangeText={(value: any) => {
                    setUpdatedBodyData({
                      ...updatedBodyData,
                      meta_title: value,
                    });
                  }}
                />
                {error.name && (
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {error.name}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
            </VStack>
            <VStack flex={0.5} paddingX={2}>
              <FormControl
                style={styles.formElement}
                isRequired
                isInvalid={!!error.name}
              >
                <FormControl.Label style={styles.formLabel}>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{color: Colors.Custom.Gray700}}
                    textLocalId={'metaKeywords'}
                  />
                </FormControl.Label>
                <Input
                  value={updatedBodyData.meta_keywords}
                  placeholder="Enter meta_keywords"
                  onChangeText={(value: any) => {
                    setUpdatedBodyData({
                      ...updatedBodyData,
                      meta_keywords: value,
                    });
                  }}
                />
                {error.name && (
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {error.name}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
            </VStack>
          </HStack>
          <HStack>
            <VStack flex={1} paddingX={2}>
              <FormControl
                style={styles.formElement}
                isRequired
                isInvalid={!!error.name}
              >
                <FormControl.Label style={styles.formLabel}>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{color: Colors.Custom.Gray700}}
                    textLocalId={'metaDescription'}
                  />
                </FormControl.Label>
                <Input
                  value={updatedBodyData.meta_description}
                  placeholder="Enter meta_description"
                  onChangeText={(value: any) => {
                    setUpdatedBodyData({
                      ...updatedBodyData,
                      meta_description: value,
                    });
                  }}
                />
                {error.name && (
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {error.name}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
            </VStack>
          </HStack>
          <HStack>
            <VStack flex={1} paddingX={2}>
              <FormControl
                style={styles.formElement}
                isRequired
                isInvalid={!!error.name}
              >
                <FormControl.Label style={styles.formLabel}>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{color: Colors.Custom.Gray700}}
                    textLocalId={'uploadBanner'}
                  />
                </FormControl.Label>
                <Upload
                  {...props}
                  onChange={(e) => {

                    const tempFileList = [...e.fileList];
                    setUpdatedBodyData({
                      ...updatedBodyData,
                      header_banner: tempFileList,
                    });
                  }}
                >
                  <AntButton icon={<AntIcon name="totop" />}>Upload</AntButton>
                </Upload>
                {error.name && (
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {error.name}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
            </VStack>
          </HStack>
        </View>
      </Drawer>
    </>
  );
};

export default UpdateTaxonView;
