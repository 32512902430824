import {Column, ColumnConfig} from '@ant-design/charts';
import moment from 'moment';
import {View, Text} from 'native-base';
import {Dimensions} from 'react-native';
import NodataViewTypeOne from '../../Analytics/EmployerAnalytics/SectionAndWidget/NoDataView/NodataViewTypeOne';
import {Colors} from '../../../../styles';
import React, {useState} from 'react';

const JourneyColumn = (props: {data?: any}) => {
  const width = Dimensions.get('window').width * 0.9;
  const [monthFilter, setMonthFilter] = useState(0);

  const colData = props?.data;

  const configStack: ColumnConfig = {
    appendPadding: 10,
    data:
      colData?.map?.((item: any) => {
        return {
          Patients: item.count,
          month: moment(item.month).format('MMM YY'),
        };
      }) || [],
    color: () => '#80A4D5',
    maxColumnWidth: 32,
    xField: 'month',
    yField: 'Patients',
    seriesField: 'type',
    // scrollbar: {
    //     type: 'horizontal',
    // },
    yAxis: {
      tickLine: {
        style: {
          fill: '#fff',
          stroke: '#fff',
        },
      },  
      label: {
        // ATTENTION: the below code is added to handle not showing decimal values in patient assigned chart in care journey analytics view
        formatter:(text:string) => {
          const num = parseFloat(text);
          if (Number.isInteger(num)) {
            return num.toString();
          } else {
            return "";
          }
        },
        style: {
          fontSize: 12,
          fontWeight: 400,
        },
      },
      //   title: {
      //     text: "graphYAxisTitle.title",
      //     description: "graphYAxisTitle.description",
      //     style: {
      //       fill: '#80A4D5'
      //     }
      //   },
      line: {
        style: {
          stroke: '#F2F4F7',
        },
      },
      grid: {
        alignTick: true,
        line: {
          type: 'line',
          style: {
            stroke: '#F2F4F7',
          },
        },
        closed: true,
      },
    },
    renderer: 'svg',
    xAxis: {
      tickLine: {
        style: {
          fill: '#fff',
          stroke: '#fff',
        },
      },
      //   title: {
      //     text: "graphXAxisTitle.title",
      //     description: "graphXAxisTitle.description",
      //     style: {
      //       fill: '#80A4D5'
      //     },
      //     offset: 60
      //   },
      label: {
        style: {
          fontSize: 10,
          fontWeight: 400,
        },        
      },
      line: {
        style: {
          stroke: '#F2F4F7',
        },
      },
    },
    legend: {
      position: 'top-right',
      layout: 'horizontal',
      itemSpacing: 3,
      itemName: {
        formatter(text: any) {
          return text?.toLocaleUpperCase();
        },
      },
      marker: {
        symbol: 'circle',
        style(style: any) {
          return {
            ...style,
            r: 4,
          };
        },
      },
    },
    label: {
      position: 'middle',
      style: {
        fill: 'transparent',
      },
      formatter: () => {
        return '';
      },
    },
    height: 400,
    width: width * 0.3,
    columnStyle(datum: any) {
      return {
        radius: [2, 2, 0, 0],
        fill: null,
      };
    },
    onReady: (plot: any) => {
      plot.on('element:click', (args: any) => {
        if (args?.data?.data?.type) {
          //onReady(args);
        }
      });
    },
  };

  return (
    <View flexDirection={'column'} style={{flex: 1}}>
      {colData?.length ? (
        <>
          <View
            flexDirection={'row'}
            justifyContent={'space-between'}
            style={{
              marginBottom: 20,
            }}
          >
            <View flexDirection={'row'} alignItems={'center'}>
              <View
                style={{
                  backgroundColor: '#80A4D5',
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  marginRight: 4,
                }}
              ></View>
              <Text
                size={'smNormal'}
                style={{
                  fontSize: 12,
                  color: Colors.Custom.Gray700,
                }}
              >
                No. of Patients Assigned
              </Text>
            </View>
          </View>
          <Column {...configStack} />
        </>
      ) : (
        <View
          style={{
            paddingTop: 30,
            width: '100%',
            height: 320,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {' '}
          <NodataViewTypeOne viewCode="TEXT_ICON" />
        </View>
      )}
    </View>
  );
};

export default React.memo(JourneyColumn);
