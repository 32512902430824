import React from 'react';
import {IContact} from '../../../../../TeamInbox/Conversations/interfaces';
import {Text} from 'native-base';

interface CommunicationTypeSavingsProps {
  columnData: any;
  columnConfig: any;
  contactData: IContact;
  contactDrillDownData: any;
  isOnFirstView: boolean;
  filterData?: any;
  onColumnActionPerformed?: (actionCode: string, actionData?: any) => void;
}
const CommunicationTypeSavings = (props: CommunicationTypeSavingsProps) => {
  const displayText = props.columnData?.data?.displayText || '-';
  return <Text>{displayText}</Text>;
};

export default React.memo(CommunicationTypeSavings);
