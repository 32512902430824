import {LineConfig} from '@ant-design/charts';
import {Line} from '@ant-design/plots';
import {Text} from 'native-base';
import React, { useMemo } from 'react';
import {Colors} from '../../../../../styles';
// import { Line } from '@ant-design/plots';
import {getFormattedDataForLineGraph} from '../EmployerUtils/FormateDataUtils';
import {IWidgetCardView} from '../interface';
import {getTenantForDemo} from '../tempDataFile';
import { ANALYTICS_RESOURCE } from '../../../../../constants';
import { getColorByPositiveStatus } from './utils';

const LineGraphWidget = (props: IWidgetCardView) => {
  const {sectionData, resourceData, loading, reusableData} = props;
  const formattedData = getFormattedDataForLineGraph(sectionData, resourceData);
  const isGraphColorInverted = sectionData.resourceCode === ANALYTICS_RESOURCE.NO_SHOWS;
  let positive = true;
  const isInCreased =  formattedData[0].scales  < formattedData[formattedData.length - 1].scales;
    if (isInCreased) {
      positive = true;
    } else {
      positive = false;
    }
  const config: LineConfig = {
    data: formattedData,
    // padding: 'auto',
    xField: 'Date',
    yField: 'scales',
    xAxis: false,
    yAxis: {
      grid: {
        line:{
          style: {
            lineDash: null
          }
        }
      },
      line: {
        style: {
          lineWidth: 0,
          fill: '#fff'
        }
      },
      label: {
        formatter(text, item, index) {
            return ''
        },
      },
      title: null
    },
    smooth: true,
    autoFit: true,
    tooltip: {
      customContent(title, data) {
        return title
      },
    },
    height: 90,
    width: 90,
    className: 'print-hide',
    lineStyle(datum) {
      return {
        stroke: getColorByPositiveStatus(positive || getTenantForDemo(), isGraphColorInverted),
        fill: getColorByPositiveStatus(positive || getTenantForDemo(),isGraphColorInverted),
        fillOpacity: 0.1,
      };
    },

  };
  const lineChartView = useMemo(() => {
    return (
      <>
        <Line {...config} />
      </>
    );
  }, [loading]);
  return (
    <>
      {loading ? (
        <></>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: 90,
            alignItems: 'center',
          }}
        >
          <main
            id={sectionData.resourceCode}
            className={props.isPrintPreview?"canvas-wrapper":''}
            style={{overflow: 'hidden', height: 'auto', position: 'relative', top: 8}}
          >
            <div>
            {lineChartView}
            </div>
          </main>
        </div>
      )}
    </>
  );
};
export default React.memo(LineGraphWidget);
