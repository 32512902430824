import { gql } from "@apollo/client";

export const GetAccountsData = gql`
query getSingleAccountDetails($id: bigint!)
{  account(id: $id) {  
  websiteLink  
  name 
  instagramLink 
  description
  subtitle
  twitterLink
  facebookLink
  logo
}
}
`;

export const GET_ACCOUNT_INFO_BY_ID = gql`
  query getAccountInfoById($id: bigint!) {
    accounts(where: {id: {_eq: $id}}) {
      accountSubdomainMap {
        id
        accountId
        accountUuid
        subDomain
        additionalAttributes
        accountToSubdomain {
          name
          uuid
          supportEmail
        }
      }
    }
  } 
`

export default {
    GetAccountsData,
};