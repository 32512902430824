import {Tooltip} from 'native-base';
import React from 'react';

const EyeHideSvg = (props: {isPrimary?: boolean}) => {
  return (
    <Tooltip label="Hidden For Employer" placement="top">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="13"
        fill="none"
        viewBox="0 0 14 13"
      >
        <path
          fill={props?.isPrimary?"#7F56D9":"#D0D5DD"}
          d="M6.75 7.867a1.75 1.75 0 001.743-1.903L6.597 7.86c.05.004.101.007.153.007zM12.542.703l-.667-.666a.125.125 0 00-.177 0L9.99 1.745c-.943-.481-2.002-.722-3.178-.722-3.003 0-5.244 1.564-6.722 4.692a.942.942 0 000 .805C.68 7.764 1.392 8.76 2.224 9.51L.57 11.164a.125.125 0 000 .176l.667.667a.125.125 0 00.177 0L12.542.88a.125.125 0 000-.177zM4 6.117A2.75 2.75 0 018.045 3.69l-.76.76a1.751 1.751 0 00-2.202 2.202l-.76.76A2.736 2.736 0 014 6.117z"
        ></path>
        <path
          fill={props?.isPrimary?"#7F56D9":"#D0D5DD"}
          d="M13.534 5.714c-.55-1.158-1.205-2.103-1.964-2.834L9.318 5.133a2.751 2.751 0 01-3.553 3.552l-1.91 1.91c.886.41 1.872.616 2.957.616 3.003 0 5.244-1.564 6.722-4.692a.942.942 0 000-.805z"
        ></path>
      </svg>
    </Tooltip>
  );
};

export default EyeHideSvg;
