import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  container: {
    width: 'max-content',
    borderWidth: 0,
    // borderColor: Colors.Custom.Gray100,
    // boxShadow:
    //   '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  },
  optionContainer: {
    padding: 8,
  },
});
