import React, { useMemo } from 'react';
import { Path, Svg, Circle, Defs, RadialGradient, Stop, LinearGradient } from 'react-native-svg';

const NoPatientFoundSvg = React.memo(() => {
  const svgContent = useMemo(() => (
    <Svg width="102" height="101" viewBox="0 0 102 101" fill="none">
      <Circle cx="51" cy="50.5" r="50" stroke="url(#paint0_radial_7052_728746)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1.46 1.46" />
      <Circle cx="51.0007" cy="50.4987" r="42.6667" stroke="url(#paint1_radial_7052_728746)" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1.46 1.46" />
      <Path d="M15.8545 50.6016C15.8545 31.2716 31.5245 15.6016 50.8545 15.6016C70.1845 15.6016 85.8545 31.2716 85.8545 50.6016C85.8545 69.9315 70.1845 85.6016 50.8545 85.6016C31.5245 85.6016 15.8545 69.9315 15.8545 50.6016Z" fill="url(#paint2_linear_7052_728746)" />
      <Path d="M44.6107 66.4718H57.3885M39.819 61.6801H62.1801M35.0273 56.8885H66.9718M50.9996 34.5273V36.1246M66.9718 50.4996H65.3746M36.6246 50.4996H35.0273M62.2932 39.2059L61.6658 39.8334M40.333 39.833L39.7055 39.2055M41.4162 50.4996C41.4162 45.2068 45.7068 40.9162 50.9996 40.9162C56.2923 40.9162 60.5829 45.2068 60.5829 50.4996C60.5829 52.93 59.6781 55.1491 58.1871 56.8385H43.8121C42.321 55.1491 41.4162 52.93 41.4162 50.4996Z" stroke="#8A94A8" strokeWidth="1.59722" strokeLinecap="round" />
      <Defs>
        <RadialGradient id="paint0_radial_7052_728746" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(51.0001 73.1082) rotate(-90.639) scale(78.4672)">
          <Stop stopColor="#A1AABA" />
          <Stop offset="1" stopColor="#E4E7EC" />
        </RadialGradient>
        <RadialGradient id="paint1_radial_7052_728746" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(51.0007 69.791) rotate(-90.639) scale(66.9587)">
          <Stop stopColor="#A1AABA" />
          <Stop offset="1" stopColor="#E4E7EC" />
        </RadialGradient>
        <LinearGradient id="paint2_linear_7052_728746" x1="26.0002" y1="23.5" x2="74.0002" y2="84.25" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#F9FAFC" />
          <Stop offset="1" stopColor="#F1F2F3" />
        </LinearGradient>
      </Defs>
    </Svg>
  ), []);

  return svgContent;
});

export default NoPatientFoundSvg;