import {
  Button,
  Checkbox,
  FormControl,
  HStack,
  Input,
  Modal,
  Select,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useEffect, useRef, useState} from 'react';
import {months} from '../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {attributions} from '../../../../../dummyData/Sales';
import {
  members,
  usersData,
} from '../../../../../dummyData/Sales/pipelineModalData';
import {tagColorList} from '../../../Contacts/Tags/TagsModal';
import {ColorSelector} from '../../../Contacts/Tags/TagsModal/ColorSelector';
import AddStagesView from './AddStagesView/AddStagesView';
import {style} from './PipelineModalStyles';
import UserOrMemberInput from './UserOrMemberInput';

// const formattedLeadData = {
//   pName: '',
//   visibility: '',
//   board: '',
//   status: 'Active',
//   created_by: '',
//   attribution: '',
//   attrName: '',
//   fractionalPart: '',
//   assignedUser: false,
//   created_at: Date.now(),
// };

const PipelineModal = React.forwardRef((props: any, ref: any) => {
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors]: any = useState({});
  const [color, setColor] = useState('#808080');
  const stageRef: any = useRef();
  // let formObject: any = {};
  const [formObject, setFormObject] = useState({} as any);
  const attribution = '';
  React.useImperativeHandle(ref, () => ({
    openModal: () => {
      setShowModal(true);
    },
  }));

  const handleFromChange = (key: any, value: any) => {
    formObject[key] = value;
  };

  const validate = (formObj: any) => {
    if (!formObj.pName) {
      setErrors({
        ...errors,
        pName: 'Field is required',
        visibility: '',
      });
      return false;
    } else if (formObj.pName.length < 3) {
      setErrors({
        ...errors,
        pName: 'Filed is too short',
        visibility: '',
        board: '',
        fractionalPart: '',
      });
      return false;
    } else if (formObj.visibility === undefined) {
      setErrors({
        ...errors,
        visibility: 'Filed is not selected',
        pName: '',
        board: '',
        fractionalPart: '',
      });
      return false;
    } else if (formObj.board === undefined) {
      setErrors({
        ...errors,
        board: 'Filed is not selected',
        visibility: '',
        pName: '',
        fractionalPart: '',
      });
      return false;
    } else if (isNaN(formObj.fractionalPart)) {
      setErrors({
        ...errors,
        board: '',
        visibility: '',
        pName: '',
        fractionalPart: 'Please enter Number',
      });
      return false;
    } else if (formObj.fractionalPart > 8) {
      setErrors({
        ...errors,
        board: '',
        visibility: '',
        pName: '',
        fractionalPart: 'Enter number in 1 to 9',
      });
      return false;
    } else {
      setErrors({
        ...errors,
        pName: '',
      });
    }
    return true;
  };

  const onSubmit = () => {
    if (validate(formObject)) {
      if (props.editPipeline !== undefined) {
        props.onActionPerformed(COMMON_ACTION_CODES.EDIT, formObject);
        clearModalData();
        closeModal();
      } else {
        const cDate = new Date();
        const month = months[cDate.getMonth()];
        const day = cDate.getDay();
        const year = cDate.getFullYear();
        formObject['created_at'] = `${month} ${day}, ${year}`;
        formObject['created_by'] = 'Bhavesh Patel';
        formObject['status'] = 'Active';
        formObject['tagColor'] = color;
        formObject['key'] = Date.now();
        props.onActionPerformed(COMMON_ACTION_CODES.SAVE, formObject);

        clearModalData();
        closeModal();
      }
    } else {
    }
  };

  const clearModalData = () => {
    setFormObject({});
  };

  const closeModal = () => {
    props.onActionPerformed(COMMON_ACTION_CODES.CANCEL, formObject);
    clearModalData();
    setErrors({});
    setShowModal(false);
  };
  const getStagesData = (stages: any) => {
    formObject['stages'] = stages;
  };
  const triggerStageComp = () => {
    if (stageRef.current) {
      stageRef.current.sendStage();
    }
  };

  const findBoardId = (name: string) => {
    for (const item of props.boardList) {
      if (item.title == name) {
        const board = item;
        return board.id;
      }
    }
  };

  useEffect(() => {
    clearModalData();
    if (props.editPipeline !== undefined) {
      setFormObject(props.editPipeline);
    }
  }, [props.editPipeline]);

  return (
    <Modal
      isOpen={showModal}
      onClose={() => {
        closeModal();
      }}
      style={style.modalContainer}
    >
      <Modal.Content maxWidth="100%">
        <Modal.CloseButton />
        <Modal.Header style={style.headerText}>
          {props.editPipeline ? 'Edit Pipeline' : 'Add Pipeline'}
        </Modal.Header>
        <Modal.Body>
          <FormControl isRequired style={style.formElement}>
            <FormControl.Label style={style.formLabel}>Name</FormControl.Label>
            <Input
              variant="outline"
              placeholder="Enter Name"
              defaultValue={formObject.pName}
              // value={formObject.pName}
              onChangeText={(value: any) => {
                handleFromChange('pName', value);
              }}
            />
            {errors.pName != '' && (
              <View>
                <Text color={'red.500'}>{errors.pName}</Text>
              </View>
            )}
          </FormControl>
          <HStack flex={1} justifyContent={'space-between'}>
            <VStack flex={0.8}>
              <FormControl isRequired style={style.formElement}>
                <FormControl.Label style={style.formLabel}>
                  Visibility
                </FormControl.Label>
                <Select
                  variant="outline"
                  defaultValue={formObject.visibility}
                  selectedValue={formObject.visibility}
                  accessibilityLabel="Visibility"
                  placeholder="VISIBILITY"
                  onValueChange={(value) => {
                    handleFromChange('visibility', value);
                    setFormObject({...formObject, visibility: value});
                  }}
                >
                  <Select.Item label="Public" value="public" />
                  <Select.Item label="Private" value="private" />
                </Select>
                {errors.visibility != '' && (
                  <View>
                    <Text color={'red.500'}>{errors.visibility}</Text>
                  </View>
                )}
              </FormControl>
            </VStack>
            <VStack flex={0.2} mt={-2} ml={1}>
              <ColorSelector
                color={formObject.tagColor ? formObject.tagColor : color}
                title="COLOR"
                colorList={tagColorList}
                onBtnPress={(color: string) => setColor(color)}
                onChangeHex={(text) => setColor('#' + text)}
              />
            </VStack>
          </HStack>
          {formObject.visibility == 'private' ? (
            <FormControl style={style.formElement} mt={2}>
              <FormControl.Label style={style.formLabel}>
                Members
              </FormControl.Label>
              <UserOrMemberInput
                users={formObject.members}
                listData={members}
                onActionPerform={(data: any) => {
                  setFormObject({...formObject, members: data});
                }}
              />
            </FormControl>
          ) : (
            ''
          )}
          <FormControl isRequired style={style.formElement}>
            <FormControl.Label style={style.formLabel}>Board</FormControl.Label>
            <Select
              variant="outline"
              defaultValue={formObject.board}
              selectedValue={formObject.board}
              accessibilityLabel="Board"
              placeholder="BOARD"
              onValueChange={(value) => {
                handleFromChange('board', value);
                handleFromChange('boardId', findBoardId(formObject.board));
              }}
            >
              {props.boardList
                ? props.boardList.map((item: any, index: any) => {
                    return (
                      <Select.Item
                        key={index}
                        label={item.title}
                        value={item.title}
                      />
                    );
                  })
                : 'empty'}
            </Select>
            {errors.board != '' && (
              <View>
                <Text color={'red.500'}>{errors.board}</Text>
              </View>
            )}
          </FormControl>

          <HStack flex={1} justifyContent={'space-between'}>
            <VStack flex={0.8}>
              <FormControl style={style.formElement}>
                <HStack flex={1} justifyContent={'space-between'} mb={2}>
                  <VStack flex={0.8}>
                    <FormControl.Label style={style.formLabel}>
                      Number Configuration
                    </FormControl.Label>
                  </VStack>
                  <VStack flex={0.2}>
                    <Select
                      variant="outline"
                      accessibilityLabel="Attribution"
                      placeholder="Attribution"
                      defaultValue={formObject.attribution}
                      onValueChange={(value) => {
                        handleFromChange('attribution', value);
                        attribution.concat(value);
                      }}
                    >
                      {attributions.map((item, index) => {
                        return (
                          <Select.Item
                            key={index}
                            label={item.label}
                            value={item.value}
                          />
                        );
                      })}
                    </Select>
                  </VStack>
                </HStack>
                <Input
                  variant="outline"
                  placeholder="Choose an attribute or any number you prefer"
                  defaultValue={formObject.attrName}
                  onChangeText={(value) => {
                    handleFromChange('attrName', value);
                  }}
                />
              </FormControl>
            </VStack>
            <VStack flex={0.2} ml={1}>
              <FormControl style={style.formElement}>
                <FormControl.Label style={style.formLabel}>
                  Fractional Part
                </FormControl.Label>
                <Input
                  keyboardType={'numeric'}
                  mt={4}
                  defaultValue={formObject.fractionalPart}
                  variant="outline"
                  placeholder="1-8"
                  onChangeText={(value) => {
                    handleFromChange('fractionalPart', value);
                  }}
                />
                {errors.fractionalPart != '' && (
                  <View>
                    <Text color={'red.500'}>{errors.fractionalPart}</Text>
                  </View>
                )}
              </FormControl>
            </VStack>
          </HStack>

          <FormControl mt={4}>
            <HStack alignItems={'center'}>
              <Text fontSize={12} ml={1}>
                Show Only The User's Assigned(Created) Deal
              </Text>
              <Checkbox
                value="ASSIGNED_USER"
                defaultIsChecked={formObject.assignedUser}
                isChecked={formObject.assignedUser}
                onChange={(value) => {
                  handleFromChange('assignedUser', value);
                  setFormObject({...formObject, assignedUser: value});
                  return;
                }}
                style={style.checkboxStyle}
              ></Checkbox>
            </HStack>
          </FormControl>
          {formObject.assignedUser ? (
            <FormControl style={style.formElement} mt={2}>
              <FormControl.Label style={style.formLabel}>
                Users Eligible To See All Deal
              </FormControl.Label>
              <UserOrMemberInput
                users={formObject.users}
                listData={usersData}
                onActionPerform={(data: any) => {
                  setFormObject({...formObject, users: data});
                }}
              />
            </FormControl>
          ) : (
            ''
          )}

          <FormControl mt={4}>
            <FormControl.Label>Stages</FormControl.Label>
            <AddStagesView
              sendStagesData={getStagesData}
              ref={stageRef}
              stages={formObject.stages}
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button
              variant="ghost"
              colorScheme="blueGray"
              onPress={() => {
                closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              onPress={() => {
                triggerStageComp();
                onSubmit();
              }}
              _text={{
                color: 'white',
              }}
            >
              Save
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
});

export default PipelineModal;
