
export const GET_IMPORT_CONTACT_LOG = `
  query GetImportContactLog($tenantId: uuid!) {
    bulkImportSummaries (where: {
      tenantId: {_eq: $tenantId}
      isInProgress: {_eq: false}
    }, order_by: {createdOn: desc}) {
      tenantId
      batchId
      createdOn
      totalCount
      passCount
      failedCount
      sqsType
      fileName
    }
  }
`;

export const GET_FAILED_IMPORTED_CONTACTS = `
  query GetFailedImportedContacts($batchId: uuid!, $tenantId: uuid!) {
    sqsLogs (where: {
      batchId: {_eq: $batchId},
      tenantId: {_eq: $tenantId},
      status: {_neq: "CREATED"}
    }, order_by: {createdOn: desc}) {
      tenantId
      batchId
      createdOn
      messageBody
      metadata
      status
    }
  }
`;

export default {
  GET_IMPORT_CONTACT_LOG,
};
