import { StyleSheet } from 'react-native';
import { Colors } from '../../../../styles/Colors';

export const styles = StyleSheet.create({
    headerRightBox:{
        alignItems:'center',
        flexDirection:'row'
    },
    headerContainer: {
        padding: 16,
        paddingVertical: 12,
        borderBottomWidth: 1,
        borderBottomColor: Colors.Custom.Gray200,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    datePickerContainer :{
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 12,
        borderWidth: 1,
        borderColor: '#e4e7ec',
        borderRadius: 8,
        backgroundColor: '#fff',
        overflow: 'hidden',
        height: 40
    },
    countWithTextContainer: {
        paddingHorizontal: 16,
        paddingVertical: 20,
        borderWidth: 1,
        borderColor: Colors.Custom.Gray200,
        borderRadius: 8,
        marginBottom: 12,
        flex: 1
    },
    pieChartContainer : {
        paddingHorizontal: 16,
        paddingVertical: 12,
        borderWidth: 1,
        borderColor: Colors.Custom.Gray200,
        borderRadius: 8,
        flex: 1,
        marginBottom: 12,
        marginHorizontal: 12
    },
    lineChartContainer : {
        paddingHorizontal: 16,
        paddingVertical: 12,
        borderWidth: 1,
        borderColor: Colors.Custom.Gray200,
        borderRadius: 8,
        flex: 1,
        marginBottom: 12
    },
    formResponseContainer: {
        padding: 16,
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: Colors.Custom.Gray200,
        borderRadius: 6,
        marginBottom: 16,
        flexDirection: 'row'
    }

});
