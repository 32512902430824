import React from "react";

const EnrolledForCareSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 40 35"
    >
      <g clipPath="url(#clip0_770_112083)">
        <path
          fill={svgColors}
          d="M17.5 0h4.375c1.21 0 2.188.978 2.188 2.188v4.374c0 1.21-.978 2.188-2.188 2.188H17.5a2.185 2.185 0 01-2.188-2.188V2.188C15.313.978 16.29 0 17.5 0zM4.375 4.375h8.75v3.281a3.282 3.282 0 003.281 3.282h6.563a3.282 3.282 0 003.281-3.282V4.375H35a4.379 4.379 0 014.375 4.375v21.875A4.379 4.379 0 0135 35H4.375A4.379 4.379 0 010 30.625V8.75a4.379 4.379 0 014.375-4.375zm7.656 25.519c0 .403.328.731.732.731h13.85a.732.732 0 00.73-.731A3.642 3.642 0 0023.7 26.25h-8.025a3.642 3.642 0 00-3.644 3.644zm7.656-5.831a4.379 4.379 0 004.375-4.375 4.379 4.379 0 00-4.375-4.375 4.379 4.379 0 00-4.375 4.374 4.379 4.379 0 004.376 4.375z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_770_112083">
          <path fill="#fff" d="M0 0H39.375V35H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default React.memo(EnrolledForCareSvg);
