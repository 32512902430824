import {useLazyQuery} from '@apollo/client';
import {
  HStack,
  Spinner,
  Text,
  TextArea,
  VStack,
  View,
  useToast,
} from 'native-base';
import React, {useContext, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {
  AUTO_RESPONSE_ASSIGNEE_TYPES,
  AUTO_RESPONSE_TYPES,
  BUTTON_TYPE,
} from '../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {
  AVAILABILITY_TYPE_CODES,
  MLOV_CATEGORY,
} from '../../../constants/MlovConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {ScheduleQueries} from '../../../services';
import UserPracticeLocationQueries from '../../../services/Location/UserPracticeLocationQueries';
import {IAccountPracticeLocationResp} from '../../../services/Location/interfaces';
import TeamQueries from '../../../services/Team/TeamQueries';
import {Colors} from '../../../styles';
import {getAccountUUID, getUserUUID} from '../../../utils/commonUtils';
import {ToastType, showToast} from '../../../utils/commonViewUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {ModalActionAntSelect} from '../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {IAccountLocation} from '../Contacts/TeamMembers/interfaces';
import {
  addOrUpdateAutoResponse,
  addTimeZonesToAvailabilities,
  getAutoResponsesFromApi,
} from './AutoReplyViewUtils';
import {IAutoResponseConfig, IPracticeAvailabilityConfig} from './interfaces';

interface IPatientChatAutoResponseView {
  selectedLocationId: string;
  locationData: any[];
}

const PatientChatAutoResponseView = (props: IPatientChatAutoResponseView) => {
  const [state, setState] = React.useState({
    loading: false,
    isSubmitting: false,
    reply: '',
    responseId: '',
    availabilities: [] as IPracticeAvailabilityConfig[],
    assigneeId: '',
  });
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const accountUuid = getAccountUUID();
  const userUuid = getUserUUID();
  const toast = useToast();

  const availabilityTypes =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.AVAILABILITY_TYPE
    ) || [];

  const practiceScheduleAvailabilityTypeId = getMlovIdFromCode(
    availabilityTypes,
    AVAILABILITY_TYPE_CODES.PRACTICE_SCHEDULE
  );

  const [getAvailabilitiesAPI] = useLazyQuery(
    ScheduleQueries.GET_PRACTICE_SCHEDULE
  );

  const [getAccountLocationFromPracticeLocation] = useLazyQuery(
    UserPracticeLocationQueries.GetAccountLocationFromPracticeLocation
  );

  const [getAccountPracticeLocations] =
    useLazyQuery<IAccountPracticeLocationResp>(TeamQueries.GetLocations, {
      variables: {accountUuid: accountUuid},
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    });

  const getPracticeAvailabilityWhereCondition = (locationId: string) => {
    let whereCondition: any = {};
    whereCondition = {
      isDeleted: {_eq: false},
      typeId: {_eq: practiceScheduleAvailabilityTypeId},
    };

    whereCondition.userId = {_is_null: true};
    whereCondition.locationId = {
      _eq: locationId,
    };
    return whereCondition;
  };

  const [getTimezonesByUuids] = useLazyQuery(
    ScheduleQueries.GET_TIMEZONES_BY_UUIDS
  );

  const getAutoResponseConfig = (): IAutoResponseConfig => {
    const locationData = props?.locationData?.find((item) => item?.practiceLocationId === state.assigneeId)
    return {
      id: state.responseId,
      inboxId: -1,
      accountId: accountUuid,
      accountLocationUuid: locationData?.uuid || state.assigneeId,
      practiceAvailabilityConfig: state.availabilities,
      replyText: state.reply,
      replyType: AUTO_RESPONSE_TYPES.CHAT,
      userId: userUuid,
      assigneeType: AUTO_RESPONSE_ASSIGNEE_TYPES.LOCATION,
      assigneeUuid: locationData?.uuid || state.assigneeId,
    };
  };

  const errorHandler = (error: any) => {
    showToast(
      toast,
      'Error in saving responses. Please try again later',
      ToastType.error
    );
    setState((prev) => {
      return {
        ...prev,
        isSubmitting: false,
      };
    });
  };

  const onSubmit = async () => {
    setState((prev) => {
      return {
        ...prev,
        isSubmitting: true,
      };
    });
    try {
      const body = getAutoResponseConfig();
      const availabilities = await getAvailabilitiesData();
      body.practiceAvailabilityConfig = availabilities || [];
      const response = await addOrUpdateAutoResponse({body});
      const data = response.data;
      if (!data?.id) {
        errorHandler({});
        return;
      }
      showToast(toast, 'Responses saved successfully', ToastType.success);
      setState((prev) => {
        return {
          ...prev,
          isSubmitting: false,
          responseId: data.id,
        };
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const getAvailabilitiesData = async () => {
    if (!state.assigneeId) {
      return [];
    }
    const accountLocationResponse =
      await getAccountLocationFromPracticeLocation({
        variables: {
          practiceLocationId: state.assigneeId,
        },
      });
    const accountLocationUuid =
      accountLocationResponse?.data?.accountLocations?.[0]?.uuid;
    if (!accountLocationUuid) {
      return [];
    }
    const availabilitiesResponse = await getAvailabilitiesAPI({
      variables: {
        whereCondition: getPracticeAvailabilityWhereCondition(accountLocationUuid),
      },
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    });
    const timezoneIds = availabilitiesResponse.data.schedules.map(
      (item: any) => item.timezoneId
    );
    const timezoneResponse = await getTimezonesByUuids({
      variables: {
        uuids: timezoneIds,
      },
    });
    const availabilities = addTimeZonesToAvailabilities({
      timezones: timezoneResponse.data.timezones,
      availabilities: availabilitiesResponse.data.schedules || [],
    });
    return availabilities;
  };

  const getPracticeLocationsAndAutoResponseData = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const response = await getAccountPracticeLocations();
    const locationList = response.data?.accountLocations || [];
    const locationData: any[] = [];
    locationData.push(
      ...locationList.map((location: IAccountLocation) => {
        return {
          practiceLocationId: location?.uuid || '',
          practiceLocationName: location?.practiceLocation?.name || '',
        };
      })
    );
    setState((prev) => {
      return {
        ...prev,
        loading: false,
        locationData: locationData,
      };
    });
  };

  const getAutoResponseFromLocation = async () => {
    if (!state.assigneeId) {
      return;
    }
    const locationData = props?.locationData?.find((item) => item?.practiceLocationId === state.assigneeId)
    try {
      const response = await getAutoResponsesFromApi({
        inboxId: -1,
        assigneeUuid: locationData ?.uuid || state.assigneeId,
      });
      setState((prev) => {
        return {
          ...prev,
          reply: response?.replyText || '',
          availabilities: response?.practiceAvailabilityConfig || [],
          responseId: response?.id || '',
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
        };
      });
    }
  };

  useEffect(() => {
    //getPracticeLocationsAndAutoResponseData();
  }, []);

  useEffect(() => {
    getAutoResponseFromLocation();
  }, [state.assigneeId]);

  return (
    <VStack>
      <HStack mt={3}>
        <HStack>
          <VStack>
            <Text fontSize={18}>Auto Reply Settings</Text>
            <View>
              <Text
                style={{
                  width: 600,
                  color: Colors.Custom.Gray500,
                }}
              >
                Auto Reply settings empower you to send automated responses to
                patient app chats during out-of-office hours. Tailor your
                messages based on location availability. When a patient sends a
                message to your practice, the system checks their mapped
                location's availability and sends the mapped Auto Reply message
                accordingly.
              </Text>
            </View>
          </VStack>
        </HStack>
      </HStack>

      <VStack mt={4}>
        <View style={{}}>
          <Text>Location</Text>
        </View>

        <View
          style={{
            width: 600,
          }}
        >
          {state.loading ? (
            <View
              width={600}
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </View>
          ) : (
            <View>
              <ModalActionAntSelect
                isRequired={false}
                allowClear={false}
                // label={''}
                showSearch={true}
                placeholder="Select location"
                defaultValue={state.assigneeId || undefined}
                filterOption={(input: string, option: any) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value: any) => {
                  setState((prev) => {
                    return {
                      ...prev,
                      assigneeId: value,
                    };
                  });
                }}
                customStyle={{width: 600}}
                data={props?.locationData}
                optionProps={{
                  key: 'practiceLocationId',
                  value: 'practiceLocationId',
                  label: 'practiceLocationName',
                }}
                marginTop={2}
              />
              <Text
                fontSize={12}
                color={'warning.600'}
                style={{
                  marginTop: 3,
                }}
              >
                Ensure that the practice location is configured with the
                corresponding practice availability
              </Text>

              <View
                style={{
                  marginTop: 8,
                }}
              >
                <View style={{}}>
                  <Text>Automatic - Reply message</Text>
                </View>
                <TextArea
                  marginTop={2}
                  width={600}
                  placeholder='Enter text here...'
                  placeholderTextColor={Colors.Custom.Gray300}
                  value={state.reply}
                  onChangeText={(text) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        reply: text,
                      };
                    });
                  }}
                />
              </View>
              <View
                style={{
                  marginTop: 10,
                }}
              >
                <FoldButton
                  customProps={{
                    btnText: 'Save',
                  }}
                  nativeProps={{
                    variant: BUTTON_TYPE.PRIMARY,
                    style: {
                      width: 'fit-content',
                    },
                    isLoading: state.isSubmitting,
                    onPress() {
                      onSubmit();
                    },
                  }}
                />
              </View>
            </View>
          )}
        </View>
      </VStack>
    </VStack>
  );
};

export default PatientChatAutoResponseView;
