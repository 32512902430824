import {
  LazyQueryExecFunction,
  OperationVariables,
  QueryResult
} from '@apollo/client';
import {
  AppointmentTypeGraphControllerArgs,
  AppointmentTypeGraphData,
  AppointmentTypeGraphState, SingleContact
} from './interfaces';

export class AppointmentTypeGraphController {
  showNoEmployerText: boolean;
  showSpinner: boolean;
  showNoVisitText: boolean;

  private state: AppointmentTypeGraphState;
  private accountId: string | number;
  private employerId?: string;
  private setState: React.Dispatch<
    React.SetStateAction<AppointmentTypeGraphState>
  >;
  private getAppointmentTypes: LazyQueryExecFunction<
    any,
    {
      tenantId: string;
    }
  >;
  private setIsShowAppointmentTypesGraph: any;
  private getOnlyContactUuidByEmployerBoolExp: LazyQueryExecFunction<
    any,
    {
      where: any;
    }
  >;
  private getContactUuidsByAppointmentTypes: LazyQueryExecFunction<
    any,
    {
      tenantId: string;
      appointmentTypeId: string;
    }
  >;
  private getContactsByUuid: LazyQueryExecFunction<any, OperationVariables>;

  constructor(args: AppointmentTypeGraphControllerArgs) {
    this.state = args.state;
    this.showNoEmployerText = this.state.patientUuidList.length == 0;
    this.showSpinner = this.state.loading;
    this.showNoVisitText = this.state.graphData.every(
      (item) => item.count == 0
    );
    this.employerId = args.employerId;
    this.accountId = args.accountId;
    this.setIsShowAppointmentTypesGraph = args.setIsShowAppointmentTypesGraph;
    this.setState = args.setState;
    this.getAppointmentTypes = args.getAppointmentTypes;
    this.getOnlyContactUuidByEmployerBoolExp = args.getOnlyContactUuidByEmployerBoolExp;
    this.getContactUuidsByAppointmentTypes =
      args.getContactUuidsByAppointmentTypes;
    this.getContactsByUuid = args.getContactsByUuid;

    // binding
    this.onMount = this.onMount.bind(this);
    this.getAppointmentTypeList = this.getAppointmentTypeList.bind(this);
    this.getPatientUuidListFromEmployerId =
      this.getPatientUuidListFromEmployerId.bind(this);
    this.getPatientUuidListFromResponse =
      this.getPatientUuidListFromResponse.bind(this);
    this.getAppointmentTypeGraphData =
      this.getAppointmentTypeGraphData.bind(this);
    this.getGraphDataFromResponse = this.getGraphDataFromResponse.bind(this);
    this.setGraphData = this.setGraphData.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.isNoVisitCompleted = this.isNoVisitCompleted.bind(this);
    this.onAppointmentTypeSelected = this.onAppointmentTypeSelected.bind(this);
    this.handleSelectedAppointmentType =
      this.handleSelectedAppointmentType.bind(this);
    this.setSelectedAppointmentTypeId =
      this.setSelectedAppointmentTypeId.bind(this);
    this.getContactUuidListFromAppointmentResponse =
      this.getContactUuidListFromAppointmentResponse.bind(this);
    this.getContactList = this.getContactList.bind(this);
  }

  onMount() {
    this.getAppointmentTypeGraphData();
  }

  handleSelectedAppointmentType() {
    if (this.state.selectedAppointmentTypeId.length > 0) {
      this.onAppointmentTypeSelected();
    }
  }

  private async getAppointmentTypeGraphData() {
    await this.getAppointmentTypeList();
    await this.getPatientUuidListFromEmployerId();
  }

  private async onAppointmentTypeSelected() {
    try {
      const response = await this.getContactUuidsByAppointmentTypes();
      const data = response.data.appointments || [];
      const contactUuidList =
        this.getContactUuidListFromAppointmentResponse(data);

      await this.getContactList(contactUuidList);

      this.setSelectedAppointmentTypeId('');
    } catch (error) {

    }
  }

  async getAppointmentTypeList() {
    try {
      const response = await this.getAppointmentTypes();
      if(!response?.data?.appointmentTypes?.length){
        this.setIsShowAppointmentTypesGraph(false);
      }
      this.setState((prev) => {
        return {
          ...prev,
          appointmentTypes: response.data.appointmentTypes,
        };
      });
    } catch (error) {

    }
  }

  private async getPatientUuidListFromEmployerId() {
    if(!this.state.employerId){
      return;
    }
    this.setLoading(true);
    try {
      const response = await this.getOnlyContactUuidByEmployerBoolExp();
      const data: any = response?.data?.employers || [];
      const patientUuidList = this.getPatientUuidListFromResponse(data);
      this.setState((prev) => {
        return {
          ...prev,
          loading:false,
          patientUuidList: patientUuidList,
        };
      });
    } catch (error) {

    }
  }

  private getPatientUuidListFromResponse(data:[any]): string[] {
    const list: string[] = [];
    data.forEach((item) => {
      const employee = item.employee;
      employee.forEach((em:any) => {
        const contactUuid = em?.contactUuid;
        if (contactUuid) {
          list.push(contactUuid);
        }
      });
    });
    return list;
  }



  private getGraphDataFromResponse(
    response: QueryResult<any, OperationVariables>[]
  ) {
    const graphData: AppointmentTypeGraphData[] = [];
    response.forEach((item, index) => {
      const count = item?.data?.aggregateAppointments?.aggregate?.count || 0;
      const eventName = this.state.appointmentTypes[index].eventName || '';
      const typeId = this.state.appointmentTypes[index].id || '';
      //if(count > 0){
        graphData.push({
          name: eventName,
          count: count,
          typeId: typeId,
        });
     // }
    });
    return graphData;
  }

  private setGraphData(data: AppointmentTypeGraphData[]) {
    this.setState((prev) => {
      return {
        ...prev,
        graphData: data,
      };
    });
  }

  private setLoading(value: boolean) {
    this.setState((prev) => {
      return {
        ...prev,
        loading: value,
      };
    });
  }

  private isNoVisitCompleted(): boolean {
    return this.state.graphData.every((item) => item.count == 0);
  }

  static getFilterQuery(args: {employerId?: string; accountUuid: string}) {
    const where: any = {};
    where.isDeleted = {_eq: false};
    where.accountUuid = {_eq: args.accountUuid};
    if (args.employerId) {
      where.id = {_eq: args.employerId};
    }
    return where;
  }

  setSelectedAppointmentTypeId(typeId: string) {
    this.setState((prev) => {
      return {
        ...prev,
        selectedAppointmentTypeId: typeId,
      };
    });
  }

  private getContactUuidListFromAppointmentResponse(data: any[]): string[] {
    const result: string[] = [];
    if (data.length == 0) {
      return result;
    }
    data.forEach((item) => {
      const participants = item.participants || [];
      participants.forEach((p: any) => {
        const contactId = p?.contactId || '';
        if (contactId) {
          result.push(contactId);
        }
      });
    });
    return [...new Set(result)];
  }

  private async getContactList(contactUuidList: string[]) {
    try {
      const response = await this.getContactsByUuid({
        variables: {
          contactUuidList: contactUuidList,
          limit: this.state.pageSize,
          offset: this.state.page,
          accountId: this.accountId,
        },
      });
      const contacts = response.data.contacts || [];
      const list = this.getFormattedContactList(contacts);
    } catch (error) {

    }
  }

  private getFormattedContactList(data: any[]): SingleContact[] {
    const result: SingleContact[] = [];
    if (data.length == 0) {
      return [];
    }
    data.forEach((item) => {
      const id = item.id;
      const uuid = item.uuid;
      const name = item.name;
      const email = item.email || '';
      const gender = item?.person?.gender?.value || '';
      result.push({
        id,
        uuid,
        name,
        email,
        gender,
      });
    });

    return result;
  }
}
