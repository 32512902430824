import { View, Text } from "native-base";
import { Colors } from "../../../../styles/Colors";
import { useLazyQuery } from "@apollo/client";
import { GET_GOALS_BY_CARE_JOURNEY_ID } from "../../../../services/CareJourney/CareJourneyQueries";
import { useEffect, useState } from "react";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../constants/Configs";
import NodataViewTypeOne from "../../Analytics/EmployerAnalytics/SectionAndWidget/NoDataView/NodataViewTypeOne";

const JourneyProgressGoals = (props: {
    patientCareJourneyId: string
}) => {

    const [goals, setGoals] = useState([]);

    const [GetGoals] = useLazyQuery(
        GET_GOALS_BY_CARE_JOURNEY_ID
    );

    const fetchData = () => {
        GetGoals({
            fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
            variables: {
                'patientCareJourneyId': props.patientCareJourneyId
            },
            context: {
                service: CARESTUDIO_APOLLO_CONTEXT,
            },
        }).then((resp) => {
            if (resp.data?.patientCareJourneyGoals) {
                setGoals(resp.data?.patientCareJourneyGoals)
            }
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <View flexDirection={'column'} alignItems={'flex-start'} style={{ padding: 12, flex: 1 }}>
                <View flexDirection={'row'} alignItems={'center'}>
                <Text style={{
                    marginBottom: 8,
                    textAlign: 'center',
                    width:'100%'
                }} size={'smBold'} fontSize={18}>
                    Goals
                </Text>
                </View>
                {
                    goals?.length ? goals.map((item: any) => {
                        return (
                            <View flexDirection={'column'} style={{ marginVertical: 6 }}>
                                <Text style={{
                                    color: Colors.Custom.Gray700,
                                    fontSize: 16
                                }} size={'smBold'}>
                                    {item.title}
                                </Text>
                                <Text style={{
                                    color: Colors.Custom.Gray500,
                                    fontSize: 14
                                }} size={'smSemiBold'}>
                                    {item.description}
                                </Text>
                            </View>
                        )
                    })
                        :
                        <View style={{
                            paddingTop: 5,
                            width: '100%',
                        }}> <NodataViewTypeOne viewCode="TEXT_ICON" /></View>
                }
        </View>

    )
}

export default JourneyProgressGoals;
