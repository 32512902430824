import {
  Checkbox,
  Divider,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from 'native-base';
import {useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {IStockLocationData} from '../../../../../../../services/ProductAndServices/interfaces/StockLocation';
import {Colors} from '../../../../../../../styles/Colors';
import {ISingleVariantStockInfoProps} from '../interfaces';

export const SingleVariantStockInfo = (props: ISingleVariantStockInfoProps) => {
  const {
    singleStockItem,
    stockLocationList,
    onStockLocationInfoActionPerformed,
  } = props;
  const [backOrderable, setBackOrderable] = useState(
    singleStockItem.attributes?.backorderable
  );
  const getLocationNameFromId = (): string => {
    let locationName = 'default';
    const locationId =
      singleStockItem.relationships?.stock_location?.data.id || '';
    const locationData =
      stockLocationList.find((singleLocation) => {
        return singleLocation.id == locationId;
      }) || ({} as IStockLocationData);
    if (locationData?.attributes?.name) {
      locationName = locationData?.attributes?.name;
    }
    return locationName;
  };

  return (
    <HStack
      borderWidth={1}
      borderTopWidth={0}
      borderColor={Colors.Custom.BorderColor}
      space={3}
      divider={<Divider />}
      w="90%"
    >
      <VStack flex={0.3} pl={2} pt={2} pb={2} justifyContent="space-between">
        <Text>{getLocationNameFromId()}</Text>
      </VStack>
      <VStack flex={0.3} pl={2} pt={2} pb={2} justifyContent="space-between">
        <Text>{singleStockItem.attributes?.count_on_hand || ''}</Text>
      </VStack>
      <VStack flex={0.2} pl={2} pt={2} pb={2} justifyContent="space-between">
        <Checkbox
          value=""
          isChecked={backOrderable}
          onChange={(isSelected) => {
            setBackOrderable(isSelected);
            onStockLocationInfoActionPerformed(
              COMMON_ACTION_CODES.STATUS_CHANGED,
              {
                singleStockItem,
                backOrderable: isSelected,
              }
            );
          }}
        ></Checkbox>
      </VStack>
      <VStack flex={0.2} pl={2} pt={2} pb={2} justifyContent="space-between">
        <IconButton
          colorScheme="gray"
          variant="ghost"
          onPress={() => {
            onStockLocationInfoActionPerformed(
              COMMON_ACTION_CODES.DELETE,
              singleStockItem
            );
          }}
          icon={<Icon as={AntIcon} name="delete" size="5" color="gray.400" />}
        />
      </VStack>
    </HStack>
  );
};
