import React, {useContext} from 'react';
import {IDayLapseView} from './interface';
import Stack from '../LayoutComponents/Stack';
import {Skeleton} from 'native-base';
import {Text} from 'native-base';
import {
  getAttributeByKey,
  getDayLapse,
  getDayLapseColorCode,
} from '../CareProgramPanel/CareProgramUtils';
import {ADDITIONAL_ATTRIBUTE_KEY} from '../MemebersView/constant';
import {IContactCareProgramResponse} from '../../../services/ContactCareProgram/interface';
import {Colors} from '../../../styles/Colors';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {MLOV_CATEGORY} from '../../../constants';
import {View} from 'react-native';

const DayLapseView = (props: IDayLapseView) => {
  const {selectedRecord} = props;
  const mlovData = useContext(CommonDataContext);
  const appointmentStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

  const getDayLapseView = () => {
    if (
      selectedRecord?.additionalDataLoading?.careProgramLoading ||
      selectedRecord?.additionalDataLoading?.nextPCPAppointmentLoading
    ) {
      return <Skeleton.Text lines={1}></Skeleton.Text>;
    }
    if (
      !selectedRecord?.additionalDataLoading?.careProgramLoading &&
      !selectedRecord?.contactCarePrograms?.length
    ) {
      return (
        <Stack direction={'row'}>
          <Text
            wordBreak={'break-word'}
            color={Colors.Custom.Gray500}
            size={'smLight'}
          >
            {'-'}
          </Text>
        </Stack>
      );
    }
    const contactCarePrograms: IContactCareProgramResponse[] =
      selectedRecord?.contactCarePrograms || [];

    return (
      <Stack direction={'column'}>
        {(contactCarePrograms || []).map((contactCareProgram, index) => {
          const dischargeDate = getAttributeByKey(
            ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE,
            contactCareProgram
          );
          const pcpAppointmentStatus =
            getMlovIdFromCode(
              appointmentStatusList,
              selectedRecord?.pcpAppointmentData?.statusId
            ) || '';
          const dayLapseData = getDayLapse(dischargeDate || '');
          const dayLapseColorCode = getDayLapseColorCode(
            dischargeDate || '',
            selectedRecord?.pcpAppointmentData?.startDateTime,
            contactCarePrograms?.[0]?.complexity,
            pcpAppointmentStatus
          );
          return (
            <Stack direction={'row'} key={`${contactCareProgram?.id}_${index}`}>
              <Text
                wordBreak={'break-word'}
                color={dayLapseColorCode}
                size={'smLight'}
              >
                {dayLapseData}
              </Text>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  return <View>{getDayLapseView()}</View>;
};

export default DayLapseView;
