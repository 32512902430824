import React, {useState} from 'react';
import {HStack, View} from 'native-base';
// import {styles} from './CampaignStyles';
import {Colors} from '../../../../styles/Colors';
import {LeftSideFilters} from './LeftSideFilters/LeftSideFilters';
import {RightSideTable} from './RightSideTable/RightSideTable';
import {CAMPAIGN_CONSTANTS} from './CampaignConstants';

const Campaign = () => {
  const [currentScreen, setCurrentScreen] = useState(
    CAMPAIGN_CONSTANTS.LANDING
  );

  const handleScreenChange = (screen: string): any => {
    setCurrentScreen(screen);
  };

  {
    if (currentScreen == CAMPAIGN_CONSTANTS.LANDING) {
      return (
        <HStack flex="1" bgColor={Colors.Custom.BackgroundColor}>
          <View
            flex="0.2"
            margin={2}
            borderWidth={1}
            borderColor={Colors.Custom.BorderColor}
          >
            <LeftSideFilters />
          </View>
          <View
            flex="0.8"
            background={'#fff'}
            marginTop={2}
            marginRight={2}
            marginBottom={2}
            borderWidth={1}
            borderColor={Colors.Custom.BorderColor}
          >
            <RightSideTable
              currentScreen={currentScreen}
              handleScreenChange={handleScreenChange}
            />
          </View>
        </HStack>
      );
    } else {
      return (
        <HStack flex="1" bgColor={Colors.Custom.BackgroundColor}>
          <View
            flex="1"
            background={'#fff'}
            margin={2}
            borderColor={Colors.Custom.BorderColor}
          >
            <RightSideTable
              currentScreen={currentScreen}
              handleScreenChange={handleScreenChange}
            />
          </View>
        </HStack>
      );
    }
  }
};

export default Campaign;
