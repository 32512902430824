import {gql} from '@apollo/client';
import {AxiosRequestConfig} from 'axios';
import {IPaymentBody} from '../../components/RightSideContainer/Sales/ProductsAndServices/PaymentSetup/interfaces';
import {PAGINATION_DIRECTION} from '../../constants';
import {STRIP_API_PATH} from '../../constants/StripeApiPath';
import BaseService from '../CommonService/BaseService';
import { IPackageResponse } from '../../components/common/SearchPackages/interfaces';

export const getProducts = async (searchString: string, enablePackageConfig: boolean) => {
  const baseService = BaseService.getSharedInstance().axios;
  if (enablePackageConfig) {
    return await baseService.get(
      `${STRIP_API_PATH.PRODUCT_LIST}?searchString=${searchString || ''}&limit=${searchString ? '' : '20'}`
    );
  } else {
    if (searchString) {
      return await baseService.get(
        `${STRIP_API_PATH.SEARCH_PRODUCT_LIST}?searchString=${searchString}`
      );
    } else {
      return await baseService.get(`${STRIP_API_PATH.PRODUCT_LIST}?limit=20`);
    }
  }
};

export const createProducts = async (productBodyData: any) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.post(STRIP_API_PATH.PRODUCT_LIST, productBodyData);
};

export const createBilling = async (productBodyData: any) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.post(
    STRIP_API_PATH.BILLING_PRODUCT_LIST,
    productBodyData
  );
};

export const getProductsDetail = async (productId: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get(STRIP_API_PATH.PRODUCT_LIST + '/' + productId);
};
export const updateProductsDetail = async (
  productId: string,
  productBodyData: any
) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.put(STRIP_API_PATH.PRODUCT_LIST, productBodyData);
};

export const updateBillingDetail = async (
  productId: string,
  productBodyData: any
) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.put(
    STRIP_API_PATH.BILLING_PRODUCT_LIST,
    productBodyData
  );
};

export const getOrdersList = async (
  pageSize: number,
  paginationDir: string,
  startOrEnd: string
) => {
  const baseService = BaseService.getSharedInstance().axios;
  let paginationString = '';
  if (paginationDir == PAGINATION_DIRECTION.NEXT) {
    paginationString = `&starting_after=${startOrEnd}`;
  } else if (paginationDir == PAGINATION_DIRECTION.PREV) {
    paginationString = `&ending_before=${startOrEnd}`;
  }

  return await baseService.get(
    `${STRIP_API_PATH.SUBSCRIPTION_ORDERS}?pageSize=${pageSize}${paginationString}`
  );
  // return await baseService.get(STRIP_API_PATH.SEARCH_ORDER_LIST);
};

export const getOrdersListOneTime = async () => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get(STRIP_API_PATH.SEARCH_ORDER_LIST_ONE_TIME);
};

export const getOrdersDetailsOneTime = async (id: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get(`${STRIP_API_PATH.ORDER_LIST}/${id}`);
};

export const getBillingOrdersList = async (expand: string[]) => {
  const baseService = BaseService.getSharedInstance().axios;
  const options: AxiosRequestConfig = {
    params: {expand: expand},
  };
  return await baseService.get(
    STRIP_API_PATH.SEARCH_BILLING_ORDER_LIST,
    options
  );
};
export const getOrdersDetails = async (id: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get(
    `${STRIP_API_PATH.SUBSCRIPTION_ORDER_DETAIL}/${id}`
  );
};

export const getSubscriptionDetails = async (id: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get(`${STRIP_API_PATH.SUBSCRIPTION_DETAILS}/${id}`);
};

export const CreatePaymentCred = async (body: IPaymentBody) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.post(`${STRIP_API_PATH.STRIP_PAYMENT_CRED}`, body);
};

export const UpdatePaymentCred = async (body: IPaymentBody) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.post(`${STRIP_API_PATH.STRIP_PAYMENT_CRED}`, body);
};

export const SearchProducts = async (searchString: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get(
    `${STRIP_API_PATH.PRODUCT_LIST}?searchString=${searchString}`
  );
};

export const GetSubscriptionList = async (contactUuid: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get(
    `${STRIP_API_PATH.SUBSCRIPTION_LIST}?contactUuid=${contactUuid}`
  );
};

export const GetOneTimeList = async (customerId?: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get(
  `${STRIP_API_PATH.ONE_TIME_LIST}/${customerId}`
  );
};

export const GetSubscriptionPrevInvoices = async (id: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get(`${STRIP_API_PATH.SUBSCRIPTION_INVOICES}/${id}`);
};

export const GetContactByStripeCustomerId = gql`
  query GetContactByStripeCustomerId($stripeCustomerId: String!) {
    contactAdditionalInfos(
      where: {stripeCustomerId: {_eq: $stripeCustomerId}}
    ) {
      contact {
        id
        uuid
        name
        contactType {
          contactType {
            code
            id
            value
          }
        }
      }
    }
  }
`;

export const GetContactsSubscription = async (body: any) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.post(`${STRIP_API_PATH.SUBSCRIPTION}`,body);
};

export const GetPackages = async (params: any) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get<IPackageResponse>(
    `${STRIP_API_PATH.PACKAGE_PRODUCTS}`, {
      params: params
    }
  );
};

export const GetPackage = async (id: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.get(
    `${STRIP_API_PATH.GET_PACKAGE}/${id}`
  );
};
