import React from 'react';
import {ICommonSvgProps} from './interfaces';

const GmailSvg = (props: ICommonSvgProps) => {
  const height = props.height || '16';
  const width = props.width || '16';
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.72728 12.0023V7.36594L3.28939 6.05048L2 5.3205V11.1841C2 11.6369 2.3668 12.0023 2.8182 12.0023H4.72728Z"
        fill="#4285F4"
      />
      <path
        d="M11.2727 12.0023H13.1818C13.6346 12.0023 14 11.6355 14 11.1841V5.32055L12.5396 6.15666L11.2727 7.36594V12.0023Z"
        fill="#34A853"
      />
      <path
        d="M4.72728 7.36594L4.53163 5.55436L4.72728 3.8205L8 6.27506L11.2727 3.8205L11.4916 5.46075L11.2727 7.36594L8 9.8205L4.72728 7.36594Z"
        fill="#EA4335"
      />
      <path
        d="M11.2727 3.8205V7.36594L14 5.32055V4.22958C14 3.21778 12.845 2.64098 12.0364 3.24778L11.2727 3.8205Z"
        fill="#FBBC04"
      />
      <path
        d="M2 5.3205L4.72728 7.36594V3.8205L3.96359 3.24779C3.15359 2.64094 2 3.21779 2 4.22954V5.3205Z"
        fill="#C5221F"
      />
      <path
        d="M8 9.7912L4.75072 7.35422V3.86738L7.98594 6.29381L8 6.30436L8.01406 6.29381L11.2493 3.86738V7.35422L8 9.7912ZM2.02344 11.1841V5.36737L4.70384 7.37766V11.9789H2.8182C2.37972 11.9789 2.02344 11.6239 2.02344 11.1841ZM4.70384 4.23721V6.93066L4.5552 5.55442L4.70384 4.23721ZM4.50832 5.55688L4.69732 7.30676L3.30521 6.03319L3.30562 6.03274L3.30094 6.03009L2.02344 5.30684V4.22954C2.02344 3.23712 3.15497 2.67127 3.94953 3.26654C3.94954 3.26654 3.94954 3.26654 3.94954 3.26655L4.70249 3.8312L4.50834 5.55173L4.50776 5.55167L4.50832 5.55688ZM4.35689 7.05885L2.30586 5.52059L3.27553 6.06957L4.35689 7.05885ZM11.2962 6.95655V4.17336L11.468 5.46097L11.2962 6.95655ZM11.2978 3.83101L12.0505 3.26653L12.0505 3.26653C12.8436 2.67132 13.9766 3.2371 13.9766 4.22958V5.30696L12.5279 6.13632L12.5276 6.1357L12.5234 6.1397L11.3034 7.30425L11.5149 5.46342L11.5156 5.46351L11.5148 5.45765L11.2978 3.83101ZM13.6827 5.52918L11.5743 7.11047L12.5537 6.17558L13.6827 5.52918ZM11.2962 7.37766L13.9766 5.36742V11.1841C13.9766 11.6226 13.6216 11.9789 13.1818 11.9789H11.2962V7.37766Z"
        stroke="#667085"
        stroke-width="0.046875"
      />
    </svg>
  );
};

export default GmailSvg;
