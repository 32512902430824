import React from "react";

const CareJourneyAdherenceSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{width: 'inherit', height: 'inherit'}}
    fill="none"
    viewBox="0 0 25 25"
  >
    <path
      fill={svgColors}
      fillRule="evenodd"
      d="M2.305 14.063c1.917 2.875 5.162 6.048 10.195 9.375 5.033-3.327 8.278-6.5 10.195-9.375H18.75a.781.781 0 01-.725-.49l-2.277-5.691-2.496 8.739a.782.782 0 01-1.477.076L9.2 10.263 6.9 13.716a.781.781 0 01-.65.347H2.305zM1.373 12.5C-3.79 2.626 6.891-3.124 12.225 1.787c.094.086.186.175.275.268.088-.092.18-.181.275-.266 5.334-4.913 16.016.837 10.85 10.713h-4.349l-2.928-7.322a.782.782 0 00-1.476.076l-2.495 8.74L10.1 8.303a.781.781 0 00-1.375-.143l-2.894 4.34H1.373z"
      clipRule="evenodd"
    ></path>
  </svg>
  );
}

export default React.memo(CareJourneyAdherenceSvg);
