import {ITaxons, ITaxonsDisplayList} from './Interface';

export const getFormatedTaxons = (taxons: ITaxons[]): ITaxonsDisplayList[] => {
  const taxonsDisplayList: ITaxonsDisplayList[] = [];
  taxons?.forEach((taxons: ITaxons) => {
    taxonsDisplayList.push({
      id: taxons.id,
      name: taxons.attributes.name,
      title: taxons.attributes.name,
      value: taxons.id,
      key: taxons.id,
      index: taxons.id,
      level: taxons.attributes.depth,
      children: getFormatedTaxons(taxons.children),
      rawObjRes: taxons,
      parentId: taxons.parentId || taxons.id ,
    });
  });
  return taxonsDisplayList;
};

export const checkEvenOrOd = (index: number) => {
  return index % 2 == 0;
};
