import React from 'react';
import {Colors} from '../../../../styles';
import {ICommonSvgProps} from '../interfaces';

const ArroyLeft = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';
  const customColor = props.customStrokeColor ? props.customStrokeColor : strokeColor
  return (
    <svg
      style={{width: '16', height: '16'}}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6666 7.66626H4.54661L8.94661 3.2796C9.19768 3.02852 9.33873 2.688 9.33873 2.33293C9.33873 1.97786 9.19768 1.63733 8.94661 1.38626C8.69554 1.13519 8.35501 0.994141 7.99994 0.994141C7.64487 0.994141 7.30435 1.13519 7.05328 1.38626L0.38661 8.05293C0.265222 8.17973 0.170069 8.32926 0.10661 8.49293C-0.0267475 8.81754 -0.0267475 9.18165 0.10661 9.50626C0.170069 9.66993 0.265222 9.81946 0.38661 9.94626L7.05328 16.6129C7.17723 16.7379 7.3247 16.8371 7.48717 16.9048C7.64965 16.9725 7.82393 17.0073 7.99994 17.0073C8.17596 17.0073 8.35023 16.9725 8.51271 16.9048C8.67519 16.8371 8.82266 16.7379 8.94661 16.6129C9.07158 16.489 9.17077 16.3415 9.23847 16.179C9.30616 16.0166 9.34101 15.8423 9.34101 15.6663C9.34101 15.4902 9.30616 15.316 9.23847 15.1535C9.17077 14.991 9.07158 14.8435 8.94661 14.7196L4.54661 10.3329H14.6666C15.0202 10.3329 15.3594 10.1925 15.6094 9.9424C15.8595 9.69236 15.9999 9.35322 15.9999 8.9996C15.9999 8.64597 15.8595 8.30684 15.6094 8.05679C15.3594 7.80674 15.0202 7.66626 14.6666 7.66626Z"
        fill={customColor}
      />
    </svg>
  );
};

export default ArroyLeft;
