import {View, Text, HStack, VStack, Pressable} from 'native-base';

import {styles} from '../CampaignStyles';
import {Colors} from '../../../../../styles/Colors';
import {IStatusKindListProps, IStatusKindProps} from '../interfaces';
import {FILTER_COMPONENT_CODE} from '../../../../../constants';

export const CommonStatusKindList = (props: IStatusKindListProps) => {
  const {detailsList} = props;

  const getCurrentFunction = (label: string) => {
    if (props.label === FILTER_COMPONENT_CODE.KIND && props.getCurrentKind) {
      return props.getCurrentKind(label);
    } else {
      if (props.getCurrentStatus) return props.getCurrentStatus(label);
    }
  };

  return (
    <View style={styles.btPadding}>
      {detailsList.map((status: IStatusKindProps, index: number) => {
        return (
          <Pressable
            onPress={() => {
              getCurrentFunction(status.label);
            }}
            _hover={{bg: Colors.Custom.HoverBgColor}}
            _focus={{
              borderRightColor: Colors.Custom.PrimaryColor,
              borderRightWidth: 3,
              backgroundColor: Colors.Custom.HoverBgColor,
            }}
            key={index}
          >
            <HStack style={[styles.tagListItem]} flex={1}>
              <VStack flex={0.97}>
                <Text>{status.label}</Text>
              </VStack>
              <VStack flex={0.03}>
                <Text>{status.count}</Text>
              </VStack>
            </HStack>
          </Pressable>
        );
      })}
    </View>
  );
};
