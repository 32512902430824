import {Badge, HStack} from 'native-base';
import {Colors} from '../../../../../../styles';
import {ICampaignTableSending} from '../../interfaces';
import {styles} from './TableComponentsStyles';

export const CampaignTableSending = (props: ICampaignTableSending) => {
  return (
    <HStack style={styles.sendingWidth}>
      <Badge
        style={styles.sendingStyle}
        _text={{fontSize: 8, color: Colors.Custom.PrimaryColor}}
      >
        {props.status.toUpperCase()}
      </Badge>
    </HStack>
  );
};
