import { View, Text, Pressable, StyleSheet } from 'react-native'
import React, { useState } from 'react'
import { Colors } from '../../../../styles'
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces'
import Stack from '../../../common/LayoutComponents/Stack';
import { getPriorityIcon, getStatusBadge } from '../../../TaskCard/TaskCardHelper';
import { DisplayCardAvatar } from '../../../common/DisplayCard/DisplayCardAvatar';
import { GROUP_MEMBER_TYPE } from '../../../../constants';
import Close from '../../../../assets/svg/Close';
import { getActionAndReasonFromConflict } from '../../TaskModuleHelper';
import { IBulkOperationData, IConflict } from '../../TaskInterfaces';
import { Tooltip } from 'antd';
import WarningSvg from '../../../common/Svg/WarningSvg';

export interface IBulkTaskCardListViewProps {
  taskList: {task: ITask, conflicts: IConflict[]}[];
  operations?: IBulkOperationData;
  showConflictWithoutAction?: boolean;
  onRemove?: (task: ITask) => void;
}

const BulkTaskCardListView = (props: IBulkTaskCardListViewProps) => {
  const { taskList, operations, showConflictWithoutAction, onRemove } = props;

  const [tooltipId, setTooltipId] = useState<string>('');

  return (
    <Stack direction="column">
      {taskList.map((taskData) => {
        const {task, conflicts} = taskData;
        const priorityIcon = getPriorityIcon(task.priority, false, 0);
        const statusBadge = getStatusBadge(task.status);
        const conflictDisplayList = getActionAndReasonFromConflict(conflicts, task, operations);

        return (
          <Stack direction="column" space={2} style={styles.taskContainer}>
            <Stack
              key={`BulkTaskCardListView_${task.id}`}
              direction="column"
              space={8}
              style={StyleSheet.flatten([
                styles.taskCard,
                showConflictWithoutAction && conflictDisplayList.length ? styles.taskCardWithConflict : null
              ])}
              >
              <Stack direction="row"
                style={
                  conflictDisplayList.length > 0 ? styles.taskHeaderWithConflict : styles.taskHeader
                }>
                <View style={styles.priorityIconContainer}>
                  {priorityIcon}
                </View>
                <View style={{paddingRight: 1, flex: 7.5, flexGrow: 1}}>
                  <Text style={styles.titleText}>{task.title}</Text>
                </View>
                <View style={styles.statusBadgeContainer}>
                  <View style={styles.statusBadgeWrapper}>{statusBadge}</View>
                </View>
                <View style={styles.avatarContainer}>
                  <Tooltip title={task.assigneeUser?.name} open={task.id === tooltipId}>
                    <div onMouseOver={() => setTooltipId(task.id)} onMouseLeave={() => setTooltipId('')} >
                      <DisplayCardAvatar
                        avatarStyle={{
                          avatarSize: '12',
                          height: 32,
                          width: 32,
                          textStyle: {
                            fontSize: 16
                          }
                        }}
                        isLetterAvatarShow
                        userData={{
                          userName: task.assigneeUser?.name,
                          userId: task.assigneeId || '',
                          userType: GROUP_MEMBER_TYPE.USER,
                        }}
                      />
                    </div>
                  </Tooltip>
                </View>
                <View style={styles.closeButtonContainer}>
                  <Pressable onPress={() => {
                      onRemove?.(task);
                    }}>
                    <Close fill={Colors.Custom.Gray500} size={20} />
                  </Pressable>
                </View>
              </Stack>
              {!showConflictWithoutAction && conflictDisplayList.length > 0 && (
                <Stack direction="column" style={styles.conflictContainer}>
                  <Stack direction="row" style={styles.conflictHeader}>
                    <View style={styles.conflictActionColumn}>
                      <Text style={styles.conflictHeaderText}>{'ACTION'}</Text>
                    </View>
                    <View style={styles.conflictReasonColumn}>
                      <Text style={styles.conflictHeaderText}>{'CONFLICT'}</Text>
                    </View>
                  </Stack>
                  {conflictDisplayList.map((conflict, index) => {
                    const {action, reason} = conflict;
                    return (
                      <Stack
                        key={`BulkTaskConflict_${action}_${reason}_${task.id}`}
                        direction="row"
                        style={StyleSheet.flatten([
                          styles.conflictRow,
                          index === conflictDisplayList.length - 1 && styles.lastConflictRow
                        ])}>
                        <View style={styles.conflictActionColumn}>
                          <Text style={styles.conflictText}>{action}</Text>
                        </View>
                        <View style={styles.conflictReasonColumn}>
                          <Text style={styles.conflictText}>{reason}</Text>
                        </View>
                      </Stack>
                    );
                  })}
                </Stack>
              )}
            </Stack>
            {showConflictWithoutAction && conflictDisplayList.length > 0 && (
              <Stack direction="column" style={styles.conflictWithoutActionContainer}>
                {conflictDisplayList.map((conflict, index) => {
                  const {action, reason} = conflict;
                  if (index > 0) {
                    return <></>;
                  }
                  return (
                    <Stack
                      key={`BulkTaskConflict_${action}_${reason}_${task.id}`}
                      direction="row"
                      space={8}
                      style={styles.conflictWithoutActionRow}>
                      <View>
                        <WarningSvg customStrokeColor={Colors.Custom.crossIconColor} strokeWidth={16} />
                      </View>
                      <View style={styles.conflictWithoutActionTextContainer}>
                        <Text style={styles.conflictWithoutActionText}>{reason}</Text>
                      </View>
                    </Stack>
                  );
                })}
              </Stack>
            )}
          </Stack>
        );
      })}
    </Stack>
  )
}

const styles = StyleSheet.create({
  taskContainer: {
    marginBottom: 12,
  },
  taskCard: {
    backgroundColor: Colors.Custom.Gray100,
    borderWidth: 0.5,
    borderRadius: 8,
    overflow: 'hidden',
  },
  taskCardWithConflict: {
    borderColor: Colors.Custom.overdueTaskIconColor,
  },
  taskHeader: {
    alignItems: 'center',
    backgroundColor: Colors.Custom.White,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  taskHeaderWithConflict: {
    borderBottomColor: Colors.Custom.Gray300,
    borderBottomWidth: 0.5,
    alignItems: 'center',
    backgroundColor: Colors.Custom.White,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  priorityIconContainer: {
    paddingRight: 1,
    flex: 0.5,
  },
  titleContainer: {
    paddingRight: 1,
    flex: 7.5,
    flexGrow: 1,
  },
  titleText: {
    color: Colors.Custom.Gray500,
    fontWeight: '400',
  },
  statusBadgeContainer: {
    paddingRight: 1,
    flex: 1.5,
  },
  statusBadgeWrapper: {
    alignItems: 'center',
  },
  avatarContainer: {
    paddingRight: 1,
    flex: 1,
  },
  closeButtonContainer: {
    paddingRight: 1,
  },
  conflictContainer: {
    marginHorizontal: 8,
    marginBottom: 8,
    paddingVertical: 8,
    borderColor: Colors.Custom.Gray300,
    borderWidth: 0.5,
    backgroundColor: Colors.Custom.White,
    borderRadius: 8,
  },
  conflictHeader: {
    borderBottomColor: Colors.Custom.Gray300,
    borderBottomWidth: 0.5,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  conflictActionColumn: {
    flex: 4,
  },
  conflictReasonColumn: {
    flex: 6,
  },
  conflictHeaderText: {
    fontWeight: '600',
    fontSize: 14,
    color: Colors.Custom.Gray600,
  },
  conflictRow: {
    borderBottomColor: Colors.Custom.Gray300,
    borderBottomWidth: 0.5,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  lastConflictRow: {
    borderBottomWidth: 0,
  },
  conflictText: {
    fontWeight: '400',
    fontSize: 14,
    color: Colors.Custom.Gray500,
  },
  conflictWithoutActionContainer: {
    marginTop: 4,
  },
  conflictWithoutActionRow: {
    alignItems: 'center',
  },
  conflictWithoutActionTextContainer: {
    flex: 1,
  },
  conflictWithoutActionText: {
    fontWeight: '400',
    fontSize: 12,
    color: Colors.Custom.crossIconColor,
  },
});

export default BulkTaskCardListView
