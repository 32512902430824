import {title} from '@uiw/react-md-editor';
import {Divider, HStack, View, VStack} from 'native-base';
import {Colors} from '../../../../../../../styles';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {styles} from '../StockStyles';

export const StockInfoHeader = () => {
  return (
    <View>
      <View
        style={[
          styles.topContainer,
          {
            height: 64,
            borderTopWidth: 1,
            borderTopColor: Colors.Custom.BorderColor,
          },
        ]}
      >
        <HStack flex={1}>
          <VStack flex={0.4} justifyContent={'center'}>
            <View style={styles.left}>
              {title && (
                <DisplayText
                  size={'lgBold'}
                  textLocalId={'variant'}
                  extraStyles={styles.leftText}
                />
              )}
            </View>
          </VStack>
          <VStack flex={0.6} justifyContent={'center'}>
            <View style={styles.left}>
              {title && (
                <DisplayText
                  size={'lgBold'}
                  textLocalId={'stockLocationInfo'}
                  extraStyles={styles.leftText}
                />
              )}
            </View>
          </VStack>
        </HStack>
      </View>
      <Divider />
    </View>
  );
};
