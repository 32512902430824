import {Text, View} from "native-base"
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {QM_CONST} from "../../../../constants";
import {isEmployerRole} from "../../../../utils/commonUtils";
import CustomTabsView from "../../../common/CustomTabsView/CustomTabsView";
import {ITabsList} from "../../../common/CustomTabsView/interfaces";
import {TableTopBar, TABLE_TOP_BAR_ACTION_CODES} from "../../../common/TableTopBar";
import TitleSubtitleView from "../../../common/TitleSubtitleView/TitleSubtitleView";
import {getPathFromHash} from "../../ContentManagement/ContentManagementUtils";
import Programs from "./Programs/Programs";
import QualityMeasures from "./QualityMeasures/QualityMeasures";

const ContractDetails = () => {
  const {contractId} = useParams();
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  // HIDE AS THIS NOT IMPLEMENTED NOW
  const disableProgram = true 
  const [stateData, setStateData] = useState({
    selectedActionCode: '',
    selectedRawData: {} as any,
    qualityMeasuresCount: 0 as number
  })
  const navigate = useNavigate();
  const tabList = (): ITabsList[] => {
    const tabs: ITabsList[] = [
      {
        key: 'qualityMeasures',
        title: 'Quality Measures',
        path: 'qualityMeasures',
        tabContainerElem() {
          return (
            <QualityMeasures
              searchString={stateData.selectedRawData?.searchString || ''}
              updateQualityMeasuresCount={(qualityMeasuresCount: number) => {
                setStateData((prev) => {
                  return {
                    ...prev,
                    qualityMeasuresCount: qualityMeasuresCount,
                  }
                })
              }}
            />
          )
        },
        count: stateData?.qualityMeasuresCount
      }
    ]
    if (!disableProgram) {
      tabs.push({
        key: 'programs',
        title: 'Programs',
        path: 'programs',
        tabContainerElem: () => {
          return (
            <Programs
              searchString={stateData.selectedRawData?.searchString || ''}
            />
          );
        },
      });
    }
    return tabs
  }
  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any,
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: actionCode,
            selectedRawData: actionData,
          };
        });
      break;
    }
  };
  const getSelectedTopBar = (selectedTab: ITabsList) => {
    if ( selectedTab.key === QM_CONST.PROGRAMS) {
      return false
    }
    return  <TableTopBar
    selectedTab={selectedTab.key}
    title=""
    alignSearchBarFlexEnd={true}
    hStackMinHight={70}
    isDividerNotVisible={true}
    onActionPerformed={(actionCode: string, actionData: any) => {
      onTableTopBarActionPerformed(actionCode, actionData);
    }}
  />
  }
  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab)
  }
  useEffect(() => {
    const path = getPathFromHash(window.location.hash);
    const tabIndex = tabList().findIndex((item) => item.path === path);
    setSelectedTab(tabList()[tabIndex]);
  }, [])
  return (
    <View flex={1}>
      <View>
        <TitleSubtitleView
          title={'contracts'}
        />
      </View>
      <View>
        <CustomTabsView
          showBackButton={isEmployerRole() ? false : true}
          contentTopMargin={0}
          showHeading={true}
          basePath={`/members/contracts/${contractId}`}
          tabsList={tabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
          backButtonRouteFunc={()=> {
            navigate(`/members/contracts`);
          }}
          canNavigate={true}
          rightView={() => {
            return getSelectedTopBar(selectedTab);
          }}
          onTabClick={(tabItem) => {
            onTabClick(tabItem);
          }}
        />
      </View>
    </View>
  )
}

export default ContractDetails;
