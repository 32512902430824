import {Table, Spin} from 'antd';
import {View} from 'native-base';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {TableWrapper} from '../../../../common/TableWrapper';
import {getPackagesLabelsColumns} from './Helper';
import {ITagsTableData} from '../../../Contacts';
import {IPackagesLabelTable} from './interfaces';

export const PackagesLabelTable = (props: IPackagesLabelTable) => {
  const screenHeight = Dimensions.get('window').height;

  const onPackagesLabelsActionPerformed = (
    actionCode: string,
    rowRecord: ITagsTableData
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.SAVE:
        props?.onActionPerformed(actionCode, rowRecord);
        break;
      case COMMON_ACTION_CODES.DELETE:
        props?.onActionPerformed(actionCode, rowRecord);
        break;
      default:
        break;
    }
  };

  return (
    <View flex={1}>
      <TableWrapper
        pagination={{
          pageSize: props?.pageSize,
          current: props?.pageNo,
          total: props?.total,
          onChange(page, pageSize) {
            if (props?.onPagination) {
              props.onPagination(page, pageSize);
            }
          },
        }}
        customStyle={{zIndex: 999}}
      >
        <Table
          style={{
            backgroundColor: '#fff',
          }}
          scroll={{x: 700, y: screenHeight - 260}}
          columns={getPackagesLabelsColumns(
            onPackagesLabelsActionPerformed
          )}
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          loading={{
            spinning: props?.loading,
            indicator: (
              <View style={{
                justifyContent: 'center',
                height: '100%'
              }}>
                <Spin/>
              </View>
            ),
          }}
          dataSource={props?.data}
          pagination={false}
          rowKey={(record: ITagsTableData) => record.id}
        />
      </TableWrapper>
    </View>
  );
};
