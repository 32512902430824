import { IPowerBiAccountReports, IPowerBiWorkspaceReport} from '../../../../../services/PowerBI/interface';
import {LazyQueryExecFunction} from '@apollo/client';
import {IUserRoleCode} from '../../../../../Interfaces';
import {IPayer} from '../../../../../services/MarketCost/interface';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {getPowerBIWorkspaces} from '../../../../../services/PowerBI/PowerBIService';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {DEFAULT_EMPLOYER_REPORTING_CODE} from '../../../../../constants';

export interface AnalyticsState {
  list: Employer[];
  loading: boolean;
  selected: Employer;
  selectedPowerBiAccountReport: IPowerBiWorkspaceReport;
  dateRangeType?: string;
  saveDashboardView?: JSX.Element;
  dashboardAction?: boolean;
  employerCount?: any
  sectionUpdateAction?: boolean;
  allPayerList?: IPayer[];
  powerBiAccountReports?: IPowerBiAccountReports[],
  powerBiReportMode?: PowerBiReportMode
}

export enum PowerBiReportMode {
  VIEW = "view",
  EDIT = "edit"
}

export interface AnalyticsControllerArgs {
  tenantId: string;
  toast: any;
  state: AnalyticsState;
  userEmployerId: string;
  getEmployers: LazyQueryExecFunction<
    any,
    {
      accountUuid: string;
      query: string;
    }
  >;
  getAllPayersFromApi?: LazyQueryExecFunction<any, any>;
  userRoles: IUserRoleCode[];
  setState: React.Dispatch<React.SetStateAction<AnalyticsState>>;
  id: string;
  isFromEmployerListing: boolean;
  isEnablePowerBiAnalytics: boolean;
}

export interface Employer {
  id: string;
  name: string;
  email?: string;
  phoneNumber?: string;
  isDeleted?: boolean;
  address?: any[];
  employerDeal?: any[];
  employee_aggregate?: {
    aggregate: {
      count: number
    }
  };
  employerPriceSourceData?: {
    id: string;
    payerId: string;
    carrierId: string;
    priceSource: string;
  }
}

export class AnalyticsController {
  private state: AnalyticsState;
  private tenantId: string;
  private toast: any;
  private userRoles: IUserRoleCode[];
  private userEmployerId: string;
  private setState: React.Dispatch<React.SetStateAction<AnalyticsState>>;
  private isFromEmployerListing: boolean;
  private isEnablePowerBiAnalytics: boolean;

  getEmployers: LazyQueryExecFunction<
    any,
    {
      accountUuid: string;
      query: string
    }
  >;
  getAllPayersFromApi: any;
  showSkeleton: boolean;
  private id: string;

  constructor(args: AnalyticsControllerArgs) {
    this.state = args.state;
    this.tenantId = args.tenantId;
    this.toast = args.toast;
    this.userRoles = args.userRoles;
    this.userEmployerId = args.userEmployerId;
    this.showSkeleton = this.state.loading;
    this.setState = args.setState;
    this.getEmployers = args.getEmployers;
    if (args.getAllPayersFromApi) {
      this.getAllPayersFromApi = args.getAllPayersFromApi;
    } else {
      this.getAllPayersFromApi = null;
    }
    this.id = args.id;
    this.isFromEmployerListing = args.isFromEmployerListing;
    this.isEnablePowerBiAnalytics = args.isEnablePowerBiAnalytics;

    // binding
    this.onMount = this.onMount.bind(this);
    this.getEmployerList = this.getEmployerList.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.isEmployerRole = this.isEmployerRole.bind(this);
    this.getSelectedEmployerOnMount =
      this.getSelectedEmployerOnMount.bind(this);
  }

  onMount() {
    this.getEmployerList();
  }

  getDefaultEmployerReportDummyWorkSpace = () => {
  const dateStr = new Date().toISOString();
    return {
      id: '',
      additionalAttributes: {},
      accountUuid: this.tenantId,
      powerBiWorkspacesReports: [
        {
            id: 'eee8ae3e-f3a6-4aed-8157-cc8f4ce95eee',
            name: 'Employer Report',
            code: DEFAULT_EMPLOYER_REPORTING_CODE,
            description: DEFAULT_EMPLOYER_REPORTING_CODE,
            isDefault: false,
            version: 'V1',
            workspaceId: '',
            powerBiWorkspaceId: '',
            powerBiReportId: '',
            additionalAttributes: {},
            isDeleted: false,
            createdAt: dateStr,
            updatedAt: dateStr
        }
    ]
  };
  }

  private async getEmployerList() {
    this.setLoading(true);
    try {
      let powerBiWorkspaces: IPowerBiAccountReports[] = [];
      const employerReportingDefaultDummyWorkspace = this.getDefaultEmployerReportDummyWorkSpace();
      const response = await this.getEmployers();
      const employersList: Employer[] = response?.data?.employers || [];
      const employers: Employer[] = employersList?.sort((a: any, b: any) => {
        return (a.name || '')?.localeCompare(b.name || '')
      })
      // if(getTenantForDemo()) {
      //   employers.unshift(TEMP_EMP_TWO)
      //   // employers.unshift(TEMP_EMP_ONE)
      // }
      let allPayerList: any[] = [];
      if (this.getAllPayersFromApi) {
        const payerResponse = await this.getAllPayersFromApi();
        if (payerResponse?.data?.payers) {
          allPayerList = payerResponse?.data?.payers;
        }
      }
      if (this.isEnablePowerBiAnalytics) {
        const powerBIWorkspacesResp = await getPowerBIWorkspaces();
        if (powerBIWorkspacesResp?.data?.powerBiAccountReports?.length) {
          const finalPowerBiWorkspaces: any[] = [];
          powerBIWorkspacesResp?.data?.powerBiAccountReports.forEach((singleData)=> {
            if (singleData?.id) {
              finalPowerBiWorkspaces.push(singleData?.powerBiWorkspacesReports);
            }
          });
          powerBiWorkspaces = [employerReportingDefaultDummyWorkspace?.powerBiWorkspacesReports[0], ...finalPowerBiWorkspaces];
        }
      }
      const selected = this.getSelectedEmployerOnMount(employers, this.id || this.userEmployerId);
      if (this.isEnablePowerBiAnalytics) {
        const selectAll = {
          id: 'SELECT_ALL',
          name: 'Select All',
        } as any;
        employers.unshift(selectAll);
      }
      this.setState((prev) => {
        return {
          ...prev,
          list: employers,
          loading: false,
          selected: selected,
          allPayerList: allPayerList,
          powerBiAccountReports: powerBiWorkspaces,
          selectedPowerBiAccountReport: employerReportingDefaultDummyWorkspace?.powerBiWorkspacesReports[0] as any
        };
      });
    } catch (error) {

      this.setLoading(false);
      showToast(this.toast, 'Error in getting employers', ToastType.error);
    }
  }

  private setLoading(value: boolean) {
    this.setState((prev) => {
      return {
        ...prev,
        loading: value,
      };
    });
  }

  setSelected(value: string) {
    const selected = this.state.list.find((item) => item.id == value);
    this.setState((prev) => {
      return {
        ...prev,
        selected: selected || ({} as Employer),
      };
    });
  }

  private getSelectedEmployerOnMount(list: Employer[], id: string): Employer {

    const isSelectedId = this.isEmployerRole() || this.isFromEmployerListing

    if (isSelectedId && (this.userEmployerId.length > 0 || id.length > 0)) {
      const selected = list.find((item) => {
        return item.id == id || item.id == this.userEmployerId
      }
      );
      return selected || ({} as Employer);
    }
    return list[0];


  }

  isEmployerRole() {
    return this.userRoles.some((item) => item.code == 'EMPLOYER');
    // return this.userRoles.some((item) => true);
  }
}
