import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Drawer, Radio, Select} from 'antd';
import {HStack, Pressable, Spinner, Text, View} from 'native-base';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ANALYTICS_RESOURCE, BUTTON_TYPE} from '../../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {stageStateMetaData} from '../../../../../dummyData/Sales';
import {FormsQueries} from '../../../../../services';
import EmployerDashboardQueries from '../../../../../services/Employer/EmployerServices/EmployerDashboardQueries';
import {GET_EMPLOYER_SATISFACTION, GET_FORM_CATEGORIES} from '../../../../../services/Forms/FormsQueries';
// import {UserQueries} from '../../../../../services';
import {Colors} from '../../../../../styles';
import {capitalizeText} from '../../../../common/ContactRelationView/ContactRelationUtils';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import RightArrow from '../../../../common/Svg/WidgetsSvg/RightArrow';
import CreateForm from '../../../../PersonOmniView/MiddleContainer/PatientNotes/components/CreatePatientNote';
import {IFormCategory} from '../../../Forms/interfaces';
import {ISectionListItem} from '../interface';
import NodataViewTypeOne from '../SectionAndWidget/NoDataView/NodataViewTypeOne';
import UpdateFormFromList from './updateFormFromList';
import './CommonStyle.css'
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {useIntl} from 'react-intl';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {getMemberSatisfactionFormsOnly} from '../../../../../utils/commonUtils';
import { styles } from '../../styles';

const {Option} = Select;

interface IFormListView {
  sectionData: ISectionListItem;
  onClose: (args?: {code?: string, formId?: string}) => void;
  formId?: string;
}

const FormListView = (props: IFormListView) => {
  const {sectionData, formId} = props;
  const navigate = useNavigate();
  const intl = useIntl();
  const [stateData, setStateData] = useState({
    showDrawer: true,
    showUpdateDrawer: false,
    isSubmitting: false,
    formList: [] as any[],
    selected: {} as any,
    selectedFormObj: {} as any,
    loading: false,
    createForm: false,
    selectedCategoryId: '' as any,
    categoryCode: sectionData.resourceCode || '',
    formCategoryList: [],
  });
  const [GET_MEMBERS_SATISFACTION] = useLazyQuery(
    FormsQueries.GET_MEMBERS_SATISFACTION,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [GET_USERS_SATISFACTION] = useLazyQuery(
    FormsQueries.GET_USERS_SATISFACTION,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [GET_EMPLOYER_SATISFACTION] = useLazyQuery(
    FormsQueries.GET_EMPLOYER_SATISFACTION,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [UPDATE_SECTION_ADDITIONAL] = useMutation(
    EmployerDashboardQueries.UPDATE_SECTION_ADDITIONAL
  );

  const {loading: allCategoriesLoading} = useQuery(GET_FORM_CATEGORIES, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    // skip: !isFold,
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onError(error) {
    },
    onCompleted(data) {
      const categoryCode = sectionData.resourceCode === ANALYTICS_RESOURCE.MEMBER_SATISFACTION ||
        sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION ? ANALYTICS_RESOURCE.MEMBER_SATISFACTION : sectionData.resourceCode || ''
      setStateData((prev) => {
        return {
          ...prev,
          formCategoryList: data?.formCategories,
        };
      });
      const noteCategoryId = data?.formCategories?.filter(
        (category: IFormCategory) => category.code === categoryCode
      )?.[0]?.id;
      setStateData((prev) => {
        return {
          ...prev,
          selectedCategoryId: noteCategoryId,
          categoryCode: categoryCode,
        };
      });
    },
  });

  const onClose = () => {
    setStateData((prev) => {
      return {
        ...prev,
        showDrawer: false,
      };
    });
    setTimeout(() => {
      props.onClose();
    }, 200);
  };

  const getMembersSatisfaction = async () => {
    const response = await GET_MEMBERS_SATISFACTION().catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          formList: [],
          loading: false,
        };
      });
    });
    if (response?.data?.forms?.length) {
      const forms = getMemberSatisfactionFormsOnly(response?.data?.forms) as any[];
      setStateData((prev) => {
        return {
          ...prev,
          formList: forms,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          formList: [],
          loading: false,
        };
      });
    }
  };

  const getUsersSatisfaction = async () => {
    const response = await GET_USERS_SATISFACTION().catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          formList: [],
          loading: false,
        };
      });
    });
    if (response?.data?.forms?.length) {
      const forms = response?.data?.forms;
      setStateData((prev) => {
        return {
          ...prev,
          formList: forms,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          formList: [],
          loading: false,
        };
      });
    }
  };

  const getEmployerSatisfaction = async () => {
    const response = await GET_EMPLOYER_SATISFACTION().catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          formList: [],
          loading: false,
        };
      });
    });
    if (response?.data?.forms?.length) {
      const forms = response?.data?.forms;
      setStateData((prev) => {
        return {
          ...prev,
          formList: forms,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          formList: [],
          loading: false,
        };
      });
    }
  };
  const handleSubmit = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const additionalAttributes = {
      ...sectionData.additionalAttributes,
      formId: stateData?.selected,
    };
    const response = await UPDATE_SECTION_ADDITIONAL({
      variables: {
        id: sectionData?.id,
        additionalAttributes: additionalAttributes,
      },
    }).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (response?.data?.updatePageSections?.returning?.length) {
      props.onClose({code: COMMON_ACTION_CODES.COMPLETED, formId: stateData?.selected})
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          showDrawer: false
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };
  const getFormsByWidget = () => {
    if (sectionData?.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION
      || sectionData?.resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE) {
      getMembersSatisfaction();
    } else if (sectionData?.resourceCode === ANALYTICS_RESOURCE.USER_SATISFACTION) {
      getUsersSatisfaction();
    } else if (sectionData?.resourceCode === ANALYTICS_RESOURCE.EMPLOYER_SATISFACTION) {
      getEmployerSatisfaction();
    }
  }
  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    getFormsByWidget();
    setStateData((prev) => {
      return {
        ...prev,
        selected: formId || sectionData?.additionalAttributes?.formId,
      };
    });
  }, []);
  return (
    <>
      <Drawer
        visible={stateData.showDrawer}
        width={700}
        onClose={() => {
          onClose();
        }}
        title={
          <>
            <ModalActionTitle
              title={
                sectionData?.additionalAttributes?.formId
                  ? 'updateForms'
                  : 'addForms'
              }
              titleColor={''}
              buttonList={[
                {
                  show: true,
                  id: 1,
                  btnText: 'createNewForm',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.PRIMARY,
                  isTransBtn: false,
                  onClick: () => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        createForm: true,
                      };
                    });
                  },
                },
                {
                  show: true,
                  id: 2,
                  btnText: stateData?.selected?.id ? 'edit' : 'submit',
                  textColor: Colors.Custom.mainPrimaryPurple,
                  variant: BUTTON_TYPE.PRIMARY,
                  isTransBtn: false,
                  onClick: () => {
                    handleSubmit();
                  },
                  isLoading: stateData.isSubmitting,
                },
              ]}
            />
          </>
        }
      >
        <View>
          {!stateData.formList.length && stateData.loading ? (
            <Spinner
              size="lg"
              style={styles.spinner}
            />
          ) : (
            <>
              {stateData.formList.length ? (
                <Radio.Group
                  name="userSelection"
                  value={stateData?.selected}
                  onChange={(data) => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        selected: data?.target?.value,
                      };
                    });
                  }}
                >
                  {stateData.formList?.map((item: any, index) => {
                    return (
                      <HStack
                        key={index}
                        style={styles.hStack}
                        space={2}
                      >
                        <Radio
                          key={index} value={item?.id}>
                          <View
                            style={styles.radioView}
                          >
                            <Text style={styles.radioText}>
                              {capitalizeText(item?.name)}
                            </Text>
                            <Text style={styles.responseText}>Response:{item?.formResponses_aggregate?.aggregate?.count}</Text>
                          </View>
                        </Radio>
                        <Pressable
                          onPress={() => {
                            const url = '/analytics/updateForm';
                            const formState = {
                              isValid: false,
                              categoryList: [...stateData.formCategoryList],
                            };
                            navigate(url, {
                              state: {
                                selectedForm: item,
                                addFormDetail: formState,
                                // isAddExisting: isExisting,
                                isAddExisting: false,
                                isUpdate: true,
                                isClone: false,
                              },
                            });
                            setStateData((prev) => {
                              return {
                                ...prev,
                                showUpdateDrawer: true
                              }
                            })
                          }}
                          style={styles.pressable}
                        >
                          <View style={styles.rightArrowView}>
                            <RightArrow />
                          </View>
                        </Pressable>
                      </HStack>
                    );
                  })}
                </Radio.Group>
              ) : (
                <>
                  <NodataViewTypeOne
                    viewCode="TEXT_ICON"
                    text={'No Form to select'}
                  />
                  <View
                    style={styles.noDataView}
                  >
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          setStateData((prev) => {
                            return {
                              ...prev,
                              createForm: true,
                            };
                          });
                        },
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: 'createNewForm',
                        }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  </View>
                </>
              )}
            </>
          )}
        </View>
      </Drawer>
      <CreateForm
        style={{marginTop: 20}}
        showModal={stateData.createForm}
        onCancel={() => {
          setStateData((prev) => {
            return {
              ...prev,
              createForm: false,
            };
          });
        }}
        noteCategoryId={stateData.selectedCategoryId}
        title={'createNewForm'}
        categoryCode={stateData.categoryCode}
        quickNoteCreation={false}
        isFromEmpDashboard={true}
        handleNewlyCreatedNote={(noteId) => {
          setStateData((prev) => {
            return {
              ...prev,
              loading: true,
              createForm: false,
            };
          });
          getFormsByWidget()
        }}
      />
      {stateData.showUpdateDrawer ? (
        <UpdateFormFromList
          style={{marginTop: 20}}
          isFromEmpDashboard={true}
          onClose={() => {
            setStateData((prev) => {
              return {
                ...prev,
                showUpdateDrawer: false,
              };
            });
            const url = '/analytics';
            navigate(url)
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default FormListView;
