import {Mix, MixConfig} from '@ant-design/plots';

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  ANALYTICS_RESOURCE,
  DATE_FORMATS,
  DISPLAY_TYPE,
  EMPLOYER_REPORT_FILTER_CODES,
  MOMENT_DATE_CONST,
} from '../../../../../../constants';
import {
  getFormattedDataForMixChart,
} from '../../EmployerUtils/FormateDataUtils';
import {IAppliedFilter, IMixLineStackColumnChartResourceData, IResource, IWidgetCardView} from '../../interface';
// import ReactToPrint, {useReactToPrint} from 'react-to-print';
import {Skeleton, Typography} from 'antd';
import moment from 'moment';
import EyeHideSvg from '../../../../../common/Svg/WidgetsSvg/EyeHideSvg';
import InfoIconView from '../../CommonView/InfoIconView';
import NodataViewTypeOne from '../NoDataView/NodataViewTypeOne';
import WidgetActionView from '../WidgetActionView';
import {DashboardDataContext} from '../../EmployerContext/DashboardContext';
import {AddRendererToCanvass, WIDGET_ACTION_CODE} from '../../EmployerUtils/EmployerConst';
import {
  getDemoDataForEmployerReportCurrentSection,
  getTenantForDemo,
} from '../../tempDataFile';
import {getSectionDataByResourceCode} from '../../../../../../services/Employer/EmployerServices/EmployerServices';
import {Pressable} from 'react-native';
import RestSvgIcons from '../../../../../common/Svg/RestSvgIcons';
import {HStack, Tooltip, VStack, View} from 'native-base';
import {getEmployerReportFilterList} from '../../../../../../utils/commonUtils';
import {getGraphColorsByName, getYAxisMaxValueWidgetObject} from '../utils';
import CustomTooltip from './CustomTooltip';
import WidgetHeader from '../../WidgetHeader/WidgetHeader';
import {graphColorList, graphMetaDataColor} from '../../graphColors';
import {aggregateGroupEntry, getDateRangeFilterFromAppliedFilter, getXAxisTitleByResourceCode, getYAxisTitleByResourceCode, isWidgetDataEmpty} from '../../EmployerUtils/WidgetUtils';
import {capitalizeText} from '../../../../ContentManagement/CampaignManagement/Helper';
import GaugeChartView from '../GaugeCharts/GaugeCharts';
import {Colors} from '../../../../../../styles';
import FormListView from '../../CommonView/FormListView';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {getNPSWidgetElem} from './NPSWidget';
import {getEngageForCareElem} from './EngageForCareWidget';
import {INotEngagedInMonths} from './interface';
import {getFormattedDateStringInTimeZone} from '../../../../../../utils/DateUtils';
import { getDateRange } from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/Activities/ActivityUtils';
const {Text} = Typography;
const MixLineStackColumnChart = (props: IWidgetCardView) => {
  const {
    sectionData,
    dateRange,
    appliedFilter,
    employerId,
    isAccountDashboard,
    memberType,
    hintMembershipDisabled,
  } = props;
  const actionText = 'create a form';
  const isNPSForm = sectionData.resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE;
  const containerRef: any = useRef()
  const titleRef: any = useRef();
  const [stateData, setStateData] = useState({
    resourceData: {} as IMixLineStackColumnChartResourceData,
    loading: false,
    employeeTotalCount: 0,
    selectedView: '',
    selectedResourceData: {} as any,
    masterData: {} as any,
    appliedFilter: props.appliedFilter,
    filterValueName: [] as string[],
    filterValueString: '',
    selectedAppliedFilter: [] as any[],
    graphWrapperHeight: 350,
    formId: '',
  });
  const isRendererCanvass = AddRendererToCanvass.includes(sectionData.resourceCode || '')
  let finalResourceData = stateData.resourceData;
  let formattedResourceData = {} as any;
  if (getTenantForDemo()) {
    if (sectionData?.resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE) {
      const tempResourceData: any = stateData.resourceData;
      finalResourceData = tempResourceData?.result;
    }
    const dateFilter = getDateRangeFilterFromAppliedFilter(appliedFilter)
    formattedResourceData = getFormattedDataForMixChart(
      dateFilter,
      stateData.resourceData,
      sectionData.currentDisplayType || '',
      sectionData.resourceCode || '',
      isAccountDashboard
    );
  } else {
    if (sectionData?.resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE) {
      const tempResourceData: any = stateData.resourceData;
      finalResourceData = tempResourceData?.result;
    }
    const dateFilter = getDateRangeFilterFromAppliedFilter(appliedFilter)
    formattedResourceData = getFormattedDataForMixChart(
      dateFilter,
      finalResourceData,
      sectionData.currentDisplayType || '',
      sectionData.resourceCode || '',
      isAccountDashboard
    );
  }


  const onActionClick = (code: string, data?: any) => {
    switch (code) {
      case WIDGET_ACTION_CODE.RESET_LOCATION_FILTER:
        const filteredWidgetFilter = stateData.selectedAppliedFilter.filter(item => {
          return item.filterCode != 'LOCATION'
        })
        setStateData(prev => {
          return {
            ...prev,
            selectedAppliedFilter: filteredWidgetFilter,
            filterValueString: '',
            filterValueName: []
          }
        })
        getSectionByResourceCode();
        break;
      default:
        props.onActionClick?.(code, data)
        break;
    }
  }
  const getMixChartView = () => {
      let chartView: JSX.Element = <></>
    if (
      stateData?.resourceData?.columnStackDataList?.length ||
      (stateData?.resourceData?.result?.columnStackDataList?.length &&
        sectionData?.resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE)
    ) {
      const finalMixPlotsConfigs: any =
        getMixPlotsConfigsForLineANDColumnStack();
      chartView = <Mix {...finalMixPlotsConfigs} />;
    }
    return chartView;
  }
  const graphYAxisTitle = getYAxisTitleByResourceCode(sectionData.resourceCode || '')
  const graphXAxisTitle = getXAxisTitleByResourceCode(sectionData.resourceCode || '')
  const getBarChartViewByType = () => {
    let ele = <></>;
    switch (sectionData.currentDisplayType) {
      case DISPLAY_TYPE.MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE: {
        if (formattedResourceData?.columnStackDataList?.length) {
          ele = getMixChartView();
        }
        break;
      }
      case DISPLAY_TYPE.DETAIL_WITH_MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE:
        ele = getDetailWithMixBarChartWithStackColumnWithLineElem();

        break;
    }
    return ele;
  };

  const onNotEngagedInMonths = (singleMonthData: INotEngagedInMonths) => {
    if (!singleMonthData?.month) {
      return;
    }
    let filterData: any = [];
    switch (sectionData?.resourceCode) {
      case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE:
        {
          filterData = [
            {
              filterCode: EMPLOYER_REPORT_FILTER_CODES.ENGAGED_MEMBER_MONTH,
              filterValue: {
                value: singleMonthData?.month,
              },
            },
          ];
        }
        break;
      case ANALYTICS_RESOURCE.PATIENT_ABSENCE_ANALYSIS:
        {
          filterData = [
            {
              filterCode: EMPLOYER_REPORT_FILTER_CODES.NOT_SEEN_PATIENT_MONTH,
              filterValue: {
                value: singleMonthData?.month,
              },
            },
          ];
        }
        break;
      case ANALYTICS_RESOURCE.NOT_ENROLLED_FOR_CARE:
        {
          filterData = [
            {
              filterCode: EMPLOYER_REPORT_FILTER_CODES.NOT_ENGAGED_MEMBER_MONTH,
              filterValue: {
                value: singleMonthData?.month,
              },
            },
          ];
        }
        break;
      default:
        return;
        break;
    }
    const drillDownTitle =
      sectionData.resourceCode ===
        ANALYTICS_RESOURCE.PATIENT_ABSENCE_ANALYSIS ||
      sectionData.resourceCode ===
        ANALYTICS_RESOURCE.NOT_ENROLLED_FOR_CARE
        ? sectionData.name
        : 'Engaged Members';
    props?.onActionClick?.(sectionData.resourceCode || '', {
      filterData,
      count: 0,
      name: drillDownTitle,
    });
  };

  const getDetailWithMixBarChartWithStackColumnWithLineElem = () => {
    let ele = <></>;
    switch (props?.sectionData?.resourceCode) {
      case ANALYTICS_RESOURCE.NET_PROMOTER_SCORE: {
        ele = getNPSWidgetElem(props, stateData, getMixChartView, ele);
        break;
      }
      case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE:
      case ANALYTICS_RESOURCE.PATIENT_ABSENCE_ANALYSIS:
      case ANALYTICS_RESOURCE.NOT_ENROLLED_FOR_CARE: {
        ele = getEngageForCareElem(props, stateData, getMixChartView, ele, onNotEngagedInMonths);
        break;
      }
      default:
        break;
    }
    return ele;
  }

  const getMixPlotsConfigsForLineANDColumnStack = () => {
    const stackColumnConfig = getMixPlotForColumnStackChart();
    const finalMixPlotsConfigs: any = {
      appendPadding: 8,
      height: 240,
      color: graphColorList,
      renderer: isRendererCanvass ? 'canvas' : 'svg',
      tooltip: {
        shared: true,
        customContent: (title: string, data: any[]) => {
          const allTotalColumns: any[] = [];
          const notIncludeInAllCount = ['inactive members', 'total active members'];
          const notIncludeInMembershipCount = ['inactive members', 'one off', 'total active members'];
          const allMembershipColumns: any[] = [];
          data.forEach((singleItem) => {
            if (!notIncludeInAllCount?.includes(singleItem?.name?.toLowerCase())) {
              allTotalColumns.push(singleItem?.name?.toLowerCase());
            }
            if (!notIncludeInMembershipCount?.includes(singleItem?.name?.toLowerCase())) {
              allMembershipColumns.push(singleItem?.name?.toLowerCase());
            }
          });
          const totalMetaData: any[] = [
            {
              title: 'Total Membership Count',
              columnList: allMembershipColumns,
            }
          ];
          if (title && data?.length) {
            return <CustomTooltip title={title} data={data} totalMetaData={totalMetaData} />
          }
        },
      },
      syncViewPadding: true,
      plots: [stackColumnConfig],
      legend: {
        itemSpacing: 3,
        itemName: {
          formatter(text: any) {
            return text?.toLocaleUpperCase()
          },
        },
        position: 'top-right',
        layout: 'horizontal',
        marker: {
          symbol: 'circle',
          style(style: any) {
            return {
              ...style,
            }
          },
        }
      },
      xAxis: {
        title: {
          text: graphXAxisTitle.title,
          description: graphXAxisTitle.description,
          style: {
            fill: graphMetaDataColor.axisTextColor
          }
        },
      },
      yAxis: {
        title: {
          text: graphYAxisTitle.title,
          description: graphYAxisTitle.description,
          style: {
            fill: graphMetaDataColor.axisTextColor
          }
        },
      },
      onReady: (plot: any) => {
        plot.on('element:click', (args: any) => {
          if (args?.data?.data?.type) {
            onReady(args);
          }
        });
      },
    };
    return finalMixPlotsConfigs;
  }
  const getToolTipTextForAggregateValue = () => {
    switch (sectionData.resourceCode) {
      case ANALYTICS_RESOURCE.EMPLOYEE_COUNT:
        return 'Total Active Members';
      case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE:
        return 'Total Engaged Members';
      default:
        break;
    }
  }
  const getMixPlotForLineChart = () => {
    let lineDataList = formattedResourceData?.lineDataList;
    if (getTenantForDemo() && sectionData?.resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE) {
      const resourceData: any = getDemoDataForEmployerReportCurrentSection(sectionData);
      const dateFilter = getDateRangeFilterFromAppliedFilter(appliedFilter);
      const tempFormattedResourceData = getFormattedDataForMixChart(
        dateFilter,
        {...resourceData?.result},
        sectionData.currentDisplayType || '',
        sectionData.resourceCode || '',
        isAccountDashboard
      );
      lineDataList = tempFormattedResourceData.lineDataList;
    }

    const lineConfig = {
      type: 'line',
      options: {
        data: lineDataList,
        xField: 'month',
        yField: 'value',
        height: 245,
        renderer: isRendererCanvass ? 'canvas' : 'svg',
        xAxis: {
          tickLine: {
            style: {
              fill: '#fff',
              stroke: '#fff',
            },
          },
          title: {
            text: graphXAxisTitle.title,
            description: graphXAxisTitle.description,
            style: {
              fill: graphMetaDataColor.axisTextColor
            },
            offset: 60
          },
          label: false,
          line: {
            style: {
              stroke: '#F2F4F7',
            },
          },
          grid: {
            alignTick: true,
            line: {
              type: 'line',
              style: {
                stroke: '#F2F4F7'
              }
            },
            closed: true
          }
        },
        yAxis: {
          tickLine: {
            style: {
              fill: '#fff',
              stroke: '#fff',
            },
          },
          title: {
            text: graphYAxisTitle.title,
            description: graphYAxisTitle.description,
            style: {
              fill: graphMetaDataColor.axisTextColor
            }
          },
          line: {
            style: {
              stroke: '#F2F4F7',
            },
          },
          grid: {
            alignTick: true,
            line: {
              type: 'line',
              style: {
                stroke: '#F2F4F7'
              }
            },
            closed: true
          }
        },
        color: '#FF6B3B',
        meta: {
          date: {
            sync: 'date',
          },
          value: {
            sync: true,
            alias: getToolTipTextForAggregateValue(),
          },
        },
        label: {
          callback: (value: any) => {
            return {
              offsetY: value === 0.148 ? 36 : value === 0.055 ? 0 : 20,
              style: {
                fill: '#1AAF8B',
                fontWeight: 700,
                stroke: '#fff',
                lineWidth: 1,
              },
            };
          },
          formatter: () => {
            return ''
          }
        },
      },
    };
    return lineConfig;
  };

  const getMixPlotForColumnStackChart = () => {
    let columnStackDataList = formattedResourceData?.columnStackDataList;
    if (getTenantForDemo() && sectionData?.resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE) {
      const resourceData: any = getDemoDataForEmployerReportCurrentSection(sectionData);
      const dateFilter = getDateRangeFilterFromAppliedFilter(appliedFilter)
      const tempFormattedResourceData = getFormattedDataForMixChart(
        dateFilter,
        {...resourceData?.result},
        sectionData.currentDisplayType || '',
        sectionData.resourceCode || '',
        isAccountDashboard
      );
      columnStackDataList = tempFormattedResourceData.columnStackDataList;
    }
    let colorList = getGraphColorsByName(columnStackDataList as any);
    const yAxisMaxValue = getYAxisMaxValueWidgetObject(columnStackDataList, sectionData.resourceCode)
    if (isAccountDashboard) {
      colorList = graphColorList;
    } else {
      colorList = graphColorList;
    }
    const configStack: any = {
      type: 'column',
      options: {
        data: columnStackDataList,
        color: colorList,
        isStack: true,
        renderer: isRendererCanvass ? 'canvas' : 'svg',
        xField: 'month',
        yField: 'value',
        seriesField: 'type',
        label: {
          position: 'middle',
          formatter: () => {
            return ''
          }
        },
        height: 245,
        autoFit: true,
        xAxis: {
          tickLine: {
            style: {
              fill: '#fff',
              stroke: '#fff',
            },
          },
          title: {
            text: graphXAxisTitle.title,
            description: graphXAxisTitle.description,
            style: {
              fill: graphMetaDataColor.axisTextColor
            },
            offset: 60
          },
          label: {
            offset: 20,
            offsetY: -4,
            rotate: -1.1,
            style: {
              fontSize: 9
            }
          },
          line: {
            style: {
              stroke: '#F2F4F7',
            },
          },
          grid: {
            alignTick: true,
            line: {
              type: 'line',
              style: {
                stroke: '#F2F4F7'
              }
            },
            closed: true
          }
        },
        yAxis: {
          tickLine: {
            style: {
              fill: '#fff',
              stroke: '#fff',
            },
          },
          title: {
            text: graphYAxisTitle.title,
            description: graphYAxisTitle.description,
            style: {
              fill: graphMetaDataColor.axisTextColor
            }
          },
          line: {
            style: {
              stroke: '#F2F4F7',
            },
          },
          grid: {
            alignTick: true,
            line: {
              type: 'line',
              style: {
                stroke: '#F2F4F7'
              }
            },
            closed: true
          },
          ...(yAxisMaxValue)
        },
        meta: {
          date: {
            sync: true,
          },
          value: {
            sync: true,
          },
        },
        annotations: aggregateGroupEntry(sectionData.resourceCode || '', columnStackDataList),
        columnStyle(datum: any) {
          return {
            radius: [2, 2, 0, 0],
          };
        },
        onReady: (plot: any) => {
          plot.on('element:click', (args: any) => {
            if (args?.data?.data?.type) {
              onReady(args);
            }
          });
        },
      }
    };

    return configStack;
  };

  const onReady = (data: any) => {
    let filterData = {} as any;
    const fromDate = getFormattedDateStringInTimeZone(
      data?.data?.data?.date,
      `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
      MOMENT_DATE_CONST.START_OF
    );
    const toDate = getFormattedDateStringInTimeZone(
      data?.data?.data?.date,
      `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`,
      MOMENT_DATE_CONST.END_OF
    );
    const diffDate = moment(new Date(toDate)).diff(new Date(fromDate), 'month', true)
    const type: string = data?.data?.data?.type;
    const dataType = capitalizeText(type);
    const dateOrMonth = data?.data?.data?.date || data?.data?.data?.month
    switch (sectionData.resourceCode) {
      case ANALYTICS_RESOURCE.EMPLOYEE_COUNT:
      case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE:
        let memberType = type?.toLowerCase();
        if (sectionData?.resourceCode === ANALYTICS_RESOURCE.ENROLLED_FOR_CARE) {
          memberType = data?.data?.data?.filterCode;
        }
        filterData = [
          {
            filterCode: 'DATE_RANGE',
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
          },
          {
            filterCode: 'EMPLOYEE_TYPE',
            filterValue: [memberType],
            count: data?.data?.data?.value
          }
        ];
        filterData = [...filterData, ...stateData.selectedAppliedFilter]
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value || data?.data?.data?.xField, type: dataType, date: dateOrMonth});
        break;
      case ANALYTICS_RESOURCE.NET_PROMOTER_SCORE:
        filterData = [
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.DATE_RANGE,
            filterValue: {
              fromDate: fromDate,
              toDate: toDate,
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.FORM_ID,
            filterValue: {
              value: sectionData?.additionalAttributes?.formId
            },
          },
          {
            filterCode: EMPLOYER_REPORT_FILTER_CODES.NPS_SCORE_CATEGORY,
            filterValue: {
              value: data?.data?.data?.filterCode
            },
          },
        ];
        filterData = [...filterData, ...stateData.selectedAppliedFilter]
        props?.onActionClick?.(sectionData.resourceCode || '', {filterData, count: data?.data?.data?.value || data?.data?.data?.xField, type: dataType, date: dateOrMonth});
        break;
    }
  };
  const barChartView = useMemo(() => {
    return getBarChartViewByType()
  }, [stateData.loading])
  const getSectionByResourceCode = async (appliedFilter?: IAppliedFilter[], selectedFormId?: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let formId = '';
    if ((sectionData.resourceCode === ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION)) {
      formId = sectionData?.additionalAttributes?.formId;
    } else if (sectionData.resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE) {
      formId = selectedFormId || sectionData?.additionalAttributes?.formId;
    }
    let filterList: any = appliedFilter?.length ? appliedFilter : stateData.appliedFilter;
    if (!filterList?.length) {
      filterList = [];
    }
    let appliedFilterList = [];
    if (sectionData?.appliedFilter) {
      appliedFilterList = getEmployerReportFilterList(sectionData?.appliedFilter);
      if (appliedFilterList?.length && filterList?.length) {
        filterList = [...filterList, ...appliedFilterList];
      } else if (appliedFilterList?.length) {
        filterList = [...appliedFilterList];
      }
    }
    if (stateData.appliedFilter?.length && sectionData.resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE && formId) {
      filterList = [
        ...stateData.appliedFilter,
        {
          filterCode: EMPLOYER_REPORT_FILTER_CODES.FORM_ID,
          filterValue: {
            value: formId
          }
        }
      ];

    }

    if(stateData.appliedFilter?.length && sectionData.resourceCode === ANALYTICS_RESOURCE.ENROLLED_FOR_CARE) {
      filterList = [
        ...stateData.appliedFilter,
        {
          filterCode: EMPLOYER_REPORT_FILTER_CODES.ENGAGED_OF_CARE,
          filterValue: {
            value: true
          }
        }
      ]
    }

    if(stateData.appliedFilter?.length && sectionData.resourceCode === ANALYTICS_RESOURCE.NOT_ENROLLED_FOR_CARE) {
      filterList = [
        ...stateData.appliedFilter,
        {
          filterCode: EMPLOYER_REPORT_FILTER_CODES.ENGAGED_OF_CARE,
          filterValue: {
            value: false
          }
        }
      ]
    }
    if (hintMembershipDisabled) {
      filterList.push({
        filterCode: EMPLOYER_REPORT_FILTER_CODES.MEMBERSHIP, 
        filterValue: hintMembershipDisabled
      })
    }
    const response = await getSectionDataByResourceCode(
      employerId,
      sectionData.resourceCode || '',
      filterList,
      formId,
      isAccountDashboard,
      memberType,
      sectionData.id
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          resourceData: {
            columnStackDataList: [],
            lineDataList: []
          },
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data && (response?.data?.result?.columnStackDataList?.length || isWidgetDataEmpty(response?.data))) {
      const containerHight = containerRef?.current?.offsetHeight;
      const titleWrapperHight = titleRef?.current?.offsetHeight;
      const heightVariable = (props.sectionData?.subHeading?.length || 0) > 100 ? 20 : 10
      const graphWrapper = containerHight - titleWrapperHight - heightVariable
      let resourceData = response.data?.result;
      if (sectionData?.resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE) {
        resourceData = response.data;
      }
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
          graphWrapperHeight: graphWrapper
        };
      });
    } else {
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: {
            columnStackDataList: [],
            lineDataList: []
          },
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };
  const controller = new AbortController();
  useEffect(() => {
    if (getTenantForDemo()) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: true
        }
      })
      const resourceData: any = getDemoDataForEmployerReportCurrentSection(sectionData);
      setTimeout(() => {
        setStateData((prev) => {
          return {
            ...prev,
            resourceData: resourceData,
            loading: false
          }
        })
      }, 3000)
      return
    } else {
      getSectionByResourceCode();
    }
    return () => controller?.abort();
  }, [sectionData.id])
  useEffect(() => {
    if (props.filterData?.resourceCode == sectionData.resourceCode) {
      const appliedFilter = stateData.appliedFilter?.length ? [...stateData.appliedFilter] : [];
      const selectedFilter = {
        filterCode: props.filterData?.filterCode,
        filterValue: props.filterData?.filterValue
      }
      let filterValueString = '';
      props.filterData?.filterValueName?.forEach((item: string, index: number) => {
        if (index < 2) {
          return
        }
        filterValueString = filterValueString + (filterValueString.length ? " | " : '') + item
      })
      setStateData(prev => {
        return {
          ...prev,
          filterValueName: props.filterData?.filterValueName,
          filterValueString: filterValueString,
          selectedAppliedFilter: [selectedFilter]
        }
      })
      appliedFilter.push(selectedFilter);
      getSectionByResourceCode(appliedFilter);
    }
    return () => controller?.abort();
  }, [props.filterData?.filterValue])

  const getRefreshButtonOnLocalEnv = () => {
    if (window.location.host.includes('localhost')) {
      return (
        <Pressable
          style={{marginHorizontal: 3}}
          onPress={() => {
            setStateData(prev => {
              return {
                ...prev,
                filterValueName: [],
                filterValueString: ''
              }
            })
            getSectionByResourceCode();
          }}
        >
          <Tooltip label="Refresh">
            <RestSvgIcons />
          </Tooltip>
        </Pressable>
      )
    }
    return <></>;
  }
  const getMainWidgetWidth = () => {
    let width = '88%';
    switch (sectionData.resourceCode) {
      case ANALYTICS_RESOURCE.NET_PROMOTER_SCORE:
      case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE:
        width = '92%';
        break;
    }
    return width;
  }

  return (
    <div ref={containerRef} className="widgetBoxShadow">
      <div ref={titleRef}>
        <WidgetHeader isPrintPreview={props.isPrintPreview} isPrintDownload={props.isPrintDownload} refreshButtonOnLocalEnv={getRefreshButtonOnLocalEnv()} sectionData={sectionData} onActionClick={onActionClick} />
      </div >
      {
        stateData.loading ? (
          <Skeleton style={{padding: 40}} active={true} />
        ) : (
          <>
            {(stateData.resourceData &&
            (stateData.resourceData?.columnStackDataList?.length ||
              isWidgetDataEmpty({result: stateData.resourceData}))) ||
              (
                sectionData.resourceCode === ANALYTICS_RESOURCE.NET_PROMOTER_SCORE
                && (stateData.resourceData?.result?.columnStackDataList?.length)
              ) ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  height: '75%'
                }}
              >
                <main style={{alignSelf: 'center', width: '100%', height: '100%', justifyContent: 'center', display: 'flex', alignItems: 'flex-start'}}>
                  <main
                    style={{padding: '10px 0px', width: getMainWidgetWidth()}}
                    className={stateData?.resourceData?.columnStackDataList?.length && props.isPrintPreview ? 'canvas-wrapper' : ''}
                  >
                    {barChartView}
                  </main>
                </main>
              </div>
            ) :
              (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '60%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <NodataViewTypeOne viewCode={'TEXT_ICON'} />
                </div>
              )
            }
          </>
        )
      }
      {stateData.selectedView === WIDGET_ACTION_CODE.FORM_LIST_VIEW ? (
        <FormListView
          sectionData={sectionData}
          formId={stateData.formId}
          onClose={(args?: any) => {
            if (args?.code === COMMON_ACTION_CODES.COMPLETED) {
              setStateData(prev => {
                return {
                  ...prev,
                  formId: args.formId,
                  selectedView: '',
                  loading: true
                }
              })
              getSectionByResourceCode([], args.formId)
            }
            setStateData((prev) => {
              return {
                ...prev,
                selectedView: ''
              };
            });
          }}
        />
      ) : (
        <></>
      )}
    </div >
  );
};

export default React.memo(MixLineStackColumnChart);



