import {gql} from '@apollo/client';

export const GET_CPT_CODES = gql`
    query getCptCodes {
        careProgramCptCodes(where: {isDeleted: {_eq: false}}) {
            cptCode
        }
    }
`;


export const GET_CONTACT_CARE_PROGRAM_BILLABLE_ACTIVITY_ENTITIES = gql`
  query getEntities($where: contact_care_program_billable_activity_entities_bool_exp!, $limit:Int, $offset: Int) {
    contactCareProgramBillableActivityEntities(where: {_and: [$where, {isDeleted: {_eq: false}}]}, limit: $limit, offset: $offset, order_by: {createdOn: desc}) {
      resourceId
      resourceTypeCode
      createdOn
      durationInMinutes
      createdBy
      updatedBy
      performedByUserId
      timestamp
    }
    aggregateContactCareProgramBillableActivityEntities(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_BILLABLE_ACTIVITIES_BY_MONTH = gql`
query getBilledActivitiesByMonth($params: GetBilledActivitiesByMonthInput!) {
  getBilledActivitiesByMonth(params: $params) {
    records {
      month
      totalAmount
      distinctContactIdCount
      ehrCode
    }
    totalRecords
    timezone
  }
}
`;

export const GET_BILLING_MINS_BY_MONTH = gql`
query getBillableActivities($params: GetBillableActivitiesInput!) {
  getBillableActivities(params: $params) {
    records {
      id
      contactId
      totalDurationInMinutes
    }
  }
}
`;

export const GET_BILLABLE_ATTRIBUTES_AND_CODES = gql`
query getBillableActivities($offset: Int, $limit: Int, $where: contact_care_program_billable_activity_bool_exp!) {
  contactCareProgramBillableActivities(offset: $offset, limit: $limit, where: {_and: [$where, {isDeleted: {_eq: false}}]}) {
    contactCareProgram {
      additionalAttributes {
        attributeKey
        attributeValue
      }
    }
    billableActivityCptCodes {
      careProgramCptCode {
        amount
        durationInMinutes
        cptCode
      }
    }
  }
}
`;

export const GET_CARE_PROGRAM_BILLABLE_CODES = gql`
query getCareProgramBillableActivities($offset: Int, $limit: Int, $where: contact_care_program_billable_activity_bool_exp!) {
  contactCareProgramBillableActivities(offset: $offset, limit: $limit, where: {_and: [$where, {isDeleted: {_eq: false}}]}) {
    timestamp
    performedByUserId
    statusId
    billableActivityCptCodes(where: {isDeleted: {_eq: false}}) {
      careProgramCptCode {
        amount
        durationInMinutes
        cptCode
        createdOn
      }
    }
  }
}
`;

export const GET_BILLABLE_ACTIVITIES = gql`
query getBillableActivities($params: GetBillableActivitiesInput!) {
  getBillableActivities(params: $params) {
    records {
      id
      careProgramTypeId
      contactId
      serviceDateTime
      totalAmount
      totalDurationInMinutes
      primaryCarePhysicianUserId
    }
    totalRecords
  }
}
`;

export const PROCESS_BILLING = gql`
  mutation processBilling($params: ProcessContactCareProgramBillableActivityInput!) {
    processContactCareProgramBillableActivity(params: $params) {
      id
    }
  }
`;

export const GET_BILL_ACTIVITIES_SUM = gql`
  query getBillableActivitiesSum($params: GetBillableActivitiesAmountSumInput!) {
    getBillableActivitiesAmountSum (params: $params) {
      totalAmount
    }
  }
`;

export default {
    GET_CPT_CODES,
    GET_CONTACT_CARE_PROGRAM_BILLABLE_ACTIVITY_ENTITIES,
    GET_BILLABLE_ACTIVITIES_BY_MONTH,
    GET_BILLING_MINS_BY_MONTH,
    GET_BILLABLE_ATTRIBUTES_AND_CODES,
    GET_BILLABLE_ACTIVITIES,
    GET_BILL_ACTIVITIES_SUM,
  };
