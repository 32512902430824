import AntIcon from 'react-native-vector-icons/AntDesign';
import React, { useEffect, useState } from "react";
import { Popover, Tooltip } from "antd";
import { Dimensions, Pressable, View, Text} from 'react-native';
import {ITagsTableData} from '../../../Contacts';
import {Colors} from '../../../../../styles';
import {PACKAGES_TAG_VALUE_MAX_DISPLAY_CHAR} from './PackagesConst';
import {IJourneyPackageTagsFilter} from './interfaces';
import {PackagesTagSearchAndSelect} from './PackagesTagSearchAndSelect';


export const JourneyPackageTagsFilter = (props: IJourneyPackageTagsFilter) => {
    const [stateData,setStateData] = useState({
      selectedProductPackageTag: [] as ITagsTableData[],
      searchTagString: '',
    });
    const [showChild, setShowChild] = useState(false);
    const {width} = Dimensions.get('window');

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedProductPackageTag: props?.tags || [],
      };
    });
  }, [props?.tags]);

    const popoverContent: JSX.Element = (
      <View style={{
          backgroundColor: Colors.Custom.White,
          position: 'relative',
          minHeight: 30,
          maxHeight: 500,
          width: 400,
          overflow: 'scroll'
        }}
      >
        <PackagesTagSearchAndSelect
          onDetailChange={(selectedLabels: ITagsTableData[]) => {
            setStateData((prev) => {
              return {
                ...prev,
                selectedProductPackageTag: selectedLabels,
              }
            })
          }}
          showChild={showChild}
          productPackageTag={stateData?.selectedProductPackageTag}
          defaultValue={stateData?.searchTagString}
          onTextChange={(text?: string) => {
            setStateData((prev) => {
              return {
                ...prev,
                searchTagString: text || '',
              }
            })
          }}
        />
      </View>
    )
    const selectedTag = stateData?.selectedProductPackageTag?.[0]?.title;

    return (
        <Popover
          content={popoverContent}
            trigger={"click"}
            placement="bottomLeft"
            open={showChild}
            destroyTooltipOnHide
            onOpenChange={(visible: boolean) => {
              setShowChild(visible);
              if (!visible) {
                setStateData((prev) => {
                  return {
                    ...prev,
                    searchTagString: '',
                  };
                });
                props?.onClose?.(stateData?.selectedProductPackageTag);
              }
            }}
        >
            <Pressable
              onPress={() => {
                const visible = !showChild;
                setShowChild(visible);
                if (!visible) {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      searchTagString: '',
                    };
                  });
                  props?.onClose?.(stateData?.selectedProductPackageTag);
                }
              }}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                borderWidth: 1,
                borderRadius: 8,
                borderColor: Colors.Custom.Gray200,
                justifyContent: 'space-between',
                backgroundColor: Colors.Custom.White,
                minHeight: 40,
                paddingHorizontal: 10,
                minWidth: 126
            }}>
              {props.label && <Text style={{
                  fontSize: 13,
                  marginRight: 2,
                  color: Colors.Custom.Gray700
                }}
              >
                {props.label}{stateData?.selectedProductPackageTag?.length ? ': ' : ''}
              </Text>}
              {stateData?.selectedProductPackageTag?.length > 0 && <>
                <View style={{
                    maxWidth: width/16,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      marginRight: 2,
                      overflow: 'hidden',
                      fontWeight: '600'
                    }}
                  >
                    <Tooltip
                      title={selectedTag?.length > PACKAGES_TAG_VALUE_MAX_DISPLAY_CHAR ? selectedTag : ''}
                      placement={'top'}
                      destroyTooltipOnHide={true}
                    >
                      {selectedTag?.length > PACKAGES_TAG_VALUE_MAX_DISPLAY_CHAR ? `${selectedTag?.slice(0, PACKAGES_TAG_VALUE_MAX_DISPLAY_CHAR)}...` : selectedTag}
                    </Tooltip>
                  </Text>
                </View>
                {stateData?.selectedProductPackageTag?.length > 1 && <Text style={{
                    fontSize: 14,
                    marginRight: 2,
                    fontWeight: '600'
                  }}
                >
                  {('+'+(stateData?.selectedProductPackageTag?.length - 1))}
                </Text>}
              </>}
              <AntIcon name="down" color={Colors.Custom.Gray300} size={14} />
            </Pressable>
        </Popover>
    )
}
