import {IconButton, Text, View} from 'native-base';
import React from 'react';
import {TextStyle, ViewStyle} from 'react-native';
import {styles} from './ScriptContainerStyles';
import AntIcon from 'react-native-vector-icons/AntDesign';

export interface IScriptContainerProps {
  scriptText: string;
  wrapperStyle?: ViewStyle;
  scriptTextStyle?: TextStyle;
  onCopyScript: () => void;
}

const ScriptContainer = (props: IScriptContainerProps) => {
  const {scriptText, wrapperStyle, scriptTextStyle} = props;
  return (
    <View style={[styles.wrapper, wrapperStyle]}>
      <IconButton
        alignSelf={'flex-end'}
        icon={<AntIcon name="copy1" size={20} />}
        onPress={() => {
          props.onCopyScript();
        }}
      />
      <Text style={[styles.scriptText, scriptTextStyle]}>{scriptText}</Text>
    </View>
  );
};

export default ScriptContainer;
