import {Button, HStack, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {boardData} from '../../../../../dummyData/Sales';
import {Colors} from '../../../../../styles/Colors';
import {pipelineArray} from '../../../../../dummyData/Sales/boardDummyData';
import {BoardListView} from '../BoardListView';
import {BoardModal} from '../BoardModal';
import {Pipeline} from '../Pipeline';
import {styles} from './BoardPipelineStyle';

const BoardPipeline = () => {
  const [showModal, setShowModal] = useState(false);
  const [boardList, setBoardList]: any = useState(boardData);
  const [updateBoard, setUpdateBoard] = useState({} as any);
  const [selectedBoard, setSelectedBoard]: any = useState(boardData[0]);
  const [pipelineData, setPipelineData] = useState(pipelineArray);

  const onBoardActionPerformed = (
    actionCode: string,
    boarName: any,
    isModalOpen: boolean,
    boardObj: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.SAVE:
        addNewBoard(boarName);
        setUpdateBoard({});
        break;
      case COMMON_ACTION_CODES.EDIT:
        editBoard(boardObj);
        setUpdateBoard({});
        break;
      case COMMON_ACTION_CODES.DELETE:
        deleteBoard(boardObj);
        break;
      case COMMON_ACTION_CODES.EDIT_MODE:
        setUpdateBoard(boardObj);
        break;
      case COMMON_ACTION_CODES.ITEM_SELECT:
        setSelectedBoard(boardObj);
        break;
      case COMMON_ACTION_CODES.CANCEL:
        setUpdateBoard({});
        break;
    }
    handleModalState(isModalOpen);
  };

  const addNewBoard = (newBoard: any) => {
    const newBoardD = {
      id: Date.now(),
      title: newBoard,
    };
    // boardList.push(newBoardD);
    setBoardList((prev: any) => [...prev, newBoardD]);
    handleModalState(false);
  };

  const editBoard = (boardObj: any) => {
    boardList.forEach((element: any, index: any) => {
      if (element.id === boardObj.id) {
        boardList[index] = boardObj;
      }
    });
  };

  const deleteBoard = (item: any) => {
    const newBoardData = boardList.filter((board: any) => {
      return board.id !== item.id;
    });
    setBoardList(newBoardData);
  };

  const onPipelineActionPerformed = (actionCode: any, pipelineObj: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.SAVE:
        setPipelineData([...pipelineData, pipelineObj]);
        break;
      case COMMON_ACTION_CODES.EDIT:
        editPipeline(pipelineObj);
        break;
      case COMMON_ACTION_CODES.DELETE:
        deletePipeline(pipelineObj);
        break;
    }
  };
  const deletePipeline = (obj: any) => {
    const newTableData = pipelineData.filter((rowData: any) => {
      return rowData.key !== obj.key;
    });
    setPipelineData(newTableData);
  };

  const editPipeline = (pipelineObj: any) => {
    pipelineData.forEach((element: any, index: any) => {
      if (element.key === pipelineObj.key) {
        pipelineData[index] = pipelineObj;
      }
    });
    setPipelineData(pipelineData);
  };

  const handleModalState = (isOpened: boolean) => {
    setShowModal(isOpened);
  };

  return (
    <View backgroundColor={Colors.Custom.BackgroundColor} flex={1}>
      <HStack flex={1}>
        <View flex={0.2} style={styles.leftContainer}>
          <View style={styles.leftHeaderContainer}>
            <Button
              colorScheme={'primary'}
              _text={{color: 'white'}}
              style={styles.buttonStyle}
              onPress={() => {
                handleModalState(true);
              }}
            >
              Add New Board
            </Button>
          </View>
          <View>
            <BoardListView
              boardData={boardList}
              selectedBoard={selectedBoard}
              onActionPerformed={onBoardActionPerformed}
            />
          </View>
        </View>
        <View flex={0.8} style={styles.rightContainer}>
          <Pipeline
            selectedBoard={selectedBoard}
            boardList={boardList}
            pipelineData={pipelineData}
            onPipelineAction={onPipelineActionPerformed}
          />
        </View>
      </HStack>
      <BoardModal
        showModal={showModal}
        boardData={updateBoard}
        onActionPerformed={onBoardActionPerformed}
        handleModalState={handleModalState}
      />
    </View>
  );
};

export default BoardPipeline;
