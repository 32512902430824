import React from "react";

const VectorBuildingSvg = (props: {color?: string})=> {
  const svgColors = props.color ? props.color : '#7F56D9'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 34 45"
    >
      <path
        fill={svgColors}
        d="M5.188.5A5.063 5.063 0 00.125 5.563v37.124c0 .932.756 1.688 1.688 1.688h5.062v-9.563a1.687 1.687 0 011.688-1.687h16.874a1.687 1.687 0 011.688 1.688v9.562h5.063a1.687 1.687 0 001.687-1.688v-20.25a5.062 5.062 0 00-5.063-5.062h-1.687V5.562A5.063 5.063 0 0022.062.5H5.188zm1.687 10.125a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zm2.25 13.5a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5zM6.875 18.5a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM17 8.375a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5zm-2.25 18a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zm10.125-2.25a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5zM14.75 18.5a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zm9 25.875V36.5h-5.063v7.875h5.063zm-8.438 0V36.5H10.25v7.875h5.063z"
      ></path>
    </svg>
  );
}

export default React.memo(VectorBuildingSvg);
