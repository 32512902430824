import {HStack, VStack} from 'native-base';
import {ScrollView} from 'react-native';
import {styles} from '../../../CampaignStyles';
import {EmailSubject} from './EmailSubject';
import {EmailTemplate} from './EmailTemplate';
import {FromUser} from './FromUser';
import {ReplyTo} from './ReplyTo';
import {Schedule} from './Schedule';
import {Sender} from './Sender';

export const CampaignStepThreeScreen = () => {
  return (
    <HStack flex={1}>
      <VStack flex={0.3} style={styles.leftStack}>
        <ScrollView>
          <VStack space={6}>
            <FromUser />
            <Sender />
            <ReplyTo />
            <EmailSubject />
            <EmailTemplate />
            <Schedule />
          </VStack>
        </ScrollView>
      </VStack>
      <VStack flex={0.7}></VStack>
    </HStack>
  );
};
