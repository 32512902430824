import { ITabsList } from "../../../common/CareDashboard/CareDashboardTopBar/interfaces";
import { IDashboardProgramFilter } from "../../../common/MemebersView/interface";
import { DocStatus } from "./interfaces";

export const ADD_NEW_FORM = 'ADD_NEW_FORM';
export const CREATE_NEW_FORM = 'Create New Template';
export const SOAP_NOTE_FORM_CODE = 'SOAP'
export const PHONE_NOTE_FORM_CODE = 'PHONE'

export const NARRATIVE = 'Narrative'

export const ELATION_NOTE_CATEGORY_DISPLAY = {
    NONE:'',
    VISIT: 'Visit',
    NON_VISIT : 'Non Visit'
}

export const ELATION_NOTE_TYPE = {
  VISIT: 'visit_notes',
  NON_VISIT: 'non_visit_notes',
};

export const REASON = 'Reason'


export const ORDER = 'Order'

export enum CARE_TIME_LINE_VIEW {
  READ_ONLY_NOTE = 'READ_ONLY_NOTE',
  SIGNED_NOTE = 'SIGNED_NOTE',
  ADD_OR_UPDATE_NOTE = 'ADD_OR_UPDATE_NOTE',
  TIMELINE_LIST_VIEW = 'TIMELINE_LIST_VIEW',
  ENCOUNTER_VIEW = 'ENCOUNTER_VIEW'
}

export const PATIENT_NOTE_OPERATION = {
  PRINT: 'PRINT',
  DELETE: 'DELETE',
  SIGN: 'SIGN',
  SAVE: 'SAVE',
  UNLOCK: 'UNLOCK',
  REFETCH: 'REFETCH',
  VIEW_SIGNED_NOTE: 'VIEW_SIGNED_NOTE',
  CREATE_NOTE: 'CREATE_NOTE',
  CREATE_NOTE_WITH_LINK_APPOINTMENT: 'CREATE_NOTE_WITH_LINK_APPOINTMENT',
  EDIT_NOTE: 'EDIT_NOTE',
  EDIT_INPROGRESS_NOTE: 'EDIT_INPROGRESS_NOTE',
  VIEW_CARE_TIMELINE: 'VIEW_CARE_TIMELINE',
  READ_ONLY_NOTE: 'READ_ONLY_NOTE',
  WITHOUT_REFETCH_LIST_VIEW: 'WITHOUT_REFETCH_LIST_VIEW',
  VIEW_ENCOUNTER: 'VIEW_ENCOUNTER',
  PRINT_SHAREABLE: 'PRINT_SHAREABLE',
}

export const RESOURCE_AUDIT_STATUS = {
  CREATE: 'create',
  SIGN: 'sign',
  UNLOCK: 'unlock',
  AMEND: 'amend'
};

export const STATUS_FOR_FILTER = [
  { code: DocStatus.PRELIMINARY, value: 'In Progress' },
  { code: DocStatus.FINAL, value: 'Signed' },
  { code: DocStatus.AMENDED, value: 'Amended And Signed' },
];

export const careTimelineTabList:IDashboardProgramFilter[] = [
  {
    categoryCode: 'All',
    displayName: 'All',
    sequence: 1,
  },
  {
    displayName: 'Appointments',
    categoryCode: 'Appointment',
    sequence: 2,
  },
  {
    displayName: 'Notes',
    categoryCode: 'DocumentReference',
    sequence: 3,
  },
  {
    displayName: 'Labs/Imaging',
    categoryCode: 'DiagnosticReport',
    sequence: 4,
  },
  {
    displayName: 'PAMI',
    categoryCode: 'PAMI',
    sequence: 5,
  }
];

export enum CARE_TIMELINE_TABS {
  ALL = 'All',
  // APPOINTMENT_LAST_VISIT = 'AppointmentLastVisit',
  APPOINTMENT = 'Appointment',
  DOCUMENT_REFERENCE = 'DocumentReference',
  DIAGNOSTIC_REPORT = 'DiagnosticReport',
  PAMI = 'PAMI',
  IMAGING = 'Imaging'
};

export enum TIMELINE_RESOURCES {
  APPOINTMENT = 'Appointment',
  DOCUMENT_REFERENCE = 'DocumentReference',
  DIAGNOSTIC_REPORT = 'DiagnosticReport',
}
