import React from "react";

function CarePlanStatusSkipped() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#F6F7F8"
        d="M.5 8.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0z"
      ></path>
      <path
        stroke="#6F7A90"
        d="M.5 8.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0z"
      ></path>
      <path
        stroke="#6F7A90"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.204 11.578l2.461-2.872-2.461-2.872m-2.872 5.744l2.462-2.872-2.462-2.872"
      ></path>
    </svg>
  );
}

export default React.memo(CarePlanStatusSkipped);