
import React from 'react';
import {Image, Spinner, View} from 'native-base'
import { ICommonSvgProps } from '../interfaces';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { Colors } from '../../../../styles/Colors';

const CallHold = (props: ICommonSvgProps) => {
  const isWebsite = isWeb();
  return (
    <>
    {isWebsite ?
        props?.showSpinnerWhenLoading ?
          <View
            width={38}
            height={38}
            bg={Colors.Custom.IconColorWhite}
            borderRadius={19}
            style={{
              shadowColor: '#667085',
              shadowOpacity: 1,
              shadowRadius: 1,
              shadowOffset: {
                width: 0,
                height: 1,
              },
              justifyContent: 'center',
              alignItems: 'center',
            }}
            marginBottom={1}
          >
            {<Spinner />}
          </View>
    :
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? 46}
      height={props.height ?? 45}
      fill="none"
      viewBox="0 0 46 45"
    >
      <g filter="url(#filter0_dd_2779_215377)">
        <rect width="38" height="38" x="4" y="2.5" fill={props.isActive ? "#825AC7": "#fff"} rx="19"></rect>
        <path
          stroke={!props.isEnabled ? "#E4E7EC" : props.isActive ? "#fff" :"#667085"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M21.333 24v-5m3.334 5v-5m6.666 2.5a8.333 8.333 0 11-16.666 0 8.333 8.333 0 0116.666 0z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_2779_215377"
          width="45"
          height="45"
          x="0.5"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2779_215377"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_2779_215377"
            result="effect2_dropShadow_2779_215377"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_2779_215377"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
     :
     <Image
       resizeMode="contain"
       size={'32px'}
       source={require('../../../../assets/images/PNGImages/call_hold.png')}
       alt="image"
     />
   }
 </>
  );
};

export default CallHold;
