import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';

export const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: Colors.Custom.Gray100,
    borderRadius: 20,
    padding: 20,
    height: '100%',
    overflow: 'scroll',
  },
  scriptText: {
    textAlign: 'left',
  },
});
