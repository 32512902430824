import {LOCAL_SUPPORTED_EVENT_CODE_LIST, SUPPORTED_EVENT_CODE, SUPPORTED_EVENT_CODE_LIST} from '../constants/WebSocketConst';
import {broadcastEventToSidecarActionsScreen} from '../sidecar/common/SideCardCommonUtils';
import {EventBus} from './EventBus';
declare const window: any;
export const broadcastEvent = (eventCode: string, eventData: any): void => {
  const eventBus = EventBus.getEventBusInstance();

  if (SUPPORTED_EVENT_CODE_LIST.includes(eventCode)) {
    eventBus.broadcastEvent(eventCode, eventData);
    if (window.isExtension) {
      broadcastEventToSidecarActionsScreen('', eventCode, eventData);
    }
  } else {
    throw new Error(
      eventCode + ' is not supported please add in supportedEventCode'
    );
  }
};

export const localBroadcastEvent = (eventCode: string, eventData: any): void => {
  const eventBus = EventBus.getEventBusInstance();

  if (LOCAL_SUPPORTED_EVENT_CODE_LIST.includes(eventCode)) {
    eventBus.broadcastEvent(eventCode, eventData);
  } else {
    throw new Error(
      eventCode + ' is not supported please add in supportedEventCode'
    );
  }
};

