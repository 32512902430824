import BaseService from '../../../../../../services/CommonService/BaseService';
import CommonService from '../../../../../../services/CommonService/CommonService';
import {
  IEmailInboxCreateParams,
  IEmailInboxCreateResp,
  IEmailInboxMemberUpdateParams,
  IForwardRoute,
  IUpdateEmailChannelImapConfig,
  IUpdateEmailChannelSmtpConfig,
} from './interfaces';

export const updateInboxMembers = (params: IEmailInboxMemberUpdateParams) => {
  const pathParams = params.pathParams;
  const bodyParams = params.bodyParams;
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.axiosService;
  return axiosService.patch(
    `/accounts/${pathParams.accountId}/inbox_members`,
    bodyParams
  );
};

export const createSMTPEmail = (bodyParams: {
  username: string;
  domain: string;
}) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.post(`/crm-nest/smtp-credentials`, bodyParams);
};

export const createEmailInboxAPI = (params: {
  bodyParams: IEmailInboxCreateParams;
  pathParams: {
    accountId: number;
  };
}) => {
  const pathParams = params.pathParams;
  const bodyParams = params.bodyParams;
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.axiosService;
  return axiosService.post<IEmailInboxCreateResp>(
    `/accounts/${pathParams.accountId}/inboxes`,
    bodyParams
  );
};

export const updateImapChannelSettingsForEmailInbox = async (params: {
  bodyParams: IUpdateEmailChannelImapConfig;
  pathParams: {
    accountId: string;
    inboxId: string;
  };
}) => {
  const pathParams = params.pathParams;
  const bodyParams = params.bodyParams;
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.axiosService;
  return axiosService.patch<IUpdateEmailChannelImapConfig>(
    `/accounts/${pathParams.accountId}/inboxes/${pathParams.inboxId}`,
    bodyParams
  );
};

export const createEmailForwardRoute = (bodyParams: {
  recipientEmail: string;
  description: string;
  channelId: string;
}) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.post<IForwardRoute>(`crm-nest/mail/routes`, bodyParams);
};

export const deleteEmailForwardRoute = (routeId: string) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.delete<IForwardRoute>(`crm-nest/mail/routes/${routeId}`);
};

export const updateSmtpChannelSettingsForEmailInbox = async (params: {
  bodyParams: IUpdateEmailChannelSmtpConfig;
  pathParams: {
    accountId: string;
    inboxId: string;
  };
}) => {
  const pathParams = params.pathParams;
  const bodyParams = params.bodyParams;
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.axiosService;
  return axiosService.patch<IUpdateEmailChannelSmtpConfig>(
    `/accounts/${pathParams.accountId}/inboxes/${pathParams.inboxId}`,
    bodyParams
  );
};
