import {Table, Spin} from 'antd';
import {View} from 'native-base';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {getCustomFiledColumns} from './Helper';
import {TableWrapper} from '../../../common/TableWrapper';
import {ICustomFieldData, ICustomFieldTable} from './interface';


export const CustomFieldTable = (props: ICustomFieldTable) => {
  const { data, onActionPerformed , loading, pageSize, pageNo, total } = props;
  const screenHeight = Dimensions.get('window').height;

  const onCustomFieldActionPerformed = (
    actionCode: string,
    rowRecord: ICustomFieldData
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        onActionPerformed(actionCode, rowRecord);
        break;
      case COMMON_ACTION_CODES.STATUS_CHANGED:
        onActionPerformed(actionCode, rowRecord);
        break;
      default:
        break;
    }
  };

  return (
    <View flex={1}>
      <TableWrapper
        pagination={{
          pageSize: pageSize,
          current: pageNo,
          total: total,
          onChange(page, pageSize) {
            if (props?.onPagination) {
              props.onPagination(page, pageSize);
            }
          },
          showSizeChanger: true,
        }}
        hideOnSinglePage={false}
        customStyle={{zIndex: 999}}
      >
        <Table
          style={{
            backgroundColor: '#fff',
          }}
          scroll={{x: 700, y: screenHeight - 260}}
          columns={getCustomFiledColumns(
            onCustomFieldActionPerformed
          )}
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          loading={{
            spinning: loading,
            indicator: (
              <View style={{
                justifyContent: 'center',
                height: '100%'
              }}>
                <Spin/>
              </View>
            ),
          }}
          dataSource={data}
          pagination={false}
          rowKey={(record: ICustomFieldData) => record.id}
        />
      </TableWrapper>
    </View>
  );
};
