import BaseService from "../CommonService/BaseService";

export const addOrUpdateUsersRolePermissions = (body: {
    entityCode: string;
    userRoleCode: string;
    actionCode: string
    permissionCode: string;
  }) => {
    const axios = BaseService.getSharedInstance().axios;
    return axios
      .post('/crm-nest/add-update-permission', {data: body})
      .catch((error) => {

      });
  }
