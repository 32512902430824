import React from 'react';

const SyncSvg = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.9419 7.55556H2.4419V7.55556L2.9419 7.55556ZM2.9419 8.66667L2.58973 9.02161C2.78467 9.21502 3.09912 9.21502 3.29406 9.02161L2.9419 8.66667ZM4.41393 7.9105C4.60996 7.716 4.6112 7.39942 4.41671 7.20339C4.22222 7.00737 3.90564 7.00612 3.70961 7.20062L4.41393 7.9105ZM2.17418 7.20062C1.97816 7.00612 1.66158 7.00737 1.46708 7.20339C1.27259 7.39942 1.27383 7.716 1.46986 7.9105L2.17418 7.20062ZM12.8991 4.92853C13.0437 5.16377 13.3516 5.23723 13.5869 5.09261C13.8221 4.94799 13.8956 4.64005 13.751 4.40481L12.8991 4.92853ZM14.0313 7.33333L14.3828 6.97772C14.188 6.7852 13.8746 6.7852 13.6798 6.97772L14.0313 7.33333ZM12.5556 8.08883C12.3592 8.28295 12.3574 8.59953 12.5515 8.79593C12.7456 8.99233 13.0622 8.99417 13.2586 8.80006L12.5556 8.08883ZM14.804 8.80006C15.0004 8.99417 15.3169 8.99233 15.5111 8.79593C15.7052 8.59953 15.7033 8.28295 15.5069 8.08883L14.804 8.80006ZM4.03398 11.0707C3.88896 10.8358 3.58089 10.7628 3.34589 10.9078C3.1109 11.0529 3.03796 11.3609 3.18298 11.5959L4.03398 11.0707ZM8.54127 1.5C5.17639 1.5 2.4419 4.20747 2.4419 7.55556H3.4419C3.4419 4.76714 5.72125 2.5 8.54127 2.5V1.5ZM2.4419 7.55556L2.4419 8.66667L3.4419 8.66667L3.4419 7.55556L2.4419 7.55556ZM3.29406 9.02161L4.41393 7.9105L3.70961 7.20062L2.58973 8.31173L3.29406 9.02161ZM3.29406 8.31173L2.17418 7.20062L1.46986 7.9105L2.58973 9.02161L3.29406 8.31173ZM13.751 4.40481C12.68 2.66285 10.7469 1.5 8.54127 1.5V2.5C10.3876 2.5 12.0038 3.4723 12.8991 4.92853L13.751 4.40481ZM8.41051 14.5C11.7854 14.5 14.5313 11.7943 14.5313 8.44444H13.5313C13.5313 11.2311 11.2442 13.5 8.41051 13.5V14.5ZM14.5313 8.44444V7.33333H13.5313V8.44444H14.5313ZM13.6798 6.97772L12.5556 8.08883L13.2586 8.80006L14.3828 7.68894L13.6798 6.97772ZM13.6798 7.68894L14.804 8.80006L15.5069 8.08883L14.3828 6.97772L13.6798 7.68894ZM3.18298 11.5959C4.25806 13.338 6.19804 14.5 8.41051 14.5V13.5C6.55551 13.5 4.93258 12.5269 4.03398 11.0707L3.18298 11.5959Z"
        fill="#A7AFBE"
      />
    </svg>
  );
};

export default SyncSvg;
