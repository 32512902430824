import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  Spacer,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../styles';
import UserAutoComplete from '../../../common/CalendarWidget/UserAutoComplete/UserAutoComplete';
import {generateUniqueKey} from './AppointmentTypesHelper';
import {IUserGroupData} from './Interfaces';

const ParticipantGroup = (props: {
  maxParticipants?: number;
  selectedUsers: IUserGroupData[];
  onChange: (data: IUserGroupData[]) => void;
}) => {
  const intl = useIntl();

  props.selectedUsers.forEach((data: IUserGroupData) => {
    if (!data.uniqueKey) {
      data.uniqueKey = generateUniqueKey();
    }
  });
  const [users, setUsers] = useState<IUserGroupData[]>(props.selectedUsers);

  const handleOnDragEnd = (result: any) => {
    if (result.destination) {
      const items = Array.from(users);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setUsers(items);
    }
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    ...draggableStyle,
    userSelect: 'none',
    padding: '0 8px 8px 0',
  });

  useEffect(() => {
    props.onChange(users);
  }, [users]);

  return (
    <VStack space={2}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="participantGroup">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {users.map((userData: IUserGroupData, index: any) => {
                return (
                  <Draggable
                    key={userData.uniqueKey}
                    draggableId={userData.uniqueKey || ''}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <HStack space={2} alignItems="center">
                          <View minWidth={300}>
                            <UserAutoComplete
                              selectedValue={userData.user}
                              onChange={(data: any) => {
                                setUsers((prevState) => {
                                  const newStateValue = [...prevState];
                                  newStateValue[index].user = data;
                                  return newStateValue;
                                });
                              }}
                            />
                          </View>
                          <View>
                            <Input
                            _focus={{
                              borderColor: Colors.Custom.Gray200
                            }}
                              placeholder="Duration"
                              value={`${userData.duration}`}
                              onChangeText={(text: string) => {
                                const number = text.replace(/\D/, '');
                                setUsers((prevState) => {
                                  const newStateValue = [...prevState];
                                  newStateValue[index].duration = number
                                    ? parseInt(number)
                                    : 0;
                                  return newStateValue;
                                });
                              }}
                              InputRightElement={
                                <Box backgroundColor="gray.200">
                                  <Text padding={2}>min</Text>
                                </Box>
                              }
                            />
                          </View>
                          {users.length !== 1 && (
                            <HStack
                              space={2}
                              justifyContent="center"
                              marginLeft={4}
                            >
                              <IconButton
                                onPress={() => {
                                  setUsers((prevState) => {
                                    const newStateValue = [...prevState];
                                    newStateValue.splice(index, 1);
                                    return newStateValue;
                                  });
                                }}
                                borderRadius="xl"
                                size="sm"
                                variant="ghost"
                                colorScheme="muted"
                                icon={
                                  <Icon as={AntIcon} name="delete" size="4" />
                                }
                              />
                              <IconButton
                                borderRadius="xl"
                                size="sm"
                                variant="ghost"
                                colorScheme="muted"
                                icon={
                                  <Icon
                                    as={FeatherIcon}
                                    name="align-justify"
                                    size="4"
                                  />
                                }
                              />
                            </HStack>
                          )}
                          <Spacer />
                          {/* <View justifyContent="center" marginLeft={4}>
                            <IconButton
                              borderRadius="xl"
                              size="sm"
                              variant="outline"
                              colorScheme="muted"
                              icon={<Icon as={AntIcon} name="plus" size="4" />}
                            />
                          </View> */}
                        </HStack>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {props.selectedUsers.length !== props.maxParticipants && (
        <HStack justifyContent="center">
          <Button
            size="sm"
            variant="outline"
            colorScheme="muted"
            leftIcon={<Icon as={AntIcon} name="plus" size="4" />}
            onPress={() => {
              setUsers([
                ...users,
                {duration: 30, uniqueKey: generateUniqueKey()},
              ]);
            }}
          >
            {intl.formatMessage({id: 'add'})}
          </Button>
          <Spacer />
        </HStack>
      )}
    </VStack>
  );
};

export default ParticipantGroup;
