
export const USER_PERMISSIONS_CONST = {
    IS_EMPLOYER_PROFILE_TAB_SHOW : true,
    IS_EMPLOYER_OWNER_SHOW_FILTER_BY_CONTACT : false,
    IS_EMPLOYER_OWNER_SHOW_ONLY_INTERNAL : true,
    IS_EMPLOYER_OWNER_EDIT_DEAL_STATUS : false,
}

export const isEmployerOwnerShowFilterByContact = (() => {
    return USER_PERMISSIONS_CONST.IS_EMPLOYER_OWNER_SHOW_FILTER_BY_CONTACT;
});

export interface UserRolePermission {
  userRoleCode: string;
  entityCode: string;
  actionCode: string;
  permissionCode: string;
  accountUUID: string;
}
