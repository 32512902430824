import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';

export const styles = StyleSheet.create({
  scrollViewContainer: {height: 200, marginTop: 12},
  modalView: {
    height: 320,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
  },
  modalHeaderView: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  modalTitleText: {
    color: Colors.Custom.Gray700,
    fontWeight: '500',
    fontSize: 16,
  },
  infoBannerView: {marginTop: 8},
  noUsersFoundText: {
    marginTop: 8,
    textAlign: 'center',
    color: Colors.Custom.Gray400,
  },
  dividerView: {
    marginLeft: 4,
    height: 18,
    width: 1,
    backgroundColor: Colors.FoldPixel.GRAY100,
  },
  arrowContainerView: {
    width: 12,
    height: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  unassignedViewContainer: {alignItems: 'center'}
});

export const reactStyles: Record<string, React.CSSProperties> = {
  borderRadius12: {
    borderRadius: 12,
  },
  loadingStyle: {marginTop: 8},
  overlayInnerStyle: {
    padding: 0,
    borderRadius: 8,
  },
  borderRadius8: {borderRadius: 8},
};
