import {gql} from '@apollo/client';

export const GET_PRESCRIBED_PROCEDURES = gql`
  query GetPrescribedProcedure($ids: [uuid!]!, $employerUuid: uuid!, $accountUuid: uuid!) {
    prescribedProcedure(
      where: {
        id: {
          _in: $ids
        }
      }
    ) {
      id
      key: id
      name
      code
      description
      codeType
      prescribedProcedureCptcodes {
        id
        key: id
        cptCodeData{
          code
          description
        }
        accountPrescribedProcedure: accountLabCPTPrescribedData(where: {
          accountUuid: {
            _eq: $accountUuid
          },
          employerUuid: {
            _eq: $employerUuid
          }
        }) {
          id
          key: id
          prescribedProcedureId
          accountUuid
          employerUuid
          prescribedProcedureCost {
            id
            price
            priceData
            marketPriceData
            currentMarketPrice
          }
        }
      }
      accountPrescribedProcedure(where: {
        accountUuid: {
          _eq: $accountUuid
        },
        employerUuid: {
          _eq: $employerUuid
        }
      }) {
        id
        key: id
        prescribedProcedureId
        accountUuid
        employerUuid
        prescribedProcedureCost {
          id
          price
          priceData
          marketPriceData
          currentMarketPrice
        }
      }
    }
  }
`;

export const GET_PRESCRIBED_PROCEDURES_ACC = gql`
  query GetPrescribedProcedure($ids: [uuid!]!, $accountUuid: uuid!) {
    prescribedProcedure(
      where: {
        id: {
          _in: $ids
        }
      }
    ) {
      id
      key: id
      name
      code
      description
      codeType
      prescribedProcedureCptcodes {
        id
        key: id
        cptCodeData{
          code
          description
        }
        accountPrescribedProcedure: accountLabCPTPrescribedData(where: {
          accountUuid: {
            _eq: $accountUuid
          }
        }) {
          id
          key: id
          prescribedProcedureId
          accountUuid
          employerUuid
          prescribedProcedureCost {
            id
            price
            priceData
            marketPriceData
            currentMarketPrice
          }
        }
      }
      accountPrescribedProcedure(where: {
        accountUuid: {
          _eq: $accountUuid
        }
      }) {
        id
        key: id
        prescribedProcedureId
        accountUuid
        employerUuid
        prescribedProcedureCost(
          order_by: {
            createdAt: desc_nulls_last
          },
          limit: 1
        ) {
          id
          price
          priceData
          marketPriceData
          currentMarketPrice
        }
      }
    }
  }
`;

export const UPDATE_PRESCRIBED_PROCEDURES_COST = gql`
  mutation UpdatePrescribedProcedureCost(
    $price: float8,
    $priceData: jsonb,
    $marketPriceData: jsonb,
    $id:uuid!,
    $currentMarketPrice: float8
  ) {
    updatePrescribedProcedureCost(
      pk_columns: {id: $id}
      _set: {
        price: $price,
        priceData: $priceData,
        marketPriceData: $marketPriceData,
        currentMarketPrice: $currentMarketPrice,
      }
    ) {
      id
      price
      priceData
      marketPriceData
    }
  }
`;



export const CREATE_ACCOUNT_PRESCRIBED_PROCEDURES = gql`
  mutation CreateAccountPrescribedProcedure(
    $prescribedProcedureId: uuid,
    $accountUuid: uuid,
    $employerUuid: uuid,
    $prescribedProceduresCptcodeId: uuid,
  ) {
    createAccountPrescribedProcedure(
      object: {
        prescribedProcedureId: $id,
        accountUuid: $accountUuid,
        employerUuid: $employerUuid,
        prescribedProceduresCptcodeId: $prescribedProceduresCptcodeId,
      }
    ) {
      id
    }
  }
`;

export const CREATE_PRESCRIBED_PROCEDURES_COST = gql`
  mutation CreatePrescribedProcedureCost(
    $id: uuid,
    $accountUuid: uuid,
    $price: float8,
    $marketPriceData: jsonb,
    $priceData: jsonb,
    $currentMarketPrice: float8

  ) {
    createPrescribedProcedureCost(
      object: {
        accountPrescribedProcedureId: $id,
        accountUuid: $accountUuid,
        price: $price,
        priceData: $priceData,
        marketPriceData: $marketPriceData,
        currentMarketPrice: $currentMarketPrice,
      }
    ) {
      id
      accountUuid
      accountPrescribedProcedureId
      price
      priceData
      marketPriceData
      currentMarketPrice
    }
  }
`;
