import {Drawer} from 'antd';
import {Text, useMediaQuery, View} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../constants';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {styles} from './AccessFormStyles';
import BulkImportSpruce from './BulkImportSpruce';
const {width} = Dimensions.get('window');

const AccessForm = () => {
  const [accessFormState, setAccessFormState] = useState({
    isOpen: false,
  });
  const intl = useIntl();
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const finalWidth = isIPadScreen || isIPadMiniScreen ? width / 1.5 : width / 3;

  return (
    <View style={styles.container}>
      <View
        style={{
          marginVertical: 20,
        }}
      >
        <Text>Your Practice is not yet connected to Spruce</Text>
      </View>

      <FoldButton
        nativeProps={{
          variant: BUTTON_TYPE.PRIMARY,
          onPress: () => {
            setAccessFormState((prev) => {
              return {
                ...prev,
                isOpen: true,
              };
            });
          },
        }}
        customProps={{
          btnText: intl.formatMessage({
            id: 'Connect to Spruce',
          }),
          withRightBorder: false,
        }}
      ></FoldButton>
      {accessFormState.isOpen && (
        <Drawer
          width={finalWidth}
          open={accessFormState.isOpen}
          closeIcon={null}
          maskClosable={true}
          closable
          onClose={() =>
            setAccessFormState((prev) => {
              return {
                ...prev,
                isOpen: false,
              };
            })
          }
        >
          <>
            <BulkImportSpruce
              onModalClose={() => {
                setAccessFormState((prev) => {
                  return {
                    ...prev,
                    isOpen: false,
                  };
                });
              }}
            />
          </>
        </Drawer>
      )}
    </View>
  );
};

export default AccessForm;
