import { Drawer, InputNumber, Slider } from 'antd';
import {
  HStack,
  IconButton,
  Pressable,
  Text,
  useMediaQuery,
  View,
  VStack
} from 'native-base';
import React from 'react';
import { Dimensions } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH } from '../../../../constants';
import { Colors } from '../../../../styles';
import { ModalActionTitle } from '../../ModalActionTitle/ModalActionTitle';
import {
  DEFAULT_FROM_VALUE,
  DEFAULT_TO_VALUE,
  getFilterObject
} from './CustomAgeFilterUtils';
import {
  CustomAgeFilterProps,
  CustomAgeFilterState,
  IAgeFilterData
} from './interfaces';

const CustomAgeFilter = (props: CustomAgeFilterProps) => {
  const {isOpen, ageFilterData, onClose, onSave} = props;

  const [state, setState] = React.useState<CustomAgeFilterState>({
    data: [] as IAgeFilterData[],
  });

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const {width} = Dimensions.get('window');
  const drawerWidth =
    isIPadMiniScreen || isIPadScreen ? width * 0.7 : width * 0.4;

  React.useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        data: ageFilterData,
      };
    });
  }, []);

  const handleItemDelete = (args: {index: number}) => {
    const {index} = args;
    const newArray = [...state.data];
    if (newArray.length == 1) {
      return;
    }
    newArray.splice(index, 1);
    setState((prev) => {
      return {
        ...prev,
        data: newArray,
      };
    });
  };

  const handleAddMore = () => {
    const array = [...state.data];
    const newObj = getFilterObject({
      fromAge: DEFAULT_FROM_VALUE,
      toAge: DEFAULT_TO_VALUE,
    });
    array.push(newObj);
    setState((prev) => {
      return {
        ...prev,
        data: array,
      };
    });
  };

  const handleOnSave = () => {
    const data = [...state.data];
    const codes = data.map((item) => item.code);
    const finalData = data.filter((item, index, array) => {
      return codes.indexOf(item.code) === index;
    });
    onSave(finalData);
    onClose();
  };

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      width={drawerWidth}
      title={
        <ModalActionTitle
          title={'Filters'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                onClose();
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'apply',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                handleOnSave();
              },
            },
          ]}
        />
      }
    >
      {state.data.map((item, index) => {
        return (
          <VStack
            key={index}
            flex={1}
            space={'1'}
            style={{
              marginBottom: 30,
              padding: 4,
            }}
          >
            <HStack
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <View>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '700',
                    marginBottom: 6,
                  }}
                >{`Filter ${index + 1}`}</Text>
              </View>
              <View>
                <IconButton
                  icon={<AntIcon name="close" />}
                  onPress={() => {
                    handleItemDelete({index});
                  }}
                />
              </View>
            </HStack>
            <VStack style={{}}>
              <View>
                <Text>From</Text>
              </View>
              <HStack style={{flex: 1, width: '100%'}}>
                <View flex={0.8}>
                  <Slider
                    onChange={(value: number) => {
                      const obj = getFilterObject({
                        fromAge: value,
                        toAge: item.toAge,
                      });
                      const newArray = [...state.data];
                      newArray[index] = obj;
                      setState((prev) => {
                        return {
                          ...prev,
                          data: newArray,
                        };
                      });
                    }}
                    value={item.fromAge}
                  />
                </View>
                <View flex={0.2}>
                  <InputNumber
                    value={item.fromAge}
                    onChange={(value:any) => {
                      const obj = getFilterObject({
                        fromAge: value,
                        toAge: item.toAge,
                      });
                      const newArray = [...state.data];
                      newArray[index] = obj;
                      setState((prev) => {
                        return {
                          ...prev,
                          data: newArray,
                        };
                      });
                    }}
                  />
                </View>
              </HStack>
            </VStack>
            <VStack>
              <View>
                <Text>To</Text>
              </View>
              <HStack style={{flex: 1, width: '100%'}}>
                <View flex={0.8}>
                  <Slider
                    onChange={(value: number) => {
                      const obj = getFilterObject({
                        fromAge: item.fromAge,
                        toAge: value,
                      });
                      const newArray = [...state.data];
                      newArray[index] = obj;
                      setState((prev) => {
                        return {
                          ...prev,
                          data: newArray,
                        };
                      });
                    }}
                    value={item.toAge}
                  />
                </View>
                <View flex={0.2}>
                  <InputNumber
                    value={item.toAge}
                    onChange={(value:any) => {
                      const obj = getFilterObject({
                        fromAge: item.fromAge,
                        toAge: value,
                      });
                      const newArray = [...state.data];
                      newArray[index] = obj;
                      setState((prev) => {
                        return {
                          ...prev,
                          data: newArray,
                        };
                      });
                    }}
                  />
                </View>
              </HStack>
            </VStack>
          </VStack>
        );
      })}
      <Pressable
        style={{
          marginVertical: 12,
        }}
        onPress={() => {
          handleAddMore();
        }}
      >
        <View>
          <Text
            style={{
              marginTop: 4,
              fontSize: 12,
              color: Colors.Custom.PrimaryColor,
              marginLeft: 4,
            }}
          >
            Add More
          </Text>
        </View>
      </Pressable>
    </Drawer>
  );
};

export default CustomAgeFilter;
