export enum JourneyStatus {
  Active = 'ACTV',
  Full = 'FULL',
  Draft = 'DRFT',
  InActive = 'INAV',
}

export enum JourneyTag {
  Prescribable = 'Prescribable',
  ReqTriage = 'Req triage',
  SelfPurchase = 'Self purchase',
  ManagerAssessment = 'Manager assessment',
}

export enum TableSortOrders {
  ascend = 'ascend',
  descend = 'descend',
}
