import React from "react";
const AvoidedWorkplaceAbsenceSavingSvg = (props: {color?: string}) => {
  const svgColors = props.color ? props.color : '#98A2B3'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path stroke={svgColors} d="M15.7002 6.47348H8.30021M15.7002 6.47348H18.8877C20.6127 6.47348 22.0002 7.86098 22.0002 9.58598V19.611C22.0002 21.336 20.6127 22.7235 18.8877 22.7235H5.11271C3.38771 22.7235 2.00021 21.336 2.00021 19.611V9.58598C2.00021 7.86098 3.38771 6.47348 5.11271 6.47348H8.30021M15.7002 6.47348V4.29848C15.7002 3.42348 14.9877 2.72348 14.1252 2.72348H9.87521C9.00021 2.72348 8.30021 3.43598 8.30021 4.29848V6.47348M12.0002 18.3985V14.5985M12.0002 14.5985V10.7985M12.0002 14.5985H15.8002M12.0002 14.5985H8.20021" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />

    </svg>
  );
}

export default React.memo(AvoidedWorkplaceAbsenceSavingSvg);
