import {useMemo} from 'react';

const useBoxPlot = (boxPlotsData: any) => {
  const data = useMemo(
    () =>
      boxPlotsData.map((v: any) => {
        const max = v.fourth * 1.1;

        return {
          min: v.min,
          first: v.first - v.min,
          second: v.second - v.first,
          third: v.third - v.second,
          fourth: v.fourth - v.third,
          label: {
            first: v.first,
            second: v.second,
            third: v.third,
            fourth: v.fourth,
          },
          color: {
            first: '#F4EBFF',
            second: '#D6BBFB',
            third: '#9E77ED',
            fourth: '#6941C6',
          },
          target: v.target,
          max: Math.floor(max),
        };
      }),
    [boxPlotsData]
  );

  return data;
};

export default useBoxPlot;
