import { Checkbox, Divider, Spin } from "antd"
import { useContext, useEffect, useRef, useState } from "react"
import { View, Text, Pressable, ScrollView, TextInput } from "react-native"
import AntDesign from "react-native-vector-icons/AntDesign"
import {CheckboxChangeEvent} from "antd/lib/checkbox"
import {useLazyQuery} from "@apollo/client"
import {debounce} from 'lodash'
import {useIntl} from "react-intl"
import {LoadingOutlined} from "@ant-design/icons"
import {IPackagesTagSearchAndSelect, IPackagesTagSearchAndSelectState} from "./interfaces"
import {useToast} from "../../../../Toast/ToastProvider"
import {CommonDataContext} from "../../../../../context/CommonDataContext"
import {getMlovListFromCategory} from "../../../../../utils/mlovUtils"
import {MLOV_CATEGORY} from "../../../../../constants"
import TagQueries from "../../../../../services/Tags/TagQueries"
import {PAGE_LIMIT, PRODUCT_TYPE_CATEGORY} from "./PackagesConst"
import {formatGetTags} from "../../../Contacts/Tags/Helper/formatTagsData"
import {ITagsTableData} from "../../../Contacts"
import {Colors} from "../../../../../styles"
import {ICustomToast} from "../../../Contacts/CustomField/interface"
import {CUSTOM_FIELD_TOAST_ERROR_DURATION} from "../../../Contacts/CustomField/CustomFieldConst"
import {ToastType} from "../../../../../utils/commonViewUtils"

export const PackagesTagSearchAndSelect = (props: IPackagesTagSearchAndSelect) => {
  const [stateData, setStateData] = useState<IPackagesTagSearchAndSelectState>({
    labelDataLoading: false,
    labelData: [],
    selectedLabelData: [],
    hoveredIndex: null,
    offset: 0,
    value: '',
  })
  const intl = useIntl();
  const toast = useToast();
  const commonData = useContext(CommonDataContext);
  const labels = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.PACKAGE_TYPE
  );
  const controller = new AbortController();

  useEffect(()=> {
    setStateData((prev) => {
      return {
        ...prev,
        selectedLabelData: props?.productPackageTag || []
      }
    })
  },[props?.productPackageTag]);

  useEffect(()=> {
    setStateData((prev) => {
      return {
        ...prev,
        value: '',
      }
    })
  },[props?.showChild])

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const filterPackageMlov = labels?.filter?.((item) => {
    return item.code === PRODUCT_TYPE_CATEGORY.PACKAGE;
  });
  const packageMlov = filterPackageMlov?.[0];
  const categoryId = packageMlov?.id;

  const [GetLabelByFilterPagination] = useLazyQuery(TagQueries.GetLabelByFilterPagination, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const setLoading = (loading: boolean) => {
    setStateData((prev) => {
      return {
        ...prev,
        labelDataLoading: loading
      }
    })
  }

  const getPackagesLabel = async (searchString: string) => {
    setLoading?.(true);
    try {
      const response = await GetLabelByFilterPagination({
        variables: {
          category: categoryId,
          searchString: `%${searchString}%`,
          limit: PAGE_LIMIT,
          offset: stateData?.offset,
          orderBy: {updatedAt: 'desc'}
        },
      })
      if (response?.data?.labels?.length) {
        const formattedData = formatGetTags(response?.data?.labels);
        setStateData((prev) => {
          return {
            ...prev,
            labelData: formattedData,
            labelDataLoading: false,
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            labelData: [],
            labelDataLoading: false
          };
        });
      }
    } catch (error: any) {
      setLoading?.(false);
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({ id: 'apiErrorMsg' }),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
        closeAllPrevToast: true,
      })
    }
  }

  const handleMouseEnter = (index: number) => {
    setStateData((prev) => {
      return {
        ...prev,
        hoveredIndex: index
      }
    })
  };

  const handleMouseLeave = () => {
    setStateData((prev) => {
      return {
        ...prev,
        hoveredIndex: null
      }
    })
  };

  const isChecked = (id: number) => {
    return stateData?.selectedLabelData?.find?.((label: ITagsTableData) => label?.id === id) ? true : false
  }

  const onChange = (event: CheckboxChangeEvent, field: ITagsTableData) => {
    let tempSelectedLabelData = stateData?.selectedLabelData;
    if(event?.target?.checked) {
      tempSelectedLabelData = [...tempSelectedLabelData, field];
    } else {
      tempSelectedLabelData = tempSelectedLabelData?.filter?.((tempLabelData) => {
        return field?.id !== tempLabelData?.id
      })
    }
    setStateData((prev) => {
      return {
        ...prev,
        selectedLabelData: tempSelectedLabelData
      }
    });
    props?.onDetailChange?.(tempSelectedLabelData);
  }

  const getTextColor = (index: number) => {
    return stateData?.hoveredIndex === index ? Colors.Custom.Primary300 : Colors.Custom.Gray600;
  }

  const renderOptions = (
    <ScrollView style={{
      height: 260,
      marginTop: 10,
      marginBottom: 10,
      paddingHorizontal: 10,
      opacity: stateData?.labelDataLoading ? 0.5 : 1,
    }}>
      {stateData?.labelData?.map?.((field: ITagsTableData, index: number) => {
          return (
            <>
              <Pressable
                key={`tags_${field?.id}_${index}`}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginVertical: 10,
                }}
              >
                <div
                  style={{
                    width: '100%',
                    borderWidth: '1px'
                  }}
                  key={field?.id}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Checkbox
                    style={{
                      alignSelf: 'center',
                      width: '100%',
                    }}
                    className="label-checkbox"
                    value={field?.id}
                    checked={isChecked(field?.id)}
                    onChange={(event) => {
                      onChange(event, field)
                    }}
                  >
                    <Text style={{
                        marginLeft: 4,
                        color: getTextColor(index),
                        fontSize: 16,
                        alignSelf: 'center',
                      }}
                    >
                      {field?.title}
                    </Text>
                  </Checkbox>
                </div>
              </Pressable>
              {stateData?.labelData?.length -1 > index && <Divider style={{margin: 0}} />}
            </>
          )
        })
      }
      {stateData?.labelDataLoading && <View style={{
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
          height: 260,
          left: '50%'
        }}>
         <Spin size='large' indicator={<LoadingOutlined style={{ fontSize: 32, color: Colors.Custom.Primary300 }} spin />} />
      </View>}
      {!stateData?.labelDataLoading && stateData?.labelData?.length === 0 && <View style={{
        height: 260,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Text style={{
          textAlign: 'center',
          color: Colors.Custom.Gray500
        }}>
          {intl.formatMessage({id: 'noDataFound'})}
        </Text>
      </View>}
    </ScrollView>
  );

  const renderLabel = (label: ITagsTableData) => {
    return (
      <View
        key={label?.id}
        style={{
          backgroundColor: Colors.Custom.Gray200,
          paddingHorizontal: '8px',
          paddingVertical: '6px',
          marginRight: 6,
          borderRadius: 20,
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 6,
        }}>
        <Text style={{
          color: Colors.Custom.Gray700,
          fontSize: 14,
        }}>
          {label?.title}
        </Text>
        <Pressable
          onPress={()=> {
            const updatedSelectedLabelData = stateData?.selectedLabelData?.filter?.((packageLabel: ITagsTableData)=> {
              return packageLabel?.id !== label?.id
            })
            setStateData((prev)=> {
              return {
                ...prev,
                selectedLabelData: updatedSelectedLabelData
              }
            })
            props?.onDetailChange?.(updatedSelectedLabelData);
          }
        }>
          <AntDesign style={{
            marginLeft: 3,
            color: Colors.Custom.Gray500,
          }}
            size={12}
            name="close"
          />
        </Pressable>
      </View>
    )
  }

  const debounceTextChange = debounce((text)=> {
    getPackagesLabel(text);
    props?.onTextChange?.(text);
  }, 400);

  useEffect(() => {
    if (!stateData?.value?.length) {
      getPackagesLabel('');
    }
    return () => controller?.abort();
  }, [stateData?.value]);

  return (<>
    <View>
      <View style={{
        position: 'absolute',
        left: 8,
        top: 5,
      }}>
        <AntDesign size={20} color={Colors.Custom.Gray300} name="search1" />
      </View>
      <View style={{
        alignSelf: 'center'
      }}>
        <TextInput
          nativeID="react-input"
          style={{
            borderWidth: 0.5,
            borderColor: Colors.Custom.Gray400,
            borderRadius: 4,
            height: 32,
            width: 390,
            paddingLeft: 34,
            fontWeight: '300',
            fontSize: 14,
            color: Colors.Custom.Gray700,
          }}
          onChangeText={(text) => {
            debounceTextChange(text);
            setStateData((prev) => {
              return {
                ...prev,
                value: text,
              }
            })
          }}
          value={stateData?.value}
          placeholder={intl.formatMessage({id: 'searchTags'})}
          placeholderTextColor={Colors.Custom.Gray400}
        />
      </View>
      {renderOptions}
      {stateData?.selectedLabelData?.length > 0 && <View style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 14,
        overflow: 'scroll'
      }}>
        {stateData?.selectedLabelData?.map?.((label: ITagsTableData, index: number) => {
          return renderLabel(label);
        })}
      </View>}
    </View>
  </>)
}
