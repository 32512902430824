import React from 'react';
import {Colors} from '../../../styles';
import {ICommonSvgProps} from './interfaces';

const BoardIconSvg = (props: ICommonSvgProps) => {
  const {isEnabled} = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8333 0.5H2.16667C1.24619 0.5 0.5 1.24619 0.5 2.16667V13.8333C0.5 14.7538 1.24619 15.5 2.16667 15.5H13.8333C14.7538 15.5 15.5 14.7538 15.5 13.8333V2.16667C15.5 1.24619 14.7538 0.5 13.8333 0.5Z"
        stroke={isEnabled ? 'white' : 'black'}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.33333 3.83333H3.83333V11.3333H6.33333V3.83333Z"
        stroke={isEnabled ? 'white' : 'black'}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1667 3.83333H9.66667V8H12.1667V3.83333Z"
        stroke={isEnabled ? 'white' : 'black'}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BoardIconSvg;
