import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {HStack, Skeleton, View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import Icon from 'react-native-vector-icons/AntDesign';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {ILoginUserData, IMlov} from '../../../../Interfaces';
import TagQueries from '../../../../services/Tags/TagQueries';
import {TableTopBar} from '../../../common/TableTopBar';
import {formatGetTags} from './Helper/formatTagsData';
import {ITagsTableData} from './interfaces';
import {AddTagModal} from './TagsModal';
import {styles} from './TagsStyles';
import {TagsTable} from './TagsTable';
import { LABEL_TYPE_CODES } from '../../../../constants/MlovConst';
import {createLabelAndLabelType} from '../../Sales/ProductsAndServices/JourneyPackages/PackageServices';
import {notification} from 'antd';
import {useIntl} from 'react-intl';

const TagsScreen = (props: {
  category: IMlov;
  searchString?: string;
  openModal?: boolean;
  showTableTopBar?: boolean;
  onViewChangeCallback?: () => void;
}) => {
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const intl = useIntl();

  const {category} = props;

  const [openModal, setOpenModal] = useState(false);
  const [stateData, setStateData] = useState<any>([]);
  //const [tagsMenuList, setTagsMenuList] = useState([]);
  const [searchString, setSearchString] = useState('');

  const [tagStateData, setTagStateData] = useState({
    limit: 10,
    offSet: 0,
    pageNo: 1,
    total: 0,
    loading: false
  })

  const [createLabel] = useMutation(TagQueries.CreateLabel);
  const [createLabelType] = useMutation(TagQueries.CreateLabelType);
  const [updateTag] = useMutation(TagQueries.UpdateTag);
  const [deleteTag] = useMutation(TagQueries.DeleteTag);

  const getSearch = (actionCode: string, actionData: any) => {
    setSearchString(actionData.searchString);
  };


  const [GetLabelByFilterPagination] = useLazyQuery(TagQueries.GetLabelByFilterPagination, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    notifyOnNetworkStatusChange: true,
  })

  const onPagination = (page: any, pageSize: any) => {
    const pageNumber = page;
    const pageLimit = pageSize;
    const offset = pageNumber * pageLimit - pageLimit;
    setTagStateData((prev)=> {
      return {
        ...prev,
        offSet: offset,
        pageNo: page,
        limit: pageLimit,
      }
    })
  };

  const getLabelByPagination = async () => {
    setTagStateData((prev) => {
      return {
        ...prev,
        loading: true
      };
    });
    const response = await GetLabelByFilterPagination({
      variables: {
        category: category.id,
        searchString: `%${props.searchString}%`,
        limit: tagStateData.limit,
        offset: tagStateData.offSet,
        orderBy:
          category.code === LABEL_TYPE_CODES.TASK
            ? {updatedAt: 'desc'}
            : {title: 'asc'},
      },
    }).catch(() => {
      setTagStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (response?.data?.labels?.length) {
      setStateData(formatGetTags(response?.data?.labels));
      setTagStateData((prev) => {
        return {
          ...prev,
          total: response?.data?.labelAggregate?.aggregate?.count,
          loading: false
        };
      });
    }else {
      setStateData([]);
      setTagStateData((prev) => {
        return {
          ...prev,
          loading: false
        };
      });
    }
  };

  useEffect(() => {
    getLabelByPagination()

  }, [tagStateData.pageNo, tagStateData.limit]);

  useEffect(() => {
    if (props.searchString) {
      setSearchString(props.searchString);
      setTagStateData((prev) => {
        return {
          ...prev,
          offSet: 0,
          pageNo: 1,
        };
      });
      getLabelByPagination();
    } else {
      setSearchString('');
      setTagStateData((prev) => {
        return {
          ...prev,
          offSet: 0,
          pageNo: 1,
        };
      });
      getLabelByPagination();
    }
  }, [props.searchString]);
  useEffect(() => {
    if (props.openModal) {
      setOpenModal(props.openModal);
    }
  }, [props.openModal]);

  const handleAddTag = async (newTag: any) => {
    try {
      const createLabelBody = [{
        accountId: userData.account_id,
        color: newTag?.color,
        description: newTag?.newTagDescription,
        title: newTag?.newTagName,
        labelTypeId: newTag?.categoryId
      }]
      const response = await createLabelAndLabelType(createLabelBody);
      getLabelByPagination()
    } catch (error: any) {
      const message = error?.response?.data?.message || error?.networkError?.result?.message
      notification.error({
        message: message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: 5.0,
        placement: 'topRight'
      });
    }
  };

  const handleEditTag = (tag: ITagsTableData) => {
    updateTag({variables: tag}).then((res) => {
      getLabelByPagination()
    });
  };

  const handleDeleteTag = (tag: ITagsTableData) => {
    deleteTag({variables: {id: tag.id}}).then((res) => {
      getLabelByPagination()
    });
  };

  return (
    <View  flex={1}>
      <HStack flex={1}>
        <View flex={1} style={styles.container}>
          {props.showTableTopBar && (
            <TableTopBar
              title={`${category?.value} Tags`}
              onActionPerformed={getSearch}
              buttonList={[
                {
                  btnText: 'tagAdd',
                  colorScheme: 'primary',
                  textColor: 'white',
                  variant: 'PRIMARY',
                  size: 'sm',
                  leftIcon: <Icon name="plus" color={'white'} />,
                  btnClick: () => setOpenModal(true),
                },
              ]}
            />
          )}

          <AddTagModal
            title={`Add ${category?.code === LABEL_TYPE_CODES.TASK ? 'Label': 'Tag'} - ${category?.value}`}
            category={category}
            isModalOpen={openModal}
            onModalClose={() => {
              setOpenModal(false);
              if (props.onViewChangeCallback) {
                props.onViewChangeCallback();
              }
            }}
            onSave={(completeTag: ITagsTableData) => handleAddTag(completeTag)}
          />
          {tagStateData.loading ? (
            <View background={'#fff'} padding={3}>
              <Skeleton.Text lines={6} />
            </View>
          ) : (
            <TagsTable
              onPagination={onPagination}
              pageNo={tagStateData.pageNo}
              pageSize={tagStateData.limit}
              total={tagStateData.total}
              data={stateData}
              onDelete={(tag: ITagsTableData) => handleDeleteTag(tag)}
              onEdit={(tag: ITagsTableData) => handleEditTag(tag)}
            />
          )}
        </View>
      </HStack>
    </View>
  );
};

export default TagsScreen;
