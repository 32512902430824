import {Avatar, Text, View} from 'native-base';
import React from 'react';
import {ITitleProps} from '../Interfaces';
import TitleViewHelper from './TitleViewHelper';
import {styles} from './TitleViewStyles';

export const TitleView = (props: ITitleProps) => {
  return (
    <View style={styles.nameContainer}>
      <Avatar backgroundColor="primary.300">
        <Text style={styles.avatarText}>
          {TitleViewHelper.getInitials(props.title)}
        </Text>
      </Avatar>
      <View style={styles.titleContainer} flex={1}>
        <Text style={styles.title}>{props.title}</Text>
        <Text style={styles.subtitle}>{props.subtitle}</Text>
      </View>
    </View>
  );
};

export default TitleView;
