import React, {useState, useEffect, useContext} from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  SectionList,
  Pressable,
} from 'react-native';
import {Skeleton, Spinner, Text} from 'native-base';
import Stack from '../../common/LayoutComponents/Stack';
import {
  ADDITIONAL_DATA_CODE,
  CARE_MANAGEMENT_CATEGORY_CODES,
  CARE_MANAGEMENT_PROGRAM_CODES,
  CONTACT_CARE_PROGRAM_FITER_ACTION_CODES,
} from '../CareManagementBilling/CareManagementConstants';
import {Colors} from '../../../styles';
import {useNavigate, useParams} from 'react-router-dom';
import {useLazyQuery} from '@apollo/client';
import ContactCareProgram from '../../../services/ContactCareProgram/ContactCareProgram';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {
  CARE_TEAM_MEMBER_TYPE,
  MLOV_CATEGORY,
} from '../../../constants/MlovConst';
import ContactCareProgramCard from './ContactCareProgramCard';
import {
  ICareManagementView,
  ICareProgramState,
  IFutureAppointmentData,
  ICareProgramDateFilter,
} from './interface';
import {useCareProgramAdditionalData} from './CareManagementViewHooks/useCareProgramAdditionalData';
import {usePersonOmniViewContext} from '../../PersonOmniView/PersonOmniView.context';
import CareManagementTopBarView from './CareManagementTopBarView';
import {
  getGroupedCareProgramData,
  getWhereConditionForCareProgram,
} from './CareManagementUtils';
import {ICondition} from '../Forms/FHFormio/CustomComponents/Conditions/interfaces';
import {getRecordListFormatted} from '../Forms/FHFormio/CustomComponents/Conditions/AddOrUpdateConditions/AddOrUpdateConditionsHelper';
import {IUser} from '../../../Interfaces';
import UserQueries from '../../../services/User/UserQueries';
import CareManagementFilterView from './CareManagementFilterView';
import {
  ICareProgramAssignee,
  ICareProgramStatusData,
} from '../../PersonOmniView/MiddleContainer/CarePlan/interfaces';
import BulkTaskSelectSvg from '../../common/Svg/Task/BulkTaskSelectSvg';
import {Divider} from 'antd';
import {antStyles} from './CareManageMentViewStyles';
import CareProgramHeartCircleSvg from '../../common/Svg/CarePlanSvgs/CareProgramHeartCircleSvg';
import {useIntl} from 'react-intl';
import ContactCareProgramMultiSelectView from './ContactCareProgramMultiSelectView';

const CareManagementView = (props: ICareManagementView) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const {id, code} = useParams();
  const activeTabCode = code || 'all';
  if (!code) {
    navigate(`/members/patient/${id}/careManagement/all`);
  }
  const commonContextData = useContext(CommonDataContext);
  const {data: contactData, formattedData} = usePersonOmniViewContext();
  const careProgramTypesList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_TYPES
    ) || [];
  const careProgramStatusList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CONTACT_CARE_PROGRAM_STATUS
    ) || [];

  const [careProgramState, setCareProgramState] = useState<ICareProgramState>({
    groupCareProgramList: [],
    chronicConditionsData: [],
    pcpUserData: {},
    careManagerUserData: {},
    pcpFutureAppointmentData: {} as IFutureAppointmentData,
    careProgramLoading: true,
    patientAdditionalDataLoading: true,
    careProgramEnrollmentLoading: false,
    displayFilters: false,
    enableBulkSelection: false,
    selectedCareProgramList: [],
    careProgramUuids: [],
  });

  const [careProgramFilterState, setCareProgramFilterState] = useState({
    assigneeData: {} as ICareProgramAssignee,
    selectedStatus: {} as ICareProgramStatusData,
    selectedDateFilter: {} as ICareProgramDateFilter,
  });

  const [getContactCarePrograms] = useLazyQuery(
    ContactCareProgram.GET_CONTACT_CARE_PROGRAM_LIST_BY_CARE_PROGRAM_TYPE_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getUsersByUserUuidsAPI] = useLazyQuery<{
    accountUsers: {
      user: IUser;
    }[];
  }>(UserQueries.GetUsersByUserUuids, {
    fetchPolicy: 'no-cache',
  });

  const {getPatientAdditionalData} = useCareProgramAdditionalData();

  const getCareProgramTypeId = (activeTabCode: string) => {
    if (activeTabCode !== CARE_MANAGEMENT_PROGRAM_CODES.ALL) {
      const careProgramTypeId = getMlovIdFromCode(
        careProgramTypesList,
        activeTabCode.toUpperCase()
      );
      return careProgramTypeId;
    }
    return '';
  };

  const fetchContactCareProgramData = async (
    activeTabCode: string,
    assigneeId?: string,
    statusCode?: string
  ) => {
    try {
      setCareProgramState((prev) => ({
        ...prev,
        groupCareProgramList: [],
        careProgramUuids: [],
        careProgramLoading: true,
      }));

      const careProgramTypeId = getCareProgramTypeId(activeTabCode);
      const careProgramStatusId = statusCode
        ? getMlovIdFromCode(careProgramStatusList, statusCode)
        : '';
      const careProgramWhereCondition = getWhereConditionForCareProgram(
        props?.contactUuid,
        careProgramTypeId,
        assigneeId || '',
        careProgramStatusId || '',
        careProgramFilterState?.selectedDateFilter
      );

      const contactCareProgramResponse = await getContactCarePrograms({
        variables: {
          careProgramWhereCondition: careProgramWhereCondition,
        },
      });

      const groupCareProgramList = getGroupedCareProgramData(
        contactCareProgramResponse?.data?.contactCarePrograms,
        careProgramStatusList
      );

      const careProgramUuids =
        contactCareProgramResponse?.data?.contactCarePrograms?.map(
          (program: any) => program.id
        ) || [];

      setCareProgramState((prev) => ({
        ...prev,
        groupCareProgramList: groupCareProgramList,
        careProgramUuids: careProgramUuids,
        careProgramLoading: false,
      }));
    } catch (e) {
      setCareProgramState((prev) => ({
        ...prev,
        groupCareProgramList: [],
        careProgramUuids: [],
        careProgramLoading: false,
      }));
    }
  };

  const handlePatientAdditionalData = async () => {
    try {
      const accountLocationUuid = formattedData?.accountLocationUuid || '';
      const careProgramAdditionalData = await getPatientAdditionalData({
        patientId: contactData?.patient?.patientId,
        contactUuid: contactData?.uuid,
        location: accountLocationUuid,
        additionalDataCodes: [
          ADDITIONAL_DATA_CODE.CHRONIC_CONDITIONS,
          ADDITIONAL_DATA_CODE.PCP_AND_CARE_MANAGER,
          ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT,
        ],
      });
      const chronicConditionResources =
        careProgramAdditionalData?.CHRONIC_CONDITIONS?.resources;
      const careTeamUserData =
        careProgramAdditionalData?.PCP_AND_CARE_MANAGER?.careTeams?.[0]
          ?.careTeamMemberType;

      const futureAppointmentData =
        careProgramAdditionalData?.FUTURE_APPOINTMENT?.appointments?.[0] || {};

      let chronicConditions: ICondition[] = [];
      if (chronicConditionResources) {
        chronicConditionResources?.forEach((resource: any) => {
          chronicConditions = getRecordListFormatted(
            resource.conditions?.entry || []
          );
        });
      }

      const careTeamUserIds = careTeamUserData?.map(
        (item: any) => item?.userId
      );
      if (careTeamUserIds?.length) {
        const careTeamUsers = await getUsersByUserUuidsAPI({
          variables: {
            userUuids: careTeamUserIds,
          },
        });

        careTeamUserData?.forEach((item: any) => {
          const userData = careTeamUsers?.data?.accountUsers?.find(
            (singleUser) => singleUser?.user?.uuid === item?.userId
          )?.user;
          item.userData = userData;
        });
      }

      const pcpUserData = careTeamUserData?.find(
        (careTeamMemberType: any) =>
          careTeamMemberType?.memberType?.code === CARE_TEAM_MEMBER_TYPE.PCP
      );
      const careManagerData = careTeamUserData?.find(
        (careTeamMemberType: any) =>
          careTeamMemberType?.memberType?.code ===
          CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
      );

      setCareProgramState((prev) => {
        return {
          ...prev,
          chronicConditionsData: chronicConditions || ([] as ICondition[]),
          pcpUserData: pcpUserData || ({} as any),
          careManagerUserData: careManagerData || ({} as any),
          pcpFutureAppointmentData: futureAppointmentData,
          patientAdditionalDataLoading: false,
        };
      });
    } catch (e) {
      setCareProgramState((prev) => {
        return {
          ...prev,
          chronicConditionsData: [] as ICondition[],
          pcpUserData: {} as any,
          careManagerUserData: {} as any,
          patientAdditionalDataLoading: false,
        };
      });
    }
  };

  useEffect(() => {
    fetchContactCareProgramData(activeTabCode);
    handlePatientAdditionalData();
    return () => {
      setCareProgramState((prev) => {
        return {
          ...prev,
          groupCareProgramList: [],
          careProgramUuids: [],
          careProgramLoading: true,
          chronicConditionsData: [] as ICondition[],
          pcpUserData: {} as any,
          careManagerUserData: {} as any,
          patientAdditionalDataLoading: true,
        };
      });
    };
  }, []);

  useEffect(() => {
    fetchContactCareProgramData(
      activeTabCode,
      careProgramFilterState?.assigneeData?.uuid,
      careProgramFilterState?.selectedStatus?.code
    );
  }, [
    careProgramFilterState?.assigneeData?.uuid,
    careProgramFilterState?.selectedStatus,
    careProgramFilterState?.selectedDateFilter,
  ]);

  const onHeaderTabClick = (tabCode: string) => {
    onFilterActionPerformed(CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.CLEAR_ALL);
    setCareProgramState((prev) => {
      return {
        ...prev,
        displayFilters: false,
      };
    });
    fetchContactCareProgramData(tabCode);
    navigate(`/members/patient/${id}/careManagement/${tabCode}`);
  };

  const onCareProgramEnrollmentLoading = (value: boolean) => {
    setCareProgramState((prev) => {
      return {
        ...prev,
        careProgramEnrollmentLoading: value,
      };
    });
  };

  const onFilterActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.ASSIGNEE_FILTER_CHANGE:
        setCareProgramFilterState((prev) => {
          return {
            ...prev,
            assigneeData: actionData,
          };
        });
        break;

      case CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.STATUS_CHANGE:
        setCareProgramFilterState((prev) => {
          return {
            ...prev,
            selectedStatus: actionData,
          };
        });
        break;

      case CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.DATE_FILTER_CHANGE:
        setCareProgramFilterState((prev) => {
          return {
            ...prev,
            selectedDateFilter: actionData,
          };
        });
        break;

      case CONTACT_CARE_PROGRAM_FITER_ACTION_CODES.CLEAR_ALL:
        setCareProgramFilterState((prev) => {
          return {
            ...prev,
            selectedStatus: {} as ICareProgramStatusData,
            assigneeData: {} as ICareProgramAssignee,
            selectedDateFilter: {} as ICareProgramDateFilter,
          };
        });
        break;
    }
  };

  const handleBulkAction = (visible: boolean) => {
    setCareProgramState((prev) => {
      return {
        ...prev,
        enableBulkSelection: visible,
        selectedCareProgramList: [],
      };
    });
  };

  const handleSelectAll = () => {
    setCareProgramState((prev) => {
      return {
        ...prev,
        selectedCareProgramList: careProgramState.careProgramUuids,
      };
    });
  };

  const handleCheckBoxChange = (checked: boolean, careProgramId: string) => {
    setCareProgramState((prev) => {
      return {
        ...prev,
        selectedCareProgramList: checked
          ? [...new Set([...prev.selectedCareProgramList, careProgramId])]
          : prev.selectedCareProgramList.filter((id) => id !== careProgramId),
      };
    });
  };

  const checkBoxChecked = (careProgramId: string) => {
    return careProgramState?.selectedCareProgramList.includes(careProgramId);
  };

  const renderContactCareProgramCard = (contactCareProgram: any) => {
    return (
      <ContactCareProgramCard
        contactCareProgram={contactCareProgram?.item}
        pcpUserData={careProgramState?.pcpUserData}
        careManagerUserData={careProgramState?.careManagerUserData}
        chronicConditionsData={careProgramState?.chronicConditionsData}
        pcpFutureAppointmentData={careProgramState?.pcpFutureAppointmentData}
        patientAdditionalDataLoading={
          careProgramState?.patientAdditionalDataLoading
        }
        enableBulkSelection={careProgramState.enableBulkSelection}
        onCheckBoxChange={handleCheckBoxChange}
        checked={checkBoxChecked(contactCareProgram?.item?.id)}
        onActionCloseCareProgram={handleRefresh}
      />
    );
  };

  const getCareManagementBodyElementView = () => {
    return (
      <View style={styles.bodyContainer}>
        {careProgramState?.groupCareProgramList?.length > 0 ? (
          <SafeAreaView>
            <SectionList
              keyboardShouldPersistTaps="handled"
              keyboardDismissMode="on-drag"
              sections={careProgramState?.groupCareProgramList}
              stickySectionHeadersEnabled={false}
              renderSectionHeader={({section}: any) =>
                section.data.length ? (
                  <Stack
                    direction="row"
                    style={{
                      alignItems: 'center',
                    }}
                  >
                    {(section.key === CARE_MANAGEMENT_CATEGORY_CODES.ACTIVE ||
                      section.key === CARE_MANAGEMENT_CATEGORY_CODES.NEW) && (
                        <>
                          <Pressable
                            onPress={() =>
                              handleBulkAction(
                                !careProgramState.enableBulkSelection
                              )
                            }
                          >
                            <BulkTaskSelectSvg
                              height={16}
                              width={16}
                              customStrokeColor={Colors.Custom.Gray500}
                            />
                          </Pressable>
                          <Divider
                            type="vertical"
                            orientationMargin={8}
                            style={antStyles.dividerStyle}
                          />
                        </>
                      )}
                    <Text style={styles.sectionTitle}>{section.key}</Text>
                  </Stack>
                ) : null
              }
              renderItem={renderContactCareProgramCard}
              keyExtractor={(item: any) => item?.id}
            />
          </SafeAreaView>
        ) : (
          <Stack direction="column" style={styles.emptyStateContainer}>
            <CareProgramHeartCircleSvg />
            <Text style={styles.emptyStateText}>
              {intl.formatMessage({id: 'noCareProgramMsg'})}
            </Text>
          </Stack>
        )}
      </View>
    );
  };

  const handleRefresh = React.useCallback(() => {
    fetchContactCareProgramData(
      activeTabCode,
      careProgramFilterState?.assigneeData?.uuid,
      careProgramFilterState?.selectedStatus?.code
    );
  }, [
    activeTabCode,
    careProgramFilterState?.assigneeData?.uuid,
    careProgramFilterState?.selectedStatus?.code,
    fetchContactCareProgramData,
  ]);

  return (
    <View style={styles.outerContainer}>
      {careProgramState?.careProgramEnrollmentLoading ? (
        <Stack direction="row">
          <Spinner size={'sm'} />
        </Stack>
      ) : (
        <View style={styles.container}>
          <CareManagementTopBarView
            activeTabCode={activeTabCode}
            contactUuid={props?.contactUuid}
            contactId={contactData?.id}
            onHeaderTabClick={onHeaderTabClick}
            onCareProgramEnrollmentLoading={onCareProgramEnrollmentLoading}
            onFilterClick={() => {
              setCareProgramState((prev) => {
                return {
                  ...prev,
                  displayFilters: !prev.displayFilters,
                };
              });
            }}
          />
          {careProgramState.displayFilters && (
            <CareManagementFilterView
              contactUuid={props?.contactUuid}
              onFilterActionPerformed={onFilterActionPerformed}
              assigneeFilterData={careProgramFilterState?.assigneeData}
              selectedStatusFilter={careProgramFilterState?.selectedStatus}
              selectedDateFilter={careProgramFilterState?.selectedDateFilter}
            />
          )}
          {careProgramState?.careProgramLoading ? (
            <Skeleton.Text lines={4} />
          ) : (
            getCareManagementBodyElementView()
          )}
        </View>
      )}
      {careProgramState.enableBulkSelection && (
        <ContactCareProgramMultiSelectView
          contactId={props?.contactUuid}
          visible={careProgramState.enableBulkSelection}
          selectedCareProgramList={careProgramState.selectedCareProgramList}
          careProgramUuids={careProgramState.careProgramUuids}
          onClose={() => {
            handleBulkAction(false);
          }}
          onActionCloseCareProgram={handleRefresh}
          onSelectAll={handleSelectAll}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  container: {
    flex: 0.7,
  },
  bodyContainer: {
    flex: 1,
    padding: 16,
  },
  section: {
    marginBottom: 24,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.Custom.Gray500,
    alignItems: 'center',
  },
  emptyStateContainer: {
    flex: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
    backgroundColor: Colors.Custom.ContainerBGColor,
  },
  emptyStateText: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.Custom.Gray400,
    textAlign: 'center',
    marginTop: 16,
  },
});

export default CareManagementView;
