import {useLazyQuery} from '@apollo/client';
import {Table} from 'antd';
import {ScrollView, Skeleton, Text, useToast, View, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {FORM_ACTION_CODES} from '../../../../constants/ActionConst';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {FormsQueries} from '../../../../services';
import {Colors} from '../../../../styles';
import FormResponseView from '../../../common/FormResponseView/FormResponseView';
import PageBodyContainer from '../../../common/PageBodyContainer/PageBodyContainer';
import {NoActivitySvg} from '../../../common/Svg';
import {TableTopBar} from '../../../common/TableTopBar';
import {getPatientFormResponsesTableColumns} from './Helper';
import {IContactFormResult, IFormResponseTableViewProps, IFormSender} from './interfaces';
import PendingFormRespDrawer from './PendingFormRespDrawer/PendingFormRespDrawer';
import {capitalizeText} from '../../../common/ContactRelationView/ContactRelationUtils';
import { GET_ACTIVE_INACTIVE_ACCOUNT_USER } from '../../../../services/User/UserQueries';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';

const getTableTopBarTitle = (firstName: string) => {
  return `${capitalizeText(firstName)}'s Form Responses`;
};

const FormResponseTableView = (props: IFormResponseTableViewProps) => {
  const {personData} = props;
  const {contactUUID} = personData;
  const contactLocationUuid = personData?.accountLocationUuid;
  const intl = useIntl();

  const {height} = Dimensions.get('window');
  const [formResponseTableState, setFormResponseTableState] = useState<{
    searchString: string;
    isDrawerVisible: boolean;
    selectedRow?: IContactFormResult;
    formattedFormResponse: IContactFormResult[];
    isNoDataFound: boolean;
    loading: boolean;
    patientFormListView: boolean;
    selectedFormId: string;
    selectedFormName: string;
    contactDigitalSignStatus: { [index: string]: boolean},
    accountUsers: IFormSender[],
  }>({
    searchString: '',
    isDrawerVisible: false,
    selectedRow: undefined,
    formattedFormResponse: [],
    isNoDataFound: false,
    loading: true,
    patientFormListView: true,
    selectedFormId: '',
    selectedFormName: '',
    contactDigitalSignStatus: {},
    accountUsers: [],
  });

  const toast = useToast();

  const [searchForms] = useLazyQuery(
    FormsQueries.GET_CONTACT_FORM_LIST_BY_NAME
  );

  const [fetchActiveInActiveUser] = useLazyQuery(
    GET_ACTIVE_INACTIVE_ACCOUNT_USER
  );

  const [getContactDigitalSignedFormResponseByFormId] = useLazyQuery(
    FormsQueries.GET_CONTACT_DIGITAL_SIGNED_FORM_RESPONSE_BY_FORM_ID
  );

  const onTableActionPerformed = (selectedForm: any, action: string) => {
    switch (action) {
      case FORM_ACTION_CODES.FORM_RESPONSE_VIEW:
        setFormResponseTableState((prevState) => ({
          ...prevState,
          selectedFormId: selectedForm.formId,
          selectedFormName: selectedForm.name,
          patientFormListView: false,
        }));
        break;
      case FORM_ACTION_CODES.PENDING_FORM_STATUS_VIEW:
        setFormResponseTableState((prevState) => ({
          ...prevState,
          isDrawerVisible: true,
          selectedFormId: selectedForm.formId,
          selectedFormName: selectedForm.name,
        }));
        break;
    }
  };

  const formatResponse = (contactFormResponce: any): IContactFormResult[] => {
    const formattedFormResponse: IContactFormResult[] = [];
    contactFormResponce.map((item: any) => {
      formattedFormResponse.push({
        formId: item.formId,
        name: item.name,
        pendingCount: item.pendingCount,
        submittedCount: item.submittedCount,
      });
    });
    return formattedFormResponse;
  };

  async function fetchForms() {
    setFormResponseTableState((prevState) => ({...prevState, loading: true}));
    try {
      const forms = await searchForms({
        variables: {
          contactId: contactUUID,
          formName: `%${formResponseTableState.searchString}%`,
        },
        fetchPolicy: 'no-cache',
        context: {
          service: CARESTUDIO_APOLLO_CONTEXT,
        },
      });

      const contactDigitalSignedFormResponse = await getContactDigitalSignedFormResponseByFormId({
        variables: {
          formIds: forms.data.contactFormsStatus.map((form: any) => {
            return form.formId;
          }),
          contactId: contactUUID,
        },
        fetchPolicy: 'no-cache',
        context: {
          service: CARESTUDIO_APOLLO_CONTEXT,
        },
      });

      const contactDigitalSignStatus: any = {};
      contactDigitalSignedFormResponse.data.formResponses.forEach((formResponse: any) => {
        const formId = formResponse?.formId;

        if (!formId || contactDigitalSignStatus[formId]) {
          return;
        }
        if (formResponse.isFormDigitallySignedByContact) {
          contactDigitalSignStatus[formId] = true;
        } else if (formResponse.isContactSignComponentPresent) {
          contactDigitalSignStatus[formId] = false;
        }
      });

      setFormResponseTableState((prevState) => ({
        ...prevState,
        loading: false,
        formattedFormResponse: formatResponse(forms.data.contactFormsStatus),
        contactDigitalSignStatus,
      }));
    } catch (error) {
      setFormResponseTableState((prev) => {
        return {
          ...prev,
          isNoDataFound: true,
          loading: false,
        };
      });
      showToast(
        toast,
        intl.formatMessage({id: 'errorMsg'}),
        ToastType.error,
      );
    }
  }

  async function fetchUserDetail() {
    setFormResponseTableState((prevState) => ({...prevState, loading: true}));
    try {
      const accountUsers = await fetchActiveInActiveUser({
        fetchPolicy: 'no-cache',
      });
      setFormResponseTableState((prevState) => ({
        ...prevState,
        accountUsers: accountUsers?.data?.users || [],
        loading: false,
      }));
    } catch (error) {
      setFormResponseTableState((prev) => {
        return {
          ...prev,
          isNoDataFound: true,
          loading: false,
        };
      });
      showToast(
        toast,
        intl.formatMessage({id: 'errorMsg'}),
        ToastType.error,
      );
    }
  }

  useEffect(() => {
    fetchForms();
  }, [formResponseTableState.searchString]);

  useEffect(() => {
    fetchUserDetail();
  }, []);

  return (
    <View>
      <>
        {formResponseTableState.patientFormListView && (
          <VStack>
            <View p={4} backgroundColor={Colors.Custom.White}>
              <TableTopBar
                // title={getTableTopBarTitle(personData.firstName)}
                title={''}
                hideSearchBar={false}
                placeHolderText="Search Form Responses"
                isDividerNotVisible
                onActionPerformed={(
                  actionCode: string,
                  value: {searchString: string}
                ) => {
                  const {searchString} = value;
                  setFormResponseTableState((prevState) => {
                    return {...prevState, searchString};
                  });
                }}
              />
            </View>
            <PageBodyContainer style={styles.pageBodyContainer}>
              {formResponseTableState.loading ? (
                <Skeleton.Text lines={4} />
              ) : (
                <ScrollView height={height - 218}>
                  <Table
                    dataSource={(formResponseTableState.formattedFormResponse || []).map((response) => {
                      const contactDigitalSignStatus = formResponseTableState.contactDigitalSignStatus;
                      const formId = response.formId;
                      return {
                        ...response,
                        isFormSignedByPatientText: (contactDigitalSignStatus[formId] ?
                          'Yes'
                          : (contactDigitalSignStatus[formId] === false ?
                            'No'
                            : 'NA')),
                      };
                    })}
                    columns={getPatientFormResponsesTableColumns(
                      onTableActionPerformed
                    )}
                    pagination={false}
                    scroll={{y: height - 294}}
                  />
                </ScrollView>
              )}
            </PageBodyContainer>
          </VStack>
        )}

        {!formResponseTableState.patientFormListView &&
          formResponseTableState.selectedFormId && (
            <FormResponseView
              onClose={() => {
                setFormResponseTableState((prev) => {
                  return {
                    ...prev,
                    selectedRow: undefined,
                    isDrawerVisible: false,
                    patientFormListView: true,
                    searchString: '',
                  };
                });
              }}
              contactId={contactUUID || ''}
              formId={formResponseTableState.selectedFormId}
              formName={formResponseTableState.selectedFormName}
              accountUsers={formResponseTableState.accountUsers}
              contactLocationUuid={contactLocationUuid}
            />
          )}
      </>

      {formResponseTableState.isNoDataFound &&
        formResponseTableState.formattedFormResponse?.length === 0 && (
          <NoActivitySvg titleId="noFormResponses" />
        )}
      {formResponseTableState.isDrawerVisible && (
        <PendingFormRespDrawer
          onClose={() => {
            setFormResponseTableState((prev) => {
              return {
                ...prev,
                isDrawerVisible: false,
                selectedRow: undefined,
                searchString: '',
              };
            });
          }}
          isDrawerVisible={formResponseTableState.isDrawerVisible}
          contactId={contactUUID || ''}
          formId={formResponseTableState.selectedFormId}
          formName={formResponseTableState.selectedFormName}
          accountUsers={formResponseTableState.accountUsers}
        />
      )}
    </View>
  );
};

export default FormResponseTableView;

const styles = StyleSheet.create({
  pageBodyContainer: {
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    marginHorizontal: 0
  }
});
