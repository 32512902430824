import {insertUserAction} from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';

export const handleProviderAppLoginAction = async (args: {
  accountUuid: string;
  userUuid: string;
  level: string;
  metadata?: any;
}) => {
  const {accountUuid, userUuid, metadata, level} = args;
  try {
    const response = await insertUserAction({
      actionCode: 'LOGIN_TO_FOLD_PROVIDER_APP',
      level: level,
      status: 'COMPLETED',
      userId: userUuid,
      metadata: metadata,
    });
    return response;
  } catch (error) {

  }
};

export const handleRunExistingAutomationOnMember = async (args: {
  accountUuid: string;
  userUuid: string;
  level: string;
  metadata?: any;
}) => {
  const {accountUuid, userUuid, metadata, level} = args;
  try {
    const response = await insertUserAction({
      actionCode: 'RUN_EXISTING_AUTOMATION_ON_A_MEMBER',
      level: level,
      status: 'COMPLETED',
      userId: userUuid,
      metadata: metadata,
    });
    return response;
  } catch (error) {

  }
};

export const handleChatWithTeammateAction = async (args: {
  accountUuid: string;
  userUuid: string;
  level: string;
  metadata?: any;
}) => {
  const {accountUuid, userUuid, metadata, level} = args;
  try {
    const response = await insertUserAction({
      actionCode: 'CHAT_WITH_A_TEAM_MATE',
      level: level,
      status: 'COMPLETED',
      userId: userUuid,
      metadata: metadata,
    });
  } catch (error) {

  }
};

export const handleSendFormToMemberAction = async (args: {
  accountUuid: string;
  userUuid: string;
  metadata?: any;
  level: string;
}) => {
  const {accountUuid, userUuid, metadata, level} = args;
  try {
    const response = await insertUserAction({
      actionCode: 'SEND_AN_EXISTING_FORM_TO_A_MEMBER',
      level: level,
      status: 'COMPLETED',
      userId: userUuid,
      metadata: metadata,
    });
    return response;
  } catch (error) {

  }
};

export const handleCreateMemberGroupAction = async (args: {
  accountUuid: string;
  userUuid: string;
  level: string;
  metadata?: any;
}) => {
  const {accountUuid, userUuid, metadata, level} = args;
  try {
    const response = await insertUserAction({
      actionCode: 'CREATE_A_MEMBER_GROUP',
      level: level,
      status: 'COMPLETED',
      userId: userUuid,
      metadata: metadata,
    });

    return response;
  } catch (error) {

  }
};

export const handleCreateCampaignAction = async (args: {
  accountUuid: string;
  userUuid: string;
  level: string;
  metadata?: any;
}) => {
  const {accountUuid, userUuid, metadata, level} = args;
  try {
    const response = await insertUserAction({
      actionCode: 'CREATE_A_CAMPAIGN',
      level: level,
      status: 'COMPLETED',
      userId: userUuid,
      metadata: metadata,
    });
    return response;
  } catch (error) {

  }
};

export const handleCreateEmailTemplateAction = async (args: {
  accountUuid: string;
  userUuid: string;
  level: string;
  metadata?: any;
}) => {
  const {accountUuid, userUuid, metadata, level} = args;
  try {
    const response = await insertUserAction({
      actionCode: 'CONFIGURE_AN_EMAIL_TEMPLATE',
      level: level,
      status: 'COMPLETED',
      userId: userUuid,
      metadata: metadata,
    });
    return response;
  } catch (error) {

  }
};

export const handleCreateFormAction = async (args: {
  accountUuid: string;
  userUuid: string;
  level: string;
  metadata?: any;
}) => {
  const {accountUuid, userUuid, metadata, level} = args;
  try {
    const response = await insertUserAction({
      actionCode: 'CREATE_A_NEW_FORM_USING_HEALTH_COMPONENT',
      level: level,
      status: 'COMPLETED',
      userId: userUuid,
      metadata: metadata,
    });
    return response;
  } catch (error) {

  }
};

export const handleCreateTaskPoolAction = async (args: {
  accountUuid: string;
  userUuid: string;
  level: string;
  metadata?: any;
}) => {
  const {accountUuid, userUuid, metadata, level} = args;
  try {
    const response = await insertUserAction({
      actionCode: 'CREATE_A_TASK_POOL',
      level: level,
      status: 'COMPLETED',
      userId: userUuid,
      metadata: metadata,
    });
    return response;
  } catch (error) {

  }
};

export const handleConfigureCareJourneyAction = async (args: {
  accountUuid: string;
  userUuid: string;
  level: string;
  metadata?: any;
}) => {
  const {accountUuid, userUuid, metadata, level} = args;
  try {
    const response = await insertUserAction({
      actionCode: 'CONFIGURE_A_CARE_JOURNEY',
      level: level,
      status: 'COMPLETED',
      userId: userUuid,
      metadata: metadata,
    });

    return response;
  } catch (error) {

  }
};

export const handleSetupPhoneTreeAction = async (args: {
  accountUuid: string;
  level: string;
  userUuid: string;
  metadata?: any;
}) => {
  const {accountUuid, userUuid, metadata, level} = args;
  try {
    const response = await insertUserAction({
      actionCode: 'SETUP_A_PHONE_TREE',
      level: level,
      status: 'COMPLETED',
      userId: userUuid,
      metadata: metadata,
    });

    return response;
  } catch (error) {

  }
};

export const handleCreateOnboardingAutomationAction = async (args: {
  accountUuid: string;
  userUuid: string;
  level: string;
  metadata?: any;
}) => {
  const {accountUuid, userUuid, metadata, level} = args;
  try {
    const response = await insertUserAction({
      actionCode: 'CREATE_AN_ONBOARDING_AUTOMATION',
      level: level,
      status: 'COMPLETED',
      userId: userUuid,
      metadata: metadata,
    });
    return response;
  } catch (error) {

  }
};

export const handleCreateEmployerAction = async (args: {
  accountUuid: string;
  userUuid: string;
  level: string;
  metadata?: any;
}) => {
  const {accountUuid, userUuid, metadata, level} = args;
  try {
    const response = await insertUserAction({
      actionCode: 'CREATE_AN_EMPLOYER_AND_COUPLE_OF_EMPLOYEES',
      level: level,
      status: 'COMPLETED',
      userId: userUuid,
      metadata: metadata,
    });
    return response;
  } catch (error) {

  }
};

export const handleConfigurePhoneNumberAction = async (args: {
  accountUuid: string;
  userUuid: string;
  level: string;
  metadata?: any;
}) => {
  const {accountUuid, userUuid, metadata, level} = args;
  try {
    const response = await insertUserAction({
      actionCode: 'CONFIGURE_PHONE_NUMBER_FOR_SMS_AND_VOICE',
      level: level,
      status: 'COMPLETED',
      userId: userUuid,
      metadata: metadata,
    });
    return response;
  } catch (error) {

  }
};
