import {HStack, View, Text, Divider, Pressable} from "native-base";
import {Colors} from "../../../styles";
import InfoSvg from "../Svg/InfoSvg";
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";
import AntDesign from "react-native-vector-icons/AntDesign";
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import { StyleSheet } from "react-native";

interface ICareDashboardFilterInfo {
  showMessage?: boolean,
  message: string,
  selectedTab?: string
  onActionPerformed: (tabCode: any, rawData: any) => void;
}
const careDashboardFilterInfoTimer = 7000;

export const CareDashboardFilterInfo = (props: ICareDashboardFilterInfo)=> {
  const intl = useIntl();
  const [showCareDashboardFilter, setShowCareDashboardFilter] = useState(false);
  useEffect(()=>{
    setShowCareDashboardFilter(true);
    const timerId = setTimeout(() => {
      setShowCareDashboardFilter(false);
    }, careDashboardFilterInfoTimer);

    return () => {
      clearTimeout(timerId);
    };
  },[props?.selectedTab, props?.message])

  return (
    <>
    {(props?.showMessage && showCareDashboardFilter) && <View px={'16px'} borderBottomWidth={1} borderColor={Colors.Custom.BorderColor} height={'44px'}>
      <HStack height={'full'} alignItems={'center'}>
        <View mt={0.5}>
          <InfoSvg customDimension={'20'} />
        </View>
        <Text ml={1} color={'gray.500'} fontSize={'14px'} fontWeight={400} lineHeight={'150%'}>
          {intl.formatMessage({id:props?.message})}
        </Text>
        <Divider mx={3} height={'16px'} bg={'gray.500'} orientation="vertical" />
        <Pressable style={styles.dismissButton}
          onPress={() => {
            props?.onActionPerformed?.(
              COMMON_ACTION_CODES.SHOW_FILTER_INFO_CHANGE,
              {
                showFilterInfoOne: false,
                showFilterInfoTwo: false
              },
            );
          }}
        >
          <AntDesign size={18} color={Colors.Custom.mainPrimaryPurple} name="close" />
          <Text ml={1} color={Colors.Custom.mainPrimaryPurple} fontSize={'14px'} fontWeight={600} lineHeight={'150%'}>
          {intl.formatMessage({id:'dismiss'})}
          </Text>
        </Pressable>
      </HStack>
    </View>}
    </>
  )
}

const styles = StyleSheet.create({
  dismissButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});