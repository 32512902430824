import { BILLING_API_PATH } from '../../constants/BillingApiPath';
import BaseService from '../CommonService/BaseService';
import BillingService from '../CommonService/BillingService';

export const getUsages = async (accountUuid: string) => {
  const billingServiceInstance =
    BillingService.getBillingServiceInstanceInstance().axios;
  if (accountUuid) {
    return await billingServiceInstance.get(
      `${BILLING_API_PATH.USAGES_LIST}?accountUuid=${accountUuid}`
    );
  }
};

export const getActiveLivesCount = async (
  accountId: number | undefined,
  employerId?: string
) => {
  const baseService = BaseService.getSharedInstance().axios;
  const body: any = {
    type: 'Active Lives',
    account_id: accountId,
  };
  if (employerId) {
    body.employerId = employerId;
  }
  if (accountId) {
    return await baseService.post(
      `${BILLING_API_PATH.ACTIVE_LIVES_COUNT}`,
      body
      );
    }
};

export const getSuggestions = async (prompt: string) => {
  const billingServiceInstance =
    BillingService.getBillingServiceInstanceInstance().axios;
  if (prompt) {
    const body = {
      prompt: prompt,
    };
    return await billingServiceInstance.post(
      `${BILLING_API_PATH.OPENAI_COMPLETION}`,
      body
    );
  }
};

export const getNaturalLanguageParse = async (prompt: string) => {
  const billingServiceInstance =
    BillingService.getBillingServiceInstanceInstance().axios;
  if (prompt) {
    const body = {
      model: "gpt-3.5-turbo-instruct",
      prompt:  'Can you parse below paragraph and extract coded intent, entity and delay in hours converted from days per sentence in JSON format:\n\n"'+ prompt + '"',
    };
    const response = await billingServiceInstance.post(
      `${BILLING_API_PATH.OPENAI_COMPLETION_PLAIN}`,
      body
    );
    
    const resultList = response?.data?.result.map((result:any)=>{
      let text = result.text;
      let list = []
      try{
        list = JSON.parse(result.text)
      }
      catch  {
        try{
        text = (text||'').replaceAll('\n','');
        text = text.replaceAll('JSON Format:','');
        list = JSON.parse(text);
        } catch  {
          try{
            text = '[' + text + ']';
            list = JSON.parse(text);
            } catch  {
              list = []
            }
        }
      }
      if(list && !Array.isArray(list)){
        list  = [list];
      }
      return list;

    });
    return resultList[0];
  }
};

export const getActiveLivesContactList = async (body: any) => {
  const baseService = BaseService.getSharedInstance().axios;
  return await baseService.post(
    `${BILLING_API_PATH.ACTIVE_LIVES_CONTACTS}`,
    body
  );
}
export const getDalleSuggestions = async (prompt: string) => {
  const billingServiceInstance =
    BillingService.getBillingServiceInstanceInstance().axios;
  if (prompt) {
    const body = {
      prompt: prompt,
    };
    return await billingServiceInstance.post(
      `${BILLING_API_PATH.DALLE_COMPLETION}`,
      body
    );
  }
};
