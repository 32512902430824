import {Card, Select, Table} from 'antd';
import {Input} from 'native-base';
import React, {FC, useEffect, useState} from 'react';
import LocalStorage from '../../../../../utils/LocalStorage';
import {IModalStep} from '../../pages/Opportunity';
import OpportunityAssociationModal from '../OpportunityAssociationModal';
import './style.css';
export interface IAutomationResultsProps {
  setModalStep?: any;
  modalStep?: IModalStep;
  automationId?: string;
  onNewData?: any;
  okText?: string;
}

export interface IAutomationProp {
  isLoading: boolean;
  data?: any;
  automation?: any;
}

const OpportunityAutomationResults: FC<IAutomationResultsProps> = ({
  modalStep,
  setModalStep,
  automationId,
  onNewData,
  okText,
}) => {
  const [automation, setAutomation] = useState<IAutomationProp>({
    isLoading: false,
    data: null,
    automation: null,
  });
  const getHeaders = async () => {
    const userDataString = await LocalStorage.getItem('user');
    const userData = JSON.parse(userDataString);

    const responseAccessToken = userData?.accessToken || '';
    const fold_access_token = userData?.fold_access_token;

    const headersObj: any = {
      'access-token': responseAccessToken,
      fold_access_token,
    };

    return headersObj;
  };
  const fetchAutomation = async () => {
    try {
      const authHeaders = await getHeaders();
      const req = await fetch(
        `https://dev.foldhealth.io/opportunities/automation/${
          automationId || modalStep?.opportunity._id
        }/results`,
        {
          headers: authHeaders,
        }
      );
      const res = await req.json();

      setAutomation({
        isLoading: false,
        data: res.results,
        automation: res.automation,
      });
    } catch (error: any) {
    }
  };

  useEffect(() => {
    automationId && fetchAutomation();
  }, [automationId]);

  const [newOpportunity, setNewOpportunity] = useState<any>({
    isLoading: false,
    isVisible: true,
    data: null,
    error: null,
    form: {
      name: '',
      goals: [],
    },
    table: {
      selectedRows: [],
    },
  });
  const setSelectedRows = (newRows: any[]) => {
    setNewOpportunity({
      ...newOpportunity,
      table: {
        ...newOpportunity.table,
        selectedRows: newRows,
      },
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], cSelectedRows: any[]) => {
      setSelectedRows(cSelectedRows);
    },
    getCheckboxProps: (record: any) => ({
      // disabled: record.name === "Disabled User", // Column configuration not to be checked
      // name: record.name,
    }),
  };

  const handleRowClick = (params: any) => {
    // alert("To be implemented.");
  };

  const generateCols = () => {
    const results =
      automation &&
      automation.automation &&
      automation.automation.opportunities;

    return results
      ? results.map((res: any) => ({
          key: res._id,
          title: res.name,
          populationGoal: {
            title: res.members.length,
            description: res.name,
          },
          description: `${res.members.length} members`,
        }))
      : [];
  };

  const handleUpdateField = (record: any, value: string, field: string) => {
    const updatedOpp = {
      ...newOpportunity,
      table: {
        ...newOpportunity.table,
        selectedRows: newOpportunity.table.selectedRows.map((goal: any) => {
          return goal.key == record.key ? {...goal, [field]: value} : goal;
        }),
      },
    };

    setNewOpportunity(updatedOpp);
  };

  const saveOpportunities = async () => {
    try {
      const authHeaders = await getHeaders();
      const req = await fetch(
        `https://dev.foldhealth.io/opportunities/automation/${
          automationId || modalStep?.opportunity._id
        }/save`,
        {
          method: 'POST',
          body: JSON.stringify({
            opportunities: newOpportunity.table.selectedRows.map(
              (sr: any) => sr.key
            ),
          }),
          headers: {
            'Content-Type': 'application/json',
            ...authHeaders,
          },
        }
      );
      const res = await req.json();

      onNewData(
        res.map((d: any) => ({
          _id: d._id,
          id: d._id,
          key: d._id,
          name: d.name,
          author: d.author,
          source: d.source,
          members: d.members,
          careJourney: 'Assign',
          remainingTime: null,
          creationDate: new Date(),
          favorite: false,
          favorites: [],
        }))
      );
    } catch (error) {
    }
  };

  return (
    <div className="w-full automationModalContainer">
      <OpportunityAssociationModal
        onOkClick={() => saveOpportunities()}
        className="automationModalContainer"
        width={916}
        okText={okText}
        title={
          <div
            style={{
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexDirection: 'column',
              width: '100%',
              display: 'flex',
              paddingTop: '1rem',
            }}
          >
            <h1 className="text-[24px] capitalize">Opportunity Automation</h1>
            <p>
              Opportunities that were automatically found based on your data.
            </p>
          </div>
        }
        isVisible={newOpportunity.isVisible}
        onClose={() => setNewOpportunity({...newOpportunity, isVisible: false})}
      >
        <Card>
          <Table
            className="automation_table"
            style={{width: '100%'}}
            rowKey="key"
            // key={"key"}
            dataSource={generateCols()}
            // dataSource={generateCols() || [
            //   {
            //     key: "title",
            //     title: "BMI above 27.0 kg/m2",
            //     populationGoal: {
            //         title: "BMI",
            //         description: "Above 27.0 kg/m2"
            //     },
            //     description: "473 members",
            //   },
            //   {
            //     key: "title",
            //     title: "Triglycerides above 150.0",
            //     populationGoal: {
            //         title: "BMI",
            //         description: "Above 27.0 kg/m2"
            //     },
            //     description: "473 members",
            //   },
            // ]}
            columns={[
              {
                title: <span style={{fontSize: '14px'}}>Opportunity Name</span>,
                dataIndex: 'title',
                key: 'title',
                render: (text: string, record: any) => {
                  return (
                    <Input
                      onChange={(e: any) =>
                        handleUpdateField(record, e.target.value, 'title')
                      }
                      borderRadius="8px"
                      padding="10px 16px 10px 16px"
                      height="40px"
                      defaultValue={text}
                    />
                  );
                },
              },
              {
                title: <span style={{fontSize: '14px'}}>Population Goal</span>,
                dataIndex: 'populationGoal',
                key: 'populationGoal',
                render: (text: string, record: any) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <span style={{fontWeight: '500'}}>
                        {record.populationGoal.title} members
                      </span>
                      <span style={{color: '#667085'}}>
                        {record.populationGoal.description}
                      </span>
                    </div>
                  );
                },
              },
              {
                title: (
                  <span style={{fontSize: '14px'}}>
                    Opportunity Description
                  </span>
                ),
                dataIndex: 'description',
                key: 'description',
                render: (text: string, record: any) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <span className="font-medium">
                        {record.populationGoal.title} members
                      </span>
                      <span className="text-gray-500">{record.title}</span>
                    </div>
                  );
                },
              },
            ]}
            loading={newOpportunity.isLoading}
            rowSelection={{type: 'checkbox', ...rowSelection}}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: () => {
            //       handleRowClick(record.key);
            //     }, // click row
            //   };
            // }}
            pagination={false}
          />
        </Card>
      </OpportunityAssociationModal>
    </div>
  );
};

export default OpportunityAutomationResults;
