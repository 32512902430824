import React, {useContext} from 'react';
import {Divider, Drawer, Skeleton, Table, Tooltip, notification} from 'antd';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  GROUP_MEMBER_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  PERSON_TYPES,
  SMALL_WINDOW_1700,
  getInActiveContactError,
} from '../../../../constants/StringConst';
import {Colors} from '../../../../styles/Colors';
import {useMediaQuery, useToast} from 'native-base';
import {Dimensions, View, Text} from 'react-native';
import {
  IBillingData,
  ICareProgramCptCode,
  IHandleTableActions,
  IHookState,
} from '../BillingHooks/BillingHookInterfaces';
import {PatientInfoBanner} from '../../../PatientInfoBanner/PatientInfoBanner';
import {
  getGenderTextByGenderCode,
  getPrimaryGenderCode,
  isActiveContact,
} from '../../../../utils/commonUtils';
import {isPatientDetailViewAllowed} from '../../UserAccess/UserAccessPermission';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {useNavigate} from 'react-router-dom';
import {getContactTypeValue} from '../../TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import {IContact, IContactType} from '../../TeamInbox/Conversations/interfaces';
import {getPrimaryGenderLabel} from '../../../../utils/capabilityUtils';
import {getAgeValue, getDateStrFromFormat} from '../../../../utils/DateUtils';
import Stack from '../../../common/LayoutComponents/Stack';
import {useIntl} from 'react-intl';
import {getAttributesListToDisplay, getCptCodeAmountBillingColumns} from '../CareProgramBillingHelper';
import { IBatchedAccountUsersInterface } from '../../../CustomHooks/useGetBatchedAccountUsers';
import { useMutation } from '@apollo/client';
import { PROCESS_BILLING } from '../BillingQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../../../constants';
import ProviderActivityAndOutreachComponent from './ProviderOutreachAndActivity/ProviderOutreachAndActivity';

interface IDetailedBill {
  handleTableActions: (params: IHandleTableActions) => void;
  onClose: () => void;
  billingData: IBillingData;
  hookState: IHookState;
  accountUserList: IBatchedAccountUsersInterface[];
  attributeAndCodesLoading: boolean;
  onSendClaim: () => void;
  handleActions: any;
  isBilledView?: boolean;
}

const DetailedBill = (props: IDetailedBill) => {
  const {handleTableActions, onClose, billingData, hookState, accountUserList, isBilledView} = props;
  const commonData = useContext(CommonDataContext);
  const careProgramTypes = getMlovListFromCategory(commonData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_TYPES) || [];
  const navigate = useNavigate();
  const intl = useIntl();
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const screenDimensions = Dimensions.get('window');
  const smallWindow = screenDimensions?.width < SMALL_WINDOW_1700;
  const isPatientDetailViewAllowedConfig = isPatientDetailViewAllowed()
  const navigateOrOpenContactIdDrawer = (
    contactId?: string,
    additionalData?: any
  ) => {
    if (!contactId) {
      return;
    }
    if (
      isPatientDetailViewAllowedConfig
    ) {
      navigate(`/members/patient/${contactId}`, additionalData);
    }
  };

  const openContactInWindowOrOpenContactIdDrawer = (contactId?: string) => {
    if (!contactId) {
      return;
    }

    if (
      isPatientDetailViewAllowedConfig
    ) {
      window.open(`/#/members/patient/${contactId}`, '_blank', 'noopener,noreferrer');
    }
  };

  const [processBilling, { loading: loadingProcessBilling }] = useMutation(
    PROCESS_BILLING,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      onCompleted: () => {
        props?.onSendClaim()
      }
    },
  );


  const getPatientOrProspectElem = (
    contactType: IContactType,
    contactData: IContact
  ): JSX.Element => {
    const contactTypeStr = getContactTypeValue(contactType);
    const genderValue = getGenderTextByGenderCode(contactData);
    const genderLabel = getPrimaryGenderLabel('');
    const ageValue = getAgeValue(contactData?.person?.birthDate || '', '');

    const getAgeAndGenderText = (): JSX.Element => {
      return (
        <Stack direction="row">
          <Text>
            {`${contactTypeStr}${
              contactData?.person?.birthDate &&
              getAgeValue(contactData?.person?.birthDate, '')
                ? ' | '
                : ''
            }`}
          </Text>
          <Tooltip
            title={ageValue}
            placement="top"
            color={Colors.Custom.Gray700}
          >
            <Text>
              {contactData?.person?.birthDate
                ? getDateStrFromFormat(
                    contactData?.person?.birthDate,
                    DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                  )
                : ''}
            </Text>
          </Tooltip>
          <Text>{genderValue ? ` | ${genderValue}` : ''}</Text>
        </Stack>
      );
    };
    return (
      <Stack direction="row">
        {contactData?.person?.birthDate || genderValue || contactTypeStr
          ? getAgeAndGenderText()
          : undefined}
      </Stack>
    );
  };

  const renderLabelValue = (attribute: {labelId: string; value: string}): JSX.Element => {
    return (
      <Stack direction="column" space={4} style={{flex: 0.5}}>
        <Text
          style={{
            color: Colors.FoldPixel.GRAY200,
            fontWeight: '400',
            fontSize: 14,
          }}
        >
          {intl.formatMessage({id: attribute.labelId})}
        </Text>
        <Text
          style={{
            color: Colors.FoldPixel.GRAY400,
            fontWeight: '500',
            fontSize: 14,
          }}
        >
          {attribute.value}
        </Text>
      </Stack>
    );
  };

  const renderAttributes = (): (JSX.Element | null)[] => {
    const attributes = getAttributesListToDisplay(
      billingData,
      careProgramTypes,
      hookState.attributesAndCodes?.[0]?.contactCareProgram
    );
    return attributes.map((attribute, index) => {
      // below operation is just to render two items in a single row
      if (index % 2 !== 0) return null;
      const currentAttribute = attribute;
      const nextAttribute = attributes[index + 1];
      return (
        <View key={index}>
          <Stack direction="row" style={{flex: 1}}>
            {renderLabelValue(currentAttribute)}
            {!!nextAttribute && renderLabelValue(nextAttribute)}
          </Stack>
          <Divider style={{marginTop: 10, marginBottom: 10}} />
        </View>
      );
    });
  };

  const handleOnShowDetails = () => {
    const currentStatus = isActiveContact(billingData?.contactData);
    if (!currentStatus) {
      const message = getInActiveContactError(billingData?.contactData);
      notification.error({
        message,
      });
      return;
    }
    if (
      billingData?.contactData?.contactType?.contactType?.code == 'CUSTOMER'
    ) {
      navigateOrOpenContactIdDrawer?.(billingData?.contactData.id?.toString(), {
        state: {contactData: billingData?.contactData},
      });
    } else {
      navigate(`/members/prospect/${billingData?.contactData.id}`, {
        state: {contactData: billingData?.contactData},
      });
    }
  }

  const onPatientProfileIconClick = () => {
    const currentStatus = isActiveContact(billingData?.contactData);
    if (!currentStatus) {
      const message = getInActiveContactError(billingData?.contactData);
      notification.error({
        message,
      });
      return;
    }
    if (
      billingData?.contactData?.contactType?.contactType?.code ==
        PERSON_TYPES.CUSTOMER ||
      billingData?.contactData?.contactType?.contactType?.code ===
        PERSON_TYPES.CUSTOMER
    ) {
      openContactInWindowOrOpenContactIdDrawer?.(
        billingData?.contactData.id?.toString()
      );
    } else {
      window.open(
        `/#/members/prospect/${billingData?.contactData.id}`,
        '_blank',
        'noopener,noreferrer'
      );
    }
  };

  const dataSourceForTable = hookState?.attributesAndCodes?.flatMap((item) =>
    item.billableActivityCptCodes.map(
      (code) => code.careProgramCptCode
    )
  )

  return (
    <Drawer
      destroyOnClose
      placement="right"
      open={true}
      onClose={onClose}
      closable
      width={
        isIPadScreen || isIPadMiniScreen ? '50%' : smallWindow ? '40%' : '35%'
      }
      title={
        <ModalActionTitle
          title={'billingDetails'}
          buttonList={[
            {
              id: 1,
              btnText: 'close',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: onClose,
              show: true,
            },
            {
              id: 1,
              show: !isBilledView,
              btnText: 'sendClaimTOEhr',
              isLoading: loadingProcessBilling,
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              isDisabled: loadingProcessBilling,
              onClick: async () => {
                await processBilling({
                  variables: {
                    params: {
                      contactCareProgramIds: billingData.id,
                    },
                  },
                });
              },
            },
          ]}
        />
      }
    >
      {props?.attributeAndCodesLoading && <Skeleton active />}
      {!props?.attributeAndCodesLoading && (
        <View
          style={{
            backgroundColor: Colors.FoldPixel.GRAY50,
            padding: 10,
            borderRadius: 10,
          }}
        >
          <PatientInfoBanner
            contactType={billingData?.contactData?.contactType?.contactType}
            isLetterAvatarShow={true}
            name={billingData.contactData.name}
            contactData={billingData.contactData}
            contactActiveStatus={billingData.contactData?.isActive}
            bannerType="bigBanner"
            isHorizontal={true}
            isSelected={false}
            headerString={billingData.contactData.name || ''}
            loggedInTime={
              billingData?.contactData?.additionalAttributes?.loggedInTime
            }
            genderCode={getPrimaryGenderCode(billingData?.contactData)}
            userId={billingData?.contactData?.id}
            userType={
              billingData.contactData.contactType.contactType.code ===
              'CUSTOMER'
                ? GROUP_MEMBER_TYPE.PATIENT
                : GROUP_MEMBER_TYPE.CONTACT
            }
            subtitleElement={getPatientOrProspectElem(
              billingData.contactData.contactType.contactType,
              billingData.contactData
            )}
            shouldShowSubtitleHighlighter={false}
            showDetailProfile={true}
            onShowDetails={handleOnShowDetails}
            onPatientProfileIconClick={onPatientProfileIconClick}
          />
          <Divider style={{marginTop: 12, marginBottom: 12}} />
          {renderAttributes()}
          <Table
            style={{
              backgroundColor: Colors.FoldPixel.GRAY50,
            }}
            columns={getCptCodeAmountBillingColumns()}
            summary={(pageData) => {
              let totalAmount = 0;
              pageData.forEach((item) => {
                totalAmount += item.amount;
              });
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                      <Text
                        style={{
                          color: Colors.FoldPixel.GRAY400,
                          fontWeight: '500',
                          fontSize: 14,
                        }}
                      >
                        {intl.formatMessage({id: 'totalBilledAmount'})}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      <Text
                        style={{
                          color: Colors.FoldPixel.GRAY400,
                          fontWeight: '500',
                          fontSize: 18,
                        }}
                      >
                        ${totalAmount.toFixed(2)}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
            dataSource={dataSourceForTable}
            pagination={false}
            rowKey={(record: ICareProgramCptCode) => record.cptCode}
          />
          <ProviderActivityAndOutreachComponent
            selectedBillableActivityId={hookState.selectedItemForDetailView?.id}
            accountUserList={accountUserList}
            isBilledView={isBilledView}
          />
        </View>
      )}
    </Drawer>
  );
};

export default DetailedBill;
