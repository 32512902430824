import {
  HStack,
  Icon,
  IconButton,
  Pressable,
  Text,
  View,
  VStack,
} from 'native-base';
import React from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {Colors} from '../../../../../../../styles';
import TaxonsDnDContext from '../TaxonsDnDContext/TaxonsDnDContext';
import {checkEvenOrOd} from '../TaxonsUtils';
import {ITaxonListItem} from './Interface';
import {styles} from './TaxonListItemStyles';

const TaxonListItemView = (props: ITaxonListItem) => {
  const {taxon, index} = props;
  return (
    <VStack style={{marginVertical: 8}}>
      <HStack
      ml={ taxon.level > 1 ? taxon.level * 5 : '' }
        style={styles.listStyles}
        flex={1}
        alignItems={'center'}
        backgroundColor={
          checkEvenOrOd(index) ? Colors.Custom.DisplayCardBGColor : ''
        }
        shadow={1}
        mt={2}
      >
        <VStack flex={0.1}>
          <Icon
            as={AntIcon}
            name="bars"
            size="6"
            color={Colors.Custom.IconColor}
          />
        </VStack>
        <VStack flex={0.9}>
          <Text style={styles.taxonTextStyle}>{taxon.name}</Text>
        </VStack>
        <VStack flex={0.2} alignItems={'center'}>
          <HStack>
            <VStack>
              <Pressable>
                <IconButton
                  colorScheme="gray"
                  variant="ghost"
                  onPress={() => {
                    if (props.onActionPerformed) {
                      props.onActionPerformed(COMMON_ACTION_CODES.EDIT, taxon);
                    }
                  }}
                  icon={
                    <Icon as={Feather} name="edit-2" size="5" color="gray.400" />
                  }
                />
              </Pressable>
            </VStack>
            <VStack>
              <Pressable>
                <IconButton
                  colorScheme="gray"
                  variant="ghost"
                  onPress={() => {
                    if (props.onActionPerformed) {
                      props.onActionPerformed(
                        COMMON_ACTION_CODES.DELETE,
                        taxon
                      );
                    }
                  }}
                  icon={
                    <Icon
                      as={AntIcon}
                      name="delete"
                      size="5"
                      color="gray.400"
                    />
                  }
                />
              </Pressable>
            </VStack>
          </HStack>
        </VStack>
      </HStack>
      {true && taxon.children && taxon.children.length > 0 ? (
        <HStack
          justifyContent={'flex-end'}
          // borderColor={'red.100'}
          // borderWidth={2}
        >
          <View flex={1}>
            <TaxonsDnDContext
              onActionPerformed={props.onActionPerformed}
              taxon={taxon}
            />
            {/* <TaxonDndDroppable taxon={taxons.children} /> */}
          </View>
        </HStack>
      ) : (
        <></>
      )}
    </VStack>
  );
};

export default TaxonListItemView;
