import {useMutation, useQuery} from '@apollo/client';
import {Drawer, Skeleton, notification, Empty} from 'antd';
import {cloneDeep} from 'lodash';
import {VStack, HStack, View, Text, Divider} from 'native-base';
import {useContext, useState} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {MLOV_CATEGORY} from '../../../../../constants/MlovConst';
import {
  BUTTON_TYPE,
  DISPLAY_DATE_FORMAT,
} from '../../../../../constants/StringConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import CareJourneyQueries from '../../../../../services/CareJourney/CareJourneyQueries';
import UserQueries from '../../../../../services/User/UserQueries';
import {Colors} from '../../../../../styles/Colors';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {
  addTimeToDate,
  getDateObject,
  getDateObjFromDateStrAndFormat,
  getDateToMomentISOString,
  getJourneyStartDateWithOffset,
  getMomentObj,
  isPastDay,
  isTodayDate,
} from '../../../../../utils/DateUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovValueFromId,
} from '../../../../../utils/mlovUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionDatePicker} from '../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import {ModalActionSelect} from '../../../../common/ModalActionCommonComponent/ModalActionSelect';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {getCareJourneyEndDate, getPatientJourneyTeamRole} from '../../AddOrUpdateJourney/CareJourneyPreview/CareJourneyPreviewHelper';
import {JOURNEYS_STATUS_CODE} from './PatientCareJourneyHelper';
import {
  IPatientJourneyProps,
  IPatientJourneyUserData,
  IStartCareJourneyProps,
} from './PatientJourneyInterface';
import {useIntl} from 'react-intl';

const StartCareJourneyDrawer = (props: IPatientJourneyProps) => {
  const [stateData, setStateData] = useState<IStartCareJourneyProps>({
    startDate: undefined,
    careTeam: getPatientJourneyTeamRole(props.selectedJourney?.patientCareJourneyTeam) || [],
    usersData: [],
    selectedUserRoleMap: new Map<string, string>(),
    loading: true,
    saveLoading: false,
  });
  const intl = useIntl();
  const commonDataContext = useContext(CommonDataContext);
  const userRoles = getMlovListFromCategory(
    commonDataContext.MLOV,
    MLOV_CATEGORY.USER_ROLES,
  );
  const journeyDuration =
    getMlovListFromCategory(
      commonDataContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_JOURNEY_DURATION,
    ) || [];

  const patientJourneyStatusList =
    getMlovListFromCategory(
      commonDataContext.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PATIENT_CARE_JOURNEY_STATUS,
    ) || [];

  const getDuration = () => {
    if (props.selectedJourney?.careJourneyId) {
      const journeyDetails = props.selectedJourney;
      return (
        ' : ' +
        (journeyDetails?.duration || journeyDetails?.careJourney.duration) +
        ' ' +
        ((props.selectedJourney?.durationUnitId || props.selectedJourney?.careJourney?.durationUnitId)
          ? getMlovValueFromId(
              journeyDuration,
              (journeyDetails?.durationUnitId || journeyDetails?.careJourney?.durationUnitId),
            )
          : '')
      );
    }
    return '';
  };

  const isUserSelected = (userId: string, roleId: string | undefined) => {
    const matchTeamMember = props.selectedJourney.patientCareJourneyTeam.find(
      user => user.userId === userId && user.roleId === roleId,
    ) || undefined;
    if (matchTeamMember) {
      setStateData(prev => {
        const userRoleMap = prev.selectedUserRoleMap;
        userRoleMap.set(roleId || '', userId);
        return {
          ...prev,
          selectedUserRoleMap: userRoleMap,
        };
      });
      return true;
    }
    return false;
  };

  const [updatePatientCareJourney] = useMutation(
    CareJourneyQueries.UPDATE_PATIENT_CARE_JOURNEY_AND_CARE_TEAM,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    },
  );

  const getFilteredUsersByRoleId = (
    users: any[],
    roleId: string | undefined,
  ) => {
    return users
      .filter(user => {
        if (user.userRoles && user.userRoles.length > 0) {
          const matchedData = user.userRoles.filter((userRole: any) => {
            if (userRole?.userRole) {
              return userRole?.userRole?.id === roleId;
            }
            return false;
          });
          return matchedData.length > 0;
        }
        return false;
      })
      .map(user => {
        const matchTeamMember = props.selectedJourney.patientCareJourneyTeam.find(
          teamMember => teamMember.userId === user.uuid && teamMember.roleId === roleId,
        );
        return {
          name: user.name,
          uuid: user.uuid,
          roleId: roleId,
          isSelected: isUserSelected(user.uuid, roleId),
          patientCareJourneyId: matchTeamMember?.patientCareJourneyId,
          id: matchTeamMember?.id
        } as IPatientJourneyUserData;
      });
  };

  const getRoleIds = () => {
    const roleIds: string[] = [];
    (stateData.careTeam || []).forEach(
      role => {
        roleIds.push(role?.roleId);
      },
    );
    return roleIds;
  };

  const getJourneyRoleWiseUsers = useQuery(UserQueries.GET_USERS_BY_ROLE_IDS, {
    fetchPolicy: 'no-cache',
    variables: {
      roleIds: getRoleIds() || [],
      accountId: getAccountUUID(),
    },
    onCompleted: (response: any) => {
      const userRoleMapping: any[] = [];
      if (response && response.users && response.users.length > 0) {
        const users = cloneDeep(response.users);
        stateData.careTeam.forEach(role => {
          userRoleMapping.push({
            roleId: role.roleId,
            userList: getFilteredUsersByRoleId(users, role?.roleId),
          });
        });
      }
      setStateData(prev => ({
        ...prev,
        usersData: userRoleMapping,
        loading: false,
      }));
    },
    onError: (error: any) => {

      setStateData(prev => ({
        ...prev,
        loading: false,
      }));
    },
  });

  const resetContent = () => {
    setStateData(prev => {
      return {
        ...prev,
        startDate: undefined,
        careTeam: [],
        usersData: [],
        selectedUserRoleMap: new Map<string, string>(),
        loading: false,
      };
    });
  };

  const isAllowedToStartJourney = () => {
    return stateData.startDate && (stateData.careTeam.length===stateData.selectedUserRoleMap.size);
  };

  const getSelectedUsers = () => {
    const userList: any[] = [];
    stateData.usersData.map((roleUser: any, index) => {
      (roleUser.userList || []).filter((user: any) => {
        if (user.isSelected) {
          userList.push({patientCareJourneyId: props.selectedJourney.id, id: user?.id,  roleId: roleUser.roleId, userId: user.uuid});
        }
      });
    });

   (props.selectedJourney.patientCareJourneyTeam || []).forEach(prevCareTeam => {
    const isUserExist = (userList || []).some((user: any) => {
      return user?.id === prevCareTeam.id;
    });
    if (!isUserExist) {
      userList.push({patientCareJourneyId: props.selectedJourney.id, id: prevCareTeam.id,  roleId: prevCareTeam.roleId, userId: prevCareTeam.userId, isDeleted: true});
    }
   });
    return userList;
  };

  const getPatientJourneyConfig = () => {
    let selectedDate = getDateObjFromDateStrAndFormat(
      stateData.startDate || '',
    );
    const duration = parseInt((props.selectedJourney.duration || props.selectedJourney?.careJourney?.duration || 0).toString());
    const durationUnit = (props.selectedJourney.durationUnitId  || props.selectedJourney?.careJourney?.durationUnitId);
    let durationUnitCode = 'MONTH';

    const matchedData = journeyDuration?.filter?.((duration: any) => duration.id === durationUnit) || [];

    if (matchedData.length > 0) {
      durationUnitCode = matchedData[0].code;
    }
    if (isTodayDate(selectedDate)) {
      selectedDate = new Date();
    } else {
      selectedDate = getJourneyStartDateWithOffset(selectedDate)?.toDate();
    }
    const endDateTime = addTimeToDate(selectedDate, duration, durationUnitCode);
    const startDateTimeStr: string = getDateToMomentISOString(selectedDate);
    const startDateTime: Date = getDateObject(startDateTimeStr);
    const assignStatusId: string = getMlovIdFromCode(patientJourneyStatusList, JOURNEYS_STATUS_CODE.ASSIGNED);
    const activeStatusId: string = getMlovIdFromCode(patientJourneyStatusList, JOURNEYS_STATUS_CODE.ACTIVE);
    const journeyStatusId = startDateTime && startDateTime <= new Date() ? activeStatusId : assignStatusId;

    return {
      id: props.selectedJourney.id,
      operationCode: 'ACTIVATE',
      data: {
        statusId: journeyStatusId,
        startDateTime: startDateTimeStr,
        endDateTime: getDateToMomentISOString(endDateTime),
        careJourneyId: props.selectedJourney.careJourneyId,
        careJourneyVersionId: props.selectedJourney.careJourneyVersionId,
        memberOutcome: props.selectedJourney.memberOutcome,
        businessOutcome: props.selectedJourney.businessOutcome,
      },
    };
  };

  const updatePatientCareJourneyDetail = () => {
    setStateData(prev => ({ ...prev, saveLoading: true }));
    updatePatientCareJourney({
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      variables: {
        data: getPatientJourneyConfig(),
        patientCareJourneyTeam: getSelectedUsers(),
      },
      onCompleted: () => {
        notification.destroy();
        notification.success({
          message: isTodayDate(stateData.startDate || '')
            ? intl.formatMessage({id: 'journeyStartMsg'})
            : intl.formatMessage({id: 'journeyStartDateUpdatedMsg'}),
          duration: 3.0,
        });
        props.onActionPerformed(undefined,props.selectedJourney);
        setStateData(prev => ({ ...prev, saveLoading: true }));
        resetContent();
      },
      onError: error => {

        setStateData(prev => ({ ...prev, saveLoading: true }));
        notification.destroy();
          notification.error({
            message: intl.formatMessage({id: 'apiErrorMsg'}),
            duration: 3.0,
          });
      },
    });
  };

  return (
    <Drawer
      destroyOnClose
      placement="right"
      onClose={() => {
        resetContent();
        props.onCancel();
      }}
      visible={props.enabledDrawer}
      closable={false}
      width={'40%'}
      title={ <ModalActionTitle
        title={'startJourney'}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: 'cancel',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isDisabled: stateData.saveLoading,
            isTransBtn: false,
            onClick: () => {
              resetContent();
              props.onCancel();
            },
          },
          {
            show: true,
            id: 2,
            btnText: 'start',
            isDisabled: !isAllowedToStartJourney() || stateData.saveLoading,
            isLoading: props.isLoading || stateData.saveLoading,
            textColor: Colors.Custom.mainPrimaryPurple,
            variant: BUTTON_TYPE.PRIMARY,
            isTransBtn: false,
            onClick: () => {
              updatePatientCareJourneyDetail();
            },
          },
        ]}
      />}
      >

      {(stateData.loading || getJourneyRoleWiseUsers.loading) && (
        <View width="full" padding={4}>
          <Skeleton active />
        </View>
      )}
      {!stateData.loading && !getJourneyRoleWiseUsers.loading && (
        <View>
          <VStack margin={2}>
            <DisplayText
              size={'mdSemibold'}
              extraStyles={{
                fontWeight: 700,
                fontSize: 20,
                fontFamily: 'Manrope',
                paddingTop: 4,
              }}
              textLocalId={props?.selectedJourney?.title || props?.selectedJourney?.careJourney?.title}
            />
          </VStack>
          <Divider margin={2} />
          <VStack margin={2}>
            <HStack>
              <DisplayText
                size={'mdSemibold'}
                extraStyles={{
                  color: Colors.Custom.Gray700,
                  fontWeight: 400,
                  fontSize: 14,
                  fontFamily: 'Manrope',
                }}
                textLocalId={'journeyDuration'}
              />

              <DisplayText
                size={'mdSemibold'}
                extraStyles={{
                  color: Colors.Custom.Gray700,
                  fontWeight: 400,
                  fontSize: 14,
                  fontFamily: 'Manrope',
                }}
                textLocalId={getDuration()}
              />
            </HStack>
          </VStack>
          <VStack margin={2} marginTop={8}>
            <DisplayText
              size={'mdSemibold'}
              extraStyles={{
                fontWeight: 600,
                fontSize: 18,
                fontFamily: 'Manrope',
              }}
              textLocalId={'startJourney'}
            />
          </VStack>
          <Divider margin={2} />
          <VStack margin={2}>
            <HStack flex={1}>
              <DisplayText
                size={'mdSemibold'}
                extraStyles={{
                  color: Colors.Custom.Gray700,
                  fontWeight: 400,
                  fontSize: 14,
                  fontFamily: 'Manrope',
                }}
                textLocalId={'fromDate'}
              />
              <Text color="error.500">*</Text>
            </HStack>
            <ModalActionDatePicker
              isRequired={false}
              label={''}
              format={DISPLAY_DATE_FORMAT}
              onChange={(date: any) => {
                if (date) {
                  setStateData(prev => {
                    return {
                      ...prev,
                      startDate: date,
                    };
                  });
                }
              }}
              disabledDate={(current: any) => {
                return current && isPastDay(current);
              }}
              value={stateData.startDate || ''}
            />
          </VStack>
          {props?.selectedJourney?.careJourney?.careJourneyTeam?.length === 0 ?
            (
              <VStack alignItems={'center'} marginTop={12}>
                <Empty description={<text fontFamily='Manrope, sans-serif' style={{ color: '#D0D5DD' }}>No care team selected</text>} />
              </VStack>
            )
            :
            (
              <>
                <VStack margin={2} marginTop={8}>
                  <DisplayText
                    size={'mdSemibold'}
                    extraStyles={{
                      fontWeight: 600,
                      fontSize: 18,
                      fontFamily: 'Manrope',
                    }}
                    textLocalId={'selectCareTeam'}
                  />
                </VStack>
                <Divider margin={2} />
                <VStack margin={2}>
                  <VStack space={4} justifyContent="center">
                    {stateData.usersData.map((roleUser: any, index) => {
                      if (roleUser.userList.length) {
                        return (
                          <HStack alignItems="center" space={2} key={index} >
                            <ModalActionSelect
                              label={getMlovValueFromId(userRoles, roleUser.roleId)}
                              isRequired={true}
                              placeholder={`Select ${getMlovValueFromId(
                                userRoles,
                                roleUser.roleId,
                              )}`}
                              selectedValue={
                                stateData?.selectedUserRoleMap?.get(roleUser.roleId) ||
                                ''
                              }
                              onValueChange={(value: string) => {
                                (roleUser.userList || []).forEach((user: any) => {
                                  user.isSelected = user?.uuid === value;
                                });
                                setStateData(prev => {
                                  const userRoleMap = prev.selectedUserRoleMap;
                                  userRoleMap.set(roleUser.roleId, value);
                                  return {
                                    ...prev,
                                    selectedUserRoleMap: userRoleMap,
                                  };
                                });
                              }}
                              data={roleUser.userList}
                              selectItemProps={{
                                key: 'uuid',
                                label: 'name',
                                value: 'uuid',
                              }}
                              customStyle={{ flex: 1 }}
                            />
                          </HStack>
                        );
                      }
                    })}
                  </VStack>
                </VStack>
              </>
            )}
        </View>
      )}
    </Drawer>
  );
};

export default StartCareJourneyDrawer;
