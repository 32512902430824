import {Image, Text, View} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {styles} from '../../AccessFormStyles';
import {AccessFormProps} from '../interfaces';
import {AccessFormDrawer} from './AccessFormDrawer';

const AccessForm = (props: AccessFormProps) => {
  const {onFormCompleteAction} = props;
  const [accessFormState, setAccessFormState] = useState({
    isOpen: false,
  });
  const intl = useIntl();
  const handleFormCompleteAction = (actionCode: string) => {
    setAccessFormState((prev) => {
      return {...prev, isOpen: false};
    });
    onFormCompleteAction(actionCode);
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          marginVertical: 20,
          marginBottom: 20,
        }}
      >
        <Text>Your Practice is not yet connected to hint</Text>
        <Text
          size={'smMedium'}
          color={Colors.Custom.Gray700}
          style={{marginTop: 20}}
        >
          We would need “AUTHORIZATION CODE” from Hint dashboard to enable the
          integration between Fold and Hint. Please follow below steps to get
          access to authorization code (step 4).
        </Text>

        <Text
          size={'smMedium'}
          color={Colors.Custom.Gray700}
          style={{marginTop: 20}}
        >
          Step 1: Find and click on Fold health in Other integrations
        </Text>

        <Image
          resizeMode="contain"
          style={{width: 300, height: 300}}
          source={require('../../../../../assets/images/Integration/hint-integration-step-1.png')}
          alt="image"
        ></Image>

        <Text
          size={'smMedium'}
          color={Colors.Custom.Gray700}
          style={{marginTop: 20}}
        >
          Step 2: Click on integrate with Fold Health
        </Text>

        <Image
          resizeMode="contain"
          style={{width: 500, height: 150}}
          source={require('../../../../../assets/images/Integration/hint-integration-step-2.png')}
          alt="image"
        ></Image>

        <Text
          size={'smMedium'}
          color={Colors.Custom.Gray700}
          style={{marginTop: 20}}
        >
          Step 3: Click on Enable Fold Health
        </Text>
        <Image
          resizeMode="contain"
          style={{width: 300, height: 150}}
          source={require('../../../../../assets/images/Integration/hint-integration-step-3.png')}
          alt="image"
        ></Image>

        <Text
          size={'smMedium'}
          color={Colors.Custom.Gray700}
          style={{marginTop: 20}}
        >
          Step 4: Copy the Authorization code and input in the box below
        </Text>
        <Image
          resizeMode="contain"
          style={{width: 500, height: 200}}
          source={require('../../../../../assets/images/Integration/hint-integration-step-4.png')}
          alt="image"
        ></Image>
      </View>
      <FoldButton
        nativeProps={{
          variant: BUTTON_TYPE.PRIMARY,
          onPress: () => {
            setAccessFormState((prev) => {
              return {
                ...prev,
                isOpen: true,
              };
            });
          },
        }}
        customProps={{
          btnText: intl.formatMessage({
            id: 'Connect to Hint',
          }),
          withRightBorder: false,
        }}
      ></FoldButton>
      {accessFormState.isOpen && (
        <AccessFormDrawer
          isOpen={accessFormState.isOpen}
          onFormCompleteAction={(actionCode) => {
            handleFormCompleteAction(actionCode);
          }}
          onClose={() =>
            setAccessFormState((prev) => {
              return {
                ...prev,
                isOpen: false,
              };
            })
          }
        />
      )}
    </View>
  );
};

export default AccessForm;
