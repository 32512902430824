import {Table} from 'antd';
import React, {useEffect, useState} from 'react';
import {Dimensions, View} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {getInboxColumns} from './EFaxInboxTableColumn';
import {IEFaxInbox} from '../Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/interface';
import {useLazyQuery} from '@apollo/client';
import InboxQueries from '../../../../services/Inbox/InboxQueries';
import {getAccountId, getUserId, isLoginUserBusinessOwner} from '../../../../utils/commonUtils';
import {Skeleton} from 'native-base';
import {IEFaxInboxTable} from './interface';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { StyleSheet } from 'react-native';

const EFaxInboxTable = (props: IEFaxInboxTable) => {
  const {height} = Dimensions.get('window');
  const finalHeight = height - 210;
  const accountId = getAccountId();
  const userId = getUserId();
  const isBusinessOwner= isLoginUserBusinessOwner();

  const [state, setState] = useState({
    loading: true,
    inboxList: [] as IEFaxInbox[],
  });

  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );

  useEffect(() => {
    if (!props?.enableUpdateDrawer) {
      getInboxList();
    }
  }, [props?.enableUpdateDrawer]);

  const [getEFaxInboxesOnUserLevel] = useLazyQuery(
    InboxQueries.GetUserEFaxInboxListWithInboxMembers,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getEFaxInboxes] = useLazyQuery(
    InboxQueries.GetEFaxInboxListWithInboxMembers,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getInboxList = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let inboxList = [] as IEFaxInbox[]
    if (!isCommunicationLocationHandlingEnabled || isBusinessOwner) {
      const response = await getEFaxInboxes({
        variables: {
          accountId: accountId,
        },
      });
      inboxList = response?.data?.inboxes
    } else {
      const response = await getEFaxInboxesOnUserLevel({
        variables: {
          accountId: accountId,
          userId: userId,
        },
      });
      inboxList = response?.data?.inboxes
    }
    setState((prev) => {
      return {
        ...prev,
        loading: false,
        inboxList: inboxList,
      };
    });
  };

  return (
    <>
      {state.loading ? (
        <View style={styles.skeletonContainer}>
          <Skeleton.Text lines={4} />
        </View>
      ) : (
        <Table
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          style={{minHeight: finalHeight}}
          scroll={{x: 800, y: finalHeight}}
          dataSource={state.inboxList}
          columns={getInboxColumns(props.setAction)}
          pagination={false}
          onRow={(data) => {
            return {
              onClick: () => props.setAction(COMMON_ACTION_CODES.EDIT, data),
            };
          }}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  skeletonContainer: {
    padding: 6,
  },
});

export default EFaxInboxTable;
