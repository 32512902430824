import {
  Center,
  Select,
  Skeleton,
  Spinner,
  Text,
  useMediaQuery,
  View,
} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {
  getAccountName,
  getAccountUUID,
  getMemberSatisfactionFormsOnly,
  isChildAccount,
  isEmployerRole,
} from '../../../../../utils/commonUtils';
import {DashboardDataContext} from '../../../Analytics/EmployerAnalytics/EmployerContext/DashboardContext';
import {EMPLOYER_CONST, MEMBER_CONST, TEMP_MEMBER_TYPE_FOR_DASHBOARD} from '../../../Analytics/EmployerAnalytics/EmployerUtils/EmployerConst';
import '../../../BrazilCodeMR/index.css';
import AccountAnalytics from './AccountAnalytics';
import {Account, AnalyticsState} from './AnalyticsController';
import '../../../BrazilCodeMR/pages/analytics/EmpDashboard.css';
import DateRangeFilter from '../../../Analytics/EmployerAnalytics/CommonView/DateRangeFilter';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useIntl} from 'react-intl';
import PrintPreview from '../../../Analytics/EmployerAnalytics/PrintPreview/PrintPreview';
import {ModalActionAntSelect} from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {FormsQueries} from '../../../../../services';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {useLazyQuery} from '@apollo/client';
import {IMemberSatisfactionForm} from '../../../Analytics/EmployerAnalytics/interface';
import {IAccountLocation} from '../../../Contacts/TeamMembers/interfaces';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import {IAccountPracticeLocationResp} from '../../../../../services/Location/interfaces';
import {getResourceAbilities} from '../../../../../utils/capabilityUtils';
import {FHIR_RESOURCE} from '../../../../../constants/FhirConstant';
import {getAccountConfigValue, isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';

export default function AccountDashboard(props: any) {
  const {height} = Dimensions.get('window');
  const [accDashboardState, setAccDashboardState] = useState({
    showPayerSelectModal: false,
    selectedPayerId: '',
    selectedPayerIdAfterUpdate: '',
    isLoading: false,
  });
  const isChildAccountFlag = isChildAccount()
  const hintMembershipDisabled = isAccountConfigEnabled(CONFIG_CODES.CONTACT_MEMBERSHIP_DISABLED) || isChildAccountFlag
  const [state, setState] = useState<AnalyticsState>({
    loading: false,
    selected: {} as Account,
    memberType: MEMBER_CONST.ALL_MEMBERS,
    dateRangeType: EMPLOYER_CONST.YEAR,
    dashboardAction: false,
    allPayerList: [],
    memberSatisfactionFormList: [] as IMemberSatisfactionForm[],
  });
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const [dateRange, setDateRange] = useState({
    from: '' as any,
    to: '' as any,
    uiFromDate: '' as any,
    uiToDate: '' as any,
  });
  const tenantId = getAccountUUID();
  const accountName = getAccountName();
  const [preview, setPreview] = useState<boolean>(false);
  const intl = useIntl();
  const [locationFiltersState, setLocationFiltersState] = useState({
    accountPracticeLocations: [] as IAccountLocation[],
  });
  const [accountPracticeLocations] = useLazyQuery<IAccountPracticeLocationResp>(
    UserPracticeLocationQueries.GetPracticeLocationsByTenantId,
    {
      variables: {tenantId: tenantId},
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [GetMembersSatisfaction] = useLazyQuery(
    FormsQueries.GET_MEMBERS_SATISFACTION,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  useEffect(() => {
    updateMembersastisfactionList();
  }, []);

  const updateMembersastisfactionList = async () => {
    let memberSatisfactionFormList = [] as any[];
    const memberSatisfactionFormResp = await GetMembersSatisfaction();
    if (memberSatisfactionFormResp?.data?.forms?.length) {
      memberSatisfactionFormList = getMemberSatisfactionFormsOnly(memberSatisfactionFormResp?.data?.forms) as any[];;
    }
    setState((prev) => {
      return {
        ...prev,
        memberSatisfactionFormList: [...memberSatisfactionFormList],
        isLoading: false,
      };
    });
  };
  const getAccountPracticeLocations = async () => {
    const accountLocationRes = await accountPracticeLocations().catch((err) => {
    })

    if (accountLocationRes?.data?.accountLocations?.length) {
      const allLocationObj: any =  {
        uuid: 'ALL',
        practiceLocation: {
          name: 'All location',
          uuid: 'ALL',
        }
      }
      accountLocationRes?.data?.accountLocations.unshift(allLocationObj)
      setLocationFiltersState(prev => {
        return {
          ...prev,
          accountPracticeLocations: accountLocationRes?.data?.accountLocations || [] as IAccountLocation[]
        }
      })
    }
  }

  useEffect(() => {
    getAccountPracticeLocations()
  },[])
  return (
    <View nativeID="empMainSection">
      {accDashboardState.isLoading && (
        <View
          height={'full'}
          width={'full'}
          position="absolute"
          zIndex={10}
          bg={'coolGray.50:alpha.70'}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner size={'lg'} />
          </Center>
        </View>
      )}
      <View style={{flexDirection: 'column'}}>
        {!props.isFromEmployerListing ? (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: isSmallScreen ? 'space-evenly' : 'space-between',
              marginHorizontal: isSmallScreen ? '' : 22,
            }}
          >
            <View
              style={{
                paddingHorizontal: isSmallScreen ? 10 : 0,
                paddingVertical: 12,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <View style={{width: hintMembershipDisabled ? 100 : props?.isSmallScreen ? 170 : 320}}>
              <View
                  style={{
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: '#e4e7ec',
                    borderStyle: 'solid',
                    padding: 8,
                    height: 40,
                    display: hintMembershipDisabled ? 'flex' : 'none',
                    justifyContent: 'center',
                  }}
                >
                <Text size={'xsNormal'} color={'#1f2937'}>
                  All Members
                </Text>
                </View>
                <Select
                  width={'100%'}
                  height={10}
                  display={hintMembershipDisabled ? 'none' : 'flex'}
                  borderRadius={8}
                  borderColor={Colors.Custom.Gray200}
                  backgroundColor={'#fff'}
                  onValueChange={(value) => {
                    setState?.((prev: any) => {
                      return {
                        ...prev,
                        memberType: value,
                      };
                    });
                  }}
                  selectedValue={state?.memberType || ''}
                >
                  {TEMP_MEMBER_TYPE_FOR_DASHBOARD?.map((item) => {
                    return (
                      <Select.Item
                        fontSize={16}
                        label={item.name}
                        value={item.code}
                        key={item.id}
                      />
                    );
                  })}
                </Select>
              </View>
              <DateRangeFilter
                dateRange={dateRange}
                setDateRange={setDateRange}
                setState={setState}
                state={state}
                isSmallScreen={isSmallScreen}
              />
            </View>
            {isEmployerRole() ? (
              <></>
            ) : (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              ></View>
            )}
          </View>
        ) : (
          <></>
        )}
        <Text
          style={{
            marginLeft: isSmallScreen ? 10 : 18,
            color: Colors.Custom.Gray400,
            padding: 4,
            paddingTop: 0,
            fontSize: 12,
          }}
        >
          • {intl.formatMessage({id: 'analyticsSyncMessage'})}
        </Text>
      </View>
      <View
        style={{
          height: height - 140,
          backgroundColor: '#F2F4F7',
          paddingTop: 16,
          marginTop: 0,
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderColor: Colors.Custom.Gray200,
        }}
      >
        {preview ? (
          <div
            className="main-container"
            style={{
              marginTop: props.isFromEmployerListing ? 20 : 0,
              // height: props.isFromEmployerListing ? height - 100 : height - 100,
              overflow: 'scroll',
              paddingTop: 12,
            }}
          >
            <DashboardDataContext.Provider
              value={{
                toDate: dateRange.to,
                fromDate: dateRange.from,
                dateRangeType: state.dateRangeType || '',
                uiToDate: dateRange.uiToDate,
                uiFromDate: dateRange.uiFromDate,
                employerName: state?.selected?.name || '',
              }}
            >
              {preview ? (
                <PrintPreview
                  memberSatisfactionFormList={props.memberSatisfactionFormList || []}
                  isVisible={preview}
                  onCancel={() => {
                    setPreview(false);
                  }}
                  dateRangeType={state.dateRangeType || ''}
                  dateRange={dateRange}
                />
              ) : (
                <></>
              )}
            </DashboardDataContext.Provider>
          </div>
        ) : (
          <></>
        )}
        <div
          className="main-container"
          style={{
            marginTop: props.isFromEmployerListing ? 20 : 0,
            overflow: 'scroll',
            paddingTop: 12,
          }}
        >
          <DashboardDataContext.Provider
            value={{
              toDate: dateRange?.to,
              fromDate: dateRange?.from,
              dateRangeType: state?.dateRangeType || '',
              uiToDate: dateRange?.uiToDate,
              uiFromDate: dateRange?.uiFromDate,
              accountPracticeLocations: locationFiltersState.accountPracticeLocations
            }}
          >
            {state.loading ? (
              <Skeleton.Text lines={5} />
            ) : (
              <AccountAnalytics
                dateRangeType={state.dateRangeType || ''}
                dateRange={dateRange}
                dashboardCallback={() => {
                  props.dashboardCallback?.();
                  setState((prev) => {
                    return {
                      ...prev,
                      dashboardAction: false,
                    };
                  });
                }}
                dashboardAction={state.dashboardAction}
                accountUuid={tenantId}
                accountName={accountName}
                memberType={state.memberType}
              />
            )}
          </DashboardDataContext.Provider>
        </div>
      </View>
    </View>
  );
}
