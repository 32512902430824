export const getFormatedResponseObject = (taxon: any, taxonomy: any) => {
  const formatedObjet = {
    taxonomy_id: taxonomy.id,
    parent_id: taxon.parentId,
    name: taxon.name,
    description: taxon.rawObjRes.attributes.description,
    meta_description: taxon.rawObjRes.attributes.meta_description,
    meta_keywords: taxon.rawObjRes.attributes.meta_keywords,
    meta_title: taxon.rawObjRes.attributes.meta_title,
    permalink: taxon.rawObjRes.attributes.permalink,
    tempParam: getFoamtedParamLink(taxon.rawObjRes.attributes.permalink).last
  };
  return formatedObjet;
};

export const getFoamtedParamLink = (value: string) => {
  const rest = value.substring(0, value.lastIndexOf('/') + 1);
  const last = value.substring(value.lastIndexOf('/') + 1, value.length);
  const formatedParamLink = {
    rest,
    last,
  };
  return formatedParamLink;
};
