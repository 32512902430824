import CommonService from "../../../services/CommonService/CommonService";
import { GET_MERGE_DELAY_TRIGGERS } from "../../../services/MergeDelay/MergeDelayQueries";
 "../../../services/MergeDelay/MergeDelayQueries";

const commonService = CommonService.getCommonServiceInstance();
const workflowService = commonService.workflowService;

export const addUpdateMergeDelayTrigger = async (
    mergeData: any
  ) => {
    const response = await workflowService.post('/workflow/api/v2/mergeAndDelayedTrigger', mergeData);
    return response
  };
  export const getMergeDelayTriggers = async (
    limit: number, offset:number
  ) => {
    const queryString = GET_MERGE_DELAY_TRIGGERS;
    const data = {
      query: queryString,
      variables: {
            offset,
            limit
        
      },
    };
    const response = await workflowService.post('/graphql',data);
    
    return response?.data?.data?.mergeAndDelayedTriggers;
  };
