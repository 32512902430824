import moment from 'moment';
import {Colors} from '../../../../styles';

export const getColors = (status: any) => {
  let color = Colors.Custom.Gray400;
  if (status.code === 'open') {
    color = Colors.info['500'] || '';
  }
  if (status.code === 'All') {
    color = 'green' || '';
  }
  if (status.code === 'resolved') {
    color = Colors.success['500'] || '';
  }
  if (status.code === 'pending') {
    color = Colors.warning['400'] || '';
  }
  if (status.code === 'snoozed') {
    color = '#FD853A';
  }
  return color;
};

export const formatDate = (date: any) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const getToDayDate = (returnInISOFormat = false) => {
  const fromDate = moment().startOf('day');
  const toDate = moment().endOf('day');
  const from = returnInISOFormat ? fromDate.toISOString() : fromDate.format('YYYY-MM-DDTHH:mm:ss');
  const to = returnInISOFormat ? toDate.toISOString() : toDate.format('YYYY-MM-DDTHH:mm:ss');
  // const from = new Date(new Date().setUTCHours(0,0,0,0)).toISOString()
  // const to = new Date(new Date().setUTCHours(24,0,0,0)).toISOString()

  return {fromDate: from, toDate: to};
};

export const getYesterdayDate = (returnInISOFormat = false) => {
  const fromDate = moment().subtract(1, 'days').startOf('day');
  const toDate = moment().startOf('day');
  const from = returnInISOFormat ? fromDate.toISOString() : fromDate.format('YYYY-MM-DDTHH:mm:ss');
  const to = returnInISOFormat ? toDate.toISOString() : toDate.format('YYYY-MM-DDTHH:mm:ss');

  return {fromDate: from, toDate: to};
};
export const getLastWeek = (returnInISOFormat = false) => {
  const fromDate = moment().subtract(1, 'weeks').startOf('day');
  const toDate = moment().endOf('day');
  const from = returnInISOFormat ? fromDate.toISOString() : fromDate.format('YYYY-MM-DDTHH:mm:ss');
  const to = returnInISOFormat ? toDate.toISOString() : toDate.format('YYYY-MM-DDTHH:mm:ss');

  return {fromDate: from, toDate: to};
};

export const getLastMonth = (returnInISOFormat = false) => {
  const fromDate = moment().subtract(1, 'months').startOf('day');
  const toDate = moment().endOf('day');
  const from = returnInISOFormat ? fromDate.toISOString() : fromDate.format('YYYY-MM-DDTHH:mm:ss');
  const to = returnInISOFormat ? toDate.toISOString() : toDate.format('YYYY-MM-DDTHH:mm:ss');

  return {fromDate: from, toDate: to};
};


const DATE_FILTER_CODES = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST_WEEK: 'LAST_WEEK',
  LAST_MONTH: 'LAST_MONTH',
};

export const DATE_FILTER_LIST = [
  {
    id: 1,
    code: DATE_FILTER_CODES.TODAY,
    value: getToDayDate(),
    name: 'toDay',
  },
  {
    id: 2,
    code: DATE_FILTER_CODES.YESTERDAY,
    value: getYesterdayDate(),
    name: 'yesterday',
  },
  {
    id: 3,
    code: DATE_FILTER_CODES.LAST_WEEK,
    value: getLastWeek(),
    name: 'lastWeek',
  },
  {
    id: 4,
    code: DATE_FILTER_CODES.LAST_MONTH,
    value: getLastMonth(),
    name: 'lastMonth',
  },
];

export const getDateObjFromCode = (dateCode: string, returnInISOFormat = false) => {
  switch (dateCode) {
    case DATE_FILTER_CODES.TODAY:
      return getToDayDate(returnInISOFormat);
      break;
    case DATE_FILTER_CODES.YESTERDAY:
      return getYesterdayDate(returnInISOFormat);
      break;
    case DATE_FILTER_CODES.LAST_WEEK:
      return getLastWeek(returnInISOFormat);
      break;
    case DATE_FILTER_CODES.LAST_MONTH:
      return getLastMonth(returnInISOFormat);
      break;
  }
};
