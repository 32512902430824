import {
  ArrowLeftOutlined,
  MoreOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {Button, Card, Col, Dropdown, Row, Spin, Tag} from 'antd';
import {Stack, useMediaQuery, View, VStack} from 'native-base';
import React, {FC, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import BarChartGraph from '../widgets/components/BarChart';
import listData from '../widgets/mockData/HealthStatus';
import ItemDescription from '../components/ItemDescription';
import SingularActionsDropdownMenu from '../components/SingularActions';
import {AnalyticsTableOptions} from '../components/Analytics/AnalyticsTableOptions';
import EpisodeDetailsModal from '../components/EpisodeDetailsModal';
import OpportunityAssociationModal from '../components/OpportunityAssociationModal';
import {
  performanceOverTimeMock,
  pOverTimeMock,
} from '../widgets/mockData/PerformanceOverTime';
import {
  clinicalPerformanceMock,
  clinicalPerformanceMockData,
} from '../widgets/mockData/ClinicalPerformanceMock';
import OpportunityDetailDisplay from '../components/OpportunityDetailDisplay';
import AreaCartesianGridChart from '../widgets/components/AreaCartesianGridChart';
import LocalStorage from '../../../../utils/LocalStorage';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import {SearchBar} from '../../../common/SearchBar';
import {Colors} from '../../../../styles';
import {IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../constants';
import {Dimensions} from 'react-native';

const CREATE_STEPS = ['QUICKSTART', 'ASSOCIATE'];
const renderLegend = (props: any) => {
  const {payload} = props;

  return (
    <ul
      style={{
        textAlign: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
        gap: '1.75rem',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <li style={{color: '#53389E', fontSize: '16px'}}>Target</li>
      {payload.map((entry: any, index: number) => (
        <li
          key={`item-${index}-entry`}
          style={{color: entry.color, fontSize: '16px'}}
        >
          {entry.value == 'value' ? 'Glucose' : entry.value}
        </li>
      ))}
    </ul>
  );
};

const OpportunityDetails: FC = () => {
  const {opportunityId} = useParams();
  const {state: stateOpportunity} = useLocation();
  const navigate = useNavigate();
  const [opportunity, setOpportunity] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
    },
    {
      title: 'Source',
      dataIndex: 'source',
      // key: "source",
      render: (text: string, _record: any) => {
        return (
          <Button size="small" type="default" icon={<SettingOutlined />}>
            {text}
          </Button>
        );
      },
    },
    {
      title: 'Members',
      dataIndex: 'members',
      key: 'members',
    },
    {
      title: 'Care Journey',
      dataIndex: 'careJourney',
      key: 'careJourney',
    },
    {
      title: 'Remaining Time',
      dataIndex: 'remainingTime',
      key: 'remainingTime',
    },
    {
      title: 'Creation Date',
      dataIndex: 'creationDate',
      key: 'creationDate',
      render: (text: string, record: any) => {
        return <span>{record.creationDate.toDateString()}</span>;
      },
    },
    {
      render: () => {
        return (
          <Dropdown
            overlay={SingularActionsDropdownMenu}
            placement="bottomRight"
          >
            <Button
              className="btnMoreOptions"
              shape="circle"
              type="text"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        );
      },
    },
  ];

  const [loading, setLoading] = useState(false);
  const getHeaders = async () => {
    const userDataString = await LocalStorage.getItem('user');
    const userData = JSON.parse(userDataString);

    const responseAccessToken = userData?.accessToken || '';
    const fold_access_token = userData?.fold_access_token;

    const headersObj: any = {
      'access-token': responseAccessToken,
      fold_access_token,
    };

    return headersObj;
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const findOpportunity = async (opportunity: any) => {
    setOpportunity({
      ...opportunity,
      isLoading: true,
    });
    try {
      const authHeaders = await getHeaders();
      const req = await fetch(
        'https://dev.foldhealth.io/opportunities/view/' + opportunity,
        {
          headers: authHeaders,
        }
      );
      const res = await req.json();

      if (res.status) {
        setOpportunity({
          ...opportunity,
          isLoading: false,
          data: res.opportunity || res.data,
          error: null,
        });
      }
    } catch (err: any) {
      setOpportunity({
        isLoading: false,
        data: err.response,
        error: err.message,
      });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const [pagination, setPagination] = useState({
    page: 1,
    pages: 0,
    total: 0,
    limit: 10,
  });
  const navHistory = useNavigate();

  useEffect(() => {
    stateOpportunity &&
      setOpportunity({...opportunity, data: stateOpportunity as any});
    opportunityId && !stateOpportunity && findOpportunity(opportunityId);
  }, [opportunityId]);

  const opportunityName = opportunity?.data
    ? (opportunity.data as any).title
    : null;

  const [episodeDetails, setEpisodeDetails] = useState({
    isVisible: false,
    selected: null,
  });

  const {height} = Dimensions.get('window');

  return (
    <View>
      <View
        background={'#fff'}
        paddingBottom="16px"
        borderBottomWidth={1}
        borderBottomColor={'#E4E7EC'}
      >
        {!opportunity.isLoading && opportunity.data ? (
          <TitleSubtitleView
            showBackButton
            onBackClick={() => navHistory('/opportunities')}
            title={(opportunity?.data as any).name}
          />
        ) : (
          <Spin />
        )}
      </View>
      <EpisodeDetailsModal
        width={916}
        title={
          <div
            style={{
              paddingTop: '1rem',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexDirection: 'column',
              width: '100%',
              display: 'flex',
            }}
          >
            <h1 style={{fontSize: '24px'}}>Episode Details</h1>
            {opportunity.data && (
              <p>
                View all Episode Details for {(opportunity.data as any).name}
              </p>
            )}
          </div>
        }
        isVisible={episodeDetails.isVisible}
        onClose={() => setEpisodeDetails({...episodeDetails, isVisible: false})}
      >
        <Card
          title={
            <span
              style={{
                gap: '0.5rem',
                justifyContent: 'flex-start',
                alignItems: 'center',
                display: 'inline-flex',
              }}
            >
              Total{' '}
              <Button className="btnTagDefault" shape="round" size="small">
                {/* {opportunity.data ? (opportunity.data as any).members.length : 0} Members */}
                147 Members
              </Button>
            </span>
          }
        >
          <OpportunityDetailDisplay opportunity={opportunity} />
        </Card>
      </EpisodeDetailsModal>

      <div
        className="analyticsViewOpportunitiesContainer"
        style={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
          width: '100%',
          display: 'flex',
          padding: 34,
          height: height - 100,
          overflow: 'scroll',
        }}
      >
        {isIPadMiniScreen || isIPadScreen ? (
          <>
            <Stack
              space={'2'}
              direction="column"
              style={{
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Card
                title="Episode Details"
                extra={
                  <Button
                    type="default"
                    shape="round"
                    onClick={(e) =>
                      setEpisodeDetails({...episodeDetails, isVisible: true})
                    }
                  >
                    View All Details
                  </Button>
                }
                style={{
                  width: '100%',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <p>
                    <span style={{fontWeight: 600}}>Primary Diagnosis</span>{' '}
                    <span style={{color: 'rgb(156 163 175 /1)'}}>
                      {' '}
                      Type 2 Diabetes mellitus
                    </span>
                  </p>
                </div>
                <div
                  style={{
                    justifyContent: 'flex-start',
                    alignItems: 'baseline',
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Tag>55 Encounters</Tag>
                  <p>
                    <span style={{fontWeight: 600}}>
                      Primary Diagnosis Description
                    </span>{' '}
                    <span style={{color: 'rgb(156 163 175 /1)'}}>
                      {' '}
                      Type 2 Diabetes mellitus
                    </span>
                  </p>
                </div>
              </Card>
              <Card
                title="Cohort Details"
                style={{width: '100%', height: '100%'}}
              >
                <ItemDescription label="Cohort Size">
                  147 members
                </ItemDescription>
                <ItemDescription label="Care Journey">
                  {opportunity.data
                    ? (opportunity.data as any).careJourney
                    : 'None'}
                </ItemDescription>
              </Card>
              <Card title="Timeframe" style={{width: '100%', height: '100%'}}>
                <ItemDescription label="Remaining Time">
                  {opportunity.data &&
                  (opportunity.data as any).startDate &&
                  (opportunity.data as any).endDate
                    ? new Date(
                        new Date((opportunity.data as any).endDate).getTime() -
                          new Date(
                            (opportunity.data as any).startDate
                          ).getTime()
                      ).toDateString()
                    : '-'}
                </ItemDescription>
                <ItemDescription label="Start Date">
                  {opportunity.data && (opportunity.data as any).startDate
                    ? new Date(
                        (opportunity.data as any).startDate
                      ).toDateString()
                    : '-'}
                </ItemDescription>
                <ItemDescription label="End Date">25 Sep, 2022</ItemDescription>
              </Card>

              <Card
                style={{
                  width: '100%',
                }}
                title="Clinical Performance"
              >
                <BarChartGraph
                  width="700"
                  height="450"
                  color="#7F56D9"
                  data={clinicalPerformanceMockData}
                  id="overTimePerfomance"
                />
              </Card>

              <Card
                style={{
                  width: '100%',
                }}
                title="Performance Over Time"
              >
                <AreaCartesianGridChart
                  pOverTimeMock={pOverTimeMock}
                  renderLegend={renderLegend}
                />
              </Card>
            </Stack>
          </>
        ) : (
          <>
            <Row
              className="equal-height"
              style={{
                width: '100%',
                height: '100%',
                marginBottom: '1.25rem',
              }}
            >
              <Col className="gutter-row" span={12}>
                <Card
                  title="Episode Details"
                  extra={
                    <Button
                      type="default"
                      shape="round"
                      onClick={(e) =>
                        setEpisodeDetails({...episodeDetails, isVisible: true})
                      }
                    >
                      View All Details
                    </Button>
                  }
                  style={{
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <p>
                      <span style={{fontWeight: 600}}>Primary Diagnosis</span>{' '}
                      <span style={{color: 'rgb(156 163 175 /1)'}}>
                        {' '}
                        Type 2 Diabetes mellitus
                      </span>
                    </p>
                  </div>
                  <div
                    style={{
                      justifyContent: 'flex-start',
                      alignItems: 'baseline',
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <Tag>55 Encounters</Tag>
                    <p>
                      <span style={{fontWeight: 600}}>
                        Primary Diagnosis Description
                      </span>{' '}
                      <span style={{color: 'rgb(156 163 175 /1)'}}>
                        {' '}
                        Type 2 Diabetes mellitus
                      </span>
                    </p>
                  </div>
                </Card>
              </Col>

              <Col className="gutter-row" span={12}>
                <Row>
                  <Col className="gutter-row" span={12}>
                    <Card
                      title="Cohort Details"
                      style={{width: '100%', height: '100%'}}
                    >
                      <ItemDescription label="Cohort Size">
                        147 members
                      </ItemDescription>
                      <ItemDescription label="Care Journey">
                        {opportunity.data
                          ? (opportunity.data as any).careJourney
                          : 'None'}
                      </ItemDescription>
                    </Card>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Card
                      title="Timeframe"
                      style={{width: '100%', height: '100%'}}
                    >
                      <ItemDescription label="Remaining Time">
                        {opportunity.data &&
                        (opportunity.data as any).startDate &&
                        (opportunity.data as any).endDate
                          ? new Date(
                              new Date(
                                (opportunity.data as any).endDate
                              ).getTime() -
                                new Date(
                                  (opportunity.data as any).startDate
                                ).getTime()
                            ).toDateString()
                          : '-'}
                      </ItemDescription>
                      <ItemDescription label="Start Date">
                        {opportunity.data && (opportunity.data as any).startDate
                          ? new Date(
                              (opportunity.data as any).startDate
                            ).toDateString()
                          : '-'}
                      </ItemDescription>
                      <ItemDescription label="End Date">
                        25 Sep, 2022
                      </ItemDescription>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                width: '100%',
              }}
            >
              <Col className="gutter-row" span={12}>
                <Card
                  style={{
                    width: '100%',
                  }}
                  title="Clinical Performance"
                >
                  <BarChartGraph
                    width="700"
                    height="450"
                    color="#7F56D9"
                    data={clinicalPerformanceMockData}
                    id="overTimePerfomance"
                  />
                </Card>
              </Col>
              <Col className="gutter-row" span={12}>
                <Card
                  style={{
                    width: '100%',
                  }}
                  title="Performance Over Time"
                >
                  <AreaCartesianGridChart
                    pOverTimeMock={pOverTimeMock}
                    renderLegend={renderLegend}
                  />
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </View>
  );
};

export default OpportunityDetails;
function handleRowClick(name: string) {
  throw new Error('Function not implemented.');
}
