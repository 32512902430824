import {HStack, Text, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
// import {useIntl} from 'react-intl';
import {Platform, Pressable, View} from 'react-native';
import {Colors} from '../../../../styles';
import {CONVERSATION_TAB_FILTER_LIST} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {DisplayText} from '../../DisplayText/DisplayText';
import {getColors} from './HelperUtils';
import {IStatusFilterList} from './interfaces';

const MessageTypeFilterList = (props: IStatusFilterList) => {
  // const intl = useIntl();
  const {onStatusChange} = props;
  const [dataList, setDataList] = useState([] as any);
  const [selected, setSelected] = useState('All');
  useEffect(() => {
    setDataList(CONVERSATION_TAB_FILTER_LIST);
  }, []);
  return (
    <View>
      <DisplayText
        size={'xsMedium'}
        extraStyles={{fontSize: 10, color: Colors.Custom.Gray500}}
        textLocalId="messageType"
      />
      <VStack style={{padding: 0, borderRadius: 16, width: 100}}>
        {(dataList || []).map((statusObj: any, index: any) => {
          return (
            <Pressable
              key={index}
              onPress={() => {
                setSelected(statusObj.code);
                onStatusChange(statusObj);
              }}
            >
              <HStack
                alignItems={'center'}
                style={{marginVertical: 8}}
                flex={1}
              >
                <View
                  style={{
                    width: 6,
                    height: 6,
                    borderRadius: 50,
                    backgroundColor: getColors(statusObj),
                    marginRight: 10,
                  }}
                ></View>
                {Platform.OS === 'web' ? (
                  <DisplayText
                    size={'xsMedium'}
                    extraStyles={{}}
                    textLocalId={statusObj.code}
                  />
                ) : (
                  <Text>
                    {/* {intl.formatMessage({id: statusObj.code})} */}
                    <>!OS</>
                  </Text>
                )}
              </HStack>
            </Pressable>
          );
        })}
      </VStack>
    </View>
  );
};

export default MessageTypeFilterList;
