import React, {FC, MouseEventHandler, PropsWithChildren} from 'react';
import {Colors} from '../../../../../../styles';

interface ITableTabProps {
  text: string;
  value: string;
  isActive: boolean;
  handleSelect?: any;
}

const tableTabStyles = {
  activeTab: {
    marginRight: '2rem',
    fontWeight: 'semibold',
    color: '#6941C6',
    backgroundColor: '#F9F5FF',
    cursor: 'pointer',
  },
  defaultTab: {
    color: '#667085',
    cursor: 'pointer',
  },
};

const TableTab: FC<ITableTabProps> = ({
  isActive,
  text,
  value,
  handleSelect,
}) => {
  return (
    <div
      {...(handleSelect ? {onClick: (e) => handleSelect(e, value)} : {})}
      style={{
        ...(isActive ? tableTabStyles.activeTab : tableTabStyles.defaultTab),
      }}
      className={
        isActive
          ? `mr-4 font-semibold text-indigo-700 bg-indigo-200/20 px-3 py-2 rounded cursor-pointer`
          : `font-semibold mr-4 text-[#667085] bg-transparent cursor-pointer px-3 py-1`
      }
      data-value={value}
    >
      {text || 'Pending'}
    </div>
  );
};

export default TableTab;
