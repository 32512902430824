import { MAIN_MENU_CODES } from "../components/SideMenuBar/SideBarConst"
import { EventBus } from "./EventBus"

export function isMacintosh() {
    return navigator.platform.indexOf('Mac') > -1
}

export function isWindows() {
    return navigator.platform.indexOf('Win') > -1
}

export const SHORT_CODE_KEYS_MAC = {
    task: 'Control + Shift + T',
    appointment: 'Control + Shift + S',
    member: 'Control + Shift + M',
    automation: 'Control + Shift + A',
    campaign: 'Control + Shift + C',
}

export const SHORT_CODE_KEYS_WINDOWS = {
    task: 'Alt + Shift + T',
    appointment: 'Alt + Shift + S',
    member: 'Alt + Shift + M',
    automation: 'Alt + Shift + A',
    campaign: 'Alt + Shift + C',
}

export const shortsCutKeysAction = () => {
    if (isMacintosh()) {
        return [
            {
                type: 'appointment',
                shortKeys: ['Control', 'Shift', 'S'],
            },
            {
                type: 'task',
                shortKeys: ['Control', 'Shift', 'T'],
            },
            {
                type: 'member',
                shortKeys: ['Control', 'Shift', 'M'],
            },
            {
                type: 'campaign',
                shortKeys: ['Control', 'Shift', 'C'],
            },
            {
                type: 'automation',
                shortKeys: ['Control', 'Shift', 'A'],
            },
        ]
    } else {
        return [
            {
                type: 'appointment',
                shortKeys: ['Alternate', 'Shift', 'S'],
            },
            {
                type: 'task',
                shortKeys: ['Alternate', 'Shift', 'T'],
            },
            {
                type: 'member',
                shortKeys: ['Alternate', 'Shift', 'M'],
            },
            {
                type: 'campaign',
                shortKeys: ['Alternate', 'Shift', 'C'],
            },
            {
                type: 'automation',
                shortKeys: ['Alternate', 'Shift', 'A'],
            },
        ]
    }
}

const callEventHandler = (type: string) => {
    let ref:any;
    let url;
    if (!window?.location?.href.includes('/admin')) {
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent('SHORTKEYS', {
            type: type,
        });
    } else {
        if( ['automation'].indexOf(type) != -1){
            const eventBus = EventBus.getEventBusInstance();
            eventBus.broadcastEvent('SHORTKEYS', {
                type: type,
            });
        }else{
            const homeUrl = window?.location?.origin + '/#/home';
            url = homeUrl ;
            ref = window.open('', MAIN_MENU_CODES.HOME);
    
            if(ref?.location && ref?.location == 'about:blank'){
                ref.location.href = url;
                ref.focus();
                ref.postMessage(type, window?.location?.origin + '/#/home');
              } else {
                ref.focus();
                ref.postMessage(type, window?.location?.origin + '/#/home');
            }
        }
    }
}

export const setShortKeysCombination = (event: any) => {
    const keyName = event.key;
    if ((event.ctrlKey || event.altKey) && event.shiftKey && keyName === 'T') {
        callEventHandler('task')
    } else if ((event.ctrlKey || event.altKey) && event.shiftKey && keyName === 'S') {
        callEventHandler('appointment')
    } else if ((event.ctrlKey || event.altKey) && event.shiftKey && keyName === 'C') {
        callEventHandler('campaign')
    } else if ((event.ctrlKey || event.altKey) && event.shiftKey && keyName === 'M') {
        callEventHandler('member')
    } else if ((event.ctrlKey || event.altKey) && event.shiftKey && keyName === 'A') {
        callEventHandler('automation')
    }else if (event.shiftKey && keyName === 'Enter') {
        // const eventBus = EventBus.getEventBusInstance();
        // eventBus.broadcastEvent('SHORTKEYS', {
        //     type: 'AddTask',
        // });
    }
}

export const openEventsScreen = (type: any) => {
    if (type === 'task') {
        callEventHandler('task')
    } else if (type === 'appointment') {
        callEventHandler('appointment')
    } else if (type === 'campaign') {
        callEventHandler('campaign')
    } else if (type === 'member') {
        callEventHandler('member')
    } else if (type === 'automation') {
        callEventHandler('automation')
    }else {
        
    }
}