import {Skeleton, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import CommonService from '../../../../services/CommonService/CommonService';
import PageBodyContainer from '../../../common/PageBodyContainer/PageBodyContainer';
import {AccessForm} from './AccessForm';
import {ElationViewCodes} from './ElationConfigConsts';
import {PracticeDetails} from './PracticeDetails';
import {TokenDetails} from './TokenDetails';

const ElationConfig = () => {
  const [ElationConfigState, setElationConfigState] = useState({
    viewCode: ElationViewCodes.ACCESS_FORM,
    loading: true
  });

  const commonService = CommonService.getCommonServiceInstance();
  const integrationService = commonService.integrationService;

  const {height} = Dimensions.get('window');

  const fetchEhrDetails = async () => {
    setElationConfigState((prev) => {
      return {
        ...prev,
        loading: true
      };
    });
    try {
      const url = '/config/ehr-info';
      integrationService
        .get(url, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((resp: {data: any}) => {
          if (!resp?.data?.accountId) {
            setElationConfigState((prev) => {
              return {
                ...prev,
                loading: false,
                viewCode: ElationViewCodes.ACCESS_FORM
              };
            });
          } else {
            if (resp.data?.isIntegrated) {
              setElationConfigState((prev) => {
                return {
                  ...prev,
                  loading: false,
                  viewCode: ElationViewCodes.PRACTICE_DETAIL
                };
              });
            } else {
              setElationConfigState((prev) => {
                return {
                  ...prev,
                  loading: false,
                  viewCode: ElationViewCodes.TOKEN_DETAIL
                };
              });
            }
          }
        })
        .catch((e: any) => {

        });
    } catch (error) {

      setElationConfigState((prev) => {
        return {
          ...prev,
          viewCode: ElationViewCodes.ACCESS_FORM,
          loading: false
        };
      });
    }
  };

  const getViewFromSelectedCode = () => {
    return (
      <View height={height - 180}>
        <PageBodyContainer>
          {ElationConfigState.loading ? (
            <Skeleton.Text lines={4} />
          ) : (
            <>
              {ElationConfigState.viewCode === ElationViewCodes.ACCESS_FORM && (
                <AccessForm />
              )}

              {ElationConfigState.viewCode ===
                ElationViewCodes.PRACTICE_DETAIL && <PracticeDetails />}

              {ElationConfigState.viewCode ===
                ElationViewCodes.TOKEN_DETAIL && <TokenDetails />}
            </>
          )}
        </PageBodyContainer>
      </View>
    );
  };

  useEffect(() => {
    fetchEhrDetails();
  }, []);

  return <>{getViewFromSelectedCode()}</>;
};

export default ElationConfig;
