import { Select } from "antd";
import { View } from "native-base";
import { useState } from "react";



const HourOptions: any = [
    // { value: '00', label: '00' },
    // { value: '01', label: '01' },
    // { value: '02', label: '02' },
    // { value: '03', label: '03' },
    // { value: '04', label: '04' },
    // { value: '05', label: '05' },
    // { value: '06', label: '06' },
    // { value: '07', label: '07' },
    // { value: '08', label: '08' },
    // { value: '09', label: '09' },
    // { value: '10', label: '10' },
    // { value: '11', label: '11' },
    // { value: '12', label: '12' },
    // { value: '13', label: '13' },
    // { value: '14', label: '14' },
    // { value: '15', label: '15' },
    // { value: '16', label: '16' },
    // { value: '18', label: '17' },
    ];

    const minuteOptions: any = [];
 const gentrateHours = ()=> {
    for(let i=0;i<24;i++){
        HourOptions.push({ lable: i, value: i})
    }
 } 
    gentrateHours(); 

    const gentrateMinutes = ()=> {
        for(let i=0;i<60;i=i+10){
            minuteOptions.push({ lable: i, value: i})
        }
    } 
    gentrateMinutes();  
export const TimerDropdown = ({ defaultValue, optionType, onChange }: any)=> {
    const [value, setValue] = useState(defaultValue);

    const handleChange = (value: string)=> {
        setValue(value)
        onChange(value);
    }
    return (
        <View>
            <Select
                defaultValue={value}
                style={{ width: 120 }}
                onChange={handleChange}
                options={optionType === 'hour'? HourOptions: minuteOptions}
            />
        </View>
    )
}