import { View, Text } from 'react-native'
import React from 'react'
import TitleSubtitleView from '../../components/common/TitleSubtitleView/TitleSubtitleView'
import { VStack } from 'native-base'
import { JourneyAnalytics } from '../../components/RightSideContainer/Journeys/Analytics'

const JourneyAnalyticsView = () => {
  return (
    <View>
      <View style={{backgroundColor: '#fff'}}>
        <TitleSubtitleView />
      </View>
      <View>
        <VStack>
          <JourneyAnalytics />
        </VStack>
      </View>
    </View>
  );
}

export default JourneyAnalyticsView