import {useMutation} from '@apollo/client';
import {Drawer} from 'antd';
import {Button, HStack, Spinner, Text, View} from 'native-base';
import React, {useState} from 'react';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {LeadQueries} from '../../../services';
import {Colors} from '../../../styles';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {DisplayText} from '../DisplayText/DisplayText';
import ModalActionBtn from '../ModalActionBtn/ModalActionBtn';
import {styles} from './DeleteContactViewStyles';

const DeleteContactView = (props: any) => {
  const [showModal, setShowModal] = useState(true);
  const {contactId} = props;
  const [deleteContact] = useMutation(LeadQueries.DeleteContact);
  const closeModal = () => {
    setShowModal(false);
    props.onDeleteCompleteAction(COMMON_ACTION_CODES.RESET);
  };

  const onDeleteAction = () => {
    const promiseList = [
      deleteContact({
        variables: {
          id: contactId,
        },
      }),
    ];
    Promise.all(promiseList).then(() => {
      closeModal();
    });
  };
  const {width} = Dimensions.get('window');
  const finalWidth = width / 3;
  return (
    <>
      <Drawer
        visible={showModal}
        width={finalWidth}
        onClose={() => closeModal()}
        title={
          <>
            <HStack
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text>
                <DisplayText
                  textType={'Heading'}
                  textLocalId={'confirmDeletion'}
                />
              </Text>
              <Button.Group justifyContent={'flex-end'}>
                <FoldButton
                  nativeProps={{
                    variant: BUTTON_TYPE.SECONDARY,
                    onPress: () => {
                      closeModal();
                    },
                  }}
                  customProps={{
                    btnText: (
                      <DisplayText
                        textLocalId={'cancel'}
                        size={'smBold'}
                        extraStyles={{
                          color: Colors.Custom.mainSecondaryBrown,
                        }}
                      />
                    ),
                    withRightBorder: false,
                  }}
                ></FoldButton>
                <FoldButton
                  nativeProps={{
                    variant: BUTTON_TYPE.SECONDARY,
                    onPress: () => {
                      onDeleteAction();
                    },
                  }}
                  customProps={{
                    btnText: (
                      <DisplayText
                        textLocalId={'delete'}
                        size={'smBold'}
                        extraStyles={{
                          color: Colors.Custom.mainSecondaryBrown,
                        }}
                      />
                    ),
                    withRightBorder: false,
                  }}
                ></FoldButton>
              </Button.Group>
            </HStack>
          </>
        }
      >
        {false ? <Spinner size="lg" style={styles.spinnerStyle} /> : <></>}
        <View
          justifyContent={'flex-start'}
          alignItems={'center'}
          flex={1}
          flexDirection={'row'}
        >
          <Text fontSize={16}>
            <DisplayText textLocalId="deleteConfirmation" />
          </Text>
        </View>
        {/* <Button.Group space={2} justifyContent={'flex-end'} width={'100%'}>
          <ModalActionBtn
            btnText="cancel"
            btnStype={BUTTON_TYPE.SECONDARY}
            onClickAction={() => {
              closeModal();
            }}
            customStyle={{marginRight: 20}}
          />
          <ModalActionBtn
            btnText="delete"
            btnStype={BUTTON_TYPE.PRIMARY}
            onClickAction={() => {
              onDeleteAction();
            }}
          />
        </Button.Group> */}
      </Drawer>
    </>
  );
};

export default DeleteContactView;
