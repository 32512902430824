import {Modal} from 'antd';
import React, {FC, ReactElement, ReactNode} from 'react';

interface AssociationModalProps {
  isVisible: boolean;
  title: string | ReactNode | ReactElement;
  onClose: any;
  width?: number | string;
  className?: string;
  onOkClick?: any;
  onCancelClick?: any;
  okText?: string;
}

const OpportunityAssociationModal: FC<AssociationModalProps> = ({
  title,
  isVisible,
  children,
  onClose,
  width = 916,
  className,
  onOkClick,
  okText,
}) => {
  return (
    <div className="opportunityCreateModal">
      <Modal
        className={className}
        width={width}
        title={title}
        visible={isVisible}
        onOk={onOkClick}
        onCancel={onClose}
        okText={okText || 'Next'}
      >
        {children}
      </Modal>
    </div>
  );
};

export default OpportunityAssociationModal;
