import * as React from 'react';
import {IConversationData} from '../components/RightSideContainer/TeamInbox/Conversations/interfaces';
export interface IConversationFilterContextData {
  conversationFilter: any;
  selectedTabCode: string;
  searchString: string;
  readConversationData: IConversationData;
}
export const defaultConversationFilter: IConversationFilterContextData = {
  conversationFilter: {} as any,
  selectedTabCode: '',
  searchString: '',
  readConversationData: {} as any,
};

export const ConversationFilterContext = React.createContext(
  defaultConversationFilter
);
