import {useLazyQuery} from '@apollo/client';
import {Table} from 'antd';
import {Spinner, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {MLOV_CATEGORY} from '../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import CostTemplateQueries from '../../../../../services/CostSavingTemplates/CostTemplateQueries';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {
  getMlovIdFromCode,
  getMlovListByCategory,
} from '../../../../../utils/mlovUtils';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import {TableWrapper} from '../../../../common/TableWrapper';
import {getCostSavingTemplateTableColumns} from './CostTemplatesTableColumn';
import NoTemplateView from './helperFiles/NoTemplateView';
import {
  getCostSavingFormattedData,
  getCostSavingTemplateQuery,
} from './helperFiles/utils';
import {
  ICostSavingTemplateResponse,
  ICostSavingTemplateTable,
  ICostSavingTemplateTableProps,
} from './interfaces/savingTemplatesInterface';

const CostSavingTemplateTable = (props: ICostSavingTemplateTableProps) => {
  const {costType, savingTypeId, isFilterVisible, saveActionPerformed, searchText} = props;
  const accountUuid = getAccountUUID();
  const [stateData, setStateData] = useState({
    currentPage: 1,
    loading: false,
    dataList: [] as ICostSavingTemplateTable[],
    total: 0,
    pageSize: 10,
    offset: 0,
  });
  const costTypeList = getMlovListByCategory(MLOV_CATEGORY.COST_CATEGORY);
  const costTypeId = getMlovIdFromCode(costTypeList, costType);
  const {height} = Dimensions.get('window');
  const finalHeight = height - (isFilterVisible ? 346 : 260);
  const [GET_COST_TEMPLATES] = useLazyQuery(
    CostTemplateQueries.GET_COST_TEMPLATES,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const handlePageChange = (page: number, currentPageSize: number) => {
    const offset = currentPageSize * (page - 1);
    setStateData((prev) => {
      return {
        ...prev,
        currentPage: page,
        pageSize: currentPageSize,
        offset: offset,
      };
    });
  };
  const getCostTemplates = async (offSet?: number) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const response: ICostSavingTemplateResponse = await GET_COST_TEMPLATES({
      variables: {
        where: getCostSavingTemplateQuery({
          accountUuid,
          savingTypeId,
          costTypeId,
          searchText
        }),
        limit: stateData.pageSize,
        offset: offSet == 0 ? offSet : stateData.offset,
      },
    });
    if (response.data?.costSavingTemplates?.length) {
      const costSavingData = getCostSavingFormattedData(
        response.data?.costSavingTemplates,
        costTypeList
      );
      const totalAgr =
        response?.data?.costSavingTemplatesAggregate?.aggregate?.count;
      setStateData((prev) => {
        return {
          ...prev,
          dataList: costSavingData,
          loading: false,
          total: totalAgr,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          dataList: [],
          loading: false,
          total: 0,
        };
      });
    }
  };
  useEffect(() => {
    getCostTemplates();
  }, [stateData.currentPage, stateData.pageSize]);
  useEffect(() => {
    if (stateData.currentPage !== 1) {
      setStateData((prev) => {
        return {
          ...prev,
          currentPage: 1,
          offset: 0,
        };
      });
    } else {
      getCostTemplates(0);
    }
  }, [costType,searchText]);
  useEffect(() => {
    if (saveActionPerformed) {
      getCostTemplates();
    }
  }, [saveActionPerformed]);
  return (
    <PageBodyContainer>
      <TableWrapper
        wrapperStyle={{alignItems: 'center'}}
        minHeight={isFilterVisible ? 220 : 0}
        pagination={{
          current: stateData.currentPage,
          pageSize: 10,
          total: stateData.total,
          onChange: (currentPage, currentPageSize) => {
            handlePageChange(currentPage, currentPageSize);
          },
        }}
      >
        <Table
          scroll={{x: 700, y: finalHeight}}
          columns={getCostSavingTemplateTableColumns(props.onActionPerformed)}
          rowKey={(row: any) => `${row?.id}_saving_template`}
          loading={{
            spinning: stateData.loading,
            indicator: (
              <VStack justifyContent="center" height={'100%'}>
                <Spinner size={'lg'} />
              </VStack>
            ),
          }}
          dataSource={stateData.dataList}
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          onRow={(data) => {
            return {
              onClick: () => {
                props.onActionPerformed?.(
                  COMMON_ACTION_CODES.DETAIL_VIEW,
                  data
                );
              },
            };
          }}
          pagination={false}
          locale={{
            emptyText: !stateData.loading && <NoTemplateView />,
          }}
        />
      </TableWrapper>
    </PageBodyContainer>
  );
};

export default CostSavingTemplateTable;
