import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../../../../styles/Colors';
export const styles = StyleSheet.create({
  mainContainer: {
    // shadowColor: 'black',
    // shadowOffset: {width: 2, height: 2},
    // shadowOpacity: 0.2,
    // shadowRadius: 8,
    elevation: 10,
    borderColor: Colors.Custom.BorderColor,
    // borderWidth: 1,
    overflow: 'hidden',
    marginRight: 8,
    // marginBottom: 24,
    // marginTop: 8,
  },
});
