import {Dimensions} from 'react-native';
import {getDateStrFromFormat} from '../../../../../utils/DateUtils';
import {HStack, VStack, View, Text, Pressable} from 'native-base';
import {DATE_FORMATS} from '../../../../../constants';
import {Colors} from '../../../../../styles/Colors';
import Feather from 'react-native-vector-icons/Feather';
import MemberSvg from '../../../../common/Svg/PopulationGroupAudit/MemberSvg';
import FormSvg from '../../../../common/Svg/PopulationGroupAudit/FormSvg';
import CampaignSvg from '../../../../common/Svg/PopulationGroupAudit/CampaignSvg';
import AutomationSvg from '../../../../common/Svg/PopulationGroupAudit/AutomationSvg';
import {Skeleton} from 'antd';
import React, {useState} from 'react';
import GroupDetailMemberListDrawer from './GroupDetailMemberListDrawer';
import FormResponseAudit from '../../../../CommonComponents/FormResponseAudit';
import {ActionTypeNames, IAuditLogData} from './Hooks/interface';
import {useIntl} from 'react-intl';
import RuleCreatedSvg from '../../../../common/Svg/PopulationGroupAudit/RuleCreatedSvg';

const AuditTimelineItemView = (props: {
  subTitleTextLoading: boolean;
  auditLogData: IAuditLogData;
  actionPerformerName: string;
  resourceName?: string;
}) => {
  const {auditLogData} = props;
  const {width} = Dimensions.get('window');

  const dateStr = auditLogData?.createdOn
    ? getDateStrFromFormat(
        auditLogData?.createdOn,
        DATE_FORMATS.CRM_ACTIVITY_DATE_FORMAT
      )
    : '';

  
  const intl = useIntl();

  const getText = (action: ActionTypeNames): JSX.Element => {
    switch (action) {
      case ActionTypeNames.RULE_UPDATED:
        return (
          <Text>
            <Text style={{fontWeight: '700'}}>
              {auditLogData?.membersAddedCount} members added
            </Text>{' '}
            and{' '}
            <Text style={{fontWeight: '700'}}>
              {auditLogData?.membersDroppedCount} members were dropped off
            </Text>
          </Text>
        );
      case ActionTypeNames.FORM:
        return (
          <Text>
            <Text style={{fontWeight: '700'}}>{props.actionPerformerName}</Text>{' '}
            sent a form to the group
          </Text>
        );
      case ActionTypeNames.JOURNEY:
        return (
          <Text>
            <Text style={{fontWeight: '700'}}>{props.actionPerformerName}</Text>{' '}
            assigned a journey
          </Text>
        );
      case ActionTypeNames.RULE_CREATION:
        return (
          <Text>
            <Text style={{fontWeight: '700'}}>{props.actionPerformerName}</Text>{' '}
            created the group
          </Text>
        );
      default:
        return <></>;
    }
  };

  const getSubtitleText = (action: ActionTypeNames) => {
    let actionText = '';
    switch (action) {
      case ActionTypeNames.FORM:
      case ActionTypeNames.JOURNEY:
        actionText = props?.resourceName || '';
        break;
    }
    return actionText;
  };

  const getIconName = (action: ActionTypeNames) => {
    switch (action) {
      case ActionTypeNames.RULE_UPDATED:
        return <MemberSvg />;
      case ActionTypeNames.FORM:
        return <FormSvg />;
      case ActionTypeNames.CAMPAIGN:
        return <CampaignSvg />;
      case ActionTypeNames.JOURNEY:
        return <AutomationSvg />;
      case ActionTypeNames.RULE_CREATION:
        return <RuleCreatedSvg />;
      case ActionTypeNames.AUTOMATION:
      default:
        return <AutomationSvg />;
    }
  };

  return (
    <View key={auditLogData.auditId}>
      <View>
        <View key={auditLogData?.auditId}>
          <HStack
            style={{minWidth: width * 0.25}}
            flex={1}
            alignItems={'center'}
          >
            <View marginLeft={5} marginRight={3}>
              <View
                style={{
                  width: 2,
                  height: 50,
                  backgroundColor: Colors.Custom.Gray100,
                  alignSelf: 'center',
                  transform: [{rotate: '180deg'}],
                }}
              />
              <View
                style={{
                  borderRadius: 88,
                  padding: 10,
                  borderWidth: 1,
                  borderColor: Colors.Custom.Gray200,
                  alignContent: 'center',
                }}
                backgroundColor={'none'}
              >
                {getIconName(auditLogData?.parentResourceTypeCode)}
              </View>
              <View
                style={{
                  width: 2,
                  height: 65,
                  backgroundColor: Colors.Custom.Gray100,
                  alignSelf: 'center',
                  transform: [{rotate: '180deg'}],
                }}
              />
            </View>
            <VStack
              flex={1}
              style={{
                marginTop: 15,
              }}
              borderRadius={10}
              backgroundColor={'none'}
              marginRight={0}
              py={2}
              paddingLeft={2}
            >
              <HStack alignContent={'flex-start'}>
                <Text
                  color={Colors.Custom.Gray400}
                  style={{
                    lineHeight: 20,
                    fontWeight: '600',
                    letterSpacing: 1,
                    fontFamily: 'Manrope',
                  }}
                  fontSize={12}
                >
                  {auditLogData?.parentResourceTypeCode ===
                  ActionTypeNames.RULE_UPDATED ? (
                    <Text>{dateStr} • LAST 24 HOURS </Text>
                  ) : (
                    <Text>{dateStr}</Text>
                  )}
                </Text>
              </HStack>
              <VStack display={'flex'} flexWrap={'wrap'}>
                <HStack
                  alignItems={'flex-start'}
                  width={{
                    base: '100%',
                    sm: '100%',
                    md: '57.5%',
                    lg: '57.5%',
                    xl: '57.5%',
                  }}
                >
                  <View
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={500}
                  >
                    <Text
                      style={{
                        color: Colors.Custom.Gray900,
                        fontWeight: '500',
                        fontFamily: 'Manrope',
                      }}
                      fontSize={16}
                      lineHeight={24}
                      paddingRight={2}
                      numberOfLines={2}
                      textOverflow={'ellipsis'}
                    >
                      {getText(auditLogData?.parentResourceTypeCode)}
                    </Text>
                    {auditLogData?.parentResourceTypeCode ==
                      ActionTypeNames.FORM && (
                      <FormResponseAudit
                        successCount={auditLogData?.successCount || 0}
                        submittedCount={auditLogData?.submittedCount || 0}
                        draftCount={auditLogData?.draftCount || 0}
                        pendingCount={auditLogData?.pendingCount || 0}
                      />
                    )}
                    {auditLogData?.parentResourceTypeCode ===
                      ActionTypeNames.JOURNEY && (
                      <View
                        flexDirection="row"
                        style={{
                          backgroundColor: Colors.Custom.Gray100,
                          borderRadius: 20,
                          paddingLeft: 12,
                          paddingRight: 19,
                          alignItems: 'center',
                          width: 70,
                          paddingVertical: 6,
                          justifyContent: 'center',
                        }}
                      >
                        <RuleCreatedSvg />
                        <Text
                          overflow={'hidden'}
                          textOverflow={'ellipsis'}
                          whiteSpace={'nowrap'}
                          style={{
                            marginRight: 3,
                            paddingLeft: 5,
                          }}
                          size={'smMedium'}
                          color={Colors.Custom.Gray500}
                        >
                          {auditLogData?.successCount}
                        </Text>
                      </View>
                    )}
                  </View>
                </HStack>
                {props?.subTitleTextLoading ? (
                  <Skeleton.Input
                    size="small"
                    style={{height: 16, marginTop: 4}}
                  />
                ) : (
                  <Text
                    color={Colors.Custom.Gray400}
                    style={{
                      lineHeight: 20,
                      fontWeight: '600',
                      letterSpacing: 1,
                      fontFamily: 'Manrope',
                    }}
                    fontSize={13}
                  >
                    <Text>
                      {getSubtitleText(auditLogData?.parentResourceTypeCode)}
                    </Text>
                  </Text>
                )}
              </VStack>
            </VStack>
          </HStack>
        </View>
      </View>
    </View>
  );
};

export default AuditTimelineItemView;
