import {IPracticeDetail, PracticeDetailApiResponse} from './interfaces';

export const validateAccessForm = (args: {accessCode: string}) => {
  const {accessCode} = args;
  const errors: any = {};
  let valid = true;

  errors.accessCode = !accessCode ? 'Please Enter Access Code' : '';

  if (errors.accessCode) {
    valid = false;
  }

  return {
    valid,
    errors,
  };
};

export const getFormattedPracticeDetails = (
  data: PracticeDetailApiResponse
): IPracticeDetail => {
  const result: IPracticeDetail = {
    name: data.name || '',
    url: data.url || '',
    city: data.address_city || '',
    country: data.address_country || '',
    email: data.email || '',
    integrationRecordId: data.integration_record_id || '',
    line1: data.address_line1 || '',
    line2: data.address_line2 || '',
    logoUrl: data.logo_url || '',
    phone: data.phones || '',
    state: data.address_state || '',
    zipCode: data.address_zip || '',
  };
  return result;
};
