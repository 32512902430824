const listData = [
  {
    id: 1,
    risk: 'Low',
    members: 551,
    percent: '40%',
    color: '#82ca9d',
    day: 12,
    contactIds: [
      'a061c884-d498-4bb9-96b1-17b9ef3cd214',
      '3f06f056-71cd-4f32-8e16-3dd22f0f6327',
      'e9ec0d59-070a-47e9-9a43-ab621ec7f400',
      'eec1873b-53c8-4afd-9fd5-ad93510518b9',
      '88fb960b-f704-4fa8-8368-b51e1dee7bfb',
      'd14c8cf1-d543-4d5b-ba0a-d76556bb1283',
      '0ffb29f1-3766-465a-8041-dd7b83b9aff0',
      '161ba353-874a-4753-a15c-257594688bfd',
      'fb4e4ee6-68ea-4e90-abe4-c0865d1919de',
      'd7a3e95e-512e-4d60-890d-813708bf2db4',
      '82a2854a-198b-40cd-897b-a7eb5df02aee',
      'c810a93b-4bf3-4c29-9a60-b591ef7bea5e',
      '5b839ca5-c040-4813-85ee-3782ead01fe1',
      '56d106c7-fa05-4ea6-849c-14ae2039f162',
      '0f932134-da66-4f1e-93d9-e937132c9634',
      '167bb707-5081-4f30-bd38-f72c4fdce9de',
      '89c3e535-ffb9-41a2-b2f1-e3c4a42891fa',
      'd1cab8c2-6575-4dcb-9680-0bbba4bda287',
      'f82ea1ca-0fee-4cf9-b5f5-8228ee229a5e',
      '3d8dcb48-42f1-4550-a1ab-78223474f667',
    ],
    data: [
      {
        name: 'Page A',
        uv: 35,
      },
      {
        name: 'Page B',
        uv: 30,
      },
      {
        name: 'Page C',
        uv: 40,
      },
    ],
  },

  {
    id: 2,
    risk: 'Moderate',
    members: 551,
    percent: '40%',
    color: '#82ca9d',
    day: 25,
    contactIds: [
      'a061c884-d498-4bb9-96b1-17b9ef3cd214',
      '3f06f056-71cd-4f32-8e16-3dd22f0f6327',
      'e9ec0d59-070a-47e9-9a43-ab621ec7f400',
      'eec1873b-53c8-4afd-9fd5-ad93510518b9',
      '88fb960b-f704-4fa8-8368-b51e1dee7bfb',
      'd14c8cf1-d543-4d5b-ba0a-d76556bb1283',
      '0ffb29f1-3766-465a-8041-dd7b83b9aff0',
      '161ba353-874a-4753-a15c-257594688bfd',
      'fb4e4ee6-68ea-4e90-abe4-c0865d1919de',
      'd7a3e95e-512e-4d60-890d-813708bf2db4',
      '82a2854a-198b-40cd-897b-a7eb5df02aee',
      'c810a93b-4bf3-4c29-9a60-b591ef7bea5e',
      '5b839ca5-c040-4813-85ee-3782ead01fe1',
      '56d106c7-fa05-4ea6-849c-14ae2039f162',
      '0f932134-da66-4f1e-93d9-e937132c9634',
      '167bb707-5081-4f30-bd38-f72c4fdce9de',
      '89c3e535-ffb9-41a2-b2f1-e3c4a42891fa',
      'd1cab8c2-6575-4dcb-9680-0bbba4bda287',
      'f82ea1ca-0fee-4cf9-b5f5-8228ee229a5e',
      '3d8dcb48-42f1-4550-a1ab-78223474f667',
    ],
    data: [
      {
        name: 'Page A',
        uv: 40,
      },
      {
        name: 'Page B',
        uv: 40,
      },
      {
        name: 'Page C',
        uv: 50,
      },
    ],
  },
  {
    id: 3,
    risk: 'High',
    members: 275,
    percent: '20%',
    color: '#F43F5E',
    day: 54,
    contactIds: [
      'a061c884-d498-4bb9-96b1-17b9ef3cd214',
      '3f06f056-71cd-4f32-8e16-3dd22f0f6327',
      'e9ec0d59-070a-47e9-9a43-ab621ec7f400',
      'eec1873b-53c8-4afd-9fd5-ad93510518b9',
      '88fb960b-f704-4fa8-8368-b51e1dee7bfb',
      'd14c8cf1-d543-4d5b-ba0a-d76556bb1283',
      '0ffb29f1-3766-465a-8041-dd7b83b9aff0',
      '161ba353-874a-4753-a15c-257594688bfd',
      'fb4e4ee6-68ea-4e90-abe4-c0865d1919de',
      'd7a3e95e-512e-4d60-890d-813708bf2db4',
      '82a2854a-198b-40cd-897b-a7eb5df02aee',
      'c810a93b-4bf3-4c29-9a60-b591ef7bea5e',
      '5b839ca5-c040-4813-85ee-3782ead01fe1',
      '56d106c7-fa05-4ea6-849c-14ae2039f162',
      '0f932134-da66-4f1e-93d9-e937132c9634',
      '167bb707-5081-4f30-bd38-f72c4fdce9de',
      '89c3e535-ffb9-41a2-b2f1-e3c4a42891fa',
      'd1cab8c2-6575-4dcb-9680-0bbba4bda287',
      'f82ea1ca-0fee-4cf9-b5f5-8228ee229a5e',
      '3d8dcb48-42f1-4550-a1ab-78223474f667',
    ],
    data: [
      {
        name: 'Page A',
        uv: 15,
      },
      {
        name: 'Page B',
        uv: 30,
      },
      {
        name: 'Page C',
        uv: 20,
      },
    ],
  },
];

export default listData;
