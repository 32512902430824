import React, {FC} from 'react';

interface TabLink {
  value?: string;
  label: string;
  disabled?: boolean;
}

const tableTabStyles = {
  activeTab: {
    marginRight: '2rem',
    fontWeight: 'semibold',
    color: '#6941C6',
    backgroundColor: '#F9F5FF',
    cursor: 'pointer',
    padding: '0.5rem 1rem',
  },
  defaultTab: {
    color: '#667085',
    cursor: 'pointer',
    marginRight: '2rem',
  },
};

const TabLink: FC<TabLink> = ({label, value, disabled}) => {
  return (
    <div
      {...(disabled ? {disabled} : {})}
      {...(value ? {'data-value': value} : {})}
      style={{
        ...(!disabled ? tableTabStyles.activeTab : tableTabStyles.defaultTab),
      }}
    >
      {label}
    </div>
  );
};

export default TabLink;
