export const JOURNEY_TABS = {
  DETAIL_AND_MEMBERS: 'detailsAndMembers',
  JOURNEY_DESIGN: 'journeyDesign',
  CARE_TEAM: 'careTeam',
  PREVIEW: 'preview',
};

export const JOURNEY_PRESCRIBE_TRIGGER_EVENT = 'prescribed';
export const JOURNEY_PURCHASE_TRIGGER_EVENT = 'purchased';
export const JOURNEY_PURCHASE_NODE = 'OnCarePlanPurchase';
export const JOURNEY_PRESCRIBE_NODE = 'OnCarePlanPrescribe';
export const JOURNEY_USER_IS_PRIMARY_NODE = 'CareJourneyUserIsPrimaryMember';
export const JOURNEY_SCHEDULE_VISIT_NODE = 'CareJourneyScheduleVisit';
export const JOURNEY_VISIT_NODE = 'CareJourneyVisit';

export const JOURNEY_TRIGGER_EVENTS = [
  {
    label: 'To be prescribed by care provider',
    value: JOURNEY_PRESCRIBE_TRIGGER_EVENT,
  }
  //,{label: 'To be purchased by member', value: JOURNEY_PURCHASE_TRIGGER_EVENT},
];
