import {Tooltip} from 'antd';
import {Image} from 'native-base';
import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {getSourceNameByWidget} from '../../RightSideContainer/Analytics/EmployerAnalytics/EmployerUtils/WidgetUtils';
import './tooltip.css';
const WidgetsInfoSvg = (props: {
  customColor?: string;
  customDimension?: string;
  isToolTip?: boolean;
  title?: string;
  subTitle?: string;
  resourceCode: string;
  width?: number;
  height?: number;
  info?: string
}) => {
  const {customColor, resourceCode, width, height} = props;
  const svgView = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ width ? width : "24"}
      height={ height ? height : "25"}
      fill="none"
      viewBox="0 0 24 25"
    >
      <g>
        <path
          stroke="#98A2B3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 16.224v-4m0-4h.01m9.99 4c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10z"
        ></path>
      </g>
    </svg>
  )
  return (
    <>
      {isWeb() ? (
        <>
          {props.isToolTip ? (
            <Tooltip
              overlayClassName='ER-tooltip'
              placement={'bottomLeft'}
              overlayInnerStyle={{
                backgroundColor: '#fff',
                borderRadius: 16,
                width: '360px',
              }}
              overlayStyle={{backgroundColor: '#fff', width: '360px'}}
              overlay={
                <>
                <pre
                    className="text-desc"
                    style={{background: 'white', color: '#000', width: '100%', display: props.info ? 'block' : 'none' }}
                  >
                    {props.info || ''}
                </pre>
                <div style={{display: props.info ? 'none' : 'block' }}>
                  <div
                  className='border-bottom'
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div className="tooltip-title-custom">SOURCE</div>
                    <div className="tooltip-title-custom" style={{color: '#000'}}>{getSourceNameByWidget(resourceCode)}</div>
                  </div>
                  <div
                    className="text-desc"
                    style={{background: 'white', color: '#000'}}
                  >
                    {props.subTitle || ''}
                  </div>
                  <div
                  className='border-bottom'
                  >
                  <div className="tooltip-title-custom">Rule</div>
                  </div>
                  <pre
                    className="text-desc"
                    style={{background: 'white', color: '#000', width: '100%', }}
                  >
                    {props.title || ''}
                    </pre>
                </div>
                </>
              }
              style={{background: 'white', borderColor: '#fff'}}
            >
              {svgView}
            </Tooltip>
          ) : (
            svgView
          )}
        </>
      ) : (
        <Image
          width={'6'}
          height={'6'}
          source={require('../../../assets/images/MessageActionImages/info.png')}
          alt="aa"
        />
      )}
    </>
  );
};

export default WidgetsInfoSvg;
