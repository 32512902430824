import { StyleSheet } from "react-native";

export const campaignHistoryStyles = StyleSheet.create({
  leftMargin: {
    marginLeft: 32
  },
  stackStyles: {
    alignItems: 'center',
    alignContent: 'center'
  },
  monthIconStyles: {
    borderRadius: 4,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});
