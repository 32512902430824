import {DATE_FORMATS} from '../../../../../../constants';
import { StrCaseConverter } from '../../../../../../utils/CaseConverter';
import {
  getDateStrFromFormat,
  getMomentObj,
} from '../../../../../../utils/DateUtils';
import { getAnonationIndex, getRangeTextFromGraph } from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils';
import { DataType, GraphsTypes, IHighLightValues } from '../HomeMonitoringView/interface';
import {IObservation} from '../Vitals/interfaces';
import {ILabReportGroup, ILabEntry, IGraph} from './interfaces';

export const getReportName = (report: IObservation) => {
  let name = '';
  report?.code?.coding?.map((code) => {
    if (code.display) {
      name = code.display;
    }
  });
  return StrCaseConverter.toTitleCase(name);
};

export const getLoincCode = (report: IObservation) => {
  let loincCode = '';
  report.code?.coding?.map((code) => {
    if (code.code) {
      loincCode = code.code;
    } else if (code.display) {
      loincCode = code.display;
    }
  });
  return loincCode;
};

export const getReportValueAndUnit = (report: IObservation) => {
  let valueUnitText = '';

  if (report?.valueQuantity?.value) {
    valueUnitText = report?.valueQuantity?.value?.toString();
  }

  if (report?.valueQuantity?.unit) {
    valueUnitText = `${valueUnitText} ${report?.valueQuantity?.unit}`;
  }

  return valueUnitText;
};

export const getReportUnit = (report: IObservation) => {
  let unitText = '';

  if (report?.valueQuantity?.unit) {
    unitText = `${report?.valueQuantity?.unit}`;
  }

  return unitText;
};

export const getObservationValue = (observation: IObservation) => {
  return observation?.valueQuantity?.value;
};

export const formatLabReportsData = (labEntries: ILabEntry[]) => {
  const reports = labEntries?.map((report: any) => {
    const observation = {
      ...report?.resource,
      loinc: getLoincCode(report?.resource),
    };
    return observation;
  });

  const reducedLabReports = reports.reduce((result, item) => {
    return {
      ...result,
      [item.loinc]: [...(result[item.loinc] || []), item],
    };
  }, {});

  const labReportsGroups: ILabReportGroup[] = [];

  for (const key in reducedLabReports) {
    const sortedItems = reducedLabReports[key].sort(
      (observation1: IObservation, observation2: IObservation) => {
        const recordDate1 =
          observation1.effectiveDateTime || observation1.issued;
        const recordDate2 =
          observation2.effectiveDateTime || observation2.issued;
        if (recordDate1 && recordDate2) {
          const date1 = getMomentObj(recordDate1);
          const date2 = getMomentObj(recordDate2);
          return date2 > date1 ? -1 : 1;
        }
        return 0;
      }
    );

    labReportsGroups.push({
      code: key,
      observations: sortedItems,
    });
  }

  return labReportsGroups;
};

export const getLastAddedGroup = (reportGroup: ILabReportGroup) => {
  const lastReport =
    reportGroup?.observations?.[reportGroup?.observations?.length - 1];
  return lastReport;
};

export const getLabReportsGraphData = (reportGroups: ILabReportGroup[], ccmDate: string) => {
  return reportGroups?.map((reportGroup: ILabReportGroup) => {
    const graphData: IGraph[] = [];
    reportGroup?.observations?.map((observation) => {
      const valueY = getObservationValue(observation);
      if (valueY) {
        if (typeof valueY === 'string' && isNaN(Number(valueY))) {
          return;
        }
        graphData.push({
          y: (+valueY),
          x: getDateStrFromFormat(
            observation?.effectiveDateTime || observation?.issued,
            DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT
          ),
          dateStr: observation?.effectiveDateTime || observation?.issued || ''
        });
      }
    });

    const title = getReportName(reportGroup?.observations?.[0]);

    const uniqueDates = new Set(graphData.map((item) => item.x));

    const dataWithUniqueDates = Array.from(uniqueDates).map((date) => {
      const matchedData = graphData.filter((item) => item.x === date);
      return {
        x: date || '',
        y: matchedData?.[0]?.y || '',
        dateStr: matchedData?.[0]?.dateStr
      };
    });

    const recordLength = graphData?.length || 0;
    let lastRecordedData = undefined;
    let highLightValues: IHighLightValues[] = [];
    if (recordLength > 0) {
      const lastRecord = graphData?.[recordLength - 1];
      lastRecordedData = {
        value: lastRecord?.y || '',
        date: lastRecord?.dateStr || '',
      }
      highLightValues = [
        {
          value: `${lastRecord?.y || ''}`,
          subValue: 'Latest',
          hideUnit: false,
        }
      ];
      const rangeText = getRangeTextFromGraph(graphData || []);
      highLightValues.push({
        value: rangeText,
        subValue: 'Range',
      });
    }

    return {
      ...reportGroup,
      title: title,
      graphData: graphData,
      annontationIndex: graphData.length && ccmDate ? getAnonationIndex(ccmDate, dataWithUniqueDates) : undefined,
      graphType: GraphsTypes.LINE,
      loinc: reportGroup?.code || '',
      highLightValues: highLightValues || [],
      dataAvailability: '',
      dataType: DataType.VITAL,
      vitalYAxisLabel: '',
      displayUnit: getReportUnit(reportGroup?.observations?.[0]),
      lastRecordedData,
    };
  })?.filter((reportGraph)=> reportGraph?.graphData?.length > 0);
};
