export const BULK_CARE_TEAM_CONFLICT_CODE = {
  ADD: 'ADD',
  REPLACE: 'REPLACE',
  NO_CHANGE: 'NO_CHANGE',
  SELECTED_COUNT: 'SELECTED_COUNT'
} as const;

export const SUMMARY_VIEW_VISIBLE_CODES = [
  BULK_CARE_TEAM_CONFLICT_CODE.ADD,
  BULK_CARE_TEAM_CONFLICT_CODE.REPLACE,
  BULK_CARE_TEAM_CONFLICT_CODE.NO_CHANGE,
];
