import {IMediaLibraryData} from './interfaces';
export const getFormattedMediaContent = (mediaData: any) => {
  const formattedMediaContent: IMediaLibraryData[] = [];
  const data = mediaData;

  data.map((item: any) => {
    const ext = item.attributes.file.data[0].attributes.ext;
    let nameWithoutExt = '';
    if (item.attributes.name) {
      nameWithoutExt = item.attributes.name.replace(ext, '');
    }
    let thumbnail = '';
    let imageHeight = undefined;
    let imageWidth = undefined;
    if (item.attributes.file.data[0].attributes.mime.includes('image')) {
      if (item.attributes.file.data[0].attributes.formats) {
        thumbnail =
          item.attributes.file.data[0].attributes.formats?.small?.url ||
          item.attributes.file.data[0].attributes.formats?.thumbnail?.url;
        imageHeight =
          item.attributes.file.data[0].attributes.formats?.small?.height ||
          item.attributes.file.data[0].attributes.formats?.thumbnail?.height;
        imageWidth =
          item.attributes.file.data[0].attributes.formats?.small?.width ||
          item.attributes.file.data[0].attributes.formats?.thumbnail?.width;
      } else {
        thumbnail = item.attributes.file.data[0].attributes.url;
        imageHeight = 200;
        imageWidth = 200;
      }
    }

    formattedMediaContent.push({
      id: item.id,
      lastUpdated: item.attributes.updatedAt,
      mime: item.attributes.file.data[0].attributes.mime,
      size: item.attributes.file.data[0].attributes.size,
      ext: ext,
      name: nameWithoutExt,
      type: item.attributes.type,
      url: item.attributes.file.data[0].attributes.url,
      thumbnail: thumbnail,
      imageHeight: imageHeight,
      imageWidth: imageWidth,
      fileId: item.attributes.file.data[0].id,
    });
  });
  return formattedMediaContent;
};

export const getSingleMediaContent = (data: any): IMediaLibraryData => {
  const ext = data.attributes.file.data[0].attributes.ext;
  const nameWithoutExt = data.attributes.name.replace(ext, '');
  let thumbnail = '';
  let imageHeight = undefined;
  let imageWidth = undefined;

  if (data.attributes.file.data[0].attributes.mime.includes('image')) {
    if (data.attributes.file.data[0].attributes.formats) {
      thumbnail = data.attributes.file.data[0].attributes.formats.small.url;
      imageHeight =
        data.attributes.file.data[0].attributes.formats.small.height;
      imageWidth = data.attributes.file.data[0].attributes.formats.small.width;
    } else {
      thumbnail = data.attributes.file.data[0].attributes.url;
      imageHeight = 200;
      imageWidth = 200;
    }
  }

  const tempObj: IMediaLibraryData = {
    id: data.id,
    lastUpdated: data.attributes.updatedAt,
    mime: data.attributes.file.data[0].attributes.mime,
    size: data.attributes.file.data[0].attributes.size,
    ext: ext,
    name: nameWithoutExt,
    type: data.attributes.type,
    url: data.attributes.file.data[0].attributes.url,
    thumbnail: thumbnail || '',
    imageHeight: imageHeight,
    imageWidth: imageWidth,
  };

  return tempObj;
};

export const getMediaContentObject = (
  mediaContent: IMediaLibraryData | undefined
) => {
  return {
    data: {
      name: mediaContent?.name,
    },
  };
};

export const mediaFilter: {text: string; value: string}[] = [
  {
    text: 'video',
    value: 'video',
  },
  {
    text: 'image',
    value: 'image',
  },
  {
    text: 'audio',
    value: 'audio',
  },
  {
    text: 'pdf',
    value: 'pdf',
  },
];

export const getIconNameByMime = (mime: string): string => {
  const mediaType = getMediaTypeFromMime(mime);
  let iconName = '';
  switch (mediaType) {
    case 'audio':
      iconName = 'sound';
      break;

    case 'image':
      iconName = 'picture';
      break;

    case 'video':
      iconName = 'play';
      break;
    case 'pdf':
      iconName = 'pdffile1';
      break;
  }

  return iconName;
};

export const getMediaTypeFromMime = (mime: string) => {
  let mediaType = mime.split('/')[0];
  if (mediaType.includes('application')) {
    mediaType = mime.split('/')[1];
  }

  return mediaType;
};

export const MEDIA_VIEW_CODES = {
  MEDIA_LIST_VIEW: 'MEDIA_LIST_VIEW',
  MEDIA_ADD_VIEW: 'MEDIA_ADD_VIEW',
  MEDIA_DELETE_VIEW: 'MEDIA_DELETE_VIEW',
  MEDIA_EDIT_VIEW: 'MEDIA_EDIT_VIEW',
  MEDIA_PREVIEW_VIEW: 'MEDIA_PREVIEW_VIEW',
};
export const MEDIA_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  PDF: 'pdf',
  DOCX: 'vnd.openxmlformats-officedocument.wordprocessingml.document',
};
