import {Text, View} from 'native-base';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {PETableActions} from './PETableActions';
import {getTitleStringForListView} from '../PatientEducationUtils';

export const getPETableColumns = (
  actionFn: (actionCode: string, actionData: any) => void,
  defaultSortOrder?: 'ascend' | 'descend'
) => {
  const colums: any = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="title"
        />
      ),
      dataIndex: 'title',
      key: 'title',
      fixed: 'left',
      render: (title: string) => {
        return (
          <View>
            <Text>{getTitleStringForListView(title)}</Text>
          </View>
        );
      },
    },
    // {
    //   title: (
    //     <DisplayText
    //       size={'xsMedium'}
    //       extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
    //       textLocalId="description"
    //     />
    //   ),
    //   dataIndex: 'description',
    //   key: 'description',
    //   render: (description: string) => {
    //     return (
    //       <View>
    //         <Text>
    //           {description.length < 50
    //             ? description
    //             : description.slice(0, 50) + '...'}
    //         </Text>
    //       </View>
    //     );
    //   },
    // },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="lastUpdated"
        />
      ),
      dataIndex: 'lastUpdated',
      defaultSortOrder: defaultSortOrder,
      sorter: (a: any, b: any) =>
        new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime(),
      render: (lastUpdated: string) => {
        const date = new Date(lastUpdated).toLocaleDateString('en-US', {
          year: 'numeric',
          day: 'numeric',
          month: 'long',
        });
        return (
          <View>
            <Text>{date}</Text>
          </View>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="actions"
        />
      ),
      key: 'Actions',
      fixed: 'right',
      render: (record: any) => {
        return <PETableActions record={record} onActionPerformed={actionFn} />;
      },
    },
  ];

  return colums;
};
