import { notification, Table } from "antd";
import { Button, HStack, VStack, Text, View, useToast, Pressable } from "native-base";
import { useIntl } from "react-intl";
import { BUTTON_TYPE, MLOV_CATEGORY } from "../../../../../constants";
import TitleSubtitleView from "../../../../common/TitleSubtitleView/TitleSubtitleView";
import { FoldButton } from "../../../../CommonComponents/FoldButton/FoldButton";
import { COMMON_ACTION_CODES } from "../../../../../constants/ActionConst";
import { useContext, useEffect, useState } from "react";
import { TableWrapper } from "../../../../common/TableWrapper";
import { getSeededRulesList, importSeededRule } from "../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi";
import { getMlovListFromCategory } from "../../../../../utils/mlovUtils";
import { CommonDataContext } from "../../../../../context/CommonDataContext";
import { Dimensions } from "react-native";
import { Colors } from "../../../../../styles/Colors";
import { DisplayText } from "../../../../common/DisplayText/DisplayText";
import { SearchBar } from "../../../../common/SearchBar";
import { debounce } from "lodash";
import { getAccountUUID } from "../../../../../utils/commonUtils";

const ImportRule = (props: any) => {
    const intl = useIntl()
    const [componentState, setComponentState] = useState({
        page: 0,
        pageSize: 10,
        isLoading: true,
        total: 0,
        searchString: '',
        seededRulesData: [],
        importedAGroup: false
    })
    const accountUuid = getAccountUUID();
    const toast = useToast();
    const mlovData = useContext(CommonDataContext);
    const { height } = Dimensions.get('window');
    const personGenderList = getMlovListFromCategory(
        mlovData.MLOV,
        MLOV_CATEGORY.PERSON_GENDER
    );
    const onActionPerformed = async (action: string, data: any) => {
        switch (action) {
            case COMMON_ACTION_CODES.IMPORT:
                setComponentState((prev) => {
                    return {
                        ...prev,
                        isLoading: true,
                        importedAGroup: true
                    };
                });
                await importSeededRule(data?.id).then((response: any) => {
                    if (response?.id) {
                        notification.success({
                            message: 'Rule imported successfully',
                        })
                    }
                }).catch((error: any) => {
                    notification.error({
                        message: 'Something went wrong while importing rule',
                    });
                })
                await getSeededRules(componentState.page, componentState.pageSize, componentState.searchString)
        }
    }

    const getSeededRules = async (page: number, pageSize: number, searchString: string) => {
        setComponentState((prev) => {
            return {
                ...prev,
                isLoading: true,
            };
        });
        try {
            const seededRulesListRes = await getSeededRulesList(
                accountUuid,
                pageSize,
                searchString,
                pageSize * page,
            );
            setComponentState((prev) => {
                return {
                    ...prev,
                    isLoading: false,
                    total: seededRulesListRes?.data?.total,
                    seededRulesData: seededRulesListRes?.data?.data
                };
            });
        } catch (error) {
            setComponentState((prev) => {
                return {
                    ...prev,
                    isLoading: false,
                };
            });
            notification.error({
                message: 'Something went wrong while fecthing list. Please try again',
            });
        }
    }

    useEffect(() => {
        getSeededRules(componentState.page, componentState.pageSize, componentState.searchString)
    }, [])

    const getColumns = (
        actionFn: (actionCode: string, record: any) => void
    ) => {
        return [
            {
                title: <DisplayText
                    size={'xsMedium'}
                    extraStyles={{ color: Colors.primary['700'] || '', marginLeft: 8 }}
                    textLocalId={'ruleName'}
                />,
                key: 'name',
                dataIndex: 'name',
                width: '40%',
                render: (data: any) => {
                    return (
                        <View style={{ flexDirection: 'row', alignItems: 'center', width: '80%', flexWrap: 'wrap' }}>
                            <Text fontWeight={200} fontSize="16px" style={{ color: Colors.Custom.Gray900 }}>
                                {data || '-'}
                            </Text>
                        </View>
                    );
                },
            },
            {
                title: <DisplayText
                    size={'xsMedium'}
                    extraStyles={{ color: Colors.primary['700'] || '', marginLeft: 8 }}
                    textLocalId={'description'}
                />,
                dataIndex: 'description',
                key: 'description',
                width: '40%',
                render: (data: any) => {
                    return (
                        <View style={{ flexWrap: 'wrap' }}>
                            <Text fontWeight={200} fontSize="16px" style={{ flexWrap: 'wrap', color: Colors.Custom.Gray600 }}>
                                {(data !== 'None' && data) ? data : '-'}
                            </Text>
                        </View>
                    );
                },
            },
            {
                title: <DisplayText
                    size={'xsMedium'}
                    extraStyles={{ color: Colors.primary['700'] || '', marginLeft: 8 }}
                    textLocalId={'action'}
                />,
                key: 'action',
                width: '15%',
                render: (data: any) => {
                    return (
                        <View flexDirection="row">
                            <Pressable
                                style={{ paddingHorizontal: 12, paddingVertical: 8 }}
                                onPress={() => actionFn(COMMON_ACTION_CODES.IMPORT, data)}>
                                <Text
                                    fontSize={14}
                                    fontWeight={500}
                                    color={Colors.Custom.PurpleColor}
                                >
                                    {'Import'}
                                </Text>
                            </Pressable>
                        </View>
                    );
                },
            },
        ];
    };


    return (
        <VStack overflow="scroll">
            <HStack alignItems={'center'} justifyContent={'center'} marginBottom={5}>
                <TitleSubtitleView
                    isTitleSubtitleOldView={true}
                    isHideCommonButton={true}
                    containerStyle={{ marginLeft: 0, marginBottom: 5 }}
                    titleLabelId={intl.formatMessage({ id: 'importRule' })}
                    subtitleLabelId=""
                />
                <HStack style={{ justifyContent: 'flex-end', flex: 1, marginRight: 15 }}>
                    <SearchBar
                        searchText={componentState?.searchString || ''}
                        height={10}
                        searchBarInputStyles={props.searchInputStyle}
                        placeholderText={intl.formatMessage({ id: 'searchRule' })}
                        borderRadius={8}
                        onChange={debounce((value: string) => {
                            setComponentState((prev) => {
                                return {
                                    ...prev,
                                    searchString: value,
                                    page: 0,
                                    pageSize: 10
                                }
                            })
                            getSeededRules(0, 10, value)
                        }, 500)}
                    />
                </HStack>
                <Button.Group justifyContent={'flex-end'}>
                    <FoldButton
                        nativeProps={{
                            variant: BUTTON_TYPE.SECONDARY,
                            onPress: () => {
                                props.onModalClose(componentState?.importedAGroup);
                            },
                            style: { marginRight: 12, paddingHorizontal: 16 },
                        }}
                        customProps={{
                            btnText: intl.formatMessage({
                                id: 'close',
                            }),
                            withRightBorder: false,
                        }}
                    ></FoldButton>
                </Button.Group>
            </HStack>
            <VStack>
                <TableWrapper
                    pagination={{
                        current: componentState.page + 1,
                        pageSize: componentState.pageSize,
                        total: componentState.total || 0,
                        onChange: async (currentPage, currentPageSize) => {
                            setComponentState((prev) => {
                                return {
                                    ...prev,
                                    page: currentPage - 1,
                                    pageSize: currentPageSize,
                                };
                            });
                            await getSeededRules(currentPage - 1, currentPageSize, componentState?.searchString)
                        },
                    }}
                >
                    <Table
                        dataSource={componentState?.seededRulesData}
                        columns={getColumns(onActionPerformed)}
                        loading={componentState?.isLoading}
                        scroll={{ x: 500, y: height - 300 }}
                        pagination={false}
                    />
                </TableWrapper>
            </VStack>
        </VStack>
    );
}

export default ImportRule;
