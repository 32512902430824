import {Divider, HStack, Text, View} from 'native-base';
import React from 'react';

const InstructionsView = ({
  title,
  instructionDataArray,
  description,
  summary,
  refVideos,
}: {
  title?: string;
  instructionDataArray?: any[];
  description?: JSX.Element;
  summary?: JSX.Element;
  refVideos?: any[];
}) => {
  return (
    <View >
      <View mx={6}>
        <View my={4}>
          <Text
            mt={2}
            size={'lgBold'}
          >
            {title}
          </Text>
          <Divider my={2}/>
          <View mt={2} ml={3}>
            {summary}
          </View>
          {instructionDataArray?.map((instruction, index) => {
            return (
              <HStack mt={1.5} ml={3} width={'85%'}>
                <Text>{index + 1}.</Text>
                <Text ml={1}>{instruction}</Text>
              </HStack>
            );
          })}
          <View mt={2} ml={3}>
            {description}
          </View>
        </View>
        <Divider my={2}/>
      </View>
    </View>
  );
};
export default InstructionsView;
