import { Skeleton, View } from 'native-base';
import { Dimensions, ViewStyle, StyleSheet } from 'react-native';
import { MLOV_CATEGORY } from '../../../../constants';
import { getMlovListByCategory } from '../../../../utils/mlovUtils';
import RenderWidgets from './RenderWidgets';
import { computeStyles } from './commonUtils';
import { IPageSection, ITaskAnalyticsPage } from './interface';

const TaskAnalyticsPage = (props: ITaskAnalyticsPage) => {
  const {pageData, taskAnalyticState, userData } = props;
  const height = Dimensions.get('window').height * 0.865;
  const sectionTypes = getMlovListByCategory(MLOV_CATEGORY.SectionType);

  const renderSections = (pageSections: IPageSection[]) => {
    return (
      <>
        {pageSections?.map((pageSection: IPageSection, index: number) => {
          if (!pageSection?.isVisible) {
            return null;
          }
          const sectionType = sectionTypes.find(
            (type) => type.id == pageSection.type
          );

          if (sectionType?.code == 'HEADER') {
            return (
              <>
                <View
                  key={pageSection.id}
                  style={
                    computeStyles(pageSection.sectionDisplayConfig) as ViewStyle
                  }
                >
                  {pageSection.childSections
                    ? renderSections(pageSection.childSections)
                    : null}
                </View>
              </>
            );
          } else {
            return (
              <View
                key={pageSection.id}
                overflowX={'scroll'}
                padding={4}
                borderRadius={16}
                backgroundColor={'white'}
                style={[
                  computeStyles(pageSection.sectionDisplayConfig) as ViewStyle,
                  styles.sectionShadow,
                ]}
              >
                <RenderWidgets taskAnalyticState={taskAnalyticState} pageSection={pageSection} userData={userData} />
              </View>
            );
          }
        })}
      </>
    );
  };
  return (
    <>
      {pageData.id ? (
        <>
          <View style={styles.pageContainer}>
            {renderSections(pageData.pageSections as IPageSection[])}
          </View>
        </>
      ) : (
        <View style={styles.pageContainer}>
          <Skeleton.Text lines={15} />
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  pageContainer: {
    height: Dimensions.get('window').height * 0.865,
    overflow: 'scroll',
    backgroundColor: '#E9ECF1',
    padding: 12,
  },
  sectionShadow: {
    shadowColor: '#0000000F',
    shadowOffset: { height: 12, width: 0 },
    shadowRadius: -15,
  },
});

export default TaskAnalyticsPage;
