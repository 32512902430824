import React from 'react'
import {useParams} from 'react-router-dom';
import {ICareManagementView} from './interface';
import {ContactCareProgramView} from '../ContactCareProgram';
import CareManagementView from './CareManagementView';

const CareManagement = (props: ICareManagementView) => {
  const {careProgramId} = useParams();

  if (careProgramId) {
    return <ContactCareProgramView contactCareProgramId={careProgramId} />;
  }

  return <CareManagementView {...props} />;
};

export default CareManagement;