import React, {useEffect, useState} from 'react';
import {DragDropContext} from 'react-beautiful-dnd';
import CommerceCommonService from '../../../../../../../services/CommonService/CommerceCommonService';
import {ITaxonsDisplayList} from '../Interface';
// import {ITaxonsDisplayList} from '../Interface';
import TaxonDndDroppable from '../TaxonDndDropable/TaxonDndDroppable';
import {ITaxonsDndContext} from './interface';

const TaxonsDnDContext = (props: ITaxonsDndContext) => {
  const {taxon} = props;
  const [taxaonData, setTaxonData] = useState({} as ITaxonsDisplayList);
  const handleOnDragEnd = (result: any) => {

    if (result.destination && result.destination.index != result.source.index) {
      const sourceIndex = result.source.index;
      const destination = result.destination.index;
      const movedItem = taxaonData.children[sourceIndex];
      const parentId = movedItem.parentId;
      const items = Array.from(taxaonData.children);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      taxaonData.children = items;

      const body = {
        taxon: {
          parent_id: parseInt(parentId),
          position: destination,
        },
      };
      const commerceCommonService =
        CommerceCommonService.getCommerceCommonServiceInstance();
      const axiosService = commerceCommonService.axiosService;
      axiosService
        .patch(
          `/commerce/api/v2/platform/taxons/${movedItem.id}/reposition`,
          body
        )
        .catch((err: any) => {

        });
      setTaxonData((prev: any) => {
        prev = taxaonData;
        return prev;
      });
    }
  };

  useEffect(() => {
    setTaxonData((prev: any) => {
      prev = taxon;
      return prev;
    });
  }, []);
  return (
    <DragDropContext
      // onDragStart={(e: any) => {
      //

      // }}
      onDragEnd={handleOnDragEnd}
    >
      <TaxonDndDroppable
        key={1}
        taxon={taxaonData}
        index={1}
        isDropDisabled={false}
        onActionPerformed={props.onActionPerformed}
      />
    </DragDropContext>
  );
};
export default TaxonsDnDContext;
