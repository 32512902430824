import {IKeyOperation} from '../../../../../../../Interfaces';
import {
  IAddOrUpdateImmunizationParams,
  IImmunization,
  IImmunizationComponentValue,
} from '../interfaces';
import {ImmunizationFields} from '../../CustomComponentUtils';
import {FHIR_RESOURCE} from '../../../../../../../constants/FhirConstant';
import {
  IFormatHieMetaData,
  IHieFormattedData,
  IHieInvalidField,
} from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import {Immunization} from 'fhir/r4';
import {HieInvalidFields} from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';
import {
  getTextFromCoding,
  getValidCoding,
} from '../../../../../Integration/FhirUtils';
import {PAMISearchType} from '../../../../../../common/PAMISearch/PAMISearch';
import {getEHRCapability} from '../../../../../../../utils/commonUtils';
import {CapabilityResource} from '../../CustomWrapper/CustomComponentHelper';
import {InputType, getFieldCapabilities} from '../../../../../../../utils/capabilityUtils';
import {getMomentObj} from '../../../../../../../utils/DateUtils';

export const getRecordListFormatted = (list: any[]) => {
  const entries = [];
  for (const el of list || []) {
    entries.push(el.resource);
  }
  const formattedData = getRecordDataFormatted(entries);
  return sortItems(formattedData);
};

export const getRecordDataFormatted = (listData: any[]): IImmunization[] => {
  const tempFormattedList: IImmunization[] = [];
  listData.map((item: any) => {
    if (item?.vaccineCode) {
      const status = item.status ? item.status : 'Not Completed';
      const tempObj: IImmunization = {
        id: item?.id,
        occurrenceDateTime:
          item?.occurrence?.dateTime || item.occurrenceDateTime,
        immunization: item?.vaccineCode,
        name: getImmunizationName(item?.vaccineCode),
        statusCode: status,
        practitioner: getImmunizationPractitioner(item),
        primarySource: item.primarySource || false,
        recordedDate: item?.recordedDate,
        doseQuantity: item?.doseQuantity?.value,
        doseQuantityUnit: item?.doseQuantity?.unit,
        uniqueId: item?.id,
        isFreeTextRecord: !item?.vaccineCode?.coding?.length,
        meta: item?.meta
      };
      tempFormattedList.push(tempObj);
    }
  });
  return tempFormattedList;
};

export const getSampleData = (): IImmunizationComponentValue => {
  return {
    immunizations: [
      {
        id: '34234324',
        name: 'Sample Immunization',
        occurrenceDateTime: '2020-12-14',
        immunization: {
          text: 'Sample Immunization',
        },
        statusCode: 'active',
        doseQuantity: '10',
        doseQuantityUnit: 'ml',
        primarySource: true,
        practitioner: {
            resourceType: "Practitioner",
            id: "Test Doctor",
            name:
            [
                {
                    given:
                    [
                        "Test Doctor"
                    ],
                    family: "Doctor"
                }
            ],
        },
        uniqueId: '1234',
        isFreeTextRecord: false
      }
    ]
  }
};

export const sortItems = (records: IImmunization[]) => {
  return records.sort((a, b) => {
    const statusA = a.statusCode || '';
    const statusB = b.statusCode || '';
    if (statusA !== statusB) {
      return statusA.toLowerCase().localeCompare(statusB.toLowerCase());
    }
    return (
      new Date(
        b.recordedDate || b.occurrenceDateTime || new Date()
      ).getTime() -
      new Date(
        a.recordedDate || a.occurrenceDateTime || new Date()
      ).getTime()
    );
  });
};

export const getImmunizationPractitioner = (item: any) => {
  for (const actor of item?.performer ?? []) {
    const actorType = actor?.actor?.reference?.length ? actor.actor.reference.split('/') : undefined
    if (actorType && actorType?.[0] === FHIR_RESOURCE.PRACTITIONER) {
      return {
        id: actorType[1]
      }; 
    } 
  }
  return {
    id: undefined,
  };
};

export const getImmunizationName = (item: any) => {
  let name = item?.text;
  if (!name && item?.coding?.length) {
    name = item.coding[0].display;
  }
  return name;
};

export const isInvalid = (
  field: ImmunizationFields,
  item: IImmunization,
  submitTriggered: boolean,
  fields?: IKeyOperation
) => {
  if (field !== ImmunizationFields.immunization && !item.primarySource && fields?.vaccineCode?.hideFieldsForFreeText) {
    return false;
  }
  switch (field) {
    case ImmunizationFields.occurrenceDateTime:
      if (
        (fields?.occurrenceDateTime?.isRequired || false) &&
        submitTriggered
      ) {
        return !item.occurrenceDateTime;
      }
      return false;
    case ImmunizationFields.performer:
      if ((fields?.performer?.isRequired || false) && submitTriggered) {
        return !item.practitioner?.id;
      }
      return false;

    case ImmunizationFields.doseQuantity:
      if ((fields?.doseQuantity?.isRequired || false) && submitTriggered) {
        return !item.doseQuantity;
      }
      return false;

    case ImmunizationFields.doseQuantityUnit:
      if ((fields?.doseQuantityUnit?.isRequired || false) && submitTriggered) {
        return !item.doseQuantityUnit;
      }
      return false;

    default:
      return false;
  }
};

export const getFHIRTemplate = (params: IAddOrUpdateImmunizationParams) => {
  return {
    resourceType: 'Immunization',
    ...(params.id && {id: params.id}),
    occurrenceDateTime: params.occurrenceDateTime,
    vaccineCode: params.immunization,
    primarySource: params.primarySource || false,
    patient: {
      reference: `Patient/${params.patientId}`,
    },
    ...(params.practitioner?.id && {
      performer: [
        {
          actor: {
            reference: `Practitioner/${params.practitioner.id}`,
          },
        },
      ],
    }),
    ...(params.doseQuantity && {
      doseQuantity: {
        value: parseFloat(params.doseQuantity),
        ...(params.doseQuantityUnit && {unit: params.doseQuantityUnit}),
      },
    }),
    ...(params?.meta && { meta: params?.meta }),
    ...(params?.extension && { meta: params?.extension })
  };
};

export const formatHieImmunizationData = (
  resource: any,
  metaData: IFormatHieMetaData
): IHieFormattedData | undefined => {
  const immuResource: Immunization = resource; 

  const capabilities = getEHRCapability(metaData.ehrCapabilities, [
    CapabilityResource.immunization,
  ]);

  const fieldCapabilities = getFieldCapabilities(capabilities);

  const display = {
    title: getHieImmunizationCodes(resource, fieldCapabilities?.vaccineCode?.allowedCodeSystems)?.text || '',
    date: getOcurrenceDateTime(immuResource),
    status: immuResource.status,
  };

  const validatedData = validateAndFormatImunizationHieResource(
    resource,
    fieldCapabilities,
    metaData
  );

  return {
    display,
    resourceData: validatedData.resource,
    invalidFields: validatedData.invalidFields,
  };
};

const getOcurrenceDateTime = (immuResource: Immunization) => {
  return immuResource.occurrenceDateTime || immuResource.occurrenceString;
};

const getValidPerformerId = (immuResource: Immunization, practionerData: any[]) => {
  const practitionerRef = immuResource.performer?.[0].actor.reference;
  const practionerId = practitionerRef?.replace('Practitioner/', '');

  if(practionerId){
    const practionerExist = practionerData?.find((practioner)=> practioner.id === practionerId);
    if(!practionerExist){
      return;
    }
  }
  return practionerId;
};

const getHieImmunizationCodes = (
  resource: Immunization,
  allowedCodeSystems?: string[]
) => {
  const codeObj = resource.vaccineCode;
  const coding = getValidCoding(codeObj, allowedCodeSystems);
  const text = getTextFromCoding(coding) || codeObj?.text;
  return {coding: coding, text: text};
};

export const getImmunizationInvalidCodeField = () => {
  return {
    field: ImmunizationFields.vaccineCode,
    inputType: HieInvalidFields.code,
    invalid: true,
    label: 'Immunization',
    placeholder: 'Search Immunization',
    extraData: {
      searchType: PAMISearchType.immunization,
    },
  }
}

export const validateAndFormatImunizationHieResource = (
  resource: Immunization,
  fields: any,
  metaData: IFormatHieMetaData
) => {
  const invalidFields: IHieInvalidField[] = [];

  Object.keys(fields).forEach(function (key, index) {
    const field = ImmunizationFields[key as keyof typeof ImmunizationFields];
    switch (field) {
      case ImmunizationFields.vaccineCode:
        const immunizationCodes = getHieImmunizationCodes(
          resource,
          fields?.vaccineCode?.allowedCodeSystems
        );

        if (
          fields?.vaccineCode.inputType === InputType.openChoice &&
          immunizationCodes.text
        ) {
          resource.vaccineCode = {
            text: immunizationCodes.text,
            ...(immunizationCodes?.coding?.length && {
              coding: immunizationCodes.coding,
            }),
          };
        }
        else if (
          (!immunizationCodes?.coding?.length ||  !immunizationCodes.text)&&
          fields?.vaccineCode?.isRequired
        ) {
          invalidFields.push(getImmunizationInvalidCodeField());
        } else {
          resource.vaccineCode = {
            text: immunizationCodes?.text,
            coding: immunizationCodes?.coding,
          };
        }
        break;

      case ImmunizationFields.performer:
        const practionerId = getValidPerformerId(resource, metaData.practitionerData || []);
        if (!practionerId && fields?.performer?.isRequired) {
          invalidFields.push({
            field: ImmunizationFields.performer,
            inputType: HieInvalidFields.practitioner,
            invalid: true,
            label: 'Practitioner',
            placeholder: 'Select Practitioner',
            extraData: {
              practitionerData: metaData.practitionerData,
            },
          });
        } else if (practionerId) {
          resource.performer = [
            {
              actor: {
                reference: `Practitioner/${practionerId}`,
              },
            },
          ];
        }
        else {
          delete resource.performer;
        }

        break;


      case ImmunizationFields.occurrenceDateTime:
        const occurrenceDateTime = getOcurrenceDateTime(resource);
        if (!occurrenceDateTime && fields?.occurrenceDateTime?.isRequired) {
          invalidFields.push({
            inputType: HieInvalidFields.date,
            field: ImmunizationFields.occurrenceDateTime,
            invalid: true,
          });
        } else if (occurrenceDateTime) {
          const formattedOccurenceDateTime =
            getMomentObj(occurrenceDateTime).toISOString();
          resource.occurrenceDateTime = formattedOccurenceDateTime;
        }
        break;

      case ImmunizationFields.doseQuantity:
        const doseQuantity = resource.doseQuantity?.value;
        
        if (!doseQuantity && fields?.doseQuantity?.isRequired) {
          invalidFields.push({
            inputType: HieInvalidFields.input,
            field: ImmunizationFields.doseQuantity,
            placeholder: 'Dose Quantity',
            invalid: true,
          });
        }
        else if(doseQuantity){
          resource.doseQuantity = {
            ...resource.doseQuantity,
            value: doseQuantity
          };
        }

        break;
      
      case ImmunizationFields.doseQuantityUnit:
      const doseQuantityUnit = resource.doseQuantity?.unit;
      
      if (!doseQuantityUnit && fields?.doseQuantityUnit?.isRequired) {
        invalidFields.push({
          inputType: HieInvalidFields.input,
          field: ImmunizationFields.doseQuantityUnit,
          placeholder: 'Dose Quantity Unit',
          invalid: true,
        });
      }
      else if(doseQuantityUnit){
        resource.doseQuantity = {
          ...resource.doseQuantity,
          unit: doseQuantityUnit
        };
      }
        break;  
    }
  });

  return {
    invalidFields: invalidFields,
    resource: resource,
  };
};

export const updateHieImmunizationMatchedData = (
  resource: Immunization,
  invalidFields: IHieInvalidField[],
  metaData: IFormatHieMetaData
) => {
  invalidFields.map((invalidField) => {
    if (invalidField.value) {
      switch (invalidField.field) {
        case ImmunizationFields.vaccineCode:
          resource.vaccineCode = invalidField.value;
          break;

        case ImmunizationFields.occurrenceDateTime:
          resource.occurrenceDateTime = invalidField.value;
          break;
       
        case ImmunizationFields.doseQuantity:
            resource.doseQuantity = {
              ...resource.doseQuantity,
              value: invalidField.value
            };
            break;  
              
        case ImmunizationFields.doseQuantityUnit:
          resource.doseQuantity = {
            ...resource.doseQuantity,
            unit: invalidField.value
          };
          break;  
        case ImmunizationFields.performer:
          resource.performer = [
            {
              actor: {
                reference: `Practitioner/${invalidField?.value.id}`,
              },
            },
          ]         
          break;    
      }
    }
    return invalidField;
  });

  return formatHieImmunizationData(resource, metaData);
};

export const formatHieImmunizationResourceObj = (
  resource: Immunization,
  metaData: IFormatHieMetaData
) => {
  const occurrenceDateTime =
    resource.occurrenceDateTime || resource.occurrenceString;

  return {
    resourceType: FHIR_RESOURCE.IMMUNIZATION,
    occurrenceDateTime: occurrenceDateTime,
    vaccineCode: resource.vaccineCode,
    primarySource: true,
    patient: {
      reference: `Patient/${metaData.patientId}`,
    },
    ...(resource.performer && {
      performer: resource.performer,
    }),
    ...(resource.doseQuantity && {
      doseQuantity: resource.doseQuantity,
    }),
    ...(resource?.meta && {meta: resource?.meta}),
    ...(resource?.extension && {meta: resource?.extension}),
  };
};
