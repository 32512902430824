import {Menu} from 'antd';
import {VStack} from 'native-base';
import {useIntl} from 'react-intl';
import {styles} from './EmailTemplateOptionStyles';

export interface EmailTemplateOptionsProps {
  onTemplateOptionActionPerformed: (actionCode: string) => void;
}

export interface IEmailTemplateOption {
  id: number;
  titleId: string;
  code: string;
}

export const EMAIL_TEMPLATE_OPTION_CODES = {
  CREATE: 'CREATE',
  PASTE_IN_CODE: 'PASTE_IN_CODE',
  UPLOAD_ZIP: 'UPLOAD_ZIP',
};

const EmailTemplateOptions = (props: EmailTemplateOptionsProps) => {
  const {onTemplateOptionActionPerformed} = props;

  const intl = useIntl();

  const options: IEmailTemplateOption[] = [
    {
      id: 1,
      titleId: 'createUsingUIDesigner',
      code: EMAIL_TEMPLATE_OPTION_CODES.CREATE,
    },
    {
      id: 2,
      titleId: 'pasteInCode',
      code: EMAIL_TEMPLATE_OPTION_CODES.PASTE_IN_CODE,
    },
    {
      id: 3,
      titleId: 'uploadZipFolder',
      code: EMAIL_TEMPLATE_OPTION_CODES.UPLOAD_ZIP,
    },
  ];

  return (
    <VStack style={styles.container}>
      <Menu style={{padding: 8}}>
        {options.map((item) => {
          return (
            <Menu.Item
              key={item.id}
              onClick={() => onTemplateOptionActionPerformed(item.code)}
            >
              {intl.formatMessage({id: item.titleId})}
            </Menu.Item>
          );
        })}
      </Menu>
    </VStack>
  );
};

export default EmailTemplateOptions;
