export const MSG_SEND_OPTIONS = {
  BROWSE_FORMS: 'BROWSE_FORMS',
  BROWSE_ARTICLES: 'BROWSE_ARTICLES',
  SEND_APPOINTMENT_LINK: 'SEND_APPOINTMENT_LINK',
  BROWSE_ATTACHMENTS: 'BROWSE_ATTACHMENTS',
  BROWSE_CONTACT: 'BROWSE_CONTACT',
  SCHEDULE_MESSAGE: 'SCHEDULE_MESSAGE',
  MEDIA_ATTACHMENTS: "MEDIA_ATTACHMENTS"
};

export const hideEditorToolbar = true; //hide toast ui editor in message screen for now
export const isCommunicateWithInactivePatient = true;

export const ToastUiEditorToolbarItems = {
  toolbar: [
  ['heading', 'bold', 'italic', 'strike'],
  ['hr', 'quote'],
  ['ul', 'ol', 'link'],
  ]
}

export const MEMBER_SUBTITLE_DATA_CODE = {
  GROUP_NAME: 'GROUP_NAME',
  CONTACT_TYPE: 'CONTACT_TYPE',
  AGE: 'AGE',
  GENDER: 'GENDER',
  LOACTION: 'LOCATION',
  PCP_USER_NAME: 'PCP_USER_NAME',
  MEMBER_COUNT: 'MEMBER_COUNT',
  CONVERSATION_INBOX_NAME: 'CONVERSATION_INBOX_NAME',
}

export const MEMBER_SUBTITLE_DATA_CODE_LIST = [
  MEMBER_SUBTITLE_DATA_CODE.GROUP_NAME,
  MEMBER_SUBTITLE_DATA_CODE.CONTACT_TYPE,
  MEMBER_SUBTITLE_DATA_CODE.AGE,
  MEMBER_SUBTITLE_DATA_CODE.GENDER,
  MEMBER_SUBTITLE_DATA_CODE.LOACTION,
  MEMBER_SUBTITLE_DATA_CODE.PCP_USER_NAME,
  MEMBER_SUBTITLE_DATA_CODE.MEMBER_COUNT,
  MEMBER_SUBTITLE_DATA_CODE.CONVERSATION_INBOX_NAME,
]

export const MEMBER_SUBTITLE_DATA_CODE_LIST_INTERNAL = [
  MEMBER_SUBTITLE_DATA_CODE.MEMBER_COUNT,
]

export const MEMBER_SUBTITLE_DATA_CODE_LIST_CHAT = [
  MEMBER_SUBTITLE_DATA_CODE.CONTACT_TYPE,
  MEMBER_SUBTITLE_DATA_CODE.AGE,
  MEMBER_SUBTITLE_DATA_CODE.GENDER,
  MEMBER_SUBTITLE_DATA_CODE.MEMBER_COUNT,
  MEMBER_SUBTITLE_DATA_CODE.PCP_USER_NAME,
  MEMBER_SUBTITLE_DATA_CODE.LOACTION,
  MEMBER_SUBTITLE_DATA_CODE.CONVERSATION_INBOX_NAME,
]

export const BlockNumberActionMessage = 'has unsubscribed from our SMS chat, You can’t reply to this conversation.'

export const CONVERSATION_TASK_CODE = {
  TASK: 'TASK',
  MESSAGE: 'MESSAGE',
};

export const SEARCH_MSG_CONST = {
  SEARCH_MSG_LENGTH: 80,
  AUTOMATED_MSG: 'Automated Message',
  YOU: 'You',
  USER: 'user',
}
export const DEVICES_NAME = {
  GOOGLE: 'google',
};

export const DEVICES_MODAL = {
  PIXEL: 'pixel',
};

export const MSG_FOOTER_TAB_NAME = {
  INTERNAL: 'Internal',
  REPLY: 'Reply',
  SMS: 'SMS',
  EMAIL: 'Email',
  CHAT: 'Secure Message',
}


export const KEY_PRESS_CODES = {
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ENTER: 'Enter',
}

export const ON_PRESS_ENTER_ACTION_CODES = {
  SEND_MESSAGE: 'SEND_MESSAGE',
  NEW_LINE: 'NEW_LINE',
}
export const mentionRegexForMessaging =/\[@(.*?)\]\(mention:\/\/(user|contact)\/([\w-]+)\/(user_uuid|contact_uuid)\/(.*?)\)/g;

export const UPDATE_MESSAGE_HEIGHT = 220;

export const PATIENT_CONTEXT_MODULE_CODE = 'PATIENT_CONTEXT_MODULE_CODE'