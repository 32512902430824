import {Gauge, GaugeConfig} from "@ant-design/charts";
import {DISPLAY_TYPE} from "../../../../../../constants";
import {Colors} from "../../../../../../styles";
import {graphColorList} from "../../graphColors";
import {IWidgetCardView} from "../../interface";

const GaugeChartView = (props: IWidgetCardView) => {
  const {sectionData, height, resourceData} = props;
  const finalResourceData: any = resourceData;
  const formAdditionalData = finalResourceData?.additionalData;
  const averageRatingScoreByType = formAdditionalData?.averageRatingScoreByType || [];
  const getNPSScore = () => {
    return averageRatingScoreByType.find((singleItem: any) => {
      return singleItem?.filterCode === 'NPS_SCORE'
    });
  }
  const getGaugeChartView = () => {
    let ele = <></>;
    const npsScore = getNPSScore();
    if (npsScore?.avgScorePercentage) {
      switch (sectionData.currentDisplayType) {
        case DISPLAY_TYPE.DETAIL_WITH_MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE:
          const gaugeConfig: GaugeConfig = {
            percent: npsScore?.avgScorePercentage / 100,
            height: height ? height : 108,
            renderer: 'svg',
            range: {
              color: '#80A4D5',
            },
            tooltip: {

            },
            indicator: {
              pointer: {
                style: {
                  stroke: Colors.Custom.Gray300,
                },
              },
              pin: {
                style: {
                  stroke: Colors.Custom.Gray300,
                },
              },
            },
            axis: {
              label: {
                formatter(v) {
                  return '';
                },
              },
              subTickLine: null,
              tickLine: null
            },
            statistic: {
              content: {
                formatter: () => `${npsScore?.avgScorePercentage}%`,
                style: {
                  fontSize: '20px',
                  lineHeight: '36px',
                },
              },
            },
          }
          ele = <Gauge {...gaugeConfig} />
          break;
      }
      return ele
    }

  }

  return (
    <>
      {
        averageRatingScoreByType?.length > 0 &&
        (getGaugeChartView())
      }
    </>

  )
}

export default GaugeChartView;
