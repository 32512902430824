import {INewCampaignProps} from '../interfaces';
import {TitleBar} from './TitleBar';
import {Steps} from 'antd';
import {Dimensions} from 'react-native';
import {useState} from 'react';
import {CAMPAIGN_CONSTANTS} from '../CampaignConstants';
import {Content} from 'antd/lib/layout/layout';
import {CampaignStepOneScreen} from './StepScreens/CampaignStepOneScreen';
import {CampaignStepTwoScreen} from './StepScreens/CampaignStepTwoScreen/CampaignStepTwoScreen';
import {CampaignStepThreeScreen} from './StepScreens/CampaignStepThreeScreen/CampaignStepThreeScreen';
const {Step} = Steps;

export const NewCampaign = (props: INewCampaignProps) => {
  const {height} = Dimensions.get('window');

  const [currentStep, setCurrentStep] = useState(
    CAMPAIGN_CONSTANTS.STEP_ONE_NUMERIC
  );

  const onChangeStep = (newCurrentStep: number) => {
    setCurrentStep(newCurrentStep);
  };

  const onTitleChange = (text: string): any => {
    // onTitleChange
  };

  const getStepScreen = () => {
    switch (currentStep) {
      case CAMPAIGN_CONSTANTS.STEP_ONE_NUMERIC:
        return <CampaignStepOneScreen />;
      case CAMPAIGN_CONSTANTS.STEP_TWO_NUMERIC:
        return <CampaignStepTwoScreen />;
      case CAMPAIGN_CONSTANTS.STEP_THREE_NUMERIC:
        return <CampaignStepThreeScreen />;
      case CAMPAIGN_CONSTANTS.STEP_FOUR_NUMERIC:
        return <></>;
    }
  };

  return (
    <Content
      className="filter-expand"
      style={{height: height - 82, overflow: 'auto'}}
    >
      <TitleBar
        onTitleChange={onTitleChange}
        handleScreenChange={props.handleScreenChange}
      />
      <Steps
        type="navigation"
        size="small"
        current={currentStep}
        onChange={onChangeStep}
        className="site-navigation-steps"
      >
        <Step title={CAMPAIGN_CONSTANTS.STEP_ONE} />
        <Step title={CAMPAIGN_CONSTANTS.STEP_TWO} />
        <Step title={CAMPAIGN_CONSTANTS.STEP_THREE} />
        <Step title={CAMPAIGN_CONSTANTS.STEP_FOUR} />
      </Steps>
      {getStepScreen()}
    </Content>
  );
};
