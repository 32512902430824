import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  dateTextWrapper: {
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 1,
    borderRadius: 16,
    paddingHorizontal: 6,
  },
  menuStyle: {
    padding: 0,
    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)'
  },
});
