import {Text} from 'native-base';
import {IntlShape} from 'react-intl';
import {Colors} from '../../styles';
import {getDateStrFromFormat} from '../../utils/DateUtils';
import Stack from '../common/LayoutComponents/Stack';
import {DATE_FORMATS} from '../../constants';
import {Line} from '@ant-design/charts';
import {DisplayText} from '../common/DisplayText/DisplayText';
import {PendingIconSvg} from './assets/images/PendingIconSvg';
import {FORM_SCORE_OPERATIONS} from './constants';
import {GENERAL_RISK_SCORE_GROUP_NAME} from '../RightSideContainer/Forms/FormBuilderWidget/RiskScoreConfiguration/SectionWiseRiskScoreView';
import {CustomLineGraph} from '../RightSideContainer/Forms/FHFormio/CustomComponents/CustomLineGraph/CustomLineGraph';
import TrendView from '../RightSideContainer/Forms/FHFormio/CustomComponents/HomeMonitoringView/components/TrendView';
import { getTrimmedDataForTrendView } from '../RightSideContainer/Forms/FHFormio/CustomComponents/ClinicalAssessment/ClinicalAssessmentHelper';
import { converGraphDataToVitalGraphData } from '../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils';
import { GraphsTypes } from '../RightSideContainer/Forms/FHFormio/CustomComponents/HomeMonitoringView/interface';

export const getColumns = (intl: IntlShape): any[] => {
  const columns: any[] = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500 || '', marginLeft: 8}}
          textLocalId={'assessment'}
        />
      ),
      key: 'name',
      dataIndex: 'name',
      width: '40%',
      fixed: 'left',
      render: (name: string, form: any) => {
        return (
          <Stack space={1} direction="column" style={{marginLeft: 8}}>
            <Text fontSize={14} color={Colors.Custom.Gray900}>
              {form.title}
            </Text>
            {!!form.lastSubmittedOn && (
              <Text fontSize={'xs'}  color={Colors.Custom.Gray500}>
                {`${getDateStrFromFormat(
                  form.lastSubmittedOn,
                  DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                )}`}
              </Text>
            )}
            {form.recentFormScore ? (
              <Text fontSize={12} color={Colors.Custom.Gray500}>
                {`${intl.formatMessage({id: 'lastFormScore'})} : ${
                  form.recentFormScore
                }`}
                {form.lastFormState ? (
                  <Text fontSize={12} color={Colors.Custom.Gray500}>
                    {` (${form.lastFormState})`}
                  </Text>
                ) : (
                  <></>
                )}
              </Text>
            ) : (
              <></>
            )}

            {!form.recentFormScore && !form.lastSubmittedOn && (
              <Stack direction="row" style={{alignItems: 'center'}}>
                <PendingIconSvg />
                <Text
                  fontSize={12}
                  marginLeft={1}
                  color={Colors.Custom.PendingTaskFontColor}
                >
                  {intl.formatMessage({id: 'pending'})}
                </Text>
              </Stack>
            )}
          </Stack>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500 || '', marginLeft: 0}}
          textLocalId={'lastSentOn'}
        />
      ),
      key: 'lastFormSentOn',
      dataIndex: 'lastFormSentOn',
      width: '20%',
      fixed: 'left',
      render: (name: string, form: any) => {
        return (
          <Stack space={1} direction="column">
            {!!form.lastFormSentOn && (
              <Text fontSize={'xs'}>
                {`${getDateStrFromFormat(
                  form.lastFormSentOn,
                  DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                )}`}
              </Text>
            )}
          </Stack>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500 || '', marginLeft: 0}}
          textLocalId={'trend'}
        />
      ),
      key: 'graphDataSets',
      dataIndex: 'graphDataSets',
      width: '40%',
      fixed: 'left',
      render: (name: string, form: any) => {
        const updatedForm = getTrimmedDataForTrendView(form);

        return (
          <Stack space={1} direction="column">
            {form.graphData?.length > 0 && (
              <TrendView
                vitalGraph={converGraphDataToVitalGraphData(updatedForm, GraphsTypes.LINE)}
                toolTipFontSize={10}
              />
            )}
          </Stack>
        );
      },  
    },
  ];
  return columns;
};
