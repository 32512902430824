import {OperationVariables, QueryResult, useLazyQuery} from '@apollo/client';
import {Table} from 'antd';
import {Spinner} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {RIGHT_SIDE_CONTAINER_CODE} from '../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {ILoginUserData} from '../../../../Interfaces';
import {CloudTelephonyQueries, UserQueries} from '../../../../services';
import InboxQueries from '../../../../services/Inbox/InboxQueries';
import {getAccountId, getAccountUUID, getAllowedUserAccountLocationUuids, getAllowedUserPracticeLocationUuids, getUserPracticeLocationUuids, getUserUUID, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner} from '../../../../utils/commonUtils';
import { TableWrapper } from '../../../common/TableWrapper';
import {onPagination} from '../../Contacts/Leads/LeadView/LeadTableView/LeadListView/LeadListHelper';
import {IUsersResponse} from '../../Contacts/TeamMembers/interfaces';
import {CHANNEL_TYPE} from '../../TeamInbox/Conversations/ConversationConst';
import {formatIntegrationsData} from '../../TeamInbox/Integrations/Helper/formatIntegrationsData';
import {getVirtualNumberFormattedList} from '../CloudTelephonyUtils';
import {IIvrList} from '../CloudTelephonyView/interface';
import {IUser, IVirtualPhoneNumberResp} from '../interfaces';
import {LinkPhoneDrawer} from '../LinkPhoneDrawer';
import {getActiveNumbersTableColumns} from './ActiveNumbersTableColumns';
import {styles} from './ActiveNumbersTableStyles';
import DeleteReleaseNumber from './DeleteReleaseNumber';
import EditAssigneeToNumber from './EditAssigneeToNumber';
import { ASSIGN_TYPE } from '../CloudTelePhoneyConst';
import VirtualPhoneNumberQueries from '../../TeamInbox/CallLogs/VirtualPhoneNumberQueries';
import UserPracticeLocationQueries from '../../../../services/Location/UserPracticeLocationQueries';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { USER_ACCESS_PERMISSION } from '../../UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import { getUserSearchByBoRoleWhereCondition } from '../../../common/OutboundCallSmsView/utils';
import { SEARCH_USERS_BY_ROLE_CODES } from '../../../../services/User/UserQueries';

interface IActiveNumbersTable {
  event: string;
  userList: IUser[];
  onActionPerformed: (actionCode: string, data?: any, value?: any) => void;
  ivrFlowList: IIvrList[];
  loading?: boolean;
  searchString?: string;
}

const ActiveNumbersTable = (props: IActiveNumbersTable) => {
  const accountUuid = getAccountUUID();
  const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CALL
  );
  const isAllowedAllLocation = isLoginUserBusinessOwner() || isLoggedInUserWorkFlowOrCustomerSuccess();
  const isCommunicationLocationHandlingEnabled = !isAllowedAllLocation && isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const commonData = useContext(CommonDataContext);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const CommunicationType = mlovData?.CommunicationType;
  const assigneesTypeList = mlovData['VirtualNumberAssignee'] || [];
  const userData = commonData.userData || ({} as ILoginUserData);
  const userUuid = getUserUUID();

  const [getInboxes] = useLazyQuery(InboxQueries.GetInboxesData, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    notifyOnNetworkStatusChange: true,
    variables: {
      whereCondition: {
        accountId: {
          _eq: userData.account_id
        },
        channelType: {
          _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS
        },
        isDeleted: {
          _eq: false
        }
      }
    }
  });

  const [stateData, setStateData] = useState({
    tableListData: [] as any,
    loading: false,
    selectedViewCode: '',
    selectedData: {} as any,
    inboxes: [] as any[],
    pageSize: 10,
    total: 10,
    currentPage: 1,
    offSet: 0
  });
  const [GET_VIRTUAL_PHONE_NUMBER] = useLazyQuery<IVirtualPhoneNumberResp>(
    CloudTelephonyQueries.GET_VIRTUAL_PHONE_NUMBER,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [GET_VIRTUAL_PHONE_NUMBER_BY_USER_LOCATIONS] = useLazyQuery<IVirtualPhoneNumberResp>(
    CloudTelephonyQueries.GET_VIRTUAL_PHONE_NUMBER_BY_USER_LOCATIONS,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT] = useLazyQuery(
    CloudTelephonyQueries.GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [getUsersData] = useLazyQuery<IUsersResponse>(UserQueries.GET_USERS, {
    variables: {
      searchString: '%%',
      accountId: getAccountId(),
    },
  });

  const getInboxListFromAPI = async () => {
    const inboxResp = await getInboxes({
      variables: {
        whereCondition: {
          accountId: {
            _eq: userData.account_id
          },
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS
          },
          isDeleted: {
            _eq: false
          }
        }
      }
    });
    const inboxes = formatIntegrationsData(inboxResp?.data || []);
    setStateData((prev) => {
      return {
        ...prev,
        inboxes: inboxes,
      };
    });
  }

  useEffect(() => {
    getInboxListFromAPI();
  }, []);

  useEffect(() => {
    if (props.event == 'REFETCH_INBOXES') {
      getInboxListFromAPI();
    }
  }, [props.event]);

  const [GetVirtualPhoneNumberAssigneeNames] = useLazyQuery(
    VirtualPhoneNumberQueries.GetVirtualPhoneNumberAssigneeNames,{
      fetchPolicy: 'no-cache',
    }
  );
  
  const [GetUsersByUserPracticeLocations] = useLazyQuery(
    UserPracticeLocationQueries.GetUsersByUserPracticeLocations, {
      fetchPolicy: 'no-cache',
    }
  );

  const [getUsersByRoles] = useLazyQuery(SEARCH_USERS_BY_ROLE_CODES, {
    fetchPolicy: 'no-cache',
  });

  const getNumbersList = async (offSet?: number) => {
    let usersWithMatchingLocations: string[] = [];

    if (isCommunicationLocationHandlingEnabled) {

      const [userPracticeLocationsResp, userResponse] = await Promise.all([
        GetUsersByUserPracticeLocations({
          variables: {
            locationUuids: allowedUserAccountLocationUuids,
            accountUuid: accountUuid
          },
        }),
        getUsersByRoles({
          variables: {
            where: getUserSearchByBoRoleWhereCondition(),
          }
        })
      ]);

      if (userPracticeLocationsResp?.data?.userPracticeLocations) {
        usersWithMatchingLocations =
          userPracticeLocationsResp?.data?.userPracticeLocations?.map(
            (value: {uuid: string; userUuid: string}) => {
              return value?.userUuid;
            }
          );
      }
      if (userResponse.data?.users?.length) {
        const boUsers = userResponse.data?.users?.map(
          (value: {uuid: string}) => {
            return value?.uuid;
          }
        );
        usersWithMatchingLocations.push(...boUsers);
      }

      usersWithMatchingLocations.push(...allowedUserAccountLocationUuids);
      usersWithMatchingLocations.push(userUuid)
      usersWithMatchingLocations = Array.from(new Set(usersWithMatchingLocations));
    }
    
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let responses: QueryResult<IVirtualPhoneNumberResp, OperationVariables>;
    if (isCommunicationLocationHandlingEnabled) {
      responses = await GET_VIRTUAL_PHONE_NUMBER_BY_USER_LOCATIONS({
        context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
        variables: {
          accountUuid: accountUuid,
          searchString: `%${props?.searchString}%` || '%%',
          assigneeIds: usersWithMatchingLocations,
          limit: 10,
          offset: offSet || 0
        },
      });
    } else {
      responses = await GET_VIRTUAL_PHONE_NUMBER({
        context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
        variables: {
          accountUuid: accountUuid,
          searchString: `%${props?.searchString}%` || '%%',
          limit: 10,
          offset: offSet || 0
        },
      });  
    }

    const userDataList = await getUsersData();
    const allDefaultForAccount = await GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        accountUuid: accountUuid,
        tenantId: accountUuid
      },
    });
    const virtualNumberList = getVirtualNumberFormattedList(
      responses?.data?.virtualPhoneNumberAssignees || [],
      assigneesTypeList,
      userDataList.data?.users || [],
      props.ivrFlowList,
      allDefaultForAccount?.data?.numberDefaultCommunications || [],
      CommunicationType
    );

    const userList: (string | undefined)[] = [];
    const locationList: (string | undefined)[] = [];
    virtualNumberList.forEach((virtualNumber: { assignedTypeCode: string; userUuid: string | undefined; }) => {
      if (virtualNumber.assignedTypeCode === ASSIGN_TYPE.USER && virtualNumber.userUuid) {
        userList.push(virtualNumber.userUuid);
      } else if (virtualNumber.assignedTypeCode === ASSIGN_TYPE.CLINIC && virtualNumber.userUuid) {
        locationList.push(virtualNumber.userUuid);
      }
    })

    async function getData() {
      const responseData = await GetVirtualPhoneNumberAssigneeNames({
        variables: {
          userUuids: userList,
          locationUuids: locationList
        },
      })
      .then((response) => {
        const { users, accountLocations } = response.data;
        virtualNumberList.forEach((virtualNumber: { assignedTypeCode: string; userUuid: string | undefined; phoneNumber: { name: any; }; }) => {
          if (virtualNumber.assignedTypeCode === ASSIGN_TYPE.USER) {
            const foundUser = users.find((user: { uuid: string | undefined; }) => user.uuid === virtualNumber.userUuid);
            if (foundUser) {
              virtualNumber.phoneNumber.name = foundUser.name;
            }
          } else if (virtualNumber.assignedTypeCode === ASSIGN_TYPE.CLINIC) {
              const foundLocation = accountLocations.find((location: { uuid: string | undefined; }) => location.uuid === virtualNumber.userUuid);
              if (foundLocation) {
                virtualNumber.phoneNumber.name = foundLocation.practiceLocation.name;
              }
          }
        });
        const formattedData = virtualNumberList;
        setStateData((prev) => {
          return {
            ...prev,
            tableListData: formattedData || [],
            loading: false,
            total: responses?.data?.virtualPhoneNumberAssigneeAggregate?.aggregate?.count || 100
          };
        });
      })
      .catch((error) => {
        setStateData((prev) => {
          return {
            ...prev,
            tableListData: [],
            loading: false,
            total: 0
          };
        });
      });
    }

    getData();

  };

  const onActionPerformed = (actionCode: string, data?: any, value?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.TOGGLE_SMS_INBOX:
        props.onActionPerformed(
          COMMON_ACTION_CODES.TOGGLE_SMS_INBOX,
          data,
          value
        );

        break;
      case COMMON_ACTION_CODES.DELETE:
        props.onActionPerformed(COMMON_ACTION_CODES.DELETE, data, value);

        break;
      case COMMON_ACTION_CODES.EDIT:
        props.onActionPerformed(COMMON_ACTION_CODES.EDIT, data);

        break;
      case COMMON_ACTION_CODES.CANCEL:
        getNumbersList();
        props.onActionPerformed(COMMON_ACTION_CODES.FLOW_UPDATED, data, value);
        setStateData((prev) => {
          return {
            ...prev,
            selectedViewCode: '',
            selectedData: {},
          };
        });
        break;
      case COMMON_ACTION_CODES.COMPLETED:
        getNumbersList();
        setTimeout(() => {
          setStateData((prev) => {
            return {
              ...prev,
              selectedViewCode: '',
              selectedData: {},
            };
          });
        }, 300);

        break;
      case COMMON_ACTION_CODES.EDIT_MODE:
        getNumbersList();
        setTimeout(() => {
          setStateData((prev) => {
            return {
              ...prev,
              selectedViewCode: '',
              selectedData: {},
            };
          });
        }, 300);

        break;
      case COMMON_ACTION_CODES.DOWNLOAD_CLICK:
        break;
      case COMMON_ACTION_CODES.DOCUMENT_CLICK:
        break;

      case COMMON_ACTION_CODES.VIEW_LINKED_IP_PHONES:
        setStateData((prev) => {
          return {
            ...prev,
            selectedViewCode: COMMON_ACTION_CODES.VIEW_LINKED_IP_PHONES,
            selectedData: data,
          };
        });
        break;
      case COMMON_ACTION_CODES.LINK_IP_PHONE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedViewCode: COMMON_ACTION_CODES.LINK_IP_PHONE,
            selectedData: data,
          };
        });
        break;
      case COMMON_ACTION_CODES.CALL:
        props.onActionPerformed(COMMON_ACTION_CODES.CALL, data);
        break;
    }
  };

  useEffect(() => {
    getNumbersList();
  }, [props.searchString]);

  useEffect(() => {
    onActionPerformed(props.event)
  }, [props.event]);
  const {height} = Dimensions.get('window');
  const finalHeight = height - 260;

  return (
    <>
      {/* {stateData.loading || props.loading ? (
        <Spinner size="lg" style={styles.spinnerStyle} />
      ) : ( */}

      <TableWrapper  pagination={{
            // position: ['bottomCenter'],
            pageSize: stateData.pageSize,
            total: stateData.total || 0,
            current: stateData.currentPage,
            onChange: (page, pageSize) => {
              setStateData({
                ...stateData,
                pageSize: pageSize,
                currentPage: page,
                offSet: onPagination(page, pageSize),
              });
              const offSet = onPagination(page, pageSize);
              getNumbersList(offSet);
            },
          }}>
        <Table
          loading={stateData.loading || props.loading}
          rowClassName={(record, index) =>
            index % 2 == 0 ? 'table-row-light' : ''
          }
          pagination={false}
          rowKey={(record) => {
            return record.id;
          }}
          style={{minHeight: finalHeight}}
          scroll={{x: 800, y: finalHeight}}
          columns={getActiveNumbersTableColumns(
            onActionPerformed,
            props.ivrFlowList,
            stateData?.inboxes
          )}
          dataSource={stateData.tableListData}
          onRow={(_record: any) => {
            return {
              onClick: () => {
                props.onActionPerformed(COMMON_ACTION_CODES.EDIT, _record);
              },
            };
          }}
        />
      </TableWrapper>

      {RIGHT_SIDE_CONTAINER_CODE.CONTACT_DELETE_VIEW ===
        stateData.selectedViewCode && (
        <DeleteReleaseNumber
          onFormCompleteAction={onActionPerformed}
          selectedData={stateData.selectedData}
          selectedInboxData
        />
      )}
      {RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW ===
        stateData.selectedViewCode && (
        <EditAssigneeToNumber
          userList={props.userList}
          onFormCompleteAction={onActionPerformed}
          selectedData={stateData.selectedData}
        />
      )}

      {stateData.selectedViewCode == COMMON_ACTION_CODES.LINK_IP_PHONE ||
      stateData.selectedViewCode ==
        COMMON_ACTION_CODES.VIEW_LINKED_IP_PHONES ? (
        <LinkPhoneDrawer
          open={
            stateData.selectedViewCode ==
              COMMON_ACTION_CODES.VIEW_LINKED_IP_PHONES ||
            stateData.selectedViewCode == COMMON_ACTION_CODES.LINK_IP_PHONE
              ? true
              : false
          }
          data={stateData.selectedData}
          onClose={() => {
            setStateData((prev) => {
              return {
                ...prev,
                selectedViewCode: '',
              };
            });
          }}
          actionCode={stateData.selectedViewCode}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ActiveNumbersTable;
