import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles';
const baseMargin = {
  marginTop: 24,
  marginLeft: 24,
  marginRight: 24,
  marginBottom: 24,
};
export const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: 'white',
    shadowColor: 'black',
    shadowOffset: {width: 2, height: 1},
    shadowOpacity: 0.1,
    shadowRadius: 8,
    borderRadius: 8,
    elevation: 10,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 1,
    overflow: 'hidden',
  },
  container: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#e5e7eb',
  },
  shadow: {
    shadowColor: 'black',
    shadowOffset: {width: 2, height: 2},
    shadowOpacity: 0.2,
    shadowRadius: 8,
    elevation: 10,
  },
  loadingView: {
    ...baseMargin,
  },
  leadTableViewContainer: {
    // marginLeft: 2,
  },
  contactSearchBar: {
    paddingVertical: 12,
  },
});
