import { useToast, View } from 'native-base';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import {
  TableTopBar
} from '../../../common/TableTopBar';
import { saveWorkflow } from '../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { getWorkflowListTopBarButtons } from '../../Workflow/Workflow/WorkflowTableView/Helper/TopBarButtons';
import { changeWorkflowIdForImport } from '../../Workflow/workflowUtils';


const AutomationTopBar = (props:{workflowUrl:string, onTableTopBarActionPerformed:any}) => {
  const navigate = useNavigate();
  const inputRef = useRef<any>(null);
  const toast = useToast();
  const handleInputFileClick = () => {
    // 👇️ open file input box on click of other element
    if(inputRef?.current?.click){
      inputRef.current.click();
    }
  };

  function onReaderLoad(event:any){
    const obj = JSON.parse(event.target.result);
    const newWorkflowData = changeWorkflowIdForImport(obj);
    if(newWorkflowData?.workflow &&  newWorkflowData?.graph?.nodes && newWorkflowData?.graph?.nodes?.length){
    const data = {
      ...newWorkflowData.workflow,
      triggerInfo: newWorkflowData.triggerInfo,
      edges: newWorkflowData?.graph?.edges,
      nodes: newWorkflowData?.graph?.nodes,
    }
      saveWorkflow(data,()=>{
        showToast(
          toast,
          'Workflow Imported Successfully',
          ToastType.info
        );
      },()=>{
        showToast(
          toast,
          'Error In Import Workflow',
          ToastType.error
        );
      });
    } else {
      showToast(
        toast,
        'Invalid Workflow Details',
        ToastType.error
      );
    }
    
}

  function handleFileChange(event:any) {
    const reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(event.target.files[0]);
}
  return <View>
    <input
      style={{display: 'none'}}
      ref={inputRef}
      type="file"
      accept='text/json'
      onChange={handleFileChange}
    />
    <TableTopBar
      isDividerNotVisible={true}
      alignSearchBarFlexEnd={true}
      onActionPerformed={(actionCode: string, actionData: any) => {
        props.onTableTopBarActionPerformed(actionCode, actionData);
      }}
      {...getWorkflowListTopBarButtons(
        [
          () => {
            navigate(props.workflowUrl, {});
          }
        ],
        props.workflowUrl,
        undefined,
        handleInputFileClick
      )}
    />
  </View>
}


export default AutomationTopBar;
