import React, {useEffect, useRef, useState} from 'react';
import {Animated, Text, StyleSheet} from 'react-native';
import UnitySvg from '../../common/Svg/UnitySvg';
import { View } from 'native-base';
import { loaderTemeplateValues } from './interfaces';

export const LLMSkeletonLoader = (props :loaderTemeplateValues) => {
  const [index, setIndex] = useState(0);
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start(() => {
      // Animation completed, reset and update index
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 0,
        useNativeDriver: true,
      }).start();
      setIndex((prevIndex) => (prevIndex + 1) % props.texts.length);
    });
  }, [index]); // Re-run the effect when index changes

  return (
    <View height={'200px'}  style={styles.container}>
       <View> <UnitySvg iconKey={props.iconKey}/></View>
      <Animated.Text

        style={[
          styles.text,
          {
            opacity: fadeAnim,
            transform: [
              {
                translateY: fadeAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [10, 0],
                }),
              },
            ],
          },
        ]}
      >
        {props.texts[index]}
      </Animated.Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'absolute',
    left: '30%',

    alignItems: 'center',
    flexDirection: 'row',
    height: '100px'
  },
  text: {

    paddingLeft : 2
  },
});
