import {
  IDatum2,
  ITaxanomoiesTableDisplayData,
} from './TaxonomiesListView/interfaces';

export const getFormatedTaxonomiesRes = (
  resData: IDatum2[]
): ITaxanomoiesTableDisplayData[] => {
  const taxanomiesDisplayList: ITaxanomoiesTableDisplayData[] = [];
  resData.forEach((taxanomoy: IDatum2) => {
    const taxanomoiesAttribute = taxanomoy.attributes;
    taxanomiesDisplayList.push({
      id: taxanomoy.id,
      name: taxanomoiesAttribute.name,
      rawObjRes: taxanomoiesAttribute,
      index: taxanomoy.id,
      key: taxanomoy.id,
      rootId: taxanomoy.relationships.root.data.id,
    });
  });
  return taxanomiesDisplayList;
};

export const getFormatedTaxonomyRes = (
  resData: IDatum2
): ITaxanomoiesTableDisplayData => {
  const taxanomiesDisplayList: ITaxanomoiesTableDisplayData = {
    id: resData.id,
    name: resData.attributes.name,
    rawObjRes: resData.attributes,
    index: resData.id,
    key: resData.id,
    rootId: resData.relationships.root.data.id,
  };

  return taxanomiesDisplayList;
};
