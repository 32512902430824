import React from 'react';
import {Colors} from '../../../styles';
import {ICommonSvgProps} from './interfaces';
import Svg, { Path } from 'react-native-svg';
import {StyleSheet} from 'react-native';

const CareJourney = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';
  return (
    <Svg
      style={styles.svg}
      viewBox="0 0 20 20"
      fill="none"
    >
      <Path 
        d="M0.625 10C0.625 12.4864 1.61272 14.871 3.37087 16.6291C5.12903 18.3873 7.5136 19.375 10 19.375C12.4864 19.375 14.871 18.3873 16.6291 16.6291C18.3873 14.871 19.375 12.4864 19.375 10C19.375 7.5136 18.3873 5.12903 16.6291 3.37087C14.871 1.61272 12.4864 0.625 10 0.625C7.5136 0.625 5.12903 1.61272 3.37087 3.37087C1.61272 5.12903 0.625 7.5136 0.625 10V10Z"
        stroke={strokeColor}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M15 8.125H11.875V5C11.875 4.83424 11.8092 4.67527 11.6919 4.55806C11.5747 4.44085 11.4158 4.375 11.25 4.375H8.75C8.58424 4.375 8.42527 4.44085 8.30806 4.55806C8.19085 4.67527 8.125 4.83424 8.125 5V8.125H5C4.83424 8.125 4.67527 8.19085 4.55806 8.30806C4.44085 8.42527 4.375 8.58424 4.375 8.75V11.25C4.375 11.4158 4.44085 11.5747 4.55806 11.6919C4.67527 11.8092 4.83424 11.875 5 11.875H8.125V15C8.125 15.1658 8.19085 15.3247 8.30806 15.4419C8.42527 15.5592 8.58424 15.625 8.75 15.625H11.25C11.4158 15.625 11.5747 15.5592 11.6919 15.4419C11.8092 15.3247 11.875 15.1658 11.875 15V11.875H15C15.1658 11.875 15.3247 11.8092 15.4419 11.6919C15.5592 11.5747 15.625 11.4158 15.625 11.25V8.75C15.625 8.58424 15.5592 8.42527 15.4419 8.30806C15.3247 8.19085 15.1658 8.125 15 8.125Z"
        stroke={strokeColor}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const styles = StyleSheet.create({
  svg: {
    width: 'inherit',
    height: 'inherit',
  },
});

export default React.memo(CareJourney);
