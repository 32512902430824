import {View} from "native-base";
import {ANALYTICS_RESOURCE} from "../../../../../constants";
import AppointmentBookedSvg from "../../../../common/Svg/WidgetsSvg/AppointmentBookedSvg";
import CareJourneyAdherenceSvg from "../../../../common/Svg/WidgetsSvg/CareJourneyAdherenceSvg";
import ChatMessagingSvg from "../../../../common/Svg/WidgetsSvg/ChatMessagingSvg";
import ChronicRisksSvg from "../../../../common/Svg/WidgetsSvg/ChronicRisksSvg";
import CustomerSatisfactionSvg from "../../../../common/Svg/WidgetsSvg/CustomerSatisfactionSvg";
import EmployeeWellnessIndexSvg from "../../../../common/Svg/WidgetsSvg/EmployeeWellnessIndexSvg";
import EnrolledForCareSvg from "../../../../common/Svg/WidgetsSvg/EnrolledForCareSvg";
import ErxSavingsSvg from "../../../../common/Svg/WidgetsSvg/ErxSavingsSvg";
import ImagingSavingsSvg from "../../../../common/Svg/WidgetsSvg/ImagingSavingsSvg";
import LabSavingsSvg from "../../../../common/Svg/WidgetsSvg/LabSavingsSvg";
import NoShowCalendIcon from "../../../../common/Svg/WidgetsSvg/NoShowCalendIcon";
import ProceduresSvg from "../../../../common/Svg/WidgetsSvg/ProceduresSvg";
import ProjectedROISvg from "../../../../common/Svg/WidgetsSvg/ProjectedROISvg";
import VectorBuildingSvg from "../../../../common/Svg/WidgetsSvg/VectorBuildingSvg";
import VideoCallingSvg from "../../../../common/Svg/WidgetsSvg/VideoCallingSvg";
import VisitsSavingsSvg from "../../../../common/Svg/WidgetsSvg/VisitsSavingsSvg";
import VoiceCallingSvg from "../../../../common/Svg/WidgetsSvg/VoiceCallingSvg";
import AvoidableVisitSavingSvg from "../../../../common/Svg/WidgetsSvg/AvoidableVisitSavingSvg";
import AvoidedWorkplaceAbsenceSavingSvg from "../../../../common/Svg/WidgetsSvg/AvoidedWorkplaceAbsenceSavingSvg";

export const getIconForWidgetByCode = (codeType: string): JSX.Element => {
  let iconElem = <></>;
  switch (codeType) {
    case ANALYTICS_RESOURCE.EMPLOYEE_COUNT:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <VectorBuildingSvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.CHAT_MESSAGING:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <ChatMessagingSvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.VOICE_CALLING:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <VoiceCallingSvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.VIDEO_CALLING:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <VideoCallingSvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.ENROLLED_FOR_CARE:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <EnrolledForCareSvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.EMPLOYEE_WELLNESS_INDEX:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <EmployeeWellnessIndexSvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION:
    case ANALYTICS_RESOURCE.USER_SATISFACTION:
    case ANALYTICS_RESOURCE.EMPLOYER_SATISFACTION:
      iconElem = <></>
      // (
      //   <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
      //     <CustomerSatisfactionSvg  />
      //   </div>
      // );
      break;
    case ANALYTICS_RESOURCE.PROJECTED_ROI:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <ProjectedROISvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.TOTAL_SAVINGS:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <ProjectedROISvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.CLAIMS_AVOIDED:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <ProjectedROISvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.CHRONIC_RISKS:
    case ANALYTICS_RESOURCE.EXPENSE_PARETO_CURVES:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <ChronicRisksSvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.PROCEDURES_SAVINGS:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <ProceduresSvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.IMAGING_SAVINGS:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <ImagingSavingsSvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.VISITS_SAVINGS:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <VisitsSavingsSvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.LAB_SAVINGS:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <LabSavingsSvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.ERX_SAVINGS:
    case ANALYTICS_RESOURCE.MEDICATION_ADHERENCE:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <ErxSavingsSvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.APPOINTMENT_BOOKED:
    case ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED:
    case ANALYTICS_RESOURCE.SYMPTOMS_REPORTED:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <AppointmentBookedSvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.CARE_JOURNEY_ADHERENCE:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <CareJourneyAdherenceSvg />
        </div>
      )
      break;
    case ANALYTICS_RESOURCE.NO_SHOWS:
      iconElem = (
        <View display={'inline'} style={{width: 'inherit', height: 'inherit'}}>
          <NoShowCalendIcon />
        </View>
      )
      break;
    case ANALYTICS_RESOURCE.AVOIDABLE_VISIT_SAVING:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <AvoidableVisitSavingSvg />
        </div>
      );
      break;
    case ANALYTICS_RESOURCE.AVOIDED_WORKDAY_ABSENCE_SAVING:
      iconElem = (
        <div className="common-style" style={{width: 'inherit', height: 'inherit'}}>
          <AvoidedWorkplaceAbsenceSavingSvg />
        </div>
      );
      break;

  }
  return iconElem;
}
