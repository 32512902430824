import React, { useState } from 'react';
import { Table, Button } from 'antd';
import { LeftOutlined, RightOutlined, MoreOutlined } from '@ant-design/icons';
import styles from './BillingTable.module.css';
import { useContactCareProgramBilling } from './useContactCareProgramBilling';
import { IContactCareProgramBillableActivity } from './BillingInterface';
import { getDateObject, getFormattedDate } from '../../../../../../../utils/DateUtils';
import { DISPLAY_DATE_FORMAT } from '../../../../../../../constants';

interface BillingTableProps {
  selectedDate: string;
}

const BillingTable: React.FC<{}> = () => {
  // States
  const [componentState, setComponentState] = useState<BillingTableProps>({
    selectedDate: new Date().toISOString(),
  });

  // Hooks
  const { hookState: { attributesAndCodes, totalBilling }, attributeAndCodesLoading } = useContactCareProgramBilling({
    selectedDate: componentState.selectedDate,
  });

  const columns = [
    {
      title: 'Service Date',
      dataIndex: 'timestamp',
      key: 'timestamp',
      sorter: (a: IContactCareProgramBillableActivity, b: IContactCareProgramBillableActivity) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
      render: (text: string) => {
        return <span>{getFormattedDate(text, DISPLAY_DATE_FORMAT)}</span>
      }
    },
    {
      title: 'Time Spent',
      dataIndex: 'durationInMinutes',
      key: 'durationInMinutes',
      render: (text: number) => {
        return <span>{text} minutes</span>
      }
    },
    {
      title: 'Amount',
      dataIndex: 'totalDollars',
      key: 'totalDollars',
      sorter: (a: IContactCareProgramBillableActivity, b: IContactCareProgramBillableActivity) => (a.totalDollars || 0) - (b.totalDollars || 0),
      render: (text: number) => {
        return <span>${text}</span>
      }
    },
    // {
    //   title: 'Provider',
    //   dataIndex: 'provider',
    //   key: 'provider',
    //   render: (text: string) => (
    //     <>
    //       <span>{text}</span>
    //       <span className={styles.providerType}>(PCP)</span>
    //     </>
    //   ),
    // },
    // {
    //   title: 'Care Manager',
    //   dataIndex: 'careManager',
    //   key: 'careManager',
    // },
    {
      title: 'CPT Code',
      dataIndex: 'cptCodes',
      key: 'cptCodes',
      render: (text: string[]) => {
        return <span>{text.join(', ')}</span>
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        return <span>{text}</span>
      }
    }
  ];

  return (
    <section className={styles.billingContainer}>
      <header className={styles.billingHeader}>
        <h2 className={styles.billingTitle}>Total Billing this month : ${totalBilling}</h2>
        <div className={styles.monthSelector}>
          <Button
            icon={<LeftOutlined style={{fontSize: '12px'}} />}
            className={styles.monthButton}
            aria-label="Previous month"
            disabled={attributeAndCodesLoading}
            onClick={() => {
              const date = getDateObject(componentState.selectedDate);
              date.setMonth(date.getMonth() - 1);
              setComponentState({
                ...componentState,
                selectedDate: date.toISOString(),
              });
            }}
          />
          <span className={styles.currentMonth}>{getFormattedDate(componentState.selectedDate, 'MMM YYYY')}</span>
          <Button
            icon={<RightOutlined style={{fontSize: '12px'}} />}
            className={styles.monthButton}
            aria-label="Next month"
            disabled={attributeAndCodesLoading}
            onClick={() => {
              const date = getDateObject(componentState.selectedDate);
              date.setMonth(date.getMonth() + 1);
              setComponentState({
                ...componentState,
                selectedDate: date.toISOString(),
              });
            }}
          />
        </div>
      </header>
      <Table
        className={styles.billingTable}
        columns={columns}
        dataSource={attributesAndCodes}
        pagination={false}
        scroll={{ x: true }}
        loading={attributeAndCodesLoading}
      />
    </section>
  );
};

export default BillingTable;
