import {Table} from 'antd';
import {Button, HStack, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {styles} from './PipelineTableStyle';
const PipelineTable = (props: any) => {
  const [dataSource, setDataSource]: any = useState();

  const TableColumnsMetaData = [
    {
      title: 'NAME',
      dataIndex: 'pName',
      key: 'pName',
      width: '30%',
      sorter: (a: any, b: any) => a.name - b.name,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
    },
    {
      title: 'CREATED AT',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '20%',
    },
    {
      title: 'CREATED BY',
      dataIndex: 'created_by',
      key: 'created_by',
      width: '20%',
    },
    {
      title: 'ACTION',
      key: 'action',
      width: '10%',
      render: (e: any) => (
        <HStack alignItems={'center'}>
          <VStack>
            <Button
              backgroundColor={'transparent'}
              onPress={() => {
                editTableRow(e);
              }}
            >
              <AntIcon name={'edit'} style={styles.icons} />
            </Button>
          </VStack>
          <VStack>
            <Button
              backgroundColor={'transparent'}
              onPress={() => {
                duplicateTableRow(e);
              }}
            >
              <AntIcon name={'copy1'} style={styles.icons} />
            </Button>
          </VStack>
          <VStack>
            <Button
              backgroundColor={'transparent'}
              onPress={() => {
                archivedTableRow(e);
              }}
            >
              <AntIcon name={'export2'} style={styles.icons} />
            </Button>
          </VStack>
          <VStack>
            <Button
              backgroundColor={'transparent'}
              onPress={() => {
                deleteTableRow(e);
              }}
            >
              <AntIcon name={'close'} style={styles.icons} />
            </Button>
          </VStack>
        </HStack>
      ),
    },
  ];

  const editTableRow = (e: any) => {
    props.onTableActionPerformed(e, COMMON_ACTION_CODES.EDIT);

  };

  const duplicateTableRow = (e: any) => {

  };

  const archivedTableRow = (e: any) => {

  };

  const deleteTableRow = (rowData: any) => {
    props.onTableActionPerformed(rowData, COMMON_ACTION_CODES.DELETE);
  };

  useEffect(() => {
    setDataSource(props.dataSource);
  }, [props.dataSource]);

  return (
    <>
      <Table
        dataSource={dataSource}
        style={{width: '100%'}}
        columns={TableColumnsMetaData}
        pagination={false}
      />
    </>
  );
};
export default PipelineTable;
