import {HStack, Text, View, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions, Platform, Pressable} from 'react-native';
import {Colors} from '../../../styles';
import {styles} from '../../../components/Login/LoginStyles';
import FoldhealthLogoSvg from '../../../components/common/Svg/FoldhealthLogoSvg';
import {EXTRA_SMALL_SCREEN, SMALL_SCREEN} from '../../../constants';
import AccessLockSvg from '../../../components/common/Svg/AccessLockSvg';
import {ISsoErrorInterface} from '../../../Interfaces/CommonInterfaces';
import {getSsoUserEmail, getSupportEmailFromSSOConfig} from './SsoUtils';
import { testID, TestIdentifiers } from '../../../testUtils';

export const SsoAccessErrorPage = (props: ISsoErrorInterface) => {
  const {height} = Dimensions.get('window');
  const isSmallScreen = height <= SMALL_SCREEN;
  const extraSmallScreen = height <= EXTRA_SMALL_SCREEN;
  const adminEmail = getSupportEmailFromSSOConfig(props?.ssoLoginData?.account?.accountConfigurations?.value || '{}');
  const [email, setEmail] = useState('');

  useEffect(() => {
    getUserDetailsByToken();
  }, []);

  const getUserDetailsByToken = async () => {
    const email = await getSsoUserEmail(props?.currentSession || '');
    setEmail(email);
  };

  return (
    <>
      <VStack bgColor={'white'} alignItems={'center'} height={height}>
        {<View {...testID(TestIdentifiers.foldhealthLogoWithName)} mt={10}>{<FoldhealthLogoSvg />}</View>}
        <VStack
          mt={Platform.OS === 'web' ? (isSmallScreen ? '2%' : '5%') : '0'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 5,
          }}
        >
          <View
            style={[
              Platform.OS === 'web' && styles.mainBoxContainerShadow,
              {
                display: 'flex',
                flexDirection: 'column',
                overflow: Platform.OS === 'web' ? 'hidden' : 'scroll',
                width: Platform.OS === 'web' ? 445 : '100%',
              },
            ]}
            bg={'white'}
          >
            <VStack w={'100%'} mb={8}>
              {!extraSmallScreen && (
                <HStack
                  justifyContent="center"
                  alignItems={'center'}
                  flex={1}
                  m={5}
                  {...testID('image')}
                >
                  {Platform.OS === 'web' ? <AccessLockSvg /> : <></>}
                </HStack>
              )}
              <Text
                style={{
                  textAlign: 'center',
                  fontWeight: '700',
                  fontSize: 24,
                  lineHeight: 45,
                  color: Colors.Custom.Gray500,
                  marginTop: extraSmallScreen ? 6 : 0,
                }}
                {...testID('Looks like you don’t have access')}
              >
                {`Looks like you don’t have access`}
              </Text>
              <View
                mt={2}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginHorizontal: 10,
                }}
              >
                <Text
                  style={{
                    fontWeight: '500',
                    fontSize: 18,
                    lineHeight: 21,
                    textAlign: 'center',
                    color: Colors.Custom.Gray400,
                  }}
                  {...testID('Please reach out to your admin')}
                >
                  {`Please reach out to your admin ${
                    adminEmail?.length && `(${adminEmail})`
                  } to get the access`}
                </Text>

                <View
                  mt={4}
                  mb={4}
                  height={1}
                  borderBottomColor={Colors.Custom.Gray200}
                  borderBottomWidth={1}
                  width={'85%'}
                ></View>

                <HStack>
                  <Text
                    style={{
                      color: Colors.Custom.Gray400,
                      fontWeight: '500',
                      fontSize: 16,
                      lineHeight: 21,
                      textAlign: 'center',
                    }}
                    {...testID('You are logged in as')}
                  >
                    {'You are logged in as '}
                  </Text>
                  <Text
                    style={{
                      color: 'black',
                      fontWeight: '500',
                      fontSize: 16,
                      lineHeight: 21,
                      textAlign: 'center',
                    }}
                    {...testID(TestIdentifiers.loginUserName)}
                  >
                    {email}
                  </Text>
                </HStack>

                <Pressable
                  onPress={() => {
                    props?.onSsoLogin();
                  }}
                  {...testID(TestIdentifiers.switchAccountBtn)}
                >
                  <Text
                    mt={3}
                    style={{
                      color: Colors.Custom.PrimaryColor,
                      fontWeight: '600',
                      fontSize: 14,
                      lineHeight: 21,
                      textAlign: 'center',
                    }}
                  >
                    {'Switch account'}
                  </Text>
                </Pressable>
              </View>
            </VStack>
          </View>
        </VStack>
        <HStack
          justifyContent={'center'}
          style={
            Platform.OS === 'web'
              ? {position: 'absolute', bottom: 10, width: '100%'}
              : {
                  width: '100%',
                  display: 'flex',
                  bottom: 24,
                  alignItems: 'center',
                }
          }
        >
          <Text size={'smMedium'} color={Colors.Custom.Gray400} {...testID(TestIdentifiers.copyRightText)}>
            Copyright 2024 Fold Health, Inc. All Rights Reserved.
          </Text>
        </HStack>
      </VStack>
    </>
  );
};
