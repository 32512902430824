import {Drawer} from 'antd';
import {FormControl, HStack, Input, Text, useToast} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {connectToHint} from '../../../../../../services/CommonService/AidBoxService';
import {Colors} from '../../../../../../styles';
import {
  getAccountUUID,
  getUserActionObjectFromActionCode,
  getUserUUID,
} from '../../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../../utils/commonViewUtils';
import ModalActionBtn from '../../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import {insertUserAction} from '../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {styles} from '../../../AccessFormStyles';
import {validateAccessForm} from '../../HintConfigHelper';
import {AccessFormDrawerProps} from '../../interfaces';
const {Label, ErrorMessage} = FormControl;

const AccessFormDrawer = (props: AccessFormDrawerProps) => {
  const {isOpen, onClose, onFormCompleteAction} = props;
  const userActionObject = getUserActionObjectFromActionCode('HINT');
  const level = userActionObject.level || '';
  const accountUuid = getAccountUUID();
  const userId = getUserUUID();
  const intl = useIntl();
  const [state, setState] = useState({
    submitting: false,
    accessCode: '',
    formError: {} as any,
    error: false,
  });

  const toast = useToast();
  const handleSubmit = () => {
    const {errors, valid} = validateAccessForm({accessCode: state.accessCode});
    if (!valid) {
      setState((prev) => {
        return {
          ...prev,
          formError: errors,
        };
      });
      return;
    }
    handleConnectToHint();
  };

  const handleConnectToHint = async () => {
    setState((prev) => {
      return {
        ...prev,
        submitting: true,
      };
    });
    try {
      const response = await connectToHint({hintSecretToken: state.accessCode});
      if (response.data.hintPracticeId && response.data.success) {
        showToast(toast, 'Successfully Connected to Hint', ToastType.success);
        await insertUserAction({
          actionCode: 'HINT',
          level,
          status: 'COMPLETED',
          userId: userId,
        });
        onFormCompleteAction(COMMON_ACTION_CODES.COMPLETED);
      }
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          submitting: false,
        };
      });

      onFormCompleteAction(COMMON_ACTION_CODES.BACK);
      showToast(
        toast,
        'Something went wrong while connecting to Hint. Please check if you have correct access code',
        ToastType.error
      );
    }
  };

  return (
    <Drawer
      visible={isOpen}
      onClose={onClose}
      width={'33vw'}
      title={
        <>
          <HStack
            style={{
              marginTop: 10,
              marginHorizontal: 0,
              marginBottom: 20,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text size={'xxlBold'} color={Colors.primary[400]}>
              Connect To Hint
            </Text>

            <HStack space={2} alignContent="center" alignItems={'center'}>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    onClose();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'cancel',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    handleSubmit();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'Connect',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </HStack>
          </HStack>
        </>
      }
    >
      <FormControl
        style={styles.formContainer}
        isRequired
        isInvalid={state.formError.accessCode}
      >
        <Label style={styles.formLabel}>
          <Text size={'smMedium'} color={Colors.Custom.Gray700}>
            Access Code
          </Text>
        </Label>
        <Input
          height={44}
          fontSize={16}
          value={state.accessCode}
          onChangeText={(value) => {
            setState((prev) => {
              return {
                ...prev,
                accessCode: value,
              };
            });
          }}
        />
        {state.formError.accessCode && (
          <ErrorMessage
            _text={{
              fontSize: 'xs',
              color: 'error.500',
              fontWeight: 500,
            }}
          >
            {state.formError.accessCode}
          </ErrorMessage>
        )}
      </FormControl>
    </Drawer>
  );
};

export default AccessFormDrawer;
