import {DownOutlined} from '@ant-design/icons';
import {Col, Dropdown, Menu, Row, Space, Typography} from 'antd';
import {Stack, useMediaQuery, View} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {IPAD_WIDTH} from '../../../../../constants';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import ChronicPatientsChart from '../../components/Chart/ChronicPatientsChart';
import PatientEngagementRiskChart from '../../components/Chart/PatientEngagementRiskChart';
import ExpenseTable from '../../components/Table/ExpenseTable';
import HealthStatusTable from '../../components/Table/HealthStatusTable';
import PatientRiskTable from '../../components/Table/PatientRiskTable';
import '../../index.css';
import analyticsRequest from '../../services/analytics';
import ChronicCondition from '../../widgets/mockData/ChronicCondition';
import EngagementData from '../../widgets/mockData/EngagementData';
import ExpenseData from '../../widgets/mockData/ExpenseData';

const {Text} = Typography;
const menu = (
  <Menu
    items={[
      {
        label: ' All Employers',
        key: 'emp-0',
      },
    ]}
  />
);

const menu2 = (
  <Menu
    items={[
      {
        label: 'Monthly',
        key: 'mon-0',
      },
    ]}
  />
);

export default function OldAnalyticsDataComponentView(props:any) {
  const [loading, setLoading] = useState(true);
  const [dataChronic, setDataChronic] = useState([]);
  const [dataEngagement, setDataEngagement] = useState([]);
  const [dataExpense, setDataExpense] = useState([]);
  const [isIPadScreen] = useMediaQuery({maxWidth: IPAD_WIDTH});

  const handlerRequest = async () => {
    setLoading(true);
    const resMap = await Promise.allSettled([
      analyticsRequest.getPatientChronic(),
      analyticsRequest.getPatientEngagement(),
      analyticsRequest.getPatientExpense(),
    ]);

    const chronic =
      resMap[0].status === 'fulfilled'
        ? resMap[0].value.data
        : ChronicCondition;
    const engagement =
      resMap[1].status === 'fulfilled' ? resMap[1].value.data : EngagementData;
    const expense =
      resMap[2].status === 'fulfilled' ? resMap[2].value.data : ExpenseData;

    setDataChronic(chronic);
    setDataEngagement(engagement);
    setDataExpense(expense);

    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  // useEffect(() => {
  //   handlerRequest();
  // }, []);
  const {height} = Dimensions.get('window');
  const finalHeight = props.isFromEmployerListing ?  height - 250 : height - 180;
  return (
    <View>
      {!props.isFromEmployerListing ? <View
        style={{flex: 1}}
        // paddingBottom={'16px'}
        // background={'#fff'}
        // borderBottomWidth={1}
        // borderBottomColor={'#E4E7EC'}
      >
        <TitleSubtitleView titleLabelId="analytics" />
      </View> :<></> }

      <View padding={'16px'}>
        <div
          style={{
            display: 'flex',
            marginBottom: '16px',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '28px',
          }}
        >
          <Text style={{marginRight: '8px'}}>Showing data for</Text>
          <Dropdown overlay={menu}>
            <a onClick={(e) => e.preventDefault()}>
              <Space style={{marginRight: '8px'}}>
                All Employers
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>

          <Text style={{marginRight: '8px'}}>aggregated</Text>
          <Dropdown overlay={menu2}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Monthly
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
        <div
          style={{
            maxHeight: finalHeight,
            width: '100%',
            overflow: 'scroll',
          }}
        >
          {!isIPadScreen && (
            <Row gutter={[16, 16]}>
              <Col
                className="gutter-row"
                sm={{span: 24}}
                md={{span: 16}}
                lg={{span: 16}}
                xl={{span: 8}}
              >
                <ChronicPatientsChart loading={loading} data={dataChronic} />
              </Col>
              <Col
                className="gutter-row"
                sm={{span: 24}}
                md={{span: 16}}
                lg={{span: 16}}
                xl={{span: 8}}
              >
                <PatientEngagementRiskChart
                  loading={loading}
                  data={dataEngagement}
                />
              </Col>
              <Col
                className="gutter-row"
                sm={{span: 24}}
                md={{span: 16}}
                lg={{span: 16}}
                xl={{span: 8}}
              >
                <ExpenseTable loading={loading} data={dataExpense} />
              </Col>
            </Row>
          )}

          {isIPadScreen && (
            <Stack
              style={{
                marginTop: 12,
              }}
              space={'3'}
              direction={isIPadScreen ? 'column' : 'row'}
            >
              <ChronicPatientsChart loading={loading} data={dataChronic} />

              <PatientEngagementRiskChart
                loading={loading}
                data={dataEngagement}
              />

              <ExpenseTable loading={loading} data={dataExpense} />
            </Stack>
          )}

          {!isIPadScreen && (
            <Row gutter={[16, 16]} style={{marginTop: '16px'}}>
              <Col
                sm={{span: 24}}
                md={{span: 12}}
                lg={{span: 12}}
                xl={{span: 12}}
              >
                <HealthStatusTable loading={loading} />
              </Col>
              <Col
                sm={{span: 24}}
                md={{span: 12}}
                lg={{span: 12}}
                xl={{span: 12}}
              >
                <PatientRiskTable loading={loading} data={[]} />
              </Col>
            </Row>
          )}

          {isIPadScreen && (
            <Stack
              style={{
                marginTop: 12,
              }}
              space={'3'}
              direction={isIPadScreen ? 'column' : 'row'}
            >
              <HealthStatusTable loading={loading} />
              <PatientRiskTable loading={loading} data={[]} />
            </Stack>
          )}
        </div>
      </View>
    </View>
  );
}
