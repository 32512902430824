import React from 'react';

const GalleryPlaceholderSvg = (props: {
  customColor?: string;
  customDimension?: string;
  isToolTip?: boolean;
  title?: string;
}) => {
  const {customColor} = props;
  const strokeColor = customColor ? customColor : '#667085';

  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 31.5H29.5C30.6046 31.5 31.5 30.6046 31.5 29.5V15.5C31.5 14.3954 30.6046 13.5 29.5 13.5H15.5C14.3954 13.5 13.5 14.3954 13.5 15.5V29.5C13.5 30.6046 14.3954 31.5 15.5 31.5ZM15.5 31.5L26.5 20.5L31.5 25.5M20.5 19C20.5 19.8284 19.8284 20.5 19 20.5C18.1716 20.5 17.5 19.8284 17.5 19C17.5 18.1716 18.1716 17.5 19 17.5C19.8284 17.5 20.5 18.1716 20.5 19Z"
        stroke="#98A2B3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default GalleryPlaceholderSvg;
