import {isContactConsentRequired} from '../../../../../../../utils/commonUtils';
import CareProgramStepOutreachUtils from '../../../../../../common/CareProgramStepOutreach/CareProgramStepOutreachUtils';
import {usePersonOmniViewContext} from '../../../../../../PersonOmniView/PersonOmniView.context';

export const useOutreach = () => {
  const isConsentRequired = isContactConsentRequired();
  const {data: contact} = usePersonOmniViewContext();
  const consentCheck = CareProgramStepOutreachUtils.isConsentRequiredAndGiven(
    contact,
    isConsentRequired
  );
  const enabledContactModes =
    CareProgramStepOutreachUtils.getEnabledContactModes(contact, consentCheck);

  return {
    enabledContactModes,
  };
};
