import {View} from 'native-base';
import {Colors} from '../../../styles';
import {commonBodyContentContainer} from '../../../styles/CommonBodyContentStyle';
import CustomTabsView from '../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../common/CustomTabsView/interfaces';
import TitleSubtitleView from '../../common/TitleSubtitleView/TitleSubtitleView';
import {styles} from '../TeamInbox/styles';
import AppTabPermissions from './AppTabPermissions';
import {USER_ACCESS_PERMISSION} from './UserAccessPermission';

const UserSettings = () => {
  const tagsTabList = (): ITabsList[] => {
    const array: ITabsList[] = [
      {
        key: 'dashboard',
        title: 'Dashboard',
        path: 'dashboard',
        tabContainerElem: () => {
          return (
            <AppTabPermissions
              key={'dashboard'}
              parentTabCode={
                USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code
              }
            ></AppTabPermissions>
          );
        },
      },
      {
        key: 'adminPanel',
        title: 'Admin Panel',
        path: 'adminPanel',
        tabContainerElem: () => {
          return (
            <AppTabPermissions
              key={'adminPanel'}
              parentTabCode={
                USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code
              }
            ></AppTabPermissions>
          );
        },
      },
    ];
    return array;
  };

  return (
    <View >
      <View style={styles.titleWrapper}>
        <TitleSubtitleView
          titleLabelId="userSettingsTitle"
          subtitleLabelId="userSettingsSubTitle"
        />
      </View>
      <View
        style={[
          styles.tabsWrapper,
          commonBodyContentContainer.pageContentContainer,
        ]}
      >
        <CustomTabsView
          contentTopMargin={0}
          heading="userAccess"
          showHeading
          basePath={'/admin/userSettings'}
          tabsList={tagsTabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
          }}
          canNavigate={true}
        />
      </View>
    </View>
  );
};

export default UserSettings;
