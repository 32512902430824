export const CAMPAIGN_CONSTANTS = {
  TAG_FILTER_HEADER: 'Filter By Tags',
  EMAIL_MODAL_HEADER: 'Email Statistics',
  EMAIL_MODAL_BODY: 'Average email statistics:',
  NEW_CAMPAIGN: 'New Campaign',
  AUTO_CAMPAIGN: 'Auto campaign',
  MANUAL_CAMPAIGN: 'Manual campaign',
  VISITOR_CAMPAIGN: 'Visitor auto campaign',
  LANDING: 'LANDING_PAGE',
  TITLE_CANCEL: 'Cancel',
  TITLE_SEND: 'Send & Live',
  TITLE_DRAFT: 'Save & Draft',
  STEP_ONE: 'Choose Channel',
  STEP_TWO: 'Choose Target',
  STEP_THREE: 'Compose Campaign',
  STEP_FOUR: 'Full Preview',
  STEP_ONE_NUMERIC: 0,
  STEP_TWO_NUMERIC: 1,
  STEP_THREE_NUMERIC: 2,
  STEP_FOUR_NUMERIC: 3,
  TAG: 'Tag',
  SEGMENT: 'Segment',
  TITLE: 'Title',
  CHOOSE_MESSAGE: 'CHOOSE A MESSAGE TYPE',
  SEGMENT_TYPE: 'SEGMENT TYPE',
  MESSENGER: 'Messenger',
  EMAIL: 'Email',
  SMS: 'SMS',
};
