import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const getStyles = ({
  checkSelectedConversationCountLimit,
}: {
  checkSelectedConversationCountLimit: boolean;
}) => {
  return StyleSheet.create({
    markAsReadButton: {
      paddingHorizontal: 6,
      paddingVertical: 6,
      borderWidth: 1,
      borderRadius: 8,
      borderColor: Colors.Custom.Gray200,
    },
    markAsReadButtonText: {
      paddingRight: 4,
      paddingLeft: 8,
    },
    archiveButton: {
      paddingHorizontal: 6,
      paddingVertical: 6,
      borderWidth: 1,
      borderRadius: 8,
      borderColor: Colors.Custom.Gray200,
      opacity: checkSelectedConversationCountLimit ? 0.3 : 1,
    },
    archiveButtonText: {
      paddingRight: 2,
      paddingLeft: 4,
    },
    selectedConversationCount: {
      paddingRight: 16,
      borderRightWidth: 1,
      borderRightColor: Colors.Custom.Gray200,
    },
    container: {
      flex: 1,
      alignItems: 'center',
      padding: 16,
    },
    flex1: {
      flex: 1,
    },
    flex05: {
      flex: 0.5,
    },
    flex05End: {
      flex: 0.5,
      justifyContent: 'flex-end',
    },
    mt4: {
      marginTop: 4,
    },
    pl16: {
      paddingLeft: 16,
    },
  });
};
