import {useContext} from 'react';
import GroupListPanel from '../common/GroupList/GroupListPanel';
import {CommonDataContext} from '../../context/CommonDataContext';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../utils/mlovUtils';
import {GROUP_MEMBER_TYPE, MLOV_CATEGORY, PARENT_CODE} from '../../constants';
import {IContact} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {componentStyles} from './components/components.styles';

interface ISideCarPatientComms {
  contactData?: IContact;
}
const SideCarPatientComms = (props: ISideCarPatientComms) => {
  const {contactData} = props;
  const commonData = useContext(CommonDataContext);
  const groupMemberTypeList =
    getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.GroupMemberType) ||
    [];
  const contactTypeId = getMlovIdFromCode(
    groupMemberTypeList,
    GROUP_MEMBER_TYPE.PATIENT
  );
  const groupMemberData = {
    groupUserId: contactData?.uuid,
    groupUserTypeId: contactTypeId,
  };
  return (
    <>
      <GroupListPanel
        isDefaultClose={false}
        groupMemberData={groupMemberData as any}
        parentCode={PARENT_CODE.MESSAGING_DETAIL_PANEL}
        style={componentStyles.groupListPanel}
        shouldDisableLimit={true}
        isInstantChatFromComms={true}
        contactData={contactData}
      />
    </>
  );
};
export default SideCarPatientComms;
