import {gql} from '@apollo/client';

export const CREATE_RECENT_ITEM = gql`
  mutation CREATE_RECENT_ITEM(
    $accountId: bigint!
    $itemUUID: uuid!
    $userUuid: uuid!
    $recentItemTypeUUID: uuid!
  ) {
    insert_recent_items(
      objects: {
        accountId: $accountId
        itemUUID: $itemUUID
        recentItemTypeUUID: $recentItemTypeUUID
        userUuid: $userUuid
      }
      on_conflict: {constraint: recent_items_pkey, update_columns: itemUUID}
    ) {
      returning {
        userUuid
        recentItemTypeUUID
        itemUUID
        id
        isDeleted
        accountId
      }
    }
  }
`;

export const GET_RECENT_ITEMS = gql`
  query GET_RECENT_ITEMS($recentItemTypeUUID: uuid!, $userUuid: uuid!) {
    recent_items(
      order_by: {updatedAt: desc}
      where: {
        recentItemTypeUUID: {_eq: $recentItemTypeUUID}
        userUuid: {_eq: $userUuid}
        isDeleted: {_eq: false}
      }
      limit: 3
    ) {
      accountId
      createdAt
      id
      isDeleted
      itemUUID
      updatedAt
      recentItemTypeUUID
      userUuid
      uuid
      recentItemsMember {
        id
        name
        email
        uuid
      }
    }
  }
`;

export default {
  CREATE_RECENT_ITEM,
  GET_RECENT_ITEMS,
};
