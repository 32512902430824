import {Drawer, Table} from 'antd';
import {FilterValue} from 'antd/lib/table/interface';
import {View} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import {useNavigate} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {Colors} from '../../../../../styles';
import {
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES,
} from '../../../../common/TableTopBar';
import {TableWrapper} from '../../../../common/TableWrapper';
import AddOrUpdateCampaign from '../../CampaignManagement/AddOrUpdateCampaign/AddOrUpdateCampaign';
import {CMSLoading} from '../../CMSLoading';
import {ContentTypes} from '../../ContentManagementConsts';
import {
  deleteTemplate,
  getSortString,
  getTableSortOrderString,
  getTemplateCategories,
  getTemplateCategoryList,
  getTemplates,
  navigateToAutomationScreen,
} from '../../ContentManagementUtils';
import EmailTemplateOptions, {
  EMAIL_TEMPLATE_OPTION_CODES,
} from '../EmailTemplateOptions/EmailTemplateOptions';
import {getEmailTemplatesColumns} from '../EmailTemplatesTable/Helper';
import {
  getCategoryQueryString,
  getEmailEditorRoute,
  getFormattedEmailTemplateData,
  getFormattedSingleEmailTemplate,
} from '../EmailTemplatesUtils';
import {IEmailTemplateData, ITemplateCategory} from '../interfaces';
import {
  EmailApiData,
  EmailApiMetaData,
  IEmailTemplateTableColumnsArgs,
} from './interfaces';
import { filteredFormsCategoryList } from '../../../../../utils/commonUtils';

const EmailTemplatesListView = (props: any) => {
  const [emailTableState, setEmailTableState] = useState({
    isLoading: true,
    isNoDataFound: false,
    searchString: '',
    templatesList: [] as EmailApiData[],
    templatesDisplayList: [] as IEmailTemplateData[],
    templatesMeta: {} as EmailApiMetaData,
    page: 1,
    pageSize: 10,
    categoryList: [] as ITemplateCategory[],
    selectedCategories: [] as FilterValue | null,
    sort: 'desc',
  });
  const {height} = Dimensions.get('window');
  const navigate = useNavigate();

  const [campaignDrawerState, setCampaignDrawerState] = useState<any>({
    isVisible: false,
    channel: undefined,
    templateId: undefined,
  });

  const getEmailTemplates = async () => {
    const path = `${ContentTypes.emails.path}?${getCategoryQueryString(
      emailTableState.categoryList,
      emailTableState.selectedCategories
    )}`;

    const emailResponse = await getTemplates(path, {
      name: emailTableState.searchString,
      page: emailTableState.page,
      pageSize: emailTableState.pageSize,
      sort: emailTableState.sort,
    });

    const categoryResponse = await getTemplateCategories();
    const categoryList = getTemplateCategoryList(categoryResponse);
    const filteredCategories = filteredFormsCategoryList(categoryList)
    const data = getFormattedEmailTemplateData(emailResponse);
    setEmailTableState((prev) => {
      return {
        ...prev,
        templatesDisplayList: data,
        templatesList: emailResponse.data,
        templatesMeta: emailResponse.meta,
        isLoading: false,
        categoryList: filteredCategories,
      };
    });
  };

  useEffect(() => {
    setEmailTableState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });

    try {
      getEmailTemplates();
    } catch (error) {
      setEmailTableState((prev) => {
        return {
          ...prev,
          isLoading: false,
          isNoDataFound: false,
        };
      });

    }
  }, [
    emailTableState.page,
    emailTableState.pageSize,
    emailTableState.searchString,
    emailTableState.selectedCategories,
    emailTableState.sort,
  ]);

  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setEmailTableState((prev) => {
          return {
            ...prev,
            page: 1,
            searchString: actionData?.searchString || '',
          };
        });
        break;
    }
  };

  const onDeleteTemplate = (rowData: IEmailTemplateData) => {
    const templateId = String(rowData.id);
    deleteTemplate(ContentTypes.emails.path, templateId)
      .then((data) => getFormattedSingleEmailTemplate(data))
      .then((deletedTemplate) => {
        const newTemplateData = emailTableState.templatesDisplayList.filter(
          (item) => item.id !== deletedTemplate.id
        );
        const newTemplateList = emailTableState.templatesList.filter(
          (item) => item.id !== deletedTemplate.id
        );
        setEmailTableState((prev) => {
          return {
            ...prev,
            templatesDisplayList: newTemplateData,
            templatesList: newTemplateList,
          };
        });
      });
  };

  const onRowClicked = (rowData: IEmailTemplateData) => {
    const route = getEmailEditorRoute(rowData);
    navigate(route);
  };

  const onEmailTemplatesTableActionPerformed = (
    actionCode: string,
    actionData: IEmailTemplateData
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.DELETE:
        onDeleteTemplate(actionData);
        break;

      case COMMON_ACTION_CODES.ROW_CLICKED:
        onRowClicked(actionData);
        break;

      case COMMON_ACTION_CODES.CREATE_CAMPAIGN:
        setCampaignDrawerState({
          isVisible: true,
          channel: 'email',
          templateId: actionData?.id,
        });
        break;

      case COMMON_ACTION_CODES.CREATE_AUTOMATION:
        navigateToAutomationScreen({
          record: actionData,
          type: 'email',
          navigate,
        });
        break;
    }
  };

  const emailTemplatesTableColumnArgs: IEmailTemplateTableColumnsArgs = {
    actionFn: onEmailTemplatesTableActionPerformed,
    templateCategories: emailTableState.categoryList.map((item) => {
      return {
        text: item.name,
        value: item.name,
      };
    }),
    defaultFilteredValues: emailTableState.selectedCategories as string[],
    defaultSortOrder: getTableSortOrderString(emailTableState.sort),
  };

  const onEmailTemplatesOptionActionPerformed = (code: string) => {
    switch (code) {
      case EMAIL_TEMPLATE_OPTION_CODES.CREATE:
        handleCreateTemplateOption();

        break;
      case EMAIL_TEMPLATE_OPTION_CODES.PASTE_IN_CODE:
        handlePasteTemplateOption();
        break;
      case EMAIL_TEMPLATE_OPTION_CODES.UPLOAD_ZIP:
        handleUploadZipOption();
        break;
    }
  };

  const handleCreateTemplateOption = () => {
    navigate('/admin/contentManagement/emailTemplates/create');
  };

  const handlePasteTemplateOption = () => {
    navigate('/admin/contentManagement/emailTemplates/html');
  };

  const handleUploadZipOption = () => {
    navigate('/admin/contentManagement/emailTemplates/html', {
      state: {
        unZipOption: true,
      },
    });
  };

  useEffect(() => {
    setEmailTableState((prev) => {
      return {
        ...prev,
        page: props.page,
      };
    });
  }, [props.page]);

  useEffect(() => {
    setEmailTableState((prev) => {
      return {
        ...prev,
        searchString: props.searchString,
      };
    });
  }, [props.searchString]);

  return (
    <>
      <View>
        <Drawer
          visible={campaignDrawerState?.isVisible}
          width={Dimensions.get('window').width * 0.7}
          onClose={() => {
            setCampaignDrawerState({
              isVisible: false,
              channel: undefined,
              templateId: undefined,
            });
          }}
          headerStyle={{
            display: 'none',
          }}
        >
          {campaignDrawerState?.isVisible ? (
            <AddOrUpdateCampaign
              shouldShowEdit={true}
              initData={undefined}
              channel={campaignDrawerState?.channel}
              templateId={campaignDrawerState?.templateId}
              close={() => {
                setCampaignDrawerState({
                  isVisible: false,
                  channel: undefined,
                  templateId: undefined,
                });
              }}
            />
          ) : null}
        </Drawer>
        {props.showTableTopBar && (
          <TableTopBar
            title="emailTemplates"
            onActionPerformed={(actionCode: string, actionData: any) => {
              onTableTopBarActionPerformed(actionCode, actionData);
            }}
            buttonList={[
              {
                btnText: 'Create Template',
                id: 2,
                size: 'sm',
                colorScheme: 'primary',
                textColor: 'white',
                variant: '',
                backgroundColor: Colors.primary['500'],
                leftIcon: <Icon name="plus" color={'white'} size={16} />,
                content: (
                  <EmailTemplateOptions
                    onTemplateOptionActionPerformed={
                      onEmailTemplatesOptionActionPerformed
                    }
                  />
                ),
                dropDownType: 'MENU_DROPDOWN',
                btnClick() {
                  // btnClick
                },
              },
            ]}
          />
        )}

        <View
          bgColor={'white'}
          // p={2}
        >
          {emailTableState.templatesDisplayList &&
            !emailTableState.isLoading && (
              <TableWrapper
                hideOnSinglePage={false}
                pagination={{
                  current: emailTableState.page,
                  pageSize: emailTableState.pageSize,
                  total: emailTableState.templatesMeta.pagination.total,
                  onChange: (currentPage, currentPageSize) => {
                    setEmailTableState((prev) => {
                      return {
                        ...prev,
                        page: currentPage,
                        pageSize: currentPageSize,
                      };
                    });
                  },
                }}
              >
                <Table
                  rowClassName={(record, index) =>
                    index % 2 == 0 ? 'table-row-light' : ''
                  }
                  dataSource={emailTableState.templatesDisplayList || []}
                  columns={getEmailTemplatesColumns(
                    emailTemplatesTableColumnArgs
                  )}
                  scroll={{x: 850, y: height - 312}}
                  onRow={(data) => {
                    return {
                      onClick: () => {
                        onEmailTemplatesTableActionPerformed(
                          COMMON_ACTION_CODES.ROW_CLICKED,
                          data
                        );
                      },
                    };
                  }}
                  onChange={(pagination, filters, sorter: any) => {
                    const categories = filters.templateCategory;
                    setEmailTableState((prev) => {
                      return {
                        ...prev,
                        selectedCategories: categories,
                        sort: getSortString(sorter['order']),
                      };
                    });
                  }}
                  rowKey={(row) => row.id as string}
                  pagination={false}
                />
              </TableWrapper>
            )}

          {emailTableState.isLoading && <CMSLoading />}
        </View>
      </View>
    </>
  );
};

export default EmailTemplatesListView;
