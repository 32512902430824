import {getDateStrFromFormat} from "../../../../../utils/DateUtils";
import {IDrillDownColumnList} from "../interface";
import {CURRENT_FORMAT, DATA_TYPE} from "./EmployerConst";

export const getColumnValueByDataType = (value: any, item: IDrillDownColumnList) => {
  if ( value === null) {
    return '-'
  }
  let valueData = value
  if (item.DATA_TYPE === DATA_TYPE.STRING) {
    valueData = value;
  } else if (item.DATA_TYPE === DATA_TYPE.DATE) {
    if (item?.DISPLAY_DATE_FORMAT) {
      valueData = getDateStrFromFormat(value,item?.DISPLAY_DATE_FORMAT)
    } else {
      valueData = getDateStrFromFormat(value);
    }
  } else if (item.DATA_TYPE === DATA_TYPE.DURATION) {
    if (item.CURRENT_FORMAT) {
      valueData = value + ' ' + getDurationSuffix(item.CURRENT_FORMAT)
    } else {
      valueData = value;
    }
  }
  return  valueData
}

export const getDurationSuffix = (format: string) => {
   if (format === CURRENT_FORMAT.MIN) {
    return  'min'
   } else if (format === CURRENT_FORMAT.SEC) {
    return 'sec'
   }
   return ''
}
