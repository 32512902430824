import React from "react";

function CarePlanStatusComplete() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#E8FDF3"
        d="M.5 8.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0z"
      ></path>
      <path
        stroke="#12B76A"
        d="M.5 8.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0z"
      ></path>
      <path
        stroke="#12B76A"
        strokeLinecap="round"
        d="M4.889 8.54l1.493 1.66a.667.667 0 00.967.026l3.503-3.503"
      ></path>
    </svg>
  );
}

export default React.memo(CarePlanStatusComplete);