import {IGroupConversation, IGroupMember} from './../../../../../../services/Conversations/interfaces';
import {GROUP_TYPE_CODES} from '../../../../../../constants/MlovConst';
import {IContact, INewConversationResponse} from '../../interfaces';
import {GROUP_MEMBER_TYPE} from '../../../../../../constants/StringConst';

export const isSearchStringMatchedWithReceivedConversation = (searchString: string, receivedConversation: INewConversationResponse) => {
  const conversationData = receivedConversation?.conversations?.[0] || receivedConversation?.conversationMentions?.[0]?.conversation;
  let isSearchStringMatched = false;
  if (searchString && conversationData?.id) {
    if (conversationData?.inboxId > 0 && conversationData?.conversationContact?.name) {
      isSearchStringMatched = isConversationContactNameMatched(searchString, conversationData?.conversationContact);
    } else if (conversationData?.groupConversation?.groupMembers?.length) {
      isSearchStringMatched = isSearchStringMatchedInGroup(searchString, conversationData?.groupConversation);
    } else {
      isSearchStringMatched = true;
    }
  } else if (!searchString) {
    isSearchStringMatched = true;
  }
  return isSearchStringMatched;
}


const isSearchStringMatchedInGroup = (searchString: string, groupConversation: IGroupConversation) => {
  const groupCode = groupConversation?.groupType?.code;
  let isSearchStringMatched = false;
  switch (groupCode) {
    case GROUP_TYPE_CODES.PRIVATE:
      isSearchStringMatched = isSearchStringMatchedInPrivateGroup(searchString, groupConversation);
      break;
    case GROUP_TYPE_CODES.INTERNAL:
      isSearchStringMatched = isSearchStringMatchedInInternalGroup(searchString, groupConversation);
      break;
    case GROUP_TYPE_CODES.USER_BROADCAST:
      isSearchStringMatched = isSearchStringMatchedInUserBroadcastGroup(searchString, groupConversation);
      break;
    case GROUP_TYPE_CODES.PATIENT_BROADCAST:
      isSearchStringMatched = isSearchStringMatchedInPatientBroadcastGroup(searchString, groupConversation);
      break;
  }
  return isSearchStringMatched;
}

const isSearchStringMatchedInPrivateGroup = (searchString: string, groupConversation: IGroupConversation) => {
  const isPatientNameMatched = isPatientNameMatchedInGroupMembers(searchString, groupConversation?.groupMembers || []);
  const isGroupNameMatched = isGroupNameMatchedInGroupConversation(searchString, groupConversation);
  return (isPatientNameMatched || isGroupNameMatched);
}



const isSearchStringMatchedInInternalGroup = (searchString: string, groupConversation: IGroupConversation) => {
  const isUserNameMatched = isOnlyGroupUserNameMatched(searchString, groupConversation?.groupMembers || []);
  const isGroupNameMatched = isGroupNameMatchedInGroupConversation(searchString, groupConversation);
  return (isUserNameMatched || isGroupNameMatched);
}

const isSearchStringMatchedInUserBroadcastGroup = (searchString: string, groupConversation: IGroupConversation) => {
  const isUserNameMatched = isOnlyGroupUserNameMatched(searchString, groupConversation?.groupMembers || []);
  const isGroupNameMatched = isGroupNameMatchedInGroupConversation(searchString, groupConversation);
  return (isUserNameMatched || isGroupNameMatched);
}

const isSearchStringMatchedInPatientBroadcastGroup = (searchString: string, groupConversation: IGroupConversation) => {
  const isPatientNameMatched = isPatientNameMatchedInGroupMembers(searchString, groupConversation?.groupMembers || []);
  const isGroupNameMatched = isGroupNameMatchedInGroupConversation(searchString, groupConversation);
  return (isPatientNameMatched || isGroupNameMatched);
}

const isGroupNameMatchedInGroupConversation = (searchString: string, groupConversation: IGroupConversation) => {
  let isSearchStringMatched = false;
  if (groupConversation?.name && groupConversation?.name?.includes(searchString)) {
    isSearchStringMatched = true;
  }
  return isSearchStringMatched;
}


const isPatientNameMatchedInGroupMembers = (searchString: string, groupMembers: IGroupMember[]) => {
  const isSearchStringMatched = groupMembers?.some((singleGroupMember) => {
    if (singleGroupMember?.groupUserType?.code === GROUP_MEMBER_TYPE?.PATIENT) {
      return singleGroupMember?.contact?.name?.includes(searchString);
    }
    return false;

  });
  return isSearchStringMatched;
}

const isGroupPatientOrFamilyMemberNameMatched = (searchString: string, groupMembers: IGroupMember[]) => {
  const isSearchStringMatched = groupMembers?.some((singleGroupMember) => {
    if (singleGroupMember?.groupUserType?.code === GROUP_MEMBER_TYPE?.PATIENT) {
      return singleGroupMember?.contact?.name?.includes(searchString);
    } else if (singleGroupMember?.groupUserType?.code === GROUP_MEMBER_TYPE?.CONTACT) {
      return singleGroupMember?.contact?.name?.includes(searchString);
    }
    return false;

  });
  return isSearchStringMatched;
}

const isAnyGroupMembersNameMatched = (searchString: string, groupMembers: IGroupMember[]) => {
  const isSearchStringMatched = groupMembers?.some((singleGroupMember) => {
    if (singleGroupMember?.groupUserType?.code === GROUP_MEMBER_TYPE?.PATIENT) {
      return singleGroupMember?.contact?.name?.includes(searchString);
    } else if (singleGroupMember?.groupUserType?.code === GROUP_MEMBER_TYPE?.CONTACT) {
      return singleGroupMember?.contact?.name?.includes(searchString);
    } else if (singleGroupMember?.groupUserType?.code === GROUP_MEMBER_TYPE?.USER) {
      return singleGroupMember?.user?.name?.includes(searchString);
    }
    return false;

  });
  return isSearchStringMatched;
}

const isOnlyGroupUserNameMatched = (searchString: string, groupMembers: IGroupMember[]) => {
  const isSearchStringMatched = groupMembers?.some((singleGroupMember) => {
    if (singleGroupMember?.groupUserType?.code === GROUP_MEMBER_TYPE?.USER) {
      return singleGroupMember?.user?.name?.includes(searchString);
    }
    return false;
  });
  return isSearchStringMatched;
}

const isConversationContactNameMatched = (searchString: string, conversationContact: IContact) => {
  let isSearchStringMatched = false;
  if (conversationContact?.name && conversationContact?.name?.includes(searchString)) {
    isSearchStringMatched = true;
  }
  return isSearchStringMatched;
}
