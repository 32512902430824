import { FC, createContext, useState } from 'react';
import {ISort} from './interfaces';

export interface IFilterDataContextProvider {
  children: JSX.Element;
}

export interface IFilterDataContext {
  filter?: any;
  sort?: ISort;
  searchString?: string;
  type: string;
  saveFilterData?: (data: any) => void,
  clearFilterData?: () => void,
  page: number,
  pageSize: number,
  appliedFilters: string[] 
}

export const defaultData: IFilterDataContext = {
  filter: {},
  searchString: '',
  page: 0,
  pageSize: 12,
  type: '',
  appliedFilters: []
};

export const FilterDataContext = createContext(defaultData);

export const FilterDataContextProvider: FC<IFilterDataContextProvider> = ({
  children,
}) => {

  const [filterData, setFilterData] = useState(defaultData)

  const saveFilterData = ((data: any)=> {  
  
    setFilterData((prev)=> {
      return {
        ...prev,
        ...data
      }
    })
  })

  const clearFilterData = (() => { 
    setFilterData((prev)=> {
      return {
        ...prev,
        ...defaultData
      }
    })
  })

  return (
    <FilterDataContext.Provider value={{
      ...filterData,
      saveFilterData,
      clearFilterData
    }}>{children}</FilterDataContext.Provider>
  );
};
