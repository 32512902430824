import {FormControl, Select, Text} from 'native-base';
import {useState} from 'react';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {styles} from '../../../CampaignStyles';

export const FromUser = () => {
  const [fromUser, setFromUser] = useState('');
  return (
    <FormControl style={styles.selectScreenTwo}>
      <FormControl.Label>
        <DisplayText textLocalId="from" />
        <Text>:</Text>
      </FormControl.Label>
      <Select
        selectedValue={fromUser}
        accessibilityLabel="Choose Users"
        placeholder="Choose Users"
        onValueChange={(itemValue) => setFromUser(itemValue)}
      >
        <Select.Item label="Bhavesh Patel" value="Bhavesh Patel" />
      </Select>
    </FormControl>
  );
};
