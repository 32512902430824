import {View} from 'native-base';
import {Colors} from '../../../styles';
import {commonBodyContentContainer} from '../../../styles/CommonBodyContentStyle';
import { getAccountUUID } from '../../../utils/commonUtils';
import CustomTabsView from '../../common/CustomTabsView/CustomTabsView';
import {ITabsList} from '../../common/CustomTabsView/interfaces';
import TitleSubtitleView from '../../common/TitleSubtitleView/TitleSubtitleView';
import {AppointmentWidget} from './AppointmentWidget';
import {SignUpWidget} from './SignUpWidget';

const PatientPortalView = () => {
  const accountUuid = getAccountUUID();

  const tabList = (): ITabsList[] => {
    return [
      {
        key: 'signUpWidget',
        title: 'Sign Up Widget',
        path: 'signUpWidget',
        tabContainerElem: () => {
          return <SignUpWidget accountUuid={accountUuid}  />;
        },
      },
      {
        key: 'appointmentWidget',
        title: 'Appointment Widget',
        path: 'appointmentWidget',
        tabContainerElem: () => {
          return <AppointmentWidget accountUuid={accountUuid} />;
        },
      },
    ];
  };

  return (
    <View bgColor={'white'}>
      {/* <View>
        <TitleSubtitleView titleLabelId="webWidgets" />
      </View> */}
      <View style={[commonBodyContentContainer.pageContentContainer]}>
        <CustomTabsView
          contentTopMargin={0}
          showHeading
          heading='widgetTitle'
          tabsList={tabList()}
          basePath="/admin/patientPortal"
          bottomOffset={130}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
        />
      </View>
    </View>
  );
};

export default PatientPortalView;
