import React from 'react';
import Svg, { Path } from "react-native-svg";

const MemberSvg = () => {
  return (
    <Svg
      width="12"
      height="14"
      viewBox="0 0 14 12"
      fill="none"
    >
      <Path
        d="M9.111 10.75V9.69444C9.111 9.13454 8.88858 8.59757 8.49267 8.20166C8.09676 7.80575 7.55979 7.58333 6.99989 7.58333H3.30545C2.74555 7.58333 2.20858 7.80575 1.81267 8.20166C1.41676 8.59757 1.19434 9.13454 1.19434 9.69444V10.75M11.2221 3.88889V7.05556M12.8054 5.47222H9.63878M7.26378 3.36111C7.26378 4.52705 6.3186 5.47222 5.15267 5.47222C3.98674 5.47222 3.04156 4.52705 3.04156 3.36111C3.04156 2.19518 3.98674 1.25 5.15267 1.25C6.3186 1.25 7.26378 2.19518 7.26378 3.36111Z"
        stroke="#667085"
        stroke-width="0.666667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default MemberSvg;
