import { StyleSheet } from "react-native";
import { baseMargin } from "../../TaxonomiesListView/TaxonomiesListViewStyles";

export const styles = StyleSheet.create({
    spinnerStyle: {
        margin: '0 auto',
        color: 'black',
        position: 'absolute',
        zIndex: 100011111,
        backgroundColor: '#0000000f',
        height: '100%',
        width: '100%',
        padding: 0
      },
      loadingView: {
        ...baseMargin,
      },
      formElement: {
        paddingTop: 10,
        paddingBottom: 10,
      },
      formLabel: {
        marginLeft: 4,
      },
})