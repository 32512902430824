import React from 'react';
import {Colors} from '../../../../styles/Colors';
import {ICommonSvgProps} from '../interfaces';

const OpportunitySvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected ? Colors.primary['500'] : '#667085';

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 3C3 1.89543 3.89543 1 5 1H19C20.1046 1 21 1.89543 21 3V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V3ZM19 3H5V21H19V3ZM9 5C9.55228 5 10 5.44772 10 6V7H17C17.5523 7 18 7.44772 18 8C18 8.55228 17.5523 9 17 9H10V11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H10V15H17C17.5523 15 18 15.4477 18 16C18 16.5523 17.5523 17 17 17H10V18C10 18.5523 9.55228 19 9 19C8.44772 19 8 18.5523 8 18V17H7C6.44772 17 6 16.5523 6 16C6 15.4477 6.44772 15 7 15H8V13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H8V9H7C6.44772 9 6 8.55228 6 8C6 7.44772 6.44772 7 7 7H8V6C8 5.44772 8.44772 5 9 5Z"
        fill="#fff"
      />
    </svg>
  );
};

export default React.memo(OpportunitySvg);
