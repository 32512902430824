import React from 'react';
import {Path, Svg} from 'react-native-svg';
import {Colors} from '../../styles';
import {ICommonSvgProps} from '../../components/common/Svg/interfaces';

export const ErrorCircleIconSvg = (props: ICommonSvgProps) => {
  const size = props.size || 16;
  return (
    <Svg width="12" height="11" viewBox="0 0 12 11" fill="none">
      <Path
        d="M6 3V6M6 7.25V7.5M1 5.5C1 2.73858 3.23858 0.5 6 0.5C8.76142 0.5 11 2.73858 11 5.5C11 8.26142 8.76142 10.5 6 10.5C3.23858 10.5 1 8.26142 1 5.5Z"
        stroke="#D92D20"
        strokeLinecap="round"
      />
    </Svg>
  );
};
