import {StyleSheet} from 'react-native';
import { Colors } from '../../../styles';
export const styles = StyleSheet.create({
  pageTitle: {
    fontWeight: '500',
    fontSize: 20,
    lineHeight: 24,
    color: '#101828',
  },
  pageSubtitle: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    color: Colors.Custom.Gray500,
  },
  pageTitleContainer: {
    padding: 32,
    paddingTop: 16,
    paddingBottom: 16,
    backgroundColor: '#FFF',
  },
});
