import { models } from "powerbi-client";
import {POWER_BI_REPORT_ERROR} from "../../../../../constants";
import {IPowerBiCreateReportFormRef, IPowerBiReportFormKeys, IPowerBIFilter} from "./interface";

export const getUpdatedPowerBiErrorMessages = (powerBiCreateFormRef: React.MutableRefObject<IPowerBiCreateReportFormRef>): any => {
  let isErrorPresent = false;
  const errorsObj: any = {
  };

  if (powerBiCreateFormRef?.current && Object.keys(powerBiCreateFormRef?.current)?.length) {
    const formElemKeys: any[] = Object.keys(powerBiCreateFormRef?.current);
    formElemKeys.forEach((singleKey: IPowerBiReportFormKeys) => {
      const formFieldRef = powerBiCreateFormRef?.current[singleKey];
      if (formFieldRef?.value) {
        errorsObj[singleKey] = '';
      } else {
        isErrorPresent = true;
        errorsObj[singleKey] = POWER_BI_REPORT_ERROR[singleKey];
      }
    });
  }
  return {
    errors: {
      ...errorsObj,
    },
    isErrorPresent,
  };
};

export const getVisualFilterTableName = (filters: IPowerBIFilter[]) => {
  const tableCount: {[key: string]: number} = {};
  let tableWithMaxCount = '';
  if (filters.length) {
    let maxCount = 0;
    filters.forEach((filter: IPowerBIFilter) => {
      const tableName = filter.target.table;
      if (tableCount[tableName]) {
        tableCount[tableName]++;
      } else {
        tableCount[tableName] = 1;
      }
    });
    for (const table in tableCount) {
      if (tableCount[table] > maxCount) {
        maxCount = tableCount[table];
        tableWithMaxCount = table;
      }
    }
  }
  return tableWithMaxCount;
}

export async function getDateRangeFilter(visual: any, tableName: string): Promise<IPowerBIFilter[]> {
  try {
      const existingFilters = await visual.getFilters();
      const dateRangeFilters: IPowerBIFilter[] = existingFilters.filter((filter: IPowerBIFilter) =>
          filter.target.table === tableName &&
          (filter.target.column === "FromDate" || filter.target.column === "ToDate")
      );
      return dateRangeFilters;
  } catch (error) {
      console.error('Error retrieving existing filters from visual:', error);
      return [];
  }
}

/**
 * Function to apply a dynamic date range filter to a Power BI visual
 * @param fromDate - The start date of the filter range
 * @param toDate - The end date of the filter range
 * @param visual - The Power BI visual to which the filter will be applied
 * @param tableName - The name of the table to be filtered
 */
export async function applyDateRangeFilter(fromDate: Date, toDate: Date, visual: any, tableName: string, formId: string, employerId?: string): Promise<void> {
  try {
    // Get all existing filters from the visual
    let existingFilters = await visual.getFilters();
    let customFilters: IPowerBIFilter[] = [];
    // Construct the new date range filters
    if (tableName !== 'FormComponentAssociation') {
      customFilters = [
        {
          $schema: 'http://powerbi.com/product/schema#advanced',
          target: {
            table: tableName,
            column: 'FromDate'
          },
          filterType: 0,
          logicalOperator: 'And',
          conditions: [
            {
              operator: 'GreaterThanOrEqual',
              value: fromDate.toISOString()
            }
          ]
        },
        {
          $schema: 'http://powerbi.com/product/schema#advanced',
          target: {
            table: tableName,
            column: 'ToDate'
          },
          filterType: 0,
          logicalOperator: 'And',
          conditions: [
            {
              operator: 'LessThanOrEqual',
              value: toDate.toISOString()
            }
          ]
        }
      ];
    }
    let existingFormIdFilter: IPowerBIFilter = existingFilters.find((filter: IPowerBIFilter) =>
        (filter.target.column === 'FromId')
    );
    existingFilters = existingFilters.filter((filter: IPowerBIFilter) =>
      (filter.target.column !== 'FromId')
    );
    if (formId) {
      existingFormIdFilter = {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: tableName,
          column: 'FormId'
        },
        filterType: 1,
        operator: 'In',
        values: [
          formId
        ],
        requireSingleSelection: false
      };
      customFilters?.push(existingFormIdFilter);
    }

    // Add employerId filter if provided
    if (employerId && employerId !== 'SELECT_ALL' && tableName !== 'FormComponentAssociation') {
      const employerIdFilter: IPowerBIFilter = {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: tableName,
          column: 'EmployerId'
        },
        filterType: 1,
        operator: 'In',
        values: [
          employerId
        ],
        requireSingleSelection: false
      };
      customFilters?.push(employerIdFilter);
    }

    // Remove existing date filters and keep non-date filters
    const nonDateFilters = existingFilters.filter((filter: IPowerBIFilter) =>
      !(filter.target.table === tableName &&
        (filter.target.column === 'FromDate' || filter.target.column === 'ToDate' || filter.target.column === 'FormId' || filter.target.column === 'EmployerId'))
    );

    // Combine non-date filters with new date filters
    const updatedFilters = [...nonDateFilters, ...customFilters];
    await visual.updateFilters(models.FiltersOperations.RemoveAll);

    // Apply the combined filters to the visual
    await visual.updateFilters(models.FiltersOperations.ReplaceAll, [...updatedFilters]);

    console.log('Date range filter applied successfully:', updatedFilters);
  } catch (error) {
    console.error('Error applying date range filter to visual:', error);
  }
}
