import {useLazyQuery} from '@apollo/client';
import {Button, HStack, Pressable, VStack} from 'native-base';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {LeadQueries} from '../../../services';
import WorkflowExecutionLogQueries from '../../../services/WorkflowExecutionLog/WorkflowExecutionLogQueries';
import {Colors} from '../../../styles';
import {getAccountId} from '../../../utils/commonUtils';
import {AnalyticsWidget} from '../../RightSideContainer/Analytics/AnalyticsWidget';
import FilterIconSvg from '../Svg/FilterIconSvg';
import FilterNewIconSvg from '../Svg/FilterNewIconSvg';
import {CustomAgeFilter} from './CustomAgeFilter';
import {
  getFinalQuery,
  initialGraphData,
} from './CustomAgeFilter/CustomAgeFilterUtils';
import {GraphMemberList} from './GraphMemberList';
import {GraphConfig} from './GraphUtils';

const AgeGroupsChart = (props: {employerId?: string}) => {
  const tenantId = getAccountId();
  const [ageDataList, setAgeDataList] = useState([]);
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 1;
  const [contactsState, setContactsState] = React.useState({
    isLoading: false,
    isOpen: false,
    membersData: [] as any,
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
    toDate: '',
    fromDate: '',
    openContactListDrawer: false,
    contactUuidList: [] as string[],
    isCustomerFilterOpen: false,
    finalQuery: getFinalQuery({
      data: initialGraphData,
      tenantId,
      employerId: props.employerId,
    }),
    graphLoading: false,
    graphData: initialGraphData,
  });

  const graphDataRef = useRef<any[]>(initialGraphData);

  const [getAgeDataList] = useLazyQuery(
    WorkflowExecutionLogQueries.getDynamicQuery(contactsState.finalQuery),
    {
      onCompleted(response) {
        const ageDataList: any = [];
        contactsState.graphData.forEach((item: any) => {
          if (response[item.code]?.aggregate?.count > 0) {
            ageDataList.push({
              value: response[item.code]?.aggregate?.count,
              type: item.type,
            });
          }
        });
        setContactsState((prev) => {
          return {
            ...prev,
            graphLoading: false,
          };
        });
        setAgeDataList(ageDataList);
      },
    }
  );

  const [getContacts] = useLazyQuery<{contacts: any[]}, any>(
    LeadQueries.GetContactsByFromDateToDate,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (contactsState.isOpen) {
      getContactsList();
    }
  }, [contactsState.isOpen, contactsState.page, contactsState.pageSize]);

  useEffect(() => {
    setContactsState((prev) => {
      return {
        ...prev,
        graphLoading: true,
      };
    });
    getAgeDataList();
  }, [contactsState.finalQuery]);

  const getContactsList = () => {
    setContactsState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const boolExpression: any = {
      accountId: {_eq: tenantId},
      person: {
        birthDate: {_gte: contactsState.fromDate, _lte: contactsState.toDate},
      },
    };
    if (props.employerId) {
      boolExpression.employees = {
        employerId: {
          _eq: props.employerId,
        },
      };
    }
    getContacts({
      variables: {
        offset: (contactsState.page - 1) * contactsState.pageSize,
        limit: contactsState.pageSize,
        boolExpression: boolExpression,
      },
    })
      .then((contactRespData) => {
        setContactsState((prev) => {
          const contactUuidList: any = [];
          contactRespData?.data?.contacts.forEach((item: any) => {
            if (item?.uuid) {
              contactUuidList.push(item?.uuid);
            }
          });
          return {
            ...prev,
            isLoading: false,
            contactUuidList,
            openContactListDrawer: contactUuidList.length > 0,
          };
        });
      })
      .catch(() => {
        alert('error');
        setContactsState((prev) => {
          return {
            ...prev,
            membersData: [],
            isLoading: true,
          };
        });
      });
  };

  const config = {
    data: ageDataList,
    angleField: 'value',
    colorField: 'type',
    ...GraphConfig,
    onReady: (plot: any) => {
      plot.on('element:click', (args: any) => {
        handleGraphClick(args);
      });
    },
  };

  const handleGraphClick = (args: any) => {
    if (args?.data?.data?.type) {
      graphDataRef.current.map((item: any) => {
        if (item.type === args?.data?.data?.type) {
          setContactsState((prev) => {
            return {
              ...prev,
              isOpen: true,
              toDate: item.toDate,
              fromDate: item.fromDate,
              total: args?.data?.data?.value,
            };
          });
        }
      });
    }
  };

  const handleResetFilter = () => {
    const query = getFinalQuery({
      data: initialGraphData,
      tenantId: tenantId || '',
      employerId: props.employerId,
    });
    setContactsState((prev) => {
      return {
        ...prev,
        finalQuery: query,
        graphData: initialGraphData,
      };
    });
  };

  const getPieChart = useMemo(() => {
    return (
      <AnalyticsWidget
        pieConfig={{
          ...config,
        }}
        height="358px"
        legend={{
          position: 'bottom',
          layout: 'horizontal',
          maxRow: 3,
          title: {
            title: undefined,
            spacing: 10,
            style: {fontSize: 0, lineWidth: 0, lineHeight: 0},
          },
        }}
        title="Age Groups"
        subtitle="Age Groups of Active and Inactive members"
        searchComponent={
          <HStack space={'2'}>
            <Pressable
              style={[
                {
                  width: 32,
                  height: 32,
                  justifyContent: 'center',
                  marginHorizontal: 2,
                },
              ]}
              _focus={{
                borderColor: 'black',
              }}
              onPress={() => {
                setContactsState((prev) => {
                  return {
                    ...prev,
                    isCustomerFilterOpen: true,
                  };
                });
              }}
            >
              <FilterNewIconSvg
                isEnabled={true}
              />
            </Pressable>
            <Pressable
              style={{
                height: 32,
                width: 32,
                borderRadius: 18,
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onPress={() => {
                handleResetFilter();
              }}
            >
              <MaterialCommunityIcon
                name="reload"
                size={18}
                color={Colors.Custom.foldIconColor}
              />
            </Pressable>
          </HStack>
        }
      />
    );
  }, [ageDataList]);

  return (
    <VStack style={{flex: 1}}>
      {getPieChart}

      {contactsState.openContactListDrawer && (
        <GraphMemberList
          page={contactsState.page}
          pageSize={contactsState.pageSize}
          total={contactsState.total}
          onPaginationChange={(page: number, pageSize: number) => {
            setContactsState((prev) => {
              return {
                ...prev,
                page,
                pageSize,
              };
            });
          }}
          title={'Member by Age'}
          isOpen={contactsState.openContactListDrawer}
          contactUuidList={contactsState.contactUuidList}
          onCompleteAction={() => {
            setContactsState((prev) => {
              return {
                ...prev,
                contactList: [],
                page: DEFAULT_PAGE,
                pageSize: DEFAULT_PAGE_SIZE,
                selectedSegmentType: '',
                isOpen: false,
                openContactListDrawer: false,
                contactUuidList: [],
              };
            });
          }}
        />
      )}

      {contactsState.isCustomerFilterOpen && (
        <CustomAgeFilter
          ageFilterData={contactsState.graphData}
          isOpen={contactsState.isCustomerFilterOpen}
          onClose={() => {
            setContactsState((prev) => {
              return {
                ...prev,
                isCustomerFilterOpen: false,
              };
            });
          }}
          onSave={(filterData) => {
            const query = getFinalQuery({
              data: filterData,
              tenantId: tenantId || '',
              employerId: props.employerId,
            });
            graphDataRef.current = filterData;
            setContactsState((prev) => {
              return {
                ...prev,
                finalQuery: query,
                graphData: filterData,
              };
            });
          }}
        />
      )}
    </VStack>
  );
};

export default AgeGroupsChart;
