import { View } from 'native-base';
import { useEffect, useState } from 'react';
import { getActiveLivesContactList, getActiveLivesCount } from '../../../../services/Billing/BillingServices';
import {
  getAccountId,
  getAccountUUID, getUserEmployerId
} from '../../../../utils/commonUtils';
import { GraphMemberList } from '../../../common/AppDashboard/GraphMemberList';
import { GraphConfig } from '../../../common/AppDashboard/GraphUtils';
import { GRAPH_COLOR_CONSTS } from '../AnalyticsConsts';
import { AnalyticsWidget } from '../AnalyticsWidget';
import { } from './ActiveLivesUtils';

const ActiveLivesGraph = (props: {
  employerId?: string;
  isDashboard?: boolean;
}) => {
  const employerId = getUserEmployerId() || props.employerId;
  const accountUuid = getAccountUUID();
  const accountId = getAccountId();
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 1;
  const [state, setState] = useState({
    loading: false,
    error: false,
    total: 0,
    loggedIn: 0,
    notLoggedIn: 0,
    percent: 0,
    isOpen: false,
    selectedSegmentType: '',
    contactList: [] as any[],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    listLoading: false,
    openContactListDrawer: false,
    contactUuidList: [] as string[],
  });

  const [config, setConfig] = useState<any>();
  const GRAPH_COLUMN:any = {
    EXISTING_USERS: {
      display: 'Existing Users',
      code: 'existingUsers',
      segmentCode: 'Existing Lives'
    },
    NEW_USERS: {
      display: 'New Users',
      code: 'newUsers',
      segmentCode: 'Active Lives'
    }
  }
  const getActiveLivesStat = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const activeLivesCountResponse:any = await getActiveLivesCount(accountId || undefined, employerId);
      const activeLivesCount: {existingUsers:number, newUsers:number} = activeLivesCountResponse.data;
      const existingUsers = activeLivesCount?.existingUsers || 0;
      const newUsers = activeLivesCount?.newUsers || 0;
      setState((prev) => {
        return {
          ...prev,
          existingUsers: existingUsers,
          newUsers: newUsers,
          total: newUsers + existingUsers,
          loading: false,
        };
      });

      
      
      const graphData: { count: number; name: string; color: string }[] = [
        {
          count: existingUsers,
          name: GRAPH_COLUMN.EXISTING_USERS.display,
          color: GRAPH_COLOR_CONSTS.POSITIVE,
        },
        {
          count: newUsers,
          name: GRAPH_COLUMN.NEW_USERS.display,
          color: GRAPH_COLOR_CONSTS.NEGATIVE,
        },
      ];
      const config = {
        data: graphData,
        angleField: 'count',
        colorField: 'name',
        ...GraphConfig,
        onReady: (plot: any) => {
          plot.on('element:click', (args: any) => {
            const name = args.data.data.name;
            setState((prev) => {
              return {
                ...prev,
                isOpen: true,
                page:DEFAULT_PAGE,
                pageSize:DEFAULT_PAGE_SIZE,
                selectedSegmentType: name,
                total: args?.data?.data?.count,
              };
            });
          });
        },
      };
      setConfig(config)
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          list: [],
        };
      });
    }
  };



  useEffect(() => {
    getActiveLivesStat();
  }, [employerId]);


  const setContactUuidList =  async () =>{
    const offset = (state.page-1) * state.pageSize;
    const limit = state.pageSize;
    let segmentCode;
    Object.keys(GRAPH_COLUMN).some((key)=>{
      if(GRAPH_COLUMN[key].display == state.selectedSegmentType){
        segmentCode = GRAPH_COLUMN[key].segmentCode;
        return true;
      }
    });
    if(segmentCode){
      const data:any = {
        segmentCode: segmentCode,
        type: segmentCode,
        offset: offset,
        limit: limit
      }
      if(employerId){
        data.employerId = employerId;
      }
      const contactUuidListResponse = await getActiveLivesContactList(data);
      const contactUuidList = contactUuidListResponse.data?.map((response:any)=>{return response?.uuid}).filter((uuid:string)=>{return !!uuid}) ||[];
      setState((prev) => {
        return {
          ...prev,
          openContactListDrawer: true,
          contactUuidList:contactUuidList
        };
      });
    }

  }

  useEffect(() => {
    if (state.selectedSegmentType.length > 0 && state.isOpen) {
      setState((prev) => {
        return {
          ...prev,
          listLoading: true
        };
      });
      setContactUuidList()
    }
  }, [state.selectedSegmentType, state.page, state.pageSize, state.isOpen, employerId]);



  return (
    <View flex={1}>
      {config && <AnalyticsWidget
        pieConfig={{
          ...config,
        }}
        height="358px"
        legend={{
          position: 'bottom',
          layout: 'horizontal',
          maxRow: 3,
          title: {
            title: undefined,
            spacing: 10,
            style: { fontSize: 0, lineWidth: 0, lineHeight: 0 },
          },
        }}
        title="Active Lives"
        subtitle="Breakup of lives before and after Fold go-live"
      />
      }

      {state.openContactListDrawer && (
        <GraphMemberList
          page={state.page}
          pageSize={state.pageSize}
          onPaginationChange={(page: number, pageSize: number) => {

            setState((prev) => {
              return {
                ...prev,
                page,
                pageSize
              }
            });
          }}
          total={state.total}
          title={'Active Lives'}
          isOpen={state.openContactListDrawer}
          contactUuidList={state.contactUuidList}
          onCompleteAction={() => {
            setState((prev) => {
              return {
                ...prev,
                contactList: [],
                page: DEFAULT_PAGE,
                pageSize: DEFAULT_PAGE_SIZE,
                selectedSegmentType: '',
                openContactListDrawer: false,
                isOpen: false,
                contactUuidList: [],
              };
            });
          }}
        />
      )}
    </View>
  );
};

export default ActiveLivesGraph;
