
const ClockIcon = () => {

    return (
        <svg width="75" height="76" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1276_31511)">
                <path d="M23.7916 7.64585C21.4921 5.81955 18.6036 4.89813 15.6713 5.05549C12.739 5.21285 9.96577 6.43811 7.87492 8.50001C5.80134 10.5873 4.57324 13.368 4.42708 16.3065C4.28092 19.2451 5.22703 22.1339 7.08326 24.4167C10.426 16.9792 16.3667 11.0164 23.7916 7.64585Z" fill="#825AC7" />
                <path d="M39.2918 20.2916C39.2918 19.7943 39.0942 19.3174 38.7426 18.9658C38.3909 18.6142 37.914 18.4166 37.4167 18.4166C36.9195 18.4166 36.4426 18.6142 36.0909 18.9658C35.7393 19.3174 35.5417 19.7943 35.5417 20.2916V39.0416L50.2501 46.3333C50.6976 46.5515 51.2136 46.5831 51.6844 46.4209C52.1551 46.2588 52.5422 45.9163 52.7605 45.4687C52.9788 45.0212 53.0103 44.5052 52.8481 44.0344C52.686 43.5636 52.3435 43.1765 51.8959 42.9583L39.2709 36.7083L39.2918 20.2916Z" fill="#825AC7" />
                <path d="M58.3334 58.3749C62.1494 54.5092 64.8004 49.6475 65.9831 44.3458C67.1657 39.0442 66.8323 33.5167 65.0209 28.3957C63.5693 28.6524 62.0872 28.6875 60.625 28.4999C62.8205 33.8441 63.0971 39.7839 61.4079 45.309C59.7186 50.8342 56.1678 55.6037 51.3592 58.8066C46.5506 62.0095 40.7811 63.448 35.0317 62.8775C29.2823 62.307 23.908 59.7627 19.8226 55.6773C15.7372 51.5919 13.1929 46.2176 12.6224 40.4682C12.052 34.7188 13.4904 28.9493 16.6933 24.1407C19.8962 19.3321 24.6657 15.7813 30.1909 14.0921C35.7161 12.4028 41.6558 12.6794 47 14.8749C46.921 14.2529 46.8792 13.6268 46.875 12.9999C46.8833 12.1548 46.9599 11.3118 47.1042 10.4791C40.8802 8.33915 34.1155 8.36721 27.9094 10.5587C21.7034 12.7501 16.421 16.9762 12.9209 22.5499C9.42073 28.1236 7.90859 34.7172 8.63025 41.2591C9.35192 47.801 12.265 53.9065 16.8959 58.5832L11.5834 63.8957C11.1938 64.2935 10.9783 64.8298 10.9841 65.3866C10.99 65.9433 11.2168 66.4749 11.6146 66.8645C12.0124 67.254 12.5487 67.4696 13.1055 67.4637C13.6622 67.4578 14.1938 67.231 14.5834 66.8332L20.125 61.2916C25.1928 65.1265 31.3814 67.1878 37.7366 67.1577C44.0917 67.1276 50.2605 65.0077 55.2917 61.1249L61 66.8332C61.3986 67.1745 61.9112 67.3529 62.4356 67.3326C62.9599 67.3124 63.4573 67.095 63.8283 66.724C64.1993 66.353 64.4167 65.8556 64.4369 65.3313C64.4572 64.8069 64.2788 64.2943 63.9375 63.8957L58.3334 58.3749Z" fill="#DCC8F5" />
                <path d="M62.4999 23.4166C68.2529 23.4166 72.9166 18.7529 72.9166 13C72.9166 7.24701 68.2529 2.58331 62.4999 2.58331C56.747 2.58331 52.0833 7.24701 52.0833 13C52.0833 18.7529 56.747 23.4166 62.4999 23.4166Z" fill="#FDB022" />
            </g>
            <defs>
                <clipPath id="clip0_1276_31511">
                    <rect width="75" height="75" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default ClockIcon;
