import {SECTION_LIST} from "../../../../../constants";
import {CustomPrintStyle} from "../CustomPrint/CustomCss";
import {getFooterEle, getHeaderEle} from "../CustomPrint/customPrintEle";


export const PrintPageFunction = async (stateData:any) => {
  document.querySelectorAll('#empReportIframe')?.forEach((item) => {
    item?.remove();
  });
  const $empMainSectionRef:any = document.querySelector('#printParent')
  // addOrDeleteImageTag(false,$empMainSectionRef)
  // addOrDeleteImageTag(false,$empMainSectionRef)
  const getAllCanvasWrapper = document.querySelectorAll('.canvas-wrapper');
  if (getAllCanvasWrapper.length) {
    addOrDeleteImageTag(true,$empMainSectionRef)
  }
  const $styleTag = document.createElement('style');
  $styleTag.textContent = CustomPrintStyle;

  const $iframeContent: any = document.createElement('div');
  $iframeContent.setAttribute('style', 'width: auto;display:table;');
  $iframeContent.setAttribute('id', 'root');
  const $empMainSection:any = document.createElement('div');
  $empMainSection.innerHTML = $empMainSectionRef.innerHTML;
  const $printTable: any = document.createElement('table');


  const $printTbody: any = document.createElement('tbody');
  const $tr1: any = document.createElement('tr');
  const $td1: any = document.createElement('td');
  $td1.innerHTML = $empMainSection?.outerHTML;
  $tr1.appendChild($td1);

  patientStatsWidgetPlacement($empMainSection, 2,SECTION_LIST.PATIENT_STATISTICS)
  patientStatsWidgetPlacement($empMainSection, 2,SECTION_LIST.UTILIZATION)

  $printTbody.appendChild($tr1);
  $printTable.appendChild($printTbody);
  $iframeContent.appendChild($printTable);


  return $iframeContent.outerHTML;

  const $iframe: any = document.createElement('iframe');
  $iframe.setAttribute('id', 'empReportIframe');
  $iframe.setAttribute('width', '100vw');
  $iframe.setAttribute('height', '100vh');
  $iframe.style.display = 'none';
  document.getElementsByTagName('body')[0].appendChild($iframe);
  $iframe.contentWindow.document.head.innerHTML = $styleTag?.outerHTML;
  $iframe.contentWindow.document.body.innerHTML = $iframeContent?.outerHTML;

};


export const addOrDeleteImageTag = (isAdd: boolean, wrapperEle?:any) => {
  const getAllCanvasWrapper:any[] = wrapperEle.querySelectorAll('.canvas-wrapper');

  if (getAllCanvasWrapper.length) {
    getAllCanvasWrapper.forEach((item) => {
      let image: any;
      let canvas: any;
      if (isAdd) {
         canvas = item?.querySelector('canvas');
         if(canvas){
        const {width, height}: any = canvas?.getBoundingClientRect();
        const canvasWidth = width;
        const canvasHeight = height;

        // const canvas = nativeBaseWrapper?.getElementsByTagName('canvas');
        const dataUrl = canvas?.toDataURL();
         image = document.createElement('img');
        Object.assign(image, {
          className: 'print-show',
          style: `objectFit: 'contain'`,
          src: dataUrl,
          height: canvasHeight,
          width: canvasWidth,
        });
        const node: any = item.querySelector('.canvas-wrapper > div > div');
        //canvas.style.display = 'none';
        node?.appendChild(image);}
      } else {
        canvas = item?.querySelector('canvas');
        if(canvas != null){
          canvas.style.display = 'inline-block'
         }
        const node: any = item.querySelector('.canvas-wrapper > div > div ');
        const removeImage: any = item.querySelector('.canvas-wrapper > div > div > img');
        if (removeImage != null) {
          removeImage.style.display = 'none'
           const remo = node.removeChild(removeImage);
        }
      }
    });
  }
};

export const patientStatsWidgetPlacement = (employerBlock:any, chunk:number, sectionName:string)=> {

  const  $sectionWrapper:any = employerBlock.querySelector(`#${sectionName}-wrapper`)
  const $getAllPatientStatsWidget =  $sectionWrapper?.childNodes
  const $sectionContainer = employerBlock?.querySelector(`.${sectionName}-container`);

  if (!$sectionWrapper) {
    return
  }
  const $wrapperSectionClass = $sectionWrapper.getAttribute('class');
  $sectionContainer.removeChild($sectionWrapper)
  const chunkSize = chunk

  const arrayOfElements = sliceIntoChunks($getAllPatientStatsWidget, chunkSize)

  arrayOfElements.forEach((item, index)=> {
    const $wrapper:any = document.createElement('div');
    const $mainElementWrapper:any = document.createElement('main');
    const $newElementWrapper:any = document.createElement('div');
    const $pageBreakElement = document.createElement('div');

    $pageBreakElement.setAttribute('style','break-after: always;page-break-after: always;display:none;');

    $newElementWrapper.setAttribute('class',$wrapperSectionClass);

    item.forEach((ele:any)=> {
      $newElementWrapper.appendChild(ele)
    })

    $mainElementWrapper.appendChild($newElementWrapper)

    $wrapper.appendChild($mainElementWrapper);
    $wrapper.appendChild($pageBreakElement)

    $sectionContainer.appendChild($wrapper);
  })

}




export const  sliceIntoChunks =   (arr:any[], chunkSize:any) =>{
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = [...arr].slice(i, i + chunkSize);
      res.push(chunk);
  }
  return res;
}
